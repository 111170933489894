/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { HttpClient } from "@angular/common/http";
import { Injectable, LOCALE_ID, ViewContainerRef } from "@angular/core";
import { ValidatorFn, Validators } from "@angular/forms";
import {
  Col,
  DataType,
  ExecuteEventDatatypeOptions,
  ExecuteEvent_DisplayMode,
  FilterType,
} from "src/app/form-list/form-list/form-list";
import { FormListModel } from "src/app/form-list/form-list/form-list-model.interface";
import { AuthService } from "src/app/shared/services/auth.service";
import mccList from "../../../../shared/json/mcc-list.json";
import { Observable, of, Subscription } from "rxjs";
import { TakuPay, TakuPaymentAccountStatus } from "./taku-pay";
import { SelectItem } from "primeng/api";
import { DBService } from "src/app/shared/services/db.service";
import { TakuPayAccountDetail } from "./taku-pay-account-details/taku-pay-account-details";
import { Router } from "@angular/router";
import { EventEmitter, OnDestroy } from "@angular/core";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { Other_Type } from "../../business-settings/tender-type/tender-type";

import { TenderReceiptTapePreviewComponent } from "src/app/core/previews/receipt/tender-receipt-tape-preview/tender-receipt-tape-preview.component";
import { PrintingMgr } from "src/app/shared/services/printing-service.service";
import { StarCloudPrintServiceService } from "src/app/shared/services/star-cloud-print-service.service";
import { PrintHelpers } from "src/app/utility/PrintHelpers";
import { PrinterManufacturer } from "../../store-settings/printer/printer-settings";
import { SaleDocTender } from "src/app/core/document/sale-document/sale-doc-tender/sale-doc-tender";
import { SaleDoc } from "src/app/core/document/sale-document/sale-doc/sale-doc";
import { ResponseTransaction } from "../gateway-terminals/payment-terminal";
import { MonetaryHelpers } from "src/app/utility/MonetaryHelpers";
import { ClearCacheService } from "src/app/shared/services/http-interceptors/clear-cache.service";

@Injectable({
  providedIn: "root",
})
export class TakuPayService extends DBService implements FormListModel, OnDestroy {
  enum_taku_payment_account_statuses = this.enumSelectOptions(TakuPaymentAccountStatus, { useNullAsValue: false });
  getLocation: EventEmitter<TakuPay> = new EventEmitter<TakuPay>();
  setupLink: EventEmitter<TakuPayAccountDetail> = new EventEmitter<TakuPayAccountDetail>();
  subsList: Subscription[] = [];
  sMesageUnknownError = "Unknown Error";

  constructor(
    protected http: HttpClient,
    protected authService: AuthService,
    private router: Router,
    private appSettingsService: AppSettingsStorageService,
    private starCloudPrintServiceService: StarCloudPrintServiceService,
    private clearCacheService: ClearCacheService
  ) {
    super(http, authService);
    this.subsList.push(
      this.getLocation.subscribe((takuPay: TakuPay) => {
        const storeId = this.appSettingsService.getStoreId();
        this.router.navigate(["/integrations/my-apps/taku-payment-gateways/list/takuPaymentGateway/", storeId]);
      })
    );

    this.subsList.push(
      this.setupLink.subscribe((takuPay: TakuPayAccountDetail) => {
        window.open(takuPay.setupLink, "_blank");
      })
    );
  }

  getValidationRules(): Partial<Record<keyof TakuPayAccountDetail, ValidatorFn[]>> {
    return {
      name: [Validators.required],
      mcc: [Validators.required],
      countryIsoCode: [Validators.required],
      userFirstName: [Validators.required],
      userLastName: [Validators.required],
      userEmail: [Validators.required],
    };
  }

  get_lookup_stores(): Observable<SelectItem[]> {
    return this.lookupSelectOptions("store", "storeName", { enableFieldName: "isActive" });
  }

  formlist_lookup_delivery_statuses(rowData): SelectItem[] {
    return this.enum_taku_payment_account_statuses.map((row) => {
      row.disabled = true;
      row.icon = "pi pi-lock";
      return row;
    });
  }

  getSetupLink(rowData: TakuPayAccountDetail) {
    return rowData.setupLink ? "Account Setup" : "";
  }

  getFormListColumns(): Col[] {
    return [
      {
        field: "isActive",
        header: "Active",
        visible: false,
        readonly: false,
        frozen: false,
        dataType: DataType.checkbox,
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.enum,
      },
      {
        field: "status",
        header: "Status",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.formlist_lookup_delivery_statuses.bind(this),
        filterType: FilterType.enum,
        isNotSortable: false,
        classObj: "status-margin",
        filterOptions: this.enum_taku_payment_account_statuses,
      },
      {
        field: "name",
        header: "TAKU PAY Account Name",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.lookup,
        dataOptions: this.get_lookup_stores(),
        filterType: FilterType.lookup,
        colWidth: 250,
      },
      {
        field: "accountId",
        header: "TAKU Pay Account ID",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 220,
      },
      {
        field: "setupLink",
        header: "Setup Link",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.execute_event,
        dataOptions: [],
        dataTypeOptions: [
          new ExecuteEventDatatypeOptions({
            displayMode: ExecuteEvent_DisplayMode.LINK,
            label: this.getSetupLink,
            event: this.setupLink,
            enabledOnEditMode: false,
            styleClass: "",
          }),
        ],
        filterType: FilterType.none,
        colWidth: 150,
      },
      {
        field: "location",
        header: "Location",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.execute_event,
        dataOptions: [],
        dataTypeOptions: [
          new ExecuteEventDatatypeOptions({
            displayMode: ExecuteEvent_DisplayMode.LINK,
            label: "View",
            event: this.getLocation,
          }),
        ],
        filterType: FilterType.none,
        colWidth: 150,
      },
      {
        field: "countryIsoCode",
        header: "Country",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "currencyIsoCode",
        header: "Currency",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
    ];
  }

  getMccList() {
    let mccListFilter;
    if (this.appSettingsService.getUser().isSuperAdmin) {
      mccListFilter = mccList.filter(function (mccList) {
        return mccList.isLimited == false;
      });
    } else {
      mccListFilter = mccList.filter(function (mccList) {
        return mccList.isLimited == true;
      });
    }
    return of(mccListFilter);
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }

  getCardtype(_type: string): Other_Type {
    switch (_type) {
      case "Interac":
        return Other_Type.Interac;
      case "Visa":
        return Other_Type.Visa;
      case "MC":
      case "Mastercard":
        return Other_Type.MC;
      case "Maestro":
        return Other_Type.MCMaestro;
      case "Amex":
      case "AmEx":
        return Other_Type.Amex;
      case "JCB":
        return Other_Type.JCB;
      case "Discover":
        return Other_Type.Discover;
      default:
        return null;
    }
  }

  createTakuPayAccount(id: number): Observable<any> {
    this.clearCacheService.delete("takuPaymentAccount");
    return this._postRequest(`${this.webUrl}takuPay/account/${id}`, {}, {});
  }

  removeTakuPayAccount(id: number): Observable<any> {
    this.clearCacheService.delete("takuPaymentAccount");
    return this._deleteRequest(`${this.webUrl}takuPay/account/${id}`, {}, {});
  }

  syncTAKUPayAccounts() {
    this.clearCacheService.delete("takuPaymentAccount");
    const endpointUrl = this.webUrl + `takuPay/syncTAKUPayAccounts`;
    return this._postRequest(endpointUrl, {});
  }

  createTakuPayLocation(id: number): Observable<any> {
    this.clearCacheService.delete("takuPaymentGateway");
    return this._postRequest(`${this.webUrl}takuPay/location/${id}`, {}, {});
  }

  removeTakuPayLocation(id: number): Observable<any> {
    this.clearCacheService.delete("takuPaymentGateway");
    return this._deleteRequest(`${this.webUrl}takuPay/location/${id}`, {}, {});
  }

  pairTerminal(id: number) {
    this.clearCacheService.delete("takuPaymentTerminal");
    return this._postRequest(`${this.webUrl}takuPay/terminal/${id}`, {}, {});
  }

  unPairTerminal(id: number) {
    this.clearCacheService.delete("takuPaymentTerminal");
    return this._deleteRequest(`${this.webUrl}takuPay/terminal/${id}`, {}, {});
  }

  cancelTerminalPaymentPrompt(storeId: number, paymentId: string): Observable<any> {
    return this._postRequest(
      `${this.webUrl}takuPay/terminal/cancel-payment-prompt/${storeId}`,
      { payment_id: paymentId },
      {}
    );
  }

  runTransaction(command: string, id: number, body: unknown): Observable<any> {
    return this._postRequest(`${this.webUrl}takuPay/payment/${command}/${id}`, body, {});
  }

  approveDomain(id: number): Observable<any> {
    return this._postRequest(`${this.webUrl}takuPay/approveDomain/${id}`, {}, {});
  }

  printPaymentReceipt(
    printingMgr: PrintingMgr,
    saleDocTender: SaleDocTender,
    saleDoc: SaleDoc,
    printPreviewHost: ViewContainerRef
  ): void {
    if (
      this.appSettingsService.getStation() &&
      this.appSettingsService.getStation().receiptPrinter?.manufacturer === PrinterManufacturer.star_cloudPRNT
    ) {
      //Taku Pay doesn't need this below lines because it was attached Receipt directly
      //this.starCloudPrintServiceService.printStarCloudCardReceipt(saleDoc);
    } else {
      let storeInfoHtml = `<div class="storeInfoTopWrapper" style="font-family: monospace; display: flex; flex-direction: column; align-items: center;">`;
      if (printingMgr.tapeSizeBuilderSettings.printStoreName) {
        storeInfoHtml += `<h2 class="retailer-name">${saleDoc.store.storeName}</h2>`;
      }
      storeInfoHtml += `<span href="https://maps.google.com/?q=&t=m" target="_blank"></span>`;
      if (printingMgr.tapeSizeBuilderSettings.printAddress) {
        storeInfoHtml += `<div class="retailer-address" style="display: flex; flex-direction: column; align-items: center;">
                  <div class="address-line1">${saleDoc.store.address?.line1}</div>
                  <div class="address-city">${saleDoc.store.address?.city}, ${PrintHelpers.subdivisionDisplay(
          saleDoc.store.address?.subDivisionIsoCode
        )}
                    ${saleDoc.store.address?.postalCode}</div>
                </div>`;
      }
      if (printingMgr.tapeSizeBuilderSettings.printPhoneNo && saleDoc.store.address?.addressPhone) {
        storeInfoHtml += `<div class="retailer-phone">
                  <span class="phone-label">Phone:</span> ${saleDoc.store.address?.addressPhone.fullPhoneNumber}
                  </div>`;
      }
      if (printingMgr.tapeSizeBuilderSettings.printWebsite) {
        storeInfoHtml += `<div class="retailer-website">${saleDoc.store.url}</div>`;
      }
      if (printingMgr.tapeSizeBuilderSettings.printEmail) {
        storeInfoHtml += `<div class="retailer-email">${saleDoc.store.address?.addressEmail.email}</div>`;
      }
      storeInfoHtml += `</div><br>`;

      if (saleDocTender?.refTransaction && saleDocTender?.refTransaction["merchantReceiptTemplate"]) {
        printingMgr.printTemplate(
          '<div style="max-width: 300px">' +
            storeInfoHtml +
            "<pre>" +
            saleDocTender.refTransaction["merchantReceiptTemplate"] +
            "</pre></div>",
          printPreviewHost,
          TenderReceiptTapePreviewComponent,
          "Tender Receipt - Print Preview"
        );
      }

      if (saleDocTender.refTransaction && saleDocTender.refTransaction["customerReceiptTemplate"]) {
        printingMgr.printTemplate(
          '<div style="max-width: 300px">' +
            storeInfoHtml +
            "<pre>" +
            saleDocTender.refTransaction["customerReceiptTemplate"] +
            "</pre></div>",
          printPreviewHost,
          TenderReceiptTapePreviewComponent,
          "Tender Receipt - Print Preview"
        );
      }
    }
  }

  getTenderReceiptStoreInfoHtml(
    printingMgr: PrintingMgr,
    saleDocTender: SaleDocTender,
    saleDoc: SaleDoc
  ): [string, string] {
    let storeInfoHtml = `<div class="storeInfoTopWrapper" style="font-family: monospace; display: flex; flex-direction: column; align-items: center;">`;
    if (printingMgr.tapeSizeBuilderSettings.printStoreName) {
      storeInfoHtml += `<h2 class="retailer-name">${saleDoc.store.storeName}</h2>`;
    }
    storeInfoHtml += `<span href="https://maps.google.com/?q=&t=m" target="_blank"></span>`;
    if (printingMgr.tapeSizeBuilderSettings.printAddress) {
      storeInfoHtml += `<div class="retailer-address" style="display: flex; flex-direction: column; align-items: center;">
                  <div class="address-line1">${saleDoc.store.address?.line1}</div>
                  <div class="address-city">${saleDoc.store.address?.city}, ${PrintHelpers.subdivisionDisplay(
        saleDoc.store.address?.subDivisionIsoCode
      )}
                    ${saleDoc.store.address?.postalCode}</div>
                </div>`;
    }
    if (printingMgr.tapeSizeBuilderSettings.printPhoneNo && saleDoc.store.address?.addressPhone) {
      storeInfoHtml += `<div class="retailer-phone">
                  <span class="phone-label">Phone:</span> ${saleDoc.store.address?.addressPhone.fullPhoneNumber}
                  </div>`;
    }
    if (printingMgr.tapeSizeBuilderSettings.printWebsite) {
      storeInfoHtml += `<div class="retailer-website">${saleDoc.store.url}</div>`;
    }
    if (printingMgr.tapeSizeBuilderSettings.printEmail) {
      storeInfoHtml += `<div class="retailer-email">${saleDoc.store.address?.addressEmail.email}</div>`;
    }
    storeInfoHtml += `</div><br>`;

    const receiptTemplates: [string, string] = [null, null];
    if (saleDocTender?.refTransaction && saleDocTender?.refTransaction["customerReceiptTemplate"]) {
      receiptTemplates[0] = `<div style="max-width: 300px">
            ${storeInfoHtml}
            <pre>
              ${saleDocTender.refTransaction["customerReceiptTemplate"] as string}
            </pre>
          </div>`;
    }

    if (saleDocTender.refTransaction && saleDocTender.refTransaction["merchantReceiptTemplate"]) {
      receiptTemplates[1] = `
          <div style="max-width: 300px">
            ${storeInfoHtml}
            <pre>
              ${saleDocTender.refTransaction["merchantReceiptTemplate"] as string}
            </pre>
          </div>`;
    }

    return receiptTemplates;
  }

  createPaymentReceipt(refTransaction: ResponseTransaction, bMerchantCopy = true): string {
    let leftDisp = "";
    let rightDisp = "";
    let needSpace = 0;
    let receiptFormatted = "";
    const charactersPerLine = 42;

    //let sCurrencySymbol = this.getCurrencySymbol(saleDoc.doc.currencyIsoCode);
    const currencySymbol = "$";

    receiptFormatted = `\r\n` + `Payment Method                      Amount` + `\r\n`;
    leftDisp = `${refTransaction.CardType} x${refTransaction.Last4}`;
    rightDisp = currencySymbol + MonetaryHelpers.roundToDecimalPlaces(parseFloat(refTransaction.Amount)).toFixed(2);
    needSpace = charactersPerLine - (leftDisp.length + rightDisp.length);
    receiptFormatted += leftDisp + " ".repeat(needSpace) + rightDisp + `\r\n`;

    leftDisp = `Trans Type`;
    rightDisp = refTransaction?.TransType.charAt(0).toUpperCase() + refTransaction?.TransType?.slice(1);
    needSpace = charactersPerLine - (leftDisp.length + rightDisp.length);
    receiptFormatted += leftDisp + " ".repeat(needSpace) + rightDisp + `\r\n`;

    if (bMerchantCopy) {
      leftDisp = `Terminal Id`;
      if (refTransaction?.TerminalID !== null && refTransaction?.TerminalID !== undefined) {
        rightDisp = refTransaction?.TerminalID ? refTransaction?.TerminalID : "";
        needSpace = charactersPerLine - (leftDisp.length + rightDisp.length);
        receiptFormatted += leftDisp + " ".repeat(needSpace) + rightDisp + `\r\n`;
      }

      leftDisp = `Trans Source`;
      rightDisp = refTransaction?.EntryMethod ? refTransaction?.EntryMethod : "";
      needSpace = charactersPerLine - (leftDisp.length + rightDisp.length);
      receiptFormatted += leftDisp + " ".repeat(needSpace) + rightDisp + `\r\n`;

      leftDisp = `Auth Code`;
      rightDisp = refTransaction?.AuthCode ? refTransaction?.AuthCode : "";
      needSpace = charactersPerLine - (leftDisp.length + rightDisp.length);
      receiptFormatted += leftDisp + " ".repeat(needSpace) + rightDisp + `\r\n`;

      leftDisp = `EMV App Label`;
      rightDisp = refTransaction?.EMV_App_Label ? refTransaction?.EMV_App_Label : "";
      needSpace = charactersPerLine - (leftDisp.length + rightDisp.length);
      receiptFormatted += leftDisp + " ".repeat(needSpace) + rightDisp + `\r\n`;

      leftDisp = `EMV App Id`;
      rightDisp = refTransaction?.EMV_App_Name ? refTransaction?.EMV_App_Name : "";
      needSpace = charactersPerLine - (leftDisp.length + rightDisp.length);
      receiptFormatted += leftDisp + " ".repeat(needSpace) + rightDisp + `\r\n\r\n`;

      receiptFormatted += `\r\n\r\n`;
      receiptFormatted += "      x_____________________________      " + `\r\n`;
      receiptFormatted += "                Signature                 " + `\r\n\r\n`;
      receiptFormatted += "     I AGREE TO PAY ABOVE TOTAL AMOUNT    " + `\r\n`;
      receiptFormatted += "    ACCORDING TO CARD ISSUER AGREEMENT    " + `\r\n`;
      receiptFormatted += "  (MERCHANT AGREEMENT IF CREDIT VOUCHER)  " + `\r\n\r\n`;
      receiptFormatted += "               MERCHANT COPY              " + `\r\n`;
    } else {
      receiptFormatted += `\r\n`;
      receiptFormatted += "               CUSTOMER COPY              " + `\r\n`;
    }

    return receiptFormatted;
  }
}
/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
