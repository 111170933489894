import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { pairwise, startWith } from "rxjs/operators";
import { Inventory } from "src/app/core/inventory/inventory/inventory";
import { InventoryService } from "src/app/core/inventory/inventory/inventory.service";
import { SaleDocLineComponent } from "../sale-doc-line/sale-doc-line.component";

@Component({
  selector: "taku-self-checkout-addon-list-card",
  templateUrl: "./self-checkout-addon-list-card.component.html",
  styleUrls: ["./self-checkout-addon-list-card.component.scss"],
})
export class SelfCheckoutAddonListCardComponent {
  subsList: Subscription[] = [];

  @Input() _myForm: UntypedFormGroup;
  @Input() disabled = false;
  @Output() viewSelfCheckoutItemDetails: EventEmitter<UntypedFormGroup> = new EventEmitter();
  @Input() width: number;
  @Input() height: number;
  indexInDocLinesCtrl;
  docLineCtrl;
  inventoryCtrl: UntypedFormGroup;

  @Input() cartDocLinesForm: UntypedFormGroup;
  fb: UntypedFormBuilder;
  @Output() addItemToCart: EventEmitter<any> = new EventEmitter();
  @Output() deleteItemFromCart: EventEmitter<number> = new EventEmitter();

  constructor(fb: UntypedFormBuilder, public inventoryService: InventoryService) {
    this.fb = fb;
  }

  ngOnInit(): void {
    // console.log(this._myForm);

    // subscription to watch values changes on the LOCAL addon form (this.qtyCtrl)
    this.subsList.push(
      this.qtyCtrl.valueChanges.pipe(startWith(0), pairwise()).subscribe(([prev, newValue]) => {
        this.updateIndexAndDocLineCtrl();
        // console.log(prev, newValue);

        if (newValue === 0) {
          // remove item from the cart/doclinesForm
          this.deleteItemFromCart.emit(this.indexInDocLinesCtrl);
        } else if (prev === 0) {
          // console.log(this.inventory);
          // add the addon item to the cart/doclinesForm
          this.addItemToCart.emit({ inventory: this.inventory, addQtyAmount: newValue });
        } else {
          this.docLineCtrl.get("qty").setValue(newValue);
        }
      })
    );
  }

  get InventoryImagePinnedToPictureGallery() {
    let result = this.inventory?.inventoryImages.find((row) => row.pinToPictureGallery == true);
    if (!result) {
      result =
        this.inventory?.parentInventory?.inventoryImages &&
        this.inventory.parentInventory?.inventoryImages.find((row) => row.pinToPictureGallery == true);
    }
    return result;
  }

  get inventory(): Inventory {
    return this._myForm.get("docLine").value.inventory;
  }

  get qtyCtrl() {
    return this._myForm.get("qty");
  }

  get inventoryVariantsStr() {
    return this._myForm
      .get("docLine.inventory")
      .value.inventoryVariants?.map((_inventoryVariant) => _inventoryVariant.inventoryOptionsSettingValue?.optionValue)
      .join(",");
  }

  openItemDetailsDlg(e: MouseEvent) {
    e.stopImmediatePropagation();

    const tempform = SaleDocLineComponent.set(this.fb, Object.assign({}, this._myForm.value), 0);

    this.viewSelfCheckoutItemDetails.emit(tempform);

    tempform.valueChanges.pipe(startWith(this._myForm.value), pairwise()).subscribe(([prev, newValue]) => {
      if (prev.docLine.inventoryId == newValue.docLine.inventoryId) {
        if (prev.qty !== newValue.qty) {
          this.qtyCtrl.setValue(newValue.qty);
        }
      } else {
        if (newValue.qty !== 0) {
          this.addItemToCart.emit({ inventory: newValue.docLine.inventory, addQtyAmount: newValue.qty });
        }
      }
    });
  }

  increaseQty() {
    // update local formgroup
    this.qtyCtrl.setValue(this.qtyCtrl.value + 1);
  }

  decreaseQty() {
    // only run this function when qty > 0
    if (this.qtyCtrl.value !== 0) {
      // update local formgroup
      this.qtyCtrl.setValue(this.qtyCtrl.value - 1);
    }
  }

  updateIndexAndDocLineCtrl() {
    const tempArray = Object.keys(this.cartDocLinesForm["controls"]);

    for (let i = 0; i < tempArray.length; i++) {
      if (this.cartDocLinesForm["controls"][i].value.docLine.inventoryId === this.inventory.id) {
        this.indexInDocLinesCtrl = i;
        this.docLineCtrl = this.cartDocLinesForm["controls"][i];
        break;
      }
    }
  }
}
