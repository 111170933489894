/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Person } from "../../contact-accounts/person/person";
import { Contact } from "../../contact-accounts/contact/contact";

export class CsvCommercialContact {
  accountCode: string;
  companyName: string;
  person: Person;

  constructor() {
    this.accountCode = null;
    this.companyName = null;
    this.person = null;
  }
}

export class BackendCommercialContact {
  accountCode: string;
  contacts: Contact[];

  constructor(accountCode: string) {
    this.accountCode = accountCode;
    this.contacts = [];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
