/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { FormDataHelpers } from "src/app/utility/FormDataHelpers";
import * as _ from "lodash";
import { FormListService } from "src/app/form-list/form-list/form-list.service";

@Injectable({
  providedIn: "root",
})
export class CloningService {
  private excludedFieldsPerModel: { [key: string]: string[] } = {
    inventory: ["sku"],
    commercialAccount: ["account.accountCode"],
    personalAccount: ["account.accountCode"],
  };

  constructor(private formListService: FormListService) {}

  cloneObject(srcObject: any, model: string, additionalExclusions: string[] = []) {
    const defaultExclusions = this.excludedFieldsPerModel[model] || [];
    const exclusions = defaultExclusions.concat(additionalExclusions);
    const defaultValues = _.cloneDeep(srcObject);
    for (const excludedField of exclusions) {
      _.unset(defaultValues, excludedField);
    }
    // Set model ids to 0, to current and nested objects in order to signal the records is new
    FormDataHelpers.clearModelIDs(defaultValues);

    const clonedObject = this.formListService.newRow(model, defaultValues);
    return clonedObject;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
