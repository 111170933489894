<header>
  <p-button icon="pi pi-arrow-left" (onClick)="goBack()"></p-button>
  <h2>{{ inventoryItem.description1 }}</h2>
  <p-button icon="_" pRipple pButton (onClick)="openInventoryEditForm()" class="p-button-rounded">
    <i class="material-icons">remove_red_eye</i>
  </p-button>
</header>

<p-tabView class="grid" styleClass="tabview-fluid">
  <p-tabPanel header="DETAILS">
    <div class="grid details-tab-content">
      <div class="col-8 categories-field"> <strong>Categories:</strong> {{ _inventoryCatsNames.join(", ") }} </div>

      <div class="col-3 price-field">
        {{ inventoryItem.standardPrice | currency : "CAD" : "symbol-narrow" : "1.2" }}
      </div>

      <div class="col-5 sku-field"> <strong>SKU:</strong> {{ inventoryItem.sku }} </div>

      <div class="col-6 barcode-field">
        <ng-template [ngIf]="inventoryItem.inventoryBarcodes.length > 0">
          <strong>Barcode:</strong> {{ inventoryItem.inventoryBarcodes[0].barcode }}
        </ng-template>
      </div>

      <hr class="fields-divider" />

      <div class="col-12"><strong>Policies</strong></div>
      <div class="col-12 policy-field" [innerHTML]="inventoryItem.policy"></div>
    </div>
  </p-tabPanel>

  <p-tabPanel header="HISTORY">
    <div class="col-12"> History </div>
  </p-tabPanel>

  <p-tabPanel header="PRODUCT IMAGES">
    <div class="col-12"> Images </div>
  </p-tabPanel>
</p-tabView>

<!-- <div class="col-12">
  {{inventoryItem|json}}
</div> -->
