/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { TenderType } from "../../business-settings/tender-type/tender-type";

export class PaymentGateway {
  id: number;
  shortName: string;
  paymentGatewayType: PaymentGatewayType; //Enum
  currencyIsoCode: string;
  isActive: boolean;
  storeId: number; // lookup for store
  tenderTypes: TenderType[];

  constructor() {
    this.id = 0;
    this.shortName = null;
    this.paymentGatewayType = null;
    this.currencyIsoCode = null;
    this.isActive = true;
    this.storeId = null;
    this.tenderTypes = [];
  }
}

export enum PaymentGatewayType {
  PAYROC = "PayRoc",
  MONERIS = "Moneris",
}

export const PaymentGatewayTypes = {
  PayRoc: PaymentGatewayType.PAYROC,
  Moneris: PaymentGatewayType.MONERIS,
};

export enum PaymentGatewayShortname {
  PAYROC_SHORTNAME = "PR",
  MONERIS_SHORTNAME = "MO",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
