export enum TakuPaymentAccountStatus {
  Pending = "pending",
  Created = "created",
  Rejected = "rejected",
  Restricted = "restricted",
  Enabled = "enabled",
}

export class TakuPay {
  status: TakuPaymentAccountStatus;
  accountId: string;
  setup: string;
  location: string;
  countryCode: string;
  currency: string;

  constructor() {
    this.status = TakuPaymentAccountStatus.Pending;
    this.accountId = "";
    this.setup = "";
    this.location = "";
    this.countryCode = "";
    this.currency = "";
  }
}
