import { Component, ElementRef, OnDestroy, OnInit } from "@angular/core";
import { forkJoin, ReplaySubject, Subscription } from "rxjs";
import { CashoutSummaryDoc, DBCashoutService } from "src/app/core/cashout/db-cashout.service";
import { BusinessDetail } from "src/app/core/settings/business-settings/business-detail/business-detail";
import { User } from "src/app/core/users/user/user";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { LocationService } from "src/app/shared/services/location.service";
import { PrintHelpers } from "src/app/utility/PrintHelpers";
import { CashoutSlipBase } from "../CashoutSlipBase.interface";
import * as _ from "lodash";
import { Store } from "src/app/core/settings/store-settings/store/store";
// import { CashoutOpeningFloat } from 'src/app/core/settings/store-settings/settings-cashout-opening-float/cashout-opening-float';
import { CashoutDeposits } from "src/app/core/cashout/cashout-closetill/cashout-closetill.component";
// import { PrintPaginator } from '../cashout-slip-full-size/cashout-slip-full-size.component';

// OpeningCashoutDeposits = CashoutOpeningFloat

type PageInfo = {
  pageNumber: number;
  isFirstPage: boolean;
  isLastPage: boolean;
  docsSummaries: CashoutSummaryDoc[];
  // deposits: CashoutDeposits[],
};

class PrintPaginator {
  static readonly LINES_PER_FULLPAGE = 15;

  readonly totalPages: number;

  constructor(private docsSummaries: CashoutSummaryDoc[]) {
    this.totalPages = Math.ceil(docsSummaries.length / PrintPaginator.LINES_PER_FULLPAGE);
  }

  getPagesList(): PageInfo[] {
    const pagesInfo: PageInfo[] = [];

    for (let pageNo = 1; pageNo <= this.totalPages; pageNo++) {
      const isFirstPage = pageNo === 1;
      const isLastPage = pageNo === this.totalPages;
      let docsSummaries;
      // let deposits;

      if (this.totalPages > 1) {
        if (pageNo === 1) {
          docsSummaries = this.docsSummaries;
          // deposits = null;
        } else {
          docsSummaries = null;
          // deposits = this.deposits;
        }
      } else {
        docsSummaries = this.docsSummaries;
        // deposits = this.deposits;
      }

      pagesInfo.push({
        pageNumber: pageNo,
        isFirstPage,
        isLastPage,
        docsSummaries,
        // deposits,
      });
    }

    return pagesInfo;
  }
}

@Component({
  selector: "taku-opening-cashout-slip-full-size",
  templateUrl: "./opening-cashout-slip-full-size.component.html",
  styleUrls: ["../../../../../assets/layout/taku/opening-cashout-slip-full-size.component.css"],
})
export class OpeningCashoutSlipFullSizeComponent extends CashoutSlipBase implements OnInit, OnDestroy {
  businessDetail: BusinessDetail;

  private static readonly COMPILED_CSS_URL = "/assets/layout/taku/opening-cashout-slip-full-size.component.css";
  subsList: Subscription[] = [];

  openingUser: User;
  printedDate: Date;
  _countryName: string = null;
  _digitsFormat = "1.2-2";
  _printedDate = new Date();

  private _paginator: PrintPaginator;
  pagesInfo: PageInfo[];
  totalPages = 0;

  private _dataReadySubject = new ReplaySubject<boolean>(1);
  onDataReady$ = this._dataReadySubject.asObservable();

  constructor(
    elementRef: ElementRef,
    private locationService: LocationService,
    private dbService: DBCashoutService,
    private appSettings: AppSettingsStorageService
  ) {
    super(elementRef);
  }

  ngOnInit() {
    this.subsList.push(
      forkJoin([
        this.dbService.getRow<Store>("store", this.cashout.storeId),
        this.dbService.getSummary(this.cashout.id),
        this.dbService.getRow<User>("user", this.cashout.openingCashFloatUserId),
        // this.dbService.getRow<User>('user', this.cashout.closingCashFloatUserId),
        this.appSettings.getBusinessDetails(),
      ]).subscribe(
        ([store, cashoutsSummary, user, businessDetail]: [Store, CashoutSummaryDoc[], User, BusinessDetail]) => {
          this.store = _.merge(new Store(), store);
          this.docsSummaries = cashoutsSummary.map((doc) => _.merge(new CashoutSummaryDoc(), doc));
          this.openingUser = _.merge(new User(), user);
          this.businessDetail = businessDetail;

          this._paginator = new PrintPaginator(this.docsSummaries);
          this.pagesInfo = this._paginator.getPagesList();
          this.totalPages = this._paginator.totalPages;

          this.printedDate = new Date();
          if (this.store.address) {
            this._countryName = this.locationService.lookupCountryByIsoCode(this.store.address.countryIsoCode);
          }

          this._dataReadySubject.next(true);
        }
      )
    );
  }

  getStylesheets() {
    return [OpeningCashoutSlipFullSizeComponent.COMPILED_CSS_URL];
  }

  sendToPrinter() {
    PrintHelpers.printElementContents(this.elementRef.nativeElement, [
      OpeningCashoutSlipFullSizeComponent.COMPILED_CSS_URL,
    ]);
  }

  get businessLogoUrl() {
    // First try to use the Store's logo, if not available fallback to Business's logo
    const logoUrl = this.store.urlStoreLogoUrl || this.businessDetail.urlBusinessDetailBusinessLogoUrl;
    return logoUrl; // WebHelpers.buildImageUrl(logoUrl);
    // return WebHelpers.buildImageUrl(this.businessDetail.BusinessDetailBusinessLogoUrl);
  }
  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}
