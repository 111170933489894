/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { ViewContainerRef } from "@angular/core";
import { SaleDoc } from "../core/document/sale-document/sale-doc/sale-doc";
import { TenderPrintingSettings } from "../core/document/sale-document/tender-screen/tender-screen.component";
import { PrintingMgr, ReceiptPrintingSize } from "../shared/services/printing-service.service";

export class PrintHelpers {
  static printElementContents(el: HTMLElement, stylesheetUrls = [], pageTitle = ""): Window {
    let popupWin;
    const h = screen.height;
    const w = screen.width;
    let stylesheetsHTML = "";
    for (const stylesheetUrl of stylesheetUrls) {
      stylesheetsHTML += `<link href="${stylesheetUrl}" rel="stylesheet" type="text/css">\n`;
    }

    popupWin = window.open("", "_blank", "top=0,left=0,height=" + h + ",width=" + w);
    popupWin?.document.open();
    popupWin?.document.write(`
          <html>
            <head>
              <title>${pageTitle}</title>
              ${stylesheetsHTML}
            </head>
            <body onload="window.print();window.close()">${el.innerHTML || el}</body>
          </html>`);
    // popupWin.document.close();

    setTimeout(() => {
      popupWin.document.close();
    }, 350);

    return popupWin;
  }

  static isTakuPayTransaction(saleDoc: SaleDoc): boolean {
    return saleDoc?.saleDocTenders?.filter((result) => result?.refTransaction?.PaymentId).length > 0;
  }

  static subdivisionDisplay(subDivisionIsoCode: string): string {
    return subDivisionIsoCode ? subDivisionIsoCode.split("-").pop() : "";
  }

  static printSaleDocFromTenderSettings(
    saleDoc: SaleDoc,
    previewHost: ViewContainerRef,
    printingSettings: TenderPrintingSettings,
    printingMgr: PrintingMgr
  ): void {
    if (printingSettings.print) {
      // if print is enabled
      const copies = [saleDoc];
      if (
        PrintHelpers.isTakuPayTransaction(saleDoc) &&
        printingSettings.printingSize === ReceiptPrintingSize.TAPE_SIZE
      ) {
        if (printingSettings.extraCopy) {
          // TakuPay receipt includes Merchant Copy to item line receipt.
          const cloneSaleDoc = Object.assign({}, saleDoc);
          Object.assign(cloneSaleDoc, { isMerchantReceipt: true }, { isCustomerReceipt: false });
          copies.push(cloneSaleDoc);
        }
      }
      printingMgr.printDocumentsWithSize(copies, previewHost, printingSettings.printingSize);
    }
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
