/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { UntypedFormArray, UntypedFormBuilder } from "@angular/forms";
import { Address } from "../../../contact-accounts/address/address";
import { StoreInstantMsg } from "../../../contact-accounts/store-instant-msg/store-instant-msg";
import { StoreSocialProfile } from "../../../contact-accounts/store-social-profile/store-social-profile";
import { Zone } from "../../zone-settings/Zone";
import { StoreHour } from "../store-hour/store-hour";
import * as _ from "lodash";

export class Store {
  id: number;
  storeName: string;
  shortName: string;
  storeID: string;
  url: string;
  storeType: Store_Type;
  isActive: boolean;
  address: Address;
  stockId: number;
  zone: Zone;
  zoneId: number;
  storeHours: StoreHour[];
  // storeEmails: StoreEmail[];
  storeInstantMsgs: StoreInstantMsg[];
  storeSocialProfiles: StoreSocialProfile[];
  storeLogoUrl: string;
  urlStoreLogoUrl: string;
  urlStoreLogoUrl40: string;
  urlStoreLogoUrl150: string;
  urlStoreLogoUrl475: string;
  storeTimeZone: string;

  constructor(public _zone: Zone = null) {
    this.id = 0;
    this.storeName = "";
    this.shortName = "";
    this.storeID = "";
    this.url = "";
    this.storeTimeZone = "America/Toronto";
    this.storeType = Store_Type.physical;
    this.isActive = true;
    this.address = new Address();
    this.zone = _zone || null;
    this.zoneId = _zone ? _zone.id : null;
    this.stockId = null;
    this.storeLogoUrl = null;
    this.urlStoreLogoUrl = null;
    this.urlStoreLogoUrl40 = null;
    this.urlStoreLogoUrl150 = null;
    this.urlStoreLogoUrl475 = null;
    this.storeHours = [];
    // this.storeEmails = [];
    this.storeSocialProfiles = [];
    this.storeInstantMsgs = [];
  }

  get addressPrettyPrint(): string | null {
    if (!this.address) return null;

    return `${this.address.line1}\n
                ${this.address.line2 ? this.address.line2 + "\n" : ""}
                ${this.address.postalCode ? this.address.postalCode + "\n" : ""}\n
                ${this.address.city} ${this.address.subDivisionIsoCode || ""} ${this.address.countryIsoCode || ""}`;
  }

  static convertIntoTypedObject(store: any): Store {
    const typedStore: Store = _.merge(new Store(), store);
    // Convert social profile to appropriate type
    typedStore.storeSocialProfiles = typedStore.storeSocialProfiles.map((socialProfile) =>
      _.merge(new StoreSocialProfile(), socialProfile)
    );

    return typedStore;
  }

  static setArray(fb: UntypedFormBuilder, stores: Store[]): UntypedFormArray {
    const formArray = fb.array([]);
    stores.map((inventoryStock) => {
      const temp = fb.group(inventoryStock);
      formArray.push(temp);
    });

    return formArray;
  }

  static initArray(fb: UntypedFormBuilder): UntypedFormArray {
    const formArray = fb.array([]);
    return formArray;
  }
}

export enum Store_Type {
  physical = "Physical",
  dispatch = "Dispatch",
  dynamic = "Dynamic",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
