/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { PersonPhoneType } from "../person-phone/person-phone";
import { CommercialPhoneType } from "../commercial-account-phone/commercial-account-phone";
import { AddressPhoneType } from "../address-phone/address-phone";

export class GenericPhone {
  phoneType: AddressPhoneType | PersonPhoneType | CommercialPhoneType;
  countryIsoCode: string;
  tel: string;
  ext: string;

  static FormatNumber(phone: GenericPhone): string {
    return `${phone.tel ? phone.tel : ""}${phone.ext ? ` ext. ${phone.ext}` : ""}`.trim();
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
