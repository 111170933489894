/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { DBService } from "src/app/shared/services/db.service";

@Component({
  selector: "taku-google-shopping-push-dialog",
  templateUrl: "./google-shopping-push-dialog.component.html",
  styleUrls: ["./google-shopping-push-dialog.component.scss"],
})
export class GoogleShoppingPushSelectedDialogComponent implements OnInit {
  pushForm: UntypedFormGroup;
  countEnabled: number;
  countDisabled: number;
  submitToCaption: string;
  checkBoxCaption: string;
  info: string;
  submitButtonCaption: string;

  constructor(
    private fb: UntypedFormBuilder,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private dbService: DBService
  ) {
    this.countEnabled = this.config.data.countEnabled || 0;
    this.countDisabled = this.config.data.countDisabled || 0;
    this.submitToCaption = this.config.data.submitToCaption;
    this.checkBoxCaption = this.config.data.checkBoxCaption || "Include disabled items when submitting";
    this.info =
      this.config.data.info ||
      `Are you sure you want to <strong>submit</strong> the selected items to ${this.submitToCaption}?`;
    this.submitButtonCaption = this.config.data.submitButtonCaption || "SUBMIT & ENABLE";
  }

  ngOnInit() {
    this.pushForm = this.fb.group({
      includeDisabled: false,
    });
  }

  onActivateGMC() {
    const pushOptions = this.pushForm.value;
    this.ref.close({ pushOptions, actionInitiated: true });
  }

  get includeDisabledCtrl() {
    return this.pushForm.get("includeDisabled");
  }

  get totalToSubmit() {
    return this.countEnabled + (this.includeDisabledCtrl.value ? this.countDisabled : 0);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
