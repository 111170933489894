<span [formGroup]="formGroup" class="p-float-label">
  <p-inputNumber
    [formControlName]="fieldName"
    [min]="min"
    mode="decimal"
    [max]="max"
    [pKeyFilter]="keyFilter"
    [showButtons]="true"
    [disabled]="disabled"
  ></p-inputNumber>
  <label *ngIf="fieldCaption" [attr.for]="fieldName" [innerHTML]="fullFieldCaption"></label>
</span>

<form-validation-messages
  *ngIf="showValidationErrors"
  [hidden]="ctrlField.valid || !ctrlField.dirty"
  [errors]="ctrlField.errors"
  [fieldName]="fieldName"
></form-validation-messages>
