/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Location } from "@angular/common";
import { Component, Output, EventEmitter } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, MessageService, SelectItem } from "primeng/api";
import { Observable } from "rxjs";
import { GenericFormComponent } from "../../../../forms/generic-form/generic-form.component";
import { AlertMessagesService } from "../../../../shared/services/alert-messages.service";
import { AddressLocationType } from "../../../contact-accounts/address/address";
import { AddressComponent } from "../../../contact-accounts/address/address.component";
import { Stock } from "./stock";
import { StockService } from "./stock.service";
import { AppSettingsStorageService } from "../../../../shared/app-settings-storage.service";
import { AppComponent } from "src/app/app.component";

@Component({
  selector: "taku-stock",
  templateUrl: "./stock.component.html",
  styleUrls: ["./stock.component.scss"],
})
export class StockComponent extends GenericFormComponent {
  enum_stock_types: SelectItem[];
  lookup_zones$: Observable<SelectItem[]>;
  @Output() dialogClosed = new EventEmitter<any>();

  constructor(
    public dbService: StockService,
    _router: Router,
    fb: UntypedFormBuilder,
    location: Location,
    _route: ActivatedRoute,
    messageService: MessageService,
    alertMessage: AlertMessagesService,
    confirmationService: ConfirmationService,
    appSettings: AppSettingsStorageService
  ) {
    super(_router, fb, dbService, location, _route, messageService, alertMessage, confirmationService, appSettings);
  }

  initForm() {
    this._model = "stock";
    this._object = new Stock();
    this._myForm = StockComponent.init(this.fb);
  }

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    const tmpModel = new Stock();
    const tmpForm = fb.group(tmpModel);
    tmpForm.setControl("address", AddressComponent.init(fb, AddressLocationType.commercial));

    return tmpForm;
  }

  initLookups() {
    this.enum_stock_types = this.dbService.get_enum_stock_type();
    this.lookup_zones$ = this.dbService.get_lookup_zones();
  }

  initValidation() {
    this._validation = this.dbService.getValidationRules();
  }

  closeSidebar() {
    this.dialogClosed.emit();
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
