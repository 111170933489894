import { Component, ElementRef, Input, OnInit } from "@angular/core";
import { SaleDoc } from "src/app/core/document/sale-document/sale-doc/sale-doc";
import { StoreSettingTapeSalePrint } from "src/app/core/settings/store-settings/tape-salereceipt-builder/store-setting-tape-sale-print";

@Component({
  selector: "taku-closed-batch-details-tape-preview",
  templateUrl: "./closed-batch-details-tape-preview.component.html",
  styleUrls: ["./closed-batch-details-tape-preview.component.scss"],
})
export class ClosedBatchDetailsTapePreviewComponent implements OnInit {
  @Input("saleDocData") saledoc: SaleDoc;
  @Input("builderSettings") settings: StoreSettingTapeSalePrint;

  readonly STYLESHEET_PATH = "";

  constructor(public elementRef: ElementRef) {}

  ngOnInit(): void {}
}
