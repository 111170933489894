/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { LocalDataService } from "src/app/shared/services/local-data.service";
import { TAKUPayoutsReport } from "./taku-payouts-report";
import { AuthService } from "src/app/shared/services/auth.service";
import { HttpClient } from "@angular/common/http";
import * as moment from "moment";
@Injectable({
  providedIn: "root",
})
export class TAKUPayoutsReportLocalDataService extends LocalDataService {
  constructor(protected http: HttpClient, protected authService: AuthService) {
    super(http, authService);
  }

  getTAKUPayoutsReport(takuPaymentAccountId: number, dateFrom: Date | string, dateTo: Date | string) {
    const start_date = moment(dateFrom).format("YYYY-MM-DD") + "T00:00:00.000Z";
    const end_date = moment(dateTo).format("YYYY-MM-DD") + "T00:00:00.000Z";
    const queryParams = {
      start_date,
      end_date,
    };

    return this._getRequest<TAKUPayoutsReport[]>(this.webUrl + "takuPay/Payouts/" + takuPaymentAccountId, queryParams);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
