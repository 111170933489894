/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Location } from "@angular/common";
import { Component, EventEmitter, Output } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { BehaviorSubject, Subscription } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { AppComponent } from "src/app/app.component";
import { DBMode, GenericFormComponent } from "../../../forms/generic-form/generic-form.component";
import { AppSettingsStorageService } from "../../../shared/app-settings-storage.service";
import { AlertMessagesService } from "../../../shared/services/alert-messages.service";
import { DBService } from "../../../shared/services/db.service";
import { Tax, TaxLocationBasis, TaxPriceBasis, TaxTotal } from "./tax";
import { TaxService } from "./tax.service";

@Component({
  selector: "taku-tax",
  templateUrl: "./tax.component.html",
  styleUrls: ["./tax.component.scss"],
})
export class TaxComponent extends GenericFormComponent {
  @Output() dialogClosed = new EventEmitter<any>();
  enumTaxBase = this.dbService.enumSelectOptions(TaxPriceBasis);
  enumTaxTotal = this.dbService.enumSelectOptions(TaxTotal);
  enumLocationBasis = this.dbService.enumSelectOptions(TaxLocationBasis);
  _compoundSeqStatus$ = new BehaviorSubject({
    isDuplicated: false,
  });

  readonly _disabledFieldsInEditMode = ["taxTotal", "isFlatTax", "isTaxIncluded"];
  private subscriptionCheckCompoundSeq: Subscription;

  closeSidebar() {
    this.dialogClosed.emit();
  }

  initForm() {
    this._model = "tax";
    this._object = new Tax();
    this._myForm = this.fb.group(new Tax());
  }

  constructor(
    public taxService: TaxService,
    protected _router: Router,
    public fb: UntypedFormBuilder,
    public dbService: DBService,
    protected location: Location,
    public _route: ActivatedRoute,
    protected messageService: MessageService,
    protected alertMessage: AlertMessagesService,
    protected confirmationService: ConfirmationService,
    appSettings: AppSettingsStorageService
  ) {
    super(_router, fb, dbService, location, _route, messageService, alertMessage, confirmationService, appSettings);
  }

  ngOnInit() {
    super.ngOnInit();
    this._setupControls();
    this.subsList.push(this.changedForm.subscribe(() => this._disableCtrlsInEditMode()));
    this.taxService.taxTotalValidationHandler.setupControl(this.taxTotalCtrl, this._myForm);
  }

  private _setupControls() {
    this._setupCompoundSettings();
    this._setupTaxTotal();
    this._disableCtrlsInEditMode();
  }

  private _disableCtrlsInEditMode(): any {
    // if (this.dbMode === DBMode.insert) { // If in insert mode, do nothing
    //   return;
    // }
    // for (const fieldName of this._disabledFieldsInEditMode) {
    //   this._myForm.get(fieldName).disable();
    // }
  }

  private _setupTaxTotal(): any {
    const taxTotalCtrl = this._myForm.get("taxTotal") as UntypedFormControl;
    const dependentFields = ["isCompound", "taxPriceBasis"];
    const depFieldCtrls: AbstractControl[] = [];
    for (const fieldName of dependentFields) {
      depFieldCtrls.push(this._myForm.get(fieldName));
    }

    const changeDepFields = (taxTotalValue) => {
      // if byTotal disable and clear depdent fields
      if (taxTotalValue === TaxTotal.ByTotal) {
        depFieldCtrls.forEach((ctrl) => {
          ctrl.reset();
          ctrl.disable();
        });
      } else {
        // Reenable any previously disable fields
        depFieldCtrls.forEach((ctrl) => ctrl.enable());
      }
    };

    changeDepFields(taxTotalCtrl.value);
    this.subsList.push(taxTotalCtrl.valueChanges.pipe(distinctUntilChanged()).subscribe(changeDepFields));
  }

  get ctrlcompundCheckbox() {
    return this._myForm.get("isCompound");
  }

  get ctrlCompoundSeq() {
    return this._myForm.get("compoundSeq");
  }

  get taxTotalCtrl() {
    return this._myForm.get("taxTotal");
  }

  private _setupCompoundSettings() {
    // Change default control's behaviour
    const setCompoundSeqStatus = (val) => {
      if (val) {
        this.ctrlCompoundSeq.enable();
      } else {
        this.ctrlCompoundSeq.setValue(0);
        this.ctrlCompoundSeq.disable();
      }
    };

    setCompoundSeqStatus(this.ctrlcompundCheckbox.value);
    this.subsList.push(
      this.ctrlcompundCheckbox.valueChanges.pipe(distinctUntilChanged()).subscribe(setCompoundSeqStatus)
    );

    this.subsList.push(
      this.ctrlCompoundSeq.valueChanges.subscribe((compoundSeqVal) => {
        const filter = {
          compoundSeq: {
            matchMode: "equals",
            value: compoundSeqVal,
          },
        };
        if (this._id)
          // if its an existing tax in DB, exclude it from results
          filter["id"] = {
            matchMode: "ne",
            value: this._id,
          };

        // Prevent the subcription to throw previous values AFTER the current one
        if (this.subscriptionCheckCompoundSeq) this.subscriptionCheckCompoundSeq.unsubscribe();
        // verify if sequence number has been used in another tax records
        this.subscriptionCheckCompoundSeq = this.dbService
          .getRows("tax", JSON.stringify(filter), 0, 1)
          .subscribe((response) => {
            // Emit new status
            if (response.rows.length)
              // if the row has same sequence means it's duplicated
              this._compoundSeqStatus$.next({
                isDuplicated: true,
              });
            else
              this._compoundSeqStatus$.next({
                isDuplicated: false,
              });
          });
      })
    );
  }

  initValidation() {
    this._validation = this.taxService.getValidationRules();
  }

  onSave() {
    if (this.dbMode !== DBMode.insert && this._myForm.get("shortDesc").dirty) {
      this.confirmationService.confirm({
        header: "Warning",
        message: `Please note that you are trying to change a tax that has been used in the past. This will change any historical documents or reports that have used this tax.

        Would you still like to proceed?`,
        accept: () => {
          super.onSave();
        },
      });
    } else {
      super.onSave();
    }
  }

  ngOnDestroy() {
    if (this.subscriptionCheckCompoundSeq) this.subscriptionCheckCompoundSeq.unsubscribe();
    super.ngOnDestroy();
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
