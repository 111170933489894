<p-panel class="panel-pagetitle">
  <ng-template pTemplate="header">
    <div class="grid col-12">
      <div class="col-12 sm:col-8">
        <h1> <i class="{{ pageIconStyleClass }}"></i> <span [innerHTML]="pageTitle"></span> </h1>
      </div>
      <div class="col-12 sm:col-4">
        <ng-content select="*.panel-toolbar"></ng-content>
      </div>
    </div>
  </ng-template>

  <div class="col-12">
    <div class="col-12 cards-container">
      <div class="col-12 card-section" *ngFor="let cardsSection of cardsSections">
        <h2 *ngIf="cardsSection.label" class="col-12">{{ cardsSection.label }}</h2>
        <p-card
          *ngFor="let card of cardsSection.cards"
          class="col-12 md:col-4 lg:col-3 xl:col-2 nav-item"
          [ngClass]="{ disableCard: card.isEnabled != undefined && !card.isEnabled }"
          id="business_settings_{{ card.id | lowercase }}"
        >
          <a
            [routerLink]="card.routePath"
            (click)="card.openAction ? card.openAction.emit(card.channelType) : null"
            [queryParams]="card.routeQueryParams"
            router
            routerLinkActive="router-link-active"
          >
            <div class="top">
              <div class="icon-container">
                <i [class]="card.iconStyleClass">{{ card.iconName }}</i>
              </div>
              <h3 [innerHTML]="card.cardTitle"></h3>
            </div>

            <div class="description">
              <ul>
                <li *ngFor="let descLine of card.cardDesc"
                  ><span>{{ descLine }}</span></li
                >
              </ul>
            </div>

            <div *ngIf="isAppsCard" class="connection-status">
              <span *ngIf="card.isConnected && card.isEnabled" class="connected-label">
                <span class="material-icons">check_circle</span> Connected
              </span>
              <span *ngIf="!card.isConnected || !card.isEnabled" class="not-connected-label">
                <!-- COMING SOON -->
              </span>
            </div>
          </a>
        </p-card>
      </div>
      <!-- <p-card class="col-2 nav-item">
        <div class="top">
          <div class="icon-container">
            <i class="ui-icon-business"></i>
          </div>
          <h3>Business Details</h3>
        </div>

        <div class="description">
          <p>Brief description of settings page
              Lorem ipsum dolor, sit amet lorem
              more content goes here</p>
        </div>
      </p-card> -->
    </div>
  </div>
</p-panel>
