<taku-return-doc-mobile
  #saleDocComponent
  (inventoryItemSaved)="onInventoryItemSaved($event)"
  (noReceiptItem)="onReturnNoReceiptItem()"
  (searchReceiptsOpened)="searchReceiptsOpened()"
  (tenderScreenOpened)="openTenderScreen($event)"
  (onDocLinesDeleted)="onDocLinesDeleted($event)"
  (docLineQtyChanged)="docLineQtyChanged($event)"
  (onDocReset)="onDocReset($event)"
>
  <ng-template
    #saleDocHeaderDialog
    let-saleForm
    let-station="saleStation"
    let-account="saleAccount"
    let-cashout="cashout"
  >
    <taku-sale-invoice-doc-details [saleForm]="saleForm" [station]="station" [account]="account" [cashout]="cashout">
    </taku-sale-invoice-doc-details>
  </ng-template>

  <ng-template
    #saleDocHeader
    let-saleForm
    let-station="saleStation"
    let-user="documentUser"
    let-account="saleAccount"
    let-cashout="cashout"
  >
    <div id="invoiceHeader" class="card col-12 p-0">
      <div id="doc-main-heading" class="col-12">
        <div class="invoiceDetails-btn-wrapper">
          <i class="material-icons" (click)="docComponent.openDocDetailsDialog()">more_vert</i>
        </div>

        <div class="doc-type-block">
          <div class="doc-type-wrapper">
            <span class="doc-type cursor-pointer flex align-items-center" (click)="switchDocMenu.toggle($event)">
              RETURNS
              <i class="switchdoc-dropdown pi pi-caret-down"></i>
            </span>
            <p-menu
              #switchDocMenu
              [style]="{ fontWeight: 700 }"
              [model]="docTypesMenu"
              [popup]="true"
              appendTo="body"
            ></p-menu>
          </div>
        </div>
      </div>

      <div id="top-info-cards" class="col-12">
        <div class="grid pl-2">
          <div class="col-9 doc-info-box account-info">
            <dl>
              <dt>Account:</dt>
              <dd>{{ account ? account.headline : "-" }}</dd>
              <a
                *ngIf="account"
                class="pi pi-times icon-clear-account"
                href="javascript://"
                (click)="onClearAccBtnPressed()"
              ></a>
            </dl>
          </div>
          <div class="grid col-3 doc-info-box">
            <dl>
              <dt>Station#:</dt>
              <dd>{{ station.stationNumber }}</dd>
            </dl>
          </div>
        </div>
      </div>
      <!-- Search boxes (Inventory and Account) -->
      <div class="col-12" id="searchFields-wrapper">
        <div class="grid col-12 customer-search-wrapper">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="material-icons">group</i></span>
            <taku-search-accounts
              id="account_search"
              #accountsSearchBox
              placeholder="Search for Account"
              (itemSelected)="onSearchResAccount($event)"
              (onNewItemClicked)="openNewModelInDialog($event)"
              [showAnimatePlaceholder]="false"
            >
            </taku-search-accounts>
          </div>
          <p-splitButton
            class="btnNewAccounts"
            #btnNewAccounts
            label=""
            icon="pi pi-plus"
            (onClick)="openAddNewAccountDialog($event)"
            [model]="newAccountMenu"
            appendTo="body"
            *ngIf="isAddPersonalAccountVisible || isAddCommercialAccountVisible"
            [disabled]="!isAddPersonalAccountEnabled && !isAddCommercialAccountEnabled"
          ></p-splitButton>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #saleDocFooter>
    <!-- <div class="col-12    toolbar-bottom">
        <p-tabMenu id="sales-mainMenu" [model]="mainMenuItems" styleClass="sales-tabsBtns"></p-tabMenu>
    </div> -->
  </ng-template>

  <ng-template
    #extraModalViews
    let-dialogName
    let-dialogExtra="dialogExtra"
    let-saleForm="saleForm"
    let-station="saleStation"
    let-user="documentUser"
    let-account="saleAccount"
    let-invoiceSettings="invoiceSettings"
    let-saleTotal="saleTotal"
  >
    <ng-template [ngIf]="dialogName !== SideDialogName.RETURN_ITEM_SEARCH_FORM">
      <span [ngSwitch]="dialogName">
        <div *ngSwitchCase="SideDialogName.TENDER_SCREEN" class="tender-screen">
          <taku-tender-screen
            *ngIf="dialogExtra.zone"
            [formGroup]="saleForm"
            [docAccount]="account"
            [amountToPay]="-1 * saleTotal"
            [zoneSettings]="dialogExtra.zone"
            [storePolicies]="dialogExtra.storePolicies"
            (changesAccepted)="closeRightDialog(); docComponent.onTendersProcessed($event)"
            (onDocTenderRefDialog)="docComponent.openTenderRefNoDialog($event)"
            [_mode]="TenderScreenMode.RETURNS"
          ></taku-tender-screen>
        </div>

        <div *ngSwitchCase="SideDialogName.INVENTORY_DETAILS" class="inventory-details-panel">
          <taku-inventory-doc-preview
            [inventoryItem]="dialogExtra"
            (onBack)="openRightDialog($any('search'))"
            (onOpenEditForm)="docComponent.openInventoryEditForm($event)"
          ></taku-inventory-doc-preview>
        </div>

        <div *ngSwitchCase="SideDialogName.RETURN_ITEM_NOINVOICE" class="return-item-panel">
          <taku-return-item (onItemReturned)="onItemReturned($event)" (onNewInventory)="openNewModelInDialog($event)">
          </taku-return-item>
        </div>

        <div *ngSwitchCase="SideDialogName.RETURN_ITEM_SEARCH_RESULTS" class="returnItem-searchresults">
          <taku-form-header
            toolbarTitle="Search Results"
            displayMode="inline"
            [showActionBtns]="false"
            [showBackBtn]="false"
          ></taku-form-header>
          <taku-form-list
            [_rowSelectionType]="RowSelectionType.NONE"
            [_showHeader]="false"
            [_extraQueryParams]="_returnsSearchExtraParams"
            [_filter]="dialogExtra ? dialogExtra : undefined"
            _model="saleDoc"
            _detailMode="n"
            _scrollHeight="full"
            [_cols]="returnDocsColumns"
            [_saveFormState]="false"
          >
          </taku-form-list>
        </div>
      </span>
    </ng-template>
    <!-- End of NgSwitch -->

    <div [hidden]="dialogName !== SideDialogName.RETURN_ITEM_SEARCH_FORM" class="returnItem-searchform">
      <taku-document-search-return
        [inputSaleDoc]="docForReturnPreview"
        [allowNoInvoiceReturns]="docComponent.canNoInvoiceReturns"
        [docAccount]="account"
        (singleQtyReturned)="docComponent.addLineForReturn($event)"
        (entireDocReturned)="docComponent.setReturnedDoc($event)"
        (multipleResultsReturned)="openFormListForReturnSearch($event)"
        (noReceiptItem)="onReturnNoReceiptItem()"
      ></taku-document-search-return>
    </div>
  </ng-template>

  <ng-template
    #saleDocRightSide
    let-saleForm
    let-station="saleStation"
    let-user="documentUser"
    let-account="saleAccount"
    let-invoiceSettings="invoiceSettings"
    let-saleTotal="saleTotal"
  >
  </ng-template>
</taku-return-doc-mobile>
