/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Input } from "@angular/core";
import * as moment from "moment";
import { AutocompleteDatatypeOptions } from "src/app/form-list/form-list/form-list";
import { UntypedFormGroup } from "@angular/forms";
import { TimeZoneAutocompleteTransformer } from "./time-zone-autocomplete-transformer";
import { of, Observable } from "rxjs";

@Component({
  selector: "taku-time-zone-autocomplete-input",
  templateUrl: "./time-zone-autocomplete-input.component.html",
  styleUrls: ["./time-zone-autocomplete-input.component.scss"],
})
export class TimeZoneAutocompleteInputComponent {
  @Input() formGroup?: UntypedFormGroup;
  @Input() fieldName?: string;
  @Input() isDropDown = false;

  // list of all time zones from the moment timezone library
  timeZones: string[] = [];
  // options used for the autocomplete component.
  timeZoneAutoCompleteOptions: AutocompleteDatatypeOptions;

  constructor() {
    // get all timezone names
    this.timeZones = moment.tz.names();

    this.timeZoneAutoCompleteOptions = {
      completeMethod: this.searchTimeZone.bind(this), // This method is used to return options based on the user's query
      isMultiple: false,
      transformer: new TimeZoneAutocompleteTransformer(), // Transforms result values into SelectItems and vice versa.
    };
  }

  searchTimeZone(query: string): Observable<string[]> {
    // return all times zones that contain the query as a substring.
    return of(
      this.timeZones.filter((timeZone) => {
        return timeZone.toLocaleLowerCase().includes(query.toLocaleLowerCase());
      })
    );
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
