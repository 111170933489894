<taku-form-header
  displayMode="full"
  *ngIf="_pageTitle || _pageIconStyleClass"
  (backClicked)="goBack()"
  [toolbarTitle]="_pageTitle"
  [iconStyleClass]="_pageIconStyleClass"
  [iconName]="_pageIconName"
  [showActionBtns]="false"
  [showBackBtn]="!_disableBackLink"
  [extraButtonIcon]="extraButtonIconClass"
  (extraButtonClicked)="emitExtraButtonClicked($event)"
></taku-form-header>

<div class="paneltitle-right">
  <ng-content select="*.panel-toolbar"></ng-content>
</div>

<taku-form-list
  #formList
  [ngClass]="{ pullDown: _pageTitle || _pageIconStyleClass }"
  [_model]="_model"
  [_detailMode]="_detailMode"
  [_filter]="activeFilter"
  [_cols]="_cols"
  [_title]="_formlistTitle"
  [_settingsCol]="_settingsCol"
  [_defaultRowValues]="_defaultRowValues"
  [_extraQueryParams]="_extraQueryParams"
  [_hideAddLineHeaderBtn]="_disableAddRow"
  [_onCustomizeFetchFilter]="_changeFormListFilter"
  [_rowSelectionType]="_rowSelectionType"
  [defaultSortField]="_sortField"
  [_headerInfo]="_headerInfo"
  [_dialogShowHeader]="false"
  _dialogStyleClass="form-list-dialog-style"
>
  <div takuFormListHeaderCustomBtns>
    <button
      id="print_label"
      pButton
      pTooltip="Print Label"
      tooltipPosition="left"
      class="header-button p-button-rounded"
      (click)="onPrintLabel()"
      icon="pi pi-tag"
    >
    </button>
  </div>
</taku-form-list>
