import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Inventory } from "src/app/core/inventory/inventory/inventory";
import { SaleDocSelfCheckoutMobileComponent } from "../sale-doc-self-checkout-mobile/sale-doc-self-checkout-mobile.component";
import { SaleDocSelfCheckoutWrapperBaseComponent } from "../sale-doc-self-checkout-wrapper-base/sale-doc-self-checkout-wrapper-base.component";
import { SelfCheckoutItemDetailsComponent } from "../self-checkout-item-details/self-checkout-item-details.component";
@Component({
  selector: "taku-sale-doc-self-checkout-wrapper-mobile",
  templateUrl: "./sale-doc-self-checkout-wrapper-mobile.component.html",
  styleUrls: ["./sale-doc-self-checkout-wrapper-mobile.component.scss"],
})
export class SaleDocSelfCheckoutWrapperMobileComponent extends SaleDocSelfCheckoutWrapperBaseComponent {
  @Input() dvWidth: number;
  @Input() dvHeight: number;

  onInventoryItemSelected(item: Inventory) {
    // e.stopPropagation();

    this.docComponent.addToSaleDoc(item);

    // finding the index of the item in cart
    let itemIndexInCart = 0;
    this.docComponent.saleDocLines.value.forEach((element, index) => {
      if (element.docLine.inventoryId === item.id) {
        itemIndexInCart = index;
      }
    });

    const docLineForm = this.docComponent.saleDocLines.at(itemIndexInCart);
    this.openProductDetailsDlg(docLineForm as UntypedFormGroup, itemIndexInCart);
  }

  openProductDetailsDlg(saleForm: UntypedFormGroup, itemIndexInCart?: number) {
    const originalQty = saleForm.get("qty").value;

    this.ref = this.dialogService.open(SelfCheckoutItemDetailsComponent, {
      styleClass: "selfcheckout-mobile rounded-selfcheckout-dialog",
      contentStyle: {
        overflow: "auto",
        height: "60%",
        minWidth: "280px",
        maxWidth: "680px",
        maxHeight: "1100px",
      },
      data: {
        _saleForm: saleForm,
        _isAddToCart: true,
      },
    });

    this.subsList.push(
      this.ref.onClose.subscribe((onMobileAddToCart) => {
        let newQty = 0;
        if (onMobileAddToCart === undefined) {
          newQty = originalQty - 1;
        } else {
          newQty = originalQty + onMobileAddToCart.qtyToAdd;
        }

        if (newQty !== 0) {
          saleForm.patchValue({ qty: newQty });
        } else {
          this.docComponent.deleteInvoiceLine(itemIndexInCart);
        }
      })
    );
  }

  onOpenShoppingCart() {
    const mobileDocComponent = this.docComponent as SaleDocSelfCheckoutMobileComponent;
    mobileDocComponent.openShoppingCart();
  }

  onGoingBackToSearchScreen() {
    this._showInventoryItems = false;
  }

  get dvWidthAfterOffset(): number {
    return this.dvWidth - 20;
  }

  get dvHeightAfterOffset(): number {
    return this.dvHeight - Math.floor(this.dvHeight * 0.6);
  }

  ngOnDestroy(): void {
    this.ref?.close(false);
    super.ngOnDestroy();
  }
}
