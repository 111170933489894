/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Location } from "@angular/common";
import { Component, Output, EventEmitter, ViewChild } from "@angular/core";
import { AbstractControl, UntypedFormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { GenericFormComponent } from "../../../forms/generic-form/generic-form.component";
import { AlertMessagesService } from "../../../shared/services/alert-messages.service";
import { DBService } from "../../../shared/services/db.service";
import { Category } from "./category";
import { InventoryCategoryService } from "./inventory-category.service";
import { AppSettingsStorageService } from "../../../shared/app-settings-storage.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { AppComponent } from "src/app/app.component";
import { FileUpload } from "primeng/fileupload";

@Component({
  selector: "taku-category",
  templateUrl: "./category.component.html",
  styleUrls: ["./category.component.scss"],
})
export class CategoryComponent extends GenericFormComponent {
  @Output() dialogClosed = new EventEmitter<any>();
  @ViewChild("fileUploader") _fileUploader: FileUpload;

  initForm() {
    this._model = "category";
    this._object = new Category();
    this._myForm = this.fb.group(new Category());
  }

  constructor(
    public categoryService: InventoryCategoryService,
    protected _router: Router,
    public fb: UntypedFormBuilder,
    public dbService: DBService,
    protected location: Location,
    public _route: ActivatedRoute,
    protected messageService: MessageService,
    protected alertMessage: AlertMessagesService,
    protected confirmationService: ConfirmationService,
    appSettings: AppSettingsStorageService,
    private httpClient: HttpClient
  ) {
    super(_router, fb, dbService, location, _route, messageService, alertMessage, confirmationService, appSettings);
  }

  initValidation() {
    this._validation = this.categoryService.getValidationRules();
  }

  closeModal() {
    this.dialogClosed.emit();
  }

  clearImage() {
    this.imageUrlCtrl.setValue(null);
    this.urlImageUrlCtrl.setValue(null);
    this.imageUrlCtrl.markAsDirty();
  }

  myUploader(event: { files: any[] }) {
    // get signedUrl
    event.files.map((_file) => {
      if (_file.size <= 2 * 1024 * 1024) {
        this.dbService._getRequest<any>(environment.apiUrl + "/uploadURL/" + _file.name).subscribe(
          (response: any) => {
            this.subsList.push(
              this.httpClient.put(response.url, _file).subscribe(
                (res) => {
                  this.imageUrlCtrl.setValue(response.fileName);
                  this.urlImageUrlCtrl.setValue(response.bucketPath + "/" + response.fileName);
                  this.imageUrlCtrl.markAsDirty();
                },
                (err) => console.log(err)
              )
            );
          },
          (err) => console.log(err)
        );
      } else {
        this.messageService.add(this.alertMessage.getMessage("max-upload-size-warning"));
        this._fileUploader.clear();
      }
    });
  }

  get fileNameImageUrl() {
    return this._myForm.value.urlImageFileName;
  }

  get imageUrlCtrl(): AbstractControl {
    return this._myForm.get("imageFileName");
  }

  get urlImageUrlCtrl(): AbstractControl {
    return this._myForm.get("urlImageFileName");
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
