import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { TakuInputComponent } from "./taku-input.component";
import { TakuInputMaskModule } from "../taku-inputmask/taku-inputmask.module";
import { KeyFilterModule } from "primeng/keyfilter";
import { FormValidationMessageModule } from "src/app/forms/form-validation-messages/form-validation-message.module";
import { InputTextModule } from "primeng/inputtext";

@NgModule({
  declarations: [TakuInputComponent],
  exports: [TakuInputComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TakuInputMaskModule,
    InputTextModule,
    KeyFilterModule,
    FormValidationMessageModule,
  ],
})
export class TakuInputModule {}
