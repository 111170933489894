/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { DBService } from "../../../shared/services/db.service";
import { map, mergeMap } from "rxjs/operators";
import { AuthService } from "src/app/shared/services/auth.service";
import { SettingCardInfo } from "../settings-cards/setting-cards.component";
import { Subscription } from "rxjs";

@Component({
  selector: "taku-zone-settings-cards",
  templateUrl: "zone-settings-cards.component.html",
  styleUrls: ["../settings-cards/settings-cards.component.scss"],
})
export class ZoneSettingsCardsComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];

  readonly _pageTitlePrefix = "Current Zone Settings";
  pageTitle = this._pageTitlePrefix;
  pageIconStyleClass = "takuicon-create-region-settings";
  cardsInfo: SettingCardInfo[] = [];
  activeZoneId = 0;
  takuPaymentAccountExist = false;

  zoneChanged(zoneId) {
    this._router.navigate(["../", zoneId], { relativeTo: this._route });
  }

  updatePageTitle(zoneId: number) {
    this.subsList.push(
      this.dbService.getRow("zone", zoneId).subscribe((zone) => {
        this.pageTitle = `${this._pageTitlePrefix}&nbsp;<span class="secondary-text">(${zone.zoneName})</span>`;
      })
    );
  }

  updateCardsInfo(zoneId) {
    this.cardsInfo = [
      {
        routePath: ["/settings/zones/current-zone/denominations/model-list/n/cashDenomination", zoneId],
        routeQueryParams: { title: "Monetary Denominations" },
        iconStyleClass: "takuicon-money",
        cardTitle: "Denominations",
        cardDesc: ["Set Up Cash or other Monetary Denominations", "(e.g. $1, $2, $5...)"],
        isVisible: this.authService.hasVisiblePermit("Settings_Menu_Zones_Current_Zone_Denominations"),
        isEnabled: this.authService.hasEnablePermit("Settings_Menu_Zones_Current_Zone_Denominations"),
      },
      {
        routePath: ["/settings/zones/current-zone/tenders/model-list/n/tenderType", zoneId],
        routeQueryParams: { title: "Tenders" },
        iconStyleClass: "pi pi-credit-card",
        cardTitle: "Tender Types",
        cardDesc: ["Set Up Tender Types for Transactions", "(e.g. cash, credit...)"],
        isVisible: this.authService.hasVisiblePermit("Settings_Menu_Zones_Current_Zone_Tender_Types"),
        isEnabled: this.authService.hasEnablePermit("Settings_Menu_Zones_Current_Zone_Tender_Types"),
      },
      {
        routePath: ["/settings/zones/current-zone/shippers/model-list/s/shipper", zoneId],
        routeQueryParams: { title: "Shippers" },
        iconStyleClass: "material-icons",
        iconName: "local_shipping",
        cardTitle: "Shippers",
        cardDesc: ["Set Up Zone Shippers", "(e.g. West Coast, Central...)"],
        isVisible: this.authService.hasVisiblePermit("Settings_Menu_Zones_Current_Zone_Shippers"),
        isEnabled: this.authService.hasEnablePermit("Settings_Menu_Zones_Current_Zone_Shippers"),
      },
      {
        routePath: ["/settings/zones/current-zone/stock/model-list/s/stock", zoneId],
        routeQueryParams: { title: "Stock" },
        iconStyleClass: "takuicon-stock-settings",
        cardTitle: "Stock",
        cardDesc: ["Set Up Multiple Inventory Stock", "(i.e. separate stock allocations)"],
        isVisible: this.authService.hasVisiblePermit("Settings_Menu_Zones_Current_Zone_Stock"),
        isEnabled: this.authService.hasEnablePermit("Settings_Menu_Zones_Current_Zone_Stock"),
      },
      {
        routePath: ["/settings/zones/current-zone/inventory-formats/model-list/n/inventoryDocFormat", zoneId],
        iconStyleClass: "material-icons",
        iconName: "text_fields",
        cardTitle: "Inventory Doc Formats",
        cardDesc: [
          "Inventory Adjustment Doc",
          "Inventory Transfer In Doc",
          "Stock Receiving Doc",
          "Purchase Order Doc",
          "Inventory Transfer Out",
        ],
        isVisible: this.authService.hasVisiblePermit("Settings_Menu_Zones_Current_Zone_Inventory_Doc_Formats"),
        isEnabled: this.authService.hasEnablePermit("Settings_Menu_Zones_Current_Zone_Inventory_Doc_Formats"),
      },
      {
        routePath: ["/settings/zones/current-zone/recurring-orders/model-list/n/recurringOrderSetting", zoneId],
        iconStyleClass: "material-icons",
        iconName: "event_repeat",
        cardTitle: "Recurring Order Setup",
        cardDesc: [
          "Create Recurring Frequencies",
          "Add Recurring Order Discounts",
          "Create Cancellation Rules",
          "Apply to Stores",
        ],
        isVisible: this.authService.hasVisiblePermit("Settings_Menu_Zones_Current_Zone_Recurring_Order_Setup"),
        isEnabled: this.authService.hasEnablePermit("Settings_Menu_Zones_Current_Zone_Recurring_Order_Setup"),
      },
      {
        routePath: ["/settings/zones/current-zone/mail-settings", zoneId],
        iconStyleClass: "material-icons",
        iconName: "mail",
        cardTitle: "Email Settings",
        cardDesc: ["Set Up SMTP Server Options for Outgoing Purchasing Emails", "Enable Encryption"],
        isVisible: this.authService.hasVisiblePermit("Settings_Menu_Zones_Manage_Zones"),
        isEnabled: this.authService.hasEnablePermit("Settings_Menu_Zones_Manage_Zones"),
      },
    ];
  }
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private dbService: DBService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    const activeFilter = {
      isActive: { matchMode: "equals", value: true },
    };
    this.subsList.push(
      this.dbService
        .getRows("takuPaymentAccount", JSON.stringify(activeFilter))
        .pipe(
          map((result) => {
            if (result.count > 0) {
              this.takuPaymentAccountExist = true;
            }
          }),
          mergeMap(() => this._route.params.pipe(map((params: Params) => +params.zoneId)))
        )
        .subscribe((zoneId) => {
          // this.cardsInfo = [...this.cardsInfo];
          this.updateCardsInfo(zoneId);
          this.updatePageTitle(zoneId);
          this.activeZoneId = zoneId;
        })
    );
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
