/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { formatDate } from "@angular/common";
import {
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
  OnDestroy,
  SimpleChanges,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import * as moment from "moment";
import * as _ from "lodash";
import { MenuItem, SelectItem } from "primeng/api";
import { Col, RowSelectionEvent, RowSelectionType, FilterType } from "../../../../form-list/form-list/form-list";
import { FormListComponent } from "../../../../form-list/form-list/form-list.component";
import { AppSettingsStorageService } from "../../../../shared/app-settings-storage.service";
import {
  PrintingFactoryService,
  PrintingMgr,
  ReceiptPrintingSize,
} from "../../../../shared/services/printing-service.service";
import { SearchResultItem } from "../../../../taku-ui/taku-search-accounts/SearchResultItem";
import { TakuSearchAccountsComponent } from "../../../../taku-ui/taku-search-accounts/taku-search-accounts.component";
import { WebHelpers } from "../../../../utility/WebHelpers";
import { DocState } from "../../doc/doc";
import { SaleDoc } from "../sale-doc/sale-doc";
import { SaleDocService } from "../sale-doc/sale-doc.service";
import { SaledocVoidDialogComponent } from "../saledoc-void-dialog/saledoc-void-dialog.component";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { Observable, of, Subscription } from "rxjs";
import { RevisitSaleService } from "./revisit-sale.service";
import { FormDialogInputComponent } from "src/app/forms/form-dialog-input/form-dialog-input.component";
import { GiftReceiptTapePreviewComponent } from "src/app/core/previews/receipt/gift-receipt-tape-preview/gift-receipt-tape-preview.component";
import { MonerisService } from "src/app/core/settings/integration-settings/moneris-terminal/moneris.service";
import { map, shareReplay } from "rxjs/operators";
import { TakuPayService } from "src/app/core/settings/integration-settings/taku-pay/taku-pay.service";
import { PrintHelpers } from "src/app/utility/PrintHelpers";

type RevisitSaleState = {
  searchFormValue: any;
  headerFormValue: any;
  accountName: string;
  formListVisible: boolean;
  formListFilter: any;
};

@Component({
  selector: "taku-revisit-sale",
  templateUrl: "./revisit-sale.component.html",
  styleUrls: ["./revisit-sale.component.scss"],
  providers: [DialogService],
})
export class RevisitSaleComponent implements OnInit, OnChanges, OnDestroy {
  private static readonly DATE_FORMAT = "yyyy-MM-dd";
  subsList: Subscription[] = [];
  _isDataReady = false;
  @HostBinding("class.full-size")
  @Input()
  fullSizeMode = true;
  @Input() enableFullScreen = false;
  @Input() componentState?: RevisitSaleState;
  @Input() preloadData = true;
  @Input() shortenFormHeaderHeight = false;
  @Output() onFullScreen: EventEmitter<RevisitSaleState> = new EventEmitter();
  @Input() inRightSideOfSaleDoc = false;

  @ViewChild("formList") formListComponent: FormListComponent;
  @ViewChild("printPreviewHost", { read: ViewContainerRef, static: true }) printPreviewHost: ViewContainerRef;
  @ViewChild("searchAccountsComp", { static: true }) _searchAccountsComp: TakuSearchAccountsComponent;

  progressBar: DynamicDialogRef;
  lookup_stores$: Observable<SelectItem[]> = of([]);
  isAdmin: boolean;
  hasTakuPaymentTerminal: boolean;

  _revisitSaleDocExtraParams = {
    includes: "saleDocTender",
  };

  getPrintItems(rowData: SaleDoc): MenuItem[] {
    return [
      {
        label: "Receipt (Tape)",
        command: () => {
          this.onPrintPressed(rowData, ReceiptPrintingSize.TAPE_SIZE);
        },
        icon: "takuicon-measuring-tape",
      },
      {
        label: "Receipt (Letter)",
        command: () => {
          this.onPrintPressed(rowData, ReceiptPrintingSize.LETTER_SIZE);
        },
        icon: "takuicon-invoice",
      },
      {
        label: "Card Receipt",
        command: () => {
          this.onReceiptCardPrintPressed(rowData);
        },
        icon: "text-3xl pi pi-credit-card",
      },
      {
        label: "Gift Receipt",
        command: () => {
          this.onReprintGiftReceipt(rowData);
        },
        icon: "takuicon-gift",
      },
      {
        label: "Packing List (Tape)",
        command: () => {
          this.onPrintPackingList(rowData, ReceiptPrintingSize.TAPE_SIZE);
        },
        icon: "takuicon-measuring-tape",
      },
      {
        label: "Packing List (Letter)",
        command: () => {
          this.onPrintPackingList(rowData, ReceiptPrintingSize.LETTER_SIZE);
        },
        icon: "takuicon-invoice",
      },
      {
        label: "Prep Order (Tape)",
        command: () => {
          this.onPrintPrep(rowData);
        },
        icon: "takuicon-measuring-tape",
        disabled: !this.appSettingsService.getStation()?.prepPrinter,
      },
    ];
  }

  emailReceiptSizesMenuItems: MenuItem[] = [
    {
      label: ReceiptPrintingSize.TAPE_SIZE,
      command: () => {
        this.onEmailDoc(ReceiptPrintingSize.TAPE_SIZE);
      },
      icon: "takuicon-measuring-tape",
    },
    {
      label: ReceiptPrintingSize.LETTER_SIZE,
      command: () => {
        this.onEmailDoc(ReceiptPrintingSize.LETTER_SIZE);
      },
      icon: "takuicon-invoice",
    },
  ];

  saleDocColumns: Col[] = [];
  formGroup: UntypedFormGroup;
  headerFormGroup: UntypedFormGroup;
  formFilter: {} = null;
  printMgr: PrintingMgr;
  showFormList: boolean;
  lastSelectedDoc: SaleDoc;
  RowSelectionType = RowSelectionType;
  activeAccountName: string;
  voidDocumentEvent = new EventEmitter<SaleDoc>();
  printDocumentEvent = new EventEmitter<SaleDoc>();
  emailDocumentEvent = new EventEmitter<SaleDoc>();

  docTypeOptions;

  constructor(
    private dialogService: DialogService,
    private saleDocService: SaleDocService,
    private fb: UntypedFormBuilder,
    @Inject(LOCALE_ID) private defaultLocale: string,
    private printingFactory: PrintingFactoryService,
    private appSettingService: AppSettingsStorageService,
    private revisitSaleService: RevisitSaleService,
    private appSettingsService: AppSettingsStorageService,
    public monerisService: MonerisService,
    public takuPayService: TakuPayService
  ) {
    this.subsList.push(
      this.printDocumentEvent.subscribe((saleDoc: SaleDoc) => {
        this.onPrintPressed(saleDoc, ReceiptPrintingSize.TAPE_SIZE);
        this.subsList.push(
          this.revisitSaleService.getRow("saleDoc", saleDoc.id).subscribe((data) => {
            const saleDoc = SaleDoc.convertIntoTypedObject(data);
            if (PrintHelpers.isTakuPayTransaction(saleDoc)) {
              Object.assign(saleDoc, { isMerchantReceipt: false }, { isCustomerReceipt: true });
              this.printMgr.printDocumentsWithSize([saleDoc], this.printPreviewHost, ReceiptPrintingSize.TAPE_SIZE);
            } else {
              this.printMgr.printDocumentsWithSize([saleDoc], this.printPreviewHost, ReceiptPrintingSize.TAPE_SIZE);
            }
          })
        );
      })
    );
    this.subsList.push(
      this.emailDocumentEvent.subscribe((saleDoc: SaleDoc) => {
        this.sendEmail(saleDoc);
      })
    );
    this.subsList.push(
      this.voidDocumentEvent.subscribe((saleDoc: SaleDoc) => {
        this.openVoidDocDialog(saleDoc);
      })
    );

    this.subsList.push(
      this.saleDocService.previewPopupClosedEvent$.subscribe(() => this.formListComponent.reloadData())
    );

    const activeStore = this.appSettingsService.getStore();
    this.isAdmin = !activeStore.id;
    this.subsList.push(
      this.appSettingsService.activeStoreChanged$.subscribe((store) => {
        this.isAdmin = !store.id;
      })
    );

    this.lookup_stores$ = this.revisitSaleService.lookup_stores();
    this.docTypeOptions = this.saleDocService.enum_doc_types;
    // this.docTypeOptions.shift();
    // console.log(this.docTypeOptions);
  }

  ngOnInit(): void {
    this.saleDocColumns = this.saleDocService.getFormListColumns({
      printItems: this.getPrintItems.bind(this),
      emailDocumentEvent: this.emailDocumentEvent,
      voidDocumentEvent: this.voidDocumentEvent,
    });
    this.saleDocColumns.find((row) => row.field === "doc.docNo").filterType = FilterType.none;
    const date = new Date();
    const firstDate = new Date().setDate(date.getDate() - 30);
    const lastDate = new Date();
    const _storeId = this.appSettingsService.getStoreId();
    this.formGroup = this.fb.group({
      docNo: null,
      accountId: 0,
      dateFrom: formatDate(firstDate, RevisitSaleComponent.DATE_FORMAT, this.defaultLocale),
      dateTo: formatDate(lastDate, RevisitSaleComponent.DATE_FORMAT, this.defaultLocale),
      storeId: this.isAdmin ? 0 : _storeId,
      docType: 0,
    });
    this.headerFormGroup = this.fb.group({
      emailAddress: this.fb.control(null, Validators.email),
    });

    if (this.componentState) this.restoreState(this.componentState);
    else this.showFormList = false;

    // this.subsList.push(this.route.params.subscribe((params) => {
    //   const storeId = parseInt(params['storeId']) || this.appSettingService.getStoreId();
    //   this.printMgr = this.printingFactory.build(storeId);
    // }));

    if (this.appSettingsService.getStation()?.id) {
      this.subsList.push(
        this.checkTakuPaymentTerminal(this.appSettingsService.getStation().id).subscribe((result) => {
          this.hasTakuPaymentTerminal = result;
        })
      );
    }

    if (this.preloadData) {
      this.searchDocuments();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["componentState"] && this.componentState && this.formGroup) {
      // if we have changed the prefilled search, so search documents again
      this.restoreState(this.componentState);
    }
  }

  private restoreState(state: RevisitSaleState) {
    this.formGroup.setValue(state.searchFormValue);
    this.headerFormGroup.setValue(state.headerFormValue);
    this.showFormList = state.formListVisible;
    this.activeAccountName = state.accountName;
    this._searchAccountsComp.textSearch = state.accountName || "";
    this.formFilter = state.formListFilter;
  }

  get _isMobile(): boolean {
    return WebHelpers.isMobileScreen();
  }

  get _hasSelectedDocs(): boolean {
    return this.formListComponent && this.formListComponent._selectedObjects;
  }

  get emailAddressCtrl() {
    return this.headerFormGroup.get("emailAddress");
  }

  onKeyPressedDocNo(e) {
    if (e.keyCode === 13) {
      // If 'Enter' was pressed made the search automatically
      this.searchDocuments();
    }
  }

  clearSearch(): void {
    const date = new Date();
    const firstDate = new Date().setDate(date.getDate() - 30);
    const lastDate = new Date();
    const _storeId = this.appSettingsService.getStoreId();
    this.formGroup.reset({
      docNo: null,
      accountId: 0,
      dateFrom: formatDate(firstDate, RevisitSaleComponent.DATE_FORMAT, this.defaultLocale),
      dateTo: formatDate(lastDate, RevisitSaleComponent.DATE_FORMAT, this.defaultLocale),
      storeId: this.isAdmin ? 0 : _storeId,
      docType: 0,
    });
    this.clearSelectedAccount();
    // this.formFilter = null;
    this._searchAccountsComp.clearSearchInputOnly();
    this.searchDocuments();
  }

  clearSelectedAccount() {
    this.formGroup.get("accountId").setValue(null);
    this.activeAccountName = null;
  }

  searchDocuments() {
    this._isDataReady = false;

    // Clear any email address
    this.headerFormGroup.patchValue({
      emailAddress: null,
    });

    this.showFormList = true;
    const searchQuery = this.formGroup.value;
    const queryObject = {};

    // if (!this.fullSizeMode)
    // queryObject['doc.docType'] = { value: DocType.sales_invoice, matchMode: 'equals'};

    if (searchQuery.storeId !== 0) {
      queryObject["storeId"] = { value: searchQuery.storeId, matchMode: "equals" };
    }
    queryObject["doc.state"] = { value: [DocState.voided, DocState.finalized, DocState.approved], matchMode: "in" };
    if (searchQuery.docType === 0) {
      searchQuery.docType = null;
    }
    if (searchQuery.docType !== null) {
      queryObject["doc.docType"] = { value: searchQuery.docType, matchMode: "equals" };
    }
    if (searchQuery.docNo) {
      // Ignore dates and account if docNo was set in search
      queryObject["doc.docNo"] = { value: searchQuery.docNo, matchMode: "equals" };
    } else {
      if (searchQuery.accountId) {
        queryObject["accountId"] = { value: searchQuery.accountId, matchMode: "equals" };
      }
      if (searchQuery.dateFrom || searchQuery.dateTo) {
        if (!searchQuery.dateFrom) {
          searchQuery.dateFrom = "1800-01-01";
        }

        if (!searchQuery.dateTo) {
          const newDateTo = moment().add(100, "years").toDate();
          searchQuery.dateTo = formatDate(newDateTo, RevisitSaleComponent.DATE_FORMAT, this.defaultLocale);
        }

        queryObject["doc.docClosingDate"] = { value: [searchQuery.dateFrom, searchQuery.dateTo], matchMode: "between" };
      }
    }

    // TODO: Add filters for date range
    this.formFilter = queryObject;

    setTimeout(() => {
      this._isDataReady = true;
    }, 0);
  }

  onSearchResAccount(account: SearchResultItem) {
    this.formGroup.patchValue({
      accountId: account.ID,
    });

    this.activeAccountName = account.headline;
  }

  checkTakuPaymentTerminal(stationId: number): Observable<boolean> {
    const filter = {
      stationId: { value: stationId, matchMode: "equals" },
      isPaired: { value: true, matchMode: "equals" },
    };

    return this.takuPayService.getRows("takuPaymentTerminal", JSON.stringify(filter), 0, 1).pipe(
      shareReplay(1),
      map((res: any) => res.rows && res.rows.length)
    );
  }

  openNewModelInDialog() {}

  refreshData() {
    if (this.formListComponent) this.formListComponent.reloadData();
  }

  openCashDrawer() {
    this.printMgr = this.printingFactory.build(this.appSettingService.getStoreId());
    this.printMgr.printOpenCashDrawer(this.printPreviewHost, ReceiptPrintingSize.TAPE_SIZE);
  }

  onPrintPressed(_selectedSaleDoc: SaleDoc, printSize: ReceiptPrintingSize) {
    // const storeId = parseInt(params['storeId']) || this.appSettingService.getStoreId();
    this.printMgr = this.printingFactory.build(_selectedSaleDoc.storeId);

    this.subsList.push(
      this.printMgr.isReady.subscribe({
        next: () => {
          const selectedDoc: SaleDoc = _selectedSaleDoc;
          if (!selectedDoc) return;

          this.subsList.push(
            this.revisitSaleService.getRow("saleDoc", selectedDoc.id).subscribe((data) => {
              const saleDoc = SaleDoc.convertIntoTypedObject(data);
              if (PrintHelpers.isTakuPayTransaction(saleDoc)) {
                Object.assign(saleDoc, { isMerchantReceipt: false }, { isCustomerReceipt: true });
                this.printMgr.printDocumentsWithSize([saleDoc], this.printPreviewHost, printSize);
              } else {
                this.printMgr.printDocumentsWithSize([saleDoc], this.printPreviewHost, printSize);
              }
            })
          );
        },
        error: (error) => {
          alert("Error: Can't print because Receipt Builder Settings are not available");
        },
      })
    );
  }

  onPrintPackingList(_selectedSaleDoc: SaleDoc, printSize: ReceiptPrintingSize) {
    this.printMgr = this.printingFactory.build(_selectedSaleDoc.storeId);

    this.subsList.push(
      this.printMgr.isReady.subscribe({
        next: () => {
          const selectedDoc: SaleDoc = _selectedSaleDoc;
          if (!selectedDoc) return;

          this.subsList.push(
            this.revisitSaleService.getRow("saleDoc", selectedDoc.id).subscribe((data) => {
              const saleDoc = SaleDoc.convertIntoTypedObject(data);
              saleDoc["isPackingList"] = true;
              this.printMgr.printDocumentsWithSize([saleDoc], this.printPreviewHost, printSize);
            })
          );
        },
        error: (error) => {
          alert("Error: Can't print because Receipt Builder Settings are not available");
        },
      })
    );
  }

  onPrintPrep(_selectedSaleDoc: SaleDoc) {
    this.printMgr = this.printingFactory.build(_selectedSaleDoc.storeId);
    this.subsList.push(
      this.printMgr.isReady.subscribe({
        next: () => {
          const selectedDoc: SaleDoc = _selectedSaleDoc;
          if (!selectedDoc) return;

          this.subsList.push(
            this.revisitSaleService.getRow("saleDoc", selectedDoc.id).subscribe((data) => {
              const saleDoc = SaleDoc.convertIntoTypedObject(data);
              this.printMgr.printStarCloudPRNTPrepInBatch([saleDoc]);
            })
          );
        },
        error: (error) => {
          alert("Error: Can't print because Receipt Builder Settings are not available");
        },
      })
    );
  }

  onReceiptCardPrintPressed(_selectedSaleDoc: SaleDoc) {
    // const storeId = parseInt(params['storeId']) || this.appSettingService.getStoreId();
    this.printMgr = this.printingFactory.build(_selectedSaleDoc.storeId);

    this.subsList.push(
      this.printMgr.isReady.subscribe({
        next: () => {
          const selectedDoc: SaleDoc = _selectedSaleDoc;
          if (!selectedDoc) return;

          this.subsList.push(
            this.revisitSaleService.getRow("saleDoc", selectedDoc.id).subscribe((data) => {
              const saleDoc = SaleDoc.convertIntoTypedObject(data);

              for (let index = 0; index < saleDoc.saleDocTenders.length; index++) {
                const _saleDocTender = saleDoc.saleDocTenders[index];
                if (
                  _saleDocTender.refTransaction &&
                  (_saleDocTender.refTransaction["customerReceiptTemplate"] ||
                    _saleDocTender.refTransaction["merchantReceiptTemplate"])
                ) {
                  if (PrintHelpers.isTakuPayTransaction(saleDoc)) {
                    this.takuPayService.printPaymentReceipt(
                      this.printMgr,
                      _saleDocTender,
                      saleDoc,
                      this.printPreviewHost
                    );
                  } else {
                    this.monerisService.printPaymentReceipt(
                      this.printMgr,
                      _saleDocTender,
                      saleDoc,
                      this.printPreviewHost
                    );
                  }
                }
              }
            })
          );
        },
        error: (error) => {
          alert("Error: Can't print because Receipt Builder Settings are not available");
        },
      })
    );
  }

  onReprintGiftReceipt(_selectedSaleDoc: SaleDoc) {
    this.printMgr = this.printingFactory.build(_selectedSaleDoc.storeId);

    this.subsList.push(
      this.printMgr.isReady.subscribe({
        next: () => {
          const selectedDoc: SaleDoc = _selectedSaleDoc;
          if (!selectedDoc) return;

          this.subsList.push(
            this.revisitSaleService.getRow("saleDoc", selectedDoc.id).subscribe((data) => {
              const saleDoc = SaleDoc.convertIntoTypedObject(data);
              this.printMgr.printSaleDocsInBatch(
                [_selectedSaleDoc],
                this.printPreviewHost,
                GiftReceiptTapePreviewComponent
              );
            })
          );
        },
        error: (error) => {
          alert("Error: Can't print because Receipt Builder Settings are not available");
        },
      })
    );
  }

  onDocSelected(event: RowSelectionEvent) {
    if (!event.data) return;

    this.subsList.push(
      this.revisitSaleService.getRow("saleDoc", event.data.id).subscribe((data) => {
        this.lastSelectedDoc = SaleDoc.convertIntoTypedObject(data);
        this.headerFormGroup.patchValue({
          emailAddress: this.lastSelectedDoc.accountEmail,
        });
      })
    );
  }

  onDocUnselected(event: SaleDoc) {
    this.headerFormGroup.patchValue({
      emailAddress: null,
    });
  }

  onEmailDoc(printSize: ReceiptPrintingSize) {
    const selectedDoc: SaleDoc = this.formListComponent._selectedObjects;
    if (!selectedDoc) return;

    this.subsList.push(
      this.revisitSaleService.getRow("saleDoc", selectedDoc.id).subscribe((data) => {
        const saleDoc = SaleDoc.convertIntoTypedObject(data);
        this.printMgr = this.printingFactory.build(saleDoc.storeId);
        this.subsList.push(
          this.printMgr
            .sendInvoiceEmail(saleDoc, this.emailAddressCtrl.value, this.printPreviewHost, printSize)
            .subscribe({
              next: (response) => {
                // Do nothing for NOW
              },
            })
        );
      })
    );
  }

  onFullScreenPressed() {
    this.onFullScreen.emit(this.getComponentState());
  }

  getComponentState(): RevisitSaleState {
    return {
      accountName: this.activeAccountName,
      formListVisible: this.showFormList,
      searchFormValue: this.formGroup.value,
      formListFilter: this.formFilter,
      headerFormValue: this.headerFormGroup.value,
    };
  }

  sendEmail(saleDoc: SaleDoc): void {
    this.printMgr = this.printingFactory.build(saleDoc.storeId);
    const dialogRef = this.dialogService.open(FormDialogInputComponent, {
      showHeader: true,
      header: "Enter email address",
      data: {
        caption: "Email Address",
        message: "",
        acceptLabel: "Send Email",
        inputType: "email",
        required: true,
        // pattern:'^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'
        // rejectVisible: false,
      },
    });
    this.subsList.push(
      dialogRef.onClose.subscribe((result) => {
        if (result) {
          this.printMgr = this.printingFactory.build(saleDoc.storeId);
          this.subsList.push(
            this.revisitSaleService.getRow("saleDoc", saleDoc.id).subscribe((data) => {
              const saleDoc = SaleDoc.convertIntoTypedObject(data);
              this.subsList.push(
                this.printMgr
                  .sendInvoiceEmail(saleDoc, result, this.printPreviewHost, ReceiptPrintingSize.LETTER_SIZE)
                  .subscribe({
                    next: (response) => {
                      // Do nothing for NOW
                    },
                  })
              );
            })
          );
        }
      })
    );
  }

  openVoidDocDialog(saleDoc: SaleDoc): void {
    const saleDocCopy = _.cloneDeep(saleDoc);
    const dialog = this.dialogService.open(SaledocVoidDialogComponent, {
      showHeader: false,
      data: {
        saleDoc: saleDocCopy,
      },
    });
    this.subsList.push(
      dialog.onClose.subscribe((result) => {
        if (!result.success) {
          return;
        }
        this.formListComponent.reloadData();
      })
    );
  }

  ngOnDestroy(): void {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
