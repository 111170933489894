<taku-sale-doc
  #saleDocComponent
  (inventoryItemSaved)="onInventoryItemSaved($event)"
  (saleLinesCleared)="onSalesLinesCleared()"
  (onDocSaved)="onDocSaved($event)"
  (onDocReset)="onDocReset()"
  (tenderScreenOpened)="openTenderScreen($event)"
  (itemReturned)="itemReturnedAction($event)"
  (onDocLinesDeleted)="onDocLinesDeleted($event)"
  (docLineQtyChanged)="docLineQtyChanged($event)"
  (onDocParked)="showDefaultTab()"
>
  <ng-template
    #saleDocHeaderDialog
    let-saleForm
    let-station="saleStation"
    let-account="saleAccount"
    let-cashout="cashout"
  >
    <taku-sale-invoice-doc-details
      [saleForm]="saleForm"
      [station]="station"
      [account]="account"
      [cashout]="cashout"
    ></taku-sale-invoice-doc-details>
  </ng-template>

  <ng-template
    #saleDocHeader
    let-saleForm
    let-station="saleStation"
    let-user="documentUser"
    let-account="saleAccount"
    let-cashout="cashout"
    let-activePriceLevel="activePriceLevel"
  >
    <div id="invoiceHeader" class="card col-12">
      <div id="doc-main-heading" class="col-12">
        <div class="col-12 doc-type-block">
          <span
            class="flex align-items-center"
            [ngClass]="{
              'is-disabled': orderEditLock,
              'cursor-pointer': docTypesMenu.length > 0,
              'doc-type': docComponent.selectedDocType === SaleDocType.sales_invoice,
              'pre-orders': docComponent.selectedDocType === SaleDocType.pre_order
            }"
            (click)="docTypesMenu.length > 0 && refreshDocTypeMenuState() && switchDocMenu.toggle($event)"
          >
            <span>{{ getDocTypeLabel() }}</span>
            <i *ngIf="docTypesMenu.length > 0" class="switchdoc-dropdown pi pi-caret-down"></i>
          </span>
          <p-menu
            #switchDocMenu
            [style]="{ fontWeight: 700 }"
            [model]="docTypesMenu"
            [popup]="true"
            appendTo="body"
          ></p-menu>
          <span *ngIf="station" class="till-number">
            <span class="label">STATION#</span> {{ station.stationNumber }}
          </span>

          <span class="sale-doc-headerRight-wrapper" [ngClass]="{ 'return-description': showChangeDue }">
            <span class="deliveryMethod">{{ saleForm.value.deliveryMethod }}<br /></span>
            <span *ngIf="showChangeDue" class="lastChangeDue">
              Last Change Due:
              {{ docComponent._lastChangeDue | currency : docComponent.documentCurrency : "symbol-narrow" : "1.2" }}
            </span>
          </span>
        </div>

        <!-- <div class="col-4 col-offset-2 doc-info-box user-info" *ngIf="user">
          <dl>
            <dt>User:</dt>
            <dd>{{user.userName}}</dd>
          </dl>
        </div> -->
      </div>

      <div id="top-info-cards" class="col-12" [ngClass]="{ 'top-info-card-return': showChangeDue }">
        <div class="grid align-items-center">
          <div class="doc-info-box account-info col-3">
            <dl>
              <dt>Account:</dt>
              <dd>
                <span [ngClass]="{ activePriceLevelAccount: !!activePriceLevel }">{{
                  account ? account.headline : "-"
                }}</span>
                <a
                  *ngIf="account && !orderEditLock"
                  class="pi pi-times icon-clear-account"
                  href="javascript://"
                  (click)="onClearAccBtnPressed($event)"
                ></a>
              </dd>

              <!-- <dt>Balance:</dt>
            <dd>$ 0.00</dd> -->
            </dl>
          </div>
          <div class="col col-3">
            <taku-calendar-date
              *ngIf="
                getDocTypeLabel() !== 'SALES' ||
                [
                  FulfillmentStatus.unfulfilled,
                  FulfillmentStatus.processing,
                  FulfillmentStatus.partly_Fulfillment
                ].includes(saleForm.value.fulfillmentStatus)
              "
              [minDate]="todayDate"
              [formGroup]="saleForm"
              fieldName="deliveryDate"
              fieldCaption="Outgoing Date"
              appendTo="body"
            >
            </taku-calendar-date>
          </div>
          <div class="col col-3">
            <taku-dropdown
              [formGroup]="saleForm"
              [options]="enum_saledoc_sources"
              fieldName="customerSource"
              fieldCaption="Source"
              appendTo="body"
            ></taku-dropdown>
          </div>
          <div class="doc-info-ctrls col-3">
            <button
              pButton
              label="Invoice Details"
              class="p-button-secondary"
              (click)="docComponent.openDocDetailsDialog()"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template
    #saleDocRightSide
    let-saleForm
    let-station="saleStation"
    let-user="documentUser"
    let-account="saleAccount"
    let-invoiceSettings="invoiceSettings"
    let-saleTotal="saleTotal"
  >
    <div id="searchFields-wrapper" class="card" [ngClass]="{ 'is-disabled': orderEditLock || transactionAreaDisabled }">
      <h3>Account and Inventory Selection</h3>
      <!-- ********** search box for Items **************** -->
      <div class="col-12 p-0 inventory-search-wrapper">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="material-icons">local_offer</i></span>
          <taku-search-inventory
            id="search_inventory"
            #inventorySearchBox
            placeholder="Scan or Search for Items"
            (itemSelected)="onSearchResInventory($event)"
            (onSearchResults)="onInventorySearchResults($event)"
            (onNewItemClicked)="openNewModelInDialog($event)"
            [showAnimatePlaceholder]="false"
            (onUnsellableItemDialogHide)="_saleDocListComponent.showGalleryTab()"
          ></taku-search-inventory>
        </div>
      </div>
      <!-- *********end of search box for items ***************** -->

      <!-- **********search box for Customer**************** -->
      <div
        class="grid p-2 customer-search-wrapper"
        [ngClass]="{ reduceSearchBarWidth: !isAddPersonalAccountVisible && !isAddCommercialAccountVisible }"
      >
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="material-icons">group</i></span>
          <taku-search-accounts
            id="account_search"
            #accountsSearchBox
            placeholder="Search for Account or Add New"
            (itemSelected)="onSearchResAccount($event)"
            (onNewItemClicked)="openNewModelInDialog($event)"
            [showAnimatePlaceholder]="false"
          ></taku-search-accounts>
        </div>

        <p-splitButton
          class="btnNewAccounts"
          #btnNewAccounts
          label=""
          icon="pi pi-plus"
          (onClick)="openAddNewAccountDialog($event)"
          appendTo="body"
          [model]="newAccountMenu"
          *ngIf="isAddPersonalAccountVisible || isAddCommercialAccountVisible"
          [disabled]="!isAddPersonalAccountEnabled && !isAddCommercialAccountEnabled"
        ></p-splitButton>
      </div>
      <!-- ********** end of add a customer icon **************** -->
    </div>

    <div #container id="rightside-tabs" class="card">
      <div
        id="rightsideDialog"
        class="dialog-{{ _activeDialog }}"
        [hidden]="!_activeDialog || !dialogExists(SideDialogNameDesktop, _activeDialog)"
      >
        <div class="dialog-header">
          <button
            pButton
            pRipple
            type="button"
            class="p-button-rounded close-btn"
            icon="pi pi-times"
            (click)="closeRightDialog()"
          ></button>
        </div>

        <div class="dialog-body">
          <ng-template [ngIf]="_activeDialog !== SideDialogName.RETURN_ITEM_SEARCH_FORM">
            <div [ngSwitch]="_activeDialog" style="height: 100%">
              <!-- <div *ngSwitchCase="SideDialogName.SEARCH_RESULTS" class="search-results-panel">
              <p-tabView class="tabview-nopad">
                <div class="tab-header searchresult-tabheader" (click)="closeRightDialog()">
                  <i class="pi pi-search"></i> Search Results <i class="ui-icon-arrow-drop-down"></i>
                </div>

                <p-tabPanel header="Search Results">
                  <taku-item-search-res *ngIf="inventorySearchResults" [results]="inventorySearchResults"
                    (itemSelected)="onInventorySelected($event)" (itemViewDetails)="docComponent.openInventoryEditForm($event)"></taku-item-search-res>
                </p-tabPanel>
              </p-tabView>
            </div> -->

              <!-- <div *ngSwitchCase="SideDialogName.GALLERY" class="gallery-panel">
              <div class="tab-header gallery-tabheader" (click)="closeRightDialog()">
                <i class="material-icons">crop_original</i> Picture Gallery <i class="ui-icon-arrow-drop-down"></i>
              </div>
              <div class="col-12" [style.marginTop]="'15px'">
                GALLERY PLACEHOLDER
              </div>
            </div> -->

              <div *ngSwitchCase="SideDialogName.TENDER_SCREEN" class="tender-screen">
                <taku-tender-screen
                  *ngIf="_activeDialogExtra.zone"
                  [formGroup]="saleForm"
                  [docAccount]="account"
                  [amountToPay]="saleTotal"
                  [zoneSettings]="_activeDialogExtra.zone"
                  [paymentGateways]="_activeDialogExtra.paymentGateways"
                  [storePolicies]="_activeDialogExtra.storePolicies"
                  [origTakuPaySaleDocTenders]="_activeDialogExtra.origTakuPaySaleDocTenders"
                  (changesAccepted)="closeRightDialog(); docComponent.onTendersProcessed($event)"
                  (onDocTenderRefDialog)="docComponent.openTenderRefNoDialog($event)"
                  (onPaymentProcessed)="showDefaultTab()"
                ></taku-tender-screen>
              </div>
              <!-- (saveChanges) = "docComponent.onSave()" -->

              <!-- <div *ngSwitchCase="SideDialogName.INVENTORY_DETAILS" class="inventory-details-panel">
              <taku-inventory-doc-preview [inventoryItem]="_activeDialogExtra" (onBack)="openRightDialog('search')"
                (onOpenEditForm)="docComponent.openInventoryEditForm($event)"></taku-inventory-doc-preview>
            </div> -->

              <div *ngSwitchCase="SideDialogName.RETURN_ITEM_NOINVOICE" class="return-item-panel">
                <taku-return-item
                  (onItemReturned)="onItemReturned($event)"
                  (onNewInventory)="openNewModelInDialog($event)"
                ></taku-return-item>
              </div>

              <div *ngSwitchCase="SideDialogName.RETURN_ITEM_SEARCH_RESULTS" class="returnItem-searchresults">
                <taku-form-header
                  toolbarTitle="Search Results"
                  displayMode="inline"
                  [showActionBtns]="false"
                  [showBackBtn]="false"
                ></taku-form-header>
                <taku-form-list
                  [_rowSelectionType]="RowSelectionType.NONE"
                  [_showHeader]="true"
                  [_filter]="_activeDialogExtra ? _activeDialogExtra : undefined"
                  _model="saleDoc"
                  _detailMode="n"
                  _scrollHeight="calc(100vh - 33.6rem)"
                  [_cols]="returnDocsColumns"
                  [_saveFormState]="false"
                  [_extraQueryParams]="_returnsSearchExtraParams"
                  [_hideFilterControls]="true"
                  [_hideSavingCtrls]="true"
                  [_showCtrlsInEditMode]="false"
                  [_hideAddLineHeaderBtn]="true"
                  [_hideOptionsHeaderBtn]="false"
                  [_hideGlobalFilter]="true"
                  [_hideAddHeaderBtn]="true"
                  [_hideDeleteHeaderBtn]="true"
                  [_title]="'Sale Documents'"
                ></taku-form-list>
              </div>
            </div>
          </ng-template>

          <div [hidden]="_activeDialog !== SideDialogName.RETURN_ITEM_SEARCH_FORM" class="returnItem-searchform">
            <taku-document-search-return
              (singleQtyReturned)="docComponent.addLineForReturn($event)"
              [inputSaleDoc]="docForReturnPreview"
              [allowNoInvoiceReturns]="docComponent.canNoInvoiceReturns"
              (entireDocReturned)="docComponent.setReturnedDoc($event)"
              (multipleResultsReturned)="openFormListForReturnSearch($event)"
              (noReceiptItem)="itemReturnedNoReceipt($event)"
              [docAccount]="account"
            ></taku-document-search-return>
          </div>
        </div>
      </div>

      <p-tabView
        id="sales-mainMenu"
        orientation="right"
        class="top-tabview-nopad"
        styleClass="sales-tabsBtns"
        [(activeIndex)]="activeTabIndex"
        (onChange)="onTabViewChange($event)"
      >
        <p-tabPanel *ngIf="isRevisitTabVisible" [disabled]="isTenderScreenOpened || !isRevisitTabEnabled">
          <ng-template pTemplate="header">
            <i class="material-icons p-tabview-left-icon">receipt</i>
            <span>Revisit</span>
          </ng-template>
          <ng-template pTemplate="content">
            <taku-revisit-sale
              [inRightSideOfSaleDoc]="true"
              [shortenFormHeaderHeight]="true"
              [fullSizeMode]="false"
              [enableFullScreen]="true"
              (onFullScreen)="openRevisitInFullScreen($event)"
            ></taku-revisit-sale>
          </ng-template>
        </p-tabPanel>

        <p-tabPanel [disabled]="isTenderScreenOpened">
          <ng-template pTemplate="header">
            <i class="material-icons p-tabview-left-icon">book</i>
            <span>Parked</span>
          </ng-template>
          <ng-template pTemplate="content">
            <taku-parked-sales
              #parkedSalesComponent
              [shortenFormHeaderHeight]="true"
              [fullSizeMode]="false"
              _scrollHeight="calc(100vh - 40rem)"
              (onDocReopened)="docComponent.reopenParkedSale($event)"
            ></taku-parked-sales>
          </ng-template>
        </p-tabPanel>

        <p-tabPanel *ngIf="isCrmTabVisible" [disabled]="isTenderScreenOpened || !isCrmTabEnabled">
          <ng-template pTemplate="header">
            <i class="material-icons p-tabview-left-icon">supervisor_account</i>
            <span>CRM</span>
          </ng-template>
          <taku-crm-sale
            [shortenFormHeaderHeight]="true"
            [formGroup]="saleForm"
            [accountInfo]="account?.data"
            (onAccountFormRequested)="docComponent.openAccountEditForm($event)"
            (onCustomerConsentBtn)="docComponent.openConsentEditForm($event)"
          >
          </taku-crm-sale>
        </p-tabPanel>

        <p-tabPanel id="invoice-details" [disabled]="isTenderScreenOpened">
          <ng-template pTemplate="header">
            <i class="material-icons p-tabview-left-icon">contact_mail</i>
            <span>Bill To</span>
          </ng-template>
          <taku-invoice-details
            [shortenFormHeaderHeight]="true"
            [account]="account"
            [saleForm]="saleForm"
            [salesDocSettings]="invoiceSettings.salesDocSettings"
          ></taku-invoice-details>
        </p-tabPanel>
        <p-tabPanel [ngClass]="{ 'is-disabled': orderEditLock }">
          <ng-template pTemplate="header">
            <i class="material-icons p-tabview-left-icon">list</i>
            <span>Lists</span>
          </ng-template>
          <taku-sale-doc-list
            #saleDocListComponent
            [saleForm]="saleForm"
            [shortenFormHeaderHeight]="true"
            [searchResults]="inventorySearchResults"
            (itemSelected)="onInventorySelected($event)"
            (itemViewDetails)="docComponent.openInventoryEditForm($event)"
            [dvHeight]="container.offsetHeight"
            [dvWidth]="container.offsetWidth"
            [docComponent]="docComponent"
          >
          </taku-sale-doc-list>
        </p-tabPanel>
        <p-tabPanel [disabled]="isTenderScreenOpened">
          <ng-template pTemplate="header">
            <i class="material-icons p-tabview-left-icon">redeem</i>
            <span>Vouchers</span>
          </ng-template>
          <ng-template pTemplate="content">
            <div>
              <taku-form-header
                shortenHeight="true"
                toolbarTitle="Voucher Balance"
                displayMode="inline"
                [showActionBtns]="false"
                [showBackBtn]="false"
              >
              </taku-form-header>
              <div class="p-4 voucher-balance">
                <taku-voucher-balance></taku-voucher-balance>
              </div>
            </div>
          </ng-template>
        </p-tabPanel>
      </p-tabView>
    </div>
  </ng-template>
</taku-sale-doc>
