<div class="grid">
  <div class="col-12 drop-container">
    <taku-dropdown
      [formGroup]="formGroup"
      [options]="lookupStocks$ | async"
      fieldName="stockId"
      fieldCaption="Stock"
    ></taku-dropdown>
  </div>

  <!-- <div class="col-12">
  <taku-taku-calendar-range [formGroup]="formGroup" fieldCaption="Date Range" fieldNameRangeType="dateRangeType"
    fieldNameDateFrom="dateFrom" fieldNameDateTo="dateTo"></taku-taku-calendar-range>
</div> -->
</div>
