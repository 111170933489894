<div class="col-12 card topWrapper">
  <a
    href="javascript://"
    (click)="goPrevStep()"
    class="prev-step"
    [ngClass]="{ 'btn-disabled': !prevStepEnabled, invisibleLink: !hasPrevStep }"
  >
    <i class="pi pi-chevron-left"></i> <span class="label-text">{{ prevStepLabel }}</span>
  </a>
  <p-steps [model]="model" [(activeIndex)]="activeIndex" [readonly]="false"></p-steps>
  <a
    *ngIf="activeIndex !== maxSteps - 1"
    id="next_btn"
    [hidden]="isLastStep && !lastStepVisible"
    href="javascript://"
    (click)="goNextStep()"
    class="next-step"
    [ngClass]="{ 'btn-disabled': !nextStepEnabled, 'last-step': isLastStep }"
  >
    <span class="label-text">{{ nextStepLabel }}</span> <i class="pi pi-chevron-right"></i>
  </a>
</div>
