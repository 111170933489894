/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, EventEmitter, Input, Output } from "@angular/core";
import * as _ from "lodash";

export type StepValidationMessages = {
  stepCaption: string;
  messages: ({ fieldName: string; validationError: string } | string)[];
};

@Component({
  selector: "taku-dialog-validation-messages",
  templateUrl: "./dialog-validation-messages.component.html",
  styleUrls: ["./dialog-validation-messages.component.scss"],
})
export class DialogValidationMessagesComponent {
  @Input() validationMessages: StepValidationMessages[] = [];
  @Input() dialogVisible: boolean;
  @Output() dialogVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onDialogHide: EventEmitter<any> = new EventEmitter<any>();

  _toSentenceCase(fieldName) {
    return _.startCase(fieldName);
  }

  onCloseDialog() {
    this.dialogVisibleChange.emit(false);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
