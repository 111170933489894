/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { InventoryValueReportLocalDataService } from "./inventory-value-report-local-data.service";
import { Subscription } from "rxjs";
import { FormListComponent } from "src/app/form-list/form-list/form-list.component";
import { RowSelectionType } from "src/app/form-list/form-list/form-list";
import {
  InventoryValueReportCols,
  InventoryValueReportColsFactoryService,
} from "./inventory-value-report-cols.service";
import { MessageService } from "primeng/api";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";
import { ReportParamsService } from "../../report-params.service";
import { ReportFilterFields } from "../../report-filter-fields";
import { InventoryValueReport } from "./inventory-value-report";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { resetReportRowState } from "../../report";

@Component({
  selector: "taku-inventory-value-report",
  templateUrl: "./inventory-value-report.component.html",
  styleUrls: ["./inventory-value-report.component.scss"],
  providers: [InventoryValueReportLocalDataService],
})
export class InventoryValueReportComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];
  @ViewChild("formListReport") formListComponent: FormListComponent;

  _model = "inventoryValueReport";
  _stateKey = "FL-" + this._model;
  RowSelectionType = RowSelectionType;
  public reportCols: InventoryValueReportCols;
  dynamicFields: string[] = [];
  _headerInfo: string;

  constructor(
    private reportColsFactoryService: InventoryValueReportColsFactoryService,
    public inventoryValueReportLocalService: InventoryValueReportLocalDataService,
    private messageService: MessageService,
    private alertMessagesService: AlertMessagesService,
    public reportParamsService: ReportParamsService,
    private appSettingsService: AppSettingsStorageService
  ) {
    this.reportCols = this.reportColsFactoryService.build([]);
  }

  ngOnInit() {
    this.subsList.push(
      this.reportParamsService.reportParamsChanged$.subscribe((filters: ReportFilterFields) => {
        let _zoneId = 0;
        if (filters.stockId === 0) {
          const _stocksFilter = {};
          if (this.appSettingsService.getStoreId()) {
            _zoneId = this.appSettingsService.getStore().zoneId;
          } else if (this.appSettingsService.getBackofficeId()) {
            _zoneId = this.appSettingsService.getBackoffice().zoneId;
          }
        }
        this.updateReport(filters.stockId, _zoneId);
        this.reportParamsService._isFetchingData = false;

        resetReportRowState(this._stateKey);
      })
    );

    // this.subsList.push(this.reportColsFactoryService.build([]).getInventoryDetails.subscribe({
    //   next: (inventory) => {
    //     console.log(inventory);
    //   },
    //   error: (error) => {
    //     console.log(error);
    //   },
    //   complete: () => {
    //     console.log("COMPLETED!");
    //   }
    // }));
    this._headerInfo = `At this time, all monetary values in reports are based on the system currency which can be found in Settings > Business Settings > Business Details > Default Currency`;
  }

  private updateReport(stockId, zoneId: number) {
    this.reportParamsService._isFetchingData = true;
    this.inventoryValueReportLocalService.queryParams = {
      stockId,
      zoneId,
    };
    // this.subsList.push(this.inventoryValueReportLocalService.getRows('',null,null,null,null,null,this.inventoryValueReportLocalService.queryParams).subscribe({
    //   next: rows => {
    //     this.buildFormlistCols(rows && rows.length ? rows[0] : null);
    //     this.refreshFormList(rows || []);
    //     this.reportParamsService._isFetchingData = false;
    //   },
    //   error: error => {
    //     this.buildFormlistCols(null);
    //     // Show toast with error message
    //     this.messageService.add(this.alertMessagesService.getErrorMessage(error));
    //     // Empty form list
    //     this.refreshFormList([]);
    //     this.reportParamsService._isFetchingData = false;
    //   }
    // }));
  }

  private buildFormlistCols(rowData: InventoryValueReport) {
    this.dynamicFields = rowData
      ? Object.keys(rowData).filter((key) => !Object.keys(new InventoryValueReport()).includes(key))
      : [];
    this.reportCols = this.reportColsFactoryService.build(this.dynamicFields);
  }

  private refreshFormList(data: any[]) {
    data.forEach((inventoryValue) => {
      const inventoryValueReport = Object.assign(new InventoryValueReport(), inventoryValue);
      // This makes store getter result into plain property
      inventoryValue.marginPercentCalculated = inventoryValueReport.marginPercent;
      inventoryValue.marginAmountCalculated = inventoryValueReport.marginAmount;
      inventoryValue.totalSoldPriceCalculated = inventoryValueReport.totalSoldPrice;
      inventoryValue.totalCostCalculated = inventoryValueReport.totalCost;

      this.dynamicFields.forEach((key) => {
        inventoryValue[key] = parseFloat(inventoryValue[key]) || 0;
      });
    });
    this.inventoryValueReportLocalService.initialize(data, this._model);
    setTimeout(() => {
      this.formListComponent.reloadData();
    }, 0);
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
