/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { DBService } from "src/app/shared/services/db.service";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import _, { cloneDeep } from "lodash";
import { Observable, Subject, combineLatest } from "rxjs";
import { tap, map, startWith } from "rxjs/operators";
import { PaymentGatewayShortname } from "../../integration-settings/payment-gateways/payment-gateway";
import { TakuPaymentGatewayShortname } from "../../integration-settings/taku-pay/taku-payment-gateways-list/taku-payment-gateway";
import { StoreTenderType, StoreTenderTypeList } from "./TenderTypesSettings";
import { PaymentTerminal } from "../../integration-settings/gateway-terminals/payment-terminal";
import { TakuPaymentTerminal } from "../../integration-settings/taku-pay/taku-payment-gateway-terminals/taku-payment-terminal";
import { AuthService } from "src/app/shared/services/auth.service";
import { HttpClient } from "@angular/common/http";
import { FilterRows } from "src/app/utility/FormDataHelpers";

@Injectable({
  providedIn: "root",
})
export class StoreTenderTypesSettingsService extends DBService {
  constructor(
    protected http: HttpClient,
    protected authService: AuthService,
    private appSettingsService: AppSettingsStorageService
  ) {
    super(http, authService);
  }

  private getPaymentTerminalList(): [Observable<PaymentTerminal[] | null>, Observable<TakuPaymentTerminal[] | null>] {
    const filter: FilterRows<PaymentTerminal | TakuPaymentTerminal> = {
      stationId: { value: this.appSettingsService.getStationId(), matchMode: "equals" },
      isPaired: { value: 1, matchMode: "equals" },
      isActive: { value: 1, matchMode: "equals" },
    };
    const paymentTerminalList = this.getRows("paymentTerminal", JSON.stringify(filter), 0, 1000).pipe(
      map((response: any) => response.rows)
    );
    const takuPaymentTerminalList = this.getRows("takuPaymentTerminal", JSON.stringify(filter), 0, 1000).pipe(
      map((response: any) => response.rows)
    );

    // Start with null so that if the tenderType request comes back first, the combineLatest will have input from all 3 observables
    return [
      paymentTerminalList.pipe(startWith(null as PaymentTerminal[] | null)),
      takuPaymentTerminalList.pipe(startWith(null as TakuPaymentTerminal[] | null)),
    ];
  }

  public getStoreTenderTypes(terminalCheckComplete?: Subject<boolean>): Observable<StoreTenderType[]> {
    return combineLatest([
      this.appSettingsService
        .getStoreSettings<StoreTenderTypeList>("storeTenderTypeList")
        .pipe(map((s) => cloneDeep(s))),
      ...this.getPaymentTerminalList(),
    ]).pipe(
      tap(([_, paymentTerminals, takuPaymentTerminals]) =>
        terminalCheckComplete?.next(!!paymentTerminals && !!takuPaymentTerminals)
      ),
      map(([storeTenderTypeList, paymentTerminals, takuPaymentTerminals]) => {
        if (!storeTenderTypeList) {
          // We need the tender type list, so keep the loading spinner going until we get it
          return null;
        }

        const tenderTypes = storeTenderTypeList.storeTenderTypes.map((storeTenderType) => {
          if (paymentTerminals) {
            const matchingTerminal = paymentTerminals.find((paymentTerminal) =>
              paymentTerminal.paymentGateway.tenderTypes.some(
                (paymentTenderType) => paymentTenderType.id === storeTenderType.tenderTypeId
              )
            );
            if (matchingTerminal) {
              storeTenderType.tenderType.paymentTerminalId = matchingTerminal.id;
              storeTenderType.tenderType.paymentTerminal = matchingTerminal;
              storeTenderType.tenderType.paymentGatewayShortname = PaymentGatewayShortname.MONERIS_SHORTNAME;
            }
          }

          if (takuPaymentTerminals) {
            const matchingTerminal = takuPaymentTerminals.find((paymentTerminal) =>
              paymentTerminal.takuPaymentGateway.tenderTypes.some(
                (paymentTenderType) => paymentTenderType.id === storeTenderType.tenderTypeId
              )
            );
            if (matchingTerminal) {
              storeTenderType.tenderType.takuPaymentTerminalId = matchingTerminal.id;
              storeTenderType.tenderType.takuPaymentTerminal = matchingTerminal;
              storeTenderType.tenderType.takuPaymentGatewayShortname = TakuPaymentGatewayShortname.TAKUPAY_SHORTNAME;
            }
          }
          return storeTenderType;
        });

        return _.sortBy(tenderTypes, "priority");
      })
    );
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
