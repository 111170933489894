/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, OnDestroy } from "@angular/core";
import { Location } from "@angular/common";
import { MenuItem } from "primeng/api";
import { AppSettingsStorageService } from "../../../../../shared/app-settings-storage.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Event as NavigationEvent } from "@angular/router";
import { NavigationEnd } from "@angular/router";
import { Subscription } from "rxjs";

@Component({
  selector: "taku-google-shopping-main",
  templateUrl: "./google-shopping-main.component.html",
  styleUrls: ["./google-shopping-main.component.scss"],
})
export class GoogleShoppingMainComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];

  _activeStoreId;
  _activeZoneId: number;
  _activeTab = 1;

  _gmcPages: MenuItem[] = [
    // { label: 'Products', routerLink: 'products' },
    // { label: 'Settings', routerLink: 'settings' },
  ];

  constructor(
    private locationService: Location,
    private appSettings: AppSettingsStorageService,
    private router: Router
  ) {
    this._activeZoneId = this.appSettings.getZoneId();
    // this._activeStoreId = this.appSettings.getStoreId();

    // Because the active route is associated with a child router-outlet, we cannot access directly to the route children's params,
    // but only through router events
    this.subsList.push(
      this.router.events.subscribe((event: NavigationEvent): void => {
        if (event instanceof NavigationEnd) {
          const routeStoreId = +event.url.replace(/.*\/(\d+)\??.*/g, "$1");
          this._activeStoreId = routeStoreId || this.appSettings.getStoreId();

          this._activeTab = this.router.url.includes("/google-shopping/settings") ? 1 : 0;
        }
      })
    );
  }

  ngOnInit() {
    this._gmcPages = [
      { label: "Products", routerLink: "products" },
      { label: "Settings", routerLink: "settings" },
    ];
  }

  goBack(): void {
    if (this.locationService) {
      this.locationService.back();
    }
  }

  storeChanged(storeId) {
    this.router.navigate(["/integrations/my-apps/google-shopping/products", storeId], {
      queryParamsHandling: "preserve",
    });
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
