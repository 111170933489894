/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Input } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import * as _ from "lodash";
import { defer, Observable } from "rxjs";
import { map, shareReplay, tap } from "rxjs/operators";
import { FormHeaderDisplayMode } from "src/app/forms/form-header/form-header.component";
import { DBMode } from "../../../../forms/generic-form/generic-form.component";
import { CommonValidators } from "../../../../shared/validators/CommonValidators";
import { StoreSettingsGenericComponent } from "../StoreSettingsGenericComponent";
import { StoreTenderType, StoreTenderTypeList } from "./TenderTypesSettings";

@Component({
  selector: "taku-tender-types-settings",
  templateUrl: "./tender-types-settings.component.html",
  styleUrls: ["./tender-types-settings.component.scss"],
})
export class TenderTypesSettingsComponent extends StoreSettingsGenericComponent<StoreTenderTypeList> {
  @Input() insideWizard = false;
  @Input() customHeaderTitle: string;
  @Input() autoAddNewToSelected = false;

  _pageTitlePrefix = "My Tender Types";
  availableTenderTypes: StoreTenderType[] = [];
  storeTenderTypes: StoreTenderType[] = [];
  FormHeaderDisplayMode = FormHeaderDisplayMode;
  _availableTenderTypes$: Observable<StoreTenderType[]>;

  private buildAvailableTenderTypes(zoneId: number): Observable<StoreTenderType[]> {
    const zoneFilter = JSON.stringify({
      zoneId: { value: zoneId, matchMode: "equals" },
      isEnabled: { value: true, matchMode: "equals" },
    });
    return this.dbService.getRows("tenderType", zoneFilter).pipe(
      map((myObjects: any) =>
        myObjects.rows.map((tenderType) => <StoreTenderType>{ id: 0, tenderTypeId: tenderType.id, tenderType })
      ),
      shareReplay(1)
    );
  }

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    const tmpForm = fb.group(new StoreTenderTypeList());
    tmpForm.setControl("storeTenderTypes", fb.array([]));

    return tmpForm;
  }

  setFormArrays(): void {
    this._myForm.setControl(
      "storeTenderTypes",
      this.fb.array(
        this._object.storeTenderTypes
          .sort((a, b) => a.priority - b.priority)
          .map((tenderType) => this.fb.group(tenderType))
      )
    );
  }

  initForm(): void {
    this._model = "storeTenderTypeList";
    this._object = new StoreTenderTypeList();
    this._myForm = TenderTypesSettingsComponent.init(this.fb);
  }

  get selectedTendersArray(): UntypedFormArray {
    return <UntypedFormArray>this._myForm.get("storeTenderTypes");
  }

  // Update form fields based on input
  private syncFormWithInput() {
    // Copy selected tenders from control to form
    // this._myForm.setControl('storeTenderTypes', this.fb.array(
    //   this.storeTenderTypes.map(i => this.fb.group(i))
    // ));
    // Delete any previous tenders
    while (this.selectedTendersArray.length !== 0) {
      this.selectedTendersArray.removeAt(0);
    }
    // Add current tenders
    this.storeTenderTypes.forEach((tender, index) => {
      tender.priority = index;
      this.selectedTendersArray.push(this.fb.group(tender));
    });

    this.selectedTendersArray.updateValueAndValidity();
  }

  onSubmit(silentMode = false): Observable<any> {
    return defer(() => {
      this.syncFormWithInput();
      this._myForm.markAsDirty();
      return super.onSubmit(silentMode);
    });
  }

  loadObject(): void {
    this.subsList.push(
      this.storeId$.subscribe((storeId) => {
        this.subsList.push(
          this._fetchAndStoreFormObject$(storeId).subscribe((storeTenderTypeList: StoreTenderTypeList) => {
            this._availableTenderTypes$ = this.buildAvailableTenderTypes(storeTenderTypeList.store.zoneId);
            this.updateUI(storeTenderTypeList);
          })
        );
      })
    );
  }

  onRevert(): void {
    super.onRevert();
    this.availableTenderTypes = [];
    this.updateUI(this._object, false);
  }

  private updateUI(storeTenderTypeList: StoreTenderTypeList, autoAdd?: boolean) {
    if (autoAdd === undefined) autoAdd = this.autoAddNewToSelected && this.dbMode === DBMode.insert;
    this.storeTenderTypes = (_.cloneDeep(storeTenderTypeList.storeTenderTypes) || []).sort(
      (a, b) => a.priority - b.priority
    );
    this._updatePageTitle(storeTenderTypeList.store);
    this.subsList.push(this.addNewTenderTypes(autoAdd).subscribe());
  }

  addNewTenderTypes(autoAddNewToSelected = this.autoAddNewToSelected): Observable<StoreTenderType[]> {
    const cmpFn = (tender1, tender2) => tender1.tenderTypeId === tender2.tenderTypeId;

    return this._availableTenderTypes$.pipe(
      tap((actualTenderTypes: StoreTenderType[]) => {
        const currTenderTypes = this.storeTenderTypes.concat(this.availableTenderTypes);
        const newTenders = _.differenceWith(actualTenderTypes, currTenderTypes, cmpFn);
        // const deletedTenders = _.differenceWith(currTenderTypes, actualTenderTypes, cmpFn);

        if (autoAddNewToSelected) this.storeTenderTypes.push(...newTenders);
        else this.availableTenderTypes = _.cloneDeep(newTenders);

        this.syncFormWithInput();
      })
    );
  }

  initValidation(): void {
    this._validation = {
      storeTenderTypes: CommonValidators.minLengthArray(1),
    };
  }

  onTendersMoved(): void {
    this.syncFormWithInput();
    this.selectedTendersArray.markAsDirty();
  }

  onTendersOrderChanged(event): void {
    this._myForm.markAsDirty();
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
