/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { GMBVerificationMethod } from "../core/settings/integration-settings/google-my-business/gmb-accounts-list/GMBGoogleAccount";

export class GMBHelpers {
  static PreviewGMBLocationAddress(postalAddress): string {
    const { regionCode, administrativeArea, locality, postalCode, addressLines } = postalAddress;
    return `${addressLines.join("\n")}
                ${postalCode}
                ${locality} ${administrativeArea} ${regionCode}`;
  }

  static ParseErrorMsgToUserMsg(errors: any): string {
    if (errors.error) errors = errors.error;
    if (errors.details) errors = errors.details;

    if (!Array.isArray(errors)) errors = [errors];

    const fnBuildErrorStr = (error) => {
      // return  `Code ${error.code}: ${error.message} ${error.field ? 'Field: '+error.field+'.' : ''}`
      return `Code ${error.code}: ${error.message}`;
    };
    const errorMsgs: string[] = [];
    (errors as any[]).forEach((error) => {
      if (error.errorDetails) error = error.errorDetails;
      if (!Array.isArray(error)) error = [error];

      error.forEach((_error: any) => errorMsgs.push(fnBuildErrorStr(_error)));
    });

    return errorMsgs.join("<br>");
  }

  static VerificationOptionToInputBody(verificationOption, languageCode): any {
    let requestDataKey;
    let requestDataObject;
    switch (verificationOption.verificationMethod) {
      case GMBVerificationMethod.ADDRESS:
        requestDataKey = "addressInput";
        requestDataObject = {
          // use the business name as contact name for now
          mailerContactName: verificationOption.addressData.businessName,
        };
        break;

      case GMBVerificationMethod.PHONE_CALL:
      case GMBVerificationMethod.SMS:
        requestDataKey = "phoneInput";
        requestDataObject = {
          phoneNumber: verificationOption.phoneData.phoneNumber,
        };
        break;

      case GMBVerificationMethod.EMAIL:
        requestDataKey = "emailInput";
        requestDataObject = {
          // use the business name as contact name for now
          emailAddress: `${verificationOption.emailData.userName}@${verificationOption.emailData.domainName}`,
        };
        break;
    }

    if (!requestDataKey || !requestDataObject)
      return new Error(`Unrecognized verification method: ${verificationOption.verificationMethod}`);

    return {
      method: verificationOption.verificationMethod,
      languageCode,
      [requestDataKey]: requestDataObject,
    };
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
