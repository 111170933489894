/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { DeliveryMethod } from "src/app/core/sale-channel/sale-channel-orders/sale-channel-order";
import { Address } from "../../../contact-accounts/address/address";
import { Zone } from "../../zone-settings/Zone";

export class Shipper {
  id: number;
  shipperName: string;
  shortName: string;
  shipperType: Shipper_Type;
  shippingMethod: JSON;
  deliveryMethod: DeliveryMethod;
  isActive: boolean;
  address: Address;
  zoneId: number;
  zone: Zone;
  accountNumber: string;

  constructor() {
    this.id = 0;
    this.shipperName = null;
    this.shortName = null;
    this.shipperType = Shipper_Type.integrated;
    this.shippingMethod = null;
    this.deliveryMethod = null;
    this.isActive = true;
    this.address = new Address();
    this.zoneId = 0;
    this.zone = null;
    this.accountNumber = "";
  }
}

export enum Shipper_Type {
  integrated = "Integrated",
  standalone = "Standalone",
}

export enum ShippingMethod {
  onDemand = "On Demand",
  flatRateShipping = "Flat Rate Shipping",
  ground = "Ground",
  ocean = "Ocean",
  air = "Air",
  other = "Other",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
