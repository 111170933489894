/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from "@angular/router";
import { Observable, zip, of } from "rxjs";
import { MessageService } from "primeng/api";
import { AlertMessagesService } from "../shared/services/alert-messages.service";
import { map, catchError, retry } from "rxjs/operators";
import { StoreSettingSaleDoc } from "../core/settings/store-settings/sales-doc-settings/sales-doc-settings";
import { ReturnSetting } from "../core/settings/store-settings/return-settings/ReturnSettings";
import { AppSettingsStorageService } from "../shared/app-settings-storage.service";

export type InvoiceSettings = {
  salesDocSettings: StoreSettingSaleDoc;
  returnSettings: ReturnSetting;
};

@Injectable({
  providedIn: "root",
})
export class InvoiceSettingsResolverService implements Resolve<any> {
  constructor(
    private messageService: MessageService,
    private alertService: AlertMessagesService,
    private appStorageService: AppSettingsStorageService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InvoiceSettings> {
    return zip(
      this.appStorageService.getStoreSettings("storeSettingSaleDoc"),
      this.appStorageService.getStoreSettings("storeSettingReturn")
    ).pipe(
      map(([salesDocSettings, returnSettings]) => {
        return {
          salesDocSettings,
          returnSettings,
        };
      }),
      catchError((error) => {
        this.messageService.add(
          this.alertService.getErrorMessage(null, "Error fetching settings", `Details: ${JSON.stringify(error)}`)
        );
        return of(null);
      }),
      retry(2) // retry two times when error occurs
    );
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
