/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { concatAll, map, zipAll } from "rxjs/operators";
import { Stock } from "src/app/core/settings/business-settings/stock/stock";
import { Store } from "src/app/core/settings/store-settings/store/store";
import { Zone } from "src/app/core/settings/zone-settings/Zone";
import { DBService } from "src/app/shared/services/db.service";

export class BusinessSetupResolvedData {
  constructor(public store: Store, public zone: Zone, public stock: Stock) {}
}

@Injectable({
  providedIn: "root",
})
export class BusinessSetupResolverService implements Resolve<BusinessSetupResolvedData> {
  constructor(private db: DBService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): BusinessSetupResolvedData | Observable<BusinessSetupResolvedData> | Promise<BusinessSetupResolvedData> {
    const storeId = +route.paramMap.get("storeId");
    if (storeId)
      // if we have a store id in the route params
      return this.db.getRow<Store>("store", storeId).pipe(
        map((store: Store) => [
          of(store),
          store.zoneId ? this.db.getRow<Zone>("zone", store.zoneId) : of(null),
          store.stockId ? this.db.getRow<Stock>("stock", store.stockId) : of(null),
        ]),
        concatAll(),
        zipAll(),
        map(([store, zone, stock]) => new BusinessSetupResolvedData(<Store>store, <Zone>zone, <Stock>stock))
      );
    else return of(null);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
