import { Store } from "../settings/store-settings/store/store";

export class Promotion {
  id = 0;
  isActive = true;
  name = "";
  note?: string = null;
  effectiveDate: Date = null;
  endDate: Date = null;
  priority = 1;
  discountPercentage = 0;
  canCombineDiscount = false;
  minimumSubTotal: number | string = 0;
  zoneId: number;
  categoryId = 0;
  category? = null;
  updatedAt: string;
  stores?: Store[] = null;

  constructor(zoneId: number) {
    this.zoneId = zoneId;
  }
}
