/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Address } from "src/app/core/contact-accounts/address/address";
import { TakuPaymentAccountStatus } from "../taku-pay";

export class TakuPayAccountDetail {
  id: number;
  statementDescriptor: string;
  mcc: string;
  countryIsoCode: string;
  userFirstName: string;
  userLastName: string;
  userEmail: string;
  address: Address;
  externalAccountId: string;
  accountId: string;
  setupLink: string;
  name: string;
  userExternalId: string;
  currencyIsoCode: string;
  status: TakuPaymentAccountStatus;
  isActive: boolean;
  addressId: string;
  createdAt: string;
  updatedAt: string;

  constructor() {
    this.id = 0;
    this.statementDescriptor = "";
    this.mcc = null;
    this.countryIsoCode = null;
    this.userFirstName = "";
    this.userLastName = "";
    this.userEmail = "";
    this.address = new Address();
    this.externalAccountId = "";
    this.accountId = null;
    this.setupLink = "";
    this.name = "";
    this.userExternalId = null;
    this.currencyIsoCode = "";
    this.status = TakuPaymentAccountStatus.Pending;
    this.isActive = true;
    this.addressId = null;
    this.createdAt = null;
    this.updatedAt = null;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
