/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { DBService } from "../../../shared/services/db.service";
import { FormListModel } from "../../../form-list/form-list/form-list-model.interface";
import { ValidatorFn, Validators } from "@angular/forms";
import { DataType, FilterType, Col } from "../../../form-list/form-list/form-list";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class BusinessBrandService extends DBService implements FormListModel {
  getValidationRules(): { [key: string]: ValidatorFn[] | {} } {
    return {
      isEnabled: [Validators.required],
      name: [Validators.required],
    };
  }

  getFormListColumns() {
    return [
      {
        field: "isEnabled",
        header: "Is Enabled",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.checkbox,
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.enum,
      },
      {
        field: "name",
        header: "Name",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
    ];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
