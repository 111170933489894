/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ConfirmationService } from "primeng/api";
import { Observable, Observer, of, Subscription } from "rxjs";
import { FormListMainComponent } from "src/app/form-list/form-list-main/form-list-main.component";
import { MultiFormListComponent } from "src/app/form-list/form-list-main/multiple-form-lists.interface";
import { FormListComponent } from "src/app/form-list/form-list/form-list.component";
import { DBService } from "src/app/shared/services/db.service";

@Component({
  selector: "taku-inventory-options-setting-value-form-list",
  templateUrl: "./inventory-options-setting-value-form-list.component.html",
  styleUrls: ["./inventory-options-setting-value-form-list.component.scss"],
})
export class InventoryOptionsSettingValueFormListComponent implements OnInit, MultiFormListComponent, OnDestroy {
  subsList: Subscription[] = [];

  @ViewChild(FormListMainComponent, { static: true }) formListMainComponent: FormListMainComponent;

  activeInventoryOptionsSettingName = "";
  _defaultRowValues: {};
  _filter: {} = null;
  // _isFormReady = false;

  constructor(
    private _route: ActivatedRoute,
    protected confirmationService: ConfirmationService,
    private dbService: DBService
  ) {}

  get pageTitleSuffix() {
    return this.activeInventoryOptionsSettingName
      ? `&nbsp;<span class="secondary-text">(${this.activeInventoryOptionsSettingName})</span>`
      : "";
  }

  ngOnInit() {
    this.subsList.push(
      this._route.params.subscribe((params) => {
        if (params["inventoryOptionsSettingId"]) {
          const inventoryOptionsSettingId = +params["inventoryOptionsSettingId"];
          this.updatePageTitle(inventoryOptionsSettingId);

          this._defaultRowValues = inventoryOptionsSettingId === 0 ? {} : { inventoryOptionsSettingId };
          this._filter =
            inventoryOptionsSettingId === 0
              ? {}
              : {
                  inventoryOptionsSettingId: { value: inventoryOptionsSettingId, matchMode: "equals" },
                };
          // this._isFormReady = true;
        }
      })
    );
  }

  updatePageTitle(inventoryOptionsSettingId: number) {
    this.subsList.push(
      this.dbService
        .getRow("inventoryOptionsSetting", inventoryOptionsSettingId)
        .subscribe((inventoryOptionsSetting) => {
          this.activeInventoryOptionsSettingName = inventoryOptionsSetting.optionName;
        })
    );
  }

  beforeSaveAll() {
    return new Observable((observer: Observer<boolean>) => {
      this.confirmationService.confirm({
        header: "Confirmation",
        message: `Any changes to Options or Attributes will be saved to all associated products.
      Do you still want to proceed?
      `,
        acceptLabel: "Yes",
        rejectLabel: "No",
        rejectVisible: true,
        acceptVisible: true,
        accept: () => {
          observer.next(true);
          observer.complete();
        },
        reject: () => {
          observer.next(false);
          observer.complete();
        },
      });
    });
  }

  retrieveFormLists(): FormListComponent[] {
    return [this.formListMainComponent.formListComponent];
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
