/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { LoginResponse } from "../../core/users/login/login-resposne";
import { UserProfile } from "../../core/users/login/user-profile";
import * as _ from "lodash";

type UserStorage = Omit<UserProfile, "id"> & { userId: number };

@Injectable()
export class UserProfileService {
  userId?: number;
  userName: string;
  firstName: string;
  lastName: string;
  role: any;
  email: string;
  imageUrl?: URL;
  uuid: string;

  profileStorageKey = "profile";

  constructor() {
    if (this.getProfileToken()) {
      const user = this.readUserFromStorage();
      this.userId = user.userId;
      this.userName = user.userName;
      this.firstName = user.firstName;
      this.lastName = user.lastName;
      this.role = user.role;
      this.email = user.email;
      this.uuid = user.uuid;
      // this.imageUrl = new URL((user.imageUrl));
    }
  }

  setUser(loginResponse: LoginResponse) {
    localStorage.setItem(
      this.profileStorageKey,
      JSON.stringify({
        userId: loginResponse.id, // right now server does not send userId, if we need it, we should add it later to the server side response
        userName: loginResponse.userName,
        firstName: loginResponse.firstName,
        lastName: loginResponse.lastName,
        role: loginResponse.role,
        email: loginResponse.email,
        uuid: loginResponse.uuid,
      })
    );

    if (loginResponse.id) {
      this.userId = loginResponse.id; // right now server does not send userId, if we need it, we should add it later to the server side response
    }
    this.userName = loginResponse.userName;
    this.firstName = loginResponse.firstName;
    this.lastName = loginResponse.lastName;
    this.role = loginResponse.role;
    this.email = loginResponse.email;
    this.uuid = loginResponse.uuid;
  }

  removeUser() {
    localStorage.removeItem(this.profileStorageKey);
  }

  private readUserFromStorage(): UserStorage {
    const user = this.getProfileToken();
    return user ? JSON.parse(user) : {};
  }

  getUser(): UserProfile {
    if (this.isUser()) {
      const user = this.readUserFromStorage();
      const userProfile = _.merge(new UserProfile(), {
        id: user.userId,
        userName: user.userName,
        firstName: user.firstName,
        lastName: user.lastName,
        role: user.role,
        imageUrl: user.imageUrl,
        email: user.email,
        uuid: user.uuid,
      });
      return userProfile;
    } else {
      return null;
    }
  }

  get userFullName(): string {
    if (this.isUser()) {
      return this.firstName + " " + this.lastName;
    }
  }

  getProfileToken(): string {
    return localStorage.getItem(this.profileStorageKey);
  }

  getUserId(): number {
    return JSON.parse(this.getProfileToken()).userId;
  }

  isUser(): boolean {
    return this.getProfileToken() != null;
  }

  getRole() {
    return this.role;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
