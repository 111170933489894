/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component } from "@angular/core";
import { NestedFormComponent } from "../../../forms/nested-form/nested-form.component";
import {
  CommercialAccountSocialProfileType,
  CommercialAccountSocialProfile,
} from "./commercial-account-social-profile";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ACTION_TYPE } from "src/app/shared/components/action-button/action-type.enum";

@Component({
  selector: "taku-commercial-account-social-profile",
  templateUrl: "./commercial-account-social-profile.component.html",
  styleUrls: ["./commercial-account-social-profile.component.scss"],
})
export class CommercialAccountSocialProfileComponent extends NestedFormComponent {
  public ACTION_TYPE: typeof ACTION_TYPE = ACTION_TYPE;
  // Define enum variables
  enum_commecrial_social_profile_type = this.dbService.enumSelectOptions(CommercialAccountSocialProfileType);

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    const commercialAccountSocialProfile_Group = fb.group(new CommercialAccountSocialProfile());
    return commercialAccountSocialProfile_Group;
  }

  static setArray(fb: UntypedFormBuilder, commercialAccountSocialProfiles: CommercialAccountSocialProfile[]) {
    const formArray = fb.array([]);
    commercialAccountSocialProfiles.map((commercialAccountSocialProfile) => {
      const temp = fb.group(commercialAccountSocialProfile);
      formArray.push(temp);
    });
    return formArray;
  }

  initValidation() {
    this._validation = {};
  }

  copySocialProfileToClipboard() {
    this.copyValueToClipboard(this.socialProfileCtrl.value);
  }

  get socialProfileCtrl() {
    return this._myForm.get("socialProfile");
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
