<taku-form-dialoge-header
  [pristine]="_myForm.pristine"
  [valid]="_myForm.valid"
  (backClicked)="goBack()"
  (saveClicked)="onSave()"
  (revertClicked)="onRevert()"
  (cancelClicked)="closeModal()"
  (newClicked)="onNew()"
  (deleteClicked)="onDelete()"
  (cloneClicked)="onClone()"
  toolbarTitle="Price Levels"
>
</taku-form-dialoge-header>

<form [formGroup]="_myForm" novalidate *ngIf="active">
  <p class="px-1">
    An easier way to manage discounts for different customers is with Price Levels. You can easily set an
    across-the-board price adjustment for a specific customer or group of customers by assigning each price level to any
    existing
    <a
      target="_blank"
      href="https://help.takulabs.io/how-to-get-started/general-settings/account-settings/account-groups"
      >Account Group</a>. We take care of the rest with no additional effort on your part. Click
    <a target="_blank" href="https://help.takulabs.io/how-to-get-started/discount-controls/price-levels">here</a>
    to learn more.
  </p>
  <h3>Price Level Details</h3>
  <div class="priceLevelDetails">
    <taku-input [formGroup]="_myForm" fieldName="name" fieldCaption="Price Level Name"></taku-input>
    <taku-dropdown
      [formGroup]="_myForm"
      [options]="lookup_zones$ | async"
      fieldName="zoneId"
      fieldCaption="Zone"
    ></taku-dropdown>
    <taku-dropdown
      [formGroup]="_myForm"
      [options]="lookup_acctGroups$ | async"
      fieldName="acctGroupId"
      fieldCaption="Account Group"
    ></taku-dropdown>
    <taku-calendar-date
      [formGroup]="_myForm"
      fieldName="effectiveDate"
      fieldCaption="Effective Date"
      appendTo="body"
    ></taku-calendar-date>
    <taku-calendar-date
      [formGroup]="_myForm"
      fieldName="endDate"
      fieldCaption="End Date"
      appendTo="body"
    ></taku-calendar-date>
    <div class="flex align-items-center">
      <taku-input-spinner
        [formGroup]="_myForm"
        [min]="1"
        [max]="127"
        fieldName="priority"
        fieldCaption="Priority"
      ></taku-input-spinner>
      <button
        pButton
        pRipple
        class="p-button-rounded p-button-secondary p-button-icon-only ml-2 flex-none"
        icon="pi pi-info-circle"
        (click)="priorityTip.toggle($event)"
      ></button>
      <p-overlayPanel #priorityTip appendTo="body">
        <p>
          Note that '1' represents the highest priority. The lower the number, the higher the priority.<br />
          Therefore, prioritize your tasks starting from '1' and proceed numerically.
        </p>
      </p-overlayPanel>
    </div>
    <taku-checkbox [formGroup]="_myForm" fieldName="isActive" fieldCaption="Is Active?"></taku-checkbox>
  </div>
  <h3>Price Level Rules</h3>
  <div class="priceLevelRules">
    <taku-multiselect
      [formGroup]="_myForm"
      [options]="lookup_stores$ | async"
      fieldName="stores"
      fieldCaption="Choose Stores"
      appendTo="body"
      class="storeDropdown"
    ></taku-multiselect>

    <div class="flex align-items-center">
      <taku-input [formGroup]="_myForm" fieldName="adjustBy" fieldCaption="Adjust By (Percentage)"></taku-input>

      <button
        pButton
        pRipple
        class="p-button-rounded p-button-secondary p-button-icon-only ml-2 flex-none"
        icon="pi pi-info-circle"
        (click)="adjustTip.toggle($event)"
      ></button>

      <p-overlayPanel #adjustTip appendTo="body">
        <p>This will be applied to the <b>sell price</b> of products.</p>
      </p-overlayPanel>
    </div>
  </div>
  <taku-input-textarea class="mt-3" [formGroup]="_myForm" fieldName="note" fieldCaption="Note"> </taku-input-textarea>
</form>
