/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit } from "@angular/core";

@Component({
  selector: "taku-sale-doc-tax",
  templateUrl: "./sale-doc-tax.component.html",
  styleUrls: ["./sale-doc-tax.component.scss"],
})
export class SaleDocTaxComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
