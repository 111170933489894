<taku-sale-doc-mobile
  #saleDocComponent
  (inventoryItemSaved)="onInventoryItemSaved($event)"
  (saleLinesCleared)="onSalesLinesCleared()"
  (onDocSaved)="onDocSaved($event)"
  (onDocReset)="onDocReset()"
  (tenderScreenOpened)="openTenderScreen($event)"
  (itemReturned)="itemReturnedAction($event)"
  (onDocLinesDeleted)="onDocLinesDeleted($event)"
  (docLineQtyChanged)="docLineQtyChanged($event)"
>
  <ng-template
    #saleDocHeaderDialog
    let-saleForm
    let-station="saleStation"
    let-account="saleAccount"
    let-cashout="cashout"
  >
    <taku-sale-invoice-doc-details [saleForm]="saleForm" [station]="station" [account]="account" [cashout]="cashout">
    </taku-sale-invoice-doc-details>
  </ng-template>

  <ng-template
    #saleDocHeader
    let-saleForm
    let-station="saleStation"
    let-user="documentUser"
    let-account="saleAccount"
    let-cashout="cashout"
    let-activePriceLevel="activePriceLevel"
  >
    <div id="invoiceHeader" class="card col-12 p-0">
      <div id="doc-main-heading" class="col-12">
        <div class="invoiceDetails-btn-wrapper">
          <i class="material-icons" (click)="docComponent.openDocDetailsDialog()">more_vert</i>
        </div>

        <div class="doc-type-block">
          <div
            class="doc-type-wrapper flex align-items-center"
            [ngClass]="{
              'is-disabled': orderEditLock,
              'cursor-pointer': docTypesMenu.length > 0,
              'doc-type': docComponent.selectedDocType === SaleDocType.sales_invoice,
              'pre-orders': docComponent.selectedDocType === SaleDocType.pre_order
            }"
            (click)="docTypesMenu.length > 0 && refreshDocTypeMenuState() && switchDocMenu.toggle($event)"
          >
            <span>{{ getDocTypeLabel() }}</span>
            <p-menu
              #switchDocMenu
              [style]="{ fontWeight: 700 }"
              [model]="docTypesMenu"
              [popup]="true"
              appendTo="body"
            ></p-menu>
            <i *ngIf="docTypesMenu.length > 0" class="switchdoc-dropdown pi pi-caret-down"></i>
          </div>

          <span class="sale-doc-headerRight-wrapper">
            <span class="deliveryMethod">{{ saleForm.value.deliveryMethod }}<br /></span>
            <span *ngIf="showChangeDue" class="lastChangeDue">
              Last Change Due:
              {{ docComponent._lastChangeDue | currency : docComponent.documentCurrency : "symbol-narrow" : "1.2"
              }}<br />
            </span>
            <span *ngIf="station" class="stn-num">
              Stn#: <b>{{ station.stationNumber }}</b>
            </span>
          </span>
        </div>
      </div>

      <div id="top-info-cards" class="col-12">
        <div class="grid pl-2">
          <div class="col doc-info-box account-info">
            <dl>
              <dt>Account:</dt>
              <dd [ngClass]="{ activePriceLevelAccount: !!activePriceLevel }">{{
                account ? account.headline : "-"
              }}</dd>
              <a
                *ngIf="account && !orderEditLock"
                class="pi pi-times icon-clear-account"
                href="javascript://"
                (click)="onClearAccBtnPressed($event)"
              ></a>
            </dl>
          </div>
          <div class="col pickup-date-wrapper" *ngIf="getDocTypeLabel() !== 'SALES'">
            <taku-calendar-date
              [formGroup]="saleForm"
              [minDate]="todayDate"
              fieldName="deliveryDate"
              fieldCaption="Date"
              appendTo="body"
            >
            </taku-calendar-date>
          </div>
          <div class="col source-wrapper">
            <taku-dropdown
              [formGroup]="saleForm"
              [options]="enum_saledoc_sources"
              fieldName="customerSource"
              fieldCaption="Source"
              appendTo="body"
            ></taku-dropdown>
          </div>
        </div>
      </div>
      <!-- Search boxes (Inventory and Account) -->
      <div class="col-12" id="searchFields-wrapper" [ngClass]="{ 'is-disabled': orderEditLock }">
        <div class="col-12 p-0 inventory-search-wrapper">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="material-icons">local_offer</i></span>
            <taku-search-inventory
              #inventorySearchBox
              placeholder="Search for Item"
              (itemSelected)="onSearchResInventory($event)"
              (onSearchResults)="onInventorySearchResults($event)"
              (onNewItemClicked)="openNewModelInDialog($event)"
              [showAnimatePlaceholder]="false"
            ></taku-search-inventory>
          </div>
        </div>
        <div
          class="grid p-2 customer-search-wrapper"
          [ngClass]="{ reduceSearchBarWidth: !isAddPersonalAccountVisible && !isAddCommercialAccountVisible }"
        >
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="material-icons">group</i></span>
            <taku-search-accounts
              id="account_search"
              #accountsSearchBox
              placeholder="Search for Account"
              (itemSelected)="onSearchResAccount($event)"
              (onNewItemClicked)="openNewModelInDialog($event)"
              [showAnimatePlaceholder]="false"
            >
            </taku-search-accounts>
          </div>
          <p-splitButton
            class="btnNewAccounts"
            #btnNewAccounts
            label=""
            icon="pi pi-plus"
            (onClick)="openAddNewAccountDialog($event)"
            [model]="newAccountMenu"
            appendTo="body"
            *ngIf="isAddPersonalAccountVisible || isAddCommercialAccountVisible"
            [disabled]="!isAddPersonalAccountEnabled && !isAddCommercialAccountEnabled"
          ></p-splitButton>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #saleDocFooter>
    <div class="toolbar-bottom">
      <p-tabMenu
        id="sales-mainMenu"
        [model]="mainMenuItems"
        styleClass="sales-tabsBtns"
        [scrollable]="true"
      ></p-tabMenu>
    </div>
  </ng-template>

  <ng-template
    #extraModalViews
    let-dialogName
    let-dialogExtra="dialogExtra"
    let-saleForm="saleForm"
    let-station="saleStation"
    let-user="documentUser"
    let-account="saleAccount"
    let-invoiceSettings="invoiceSettings"
    let-saleTotal="saleTotal"
  >
    <ng-template [ngIf]="dialogName !== SideDialogName.RETURN_ITEM_SEARCH_FORM">
      <span [ngSwitch]="dialogName">
        <div *ngSwitchCase="SideDialogName.TENDER_SCREEN" class="tender-screen">
          <taku-tender-screen
            *ngIf="dialogExtra.zone"
            [formGroup]="saleForm"
            [docAccount]="account"
            [amountToPay]="saleTotal"
            [zoneSettings]="dialogExtra.zone"
            [origTakuPaySaleDocTenders]="dialogExtra.origTakuPaySaleDocTenders"
            [storePolicies]="dialogExtra.storePolicies"
            (changesAccepted)="closeRightDialog(); docComponent.onTendersProcessed($event)"
            (onDocTenderRefDialog)="docComponent.openTenderRefNoDialog($event)"
          ></taku-tender-screen>
        </div>
        <!-- (saveChanges) = "docComponent.onSave()" -->

        <div *ngSwitchCase="SideDialogName.INVENTORY_DETAILS" class="inventory-details-panel">
          <taku-inventory-doc-preview
            [inventoryItem]="dialogExtra"
            (onBack)="openRightDialog($any('search'))"
            (onOpenEditForm)="docComponent.openInventoryEditForm($event)"
          ></taku-inventory-doc-preview>
        </div>

        <div *ngSwitchCase="SideDialogName.RETURN_ITEM_NOINVOICE" class="return-item-panel">
          <taku-return-item (onItemReturned)="onItemReturned($event)" (onNewInventory)="openNewModelInDialog($event)">
          </taku-return-item>
        </div>

        <div *ngSwitchCase="SideDialogName.RETURN_ITEM_SEARCH_RESULTS" class="returnItem-searchresults">
          <taku-form-header
            toolbarTitle="Search Results"
            displayMode="inline"
            [showActionBtns]="false"
            [showBackBtn]="false"
          ></taku-form-header>
          <taku-form-list
            [_rowSelectionType]="RowSelectionType.NONE"
            [_showHeader]="false"
            [_extraQueryParams]="_returnsSearchExtraParams"
            [_filter]="dialogExtra ? dialogExtra : undefined"
            _model="saleDoc"
            _detailMode="n"
            [_scrollHeight]="isTablet ? 'calc(100vh - 210pt)' : 'full'"
            [_cols]="returnDocsColumns"
            [_saveFormState]="false"
          ></taku-form-list>
        </div>

        <div *ngSwitchCase="SideDialogName.INVOICE_BILLTO" class="billTo-panel">
          <taku-invoice-details
            [account]="account"
            [saleForm]="saleForm"
            [salesDocSettings]="invoiceSettings.salesDocSettings"
          ></taku-invoice-details>
        </div>

        <div *ngSwitchCase="SideDialogName.VOUCHER_BALANCE" class="billTo-panel">
          <taku-form-header
            shortenHeight="true"
            toolbarTitle="Voucher Balance"
            displayMode="inline"
            [showActionBtns]="false"
            [showBackBtn]="false"
          >
          </taku-form-header>
          <div class="p-3">
            <taku-voucher-balance></taku-voucher-balance>
          </div>
        </div>

        <div *ngSwitchCase="SideDialogName.PARKED_INVOICES" class="parkedInvoices-panel">
          <taku-parked-sales
            [fullSizeMode]="false"
            (onDocReopened)="docComponent.reopenParkedSale($event)"
          ></taku-parked-sales>
        </div>

        <div *ngSwitchCase="SideDialogName.INVOICE_CRM" class="crm-panel">
          <taku-crm-sale
            [formGroup]="saleForm"
            [accountInfo]="account?.data"
            (onAccountFormRequested)="docComponent.openAccountEditForm($event)"
            (onCustomerConsentBtn)="docComponent.openConsentEditForm($event)"
          ></taku-crm-sale>
        </div>

        <div *ngSwitchCase="SideDialogName.REVISIT_INVOICES" class="revisitInvoices-panel">
          <taku-revisit-sale [fullSizeMode]="false"></taku-revisit-sale>
        </div>

        <div
          #container
          *ngSwitchCase="SideDialogName.SALE_DOC_LIST"
          class="saleDocLists-panel"
          [ngClass]="{ 'is-disabled': orderEditLock }"
        >
          <taku-sale-doc-list
            [saleForm]="saleForm"
            [searchResults]="inventorySearchResults"
            (itemSelected)="onInventorySelected($event)"
            (itemViewDetails)="docComponent.openInventoryEditForm($event)"
            [dvHeight]="container.offsetHeight"
            [dvWidth]="container.offsetWidth"
            [docComponent]="docComponent"
          ></taku-sale-doc-list>
        </div>
      </span>
    </ng-template>
    <!-- End of NgSwitch -->

    <div [hidden]="dialogName !== SideDialogName.RETURN_ITEM_SEARCH_FORM" class="returnItem-searchform">
      <taku-document-search-return
        [inputSaleDoc]="docForReturnPreview"
        [allowNoInvoiceReturns]="docComponent.canNoInvoiceReturns"
        [docAccount]="account"
        (singleQtyReturned)="docComponent.addLineForReturn($event)"
        (entireDocReturned)="docComponent.setReturnedDoc($event)"
        (multipleResultsReturned)="openFormListForReturnSearch($event)"
        (noReceiptItem)="itemReturnedNoReceipt($event)"
      ></taku-document-search-return>
    </div>
  </ng-template>

  <ng-template
    #saleDocRightSide
    let-saleForm
    let-station="saleStation"
    let-user="documentUser"
    let-account="saleAccount"
    let-invoiceSettings="invoiceSettings"
    let-saleTotal="saleTotal"
  >
  </ng-template>
</taku-sale-doc-mobile>
