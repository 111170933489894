/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Location } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  NgZone,
  Output,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from "lodash";
import { ConfirmationService, Message, MessageService, SelectItem } from "primeng/api";
import { SplitButton } from "primeng/splitbutton";
import { Observable } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";
import { PrintingFactoryService } from "src/app/shared/services/printing-service.service";
import { InvoiceValidators } from "../../../../shared/validators/InvoiceValidators";
import { SearchResultItem } from "../../../../taku-ui/taku-search-accounts/SearchResultItem";
import { TakuSearchAccountsComponent } from "../../../../taku-ui/taku-search-accounts/taku-search-accounts.component";
import { TakuSearchInventoryComponent } from "../../../../taku-ui/taku-search-inventory/taku-search-inventory.component";
import { FormDataHelpers } from "../../../../utility/FormDataHelpers";
import { InvoiceHelpers } from "../../../../utility/InvoiceHelpers";
import { MonetaryHelpers } from "../../../../utility/MonetaryHelpers";
import { AccountType } from "../../../contact-accounts/account/account";
import { AddressEmailComponent } from "../../../contact-accounts/address-email/address-email.component";
import { AddressPhoneComponent } from "../../../contact-accounts/address-phone/address-phone.component";
import { Address, AddressLocationType, AddressType } from "../../../contact-accounts/address/address";
import { AddressComponent } from "../../../contact-accounts/address/address.component";
import { CommercialAccount } from "../../../contact-accounts/commercial-account/commercial-account";
import { PersonalAccount } from "../../../contact-accounts/personal-account/personal-account";
import { Inventory } from "../../../inventory/inventory/inventory";
import { SaleDocEntryMethod } from "../../../settings/store-settings/sales-doc-settings/sales-doc-settings";
import { StorePoliciesSettings } from "../../../settings/store-settings/store-policies-settings/StorePoliciesSettings";
import { StoreSettingTapeSalePrint } from "../../../settings/store-settings/tape-salereceipt-builder/store-setting-tape-sale-print";
import { CompoundAccount } from "../../sale-document/crm-sale/crm-sale.component";
import {
  FullSizeDialogName,
  GenericSaleDocComponent,
  SaveDocActionType,
} from "../../sale-document/generic-sale-doc.component";
import { SaleDocLine } from "../../sale-document/sale-doc-line/sale-doc-line";
import { SaleDoc } from "../../sale-document/sale-doc/sale-doc";
import { SaleDocService } from "../../sale-document/sale-doc/sale-doc.service";
import { TenderScreenData } from "../../sale-document/sale-invoice-doc-base/sale-invoice-doc-base.component";
import { TenderScreenMode } from "../../sale-document/tender-screen/tender-screen.component";
import { SingleQtyReturned } from "../document-search-return/document-search-return.component";
import { ReturnInvoiceStateService } from "../return-invoice-state.service";
import { ReturnSaleDocLineComponent } from "../return-sale-doc-line/return-sale-doc-line.component";
import { InvoiceKeyboardActionsService } from "../../sale-document/sale-invoice-doc-base/invoice-keyboard-actions.service";
import { AppComponent } from "src/app/app.component";
import { SaleDocType } from "../../doc/doc";
import { MonerisService } from "src/app/core/settings/integration-settings/moneris-terminal/moneris.service";
import { TakuPayService } from "src/app/core/settings/integration-settings/taku-pay/taku-pay.service";
import { StoreTenderTypesSettingsService } from "src/app/core/settings/store-settings/store-tender-type-settings/tender-types-settings.service";
import { PriceLevelService } from "src/app/core/price-levels/price-level-service";
import { ModelFormGroup } from "src/app/utility/ModelFormGroup";

@Component({
  selector: "taku-return-sale-doc",
  templateUrl: "./return-sale-doc.component.html",
  styleUrls: ["./return-sale-doc.component.scss"],
})
export class ReturnSaleDocComponent extends GenericSaleDocComponent implements AfterViewInit {
  static readonly INVOICE_DETAILS_TAB_INDEX = 0;

  @Output() inventoryItemSaved = new EventEmitter<Inventory>();
  @Output() docLineQtyChanged = new EventEmitter<{ lineId: number; qty: number }>();
  @Output() tenderScreenOpened = new EventEmitter<TenderScreenData>();
  @Output() noReceiptItem = new EventEmitter<any>();
  @Output() searchReceiptsOpened = new EventEmitter<any>();

  debug = false;
  // static readonly DECIMAL_PLACES = 2;
  activeTabIndex: number = ReturnSaleDocComponent.INVOICE_DETAILS_TAB_INDEX;
  _decimalFormatting = "1.2-2";
  // _saveDocAction = SaveDocAction.TENDER_SCREEN;

  TenderScreenMode = TenderScreenMode;

  lookupShippers: SelectItem[];
  msgs: Message[] = [];

  loading = true;
  _myForm: UntypedFormGroup;

  // searchedItemText = "";
  searchedCustomerText = "";
  // _activeFullDialog: FullSizeDialogName;
  _activeFullDialogExtra: any;
  inventorySearchResults: Inventory[];
  _selectedAccount: SearchResultItem;
  // invoice: ReturnSaleDoc;
  // selectedLineIndex = -1; // which ReturnSaleDocLine has been selected? -1 -> no line

  // enums
  // FullSizeDialogName = FullSizeDialogName;

  // @ViewChild('generalDiscountAmount') discAmountCtrl: TakuInputComponent;

  // Taku Search components
  @ViewChild("accountsSearchBox") accountsSearchComponent: TakuSearchAccountsComponent;
  @ViewChild("inventorySearchBox") inventorySearchComponent: TakuSearchInventoryComponent;
  @ViewChild("btnNewAccounts") btnNewAccountsComponent: SplitButton;

  // @ViewChildren(ReturnSaleDocLineComponent) saleLinesComponents: QueryList<ReturnSaleDocLineComponent>;
  // Overlays visibility
  _overlaysVisibility: { [key: string]: boolean } = {
    discount: false,
    shipping: false,
  };

  currentFetchLimit = 10;
  builderSettings: StoreSettingTapeSalePrint;
  storePolicies: StorePoliciesSettings;
  _fullDialogParams: any;

  static init(fb, saleDoc: SaleDoc): UntypedFormGroup {
    const tmpForm = fb.group(saleDoc);
    tmpForm.setControl("saleDocLines", fb.array([]));
    tmpForm.setControl("saleDocTenders", fb.array([]));
    tmpForm.setControl("doc", fb.group(saleDoc.doc));
    tmpForm.setControl("billingAddress", AddressComponent.init(fb, null, AddressType.billingAddress));
    tmpForm.setControl("shippingAddress", AddressComponent.init(fb, null, AddressType.shippingAddress));

    // CRM compound objects
    // tmpForm.setControl( 'email', fb.group(tmpObject.email) );
    // tmpForm.setControl( 'phone', fb.group(tmpObject.phone) );
    return tmpForm;
  }

  constructor(
    public app: AppComponent,
    _router: Router,
    fb: UntypedFormBuilder,
    dbService: SaleDocService,
    location: Location,
    _route: ActivatedRoute,
    messageService: MessageService,
    alertMessage: AlertMessagesService,
    confirmationService: ConfirmationService,
    appSettingsService: AppSettingsStorageService,
    changeDetectorRef: ChangeDetectorRef,
    invoiceStateService: ReturnInvoiceStateService,
    printingFactoryService: PrintingFactoryService,
    ngZone: NgZone,
    keyboardActionsService: InvoiceKeyboardActionsService,
    elRef: ElementRef,
    monerisService: MonerisService,
    takuPayService: TakuPayService,
    storeTenderTypesSettingsService: StoreTenderTypesSettingsService,
    priceLevelService: PriceLevelService
  ) {
    super(
      app,
      _router,
      fb,
      dbService,
      location,
      _route,
      messageService,
      alertMessage,
      confirmationService,
      appSettingsService,
      changeDetectorRef,
      invoiceStateService,
      printingFactoryService,
      ngZone,
      keyboardActionsService,
      elRef,
      monerisService,
      takuPayService,
      storeTenderTypesSettingsService,
      priceLevelService
    );
  }

  loadObject() {
    super.loadObject();
    this.postprocessForm();
  }

  onSubmit(silentMode = true): Observable<any> {
    return super.onSubmit(silentMode);
  }

  setReturnedDoc(saleDoc: SaleDoc) {
    this.origTakuPaySaleDocTenders = saleDoc.saleDocTenders.filter(
      (result) => result.refTransaction?.PaymentId && !result.isReturned
    );
    if (this.origTakuPaySaleDocTenders.length > 0) {
      this.origTakuPaySaleDocTenders.sort((a, b) => b.id - a.id);
    }

    this._resetSaleDoc(false);

    // if this a new OR the same document, then keep 'No Receipt' lines (lines to associated with original sales document)
    const saleLinesToKeep: AbstractControl[] = [];
    // if ([0, saleDoc.doc.id].includes(this._object.doc.id) ){
    for (let i = 0; i < this.saleDocLines.length; i++) {
      if (this.saleDocLines.at(i).get("id").value !== 0) continue;

      // if line is detached from invoice / new line
      saleLinesToKeep.push(_.cloneDeep(this.saleDocLines.at(i)));
      // remove from current sale doc, so don't get reset whith empty values
      // this.saleDocLines.removeAt(i);
    }
    // }

    // Set same account as the previous document
    const docAccount = this.buildAccountSearchResult(saleDoc);
    if (docAccount) this._setAsDocAccount(docAccount);
    // Set doc lines
    this._object.saleDocLines = saleDoc.saleDocLines;
    this.postprocessForm();

    // add lines from previous document that should persist
    saleLinesToKeep.forEach((saleLine) => this.saleDocLines.push(saleLine));

    this._saveDocAction = { type: SaveDocActionType.TENDER_SCREEN };
    this._myForm.markAsDirty();

    this.changeDetectorRef.detectChanges();
    this._highlightAndShowLine(this.saleDocLines.length - 1);
  }

  openAccountEditForm(accountData: CompoundAccount) {
    switch (accountData.account.accountType) {
      case AccountType.commercial:
        this.openFullSizeDialog(FullSizeDialogName.COMMERCIAL_ACCOUNT, accountData);
        break;
      case AccountType.personal:
        this.openFullSizeDialog(FullSizeDialogName.PERSONAL_ACCOUNT, accountData);
        break;
    }
  }

  get invoiceTotalShipping(): number {
    const shippingCharge = this._myForm.get("shipperChargeAmount").value;
    if (shippingCharge === "" || shippingCharge === null) return 0;

    return MonetaryHelpers.roundToDecimalPlaces(parseFloat(shippingCharge));
  }

  initForm() {
    this.docType = SaleDocType.sales_return;
    super.initForm();
    this._myForm = ReturnSaleDocComponent.init(this.fb, this._object);

    this.subsList.push(
      this._myForm
        .get("discountType")
        .valueChanges.pipe(
          distinctUntilChanged() // only emits if previous discount value was different
        )
        .subscribe((newDiscountType) => {
          this.onChangeDiscType();
        })
    );
  }

  setFormArrays() {
    super.setFormArrays();

    this._myForm.setControl("saleDocLines", ReturnSaleDocLineComponent.setArray(this.fb, this._object.saleDocLines));
  }

  get _shipperName(): string {
    if (!this.lookupShippers) return null;

    const shipperId = this._myForm.get("shipperId").value;
    const selectedShipper = this.lookupShippers.find((shipper) => shipper.value === shipperId);
    if (!selectedShipper) return null;

    return selectedShipper.label;
  }

  initLookups() {
    this.subsList.push(
      this.dbService
        .lookupSelectOptions("shipper", "shipperName", { enableFieldName: "isActive" })
        .subscribe((shippers) => {
          this.lookupShippers = shippers;
        })
    );
  }

  onSave() {
    // Clear ids fields to avoid server errors
    const doc: SaleDoc = this._myForm.value;
    if (doc.id === 0) {
      // Clear ids originated from returned line from; other invoices
      for (let i = 0; i < this.saleDocLines.length; i++) {
        const theLine = this.saleDocLines.at(i) as ModelFormGroup<SaleDocLine>;
        const value = theLine.getRawValue();
        if (value.voucher) {
          value.voucher = null;
        }
        theLine.setValue(FormDataHelpers.clearModelIDs(value));
      }
    }

    super.onSave();
  }

  onChangeDiscType() {
    const discountTypeCtrl = this._myForm.get("discountType");
    const discountAmountCtrl = this._myForm.get("discountAmount");
    const unitPriceCtrl = this._myForm.get("subTotal");

    InvoiceHelpers.switchDiscountType(
      discountTypeCtrl,
      discountAmountCtrl,
      unitPriceCtrl,
      this.confirmationService,
      "Invoice Subtotal"
    );
  }

  onInventorySearchResults(items: SearchResultItem[]) {
    if (items.length === 0) {
      // this.inventorySearchResults = null;
    } else {
      // adding the search result to the other component
      this.inventorySearchResults = items.map((item) => item.data) as Inventory[];
    }
  }

  // collapseAll() {
  //   this.saleLinesComponents.forEach(saleLine => saleLine.collapseRow());
  // }
  ////////////////////////////////////////////// End of Dialogs //////////////////////////////////////////////////////////

  onSearchResInventory(item: SearchResultItem) {
    const inventoryItem: Inventory = item.data as Inventory;
    this.addToReturnSaleDoc(inventoryItem); // TODO: this method should be rewrritten
    if (this.inventorySearchComponent) this.inventorySearchComponent.cleanAndFocusSearchInput();
  }

  onInventorySelected(item: Inventory) {
    this.addToReturnSaleDoc(item); // TODO: this method should be rewrritten
  }

  onNewPersonalAccountCreated(personalAccount: PersonalAccount) {
    const accountResultItem = SearchResultItem.build("personalAccount", personalAccount);
    this._setAsDocAccount(accountResultItem);
    this.closeFullSizeDialog();
  }

  onNewCommercialAccountCreated(commecialAccount: CommercialAccount) {
    const accountResultItem = SearchResultItem.build("commercialAccount", commecialAccount);
    this._setAsDocAccount(accountResultItem);
    this.closeFullSizeDialog();
  }

  onInventoryItemSaved(inventory: Inventory) {
    this.inventoryItemSaved.emit(inventory);
    this.findAndUpdateDocLines(inventory);
    this.closeFullSizeDialog();
  }

  findAndUpdateDocLines(inventory: Inventory): any {
    for (let i = 0; i < this.saleDocLines.length; i++) {
      const theSaleLine = this.saleDocLines.at(i) as UntypedFormGroup;
      // This items already exist in sale document
      if (theSaleLine.get("docLine.inventoryId").value === inventory.id) {
        this.updateReturnSaleDocLine(inventory, theSaleLine);
      }
    }
  }

  onNewInventoryItem(inventory: Inventory) {
    this.addToReturnSaleDoc(inventory);
    this.closeFullSizeDialog();
  }

  onSearchResAccount(account: SearchResultItem) {
    this._setAsDocAccount(account);
    if (this.accountsSearchComponent) this.accountsSearchComponent.cleanAndFocusSearchInput();
  }

  private static findBestMatchAddressByType(
    addresses: Address[],
    type: AddressType,
    locationType: AddressLocationType
  ): Address {
    if (!addresses || addresses.length === 0) return new Address(locationType);

    const addressMatches = addresses.filter((address) => address.addressType === type && address.isDefault);
    if (addressMatches.length === 0) return new Address(locationType);

    // Clone in order to not use the same address for shipping and billing
    const bestMatch = _.cloneDeep(addressMatches[0]);
    // Set id to 0, so we don't have issues when saving
    FormDataHelpers.clearModelIDs(bestMatch);

    return bestMatch;
  }

  _prefillAddress(addressType: AddressType, account: SearchResultItem) {
    // Create a blank shipping and billing address
    let address: Address;

    switch (account.modelName) {
      case "personalAccount":
        const accPerson: PersonalAccount = account.data as PersonalAccount;
        // prefill addresses
        const personAddresses = accPerson.person.personAddresses.map((adress) => adress.address);
        if (addressType == AddressType.billingAddress)
          address = ReturnSaleDocComponent.findBestMatchAddressByType(
            personAddresses,
            AddressType.billingAddress,
            AddressLocationType.residential
          );
        else if (addressType == AddressType.shippingAddress)
          address = ReturnSaleDocComponent.findBestMatchAddressByType(
            personAddresses,
            AddressType.shippingAddress,
            AddressLocationType.residential
          );
        break;

      case "commercialAccount":
        const accCommercial: CommercialAccount = <CommercialAccount>account.data;
        // prefill addresses
        const companyAddresses = accCommercial.commercialAccountAddresses.map((adress) => adress.address);
        if (addressType == AddressType.billingAddress)
          address = ReturnSaleDocComponent.findBestMatchAddressByType(
            companyAddresses,
            AddressType.shippingAddress,
            AddressLocationType.commercial
          );
        else if (addressType == AddressType.shippingAddress)
          address = ReturnSaleDocComponent.findBestMatchAddressByType(
            companyAddresses,
            AddressType.billingAddress,
            AddressLocationType.commercial
          );
        break;
    }

    if (addressType == AddressType.billingAddress)
      this._myForm.setControl("billingAddress", AddressComponent.set(this.fb, address));
    else if (addressType == AddressType.shippingAddress)
      this._myForm.setControl("shippingAddress", AddressComponent.set(this.fb, address));
  }

  _prefillFormAccountFields(account: SearchResultItem): void {
    // prefill addresses
    this._prefillAddress(AddressType.billingAddress, account);
    this._prefillAddress(AddressType.shippingAddress, account);
  }

  _setAsDocAccount(account: SearchResultItem): any {
    // Don't Auto Copy by default when 'Sale Doc Settings' has not been created yet
    if (this._invoiceSettings.salesDocSettings && this._invoiceSettings.salesDocSettings.autoCopyAccountAddress) {
      this._prefillFormAccountFields(account);
    }

    this._myForm.get("accountId").setValue(account.ID);
    this._myForm.get("taxAccountCategoryId").setValue(account.data.account.taxAccountCategoryId);
    this._myForm.get("taxID").setValue(account.data.account.taxID);
    this._selectedAccount = account;
    this.activeTabIndex = 3;
  }

  // The Component should be able to display, add, and remove items from the saleDocLines FormArray.
  // Use the FormGroup.get method to acquire a reference to that FormArray. Wrap the expression in a
  // saleDocLines convenience property for clarity and re-use.
  addToReturnSaleDoc(inventory: Inventory) {
    if (!this._invoiceSettings.salesDocSettings) {
      this._addToSaleAsNewLine(inventory, 1);
      return;
    }

    switch (this._invoiceSettings.salesDocSettings.entryMethod) {
      case SaleDocEntryMethod.AccumulateLastLine:
        this._addToSaleCombineLastLineOnly(inventory);
        break;

      case SaleDocEntryMethod.CombineQty:
        this._addToSaleCombineEveryLine(inventory);
        break;

      case SaleDocEntryMethod.NewLine:
      default:
        this._addToSaleAsNewLine(inventory, 1);
    }
  }

  _addToSaleCombineEveryLine(inventory: Inventory): any {
    let skuFound = false;
    for (let index = 0; index < this.saleDocLines.length; index++) {
      const theReturnSaleDocLine = this.saleDocLines.at(index);
      if (this._compareLineBySKU(inventory, theReturnSaleDocLine)) {
        this.incQty(index);
        skuFound = true;
      }
    }

    if (!skuFound)
      // If the inventory wasn't in the whole invoice, add it as a new line
      this._addToSaleAsNewLine(inventory, 1);
  }

  _addToSaleCombineLastLineOnly(inventory: Inventory): any {
    const index = this.saleDocLines.length - 1;
    if (index >= 0 && this._compareLineBySKU(inventory, this.saleDocLines.at(index))) this.incQty(index);
    // if last doc line doesn't contain inventory by sku, add it as a new line
    else this._addToSaleAsNewLine(inventory, 1);
  }

  _compareLineBySKU(inventory: Inventory, saleLine: AbstractControl) {
    return inventory.sku == saleLine.get("sku").value;
  }

  _addToSaleAsNewLine(inventory: Inventory, qty: number): any {
    const saleLine: SaleDocLine = _.merge(new SaleDocLine(), {
      unitPrice: inventory.standardPrice,
      unitCost: inventory.defaultInventoryZone?.standardCost || 0,
      qty: qty,
      // TODO: Fill out DocLine
      docLine: {
        id: 0,
        seqNo:
          (this.saleDocLines.value.length
            ? Math.max(
                ...this.saleDocLines.value.map((row) => {
                  return row.docLine.seqNo;
                })
              )
            : 0) + 1,
        note: "",
        expiryDate: null,
        serialNo: "",
        refNo: 0,
        inventoryId: inventory.id,
        inventory: inventory,
        userId: this._object.doc.userId,
      },
    });

    this.updateFormWithTaxes(ReturnSaleDocLineComponent.set(this.fb, saleLine, 1000));
  }

  private updateFormWithTaxes(docLineForm: UntypedFormGroup) {
    this.saleDocLines.push(docLineForm); // adding an empty line to formArray
    this.saleDocLines.markAsDirty();
    this._highlightAndShowLine(this.saleDocLines.length - 1);
    // this.scrollToBottom();
  }

  updateReturnSaleDocLine(inventory: Inventory, saleLineForm: UntypedFormGroup) {
    const updatedSaleLine: SaleDocLine = _.merge(saleLineForm.value, {
      unitPrice: inventory.standardPrice,
      unitCost: inventory.defaultInventoryZone?.standardCost || 0,
      docLine: {
        inventoryId: inventory.id,
        inventory: inventory,
      },
    });
    saleLineForm.patchValue(updatedSaleLine);
    ReturnSaleDocLineComponent.filloutDerivedFormFields(saleLineForm, updatedSaleLine, updatedSaleLine.qty);
  }

  onClearLines(e) {
    if (e.screenX == 0 || e.screenY == 0) {
      // trick to avoid showing dialog on key Enter key presses
      return false;
    }

    // alert the user first
    this.confirmationService.confirm({
      message: "Do you want to clear the entire return doc?",
      header: "Delete Confirmation",
      icon: "ui-icon-delete-forever",
      rejectButtonStyleClass: "p-button-link",
      accept: () => {
        this._clearReturnSaleDocLines();
      },
      reject: () => {
        this.msgs = [{ severity: "info", summary: "Rejected", detail: "You have rejected the delete" }];
        this.messageService.addAll(this.msgs);
        if (this.inventorySearchComponent) this.inventorySearchComponent.cleanAndFocusSearchInput();
      },
    });
  }

  get hasPendingChanges(): boolean {
    if (this.saleDocLines.length > 0 || this._myForm.dirty) return true;

    return false;
  }

  private _clearReturnSaleDocLines() {
    // this.deleteAllLines();
    // this.clearSelectedAccount();
    // this.deleteTenderLines();

    // this.selectedLine = -1;
    // if (this.inventorySearchComponent) this.inventorySearchComponent.cleanAndFocusSearchInput();
    // this._myForm.markAsPristine();
    this._resetSaleDoc(false);
    this.onDocLinesDeleted.emit(this._myForm.value.doc.docNo);
    this.saleLinesCleared.emit();
    this.msgs = [{ severity: "info", summary: "Confirmed", detail: "Entire return doc cleared" }];
  }

  // onLineClick(index: number) {
  //   this.selectedLineIndex = ((this.selectedLineIndex !== index) ? index : -1);
  // }

  deleteInvoiceLine(index: number) {
    this.tenderWarningService
      .warnAboutTenders(() => {
        // Added for returns
        // grab id of removed line as is needed for returns
        const removedDocLineId = this.saleDocLines.at(index).value.id;
        this.docLineQtyChanged.emit({ lineId: removedDocLineId, qty: 0 });

        // remove the chosen row
        this.saleDocLines.removeAt(index);
      })
      .subscribe();
  }

  viewProductDetailsClicked(inventoryId) {
    this.subsList.push(
      this.dbService.getRow("inventory", inventoryId).subscribe((inventory: Inventory) => {
        this.openInventoryEditForm(inventory);
      })
    );
  }

  onLineQtyChanged(newQty: number, docLineForm: UntypedFormGroup) {
    // Only updated right side if qty is valid
    if (docLineForm.valid) this.docLineQtyChanged.emit({ lineId: docLineForm.value.id, qty: newQty });
  }

  initValidation() {
    this._validation = {
      discountAmount: InvoiceValidators.discountAmount(
        this._myForm.get("discountType"),
        this._myForm.get("subTotal"),
        "subtotal"
      ),
    };
  }

  onUser() {}

  onPark(saleTotal) {
    this.saleDocTotal = saleTotal;

    this.confirmationService.confirm({
      header: "Confirmation to Park Sale",
      message: "Are you sure you want to PARK this sale?",
      accept: () => {
        this._saveDocAction = { type: SaveDocActionType.RESET_SALEDOC };
        this.onSave();
      },
    });
  }

  ///////////////////////////////////////////   End of Buttons /////////////////////////////////////////////////////////////

  ///////////////////////////
  // For Invoice Details ///
  /////////////////////////
  onCopyBillingAddressFromAccount() {
    this._prefillAddress(AddressType.billingAddress, this._selectedAccount);
  }

  onCopyShippingAddressFromAccount() {
    this._prefillAddress(AddressType.shippingAddress, this._selectedAccount);
  }

  onCloneAddress(destAddress: UntypedFormGroup, sourceAddress: UntypedFormGroup) {
    // Before reseting address backup destination address type
    const addressType = destAddress.get("addressType").value;

    const sourceData: Address = sourceAddress.value;
    FormDataHelpers.clearModelIDs(sourceData);
    destAddress.reset(sourceData);
    destAddress.setControl("addressEmail", AddressEmailComponent.set(this.fb, sourceData.addressEmail));
    destAddress.setControl("addressPhone", AddressPhoneComponent.set(this.fb, sourceData.addressPhone));

    destAddress.patchValue({ addressType });
  }

  onClearAddress(address: UntypedFormGroup) {
    // Before reseting address backup curent address type
    const addressType = address.get("addressType").value;
    address.reset();
    address.patchValue({ addressType });
  }

  /**** NEW METHODS FOR RETURNS ****/
  addLineForReturn(returnedItem: SingleQtyReturned) {
    const returnSaleDoc = returnedItem.saleDoc;
    returnSaleDoc.doc.docSourceType = returnedItem.saleDoc.doc.docType;
    const returnedLine = returnSaleDoc.saleDocLines.find((docLine) => {
      return returnedItem.docLineId == docLine.id;
    });

    // returnedLine.docLine.refNo =
    /** If line to return is from different document, set document again but with empty lines**/
    // if (this._id != returnSaleDoc.id){
    //   returnSaleDoc.saleDocLines = [];
    //   this.setReturnedDoc(returnSaleDoc);
    // }

    // see if line has been already added
    const foundDocLine: AbstractControl = this._findDocLineById(returnedItem.docLineId);
    if (foundDocLine) {
      foundDocLine.patchValue({
        qty: foundDocLine.get("qty").value + returnedLine.qty,
      });
    } else {
      // Add only this line to the invoice
      this.updateFormWithTaxes(ReturnSaleDocLineComponent.set(this.fb, returnedLine, returnedItem.maxQty));
      // this.saleDocLines.push(ReturnSaleDocLineComponent.set(this.fb, returnedLine, returnedItem.maxQty));
    }

    this.saleDocLines.markAsDirty();
  }

  openTenderRefNoDialog(docTenderLine: UntypedFormGroup) {
    this.openFullSizeDialog(FullSizeDialogName.TENDER_REF_NO, docTenderLine, {
      styleClass: null,
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
