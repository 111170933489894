<div class="topWrapper">
  <taku-form-header [toolbarTitle]="_headerTitle" displayMode="inline" [showActionBtns]="false" [showBackBtn]="false">
    <button
      pButton
      class="p-button p-button-rounded"
      type="button"
      (click)="onClosePressed()"
      icon="pi pi-times"
    ></button>
  </taku-form-header>

  <div class="bodyWrapper">
    <div *ngIf="unrevertedTenders.length" class="mt-2">
      <div>
        The following <strong>payment(s)</strong> were accepted for this transaction. When you click the
        <strong>Void Document</strong> button below, any associated integrated payment(s) will be automatically refunded
        to the original payment method(s). All other payment(s) will be marked as voided.
      </div>
      <taku-pay-type-details [saleDocTenders]="unrevertedTenders"></taku-pay-type-details>
    </div>
    <div class="col-12">
      <taku-input-textarea
        [formGroup]="_voidForm"
        fieldName="voidReason"
        fieldCaption="Void Reason"
        [rows]="3"
      ></taku-input-textarea>
    </div>

    <footer class="col-12">
      <button
        pButton
        class="p-button-secondary cancel-btn"
        label="Cancel"
        (click)="onClosePressed()"
        [disabled]="inProgress"
      ></button>
      <button
        pButton
        [disabled]="_voidForm.invalid"
        [loading]="inProgress"
        class="void-btn"
        label="Void Document"
        (click)="onVoidPressed()"
      ></button>
    </footer>
  </div>
</div>

<div [hidden]="true">
  <ng-template #printPreviewHost></ng-template>
</div>
