/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Store } from "../store/store";

export class StorePoliciesSettings {
  id: number;
  salesPolicy: string;
  shippingPolicy: string;
  returnPolicy: string;
  storeId: number;
  store: Store;
  giftReceiptPolicy: string;
  consent: string;

  constructor(store?: Store) {
    this.id = 0;
    this.salesPolicy = "";
    this.shippingPolicy = "";
    this.returnPolicy = "";
    this.giftReceiptPolicy = "";
    this.consent = `By checking this box I confirm that I agree to receive more information about ${
      store ? `<strong>${store.storeName}</strong>` : "our"
    } products, events and offers.`;
    this.storeId = store ? store.id : 0;
    this.store = store || null;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
