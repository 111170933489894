/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Input } from "@angular/core";
import { NestedFormComponent } from "../../../forms/nested-form/nested-form.component";
import { PersonEmailType, PersonEmail } from "./person-email";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ACTION_TYPE } from "src/app/shared/components/action-button/action-type.enum";

@Component({
  selector: "taku-person-email",
  templateUrl: "./person-email.component.html",
  styleUrls: ["./person-email.component.scss"],
})
export class PersonEmailComponent extends NestedFormComponent {
  public ACTION_TYPE: typeof ACTION_TYPE = ACTION_TYPE;
  @Input() disableForm?: boolean = false;

  // Define enum variables
  // enum_commecrial_phone_type = this.dbService.enumSelectOptions(CommercialAccountPhoneType, 'Type');
  enum_person_email_type = this.dbService.enumSelectOptions(PersonEmailType);

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    const personEmail_Group = fb.group(new PersonEmail());
    return personEmail_Group;
  }

  static setArray(fb: UntypedFormBuilder, personEmails: PersonEmail[]) {
    const formArray = fb.array([]);
    personEmails.map((personEmail) => {
      const temp = fb.group(personEmail);
      formArray.push(temp);
    });
    return formArray;
  }

  initValidation() {
    this._validation = {
      emailType: [Validators.required],
      email: [Validators.email, Validators.required],
    };
  }

  copyEmailToClipboard() {
    this.copyValueToClipboard(this.emailCtrl.value);
  }

  get emailCtrl() {
    return this._myForm.get("email");
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
