/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, Output, EventEmitter, ViewChild, AfterViewInit } from "@angular/core";
import { NestedFormComponent } from "../../../../forms/nested-form/nested-form.component";
import { TakuInputTextareaComponent } from "../../../../taku-ui/taku-input-textarea/taku-input-textarea.component";

@Component({
  selector: "taku-sale-doc-tender-reference",
  templateUrl: "./sale-doc-tender-reference.component.html",
  styleUrls: ["./sale-doc-tender-reference.component.scss"],
})
export class SaleDocTenderReferenceComponent extends NestedFormComponent implements AfterViewInit {
  @Output() onDialogClosed: EventEmitter<any> = new EventEmitter();
  @ViewChild(TakuInputTextareaComponent, { static: true }) textAreaComponent: TakuInputTextareaComponent;

  ngAfterViewInit(): void {
    if (this.textAreaComponent)
      setTimeout(() => {
        this.textAreaComponent.focusAndSelectInput();
      }, 200);
  }

  okBtnPressed() {
    this.onDialogClosed.emit();
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
