import { Inject, Injectable, LOCALE_ID, inject } from "@angular/core";
import { ValidatorFn, Validators } from "@angular/forms";
import { SelectItem } from "primeng/api";
import { Observable, of } from "rxjs";
import {
  ArrayDatatypeOptions,
  Col,
  DataType,
  FilterType,
  TemplateDatatypeOptions,
} from "src/app/form-list/form-list/form-list";
import { FormListModel } from "src/app/form-list/form-list/form-list-model.interface";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { CurrencySymbolPipe } from "src/app/shared/pipes/currency-symbol.pipe";
import { AuthService } from "src/app/shared/services/auth.service";
import { InventoryStockLevelsArrayComponent } from "../inventory-stocklevels-array/inventory-stocklevels-array.component";
import { InventoryVariantSettingLine } from "../inventory-variant-setting-line/inventory-variant-setting-line";
import { Inventory, InventoryStock } from "../inventory/inventory";
import { InventoryService } from "../inventory/inventory.service";
import * as _ from "lodash";
import { DataNormalizerService } from "src/app/shared/services/data-normalizes.service";
@Injectable({
  providedIn: "root",
})
// export class InventoryVariantSettingFactoryService {

//   constructor(public dbService: DBService) { }

//   build(dynamicFields: string[]) {
//     return new InventoryVariantSettingService(this, dynamicFields);
//   }
// }
export class InventoryVariantSettingService implements FormListModel {
  lookup_InventoryVariantSettingsValue: { [inventoryOptionsSettingId: number]: SelectItem[] } = {};
  parentInventory: Inventory;
  normalizerService = inject(DataNormalizerService);
  constructor(
    public inventoryService: InventoryService,
    @Inject(LOCALE_ID) protected defaultLocale: string,
    protected appSettings: AppSettingsStorageService,
    protected authService: AuthService
  ) {
    this.currencySymbolPipe = new CurrencySymbolPipe(this.defaultLocale);
  }

  protected currencySymbolPipe: CurrencySymbolPipe;
  _disableCloning = true;

  private _currencyPreffix(currencyIsoCode) {
    if (!currencyIsoCode) return "";

    return this.currencySymbolPipe.transform(currencyIsoCode, "symbol") + " ";
  }

  standardPriceCurrency(rowData): string {
    return this._currencyPreffix(rowData.standardPriceCurrencyIsoCode);
  }

  getInventoryVariantSettingsValue(_inventoryVariantSetting: InventoryVariantSettingLine): Observable<any[]> {
    return of([
      { label: "", value: null },
      ..._inventoryVariantSetting.inventoryOptionsSettingValues.map((row) => {
        return { value: row.id, label: row.optionValue };
      }),
    ]);
  }

  getValidationRules(): { [key: string]: ValidatorFn[] | any } {
    const _validations = {};
    if (this.parentInventory) {
      this.parentInventory.inventoryVariantSettings.map((_inventoryVariantSetting) => {
        _validations[
          "inventoryVariants_detail." + _inventoryVariantSetting.inventoryOptionsSetting.optionName.replace(/ /g, "_")
        ] = [Validators.required];
      });
    }

    return { ...this.inventoryService.getValidationRules(), ..._validations };
  }

  getInventoryVariants(parentInventoryId: number) {
    const currVariantFilter = JSON.stringify({
      parentInventoryId: { value: parentInventoryId, matchMode: "equals" },
    });

    const defaultStockId = this.appSettings.getStore().stockId;
    const zoneId = this.appSettings.getZoneId();
    const fields = ["inventoryStocks.onOrderQty"];
    const _extraQueryParams = { stockId: defaultStockId, zoneId: zoneId, fields: fields };

    return this.inventoryService.getRows("inventory", currVariantFilter, 0, 1000, "", null, _extraQueryParams);
  }

  afterFetch(_inventory: Inventory, model: string) {
    switch (model) {
      case "inventories":
        _inventory.defaultInventoryStock = _inventory.inventoryStocks.find((obj) => {
          return obj.stockId === this.appSettings.getStore().stockId;
        });
    }
  }

  totalQtyOH(currentStock: InventoryStock, allStocks: InventoryStock[]) {
    return _.sumBy(allStocks, "qtyOH");
  }

  getFormListColumns(parentInventory?: Inventory, unlinkTpl?: TemplateDatatypeOptions): Col[] {
    const totalOhQtyVisible: boolean = this.authService.hasVisiblePermit(
      "Inventory_Menu_Inventory_List_Stock_Tab_Stock_Level"
    );

    this.parentInventory = parentInventory;
    const cols: Col[] = [
      {
        field: "isPaired",
        header: "Status",
        visible: true,
        readonly: true,
        frozen: true,
        dataType: DataType.template,
        dataTypeOptions: unlinkTpl,
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 130,
        isNotSortable: true,
      },
      {
        field: "isEnabled",
        header: "ACTIVE",
        visible: true,
        readonly: false,
        frozen: true,
        dataType: DataType.checkbox,
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.enum,
        colWidth: 120,
      },
      {
        field: "sku",
        header: "SKU",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
      },
      {
        field: "standardPrice",
        header: "Price",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.number,
        dataTypeOptions: { prefix: this.standardPriceCurrency.bind(this) },
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 135,
        displayFn: (rowData: Inventory) => {
          return this.normalizerService.currencyNormalizer(rowData.standardPrice, rowData.standardPriceCurrencyIsoCode);
        },
      },
      ...(!this.appSettings.isAdminLogin()
        ? [
            {
              field: "defaultInventoryZone.standardCost",
              header: "Cost",
              visible: true,
              isNotSortable: true,
              readonly: false,
              frozen: false,
              dataType: DataType.number,
              dataTypeOptions: { prefix: this.standardPriceCurrency.bind(this) },
              dataOptions: [],
              filterType: FilterType.none,
              colWidth: 135,
              displayFn: (rowData: Inventory) => {
                return this.normalizerService.currencyNormalizer(
                  rowData?.defaultInventoryZone?.standardCost,
                  rowData?.standardPriceCurrencyIsoCode
                );
              },
            },
            {
              field: "defaultInventoryZone.costBasis",
              header: "Cost Basis",
              headerHTML: "Cost<br>Basis",
              visible: true,
              isNotSortable: true,
              readonly: false,
              frozen: false,
              dataType: DataType.enum,
              dataOptions: this.inventoryService.get_enum_cost_basis(),
              filterType: FilterType.none,
              colWidth: 135,
            },
          ]
        : []),
      ...(this.appSettings.getStore().id
        ? [
            {
              field: "defaultInventoryStock.qtyOH",
              header: "Default OH Qty",
              headerHTML: "Default<br>OH Qty",
              visible: true,
              readonly: false,
              frozen: false,
              dataType: DataType.number,
              dataOptions: [],
              filterType: FilterType.none,
              colWidth: 80,
              isNotSortable: true,
            },
            {
              field: "defaultInventoryStock.minStock",
              header: "Min Stock Qty",
              headerHTML: "Min Stock<br>Qty",
              isNotSortable: true,
              visible: true,
              readonly: false,
              frozen: false,
              dataType: DataType.number,
              dataOptions: [],
              filterType: FilterType.none,
              colWidth: 135,
              displayFn: (rowData: Inventory) => {
                return this.normalizerService.normalizeNumbers(rowData.defaultInventoryStock?.minStock);
              },
            },
            {
              field: "defaultInventoryStock.maxStock",
              header: "Max Stock Qty",
              headerHTML: "Max Stock<br>Qty",
              visible: true,
              readonly: false,
              frozen: false,
              isNotSortable: true,
              dataType: DataType.number,
              dataOptions: [],
              filterType: FilterType.none,
              colWidth: 135,
              displayFn: (rowData: Inventory) => {
                return this.normalizerService.normalizeNumbers(rowData.defaultInventoryStock?.maxStock);
              },
            },
          ]
        : []),
      {
        field: "supplierCode",
        header: "Supplier Code",
        headerHTML: "Supplier Code",
        visible: true,
        isNotSortable: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 135,
      },
      {
        field: "inventorySuppliers[0].account.accountCode",
        header: "Supplier Acct Code",
        headerHTML: "Supplier<br>Acct Code",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 135,
      },
      ...(totalOhQtyVisible && !this.appSettings.getStore().id
        ? [
            {
              field: "inventoryStocks",
              header: "Total OH Qty",
              headerHTML: "Total<br>OH Qty",
              visible: true,
              readonly: true,
              frozen: false,
              dataType: DataType.array,
              dataTypeOptions: new ArrayDatatypeOptions({
                component: InventoryStockLevelsArrayComponent,
                displayField: this.totalQtyOH.bind(this),
                showSummary: false,
              }),
              dataOptions: [],
              filterType: FilterType.none,
              colWidth: 145,
              sortFields: ["inventoryStocks.qtyOH"],
              isNotSortable: true,
            },
          ]
        : []),
    ];
    if (parentInventory) {
      parentInventory.inventoryVariantSettings.map((_inventoryVariantSetting) => {
        cols.push({
          field:
            "inventoryVariants_detail." +
            _inventoryVariantSetting.inventoryOptionsSetting.optionName.replace(/ /g, "_"),
          header: _inventoryVariantSetting.inventoryOptionsSetting.optionName,
          visible: true,
          readonly: false,
          frozen: false,
          dataType: DataType.lookup,
          dataOptions: this.getInventoryVariantSettingsValue(_inventoryVariantSetting),
          filterType: FilterType.lookup,
          colWidth: 80,
        });
      });
    }
    // add new columns based on inventory variants of parent
    // this.dynamicFields.forEach(field => cols.push(
    //   {
    //     field, header: field, visible: true, readonly: true, frozen: false, dataType: DataType.input,
    //     dataOptions: [], filterType: FilterType.contains, colWidth: 80
    //   }
    // ));

    return cols;
  }
}
