/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable, inject } from "@angular/core";
import { ValidatorFn } from "@angular/forms";
import { Col, DataType, FilterType } from "../../../../form-list/form-list/form-list";
import { FormListModel } from "../../../../form-list/form-list/form-list-model.interface";
import { DBService } from "../../../../shared/services/db.service";
import { DataNormalizerService } from "src/app/shared/services/data-normalizes.service";
import { SaleAnalysisReport } from "./sale-analysis-report";

@Injectable({
  providedIn: "root",
})
export class SaleAnalysisReportColsFactoryService {
  constructor(public dbService: DBService, private normalizerService: DataNormalizerService) {}

  build(dynamicFields: string[]) {
    return new SaleAnalysisReportCols(this, dynamicFields, this.normalizerService);
  }
}

export class SaleAnalysisReportCols implements FormListModel {
  normalizerService: DataNormalizerService;
  constructor(
    private factoryService: SaleAnalysisReportColsFactoryService,
    private dynamicFields: string[],
    normalizerService: DataNormalizerService
  ) {
    this.normalizerService = normalizerService;
  }

  _disableCloning = true;
  getValidationRules(): { [key: string]: {} | ValidatorFn[] } {
    return {};
  }

  getFormListColumns(): Col[] {
    const cols: Col[] = [
      {
        field: "SKU",
        header: "SKU",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
      },
      {
        field: "description",
        header: "Description",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
      },
      {
        field: "qtyOH",
        header: "QTY OH",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
      },
      {
        field: "qtySold",
        header: "QTY Sold",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
      },
      {
        field: "unitSoldPrice",
        header: "Unit Sold Price",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
      },
      {
        field: "totalSoldPriceCalculated",
        header: "Total Price $",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
        footer: true,
      },
      {
        field: "standardCost",
        header: "Unit Cost",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
      },
      {
        field: "totalCostCalculated",
        header: "Total Cost $",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
        footer: true,
      },
      {
        field: "marginPercentCalculated",
        header: "Margin %",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
        displayFn: (rowData: SaleAnalysisReport) => {
          return this.normalizerService.normalizeNumbers(rowData.marginPercentCalculated, 2, 2);
        },
      },
      {
        field: "marginAmountCalculated",
        header: "Margin $",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
        footer: true,
        displayFn: (rowData: SaleAnalysisReport) => {
          return this.normalizerService.currencyNormalizer(rowData?.marginAmountCalculated, "USD");
        },
      },
      {
        field: "tags",
        header: "Inventory Tags",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.chips,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
      },
      {
        field: "categoryName",
        header: "Inventory Category",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
      },
    ];

    // this.dynamicFields.forEach(field => cols.push(
    //   {
    //     field, header: field, visible: true, readonly: true, frozen: false, dataType: DataType.input,
    //     dataOptions: [], filterType: FilterType.contains, colWidth: 80
    //   }
    // ));

    return cols;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
