<div class="topWrapper">
  <taku-form-header [toolbarTitle]="_headerTitle" displayMode="inline" [showActionBtns]="false" [showBackBtn]="false">
    <button pButton class="p-button-rounded" type="button" (click)="onClosePressed()" icon="pi pi-times"></button>
  </taku-form-header>
  <div class="bodyWrapper">
    <main>
      <div id="doc-fields">
        <table cellspacing="0">
          <tr>
            <td class="label-col">Doc#</td>
            <td class="value-col">
              {{ _saleChannelOrderDetail["vendorOrderNumber"] }}
            </td>
          </tr>
          <tr>
            <td class="label-col">Sale Channel</td>
            <td class="value-col">
              {{ _saleChannelOrder.saleChannel.channelName }}
            </td>
          </tr>
          <tr>
            <td class="label-col">Date & Time</td>
            <td class="value-col">
              {{ _saleChannelOrder.docDate | dateTimeFormat : _saleChannelOrder.docTime }}
              <!-- {{(new Date(_saleChannelOrder.createDate)).getFullYear() + "-" + ((new Date(_saleChannelOrder.createDate)).getMonth() + 1) + "-" + (new Date(_saleChannelOrder.createDate)).getDate() +","+ (new Date(_saleChannelOrder.createDate)).getHours() + ":" + (new Date(_saleChannelOrder.createDate)).getMinutes()}} -->
            </td>
          </tr>
          <tr>
            <td class="label-col">Customer Name</td>
            <td class="value-col">
              {{
                _saleChannelOrderDetail
                  ? _saleChannelOrderDetail["shippingPerson"]
                    ? _saleChannelOrderDetail["shippingPerson"]["name"]
                    : ""
                  : ""
              }}
              <!-- {{_saleChannelOrderDetail? (_saleChannelOrderDetail['billingPerson']?_saleChannelOrderDetail['billingPerson']['companyName']: ''):'' +' ' + _saleChannelOrderDetail? (_saleChannelOrderDetail['billingPerson']? _saleChannelOrderDetail['billingPerson']['name']: ''): ''}} -->
            </td>
          </tr>
          <tr>
            <td class="label-col">Customer Email</td>
            <td class="value-col">
              {{ _saleChannelOrderDetail["email"] }}
            </td>
          </tr>
          <tr>
            <td class="label-col">Link Existing Customer</td>
            <td class="value-col">
              {{ _saleChannelOrder["accountName"] }}
              <a
                href="javascript://"
                class="material-icons md-18"
                (click)="accountNameToggle($event)"
                [class.disabled-link]="_saleChannelOrderDetail['VOIDED']"
                style="float: right"
                >edit
                <p-overlayPanel #accountNamePanel [showCloseIcon]="false" appendTo="body">
                  <div class="p-inputgroup inputgroup-outlined">
                    <!-- field-searchVendor" [ngClass]="{ 'disabled-control': isReadOnly }" -->
                    <span class="p-inputgroup-addon"><i class="material-icons">group</i></span>
                    <taku-search-accounts
                      id="account_search"
                      #accountsSearchBox
                      [autoClearOnSelection]="false"
                      (itemSelected)="onAccountSelected($event)"
                      (onNewItemClicked)="openNewModelInDialog($event)"
                    ></taku-search-accounts>
                  </div>
                </p-overlayPanel>
              </a>
            </td>
          </tr>
        </table>

        <table cellspacing="0">
          <tr>
            <td class="label-col">Total</td>
            <td class="value-col">
              {{ _saleChannelOrderDetail["total"] | currency : "CAD" : "symbol-narrow" : "1.2" }}
            </td>
          </tr>
          <tr class="payType-line">
            <td class="label-col">Payment Method</td>
            <td class="value-col">
              {{ _saleChannelOrderDetail["paymentMethod"] }}
            </td>
          </tr>
          <tr>
            <td class="label-col">Customer Address</td>
            <td class="value-col">
              {{
                _saleChannelOrderDetail
                  ? _saleChannelOrderDetail["shippingPerson"]
                    ? _saleChannelOrderDetail["shippingPerson"]["street"]
                    : ""
                  : ""
              }}
              {{
                _saleChannelOrderDetail
                  ? _saleChannelOrderDetail["shippingPerson"]
                    ? _saleChannelOrderDetail["shippingPerson"]["city"]
                    : ""
                  : ""
              }}
              {{
                _saleChannelOrderDetail
                  ? _saleChannelOrderDetail["shippingPerson"]
                    ? _saleChannelOrderDetail["shippingPerson"]["stateOrProvinceCode"]
                    : ""
                  : ""
              }}
              {{
                _saleChannelOrderDetail
                  ? _saleChannelOrderDetail["shippingPerson"]
                    ? _saleChannelOrderDetail["shippingPerson"]["countryCode"]
                    : ""
                  : ""
              }}
              {{
                _saleChannelOrderDetail
                  ? _saleChannelOrderDetail["shippingPerson"]
                    ? _saleChannelOrderDetail["shippingPerson"]["postalCode"]
                    : ""
                  : ""
              }}
            </td>
          </tr>
          <tr>
            <td class="label-col">Customer Phone</td>
            <td class="value-col">
              {{ _saleChannelOrderDetail["shippingPerson"] ? _saleChannelOrderDetail["shippingPerson"]["phone"] : "" }}
              <!-- {{ _saleChannelOrderDetail['billingPerson']?(_saleChannelOrderDetail['billingPerson']['phone']):(_saleChannelOrderDetail['shippingPerson']?(_saleChannelOrderDetail['shippingPerson']['phone']):'') }} -->
            </td>
          </tr>
          <tr>
            <td class="label-col">Delivery Method</td>
            <td class="value-col">
              {{ _saleChannelOrderDetail["saleDoc"] ? _saleChannelOrderDetail["saleDoc"]["deliveryMethod"] : "" }}
            </td>
          </tr>
          <tr>
            <td class="label-col">Delivery Status</td>
            <td class="value-col">
              {{ _saleChannelOrderDetail["saleDoc"] ? _saleChannelOrderDetail["saleDoc"]["deliveryStatus"] : "" }}
            </td>
          </tr>
        </table>
      </div>
    </main>

    <taku-sale-doc-transactions
      *ngIf="_saleDoc?.saleDocLines"
      [saleDoc]="_saleDoc"
      (taxAdded)="onTaxAdded()"
    ></taku-sale-doc-transactions>

    <footer class="col-12 pb-4">
      <div class="right-btns col-12">
        <button
          pButton
          class="btn-action p-button-primary"
          label="Proceed"
          icon="pi pi-check"
          (click)="onProceedPressed()"
          [disabled]="_isProceedDisabled"
          [loading]="_isLoading"
        ></button>

        <button
          pButton
          pRipple
          class="btn-action p-button-secondary"
          label="Void"
          icon="pi pi-ban"
          (click)="onVoidPressed()"
          [disabled]="_isVoidDisabled"
        ></button>
      </div>
    </footer>
  </div>
</div>

<p-dialog
  [(visible)]="_activeFullDialog"
  [ngSwitch]="_activeFullDialog"
  [contentStyle]="{ maxHeight: '100%' }"
  styleClass="fixedsize-modal"
  [blockScroll]="true"
  [modal]="true"
  [showHeader]="false"
  appendTo="body"
>
  <div *ngSwitchCase="FullSizeDialogName.COMMERCIAL_ACCOUNT" class="new-commercialAccount-panel">
    <taku-commercial-account
      (changedForm)="onNewCommercialAccountCreated($event)"
      [_isDialog]="true"
      [_object]="_activeFullDialogExtra"
      (dialogClosed)="closeFullSizeDialog()"
    ></taku-commercial-account>
  </div>

  <div *ngSwitchCase="FullSizeDialogName.PERSONAL_ACCOUNT" class="new-personalAccount-panel">
    <taku-personal-account
      (changedForm)="onNewPersonalAccountCreated($event)"
      [_isDialog]="true"
      [_object]="_activeFullDialogExtra || undefined"
      (dialogClosed)="closeFullSizeDialog()"
    ></taku-personal-account>
  </div>
</p-dialog>

<p-dialog [modal]="true" [(visible)]="addToTAKUDialogVisible" [closable]="false" appendTo="body">
  <taku-form-header [toolbarTitle]="_headerTitle" displayMode="inline" [showActionBtns]="false" [showBackBtn]="false">
    <button
      pButton
      class="p-button-rounded"
      type="button"
      (click)="addToTAKUDialogVisible = false; this._isLoading = false"
      icon="pi pi-times"
    ></button>
  </taku-form-header>
  <p-card>
    <div class="grid">
      <div class="col-12">
        <p-radioButton
          name="accountType"
          [value]="AccountType.personal"
          label="New Personal Account"
          [(ngModel)]="accountType"
          inputId="personalAccount"
        ></p-radioButton>
      </div>
      <div class="col-12">
        <p-radioButton
          name="accountType"
          [value]="AccountType.commercial"
          label="New Commercial Account"
          [(ngModel)]="accountType"
          inputId="commercialAccount"
        ></p-radioButton>
      </div>
    </div>
    <div class="grid">
      <p class="col p-3"
        >Please note that it is only possible to link an existing customer account at this stage. Do you still want to
        continue without linking?</p
      >
    </div>
    <footer class="col-12">
      <div class="right-btns col-12">
        <button
          pButton
          pRipple
          class="btn-action p-button-secondary"
          label="No"
          (click)="addToTAKUDialogVisible = false; this._isLoading = false"
        ></button>

        <button
          pButton
          class="btn-action p-button-primary"
          label="Yes"
          (click)="createAccountAndAccept(accountType)"
        ></button>
      </div>
    </footer>
  </p-card>
</p-dialog>
