/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import {
  OptionalCancelBtnDetails,
  ProgressDialogComponent,
} from "../../components/progress-dialog/progress-dialog.component";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import _ from "lodash";

@Injectable({
  providedIn: "root",
})
export class BlockingUIService {
  showProgressBar(
    dialogService: DialogService,
    closable = false,
    _data = {},
    optionalCancelBtnDetails: OptionalCancelBtnDetails = null
  ): DynamicDialogRef {
    if (!_.isEmpty(optionalCancelBtnDetails)) {
      _data["optionalCancelBtnDetails"] = optionalCancelBtnDetails;
    }

    return dialogService.open(ProgressDialogComponent, {
      data: _data,
      width: "60%",
      closable: closable,
      dismissableMask: false,
      showHeader: closable,
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
