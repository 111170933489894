/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Location } from "@angular/common";
import { Component } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Router, Params } from "@angular/router";
import * as _ from "lodash";
import { ConfirmationService, MessageService } from "primeng/api";
import { DBMode, GenericFormComponent, GenericFormService } from "../../../forms/generic-form/generic-form.component";
import { AppSettingsStorageService } from "../../../shared/app-settings-storage.service";
import { AlertMessagesService } from "../../../shared/services/alert-messages.service";
import { CashoutClosingBreakdownComponent } from "../cashout-closing-breakdown/cashout-closing-breakdown.component";
import { CashoutClosingFloatComponent } from "../cashout-closing-float/cashout-closing-float.component";
import { CashoutOpeningFloatComponent } from "../cashout-opening-float/cashout-opening-float.component";
import { DBCashoutService } from "../db-cashout.service";
import { Cashout } from "./cashout";
import { CommonValidators } from "src/app/shared/validators/CommonValidators";
import { StoreSettingCashout } from "../../settings/store-settings/cashout-settings/CashoutSettings";
import { map } from "rxjs/operators";
import { TimeHelpers } from "src/app/utility/TimeHelpers";

enum CashoutActiveWorkflow {
  OPEN_CASHOUT,
  CLOSE_CASHOUT,
}

@Component({
  selector: "taku-cashout",
  templateUrl: "./cashout.component.html",
  styleUrls: ["./cashout.component.scss"],
  providers: [GenericFormService],
})
export class CashoutComponent extends GenericFormComponent {
  activeCashoutWorkFlow: CashoutActiveWorkflow = CashoutActiveWorkflow.OPEN_CASHOUT;
  cashoutPageTitle: string;
  closingBreakdownArray: UntypedFormArray;

  CashoutActiveWorkflow = CashoutActiveWorkflow;

  constructor(
    protected _router: Router,
    protected fb: UntypedFormBuilder,
    protected dbService: DBCashoutService,
    protected location: Location,
    protected _route: ActivatedRoute,
    protected messageService: MessageService,
    protected alertMessage: AlertMessagesService,
    protected confirmationService: ConfirmationService,
    appSettings: AppSettingsStorageService,
    public genericFormService: GenericFormService
  ) {
    super(
      _router,
      fb,
      dbService,
      location,
      _route,
      messageService,
      alertMessage,
      confirmationService,
      appSettings,
      genericFormService
    );
  }

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    const tmpForm = fb.group(
      Object.assign(new Cashout(), {
        openingDateTime: null,
        closingDateTime: null,
      })
    );

    tmpForm.setControl("cashoutOpeningFloats", fb.array([]));
    tmpForm.setControl("cashoutClosingFloats", fb.array([]));
    tmpForm.setControl("cashoutClosingBreakDowns", fb.array([]));

    return tmpForm;
  }

  initForm(): void {
    this._model = "cashout";
    this._object = new Cashout();
    this._myForm = CashoutComponent.init(this.fb);

    this.subsList.push(
      this._myForm.get("openingDateTime").valueChanges.subscribe((newDate) => {
        if (!newDate) return;

        const dateParts = TimeHelpers.SplitDateAndTime(newDate, true, false);
        this._myForm.patchValue({
          openingDate: dateParts[0],
          openingTime: dateParts[1],
        });
      })
    );

    this.subsList.push(
      this._myForm.get("closingDateTime").valueChanges.subscribe((newDate) => {
        if (!newDate) return;

        const dateParts = TimeHelpers.SplitDateAndTime(newDate, true, false);
        this._myForm.patchValue({
          closingDate: dateParts[0],
          closingTime: dateParts[1],
        });
      })
    );
  }

  private _setModelRelationships(formGroup: UntypedFormGroup): void {
    if (this.dbMode === DBMode.insert) {
      formGroup.patchValue({
        storeId: this.appSettingsService.getStoreId(),
        stationId: this.appSettingsService.getStationId(),
        openingCashFloatUserId: this.appSettingsService.getUserId(),
        closingCashFloatUserId: null,
      });
      this.subsList.push(
        this.appSettingsService
          .getStoreSettings("storeSettingCashout")
          .pipe(map((storeCashout) => (_.isEmpty(storeCashout) ? new StoreSettingCashout() : storeCashout)))
          .subscribe((cashoutSettings: StoreSettingCashout) => {
            formGroup.patchValue({
              cashCalculationType: cashoutSettings.cashCalculationType,
              tillCashoutClosingType: cashoutSettings.tillCashoutClosingType,
            });
          })
      );
    } else {
      formGroup.patchValue({
        closingCashFloatUserId: this.appSettingsService.getUserId(),
      });
    }
  }

  openCashout(): void {
    this.onSave();
  }

  closeCashout(): void {
    this.onSave();
  }

  onSave(): void {
    this.subsList.push(
      this.onSubmit(true).subscribe((any) => {
        if (this._object.isClosed) this.messageService.add(this.alertMessage.getMessage("finalize-success", "Cashout"));
        else this.messageService.add(this.alertMessage.getMessage("save-success", "Opening Float"));
      })
    );
  }

  loadObject(): void {
    super.loadObject();
    this.closingBreakdownArray = <UntypedFormArray>this._myForm.get("cashoutClosingBreakDowns");

    if (this.dbMode === DBMode.edit) {
      this.activeCashoutWorkFlow = CashoutActiveWorkflow.CLOSE_CASHOUT;
      this.cashoutPageTitle = "Close Cashout";
    } else {
      this.cashoutPageTitle = "Open Cashout";
    }

    // if (this._id === 0) // Only set default time if opening cashout
    //   this._myForm.patchValue({
    //     'openingDateTime': moment().add(1, 'minute').toDate(),
    //   });
    // else {
    //   this._myForm.patchValue({ // Only set default time and payment breakdown if closing cashout
    //     'closingDateTime': new Date,
    //   });
    // }

    this._setModelRelationships(this._myForm);

    // When Cashout has been saved
    this.subsList.push(
      this.changedForm.subscribe((cashout: Cashout) => {
        if (!cashout.isClosed) {
          // If cashout is open, show alert and redirect to closed cashout route
          this.messageService.add({
            severity: "success",
            summary: "Cashout is now OPEN!",
            detail: "You can start making sales.",
          });

          this.appSettingsService.setCashout(cashout);
          setTimeout(() => {
            let _ref = "";
            let _refStoreId = "";
            this._route.params.forEach((params: Params) => {
              // if (!params['detailmode']) {
              // this._model = params['model'];
              _ref = params["ref"];
              _refStoreId = params["storeId"];
              // }
            });
            switch (_ref) {
              case "saleDocument":
                this._router.navigate(["/sell/saleDoc", 0]); // Navigate to sales screen
                break;
              case "returnDocument":
                this._router.navigate(["/sell/saleDoc/returns", 0]); // Navigate to return screen
                break;
              case "saleChannelOrder":
                this._router.navigate(["/sale-channel/orders", _refStoreId]); // Navigate to return screen
                break;
              case "kiosk":
                this._router.navigate(["/kiosk/selfCheckout/landingPage"]); // Navigate to kiosk
                break;
              default:
                this._router.navigate(["/sell/saleDoc", 0]); // Navigate to sales screen
                break;
            }
          }, 1000);
        } else {
          this.messageService.add({
            severity: "success",
            summary: "Cashout is now CLOSED.",
            detail: "To make sales, please open a new cashout.",
          });
          // If Cashout has been close, so require to open it again
          this.appSettingsService.setCashout(cashout);
          setTimeout(() => {
            this._router.navigate(["/dashboard"]);
          }, 1000);
        }
      })
    );
  }

  setFormArrays(): void {
    this._myForm.setControl(
      "cashoutOpeningFloats",
      CashoutOpeningFloatComponent.setArray(this.fb, this._object.cashoutOpeningFloats)
    );
    this._myForm.setControl(
      "cashoutClosingFloats",
      CashoutClosingFloatComponent.setArray(this.fb, this._object.cashoutClosingFloats)
    );
    this._myForm.setControl(
      "cashoutClosingBreakDowns",
      CashoutClosingBreakdownComponent.setArray(this.fb, this._object.cashoutClosingBreakDowns)
    );
  }

  initValidation(): void {
    this._validation = {
      openingDateTime: [CommonValidators.currentTimeValidator(false, true)],
    };
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
