/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { GenericFormComponent } from "../../../forms/generic-form/generic-form.component";
import { Validators, UntypedFormBuilder } from "@angular/forms";
import { TaxAccountCategory } from "./tax-account-category";
import { TaxAccountCategoryService } from "./tax-account-category.service";
import { Location } from "@angular/common";
import { Router, ActivatedRoute } from "@angular/router";
import { DBService } from "../../../shared/services/db.service";
import { MessageService, ConfirmationService } from "primeng/api";
import { AlertMessagesService } from "../../../shared/services/alert-messages.service";
import { AppSettingsStorageService } from "../../../shared/app-settings-storage.service";
import { AppComponent } from "src/app/app.component";

@Component({
  selector: "taku-tax-account-category",
  templateUrl: "./tax-account-category.component.html",
  styleUrls: ["./tax-account-category.component.scss"],
})
export class TaxAccountCategoryComponent extends GenericFormComponent {
  @Output() dialogClosed = new EventEmitter<any>();

  initForm() {
    this._model = "taxAccountCategory";
    this._object = new TaxAccountCategory();
    this._myForm = this.fb.group(new TaxAccountCategory());
  }

  constructor(
    public taxCategoryService: TaxAccountCategoryService,
    protected _router: Router,
    public fb: UntypedFormBuilder,
    public dbService: DBService,
    protected location: Location,
    public _route: ActivatedRoute,
    protected messageService: MessageService,
    protected alertMessage: AlertMessagesService,
    protected confirmationService: ConfirmationService,
    appSettings: AppSettingsStorageService
  ) {
    super(_router, fb, dbService, location, _route, messageService, alertMessage, confirmationService, appSettings);
  }

  initValidation() {
    this._validation = this.taxCategoryService.getValidationRules();
  }

  closeModal() {
    this.dialogClosed.emit();
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
