<ng-container *ngTemplateOutlet="tenderLine; context: { $implicit: _myForm.value }"></ng-container>

<ng-template let-docTender #tenderLine>
  <div class="lineWrapper">
    <div class="payment-type"></div>
    <div class="tender-field transaction-time" *ngIf="docTender.createdAt">
      <div class="inner-content">
        <ng-container>{{ docTender.createdAt | date : "short" }}</ng-container>
        <!-- <ng-template *ngIf="docTender.createdAt">{{docTender.createdAt|dateTimeFormat:null:true}}</ng-template> -->
        <!-- <ng-template [ngIf]="!docTender.createdAt">---</ng-template> -->
      </div>
    </div>
    <div class="tender-field transaction-tender-type">
      <div class="transaction-tender-name">
        {{ docTender.tenderType.description }}
      </div>
      <ng-template [ngIf]="!docTender.refTransaction">
        <div class="transaction-ref-no"
          >Ref. #:
          <a href="javascript://">{{
            docTender.voucher?.uniqueCode || docTender.refNo || "Set for transaction"
          }}</a></div
        >
      </ng-template>

      <ng-template [ngIf]="docTender.refTransaction">
        <div *ngIf="docTender.refTransaction.TakuDisplayStatus" class="transaction-ref-no"
          >Status:
          <a href="javascript://" (click)="openReferenceDetailsDialog(docTender.refTransaction)">
            {{ docTender.refTransaction.TakuDisplayStatus }}
          </a>
        </div>
        <div *ngIf="docTender.refTransaction.TakuDisplayStatus">
          <button
            pButton
            type="button"
            icon="pi pi-print"
            class="p-button p-button-rounded p-button-outlined p-button-icon-only"
            (click)="onPrintPaymentReceiptClicked.emit(docTender)"
          >
          </button>
        </div>
        <div *ngIf="!inSingleLineRange" class="transaction-status-container">
          <div *ngIf="docTender.refTransaction.AuthCode" class="transaction-authCode">
            Auth Code: {{ docTender.refTransaction.AuthCode }}
          </div>
          <div *ngIf="docTender.refTransaction.CardType" class="transaction-cardType">
            Card Type: {{ docTender.refTransaction.CardType }}
          </div>
          <div *ngIf="docTender.refTransaction.Last4" class="transaction-pan">
            PAN: {{ docTender.refTransaction.Last4 }}
          </div>
          <div *ngIf="docTender.refTransaction.RefID" class="transaction-refId">
            Ref. #: {{ docTender.refTransaction.RefID }}
          </div>
        </div>
      </ng-template>
    </div>
    <div *ngIf="docTender.refTransaction && inSingleLineRange" class="transaction-status-container">
      <div *ngIf="docTender.refTransaction.AuthCode" class="transaction-authCode">
        Auth Code: {{ docTender.refTransaction.AuthCode }}
      </div>
      <div *ngIf="docTender.refTransaction.CardType" class="transaction-cardType">
        Card Type: {{ docTender.refTransaction.CardType }}
      </div>
      <div *ngIf="docTender.refTransaction.Last4" class="transaction-pan">
        PAN: {{ docTender.refTransaction.Last4 }}
      </div>
      <div *ngIf="docTender.refTransaction.RefID" class="transaction-refId">
        Ref. #: {{ docTender.refTransaction.RefID }}
      </div>
    </div>
    <div class="tender-field transaction-amount">
      {{
        docTender.amount
          | currency : docTender.tenderType.currencyIsoCode : "symbol-narrow" : decimalFormat
          | formatNegativeNumber
      }}
    </div>
  </div>
</ng-template>
