/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { EventEmitter, Injectable } from "@angular/core";
import { ValidatorFn } from "@angular/forms";
import {
  DataType,
  ExecuteEvent_DisplayMode,
  FilterType,
  ExecuteEventDatatypeOptions,
  Col,
} from "../../../../form-list/form-list/form-list";
import { FormListModel } from "../../../../form-list/form-list/form-list-model.interface";
import { DBService } from "../../../../shared/services/db.service";
import { SelectItem } from "primeng/api";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { DocState, DocStateLabels } from "../../doc/doc";
import { SaleDoc } from "../sale-doc/sale-doc";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ParkedSalesFactoryService {
  constructor(protected dbService: DBService) {}

  build(openSaleEvent: EventEmitter<any>, appSettings: AppSettingsStorageService): ParkedSalesService {
    return new ParkedSalesService(this.dbService, openSaleEvent, appSettings);
  }
}

export class ParkedSalesService implements FormListModel {
  enumDocStates = this.dbService.enumSelectOptions(this.dbService.getMappedEnum(DocState, DocStateLabels));

  constructor(
    protected dbService: DBService,
    private openSaleEvent: EventEmitter<any>,
    private appSettings: AppSettingsStorageService
  ) {}

  getLookup_stations(): Observable<SelectItem[]> {
    return this.dbService.lookupSelectOptions("station", "stationName", {
      lookupFilter: JSON.stringify({
        storeId: { value: this.appSettings.getStoreId(), matchMode: "equals" },
      }),
      enableFieldName: "isActive",
    });
  }

  getValidationRules(): { [key: string]: {} | ValidatorFn[] } {
    return {};
  }

  getFormListColumns(): Col[] {
    return [
      {
        field: "basketButton",
        header: "",
        visible: true,
        readonly: true,
        frozen: true,
        dataType: DataType.execute_event,
        dataTypeOptions: [
          new ExecuteEventDatatypeOptions({
            icon: "_",
            iconName: "shopping_basket",
            event: this.openSaleEvent,
            displayMode: ExecuteEvent_DisplayMode.BUTTON,
            fnIsRowDisabled: (rowData: SaleDoc) => rowData.doc.state === DocState.voided,
          }),
        ],
        dataOptions: [],
        filterType: FilterType.none,
        isNotSortable: true,
        colWidth: 57,
      },
      // { field: 'doc.docNo', header: 'Doc #', visible: true, readonly: true, frozen: false, dataType: DataType.input, dataOptions: [], filterType: FilterType.contains, colWidth: 75 },
      {
        field: "doc.state",
        header: "State",
        visible: true,
        readonly: true,
        frozen: true,
        dataType: DataType.enum,
        dataOptions: this.enumDocStates,
        filterType: FilterType.enum,
        colWidth: 87,
      },
      {
        field: "doc.docDate",
        header: "Parked Date",
        visible: true,
        readonly: true,
        frozen: true,
        dataType: DataType.date_date_only,
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 90,
      },
      {
        field: "dailyNo",
        header: "Daily #",
        visible: true,
        readonly: true,
        frozen: true,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.none,
        isNotSortable: true,
        colWidth: 42,
      },
      {
        field: "doc.localDocTime",
        header: "Time",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.date_time,
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 62,
        sortFields: ["doc.docDate", "doc.docTime"],
      },
      {
        field: "accountName",
        header: "Account",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.none,
        isNotSortable: true,
        colWidth: 100,
      },
      {
        field: "grandTotalCurrencyFormat",
        header: "Total",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.none,
        isNotSortable: true,
        colWidth: 85,
      },
      {
        field: "station.id",
        header: "Station Name",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.lookup,
        dataOptions: this.getLookup_stations(),
        filterType: FilterType.lookup,
        colWidth: 180,
        sortFields: ["station.stationName"],
      },
      {
        field: "doc.user.userName",
        header: "User",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 60,
      },
    ];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
