/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { AfterContentInit, Component, EventEmitter, Input, Output, OnDestroy } from "@angular/core";
import { GenericFormComponent } from "../generic-form/generic-form.component";
import { Subscription } from "rxjs";

@Component({
  selector: "taku-form-dialoge-select",
  templateUrl: "./form-dialoge-select.component.html",
  styleUrls: ["./form-dialoge-select.component.scss"],
})
export class FormDialogeSelectComponent implements AfterContentInit, OnDestroy {
  subsList: Subscription[] = [];

  @Input() model: string;
  @Input() id: number;
  @Input() object: any;
  @Input() detailedViewComponent?: any;
  @Output() changedForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() dialogClosed: EventEmitter<any> = new EventEmitter<any>();

  // Update component with params for proper visualization inside dialog
  ngAfterContentInit(): void {
    if (!this.detailedViewComponent) return;

    this.detailedViewComponent._object = this.object;
    if (this.detailedViewComponent.dialogClosed instanceof EventEmitter)
      this.subsList.push(this.detailedViewComponent.dialogClosed.subscribe(() => this._dialogClosed()));

    if (this.detailedViewComponent instanceof GenericFormComponent) {
      this.detailedViewComponent._isDialog = true;
      this.subsList.push(this.detailedViewComponent.changedForm.subscribe(($event: any) => this._formChanged($event)));
    }
  }

  _formChanged(event) {
    this.changedForm.emit(event);
  }

  _dialogClosed() {
    this.dialogClosed.emit();
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
