<div class="topWrapper">
  <div class="bodyWrapper">
    <div class="col-12">
      <span class="p-float-label">
        <input
          #firstField="ngModel"
          type="{{ _inputType }}"
          [(ngModel)]="value"
          required="{{ _required }}"
          pattern="{{ _pattern }}"
          pInputText
          email="{{ _inputType === 'email' }}"
        />
        <label for="{{ _caption }}" [innerHTML]="_caption"></label>
        <i *ngIf="value" class="material-icons clear-field" (click)="clear()">close</i>
        <!-- <div *ngIf="firstField.errors && (firstField.invalid || firstField.touched)">
          <small class="text-danger" *ngIf="firstField.errors.required">This field required</small>
          <small class="text-danger" *ngIf="firstField.errors.pattern">Please enter a valid {{_inputType==='email'? 'Email address':'value'}}</small>
        </div> -->
      </span>
      <form-validation-messages
        *ngIf="firstField.errors"
        [hidden]="!firstField.errors"
        [errors]="firstField.errors"
        [fieldName]="''"
      ></form-validation-messages>
    </div>
    {{ _message }}
    <footer class="col-12">
      <!-- <button pButton label="Cancel" (click)="onCancelPressed()"></button> -->
      <button pButton label="{{ _acceptLabel }}" (click)="onAcceptPressed()" [disabled]="firstField.invalid"></button>
    </footer>
  </div>
</div>
