/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { UntypedFormArray, UntypedFormBuilder } from "@angular/forms";
import { FormDataHelpers } from "../../../utility/FormDataHelpers";
import { InventoryZone } from "../inventory/inventory";

export class InventoryZoneForm {
  static setArray(fb: UntypedFormBuilder, inventoryZones: InventoryZone[], validationRules: any) {
    const formArray = fb.array([]);
    inventoryZones.map((inventoryZone) => {
      const temp = InventoryZoneForm.set(fb, inventoryZone, validationRules);
      formArray.push(temp);
    });
    return formArray;
  }

  static set(fb: UntypedFormBuilder, inventoryZone: InventoryZone, validationRules: any) {
    const formGroup = fb.group(inventoryZone);
    // Set validation for zones
    FormDataHelpers.applyValidationToForm(formGroup, validationRules);

    return formGroup;
  }

  static initArray(fb: UntypedFormBuilder): UntypedFormArray {
    const formArray = fb.array([]);

    return formArray;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
