<taku-form-header
  [toolbarTitle]="_pageTitle"
  displayMode="inline"
  [showActionBtns]="false"
  [showBackBtn]="true"
  (backClicked)="goBack()"
>
  <span
    class="doc-state"
    [ngClass]="
      _myForm?.get('doc.state')?.value ? 'text-' + _myForm?.get('doc.state').value.replace('', '') + '-Color' : ''
    "
  >
    {{ _myForm?.get("doc.state").value }}
  </span>
</taku-form-header>

<div *ngIf="_myForm" class="col-12">
  <div class="grid">
    <div class="col-12 md:col-6 xl:col-3">
      <p-card class="card-header card-tableheader auto-height">
        <ng-template pTemplate="header">
          <h2 class="col">Receiving Details </h2>
        </ng-template>
        <div class="grid">
          <div class="col-12 field-stockName">
            <taku-dropdown
              [formGroup]="_myForm"
              [options]="$lookup_stocks | async"
              fieldName="stockId"
              fieldCaption="Receiving Location"
              [disabled]="isReadOnly || _isSaving"
              [readonly]="true"
            ></taku-dropdown>
          </div>
          <div class="col-12">
            <!-- <taku-input [formGroup]="_myForm.controls['doc']" fieldName="docNo" fieldCaption="Doc #" [disabled]="isReadOnly">
          </taku-input> -->
            <div
              class="p-inputgroup inputgroup-outlined field-searchVendor"
              [ngClass]="{ 'disabled-control': isReadOnly }"
            >
              <span class="p-inputgroup-addon"><i class="material-icons">group</i></span>
              <taku-search-accounts
                id="account_search"
                #accountsSearchBox
                [autoClearOnSelection]="false"
                (onSelectedItemCleared)="clearAccountField()"
                [placeholder]="_searchVendorPlaceholder"
                (itemSelected)="onSearchResAccount($event)"
                (onNewItemClicked)="openNewModelInDialog($event)"
                [accountType]="AccountType.commercial"
                [accountRelationship]="[AccountRelationship.supplier, AccountRelationship.both]"
                [disabled]="true"
              >
                <!-- [disabled]="isReadOnly" -->
              </taku-search-accounts>
            </div>
          </div>

          <div class="col-6">
            <taku-calendar-date
              [formGroup]="_myForm.controls['doc']"
              fieldName="docDate"
              fieldCaption="Receiving Date"
              [disabled]="isReadOnly || _isSaving"
            >
            </taku-calendar-date>
          </div>

          <div class="col-6">
            <taku-calendar-time
              id="receiving-time"
              [formGroup]="_myForm.controls['doc']"
              fieldName="docTime"
              fieldCaption="Receiving Time"
              [disabled]="isReadOnly || _isSaving"
            >
            </taku-calendar-time>
          </div>

          <div class="col-6">
            <taku-dropdown
              [formGroup]="_myForm"
              [options]="$lookup_shippingTerms | async"
              fieldName="shippingTermId"
              fieldCaption="Shipping Terms"
              [disabled]="isReadOnly || _isSaving"
            ></taku-dropdown>
          </div>

          <div class="col-6">
            <taku-input
              [formGroup]="_myForm"
              fieldName="shipVia"
              fieldCaption="Ship Via"
              [disabled]="isReadOnly || _isSaving"
            >
            </taku-input>
          </div>
        </div>
      </p-card>
    </div>
    <div class="col-12 md:col-6 xl:col-3">
      <p-card class="card-header card-tableheader auto-height">
        <ng-template pTemplate="header">
          <h2 class="col">Notes </h2>
        </ng-template>

        <div class="col field-comment">
          <taku-input-textarea
            [formGroup]="_myForm.controls['doc']"
            fieldName="comment"
            fieldCaption="Internal Notes"
            [rows]="5"
            [disabled]="isReadOnly || _isSaving"
          >
          </taku-input-textarea>
        </div>
      </p-card>
    </div>
    <div class="col-12 xl:col-6">
      <p-card class="card-header card-tableheader">
        <ng-template pTemplate="header">
          <h2 class="col">Stock Receipt Details </h2>
        </ng-template>
        <div class="grid">
          <div class="col-12 md:col-6 grid">
            <div class="col-12">
              <taku-input
                [formGroup]="_myForm.controls['doc']"
                fieldName="docNo"
                fieldCaption="Stock Receipt #"
                [disabled]="isReadOnly || _isSaving"
              >
              </taku-input>
            </div>

            <div class="col-6 field-currency">
              <taku-currency
                [formGroup]="_myForm.controls['doc']"
                fieldName="currencyIsoCode"
                fieldCaption="Currency"
                [disabled]="isReadOnly || _isSaving"
                [readonly]="true"
              >
              </taku-currency>
            </div>

            <div class="col-6 field-currency">
              <div class="input-group">
                <taku-input
                  [formGroup]="_myForm.controls['doc']"
                  fieldName="currencyConversionRate"
                  fieldCaption="Currency Conversion"
                  [disabled]="isReadOnly || _isSaving"
                  [readonly]="true"
                >
                </taku-input>
                <button
                  type="button"
                  pButton
                  icon="pi pi-pencil"
                  class="edit-button ml-1"
                  [disabled]="isReadOnly || _isSaving"
                  (click)="toggleOverlay('currencyConversionRate', $event); onCurrencyConversionRateClicked()"
                ></button>
              </div>
            </div>

            <div class="col-12">
              <taku-dropdown
                [formGroup]="_myForm"
                [options]="$lookup_billToLocations | async"
                fieldName="locationId"
                fieldCaption="Bill to Location"
                [disabled]="isReadOnly"
                [group]="true"
              ></taku-dropdown>
            </div>

            <div class="col-6">
              <taku-input
                [formGroup]="_myForm"
                fieldName="buyerName"
                fieldCaption="Buyer's Name"
                [disabled]="isReadOnly"
              >
              </taku-input>
            </div>

            <div class="col-6">
              <taku-dropdown
                [formGroup]="_myForm"
                [options]="$lookup_paymentTerms | async"
                fieldName="paymentTermId"
                fieldCaption="Payment Terms"
                [disabled]="isReadOnly || _isSaving"
              ></taku-dropdown>
            </div>
          </div>
          <div class="col-12 md:col-6 xl:col-6 grid">
            <div class="col-6 p-fluid">
              <button
                label="Save Draft"
                pButton
                class="p-button-secondary"
                (click)="onSaveDraft()"
                [disabled]="
                  isReadOnly ||
                  _isSaving ||
                  _myForm.get('doc.state').value !== DocState.draft ||
                  isInvalidForm ||
                  isPristineForm
                "
                [loading]="isSavingDraft && _isSaving"
              ></button>
            </div>

            <div class="col-6 col-offset-0 p-fluid">
              <button
                label="Finalize"
                pButton
                (click)="onDonePressed()"
                [disabled]="
                  isReadOnly ||
                  isInvalidForm ||
                  !(isPristineForm && _myForm.get('doc.state').value === DocState.draft) ||
                  isFinalizing ||
                  _isSaving
                "
                [loading]="isFinalizing && _isSaving"
              ></button>
              <!--                 [disabled]="
                  isReadOnly || isInvalidForm || !(isPristineForm && _myForm.get('doc.state').value === DocState.draft)
                " -->
              <!-- User must save draft before able to finalize ^ -->
            </div>
            <div class="col-12 subTotal-container">
              <div class="card">
                <div class="col-12 invoice-summary">
                  <div class="line">
                    <div class="col1">
                      <b>Sub-Total</b>
                    </div>
                    <div class="col2">
                      <b>
                        {{ _myForm.get("zone.defaultCurrencyIsoCode").value }}
                        {{ inventoryDocSubTotal | number : _moneyFormat }}</b
                      >
                    </div>
                  </div>

                  <div class="line general-discount-line">
                    <div
                      class="col1 subTotalDialogAction"
                      [ngClass]="{ readonly: isReadOnly }"
                      (click)="toggleOverlay('discount', $event); onDiscAmountClicked()"
                    >
                      <ins>- Discount</ins
                      ><span
                        class="hidden"
                        *ngIf="
                          _myForm.get('discountType').value === 'Percentage' && _myForm.get('discountAmount').value
                        "
                        >( {{ _myForm.get("discountAmount").value | number : _decimalFormatting }}% )</span
                      >
                    </div>
                    <div class="col2"
                      >{{ _myForm.get("zone.defaultCurrencyIsoCode").value }}
                      {{ inventoryDocGeneralDiscount | number : _moneyFormat }}</div
                    >

                    <div class="overlay-container card grid" [hidden]="!_overlaysVisibility.discount">
                      <p-button
                        type="button"
                        class="p-button-rounded p-button p-button-icon-only close-btn"
                        icon="pi pi-times"
                        (onClick)="closeOverlay('discount')"
                      ></p-button>

                      <div class="col-12 general_disccount-label">
                        <h4>Discount on Sub-Total</h4>
                      </div>
                      <div class="p-fluid col-12 sm:col-4 chg-discount-type" [style.marginTop]="'3px'">
                        <form [formGroup]="discountForm">
                          <p-selectButton
                            [options]="enumDiscountType"
                            [formGroup]="discountForm"
                            formControlName="discountType"
                          ></p-selectButton>
                        </form>
                      </div>
                      <div class="col-12 sm:col-8 chg-discount">
                        <div class="p-inputgroup chg-amount">
                          <span
                            *ngIf="discountForm.get('discountType').value === 'Percentage'"
                            class="p-inputgroup-addon chg-border"
                            >%</span
                          >
                          <span
                            *ngIf="discountForm.get('discountType').value === 'Fixed'"
                            class="p-inputgroup-addon chg-border"
                          >
                            {{ _myForm.get("doc.currencyIsoCode").value }}
                          </span>
                          <taku-input
                            #generalDiscountAmount
                            (click)="onDiscAmountClicked()"
                            [formGroup]="discountForm"
                            fieldName="discountAmount"
                            [fieldCaption]="discountForm.get('discountAmount').errors ? '' : 'Amount'"
                            (onBlur)="setZeroIfEmpty('discountAmount')"
                            inputmode="numeric"
                          ></taku-input>
                          <span class="p-inputgroup-addon pt-0">
                            <p-button
                              icon="takuicon-calculator"
                              styleClass="squared-corners"
                              (onClick)="numpadDiscount.open()"
                            >
                            </p-button>
                            <taku-numpad
                              #numpadDiscount
                              padTitle="Enter Discount"
                              [numpadType]="
                                discountForm.get('discountType').value === 'Percentage' ? 'percentage' : 'monetary'
                              "
                              [initialQty]="discountForm.get('discountAmount').value"
                              [maximumQty]="maxDiscountAmount"
                              (submitQty)="onNumpadDiscountAmount($event)"
                            ></taku-numpad>
                          </span>
                        </div>
                      </div>
                      <div class="col-12 grid">
                        <span class="col-9"> </span>
                        <button
                          class="col-3"
                          pButton
                          label="Apply"
                          (click)="onDiscountApplyClick(); closeOverlay('discount')"
                        >
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="line general-taxes-line">
                    <div
                      class="col1 subTotalDialogAction"
                      [ngClass]="{ readonly: isReadOnly }"
                      (click)="toggleOverlay('taxes', $event)"
                    >
                      <ins>+ Taxes</ins>
                    </div>
                    <div class="col2"
                      >{{ _myForm.get("zone.defaultCurrencyIsoCode").value }}
                      {{ inventoryDocTotalTax | number : _moneyFormat }}</div
                    >

                    <div class="overlay-container card" [hidden]="!_overlaysVisibility.taxes">
                      <p-button
                        type="button"
                        class="p-button-rounded p-button-icon-only p-button close-btn"
                        icon="pi pi-times"
                        (onClick)="closeOverlay('taxes')"
                      ></p-button>
                      <div class="col-12 salesTaxes-overlay">
                        <table *ngIf="groupedTaxes.length">
                          <ng-template ngFor [ngForOf]="groupedTaxes" let-tax>
                            <tr class="tax-info">
                              <td class="tax-desc">
                                <span>{{
                                  tax?.saleTaxRule?.ruleName
                                    ? tax?.saleTaxRule?.ruleName
                                    : tax?.saleTaxRule?.tax?.shortDesc
                                }}</span>
                              </td>
                              <td class="tax-amount">
                                <span>
                                  {{ _myForm.get("zone.defaultCurrencyIsoCode").value }}
                                  {{ tax?.total | number : _moneyFormat }}</span
                                >
                              </td>
                            </tr>

                            <tr class="separator">
                              <td colspan="2">
                                <hr />
                              </td>
                            </tr>
                          </ng-template>
                        </table>

                        <div *ngIf="!groupedTaxes.length" class="no-taxes">There are no taxes for this sale yet.</div>
                      </div>
                    </div>
                  </div>
                  <div class="line general-shipping-line">
                    <div
                      class="col1 subTotalDialogAction"
                      [ngClass]="{ readonly: isReadOnly }"
                      (click)="toggleOverlay('shipping', $event); onShippingAmountClicked()"
                    >
                      <ins>+ Freight</ins
                      ><span class="hidden" *ngIf="_myForm.get('shipperChargeAmount').value"
                        >( {{ _myForm.get("shipperChargeAmount").value | number : _decimalFormatting }}% )</span
                      >
                    </div>
                    <div class="col2">
                      {{ _myForm.get("zone.defaultCurrencyIsoCode").value }}
                      {{
                        _myForm.get("shipperChargeAmount").value * _myForm.get("doc.currencyConversionRate").value
                          | number : _moneyFormat
                      }}</div
                    >

                    <div class="overlay-container card grid" [hidden]="!_overlaysVisibility.shipping">
                      <p-button
                        type="button"
                        class="p-button-rounded p-button p-button-icon-only close-btn"
                        icon="pi pi-times"
                        (onClick)="closeOverlay('shipping')"
                      ></p-button>

                      <div class="col-12 general_shipping-label">
                        <h4>Freight on Sub-Total</h4>
                      </div>
                      <div class="col-12 sm:col-8 chg-discount">
                        <div class="p-inputgroup chg-amount">
                          <span class="col mt-3">{{ _myForm.get("doc.currencyIsoCode").value }}</span>
                          <taku-input
                            #generalShippingAmount
                            (click)="onShippingAmountClicked()"
                            [formGroup]="shippingForm"
                            fieldName="shipperChargeAmount"
                            [fieldCaption]="'Freight'"
                            (onBlur)="setZeroIfEmpty('shipperChargeAmount')"
                            inputmode="numeric"
                          ></taku-input>
                          <span class="p-inputgroup-addon pt-0">
                            <p-button
                              icon="takuicon-calculator"
                              styleClass="squared-corners"
                              (onClick)="numpadShipping.open()"
                            >
                            </p-button>
                            <taku-numpad
                              #numpadShipping
                              padTitle="Enter Freight"
                              [numpadType]="'monetary'"
                              [initialQty]="shippingForm.get('shipperChargeAmount').value"
                              (submitQty)="onNumpadShippingAmount($event)"
                            ></taku-numpad>
                          </span>
                        </div>
                      </div>
                      <div class="col-12 grid">
                        <span class="col-9"> </span>
                        <button
                          class="col-3"
                          pButton
                          label="Apply"
                          (click)="onShippingApplyClick(); closeOverlay('shipping')"
                        >
                        </button>
                      </div>
                    </div>
                    <div class="overlay-container card grid" [hidden]="!_overlaysVisibility.currencyConversionRate">
                      <p-button
                        type="button"
                        class="p-button-rounded p-button p-button-icon-only close-btn"
                        icon="pi pi-times"
                        (onClick)="closeOverlay('currencyConversionRate')"
                      ></p-button>

                      <div class="col-12 general_shipping-label">
                        <h4>Currency Conversion Rate</h4>
                      </div>
                      <div class="col-12 sm:col-8 chg-currency-conversion-rate">
                        <div class="p-inputgroup chg-amount">
                          <taku-input
                            #generalCurrencyConversionRate
                            (click)="onCurrencyConversionRateClicked()"
                            [formGroup]="currencyConversionRateForm"
                            fieldName="currencyConversionRate"
                            [fieldCaption]="'Currency Conversion Rate'"
                            (onBlur)="setZeroIfEmpty('doc.currencyConversionRate')"
                            inputmode="numeric"
                          ></taku-input>
                          <span class="p-inputgroup-addon pt-0">
                            <p-button
                              icon="takuicon-calculator"
                              styleClass="squared-corners"
                              (onClick)="numpadCurrencyConversionRate.open()"
                            >
                            </p-button>
                            <taku-numpad
                              #numpadCurrencyConversionRate
                              padTitle="Enter Currency Conversion Rate"
                              [numpadType]="'monetary'"
                              [initialQty]="currencyConversionRateForm.get('currencyConversionRate').value"
                              (submitQty)="onNumpadCurrencyConversionRate($event)"
                            ></taku-numpad>
                          </span>
                        </div>
                      </div>
                      <div class="col-12 grid">
                        <span class="col-9"> </span>
                        <button
                          class="col-3"
                          pButton
                          label="Apply"
                          (click)="onCurrencyConversionRateApplyClick(); closeOverlay('currencyConversionRate')"
                        >
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 lg:col-6 sm:col-6 col-offset-0 p-fluid">
              <button
                label="Void"
                pButton
                class="p-button-secondary"
                (click)="onVoid()"
                [disabled]="isReadOnly || _myForm.get('doc.state').value !== DocState.finalized || isInvalidForm"
              ></button>
            </div>

            <div class="col-12 lg:col-6 sm:col-6 col-offset-0 p-fluid">
              <div class="doc-summary card grid">
                <span class="total-label">TOTAL:</span>
                <span class="total-value"
                  >{{ _myForm.get("zone.defaultCurrencyIsoCode").value }}
                  {{ inventoryDocTotal | number : _moneyFormat }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </p-card>
    </div>
  </div>

  <footer class="col-12">
    <p-card class="card-header card-tableheader">
      <ng-template pTemplate="header">
        <h2 class="col">Stock Receipt</h2>
      </ng-template>
      <div class="grid">
        <div class="col-12 lg:col-4">
          <p>
            <em>Stock receipts</em> are used to record products received in supplier deliveries. The product quantities
            received in this stock receipt will be added to inventory stock levels after this document is finalized.
          </p>
          <h6>Ways to add products for this delivery:</h6>
          <ol>
            <li>Easily load products from open POs</li>
            <li>Select products from the supplier product list</li>
            <li>Scan product barcodes in the green search field</li>
            <li>Search by keyword in the green search field</li>
            <li>Load by PO #</li>
          </ol>
        </div>
        <div class="col-12 lg:col-2"></div>
        <div class="col-12 lg:col-6">
          <div class="grid">
            <div class="col-12 lg:col-4">
              <!-- <button
                pButton
                pRipple
                icon="pi pi-clone"
                label="Load All Open POs"
                class="p-button-primary w-full"
                [disabled]="isReadOnly"
              ></button> -->
              <button
                pButton
                pRipple
                icon="pi pi-clone"
                label="Select POs to Load"
                class="p-button-primary w-full"
                (click)="selectPOsToLoadClicked()"
                [disabled]="isReadOnly || _isSaving"
              ></button>
            </div>
            <div class="col-12 lg:col-4">
              <button
                pButton
                pRipple
                label="Show Full Supplier List"
                class="p-button-primary w-full"
                (click)="showFullSupplierListDialogClicked()"
                [disabled]="isReadOnly || _isSaving"
              ></button>
            </div>
            <div class="col-12 lg:col-4">
              <button
                pButton
                pRipple
                label="Load PO #"
                class="p-button-primary w-full"
                (click)="loadPONumberClicked(poNumberInput.value); poNumberInput.value = ''"
                [disabled]="isReadOnly || !poNumberInput.value"
              ></button>
            </div>
          </div>

          <div class="grid">
            <div class="col-12 lg:col-4"> </div>
            <div class="col-12 lg:col-8">
              <span class="p-input-icon-right w-full">
                <i class="pi pi-search" (click)="loadPONumberClicked(poNumberInput.value)"></i>
                <input
                  #poNumberInput
                  type="text"
                  placeholder="PO# (exact match e.g. 00590)"
                  pInputText
                  (keydown.enter)="loadPONumberClicked(poNumberInput.value); poNumberInput.value = ''"
                  [disabled]="isReadOnly || _isSaving"
                />
              </span>
            </div>
          </div>
          <div class="p-inputgroup inputgroup-outlined field-searchInventory" [ngClass]="{ 'is-disabled': isReadOnly }">
            <span class="p-inputgroup-addon"><i class="material-icons">local_offer</i></span>
            <taku-search-inventory
              id="inventory_search"
              #inventorySearchBox
              [autoClearOnSelection]="true"
              placeholder="Search for Items"
              (itemSelected)="onSearchResInventory($event)"
              (onNewItemClicked)="openNewModelInDialog($event)"
              [disabled]="isReadOnly || _isSaving"
              [showAnimatePlaceholder]="false"
            >
            </taku-search-inventory>
          </div>
        </div>
      </div>
    </p-card>
  </footer>

  <div class="col-12 inventory-wrapper">
    <taku-form-list
      *ngIf="inventoryDocLineCols"
      #formListInventory
      [_hideViewCol]="true"
      _model="inventoryDocLine"
      [_hideSavingCtrls]="true"
      [_showCtrlsInEditMode]="true"
      _title="Received Inventory"
      [_hideAddLineHeaderBtn]="true"
      _detailMode="n"
      (_onRowDeleted)="onDocLineDeleted()"
      _scrollHeight="calc(100vh - 17rem)"
      [_filter]="_formFilters"
      [_cols]="inventoryDocLineCols"
      [_defaultRowValues]="_defaultRowValues"
      [_hideClone]="true"
      [_defaultRows]="1000"
      [_pageSizes]="[1000]"
      [_requiredColsComeFirst]="false"
      [_dataService]="localDataService"
      [_hideDeleteHeaderBtn]="isReadOnly"
      [_hideGlobalFilter]="true"
      [defaultSortField]="'docLine.seqNo'"
      [_stateKey]="'StockReceivingDetail'"
    >
    </taku-form-list>
  </div>

  <!-- </p-card> -->

  <p-dialog
    [(visible)]="showFullSupplierListDialog"
    [showHeader]="true"
    (onHide)="showFullSupplierListDialog = false"
    [style]="{ maxWidth: '90%' }"
    class="fullsize-modal"
    [blockScroll]="false"
    [modal]="false"
    appendTo="body"
  >
    <taku-form-list
      #supplierInventoryFormListModal
      *ngIf="_isShowFullSupllierFormListDataReady && _showFullSupllierFormListCols"
      _model="inventory"
      _detailMode="n"
      _title="Inventory"
      [_cols]="_showFullSupllierFormListCols"
      [_filter]="showFullSupllierFormListNoChildrenFilter"
      [_extraQueryParams]="fullSupplierFormListExtraQueryParams"
      [_dataService]="inventorySupplierListService"
      [_modelService]="inventorySupplierListService"
      _scrollHeight="calc(90vh - 23rem)"
      [_hideViewCol]="true"
      [_bulkEditorEnabled]="false"
      [_dialogShowHeader]="false"
      [_hideSavingCtrls]="true"
      [_showCtrlsInEditMode]="false"
      [_hideAddLineHeaderBtn]="true"
      [_hideOptionsHeaderBtn]="true"
      [_hideAddHeaderBtn]="true"
      [_hideDeleteHeaderBtn]="true"
      [_hideClone]="true"
      [_showHeader]="true"
      [_rowSelectionType]="RowSelectionType.MULTIPLE"
      [_modelValidation]="_showFullSupllierFormListRules"
      _dialogStyleClass="form-list-dialog-style"
      _stateKey="fullSupplierListDialog"
      [_saveFormState]="false"
    >
      <div takuFormListHeaderCustomBtns>
        <button
          label="Add Selected Items to PO"
          pButton
          pRipple
          type="button"
          class="header-button"
          [disabled]="!supplierInventoryFormListModal._selectedObjects?.length"
          (click)="addSelectedRowsToInventoryDoc()"
        ></button>
      </div>
    </taku-form-list>
  </p-dialog>

  <p-dialog
    [(visible)]="_activeFullDialog"
    [ngSwitch]="_activeFullDialog"
    [contentStyle]="{ maxHeight: '100%' }"
    styleClass="fixedsize-modal"
    [blockScroll]="true"
    [modal]="true"
    [showHeader]="false"
  >
    <div *ngSwitchCase="FullSizeDialogName.COMMERCIAL_ACCOUNT" class="new-commercialAccount-panel">
      <taku-commercial-account
        (changedForm)="onNewCommercialAccountCreated($event)"
        [_isDialog]="true"
        [_object]="_activeFullDialogExtra"
        (dialogClosed)="closeFullSizeDialog()"
      ></taku-commercial-account>
    </div>

    <div *ngSwitchCase="FullSizeDialogName.NEW_INVENTORY" class="inventory-panel">
      <taku-inventory
        (changedForm)="onNewInventoryItem($event)"
        [_isDialog]="true"
        [_object]="_activeFullDialogExtra"
        (dialogClosed)="closeFullSizeDialog()"
      ></taku-inventory>
    </div>

    <div *ngSwitchCase="FullSizeDialogName.EDIT_INVENTORY" class="inventory-panel">
      <taku-inventory
        (changedForm)="onChangedDocLineInventory($event)"
        [_isDialog]="true"
        [_object]="_activeFullDialogExtra"
        (dialogClosed)="closeFullSizeDialog()"
      ></taku-inventory>
    </div>
    <div *ngSwitchCase="FullSizeDialogName.VIEW_ERRORS" class="inventory-panel">
      <taku-form-header
        toolbarTitle="Receive Excess Stock Quantity"
        displayMode="inline"
        [showActionBtns]="false"
        [showBackBtn]="false"
      >
        <button
          pButton
          class="p-button-rounded"
          type="button"
          (click)="closeFullSizeDialog()"
          icon="pi pi-times"
        ></button
      ></taku-form-header>

      <div class="col-12">
        <ng-container *ngIf="'_activeFullDialogExtra?.balanceComparision[0]'" ;>
          <div>
            You are attempting to receive stock quantities that exceed the quantities ordered for this Receiving
            Location. Please note the discrepancies below:<br /><br />
          </div>
          <div>
            {{ balanceComparisonOverBalanceLength }} Row(s) = Received quantity must be allocated to multiple POs
          </div>
          <div>
            {{ balanceComparisonLinesWithoutPOLength }} Row(s) = No outstanding POs for received quantities<br /><br />
          </div>
          <div>
            By clicking <b>Proceed</b> below, any excess quantities received will be split into new rows. Where
            applicable, quantities in new rows will subtract from <b>On Order Qty</b> for ordered products still pending
            in other supplier open PO(s) associated to this Receiving Location
          </div>
          <br />
          <button
            pButton
            label="Proceed"
            (click)="forceFinalize(_activeFullDialogExtra)"
            [loading]="isProceedLoading"
          ></button>
        </ng-container>
      </div>
    </div>
  </p-dialog>

  <p-dialog
    [(visible)]="selectPOsDialogVisible"
    [showHeader]="true"
    [style]="{ maxWidth: '90%' }"
    class="fullsize-modal"
    [blockScroll]="false"
    [modal]="false"
    appendTo="body"
  >
    <h3>Select POs to Load</h3>
    <button
      pButton
      pRipple
      class="p-button-primary mb-2"
      label="Select All"
      (click)="selectAllCheckboxPOTableBtnClicked()"
    ></button>

    <p-table
      class="grid-table"
      [value]="selectPOsToLoadTableDataFormGroup?.controls.purchaseDocs.controls"
      [loading]="selectPOsToLoadTableLoading"
    >
      <ng-template pTemplate="header">
        <tr class="captions-row">
          <th>Add</th>
          <th>PO #</th>
          <th>Order Date</th>
          <th>Total</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-formGroup let-i="rowIndex">
        <tr>
          <td>
            <taku-checkbox [formGroup]="formGroup" fieldName="isSelected"></taku-checkbox>
          </td>
          <td>{{ formGroup.value.purchaseDoc.doc.docNo }}</td>
          <td>{{ formGroup.value.purchaseDoc.doc.docDate | dateFormat }}</td>
          <td>{{
            parseFloat(formGroup.value.purchaseDoc.subTotal) +
              parseFloat(formGroup.value.purchaseDoc.totalLineTax) +
              parseFloat(formGroup.value.purchaseDoc.shipperChargeAmount) -
              discountAmountMoney(formGroup.value.purchaseDoc)
              | currency : formGroup.value.purchaseDoc.doc.currencyIsoCode
          }}</td>
        </tr>
      </ng-template>
    </p-table>

    <taku-checkbox
      [formGroup]="selectPOsToLoadTableDataFormGroup"
      fieldName="markAllAsReceived"
      fieldCaption="Load Qty Not Yet Received"
    ></taku-checkbox>

    <button
      pButton
      pRipple
      class="p-button-primary block mt-2 ml-auto"
      label="Load Selected POs"
      (click)="loadSelectedPOsInvetories()"
      [loading]="selectPOsToLoadTableLoading"
      [disabled]="loadPOsButtonDisabled"
    ></button>
  </p-dialog>
</div>
<!-- Template for SKU Link -->
<ng-template #skuTpl let-rowData let-showDetailsEvent="data.showInventoryEvent">
  <div class="sku-col">
    <a href="javascript://" (click)="showDetailsEvent.emit(rowData)">{{ rowData.docLine?.inventory?.sku }}</a>
  </div>
</ng-template>

<p-confirmDialog
  #cd
  appendTo="body"
  key="confirmationDialog"
  [style]="{ width: '300pt', height: '500pt', maxWidth: 'calc(100vw - 18pt)' }"
  [closable]="false"
>
  <ng-template pTemplate="footer">
    <button type="button" pButton label="Cancel" (click)="cancelDialogClicked(); cd.hide()"></button>
    <button type="button" pButton label="Delete 0 Qty Rows" (click)="deleteEmptyOrZeroClicked(); cd.hide()"></button>
    <button type="button" pButton label="Yes" (click)="fillEmptyOrZeroClicked(); cd.hide()"></button>
  </ng-template>
</p-confirmDialog>
