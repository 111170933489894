/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import * as _ from "lodash";
import { AppConstants } from "../../../../shared/app-constants";
import { Inventory } from "../../../inventory/inventory/inventory";
import { GMCAccount } from "./gmc-accounts/GMCGoogleAccount";
declare var gapi: any;

@Injectable({
  providedIn: "root",
})
export class GoogleContentAPIService {
  initOAuth(fn) {
    gapi.load("client:auth2", () => {
      const auth = gapi.auth2.init({
        client_id: AppConstants.CONTENT_API_CLIENT_ID,
      });
      fn(auth);
    });
  }

  oAuthSignIn(signInOptions = {}): Promise<any> {
    const defaultSignInOptions = { scope: "https://www.googleapis.com/auth/content" };
    // const defaultSignInOptions = { scope: "https://www.googleapis.com/auth/content https://www.googleapis.com/auth/plus.business.manage" };
    _.defaults(signInOptions, defaultSignInOptions);

    return gapi.auth2
      .getAuthInstance()
      .grantOfflineAccess(signInOptions)
      .then(
        function (resp) {
          return resp;
        },
        function (err) {
          console.error("Error signing in", err);
        }
      );
  }

  oAuthAuthorize(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      gapi.auth2.authorize(
        {
          client_id: AppConstants.CONTENT_API_CLIENT_ID,
          scope: "email profile openid https://www.googleapis.com/auth/content",
          prompt: "select_account",
          response_type: "code",
        },
        (response: any) => {
          if (response.error) {
            reject(response);
          } else {
            resolve(response);
          }
        }
      );
    });
  }

  loadContentAPI(defaultSignInOptions?): Promise<any> {
    const loadContentAPI = gapi.client.load("content", "v2");

    if (gapi.auth2 && gapi.auth2.getAuthInstance().isSignedIn.get()) {
      return loadContentAPI;
    }

    return this.oAuthSignIn(defaultSignInOptions).then(() => loadContentAPI);
  }

  retrieveAccountInfo(): Promise<any> {
    return this.loadContentAPI({ prompt: "select_account" }).then(
      () => {
        return gapi.client.content.accounts.authinfo({});
      },
      function (err) {
        console.error("Error loading GAPI client for API", err);
      }
    );
  }

  insertProduct(inventory: Inventory, googleShooping: GMCAccount): Promise<any> {
    return this.loadContentAPI().then(() => {
      const inventoryGoogleShopping = inventory.inventoryGoogleShopping;
      return gapi.client.content.products.insert({
        merchantId: googleShooping.merchantId,
        resource: {
          channel: "local",
          contentLanguage: "EN",
          offerId: inventory.sku,
          targetCountry: "CA",
          title: inventory.description1,
          description: inventory.longDescription || inventory.description1,
          price: {
            currency: inventory.standardPriceCurrencyIsoCode,
            value: inventory.standardPrice,
          },
          link: inventoryGoogleShopping.productLink,
          imageLink: inventoryGoogleShopping.imageLink,
          brand: inventoryGoogleShopping.brand,
          gtin: inventoryGoogleShopping.GTIN,
          mpn: inventoryGoogleShopping.MPN,
          googleProductCategory: inventoryGoogleShopping.productCategory,
          productType: inventoryGoogleShopping.productType,
          condition: inventoryGoogleShopping.condition,
          ageGroup: inventoryGoogleShopping.ageGroup,
          adult: inventoryGoogleShopping.adult,
          gender: inventoryGoogleShopping.gender,
          multipack: inventoryGoogleShopping.multiPack,
          material: inventoryGoogleShopping.material,
          isBundle: inventoryGoogleShopping.isBuldle,
          sizeType: inventoryGoogleShopping.sizeType,
          sizeSystem: inventoryGoogleShopping.sizeSystem,
          pattern: inventoryGoogleShopping.pattern,
          color: inventoryGoogleShopping.color,
        },
      });
    });
  }

  disconnect() {
    // gapi.auth2.getAuthInstance().signOut();
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
