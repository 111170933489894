import { Injectable } from "@angular/core";
import { ValidatorFn, Validators } from "@angular/forms";
import { Col, DataType, FilterType } from "src/app/form-list/form-list/form-list";
import { FormListModel } from "src/app/form-list/form-list/form-list-model.interface";

@Injectable({
  providedIn: "root",
})
export class InventoryOptionsSettingService implements FormListModel {
  getValidationRules(): { [key: string]: ValidatorFn[] | {} } {
    return {
      optionName: [Validators.required],
    };
  }

  getFormListColumns() {
    return [
      {
        field: "optionName",
        header: "Option Name",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "isActive",
        header: "Active",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.checkbox,
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.enum,
      },
      {
        field: "inventoryOptionsSettingValues",
        header: "Values",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.chips,
        dataOptions: [],
        filterType: FilterType.none,
        isNotSortable: true,
      },
    ];
  }
}
