<taku-form-header
  [displayMode]="_isDialog ? 'inline' : null"
  [formGroup]="_myForm"
  (backClicked)="goBack()"
  (saveClicked)="onSave()"
  (revertClicked)="onRevert()"
  (newClicked)="onNew()"
  (deleteClicked)="onDelete()"
  (cloneClicked)="onClone()"
  [toolbarTitle]="pageTitle"
  [insideDialog]="_isDialog"
  [isSaving]="_isSaving"
>
</taku-form-header>

<div class="col-12 pl-0 pr-0">
  <div [formGroup]="_myForm" class="grid terminal-topWrapper">
    <div class="col-12 lg:col-4 xl:col-3 md:col-5 terminalCredentials-wrapper">
      <p-panel>
        <ng-template pTemplate="header">
          <h2>Terminal Assignment</h2>
          <taku-checkbox [formGroup]="_myForm" fieldName="isActive" fieldCaption="Active"></taku-checkbox>
        </ng-template>

        <div class="grid">
          <div class="col-12 grid">
            <div class="col">
              <taku-input [formGroup]="_myForm" fieldName="terminalName" fieldCaption="Terminal Name"></taku-input>
            </div>
            <span class="align-self-center">
              <a href="javascript://" class="pi pi-info-circle" (click)="terminalIdPanel.toggle($event)">
                <p-overlayPanel #terminalIdPanel [showCloseIcon]="false" appendTo="body">
                  <div class="tooltip-styling">
                    <h5>NOTE:</h5>
                    <ul>
                      <li
                        >8-character alphanumeric ID. This is the ECR number of the particular payment terminal you are
                        pairing.</li
                      >
                    </ul>
                  </div>
                </p-overlayPanel>
              </a>
            </span>
          </div>
          <div class="col-12 grid">
            <div class="col">
              <taku-dropdown
                [formGroup]="_myForm"
                [options]="dbService.getLookup_storeStations(this._myForm.get('paymentGateway').value?.storeId) | async"
                fieldName="stationId"
                fieldCaption="Station ID/Name"
              ></taku-dropdown>
            </div>
            <span class="align-self-center">
              <a href="javascript://" class="pi pi-info-circle" (click)="stationIdPanel.toggle($event)">
                <p-overlayPanel #stationIdPanel [showCloseIcon]="false" appendTo="body">
                  <div class="tooltip-styling">
                    <h5>NOTE:</h5>
                    <ul>
                      <li
                        >Select the TAKU station you would like to pair your payment terminal with from the dropdown
                        menu.</li
                      >
                    </ul>
                  </div>
                </p-overlayPanel>
              </a>
            </span>
          </div>
          <div class="col-6">
            <button
              pButton
              pRipple
              *ngIf="!_myForm.get('isPaired').value"
              type="button"
              [label]="_myForm.get('isPaired').value ? 'Unpair' : 'Pair'"
              class="p-button-raised p-button-text col-12"
              [disabled]="!_myForm.get('id').value"
              (click)="
                _myForm.get('isPaired').value
                  ? econduitService.unpairTerminal(_myForm.get('id').value)
                  : econduitService.pairTerminal(_myForm.get('id').value)
              "
            ></button>
          </div>
        </div>
      </p-panel>
    </div>

    <div class="col-12 lg:col-4 xl:col-3 md:col-5 terminalCredentials-wrapper">
      <p-panel>
        <ng-template pTemplate="header">
          <h2>Terminal Credentials</h2>
        </ng-template>

        <div class="grid">
          <div class="col">
            <taku-input
              [formGroup]="infoFormGroup"
              [readonly]="false"
              fieldName="serialNo"
              fieldCaption="Serial Number"
            ></taku-input>
          </div>
          <span class="align-self-center">
            <a href="javascript://" class="pi pi-info-circle" (click)="serialInfoPanel.toggle($event)">
              <p-overlayPanel #serialInfoPanel [showCloseIcon]="false" appendTo="body">
                <div class="tooltip-styling">
                  <h5>NOTE:</h5>
                  <ul>
                    <li>This number can usually be found underneath the physical terminal you are pairing.</li>
                  </ul>
                </div>
              </p-overlayPanel>
            </a>
          </span>
          <div class="col-12">
            <taku-input [formGroup]="infoFormGroup" [readonly]="true" fieldName="terminalID" fieldCaption="Terminal ID">
            </taku-input>
          </div>

          <div class="col-12">
            <taku-input [formGroup]="infoFormGroup" [readonly]="true" fieldName="API_Key" fieldCaption="API Key">
            </taku-input>
          </div>

          <div class="col-12">
            <taku-input
              [formGroup]="infoFormGroup"
              [readonly]="true"
              fieldName="API_Password"
              fieldCaption="API Password"
            ></taku-input>
          </div>
        </div>
      </p-panel>
    </div>

    <div class="col-12 xl:col-6 lg:col-8 md:col-7 terminalOptions-wrapper">
      <p-panel>
        <ng-template pTemplate="header">
          <h2>Terminal Options</h2>
        </ng-template>

        <p-tabView class="tabview-nopad">
          <p-tabPanel header="Get Signature">
            <div class="grid">
              <div class="col-12">
                <p
                  >The getSignature method will initiate a signature capture on the terminal without the need for a
                  transaction. This is useful for <br />
                  when a signature is needed outside of a payment situation. Possibly for a work order or some other
                  authorization where just a <br />
                  signature is required.
                </p>

                <p
                  >This method is only supported on Pax and Dejavoo terminals which have a touchscreen and have been
                  updated to a firmware <br />
                  that supports.</p
                >
              </div>

              <div class="col-12">
                <taku-checkbox [formGroup]="infoFormGroup" fieldName="enableGetSignature" fieldCaption="Enable">
                </taku-checkbox>
              </div>
            </div>
          </p-tabPanel>
        </p-tabView>
      </p-panel>
    </div>
  </div>
</div>
