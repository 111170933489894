import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, MenuItem, MessageService, SelectItem } from "primeng/api";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { GenericFormComponent } from "src/app/forms/generic-form/generic-form.component";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";
import { GatewayTerminalsService } from "../gateway-terminals/gateway-terminals.service";
import { PaymentTerminal } from "../gateway-terminals/payment-terminal";
import { MonerisService } from "./moneris.service";
import * as _ from "lodash";

export class MonerisTerminalInfo {
  merchantID: string;
  terminalID: string;
  API_Token: string;
  Pairing_Token: string;

  setTokenization: boolean;

  beepVolume: Beep_Volume;
  language: Moneris_Language;
  setTip: boolean;
  tipPercent1: number;
  tipPercent2: number;
  tipPercent3: number;
  customByPercentage: boolean;
  customByAmount: boolean;
  panMaskingSetting: boolean;

  constructor() {
    this.merchantID = null;
    this.terminalID = null;
    this.API_Token = null;
    this.Pairing_Token = null;
    this.setTokenization = false;
    this.beepVolume = Beep_Volume._70;
    this.language = Moneris_Language.en;
    this.setTip = false;
    this.tipPercent1 = null;
    this.tipPercent2 = null;
    this.tipPercent3 = null;
    this.customByPercentage = false;
    this.customByAmount = false;
    this.panMaskingSetting = false;
  }
}

export enum Beep_Volume {
  _10 = "10",
  _20 = "20",
  _30 = "30",
  _40 = "40",
  _50 = "50",
  _60 = "60",
  _70 = "70",
  _80 = "80",
  _90 = "90",
  _100 = "100",
}

export enum Moneris_Language {
  en = "EN",
  fr = "FR",
}

@Component({
  selector: "taku-moneris-terminal",
  templateUrl: "./moneris-terminal.component.html",
  styleUrls: ["./moneris-terminal.component.scss"],
})
export class MonerisTerminalComponent extends GenericFormComponent implements OnInit {
  _isPairingOrUnpairing = false;
  _isInitializing = false;
  _isCalibrating = false;
  _isPEDCodeDownloading = false;
  storeStations$: Observable<SelectItem[]>;
  unpairItems: MenuItem[] = [
    {
      label: "Force Unpair",
      command: (): void => {
        this.forceUnPairTerminal();
      },
    },
  ];

  constructor(
    protected _router: Router,
    public fb: UntypedFormBuilder,
    protected dbService: GatewayTerminalsService,
    protected location: Location,
    public _route: ActivatedRoute,
    protected messageService: MessageService,
    protected alertMessage: AlertMessagesService,
    protected confirmationService: ConfirmationService,
    protected appSettingsService: AppSettingsStorageService,
    public monerisService: MonerisService
  ) {
    super(
      _router,
      fb,
      dbService,
      location,
      _route,
      messageService,
      alertMessage,
      confirmationService,
      appSettingsService
    );
  }

  enum_beep_volume: SelectItem<any>[];
  enum_moneris_language: SelectItem<any>[];
  enum_tip_display_mode: SelectItem<any>[];

  get pageTitle() {
    return `${this._object.terminalName || "Terminal Name"}`;
  }

  get infoFormGroup() {
    return this._myForm.get("info") as UntypedFormGroup;
  }

  get terminalIDCtrl() {
    return this.infoFormGroup.get("terminalID");
  }

  initForm() {
    const newTerminal = new PaymentTerminal();
    const terminalInfo = (newTerminal.info = new MonerisTerminalInfo());
    this._object = newTerminal;
    this._model = "paymentTerminal";
    const formDef = Object.assign({}, newTerminal, {
      info: this.fb.group(Object.assign({}, terminalInfo)),
    });
    this._myForm = this.fb.group(formDef);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.storeStations$ = this.dbService.getLookup_storeStations(this._myForm.get("paymentGateway").value?.storeId);
  }

  onSave({ silentMode = false }: { silentMode?: boolean } = {}) {
    this._isSaving = true;
    // const _editSetTokenization  = this._myForm.get('info.setTokenization').dirty;
    const _editTip =
      this._myForm.get("info.setTip").dirty ||
      this._myForm.get("info.customByPercentage").dirty ||
      this._myForm.get("info.customByAmount").dirty ||
      this._myForm.get("info.tipPercent1").dirty ||
      this._myForm.get("info.tipPercent2").dirty ||
      this._myForm.get("info.tipPercent3").dirty;
    const _editPanMaskingSetting = this._myForm.get("info.panMaskingSetting").dirty;

    this.subsList.push(
      this.onSubmit(silentMode).subscribe(() => {
        // if(_editSetTokenization) {
        //   this.setTokenization(this._myForm.get('id').value)
        // }
        if (_editTip) {
          this.setTip(this._myForm.get("id").value);
        }
        if (_editPanMaskingSetting) {
          this.setPanMaskingSetting(this._myForm.get("id").value);
        }
        this._isSaving = false;
      })
    );
  }

  initValidation() {
    this._validation = this.dbService.getValidationRules();
  }

  setFormArrays() {
    if (!this._object.info) this._object.info = new MonerisTerminalInfo();
  }

  setTokenization(id: number) {
    this.monerisService
      .Tokenization(id)
      .pipe(
        catchError((errorResponse) => {
          this.processServerError(errorResponse, false);
          this._isCalibrating = false;
          return throwError(errorResponse);
        }),
        tap((result) => {
          if (result) {
            this.messageService.add({
              summary: "Pinpad Set Tokenization",
              detail: `Pinpad Set Tokenization completed successfully!`,
              severity: "success",
            });
          }
        })
      )
      .subscribe(() => {});
  }

  setTip(id: number) {
    this.monerisService
      .SetTip(id)
      .pipe(
        catchError((errorResponse) => {
          this.processServerError(errorResponse, false);
          this._isCalibrating = false;
          return throwError(errorResponse);
        }),
        tap((result) => {
          if (result) {
            this.messageService.add({
              summary: "Pinpad Set Tip",
              detail: `Pinpad Set Tip completed successfully!`,
              severity: "success",
            });
          }
        })
      )
      .subscribe(() => {});
  }

  setPanMaskingSetting(id: number) {
    this.monerisService
      .PanMaskingSetting(id)
      .pipe(
        catchError((errorResponse) => {
          this.processServerError(errorResponse, false);
          this._isCalibrating = false;
          return throwError(errorResponse);
        }),
        tap((result) => {
          if (result) {
            this.messageService.add({
              summary: "Pinpad Set Pan Mask Setting",
              detail: `Pinpad Set Pan Mask setting completed successfully!`,
              severity: "success",
            });
          }
        })
      )
      .subscribe(() => {});
  }

  calibrate(id: number) {
    this._isCalibrating = true;
    this.monerisService
      .SetCalibrate(id)
      .pipe(
        catchError((errorResponse) => {
          this.processServerError(errorResponse, false);
          this._isCalibrating = false;
          return throwError(errorResponse);
        }),
        tap((result) => {
          if (result) {
            this.messageService.add({
              summary: "Pinpad Calibrated",
              detail: `Pinpad Calibrated successfully!`,
              severity: "success",
            });
          }
        })
      )
      .subscribe(() => {
        this._isCalibrating = false;
      });
  }

  setBeep(id: number) {
    this._isCalibrating = true;
    this.monerisService
      .SetBeepVolume(id)
      .pipe(
        catchError((errorResponse) => {
          this.processServerError(errorResponse, false);
          this._isCalibrating = false;
          return throwError(errorResponse);
        }),
        tap((result) => {
          if (result) {
            this.messageService.add({
              summary: "Pinpad Set Beep",
              detail: `Pinpad set Beep successfully!`,
              severity: "success",
            });
          }
        })
      )
      .subscribe(() => {
        this._isCalibrating = false;
      });
  }

  setLanguage(id: number) {
    this._isCalibrating = true;
    this.monerisService
      .SetLanguage(id)
      .pipe(
        catchError((errorResponse) => {
          this.processServerError(errorResponse, false);
          this._isCalibrating = false;
          return throwError(errorResponse);
        }),
        tap((result) => {
          if (result) {
            this.messageService.add({
              summary: "Pinpad Language",
              detail: `Pinpad Language successfully!`,
              severity: "success",
            });
          }
        })
      )
      .subscribe(() => {
        this._isCalibrating = false;
      });
  }

  pedCodeDownload(id: number) {
    this.confirmationService.confirm({
      header: "Confirmation",
      rejectButtonStyleClass: "p-button-link",
      message: `Before performing a PED Code Download, TAKU must first settle all transactions by closing your Batch. Note that the entire process may take between 15 to 20 minutes to settle transactions and complete the download. You will be able to navigate to other pages once the PED Code download is initiated.
        \n
        Please confirm that you would like to proceed.`,
      accept: () => {
        this._isPEDCodeDownloading = true;
        this.monerisService
          .PEDCodeDownload(id)
          .pipe(
            catchError((errorResponse) => {
              this.processServerError(errorResponse, false);
              this._isPEDCodeDownloading = false;
              return throwError(errorResponse);
            }),
            tap((result) => {
              if (result) {
                this.messageService.add({
                  summary: "Pinpad PED Code Download",
                  detail: `PED Code Downloaded successfully!`,
                  severity: "success",
                });
              }
            })
          )
          .subscribe(() => {
            this._isPEDCodeDownloading = false;
          });
      },
    });
  }

  pairTerminal(id: number) {
    this._isPairingOrUnpairing = true;
    this.monerisService
      .pairTerminal(id)
      .pipe(
        catchError((errorResponse) => {
          this.processServerError(errorResponse, false);
          this._isPairingOrUnpairing = false;
          return throwError(errorResponse);
        }),
        tap((result) => {
          if (result) {
            this._myForm.patchValue({
              isPaired: result["isPaired"],
            });
            this._myForm.markAsDirty();
            return this.onSubmit().subscribe();
          }
        })
      )
      .subscribe(() => {
        this._isPairingOrUnpairing = false;
      });
  }

  forceUnPairTerminal() {
    this.confirmationService.confirm({
      message: "Are you sure you want to force unpairing terminal?",
      accept: () => {
        this._myForm.get("isPaired").setValue(false);
        this._myForm.get("info.Pairing_Token").setValue(null);
        this._myForm.markAsDirty();
        this.onSubmit().subscribe();
      },
    });
  }

  unPairTerminal(id: number) {
    this._isPairingOrUnpairing = true;
    this.monerisService
      .unPairTerminal(id)
      .pipe(
        catchError((errorResponse) => {
          this.processServerError(errorResponse, false);
          this._isPairingOrUnpairing = false;
          return throwError(errorResponse);
        }),
        tap((result) => {
          if (result) {
            this._myForm.patchValue({
              isPaired: result["isPaired"],
            });
            this._myForm.get("info.Pairing_Token").setValue(null);
            this._myForm.markAsDirty();
            this.onSubmit().subscribe();
          }
        })
      )
      .subscribe(() => {
        this._isPairingOrUnpairing = false;
      });
  }

  initializeTerminal(id: number) {
    this._isInitializing = true;
    this.monerisService
      .initializeTerminal(id)
      .pipe(
        catchError((errorResponse) => {
          this.processServerError(errorResponse, false);
          this._isInitializing = false;
          return throwError(errorResponse);
        }),
        tap((result) => {
          if (result) {
            this.messageService.add({
              summary: "Pinpad Initiazation",
              detail: `Payment terminal initiazied successfully!`,
              severity: "success",
            });
          }
        })
      )
      .subscribe(() => {
        this._isInitializing = false;
      });
  }

  initLookups() {
    this.enum_beep_volume = this.dbService.enumSelectOptions(Beep_Volume);
    this.enum_moneris_language = this.dbService.enumSelectOptions(Moneris_Language);
  }

  protected loadObject(): void {
    this._route.queryParams.subscribe((_queryParams) => {
      if (_queryParams.defaultdataValues) {
        _.merge(this._object, JSON.parse(_queryParams.defaultdataValues));
        if (!this._object.paymentGateway) {
          this.dbService.getRow("paymentGateway", this._object.paymentGatewayId).subscribe((result) => {
            this._object.paymentGateway = result;
            super.loadObject();
          });
        } else {
          super.loadObject();
        }
      } else {
        super.loadObject();
      }
    });
  }
}
