<taku-form-dialoge-header
  [pristine]="_myForm.pristine"
  [valid]="_myForm.valid"
  (backClicked)="goBack()"
  (saveClicked)="onSave()"
  (revertClicked)="onRevert()"
  (cancelClicked)="closeModal()"
  (newClicked)="onNew()"
  (deleteClicked)="onDelete()"
  (cloneClicked)="onClone()"
  toolbarTitle="Promotions"
>
</taku-form-dialoge-header>

<form [formGroup]="_myForm" novalidate *ngIf="active">
  <div class="description">
    <p>Use the options below to create a rule to apply automatic % discounts on applicable line items during sales.</p>
    <p>Tips for Setting Up Promotional Rules:</p>
    <ol>
      <li>Promotions can be shared across stores within the same Zone.</li>
      <li
        >Use the Priority field to set which rule will take precedence when multiple rules apply. Note that lower
        numbers in the Priority field take precedence over higher numbers (e.g. 1 has a higher priority than 2).</li
      >
      <li
        >By default Promotions will not be applied during a sale if applicable Account or Subscription functions offer a
        greater discount (e.g. Price Levels or Commitment discounts). If you wish to allow discount stacking, enable the
        checkbox Can be combined with other discount?</li
      >
    </ol>
  </div>
  <div class="promotionDetails">
    <taku-input [formGroup]="_myForm" fieldName="name" fieldCaption="Promotion Name"></taku-input>
    <taku-dropdown
      [formGroup]="_myForm"
      [options]="lookup_zones$ | async"
      fieldName="zoneId"
      fieldCaption="Zone"
    ></taku-dropdown>
    <taku-dropdown
      [formGroup]="_myForm"
      [options]="lookup_categories$ | async"
      fieldName="categoryId"
      fieldCaption="Inventory Categories"
    ></taku-dropdown>
    <taku-calendar-date
      [formGroup]="_myForm"
      fieldName="effectiveDate"
      fieldCaption="Effective Date"
      appendTo="body"
    ></taku-calendar-date>
    <taku-calendar-date
      [formGroup]="_myForm"
      fieldName="endDate"
      fieldCaption="End Date"
      appendTo="body"
    ></taku-calendar-date>
    <div class="flex align-items-center">
      <taku-input-spinner
        [formGroup]="_myForm"
        [min]="1"
        [max]="127"
        fieldName="priority"
        fieldCaption="Priority"
      ></taku-input-spinner>
      <button
        pButton
        pRipple
        class="p-button-rounded p-button-secondary p-button-icon-only ml-2 flex-none"
        icon="pi pi-info-circle"
        (click)="priorityTip.toggle($event)"
      ></button>
      <p-overlayPanel #priorityTip appendTo="body">
        <p>
          Note that '1' represents the highest priority. The lower the number, the higher the priority.<br />
          Therefore, prioritize your tasks starting from '1' and proceed numerically.
        </p>
      </p-overlayPanel>
    </div>
    <taku-checkbox [formGroup]="_myForm" fieldName="isActive" fieldCaption="Is Active?"></taku-checkbox>
    <taku-checkbox
      [formGroup]="_myForm"
      fieldName="canCombineDiscount"
      fieldCaption="Can be combined with other discounts?"
    ></taku-checkbox>
  </div>
  <h3>Promotion Rules</h3>
  <div class="promotionRules">
    <taku-multiselect
      [formGroup]="_myForm"
      [options]="lookup_stores$ | async"
      fieldName="stores"
      fieldCaption="Choose Stores"
      appendTo="body"
      class="storeDropdown"
    ></taku-multiselect>
    <taku-input
      [formGroup]="_myForm"
      fieldName="minimumSubTotal"
      fieldCaption="Minimum Subtotal"
      [disabled]="true"
    ></taku-input>
    <div class="flex align-items-center">
      <taku-input
        [formGroup]="_myForm"
        fieldName="discountPercentage"
        fieldCaption="Discount (Percentage)"
      ></taku-input>

      <button
        pButton
        pRipple
        class="p-button-rounded p-button-secondary p-button-icon-only ml-2 flex-none"
        icon="pi pi-info-circle"
        (click)="adjustTip.toggle($event)"
      ></button>

      <p-overlayPanel #adjustTip appendTo="body">
        <p>This will be applied to the <b>sell price</b> of products.</p>
      </p-overlayPanel>
    </div>
  </div>
  <taku-input-textarea class="mt-3" [formGroup]="_myForm" fieldName="note" fieldCaption="Note"> </taku-input-textarea>
</form>
