/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { formatCurrency } from "@angular/common";
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  LOCALE_ID,
  Output,
  ViewChild,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  HostListener,
} from "@angular/core";

import { WebHelpers } from "src/app/utility/WebHelpers";
import { AppConstants } from "src/app/shared/app-constants";
import { InvoiceKeyboardActionsService } from "src/app/core/document/sale-document/sale-invoice-doc-base/invoice-keyboard-actions.service";
import { TenderType } from "src/app/core/settings/business-settings/tender-type/tender-type";

export enum NumpadType {
  MONETARY = "monetary",
  NUMERIC = "numeric",
  PERCENTAGE = "percentage",
}

@Component({
  selector: "taku-numpad",
  templateUrl: "./taku-numpad.component.html",
  styleUrls: ["./taku-numpad.component.scss"],
})
export class TakuNumpadComponent implements AfterViewInit, OnChanges {
  @Input() padTitle = "";
  @Input() currencyIsoCode = "CAD";
  @Input() initialQty = 0;
  @Input() defaultQty = 0;
  @Input() maximumQty = Infinity;
  @Input() buttonsArray?: number[] = [10, 20, 50];
  @Input() isVisible? = false;
  @Input() tenderDescription?: string;
  @Input() selectedTenderType?: TenderType;
  @Input() numpadType: NumpadType = NumpadType.MONETARY;
  @Input("modal") _modal = true;
  @Input() dialogStyleClass = null;
  @Input() keyCaptureDisabled?: boolean;
  @Input() numpadDisabled?: boolean;
  @Input() numpadHidden?: boolean;
  @Input() acceptButtonDisabled?: boolean;

  @Output() submitQty = new EventEmitter<number>();
  /** Emits 'true' if it was closed by clicking cancel button */
  @Output() onCancel = new EventEmitter<boolean | void>();

  @ViewChild("inputDisplay", { static: true }) inputDisplay: ElementRef;
  @ViewChild("numPadDialog") numPadDialog: ElementRef;

  _isPristine = true;

  _digitsInfo = "1.2-2";
  _validationRegexp = /\d*[.,]?\d{0,2}/g;
  _validationRegexpSecond = /\d*[.,]?\d{0,4}/g;
  _regexpDigits = /[.0-9]+/g;
  _qtyValue: any;
  _userHasPressedAmountBtns = false;
  private isPristine = true;

  // @HostListener('window:keypress', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (!this.isVisible) return true;

    if (event.keyCode === AppConstants.KEY_CODES.ENTER.code) {
      // if user pressed enter
      event.stopImmediatePropagation();

      this.submit();
    }
    // else if (this.inputDisplay.nativeElement !== document.activeElement) {
    //   const char = String.fromCharCode(event.keyCode);
    //   if (/^\d$/.test(char)) this.updateQtyValue( (this.isPristine ? '' : this.sanitizedInputQty) + char);
    // }
  }

  @HostListener("window:keyup", ["$event"])
  onKeyUp(event: KeyboardEvent) {
    if (
      !this.isVisible ||
      this.inputDisplay.nativeElement === document.activeElement ||
      this.numpadDisabled ||
      this.keyCaptureDisabled
    )
      return true;

    if (event.key?.match(/^[0-9]?\.?$/)) {
      // Should accept input keys from keyboard even though input field is not focused
      this.insertCharacter(event.key);
    } else if (event.keyCode === AppConstants.KEY_CODES.BACKSPACE.code) {
      let newQty = this.sanitizedInputQty;
      if (newQty.length === 0) return; // dont' update quantiy if it is empty

      newQty = this.isPristine ? "" : newQty.substr(0, newQty.length - 1); // remove the last digit
      this.updateQtyValue(newQty);
    }
  }

  get qtyValue(): string {
    return this._qtyValue;
  }

  set qtyValue(newValue) {
    this._qtyValue = newValue;

    if (this.numericInputQty > this.maximumQty) this.resetInput(this.maximumQty);
  }

  constructor(
    @Inject(LOCALE_ID) private _locale: string,
    protected keyboardActionsService: InvoiceKeyboardActionsService,
    private rootEl: ElementRef
  ) {}

  _resetQty() {
    this.qtyValue = this._formatQuantity(this.initialQty, this._digitsInfo);
    this._isPristine = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.buttonsArray = this.buttonsArray.slice(0, 3); // only takes first 3 items as buttons
      // Don't allow negatives for quantities
      this.defaultQty = Math.max(0, this.defaultQty);
      this.maximumQty = this.maximumQty <= 0 ? Infinity : this.maximumQty; // Maximum can't be negative or zero
      this.initialQty = Math.max(0, this.initialQty);
      this._resetQty();
    }
  }

  onInputFocused(event) {
    // onFocus input element on mobile, so keyboard doesn't show up, but we can use onscreen keyboard
    // Additionally set new focused element on component so keyevents like Enter key can work
    if (event.target instanceof HTMLInputElement && WebHelpers.isTouchDevice()) {
      const elm = <HTMLInputElement>event.target;
      elm.blur();
      (<HTMLElement>this.numPadDialog.nativeElement).focus();
    }
  }

  onDialogClosed() {
    // if we calculator is inside a sidebar or dialog, reattached class for hidden overflow in body
    WebHelpers.reattachBodyClassesForDialog(this.rootEl.nativeElement);

    this.onCancel.emit();
  }

  onDialogOpened() {
    this.isPristine = true;
  }

  updateQtyValue(newQty) {
    this.isPristine = false;
    this.qtyValue = newQty;
    // if (this.sanitizedInputQty === ''){
    //   this.resetInput();
    //   return;
    // }

    const formattedVal = this._formatQuantity(this.sanitizedInputQty);
    const formattedDigits = this._formatQuantity(this.sanitizedInputQty, this._digitsInfo);
    if (![formattedVal, formattedDigits].includes(this.qtyValue))
      // if current value is not some of the possible string formats, set it
      this.qtyValue = formattedVal;
  }

  get prefixAmounts(): string {
    switch (this.numpadType) {
      case NumpadType.MONETARY:
        return this.prefixQty;

      case NumpadType.NUMERIC:
      case NumpadType.PERCENTAGE:
        return "+";
    }

    return "";
  }

  get prefixQty() {
    switch (this.numpadType) {
      case NumpadType.MONETARY:
        return "$"; // for numpad, hard coded currency symbol will be used
      //return new CurrencySymbolPipe(this._locale).transform(this.currencyIsoCode);

      case NumpadType.PERCENTAGE:
        return "%";
    }

    return "";
  }

  ngAfterViewInit(): void {
    this._resetQty();
    this._selectInput();
  }

  open() {
    this._resetQty();
    this.isVisible = true;
    this._userHasPressedAmountBtns = false;
    // Apply keylistener to numpad dialog, so we can apply event propagation effectively
    setTimeout(() => {
      (<HTMLElement>this.numPadDialog.nativeElement).addEventListener("keydown", (event) => {
        this.onKeyDown(event);
      });
      this._selectInput();
      this.keyboardActionsService._canAnnounceNumKeyPress = false;
    }, 0);
  }

  _selectInput(): any {
    setTimeout(() => {
      const inputCtrl: HTMLInputElement = this.inputDisplay.nativeElement;
      inputCtrl.focus();
      inputCtrl.select();
      this._isPristine = true;
    }, 0);
  }

  get numericInputQty(): number {
    const number = parseFloat(this.sanitizedInputQty);
    if (isNaN(number)) return 0;

    return number;
  }

  get sanitizedInputQty(): string {
    if (!this.qtyValue) return "";

    return this._sanitizeQty(this.qtyValue);
  }

  private _sanitizeQty(qty: string) {
    let firstMatch, secondMatch;
    if (!(firstMatch = qty.match(this._regexpDigits))) return "";

    if (this.numpadType === NumpadType.MONETARY) {
      secondMatch = firstMatch.join("").match(this._validationRegexp);
    } else {
      secondMatch = firstMatch.join("").match(this._validationRegexpSecond);
    }

    if (!secondMatch) return "";

    return secondMatch[0];
  }

  _formatQuantity(qty: any, digits?): string {
    qty = parseFloat(qty) || 0;
    switch (this.numpadType) {
      case NumpadType.MONETARY:
        if (digits) return formatCurrency(qty, this._locale, "$", this.currencyIsoCode, digits);
        else return this.prefixQty + qty;

      case NumpadType.PERCENTAGE:
        return qty + this.prefixQty;
    }

    return qty.toString();
  }

  submit(): void {
    this.submitQty.emit(this.numericInputQty);
    this.isVisible = false;
    this.keyboardActionsService._canAnnounceNumKeyPress = true;
  }

  cancelDialog($event) {
    this._resetQty();
    this.isVisible = false;
    this.keyboardActionsService._canAnnounceNumKeyPress = true;
    this.onCancel.emit(true);
  }

  shouldOverwriteInput() {
    // let inputCtrl: HTMLInputElement = this.inputDisplay.nativeElement;
    // return inputCtrl.selectionEnd > inputCtrl.selectionStart || this._isPristine;
    return this._isPristine;
  }

  onCharacterBtnPressed(event) {
    this.insertCharacter(event.target.textContent);
  }

  insertCharacter(insertChar: string) {
    let newQty: string;
    if (this.shouldOverwriteInput()) {
      if (insertChar === ".") {
        newQty = this._sanitizeQty("$0" + insertChar);
      } else {
        newQty = this._sanitizeQty(insertChar);
      }
    } else {
      if (insertChar !== "." || !this.qtyValue.includes(".")) {
        if (this.qtyValue === "0" && insertChar !== ".") {
          newQty = this._sanitizeQty(insertChar);
        } else {
          newQty = this._sanitizeQty(this.qtyValue + insertChar);
        }
        // newQty = this._formatQuantity(newQty);
      } else {
        newQty = this._sanitizeQty(this.qtyValue);
      }
    }
    this.qtyValue = newQty;
    this._isPristine = false;
  }

  resetInput(value = this.defaultQty) {
    this.qtyValue = this._formatQuantity(value, this._digitsInfo);
    this._selectInput();
    this.isPristine = true;
  }

  clearCharacter() {
    this.qtyValue = this.qtyValue.substring(0, this.qtyValue.length - 1);

    if (this.sanitizedInputQty === "") this.resetInput();
  }

  insertAmount(amount: number) {
    const newQty =
      this.numpadType === NumpadType.MONETARY && !this._userHasPressedAmountBtns
        ? amount.toFixed(2)
        : (this.numericInputQty + amount).toFixed(4);
    this.qtyValue = this._formatQuantity(newQty);
    this._selectInput();

    this._userHasPressedAmountBtns = true;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
