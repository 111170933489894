/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { HttpClient } from "@angular/common/http";
import { Injectable, EventEmitter } from "@angular/core";
import { ConfirmationService, MenuItem, MessageService, SelectItem } from "primeng/api";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { Observable, Subject, Subscription } from "rxjs";
import { tap } from "rxjs/operators";
import {
  Col,
  DataType,
  ExecuteEventDatatypeOptions,
  ExecuteEvent_DisplayMode,
  FilterType,
} from "src/app/form-list/form-list/form-list";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { DBService } from "src/app/shared/services/db.service";
import { LocationService } from "src/app/shared/services/location.service";
import { PrintingFactoryService, PrintingMgr } from "src/app/shared/services/printing-service.service";
import {
  DeliveryStatus,
  FulfillmentStatus,
  PaymentStatus,
  SaleDoc,
  SaleDocSource,
} from "../../document/sale-document/sale-doc/sale-doc";
import { SaleDocAllUpcomingOrderPreviewComponent } from "./../../document/sale-document/sale-doc-all-upcoming-order-preview/sale-doc-all-upcoming-order-preview.component";
import { AllUpcomingOrderPreviewComponent } from "../all-upcoming-order-preview/all-upcoming-order-preview.component";
import { DeliveryMethod, OrderStatus } from "../../sale-channel/sale-channel-orders/sale-channel-order";
import { Router } from "@angular/router";
import { ValidatorFn } from "@angular/forms";
import { Account } from "../../contact-accounts/account/account";
import { AccountDialogWrapperComponent } from "../../contact-accounts/account-dialog-wrapper/account-dialog-wrapper.component";
import { SaleDocType } from "../../document/doc/doc";

@Injectable({
  providedIn: "root",
})
export class AllUpcomingOrdersService extends DBService {
  protected lookupStores: SelectItem[];
  private postOrder = new EventEmitter<SaleDoc>();
  private navigateSalescreen = new EventEmitter<SaleDoc>();
  private showAccountInfo = new EventEmitter<Account>();

  private previewPopupClosedEvent = new Subject<void>();
  previewPopupClosedEvent$ = this.previewPopupClosedEvent.asObservable();

  printMgr: PrintingMgr;
  subsList: Subscription[] = [];
  enum_delivery_methods = this.enumSelectOptions(DeliveryMethod);
  enum_payment_statuses = this.enumSelectOptions(PaymentStatus, { useNullAsValue: false });
  enum_order_statuses = this.enumSelectOptions(OrderStatus);
  enum_fulfillment_statuses = this.enumSelectOptions(FulfillmentStatus);
  enum_delivery_statuses = this.enumSelectOptions(DeliveryStatus, { useNullAsValue: false });
  enum_saleDocSource = this.enumSelectOptions(SaleDocSource);
  get_enumCountries(): SelectItem[] {
    return this.locationService.lookupCountryOptions_full();
  }

  constructor(
    protected http: HttpClient,
    protected authService: AuthService,
    private dialogService: DialogService,
    public ref: DynamicDialogRef,
    private appSettingsService: AppSettingsStorageService,
    protected _router: Router,
    protected confirmationService: ConfirmationService,
    private locationService: LocationService,
    private printingMgrFactory: PrintingFactoryService,
    protected messageService: MessageService
  ) {
    super(http, authService);
    this.postOrder.subscribe((_orgSaleDoc: SaleDoc) => {
      if (_orgSaleDoc.id) {
        this.getRow("saleDoc", _orgSaleDoc.id).subscribe((_saleDoc) => {
          this.ref = this.dialogService.open(SaleDocAllUpcomingOrderPreviewComponent, {
            data: {
              _object: _saleDoc,
              _orgSaleDoc: _orgSaleDoc,
            },
            width: "90%",
            height: "90%",
            closable: false,
            showHeader: false,
          });
          this.ref.onClose.subscribe((_saleDoc: SaleDoc) => {
            _orgSaleDoc = _saleDoc;
            this.previewPopupClosedEvent.next();
          });
        });
      } else {
        // TODO: Do we need this below else block? Is this for sale channel orders?
        this.ref = this.dialogService.open(AllUpcomingOrderPreviewComponent, {
          data: {
            _saleDoc: _orgSaleDoc,
          },
          width: "850px",
          height: "650px",
          closable: false,
        });
        this.ref.onClose.subscribe((_saleDoc: SaleDoc) => {
          _orgSaleDoc = _saleDoc;
          if (_orgSaleDoc && _orgSaleDoc.id) {
            setTimeout(() => {
              // TODO: Why are we opening the same dialog again? Is the intention that AllUpcomingOrderPreviewComponent is for a sale channel order? And if it's approved a saledoc preview popup is opened to continue the flow?
              this.ref = this.dialogService.open(SaleDocAllUpcomingOrderPreviewComponent, {
                data: {
                  _object: _saleDoc,
                },
                width: "850px",
                height: "650px",
                closable: false,
              });
            }, 200);
          }
        });
      }
    });
    this.showAccountInfo.subscribe((saleDoc: SaleDoc) => {
      this.ref = this.dialogService.open(AccountDialogWrapperComponent, {
        data: {
          account: saleDoc.personalAccount || saleDoc.commercialAccount,
          accountType: saleDoc,
        },
        width: "90%",
        height: "90%",
        closable: false,
        showHeader: false,
      });
    });

    this.subsList.push(
      this.navigateSalescreen.subscribe((saleDoc) => {
        const extras = {};

        if (saleDoc.doc.docType === SaleDocType.recurring_order) {
          /* This can be used/uncommented to lock edits to orders when they are preloaded on sales screen, if we're having bugs happening with editing preloaded orders (adding/removing orders, etc)
           * see TPT-3518 and TPT-3519 for context.
           *  */
          // extras["queryParams"] = { orderEditLock: true };
        }

        void this._router.navigate(["/sell/saleDoc", saleDoc.id], extras);
        this.ref.close();
      })
    );
  }

  getAccountNameCaption(rowData: SaleDoc): string {
    return rowData ? rowData.accountName : "";
  }

  lookup_stores(): Observable<SelectItem[]> {
    //get all active stores that are part of an active zone
    const activeFilter = {
      isActive: { matchMode: "equals", value: true },
      "zone.isActive": { matchMode: "equals", value: true },
    };
    return this.lookupSelectOptions("store", "storeName", {
      lookupFilter: JSON.stringify(activeFilter),
      enableFieldName: "isActive",
      emptyRowCaption: "All Stores",
      emptyRowValue: -1,
    });
  }

  addDefaultValueToLookup(items: SelectItem[], defaultMessage: string): SelectItem[] {
    items.unshift({
      label: defaultMessage,
      value: 0,
    });

    if (items.length >= 2 && items[1].label == "") {
      //if the second item is the default empty option. We want the empty option to be first.
      //so we swap the first and second items.
      const temp = items[1];
      items[1] = items[0];
      items[0] = temp;
    }
    return items;
  }

  // TODO: This seems to be somewhat of a duplicate of sale-doc-all-upcoming-order-preview.component.ts.
  // this one below is used in the form-list when editing directly on the form list (which currently isn't enabled on all upcoming orders, so this never runs, ever)
  // The sale-doc-all-upcoming-order-preview.component.ts version is used on the preview popup (see constructor this.postOrder.subscribe() above).
  onFulfillmentStatusChanged(rowData, _orgObjects): void {
    const orgObject = _orgObjects.find((row) => row.id === rowData.id);
    if (orgObject) {
      if (rowData.fulfillmentStatus === FulfillmentStatus.completed) {
        this.confirmationService.confirm({
          header: "Confirmation",
          message:
            "Once this order is marked as fulfilled, it will not be possible to make further edits. Are you sure you want to proceed?",
          acceptLabel: "Yes",
          rejectLabel: "No",
          rejectVisible: true,
          acceptVisible: true,
          rejectButtonStyleClass: "p-button-link",
          accept: () => {
            rowData.deliveryStatus = DeliveryStatus.readyForPickup;
            rowData.saleDoc.deliveryStatus = DeliveryStatus.readyForPickup;
          },
          reject: () => {
            rowData.fulfillmentStatus = orgObject.fulfillmentStatus;
            rowData.deliveryStatus = orgObject.deliveryStatus;
            rowData.saleDoc.fulfillmentStatus = orgObject.saledoc.fulfillmentStatus;
            rowData.saleDoc.deliveryStatus = orgObject.saledoc.deliveryStatus;
          },
        });
      } else if (rowData.fulfillmentStatus === FulfillmentStatus.backToStock) {
        rowData.deliveryStatus = DeliveryStatus.returned;
        rowData.saleDoc.deliveryStatus = DeliveryStatus.returned;
      } else if (rowData.fulfillmentStatus === FulfillmentStatus.voided) {
        rowData.deliveryStatus = DeliveryStatus.voided;
        rowData.saleDoc.deliveryStatus = DeliveryStatus.voided;
      } else if (
        rowData.fulfillmentStatus === FulfillmentStatus.unfulfilled ||
        rowData.fulfillmentStatus === FulfillmentStatus.partly_Fulfillment ||
        rowData.fulfillmentStatus === FulfillmentStatus.processing
      ) {
        rowData.deliveryStatus = DeliveryStatus.pending;
        rowData.saleDoc.deliveryStatus = DeliveryStatus.pending;
      }
    }
  }

  onPaymentStatusChanged(rowData, _orgObjects): void {
    const orgObject = _orgObjects.find((row) => row.id === rowData.id);
    // and user is not admin
    if (
      orgObject &&
      rowData.paymentStatus !== orgObject.paymentStatus &&
      rowData.fulfillmentStatus === FulfillmentStatus.completed &&
      !this.appSettingsService.getUser().isAdmin
    ) {
      rowData.paymentStatus = orgObject.paymentStatus;
      this.messageService.add({
        severity: "error",
        summary: "Order is fulfilled",
        detail: "After fulfilling order, payment status can not be changed except by admin",
      });
    }
  }

  onFulfillmentStoreChanged(rowData, _orgObjects): void {
    const orgObject = _orgObjects.find((row) => row.id === rowData.id);
    if (
      orgObject &&
      rowData.storeId !== orgObject.storeId &&
      rowData.fulfillmentStatus === FulfillmentStatus.completed
    ) {
      rowData.storeId = orgObject.storeId;
      this.messageService.add({
        severity: "error",
        summary: "Order is fulfilled",
        detail: "After fulfilling order, store can not be changed",
      });
    }
  }

  onDeliveryMethodChanged(rowData, _orgObjects): void {
    const orgObject = _orgObjects.find((row) => row.id === rowData.id);
    if (orgObject) {
      rowData.saleDoc.deliveryMethod = rowData.deliveryMethod;
    }
  }

  onCustomerSourceChanged(rowData, _orgObjects): void {
    const orgObject = _orgObjects.find((row) => row.id === rowData.id);
    if (orgObject) {
      rowData.saleDoc.customerSource = rowData.customerSource;
    }
  }

  getValidationRules(): {
    [key: string]: {} | ValidatorFn[];
  } {
    return {
      // channelType: [Validators.required],
      // channelName: [Validators.required],
      // storeId: [Validators.required],
    };
  }

  getLookupStores(): Observable<SelectItem[]> {
    return this.lookupSelectOptions("store", "storeName", {
      enableFieldName: "isActive",
    }).pipe(tap((stores: SelectItem[]) => (this.lookupStores = stores)));
  }

  isVoidLinkDisabled(rowData: SaleDoc): boolean {
    return true; //rowData.doc.state !== DocState.finalized;
  }

  isPayButtonDisabled(rowData: SaleDoc): boolean {
    return [
      PaymentStatus.paid,
      PaymentStatus.voided,
      PaymentStatus.refunded,
      PaymentStatus.incomplete,
      PaymentStatus.disputed,
    ].includes(rowData.paymentStatus);
  }

  getTakuDocLinkCaption(rowData: SaleDoc): string {
    return rowData?.doc?.docNo || "View";
  }

  isPrintLinkDisabled(rowData: SaleDoc): boolean {
    return !rowData;
  }

  formlist_lookup_delivery_statuses(rowData: SaleDoc): SelectItem[] {
    return this.enum_delivery_statuses.map((row) => {
      if (
        rowData.fulfillmentStatus == FulfillmentStatus.completed &&
        [DeliveryStatus.returned, DeliveryStatus.voided].includes(row.value)
      ) {
        row.disabled = true;
        row.icon = "pi pi-lock";
      } else {
        row.disabled = false;
        row.icon = "";
      }
      return row;
    });
  }

  getFormListColumns(
    params?: { paymentItems: (rowData: SaleDoc) => MenuItem[]; printItems: (rowData: SaleDoc) => MenuItem[] }
    // printDocumentEvent: EventEmitter<AllUpcomingOrders>
    // isAdmin: boolean,
    // clickManageChannelEvent?: EventEmitter<any>
  ): Col[] {
    return <Col[]>[
      {
        field: "doc.docNo",
        header: "Doc #",
        visible: true,
        readonly: true,
        frozen: true,
        dataType: DataType.execute_event,
        dataOptions: [],
        dataTypeOptions: [
          new ExecuteEventDatatypeOptions({
            displayMode: ExecuteEvent_DisplayMode.LINK,
            label: this.getTakuDocLinkCaption,
            event: this.postOrder,
            enabledOnEditMode: false,
            styleClass: "",
          }),
        ],
        filterType: FilterType.contains,
        filterMatchMode: FilterType.contains, // Using as workaround: see buildFilterParamsForCol() method in db.service.ts. filterType field alone is not being obeyed in there for some reason.
        isNotSortable: false,
        colWidth: 100,
      },
      {
        field: "takuPaymentButton",
        header: "Take Payment",
        visible: true,
        readonly: true,
        frozen: true,
        dataType: DataType.execute_event,
        dataOptions: [],
        dataTypeOptions: [
          new ExecuteEventDatatypeOptions({
            icon: "pi pi-dollar",
            event: this.navigateSalescreen,
            displayMode: ExecuteEvent_DisplayMode.BUTTON,
            fnIsRowDisabled: this.isPayButtonDisabled.bind(this),
            enabledOnEditMode: false,
            styleClass: "",
          }),
        ],
        filterType: FilterType.none,
        isNotSortable: true,
        colWidth: 120,
      },
      {
        field: "packingListButton",
        header: "Packing List",
        visible: true,
        readonly: true,
        frozen: true,
        dataType: DataType.execute_event,
        dataOptions: [],
        dataTypeOptions: [
          new ExecuteEventDatatypeOptions({
            icon: "pi pi-print",
            event: null,
            displayMode: ExecuteEvent_DisplayMode.SPLITBUTTON,
            fnIsRowDisabled: this.isPrintLinkDisabled,
            items: params.printItems,
            styleClass: "",
          }),
        ],
        filterType: FilterType.none,
        isNotSortable: true,
        colWidth: 120,
      },
      {
        field: "customerSource",
        header: "Source",
        visible: false,
        readonly: true,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enum_saleDocSource,
        filterType: FilterType.none,
        isNotSortable: false,
        onModelChange: this.onCustomerSourceChanged.bind(this),
      },
      {
        field: "doc.docDate",
        header: "Date",
        visible: false,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        isNotSortable: false,
      },
      {
        field: "deliveryDate",
        header: "Outgoing Date",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.date_date_time,
        dataOptions: [],
        filterType: FilterType.contains,
        isNotSortable: false,
      },
      {
        field: "doc.docType",
        header: "Doc Type",
        visible: false,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        isNotSortable: false,
      },
      {
        field: "doc.state",
        header: "Doc State",
        visible: false,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        isNotSortable: false,
      },
      {
        field: "accountName",
        colWidth: "auto",
        header: "Account",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.execute_event,
        dataOptions: [],
        dataTypeOptions: [
          new ExecuteEventDatatypeOptions({
            displayMode: ExecuteEvent_DisplayMode.LINK,
            label: this.getAccountNameCaption,
            event: this.showAccountInfo,
            enabledOnEditMode: false,
            styleClass: "",
          }),
        ],
        filterType: FilterType.none,
        isNotSortable: true,
      },
      {
        field: "grandTotalCurrencyFormat",
        header: "Grand Total",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.none,
        isNotSortable: true,
      },
      {
        field: "doc.docTime",
        header: "Time",
        visible: false,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        isNotSortable: true,
      },
      {
        field: "saleChannel.channelName",
        header: "Channel Name",
        visible: false,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        isNotSortable: false,
      },
      {
        field: "paymentStatus",
        header: "Payment Status",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enum_payment_statuses,
        filterType: FilterType.none,
        isNotSortable: false,
        onModelChange: this.onPaymentStatusChanged.bind(this),
        classObj: "status-margin",
      },
      {
        field: "fulfillmentStatus",
        header: "Fulfillment Status",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enum_fulfillment_statuses,
        filterType: FilterType.none,
        isNotSortable: false,
        onModelChange: this.onFulfillmentStatusChanged.bind(this),
        classObj: "status-margin",
      },
      {
        field: "totalItems",
        header: "# of Items",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.none,
        isNotSortable: true,
        colWidth: 120,
      },
      {
        field: "storeId",
        header: "Fulfillment Store",
        visible: false,
        readonly: false,
        frozen: false,
        dataType: DataType.lookup,
        dataOptions: this.getLookupStores(),
        filterType: FilterType.lookup,
        sortFields: ["store.storeName"],
        onModelChange: this.onFulfillmentStoreChanged.bind(this),
      },
      {
        field: "deliveryStatus",
        header: "Delivery Status",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.formlist_lookup_delivery_statuses.bind(this),
        filterType: FilterType.none,
        isNotSortable: false,
        classObj: "status-margin",
        filterOptions: this.enum_delivery_statuses,
      },
      {
        field: "deliveryMethod",
        header: "Delivery Method",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enum_delivery_methods,
        filterType: FilterType.none,
        isNotSortable: false,
        onModelChange: this.onDeliveryMethodChanged.bind(this),
      },
      {
        field: "doc.comment",
        header: "Notes",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        isNotSortable: false,
      },
    ].filter(Boolean);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
