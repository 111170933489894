/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { PersonalAccount } from "../personal-account/personal-account";
import { CommercialAccount } from "../commercial-account/commercial-account";
import { Account, AccountType } from "../account/account";
import * as _ from "lodash";

export class ContactAccountBase {
  readonly accountName: string;
  readonly accountEmails: string[];
  readonly accountPhones: string[];
  readonly accountInitials: string[];
  account: Account;

  static ConvertIntoTypedObject<T extends ContactAccountBase>(account: T, defaultCurrencyIsoCode?: string) {
    if (!account.account) return account;

    switch (account.account.accountType) {
      case AccountType.commercial:
        return _.merge(new CommercialAccount(defaultCurrencyIsoCode), account);

      case AccountType.personal:
        return _.merge(new PersonalAccount(), account);

      default:
        return account;
    }
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
