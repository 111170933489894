/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { AppDefaultsService } from "../../../../shared/services/app-defaults.service";
import { MultiFormListComponent } from "src/app/form-list/form-list-main/multiple-form-lists.interface";
import { FormListComponent } from "src/app/form-list/form-list/form-list.component";
import { FormListMainComponent } from "src/app/form-list/form-list-main/form-list-main.component";
import { Subscription } from "rxjs";

@Component({
  selector: "taku-zones-list",
  templateUrl: "./zones-list.component.html",
  styleUrls: ["./zones-list.component.scss"],
})
export class ZonesListComponent implements MultiFormListComponent, OnDestroy {
  subsList: Subscription[] = [];

  @ViewChild(FormListMainComponent, { static: true }) formListMainComponent: FormListMainComponent;
  _defaultRowValues = {};

  constructor(appDefaultsService: AppDefaultsService) {
    this.subsList.push(
      appDefaultsService.getDefaultAddressCountry().subscribe((countryCode) => {
        this._defaultRowValues["countryIsoCode"] = countryCode;
      })
    );
  }

  retrieveFormLists(): FormListComponent[] {
    return [this.formListMainComponent.formListComponent];
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
