<!-- <p-panel class="panel-pagetitle" styleClass="panel-nopad" [style]="{width: '100%'}">
  <ng-template pTemplate="header">
    <div class="grid col-12">
      <div class="col-12 sm:col-8">
        <h1>
          <i class="takuicon-google-integration"></i> Google Shopping Integration
        </h1>
      </div>
      <div class="col-12 sm:col-4">
        <taku-store-switcher *ngIf="_activeZoneId" (onStoreChanged)="storeChanged($event)"
          [selectedStoreId]="_activeStoreId" [activeZoneId]="_activeZoneId"></taku-store-switcher>
      </div>
    </div>
  </ng-template> -->

<taku-form-header
  (backClicked)="goBack()"
  toolbarTitle="Google Shopping Integration"
  iconStyleClass="takuicon-google-integration p-mr-1"
  [showActionBtns]="false"
  [showBackBtn]="false"
  displayMode="inline"
>
  <div class="store-switcher-container">
    <taku-store-switcher
      *ngIf="_activeZoneId"
      (onStoreChanged)="storeChanged($event)"
      [selectedStoreId]="_activeStoreId"
      [activeZoneId]="_activeZoneId"
    ></taku-store-switcher>
  </div>
</taku-form-header>
<p-tabMenu *ngIf="_activeStoreId" [model]="_gmcPages" [activeItem]="_gmcPages[_activeTab]"></p-tabMenu>
<router-outlet></router-outlet>

<!-- </p-panel> -->
