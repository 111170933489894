<h3>Phones</h3>
<p-table
  id="main-table"
  responsiveLayout="stack"
  breakpoint="640px"
  [value]="_formArray['controls']"
  styleClass="p-datatable-striped"
>
  <ng-template pTemplate="header">
    <tr>
      <th>Type</th>
      <th>Country</th>
      <th>Telephone #</th>
      <th>Ext. #</th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-formGroup>
    <tr>
      <td class="phone-type-col">
        <span class="p-column-title">Type:</span>
        {{ formGroup.get("phoneType").value }}
      </td>

      <td class="phone-country-col">
        <span class="p-column-title">Country:</span>
        {{ lService.lookupCountryByIsoCode(formGroup.get("countryIsoCode").value) }}
      </td>

      <td class="phone-number-col">
        <span class="p-column-title">Telephone #:</span>
        {{ formGroup.get("tel").value }}
      </td>

      <td class="phone-ext-col">
        <span class="p-column-title">Ext. #:</span>
        {{ formGroup.get("ext").value }}
      </td>
    </tr>
  </ng-template>
</p-table>
