import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ButtonModule } from "primeng/button";
import { ACTION_TYPE, BUTTON_SIZE } from "./action-type.enum";
import { CommonModule } from "@angular/common";

@Component({
  selector: "taku-action-button",
  templateUrl: "./action-button.component.html",
  styleUrls: ["./action-button.component.scss"],
  standalone: true,
  imports: [CommonModule, ButtonModule],
})
/**
 * Component that handles action buttons in a consistent way,
 * it takes in 2 configurations as @Input.
 *
 * @attribute {actionType: string} Value comes from ACCOUNT_TYPE enum
 * @attribute {size: BUTTON_SIZE} defaults to large.
 */
export class ActionButtonComponent {
  @Input()
  action: ACTION_TYPE;

  @Input()
  size: BUTTON_SIZE = BUTTON_SIZE.LARGE;

  @Output()
  clickAction = new EventEmitter();

  onClick(): void {
    this.clickAction.emit(null);
  }
}
