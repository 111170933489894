/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpEventType } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { distinctUntilChanged, tap } from "rxjs/operators";

const serverVersionSubject = new Subject<string>();
export const ServerVersion$ = serverVersionSubject.asObservable().pipe(distinctUntilChanged());

@Injectable({
  providedIn: "root",
})
export class ServerVersionInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event.type === HttpEventType.Response && event.headers.has("x-app-version")) {
          serverVersionSubject.next(event.headers.get("x-app-version"));
        }
      })
    );
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
