<span [formGroup]="formGroup" class="toggleWrapper">
  <i
    *ngIf="showStateLabel"
    [ngStyle]="{ width: _stateLabelWidth + 'em' }"
    [ngClass]="{ toggleState: true, off: !ctrlField || !ctrlField.value }"
    >{{ ctrlField && ctrlField.value ? enabledStateLabel : disabledStateLabel }}</i
  >
  <p-inputSwitch formControlName="{{ fieldName }}" [inputId]="fieldName"></p-inputSwitch>
  <label *ngIf="fieldCaption" for="{{ fieldName }}" [ngClass]="{ 'disabled-label': ctrlField.disabled }">{{
    fieldCaption
  }}</label>
</span>
