<taku-form-header toolbarTitle="All Online Orders" displayMode="inline" [showActionBtns]="false" [showBackBtn]="false">
</taku-form-header>

<!-- <div class="grid"> -->
<div id="sale-channel-search-box" class="col-12">
  <div class="grid fields-line">
    <div class="col-12 sm:col-6 lg:col-3 p-fluid">
      <taku-taku-calendar-range
        [formGroup]="formGroup"
        fieldCaption="Date Range"
        fieldNameRangeType="dateRangeType"
        fieldNameDateFrom="dateFrom"
        fieldNameDateTo="dateTo"
      ></taku-taku-calendar-range>
    </div>
    <div class="col-12 sm:col-6 lg:col-3 p-fluid">
      <taku-dropdown
        [formGroup]="formGroup"
        [options]="lookup_sale_channels$ | async"
        fieldCaption="Channel Name"
        fieldName="saleChannelId"
      ></taku-dropdown>
    </div>
    <div class="col-12 sm:col-6 lg:col-3 p-fluid">
      <taku-dropdown
        [formGroup]="formGroup"
        [options]="enum_order_statuses"
        fieldName="orderStatus"
        fieldCaption="Order Status"
      ></taku-dropdown>
    </div>
    <div class="col-12 sm:col-6 lg:col-3 p-fluid">
      <taku-dropdown
        [formGroup]="formGroup"
        [options]="enum_payment_statuses"
        fieldName="paymentStatus"
        fieldCaption="Payment Status"
      ></taku-dropdown>
    </div>
    <div class="col-12 sm:col-6 lg:col-3 p-fluid">
      <taku-dropdown
        [formGroup]="formGroup"
        [options]="enum_fulfillment_statuses"
        fieldName="fulfillmentStatus"
        fieldCaption="Fulfillment Status"
      ></taku-dropdown>
    </div>
    <div class="col-12 sm:col-6 lg:col-3 p-fluid">
      <taku-dropdown
        [formGroup]="formGroup"
        [options]="lookup_stores$ | async"
        fieldCaption="Fulfillment Store"
        fieldName="storeId"
      ></taku-dropdown>
    </div>
    <div class="col-12 sm:col-6 lg:col-3 p-fluid">
      <taku-dropdown
        [formGroup]="formGroup"
        [options]="enum_delivery_methods"
        fieldName="deliveryMethod"
        fieldCaption="Delivery Method"
      ></taku-dropdown>
    </div>
    <div class="col-12 sm:col-6 lg:col-3 p-fluid">
      <button
        pButton
        pRipple
        type="button"
        label="Refresh"
        class="p-button-raised p-button-secondary mt-2"
        (click)="clearSearch()"
      ></button>
    </div>
  </div>

  <div id="data-progressbar">
    <p-progressBar mode="indeterminate" value="0" [style.visibility]="_isFetchingData ? 'visible' : 'hidden'">
    </p-progressBar>
  </div>

  <div class="col-12 formlistWrapper">
    <taku-form-list
      #formList
      *ngIf="_isDataReady"
      [_filter]="formFilter ? formFilter : undefined"
      [_model]="'saleChannelOrder'"
      _dialogStyleClass="fullscreen-mobilemodal"
      [_dialogShowHeader]="false"
      [_hideSavingCtrls]="false"
      [_showCtrlsInEditMode]="false"
      [_hideAddLineHeaderBtn]="true"
      [_hideOptionsHeaderBtn]="false"
      [_hideGlobalFilter]="true"
      [_hideAddHeaderBtn]="true"
      [_hideDeleteHeaderBtn]="true"
      [_title]="'All Online Orders'"
      [_rowSelectionType]="RowSelectionType.MULTIPLE"
      _detailMode="n"
      _scrollHeight="calc(100vh - 31.3rem)"
      [_hideClone]="true"
      _viewColIcon="_"
      _viewColIconName="remove_red_eye"
      [_cols]="saleChannelOrderColumns"
      [_dialogDismissable]="true"
    >
      <!-- <div takuFormListHeaderCustomBtns>
          <p-button icon="ui-icon-print"  label="Packing List" (click)="onPrintPackingList(ReceiptPrintingSize.LETTER_SIZE)" [disabled]="formList._selectedObjects === null || formList._selectedObjects?.length === 0"></p-button>
      </div> -->
    </taku-form-list>
  </div>
</div>

<div [hidden]="true">
  <ng-template #printPreviewHost></ng-template>
</div>
