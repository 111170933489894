/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import {
  Component,
  EventEmitter,
  Input,
  Output,
  HostListener,
  ElementRef,
  OnDestroy,
  OnInit,
  SimpleChanges,
  OnChanges,
} from "@angular/core";
import { FormArray, UntypedFormArray, UntypedFormGroup } from "@angular/forms";
import { ConfirmationService } from "primeng/api";
import { DBService } from "src/app/shared/services/db.service";
import { GenericInvoiceStateService } from "../generic-invoice-state.service";
import { InvoiceKeyboardActionsService } from "./sale-invoice-doc-base/invoice-keyboard-actions.service";
import { Subscription } from "rxjs";
import { throttleTime, filter } from "rxjs/operators";
import { sumBy } from "lodash";
import { ModelFormGroup } from "src/app/utility/ModelFormGroup";
import { SaleDocTender } from "./sale-doc-tender/sale-doc-tender";
import { SaleDocService } from "./sale-doc/sale-doc.service";

@Component({
  template: "",
  styles: [],
})
export class GenericSaleDocFooterComponent implements OnDestroy, OnInit, OnChanges {
  subsList: Subscription[] = [];
  private tenderWarningSub: Subscription;
  @Input() saleForm: UntypedFormGroup;
  @Output() onSalePaid = new EventEmitter<number>();
  isTenderScreenOpened = false;
  showTenderWarningStyles = false;
  _overlaysVisibility: { [key: string]: boolean } = {};

  @HostListener("window:click", ["$event"])
  closeOverlayOnOutsideClick(e: MouseEvent): void {
    const queryOverlay = `${(<HTMLElement>this.elRef.nativeElement).tagName} .overlay-container`;
    // Close all the summary overlays only when the click was within the App's layout container (ex. NOT inside a dialog/modal) AND outside an overlay's contents
    if (e.target instanceof HTMLElement && e.target.closest("app-root") && !e.target.closest(queryOverlay))
      this.toggleOverlay(null);
  }

  toggleOverlay(overlayName: string, event?: MouseEvent): void {
    if (event) event.stopImmediatePropagation();

    for (const key in this._overlaysVisibility) {
      if (key === overlayName) this._overlaysVisibility[key] = !this._overlaysVisibility[key];
      else this._overlaysVisibility[key] = false;
    }
  }

  closeOverlay(overlayName: string): void {
    this._overlaysVisibility[overlayName] = false;
  }

  setZeroIfEmpty(_formControlName: string): void {
    this.saleForm.get(_formControlName).setValue(this.saleForm.get(_formControlName).value || 0);
  }

  constructor(
    protected confirmationService: ConfirmationService,
    protected dbService: SaleDocService,
    protected invoiceStateService: GenericInvoiceStateService,
    protected keyboardActionsService: InvoiceKeyboardActionsService,
    protected elRef: ElementRef
  ) {
    this.subsList.push(
      this.keyboardActionsService.anyKeyPressed$
        .pipe(
          throttleTime(200), // Adjust the throttle time as needed
          filter((event) => event.key === "F9" && this.isPaymentEnabled && !event.repeat)
        )
        .subscribe(() => {
          this.onPayBtnPressed();
        })
    );
  }

  ngOnInit(): void {
    this.subsList.push(
      this.invoiceStateService.tenderScreenOpen$.subscribe((isOpen) => {
        this.isTenderScreenOpened = isOpen;
        this.setTenderWarningStyles();
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.saleForm?.currentValue) {
      this.setTenderWarningStyles();
      this.tenderWarningSub?.unsubscribe();
      this.tenderWarningSub = this.saleDocTenders.valueChanges.subscribe(() => this.setTenderWarningStyles());
    }
  }

  private setTenderWarningStyles() {
    const tenderTotal = sumBy(this.saleDocTenders.value, (t) => Number(t.amount));
    this.showTenderWarningStyles = !this.isTenderScreenOpened && tenderTotal !== 0;
  }

  // should be overriten by children classes
  get invoiceTotal(): number {
    return 0;
  }

  onPayBtnPressed(): void {
    this.onSalePaid.emit(this.invoiceTotal);
  }

  get isPaymentEnabled(): boolean {
    return this.saleDocLines.length > 0 && !this.isTenderScreenOpened;
  }

  get isReturnEnabled(): boolean {
    return this.saleDocLines.value.some((line) => !!line.recurringOrderSettingId);
  }

  private get saleDocTenders(): FormArray<ModelFormGroup<SaleDocTender>> {
    return this.saleForm.controls.saleDocTenders as FormArray<ModelFormGroup<SaleDocTender>>;
  }

  get saleDocLines(): UntypedFormArray {
    return this.saleForm.get("saleDocLines") as UntypedFormArray;
  }

  ngOnDestroy(): void {
    this.tenderWarningSub?.unsubscribe();
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
