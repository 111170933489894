/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

export class CommercialAccountEmail {
  id: number;
  emailType: CommercialEmailType;
  email: string;

  constructor() {
    this.id = 0;
    this.emailType = CommercialEmailType.work;
    this.email = "";
  }
}

export enum CommercialEmailType {
  personal = "Personal",
  work = "Work",
  other = "Other",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
