/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, OnDestroy } from "@angular/core";
import { DynamicDialogRef, DynamicDialogConfig } from "primeng/dynamicdialog";
import { BackendGMBService } from "../BackendGMBService";
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import { GMBHelpers } from "../../../../../utility/GMBHelpers";
import { GMBVerificationMethod } from "../gmb-accounts-list/GMBGoogleAccount";
import { Subscription } from "rxjs";

@Component({
  selector: "taku-verification-method-selector",
  templateUrl: "./verification-method-selector.component.html",
  styleUrls: ["./verification-method-selector.component.scss"],
})
export class VerificationMethodSelectorComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];
  _availableVerificationOptions;
  _formGroup: UntypedFormGroup;
  VerificationMethods = GMBVerificationMethod;
  GMBHelpers = GMBHelpers;

  constructor(
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private gmbService: BackendGMBService,
    private fb: UntypedFormBuilder
  ) {
    this._formGroup = this.fb.group({
      verificationMethod: null,
    });
  }

  ngOnInit() {
    const gmbAccountId = this.dialogConfig.data.gmbAccountId;
    const locationName = this.dialogConfig.data.locationName;
    const languageCode = this.dialogConfig.data.languageCode;

    this.subsList.push(
      this.gmbService
        .getAvailableVerificationOptions(gmbAccountId, locationName, languageCode)
        .subscribe((response) => {
          this._availableVerificationOptions = response.options;
        })
    );
  }

  onCancelPressed() {
    // Doesn't pass any data
    this.dialogRef.close(null);
  }

  onAcceptPressed() {
    const methodData = this.selectedVerificationOption;
    if (methodData) {
      this.dialogRef.close(methodData);
    } else {
      this.dialogRef.close(null);
    }
  }

  get selectedVerificationOption() {
    const selectedMethod = this.ctrlVerificationMethod.value;
    return this._availableVerificationOptions.find((method) => method.verificationMethod === selectedMethod);
  }

  get ctrlVerificationMethod() {
    return this._formGroup.get("verificationMethod");
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
