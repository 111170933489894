<div class="col-12">
  <div class="col-12 cards-container">
    <div class="col-12 card-section" *ngFor="let cardsSection of cardsInfo">
      <h4 *ngIf="cardsSection.label" class="col-12">{{ cardsSection.label }}</h4>
      <p-card
        *ngFor="let card of cardsSection.cards"
        class="col-12 md:col-6 lg:col-4 xl:col-3 nav-item"
        [ngClass]="{ disableCard: card.isEnabled != undefined && !card.isEnabled }"
      >
        <a
          [routerLink]="card.routePath"
          (click)="card.openAction ? card.openAction.emit(card.channelType) : null"
          [queryParams]="card.routeQueryParams"
          router
          routerLinkActive="router-link-active"
        >
          <div class="top">
            <div class="icon-container">
              <i [class]="card.iconStyleClass"></i>
            </div>
            <h3 [innerHTML]="card.cardTitle"></h3>
          </div>

          <div class="description">
            <ul>
              <li *ngFor="let descLine of card.cardDesc"
                ><span>{{ descLine }}</span></li
              >
            </ul>
          </div>
          <div class="connection-status">
            <span *ngIf="card.isConnected && card.isEnabled" class="connected-label">
              <span class="material-icons">check_circle</span> Connected
            </span>
            <span *ngIf="!card.isConnected || !card.isEnabled" class="not-connected-label"> COMING SOON </span>
          </div>
        </a>
      </p-card>
    </div>
  </div>
</div>

<p-dialog
  *ngIf="dialogVisible"
  [modal]="true"
  [(visible)]="dialogVisible"
  appendTo="body"
  [contentStyle]="{ maxWidth: '600pt' }"
>
  <taku-inventory-sale-channel
    [formGroup]="_currFormGroup"
    [channelType]="_currFormGroup.get('saleChannel').value.channelType"
  >
  </taku-inventory-sale-channel>
</p-dialog>
