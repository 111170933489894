/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { ValidatorFn, Validators } from "@angular/forms";
import { SelectItem } from "primeng/api";
import { Observable, of } from "rxjs";
import { Col, DataType, FilterType } from "../../../form-list/form-list/form-list";
import { FormListModel } from "../../../form-list/form-list/form-list-model.interface";
import { CurrencySymbolPipe } from "../../../shared/pipes/currency-symbol.pipe";
import { AuthService } from "../../../shared/services/auth.service";
import { DBService } from "../../../shared/services/db.service";
import { LocationService } from "../../../shared/services/location.service";
import { TaxCalcType, TaxRuleSubDivisionIsoCode, TaxRule } from "./tax-rule";

@Injectable({
  providedIn: "root",
})
export class TaxRuleService extends DBService implements FormListModel {
  lookup_subdivisions: { [countryIsoCode: string]: SelectItem[] } = {};

  constructor(
    private locationService: LocationService,
    protected http: HttpClient,
    protected authService: AuthService,
    @Inject(LOCALE_ID) protected defaultLocale: string
  ) {
    super(http, authService);
  }

  getValidationRules(): { [key: string]: ValidatorFn[] | {} } {
    return {
      taxId: [Validators.required],
      taxRate: [Validators.required],
      ruleName: [Validators.required],
      taxAcronym: [Validators.required, Validators.maxLength(2)],
    };
  }

  get_tax_calc_type(): SelectItem[] {
    return this.enumSelectOptions(TaxCalcType);
  }
  get_enumCountries(): SelectItem[] {
    return this.locationService.lookupCountryOptions_full();
  }

  get_lookup_tax(): Observable<SelectItem[]> {
    return this.lookupSelectOptions("tax", "shortDesc", { enableFieldName: "isEnabled" });
  }
  get_lookup_tax_inventory_category(): Observable<SelectItem[]> {
    return this.lookupMultiSelectOptions("taxInventoryCategory", "category", { enableFieldName: "isEnabled" });
  }
  get_lookup_tax_account_category(): Observable<SelectItem[]> {
    return this.lookupMultiSelectOptions("taxAccountCategory", "category", { enableFieldName: "isEnabled" });
  }

  get_lookup_subdivisions(countryIsoCode: string): SelectItem[] {
    if (!this.lookup_subdivisions[countryIsoCode]) {
      const regionList: SelectItem[] = this.locationService.lookupSubdivisionOptions(countryIsoCode, false);
      regionList.forEach((region) => {
        const taxRegion = new TaxRuleSubDivisionIsoCode();
        taxRegion.subDivisionIsoCode = region.value;
        region.value = taxRegion;
      });
      this.lookup_subdivisions[countryIsoCode] = regionList;
    }
    return this.lookup_subdivisions[countryIsoCode];
  }

  formlist_lookup_subdivisions(rowData: TaxRule): SelectItem[] {
    return this.get_lookup_subdivisions(rowData.countryIsoCode);
  }

  formlist_filter_subdivisions(colsFilters: { countryIsoCode: string }): SelectItem[] {
    if (colsFilters.countryIsoCode) return this.get_lookup_subdivisions(colsFilters.countryIsoCode);
    else return [];
  }

  taxRulePrefix(rowData: TaxRule): string {
    const currencySymbol = new CurrencySymbolPipe(this.defaultLocale);
    if (rowData.tax && rowData.tax.isFlatTax) return currencySymbol.transform(rowData.tax.currencyIsoCode);
    else return "";
  }

  taxRuleSuffix(rowData: TaxRule): string {
    if (rowData.tax && !rowData.tax.isFlatTax) return "%";
    else return "";
  }

  onCountryChanged(rowData: TaxRule) {
    rowData.subDivisions = null;
  }

  public headerInfo() {
    return `<div class="tooltip-styling"><h5>What are Tax Rules?</h5>
    <ul>
    <li>Sales Tax Rule settings in TAKU are used to add the tax rate and conditions (e.g. geography, time, categories, tax types, priority, etc.) for every Sales Tax in TAKU (e.g. NYC Sales Tax)</li>
    <li>You can create different rates for every TAKU Sales Tax that is filed to the same Tax Receiver (e.g. HST ON 13%, HST Atlantic 15%, etc.)</li>
    <li>For step-by-step instructions on how to setup Tax Rules, visit our help portal <a href="https://help.takulabs.io/how-to-get-started/general-settings/taxes/creating-tax-rules" target="_blank">here</a></li>

  </ul></div>
    `;
  }

  getFormListColumns() {
    return [
      {
        field: "isEnabled",
        header: "Active",
        visible: true,
        readonly: false,
        frozen: true,
        dataType: DataType.checkbox,
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.enum,
      },
      {
        field: "taxId",
        fieldValue: "tax.shortDesc",
        header: "Tax Name",
        visible: true,
        readonly: false,
        frozen: true,
        dataType: DataType.lookup,
        dataOptions: this.get_lookup_tax(),
        filterType: FilterType.lookup,
        colWidth: 150,
        sortFields: ["tax.shortDesc"],
      },
      {
        field: "ruleName",
        header: "Rule Name",
        visible: true,
        readonly: false,
        frozen: true,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 150,
      },
      {
        field: "taxAcronym",
        header: "Printer Acronym",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 115,
      },
      {
        field: "taxRate",
        header: "Tax Rate",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.number,
        dataOptions: of([]),
        dataTypeOptions: { prefix: this.taxRulePrefix.bind(this), suffix: this.taxRuleSuffix.bind(this) },
        filterType: FilterType.contains,
        colWidth: 120,
      },
      {
        field: "taxCalcType",
        header: "Tax Calculation Type",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.get_tax_calc_type(),
        filterType: FilterType.enum,
        colWidth: 162,
      },
      {
        field: "isOnlineTax",
        header: "Online Tax",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.checkbox,
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.enum,
        colWidth: 135,
      },
      {
        field: "countryIsoCode",
        header: "Country",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.get_enumCountries(),
        onModelChange: this.onCountryChanged.bind(this),
        filterType: FilterType.enum,
        colWidth: 250,
      },
      {
        field: "subDivisions",
        header: "Regions",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enumMultiselect,
        dataKey: "subDivisionIsoCode",
        filterDataKey: "subDivisionIsoCode",
        dataOptions: this.formlist_lookup_subdivisions.bind(this),
        globalFilterMatchMode: "contains",
        globalFilterDataOptions: this.locationService.lookupAllSubdivisions(),
        filterType: FilterType.enum,
        filterOptions: this.formlist_filter_subdivisions.bind(this),
        filterMatchMode: "contains",
        filterField: "`subDivisions`",
        colWidth: 250,
        info: `<div class="tooltip-styling"><h5>Filtering by Region</h5>
        <ul>
        <li>To filter by region, you will need to first select a Country</li>
        </ul></div>`,
      },
      {
        field: "cities",
        header: "Cities",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.chips,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 350,
      },
      {
        field: "postalCodes",
        header: "Postal Code",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "applyEffectiveDate",
        header: "Apply Effective Date",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.checkbox,
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.enum,
      },
      {
        field: "effectiveDate",
        header: "Effective Date",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.date_date_only,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "endDate",
        header: "End Date",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.date_date_only,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "taxAccountCategories",
        fieldValue: "taxAccountCategoriesList",
        header: "Account Categories",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.multiselect,
        dataOptions: this.get_lookup_tax_account_category(),
        filterType: FilterType.lookup,
        colWidth: 250,
        isNotSortable: true,
      },
      {
        field: "taxInventoryCategories",
        fieldValue: "taxInventoryCategoriesList",
        header: "Inventory Categories",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.multiselect,
        dataOptions: this.get_lookup_tax_inventory_category(),
        filterType: FilterType.lookup,
        colWidth: 250,
        isNotSortable: true,
      },
      {
        field: "isTimeLimited",
        header: "Time Limited",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.checkbox,
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.enum,
      },
      {
        field: "timeFrom",
        header: "Starting Time",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.date_time,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "timeTo",
        header: "Ending Time",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.date_time,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "priority",
        header: "Tax Rule Priority",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 120,
      },
    ];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
