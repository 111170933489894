<taku-steps
  class="col-12"
  [model]="cashoutSteps"
  [(activeIndex)]="activeStep"
  lastStepLabel="Close Day"
  (onStepChanged)="onStepChanged($event)"
  (onStepsCompleted)="onCloseDay()"
  [validateStepFn]="validateStep.bind(this)"
  [lastStepVisible]="false"
  [maxSteps]="cashoutSteps.length"
></taku-steps>

<div *ngIf="activeStep == 0" [formGroup]="cashoutFormGroup" class="grid cashout-closing-steps" id="cashout-step-1">
  <header class="col-12">
    <!-- <div class="open-drawer-wrapper">
      <button class="open-drawer-btn taku-button-small" pButton label="Open Cash Drawer"
        (click)="onOpenCashDrawer($event)"></button>
    </div> -->

    <h2>Cash Count</h2>
  </header>

  <main class="grid col-12">
    <div class="grid opening-time col-12 lg:col-6">
      <div class="col-4 field-label">Opening Time:</div>
      <div class="col-8 field">
        {{ openingDateCtrl.value | dateTimeFormat : openingTimeCtrl.value }}
      </div>
    </div>

    <div *ngIf="cashoutHelperForm" class="grid opening-float col-12 lg:col-offset-1 lg:col-5">
      <div class="col-4 field-label">Opening Float:</div>
      <div class="col-8 field">
        {{ liveCashoutData.totalOpeningFloat | currency : _currencyISOCode : "symbol-narrow" }}
      </div>
    </div>

    <div class="grid closing-time col-12 lg:col-6">
      <ng-template [ngIf]="closingDateCtrl.value">
        <div class="col-4 field-label">Closing Time:</div>
        <div class="col-8 field">
          {{ closingDateCtrl.value | dateTimeFormat : closingTimeCtrl.value }}
          <!-- <taku-calendar-datetime [formGroup]="formGroup" fieldName="closingDateTime"
                                  [minDate]="_minValidClosingTime" fieldCaption="Closing Date and Time"></taku-calendar-datetime> -->
        </div>
      </ng-template>
    </div>

    <div class="grid opening-float col-12 lg:col-offset-1 lg:col-5">
      <div class="col-4 field-label">Closed By:</div>
      <div class="col-8 field">
        <label class="closed-by-type field-caption">{{ closedByType }}</label>
        <div class="closed-by-caption">{{ closedByName }}</div>
      </div>
    </div>

    <div *ngIf="cashoutSettings" id="cashout_close_calculation_method" class="col-12">
      <div class="calculationMethod-wrapper">
        <div class="col-12 field-label">Calculation Method</div>
        <p-radioButton
          name="closeSaveMethod"
          [value]="CashoutSaveMethod.TOTAL"
          label="TOTALS"
          formControlName="closeSaveMethod"
        ></p-radioButton>
        <p-radioButton
          name="closeSaveMethod"
          [value]="CashoutSaveMethod.DETAIL"
          label="DETAILS"
          formControlName="closeSaveMethod"
          [disabled]="cashoutSettings.cashCalculationType === CashCalculationType.by_actual_cash"
        ></p-radioButton>
      </div>

      <table
        id="closingTotalsTable"
        *ngIf="closeSaveMethodCtrl.value === CashoutSaveMethod.TOTAL"
        class="grid-table closingfloats-table col-12"
        cellspacing="0"
      >
        <thead>
          <tr class="total-row">
            <th class="col-currency">TOTALS</th>
            <th class="w-full">
              <div *ngIf="cashoutSettings && cashoutFormGroup" [ngClass]="{'byActualCash': cashoutSettings.cashCalculationType === CashCalculationType.by_actual_cash}" class="cashCalculationColumns-totals">

                <ng-container [ngSwitch]="cashoutSettings.cashCalculationType">
                  <ng-container *ngSwitchCase="CashCalculationType.by_actual_cash">
                    <ng-container *ngTemplateOutlet="actualCashFieldOutput"></ng-container>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    <ng-container *ngTemplateOutlet="closeTotalRemainingCashFieldOutput"></ng-container>
                  </ng-container>
                </ng-container>

                <div [ngSwitch]="cashoutSettings.cashCalculationType" class="operand-container">
                  <ng-container *ngSwitchCase="CashCalculationType.by_actual_cash">
                    <div class="operand material-icons">remove</div>
                  </ng-container>
                  <ng-container *ngSwitchDefault> 
                    <div class="operand material-icons">add</div>
                  </ng-container>
                </div>
                <div class="flex-end-pad"></div>

                <div class="flex-break"></div>

                <div class="col-nextfloat">
                    <taku-money
                      [formGroup]="cashoutFormGroup"
                      fieldName="closeTotalNextFloat"
                      fieldCaption="Next Float"
                      [currencyCode]="_currencyISOCode"
                      [currencyIsReadonly]="true"
                      [autoSelect]="true"
                    >
                    </taku-money>
                </div>

                <div class="operand-container">
                  <div class="operand material-icons">drag_handle</div>
                </div>
                <div class="flex-end-pad"></div>

                <div class="flex-break"></div>

                <ng-container [ngSwitch]="cashoutSettings.cashCalculationType">
                  <ng-container *ngSwitchCase="CashCalculationType.by_actual_cash">
                    <ng-container *ngTemplateOutlet="closeTotalRemainingCashFieldOutput"></ng-container>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    <ng-container *ngTemplateOutlet="actualCashFieldOutput"></ng-container>
                  </ng-container>
                </ng-container>

                <ng-template #closeTotalRemainingCashFieldOutput>
                  <div [ngSwitch]="cashoutSettings.cashCalculationType" class="col-remainingcash">
                    <div *ngSwitchCase="CashCalculationType.by_actual_cash" class="colVal-cashresult">
                      <label>Cash for Deposit</label>
                      {{ cashoutFormGroup.get('closeTotalRemainingCash').value | currency : _currencyISOCode : "symbol-narrow" }}
                    </div>
                    <taku-money
                      *ngSwitchDefault
                      [formGroup]="cashoutFormGroup"
                      fieldName="closeTotalRemainingCash"
                      fieldCaption="Cash for Deposit"
                      [currencyCode]="_currencyISOCode"
                      [currencyIsReadonly]="true"
                      [autoSelect]="true"
                    >
                    </taku-money>
                  </div>
                </ng-template>
                <ng-template #actualCashFieldOutput>
                  <div [ngSwitch]="cashoutSettings.cashCalculationType"  class="col-actualcash">
                    <taku-money
                      *ngSwitchCase="CashCalculationType.by_actual_cash" class="colVal-cashresult"
                      [formGroup]="cashoutHelperForm"
                      fieldName="actualCash"
                      fieldCaption="Actual Cash"
                      [currencyCode]="_currencyISOCode"
                      [currencyIsReadonly]="true"
                      [autoSelect]="true"
                    >
                    </taku-money>
                    <div *ngSwitchDefault class="colVal-cashresult">
                      <label>Actual Cash</label>
                      {{ cashoutHelperForm.get('actualCash').value | currency : _currencyISOCode : "symbol-narrow" }}
                    </div>
                  </div>
                </ng-template>
              </div>
              

            </th>
            
          </tr>
        </thead>
      </table>

      <div *ngIf="closeSaveMethodCtrl.value === CashoutSaveMethod.DETAIL">
        <table id="closingDetailsTable-mobile" class="grid-table closingfloats-table" cellspacing="0">
          <ng-container *ngTemplateOutlet="closingDetailsTotalsTpl"></ng-container>
        </table>

        <p-table
          id="closingDetailsTable"
          class="grid-table closing-details"
          tableStyleClass="closingfloats-table"
          styleClass="p-datatable-striped"
          [value]="closingFloatsArray['controls']"
          responsiveLayout="stack"
          breakpoint="640px"
        >
          <ng-template pTemplate="header">
            <ng-container *ngTemplateOutlet="closingDetailsTotalsTpl"></ng-container>
            <tr class="captions-row">
              <th class="col-currency">Denomination</th>
              <th id="cashout_close_remaining_cash_header" colspan="2" class="col-remainingcash">Cash for Deposit</th>
              <th colspan="2" class="col-nextfloat">
                <div class="nextFloat-colheader">
                  <div class="label"> Next Float </div>
                  <button
                    class="p-button-secondary taku-button-small"
                    pButton
                    iconPos="right"
                    icon="pi pi-pencil"
                    label="&nbsp;&nbsp;"
                    (click)="applyFloatDefaults($event)"
                  >
                    Apply<br />Defaults
                  </button>
                </div>
              </th>
              <th colspan="2" class="colVal-cashresult">Actual Cash</th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-closingFloatForm let-i="rowIndex">
            <tr>
              <taku-cashout-closing-float
                [rowNumber]="i + 1"
                [formGroup]="closingFloatForm"
                [currencyISOCode]="_currencyISOCode"
                [requiredValidation]="closeSaveMethodCtrl.value === CashoutSaveMethod.DETAIL"
              >
              </taku-cashout-closing-float>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </main>

  <footer class="col-12">
    <!-- <div class="grid">
      <button class="p-button-secondary" label="CANCEL" pButton (click)="onCancel($event)"></button>
    </div>
    <div class="grid">
      <button label="NEXT" icon="pi pi-chevron-right" iconPos="right" pButton (click)="onSecondStep($event)"></button>
    </div>-->
  </footer>
</div>

<div *ngIf="activeStep == 1" [formGroup]="cashoutFormGroup" class="grid cashout-closing-steps" id="cashout-step-1">
  <!--
  <header class="col-12">
    <h2>Payment Breakdown</h2>
  </header>
  -->

  <main class="col-12 grid">
    <taku-cashout-payment-breakdown
      *ngIf="paymentBreakDownArray"
      [closingBreakdownArray]="paymentBreakDownArray"
      [cashout]="liveCashoutData"
      [_currencyISOCode]="_currencyISOCode"
      (onCashActualAmountChanged)="cashAmountChanged()"
    ></taku-cashout-payment-breakdown>
  </main>

  <footer class="grid col-12 details-footer">
    <div class="col-12 md:col-4">
      <taku-input-textarea [formGroup]="cashoutFormGroup" fieldCaption="Notes" fieldName="closingNote" [rows]="3">
      </taku-input-textarea>
    </div>

    <div class="grid col-12 md:col-6">
      <div class="col-fixed">
        <taku-toggle
          [captionPosition]="ToggleCaptionPosition.LEFT"
          [showStateLabel]="false"
          [formGroup]="closeSettingsForm"
          fieldName="print"
          fieldCaption="Print"
        >
        </taku-toggle>
      </div>

      <div class="col">
        <taku-dropdown [formGroup]="closeSettingsForm" [options]="enum_printing_sizes" fieldName="printingSize">
        </taku-dropdown>
        <taku-checkbox [formGroup]="closeSettingsForm" fieldName="extraCopy" fieldCaption="Extra Copy"> </taku-checkbox>
      </div>
      <button
        pButton
        pRipple
        class="p-button-primary-dark col-fixed"
        [disabled]="closeSettingsForm.invalid || cashoutFormGroup.invalid"
        (click)="onCloseDay()"
        label="CLOSE"
      >
        <span class="btn-icon material-icons" style="width: 40%; text-align: right">receipt</span>
      </button>
    </div>

    <!-- <div class="col-12 md:col-2 done-btn-container">
      <button pButton pRipple class="p-button-primary-dark col-fixed"
        [disabled]="closeSettingsForm.invalid || formGroup.invalid" (click)="onCloseDay()" label="CLOSE">
        <span class="btn-icon material-icons" style="width: 40%; text-align: right;">receipt</span>
      </button>
    </div> -->
  </footer>
</div>

<taku-dialog-validation-messages
  [validationMessages]="dialogValidationMessages"
  [(dialogVisible)]="showValidationDialog"
  (onDialogHide)="hideValidationErrorsDialog()"
>
</taku-dialog-validation-messages>

<!-- <p-dialog [modal]="true" [(visible)]="showTransactionsDialog" [dismissableMask]="true" [style]="{width: '300px', height: '200px'}">
    <taku-cashout-tender-transactions *ngIf="activeBreakdownForm" (onCloseDialog)="showTransactionsDialog = false" [cashoutId]="formGroup.get('id').value" [tenderTypeId]="activeBreakdownForm.get('tenderTypeId').value" [tenderTypeName]="activeBreakdownForm.get('tenderDescription')?.value"></taku-cashout-tender-transactions>
</p-dialog> -->

<!-- Host/Container for rendering Slip Cashout print preview -->
<div [hidden]="true">
  <ng-template #printPreviewHost></ng-template>
</div>

<ng-template #closingDetailsTotalsTpl>
  <tr class="total-row">
    <th class="col-currency">TOTALS</th>
    <th colspan="2" class="col-remainingfloat">
      <span class="p-column-title">Cash for Deposit:</span>
      <span>{{ liveCashoutData.totalClosingRemainingCash | currency : _currencyISOCode : "symbol-narrow" }}</span>
    </th>
    <th colspan="2" class="col-nextfloat">
      <span class="p-column-title">Next Float:</span>
      <span>{{ liveCashoutData.totalClosingFloat | currency : _currencyISOCode : "symbol-narrow" }}</span>
    </th>
    <th colspan="2" class="col-actualcash">
      <span class="p-column-title">Actual Cash:</span>
      <span>{{ liveCashoutData.actualCashTotal | currency : _currencyISOCode : "symbol-narrow" }}</span>
    </th>
  </tr>
</ng-template>
