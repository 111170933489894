/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Input } from "@angular/core";
import { TakuInputCtrlBase } from "../TakuInputCtrlBase";

@Component({
  selector: "taku-chips",
  templateUrl: "./taku-chips.component.html",
  styleUrls: ["./taku-chips.component.scss"],
})
export class TakuChipsComponent extends TakuInputCtrlBase {
  @Input() maxEntries: number;
  @Input() fieldPlaceholder: string;

  clear() {
    this.ctrlField.reset();
    this.ctrlField.markAsDirty();
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
