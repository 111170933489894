/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { DBService } from "../../../../../shared/services/db.service";
import { FormListModel } from "../../../../../form-list/form-list/form-list-model.interface";
import { SelectItem } from "primeng/api";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";
import { ValidatorFn } from "@angular/forms";
import { DataType, FilterType } from "../../../../../form-list/form-list/form-list";
import {
  GoogleShoppingTargetCountry,
  GoogleShoppingContentLanguage,
} from "../google-shopping-store/GoogleShoppingStore";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class GoogleShoppingStoresService extends DBService implements FormListModel {
  looup_stores: SelectItem[];
  enum_targetCountry = this.enumSelectOptions(GoogleShoppingTargetCountry);
  enum_contentLanguage = this.enumSelectOptions(GoogleShoppingContentLanguage);

  get_lookup_stores(): Observable<SelectItem[]> {
    // if (this.looup_stores)
    //     return of(this.looup_stores);
    // else
    return this.lookupSelectOptions("store", "storeName", { enableFieldName: "isActive" }).pipe(
      tap((stores: SelectItem[]) => (this.looup_stores = stores))
    );
  }

  getValidationRules(): { [key: string]: ValidatorFn[] | {} } {
    return {};
  }

  getFormListColumns() {
    return [
      {
        field: "storeId",
        header: "Store",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.lookup,
        dataOptions: this.get_lookup_stores(),
        filterType: FilterType.lookup,
        sortfields: ["store.storeName"],
      },
      {
        field: "storeCode",
        header: "Store Code",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "storeAddress",
        header: "Store Address",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "targetCountry",
        header: "Target Country",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enum_targetCountry,
        filterType: FilterType.enum,
      },
      {
        field: "contentLanguage",
        header: "Content Language",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enum_contentLanguage,
        filterType: FilterType.enum,
      },
    ];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
