import { Component, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MenuItem } from "primeng/api";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { Subscription } from "rxjs";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import {
  PrintingFactoryService,
  PrintingMgr,
  ReceiptPrintingSize,
} from "src/app/shared/services/printing-service.service";
import { ClosedBatchDetailsTapePreviewComponent } from "../../previews/receipt/closed-batch-details-tape-preview/closed-batch-details-tape-preview.component";
import { PaymentTerminal } from "../../settings/integration-settings/gateway-terminals/payment-terminal";
import { StoreSettingLetterSalePrint } from "../../settings/store-settings/full-page-salereceipt-builder/StoreSettingLetterSalePrint";
import { StoreSettingGiftReceipt } from "../../settings/store-settings/gift-receipt-builder/StoreSettingGiftReceipt";
import { Station } from "../../settings/store-settings/station/station";
import { StorePoliciesSettings } from "../../settings/store-settings/store-policies-settings/StorePoliciesSettings";
import { Store } from "../../settings/store-settings/store/store";
import { StoreSettingTapeSalePrint } from "../../settings/store-settings/tape-salereceipt-builder/store-setting-tape-sale-print";

@Component({
  selector: "taku-batch-close-details-dialog",
  templateUrl: "./batch-close-details-dialog.component.html",
  styleUrls: ["./batch-close-details-dialog.component.scss"],
})
export class BatchCloseDetailsDialogComponent implements OnInit {
  subsList: Subscription[] = [];
  detailsObject;
  _digitsFormat = "1.0-0";
  footerFormGroup: UntypedFormGroup;
  totalsCardTypeObj: any;
  store: Store;
  station: Station;
  tapeBuilderSettings: StoreSettingTapeSalePrint;
  letterBuilderSettings: StoreSettingLetterSalePrint;
  giftReceiptBuilderSettings: StoreSettingGiftReceipt;
  storePolicies: StorePoliciesSettings;
  terminal: PaymentTerminal;

  @ViewChild("printPreviewHost", { read: ViewContainerRef, static: true }) printPreviewHost: ViewContainerRef;

  printSizesMenuItems: MenuItem[] = [
    {
      label: ReceiptPrintingSize.TAPE_SIZE,
      command: () => {
        this.onPrintClosedBatchDetails(ReceiptPrintingSize.TAPE_SIZE);
      },
      icon: "takuicon-measuring-tape",
    },
    // {
    //   label: ReceiptPrintingSize.LETTER_SIZE,
    //   command: () => {
    //     // this.onReprintDoc(ReceiptPrintingSize.LETTER_SIZE)
    //   },
    //   icon: 'takuicon-invoice'
    // },
    // {
    //   label: 'Gift Receipt',
    //   command: () => {
    //     // this.onReprintGiftReceipt();
    //   },
    //   icon: 'takuicon-gift'
    // }
  ];

  emailReceiptSizesMenuItems: MenuItem[] = [
    {
      label: ReceiptPrintingSize.TAPE_SIZE,
      command: () => {
        // this.onEmailDoc(ReceiptPrintingSize.TAPE_SIZE)
      },
      icon: "takuicon-measuring-tape",
    },
    // {
    //   label: ReceiptPrintingSize.LETTER_SIZE,
    //   command: () => {
    //     // this.onEmailDoc(ReceiptPrintingSize.LETTER_SIZE)
    //   },
    //   icon: 'takuicon-invoice'
    // },
  ];

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private fb: UntypedFormBuilder,
    private appSettingsService: AppSettingsStorageService,
    protected printingFactoryService: PrintingFactoryService
  ) {
    if (this.dialogConfig.data._detailsObject) {
      this.detailsObject = this.dialogConfig.data._detailsObject;
      // console.log(this.detailsObject);
      this.saveAndRemoveTotalsObj();
    }
    if (this.dialogConfig.data._terminal) {
      this.terminal = this.dialogConfig.data._terminal;
      // console.log(this.terminal);
    }
    this.store = this.appSettingsService.getStore();
    this.station = this.appSettingsService.getStation();
    // console.log(this.station);
  }

  ngOnInit(): void {
    // console.log(this.detailsObject);
    // console.log(this.totalsCardTypeObj)
    this.footerFormGroup = this.fb.group({
      emailAddress: this.fb.control(null, Validators.email),
    });

    this._fetchExtraSettings();
  }

  private _fetchExtraSettings() {
    this.subsList.push(
      this.appSettingsService.getStoreSettings("storeSettingLetterSalePrint").subscribe((settings) => {
        this.letterBuilderSettings = settings;
      })
    );
    this.subsList.push(
      this.appSettingsService.getStoreSettings("storeSettingTapeSalePrint").subscribe((settings) => {
        this.tapeBuilderSettings = settings;
      })
    );
    this.subsList.push(
      this.appSettingsService
        .getStoreSettings("storeSettingGiftReceipt")
        .subscribe((settings) => (this.giftReceiptBuilderSettings = settings))
    );

    this.subsList.push(
      this.appSettingsService.getStoreSettings("storePolicy").subscribe((settings) => (this.storePolicies = settings))
    );
  }

  onPrintClosedBatchDetails(printSize: ReceiptPrintingSize) {
    const printMgr = this.printingFactoryService.buildWithSettings(
      this.store.id,
      this.letterBuilderSettings,
      this.tapeBuilderSettings,
      this.giftReceiptBuilderSettings,
      this.storePolicies
    );

    this.subsList.push(
      printMgr.isReady.subscribe({
        next: () => {
          const template = this.getPrintOutTemplate(printMgr);

          printMgr.printTemplate(
            '<div style="max-width: 300px; font-family: monospace;">' + template + "</div>",
            this.printPreviewHost,
            ClosedBatchDetailsTapePreviewComponent,
            "Closed Batch Details - Print Preview"
          );
        },
        error: (err) => {
          console.log(err);
        },
      })
    );
  }

  // onEmailDoc(printSize: ReceiptPrintingSize) {
  //   const printMgr = this.printingFactoryService.buildWithSettings(this.store.id,
  //     this.letterBuilderSettings, this.tapeBuilderSettings, this.giftReceiptBuilderSettings,
  //     this.storePolicies);

  //   this.subsList.push(printMgr.isReady.subscribe({
  //     next: () => {
  //       this.subsList.push(printMgr.sendInvoiceEmail(saleDoc, this.emailAddressCtrl.value, this.printPreviewHost, printSize).subscribe({
  //         next: response => {
  //           // Do nothing for NOW
  //         },
  //       }));
  //     },
  //     error: (err) => {
  //       console.log(err);
  //     }
  //   }))
  // }

  onClosePressed() {
    this.dialogRef.close();
  }

  isTotals(cardType: any) {
    return cardType === "Totals";
  }

  get emailAddressCtrl() {
    return this.footerFormGroup.get("emailAddress");
  }

  saveAndRemoveTotalsObj() {
    // console.log("--BEFORE--", this.detailsObject);
    this.totalsCardTypeObj = this.detailsObject.totalsByCardType.Totals;
    delete this.detailsObject.totalsByCardType.Totals;
    // console.log("--AFTER--", this.detailsObject);
  }

  getPrintOutTemplate(printMgr: PrintingMgr) {
    function subdivisionDisplay(subDivisionIsoCode: string): string {
      return subDivisionIsoCode ? subDivisionIsoCode.split("-").pop() : "";
    }

    let storeInfoHtml = `<div class="storeInfoTopWrapper" style="display: flex; flex-direction: column; align-items: center;">`;
    if (printMgr.tapeSizeBuilderSettings.printStoreName) {
      storeInfoHtml += `<h2 class="retailer-name">${this.store.storeName}</h2>`;
    }
    // storeInfoHtml += `<span href="https://maps.google.com/?q=${_fullAddressMap}&t=m" target="_blank"></span>`;
    if (printMgr.tapeSizeBuilderSettings.printAddress) {
      storeInfoHtml += `<div class="retailer-address" style="display: flex; flex-direction: column; align-items: center;">
      <div class="address-line1">${this.store.address?.line1}</div>
      <div class="address-city">${this.store.address?.city}, ${subdivisionDisplay(
        this.store.address?.subDivisionIsoCode
      )}
        ${this.store.address?.postalCode}</div>
    </div>`;
    }
    if (printMgr.tapeSizeBuilderSettings.printPhoneNo && this.store.address?.addressPhone.tel) {
      storeInfoHtml += `<div class="retailer-phone">
      <span class="phone-label">Phone:</span> ${this.store.address?.addressPhone.tel}
      </div>`;
    }
    if (printMgr.tapeSizeBuilderSettings.printWebsite) {
      storeInfoHtml += `<div class="retailer-website">${this.store.url}</div>`;
    }
    if (printMgr.tapeSizeBuilderSettings.printEmail) {
      storeInfoHtml += `<div class="retailer-email">${this.store.address?.addressEmail.email}</div>`;
    }
    storeInfoHtml += `</div><br>`;

    let ClosedBatchDetailsHtml = `<div style="width: 100%; display: flex; justify-content: center;"><h2 style="font-size: 1.5em;">Closed Batch Details</h2></div><br>`;
    if (this.detailsObject) {
      ClosedBatchDetailsHtml += `<div class="details-top" style="display: flex; flex-direction: column; width: 98%; padding-bottom: 0.5em;">`;
      ClosedBatchDetailsHtml += `<div style="display: flex; justify-content: space-between;"><span>Transaction Type</span><span>${this.detailsObject.TransType}</span></div>`;
      ClosedBatchDetailsHtml += `<div style="display: flex; justify-content: space-between;"><span>Transaction Date</span><span>${this.detailsObject.TransDate}</span></div>`;
      ClosedBatchDetailsHtml += `<div style="display: flex; justify-content: space-between;"><span>Transaction Time</span><span>${this.detailsObject.TransTime}</span></div>`;
      ClosedBatchDetailsHtml += `<div style="display: flex; justify-content: space-between; padding-bottom: 0.5em;"><span>Transaction Name</span><span>${this.detailsObject.TxnName}</span></div>`;
      ClosedBatchDetailsHtml += `<div style="display: flex; justify-content: space-between;"><span>Terminal ID</span><span>${this.detailsObject.TerminalId}</span></div>`;
      ClosedBatchDetailsHtml += `<div style="display: flex; justify-content: space-between;"><span>Response Code</span><span>${this.detailsObject.ResponseCode}</span></div>`;
      ClosedBatchDetailsHtml += `<div style="display: flex; justify-content: space-between;"><span>Batch Number</span><span>${this.totalsCardTypeObj.BatchNumber}</span></div>`;
      ClosedBatchDetailsHtml += `<div style="display: flex; justify-content: space-between;"><span>Condition Code</span><span>${this.totalsCardTypeObj.ConditionCode}</span></div><br>`;
      ClosedBatchDetailsHtml += `<div style="display: flex; justify-content: space-between;"><span>Terminal Name</span><span>${this.terminal.terminalName}</span></div>`;
      ClosedBatchDetailsHtml += `<div style="display: flex; justify-content: space-between;"><span>Station Name</span><span>${this.station.stationName}</span></div><br>`;
      ClosedBatchDetailsHtml += "</div>";
    }
    // Totals table at the top
    ClosedBatchDetailsHtml += `<table cellspacing="0" style="border-bottom: 1px solid #d1cfcf; padding-bottom: 0.5em;">`;
    ClosedBatchDetailsHtml += `<tr class="header-row">`;
    ClosedBatchDetailsHtml += `<th style="text-align: left;">Totals</th><th>Host Count</th><th>Host Total</th><th>Device Count</th><th>Device Total</th></tr>`;
    ClosedBatchDetailsHtml += `<tr class="body-row first-body-row">`;
    ClosedBatchDetailsHtml += `<td>Purchase</td>`;
    ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.totalsCardTypeObj.HostPurchaseCount}</td>`;
    ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.totalsCardTypeObj.HostPurchaseTotal}</td>`;
    ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.totalsCardTypeObj.DevicePurchaseCount}</td>`;
    ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.totalsCardTypeObj.DevicePurchaseTotal}</td>`;
    ClosedBatchDetailsHtml += `</tr>`;
    ClosedBatchDetailsHtml += `<tr><td>Refund</td>`;
    ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.totalsCardTypeObj.HostRefundCount}</td>`;
    ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.totalsCardTypeObj.HostRefundTotal}</td>`;
    ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.totalsCardTypeObj.DeviceRefundCount}</td>`;
    ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.totalsCardTypeObj.DeviceRefundTotal}</td>`;
    ClosedBatchDetailsHtml += `</tr>`;
    ClosedBatchDetailsHtml += `<tr><td>Correction</td>`;
    ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.totalsCardTypeObj.HostCorrectionCount}</td>`;
    ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.totalsCardTypeObj.HostCorrectionTotal}</td>`;
    ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.totalsCardTypeObj.DeviceCorrectionCount}</td>`;
    ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.totalsCardTypeObj.DeviceCorrectionTotal}</td>`;
    ClosedBatchDetailsHtml += `</tr>`;
    ClosedBatchDetailsHtml += `<tr><td>Payment</td>`;
    ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.totalsCardTypeObj.HostPurchaseCount}</td>`;
    ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.totalsCardTypeObj.HostPurchaseTotal}</td>`;
    ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.totalsCardTypeObj.DevicePurchaseCount}</td>`;
    ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.totalsCardTypeObj.DevicePurchaseTotal}</td>`;
    ClosedBatchDetailsHtml += `</tr>`;
    ClosedBatchDetailsHtml += `<tr><td>Payment Correction</td>`;
    ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.totalsCardTypeObj.HostPaymentCorrectionCount}</td>`;
    ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.totalsCardTypeObj.HostPaymentCorrectionTotal}</td>`;
    ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.totalsCardTypeObj.DevicePaymentCorrectionCount}</td>`;
    ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.totalsCardTypeObj.DevicePaymentCorrectionTotal}</td>`;
    ClosedBatchDetailsHtml += `</tr>`;
    ClosedBatchDetailsHtml += `<tr><td>Credit</td>`;
    ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.totalsCardTypeObj.HostCreditCount}</td>`;
    ClosedBatchDetailsHtml += `<td style="text-align:center;"></td>`;
    ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.totalsCardTypeObj.DeviceCreditCount}</td>`;
    ClosedBatchDetailsHtml += `<td style="text-align:center;"></td>`;
    ClosedBatchDetailsHtml += `</tr>`;
    ClosedBatchDetailsHtml += "</table>";

    // rest of the card types
    for (const cardType in this.detailsObject.totalsByCardType) {
      ClosedBatchDetailsHtml += `<table cellspacing="0" style="width: 100%; border-bottom: 1px solid #d1cfcf; padding-top: 0.5em; padding-bottom: 0.5em;">`;
      ClosedBatchDetailsHtml += `<tr class="header-row">`;
      ClosedBatchDetailsHtml += `<th style="text-align: left;">${cardType}</th><th>Host Count</th><th>Host Total</th><th>Device Count</th><th>Device Total</th></tr>`;
      ClosedBatchDetailsHtml += `<tr class="body-row first-body-row">`;
      ClosedBatchDetailsHtml += `<td>Purchase</td>`;
      ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.detailsObject.totalsByCardType[cardType].HostPurchaseCount}</td>`;
      ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.detailsObject.totalsByCardType[cardType].HostPurchaseTotal}</td>`;
      ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.detailsObject.totalsByCardType[cardType].DevicePurchaseCount}</td>`;
      ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.detailsObject.totalsByCardType[cardType].DevicePurchaseTotal}</td>`;
      ClosedBatchDetailsHtml += `</tr>`;
      ClosedBatchDetailsHtml += `<tr><td>Refund</td>`;
      ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.detailsObject.totalsByCardType[cardType].HostRefundCount}</td>`;
      ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.detailsObject.totalsByCardType[cardType].HostRefundTotal}</td>`;
      ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.detailsObject.totalsByCardType[cardType].DeviceRefundCount}</td>`;
      ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.detailsObject.totalsByCardType[cardType].DeviceRefundTotal}</td>`;
      ClosedBatchDetailsHtml += `</tr>`;
      ClosedBatchDetailsHtml += `<tr><td>Correction</td>`;
      ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.detailsObject.totalsByCardType[cardType].HostCorrectionCount}</td>`;
      ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.detailsObject.totalsByCardType[cardType].HostCorrectionTotal}</td>`;
      ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.detailsObject.totalsByCardType[cardType].DeviceCorrectionCount}</td>`;
      ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.detailsObject.totalsByCardType[cardType].DeviceCorrectionTotal}</td>`;
      ClosedBatchDetailsHtml += `</tr>`;
      ClosedBatchDetailsHtml += `<tr><td>Payment</td>`;
      ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.detailsObject.totalsByCardType[cardType].HostPurchaseCount}</td>`;
      ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.detailsObject.totalsByCardType[cardType].HostPurchaseTotal}</td>`;
      ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.detailsObject.totalsByCardType[cardType].DevicePurchaseCount}</td>`;
      ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.detailsObject.totalsByCardType[cardType].DevicePurchaseTotal}</td>`;
      ClosedBatchDetailsHtml += `</tr>`;
      ClosedBatchDetailsHtml += `<tr><td>Payment Correction</td>`;
      ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.detailsObject.totalsByCardType[cardType].HostPaymentCorrectionCount}</td>`;
      ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.detailsObject.totalsByCardType[cardType].HostPaymentCorrectionTotal}</td>`;
      ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.detailsObject.totalsByCardType[cardType].DevicePaymentCorrectionCount}</td>`;
      ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.detailsObject.totalsByCardType[cardType].DevicePaymentCorrectionTotal}</td>`;
      ClosedBatchDetailsHtml += `</tr>`;
      ClosedBatchDetailsHtml += `<tr><td>Credit</td>`;
      ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.detailsObject.totalsByCardType[cardType].HostCreditCount}</td>`;
      ClosedBatchDetailsHtml += `<td style="text-align:center;"></td>`;
      ClosedBatchDetailsHtml += `<td style="text-align:center;">${this.detailsObject.totalsByCardType[cardType].DeviceCreditCount}</td>`;
      ClosedBatchDetailsHtml += `<td style="text-align:center;"></td>`;
      ClosedBatchDetailsHtml += `</tr>`;
      ClosedBatchDetailsHtml += "</table>";
    }
    return storeInfoHtml + ClosedBatchDetailsHtml;
  }
}
