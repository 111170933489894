/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

import { DBMode } from "src/app/forms/generic-form/generic-form.component";
import { SearchResultItem } from "src/app/taku-ui/taku-search-accounts/SearchResultItem";
import { Cashout } from "../../cashout/cashout/cashout";
import { GenericInvoiceStateService } from "../generic-invoice-state.service";
import { GenericSaleDocComponent, OverlayModalName } from "../sale-document/generic-sale-doc.component";
import { ReturnInvoiceDocBaseComponent } from "./return-invoice-doc-base/return-invoice-doc-base.component";

type ReturnDocComponentState = {
  id: number;
  dbMode: DBMode;
  object: any;
  formValue: any;
  myForm: UntypedFormGroup;
  account: SearchResultItem;
  cashout: Cashout;
  saleDocTotal: number;
};

type InvoiceState = {
  activeOverlay: OverlayModalName;
  activeOverlayExtra: any;
  activeOverlayParams: any;
};

@Injectable()
export class ReturnInvoiceStateService extends GenericInvoiceStateService {
  // Hold the state of a Sale Doc component (Salesscreen)
  private lastReturnDocComponentState: ReturnDocComponentState = null;
  // Hold the state of a Invoice Sale component (Salesscreen)
  private lastInvoiceSaleComponentState: InvoiceState = null;

  saveDocState(component: GenericSaleDocComponent) {
    // Only select properties that are required by GenericForm and GenericSaleDoc components to keep the state
    this.lastReturnDocComponentState = {
      id: component._id,
      dbMode: component.dbMode,
      object: component._object,
      formValue: component._initialFormValue,
      myForm: component._myForm,
      account: component._selectedAccount,
      cashout: component._cashout,
      saleDocTotal: component.saleDocTotal,
    };
  }

  saveInvoiceState(component: ReturnInvoiceDocBaseComponent) {
    super.saveInvoiceState(component);

    // Only select properties that are required by GenericForm and GenericSaleDoc components to keep the state
    this.lastInvoiceSaleComponentState = {
      activeOverlay: component._activeDialog || component.docComponent._activeFullDialog,
      activeOverlayExtra: component._activeDialogExtra || component.docComponent._activeFullDialogExtra,
      activeOverlayParams: component.docComponent._fullDialogParams,
    };
  }

  restoreDocState(newComponent: GenericSaleDocComponent) {
    // When switching designs component, because all dialogs and overlays get closed, we have to manually anounce tender screen is closed
    this.anounceTenderScreenVisibility(false);

    const state = this.lastReturnDocComponentState;
    // Override saved state's properties into current component
    // (although component can inherit other components,
    // Angular doesn't attach inherited properties in the javascript object's _proto_, but include them as owned properties)
    newComponent._id = state.id;
    newComponent.dbMode = state.dbMode;
    newComponent._object = state.object;
    newComponent._initialFormValue = state.formValue;
    newComponent._myForm = state.myForm;
    newComponent._selectedAccount = state.account;
    newComponent._cashout = state.cashout;
    newComponent.saleDocTotal = state.saleDocTotal;
  }

  restoreInvoiceState(newComponent: ReturnInvoiceDocBaseComponent) {
    const state = this.lastInvoiceSaleComponentState;
    // newComponent.docForReturnPreview = state.docForReturn;
    newComponent.restoreActiveOverlay(state.activeOverlay, state.activeOverlayExtra, state.activeOverlayParams);
  }

  hasDocState(): boolean {
    return this.lastReturnDocComponentState != null;
  }

  hasInvoiceState(): boolean {
    return this.lastInvoiceSaleComponentState != null;
  }

  clearDocState() {
    this.lastReturnDocComponentState = null;
  }

  clearInvoiceState(component: ReturnInvoiceDocBaseComponent) {
    super.clearInvoiceState(component);
    this.lastInvoiceSaleComponentState = null;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
