/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, OnDestroy } from "@angular/core";
import { SelectItem } from "primeng/api";
import { DynamicDialogRef, DynamicDialogConfig } from "primeng/dynamicdialog";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { BackendGMBService } from "../BackendGMBService";
import { GMBLocation } from "../gmb-locations-list/GMBLocation";
import { GMBAccount, GMBAccountType } from "../gmb-accounts-list/GMBGoogleAccount";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { map } from "rxjs/operators";
import { Subscription } from "rxjs";

@Component({
  selector: "taku-gmb-locationgroup-selector",
  templateUrl: "./gmb-locationgroup-selector.component.html",
  styleUrls: ["./gmb-locationgroup-selector.component.scss"],
})
export class GmbLocationGroupSelectorComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];

  _formGroup: UntypedFormGroup;
  _lookup_locationGroups: SelectItem[] = [
    {
      label: "",
      value: null,
    },
  ];
  _gmbAccount: GMBAccount;

  constructor(
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private fb: UntypedFormBuilder,
    private gmbService: BackendGMBService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this._gmbAccount = this.dialogConfig.data.gmbAccount;
    this.subsList.push(
      this.gmbService.getLocationGroups(this._gmbAccount.id).subscribe((response) => {
        if (this._gmbAccount.accountType === GMBAccountType.PERSONAL)
          this.subsList.push(
            this.route.queryParams.pipe(map((params: Params) => params["email"])).subscribe((email) => {
              this._lookup_locationGroups.push({
                label: this._gmbAccount.accountName + (email ? ` (${email})` : ""),
                value: this._gmbAccount.name,
              });
            })
          );

        this._lookup_locationGroups.push(
          ...response.map((account) => ({
            label: `${account.accountName} (Location Group)`,
            value: account.name,
          }))
        );
      })
    );
    this._formGroup = this.fb.group({
      locationGroup: null,
    });
  }

  onCancelPressed() {
    this.dialogRef.close({ rowData: this.dialogConfig.data.rowData });
  }

  onAcceptPressed() {
    const rowData = <GMBLocation>this.dialogConfig.data.rowData;
    const selectedAccount = this.ctrlLocationGroup.value;
    rowData.gmbAccountName = selectedAccount;
    rowData.accountName = this._lookup_locationGroups.find((item) => item.value === selectedAccount).label;
    this.dialogRef.close({ rowData });
  }

  get ctrlLocationGroup() {
    return this._formGroup.get("locationGroup");
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
