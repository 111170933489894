/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, EventEmitter, forwardRef, Output, Provider } from "@angular/core";
import { animate, style, transition, trigger } from "@angular/animations";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { AutoComplete } from "primeng/autocomplete";

// Access parent class component's decorator in order to simulate decorator inheritance
// const parentDecorator: ComponentDecorator = (<any>AutoComplete)
//   .__annotations__[0];

const INPUTMASK_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TakuAutoCompleteComponent),
  multi: true,
};

@Component({
  template: `
    <span
      #container
      [ngClass]="{
        'p-autocomplete p-component': true,
        'p-autocomplete-dd': dropdown,
        'p-autocomplete-multiple': multiple
      }"
      [ngStyle]="style"
      [class]="styleClass"
    >
      <input
        *ngIf="!multiple"
        #in
        [attr.type]="type"
        [attr.id]="inputId"
        [ngStyle]="inputStyle"
        [class]="inputStyleClass"
        [autocomplete]="autocomplete"
        [attr.required]="required"
        [attr.name]="name"
        class="p-autocomplete-input p-inputtext p-component"
        [ngClass]="{ 'p-autocomplete-dd-input': dropdown, 'p-disabled': disabled }"
        [value]="inputFieldValue"
        aria-autocomplete="list"
        [attr.aria-controls]="listId"
        role="searchbox"
        [attr.aria-expanded]="overlayVisible"
        aria-haspopup="true"
        [attr.aria-activedescendant]="'p-highlighted-option'"
        (click)="onInputClick($event)"
        (input)="onInput($event)"
        (keydown)="onKeydown($event)"
        (keyup)="onKeyup($event)"
        [attr.autofocus]="autofocus"
        (focus)="onInputFocus($event)"
        (blur)="onInputBlur($event)"
        (change)="onInputChange($event)"
        (paste)="onInputPaste($event)"
        [attr.placeholder]="placeholder"
        [attr.size]="size"
        [attr.maxlength]="maxlength"
        [attr.tabindex]="tabindex"
        [readonly]="readonly"
        [disabled]="disabled"
        [attr.aria-label]="ariaLabel"
        [attr.aria-labelledby]="ariaLabelledBy"
        [attr.aria-required]="required"
      /><ul
        *ngIf="multiple"
        #multiContainer
        class="p-autocomplete-multiple-container p-component p-inputtext"
        [ngClass]="{ 'p-disabled': disabled, 'p-focus': focus }"
        (click)="multiIn.focus()"
      >
        <li #token *ngFor="let val of value" class="p-autocomplete-token">
          <ng-container *ngTemplateOutlet="selectedItemTemplate; context: { $implicit: val }"></ng-container>
          <span *ngIf="!selectedItemTemplate" class="p-autocomplete-token-label">{{ resolveFieldData(val) }}</span>
          <span
            class="p-autocomplete-token-icon pi pi-times-circle"
            (click)="removeItem(token)"
            *ngIf="!disabled && !readonly"
          ></span>
        </li>
        <li class="p-autocomplete-input-token">
          <input
            #multiIn
            [attr.type]="type"
            [attr.id]="inputId"
            [disabled]="disabled"
            [attr.placeholder]="value && value.length ? null : placeholder"
            [attr.tabindex]="tabindex"
            [attr.maxlength]="maxlength"
            (input)="onInput($event)"
            (click)="onInputClick($event)"
            (keydown)="onKeydown($event)"
            [readonly]="readonly"
            (keyup)="onKeyup($event)"
            [attr.autofocus]="autofocus"
            (focus)="onInputFocus($event)"
            (blur)="onInputBlur($event)"
            (change)="onInputChange($event)"
            (paste)="onInputPaste($event)"
            [autocomplete]="autocomplete"
            [ngStyle]="inputStyle"
            [class]="inputStyleClass"
            [attr.aria-label]="ariaLabel"
            [attr.aria-labelledby]="ariaLabelledBy"
            [attr.aria-required]="required"
            aria-autocomplete="list"
            [attr.aria-controls]="listId"
            role="searchbox"
            [attr.aria-expanded]="overlayVisible"
            aria-haspopup="true"
            [attr.aria-activedescendant]="'p-highlighted-option'"
          />
        </li>
      </ul>
      <i *ngIf="loading" class="p-autocomplete-loader pi pi-spinner pi-spin"></i
      ><button
        #ddBtn
        type="button"
        pButton
        [icon]="dropdownIcon"
        class="p-autocomplete-dropdown"
        [disabled]="disabled"
        pRipple
        (click)="handleDropdownClick($event)"
        *ngIf="dropdown"
        [attr.tabindex]="tabindex"
      ></button>
      <div
        #panel
        *ngIf="overlayVisible"
        (click)="onOverlayClick($event)"
        [ngClass]="['p-autocomplete-panel p-component']"
        [style.max-height]="virtualScroll ? 'auto' : scrollHeight"
        [ngStyle]="panelStyle"
        [class]="panelStyleClass"
        [@overlayAnimation]="{
          value: 'visible',
          params: { showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions }
        }"
        (@overlayAnimation.start)="onOverlayAnimationStart($event)"
        (@overlayAnimation.done)="onOverlayAnimationEnd($event)"
      >
        <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
        <ul
          role="listbox"
          [attr.id]="listId"
          class="p-autocomplete-items"
          [ngClass]="{ 'p-autocomplete-virtualscroll': virtualScroll }"
        >
          <ng-container *ngIf="group">
            <ng-template ngFor let-optgroup [ngForOf]="suggestions">
              <li class="p-autocomplete-item-group">
                <span *ngIf="!groupTemplate">{{ getOptionGroupLabel(optgroup) || "empty" }}</span>
                <ng-container *ngTemplateOutlet="groupTemplate; context: { $implicit: optgroup }"></ng-container>
              </li>
              <ng-container
                *ngTemplateOutlet="itemslist; context: { $implicit: getOptionGroupChildren(optgroup) }"
              ></ng-container>
            </ng-template>
          </ng-container>
          <ng-container *ngIf="!group">
            <ng-container *ngTemplateOutlet="itemslist; context: { $implicit: suggestions }"></ng-container>
          </ng-container>
          <ng-template #itemslist let-suggestionsToDisplay>
            <ng-container *ngIf="!virtualScroll; else virtualScrollList">
              <li
                role="option"
                *ngFor="let option of suggestionsToDisplay; let idx = index"
                class="p-autocomplete-item"
                pRipple
                [ngClass]="{ 'p-highlight': option === highlightOption }"
                [id]="highlightOption == option ? 'p-highlighted-option' : ''"
                (click)="selectItem(option)"
              >
                <span *ngIf="!itemTemplate">{{ resolveFieldData(option) }}</span>
                <ng-container
                  *ngTemplateOutlet="itemTemplate; context: { $implicit: option, index: idx }"
                ></ng-container>
              </li>
            </ng-container>
            <ng-template #virtualScrollList>
              <cdk-virtual-scroll-viewport
                [ngStyle]="{ height: scrollHeight }"
                [itemSize]="itemSize"
                *ngIf="virtualScroll && !noResults"
              >
                <ng-container
                  *cdkVirtualFor="
                    let option of suggestionsToDisplay;
                    let i = index;
                    let c = count;
                    let f = first;
                    let l = last;
                    let e = even;
                    let o = odd
                  "
                >
                  <li
                    role="option"
                    class="p-autocomplete-item"
                    pRipple
                    [ngClass]="{ 'p-highlight': option === highlightOption }"
                    [ngStyle]="{ height: itemSize + 'px' }"
                    [id]="highlightOption == option ? 'p-highlighted-option' : ''"
                    (click)="selectItem(option)"
                  >
                    <span *ngIf="!itemTemplate">{{ resolveFieldData(option) }}</span>
                    <ng-container
                      *ngTemplateOutlet="itemTemplate; context: { $implicit: option, index: i }"
                    ></ng-container>
                  </li>
                </ng-container>
              </cdk-virtual-scroll-viewport>
            </ng-template>
            <li *ngIf="noResults && showEmptyMessage" class="p-autocomplete-empty-message">
              <ng-container *ngIf="!emptyTemplate; else 'empty'">
                {{ emptyMessageLabel }}
              </ng-container>
              <ng-container #empty *ngTemplateOutlet="emptyTemplate"></ng-container>
            </li>
          </ng-template>
        </ul>
        <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
      </div>
    </span>
  `,
  animations: [
    trigger("overlayAnimation", [
      transition(":enter", [style({ opacity: 0, transform: "scaleY(0.8)" }), animate("{{showTransitionParams}}")]),
      transition(":leave", [animate("{{hideTransitionParams}}", style({ opacity: 0 }))]),
    ]),
  ],
  host: {
    class: "p-element p-inputwrapper",
    "[class.p-inputwrapper-filled]": "filled",
    "[class.p-inputwrapper-focus]": "(focus && !disabled) || overlayVisible",
  },
  selector: "taku-autoComplete",
  styleUrls: ["./taku-auto-complete.component.scss"],
  providers: [INPUTMASK_VALUE_ACCESSOR],
})
export class TakuAutoCompleteComponent extends AutoComplete {
  @Output()
  onSuggestionsUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  overlayVisibleChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _suggestionsUpdated: boolean;
  private _overlayVisible: boolean;

  //@ts-ignore TS2611 overwriting property with accessor
  set suggestionsUpdated(value) {
    this._suggestionsUpdated = value;

    this.onSuggestionsUpdated.emit(value);
  }

  get suggestionsUpdated() {
    return this._suggestionsUpdated;
  }

  //@ts-ignore TS2611 overwriting property with accessor
  set overlayVisible(value) {
    this._overlayVisible = value;
    if (this.overlayVisibleChanged) {
      this.overlayVisibleChanged.emit(value);
    }
  }

  get overlayVisible() {
    return this._overlayVisible;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
