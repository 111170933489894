<div class="list-card grid" [ngStyle]="{ width: width + 'px', height: height + 'px' }">
  <div class="item-left col-fixed">
    <div class="inventory-img">
      <img
        [attr.src]="
          (inventory?.inventoryImages && InventoryImagePinnedToPictureGallery?.urlFileName150) ||
          '../../../../assets/layout/images/no-image.jfif'
        "
      />
    </div>
  </div>
  <div class="item-mid col-4">
    <div class="inventory-name">
      <b>{{ inventory.description1 }}</b>
    </div>
    <div class="inventory-sku">
      SKU:
      <ng-content select="[SKULink]"></ng-content>
    </div>
    <div class="inventory-avail-stock" *ngIf="isStockVisible">
      <span [class]="stockStatusClass">
        Stock:
        {{ qtyAvail - (inventory?.defaultInventoryStock?.qtyInCart || 0) }}
      </span>
    </div>
  </div>
  <div class="item-right col">
    <span><b>S/P: </b>{{ inventory?.standardPrice | currency : "CAD" : "symbol-narrow" : "1.2" }}</span>
    <ng-content select="[addToSaleDoc]"></ng-content>
    <ng-content select="[removeItem]"></ng-content>
  </div>
</div>
