/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { ValidatorFn, Validators } from "@angular/forms";
import * as _ from "lodash";
import { SelectItem } from "primeng/api";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { Col, DataType, ExecuteEventDatatypeOptions, ExecuteEvent_DisplayMode, FilterType } from "../../../../form-list/form-list/form-list";
import { FormListModel } from "../../../../form-list/form-list/form-list-model.interface";
import { AuthService } from "../../../../shared/services/auth.service";
import { DBService } from "../../../../shared/services/db.service";
import { LocationService } from "../../../../shared/services/location.service";
import { AddressLocationType } from "../../../contact-accounts/address/address";
import { Stock, Stock_Type } from "./stock";
import { AisleComponent } from "src/app/core/aisle/aisle.component";
import { Aisle } from "src/app/core/aisle/aisle";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { Shelf } from "src/app/core/shelf/shelf";
import { ShelfComponent } from "src/app/core/shelf/shelf.component";
import { ConnectedOverlayScrollHandler } from "primeng/dom";

@Injectable({
  providedIn: "root",
})
export class StockService extends DBService implements FormListModel {
  private lookup_subdivisions: { [countryIsoCode: string]: SelectItem[] } = {};
  private lookup_zones: SelectItem[];
  private aislePopover = new EventEmitter<Aisle>();
  private shelfPopover = new EventEmitter<Shelf>();

  constructor(
    private locationService: LocationService,
    private dialogService: DialogService,
    protected http: HttpClient,
    protected authService: AuthService
  ) {
    super(http, authService);

    this.aislePopover.subscribe((_stock: Stock) => {
      this.dialogService.open(AisleComponent, {
        data: {
          stockId: _stock.id,
        },
        width: "90%",
        height: "90%",
        closable: true,
        showHeader: true,
      });
    });

    this.shelfPopover.subscribe((_stock: Stock) => {
      this.dialogService.open(ShelfComponent, {
        data: {
          stockId: _stock.id,
        },
        width: "90%",
        height: "90%",
        closable: true,
        showHeader: true,
      });
    });
  }

  get_enumCountries(): SelectItem[] {
    return this.locationService.lookupCountryOptions_full();
  }

  get_lookup_subdivisions(countryIsoCode): SelectItem[] {
    if (!countryIsoCode) return;

    if (!this.lookup_subdivisions[countryIsoCode]) {
      this.lookup_subdivisions[countryIsoCode] = this.locationService.lookupSubdivisionOptions(countryIsoCode, false);
    }
    return this.lookup_subdivisions[countryIsoCode];
  }

  formlist_lookup_subdivisions(rowData): SelectItem[] {
    return this.get_lookup_subdivisions(_.get(rowData, "address.countryIsoCode"));
  }

  get_enum_stock_type() {
    return this.enumSelectOptions(Stock_Type);
  }

  get_lookup_zones(): Observable<SelectItem[]> {
    return this.lookupSelectOptions("zone", "zoneName", { enableFieldName: "isActive" }).pipe(
      tap((data: SelectItem[]) => (this.lookup_zones = data))
    );
  }

  get_enum_address_type() {
    return this.enumSelectOptions(AddressLocationType);
  }

  getValidationRules(): { [key: string]: ValidatorFn[] | {} } {
    return {
      // stockID: [Validators.required],
      stockName: [Validators.required],
      zoneId: [Validators.required],
      stockID: [Validators.required],
      address: {
        countryIsoCode: [Validators.required],
      },
    };
  }

  formlist_filter_subdivisions(colsFilters): SelectItem[] {
    if (colsFilters["address.countryIsoCode"])
      return this.get_lookup_subdivisions(colsFilters["address.countryIsoCode"]);
    else return [];
  }

  getFormListColumns(): Col[] {
    return [
      {
        field: "aisle.id",
        header: "Aisle",
        visible: true,
        readonly: true,
        frozen: true,
        dataType: DataType.execute_event,
        dataOptions: [],
        dataTypeOptions: [
          new ExecuteEventDatatypeOptions({
            displayMode: ExecuteEvent_DisplayMode.LINK,
            label: "Aisle",
            event: this.aislePopover,
            enabledOnEditMode: false,
            styleClass: "",
          }),
        ],
        filterType: FilterType.none,
        isNotSortable: false,
        colWidth: 100,
      },
      {
        field: "shelf.id",
        header: "Shelf",
        visible: true,
        readonly: true,
        frozen: true,
        dataType: DataType.execute_event,
        dataOptions: [],
        dataTypeOptions: [
          new ExecuteEventDatatypeOptions({
            displayMode: ExecuteEvent_DisplayMode.LINK,
            label: "Shelf",
            event: this.shelfPopover,
            enabledOnEditMode: false,
            styleClass: "",
          }),
        ],
        filterType: FilterType.none,
        isNotSortable: false,
        colWidth: 100,
      },
      {
        field: "isActive",
        header: "Active",
        visible: true,
        readonly: false,
        frozen: true,
        dataType: DataType.checkbox,
        dataOptions: [],
        filterType: FilterType.enum,
      },
      {
        field: "stockID",
        header: "Stock ID",
        visible: true,
        readonly: false,
        frozen: true,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "stockName",
        header: "Stock Name",
        visible: true,
        readonly: false,
        frozen: true,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "shortName",
        header: "Stock Acronym",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "stockType",
        header: "Stock Type",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.get_enum_stock_type(),
        filterType: FilterType.enum,
      },
      {
        field: "zoneId",
        header: "Zone Name",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.lookup,
        dataOptions: this.get_lookup_zones(),
        filterType: FilterType.none,
        sortFields: ["zone.zoneName"],
      },
      {
        field: "address.addressLocationType",
        header: "Address Type",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.get_enum_address_type(),
        filterType: FilterType.enum,
      },
      {
        field: "address.line1",
        header: "Address Line 1",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 300,
      },
      {
        field: "address.line2",
        header: "Address Line 2",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 300,
      },
      {
        field: "address.countryIsoCode",
        header: "Country",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.get_enumCountries(),
        filterType: FilterType.enum,
        colWidth: 250,
      },
      {
        field: "address.subDivisionIsoCode",
        header: "Region",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.formlist_lookup_subdivisions.bind(this),
        filterType: FilterType.enum,
        filterOptions: this.formlist_filter_subdivisions.bind(this),
        colWidth: 250,
      },
      {
        field: "address.city",
        header: "City",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 250,
      },
      {
        field: "address.postalCode",
        header: "Postal/Zip",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
    ];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
