/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { SelectItem } from "primeng/api";
import { NestedFormComponent } from "../../../../forms/nested-form/nested-form.component";
import { Week_Day } from "./store-hour";

@Component({
  selector: "taku-store-hour",
  templateUrl: "./store-hour.component.html",
  styleUrls: ["./store-hour.component.scss"],
})
export class StoreHourComponent extends NestedFormComponent {
  @Output() onRemoveBtnPressed: EventEmitter<any> = new EventEmitter();
  enum_week_days: SelectItem[] = this.dbService.enumSelectOptions(Week_Day);
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
