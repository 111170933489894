<taku-form-header
  [toolbarTitle]="formlistTitle"
  displayMode="inline"
  [showActionBtns]="false"
  [showBackBtn]="false"
></taku-form-header>
<div class="col-12 actionsBtn-wrapper">
  <button *ngIf="!isAdminMode()" pButton label="New" (click)="addNewDoc()"></button>
  <button pButton label="Void" (click)="voidSelectedDocs()"></button>
  <button pButton label="Confirm" (click)="confirmSelectedDocs()"></button>
  <button pButton label="Mark as Complete" (click)="markAsComplete()"></button>
</div>

<div class="col-12">
  <taku-form-list
    #formList
    [_showDeleteCol]="true"
    [_model]="_modelName"
    [_detailMode]="'w'"
    _scrollHeight="calc(100vh - 27rem)"
    [_filter]="_formFilters"
    [_isEditable]="true"
    [_cols]="purchaseOrderCols"
    [_title]="formlistTitle"
    [_defaultRowValues]="_defaultRowValues"
    [_showHeader]="true"
    [_hideAddLineHeaderBtn]="true"
    [_hideSavingCtrls]="true"
    [_showCtrlsInEditMode]="true"
    [_detailViewRoute]="_detailViewRoute"
    [_detailQueryParams]="{ model: 'purchaseDoc' }"
    [_hideClone]="true"
    [_hideFilterControls]="false"
    [_hideDeleteHeaderBtn]="true"
    [_hideAddHeaderBtn]="true"
    [_hideGlobalFilter]="true"
    [_extraQueryParams]="_formListExtraParams"
  >
  </taku-form-list>
</div>

<ng-template #totalReceivedTpl let-rowData>
  <div>
    <p-progressBar [value]="rowData.totalReceivedPercentage"></p-progressBar>
  </div>
</ng-template>
