/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { ValidatorFn, AbstractControl, FormControl } from "@angular/forms";
import { TakuAddressValidationService } from "../services/taku-address-validation.service";
import { TakuPostalCodeComponent } from "../../taku-ui/taku-postal-code/taku-postal-code.component";

export class AddressValidators {
  static postalCodeValidator(validator: TakuAddressValidationService, component: TakuPostalCodeComponent): ValidatorFn {
    return (c: AbstractControl): { [key: string]: any } | null => {
      if (c.pristine) {
        return null;
      }

      // const countryControl = <FormControl>c.parent.get('countryIsoCode');
      // let isoCountry:string;
      //countryControl === null || !(isoCountry = countryControl.value)
      if (!component || !component.countryIsoCode) {
        return { postalcode_nocountry: true };
      }

      if (!validator.validatePostalCode(component.countryIsoCode, c.value)) {
        return { postalcode: true };
      }

      return null;
    };
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
