import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { DBService } from "src/app/shared/services/db.service";
import { FilterRows } from "src/app/utility/FormDataHelpers";
import { Voucher } from "../create-voucher/voucher";
import { ApiListResponse } from "src/app/utility/types";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { FulfillmentStatus, PaymentStatus } from "../sale-doc/sale-doc";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "taku-voucher-balance",
  templateUrl: "./voucher-balance.component.html",
  styleUrls: ["./voucher-balance.component.scss"],
})
export class VoucherBalanceComponent {
  form = new FormGroup({
    uniqueCode: new FormControl(""),
  });

  fetchingVoucher = false;
  error?: string;
  voucher?: Voucher;
  // TODO set to zone currency of voucher
  currencyISOCode = this.appSettingsService.getZone().defaultCurrencyIsoCode;

  @Input() vouchersInTransaction?: Voucher[];

  /** Emits null if voucher is not valid */
  @Output() validVoucherFound = new EventEmitter<Voucher | null>();

  constructor(private dbService: DBService, private appSettingsService: AppSettingsStorageService) {}

  checkVoucherBalance(): void {
    this.error = null;
    this.voucher = null;
    const uniqueCode = this.form.controls.uniqueCode.value;

    if (this.vouchersInTransaction) {
      const voucherIsUsedInTransaction = this.vouchersInTransaction.find((v) => v.uniqueCode === uniqueCode);
      if (voucherIsUsedInTransaction) {
        this.error = "This voucher is already used in this transaction.";
        this.validVoucherFound.emit(null);
        return;
      }
    }

    this.fetchingVoucher = true;

    const filter: FilterRows<Voucher> = {
      uniqueCode: { matchMode: "equals", value: uniqueCode },
    };
    this.dbService
      .getRows<ApiListResponse<Voucher>>("voucher", JSON.stringify(filter), null, null, null, null, {
        includes: "saleDocLine.saleDoc.doc,saleDocTender",
      })
      .subscribe(
        (vouchers) => {
          this.fetchingVoucher = false;
          if (vouchers.count !== 1) {
            this.error = "No voucher exists with this code";
            return;
          }
          const latestVoucher = vouchers.rows[0];
          const statuses = latestVoucher.saleDocLines?.map((saleDocLine) => saleDocLine.saleDoc.paymentStatus);
          const refundedDocLine = latestVoucher.saleDocLines.find((s) => !!s.saleDocLineReturnId);
          const voidedDocLine = latestVoucher.saleDocLines.find(
            (s) => s.saleDoc.paymentStatus === PaymentStatus.voided
          );

          if (!latestVoucher.importDatetime && voidedDocLine) {
            this.error = "This voucher has been voided! Sale Doc # " + voidedDocLine.saleDoc.doc.docNo;
          } else if (
            !latestVoucher.importDatetime &&
            refundedDocLine?.saleDoc?.fulfillmentStatus === FulfillmentStatus.completed
          ) {
            this.error = "This voucher has been refunded! Sale Doc # " + refundedDocLine.saleDoc.doc.docNo;
          } else if (!latestVoucher.importDatetime && !statuses.includes(PaymentStatus.paid)) {
            this.error =
              "This voucher has not been paid for! Sale Doc # " + latestVoucher.saleDocLines[0]?.saleDoc.doc.docNo;
          } else if (latestVoucher.saleDocTender) {
            this.error = "This voucher has been used already.";
          } else if (latestVoucher.zoneId !== this.appSettingsService.getZoneId()) {
            this.error = "This voucher is not usable in this zone.";
          }
          this.voucher = this.error ? null : latestVoucher;
          this.validVoucherFound.emit(this.voucher);
        },
        (err: HttpErrorResponse) => {
          this.fetchingVoucher = false;
          this.error = err.error.customMessage || err.error.message || JSON.stringify(err.error);
        }
      );
  }
}
