/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, ViewChild, OnDestroy, Input, Inject, LOCALE_ID } from "@angular/core";
import { MessageService } from "primeng/api";
import { RowSelectionType } from "../../../../form-list/form-list/form-list";
import { FormListComponent } from "../../../../form-list/form-list/form-list.component";
import { InventoryHistoryCols, InventoryHistoryReportColsFactoryService } from "./inventory-history-cols.service";
import { ReportParamsService } from "../../report-params.service";
import { Subscription } from "rxjs";
import { InventoryHistoryLocalDataService } from "./inventory-history-local-data.service";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";
import { resetReportRowState } from "../../report";
import { LocalDataService } from "src/app/shared/services/local-data.service";
import { InventoryHistory } from "src/app/core/reports/reports-list/inventory-history-report/inventory-history";
import { InventoryDoc } from "src/app/core/document/inventory-document/inventory-document/inventory-document";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";

@Component({
  selector: "taku-inventory-history",
  templateUrl: "./inventory-history.component.html",
  styleUrls: ["./inventory-history.component.scss"],
  providers: [InventoryHistoryLocalDataService],
})
export class InventoryHistoryReportComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];
  @ViewChild("formListReport") formListComponent: FormListComponent;
  @Input() loadInventoryHistoryReportParams;

  _model = "inventoryHistory";
  _stateKey = "FL-" + this._model;
  RowSelectionType = RowSelectionType;
  public reportCols: InventoryHistoryCols;
  dynamicFields: string[] = [];
  currencyIsoCode: string;

  constructor(
    private reportColsFactoryService: InventoryHistoryReportColsFactoryService,
    public inventoryHistoryLocalService: InventoryHistoryLocalDataService,
    private messageService: MessageService,
    private alertMessagesService: AlertMessagesService,
    public reportParamsService: ReportParamsService,
    private localDataService: LocalDataService,
    private appSettings: AppSettingsStorageService
  ) {
    this.reportCols = this.reportColsFactoryService.build();
  }

  ngOnInit(): void {
    this.currencyIsoCode = this.appSettings.getZone().defaultCurrencyIsoCode;

    this.subsList.push(
      this.reportParamsService.reportParamsChanged$.subscribe((filters: Record<string, number>) => {
        this.updateReport(filters);

        resetReportRowState(this._stateKey);
      })
    );
    this.loadInventoryHistoryReportParams();
  }

  private updateReport(filters: Record<string, number>) {
    this.reportParamsService._isFetchingData = true;
    this.subsList.push(
      this.inventoryHistoryLocalService.getInventoryHistoryReport(filters.inventoryId).subscribe({
        next: (rows) => {
          this.buildFormlistCols(rows && rows.length ? rows[0] : null);
          this.refreshFormList(rows || []);
          this.reportParamsService._isFetchingData = false;
          this.localDataService.updateNewObject(rows);
        },
        error: (error) => {
          this.buildFormlistCols(null);
          // Show toast with error message
          this.messageService.add(this.alertMessagesService.getErrorMessage(error));
          // Empty form list
          this.refreshFormList([]);
          this.reportParamsService._isFetchingData = false;
        },
      })
    );
  }

  private buildFormlistCols(rowData: InventoryHistory) {
    this.reportCols = this.reportColsFactoryService.build();
  }

  private refreshFormList(data: any[]) {
    data.forEach((inventoryHistory: InventoryHistory) => {
      // since getters don't work for the style of form list we are doing, we are doing this instead, below:
      const inventoryHistoryReport = Object.assign(new InventoryHistory(), inventoryHistory);
      if (inventoryHistoryReport.inventoryDocId) {
        inventoryHistoryReport.inventoryDoc = Object.assign(new InventoryDoc(), inventoryHistoryReport.inventoryDoc);
        inventoryHistory.totalCost = inventoryHistoryReport.inventoryDocLinesTotalCost(this.currencyIsoCode);
      }

      inventoryHistory.price = inventoryHistoryReport.firstLineUnitPriceCurrencyFormat;
    });
    this.inventoryHistoryLocalService.initialize(data, this._model);
    setTimeout(() => {
      this.formListComponent.reloadData();
    }, 0);
  }

  ngOnDestroy(): void {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
