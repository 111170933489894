<taku-form-header
  (backClicked)="goBack()"
  toolbarTitle="GMB Integration (Google My Business)"
  iconStyleClass="takuicon-gmb mr-1"
  [showActionBtns]="false"
  [showBackBtn]="true"
  displayMode="inline"
>
</taku-form-header>

<p-panel id="googleAccounts-wrapper" class="grid col-12 card">
  <ng-template pTemplate="header">
    <div class="googleAccounts-header">
      <h2>My Google Accounts</h2>
      <div class="connect-googleAccount">
        <button
          pButton
          label="CONNECT your Google Account"
          class="p-button-primary-dark"
          (click)="connectAccount()"
        ></button>
      </div>
    </div>
  </ng-template>

  <div *ngIf="!gmbGoogleAccounts.length" class="empty-results-msg">
    No Connected Google Accounts. Press button 'CONNECT your Google Account' to connect a new account.
  </div>

  <div class="col-12 googleAccount-details" *ngFor="let gmbAccountLink of gmbGoogleAccounts; let i = index">
    <div class="grid heading-line">
      <div class="col-12 sm:col-3 md:col-2 lg:col-1">
        <label>Your Google Account:</label>
      </div>
      <div class="col-12 sm:col-3 md:col-3 lg:col-3 google-email">
        <span class="value">{{ gmbAccountLink?.googleAccount?.email || "N/A" }}</span>
      </div>
      <div class="col-12 sm:col-6 md:col-7 lg:col-8">
        <button
          pButton
          label="Disconnect"
          class="p-button-secondary disconnect-btn"
          (click)="disconnectAccount(gmbAccountLink)"
        ></button>
      </div>
    </div>

    <table class="col-12">
      <thead>
        <tr>
          <th class="col-delete"></th>
          <th class="col-7 col-account-name">GMB Account Name</th>
          <th class="col-4 col-view-locations"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let gmbAccount of gmbAccountLink.gmbAccounts">
          <td class="col-delete">
            <taku-action-button
              [action]="ACTION_TYPE.REMOVE"
              (clickAction)="tryToRemoveGMBAccount(gmbAccountLink, gmbAccount)"
            ></taku-action-button>
          </td>
          <td class="col-7 col-account-name">
            <div class="value">{{ gmbAccount?.accountName || "N/A" }}</div>
          </td>

          <td class="col-4 col-view-locations">
            <a *ngIf="gmbAccount.id" href="javascript://" (click)="viewLocations(gmbAccountLink, gmbAccount)"
              >Manage Locations</a
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</p-panel>
