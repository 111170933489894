/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DBService } from "../../../../shared/services/db.service";
import { FormListMainComponent } from "src/app/form-list/form-list-main/form-list-main.component";
import { FormListComponent } from "src/app/form-list/form-list/form-list.component";
import { MultiFormListComponent } from "src/app/form-list/form-list-main/multiple-form-lists.interface";
import { Subscription } from "rxjs";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";

@Component({
  selector: "taku-store-form-list",
  templateUrl: "./store-form-list.component.html",
  styleUrls: ["./store-form-list.component.scss"],
})
export class StoreFormListComponent implements OnInit, MultiFormListComponent, OnDestroy {
  subsList: Subscription[] = [];

  @ViewChild(FormListMainComponent, { static: true }) formListMainComponent: FormListMainComponent;

  activeStoreName = "";
  _defaultRowValues: {};
  _disableAddRow = false;

  // _isFormReady = false;

  get pageTitleSuffix() {
    return this.activeStoreName ? `&nbsp;<span class="secondary-text">(${this.activeStoreName})</span>` : "";
  }

  ngOnInit() {
    this.subsList.push(
      this._route.params.subscribe((params) => {
        if (params["storeId"]) {
          const storeId = +params["storeId"];
          this.updatePageTitle(storeId);

          this._defaultRowValues = storeId === 0 ? {} : { storeId };
          // this._isFormReady = true;
        }
      })
    );

    this.subsList.push(
      this._route.data.subscribe((data) => {
        this._disableAddRow = !this.appSettingsService.getUser().isSuperAdmin && data.pageTitle == "Stations";
      })
    );
  }

  updatePageTitle(storeId: number) {
    this.subsList.push(
      this.dbService.getRow("store", storeId).subscribe((store) => {
        this.activeStoreName = store.storeName;
      })
    );
  }

  constructor(
    private _route: ActivatedRoute,
    private dbService: DBService,
    public appSettingsService: AppSettingsStorageService
  ) {}

  // TODO: Perform operation to change store on page
  storeChanged($event) {}

  retrieveFormLists(): FormListComponent[] {
    return [this.formListMainComponent.formListComponent];
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
