/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { DBService, ExtendedSelectItem, LookupOptions } from "src/app/shared/services/db.service";
import { Zone } from "../../settings/zone-settings/Zone";
import { AuthService } from "src/app/shared/services/auth.service";

@Injectable({
  providedIn: "root",
})
export class CommercialAccountDetailService extends DBService {
  constructor(
    protected http: HttpClient,
    protected authService: AuthService,
    private appSettings: AppSettingsStorageService
  ) {
    super(http, authService);
  }

  lookup_zones(): Observable<ExtendedSelectItem<number, Zone>[]> {
    const options: LookupOptions = { enableFieldName: "isActive" };

    return this.lookupSelectOptions("zone", "zoneName", options);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
