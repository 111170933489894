/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { ValidatorFn } from "@angular/forms";
import { Col, DataType, FilterType } from "../../../../form-list/form-list/form-list";
import { FormListModel } from "../../../../form-list/form-list/form-list-model.interface";
import { DBService } from "../../../../shared/services/db.service";
import { InventoryValueReport } from "./inventory-value-report";
import { DataNormalizerService } from "src/app/shared/services/data-normalizes.service";

@Injectable({
  providedIn: "root",
})
export class InventoryValueReportColsFactoryService {
  constructor(public dbService: DBService, private normalizerService: DataNormalizerService) {}

  build(dynamicFields: string[]) {
    return new InventoryValueReportCols(this, dynamicFields, this.normalizerService);
  }
}

export class InventoryValueReportCols implements FormListModel {
  normalizerService: DataNormalizerService;
  constructor(
    private factoryService: InventoryValueReportColsFactoryService,
    private dynamicFields: string[],
    normalizerService: DataNormalizerService
  ) {
    this.normalizerService = normalizerService;
  }

  _disableCloning = true;
  getValidationRules(): { [key: string]: {} | ValidatorFn[] } {
    return {};
  }

  getFormListColumns(): Col[] {
    const cols: Col[] = [
      {
        field: "categoryName",
        header: "Category",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
        isNotSortable: true,
      },
      {
        field: "SKU",
        header: "SKU",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
        isNotSortable: true,
      },
      {
        field: "description1",
        header: "Product Title",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
        isNotSortable: true,
      },
      {
        field: "qtyOH",
        header: "Total QTY OH",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 80,
        isNotSortable: true,
      },
      // {
      //   field: 'qtySold',
      //   header: 'QTY Sold',
      //   visible: true,
      //   readonly: true,
      //   frozen: false,
      //   dataType: DataType.number,
      //   dataOptions: [],
      //   filterType: FilterType.contains,
      //   colWidth: 80
      // },
      {
        field: "standardPrice",
        header: "Unit Sell Price",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 80,
        isNotSortable: true,
      },
      {
        field: "totalSoldPriceCalculated",
        header: "Total Price $",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 80,
        footer: true,
        isNotSortable: true,
      },
      {
        field: "standardCost",
        header: "Unit Std Cost",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 80,
        isNotSortable: true,
      },
      {
        field: "totalCostCalculated",
        header: "Total Cost $",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 80,
        footer: true,
        isNotSortable: true,
      },
      {
        field: "marginPercentCalculated",
        header: "Margin %",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 80,
        isNotSortable: true,
        displayFn: (rowData: InventoryValueReport) => {
          return this.normalizerService.normalizeNumbers(rowData.marginPercentCalculated, 2, 2);
        },
      },
      {
        field: "marginAmountCalculated",
        header: "Margin $",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 80,
        footer: true,
        isNotSortable: true,
      },
    ];

    // this.dynamicFields.forEach(field => cols.push(
    //   {
    //     field, header: field, visible: true, readonly: true, frozen: false, dataType: DataType.input,
    //     dataOptions: [], filterType: FilterType.contains, colWidth: 80
    //   }
    // ));

    return cols;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
