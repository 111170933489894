/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import * as _ from "lodash";
import { MonetaryHelpers } from "../../../../utility/MonetaryHelpers";
import { DocLine } from "../../doc-line/doc-line";
import { SaleDocLineTax } from "../sale-doc-line-tax/sale-doc-line-tax";
import { StoreSettingReturnReason } from "../../../settings/store-settings/return-reason/StoreSettingReturnReason";
import { TaxRule } from "src/app/core/tax/tax-rule/tax-rule";
import { RecurringOrderSetting } from "src/app/core/settings/zone-settings/recurring-order-settings/recurring-order-settings";
import { FrequencyName } from "src/app/core/inventory/inventory-usage-detail/inventory-usage-detail";
import { Voucher } from "../create-voucher/voucher";
import { SaleDoc } from "../sale-doc/sale-doc";

export type TotalBySaleTax = {
  saleTaxRule: TaxRule;
  total: number;
};

export class SaleDocLine {
  id: number;
  discountType: DiscountType;
  discountAmount: number | string;
  discountSetManually: boolean;
  qty: number;
  unitPrice: number;
  unitCost: number;
  docLine: DocLine;
  saleDocLineTaxes: SaleDocLineTax[];
  saleDocLineReturn?: SaleDocLineReturn;
  saleDocLineReturnId?: number;
  repeatCycle: Repeat;
  recurringOrderSettingId: number;
  recurringOrderSetting: RecurringOrderSetting;
  frequencyName?: FrequencyName;
  qtyReferred?: number;
  voucherId: number | null;
  voucher?: Voucher;
  saleDoc?: SaleDoc;

  constructor() {
    this.id = 0;
    this.discountType = DiscountType.Percentage;
    this.discountAmount = 0;
    this.discountSetManually = false;
    this.qty = 0;
    this.unitPrice = 0;
    this.unitCost = 0;
    this.saleDocLineTaxes = [];
    this.saleDocLineReturn = null;
    this.saleDocLineReturnId = null;
    this.docLine = new DocLine();
    this.repeatCycle = null;
    this.recurringOrderSettingId = null;
    this.recurringOrderSetting = null;
    this.frequencyName = null;
  }

  get discountAmountMoney(): number {
    switch (this.discountType) {
      case DiscountType.Fixed:
        return +this.discountAmount;

      case DiscountType.Percentage:
        return (this.unitPrice * +this.discountAmount) / 100;

      case DiscountType.Final_Price:
        return this.unitPrice - +this.discountAmount;

      default:
        return 0;
    }
  }

  get qtyNotReferred(): number {
    return Math.max(this.qty - this.qtyReferred, 0);
  }

  get salePrice(): number {
    let salePrice = this.unitPrice;
    // If discount amount is empty just return the initial unitprice
    if (
      this.discountAmount && typeof this.discountAmount.valueOf() === "string"
        ? !(this.discountAmount as string).trim()
        : this.discountAmount == null
    )
      return salePrice;

    salePrice -= this.discountAmountMoney;
    // switch(this.discountType){
    //   case DiscountType.Fixed:
    //      salePrice -= +this.discountAmount;
    //      break;
    //   case DiscountType.Percentage:
    //     salePrice *= (1 - +this.discountAmount/100);
    //     break;

    //   case DiscountType.Final_Price:
    //     salePrice = +this.discountAmount;
    //     break;
    // }

    return MonetaryHelpers.roundToDecimalPlacesHalfUp(salePrice, 4);
  }

  // Added in order to prevent TypedError when salePrice is fetched from form
  set salePrice(newSalePrice) {
    // DO Nothing
  }

  get lineDiscount(): number {
    return this.qty * (this.unitPrice - this.salePrice);
  }

  get totalTaxes(): number {
    let totalTaxes = 0;
    if (this.saleDocLineTaxes && this.saleDocLineTaxes.length)
      totalTaxes = this.saleDocLineTaxes.map((tax) => parseFloat(tax.amount + "")).reduce((total, tax) => total + tax);

    return totalTaxes;
  }

  static convertIntoTypedObject(saleLine: any): SaleDocLine {
    return _.merge(new SaleDocLine(), saleLine);
  }
}

export class SaleDocLineReturn {
  storeSettingReturnReasonId: number;
  storeSettingReturnReason: StoreSettingReturnReason;

  constructor() {
    this.storeSettingReturnReasonId = null;
    this.storeSettingReturnReason = null;
  }
}

export enum DiscountType {
  Fixed = "Fixed",
  Percentage = "Percentage",
  Final_Price = "Final Price",
}

export enum Repeat {
  YES_ONGOING = "Yes Ongoing",
  ONE_TIME = "1 Time",
  TWO_TIMES = "2 Times",
  THREE_TIMES = "3 Times",
  FOUR_TIMES = "4 Times",
  SIX_TIMES = "6 Times",
}

export const repeatCycleToNum = (repeat: Repeat): number => {
  switch (repeat) {
    case Repeat.ONE_TIME:
      return 1;
    case Repeat.TWO_TIMES:
      return 2;
    case Repeat.THREE_TIMES:
      return 3;
    case Repeat.FOUR_TIMES:
      return 4;
    case Repeat.SIX_TIMES:
      return 6;
    default:
      return 0;
  }
};

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
