/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable, LOCALE_ID, Inject } from "@angular/core";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { LocalDataService } from "src/app/shared/services/local-data.service";
import { CurrencyPipe, DecimalPipe } from "@angular/common";
import { SaleAnalysisSummaryReport } from "./sale-analysis-summary-report";
import { AuthService } from "src/app/shared/services/auth.service";
import { HttpClient } from "@angular/common/http";
import * as moment from "moment";
@Injectable({
  providedIn: "root",
})
export class SaleAnalysisSummaryReportLocalDataService extends LocalDataService {
  constructor(
    protected http: HttpClient,
    protected authService: AuthService,
    private appSettingsService: AppSettingsStorageService,
    @Inject(LOCALE_ID) private localeId: string
  ) {
    super(http, authService);
  }

  afterFetch(object, model: string) {
    const currency = this.appSettingsService.getZone().defaultCurrencyIsoCode;
    const currencyFormatter = new CurrencyPipe(this.localeId);

    Object.keys(object)
      .filter((key) => !Object.keys(new SaleAnalysisSummaryReport()).includes(key))
      .forEach((key) => {
        if (key.includes("-taxRate")) {
          object[key] = object[key] + " %";
        } else {
          object[key] = currencyFormatter.transform(object[key], currency, "code");
        }
      });

    object.shipperChargeAmount = currencyFormatter.transform(object.shipperChargeAmount, currency, "code");
    object.discountAmount = currencyFormatter.transform(object.discountAmount, currency, "code");
    object.marginAmountCalculated = currencyFormatter.transform(object.marginAmountCalculated, currency, "code");
    object.marginPercentCalculated = `${new DecimalPipe(this.localeId).transform(object.marginPercentCalculated)}%`;
    object.totalStandardCost = currencyFormatter.transform(object.totalStandardCost, currency, "code");
    object.totalAmountCalculated = currencyFormatter.transform(object.totalAmountCalculated, currency, "code");
    object.subTotal = currencyFormatter.transform(object.subTotal, currency, "code");
  }

  getSaleAnalysisSummaryReport(
    storeId: number,
    stationId: number,
    dateFrom: Date | string,
    dateTo: Date | string,
    accountId = 0,
    userId = 0
  ) {
    const timeTo = moment(dateTo).format("HH:mm:ss");
    const timeFrom = moment(dateFrom).format("HH:mm:ss");
    dateTo = moment(dateTo).format("YYYY-MM-DD");
    dateFrom = moment(dateFrom).format("YYYY-MM-DD");
    const queryParams = {
      dateFrom,
      dateTo,
      timeFrom,
      timeTo,
      accountId,
      userId,
      stationId,
    };

    return this._getRequest<SaleAnalysisSummaryReport[]>(
      this.webUrl + "repStoreSaleAnalysisSummary/" + storeId,
      queryParams
    );
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
