/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Location } from "@angular/common";
import { Component, Output, EventEmitter } from "@angular/core";
import { AsyncValidatorFn, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { SelectItem, MessageService, ConfirmationService } from "primeng/api";
import { Observable } from "rxjs";
import { GenericFormComponent } from "../../../../forms/generic-form/generic-form.component";
import { AddressLocationType } from "../../../contact-accounts/address/address";
import { AddressComponent } from "../../../contact-accounts/address/address.component";
import { Shipper, Shipper_Type, ShippingMethod } from "./shipper";
import { ShipperService } from "./shipper.service";
import { Router, ActivatedRoute } from "@angular/router";
import { DBService } from "../../../../shared/services/db.service";
import { AlertMessagesService } from "../../../../shared/services/alert-messages.service";
import { AppSettingsStorageService } from "../../../../shared/app-settings-storage.service";
import { SaleChannelOrdersService } from "src/app/core/sale-channel/sale-channel-orders/sale-channel-orders.service";
import * as _ from "lodash";
import { AppComponent } from "src/app/app.component";
@Component({
  selector: "taku-shipper",
  templateUrl: "./shipper.component.html",
  styleUrls: ["./shipper.component.scss"],
})
export class ShipperComponent extends GenericFormComponent {
  @Output() dialogClosed = new EventEmitter<any>();

  enum_shipper_type = this.dbService.enumSelectOptions(Shipper_Type);
  lookup_zones: Observable<SelectItem[]>;
  // ngOnInit() {
  // }

  constructor(
    public shipperService: ShipperService,
    protected _router: Router,
    public fb: UntypedFormBuilder,
    public dbService: DBService,
    protected location: Location,
    public _route: ActivatedRoute,
    protected messageService: MessageService,
    protected alertMessage: AlertMessagesService,
    protected confirmationService: ConfirmationService,
    appSettings: AppSettingsStorageService,
    private saleChannelOrdersService: SaleChannelOrdersService
  ) {
    super(_router, fb, dbService, location, _route, messageService, alertMessage, confirmationService, appSettings);
  }

  closeSidebar() {
    this.dialogClosed.emit();
  }

  initLookups() {
    this.lookup_zones = this.dbService.lookupSelectOptions("zone", "zoneName", { enableFieldName: "isActive" });
  }

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    const tmpModel = new Shipper();
    const tmpForm = fb.group(tmpModel);
    tmpForm.setControl("address", AddressComponent.init(fb, AddressLocationType.commercial));
    return tmpForm;
  }

  initForm() {
    this._model = "shipper";
    this._object = new Shipper();
    this._myForm = ShipperComponent.init(this.fb);
  }

  initValidation() {
    this._validation = this.shipperService.getValidationRules();
    // this._myForm.setAsyncValidators(<AsyncValidatorFn | AsyncValidatorFn[]>this.shipperService.getAsyncValidationRules()['$global']);
    this._myForm
      .get("shipperName")
      .setAsyncValidators(this.shipperService.validateShipperNameIsUnique.bind(this.shipperService, this._myForm));
    this._myForm.get("shipperName").updateValueAndValidity();
    this._myForm
      .get("shortName")
      .setAsyncValidators(this.shipperService.validateShortNameIsUnique.bind(this.shipperService, this._myForm));
    this._myForm.get("shortName").updateValueAndValidity();
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
