<span *ngIf="lookup_zones | async as zones">
  <ng-template [ngIf]="zones.length > 1">
    <div class="p-float-label">
      <p-dropdown
        [options]="zones"
        [filter]="true"
        [style]="{ width: '200px' }"
        (onChange)="onZoneChanged($event)"
        [(ngModel)]="selectedZoneId"
      ></p-dropdown>
      <label>View Zones</label>
    </div>
  </ng-template>
</span>
