import { Component, Input } from "@angular/core";
import { SaleDocTender } from "../sale-doc-tender/sale-doc-tender";

@Component({
  selector: "taku-tender-details-overlay",
  templateUrl: "./tender-details-overlay.component.html",
  styleUrls: ["./tender-details-overlay.component.scss"],
})
export class TenderDetailsOverlayComponent {
  @Input() saleDocTenders: SaleDocTender[];
}
