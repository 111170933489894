/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ValidatorFn, Validators } from "@angular/forms";
import { DataType, FilterType, Col, ArrayDatatypeOptions } from "../../../form-list/form-list/form-list";
import { FormListModel } from "../../../form-list/form-list/form-list-model.interface";
import { AuthService } from "../../../shared/services/auth.service";
import { CurrencyFormatterService } from "../../../shared/services/currency-formatter.service";
import { DBService } from "../../../shared/services/db.service";
import { CommonValidators } from "../../../shared/validators/CommonValidators";
import { AccountRelationship, AccountStatus, AccountType } from "../account/account";
import { AccountComponent } from "../account/account.component";
import { CommercialAccount } from "./commercial-account";
import { AccountService } from "../account/account.service";
import { of } from "rxjs";
import { SaleChannelService } from "../../sale-channel/sale-channel.service";
import { EmailArrayComponent } from "../email-array/email-array.component";
import { PhoneArrayComponent } from "../phone-array/phone-array.component";

@Injectable({
  providedIn: "root",
})
export class CommercialAccountService extends DBService implements FormListModel {
  enumAccountStatus = this.enumSelectOptions(AccountStatus);
  enumCurrencies = this.currencyService.getCurrencyCodeList();
  enumAccountRelationship = this.enumSelectOptions(AccountRelationship);

  _model = "commercialAccount";

  constructor(
    private currencyService: CurrencyFormatterService,
    protected http: HttpClient,
    protected authService: AuthService,
    private accountService: AccountService,
    private saleChannelService: SaleChannelService
  ) {
    super(http, authService);
  }

  afterFetch(commercialAccount: CommercialAccount, model: string) {
    switch (model) {
      case this._model:
        const account = commercialAccount.account;
        if (account.defaultCurrencyIsoCode)
          account.creditLimit = this.currencyService.formatMonetaryAmount({
            currencyIsoCode: account.defaultCurrencyIsoCode,
            amount: account.creditLimit,
          });
        break;
    }
  }

  getValidationRules(): { [key: string]: {} | ValidatorFn[] } {
    return {
      // Add validation for proper URL
      url: CommonValidators.websiteURL(),
      name: [Validators.required],
      account: AccountComponent.validators,
      supplierCurrencyIsoCode: [Validators.required],
    };
  }

  getFormListColumns() {
    const activeVisible: boolean = this.authService.hasVisiblePermit("Accounts_Menu_Commercial_Accounts_Status");
    const activeEnabled: boolean = this.authService.hasEnablePermit("Accounts_Menu_Commercial_Accounts_Status");

    const nameVisible: boolean = this.authService.hasVisiblePermit("Accounts_Menu_Commercial_Accounts_ General_Tab");
    const nameEnabled: boolean = this.authService.hasEnablePermit("Accounts_Menu_Commercial_Accounts_ General_Tab");

    const taxCategoryVisible: boolean = this.authService.hasVisiblePermit(
      "Accounts_Menu_Commercial_Accounts_Tax_Category"
    );
    const taxCategoryEnabled: boolean = this.authService.hasEnablePermit(
      "Accounts_Menu_Commercial_Accounts_Tax_Category"
    );

    const creditLimitVisible: boolean = this.authService.hasVisiblePermit(
      "Accounts_Menu_Commercial_Accounts_Credit_Limit"
    );
    const creditLimitEnabled: boolean = this.authService.hasEnablePermit(
      "Accounts_Menu_Commercial_Accounts_Credit_Limit"
    );

    const businessNumberVisible: boolean = this.authService.hasVisiblePermit(
      "Accounts_Menu_Commercial_Accounts_ General_Tab"
    );
    const businessNumberEnabled: boolean = this.authService.hasEnablePermit(
      "Accounts_Menu_Commercial_Accounts_ General_Tab"
    );

    const websiteVisible: boolean = this.authService.hasVisiblePermit("Accounts_Menu_Commercial_Accounts_ General_Tab");
    const websiteEnabled: boolean = this.authService.hasEnablePermit("Accounts_Menu_Commercial_Accounts_ General_Tab");

    const currencyVisible: boolean = this.authService.hasVisiblePermit(
      "Accounts_Menu_Commercial_Accounts_Credit_Limit"
    );
    const currencyEnabled: boolean = this.authService.hasEnablePermit("Accounts_Menu_Commercial_Accounts_Credit_Limit");

    const lookup_taxAccountCategory = this.lookupSelectOptions("taxAccountCategory", "category", {
      enableFieldName: "isEnabled",
    });

    return [
      ...(activeVisible
        ? [
            {
              field: "account.isEnabled",
              header: "Active",
              visible: true,
              readonly: !activeEnabled,
              frozen: true,
              dataType: DataType.checkbox,
              dataOptions: Col.boolean_filter_enum,
              filterType: FilterType.enum,
              colWidth: 120,
            },
          ]
        : []),
      ...(nameVisible
        ? [
            {
              field: "name",
              header: "Company Name",
              visible: true,
              readonly: !nameEnabled,
              frozen: true,
              dataType: DataType.input,
              dataOptions: of([]),
              filterType: FilterType.contains,
            },
          ]
        : []),
      {
        field: "account.accountRelationship",
        header: "Account Type",
        visible: true,
        readonly: false,
        frozen: true,
        dataType: DataType.enum,
        dataOptions: this.enumAccountRelationship,
        filterType: FilterType.enum,
        colWidth: 150,
      },
      {
        field: "commercialAccountPhones",
        header: `Phones`,
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.array,
        dataTypeOptions: new ArrayDatatypeOptions({
          component: PhoneArrayComponent,
          displayField: "tel",
          showSummary: true,
        }),
        dataOptions: [],
        filterType: FilterType.contains,
        filterField: "commercialAccountPhones.tel",
        isNotSortable: true,
        sortFields: ["commercialAccountPhones.tel"],
      },
      {
        field: "commercialAccountEmails",
        header: `Emails`,
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.array,
        dataTypeOptions: new ArrayDatatypeOptions({
          component: EmailArrayComponent,
          displayField: "email",
          showSummary: true,
        }),
        dataOptions: [],
        filterType: FilterType.contains,
        filterField: "commercialAccountEmails.email",
        isNotSortable: true,
        sortFields: ["commercialAccountEmails.email"],
      },
      {
        field: "account.accountCode",
        header: "Account Code",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: of([]),
        filterType: FilterType.contains,
      },
      {
        field: "account.acctGroups",
        fieldValue: "account.acctGroupsList",
        header: "Account Groups",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.multiselect,
        dataOptions: this.accountService.lookupAccountGroup(AccountType.commercial),
        importingOptions: { header: "Account Groups" },
        filterType: FilterType.multiselect,
        isNotSortable: true,
      },
      {
        field: "account.channelTypes",
        header: "Channel Types",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enumMultiselect,
        dataKey: null,
        filterDataKey: null,
        dataOptions: this.saleChannelService.enumChannelTypes(),
        filterType: FilterType.enum,
        filterOptions: this.saleChannelService.enumChannelTypes(),
        filterMatchMode: "contains",
        filterField: "`account.channelTypes`",
        colWidth: 250,
      },
      ...(creditLimitVisible
        ? [
            {
              field: "account.creditLimit",
              header: "Credit Limit",
              importingOptions: { header: "CREDIT LIMIT Amount", colWidth: 165 },
              visible: true,
              readonly: !creditLimitEnabled,
              frozen: false,
              dataType: DataType.number,
              dataOptions: of([]),
              filterType: FilterType.contains,
              colWidth: 140,
            },
          ]
        : []),
      ...(businessNumberVisible
        ? [
            {
              field: "accountBusinessNum",
              header: "Business Number",
              visible: false,
              readonly: !businessNumberEnabled,
              frozen: false,
              dataType: DataType.input,
              dataOptions: of([]),
              filterType: FilterType.contains,
            },
          ]
        : []),
      ...(websiteVisible
        ? [
            {
              field: "url",
              header: "Website Address",
              visible: false,
              readonly: !websiteEnabled,
              frozen: false,
              dataType: DataType.input,
              dataOptions: of([]),
              filterType: FilterType.contains,
            },
          ]
        : []),
      ...(taxCategoryVisible
        ? [
            {
              field: "account.taxAccountCategoryId",
              fieldValue: "account.taxAccountCategory.category",
              header: "Tax Category",
              visible: false,
              readonly: !taxCategoryEnabled,
              frozen: false,
              dataType: DataType.lookup,
              dataOptions: lookup_taxAccountCategory,
              filterType: FilterType.lookup,
              sortFields: ["account.taxAccountCategory.category"],
            },
          ]
        : []),
      ...(currencyVisible
        ? [
            {
              field: "account.defaultCurrencyIsoCode",
              header: "Currency",
              importingOptions: { header: "CREDIT LIMIT Currency", colWidth: 155 },
              visible: false,
              readonly: !currencyEnabled,
              frozen: false,
              dataType: DataType.enum,
              dataOptions: this.enumCurrencies,
              filterType: FilterType.enum,
            },
          ]
        : []),
      {
        field: "account.tags",
        header: `Tags`,
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.chips,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "account.note",
        header: `Internal Notes`,
        visible: false,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 300,
      },
    ];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
