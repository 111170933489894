/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { AuthService } from "./auth.service";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { finalize, shareReplay } from "rxjs/operators";
import { TaxRule } from "src/app/core/tax/tax-rule/tax-rule";

type CalcPurchaseTaxLineCache = Map<string, [number, Observable<TaxRule[]>]>;

const CACHE_TIME_MILLISECONDS = 4000; // 4 seconds

@Injectable({
  providedIn: "root",
})
export class DBTaxService {
  protected webUrl = environment.apiUrl;
  pendingRequestCounter = 0;
  isPending = new BehaviorSubject<boolean>(false);
  private calcPurchaseTaxLineCache: CalcPurchaseTaxLineCache = new Map();

  constructor(protected http: HttpClient, protected authService: AuthService) {}

  calculateSaleTaxLine(
    inventoryId: number,
    unitSalePrice: number,
    qty: number,
    storeId: number,
    inStore: boolean,
    accountId: number = null,
    taxAccountCategoryId: number = null,
    docDate: string = null
  ): Observable<any> {
    // tracks the number of requests that are pending.
    this.pendingRequestCounter++;
    // isPending is an observable that represents wheter or not a requested(s) is completed.
    this.isPending.next(true);

    let params = new HttpParams();
    params = params.append("_storeId", storeId.toString());
    params = params.append("_unitPrice", unitSalePrice.toString());
    params = params.append("_qty", qty.toString());
    params = params.append("_inStore", inStore ? "1" : "0");
    params = params.append("_accountId", accountId ? accountId.toString() : "null");
    params = params.append("_taxAccountCategoryId", taxAccountCategoryId ? taxAccountCategoryId.toString() : "null");
    if (docDate) {
      params = params.append("_date", docDate);
    }
    return this.http
      .get<any[]>(this.webUrl + "calcSaleTaxLine/" + inventoryId, {
        headers: new HttpHeaders().set("Authorization", this.authService.getToken()),
        params,
      })
      .pipe(
        finalize(() => {
          this.pendingRequestCounter--;
          if (this.pendingRequestCounter === 0) {
            this.isPending.next(false);
          }
        })
      );
  }

  calculatePurchaseTaxLine(
    inventoryId: number,
    unitPrice: number,
    qty: number,
    stockId: number,
    shipFromAddressId: number = null,
    taxAccountCategoryId: number = null,
    docDate: string = null
  ): Observable<TaxRule[]> {
    let params = new HttpParams();
    params = params.append("_stockId", stockId?.toString());
    params = params.append("_unitPrice", unitPrice.toString());
    params = params.append("_qty", qty?.toString() || "0");
    params = params.append("_shipFromAddressId", shipFromAddressId ? shipFromAddressId.toString() : "null");
    params = params.append("_taxAccountCategoryId", taxAccountCategoryId ? taxAccountCategoryId.toString() : "null");
    if (docDate) {
      params = params.append("_date", docDate);
    }

    const paramsArr: [
      inventoryId: number,
      unitPrice: number,
      qty: number,
      stockId: number,
      shipFromAddressId?: number,
      taxAccountCategoryId?: number,
      docDate?: string
    ] = [inventoryId, unitPrice, qty, stockId, shipFromAddressId, taxAccountCategoryId, docDate];

    let cacheKey = paramsArr.join(":");

    const cacheBody = this.calcPurchaseTaxLineCache.get(cacheKey);
    const isCached = !!cacheBody;

    if (isCached && Date.now() - cacheBody[0] < CACHE_TIME_MILLISECONDS) {
      const taxRulesCachedObservable = cacheBody[1];
      return taxRulesCachedObservable;
    } else {
      const apiObservable = this.http
        .get<TaxRule[]>(this.webUrl + "calcPurchaseTaxLine/" + inventoryId, {
          headers: new HttpHeaders().set("Authorization", this.authService.getToken()),
          params,
        })
        .pipe(shareReplay(1));

      this.calcPurchaseTaxLineCache.set(cacheKey, [Date.now(), apiObservable]);
      return apiObservable;
    }
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
