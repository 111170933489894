/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { PrinterSettings } from "../printer/printer-settings";
import { Store } from "../store/store";

export class Station {
  id: number;
  stationName: string;
  stationNumber: string;
  receiptPrinterId: number;
  receiptPrinter: PrinterSettings;
  prepPrinterId: number;
  prepPrinter: PrinterSettings;
  packingListPrinterId: number;
  packingListPrinter: PrinterSettings;
  loggedInUserId: number;
  isActive: boolean;
  storeId: number;
  store: Store;
  openingFloat: number; // This will store the total
  stationType: Station_Type;

  constructor() {
    this.id = 0;
    this.stationName = "";
    this.stationNumber = "";
    this.receiptPrinterId = null;
    this.receiptPrinter = null;
    this.prepPrinterId = null;
    this.prepPrinter = null;
    this.packingListPrinterId = null;
    this.packingListPrinter = null;
    this.loggedInUserId = null;
    this.isActive = true;
    this.storeId = null;
    this.store = null;
    this.openingFloat = 0;
    this.stationType = Station_Type.sales_register;
  }
}

export enum Station_Type {
  sales_register = "Sales Register",
  kiosk = "Kiosk",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
