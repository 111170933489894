<div [formGroup]="_myForm">
  <div class="grid moa">
    <div class="col-5 md:col-5 drop-container">
      <taku-dropdown [formGroup]="_myForm" [fieldName]="'zoneId'" [fieldCaption]="'Zone'" [options]="_zones">
      </taku-dropdown>
    </div>
    <div class="col-2 md:col-2 center">
      {{ _myForm.controls["zone"]?.value.defaultCurrencyIsoCode }}
    </div>
    <div class="col-4 md:col-4">
      <taku-input
        [formGroup]="_myForm"
        [fieldName]="'minOrderAmount'"
        [fieldCaption]="'Minimum Order Amount'"
      ></taku-input>
    </div>
    <div class="col-1 center">
      <i class="material-icons remove-button" (click)="remove()">remove_circle_outline</i>
    </div>
  </div>
</div>
