<div class="grid">
  <div class="md:col-3 col-5">
    <taku-dropdown
      [formGroup]="inventoryVariantSettingLineForm"
      [options]="lookup_inventoryVariantSetting$ | async"
      fieldName="inventoryOptionsSettingId"
      appendTo="body"
      [fieldCaption]="'Available Options'"
    >
    </taku-dropdown>
  </div>
  <div class="md:col-4 col-7">
    <taku-multiselect
      [formGroup]="inventoryVariantSettingLineForm"
      [options]="lookup_inventoryVariantSettingValue$ | async"
      fieldName="inventoryOptionsSettingValues"
      appendTo="body"
      [fieldCaption]="'Inventory Option Values'"
    >
    </taku-multiselect>
    <!-- [disabled]="inventoryVariantSettingLineForm.get('inventoryOptionsSettingId').value == null" -->
  </div>
  <div class="md:col-5 col-12">
    <div class="grid-nogutter p-fluid">
      <p-chips [ngModel]="inventoryOptionsSettingValuesChips" [disabled]="true"></p-chips>
    </div>
  </div>
</div>
