<taku-form-header
  [shortenHeight]="shortenFormHeaderHeight"
  toolbarTitle="Parked Sales"
  displayMode="inline"
  [showActionBtns]="false"
  [showBackBtn]="false"
></taku-form-header>

<div
  id="parked-sales-box"
  [formGroup]="formGroup"
  class="grid grid-nogutter col-12"
  [ngClass]="{ 'full-size': fullSizeMode }"
>
  <div class="grid col-12 fields-line first-block">
    <div
      class="col-12 md:col-12 sm:col-12 field-account"
      [ngClass]="{ disabled: formGroup.get('docNo').value, 'field-fullsize lg:col-6 xl:col-8': fullSizeMode }"
    >
      <div class="p-inputgroup inputgroup-outlined mt-2">
        <span class="p-inputgroup-addon"><i class="material-icons">group</i></span>
        <taku-search-accounts
          id="account_search"
          #searchAccountsComp
          [disabled]="formGroup.get('docNo').value"
          placeholder="Search an Account"
          [autoClearOnSelection]="false"
          [includeHeader]="false"
          [includeCreateNewBtns]="false"
          (itemSelected)="onSearchResAccount($event)"
          (onSelectedItemCleared)="clearSelectedAccount()"
        ></taku-search-accounts>
      </div>
    </div>

    <div class="col-6 md:col-6 sm:col-6 field-datefrom" [ngClass]="{ 'lg:col-3 xl:col-2': fullSizeMode }">
      <taku-calendar-date
        [disabled]="formGroup.get('docNo').value"
        [formGroup]="formGroup"
        fieldName="dateFrom"
        fieldCaption="Date From:"
        appendTo="body"
      ></taku-calendar-date>
    </div>

    <div class="col-6 md:col-6 sm:col-6 field-dateto" [ngClass]="{ 'lg:col-3 xl:col-2': fullSizeMode }">
      <taku-calendar-date
        [disabled]="formGroup.get('docNo').value"
        [formGroup]="formGroup"
        fieldName="dateTo"
        fieldCaption="Date To:"
        appendTo="body"
      ></taku-calendar-date>
    </div>
  </div>

  <div class="justify-content-end grid col-12 search-box-btns fields-line">
    <div class="col-6 md:col-6 p-fluid" [ngClass]="{ 'lg:col-6 xl:col-2': fullSizeMode }">
      <button pButton label="Clear" class="p-button-secondary" (click)="clearSearch()"
        ><span class="hidden-icons p-inputgroup-addon"><i class="icon pi pi-times"></i></span
      ></button>
    </div>

    <div class="col-6 md:col-6 p-fluid" [ngClass]="{ 'lg:col-6 xl:col-2': fullSizeMode }">
      <button pButton label="Search" (click)="searchDocuments()"
        ><span class="hidden-icons p-inputgroup-addon"><i class="icon pi pi-search"></i></span
      ></button>
    </div>
  </div>
  <!-- </div> -->
</div>

<div *ngIf="showFormList" class="col-12 formlistWrapper">
  <taku-form-list
    #formList
    *ngIf="_isDataReady"
    [_showDeleteCol]="true"
    [_disableDeleteRowButton]="disableDeleteRowButton"
    [_extraQueryParams]="extraQueryParams"
    [_showHeader]="false"
    [_filter]="formFilter ? formFilter : undefined"
    _model="saleDoc"
    _detailMode="d"
    [_scrollHeight]="_scrollHeight"
    [_cols]="parkedDocColumns"
    [_rowSelectionType]="RowSelectionType.NONE"
    [_saveFormState]="false"
    _dialogStyleClass="fullscreen-mobilemodal"
    [_dialogShowHeader]="false"
    _viewColIcon="_"
    _viewColIconName="remove_red_eye"
    [_dialogDismissable]="true"
  >
    <taku-parked-sales-preview
      taku-formListDetailedView
      (onContinueSale)="onContinueSales($event)"
      [fullSizeMode]="fullSizeMode"
    ></taku-parked-sales-preview>
  </taku-form-list>
</div>

<div [hidden]="true">
  <ng-template #printPreviewHost></ng-template>
</div>
