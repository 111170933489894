<div class="topWrapper">
  <taku-form-header
    [toolbarTitle]="'New Stock Receiving'"
    displayMode="inline"
    [showActionBtns]="false"
    [showBackBtn]="false"
  >
    <button
      pButton
      class="p-button p-button-rounded"
      type="button"
      (click)="onClosePressed()"
      icon="pi pi-times"
    ></button>
  </taku-form-header>

  <!-- <div class="grid"> -->
  <div class="col-12">
    <div class="grid">
      <div class="col-6">
        <taku-dropdown
          [options]="lookup_stocks"
          fieldName="stockId"
          fieldCaption="Receiving Location"
          [formGroup]="_newStockReceivingForm"
          [appendTo]="'body'"
        ></taku-dropdown>
      </div>
      <div class="col-6">
        <div class="p-inputgroup inputgroup-outlined field-searchVendor">
          <span class="p-inputgroup-addon"><i class="material-icons">group</i></span>
          <taku-search-accounts
            id="account_search"
            #accountsSearchBox
            [autoClearOnSelection]="false"
            (onSelectedItemCleared)="clearAccountField()"
            [placeholder]="_searchVendorPlaceholder"
            (itemSelected)="onSearchResAccount($event)"
            (onNewItemClicked)="openNewModelInDialog($event)"
            [accountType]="AccountType.commercial"
            [accountRelationship]="[AccountRelationship.supplier, AccountRelationship.both]"
            [includeHeader]="false"
          >
          </taku-search-accounts>
        </div>
      </div>
      <div class="col-6">
        <taku-currency
          [formGroup]="_newStockReceivingForm"
          fieldName="currencyIsoCode"
          fieldCaption="Receiving Currency"
          [appendTo]="'body'"
          [readonly]="true"
        >
        </taku-currency>
      </div>
      <div class="col">
        <taku-input
          [formGroup]="_newStockReceivingForm"
          fieldName="currencyConversionRate"
          fieldCaption="Convert to Default Currency"
        ></taku-input>
      </div>
      <span class="mt-4">
        <a href="javascript://" class="pi pi-info-circle" (click)="infoPanel.toggle($event)">
          <p-overlayPanel #infoPanel [showCloseIcon]="false" appendTo="body">
            <div class="tooltip-styling">
              <ul>
                <li
                  >This is only required when you are receiving products in a currency other than your Default Currency
                  in Business Settings.</li
                >
              </ul>
              <ul>
                <li
                  >For example, you enter 1.25 if your Default Currency is CAD, you are receiving goods in USD from a
                  supplier and you need to convert the PO Unit Cost from USD to CAD.</li
                >
              </ul>
              <ul>
                <li
                  >NOTE: This conversion rate will apply to all of the products in the Stock Receipt. It cannot be
                  changed once you Finalize the document.</li
                >
              </ul>
            </div>
          </p-overlayPanel>
        </a>
      </span>
    </div>
  </div>

  <footer class="col-12 btn-wrapper">
    <button pButton class="p-button-secondary cancel-btn" label="Cancel" (click)="onClosePressed()"></button>
    <button pButton [disabled]="_newStockReceivingForm.invalid" label="Create" (click)="onCreatePressed()"></button>
  </footer>
</div>
<!-- </div> -->

<p-dialog
  [(visible)]="_activeFullDialog"
  [ngSwitch]="_activeFullDialog"
  [contentStyle]="{ maxHeight: '100%' }"
  styleClass="fixedsize-modal"
  [blockScroll]="true"
  [modal]="true"
  [showHeader]="false"
  appendTo="body"
>
  <div *ngSwitchCase="FullSizeDialogName.COMMERCIAL_ACCOUNT" class="new-commercialAccount-panel">
    <taku-commercial-account
      (changedForm)="onNewCommercialAccountCreated($event)"
      [_isDialog]="true"
      [_object]="_activeFullDialogExtra"
      (dialogClosed)="closeFullSizeDialog()"
    ></taku-commercial-account>
  </div>
</p-dialog>
