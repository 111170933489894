<span [formGroup]="formGroup" [ngClass]="{ 'is-disabled': disabled }">
  <div class="col-12">
    <p-checkbox
      [label]="fieldCaption"
      [formControlName]="fieldName"
      binary="true"
      [disabled]="disabled"
      (onChange)="onChange.emit($event)"
    ></p-checkbox>
  </div>
</span>
