<!-- <p-button type="button" class="p-button-rounded close-btn" icon="pi pi-times" (onClick)="onTryToClose()"></p-button> -->
<div [formGroup]="tmpSaveForm" class="col-12 topWrapper">
  <taku-form-header
    [formGroup]="tmpSaveForm"
    [insideDialog]="true"
    [toolbarTitle]="pageTitle"
    displayMode="inline"
    [showActionBtns]="true"
    [showSecondaryBtns]="false"
    (saveClicked)="onSavedClicked()"
    (revertClicked)="onRevertClicked()"
    (backClicked)="onClose()"
  ></taku-form-header>

  <div class="col-12">
    <div class="card deliveryMethod-fields grid">
      <h3 class="col-12">Delivery Method</h3>

      <label class="col-4 md:col-3">
        <p-radioButton name="deliveryMethod" [value]="Deliverymethod.storePickup" formControlName="deliveryMethod">
        </p-radioButton>
        Pickup
      </label>

      <label class="col-4 md:col-3">
        <p-radioButton name="deliveryMethod" [value]="Deliverymethod.localDelivery" formControlName="deliveryMethod">
        </p-radioButton>
        Delivery
      </label>

      <label class="col-4 md:col-3">
        <p-radioButton name="deliveryMethod" [value]="Deliverymethod.shipOut" formControlName="deliveryMethod">
        </p-radioButton>
        Ship Out
      </label>

      <div class="col">
        <taku-calendar-date
          [formGroup]="tmpSaveForm"
          fieldName="deliveryDate"
          [minDate]="todayDate"
          fieldCaption="Outgoing Date"
          appendTo="body"
        >
        </taku-calendar-date>
      </div>
    </div>

    <div class="col-12 shipOut-body">
      <ng-template
        [ngIf]="
          tmpSaveForm.get('deliveryMethod').value === Deliverymethod.shipOut ||
          tmpSaveForm.get('deliveryMethod').value === Deliverymethod.localDelivery
        "
      >
        <div class="col-12 p-0">
          <div class="col-12 mb-2 heading-wrapper">
            <h3>Shipping Information</h3>
          </div>

          <div class="col-12 grid shipping-info-fields">
            <div class="col-12 sm:col-4 drop-container">
              <taku-dropdown
                [formGroup]="tmpSaveForm"
                [options]="lookupShippers"
                fieldName="shipperId"
                [dataKey]="'id'"
                fieldCaption="Shipper"
                (onChange)="onItemSelected($event)"
              ></taku-dropdown>
            </div>

            <div class="col-12 sm:col-4 drop-container">
              <taku-dropdown
                [formGroup]="tmpSaveForm"
                [options]="enumShippingMethod"
                fieldName="shippingMethod"
                fieldCaption="Shipping Method"
                [disabled]="!tmpSaveForm.get('shipperId').value"
              >
              </taku-dropdown>
            </div>

            <div class="col-12 sm:col-4">
              <taku-input
                [formGroup]="tmpSaveForm"
                fieldName="shipperChargeAmount"
                fieldCaption="Shipping Charges"
                keyFilter="num"
                [disabled]="!tmpSaveForm.get('shippingMethod').value"
              >
              </taku-input>
            </div>
          </div>
        </div>

        <div class="grid col-12">
          <div class="col-12 sm:col-5 heading-wrapper">
            <h3>Shipping Address</h3>
          </div>
          <div class="col-12 sm:col-7 invoice-detail-action-btns">
            <button
              type="button"
              class="p-button-secondary btn-address-copyAccount"
              iconPos="right"
              pButton
              icon=""
              label="Copy From Account"
              (click)="copyShippingAddressFromAccount()"
              [disabled]="!accountShippingAddress"
            >
              <i class="material-icons md-18">person</i>
            </button>
            <button
              type="button"
              class="p-button-secondary btn-address-clearAll"
              iconPos="right"
              pButton
              pRipple
              icon="_"
              label="Clear"
              (click)="clearAddress(this.getFormShippingAddress(this.tmpSaveForm))"
            >
              <i class="material-icons md-18">delete_forever</i>
            </button>
          </div>
        </div>

        <p-scrollPanel [style]="{ height: 'calc(100vh - 365pt)' }">
          <taku-address class="col-12" #shippingAddress [formGroup]="tmpSaveForm.get('shippingAddress')"></taku-address>
        </p-scrollPanel>
      </ng-template>
    </div>
  </div>
</div>
