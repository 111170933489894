<form [formGroup]="formGroup" *ngIf="!showConfirmation">
  <div class="col-12 md:col-8 lg:col-5 content-container grid">
    <div class="col-12 body bgColor-white">
      <div class="brand-container">
        <div class="logo-container">
          <img src="assets/layout/images/logo.png" />
        </div>
      </div>

      <div class="reset-pw-label-container">
        <h2>Reset Your Password</h2>
      </div>

      <div class="field-and-btns">
        <div class="col-12 input-container">
          <taku-input
            [formGroup]="formGroup"
            fieldName="password"
            fieldCaption="New password"
            type="password"
            style="margin-bottom: '5px'"
          ></taku-input>
          <taku-input
            [formGroup]="formGroup"
            fieldName="confirmPassword"
            fieldCaption="Retype new password"
            [showValidationErrors]="false"
            type="password"
          ></taku-input>
          <div *ngIf="formGroup.controls.confirmPassword.touched">
            <div class="invalid" *ngIf="formGroup.controls.confirmPassword.hasError('confirmPasswordMatch')"
              >Password does not match</div
            >
          </div>
        </div>
        <div class="grid col-12 grid-nogutter button-container mt-3">
          <div class="col-8 grid-align-right">
            <button
              pButton
              type="submit"
              class="p-button-lg"
              (click)="onResetPassword()"
              label="Reset Password"
              [disabled]="!formGroup.valid"
            >
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
<div class="confirm-container" *ngIf="showConfirmation">
  <h3>Your new password has been set successfully</h3>
  <div class="col-4">
    <a pButton (click)="onBack()" class="p-button-lg" label="Login"></a>
  </div>
</div>
