<div class="grid">
  <div class="header-wrapper col-12">
    <h4>Bulk Edit Options</h4>
  </div>

  <div class="col left-side">
    <div class="grid">
      <div class="col-6 lg:col-3">
        <taku-dropdown
          [formGroup]="bulkEditorForm"
          [options]="enum_editingFields"
          fieldName="editingField"
          fieldCaption="Choose Column"
        ></taku-dropdown>
      </div>

      <div class="col-6 lg:col-3">
        <taku-dropdown
          *ngIf="editingFieldCtrl.value"
          [formGroup]="bulkEditorForm"
          [options]="enum_editOptions"
          fieldName="editOption"
          fieldCaption="Choose Edit Option"
        ></taku-dropdown>
      </div>

      <ng-template [ngIf]="_includes(groupedeEditOptions.get(1), editOptionCtrl.value)">
        <div
          [formGroup]="bulkEditorForm"
          [ngClass]="{
            'col-12 lg:col-6': currentCtrlType === FieldCtrlType.CHIPS,
            'col-6 lg:col-3': currentCtrlType !== FieldCtrlType.CHIPS
          }"
        >
          <ng-template [ngIf]="this.editOptionCtrl.value === BulkEditOption.FIND_AND_CLEAR">
            <ng-template [ngIf]="currentCtrlType === FieldCtrlType.INPUT">
              <taku-input [formGroup]="bulkEditorForm" fieldName="searchValue" fieldCaption="Search Value">
              </taku-input>
              <taku-checkbox [formGroup]="bulkEditorForm" fieldName="matchLetterCase" fieldCaption="Match Letter Case">
              </taku-checkbox>
            </ng-template>

            <ng-template [ngIf]="getBulkFieldType(editingFieldCtrl.value) === BulkFieldType.DROPDOWN">
              <taku-dropdown
                [formGroup]="bulkEditorForm"
                [options]="fieldLookupOptions | async"
                fieldName="searchValue"
                fieldCaption="Search Value"
              ></taku-dropdown>
              <taku-checkbox [formGroup]="bulkEditorForm" fieldName="matchLetterCase" fieldCaption="Match Letter Case">
              </taku-checkbox>
            </ng-template>
          </ng-template>

          <ng-template [ngIf]="BulkEditOption.COPY_FROM === editOptionCtrl.value">
            <taku-dropdown
              [formGroup]="bulkEditorForm"
              [options]="enum_copyingFields"
              fieldName="copyField"
              fieldCaption="Choose Field"
            ></taku-dropdown>
          </ng-template>

          <ng-template [ngIf]="BulkEditOption.CHANGE_LETTER_CASE === editOptionCtrl.value">
            <taku-dropdown
              [formGroup]="bulkEditorForm"
              [options]="enum_letterCase"
              fieldName="letterCaseChange"
              fieldCaption="Choose Letter Case"
            ></taku-dropdown>
          </ng-template>

          <ng-template
            [ngIf]="
              !_includes(
                [BulkEditOption.FIND_AND_CLEAR, BulkEditOption.COPY_FROM, BulkEditOption.CHANGE_LETTER_CASE],
                editOptionCtrl.value
              )
            "
          >
            <ng-template [ngIf]="currentCtrlType === FieldCtrlType.INPUT">
              <taku-input [formGroup]="bulkEditorForm" fieldName="newValue" fieldCaption="New Value"></taku-input>
            </ng-template>

            <ng-template [ngIf]="currentCtrlType === FieldCtrlType.DROPDOWN">
              <taku-dropdown
                [formGroup]="bulkEditorForm"
                [options]="fieldLookupOptions | async"
                fieldName="newValue"
                fieldCaption="New Value"
              ></taku-dropdown>
            </ng-template>

            <ng-template [ngIf]="currentCtrlType === FieldCtrlType.MULTISELECT">
              <taku-multiselect
                [formGroup]="bulkEditorForm"
                [options]="fieldLookupOptions | async"
                fieldName="newValue"
                fieldCaption="New Value"
              ></taku-multiselect>
            </ng-template>

            <ng-template [ngIf]="currentCtrlType === FieldCtrlType.ENUMMULTISELECT">
              <taku-multiselect
                [formGroup]="bulkEditorForm"
                [options]="fieldMultiselectOptions | async"
                fieldName="newValue"
                fieldCaption="New Value"
                [dataKey]="null"
              ></taku-multiselect>
            </ng-template>

            <ng-template [ngIf]="currentCtrlType === FieldCtrlType.CHIPS">
              <taku-chips
                [formGroup]="bulkEditorForm"
                fieldName="chips"
                [fieldCaption]="editingFieldCtrl.value.header"
                fieldPlaceholder="Press Enter to Add Chips"
              >
              </taku-chips>
            </ng-template>
          </ng-template>
        </div>
      </ng-template>

      <ng-template [ngIf]="_includes(groupedeEditOptions.get(2), editOptionCtrl.value)">
        <ng-template
          [ngIf]="
            _includes(
              [
                BulkEditOption.REPLACE_IF_CONTAINS,
                BulkEditOption.REPLACE_ALL_IF_CONTAINS,
                BulkEditOption.FIND_AND_REPLACE
              ],
              editOptionCtrl.value
            )
          "
        >
          <ng-template [ngIf]="BulkFieldType.TEXT === getBulkFieldType(editingFieldCtrl.value)">
            <div class="col-4 lg:col-2">
              <taku-input [formGroup]="bulkEditorForm" fieldName="searchValue" fieldCaption="Search Value">
              </taku-input>
            </div>
            <div class="col-4 lg:col-2">
              <taku-checkbox [formGroup]="bulkEditorForm" fieldName="matchLetterCase" fieldCaption="Match Letter Case">
              </taku-checkbox>
            </div>
            <div class="col-4 lg:col-2">
              <taku-input [formGroup]="bulkEditorForm" fieldName="newValue" fieldCaption="New Value"></taku-input>
            </div>
          </ng-template>

          <ng-template [ngIf]="BulkFieldType.DROPDOWN === getBulkFieldType(editingFieldCtrl.value)">
            <div class="col-6 lg:col-3">
              <taku-dropdown
                [formGroup]="bulkEditorForm"
                [options]="fieldLookupOptions | async"
                fieldName="searchValue"
                fieldCaption="Old Value"
              ></taku-dropdown>
            </div>
            <div class="col-6 lg:col-3">
              <taku-dropdown
                [formGroup]="bulkEditorForm"
                [options]="fieldLookupOptions | async"
                fieldName="newValue"
                fieldCaption="New Value"
              ></taku-dropdown>
            </div>
          </ng-template>
        </ng-template>

        <ng-template [ngIf]="showNumericControls">
          <div [formGroup]="bulkEditorForm" class="col-fixed selectBtn-adjustmentType">
            <p-selectButton [options]="enum_adjustmentType" formControlName="qtyAdjustmentType"></p-selectButton>
          </div>

          <div class="col-6 lg:col-3">
            <taku-input [formGroup]="bulkEditorForm" fieldName="qtyAdjustmentAmount" fieldCaption="Adjust By">
            </taku-input>
          </div>
        </ng-template>
      </ng-template>
    </div>
  </div>

  <div class="right-side">
    <!-- <div class="clearOptions-wrapper" [ngClass]="{'col-offset-6':!showNumericControls}"> -->
    <div class="clearOptions-wrapper">
      <a href="javascript://" (click)="onResetForm()" class="text-link">CLEAR OPTIONS</a>
    </div>

    <ng-template [ngIf]="showNumericControls">
      <div class="col-8 lg:col-4">
        <!-- Round To -->
        <taku-input [formGroup]="bulkEditorForm" fieldName="qtyRoundTo" fieldCaption="Round To"></taku-input>
      </div>

      <div #opWrapper class="rounding-wrapper col-4 lg:col-2">
        <a href="javascript://" class="roundingTooltip pi pi-info-circle" (click)="opRounding.toggle($event)"></a>

        <p-overlayPanel #opRounding appendTo="body">
          <div class="panel-roundingTips">
            <h5>Examples</h5>
            <ul>
              <li>0.05 : Rounds to 5 centecimals</li>
              <li>10 : Rounds to tens</li>
            </ul>
          </div>
        </p-overlayPanel>
      </div>
    </ng-template>

    <div class="applyBtn-wrapper">
      <div [formGroup]="bulkEditorForm" class="rowSelection-options">
        <div class="rowSelection-info"> ({{ _selectedRows.length }} of {{ _totalVisibleRows }} rows selected) </div>
        <p-checkbox
          formControlName="allRowsSelection"
          (onChange)="selectAllRowsChanged($event)"
          binary="true"
          label="Select all rows below"
        ></p-checkbox>
      </div>
      <button
        pButton
        label="PREVIEW"
        icon="pi pi-check"
        (click)="applyBulkChanges()"
        [disabled]="isApplyBtnDisabled"
      ></button>
    </div>
  </div>
</div>
