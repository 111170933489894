<taku-form-header
  [formGroup]="_myForm"
  (backClicked)="goBack()"
  (saveClicked)="onSave()"
  (revertClicked)="onRevert()"
  [toolbarTitle]="pageTitle"
  iconStyleClass="material-icons"
  iconName="attach_money"
  [showSecondaryBtns]="false"
></taku-form-header>

<div [formGroup]="_myForm" class="grid pt-2">
  <p-card class="md:col-4 col-12">
    <h3>General Settings</h3>

    <div class="col-12 general-setting">
      <taku-dropdown
        [formGroup]="_myForm"
        [options]="enum_cashout_calculation_method"
        fieldName="tillCashoutClosingType"
        fieldCaption="Cashout Type"
      ></taku-dropdown>

      <taku-dropdown
        class="mt-2"
        [formGroup]="_myForm"
        [options]="enum_cash_calculation_type"
        fieldName="cashCalculationType"
        fieldCaption="Cash Calculation"
      ></taku-dropdown>
    </div>

    <!-- <div class="col-12 general-setting">
      <taku-dropdown [formGroup]="_myForm" [options]="enum_station_calculation_method" fieldName="tillStationClosingType"
        fieldCaption="Calculate Station Closing"></taku-dropdown>
    </div> -->
    <div class="grid col-12 general-setting">
      <div class="col-12">
        <p-radioButton
          name="presetOpeningFloatOption"
          [value]="PresetOpeningFloatOption.no_preset"
          label="No preset Opening Float"
          formControlName="presetOpeningFloatOption"
        ></p-radioButton>
      </div>
      <div class="col-11">
        <p-radioButton
          name="presetOpeningFloatOption"
          [value]="PresetOpeningFloatOption.next_float"
          label="Set Next Float while closing Cashout"
          formControlName="presetOpeningFloatOption"
        ></p-radioButton>
      </div>
      <div class="col-1 p-0">
        <button
          pButton
          pRipple
          class="p-button-rounded p-button-text p-button-icon-only"
          icon="pi pi-info-circle"
          (click)="nextFloatInfoPanel.toggle($event)"
        >
          <p-overlayPanel #nextFloatInfoPanel [showCloseIcon]="false" appendTo="body">
            <div class="tolltip-panel">
              This option allows you to remove cash during closing cashout and automatically apply it to your next
              opening float.
              <br />
              Note: Any cash discrepancy during the next opening will require the user to add a note.
            </div>
          </p-overlayPanel>
        </button>
      </div>
      <div class="col-11">
        <p-radioButton
          name="presetOpeningFloatOption"
          [value]="PresetOpeningFloatOption.default_values"
          label="Preload Opening Float with Default Values"
          formControlName="presetOpeningFloatOption"
        ></p-radioButton>
      </div>
      <div class="col-1 p-0">
        <button
          pButton
          pRipple
          class="p-button-rounded p-button-text p-button-icon-only"
          icon="pi pi-info-circle"
          (click)="defaultValuesInfoPanel.toggle($event)"
        >
          <p-overlayPanel #defaultValuesInfoPanel [showCloseIcon]="false" appendTo="body">
            <div class="tolltip-panel">
              Always preload the denominations in your opening float with the default values in cashout settings.
            </div>
          </p-overlayPanel>
        </button>
      </div>
    </div>
    <!-- <div class="col-12 general-setting">
      <taku-toggle [formGroup]="_myForm" fieldName="isIncludeFloatInPaymentBreakdown" fieldCaption="Include Float in
          Payment Breakdown"
        enabledStateLabel="ON" disabledStateLabel="OFF"></taku-toggle>
    </div>

    <div class="col-12 general-setting">
      <taku-toggle [formGroup]="_myForm" fieldName="isCashTenderType" fieldCaption="Enable Cash as
          Tender Type"
        enabledStateLabel="ON" disabledStateLabel="OFF"></taku-toggle>
    </div> -->

    <h3>Cashout Slip Settings</h3>
    <div class="grid col-12">
      <div class="col-12 general-setting">
        <taku-toggle
          [formGroup]="_myForm"
          fieldName="printsignitureLine"
          fieldCaption="Add Signature Line to cashout slip"
          enabledStateLabel="ON"
          disabledStateLabel="OFF"
        >
        </taku-toggle>
      </div>
      <div class="col-11 general-setting">
        <taku-toggle
          [formGroup]="_myForm"
          fieldName="showSystemValue"
          fieldCaption="Show System cashout values"
          enabledStateLabel="ON"
          disabledStateLabel="OFF"
        ></taku-toggle>
      </div>
      <div class="col-1 general-setting p-0">
        <button
          pButton
          pRipple
          class="p-button-rounded p-button-text p-button-icon-only"
          icon="pi pi-info-circle"
          (click)="systemCashoutInfoPanel.toggle($event)"
        >
          <p-overlayPanel #systemCashoutInfoPanel [showCloseIcon]="false" appendTo="body">
            <div class="tolltip-panel">
              <h5>User Access Tips</h5>
              <ul>
                <li
                  >Roles restricted from seeing System values should have Visibility access rights disabled for Cashout
                  History</li
                >
                <li>Check role settings under User Management > Manage Roles > Access Rights</li>
              </ul>
            </div>
          </p-overlayPanel>
        </button>
      </div>
      <div class="col-12 general-setting">
        <taku-toggle
          [formGroup]="_myForm"
          fieldName="showOverUnderValue"
          fieldCaption="Show Over/Under cashout values"
          enabledStateLabel="ON"
          disabledStateLabel="OFF"
        ></taku-toggle>
      </div>
    </div>
    <h3>Cashout Report Printing</h3>
    <div class="grid col-12">
      <div class="col-12 general-setting">
        <taku-toggle
          [formGroup]="_myForm"
          fieldName="showSalesReportSummary"
          fieldCaption="Show Sales Report Summary"
          enabledStateLabel="ON"
          disabledStateLabel="OFF"
        >
        </taku-toggle>
      </div>
      <div class="col-12 general-setting">
        <taku-toggle
          [formGroup]="_myForm"
          fieldName="showSalesCategories"
          fieldCaption="Show Sales Categories"
          enabledStateLabel="ON"
          disabledStateLabel="OFF"
        ></taku-toggle>
      </div>
    </div>
  </p-card>

  <p-card
    *ngIf="_myForm.get('presetOpeningFloatOption').value === PresetOpeningFloatOption.default_values"
    class="xl:col-6 md:col-8 col-12 taku-tablestyle-controllist taku-openingfloat-table"
  >
    <div class="grid col-12 taku-listable-header">
      <div class="col">
        <h3>Default Opening Float</h3>
      </div>
      <button
        pButton
        pRipple
        type="button"
        class="p-button-raised p-button-text"
        label="Sync with zone denominations..."
        (click)="syncWithZoneDenom()"
      >
      </button>
      <button
        pButton
        pRipple
        type="button"
        class="p-button-raised p-button-text"
        label="Add New"
        icon="pi pi-plus-circle"
        iconPos="right"
        (click)="addCashoutFloat()"
      >
      </button>
    </div>

    <table class="col-12">
      <thead>
        <tr class="justify-content-end">
          <th class="col-3 col-currency">Denomination</th>
          <th class="col-1 col-timessign"></th>
          <th class="col-2 sm:col-3 col-count">Count</th>
          <th class="col-1 col-equalsign"></th>
          <th class="col-3 col-total">Total</th>
          <th class="col-1"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let storeSettingCashoutOpeningFloatsForm of _myForm.get('storeSettingCashoutOpeningFloats')['controls'];
            let i = index
          "
        >
          <taku-settings-cashout-opening-float
            class="col-11"
            [formGroup]="storeSettingCashoutOpeningFloatsForm"
            [currencyISOCode]="currencyISOCode"
          >
          </taku-settings-cashout-opening-float>
          <td class="col-1 col-remove-btn">
            <taku-action-button
              [action]="ACTION_TYPE.REMOVE"
              (clickAction)="removeCashoutFloat(i)"
            ></taku-action-button>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td class="col-8 total-label">Total Cash:</td>
          <td class="col-3 total-amount">{{ totalFloatCash() | currency : currencyISOCode : "symbol-narrow" }}</td>
          <td class="col-1"></td>
        </tr>
      </tfoot>
    </table>
  </p-card>
</div>
