/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { ValidatorFn } from "@angular/forms";
import { SaleDocType } from "src/app/core/document/doc/doc";
import { Col, DataType, FilterType } from "../../../../form-list/form-list/form-list";
import { FormListModel } from "../../../../form-list/form-list/form-list-model.interface";
import { DBService } from "../../../../shared/services/db.service";

@Injectable({
  providedIn: "root",
})
export class SalesReportColsFactoryService {
  constructor(public dbService: DBService) {}

  build(dynamicFields: string[]) {
    return new SalesReportCols(this, dynamicFields);
  }
}

export class SalesReportCols implements FormListModel {
  constructor(private factoryService: SalesReportColsFactoryService, private dynamicFields: string[]) {}

  enumDocumentTypes = this.factoryService.dbService.enumSelectOptions(SaleDocType);

  _disableCloning = true;
  getValidationRules(): { [key: string]: {} | ValidatorFn[] } {
    return {};
  }

  getFormListColumns(): Col[] {
    const cols: Col[] = [
      {
        field: "docType",
        header: "Document Type",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enumDocumentTypes,
        filterType: FilterType.enum,
        colWidth: 120,
      },
      {
        field: "docClosingDate",
        header: "Date",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
      },
      {
        field: "docsCount",
        header: "# of Docs",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
        footer: false,
      },
      {
        field: "totalAmountCalculated",
        header: "Total Amount",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
        footer: true,
      },
    ];

    this.dynamicFields.forEach((field) =>
      cols.push({
        field,
        header: field,
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
      })
    );

    return cols;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
