/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { ValidatorFn, FormControl, AbstractControl, FormGroup } from "@angular/forms";
import { DiscountType } from "../../core/document/sale-document/sale-doc-line/sale-doc-line";

export class InvoiceValidators {
  static discountAmount(discountType: AbstractControl, unitPrice: AbstractControl, priceCaption: string): ValidatorFn {
    return (amountCtrl: AbstractControl): { [key: string]: any } | null => {
      const discountValue = parseFloat(amountCtrl.value),
        errorType = "invoice.discountAmount";
      if (amountCtrl.pristine || isNaN(discountValue)) return null;

      const priceValue = parseFloat(unitPrice.value);
      const error = {};
      switch (discountType.value) {
        case DiscountType.Fixed:
          if (discountValue < 0) error[errorType] = "Cannot be less than 0";
          else if (discountValue > priceValue) error[errorType] = "Cannot exceed " + priceCaption;
          break;

        case DiscountType.Percentage:
          if (discountValue < 0) error[errorType] = "Cannot be less than 0";
          else if (discountValue > 100) error[errorType] = "Cannot exceed 100%";
          break;

        case DiscountType.Final_Price:
          if (discountValue < 0) error[errorType] = "Cannot be less than 0";
          else if (discountValue > priceValue) error[errorType] = "Cannot exceed " + priceCaption;
          break;

        default:
          error[errorType] = "Select a discount type first";
      }

      // if object is empty return null
      return Object.getOwnPropertyNames(error).length ? error : null;
    };
  }
  static returnMaxQty(maxReturnQty: number): ValidatorFn {
    return (qtyCtrl: AbstractControl): { [key: string]: any } | null => {
      const errorType = "invoice.returnMaxQty";
      if (qtyCtrl.pristine) return null;

      const error = {};
      if (Math.abs(qtyCtrl.value) > Math.abs(maxReturnQty)) {
        error[errorType] = "Cannot return more than " + Math.abs(maxReturnQty);
      }
      return Object.getOwnPropertyNames(error).length ? error : null;
    };
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
