import { Component, Input } from "@angular/core";
import { CompoundAccount } from "../../document/sale-document/crm-sale/crm-sale.component";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { AccountType } from "../account/account";

@Component({
  selector: "taku-account-dialog-wrapper",
  templateUrl: "./account-dialog-wrapper.component.html",
  styleUrls: ["./account-dialog-wrapper.component.scss"],
})
export class AccountDialogWrapperComponent {
  @Input() account: CompoundAccount;
  accountType: AccountType;

  constructor(private dialogConfig: DynamicDialogConfig, private dialogRef: DynamicDialogRef) {
    this.account = dialogConfig.data.account;
    this.accountType = dialogConfig.data.account.account.accountType;
  }

  closeDialogPressed() {
    this.dialogRef.close();
  }
}
