<div class="flex align-items-center gap-4">
  <taku-input
    [formGroup]="form"
    fieldName="uniqueCode"
    fieldCaption="Unique Code"
    style="width: auto"
    autocomplete="off"
  ></taku-input>
  <button
    pButton
    type="button"
    label="Check Balance"
    class="w-11rem flex-none mt-1"
    (click)="checkVoucherBalance()"
    [loading]="fetchingVoucher"
  ></button>
</div>
<h4>
  <strong>Available Balance: </strong>
  <span *ngIf="voucher" class="balance">{{ voucher.value | currency : currencyISOCode : "symbol-narrow" }}</span>
  <div *ngIf="error" class="error">{{ error }}</div>
</h4>
