/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

export class StoreSocialProfile {
  id: number;
  socialProfileType: StoreSocialProfileType;
  socialProfile: string;

  constructor() {
    this.id = 0;
    this.socialProfileType = null;
    this.socialProfile = "";
  }

  get siteURL(): string {
    switch (this.socialProfileType) {
      case StoreSocialProfileType.facebook:
        return `facebook.com`;

      case StoreSocialProfileType.instagram:
        return `instagram.com`;

      case StoreSocialProfileType.linkedIn:
        return `linkedin.com`;

      case StoreSocialProfileType.pinterest:
        return `pinterest.com`;

      case StoreSocialProfileType.twitter:
        return `twitter.com`;
    }

    return null;
  }

  get socialIconURL(): string {
    const prefixPath = "/assets/components/receipt-preview/images";

    switch (this.socialProfileType) {
      case StoreSocialProfileType.facebook:
        return `${prefixPath}/facebook-logo.png`;

      case StoreSocialProfileType.instagram:
        return `${prefixPath}/instagram-logo.png`;

      case StoreSocialProfileType.linkedIn:
        return `${prefixPath}/linkedin-logo.png`;

      case StoreSocialProfileType.pinterest:
        return `${prefixPath}/pinterest-logo.png`;

      case StoreSocialProfileType.twitter:
        return `${prefixPath}/twitter-logo.png`;
    }

    return null;
  }
}

export enum StoreSocialProfileType {
  facebook = "Facebook",
  instagram = "Instagram",
  twitter = "Twitter",
  pinterest = "Pinterest",
  linkedIn = "LinkedIn",
  other = "Other",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
