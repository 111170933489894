/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { GMBAccount } from "../../google-my-business/gmb-accounts-list/GMBGoogleAccount";
import { GoogleAccount } from "../../google-my-business/gmb-accounts-list/GoogleAccount";
import { ContentAPI_Account } from "../gmc-api-data-definitions/ContentAPI_Account";

export class GMCGoogleAccount {
  id: number;
  isActive: boolean;
  googleAccount: GoogleAccount;
  gmcAccounts: GMCAccount[];

  constructor() {
    this.id = 0;
    this.isActive = false;
    this.googleAccount = null;
    this.gmcAccounts = [];
  }
}

export class GMCAccount {
  id: number;
  merchantId: string;
  gmcGoogleAccountId: number;
  gmbAccountId: number;
  gmbAccount: GMBAccount;
  // Added by BG
  linkedGMBAccount: ContentAPI_Account.LinkedGMBAccount;
  accessibleGMBAccounts: AccessibleGMBAccount[];
  liaSettings: LIACountrySetting;
  hasSentGMBLinkingRequest: boolean;
  isFetchingStatus: boolean;
  countryIsoCode: string;

  constructor() {
    this.id = 0;
    this.merchantId = null;
    this.gmcGoogleAccountId = null;
    this.gmbAccountId = null;
    // Added by BG
    this.linkedGMBAccount = null;
    this.accessibleGMBAccounts = null;
    this.liaSettings = null;
    this.hasSentGMBLinkingRequest = false;
    this.isFetchingStatus = false;
    this.countryIsoCode = null;
  }
}

export type AccessibleGMBAccount = {
  type: string;
  email: string;
  name: string;
  listingCount: number;
};

export type LIACountrySetting = {
  country: string;
  inventory: LIAInventoryContact;
  about: {
    status: string;
    url: string;
  };
  hostedLocalStorefrontActive: boolean;
  storePickupActive: boolean;
  posDataProvider: {
    posDataProviderId: number;
    posExternalAccountId: string;
  };
};

export type LIAInventoryContact = {
  status: string;
  inventoryVerificationContactStatus?: string;
  inventoryVerificationContactName?: string;
  inventoryVerificationContactEmail?: string;
};

export enum GMBConnectionStatus {
  NOT_AVAILABLE,
  UNLINKED,
  PENDING_UNVERIFIED,
  PENDING_VERIFIED,
  LINKED,
  LOADING,
}

export enum GMCInventoryContactStatus {
  NOT_AVAILABLE,
  NOT_SET,
  LOADING,
  PENDING = "pending",
  ACTIVE = "active",
  INACTIVE = "inactive",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
