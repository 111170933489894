/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { SaleInvoiceDocBaseComponent } from "../sale-invoice-doc-base/sale-invoice-doc-base.component";
import { InvoiceKeyboardActionsService } from "../sale-invoice-doc-base/invoice-keyboard-actions.service";
import { Component } from "@angular/core";
import { SideDialogNameDesktop, InvoiceTabsIndexes, SalesSideDialogName } from "../../types/invoice-enums.type";
import { OverlayModalName } from "../generic-sale-doc.component";
import { map } from "rxjs/operators";
import { InvoiceSettings } from "src/app/app-routing/salesscreen-settings-resolver.service";
import { SaleScreenDefaultTab } from "src/app/core/settings/store-settings/sales-doc-settings/sales-doc-settings";
import { SelectItem } from "primeng/api";
import { SaleDocSource } from "../sale-doc/sale-doc";
import { SaleDocType } from "../../doc/doc";

@Component({
  selector: "taku-sale-invoice-doc-desktop",
  templateUrl: "./sale-invoice-doc-desktop.component.html",
  styleUrls: ["./sale-invoice-doc-desktop.component.scss"],
  providers: [InvoiceKeyboardActionsService],
})
export class SaleInvoiceDocDesktopComponent extends SaleInvoiceDocBaseComponent {
  SideDialogNameDesktop = SideDialogNameDesktop;
  enum_saledoc_sources: SelectItem[];

  public _invoiceSettings: InvoiceSettings;

  restoreActiveOverlay(
    activeOverlay: OverlayModalName,
    activeOverlayExtra: any,
    activeOverlayParams: any,
    rightTabIndex: number
  ) {
    // If overlays also exist as a tab, just active the respective tab
    if (Object.values(InvoiceTabsIndexes).includes(activeOverlay))
      this.activeTabIndex = InvoiceTabsIndexes[activeOverlay];
    else {
      this.activeTabIndex = rightTabIndex;

      if ((Object.values(SalesSideDialogName) as string[]).includes(activeOverlay))
        this.openRightDialog(activeOverlay as SalesSideDialogName, activeOverlayExtra);
      else this.docComponent.openFullSizeDialog(activeOverlay, activeOverlayExtra, activeOverlayParams);
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.enum_saledoc_sources = this.dbService.enumSelectOptions(SaleDocSource);

    // Get settings from route
    this.subsList.push(
      this._route.data.pipe(map((data) => data["settings"])).subscribe((settings) => {
        this._invoiceSettings = settings;
        this.showDefaultTab();
      })
    );
  }

  getDocTypeLabel() {
    if (this.docComponent.selectedDocType == SaleDocType.sales_invoice) return "SALES";
    else return "PRE-ORDERS";
  }

  showDefaultTab() {
    switch (this._invoiceSettings.salesDocSettings.saleScreenDefaultTab) {
      case SaleScreenDefaultTab.Revisit:
        this.activeTabIndex = 0;
        break;
      case SaleScreenDefaultTab.BillTo:
        this.activeTabIndex = 3;
        break;
      case SaleScreenDefaultTab.Lists:
        this.activeTabIndex = 4;
        this._saleDocListComponent?.showGalleryTab();
        break;
      default:
        this.activeTabIndex = 0;
        break;
    }
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
