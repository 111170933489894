<!-- <div class="grid">
  <button pButton type="button" (click)="goDashboard()" label="DASHBOARD" icon="_">
    <i class="material-icons">dashboard</i>
  </button>
</div>

<div class="exception-body pagenotfound-body">
  <div class="exception-panel">
    <img src="../../../assets/layout/images/exception/icon-404.png" />

    <button type="button" (click)="goDashboard()" class="p-button p-component   error-page-btn">
      <span class="p-button-text ui-c">DASHBOARD</span>
    </button>
  </div>

  <div class="exception-band">
    <div class="exception-content">
      <h1>PAGE NOT FOUND</h1>
      <p>The page you requested cannot be found</p>
      <img src="../../../assets/layout/images/logo.png" />
    </div>
  </div>
</div> -->
<div class="page-not-found-container">
  <div class="marvin-and-mia-wrapper">
    <img src="../../../assets/layout/images/Marvin_and_Mia.png" />
  </div>
  <div class="squirrel-wrapper">
    <img src="../../../assets/layout/images/Squirrel.png" />
  </div>
  <div class="page-not-found-text">
    <h1>Oops!</h1>
    <h3>We couldn't find the page<br />you're looking for.</h3>
    <button
      pButton
      type="button"
      label="Go Back"
      class="p-button-raised p-button-warning"
      (click)="backClick()"
    ></button>
  </div>
</div>
