/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { DBService } from "src/app/shared/services/db.service";
import { Injectable } from "@angular/core";
import { SelectItem } from "primeng/api";
import { FrequencyName, UnitOfTimeMeasure } from "./inventory-usage-detail";

@Injectable({
  providedIn: "root",
})
export class InventoryUsageDetailService extends DBService {
  get_enum_frequency_name(): SelectItem[] {
    return this.enumSelectOptions(FrequencyName);
  }
  get_enum_unit_Of_Measure(): SelectItem[] {
    return this.enumSelectOptions(UnitOfTimeMeasure);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
