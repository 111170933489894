/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import * as JsBarcode from "jsbarcode";
import * as _ from "lodash";
import { BusinessDetail } from "src/app/core/settings/business-settings/business-detail/business-detail";
import { InvoiceHelpers } from "../../../../utility/InvoiceHelpers";
import { SaleDocLine } from "../../../document/sale-document/sale-doc-line/sale-doc-line";
import { SaleDocTender } from "../../../document/sale-document/sale-doc-tender/sale-doc-tender";
import { SaleDoc } from "../../../document/sale-document/sale-doc/sale-doc";
import { Tender_Type } from "../../../settings/business-settings/tender-type/tender-type";
import { StorePoliciesSettings } from "../../../settings/store-settings/store-policies-settings/StorePoliciesSettings";
import {
  Font_Size,
  Sort_Order,
  StoreSettingTapeSalePrint,
} from "../../../settings/store-settings/tape-salereceipt-builder/store-setting-tape-sale-print";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { Store } from "src/app/core/settings/store-settings/store/store";
import { LocationService } from "src/app/shared/services/location.service";
import { DocState, SaleDocType } from "src/app/core/document/doc/doc";
import { Address } from "src/app/core/contact-accounts/address/address";
import { StoreSettingTapeReturnPrint } from "src/app/core/settings/store-settings/tape-returnreceipt-builder/store-setting-tape-return-print";
import { PrintHelpers } from "src/app/utility/PrintHelpers";

@Component({
  selector: "taku-receipt-tape-preview",
  templateUrl: "./receipt-tape-preview.component.html",
  styleUrls: ["../../../../../assets/layout/taku/receipt-tape-preview.component.css"],
})
export class ReceiptTapePreviewComponent implements OnChanges, AfterViewInit {
  @Input("saleDocData") saledoc: SaleDoc;
  @Input("builderSettings") settings: StoreSettingTapeSalePrint | StoreSettingTapeReturnPrint;
  @Input() policies: StorePoliciesSettings;
  @Input() storeInfo?: Store;
  @Input() businessDetail: BusinessDetail;
  @ViewChild("returnBarcodeImage") returnBarcodeRef: ElementRef;

  readonly STYLESHEET_PATH = "/assets/layout/taku/receipt-tape-preview.component.css";

  _cardPayments: SaleDocTender[] = [];
  _cashPayments: SaleDocTender[] = [];
  _otherPayments: SaleDocTender[] = [];

  _totalCashPayments = 0;
  _roundedBalanceBeforeCash = 0;
  Math: Math = Math;
  _fullAddressMap = "";

  _digitsFormat = "1.2-2";
  _currencyIsoCode = null;
  hideNotReceiptWatermark = true;
  isTakuPayTransaction = false;

  constructor(
    public elementRef: ElementRef,
    private appSettingsService: AppSettingsStorageService,
    public lService: LocationService
  ) {}

  ngOnInit() {
    this.onSaleDocChanged();
    this.onSettingsChanged();
    this._fullAddressMap = [
      this.saledoc.store.address.line1,
      this.saledoc.store.address.line2,
      "".concat(
        this.saledoc.store.address.city,
        ", " +
          this.lService.lookupSubdivisionName(
            this.saledoc.store.address.countryIsoCode,
            this.saledoc.store.address.subDivisionIsoCode
          ) +
          ", " +
          this.lService.lookupCountryByIsoCode(this.saledoc.store.address.countryIsoCode) +
          " , ",
        this.saledoc.store.address.postalCode
      ),
    ]
      .filter(Boolean)
      .join(" ");
    this.hideNotReceiptWatermark =
      this.saledoc.doc.state !== DocState.voided &&
      this.saledoc.doc.state !== DocState.draft &&
      this.saledoc.doc.state !== DocState.suspended;
    this.isTakuPayTransaction = PrintHelpers.isTakuPayTransaction(this.saledoc);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["saledoc"] && !changes["saledoc"].firstChange) {
      this.onSaleDocChanged();
    }

    if (changes["settings"] && !changes["settings"].firstChange) {
      this.onSettingsChanged();
    }
  }

  getLineTaxAcronyms(line: SaleDocLine) {
    return line.saleDocLineTaxes
      .filter((lineTax) => lineTax.taxRule)
      .map((lineTax) => lineTax.taxRule.taxAcronym)
      .join("");
  }

  getLineStoreTaxNumbers(): void {
    console.log(this.saledoc);
  }

  get businessLogoUrl() {
    const store = this.storeInfo || this.appSettingsService.getStore();
    // First try to use the Store's logo, if not available fallback to Business's logo
    const logoUrl = store.urlStoreLogoUrl || this.businessDetail.urlBusinessDetailBusinessLogoUrl;
    return logoUrl; // WebHelpers.buildImageUrl(logoUrl);
  }

  private onSettingsChanged() {
    this.sortLines(this.settings?.lineSortOrder);
  }

  private onSaleDocChanged() {
    this._currencyIsoCode = this.saledoc.doc.currencyIsoCode;

    this._cardPayments = this.saledoc.notReturnedTenders.filter((tender) => {
      return [Tender_Type.Credit_Card, Tender_Type.Debit_Card].includes(tender.tenderType.type);
    });

    this._cashPayments = this.saledoc.notReturnedTenders.filter((tender) => {
      return Tender_Type.Cash == tender.tenderType.type;
    });

    // contains the rest of payment tenders
    this._otherPayments = _.difference(this.saledoc.notReturnedTenders, this._cardPayments, this._cashPayments);

    const invoiceHelper = new InvoiceHelpers(this.saledoc);
    this._roundedBalanceBeforeCash = invoiceHelper.roundedBalanceBeforeCash();
    this._totalCashPayments = invoiceHelper.totalCashPayments();
  }

  ngAfterViewInit(): void {
    if (this.returnBarcodeRef && this.saledoc.doc.docNo) {
      // Build barcode using invoice number
      JsBarcode(this.returnBarcodeRef.nativeElement, this.saledoc.doc.docNo, {
        width: 2,
        margin: 0,
        // textMargin: 0,
        height: 100,
      });
    }
  }

  get isReturnDoc(): boolean {
    return this.saledoc.doc.docType == SaleDocType.sales_return;
  }

  sortLines(sortMethod: Sort_Order): any {
    let fieldAccessFn;

    switch (sortMethod) {
      case Sort_Order.Category:
        fieldAccessFn = (line: SaleDocLine) => {
          return line.docLine.inventory.description1;
        };
        break;

      case Sort_Order.Discount:
        fieldAccessFn = (line: SaleDocLine) => {
          return line.lineDiscount;
        };
        break;

      case Sort_Order.SKU:
        fieldAccessFn = (line: SaleDocLine) => {
          return line.docLine.inventory.sku;
        };
        break;

      case Sort_Order.Tax_Inventory_Category:
        fieldAccessFn = (line: SaleDocLine) => {
          return line.saleDocLineTaxes[0].taxRuleId;
        };
        break;
    }

    if (fieldAccessFn) {
      this.saledoc.saleDocLines.sort((line1: SaleDocLine, line2: SaleDocLine) => {
        const cmpField1 = fieldAccessFn(line1);
        const cmpField2 = fieldAccessFn(line2);

        if (cmpField1 == cmpField2) {
          return 0;
        } else {
          return cmpField1 < cmpField2 ? -1 : 1;
        }
      });
    }
  }

  subdivisionDisplay(subDivisionIsoCode: string): string {
    return subDivisionIsoCode ? subDivisionIsoCode.split("-").pop() : "";
  }

  fontSizeToNumber(fontSize: Font_Size): number {
    switch (fontSize) {
      case Font_Size.medium:
        return 11;

      case Font_Size.small:
        return 9;
    }
  }

  hasAddressInfo(address: Address) {
    return (
      address &&
      (address.line1 ||
        address.line2 ||
        address.city ||
        address.postalCode ||
        address.attnFullPersonName ||
        (address.addressPhone && address.addressPhone.tel) ||
        (address.addressEmail && address.addressEmail.email))
    );
  }

  // get shouldDisplayShippingAddress(): boolean {
  //   // should display shipping address only if it is NOT a store sale
  //   return this.saledoc.doc.docType !== SaleDocType.sales_invoice;
  // }

  startDateAndEndIsSame(index: number): boolean {
    return this.saledoc.store.storeHours[index].startDay === this.saledoc.store.storeHours[index].endDay;
  }

  categoriesPrettyPrint(saleLine: SaleDocLine) {
    if (!saleLine.docLine) return "";

    return saleLine.docLine.inventory.categories.map((category) => category.categoryName).join(", ");
  }

  inventoryVariantsStr(saleLine: SaleDocLine) {
    return saleLine.docLine.inventory.inventoryVariants
      ?.map(
        (_inventoryVariant) =>
          _inventoryVariant.inventoryOptionsSetting?.optionName +
          ": " +
          _inventoryVariant.inventoryOptionsSettingValue?.optionValue
      )
      .join(", ");
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
