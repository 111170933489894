<div class="grid" [formGroup]="formGroup">
  <div class="col-12 lg:col-6 md:col-12">
    <p-panel>
      <ng-template pTemplate="header">
        <h5 class="mb-0">Print Options</h5>
      </ng-template>
      <div class="col-12">
        <taku-dropdown
          [formGroup]="formGroup"
          [options]="enumLabelSizes"
          fieldName="labelSize"
          fieldCaption="Label Sizes"
        >
        </taku-dropdown>
      </div>
      <div class="col-12" style="font-weight: bold"> Number of labels </div>
      <div class="col-12">
        <p-radioButton
          name="printQty"
          [value]="enumPrintQty.baseOnOHQty"
          label="Based on OH Qty"
          formControlName="printQty"
        >
        </p-radioButton>
        <!--(Qty OH: {{qtyOH}}) -->
      </div>
      <div class="col-12">
        <p-radioButton
          name="printQty"
          [value]="enumPrintQty.perSelected"
          label="Per selected product"
          formControlName="printQty"
        >
        </p-radioButton>
      </div>
      <div class="col-12">
        <taku-input [formGroup]="formGroup" fieldName="inputQty" (input)="onInputQtyChange($event)"> </taku-input>
      </div>
    </p-panel>
  </div>

  <div class="col-12 lg:col-6 md:col-12">
    <p-panel>
      <ng-template pTemplate="header">
        <h5 class="mb-0">Field Options</h5>
      </ng-template>
      <div class="grid">
        <div class="col-6">
          <div style="font-weight: bold" class="col"> Product Title Options </div>
          <div class="col">
            <p-radioButton
              formControlName="productTitle"
              name="productTitle"
              [value]="enumProductTitle.productTitle"
              label="Product Title"
            >
            </p-radioButton>
          </div>
          <div class="col">
            <p-radioButton
              formControlName="productTitle"
              name="productTitle"
              [value]="enumProductTitle.altProductTitle"
              label="Alt Product Title"
            >
            </p-radioButton>
          </div>
        </div>
        <div class="col-6">
          <p-fieldset class="attention-wrapper taku-listable-container taku-expandable-block" id="fieldsetBarcode">
            <p-header>
              <taku-checkbox
                [formGroup]="formGroup"
                fieldName="isShowBarcode"
                style="font-weight: bold"
                fieldCaption="Show Barcode"
                (onChange)="showBarcodeChanged($event)"
              >
              </taku-checkbox>
            </p-header>

            <div class="expansion-fields" *ngIf="formGroup.get('isShowBarcode').value">
              <div class="col">
                <p-radioButton
                  formControlName="showBarcode"
                  name="showBarcode"
                  [value]="enumShowBarcode.sku"
                  label="SKU"
                >
                </p-radioButton>
              </div>
              <div class="col">
                <p-radioButton
                  formControlName="showBarcode"
                  name="showBarcode"
                  [value]="enumShowBarcode.upc"
                  label="UPC Barcode"
                >
                </p-radioButton>
              </div>
            </div>
          </p-fieldset>

          <p-fieldset class="attention-wrapper taku-listable-container taku-expandable-block" id="fieldsetPrice">
            <p-header>
              <taku-checkbox
                [formGroup]="formGroup"
                fieldName="isShowPrice"
                fieldCaption="Show Price"
                style="font-weight: bold"
                (onChange)="showPriceChanged($event)"
              >
              </taku-checkbox>
            </p-header>

            <div class="expansion-fields" *ngIf="formGroup.get('isShowPrice').value">
              <div class="col">
                <p-radioButton
                  formControlName="showPrice"
                  name="showPrice"
                  [value]="enumShowPrice.sellPrice"
                  label="Sell Price"
                >
                </p-radioButton>
              </div>
              <div class="col">
                <p-radioButton
                  formControlName="showPrice"
                  name="showPrice"
                  [value]="enumShowPrice.altPrice"
                  label="Alt Price"
                >
                </p-radioButton>
              </div>
            </div>
          </p-fieldset>
        </div>
      </div>
    </p-panel>
  </div>

  <footer>
    <div class="footer-btns grid">
      <div class="btn-container">
        <button
          pButton
          class="btn-action"
          label="Close"
          icon="pi pi-ban"
          (click)="onCloseDialogClicked($event)"
          [disabled]="false"
        ></button>
      </div>

      <div class="btn-container">
        <button
          pButton
          class="btn-action reprint-btn squared-corners"
          [label]="'Print'"
          [disabled]="
            (formGroup.get('printQty').value == enumPrintQty.baseOnOHQty && qtyOH <= 0) ||
            (formGroup.get('printQty').value == enumPrintQty.perSelected && formGroup.get('inputQty').value <= 0)
          "
          icon="pi pi-print"
          (click)="onPrintLabelClicked($event)"
        >
        </button>
      </div>
    </div>
  </footer>
</div>

<div [hidden]="true">
  <ng-template #printPreviewHost></ng-template>
</div>
