<taku-form-header
  [displayMode]="_isDialog ? 'inline' : null"
  [formGroup]="_myForm"
  (backClicked)="goBack()"
  (saveClicked)="onSave()"
  (revertClicked)="onRevert()"
  (newClicked)="onNew()"
  (deleteClicked)="onDelete()"
  (cloneClicked)="onClone()"
  [toolbarTitle]="_toolbarTitle"
  [insideDialog]="_isDialog"
  [showSecondaryBtns]="false"
  [isSaving]="_isSaving"
>
</taku-form-header>

<form [formGroup]="_myForm" class="col-12">
  <div class="grid sale-channel-top-container sale-channel-basicinfo-container">
    <div class="col-4 xl:col-4 sm:col-12">
      <p-card class="card-header card-tableheader">
        <ng-template pTemplate="header" class="grid">
          <h2 class="col-12"
            >Basic Information
            <a href="javascript://" class="pi pi-info-circle" (click)="globalSearchInfoPanel.toggle($event)">
              <p-overlayPanel #globalSearchInfoPanel [showCloseIcon]="false" appendTo="body">
                <div class="tooltip-styling">
                  <h5>NOTE:</h5>
                  <ul>
                    <li
                      >Any changes made in the {{ _myForm.get("channelType").value }} Control Panel will always override
                      the settings here.</li
                    >
                  </ul>
                </div>
              </p-overlayPanel>
            </a>
          </h2>
        </ng-template>

        <div class="grid">
          <div class="col-12">
            <taku-input
              [formGroup]="_myForm"
              fieldName="onlineStoreUrl"
              fieldCaption="Online Store URL"
              [disabled]="true"
            >
            </taku-input>
          </div>

          <div class="col-12">
            <taku-input [formGroup]="_myForm" fieldName="onlineStoreName" fieldCaption="Online Store Name">
            </taku-input>
          </div>

          <div class="col-12">
            <taku-input [formGroup]="_myForm" fieldName="onlineStoreEmail" fieldCaption="Online Store Email">
            </taku-input>
          </div>
          <div class="col-12">
            <button
              *ngIf="!_myForm.get('isStoreFrontOpen').value"
              pButton
              pRipple
              (click)="openStoreFront()"
              type="button"
              label="Open Storefront"
              class="p-button-raised p-button-text col-12"
            ></button>
          </div>

          <div *ngIf="_myForm.get('isStoreFrontOpen').value" class="col-12 sm:col-6 btn-label-font-change">
            <button
              *ngIf="_myForm.get('isStoreFrontOpen').value"
              pButton
              pRipple
              (click)="closeStoreFront()"
              type="button"
              label="Close Storefront"
              class="p-button-raised p-button-text col-12"
            ></button>
          </div>
          <div *ngIf="_myForm.get('isStoreFrontOpen').value" class="col-12 sm:col-6 btn-label-font-change">
            <button
              pButton
              pRipple
              type="button"
              label="Launch Control Panel"
              (click)="launchControlPanel()"
              class="p-button-raised p-button-text col-12"
            ></button>
          </div>
        </div>
      </p-card>
    </div>

    <div class="col-4 xl:col-4 sm:col-12">
      <p-card class="payments card-header card-tableheader">
        <ng-template pTemplate="header" class="grid">
          <h2 class="col-12">Payments</h2>
        </ng-template>
        <div class="grid">
          <div class="col-12">
            Map the payment option configured in your {{ branding.generic.companyName }} eCommerce to your
            {{ branding.generic.companyName }} Retail Tenders
          </div>
          <div
            class="payment grid col-12"
            *ngFor="
              let _saleChannelPaymentMapping of _myForm.get('saleChannelPaymentMappings')['controls'];
              let i = index
            "
          >
            <div class="col-6 md:col-6 sm:col-6">
              <taku-input
                [formGroup]="_saleChannelPaymentMapping"
                fieldName="paymentName"
                fieldCaption="Payment Name"
                [disabled]="true"
              >
              </taku-input>
            </div>
            <div class="col-6 md:col-6 sm:col-6">
              <taku-dropdown
                [formGroup]="_saleChannelPaymentMapping"
                [options]="lookup_tender_types"
                fieldName="tenderTypeId"
                fieldCaption="Tender Name"
                appendTo="body"
              ></taku-dropdown>
            </div>
          </div>
        </div>
      </p-card>
    </div>

    <div class="col-4 taxes-card xl:col-4 sm:col-12">
      <p-card class="card-header card-tableheader">
        <ng-template pTemplate="header" class="grid">
          <h2 class="col-12"
            >Taxes
            <a href="javascript://" class="pi pi-info-circle" (click)="taxesInfoPanel.toggle($event)">
              <p-overlayPanel #taxesInfoPanel [showCloseIcon]="false" appendTo="body">
                <div class="tooltip-styling">
                  <h5>NOTE:</h5>
                  <ul>
                    <li
                      >Please note that {{ branding.generic.companyName }} eCommerce only supports positive
                      {{ branding.generic.companyName }} Retail sales tax rates at this time.</li
                    >
                  </ul>
                </div>
              </p-overlayPanel>
            </a>
          </h2>
        </ng-template>
        <div class="grid">
          <div class="col-12">
            <p> Set up the way taxes are calculated in your online store </p>
            <!-- <h3>
              Automatic
            </h3> -->
            <div class="col-12">
              <p-radioButton
                name="enableAutomaticTax"
                [value]="true"
                formControlName="enableAutomaticTax"
                label="Automatic"
              ></p-radioButton>
            </div>
            <div class="col-12">
              <p-radioButton
                name="enableAutomaticTax"
                [value]="false"
                formControlName="enableAutomaticTax"
                label="Manual"
              >
              </p-radioButton>
              <div *ngIf="!_myForm.get('enableAutomaticTax').value" class="mt-2 pl-2">
                Upload your existing {{ branding.generic.companyName }} Retail tax rates to
                {{ _myForm.get("channelType").value }}
              </div>
              <div class="col-10 sm:col-6 lg:col-4 xl:col-8 p-fluid">
                <button
                  pButton
                  pRipple
                  type="button"
                  icon="pi pi-upload"
                  [label]="'Upload' + branding.generic.companyName + 'Taxes'"
                  (click)="uploadTaxes()"
                  class=""
                  [loading]="_taxUploading"
                  [disabled]="_myForm.get('enableAutomaticTax').value"
                ></button>
              </div>
            </div>
            <!-- <div style="margin-bottom: 2em;" class="col-12   ">
              <taku-checkbox [formGroup]="_myForm" fieldName="enableAutomaticTax" fieldCaption="Automatic Taxes">
              </taku-checkbox>
            </div>
            <h3>
              Manual
            </h3>
            <p>
              Upload your existing TAKU Retail tax rates to {{_myForm.get('channelType').value}}
            </p>
            <div class="col-10 sm:col-6 lg:col-4 xl:col-8 p-fluid">
              <button pButton pRipple type="button" icon="pi pi-upload" label="Upload TAKU Taxes" (click)="uploadTaxes()"
                class="" [loading]="_taxUploading" [disabled]="_myForm.get('enableAutomaticTax').value"></button>
            </div> -->
          </div>
        </div>
      </p-card>
    </div>
  </div>

  <taku-inventory-sale-channel
    [formGroup]="_myForm"
    [channelType]="_myForm.get('channelType').value"
    [showUseChannelSetting]="false"
    (onSyncCategoriesClick)="syncCategories()"
    [isRefreshingCategories]="_isSyncingCategories"
  >
  </taku-inventory-sale-channel>
</form>
