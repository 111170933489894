/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
export class CashoutOpeningFloat {
  id: number;
  amount: number;
  count: number;

  constructor(amount?: number) {
    this.id = 0;
    this.amount = amount || 0;
    this.count = 0;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
