<div
  *ngIf="!isKioskScreen && !isLoginScreen && auth.isLoggedIn() && auth.isLoginCompleted(); else blankPageLayout"
  [ngClass]="{
    'p-input-filled': inputStyle === 'filled',
    'p-ripple-disabled': !ripple,
    'layout-menu-static': isStatic(),
    'layout-menu-overlay': isOverlay(),
    'layout-menu-horizontal': isHorizontal(),
    'layout-menu-slim': isSlim(),
    'layout-menu-active': menuActive,
    'layout-menu-mobile-active': mobileMenuActive,
    'layout-topbar-mobile-active': mobileTopbarActive,
    'layout-rightmenu-active': rightMenuActive,
    'layout-rtl': isRTL
  }"
  [class]="'layout-wrapper layout-menu-' + menuTheme + ' layout-topbar-' + topbarTheme"
>
  <app-topbar></app-topbar>

  <!-- <app-rightmenu></app-rightmenu> -->

  <div *ngIf="showSidebarMenu" class="menu-wrapper" (click)="onMenuClick($event)">
    <div class="layout-menu-container">
      <app-inline-menu
        *ngIf="inlineMenuPosition === 'top' || inlineMenuPosition === 'both'"
        key="top"
      ></app-inline-menu>
      <app-menu>
        <div class="appVersionNo">
          ver. {{ appVersion }} <br />
          s.ver. {{ serverVersion | async }}</div
        >
      </app-menu>
      <app-inline-menu *ngIf="inlineMenuPosition === 'bottom' || inlineMenuPosition === 'both'" key="bottom">
      </app-inline-menu>
    </div>
  </div>

  <div class="layout-main">
    <p-message
      *ngIf="idleService.idleState"
      severity="error"
      text="{{ idleService.idleState }}"
      styleClass="absolute left-50 mt-2 -translate-x-50 z-1"
    ></p-message>
    <div class="layout-content" id="layout-main-content" (window:resize)="onContentContainerResized($event)">
      <router-outlet (activate)="onActivate($event)"></router-outlet>
      <p-scrollTop
        *ngIf="!isKioskScreen && !isLoginScreen && auth.isLoggedIn() && auth.isLoginCompleted()"
        target="window"
        icon="pi pi-arrow-up"
        [threshold]="isNotMobile() ? '2500' : '1200'"
      ></p-scrollTop>
    </div>
  </div>

  <!-- <app-rightpanel></app-rightpanel> -->
  <!-- <app-config></app-config> -->
  <div *ngIf="mobileMenuActive" class="layout-mask modal-in"></div>
</div>

<p-toast position="top-right" [breakpoints]="{ '375px': { width: '100%', right: '0', left: '0' } }">
  <ng-template let-message pTemplate="message">
    <div class="flex" style="flex: 1">
      <i style="font-size: 2em" class="mr-2" [className]="getToastIconClass(message.severity)"></i>
      <div class="mr-2 flex flex-column" style="flex: 1">
        <h5 [innerHTML]="message.summary"></h5>
        <p [innerHTML]="message.detail"></p>
      </div>
    </div>
  </ng-template>
</p-toast>

<p-confirmDialog
  header="Confirmation"
  appendTo="body"
  icon="pi pi-question-circle"
  [style]="{ width: '300pt', maxWidth: 'calc(100vw - 18pt)' }"
  [closable]="false"
></p-confirmDialog>

<ng-template #blankPageLayout>
  <router-outlet (activate)="onActivate($event)"></router-outlet>
</ng-template>
