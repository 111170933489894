<div class="col-12 p-0">
  <p-panel styleClass="col-12">
    <ng-template pTemplate="header">
      <h2> Categories </h2>
    </ng-template>
    <div class="grid">
      <div class="col-12 lg:col-6 left-card">
        <taku-form-list
          [_model]="_inventoryCatsList.model"
          _title="Inventory Categories"
          [_detailMode]="_inventoryCatsList.detailMode"
          [_cols]="_inventoryCatsList.cols"
          [_dialogShowHeader]="false"
          _dialogStyleClass="form-list-dialog-style"
        ></taku-form-list>
      </div>

      <div class="col-12 lg:col-6 right-card">
        <taku-form-list
          [_model]="_accountsCatsList.model"
          _title="Account Categories"
          [_detailMode]="_accountsCatsList.detailMode"
          [_cols]="_accountsCatsList.cols"
          [_dialogShowHeader]="false"
          _dialogStyleClass="form-list-dialog-style"
        ></taku-form-list>
      </div>
    </div>
  </p-panel>
</div>
