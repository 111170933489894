import { ElementRef, Injectable } from "@angular/core";
import { AbstractControl } from "@angular/forms";
declare let b2bapis: any;

@Injectable({
  providedIn: "root",
})
export class TizenBarcodeService {
  constructor() {
    this.setBarcodeTimeOut();
  }

  getBarcodeVersion(): void {
    let Version = null;
    try {
      Version = b2bapis.b2bbarcode.getVersion();
    } catch (e) {
      console.log(
        `[Barcode API][getVersion] call syncFunction exception ${e.code} name: ${e.name} message: [${e.message}]`
      );
    }
    if (null !== Version) {
      console.log(`[Barcode API][getVersion] call syncFunction type: ${Version}`);
    }
  }

  setBarcodeTimeOut(): void {
    try {
      const SCANTIME = 90;
      console.log(`[Barcode API][setScanTimeOut] ${SCANTIME}`);
      const msgData = b2bapis.b2bbarcode.setScanTimeOut(SCANTIME);
      console.log(`[Barcode API][setScanTimeOut]  ${msgData}`);
    } catch (e) {
      console.log(
        `[Barcode API][setScanTimeOut] call syncFunction exception ${e.code}  ${e.errorName}  ${e.errorMessage}`
      );
    }
  }

  startBarcode(): void {
    function barCodeListner(barCodeData) {
      if (barCodeData.data != "NOT_DETECTED") {
        console.log(`[Barcode API][startScanBarCode] Barcode data is ${barCodeData.data}`);
      } else {
        console.log("[Barcode API][startScanBarCode] Scan timeout finished, please try again");
      }
    }

    try {
      console.log("[Barcode API][startScanBarCode] Calling Start - Scanning");
      const msgData = b2bapis.b2bbarcode.startScanBarcode(barCodeListner);
      console.log(`[Barcode API][startScanBarCode] ${msgData}`);
    } catch (e) {
      console.log(
        `[Barcode API][startScanBarCode] call syncFunction exception ${e.code} ${e.errorName} ${e.errorMessage}`
      );
    }
  }

  stopBarcode(): void {
    try {
      console.log("[Barcode API][stopScanBarCode] Calling Stop");
      b2bapis.b2bbarcode.stopScanBarcode();
    } catch (e) {
      console.log(`[Barcode API][stopScanBarCode] exception [${e.code}] name: ${e.name} message: ${e.message}`);
    }
  }

  startContinuousBarcodeScanning(_control: AbstractControl, _el: ElementRef): void {
    this.setBarcodeTimeOut();
    function barCodeListner(barCodeData) {
      if (barCodeData.data != "NOT_DETECTED") {
        _control.setValue(barCodeData.data);
        _el.nativeElement.dispatchEvent(new KeyboardEvent("keydown", { key: "Enter" }));
        console.log(`[Barcode API][startScanBarCode] Barcode data is ${barCodeData.data}`);
      } else {
        console.log("[Barcode API][startScanBarCode] Scan timeout finished, please try again");
      }
      this.stopBarcode();
      this.startContinuousBarcodeScanning(_control, _el);
    }

    try {
      b2bapis.b2bbarcode.startScanBarcode(barCodeListner);
    } catch (e) {
      this.stopBarcode();
      this.setBarcodeTimeOut();
      try {
        b2bapis.b2bbarcode.startScanBarcode(barCodeListner);
      } catch (e) {
        console.log(
          `[Barcode API][startScanBarCode] call syncFunction exception ${e.code} ${e.errorName} ${e.errorMessage}`
        );
      }
    }
  }
}
