<div id="saleTransactions-wrapper">
  <div class="sales-line-header" [ngClass]="{ 'has-scrollbars': hasScrollbars }">
    <div class="sku">Product</div>
    <div class="qty">Qty</div>
    <div class="line-discount">{{ _isMobile ? "Disc." : "Discount" }}</div>
    <div class="sale-price-col">Price</div>
    <div class="total-price">Total</div>
  </div>
  <div class="sale-lines" #saleLines>
    <div *ngFor="let saleLine of saleDoc.saleDocLines" class="sale-line">
      <div class="line-desc">
        {{ saleLine.docLine?.inventory?.description1 }}
        <span *ngIf="saleLine.voucher?.uniqueCode">(Unique Code: {{ saleLine.voucher.uniqueCode }})</span>
      </div>
      <!-- <div class="categories"></div> -->
      <div class="line-body">
        <div class="sku">
          SKU:
          <a (click)="skuStockPanel.toggle($event); populateStockAvailabilityDialog(saleLine.docLine?.inventory?.id)">
            {{ saleLine.docLine?.inventory?.sku }}
          </a>
        </div>

        <p-overlayPanel #skuStockPanel [showCloseIcon]="true" appendTo="body" styleClass="formlist-panelOverlay">
          <h3>Stock Levels</h3>
          <p-table
            id="main-table"
            [scrollable]="true"
            [scrollHeight]="tableScrollHeight"
            tableStyle="table-layout:fixed"
            responsiveLayout="stack"
            breakpoint="640px"
            [value]="_inventoryStockFormArray['controls']"
            styleClass="p-datatable-striped"
          >
            <ng-template pTemplate="header">
              <tr>
                <th class="stockName-col">Stock</th>
                <th class="onHand-col">OH Qty</th>
                <th class="committedQty-col">Commited Qty</th>
                <th class="onOrder-col">On Order Qty</th>
                <th class="minStock-col">Min Stock Qty</th>
                <th class="maxStock-col">Max Stock Qty</th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-formGroup>
              <tr>
                <taku-inventory-stock [formGroup]="formGroup" [displayMode]="_displayMode"></taku-inventory-stock>
              </tr>
            </ng-template>
          </p-table>
        </p-overlayPanel>
        <div class="qty">{{ saleLine.qty | formatNegativeNumber }}</div>
        <div class="line-discount">
          <ng-template [ngIf]="saleLine.discountAmount > 0">
            {{ saleLine.discountAmountMoney | currency : saleDoc.doc.currencyIsoCode : "symbol-narrow" : "1.2" }}
            <span *ngIf="saleLine.discountType == DiscountType.Percentage"
              ><br />({{ saleLine.discountAmount | number : "1.2" }}%)</span
            >
          </ng-template>
        </div>
        <div class="sale-price-col">
          <div class="sale-price"
            >{{ saleLine.salePrice | currency : saleDoc.doc.currencyIsoCode : "symbol-narrow" : "1.2" }}
          </div>
          <div *ngIf="saleLine.salePrice != saleLine.unitPrice" class="original-price">
            {{ saleLine.unitPrice | currency : saleDoc.doc.currencyIsoCode : "symbol-narrow" : "1.2" }}</div
          >
        </div>
        <div class="total-price">
          <span class="total-amount">{{
            saleLine.salePrice * saleLine.qty
              | currency : saleDoc.doc.currencyIsoCode : "symbol-narrow" : "1.2"
              | formatNegativeNumber
          }}</span>
          <span class="tax-acronyms">{{ getLineTaxAcronyms(saleLine) }}</span>
        </div>
      </div>
      <div class="saleTransactions-saleLine-details">
        <span
          ><i class="material-icons">bookmark_border</i> {{ inventoryVariantsStr(saleLine.docLine?.inventory) }}</span
        >
      </div>
      <div *ngIf="saleLine.recurringOrderSetting" class="saleTransactions-saleLine-details">
        <span>
          <i class="material-icons">history</i> {{ saleLine.recurringOrderSetting.recurringOrderName }}
          <span class="pipe-sep">|</span> <i class="material-icons">repeat</i> {{ saleLine.repeatCycle }}
          <span class="pipe-sep collapsePoint">|</span> <i class="material-icons">local_shipping</i>
          {{ saleDoc.deliveryMethod }}
          <ng-container *ngIf="saleDoc.doc.docNo">
            <span class="pipe-sep">|</span>
            <i class="material-icons docNumberIcon">event_repeat</i> Doc# {{ saleDoc.doc.docNo }}
          </ng-container>
        </span>
      </div>
      <div *ngIf="saleLine.docLine?.note" class="line-notes">Note: {{ saleLine.docLine?.note }}</div>
    </div>
  </div>

  <div class="summary-wrapper">
    <div *ngIf="saleDoc.doc.comment" class="invoice-notes">
      <h3>Invoice Notes</h3>
      <p>
        {{ saleDoc.doc.comment }}
      </p>
    </div>
    <div class="summary">
      <table cellspacing="0">
        <tr>
          <td class="label-col">Sub-total:</td>
          <td class="amount-col">
            {{
              saleDoc.subTotal | currency : saleDoc.doc.currencyIsoCode : "symbol-narrow" : "1.2" | formatNegativeNumber
            }}
          </td>
        </tr>

        <tr>
          <td class="label-col">- Discount:</td>
          <td class="amount-col">
            {{
              -1 * saleDoc.discountAmountMoney
                | currency : saleDoc.doc.currencyIsoCode : "symbol-narrow" : "1.2"
                | formatNegativeNumber
            }}
          </td>
        </tr>

        <tr>
          <!-- <td class="label-col" ng-style="{'background-color': saleDoc.groupedTaxLines.length !==0 ?  'red': 'white'}"> -->
          <td class="label-col">
            <a
              *ngIf="isTaxConflict()"
              href="javascript://"
              (click)="taxesPanel.toggle($event)"
              [style.color]="getTaxFontColor()"
              class="pi pi-info-circle"
            >
            </a>
            <a href="javascript://" (click)="taxesPanel.toggle($event)" style="float: right">
              <ins [style.color]="getTaxFontColor()">+ Taxes:</ins>
            </a>
            <p-overlayPanel #taxesPanel [showCloseIcon]="false" appendTo="body">
              <div class="col-12">
                <table *ngIf="staticGroupedTaxLines.length">
                  <ng-template ngFor [ngForOf]="staticGroupedTaxLines" let-tax>
                    <tr class="tax-info">
                      <td class="tax-desc">
                        <span *ngIf="tax?.taxes[0]?.saleChannelTax">
                          {{ tax?.taxes[0]?.saleChannelTax.name }}({{ tax?.taxes[0]?.saleChannelTax.value }}%)
                        </span>
                        <span>{{ tax?.saleTaxRule?.tax?.shortDesc }}</span>
                        <!-- <span>{{tax?.saleTaxRule?.ruleName ? tax?.saleTaxRule?.ruleName: tax?.saleTaxRule?.tax?.shortDesc}}</span> -->
                      </td>
                      <td class="tax-add">
                        <span *ngIf="tax?.taxes[0]?.saleChannelTax">
                          <button
                            pButton
                            class="btn-action p-button-primary"
                            label="Add + Use"
                            (click)="addTax(tax)"
                          ></button>
                        </span>
                        <span *ngIf="!tax?.taxes[0]?.saleChannelTax"
                          >({{
                            tax?.saleTaxRule?.ruleName ? tax?.saleTaxRule?.ruleName : tax?.saleTaxRule?.tax?.shortDesc
                          }}
                          {{ tax?.saleTaxRule?.taxRate | number }}%)</span
                        >
                      </td>
                      <td class="tax-amount">
                        <span>{{
                          tax?.total
                            | currency : saleDoc.doc.currencyIsoCode : "symbol-narrow" : "1.2"
                            | formatNegativeNumber
                        }}</span>
                      </td>
                    </tr>
                    <tr class="separator">
                      <td colspan="3">
                        <hr />
                      </td>
                    </tr>
                  </ng-template>
                </table>
                <div *ngIf="!saleDoc?.groupedTaxLines?.length" class="no-taxes"
                  >There are no taxes for this sale yet.
                </div>
              </div>
            </p-overlayPanel>
          </td>
          <td class="amount-col">
            {{
              saleDoc.totalTax | currency : saleDoc.doc.currencyIsoCode : "symbol-narrow" : "1.2" | formatNegativeNumber
            }}
          </td>
        </tr>

        <tr>
          <td class="label-col">+ Shipping:</td>
          <td class="amount-col">
            {{
              saleDoc.shipperChargeAmount
                | currency : saleDoc.doc.currencyIsoCode : "symbol-narrow" : "1.2"
                | formatNegativeNumber
            }}
          </td>
        </tr>

        <tr>
          <td class="label-col">Payments:</td>
          <td class="amount-col">
            {{
              saleDoc.totalPayments
                | currency : saleDoc.doc.currencyIsoCode : "symbol-narrow" : "1.2"
                | formatNegativeNumber
            }}
          </td>
        </tr>

        <tr *ngIf="saleDoc.cashRounding != 0">
          <td class="label-col">Cash Rounding:</td>
          <td class="amount-col">
            {{
              saleDoc.cashRounding
                | currency : saleDoc.doc.currencyIsoCode : "symbol-narrow" : "1.2"
                | formatNegativeNumber
            }}
          </td>
        </tr>

        <tr class="total-line">
          <td class="label-col">TOTAL:</td>
          <td class="amount-col">
            {{
              saleDoc.grandTotal
                | currency : saleDoc.doc.currencyIsoCode : "symbol-narrow" : "1.2"
                | formatNegativeNumber
            }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>

<p-dialog
  [(visible)]="_activeDialog"
  [contentStyle]="{ maxHeight: '100%' }"
  header="Confirmation"
  styleClass="fixedsize-modal"
  [blockScroll]="true"
  [modal]="true"
>
  <p>
    {{ _dialogContent }}
  </p>
  <ng-template pTemplate="footer">
    <p-button
      icon="pi pi-check"
      (click)="_activeDialog = false"
      label="Ok"
      styleClass="p-button-text"
    ></p-button> </ng-template
></p-dialog>
