/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { CommonValidators } from "src/app/shared/validators/CommonValidators";
import { SignUpService } from "./signup.service";

@Component({
  selector: "taku-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.scss"],
})
export class SignupComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];
  loading = false;
  copyrightYear = 2022;

  _myForm: UntypedFormGroup;
  constructor(public fb: UntypedFormBuilder, private signUpService: SignUpService) {}

  ngOnInit(): void {
    this._myForm = this.fb.group({
      email: ["", { validators: [Validators.required, Validators.email] }],
      firstName: ["", { validators: [Validators.required] }],
      lastName: ["", { validators: [Validators.required] }],
      businessName: [
        "",
        {
          validators: [Validators.required, Validators.minLength(3)],
        },
      ],
      accountName: [
        "",
        {
          validators: [Validators.required, Validators.minLength(3), CommonValidators.urlAddressSegment()],
        },
      ],
      consent: [true, [Validators.requiredTrue]],
    });
    this.copyrightYear = new Date().getFullYear();
  }

  onSignUpFormSubmitted() {
    this.loading = true;
    this.subsList.push(
      this.signUpService.sendSignupRequest(this._myForm.value).subscribe(
        () => {
          this.loading = false;
          window.location.href = "https://takulabs.io/thank-you-app-sign-up";
        },
        (errRes: HttpErrorResponse) => {
          if (errRes.error?.errors) {
            Object.keys(errRes.error?.errors).forEach((key) => {
              this._myForm.controls[key]?.setErrors({ generic: errRes.error.errors[key].message });
            });
          } else {
            this._myForm.controls.accountName.setErrors({ generic: "Something went wrong." });
          }
          this.loading = false;
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
