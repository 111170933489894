/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

export class TAKUPayoutsReport {
  collected_amount: number;
  date_created: string;
  date_paid: string;
  destination: string;
  fees: number;
  ic_fees_total: number;
  payout_id: string;
  refunds: number;
  report_id: number;
  status: string;
  transfer_amount: number;
  txn_fees: number;
  volume_fees: number;

  constructor() {
    this.collected_amount = null;
    this.date_created = null;
    this.date_paid = null;
    this.destination = null;
    this.fees = null;
    this.ic_fees_total = null;
    this.payout_id = null;
    this.refunds = null;
    this.report_id = null;
    this.status = null;
    this.transfer_amount = null;
    this.txn_fees = null;
    this.volume_fees = null;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
