/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, Input, ElementRef } from "@angular/core";
import { FormGroup, AbstractControl } from "@angular/forms";
import { TakuInputCtrlBase } from "../TakuInputCtrlBase";

@Component({
  selector: "taku-input-textarea",
  templateUrl: "./taku-input-textarea.component.html",
  styleUrls: ["./taku-input-textarea.component.scss"],
})
export class TakuInputTextareaComponent extends TakuInputCtrlBase {
  @Input() rows = 4;
  @Input() autoResize = false;
  @Input() set autoFocus(value: boolean) {
    this._autofocus = value ? "autofocus" : null;
  }
  _autofocus: string = null;

  focusAndSelectInput() {
    const inputField: HTMLTextAreaElement = this.el.nativeElement.querySelector("textarea");
    inputField.focus();
    inputField.select();
  }

  clear() {
    this.ctrlField.reset();
    this.ctrlField.markAsDirty();
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
