/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { Cashout } from "../../core/cashout/cashout/cashout";
import { CommercialAccount } from "../../core/contact-accounts/commercial-account/commercial-account";
import { PersonalAccount } from "../../core/contact-accounts/personal-account/personal-account";
import {
  InventoryDoc,
  InventoryDocLine,
} from "../../core/document/inventory-document/inventory-document/inventory-document";
import { SaleDoc } from "../../core/document/sale-document/sale-doc/sale-doc";
import { Brand } from "../../core/inventory/brand/brand";
import { Category } from "../../core/inventory/category/category";
import { Inventory } from "../../core/inventory/inventory/inventory";
import { UOfMeasure } from "../../core/inventory/u-of-measure/u-of-measure";
import { BackOffice } from "../../core/settings/backOffice-settings/backOffice/backOffice";
import { FiscalYearsSettings } from "../../core/settings/business-settings/fiscal-years-settings/FiscalYearsSettings";
import { Shipper } from "../../core/settings/business-settings/shipper/shipper";
import { Stock } from "../../core/settings/business-settings/stock/stock";
import { TenderType } from "../../core/settings/business-settings/tender-type/tender-type";
import { GMBLocation } from "../../core/settings/integration-settings/google-my-business/gmb-locations-list/GMBLocation";
import { GoogleShoppingStore } from "../../core/settings/integration-settings/google-shopping/google-shopping-store/GoogleShoppingStore";
import { AccountGroup } from "../../core/settings/store-settings/account-group/account-group";
import { Station } from "../../core/settings/store-settings/station/station";
import { Store } from "../../core/settings/store-settings/store/store";
import { InventoryDocFormat } from "../../core/settings/zone-settings/inventory-doc-formats/InventoryDocFormat";
import { Zone } from "../../core/settings/zone-settings/Zone";
import { TaxAccountCategory } from "../../core/tax/tax-account-category/tax-account-category";
import { TaxInventoryCategory } from "../../core/tax/tax-inventory-category/tax-inventory-category";
import { TaxRule } from "../../core/tax/tax-rule/tax-rule";
import { Tax } from "../../core/tax/tax/tax";
import { RoleData } from "../../core/users/access-rights/role-datas/role-data";
import { RolePrivilege } from "../../core/users/access-rights/role-privileges/role-privilege";
import { RoleVisibility } from "../../core/users/access-rights/role-visibility/role-visibility";
import { Role } from "../../core/users/role/role";
import { User } from "../../core/users/user/user";
import { AppSettingsStorageService } from "../../shared/app-settings-storage.service";
import { CsvCommercialContact } from "src/app/core/import/services/CsvCommercialContact";
import { CashDenomination } from "src/app/core/settings/zone-settings/zone-denominations/cash-denomination";
import * as _ from "lodash";
import { PaymentGateway } from "src/app/core/settings/integration-settings/payment-gateways/payment-gateway";
import { PaymentTerminal } from "src/app/core/settings/integration-settings/gateway-terminals/payment-terminal";
import { SaleChannel } from "src/app/core/sale-channel/sale-channel";
import { SaleChannelOrder } from "src/app/core/sale-channel/sale-channel-orders/sale-channel-order";
import { PrinterSettings } from "src/app/core/settings/store-settings/printer/printer-settings";
import { InventoryOptionsSettingValue } from "src/app/core/inventory/inventory-options-setting-value/inventory-options-setting-value";
import { InventoryOptionsSetting } from "src/app/core/inventory/inventory-options-setting/inventory-options-setting";
import { RecurringOrderSetting } from "src/app/core/settings/zone-settings/recurring-order-settings/recurring-order-settings";
import { TakuPaymentGateway } from "src/app/core/settings/integration-settings/taku-pay/taku-payment-gateways-list/taku-payment-gateway";
import { Commitments } from "src/app/core/document/sale-document/commitments/commitments";
import { PriceLevel } from "src/app/core/price-levels/price-level";
import { PaymentTerm } from "src/app/core/purchase-order-term/payment-term/payment-term";
import { ShippingTerm } from "src/app/core/purchase-order-term/shipping-term/shipping-term";
import { Aisle } from "src/app/core/aisle/aisle";
import { Shelf } from "src/app/core/shelf/shelf";
import {
  PurchaseDoc,
  PurchaseDocLine,
} from "src/app/core/document/purchase-document/purchase-document/purchase-document";
import { Promotion } from "src/app/core/promotion/promotion";
import { BusinessDetail } from "src/app/core/settings/business-settings/business-detail/business-detail";

@Injectable({
  providedIn: "root",
})
export class FormListService {
  private defaultCurrencyIsoCode: string;

  constructor(private appSettings: AppSettingsStorageService) {
    this.appSettings.getBusinessDetails().subscribe((businessDetail: BusinessDetail) => {
      this.defaultCurrencyIsoCode = businessDetail.defaultCurrencyIsoCode;
    });
  }

  newRow(model: string, defaultValues = {}) {
    const rowObject = this._getRowObject(model);
    // Override fields by provided default values
    _.merge(rowObject, defaultValues);

    return rowObject;
  }

  _getRowObject(model: string): any {
    switch (model) {
      case "inventory":
        return new Inventory(this.appSettings.getZone().defaultCurrencyIsoCode);
      case "tenderType":
        return new TenderType();
      case "zone":
        return new Zone();
      case "fiscalYear":
        return new FiscalYearsSettings();
      case "station":
        return new Station();
      case "printer":
        return new PrinterSettings();
      case "acctGroup":
        return new AccountGroup();
      case "taxInventoryCategory":
        return new TaxInventoryCategory();
      case "taxAccountCategory":
        return new TaxAccountCategory();
      case "tax":
        return new Tax();
      case "taxRule":
        return new TaxRule();
      case "uOfMeasure":
        return new UOfMeasure();
      case "category":
        return new Category();
      case "store":
        return new Store(this.appSettings.getZone());
      case "personalAccount":
        return new PersonalAccount();
      case "commercialAccount":
        return new CommercialAccount(this.defaultCurrencyIsoCode);
      case "cashDenomination":
        return new CashDenomination();
      case "shipper":
        return new Shipper();
      case "saleDoc":
        return new SaleDoc();
      case "user":
        return new User();
      case "stock":
        return new Stock();
      case "inventoryDoc":
        return new InventoryDoc();
      case "inventoryDocLine":
        return new InventoryDocLine(null);
      case "inventoryDocFormat":
        return new InventoryDocFormat();
      case "purchaseDoc":
        return new PurchaseDoc();
      case "purchaseDocLine":
        return new PurchaseDocLine(null);
      case "backOffice":
        return new BackOffice();
      case "cashout":
        return new Cashout();
      case "googleShoppingStore":
        return new GoogleShoppingStore();
      case "brand":
        return new Brand();
      case "inventoryOptionsSetting":
        return new InventoryOptionsSetting();
      case "inventoryOptionsSettingValue":
        return new InventoryOptionsSettingValue(1);
      case "role":
        return new Role();
      case "roleData":
        return new RoleData();
      case "roleVisibility":
        return new RoleVisibility();
      case "rolePrivilege":
        return new RolePrivilege();
      case "gmbLocation":
        return new GMBLocation();
      case "commercialContact":
        return new CsvCommercialContact();
      case "paymentGateway":
        return new PaymentGateway();
      case "takuPaymentGateway":
        return new TakuPaymentGateway();
      case "paymentTerminal":
        return new PaymentTerminal();
      case "saleChannel":
        return new SaleChannel(this.appSettings.getStoreId());
      case "saleChannelOrder":
        return new SaleChannelOrder();
      case "recurringOrderSetting":
        return new RecurringOrderSetting();
      case "commitment":
        return new Commitments();
      case "priceLevel":
        return new PriceLevel(this.appSettings.getZoneId());
      case "paymentTerm":
        return new PaymentTerm();
      case "shippingTerm":
        return new ShippingTerm();
      case "aisle":
        return new Aisle();
      case "shelf":
        return new Shelf();
      case "promotion":
        return new Promotion(this.appSettings.getZoneId());
      default:
        break;
    }
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
