/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, OnDestroy } from "@angular/core";
import { AppSettingsStorageService } from "../shared/app-settings-storage.service";
import { AppMenuService } from "./app-menu.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-menu",
  templateUrl: "./app-menu.component.html",
  styleUrls: ["./app-menu.component.scss"],
})
export class AppMenuComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];
  model: any[];

  constructor(private appSettingsService: AppSettingsStorageService, private appMenuService: AppMenuService) {}

  private buildMenu(): any[] {
    const storeId = this.appSettingsService.getStoreId();
    const zoneId = this.appSettingsService.getZoneId();
    const defaultStockId = storeId ? this.appSettingsService.getStore().stockId : null;

    const backOfficeId = this.appSettingsService.getBackofficeId();

    return this.appMenuService.getAppMenu(storeId, zoneId, defaultStockId);
  }

  ngOnInit() {
    this.model = this.buildMenu().filter((row) => {
      return row;
    });
    this.subsList.push(
      this.appSettingsService.activeStoreChanged$.subscribe((store) => {
        this.model = this.buildMenu();
      })
    );
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
