/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component } from "@angular/core";
import { NestedFormComponent } from "../../../forms/nested-form/nested-form.component";
import { CommercialAccountInstantMsgType, CommercialAccountInstantMsg } from "./commercial-account-instant-msg";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ACTION_TYPE } from "src/app/shared/components/action-button/action-type.enum";

@Component({
  selector: "taku-commercial-account-instant-msg",
  templateUrl: "./commercial-account-instant-msg.component.html",
  styleUrls: ["./commercial-account-instant-msg.component.scss"],
})
export class CommercialAccountInstantMsgComponent extends NestedFormComponent {
  // Define enum variables
  // enum_commecrial_phone_type = this.dbService.enumSelectOptions(CommercialAccountPhoneType, 'Type');
  public ACTION_TYPE: typeof ACTION_TYPE = ACTION_TYPE;
  enum_commecrial_instant_msg_type = this.dbService.enumSelectOptions(CommercialAccountInstantMsgType);

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    const commercialAccountInstantMsg_Group = fb.group(new CommercialAccountInstantMsg());
    return commercialAccountInstantMsg_Group;
  }

  static setArray(fb: UntypedFormBuilder, commercialAccountInstantMsgs: CommercialAccountInstantMsg[]) {
    const formArray = fb.array([]);
    commercialAccountInstantMsgs.map((commercialAccountInstantMsg) => {
      const temp = fb.group(commercialAccountInstantMsg);
      formArray.push(temp);
    });
    return formArray;
  }

  initValidation() {
    this._validation = {};
  }

  copyInstantMsgToClipboard() {
    this.copyValueToClipboard(this.instantMsgCtrl.value);
  }

  get instantMsgCtrl() {
    return this._myForm.get("instantMsg");
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
