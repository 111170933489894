<taku-form-header
  [shortenHeight]="shortenFormHeaderHeight"
  toolbarTitle="Bill To"
  displayMode="inline"
  [showActionBtns]="false"
  [showBackBtn]="false"
>
</taku-form-header>

<div class="grid col-12">
  <div class="col-12 mb-2 heading-wrapper">
    <h3>Billing Address</h3>
  </div>
  <div class="col-10 col-offset-1 invoice-detail-action-btns">
    <button
      type="button"
      class="p-button-secondary p-button-raised"
      iconPos="right"
      pButton
      pRipple
      icon=""
      label="Copy from Account"
      (click)="copyBillingAddressFromAccount()"
    >
      <i class="material-icons md-18">person</i>
    </button>
    <button
      type="button"
      class="p-button-secondary p-button-raised"
      iconPos="right"
      pButton
      pRipple
      icon="_"
      label="Clear"
      (click)="onClearAddress(saleForm.get('billingAddress'))"
    >
      <i class="material-icons">delete_forever</i>
    </button>
  </div>
</div>

<div class="col-12">
  <taku-address #billingAddress [formGroup]="saleForm.get('billingAddress')"></taku-address>
</div>
