/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "taku-formfield-decorator",
  templateUrl: "./taku-formfield-decorator.component.html",
  styleUrls: ["./taku-formfield-decorator.component.scss"],
})
export class TakuFormfieldDecoratorComponent implements OnInit {
  @Input() fieldCaption;

  constructor() {}

  ngOnInit() {}
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
