/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, NgZone, OnInit, OnDestroy } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import * as _ from "lodash";
import { ConfirmationService, MessageService, SelectItem, SelectItemGroup } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { zip, Subscription } from "rxjs";
import { map, mergeMap, tap, finalize } from "rxjs/operators";
import { AlertMessagesService } from "../../../../../shared/services/alert-messages.service";
import { GoogleAccount } from "../../google-my-business/gmb-accounts-list/GoogleAccount";
import { BackendGMCService } from "../BackendGMCService";
import { GmcAccountDetailsComponent } from "../gmc-account-details/gmc-account-details.component";
import { GoogleContentAPIService } from "../GoogleContentAPIService";
import {
  GMBConnectionStatus,
  GMCAccount,
  GMCGoogleAccount,
  GMCInventoryContactStatus,
  LIACountrySetting,
} from "./GMCGoogleAccount";
import { GMBAccount, GMBGoogleAccount } from "../../google-my-business/gmb-accounts-list/GMBGoogleAccount";
import { GMC_TooltipMessages } from "./GMC_TooltipMessages";
import { ContentAPI_Account } from "../gmc-api-data-definitions/ContentAPI_Account";
import { GmcInventoryContactInfoComponent } from "../inventory-contact-info/inventory-contact-info.component";

enum GMCLiaStatus {
  INACTIVE,
  ACTIVE,
  NOT_AVAILABLE,
  LOADING,
}

enum GMCAccountConnectionStatus {
  READY,
  NOT_READY,
  NOT_AVAILABLE,
  LOADING,
}

@Component({
  selector: "taku-gmc-accounts",
  templateUrl: "./gmc-accounts.component.html",
  styleUrls: ["./gmc-accounts.component.scss"],
  providers: [DialogService],
})
export class GMCAccountsComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];

  _modelName = "gmcGoogleAccount";
  gmcGoogleAccounts: GMCGoogleAccount[] = [];
  _lookup_gmbAccounts: SelectItemGroup[];
  _gmbAccounts: GMBAccount[];
  _gmcForms: { [key: number]: UntypedFormGroup } = {};
  _gmbLinkRequestStatus: { [gmcId: number]: { [gmbGoogleId: number]: boolean } } = {}; // Maps GMC account to multiple GMB Accounts
  GMBConnectionStatus = GMBConnectionStatus;
  GMCInventoryContactStatus = GMCInventoryContactStatus;
  GMCLiaStatus = GMCLiaStatus;
  GMCAccountConnectionStatus = GMCAccountConnectionStatus;
  Tooltip = GMC_TooltipMessages;

  constructor(
    private zone: NgZone,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private fb: UntypedFormBuilder,
    private alertMessagesService: AlertMessagesService,
    private dialogService: DialogService,
    private dbService: BackendGMCService,
    private router: Router,
    private gmcAPIService: GoogleContentAPIService
  ) {}

  ngOnInit() {
    this.gmcAPIService.initOAuth(() => {});
    this.fetchRegisteredGMBAccounts();

    const gmcAccountsFilter = {
      isActive: { matchMode: "equals", value: true },
    };

    this.subsList.push(
      this.dbService
        .getRows(this._modelName, JSON.stringify(gmcAccountsFilter))
        .pipe(map((response: any) => response.rows))
        .subscribe((accounts) => {
          accounts.forEach((account: GMCGoogleAccount) => this.setupConnectedAccount(account));
          this.gmcGoogleAccounts = accounts;
        })
    );
  }

  private fetchRegisteredGMBAccounts() {
    const accountsFilter = {
      isActive: { matchMode: "equals", value: true },
    };
    this.subsList.push(
      this.dbService
        .getRows("gmbGoogleAccount", JSON.stringify(accountsFilter), 0, 1000)
        .pipe(map((response: any) => response.rows))
        .subscribe((accounts: GMBGoogleAccount[]) => {
          this._gmbAccounts = [];
          this._lookup_gmbAccounts = [{ label: "", items: [{ label: "", value: null }] }]; // We add an empty value to the dropdown so caption doen't overlap with values
          accounts.forEach((account) => {
            const gmbAccounts = account.gmbAccounts.map((gmbAccount) =>
              Object.assign(gmbAccount, { gmbGoogleAccount: account })
            );
            this._gmbAccounts.push(...gmbAccounts);
            this._lookup_gmbAccounts.push({
              label: account.googleAccount.email,
              value: account.googleAccount.id,
              items: gmbAccounts.map((gmbAccount) => {
                return <SelectItem>{
                  label: gmbAccount.accountName,
                  value: gmbAccount.id,
                };
              }),
            });
          });
        })
    );
  }

  isActive(account: GMCGoogleAccount) {
    return account.isActive;
  }

  ctrlGMBAccountId(gmcAccountId: number): AbstractControl {
    return this._gmcForms[gmcAccountId].get("gmbAccountId");
  }

  addGoogleAccount() {
    if (!this.isAddAccountEnabled) return false;

    const newGMBAccount = new GMCGoogleAccount();
    newGMBAccount.googleAccount = new GoogleAccount();
    newGMBAccount.gmcAccounts.push(new GMCAccount());

    this.gmcGoogleAccounts.push(newGMBAccount);
  }

  gmbStatus(gmcAccount: GMCAccount): GMBConnectionStatus {
    if (gmcAccount.isFetchingStatus) return GMBConnectionStatus.LOADING;

    const gmcAccountId = gmcAccount.id;
    if (!gmcAccount.accessibleGMBAccounts) return GMBConnectionStatus.NOT_AVAILABLE;

    const gmbAccountId = this.ctrlGMBAccountId(gmcAccountId).value;
    if (!gmbAccountId) return GMBConnectionStatus.NOT_AVAILABLE;

    const lookupGmbAccount = this._gmbAccounts.find((account) => account.id === gmbAccountId);
    if (!lookupGmbAccount) return GMBConnectionStatus.NOT_AVAILABLE;

    // Is GMB Linked locations?
    if (
      gmcAccount.linkedGMBAccount &&
      lookupGmbAccount.gmbGoogleAccount.googleAccount.email === gmcAccount.linkedGMBAccount.gmbEmail
    ) {
      switch (gmcAccount.linkedGMBAccount.status) {
        case ContentAPI_Account.LinkedGMBAccount_Status.ACTIVE:
          return GMBConnectionStatus.LINKED;

        case ContentAPI_Account.LinkedGMBAccount_Status.INACTIVE:
          return GMBConnectionStatus.UNLINKED;

        case ContentAPI_Account.LinkedGMBAccount_Status.PENDING:
          return GMBConnectionStatus.PENDING_UNVERIFIED;
      }
    }

    // Is selected GMB accessible?
    const index = gmcAccount.accessibleGMBAccounts.findIndex(
      (linkedAccount) => linkedAccount.email === lookupGmbAccount.gmbGoogleAccount.googleAccount.email
    );
    if (index >= 0) return GMBConnectionStatus.PENDING_VERIFIED;

    if (gmcAccount.hasSentGMBLinkingRequest) {
      return GMBConnectionStatus.PENDING_UNVERIFIED;
    } else {
      return GMBConnectionStatus.UNLINKED;
    }
  }

  inventoryContactStatus(gmcAccount: GMCAccount): GMCInventoryContactStatus {
    if (gmcAccount.isFetchingStatus) return GMCInventoryContactStatus.LOADING;

    if (!gmcAccount.liaSettings) return GMCInventoryContactStatus.NOT_AVAILABLE;
    if (!gmcAccount.liaSettings.inventory) return GMCInventoryContactStatus.NOT_SET;

    const inventory = gmcAccount.liaSettings.inventory;

    return (inventory.inventoryVerificationContactStatus ||
      GMCInventoryContactStatus.NOT_SET) as GMCInventoryContactStatus;
  }

  liaStatus(gmcAccount: GMCAccount): GMCLiaStatus {
    if (gmcAccount.isFetchingStatus) return GMCLiaStatus.LOADING;

    const ownedDataProviderId = BackendGMCService.DEFAULT_DATAPROVIDER_ID;
    const liaSettings = gmcAccount.liaSettings;

    if (!liaSettings) return GMCLiaStatus.NOT_AVAILABLE;

    if (!liaSettings.posDataProvider || liaSettings.posDataProvider.posDataProviderId !== ownedDataProviderId)
      return GMCLiaStatus.INACTIVE;

    // const inventoryContactStatus = this.inventoryContactStatus(gmcAccount);
    if (liaSettings.inventory && liaSettings.inventory.status === GMCInventoryContactStatus.ACTIVE)
      return GMCLiaStatus.ACTIVE;
    else return GMCLiaStatus.INACTIVE;
  }

  gmcStatus(gmcAccount: GMCAccount): GMCAccountConnectionStatus {
    if (gmcAccount.isFetchingStatus) return GMCAccountConnectionStatus.LOADING;

    const gmbStatus = this.gmbStatus(gmcAccount);
    const inventoryContactStatus = this.inventoryContactStatus(gmcAccount);

    // if (gmbStatus === GMBConnectionStatus.NOT_AVAILABLE || liaStatus === GMCLiaStatus.NOT_AVAILABLE)
    //   return GMCAccountConnectionStatus.NOT_AVAILABLE;

    if (gmbStatus === GMBConnectionStatus.LINKED && inventoryContactStatus === GMCInventoryContactStatus.ACTIVE)
      return GMCAccountConnectionStatus.READY;
    else return GMCAccountConnectionStatus.NOT_READY;
  }

  linkGMBLocations(gmcAccount: GMCAccount) {
    const gmbAccount = this._gmbAccounts.find((account) => account.id === gmcAccount.gmbAccountId);
    if (!gmbAccount) {
      console.error("Couldn't find GMB Google account with id: " + gmcAccount.gmbAccountId);
      return;
    }

    const gmbEmail = gmbAccount.gmbGoogleAccount.googleAccount.email;
    this.subsList.push(
      this.dbService
        .getMerchantAccount(gmcAccount.id)
        .pipe(
          mergeMap((merchantAccount: any) => {
            // Updte merchant account information with new googleMyBusiness link
            Object.assign(merchantAccount, {
              googleMyBusinessLink: <ContentAPI_Account.LinkedGMBAccount>{
                gmbEmail,
                status: ContentAPI_Account.LinkedGMBAccount_Status.ACTIVE,
              },
            });
            return this.dbService.updateMerchantAccount(gmcAccount.id, merchantAccount);
          })
        )
        .subscribe({
          next: (merchantAccount) => {
            gmcAccount.linkedGMBAccount = merchantAccount.googleMyBusinessLink;
            // Show successful message
            this.messageService.add(this.alertMessagesService.getMessage("gmc-gmb-link-locations-success"));
          },
          error: (error) => {
            // Show general error message
            this.messageService.add(this.alertMessagesService.getMessage("gmc-gmb-link-locations-error"));
            // Show technical errors
            this.messageService.add(this.alertMessagesService.getErrorMessage(error));
          },
        })
    );
  }

  requestGMBAccess(gmcAccount: GMCAccount) {
    const gmcAccountId = gmcAccount.id;
    const gmbAccountId = this.ctrlGMBAccountId(gmcAccountId).value;
    if (!gmbAccountId) return false;

    const gmbGoogleAccount = this._gmbAccounts.find((account) => account.id === gmbAccountId);

    this.subsList.push(
      this.dbService
        .connectGMBAccount(gmcAccountId, gmbGoogleAccount.gmbGoogleAccount.googleAccount.email)
        .pipe(
          mergeMap((setGMBResp) => {
            const gmcAccountCopy = _.cloneDeep(gmcAccount);
            gmcAccountCopy.hasSentGMBLinkingRequest = true;
            return this.dbService.editRow("gmcAccount", gmcAccountCopy);
          })
        )
        .subscribe({
          next: (response: GMCAccount) => {
            _.merge(gmcAccount, response);

            if (this.gmbStatus(gmcAccount) === GMBConnectionStatus.PENDING_VERIFIED) {
              this.messageService.add(this.alertMessagesService.getMessage("gmc-gmb-connect-request-success"));
            } else {
              this.messageService.add(this.alertMessagesService.getMessage("gmc-gmb-connect-request-pending"));
            }
          },
          error: (error) => {
            this.messageService.add(this.alertMessagesService.getMessage("gmc-gmb-connect-request-error"));
          },
        })
    );
  }

  connectAccount() {
    const googleConnectionErrMsg = this.alertMessagesService.getErrorMessage(
      null,
      "GOOGLE ACCOUNT",
      "Google connection was not completed.",
      "warning"
    );
    this.gmcAPIService.oAuthSignIn().then(
      (response: any) => {
        if (!response || !response.code) {
          this.messageService.add(googleConnectionErrMsg);
          return false;
        }
        // Run inside Angular zone so change detection works
        this.zone.run(() => {
          this.subsList.push(
            this.dbService.exchangeToken(response.code).subscribe({
              next: (_response: GMCGoogleAccount) => {
                this.setupConnectedAccount(_response);
                // try to find existing account and remove it from list
                _.remove(this.gmcGoogleAccounts, (account) => account.id === _response.id);
                // Add new Connected GMC to list
                this.gmcGoogleAccounts.unshift(_response);
              },
              error: (error) => {
                this.messageService.add(this.alertMessagesService.getErrorMessage(error));
              },
            })
          );
        });
      },
      (error) => {
        this.messageService.add(googleConnectionErrMsg);
      }
    );
  }

  setInventoryContactInfo(gmcAccount: GMCAccount) {
    const dialogRef = this.dialogService.open(GmcInventoryContactInfoComponent, {
      header: "GMC Inventory Contact Information",
      closable: false,
      data: {
        country: gmcAccount.liaSettings ? gmcAccount.liaSettings.country : "CA",
        language: "EN",
        gmcAccountId: gmcAccount.id,
        contactInfo: gmcAccount.liaSettings ? gmcAccount.liaSettings.inventory : null,
      },
    });

    this.subsList.push(
      dialogRef.onClose.subscribe((success: boolean) => {
        if (success)
          // Refresh LIA status
          this.pullGMCAccountStatus(gmcAccount);
      })
    );
  }

  private setupConnectedAccount(gmcAccountLink: GMCGoogleAccount) {
    gmcAccountLink.gmcAccounts = gmcAccountLink.gmcAccounts.map((gmcAccount) =>
      Object.assign({}, new GMCAccount(), gmcAccount)
    );
    gmcAccountLink.gmcAccounts.forEach((gmcAccount) => {
      this.setupGMCAccount(gmcAccount);
    });
  }

  private setupGMCAccount(gmcAccount: GMCAccount) {
    const gmbRequestStatus = (this._gmbLinkRequestStatus[gmcAccount.id] = {});
    // Create form using only GMB Account
    const form = this.fb.group({
      gmbAccountId: gmcAccount.gmbAccountId,
    });
    // Automatically save to server when selection is changed
    this.subsList.push(
      form.valueChanges.subscribe((formValue) => {
        const selectedGMBAccount = this._gmbAccounts.find((account) => account.id === formValue.gmbAccountId);
        if (!selectedGMBAccount) return;

        gmcAccount.gmbAccount = selectedGMBAccount;
        const gmcAccountCopy = _.cloneDeep(gmcAccount);
        // Delete extra client-added fields
        delete gmcAccountCopy.accessibleGMBAccounts;
        delete gmcAccountCopy.liaSettings;
        delete gmcAccountCopy.gmbAccount;

        // Assign new values from form
        Object.assign(gmcAccountCopy, {
          gmbAccountId: formValue.gmbAccountId,
        });
        // Make a copy of previous request status
        gmbRequestStatus[gmcAccount.gmbAccount.gmbGoogleAccountId] = gmcAccount.hasSentGMBLinkingRequest;
        gmcAccountCopy.hasSentGMBLinkingRequest = gmbRequestStatus[selectedGMBAccount.gmbGoogleAccountId] || false;

        // Update gmcAccount changes to server
        this.subsList.push(
          this.dbService.editRow("gmcAccount", gmcAccountCopy).subscribe({
            next: (response) => {
              Object.assign(gmcAccount, response);
            },
          })
        );
      })
    );
    this._gmcForms[gmcAccount.id] = form;
    this.pullGMCAccountStatus(gmcAccount);
  }

  private setLiaSettings(gmcAccount: GMCAccount, liaStatus: any) {
    let liaSettings = {};
    // TODO: Remove hardcoded country code and base it on settings
    if (liaStatus.countrySettings) {
      const countrySettings = (<LIACountrySetting[]>liaStatus.countrySettings).find(
        (setting) => setting.country === "CA"
      );
      if (countrySettings) liaSettings = countrySettings;
    }
    gmcAccount.liaSettings = <any>liaSettings;
  }

  private pullGMCAccountStatus(gmcAccount: GMCAccount) {
    gmcAccount.isFetchingStatus = true;
    this.subsList.push(
      zip(
        this.dbService.getConnectedGMBAccounts(gmcAccount.id),
        this.dbService.getLiaStatus(gmcAccount.id),
        this.dbService.getMerchantAccount(gmcAccount.id)
      )
        .pipe(finalize(() => (gmcAccount.isFetchingStatus = false)))
        .subscribe(([liaAccessibleGMBAccounts, liaStatus, merchantAccount]) => {
          if (merchantAccount.googleMyBusinessLink) gmcAccount.linkedGMBAccount = merchantAccount.googleMyBusinessLink;

          if (liaAccessibleGMBAccounts.accountId)
            gmcAccount.accessibleGMBAccounts = liaAccessibleGMBAccounts.gmbAccounts;

          if (liaStatus.accountId) {
            this.setLiaSettings(gmcAccount, liaStatus);

            const liaSettings = gmcAccount.liaSettings;
            const ownedDataProviderId = BackendGMCService.DEFAULT_DATAPROVIDER_ID;
            if (!liaSettings.posDataProvider || liaSettings.posDataProvider.posDataProviderId !== ownedDataProviderId)
              this.subsList.push(
                this.dbService
                  .setPosDataProvider(gmcAccount.id, liaSettings.country || "CA", ownedDataProviderId)
                  .pipe(
                    tap(() => {}),
                    mergeMap((response) => {
                      return this.dbService.getLiaStatus(gmcAccount.id);
                    })
                  )
                  .subscribe((liaStatus) => {
                    this.setLiaSettings(gmcAccount, liaStatus);
                  })
              );
          }
        })
    );
  }

  tryToRemoveGMCAccount(gmcAccountLink: GMCGoogleAccount, gmcAccount: GMCAccount) {
    if (gmcAccount.id === 0) {
      this.unlinkGMCAccount(gmcAccountLink, gmcAccount);
    } else {
      this.confirmationService.confirm({
        message: "By deleting this GMC Account you will lose any configurations on the account. Are you sure?",
        rejectButtonStyleClass: "p-button-link",
        accept: () => {
          this.subsList.push(
            this.dbService.deleteRow("gmcAccount", gmcAccount.id).subscribe(() => {
              this.unlinkGMCAccount(gmcAccountLink, gmcAccount);

              this.messageService.add(this.alertMessagesService.getMessage("delete-success", this._modelName));
            })
          );
        },
      });
    }
  }

  private unlinkGMCAccount(gmcAccountLink: GMCGoogleAccount, gmcAccount: GMCAccount) {
    _.remove(gmcAccountLink.gmcAccounts, {
      id: gmcAccount.id,
    });
  }

  get isAddAccountEnabled() {
    // Enabled when there is no previous empty, no connected accounts
    return (
      this.gmcGoogleAccounts.findIndex((value) => {
        return value.id === 0;
      }) < 0
    );
  }

  disconnectAccount(gmcAccountLink: GMCGoogleAccount) {
    this.gmcAPIService.disconnect();

    _.remove(this.gmcGoogleAccounts, gmcAccountLink);
    gmcAccountLink.isActive = false;
    this.subsList.push(this.dbService.editRow(this._modelName, gmcAccountLink).subscribe());
  }

  viewLocations(gmcAccountLink: GMCGoogleAccount, gmcAccount: GMCAccount) {
    let email;
    const lookupGmbAccount = this._gmbAccounts.find((account) => account.id === gmcAccount.gmbAccountId);
    if (lookupGmbAccount) email = lookupGmbAccount.gmbGoogleAccount.googleAccount.email;

    this.router.navigate(["/integrations/my-apps/gmb/locations", gmcAccount.gmbAccountId], {
      queryParams: { email },
    });
  }

  openGMCAccountDetails(gmcAccountLink: GMCGoogleAccount, gmcAccount: GMCAccount = null) {
    const dialogRef = this.dialogService.open(GmcAccountDetailsComponent, {
      header: gmcAccount ? `Edit Merchant Account (ID: ${gmcAccount.merchantId})` : "Add New Merchant Account",
      closable: false,
      data: {
        gmcAccountId: gmcAccount ? gmcAccount.id : null,
        gmcGoogleAccount: gmcAccountLink,
        gmcCountry: gmcAccount ? gmcAccount.countryIsoCode : null,
      },
      width: "85%",
      height: "85%",
      // contentStyle: {
      //   height: "80vh",
      //   overflowY: "auto"
      // },
    });

    this.subsList.push(
      dialogRef.onClose.subscribe((gmcAccount: GMCAccount) => {
        if (gmcAccount && gmcAccount.id) {
          this.setupGMCAccount(gmcAccount);
          gmcAccountLink.gmcAccounts.push(gmcAccount);
        }
      })
    );
  }

  buildGMBPageLink(gmcAccount: GMCAccount) {
    return `https://merchants.google.com/mc/linkedaccounts/gmb?a=${gmcAccount.merchantId}`;
  }

  refreshAccountsStatus(gmcAccountLink: GMCGoogleAccount) {
    // Reload GMB Account in case user has connected new ones
    this.fetchRegisteredGMBAccounts();

    gmcAccountLink.gmcAccounts.forEach((account) => {
      // Updated LIA settings and connected GMB accounts
      this.pullGMCAccountStatus(account);
    });
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
