<taku-form-header
  [shortenHeight]="shortenFormHeaderHeight"
  toolbarTitle="Lists"
  displayMode="inline"
  [showActionBtns]="false"
  [showBackBtn]="false"
>
  <div toolbar-center-nocss *ngIf="isMobile" class="shopping-cart-badge-container">
    <i class="material-icons-outlined" pBadge [value]="_countTotalItems" severity="success">shopping_cart</i>
  </div>
</taku-form-header>
<!-- height = {{ height }} hAfterOffset = {{heightAfterOffset}} width = {{ width }} wAfterOffset = {{widthAfterOffset}} -->
<p-tabView class="tabview-nopad" [(activeIndex)]="_activeTab" (onChange)="handleTabChange($event)">
  <p-tabPanel header="Gallery" [selected]="true" class="gallery-tabpanel">
    <!-- Items View -->
    <taku-inventory-data-view
      #saleDocDataView
      *ngIf="_showInventoryItems"
      [saleForm]="saleForm"
      (onLayoutChange)="onLayoutOptionChange($event)"
      [rows]="currentFetchLimit"
      [totalRecords]="totalRecords"
      [productsLoading]="_galleryDataLoading"
      [dvLayoutOption]="_dvLayoutOption"
      (onLazyLoad)="loadInventoryLazy($event)"
      (onSearchSubmit)="onSearchFieldSubmit()"
      [inventories]="inventories"
      searchFieldPlaceholder="Enter a keyword"
      (onBackToCategories)="goBackToCategories()"
      [dvContainerWidth]="calculatedDvWidth"
      [dvContainerHeight]="calculatedDvHeight"
      [currentCategoryName]="currentCategory?.categoryName"
      [roundBtnsAndSearchField]="false"
      [showEmptyMessage]="showEmptyMessage"
    >
      <ng-template #saleDocGridCardTemplate let-inventory let-width="width" let-height="height">
        <taku-gallery-grid-card
          [inventory]="inventory"
          (click)="onSelectItem(inventory, $event)"
          [width]="width"
          [height]="width * 1.2"
          [_isMobile]="isMobile"
        >
          <a detailTooltip href="javascript://" class="info-circle" (click)="onClickedViewDetails(inventory, $event)">
            <i class="pi pi-info-circle"></i>
          </a>
          <a
            detailTooltipChangedFormat
            href="javascript://"
            class="info-circle"
            (click)="onClickedViewDetails(inventory, $event)"
          >
            <i class="pi pi-info-circle"></i>
          </a>
        </taku-gallery-grid-card>
      </ng-template>

      <ng-template #saleDocListCardTemplate let-inventory let-width="width" let-height="height">
        <taku-gallery-list-card [inventory]="inventory" [width]="width" [height]="height">
          <span SKULink>
            <a (click)="onClickedViewDetails(inventory, $event)"
              ><u>{{ inventory.sku }}</u></a
            >
          </span>
          <p-button
            addToSaleDoc
            (onClick)="onSelectItem(inventory, $event)"
            styleClass="squared-corners"
            icon="pi pi-plus"
          ></p-button>
        </taku-gallery-list-card>
      </ng-template>
    </taku-inventory-data-view>
    <!-- Categories View -->
    <taku-category-data-view
      #saleDocDataView
      *ngIf="!_showInventoryItems"
      [saleForm]="saleForm"
      (onItemSelected)="showItemsOfCategory($event)"
      [rows]="currentFetchLimit"
      [totalCategories]="totalCategories"
      [productsLoading]="_galleryDataLoading"
      [dvLayoutOption]="_dvLayoutOption"
      (onLazyLoad)="loadCategoryLazy($event)"
      (onSearchSubmit)="onSearchFieldSubmit()"
      [categories]="categories"
      searchFieldPlaceholder="Enter a keyword"
      (onBackToCategories)="goBackToCategories()"
      [dvContainerWidth]="calculatedDvWidth"
      [dvContainerHeight]="calculatedDvHeight"
      [roundBtnsAndSearchField]="false"
    >
    </taku-category-data-view>
  </p-tabPanel>

  <p-tabPanel header="Inventory">
    <ng-template pTemplate="content">
      <taku-form-list
        *ngIf="_isDataReady && _formListCols"
        _model="inventory"
        _detailMode="d"
        _title="Inventory"
        [_extraQueryParams]="_extraQueryParams"
        [_cols]="_formListCols"
        [_filter]="defaultFilter"
        [_dataService]="saleDocListService"
        [_modelService]="saleDocListService"
        [_bulkEditorEnabled]="false"
        [_hideClone]="true"
        [_saveFormState]="false"
        _scrollHeight="calc(100vh - 33.5rem)"
        [_dialogShowHeader]="false"
        [_hideDeleteHeaderBtn]="true"
        [_rowSelectionType]="RowSelectionType.NONE"
        [_hideFilterControls]="true"
        _stateKey="saledocList"
        [_hideViewCol]="true"
        [_modelValidation]="_formListRules"
        _dialogStyleClass="form-list-dialog-style"
      >
      </taku-form-list>
    </ng-template>
  </p-tabPanel>

  <p-tabPanel header="Search Results">
    <div *ngIf="!searchResults">
      <h3>No Results Found</h3>
    </div>
    <taku-inventory-data-view
      #searchResultsDataView
      *ngIf="searchResults"
      [saleForm]="saleForm"
      [showDataViewHeader]="false"
      [rows]="currentFetchLimit"
      [totalRecords]="searchResults.length"
      dvLayoutOption="list"
      [inventories]="searchResultsInventories"
      [dvContainerWidth]="calculatedDvWidth"
      [dvContainerHeight]="searchResultsContainerHeight"
      (onLazyLoad)="loadSearchResultsLazy($event)"
    >
      <ng-template #saleDocListCardTemplate let-inventory let-width="width" let-height="height">
        <taku-gallery-list-card [inventory]="inventory" [width]="width" [height]="height">
          <span SKULink>
            <a (click)="onClickedViewDetails(inventory, $event)"
              ><u>{{ inventory.sku }}</u></a
            >
          </span>
          <p-button
            addToSaleDoc
            (onClick)="onSelectItem(inventory, $event)"
            styleClass="squared-corners"
            icon="pi pi-plus"
          ></p-button>
        </taku-gallery-list-card>
      </ng-template>
    </taku-inventory-data-view>
  </p-tabPanel>
</p-tabView>
