import { HttpClient } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { merge } from "lodash";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { CurrencyFormatterService } from "src/app/shared/services/currency-formatter.service";
import { DBTaxService } from "src/app/shared/services/db-tax.service";
import { DBService } from "src/app/shared/services/db.service";
import { SaledocRevisitPreviewComponent } from "../../document/sale-document/saledoc-revisit-preview/saledoc-revisit-preview.component";
import { SaleDoc } from "../../document/sale-document/sale-doc/sale-doc";
import { InventoryDocumentPreviewComponent } from "../../document/inventory-document/inventory-document-preview/inventory-document-preview.component";
import { PurchaseDocumentPreviewComponent } from "../../document/purchase-document/purchase-document-preview/purchase-document-preview.component";

@Component({
  selector: "taku-inventory-stock-details",
  templateUrl: "./inventory-stock-details.component.html",
  styleUrls: ["./inventory-stock-details.component.scss"],
})
export class InventoryStockDetailsComponent extends DBService {
  @Input() inventoryStockDetails: InventoryStockDetail[];
  @Input() qtyDetailsType: QtyDetailsType;
  protected readonly QtyDetailsType: typeof QtyDetailsType = QtyDetailsType;
  constructor(
    private currencyFormatterService: CurrencyFormatterService,
    private dialogService: DialogService,
    public ref: DynamicDialogRef,
    protected http: HttpClient,
    protected authService: AuthService,
    private dbTaxService: DBTaxService,
    private fb: UntypedFormBuilder,
    protected appSettings: AppSettingsStorageService
  ) {
    super(http, authService);
  }

  showSaleDocpreview(inventoryStockDetail: InventoryStockDetail): void {
    if (inventoryStockDetail.committedQty) {
      this.getRow("saleDoc", inventoryStockDetail.id).subscribe((_saleDoc) => {
        this.dialogService.open(SaledocRevisitPreviewComponent, {
          data: {
            _object: _saleDoc,
            _orgSaleDoc: _saleDoc,
          },
          width: "90%",
          height: "90%",
          closable: false,
          showHeader: false,
        });
      });
    } else if (inventoryStockDetail.onOrderQty) {
      this.getRow("purchaseDoc", inventoryStockDetail.id).subscribe((_purchaseDoc) => {
        this.dialogService.open(PurchaseDocumentPreviewComponent, {
          data: {
            _object: _purchaseDoc,
            _orgInventoryDoc: _purchaseDoc,
            accountName: inventoryStockDetail.name,
          },
          width: "90%",
          height: "90%",
          closable: false,
          showHeader: false,
        });
      });
    }
  }
}

export enum QtyDetailsType {
  OnOrdered = "OnOrdered",
  Committed = "Committed",
}

export class InventoryStockDetail {
  id: number;
  docId: number;
  stockId: number;
  accountCode: string;
  name: string;
  docNo: string;
  docType: string;
  shippingDate: string;
  onOrderQty?: number;
  committedQty?: number;

  constructor() {
    this.id = 0;
    this.docId = 0;
    this.stockId = 0;
    this.accountCode = null;
    this.name = null;
    this.docNo = null;
    this.docType = null;
    this.shippingDate = null;
  }

  static convertIntoTypedObject(inventoryStockDetail: InventoryStockDetail): InventoryStockDetail {
    const typedInventoryStockDetail: InventoryStockDetail = merge(new InventoryStockDetail(), inventoryStockDetail);
    return typedInventoryStockDetail;
  }
}
