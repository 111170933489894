import { Injectable, inject } from "@angular/core";
import { MessageService } from "primeng/api";
import { Subject, Observable, of } from "rxjs";
import { concatAll, map, delay, catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class PrintingQueueService {
  private printQueue = new Subject<() => Window>();

  private messageService = inject(MessageService);

  private sub = this.printQueue
    .pipe(
      map((createPrintWindow) =>
        new Observable<void>((observer) => {
          const printWindow = createPrintWindow();

          const closeEvent = () => {
            if (!observer.closed) {
              observer.next();
              observer.complete();
            }
          };

          // Check three different post-print/close events for redundancy in different browsers and methods of closing
          if (!printWindow || printWindow.closed || typeof printWindow.closed == "undefined") {
            //POPUP BLOCKED
          } else {
            printWindow.onafterprint = closeEvent;
            printWindow.onbeforeunload = closeEvent;
            printWindow.onunload = closeEvent;
          }
        }).pipe(
          delay(100), // Add delay between print jobs to allow browser to close window
          catchError((error) => {
            //console.error(error);
            this.messageService.add({
              severity: "error",
              summary: "Print error",
              detail: String(error),
              life: 20000,
            });
            return of();
          })
        )
      ),
      concatAll() // Run each print job consecutively
    )
    .subscribe();

  enqueuePrintJob(job: () => Window): void {
    this.printQueue.next(job);
  }
}
