/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { DBService } from "../../../../shared/services/db.service";
import { FormListModel } from "../../../../form-list/form-list/form-list-model.interface";
import { ValidatorFn, Validators } from "@angular/forms";
import { Col, DataType, FilterType } from "../../../../form-list/form-list/form-list";
import { RolePrivilegeName } from "./role-privilege";

@Injectable({
  providedIn: "root",
})
export class RolePrivilegesService extends DBService implements FormListModel {
  enum_privileges = this.enumSelectOptions(RolePrivilegeName);

  getValidationRules(): { [key: string]: ValidatorFn[] | {} } {
    return {
      privilege: [Validators.required],
    };
  }

  getFormListColumns(): Col[] {
    return [
      {
        field: "caption",
        header: "Description",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        styleObj: { "text-align": "left" },
      },
      {
        field: "privilege",
        header: "Privilege",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enum_privileges,
        filterType: FilterType.enum,
      },
    ];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
