<div class="col-12">
  <figure class="header-image">
    <img src="/assets/layout/images/settings/integrations/google-logo.png" alt="Google" class="google-logo" />
    <figcaption>Google Shopping</figcaption>
  </figure>

  <h1>Connect your Google Shopping Account to TAKU Retail</h1>

  <div>Here's what to expect when you connect to TAKU Retail:</div>

  <div class="termsConditions-wrapper">
    <p
      >When you integrate your Google account with the TAKU Retail platform, you can connect a number of Google services
      to your TAKU Retail account. By doing so, TAKU Retail may be able to access data and information from your Google
      account. Those services and types of data are outlined below;</p
    >
    <ul>
      <li
        >Google Shopping: You can send and receive business details, location details and inventory details from the
        TAKU Retail app. TAKU Retail will have access to your Google Merchant Center data such as your merchant ID,
        Google My Business account details, GMC account email, associated account details, access token, inventory
        status and all integrated business, location and inventory details.</li
      >
    </ul>

    <p
      >We’ll only use your Google data to power different parts of the TAKU Retail tools you use every day. It's your
      data. We just want to help you grow better and work more efficiently.</p
    >

    <p
      >You can remove the integration in the TAKU Retail app by navigating to
      <em>Settings > Integrations > Marketing Integrations > Google Shopping</em> and clicking the red delete button for
      every associated account. You can also revoke TAKU Retail’s access from within your Google account by following
      the instructions listed <a href="https://support.google.com/accounts/answer/3466521" target="_blank">here</a>.</p
    >

    <p
      >For more information on the Google integration and the types of data we can access, please see our
      <a href="https://www.takulabs.io/privacy" target="_blank">Privacy Policy</a>.</p
    >
  </div>

  <footer>
    <div class="continueBtn-wrapper">
      <button pButton label="CONTINUE" (click)="onContinuePressed()"></button>
    </div>

    <div class="otherLink-wrapper">
      <a href="javascript://" (click)="anotherIntegratorLinkPressed()">&lt; Choose a different integration provider</a>
    </div>
  </footer>
</div>
