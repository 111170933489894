/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { ValidatorFn, Validators } from "@angular/forms";
import * as _ from "lodash";
import { SelectItem } from "primeng/api";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthService } from "src/app/shared/services/auth.service";
import { LocationService } from "src/app/shared/services/location.service";
import {
  ArrayDatatypeOptions,
  DataType,
  ExecuteEventDatatypeOptions,
  ExecuteEvent_DisplayMode,
  FilterType,
  Col,
  NestedCol,
} from "../../../form-list/form-list/form-list";
import { FormListModel } from "../../../form-list/form-list/form-list-model.interface";
import { DBService, LookupOptions } from "../../../shared/services/db.service";
import { CommercialPhoneType } from "../../contact-accounts/commercial-account-phone/commercial-account-phone";
import { PersonAddressArrayComponent } from "../../contact-accounts/person-address-array/person-address-array.component";
import { PersonAddress } from "../../contact-accounts/person-address/person-address";
import { PersonComponent } from "../../contact-accounts/person/person.component";
import { UserProfileService } from "src/app/shared/services/user-profile.service";
import { User } from "./user";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { PhoneArrayComponent } from "../../contact-accounts/phone-array/phone-array.component";

@Injectable({
  providedIn: "root",
})
export class UserService extends DBService implements FormListModel {
  private readonly fieldPathCountry = "person.personAddresses[0].address.countryIsoCode";
  private readonly fieldPathSubdivision = "person.personAddresses[0].address.subDivisionIsoCode";
  static readonly MODEL_NAME = "user";

  openPermissionsEvent = new EventEmitter<any>();
  lookup_subdivisions: { [countryIsoCode: string]: SelectItem[] } = {};
  enumPhoneType = this.enumSelectOptions(CommercialPhoneType);
  enumCountries = this.locationService.lookupCountryOptions_full();
  enum_isActive = this.enumSelectOptions(
    {
      Active: true,
      Inactive: false,
    },
    { useKeyAsLabel: true }
  );
  _disableCloning = true;

  getLookup_roles(): Observable<SelectItem[]> {
    return this.lookupSelectOptions("role", "roleName", { enableFieldName: "isActive" });
  }

  getLookupMulti_stores(): Observable<SelectItem[]> {
    return this.lookupMultiSelectOptions("store", "storeName", { enableFieldName: "isActive" });
  }

  getLookup_users(isSuperAdmin = true): Observable<SelectItem[]> {
    const options: LookupOptions = {
      enableFieldName: "isActive",
    };
    if (!isSuperAdmin) {
      options.lookupFilter = JSON.stringify({ isSuperAdmin: { matchMode: "ne", value: 1 } });
    }
    return this.lookupSelectOptions(UserService.MODEL_NAME, "userName", options).pipe(catchError((error) => of([])));
  }

  getLookupMulti_backOffices(): Observable<SelectItem[]> {
    return this.lookupMultiSelectOptions("backOffice", "backOfficeName", { enableFieldName: "isActive" });
  }

  getValidationRules(): { [key: string]: ValidatorFn[] | {} } {
    return {
      userName: [Validators.required],
      email: [Validators.required, Validators.email],
      person: PersonComponent.validators,
    };
  }

  onCountryChanged(rowData, newCountry) {
    _.set(rowData, this.fieldPathSubdivision, null);
  }

  get_lookup_subdivisions(countryIsoCode): SelectItem[] {
    if (!this.lookup_subdivisions[countryIsoCode])
      this.lookup_subdivisions[countryIsoCode] = this.locationService.lookupSubdivisionOptions(countryIsoCode);

    return this.lookup_subdivisions[countryIsoCode];
  }

  formlist_lookup_subdivisions(rowData): SelectItem[] {
    const countryCode = _.get(rowData, this.fieldPathCountry);
    return this.get_lookup_subdivisions(countryCode);
  }

  formlist_filter_subdivisions(colsFilters): SelectItem[] {
    const countryCode = _.get(colsFilters, this.fieldPathCountry);

    if (countryCode) return this.get_lookup_subdivisions(countryCode);
    else return [];
  }

  constructor(
    protected http: HttpClient,
    protected authService: AuthService,
    private locationService: LocationService,
    private userProfileService: UserProfileService,
    private appStorageService: AppSettingsStorageService
  ) {
    super(http, authService);
  }

  addressColDisplayLine(pAddress: PersonAddress) {
    if (!pAddress) return "";

    return [
      [pAddress.address.line1 || null, pAddress.address.line2 || null].filter(Boolean).join(". "),
      [
        pAddress.address.city || null,
        pAddress.address.countryIsoCode && pAddress.address.subDivisionIsoCode
          ? this.locationService.lookupSubdivisionName(
              pAddress.address.countryIsoCode,
              pAddress.address.subDivisionIsoCode
            )
          : null,
        pAddress.address.postalCode || null,
      ]
        .filter(Boolean)
        .join(", "),
      pAddress.address.countryIsoCode
        ? this.locationService.lookupCountryByIsoCode(pAddress.address.countryIsoCode)
        : null,
    ]
      .filter(Boolean)
      .join(". ");
  }

  getFormListColumns(): Col[] {
    const loggedInUserIsOwner =
      this.appStorageService.getUser()?.isOwner || this.appStorageService.getUser()?.isSuperAdmin;
    return [
      {
        field: "isActive",
        header: "Active",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.checkbox,
        dataOptions: [],
        filterOptions: this.enum_isActive,
        filterType: FilterType.enum,
      },
      {
        field: "isOwner",
        header: "Owner?",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.checkbox,
        dataOptions: [],
        filterType: FilterType.enum,
        filterOptions: Col.boolean_filter_enum,
        colWidth: 100,
      },
      {
        field: "isAdmin",
        header: "Admin?",
        visible: true,
        readonly: !loggedInUserIsOwner,
        frozen: false,
        dataType: DataType.checkbox,
        dataOptions: [],
        filterType: FilterType.enum,
        filterOptions: Col.boolean_filter_enum,
        colWidth: 100,
      },
      {
        field: "person.firstName",
        header: "First Name",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterField: "person",
        filterType: FilterType.contains,
      },
      {
        field: "person.lastName",
        header: "Last Name",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterField: "person",
        filterType: FilterType.contains,
      },
      {
        field: "userName",
        header: "Username",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "email",
        header: "Email",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      // First Phone number
      {
        field: "person.personPhones",
        header: `Phones`,
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.array,
        dataTypeOptions: new ArrayDatatypeOptions({
          component: PhoneArrayComponent,
          displayField: "tel",
          showSummary: true,
        }),
        dataOptions: [],
        filterType: FilterType.contains,
        filterField: "person",
        isNotSortable: true,
      },
      // { field: 'person.personPhones[0].phoneType', header: `Phone Type`, visible: false, readonly: false, frozen: false, dataType: DataType.enum, dataOptions: this.enumPhoneType, filterType: FilterType.enum, colWidth: 180, },
      // { field: 'person.personPhones[0].countryIsoCode', header: `Phone Country`, visible: false, readonly: false, frozen: false, dataType: DataType.enum, dataOptions: this.enumCountries, filterType: FilterType.enum, colWidth: 180, },
      // { field: 'person.personPhones[0].tel', header: `Phone Number`, visible: false, readonly: false, frozen: false, dataType: DataType.input, dataOptions: [], filterType: FilterType.contains, colWidth: 180, },
      // { field: 'person.personPhones[0].ext', header: `Phone Extension`, visible: false, readonly: false, frozen: false, dataType: DataType.input, dataOptions: [], filterType: FilterType.contains, colWidth: 180, },
      // First address
      {
        field: "person.personAddresses",
        header: `Addresses`,
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.array,
        dataTypeOptions: new ArrayDatatypeOptions({
          component: PersonAddressArrayComponent,
          displayField: this.addressColDisplayLine.bind(this),
          showSummary: true,
        }),
        dataOptions: [],
        filterType: FilterType.compound,
        enableGlobalFilter: false,
        isNotSortable: true,
        children: [
          new NestedCol({
            field: "address.line1",
            header: "Address Line 1",
            dataType: DataType.input,
            dataOptions: [],
            filterType: FilterType.contains,
          }),
          new NestedCol({
            field: "address.line2",
            header: "Address Line 2",
            dataType: DataType.input,
            dataOptions: [],
            filterType: FilterType.contains,
          }),
          new NestedCol({
            field: "address.countryIsoCode",
            header: "Country",
            dataType: DataType.enum,
            dataOptions: this.enumCountries,
            filterType: FilterType.enum,
          }),
          new NestedCol({
            field: "address.city",
            header: "City",
            dataType: DataType.input,
            dataOptions: [],
            filterType: FilterType.contains,
          }),
          new NestedCol({
            field: "address.postalCode",
            header: "Postal Code",
            dataType: DataType.input,
            dataOptions: [],
            filterType: FilterType.contains,
          }),
        ],
      },
      // { field: 'person.personAddresses[0].firstName', header: 'First Name', visible: false, readonly: false, frozen: false, dataType: DataType.input, dataOptions: [], filterType: FilterType.contains },
      // { field: 'person.personAddresses[0].lastName', header: 'Last Name', visible: false, readonly: false, frozen: false, dataType: DataType.input, dataOptions: [], filterType: FilterType.contains },
      // { field: this.fieldPathCountry, header: 'Country', visible: false, readonly: false, frozen: false, dataType: DataType.enum, dataOptions: this.enumCountries, filterType: FilterType.enum, onModelChange: this.onCountryChanged.bind(this)},
      // { field: this.fieldPathSubdivision, header: 'Province', visible: false, readonly: false, frozen: false, dataType: DataType.enum, dataOptions: this.formlist_lookup_subdivisions.bind(this), filterType: FilterType.enum, filterOptions: this.formlist_filter_subdivisions.bind(this), colWidth: 250 },
      // { field: 'person.personAddresses[0].address.city', header: 'City', visible: false, readonly: false, frozen: false, dataType: DataType.input, dataOptions: [], filterType: FilterType.contains },
      // { field: 'person.personAddresses[0].address.postalCode', header: 'Postal Code', visible: false, readonly: false, frozen: false, dataType: DataType.input, dataOptions: [], filterType: FilterType.contains },
      // { field: 'person.personAddresses[0].address.line1', header: 'Address Line 1', visible: false, readonly: false, frozen: false, dataType: DataType.input, dataOptions: [], filterType: FilterType.contains },
      // { field: 'person.personAddresses[0].address.line2', header: 'Address Line 2', visible: false, readonly: false, frozen: false, dataType: DataType.input, dataOptions: [], filterType: FilterType.contains },
    ];
  }

  getLoggedinUser(): Observable<User> {
    return this.getRow<User>(UserService.MODEL_NAME, this.userProfileService.getUserId());
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
