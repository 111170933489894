<p-panel id="googleAccounts-wrapper" class="col-12">
  <ng-template pTemplate="header">
    <h2>Google Merchant Center Accounts</h2>
    <div class="connect-googleAccount">
      <button
        pButton
        label="CONNECT your Google Account"
        class="p-button-primary-dark"
        (click)="connectAccount()"
      ></button>
    </div>
  </ng-template>

  <div *ngIf="!gmcGoogleAccounts || !gmcGoogleAccounts.length" class="empty-results-msg">
    No Connected Google Accounts. Press button 'CONNECT your Google Account' to connect a new account.
  </div>

  <div class="col-12 googleAccount-details" *ngFor="let gmcAccountLink of gmcGoogleAccounts; let i = index">
    <div class="grid googleAccounts-above-table">
      <div class="vertical-align-center col-4 md:col-2 xl:col-2">
        <button
          pButton
          href="javascript://"
          pTooltip="Refresh status of accounts"
          (click)="refreshAccountsStatus(gmcAccountLink)"
          class="refresh-accounts-btn"
          icon="pi pi-refresh"
        ></button>
        <label>Your Google Account:</label>
      </div>
      <div class="google-email vertical-align-center col-8 md:col-3 xl:col-2">
        <span>
          {{ gmcAccountLink?.googleAccount?.email || "N/A" }}
        </span>
      </div>
      <div class="disconnect-btn vertical-align-center col-12 md:col-3 xl:col-2">
        <button
          pButton
          label="DISCONNECT"
          class="p-button-secondary"
          (click)="disconnectAccount(gmcAccountLink)"
        ></button>
      </div>
      <div class="add-btn vertical-align-center col-12 md:col-4 xl:col-6">
        <span (click)="openGMCAccountDetails(gmcAccountLink)">
          Add New GMC<i class="material-icons">add_circle_outline</i>
        </span>
      </div>
    </div>

    <div class="googleAccountsTableWrapper">
      <table id="googleAccountsTable-mobile" class="grid-table googleAccounts-table" cellspacing="0">
        <ng-container *ngTemplateOutlet="googleAccountsHeaders"></ng-container>
      </table>

      <p-table
        id="googleAccountsTable"
        class="grid-table"
        tableStyleClass="googleAccounts-table"
        styleClass="p-datatable-striped"
        [value]="gmcAccountLink.gmcAccounts"
        responsiveLayout="stack"
        breakpoint="640px"
      >
        <ng-template pTemplate="header">
          <!-- <ng-container *ngTemplateOutlet="googleAccountsHeaders"></ng-container> -->
          <tr class="">
            <th class="col-delete"></th>
            <th class="col-actionBtn p-fluid"></th>
            <th class="col-merchantNumber">Merchant Number</th>
            <th class="col-connStatus">GMC Status</th>
            <th class="col-accountLinking">GMB Account Linking</th>
            <th class="col-accountLinking-status">GMB Account<br />Linking Status</th>
            <th class="col-LIA-contactInfo">Inventory<br />Verification Contact</th>
            <!-- <th class="col-LIA-contactInfo">GMC Lia Inventory<br>Verification Contact</th> -->
            <th class="col-LIA-status">Local Inventory Ads Status</th>
            <th class="col-view-locations"></th>
          </tr>
        </ng-template>
        <!-- <ng-template pTemplate="body" let-gmcAccount let-i="rowIndex"> -->
        <ng-template pTemplate="body" let-gmcAccount>
          <tr *ngIf="!gmcAccountLink.gmcAccounts || !gmcAccountLink.gmcAccounts.length">
            <td colspan="9" class="col-noAccounts-info">
              <em
                >There are not Google Merchant Centres registered with this account, please press button 'Add New GMC'
                in order to create one.</em
              >
            </td>
          </tr>
          <tr class="mobile-height-adjust">
            <td class="col-delete">
              <span class="p-column-title"></span>
              <a href="javascript://" (click)="tryToRemoveGMCAccount(gmcAccountLink, gmcAccount)" class="remove-btn">
                <i class="material-icons remove-button">remove_circle_outline</i>
              </a>
            </td>

            <td class="col-actionBtn p-fluid">
              <span class="p-column-title"></span>
              <!-- <button pButton (click)="openGMCAccountDetails(gmcAccountLink, gmcAccount)" label="Edit GMC" class="p-button-secondary"></button> -->
              <span class="actionBtn">
                <a
                  class="gmc-details-link"
                  href="javascript://"
                  (click)="openGMCAccountDetails(gmcAccountLink, gmcAccount)"
                >
                  <span>GMC Details</span>
                </a>
              </span>
            </td>

            <td class="col-merchantNumber">
              <span class="p-column-title">Merchant Number:</span>
              {{ gmcAccount.merchantId }}
            </td>

            <td [ngSwitch]="gmcStatus(gmcAccount)" class="col-connStatus">
              <div *ngSwitchCase="GMCAccountConnectionStatus.READY" class="status-wrapper">
                <span class="p-column-title">GMC Status:</span>
                <span class="status-text status-active">
                  Ready <i class="pi pi-info-circle" [pTooltip]="Tooltip.Connection_Status.CONNECTED"></i>
                </span>
              </div>
              <div *ngSwitchCase="GMCAccountConnectionStatus.NOT_READY" class="status-wrapper">
                <span class="p-column-title">GMC Status:</span>
                <span class="status-text status-inactive">
                  Not Ready <i class="pi pi-info-circle" [pTooltip]="Tooltip.Connection_Status.NOT_CONNECTED"></i>
                </span>
              </div>
              <div *ngSwitchCase="GMCAccountConnectionStatus.LOADING" class="status-wrapper">
                <span class="p-column-title">GMC Status:</span>
                <p-progressSpinner class="status-loading"></p-progressSpinner>
              </div>
              <div *ngSwitchDefault class="status-wrapper">
                <span class="p-column-title">GMC Status:</span>
                <span class="status-not-available">N/A</span>
              </div>
            </td>

            <td class="col-accountLinking">
              <span class="p-column-title">GMC Account Linking:</span>
              <taku-dropdown
                [formGroup]="_gmcForms[gmcAccount.id]"
                [options]="_lookup_gmbAccounts"
                appendTo="body"
                fieldName="gmbAccountId"
                fieldCaption="GMB Email"
                [group]="true"
              ></taku-dropdown>
            </td>

            <td [ngSwitch]="gmbStatus(gmcAccount)" class="col-accountLinking-status">
              <div *ngSwitchCase="GMBConnectionStatus.LINKED" class="status-wrapper">
                <span class="p-column-title">GMC Account Linking Status:</span>
                <span class="status-text status-linked">
                  Linked <i class="pi pi-info-circle" [pTooltip]="Tooltip.GMB_Linking_Status.LINKED"></i>
                </span>
                <!-- <span class="status-action col-7">
                  <a [attr.href]="buildGMBPageLink(gmcAccount)" target="_blank">View or Add New Linked Locations</a>
                </span> -->
              </div>
              <div *ngSwitchCase="GMBConnectionStatus.UNLINKED" class="status-wrapper">
                <span class="p-column-title">GMC Account Linking Status:</span>
                <span class="status-text status-unlinked">
                  Unlinked <i class="pi pi-info-circle" [pTooltip]="Tooltip.GMB_Linking_Status.UNLINKED"></i>
                </span>
                <span class="status-action">
                  <button
                    pButton
                    label="Request Access"
                    class="p-button-secondary"
                    (click)="requestGMBAccess(gmcAccount)"
                  ></button>
                </span>
              </div>
              <div *ngSwitchCase="GMBConnectionStatus.PENDING_UNVERIFIED" class="status-wrapper">
                <span class="p-column-title">GMC Account Linking Status:</span>
                <span class="status-text status-pending">
                  Pending and Unverified<i
                    class="pi pi-info-circle"
                    [pTooltip]="Tooltip.GMB_Linking_Status.PENDING_UNVERIFIED"
                  ></i>
                </span>
              </div>
              <div *ngSwitchCase="GMBConnectionStatus.PENDING_VERIFIED" class="status-wrapper">
                <span class="p-column-title">GMC Account Linking Status:</span>
                <span class="status-text status-pending">
                  Pending and Verified
                  <i class="pi pi-info-circle" [pTooltip]="Tooltip.GMB_Linking_Status.PENDING_VERIFIED"></i>
                </span>
                <span class="status-action">
                  <button
                    pButton
                    label="Link Locations"
                    class="p-button-secondary"
                    (click)="linkGMBLocations(gmcAccount)"
                  ></button>
                </span>
              </div>
              <div *ngSwitchCase="GMBConnectionStatus.LOADING" class="status-wrapper">
                <span class="p-column-title">GMC Account Linking Status:</span>
                <p-progressSpinner class="status-loading"></p-progressSpinner>
              </div>
              <div *ngSwitchDefault class="status-wrapper">
                <span class="p-column-title">GMC Account Linking Status:</span>
                <span class="status-not-available">N/A</span>
              </div>
            </td>

            <td [ngSwitch]="inventoryContactStatus(gmcAccount)" class="col-LIA-contactInfo">
              <div *ngSwitchCase="GMCInventoryContactStatus.NOT_SET" class="status-wrapper">
                <span class="p-column-title">Inventory Verification Contact:</span>
                <span class="status-text status-notset">
                  Contact Not Set
                  <i class="pi pi-info-circle" [pTooltip]="Tooltip.LIA_InventoryContact_Status.NOT_SET"></i>
                </span>
                <span class="status-action">
                  <button
                    pButton
                    label="Set Contact Information"
                    class="p-button-secondary"
                    (click)="setInventoryContactInfo(gmcAccount)"
                  ></button>
                </span>
              </div>
              <div *ngSwitchCase="GMCInventoryContactStatus.PENDING" class="status-wrapper">
                <span class="p-column-title">Inventory Verification Contact:</span>
                <span class="status-text status-pending">
                  Pending <i class="pi pi-info-circle" [pTooltip]="Tooltip.LIA_InventoryContact_Status.PENDING"></i>
                </span>
                <span class="status-action">
                  <button
                    pButton
                    label="Change Contact Information"
                    class="p-button-secondary"
                    (click)="setInventoryContactInfo(gmcAccount)"
                  ></button>
                </span>
              </div>
              <div *ngSwitchCase="GMCInventoryContactStatus.ACTIVE" class="status-wrapper">
                <span class="p-column-title">Inventory Verification Contact:</span>
                <span class="status-text status-active">
                  Active <i class="pi pi-info-circle" [pTooltip]="Tooltip.LIA_InventoryContact_Status.ACTIVE"></i>
                </span>
              </div>
              <div *ngSwitchCase="GMCInventoryContactStatus.INACTIVE" class="status-wrapper">
                <span class="p-column-title">Inventory Verification Contact:</span>
                <span class="status-text status-inactive">
                  Inactive <i class="pi pi-info-circle" [pTooltip]="Tooltip.LIA_InventoryContact_Status.INACTIVE"></i>
                </span>
                <span class="status-action">
                  <button
                    pButton
                    label="Set Contact Information"
                    class="p-button-secondary"
                    (click)="setInventoryContactInfo(gmcAccount)"
                  ></button>
                </span>
              </div>
              <div *ngSwitchCase="GMCInventoryContactStatus.LOADING" class="status-wrapper">
                <span class="p-column-title">Inventory Verification Contact:</span>
                <p-progressSpinner class="status-loading"></p-progressSpinner>
              </div>
              <div *ngSwitchDefault class="status-wrapper">
                <span class="p-column-title">Inventory Verification Contact:</span>
                <span class="status-not-available">N/A</span>
              </div>
            </td>

            <td [ngSwitch]="liaStatus(gmcAccount)" class="col-LIA-status">
              <div *ngSwitchCase="GMCLiaStatus.ACTIVE" class="status-wrapper">
                <span class="p-column-title">Local Inventory Ads Status:</span>
                <span class="status-text status-active">
                  Active <i class="pi pi-info-circle" [pTooltip]="Tooltip.LIA_Status.ACTIVE"></i>
                </span>
              </div>

              <div *ngSwitchCase="GMCLiaStatus.INACTIVE" class="status-wrapper">
                <span class="p-column-title">Local Inventory Ads Status:</span>
                <span class="status-text status-inactive">
                  Inactive <i class="pi pi-info-circle" [pTooltip]="Tooltip.LIA_Status.INACTIVE"></i>
                </span>
              </div>

              <div *ngSwitchCase="GMCLiaStatus.LOADING" class="status-wrapper">
                <span class="p-column-title">Local Inventory Ads Status:</span>
                <p-progressSpinner class="status-loading"></p-progressSpinner>
              </div>

              <div *ngSwitchDefault class="status-wrapper status-not-available">
                <span class="p-column-title">Local Inventory Ads Status:</span>
                <span class="status-not-available">N/A</span>
              </div>
            </td>

            <td class="col-view-locations">
              <span class="p-column-title"></span>
              <a
                class="manage-locations-link"
                href="javascript://"
                [ngClass]="{ 'disabled-link': !gmcAccount.gmbAccountId }"
                (click)="viewLocations(gmcAccountLink, gmcAccount)"
              >
                Manage Locations
              </a>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</p-panel>

<ng-template #googleAccountsHeaders let-gmcAccountLink>
  <tr>
    <th class="col-delete"></th>
    <th class="col-actionBtn p-fluid"></th>
    <th class="col-merchantNumber">Merchant Number</th>
    <th class="col-connStatus">GMC Status</th>
    <th class="col-accountLinking">GMB Account Linking</th>
    <th class="col-accountLinking-status">GMB Account<br />Linking Status</th>
    <th class="col-LIA-contactInfo">Inventory<br />Verification Contact</th>
    <th class="col-LIA-status">Local Inventory Ads Status</th>
    <th class="col-view-locations"></th>
  </tr>
</ng-template>
