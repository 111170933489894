import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ValidatorFn, Validators } from "@angular/forms";
import { Col, DataType, FilterType } from "src/app/form-list/form-list/form-list";
import { FormListModel } from "src/app/form-list/form-list/form-list-model.interface";
import { AuthService } from "src/app/shared/services/auth.service";
import { DBService } from "src/app/shared/services/db.service";
import { StoreService } from "../store/store.service";
import { PrinterManufacturer } from "./printer-settings";

@Injectable({
  providedIn: "root",
})
export class PrinterSettingsService extends DBService implements FormListModel {
  enum_printer_manufacturer = this.enumSelectOptions(PrinterManufacturer);
  constructor(protected http: HttpClient, protected authService: AuthService, private storeService: StoreService) {
    super(http, authService);
  }

  getValidationRules(): { [key: string]: ValidatorFn[] | {} } {
    return {
      name: [Validators.required],
      uniqueAddress: [Validators.required],
    };
  }

  getFormListColumns() {
    return [
      {
        field: "storeId",
        exportable: false,
        header: "Store",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.lookup,
        dataOptions: this.storeService.lookup_stores(),
        filterType: FilterType.lookup,
        sortFields: ["store.storeName"],
      },
      {
        field: "name",
        exportable: false,
        header: "Printer Name",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: this.storeService.lookup_stores(),
        filterType: FilterType.contains,
      },
      {
        field: "uniqueAddress",
        header: "MAC Address",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "manufacturer",
        header: "Manufacturer",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enum_printer_manufacturer,
        filterType: FilterType.enum,
      },
      {
        field: "isActive",
        header: "Active",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.checkbox,
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.enum,
      },
    ];
  }
}
