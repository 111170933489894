/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

export class BusinessFormatsSettings {
  id = 0;
  skuPrefix = "";
  skuStartingNumber = 1000;
  skuNoOfDigits = 6;
  skuSuffix = "";
  accountPrefix = "";
  accountStartingNumber = 1000;
  accountNoOfDigits = 6;
  accountSuffix = "";
  voucherPrefix = "";
  /** This number is not editable and not persisted. It is added here only as a convienient frontend hack */
  voucherStartingNumber = 1;
  voucherNoOfDigits = 12;
  voucherSuffix = "";
  updatedAt: string;
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
