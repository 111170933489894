/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ValidatorFn, Validators } from "@angular/forms";
import { Col, DataType, FilterType } from "../../form-list/form-list/form-list";
import { FormListModel } from "../../form-list/form-list/form-list-model.interface";
import { AuthService } from "../../shared/services/auth.service";
import { DBService, ExtendedSelectItem, LookupOptions } from "../../shared/services/db.service";
import { Observable, of } from "rxjs";
import { Zone } from "../settings/zone-settings/Zone";
import { AccountGroup } from "../settings/store-settings/account-group/account-group";
import { Store } from "../settings/store-settings/store/store";
import { SelectItem } from "primeng/api";
import { FilterRows } from "src/app/utility/FormDataHelpers";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { CommonValidators } from "src/app/shared/validators/CommonValidators";
import { ApiListResponse, ValidatorMapType } from "src/app/utility/types";
import { PriceLevel } from "./price-level";
import moment from "moment";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class PriceLevelService extends DBService implements FormListModel {
  _model = "priceLevel";

  constructor(
    protected http: HttpClient,
    protected authService: AuthService,
    private appSettings: AppSettingsStorageService
  ) {
    super(http, authService);
  }

  lookup_zones(): Observable<ExtendedSelectItem<number, Zone>[]> {
    const options: LookupOptions = { enableFieldName: "isActive" };

    if (!this.appSettings.isAdminLogin()) {
      const filter: FilterRows<Zone> = { id: { matchMode: "equals", value: this.appSettings.getZoneId() } };
      options.lookupFilter = JSON.stringify(filter);
    }
    return this.lookupSelectOptions("zone", "zoneName", options);
  }

  lookup_accountGroups(): Observable<ExtendedSelectItem<number, AccountGroup>[]> {
    return this.lookupSelectOptions("acctGroup", "name", { enableFieldName: "isEnabled" });
  }

  lookup_stores(): Observable<SelectItem<number>[]> {
    const options: LookupOptions = { enableFieldName: "isActive" };

    if (!this.appSettings.isAdminLogin()) {
      const filter: FilterRows<Store> = { zoneId: { matchMode: "equals", value: this.appSettings.getZoneId() } };
      options.lookupFilter = JSON.stringify(filter);
    }
    return this.lookupMultiSelectOptions("store", "storeName", options);
  }

  getPriceLevelsForStore(storeIdParam?: number): Observable<PriceLevel[]> {
    const storeId = storeIdParam ?? this.appSettings.getStoreId();
    if (!storeId) {
      console.warn("Cannot get active price levels without logging in as store.");
      return of([] as PriceLevel[]);
    }

    const filter: FilterRows<PriceLevel> = {
      "stores.id": { matchMode: "equals", value: storeId },
      isActive: { matchMode: "equals", value: true },
    };

    return this.getRows<ApiListResponse<PriceLevel>>("priceLevel", JSON.stringify(filter), null, 500, "priority", 1, {
      activeForStoreId: storeId,
    }).pipe(map((res) => res.rows));
  }

  getValidationRules(): ValidatorMapType<PriceLevel> {
    return {
      name: [Validators.required],
      priority: [Validators.required, Validators.min(1), Validators.max(127)],
      adjustBy: [Validators.required, Validators.min(0)],
      acctGroupId: [Validators.required, CommonValidators.lookup_singleSelect()],
    };
  }

  getFormListColumns(): Col[] {
    return [
      {
        field: "isActive",
        header: "Active",
        visible: true,
        readonly: false,
        frozen: true,
        dataType: DataType.checkbox,
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.enum,
        colWidth: 120,
      },
      {
        field: "name",
        header: "Name",
        visible: true,
        readonly: false,
        frozen: true,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 160,
      },
      {
        field: "acctGroupId",
        header: "Account Group",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.lookup,
        dataOptions: this.lookup_accountGroups(),
        filterType: FilterType.lookup,
        sortFields: ["acctGroup.name"],
      },
      {
        field: "priority",
        header: "Priority",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 120,
      },
      {
        field: "adjustBy",
        header: "Adjust By (%)",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 140,
      },
      {
        field: "storesMap",
        header: "Stores",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 140,
      },
      {
        field: "zoneId",
        header: "Zone Name",
        visible: false,
        readonly: false,
        frozen: false,
        dataType: DataType.lookup,
        dataOptions: this.lookup_zones(),
        filterType: FilterType.lookup,
        sortFields: ["zone.zoneName"] /*onModelChange: this.onZoneChanged.bind(this)*/,
      },
      {
        field: "effectiveDate",
        header: "Effective Date",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.date_date_only,
        dataOptions: [],
        filterType: FilterType.none,
      },
      {
        field: "endDate",
        header: "End Date",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.date_date_only,
        dataOptions: [],
        filterType: FilterType.none,
      },
      {
        field: "note",
        header: "Note",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 160,
      },
    ];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
