<div *ngFor="let page of pagesInfo" id="fullpage-preview-main">
  <header>
    <section id="retailer-info">
      <div class="left-side">
        <div class="retailer-logo-wrapper">
          <img *ngIf="businessLogoUrl" [attr.src]="businessLogoUrl" [attr.alt]="businessDetail?.businessName" />
          <span *ngIf="!businessLogoUrl" class="retailer-logo-placeholder"></span>
        </div>
        <h2 class="retailer-name">{{
          purchaseDoc?.backOffice?.backOfficeName ? purchaseDoc?.backOffice?.backOfficeName : storeInfo?.storeName
        }}</h2>
      </div>
      <!--
      <div class="right-side">
        <a href="https://maps.google.com/?q={{ _fullAddressMap }}&t=m" target="_blank">
          <div class="retailer-address">
            <div *ngIf="storeInfo?.address?.line1 | trim as line1" class="address-line">{{ line1 }}</div>
            <div *ngIf="storeInfo?.address?.line2 | trim as line2" class="address-line">{{ line2 }}</div>
            <div class="address-city">
              {{ storeInfo?.address?.city }},
              {{ subdivisionDisplay(storeInfo?.address?.subDivisionIsoCode) }}
              {{ storeInfo?.address?.postalCode }}
            </div>
            <div class="address-country">&nbsp;{{ storeInfo.address?.countryIsoCode }}</div>
          </div>
        </a>
        <div class="retailer-bottom-right">
          <div class="retailer-phone">
            <span class="phone-label">Phone:</span>
            {{ storeInfo?.address?.addressPhone.fullPhoneNumber }}
          </div>
          <div class="retailer-email">
            {{ storeInfo?.address?.addressEmail.email }}
          </div>
        </div>
      </div>
      -->
    </section>
    <div></div>
    <div class="right-side">
      <div class="printpage-no">-- Page {{ page.pageNumber }} of {{ totalPages }} --</div>
      <section id="receipt-details">
        <h1>
          <span class="receipt-doctype">
            {{ purchaseDoc.doc.docType }}
          </span>
          <span class="receipt-docno">No: {{ purchaseDoc.doc.docNo }}</span>
        </h1>
        <ul class="left-side">
          <li>
            <span class="receipt-field-label">Date:</span> {{ purchaseDoc.doc.docDate | dateFormat }} @
            {{ purchaseDoc.doc.docTime }}
          </li>

          <li *ngIf="purchaseDoc.doc.user">
            <span class="receipt-field-label">User:</span> {{ purchaseDoc.doc.user?.userName }}
          </li>
        </ul>
      </section>
    </div>

    <ng-container *ngTemplateOutlet="invoiceShippingInfo; context: { page: page }"></ng-container>
  </header>

  <main>
    <table id="doc-lines" cellspacing="0" [ngStyle]="{ fontSize: '11px' }">
      <thead>
        <th class="col-supplier-code">SUPPLIER CODE</th>
        <th class="col-description">PRODUCT TITLE</th>
        <th class="col-qty">QTY</th>
        <th class="col-uofm">U OF M</th>
        <th class="col-pack-size">PACK SIZE</th>
        <th class="col-price">UNIT COST</th>
        <th class="col-amount">TOTAL</th>
      </thead>

      <ng-template ngFor [ngForOf]="page.purchaseDocLines" let-line>
        <tbody class="transaction-lines">
          <tr class="item-line1">
            <td class="item-supplier-code">{{ getSupplierCode(line) }}</td>
            <td [attr.colspan]="1" class="item-description">
              <span class="item-desc-inner">
                {{ line.docLine?.inventory.description1 }}
              </span>
            </td>
            <td class="item-qty">{{ line.qty | formatNegativeNumber }}</td>
            <td class="item-uofm"> {{ line.docLine?.inventory?.uOfMeasure?.name }} </td>
            <td class="item-pack-size">{{ line.packSize }}</td>
            <td class="item-sale-price" [ngClass]="{ 'add-border-right': purchaseDoc['isPackingList'] }">
              <div>
                {{ line.unitPrice | currency : _currencyIsoCode : "symbol-narrow" : _digitsFormat }}
              </div>
            </td>
            <td class="item-total">
              <span>
                {{
                  line.unitPrice * line.qty
                    | currency : _currencyIsoCode : "symbol-narrow" : _digitsFormat
                    | formatNegativeNumber
                }}
                <span class="item-tax-name">{{ getLineTaxAcronyms(line) }}</span>
              </span>
            </td>
          </tr>
          <tr class="item-line2">
            <td></td>
            <td [attr.colspan]="1" class="item-variants">
              <span>{{ inventoryVariantsStr(line) }} </span>
              <span *ngIf="inventoryVariantsStr(line) && inventoryVariantsStr(line)"> | </span>
              <span>{{ categoriesPrettyPrint(line) }}</span>
              <span></span>
            </td>
            <ng-template [ngIf]="!line.docLine?.note">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </ng-template>
            <td *ngIf="line.docLine?.note" class="item-note" colspan="5">
              <span class="item-note-inner"
                >NOTE: <em>{{ line.docLine?.note }}</em></span
              >
            </td>
          </tr>
        </tbody>
      </ng-template>
    </table>
  </main>

  <ng-container *ngTemplateOutlet="invoiceFooter; context: { page: page }"></ng-container>
  <div *ngIf="!hideNotReceiptWatermark" class="not-receipt-watermark first">DRAFT PURCHASE ORDER</div>
</div>

<ng-template let-page="page" #invoiceShippingInfo>
  <div style="height: 100% !important">
    <section *ngIf="page.isFirstPage" id="ship-to" class="billing-info-block">
      <div class="header"><span class="label">ISSUED TO:</span></div>
      <b>{{ purchaseDoc.account.commercialAccount.name }}</b>
      <br />
      <div class="ship-address">
        <div class="address-line1">{{ supplierAddress?.line1 }}</div>
        <div *ngIf="supplierAddress?.line2" class="address-line2">{{ supplierAddress.line2 }}</div>
        <div class="address-city">
          {{ supplierAddress?.city || ""
          }}{{ (supplierAddress?.city ? ", " : "") + subdivisionDisplay(supplierAddress?.subDivisionIsoCode)
          }}{{
            (subdivisionDisplay(supplierAddress?.subDivisionIsoCode) ? ", " : "") + (supplierAddress?.postalCode || "")
          }}{{ (supplierAddress?.postalCode ? " " : "") + (supplierAddress?.countryIsoCode || "") }}
        </div>
        <div *ngIf="supplierAddress?.addressPhone?.tel" class="attn-phone">
          Phone: {{ supplierAddress?.addressPhone?.tel }}
          <span *ngIf="supplierAddress?.addressPhone?.ext">
            &nbsp; Ext.
            {{ supplierAddress?.addressPhone?.ext }}
          </span></div
        >
      </div>

      <div class="ship-attn">
        <div *ngIf="supplierContact?.firstName || supplierContact?.lastName" class="attn-name">
          <b
            ><span class="field-label">Contact: </span
            >{{ supplierContact?.firstName + " " + supplierContact?.lastName }}</b
          >
        </div>
        <div *ngIf="supplierContact?.personEmails.length > 0" class="attn-email">
          <b>Email: {{ supplierContact?.personEmails[0]?.email }}</b>
        </div>
      </div>
    </section>
  </div>

  <div style="height: 100% !important">
    <section
      *ngIf="page.isFirstPage && hasAddressInfo(purchaseDoc?.stockAddress) && purchaseDoc?.stockAddress as shipping"
      id="ship-to"
      class="billing-info-block"
    >
      <div class="header"><span class="label">SHIP TO:</span></div>
      <b>{{ businessName }}</b>
      <div class="ship-address">
        <div class="address-line1">{{ shipping.line1 }}</div>
        <div *ngIf="shipping.line2" class="address-line2">{{ shipping.line2 }}</div>
        <div class="address-city">
          {{ shipping.city || "" }}{{ (shipping.city ? ", " : "") + subdivisionDisplay(shipping.subDivisionIsoCode)
          }}{{ (subdivisionDisplay(shipping.subDivisionIsoCode) ? ", " : "") + (shipping.postalCode || "")
          }}{{ (shipping.postalCode ? " " : "") + (shipping.countryIsoCode || "") }}
        </div>
        <div *ngIf="shipping.addressPhone?.tel" class="attn-phone"
          >Phone: {{ shipping?.addressPhone?.tel }}
          <span *ngIf="shipping?.addressPhone?.ext"> &nbsp; Ext. {{ shipping?.addressPhone?.ext }}</span>
        </div>
      </div>

      <div class="ship-attn">
        <div class="attn-name">
          <b><span class="field-label">Receiving Location:</span> {{ purchaseDoc?.stock?.stockName }}</b>
        </div>
        <div class="attn-email">
          <b>Ship Date: {{ purchaseDoc.shippingDate | date : "MMM dd, yyyy HH:mm" }}</b>
        </div>
        <div class="attn-email" *ngIf="shipping?.addressEmail">
          <b>Email: {{ shipping?.addressEmail?.email }}</b>
        </div>
        <div class="attn-email" *ngIf="shipping?.note">
          <b>{{ shipping?.note }}</b>
        </div>
      </div>
    </section>
  </div>

  <div style="height: 100% !important">
    <section *ngIf="page.isFirstPage && billingAddress as billing" id="bill-to" class="billing-info-block">
      <div class="header"><span class="label">BILL TO:</span></div>
      <b>{{ purchaseDoc?.backOffice?.backOfficeName || this.purchaseDoc?.store?.storeName }}</b>
      <div class="bill-address">
        <div *ngIf="billing.line1" class="address-line1">{{ billing.line1 }}</div>
        <div *ngIf="billing.line2" class="address-line2">{{ billing.line2 }}</div>
        <div class="address-city">
          {{ billing.city || "" }}{{ (billing.city ? ", " : "") + subdivisionDisplay(billing.subDivisionIsoCode)
          }}{{ (subdivisionDisplay(billing.subDivisionIsoCode) ? ", " : "") + (billing.postalCode || "")
          }}{{ (billing.postalCode ? " " : "") + (billing.countryIsoCode || "") }}
        </div>
        <div *ngIf="billing.addressPhone?.tel" class="attn-phone"
          >Phone: {{ billing.addressPhone.tel }}
          <span *ngIf="billing?.addressPhone?.ext"> &nbsp; Ext. {{ billing?.addressPhone?.ext }}</span>
        </div>
      </div>

      <div class="bill-to-attn">
        <div *ngIf="purchaseDoc.buyerName" class="attn-name">
          <b><span class="field-label">Buyer's Name:</span> {{ purchaseDoc.buyerName }}</b>
        </div>
        <div *ngIf="billing.addressEmail?.email" class="attn-email">
          <b>Email: {{ billing.addressEmail.email }}</b>
        </div>
      </div>
    </section>
  </div>
</ng-template>

<ng-template let-page="page" #invoiceFooter>
  <!--
  <section *ngIf="page.isLastPage && purchaseDoc?.doc?.comment" id="document-note">
    <span class="field-label">INTERNAL NOTES:</span> {{ purchaseDoc?.doc?.comment }}
  </section>
  -->

  <div>
    <footer *ngIf="page.isLastPage">
      <div class="left-side">
        <div style="height: 45% !important">
          <section id="order-terms" class="order-terms-block">
            <div class="header"><span class="label">ORDER TERMS:</span></div>
            <div class="order-terms">
              <table style="width: 100%">
                <tr>
                  <td
                    ><b>Currency:</b>
                    {{
                      purchaseDoc?.account?.commercialAccount?.supplierCurrencyIsoCode
                        ? purchaseDoc?.account?.commercialAccount?.supplierCurrencyIsoCode
                        : purchaseDoc.zone.defaultCurrencyIsoCode
                    }}
                  </td>
                  <td><b>Shipping Terms:</b> {{ purchaseDoc?.shippingTerm?.name }} </td>
                </tr>
                <tr>
                  <td><b>Payment Terms:</b> {{ purchaseDoc?.paymentTerm?.name }} </td>
                  <td><b>Ship VIA:</b> {{ purchaseDoc.shipVia }} </td>
                </tr>
              </table>
            </div>
          </section>
        </div>
        <div style="height: 30% !important">
          <section id="supplier-instrucstions" class="order-terms-block">
            <b>Supplier Instructions:</b>{{ purchaseDoc.supplierInstruction }}
          </section>
        </div>
        <div style="height: 30% !important">
          <section id="delivery-instrucstions" class="order-terms-block">
            <b>Delivery Instructions:</b>{{ purchaseDoc.deliveryInstruction }}
          </section>
        </div>
      </div>

      <div class="right-side">
        <section id="receipt-summary-totals">
          <table cellspacing="0">
            <tr class="receipt-subtotal">
              <td class="label" colspan="2">SUBTOTAL</td>
              <td class="amount" colspan="2">
                {{
                  purchaseDocSubTotal(purchaseDoc)
                    | currency : _currencyIsoCode : "symbol-narrow" : _digitsFormat
                    | formatNegativeNumber
                }}
              </td>
            </tr>
            <tr class="receipt-discount">
              <td class="label" colspan="2">
                DISCOUNT
                <span *ngIf="purchaseDoc.discountType === 'Percentage'">
                  ({{ purchaseDoc.discountAmount === 0.0 ? 0 : purchaseDoc.discountAmount }}%)
                </span>
              </td>
              <td class="amount" colspan="2">
                {{
                  purchaseDocDiscount(purchaseDoc)
                    | currency : _currencyIsoCode : "symbol-narrow" : _digitsFormat
                    | formatNegativeNumber
                }}
              </td>
            </tr>
            <tr *ngFor="let linetax of groupedTaxLines(purchaseDoc)" class="receipt-tax">
              <td class="label" colspan="2">TAX ({{ linetax.saleTaxRule?.tax?.shortDesc }})</td>
              <td class="amount" colspan="2">
                {{
                  linetax.total | currency : _currencyIsoCode : "symbol-narrow" : _digitsFormat | formatNegativeNumber
                }}
              </td>
            </tr>
            <tr class="receipt-shipping">
              <td class="label" colspan="2">SHIPPING</td>
              <td class="amount" colspan="2">
                {{
                  purchaseDoc.shipperChargeAmount
                    | currency : _currencyIsoCode : "symbol-narrow" : _digitsFormat
                    | formatNegativeNumber
                }}
              </td>
            </tr>
            <tr class="receipt-grand-total">
              <th colspan="2">
                <span class="item-count">{{ totalItems(purchaseDoc) }} items</span>
                <span class="label">TOTAL</span>
              </th>
              <th class="amount" colspan="2">
                {{
                  purchaseDocTotal(purchaseDoc)
                    | currency : _currencyIsoCode : "symbol-narrow" : _digitsFormat
                    | formatNegativeNumber
                }}
              </th>
            </tr>
          </table>
        </section>

        <section *ngIf="purchaseDoc.doc.docNo" id="returns-barcode">
          <div class="barcodeWrapper">
            <svg class="barcode-render" #returnsBarcode></svg>
          </div>
        </section>
      </div>
    </footer>
  </div>
</ng-template>
