/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

export class Role {
  id: number;
  roleName: string;
  isActive: boolean;
  isSystemRole: boolean;

  constructor() {
    this.id = 0;
    this.roleName = null;
    this.isActive = true;
    this.isSystemRole = false;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
