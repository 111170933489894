<div id="tape-cashout-slip">
  <header>
    <section *ngIf="store" id="retailer-info">
      <div class="retailer-logo-wrapper">
        <img
          *ngIf="businessLogoUrl"
          [attr.src]="businessLogoUrl"
          [attr.alt]="businessDetail.businessName"
          style="max-width: 130px"
        />
        <span *ngIf="!businessLogoUrl" class="retailer-logo-placeholder">YOUR LOGO HERE</span>
      </div>

      <h2 class="retailer-name">{{ store.storeName }}</h2>

      <div *ngIf="store.address" class="retailer-address">
        <div class="address-line1">{{ store.address?.line1 }}</div>
        <div class="address-city"
          >{{ store.address?.city }}, {{ subdivisionDisplay(store.address?.subDivisionIsoCode) }}
          {{ _countryName | uppercase }} {{ store.address?.postalCode }}</div
        >
      </div>

      <div *ngIf="store.address?.addressPhone" class="retailer-phone">{{
        store.address?.addressPhone.fullPhoneNumber
      }}</div>
    </section>
  </header>

  <main>
    <section id="slip-details">
      <h1>CASHOUT REPORT</h1>
      <ul>
        <li>
          <span class="slip-field-label">Printed:</span>
          <span class="slip-field-contents">{{ _printedDate | dateTimeFormat : printedDate.toTimeString() }}</span>
        </li>
        <li *ngIf="cashout.closingDate">
          <span class="slip-field-label">Closing Date:</span>
          <span class="slip-field-contents">{{ cashout.closingDate | dateTimeFormat : cashout.closingTime }}</span>
        </li>
        <li *ngIf="closingUser">
          <span class="slip-field-label">Closing User:</span>
          <span class="slip-field-contents">{{ closingUser.person.fullName | uppercase }}</span>
        </li>
        <li>
          <span class="slip-field-label">Cashout ID:</span> <span class="slip-field-contents">{{ cashout.id }}</span>
        </li>
        <li>
          <span class="slip-field-label">Station ID:</span>
          <span class="slip-field-contents">{{ cashout.station.stationNumber }}</span>
        </li>
      </ul>

      <table id="slip-documents">
        <tbody>
          <tr class="category-header">
            <th class="col-description">
              <div class="inner-wrapper"> SLIP DETAILS </div>
            </th>
            <th class="col-qty">
              <div class="inner-wrapper"> QTY </div>
            </th>
            <th class="col-total">
              <div class="inner-wrapper"> TOTAL{{ currencyIsoCode | currencySymbol }} </div>
            </th>
          </tr>
          <tr *ngFor="let doc of docsSummaries">
            <td class="col-description" [ngClass]="{ disabled: doc.state === 'Voided' }"
              >{{ doc.state === "Voided" ? "Voided " : "" }}{{ doc.docType }}</td
            >
            <td class="col-qty" [ngClass]="{ disabled: doc.state === 'Voided' }">{{ doc.count_doc }}</td>
            <td class="col-total" [ngClass]="{ disabled: doc.state === 'Voided' }">{{
              doc.grandTotal | number : _digitsFormat
            }}</td>
          </tr>
          <tr *ngIf="!docsSummaries || !docsSummaries.length">
            <td colspan="3">- No documents found for this cashout -</td>
          </tr>
          <!-- <tr>
                        <td class="col-description">Sales Invoices</td>
                        <td class="col-qty">50</td>
                        <td class="col-total">99.99</td>
                    </tr>
                    <tr>
                        <td class="col-description">Sales Invoices</td>
                        <td class="col-qty">50</td>
                        <td class="col-total">99.99</td>
                    </tr>
                    <tr>
                        <td class="col-description">Sales Invoices</td>
                        <td class="col-qty">50</td>
                        <td class="col-total">99.99</td>
                    </tr> -->
          <tr class="category-header">
            <th class="col-description">
              <div class="inner-wrapper">
                <span class="hide-deposit-label">DEPOSITS</span>
              </div>
            </th>
            <th class="col-system">
              <div class="inner-wrapper"><ng-template [ngIf]="showSystemValues">SYSTEM</ng-template></div>
            </th>
            <th class="col-total">
              <div class="inner-wrapper"> ACTUAL{{ currencyIsoCode | currencySymbol }} </div>
            </th>
          </tr>
          <tr *ngFor="let deposit of deposits">
            <td class="col-description">{{ deposit.tenderDescription }}</td>
            <td class="col-system"
              ><ng-template [ngIf]="showSystemValues">{{
                deposit.systemTotal || 0 | number : _digitsFormat
              }}</ng-template></td
            >
            <td class="col-total">{{ deposit.actualAmount || 0 | number : _digitsFormat }}</td>
          </tr>
          <!-- <tr>
                        <td class="col-description">Cash for Deposit</td>
                        <td class="col-system">89.50</td>
                        <td class="col-total">80.98</td>
                    </tr>
                    <tr>
                        <td class="col-description">Cash for Deposit</td>
                        <td class="col-system">89.50</td>
                        <td class="col-total">80.98</td>
                    </tr>
                    <tr>
                        <td class="col-description">Cash for Deposit</td>
                        <td class="col-system">89.50</td>
                        <td class="col-total">80.98</td>
                    </tr>
                    <tr>
                        <td class="col-description">Cash for Deposit</td>
                        <td class="col-system">89.50</td>
                        <td class="col-total">80.98</td>
                    </tr>
                    <tr>
                        <td class="col-description">Cash for Deposit</td>
                        <td class="col-system">89.50</td>
                        <td class="col-total">80.98</td>
                    </tr>
                    <tr>
                        <td class="col-description">Cash for Deposit</td>
                        <td class="col-system">89.50</td>
                        <td class="col-total">80.98</td>
                    </tr>
                    <tr>
                        <td class="col-description">Cash for Deposit</td>
                        <td class="col-system">89.50</td>
                        <td class="col-total">80.98</td>
                    </tr>
                    <tr>
                        <td class="col-description">Cash for Deposit</td>
                        <td class="col-system">89.50</td>
                        <td class="col-total">80.98</td>
                    </tr>
                    <tr>
                        <td class="col-description">Cash for Deposit</td>
                        <td class="col-system">89.50</td>
                        <td class="col-total">80.98</td>
                    </tr> -->
        </tbody>
        <tfoot>
          <tr class="total-row">
            <td class="col-description"></td>
            <td class="col-system"
              ><ng-template [ngIf]="showSystemValues">{{
                totalSystemAmount | currency : currencyIsoCode : "symbol-narrow" : _digitsFormat
              }}</ng-template></td
            >
            <td class="col-total">{{
              totalActualAmount | currency : currencyIsoCode : "symbol-narrow" : _digitsFormat
            }}</td>
          </tr>
          <tr class="penny-rounding">
            <td class="col-description" colspan="2">Cash Rounding</td>
            <td class="col-total">{{
              totalPennyRounding | currency : currencyIsoCode : "symbol-narrow" : _digitsFormat | formatNegativeNumber
            }}</td>
          </tr>
          <tr *ngIf="cashout.closingNote">
            <td colspan="3">
              <div class="notes" style="text-align: left"> Notes: {{ cashout.closingNote }} </div>
            </td>
          </tr>
          <tr *ngIf="showSignatureLine">
            <td class="signature" colspan="4">
              <!-- <hr> -->
              <div class="text">Signature</div>
            </td>
          </tr>
        </tfoot>
      </table>
    </section>
  </main>
</div>
