/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Location } from "@angular/common";
import { AfterViewInit, Component, Input } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { DBMode, GenericFormComponent } from "../../../forms/generic-form/generic-form.component";
import { AppSettingsStorageService } from "../../../shared/app-settings-storage.service";
import { AlertMessagesService } from "../../../shared/services/alert-messages.service";
import { AccountType } from "../account/account";
import { AccountComponent } from "../account/account.component";
import { ConsentFormInfo, PersonComponent } from "../person/person.component";
import { PersonalAccount } from "./personal-account";
import { PersonalAccountService } from "./personal-account.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { PersonalAccountsAccessRightsFields } from "../shared/personal-accounts-access-rights-fields";
import { PersonalAccountAccessRights } from "./personal-account-access-rights";

@Component({
  selector: "taku-personal-account",
  templateUrl: "./personal-account.component.html",
  styleUrls: ["./personal-account.component.scss"],
})
export class PersonalAccountComponent extends GenericFormComponent implements AfterViewInit {
  @Input() personalAccountAccessRights?: PersonalAccountsAccessRightsFields = new PersonalAccountAccessRights(
    this.authService
  );

  _pageTitle = "Personal Account";
  _toolbarTitle: string = this._pageTitle;

  constructor(
    protected _router: Router,
    public fb: UntypedFormBuilder,
    public dbService: PersonalAccountService,
    protected location: Location,
    public _route: ActivatedRoute,
    protected messageService: MessageService,
    protected alertMessage: AlertMessagesService,
    protected confirmationService: ConfirmationService,
    protected appSettingsService: AppSettingsStorageService,
    private authService: AuthService
  ) {
    super(
      _router,
      fb,
      dbService,
      location,
      _route,
      messageService,
      alertMessage,
      confirmationService,
      appSettingsService
    );
  }

  ngAfterViewInit(): void {
    this._afterComponentInitCompleteAsync();
  }

  private _afterComponentInitCompleteAsync() {
    void Promise.resolve().then(() => {
      if (this.dbMode === DBMode.insert && this._myForm.status === "VALID") {
        this._myForm.markAsDirty();
      }
    });
  }

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    const tmpForm = fb.group(new PersonalAccount());

    tmpForm.setControl("account", AccountComponent.init(fb, AccountType.personal));
    tmpForm.setControl("person", PersonComponent.init(fb));
    // tmpForm.setControl('accountCreditCards', fb.array([]));

    return tmpForm;
  }

  initLookups() {
    // this.dbService.lookupSelectOptions('person', 'name').subscribe(data => this.lookup_person = data);
  }

  onCancel() {}

  onSecondStep() {}

  initForm() {
    this._model = "personalAccount";
    this._object = new PersonalAccount();
    this._myForm = PersonalAccountComponent.init(this.fb);

    // By default every new Personal Account will have one billing (default address) and one shipping address
    // const billingAddress = new PersonAddress(AddressType.billingAddress);
    // billingAddress.address.isDefault = true;
    // const shippingAddress = new PersonAddress(AddressType.shippingAddress);
    // this._object.person.personAddresses = [ billingAddress, shippingAddress ];

    this._updateToolbarTitle();
    // Update titlebar after saving data
    this.subsList.push(
      this.changedForm.subscribe(() => {
        this._updateToolbarTitle();
      })
    );
  }

  loadObject() {
    super.loadObject();
    this._updateToolbarTitle();
  }

  setFormArrays(): void {
    this._myForm.setControl("person", PersonComponent.set(this.fb, this._object.person));
    this._myForm.setControl("account", AccountComponent.set(this.fb, this._object.account));
  }

  initValidation() {
    this._validation = {};
  }

  consentApproved(consentInfo: ConsentFormInfo) {
    this._myForm.patchValue({
      consent: consentInfo.consentApproved,
      consentDateTime: consentInfo.consentTime,
    });
  }

  get consentCtrl() {
    return this._myForm.get("consent");
  }

  get consentDateTimeCtrl() {
    return this._myForm.get("consentDateTime");
  }

  protected _updateToolbarTitle() {
    if (!this._object) return;

    const fullName: string = (this._object.person.firstName + " " + this._object.person.lastName).trim();
    this._toolbarTitle = this._pageTitle;
    if (this._id !== 0)
      this._toolbarTitle += `&nbsp;<span class='secondary-text'>${fullName ? `(${fullName})` : "&nbsp;"}</span>`;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
