/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Input, ViewChild, ElementRef, SimpleChanges, Output, EventEmitter } from "@angular/core";
import { SaleDoc } from "../sale-doc/sale-doc";
import { SaleDocLine, DiscountType } from "../sale-doc-line/sale-doc-line";
import { InvoiceHelpers } from "src/app/utility/InvoiceHelpers";
import { DBService } from "src/app/shared/services/db.service";
import { Tax } from "src/app/core/tax/tax/tax";
import { TaxRule } from "src/app/core/tax/tax-rule/tax-rule";
import { MessageService } from "primeng/api";
import { Inventory, InventoryStock } from "src/app/core/inventory/inventory/inventory";
import { UntypedFormArray, UntypedFormBuilder } from "@angular/forms";
import { InventoryStockComponent } from "src/app/core/inventory/inventory-stock/inventory-stock.component";
import { NestedFormDisplayMode } from "src/app/forms/nested-form/nested-form.component";
import { WebHelpers } from "src/app/utility/WebHelpers";
import { forkJoin } from "rxjs";
import { MonetaryHelpers } from "src/app/utility/MonetaryHelpers";

@Component({
  selector: "taku-sale-doc-transactions",
  templateUrl: "./sale-doc-transactions.component.html",
  styleUrls: ["./sale-doc-transactions.component.scss"],
})
export class SaleDocTransactionsComponent {
  @Input() saleDoc: SaleDoc;
  @ViewChild("saleLines", { static: true }) saleLinesWrapper: ElementRef;
  @Output() taxAdded: EventEmitter<any> = new EventEmitter<any>();

  DiscountType = DiscountType;
  inventoryStocksByInventoryId: Record<number, InventoryStock[]> = {};
  roundToDecimalPlacesHalfUp = MonetaryHelpers.roundToDecimalPlacesHalfUp;
  _activeDialog = false;
  _dialogContent = "";
  _displayMode: NestedFormDisplayMode = NestedFormDisplayMode.TABULAR_DATA;
  _inventoryStockFormArray: UntypedFormArray = InventoryStockComponent.initArray(this.fb);
  staticGroupedTaxLines = [];

  constructor(private dbService: DBService, private messageService: MessageService, public fb: UntypedFormBuilder) {}

  ngOnInit() {
    this.retrieveInventoryStocks();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.saleDoc.groupedTaxLines) {
      this.staticGroupedTaxLines = this.saleDoc.groupedTaxLines;
    }
  }

  private retrieveInventoryStocks(): void {
    const _inventoryIds = [];
    const _inventoryStocksByInventoryId: Record<number, InventoryStock[]> = {};

    this.saleDoc.saleDocLines?.forEach((saleLine) => {
      if (saleLine.docLine?.inventory?.id) {
        _inventoryIds.push(saleLine.docLine?.inventory?.id);
      }
    });

    if (!_inventoryIds.length) {
      return;
    }

    const _filter = {
      inventoryId: {
        value: _inventoryIds,
        matchMode: "in",
      },
    };

    this.dbService.getRows("inventoryStock", JSON.stringify(_filter), 0, 50).subscribe((results) => {
      if (results?.rows?.length > 0) {
        const _inventoryStocks = results.rows;

        _inventoryStocks.forEach((_inventoryStock) => {
          if (!_inventoryStocksByInventoryId.hasOwnProperty(_inventoryStock.inventoryId)) {
            _inventoryStocksByInventoryId[_inventoryStock.inventoryId] = [_inventoryStock];
          } else {
            _inventoryStocksByInventoryId[_inventoryStock.inventoryId].push(_inventoryStock);
          }
        });
      }

      if (Object.keys(_inventoryStocksByInventoryId).length !== 0) {
        this.inventoryStocksByInventoryId = _inventoryStocksByInventoryId;
      }
    });
  }

  populateStockAvailabilityDialog(inventoryId: number) {
    this._inventoryStockFormArray = InventoryStockComponent.setArray(
      this.fb,
      this.inventoryStocksByInventoryId[inventoryId]
    );
  }

  get tableScrollHeight() {
    // calc(90vh - 45pt)
    return WebHelpers.isMobileScreen() ? "330pt" : "316pt";
  }

  get _isMobile() {
    return WebHelpers.isMobileScreen();
  }

  getLineTaxAcronyms(saleLine: SaleDocLine) {
    return InvoiceHelpers.GetCombinedTaxAcronyms(saleLine);
  }

  inventoryVariantsStr(_inventory: Inventory): string {
    return _inventory.inventoryVariants
      ?.map((_inventoryVariant) => _inventoryVariant.inventoryOptionsSettingValue?.optionValue)
      .join(", ");
  }

  get hasScrollbars() {
    const linesEl = this.saleLinesWrapper.nativeElement;
    return linesEl.scrollHeight > linesEl.clientHeight;
  }

  getTaxFontColor() {
    if (this.staticGroupedTaxLines.findIndex((row) => row.saleTaxRule === null) !== -1) {
      return "red";
    } else {
      return "black";
    }
  }

  isTaxConflict() {
    return this.staticGroupedTaxLines.findIndex((row) => row.saleTaxRule === null) !== -1;
  }

  addTax(tax) {
    let selectedTax = tax.taxes[0];

    this.dbService
      .getRows("tax", JSON.stringify({ shortDesc: { value: selectedTax.saleChannelTax.name, matchMode: "equals" } }))
      .subscribe((_taxes) => {
        if (_taxes.count === 1) {
          this._dialogContent = `Tax Name and / or Tax Rule ${_taxes.rows[0].shortDesc} is disabled. Both need to be enabled from within Business Settings to proceed.`;
          this._activeDialog = true;
          // this.messageService.add({
          //   summary: "Online Tax and Tax Rule",
          //   severity: "error",
          //   detail: `Tax name ${_taxes.rows[0].shortDesc} is disabled and you need to enable it to proceed!`,
          //   life: 6000
          // })
        } else if (_taxes.count === 0) {
          const _tax = new Tax();
          _tax.shortDesc = selectedTax.saleChannelTax.name;
          _tax.isOnlineTax = true;
          _tax.isTaxIncluded = selectedTax.saleChannelTax.includeInPrice;
          this.dbService.addRow("tax", _tax).subscribe((_newTax) => {
            const _taxRule = new TaxRule();
            _taxRule.taxId = _newTax.id;
            _taxRule.isOnlineTax = true;
            _taxRule.priority = 127;
            _taxRule.taxRate = selectedTax.saleChannelTax.value;
            _taxRule.ruleName = selectedTax.saleChannelTax.name;
            this.dbService.addRow("taxRule", _taxRule).subscribe((_newTaxRule) => {
              selectedTax.taxRuleId = _newTaxRule.id;
              selectedTax.taxRule = _newTaxRule;
              delete selectedTax.saleChannelTax;
              tax.saleTaxRule = _newTaxRule;
              this.messageService.add({
                summary: "Online Tax and Tax Rule",
                severity: "success",
                detail: `Online tax and tax rule is set successfully!`,
                life: 6000,
              });
              this.taxAdded.emit();
            });
          });
        } else {
          this.dbService
            .getRows(
              "taxRule",
              JSON.stringify({
                isOnlineTax: { value: true, matchMode: "equals" },
                taxRate: { value: selectedTax.saleChannelTax.value, matchMode: "equals" },
                isEnabled: { value: true, matchMode: "equals" },
                taxId: { value: _taxes.rows[0].id, matchMode: "equals" },
              })
            )
            .subscribe((_taxRules) => {
              if (_taxRules.count === 0) {
                const _taxRule = new TaxRule();
                _taxRule.taxId = _taxes.rows[0].id;
                _taxRule.isOnlineTax = true;
                _taxRule.priority = 127;
                _taxRule.taxRate = selectedTax.saleChannelTax.value;
                _taxRule.ruleName = selectedTax.saleChannelTax.name;
                this.dbService.addRow("taxRule", _taxRule).subscribe((_newTaxRule) => {
                  selectedTax.taxRuleId = _newTaxRule.id;
                  selectedTax.taxRule = _newTaxRule;
                  delete selectedTax.saleChannelTax;
                  tax.saleTaxRule = _newTaxRule;
                  this.messageService.add({
                    summary: "Online Tax Rule",
                    severity: "success",
                    detail: `Online tax rule is set successfully!`,
                    life: 6000,
                  });
                  this.taxAdded.emit();
                });
              }
            });
        }
      });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
