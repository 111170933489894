/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Location } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Component, Input, ViewChild } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { FormHeaderDisplayMode } from "src/app/forms/form-header/form-header.component";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { DBService } from "src/app/shared/services/db.service";
import { FileSystemBackendService } from "src/app/shared/services/FileSystemBackend.service";
import { GenericFormComponent } from "../../../../forms/generic-form/generic-form.component";
import { AddressLocationType } from "../../../contact-accounts/address/address";
import { AddressComponent } from "../../../contact-accounts/address/address.component";
import { BusinessDetail, Business_Type } from "./business-detail";
import { environment } from "src/environments/environment";
import { FileUpload } from "primeng/fileupload";

@Component({
  selector: "taku-business-detail",
  templateUrl: "./business-detail.component.html",
  styleUrls: ["./business-detail.component.scss"],
})
export class BusinessDetailComponent extends GenericFormComponent {
  @Input() insideWizard = false;
  @Input() customHeaderTitle: string;
  @ViewChild("fileUploader") _fileUploader: FileUpload;

  enum_business_type = this.dbService.enumSelectOptions(Business_Type);
  FormHeaderDisplayMode = FormHeaderDisplayMode;
  fetchingDefaultLogo = false;

  constructor(
    protected _router: Router,
    public fb: UntypedFormBuilder,
    public dbService: DBService,
    protected location: Location,
    public _route: ActivatedRoute,
    protected messageService: MessageService,
    protected alertMessage: AlertMessagesService,
    protected confirmationService: ConfirmationService,
    appSettings: AppSettingsStorageService,
    protected authService: AuthService,
    private fileSystemBackendService: FileSystemBackendService,
    private httpClient: HttpClient
  ) {
    super(_router, fb, dbService, location, _route, messageService, alertMessage, confirmationService, appSettings);

    this.subsList.push(
      this.changedForm.subscribe((businessDetail) => {
        appSettings.updateBusinessDetails(businessDetail);
      })
    );
  }

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    const tmpModel = new BusinessDetail();
    const tmpForm = fb.group(tmpModel);
    tmpForm.setControl("address", AddressComponent.init(fb, AddressLocationType.commercial));
    return tmpForm;
  }

  clearImage() {
    this.logoUrlCtrl.setValue(null);
    this.urlLogoUrlCtrl.setValue(null);
    this.logoUrlCtrl.markAsDirty();
  }

  myUploader(event) {
    // get signedUrl
    event.files.map((_file) => {
      if (_file.size <= 2 * 1024 * 1024) {
        this.subsList.push(
          this.dbService._getRequest<any>(environment.apiUrl + "/uploadURL/" + _file.name).subscribe(
            (response: any) => {
              this.subsList.push(
                this.httpClient.put(response.url, _file).subscribe(
                  (res) => {
                    this.logoUrlCtrl.setValue(response.fileName);
                    this.urlLogoUrlCtrl.setValue(response.bucketPath + "/" + response.fileName);
                    this.logoUrlCtrl.markAsDirty();
                  },
                  (err) => {}
                )
              );
            },
            (err) => {}
          )
        );
      } else {
        this.messageService.add(this.alertMessage.getMessage("max-upload-size-warning"));
        this._fileUploader.clear();
      }
    });
  }

  get logoUrlCtrl(): AbstractControl {
    return this._myForm.get("BusinessDetailBusinessLogoUrl");
  }

  get urlLogoUrlCtrl(): AbstractControl {
    return this._myForm.get("urlBusinessDetailBusinessLogoUrl");
  }

  get logoImageUrl() {
    return this._myForm.value.urlBusinessDetailBusinessLogoUrl;
  }

  initLookups() {}

  loadObject() {
    this.subsList.push(
      this.dbService.getRow(this._model, 1).subscribe(
        (myObject) => {
          this._id = 1;
          this._object = myObject;
          super.loadObject();
        },
        (error) => {
          this._id = 0;
          super.loadObject();
          this.setupDefaultLogo();
        },
        this.setupDefaultLogo
      )
    );
  }

  setupDefaultLogo() {
    // If there's no existing logo, load the default image
    if (this._object && !this._object.BusinessDetailBusinessLogoUrl && !this.fetchingDefaultLogo) {
      this.fetchingDefaultLogo = true;
      fetch("/assets/layout/images/retail_tag.png")
        .then((res) => res.blob())
        .then((blob) => {
          this._fileUploader.files = [new File([blob], "default_logo.png", { type: blob.type })];
          this._fileUploader.upload();
        });
    }
  }

  onSave(): void {
    super.onSave();
    this.appSettingsService.updateBusinessDetails(this._object);
  }

  initForm() {
    this._model = "businessDetail";
    this._object = new BusinessDetail();
    this._myForm = BusinessDetailComponent.init(this.fb);
  }

  initValidation() {
    this._validation = {
      businessName: Validators.required,
      businessType: Validators.required,
      defaultCurrencyIsoCode: Validators.required,
      businessTimeZone: Validators.required,
    };

    this.applyValidationToForm(
      {
        line1: Validators.required,
        countryIsoCode: Validators.required,
        subDivisionIsoCode: Validators.required,
        city: Validators.required,
        postalCode: Validators.required,
      },
      <UntypedFormGroup>this._myForm.get("address")
    );
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
