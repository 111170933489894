/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { ArrayFormComponent } from "src/app/forms/array-form/array-form.component";
import { FormDataHelpers } from "src/app/utility/FormDataHelpers";
import { Address, AddressType } from "../address/address";
import { PersonAddress } from "../person-address/person-address";
import { PersonAddressComponent } from "../person-address/person-address.component";

@Component({
  selector: "taku-person-address-array",
  templateUrl: "./person-address-array.component.html",
  styleUrls: ["./person-address-array.component.scss"],
})
export class PersonAddressArrayComponent extends ArrayFormComponent {
  setArray(addresses: PersonAddress[]) {
    return PersonAddressComponent.setArray(this.fb, addresses);
  }

  ngOnInit() {
    // Order addresses
    const billingAddresses: AbstractControl[] = [];
    const shippingAddresses: AbstractControl[] = [];
    for (let i = 0; i < this._formArray.length; i++) {
      const addressForm = this._formArray.at(i);
      if ((<Address>addressForm.value.address).addressType === AddressType.billingAddress)
        billingAddresses.push(addressForm);
      else shippingAddresses.push(addressForm);
    }

    // Remove all elements from the form array
    FormDataHelpers.EmptyFormArray(this._formArray);
    // Combines the addresses for the two types
    const allAddresses = billingAddresses.concat(shippingAddresses);
    // Add the address in the desired order (Billing first, then Shipping)
    allAddresses.forEach((addressForm) => this._formArray.push(addressForm));
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
