<taku-form-header
  toolbarTitle="Select Parent for New Location"
  displayMode="inline"
  [showActionBtns]="false"
  [showBackBtn]="false"
></taku-form-header>

<div class="col-12 drop-container">
  <taku-dropdown
    [formGroup]="_formGroup"
    [options]="_lookup_locationGroups"
    fieldName="locationGroup"
    fieldCaption="GMB Account/Location Group"
  ></taku-dropdown>
</div>

<div class="note">Note: The new location will be created under the selected GMB Account or Location Group</div>

<div class="grid">
  <button pButton label="CANCEL" class="p-button-secondary col-6" (click)="onCancelPressed()"></button>
  <button
    pButton
    label="ACCEPT"
    class="col-6"
    (click)="onAcceptPressed()"
    [disabled]="!ctrlLocationGroup.value"
  ></button>
</div>
