/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Zone } from "../../zone-settings/Zone";
import { Transaction_Type } from "src/app/shared/services/transaction-types";
import { PaymentTerminal } from "../../integration-settings/gateway-terminals/payment-terminal";
import { TakuPaymentTerminal } from "../../integration-settings/taku-pay/taku-payment-gateway-terminals/taku-payment-terminal";

export class TenderType {
  id: number;
  type: Tender_Type;
  otherType: Other_Type;
  description: string;
  currencyIsoCode: string;
  zoneId: number;
  zone: Zone;
  transactionTypes: {
    /** Always 0 apparently */
    id: number;
    transactionType: Transaction_Type;
  }[];
  percentageFee: number;
  flatFee: number;
  paymentTerminalId?: number;
  paymentTerminal?: PaymentTerminal;
  paymentGatewayShortname?: string;
  isEnabled: boolean;
  isIntegrated: boolean;
  takuPaymentTerminalId?: number;
  takuPaymentTerminal?: TakuPaymentTerminal;
  takuPaymentGatewayShortname?: string;

  constructor() {
    this.id = 0;
    this.type = Tender_Type.Cash;
    this.otherType = null;
    this.description = "";
    this.currencyIsoCode = null;
    this.zoneId = 0;
    this.zone = null;
    this.transactionTypes = null;
    this.percentageFee = 0;
    this.flatFee = 0;
    this.paymentTerminalId = 0;
    this.paymentTerminal = null;
    this.paymentGatewayShortname = null;
    this.isEnabled = true;
    this.isIntegrated = false;
    this.takuPaymentTerminalId = 0;
    this.takuPaymentTerminal = null;
    this.takuPaymentGatewayShortname = null;
  }
}

export enum Tender_Type {
  Cash = "Cash",
  Digital_Token = "Digital Token",
  Check_Cheque = "Check / Cheque",
  Fund_Transfer_Bank = "Fund Transfer Bank",
  Fund_Transfer_Processor = "Fund Transfer Processor",
  Store_Financing = "Store Financing",
  Debit_Card = "Debit Card",
  Credit_Card = "Credit Card",
  Prepaid_Funds = "Prepaid Funds",
  Store_Voucher = "Store Voucher",
  Store_Credit = "Store Credit",
  Online_Payment = "Online Payment",
}

export enum Other_Type {
  Interac = "interac(Debit)",
  Visa = "Visa",
  MC = "MC",
  MCMaestro = "MC-Maestro",
  Amex = "Amex",
  JCB = "JCB",
  Discover = "Discover",
  Debit = "Debit",
}

export const Other_Type_Labels = {
  Interac: "Interac",
};

export class CardNotPresentDetails {
  accountId: number;
  cardNotPresentType: Card_Not_Present_Type;
  constructor(accountId: number, cardNotPresentType: Card_Not_Present_Type) {
    this.accountId = accountId;
    this.cardNotPresentType = cardNotPresentType;
  }
}

export enum Card_Not_Present_Type {
  SavedCards = "SavedCards",
  ManualEntry = "ManualEntry",
}

export class TenderTypeTransactionType {
  id: number;
  transactionType: Transaction_Type;

  constructor(transactionType: Transaction_Type = null) {
    this.id = 0;
    this.transactionType = transactionType;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
