/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ValidatorFn, Validators, AbstractControl, UntypedFormGroup } from "@angular/forms";
import { Col, DataType, FilterType, FormControlConfigurator } from "../../../form-list/form-list/form-list";
import { FormListModel } from "../../../form-list/form-list/form-list-model.interface";
import { AuthService } from "../../../shared/services/auth.service";
import { CurrencyFormatterService } from "../../../shared/services/currency-formatter.service";
import { DBService } from "../../../shared/services/db.service";
import { TaxLocationBasis, TaxPriceBasis, TaxTotal } from "./tax";

class TaxTotalValidationHandler implements FormControlConfigurator {
  setupControl(formControl: AbstractControl, formGroup: UntypedFormGroup) {
    this.applyDynamicValidation(formControl.value, formGroup);

    formControl.valueChanges.subscribe((newValue) => {
      this.applyDynamicValidation(newValue, formGroup);
    });
  }

  private applyDynamicValidation(taxTotal, formGroup) {
    const taxPriceBasisCtrl = formGroup.get("taxPriceBasis");
    if (taxTotal === TaxTotal.byLine) taxPriceBasisCtrl.setValidators(Validators.required);
    else taxPriceBasisCtrl.clearValidators();

    taxPriceBasisCtrl.updateValueAndValidity();
  }
}

@Injectable({
  providedIn: "root",
})
export class TaxService extends DBService implements FormListModel {
  constructor(
    private currencyService: CurrencyFormatterService,
    protected http: HttpClient,
    protected authService: AuthService
  ) {
    super(http, authService);
  }
  taxTotalValidationHandler = new TaxTotalValidationHandler();
  getValidationRules(): { [key: string]: ValidatorFn[] | {} } {
    return {
      // taxAcronym: [Validators.required, Validators.maxLength(2)],
      shortDesc: [Validators.required],
      taxAccountNo: [Validators.required],
      fundReceiver: [Validators.required],
      currencyIsoCode: [Validators.required],
      taxTotal: [Validators.required],
    };
  }

  get_enumTaxBase(): any[] {
    return this.enumSelectOptions(TaxPriceBasis);
  }
  get_enumTaxTotal(): any[] {
    return this.enumSelectOptions(TaxTotal);
  }
  get_enum_tax_location_basis(): any[] {
    return this.enumSelectOptions(TaxLocationBasis);
  }

  taxInfo(): string {
    return `<div class="tooltip-styling"><h5>Definition</h5>
    <ul>
    <li>Tax Name is the name for a main type of tax that shares the same Tax Number and Tax Receiver</li>
    <li>For example HST, GST, VAT, etc.</li>
    <li>If you need to add several taxes that share the same Tax Number and Tax Receiver but have different tax rates, please add only 1 Tax Name and set up separate Tax Rules for each rate.</li>
    </ul>
    </div>`;
  }

  public headerInfo() {
    return `<div class="tooltip-styling"><h5>What are Sales Taxes?</h5>
    <ul>
    <li>Sales Tax settings in TAKU are used to setup every tax (e.g. NYC sales taxes) that is tracked, reported and paid to an individual Tax Receiver (e.g. CRA)</li>
    <li>Sales tax rates are setup in Tax Rules in TAKU</li>
    <li>For step-by-step instructions on how to setup Sales Taxes, visit our help portal <a href="https://help.takulabs.io/how-to-get-started/general-settings/taxes/creating-sales-taxes" target="_blank">here</a></li>

  </ul></div>
    `;
  }

  getFormListColumns(): Col[] {
    return [
      {
        field: "isEnabled",
        header: "Active",
        visible: true,
        readonly: false,
        frozen: true,
        dataType: DataType.checkbox,
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.enum,
      }, // Have to be changed to toggle
      // { field: 'taxAcronym', header: 'Printer Acronym', visible: true, readonly: false, frozen: false, dataType: DataType.input, dataOptions: [], filterType: FilterType.contains, colWidth: 115 },
      {
        field: "shortDesc",
        header: "Tax Name",
        info: this.taxInfo(),
        visible: true,
        readonly: false,
        frozen: true,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "taxAccountNo",
        header: "Tax Number",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "isOnlineTax",
        header: "Online Tax",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.checkbox,
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.enum,
      },
      {
        field: "printOnReceipt",
        header: "Receipt Printing",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.checkbox,
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.enum,
      },
      {
        field: "longDesc",
        header: "Tax Description",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "fundReceiver",
        header: "Tax Receiver",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "currencyIsoCode",
        header: "Currency",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.currencyService.getCurrencyCodeList(),
        filterType: FilterType.enum,
        colWidth: 120,
      },
      {
        field: "applyEffectiveDate",
        header: "Apply Effective Date",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.checkbox,
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.enum,
      },
      {
        field: "effectiveDate",
        header: "Effective Date",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.date_date_only,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "endDate",
        header: "End Date",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.date_date_only,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      // { field: 'isTaxIncluded', header: 'Tax Included', visible: true, readonly: false, frozen: false, dataType: DataType.checkbox, dataOptions: Col.boolean_filter_enum, filterType: FilterType.enum },
      {
        field: "taxTotal",
        header: "How to Calculate",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.get_enumTaxTotal(),
        filterType: FilterType.enum,
        setupFormControl: this.taxTotalValidationHandler,
        colWidth: 150,
      },
      {
        field: "taxPriceBasis",
        header: "Calculate Based On",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.get_enumTaxBase(),
        filterType: FilterType.enum,
        colWidth: 150,
      },
      {
        field: "isFlatTax",
        header: "Flat Tax",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.checkbox,
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.enum,
      },
      {
        field: "isRefundable",
        header: "Can Refund",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.checkbox,
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.enum,
      },
      {
        field: "isRecoverable",
        header: "Can Recover",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.checkbox,
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.enum,
      },
      {
        field: "isShippingIncluded",
        header: "Shipping Included",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.checkbox,
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.enum,
      },
      {
        field: "taxLocationBasis",
        header: "Tax Basis",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.get_enum_tax_location_basis(),
        filterType: FilterType.enum,
      },
      {
        field: "isCompound",
        header: "Compound Tax",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.checkbox,
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.enum,
        colWidth: 120,
      },
      {
        field: "compoundSeq",
        header: "Compund Sequence",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 120,
      },
    ];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
