/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Validators } from "@angular/forms";
import { Col, DataType, FilterType } from "../../form-list/form-list/form-list";
import { FormListModel } from "../../form-list/form-list/form-list-model.interface";
import { AuthService } from "../../shared/services/auth.service";
import { DBService, ExtendedSelectItem, LookupOptions } from "../../shared/services/db.service";
import { Observable, of } from "rxjs";
import { Store } from "../settings/store-settings/store/store";
import { SelectItem } from "primeng/api";
import { FilterRows } from "src/app/utility/FormDataHelpers";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { CommonValidators } from "src/app/shared/validators/CommonValidators";
import { ApiListResponse, ValidatorMapType } from "src/app/utility/types";
import { map, take } from "rxjs/operators";
import { Promotion } from "./promotion";
import { Zone } from "../settings/zone-settings/Zone";
import { Inventory } from "../inventory/inventory/inventory";
import { cloneDeep } from "lodash";

@Injectable({
  providedIn: "root",
})
export class PromotionService extends DBService implements FormListModel {
  _model = "promotion";

  constructor(
    protected http: HttpClient,
    protected authService: AuthService,
    private appSettings: AppSettingsStorageService
  ) {
    super(http, authService);
  }

  /**
   * Returns a promotion for a given item in case it exists. This method
   * already handles "category promotion priority" and validates date range.
   */
  getPromotionalItem(item: Inventory): Observable<Promotion | null> {
    return this.getPromotionsForStore().pipe(
      take(1),
      map((promotions) => {
        const itemCategoryIds = item.categories.map((c) => c.id);
        // since the api return is already ordered by priority, it takes the highest priority
        const foundPromotion = promotions.find((p) => itemCategoryIds.includes(p.categoryId));
        if (!foundPromotion) {
          return null;
        }

        const discount = cloneDeep(foundPromotion);

        // handles max discount ammount for item
        if (item.allowDiscount && Number(discount.discountPercentage) > Number(item.maxDiscountPercentage)) {
          discount.discountPercentage = item.maxDiscountPercentage;
        }
        return discount;
      })
    );
  }

  /**
   * Returns all available promotion for a given store ordered
   * by priority.
   */
  getPromotionsForStore(storeIdParam?: number): Observable<Promotion[]> {
    const storeId = storeIdParam ?? this.appSettings.getStoreId();
    if (!storeId) {
      console.warn("Cannot get active promotions without logging in as store.");
      return of([] as Promotion[]);
    }

    const filter: FilterRows<Promotion> = {
      "stores.id": { matchMode: "equals", value: storeId },
      isActive: { matchMode: "equals", value: true },
    };

    return this.getRows<ApiListResponse<Promotion>>("promotion", JSON.stringify(filter), null, 500, "priority", 1, {
      activeForStoreId: storeId,
    }).pipe(map((res) => res.rows));
  }

  lookup_zones(): Observable<ExtendedSelectItem<number, Zone>[]> {
    const options: LookupOptions = { enableFieldName: "isActive" };

    if (!this.appSettings.isAdminLogin()) {
      const filter: FilterRows<Zone> = { id: { matchMode: "equals", value: this.appSettings.getZoneId() } };
      options.lookupFilter = JSON.stringify(filter);
    }
    return this.lookupSelectOptions("zone", "zoneName", options);
  }

  lookup_categories(): Observable<SelectItem[]> {
    return this.lookupSelectOptions("category", "categoryName", { enableFieldName: "isEnabled" });
  }

  lookup_stores(): Observable<SelectItem<number>[]> {
    const options: LookupOptions = { enableFieldName: "isActive" };

    if (!this.appSettings.isAdminLogin()) {
      const filter: FilterRows<Store> = { zoneId: { matchMode: "equals", value: this.appSettings.getZoneId() } };
      options.lookupFilter = JSON.stringify(filter);
    }
    return this.lookupMultiSelectOptions("store", "storeName", options);
  }

  getValidationRules(): ValidatorMapType<Promotion> {
    return {
      name: [Validators.required],
      priority: [Validators.required, Validators.min(1), Validators.max(127)],
      canCombineDiscount: [Validators.required, Validators.min(0)],
      categoryId: [Validators.required, CommonValidators.lookup_singleSelect()],
    };
  }

  getFormListColumns(): Col[] {
    return [
      {
        field: "isActive",
        header: "Active",
        visible: true,
        readonly: false,
        frozen: true,
        dataType: DataType.checkbox,
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.enum,
        colWidth: 120,
      },
      {
        field: "name",
        header: "Name",
        visible: true,
        readonly: false,
        frozen: true,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 160,
      },
      {
        field: "categoryId",
        header: "Inventory Categories",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.lookup,
        dataOptions: this.lookup_categories(),
        filterType: FilterType.lookup,
        sortFields: ["category.categoryName"],
        colWidth: 160,
      },
      {
        field: "priority",
        header: "Priority",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 120,
      },
      {
        field: "discountPercentage",
        header: "Discount (%)",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 140,
      },
      {
        field: "canCombineDiscount",
        header: "Can Combine Discount",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.number,
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.contains,
        colWidth: 140,
      },
      {
        field: "effectiveDate",
        header: "Effective Date",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.date_date_only,
        dataOptions: [],
        filterType: FilterType.none,
      },
      {
        field: "endDate",
        header: "End Date",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.date_date_only,
        dataOptions: [],
        filterType: FilterType.none,
      },
      {
        field: "note",
        header: "Note",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 160,
      },
    ];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
