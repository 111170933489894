/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Location } from "@angular/common";
import { Component, EventEmitter, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from "lodash";
import { ConfirmationService, SelectItem } from "primeng/api";
import { MessageService } from "primeng/api";
import { startWith } from "rxjs/operators";
import { AppComponent } from "src/app/app.component";
import { DBMode, GenericFormComponent } from "../../../forms/generic-form/generic-form.component";
import { AppSettingsStorageService } from "../../../shared/app-settings-storage.service";
import { AlertMessagesService } from "../../../shared/services/alert-messages.service";
import { CurrencyFormatterService } from "../../../shared/services/currency-formatter.service";
import { Tax } from "../tax/tax";
import { TaxRule } from "./tax-rule";
import { TaxRuleService } from "./tax-rule.service";

@Component({
  selector: "taku-tax-rule",
  templateUrl: "./tax-rule.component.html",
  styleUrls: ["./tax-rule.component.scss"],
})
export class TaxRuleComponent extends GenericFormComponent {
  // @ViewChild('postalCode') postalCodeComponent: TakuPostalCodeComponent; // Get access to postal code component for the validator
  @Output() dialogClosed = new EventEmitter<any>();

  // Define lookup Variables
  private taxesInfo: Tax[];
  lookup_taxes: SelectItem[];
  lookup_taxInventoryCategories$ = this.dbService.get_lookup_tax_inventory_category();
  lookup_taxAccountCategories$ = this.dbService.get_lookup_tax_account_category();
  enum_tax_calc_type = this.dbService.get_tax_calc_type();

  enum_subdivisions: SelectItem[] = [];
  rateCurrency: any;
  isFlatTax = false;
  _timeBasedField: UntypedFormControl;
  _countryField: UntypedFormControl;

  constructor(
    _router: Router,
    fb: UntypedFormBuilder,
    public dbService: TaxRuleService,
    location: Location,
    _route: ActivatedRoute,
    messageService: MessageService,
    alertMessage: AlertMessagesService,
    confirmationService: ConfirmationService,
    appSettings: AppSettingsStorageService,
    private _currencyService: CurrencyFormatterService
  ) {
    super(_router, fb, dbService, location, _route, messageService, alertMessage, confirmationService, appSettings);
  }

  ngOnInit() {
    super.ngOnInit();
    this.isFlatTax = this.checkedAndIsFlatTax();
  }

  loadObject() {
    super.loadObject();

    // Watch control's behaviour
    this.setupFormControls();
  }

  closeSidebar() {
    this.dialogClosed.emit();
  }

  get taxIdCtrl() {
    return this._myForm.get("taxId");
  }

  setupFormControls() {
    this._timeBasedField = <UntypedFormControl>this._myForm.get("isTimeLimited");
    this._countryField = <UntypedFormControl>this._myForm.get("countryIsoCode");

    // Listen for changes in Tax Name
    this.subsList.push(
      this.taxIdCtrl.valueChanges.subscribe((taxId) => {
        this._updateTaxRateControl(taxId);
      })
    );
    // Listen for changes on Time Based checkbox
    const timeControlNames = ["timeFrom", "timeTo"];
    this.subsList.push(
      this._timeBasedField.valueChanges
        .pipe(startWith(this._myForm.get("isTimeLimited").value))
        .subscribe((timeBasedEnabled) => {
          timeControlNames.forEach((controlName) => {
            const formControl = this._myForm.get(controlName);
            timeBasedEnabled ? formControl.enable() : formControl.disable();
          });
        })
    );

    if (this._countryField.value) {
      this.enum_subdivisions = this.dbService.get_lookup_subdivisions(this._countryField.value);
    }

    this.subsList.push(
      this._countryField.valueChanges.subscribe((newCountryIsoCode) => {
        this._myForm.get("subDivisions").setValue(null);
        this.enum_subdivisions = this.dbService.get_lookup_subdivisions(newCountryIsoCode);
      })
    );
  }

  _updateTaxRateControl(taxId) {
    if (!this.taxesInfo) return;

    const taxData = this.taxesInfo.find((theTax: Tax) => theTax.id === taxId);

    if (taxData) {
      this.rateCurrency = this._currencyService.getCurrency(taxData.currencyIsoCode);
      this.isFlatTax = taxData.isFlatTax;
    }
  }

  initLookups() {
    // Assign the whole object (tax data) as select item's value
    this.subsList.push(
      this.dbService
        .lookupSelectOptions("tax", "shortDesc", { dataKey: null, enableFieldName: "isEnabled" })
        .subscribe((items) => {
          this.taxesInfo = items.map((item) => item.value).filter((value) => !!value);
          this.lookup_taxes = items.map((item) => Object.assign(item, { value: _.get(item.value, "id", null) }));

          this._updateTaxRateControl(this.taxIdCtrl.value);
        })
    );
  }

  initForm() {
    this._model = "taxRule";
    this._object = new TaxRule();
    this._myForm = this.fb.group(new TaxRule());
  }

  initValidation() {
    // this._validation = {...this.taxRuleService.getValidationRules(),
    //   postalCodes: AddressValidators.postalCodeValidator(this._addressValidationService, this.postalCodeComponent),
    // };
    this._validation = this.dbService.getValidationRules();
  }

  onSave() {
    if (this.dbMode !== DBMode.insert && this._myForm.get("ruleName").dirty) {
      this.confirmationService.confirm({
        header: "Warning",
        message: `Please note that you are trying to change a tax that has been used in the past. This will change any historical documents or reports that have used this tax.

        Would you still like to proceed?`,
        accept: () => {
          super.onSave();
        },
      });
    } else {
      super.onSave();
    }
  }

  clearField(fieldName: string) {
    this._myForm.controls[fieldName].reset();
  }

  checkedAndIsFlatTax(): boolean {
    return this._object.tax.isFlatTax;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
