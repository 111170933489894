/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

export class AddressEmail {
  id: number;
  emailType: AddressEmailType;
  email: string;

  constructor() {
    this.id = 0;
    this.emailType = AddressEmailType.personal;
    this.email = "";
  }
}

export enum AddressEmailType {
  personal = "Personal",
  work = "Work",
  other = "Other",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
