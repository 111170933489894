<taku-form-list
  *ngIf="_formListCols && _formListFilter"
  [_model]="_formListModel"
  [_showHeader]="false"
  _detailMode="n"
  [_filter]="_formListFilter"
  [_cols]="_formListCols"
  _scrollHeight="full"
  [_hideAddLineHeaderBtn]="false"
  [_hideAddHeaderBtn]="false"
  [_showDeleteCol]="false"
  [_bulkEditorEnabled]="false"
  [_hideFilterControls]="false"
  [_saveFormState]="false"
></taku-form-list>

<ng-template #terminalPairingTpl let-rowData>
  <!-- <button pButton [label]="rowData.isPaired ? 'Settle' : 'Settle'"  -->
  <button
    pButton
    [label]="'View Totals'"
    class="pairing-btn p-button-secondary"
    (click)="onColOpenTotalBtnClicked(rowData)"
    [loading]="_settleBtnRunning[rowData.id]"
  >
  </button>
</ng-template>

<h4 id="cashout_payment_breakdown">Payment Breakdown</h4>

<table id="paymentBreakdownTable-mobile" class="grid-table closingfloats-table" cellspacing="0">
  <ng-container *ngTemplateOutlet="paymentBreakdownTotals"></ng-container>
</table>

<p-table
  class="payment-breakdown grid-table col-12"
  tableStyleClass="closingbreakdowns-table"
  [value]="closingBreakdownArray.length ? closingBreakdownArray['controls'] : [null]"
  responsiveLayout="stack"
  breakpoint="640px"
>
  <ng-template pTemplate="header">
    <tr>
      <!-- <th class="blank-cell">&nbsp;</th> -->
      <th class="col-payment-type">Payment Type</th>
      <th *ngIf="showSystemColumn" class="col-system-amount">System</th>
      <th class="col-actual-amount">Reported Amount</th>
      <th *ngIf="showOverUnderCol" class="col-over-under">Over/Under (+/-)</th>
    </tr>
    <ng-container *ngTemplateOutlet="paymentBreakdownTotals"></ng-container>
  </ng-template>

  <ng-template pTemplate="body" let-closingBreakDownForm let-i="rowIndex">
    <tr *ngIf="closingBreakDownForm">
      <taku-cashout-closing-breakdown
        (onShowDetails)="onTransactionsDetails(i)"
        [formGroup]="closingBreakDownForm"
        [index]="i"
        [currencyISOCode]="_currencyISOCode"
        [isEditable]="isEditable && closingBreakDownForm.value.tenderTypeType !== 'Cash'"
        [showSystemValue]="showSystemColumn"
        [showOverUnderValue]="showOverUnderCol"
      ></taku-cashout-closing-breakdown>
    </tr>
    <tr *ngIf="!closingBreakDownForm">
      <td colspan="5" class="nopayments-col"> There are no payments to show in this cashout. </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="footer">
    <ng-container *ngTemplateOutlet="paymentBreakdownTotals"></ng-container>
  </ng-template>
</p-table>

<ng-template #paymentBreakdownTotals>
  <tr class="total-row">
    <!-- <th class="blank-cell">&nbsp;</th> -->
    <th class="col-payment-type total-label">TOTAL:</th>
    <th *ngIf="showSystemColumn" class="col-system-amount">
      <span class="p-column-title">System</span>
      {{ breakdownSystemTotal | currency : _currencyISOCode : "code" | formatNegativeNumber }}
    </th>
    <th class="col-actual-amount">
      <span class="p-column-title">Reported Amount</span>
      {{ breakdownActualTotal | currency : _currencyISOCode : "code" | formatNegativeNumber }}</th
    >
    <th *ngIf="showOverUnderCol" class="col-over-under">
      <span class="p-column-title">Over/Under (+/-)</span>
      {{ breakdownOverUnderTotal | currency : _currencyISOCode : "code" | formatNegativeNumber }}</th
    >
  </tr>
</ng-template>
