<div [formGroup]="_myForm">
  <div class="grid">
    <div class="col grid">
      <div class="col-12 status-container">
        <taku-dropdown
          [disabled]="disableForm"
          [formGroup]="_myForm"
          [options]="enum_person_social_profile_type"
          [fieldName]="'socialProfileType'"
          [fieldCaption]="'Social Type'"
          appendTo="body"
        >
        </taku-dropdown>
      </div>
      <div class="col-12">
        <taku-input
          [disabled]="disableForm"
          [formGroup]="_myForm"
          [fieldName]="'socialProfile'"
          [fieldCaption]="'Social Profile'"
        >
        </taku-input>
      </div>
    </div>
    <div class="col-fixed copy-icon-container">
      <taku-action-button
        [action]="ACTION_TYPE.COPY"
        (clickAction)="copySocialProfileToClipboard()"
      ></taku-action-button>
    </div>
  </div>
</div>
