<div class="payment-section">
  <div class="col-12 each-line">
    <span class="">Subtotal</span>
    <span>
      {{ invoiceSubTotal | currency : currencyIsoCode : "symbol-narrow" : "1.2" | formatNegativeNumber }}
    </span>
  </div>
  <!-- <div class="col-12 each-line">
    <span class="">Shipping</span>
    <span>
      {{ invoiceTotalShipping | currency:currencyIsoCode:'symbol-narrow':'1.2' | formatNegativeNumber }}
    </span>
  </div> -->
  <div *ngIf="invoiceTotalTax === 0" class="col-12 each-line">
    <span class="">Tax</span>
    <span>
      {{ invoiceTotalTax | currency : currencyIsoCode : "symbol-narrow" : "1.2" | formatNegativeNumber }}
    </span>
  </div>
  <div *ngFor="let tax of groupedTaxes" class="col-12 each-line">
    <span>
      {{ tax?.saleTaxRule?.ruleName ? tax?.saleTaxRule?.ruleName : tax?.saleTaxRule?.tax?.shortDesc }}
    </span>
    <span>
      {{ tax?.total | currency : currencyIsoCode : "symbol-narrow" : "1.2" | formatNegativeNumber }}
    </span>
  </div>
  <div *ngIf="!inPaymentPage" class="col-12 pay-btn">
    <button
      type="button"
      pButton
      pRipple
      class="p-button-lg p-button-rounded"
      (click)="onSelfCheckoutPayBtnPressed()"
      [disabled]="saleDocLines.length == 0"
    >
      <div class="btn-label-container">
        <span>PAY</span>
        <span>{{ invoiceTotal | currency : currencyIsoCode : "symbol-narrow" : "1.2" | formatNegativeNumber }}</span>
      </div>
    </button>
  </div>
  <hr *ngIf="inPaymentPage" class="fields-divider" />
  <div *ngIf="inPaymentPage" class="col-12 each-line invoiceTotal-topWrapper">
    <span class="">Total</span>
    <span class="invoiceTotal-wrapper">
      <span class="currencyCode mr-3">{{ currencyIsoCode }}</span>
      <span class="invoiceTotal">{{
        invoiceTotal | currency : currencyIsoCode : "symbol-narrow" : "1.2" | formatNegativeNumber
      }}</span>
    </span>
  </div>
</div>
