/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { DecimalPipe } from "@angular/common";
import { AppConstants } from "src/app/shared/app-constants";

export class InventoryDocFormat {
  id: number;
  docType: Inventory_Doc_Type;
  prefix: string;
  startingNumber: number | string;
  noOfDigits: number;
  suffix: string;
  zoneId: number;

  constructor() {
    this.id = 0;
    this.docType = null;
    this.prefix = "";
    this.startingNumber = null;
    this.noOfDigits = null;
    this.suffix = "";
    this.zoneId = null;
  }

  get preview() {
    const decimalPipe = new DecimalPipe(AppConstants.DEFAULT_LOCALE_ID);
    let number = decimalPipe.transform(this.startingNumber, `${this.noOfDigits}.0-0`);
    if (number) number = number.replace(/[^\d]/g, "");

    return `${this.prefix || ""}${number || this.startingNumber || ""}${this.suffix || ""}`;
  }
}

export enum Inventory_Doc_Type {
  // Inventory_Transfer_In = 'Inventory Transfer In',
  // Inventory_Transfer_Out = 'Inventory Transfer Out',
  // Inventory_Damage = 'Inventory Damage',
  Stock_Receiving = "Stock Receiving",
  Purchase_Order = "Purchase Order"
  // Inventory_Adjustment = 'Inventory Adjustment'
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
