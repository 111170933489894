/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { AppConstants } from "../app-constants";
import * as moment from "moment";
import * as _ from "lodash";

@Pipe({
  name: "dateFormat",
  standalone: true,
})
export class DateFormatPipe extends DatePipe implements PipeTransform {
  transform(date: string | Date): any {
    if (date == null) return "";

    let localTime;
    if (!_.isDate(date)) localTime = moment(date).toDate();
    else localTime = moment.utc(date).local().toDate();

    return super.transform(localTime, AppConstants.DATE_FMT);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
