/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Zone } from "../../zone-settings/Zone";

export class CashDenomination {
  id: number;
  value: number;
  cashType: Cash_Type;
  zone: Zone;
  zoneId: number;

  constructor() {
    this.id = 0;
    this.value = 0;
    this.cashType = Cash_Type.paper;
    this.zone = null;
    this.zoneId = null;
  }
}

export enum Cash_Type {
  coin = "Coin",
  paper = "Paper",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
