<taku-form-header
  displayMode="full"
  [toolbarTitle]="_pageTitle"
  iconStyleClass="material-icons"
  iconName="event_repeat"
  [showActionBtns]="false"
  [showBackBtn]="true"
  (backClicked)="goBack()"
></taku-form-header>

<div class="paneltitle-right">
  <ng-content select="*.panel-toolbar"></ng-content>
</div>

<taku-form-list
  #formList
  [ngClass]="{ pullDown: _pageTitle || _pageIconStyleClass }"
  [_model]="'recurringOrderSetting'"
  [_detailMode]="_detailMode"
  [_filter]="activeFilter"
  [_cols]="_cols"
  _title="Recurring Order Setup"
  [_settingsCol]="_settingsCol"
  [_defaultRowValues]="_defaultRowValues"
  [_extraQueryParams]="_extraQueryParams"
  [_hideAddLineHeaderBtn]="_disableAddRow"
  [_rowSelectionType]="_rowSelectionType"
  [defaultSortField]="_sortField"
  [_headerInfo]="_headerInfo"
  [_dataService]="recurringOrderSettingsService"
  [_dialogShowHeader]="false"
  _dialogStyleClass="form-list-dialog-style"
>
</taku-form-list>
