<h3>{{ qtyDetailsType === QtyDetailsType.OnOrdered ? "Open Purchase Orders" : "Upcoming Orders" }}</h3>

<p-table
  scrollHeight="300px"
  responsiveLayout="stack"
  breakpoint="640px"
  [value]="inventoryStockDetails"
  styleClass="p-datatable-striped"
>
  <ng-template pTemplate="header">
    <tr>
      <th class="col-name">Account</th>
      <th class="col-doc">{{ qtyDetailsType === QtyDetailsType.OnOrdered ? "PO#" : "Doc#" }}</th>
      <th class="col-qty">Total Qty</th>
      <th class="col-date">Ship Date</th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-inventoryStockDetail>
    <tr>
      <td class="col-name">
        <span class="p-column-title">Name:</span>
        {{ inventoryStockDetail.name }}
      </td>
      <td class="col-doc">
        <span class="p-column-title">{{ qtyDetailsType === QtyDetailsType.OnOrdered ? "PO#" : "Doc#" }}:</span>
        <!--
        <a class="underline" (click)="showSaleDocpreview(inventoryStockDetail)">{{ inventoryStockDetail.docNo }}</a>
        -->
        <p-button
          (click)="showSaleDocpreview(inventoryStockDetail)"
          label="{{ inventoryStockDetail.docNo }}"
          class="p-button-link"
          styleClass="p-button-link"
        >
        </p-button>
      </td>
      <td class="col-qty">
        <span class="p-column-title">Total Qty:</span>
        {{ inventoryStockDetail?.onOrderQty || inventoryStockDetail?.committedQty }}
      </td>
      <td class="col-date">
        <span class="p-column-title">Shipping Date:</span>
        {{ inventoryStockDetail.shippingDate | dateFormat }}
      </td>
    </tr>
  </ng-template>
</p-table>

<br />
<ng-container *ngIf="qtyDetailsType === QtyDetailsType.OnOrdered; then noteOnOrderd; else noteCommitted">
</ng-container>

<ng-template #noteOnOrderd> <p class="notes">Total Qty = Total Qty Not Yet Received </p></ng-template>

<ng-template #noteCommitted>
  <p class="notes">
    1. Total Qty = Total Qty Not Yet Fulfilled <br />
    2. Only approved Online Orders will appear
  </p>
</ng-template>
