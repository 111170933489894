<div class="itemDetailDlg-wrapper">
  <div class="item-detail-top grid">
    <div class="item-images-container col-6 lg:col-5">
      <div class="main-image-container">
        <img
          [attr.src]="
            InventoryImagePinnedToPictureGallery?.urlFileName150 || '../../../../../assets/layout/images/no-image.jfif'
          "
        />
      </div>
      <!-- <div class="mt-3 other-images-container" *ngFor="let otherImage of otherImages; index as i">
        <div class="img-container">
          <img [attr.src]="otherImage.urlFileName150">
        </div>
      </div> -->
    </div>
    <div class="other-info-container col-6 lg:col-7">
      <div class="name-and-price">
        <span class="item-name">{{ selectedInventory?.description1 || parentInventory?.description1 }}</span>
        <span *ngIf="showSalePrice" class="item-price mt-3">{{
          selectedInventory?.standardPrice || parentInventory?.standardPrice
            | currency : "CAD" : "symbol-narrow" : "1.2"
        }}</span>
      </div>
      <hr class="fields-divider" />
      <ng-container *ngIf="isParentItem">
        <div *ngFor="let inventoryOption of inventoryOptions; index as i">
          {{ inventoryOption.optionName }}
          <p-selectButton
            [options]="lookup_inventoryOptionValue(inventoryOption)"
            [(ngModel)]="selectedOpt[i]"
            (onChange)="optionChange()"
            optionLabel="value"
            optionDisabled="none"
          >
            <ng-template let-item>
              <div [ngClass]="{ disabled: item.disabled }">{{ item.value }}</div>
            </ng-template>
          </p-selectButton>
        </div>
        <div
          *ngIf="
            (items.length === 0 && !isSearching) ||
            (!isSelectedItemAvailable() && areAllOptionsSelected && !isSearching)
          "
        >
          <span class="not-available">Not Available</span>
        </div>
      </ng-container>
    </div>
    <div class="mt-1 other-images-container col-12">
      <p-galleria
        [value]="otherImages"
        style="max-height: 200px"
        [ngStyle]="{ width: otherImages.length > 0 ? '100%' : '685px' }"
        [responsiveOptions]="responsiveOptions"
      >
        <ng-template pTemplate="thumbnail" let-item>
          <div class="grid grid-nogutter justify-content-center">
            <img [src]="item.urlFileName150" />
          </div>
        </ng-template>
      </p-galleria>
      <!-- <div class="img-container" *ngFor="let otherImage of otherImages; index as i">
        <img [attr.src]="otherImage.urlFileName150">
      </div> -->
    </div>
  </div>
  <hr class="fields-divider" />
  <div #container class="item-detail-mid">
    <span class="description-label">Description</span>
    <p-scrollPanel
      id="item-longDescription-wrapper"
      [style]="{ width: '100%', height: '100%' }"
      styleClass="custombar-salesscreen"
    >
      <div [innerHTML]="longDescription" class="longDescription-container"> </div>
      <!-- {{ longDescription }} -->
      <p-scrollTop
        *ngIf="supportsScroll"
        target="parent"
        styleClass="custom-scrollTop"
        icon="pi pi-arrow-up"
      ></p-scrollTop>
    </p-scrollPanel>
  </div>
  <div class="item-detail-footer">
    <div class="footer-btns cancel-btn col-4" [ngClass]="{ 'line-height-adjust': isAddToCart }">
      <button
        autofocus
        pButton
        type="button"
        (click)="onCancelPressed()"
        label="Cancel"
        class="p-button-outlined p-button-rounded"
      ></button>
    </div>

    <div class="qty-ctrl-container grid col-4">
      <div class="col-4 remove-btn qty-btns">
        <i class="material-icons" [ngClass]="{ 'disabled-btn': disableRemoveBtn }" (click)="onRemoveQtyPressed()"
          >remove_circle_outline</i
        >
      </div>
      <div class="col-4 item-cart-qty">
        <b *ngIf="isAddOnItem">{{ updatedQty }}</b>
        <b *ngIf="!isAddOnItem">{{ isAddToCart ? qtyToAdd : updatedQty }}</b>
      </div>
      <div class="col-4 add-btn qty-btns">
        <i class="material-icons" (click)="onAddQtyPressed()">add_circle</i>
      </div>
    </div>

    <div class="footer-btns done-btn col-4">
      <button
        pButton
        type="button"
        class="p-button-rounded"
        [label]="doneButtonCaption || (isAddToCart ? 'Add to Cart' : 'Done')"
        (click)="onDoneBtnPressed()"
        [disabled]="!selectedInventory"
      ></button>
    </div>
  </div>
</div>
