/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { CurrencyPipe } from "@angular/common";
import { Inject, Injectable, LOCALE_ID } from "@angular/core";

@Injectable({ providedIn: "root" })
export class DataNormalizerService {
  constructor(@Inject(LOCALE_ID) private defaultLocale: string) {}

  currencyNormalizer(value: string | number, isoCode: string): string {
    if (value === null || value === undefined) return;
    const digitsInfo = "1.2-2"; // This specifies a minimum of 2 decimal places and a maximum of 2.
    const convertedCurrency = new CurrencyPipe(this.defaultLocale, isoCode).transform(
      value,
      isoCode,
      "symbol",
      digitsInfo
    );
    return convertedCurrency ? convertedCurrency.replace(/[^0-9.-]/g, "") : "";
  }

  normalizeNumbers(val: string | number, min = 0, max = 4): string {
    if (!val) return;
    const numVal = typeof val === "string" ? parseFloat(val) : val;
    const hasDecimals = numVal % 1 !== 0;
    return hasDecimals ? numVal.toFixed(max) : numVal.toFixed(min);
  }
}
