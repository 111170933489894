/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Input, ViewChild } from "@angular/core";
import { FormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ConfirmationService } from "primeng/api";
import { map } from "rxjs/operators";
import { NestedFormComponent } from "../../../forms/nested-form/nested-form.component";
import { AppDefaultsService } from "../../../shared/services/app-defaults.service";
import { CurrencyFormatterService } from "../../../shared/services/currency-formatter.service";
import { DBService } from "../../../shared/services/db.service";
import { LocationService } from "../../../shared/services/location.service";
import { TakuAddressValidationService } from "../../../shared/services/taku-address-validation.service";
import { AddressValidators } from "../../../shared/validators/AddressValidators";
import { TakuPostalCodeComponent } from "../../../taku-ui/taku-postal-code/taku-postal-code.component";
import { AddressEmailComponent } from "../address-email/address-email.component";
import { AddressPhoneComponent } from "../address-phone/address-phone.component";
import { Address, AddressLocationType, AddressType } from "./address";
import { FormDataHelpers } from "src/app/utility/FormDataHelpers";
import { AddressEmail } from "../address-email/address-email";
import { AddressPhone } from "../address-phone/address-phone";

@Component({
  selector: "taku-address",
  templateUrl: "./address.component.html",
  styleUrls: ["./address.component.scss"],
})
export class AddressComponent extends NestedFormComponent {
  // ngOnChanges(changes: SimpleChanges): void {
  //   if (!this._myForm.get('countryIsoCode').value){
  //     this._myForm.get('subDivisionIsoCode').disable();
  //   }
  // }

  @ViewChild(TakuPostalCodeComponent, { static: true }) postalCodeComponent: TakuPostalCodeComponent; // Get access to postal code component for the validator
  @Input() showIsDefaultControl = false;
  @Input() autosetPhoneCountry = false;
  @Input() disableForm?: boolean = false;
  @Input() showLocationTypeControl = true;
  @Input() showAttentionControl = true;
  @Input() showNoteControl = true;
  @Input() showEmailControl = true;
  @Input() showPhoneCopyControl = true;

  // Define enum variables
  enum_address_type = this.dbService.enumSelectOptions(AddressType);
  enum_address_location_type = this.dbService.enumSelectOptions(AddressLocationType);

  // Define lookup Variables
  lookup_city: any[];
  attentionVisibility = false;

  static init(
    fb: UntypedFormBuilder,
    addressLocationType: AddressLocationType = null,
    addressType?: AddressType
  ): UntypedFormGroup {
    const address_Group = fb.group(new Address(addressLocationType, addressType));
    address_Group.setControl("addressEmail", AddressEmailComponent.init(fb));
    address_Group.setControl("addressPhone", AddressPhoneComponent.init(fb));

    return address_Group;
  }

  static set(fb: UntypedFormBuilder, address: Address): UntypedFormGroup {
    const temp = fb.group(address);
    if (!address.addressEmail) {
      address.addressEmail = new AddressEmail();
    }
    if (!address.addressPhone) {
      address.addressPhone = new AddressPhone();
    }
    temp.setControl("addressEmail", AddressEmailComponent.set(fb, address.addressEmail));
    temp.setControl("addressPhone", AddressPhoneComponent.set(fb, address.addressPhone));
    return temp;
  }

  constructor(
    private _validator: TakuAddressValidationService,
    public dbService: DBService,
    private appDefaultsService: AppDefaultsService,
    public fb: UntypedFormBuilder,
    public lService: LocationService,
    public cfService: CurrencyFormatterService,
    protected confirmationService: ConfirmationService
  ) {
    super(dbService, fb);
  }

  ngOnInit() {
    super.ngOnInit();

    // if this is a new address, use the country from Business Details
    if (this._myForm.get("id").value === 0 && !this._myForm.get("countryIsoCode").value) {
      this.subsList.push(
        this.appDefaultsService.getDefaultAddressCountry().subscribe((defaultCountryCode) => {
          this._myForm.get("countryIsoCode").setValue(defaultCountryCode, { emitEvent: false });
        })
      );
    }

    this.subsList.push(
      this.countryIsoCodeCtrl.valueChanges.subscribe((countryIsoCode) => {
        this.showCountryChangeConfirmationDialog(countryIsoCode);
        // update also phone's country if parameter is set and there is no phone specified yet
        if (this.autosetPhoneCountry && !this.phoneCtrl.get("tel").value)
          this.phoneCtrl.get("countryIsoCode").setValue(countryIsoCode);
      })
    );
  }

  get locationTypeCtrl() {
    return this._myForm.get("addressLocationType");
  }

  get addressTypeCtrl() {
    return this._myForm.get("addressType");
  }

  get isDefaultCtrl() {
    return this._myForm.get("isDefault");
  }

  get line1Ctrl() {
    return this._myForm.get("line1");
  }

  get line2Ctrl() {
    return this._myForm.get("line2");
  }

  get countryIsoCodeCtrl() {
    return this._myForm.get("countryIsoCode");
  }

  get subdivisionIsoCodeCtrl() {
    return this._myForm.get("subDivisionIsoCode");
  }

  get cityCtrl() {
    return this._myForm.get("city");
  }

  get postalCodeCtrl() {
    return this._myForm.get("postalCode");
  }

  get phoneCtrl() {
    return this._myForm.get("addressPhone");
  }

  get emailCtrl() {
    return this._myForm.get("addressEmail");
  }

  get countryFullName() {
    return this.lService.lookupCountryByIsoCode(this.countryIsoCodeCtrl.value);
  }

  get subdivisionFullName() {
    return this.countryIsoCodeCtrl.value && this.subdivisionIsoCodeCtrl.value
      ? this.lService.lookupSubdivisionName(this.countryIsoCodeCtrl.value, this.subdivisionIsoCodeCtrl.value)
      : null;
  }

  private showCountryChangeConfirmationDialog(newCountry) {
    const oldCountryVal = this._myForm.value.countryIsoCode;
    if (!oldCountryVal || oldCountryVal === newCountry) {
      return;
    }
    if (!this._myForm.value.subDivisionIsoCode) {
      return;
    }

    this.confirmationService.confirm({
      message: "By changing country you will lose subzone, city and postal/zip. Are you sure?",
      accept: () => {
        this._myForm.patchValue({ subDivisionIsoCode: null, city: null, postalCode: null });
      },
      reject: () => {
        this._myForm.patchValue({ countryIsoCode: oldCountryVal });
      },
    });
  }

  initLookups() {}

  // addAddressPhone() {
  //   const addressPhone = AddressPhoneComponent.init(this.fb);
  //   if (!(this._myForm.controls['addressPhones'] instanceof FormArray)) {
  //     this._myForm.setControl('addressPhones', this.fb.array([]));
  //   }
  //   (<FormArray>this._myForm.controls['addressPhones']).push(addressPhone);
  // }

  // private _removeFormArrayChild(formArray:FormArray, index:number){
  //   formArray.removeAt(index);
  //   this._myForm.markAsDirty();
  // }

  // removeAddressPhone(index:number) {
  //   const phonesArray:FormArray = (<FormArray>this._myForm.get('addressPhones'));
  //   if (phonesArray.at(index).pristine){
  //     this._removeFormArrayChild(phonesArray, index);
  //     return;
  //   }

  //   this.confirmationService.confirm({
  //     message: 'By deleting this Phone Address you will lose your changes. Are you sure?',
  //     accept: () => {
  //       this._removeFormArrayChild(phonesArray, index);
  //     }
  //   });
  // }

  lookupCity(event: any) {
    this.subsList.push(
      this.lService
        .lookupCityOptions(this._myForm.get("subDivisionIsoCode").value, event.query)
        .pipe(
          map((cities: any[]) => {
            return cities.map((data) => data.city);
          })
        )
        .subscribe({
          next: (cities) => (this.lookup_city = cities),
          error: (error) => (this.lookup_city = []),
        })
    );
  }

  clear() {
    this._myForm.get("city").reset();
  }

  initValidation() {
    let postalCodeValdiation = [AddressValidators.postalCodeValidator(this._validator, this.postalCodeComponent)];
    if (this._myForm.get("postalCode").validator) {
      postalCodeValdiation = [this._myForm.get("postalCode").validator, ...postalCodeValdiation];
    }
    this._validation = {
      countryIsoCode: [Validators.required],
      // subDivisionIsoCode: [Validators.required],
      postalCode: postalCodeValdiation,
      // line1: [Validators.required]
    };
  }

  toggleAttentionVisibility() {
    this.attentionVisibility = !this.attentionVisibility;
  }

  get cityCaption() {
    return FormDataHelpers.InputFieldCaption("City", this.cityCtrl);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
