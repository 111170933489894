import { Component, OnInit } from "@angular/core";
import { Inventory } from "src/app/core/inventory/inventory/inventory";
import { SaleDocLine } from "../sale-doc-line/sale-doc-line";
import { SaleDocLineComponent } from "../sale-doc-line/sale-doc-line.component";
import { SaleDocSelfCheckoutWrapperBaseComponent } from "../sale-doc-self-checkout-wrapper-base/sale-doc-self-checkout-wrapper-base.component";
import * as _ from "lodash";
import { SelfCheckoutItemDetailsComponent } from "../self-checkout-item-details/self-checkout-item-details.component";
@Component({
  selector: "taku-sale-doc-self-checkout-wrapper-desktop",
  templateUrl: "./sale-doc-self-checkout-wrapper-desktop.component.html",
  styleUrls: ["./sale-doc-self-checkout-wrapper-desktop.component.scss"],
})
export class SaleDocSelfCheckoutWrapperDesktopComponent extends SaleDocSelfCheckoutWrapperBaseComponent {
  onDocReset() {}

  onSalesLinesCleared() {}

  // this is desktop only
  onSelectItem(inventory: Inventory) {
    if (inventory.isVariantParent) {
      const saleLine: SaleDocLine = _.merge(new SaleDocLine(), {
        unitPrice: inventory.standardPrice,
        unitCost: inventory.defaultInventoryZone?.standardCost || 0,
        qty: 1,
        // TODO: Fill out DocLine
        docLine: {
          id: 0,
          seqNo: 1,
          note: "",
          expiryDate: null,
          serialNo: "",
          refNo: 0,
          inventoryId: inventory.id,
          inventory: inventory,
        },
      });
      const docLineForm = SaleDocLineComponent.set(this.fb, saleLine, 0);

      this.ref = this.dialogService.open(SelfCheckoutItemDetailsComponent, {
        styleClass: "selfcheckout-mobile rounded-selfcheckout-dialog",
        contentStyle: {
          overflow: "auto",
          height: "60%",
          minWidth: "280px",
          maxWidth: "680px",
          maxHeight: "1100px",
        },
        data: {
          _saleForm: docLineForm,
          _isAddToCart: true,
        },
      });

      this.subsList.push(
        this.ref.onClose.subscribe((result) => {
          if (result && result.qtySelected > 0) {
            this.docComponent.addToSaleDoc(docLineForm.value.docLine.inventory, result.qtySelected);
            // let itemIndexInCart = 0;
            // this.docComponent.saleDocLines.value.forEach((element, index) => {
            //   if (element.docLine.inventoryId === inventory.id) {
            //     itemIndexInCart = index;
            //   }
            // });
            // if (result !== undefined) {
            //   const newSaleDocLine =  this.docComponent.saleDocLines.at(itemIndexInCart);
            //   const originalQty = newSaleDocLine.get('qty').value -1;
            //   let newQty = 0;
            //   newQty = originalQty + result.qtySelected;
            //   newSaleDocLine.patchValue({ qty: newQty });
            this.saleDocDataViewComponent.autoSelectSearchField();
            // }
          }
        })
      );
    } else {
      this.docComponent.addToSaleDoc(inventory);
      this.saleDocDataViewComponent.autoSelectSearchField();
    }
  }

  onReturnToCart() {
    super.onReturnToCart();
    this.saleDocDataViewComponent?.autoSelectSearchField();
  }

  ngOnDestroy(): void {
    this.ref?.close(false);
    super.ngOnDestroy();
  }
}
