/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  inject,
} from "@angular/core";
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import * as _ from "lodash";
import { ConfirmationService, SelectItem } from "primeng/api";
import { DBService, ExtendedSelectItem } from "../../../../shared/services/db.service";
import { CommonValidators } from "../../../../shared/validators/CommonValidators";
import { InvoiceValidators } from "../../../../shared/validators/InvoiceValidators";
import { StoreSettingReturnReason } from "../../../settings/store-settings/return-reason/StoreSettingReturnReason";
import { GenericSaleDocLineComponent } from "../generic-sale-doc-line.component";
import { SaleDocLineTax } from "../sale-doc-line-tax/sale-doc-line-tax";
import { SaleDocService } from "../sale-doc/sale-doc.service";
import { InvoiceKeyboardActionsService } from "../sale-invoice-doc-base/invoice-keyboard-actions.service";
import { DiscountType, Repeat, SaleDocLine, repeatCycleToNum } from "./sale-doc-line";
import { AuthService } from "src/app/shared/services/auth.service";
import { CurrencyFormatterService } from "src/app/shared/services/currency-formatter.service";
import { AppComponent } from "src/app/app.component";
import { SaleDoc } from "../sale-doc/sale-doc";
import { merge } from "rxjs";
import { Inventory } from "src/app/core/inventory/inventory/inventory";
import {
  FrequencyType,
  RecurringOrderSetting,
  frequencyTypeToNumDays,
} from "src/app/core/settings/zone-settings/recurring-order-settings/recurring-order-settings";
import {
  FrequencyName,
  InventoryUsageDetail,
} from "src/app/core/inventory/inventory-usage-detail/inventory-usage-detail";
import moment from "moment";
import { distinctUntilChanged, skip, tap, map, take } from "rxjs/operators";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { FilterRows } from "src/app/utility/FormDataHelpers";
import { TakuPaymentGateway } from "src/app/core/settings/integration-settings/taku-pay/taku-payment-gateways-list/taku-payment-gateway";
import { PriceLevel } from "src/app/core/price-levels/price-level";
import { PromotionService } from "src/app/core/promotion/promotion.service";

@Component({
  selector: "taku-sale-doc-line",
  templateUrl: "./sale-doc-line.component.html",
  styleUrls: ["./sale-doc-line.component.scss"],
})
export class SaleDocLineComponent extends GenericSaleDocLineComponent implements OnInit, OnChanges {
  // @ViewChild('qtyInput', { static: true }) _qtyComponent: TakuInputComponent;

  @Input() activePriceLevel?: PriceLevel;
  @Input() returnReasons: StoreSettingReturnReason[] = [];
  @Input() set saleDoc(_saleDoc: SaleDoc) {
    this._saleDoc = _saleDoc;
    if (!_saleDoc.accountId && this._myForm.get("recurringOrderSettingId").value !== null) {
      this._myForm.get("recurringOrderSettingId").setValue(null);
      this._myForm.get("recurringOrderSetting").setValue(null);
    }
  }
  get saleDoc() {
    return this._saleDoc;
  }

  @Output() viewProductDetails: EventEmitter<number> = new EventEmitter();
  // @Output() qtyChanged:EventEmitter<number> = new EventEmitter;

  _returnReasonsEnumOptions: SelectItem[] = [];
  enum_repeat: SelectItem[] = [];
  enum_frequency_name: SelectItem[] = [];
  recurringOrderSettingOptions: ExtendedSelectItem<number, RecurringOrderSetting>[] = [];
  parent?: Inventory;
  refillFrequency = "";
  deliveryMethod = "";
  today = new Date();
  lastRepeatDate?: Date;
  nextOutgoingDate?: Date;
  takuPaymentAccountExist = false;
  recurringSubTotal = "";
  recurringDiscount?: number;
  promotionService = inject(PromotionService);

  public static readonly TAG_CLEARIDS_ONSAVE = "_TAG_ClearIDs_OnSave";

  isInventoryDetailsVisible = false;
  isInventoryDetailsEnabled = false;

  private recurringOrderSettingById: Record<number, RecurringOrderSetting>;

  constructor(
    public app: AppComponent,
    public dbService: DBService,
    public fb: UntypedFormBuilder,
    protected elementRef: ElementRef,
    keyboardActionsService: InvoiceKeyboardActionsService,
    saleDocService: SaleDocService,
    confirmationService: ConfirmationService,
    private authService: AuthService,
    protected currencyFormatterService: CurrencyFormatterService,
    private appSettings: AppSettingsStorageService
  ) {
    super(
      app,
      dbService,
      fb,
      elementRef,
      saleDocService,
      confirmationService,
      keyboardActionsService,
      currencyFormatterService
    );

    this.isInventoryDetailsVisible = authService.hasVisiblePermit("Inventory_Menu_Inventory_List");
    this.isInventoryDetailsEnabled = authService.hasEnablePermit("Inventory_Menu_Inventory_List");
  }

  ngOnInit(): void {
    super.ngOnInit();
    const tmpForm = this._myForm;
    if (tmpForm.value.docLine.inventory.allowDiscount) {
      setTimeout(() => {
        switch (tmpForm.value["discountType"]) {
          case DiscountType.Percentage:
            tmpForm
              .get("discountAmount")
              .setValidators(Validators.max(tmpForm.value.docLine.inventory.maxDiscountPercentage));
            break;
          case DiscountType.Fixed:
            tmpForm
              .get("discountAmount")
              .setValidators(
                Validators.max(Math.min(tmpForm.value.unitPrice, tmpForm.value.docLine.inventory.maxDiscountAmount))
              );
            break;
          case DiscountType.Final_Price:
            tmpForm
              .get("discountAmount")
              .setValidators(
                Validators.min(
                  tmpForm.value.unitPrice -
                    Math.min(tmpForm.value.unitPrice, tmpForm.value.docLine.inventory.maxDiscountAmount)
                )
              );
            break;
        }
        tmpForm.get("discountAmount").updateValueAndValidity();
      }, 0);
    }

    const storeId = this.appSettings.getStoreId();

    const activeFilter: FilterRows<TakuPaymentGateway> = {
      isActive: { matchMode: "equals", value: true },
      storeId: { matchMode: "equals", value: storeId },
      "takuPaymentAccount.isActive": { matchMode: "equals", value: true },
    };
    this.subsList.push(
      this.dbService
        .getRows("takuPaymentGateway", JSON.stringify(activeFilter))
        .pipe(map((result) => result.rows))
        .subscribe((result) => {
          if (result.length > 0) {
            this.takuPaymentAccountExist = true;
          }
        })
    );

    this.enum_repeat = this.saleDocService.enum_repeat;
    this.setFrequencyNameOptions(this._myForm.value.docLine.inventory);

    const recurringOrderSettingFilter = {
      zoneId: { matchMode: "equals", value: this.saleDoc.store.zoneId },
    };

    this.subsList.push(
      this.saleDocService
        .lookupRecurringOrderSetting({ lookupFilter: JSON.stringify(recurringOrderSettingFilter) })
        .pipe(
          // Create hashmap of recurring order setting by id for easier/faster retrieval of dropdown selected object
          tap((settings) => {
            this.recurringOrderSettingById = settings.reduce((recurringOrderSettingById, setting) => {
              if (setting.value) {
                recurringOrderSettingById[setting.value] = setting.object;
              }
              return recurringOrderSettingById;
            }, {} as Record<number, RecurringOrderSetting>);
          }),
          map((settings) => {
            // If there are no usage details, then we want the default option disabled
            return this.processRecurringOptionsDisabled(settings);
          })
        )
        .subscribe((settings) => {
          this.recurringOrderSettingOptions = settings;
          this.updateNextAndLastRepeatDates();
          this.updateSubTotal();
          const recurId: number = this._myForm.get("recurringOrderSettingId").value;
          if (recurId) {
            this.setRefillFrequency(recurId);
            const selectedSetting = this.recurringOrderSettingById[recurId];
            if (selectedSetting.recurringOrderFrequency === FrequencyType.DEFAULT_REFILL_FREQUENCY) {
              this._myForm.get("frequencyName").setValidators(Validators.required);
            }
          }
        })
    );

    // This was running once on component load, where there was no dropdown option value changed yet.
    // skip(1) stops the initial unnecessary component load subscribe from firing.
    this.subsList.push(
      this._myForm
        .get("recurringOrderSettingId")
        .valueChanges.pipe(skip(1), distinctUntilChanged())
        .subscribe((recurId: number) => {
          // regrading use of { emitEvent: false }, it stops the setValue from doing valueChanges on those fields.
          // This recurringOrderSettingId valueChanges is setting the defaults of these fields
          // then it runs similar function processes at the end that the frequencyName and repeatCycle valueChanges run
          // setting the { emitEvent: false } here stops cyclical firing
          let selectedSetting: RecurringOrderSetting;

          if (recurId) {
            selectedSetting = this.recurringOrderSettingById[recurId];
            this.setRefillFrequency(recurId);
            this._myForm.get("repeatCycle").setValue(Repeat.YES_ONGOING, { emitEvent: false });

            if (selectedSetting.recurringOrderFrequency === FrequencyType.DEFAULT_REFILL_FREQUENCY) {
              this._myForm.get("frequencyName").setValue(this.enum_frequency_name[1].label, { emitEvent: false });
              this._myForm.get("frequencyName").setValidators(Validators.required);
            } else {
              this._myForm.get("frequencyName").clearValidators();
              this._myForm.get("frequencyName").setValue(null, { emitEvent: false });
            }
          } else {
            this.refillFrequency = "";
            this._myForm.get("frequencyName").clearValidators();
            this._myForm.get("frequencyName").setValue(null, { emitEvent: false });
            this._myForm.get("repeatCycle").setValue(null, { emitEvent: false });
          }

          this._myForm.get("recurringOrderSetting").setValue(selectedSetting); // keeping this in sync when recurringOrderSettingId changes
          this.updateSubTotal();
          this.updateNextAndLastRepeatDates();
        })
    );

    this.subsList.push(
      // adding skip(1) as this was happening twice on component load (nothing update, was throwing undefined error in updateNextAndLastRepeatDates())
      merge(
        this._myForm.get("repeatCycle").valueChanges.pipe(skip(1), distinctUntilChanged()),
        this._myForm.get("frequencyName").valueChanges.pipe(skip(1), distinctUntilChanged())
      ).subscribe(() => {
        this.updateNextAndLastRepeatDates();
      })
    );

    this.subsList.push(this._myForm.get("qty").valueChanges.subscribe(() => this.updateSubTotal()));

    //This explictly myForm as dirty when sale doc line is added and newly added form control is not touched
    this._myForm.markAsDirty();
  }

  private setRefillFrequency(recurId: number): void {
    this.refillFrequency = this.recurringOrderSettingById[recurId].recurringOrderFrequency;
  }

  private updateNextAndLastRepeatDates = (): void => {
    const recurringOrderSettingId = this._myForm.get("recurringOrderSettingId").value as number;
    const repeat = this._myForm.get("repeatCycle").value as Repeat;

    if (!recurringOrderSettingId || !repeat) {
      this.lastRepeatDate = null;
      this.nextOutgoingDate = null;
      return;
    }

    const repeatNum = repeatCycleToNum(repeat);
    const selectedRecurring = this.recurringOrderSettingById[recurringOrderSettingId];

    if (selectedRecurring.recurringOrderFrequency === FrequencyType.DEFAULT_REFILL_FREQUENCY) {
      // If 'recurringOrderSettingId' is a defaultRefillFrequency, then calculate using inventoryUsageDetail
      const usageDetails =
        (this._myForm.get("docLine.inventory").value as Inventory).inventoryUsageDetails ??
        this.parent?.inventoryUsageDetails;
      const selectedFrequency = this._myForm.get("frequencyName").value as FrequencyName;

      if (!selectedFrequency) {
        this.lastRepeatDate = null;
        this.nextOutgoingDate = null;
        return;
      }

      const selectedUsage = usageDetails.find((d) => d.frequencyName === selectedFrequency);

      this.nextOutgoingDate = InventoryUsageDetail.calculateNextDate(selectedUsage);

      if (repeat === Repeat.YES_ONGOING) {
        this.lastRepeatDate = null;
      } else {
        this.lastRepeatDate = InventoryUsageDetail.calculateEndDate(selectedUsage, repeatNum);
      }
    } else {
      // Calculate end date using recurringOrderSetting
      const numDays = frequencyTypeToNumDays(selectedRecurring.recurringOrderFrequency);

      this.nextOutgoingDate = moment().add(numDays, "days").toDate();

      if (repeat === Repeat.YES_ONGOING) {
        this.lastRepeatDate = null;
      } else {
        this.lastRepeatDate = moment()
          .add(numDays * repeatNum, "days")
          .toDate();
      }
    }
  };

  private processRecurringOptionsDisabled(
    settings: ExtendedSelectItem<number, RecurringOrderSetting>[]
  ): ExtendedSelectItem<number, RecurringOrderSetting>[] {
    return !settings
      ? []
      : settings.map((setting) => {
          if (setting.label.toLocaleLowerCase().includes("default")) {
            setting["disabled"] = false;
            if (this.enum_frequency_name.length <= 1) {
              setting["disabled"] = true;
            }
          }
          return setting;
        });
  }

  private setFrequencyNameOptions(inventory: Inventory): void {
    let inventoryUsageDetails = inventory.inventoryUsageDetails;

    // First update the frequency name (usage detail) options with the child
    const inventoryUsageDetailSet = new Set(inventoryUsageDetails.map((detail) => detail.frequencyName.toString()));
    const frequencyNameOptions = this.saleDocService.enum_frequency_name_options.filter(
      (detail) => inventoryUsageDetailSet.has(detail.label) || detail.label == ""
    );
    this.enum_frequency_name = frequencyNameOptions;

    // if child has empty usage details, but has a parent, async query the parent and reupdate with parent's usage details as fallback.
    if (inventory.inventoryUsageDetails.length == 0 && inventory.parentInventoryId != null) {
      this.subsList.push(
        this.dbService.getRow("inventory", inventory.parentInventoryId).subscribe((inventory) => {
          this.parent = inventory;
          inventoryUsageDetails = inventory.inventoryUsageDetails;
          // First update the frequency name (usage detail) options with the child
          const inventoryUsageDetailSet = new Set(
            inventoryUsageDetails.map((detail) => detail.frequencyName.toString())
          );
          const frequencyNameOptions = this.saleDocService.enum_frequency_name_options.filter(
            (detail) => inventoryUsageDetailSet.has(detail.label) || detail.label == ""
          );
          this.enum_frequency_name = frequencyNameOptions;

          this.recurringOrderSettingOptions = this.processRecurringOptionsDisabled(this.recurringOrderSettingOptions);
        })
      );
    }
  }

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes.activePriceLevel && !changes.activePriceLevel.isFirstChange()) {
      this.handleDiscount(this._myForm.value.docLine.inventory);
    }
  }

  userInputAmount(data: string): void {
    if (data) this._myForm.get("discountSetManually").patchValue(true);
  }

  private updateSubTotal(): void {
    this.handleDiscount(this._myForm.value.docLine.inventory);
    const recurrId = this._myForm.get("recurringOrderSettingId").value;
    if (!recurrId) {
      this.recurringSubTotal = null;
      this.recurringDiscount = null;
      return;
    }

    const unitPrice = +this.unitPriceCtrl.value;
    const recurringOrderSetting = this.recurringOrderSettingById[recurrId];
    this.recurringDiscount = +recurringOrderSetting.recuringDiscountPercent;

    if (this.activePriceLevel) {
      const priceLevelDiscount = Number(this.activePriceLevel.adjustBy);
      if (priceLevelDiscount > this.recurringDiscount) {
        this.recurringDiscount = priceLevelDiscount;
      }
    }

    const restrictDiscount = this._myForm.value.docLine.inventory?.allowDiscount;
    if (restrictDiscount) {
      const maxInventoryDiscount = Number(this._myForm.value.docLine.inventory?.maxDiscountPercentage);
      if (maxInventoryDiscount < this.recurringDiscount) {
        this.recurringDiscount = maxInventoryDiscount;
      }
    }

    this.recurringSubTotal = ((unitPrice - (unitPrice * this.recurringDiscount) / 100) * +this.qtyCtrl.value).toFixed(
      2
    );
  }

  onNumpadItemQty(newQty: number): void {
    newQty = newQty * Math.sign(this.qtyCtrl.value || 1);
    this.qtyCtrl.setValue(newQty);
  }

  onNumpadDiscountAmount(amount) {
    this.discountAmountCtrl.setValue(amount);
    this.onChangeDiscAmount();
  }

  // onUnitPriceChanged(amount) {
  //   this.unitPriceCtrl.setValue(amount);
  // }

  showItemDetailsDlg(e: MouseEvent) {
    e.stopImmediatePropagation();

    const inventoryId = this._myForm.get("docLine.inventoryId").value;
    if (!inventoryId) {
      // TODO: Change way to log or present error
      alert("Error: Inventory not found for sale line");
      return;
    }

    this.viewProductDetails.emit(inventoryId);
    return false;
  }

  onQtyClicked($event) {
    this._focusAndSelectTakuInput($event.target);
  }

  onDiscAmountClicked() {
    setTimeout(() => {
      this.discAmountComponent.focusAndSelectInput();
    }, 0);
  }

  // static init(fb:FormBuilder){
  //   const tmpObject = new SaleDocLine;
  //   const tmpForm = fb.group(tmpObject);
  //   // SaleDocLineComponent.filloutDerivedFormFields(tmpForm, tmpObject.docLine.inventory);
  //   tmpForm.setControl('docLine', fb.group(tmpObject.docLine));
  //   return tmpForm;
  // }

  static set(fb: UntypedFormBuilder, saleLine: SaleDocLine, minQty = 0) {
    const tmpForm = fb.group(saleLine);
    // Fillout derived form fields when data comes from server, using incorporated inventory object in response
    tmpForm.setControl("docLine", fb.group(saleLine.docLine));
    tmpForm.setControl("saleDocLineTaxes", SaleDocLineComponent.setTaxesArray(fb, saleLine.saleDocLineTaxes));
    if (saleLine.saleDocLineReturn) tmpForm.setControl("saleDocLineReturn", fb.group(saleLine.saleDocLineReturn));

    SaleDocLineComponent.filloutDerivedFormFields(tmpForm, saleLine, minQty);
    // Set validation dynamically
    const qtyCtrl = tmpForm.get("qty");
    if (saleLine.qty >= 0) {
      qtyCtrl.setValidators(CommonValidators.digits());
    } else {
      qtyCtrl.setValidators(CommonValidators.negativeNumber());
    }

    tmpForm.get("discountType").valueChanges.subscribe((_value) => {
      if (tmpForm.get("docLine").get("inventory").value.allowDiscount) {
        switch (_value) {
          case DiscountType.Percentage:
            tmpForm.get("discountAmount").clearValidators();
            if (tmpForm.value["discountType"] !== _value) {
              tmpForm.get("discountAmount").setValue(0);
            }
            tmpForm
              .get("discountAmount")
              .setValidators(Validators.max(tmpForm.value.docLine.inventory.maxDiscountPercentage));
            break;
          case DiscountType.Fixed:
            tmpForm.get("discountAmount").clearValidators();
            if (tmpForm.value["discountType"] !== _value) {
              tmpForm.get("discountAmount").setValue(0);
            }
            tmpForm
              .get("discountAmount")
              .setValidators(
                Validators.max(Math.min(tmpForm.value.unitPrice, tmpForm.value.docLine.inventory.maxDiscountAmount))
              );
            break;
          case DiscountType.Final_Price:
            tmpForm.get("discountAmount").clearValidators();
            if (tmpForm.value["discountType"] !== _value) {
              tmpForm.get("discountAmount").setValue(tmpForm.get("unitPrice").value);
            }
            tmpForm
              .get("discountAmount")
              .setValidators(
                Validators.min(
                  tmpForm.value.unitPrice -
                    Math.min(tmpForm.value.unitPrice, tmpForm.value.docLine.inventory.maxDiscountAmount)
                )
              );
            break;
        }
        tmpForm.get("discountAmount").updateValueAndValidity();
      }
    });

    return tmpForm;
  }

  static setTaxesArray(fb: UntypedFormBuilder, saleLineTaxes: SaleDocLineTax[]): UntypedFormArray {
    return fb.array(saleLineTaxes.map((taxLine) => fb.group(taxLine)));
  }

  static setArray(fb: UntypedFormBuilder, salesLines: SaleDocLine[]) {
    const formArray = fb.array([]);
    salesLines.map((saleLine) => {
      const temp = SaleDocLineComponent.set(fb, saleLine);
      formArray.push(temp);
    });
    return formArray;
  }

  static filloutDerivedFormFields(saleLineForm: UntypedFormGroup, saleLine: SaleDocLine, minQty = 0) {
    saleLine = _.merge(new SaleDocLine(), saleLine);
    // Extra fields for data presentation
    const derivedFields = {
      salePrice: saleLine.salePrice,
      description: saleLine.docLine.inventory.description1,
      sku: saleLine.docLine.inventory.sku,
      categories: saleLine.docLine.inventory.categories.map((cat) => cat.categoryName).join(", "),
      minQty,
    };
    // // override minQty only if it is defined

    // attach fields to current form
    for (const key in derivedFields) {
      let formControl: AbstractControl;
      const value = derivedFields[key];
      if ((formControl = saleLineForm.get(key))) formControl.setValue(value);
      else saleLineForm.addControl(key, new UntypedFormControl(value));
    }
  }

  handleDiscount(item: Inventory): void {
    if (this.qtyCtrl.value <= 0) return;
    this.promotionService
      .getPromotionalItem(item)
      .pipe(take(1))
      .subscribe((promo) => {
        if (
          !promo ||
          this._myForm.get("discountType").value === DiscountType.Final_Price ||
          this._myForm.get("discountType").value === DiscountType.Fixed ||
          this._myForm.get("discountSetManually").value === true
        )
          return;

        // final discount that should be applied to the line item
        let discountToApply = 0;
        const maxDiscount = item.allowDiscount ? Number(item.maxDiscountAmount) : 0;
        const discount =
          maxDiscount > Number(promo.discountPercentage) ? maxDiscount : Number(promo.discountPercentage);
        discountToApply = discount;

        if (this.activePriceLevel) {
          const priceLevelDiscount = Number(this.activePriceLevel.adjustBy);

          if (priceLevelDiscount > this.recurringDiscount || priceLevelDiscount > discount) {
            this.recurringDiscount = priceLevelDiscount;
            discountToApply = priceLevelDiscount;
          }

          // for this current iteration it can only combine discounts if priceLevels applies
          if (promo.canCombineDiscount) {
            const combinedDiscount = discount + priceLevelDiscount;
            discountToApply = item.allowDiscount && maxDiscount < combinedDiscount ? maxDiscount : combinedDiscount;
          }
        }
        // patches the discount input with the final discount if it is not return line
        this.discountAmountCtrl.patchValue(discountToApply);
      });
  }

  // onChangeSalePrice() {
  //   if (this._myForm.get('salePrice').value) {
  //     const discAmountPercent: number = 100 - (((this._myForm.get('salePrice').value) * 100) / (this._myForm.get('unitPrice').value));
  //     this._myForm.get('discountAmount').setValue(discAmountPercent);
  //     this._myForm.get('discountType').setValue(DiscountType.Percentage);
  //   } else {
  //     this._myForm.get('salePrice').reset();
  //   }
  // }

  onPriceClick() {
    this.selected.emit();
  }

  ///////////////////////////////////////////// Buttons //////////////////////////////////////////////////////////////////////

  isQtyValid(newQty): boolean {
    // If quantity value is not an integer/number after parsing, do nothing
    return !isNaN(newQty) && (this.minQtyCtrl.value == null || newQty >= this.minQtyCtrl.value);
  }

  initValidation() {
    this._validation = {
      discountAmount: InvoiceValidators.discountAmount(this.discountTypeCtrl, this.unitPriceCtrl, "unit price"),
    };
    if (!this.isPositiveQty && this.minQtyCtrl.value) {
      this._validation["qty"] = [InvoiceValidators.returnMaxQty(this.minQtyCtrl.value)];
      // [Validators.min(this.minQtyCtrl.value)]
    }
  }

  get maxDiscountAmount(): number {
    switch (this.discountTypeCtrl.value) {
      case DiscountType.Fixed:
      case DiscountType.Final_Price:
        return parseFloat(this.unitPriceCtrl.value);

      case DiscountType.Percentage:
        return 100;
    }
  }

  get calcMaximumQty() {
    if (this.isPositiveQty) {
      return this.maxQtyCtrl?.value || Infinity;
    } else {
      return this.minQtyCtrl?.value * -1 || Infinity;
    }
  }

  get isPositiveQty() {
    return this.qtyCtrl.value.toString()[0] !== "-"; // && (this.qtyCtrl.value >= 0 || this.qtyCtrl.value === '' || this.qtyCtrl.value[0] === '.');
  }

  /** If is a return line and has return reason control enabled */
  get isReturnReasonActive(): boolean {
    // return this.qtyCtrl.value < 0 && !!this._myForm.get('saleDocLineReturn.storeSettingReturnReasonId');

    return this.qtyCtrl.value < 0 && this.ctrlDocLineRefNo && this.ctrlDocLineRefNo.value;
  }

  hasReturnItem() {
    return this.saleDoc.saleDocLines.some((line) => !!line.saleDocLineReturnId && line.qty < 0);
  }

  get ctrlReturnReasonSettingId(): AbstractControl {
    return this._myForm.get("saleDocLineReturn.storeSettingReturnReasonId");
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
