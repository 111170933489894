/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Pipe, PipeTransform, Inject, LOCALE_ID } from "@angular/core";
import { DatePipe } from "@angular/common";
import { AppConstants } from "../app-constants";
import * as _ from "lodash";
import * as moment from "moment";
import { TimeHelpers } from "../../utility/TimeHelpers";

@Pipe({
  name: "dateTimeFormat",
  standalone: true,
})
export class DateTimeFormatPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  transform(date: string | Date, time?: string, includeSeconds = false): any {
    let localTime;
    if (date === null) {
      return null;
    } else {
      if (!_.isDate(date) && !time) {
        localTime = moment(date).toDate();
      } else {
        const dateStr = _.isDate(date) ? moment.utc(date).format("YYYY-MM-DD") : date;
        const timeStr = time || moment.utc(date).format("hh:mm:ss a");
        localTime = TimeHelpers.UnifyDateAndTime(dateStr, timeStr, false);
      }
    }

    const timeFormat = includeSeconds ? AppConstants.DATE_TIME_SECS_FMT : AppConstants.DATE_TIME_FMT;
    return new DatePipe(this.locale).transform(localTime, timeFormat);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
