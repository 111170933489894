<p-table [value]="allServerErrors" styleClass="p-datatable-striped" responsiveLayout="stack" breakpoint="640px">
  <ng-template pTemplate="header">
    <tr>
      <th>Timestamp</th>
      <th>Error Message</th>
      <th>Trace ID</th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-serverError>
    <tr>
      <td>
        <span class="p-column-title">Timestamp:</span>
        {{ serverError.timestamp | dateTimeFormat }}
      </td>

      <td>
        <span class="p-column-title">Error Message:</span>
        {{ serverError.message }}
      </td>

      <td>
        <span class="p-column-title">Trace ID:</span>
        {{ serverError.traceId }}
      </td>
    </tr>
  </ng-template>
</p-table>
