<div class="mobile-checkout-topWrapper">
  <taku-sale-doc-self-checkout-mobile
    #saleDocComponent
    (onStartOver)="onStartOverPressed($event)"
    (saleLinesCleared)="onSalesLinesCleared()"
    (onReturnToCart)="onReturnToCart()"
    [topWrapperHeight]="dvHeight"
    (onDocSaved)="onDocSaved($event)"
    (onDocReset)="onDocReset()"
    (tenderScreenOpened)="openTenderScreen($event)"
    (itemReturned)="itemReturnedAction($event)"
    (onDocLinesDeleted)="onDocLinesDeleted($event)"
    (docLineQtyChanged)="docLineQtyChanged($event)"
    (backToSearchScreen)="onGoingBackToSearchScreen()"
  >
    <ng-template #saleDocDataViewTemplate let-saleForm>
      <taku-category-data-view
        #saleDocDataView
        *ngIf="!_showInventoryItems"
        [saleForm]="saleForm"
        (onItemSelected)="showItemsOfCategory($event)"
        [rows]="currentFetchLimit"
        [totalCategories]="totalCategories"
        [productsLoading]="_productsLoading"
        (onLazyLoad)="loadCategoryLazy($event)"
        (onSearchSubmit)="onSearchFieldSubmit()"
        [categories]="categories"
        (onBackToCategories)="goBackToCategories()"
        [dvContainerWidth]="dvWidthAfterOffset"
        [dvContainerHeight]="dvHeightAfterOffset"
        [inSelfCheckout]="true"
        (openShoppingCart)="onOpenShoppingCart()"
      >
      </taku-category-data-view>
      <taku-inventory-data-view
        #saleDocDataView
        *ngIf="_showInventoryItems"
        [saleForm]="saleForm"
        [rows]="currentFetchLimit"
        [totalRecords]="totalRecords"
        [productsLoading]="_productsLoading"
        (onLazyLoad)="loadInventoryLazy($event)"
        (onSearchSubmit)="onSearchFieldSubmit()"
        [inventories]="inventories"
        (onBackToCategories)="goBackToCategories()"
        [dvContainerWidth]="dvWidthAfterOffset"
        [dvContainerHeight]="dvHeightAfterOffset"
        [inSelfCheckout]="true"
        (openShoppingCart)="onOpenShoppingCart()"
        [currentCategoryName]="currentCategory?.categoryName"
      >
        <ng-template #saleDocGridCardTemplate let-inventory let-width="width" let-height="height">
          <taku-sale-doc-self-checkout-grid-card
            [inventory]="inventory"
            [width]="width"
            [height]="height"
            [_isMobile]="true"
            (click)="onInventoryItemSelected(inventory)"
          >
          </taku-sale-doc-self-checkout-grid-card>
        </ng-template>
      </taku-inventory-data-view>
    </ng-template>
  </taku-sale-doc-self-checkout-mobile>
</div>
