import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { SaleDocFooterComponent } from "../sale-doc-footer/sale-doc-footer.component";

@Component({
  selector: "taku-self-checkout-desktop-footer",
  templateUrl: "./self-checkout-desktop-footer.component.html",
  styleUrls: ["./self-checkout-desktop-footer.component.scss"],
})
export class SelfCheckoutDesktopFooterComponent extends SaleDocFooterComponent {
  @Output() onSelfCheckoutPay: EventEmitter<any> = new EventEmitter();
  @Output() adjustScrollPanelHeight: EventEmitter<number> = new EventEmitter();

  @Input() inPaymentPage = false;

  onSelfCheckoutPayBtnPressed() {
    this.onSelfCheckoutPay.emit(this);
  }

  // overriding the parent's ngOnChanges
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.saleForm) {
      this.updateInvoiceSubTotal();
      this.updateInvoiceTotalTax();
      this.subsList.push(
        this.saleForm.valueChanges.subscribe((newValue) => {
          this.updateInvoiceSubTotal();
          this.updateInvoiceTotalTax();
          if (this.groupedTaxes.length >= 3) {
            this.resizeScrollPanelHeight(this.groupedTaxes.length);
          }
          this.docTotalChanged.emit(this.invoiceTotal);
        })
      );
    }
  }

  resizeScrollPanelHeight(numberOfTaxTypes: number) {
    const heightAdjustCount = numberOfTaxTypes - 2;
    this.adjustScrollPanelHeight.emit(heightAdjustCount);
  }
}
