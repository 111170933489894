<div [formGroup]="formGroup">
  <div class="col-12 p-float-label">
    <p-calendar
      formControlName="{{ fieldName }}"
      [monthNavigator]="true"
      [yearNavigator]="true"
      yearRange="1900:2030"
      [showIcon]="true"
      [showTime]="true"
      dataType="string"
      [style]="{ width: '100%' }"
      dateFormat="yy-mm-dd"
      [appendTo]="appendTo"
      [minDate]="minDate"
      [maxDate]="maxDate"
      [touchUI]="isMobile"
      [readonlyInput]="isMobile"
    ></p-calendar>
    <label for="{{ fieldName }}" [innerHTML]="fullFieldCaption"></label>
    <i
      class="material-icons clear-field"
      (click)="clear()"
      *ngIf="formGroup.controls[fieldName].value && !formGroup.controls[fieldName].disabled"
      >close</i
    >

    <form-validation-messages
      [hidden]="formGroup.controls[fieldName].valid || !formGroup.controls[fieldName].dirty"
      [errors]="formGroup.controls[fieldName].errors"
      [fieldName]="fieldName"
    ></form-validation-messages>
  </div>
</div>
