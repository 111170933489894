/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { Col, RowSelectionType } from "../../../form-list/form-list/form-list";
import { DocState } from "../../document/doc/doc";
import { CashoutTenderDocsService } from "./cashout-tender-docs.service";

@Component({
  selector: "taku-cashout-tender-transactions",
  templateUrl: "./cashout-tender-transactions.component.html",
  styleUrls: ["./cashout-tender-transactions.component.scss"],
})
export class CashoutTenderTransactionsComponent implements OnInit {
  // @Input() cashoutId: number;
  // @Input() tenderTypeId: number;
  // @Input() tenderTypeName: string;
  // @Output() onCloseDialog: EventEmitter<any> = new EventEmitter;

  formlistFilter: {};
  cashoutTenderColumns: Col[];
  RowSelectionType = RowSelectionType;
  _formListExtraParams = {
    includes: "saleDocTender",
  };
  constructor(
    public dbService: CashoutTenderDocsService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    this.cashoutTenderColumns = this.dbService.getFormListColumns();
  }

  ngOnInit(): void {
    // Bring data from dialog service, as this is using Dynamic Dialog
    this.formlistFilter = {
      cashoutId: { value: this.config.data.cashoutId, matchMode: "equals" },
      "saleDocTenders.tenderTypeId": { value: this.config.data.tenderTypeId, matchMode: "equals" },
      "doc.state": { value: [DocState.finalized, DocState.voided], matchMode: "in" },
    };
    this.dbService._tenderTypeId = this.config.data.tenderTypeId;
  }

  onCloseBtnPressed() {
    // this.onCloseDialog.emit();
    this.ref.close();
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
