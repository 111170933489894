<span
  [formGroup]="formGroup"
  class="{{ leftIconClass || leftIconName ? 'p-input-icon-left' : '' }} {{
    rightIconClass || rightIconName ? 'p-input-icon-right' : ''
  }} p-float-label"
>
  <i *ngIf="leftIconClass || leftIconName" class="{{ leftIconClass }}">{{ leftIconName }}</i>
  <taku-inputMask
    *ngIf="inputMask"
    [characterPattern]="maskCharacterPattern"
    [unmask]="unmask"
    [maxlength]="maxLength"
    [autoClear]="false"
    [attr.tabindex]="tabIndex"
    [mask]="inputMask"
    [numericMaskChar]="_numericMaskChar"
    [alphaMaskChar]="_alphaMaskChar"
    [attr.disabled]="disabled || null"
    [readonly]="readonly || null"
    [formControlName]="fieldName"
    [slotChar]="slotChar"
    (onBlur)="onBlur.emit($event)"
    (onFocusOut)="onFocusOut.emit($event)"
    [disabled]="disabled"
  ></taku-inputMask>
  <input
    #inputField
    *ngIf="!inputMask"
    [id]="fieldName"
    [maxlength]="maxLength"
    [attr.tabindex]="tabIndex"
    [type]="type"
    [pKeyFilter]="keyFilter"
    pInputText
    [attr.disabled]="disabled || null"
    [attr.readonly]="readonly || null"
    [formControlName]="fieldName"
    (blur)="onBlur.emit($event)"
    (click)="autoSelect && focusAndSelectInput()"
    [ngClass]="ngClass"
    (focusout)="onFocusOut.emit($event)"
    [attr.autocomplete]="autocomplete"
    [attr.inputmode]="inputmode"
    (input)="inputChanges($event)"
  />
  <i *ngIf="disabled" class="pi pi-lock locked-feature"></i>
  <label
    *ngIf="fullFieldCaption"
    for="{{ fieldName }}"
    [innerHTML]="fullFieldCaption"
    class="text-overflow-ellipsis"
    [style.maxWidth]="
      'calc(100% + ' +
      (-30 - (leftIconClass || leftIconName ? 18 : 0) - (rightIconClass || rightIconName ? 18 : 0)) +
      'px)'
    "
  ></label>

  <i
    *ngIf="ctrlField.value && !(readonly || disabled)"
    class="material-icons clear-field"
    (click)="clear()"
    [ngStyle]="{
      'padding-right': (rightIconClass || isPasswordField) && '20px',
      'padding-top': rightIconClass && '15px'
    }"
    >close</i
  >
  <i *ngIf="rightIconClass || rightIconName" class="{{ rightIconClass }}">{{ rightIconName }}</i>
  <i
    *ngIf="isPasswordField"
    class="password-eye"
    [ngClass]="type === 'text' ? 'pi pi-eye-slash' : 'pi pi-eye'"
    (click)="togglePasswordVisibility()"
  ></i>
</span>

<form-validation-messages
  *ngIf="showValidationErrors"
  [hidden]="ctrlField.valid || !ctrlField.dirty"
  [errors]="ctrlField.errors"
  [fieldName]="fieldName"
></form-validation-messages>
