/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Store } from "../store/store";
import { StoreSettingPrintBase } from "../store-setting-print-base.class";
import { Zone } from "../../zone-settings/Zone";

export class StoreSettingTapeReturnPrint extends StoreSettingPrintBase {
  id: number;
  headerFontSize: Font_Size;
  printLogo: boolean;
  printStoreName: boolean;
  printWebsite: boolean;
  printEmail: boolean;
  printPhoneNo: boolean;
  printAddress: boolean;
  printStoreTaxNumber: boolean;
  printTaxNumber: boolean;
  printBusinessHours: boolean;
  printAccountInfo: boolean;
  printDiscountedPrice: boolean;
  printSocialIcons: boolean;
  printPoweredByTalulabs: boolean;
  lineFontSize: Font_Size;
  lineSortOrder: Sort_Order;
  printDocumentNo: boolean;
  printDate: boolean;
  printBillingAddress: boolean;
  printShippingAddress: boolean;
  printDescription2: boolean;
  printSKU: boolean;
  printUofM: boolean;
  printBarcodeNo: boolean;
  printVariants: boolean;
  printOriginalPrice: boolean;
  printCategoryHeader: boolean;
  printCategoryInLine: boolean;
  printSummary: boolean;
  printTaxInventoryType: boolean;
  printreturnReceipt: boolean;
  printTaxSummary: boolean;
  printTenderDetail: boolean;
  printTransactionRecord: boolean;
  customerNote: string;
  storeId: number;
  store: Store;
  printStationNumber: boolean;
  printStationName: boolean;

  constructor(zone: Zone, store?: Store) {
    super(zone);

    this.id = 0;
    this.headerFontSize = Font_Size.small;
    this.printLogo = true;
    this.printStoreName = true;
    this.printWebsite = true;
    this.printEmail = true;
    this.printPhoneNo = true;
    this.printAddress = true;
    this.printStoreTaxNumber = false;
    this.printTaxNumber = false;
    this.printBusinessHours = true;
    this.printAccountInfo = false;
    this.printDiscountedPrice = true;
    this.printSocialIcons = true;
    this.printPoweredByTalulabs = true;
    this.lineFontSize = Font_Size.small;
    this.lineSortOrder = Sort_Order.Scan_Order;
    this.printDocumentNo = true;
    this.printDate = true;
    this.printBillingAddress = false;
    this.printShippingAddress = true;
    this.printDescription2 = false;
    this.printSKU = true;
    this.printUofM = true;
    this.printBarcodeNo = true;
    this.printVariants = true;
    this.printOriginalPrice = true;
    this.printCategoryHeader = false;
    this.printCategoryInLine = false;
    this.printSummary = true;
    this.printTaxInventoryType = true;
    this.printreturnReceipt = true;
    this.printTaxSummary = true;
    this.printTenderDetail = true;
    this.printTransactionRecord = false;
    this.customerNote = "Thank you for your preference";
    this.storeId = store ? store.id : 0;
    this.store = store || null;
    this.printStationNumber = true;
    this.printStationName = false;
  }
}

export enum Font_Size {
  small = "Small",
  medium = "Medium",
}

export enum Sort_Order {
  Scan_Order = "Scan Order",
  Category = "Category",
  Tax_Inventory_Category = "Tax Inventory Category",
  SKU = "SKU",
  Discount = "Discount",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
