<div class="col-12 credit-cards" [formGroup]="myForm">
  <div class="grid col-12 credit-cards-header">
    <h3>{{ subTitle }}</h3>
    <a class="add-button" [ngClass]="{ 'btn-disabled': myForm.get('id')?.value == 0 }" (click)="onAddCreditCard()">
      <label>Add New Card</label>
      <i class="material-icons">add_circle</i>
    </a>
  </div>

  <div class="grid col-12">
    <p-table
      [resizableColumns]="true"
      [value]="myForm.get('accountCreditCards').value"
      responsiveLayout="stack"
      breakpoint="640px"
      styleClass="p-datatable-striped"
      [tableStyle]="{ 'min-width': '40rem' }"
    >
      <ng-template pTemplate="header">
        <tr>
          <th class="p-resizable-column">
            <strong>Cardholder Name</strong>
          </th>
          <th class="p-resizable-column">
            <strong>Card Type</strong>
          </th>
          <th class="p-resizable-column">
            <strong>Last 4</strong>
          </th>
          <th class="p-resizable-column">
            <strong>Expiry Date</strong>
          </th>
          <th class="p-resizable-column">
            <strong>Status</strong>
          </th>
          <th class="p-resizable-column">
            <strong>Default</strong>
          </th>
          <th class="p-resizable-column">
            <span> </span>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-card let-rowIndex="rowIndex" let-columns="columns">
        <tr>
          <td class="text-center">
            {{ card.cardHolderName }}
          </td>
          <td class="p-resizable-column">
            {{ card.cardType }}
          </td>
          <td class="p-resizable-column">
            {{ card.last4Digits }}
          </td>
          <td class="p-resizable-column">
            {{ card.expiryDate }}
          </td>
          <td>
            <p-tag
              [value]="card.status"
              [severity]="card.status === 'Active' ? 'success' : 'danger'"
              [rounded]="true"
            ></p-tag>
          </td>
          <td class="p-resizable-column">
            <p-button
              type="button"
              styleClass="p-button-secondary"
              class="p-button p-button-rounded p-button-icon-only p-button-secondary"
              (onClick)="makeDefault(rowIndex)"
            >
              <i class="material-icons m-auto">{{
                card.isDefault ? "radio_button_checked" : "radio_button_unchecked"
              }}</i>
            </p-button>
          </td>
          <td class="p-resizable-column">
            <p-button
              type="button"
              styleClass="p-button-secondary"
              class="p-button p-button-rounded p-button-icon-only p-button-secondary"
              (onClick)="removeCard(rowIndex)"
            >
              <i class="material-icons m-auto remove-button">remove_circle_outline</i>
            </p-button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
