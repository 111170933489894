/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Input, OnInit, OnChanges, SimpleChanges } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { NestedFormComponent } from "../../../forms/nested-form/nested-form.component";
import { CommonValidators } from "../../../shared/validators/CommonValidators";
import { MonetaryHelpers } from "../../../utility/MonetaryHelpers";
import { Cashout, CashoutClosingFloat } from "../cashout/cashout";

@Component({
  selector: "taku-cashout-closing-float",
  templateUrl: "./cashout-closing-float.component.html",
  styleUrls: ["./cashout-closing-float.component.scss"],
})
export class CashoutClosingFloatComponent extends NestedFormComponent implements OnInit, OnChanges {
  @Input() qtyFieldTextAlignment;
  @Input() currencyISOCode;
  @Input() rowNumber;
  @Input() requiredValidation: boolean;
  fieldCountMaxLength: number;

  static setArray(fb: UntypedFormBuilder, closingFloats: CashoutClosingFloat[]) {
    const formArray = fb.array([]);
    closingFloats.forEach((closingFloat) => formArray.push(fb.group(closingFloat)));

    return formArray;
  }

  ngOnInit() {
    super.ngOnInit();

    const maxValidCountValue = Cashout.MAX_COUNT - 1;
    this.fieldCountMaxLength = (maxValidCountValue + "").length;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["requiredValidation"] && !changes["requiredValidation"].firstChange) {
      this.initValidation();
      this.setValidation();
      Object.keys(this._validation).forEach((key) => {
        this._myForm.controls[key].updateValueAndValidity();
      });
    }
  }

  initValidation() {
    this._validation = {
      nextFloatCount: [Validators.min(0), CommonValidators.digits()],
      remainingFloatCount: [Validators.min(0), CommonValidators.digits()],
    };

    if (this.requiredValidation)
      Object.keys(this._validation).forEach((key) => {
        this._validation[key].push(Validators.required);
      });
  }

  formatDenominationLabel(amount) {
    return MonetaryHelpers.pluralizeDenomination(amount, this.currencyISOCode);
  }

  get actualCashCount() {
    let nextFloatCount = parseInt(this._myForm.get("nextFloatCount").value);
    nextFloatCount = isNaN(nextFloatCount) ? 0 : nextFloatCount;

    let remainingFloatCount = parseInt(this._myForm.get("remainingFloatCount").value);
    remainingFloatCount = isNaN(remainingFloatCount) ? 0 : remainingFloatCount;

    return nextFloatCount + remainingFloatCount;
  }

  isDesktopDisplayRange(): boolean {
    // this width was suggested by Karen for better responsive design of the table
    return window.innerWidth > 812;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
