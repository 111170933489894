<div class="topWrapper">
  <taku-form-header
    toolbarTitle="Closed Batch Details"
    displayMode="inline"
    [showActionBtns]="false"
    [showBackBtn]="false"
  >
    <button pButton class="p-button-rounded" type="button" (click)="onClosePressed()" icon="pi pi-times"></button>
  </taku-form-header>
  <div class="bodyWrapper">
    <div class="body-top">
      <table cellspacing="0">
        <tr>
          <td class="label-col">Transaction Type</td>
          <td class="value-col">{{ detailsObject.TransType }}</td>
        </tr>
        <tr>
          <td class="label-col">Transaction Date</td>
          <td class="value-col">{{ detailsObject.TransDate }}</td>
        </tr>
        <tr>
          <td class="label-col">Transaction Time</td>
          <td class="value-col">{{ detailsObject.TransTime }}</td>
        </tr>
        <tr>
          <td class="label-col">Transaction Name</td>
          <td class="value-col">{{ detailsObject.TxnName }}</td>
        </tr>
      </table>

      <table cellspacing="0">
        <tr>
          <td class="label-col">Terminal ID</td>
          <td class="value-col">{{ detailsObject.TerminalId }}</td>
        </tr>
        <tr>
          <td class="label-col">Response Code</td>
          <td class="value-col">{{ detailsObject.ResponseCode }}</td>
        </tr>
        <tr>
          <td class="label-col">Batch Number</td>
          <td class="value-col">{{ totalsCardTypeObj.BatchNumber }}</td>
        </tr>
        <tr>
          <td class="label-col">Condition Code</td>
          <td class="value-col">{{ totalsCardTypeObj.ConditionCode }}</td>
        </tr>
      </table>
    </div>
    <div class="body-bottom">
      <div class="cardType-container">
        <div class="main-table">
          <table cellspacing="0" class="header-table">
            <tr class="header-row">
              <th>Totals</th>
              <th>Host Count</th>
              <th>Host Total</th>
              <th>Device Count</th>
              <th>Device Total</th>
            </tr>
            <tr class="body-row first-body-row">
              <td>Purchase</td>
              <td>{{ totalsCardTypeObj.HostPurchaseCount | number : _digitsFormat }}</td>
              <td>{{ totalsCardTypeObj.HostPurchaseTotal }}</td>
              <td>{{ totalsCardTypeObj.DevicePurchaseCount | number : _digitsFormat }}</td>
              <td
                ><span>{{ totalsCardTypeObj.DevicePurchaseTotal }}</span></td
              >
            </tr>
            <tr class="body-row">
              <td>Refund</td>
              <td>{{ totalsCardTypeObj.HostRefundCount | number : _digitsFormat }}</td>
              <td>{{ totalsCardTypeObj.HostRefundTotal }}</td>
              <td>{{ totalsCardTypeObj.DeviceRefundCount | number : _digitsFormat }}</td>
              <td
                ><span>{{ totalsCardTypeObj.DeviceRefundTotal }}</span></td
              >
            </tr>
            <tr class="body-row">
              <td>Correction</td>
              <td>{{ totalsCardTypeObj.HostCorrectionCount | number : _digitsFormat }}</td>
              <td>{{ totalsCardTypeObj.HostCorrectionTotal }}</td>
              <td>{{ totalsCardTypeObj.DeviceCorrectionCount | number : _digitsFormat }}</td>
              <td
                ><span>{{ totalsCardTypeObj.DeviceCorrectionTotal }}</span></td
              >
            </tr>
            <tr class="body-row">
              <td>Payment</td>
              <td>{{ totalsCardTypeObj.HostPurchaseCount | number : _digitsFormat }}</td>
              <td>{{ totalsCardTypeObj.HostPurchaseTotal }}</td>
              <td>{{ totalsCardTypeObj.DevicePurchaseCount | number : _digitsFormat }}</td>
              <td
                ><span>{{ totalsCardTypeObj.DevicePurchaseTotal }}</span></td
              >
            </tr>
            <tr class="body-row">
              <td>Payment Correction</td>
              <td>{{ totalsCardTypeObj.HostPaymentCorrectionCount | number : _digitsFormat }}</td>
              <td>{{ totalsCardTypeObj.HostPaymentCorrectionTotal }}</td>
              <td>{{ totalsCardTypeObj.DevicePaymentCorrectionCount | number : _digitsFormat }}</td>
              <td
                ><span>{{ totalsCardTypeObj.DevicePaymentCorrectionTotal }}</span></td
              >
            </tr>
            <tr class="body-row">
              <td>Credit</td>
              <td>{{ totalsCardTypeObj.HostCreditCount | number : _digitsFormat }}</td>
              <td></td>
              <td>{{ totalsCardTypeObj.DeviceCreditCount | number : _digitsFormat }}</td>
              <td></td>
            </tr>
          </table>
          <table
            cellspacing="0"
            class="header-table"
            *ngFor="let cardType of detailsObject.totalsByCardType | keyvalue"
          >
            <tr class="header-row">
              <th>{{ cardType.key }}</th>
              <th>Host Count</th>
              <th>Host Total</th>
              <th>Device Count</th>
              <th>Device Total</th>
            </tr>
            <tr class="body-row first-body-row">
              <td>Purchase</td>
              <td>{{ cardType.value.HostPurchaseCount | number : _digitsFormat }}</td>
              <td>{{ cardType.value.HostPurchaseTotal }}</td>
              <td>{{ cardType.value.DevicePurchaseCount | number : _digitsFormat }}</td>
              <td
                ><span>{{ cardType.value.DevicePurchaseTotal }}</span></td
              >
            </tr>
            <tr class="body-row">
              <td>Refund</td>
              <td>{{ cardType.value.HostRefundCount | number : _digitsFormat }}</td>
              <td>{{ cardType.value.HostRefundTotal }}</td>
              <td>{{ cardType.value.DeviceRefundCount | number : _digitsFormat }}</td>
              <td
                ><span>{{ cardType.value.DeviceRefundTotal }}</span></td
              >
            </tr>
            <tr class="body-row">
              <td>Correction</td>
              <td>{{ cardType.value.HostCorrectionCount | number : _digitsFormat }}</td>
              <td>{{ cardType.value.HostCorrectionTotal }}</td>
              <td>{{ cardType.value.DeviceCorrectionCount | number : _digitsFormat }}</td>
              <td
                ><span>{{ cardType.value.DeviceCorrectionTotal }}</span></td
              >
            </tr>
            <tr class="body-row">
              <td>Payment</td>
              <td>{{ cardType.value.HostPurchaseCount | number : _digitsFormat }}</td>
              <td>{{ cardType.value.HostPurchaseTotal }}</td>
              <td>{{ cardType.value.DevicePurchaseCount | number : _digitsFormat }}</td>
              <td
                ><span>{{ cardType.value.DevicePurchaseTotal }}</span></td
              >
            </tr>
            <tr class="body-row">
              <td>Payment Correction</td>
              <td>{{ cardType.value.HostPaymentCorrectionCount | number : _digitsFormat }}</td>
              <td>{{ cardType.value.HostPaymentCorrectionTotal }}</td>
              <td>{{ cardType.value.DevicePaymentCorrectionCount | number : _digitsFormat }}</td>
              <td
                ><span>{{ cardType.value.DevicePaymentCorrectionTotal }}</span></td
              >
            </tr>
            <tr class="body-row">
              <td>Credit</td>
              <td>{{ cardType.value.HostCreditCount | number : _digitsFormat }}</td>
              <td></td>
              <td>{{ cardType.value.DeviceCreditCount | number : _digitsFormat }}</td>
              <td></td>
            </tr>
          </table>
        </div>
        <div class="email-and-print-top-container">
          <div class="sendEmailWrapper">
            <taku-input
              class="field-emailAddress"
              [formGroup]="footerFormGroup"
              fieldName="emailAddress"
              fieldCaption="Email address"
            ></taku-input>
            <div class="email-input-container">
              <button
                pButton
                class="email-btn squared-corners"
                [disabled]="footerFormGroup.get('emailAddress').invalid || !footerFormGroup.get('emailAddress').value"
                (click)="menuEmailReceiptSizes.toggle($event)"
              >
                <i class="material-icons"> email </i>
                <span class="ml-2 font-bold">Send</span>
              </button>
            </div>
            <p-menu #menuEmailReceiptSizes [popup]="true" [model]="emailReceiptSizesMenuItems" appendTo="body"></p-menu>
          </div>
          <div class="printWrapper">
            <button
              pButton
              class="squared-corners"
              [label]="'Print'"
              icon="pi pi-print"
              (click)="menuPrintSizes.toggle($event)"
            >
            </button>
            <p-menu #menuPrintSizes [popup]="true" [model]="printSizesMenuItems" appendTo="body"></p-menu>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div [hidden]="true">
  <ng-template #printPreviewHost></ng-template>
</div>
