/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { AccountGroup } from "../../settings/store-settings/account-group/account-group";
import { TaxAccountCategory } from "../../tax/tax-account-category/tax-account-category";
import { AccountCreditCard } from "../account-credit-card";
import { ContactAccountBase } from "./contact-account-base";

export class Account {
  id: number;
  updatedAt: string;
  accountType: AccountType;
  accountRelationship: AccountRelationship;
  accountCode: string;
  status: AccountStatus;
  isEnabled: boolean;
  note: string;
  creditLimit: number | string;
  defaultCurrencyIsoCode: string;
  acctGroups: AccountGroup[];
  acctAssociationId: number;
  // acctAssociation: AcctAssociation;
  taxAccountCategoryId: number;
  taxAccountCategory: TaxAccountCategory;
  tags: JSON;
  taxID: string;
  channelTypes: JSON;
  accountCreditCards: AccountCreditCard[];

  constructor(accountType: AccountType = AccountType.personal) {
    this.id = 0;
    this.accountType = accountType;
    this.accountRelationship = AccountRelationship.customer;
    this.accountCode = null;
    this.status = AccountStatus.active;
    this.note = "";
    this.creditLimit = 0;
    this.defaultCurrencyIsoCode = null;
    this.acctGroups = [];
    this.acctAssociationId = null;
    this.taxAccountCategoryId = null;
    this.tags = null;
    this.taxID = null;
    this.isEnabled = true;
    this.channelTypes = null;
    this.accountCreditCards = [];
  }

  get acctGroupsList(): string {
    return this.acctGroups.map((row) => row.name).join("; ");
  }

  static GetContactAccount(saleDoc): ContactAccountBase {
    if (saleDoc.account instanceof Account) {
      switch (saleDoc.account.accountType) {
        case AccountType.commercial:
          return saleDoc.commercialAccount;

        case AccountType.personal:
          return saleDoc.personalAccount;
      }
    }

    return null;
  }
}
export enum AccountType {
  commercial = "Commercial",
  personal = "Personal",
}

export enum AccountRelationship {
  customer = "Customer",
  supplier = "Supplier",
  both = "Both",
}

export enum AccountStatus {
  active = "Active",
  inactive = "Inactive",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
