/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { CurrencyPipe } from "@angular/common";
import { AppConstants } from "src/app/shared/app-constants";
import { FormatNegativeNumberPipe } from "src/app/shared/pipes/format-negative-number.pipe";
import { MonetaryHelpers } from "src/app/utility/MonetaryHelpers";
import { SaleDoc } from "../../document/sale-document/sale-doc/sale-doc";
import { Store } from "../../settings/store-settings/store/store";
import { SaleChannel } from "../sale-channel";
import { Account } from "../../contact-accounts/account/account";

export class SaleChannelOrder {
  id: number;
  shipperChargeAmount: number;
  orderStatus: OrderStatus;
  deliveryMethod: DeliveryMethod;
  channelOrderId: string;
  docNo: number;
  docDate: string;
  docTime: string;
  accountId: number;
  account: Account;
  paymentStatus: SaleChannelPaymentStatus;
  fulfillmentStatus: SaleChannelFulfillmentStatus;
  subTotal: number;
  email: string;
  shipTo: string;
  deliveryStatus: SaleChannelDeliveryStatus;
  pickupDate: Date;
  notes: string;
  itemsCount: number;
  deliveryMethodName: string;
  saleDocId: number;
  saleDoc: SaleDoc;
  saleChannelId: number;
  saleChannel: SaleChannel;
  storeId: number;
  store: Store;

  constructor(_storeId?: number) {
    this.id = 0;
    this.shipperChargeAmount = 0;
    this.orderStatus = OrderStatus.new;
    this.deliveryMethod = DeliveryMethod.storePickup;
    this.channelOrderId = null;
    this.docNo = 0;
    this.docDate = null;
    this.docTime = null;
    this.accountId = null;
    this.account = new Account();
    this.paymentStatus = SaleChannelPaymentStatus.unpaid;
    this.fulfillmentStatus = SaleChannelFulfillmentStatus.unfulfilled;
    this.subTotal = 0;
    this.email = "";
    this.shipTo = "";
    this.deliveryStatus = SaleChannelDeliveryStatus.pending;
    this.pickupDate = null;
    this.notes = null;
    this.itemsCount = 0;
    this.deliveryMethodName = null;
    this.saleDocId = null;
    this.saleDoc = null;
    this.saleChannelId = null;
    this.saleChannel = null;
    this.storeId = null;
    this.store = null;
  }

  get subTotalCurrencyFormat(): string {
    let formattedTotal;
    const _currencyIsoCode = "CAD";
    if (_currencyIsoCode)
      formattedTotal = new CurrencyPipe(AppConstants.DEFAULT_LOCALE_ID).transform(
        this.subTotal,
        _currencyIsoCode,
        "code"
      );
    else formattedTotal = MonetaryHelpers.roundToDecimalPlaces(this.subTotal).toFixed(2);

    return new FormatNegativeNumberPipe().transform(formattedTotal);
  }
}

export enum OrderStatus {
  new = "NEW",
  accepted = "ACCEPTED",
  pendingRefund = "PENDING REFUND",
  voided = "VOIDED",
}

export enum DeliveryMethod {
  storePickup = "Store Pickup",
  localDelivery = "Local Delivery",
  shipOut = "Ship Out",
}

export enum SaleChannelPaymentStatus {
  unpaid = "Unpaid",
  authorized = "Authorized",
  partlyPaid = "Partly Paid",
  paid = "Paid",
  voided = "Voided",
  partlyRefunded = "Partly Refunded",
  refunded = "Refunded",
  incomplete = "Incomplete",
  disputed = "Disputed",
}

export enum SaleChannelFulfillmentStatus {
  unfulfilled = "Unfulfilled",
  processing = "Processing",
  partlyFulfilled = "Partly Fulfilled",
  completed = "Completed",
  backToStock = "Back to Stock",
  voided = "Voided",
}

export enum SaleChannelDeliveryStatus {
  pending = "Pending",
  readyForPickup = "Ready for Pickup",
  pickedUp = "Picked Up",
  delivered = "Delivered",
  returned = "Returned",
  voided = "Voided",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
