<h3>Tender Types</h3>
<p-table
  id="main-table"
  responsiveLayout="stack"
  breakpoint="640px"
  [value]="_formArray.value"
  styleClass="p-datatable-striped"
  [scrollable]="true"
  scrollHeight="200px"
>
  <ng-template pTemplate="header">
    <tr>
      <th class="col-payType">Pay Type</th>
      <th class="col-date">Date</th>
      <th class="col-time">Time</th>
      <th class="col-refNo">Ref #</th>
      <th class="col-total">Total</th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-tender>
    <tr>
      <td class="col-payType">
        <span class="p-column-title">Pay Type:</span>
        <ng-template [ngIf]="tender.refTransaction">
          {{ tender.refTransaction.CardType }}
        </ng-template>
        <ng-template [ngIf]="!tender.refTransaction">
          {{ tender.tenderType.description }}
        </ng-template>
      </td>
      <td class="col-date">
        <span class="p-column-title">Date:</span>
        {{ tender.createdAt | dateFormat }}
      </td>
      <td class="col-time">
        <span class="p-column-title">Time:</span>
        {{ tender.createdAt | date : "HH:mm:ss" }}
      </td>
      <td class="col-refNo">
        <ng-template [ngIf]="!tender.refTransaction">
          <span class="p-column-title">Ref #:</span>
          {{ tender.refNo }}
        </ng-template>
        <ng-template [ngIf]="tender.refTransaction && tender.refTransaction.RefID !== ''">
          <div class="line-transaction">
            <div class="line-transaction-title">Trans Type:</div>
            <div class="line-transaction-value">{{ tender.refTransaction.TransType | titlecase }}</div>
          </div>
          <div class="line-transaction">
            <div class="line-transaction-title">Auth Code:</div>
            <div class="line-transaction-value">{{ tender.refTransaction.AuthCode }}</div>
          </div>
          <div class="line-transaction">
            <div class="line-transaction-title">Card Type:</div>
            <div class="line-transaction-value">{{ tender.refTransaction.CardType }}</div>
          </div>
          <div class="line-transaction">
            <div class="line-transaction-title">Last 4:</div>
            <div class="line-transaction-value">{{ tender.refTransaction.Last4 }}</div>
          </div>
          <div class="line-transaction">
            <div class="line-transaction-title">Ref ID:</div>
            <div class="line-transaction-value">{{ tender.refTransaction.RefID }}</div>
          </div>
          <div class="line-transaction">
            <div class="line-transaction-title">Entry Method:</div>
            <div class="line-transaction-value">{{ tender.refTransaction.EntryMethod }}</div>
          </div>
        </ng-template>
      </td>
      <td class="col-total">
        <span class="p-column-title">Total:</span>
        {{
          tender.amount
            | currency : tender.tenderType.currencyIsoCode : "symbol-narrow" : decimalFormat
            | formatNegativeNumber
        }}
      </td>
    </tr>
  </ng-template>
</p-table>
