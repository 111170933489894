import { Component, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import * as _ from "lodash";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { GenericFormService } from "src/app/forms/generic-form/generic-form.component";
import { DBService } from "src/app/shared/services/db.service";
import { PrintingFactoryService } from "src/app/shared/services/printing-service.service";
import { Inventory, InventoryStock } from "../inventory/inventory";
import {
  InventoryLabelSettings,
  LabelSizes,
  PrintQty,
  ProductTitle,
  ShowBarcode,
  ShowPrice,
} from "./inventory-label-settings";

@Component({
  selector: "taku-inventory-label-settings",
  templateUrl: "./inventory-label-settings.component.html",
  styleUrls: ["./inventory-label-settings.component.scss"],
  providers: [GenericFormService],
})
export class InventoryLabelSettingsComponent implements OnInit {
  @ViewChild("printPreviewHost", { read: ViewContainerRef, static: true }) printPreviewHost: ViewContainerRef;
  formGroup: UntypedFormGroup;
  isDisplayInventoryLabelSettings = false;
  inventoriesArray: Inventory[];
  showBarcodeVisibility = false;
  showPriceVisibility = false;
  qtyOH = 0;

  enumPrintQty = PrintQty;
  enumProductTitle = ProductTitle;
  enumShowBarcode = ShowBarcode;
  enumShowPrice = ShowPrice;
  enumLabelSizes = this.dbService.enumSelectOptions(LabelSizes, { useKeyAsLabel: false, useNullAsValue: true });

  constructor(
    private fb: UntypedFormBuilder,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dbService: DBService,
    private printingService: PrintingFactoryService
  ) {
    this.inventoriesArray = config.data.inventoriesArray;
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group(new InventoryLabelSettings(0));
    this.qtyOH = this.totalQtyOH(this.inventoriesArray[0].inventoryStocks);
  }

  onClosePressed(): void {
    if (this.ref) {
      this.ref.close();
    }
  }

  onPrintLabelClicked($event): void {
    this.printInventoryLabels(this.inventoriesArray, this.formGroup);
  }

  onCloseDialogClicked($event): void {
    if (this.ref) {
      this.ref.close();
    }
  }

  showBarcodeChanged($event): void {
    if ($event.checked) {
      this.showBarcodeVisibility = true;
      this.formGroup.get("showBarcode").setValue(this.enumShowBarcode.sku);
    } else {
      this.showBarcodeVisibility = false;
      this.formGroup.get("showBarcode").reset();
    }
  }

  showPriceChanged($event): void {
    if ($event.checked) {
      this.showPriceVisibility = true;
      this.formGroup.get("showPrice").setValue(this.enumShowPrice.sellPrice);
    } else {
      this.showPriceVisibility = false;
      this.formGroup.get("showPrice").reset();
    }
  }

  onInputQtyChange($event): void {
    const inputQty = parseInt($event.target.value);
    if (inputQty > 0) {
      this.formGroup.get("printQty").setValue(this.enumPrintQty.perSelected);
    }
  }

  totalQtyOH(allStocks: InventoryStock[]): number {
    return _.sumBy(allStocks, "qtyOH");
  }

  printInventoryLabels(inventoriesArray: Inventory[], formGroup: UntypedFormGroup): void {
    this.printingService.build(null).printInventoryLabels(inventoriesArray, formGroup, this.printPreviewHost);
  }
}
