<div class="desktop-checkout-topWrapper" #container>
  <taku-sale-doc-self-checkout-desktop
    #saleDocComponent
    (onStartOver)="onStartOverPressed($event)"
    (saleLinesCleared)="onItemsCleared()"
    (onReturnToCart)="onReturnToCart()"
    [topWrapperHeight]="container.offsetHeight"
    (onDocSaved)="onDocSaved($event)"
    (onDocReset)="onSelfCheckoutDocReset()"
    (tenderScreenOpened)="openTenderScreen($event)"
    (itemReturned)="itemReturnedAction($event)"
    (onDocLinesDeleted)="onDocLinesDeleted($event)"
    (docLineQtyChanged)="docLineQtyChanged($event)"
  >
    <ng-template #saleDocDataViewTemplate let-saleForm let-dvWidth="dvWidth" let-dvHeight="dvHeight">
      <taku-category-data-view
        #saleDocDataView
        *ngIf="!_showInventoryItems"
        [saleForm]="saleForm"
        (onItemSelected)="showItemsOfCategory($event)"
        [rows]="currentFetchLimit"
        [totalCategories]="totalCategories"
        [productsLoading]="_productsLoading"
        [inSelfCheckout]="true"
        (onLazyLoad)="loadCategoryLazy($event)"
        (onSearchSubmit)="onSearchFieldSubmit()"
        [categories]="categories"
        (onBackToCategories)="goBackToCategories()"
        [dvContainerWidth]="dvWidth"
        [dvContainerHeight]="dvHeight"
      >
      </taku-category-data-view>
      <taku-inventory-data-view
        #saleDocDataView
        *ngIf="_showInventoryItems"
        [saleForm]="saleForm"
        (onItemSelected)="onSelectItem($event)"
        [rows]="currentFetchLimit"
        [totalRecords]="totalRecords"
        [productsLoading]="_productsLoading"
        [inSelfCheckout]="true"
        (onLazyLoad)="loadInventoryLazy($event)"
        (onSearchSubmit)="onSearchFieldSubmit()"
        [inventories]="inventories"
        (onBackToCategories)="goBackToCategories()"
        [dvContainerWidth]="dvWidth"
        [dvContainerHeight]="dvHeight"
        [currentCategoryName]="currentCategory?.categoryName"
      >
        <ng-template #saleDocGridCardTemplate let-inventory let-width="width" let-height="height">
          <taku-sale-doc-self-checkout-grid-card
            [inventory]="inventory"
            [width]="width"
            [height]="width * 1.1"
            [_isMobile]="false"
            (click)="onSelectItem(inventory)"
          >
          </taku-sale-doc-self-checkout-grid-card>
        </ng-template>
      </taku-inventory-data-view>
    </ng-template>
  </taku-sale-doc-self-checkout-desktop>
</div>
