/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { GenericPhone } from "../phone-shared/GenericPhone";

export class AddressPhone implements GenericPhone {
  id: number;
  phoneType: AddressPhoneType;
  countryIsoCode: string;
  tel: string;
  ext: string;

  constructor() {
    this.id = 0;
    this.phoneType = AddressPhoneType.work;
    this.countryIsoCode = "";
    this.tel = "";
    this.ext = "";
  }

  get fullPhoneNumber() {
    return GenericPhone.FormatNumber(this);
  }
}

export enum AddressPhoneType {
  work = "Work",
  home = "Home",
  mobile = "Mobile",
  fax = "Fax",
  other = "Other",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
