<taku-form-dialoge-header
  [pristine]="_myForm.pristine"
  [valid]="_myForm.valid"
  (backClicked)="goBack()"
  (saveClicked)="onSave()"
  (revertClicked)="onRevert()"
  (newClicked)="onNew()"
  (deleteClicked)="onDelete()"
  (cancelClicked)="closeSidebar()"
  (cloneClicked)="onClone()"
  toolbarTitle="TAX DETAILS"
></taku-form-dialoge-header>

<div [formGroup]="_myForm" class="col-12">
  <p-panel>
    <ng-template pTemplate="header">
      <div>
        <h1>Tax Information</h1>
      </div>
      <taku-checkbox id="active_checkbox" [formGroup]="_myForm" [fieldName]="'isEnabled'" fieldCaption="Active">
      </taku-checkbox>
    </ng-template>
    <!-- <div class="col-12 ">Tax Details</div> -->

    <div class="grid col-12">
      <div id="general_tax" class="col-12 lg:col-6">
        <div class="grid">
          <div class="col-12">
            <strong>General Tax</strong>
          </div>

          <div id="tax_name" class="col-12 lg:col-6">
            <taku-input [formGroup]="_myForm" fieldName="shortDesc" fieldCaption="Tax Name"> </taku-input>
          </div>
          <div id="tax_number" class="col-12 lg:col-6">
            <taku-input [formGroup]="_myForm" fieldName="taxAccountNo" fieldCaption="Tax Number"> </taku-input>
          </div>

          <div id="tax_receiver" class="col-12 lg:col-6">
            <taku-input [formGroup]="_myForm" fieldName="fundReceiver" fieldCaption="Tax Receiver"> </taku-input>
          </div>
          <div class="col-12 lg:col-6">
            <taku-currency [formGroup]="_myForm" fieldName="currencyIsoCode" fieldCaption="Currency"></taku-currency>
          </div>

          <div class="col-6" [ngStyle]="{ clear: 'left' }">
            <taku-input [formGroup]="_myForm" fieldName="longDesc" fieldCaption="Long Description"> </taku-input>
          </div>

          <!-- <div id="tax_acronym" class="col-6">
            <taku-input [formGroup]="_myForm" fieldName="taxAcronym" fieldCaption="Printer Acronym"> </taku-input>
          </div> -->

          <div class="col-3">
            <taku-checkbox [formGroup]="_myForm" fieldName="isOnlineTax" fieldCaption="Online Tax" [disabled]="true">
            </taku-checkbox>
          </div>

          <div class="col-3">
            <taku-checkbox [formGroup]="_myForm" fieldName="printOnReceipt" fieldCaption="Receipt Printing">
            </taku-checkbox>
          </div>

          <div class="col-12">
            <hr class="fields-divider" />
            <strong>Dates</strong>
          </div>

          <div class="col-12 lg:col-12">
            <taku-checkbox [formGroup]="_myForm" fieldName="applyEffectiveDate" fieldCaption="Apply Effective Date">
            </taku-checkbox>
          </div>

          <div class="col-12 lg:col-6 calendar-container">
            <taku-calendar-date
              [formGroup]="_myForm"
              fieldName="effectiveDate"
              fieldCaption="Effective Date"
              appendTo="body"
              [disabled]="!_myForm.get('applyEffectiveDate').value"
            ></taku-calendar-date>
          </div>

          <div class="col-12 lg:col-6 calendar-container">
            <taku-calendar-date
              [formGroup]="_myForm"
              fieldName="endDate"
              fieldCaption="End Date"
              appendTo="body"
              [disabled]="!_myForm.get('applyEffectiveDate').value"
            ></taku-calendar-date>
          </div>
        </div>
      </div>

      <div id="calculation_method" class="col-12 lg:col-6">
        <div class="grid">
          <div class="col-12">
            <strong>Calculation Method</strong>
          </div>

          <div class="col-12 lg:col-6">
            <taku-dropdown
              [formGroup]="_myForm"
              [options]="enumTaxTotal"
              fieldName="taxTotal"
              fieldCaption="How to Calculate"
            >
            </taku-dropdown>
          </div>

          <div class="col-12 lg:col-6">
            <taku-dropdown
              [formGroup]="_myForm"
              [options]="enumTaxBase"
              fieldName="taxPriceBasis"
              fieldCaption="Calculate Based on"
            ></taku-dropdown>
          </div>

          <div class="col-6">
            <taku-checkbox [formGroup]="_myForm" fieldName="isFlatTax" fieldCaption="Flat Tax"></taku-checkbox>
          </div>

          <div class="col-6">
            <taku-dropdown
              [formGroup]="_myForm"
              [options]="enumLocationBasis"
              fieldName="taxLocationBasis"
              fieldCaption="Tax Basis"
            >
            </taku-dropdown>
          </div>

          <div class="col-6 inputctrl-wrapper">
            <taku-checkbox
              [formGroup]="_myForm"
              fieldName="isTaxIncluded"
              fieldCaption="Tax Included"
              [disabled]="true"
            >
            </taku-checkbox>
            <i class="pi pi-lock locked-feature"></i>
          </div>

          <div class="col-6">
            <taku-checkbox [formGroup]="_myForm" fieldName="isShippingIncluded" fieldCaption="Shipping Included">
            </taku-checkbox>
          </div>

          <div class="col-12">
            <hr class="fields-divider" />
            <strong>Accounting Method</strong>
          </div>

          <div class="col-6">
            <taku-checkbox [formGroup]="_myForm" fieldName="isRefundable" fieldCaption="Can Refund"> </taku-checkbox>
          </div>

          <div class="col-6">
            <taku-checkbox [formGroup]="_myForm" fieldName="isRecoverable" fieldCaption="Can Recover"> </taku-checkbox>
          </div>

          <div class="col-12">
            <hr class="fields-divider" />
            <strong>Compound Settings</strong>
          </div>

          <div class="col-12 lg:col-6">
            <taku-checkbox [formGroup]="_myForm" fieldName="isCompound" fieldCaption="Compound Tax"> </taku-checkbox>
          </div>

          <div class="col-12 lg:col-6">
            <taku-input-spinner
              [formGroup]="_myForm"
              fieldName="compoundSeq"
              fieldCaption="Compound Sequence"
              [min]="0"
              [max]="127"
              keyFilter="pint"
            ></taku-input-spinner>

            <div
              *ngIf="_compoundSeqStatus$ | async as status"
              [hidden]="!status.isDuplicated || !ctrlcompundCheckbox.value"
              class="taku-warningMsg"
            >
              <p-message severity="warn" text="&nbsp;Sequence number already in use."></p-message>
            </div>
          </div>
        </div>
      </div>
    </div>
  </p-panel>
</div>
<!-- {{_object|json}} -->

<!-- <taku-form-footer></taku-form-footer> -->
