/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { FormListComponent } from "src/app/form-list/form-list/form-list.component";
import { SaleTaxReport } from "./sale-tax-report";
import { ReportFilterFields } from "../../report-filter-fields";
import { SaleTaxReportCols, SalesTaxReportColsFactoryService } from "./sale-tax-report-col.service";
import { SaleTaxReportLocalDataService } from "./sale-tax-report-local-data.service";
import { MessageService } from "primeng/api";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";
import { ReportParamsService } from "../../report-params.service";
import { RowSelectionType } from "../../../../form-list/form-list/form-list";
import { resetReportRowState } from "../../report";
import { LocalDataService } from "src/app/shared/services/local-data.service";

@Component({
  selector: "taku-sale-tax-report",
  templateUrl: "./sale-tax-report.component.html",
  styleUrls: ["./sale-tax-report.component.scss"],
  providers: [SaleTaxReportLocalDataService],
})
export class SaleTaxReportComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];
  @ViewChild("formListReport") formListComponent: FormListComponent;

  _model = "saleTaxReport";
  _stateKey = "FL-" + this._model;
  RowSelectionType = RowSelectionType;
  public reportCols: SaleTaxReportCols;
  dynamicFields: string[] = [];

  constructor(
    private reportColsFactoryService: SalesTaxReportColsFactoryService,
    public saleTaxReportLocalDataService: SaleTaxReportLocalDataService,
    private messageService: MessageService,
    private alertMessagesService: AlertMessagesService,
    public reportParamsService: ReportParamsService,
    private localDataService: LocalDataService
  ) {
    this.reportCols = this.reportColsFactoryService.build([]);
  }

  ngOnInit() {
    this.subsList.push(
      this.reportParamsService.reportParamsChanged$.subscribe((filters: ReportFilterFields) => {
        this.updateReport(filters);

        resetReportRowState(this._stateKey);
      })
    );
  }

  private updateReport(filters: ReportFilterFields) {
    this.reportParamsService._isFetchingData = true;
    this.subsList.push(
      this.saleTaxReportLocalDataService
        .getSaleTaxReport(filters.storeId, filters.stationId, filters.dateFrom, filters.dateTo, 0, 0)
        .subscribe({
          next: (rows) => {
            this.buildFormlistCols(rows && rows.length ? rows[0] : null);
            this.refreshFormList(rows || []);
            this.reportParamsService._isFetchingData = false;
            this.localDataService.updateNewObject(rows);
          },
          error: (error) => {
            this.buildFormlistCols(null);
            // Show toast with error message
            this.messageService.add(this.alertMessagesService.getErrorMessage(error));
            // Empty form list
            this.refreshFormList([]);
            this.reportParamsService._isFetchingData = false;
          },
        })
    );
  }

  private buildFormlistCols(rowData: SaleTaxReport) {
    this.dynamicFields = rowData
      ? Object.keys(rowData).filter((key) => !Object.keys(new SaleTaxReport()).includes(key))
      : [];
    this.reportCols = this.reportColsFactoryService.build(this.dynamicFields);
  }

  private refreshFormList(data: any[]) {
    data.forEach((saleDay) => {
      const saleTaxReport = Object.assign(new SaleTaxReport(), saleDay);
      // This makes store getter result into plain property
      saleDay.totalAmountCalculated = saleTaxReport.totalAmount;

      this.dynamicFields.forEach((key) => {
        if (!key.includes("fundReceiver") && !key.includes("taxAccountNo") && !key.includes("taxCalcType")) {
          if (saleDay[key] === null || saleDay[key] === undefined) {
            saleDay[key] = 0;
          } else if (!isNaN(parseFloat(saleDay[key]))) {
            saleDay[key] = parseFloat(saleDay[key]);
          }
        }
      });
    });
    this.saleTaxReportLocalDataService.initialize(data, this._model);
    setTimeout(() => {
      this.formListComponent.reloadData();
    }, 0);
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
