/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, HostBinding, Input } from "@angular/core";
import { TakuInputCtrlBase } from "../TakuInputCtrlBase";

export enum ToggleCaptionPosition {
  LEFT,
  RIGHT,
}

@Component({
  selector: "taku-toggle",
  templateUrl: "./taku-toggle.component.html",
  styleUrls: ["./taku-toggle.component.scss"],
})
export class TakuToggleComponent extends TakuInputCtrlBase {
  @Input() captionPosition: ToggleCaptionPosition = ToggleCaptionPosition.RIGHT;
  @Input() enabledStateLabel = "ACTIVE";
  @Input() disabledStateLabel = "INACTIVE";
  @HostBinding("class.hasStateLabel")
  @Input()
  showStateLabel = true;

  // In order to avoid caption 'jumping' issue, we need to assign a fixed size to label container
  _stateLabelWidth: number; //In 'EM
  ToggleCaptionPosition = ToggleCaptionPosition;

  @HostBinding("class.caption-left")
  get isCaptionToLeft() {
    return this.captionPosition === ToggleCaptionPosition.LEFT;
  }

  @HostBinding("class.caption-right")
  get isCaptionToRight() {
    return this.captionPosition === ToggleCaptionPosition.RIGHT;
  }

  ngOnInit() {
    super.ngOnInit();
    this._stateLabelWidth = 0.5 * Math.max(this.enabledStateLabel.length, this.disabledStateLabel.length);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
