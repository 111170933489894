<div
  [ngClass]="{ 'layout-inline-menu': true, 'layout-inline-menu-active': app.inlineMenuActive[key] }"
  [ngStyle]="style"
  [class]="styleClass"
>
  <a
    class="layout-inline-menu-action flex flex-row align-items-center"
    [ngClass]="app.isHorizontal() ? 'p-3 lg:col-1 py-3' : 'p-3'"
    (click)="onClick($event)"
    pTooltip="{{ userService.userFullName || userService.userName }}"
    [tooltipDisabled]="isTooltipDisabled"
  >
    <img src="assets/layout/images/KWW.png" alt="avatar" style="width: 32px; height: 32px" />
    <span class="flex flex-column" [ngClass]="{ 'ml-2': !app.isRTL, 'mr-2': app.isRTL }">
      <span class="font-bold">{{ userService.userFullName || userService.userName }}</span>
    </span>
    <i class="layout-inline-menu-icon pi pi-angle-down" [ngClass]="{ 'ml-auto': !app.isRTL, 'mr-auto': app.isRTL }"></i>
  </a>

  <ul
    class="layout-inline-menu-action-panel"
    [@menu]="
      app.inlineMenuActive[key]
        ? isHorizontalActive()
          ? 'visible'
          : 'visibleAnimated'
        : isHorizontalActive()
        ? 'hidden'
        : 'hiddenAnimated'
    "
  >
    <li class="layout-inline-menu-action-item" pTooltip="Profile" [tooltipDisabled]="isTooltipDisabled">
      <a class="flex flex-row align-items-center" [attr.tabindex]="tabIndex">
        <i class="pi pi-cog pi-fw"></i>
        <!-- <i class="material-icons">person</i> -->
        <span>Profile</span>
      </a>
    </li>
    <li *ngIf="userService.role" class="layout-inline-menu-action-item">
      <a class="flex flex-row align-items-center" [attr.tabindex]="tabIndex">
        <i class="pi pi-file-o pi-fw"></i>
        <!-- <i class="material-icons">security</i> -->
        <span class="profile-role">{{ userService.role | userRoleConversion }}</span>
      </a>
    </li>
    <li class="layout-inline-menu-action-item" pTooltip="Logout" [tooltipDisabled]="isTooltipDisabled">
      <a class="flex flex-row align-items-center" [attr.tabindex]="tabIndex" (click)="onLogout()">
        <!-- <a href="javascript://" (click)="onLogout()" class="flex flex-row align-items-center" [attr.tabindex]="!active ? '-1' : null"> -->
        <!-- <i class="material-icons">power_settings_new</i> -->
        <i class="pi pi-power-off pi-fw"></i>
        <span>Logout</span>
      </a>
    </li>
  </ul>
</div>
