/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

export namespace GMC_TooltipMessages {
  export const Connection_Status = {
    CONNECTED: `Your account is fully connected, you can send and publish your inventory on Google`,
    NOT_CONNECTED: `Your account is NOT fully connected yet.`,
  };

  export const GMB_Linking_Status = {
    PENDING_UNVERIFIED: `Google will send a message to the selected GMB Email to confirm your intention to link your Google Merchant Account to GMB. Please check its inbox.`,
    PENDING_VERIFIED: `Your Google My Business email has been verified. Now you can link your locations.`,
    LINKED: `Your GMB account is successfully linked to your Google Merchant Center`,
    UNLINKED: `The selected GMB account is still not linked. Please press 'Request Access' button`,
  };

  export const LIA_InventoryContact_Status = {
    PENDING: `Google has sent a message to the provided Contact Email to confirm the new inventory contact information. Please check its inbox for a message with title "Email Address Change Verification"`,
    ACTIVE: `You Inventory Contact information has been verified.`,
    NOT_SET: `You haven't set Inventory Contact information for this Google Merchant account. Please press the button "Set Contact Information".`,
    INACTIVE: `Your request to change the Inventory Contact Information has been expired. Please send it again by pressing the button "Change Contact Information"`,
  };

  export const LIA_Status = {
    ACTIVE: `The Local Inventory Ads for this Merchant Center is fully active and ready to publish inventory information.`,
    INACTIVE: `The Local Inventory Ads is not active yet. It will become automatically active when at least 50 inventory items are sent and approved into to your Google Merchant Center`,
  };
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
