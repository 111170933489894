import { HttpErrorResponse } from "@angular/common/http";
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { Category } from "src/app/core/inventory/category/category";
import { Inventory, InventoryStock } from "src/app/core/inventory/inventory/inventory";
import { Col, RowSelectionType } from "src/app/form-list/form-list/form-list";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { DBSearchService } from "src/app/shared/services/db-search.service";
import { WebHelpers } from "src/app/utility/WebHelpers";
import { SaleDocLine } from "../sale-doc-line/sale-doc-line";
import { SaleDocListService } from "./sale-doc-list.service";
import * as _ from "lodash";
import { MonetaryHelpers } from "src/app/utility/MonetaryHelpers";
import { SaleDocComponent } from "../sale-doc/sale-doc.component";
import { InventoryDataViewComponent } from "../inventory-data-view/inventory-data-view.component";
import { CategoryDataViewComponent } from "../category-data-view/category-data-view.component";
import { SaleDocService } from "../sale-doc/sale-doc.service";
import { ConfirmationService } from "primeng/api";
import { FilterRows } from "src/app/utility/FormDataHelpers";

export enum DataViewLayoutOptions {
  GRID = "grid",
  LIST = "list",
}

@Component({
  selector: "taku-sale-doc-list",
  templateUrl: "./sale-doc-list.component.html",
  styleUrls: ["./sale-doc-list.component.scss"],
})
export class SaleDocListComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  protected static readonly MAX_INITIAL_RESULTS = 10;
  protected static readonly MAX_MORE_RESULTS = 25;

  subsList: Subscription[] = [];
  private _searchResults: Inventory[];
  orgSearchResults: Inventory[];
  searchResultsInventories: Inventory[];

  @Input() saleForm: UntypedFormGroup;

  @Input() set searchResults(value: Inventory[]) {
    this.orgSearchResults = _.cloneDeep(value);
    this._searchResults = value;
    this._searchResults.map((_inventory) => {
      if (!_inventory["defaultInventoryStock"]) {
        _inventory["defaultInventoryStock"] = new InventoryStock();
      }
      _inventory["defaultInventoryStock"]["committedQty"] = MonetaryHelpers.roundToDecimalPlaces(
        (_inventory?.defaultInventoryStock?.committedQty || 0) +
          this.docComponent._myForm.value.saleDocLines
            .filter((row) => row.docLine.inventoryId === _inventory.id)
            .map((row) => row.qty)
            .reduce((a, b) => a + b, 0),
        4
      );
    });
  }

  get searchResults() {
    return this._searchResults;
  }

  @Input() shortenFormHeaderHeight = false;
  @Input() dvWidth: number;
  @Input() dvHeight: number;
  @Input() docComponent: SaleDocComponent;
  @Output() itemSelected = new EventEmitter<Inventory>();
  @Output() itemViewDetails = new EventEmitter<Inventory>();
  @ViewChild("searchInput") searchField: ElementRef;
  // saleDocListForm: FormGroup;
  searchFieldControl;
  preferredListWidth = 320;
  readonly defaultFilter: FilterRows<Inventory> = {
    parentInventoryId: { matchMode: "equals", value: null, allowNull: true },
    isSellable: { matchMode: "equals", value: true },
    includedInGallery: { matchMode: "equals", value: true },
  };
  isInventoryDetailsVisible = false;
  isInventoryDetailsEnabled = false;
  inventories: Inventory[];
  categories: Category[];
  orgInventories: Inventory[];
  showEmptyMessage = true;
  _isFetchingData = false;
  _isDataReady = false;
  _galleryDataLoading = false;
  _activeTab = 0;
  _showClearButton = false;
  // _showItems = false;
  _showInventoryItems = false;
  _dvLayoutOption: DataViewLayoutOptions = DataViewLayoutOptions.GRID;

  // storeId: number;
  // defaultStockId: number;
  // zoneId: number;
  _extraQueryParams: {};
  _formListCols: Col[];
  _formListRules: {};
  RowSelectionType = RowSelectionType;
  currentFetchLimit: number = SaleDocListComponent.MAX_INITIAL_RESULTS;
  totalRecords: number;
  totalCategories: number;
  lazyLoadFilter = "";
  allCategoriesFilter;
  currentCategory: Category;

  @ViewChild("saleDocDataView") saleDocDataViewComponent: InventoryDataViewComponent | CategoryDataViewComponent;
  @ViewChild("searchResultsDataView") searchResultsDataViewComponent: InventoryDataViewComponent;

  constructor(
    @Inject(SaleDocListService) public saleDocListService: SaleDocListService,
    private appSettingsService: AppSettingsStorageService,
    private auth: AuthService,
    private dbService: SaleDocService,
    private dataService: DBSearchService,
    public fb: UntypedFormBuilder,
    protected confirmationService: ConfirmationService
  ) {
    // this.storeId = this.appSettingsService.getStoreId();
    // this.defaultStockId = this.storeId ? this.appSettingsService.getStore().stockId : null;
    // this.zoneId = this.appSettingsService.getZoneId();

    this.allCategoriesFilter = {
      isEnabled: {
        value: true,
        matchMode: "equals",
      },
      includedInGallery: {
        value: true,
        matchMode: "equals",
      },
    };
  }

  ngOnInit(): void {
    this._activeTab = null;
    setTimeout(() => {
      // this._activeTab = 0;
      this.showGalleryTab();
      // this.getAllCategories(0);
      this.getAllCategories(0, this.saleDocDataViewComponent?.newFetchLimit(this._dvLayoutOption));
    }, 0);

    // if (this.storeId && this.defaultStockId) {
    //   this._extraQueryParams = { stockId: this.defaultStockId, zoneId : this.zoneId }
    // }

    this.isInventoryDetailsVisible = this.auth.hasVisiblePermit("Inventory_Menu_Inventory_List");
    this.isInventoryDetailsEnabled = this.auth.hasEnablePermit("Inventory_Menu_Inventory_List");

    this.subsList.push(
      this.saleDocListService.navigateInventory.subscribe((inventory) => {
        this.itemViewDetails.emit(inventory);
      })
    );

    this.subsList.push(
      this.saleDocListService.addToSaleDoc.subscribe((inventory) => {
        this.itemSelected.emit(inventory);
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["saleForm"]) {
      if (this._showInventoryItems) {
        const categoryId: number = this.currentCategory?.id ?? null;
        this.searchInventory(
          0,
          undefined,
          this.saleDocDataViewComponent?.newFetchLimit(this._dvLayoutOption),
          categoryId
        );
      } else {
        this.goBackToCategories();
      }
    }
    if (changes["searchResults"] && this._searchResults.length > 0) {
      this.updateFetchLimit(this.searchResultsDataViewComponent.newFetchLimit(DataViewLayoutOptions.LIST));
      // should I create a separate fetchlimit property for searchResults ??
      this.searchResultsInventories = this.orgSearchResults.slice(0, this.currentFetchLimit);
    }
  }

  loadSearchResultsLazy(event) {
    setTimeout(() => {
      this.searchResultsInventories = this.orgSearchResults.slice(event.first, event.first + this.currentFetchLimit);
    }, 0);
  }

  loadInventoryLazy(event): void {
    let categoryId: number;
    if (!this.saleDocDataViewComponent?.searchFieldControl?.value) {
      if (this.currentCategory) {
        categoryId = this.currentCategory.id;
      }
    }
    this.searchInventory(
      event.first,
      this.saleDocDataViewComponent?.searchFieldControl?.value,
      this.saleDocDataViewComponent?.newFetchLimit(this._dvLayoutOption),
      categoryId
    );
  }

  loadCategoryLazy(event) {
    setTimeout(() => {
      this.getAllCategories(event.first, this.saleDocDataViewComponent?.newFetchLimit());
    }, 0);
    // this.getAllCategories(event.first);
  }

  updateFetchLimit(limit: number) {
    this.currentFetchLimit = limit;
  }

  getAllCategories(_offset: number, newLimit: number) {
    this._galleryDataLoading = true;
    if (newLimit) {
      this.updateFetchLimit(newLimit);
    }
    this.subsList.push(
      this.dbService
        .getRows("category", JSON.stringify(this.allCategoriesFilter), _offset, -1, "categoryName", 1)
        .subscribe({
          next: (categories) => {
            if (categories) {
              this.totalCategories = categories.count;
              this.categories = [...categories.rows];
            }
          },
          error: (err) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status == 404 && err.error.success === false) {
                this.categories = [];
                this.totalCategories = 0;
                this._galleryDataLoading = false;
              }
            }
          },
          complete: () => {
            this._galleryDataLoading = false;
          },
        })
    );
  }

  searchInventory(offset: number, query?: string, _updatedFetchLimit?: number, categoryId?: number): void {
    this._galleryDataLoading = true;
    const defaultStockId: number = this.appSettingsService.getStore().stockId;
    const zoneId: number = this.appSettingsService.getZoneId();
    const _extraQueryParams = {
      stockId: defaultStockId,
      zoneId: zoneId,
    };
    let searchQuery: string;
    offset = offset || 0;
    if (query) {
      searchQuery = query;
    } else {
      searchQuery = this.saleDocDataViewComponent?.searchFieldControl?.value || "";
    }
    let limit = searchQuery ? 100 : 10;
    if (_updatedFetchLimit) {
      this.updateFetchLimit(_updatedFetchLimit);
    }
    const filter = _.cloneDeep(this.defaultFilter);
    if (categoryId) {
      filter["categories.id"] = { matchMode: "equals", value: categoryId };
      limit = 100;
    }

    this.subsList.push(
      this.dataService
        .searchInventory({
          searchValue: searchQuery,
          _filter: JSON.stringify(filter),
          _offset: offset,
          _limit: limit,
          extraQueryParams: _extraQueryParams,
        })
        .subscribe({
          next: (results) => {
            if (results) {
              this.totalRecords = results.count;
              if (results.count > 0) {
                const sellableInventories = results.rows; //.filter((inventory) => inventory.isSellable);
                if (sellableInventories.length > 0) {
                  // this.inventories = [...results.rows];
                  // this.orgInventories = _.cloneDeep(results.rows);
                  this.inventories = [...sellableInventories];
                  this.orgInventories = _.cloneDeep(sellableInventories);
                  this.inventories.map((_inventory) => {
                    if (!_inventory["defaultInventoryStock"]) {
                      _inventory["defaultInventoryStock"] = new InventoryStock();
                    }
                    // This is to retrieve the previous selected qty when route to home and come back
                    _inventory["defaultInventoryStock"]["qtyInCart"] = this.docComponent._myForm.value.saleDocLines
                      .filter(
                        (row) =>
                          row.docLine.inventoryId === _inventory.id ||
                          row.docLine.inventory.parentInventoryId === _inventory.id
                      )
                      .map((row) => row.qty)
                      .reduce((a, b) => a + b, 0);
                    _inventory["defaultInventoryStock"]["committedQty"] = MonetaryHelpers.roundToDecimalPlaces(
                      (_inventory?.defaultInventoryStock?.committedQty || 0) +
                        this.docComponent._myForm.value.saleDocLines
                          .filter((row) => row.docLine.inventoryId === _inventory.id)
                          .map((row) => row.qty)
                          .reduce((a, b) => a + b, 0),
                      4
                    );
                  });
                  // this.totalRecords = sellableInventories.length;
                  // this.totalRecords = results.count;
                } else {
                  this.showEmptyMessage = false;
                  // this item cannot be sold confirmationservice
                  this.confirmationService.confirm({
                    header: `NOTE`,
                    message: `Sorry, this item cannot be sold. Please speak to an associate.\n`,
                    acceptLabel: "Okay",
                    rejectVisible: false,
                    accept: () => {
                      this.showGalleryTab();
                      this.saleDocDataViewComponent.clearSearchField();
                      this.saleDocDataViewComponent.autoSelectSearchField();
                    },
                  });
                }
              }
            }
          },
          error: (err) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status == 404 && err.error.success === false) {
                this.showEmptyMessage = false;
                // no results found confirmationservice
                this.confirmationService.confirm({
                  header: `Inventory search: No Results Found`,
                  message: this.currentCategory
                    ? `It looks like there isn't any inventory under this category`
                    : `We couldn't find any inventory matching your search of '${searchQuery}'\n`,
                  acceptLabel: "Okay",
                  rejectVisible: false,
                  accept: () => {
                    this.showGalleryTab();
                    this.saleDocDataViewComponent.clearSearchField();
                    this.saleDocDataViewComponent.autoSelectSearchField();
                  },
                });
                this.inventories = [];
                this.orgInventories = [];
                this.totalRecords = 0;
                this._galleryDataLoading = false;
                // this.lastSelectedCategory = this.currentCategory;
                // this.currentCategory = undefined;
                if (!categoryId) {
                  this.currentCategory = undefined;
                }
              }
            }
          },
          complete: () => {
            this._galleryDataLoading = false;
            // this.lastSelectedCategory = this.currentCategory;
            if (!categoryId) {
              this.currentCategory = undefined;
            }
          },
        })
    );
  }

  undoAllSale() {
    this.inventories = _.cloneDeep(this.orgInventories);
    this.searchResults = _.cloneDeep(this.orgSearchResults);
  }

  saleQtyChanged(saleDocLine: SaleDocLine, qty: number) {
    const _inventory = this.orgInventories?.find(
      (row) => row.id === saleDocLine.docLine.inventoryId || row.id === saleDocLine.docLine.inventory.parentInventoryId
    );

    if (_inventory) {
      this.inventories.find(
        (row) =>
          row.id === saleDocLine.docLine.inventoryId || row.id === saleDocLine.docLine.inventory.parentInventoryId
      ).defaultInventoryStock.committedQty = MonetaryHelpers.roundToDecimalPlaces(
        (_inventory.defaultInventoryStock?.committedQty || 0) - qty,
        4
      );
      this.inventories.find(
        (row) =>
          row.id === saleDocLine.docLine.inventoryId || row.id === saleDocLine.docLine.inventory.parentInventoryId
      )["defaultInventoryStock"]["qtyInCart"] = qty * -1;
    }

    const _searchResults = this.orgSearchResults?.find(
      (row) => row.id === saleDocLine.docLine.inventoryId || row.id === saleDocLine.docLine.inventory.parentInventoryId
    );
    if (_searchResults) {
      this.searchResults.find(
        (row) =>
          row.id === saleDocLine.docLine.inventoryId || row.id === saleDocLine.docLine.inventory.parentInventoryId
      ).defaultInventoryStock.committedQty = MonetaryHelpers.roundToDecimalPlaces(
        (_searchResults.defaultInventoryStock?.committedQty || 0) - qty,
        4
      );
      this.searchResults.find(
        (row) =>
          row.id === saleDocLine.docLine.inventoryId || row.id === saleDocLine.docLine.inventory.parentInventoryId
      )["defaultInventoryStock"]["qtyInCart"] = qty * -1;
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this._formListCols = this.saleDocListService.getFormListColumns();

      this._formListRules = this.saleDocListService.getValidationRules();
      this._isDataReady = true;
    }, 0);
  }

  onSelectItem(item: Inventory, e: Event): void {
    e.stopPropagation();
    this.itemSelected.emit(item);
  }

  showItemsOfCategory(category: Category): void {
    this._showInventoryItems = true;
    this.currentCategory = category;
  }

  goBackToCategories(): void {
    this._showInventoryItems = false;
    this.currentCategory = undefined;
    this.saleDocDataViewComponent?.clearSearchField();
    this.saleDocDataViewComponent?.autoSelectSearchField();
    this.inventories = [];
    // this._showCategories = true;
    // if I call this here, it gets called twice as it gets called from ngOnInit anyway
    // this.getAllCategories(0);
    this.currentCategory = undefined;
    this._dvLayoutOption = DataViewLayoutOptions.GRID;
  }

  onClickedViewDetails(item: Inventory, e: Event): void {
    document.body.style.cursor = "wait";
    e.stopPropagation();
    if (this.isInventoryDetailsEnabled && this.isInventoryDetailsVisible) {
      this.subsList.push(
        this.dbService.getRow("inventory", item.id).subscribe((inventory) => {
          this.itemViewDetails.emit(inventory);
          document.body.style.cursor = "auto";
        })
      );
    }
  }

  get isMobile() {
    return !WebHelpers.isDesktopScreen();
  }

  dataViewHeaderMobileRange(): boolean {
    return WebHelpers.isSmallerMobileScreen();
  }

  handleTabChange(e) {
    // console.log(`The active tab is ${e.index}`);
    if (e.index == 2) {
      this.updateFetchLimit(this.searchResultsDataViewComponent?.newFetchLimit(DataViewLayoutOptions.LIST));
    } else {
      this.updateFetchLimit(this.saleDocDataViewComponent?.newFetchLimit(this._dvLayoutOption));
    }
  }

  onLayoutOptionChange(e) {
    if (this._dvLayoutOption !== e.layout) {
      this._dvLayoutOption = e.layout;
      let categoryId: number = null;
      if (this.currentCategory) {
        categoryId = this.currentCategory.id;
      }
      this.searchInventory(
        0,
        undefined,
        this.saleDocDataViewComponent?.newFetchLimit(this._dvLayoutOption),
        categoryId
      );
    }
    // console.log(`dataview layout changed to ${this._dvLayoutOption}`);
    // this.updateFetchLimit(this.newFetchLimit);
  }

  get _countTotalItems(): number {
    let countItems = 0;
    this.saleDocLines.controls.forEach((docLineForm: UntypedFormGroup) => {
      const qty = parseFloat(docLineForm.get("qty").value);
      if (!isNaN(qty)) countItems += qty;
    });

    return MonetaryHelpers.roundToDecimalPlaces(countItems, 4);
  }

  get saleDocLines(): UntypedFormArray {
    return this.saleForm.get("saleDocLines") as UntypedFormArray;
  }

  showGalleryTab() {
    this._activeTab = 0;
    this._showInventoryItems = false;
    this.showEmptyMessage = true;
    this.currentCategory = undefined;
    // this._showCategories = true;
    // this._showItems = false;
  }

  onSearchFieldSubmit() {
    this.searchInventory(
      0,
      this.saleDocDataViewComponent?.searchFieldControl?.value,
      this.saleDocDataViewComponent?.newFetchLimit(this._dvLayoutOption)
    );

    if (this._showInventoryItems) {
      this.currentCategory = undefined;
    } else {
      this._showInventoryItems = true;
    }
  }

  get calculatedDvWidth() {
    if (this.isMobile) {
      return this.dvWidth;
    } else {
      return this.dvWidth - 60;
    }
  }

  get calculatedDvHeight() {
    if (this.isMobile) {
      return this.dvHeight - 270;
    } else {
      // return this.dvHeight - 312;
      return this.dvHeight - 312 + 100;
    }
  }

  get searchResultsContainerHeight() {
    // if (this.isMobile) {
    //   return this.dvHeight;
    // } else {
    //   return this.dvHeight - 100;
    // }
    return this.calculatedDvHeight + 127;
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}
