<div [formGroup]="formGroup" class="p-float-label" [ngClass]="{ 'inputctrl-nofilled': !isFilled }">
  <p-dropdown
    (onClick)="handleClick($event)"
    (onChange)="handleChange($event)"
    [readonly]="readonly"
    [options]="options"
    [optionLabel]="'label'"
    [optionValue]="'value'"
    formControlName="{{ fieldName }}"
    [group]="group"
    [appendTo]="appendTo"
    [dataKey]="dataKey"
    [id]="fieldName"
    [ngClass]="{ 'inputctrl-filled': isFilled, mobile: isMobileDevice }"
    [filter]="useFilter"
  >
    <ng-template let-group pTemplate="group">
      <div class="optionGroup-wrapper">{{ group.label }}</div>
    </ng-template>

    <ng-template let-item pTemplate="item">
      <div class="ui-helper-clearfix item-wrapper">
        <i *ngIf="item.icon" class="item-icon {{ item.icon }}"></i>
        <span class="item-label">{{ item.label }}</span>
      </div>
    </ng-template>
  </p-dropdown>

  <label *ngIf="fieldCaption" for="{{ fieldName }}" [innerHTML]="fullFieldCaption"></label>
  <form-validation-messages
    *ngIf="showValidationErrors"
    [hidden]="ctrlField.valid || !ctrlField.dirty"
    [errors]="ctrlField.errors"
    [fieldName]="fieldName"
  ></form-validation-messages>
</div>
