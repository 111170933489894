/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Location } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from "lodash";
import { ConfirmationService, Message, MessageService } from "primeng/api";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";
import { PrintingFactoryService } from "src/app/shared/services/printing-service.service";
import { DBMode } from "../../../../forms/generic-form/generic-form.component";
import { InvoiceValidators } from "../../../../shared/validators/InvoiceValidators";
import { SearchResultItem } from "../../../../taku-ui/taku-search-accounts/SearchResultItem";
import { FormDataHelpers } from "../../../../utility/FormDataHelpers";
import { Account, AccountType } from "../../../contact-accounts/account/account";
import { AddressType } from "../../../contact-accounts/address/address";
import { AddressComponent } from "../../../contact-accounts/address/address.component";
import { CommercialAccount } from "../../../contact-accounts/commercial-account/commercial-account";
import { PersonalAccount } from "../../../contact-accounts/personal-account/personal-account";
import { Inventory } from "../../../inventory/inventory/inventory";
import { SaleDocEntryMethod } from "../../../settings/store-settings/sales-doc-settings/sales-doc-settings";
import { Doc, DocState } from "../../doc/doc";
import { SingleQtyReturned } from "../../return-document/document-search-return/document-search-return.component";
import { CompoundAccount } from "../crm-sale/crm-sale.component";
import { FullSizeDialogName, GenericSaleDocComponent, SaveDocActionType } from "../generic-sale-doc.component";
import { DiscountType, SaleDocLine, SaleDocLineReturn } from "../sale-doc-line/sale-doc-line";
import { SaleDocLineComponent } from "../sale-doc-line/sale-doc-line.component";
import { SaleInvoiceStateService } from "../sale-invoice-state.service";
import { SaledocDeliveryMethodComponent } from "../saledoc-delivery-method/saledoc-delivery-method.component";
import { SaleDoc } from "./sale-doc";
import { SaleDocService } from "./sale-doc.service";
import { InvoiceKeyboardActionsService } from "../sale-invoice-doc-base/invoice-keyboard-actions.service";
import { AppComponent } from "src/app/app.component";
import { MonerisService } from "src/app/core/settings/integration-settings/moneris-terminal/moneris.service";
import { TakuPayService } from "src/app/core/settings/integration-settings/taku-pay/taku-pay.service";
import { PriceLevel } from "src/app/core/price-levels/price-level";
import { StoreTenderTypesSettingsService } from "src/app/core/settings/store-settings/store-tender-type-settings/tender-types-settings.service";
import { PriceLevelService } from "src/app/core/price-levels/price-level-service";
import { Voucher } from "../create-voucher/voucher";
import { TimeHelpers } from "src/app/utility/TimeHelpers";

@Component({
  selector: "taku-sale-doc",
  templateUrl: "./sale-doc.component.html",
  styleUrls: ["./sale-doc.component.scss"],
})
export class SaleDocComponent extends GenericSaleDocComponent implements OnInit, AfterViewInit {
  constructor(
    public app: AppComponent,
    _router: Router,
    fb: UntypedFormBuilder,
    dbService: SaleDocService,
    location: Location,
    _route: ActivatedRoute,
    messageService: MessageService,
    alertMessage: AlertMessagesService,
    confirmationService: ConfirmationService,
    appSettingsService: AppSettingsStorageService,
    changeDetectorRef: ChangeDetectorRef,
    invoiceStateService: SaleInvoiceStateService,
    printingFactoryService: PrintingFactoryService,
    ngZone: NgZone,
    keyboardActionsService: InvoiceKeyboardActionsService,
    elRef: ElementRef,
    monerisService: MonerisService,
    takuPayService: TakuPayService,
    storeTenderTypesSettingsService: StoreTenderTypesSettingsService,
    priceLevelService: PriceLevelService
  ) {
    super(
      app,
      _router,
      fb,
      dbService,
      location,
      _route,
      messageService,
      alertMessage,
      confirmationService,
      appSettingsService,
      changeDetectorRef,
      invoiceStateService,
      printingFactoryService,
      ngZone,
      keyboardActionsService,
      elRef,
      monerisService,
      takuPayService,
      storeTenderTypesSettingsService,
      priceLevelService
    );
  }

  get canMakeReturns(): boolean {
    const returnSettings = this._invoiceSettings.returnSettings;
    if (!returnSettings) return false;

    return returnSettings.canReturnOnSaleScreen;
  }

  @Output() saleLinesCleared: EventEmitter<any> = new EventEmitter();
  @Output() inventoryItemSaved: EventEmitter<Inventory> = new EventEmitter();
  @Output() docLineQtyChanged: EventEmitter<{ saleDocLines: SaleDocLine[]; saleDocLine: SaleDocLine; newQty: number }> =
    new EventEmitter();
  @Output() itemReturned: EventEmitter<number> = new EventEmitter();
  @Output() onDocParked: EventEmitter<any> = new EventEmitter();

  debug = false;
  // static readonly DECIMAL_PLACES = 2;
  _decimalFormatting = "1.2-2";
  msgs: Message[] = [];
  loading = true;

  _myForm: UntypedFormGroup;

  // searchedItemText = "";
  searchedCustomerText = "";
  successMessageEntityName = "Sale";

  // invoice: SaleDoc;

  @ViewChild(SaledocDeliveryMethodComponent) deliveryMethodComponent: SaledocDeliveryMethodComponent;
  currentFetchLimit = 10;

  static init(fb, saleDoc: SaleDoc): UntypedFormGroup {
    const tmpForm = fb.group(saleDoc);
    tmpForm.setControl("saleDocLines", fb.array([]));
    tmpForm.setControl("saleDocTenders", fb.array([]));
    tmpForm.setControl("doc", fb.group(saleDoc.doc));
    tmpForm.setControl("billingAddress", AddressComponent.init(fb, null, AddressType.billingAddress));
    tmpForm.setControl("shippingAddress", AddressComponent.init(fb, null, AddressType.shippingAddress));
    tmpForm.controls["deliveryDate"].setValidators([Validators.required]);
    // CRM compound objects
    // tmpForm.setControl( 'email', fb.group(tmpObject.email) );
    // tmpForm.setControl( 'phone', fb.group(tmpObject.phone) );
    return tmpForm;
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (history.state?.unParked === true) {
      const newDateTime = this.setDocDateTime(this._object.doc);
      this._myForm.controls.doc.patchValue(newDateTime);
    }
  }

  loadObject() {
    super.loadObject();
    this.postprocessForm();
  }

  applyBulkDiscount($event: { discountType: DiscountType; discountAmount: number }): void {
    this._myForm.get("saleDocLines")["controls"].map((_saleDocLineCtrl) => {
      if ($event.discountType === DiscountType.Percentage) {
        _saleDocLineCtrl.get("discountType").setValue($event.discountType, { emitEvent: false });
        _saleDocLineCtrl
          .get("discountAmount")
          .setValue(
            Math.min(
              _saleDocLineCtrl.value.docLine.inventory.allowDiscount
                ? Number(_saleDocLineCtrl.value.docLine.inventory.maxDiscountPercentage)
                : 100,
              $event.discountAmount
            )
          );
      }
    });
  }

  private setDocDateTime(doc: Doc): Pick<Doc, "docDate" | "docTime"> {
    const [docDate, docTime] = TimeHelpers.splitDateAndTimeTZ(
      new Date(),
      this.appSettingsService.getStore().storeTimeZone
    );
    doc.docDate = docDate;
    doc.docTime = docTime;
    return { docDate, docTime };
  }

  reopenParkedSale(saleDoc: SaleDoc): void {
    const openParkedSaleAction = () => {
      this.closeFullSizeDialog();

      this._object = saleDoc;
      this._object.doc.state = DocState.suspended;
      this.setDocDateTime(this._object.doc);
      this._id = saleDoc.id;
      this.dbMode = DBMode.edit;
      // this.loadObject();
      // this.resetForm(); // as invoice was parked we update the taxes
      this._myForm.reset(this._object, { emitEvent: false });
      this.invoiceNoteCtrl.markAsDirty();
      this.postprocessForm();
      this._selectedAccount = this.buildAccountSearchResult(saleDoc);
      this._saveDocAction = { type: SaveDocActionType.TENDER_SCREEN };
      this.applyAccountPriceLevel(this._selectedAccount.data.account);
    };

    if (saleDoc.id === this._id) {
      // If trying to reopen the same document, just do nothing
      this.confirmationService.confirm({
        header: "Opening a Parked Sale again",
        message:
          "We noticed you are trying to open the active parked sale. <br><br>" +
          "This won't change the current invoice.",
        acceptLabel: "OK",
        rejectVisible: false,
      });
      return;
    }

    if (this.saleDocLines.length > 0) {
      // if we have unsaved lines show confirmation alert
      this.confirmationService.confirm({
        header: "Confirmation to discard current sale",
        message: "By reopening the parked sale, you will lose your current changes. Are you sure?",
        acceptLabel: "YES",
        rejectLabel: "NO",
        rejectButtonStyleClass: "p-button-link",
        accept: () => {
          this._clearSaleDocLines();
          openParkedSaleAction();
        },
      });
    } else {
      this._clearSaleDocLines();
      openParkedSaleAction();
    }
  }

  openAccountEditForm(accountData: CompoundAccount) {
    switch (accountData.account.accountType) {
      case AccountType.commercial:
        this.openFullSizeDialog(FullSizeDialogName.COMMERCIAL_ACCOUNT, accountData, {
          showHeader: false,
        });
        break;
      case AccountType.personal:
        this.openFullSizeDialog(FullSizeDialogName.PERSONAL_ACCOUNT, accountData, {
          showHeader: false,
        });
        break;
    }
  }

  openConsentEditForm(personFormAndAccountData: { personForm: UntypedFormGroup; accountData: PersonalAccount }) {
    // let personFormAndAccountData = {personForm: personForm, accountData: accountData}
    this.openFullSizeDialog(FullSizeDialogName.CUSTOMER_CONSENT, personFormAndAccountData, {
      showHeader: false,
    });
  }

  initForm() {
    super.initForm();
    this._myForm = SaleDocComponent.init(this.fb, this._object);
  }

  onAcceptOpenCashoutAction() {
    this._router.navigate(["/sell/cashout", 0]);
  }

  setFormArrays() {
    super.setFormArrays();

    this._myForm.setControl("saleDocLines", SaleDocLineComponent.setArray(this.fb, this._object.saleDocLines));
  }

  onInventoryItemSaved(inventory: Inventory) {
    this.inventoryItemSaved.emit(inventory);
    this.findAndUpdateDocLines(inventory);
    this.closeFullSizeDialog();
  }

  // _showSearchView() {
  //   this.openRightDialog(SideDialogName.SEARCH_RESULTS);
  // }

  // onCustomerSearch(value: string) {
  //   this.searchedCustomerText = value;
  //   this._searchAll().subscribe(data => {
  //     this.inventorySearchResults = null;
  //     this.accountsSearchResults = data.rows;
  //     this._showSearchView();
  //   });
  // }

  ////////////////////////////////////////////// End of Dialogs //////////////////////////////////////////////////////////

  onNewPersonalAccountCreated(personalAccount: PersonalAccount) {
    const accountResultItem = SearchResultItem.build("personalAccount", personalAccount);
    this._setAsDocAccount(accountResultItem);
    this.closeFullSizeDialog();
  }

  onNewCommercialAccountCreated(commecialAccount: CommercialAccount) {
    const accountResultItem = SearchResultItem.build("commercialAccount", commecialAccount);
    this._setAsDocAccount(accountResultItem);
    this.closeFullSizeDialog();
  }

  findAndUpdateDocLines(inventory: Inventory): any {
    for (let i = 0; i < this.saleDocLines.length; i++) {
      const theSaleLine = this.saleDocLines.at(i) as UntypedFormGroup;
      // This items already exist in sale document
      if (theSaleLine.get("docLine.inventoryId").value === inventory.id) {
        this.updateSaleDocLine(inventory, theSaleLine);
      }
    }
  }

  onNewInventoryItem(inventory: Inventory) {
    this.addToSaleDoc(inventory);
    this.closeFullSizeDialog();
    this.inventoryItemSaved.emit(inventory);
  }

  _setAsDocAccount(account: SearchResultItem): void {
    if (!account) {
      this.clearSelectedAccount();
      return;
    }
    this.applyAccountPriceLevel(account.data.account);
    const accountField = this._myForm.get("accountId");
    accountField.setValue(account.ID);
    this._myForm.get("taxAccountCategoryId").setValue(account.data.account.taxAccountCategoryId);
    this._myForm.get("taxID").setValue(account.data.account.taxID);
    accountField.markAsDirty();

    this._selectedAccount = account;
  }

  private applyAccountPriceLevel(account: Account): void {
    if (account.acctGroups.length) {
      // Get price levels
      this.subsList.push(
        this.priceLevelsForStore.subscribe((priceLevels: PriceLevel[]) => {
          this.activePriceLevel = priceLevels.find((priceLevel) =>
            account.acctGroups.some((acctGroup) => acctGroup.id === priceLevel.acctGroupId)
          );
          if (this.activePriceLevel) {
            this.applyBulkDiscount({
              discountType: DiscountType.Percentage,
              discountAmount: this.getActivePriceLevelDiscountAmount(),
            });
          }
        })
      );
    } else {
      this.activePriceLevel = null;
    }
  }

  private getActivePriceLevelDiscountAmount(): number {
    if (!this.activePriceLevel) {
      return 0;
    }
    return Number(this.activePriceLevel.adjustBy);
  }

  // The Component should be able to display, add, and remove items from the saleDocLines FormArray.
  // Use the FormGroup.get method to acquire a reference to that FormArray. Wrap the expression in a
  // saleDocLines convenience property for clarity and re-use.

  addToSaleDoc(inventory: Inventory, qty = 1) {
    this._addToSaleDoc(inventory, qty);
  }

  private _addToSaleDoc(inventory: Inventory, qty: number) {
    if (!this._invoiceSettings.salesDocSettings) {
      this._addToSaleAsNewLine(inventory, qty);
      return;
    }

    switch (this._invoiceSettings.salesDocSettings.entryMethod) {
      case SaleDocEntryMethod.AccumulateLastLine:
        this._addToSaleCombineLastLineOnly(inventory, qty);
        break;

      case SaleDocEntryMethod.CombineQty:
        this._addToSaleCombineEveryLine(inventory, qty);
        break;

      case SaleDocEntryMethod.NewLine:
      default:
        this._addToSaleAsNewLine(inventory, qty);
    }
  }

  _addToSaleCombineEveryLine(inventory: Inventory, qty: number): any {
    let skuFound = false;
    for (let index = 0; index < this.saleDocLines.length; index++) {
      const theSaleDocLine = this.saleDocLines.at(index);
      if (theSaleDocLine.get("qty").value > 0 && this._compareLineBySKU(inventory, theSaleDocLine)) {
        for (let i = 0; i < qty; i++) {
          this.incQty(index);
        }
        this._highlightAndShowLine(index);
        skuFound = true;
      }
    }

    if (!skuFound)
      // If the inventory wasn't in the whole invoice, add it as a new line
      this._addToSaleAsNewLine(inventory, qty);
  }

  _addToSaleCombineLastLineOnly(inventory: Inventory, qty: number): any {
    let skuFound = false;
    const index = this.saleDocLines.length - 1;
    if (index >= 0) {
      const theSaleDocLine = this.saleDocLines.at(index);
      // Don't combine line if it has negative value (is return)
      if (theSaleDocLine.get("qty").value > 0 && this._compareLineBySKU(inventory, theSaleDocLine)) {
        for (let i = 0; i < qty; i++) {
          this.incQty(index);
        }
        this._highlightAndShowLine(index);
        skuFound = true;
      }
    }

    if (!skuFound)
      // if last doc line doesn't contain inventory by sku, add it as a new line
      this._addToSaleAsNewLine(inventory, qty);
  }

  _compareLineBySKU(inventory: Inventory, saleLine: AbstractControl) {
    return inventory.sku === saleLine.get("sku").value;
  }

  onDeliveryMethod(method) {
    this._object.deliveryMethod = method;
  }

  _addToSaleAsNewLine(inventory: Inventory, qty: number, isReturn = false, voucher?: Voucher): void {
    const saleLine: SaleDocLine = _.merge(new SaleDocLine(), {
      unitPrice: voucher?.value ?? inventory.standardPrice,
      unitCost: inventory.defaultInventoryZone?.standardCost || 0,
      qty: isReturn ? -1 * qty : qty,
      // TODO: Fill out DocLine
      docLine: {
        id: 0,
        seqNo:
          (this.saleDocLines.value.length
            ? Math.max(
                ...this.saleDocLines.value.map((row) => {
                  return row.docLine.seqNo;
                })
              )
            : 0) + 1,
        note: "",
        expiryDate: null,
        serialNo: "",
        refNo: 0,
        inventoryId: inventory.id,
        inventory: inventory,
        userId: this._object.doc.userId,
      },
    } as SaleDocLine);
    if (voucher) {
      saleLine.voucherId = 0;
      saleLine.voucher = voucher;
    }
    if (this.activePriceLevel) {
      saleLine.discountType = DiscountType.Percentage;
      saleLine.discountAmount = this.getActivePriceLevelDiscountAmount();
    }

    // When we are returning inventory from Sales Screen, there is no minimum. But regular sales as 0 as minimum
    const docLineForm = SaleDocLineComponent.set(this.fb, saleLine, isReturn ? null : 0);

    this.saleDocLines.push(docLineForm); // adding an empty line to formArray
    this.saleDocLines.markAsDirty();
    this._highlightAndShowLine(this.saleDocLines.length - 1);

    this.docLineQtyChanged.emit({ saleDocLines: this.saleDocLines.value, saleDocLine: docLineForm.value, newQty: qty });
  }

  updateSaleDocLine(inventory: Inventory, saleLineForm: UntypedFormGroup) {
    const updatedSaleLine: SaleDocLine = _.merge(saleLineForm.value, {
      unitPrice: inventory.standardPrice,
      unitCost: inventory.defaultInventoryZone?.standardCost || 0,
      docLine: {
        inventoryId: inventory.id,
        inventory,
      },
    });
    saleLineForm.patchValue(updatedSaleLine);
    SaleDocLineComponent.filloutDerivedFormFields(saleLineForm, updatedSaleLine);
  }

  onClearLines(e) {
    if (e.screenX === 0 || e.screenY === 0) {
      // trick to avoid showing dialog on key Enter key presses
      return false;
    }
    this.keyboardActionsService._canAnnounceNumKeyPress = false;
    // alert the user first
    this.confirmationService.confirm({
      message: "Do you want to delete the entire invoice?",
      header: "Delete Confirmation",
      icon: "ui-icon-delete-forever",
      rejectButtonStyleClass: "p-button-link",
      accept: () => {
        this.keyboardActionsService._canAnnounceNumKeyPress = true;
        this._clearSaleDocLines();
      },
      reject: () => {
        this.keyboardActionsService._canAnnounceNumKeyPress = true;
        this.msgs = [{ severity: "info", summary: "Rejected", detail: "You have rejected the delete" }];
        this.messageService.addAll(this.msgs);
        // this.saleLinesCleared.emit();
        // this.inventorySearchComponent.cleanAndFocusSearchInput();
      },
    });
  }

  protected _clearSaleDocLines() {
    this._resetSaleDoc();
    this.onDocLinesDeleted.emit(this._myForm.value.doc.docNo);
    this.saleLinesCleared.emit();
    this.msgs = [{ severity: "info", summary: "Confirmed", detail: "Entire invoice deleted" }];
  }

  deleteInvoiceLine(index: number) {
    this.tenderWarningService
      .warnAboutTenders(() => {
        this.docLineQtyChanged.emit({
          saleDocLines: this.saleDocLines.value,
          saleDocLine: this.saleDocLines.at(index).value,
          newQty: 0,
        });

        // remove the chosen row
        this.saleDocLines.removeAt(index);
      })
      .subscribe();
  }

  viewProductDetailsClicked(inventoryId) {
    this.subsList.push(
      this.dbService.getRow("inventory", inventoryId).subscribe((inventory: Inventory) => {
        this.openInventoryEditForm(inventory);
      })
    );
  }

  onLineQtyChanged(newQty: number, saleDocLineForm: UntypedFormGroup) {
    // Only updated right side if qty is valid
    if (saleDocLineForm.valid) {
      this.docLineQtyChanged.emit({
        saleDocLines: this.saleDocLines.value,
        saleDocLine: saleDocLineForm.value,
        newQty: newQty,
      });
    }
  }

  initValidation() {
    this._validation = {
      discountAmount: InvoiceValidators.discountAmount(
        this._myForm.get("discountType"),
        this._myForm.get("subTotal"),
        "subtotal"
      ),
    };
  }

  onPark(saleTotal: number): void {
    this.saleDocTotal = saleTotal;
    if (this.saleDocLines.length > 0) {
      this.confirmationService.confirm({
        header: "Confirmation to Park Sale",
        message: "Are you sure you want to PARK this sale?",
        rejectButtonStyleClass: "p-button-link",
        accept: () => {
          this._saveDocAction = { type: SaveDocActionType.RESET_SALEDOC };
          this._myForm.get("doc.state").setValue(DocState.draft);
          this.onSave({ silentMode: false });
          this.onDocParked.emit();
        },
      });
    } else
      this.confirmationService.confirm({
        header: "Parking Empty Invoice",
        message: "Cannot park this invoice because it does not contain transaction lines",
        rejectVisible: false,
        acceptLabel: "OK",
        accept: () => {
          // DO Nothing
        },
      });
  }

  onSave({ silentMode = true } = {}) {
    // Clear ids originated from returned line from other invoices
    for (let i = 0; i < this.saleDocLines.length; i++) {
      const theLine = this.saleDocLines.at(i) as UntypedFormGroup;
      // if the line has been marked as a return
      const clearIDsField = theLine.get(SaleDocLineComponent.TAG_CLEARIDS_ONSAVE);
      if (clearIDsField && clearIDsField.value) {
        const value = theLine.getRawValue();
        if (value.voucher) {
          value.voucher = null;
        }
        theLine.setValue(FormDataHelpers.clearModelIDs(value));
        theLine.removeControl(SaleDocLineComponent.TAG_CLEARIDS_ONSAVE);
      }
    }
    this._myForm
      .get("shipperId")
      .setValue(
        this._myForm.get("shipperId").value
          ? this._myForm.get("shipperId").value.id
          : this._myForm.get("shipperId").value
      );
    super.onSave({ silentMode });
  }

  itemReturnedAction(event) {
    this.itemReturned.emit(event);
  }

  ///////////////////////////////////////////   End of Buttons /////////////////////////////////////////////////////////////

  openTenderRefNoDialog(docTenderLine: UntypedFormGroup) {
    this.openFullSizeDialog(FullSizeDialogName.TENDER_REF_NO, docTenderLine, {
      styleClass: null,
    });
  }

  /**** NEW METHODS FOR RETURNS ****/
  addLineForReturn(returnedItem: SingleQtyReturned) {
    const returnSaleDoc = returnedItem.saleDoc;
    const returnedLine = returnSaleDoc.saleDocLines.find((docLine) => {
      return returnedItem.docLineId === docLine.id;
    });

    // see if line has been already added
    const foundDocLine: AbstractControl = this._findDocLineById(returnedItem.docLineId);
    if (foundDocLine) {
      foundDocLine.patchValue({
        qty: foundDocLine.get("qty").value - returnedLine.qty,
      });
    } else {
      // Add only this line to the invoice
      this.saleDocLines.push(this.buildDocLineForReturn(returnedLine, returnedItem.maxQty));
    }

    this.saleDocLines.markAsDirty();
  }

  buildDocLineForReturn(saleDocLine: SaleDocLine, maxQty): UntypedFormGroup {
    saleDocLine.qty *= -1;
    saleDocLine.saleDocLineReturn = new SaleDocLineReturn();
    // Mix Qty is the inverse of maxQty
    const line = SaleDocLineComponent.set(this.fb, saleDocLine, -1 * maxQty);
    // Mark this line as a returned line in order to clear IDs later
    line.setControl(SaleDocLineComponent.TAG_CLEARIDS_ONSAVE, this.fb.control(true));
    line.get("docLine.refNo").setValue(saleDocLine.docLine.id);

    line.get("docLine.seqNo").setValue(
      (this.saleDocLines.value.length
        ? Math.max(
            ...this.saleDocLines.value.map((row) => {
              return row.docLine.seqNo;
            })
          )
        : 0) + 1
    );

    this.docLineQtyChanged.emit({
      saleDocLines: this.saleDocLines.value,
      saleDocLine: line.value,
      newQty: saleDocLine.qty,
    });
    return line;
  }

  setReturnedDoc(saleDoc: SaleDoc): void {
    this._setAsDocAccount(this.buildAccountSearchResult(saleDoc));
    this.origTakuPaySaleDocTenders = saleDoc.saleDocTenders.filter(
      (result) => result.refTransaction?.PaymentId && !result.isReturned
    );
    if (this.origTakuPaySaleDocTenders.length > 0) {
      this.origTakuPaySaleDocTenders.sort((a, b) => b.id - a.id);
    }

    for (const returnedLine of saleDoc.saleDocLines) {
      const foundDocLine: AbstractControl = this._findDocLineById(returnedLine.id);
      if (foundDocLine) {
        foundDocLine.patchValue({
          qty: -1 * returnedLine.qty,
        });
      } else {
        this.saleDocLines.push(this.buildDocLineForReturn(returnedLine, returnedLine.qty));
      }
    }
    this._myForm.markAsDirty();
    this.changeDetectorRef.detectChanges();
  }

  get invoiceNoteCtrl() {
    return this._myForm.get("doc").get("comment");
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
