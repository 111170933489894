<taku-form-dialoge-header
  [pristine]="_myForm.pristine"
  [valid]="_myForm.valid"
  (backClicked)="goBack()"
  (saveClicked)="onSave()"
  (revertClicked)="onRevert()"
  displayMode="inline"
  toolbarTitle="STOCK DETAILS"
  iconStyleClass="takuicon-stock-settings"
  (cancelClicked)="closeSidebar()"
></taku-form-dialoge-header>

<div [formGroup]="_myForm" class="grid">
  <div class="lg:col-4 col-12">
    <p-panel>
      <ng-template pTemplate="header">
        <h3>Basic Information</h3>
        <taku-checkbox [formGroup]="_myForm" fieldName="isActive" fieldCaption="Active"></taku-checkbox>
      </ng-template>

      <div class="col-12">
        <taku-input [formGroup]="_myForm" fieldName="stockID" fieldCaption="Stock ID"></taku-input>
      </div>

      <div class="col-12">
        <taku-input [formGroup]="_myForm" fieldName="stockName" fieldCaption="Stock Name"></taku-input>
      </div>

      <div class="col-12">
        <taku-input [formGroup]="_myForm" fieldName="shortName" fieldCaption="Stock Acronym"></taku-input>
      </div>

      <div class="drop-container col-12">
        <taku-dropdown
          [formGroup]="_myForm"
          [options]="enum_stock_types"
          fieldName="stockType"
          fieldCaption="Stock Type"
        ></taku-dropdown>
      </div>

      <div class="drop-container col-12">
        <taku-dropdown
          [formGroup]="_myForm"
          [options]="lookup_zones$ | async"
          fieldName="zoneId"
          fieldCaption="Zone"
        ></taku-dropdown>
      </div>
    </p-panel>
  </div>

  <div class="lg:col-8 col-12">
    <p-panel styleClass="panel-nopad">
      <ng-template pTemplate="header">
        <h3>Address Information</h3>
      </ng-template>

      <div class="col-12">
        <taku-address [formGroup]="_myForm.get('address')"></taku-address>
      </div>
    </p-panel>
  </div>
</div>
