import { AccountGroup } from "../settings/store-settings/account-group/account-group";
import { Store } from "../settings/store-settings/store/store";
import { Zone } from "../settings/zone-settings/Zone";

export class PriceLevel {
  id = 0;
  isActive = true;
  name = "";
  note?: string = null;
  effectiveDate?: Date = null;
  endDate?: Date = null;
  priority = 1;
  adjustBy: number | string = 0;
  acctGroupId?: number = null;
  acctGroup?: AccountGroup;
  zoneId: number;
  zone?: Zone;
  stores?: Store[] = [];
  updatedAt: string;

  constructor(zoneId: number) {
    this.zoneId = zoneId;
  }

  get storesMap(): string {
    return this.stores?.map((store) => store.storeName).join(", ") ?? "";
  }
}
