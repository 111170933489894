/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { GoogleAccount } from "./GoogleAccount";

export class GMBGoogleAccount {
  id: number;
  googleAccount: GoogleAccount;
  gmbAccounts: GMBAccount[];
  isActive: boolean;

  constructor() {
    this.id = 0;
    this.googleAccount = null;
    this.gmbAccounts = [];
    this.isActive = false;
  }
}

export class GMBAccount {
  id: number;
  name: string;
  accountName: string;
  state: GMBVerificationState;
  gmbGoogleAccountId: number;
  gmbGoogleAccount?: GMBGoogleAccount;
  accountType: GMBAccountType;

  constructor() {
    this.id = 0;
    this.name = null;
    this.accountName = null;
    this.state = null;
    this.accountType = null;
    this.gmbGoogleAccountId = null;
  }
}

export enum GMBVerificationMethod {
  ADDRESS = "ADDRESS",
  EMAIL = "EMAIL",
  PHONE_CALL = "PHONE_CALL",
  SMS = "SMS",
}

export enum GMBVerificationState {
  VERIFICATION_STATE_UNSPECIFIED = "VERIFICATION_STATE_UNSPECIFIED",
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}

export enum GMBAccountType {
  ACCOUNT_TYPE_UNSPECIFIED = "ACCOUNT_TYPE_UNSPECIFIED",
  PERSONAL = "PERSONAL",
  LOCATION_GROUP = "LOCATION_GROUP",
  USER_GROUP = "USER_GROUP",
  ORGANIZATION = "ORGANIZATION",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
