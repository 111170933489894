<div class="grid col-12">
  <div class="col-4">
    <taku-dropdown
      [formGroup]="_myForm"
      [options]="lookup_roles"
      fieldName="roleId"
      fieldCaption="Role"
      appendTo="body"
    >
    </taku-dropdown>
  </div>

  <div class="col-4">
    <taku-multiselect
      [formGroup]="_myForm"
      [options]="lookup_stores"
      fieldName="stores"
      appendTo="body"
      fieldCaption="Stores"
    ></taku-multiselect>
  </div>

  <div class="col-4">
    <taku-multiselect
      [formGroup]="_myForm"
      [options]="lookup_backOffices"
      fieldName="backOffices"
      appendTo="body"
      fieldCaption="Back Offices"
    ></taku-multiselect>
  </div>

  <div class="col-9 col-offset-3 validationErrors-wrapper">
    <form-validation-messages [hidden]="_myForm.valid || !_myForm.dirty" [errors]="_myForm.errors">
    </form-validation-messages>
  </div>
</div>
