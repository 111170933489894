/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { PersonalAccountsAccessRightsFields } from "./personal-accounts-access-rights-fields";

export class EmptyPersonalAccountsAccessRights implements PersonalAccountsAccessRightsFields {
  isNoteVisible = true;
  isStatusVisible = true;
  isTaxCategoryVisible = true;
  isTaxIdVisible = true;
  isCreditLimitVisible = true;
  isGeneralTabVisible = true;
  isAddressTabVisible = true;
  isNoteEnabled = true;
  isStatusEnabled = true;
  isTaxCategoryEnabled = true;
  isTaxIdEnabled = true;
  isCreditLimitEnabled = true;
  isGeneralTabEnabled = true;
  isAddressTabEnabled = true;
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
