/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Location } from "@angular/common";
import { Component, Output, EventEmitter } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { GenericFormComponent } from "../../../forms/generic-form/generic-form.component";
import { AlertMessagesService } from "../../../shared/services/alert-messages.service";
import { DBService } from "../../../shared/services/db.service";
import { UOfMeasure, UOfMeasure_type } from "./u-of-measure";
import { UnitOfMeasureService } from "./u-of-measure.service";
import { AppSettingsStorageService } from "../../../shared/app-settings-storage.service";
import { AppComponent } from "src/app/app.component";

@Component({
  selector: "taku-u-of-measure",
  templateUrl: "./u-of-measure.component.html",
  styleUrls: ["./u-of-measure.component.scss"],
})
export class UOfMeasureComponent extends GenericFormComponent {
  @Output() dialogClosed = new EventEmitter<any>();
  _enum_stock_type = this.dbService.enumSelectOptions(UOfMeasure_type);

  constructor(
    public unitOfMeasureService: UnitOfMeasureService,
    protected _router: Router,
    public fb: UntypedFormBuilder,
    public dbService: DBService,
    protected location: Location,
    public _route: ActivatedRoute,
    protected messageService: MessageService,
    protected alertMessage: AlertMessagesService,
    protected confirmationService: ConfirmationService,
    appSettings: AppSettingsStorageService
  ) {
    super(_router, fb, dbService, location, _route, messageService, alertMessage, confirmationService, appSettings);
  }

  initForm() {
    this._model = "uOfMeasure";
    this._object = new UOfMeasure();
    this._myForm = this.fb.group(new UOfMeasure());
  }

  initValidation() {
    this._validation = this.unitOfMeasureService.getValidationRules();
  }

  closeModal() {
    this.dialogClosed.emit();
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
