<div [formGroup]="_myForm">
  <div class="grid">
    <div class="grid col">
      <div class="col-12 status-container">
        <taku-dropdown
          [formGroup]="_myForm"
          [options]="enum_commecrial_instant_msg_type"
          [fieldName]="'instantMsgType'"
          [fieldCaption]="'Instant Msg Type'"
        >
        </taku-dropdown>
      </div>
      <div class="col-12">
        <taku-input [formGroup]="_myForm" [fieldName]="'instantMsg'" [fieldCaption]="'Instant Msg'"> </taku-input>
      </div>
    </div>
    <div class="col-fixed copy-icon-container">
      <taku-action-button [action]="ACTION_TYPE.COPY" (clickAction)="copyInstantMsgToClipboard()"></taku-action-button>
    </div>
  </div>
</div>
