<div id="open-cash-drawer-preview">
  <header></header>
  <main>
    <div style="position: relative">
      <span>Station #: {{ stationId }}</span>
      <span style="position: absolute; right: 0">Date: {{ date | date : "yyyy-MM-dd" }}</span>
    </div>
    <div style="position: relative">
      <span>Username: {{ userName }}</span>
      <span style="position: absolute; right: 0">Time: {{ date | date : "shortTime" }}</span>
    </div>
    <h2 style="text-align: center">Open Cash Drawer</h2>
  </main>
</div>
