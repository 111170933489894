/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, OnDestroy } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { SelectItem } from "primeng/api/selectitem";
import { DBService } from "src/app/shared/services/db.service";
import { shareReplay } from "rxjs/operators";
import { ReportParamsService } from "../report-params.service";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";

@Component({
  selector: "taku-reports-params",
  templateUrl: "./reports-params.component.html",
  styleUrls: ["./reports-params.component.scss"],
})
export class ReportsParamsComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];
  formGroup = null;
  lookupStores$: Observable<SelectItem[]>;
  lookupStations$: Observable<SelectItem[]>;

  constructor(
    private dbService: DBService,
    private reportParamsService: ReportParamsService,
    private appSettings: AppSettingsStorageService
  ) {}

  ngOnInit(): void {
    this.lookupStores$ = this.dbService
      .lookupSelectOptions("store", "storeName", {
        enableFieldName: "isActive",
        emptyRowCaption: "All Stores",
        emptyRowValue: 0,
      })
      .pipe(shareReplay(1));

    if (this.appSettings.getStoreId()) {
      this.lookupStations$ = this.dbService
        .lookupSelectOptions("station", "stationName", {
          enableFieldName: "isActive",
          lookupFilter: JSON.stringify({
            storeId: { matchMode: "equals", value: this.appSettings.getStoreId() },
          }),
          emptyRowCaption: "All Stations",
          emptyRowValue: 0,
        })
        .pipe(shareReplay(1));
    }

    this.selectedStoreCtrl.valueChanges.subscribe((newValue) => {
      this.lookupStations$ = this.dbService
        .lookupSelectOptions("station", "stationName", {
          enableFieldName: "isActive",
          lookupFilter: JSON.stringify({
            storeId: { matchMode: "equals", value: newValue },
          }),
          emptyRowCaption: "All Stations",
          emptyRowValue: 0,
        })
        .pipe(shareReplay(1));
    });

    this.subsList.push(
      this.formGroup.valueChanges.subscribe((newValue) => {
        this.reportParamsService.setReportParam(newValue);
      })
    );
  }

  get selectedStoreCtrl() {
    return this.formGroup.get("storeId");
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
