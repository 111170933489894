/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Location } from "@angular/common";
import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FormListMainComponent } from "src/app/form-list/form-list-main/form-list-main.component";
import { FormListColumnsService } from "src/app/form-list/form-list-columns.service";
import { Store } from "../store/store";
import { StoreService } from "../store/store.service";

@Component({
  selector: "taku-store-list",
  templateUrl: "./store-list.component.html",
  styleUrls: ["./store-list.component.scss"],
})
export class StoreListComponent extends FormListMainComponent {
  private hasRequestedBusinessWizard;

  constructor(
    location: Location,
    _route: ActivatedRoute,
    formListColumnsService: FormListColumnsService,
    private _storeService: StoreService
  ) {
    super(location, _route, formListColumnsService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.hasRequestedBusinessWizard = false;
    // this._defaultRowValues = { zoneId: this.appSettingsService.getZoneId() }
  }

  onNewStoreCreated(store: Store) {
    if (!this.hasRequestedBusinessWizard) {
      this.hasRequestedBusinessWizard = true;

      // this._storeService.showBusinessSetupConfirmation(store.id, () => {
      //   this.hasRequestedBusinessWizard = false;
      // });
    }
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
