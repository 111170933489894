/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
export class GoogleShoppingStore {
  id: number;
  storeCode: string;
  storeAddress: string;
  targetCountry: GoogleShoppingTargetCountry;
  contentLanguage: GoogleShoppingContentLanguage;
  storeId: number;
  googleShoppingAccountId: number;

  constructor() {
    this.id = 0;
    this.storeCode = null;
    this.storeAddress = null;
    this.targetCountry = GoogleShoppingTargetCountry.Canada;
    this.contentLanguage = GoogleShoppingContentLanguage.English;
    this.storeId = null;
    this.googleShoppingAccountId = null;
  }
}

export enum GoogleShoppingTargetCountry {
  USA = "USA",
  Canada = "Canada",
}

export enum GoogleShoppingContentLanguage {
  English = "English",
  French = "French",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
