<div *ngIf="!results">
  <h2>No Items</h2>
</div>
<p-dataView
  *ngIf="results"
  [value]="results"
  scrollable="true"
  [rows]="10"
  [lazy]="false"
  (onLazyLoad)="loadData($event)"
  [paginator]="true"
>
  <ng-template let-item let-i="index" pTemplate="listItem">
    <div class="grid car-item" [ngClass]="{ highlighted: _hightlightedItem == i }">
      <div class="add-item-col">
        <!-- <a (click)="onSelectItem(item, $event)" style="cursor:pointer">
          <i class="ui-icon-add-box"></i>
        </a> -->
        <p-button (onClick)="onSelectItem(item, i, $event)" styleClass="squared-corners" icon="pi pi-plus"></p-button>
      </div>
      <!-- <img src="assets/showcase/images/demo/car/{{car.brand}}.png"> -->
      <!-- <i class="fa fa-search" (click)="onSelectItem(item)" style="cursor:pointer;float:center;margin-top:15px"></i> -->
      <div class="car-details">
        <div class="grid">
          <div class="col-9 md:col-12 sm:col-12">
            <h3 class="product-description">
              {{ item.description1 }}
            </h3>
            <div *ngIf="item.categories.length > 0" class="product-categories">
              <strong>Categories:</strong> {{ categoriesNames(item).join(", ") }}
            </div>
          </div>
          <div class="col-3 md:col-12 sm:col-12 product-stock-price">
            <b>S/P:</b> {{ item.standardPrice | currency : "CAD" : "symbol-narrow" : "1.2" }}
          </div>

          <div class="grid col-12 product-bottom-line">
            <div class="col-4 md:col-12 sm:col-12"> <b>SKU:</b> {{ item.sku }} </div>
            <div class="col-4 md:col-12 sm:col-12">
              <span *ngIf="item.inventoryBarcodes.length > 0"
                ><b>Barcode:</b> {{ item.inventoryBarcodes[0].barcode }}</span
              >
            </div>
            <div class="grid col-4 md:col-12 sm:col-12 product-btn-details" *ngIf="isInventoryDetailsVisible">
              <button
                class="p-button-secondary taku-button-small"
                pButton
                icon="_"
                (click)="onClickedViewDetails(item, $event)"
                [disabled]="!isInventoryDetailsEnabled"
              >
                <i class="material-icons">remove_red_eye</i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</p-dataView>
