<div class="col-12">
  <taku-multiselect
    [formGroup]="formGroup"
    [disabled]="disabled"
    [options]="lookup_subdivisions"
    [fieldName]="fieldName"
    [fieldCaption]="fieldCaption"
    [dataKey]="null"
  ></taku-multiselect>
</div>
