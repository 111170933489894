<div class="col-12">
  <taku-form-dialoge-header
    [pristine]="_myForm.pristine"
    [valid]="_myForm.valid"
    (backClicked)="goBack()"
    (saveClicked)="onSave()"
    (revertClicked)="onRevert()"
    (cancelClicked)="closeSidebar()"
    toolbarTitle="STORE DETAILS"
    iconStyleClass="ui-icon-store"
    [isSaving]="_isSaving"
  ></taku-form-dialoge-header>
  <div [formGroup]="_myForm" class="grid">
    <!-- <p-panel class="col-12   ">
    <ng-template pTemplate="header">
      <i class="icon ui-icon-store"></i>
      <h1>
        STORE DETAILS
      </h1> -->

    <!-- <taku-location-switcher (onLocationChanged)="refreshSettings($event);"></taku-location-switcher>
  </ng-template> -->

    <div class="lg:col-4 col-12">
      <p-panel>
        <ng-template pTemplate="header">
          <h3>Basic Information</h3>

          <taku-checkbox [formGroup]="_myForm" fieldName="isActive" fieldCaption="Active"></taku-checkbox>
        </ng-template>

        <div class="col-12">
          <taku-input [formGroup]="_myForm" fieldName="storeName" fieldCaption="Store Name"></taku-input>
        </div>

        <div class="col-12">
          <taku-input [formGroup]="_myForm" fieldName="shortName" fieldCaption="Short Name"></taku-input>
        </div>

        <div class="col-12">
          <taku-input [formGroup]="_myForm" fieldName="url" fieldCaption="Website"></taku-input>
        </div>

        <div class="col-12">
          <taku-input [formGroup]="_myForm" fieldName="storeID" fieldCaption="Store ID"></taku-input>
        </div>

        <div class="drop-container col-12">
          <taku-dropdown
            [formGroup]="_myForm"
            [options]="enum_store_types"
            fieldName="storeType"
            fieldCaption="Store Type"
          ></taku-dropdown>
        </div>

        <div class="col-12 drop-container">
          <taku-time-zone-autocomplete-input [formGroup]="_myForm" fieldName="storeTimeZone" [isDropDown]="true">
          </taku-time-zone-autocomplete-input>
        </div>

        <div class="drop-container col-12">
          <taku-dropdown [formGroup]="_myForm" [options]="lookup_zones$ | async" fieldName="zoneId" fieldCaption="Zone">
          </taku-dropdown>
        </div>

        <div class="drop-container col-12">
          <taku-dropdown
            [disabled]="!_myForm.get('zoneId').value"
            [formGroup]="_myForm"
            [options]="lookup_stocks"
            fieldName="stockId"
            fieldCaption="Default Stock"
          ></taku-dropdown>
        </div>
        <hr class="fields-divider" />

        <div class="single-picture-uploader">
          <div class="col-12">
            <strong>Store Logo</strong>
          </div>

          <div class="col-12">
            <p-fileUpload
              #fileUploader
              name="image"
              auto="true"
              accept="image/*"
              customUpload="true"
              (uploadHandler)="myUploader($event)"
            ></p-fileUpload>
          </div>

          <div class="col-12 picture-container" *ngIf="_myForm.value.urlStoreLogoUrl">
            <i class="material-icons clear-field" (click)="clearImage()">close</i>
            <img [attr.src]="logoImageUrl" />
          </div>
        </div>
      </p-panel>
    </div>

    <div class="lg:col-8 col-12">
      <p-panel styleClass="panel-nopad">
        <ng-template pTemplate="header">
          <h3>Additional Information</h3>
        </ng-template>

        <div class="col-12">
          <p-tabView>
            <p-tabPanel header="Address" [selected]="true">
              <taku-address [formGroup]="_myForm.get('address')"></taku-address>
            </p-tabPanel>

            <p-tabPanel header="Social">
              <p-fieldset class="col-12 taku-listable-container" formArrayName="storeInstantMsgs">
                <ng-template pTemplate="header">
                  <h5>Instant Messengers</h5>
                </ng-template>

                <div class="add-button-container">
                  <a class="add-button" (click)="addStoreInstantMsg()">
                    <label>Add New</label>
                    <i class="material-icons">add_circle_outline</i>
                  </a>
                </div>
                <div
                  class="col-12 taku-listable-controlset"
                  *ngFor="let storeInstantMsg of _myForm.controls['storeInstantMsgs']['controls']; let i = index"
                >
                  <div class="col-11">
                    <taku-store-instant-msg [formGroup]="_myForm.get('storeInstantMsgs')['controls'][i]">
                    </taku-store-instant-msg>
                  </div>
                  <div class="col-1 remove-button-container">
                    <a (click)="removeStoreInstantMsg(i)">
                      <i class="material-icons remove-button">remove_circle_outline</i>
                    </a>
                  </div>
                </div>
              </p-fieldset>

              <p-fieldset class="col-12 taku-listable-container" formArrayName="storeSocialProfiles">
                <ng-template pTemplate="header">
                  <h5>Social Media</h5>
                </ng-template>

                <div class="add-button-container">
                  <a class="add-button" (click)="addStoreSocialProfile()">
                    <label>Add New</label>
                    <i class="material-icons">add_circle_outline</i>
                  </a>
                </div>

                <div
                  class="col-12 taku-listable-controlset"
                  *ngFor="let storeSocialProfile of _myForm.controls['storeSocialProfiles']['controls']; let i = index"
                >
                  <div class="col-11">
                    <taku-store-social-profile [formGroup]="_myForm.get('storeSocialProfiles')['controls'][i]">
                    </taku-store-social-profile>
                  </div>
                  <div class="col-1 remove-button-container">
                    <a (click)="removeStoreSocialProfile(i)">
                      <i class="material-icons remove-button">remove_circle_outline</i>
                    </a>
                  </div>
                </div>
              </p-fieldset>
            </p-tabPanel>

            <p-tabPanel header="Hours">
              <div id="storeHoursList" class="xl:col-10 col-12 xl:col-offset-1" formArrayName="storeHours">
                <p-card>
                  <ng-template pTemplate="header" class="grid">
                    <h4 class="col-12">Store Hours</h4>
                  </ng-template>

                  <div class="col-12 store-add-button-container">
                    <a class="add-button" (click)="addStoreHour()">
                      <label>Add New Period</label>
                      <i class="material-icons">add_circle_outline</i>
                    </a>
                  </div>

                  <p-table
                    id="storeHoursTable"
                    responsiveLayout="stack"
                    breakpoint="1100px"
                    [value]="_myForm.get('storeHours')['controls']"
                  >
                    <ng-template pTemplate="header" let-columns>
                      <tr>
                        <th class="col-startDay">Start Day</th>
                        <th class="col-endDay">End Day</th>
                        <th class="col-openTime">Open Time</th>
                        <th class="col-closeTime">Close Time</th>
                        <th class="col-1 col-remove-btn"></th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-storeHourForm let-index="rowIndex">
                      <tr #storeHourRow>
                        <!-- <taku-store-hour (onRemoveBtnPressed)="deleteStoreHour(index)" [formGroup]="storeHourForm"
                          [style.width]="'100%'">
                        </taku-store-hour> -->
                        <td>
                          <span class="p-column-title">Start Day:</span>
                          <taku-dropdown
                            [formGroup]="storeHourForm"
                            [options]="enum_week_days"
                            fieldName="startDay"
                            fieldCaption="Week Day"
                          ></taku-dropdown>
                        </td>

                        <td>
                          <span class="p-column-title">End Day:</span>
                          <taku-dropdown
                            [formGroup]="storeHourForm"
                            [options]="enum_week_days"
                            fieldName="endDay"
                            fieldCaption="Week Day"
                          ></taku-dropdown>
                        </td>

                        <td>
                          <span class="p-column-title">Open Time:</span>
                          <taku-calendar-time [formGroup]="storeHourForm" fieldName="openTime" fieldCaption="Time">
                          </taku-calendar-time>
                        </td>

                        <td>
                          <span class="p-column-title">Closing Time:</span>
                          <taku-calendar-time [formGroup]="storeHourForm" fieldName="closeTime" fieldCaption="Time">
                          </taku-calendar-time>
                        </td>

                        <td class="col-1 col-remove-btn">
                          <span class="p-column-title"></span>
                          <a (click)="deleteStoreHour(index)">
                            <i class="material-icons remove-button">remove_circle_outline</i>
                          </a>
                        </td>
                        <!-- <a [ngStyle]="{ height: storeHourRow.offsetHeight + 'px' }" class="remove-btn-wrapper">
                            <i class="material-icons remove-button" (click)="deleteStoreHour(index)">remove_circle_outline</i>
                        </a> -->
                      </tr>
                    </ng-template>
                  </p-table>
                </p-card>
                <!-- <table class="col-12   ">
                  <thead>
                    <tr>
                      <th class="col-3 col-startDay">Start Day</th>
                      <th class="col-3 col-endDay">End Day</th>
                      <th class="col-3 col-openTime">Open Time</th>
                      <th class="col-3 col-closeTime">Close Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="col-12   "
                      *ngFor="let storeHourForm of _myForm.get('storeHours')['controls']; let i=index">
                      <taku-store-hour [formGroup]="storeHourForm" [style.width]="'100%'"></taku-store-hour>
                      <td class="col-remove-btn">
                        <a (click)="deleteStoreHour(i)">
                          <i class="material-icons remove-button">remove_circle_outline</i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table> -->
              </div>
            </p-tabPanel>
          </p-tabView>
        </div>
      </p-panel>
    </div>
    <!-- </p-panel> -->
  </div>
</div>

<!-- <div class="col-12">
  {{_myForm.value|json}}
</div> -->
