<div class="col-12 md:col-8 lg:col-5 content-container grid">
  <div class="col-12 body bgColor-white">
    <div class="brand-container">
      <div class="logo-container">
        <img [src]="branding.mainLogoDark" />
      </div>
    </div>

    <div class="reset-pw-label-container">
      <h1>Reset Your Password</h1>
    </div>

    <div class="field-and-btns">
      <div class="col-10 instructions">
        <p>We’ll email you instructions to reset your password.</p>
      </div>
      <div class="col-12 input-container">
        <taku-input [formGroup]="formGroup" fieldName="accountUsername" fieldCaption="Username"></taku-input>
      </div>
      <div class="grid col-12 grid-nogutter button-container mt-3">
        <div class="col-4 grid-align-left">
          <button
            pButton
            (click)="onBack($event)"
            class="p-button-lg p-button-text"
            icon="pi pi-chevron-left"
            label="Back"
          ></button>
        </div>

        <div class="col-8 grid-align-right">
          <button
            pButton
            type="submit"
            class="p-button-lg"
            (click)="sendResetPasswordEmail($event)"
            label="Reset Password"
            [loading]="_sendingEmail"
          >
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
