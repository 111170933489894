/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { DocumentSearchForReturnComponent } from "./document-search-return.component";
import { SaleDoc } from "../../sale-document/sale-doc/sale-doc";
import { SaleDocLine } from "../../sale-document/sale-doc-line/sale-doc-line";
import { UntypedFormGroup } from "@angular/forms";
import * as _ from "lodash";

class SearchDocForReturnComponentState {
  constructor(
    public docsSearchForm: UntypedFormGroup = null,
    public saleDoc: SaleDoc = null,
    public origSaleDocLines: SaleDocLine[] = null
  ) {}
}

@Injectable({
  providedIn: "root",
})
export class SearchDocForReturnStateService {
  componentState: SearchDocForReturnComponentState;

  saveComponentState(component: DocumentSearchForReturnComponent, onlyFields?: string[]) {
    const defaults = new SearchDocForReturnComponentState();
    this.componentState = Object.assign(
      {},
      this.componentState || defaults,
      _.pick(
        {
          docsSearchForm: component.docsSearchForm,
          saleDoc: component.saleDoc,
          origSaleDocLines: component._origSaleDocLines,
        },
        onlyFields || Object.keys(defaults)
      )
    );
  }

  restoreComponentState(newComponent: DocumentSearchForReturnComponent) {
    const state = this.componentState;
    // Keep not empty values from current search form
    state.docsSearchForm.setValue(
      Object.assign(state.docsSearchForm.value, newComponent.docsSearchForm.value.filter(Boolean))
    );
    newComponent.docsSearchForm = state.docsSearchForm;
    newComponent.saleDoc = state.saleDoc;
    newComponent._origSaleDocLines = state.origSaleDocLines;
  }

  hasState() {
    return this.componentState != null;
  }

  clearState() {
    this.componentState = null;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
