<taku-form-header
  [formGroup]="_myForm"
  (backClicked)="goBack()"
  (saveClicked)="onSave()"
  (revertClicked)="onRevert()"
  toolbarTitle="Business Formats"
  iconStyleClass="material-icons"
  iconName="text_fields"
></taku-form-header>

<div [formGroup]="_myForm">
  <p-card class="grid col-12">
    <div id="fieldFormatsList" class="col-12 lg:col-10 lg:col-offset-1 xl:col-8 xl:col-offset-2">
      <p-table
        id="docFormatsTable"
        class="col-12 grid-table"
        [value]="businessFormatsRows"
        responsiveLayout="stack"
        breakpoint="640px"
        styleClass="p-datatable-striped"
      >
        <ng-template pTemplate="header">
          <tr>
            <th class="col-doctype">Field Type</th>
            <th class="col-prefix">Prefix</th>
            <th class="col-numdigits"># of Digits</th>
            <th class="col-firstNumber">Starting Number</th>
            <th class="col-suffix">Suffix</th>
            <th class="col-preview">Preview</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-businessFormatRow>
          <tr>
            <taku-fields-format
              [formGroup]="_myForm"
              colTypeTitle="Field Type"
              [docType]="businessFormatRow.fieldType"
              [prefixFieldCaption]="businessFormatRow.prefixFieldCaption"
              [prefixFieldName]="businessFormatRow.prefixFieldName"
              [suffixFieldName]="businessFormatRow.suffixFieldName"
              [noOfDigitsFieldName]="businessFormatRow.noOfDigitsFieldName"
              [startingNumberFieldName]="businessFormatRow.startingNumberFieldName"
            ></taku-fields-format>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </p-card>
</div>
