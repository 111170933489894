/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, LOCALE_ID, inject } from "@angular/core";
import { UntypedFormBuilder, ValidatorFn, Validators } from "@angular/forms";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";
import { Col, DataType, FilterType, TemplateDatatypeOptions } from "../../../../form-list/form-list/form-list";
import { FormListModel } from "../../../../form-list/form-list/form-list-model.interface";
import { AppSettingsStorageService } from "../../../../shared/app-settings-storage.service";
import { AuthService } from "../../../../shared/services/auth.service";
import { DBService, ExtendedSelectItem, LookupOptions } from "../../../../shared/services/db.service";
import { DocState } from "../../doc/doc";
import { PurchaseDocLineService } from "../purchase-document/purchase-doc-line.service";
import { PurchaseDoc, PurchaseDocLine, PurchaseDocLineTax } from "../purchase-document/purchase-document";
import type { Stock } from "src/app/core/settings/business-settings/stock/stock";
import { PaymentTerm } from "src/app/core/purchase-order-term/payment-term/payment-term";
import { ShippingTerm } from "src/app/core/purchase-order-term/shipping-term/shipping-term";
import { Shipper } from "src/app/core/settings/business-settings/shipper/shipper";
import { DBTaxService } from "src/app/shared/services/db-tax.service";
import { TaxRule } from "src/app/core/tax/tax-rule/tax-rule";
import { Zone } from "src/app/core/settings/zone-settings/Zone";
import { DataNormalizerService } from "src/app/shared/services/data-normalizes.service";
import { CurrencySymbolPipe } from "src/app/shared/pipes/currency-symbol.pipe";
import { Store } from "src/app/core/settings/store-settings/store/store";
import { BackOffice } from "src/app/core/settings/backOffice-settings/backOffice/backOffice";

@Injectable({
  providedIn: "root",
})
export class PurchaseDocService extends DBService implements FormListModel {
  enum_doc_states = this.enumSelectOptions(DocState);
  normalizeService = inject(DataNormalizerService);

  getValidationRules(): { [key: string]: {} | ValidatorFn[] } {
    return {
      stockId: [Validators.required],
      "doc.currencyIsoCode": [Validators.required],
      "doc.docDate": [Validators.required],
      "doc.docTime": [Validators.required],
      accountId: [Validators.required],
    };
  }

  constructor(
    private appSettings: AppSettingsStorageService,
    public purchaseDocLineService: PurchaseDocLineService,
    protected http: HttpClient,
    protected authService: AuthService,
    private dbTaxService: DBTaxService,
    private fb: UntypedFormBuilder,
    @Inject(LOCALE_ID) private defaultLocale: string
  ) {
    super(http, authService);
  }

  moneyPrefix(rowData: PurchaseDoc) {
    return new CurrencySymbolPipe(this.defaultLocale).transform(rowData.doc.currencyIsoCode);
  }

  updatePurchaseTaxesInLineForm(line: PurchaseDocLine, purchaseDoc: PurchaseDoc): Observable<TaxRule[]> {
    const inventoryId = line["docLine"]["inventoryId"];
    const qty = +line["qty"];
    const unitPrice = +line["unitPrice"];
    const unitDiscount = +line["unitDiscount"];

    return this.dbTaxService
      .calculatePurchaseTaxLine(
        inventoryId,
        unitPrice - unitDiscount,
        qty,
        purchaseDoc.stockId,
        purchaseDoc.shippingAddressId,
        purchaseDoc.zone?.taxAccountCategoryId || null,
        purchaseDoc.doc.docDate
      )
      .pipe(
        tap((taxRules: TaxRule[]) => {
          this.updateTaxLines(line, taxRules);
        })
      );
  }

  private updateTaxLines(line: PurchaseDocLine, taxRules: TaxRule[]): void {
    const lineTaxes: PurchaseDocLineTax[] = [];
    taxRules.forEach((taxRule) => {
      lineTaxes.push({
        id: 0, // set as new tax line
        amount: taxRule.taxAmount || 0,
        taxRuleId: taxRule.id,
        taxRule: taxRule,
      });
    });
    line["purchaseDocLineTaxes"] = lineTaxes;
  }

  postEmailReceipt(purchaseDocId: number, emailAddress: string, html: string, stylesheet: string) {
    return this._postRequest<any>(this.webUrl + "emailPurchase/" + purchaseDocId, {
      email: emailAddress,
      html,
      stylesheet,
    });
  }

  get_lookup_stocks(): Observable<ExtendedSelectItem<number, Stock>[]> {
    const options: LookupOptions = {
      enableFieldName: "isActive",
    };
    // Only filter stock dropdown-options for non-admin
    if (this.getDefaultZoneId() !== 0) {
      options.lookupFilter = JSON.stringify({ zoneId: { matchMode: "equals", value: this.getDefaultZoneId() } });
    }

    return this.lookupSelectOptions<number, Stock>("stock", "stockName", options);
  }

  get_lookup_paymentTerms(): Observable<ExtendedSelectItem<number, PaymentTerm>[]> {
    const options: LookupOptions = {
      enableFieldName: "isActive",
    };

    return this.lookupSelectOptions<number, PaymentTerm>("paymentTerm", "name", options);
  }

  get_lookup_shippingTerms(): Observable<ExtendedSelectItem<number, ShippingTerm>[]> {
    const options: LookupOptions = {
      enableFieldName: "isActive",
    };

    return this.lookupSelectOptions<number, ShippingTerm>("shippingTerm", "name", options);
  }

  get_lookup_shipper(): Observable<ExtendedSelectItem<number, Shipper>[]> {
    const options: LookupOptions = {
      enableFieldName: "isActive",
    };

    return this.lookupSelectOptions<number, Shipper>("shipper", "shipperName", options);
  }

  get_lookup_stores(): Observable<ExtendedSelectItem<string, Store>[]> {
    const options: LookupOptions = {
      enableFieldName: "isActive",
    };

    return this.lookupSelectOptions<string, Store>("store", "storeName", options);
  }

  get_Lookup_backOffices(): Observable<ExtendedSelectItem<string, BackOffice>[]> {
    const options: LookupOptions = {
      enableFieldName: "isActive",
    };

    return this.lookupSelectOptions<string, BackOffice>("backOffice", "backOfficeName", options);
  }

  getDefaultZoneId(): number {
    return this.appSettings.getZoneId();
  }

  getDefaultZone(): Zone {
    return this.appSettings.getZone();
  }

  getCurrentUserId(): number {
    return this.appSettings.getUserId();
  }

  getFormListColumns(extraData?: Record<string, unknown>): Col[] {
    return [
      {
        field: "account.commercialAccount.name",
        header: "Supplier",
        visible: true,
        readonly: true,
        frozen: true,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 130,
      },
      {
        field: "stockId",
        fieldValue: "stock.stockName",
        header: "Receiving Location",
        visible: true,
        readonly: false,
        frozen: true,
        dataType: DataType.lookup,
        dataOptions: this.get_lookup_stocks(),
        filterType: FilterType.lookup,
        sortFields: ["stock.stockName"],
      },
      {
        field: "doc.docNo",
        header: "PO #",
        visible: true,
        readonly: false,
        frozen: true,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 100,
      },
      {
        field: "doc.state",
        header: "Doc Status",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enum_doc_states,
        filterType: FilterType.enum,
        static: true,
        colWidth: 160,
      },
      {
        field: "doc.docDate",
        header: "PO Date",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.date_date_only,
        dataOptions: of([]),
        filterType: FilterType.contains,
      },
      {
        field: "doc.docTime",
        header: "PO Time",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.date_time,
        dataOptions: of([]),
        filterType: FilterType.contains,
        colWidth: 90,
        sortFields: ["doc.docDate", "doc.docTime"],
      },
      {
        field: "shippingDate",
        header: "Shipping Date",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.date_date_only,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 100,
      },
      {
        field: "grandTotal",
        header: "Total Cost",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: of([]),
        dataTypeOptions: { prefix: this.moneyPrefix.bind(this) },
        filterType: FilterType.contains,
        colWidth: 90,
        displayFn: (rowData: PurchaseDoc) => {
          return this.normalizeService.currencyNormalizer(rowData.grandTotal, rowData.doc.currencyIsoCode);
        },
      },
      {
        field: "totalPendingAmount",
        header: "Amount Pending",
        visible: false,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: of([]),
        dataTypeOptions: { prefix: this.moneyPrefix.bind(this) },
        filterType: FilterType.none,
        colWidth: 90,
        isNotSortable: true,
        displayFn: (rowData: PurchaseDoc) => {
          return this.normalizeService.currencyNormalizer(rowData.totalPendingAmount, rowData.doc.currencyIsoCode);
        },
      },
      {
        field: "totalQty",
        header: "# Items in PO",
        visible: false,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: of([]),
        filterType: FilterType.none,
        colWidth: 90,
        isNotSortable: true,
        displayFn: (rowData: PurchaseDoc) => {
          return this.normalizeService.normalizeNumbers(rowData.totalQty);
        },
      },
      {
        field: "totalReceivedQty",
        header: "# Items Received",
        visible: false,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: of([]),
        filterType: FilterType.none,
        colWidth: 90,
        isNotSortable: true,
        displayFn: (rowData: PurchaseDoc) => {
          return this.normalizeService.normalizeNumbers(rowData.totalReceivedQty);
        },
      },
      {
        field: "totalReceivedPercentage",
        header: "Total % Received",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.template,
        dataOptions: [],
        filterType: FilterType.none,
        static: true,
        colWidth: 130,
        attachTemplate: extraData.totalReceivedTemplateOptions as TemplateDatatypeOptions,
      },
      {
        field: "doc.user.userName",
        header: "Created by",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: of([]),
        filterType: FilterType.contains,
        colWidth: 110,
      },
      {
        field: "buyerName",
        header: "Buyer's Name",
        visible: false,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: of([]),
        filterType: FilterType.contains,
        colWidth: 110,
      },
    ];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
