import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

type SignupRequest = {
  email: string;
  firstName: string;
  lastName: string;
  accountName: string;
  businessName: string;
  consent: boolean;
};
@Injectable({
  providedIn: "root",
})
export class SignUpService {
  webUrl = environment.apiUrl;

  constructor(protected http: HttpClient) {}

  sendSignupRequest(body: SignupRequest): Observable<any> {
    return this.http.post(`${this.webUrl}hubspot/signup`, body);
  }
}
