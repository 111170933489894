<taku-form-header
  [formGroup]="_myForm"
  (backClicked)="goBack()"
  (saveClicked)="onSave()"
  (revertClicked)="onRevert()"
  [toolbarTitle]="pageTitle"
  iconStyleClass="material-icons"
  iconName="email"
  [showSecondaryBtns]="false"
></taku-form-header>

<div [formGroup]="_myForm">
  <p-card class="grid col-12">
    <div class="col-12 xl:col-4 lg:col-5">
      <div class="col-12">
        <h2>SMTP Server Options</h2>
        <p>To send outgoing emails from TAKU Retail, the SMTP server must be set.</p>
      </div>

      <div class="col-8">
        <taku-input [formGroup]="_myForm" fieldName="smtpHost" fieldCaption="SMTP Host"></taku-input>
      </div>

      <div class="col-4">
        <taku-input [formGroup]="_myForm" fieldName="port" fieldCaption="Port"></taku-input>
      </div>

      <div class="col-12 encryption-settings">
        <div class="col-12">
          <label class="field-caption">Encryption</label>
        </div>

        <div class="col-4">
          <p-radioButton
            name="encryption"
            [value]="SettingEmailEncryption.None"
            formControlName="encryption"
            label="None"
          ></p-radioButton>
        </div>
        <div class="col-4">
          <p-radioButton
            name="encryption"
            [value]="SettingEmailEncryption.SSL"
            formControlName="encryption"
            label="SSL"
          ></p-radioButton>
        </div>
        <div class="col-4">
          <p-radioButton
            name="encryption"
            [value]="SettingEmailEncryption.TLS"
            formControlName="encryption"
            label="TLS"
          ></p-radioButton>
        </div>
      </div>

      <div class="col-12">
        <div class="col-12">
          <label class="field-caption">Authentication</label>
        </div>
        <div class="col-12">
          <taku-toggle
            [formGroup]="_myForm"
            fieldName="authentication"
            enabledStateLabel="ON"
            disabledStateLabel="OFF"
            [captionPosition]="ToggleCaptionPosition.RIGHT"
          ></taku-toggle>
        </div>
      </div>

      <div class="col-12">
        <taku-input [formGroup]="_myForm" fieldName="userName" fieldCaption="Username"></taku-input>
      </div>

      <div class="col-12">
        <taku-input [formGroup]="_myForm" fieldName="password" fieldCaption="Password" type="password"></taku-input>
      </div>
    </div>
  </p-card>
</div>
