/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit } from "@angular/core";
import { NestedFormComponent } from "../../../forms/nested-form/nested-form.component";
import { Contact } from "./contact";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { PersonComponent } from "../person/person.component";
import { PersonAddressComponent } from "../person-address/person-address.component";
import { PersonPhoneComponent } from "../person-phone/person-phone.component";

@Component({
  selector: "taku-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"],
})
export class ContactComponent extends NestedFormComponent {
  // Define enum variables

  // Define lookup Variables
  // lookup_person: any;
  initLookups() {
    // this.dbService.lookupSelectOptions('person', 'name').subscribe(data => this.lookup_person = data);
  }

  static init(fb: UntypedFormBuilder, commercialAccountId: number): UntypedFormGroup {
    const newContact = new Contact();
    newContact.commercialAccountId = commercialAccountId;
    const contact_Group = fb.group(newContact);
    contact_Group.setControl("person", PersonComponent.init(fb));
    return contact_Group;
  }

  static setArray(fb: UntypedFormBuilder, contacts: Contact[]) {
    const formArray = fb.array([]);
    contacts.map((contact) => {
      const form = ContactComponent.set(fb, contact);
      formArray.push(form);
    });
    return formArray;
  }

  static set(fb: UntypedFormBuilder, contact: Contact) {
    const contactForm = fb.group(contact);
    contactForm.setControl("person", PersonComponent.set(fb, contact.person));
    return contactForm;
  }

  // initChildControls() {
  //   if (this._formControl === undefined ) { // if there is an input
  //     this._formControl = this.fb.group(new Customer());
  //   }
  //     this._formControl.setControl('person', this.fb.group(new Person));
  // }

  // initValidation() {
  //   this._validation = {
  //     // person: [Validators.required],
  //     // personAddresses: []
  //     // sku: [Validators.required],
  //     // description1: [Validators.required],
  //     // stockType: [Validators.required],
  //     // sellType: [Validators.required],
  //     // status: [Validators.required]
  //   };
  // }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
