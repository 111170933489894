/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { AutocompleteTransformer } from "src/app/form-list/form-list/form-list";
import { SelectItem } from "primeng/api";

export class TimeZoneAutocompleteTransformer implements AutocompleteTransformer {
  toAutocompleteItem(timezoneName: string): SelectItem {
    return { label: timezoneName, value: timezoneName };
  }

  fromAutocompleteItem(item: SelectItem): string {
    return item.value;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
