/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { AsyncValidatorFn, ValidatorFn, Validators } from "@angular/forms";
import * as _ from "lodash";
import { CashoutHistoryService } from "../core/cashout/cashout-history/cashout-history.service";
import { CommercialAccountService } from "../core/contact-accounts/commercial-account/commercial-account.service";
import { PersonalAccountService } from "../core/contact-accounts/personal-account/personal-account.service";
import { InventoryDocService } from "../core/document/inventory-document/inventory-doc-list/inventory-doc.service";
import { InventoryDocLineService } from "../core/document/inventory-document/inventory-document/inventory-doc-line.service";
import { SaleDocService } from "../core/document/sale-document/sale-doc/sale-doc.service";
import { BusinessBrandService } from "../core/inventory/brand/brand.service";
import { InventoryCategoryService } from "../core/inventory/category/inventory-category.service";
import { InventoryService } from "../core/inventory/inventory/inventory.service";
import { UnitOfMeasureService } from "../core/inventory/u-of-measure/u-of-measure.service";
import { BackofficeService } from "../core/settings/backOffice-settings/backOffice/backOffice.service";
import { FiscalYearsService } from "../core/settings/business-settings/fiscal-years-settings/fiscal-years.service";
import { ShipperService } from "../core/settings/business-settings/shipper/shipper.service";
import { StockService } from "../core/settings/business-settings/stock/stock.service";
import { GoogleShoppingStoresService } from "../core/settings/integration-settings/google-shopping/google-shopping-stores-list/google-shopping-stores.service";
import { AccountGroupService } from "../core/settings/store-settings/account-group/account-group.service";
import { StationService } from "../core/settings/store-settings/station/station.service";
import { StoreService } from "../core/settings/store-settings/store/store.service";
import { InventoryDocFormatsService } from "../core/settings/zone-settings/inventory-doc-formats/InventoryDocFormats.service";
import { ZoneDenominationService } from "../core/settings/zone-settings/zone-denominations/zone-denominations.service";
import { ZoneTenderTypesService } from "../core/settings/zone-settings/zone-tender-types/zone-tender-types.service";
import { ZoneService } from "../core/settings/zone-settings/zone.service";
import { TaxAccountCategoryService } from "../core/tax/tax-account-category/tax-account-category.service";
import { TaxInventoryCategoryService } from "../core/tax/tax-inventory-category/tax-inventory-category.service";
import { TaxRuleService } from "../core/tax/tax-rule/tax-rule.service";
import { TaxService } from "../core/tax/tax/tax.service";
import { RoleDatasService } from "../core/users/access-rights/role-datas/role-datas.service";
import { RolePrivilegesService } from "../core/users/access-rights/role-privileges/role-privileges.service";
import { RoleVisibilityService } from "../core/users/access-rights/role-visibility/role-visibility.service";
import { RolesService } from "../core/users/role/roles.service";
import { UserService } from "../core/users/user/user.service";
import { Col } from "./form-list/form-list";
import { FormListModel } from "./form-list/form-list-model.interface";
import { DBService } from "../shared/services/db.service";
import { PaymentGatewayService } from "src/app/core/settings/integration-settings/payment-gateways/payment-gateway.service";
import { GatewayTerminalsService as PaymentGatewayTerminalsService } from "src/app/core/settings/integration-settings/gateway-terminals/gateway-terminals.service";
import { SaleChannelService } from "src/app/core/sale-channel/sale-channel.service";
import { SaleChannelOrdersService } from "../core/sale-channel/sale-channel-orders/sale-channel-orders.service";
import { PrinterSettingsService } from "../core/settings/store-settings/printer/printer-settings.service";
import { InventoryOptionsSettingService } from "../core/inventory/inventory-options-setting/inventory-options-setting.service";
import { InventoryOptionsSettingValueService } from "../core/inventory/inventory-options-setting-value/inventory-options-setting-value.service";
import { RecurringOrderSettingsService } from "../core/settings/zone-settings/recurring-order-settings/recurring-order-settings.service";
import { TakuPayService } from "./../core/settings/integration-settings/taku-pay/taku-pay.service";
import { TakuPaymentGatewayService } from "./../core/settings/integration-settings/taku-pay/taku-payment-gateways-list/taku-payment-gateway.service";
import { TakuPaymentGatewayTerminalsService } from "./../core/settings/integration-settings/taku-pay/taku-payment-gateway-terminals/taku-payment-gateway-terminals.service";
import { PriceLevelService } from "../core/price-levels/price-level-service";
import { PaymentTermService } from "../core/purchase-order-term/payment-term/payment-term.service";
import { ShippingTermService } from "../core/purchase-order-term/shipping-term/shipping-term.service";
import { AisleService } from "../core/aisle/aisle.service";
import { ShelfService } from "../core/shelf/shelf.service";
import { PromotionService } from "../core/promotion/promotion.service";
import { PurchaseDocLineService } from "../core/document/purchase-document/purchase-document/purchase-doc-line.service";

@Injectable({
  providedIn: "root",
})
export class FormListColumnsService {
  // you need to and new service in constructor below
  constructor(
    private inventoryService: InventoryService,
    private personalAccountService: PersonalAccountService,
    private commercialAccountService: CommercialAccountService,
    private taxAccountCategoryService: TaxAccountCategoryService,
    private taxInventoryCategoryService: TaxInventoryCategoryService,
    private taxRuleService: TaxRuleService,
    private taxService: TaxService,
    private takuPayService: TakuPayService,
    private userService: UserService,
    private acctGroupService: AccountGroupService,
    private fiscalYearsService: FiscalYearsService,
    private zoneService: ZoneService,
    private storeService: StoreService,
    private inventoryCatService: InventoryCategoryService,
    private unitOfMeasureService: UnitOfMeasureService,
    private tenderTypeService: ZoneTenderTypesService,
    private shipperService: ShipperService,
    private stationService: StationService,
    private inventoryOptionsSettingService: InventoryOptionsSettingService,
    private inventoryOptionsSettingValueService: InventoryOptionsSettingValueService,
    private printerSettingsService: PrinterSettingsService,
    private saleDocService: SaleDocService,
    private zoneDenomService: ZoneDenominationService,
    private stockService: StockService,
    private inventoryDocService: InventoryDocService,
    private inventoryDocLineService: InventoryDocLineService,
    private purchaseDocLineService: PurchaseDocLineService,
    private inventoryDocFormatsService: InventoryDocFormatsService,
    private backOfficeService: BackofficeService,
    private googleShoppingStoresService: GoogleShoppingStoresService,
    private brandService: BusinessBrandService,
    private rolesService: RolesService,
    private roleDatasService: RoleDatasService,
    private roleVisibilityService: RoleVisibilityService,
    private rolePrivilegesService: RolePrivilegesService,
    private cashoutService: CashoutHistoryService,
    private paymentGatewayService: PaymentGatewayService,
    private paymentGatewayTerminalsService: PaymentGatewayTerminalsService,
    private takuPaymentGatewayService: TakuPaymentGatewayService,
    private takuPaymentGatewayTerminalsService: TakuPaymentGatewayTerminalsService,
    private saleChannelService: SaleChannelService,
    private recurringOrderSettingsService: RecurringOrderSettingsService,
    private priceLevelsService: PriceLevelService,
    private paymentTermService: PaymentTermService,
    private shippingTermService: ShippingTermService,
    private aisleService: AisleService,
    private shelfService: ShelfService,
    private promotionService: PromotionService,
    private saleChannelOrdersService: SaleChannelOrdersService
  ) {}

  getColumnsService(model: string): FormListModel {
    switch (model) {
      case "inventory":
        return this.inventoryService;
      case "personalAccount":
        return this.personalAccountService;
      case "commercialAccount":
        return this.commercialAccountService;
      case "taxAccountCategory":
        return this.taxAccountCategoryService;
      case "taxInventoryCategory":
        return this.taxInventoryCategoryService;
      case "taxRule":
        return this.taxRuleService;
      case "tax":
        return this.taxService;
      case "takuPaymentAccount":
        return this.takuPayService;
      case "takuPaymentGateway":
        return this.takuPaymentGatewayService;
      case "takuPaymentTerminal":
        return this.takuPaymentGatewayTerminalsService;
      case "user":
        return this.userService;
      case "acctGroup":
        return this.acctGroupService;
      case "fiscalYear":
        return this.fiscalYearsService;
      case "zone":
        return this.zoneService;
      case "store":
        return this.storeService;
      case "category":
        return this.inventoryCatService;
      case "uOfMeasure":
        return this.unitOfMeasureService;
      case "tenderType":
        return this.tenderTypeService;
      case "shipper":
        return this.shipperService;
      case "station":
        return this.stationService;
      case "inventoryOptionsSetting":
        return this.inventoryOptionsSettingService;
      case "inventoryOptionsSettingValue":
        return this.inventoryOptionsSettingValueService;
      case "printer":
        return this.printerSettingsService;
      case "cashDenomination":
        return this.zoneDenomService;
      case "saleDoc":
        return this.saleDocService;
      case "stock":
        return this.stockService;
      case "inventoryDoc":
        return this.inventoryDocService;
      case "inventoryDocLine":
        return this.inventoryDocLineService;
      case "purchaseDocLine":
        return this.purchaseDocLineService;
      case "inventoryDocFormat":
        return this.inventoryDocFormatsService;
      case "backOffice":
        return this.backOfficeService;
      case "cashout":
        return this.cashoutService;
      case "googleShoppingStore":
        return this.googleShoppingStoresService;
      case "brand":
        return this.brandService;
      case "role":
        return this.rolesService;
      case "roleData":
        return this.roleDatasService;
      case "roleVisibility":
        return this.roleVisibilityService;
      case "rolePrivilege":
        return this.rolePrivilegesService;
      case "paymentGateway":
        return this.paymentGatewayService;
      case "paymentTerminal":
        return this.paymentGatewayTerminalsService;
      case "saleChannel":
        return this.saleChannelService;
      // case 'saleChannelOrder': return this.saleChannelOrdersService;
      // case 'saleDocReport': return this.salesReportColsService;
      case "recurringOrderSetting":
        return this.recurringOrderSettingsService;
      case "priceLevel":
        return this.priceLevelsService;
      case "paymentTerm":
        return this.paymentTermService;
      case "shippingTerm":
        return this.shippingTermService;
      case "aisle":
        return this.aisleService;
      case "shelf":
        return this.shelfService;
      case "promotion":
        return this.promotionService;
      default:
        return null;
    }
  }

  getDbService(model: string): DBService {
    switch (model) {
      default:
        const colService = this.getColumnsService(model);
        return colService instanceof DBService ? colService : null;
    }
  }

  getValidation(model: string): { [key: string]: ValidatorFn[] | {} } {
    const colService = this.getColumnsService(model);
    return colService ? colService.getValidationRules() : {};
  }

  getAsyncValidation(model: string): { [key: string]: AsyncValidatorFn | AsyncValidatorFn[] | {} } {
    const colService = this.getColumnsService(model);
    // Because async validation rules are optional we have to check first for presence of both service and method
    return colService && colService.getAsyncValidationRules ? colService.getAsyncValidationRules() : {};
  }

  getDefaultColumns(model: string): any[] {
    const modelService = this.getColumnsService(model);
    if (!modelService) {
      return [];
    }

    return modelService.getFormListColumns();
  }

  formatColumnsHeader(columns: Col[], modelValidation?: {}, putRequiredFirst = true): any[] {
    columns = columns.map((col) => Object.assign(new Col(col.field), col)); // Assign column defaults

    if (modelValidation) {
      // Integrate validation message into column definition
      // user headerHTML property for REQUIRED messages in column headers
      columns.forEach((col) => {
        // find validation rule for column
        const validators: ValidatorFn[] | {} = _.get(modelValidation, col.field);
        if (!validators) {
          return;
        }

        if (
          validators === Validators.required ||
          (Array.isArray(validators) && validators.find((validator) => validator === Validators.required))
        ) {
          // Required validator found
          // Add required label to header column
          if (putRequiredFirst) col.static = true;
          col.headerHTML = `${col.headerHTML || col.header} <br><span class="col-required">(required)</span>`;
        }
      });
    }

    return columns;
  }

  sortColumnsForSelector(columns: Col[]) {
    // First make a copy
    const colsCopy = [...columns];
    // First round, put visible columns at first
    colsCopy
      .sort((col1, col2) => {
        if (col1.visible && !col2.visible) return -1;

        if (!col1.visible && col2.visible) return 1;
        return 0;
      })
      .sort((col1, col2) => {
        // Second round, now static columns are first
        if (col1.static && !col2.static) return -1;

        if (!col1.static && col2.static) return 1;
        return 0;
      })
      .sort((col1, col2) => {
        // Third round, the frozen columns moves at the top
        if (col1.frozen && !col2.frozen) return -1;

        if (!col1.frozen && col2.frozen) return 1;
        return 0;
      })
      .sort((col1, col2) => {
        // Forth round, Columns that are not data field move to top
        if (col1.field === "" && col2.field !== "") return -1;

        if (col1.field !== "" && col2.field === "") return 1;
        return 0;
      });

    return colsCopy;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
