/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { PersonalAccount } from "./personal-account";

export class PersonalAccountHelper {
  constructor(private _account: PersonalAccount) {}

  prefillFromFullName(fullName: string) {
    const fullNameArray = fullName.split(/\s+/);
    const firstName = fullNameArray.shift();
    const lastName = fullNameArray.join(" ");

    Object.assign(this._account.person, {
      firstName,
      lastName,
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
