/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, EventEmitter, OnInit, ViewChild, ViewContainerRef, OnDestroy, inject } from "@angular/core";
import { MenuItem } from "primeng/api";
import { Inventory } from "src/app/core/inventory/inventory/inventory";
import { Col, RowSelectionType } from "src/app/form-list/form-list/form-list";
import { DBService } from "src/app/shared/services/db.service";
import { SearchResultItem } from "src/app/taku-ui/taku-search-accounts/SearchResultItem";
import { GenericInvoiceSaleComponent } from "../../sale-document/generic-invoice-doc.component";
import { OverlayModalName } from "../../sale-document/generic-sale-doc.component";
import { SaleDoc } from "../../sale-document/sale-doc/sale-doc";
import { InvoiceKeyboardActionsService } from "../../sale-document/sale-invoice-doc-base/invoice-keyboard-actions.service";
import { TenderScreenData } from "../../sale-document/sale-invoice-doc-base/sale-invoice-doc-base.component";
import { TenderScreenMode } from "../../sale-document/tender-screen/tender-screen.component";
import { DocumentSearchReturnColsServiceFactory } from "../document-search-return/document-search-return.service";
import { ReturnInvoiceStateService } from "../return-invoice-state.service";
import { ReturnSaleDocComponent } from "../return-sale-doc/return-sale-doc.component";
import { ReturnsSideDialogName } from "../../types/invoice-enums.type";
import { AuthService } from "src/app/shared/services/auth.service";
import { Subscription } from "rxjs";
import { UntypedFormBuilder } from "@angular/forms";
import { SaleDocLine } from "../../sale-document/sale-doc-line/sale-doc-line";
import * as _ from "lodash";
import { ReturnSaleDocLineComponent } from "../return-sale-doc-line/return-sale-doc-line.component";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { SelfCheckoutItemDetailsComponent } from "../../sale-document/self-checkout-item-details/self-checkout-item-details.component";
import { mergeMap } from "rxjs/operators";
import { ReturnInvoiceService } from "../return-invoice.service";
import { SaleDocType } from "../../doc/doc";
import { Router } from "@angular/router";
@Component({
  selector: "taku-return-invoice-doc-base",
  template: "",
  styles: [],
})
export class ReturnInvoiceDocBaseComponent extends GenericInvoiceSaleComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];
  @ViewChild("saleDocComponent", { static: true }) docComponent: ReturnSaleDocComponent;
  // @ViewChild('accountsSearchBox', { static: false }) accountsSearchComponent: TakuSearchAccountsComponent;
  // @ViewChild('btnNewAccounts', { static: false }) btnNewAccountsComponent: SplitButton;

  returnDocsColumns: Col[];
  _returnDocPreviewSelected: EventEmitter<SaleDoc> = new EventEmitter();
  docForReturnPreview: SaleDoc;
  _activeDialog: ReturnsSideDialogName;
  _activeDialogExtra: any;
  _returnsSearchExtraParams = {
    includes: "saleDocLine,saleDocTender",
  };
  readonly cssClassName = "return-invoice-component";

  private router = inject(Router);

  docTypesMenu: MenuItem[] = [
    {
      label: "SALES",
      command: (): void => void this.router.navigate(["/sell/saleDoc", 0]),
    },
    // { label: "SALES ORDERS", command: () => this.docComponent.switchDocType('salesOrders') },
  ];

  // enums
  // DocType = DocType;
  RowSelectionType = RowSelectionType;
  SideDialogName = ReturnsSideDialogName;
  TenderScreenMode = TenderScreenMode;

  constructor(
    viewContainer: ViewContainerRef,
    keyboardActionsService: InvoiceKeyboardActionsService,
    public docSearchServiceFactory: DocumentSearchReturnColsServiceFactory,
    private dbService: DBService,
    protected returnInvoiceStateService: ReturnInvoiceStateService,
    protected auth: AuthService,
    public fb: UntypedFormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef
  ) {
    super(viewContainer, keyboardActionsService, returnInvoiceStateService, auth);
  }

  private returnInvoiceService = inject(ReturnInvoiceService);

  ngOnInit(): void {
    document.body.classList.add(this.cssClassName);

    // When a document has been selected from formlist it should communicate to search return component
    this.subsList.push(
      this._returnDocPreviewSelected
        .pipe(mergeMap((saleDoc) => this.returnInvoiceService.getSaleDocToReturn(saleDoc.id)))
        .subscribe((saleDoc) => {
          this.docForReturnPreview = saleDoc;
          this.closeRightDialog();
        })
    );

    this.returnDocsColumns = this.docSearchServiceFactory.build(this._returnDocPreviewSelected).getFormListColumns();
    if (this.returnInvoiceStateService.hasInvoiceState()) this.returnInvoiceStateService.restoreInvoiceState(this);
  }

  ngOnDestroy(): void {
    this.ref?.close(false);
    document.body.classList.remove(this.cssClassName);
    this.returnInvoiceStateService.saveInvoiceState(this);
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }

  onDocReset(saleDoc: SaleDoc) {
    this.docForReturnPreview = saleDoc;
  }

  onInventoryItemSaved(inventory: Inventory) {
    if (this._activeDialog == ReturnsSideDialogName.INVENTORY_DETAILS) {
      this._activeDialogExtra = inventory;
    }
  }

  ///////////////////////
  // HEADER's methods //
  /////////////////////
  onClearAccBtnPressed() {
    this.docComponent.clearSelectedAccount();
  }

  onReturnNoReceiptItem() {
    if (this._returnsSearchComponent) this._returnsSearchComponent.clearSearchFields();
    this.openRightDialog(ReturnsSideDialogName.RETURN_ITEM_NOINVOICE);
  }

  searchReceiptsOpened() {
    this.openRightDialog(ReturnsSideDialogName.RETURN_ITEM_SEARCH_FORM);
  }

  onItemReturned(inventory: Inventory) {
    // Add as a SaleDoc Line as a return (negative quantity)
    if (inventory.isVariantParent) {
      const saleLine: SaleDocLine = _.merge(new SaleDocLine(), {
        unitPrice: inventory.standardPrice,
        unitCost: inventory.defaultInventoryZone?.standardCost || 0,
        qty: 1,
        // TODO: Fill out DocLine
        docLine: {
          id: 0,
          seqNo: 1,
          note: "",
          expiryDate: null,
          serialNo: "",
          refNo: 0,
          inventoryId: inventory.id,
          inventory: inventory,
        },
      });
      const docLineForm = ReturnSaleDocLineComponent.set(this.fb, saleLine, 0);

      this.ref = this.dialogService.open(SelfCheckoutItemDetailsComponent, {
        styleClass: "selfcheckout-mobile rounded-selfcheckout-dialog",
        contentStyle: {
          overflow: "auto",
          height: "60%",
          minWidth: "280px",
          maxWidth: "680px",
          maxHeight: "1100px",
        },
        data: {
          _saleForm: docLineForm,
          _isAddToCart: true,
        },
      });

      this.subsList.push(
        this.ref.onClose.subscribe((result) => {
          if (result && result.qtySelected > 0) {
            this.docComponent._addToSaleAsNewLine(docLineForm.value.docLine.inventory, result.qtySelected);
            // let itemIndexInCart = 0;
            // this.docComponent.saleDocLines.value.forEach((element, index) => {
            //   if (element.docLine.inventoryId === inventory.id) {
            //     itemIndexInCart = index;
            //   }
            // });
            // if (result !== undefined) {
            //   const newSaleDocLine = this.docComponent.saleDocLines.at(itemIndexInCart);
            //   // const originalQty = newSaleDocLine.get('qty').value -1;
            //   // let newQty = 0;
            //   // newQty = originalQty + result.qtySelected;
            //   newSaleDocLine.patchValue({ qty: result.qtySelected });
            this.closeRightDialog();
            // }
          }
        })
      );
    } else {
      this.docComponent._addToSaleAsNewLine(inventory, 1);
      this.closeRightDialog();
    }
  }

  openRightDialog(dialog: ReturnsSideDialogName, extra = null) {
    this._activeDialog = dialog;
    this._activeDialogExtra = extra;
  }

  openTenderScreen(tenderData: TenderScreenData) {
    this.openRightDialog(ReturnsSideDialogName.TENDER_SCREEN, tenderData);
    this.returnInvoiceStateService.anounceTenderScreenVisibility(true);
  }

  openFormListForReturnSearch(filterQuery: {}) {
    this.openRightDialog(ReturnsSideDialogName.RETURN_ITEM_SEARCH_RESULTS, filterQuery);
  }

  closeRightDialog() {
    if (this._activeDialog === ReturnsSideDialogName.TENDER_SCREEN)
      this.returnInvoiceStateService.anounceTenderScreenVisibility(false);

    this._activeDialog = null;
  }

  _showSearchView() {
    this.openRightDialog(ReturnsSideDialogName.SEARCH_RESULTS);
  }

  onDocLinesDeleted(docNo: string) {
    this._returnsSearchComponent.undoAllReturns(docNo);
  }
  docLineQtyChanged({ lineId, qty }) {
    this._returnsSearchComponent.returnQtyChanged(lineId, qty);
  }

  onSearchResAccount(account: SearchResultItem) {
    this.docComponent._setAsDocAccount(account);
    this.accountsSearchComponent.cleanAndFocusSearchInput();
  }

  openAddNewAccountDialog(e: Event) {
    this.btnNewAccountsComponent.onDropdownButtonClick(e);
  }

  restoreActiveOverlay(activeOverlay: OverlayModalName, activeOverlayExtra: any, activeOverlayParams: any) {
    // Set behaviour on children components
  }

  dialogExists(validDialogs, testDialog): boolean {
    return Object.values(validDialogs).includes(testDialog);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
