/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, OnChanges, Input } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { LocationService } from "../../shared/services/location.service";

@Component({
  selector: "taku-multi-sub-region",
  templateUrl: "./taku-multi-sub-region.component.html",
  styleUrls: ["./taku-multi-sub-region.component.scss"],
})
export class TakuMultiSubRegionComponent implements OnChanges {
  @Input() formGroup: UntypedFormGroup;
  @Input() fieldName: string;
  @Input() fieldCaption: string;
  @Input() countryIsoCode: string;
  @Input() disabled = false;
  // @Input() options;

  lookup_subdivisions: any[];

  constructor(public locationService: LocationService) {}

  ngOnChanges() {
    if (this.countryIsoCode) {
      this.lookup_subdivisions = this.locationService.lookupSubdivisionOptions(this.countryIsoCode, false);
    }
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
