<p-toolbar [ngClass]="{ 'shorter-height': shortenHeight }">
  <div class="p-toolbar-group-left col">
    <button
      *ngIf="showBackBtn && !isDisplayModeWizard"
      pButton
      pRipple
      type="button"
      class="p-button-rounded header-button toolbar-backbtn"
      (click)="onBack()"
      [icon]="insideDialog ? 'pi pi-times' : 'pi pi-arrow-left'"
    ></button>
    <div class="icon-and-toolbar-title">
      <i *ngIf="iconStyleClass" class="icon" [ngClass]="iconStyleClass">{{ iconName }}</i>
      <h1 *ngIf="toolbarTitle" [innerHTML]="toolbarTitle | safeHtml"></h1>
    </div>
    <i *ngIf="extraButtonIcon" class="icon" [ngClass]="extraButtonIcon" (click)="extraIconClicked($event)"></i>
  </div>

  <ng-content select="[toolbar-center-nocss]"></ng-content>

  <div class="p-toolbar-group-right">
    <ng-content></ng-content>
    <div *ngIf="showActionBtns; then mainButtons"></div>
    <div
      *ngIf="showActionBtns && showSecondaryBtns && !(insideDialog || isDisplayModeWizard); then secondaryButtons"
    ></div>
  </div>

  <ng-template #mainButtons>
    <button
      pButton
      pRipple
      type="button"
      class="header-button p-button-rounded p-button-icon-only"
      (click)="onRevert()"
      [disabled]="formGroup?.pristine || disableButtons"
      icon="_"
      ><i class="material-icons"> undo </i></button
    >
    <button
      id="save_button"
      aria-label="Save"
      pButton
      pRipple
      type="button"
      class="header-button p-button-rounded"
      (click)="onSave()"
      [disabled]="formGroup?.pristine || !formGroup?.valid || disableButtons"
      icon="pi pi-save"
      [ngClass]="{ 'p-button-ternary-custom': !formGroup?.pristine && formGroup?.valid }"
      [loading]="isSaving"
    ></button>
  </ng-template>

  <ng-template #secondaryButtons>
    <button
      type="button"
      class="header-button toolbar-newbtn p-button-rounded"
      pButton
      pRipple
      icon="pi pi-plus"
      [disabled]="!formGroup?.pristine || !formGroup?.valid || disableButtons"
      (click)="onNew()"
    ></button>
    <button
      type="button"
      class="header-button toolbar-morebtn p-button-rounded"
      pButton
      pRipple
      icon="pi pi-ellipsis-v"
      (click)="menu.toggle($event)"
      [disabled]="disableButtons"
    ></button>
    <div #morebtnOverlayWrapper class="morebtnOverlayWrapper"></div>
    <p-menu #menu [popup]="true" [model]="items" [appendTo]="'body'" [style]="{ width: '100px' }"> </p-menu>
  </ng-template>
</p-toolbar>
