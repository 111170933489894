<div id="tape-preview-main">
  <header>
    <section id="retailer-info" [ngStyle]="{ fontSize: fontSizeToNumber(settings.headerFontSize) + 'px' }">
      <div *ngIf="settings.printLogo" class="retailer-logo-wrapper">
        <img *ngIf="businessLogoUrl" [attr.src]="businessLogoUrl" [attr.alt]="businessDetail.businessName" />
        <span *ngIf="!businessLogoUrl" class="retailer-logo-placeholder">YOUR LOGO HERE</span>
      </div>
      <h2 *ngIf="settings.printStoreName" class="retailer-name">{{ saledoc.store.storeName }}</h2>
      <div *ngIf="settings.printAddress" class="retailer-address">
        <div class="address-line1">{{ saledoc.store.address?.line1 }}</div>
        <div class="address-city"
          >{{ saledoc.store.address?.city }}, {{ subdivisionDisplay(saledoc.store.address?.subDivisionIsoCode) }}
          {{ saledoc.store.address?.postalCode }}</div
        >
      </div>

      <div *ngIf="saledoc.taxID" class="retailer-tax-info"> Account Tax ID#: {{ saledoc.taxID }} </div>

      <div *ngIf="settings.printPhoneNo && saledoc.store.address?.addressPhone" class="retailer-phone">
        <span class="phone-label">Phone:</span> {{ saledoc.store.address?.addressPhone.fullPhoneNumber }}
      </div>

      <div *ngIf="settings.printWebsite" class="retailer-website">{{ saledoc.store.url }}</div>
      <div *ngIf="settings.printEmail" class="retailer-email">
        {{ saledoc.store.address?.addressEmail.email }}
      </div>
    </section>

    <section id="receipt-details" [ngStyle]="{ fontSize: fontSizeToNumber(settings.headerFontSize) + 'px' }">
      <ul class="receipt-details-col-left">
        <li>
          <span class="receipt-field-label">GIFT RECEIPT</span>
        </li>
        <li *ngIf="settings.printDocumentNo">
          <span class="receipt-field-label">Receipt #:</span> {{ saledoc.doc.docNo }}
        </li>
        <li *ngIf="settings.printDate">
          <span class="receipt-field-label">Date:</span>
          {{ saledoc.doc.docDate | dateTimeFormat : saledoc.doc.docTime | trim : true }}
        </li>
      </ul>

      <ul class="receipt-details-col-right">
        <li> <span class="receipt-field-label">User:</span> {{ saledoc.doc.user?.userName }} </li>
        <li> <span class="receipt-field-label">Store:</span> {{ saledoc.store.storeID }} </li>
        <li> <span class="receipt-field-label">Till:</span> {{ saledoc.station?.stationNumber }} </li>
      </ul>
    </section>
  </header>

  <main>
    <section id="gift-details">
      <h1>A GIFT FOR YOU!</h1>

      <table class="people-names">
        <tr>
          <td class="label-col"><span>To:</span></td>
          <td class="name-col"></td>
        </tr>
        <tr class="spacing-row">
          <td colspan="2"></td>
        </tr>
        <tr>
          <td class="label-col"><span>From:</span></td>
          <td class="name-col"></td>
        </tr>
      </table>

      <div class="receiptBarcode-wrapper">
        <svg #receiptBarcode></svg>
      </div>
    </section>

    <section id="document-note" class="store-policy">
      <span class="title">GIFT RECEIPT NOTE:</span>
      <span class="content" [innerHTML]="policies.giftReceiptPolicy"></span>
    </section>

    <section *ngIf="settings.printReturnPolicy && policies?.returnPolicy" id="returns">
      <div class="returns-policies store-policy">
        <span class="title">STORE POLICY:</span>
        <span class="content" [innerHTML]="policies.returnPolicy"></span>
      </div>
    </section>
    <section *ngIf="settings.printSalePolicy && policies?.salesPolicy" id="sales">
      <div class="sales-policies store-policy">
        <span class="title">SALES POLICY:</span>
        <span class="content" [innerHTML]="policies.salesPolicy"></span>
      </div>
    </section>
  </main>

  <footer>
    <section *ngIf="settings.printSocialIcons" id="social-links">
      <div class="social-links-inner">
        <div
          *ngFor="let socialProfile of saledoc.store.storeSocialProfiles"
          class="social-account {{ socialProfile.socialProfileType }}-account"
        >
          <ng-template [ngIf]="socialProfile.siteURL">
            <span class="social-logo-wrapper"><img [src]="socialProfile.socialIconURL" /></span>
            <span class="social-link">{{ socialProfile.socialProfile }}</span>
          </ng-template>
        </div>
      </div>
    </section>

    <!-- <section *ngIf="settings.customerNote" id="acknowledge-note">
      <span>{{settings.customerNote}}</span>
    </section> -->

    <a href="https://takulabs.io/sign-up-today" target="_blank">
      <section *ngIf="settings.printPoweredByTalulabs" id="powered-by">
        <img src="/assets/components/receipt-preview/images/taku-logo.png" alt="TakuLabs" class="app-logo" />
        <span class="text">Powered by TAKU</span>
      </section>
    </a>
  </footer>
</div>

<!-- <div class="col-12">
  {{saledoc|json}}
</div> -->
