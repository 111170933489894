const TEN_MINUTES = 600_000;
const FIVE_MINUTES = 300_000;
const ONE_MINUTE = 60_000;
const NO_CACHING = 0;

/**
 * To prevent a particular model from hitting the default cache,
 * set `model: NO_CACHING`
 */
export const CacheMillisecondsPerModel: Record<string, number> = {
  takuPaymentTerminal: TEN_MINUTES,
  takuPaymentAccount: TEN_MINUTES,
  takuPaymentGateway: TEN_MINUTES,
  paymentTerminal: TEN_MINUTES,
  acctGroup: FIVE_MINUTES,
  businessFormat: TEN_MINUTES,
  uOfMeasure: TEN_MINUTES,
  recurringOrderSetting: TEN_MINUTES,
  priceLevel: TEN_MINUTES,
  promotion: TEN_MINUTES,
  category: TEN_MINUTES,
  stock: TEN_MINUTES,
  brand: TEN_MINUTES,
  taxInventoryCategory: TEN_MINUTES,
  tenderType: TEN_MINUTES,
  paymentTerm: TEN_MINUTES,
  shippingTerm: TEN_MINUTES,
  storeSettingEmail: TEN_MINUTES,
  storeSettingGiftReceipt: TEN_MINUTES,
  storeSettingKiosk: TEN_MINUTES,
  storeSettingLetterSalePrint: TEN_MINUTES,
  storeSettingLetterReturnPrint: TEN_MINUTES,
  storeSettingReturnReason: TEN_MINUTES,
  storeSettingReturn: TEN_MINUTES,
  storeSettingSaleDoc: TEN_MINUTES,
  storeSettingTapeSalePrint: TEN_MINUTES,
  storeSettingTapeReturnPrint: TEN_MINUTES,
  storePolicy: TEN_MINUTES,
  storeTenderType: TEN_MINUTES,
  storeTenderTypeList: TEN_MINUTES,
  storeSocialProfile: TEN_MINUTES,
};
