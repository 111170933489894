import { InventoryOptionsSetting } from "../inventory-options-setting/inventory-options-setting";

export class InventoryOptionsSettingValue {
  id: number;
  optionValue: string;
  isActive: boolean;
  inventoryOptionsSettingId: number;

  constructor(inventoryOptionsSettingId: number) {
    this.id = 0;
    this.optionValue = "";
    this.isActive = true;
    this.inventoryOptionsSettingId = inventoryOptionsSettingId;
  }
}
