/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../shared/services/auth.service";
import { AppSettingsStorageService } from "../shared/app-settings-storage.service";
import { ConfirmationService } from "primeng/api";

@Injectable({
  providedIn: "root",
})
export class AccessRightsGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router,
    private appSettingsService: AppSettingsStorageService,
    protected confirmationService: ConfirmationService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let accessRight = false;
    const featureNames: string[] = next.data.accessRightFeatureNames;
    const hideInAdminMode = next.data.hideInAdminMode;
    const disableInAdminMode = next.data.disableInAdminMode;

    // The user needs Visible and Enable access rights on the all the features in the featureNames list.
    if (featureNames) {
      accessRight = featureNames.every(
        (featureName) => this.auth.hasEnablePermit(featureName) && this.auth.hasVisiblePermit(featureName)
      );
    }

    if (this.appSettingsService.getStoreId()) {
      if (accessRight) return true;
    } else {
      if (accessRight && !hideInAdminMode && !disableInAdminMode) {
        return true;
      } else {
        this.router.navigate(["/dashboard"]);
        return false;
      }
    }

    // Store the attempted URL for redirecting
    this.auth.redirectUrl = state.url;

    // User does not have the required access rights for this route. Redirect them to login page so they can login as another user.
    // this.router.navigate(['/login']);
    this.confirmationService.confirm({
      header: "Confirmation",
      message:
        "You need permission to perform this action. Please contact your TAKU administrator to review your settings.",
      acceptLabel: "ok",
      rejectVisible: false,
      acceptVisible: true,
      // accept: () => {
      //   observer.next(true);
      //   observer.complete();
      // },
    });

    return false;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
