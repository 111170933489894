/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { DBService } from "../../../../shared/services/db.service";
import { FormListModel } from "../../../../form-list/form-list/form-list-model.interface";
import { ValidatorFn, Validators } from "@angular/forms";
import { Col, DataType, FilterType } from "../../../../form-list/form-list/form-list";
import { ModuleType, RoleData } from "./role-data";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class RoleDatasService extends DBService implements FormListModel {
  //We want the form to be initially loaded sorted by the 'module' column.
  getRows(
    _model: string,
    _filter?: string,
    _offset?: number,
    _limit?: number,
    _sortField = "module",
    _sortOrder = 1,
    extraParams = {},
    _orFilter?
  ): Observable<any> {
    return super.getRows(_model, _filter, _offset, _limit, _sortField, _sortOrder, extraParams, _orFilter);
  }

  enumModuleTypes = this.enumSelectOptions(ModuleType);

  getValidationRules(): { [key: string]: ValidatorFn[] | {} } {
    return {
      canEdit: [Validators.required],
      canAdd: [Validators.required],
      canDelete: [Validators.required],
    };
  }

  getFormListColumns(): Col[] {
    const disableFieldPredicate = (rowData: RoleData) => {
      //if the module name contains 'Settings' we want to disable the field. This is used for the Add and Delete fields.
      return rowData.module.includes("Settings");
    };

    return [
      {
        field: "module",
        header: "Module",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enumModuleTypes,
        filterType: FilterType.enum,
      },
      {
        field: "caption",
        header: "Description",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        styleObj: { "text-align": "left" },
      },
      {
        field: "canEdit",
        header: "Edit",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.checkbox,
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.enum,
      },
      {
        field: "canAdd",
        header: "Add",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.checkbox,
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.enum,
        readonlyPredicate: disableFieldPredicate,
      },
      {
        field: "canDelete",
        header: "Delete",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.checkbox,
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.enum,
        readonlyPredicate: disableFieldPredicate,
      },
    ];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
