<taku-form-header
  [formGroup]="_myForm"
  (backClicked)="goBack()"
  (saveClicked)="onSave()"
  (revertClicked)="onRevert()"
  [toolbarTitle]="customHeaderTitle || 'Business Details'"
  [displayMode]="insideWizard ? FormHeaderDisplayMode.WIZARD : null"
  [iconStyleClass]="'material-icons'"
  [iconName]="'business'"
  [isSaving]="_isSaving"
>
</taku-form-header>
<div class="col-12 pl-0 pr-0">
  <div [formGroup]="_myForm" class="grid">
    <!-- <p-panel class="col-12   ">
      <ng-template pTemplate="header">
        <i class="icon ui-icon-attach-money"></i>
        <h1>
          BUSINESS DETAILS
        </h1>
      </ng-template> -->
    <div class="xl:col-4 md:col-5 lg:col-5 col-12">
      <p-panel>
        <ng-template pTemplate="header">
          <h3>Basic Information</h3>
        </ng-template>

        <div class="col-12">
          <taku-input [formGroup]="_myForm" fieldName="businessName" fieldCaption="Business Name"></taku-input>
        </div>

        <div class="col-12 drop-container">
          <taku-dropdown
            [formGroup]="_myForm"
            fieldName="businessType"
            fieldCaption="Business Type"
            [options]="enum_business_type"
          ></taku-dropdown>
        </div>

        <div class="col-12 drop-container">
          <taku-currency [formGroup]="_myForm" fieldName="defaultCurrencyIsoCode" fieldCaption="Default Currency">
          </taku-currency>
        </div>

        <div class="col-12 drop-container">
          <taku-time-zone-autocomplete-input [formGroup]="_myForm" fieldName="businessTimeZone" [isDropDown]="true">
          </taku-time-zone-autocomplete-input>
        </div>

        <hr class="fields-divider" />

        <div class="single-picture-uploader">
          <div class="col-12">
            <strong>Business Logo</strong>
          </div>

          <!-- TODO: Change url for backend implementation -->
          <div class="col-12">
            <p-fileUpload
              #fileUploader
              name="image"
              auto="true"
              accept="image/*"
              customUpload="true"
              (uploadHandler)="myUploader($event)"
            ></p-fileUpload>
          </div>

          <div class="col-12 picture-container" *ngIf="_myForm.value.urlBusinessDetailBusinessLogoUrl">
            <i class="material-icons clear-field" (click)="clearImage()">close</i>
            <img [attr.src]="logoImageUrl" />
          </div>
        </div>
      </p-panel>
    </div>

    <div class="xl:col-8 md:col-7 lg:col-7 col-12">
      <p-panel>
        <ng-template pTemplate="header">
          <h3>Contact Information</h3>
        </ng-template>

        <div class="col-12">
          <taku-input [formGroup]="_myForm" fieldName="website" fieldCaption="Website"></taku-input>
        </div>

        <hr class="fields-divider" />

        <taku-address [formGroup]="_myForm.get('address')" [autosetPhoneCountry]="true"></taku-address>
      </p-panel>
    </div>

    <!-- </p-panel> -->
  </div>
</div>
