/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, ElementRef, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { DomHandler } from "primeng/dom";
import { SaleDoc } from "../../../document/sale-document/sale-doc/sale-doc";
import { StorePoliciesSettings } from "../store-policies-settings/StorePoliciesSettings";
import { StoreSettingsGenericComponent } from "../StoreSettingsGenericComponent";
import { Font_Size, Sort_Order, StoreSettingLetterReturnPrint } from "./StoreSettingLetterReturnPrint";
import { Zone } from "../../zone-settings/Zone";
import { BusinessDetail } from "../../business-settings/business-detail/business-detail";

@Component({
  selector: "taku-full-page-returnreceipt-builder",
  templateUrl: "full-page-returnreceipt-builder.component.html",
  styleUrls: ["full-page-returnreceipt-builder.component.scss"],
})
export class FullPageReturnReceiptBuilderComponent extends StoreSettingsGenericComponent {
  lookup_font_size: any[];
  lookup_transaction_sorting: any[];
  @ViewChild("previewWrapper", { read: ElementRef, static: true }) previewWrapperEl: ElementRef;
  @ViewChild("invoicePreview", { read: ElementRef }) invoicePreviewEl: ElementRef;
  @ViewChild("previewWrapper2", { read: ElementRef, static: true }) previewWrapperEl2: ElementRef;
  @ViewChild("invoicePreview2", { read: ElementRef }) invoicePreviewEl2: ElementRef;
  @ViewChild("previewWrapper3", { read: ElementRef, static: true }) previewWrapperEl3: ElementRef;
  @ViewChild("invoicePreview3", { read: ElementRef }) invoicePreviewEl3: ElementRef;

  get invoiceScaleDown() {
    return this.getInvoiceScale(this.previewWrapperEl, this.invoicePreviewEl);
  }
  get invoiceScaleDown2() {
    return this.getInvoiceScale(this.previewWrapperEl2, this.invoicePreviewEl2);
  }
  get invoiceScaleDown3() {
    return this.getInvoiceScale(this.previewWrapperEl3, this.invoicePreviewEl3);
  }

  get invoiceScaleHeight() {
    return this.getInvoiceHeight(this.previewWrapperEl, this.invoicePreviewEl);
  }

  get invoiceScaleHeight2() {
    return this.getInvoiceHeight(this.previewWrapperEl2, this.invoicePreviewEl2);
  }

  get invoiceScaleHeight3() {
    return this.getInvoiceHeight(this.previewWrapperEl3, this.invoicePreviewEl3);
  }

  private getInvoiceScale(wrapperEl: ElementRef, invoiceEl: ElementRef) {
    return invoiceEl
      ? DomHandler.innerWidth(wrapperEl.nativeElement) / (1.1 * DomHandler.getOuterWidth(invoiceEl.nativeElement))
      : 1;
  }

  private getInvoiceHeight(wrapperEl: ElementRef, invoiceEl: ElementRef) {
    return invoiceEl
      ? Math.ceil(DomHandler.getOuterHeight(invoiceEl.nativeElement) * this.getInvoiceScale(wrapperEl, invoiceEl))
      : null;
  }

  // get invoiceLeftOffset(){
  //   const scale = this.invoiceScaleDown;
  //   return (2*scale)/(1 - scale);
  // }

  previewSize = "NORMAL";
  _pageTitlePrefix = "Return Full-Page Receipt";
  receiptDemoData: {
    saledoc: SaleDoc;
    policies: StorePoliciesSettings;
    businessDetail: BusinessDetail;
  };

  initLookups() {
    this.lookup_font_size = this.dbService.enumSelectOptions(Font_Size);
    this.lookup_transaction_sorting = this.dbService.enumSelectOptions(Sort_Order).map((item) => {
      if (item.value !== Sort_Order.Scan_Order && item.value !== null) {
        item.disabled = true;
        item.icon = "pi pi-lock";
      }

      return item;
    });
  }

  initValidation() {
    this._validation = {
      headerFontSize: Validators.required,
      lineFontSize: Validators.required,
      lineSortOrder: Validators.required,
    };
  }

  // Prevent issues with server side
  // ngOnInit() {
  //   super.ngOnInit();

  //   const disabledFields = ['printBillingAddress', 'printShippingAddress'];
  //   disabledFields.forEach(fieldName => {
  //     const fieldCtrl = this._myForm.get(fieldName);
  //     fieldCtrl.disable();
  //     fieldCtrl.setValue(true);
  //   });
  // }

  static init(fb: UntypedFormBuilder, zone: Zone): UntypedFormGroup {
    const tmpModel = new StoreSettingLetterReturnPrint(zone);
    const tmpForm = fb.group(tmpModel);

    return tmpForm;
  }

  initForm() {
    const activeZone = this.appSettingsService.getZone();
    this._model = "storeSettingLetterReturnPrint";
    this._object = new StoreSettingLetterReturnPrint(activeZone);
    this._myForm = FullPageReturnReceiptBuilderComponent.init(this.fb, activeZone);
  }

  ngOnInit() {
    super.ngOnInit();

    this.subsList.push(
      this.printingFactoryService.createDemoData().subscribe((demoData) => {
        this.receiptDemoData = demoData;
      })
    );
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
