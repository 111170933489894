import { Component, Input, OnInit } from "@angular/core";
import { Inventory } from "src/app/core/inventory/inventory/inventory";

@Component({
  selector: "taku-sale-doc-self-checkout-grid-card",
  templateUrl: "./sale-doc-self-checkout-grid-card.component.html",
  styleUrls: ["./sale-doc-self-checkout-grid-card.component.scss"],
})
export class SaleDocSelfCheckoutGridCardComponent implements OnInit {
  @Input() inventory: Inventory;
  @Input() width: number;
  @Input() height: number;

  @Input() _isMobile = false;

  get InventoryImagePinnedToPictureGallery() {
    return this.inventory?.inventoryImages.find((row) => row.pinToPictureGallery == true);
  }

  constructor() {}

  ngOnInit(): void {}
}
