/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ElementRef,
  inject,
} from "@angular/core";
import { FormControl, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { AngularFittextDirective } from "angular-fittext";
import * as _ from "lodash";
import { SelectItem, ConfirmationService } from "primeng/api";
import { Subject, merge } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { TakuInputComponent } from "../../../../taku-ui/taku-input/taku-input.component";
import { InvoiceHelpers } from "../../../../utility/InvoiceHelpers";
import { MonetaryHelpers } from "../../../../utility/MonetaryHelpers";
import { GenericSaleDocFooterComponent } from "../generic-sale-doc-footer.component";
import { TotalBySaleTax } from "../sale-doc-line/sale-doc-line";
import { DiscountType, FulfillmentStatus, SaleDoc } from "../sale-doc/sale-doc";
import { DBService } from "src/app/shared/services/db.service";
import { SaleInvoiceStateService } from "../sale-invoice-state.service";
import { InvoiceKeyboardActionsService } from "../sale-invoice-doc-base/invoice-keyboard-actions.service";
import { SaleDocType } from "../../doc/doc";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { DBTaxService } from "src/app/shared/services/db-tax.service";
import { SaleDocService } from "../sale-doc/sale-doc.service";

@Component({
  selector: "taku-sale-doc-footer",
  templateUrl: "./sale-doc-footer.component.html",
  styleUrls: ["./sale-doc-footer.component.scss"],
})
export class SaleDocFooterComponent extends GenericSaleDocFooterComponent implements OnChanges, OnInit {
  @Input() allowReturns = false;
  @Input() currencyIsoCode: string;

  @Output() saleParked = new EventEmitter<number>();
  @Output() itemReturned = new EventEmitter<number>();
  @Output() openDeliveryDialog = new EventEmitter<number>();
  @Output() docTotalChanged = new EventEmitter<number>();
  @Output() applyBulkDiscount: EventEmitter<any> = new EventEmitter();
  @ViewChild(AngularFittextDirective, { static: true }) invoiceTotalAmountDirective: AngularFittextDirective;
  @ViewChild("generalDiscountAmount", { static: true }) discAmountInputComponent: TakuInputComponent;
  @ViewChild("bulkDiscountAmount", { static: true }) bulkDiscAmountInputComponent: TakuInputComponent;
  bulkSaleForm: UntypedFormGroup;
  groupedTaxes: TotalBySaleTax[] = [];
  _decimalFormatting = "1.2-2";
  preorderSelected = false;
  shouldDisablePay = false;

  taxService = inject(DBTaxService);
  ngUnsubscribe = new Subject();

  protected readonly FulfillmentStatus = FulfillmentStatus;
  protected readonly SaleDocType = SaleDocType;

  get invoiceSubTotal() {
    return this.subTotalCtrl.value;
  }
  set invoiceSubTotal(newSubtotal) {
    this.saleForm.get("subTotal").setValue(newSubtotal, { emitEvent: false });
  }

  lookupShippers: SelectItem[];
  invoiceTotalTax = 0;

  enumDiscountType: SelectItem[] = [
    { label: "%", value: DiscountType.Percentage },
    { label: "$", value: DiscountType.Fixed },
  ];
  enumBulkDiscountType: SelectItem[] = [
    { label: "%", value: DiscountType.Percentage, disabled: false },
    { label: "$", value: DiscountType.Fixed, disabled: true },
  ];
  // Overlays visibility
  _overlaysVisibility: { [key: string]: boolean } = {
    discount: false,
    shipping: false,
    taxes: false,
  };

  orderEditLock = false;

  constructor(
    confirmationService: ConfirmationService,
    protected dbService: SaleDocService,
    invoiceStateService: SaleInvoiceStateService,
    keyboardActionsService: InvoiceKeyboardActionsService,
    elRef: ElementRef,
    fb: UntypedFormBuilder,
    public _route: ActivatedRoute,
    private _router: Router
  ) {
    super(confirmationService, dbService, invoiceStateService, keyboardActionsService, elRef);
    this.bulkSaleForm = fb.group({
      discountType: DiscountType.Percentage,
      discountAmount: 0,
    });

    this.orderEditLock = this._route.snapshot.queryParamMap.get("orderEditLock") == "true";
    this.subsList.push(
      this._router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.orderEditLock = this._route.snapshot.queryParamMap.get("orderEditLock") == "true";
        }
      })
    );
  }

  get transactionAreaDisabled(): boolean {
    return this.dbService.transactionAreaDisabled;
  }

  onApplyClick() {
    this.applyBulkDiscount.emit(this.bulkSaleForm.value);
  }

  onDiscAmountClicked() {
    setTimeout(() => {
      this.discAmountInputComponent.focusAndSelectInput();
    }, 0);
  }

  onBulkDiscAmountClicked() {
    setTimeout(() => {
      this.bulkDiscAmountInputComponent.focusAndSelectInput();
    }, 0);
  }

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes.saleForm) {
      this.subsList.push(
        this.saleForm
          .get("discountType")
          .valueChanges.pipe(
            distinctUntilChanged() // only emits if previous discount value was different
          )
          .subscribe((newDiscountType) => {
            this.onChangeDiscType();
          })
      );

      this.updateInvoiceSubTotal();
      this.updateInvoiceTotalTax();
      this.subsList.push(
        this.saleForm.valueChanges.subscribe((newValue) => {
          this.updateInvoiceSubTotal();
          this.updateInvoiceTotalTax();
          this.docTotalChanged.emit(this.invoiceTotal);
        })
      );
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.dbService
      .lookupSelectOptions("shipper", "shipperName", { enableFieldName: "isActive" })
      .subscribe((shippers) => {
        this.lookupShippers = shippers;
      });

    this.subsList.push(
      this.docTotalChanged.subscribe((newTotal) => {
        if (this.invoiceTotalAmountDirective) this.invoiceTotalAmountDirective.onWindowResize();
      })
    );

    this.subsList.push(
      this.invoiceStateService.docType$.subscribe(
        (value) => (this.preorderSelected = value === SaleDocType.pre_order ? true : false)
      )
    );

    this.subsList.push(
      merge(
        this.saleForm.get("saleDocLines").valueChanges.pipe(distinctUntilChanged()),
        this.saleForm.get("fulfillmentStatus").valueChanges.pipe(distinctUntilChanged()),
        this.saleForm.get("doc.docType").valueChanges.pipe(distinctUntilChanged()),
        this.saleForm.get("deliveryDate").valueChanges.pipe(distinctUntilChanged())
      ).subscribe(() => {
        this.shouldDisablePay = this.shouldDisablePayBtn();
      })
    );

    this.subsList.push(
      this.taxService.isPending.subscribe((data) => {
        this.shouldDisablePay = data || this.shouldDisablePayBtn();
      })
    );
  }

  onParkBtnPressed(): void {
    this.saleParked.emit(this.invoiceTotal);
  }

  itemReturnedPressed(): void {
    this.itemReturned.emit();
  }

  get _countTotalItems(): number {
    let countItems = 0;
    this.saleDocLines.controls.forEach((docLineForm: UntypedFormGroup) => {
      const qty = parseFloat(docLineForm.get("qty").value);
      if (!isNaN(qty)) countItems += qty;
    });

    return MonetaryHelpers.roundToDecimalPlaces(countItems, 4);
  }

  updateInvoiceSubTotal(): void {
    let subTotal = 0;
    for (let _i = 0; _i < this.saleDocLines.length; _i++) {
      subTotal += this.saleDocLines.at(_i).get("qty").value * this.saleDocLines.at(_i).get("salePrice").value;
    }
    // Update respective form field for server-side
    // TODO: Move this to another method, as right now it's causing Angular warnings
    // about the view being modified after checked
    // this.saleForm.get('subTotal').setValue(subTotal);

    this.invoiceSubTotal = MonetaryHelpers.roundToDecimalPlacesHalfUp(subTotal);
  }

  // get linesSubtotalDiscount(): number { // TODO: This method should be revised with proper promotion system
  //   // for now i assumed discount can be a percentage
  //   let totalDisc = 0;
  //   for (var _i = 0; _i < this.saleDocLines.length; _i++) {
  //     totalDisc += (this.saleDocLines.at(_i).get('qty').value
  //       * ((this.saleDocLines.at(_i).get('discountAmount').value * this.saleDocLines.at(_i).get('salePrice').value)) / 100);
  //   }
  //   return totalDisc;
  // }

  get invoiceGeneralDiscount(): number {
    const discountType = this.discountTypeCtrl.value;
    const discountAmount = this.discountAmountCtrl.value;
    let totalDiscount = 0;
    // If discount amount is empty just return the initial unitprice
    if (discountAmount === "" || discountAmount === null) return 0;

    switch (discountType) {
      case DiscountType.Fixed:
        totalDiscount = discountAmount;
        break;
      case DiscountType.Percentage:
        totalDiscount = (this.invoiceSubTotal * discountAmount) / 100;
        break;
    }
    // Round to only 2 decimal places
    return MonetaryHelpers.roundToDecimalPlaces(totalDiscount);
  }

  updateInvoiceTotalTax(): void {
    this.groupedTaxes = SaleDoc.convertIntoTypedObject(this.saleForm.value).groupedTaxLines;
    this.invoiceTotalTax = _.sumBy(this.groupedTaxes, (tax) => tax.total);
  }

  get invoiceTotal(): number {
    //const total = this.invoiceSubTotal - this.invoiceGeneralDiscount + this.invoiceTotalShipping + this.invoiceTotalTax;
    const total = MonetaryHelpers.roundToDecimalPlacesHalfUp(
      this.invoiceSubTotal - this.invoiceGeneralDiscount + this.invoiceTotalShipping + this.invoiceTotalTax
    );

    return total;
  }

  get invoiceTotalShipping(): number {
    const shippingCharge = this.saleForm.get("shipperChargeAmount").value;
    if (shippingCharge === "" || shippingCharge === null) return 0;

    // Make shipping negative if we are only refunding
    return (this.invoiceSubTotal < 0 ? -1 : 1) * MonetaryHelpers.roundToDecimalPlaces(parseFloat(shippingCharge));
  }

  onChangeDiscType() {
    InvoiceHelpers.switchDiscountType(
      this.discountTypeCtrl,
      this.discountAmountCtrl,
      this.subTotalCtrl,
      this.confirmationService,
      "Invoice Subtotal"
    );

    setTimeout(() => {
      if (this.discAmountInputComponent) this.discAmountInputComponent.focusAndSelectInput();
    }, 100);
  }

  onDeliveryLinkPressed() {
    this.openDeliveryDialog.emit();
  }

  get subTotalCtrl() {
    return this.saleForm.get("subTotal");
  }

  get discountTypeCtrl() {
    return this.saleForm.get("discountType");
  }

  get discountAmountCtrl() {
    return this.saleForm.get("discountAmount");
  }

  get _shipperName(): string {
    if (!this.lookupShippers) return null;

    const shipperId = this.saleForm.get("shipperId").value;
    const selectedShipper = this.lookupShippers.find((shipper) => shipper.value === shipperId);
    if (!selectedShipper) return null;

    return selectedShipper.label;
  }

  get maxDiscountAmount(): number {
    switch (this.discountTypeCtrl.value) {
      case DiscountType.Fixed:
        return this.invoiceSubTotal;

      case DiscountType.Percentage:
        return 100;
    }
  }

  private shouldDisablePayBtn(): boolean {
    return (
      (this.preorderSelected ||
        ([FulfillmentStatus.unfulfilled, FulfillmentStatus.processing, FulfillmentStatus.partly_Fulfillment].includes(
          this.saleForm.get("fulfillmentStatus").value
        ) &&
          this.saleForm.get("doc.docType").value === SaleDocType.sales_invoice)) &&
      (this.saleForm.get("deliveryDate").invalid || !this.saleForm.get("accountId")?.value)
    );
  }

  onNumpadDiscountAmount(amount) {
    this.discountAmountCtrl.setValue(amount);
  }

  onNumpadBulkDiscountAmount(amount) {
    this.bulkSaleForm.get("discountAmount").setValue(amount);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
