/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { CalendarDateRangeType } from "src/app/taku-ui/taku-calendar-range/taku-calendar-range.component";

export class ReportFilterFields {
  takuPaymentAccountId: number;
  stockId: number;
  storeId: number;
  dateRangeType: CalendarDateRangeType;
  dateFrom: Date;
  dateTo: Date;
  stationId: number;

  constructor() {
    this.takuPaymentAccountId = null;
    this.stockId = null;
    this.storeId = null;
    this.dateRangeType = null;
    this.dateFrom = null;
    this.dateTo = null;
    this.stationId = null;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
