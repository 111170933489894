<taku-form-header
  [formGroup]="_myForm"
  (backClicked)="goBack()"
  (saveClicked)="onSave()"
  (revertClicked)="onRevert()"
  [toolbarTitle]="pageTitle"
  iconStyleClass="material-icons"
  iconName="receipt"
  [showSecondaryBtns]="false"
></taku-form-header>

<div [formGroup]="_myForm" class="grid pt-2">
  <p-card class="col-12">
    <div class="grid lg:col-4 xl:col-3">
      <div class="col-12 drop-container">
        <taku-dropdown
          [formGroup]="_myForm"
          [options]="lookup_entry_method"
          fieldName="entryMethod"
          fieldCaption="Salesscreen Entry Method"
        ></taku-dropdown>
      </div>

      <div class="col-12">
        <taku-dropdown
          [formGroup]="_myForm"
          [options]="lookup_default_tab"
          fieldName="saleScreenDefaultTab"
          fieldCaption="Salesscreen Default Tab Options"
        ></taku-dropdown>
      </div>

      <div class="spacing2"></div>

      <div class="col-12">
        <taku-toggle
          [formGroup]="_myForm"
          [fieldName]="'autoCopyAccountAddress'"
          fieldCaption="Auto-Copy Pinned Address from Account Profile to Invoice Details"
          enabledStateLabel="ON"
          disabledStateLabel="OFF"
        >
        </taku-toggle>
      </div>
    </div>
  </p-card>
</div>
