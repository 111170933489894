<div [formGroup]="_myForm">
  <div class="grid">
    <div class="col grid">
      <div class="col-12 xl:col-5 drop-container">
        <taku-dropdown
          [disabled]="disableForm"
          [formGroup]="_myForm"
          [options]="enum_person_email_type"
          [fieldName]="'emailType'"
          [fieldCaption]="'Email Type'"
        >
        </taku-dropdown>
      </div>
      <div class="col-12 xl:col-7">
        <taku-input
          [disabled]="disableForm"
          [formGroup]="_myForm"
          [fieldName]="'email'"
          [fieldCaption]="'Email'"
        ></taku-input>
      </div>
    </div>
    <div class="col-fixed copy-icon-container">
      <taku-action-button [action]="ACTION_TYPE.COPY" (clickAction)="copyEmailToClipboard()"></taku-action-button>
    </div>
  </div>
</div>
