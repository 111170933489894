/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { TaxAccountCategory } from "../tax-account-category/tax-account-category";
import { TaxInventoryCategory } from "../tax-inventory-category/tax-inventory-category";
import { Tax } from "../tax/tax";

export class TaxRule {
  id: number;
  ruleName: string;
  countryIsoCode: string;
  cities: JSON;
  postalCodes: string;
  taxRate: number;
  taxCalcType: TaxCalcType;
  priority: number;
  isTimeLimited: boolean;
  timeFrom: string;
  timeTo: string;
  isEnabled: boolean;
  taxAccountCategories: TaxAccountCategory[];
  taxInventoryCategories: TaxInventoryCategory[];
  effectiveDate: Date;
  applyEffectiveDate: boolean;
  endDate: Date;
  taxId: number;
  tax: Tax;
  subDivisions: JSON;
  taxAmount: number;
  isOnlineTax: boolean;
  taxAcronym: string;

  constructor() {
    this.id = 0;
    this.ruleName = null;
    this.countryIsoCode = "CA";
    this.cities = null;
    this.postalCodes = "*";
    this.taxRate = 0;
    this.taxCalcType = TaxCalcType.positive;
    this.priority = 0;
    this.isTimeLimited = false;
    this.timeFrom = null;
    this.timeTo = null;
    this.isEnabled = true;
    this.taxAccountCategories = [];
    this.taxInventoryCategories = [];
    this.effectiveDate = null;
    this.applyEffectiveDate = false;
    this.endDate = null;
    this.taxId = null;
    this.tax = null;
    this.subDivisions = null;
    this.taxAmount = 0;
    this.isOnlineTax = false;
    this.taxAcronym = "";
  }

  get taxAccountCategoriesList() {
    return this.taxAccountCategories.map((row) => row.category).join("; ");
  }
  get taxInventoryCategoriesList() {
    return this.taxInventoryCategories.map((row) => row.category).join("; ");
  }
}

export enum TaxCalcType {
  positive = "Positive",
  rebate = "Rebate",
  exempt = "Exempt",
}

export class TaxRuleSubDivisionIsoCode {
  id: number;
  subDivisionIsoCode: string;
  constructor() {
    this.id = 0;
    this.subDivisionIsoCode = null;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
