<div [formGroup]="formGroup" class="p-float-label" [ngClass]="{ 'inputctrl-nofilled': !isFilled }">
  <p-dropdown
    [options]="lookup_subdivisions"
    [formControlName]="hasLoadedOptions() ? fieldName : null"
    [ngClass]="{ 'inputctrl-filled': isFilled }"
    [style]="{ 'min-width': '100%' }"
    filter="true"
    filterBy="label"
    resetFilterOnHide="true"
    appendTo="body"
  >
    <ng-template pTemplate="item" let-item>
      <span>{{ item.label }}</span>
    </ng-template>
  </p-dropdown>
  <label *ngIf="fieldCaption" for="{{ fieldName }}" [innerHTML]="fullFieldCaption"></label>
  <form-validation-messages
    [hidden]="formGroup.get(fieldName).valid || !formGroup.get(fieldName).dirty"
    [errors]="formGroup.get(fieldName).errors"
    [fieldName]="fieldName"
  ></form-validation-messages>
  <!-- <form-validation-messages *ngIf="formGroup.get(fieldName).touched" [errors]="formGroup.get(fieldName).errors"></form-validation-messages> -->
</div>
