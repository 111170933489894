import { Injectable, ViewContainerRef } from "@angular/core";
import { Observable } from "rxjs";
import { SaleDocTender } from "src/app/core/document/sale-document/sale-doc-tender/sale-doc-tender";
import { SaleDoc } from "src/app/core/document/sale-document/sale-doc/sale-doc";
import { TenderReceiptTapePreviewComponent } from "src/app/core/previews/receipt/tender-receipt-tape-preview/tender-receipt-tape-preview.component";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { DBSearchService } from "src/app/shared/services/db-search.service";
import { DBService } from "src/app/shared/services/db.service";
import { PrintingMgr } from "src/app/shared/services/printing-service.service";
import { StarCloudPrintServiceService } from "src/app/shared/services/star-cloud-print-service.service";
import { PrintHelpers } from "src/app/utility/PrintHelpers";
import { Other_Type } from "../../business-settings/tender-type/tender-type";
import { PrinterManufacturer } from "../../store-settings/printer/printer-settings";

@Injectable({
  providedIn: "root",
})
export class MonerisService {
  //Result code which return from moneris Cloud
  public sResultCodeError = "Error";
  public sResultCodeSuccess = "Success";
  public sResultCodeApproved = "Approved";
  public sResultCodeDeclined = "Declined";
  public sResultCodeCancelled = "Cancelled";
  public sResultCodeTerminalBusy = "Terminal Busy";
  public sResultCodeConnectionTimeout = "Connection Timeout";

  public sMesageUnknownError = "Unknown Error";
  public sMesageCommError = "No response from terminal, unable to determine transaction approval or decline.";
  public sMesageTerminalOffline = "Terminal is offline.";
  public sMessageCloseBatchApproved = "APPROVED";
  public sMessageCloseBatchAlreadySettled = "Batch Already Settled";
  public sMesageCheckStatusTransaction = "Check Status is not supported for transactions older than 45 days.";

  constructor(
    private dbService: DBService,
    private appSettingsService: AppSettingsStorageService,
    private starCloudPrintServiceService: StarCloudPrintServiceService
  ) {}

  pairTerminal(id: number) {
    return this.dbService._postRequest(this.dbService.webUrl + "monerisPairTerminal/" + id, {}, {});
  }

  unPairTerminal(id: number) {
    return this.dbService._postRequest(this.dbService.webUrl + "monerisUnPairTerminal/" + id, {}, {});
  }

  initializeTerminal(id: number) {
    return this.dbService._postRequest(this.dbService.webUrl + "monerisInitializeTerminal/" + id, {}, {});
  }

  Tokenization(id: number) {
    return this.dbService._postRequest(this.dbService.webUrl + "monerisTokenization/" + id, {}, {});
  }
  SetBeepVolume(id: number) {
    return this.dbService._postRequest(this.dbService.webUrl + "monerisSetBeepVolume/" + id, {}, {});
  }
  SetCalibrate(id: number) {
    return this.dbService._postRequest(this.dbService.webUrl + "monerisCalibrate/" + id, {}, {});
  }
  SetLanguage(id: number) {
    return this.dbService._postRequest(this.dbService.webUrl + "monerisSetLanguage/" + id, {}, {});
  }
  SetTip(id: number) {
    return this.dbService._postRequest(this.dbService.webUrl + "monerisSetTip/" + id, {}, {});
  }
  PanMaskingSetting(id: number) {
    return this.dbService._postRequest(this.dbService.webUrl + "monerisPanMaskingSetting/" + id, {}, {});
  }
  PEDCodeDownload(id: number) {
    return this.dbService._postRequest(this.dbService.webUrl + "monerisPEDCodeDownload/" + id, {}, {});
  }

  runTransaction(command: string, id: number, body: any): Observable<any> {
    return this.dbService._postRequest(this.dbService.webUrl + "monerisRunTransaction/" + command + "/" + id, body, {});
  }

  checkStatus(command: string, id: number, body: any): Observable<any> {
    return this.dbService._postRequest(this.dbService.webUrl + "monerisCheckStatus/" + command + "/" + id, body, {});
  }

  closeBatch(id: number): Observable<any> {
    return this.dbService._postRequest(this.dbService.webUrl + "monerisCloseBatch/" + id, {}, {});
  }

  openTotal(id: number): Observable<any> {
    return this.dbService._postRequest(this.dbService.webUrl + "monerisOpenTotal/" + id, {}, {});
  }

  getCardtype(_type): Other_Type {
    switch (_type) {
      case "P":
        return Other_Type.Interac;
      case "V":
        return Other_Type.Visa;
      case "M":
        return Other_Type.MC;
      case "AX":
        return Other_Type.Amex;
      case "C1":
        return Other_Type.JCB;
      case "NO":
        return Other_Type.Discover;
      default:
        return null;
    }
  }

  getResponseErrorCode(errorCode: string): string {
    switch (errorCode) {
      case "000":
        return `ECR Request accepted by the
            PED and a Moneris host
            request is returned to the
            ECR`;
      case "001":
        return `Moneris host Response
        received from the PED.`;
      case "002":
        return `Response received from the
        PED.`;
      case "003":
        return `Initialization complete. The Initialization process has completed up to the end of the
        Handshake record exchange
        between the ECR/PED and
        Moneris host.
        `;
      case "005":
        return `Close Batch part 1 complete.`;
      case "006":
        return `The Batch successfully closed
        and was in balance.`;
      case "007":
        return `The Batch successfully closed,
        but was out of balance.`;
      case "010":
        return `Transaction is partially
        approved – cardholder confirmation needed.`;
      case "011":
        return `EMV Pre-Auth response accepted.
        `;
      case "012":
        return `Pre-Auth is partially approved
        – cardholder confirmation
        needed.`;
      case "050":
        return `Auto retry requested. The 1st
        flag byte in FID ‘s’ contained a
        value of ‘1’.`;
      case "051":
        return `Response received, retry
        requested. The 1st flag byte in
        FID ‘s’ contained a value of
        either ‘2’ or ‘3’.
        `;
      case "052":
        return `Response received, initialization requested. The 3rd
        flag byte in FID ‘s’ contained a
        value ‘1’.
        `;
      case "089":
        return `Cashback amount not
        returned on response`;
      case "098":
        return `Cancel/Reset request accepted.
        `;
      case "099":
        return `Transaction Completed, process ECR response.
        `;
      case "100":
        return `Invalid Transaction Code.`;
      case "101":
        return `Invalid transaction request
        parameter such as Amount.`;
      case "102":
        return `Missing Amount field`;
      case "103":
        return `1. Invalid Entry Method.
        2. Card Present UnionPay Purchase or Pre-Authorization request is sent with Entry Method ‘W’.`;
      case "104":
        return `Missing TRACK 2 data. `;
      case "105":
        return `Invalid or missing Approval
        Number`;
      case "106":
        return `Missing Approval Number on
        Pre-Authorization Completion
        Request
        `;
      case "107":
        return `One, or more, of the field separator characters is either
        missing or invalid`;
      case "108":
        return `The EOT character designating
        the end of a message from
        the ECR is either missing or
        corrupted
        `;
      case "109":
        return `Invalid Close Batch/Totals flag`;
      case "110":
        return `Missing/invalid Clear Totals
        flag`;
      case "111":
        return `Invalid Merchant ID`;
      case "112":
        return `Missing Merchant ID`;
      case "113":
        return `Invalid ECR ID`;
      case "114":
        return `Missing ECR ID`;
      case "115":
        return `Invalid Clerk ID`;
      case "116":
        return `Invalid Echo Data`;
      case "117":
        return `Missing Clerk ID`;
      case "118":
        return `Invalid length or format of
        host routing code
        `;
      case "120":
        return `Unknown or illegal field. This
        condition code is a general validation error and may be
        returned for any transaction
        that contains an unknown or
        illegal field.`;
      case "122":
        return `Cashback Allowed Indicator is
        not expected`;
      case "123":
        return `Attempt to Set Floor Limit or
        Set SAF Without Host Initialization`;
      case "124":
        return `Missing/invalid Card Plan or
        Card Plan is not Credit`;
      case "125":
        return `Missing/invalid Floor Limit`;
      case "126":
        return `Missing/Invalid Maximum SAF
        Record
        `;
      case "127":
        return `SAF Mode is OFF`;
      case "128":
        return `SAF Was Declined by Host`;
      case "129":
        return `Missing/invalid Add/Remove
        BIN Range Indicator`;
      case "130":
        return `Missing/invalid Low BIN
        Range`;
      case "131":
        return `Missing/invalid High BIN
        Range
        `;
      case "132":
        return `BIN range table is full`;
      case "133":
        return `Invalid Extended Configuration
        `;
      case "134":
        return `Missing Extended Configuration`;
      case "135":
        return `Missing/invalid Clerk List`;
      case "136":
        return `Missing/invalid Display
        Timeout`;
      case "137":
        return `Either of the Invoice Number
        or Promo Code is present
        when the other is not`;
      case "138":
        return `Invalid Message Text – Line#n`;
      case "139":
        return `To-be-deleted BIN range cannot be found`;
      case "140":
        return `There is no previous PED message to resend`;
      case "141":
        return `Not supported in Unattended
        Mode`;
      case "142":
        return `Missing/invalid Entry Method
        Allowed Mask`;
      case "145":
        return `Missing/invalid Track Selection Indicator
        `;
      case "146":
        return `EMV Initialization Failed –
        EMV Insert Not Allowed`;
      case "147":
        return `EMV Card Read Error`;
      case "148":
        return `PED Serial Number is not
        present or cannot be
        retrieved by the PED
        `;
      case "149":
        return `Code download application is
        not present in the PED
        `;
      case "150":
        return `The transaction type requested is not allowed by the PED.
        The trans was not enabled via
        DID 'p' in the download, or
        the PED has been logged off,
        or no bin range has been
        added.
        `;
      case "151":
      case "613":
        return `Card Type not supported. The
        card type being used is not
        contained in the BIN table (via
        DIDs '0' - '29') or was not
        activated via DID 'k' in the
        download. Card Plan in the
        Token field cannot be found in
        the supported BIN ranges
        Manual entry of a card
        present Purchase or PreAuthorization using a
        UnionPay card UnionPay
        Force Post transaction`;
      case "152":
        return `Refund Limit exceeded: Transaction not allowed.`;
      case "153":
        return `Optional debit/credit indicator does not match card type
        used
        `;
      case "154":
        return `Cashback not allowed`;
      case "155":
        return `Invalid or missing variable
        name`;
      case "156":
        return `Invalid variable value`;
      case "157":
        return `Invalid configuration file name`;
      case "158":
        return `No Record Found`;
      case "160":
        return `Transaction Not Allowed: Reinitialization required`;
      case "161":
        return `Only one transaction retry is
        allowed per transaction.`;
      case "162":
        return `An ECR request with a new
        transaction, or command, was
        sent while another transaction has not yet completed.`;
      case "163":
        return `A '97' Reset command was
        issued by the ECR after the
        Moneris host response was
        received by the PED, but
        before the '99' Completion
        command was processed.`;
      case "164":
        return `Invalid MOTO E-Commerce
        Flag
        `;
      case "165":
        return `A '99' Completion command
        was issued by the ECR before
        it sent the Moneris host
        response to the PED.`;
      case "166":
        return `A '99' Completion command
        was issued by the ECR while
        the PED is in idle.`;
      case "170":
        return `Invalid Invoice Number`;
      case "171":
        return `The Invoice Number is disallowed for this card type`;
      case "172":
        return `Invalid Promo Code`;
      case "173":
        return `The Promo Code is disallowed
        for this card type`;
      case "174":
        return `Release SAF Record command was received
        and the SAF file was empty`;
      case "188":
        return `BIN range already added`;
      case "190":
        return `A card swiped at the ECR and included in the ECR
        request had a service code of either '2xx' or '6xx'`;
      case "191":
        return `SAF record cannot be released due to host disconnect`;
      case "192":
        return `SAF Maximum Size reached`;
      case "193":
        return `SAF record cannot be accepted due to Service Code x2x`;
      case "194":
        return `One of the Transaction Status Information checks fail`;
      case "195":
        return `Invalid Encrypted Track2 length or format`;
      case "196":
        return `Invalid Expiry Date`;
      case "197":
        return `Invalid Swiped Indicator`;
      case "198":
        return `Invalid/Missing Original Pre-Auth Amount`;
      case "199":
        return `Invalid Scan Type`;
      case "200":
        return `No match on Merchant ID. A Moneris host response code
        of '960' was returned in the Initialization transaction`;
      case "201":
        return `No match on PED Serial Number. A Moneris host response code
        of '961' was returned in the Initialization transaction`;
      case "202":
        return `Initialization MAC'ing error - The PED retries the host
        init for a second time and still receives the MAC error`;
      case "205":
        return `Initialization MAC'ing error - Contact Help Centre. The
        Initialization failed on a MAC error (on DID 'R')`;
      case "206":
        return `The Initialization failed for one of the reasons below:
        1. A Moneris host response code of '965' was returned in
        the Initialization transaction
        2. The Initialization transaction was aborted with a
        Moneris host response code of '882' returned on a download
        packet
        3. Any other reason the Initialization failed that is not
        covered by the '2xx' codes above.`;
      case "210":
        return `The Initialization failed due to an EMV failure`;
      case "250":
        return `No response received from the Host`;
      case "300":
        return `The LRC character, in the Moneris host response, did not
        match the expected value, as calculated by the PED`;
      case "301":
        return `No LRC character was detected after the ETX character in
        the Moneris host response`;
      case "302":
        return `No ETX character was detected in the Moneris host
        response`;
      case "303":
        return `No STX character was detected in the Moneris host
        response`;
      case "304":
        return `The Moneris host response message failed MAC verification`;
      case "306":
        return `A Moneris host response message is received, by the PED,
        when it is in idle, or otherwise not expecting one`;
      case "307":
        return `This condition code is issued for the following conditions:
        1. A normal Moneris host financial declined response was received
        where the Moneris host response code match one of the values
        downloaded in DID's 'O' or 'P'. For this condition, this code replaces
        the '099' code and the normal ECR response message is to be attached
        to the code.
        2. The Moneris host response message did not match up to the
        outstanding Moneris host request (i.e. the transaction code is
        different). For this condition, this code replaces the '001' code
        and the transaction is cancelled
        3. The Moneris host response message contained an invalid header
        Transaction Date or Time. For this condition, this code replaces the
        '001' code`;
      case "308":
        return `A non-Moneris host message was received when a Moneris host
        one was expected (i.e. the 1st two characters of the message did not
        have a routing code of '9.')`;
      case "309":
        return `Invalid Moneris host response received:
        1. One of the mandatory FID's ('F' (on approvals), 'X', 'g'
        (on declines), 'h', 's', or '9F' (EMV only)) is missing from a
        Moneris host financial response
        2. FID 'g' (on a decline) was empty (zero length) or only contained
        the '=' separator
        3. One of the FID's had corrupted data
        4. FID 'Q' has a different value or is missing data from the request`;
      case "310":
        return `This condition code is issued
        for the following conditions:
        1. An EMV card was pulled
        from the insertion reader
        prior to the transaction being
        completed.
        2. The EMV card declined a
        Moneris host response that
        was approved by the host.
        3. A customer declined the
        partially approved Moneris
        host response that was
        approved by the host`;
      case "350":
        return `Unrecoverable data error:
        error condition(s) (as defined
        for codes '300', '301', '302',
        and '303') has been detected
        in 3 consecutive attempts in
        reception of the Moneris host
        response`;
      case "400":
        return `The PINpad has timed out while waiting for
        the cardholder to respond to a displayed prompt`;
      case "401":
        return `The CANCEL key was pressed in response to
        a cardholder prompt on the PINpad`;
      case "402":
        return `The Moneris host response
        message was sent to the PED
        after the host response timer
        (as set from the data in DID 'J
        ') has expired, but before any
        other function, or transaction,
        was initiated by the ECR`;
      case "420":
        return `Secondary Application Is Not Responding`;
      case "450":
        return `One, or more, of the KKMAC, KKPE, or KKME master keys
        have been corrupted`;
      case "460":
        return `The 'Resend Last PED Response' message fails to reach the ECR`;
      case "461":
        return `Current batch needs to be
        closed before Code Download
        can be initiated`;
      case "500":
        return `The MOTO/E-Commerce Flag is not allowed`;
      case "501":
        return `Fallback transaction cannot be
        added to SAF`;
      case "503":
        return `Benefit Amount is greater
        than Total Amount`;
      case "504":
        return `Moneris Gift and Loyalty ID is
        missing or invalid`;
      case "505":
        return `Moneris Gift and Loyalty Init
        required`;
      case "506":
        return `No original transaction code
        for void`;
      case "507":
        return `Invalid original transaction
        code for void`;
      case "508":
        return `Bad or missing card code`;
      case "509":
        return `No totals for Retrieve Card
        Code Totals command`;
      case "511":
        return `Refund not allowed`;
      case "512":
        return `No host totals available for Close Batch`;
      case "513":
        return `Amount doesn't match`;
      case "514":
        return `CVC entered without Customer Track2 or CVC is invalid`;
      case "517":
        return `Current Moneris Gift and Loyalty batch needs to be closed
        before Code Download can be initiated`;
      case "518":
        return `Invalid Gift and Loyalty card
        code`;
      case "519":
        return `Card used falls into “Whitelisted” BIN range`;
      case "523":
        return `Trans request fails to connect
        or there is no host response. SAF is enabled and trans
        amount is greater than SAF
        Floor Limit`;
      case "524":
        return `Trans request fails to connect
        or there is no host response. SAF is not enabled and trans
        amount is greater than SAF
        Floor Limit`;
      case "525":
        return `Terminal ID Not on File`;
      case "526":
        return `Merchant ID Invalid`;
      case "527":
        return `Invalid or Missing IP Address`;
      case "528":
        return `Invalid or Missing IP Port`;
      case "529":
        return `Invalid or Missing SSL Indicator`;
      case "530":
        return `Token field exists and token is
        invalid or missing`;
      case "531":
        return `Debit or UnionPay cards are
        not supported for financial
        transactions with a token`;
      case "532":
        return `Token and Encrypted Track2
        are sent in the same ECR
        request`;
      case "533":
        return `Cashback request is sent with
        a token`;
      case "534":
        return `1. Invalid or missing Moneris
        Token value
        2. Moneris Token request has
        a valid value of '1' and a token
        is also sent in the ECR request
        message`;
      case "536":
        return `A Moneris token request is
        sent or a token is sent but
        tokenization is disabled`;
      case "538":
        return `No track is available on the
        card`;
      case "540":
        return `Customer declines the partially approved amount or
        allows the 'Approved Amount:' prompt to time-out`;
      case "541":
        return `1. Card Number length error
        2. Card Number is missing and
        there is no Token
        3. Token is missing and there
        is no Card Number`;
      case "542":
        return `Account Type length error`;
      case "543":
        return `DCC information in Purchase
        Void or Refund Void is missing
        or invalid`;
      case "544":
        return `1. Token and/or Original Foreign Currency Amount fields
        are not accepted for a DCC
        Pre-Auth Completion transaction
        2. DCC data were sent for
        Void but the original transaction was not DCC assessed`;
      case "545":
        return `Serial number is invalid or missing from the Cloud Prefix`;
      case "548":
        return `Invalid or missing URL`;
      case "549":
        return `Invalid or missing Port`;
      case "550":
        return `Invalid MAC data (stand-alone
          application)`;
      case "551":
        return `Error with MAC Generated
        data`;
      case "552":
        return `Language Code is incorrect`;
      case "560":
        return `Percentage value is incorrect`;
      case "561":
        return `Invalid IssuerId Data`;
      case "600":
        return `Invalid POS Entry Mode`;
      case "601":
        return `Invalid EMV Request Data`;
      case "602":
        return `Invalid EMV Additional Data`;
      case "603":
        return `Invalid Chip Condition Code`;
      case "604":
        return `Invalid EMV Reason Online
        Code`;
      case "605":
        return `Invalid EMV Issuer Script Results`;
      case "606":
        return `The EMV card had determined
        that its PIN try counter had
        been exceeded`;
      case "607":
        return `EMV card removed prior to
        completion of the transaction`;
      case "608":
        return `The transaction is declined by
        the EMV card prior to its
        being sent to the host. The
        transaction declined by the
        EMV card after host reply
        received`;
      case "609":
        return `An error was detected with
        the EMV chip 1. The chip error
        occurs before the transaction
        request goes to the host, then
        all EMV cards are treated the
        same. The chip error occurs,
        the transaction is declined
        and c.c. '609' is returned to
        the ECR 2. The chip error
        occurs after the transaction
        request goes to and is
        approved by the host, then all
        EMV cards are treated the
        same with the exception of
        MasterCard. For all other EMV
        cards, '609' is returned to the
        ECR and a reversal must be
        generated and sent back to
        the host. For MasterCard,
        even though there is a chip
        error, the transaction is still
        approved at the chip and the
        transaction is approved at the
        PED. No reversal is generated.
        The MasterCard '099' message will contain whatever
        data is available at the time
        and it is sent back in the '099'
        message`;
      case "610":
        return `An EMV chip card was attempted to be used for a Pre-Auth
        transaction when the extended configuration 'PN' (EMV
        Pre-Auth not allowed) is in
        effect`;
      case "611":
        return `A fallback swipe was attempted for a card whose BIN does
        not allow fallback (as per the
        setting in DID 't' in the download parameters)`;
      case "612":
        return `Invalid Track 2 Equivalent data on the EMV chip`;
      case "613":
        return `Card Type not supported.`;
      case "614":
        return `One or more mandatory EMV
        data items is missing from the
        Pre-auth Completion ECR
        request`;
      case "615":
        return `TC Advice is not supported`;
      case "616":
        return `Transaction Not Completed
        Reset '97' command received
        to stop the EMV transaction
        while waiting for host
        response`;
      case "621":
        return `Extended configuration 'PA' or
        'PD' was used when DID 's'
        did not contain a value of 'M'`;
      case "622":
        return `Invalid card name`;
      case "623":
        return `Missing card name`;
      case "624":
        return `Invalid application label`;
      case "625":
        return `Invalid application preferred
        name`;
      case "626":
        return `Invalid EMV application ID`;
      case "627":
        return `Invalid ARQC`;
      case "628":
        return `Invalid TVR for ARQC`;
      case "629":
        return `Invalid TC/AAC`;
      case "630":
        return `Invalid TVR for TC/AAC`;
      case "631":
        return `Invalid CVM indicator`;
      case "632":
        return `Invalid Form Factor`;
      case "633":
        return `Invalid Contactless Data`;
      case "634":
        return `Invalid format of the EMV terminal capabilities field in PreAuth Completion request`;
      case "640":
        return `MSD After-Reversal Code`;
      case "641":
        return `Invalid EMV ISO Response
        Code`;
      default:
        return "Undefined Error";
    }
  }

  printPaymentReceipt(
    printingMgr: PrintingMgr,
    saleDocTender: SaleDocTender,
    saleDoc: SaleDoc,
    printPreviewHost: ViewContainerRef,
    isTenderScreen = false
  ): void {
    if (
      this.appSettingsService.getStation() &&
      this.appSettingsService.getStation().receiptPrinter?.manufacturer === PrinterManufacturer.star_cloudPRNT
    ) {
      this.starCloudPrintServiceService.printStarCloudCardReceipt(saleDoc);
    } else {
      let storeInfoHtml = `<div class="storeInfoTopWrapper" style="font-family: monospace; display: flex; flex-direction: column; align-items: center;">`;
      if (printingMgr.tapeSizeBuilderSettings.printStoreName) {
        storeInfoHtml += `<h2 class="retailer-name">${saleDoc.store.storeName}</h2>`;
      }
      storeInfoHtml += `<span href="https://maps.google.com/?q=&t=m" target="_blank"></span>`;
      if (printingMgr.tapeSizeBuilderSettings.printAddress) {
        storeInfoHtml += `<div class="retailer-address" style="display: flex; flex-direction: column; align-items: center;">
                  <div class="address-line1">${saleDoc.store.address?.line1}</div>
                  <div class="address-city">${saleDoc.store.address?.city}, ${PrintHelpers.subdivisionDisplay(
          saleDoc.store.address?.subDivisionIsoCode
        )}
                    ${saleDoc.store.address?.postalCode}</div>
                </div>`;
      }
      if (printingMgr.tapeSizeBuilderSettings.printPhoneNo && saleDoc.store.address?.addressPhone) {
        storeInfoHtml += `<div class="retailer-phone">
                  <span class="phone-label">Phone:</span> ${saleDoc.store.address?.addressPhone.fullPhoneNumber}
                  </div>`;
      }
      if (printingMgr.tapeSizeBuilderSettings.printWebsite) {
        storeInfoHtml += `<div class="retailer-website">${saleDoc.store.url}</div>`;
      }
      if (printingMgr.tapeSizeBuilderSettings.printEmail) {
        storeInfoHtml += `<div class="retailer-email">${saleDoc.store.address?.addressEmail.email}</div>`;
      }
      storeInfoHtml += `</div><br>`;

      if (saleDocTender.refTransaction && saleDocTender.refTransaction["customerReceiptTemplate"]) {
        printingMgr.printTemplate(
          '<div style="max-width: 300px">' +
            storeInfoHtml +
            "<pre>" +
            saleDocTender.refTransaction["customerReceiptTemplate"] +
            "</pre></div>",
          printPreviewHost,
          TenderReceiptTapePreviewComponent,
          "Tender Receipt - Print Preview"
        );
      }

      if (
        saleDocTender?.refTransaction &&
        saleDocTender?.refTransaction["merchantReceiptTemplate"] &&
        (this.appSettingsService.getUserPreferences()?.tenderPrintingSettings?.extraCopy || !isTenderScreen)
      ) {
        printingMgr.printTemplate(
          '<div style="max-width: 300px">' +
            storeInfoHtml +
            "<pre>" +
            saleDocTender.refTransaction["merchantReceiptTemplate"] +
            "</pre></div>",
          printPreviewHost,
          TenderReceiptTapePreviewComponent,
          "Tender Receipt - Print Preview"
        );
      }
    }
  }
}
