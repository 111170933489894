/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { EventEmitter, TemplateRef, Type } from "@angular/core";
import { AbstractControl, FormControlStatus, UntypedFormGroup } from "@angular/forms";
import { MenuItem, SelectItem, TableState } from "primeng/api";
import { Observable } from "rxjs";
import { ArrayFormComponent } from "src/app/forms/array-form/array-form.component";

export class NestedCol {
  field: string;
  header: string;
  dataType: DataType;
  filterType: FilterType;
  dataOptions: SelectItem[];
  filterMatchMode?: string;
  filterField?: string;
  filterDataKey?: string;
  filterTransformer?: (arg0: string) => string;
  dataKey?: string;
  enableGlobalFilter?: boolean;
  globalFilterDataOptions?: SelectItem[] | Observable<SelectItem[]>;
  globalFilterMatchMode?: string;

  constructor({
    field,
    header,
    dataType,
    filterType,
    dataOptions,
    filterMatchMode,
    filterField,
    filterDataKey,
    filterTransformer,
    dataKey,
    enableGlobalFilter = true,
    globalFilterDataOptions,
    globalFilterMatchMode,
  }: {
    field: string;
    header: string;
    dataType: DataType;
    filterType: FilterType;
    dataOptions: SelectItem[];
    filterMatchMode?: string;
    filterField?: string;
    filterTransformer?: (arg0: string) => string;
    filterDataKey?: string;
    dataKey?: string;
    enableGlobalFilter?: boolean;
    globalFilterDataOptions?;
    globalFilterMatchMode?;
  }) {
    this.field = field;
    this.header = header;
    this.dataType = dataType;
    this.filterType = filterType;
    this.dataOptions = dataOptions;
    this.filterMatchMode = filterMatchMode;
    this.filterField = filterField;
    this.filterDataKey = filterDataKey;
    this.filterTransformer = filterTransformer;
    this.dataKey = dataKey;
    this.enableGlobalFilter = enableGlobalFilter;
    this.globalFilterDataOptions = globalFilterDataOptions;
    this.globalFilterMatchMode = globalFilterMatchMode;
  }
}

export class Col {
  constructor(key: string) {
    this.field = key;
    this.header = key;
    this.footer = false;
    this.visible = true;
    this.static = false;
    this.readonly = false;
    this.frozen = false;
    this.isNotSortable = false;
    this.dataKey = "id";
    this.filterDataKey = null;
    this.dataType = DataType.input;
    this.dataTypeOptions = {};
    this.dataOptions = [];
    this.filterType = FilterType.contains;
    this.filterOptions = null;
    this.filterMatchMode = null;
    this.filterField = null;
    this.sortFields = null;
    this.onModelChange = null;
    this.importingOptions = null;
    this.showValidationErrors = true;
    this.disabled = false;
    this.selectorIcon = null;
    this.enableGlobalFilter = true;
    this.children = [];
    this.globalFilterDataOptions = null;
    this.globalFilterMatchMode = null;
    this.styleObj = null;
    this.classObj = null;
    this.filterTransformer = null;
    this.readonlyPredicate = null;
    this.info = null;
    this.displayFn = null;
  }

  static boolean_filter_enum: SelectItem[] = [
    { label: "True", value: true },
    { label: "False", value: false },
  ];
  field: string; // field name like shortDesc
  header: string; // header name the field
  headerHTML?: string; // HTML-ready header label for columns
  footer?: boolean;
  visible: boolean; // if field is not visible you can find field name in a drop down on top left
  static?: boolean;
  readonly: boolean; // if this field is read noly and you can not edit it in form-list
  frozen: boolean; // if field is frozen it should be true
  dataType: DataType; // DataType is a class that you can select one of them in a list beloew
  dataTypeOptions?: any;
  /** This will be used for filtering if filterOptions is not defined */
  dataOptions:
    | SelectItem[]
    | Observable<SelectItem[]>
    | ((rowData: any, allRows: any[], allOrgRows: any[]) => SelectItem[]); // it is a function from options(it could be enumbe or lookups or multiselect)
  dataKey?: string;
  filterDataKey?: string;
  /** you can find filterType enum in this page and based on selection you can see different type of filter on every column */
  filterType: FilterType;
  /** property for creating custom options for fitering, by default it uses dataOptions. Input SelectItem[] to be displayed in filter */
  filterOptions?: SelectItem[] | Observable<SelectItem[]> | ((colsFilters: Record<string, any>) => SelectItem[]);
  /** filter matchmode - contains, equals and so on */
  filterMatchMode?: string;
  filterField?: string;
  filterTransformer?: (string) => string;
  sortFields?: string[];
  colWidth?: number; // Override default column width (pixels)
  isNotSortable?: boolean;
  onModelChange?: (rowData: any, _orgObjects: any[], formGroup: UntypedFormGroup) => void;
  setupFormControl?: FormControlConfigurator;
  importingOptions?: { header: string; headerHTML?: string; colWidth?: number };
  exportable?: boolean;
  fieldValue?: string;
  showValidationErrors?: boolean;
  disabled?: boolean;
  selectorIcon?: string;
  enableGlobalFilter?: boolean;
  /** TODO what does this do? */
  globalFilterDataOptions?: SelectItem[] | Observable<SelectItem[]>;
  globalFilterMatchMode?: string;
  children?: NestedCol[];
  attachTemplate?: TemplateDatatypeOptions;
  styleObj?: { [_class: string]: any };
  // a style obj used for the cells in the body of this specific column. this style obj is passed to ngStyle.
  // If this predicate is provided, it is run for every row in the formlist. If it returns true, the content of the current row and column
  // will be set to "-". This predicate is used when this column is not applicable for a subset of the rows in the form list.
  classObj?: string;
  readonlyPredicate?: (rowData) => boolean;
  info?: string;
  displayFn?: (rowData: Record<string, any>) => string | number;
}

export interface FormControlConfigurator {
  setupControl(formControl: AbstractControl, formGroup: UntypedFormGroup, col?: Col);
}

export class RowSelectionEvent {
  data: any;
  rowIndex: number;
}

export enum DataType {
  input = "input",
  number = "number",
  checkbox = "checkbox",
  toggle = "toggle",
  lookup = "lookup",
  enum = "enum",
  multiselect = "multiselect",
  enumMultiselect = "enumMultiselect",
  date_date_only = "date_date_only",
  date_date_time = "date_date_time",
  date_time = "date_time",
  autocomplete = "autocomplete",
  country = "country",
  subzone = "subzone",
  selectrow = "selectrow",
  chips = "chips",
  execute_event = "execute_event",
  open_details_link = "open_details_link",
  template = "template",
  array = "array",
}

export enum FilterType {
  none = "none",
  contains = "contains",
  enum = "enum",
  lookup = "lookup",
  multiselect = "multiselect",
  enumMultiselect = "enumMultiselect",
  clear_filters = "clear_filters",
  checkbox = "checkbox",
  compound = "compound", // Used for form arrays
}

export class DefaultColWidth {
  dataType: DataType;
  defaultWidth: number;
}

export enum DefaultColsWidths {
  toggle = 110,
  chips = 300,
  checkbox = 110,
  execute_event = 48,
  date_date_only = 140,
  date_date_time = 180,
  date_time = 140,
  number = 150,
  array = 300,
}

export enum FrozenColsWidths {
  selectrow = "42px !important",
  viewrow = "60px !important",
  settings = "105px !important",
  deleterow = "57px !important",
  clear_state = "60px !important",
  execute_event = "48px !important",
}

export enum DefaultColsFrozen {
  selectrow = "selectrow",
  viewrow = "viewrow",
  settings = "settings",
  deleterow = "deleterow",
  clear_state = "clear_state",
}

export class SettingsColumn {
  caption: string;
  routeLink: string;
  routeQueryParams: any;
}

export enum RowSelectionType {
  NONE,
  SINGLE,
  MULTIPLE,
}

export enum ExecuteEvent_DisplayMode {
  LINK,
  BUTTON,
  SPLITBUTTON,
}

export type ValidityStatusChange = {
  status: FormControlStatus;
  object: any;
  rowIndex: number;
};

export class ExecuteEventDatatypeOptions {
  public displayMode: ExecuteEvent_DisplayMode;
  public label: string | Function;
  public event: EventEmitter<any>;
  public enabledOnEditMode: boolean;
  public icon?: string | Function;
  public iconName?: string;
  public styleClass: string;
  public fnIsRowDisabled?: (rowData: any) => boolean;
  public items?: (rowData: any) => MenuItem[];

  constructor({
    displayMode,
    event,
    label,
    icon,
    iconName,
    enabledOnEditMode = true,
    styleClass,
    fnIsRowDisabled,
    items,
  }: {
    displayMode: ExecuteEvent_DisplayMode;
    label?: string | Function;
    event: EventEmitter<any>;
    icon?: string | Function;
    iconName?: string;
    enabledOnEditMode?: boolean;
    styleClass?: string;
    fnIsRowDisabled?: (rowData: any) => boolean;
    items?: (rowData: any) => MenuItem[];
  }) {
    this.displayMode = displayMode;
    this.label = label;
    this.event = event;
    this.enabledOnEditMode = enabledOnEditMode;
    this.icon = icon;
    this.iconName = iconName;
    if (styleClass === undefined && displayMode === ExecuteEvent_DisplayMode.BUTTON) {
      if (label) {
        styleClass = "p-button-secondary";
      } else {
        styleClass = "p-button-secondary p-button-rounded";
      }
    } else {
      // styleClass = '';
    }

    this.styleClass = styleClass;
    this.fnIsRowDisabled = fnIsRowDisabled;
    this.items = items;
  }
}

export class AutocompleteDatatypeOptions {
  public completeMethod: Function;
  public transformer: AutocompleteTransformer;
  public isMultiple: boolean;

  constructor({
    completeMethod,
    transformer,
    isMultiple = false,
  }: {
    completeMethod: Function;
    transformer: AutocompleteTransformer;
    isMultiple?: boolean;
  }) {
    this.completeMethod = completeMethod;
    this.transformer = transformer;
    this.isMultiple = isMultiple;
  }
}

export class TemplateDatatypeOptions {
  templateRef: TemplateRef<any>;
  data: any;

  constructor({ templateRef, data }: { templateRef: TemplateRef<any>; data?: any }) {
    this.templateRef = templateRef;
    this.data = data;
  }
}

export interface AutocompleteTransformer {
  toAutocompleteItem(object: any): SelectItem;
  fromAutocompleteItem(item: SelectItem): any;
}

export enum FormListExportFormat {
  CSV,
  PDF,
  EXCEL,
}

export class ArrayDatatypeOptions {
  component: Type<ArrayFormComponent>;
  displayField: ((row: any, allRows: any[]) => string | number) | string;
  showSummary: boolean;

  constructor({
    component,
    displayField,
    showSummary,
  }: {
    component: Type<ArrayFormComponent>;
    displayField: ((row: any, allRows: any[]) => string | number) | string;
    showSummary: boolean;
  }) {
    this.component = component;
    this.displayField = displayField;
    this.showSummary = showSummary;
  }
}

export class ChipsDatatypeOptions {
  maxEntries: number;

  constructor({ maxEntries }: { maxEntries?: number }) {
    this.maxEntries = maxEntries;
  }
}

export class FormListFilter {
  [key: string]: { matchMode: string; value: any };
}

// TODO: we shouldn't add custom fields to the PrimeNG storage object since it is overwritten
// internally by the p-table code.
export type FormListStorageState = TableState & {
  saveFirst?: boolean;
  chooserActiveCols?: string[];
};

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
