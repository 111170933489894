/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable, OnDestroy } from "@angular/core";
import { ValidatorFn, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { SelectItem } from "primeng/api";
import { Observable, of, Subscription } from "rxjs";
import { map, tap } from "rxjs/operators";
import {
  Col,
  DataType,
  ExecuteEventDatatypeOptions,
  ExecuteEvent_DisplayMode,
  FilterType,
} from "src/app/form-list/form-list/form-list";
import { FormListModel } from "src/app/form-list/form-list/form-list-model.interface";
import { FormListComponent } from "src/app/form-list/form-list/form-list.component";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { CurrencyFormatterService } from "src/app/shared/services/currency-formatter.service";
import { DBService } from "src/app/shared/services/db.service";
import { StoreTenderTypeList } from "../../../store-settings/store-tender-type-settings/TenderTypesSettings";
import { TakuPaymentGateway } from "./taku-payment-gateway";
import { Tender_Type } from "../../../business-settings/tender-type/tender-type";

@Injectable({
  providedIn: "root",
})
export class TakuPaymentGatewayService extends DBService implements FormListModel, OnDestroy {
  subsList: Subscription[] = [];
  protected lookupStores: SelectItem[];

  _formListComponent: FormListComponent;
  // enumPaymentGateways = this.enumSelectOptions(PaymentGatewayTypes, {useKeyAsLabel: true});
  manageTerminalEvent: EventEmitter<TakuPaymentGateway> = new EventEmitter<TakuPaymentGateway>();

  constructor(
    protected currencyService: CurrencyFormatterService,
    protected router: Router,
    private appSettings: AppSettingsStorageService,
    http: HttpClient,
    authService: AuthService
  ) {
    super(http, authService);

    this.subsList.push(
      this.manageTerminalEvent.subscribe((paymentGateway: TakuPaymentGateway) => {
        this.router.navigate([
          "/integrations/my-apps/taku-payment-gateways/terminals",
          paymentGateway.id,
          paymentGateway.paymentGatewayType,
          paymentGateway.storeId,
          paymentGateway.id,
        ]);
      })
    );
  }

  get_lookup_stores(): Observable<SelectItem[]> {
    return this.lookupSelectOptions("store", "storeName", { enableFieldName: "isActive" }).pipe(
      tap((stores: SelectItem[]) => (this.lookupStores = stores))
    );
  }

  getValidationRules(): { [key: string]: {} | ValidatorFn[] } {
    return {
      storeId: [Validators.required],
    };
  }

  displayName(rowData) {
    const displayName = rowData.takuPaymentAccountId ? `(${rowData.takuPaymentAccountId})` : "";
    return `${rowData.displayName} ${displayName}`;
  }

  getFormListColumns(): Col[] {
    return [
      {
        field: "manageTerminalLink",
        header: "Manage Terminals",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.execute_event,
        dataOptions: [],
        dataTypeOptions: [
          new ExecuteEventDatatypeOptions({
            displayMode: ExecuteEvent_DisplayMode.LINK,
            label: "Terminals",
            event: this.manageTerminalEvent,
            enabledOnEditMode: false,
          }),
        ],
        filterType: FilterType.none,
        colWidth: 90,
      },
      {
        field: "isActive",
        header: "Active",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.checkbox,
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.enum,
      },

      {
        field: "takuPayAccountName",
        header: "TAKU Pay Account Name",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        dataTypeOptions: { prefix: this.displayName.bind(this) },
        filterType: FilterType.contains,
      },
      // {
      //   field: 'paymentGatewayType', header: 'Payment Gateway', visible: true, readonly: false, frozen: false, dataType: DataType.enum,
      //   dataOptions: this.enumPaymentGateways, filterType: FilterType.enum
      // },
      {
        field: "storeId",
        header: "Store Name",
        visible: true,
        readonly: this.appSettings.isAdminLogin() ? false : true,
        frozen: false,
        dataType: DataType.lookup,
        dataOptions: this.get_lookup_stores(),
        filterType: this.appSettings.isAdminLogin() ? FilterType.lookup : FilterType.none,
        sortFields: ["store.storeName"],
      },
      {
        field: "tenderTypes",
        header: "Card Types",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.multiselect,
        dataOptions: this.getLookupMulti_cardTypes(),
        filterType: FilterType.multiselect,
        isNotSortable: true,
      },
      {
        field: "locationId",
        header: "Location Id",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
    ];
  }

  getLookupMulti_cardTypes(): Observable<SelectItem[]> {
    return this.appSettings
      .getStoreSettings("storeTenderTypeList")
      .pipe(
        map((storeTenderTypeList: StoreTenderTypeList) =>
          storeTenderTypeList?.storeTenderTypes
            .filter((tender) => [Tender_Type.Credit_Card, Tender_Type.Debit_Card].includes(tender.tenderType.type))
            .map((tender) => ({ label: tender.tenderType.description, value: tender.tenderType }))
        )
      );
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
