/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit } from "@angular/core";
import { StoreSettingEmail, SettingEmailEncryption } from "../../store-settings/store-mail-settings/StoreSettingEmail";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ToggleCaptionPosition } from "../../../../taku-ui/taku-toggle/taku-toggle.component";
import { GenericFormComponent } from "src/app/forms/generic-form/generic-form.component";
import { distinctUntilChanged, map, switchMap, tap } from "rxjs/operators";
import { Zone } from "../Zone";
import { FilterRows } from "src/app/utility/FormDataHelpers";
import { forkJoin } from "rxjs";
import { ApiListResponse } from "src/app/utility/types";

@Component({
  selector: "taku-zone-email-settings",
  templateUrl: "../../store-settings/store-mail-settings/store-mail-settings.component.html",
  styleUrls: ["../../store-settings/store-mail-settings/store-mail-settings.component.scss"],
})
export class ZoneEmailSettingsComponent extends GenericFormComponent implements OnInit {
  _pageTitlePrefix = "Email Settings";
  pageTitle = this._pageTitlePrefix;

  SettingEmailEncryption = SettingEmailEncryption;
  ToggleCaptionPosition = ToggleCaptionPosition;

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    const tmpModel = new StoreSettingEmail();
    const tmpForm = fb.group(tmpModel);

    return tmpForm;
  }

  ngOnInit(): void {
    this.subsList.push(
      this._route.params
        .pipe(
          map((params) => Number(params["zoneId"])),
          distinctUntilChanged(),
          tap((zoneId) => {
            this._object.zoneId = zoneId;
            this._myForm.patchValue({ zoneId });
          }),
          switchMap((zoneId) =>
            forkJoin([this.dbService.getRow<Zone>("zone", zoneId), this._fetchAndStoreFormObject$(zoneId)])
          )
        )
        .subscribe(([zone, zoneEmailSettings]) => {
          this.pageTitle = `${this._pageTitlePrefix}&nbsp;<span class="secondary-text">(${zone.zoneName})</span>`;
        })
    );
    super.ngOnInit();
  }

  initForm(): void {
    this._model = "storeSettingEmail";
    this._object = new StoreSettingEmail();
    this._myForm = ZoneEmailSettingsComponent.init(this.fb);
  }

  private _fetchAndStoreFormObject$(zoneId: number) {
    const filter: FilterRows<StoreSettingEmail> = {
      zoneId: { value: zoneId, matchMode: "equals" },
    };

    return this.dbService.getRows<ApiListResponse<StoreSettingEmail>>(this._model, JSON.stringify(filter)).pipe(
      tap((myObjects) => {
        if (myObjects.rows.length === 0) {
          this._id = 0;
        } else {
          this._object = myObjects.rows[0];
          this._id = myObjects.rows[0].id;
        }
        super.loadObject();
      })
    );
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
