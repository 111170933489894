/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit } from "@angular/core";
import { NestedFormComponent } from "../../../forms/nested-form/nested-form.component";
import { StoreInstantMsgType, StoreInstantMsg } from "./store-instant-msg";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";

@Component({
  selector: "taku-store-instant-msg",
  templateUrl: "./store-instant-msg.component.html",
  styleUrls: ["./store-instant-msg.component.scss"],
})
export class StoreInstantMsgComponent extends NestedFormComponent {
  // Define enum variables
  // enum_commecrial_phone_type = this.dbService.enumSelectOptions(CommercialAccountPhoneType, 'Type');
  enum_store_instant_msg_type = this.dbService.enumSelectOptions(StoreInstantMsgType);

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    const storeInstantMsg_Group = fb.group(new StoreInstantMsg());
    return storeInstantMsg_Group;
  }

  static setArray(fb: UntypedFormBuilder, storeInstantMsgs: StoreInstantMsg[]) {
    const formArray = fb.array([]);
    storeInstantMsgs.map((storeInstantMsg) => {
      const temp = fb.group(storeInstantMsg);
      formArray.push(temp);
    });
    return formArray;
  }

  initValidation() {
    this._validation = {};
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
