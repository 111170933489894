/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Input } from "@angular/core";
import { WebHelpers } from "src/app/utility/WebHelpers";
import { TakuInputCtrlBase } from "../TakuInputCtrlBase";

@Component({
  selector: "taku-editor",
  templateUrl: "./taku-editor.component.html",
  styleUrls: ["./taku-editor.component.scss"],
})
export class TakuEditorComponent extends TakuInputCtrlBase {
  @Input() _height?: string;
  // @Input() showHyperlinkBtn = false;
  @Input() maxCharLength;
  displayDialog = false;
  ifDisplayDialog = true;
  editorBodyHtmlContent;

  get charLength(): number {
    return this.ctrlField.value ? this.ctrlField.value.length : 0;
  }

  showEditorInDialog() {
    this.updateFormGroup();
    this.displayDialog = true;
    this.ifDisplayDialog = false;
    setTimeout(() => {
      this.ifDisplayDialog = true;
    }, 0);
  }

  updateFormGroup() {
    this.ctrlField.setValue(this.ctrlField.value);
  }

  get isMobile(): boolean {
    // return !WebHelpers.isDesktopScreen()
    return WebHelpers.isMobileScreen();
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
