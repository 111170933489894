/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "formatNegativeNumber",
  standalone: true,
})
export class FormatNegativeNumberPipe implements PipeTransform {
  transform(numericValue: string | number, args?: any): any {
    if (numericValue == null) return "";

    let formattedValue = numericValue + "";

    const number = Number(formattedValue.replace(/[^0-9.-]+/g, ""));
    if (number < 0) {
      formattedValue = formattedValue.replace(/-+/g, ""); // remove negative sign
      formattedValue = `(${formattedValue})`;
    }

    return formattedValue;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
