import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { TakuInputMaskComponent } from "./taku-inputmask.component";
import { InputTextModule } from "primeng/inputtext";
import { KeyFilterModule } from "primeng/keyfilter";

@NgModule({
  declarations: [TakuInputMaskComponent],
  exports: [TakuInputMaskComponent],
  imports: [CommonModule, ReactiveFormsModule, InputTextModule, KeyFilterModule],
})
export class TakuInputMaskModule {}
