/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, MessageService, SelectItem } from "primeng/api";
import { GenericFormComponent } from "../../../forms/generic-form/generic-form.component";
import { AlertMessagesService } from "../../../shared/services/alert-messages.service";
import { PersonComponent } from "../../contact-accounts/person/person.component";
import { User } from "./user";
import { UserService } from "./user.service";
import { AppSettingsStorageService } from "../../../shared/app-settings-storage.service";
import { Observable, concat } from "rxjs";
import { toArray, tap, map } from "rxjs/operators";
import { AuthService } from "src/app/shared/services/auth.service";
import { RolePermissionsComponent } from "../role-permissions/role-permissions.component";
import { DialogService } from "primeng/dynamicdialog";

class OwnershipForm {
  ownerUserId: number;

  constructor() {
    this.ownerUserId = null;
  }
}

@Component({
  selector: "taku-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.scss"],
})
export class UserComponent extends GenericFormComponent implements OnInit {
  lookup_users$: Observable<SelectItem[]>;
  ownershipForm: UntypedFormGroup;
  reAssignOwnershipStep = 0;
  _sendingEmail = false;

  static init(fb): UntypedFormGroup {
    const tmpForm = fb.group(new User());
    tmpForm.setControl("person", PersonComponent.init(fb));
    return tmpForm;
  }

  constructor(
    protected _router: Router,
    public fb: UntypedFormBuilder,
    public dbService: UserService,
    protected location: Location,
    public _route: ActivatedRoute,
    protected messageService: MessageService,
    protected alertMessage: AlertMessagesService,
    protected confirmationService: ConfirmationService,
    public auth: AuthService,
    appSettings: AppSettingsStorageService,
    private dialogService: DialogService
  ) {
    super(_router, fb, dbService, location, _route, messageService, alertMessage, confirmationService, appSettings);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subsList.push(
      this.changedForm.subscribe((user: User) => {
        if (user.id === this.appSettingsService.getUserId()) this.appSettingsService.updateUser(user);
      })
    );

    this.lookup_users$ = this.dbService.getLookup_users(false).pipe(
      map((items: SelectItem[]) => items.filter((item) => item.value !== this._object.id)) // Exclude current user
    );

    this.ownershipForm = this.fb.group(new OwnershipForm());
    this.subsList.push(
      this.ownerUserCtrl.valueChanges.subscribe((newOwnerId) => {
        this.reAssignOwnership(newOwnerId);
      })
    );
  }

  initLookups() {}

  initForm() {
    this._model = "user";
    this._object = new User();
    this._myForm = UserComponent.init(this.fb);
  }

  setFormArrays() {
    this._myForm.setControl("person", PersonComponent.set(this.fb, this._object.person));
  }

  initValidation() {
    this._validation = this.dbService.getValidationRules();
  }

  get ownerUserCtrl() {
    return this.ownershipForm.get("ownerUserId");
  }

  get isOwnerCtrl() {
    return this._myForm.get("isOwner");
  }

  get loggedInUser(): User {
    return this.appSettingsService.getUser();
  }

  reAssignOwnership(newOwnerId: number) {
    this.confirmationService.confirm({
      header: "Confirmation",
      message:
        "Re-assigning ownership of this TAKU account will remove your access to owner-only functions. This action cannot be reversed.",
      acceptLabel: "RE-ASSIGN",
      rejectLabel: "CANCEL",
      rejectButtonStyleClass: "p-button-link",
      reject: () => {
        this.ownerUserCtrl.setValue(null, { emitEvent: false });
      },
      accept: () => {
        this.subsList.push(
          this.changeOwnership(newOwnerId).subscribe({
            next: ([removeResult, currUserResponse]) => {
              // Update _object data according to current user new ownership
              const isCurrentUserOwner = this._object.id === newOwnerId;
              this._object.isOwner = isCurrentUserOwner;
              if (this._object.id === this.appSettingsService.getUserId())
                this.appSettingsService.updateUser(this._object);
              this.isOwnerCtrl.setValue(isCurrentUserOwner, { emitEvent: false });

              this.messageService.add(this.alertMessage.getMessage("user-ownership-reassignation-success"));
            },
            error: (error) => {
              this.messageService.add(this.alertMessage.getMessage("user-ownership-reassignation-error"));
            },
            complete: () => {
              this.ownerUserCtrl.setValue(null, { emitEvent: false });
              this.reAssignOwnershipStep = 0;
            },
          })
        );
      },
    });
  }

  private changeOwnership(newOwnerId: number): Observable<any[]> {
    const removeOwernshipReq = this.dbService.patchRows(
      "user",
      {
        isOwner: false,
      },
      JSON.stringify({
        id: {
          matchMode: "ne",
          value: newOwnerId,
        },
      })
    );

    const setOwnershipReq = this.dbService.patchRow("user", {
      id: newOwnerId,
      isOwner: true,
    });

    return concat(removeOwernshipReq, setOwnershipReq).pipe(
      toArray(),
      tap((responses) => {})
    );
  }

  changeReAssignOwnerShipStep() {
    // there are only 2 steps (unidirectional)
    if (this.reAssignOwnershipStep === 0) this.reAssignOwnershipStep = 1;
  }

  assignedRoles() {
    this.subsList.push(
      this.dbService.getRow("user", this._object.id).subscribe((userData) => {
        this.dialogService.open(RolePermissionsComponent, {
          data: {
            user: userData,
          },
          closable: false,
          styleClass: "rolePermission-dialog-style",
          height: "600px",
          contentStyle: {
            height: "calc(100% - 40px)",
            overflow: "auto",
          },
        });
      })
    );
  }

  onSave(): void {
    // if the isActive value has been changed in the user details page
    if (this._myForm.value.isActive !== this._object.isActive) {
      // allow saving IFF appsettings.userid !== _myForm.userid
      if (this._object.id !== this.appSettingsService.getUserId()) {
        super.onSave();
      } else {
        alert("User cannot change their own active status!");
      }
    } else {
      super.onSave();
    }
  }

  sendPasswordReset(): void {
    this._sendingEmail = true;
    const _accountName = this.appSettingsService.getAccountName();
    this.subsList.push(
      this.auth.forgotPassowrd(_accountName, this._myForm.get("userName").value).subscribe(
        () => {
          this.messageService.add(this.alertMessage.getMessage("email-sent-success"));
          this._sendingEmail = false;
        },
        (error) => {
          this.messageService.add(this.alertMessage.getErrorMessage(error, "", ""));
          this._sendingEmail = false;
        }
      )
    );
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
