/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { UnsavedChangesGuard } from "src/app/app-routing/UnsavedChanges.guard";
import { DeliveryStatus, FulfillmentStatus, PaymentStatus, SaleDoc } from "../sale-doc/sale-doc";
import { Observable, Subscription, forkJoin, from, of } from "rxjs";
import { Message, MessageService } from "primeng/api";
import { SaleDocTender } from "../sale-doc-tender/sale-doc-tender";
import { Doc, DocState, SaleDocType } from "../../doc/doc";
import { FilterRows, FormDataHelpers } from "src/app/utility/FormDataHelpers";
import {
  PaymentTerminal,
  ResponseTransaction,
} from "src/app/core/settings/integration-settings/gateway-terminals/payment-terminal";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { MonerisService } from "src/app/core/settings/integration-settings/moneris-terminal/moneris.service";
import { HttpErrorResponse } from "@angular/common/http";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";
import { PrintingFactoryService, PrintingMgr } from "src/app/shared/services/printing-service.service";
import { DBService } from "src/app/shared/services/db.service";
import { catchError, concatAll, finalize, map, switchMap, tap } from "rxjs/operators";
import { ApiListResponse } from "src/app/utility/types";
import { cloneDeep, pick } from "lodash";
import {
  TakuPayCommandName,
  TakuPayMethodType,
  TakuPaymentStatus,
} from "src/app/core/settings/integration-settings/taku-pay/taku-payment-gateway-terminals/taku-payment-terminal";
import { TakuPayService } from "src/app/core/settings/integration-settings/taku-pay/taku-pay.service";

@Component({
  selector: "taku-saledoc-void-dialog",
  templateUrl: "./saledoc-void-dialog.component.html",
  styleUrls: ["./saledoc-void-dialog.component.scss"],
})
export class SaledocVoidDialogComponent implements OnInit, OnDestroy {
  private subsList: Subscription[] = [];
  private saleDoc: SaleDoc;
  private printMgr: PrintingMgr;
  @ViewChild("printPreviewHost", { read: ViewContainerRef, static: true }) printPreviewHost: ViewContainerRef;

  unrevertedTenders: SaleDocTender[] = [];
  _voidForm = this.fb.group({
    voidReason: this.fb.control<string>(null, Validators.required),
  });
  _headerTitle: string;
  inProgress = false;

  constructor(
    dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private fb: FormBuilder,
    private saveGuard: UnsavedChangesGuard,
    private messageService: MessageService,
    private appSettingsService: AppSettingsStorageService,
    private dbService: DBService,
    private alertMessagesService: AlertMessagesService,
    private monerisService: MonerisService,
    private takuPayService: TakuPayService,
    printingMgrFactory: PrintingFactoryService
  ) {
    this.saleDoc = dialogConfig.data.saleDoc;
    this.printMgr = printingMgrFactory.build(dialogConfig.data.saleDoc.storeId);
  }

  ngOnInit(): void {
    this._headerTitle = `<span>DOC# ${this.saleDoc.doc.docNo} -&nbsp;</span><span>Void Document</span>`;
    this.unrevertedTenders = this.getUnrevertedTenders();
  }

  private getUnrevertedTenders() {
    const unrevertedTenders: SaleDocTender[] = [];
    // This is how the salesscreen reverts tenders, by maintaining their order next to each other
    for (let i = 0; i < this.saleDoc.saleDocTenders.length; i++) {
      if (
        this.saleDoc.saleDocTenders[i + 1] &&
        this.saleDoc.saleDocTenders[i + 1].isReturned &&
        Number(this.saleDoc.saleDocTenders[i].amount) === -1 * Number(this.saleDoc.saleDocTenders[i + 1].amount)
      ) {
        // Skip adding reverted pairs to the displayed list
        i++;
        continue;
      } else {
        unrevertedTenders.push(this.saleDoc.saleDocTenders[i]);
      }
    }
    // Remove reverted tenders that are not ordered next to each other, which might happen if voiding fails and only some tenders are reverted
    const revertedTenderIds = new Set<number>();
    const revertedTenders = unrevertedTenders.filter((t) => !!t.refNo && !isNaN(Number(t.refNo)));
    unrevertedTenders.forEach((tenderA) => {
      const revertedMatch = revertedTenders.find(
        (tenderB) =>
          tenderA.id === Number(tenderB.refNo) &&
          Number(tenderA.amount) === -1 * Number(tenderB.amount) &&
          (tenderA.isReturned || tenderB.isReturned)
      );
      if (revertedMatch) {
        revertedTenderIds.add(revertedMatch.id);
        revertedTenderIds.add(tenderA.id);
      }
    });
    return unrevertedTenders.filter((t) => !revertedTenderIds.has(t.id));
  }

  onVoidPressed(): void {
    if (this.saleDoc.cashout?.isClosed) {
      this.messageService.add({
        summary: "Cannot void",
        detail:
          "This transaction cannot be voided because it has already been cashed out. " +
          "To reverse this transaction, please use the Return function.",
        severity: "warn",
      });
      return this.dialogRef.close({ success: false });
    }

    this.inProgress = true;

    let tenderRequests = [of([])] as Observable<SaleDocTender[] | { error: Message }>[];
    if (this.unrevertedTenders.length) {
      /** Since moneris refunds have to go to the terminal, if there are multiple, they must be handled consecutively */
      const monerisRequests = [] as Observable<SaleDocTender[] | { error: Message }>[];
      tenderRequests = [];

      this.unrevertedTenders.forEach((tender) => {
        if (tender.refTransaction) {
          if (tender.refTransaction.PaymentId) {
            tenderRequests.push(this.voidTakuPayTender(tender));
          } else {
            monerisRequests.push(this.voidMonerisTender(tender));
          }
        } else {
          tenderRequests.push(this.voidNonIntegratedTender(tender));
        }
      });
      // Moneris requests are concatenated into a single observable, run in parallel with other void requests
      if (monerisRequests.length) {
        tenderRequests.push(from(monerisRequests).pipe(concatAll()));
      }
    }

    forkJoin(tenderRequests)
      .pipe(
        switchMap((voidedRequestResponses) => {
          const failedVoidRequests = voidedRequestResponses.filter((r) => !!r["error"]) as { error: Message }[];
          if (failedVoidRequests.length) {
            this.messageService.add({
              severity: "error",
              summary: "Voiding failed",
              detail: `Failed to revert ${failedVoidRequests.length} payment(s)`,
              life: 20000,
            });
            failedVoidRequests.forEach((r) => {
              r.error.life = 20000;
              this.messageService.add(r.error);
            });

            this.dialogRef.close({
              success: false,
            });
            return of();
          }
          return this.voidSaleDocRequests(this.saleDoc, this._voidForm.controls.voidReason.value).pipe(
            tap(() =>
              this.dialogRef.close({
                success: true,
                voidReason: this._voidForm.controls.voidReason.value,
              })
            )
          );
        }),
        finalize(() => (this.inProgress = false))
      )
      .subscribe();
  }

  onClosePressed(): void {
    this.subsList.push(
      this.saveGuard.checkForm(this._voidForm.pristine).subscribe((canClose) => {
        if (canClose)
          this.dialogRef.close({
            success: false,
          });
      })
    );
  }

  private voidSaleDocRequests(saleDoc: SaleDoc, voidReason?: string) {
    saleDoc.paymentStatus = PaymentStatus.voided;
    saleDoc.fulfillmentStatus = FulfillmentStatus.voided;
    saleDoc.deliveryStatus = DeliveryStatus.voided;
    saleDoc.doc.state = DocState.voided;
    if (voidReason) {
      saleDoc.doc.voidReason = voidReason;
    }

    return forkJoin([
      this.dbService.patchRow<Partial<SaleDoc>>(
        "saleDoc",
        pick(saleDoc, ["id", "fulfillmentStatus", "paymentStatus", "deliveryStatus"])
      ),
      this.dbService.patchRow<Partial<Doc>>("doc", pick(saleDoc.doc, ["id", "state", "voidReason"])),
    ]);
  }

  private voidNonIntegratedTender(saleDocTender: SaleDocTender): Observable<SaleDocTender[]> {
    const voidedSaleDocTender = this.createVoidTender(saleDocTender);

    return this.dbService.addRow("saleDocTender", voidedSaleDocTender).pipe(map((res) => [res]));
  }

  private voidTakuPayTender(saleDocTender: SaleDocTender): Observable<SaleDocTender[] | { error: Message }> {
    const command =
      saleDocTender.refTransaction?.CardType.toUpperCase() === TakuPayMethodType.INTERAC.toUpperCase()
        ? TakuPayCommandName.COMMAND_REFUND
        : TakuPayCommandName.COMMAND_CANCEL;
    const apiBody = {
      amount: saleDocTender.amount,
      invoiceNumber: this.saleDoc.id,
      payment_id: saleDocTender.refTransaction?.PaymentId,
    };
    return this.takuPayService.runTransaction(command, this.saleDoc.storeId, apiBody).pipe(
      switchMap((result) => {
        if (
          !result ||
          ![TakuPaymentStatus.STATUS_CANCEL_REQUESTED, TakuPaymentStatus.STATUS_REFUND_REQUESTED].includes(
            result.status
          )
        ) {
          return of({
            error: {
              summary: "Error",
              severity: "error",
              detail: `Transaction error. Status: ${result.status}`,
            },
          });
        }

        this.messageService.add({
          summary: "Success",
          severity: "success",
          detail: "TakuPay transaction successfully voided",
          life: 20000,
        });

        const responseTransaction: ResponseTransaction = new ResponseTransaction();

        responseTransaction.TransType =
          result["status"] === TakuPaymentStatus.STATUS_REFUND_REQUESTED
            ? TakuPayCommandName.COMMAND_REFUND
            : TakuPayCommandName.COMMAND_CANCEL;
        responseTransaction.TerminalID = saleDocTender.takuPaymentTerminal?.terminalId;
        responseTransaction.PaymentId = result["payment_id"];
        responseTransaction.PaymentMethod = result["payment_method_id"];
        responseTransaction.Amount = result["amount"];

        responseTransaction.PaymentType = result["payment"]?.["type"];
        responseTransaction.CardType = result["payment"]?.["brand"];
        responseTransaction.Last4 = result["payment"]?.["last_4"];
        responseTransaction.Name = result["payment"]?.["cardholder_name"];
        responseTransaction.ExpDate = `${result["payment"]?.["exp_month"]}/${result["payment"]?.["exp_year"]}`;

        responseTransaction.AuthCode =
          result["payment"]?.["receipt_data"]?.["emv_auth_code"] ?? result.authorization_code;
        responseTransaction.RefID = result["payment_method_id"]
          ? result["payment_method_id"]
          : result["payment"]?.["receipt_data"]?.["emv_reference_number"];
        responseTransaction.TerminalID = result["payment"]?.["receipt_data"]?.["emv_term_id"];
        responseTransaction.EMV_App_Label = result["payment"]?.["receipt_data"]?.["emv_app_label"];
        responseTransaction.EMV_App_Name = result["payment"]?.["receipt_data"]?.["emv_app_id"];
        responseTransaction.Cryptogram = result["payment"]?.["receipt_data"]?.["emv_cryptogram"];
        responseTransaction.EntryMethod = result["payment"]?.["receipt_data"]?.["card_entry_mode"];
        responseTransaction["customerReceiptTemplate"] = this.takuPayService.createPaymentReceipt(
          responseTransaction,
          false
        );
        responseTransaction["merchantReceiptTemplate"] = this.takuPayService.createPaymentReceipt(
          responseTransaction,
          true
        );

        saleDocTender.refTransaction["VoidAuthCode"] = responseTransaction.AuthCode;

        const voidedSaleDocTender = this.createVoidTender(saleDocTender);
        voidedSaleDocTender.refTransaction = responseTransaction;

        return forkJoin([
          this.dbService.patchRow("saleDocTender", pick(saleDocTender, ["id", "refTransaction"])),
          this.dbService.addRow("saleDocTender", voidedSaleDocTender),
        ]).pipe(
          tap(() => {
            if (voidedSaleDocTender.refTransaction?.["customerReceiptTemplate"]) {
              this.takuPayService.printPaymentReceipt(
                this.printMgr,
                voidedSaleDocTender,
                this.saleDoc,
                this.printPreviewHost
              );
            }
          })
        );
      }),
      catchError(this.handleRequestError.bind(this))
    );
  }

  private voidMonerisTender(saleDocTender: SaleDocTender): Observable<{ error: Message } | SaleDocTender[]> {
    if (!this.appSettingsService.getStationId()) {
      // Moneris cannot refund/void without card present
      return of({
        error: {
          severity: "warn",
          summary: "Cannot void payment",
          detail: "Moneris transactions must be returned to a physical card terminal, not in admin mode",
        },
      });
    }
    const filter: FilterRows<PaymentTerminal> = {
      isActive: { value: true, matchMode: "equals" },
      stationId: { value: this.appSettingsService.getStationId(), matchMode: "equals" },
    };

    return this.dbService
      .getRows<ApiListResponse<PaymentTerminal>>("paymentTerminal", JSON.stringify(filter), 0, -1)
      .pipe(
        map((response) => response.rows),
        switchMap((_paymentTerminal) => {
          if (!_paymentTerminal.length) {
            return of({
              error: {
                severity: "warn",
                summary: "Cannot void payment",
                detail:
                  "Moneris transactions must be returned to a physical card terminal, none are associated to this station",
              },
            });
          }
          const apiBody = {
            amount: saleDocTender.amount,
            invoiceNumber: this.saleDoc.id,
            AuthCode: saleDocTender.refTransaction.AuthCode,
          };
          const command =
            this.saleDoc.doc.docType === SaleDocType.sales_return ? "refundCorrection" : "purchaseCorrection";
          return this.monerisService.runTransaction(command, _paymentTerminal[0].id, apiBody);
        }),
        switchMap((response) => {
          if (response?.error?.severity) {
            // return error from previous switchMap
            return of(response as { error: Message });
          }
          if (!response || response.Error !== "false" || !response.ResponseCode) {
            return of({
              error: {
                summary: "Error",
                severity: "error",
                detail: this.monerisService.getResponseErrorCode(response["ErrorCode"]),
                life: 8000,
              },
            });
          }

          if (Number(response["ResponseCode"]) >= 50) {
            return of({
              error: {
                summary: "Declined",
                severity: "error",
                detail: "Transaction Declined.",
              },
            });
          }
          this.messageService.add({
            summary: "Success",
            severity: "success",
            detail: "Moneris transaction successfully voided",
            life: 20000,
          });

          const responseTransaction: ResponseTransaction = new ResponseTransaction();

          responseTransaction.AuthCode = response["AuthCode"];
          responseTransaction.CardType = response["CardName"];
          responseTransaction.Last4 = response["Pan"];
          responseTransaction.RefID = response["ReferenceNumber"];
          responseTransaction["customerReceiptTemplate"] = response["customerReceiptTemplate"];
          responseTransaction["merchantReceiptTemplate"] = response["merchantReceiptTemplate"];

          // Saving object
          saleDocTender.refTransaction.VoidAuthCode = response.AuthCode;

          const voidedSaleDocTender = this.createVoidTender(saleDocTender);
          voidedSaleDocTender.refTransaction = responseTransaction;

          if (response["PartialAuthAmount"]) {
            voidedSaleDocTender.amount = Number(response["PartialAuthAmount"]);
          }

          return forkJoin([
            this.dbService.patchRow("saleDocTender", saleDocTender),
            this.dbService.addRow("saleDocTender", voidedSaleDocTender),
          ]).pipe(
            tap(() => {
              if (voidedSaleDocTender.refTransaction?.["customerReceiptTemplate"]) {
                this.monerisService.printPaymentReceipt(
                  this.printMgr,
                  voidedSaleDocTender,
                  this.saleDoc,
                  this.printPreviewHost
                );
              }
            })
          );
        }),
        catchError(this.handleRequestError.bind(this))
      );
  }

  private createVoidTender(saleDocTender: SaleDocTender): SaleDocTender {
    const voidedSaleDocTender = cloneDeep(saleDocTender);

    FormDataHelpers.clearModelIDs(voidedSaleDocTender);
    voidedSaleDocTender.createdAt = undefined;
    voidedSaleDocTender.updatedAt = undefined;
    voidedSaleDocTender.refNo = String(saleDocTender.id);
    voidedSaleDocTender.amount = -1 * Number(saleDocTender.amount); // Make it negative for reversion
    voidedSaleDocTender.isReturned = true;

    return voidedSaleDocTender;
  }

  private handleRequestError(errorResponse) {
    if (errorResponse instanceof HttpErrorResponse && errorResponse.status !== 500) {
      // This case net::ERR_FAILED
      if (errorResponse.status == 0 && errorResponse.statusText === "Unknown Error") {
        return of({
          error: this.alertMessagesService.getErrorMessage(
            null,
            "ERROR",
            `${errorResponse.statusText}\nPlease make another VERIFICATION REQUEST.`
          ),
        });
      }

      if (errorResponse.error.error?.message) {
        const errorMsg = <string>errorResponse.error.message;
        return of({
          error: this.alertMessagesService.getErrorMessage(
            null,
            "ERROR",
            `${errorMsg}\nPlease make another VERIFICATION REQUEST.`
          ),
        });
      }
    }
    return of({ error: this.alertMessagesService.getErrorMessage(errorResponse) });
  }

  ngOnDestroy(): void {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
