/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Message } from "primeng/api";
import * as _ from "lodash";
import { GMBHelpers } from "src/app/utility/GMBHelpers";

export type ServerErrorInfo = {
  success: boolean;
  message: string;
  customMessage: string;
  errors: any[];
};

@Injectable({
  providedIn: "root",
})
export class AlertMessagesService {
  private parseErrorMessage(
    err: HttpErrorResponse,
    fnErrorParser: (any) => string,
    summary?: string,
    detail?: string,
    severity?,
    life = 8000
  ) {
    let newSummary = "";
    let newDetail = "";

    if (err && (err instanceof HttpErrorResponse || err["customMessage"] || err["errors"] || err["message"])) {
      newSummary = `Error message`;
      const errorResp: ServerErrorInfo = err.error ? err.error : err;
      if (errorResp.customMessage) {
        newDetail = errorResp.customMessage;
      } else if (errorResp.errors) {
        newDetail = fnErrorParser(errorResp.errors);
      } else {
        newDetail = errorResp.message;
      }
    } else {
      newSummary = "Unknown Error";
      newDetail = _.toString(err);
    }

    newSummary = summary || newSummary;
    newDetail = detail || newDetail;

    return { severity, summary: newSummary, detail: newDetail, life };
  }

  getErrorMessage(err: HttpErrorResponse, summary?: string, detail?: string, severity = "error", life = 3000): Message {
    const fnParseErrors = (errors) => {
      if (!Array.isArray(errors)) errors = [errors];
      return errors
        .map((error) => error.message)
        .filter(Boolean)
        .join("<br>");
    };
    return this.parseErrorMessage(err, fnParseErrors, summary, detail, severity, life);
  }

  getGMB_errorMessage(
    err: HttpErrorResponse,
    summary?: string,
    detail?: string,
    severity = "error",
    life = 6000
  ): Message {
    return this.parseErrorMessage(err, GMBHelpers.ParseErrorMsgToUserMsg, summary, detail, severity, life);
  }

  getMessage(messageType: string, recordName = "Record"): Message {
    let _message: string;
    let _severity: string;
    let _summary: string;

    switch (messageType) {
      case "reset-defaults-success": {
        _message = "Defaults applied successfully!";
        _severity = "success";
        break;
      }
      case "delete-success": {
        _message = `${recordName} deleted successfully!`;
        _severity = "success";
        break;
      }
      case "multi-edit-success": {
        _message = `All records saved successfully!</br>
        To see updated rows, clear and apply the filter again.`;
        _severity = "success";
        break;
      }
      case "discard-all-success": {
        _message = "All records discarded successfully!";
        _severity = "warn";
        break;
      }
      case "save-success": {
        _message = `${recordName} saved successfully!`;
        _severity = "success";
        break;
      }
      case "finalize-success": {
        _message = `${recordName} is finalized successfully`;
        _severity = "success";
        break;
      }
      case "email-sent-success": {
        _message = `Email has been sent successfully!`;
        _severity = "success";
        break;
      }

      case "gmb-location-create": {
        _message = `GMB Location has been successfully created!`;
        _severity = "success";
        break;
      }

      case "gmb-location-update": {
        _message = `GMB Location has been successfully updated!`;
        _severity = "success";
        break;
      }

      case "gmb-location-verification-create": {
        _message = `Verification request has been successfully created!`;
        _severity = "success";
        break;
      }

      case "gmb-location-verificationpin-success": {
        _message = `Verification PIN has been successfully confirmed!.\nYour GMB Location is now verified.`;
        _severity = "success";
        break;
      }

      case "gmb-location-verificationpin-fail": {
        _message = `Verification PIN is incorrect.\nPlease try again with a different PIN.`;
        _severity = "error";
        break;
      }

      case "gmc-gmb-connect-request-pending": {
        _message = `Connection request to your GMB Account has been sent successfully!.\nPlease check your email inbox and confirm request.`;
        _severity = "success";
        break;
      }

      case "gmc-gmb-connect-request-success": {
        _message = `Your GMB Account has been successfully verified with your Google Merchant Center!.`;
        _severity = "success";
        break;
      }

      case "gmc-gmb-connect-request-error": {
        _message = `Request to verify your GMB Account couldn't be sent at this time. Please try again.`;
        _severity = "error";
        break;
      }

      case "gmc-gmb-link-locations-success": {
        _message = `Your GMB Locations has been successfully linked to your Google Merchant Center!.`;
        _severity = "success";
        break;
      }

      case "gmc-gmb-link-locations-error": {
        _message = `Request to link your GMB Locations couldn't be sent at this time. Please try again.`;
        _severity = "error";
        break;
      }

      case "gmc-lia-inventorycontact-success": {
        _message = `Inventory Contact Information has been sent successfully!.`;
        _severity = "success";
      }

      case "gmc-lia-inventorycontact-error": {
        _message = `Inventory Contact Information couldn't be updated!.\nPlease try again`;
        _severity = "error";
      }

      case "google-insert-product": {
        _message = `Product sent to your Google Merchant account successfully!`;
        _severity = "success";
        break;
      }

      case "returns-item-moved": {
        _message = `Item '${recordName}' has been moved to the salesscreen`;
        _severity = "success";
        break;
      }

      case "user-ownership-reassignation-success": {
        _message = `Ownership successfully re-assigned`;
        _severity = "success";
        break;
      }

      case "user-ownership-reassignation-error": {
        _message = `An error ocurred when assigning ownership to the current user.`;
        _severity = "error";
        break;
      }

      case "invalid-formlist-records": {
        _message = `To save, you will need to edit or add to the highlighted fields.`;
        _severity = "error";
        break;
      }

      case "econduit-pair-terminal-success": {
        _message = `Terminal paired successfully.`;
        _severity = "success";
        break;
      }

      case "econduit-unpair-terminal-success": {
        _message = `Terminal unpaired successfully.`;
        _severity = "success";
        break;
      }

      case "econduit-close-batch-success": {
        _message = `Close batch successfully.`;
        _severity = "success";
        break;
      }

      case "econduit-close-batch-need-pair-terminal": {
        _message = `To close batch, you need to pair terminal first.`;
        _severity = "error";
        break;
      }

      case "moneris-close-batch-successful": {
        _message = "Batch Close was completed and is in balance.";
        _severity = "success";
        break;
      }

      case "moneris-close-batch-out-of-balance": {
        _message = "Batch Close was completed and is in balance.";
        _severity = "success";
        break;
      }

      case "sync-roles-success": {
        _message = `Roles and access rights were synced successfully.`;
        _severity = "success";
        break;
      }

      case "max-upload-size-warning": {
        _message = `Maximum upload size of 2MB per file`;
        _severity = "warn";
        _summary = "Warning";
        break;
      }

      case "kiosk-password-fail": {
        _message = `Password is incorrect.`;
        _severity = "error";
        break;
      }

      case "kiosk-password-success": {
        _message = `Logged out of Kiosk Self-checkout successfully.`;
        _severity = "success";
        break;
      }

      case "taku-payment-account-delete-failure": {
        _message = "There is already a TAKU Payment Gateway assigned to this account, and you cannot delete it.";
        _severity = "error";
        break;
      }

      case "taku-payment-account-permission-failure": {
        _message = "Please login with super admin to delete TAKU Accounts.";
        _severity = "error";
        break;
      }

      case "taku-payment-gateway-success": {
        _message = "The TAKU Pay location created successfully!";
        _severity = "success";
        break;
      }

      case "taku-payment-gateway-failure": {
        _message = "The TAKU Pay location was not created. Please select correct account and try again!";
        _severity = "error";
        break;
      }

      case "taku-payment-gateway-delete-failure": {
        _message = "The TAKU Pay location was not deleted. Please remove terminal before deleting location.";
        _severity = "error";
        break;
      }

      // case "stock-receiving-without-purchase-order-warning": {
      //   _summary = "Notification";
      //   _message = "Receive excess stock quantity.";
      //   _severity = "warn";
      //   break;
      // }

      // case 'pattern': return 'The input value does not match the pattern required!';
      // case 'email': return 'Invalid email!';
      // case 'minlength': return 'Minimum length is ' + error.requiredLength + '!';
      // case 'maxlength': return 'Maximum length is ' + error.requiredLength + '!';
      // case 'minNumber': return 'Minimal value is ' + error.requiredRange + '!';
      // case 'maxNumber': return 'Maximal value is ' + error.requiredRange + '!';
      // case 'noEmpty': return 'Only blank spaces are not allowed!';
      // case 'rangeLength': return 'The input must be between ' + error.rangeMin + ' and ' + error.rangeMax + ' symbols long!';
      // case 'range': return 'The input must be between ' + error.rangeMin + ' and ' + error.rangeMax + '!';
      // case 'digit': return 'The input must be a number!';
      // case 'equal': return 'The input must be equal to ' + error.comparer + '!';
      // case 'url': return 'The input must be a valid URL!';
      // case 'date': return 'The input must be a valid date!';
      // case 'areEqual': return 'The values in the group must match!';
      // case 'passwords': return 'Both fields "Password" and "Confirm Password" must match!';
      // case 'unknownError': return 'Unknown Error!';
    }
    return { severity: _severity, summary: _summary || "Service Message", detail: _message };
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
