import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { SearchResultItem } from "src/app/taku-ui/taku-search-accounts/SearchResultItem";
import { Inventory, InventoryStock, InventoryVariant } from "../inventory/inventory";
import { InventoryVariantSettingLine } from "../inventory-variant-setting-line/inventory-variant-setting-line";
import { Col, RowSelectionType, TemplateDatatypeOptions } from "src/app/form-list/form-list/form-list";
import { InventoryVariantSettingService } from "./inventory-variant-setting.service";
import { InventoryVariantSettingLocalDataService } from "./inventory-variant-setting-local-data.service";
import { Observable, Subscription } from "rxjs";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";
import { FormListComponent } from "src/app/form-list/form-list/form-list.component";
import { InventoryComponent } from "../inventory/inventory.component";
import { NestedFormComponent } from "src/app/forms/nested-form/nested-form.component";
import { InventoryService } from "../inventory/inventory.service";
import { CommonValidators } from "src/app/shared/validators/CommonValidators";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { InventoryLabelSettingsComponent } from "../inventory-label-settings/inventory-label-settings.component";
import { ACTION_TYPE } from "src/app/shared/components/action-button/action-type.enum";
import { ChannelType } from "../../sale-channel/sale-channel";

@Component({
  selector: "taku-inventory-variant-setting",
  templateUrl: "./inventory-variant-setting.component.html",
  styleUrls: ["./inventory-variant-setting.component.scss"],
  providers: [InventoryVariantSettingLocalDataService],
})
export class InventoryVariantSettingComponent extends NestedFormComponent implements OnInit, OnDestroy, AfterViewInit {
  public ACTION_TYPE: typeof ACTION_TYPE = ACTION_TYPE;
  subsList: Subscription[] = [];
  _myForm: UntypedFormGroup;
  _inventoryComponent: InventoryComponent;
  RowSelectionType = RowSelectionType;
  _isFetchingData = false;
  _isFormListActive = true;
  inventoryVariantColumns: Col[] = [];
  _searchFilter = {
    isVariantParent: { value: false, matchMode: "equals" },
    parentInventoryId: { value: null, matchMode: "equals" },
  };
  _formListRules: {};
  _inventorySeachQueryParams = { includes: "inventorySaleChannel,inventoryStore" };

  @ViewChild("formListInventoryVariantSetting") formListComponent: FormListComponent;
  @ViewChild("unlinkTpl", { static: true }) unlinkTpl: TemplateRef<any>;

  constructor(
    private dialogRef: DynamicDialogRef,
    public inventoryVariantSettingLocalDataService: InventoryVariantSettingLocalDataService,
    private dialogConfig: DynamicDialogConfig,
    protected confirmationService: ConfirmationService,
    public inventoryVariantSettingService: InventoryVariantSettingService,
    public fb: UntypedFormBuilder,
    public messageService: MessageService,
    private alertMessagesService: AlertMessagesService,
    public dbService: InventoryService,
    protected appSettings: AppSettingsStorageService,
    public dialogService: DialogService
  ) {
    super(dbService, fb);
  }

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    const tempForm = fb.group(new InventoryVariantSettingLine());
    return tempForm;
  }

  static initVariants(fb: UntypedFormBuilder): UntypedFormGroup {
    return fb.group(new InventoryVariant());
  }

  static setArray(fb: UntypedFormBuilder, inventoryVariantSettingLines: InventoryVariantSettingLine[]) {
    const formArray = fb.array([]);
    inventoryVariantSettingLines.map((inventoryVariantSettingLine) => {
      const temp = fb.group(inventoryVariantSettingLine);
      temp.get("inventoryOptionsSettingValues").setValue(inventoryVariantSettingLine.inventoryOptionsSettingValues);
      formArray.push(temp);
    });
    return formArray;
  }

  initValidation() {
    this._validation = {
      inventoryVariantSettings: {
        inventoryOptionsSettingId: [Validators.required, CommonValidators.lookup_singleSelect()],
        inventoryOptionsSettingValues: [Validators.required, CommonValidators.lookup_multiSelect()],
      },
    };
  }
  deleteRows(rowsToDelete: Inventory[]): Observable<any> {
    return new Observable((observer) => {
      rowsToDelete = rowsToDelete.filter((row) => row.id <= 0);
      rowsToDelete.map((row) => (row.id = 0));
      observer.next(rowsToDelete);
      observer.complete();
    });
  }

  onPrintLabel() {
    this.dialogService.open(InventoryLabelSettingsComponent, {
      styleClass: "inventory-lable-settings fullscreen-mobilemodal",
      contentStyle: {
        overflow: "auto",
        width: "85vw",
        maxWidth: "800pt",
        minWidth: "620pt",
        padding: "7px",
      },
      data: {
        inventoriesArray: this.formListComponent._selectedObjects,
      },
    });
  }

  clearAll() {
    // this._myForm.get('inventoryVariantSetting').setValue([]);
    // this._myForm.markAsDirty();
    this.updateFormList();
  }

  syncVariants() {
    // remove rows that doesn't have avaialbe option selected
    this._myForm.value.inventoryVariantSettings.map((row, index) => {
      if (!row.inventoryOptionsSettingId) {
        this.removeInventoryVariantSettingLine(index);
      }
    });
    if (this._myForm.value.inventoryVariantSettings.length == 0) {
      return;
    }
    // check if there are overlapping options
    const _inventoryVariantsSettingSortedArray = this._myForm.value.inventoryVariantSettings
      .map((row) => row.inventoryOptionsSettingId)
      .sort();
    for (let i = 1; i < _inventoryVariantsSettingSortedArray.length; i++) {
      if (_inventoryVariantsSettingSortedArray[i - 1] === _inventoryVariantsSettingSortedArray[i]) {
        this.messageService.add({
          severity: "error",
          summary: "Inventory Variant Options Not Valid",
          detail: "Inventory variant options are overlapping!",
        });
        return;
      }
    }

    this._isFetchingData = true;
    const _allRows = this.formListComponent._objects ? this.formListComponent._objects.filter((row) => row.id > 0) : [];
    this._isFormListActive = false;

    // prepare existing inventory items
    _allRows.map((_existingInventoryItem) => {
      _existingInventoryItem.inventoryVariants = [];
      if (this._myForm.value.inventoryVariantSettings[0]) {
        if (
          !_existingInventoryItem.inventoryVariants.find(
            (_inventoryVariant) =>
              _inventoryVariant.inventoryOptionsSettingId ===
              this._myForm.value.inventoryVariantSettings[0].inventoryOptionsSettingId
          )
        ) {
          // Add
          _existingInventoryItem.inventoryVariants.push({
            inventoryOptionsSettingId: this._myForm.value.inventoryVariantSettings[0].inventoryOptionsSettingId,
            inventoryOptionsSettingValueId: null,
          });
        }
      }
      if (this._myForm.value.inventoryVariantSettings[1]) {
        if (
          !_existingInventoryItem.inventoryVariants.find(
            (_inventoryVariant) =>
              _inventoryVariant.inventoryOptionsSettingId ===
              this._myForm.value.inventoryVariantSettings[1].inventoryOptionsSettingId
          )
        ) {
          // Add
          _existingInventoryItem.inventoryVariants.push({
            inventoryOptionsSettingId: this._myForm.value.inventoryVariantSettings[1].inventoryOptionsSettingId,
            inventoryOptionsSettingValueId: null,
          });
        }
      }
      if (this._myForm.value.inventoryVariantSettings[2]) {
        if (
          !_existingInventoryItem.inventoryVariants.find(
            (_inventoryVariant) =>
              _inventoryVariant.inventoryOptionsSettingId ===
              this._myForm.value.inventoryVariantSettings[2].inventoryOptionsSettingId
          )
        ) {
          // Add
          _existingInventoryItem.inventoryVariants.push({
            inventoryOptionsSettingId: this._myForm.value.inventoryVariantSettings[2].inventoryOptionsSettingId,
            inventoryOptionsSettingValueId: null,
          });
        }
      }
      if (this._myForm.value.inventoryVariantSettings[3]) {
        if (
          !_existingInventoryItem.inventoryVariants.find(
            (_inventoryVariant) =>
              _inventoryVariant.inventoryOptionsSettingId ===
              this._myForm.value.inventoryVariantSettings[3].inventoryOptionsSettingId
          )
        ) {
          // Add
          _existingInventoryItem.inventoryVariants.push({
            inventoryOptionsSettingId: this._myForm.value.inventoryVariantSettings[3].inventoryOptionsSettingId,
            inventoryOptionsSettingValueId: null,
          });
        }
      }
    });
    // create a copy from parent without inventory Images and inventory options and sku that must be null
    const templateInventory = {
      ...this._myForm.value,
      inventoryImages: [],
      sku: null,
      isVariantParent: false,
      inventoryVariantSettings: [],
      inventoryVariants: [],
      inventoryBarcodes: this._myForm.value.inventoryBarcodes.map((row) => ({ ...row, id: 0 })),
      inventoryGoogleShopping: null,
      inventorySaleChannels: [],
      inventoryStocks: this._myForm.value.inventoryStocks.map((row) => ({
        ...row,
        id: 0,
        minStock: 0,
        committedQty: 0,
        onOrderQty: 0,
        qtyOH: 0,
      })),
      inventoryZones: this._myForm.value.inventoryZones.map((row) => ({ ...row, id: 0 })),
      inventorySuppliers: this._myForm.value.inventorySuppliers.map((supplier) => ({
        ...supplier,
        id: 0,
        inventorySupplierProductDetails: supplier.inventorySupplierProductDetails.map((detail) => ({
          ...detail,
          id: 0,
        })),
      })),
    };

    for (const option1 of this._myForm.value.inventoryVariantSettings[0]?.inventoryOptionsSettingValues || [null]) {
      for (const option2 of this._myForm.value.inventoryVariantSettings[1]?.inventoryOptionsSettingValues || [null]) {
        for (const option3 of this._myForm.value.inventoryVariantSettings[2]?.inventoryOptionsSettingValues || [null]) {
          for (const option4 of this._myForm.value.inventoryVariantSettings[3]?.inventoryOptionsSettingValues || [
            null,
          ]) {
            const _inventoryVariants = [];
            const _inventoryVariants_detail = {};
            if (option1) {
              _inventoryVariants.push({
                inventoryOptionsSettingId: option1.id,
                inventoryOptionsSettingValueId: option1.inventoryOptionsSettingId,
              });
              _inventoryVariants_detail[
                this._myForm.value.inventoryVariantSettings[0].inventoryOptionsSetting.optionName.replace(/ /g, "_")
              ] = option1.id;
            }
            if (option2) {
              _inventoryVariants.push({
                inventoryOptionsSettingId: option2.id,
                inventoryOptionsSettingValueId: option2.inventoryOptionsSettingId,
              });
              _inventoryVariants_detail[
                this._myForm.value.inventoryVariantSettings[1].inventoryOptionsSetting.optionName.replace(/ /g, "_")
              ] = option2.id;
            }
            if (option3) {
              _inventoryVariants.push({
                inventoryOptionsSettingId: option3.id,
                inventoryOptionsSettingValueId: option3.inventoryOptionsSettingId,
              });
              _inventoryVariants_detail[
                this._myForm.value.inventoryVariantSettings[2].inventoryOptionsSetting.optionName.replace(/ /g, "_")
              ] = option3.id;
            }
            if (option4) {
              _inventoryVariants.push({
                inventoryOptionsSettingId: option4.id,
                inventoryOptionsSettingValueId: option4.inventoryOptionsSettingId,
              });
              _inventoryVariants_detail[
                this._myForm.value.inventoryVariantSettings[3].inventoryOptionsSetting.optionName.replace(/ /g, "_")
              ] = option4.id;
            }
            if (
              !_allRows.find(
                (_existingInventoryItem) =>
                  (!option1 ||
                    (_existingInventoryItem.inventoryVariants_detail &&
                      _existingInventoryItem.inventoryVariants_detail[
                        this._myForm.value.inventoryVariantSettings[0].inventoryOptionsSetting.optionName.replace(
                          / /g,
                          "_"
                        )
                      ] === option1.id)) &&
                  (!option2 ||
                    (_existingInventoryItem.inventoryVariants_detail &&
                      _existingInventoryItem.inventoryVariants_detail[
                        this._myForm.value.inventoryVariantSettings[1].inventoryOptionsSetting.optionName.replace(
                          / /g,
                          "_"
                        )
                      ] === option2.id)) &&
                  (!option3 ||
                    (_existingInventoryItem.inventoryVariants_detail &&
                      _existingInventoryItem.inventoryVariants_detail[
                        this._myForm.value.inventoryVariantSettings[2].inventoryOptionsSetting.optionName.replace(
                          / /g,
                          "_"
                        )
                      ] === option3.id)) &&
                  (!option4 ||
                    (_existingInventoryItem.inventoryVariants_detail &&
                      _existingInventoryItem.inventoryVariants_detail[
                        this._myForm.value.inventoryVariantSettings[3].inventoryOptionsSetting.optionName.replace(
                          / /g,
                          "_"
                        )
                      ] === option4.id))
              )
            ) {
              const newInventory = Object.assign(
                {},
                templateInventory,
                { inventoryVariants: _inventoryVariants },
                { inventoryVariants_detail: _inventoryVariants_detail }
              );
              newInventory.parentInventoryId = this._myForm.value.id;
              newInventory.id = 0;
              _allRows.push(newInventory);
            }
          }
        }
      }
      this._formListRules = {};
      setTimeout(() => {
        this._formListRules = this.inventoryVariantSettingService.getValidationRules();
      }, 0);
    }

    this._myForm.markAsDirty();
    this.inventoryVariantColumns = this.inventoryVariantSettingService.getFormListColumns(
      this._myForm.value,
      new TemplateDatatypeOptions({ templateRef: this.unlinkTpl })
    );
    setTimeout(() => {
      this._isFormListActive = true;
      this.refreshFormList(_allRows || []);
    }, 0);
    this._isFetchingData = false;
  }

  static inventoryVariantsSetArray(fb: UntypedFormBuilder, inventoryVariants: InventoryVariant[]) {
    const formArray = fb.array([]);
    inventoryVariants.map((inventoryVariant) => {
      const temp = fb.group(inventoryVariant);
      formArray.push(temp);
    });
    return formArray;
  }

  ngOnInit(): void {
    this._myForm = this.dialogConfig.data.inventoryFormGroup;
    this._inventoryComponent = this.dialogConfig.data.inventoryComponent;
    this.inventoryVariantColumns = this.inventoryVariantSettingService.getFormListColumns(
      this._myForm.value,
      new TemplateDatatypeOptions({ templateRef: this.unlinkTpl })
    );
    this.updateFormList();
    // setTimeout(() => {
    this._formListRules = this.inventoryVariantSettingService.getValidationRules();
    // }, 0);
    super.ngOnInit();
  }

  onClosePressed() {
    this.dialogRef.close("close");
  }

  onSave() {
    // check if there are overlapping options
    const _inventoryVariantsSettingSortedArray = this._myForm.value.inventoryVariantSettings
      .map((row) => row.inventoryOptionsSettingId)
      .sort();
    for (let i = 1; i < _inventoryVariantsSettingSortedArray.length; i++) {
      if (_inventoryVariantsSettingSortedArray[i - 1] === _inventoryVariantsSettingSortedArray[i]) {
        this.messageService.add({
          severity: "warn",
          summary: "Inventory Variant Options Not Valid",
          detail: "Inventory variant options are overlapping!",
          life: 15000,
        });
        return;
      }
    }
    if (this._myForm.value.inventoryVariantSettings.length == 0 && this.formListComponent._objects.length > 0) {
      this.messageService.add({
        severity: "warn",
        summary: "Inventory Variant Options Not Valid",
        detail:
          "Empty inventory variant setting can not have inventory item assigned when there is no inventory variant option setting!",
        life: 15000,
      });
      return;
    }

    if (!this.isFormListVariantsFieldsSetup()) {
      this.inventoryVariantColumns = this.inventoryVariantSettingService.getFormListColumns(
        this._myForm.value,
        new TemplateDatatypeOptions({ templateRef: this.unlinkTpl })
      );
      this._formListRules = {};
      this._formListRules = this.inventoryVariantSettingService.getValidationRules();
      this.messageService.add({
        severity: "error",
        summary: "Inventory Variants columns Not Valid",
        detail: "Inventory variants grid is not matching variant settings saved!",
      });
      setTimeout(() => {
        this.formListComponent.enableEditMode();
      }, 0);
    } else {
      // check _objects if they overlapp
      if (!this.formListComponent.areAllFieldsValid) {
        this.messageService.add({
          severity: "warn",
          summary: "Inventory Variants Not Valid",
          detail: "Inventory variants grid is not valid and cannot be saved!",
          life: 15000,
        });
      } else if (this.areVariantsOverlap()) {
        this.messageService.add({
          severity: "info",
          summary: "Notification",
          detail:
            "You cannot save as there are duplicate variants below. Every variant (combination of options) must be unique. Please review the combinations below and delete any duplicate rows before saving. It is recommended that you delete a duplicate row instead of unlinking the existing variant which may have transaction history. Unlinking an existing variant will convert that SKU into a separate single product.",
          life: 25000,
        });
      } else {
        // save option settings in parent inventory
        this._inventoryComponent.onSave();
        // batchAddrows
        // batchEditRows
        // refresh form-list data

        // prepare all inventory variants
        this.formListComponent._objects.map((_inventory: Inventory) => {
          if (_inventory.id < 0) {
            _inventory.id = 0;
          }
          const previousInventoryVariants = _inventory.inventoryVariants ?? [];
          _inventory.inventoryVariants = [];
          for (let j = 0; j < this._myForm.value.inventoryVariantSettings.length; j++) {
            const fieldName = this._myForm.value.inventoryVariantSettings[j].inventoryOptionsSetting.optionName.replace(
              / /g,
              "_"
            );
            const newInventoryVariant = new InventoryVariant();
            newInventoryVariant.inventoryOptionsSettingId =
              this._myForm.value.inventoryVariantSettings[j].inventoryOptionsSettingId;
            newInventoryVariant.inventoryOptionsSettingValueId = _inventory["inventoryVariants_detail"][fieldName];

            // If this inventory has previously been saved with this variant, keep the id and inventoryId for the variant
            const previousVariant = previousInventoryVariants.find(
              (variant) => variant.inventoryOptionsSettingId === newInventoryVariant.inventoryOptionsSettingId
            );
            if (previousVariant) {
              newInventoryVariant.id = previousVariant.id;
              newInventoryVariant.inventoryId = previousVariant.inventoryId;
            }

            _inventory.inventoryVariants.push(newInventoryVariant);
          }
          // set inventory stock qty
          if (this.appSettings.isStoreLogin()) {
            const index = _inventory.inventoryStocks.findIndex(
              (row) => row.stockId === this.appSettings.getStore().stockId
            );
            if (_inventory.defaultInventoryStock && _inventory.defaultInventoryStock.qtyOH != undefined) {
              _inventory.inventoryStocks[index].qtyOH = _inventory.defaultInventoryStock.qtyOH;
            }
            if (_inventory.defaultInventoryStock && _inventory.defaultInventoryStock.maxStock !== undefined) {
              _inventory.inventoryStocks[index].maxStock = _inventory.defaultInventoryStock.maxStock;
            }
            if (_inventory.defaultInventoryStock && _inventory.defaultInventoryStock.minStock !== undefined) {
              _inventory.inventoryStocks[index].minStock = _inventory.defaultInventoryStock.minStock;
            }
          }
          // set zone cost
          if (!this.appSettings.isAdminLogin()) {
            const indexZones = _inventory.inventoryZones.findIndex(
              (row) => row.zoneId === this.appSettings.getStore()?.zoneId || this.appSettings.getBackoffice()?.zoneId
            );
            if (_inventory.defaultInventoryZone.standardCost !== undefined) {
              _inventory.inventoryZones[indexZones].standardCost = _inventory.defaultInventoryZone.standardCost;
            }
          }
        });

        this.formListComponent.saveAllFn(this.dbService, false, {
          stockId: this.appSettings.getStore().stockId,
          zoneId: this.appSettings.getStore().zoneId,
        });
        this.formListComponent._orgObjects = this.formListComponent._orgObjects.filter((row) => row.id > 0);

        // this._myForm.markAsDirty();
      }
      // this._myForm.markAsDirty();
    }
  }

  isFormListVariantsFieldsSetup() {
    const _tempInventoryVariantSettings = this._myForm.value.inventoryVariantSettings
      .map((row) => "inventoryVariants_detail." + row.inventoryOptionsSetting.optionName.replace(/ /g, "_"))
      .sort();
    const _tempInventoryVariantColumns = this.inventoryVariantColumns
      .map((row) => row.field)
      .filter((row) => row.startsWith("inventoryVariants_detail.", 0))
      .sort();

    return _tempInventoryVariantSettings.toString() == _tempInventoryVariantColumns.toString();
  }

  onAllRowsSaved(result) {
    result.newValues.map((_inventory) => {
      _inventory.defaultInventoryStock = _inventory.inventoryStocks.find((obj) => {
        return obj.stockId === this.appSettings.getStore().stockId;
      });
    });
    setTimeout(() => {
      this.refreshFormList(this.formListComponent._objects || []);
    }, 0);
  }

  areVariantsOverlap(): boolean {
    for (let i = 0; i < this.formListComponent._objects.length - 1; i++) {
      if (
        this.formListComponent._objects.slice(i + 1).find((row) => {
          for (let j = 0; j < this._myForm.value.inventoryVariantSettings.length; j++) {
            const fieldName = this._myForm.value.inventoryVariantSettings[j].inventoryOptionsSetting.optionName.replace(
              / /g,
              "_"
            );
            if (
              this.formListComponent._objects[i]["inventoryVariants_detail"][fieldName] !==
              row["inventoryVariants_detail"][fieldName]
            ) {
              return false;
            }
          }
          return true;
        })
      ) {
        return true;
      }
    }
    return false;
  }

  onRevert() {
    this._inventoryComponent.onRevert();
    this.inventoryVariantColumns = this.inventoryVariantSettingService.getFormListColumns(
      this._myForm.value,
      new TemplateDatatypeOptions({ templateRef: this.unlinkTpl })
    );
    this.clearAll();
    setTimeout(() => {
      this._isFormListActive = true;
      setTimeout(() => {}, 0);
    }, 0);
    this.formListComponent._formGroups.markAsPristine();
    // this._myForm.markAsDirty();
  }

  openNewModelInDialog(defaultData: any) {}

  onSearchResInventory(inventoryResult: SearchResultItem) {
    this._isFetchingData = true;

    const tempInventory = inventoryResult.data;
    if (!tempInventory.defaultInventoryStock) {
      const newInventoryStock = new InventoryStock();
      newInventoryStock.stockId = this.appSettings.getStore().stockId;
      tempInventory.inventoryStocks.push(newInventoryStock);
      tempInventory.defaultInventoryStock = newInventoryStock;
    }
    tempInventory.parentInventoryId = this._myForm.value.id;
    tempInventory["inventoryVariants_detail"] = {};
    tempInventory.inventoryVariants.map((inventoryVariant) => {
      tempInventory["inventoryVariants_detail"][
        inventoryVariant.inventoryOptionsSetting.optionName.replace(/ /g, "_")
      ] = inventoryVariant.inventoryOptionsSettingValueId;
    });

    this.formListComponent.addNewRow(tempInventory);
    this.refreshFormList(this.formListComponent._objects || []);

    this._myForm.markAsDirty();
    this._isFetchingData = false;
  }

  removeInventoryVariantSettingLine(index: number) {
    const inventoryvariantSettingArray = <UntypedFormArray>this._myForm.get("inventoryVariantSettings");
    if (inventoryvariantSettingArray.at(index).pristine) {
      inventoryvariantSettingArray.removeAt(index);
      this._myForm.markAsDirty();
      return;
    }

    this.confirmationService.confirm({
      message: "By deleting this variant setting you will lose any changes. Are you sure?",
      rejectButtonStyleClass: "p-button-link",
      accept: () => {
        inventoryvariantSettingArray.removeAt(index);
        this._myForm.markAsDirty();
      },
    });
  }

  addInventoryVariantSettingLine() {
    if (!(this._myForm.controls.inventoryVariantSettings instanceof UntypedFormArray)) {
      this._myForm.setControl("inventoryVariantSettings", this.fb.array([]));
    }
    if ((this._myForm.controls.inventoryVariantSettings as UntypedFormArray).length < 4) {
      (this._myForm.controls.inventoryVariantSettings as UntypedFormArray).push(
        InventoryVariantSettingComponent.init(this.fb)
      );
    }
  }

  private updateFormList() {
    this._isFetchingData = true;
    this.subsList.push(
      this.inventoryVariantSettingService.getInventoryVariants(this._myForm.get("id").value).subscribe({
        next: (data) => {
          // set defaultInventory
          if (this.appSettings.getStoreId()) {
            data.rows.map((row) => {
              if (Object.keys(row.defaultInventoryStock).length === 0) {
                row.defaultInventoryStock = row.inventoryStocks.find((obj) => {
                  return obj.stockId === this.appSettings.getStore().stockId;
                });
                if (!row.defaultInventoryStock) {
                  const newInventoryStock = new InventoryStock();
                  newInventoryStock.stockId = this.appSettings.getStore().stockId;
                  row.inventoryStocks.push(newInventoryStock);
                  row.defaultInventoryStock = newInventoryStock;
                }
              }
            });
          }
          this.setVariantDynamicFields(data.rows);
          this.refreshFormList(data.rows || []);
          this._isFetchingData = false;
        },
        error: (error) => {
          // Show toast with error message
          this.messageService.add(this.alertMessagesService.getErrorMessage(error));
          // Empty form list
          this.refreshFormList([]);
          this._isFetchingData = false;
        },
      })
    );
  }

  setVariantDynamicFields(records: Inventory[]): void {
    records.map((record) => {
      record["inventoryVariants_detail"] = {};
      record.inventoryVariants.forEach((inventoryVariant) => {
        record["inventoryVariants_detail"][inventoryVariant.inventoryOptionsSetting.optionName.replace(/ /g, "_")] =
          inventoryVariant.inventoryOptionsSettingValueId;
      });
    });
  }

  private refreshFormList(data: any[]) {
    this.inventoryVariantSettingLocalDataService.initialize(data, "inventory");
    setTimeout(() => {
      this.formListComponent.reloadData();
      this.formListComponent.enableEditMode();
      this.formListComponent._orgObjects = this.formListComponent._orgObjects.filter((row) => row.id > 0);
    }, 0);
  }

  onUnlinkBtnClicked(rowData) {
    this.confirmationService.confirm({
      message:
        "Removing options or attributes will unlink any associated products. This action cannot be reversed. Are you sure you want to proceed?",
      rejectButtonStyleClass: "p-button-link",
      accept: () => {
        let indexToRemove = -1;
        rowData.inventorySaleChannels.forEach((value, index) => {
          if (value.saleChannel.channelType === ChannelType.takuecommerce && value.saleChannel.isConnected) {
            indexToRemove = index;
          }
        });

        if (indexToRemove !== -1) {
          this.dbService
            ._postRequest<any>(
              this.dbService.webUrl +
                "TAKUeCommerceUnlink/" +
                rowData.inventorySaleChannels.at(indexToRemove).saleChannelId +
                "/" +
                rowData.id,
              {}
            )
            .subscribe({
              next: (response) => {
                this.messageService.add(this.alertMessagesService.getMessage("save-success", "Unlinking"));
                this.formListComponent._removeRow(rowData);
              },
              error: (error) => {
                // Handle error scenario
                this.messageService.add(this.alertMessagesService.getErrorMessage(error));
              },
            });
        } else {
          this.updateRowData(rowData);
        }
      },
    });
  }

  private updateRowData(rowData): void {
    rowData.parentInventoryId = null;
    // rowData.inventoryVariants = [];
    this.dbService.editRow("inventory", rowData).subscribe({
      next: (_response) => {
        this.messageService.add(this.alertMessagesService.getMessage("save-success", "Unlinking"));
        this.formListComponent._removeRow(rowData);
      },
      error: (_errorResponse) => {
        this.messageService.add(this.alertMessagesService.getErrorMessage(_errorResponse));
      },
    });
  }

  onRemoveRowBtnClicked(rowData) {
    const rowIndex = this.formListComponent._objects.findIndex((row) => row.id === rowData.id);
    this.formListComponent._objects.splice(rowIndex, 1);
    this.refreshFormList(this.formListComponent._objects);
  }

  ngOnDestroy(): void {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }

  ngAfterViewInit() {
    // setTimeout(() => {
    //   this._formListRules = this.inventoryVariantSettingService.getValidationRules();
    // }, 0);
  }
}
