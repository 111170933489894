/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

export class PersonInstantMsg {
  id: number;
  instantMsgType: PersonInstantMsgType;
  instantMsg: string;

  constructor() {
    this.id = 0;
    this.instantMsgType = null;
    this.instantMsg = "";
  }
}

export enum PersonInstantMsgType {
  skype = "Skype",
  facebookMessenger = "FB Messenger",
  whatsapp = "Whatsapp",
  other = "Other",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
