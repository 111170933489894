/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

export class InventoryImage {
  id: number;
  fileName: string;
  urlFileName: string;
  urlFileName40: string;
  urlFileName150: string;
  urlFileName475: string;
  isWebEnabled: boolean;
  isWebDefault: boolean;
  pinToPictureGallery: boolean;
  caption: string;
  altTitle: string;

  constructor() {
    this.id = 0;
    this.fileName = "";
    this.urlFileName = "";
    this.urlFileName40 = "";
    this.urlFileName150 = "";
    this.urlFileName475 = "";
    this.isWebEnabled = true;
    this.isWebDefault = false;
    this.pinToPictureGallery = false;
    this.caption = "";
    this.altTitle = "";
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
