/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Address } from "../../../contact-accounts/address/address";
import { Zone } from "../../zone-settings/Zone";

export class BackOffice {
  id: number;
  backOfficeName: string;
  backOfficeID: string;
  isActive: boolean;
  address: Address;
  zoneId: number | null;
  zone: Zone;
  backOfficeTimeZone: string;
  logoFileName?: string = null;
  logoUrl?: string = null;
  logoUrl40?: string = null;
  logoUrl150?: string = null;
  logoUrl475?: string = null;

  constructor() {
    this.id = 0;
    this.backOfficeName = "";
    this.backOfficeID = "";
    this.isActive = true;
    this.address = new Address();
    this.zoneId = null;
    this.zone = null;
    this.backOfficeTimeZone = "America/Toronto";
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
