import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Validators } from "@angular/forms";
import { Col, DataType, FilterType } from "../../../form-list/form-list/form-list";
import { FormListModel } from "../../../form-list/form-list/form-list-model.interface";
import { AuthService } from "../../../shared/services/auth.service";
import { DBService } from "../../../shared/services/db.service";
import { PaymentTerm } from "./payment-term";
import { ValidatorMapType } from "src/app/utility/types";

@Injectable({
  providedIn: "root",
})
export class PaymentTermService extends DBService implements FormListModel {
  _model = "paymentTerm";

  constructor(protected http: HttpClient, protected authService: AuthService) {
    super(http, authService);
  }

  getValidationRules(): ValidatorMapType<PaymentTerm> {
    return {
      name: [Validators.required],
    };
  }

  getFormListColumns(): Col[] {
    return [
      {
        field: "isActive",
        header: "Active",
        visible: true,
        readonly: false,
        frozen: true,
        dataType: DataType.checkbox,
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.enum,
        colWidth: 120,
      },
      {
        field: "name",
        header: "Name",
        visible: true,
        readonly: false,
        frozen: true,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 160,
      },
    ];
  }
}
