<taku-form-header
  [displayMode]="insideWizard ? FormHeaderDisplayMode.WIZARD : FormHeaderDisplayMode.FULL"
  [formGroup]="_myForm"
  (backClicked)="goBack()"
  (saveClicked)="onSave()"
  (revertClicked)="onRevert()"
  [toolbarTitle]="customHeaderTitle || pageTitle"
  [showSecondaryBtns]="false"
  [iconStyleClass]="'pi pi-credit-card'"
></taku-form-header>

<div class="grid">
  <p-card class="col-12">
    <div class="grid col-12 grid justify-content-center">
      <div class="pickList-wrapper">
        <p-pickList
          [source]="availableTenderTypes"
          [target]="storeTenderTypes"
          filterBy="name"
          sourceHeader="AVAILABLE"
          filterBy="tenderType.description"
          targetHeader="SELECTED"
          [showSourceControls]="false"
          (onMoveToTarget)="onTendersMoved()"
          (onMoveToSource)="onTendersMoved()"
          (onTargetReorder)="onTendersOrderChanged($event)"
          responsiveLayout="stack"
          breakpoint="640px"
        >
          <ng-template let-storeTender pTemplate="item">
            <i class="tender-icon"></i> {{ storeTender.tenderType?.description }}
          </ng-template>
        </p-pickList>
        <form-validation-messages
          class="tenders-validation"
          [hidden]="selectedTendersArray.valid || !selectedTendersArray.dirty"
          [errors]="selectedTendersArray.errors"
          field="storeTenderTypes"
        ></form-validation-messages>
      </div>
      <!-- <div class="col-12">
        <code>Form: {{_myForm.value|json}}</code><br><br>
        <code>Available TT: {{availableTenderTypes|json}}</code><br><br>
        <code>Selected TT: {{storeTenderTypes|json}}</code><br><br>
      </div> -->
    </div>
  </p-card>
</div>
