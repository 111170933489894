<div class="grid p-fluid topWrapper" [ngClass]="{ 'disabled-control': disabled }">
  <div class="col-12 pt-1 pl-0">
    <div class="p-inputgroup">
      <span class="p-float-label">
        <taku-autoComplete
          [disabled]="disabled"
          [(ngModel)]="textSearch"
          [suggestions]="searchResults"
          (completeMethod)="search($event)"
          [emptyMessage]="_noResultsMsg"
          size="30"
          styleClass="search-box"
          [scrollHeight]="_scrollHeight"
          appendTo="body"
          (onSuggestionsUpdated)="onSuggestionsUpdated()"
          (onSelect)="resultItemSelected($event)"
          [readonly]="readonly"
          (onKeyUp)="checkPressedKey($event)"
          [ngClass]="{ 'inputctrl-filled': textSearch }"
          [delay]="0"
          [placeholder]="showAnimatePlaceholder ? '' : _placeholder"
        >
          <ng-template let-item pTemplate="item">
            <taku-searchresult-line
              [searchQuery]="textSearch"
              [headline]="item.headline"
              [subline]="item.description"
              [imageIcon]="getInventoryIcon(item._data)"
              [infoline]="getInventoryVariantsStr(item._data)"
            ></taku-searchresult-line>
          </ng-template>
        </taku-autoComplete>
        <label *ngIf="showAnimatePlaceholder" [innerHTML]="_placeholder" style="margin-top: -7px"></label>
      </span>
      <i class="material-icons clear-field" (click)="onFieldCleared($event)" *ngIf="textSearch && !disabled">close</i>
      <span class="p-inputgroup-addon"
        ><a
          href="javascript://"
          (click)="!disabled && searchAgain()"
          class="pi pi-search"
          [ngClass]="{ 'icon-disabled': isDropdownOpen || !textSearch }"
        ></a
      ></span>
    </div>

    <div [style.display]="'none'">
      <div id="selectFilter" #selectFilterContainer [hidden]="!includeHeader">
        <p-selectButton
          *ngIf="filterTypes.length > 1"
          [options]="filterTypes"
          [ngModel]="selectedFilter"
          (ngModelChange)="onHeaderTabChanged($event)"
        >
          <ng-template let-item>
            <i class="material-icons picture" [hidden]="!item.icon">{{ item.icon }}</i>
            <span>{{ item.label }}</span>
          </ng-template>
        </p-selectButton>
      </div>

      <div id="actionLinks" #actionLinksContainer>
        <ul
          class="addnewLinks"
          *ngFor="let addNewItemInfo of activeLinks"
          [hidden]="!includeCreateNewBtns || !addNewItemInfo.visible"
          [ngClass]="{ disableNewLink: addNewItemInfo.disabled }"
        >
          <li>
            <a (click)="newItemClicked(addNewItemInfo.modelName, $event)">
              <taku-searchresult-line
                [headline]="'Add New ' + addNewItemInfo.label | uppercase"
                [imageIcon]="addNewItemInfo.imagePath"
              ></taku-searchresult-line>
            </a>
          </li>
        </ul>

        <a *ngIf="showLoadMore" (click)="loadMore()" class="loadMore">
          <h3><i class="material-icons loadmore-icon">keyboard_arrow_down</i> MORE RESULTS</h3>
        </a>
      </div>
    </div>
  </div>
</div>

<p-confirmDialog
  #cd
  [key]="_confirmationDialogKey"
  header="No Results Found"
  icon="pi pi-exclamation-triangle"
  [style]="{ width: '300pt', maxWidth: 'calc(100vw - 18pt)' }"
  appendTo="body"
  [closeOnEscape]="false"
  [closable]="false"
>
  <ng-template pTemplate="footer">
    <!-- Create one accept button for each available entity -->
    <div class="flex flex-column md:flex-md-row align-items-center justify-content-between">
      <div *ngFor="let link of activeLinks" class="confirmationDialogButtonContainer">
        <button
          type="button"
          pButton
          icon="pi pi-plus"
          [label]="'Add ' + link.label"
          *ngIf="link.visible"
          (click)="cd.confirmation.accept(link.modelName); cd.hide()"
          [disabled]="link.disabled"
          class="p-confirmdialog-acceptbutton"
        ></button>
      </div>
      <button type="button" pButton pRipple class="p-button-secondary" label="Close" (click)="cd.reject()"></button>
    </div>
  </ng-template>
</p-confirmDialog>
