<div [formGroup]="formGroup" class="col-12 taku-editor-topWrapper">
  <h5>{{ fieldCaption }}</h5>
  <p-editor formControlName="{{ fieldName }}" [style]="{ height: _height }">
    <!-- <ng-template pTemplate="header">
      <button class="ql-bold"></button>
      <button class="ql-italic"></button>
      <button class="ql-underline"></button>
      <button class="ql-list" value="ordered"></button>
      <button class="ql-list" value="bullet"></button>
      <button *ngIf="showHyperlinkBtn" class="ql-link" ></button>

      <button class="ql-clean"></button>
    </ng-template> -->
  </p-editor>
  <i class="pi pi-window-maximize" (click)="showEditorInDialog()"></i>
  <div *ngIf="maxCharLength" class="char-counter" [ngClass]="{ 'validation-error': charLength > maxCharLength }"
    >{{ charLength }}/{{ maxCharLength }}</div
  >
  <p-dialog
    *ngIf="ifDisplayDialog"
    [(visible)]="displayDialog"
    (onHide)="updateFormGroup()"
    styleClass="longDescription-dialog"
    [ngStyle]="{ width: isMobile ? '100vh' : '75vh', 'max-height': isMobile ? '100vh' : '80vh' }"
  >
    <h5>{{ fieldCaption }}</h5>
    <p-editor formControlName="{{ fieldName }}"></p-editor>
    <div *ngIf="maxCharLength" class="char-counter" [ngClass]="{ 'validation-error': charLength > maxCharLength }"
      >{{ charLength }}/{{ maxCharLength }}</div
    >
  </p-dialog>
</div>
