/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewContainerRef,
  OnInit,
  OnDestroy,
  OnChanges,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ConfirmationService, MenuItem, MessageService, SelectItem } from "primeng/api";
import { GiftReceiptTapePreviewComponent } from "src/app/core/previews/receipt/gift-receipt-tape-preview/gift-receipt-tape-preview.component";
import { Tender_Type } from "src/app/core/settings/business-settings/tender-type/tender-type";
import { AppSettingsStorageService } from "../../../../shared/app-settings-storage.service";
import {
  PrintingFactoryService,
  PrintingMgr,
  ReceiptPrintingSize,
} from "../../../../shared/services/printing-service.service";
import { WebHelpers } from "../../../../utility/WebHelpers";
import { DeliveryStatus, FulfillmentStatus, PaymentStatus, SaleDoc } from "../sale-doc/sale-doc";
import { DocState, SaleDocType } from "../../doc/doc";
import { StoreSettingEmail } from "src/app/core/settings/store-settings/store-mail-settings/StoreSettingEmail";
import { Subscription } from "rxjs";
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { Router } from "@angular/router";
import {
  SaleChannelFulfillmentStatus,
  OrderStatus,
  SaleChannelPaymentStatus,
  SaleChannelOrder,
  SaleChannelDeliveryStatus,
  DeliveryMethod,
} from "src/app/core/sale-channel/sale-channel-orders/sale-channel-order";
import { AllUpcomingOrdersService } from "./../../../all-upcoming-orders/all-upcoming-orders/all-upcoming-orders.service";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";
import * as _ from "lodash";
import { SaledocVoidDialogComponent } from "../saledoc-void-dialog/saledoc-void-dialog.component";
import { MonerisService } from "src/app/core/settings/integration-settings/moneris-terminal/moneris.service";
import { pairwise } from "rxjs/operators";
import { DBService } from "src/app/shared/services/db.service";
import { SaleChannelOrdersService } from "src/app/core/sale-channel/sale-channel-orders/sale-channel-orders.service";
import moment from "moment";
import { OverlayPanel } from "primeng/overlaypanel";
import { Commitments } from "../commitments/commitments";
import { Clipboard } from "@angular/cdk/clipboard";
import { AccountDialogWrapperComponent } from "src/app/core/contact-accounts/account-dialog-wrapper/account-dialog-wrapper.component";
import { ACTION_TYPE, BUTTON_SIZE } from "src/app/shared/components/action-button/action-type.enum";
import { PrintHelpers } from "src/app/utility/PrintHelpers";
import { TakuPayService } from "src/app/core/settings/integration-settings/taku-pay/taku-pay.service";

enum DialogName {
  PRINT_PREVIEW = "printPreview",
}

@Component({
  selector: "taku-sale-doc-all-upcoming-order-preview",
  templateUrl: "./sale-doc-all-upcoming-order-preview.component.html",
  styleUrls: ["./sale-doc-all-upcoming-order-preview.component.scss"],
  providers: [DialogService],
})
export class SaleDocAllUpcomingOrderPreviewComponent implements OnChanges, OnInit, OnDestroy {
  protected readonly SaleDocType = SaleDocType;
  saleDocCommitment?: Commitments;
  subsList: Subscription[] = [];
  ReceiptPrintingSize = ReceiptPrintingSize;
  @Input() _object;
  @Output() dialogClosed: EventEmitter<any> = new EventEmitter();
  @Output() changedForm: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild("printPreviewHost", { read: ViewContainerRef, static: true }) printPreviewHost: ViewContainerRef;
  _enabledEditMode = true;
  @ViewChild("deliveryDatePanel", { static: false }) deliveryDatePanel: OverlayPanel;

  private emailSettings: StoreSettingEmail;
  enum_saledoc_fulfillment_status: SelectItem[];
  enum_saledoc_delivery_method: SelectItem[];
  enum_saledoc_delivery_status: SelectItem[];
  progressBar: DynamicDialogRef;
  public ACTION_TYPE: typeof ACTION_TYPE = ACTION_TYPE;
  public BUTTON_SIZE: typeof BUTTON_SIZE = BUTTON_SIZE;

  Tender_Type = Tender_Type;
  FulfillmentStatus = FulfillmentStatus;
  saleDoc: SaleDoc;
  saleChannelOrder: SaleChannelOrder;
  tendersPrettyPrint: string;
  _activeFullDialog: DialogName;
  _activeFullDialogExtra: any;
  _saleChannelOrder: SaleChannelOrder;
  footerFormGroup: UntypedFormGroup;
  _isStore: boolean;
  printSizesMenuItems: MenuItem[] = [];
  _currencyISOCode = "CAD";
  orderCreationDateObj: Date;

  emailReceiptSizesMenuItems: MenuItem[] = [
    {
      label: ReceiptPrintingSize.TAPE_SIZE,
      command: () => {
        this.onEmailDoc(ReceiptPrintingSize.TAPE_SIZE);
      },
      icon: "takuicon-measuring-tape",
    },
    {
      label: ReceiptPrintingSize.LETTER_SIZE,
      command: () => {
        this.onEmailDoc(ReceiptPrintingSize.LETTER_SIZE);
      },
      icon: "takuicon-invoice",
    },
  ];

  printMgr: PrintingMgr;
  _headerTitle = "";
  decimalFormat = "1.2-2";

  constructor(
    private fb: UntypedFormBuilder,
    appSettings: AppSettingsStorageService,
    protected _router: Router,
    printingMgrFactory: PrintingFactoryService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    protected confirmationService: ConfirmationService,
    private allUpcomingOrdersService: AllUpcomingOrdersService,
    private appSettingsService: AppSettingsStorageService,
    private messageService: MessageService,
    private alertMessagesService: AlertMessagesService,
    private dialogService: DialogService,
    public monerisService: MonerisService,
    private takuPayService: TakuPayService,
    private dbService: DBService,
    private clipboard: Clipboard,
    private saleChannelOrdersService: SaleChannelOrdersService
  ) {
    this._isStore = appSettings.getStoreId() ? true : false;
    this._currencyISOCode = appSettings.getZone().defaultCurrencyIsoCode;
    if (this.config && this.config.data && this.config.data._object) {
      this._object = this.config.data._object;
      this._enabledEditMode = this.config.data._enabledEditMode || this._enabledEditMode;
      this._saleChannelOrder = this.config.data ? this.config.data._saleChannelOrder : null;
      this.saleDoc = SaleDoc.convertIntoTypedObject(this._object);
      this.tendersPrettyPrint = [
        ...new Set(
          this.saleDoc.notReturnedTenders.map((t) =>
            t.refTransaction != null ? t.refTransaction.CardType : t.tenderType ? t.tenderType.description : null
          )
        ),
      ]
        .filter(Boolean)
        .join(", ");

      const _filter = {
        saledocId: {
          value: this.saleDoc.id,
          matchMode: "equals",
        },
      };

      this.allUpcomingOrdersService
        .getRows("saleChannelOrder", JSON.stringify(_filter))
        .subscribe((_saleChannelOrders) => {
          if (_saleChannelOrders.count > 0) {
            this.saleChannelOrder = _saleChannelOrders.rows[0];
          }
        });

      // this._headerTitle = `<span>DOC# ${this.saleDoc.doc.docNo} -&nbsp;</span><span>Revisit Details</span>`;
      this.printMgr = printingMgrFactory.build(this.saleDoc.storeId);
    } else {
      this.printMgr = printingMgrFactory.build(appSettings.getStoreId());
    }
    this.footerFormGroup = this.fb.group({
      emailAddress: this.fb.control(null, Validators.email),
      deliveryDate: this.fb.control(this.saleDoc ? this.saleDoc.deliveryDate : null),
      fulfillmentStatus: this.fb.control(this.saleDoc ? this.saleDoc.fulfillmentStatus : null),
      deliveryMethod: this.fb.control(this.saleDoc ? this.saleDoc.deliveryMethod : null),
      deliveryStatus: this.fb.control(this.saleDoc ? this.saleDoc.deliveryStatus : null),
    });

    if (this.saleDoc?.doc?.docDate) {
      this.orderCreationDateObj = moment(this.saleDoc.doc.docDate).toDate();
    } else {
      this.orderCreationDateObj = new Date();
    }

    this.footerFormGroup
      .get("deliveryDate")
      .valueChanges.pipe(pairwise())
      .subscribe(([prev, next]) => {
        if (prev != next) {
          this.onDeliveryDateChanged();
          this.deliveryDatePanel.toggle(null, this.deliveryDatePanel.el.nativeElement);
        }
      });
  }

  ngOnInit() {
    this.printSizesMenuItems = [
      {
        label: "Receipt (Tape)",
        command: () => {
          this.onReprintDoc(ReceiptPrintingSize.TAPE_SIZE);
        },
        icon: "takuicon-measuring-tape",
      },
      {
        label: "Receipt (Letter)",
        command: () => {
          this.onReprintDoc(ReceiptPrintingSize.LETTER_SIZE);
        },
        icon: "takuicon-invoice",
      },
      {
        label: "Card Receipt",
        command: () => {
          this.onReceiptCardPrintPressed();
        },
        icon: "text-3xl pi pi-credit-card",
      },
      {
        label: "Gift Receipt",
        command: () => {
          this.onReprintGiftReceipt();
        },
        icon: "takuicon-gift",
      },
      {
        label: "Packing List (Tape)",
        command: () => {
          this.onPrintPackingList(ReceiptPrintingSize.TAPE_SIZE);
        },
        icon: "takuicon-measuring-tape",
        disabled:
          this.saleChannelOrder?.orderStatus === "VOIDED" ||
          this.saleChannelOrder?.orderStatus === "PENDING REFUND" ||
          this.saleDoc.doc.state === "Voided",
      },
      {
        label: "Packing List (Letter)",
        command: () => {
          this.onPrintPackingList(ReceiptPrintingSize.LETTER_SIZE);
        },
        icon: "takuicon-invoice",
        disabled:
          this.saleChannelOrder?.orderStatus === "VOIDED" ||
          this.saleChannelOrder?.orderStatus === "PENDING REFUND" ||
          this.saleDoc.doc.state === "Voided",
      },
      {
        label: "Prep Order (Tape)",
        command: () => {
          // Print prep
          this.onPrintPrep();
        },
        icon: "takuicon-measuring-tape",
        disabled:
          this.saleChannelOrder?.orderStatus === "VOIDED" ||
          this.saleChannelOrder?.orderStatus === "PENDING REFUND" ||
          this.saleDoc.doc.state === "Voided" ||
          !this.appSettingsService.getStation()?.prepPrinter,
      },
    ];

    this.enum_saledoc_fulfillment_status = this.allUpcomingOrdersService
      .enumSelectOptions(FulfillmentStatus)
      .map((opt) => {
        if (opt.value === FulfillmentStatus.voided) {
          opt.disabled = true;
          opt.icon = "pi pi-lock";
        }
        return opt;
      });
    this.enum_saledoc_delivery_method = this.allUpcomingOrdersService.enumSelectOptions(DeliveryMethod);
    //this.enum_saledoc_delivery_status = this.allUpcomingOrdersService.enumSelectOptions(DeliveryStatus);
    this.enum_saledoc_delivery_status = this.getDeliveryStatusBasedOnFulfillmentStatus();

    this.subsList.push(
      this.appSettingsService.getStoreSettings("storeSettingEmail").subscribe({
        next: (settings) => {
          this.emailSettings = settings;
        },
        error: (error) => {
          this.emailSettings = null;
        },
      })
    );

    // If recurring settings are null, query for them from related commitment
    if (!this.saleDoc.saleDocLines[0]?.recurringOrderSetting) {
      const _cmtFilter = {
        "commitmentCycles.refSaleDocId": {
          value: this.saleDoc.id,
          matchMode: "equals",
        },
      };

      this.dbService.getRows("commitment", JSON.stringify(_cmtFilter)).subscribe((res) => {
        if (res.rows?.length >= 1 && this.saleDoc.saleDocLines?.length >= 1) {
          const saleDocCommitment = res.rows[0];
          this.saleDocCommitment = saleDocCommitment;

          this.saleDoc.saleDocLines = this.saleDoc.saleDocLines.map((saleDocLine) => {
            if (saleDocLine.recurringOrderSetting == null) {
              // There should only ever be one commitment for all the sale doc lines,
              // so applying recurring settings for all from single commitment
              saleDocLine.recurringOrderSettingId = saleDocCommitment.recurringOrderSettingId;
              saleDocLine.recurringOrderSetting = saleDocCommitment.recurringOrderSetting;
              saleDocLine.repeatCycle = saleDocCommitment.repeatCycle;
            }
            return saleDocLine;
          });
        }
      });
    }

    if (!this.saleChannelOrder) {
      const _filter = {
        saledocId: {
          value: this.saleDoc.id,
          matchMode: "equals",
        },
      };

      this.allUpcomingOrdersService
        .getRows("saleChannelOrder", JSON.stringify(_filter))
        .subscribe((_saleChannelOrders) => {
          if (_saleChannelOrders.count > 0) {
            this.saleChannelOrder = _saleChannelOrders.rows[0];
          }
        });
    }
    this.ngOnChanges();
  }

  ngOnChanges() {
    this.saleDoc = SaleDoc.convertIntoTypedObject(this._object);
    this._headerTitle = `<span>DOC# ${this.saleDoc.doc.docNo} -&nbsp;</span><span style="color: orange;">${this.saleDoc.doc.state}</span>`;
    if (
      this.saleDoc.fulfillmentStatus === FulfillmentStatus.completed &&
      this.saleDoc.paymentStatus === PaymentStatus.paid
    ) {
      this._headerTitle += `&nbsp;<i class="pl-2 pi pi-check-circle" style="color: green; font-size: 1.2em;"></i>`;
    } else {
      this._headerTitle += `&nbsp;<i class="pl-2 pi pi-check-circle" style="color: grey; font-size: 1.2em;"></i>`;
    }
    //this.tendersPrettyPrint = _.uniqBy(this.saleDoc.notReturnedTenders, 'tenderType.id').map(t => (t.refTransaction != null) ? t.refTransaction.CardType : t.tenderType.description).join(', ');
    this.tendersPrettyPrint = [
      ...new Set(
        this.saleDoc.notReturnedTenders.map((t) =>
          t.refTransaction != null ? t.refTransaction.CardType : t.tenderType ? t.tenderType.description : null
        )
      ),
    ]
      .filter(Boolean)
      .join(", ");

    this.footerFormGroup.setValue({
      emailAddress: this.saleDoc.accountEmail,
      deliveryDate: this.saleDoc.deliveryDate,
      fulfillmentStatus: this.saleDoc.fulfillmentStatus,
      deliveryMethod: this.saleDoc.deliveryMethod,
      deliveryStatus: this.saleDoc.deliveryStatus,
    });
  }

  get emailAddressCtrl() {
    return this.footerFormGroup.get("emailAddress");
  }

  get sendEmailEnabled() {
    return this.emailSettings && this.emailSettings.id;
  }

  get _isMobile() {
    return WebHelpers.isMobileScreen();
  }

  get isVoided() {
    return this.saleDoc.doc.state === DocState.voided;
  }

  onClosePressed() {
    this.dialogClosed.emit(this.saleDoc);
    if (this._saleChannelOrder) {
      this.allUpcomingOrdersService.ref.close(this._saleChannelOrder);
    } else {
      if (this.ref) {
        this.ref.close();
      }
    }
  }

  closeFullSizeDialog() {
    this._activeFullDialog = null;
  }

  onPrintPackingList(printSize: ReceiptPrintingSize) {
    const _tempSaleDoc = Object.assign({}, this.saleDoc);
    _tempSaleDoc["isPackingList"] = true;
    this.printMgr.printDocumentsWithSize([_tempSaleDoc], this.printPreviewHost, printSize);
  }

  onPrintPrep() {
    this.printMgr.printStarCloudPRNTPrepInBatch([this.saleDoc]);
  }

  onReprintDoc(printSize: ReceiptPrintingSize) {
    this.printMgr.printDocumentsWithSize([this.saleDoc], this.printPreviewHost, printSize);
  }

  onReceiptCardPrintPressed() {
    for (let index = 0; index < this.saleDoc.saleDocTenders.length; index++) {
      const _saleDocTender = this.saleDoc.saleDocTenders[index];
      if (
        _saleDocTender.refTransaction &&
        (_saleDocTender.refTransaction["customerReceiptTemplate"] ||
          _saleDocTender.refTransaction["merchantReceiptTemplate"])
      ) {
        if (PrintHelpers.isTakuPayTransaction(this.saleDoc)) {
          this.takuPayService.printPaymentReceipt(this.printMgr, _saleDocTender, this.saleDoc, this.printPreviewHost);
        } else {
          this.monerisService.printPaymentReceipt(this.printMgr, _saleDocTender, this.saleDoc, this.printPreviewHost);
        }
      }
    }
  }

  onReprintGiftReceipt() {
    this.printMgr.printSaleDocsInBatch([this.saleDoc], this.printPreviewHost, GiftReceiptTapePreviewComponent);
  }

  onOpenSalesScreen() {
    if (this._router.url.includes("/saleDoc")) {
      this.confirmationService.confirm({
        header: "Confirmation to discard current sale",
        message: "Clicking this Pay Button will clear your current sale.<br>Are you sure you want to proceed?",
        acceptLabel: "Yes",
        rejectLabel: "No",
        rejectVisible: true,
        acceptVisible: true,
        rejectButtonStyleClass: "p-button-link",
        accept: () => {
          void this._router.navigate(["/sell/saleDoc", this.saleDoc.id], {
            state: { bypassGuards: true, skipUnfinalizedSalesCheck: true },
          });
          this.ref.close();
        },
        // reject: () => {}
      });
    } else {
      void this._router.navigate(["/sell/saleDoc", this.saleDoc.id]);
      this.ref.close();
    }
  }

  onDeliveryDateChanged() {
    this.saleChannelOrdersService
      .patchRow("saleDoc", { id: this.saleDoc.id, deliveryDate: this.footerFormGroup.get("deliveryDate").value })
      .subscribe({
        next: () => {
          this.saleDoc.deliveryDate = this.footerFormGroup.get("deliveryDate").value;
          if (this._saleChannelOrder) {
            this._saleChannelOrder.pickupDate = this.saleDoc.deliveryDate as unknown as Date;
          }
          if (this.config && this.config.data && this.config.data._orgSaleDoc) {
            this.config.data._orgSaleDoc.deliveryDate = this.saleDoc.deliveryDate as unknown as Date;
          }
          this.messageService.add(this.alertMessagesService.getMessage("save-success"));
        },
        error: (error) => {
          this.footerFormGroup.reset(this.saleDoc);
          this.messageService.add(this.alertMessagesService.getErrorMessage(error));
        },
      });
  }

  onFulfillmetStatusChanged() {
    if (this.footerFormGroup.get("fulfillmentStatus").dirty) {
      switch (this.footerFormGroup.get("fulfillmentStatus").value) {
        case FulfillmentStatus.completed:
          this.confirmationService.confirm({
            header: "Confirmation",
            message:
              "Once this order is marked as fulfilled, it will not be possible to make further edits. Are you sure you want to proceed?",
            acceptLabel: "Yes",
            rejectLabel: "No",
            rejectVisible: true,
            acceptVisible: true,
            rejectButtonStyleClass: "p-button-link",
            accept: () => {
              this.allUpcomingOrdersService
                .patchRow("saleDoc", {
                  id: this.saleDoc.id,
                  fulfillmentStatus: this.footerFormGroup.get("fulfillmentStatus").value,
                  deliveryStatus: DeliveryStatus.readyForPickup,
                })
                .subscribe({
                  next: () => {
                    this.saleDoc.fulfillmentStatus = this.footerFormGroup.get("fulfillmentStatus").value;
                    this.saleDoc.deliveryStatus = DeliveryStatus.readyForPickup;
                    this.footerFormGroup.get("deliveryStatus").setValue(DeliveryStatus.readyForPickup);
                    if (this._saleChannelOrder) {
                      this._saleChannelOrder.fulfillmentStatus = this.saleDoc
                        .fulfillmentStatus as unknown as SaleChannelFulfillmentStatus;
                      this._saleChannelOrder.deliveryStatus = this.saleDoc
                        .deliveryStatus as unknown as SaleChannelDeliveryStatus;
                      // this._saleChannelOrder.deliveryStatus = SaleChannelDeliveryStatus.readyForPickup;
                    }
                    if (this.config && this.config.data && this.config.data._orgSaleDoc) {
                      this.config.data._orgSaleDoc.fulfillmentStatus = this.saleDoc
                        .fulfillmentStatus as unknown as FulfillmentStatus;
                      this.config.data._orgSaleDoc.deliveryStatus = this.saleDoc
                        .deliveryStatus as unknown as DeliveryStatus;
                      // this.config.data._orgSaleDoc.deliveryStatus = DeliveryStatus.readyForPickup;
                    }
                    this.enum_saledoc_delivery_status = this.getDeliveryStatusBasedOnFulfillmentStatus();
                    this.messageService.add(this.alertMessagesService.getMessage("save-success"));
                  },
                  error: (error) => {
                    this.footerFormGroup.reset(this.saleDoc);
                    this.messageService.add(this.alertMessagesService.getErrorMessage(error));
                  },
                });
            },
            reject: () => {
              this.footerFormGroup.get("fulfillmentStatus").reset(this.saleDoc.fulfillmentStatus);
              return;
            },
          });

          break;
        case FulfillmentStatus.backToStock:
          this.allUpcomingOrdersService
            .patchRow("saleDoc", {
              id: this.saleDoc.id,
              fulfillmentStatus: this.footerFormGroup.get("fulfillmentStatus").value,
              deliveryStatus: DeliveryStatus.returned,
            })
            .subscribe({
              next: () => {
                this.saleDoc.fulfillmentStatus = this.footerFormGroup.get("fulfillmentStatus").value;
                this.saleDoc.deliveryStatus = DeliveryStatus.returned;
                this.footerFormGroup.get("deliveryStatus").setValue(DeliveryStatus.returned);
                if (this._saleChannelOrder) {
                  this._saleChannelOrder.fulfillmentStatus = this.saleDoc
                    .fulfillmentStatus as unknown as SaleChannelFulfillmentStatus;
                  this._saleChannelOrder.deliveryStatus = this.saleDoc
                    .deliveryStatus as unknown as SaleChannelDeliveryStatus;
                  // this._saleChannelOrder.deliveryStatus = SaleChannelDeliveryStatus.returned;
                }
                if (this.config && this.config.data && this.config.data._orgSaleDoc) {
                  this.config.data._orgSaleDoc.fulfillmentStatus = this.saleDoc
                    .fulfillmentStatus as unknown as FulfillmentStatus;
                  // this.config.data._orgSaleDoc.deliveryStatus = DeliveryStatus.returned;
                }
                this.enum_saledoc_delivery_status = this.getDeliveryStatusBasedOnFulfillmentStatus();
                this.messageService.add(this.alertMessagesService.getMessage("save-success"));
              },
              error: (error) => {
                this.footerFormGroup.reset(this.saleDoc);
                this.messageService.add(this.alertMessagesService.getErrorMessage(error));
              },
            });

          break;
        case FulfillmentStatus.voided:
          this.allUpcomingOrdersService
            .patchRow("saleDoc", {
              id: this.saleDoc.id,
              fulfillmentStatus: this.footerFormGroup.get("fulfillmentStatus").value,
              deliveryStatus: DeliveryStatus.voided,
            })
            .subscribe({
              next: () => {
                this.saleDoc.fulfillmentStatus = this.footerFormGroup.get("fulfillmentStatus").value;
                this.saleDoc.deliveryStatus = DeliveryStatus.voided;
                this.footerFormGroup.get("deliveryStatus").setValue(DeliveryStatus.voided);
                if (this._saleChannelOrder) {
                  this._saleChannelOrder.fulfillmentStatus = this.saleDoc
                    .fulfillmentStatus as unknown as SaleChannelFulfillmentStatus;
                  this._saleChannelOrder.deliveryStatus = this.saleDoc
                    .deliveryStatus as unknown as SaleChannelDeliveryStatus;
                  // this._saleChannelOrder.deliveryStatus = SaleChannelDeliveryStatus.voided;
                }
                if (this.config && this.config.data && this.config.data._orgSaleDoc) {
                  this.config.data._orgSaleDoc.fulfillmentStatus = this.saleDoc
                    .fulfillmentStatus as unknown as FulfillmentStatus;
                  this.config.data._orgSaleDoc.deliveryStatus = this.saleDoc
                    .deliveryStatus as unknown as SaleChannelDeliveryStatus;
                }
                this.enum_saledoc_delivery_status = this.getDeliveryStatusBasedOnFulfillmentStatus();
                this.messageService.add(this.alertMessagesService.getMessage("save-success"));
              },
              error: (error) => {
                this.footerFormGroup.reset(this.saleDoc);
                this.messageService.add(this.alertMessagesService.getErrorMessage(error));
              },
            });

          break;
        case FulfillmentStatus.unfulfilled:
        case FulfillmentStatus.partly_Fulfillment:
        case FulfillmentStatus.processing:
          this.allUpcomingOrdersService
            .patchRow("saleDoc", {
              id: this.saleDoc.id,
              fulfillmentStatus: this.footerFormGroup.get("fulfillmentStatus").value,
              deliveryStatus: DeliveryStatus.pending,
            })
            .subscribe({
              next: () => {
                this.saleDoc.fulfillmentStatus = this.footerFormGroup.get("fulfillmentStatus").value;
                this.saleDoc.deliveryStatus = DeliveryStatus.pending;
                this.footerFormGroup.get("deliveryStatus").setValue(DeliveryStatus.pending);
                if (this._saleChannelOrder) {
                  this._saleChannelOrder.fulfillmentStatus = this.saleDoc
                    .fulfillmentStatus as unknown as SaleChannelFulfillmentStatus;
                  this._saleChannelOrder.deliveryStatus = this.saleDoc
                    .deliveryStatus as unknown as SaleChannelDeliveryStatus;
                  // this._saleChannelOrder.deliveryStatus = SaleChannelDeliveryStatus.pending;
                }
                if (this.config && this.config.data && this.config.data._orgSaleDoc) {
                  this.config.data._orgSaleDoc.fulfillmentStatus = this.saleDoc
                    .fulfillmentStatus as unknown as FulfillmentStatus;
                  this.config.data._orgSaleDoc.deliveryStatus = this.saleDoc
                    .deliveryStatus as unknown as SaleChannelDeliveryStatus;
                }
                this.enum_saledoc_delivery_status = this.getDeliveryStatusBasedOnFulfillmentStatus();
                this.messageService.add(this.alertMessagesService.getMessage("save-success"));
              },
              error: (error) => {
                this.footerFormGroup.reset(this.saleDoc);
                this.messageService.add(this.alertMessagesService.getErrorMessage(error));
              },
            });
          break;
        default:
          break;
      }
    } else {
      this.allUpcomingOrdersService
        .patchRow("saleDoc", {
          id: this.saleDoc.id,
          fulfillmentStatus: this.footerFormGroup.get("fulfillmentStatus").value,
        })
        .subscribe({
          next: () => {
            this.saleDoc.fulfillmentStatus = this.footerFormGroup.get("fulfillmentStatus").value;
            this.saleDoc.deliveryStatus = this.footerFormGroup.get("deliveryStatus").value;
            if (this._saleChannelOrder) {
              this._saleChannelOrder.fulfillmentStatus = this.saleDoc
                .fulfillmentStatus as unknown as SaleChannelFulfillmentStatus;
              this._saleChannelOrder.deliveryStatus = this.saleDoc
                .deliveryStatus as unknown as SaleChannelDeliveryStatus;
            }
            if (this.config && this.config.data && this.config.data._orgSaleDoc) {
              this.config.data._orgSaleDoc.fulfillmentStatus = this.saleDoc
                .fulfillmentStatus as unknown as FulfillmentStatus;
              this.config.data._orgSaleDoc.deliveryStatus = this.saleDoc.deliveryStatus as unknown as DeliveryStatus;
            }
            this.enum_saledoc_delivery_status = this.getDeliveryStatusBasedOnFulfillmentStatus();
            this.messageService.add(this.alertMessagesService.getMessage("save-success"));
          },
          error: (error) => {
            this.footerFormGroup.reset(this.saleDoc);
            this.messageService.add(this.alertMessagesService.getErrorMessage(error));
          },
        });
    }
  }

  onDeliveryMethodChanged() {
    this.allUpcomingOrdersService
      .patchRow("saleDoc", { id: this.saleDoc.id, deliveryMethod: this.footerFormGroup.get("deliveryMethod").value })
      .subscribe({
        next: () => {
          this.saleDoc.deliveryMethod = this.footerFormGroup.get("deliveryMethod").value;
          if (this._saleChannelOrder) {
            this._saleChannelOrder.deliveryMethod = this.saleDoc.deliveryMethod as unknown as DeliveryMethod;
          }
          if (this.config && this.config.data && this.config.data._orgSaleDoc) {
            this.config.data._orgSaleDoc.deliveryMethod = this.saleDoc.deliveryMethod as unknown as DeliveryMethod;
          }
          this.messageService.add(this.alertMessagesService.getMessage("save-success"));
        },
        error: (error) => {
          this.footerFormGroup.reset(this.saleDoc);
          this.messageService.add(this.alertMessagesService.getErrorMessage(error));
        },
      });
  }

  onDeliveryStatusChanged() {
    const patchObj = { id: this.saleDoc.id, deliveryStatus: this.footerFormGroup.get("deliveryStatus").value };

    if (
      this.footerFormGroup.get("deliveryStatus").value === DeliveryStatus.pickedUp &&
      this.footerFormGroup.get("fulfillmentStatus").value !== FulfillmentStatus.completed
    ) {
      this.footerFormGroup.get("fulfillmentStatus").setValue(FulfillmentStatus.completed);
      this.saleDoc.fulfillmentStatus = FulfillmentStatus.completed;
      patchObj["fulfillmentStatus"] = FulfillmentStatus.completed;
    }

    this.allUpcomingOrdersService.patchRow("saleDoc", patchObj).subscribe({
      next: () => {
        this.saleDoc.deliveryStatus = this.footerFormGroup.get("deliveryStatus").value;
        if (this._saleChannelOrder) {
          this._saleChannelOrder.deliveryStatus = this.saleDoc.deliveryStatus as unknown as SaleChannelDeliveryStatus;
        }
        if (this.config && this.config.data && this.config.data._orgSaleDoc) {
          this.config.data._orgSaleDoc.deliveryStatus = this.saleDoc.deliveryStatus as unknown as DeliveryStatus;
        }
        if (this.saleDoc.deliveryStatus === DeliveryStatus.pickedUp) {
          if (this.config?.data?._orgSaleDoc) {
            this.config.data._orgSaleDoc.fulfillmentStatus = this.saleDoc.fulfillmentStatus;
          }
          this.enum_saledoc_delivery_status = this.getDeliveryStatusBasedOnFulfillmentStatus();
        }

        this.messageService.add(this.alertMessagesService.getMessage("save-success"));
      },
      error: (error) => {
        this.footerFormGroup.reset(this.saleDoc);
        this.messageService.add(this.alertMessagesService.getErrorMessage(error));
      },
    });
  }

  onVoidPressed() {
    const saleDocCopy = _.cloneDeep(this.saleDoc);
    const dialog = this.dialogService.open(SaledocVoidDialogComponent, {
      showHeader: false,
      data: {
        saleDoc: saleDocCopy,
      },
    });
    this.subsList.push(
      dialog.onClose.subscribe((_result) => {
        if (_result.success) {
          this.voidSalechannelOrder();
        }
      })
    );
  }

  private voidSalechannelOrder() {
    if (this._saleChannelOrder) {
      this.saleChannelOrdersService
        .editSaleChannelOrder(this._saleChannelOrder, {
          paymentStatus: "CANCELLED",
          fulfillmentStatus: "WILL_NOT_DELIVER",
        })
        .subscribe((result) => {
          this._saleChannelOrder.orderStatus =
            this._saleChannelOrder.paymentStatus === SaleChannelPaymentStatus.unpaid
              ? OrderStatus.voided
              : OrderStatus.pendingRefund;
          this._saleChannelOrder.paymentStatus = SaleChannelPaymentStatus.voided;
          this._saleChannelOrder.fulfillmentStatus = SaleChannelFulfillmentStatus.voided;
          this._saleChannelOrder.deliveryStatus = SaleChannelDeliveryStatus.voided;
          this.allUpcomingOrdersService.editRow("saleChannelOrder", this._saleChannelOrder).subscribe(() => {
            if (this.config?.data?._orgSaleDoc) {
              this.config.data._orgSaleDoc.fulfillmentStatus = FulfillmentStatus.voided;
            }

            this.allUpcomingOrdersService.ref.close(this._saleChannelOrder);
          });
        });
    } else {
      this.dialogClosed.emit();
      this.changedForm.emit(this.saleDoc);
      if (this.ref) {
        this.ref.close(this.saleDoc);
      }
    }
  }

  onEmailDoc(printSize: ReceiptPrintingSize) {
    this.subsList.push(
      this.printMgr
        .sendInvoiceEmail(this.saleDoc, this.emailAddressCtrl.value, this.printPreviewHost, printSize)
        .subscribe({
          next: (response) => {
            // Do nothing for NOW
          },
        })
    );
  }

  openFullSizeDialog(dialog: DialogName, extra = null) {
    this._activeFullDialog = dialog;
    this._activeFullDialogExtra = extra;
  }

  get noCashTenders() {
    return this.saleDoc.saleDocTenders.filter((tender) => tender.tenderType.type !== Tender_Type.Cash);
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }

  /**
   * Get delivery status based on fulfillment status
   * @returns this.enum_saledoc_delivery_status : SelectItem[]
   */
  getDeliveryStatusBasedOnFulfillmentStatus(): SelectItem[] {
    if (this.saleDoc.fulfillmentStatus == FulfillmentStatus.completed) {
      return this.allUpcomingOrdersService.enumSelectOptions(DeliveryStatus).map((item) => {
        if ([DeliveryStatus.returned, DeliveryStatus.voided].includes(item.value)) {
          item.disabled = true;
          item.icon = "pi pi-lock";
        }
        return item;
      });
    } else {
      return this.allUpcomingOrdersService.enumSelectOptions(DeliveryStatus);
    }
  }

  copyEmailToClipboard(email: string): void {
    this.clipboard.copy(email);
  }

  openCustomerDetails() {
    this.config.data.account = this.saleDoc.commercialAccount || this.saleDoc.personalAccount;
    this.dialogService.open(AccountDialogWrapperComponent, this.config);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
