/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { Observable, of, Subject, BehaviorSubject } from "rxjs";
import { DBService } from "./services/db.service";
import { map, take, tap } from "rxjs/operators";
import { Zone } from "../core/settings/zone-settings/Zone";
import { Store } from "../core/settings/store-settings/store/store";
import { Station } from "../core/settings/store-settings/station/station";
import { BackOffice } from "../core/settings/backOffice-settings/backOffice/backOffice";
import * as _ from "lodash";
import { BusinessDetail } from "../core/settings/business-settings/business-detail/business-detail";
import { User } from "../core/users/user/user";
import { Cashout } from "../core/cashout/cashout/cashout";
import { StoreSettingCashout } from "../core/settings/store-settings/cashout-settings/CashoutSettings";
import { TenderPrintingSettings } from "src/app/core/document/sale-document/tender-screen/tender-screen.component";
import { AuthService } from "./services/auth.service";

export type LoginType = "Admin" | "BackOffice" | "Store";

class AppSessionSettings {
  zone: Zone;
  store: Store;
  backOffice: BackOffice;
  station: Station;
  user: User;
  cashout: Cashout;
  businessDetails: BusinessDetail;
  loginType: LoginType;

  // zoneSettings: {[key:string]: any};
  storeSettings: { [key: string]: any };
  backOfficeSettings: { [key: string]: any };
  // stationSettings: {[key:string]: any};
  googleShoppingEnabled = false;
}

class AppUserPreferences {
  static readonly STORAGE_KEY = "userPreferences";
  tenderPrintingSettings: TenderPrintingSettings;

  constructor() {
    this.tenderPrintingSettings = null;
  }
}

class AppPersistentSettings {
  accountName: string;
  backOfficeId: number;
  storeId: number;
}

@Injectable({
  providedIn: "root",
})
export class AppSettingsStorageService {
  private appSessionSettings: AppSessionSettings;
  private appPersistentSettings: AppPersistentSettings;
  private appUserPreferences: AppUserPreferences;
  private static readonly TOKEN_STORAGE_KEY = "appSettings";
  cashoutId$: BehaviorSubject<number>;
  activeStoreChanged$: Subject<Store>;
  activeBackOfficeChanged$: Subject<BackOffice>;

  constructor(private dbService: DBService, private auth: AuthService) {
    if (!this.fetchFromPersistentStorage()) {
      this.appPersistentSettings = new AppPersistentSettings();
    }

    if (!this.fetchFromSessionStorage()) {
      this.appSessionSettings = new AppSessionSettings();
      // TODO: This should be called during the login process once user has selected store and station
      // this.initSettings(1, 1, 1, 1);
    }

    if (!this.fetchUserPreferences()) {
      this.appUserPreferences = new AppUserPreferences();
    }

    this.cashoutId$ = new BehaviorSubject(this.getCashoutId());
    this.activeStoreChanged$ = new Subject();
    this.activeBackOfficeChanged$ = new Subject();
    this.auth.logoutSource$.subscribe(() => this.cleanSessionStorage());
  }

  initStorage(
    zone: Zone,
    store: Store,
    station: Station,
    backOffice: BackOffice,
    user: User,
    cashout: Cashout,
    loginType: LoginType
  ) {
    this.setZone(zone);
    this.setStation(station);
    this.setBackoffice(backOffice);
    this.setUser(user);
    this.setCashout(cashout);
    this.setStore(store);
    this.appSessionSettings.loginType = loginType;

    this.cleanSettings();
    this.updateIntoStorage();
  }

  cleanSessionStorage() {
    this.appSessionSettings = new AppSessionSettings();
    this.updateIntoStorage();
  }

  private cleanSettings() {
    Object.assign(this.appSessionSettings, {
      zoneSettings: {},
      storeSettings: {},
      stationSettings: {},
    });
  }

  private updateIntoStorage() {
    sessionStorage.setItem(AppSettingsStorageService.TOKEN_STORAGE_KEY, JSON.stringify(this.appSessionSettings));

    localStorage.setItem(AppSettingsStorageService.TOKEN_STORAGE_KEY, JSON.stringify(this.appPersistentSettings));
  }

  private updatePreferencesIntoStorage() {
    sessionStorage.setItem(AppUserPreferences.STORAGE_KEY, JSON.stringify(this.appUserPreferences));
  }

  private fetchFromSessionStorage() {
    try {
      this.appSessionSettings = JSON.parse(sessionStorage.getItem(AppSettingsStorageService.TOKEN_STORAGE_KEY));
      return !!this.appSessionSettings;
    } catch (e) {
      return false;
    }
  }

  private fetchUserPreferences() {
    try {
      this.appUserPreferences = JSON.parse(sessionStorage.getItem(AppUserPreferences.STORAGE_KEY));
      return !!this.appUserPreferences;
    } catch (e) {
      return false;
    }
  }

  public static getLoggedInAccount(): AppPersistentSettings {
    return JSON.parse(localStorage.getItem(AppSettingsStorageService.TOKEN_STORAGE_KEY));
  }

  private fetchFromPersistentStorage() {
    try {
      this.appPersistentSettings = JSON.parse(localStorage.getItem(AppSettingsStorageService.TOKEN_STORAGE_KEY));
      return !!this.appPersistentSettings;
    } catch (e) {
      return false;
    }
  }

  private setZone(zone) {
    this.appSessionSettings.zone = zone;
    // TODO: Fetch settings from server
  }

  private setStore(store) {
    this.appSessionSettings.store = store;
    this.activeStoreChanged$.next(store);
    // TODO: Fetch settings from server
    this.appPersistentSettings.storeId = store.id;
    this.updateIntoStorage();
  }

  getPersistentStoreId() {
    return this.appPersistentSettings.storeId;
  }

  getPersistentBackOfficeId() {
    return this.appPersistentSettings.backOfficeId;
  }

  private setBackoffice(backOffice) {
    this.appSessionSettings.backOffice = backOffice;
    this.activeBackOfficeChanged$.next(backOffice);
    // TODO: Fetch settings from server
    this.appPersistentSettings.backOfficeId = backOffice ? backOffice.id : 0;
    this.updateIntoStorage();
  }

  private setStation(station) {
    this.appSessionSettings.station = station;
    this.updateIntoStorage();
  }

  private setUser(user) {
    this.appSessionSettings.user = user;
    // TODO: Fetch settings from server
  }

  public setCashout(cashout) {
    // Cashout can be changed afterward when opening cashout
    this.appSessionSettings.cashout = cashout;
    this.cashoutId$.next(this.getCashoutId());
    this.updateIntoStorage();
  }

  public setGoogleShoppingStatus(isEnabled) {
    this.appSessionSettings.googleShoppingEnabled = isEnabled;
    this.updateIntoStorage();
  }

  getLoginType() {
    return this.appSessionSettings.loginType;
  }

  isAdminLogin() {
    return this.getLoginType() === "Admin";
  }

  isBackOfficeLogin() {
    return this.getLoginType() === "BackOffice";
  }

  isStoreLogin() {
    return this.getLoginType() === "Store";
  }

  getZone(): Zone {
    return this.appSessionSettings.zone;
  }

  getStore() {
    return this.appSessionSettings.store;
  }

  getBackoffice() {
    return this.appSessionSettings.backOffice;
  }

  getStation() {
    return this.appSessionSettings.station;
  }

  getFiscalYearId() {
    return 1;
  }

  getUser() {
    return _.merge(new User(), this.appSessionSettings.user);
  }

  getCashout() {
    return this.appSessionSettings.cashout ? _.merge(new Cashout(), this.appSessionSettings.cashout) : null;
  }

  getZoneId() {
    return this.appSessionSettings.zone ? this.appSessionSettings.zone.id : 0;
  }

  getStoreId() {
    return this.appSessionSettings.store ? this.appSessionSettings.store.id : 0;
  }

  getBackofficeId() {
    return this.appSessionSettings.backOffice ? this.appSessionSettings.backOffice.id : 0;
  }

  getStationId() {
    return this.appSessionSettings.station ? this.appSessionSettings.station.id : 0;
  }

  getUserId() {
    return this.appSessionSettings.user ? this.appSessionSettings.user.id : 0;
  }

  getCashoutId(): number {
    return this.appSessionSettings.cashout ? this.appSessionSettings.cashout.id : 0;
  }

  getGoogleShoppingEnabled(): boolean {
    return this.appSessionSettings.googleShoppingEnabled;
  }

  getStoreSettings<T = any>(settingsModel: string, isMultiple = false): Observable<T> {
    return this.getSettingsByStoreId(settingsModel, this.getStoreId(), isMultiple);
  }

  private _mapAfterFetch(settingsModel: string, results: any) {
    switch (settingsModel) {
      case "storeSettingCashout":
        (results as StoreSettingCashout).storeSettingCashoutOpeningFloats.sort((a, b) => a.amount - b.amount);
        return results;
    }
    return results;
  }

  getSettingsByStoreId(settingsModel: string, storeId: number, isMultiple = false): Observable<any> {
    const storeSettings = this.appSessionSettings.storeSettings || {};
    if (storeSettings[settingsModel]) return of(storeSettings[settingsModel]);

    const filter = {};
    filter["storeId"] = {
      value: storeId,
      matchMode: "equals",
    };

    return this.dbService.getRows(settingsModel, JSON.stringify(filter)).pipe(
      take(1),
      map((myObjects) => this.processResponse(myObjects, isMultiple)),
      map((myObjects) => this._mapAfterFetch(settingsModel, myObjects)),
      tap((settings) => {
        storeSettings[settingsModel] = settings;
        this.updateIntoStorage();
      })
    );
  }

  async isSaleChannelConnected(storeId, ChannelType) {
    const result = await this.getSaleChannelsByStoreId(storeId, ChannelType).toPromise();
    return result ? false : result.rows[0].isConnected;
  }

  getSaleChannelsByStoreId(storeId, channelType): Observable<any> {
    const filter = {};
    filter["storeId"] = {
      value: storeId,
      matchMode: "equals",
    };
    filter["channelType"] = {
      value: channelType,
      matchMode: "equals",
    };
    return this.dbService.getRows("saleChannel", JSON.stringify(filter)).pipe(
      take(1),
      map((myObjects) => {
        return myObjects.count === 0 ? {} : myObjects.rows[0];
      })
    );
  }

  async isTAKUPayAccountExists() {
    const activeFilter = {
      isActive: { matchMode: "equals", value: true },
    };
    const result = await this.dbService.getRows("takuPaymentAccount", JSON.stringify(activeFilter)).toPromise();
    return result ? false : result.rows[0].isConnected;
  }

  getUserPreferences(): AppUserPreferences {
    return this.appUserPreferences;
  }

  updateTenderPrintSettings(settings: TenderPrintingSettings) {
    this.appUserPreferences.tenderPrintingSettings = settings;
    this.updatePreferencesIntoStorage();
  }

  getBusinessDetails(): Observable<BusinessDetail> {
    if (this.appSessionSettings.businessDetails) return of(this.appSessionSettings.businessDetails);

    return this.dbService.getRow("businessDetail", 1).pipe(
      tap((data: BusinessDetail) => {
        this.updateBusinessDetails(data);
      })
    );
  }

  getKioskStoreSettingDetails(): Observable<any> {
    return this.getStoreSettings("storeSettingKiosk");
    // return this.dbService.getRow('storeSettingKiosk', 1).pipe(
    //   // create KioskSettings class later for the type?
    //   tap((data) => {
    //     // console.log(data);
    //     this.updateKioskStoreSettingDetails(data);
    //   })
    // );
  }

  updateKioskStoreSettingDetails(data) {
    // create KioskSettings class later for the type?
    this.appSessionSettings.storeSettings.storeSettingKiosk = data;
    this.updateIntoStorage();
  }

  updateBusinessDetails(businessDetail: BusinessDetail) {
    this.appSessionSettings.businessDetails = businessDetail;
    this.updateIntoStorage();
  }

  patchStoreSettings(settingsModel, settings) {
    this.appSessionSettings.storeSettings[settingsModel] = settings;
    this.updateIntoStorage();
  }

  updateStore(storeData: Store) {
    this.appSessionSettings.store = storeData;
    this.appSessionSettings.zone = storeData.zone || this.getZone();
    this.updateIntoStorage();
    this.activeStoreChanged$.next(storeData);
  }

  updateBackOffice(backOfficeData: BackOffice) {
    this.appSessionSettings.backOffice = backOfficeData;
    this.appSessionSettings.zone = backOfficeData.zone || this.getZone();
    this.updateIntoStorage();
    this.activeBackOfficeChanged$.next(backOfficeData);
  }

  updateStation(stationData: Station) {
    this.appSessionSettings.station = stationData;
    this.updateIntoStorage();
  }

  updateUser(userData: User) {
    this.appSessionSettings.user = userData;
    this.updateIntoStorage();
  }

  updateZone(zoneData: Zone) {
    this.appSessionSettings.zone = zoneData;
    if (this.appSessionSettings.store.zoneId === zoneData.id) this.appSessionSettings.store.zone = zoneData;

    this.updateIntoStorage();
  }

  // Set's the account address name
  setAccountName(accountName: string) {
    // if this is the same account, do nothing
    if (this.getAccountName() === accountName) return;

    this.appPersistentSettings.accountName = accountName;
    this.cleanSessionStorage();
  }

  getAccountName() {
    return this.appPersistentSettings.accountName;
  }

  private processResponse(myObjects, isMultiple: boolean): any {
    if (myObjects.rows.length === 0) {
      return isMultiple ? [] : {};
    } else {
      return isMultiple ? myObjects.rows : myObjects.rows[0];
    }
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
