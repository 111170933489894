/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { formatDate } from "@angular/common";
import {
  AfterViewInit,
  Component,
  Inject,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MenuItem, MessageService, SelectItem } from "primeng/api";
import { Observable, of, Subscription } from "rxjs";
import { Col, RowSelectionType } from "src/app/form-list/form-list/form-list";
import { FormListComponent } from "src/app/form-list/form-list/form-list.component";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import * as moment from "moment";
import * as _ from "lodash";
import { CalendarDateRangeType } from "src/app/taku-ui/taku-calendar-range/taku-calendar-range.component";
import { environment } from "src/environments/environment";
import {
  PrintingFactoryService,
  PrintingMgr,
  ReceiptPrintingSize,
} from "src/app/shared/services/printing-service.service";
import { AllUpcomingOrdersService } from "./all-upcoming-orders.service";
import {
  DeliveryStatus,
  FulfillmentStatus,
  PaymentStatus,
  SaleDoc,
} from "../../document/sale-document/sale-doc/sale-doc";
import { DocState, SaleDocType } from "../../document/doc/doc";
import { distinctUntilChanged } from "rxjs/operators";

@Component({
  selector: "taku-all-upcoming-orders",
  templateUrl: "./all-upcoming-orders.component.html",
  styleUrls: ["./all-upcoming-orders.component.scss"],
})
export class AllUpcomingOrdersComponent implements AfterViewInit, OnDestroy {
  private static readonly DATE_FORMAT = "yyyy-MM-dd";
  readonly maxDateValue = moment().add(100, "years").toDate();
  subsList: Subscription[] = [];
  protected webUrl = environment.apiUrl;
  ReceiptPrintingSize = ReceiptPrintingSize;
  _isFetchingData = false;
  readonly defaultActiveTabIdx = 1; // Default to "Open" tab item. doesn't ever change.
  private _activeTabId = "open"; // Default to "Open" tab item. Changes on Tab menu change
  private onTabMenuChange = (event?: any) => {
    this._activeTabId = event.item.id;
    this.searchOrders();
  };
  readonly tabMenuItems: MenuItem[] = [
    { id: "all", label: "All", command: this.onTabMenuChange },
    { id: "open", label: "Open", command: this.onTabMenuChange },
    { id: "closed", label: "Closed", command: this.onTabMenuChange },
  ];

  @ViewChild("formList", { static: false }) formListComponent: FormListComponent;

  lookup_stores$: Observable<SelectItem[]> = of([]);
  isAdmin: boolean;

  enum_delivery_methods = this.allUpcomingOrdersService.enum_delivery_methods;
  enum_payment_statuses = this.allUpcomingOrdersService.enumSelectOptions(PaymentStatus);
  enum_order_statuses = this.allUpcomingOrdersService.enum_order_statuses;
  enum_fulfillment_statuses = this.allUpcomingOrdersService.enumMultiselectOptions(FulfillmentStatus);
  enum_delivery_statuses = this.allUpcomingOrdersService.enumMultiselectOptions(DeliveryStatus);
  enum_saleDocSource = this.allUpcomingOrdersService.enum_saleDocSource;

  upcomingOrderColumns: Col[] = [];
  searchForm: UntypedFormGroup;
  headerFormGroup: UntypedFormGroup;
  formFilter: {} = null;
  defaultSearchFormValues: any;
  // showFormList: boolean;
  RowSelectionType = RowSelectionType;
  activeAccountName: string;
  printMgr: PrintingMgr;
  @ViewChild("printPreviewHost", { read: ViewContainerRef, static: true }) printPreviewHost: ViewContainerRef;

  constructor(
    private allUpcomingOrdersService: AllUpcomingOrdersService,
    private fb: UntypedFormBuilder,
    @Inject(LOCALE_ID) private defaultLocale,
    private appSettingsService: AppSettingsStorageService,
    private printingMgrFactory: PrintingFactoryService,
    private messageService: MessageService
  ) {
    const activeStore = this.appSettingsService.getStore();
    this.isAdmin = !activeStore.id;
    this.lookup_stores$ = this.allUpcomingOrdersService.lookup_stores();
    this.searchForm = this.fb.group(this.createDefaultSearchFormValues());
    this.defaultSearchFormValues = this.searchForm.value;
    if (!activeStore.id) {
      this.searchForm.get("storeId").disable();
    }
  }

  ngAfterViewInit(): void {
    this._afterViewInitCompleteAsync();
  }

  private _afterViewInitCompleteAsync(): void {
    void Promise.resolve().then(() => {
      this._isFetchingData = true;
      // this.upcomingOrderColumns = this.allUpcomingOrdersService.getFormListColumns(this.printDocumentEvent);
      this.upcomingOrderColumns = this.allUpcomingOrdersService.getFormListColumns({
        printItems: this.getPrintItems.bind(this),
        paymentItems: this.getPaymentItems.bind(this),
      });
      if (this.formListComponent) {
        this.formListComponent.unSelectAllRows();
      }

      this.searchOrders();

      this._isFetchingData = false;

      this.subsList.push(
        this.searchForm.valueChanges.subscribe((changes) => {
          this.searchOrders();
        })
      );

      this.subsList.push(
        this.allUpcomingOrdersService.previewPopupClosedEvent$.subscribe(() => {
          this.formListComponent.reloadData();
        })
      );
    });
  }

  printPackingList(saleDoc: SaleDoc, printSize: ReceiptPrintingSize): void {
    if (saleDoc) {
      this.printMgr = this.printingMgrFactory.build(saleDoc.storeId);
      this.subsList.push(
        this.printMgr.isReady.subscribe({
          next: () => {
            this.allUpcomingOrdersService.getRow("saleDoc", saleDoc.id).subscribe((_saleDoc: SaleDoc) => {
              const _tempSaleDoc: SaleDoc = Object.assign({}, _saleDoc);
              _tempSaleDoc.saleDocLines.map((_saleDocLine) => {
                _saleDocLine.unitPrice = 0;
                _saleDocLine.unitCost = 0;
                _saleDocLine.salePrice = 0;
              });
              _tempSaleDoc.subTotal = 0;
              _tempSaleDoc.shipperChargeAmount = 0;
              _tempSaleDoc.discountAmount = 0;
              _tempSaleDoc.saleDocLines.map((_saleDocLine) => {
                _saleDocLine.saleDocLineTaxes = [];
              });
              _tempSaleDoc.saleDocTenders = [];
              _tempSaleDoc.cashRounding = 0;
              _tempSaleDoc.saleDocTaxes = [];
              _tempSaleDoc.totalLineTax = 0;
              // this.printMgr = this.printingMgrFactory.build(_saleDoc.storeId);
              _tempSaleDoc["isPackingList"] = true;
              this.printMgr.printDocumentsWithSize([_tempSaleDoc], this.printPreviewHost, printSize);
            });
          },
          error: (error) => {
            alert("Error: Can't print because Receipt Builder Settings are not available");
          },
        })
      );
    }
  }

  clearSearch(): void {
    this._isFetchingData = true;
    if (this.formListComponent) {
      this.formListComponent.unSelectAllRows();
    }
    this.searchForm.reset(this.defaultSearchFormValues);
    this._isFetchingData = false;
  }

  createDefaultSearchFormValues(): Record<string, unknown> {
    const dateFrom = moment().subtract(31, "days").toDate();
    const dateTo = moment().add(100, "years").toDate();
    const _storeId = this.appSettingsService.getStoreId();
    return {
      dateRangeType: CalendarDateRangeType.CUSTOM,
      dateFrom: dateFrom,
      dateTo: dateTo,
      saleChannelId: -1,
      orderStatus: null,
      paymentStatus: null,
      fulfillmentStatus: [[]],
      deliveryStatus: [[]],
      storeId: this.isAdmin ? -1 : _storeId,
      deliveryMethod: null,
      customerSource: null,
    };
  }

  searchOrders(): void {
    const searchQuery = this.searchForm.getRawValue();
    const queryObject: Record<string, any> = {};

    // They can't use the tab menu and these three status filters at the same time
    switch (this._activeTabId) {
      case "open":
      case "closed":
        /* passing {emitEvent: false } as we were getting Maximum call stack size exceeded errors from form list component, which caused
         this.searchForm.valueChanges to unsubscribe because of error. */
        this.searchForm.get("fulfillmentStatus").disable({ emitEvent: false });
        this.searchForm.get("paymentStatus").disable({ emitEvent: false });
        this.searchForm.get("deliveryStatus").disable({ emitEvent: false });

        queryObject["isOrderClosed"] = {
          value: this._activeTabId === "closed",
          matchMode: "equals",
        };
        break;
      default:
        this.searchForm.get("fulfillmentStatus").enable({ emitEvent: false });
        this.searchForm.get("paymentStatus").enable({ emitEvent: false });
        this.searchForm.get("deliveryStatus").enable({ emitEvent: false });
        if (searchQuery.paymentStatus) {
          queryObject["paymentStatus"] = { value: searchQuery.paymentStatus, matchMode: "equals" };
        }
        if (searchQuery.fulfillmentStatus.length > 0) {
          queryObject["fulfillmentStatus"] = { value: searchQuery.fulfillmentStatus, matchMode: "in" };
        }
        if (searchQuery.deliveryStatus.length > 0) {
          queryObject["deliveryStatus"] = { value: searchQuery.deliveryStatus, matchMode: "in" };
        }
        break;
    }

    if (searchQuery.dateFrom || searchQuery.dateTo) {
      if (!searchQuery.dateFrom) {
        searchQuery.dateFrom = moment().subtract(31, "days").toDate();
      }

      if (!searchQuery.dateTo) {
        searchQuery.dateFrom = moment().add(100, "years").toDate();
      }

      queryObject["deliveryDate"] = { value: [searchQuery.dateFrom, searchQuery.dateTo], matchMode: "between" };
    }

    if (searchQuery.storeId !== -1) {
      queryObject["storeId"] = { value: searchQuery.storeId, matchMode: "equals" };
    }
    if (searchQuery.saleChannelId !== -1) {
      queryObject["saleChannelId"] = { value: searchQuery.saleChannelId, matchMode: "equals" };
    }
    if (searchQuery.orderStatus) {
      queryObject["orderStatus"] = { value: searchQuery.orderStatus, matchMode: "equals" };
    }
    if (searchQuery.deliveryMethod) {
      queryObject["deliveryMethod"] = { value: searchQuery.deliveryMethod, matchMode: "equals" };
    }
    if (searchQuery.customerSource) {
      queryObject["customerSource"] = { value: searchQuery.customerSource, matchMode: "equals" };
    }

    // queryObject['doc.docType'] = { value: [SaleDocType.online_sales_order, SaleDocType.pre_order, SaleDocType.recurring_order, SaleDocType.sales_invoice], matchMode: 'in' };

    queryObject["doc.state"] = { value: [DocState.approved, DocState.finalized], matchMode: "in" };

    this.formFilter = queryObject;
  }

  getPrintItems(rowData: SaleDoc): MenuItem[] {
    return [
      {
        label: ReceiptPrintingSize.TAPE_SIZE,
        command: () => {
          // console.log(rowData);
          this.printPackingList(rowData, ReceiptPrintingSize.TAPE_SIZE);
        },
        icon: "takuicon-measuring-tape",
      },
      {
        label: ReceiptPrintingSize.LETTER_SIZE,
        command: () => {
          // console.log(rowData);
          this.printPackingList(rowData, ReceiptPrintingSize.LETTER_SIZE);
        },
        icon: "takuicon-invoice",
      },
    ];
  }

  getPaymentItems(rowData: SaleDoc): MenuItem[] {
    return [
      {
        label: '<span class="material-icons">point_of_sale</span>  Open Register',
        command: () => {
          this.printPackingList(rowData, ReceiptPrintingSize.TAPE_SIZE);
        },
        escape: false,
      },
      {
        label: '<span class="material-icons">attach_money</span> Capture Payment',
        command: () => {
          this.printPackingList(rowData, ReceiptPrintingSize.LETTER_SIZE);
        },
        escape: false,
      },
    ];
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
