/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { CanDeactivate, Router } from "@angular/router";
import { ConfirmationService } from "primeng/api";
import { Observable } from "rxjs";
import { ReturnInvoiceDocMainComponent } from "../core/document/return-document/return-invoice-doc-main/return-invoice-doc-main.component";
import { SaleInvoiceDocMainComponent } from "../core/document/sale-document/sale-invoice-doc-main/sale-invoice-doc-main.component";
import { GenericFormComponent } from "../forms/generic-form/generic-form.component";
import { GenericSaleDocComponent } from "../core/document/sale-document/generic-sale-doc.component";

type InvoiceDocComponent = SaleInvoiceDocMainComponent | ReturnInvoiceDocMainComponent;

@Injectable()
export class InvoiceGuard implements CanDeactivate<InvoiceDocComponent> {
  constructor(private confirmationService: ConfirmationService, private _router: Router) {}

  canDeactivate(component: InvoiceDocComponent): boolean | Observable<boolean> {
    if (this._router.getCurrentNavigation()?.extras?.state?.bypassGuards) {
      // Used for auto-logout due to idleness
      return true;
    }
    if (!component.invoiceDocComponent) return true;

    const docComponent: GenericFormComponent = component.invoiceDocComponent.docComponent;
    if (!(docComponent instanceof GenericFormComponent)) {
      return true;
    }
    if (docComponent instanceof GenericSaleDocComponent && docComponent.tenderTotal !== 0) {
      return new Observable<boolean>((observer) => {
        observer.add(() => this.confirmationService.close());
        this.confirmationService.confirm({
          header: "Warning",
          icon: "pi pi-exclamation-triangle",
          message:
            "There are already tenders applied to this transaction. To navigate to a different page, " +
            "you will need to finish this transaction by: <ul>" +
            '<li>clicking <strong><span class="material-icons vertical-align-middle">receipt</span>DONE</strong></li>' +
            '<div class="my-2">OR</div>' +
            '<li><strong><span class="material-icons vertical-align-middle mr-1">undo</span>reversing</strong> the tenders already applied.</li><ul>',
          acceptLabel: "Close",
          accept: () => {
            observer.next(false);
            observer.complete();
          },
          rejectVisible: false,
        });
      });
    }
    if (!docComponent._myForm.dirty) {
      return true;
    }

    return new Observable<boolean>((observer) => {
      observer.add(() => this.confirmationService.close());
      this.confirmationService.confirm({
        header: "Confirmation",
        message: "You have unsaved changes. Are you sure you want to leave this page?",
        rejectButtonStyleClass: "p-button-link",
        accept: () => {
          setTimeout(() => {
            observer.next(true);
            observer.complete();
          }, 0);
        },
        reject: () => {
          observer.next(false);
          observer.complete();
        },
      });
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
