/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
import { Component, OnInit, ElementRef, Output, EventEmitter, ViewChild, Input } from "@angular/core";
import { Subscription } from "rxjs";
import "moment-timezone";
import { environment } from "src/environments/environment";
import type { CardElementProps } from "@adyen/adyen-web/dist/types/components/Card/types";
import type CardElement from "@adyen/adyen-web/dist/types/components/Card";
import { CoreOptions } from "@adyen/adyen-web/dist/types/core/types";
import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";
import { PersonalAccount } from "../personal-account/personal-account";
import { CommercialAccount } from "../commercial-account/commercial-account";
import { isCommercialAccount, isPersonalAccount } from "../account-helpers";
import { Address } from "../address/address";
import { PreAuthUserData } from "./adyen-integration.service";

export class AdyenCardFormState {
  isValid = false;
  fields: PreAuthUserData;
}

type AdyenAddress = Partial<{
  street: string;
  houseNumberOrName: string;
  postalCode: string;
  city: string;
  country: string;
  stateOrProvince: string;
}>;

export type AdyenCardData = {
  paymentMethod?: {
    encryptedCardNumber: number;
    encryptedExpiryMonth: string;
    encryptedExpiryYear: string;
    encryptedSecurityCode: number;
    holderName: string;
  };
  billingAddress: AdyenAddress;
  errors?: Record<string, any>;
  valid?: Record<string, any>;
};

type AdyenPrefillData = {
  holderName: string;
  billingAddress: AdyenAddress;
};

export class AdyenOptionOverrides {
  showPayButton: boolean;
  payButtonTitle: string;
  translations: Record<string, any>;
  constructor(overrides?: AdyenOptionOverrides) {
    this.showPayButton = overrides?.showPayButton ? overrides.showPayButton : false;
    this.payButtonTitle = overrides?.payButtonTitle ? overrides.payButtonTitle : "Pay";
    this.translations = overrides?.translations
      ? overrides.translations
      : {
          "en-US": {
            payButton: this.payButtonTitle,
          },
        };
  }
}

@Component({
  selector: "taku-adyen-integration",
  templateUrl: "./adyen-integration.component.html",
  styleUrls: ["./adyen-integration.component.scss"],
})
export class AdyenIntegrationComponent implements OnInit {
  subsList: Subscription[] = [];
  @ViewChild("adyen-container", { static: true }) adyenContainer: ElementRef;

  private _adyenOptionOverrides: AdyenOptionOverrides = new AdyenOptionOverrides();
  get adyenOptionOverrides(): AdyenOptionOverrides {
    return this._adyenOptionOverrides;
  }
  @Input() set adyenOptionOverrides(overrides: AdyenOptionOverrides) {
    this._adyenOptionOverrides = new AdyenOptionOverrides(overrides);
  }
  @Input() customerAccountData?: PersonalAccount | CommercialAccount;
  @Input() billingAddressOnly = false;

  @Output() adyenCardFormStateEmitter = new EventEmitter<AdyenCardFormState>();
  @Output() adyenFormSubmit = new EventEmitter<AdyenCardFormState>();
  adyenCardFormState: AdyenCardFormState = new AdyenCardFormState();
  clientKey = "";
  checkout: CardElement;

  constructor() {
    this.adyenContainer = new ElementRef("");
  }

  ngOnInit(): void {
    this.clientKey = environment.takuPayClientKey;

    const configuration: CoreOptions = {
      environment: environment.takuPayEnv,
      clientKey: this.clientKey,
      locale: "en_US",
      showPayButton: this.adyenOptionOverrides.showPayButton,
      translations: this.adyenOptionOverrides.translations,
      onChange: (state) => {
        const currFormState = this.getSimplifiedFormState(state);
        this.adyenCardFormState = currFormState;
        this.adyenCardFormStateEmitter.emit(currFormState);
      },
      onError: (error, component) => {
        console.error(error.name, error.message, error.stack, component);
      },
    };

    void this.AdyenCheckout(configuration);
  }

  private getCustomerPrefillData() {
    if (!this.customerAccountData) {
      return undefined;
    }
    const prefill: Partial<AdyenPrefillData> = {
      billingAddress: {},
    };
    let address: Address;
    if (isPersonalAccount(this.customerAccountData) && this.customerAccountData.person) {
      const p = this.customerAccountData.person;
      prefill.holderName = `${p.firstName} ${p.lastName}`;
      if (p.personAddresses.length && p.personAddresses[0].address) {
        address = p.personAddresses[0].address;
      }
    } else if (isCommercialAccount(this.customerAccountData)) {
      prefill.holderName = this.customerAccountData.name;
      const ca = this.customerAccountData.commercialAccountAddresses;
      if (ca.length && ca[0].address) {
        address = ca[0].address;
      }
    }
    if (address) {
      prefill.billingAddress = {
        street: address.line1,
        houseNumberOrName: address.line2,
        postalCode: address.postalCode,
        city: address.city,
        country: address.countryIsoCode,
        // Convert our longer province codes to the last two characters for the province/state
        stateOrProvince:
          address.subDivisionIsoCode?.length > 2 ? address.subDivisionIsoCode.slice(-2) : address.subDivisionIsoCode,
      };
    }
    return prefill;
  }

  async AdyenCheckout(configuration: CoreOptions): Promise<void> {
    await AdyenCheckout(configuration).then((checkout) => {
      const prefillData = this.getCustomerPrefillData();
      // using the "this" keyword inside the .create method below does not refer to the angular class
      // need to store a reference here, so we're for sure using the right/expected "this".
      const thisRef = this;

      // Docs here: https://docs.adyen.com/payment-methods/cards/web-drop-in/#optional-configuration
      const config: CardElementProps = {
        type: "card",
        billingAddressRequired: true,
        billingAddressMode: true,
        hasHolderName: true,
        holderNameRequired: !this.billingAddressOnly,
        data: prefillData,
        // enableStoreDetails: true, // shows checkbox for "save card for future use"
        brands: ["mc", "visa", "amex", "discover"],
        styles: {
          error: {
            color: "red",
          },
          validated: {
            color: "green",
          },
          placeholder: {
            color: "#d8d8d8",
          },
        },
        onError: (data) => {
          console.log(data);
        },
        onSubmit(state, element) {
          const currFormState = thisRef.getSimplifiedFormState(state);
          thisRef.adyenCardFormState = currFormState;
          thisRef.adyenFormSubmit.emit(currFormState);
        },
      };
      this.checkout = checkout.create("card", config).mount("#adyen-container");
    });
  }

  private getSimplifiedFormState(state: {
    data: AdyenCardData;
    isValid: boolean;
    valid: Record<string, boolean>;
  }): AdyenCardFormState {
    return {
      isValid: state.isValid || (this.billingAddressOnly && state.valid.billingAddress),
      fields: PreAuthUserData.fromAdyenData(state.data),
    };
  }
}
