<div class="col-12 statusCounters-wrapper">
  <!-- <span class="refresh-btn"> -->
  <a
    href="javascript://"
    class="pi pi-refresh gmc-status-counter refresh-btn other-btn"
    (click)="refreshProductCounters()"
  ></a>
  <!-- </span> -->

  <a
    class="gmc-status-counter active"
    [routerLink]="[]"
    [queryParams]="{ status: DestinationStatus_Status.ACTIVE }"
    routerLinkActive="statusCounter-active"
  >
    <i>Active</i>
    <!-- <span class="p-message p-message-success"> -->
    <span class="p-message">
      <span class="p-message-text">{{ _productStatistics?.active || "-" }}</span>
    </span>
  </a>

  <a
    class="gmc-status-counter expiring"
    [routerLink]="[]"
    [queryParams]="{ status: DestinationStatus_Status.EXPIRING }"
    routerLinkActive="statusCounter-active"
  >
    <i>Expiring</i>
    <!-- <span class="p-message p-message-warn"> -->
    <span class="p-message">
      <span class="p-message-text">{{ _productStatistics?.expiring || "-" }}</span>
    </span>
  </a>

  <a
    class="gmc-status-counter pending"
    [routerLink]="[]"
    [queryParams]="{ status: DestinationStatus_Status.PENDING }"
    routerLinkActive="statusCounter-active"
  >
    <i>Pending</i>
    <!-- <span class="p-message p-message-info"> -->
    <span class="p-message">
      <span class="p-message-text">{{ _productStatistics?.pending || "-" }}</span>
    </span>
  </a>

  <a
    class="gmc-status-counter disapproved"
    [routerLink]="[]"
    [queryParams]="{ status: DestinationStatus_Status.DISAPPROVED }"
    routerLinkActive="statusCounter-active"
  >
    <i>Disapproved</i>
    <!-- <span class="p-message p-message-error"> -->
    <span class="p-message">
      <span class="p-message-text">{{ _productStatistics?.disapproved || "-" }}</span>
    </span>
  </a>

  <a
    class="gmc-status-counter other-btn"
    [routerLink]="[]"
    [queryParams]="{ status: Filter_StatusAll }"
    routerLinkActive="statusCounter-active"
  >
    <i>All</i>
  </a>
</div>

<div class="col-12">
  <taku-form-list
    *ngIf="_isDataReady && _formListCols"
    [_model]="_model"
    [_dataService]="productsService"
    _title="Google Shopping Products"
    _detailMode="w"
    [_cols]="_formListCols"
    [_modelValidation]="_formListRules"
    [_filter]="_formListFilter"
    [_hideAddHeaderBtn]="true"
    [_hideDeleteHeaderBtn]="true"
    [_hideAddLineHeaderBtn]="true"
    [_extraQueryParams]="_formListQueryParams"
    _scrollHeight="calc(100vh - 31.4rem)"
    [_bulkEditorEnabled]="true"
    [_onCustomizeFetchFilter]="_changeFormListFilter"
    _stateKey="googleShoppingProducts"
  >
    <div takuFormListHeaderCustomBtns>
      <p-splitButton
        icon="pi pi-cloud-upload"
        [disabled]="!_activeGMCAccount || !_activeGMBLocation"
        [label]="isNearIphoneFiveDisplay() ? '' : ' Submit Selection to GMC'"
        (onClick)="!isNearIphoneFiveDisplay() && openSubmitSelectionDialog()"
        [model]="isNearIphoneFiveDisplay() ? gmcPushSelectItemsMobile : gmcPushSelectItems"
      >
      </p-splitButton>
    </div>
  </taku-form-list>
</div>

<ng-template #gmcStatusTpl let-rowData>
  <span class="gmc-status-col" [innerHTML]="rowData.googleStatusFormatted"></span>
</ng-template>

<ng-template #integrationStatusTpl let-rowData let-isInputCell="isInputCell">
  <span
    class="gmc-integrationStatus-attachment"
    [hidden]="
      isInputCell ||
      (!rowData.defaultInventoryStock.qtyOH && rowData.defaultInventoryStock.qtyOH != 0) ||
      rowData.defaultInventoryStock.qtyOH > 0
    "
  >
    <!-- <span class="label">{{rowData.isGMCEnabled ? 'Enabled' : 'Disabled'}}</span> -->
    <i class="pi pi-info-circle" href="javascript://" (click)="gmcIntegrationColPanel.toggle($event)"></i>
    <p-overlayPanel #gmcIntegrationColPanel [showCloseIcon]="true" appendTo="body">
      <div class="gmc-integrationPanel">
        <h4>Integration Tips</h4>
        <ul>
          <li>Any Google Ads for this SKU will only show when stock is > 0</li>
          <li
            >The following fields are required for GMC approval:
            <span class="line2">Product Description, Image URL, Brand</span>
          </li>
          <li>Do not use UPPERCASE in Product Title or Long Description</li>
          <li
            >Additional required fields for Apparel products:
            <span class="line2">Color, Size, Gender, Age Group, Google Product Category</span>
          </li>
        </ul>
      </div>
    </p-overlayPanel>
  </span>
</ng-template>

<ng-template #gmcErrorSummaryTpl let-rowData let-showDetailsEvent="data.showDetailsEvent">
  <div class="gmc-errors-col">
    <span class="summary-text" [innerHTML]="rowData.googleErrorsSummary"></span>
    <span *ngIf="rowData.hasGMCIssues" class="summary-action">
      <button class="p-button-secondary" pButton label="View Details" (click)="showDetailsEvent.emit(rowData)"></button>
    </span>
  </div>
</ng-template>
