<taku-form-header
  [formGroup]="_myForm"
  (backClicked)="goBack()"
  (saveClicked)="onSave()"
  (revertClicked)="onRevert()"
  [toolbarTitle]="pageTitle"
  iconStyleClass="takuicon-refund"
  [showSecondaryBtns]="false"
></taku-form-header>

<div [formGroup]="_myForm">
  <p-card>
    <!-- <ng-template pTemplate="header">
      <i class="icon takuicon-refund"></i> RETURN SETTINGS
    </ng-template> -->

    <div class="grid col-12 lg:col-6 lg:col-offset-1">
      <div class="col-12 taku-listable-container" formArrayName="storeSettingReturnReasons">
        <div class="col-12 taku-listable-header">
          <div class="col-6">
            <h3>Return Reasons</h3>
          </div>
          <a class="grid add-button" (click)="addReturnReason()">
            <label class="col-10">Add New Reason</label>
            <i class="material-icons">add_circle_outline</i>
          </a>
        </div>
        <div class="col-12">
          <div
            >Set-up a predefined list of available reasons for returns.<br />
            They can be associated to each line or the entire order.</div
          >
        </div>
        <div
          class="col-12 taku-listable-controlset"
          *ngFor="let returnReasonForm of _myForm.controls['storeSettingReturnReasons']['controls']; let i = index"
        >
          <div class="col-11">
            <taku-return-reason [formGroup]="returnReasonForm"></taku-return-reason>
          </div>
          <div class="remove-button-container">
            <a (click)="removeReturnReason(i)">
              <i class="material-icons remove-button">remove_circle_outline</i>
            </a>
          </div>
        </div>
      </div>

      <div class="grid col-12" style="padding-left: 15px">
        <h4>Additional Options</h4>
        <div class="col-12 inputctrl-wrapper">
          <i *ngIf="takuPayEnabled" class="pi pi-lock locked-feature mt-0"></i>
          <taku-toggle
            [formGroup]="_myForm"
            fieldName="canReturnWithoutInvoice"
            fieldCaption="Allow returns without an invoice"
            enabledStateLabel="ON"
            disabledStateLabel="OFF"
            [disabled]="takuPayEnabled"
          ></taku-toggle>
        </div>
        <div class="col-12 inputctrl-wrapper">
          <taku-toggle
            [formGroup]="_myForm"
            fieldName="canReturnOnSaleScreen"
            fieldCaption="Allow exchanges on the salesscreen with negative values"
            enabledStateLabel="ON"
            disabledStateLabel="OFF"
          ></taku-toggle>
          <a href="javascript://" class="pi pi-info-circle" (click)="canReturnOnSalescreenInfoPanel.toggle($event)">
            <p-overlayPanel #canReturnOnSalescreenInfoPanel [showCloseIcon]="false" appendTo="body">
              <div class="tooltip-styling">
                <h5>NOTE:</h5>
                <ul>
                  <li>Exchanges made in the Salesscreen are associated with Sales Invoices, not Return Invoices.</li>
                </ul>
              </div>
            </p-overlayPanel>
          </a>
        </div>
        <!-- <span class="setting-note toggle-note">NOTE: Returns made on the salesscreen are not associated to invoices.</span> -->
      </div>
    </div>
  </p-card>
</div>

<!-- {{_myForm.value | json}} -->
