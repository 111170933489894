import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { shareReplay } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { CacheMillisecondsPerModel } from "./cache-models";
import { AuthService } from "../auth.service";
import { ClearCacheService } from "./clear-cache.service";

/** Very short default cache time should hopefully only catch
 *  multiple identical requests being fired from same page. If
 *  you are certain a particular model can be cached longer,
 *  update the `CacheMillisecondsPerModel`
 */
const DEFAULT_CACHE_TIME_MILLISECONDS = 500;

/**
 * Key: the full request URL with params
 * Value: [timestamp, request] tuple
 */
type CachePerModel = Map<string, [number, Observable<HttpEvent<any>>]>;

/**
 * Key: the model/entity name
 * Value: a nested CachePerModel
 */
type Cache = Map<string, CachePerModel>;

/**
 * Only match these patterns of API requests:
 * - /model-list/{model}
 * - /model/{model}
 * - /batch/{model}
 */
const URL_REGEX = new RegExp(`^(?:model-list|model|batch)/(\\w+)`);
const URL_LENGTH = environment.apiUrl.length;

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  private cache: Cache = new Map();
  constructor(authService: AuthService, clearCacheService: ClearCacheService) {
    // Clear cache on logout
    authService.logoutSource$.subscribe(() => (this.cache = new Map()));
    clearCacheService.clearModel$.subscribe((modelName) => this.deleteModel(modelName));
  }

  private deleteModel(modelName: string): void {
    const removedFromCache = this.cache.delete(modelName);
    if (removedFromCache) {
      console.debug("cache cleared", modelName);
    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Do not cache any requests outside our backend
    if (!request.url.startsWith(environment.apiUrl)) {
      return next.handle(request);
    }

    // remove base from string to make regex more efficient
    const urlMinusBase = request.url.substring(URL_LENGTH);
    const match = URL_REGEX.exec(urlMinusBase);
    if (!match) {
      return next.handle(request);
    }

    // Get model name from first capture group in regex
    const model = match[1];
    const paramString = request.params.toString();

    if (request.method === "GET") {
      let cachedPerModel = this.cache.get(model);

      if (!cachedPerModel) {
        cachedPerModel = new Map();
        this.cache.set(model, cachedPerModel);
      } else {
        const cached = cachedPerModel.get(paramString);
        const cacheTime = CacheMillisecondsPerModel[model] ?? DEFAULT_CACHE_TIME_MILLISECONDS;

        // If cached request exists and is not expired, return cached request
        if (cached && Date.now() - cached[0] < cacheTime) {
          // console.debug("cache hit", request.urlWithParams)
          return cached[1];
        }
      }
      // console.debug("cache miss", request.urlWithParams)

      // Cache entire observable process with a shareReplay, so if multiple identical requests come in
      // before the first one has returned, only one request is sent.
      const handled = next.handle(request).pipe(shareReplay(1));
      cachedPerModel.set(paramString, [Date.now(), handled]);
      return handled;
    }

    // TODO handle POST /model-list being used as a GET
    if (["POST", "PUT", "PATCH", "DELETE"].includes(request.method)) {
      // If there is any modifying request, delete all cached data for the whole model
      this.deleteModel(model);
      return next.handle(request);
    }

    return next.handle(request);
  }
}
