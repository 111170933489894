import { Component, OnInit } from "@angular/core";
import { AdyenCardFormState } from "../adyen-integration/adyen-integration.component";
import { AdyenIntegrationService, PreAuthUserData } from "../adyen-integration/adyen-integration.service";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { Account } from "../account/account";

@Component({
  selector: "taku-adyen-integration-dialog-wrapper",
  templateUrl: "./adyen-integration-dialog-wrapper.component.html",
  styleUrls: ["./adyen-integration-dialog-wrapper.component.scss"],
})
export class AdyenIntegrationDialogWrapperComponent implements OnInit {
  account: Account;

  constructor(
    private adyenIntegrationService: AdyenIntegrationService,
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef
  ) {
    this.account = dialogConfig.data.account;
    adyenIntegrationService.dialogRef = dialogRef;
  }

  ngOnInit(): void {}

  closeDialogPressed() {
    this.dialogRef.close();
  }

  submit($event: AdyenCardFormState) {
    const preAuthUserData: PreAuthUserData = {
      card_number: $event.fields.card_number,
      card_month_exp: $event.fields.card_month_exp,
      card_year_exp: $event.fields.card_year_exp,
      card_cvv: $event.fields.card_cvv,
      billing_address: {
        zip: $event.fields.postalCode,
      },
    };

    this.adyenIntegrationService.submitPreAuthorization(this.account, preAuthUserData);
  }
}
