import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormListModel } from "src/app/form-list/form-list/form-list-model.interface";
import { AuthService } from "src/app/shared/services/auth.service";
import { ValidatorMapType } from "src/app/utility/types";
import { InventorySupplierProductDetail } from "./inventory-supplier-product-detail";
import { Validators } from "@angular/forms";
import { Col, DataType, FilterType } from "src/app/form-list/form-list/form-list";
import { DBService } from "src/app/shared/services/db.service";
import { SelectItem } from "primeng/api";
import { Observable } from "rxjs";
import { CurrencyFormatterService } from "src/app/shared/services/currency-formatter.service";

@Injectable({
  providedIn: "root",
})
export class InventorySupplierProductDetailService extends DBService implements FormListModel {
  _model = "inventorySupplierProductDetail";

  enumCurrencies = this.currencyService.getCurrencyCodeList();

  constructor(
    protected currencyService: CurrencyFormatterService,
    protected http: HttpClient,
    protected authService: AuthService
  ) {
    super(http, authService);
  }

  lookupUofMeasures(): Observable<SelectItem[]> {
    return this.lookupSelectOptions("uOfMeasure", "name", { enableFieldName: "isEnabled" });
  }

  getValidationRules(): ValidatorMapType<InventorySupplierProductDetail> {
    return {
      packSize: [Validators.required],
    };
  }

  getFormListColumns(): Col[] {
    const lookupUofMeasures = this.lookupUofMeasures();
    return [
      {
        field: "packSize",
        header: "Pack Size",
        visible: true,
        readonly: true,
        frozen: true,
        dataType: DataType.enum,
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 100,
      },
      {
        field: "skuQty",
        header: "SKU Qty",
        visible: true,
        readonly: false,
        frozen: true,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 80,
      },
      {
        field: "moqPackSize",
        header: "MOQ by Pack Size",
        visible: true,
        readonly: false,
        frozen: true,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 160,
      },
      {
        field: "supplierCode",
        header: "Supplier Code",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 140,
      },
      {
        field: "supplierCostCurrencyIsoCode",
        header: "Default Supplier Currency",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enumCurrencies,
        filterType: FilterType.none,
        colWidth: 120,
      },
      {
        field: "supplierCost",
        header: "Cost",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 160,
      },
      {
        field: "barcode",
        header: "Supplier Barcode",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 160,
      },
      {
        field: "lengthUOfMeasureId",
        fieldValue: "uOfMeasure.name",
        header: "Dimension U of M",
        importingOptions: { header: "Dimension U of M" },
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.lookup,
        dataOptions: lookupUofMeasures,
        filterType: FilterType.lookup,
        colWidth: 130,
        sortFields: ["uOfMeasure.name"],
        isNotSortable: true,
      },
      {
        field: "width",
        header: "Width",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 160,
      },
      {
        field: "CBM",
        header: "CBM",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 160,
      },
      {
        field: "CUFT",
        header: "CUFT",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 160,
      },
      {
        field: "massUOfMeasureId",
        fieldValue: "uOfMeasure.name",
        header: "Weigth U of M",
        importingOptions: { header: "Weigth U of M" },
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.lookup,
        dataOptions: lookupUofMeasures,
        filterType: FilterType.lookup,
        colWidth: 130,
        sortFields: ["uOfMeasure.name"],
        isNotSortable: true,
      },
      {
        field: "weight",
        header: "Weight",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 160,
      },
    ];
  }
}
