/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Role } from "../../role/role";

export class RoleData {
  id: number;
  feature: string;
  caption: string;
  canEdit: boolean;
  canAdd: boolean;
  canDelete: boolean;
  roleId: number;
  role: Role;
  module: ModuleType;

  constructor() {
    this.id = 0;
    this.feature = null;
    this.caption = null;
    this.canEdit = false;
    this.canAdd = false;
    this.canDelete = false;
    this.roleId = null;
    this.role = null;
    this.module = null;
  }
}

export enum ModuleType {
  Accounts = "Accounts",
  BusinessSettings = "Business Settings",
  InventoryManagement = "Inventory Management",
  Marketing = "Marketing",
  Purchasing = "Purchasing",
  SalesOperations = "Sales Operations",
  StationSettings = "Station Settings",
  Stations = "Stations",
  StoreSettings = "Store Settings",
  Stores = "Stores",
  UserManagement = "User Management",
  ZoneSettings = "Zone Settings",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
