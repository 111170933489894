/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from "@angular/core";
import { Inventory } from "../../inventory/inventory/inventory";
import { DBService } from "src/app/shared/services/db.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { Subscription } from "rxjs";

@Component({
  selector: "taku-item-search-res",
  templateUrl: "./item-search-res.component.html",
  styleUrls: ["./item-search-res.component.scss"],
})
export class ItemSearchResComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];
  @Input() results: Inventory[];
  @Output() itemSelected = new EventEmitter<Inventory>();
  @Output() itemViewDetails = new EventEmitter<Inventory>();
  _hightlightedItem = -1;

  isInventoryDetailsVisible = false;
  isInventoryDetailsEnabled = false;

  constructor(private dbService: DBService, private auth: AuthService) {}

  ngOnInit() {
    this.isInventoryDetailsVisible = this.auth.hasVisiblePermit("Inventory_Menu_Inventory_List");
    this.isInventoryDetailsEnabled = this.auth.hasEnablePermit("Inventory_Menu_Inventory_List");
  }

  onSelectItem(item: Inventory, index: number, e: Event) {
    e.stopPropagation();

    this._hightlightedItem = index;
    this.itemSelected.emit(item);
  }

  onClickedViewDetails(item: Inventory, e: Event) {
    e.stopPropagation();
    if (this.isInventoryDetailsEnabled && this.isInventoryDetailsVisible) {
      this.subsList.push(
        this.dbService.getRow("inventory", item.id).subscribe((inventory) => {
          this.itemViewDetails.emit(inventory);
        })
      );
    }
  }

  loadData(event: any) {}

  categoriesNames(item: Inventory): string[] {
    return item.categories.map((cat) => cat.categoryName);
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
