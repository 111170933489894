/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { HttpClient } from "@angular/common/http";
import { Injectable, EventEmitter, ViewChild, Optional } from "@angular/core";
import { ConfirmationService, MenuItem, MessageService, SelectItem } from "primeng/api";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { Observable, Subscription } from "rxjs";
import { tap } from "rxjs/operators";
import {
  Col,
  DataType,
  ExecuteEventDatatypeOptions,
  ExecuteEvent_DisplayMode,
  FilterType,
} from "src/app/form-list/form-list/form-list";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { DBService } from "src/app/shared/services/db.service";
import { LocationService } from "src/app/shared/services/location.service";
import { PrintingFactoryService, PrintingMgr } from "src/app/shared/services/printing-service.service";
import { DeliveryMethod, Commitments, CommitmentsState, CommitmentCycle } from "./commitments";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";
import { FormListComponent } from "src/app/form-list/form-list/form-list.component";
import { GenericFormService } from "src/app/forms/generic-form/generic-form.component";
import { Repeat } from "../sale-doc-line/sale-doc-line";
import { CommitmentsPreviewComponent } from "../commitments-preview/commitments-preview.component";
import { ValidatorFn } from "@angular/forms";
import { SaleDoc } from "../sale-doc/sale-doc";
import { Account } from "src/app/core/contact-accounts/account/account";
import { AccountDialogWrapperComponent } from "src/app/core/contact-accounts/account-dialog-wrapper/account-dialog-wrapper.component";

@Injectable({
  providedIn: "root",
})
export class CommitmentsService extends DBService {
  protected lookupStores: SelectItem[];
  private postOrder: EventEmitter<Commitments> = new EventEmitter();
  @ViewChild("formList") _formListComponent: FormListComponent;

  voidDocumentEvent: EventEmitter<Commitments> = new EventEmitter();
  printDocumentEvent: EventEmitter<Commitments> = new EventEmitter();
  pauseEvent: EventEmitter<Commitments> = new EventEmitter();
  skipEvent: EventEmitter<Commitments> = new EventEmitter();
  voidEvent: EventEmitter<Commitments> = new EventEmitter();
  showAccountInfo: EventEmitter<Account> = new EventEmitter();

  printMgr: PrintingMgr;
  subsList: Subscription[] = [];
  enum_delivery_methods = this.enumSelectOptions(DeliveryMethod);
  enum_payment_statuses = this.enumSelectOptions(CommitmentsState, { useNullAsValue: false });

  get_enumCountries(): SelectItem[] {
    return this.locationService.lookupCountryOptions_full();
  }

  constructor(
    protected http: HttpClient,
    protected authService: AuthService,
    private dialogService: DialogService,
    public ref: DynamicDialogRef,
    private appSettingsService: AppSettingsStorageService,
    protected confirmationService: ConfirmationService,
    private locationService: LocationService,
    private printingMgrFactory: PrintingFactoryService,
    protected alertMessage: AlertMessagesService,
    protected messageService: MessageService,
    @Optional() protected formService?: GenericFormService
  ) {
    super(http, authService);
    this.subsList.push(
      this.voidDocumentEvent.subscribe((saleChannelOrder: Commitments) => {
        // this.openVoidDocDialog(saleDoc)
      })
    );

    this.postOrder.subscribe((commitments: Commitments) => {
      if (commitments.id) {
        this.getRow("commitment", commitments.id).subscribe((_commitments) => {
          this.ref = this.dialogService.open(CommitmentsPreviewComponent, {
            data: {
              _object: _commitments,
              _orgSaleDoc: commitments,
            },
            width: "90%",
            height: "90%",
            closable: false,
            showHeader: false,
          });
        });
      }
      // else {
      //   this.ref = this.dialogService.open(AllUpcomingOrderPreviewComponent, {
      //     data: {
      //       _saleDoc: commitments
      //     },
      //     width: '850px',
      //     height: '650px',
      //     closable: false
      //   });
      //   this.ref.onClose.subscribe((_saleDoc: Commitments) => {
      //     commitments = _saleDoc;
      //     if (commitments && commitments.id) {
      //       setTimeout(() => {
      //         this.ref = this.dialogService.open(CommitmentsPreviewComponent, {
      //           data: {
      //             _object: _saleDoc,
      //           },
      //           width: '850px',
      //           height: '650px',
      //           closable: false
      //         });
      //       }, 200);
      //     }
      //   })
      // }
    });

    this.showAccountInfo.subscribe((saleDoc: SaleDoc) => {
      if (saleDoc.personalAccount || saleDoc.commercialAccount) {
        this.ref = this.dialogService.open(AccountDialogWrapperComponent, {
          data: {
            account: saleDoc.personalAccount || saleDoc.commercialAccount,
            accountType: saleDoc,
          },
          width: "90%",
          height: "90%",
          closable: false,
          showHeader: false,
        });
      }
    });

    this.pauseEvent.subscribe((commitments: Commitments) => {
      this.confirmationService.confirm({
        header: "Confirmation",
        message: `Status of commitment has changed to ${
          commitments.state === CommitmentsState.Paused ? CommitmentsState.Active : CommitmentsState.Paused
        }`,
        acceptLabel: "Yes",
        rejectVisible: true,
        acceptVisible: true,
        rejectButtonStyleClass: "p-button-link",
        accept: () => {
          const status = commitments.state;
          this.subsList.push(
            this.patchRow("commitment", {
              id: commitments.id,
              state: status === CommitmentsState.Paused ? CommitmentsState.Active : CommitmentsState.Paused,
            }).subscribe({
              next: (_response) => {
                commitments.state = _response.state;
              },
              error: (_errorResponse) => {
                this.messageService.add(this.alertMessage.getErrorMessage(_errorResponse));
              },
            })
          );

          if (status === CommitmentsState.Paused) {
            const commitmentCycle = new CommitmentCycle();
            commitmentCycle.commitmentId = commitments.id;
            commitmentCycle.cycleDate = commitments.creationDate;
            commitmentCycle.refSaleDocId = null;
            this.subsList.push(this.addRow("commitmentCycle", commitmentCycle).subscribe());
          }
        },
        reject: () => {},
      });
    });

    this.voidEvent.subscribe((commitments: Commitments) => {
      this.confirmationService.confirm({
        header: "Confirmation",
        message: `Status of commitment has changed to Voided`,
        acceptLabel: "Yes",
        rejectVisible: true,
        acceptVisible: true,
        rejectButtonStyleClass: "p-button-link",
        accept: () => {
          this.patchRow("commitment", { id: commitments.id, state: CommitmentsState.Voided }).subscribe({
            next: (_response) => {
              commitments.state = _response.state;
            },
            error: (_errorResponse) => {
              this.messageService.add(this.alertMessage.getErrorMessage(_errorResponse));
            },
          });
        },
        reject: () => {},
      });
    });

    this.skipEvent.subscribe((commitments: Commitments) => {
      this.confirmationService.confirm({
        header: "Confirmation",
        message: `Skip the next charge. Note that this action cannot be reversed.
          Do you still want to proceed?`,
        acceptLabel: "Yes",
        rejectVisible: true,
        acceptVisible: true,
        rejectButtonStyleClass: "p-button-link",
        accept: () => {
          const commitmentCycle = new CommitmentCycle();
          commitmentCycle.refSaleDocId = null;
          commitmentCycle.commitmentId = commitments.id;
          commitmentCycle.cycleDate = commitments.nextPickupDate;

          this.addRow("commitmentCycle", commitmentCycle).subscribe({
            next: (_response) => {},
            error: (_errorResponse) => {
              this.messageService.add(this.alertMessage.getErrorMessage(_errorResponse));
            },
            complete: () => {
              this.getRow("commitment", commitments.id).subscribe((result) => {
                commitments.nextPickupDate = result.nextPickupDate;
                commitments.nextChargeDate = result.nextChargeDate;
              });
            },
          });
        },
        reject: () => {},
      });
    });
  }

  enumRepeatCycle(row: Commitments): string {
    const count = row.commitmentCycles.filter((cycle) => cycle.refSaleDocId).length;
    let result;
    if (row.repeatCycle === Repeat.YES_ONGOING) {
      result = `${count} / -`;
    } else {
      result = `${count} / ${row.repeatCycle?.split(" ")[0]}`;
    }
    return result;
  }

  lookup_stores(): Observable<SelectItem[]> {
    //get all active stores that are part of an active zone
    const activeFilter = {
      isActive: { matchMode: "equals", value: true },
      "zone.isActive": { matchMode: "equals", value: true },
    };
    return this.lookupSelectOptions("store", "storeName", {
      lookupFilter: JSON.stringify(activeFilter),
      enableFieldName: "isActive",
      emptyRowCaption: "All Stores",
      emptyRowValue: -1,
    });
  }

  lookup_sale_channels(): Observable<SelectItem[]> {
    //get all active stores that are part of an active zone
    const activeFilter = {
      isConnected: { matchMode: "equals", value: true },
      // 'zone.isActive': { matchMode: 'equals', value: true },
    };
    return this.lookupSelectOptions("saleChannel", "channelName", {
      lookupFilter: JSON.stringify(activeFilter),
      enableFieldName: "isConnected",
      emptyRowCaption: "All Sources",
      emptyRowValue: -1,
    });
  }

  addDefaultValueToLookup(items: SelectItem[], defaultMessage: string) {
    items.unshift({
      label: defaultMessage,
      value: 0,
    });

    if (items.length >= 2 && items[1].label == "") {
      //if the second item is the default empty option. We want the empty option to be first.
      //so we swap the first and second items.
      const temp = items[1];
      items[1] = items[0];
      items[0] = temp;
    }
    return items;
  }

  // onFulfillmentStatusChanged(rowData, _orgObjects) {
  //   let orgObject = _orgObjects.find((row) => row.id === rowData.id);
  //   if (orgObject) {
  //     if (rowData.fulfillmentStatus === SaleChannelFulfillmentStatus.completed) {
  //       this.confirmationService.confirm({
  //         header: 'Confirmation',
  //         message: 'Once this order is marked as fulfilled, it will not be possible to make further edits. Are you sure you want to proceed?',
  //         acceptLabel: 'Yes',
  //         rejectLabel: 'No',
  //         rejectVisible: true,
  //         acceptVisible: true,
  //         rejectButtonStyleClass: 'p-button-link',
  //         accept: () => {
  //           rowData.saleDoc.deliveryStatus = DeliveryStatus.readyForPickup;
  //         },
  //         reject: () => {
  //           rowData.fulfillmentStatus = orgObject.fulfillmentStatus;
  //           rowData.deliveryStatus = orgObject.deliveryStatus;
  //           rowData.saleDoc.fulfillmentStatus = orgObject.saledoc.fulfillmentStatus
  //           rowData.saleDoc.deliveryStatus = orgObject.saledoc.deliveryStatus
  //         }
  //       });
  //     } else if (rowData.fulfillmentStatus === SaleChannelFulfillmentStatus.backToStock) {
  //       rowData.saleDoc.deliveryStatus = DeliveryStatus.returned;
  //     } else if (rowData.fulfillmentStatus === SaleChannelFulfillmentStatus.voided) {
  //       rowData.saleDoc.deliveryStatus = DeliveryStatus.voided;
  //     } else if ((rowData.fulfillmentStatus === SaleChannelFulfillmentStatus.unfulfilled || rowData.fulfillmentStatus === SaleChannelFulfillmentStatus.partlyFulfilled || rowData.fulfillmentStatus === SaleChannelFulfillmentStatus.processing)) {
  //       rowData.saleDoc.deliveryStatus = DeliveryStatus.pending;
  //     }
  //   }
  // }

  onPaymentStatusChanged(rowData, _orgObjects) {
    const orgObject = _orgObjects.find((row) => row.id === rowData.id);
    // and user is not admin
    if (orgObject && rowData.state !== orgObject.state && !this.appSettingsService.getUser().isAdmin) {
      rowData.state = orgObject.state;
      this.messageService.add({
        severity: "error",
        summary: "Order is fulfilled",
        detail: "After fulfilling order, payment status can not be changed except by admin",
      });
    }
  }

  onFulfillmentStoreChanged(rowData, _orgObjects) {
    const orgObject = _orgObjects.find((row) => row.id === rowData.id);
    if (orgObject && rowData.storeId !== orgObject.storeId) {
      rowData.storeId = orgObject.storeId;
      this.messageService.add({
        severity: "error",
        summary: "Order is fulfilled",
        detail: "After fulfilling order, store can not be changed",
      });
    }
  }

  onDeliveryMethodChanged(rowData, _orgObjects) {
    const orgObject = _orgObjects.find((row) => row.id === rowData.id);
    if (orgObject) {
      rowData.saleDoc.deliveryMethod = rowData.deliveryMethod;
    }
  }

  // editSaleChannelOrder(_salechannelOrder: Commitments, body: any): Observable<Commitments> {
  //   return this._putRequest<any>(this.webUrl + 'TAKUeCommerceOrderInvoice/' + _salechannelOrder.saleChannelId + '/' + _salechannelOrder.channelOrderId, body);
  // }

  // acceptSaleChannelOrder(_salechannelOrder: Commitments): Observable<SaleDoc> {
  //   return this._postRequest<any>(this.webUrl + 'TAKUeCommerceAcceptOrder/' + _salechannelOrder.saleChannelId + '/' + _salechannelOrder.channelOrderId, {
  //     stationId: this.appSettingsService.getStationId(),
  //     storeId: _salechannelOrder.storeId,
  //     cashoutId: this.appSettingsService.getCashoutId(),
  //     fiscalYearId: this.appSettingsService.getFiscalYearId()
  //   });
  // }

  getPaymentCycle(row, i): string {
    let result;
    if (row.repeatCycle === Repeat.YES_ONGOING) {
      result = `${i} / -`;
    } else {
      result = `${i} / ${row.repeatCycle?.split(" ")[0]}`;
    }
    return result;
  }

  getValidationRules(): {
    [key: string]: {} | ValidatorFn[];
  } {
    return {
      // channelType: [Validators.required],
      // channelName: [Validators.required],
      // storeId: [Validators.required],
    };
  }

  getLookupStores(): Observable<SelectItem[]> {
    return this.lookupSelectOptions("store", "storeName", {
      enableFieldName: "isActive",
    }).pipe(tap((stores: SelectItem[]) => (this.lookupStores = stores)));
  }

  isVoidLinkDisabled(rowData: Commitments) {
    return true; //rowData.doc.state !== DocState.finalized;
  }

  getTakuDocLinkCaption(rowData: Commitments) {
    return rowData.docNo ? rowData.docNo : "View";
  }

  isPrintLinkDisabled(rowData: Commitments) {
    return !rowData;
  }

  isVoidedButtonDisabled(rowData: Commitments) {
    return rowData.state === CommitmentsState.Voided || rowData.state === CommitmentsState.Completed;
  }

  isPauseButtonDisabled(rowData: Commitments) {
    return rowData.state === CommitmentsState.Voided || rowData.state === CommitmentsState.Completed;
  }

  isSkipButtonDisabled(rowData: Commitments) {
    return (
      rowData.state === CommitmentsState.Voided ||
      rowData.state === CommitmentsState.Completed ||
      rowData.state === CommitmentsState.Declined
    );
  }

  lookupActionButtons(rowData: Commitments) {
    if (
      rowData.state == CommitmentsState.Active ||
      rowData.state == CommitmentsState.Completed ||
      rowData.state == CommitmentsState.Voided
    )
      return "pi pi-pause";
    else return "pi pi-play";
  }

  getFormListColumns(
    params?: { paymentItems: (rowData: Commitments) => MenuItem[]; printItems: (rowData: Commitments) => MenuItem[] },
    extraData?: any
    // printDocumentEvent: EventEmitter<Commitments>
    // isAdmin: boolean,
    // clickManageChannelEvent?: EventEmitter<any>
  ): Col[] {
    return <Col[]>[
      {
        field: "docNo",
        header: "Commitment #",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.execute_event,
        dataOptions: [],
        dataTypeOptions: [
          new ExecuteEventDatatypeOptions({
            displayMode: ExecuteEvent_DisplayMode.LINK,
            label: this.getTakuDocLinkCaption,
            event: this.postOrder,
            enabledOnEditMode: false,
            styleClass: "",
          }),
        ],
        filterType: FilterType.contains,
        isNotSortable: false,
        colWidth: 170,
      },
      {
        field: "actionButtons",
        header: "Actions",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.execute_event,
        dataOptions: [],
        dataTypeOptions: [
          new ExecuteEventDatatypeOptions({
            icon: "pi pi-print",
            event: null,
            displayMode: ExecuteEvent_DisplayMode.SPLITBUTTON,
            fnIsRowDisabled: this.isPrintLinkDisabled,
            items: params.printItems,
            styleClass: "",
          }),
          new ExecuteEventDatatypeOptions({
            icon: "pi pi-ban",
            event: this.voidEvent,
            displayMode: ExecuteEvent_DisplayMode.BUTTON,
            fnIsRowDisabled: this.isVoidedButtonDisabled,
            styleClass: "",
          }),
          new ExecuteEventDatatypeOptions({
            icon: this.lookupActionButtons,
            event: this.pauseEvent,
            displayMode: ExecuteEvent_DisplayMode.BUTTON,
            fnIsRowDisabled: this.isPauseButtonDisabled,
            styleClass: "",
          }),
          new ExecuteEventDatatypeOptions({
            icon: "pi pi-step-forward-alt",
            event: this.skipEvent,
            displayMode: ExecuteEvent_DisplayMode.BUTTON,
            fnIsRowDisabled: this.isSkipButtonDisabled,
            styleClass: "",
          }),
        ],
        filterType: FilterType.none,
        isNotSortable: true,
        colWidth: 200,
      },
      {
        field: "state",
        header: "Payment Status",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enum_payment_statuses,
        filterType: FilterType.enum,
        isNotSortable: false,
        onModelChange: this.onPaymentStatusChanged.bind(this),
        classObj: "status-margin",
      },
      {
        field: "creationDate",
        header: "Creation Date",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        isNotSortable: false,
      },
      {
        // Calculated server-side in setDynamicFields
        field: "nextPickupDate",
        header: "Outgoing Date",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        isNotSortable: true,
      },
      {
        // Calculated server-side in setDynamicFields
        field: "nextChargeDate",
        header: "Next Charge Date",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        isNotSortable: true,
      },
      {
        field: "accountName",
        colWidth: "auto",
        header: "Customer",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.execute_event,
        dataOptions: [],
        dataTypeOptions: [
          new ExecuteEventDatatypeOptions({
            displayMode: ExecuteEvent_DisplayMode.LINK,
            label: this.getAccountNameCaption,
            event: this.showAccountInfo,
            enabledOnEditMode: false,
            styleClass: "",
          }),
        ],
        filterType: FilterType.none,
        isNotSortable: true,
      },
      {
        field: "recurringOrderSetting.recurringOrderFrequency",
        header: "Recurring",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: [],
        filterType: FilterType.enum,
        isNotSortable: false,
      },
      {
        field: "repeatCycle",
        header: "Payment Cycle",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.template,
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 150,
        attachTemplate: extraData.repeatCycleTemplateOptions,
      },
      {
        // Calculated client-side using getter
        field: "subTotalCurrencyFormat",
        header: "Order Sub-Total",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.template,
        dataOptions: [],
        filterType: FilterType.none,
        isNotSortable: true,
        colWidth: 180,
        attachTemplate: extraData.orderAmountTemplateOptions,
      },
      {
        // Calculated client-side using getter
        field: "shippingFeesCurrencyFormat",
        header: "Shipping Fees",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
        isNotSortable: true,
      },
      {
        field: "deliveryMethod",
        header: "Delivery Method",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enum_delivery_methods,
        filterType: FilterType.enum,
        isNotSortable: false,
        onModelChange: this.onDeliveryMethodChanged.bind(this),
      },
      {
        field: "storeId",
        header: "Fulfillment Store",
        visible: false,
        readonly: false,
        frozen: false,
        dataType: DataType.lookup,
        dataOptions: this.getLookupStores(),
        filterType: FilterType.lookup,
        sortFields: ["store.storeName"],
        onModelChange: this.onFulfillmentStoreChanged.bind(this),
      },
    ];
  }

  getAccountNameCaption(rowData: SaleDoc): string {
    return rowData ? rowData.accountName : "";
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
