<div id="data-progressbar">
  <p-progressBar
    mode="indeterminate"
    value="0"
    [style.visibility]="reportParamsService._isFetchingData ? 'visible' : 'hidden'"
  >
  </p-progressBar>
</div>

<div id="takuPayoutsReport-topWrapper">
  <taku-form-list
    #formListReport
    [_hideViewCol]="true"
    [_model]="_model"
    [_hideSavingCtrls]="true"
    [_showCtrlsInEditMode]="false"
    [_rowSelectionType]="RowSelectionType.NONE"
    _title="TAKU Pay Payouts Report"
    [_hideAddLineHeaderBtn]="true"
    _detailMode="n"
    _scrollHeight="calc(100vh - 30rem)"
    [_hideOptionsHeaderBtn]="false"
    [_hideFilterControls]="true"
    [_modelService]="reportCols"
    [_dataService]="takuPayoutsReportLocalService"
    [_hideGlobalFilter]="true"
    [_showFooterCurrency]="true"
  >
  </taku-form-list>
</div>
