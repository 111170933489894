import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, MenuItem, MessageService, SelectItem } from "primeng/api";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { GenericFormComponent } from "src/app/forms/generic-form/generic-form.component";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";
import { TakuPaymentGatewayTerminalsService } from "../taku-payment-gateway-terminals/taku-payment-gateway-terminals.service";
import { TakuPaymentTerminal } from "../taku-payment-gateway-terminals/taku-payment-terminal";
import { TakuPayService } from "../taku-pay.service";
import * as _ from "lodash";
import { TakuPaymentGateway } from "../taku-payment-gateways-list/taku-payment-gateway";
import { HttpErrorResponse } from "@angular/common/http";
import deviceTypeList from "../../../../../shared/json/takupay-device-type-list.json";

export class TakuPayTerminalInfo {
  pairingCode: string;
  terminalId: string;
  locationId: string;
  serialNo: string;

  constructor() {
    this.pairingCode = null;
    this.terminalId = null;
    this.locationId = null;
    this.serialNo = null;
  }
}

@Component({
  selector: "taku-taku-pay-terminal",
  templateUrl: "./taku-pay-terminal.component.html",
  styleUrls: ["./taku-pay-terminal.component.scss"],
})
export class TakuPayTerminalComponent extends GenericFormComponent implements OnInit {
  _isPairingOrUnpairing = false;
  _isInitializing = false;
  storeStations$: Observable<SelectItem[]>;
  unpairItems: MenuItem[] = [
    {
      label: "Force Unpair",
      command: (): void => {
        this.forceUnPairTerminal();
      },
    },
  ];
  deviceTypes: SelectItem[] = [];

  constructor(
    protected _router: Router,
    public fb: UntypedFormBuilder,
    protected dbService: TakuPaymentGatewayTerminalsService,
    protected location: Location,
    public _route: ActivatedRoute,
    protected messageService: MessageService,
    protected alertMessage: AlertMessagesService,
    protected confirmationService: ConfirmationService,
    protected appSettingsService: AppSettingsStorageService,
    private takuPayService: TakuPayService
  ) {
    super(
      _router,
      fb,
      dbService,
      location,
      _route,
      messageService,
      alertMessage,
      confirmationService,
      appSettingsService
    );
  }

  get pageTitle() {
    return `${this._object.name || "Terminal Name"}`;
  }

  get infoFormGroup() {
    return this._myForm.get("info") as UntypedFormGroup;
  }

  get terminalIDCtrl() {
    return this.infoFormGroup.get("terminalId");
  }

  initForm() {
    const newTerminal = new TakuPaymentTerminal();
    const terminalInfo = (newTerminal.info = new TakuPayTerminalInfo());
    const paymentGateway = (newTerminal.takuPaymentGateway = new TakuPaymentGateway());
    this._object = newTerminal;
    this._model = "takuPaymentTerminal";
    const formDef = Object.assign({}, newTerminal, {
      info: this.fb.group(Object.assign({}, terminalInfo)),
      takuPaymentGateway: this.fb.group(Object.assign({}, paymentGateway)),
    });
    this._myForm = this.fb.group(formDef);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.storeStations$ = this.dbService.getLookup_storeStations(this._myForm.get("takuPaymentGateway").value?.storeId);
  }

  onSave({ silentMode = false }: { silentMode?: boolean } = {}) {
    this._isSaving = true;
    this.subsList.push(
      this.onSubmit(silentMode).subscribe(() => {
        this._isSaving = false;
      })
    );
  }

  initValidation() {
    this._validation = this.dbService.getValidationRules();
  }

  setFormArrays() {
    if (!this._object.info) this._object.info = new TakuPayTerminalInfo();
  }

  pairTerminal(id: number) {
    this._isPairingOrUnpairing = true;
    this.takuPayService
      .pairTerminal(id)
      .pipe(
        catchError((errorResponse) => {
          this.processServerError(errorResponse, false);
          this._isPairingOrUnpairing = false;
          return throwError(errorResponse);
        }),
        tap((result) => {
          if (result) {
            this._object = result["data"];
            this.resetForm();
            this.messageService.add({
              summary: "Terminal Info",
              severity: result["success"] ? "success" : "error",
              detail: result["message"],
            });
            //this._myForm.markAsDirty();
            //return this.onSubmit().subscribe();
          }
        })
      )
      .subscribe(() => {
        this._isPairingOrUnpairing = false;
      });
  }

  forceUnPairTerminal(): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to force unpairing terminal?",
      accept: () => {
        this._myForm.get("isPaired").setValue(false);
        this._myForm.get("terminalId").setValue(null);
        this._myForm.markAsDirty();
        this.onSubmit().subscribe();
      },
    });
  }

  unPairTerminal(id: number) {
    this._isPairingOrUnpairing = true;
    this.takuPayService
      .unPairTerminal(id)
      .pipe(
        catchError((errorResponse) => {
          this.processServerError(errorResponse, false);
          this._isPairingOrUnpairing = false;
          return throwError(errorResponse);
        }),
        tap((result) => {
          if (result) {
            this._object = result["data"];
            this.resetForm();
            this.messageService.add({
              summary: "Terminal Info",
              severity: result["success"] ? "success" : "error",
              detail: result["message"],
            });
            //this._myForm.markAsDirty();
            //this.onSubmit().subscribe();
          }
        })
      )
      .subscribe(() => {
        this._isPairingOrUnpairing = false;
      });
  }

  initLookups() {}

  protected loadObject(): void {
    this._route.queryParams.subscribe((_queryParams) => {
      if (_queryParams.defaultdataValues) {
        _.merge(this._object, JSON.parse(_queryParams.defaultdataValues));
        this._object.takuPaymentGateway.id = +this._object.takuPaymentGatewayId;
        if (!this._object.takuPaymentGateway.locationId) {
          this.dbService.getRow("takuPaymentGateway", this._object.takuPaymentGatewayId).subscribe((result) => {
            this._object.takuPaymentGateway = result;
            this.deviceTypes = this.dbService.getDeviceTypeList(
              deviceTypeList.filter(
                (item) => item.countryIsoCodes.indexOf(result?.takuPaymentAccount?.countryIsoCode) > -1
              )
            );
            super.loadObject();
          });
        } else {
          super.loadObject();
        }
      } else {
        super.loadObject();
        this.deviceTypes = this.dbService.getDeviceTypeList(
          deviceTypeList.filter(
            (item) =>
              item.countryIsoCodes.indexOf(this._object?.takuPaymentGateway?.takuPaymentAccount?.countryIsoCode) > -1
          )
        );
      }
    });
  }

  onDelete() {
    if (this._object.isPaired && this._object.terminalId) {
      this.removeTakuPayTerminal(this._object.id);
    } else {
      super.onDelete();
    }
  }

  removeTakuPayTerminal(id: number) {
    this.takuPayService
      .unPairTerminal(id)
      .pipe(
        catchError((errorResponse) => {
          this.processServerError(errorResponse, false);
          return throwError(errorResponse);
        }),
        tap((result) => {
          if (result) {
            //this.messageService.add({ severity: 'success', summary: 'Service Message', detail: result.message });
            this.dbService.deleteRow(this._model, this._object.id).subscribe(
              (data) => {
                this.messageService.add(this.alertMessage.getMessage("delete-success"));
                if (this._isDialog) {
                  this.changedForm.emit("Deleted");
                } else {
                  this.goBack();
                }
              },
              (error: HttpErrorResponse) => {
                this.messageService.add(this.alertMessage.getErrorMessage(error));
              },
              () => {}
            );
            return this.onSubmit().subscribe();
          }
        })
      )
      .subscribe(() => {
        return this.onSubmit().subscribe();
      });
  }
}
