/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import * as moment from "moment";
import * as _ from "lodash";
// import { Inventory } from "../core/inventory/inventory/inventory";
import { GMBLocation } from "../core/settings/integration-settings/google-my-business/gmb-locations-list/GMBLocation";
import { ContentAPI_POS } from "../core/settings/integration-settings/google-shopping/gmc-api-data-definitions/ContentAPI_PosInventory";
import { ContentAPI_ProductStatuses } from "../core/settings/integration-settings/google-shopping/gmc-api-data-definitions/ContentAPI_ProductStatutes";
import { GoogleShoppingTitleTemplate, Inventory } from "../core/inventory/inventory/inventory";

export class GMCHelpers {
  static readonly DEFAULT_DESTINATION_NAME = "Shopping";

  static BuildPosInventoryBody(offerId, inventory: Inventory, gmbLocation: GMBLocation): ContentAPI_POS.PosInventory {
    const bodyData = {
      inventoryId: inventory.id,
      storeCode: gmbLocation.storeCode, // comes from Pos.list call
      itemId: offerId, // Should be the offerId used in the Products.insert call, so Google can associate the pos/store with the original product
      targetCountry: gmbLocation.targetCountry, // same as Products.insert call
      contentLanguage: gmbLocation.contentLanguage, // same as Products.insert call
      price: {
        currency: inventory.standardPriceCurrencyIsoCode,
        value: inventory.standardPrice + "",
      },
      quantity: inventory.inventoryStocks.reduce((a, b) => a + b.qtyOH, 0),
      gtin: null,
      timestamp: moment().format("YYYY-MM-DD"), // Date and time when stock quantity was reported for the inventory
    };

    const inventoryGoogleShopping = inventory.inventoryGoogleShopping;
    if (inventoryGoogleShopping) {
      Object.assign(bodyData, {
        gtin: inventoryGoogleShopping.GTIN, // same as Products.insert call
      });
    }

    return bodyData;
  }

  static GetProductStatus(
    productStatus: ContentAPI_ProductStatuses.ProductStatus
  ): ContentAPI_ProductStatuses.DestinationStatus_Status {
    let destStatus: ContentAPI_ProductStatuses.DestinationStatus;
    if (productStatus && productStatus.destinationStatuses)
      destStatus = productStatus.destinationStatuses.find(
        (dest) => dest.destination === GMCHelpers.DEFAULT_DESTINATION_NAME
      );

    if (!destStatus) return ContentAPI_ProductStatuses.DestinationStatus_Status.NOT_AVAILABLE;

    const requestDate = new Date();
    const maxNoExpiringDate = moment(productStatus.googleExpirationDate).subtract(4, "days").toDate();
    switch (destStatus.status) {
      // Active and Expiring status is resolved as APPROVED inside Productstatuses list API,
      // we differentiate between ACTIVE and EXPIRING state based on the expirying date (field 'googleExpirationDate')
      case ContentAPI_ProductStatuses.DestinationStatus_Status.APPROVED:
        return maxNoExpiringDate.getTime() > requestDate.getTime()
          ? ContentAPI_ProductStatuses.DestinationStatus_Status.ACTIVE
          : ContentAPI_ProductStatuses.DestinationStatus_Status.EXPIRING;

      default:
        return destStatus.status;
    }
  }

  static async BuildTitleBasedOnTemplate(inventory: Inventory, template: GoogleShoppingTitleTemplate) {
    const productTitleStr = inventory.description1;
    const brandStr = _.get(inventory, "inventoryGoogleShopping.brand", _.get(inventory, "brand.name", null));
    const genderStr = _.get(inventory, "inventoryGoogleShopping.gender", null);
    // const productTypeStr = _.get(inventory, "inventoryGoogleShopping.productType", null);
    const colorStr = _.get(inventory, "inventoryGoogleShopping.color", null);
    const sizeValuesStr = _.map(
      (_.get(inventory, "inventoryGoogleShopping.sizes", []) || []).slice(0, 1),
      _.capitalize
    ).join(", ");
    const sizesStr = sizeValuesStr ? `${sizeValuesStr}` : null;
    const materialStr = _.get(inventory, "inventoryGoogleShopping.material", null);
    const weightStr =
      inventory.weight && inventory.massUOfMeasure ? `${inventory.weight} ${inventory.massUOfMeasure.name}` : null;
    // TODO: Extract Count and Quantity

    switch (template) {
      case GoogleShoppingTitleTemplate.Basic:
        return [
          [brandStr, productTitleStr].filter(Boolean).join(" "),
          [weightStr, colorStr, materialStr, sizesStr].filter(Boolean).join(" "),
        ]
          .filter(Boolean)
          .join(", ");

      case GoogleShoppingTitleTemplate.Apparel:
        return [
          [brandStr, genderStr, productTitleStr].filter(Boolean).join(" "),
          [colorStr, materialStr, sizesStr].filter(Boolean).join(" "),
        ]
          .filter(Boolean)
          .join(", ");

      case GoogleShoppingTitleTemplate.HealthAndBeauty:
        return [[brandStr, productTitleStr].filter(Boolean).join(" "), [weightStr, colorStr].filter(Boolean).join(" ")]
          .filter(Boolean)
          .join(", ");

      case GoogleShoppingTitleTemplate.HardGoods:
        return [[brandStr, productTitleStr].filter(Boolean).join(" "), [weightStr, sizesStr].filter(Boolean).join(" ")]
          .filter(Boolean)
          .join(", ");

      case GoogleShoppingTitleTemplate.Electronics:
        return [brandStr, sizeValuesStr, colorStr, productTitleStr].filter(Boolean).join(" ");

      case GoogleShoppingTitleTemplate.Seasonal:
        return [
          [productTitleStr].filter(Boolean).join(" "),
          [colorStr, materialStr, sizesStr].filter(Boolean).join(" "),
        ]
          .filter(Boolean)
          .join(", ");
    }

    return productTitleStr;
  }

  static BuildProductsInsertBody(inventory: Inventory, gmbLocation: GMBLocation): {} {
    const titleTpl = inventory.inventoryGoogleShopping ? inventory.inventoryGoogleShopping.titleTemplate : null;

    const body = {
      channel: "local",
      contentLanguage: gmbLocation.contentLanguage,
      offerId: inventory.sku,
      targetCountry: gmbLocation.targetCountry,
      title: GMCHelpers.BuildTitleBasedOnTemplate(inventory, titleTpl),
      description: inventory.longDescription || inventory.description1,
      price: {
        currency: inventory.standardPriceCurrencyIsoCode,
        value: inventory.standardPrice,
      },
    };

    const inventoryGoogleShopping = inventory.inventoryGoogleShopping;
    if (inventoryGoogleShopping) {
      Object.assign(body, {
        link: inventoryGoogleShopping.productLink,
        imageLink: inventoryGoogleShopping.imageLink,
        brand: inventoryGoogleShopping.brand,
        gtin: inventoryGoogleShopping.GTIN,
        mpn: inventoryGoogleShopping.MPN,
        googleProductCategory: (<any>inventoryGoogleShopping.productCategory).code,
        productTypes: [inventoryGoogleShopping.productType],
        condition: inventoryGoogleShopping.condition,
        ageGroup: inventoryGoogleShopping.ageGroup,
        adult: inventoryGoogleShopping.adult,
        gender: inventoryGoogleShopping.gender,
        multipack: inventoryGoogleShopping.multiPack,
        material: inventoryGoogleShopping.material,
        isBundle: inventoryGoogleShopping.isBuldle,
        sizeType: inventoryGoogleShopping.sizeType,
        sizeSystem: inventoryGoogleShopping.sizeSystem,
        pattern: inventoryGoogleShopping.pattern,
        color: inventoryGoogleShopping.color,
      });
    }

    return body;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
