/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, ElementRef, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { SelectItem } from "primeng/api";
import { Dropdown } from "primeng/dropdown";
import type { ScrollerOptions } from "primeng/scroller";
import { LocationService } from "../../shared/services/location.service";
import { DropdownItemCustomFiltering } from "../../shared/ui/DropdownItemCustomFiltering";
import { FormDataHelpers } from "../../utility/FormDataHelpers";
import { TakuInputCtrlBase } from "../TakuInputCtrlBase";

@Component({
  selector: "taku-country",
  templateUrl: "./taku-country.component.html",
  styleUrls: ["./taku-country.component.scss"],
})
export class TakuCountryComponent extends TakuInputCtrlBase {
  @ViewChild(Dropdown, { static: true }) dropdownComponent: Dropdown;
  @Input() hideSelectedItemFlag = true;
  @Input() hideSelectedItemLabel = false;

  _countries: DropdownItemCustomFiltering[] = [];
  _scrollOptions: ScrollerOptions = {
    appendOnly: true,
    trackBy: (index: number, item: any) => item.value,
  };

  constructor(public el: ElementRef, private locationService: LocationService) {
    super(el);
  }

  ngOnInit() {
    super.ngOnInit();

    // TODO: Actually I would be better to add the empty option (first showing a label)
    // in the component instead of the service because it is more concerned with presentation layer,
    // however it would mean implementing as taku-country components all the other usages for instance 'Phone Numbers' in the Personal Account
    const countries = this.locationService.lookupCountryOptions_full();

    this._countries.push(
      Object.assign(new DropdownItemCustomFiltering(this.dropdownComponent), { value: "", label: "" })
    );
    countries.forEach((country: SelectItem) => {
      this._countries.push(Object.assign(new DropdownItemCustomFiltering(this.dropdownComponent), country));
    });
  }

  tryFocusFilterField($event) {
    return FormDataHelpers.putCursorIntoDropFilter($event);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
