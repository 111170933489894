/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Input } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { GenericPhoneComponent } from "../phone-shared/phone.component.shared";
import { AddressPhone, AddressPhoneType } from "./address-phone";

@Component({
  selector: "taku-address-phone",
  templateUrl: "../phone-shared/phone.component.shared.html",
  styleUrls: ["../phone-shared/phone.component.shared.scss"],
})
export class AddressPhoneComponent extends GenericPhoneComponent {
  @Input() disableForm?: boolean = false;
  @Input() showPhoneCopyControl = true;

  enum_phone_type = this.dbService.enumSelectOptions(AddressPhoneType);

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    const addressPhone_Group = fb.group(new AddressPhone());
    return addressPhone_Group;
  }

  static set(fb: UntypedFormBuilder, phone: AddressPhone) {
    const tmpForm = fb.group(phone);

    return tmpForm;
  }

  initValidation() {
    this._validation = {};
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
