/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnDestroy, OnInit } from "@angular/core";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { config } from "process";
import { Observable, Subscription } from "rxjs";

export type OptionalCancelBtnDetails = {
  btnText: string;
  onClickReadyParams$?: Observable<Record<string, unknown>>;
  onClick: (event: Record<string, unknown>) => void;
};

@Component({
  selector: "taku-progress-dialog",
  templateUrl: "./progress-dialog.component.html",
  styleUrls: ["./progress-dialog.component.scss"],
})
export class ProgressDialogComponent implements OnDestroy {
  subsList: Subscription[] = [];
  _note = "Note: Please wait until this process is complete.";
  _imgUrl = null;
  _optionalCancelBtnDetails: OptionalCancelBtnDetails = null;
  _optionalCancelBtnClicked = false;

  /**
   * @description
   * This will be null if it's waiting for the observable to emit the params, or `{}` (which is truthy) if there are no params to wait for.
   */
  _optionalCancelBtnOnClickReadyParams?: Record<string, unknown> = null;

  constructor(public config: DynamicDialogConfig) {
    this._note = config.data?.note || this._note;
    this._imgUrl = config.data?.imgUrl || this._imgUrl;
    this._optionalCancelBtnDetails = config.data?.optionalCancelBtnDetails || null;
    if (this._optionalCancelBtnDetails) {
      if (this._optionalCancelBtnDetails.onClickReadyParams$) {
        this.subsList.push(
          this._optionalCancelBtnDetails.onClickReadyParams$.subscribe((params) => {
            this._optionalCancelBtnOnClickReadyParams = params;
          })
        );
      } else {
        this._optionalCancelBtnOnClickReadyParams = {};
      }
    }
  }

  optionalCancelBtnClicked(): void {
    this._optionalCancelBtnClicked = true;
    this._note = "Cancelling...";
    this._optionalCancelBtnDetails.onClick(this._optionalCancelBtnOnClickReadyParams);
  }

  ngOnDestroy(): void {
    for (const sub of this.subsList) {
      sub.unsubscribe();
    }
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
