/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { WebHelpers } from "src/app/utility/WebHelpers";
import { SaleInvoiceStateService } from "../sale-invoice-state.service";
import { SaleInvoiceDocBaseComponent } from "../sale-invoice-doc-base/sale-invoice-doc-base.component";

@Component({
  selector: "taku-sale-invoice-doc-main",
  templateUrl: "./sale-invoice-doc-main.component.html",
  styleUrls: ["./sale-invoice-doc-main.component.scss"],
  providers: [SaleInvoiceStateService],
})
export class SaleInvoiceDocMainComponent implements OnInit, OnDestroy {
  @ViewChild("invoiceDocComponent") invoiceDocComponent: SaleInvoiceDocBaseComponent;
  readonly cssClassName = "sale-invoice-component";

  constructor(private saleInvoiceStateService: SaleInvoiceStateService) {}

  ngOnInit() {
    document.body.classList.add(this.cssClassName);
  }

  get useMobileDesign() {
    return WebHelpers.isMobileScreen();
  }

  get useTabletDesign() {
    return WebHelpers.isPortraitTabletScreen();
  }

  get useDesktopDesign() {
    return WebHelpers.isDesktopScreen();
  }

  ngOnDestroy(): void {
    this.saleInvoiceStateService.clearDocState();
    this.saleInvoiceStateService.clearInvoiceState(this.invoiceDocComponent);
    document.body.classList.remove(this.cssClassName);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
