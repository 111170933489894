/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { MessageService } from "primeng/api";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";
import { DBCashoutService } from "../core/cashout/db-cashout.service";
import { AppSettingsStorageService } from "../shared/app-settings-storage.service";
import { AlertMessagesService } from "../shared/services/alert-messages.service";
import { DBService } from "../shared/services/db.service";
import { FormListColumnsService } from "../form-list/form-list-columns.service";
import { ObjectDetailResolverService } from "./object-detail-resolver.service";

@Injectable()
export class CashoutResolverService extends ObjectDetailResolverService implements Resolve<any> {
  constructor(
    db: DBService,
    formListColumnsService: FormListColumnsService,
    router: Router,
    messageService: MessageService,
    alertService: AlertMessagesService,
    private cashoutService: DBCashoutService,
    private appSettingsService: AppSettingsStorageService
  ) {
    super(db, formListColumnsService, router, messageService, alertService);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const id = route.paramMap.get("id") == null ? null : +route.paramMap.get("id");
    const resolveObservable = super.resolve(route, state);
    if (resolveObservable) return resolveObservable;
    else if (id === 0)
      // if id is 0, it means previous opened cashout, so create new one
      return null;
    else if (id === null) {
      // if not a number, we are going to check if an open cashout exist, an eventually redirect when it does
      return this.cashoutService
        .searchOpenCashout$(this.appSettingsService.getStoreId(), this.appSettingsService.getStationId())
        .pipe(
          tap((cashout) => {
            this.appSettingsService.setCashout(cashout);

            return of(null);
          })
        );
    }
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
