/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Location } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  NgZone,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
  HostListener,
  OnInit,
  inject,
} from "@angular/core";
import { AbstractControl, FormArray, UntypedFormArray, UntypedFormBuilder } from "@angular/forms";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import * as _ from "lodash";
import { ConfirmationService, MessageService } from "primeng/api";
import { ScrollPanel } from "primeng/scrollpanel";
import { map, pairwise, shareReplay, startWith } from "rxjs/operators";
import { AppConstants } from "src/app/shared/app-constants";
import { InvoiceSettings } from "../../../app-routing/salesscreen-settings-resolver.service";
import { DBMode, GenericFormComponent } from "../../../forms/generic-form/generic-form.component";
import { AppSettingsStorageService } from "../../../shared/app-settings-storage.service";
import { AlertMessagesService } from "../../../shared/services/alert-messages.service";
import { PrintingFactoryService, ReceiptPrintingSize } from "../../../shared/services/printing-service.service";
import { SearchResultItem } from "../../../taku-ui/taku-search-accounts/SearchResultItem";
import { MonetaryHelpers } from "../../../utility/MonetaryHelpers";
import { PrintHelpers } from "../../../utility/PrintHelpers";
import { Cashout } from "../../cashout/cashout/cashout";
import { AccountType } from "../../contact-accounts/account/account";
import { Inventory } from "../../inventory/inventory/inventory";
import { StoreSettingLetterSalePrint } from "../../settings/store-settings/full-page-salereceipt-builder/StoreSettingLetterSalePrint";
import { StoreSettingGiftReceipt } from "../../settings/store-settings/gift-receipt-builder/StoreSettingGiftReceipt";
import { StoreSettingReturnReason } from "../../settings/store-settings/return-reason/StoreSettingReturnReason";
import { StorePoliciesSettings } from "../../settings/store-settings/store-policies-settings/StorePoliciesSettings";
import { StoreSettingTapeSalePrint } from "../../settings/store-settings/tape-salereceipt-builder/store-setting-tape-sale-print";
import { DocState, SaleDocType } from "../doc/doc";
import { GenericInvoiceStateService } from "../generic-invoice-state.service";
import { ReturnsSideDialogName, SalesSideDialogName } from "../types/invoice-enums.type";
import { GenericSaleDocLineComponent } from "./generic-sale-doc-line.component";
import { DiscountType, SaleDocLine } from "./sale-doc-line/sale-doc-line";
import { SaleDocTender } from "./sale-doc-tender/sale-doc-tender";
import { SaleDocTenderComponent } from "./sale-doc-tender/sale-doc-tender.component";
import { DeliveryStatus, FulfillmentStatus, PaymentStatus, SaleDoc, SaleDocSource } from "./sale-doc/sale-doc";
import { SaleDocService } from "./sale-doc/sale-doc.service";
import { InvoiceKeyboardActionsService } from "./sale-invoice-doc-base/invoice-keyboard-actions.service";
import { TenderScreenData } from "./sale-invoice-doc-base/sale-invoice-doc-base.component";
import { TenderPrintingSettings, TenderScreenResult } from "./tender-screen/tender-screen.component";
import { PaymentGateway } from "../../settings/integration-settings/payment-gateways/payment-gateway";
import { AppComponent } from "src/app/app.component";
import { StoreSettingLetterReturnPrint } from "../../settings/store-settings/full-page-returnreceipt-builder/StoreSettingLetterReturnPrint";
import { StoreSettingTapeReturnPrint } from "../../settings/store-settings/tape-returnreceipt-builder/store-setting-tape-return-print";
import { MonerisService } from "../../settings/integration-settings/moneris-terminal/moneris.service";
import { TakuPayService } from "../../settings/integration-settings/taku-pay/taku-pay.service";
import { PrinterManufacturer } from "../../settings/store-settings/printer/printer-settings";
import { throttleTime, filter } from "rxjs/operators";
import { Observable, Subscription } from "rxjs";
import { PriceLevel } from "../../price-levels/price-level";
import { StoreTenderTypesSettingsService } from "../../settings/store-settings/store-tender-type-settings/tender-types-settings.service";
import { PriceLevelService } from "../../price-levels/price-level-service";
import { TenderReceiptTapePreviewComponent } from "../../previews/receipt/tender-receipt-tape-preview/tender-receipt-tape-preview.component";
import { UnfinalizedSalesService } from "../unfinalized-sales.service";
import { ModelFormGroup } from "src/app/utility/ModelFormGroup";
import { TenderWarningService } from "./tender-warning.service";
import { DeliveryMethod } from "./commitments/commitments";
import { GiftReceiptTapePreviewComponent } from "../../previews/receipt/gift-receipt-tape-preview/gift-receipt-tape-preview.component";

export type OverlayModalName = FullSizeDialogName | SalesSideDialogName | ReturnsSideDialogName;

export enum FullSizeDialogName {
  COMMERCIAL_ACCOUNT = "newCommercialAccount",
  PERSONAL_ACCOUNT = "newPersonalAccount",
  EDIT_INVENTORY = "editInventory",
  NEW_INVENTORY = "newInventory",
  TENDER_SCREEN = "tenderScreen",
  DELIVERY_METHOD = "deliveryMethod",
  DOC_DETAILS = "docDetails",
  TENDER_REF_NO = "tenderReferenceNumber",
  DOCLINE_DETAILS = "DocLineDetails",
  DOC_SUMMARY = "DocSummary",
  PRINT_PREVIEW = "printPreview",
  REVISIT_SALES = "revisitSales",
  CUSTOMER_CONSENT = "customerConsent",
}

export type OverlayModalState = {
  fullDialogName: OverlayModalName;
  overlayExtra: any;
  overlayParams: any;
};

export enum SaveDocActionType {
  PRINT_PREVIEW = "printPreview",
  TENDER_SCREEN = "tenderScreen",
  RESET_SALEDOC = "clearInvoice",
}

export type SaveDocAction = {
  type: SaveDocActionType;
  data?: any;
};

@Component({
  template: "",
  styles: [],
})
export class GenericSaleDocComponent extends GenericFormComponent implements OnInit {
  docType: SaleDocType;
  @Output() onDocSaved = new EventEmitter<SaleDoc>();
  @Output() onDocReset = new EventEmitter<any>();
  @Output() tenderScreenOpened = new EventEmitter<TenderScreenData>();
  @Output() onDocLinesDeleted = new EventEmitter<string>();
  @Output() saleLinesCleared = new EventEmitter<any>();

  // @ViewChild('mainContainer', {static: true}) protected mainContainerEl:ElementRef;
  // @ViewChild('invoicePlaceholder', {static: true}) protected invoicePlaceholderEl:ElementRef;

  @ViewChild("scrollMe", { static: true }) protected myScrollContainer: ScrollPanel;
  @ViewChild("printPreviewHost", { read: ViewContainerRef, static: true }) printPreviewHost: ViewContainerRef;
  @ViewChild("transactionsArea", { static: true }) transactionsAreaEl: ElementRef;
  // Parent's Templates
  @ContentChild("saleDocHeader") headerTemplate: TemplateRef<ElementRef>;
  @ContentChild("saleDocHeaderDialog") headerDialogTemplate: TemplateRef<ElementRef>;
  @ContentChild("saleDocRightSide") rightSideTemplate: TemplateRef<ElementRef>;
  // Added for mobile
  @ContentChild("saleDocFooter") footerTemplate: TemplateRef<ElementRef>;
  @ContentChild("extraModalViews") extraModalTemplate: TemplateRef<ElementRef>;
  @ViewChildren("docLineComponent") saleLinesComponents: QueryList<GenericSaleDocLineComponent>;
  @ViewChildren("docLineComponent", { read: ElementRef }) saleLinesEls: QueryList<ElementRef>;

  static readonly DECIMAL_PLACES = 2;

  public _invoiceSettings: InvoiceSettings;
  protected _saveDocAction: SaveDocAction = { type: SaveDocActionType.TENDER_SCREEN };
  saleDocTotal = 0;
  selectedLineIndex = -1; // which SaleDocLine has been selected? -1 -> no line
  private isTenderScreenOpen = false;

  // Dialog and Sidebar state
  previousActiveOverlay: OverlayModalState;
  _activeFullDialog: OverlayModalName;
  _activeFullDialogExtra: any;
  _fullDialogParams: any;

  // Other state info
  _selectedAccount: SearchResultItem;
  _cashout: Cashout;
  _lastChangeDue: number;

  letterBuilderSettings: StoreSettingLetterSalePrint;
  returnLetterBuilderSettings: StoreSettingLetterReturnPrint;
  returnTapeBuilderSettings: StoreSettingTapeReturnPrint;
  storePolicies: StorePoliciesSettings;
  tapeBuilderSettings: StoreSettingTapeSalePrint;
  giftReceiptBuilderSettings: StoreSettingGiftReceipt;
  paymentGateways: PaymentGateway[];
  origTakuPaySaleDocTenders: SaleDocTender[];
  orderEditLock = false;

  /** Total of all tender amounts */
  tenderTotal = 0;

  priceLevelsForStore: Observable<PriceLevel[]>;
  /** If an account is added that has an account group with an associated priceLevel, store it to apply discounts */
  activePriceLevel: PriceLevel = null;

  // Add enums
  FullSizeDialogName = FullSizeDialogName;

  selectedDocType = SaleDocType.sales_invoice;

  private unfinalizedSalesService = inject(UnfinalizedSalesService);
  protected tenderWarningService = inject(TenderWarningService);

  get transactionAreaDisabled(): boolean {
    return this.dbService.transactionAreaDisabled;
  }

  constructor(
    public app: AppComponent,
    protected _router: Router,
    public fb: UntypedFormBuilder,
    public dbService: SaleDocService,
    protected location: Location,
    public _route: ActivatedRoute,
    protected messageService: MessageService,
    protected alertMessage: AlertMessagesService,
    protected confirmationService: ConfirmationService,
    appSettingsService: AppSettingsStorageService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected invoiceStateService: GenericInvoiceStateService,
    protected printingFactoryService: PrintingFactoryService,
    protected ngZone: NgZone,
    protected keyboardActionsService: InvoiceKeyboardActionsService,
    protected elRef: ElementRef,
    protected monerisService: MonerisService,
    protected takuPayService: TakuPayService,
    storeTenderTypesSettingsService: StoreTenderTypesSettingsService,
    private priceLevelService: PriceLevelService
  ) {
    super(
      _router,
      fb,
      dbService,
      location,
      _route,
      messageService,
      alertMessage,
      confirmationService,
      appSettingsService
    );

    // Called to pre-cache the network requests in the CacheInterceptor
    this.subsList.push(storeTenderTypesSettingsService.getStoreTenderTypes().subscribe());

    this.subsList.push(
      this.keyboardActionsService.anyKeyPressed$
        .pipe(
          throttleTime(200), // Adjust the throttle time as needed
          filter(
            (event) =>
              (event.keyCode === AppConstants.KEY_CODES.ARROW_UP.code ||
                event.keyCode === AppConstants.KEY_CODES.ARROW_DOWN.code) &&
              !(document.activeElement instanceof HTMLInputElement)
          )
        )
        .subscribe((event) => {
          switch (event.keyCode) {
            case AppConstants.KEY_CODES.ARROW_UP.code:
              this.selectPreviousLine();
              break;

            case AppConstants.KEY_CODES.ARROW_DOWN.code:
              this.selectNextLine();
              break;
          }
        })
    );

    this.orderEditLock = this._route.snapshot.queryParamMap.get("orderEditLock") == "true";

    this.subsList.push(
      this._router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.orderEditLock = this._route.snapshot.queryParamMap.get("orderEditLock") == "true";
          this.dbService.transactionAreaDisabled = this.shouldDisableTransactionArea();
        }
      })
    );
  }

  selectNextLine() {
    this.selectedLineIndex = (this.selectedLineIndex + 1) % this.saleDocLines.length;
  }

  selectPreviousLine() {
    this.selectedLineIndex = (this.saleDocLines.length + this.selectedLineIndex - 1) % this.saleDocLines.length;
  }

  onPay(saleTotal: number) {
    this.saleDocTotal = saleTotal;
    if (this._myForm.value.doc.state === DocState.draft) {
      this._myForm.get("doc.state").setValue(DocState.suspended);
    }
    this.onSave();
  }

  onQtyColPressed() {
    if (!this._isLineIndexValid(this.selectedLineIndex)) return;
    const docLine = this.saleLinesComponents.toArray()[this.selectedLineIndex];
    if (!docLine) return;

    docLine.openNumPad();
  }

  fixViewportHeight() {
    setTimeout(() => {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      const vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      this.elRef.nativeElement.style.setProperty("--vh", `${vh}px`);
    }, 100);
  }

  @HostListener("window:resize", ["$event"])
  onSaleDocResizes() {
    this.fixViewportHeight();
  }

  ngOnInit(): void {
    this._object.doc.currencyIsoCode = this.appSettingsService.getZone().defaultCurrencyIsoCode;

    if (this.invoiceStateService.hasDocState()) {
      this.invoiceStateService.restoreDocState(this);
    } else {
      super.ngOnInit();
      this._selectedAccount = this.buildAccountSearchResult(this._object);
      if (this._myForm.value.doc.state === DocState.draft) {
        this._cashout = this.appSettingsService.getCashout();
      }
      this._myForm.markAsPristine();
    }

    this.fixViewportHeight();
    // Get settings from route
    this.subsList.push(
      this._route.data.pipe(map((data) => data["settings"])).subscribe((settings) => {
        this._invoiceSettings = settings;
      })
    );

    // When I am creating a new invoice/doc fetch compound fields
    if (this.dbMode == DBMode.insert) {
      this._fetchCompoundFields();
    }

    this._fetchExtraSettings();
    this.subsList.push(
      this.changedForm.subscribe((data) => {
        this.postprocessForm();
        this._onDocStored(data);
      })
    );

    this.priceLevelsForStore = this.priceLevelService.getPriceLevelsForStore().pipe(shareReplay());

    // TODO: For testing only
    // this.openDeliveryMethodDialog();
    // this.tenderScreenOpened.emit({
    //   zone: this._object.store ? this._object.store.zone : null,
    //   builderSettings: this.builderSettings,
    //   storePolicies: this.storePolicies,
    // });

    // setTimeout(() => {
    //   this._myForm.get('fulfillmentStatus').setValidators([Validators.required]);
    //   this._myForm.get('fulfillmentStatus').updateValueAndValidity();
    // } , 0);

    this._myForm
      .get("doc.docType")
      .valueChanges.pipe(startWith(this._myForm.get("doc.docType").value as SaleDocType), pairwise())
      // If current is PreOrder, and doc.state not finalized, force FulfillmentStatus.unfulfilled
      // If previous value was PreOrder, FulfillmentStatus.unfulfilled is not required, auto set fulfillmentStatus to null
      .subscribe(([prev, curr]) => {
        if (curr != prev) {
          this._myForm.get("fulfillmentStatus").enable({ emitEvent: false });
          if (
            curr == SaleDocType.pre_order &&
            ![DocState.finalized, DocState.approved].includes(this._myForm.get("doc.state").value)
          ) {
            this._myForm.get("fulfillmentStatus").disable({ emitEvent: false });
            this._myForm.get("deliveryStatus").setValue(DeliveryStatus.pending);
          } else if (
            curr == SaleDocType.sales_invoice &&
            prev == SaleDocType.pre_order &&
            ![DocState.finalized, DocState.approved].includes(this._myForm.get("doc.state").value)
          ) {
            this._myForm.get("deliveryStatus").setValue(DeliveryStatus.pickedUp);
          }
        }
      });

    this.subsList.push(this.unfinalizedSalesService.promptForUnfinalizedSales().subscribe());
    this.tenderWarningService.init(this._myForm);
  }

  private calculateTenderTotal(tenders: typeof this.saleDocTendersCtrl.value) {
    this.tenderTotal = _.sumBy(tenders, (t) => Number(t.amount));
  }

  openInventoryEditForm(inventory: Inventory): void {
    this.subsList.push(
      this.dbService.getRow("inventory", inventory.id).subscribe((inventory: Inventory) => {
        this.openFullSizeDialog(FullSizeDialogName.EDIT_INVENTORY, inventory, {
          showHeader: false,
        });
      })
    );
  }

  ngAfterViewInit(): void {
    this.subsList.push(
      this.invoiceStateService.tenderScreenOpen$.subscribe((isOpen) => {
        if (!this.transactionsAreaEl) return;
        this.isTenderScreenOpen = isOpen;

        this.dbService.transactionAreaDisabled = this.shouldDisableTransactionArea();
      })
    );
  }

  private shouldDisableTransactionArea(): boolean {
    return this.orderEditLock || this.isTenderScreenOpen;
  }

  protected resetDialogs() {
    this._activeFullDialog = null;
    this._activeFullDialogExtra = null;
    this._fullDialogParams = null;
  }

  private tenderTotalSub: Subscription;
  setFormArrays(): void {
    this._myForm.setControl("saleDocTenders", SaleDocTenderComponent.setArray(this.fb, this._object.saleDocTenders));

    this.calculateTenderTotal(this.saleDocTendersCtrl.value);
    this.tenderTotalSub?.unsubscribe();
    this.tenderTotalSub = this.saleDocTendersCtrl.valueChanges.subscribe((tenders) =>
      this.calculateTenderTotal(tenders)
    );
  }

  postprocessForm() {
    this.setFormArrays();
    this._fixNumericDecimalFormat();
  }

  _fixNumericDecimalFormat(): any {
    // Limit presentation of fields to two decimals
    const decimalPlaces = GenericSaleDocComponent.DECIMAL_PLACES;
    this._myForm.patchValue({
      discountAmount: MonetaryHelpers.roundToDecimalPlaces(this._object.discountAmount, decimalPlaces).toFixed(
        decimalPlaces
      ),
      shipperChargeAmount: MonetaryHelpers.roundToDecimalPlaces(
        this._object.shipperChargeAmount,
        decimalPlaces
      ).toFixed(decimalPlaces),
    });

    const saleLines: SaleDocLine[] = this._object.saleDocLines;
    saleLines.forEach((saleLine, index) => {
      this.saleDocLines.at(index).patchValue({
        discountAmount: MonetaryHelpers.roundToDecimalPlaces(+saleLine.discountAmount, decimalPlaces).toFixed(
          decimalPlaces
        ),
      });
    });
  }

  protected buildAccountSearchResult(saleDoc: SaleDoc) {
    if (!saleDoc.account) return null;

    switch (saleDoc.account.accountType) {
      case AccountType.commercial:
        return SearchResultItem.build("commercialAccount", saleDoc.commercialAccount);

      case AccountType.personal:
        return SearchResultItem.build("personalAccount", saleDoc.personalAccount);
    }
  }

  onSave({ silentMode = true } = {}) {
    this._myForm.markAsDirty();
    super.onSave({ silentMode });
  }

  protected initForm() {
    this._model = "saleDoc";
    this._object = new SaleDoc(this.docType);
    // remove empty accounts on new sale doc
    Object.assign(this._object, {
      account: null,
      personalAccount: null,
      commercialAccount: null,
    });

    // Assign default sale doc fields
    _.merge(this._object, {
      storeId: this.appSettingsService.getStoreId(),
      store: this.appSettingsService.getStore(),
      stationId: this.appSettingsService.getStationId(),
      doc: {
        userId: this.appSettingsService.getUserId(),
      },
      // cashoutId: this.appSettingsService.getCashoutId(),
    });
  }

  get saleDocLines(): UntypedFormArray {
    return this._myForm.get("saleDocLines") as UntypedFormArray;
  }

  get saleDocTendersCtrl(): FormArray<ModelFormGroup<SaleDocTender>> {
    return this._myForm.get("saleDocTenders") as FormArray<ModelFormGroup<SaleDocTender>>;
  }

  get storeIdCtrl() {
    return this._myForm.get("storeId");
  }

  get taxAccountCategoryIdCtrl() {
    return this._myForm.get("taxAccountCategoryId");
  }

  get customerSourceCtrl() {
    return this._myForm.get("customerSource");
  }

  switchDocType(docType) {
    switch (docType) {
      case SaleDocType.pre_order:
        const hasReturnItem = this.saleDocLines.value.some((line) => !!line.saleDocLineReturnId);
        if (hasReturnItem) {
          this.confirmationService.confirm({
            header: "Confirmation",
            message:
              "There is a return item and cannot proceed to pre-order. Please remove the return item if you want to proceed to pre-order",
            rejectButtonStyleClass: "p-button-link",
            acceptLabel: "ok",
            rejectVisible: false,
            acceptVisible: true,
          });
          return false;
        }
        this.selectedDocType = SaleDocType.pre_order;
        this.invoiceStateService.docTypeChanged(this.selectedDocType);
        this._myForm.get("doc.docType").setValue(SaleDocType.pre_order);
        break;

      case SaleDocType.sales_invoice:
        this.selectedDocType = SaleDocType.sales_invoice;
        this.invoiceStateService.docTypeChanged(this.selectedDocType);
        this._myForm.get("doc.docType").setValue(SaleDocType.sales_invoice);
        this._myForm.controls.deliveryDate.setValue(null);
        break;
    }
    return true;
  }

  _onDocStored(saleDocData) {
    this.onDocSaved.emit(saleDocData);
    this.invoiceStateService.anounceSalesSaved(saleDocData);
    const isReturn = saleDocData.doc.docType == SaleDocType.sales_return;

    switch (this._saveDocAction.type) {
      case SaveDocActionType.RESET_SALEDOC:
        this._resetSaleDoc();
        break;

      case SaveDocActionType.TENDER_SCREEN:
        // Make form dirty again in order to show alert dialog when trying to exit screen/page
        this._myForm.markAsDirty({ onlySelf: true });

        this.tenderScreenOpened.emit({
          zone: this._object.store ? this._object.store.zone : null,
          builderSettings: isReturn ? this.returnLetterBuilderSettings : this.letterBuilderSettings,
          storePolicies: this.storePolicies,
          paymentGateways: this.paymentGateways,
          origTakuPaySaleDocTenders: this.origTakuPaySaleDocTenders,
        });
        break;

      case SaveDocActionType.PRINT_PREVIEW: {
        const printingSettings: TenderPrintingSettings = this._saveDocAction.data;
        const printMgr = this.printingFactoryService.buildWithSettings(
          this._object.storeId,
          isReturn ? this.returnLetterBuilderSettings : this.letterBuilderSettings,
          isReturn ? this.returnTapeBuilderSettings : this.tapeBuilderSettings,
          this.giftReceiptBuilderSettings,
          this.storePolicies
        );
        this.subsList.push(
          printMgr.isReady.subscribe({
            next: () => {
              const saleDoc: SaleDoc = SaleDoc.convertIntoTypedObject(saleDocData as SaleDoc);
              const printingMgr = this.printingFactoryService.build(saleDoc.storeId);
              PrintHelpers.printSaleDocFromTenderSettings(saleDoc, this.printPreviewHost, printingSettings, printMgr);

              if (printingSettings.giftReceipt) {
                printMgr.printSaleDocsInBatch([saleDoc], this.printPreviewHost, GiftReceiptTapePreviewComponent);
              }

              const tenderItemsToPrint: [SaleDocTender, string][] = [];
              for (let index = 0; index < saleDoc.saleDocTenders.length; index++) {
                const _saleDocTender = saleDoc.saleDocTenders[index];
                if (_saleDocTender?.refTransaction && _saleDocTender?.refTransaction["customerReceiptTemplate"]) {
                  if (PrintHelpers.isTakuPayTransaction(saleDoc)) {
                    if (
                      printingSettings.printingSize !== ReceiptPrintingSize.TAPE_SIZE &&
                      saleDoc.doc.docType !== SaleDocType.pre_order
                    ) {
                      const [customerReceiptTemplate, merchantReceiptTemplate] =
                        this.takuPayService.getTenderReceiptStoreInfoHtml(printingMgr, _saleDocTender, saleDoc);
                      if (customerReceiptTemplate) {
                        tenderItemsToPrint.push([_saleDocTender, customerReceiptTemplate]);
                      }
                      if (merchantReceiptTemplate && printingSettings.extraCopy) {
                        tenderItemsToPrint.push([_saleDocTender, merchantReceiptTemplate]);
                      }
                    }
                  } else {
                    this.monerisService.printPaymentReceipt(
                      printingMgr,
                      _saleDocTender,
                      saleDoc,
                      this.printPreviewHost,
                      true
                    );
                  }
                }
              }
              if (tenderItemsToPrint.length > 0) {
                printingMgr.printTendersTemplates(
                  tenderItemsToPrint,
                  this.printPreviewHost,
                  TenderReceiptTapePreviewComponent,
                  "Tender Receipt - Print Preview"
                );
              }

              if (
                this.appSettingsService.getStation() &&
                this.appSettingsService.getStation().prepPrinter?.manufacturer === PrinterManufacturer.star_cloudPRNT
              ) {
                printMgr.printStarCloudPRNTPrepInBatch([saleDoc]);
              }
              this._resetSaleDoc();
            },
            error: (error) => {
              alert("ERROR: Settings for Tape Builder haven't been created. CANNOT DISPLAY RECEIPT PREVIEW");
            },
          })
        );

        const saleDoc: SaleDoc = SaleDoc.convertIntoTypedObject(saleDocData);
        if (printingSettings.sendEmail) {
          // Send Invoice email to customer using server as channel
          this.subsList.push(
            printMgr
              .sendInvoiceEmail(
                saleDoc,
                printingSettings.emailAddress,
                this.printPreviewHost,
                printingSettings.printingSize
              )
              .subscribe({
                next: (response) => {},
              })
          );
        }
        break;
      }
    }
  }

  protected _resetSaleDoc(emitEvent = true) {
    this._saveDocAction = { type: SaveDocActionType.TENDER_SCREEN };
    this.clearSelectedAccount();
    //this._clearSaleDocLines();
    this.initForm();
    this.switchDocType(SaleDocType.sales_invoice);
    this._fetchCompoundFields();
    this._id = 0;
    this.loadObject();
    this.tenderWarningService.init(this._myForm);
    // Emit document reset event in order to make input focus work
    if (emitEvent) this.onDocReset.emit(this._object);
  }

  _fetchCompoundFields(): any {
    this._object.doc.user = this.appSettingsService.getUser();
    this._object.doc.currencyIsoCode = this.appSettingsService.getZone().defaultCurrencyIsoCode;
    this._object.store = this.appSettingsService.getStore();
    this._object.station = this.appSettingsService.getStation();
    this._cashout = this.appSettingsService.getCashout();
  }

  get documentCurrency() {
    return this._object.doc.currencyIsoCode;
  }

  onTendersProcessed(tenderResult: TenderScreenResult): void {
    this.closeFullSizeDialog();
    this._saveDocAction = { type: SaveDocActionType.PRINT_PREVIEW, data: tenderResult.printSettings }; // After saving it should show print preview

    if (
      this._myForm.get("doc.state").value === DocState.suspended &&
      this._myForm.get("doc.docType").value === SaleDocType.pre_order
    ) {
      this._myForm.get("fulfillmentStatus").setValue(FulfillmentStatus.unfulfilled);
    } else {
      if (
        this._myForm.get("customerSource").value === SaleDocSource.Store &&
        !this._myForm.get("fulfillmentStatus").value
      ) {
        this._myForm.get("fulfillmentStatus").setValue(FulfillmentStatus.completed);
      }
    }

    if (tenderResult.isTenderCompleted) {
      this._myForm.get("paymentStatus").setValue(PaymentStatus.paid);
      this._myForm.get("doc.state").setValue(DocState.finalized);
    } else {
      this._myForm.get("paymentStatus").setValue(PaymentStatus.unpaid);
      if (
        [SaleDocType.pre_order, SaleDocType.recurring_order, SaleDocType.online_sales_order].includes(
          this._myForm.get("doc.docType").value
        ) &&
        this._myForm.get("doc.state").value !== DocState.finalized
      ) {
        this._myForm.get("doc.state").setValue(DocState.approved);
      }
    }

    if (
      this._myForm.controls.customerSource.value === SaleDocSource.Store &&
      this._myForm.controls.deliveryMethod.value === DeliveryMethod.storePickup
    ) {
      const deliveryStatus = SaleDoc.getFinalStoreDeliveryStatus(this._myForm.get("doc.docType").value as SaleDocType);
      this._myForm.controls.deliveryStatus.setValue(deliveryStatus);
    }

    if (
      this._myForm.get("doc.docType").value === SaleDocType.sales_invoice &&
      [FulfillmentStatus.unfulfilled, FulfillmentStatus.processing, FulfillmentStatus.partly_Fulfillment].includes(
        this._myForm.get("fulfillmentStatus").value
      ) &&
      ([DeliveryStatus.pickedUp, DeliveryStatus.delivered, DeliveryStatus.returned, DeliveryStatus.voided].includes(
        this._myForm.get("deliveryStatus").value
      ) ||
        !this._myForm.get("deliveryStatus").value)
    ) {
      this._myForm.get("deliveryStatus").setValue(DeliveryStatus.pending);
    }

    if (!this._myForm.get("cashoutId").value && this._myForm.get("doc.state").value === DocState.finalized) {
      this._myForm.get("cashoutId").setValue(this.appSettingsService.getCashoutId());
    }

    this._lastChangeDue = tenderResult.changeDue;
    this.onSave({ silentMode: false });
  }

  clearSelectedAccount(): void {
    this._selectedAccount = null;
    const accountField = this._myForm.get("accountId");
    accountField.setValue(null);
    this._myForm.get("taxAccountCategoryId").setValue(null);
    if (this._myForm.get("fulfillmentStatus").value == FulfillmentStatus.unfulfilled) {
      this._myForm.get("fulfillmentStatus").setValue(null);
    }
    accountField.markAsPristine();
    if (this.activePriceLevel) {
      // Clear price level discounts if they were applied
      this.activePriceLevel = null;
      this.saleDocLines.controls.forEach((_saleDocLineCtrl) => {
        _saleDocLineCtrl.get("discountType").setValue(DiscountType.Percentage);
        _saleDocLineCtrl.get("discountAmount").setValue(0);
      });
    }
  }

  protected _findDocLineById(docLineId): AbstractControl {
    for (let i = 0; i < this.saleDocLines.length; i++) {
      const currentLine = this.saleDocLines.at(i);
      if (currentLine.get("id").value == docLineId) return currentLine;
    }

    return null;
  }

  get canNoInvoiceReturns(): boolean {
    const returnSettings = this._invoiceSettings.returnSettings;
    if (!returnSettings) return false;

    return returnSettings.canReturnWithoutInvoice;
  }

  get returnReasons(): StoreSettingReturnReason[] {
    if (!this._invoiceSettings || !this._invoiceSettings.returnSettings) return [];

    return this._invoiceSettings.returnSettings.storeSettingReturnReasons;
  }

  protected _isMobile = false;
  get isMobile(): boolean {
    return this._isMobile;
  }

  onLineToggleExpansion(event, saleLine, index) {
    // const saleLine = this.saleDocLines[index];
    event.stopPropagation();

    if (saleLine.isExpanded) {
      saleLine.collapseRow();
    } else {
      saleLine.expandRow();
    }
  }

  openDocDetailsDialog() {
    this.openFullSizeDialog(FullSizeDialogName.DOC_DETAILS, null, {
      styleClass: null,
      // sidebarPosition: 'top',
      contentStyle: {
        overflow: "visible",
      },
    });
  }

  onDocSummaryOpened(event) {
    this.openFullSizeDialog(FullSizeDialogName.DOC_SUMMARY);
  }

  openDeliveryMethodDialog() {
    this.openFullSizeDialog(FullSizeDialogName.DELIVERY_METHOD, null, {
      showHeader: false,
      styleClass: null, // Dont' assigned any custom class
      closable: false,
      contentStyle: {
        overflow: "visible",
      },
    });
  }

  openFullSizeDialog(dialog: OverlayModalName, extra = null, dialogParams: any = {}, savePreviousDialog = true) {
    dialogParams = _.merge(
      {
        styleClass: this.isMobile ? null : "fixedsize-modal",
        closable: true,
        showHeader: true,
        contentStyle: {
          maxHeight: "calc(100% - 50px)",
        },
      },
      dialogParams
    );

    const nestedOnHide = dialogParams.onHide;
    this.keyboardActionsService._canAnnounceNumKeyPress = false;
    dialogParams.onHide = () => {
      this.keyboardActionsService._canAnnounceNumKeyPress = true;
      setTimeout(() => {
        if (this.previousActiveOverlay) this.restoreOverlayModalState();

        if (nestedOnHide) nestedOnHide();
      }, 0);
    };

    if (this.isMobile) {
      if (this._activeFullDialog && savePreviousDialog) this.saveOverlayModalState();
      else this.previousActiveOverlay = null;
    } else {
      if (this._activeFullDialog) this.saveOverlayModalState();
    }

    this._activeFullDialog = dialog;
    this._activeFullDialogExtra = extra;
    this._fullDialogParams = dialogParams;
  }

  protected _fetchExtraSettings() {
    this.subsList.push(
      this.appSettingsService.getStoreSettings("storeSettingLetterSalePrint").subscribe((settings) => {
        // console.log(settings);
        this.letterBuilderSettings = settings;
      })
    );

    this.subsList.push(
      this.appSettingsService.getStoreSettings("storeSettingTapeSalePrint").subscribe((settings) => {
        // console.log(settings);
        this.tapeBuilderSettings = settings;
      })
    );

    this.subsList.push(
      this.appSettingsService
        .getStoreSettings("storeSettingGiftReceipt")
        .subscribe((settings) => (this.giftReceiptBuilderSettings = settings))
    );

    this.subsList.push(
      this.appSettingsService.getStoreSettings("storePolicy").subscribe((settings) => (this.storePolicies = settings))
    );

    this.subsList.push(
      this.appSettingsService
        .getStoreSettings("paymentGateway", true)
        .subscribe((settings) => (this.paymentGateways = settings))
    );

    // I put subscriptions on return receipts here since you can also return from the salescreen
    this.subsList.push(
      this.appSettingsService.getStoreSettings("storeSettingTapeReturnPrint").subscribe((settings) => {
        this.returnTapeBuilderSettings = settings;
        // console.log(this.returnTapeBuilderSettings);
      })
    );

    this.subsList.push(
      this.appSettingsService.getStoreSettings("storeSettingLetterReturnPrint").subscribe((settings) => {
        this.returnLetterBuilderSettings = settings;
        // console.log(this.returnLetterBuilderSettings);
      })
    );
  }

  closeFullSizeDialog() {
    this._activeFullDialog = null;
  }

  protected saveOverlayModalState() {
    this.previousActiveOverlay = {
      fullDialogName: this._activeFullDialog,
      overlayExtra: this._activeFullDialogExtra,
      overlayParams: this._fullDialogParams,
    };
  }

  protected restoreOverlayModalState() {
    this._activeFullDialog = this.previousActiveOverlay.fullDialogName;
    this._activeFullDialogExtra = this.previousActiveOverlay.overlayExtra;
    this._fullDialogParams = this.previousActiveOverlay.overlayParams;

    // Clear previous as it is now the active overlay
    this.previousActiveOverlay = null;
  }

  scrollToBottom(): void {
    if (this.myScrollContainer)
      this.myScrollContainer.scrollTop(this.myScrollContainer.contentViewChild.nativeElement.scrollHeight);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.invoiceStateService.saveDocState(this);
    this.tenderTotalSub?.unsubscribe();
    this.tenderWarningService.clear();
  }

  protected deleteAllLines() {
    let i: number = this.saleDocLines.length;
    for (i; i >= 0; i--) {
      this.saleDocLines.removeAt(i);
    }

    // Added for return functionality
    this.onDocLinesDeleted.emit(this._myForm.value.doc.docNo);
  }

  protected deleteTenderLines() {
    let i: number = this.saleDocTendersCtrl.length;
    for (i; i >= 0; i--) {
      this.saleDocTendersCtrl.removeAt(i);
    }
  }

  updateSaleTotal(newTotal) {
    this.ngZone.run(() => {
      this.saleDocTotal = newTotal;
    });
  }

  collapseAll() {
    this.saleLinesComponents.forEach((saleLine) => saleLine.collapseRow());
  }

  onLineClick(index: number) {
    this.selectedLineIndex = this.selectedLineIndex !== index ? index : -1;
  }

  incQty(index = this.selectedLineIndex) {
    if (!this._isLineIndexValid(index)) return;

    const docLine = this.saleLinesComponents.toArray()[index];
    if (!docLine || docLine._myForm.controls.voucher?.value) return;

    docLine.incQty();
  }

  decQty(index = this.selectedLineIndex) {
    if (!this._isLineIndexValid(index)) return;

    const docLine = this.saleLinesComponents.toArray()[index];
    if (!docLine || docLine._myForm.controls.voucher?.value) return;

    this.tenderWarningService.warnAboutTenders(() => docLine.decQty()).subscribe();
  }

  canDecreaseQty(index = this.selectedLineIndex): boolean {
    if (!this._isLineIndexValid(index)) return false;

    const docLine = this.saleLinesComponents.toArray()[this.selectedLineIndex];
    if (!docLine || docLine._myForm.controls.voucher?.value) return false;

    return docLine.isQtyValid(docLine.calculateQtyDecrement);
  }

  canIncreaseQty(index = this.selectedLineIndex): boolean {
    if (!this._isLineIndexValid(index)) return false;

    const docLine = this.saleLinesComponents.toArray()[this.selectedLineIndex];
    if (!docLine || docLine._myForm.controls.voucher?.value) return false;

    return docLine.isQtyValid(docLine.calculateQtyIncrement);
  }

  _isLineIndexValid(index) {
    return index >= 0 && index < this.saleLinesComponents.length;
  }

  get isSelectedIndexValid() {
    return this._isLineIndexValid(this.selectedLineIndex);
  }

  get selectedDocLine(): GenericSaleDocLineComponent {
    if (this.isSelectedIndexValid) return this.saleLinesComponents.toArray()[this.selectedLineIndex];
    else return null;
  }

  _highlightAndShowLine(index: number) {
    // Highlight line that was just added
    const scrollToLineFn = (index: number) => {
      let scrollPos = 0;
      const prevLineEl = this.saleLinesEls.toArray()[index - 1];
      // Scroll to the bottom of the previous line, that is, the beginning of the desired line
      if (prevLineEl) scrollPos = prevLineEl.nativeElement.offsetTop + prevLineEl.nativeElement.offsetHeight;

      this.myScrollContainer.scrollTop(scrollPos);
    };

    this.selectedLineIndex = index;
    if (index > this.saleLinesEls.length - 1) {
      const subscription = this.saleLinesEls.changes.subscribe((queryList) => {
        if (index === queryList.length - 1) scrollToLineFn(index);

        subscription.unsubscribe();
      });
    } else scrollToLineFn(index);
  }

  isNotDesktopDisplay(): boolean {
    return window.innerWidth < 992;
  }

  isMobileDisplay(): boolean {
    return window.innerWidth <= 640;
  }

  leftSideBarOpen() {
    return this.app.menuActive;
  }

  inDocLineFormatChangeRange(): boolean {
    if (this.leftSideBarOpen()) {
      return (992 <= window.innerWidth && window.innerWidth <= 1186) || window.innerWidth <= 452;
    } else {
      return window.innerWidth <= 452;
    }
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
