/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable, EventEmitter, OnDestroy } from "@angular/core";
import { DBService } from "../../../../shared/services/db.service";
import { DataType, FilterType, Col, AutocompleteDatatypeOptions } from "../../../../form-list/form-list/form-list";
import { merge, Observable, of, Subscription } from "rxjs";
import { ConfirmationService, SelectItem } from "primeng/api";
import { FormListModel } from "../../../../form-list/form-list/form-list-model.interface";
import { ValidatorFn, Validators } from "@angular/forms";
import { Store } from "./store";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "src/app/shared/services/auth.service";
import { Router } from "@angular/router";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { mergeMap, tap } from "rxjs/operators";
import { TimeZoneAutocompleteTransformer } from "../../shared/time-zone-autocomplete-input/time-zone-autocomplete-transformer";
import * as moment from "moment";
import { LocationService } from "src/app/shared/services/location.service";
import { StoreTenderType, StoreTenderTypeList } from "../store-tender-type-settings/TenderTypesSettings";
import { TenderType, Tender_Type } from "../../business-settings/tender-type/tender-type";
import { AppDefaultsService } from "src/app/shared/services/app-defaults.service";
import * as _ from "lodash";
import { SalesFormatsSetting } from "../sales-formats-settings/SalesFormatsSettings";
import { SaleDocFormat } from "../sale-doc-format/sale-doc-format";
import { StorePoliciesSettings } from "src/app/core/settings/store-settings/store-policies-settings/StorePoliciesSettings";
import { ReturnSetting } from "src/app/core/settings/store-settings/return-settings/ReturnSettings";
import { StoreSettingCashout } from "src/app/core/settings/store-settings/cashout-settings/CashoutSettings";
import { StoreSettingSaleDoc } from "src/app/core/settings/store-settings/sales-doc-settings/sales-doc-settings";
import { StoreSettingTapeSalePrint } from "../tape-salereceipt-builder/store-setting-tape-sale-print";
import { StoreSettingLetterSalePrint } from "../full-page-salereceipt-builder/StoreSettingLetterSalePrint";
import { StoreSettingLetterReturnPrint } from "../full-page-returnreceipt-builder/StoreSettingLetterReturnPrint";
import { StoreSettingTapeReturnPrint } from "../tape-returnreceipt-builder/store-setting-tape-return-print";
import { StoreSettingGiftReceipt } from "../gift-receipt-builder/StoreSettingGiftReceipt";
import { StoreSettingEmail } from "../store-mail-settings/StoreSettingEmail";
import { ZoneDenominationService } from "../../zone-settings/zone-denominations/zone-denominations.service";
import { SaleDocType } from "src/app/core/document/doc/doc";
@Injectable({
  providedIn: "root",
})
export class StoreService extends DBService implements FormListModel, OnDestroy {
  subsList: Subscription[] = [];
  businessSetupEvent = new EventEmitter<Store>();
  lookups_stocks: Map<number, SelectItem[]> = new Map();
  observables_stocks: Map<number, Observable<SelectItem[]>> = new Map();
  lookup_subdivisions: { [countryIsoCode: string]: SelectItem[] } = {};

  timeZones: string[] = [];
  static readonly MODEL_NAME = "store";

  constructor(
    http: HttpClient,
    authService: AuthService,
    private router: Router,
    private locationService: LocationService,
    private _confirmationService: ConfirmationService,
    private appSettings: AppSettingsStorageService,
    protected confirmationService: ConfirmationService,
    private dbService: DBService,
    private zoneDenominationService: ZoneDenominationService
  ) {
    super(http, authService);

    this.subsList.push(
      this.businessSetupEvent.subscribe((store: Store) => {
        this.router.navigate(["businessSetup", store.id]);
      })
    );

    this.timeZones = moment.tz.names();
  }

  afterCreate(store: Store, model) {
    const storeTenderTypes$ = this.dbService
      .getRows("tenderType", JSON.stringify({ zoneId: { value: store.zoneId, matchMode: "equals" } }))
      .pipe(
        mergeMap((response: any) => {
          const zoneTenders: TenderType[] = response.rows;
          const storeTendersList = new StoreTenderTypeList();
          storeTendersList.storeId = store.id;
          storeTendersList.storeTenderTypes = zoneTenders
            .filter((tender) => tender.type === Tender_Type.Cash) // only include Cash tender
            .map((tender, index) => Object.assign(new StoreTenderType(), { tenderTypeId: tender.id, priority: index }));
          return this.dbService.addRow("storeTenderTypeList", storeTendersList);
        })
      );

    const saleFormats = new SalesFormatsSetting(store);
    Object.keys(SaleDocType).forEach((key) => {
      const docType = SaleDocType[key];
      const docDefaults = AppDefaultsService.DefaultSaleDocFormats(docType);
      if (!_.isEmpty(docDefaults))
        saleFormats.saleDocFormats.push(Object.assign({}, new SaleDocFormat(), docDefaults, { docType }));
    });

    let zonecashDenominations = [];
    this.zoneDenominationService.getZoneDenominations(store.zoneId).subscribe((cashDenominations) => {
      zonecashDenominations = cashDenominations;
      merge(
        storeTenderTypes$,
        this.dbService.addRow("saleFormat", saleFormats), //
        this.dbService.addRow("storePolicy", new StorePoliciesSettings(store)),
        this.dbService.addRow("storeSettingReturn", new ReturnSetting(store)),
        this.dbService.addRow("storeSettingCashout", new StoreSettingCashout(store, zonecashDenominations)),
        this.dbService.addRow("storeSettingSaleDoc", new StoreSettingSaleDoc(store)),
        this.dbService.addRow("storeSettingTapeSalePrint", new StoreSettingTapeSalePrint(store.zone, store)),
        this.dbService.addRow("storeSettingLetterSalePrint", new StoreSettingLetterSalePrint(store.zone, store)),
        this.dbService.addRow("storeSettingTapeReturnPrint", new StoreSettingTapeReturnPrint(store.zone, store)),
        this.dbService.addRow("storeSettingLetterReturnPrint", new StoreSettingLetterReturnPrint(store.zone, store)),
        this.dbService.addRow("storeSettingGiftReceipt", new StoreSettingGiftReceipt(store)),
        this.dbService.addRow("storeSettingEmail", new StoreSettingEmail(store.id))
      ).subscribe({
        next: () => {},
        error: (error) => {},
      });
    });
  }

  afterSave(store: Store, model: string) {
    if (model === StoreService.MODEL_NAME && store.id === this.appSettings.getStoreId())
      this.appSettings.updateStore(store);
  }

  lookup_zones(): Observable<SelectItem[]> {
    return this.lookupSelectOptions("zone", "zoneName", { enableFieldName: "isActive" });
  }

  get_enumCountries(): SelectItem[] {
    return this.locationService.lookupCountryOptions_full();
  }

  lookup_stocks(zoneId: number, currentStockId: number): Observable<SelectItem[]> {
    // if (this.lookups_stocks.has(currentStockId))
    //     return of(this.lookups_stocks.get(currentStockId));
    // else
    return this.getRows("store", null, 0, 10000).pipe(
      mergeMap((response: any) => {
        let takenStocks = [];
        if (response.rows && response.rows.length)
          takenStocks = (response.rows as Store[]).map((store) => store.stockId);

        return this.lookupSelectOptions("stock", "stockName", {
          enableFieldName: "isActive",
          lookupFilter: JSON.stringify({ zoneId: { matchMode: "equals", value: zoneId } }),
        }).pipe(
          tap((items: SelectItem[]) => {
            this.lookups_stocks.set(currentStockId, items);
          })
        );
      })
    );
  }

  // This is to get the stocks for the filter drop down.
  lookupAllStocks(): Observable<SelectItem[]> {
    return this.lookupSelectOptions("stock", "stockName", { enableFieldName: "isActive" });
  }

  lookup_stores(): Observable<SelectItem[]> {
    return this.lookupSelectOptions("store", "storeName", { enableFieldName: "isActive" });
  }

  getValidationRules(): { [key: string]: ValidatorFn[] | {} } {
    return {
      storeName: [Validators.required],
      storeID: [Validators.required],
      zoneId: [Validators.required],
      stockId: [Validators.required],
      storeTimeZone: [Validators.required],
      "address.countryIsoCode": [Validators.required],
      "address.subDivisionIsoCode": [Validators.required],
      "address.city": [Validators.required],
      "address.postalCode": [Validators.required],
      "address.addressPhone.countryIsoCode": [Validators.required],
    };
  }

  showBusinessSetupConfirmation(storeId: number, onRejection?: Function) {
    this._confirmationService.confirm({
      header: "Business Setup confirmation",
      message: "Do you want to open the Business Setup wizard?",
      acceptLabel: "OK",
      rejectLabel: "No",
      rejectButtonStyleClass: "p-button-link",
      accept: () => {
        this.router.navigate(["businessSetup", storeId]);
      },
      reject: onRejection,
    });
  }

  searchTimeZone(event): Observable<string[]> {
    // return all times zones that contain the query as a substring.
    return of(
      this.timeZones.filter((timeZone) => {
        return timeZone.toLocaleLowerCase().includes(event.query.toLocaleLowerCase());
      })
    );
  }

  onZoneChanged(rowData) {
    this.showZoneChangeConfirmationDialog(rowData);
  }

  private showZoneChangeConfirmationDialog(rowData) {
    this.confirmationService.confirm({
      message: "By changing zone you will lose default stock. Are you sure?",
      rejectButtonStyleClass: "p-button-link",
      accept: () => {
        rowData["stockId"] = null;
        // this.dbService.lookup_stocks(newZoneId, this._myForm.value.stockId).subscribe(data => this.lookup_stocks = data);
      },
      reject: () => {
        rowData["zoneId"] = rowData["zone"]["id"];
        // this._myForm.patchValue({ stockId: this._myForm.value.stockId, zoneId: oldZoneId });
      },
    });
  }

  get_lookup_subdivisions(countryIsoCode: string): SelectItem[] {
    if (!this.lookup_subdivisions[countryIsoCode]) {
      const regionList: SelectItem[] = this.locationService.lookupSubdivisionOptions(countryIsoCode, false);
      // regionList.forEach(region => {
      //   const taxRegion = new TaxRuleSubDivisionIsoCode();
      //   taxRegion.subDivisionIsoCode = region.value;
      //   region.value = taxRegion;
      // });
      this.lookup_subdivisions[countryIsoCode] = regionList;
    }
    return this.lookup_subdivisions[countryIsoCode];
  }

  formlist_lookup_subdivisions(rowData: Store): SelectItem[] {
    return this.get_lookup_subdivisions(rowData.address.countryIsoCode);
  }

  formlist_lookup_stocks(rowData: Store): Observable<SelectItem[]> {
    return this.lookupSelectOptions("stock", "stockName", {
      enableFieldName: "isActive",
      lookupFilter: JSON.stringify({ zoneId: { matchMode: "equals", value: rowData.zoneId } }),
    });
  }

  formlist_filter_subdivisions(colsFilters: { countryIsoCode: string }): SelectItem[] {
    if (colsFilters.countryIsoCode) return this.get_lookup_subdivisions(colsFilters.countryIsoCode);
    else return [];
  }

  getFormListColumns(): Col[] {
    return [
      // { field: '', header: '', visible: true, readonly: true, frozen: true, dataType: DataType.execute_event, dataOptions:[], dataTypeOptions:  new ExecuteEventDatatypeOptions({displayMode: ExecuteEvent_DisplayMode.LINK, label: 'Store Setup', event: this.businessSetupEvent, enabledOnEditMode: false, }), filterType: FilterType.none, colWidth: 90, },
      {
        field: "isActive",
        header: "Active",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.checkbox,
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.enum,
      },
      {
        field: "storeID",
        header: "Store ID",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 215,
      },
      {
        field: "storeName",
        header: "Store Name",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "zoneId",
        header: "Zone Name",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.lookup,
        dataOptions: this.lookup_zones(),
        filterType: FilterType.lookup,
        sortFields: ["zone.zoneName"],
        onModelChange: this.onZoneChanged.bind(this),
      },
      {
        field: "stockId",
        header: "Default Stock",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.lookup,
        dataOptions: this.lookupAllStocks(),
        filterType: FilterType.lookup,
        sortFields: ["stock.stockName"],
      },
      // { field: 'stockId', header: 'Default Stock', visible: true, readonly: false, frozen: false, dataType: DataType.lookup, dataOptions: this.formlist_lookup_stocks.bind(this), filterType: FilterType.lookup, sortFields: ['stock.stockName'] },
      {
        field: "storeTimeZone",
        header: "Time Zone",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.autocomplete,
        dataOptions: [],
        filterType: FilterType.contains,
        dataTypeOptions: new AutocompleteDatatypeOptions({
          completeMethod: this.searchTimeZone.bind(this),
          transformer: new TimeZoneAutocompleteTransformer(),
        }),
        colWidth: 300,
        showValidationErrors: true,
      },

      // { field: 'storeNumber', header: 'Store Number', visible: true, readonly: false, frozen: false, dataType: DataType.input, dataOptions: [], filterType: FilterType.contains },
      {
        field: "stationCount",
        header: "# of Stations",
        visible: true,
        readonly: true,
        isNotSortable: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.none,
      },
      {
        field: "address.countryIsoCode",
        header: "Country",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.get_enumCountries(),
        filterType: FilterType.enum,
      },
      {
        field: "address.subDivisionIsoCode",
        header: "Region",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        filterDataKey: "subDivisionIsoCode",
        dataOptions: this.formlist_lookup_subdivisions.bind(this),
        globalFilterMatchMode: "contains",
        globalFilterDataOptions: this.locationService.lookupAllSubdivisions(),
        filterType: FilterType.enum,
        filterOptions: this.formlist_filter_subdivisions.bind(this),
        filterMatchMode: "contains",
        filterField: "`subDivisions`",
        colWidth: 250,
      },
      {
        field: "address.city",
        header: "City",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 350,
      },
      {
        field: "address.postalCode",
        header: "Postal Code",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
    ];
  }

  destroy() {
    this.observables_stocks.clear();
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
