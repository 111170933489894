import { Component, Input, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "taku-self-checkout-footer-labels",
  templateUrl: "./self-checkout-footer-labels.component.html",
  styleUrls: ["./self-checkout-footer-labels.component.scss"],
})
export class SelfCheckoutFooterLabelsComponent implements OnInit {
  @Input() isBgColorWhite = false;

  appVersion = environment.common.APP_VERSION;

  constructor() {}

  ngOnInit(): void {}
}
