/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { GenericPhone } from "../phone-shared/GenericPhone";

export class CommercialAccountPhone implements GenericPhone {
  id: number;
  tel: string;
  ext: string;
  countryIsoCode: string;
  phoneType: CommercialPhoneType;

  constructor() {
    this.id = 0;
    this.tel = "";
    this.ext = "";
    this.countryIsoCode = "";
    this.phoneType = CommercialPhoneType.work;
  }
}

export enum CommercialPhoneType {
  work = "Work",
  fax = "Fax",
  mobile = "Mobile",
  home = "Home",
  other = "Other",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
