/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { round } from "lodash";

export class InventoryValueReport {
  id: number;
  SKU: string;
  categoryName: string;
  description1: string;
  qtyOH: number;
  qtySold: number;
  standardPrice: number;
  standardCost: number;
  tags: string;
  marginPercentCalculated: number;
  marginAmountCalculated: number;

  constructor() {
    this.id = 0;
    this.SKU = null;
    this.categoryName = null;
    this.description1 = null;
    this.qtyOH = null;
    this.qtySold = null;
    this.standardPrice = null;
    this.standardCost = null;
    this.tags = null;
    this.marginPercentCalculated = 0;
    this.marginAmountCalculated = 0;
  }

  get marginPercent() {
    if (this.standardPrice == 0) return "0";
    if (Number.isNaN((this.standardPrice - this.standardCost) / this.standardPrice)) return "0";
    return (((this.standardPrice - this.standardCost) / this.standardPrice) * 100).toFixed(2);
  }

  get marginAmount() {
    return (this.standardPrice - this.standardCost).toFixed(4);
  }

  get totalSoldPrice() {
    return (this.qtyOH * this.standardPrice).toFixed(4);
  }

  get totalCost() {
    return (this.qtyOH * this.standardCost).toFixed(4);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
