/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, OnDestroy } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { AuthService } from "../../../shared/services/auth.service";
import { MessageService } from "primeng/api";
import { AlertMessagesService } from "../../../shared/services/alert-messages.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "taku-password-reset",
  templateUrl: "./password-reset.component.html",
  styleUrls: ["./password-reset.component.scss"],
})
export class PasswordResetComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];
  formGroup: UntypedFormGroup;
  showConfirmation = false;

  constructor(
    private fb: UntypedFormBuilder,
    protected _router: Router,
    private auth: AuthService,
    private messageService: MessageService,
    protected alertMessage: AlertMessagesService,
    private _route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.formGroup = this.fb.group(
      {
        password: this.fb.control("", [Validators.required]),
        confirmPassword: this.fb.control("", [Validators.required]),
      },
      { validators: this.confirmPasswordMatch(), updateOn: "change" }
    );

    this.subsList.push(
      this.formGroup.controls.confirmPassword.valueChanges.subscribe(() =>
        this.formGroup.controls.confirmPassword.markAsTouched()
      )
    );
  }

  onBack() {
    this._router.navigateByUrl("/login");
  }

  onResetPassword() {
    this.subsList.push(
      this._route.queryParams.subscribe((qparams) => {
        const { merchantAccount, token, username } = qparams;
        const password = this.formGroup.get("password").value;
        const confirmPassword = this.formGroup.get("confirmPassword").value;

        this.subsList.push(
          this.auth.resetPassword(merchantAccount, token, password, confirmPassword).subscribe(
            () => {
              this.showConfirmation = true;
            },
            (error) => {
              this.messageService.add(this.alertMessage.getErrorMessage(error));
            }
          )
        );
      })
    );
  }

  confirmPasswordMatch() {
    return (formGroup: FormGroup) => {
      const password = formGroup.controls["password"];
      const confirmPassword = formGroup.controls["confirmPassword"];

      if (password.value !== confirmPassword.value) {
        confirmPassword.setErrors({ confirmPasswordMatch: true });
      } else {
        confirmPassword.setErrors(null);
      }
    };
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
