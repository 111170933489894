<div class="grid">
  <div class="col-12 xl:col-6 drop-container">
    <taku-dropdown
      [formGroup]="formGroup"
      [options]="lookupTAKUPayAccounts$ | async"
      fieldName="takuPaymentAccountId"
      fieldCaption="TAKU Pay Account Name"
    ></taku-dropdown>
  </div>

  <div class="col-12 xl:col-6">
    <taku-taku-calendar-range
      [formGroup]="formGroup"
      fieldCaption="Date Range"
      fieldNameRangeType="dateRangeType"
      fieldNameDateFrom="dateFrom"
      fieldNameDateTo="dateTo"
    ></taku-taku-calendar-range>
  </div>
</div>
