/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { Observable, Observer, of } from "rxjs";
import { KioskDeactivateDialogComponent } from "../core/document/sale-document/kiosk-deactivate-dialog/kiosk-deactivate-dialog.component";
import { AppSettingsStorageService } from "../shared/app-settings-storage.service";
declare const Intercom: any;

@Injectable({
  providedIn: "root",
})
export class KioskGuard implements CanDeactivate<Component> {
  constructor(
    public ref: DynamicDialogRef,
    public dialogService: DialogService,
    private appSettings: AppSettingsStorageService,
    protected _router: Router
  ) {}

  canDeactivate(
    component: Component,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Observable((observer: Observer<boolean>) => {
      if (nextState.url.includes("selfCheckout/landingPage")) {
        observer.next(true);
        observer.complete();
      } else if (this.ref.onClose.source["observers"].length > 1) {
        observer.next(false);
        observer.complete();
      } else {
        if (nextState.url === "/kiosk/selfCheckout/landingPage") {
          observer.next(true);
          observer.complete();
        } else {
          this.ref = this.dialogService.open(KioskDeactivateDialogComponent, {
            // styleClass: 'fullscreen-mobilemodal dialogSidebar',
            // contentStyle: {
            //   overflow: 'auto',
            //   height: 'calc(100vh - 40pt)',
            //   minWidth: '580pt',
            // },
            header: "Enter password to exit kiosk mode",
            closable: false,
            closeOnEscape: false,
          });
          this.ref.onClose.subscribe((leaveKioskPage) => {
            observer.next(leaveKioskPage);
            observer.complete();
            if (leaveKioskPage) {
              if (!window.isOnSamsungKiosk) {
                Intercom("update", {
                  hide_default_launcher: false,
                });
              }
              this._router.navigate(["/dashboard"]);
            }
          });
        }
      }
    });
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const cashout = this.appSettings.getCashout();
    if (!cashout || cashout.isClosed) {
      this._router.navigate(["/sell/cashout", 0, "kiosk"]);
      return false;
    }

    return true;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
