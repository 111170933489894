<taku-form-dialoge-header
  [pristine]="_myForm.pristine"
  [valid]="_myForm.valid"
  (backClicked)="goBack()"
  (saveClicked)="onSave()"
  (revertClicked)="onRevert()"
  (cancelClicked)="closeDialog()"
  (newClicked)="onNew()"
  (deleteClicked)="onDelete()"
  (cloneClicked)="onClone()"
  toolbarTitle="Brand Details"
>
</taku-form-dialoge-header>

<form [formGroup]="_myForm" novalidate *ngIf="active">
  <div class="card card-w-title">
    <div class="grid form-group">
      <div class="col-12">
        <taku-checkbox [formGroup]="_myForm" fieldName="isEnabled" fieldCaption="Enabled"></taku-checkbox>
      </div>

      <div class="col-12">
        <taku-input id="brand_name" [formGroup]="_myForm" fieldName="name" fieldCaption="Brand Name"> </taku-input>
      </div>
    </div>
  </div>
</form>

<taku-form-footer></taku-form-footer>
