/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

@Component({
  selector: "taku-form-dialog-input",
  templateUrl: "./form-dialog-input.component.html",
  styleUrls: ["./form-dialog-input.component.scss"],
})
export class FormDialogInputComponent implements OnInit {
  value: string = null;
  _caption: string;
  _acceptLabel: string;
  _message: string;
  _inputType: string;
  _required: boolean;
  _pattern: string;

  constructor(private dialogRef: DynamicDialogRef, public el: ElementRef, private dialogConfig: DynamicDialogConfig) {
    this._caption = dialogConfig.data.caption;
    this._acceptLabel = dialogConfig.data.acceptLabel;
    this._message = dialogConfig.data.message;
    this._inputType = dialogConfig.data.inputType || "text";
    this._required = dialogConfig.data.required || false;
    this._pattern = dialogConfig.data.pattern || "";
    this.value = dialogConfig.data.defaultValue || "";
  }

  ngOnInit(): void {
    const inputField = this.el.nativeElement.querySelector("input");
    setTimeout(() => {
      inputField.focus();
    }, 100);
  }

  onAcceptPressed() {
    this.dialogRef.close(this.value);
  }

  onCancelPressed() {
    this.dialogRef.close(null);
  }

  clear() {
    this.value = null;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
