import { Injectable } from "@angular/core";
import { MessageService } from "primeng/api";
import { DynamicDialogRef, DialogService } from "primeng/dynamicdialog";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";
import { BlockingUIService } from "src/app/shared/services/ui/blocking-ui.service";
import { TakuPayService } from "../../settings/integration-settings/taku-pay/taku-pay.service";
import {
  TakuPayCommandName,
  TakuPayMethodType,
  TakuPayCaptureMethod,
  TakuPaymentStatus,
} from "../../settings/integration-settings/taku-pay/taku-payment-gateway-terminals/taku-payment-terminal";
import { AccountCreditCard, AccountCreditCardStatus } from "../account-credit-card";
import { Account } from "../account/account";
import { Subscription } from "rxjs";

export class PreAuthUserData {
  card_number: number;
  card_month_exp: string;
  card_year_exp: string;
  card_cvv: number;
  billing_address: PreAuthBillingAddress;
  constructor() {
    (this.card_number = null),
      (this.card_month_exp = ""),
      (this.card_year_exp = ""),
      (this.card_cvv = null),
      (this.billing_address = new PreAuthBillingAddress());
  }
}

export class PreAuthBillingAddress {
  zip: string;
  constructor() {
    this.zip = "";
  }
}

@Injectable({
  providedIn: "root",
})
export class AdyenIntegrationService {
  dialogRef?: DynamicDialogRef;
  isWaiting = false;
  progressBar: DynamicDialogRef;
  subsList: Subscription[] = [];

  constructor(
    private dialogService: DialogService,
    private appSettingsService: AppSettingsStorageService,
    private messageService: MessageService,
    private alertMessagesService: AlertMessagesService,
    private takuPayService: TakuPayService,
    private blockUIService: BlockingUIService
  ) {}

  submitPreAuthorization(account: Account, preAuthUserData: PreAuthUserData) {
    this.isWaiting = true;
    this.progressBar = this.blockUIService.showProgressBar(this.dialogService, false);

    const apiCommand = TakuPayCommandName.COMMAND_INTENT;
    const apiBody = {
      amount: 0,
      payment_method_types: TakuPayMethodType.CARD_NOT_PRESENT,
      capture_method: TakuPayCaptureMethod.MANUAL,
      external_customer_id: account.id.toString(),
      allow_saving_card: true,
    };

    this.subsList.push(
      this.takuPayService.runTransaction(apiCommand, this.appSettingsService.getStoreId(), apiBody).subscribe({
        next: (response) => {
          let authJson = {
            amount: 0,
            payment_id: response.payment_id,
            external_transaction_id: response.external_transaction_id,
            capture_method: TakuPayCaptureMethod.MANUAL, // only putting this in here for our backend to tell the difference for what intent was used.
            ...preAuthUserData,
          };

          if (apiBody.capture_method) {
            // only putting this in here for our backend to tell the difference for what intent was used.
            authJson = Object.assign(authJson, { capture_method: apiBody.capture_method });
          }

          this.takuPayService
            .runTransaction(TakuPayCommandName.COMMAND_AUTH, this.appSettingsService.getStoreId(), authJson)
            .subscribe({
              next: (response) => {
                this.progressBar.close();
                this.isWaiting = false;

                const result = response["body"]?.["result"];
                if (
                  result &&
                  (result["status"] === TakuPaymentStatus.STATUS_SUCCESS ||
                    result["status"] === TakuPaymentStatus.STATUS_CAPTURE_REQUESTED ||
                    result["status"] === TakuPaymentStatus.STATUS_REQUIRES_CAPTURE)
                ) {
                  // Check card exist or not
                  const filteredCard: AccountCreditCard[] = account?.accountCreditCards.filter(
                    (card: AccountCreditCard) =>
                      card.accountId === account.id &&
                      card.token === result?.["payment_method_id"] &&
                      card.status === AccountCreditCardStatus.ACTIVE
                  );

                  const card = new AccountCreditCard();

                  if (filteredCard.length === 0) {
                    card.accountId = account.id;
                    card.token = result?.["payment_method_id"];
                    card.status = AccountCreditCardStatus.ACTIVE;
                    card.cardType = result?.["payment"]?.["brand"];
                    card.last4Digits = result?.["payment"]?.["last_4"];
                    card.expiryDate =
                      (result?.["payment"]?.["exp_month"]).toString() +
                      "/" +
                      (result?.["payment"]?.["exp_year"]).toString().slice(-2);
                    card.cardHolderName = result?.["payment"]?.["cardholder_name"];
                    card.isDefault = account.id > 0 && result?.["payment_method_id"] !== null ? true : false;
                    card.postalCode = preAuthUserData.billing_address.zip;

                    this.messageService.add({
                      summary: "Success",
                      severity: "success",
                      detail: `Transaction Approved.`,
                    });

                    if (this.dialogRef) {
                      this.dialogRef.close(card);
                    }
                  } else {
                    this.messageService.add({
                      summary: "Success",
                      severity: "success",
                      detail: `Transaction Approved - Card already exists.`,
                    });

                    if (this.dialogRef) {
                      this.dialogRef.close(card);
                    }
                  }
                } else {
                  this.messageService.add({
                    summary: "Error",
                    severity: "error",
                    detail: `Transaction Error.`,
                  });
                }
              },
              error: (errorResponse) => {
                this.progressBar.close();
                this.isWaiting = false;
                this.messageService.add(this.alertMessagesService.getErrorMessage(errorResponse));
              },
            });
        },
        error: (errorResponse) => {
          this.progressBar.close();
          this.isWaiting = false;
          this.messageService.add(this.alertMessagesService.getErrorMessage(errorResponse));
        },
      })
    );
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}
