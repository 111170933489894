/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Input, HostBinding, ElementRef, OnInit, Output, EventEmitter } from "@angular/core";
import { UntypedFormGroup, AbstractControl } from "@angular/forms";
import { WebHelpers } from "../utility/WebHelpers";
import { FormDataHelpers } from "../utility/FormDataHelpers";

@Component({
  template: "",
  styles: [],
})
export abstract class TakuInputCtrlBase implements OnInit {
  @Input() formGroup: UntypedFormGroup;
  @Input() fieldName: string;
  @Input() fieldCaption?: string;
  @Input() readonly = false;
  @Input() showValidationErrors = true;
  @Input() ngClass;

  private _disabled = false;
  /** Setting the 'disabled' Input will automatically call the Reactive Forms disable/enable methods.
   *  This allows us to remove `[disabled]=disabled` from the **inheriting** templates, which prevents the following console warning:
   *  >  It looks like you're using the disabled attribute with a reactive form directive. If you set disabled to true
   *  >  when you set up this control in your component class, the disabled attribute will actually be set in the DOM for
   *  >  you. We recommend using this approach to avoid 'changed after checked' errors.
   */
  @Input() set disabled(isDisabled: boolean) {
    this._disabled = isDisabled;
    if (this.ctrlField) {
      isDisabled ? this.ctrlField.disable() : this.ctrlField.enable();
    }
  }
  get disabled() {
    return this._disabled;
  }

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(public el: ElementRef) {}

  @HostBinding("class.input-noCaption")
  get hasNoInputCaption(): boolean {
    return this.fieldCaption == null;
  }

  get fullFieldCaption(): string {
    return FormDataHelpers.InputFieldCaption(this.fieldCaption || "", this.ctrlField);
  }

  get plainFullFieldCaption(): string {
    return FormDataHelpers.InputFieldCaption(this.fieldCaption || "", this.ctrlField, false);
  }

  get isRequiredField() {
    const fieldValidatorFn = this.ctrlField.validator;
    return fieldValidatorFn && fieldValidatorFn(this.ctrlField) && fieldValidatorFn(this.ctrlField).required;
  }

  get ctrlField(): AbstractControl {
    return this.formGroup?.get(this.fieldName);
  }

  get isFilled(): boolean {
    const fieldVal = this.ctrlField.value;
    return fieldVal != null && (!Array.isArray(fieldVal) || fieldVal.length > 0);
  }

  get isMobile(): boolean {
    return WebHelpers.isMobileScreen();
  }

  ngOnInit(): void {}
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
