/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DBService } from "../../../../shared/services/db.service";
import { FormListComponent } from "src/app/form-list/form-list/form-list.component";
import { MultiFormListComponent } from "src/app/form-list/form-list-main/multiple-form-lists.interface";
import { FormListMainComponent } from "src/app/form-list/form-list-main/form-list-main.component";
import { Subscription } from "rxjs";
import { ZoneTenderTypesService } from "../zone-tender-types/zone-tender-types.service";

@Component({
  selector: "taku-zone-form-list",
  templateUrl: "./zone-form-list.component.html",
  styleUrls: ["./zone-form-list.component.scss"],
})
export class ZoneFormListComponent implements OnInit, MultiFormListComponent, OnDestroy {
  subsList: Subscription[] = [];

  @ViewChild(FormListMainComponent, { static: true }) formListMainComponent: FormListMainComponent;

  activeZoneName = "";
  _filter: any;
  _defaultRowValues: {};
  // _isFormReady = false;

  get pageTitleSuffix() {
    return this.activeZoneName ? `&nbsp;<span class="secondary-text">(${this.activeZoneName})</span>` : "";
  }

  ngOnInit() {
    this.subsList.push(
      this._route.params.subscribe((params) => {
        if (params.zoneId) {
          const zoneId = +params.zoneId;
          // Set filters to show only the current zone
          this._filter = {
            zoneId: { value: zoneId, matchMode: "equals" },
          };

          this._defaultRowValues = { zoneId };
          // this._isFormReady = true;

          this.updatePageTitle(zoneId);
        }
      })
    );
    this.zoneTenderTypesService.cacheAfterLogin();
  }

  updatePageTitle(zoneId: number) {
    this.subsList.push(
      this.dbService.getRow("zone", zoneId).subscribe((zone) => {
        this.activeZoneName = zone.zoneName;
      })
    );
  }

  constructor(
    private _route: ActivatedRoute,
    private dbService: DBService,
    private zoneTenderTypesService: ZoneTenderTypesService
  ) {}

  retrieveFormLists(): FormListComponent[] {
    return [this.formListMainComponent.formListComponent];
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
