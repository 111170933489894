/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
import { EventEmitter, Injectable } from "@angular/core";
import { FormListModel } from "src/app/form-list/form-list/form-list-model.interface";
import { DBService } from "src/app/shared/services/db.service";
import { ValidatorFn } from "@angular/forms";
import {
  Col,
  FilterType,
  DataType,
  ExecuteEvent_DisplayMode,
  ExecuteEventDatatypeOptions,
} from "src/app/form-list/form-list/form-list";
import { SaleDocType } from "src/app/core/document/doc/doc";
import { SaleTaxReport } from "./sale-tax-report";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { SaledocRevisitPreviewComponent } from "src/app/core/document/sale-document/saledoc-revisit-preview/saledoc-revisit-preview.component";
import { SelectItem } from "primeng/api";
import { TaxCalcType } from "src/app/core/tax/tax-rule/tax-rule";

@Injectable({
  providedIn: "root",
})
export class SalesTaxReportColsFactoryService {
  constructor(public dbService: DBService, private dialogService: DialogService, public ref: DynamicDialogRef) {}

  build(dynamicFields: string[]) {
    return new SaleTaxReportCols(this, dynamicFields, this.dbService, this.dialogService, this.ref);
  }
}
export class SaleTaxReportCols implements FormListModel {
  invoiceDocDetails: EventEmitter<SaleTaxReport> = new EventEmitter();

  constructor(
    private factoryService: SalesTaxReportColsFactoryService,
    private dynamicFields: string[],
    public dbService: DBService,
    private dialogService: DialogService,
    public ref: DynamicDialogRef
  ) {
    this.invoiceDocDetails.subscribe((saleTaxReport: SaleTaxReport) => {
      this.dbService.getRow("saleDoc", saleTaxReport.saleDocId).subscribe((_saleDoc) => {
        this.ref = this.dialogService.open(SaledocRevisitPreviewComponent, {
          data: {
            _object: _saleDoc,
            _enabledEditMode: false,
          },
          width: "90%",
          height: "90%",
          closable: false,
          showHeader: false,
        });
      });
    });
  }

  enumDocumentTypes = this.factoryService.dbService.enumSelectOptions(SaleDocType).filter((row) => {
    return row.value === "Sales Invoice" || row.value === "Sales Return";
  });
  enumTaxCalcTypes = this.dbService.enumSelectOptions(TaxCalcType);
  _disableCloning = true;

  getDocNo(rowData) {
    return rowData.docNo ? rowData.docNo : "No Doc No";
  }

  getValidationRules(): { [key: string]: {} | ValidatorFn[] } {
    return {};
  }

  getFormListColumns(): Col[] {
    const cols: Col[] = [
      {
        field: "docNo",
        header: "Doc No",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.execute_event,
        dataOptions: [],
        dataTypeOptions: [
          new ExecuteEventDatatypeOptions({
            displayMode: ExecuteEvent_DisplayMode.LINK,
            label: this.getDocNo,
            event: this.invoiceDocDetails,
            enabledOnEditMode: false,
            styleClass: "",
          }),
        ],
        filterType: FilterType.contains,
        colWidth: 80,
      },
      {
        field: "docType",
        header: "Document Type",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enumDocumentTypes,
        filterType: FilterType.enum,
        colWidth: 120,
      },
      {
        field: "docClosingDate",
        header: "Date",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
      },
      {
        field: "taxAccountCategpry",
        header: "Tax Account Category",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
      },
      {
        field: "accountName",
        header: "Account Name",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
      },
      // {
      //   field: 'docNo', header: 'Doc No', visible: true, readonly: true, frozen: false, dataType: DataType.input,
      //   dataOptions: [], filterType: FilterType.contains, colWidth: 80
      // },
      {
        field: "taxID",
        header: "Account Tax#",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
      },
      {
        field: "subTotal",
        header: "Sub Total Amount",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
        footer: true,
      },
    ];
    this.dynamicFields.forEach((field, index) => {
      let _header = field;
      let _footer = true;
      let _dataType = DataType.number;
      let _dataOptions = [];
      let _filterType = FilterType.contains;
      if (field.includes("taxAccountNo")) {
        _header = field.replace("taxAccountNo", " Tax Number");
        _footer = false;
      }
      if (field.includes("taxCalcType")) {
        _header = field.replace("taxCalcType", " Tax Calculation Type");
        _footer = false;
        _dataType = DataType.enum;
        _dataOptions = this.enumTaxCalcTypes;
        _filterType = FilterType.enum;
      }
      if (field.includes("taxRate")) {
        _header = field.replace("taxRate", " Tax Rate");
        _footer = false;
      }
      if (field.includes("fundReceiver")) {
        _header = field.replace("fundReceiver", " Tax Receiver");
        _footer = false;
      }

      cols.push({
        field: field,
        header: _header,
        visible: true,
        readonly: true,
        frozen: false,
        dataType: _dataType,
        dataOptions: _dataOptions,
        filterType: _filterType,
        colWidth: 80,
        footer: _footer,
      });
    });
    cols.push({
      field: "totalAmountCalculated",
      header: "Total Amount",
      visible: true,
      readonly: true,
      frozen: false,
      dataType: DataType.number,
      dataOptions: [],
      filterType: FilterType.contains,
      colWidth: 80,
      footer: true,
    });
    return cols;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
