<div class="grid">
  <taku-form-header
    [formGroup]="_formGroup"
    (saveClicked)="onSave()"
    (revertClicked)="onRevert()"
    toolbarTitle="GMB LOCATION DESCRIPTION"
    displayMode="inline"
    [showBackBtn]="false"
    [showSecondaryBtns]="false"
  ></taku-form-header>

  <div class="col-12">
    <div class="col-12 drop-container">
      <taku-input-textarea
        [formGroup]="_formGroup"
        fieldName="description"
        fieldCaption="Description"
        [rows]="8"
      ></taku-input-textarea>
    </div>
  </div>
</div>
