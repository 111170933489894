/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { SaleChannel } from "../../sale-channel/sale-channel";

export class InventorySaleChannel {
  id: number;
  stockControl: StockControl;
  priceControl: PriceControl;
  channelInventoryId: string;
  saleChannelId: number;
  saleChannel: SaleChannel;
  uploadStatus: UploadStatus;
  onlineStatus: OnlineStatus;
  productDetails: ProductDetails;
  isFeatured: boolean;

  constructor() {
    this.id = 0;
    this.stockControl = StockControl.ChannelSetting;
    this.priceControl = PriceControl.ChannelSetting;
    this.channelInventoryId = null;
    this.saleChannelId = null;
    this.saleChannel = null;
    this.uploadStatus = UploadStatus.No;
    this.onlineStatus = null;
    this.productDetails = ProductDetails.ChannelSetting;
    this.isFeatured = false;
  }
}

export enum StockControl {
  ChannelSetting = "Channel Setting",
  Quantity = "Quantity",
  Unlimited = "Unlimited",
}

export enum PriceControl {
  ChannelSetting = "Channel Setting",
  SellPrice = "Sell Price",
  AltPrice = "Alt Price",
  SellAltPrice = "Sell < Price",
}

export enum UploadStatus {
  Yes = "Yes",
  No = "No",
}

export enum OnlineStatus {
  Published = "Published",
  Unpublished = "Unpublished",
}

export enum ProductDetails {
  ChannelSetting = "Channel Setting",
  MainProductTitle = "Main Product Title",
  AltProductTitle = "Alt Product Title",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
