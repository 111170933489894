/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Inject, OnInit, Optional } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { ArrayFormComponent, DATA_ARRAY_TOKEN } from "src/app/forms/array-form/array-form.component";
import { DISPLAY_MODE_TOKEN } from "src/app/forms/nested-form/nested-form.component";
import { InventoryStockComponent } from "../inventory-stock/inventory-stock.component";
import { InventoryStockService } from "../inventory-stock/InventoryStock.service";
import { WebHelpers } from "src/app/utility/WebHelpers";

@Component({
  selector: "taku-inventory-stocklevels-array",
  templateUrl: "./inventory-stocklevels-array.component.html",
  styleUrls: ["./inventory-stocklevels-array.component.scss"],
})
export class InventoryStockLevelsArrayComponent extends ArrayFormComponent implements OnInit {
  constructor(
    private inventoryStockService: InventoryStockService,
    fb: UntypedFormBuilder,
    @Optional() @Inject(DATA_ARRAY_TOKEN) injectedDataArray,
    @Optional() @Inject(DISPLAY_MODE_TOKEN) injectedDisplayMode
  ) {
    super(fb, injectedDataArray, injectedDisplayMode);
  }

  ngOnInit() {
    // Add additional stocks not initially available in object coming in from server
    this.inventoryStockService.populateFormArray(this._formArray);
  }

  get tableScrollHeight() {
    // calc(90vh - 45pt)
    return WebHelpers.isMobileScreen() ? "330pt" : "316pt";
  }

  // Override default setArray
  setArray(data: any[]) {
    return InventoryStockComponent.setArray(this.fb, data);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
