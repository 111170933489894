import { Component, Input } from "@angular/core";
import { InventoryStockDetail, QtyDetailsType } from "../inventory-stock-details/inventory-stock-details.component";

@Component({
  selector: "taku-inventory-stock-details-overlay",
  templateUrl: "./inventory-stock-details-overlay.component.html",
  styleUrls: ["./inventory-stock-details-overlay.component.scss"],
})
export class InventoryStockDetailsOverlayComponent {
  @Input() inventoryStockDetails: InventoryStockDetail[];
  @Input() qtyDetailsType: QtyDetailsType;
  protected readonly QtyDetailsType: typeof QtyDetailsType = QtyDetailsType;
}
