<div class="topWrapper">
  <taku-form-header [toolbarTitle]="_headerTitle" displayMode="inline" [showActionBtns]="false" [showBackBtn]="false">
    <button pButton class="p-button-rounded" type="button" (click)="onClosePressed()" icon="pi pi-times"></button>
  </taku-form-header>
  <div class="bodyWrapper">
    <main>
      <div id="doc-fields">
        <table cellspacing="0">
          <tr>
            <td class="label-col">Commitment #</td>
            <td class="value-col">{{ commitments?.docNo }}</td>
          </tr>
          <tr>
            <td class="label-col">Type</td>
            <td class="value-col">{{ commitments?.refSaleDoc?.doc?.docType }}</td>
          </tr>
          <tr>
            <td class="label-col">Date & Time</td>
            <td class="value-col">{{
              commitments?.refSaleDoc?.doc?.docDate | dateTimeFormat : commitments?.refSaleDoc?.doc?.docTime
            }}</td>
          </tr>
          <tr>
            <td class="label-col">Store (Station) User</td>
            <td class="value-col"
              >{{ commitments?.store?.storeName }}
              {{
                commitments?.refSaleDoc?.station.stationName
                  ? "(" + commitments?.refSaleDoc?.station.stationName + ")"
                  : ""
              }}
              {{ commitments?.refSaleDoc?.doc?.user ? commitments?.refSaleDoc?.doc?.user.userName : "" }}</td
            >
          </tr>
          <tr>
            <!-- TODO handle commercial account -->
            <td class="label-col">Customer Address</td>
            <td class="value-col"
              >{{ commitments?.personalAccount?.person?.personAddresses[0]?.address.line2 }}
              {{ commitments?.personalAccount?.person?.personAddresses[0]?.address.line1 }}
              {{ commitments?.personalAccount?.person?.personAddresses[0]?.address.postalCode }}</td
            >
          </tr>
          <tr>
            <td class="label-col">Customer</td>
            <td class="value-col"
              ><span class="cursor-pointer link" (click)="openCustomerDetails()">{{
                commitments.accountName
              }}</span></td
            >
          </tr>
          <tr>
            <td class="label-col">Customer Email</td>
            <td class="value-col">
              <ul *ngFor="let email of commitments.accountEmail; trackBy: identify" class="customer-email-list">
                <li>
                  <a href="{{ 'mailto:' + email }}">{{ email }}</a>
                  <taku-action-button
                    [size]="BUTTON_SIZE.SMALL"
                    [action]="ACTION_TYPE.COPY"
                    (clickAction)="copyEmailToClipboard(email)"
                  ></taku-action-button>
                </li>
              </ul>
            </td>
          </tr>
        </table>

        <table cellspacing="0">
          <tr>
            <td class="label-col">Total</td>
            <td class="value-col">{{
              commitments.subTotal | currency : _currencyISOCode : "symbol-narrow" : "1.2"
            }}</td>
          </tr>
          <tr class="payType-line">
            <td class="label-col">Pay Type</td>
            <td class="value-col">
              <p-dropdown [options]="getPayType()" class="pay-type-dropdown" [(ngModel)]="selectedPayType"></p-dropdown>
              <taku-tender-details-overlay [saleDocTenders]="selectedPayType"></taku-tender-details-overlay>
            </td>
          </tr>
          <tr>
            <td class="label-col">Order Status</td>
            <td class="value-col"
              ><p-tag [value]="commitments?.state" [severity]="getCommitmentsStatus()" [rounded]="true"></p-tag
            ></td>
          </tr>
          <tr class="payment-cycle-line">
            <td class="label-col">Order Cycle</td>
            <td class="value-col flex">
              <div class="single-line">
                <taku-dropdown
                  [formGroup]="footerFormGroup"
                  [options]="lookup_recurringOrderSetting"
                  class="payment-cycle-dropdown"
                  fieldName="recurringOrderSettingId"
                  appendTo="body"
                  (onChange)="onPaymentCycleChanged()"
                >
                </taku-dropdown>
              </div>
              <div class="single-line">
                <span class="pl-2 repeat-cycle"
                  >{{ getRepeatCycle() }}
                  <a class="material-icons md-18" style="margin-right: 20px" (click)="commitmentPanel.toggle($event)"
                    >info</a
                  >
                </span>
                <p-overlayPanel #commitmentPanel [showCloseIcon]="true" appendTo="body">
                  <taku-form-header
                    [toolbarTitle]="paymentCycleTitle"
                    displayMode="inline"
                    [showActionBtns]="false"
                    [showBackBtn]="false"
                  >
                  </taku-form-header>
                  <p-table
                    [value]="commitments.commitmentCycles"
                    styleClass="p-datatable-gridlines"
                    responsiveLayout="stack"
                    [breakpoint]="'960px'"
                  >
                    <ng-template pTemplate="header" let-columns="columns">
                      <tr>
                        <th>Recurring Order #</th>
                        <th>Order Gen Date</th>
                        <th>Total</th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-cycle let-rowIndex="rowIndex" let-columns="columns">
                      <tr *ngIf="cycle.refSaleDoc">
                        <td><span class="p-column-title">Recurring Order #</span>{{ cycle.refSaleDoc?.doc?.docNo }}</td>
                        <td><span class="p-column-title">Order Gen Date</span>{{ cycle.refSaleDoc?.doc?.docDate }}</td>
                        <td
                          ><span class="p-column-title">Total</span
                          >{{
                            cycle.refSaleDoc?.subTotal
                              | currency
                                : cycle.refSaleDoc?.saleDocTenders[0]?.tenderType.currencyIsoCode
                                : "symbol-narrow"
                                : "1.2-2"
                              | formatNegativeNumber
                          }}</td
                        >
                      </tr>
                    </ng-template>
                  </p-table>
                </p-overlayPanel>
              </div>
              <div class="single-line">
                <button
                  [disabled]="false"
                  [hidden]="true"
                  pButton
                  pRipple
                  (click)="onVoidBtnClicked()"
                  [disabled]="isVoidedButtonDisabled()"
                  [icon]="'pi pi-ban'"
                  class="p-button-icon-only c-button"
                >
                </button>
                <button
                  [disabled]="false"
                  pButton
                  pRipple
                  (click)="onPauseBtnClicked()"
                  [disabled]="isPauseButtonDisabled()"
                  [icon]="getIcon()"
                  class="p-button-icon-only c-button"
                >
                </button>
                <button
                  [disabled]="false"
                  pButton
                  pRipple
                  (click)="onSkipBtnClicked()"
                  [disabled]="isSkipButtonDisabled()"
                  [icon]="'pi pi-step-forward-alt'"
                  class="p-button-icon-only c-button"
                >
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td class="label-col">Outgoing Date</td>
            <td class="value-col">
              {{ commitments.nextPickupDate }}
            </td>
          </tr>
          <tr class="delivery-method-line">
            <td class="label-col">Delivery Method | Shipping Fees</td>
            <td class="value-col delivery-method">
              <span class="line-item">{{ footerFormGroup.get("deliveryMethod").value }}</span>
              <span class="line-item-center">
                <a
                  *ngIf="_enabledEditMode"
                  class="material-icons md-18"
                  (click)="deliveryMethodPanel.toggle($event)"
                  [class.disabled-link]="saleChannelOrder?.orderStatus === 'VOIDED' || isVoided"
                  >edit
                  <p-overlayPanel #deliveryMethodPanel [showCloseIcon]="false" appendTo="body">
                    <taku-dropdown
                      style="width: 200px"
                      [formGroup]="footerFormGroup"
                      [options]="enum_saledoc_delivery_method"
                      fieldName="deliveryMethod"
                      fieldCaption="Delivery Method"
                      appendTo="body"
                      (onChange)="onDeliveryMethodChanged()"
                    >
                    </taku-dropdown>
                  </p-overlayPanel>
                </a>
              </span>
              <span class="line-item-center">{{
                commitments.shipperChargeAmount | currency : _currencyISOCode : "symbol-narrow" : "1.2"
              }}</span>
              <span class="line-item-right">
                <a
                  *ngIf="_enabledEditMode"
                  class="material-icons md-18"
                  (click)="ShippingFeesPanel.toggle($event)"
                  [class.disabled-link]="saleChannelOrder?.orderStatus === 'VOIDED' || isVoided"
                  >edit
                  <p-overlayPanel #ShippingFeesPanel [showCloseIcon]="false" appendTo="body">
                    <taku-input
                      [formGroup]="footerFormGroup"
                      fieldName="shipperChargeAmount"
                      fieldCaption="Shipping Fees"
                      (onBlur)="onShippingFeesChanged()"
                    ></taku-input>
                  </p-overlayPanel>
                </a>
              </span>
            </td>
          </tr>
          <tr>
            <td class="label-col">Notes</td>
            <td class="value-col">{{ commitments?.refSaleDoc?.doc?.voidReason }}</td>
          </tr>
        </table>
      </div>
    </main>
    <taku-commitments-transactions
      *ngIf="commitments.commitmentCycles"
      [commitments]="commitments"
    ></taku-commitments-transactions>
    <footer>
      <div class="footer-btns grid">
        <div class="btn-container-wrapper">
          <div class="btn-container">
            <button
              pButton
              [disabled]="!printMgr.isReady"
              class="btn-action reprint-btn squared-corners"
              [label]="'Reprint'"
              icon="pi pi-print"
              (click)="menuPrintSizes.toggle($event)"
            >
            </button>
          </div>
          <div *ngIf="_enabledEditMode" class="btn-container">
            <button
              pButton
              class="btn-action"
              label="Void"
              icon="pi pi-ban"
              [disabled]="isVoidedButtonDisabled()"
              (click)="onVoidBtnClicked()"
            ></button>
          </div>
        </div>
      </div>
    </footer>

    <p-menu #menuPrintSizes [popup]="true" [model]="printSizesMenuItems" appendTo="body"></p-menu>
    <!-- <p-menu #menuEmailReceiptSizes [popup]="true" [model]="emailReceiptSizesMenuItems" appendTo="body"></p-menu> -->
  </div>
</div>

<p-dialog
  [(visible)]="_activeFullDialog"
  [ngSwitch]="_activeFullDialog"
  [contentStyle]="{ 'max-height': 'calc(100% - 50px)' }"
  class="fullsize-modal"
  [blockScroll]="true"
  [modal]="false"
  appendTo="body"
  [showHeader]="true"
  (onHide)="closeFullSizeDialog()"
  [styleClass]="_activeFullDialog !== 'printPreview' ? 'fixedsize-modal' : null"
>
  <div *ngSwitchCase="'printPreview'" class="print-preview"> </div>
</p-dialog>

<div [hidden]="true">
  <ng-template #printPreviewHost></ng-template>
</div>
