<p-accordion>
  <p-accordionTab header="ERROR DETAILS" [(selected)]="expanded">
    <!-- <h3>ERROR DETAILS</h3> -->
    <ul>
      <li *ngFor="let fieldErrors of _errorsMessages">
        <span class="field-name" [innerHTML]="fieldErrors.field"></span>:
        <span class="field-errors">{{ fieldErrors.errorMessages }}</span>
      </li>
    </ul>

    <em *ngIf="!_errorsMessages.length">NO ERRORS FOUND</em>
  </p-accordionTab>
</p-accordion>
