<taku-form-header
  [displayMode]="_isDialog ? 'inline' : null"
  [formGroup]="_myForm"
  (backClicked)="goBack()"
  (saveClicked)="onSave()"
  (revertClicked)="onRevert()"
  (newClicked)="onNew()"
  (deleteClicked)="onDelete()"
  (cloneClicked)="onClone()"
  [toolbarTitle]="_toolbarTitle"
  [insideDialog]="_isDialog"
  [isSaving]="_isSaving"
></taku-form-header>

<div class="col-12 pl-0 pr-0">
  <form [formGroup]="_myForm" name="commercialAccountMain">
    <div class="grid commercial-account-container taku-expanded-gridcols-container">
      <div class="col-12 lg:col-3 md:col-5 account-details-container">
        <taku-account [accountAccessRights]="accountAccessRights" [formGroup]="_myForm.get('account')"></taku-account>
      </div>

      <div
        class="col-12 lg:col-6 md:col-7"
        *ngIf="
          accountAccessRights.isGeneralTabVisible ||
          accountAccessRights.isAddressTabVisible ||
          accountAccessRights.isContactsTabVisible
        "
      >
        <p-panel styleClass="panel-nopad">
          <ng-template pTemplate="header">
            <h2>Commercial Details</h2>
          </ng-template>

          <p-tabView [(activeIndex)]="_mainTabActiveIndex">
            <p-tabPanel
              *ngIf="accountAccessRights.isGeneralTabVisible"
              [disabled]="!accountAccessRights.isGeneralTabEnabled"
            >
              <ng-template pTemplate="header">
                <i class="material-icons mr-2">business</i>
                <span>General</span>
              </ng-template>
              <div class="grid">
                <div class="col-12">
                  <h5>General Information</h5>
                </div>

                <div class="col-12 lg:col-3">
                  <taku-input
                    id="company_name"
                    [formGroup]="_myForm"
                    [fieldName]="'name'"
                    [fieldCaption]="'Company Name'"
                  >
                  </taku-input>
                </div>
                <div class="col-12 lg:col-3">
                  <taku-input [formGroup]="_myForm" [fieldName]="'accountBusinessNum'" [fieldCaption]="'Business #'">
                  </taku-input>
                </div>

                <div class="col-12 lg:col-6">
                  <taku-input [formGroup]="_myForm" [fieldName]="'url'" fieldCaption="Website Address"></taku-input>
                </div>

                <!-- <div class="col-12">
                  <taku-input [formGroup]="_myForm" [fieldName]="'note'" [fieldCaption]="'Note'"> </taku-input>
                </div> -->

                <p-fieldset class="col-12 taku-listable-container" formArrayName="commercialAccountPhones">
                  <ng-template pTemplate="header">
                    <h5>Commercial Phone Numbers</h5>
                  </ng-template>

                  <div class="add-button-container">
                    <a class="add-button" (click)="addCommercialAccountPhone()">
                      <label>Add New</label>
                      <i class="material-icons">add_circle_outline</i>
                    </a>
                  </div>

                  <div
                    class="col-12 taku-listable-controlset"
                    *ngFor="
                      let commercialAccountPhoneForm of _myForm.controls['commercialAccountPhones']['controls'];
                      let i = index
                    "
                  >
                    <div class="col-11">
                      <taku-commercial-account-phone
                        [formGroup]="commercialAccountPhoneForm"
                      ></taku-commercial-account-phone>
                    </div>
                    <div class="col-1 remove-button-container">
                      <taku-action-button
                        [action]="ACTION_TYPE.REMOVE"
                        (clickAction)="removeCommercialAccountPhone(i)"
                      ></taku-action-button>
                    </div>
                  </div>
                </p-fieldset>

                <p-fieldset class="col-12 taku-listable-container" formArrayName="commercialAccountEmails">
                  <ng-template pTemplate="header">
                    <h5>Commercial Email Addresses</h5>
                  </ng-template>

                  <div class="add-button-container">
                    <a class="add-button" (click)="addCommercialAccountEmail()">
                      <label>Add New</label>
                      <i class="material-icons">add_circle_outline</i>
                    </a>
                  </div>

                  <div
                    class="col-12 taku-listable-controlset"
                    *ngFor="
                      let commercialEmailForm of _myForm.controls['commercialAccountEmails']['controls'];
                      let i = index
                    "
                  >
                    <div class="col-11">
                      <taku-commercial-account-email [formGroup]="commercialEmailForm"></taku-commercial-account-email>
                    </div>

                    <div class="col-1 remove-button-container">
                      <taku-action-button
                        [action]="ACTION_TYPE.REMOVE"
                        (clickAction)="removeCommercialAccountEmail(i)"
                      ></taku-action-button>
                    </div>
                  </div>
                </p-fieldset>

                <p-fieldset class="col-12 taku-listable-container" formArrayName="commercialAccountInstantMsgs">
                  <ng-template pTemplate="header">
                    <h5>Commercial Instant Messengers</h5>
                  </ng-template>

                  <div class="add-button-container">
                    <a class="add-button" (click)="addCommercialAccountInstantMsg()">
                      <label>Add New</label>
                      <i class="material-icons">add_circle_outline</i>
                    </a>
                  </div>
                  <div
                    class="col-12 taku-listable-controlset"
                    *ngFor="
                      let commercialInstantMsgForm of _myForm.controls['commercialAccountInstantMsgs']['controls'];
                      let i = index
                    "
                  >
                    <div class="col-11">
                      <taku-commercial-account-instant-msg
                        [formGroup]="commercialInstantMsgForm"
                      ></taku-commercial-account-instant-msg>
                    </div>
                    <div class="col-1 remove-button-container">
                      <taku-action-button
                        [action]="ACTION_TYPE.REMOVE"
                        (clickAction)="removeCommercialAccountInstantMsg(i)"
                      ></taku-action-button>
                    </div>
                  </div>
                </p-fieldset>

                <p-fieldset class="col-12 taku-listable-container" formArrayName="commercialAccountSocialProfiles">
                  <ng-template pTemplate="header">
                    <h5>Social Media</h5>
                  </ng-template>

                  <div class="add-button-container">
                    <a class="add-button" (click)="addCommercialAccountSocialProfile()">
                      <label>Add New</label>
                      <i class="material-icons">add_circle_outline</i>
                    </a>
                  </div>

                  <div
                    class="col-12 taku-listable-controlset"
                    *ngFor="
                      let commercialSocialProfileForm of _myForm.controls['commercialAccountSocialProfiles'][
                        'controls'
                      ];
                      let i = index
                    "
                  >
                    <div class="col-11">
                      <taku-commercial-account-social-profile
                        [formGroup]="commercialSocialProfileForm"
                      ></taku-commercial-account-social-profile>
                    </div>
                    <div class="col-1 remove-button-container">
                      <taku-action-button
                        [action]="ACTION_TYPE.REMOVE"
                        (clickAction)="removeCommercialAccountSocialProfile(i)"
                      ></taku-action-button>
                    </div>
                  </div>
                </p-fieldset>
              </div>
            </p-tabPanel>

            <p-tabPanel
              class="tabview-nopad"
              *ngIf="accountAccessRights.isAddressTabVisible"
              [disabled]="!accountAccessRights.isAddressTabEnabled"
            >
              <ng-template pTemplate="header">
                <i class="material-icons mr-2">place</i>
                <span>Address</span>
              </ng-template>

              <div class="col-12 address-details">
                <div formArrayName="commercialAccountAddresses">
                  <div class="col-12 p-fluid">
                    <p-selectButton
                      [options]="_addressTypes"
                      [(ngModel)]="_addressMgr.activeAddressFilter"
                      [ngModelOptions]="{ standalone: true }"
                    ></p-selectButton>
                  </div>

                  <div class="col-12 address-tabview-container">
                    <div class="grid address-action-btns justify-content-end">
                      <div class="col-6 lg:col-8 xl:col-5 add-button-container">
                        <a
                          class="add-button"
                          (click)="cloneCommercialAccountAddress()"
                          [ngClass]="{ 'btn-disabled': !_addressMgr.filteredAddresses.length }"
                        >
                          <label>Clone</label>
                          <i class="material-icons">content_copy</i>
                        </a>

                        <a class="add-button" (click)="addCommercialAccountAddress()">
                          <label>Add New</label>
                          <i class="material-icons">add_circle</i>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 address-tabview-container">
                    <p-tabView
                      [scrollable]="true"
                      [controlClose]="true"
                      (onClose)="removeCommercialAccountAddress($event)"
                      (onChange)="changedTab($event)"
                    >
                      <p-tabPanel
                        *ngFor="let commercialAccountAddress of _addressMgr.filteredAddresses; let i = index"
                        [selected]="i === _addressTabIndex"
                        [closable]="true"
                        [headerStyle]="{ 'border-right': 'solid 1px lightgrey' }"
                        header="{{ i + 1 }}: {{ commercialAccountAddress.get('address.addressLocationType').value }}"
                      >
                        <div class="col-12">
                          <taku-commercial-account-address
                            [formGroup]="commercialAccountAddress"
                          ></taku-commercial-account-address>
                        </div>
                      </p-tabPanel>
                    </p-tabView>
                  </div>
                </div>
              </div>
            </p-tabPanel>

            <p-tabPanel
              *ngIf="accountAccessRights.isContactsTabVisible"
              [disabled]="!accountAccessRights.isContactsTabEnabled"
            >
              <ng-template pTemplate="header">
                <i class="material-icons mr-2">people</i>
                <span>Contacts</span>
              </ng-template>
              <div class="col-12 associated-contacts">
                <div class="grid col-12 contacts-header">
                  <h3>Associated Contacts</h3>

                  <a class="add-button" (click)="openAddContact()">
                    <label>Add Contact</label>
                    <i class="material-icons">add_circle</i>
                  </a>
                </div>

                <div class="grid col-12" formArrayName="contacts">
                  <p-table
                    [resizableColumns]="true"
                    [value]="this._myForm.controls['contacts'].value"
                    responsiveLayout="stack"
                    breakpoint="640px"
                    selectionMode="single"
                    (onRowSelect)="selectContactRow($event.index)"
                    [(selection)]="selectedContact"
                    styleClass="p-datatable-striped"
                  >
                    <ng-template pTemplate="header">
                      <tr>
                        <th width="1%"> </th>
                        <th width="21%" class="p-resizable-column">
                          <strong>First Name</strong>
                        </th>
                        <th width="21%" class="p-resizable-column">
                          <strong>Last Name</strong>
                        </th>
                        <th width="15%">
                          <strong>Title</strong>
                        </th>
                        <th width="24%" class="p-resizable-column">
                          <strong>Email</strong>
                        </th>
                        <th width="17%" class="p-resizable-column">
                          <strong>Phone</strong>
                        </th>
                        <th width="1%">
                          <strong>PIN</strong>
                        </th>
                      </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-contact let-rowIndex="rowIndex">
                      <tr [pSelectableRow]="contact" [pSelectableRowIndex]="rowIndex">
                        <td class="text-center">
                          <strong
                            ><a class="link-text" href="javascript://" (click)="selectContactRow(rowIndex)"
                              >View</a
                            ></strong
                          >
                        </td>
                        <td class="p-resizable-column">
                          <span class="p-column-title">First Name</span>
                          {{ contact.person.firstName }}
                        </td>
                        <td class="p-resizable-column">
                          <span class="p-column-title"><strong>Last Name</strong></span>
                          {{ contact.person.lastName }}
                        </td>
                        <td>
                          <span class="p-column-title"><strong>Title</strong></span>
                          {{ contact.person.title }}
                        </td>
                        <td class="p-resizable-column">
                          <span class="p-column-title"><strong>Email</strong></span>
                          <span *ngIf="contact.person.personEmails.length > 0">{{
                            contact.person.personEmails[0].email
                          }}</span>
                        </td>
                        <td class="p-resizable-column">
                          <span class="p-column-title"><strong>Phone</strong></span>
                          <span *ngIf="contact.person.personPhones.length > 0">{{
                            contact.person.personPhones[0].tel
                          }}</span>
                        </td>
                        <td>
                          <div class="table-checkbox-container" [formGroup]="_myForm">
                            <!-- <input  [attr.checked]="contact.isDefault ? true : null"  type="radio" name="defaultContactIndex" [attr.value]="rowIndex" (click)="selectedDefaultContact($event)"  > -->
                            <span class="p-column-title" style="text-align: center"><strong>PIN</strong></span>
                            <p-radioButton
                              name="selectedContactIndex"
                              [value]="rowIndex"
                              (click)="selectedDefaultContact($event)"
                              formControlName="selectedContactIndex"
                            ></p-radioButton>
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </div>
            </p-tabPanel>

            <!-- SUPPLIER -->
            <p-tabPanel class="tabview-nopad">
              <ng-template pTemplate="header">
                <i class="material-icons mr-2">trolley</i>
                <span>Supplier</span>
              </ng-template>
              <div class="col-12">
                <div class="grid">
                  <div class="md:col-5">
                    <taku-currency
                      [formGroup]="_myForm"
                      fieldName="supplierCurrencyIsoCode"
                      fieldCaption="Default Supplier Currency"
                      [disabled]="
                        _myForm.get('account.accountRelationship').value !== AccountRelationship.supplier &&
                        _myForm.get('account.accountRelationship').value !== AccountRelationship.both
                      "
                    >
                    </taku-currency>
                  </div>
                </div>
                <div class="grid">
                  <div class="col-8">
                    <h3>Minimum Order Amount</h3>
                  </div>

                  <div class="col-4 add-button-container" [ngClass]="{ 'btn-disabled': zonesFilled }">
                    <a class="add-button" (click)="addSupplierMinAmmount()">
                      <label>Add New</label>
                      <i class="material-icons">add_circle_outline</i>
                    </a>
                  </div>
                </div>

                <div class="col-12" formArrayName="supplier">
                  <div class="grid zone-header">
                    <div class="md:col-5">Zone</div>
                    <div class="md:col-2">Currency</div>
                    <div class="md:col-4">Minimum Order Amount</div>
                  </div>
                  <div
                    class="col"
                    *ngFor="
                      let supplierDetails of _myForm.get('commercialAccountSupplierDetails')['controls'];
                      let i = index
                    "
                  >
                    <taku-commercial-details
                      [zoneList]="zones"
                      (zoneListChange)="setNewZones($event)"
                      (removeItem)="removeZoneMoa(i, $event)"
                      [formGroup]="supplierDetails"
                    ></taku-commercial-details>
                  </div>
                </div>
              </div>
            </p-tabPanel>

            <p-tabPanel [disabled]="!takuPayAccountEnabled">
              <ng-template pTemplate="header">
                <i class="material-icons mr-2">credit_card</i>
                <span>Credit Cards</span>
              </ng-template>
              <taku-account-credit-card
                [formGroup]="_myForm.get('account')"
                (takuPayAccountEnabled)="getTakuPayAccountEnabled()"
              ></taku-account-credit-card>
            </p-tabPanel>
          </p-tabView>
        </p-panel>
      </div>

      <div class="col-12 lg:col-3 note-column" *ngIf="accountAccessRights.isNoteVisible">
        <p-panel>
          <ng-template pTemplate="header">
            <h2>Note</h2>
          </ng-template>

          <div class="col-12">
            <taku-input-textarea
              [formGroup]="_myForm.controls['account']"
              fieldName="note"
              fieldCaption="Internal Notes"
              [rows]="10"
              [autoResize]="true"
              [disabled]="!accountAccessRights.isNoteEnabled"
            ></taku-input-textarea>
          </div>
          <hr class="fields-divider" />

          <div class="col-12">
            <div class="col-12">
              <h5>Tags</h5>
            </div>

            <div class="grid-nogutter col-12">
              <taku-chips
                [formGroup]="_myForm.controls['account']"
                fieldName="tags"
                fieldPlaceholder="Press Enter to Save Tags"
              ></taku-chips>
            </div>
          </div>
        </p-panel>
      </div>

      <!-- <div class="col-12">{{_myForm.value|json}}</div> -->
    </div>

    <p-sidebar
      *ngIf="_showAddContact"
      #contactsSidebar
      [(visible)]="_showAddContact"
      [modal]="true"
      [showCloseIcon]="false"
      position="right"
      appendTo="body"
      [dismissible]="false"
      blockScroll="true"
      [styleClass]="'p-sidebar-lg'"
      [fullScreen]="_isMobile || _isPortraitTabletScreen"
      [closeOnEscape]="false"
    >
      <div class="contact-topWrapper" *ngIf="_tmpContactFormGroup">
        <div class="col-12">
          <taku-form-dialoge-header
            [pristine]="_tmpContactFormGroup.pristine"
            [valid]="_tmpContactFormGroup.valid"
            (saveClicked)="saveContactAndClose()"
            (cancelClicked)="closeContactSidebar()"
            (deleteClicked)="deleteContact()"
            (revertClicked)="revertContact($event)"
            toolbarTitle="Contact Details"
          ></taku-form-dialoge-header>
        </div>
        <div class="col-12 contact-form-container">
          <taku-contact [formGroup]="_tmpContactFormGroup"></taku-contact>
        </div>
      </div>
    </p-sidebar>
  </form>
</div>
