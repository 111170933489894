/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Pipe, PipeTransform } from "@angular/core";
import { CurrencyPipe } from "@angular/common";

@Pipe({ name: "currencySymbol", standalone: true })
export class CurrencySymbolPipe extends CurrencyPipe implements PipeTransform {
  transform(value: string, format = "symbol-narrow"): any {
    const currencyValue = super.transform(0, value, format, "1.0-2");
    return currencyValue.replace(/[0-9]/g, "");
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
