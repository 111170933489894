/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Location } from "@angular/common";
import { Component, ViewChild, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { merge, Observable, Subscription } from "rxjs";
import { take, toArray } from "rxjs/operators";
import { FormListComponent, RowsSavedEvent } from "src/app/form-list/form-list/form-list.component";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { PaymentGateway } from "./payment-gateway";
import { PaymentGatewayService } from "./payment-gateway.service";

@Component({
  selector: "taku-payment-gateways-list",
  templateUrl: "./payment-gateways-list.component.html",
  styleUrls: ["./payment-gateways-list.component.scss"],
})
export class PaymentGatewaysListComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];

  @ViewChild(FormListComponent, { static: true }) formListComponent: FormListComponent;
  _formListFilter = {};
  confirmDialogKey = "paymentGateways-deleteRows";
  private formListFormRows: UntypedFormGroup[];
  active = false;
  _formlistTitle: string;

  constructor(
    protected location: Location,
    private confirmationService: ConfirmationService,
    private dbService: PaymentGatewayService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private appSettings: AppSettingsStorageService
  ) {}

  ngOnInit() {
    this.subsList.push(
      this.route.params.subscribe((params) => {
        this.active = false;
        setTimeout(() => (this.active = true), 0);
        const paymentGatewayType = params.paymentGatewayType;
        this._formlistTitle = `Payment Gateways (${paymentGatewayType})`;
        this.dbService.enumPaymentGateways.map((row) => {
          row.disabled = row.value !== paymentGatewayType;
        });
        if (paymentGatewayType) {
          this._formListFilter["paymentGatewayType"] = {
            matchMode: "equals",
            value: paymentGatewayType,
          };
        }
        const _activeStoreId = this.appSettings.getStoreId();
        if (_activeStoreId) {
          this._formListFilter["storeId"] = {
            matchMode: "equals",
            value: _activeStoreId,
          };
        }
      })
    );
  }

  private showDisableTerminalsDialog(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      // If we are disabling the current payment gateway, disable all the terminals as well
      this.confirmationService.confirm({
        header: "Confirmation",
        message:
          "By disabling the selected payment gateway, this will also disable all active terminals in the Payment Terminal page. Would you like to proceed?",
        acceptLabel: "Yes",
        rejectLabel: "No",
        rejectButtonStyleClass: "p-button-link",
        accept: () => {
          observer.next(true);
          observer.complete();
        },
        reject: () => {
          observer.next(false);
          observer.complete();
        },
      });
    });
  }

  onPaymentGatewaysSaved(event: RowsSavedEvent) {
    const changedGateways = [];
    event.newValues.forEach((newValue) => {
      const prevValue = event.oldValues.find((value) => {
        return value.id === newValue.id;
      });
      // we have changed Payment Gateway from active to inactive
      if (prevValue && prevValue.isActive && !newValue.isActive) changedGateways.push(newValue);
    });

    this.disablePaymentTerminals(changedGateways);
  }

  registerRowFormsChanges() {
    // this.lastFetchedGateways = event.objects;
    // this.disabledGatewayRecords = new Map<number, boolean>();
    if (this.active) {
      this.formListFormRows = this.formListComponent.getAllFormGroups();
      this.formListFormRows.forEach((formGroup) => {
        const isActiveCtrl = formGroup.get("isActive");
        this.subsList.push(
          isActiveCtrl.valueChanges
            .pipe
            // skip(1),
            ()
            .subscribe((isActive) => {
              if (!this.formListComponent._isFormInEditMode || isActive) return;

              this.subsList.push(
                this.showDisableTerminalsDialog().subscribe((confirmation) => {
                  // Revert value of isActive checkbox if we rejected the confirmation dialog
                  if (!confirmation) isActiveCtrl.setValue(!isActive, { emitEvent: false });
                })
              );
            })
        );
      });
    }
  }

  private disablePaymentTerminals(gateways: PaymentGateway[]) {
    if (!gateways.length) return;

    const terminalRequests: Observable<any>[] = [];
    gateways.forEach((payment) => {
      const filter = {
        paymentGatewayId: { value: payment.id, matchMode: "equals" },
      };
      terminalRequests.push(
        this.dbService.patchRows(
          "paymentTerminal",
          {
            isActive: false,
          },
          JSON.stringify(filter)
        )
      );
    });

    this.subsList.push(
      merge(...terminalRequests)
        .pipe(toArray())
        .subscribe({
          next: (results) => {
            this.messageService.add({
              summary: "Payment Terminals",
              severity: "success",
              detail: "Payment Gateways and Payment Terminals disabled",
            });
          },
          error: (error) => {
            this.messageService.add({
              summary: "Error",
              severity: "error",
              detail: "Couldn't disable all the terminals belonging to this gateway",
            });
          },
        })
    );
  }

  goBack(): void {
    this.location.back();
  }

  checkIfCanDeleteDialog = (rowsToDelete: PaymentGateway[]): Observable<any> => {
    return new Observable((observer) => {
      const notDeletableGatewayIds: number[] = [];
      this.subsList.push(
        merge(
          ...rowsToDelete.map((gateway) => {
            const filter = {
              paymentGatewayId: { value: gateway.id, matchMode: "equals" },
            };
            return this.dbService.getRows("paymentTerminal", JSON.stringify(filter), 0, 1).pipe(take(1));
          })
        ).subscribe({
          next: (response) => {
            if (response.rows && response.rows.length) notDeletableGatewayIds.push(response.rows[0].paymentGatewayId);
          },
          complete: () => {
            if (!notDeletableGatewayIds.length) {
              observer.next(rowsToDelete);
              observer.complete();
              return;
            }

            // Exclude from the selected rows, those which cannot be deleted
            rowsToDelete = rowsToDelete.filter((gateway) => !notDeletableGatewayIds.includes(gateway.id));
            observer.next(rowsToDelete);
            observer.complete();

            this.confirmationService.confirm({
              key: this.confirmDialogKey,
              header: "Some records couldn't be deleted",
              message: "In order to delete a Payment Gateway, you first need to delete all their configured Terminals.",
              acceptLabel: "OK",
              rejectVisible: false,
            });
          },
        })
      );
    });
  };

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
