/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Location } from "@angular/common";
import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import * as _ from "lodash";
import { MessageService } from "primeng/api";
import { ConfirmationService, SelectItem } from "primeng/api";
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { Observable, of, zip } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { AutocompleteDatatypeOptions, Col, RowSelectionType } from "src/app/form-list/form-list/form-list";
import { GenericFormComponent } from "../../../forms/generic-form/generic-form.component";
import { AppSettingsStorageService } from "../../../shared/app-settings-storage.service";
import { AlertMessagesService } from "../../../shared/services/alert-messages.service";
import { ToggleCaptionPosition } from "../../../taku-ui/taku-toggle/taku-toggle.component";
import { WebHelpers } from "../../../utility/WebHelpers";
import { BackendGMCService } from "../../settings/integration-settings/google-shopping/BackendGMCService";
import { ContentAPI_ProductStatuses } from "../../settings/integration-settings/google-shopping/gmc-api-data-definitions/ContentAPI_ProductStatutes";
import { GMCProductErrorDetailsComponent } from "../../settings/integration-settings/google-shopping/gmc-product-error-details/gmc-product-error-details.component";
import {
  GMC_CategoryAutocompleteTransformer,
  GoogleShoppingProductsService,
} from "../../settings/integration-settings/google-shopping/google-shopping-products/GoogleShoppingProductsService";
import { InventoryBarcode } from "../inventory-barcode/inventory-barcode";
import { InventoryBarcodeComponent } from "../inventory-barcode/inventory-barcode.component";
import { InventoryGalleryDialogComponent } from "../inventory-gallery-dialog/inventory-gallery-dialog.component";
import { InventoryImageComponent } from "../inventory-image/inventory-image.component";
import { InventoryStockComponent } from "../inventory-stock/inventory-stock.component";
import { InventoryZoneForm } from "../inventory-zone/inventory-zone-form";
import { Inventory, InventoryGoogleShopping, InventoryZone, Stock_type } from "./inventory";
import { AuthService } from "src/app/shared/services/auth.service";
import { InventoryService, InventoryStats } from "./inventory.service";
import { TimeHelpers, RelativeTime } from "src/app/utility/TimeHelpers";
import * as moment from "moment";
import { DocState, SaleDocType } from "../../document/doc/doc";
import { InventorySalesHistoryService } from "./inventory-sales-history.service";
import { InventorySaleChannelComponent } from "../inventory-sale-channel/inventory-sale-channel.component";
import { SaleDocService } from "../../document/sale-document/sale-doc/sale-doc.service";
import { Zone } from "../../settings/zone-settings/Zone";
import { Store } from "../../settings/store-settings/store/store";
import { OverlayPanel } from "primeng/overlaypanel";
import { InventoryVariantSettingComponent } from "../inventory-variant-setting/inventory-variant-setting.component";
import { InventoryLabelSettingsComponent } from "../inventory-label-settings/inventory-label-settings.component";
import { InventoryVariantSettingService } from "../inventory-variant-setting/inventory-variant-setting.service";
import { InventoryUsageDetailComponent } from "../inventory-usage-detail/inventory-usage-detail.component";
import { FrequencyName } from "../inventory-usage-detail/inventory-usage-detail";
import { AccountRelationship } from "../../contact-accounts/account/account";
import { FormDataHelpers } from "src/app/utility/FormDataHelpers";
import { InventorySupplierComponent } from "../inventory-supplier/inventory-supplier.component";
import { ExtendedSelectItem } from "src/app/shared/services/db.service";
import { ACTION_TYPE, BUTTON_SIZE } from "src/app/shared/components/action-button/action-type.enum";
import { ReportParamsService } from "../../reports/report-params.service";

@Component({
  selector: "taku-inventory",
  templateUrl: "./inventory.component.html",
  styleUrls: ["./inventory.component.scss"],
  providers: [DialogService],
})
export class InventoryComponent extends GenericFormComponent implements OnInit, AfterViewInit {
  static readonly CTRL_NAME_IS_VISIBLE = "_isVisible";
  public ACTION_TYPE: typeof ACTION_TYPE = ACTION_TYPE;
  public BUTTON_SIZE: typeof BUTTON_SIZE = BUTTON_SIZE;

  lookup_shelves: ExtendedSelectItem[];
  lookup_aisles: ExtendedSelectItem[];

  isStatusVisible: boolean;
  isSellPriceVisible: boolean;
  isAltPriceVisible: boolean;
  isPriceOptionsVisible: boolean;
  isBarcodesVisible: boolean;
  isListTypeVisible: boolean;
  isListClassificationVisible: boolean;
  isHistoryTabVisible: boolean;
  isDetailsTabVisible: boolean;
  isGoogleShoppingTabVisible: boolean;
  isCostingPanelVisible: boolean;
  isStockLevelPanelVisible: boolean;
  isNotesVisible: boolean;

  isStatusEnabled: boolean;
  isSellPriceEnabled: boolean;
  isAltPriceEnabled: boolean;
  isPriceOptionsEnabled: boolean;
  isBarcodesEnabled: boolean;
  isListTypeEnabled: boolean;
  isListClassificationEnabled: boolean;
  isHistoryTabEnabled: boolean;
  isDetailsTabEnabled: boolean;
  isGoogleShoppingTabEnabled: boolean;
  isCostingPanelEnabled: boolean;
  isStockLevelPanelEnabled: boolean;
  isNotesEnabled: boolean;
  frequencyNameDropdownOptionsDisabled: Record<string, boolean> = {} as Record<string, boolean>;
  allFrequencyNameOptionsUsed = false;
  _mainTabActiveIndex = 0;
  _inventoryVariantsList: Inventory[] = [];
  stocksPerZoneMap: Record<number, AbstractControl[]> = {};

  // Variable for uploaded files
  uploadedFiles: any[] = [];
  gmcId$: Observable<number> = this._route.queryParams.pipe(map((params: Params) => params.gmcId));
  gmbLocationId$: Observable<number> = this._route.queryParams.pipe(map((params: Params) => params.gmbLocationId));
  googleProductCategoryOptions = new AutocompleteDatatypeOptions({
    completeMethod: this.backendGMCService.gmcProductCategory_autocomplete.bind(this.backendGMCService),
    transformer: new GMC_CategoryAutocompleteTransformer(),
  });

  _isFetchingStatus: boolean;
  _defaultStockId = this.appSettingsService.getStore().stockId;
  _defaultZoneId = this.appSettingsService.getZoneId();
  _isAdmin = !this.appSettingsService.getStore().id && !this.appSettingsService.getBackoffice().id;

  googleShoppingBrandsSuggestions: SelectItem[];
  activeSaleStats: InventoryStats;
  activeReturnStats: InventoryStats;
  _digitsFormat = "1.2-2";
  // History tab
  lastTxrRelativeTime: RelativeTime;
  _historyFormListCols: Col[];
  _historyFormListFilter;
  _historyFormListQueryParams;
  vendorsSearchFilter = {
    "account.accountRelationship": {
      matchMode: "equals",
      value: AccountRelationship.supplier,
    },
  };
  RowSelectionType = RowSelectionType;
  _detailTabIsSelected = false;
  _inventoryKidsStocksFormArray: UntypedFormArray = InventoryStockComponent.initArray(this.fb);

  storesToDisplayInOverlay: Store[] = [];
  editStockType = false;
  editClassification = false;
  @ViewChild("storesOfZoneOverlay") storesOfZoneOverlayEl: OverlayPanel;

  inventoryCategories: Record<string, any>[];

  getDisplayGoogleShoppingFromChild(isDisplayGoogleShoppingDialog: boolean) {
    this.displayGoogleShoppingDialog = isDisplayGoogleShoppingDialog;
  }

  goBack(): void {
    if (this._isDialog) {
      this.dialogClosed.emit();
      this.dialogRef.close({
        success: true,
      });
    } else this.location.back();
  }

  ngOnInit(): void {
    this.subsList.push(
      this._myForm.controls.categories.valueChanges.subscribe((val) => {
        this.inventoryCategories = val.map((m) => m.categoryName).join(", ");
      })
    );

    // updating properties when component initialized in dialog
    if (this.dialogConfig.data && this.dialogConfig.data.inventoryForm) {
      this._object = this.dialogConfig.data.inventoryForm.value;
    }
    if (this.dialogConfig.data && this.dialogConfig.data.isDialog) {
      this._isDialog = this.dialogConfig.data.isDialog;
    }

    // we have to call ngOnInit AFTER we update this._object above
    super.ngOnInit();

    if (!this._isAdmin) {
      // If non-admin, expand the only zone by default
      this.zoneExpandedRowKeys = {
        [this.appSettingsService.getZoneId()]: true,
      };
    }

    this.accumulateInventoryVariantStocks();

    this.saleDocService._inventoryId = this._object.id;

    if (this._myForm.get("id").value) {
      this._historyFormListCols = this.inventorySalesHistoryService.getFormListColumns();
      this._historyFormListFilter = {
        "doc.state": { value: DocState.finalized, matchMode: "equals" },
        "saleDocLines.docLine.inventoryId": { value: this._object.id, matchMode: "equals" },
      };
      this._historyFormListQueryParams = {
        includes: "saleDocLine", // detail: true,
      };
    }

    this.stocksPerZoneMap = this.getStocksPerZone();
    this.subsList.push(
      this.changedForm.subscribe(() => {
        this.inventoryService.populateStocksFormArray(
          this.ctrlInventoryStocks,
          InventoryComponent.CTRL_NAME_IS_VISIBLE
        );
        this.stocksPerZoneMap = this.getStocksPerZone();
        this.populateGMCStatus();
      })
    );

    this.isStatusVisible = this.authService.hasVisiblePermit("Inventory_Menu_Inventory_List_Active_Inactive");
    this.isSellPriceVisible = this.authService.hasVisiblePermit("Inventory_Menu_Inventory_List_Sell_Price");
    this.isAltPriceVisible = this.authService.hasVisiblePermit("Inventory_Menu_Inventory_List_Alt_Price");
    this.isPriceOptionsVisible = this.authService.hasVisiblePermit("Inventory_Menu_Inventory_List_Price_Options");
    this.isBarcodesVisible = this.authService.hasVisiblePermit("Inventory_Menu_Inventory_List_Barcodes");
    this.isListTypeVisible = this.authService.hasVisiblePermit("Inventory_Menu_Inventory_List_Type");
    this.isListClassificationVisible = this.authService.hasVisiblePermit(
      "Inventory_Menu_Inventory_List_Classification"
    );
    this.isHistoryTabVisible = this.authService.hasVisiblePermit("Inventory_Menu_Inventory_List_History_Tab");
    this.isDetailsTabVisible = this.authService.hasVisiblePermit("Inventory_Menu_Inventory_List_Details_Tab");
    this.isGoogleShoppingTabVisible = this.authService.hasVisiblePermit(
      "Inventory_Menu_Inventory_List_Google_Shopping_Tab"
    );
    this.isCostingPanelVisible = this.authService.hasVisiblePermit("Inventory_Menu_Inventory_List_Stock_Tab_Costing");
    this.isStockLevelPanelVisible = this.authService.hasVisiblePermit(
      "Inventory_Menu_Inventory_List_Stock_Tab_Stock_Level"
    );
    this.isNotesVisible = this.authService.hasVisiblePermit("Inventory_Menu_Inventory_List_Notes");

    this.isStatusEnabled = this.authService.hasEnablePermit("Inventory_Menu_Inventory_List_Active_Inactive");
    this.isSellPriceEnabled = this.authService.hasEnablePermit("Inventory_Menu_Inventory_List_Sell_Price");
    this.isAltPriceEnabled = this.authService.hasEnablePermit("Inventory_Menu_Inventory_List_Alt_Price");
    this.isPriceOptionsEnabled = this.authService.hasEnablePermit("Inventory_Menu_Inventory_List_Price_Options");
    this.isBarcodesEnabled = this.authService.hasEnablePermit("Inventory_Menu_Inventory_List_Barcodes");
    this.isListTypeEnabled = this.authService.hasEnablePermit("Inventory_Menu_Inventory_List_Type");
    this.isListClassificationEnabled = this.authService.hasEnablePermit("Inventory_Menu_Inventory_List_Classification");
    this.isHistoryTabEnabled = this.authService.hasEnablePermit("Inventory_Menu_Inventory_List_History_Tab");
    this.isDetailsTabEnabled = this.authService.hasEnablePermit("Inventory_Menu_Inventory_List_Details_Tab");
    this.isGoogleShoppingTabEnabled = this.authService.hasEnablePermit(
      "Inventory_Menu_Inventory_List_Google_Shopping_Tab"
    );
    this.isCostingPanelEnabled = this.authService.hasEnablePermit("Inventory_Menu_Inventory_List_Stock_Tab_Costing");
    this.isStockLevelPanelEnabled = this.authService.hasEnablePermit(
      "Inventory_Menu_Inventory_List_Stock_Tab_Stock_Level"
    );
    this.isNotesEnabled = this.authService.hasEnablePermit("Inventory_Menu_Inventory_List_Notes");

    this._route.queryParams.forEach((params: any) => {
      if (params && params.defautTab === "Details" && this.isDetailsTabEnabled && this.isDetailsTabVisible) {
        this._detailTabIsSelected = true;
      }
    });

    // Pull statistics and show them under History tab
    this.updateHistoricalData();
    this._myForm.markAsPristine();

    this._mainTabActiveIndex = null;
    setTimeout(() => {
      this._mainTabActiveIndex = 0;
    }, 0);

    this.inventoryService.lookup_shelf().subscribe((data: ExtendedSelectItem[]) => {
      this.lookup_shelves = data;
    });
    this.inventoryService.lookup_aisle().subscribe((data: ExtendedSelectItem[]) => {
      this.lookup_aisles = data;
    });

    // if (this._isAdmin) {
    //   this.subsList.push(this.appSettingsService.getBusinessDetails().subscribe((businessDetails) => {
    //     this.businessDetails = businessDetails;
    //     // this._myForm.get('standardPriceCurrencyIsoCode').setValue(businessDetails.defaultCurrencyIsoCode);
    //   }));
    // }
  }

  ngAfterViewInit(): void {
    this.populateSearchFields();
  }

  loadInventoryHistoryReportParams = () => {
    this.reportParamsService.setReportParam({ inventoryId: this._object.id });
    this.reportParamsService.applyReportParam();
  };

  private accumulateInventoryVariantStocks() {
    if (this._myForm.get("isVariantParent")) {
      this.subsList.push(
        this.inventoryVariantSettingService.getInventoryVariants(this._myForm.get("id").value).subscribe({
          next: (data) => {
            // accumulate all data in an array
            this._inventoryVariantsList = data.rows;
            const _inventoryKidStocks = [];
            data.rows.map((_inventory) => {
              _inventory.inventoryStocks.map((inventoryStock) => {
                const _inventoryKidStockIndex = _inventoryKidStocks.findIndex(
                  (row) => row.stockId === inventoryStock.stockId
                );
                if (_inventoryKidStockIndex >= 0) {
                  _inventoryKidStocks[_inventoryKidStockIndex].onOrderQty += inventoryStock.onOrderQty;
                  _inventoryKidStocks[_inventoryKidStockIndex].committedQty += inventoryStock.committedQty;
                  _inventoryKidStocks[_inventoryKidStockIndex].qtyOH += inventoryStock.qtyOH;
                } else {
                  _inventoryKidStocks.push(inventoryStock);
                }
              });
            });

            this._inventoryKidsStocksFormArray = InventoryStockComponent.setArray(this.fb, _inventoryKidStocks);
            this.stocksPerZoneMap = this.getStocksPerZone();
          },
        })
      );
    }
  }

  get _isMobile() {
    return WebHelpers.isMobileScreen();
  }

  // Properties and methods for making GTIN has a different type that what is actually sent to server (just barcode number)
  _selectedGTINBarcode;
  get selectedGTINBarcode(): any | string {
    const gtin = this.googleGTINControl.value;
    if (!this._selectedGTINBarcode || this._selectedGTINBarcode.barcode !== gtin) {
      const orphanBarcode = Object.assign(new InventoryBarcode(), { barcode: gtin });
      if (gtin)
        this._selectedGTINBarcode =
          this.inventoryBarcodesArray.value.find((barcode) => barcode.barcode == gtin) || orphanBarcode;
      else this._selectedGTINBarcode = null;
    }

    return this._selectedGTINBarcode;
  }

  set selectedGTINBarcode(barcode: any | string) {
    const barcodeNumber = barcode.barcode || barcode;
    this.googleGTINControl.setValue(barcodeNumber);
    this.googleGTINControl.markAsDirty();
  }

  _selectedGMCBrand: SelectItem;
  get selectedGMCBrand() {
    const brand = this.googleBrandControl.value;
    if (!this._selectedGMCBrand || this._selectedGMCBrand.label !== brand) {
      const orphanBrand: SelectItem = { label: brand, value: 0 };
      if (brand)
        this._selectedGMCBrand =
          (this.lookup_brands &&
            this.lookup_brands.filter((item) => !item.disabled).find((item) => item.label === brand)) ||
          orphanBrand;
      else this._selectedGMCBrand = null;
    }

    return this._selectedGMCBrand;
  }

  set selectedGMCBrand(brand: any | string) {
    const brandName = brand.label || brand;
    this.googleBrandControl.setValue(brandName);
    this.googleBrandControl.markAsDirty();
  }

  // Define enum variables
  enum_stock_type = this.inventoryService.get_enum_stock_type(); // this.inventoryService.enumOptions(Stock_type, 'Stock Type');
  enum_nonphysical_stock_type = this.inventoryService.get_enum_nonphysical_stock_type(); // this.inventoryService.enumOptions(Stock_type, 'Stock Type');
  enum_cost_basis = this.inventoryService.get_enum_cost_basis();
  googleshoppingGTIN_suggestions: InventoryBarcode[] = [];
  enum_googleshoppigCondition = this.inventoryService.enum_googleshoppigCondition;
  enum_googleshoppingAgeGroup = this.inventoryService.enum_googleshoppingAgeGroup;
  enum_googleshoppingSizeType = this.inventoryService.enum_googleshoppingSizeType;
  enum_googleshoppingSizeSystem = this.inventoryService.enum_googleshoppingSizeSystem;
  enum_googleshoppingGender = this.inventoryService.enum_googleshoppingGender;
  enum_googleshoppingTitleTemplate = this.inventoryService.enum_googleshoppingTitleTemplate;

  // Define lookup Variables
  lookup_uOfMeasure: SelectItem[];
  lookup_brands: SelectItem[];
  lookup_categories: SelectItem[];
  lookup_defaultReportCategories: SelectItem[];
  lookup_tax_inventory_category: SelectItem[];

  zoneExpandedRowKeys: Record<string, boolean> = {};

  closeAllZoneRows(): void {
    // Assigning a new empty object triggers pTable change-detection and clears expanded rows.
    this.zoneExpandedRowKeys = {};
  }

  // Control Dialog's visibility
  displayStockTypeDialog = false;
  displaySellTypeDialog = false;
  displayGoogleShoppingDialog = false;

  ToggleCaptionPosition = ToggleCaptionPosition;
  GoogleProductStatus = ContentAPI_ProductStatuses.DestinationStatus_Status;
  isGoogleShoppingAvailable: boolean;

  static init(fb, inventoryObj): UntypedFormGroup {
    // console.log(inventoryObj);
    // let inventoryImagesArray = inventoryObj.inventoryImages;
    const tmpForm = fb.group(inventoryObj);
    // console.log(tmpForm);
    tmpForm.setControl("inventoryBarcodes", fb.array([]));
    tmpForm.setControl("inventorySuppliers", fb.array([]));
    tmpForm.setControl("inventoryUsageDetails", fb.array([]));
    tmpForm.setControl("inventoryZones", InventoryZoneForm.initArray(fb));
    tmpForm.setControl("inventoryStocks", InventoryStockComponent.initArray(fb));
    tmpForm.setControl(
      "inventoryImages",
      inventoryObj.inventoryImages
        ? InventoryImageComponent.setArray(fb, inventoryObj.inventoryImages)
        : InventoryImageComponent.init(fb)
    );
    tmpForm.setControl("inventoryGoogleShopping", fb.group(new InventoryGoogleShopping()));
    //tmpForm.setControl('inventorySaleChannel', fb.group(new InventorySaleChannel));
    //tmpForm.setControl('inventorySaleChannels', fb.array([]));
    tmpForm.setControl("inventorySaleChannels", fb.array([InventorySaleChannelComponent.init(fb)]));
    tmpForm.setControl("inventoryVariantSettings", fb.array([InventoryVariantSettingComponent.init(fb)]));
    tmpForm.setControl("inventoryVariants", fb.array([InventoryVariantSettingComponent.initVariants(fb)]));
    return tmpForm;
  }

  get _searchVendorPlaceholder() {
    return FormDataHelpers.InputFieldCaption(`Vendor`, this._myForm.get("accountId"));
  }

  constructor(
    private inventorySalesHistoryService: InventorySalesHistoryService,
    protected _router: Router,
    public fb: UntypedFormBuilder,
    public inventoryService: InventoryService,
    private backendGMCService: BackendGMCService,
    protected location: Location,
    public _route: ActivatedRoute,
    protected messageService: MessageService,
    protected alertMessage: AlertMessagesService,
    appSettings: AppSettingsStorageService,
    protected confirmationService: ConfirmationService,
    private dialogService: DialogService,
    private gmcProductService: GoogleShoppingProductsService,
    private authService: AuthService,
    private saleDocService: SaleDocService,
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    public inventoryVariantSettingService: InventoryVariantSettingService,
    private reportParamsService: ReportParamsService
  ) {
    super(
      _router,
      fb,
      inventoryService,
      location,
      _route,
      messageService,
      alertMessage,
      confirmationService,
      appSettings
    );
    this.initLookupsInventory();

    Object.entries(FrequencyName).forEach(([_, value]) => {
      this.frequencyNameDropdownOptionsDisabled[value] = false;
    });
  }

  setFrequencyNameDropdownOptionsDisabled(event: Record<string, boolean>): void {
    this.frequencyNameDropdownOptionsDisabled = {
      ...this.frequencyNameDropdownOptionsDisabled,
      ...event,
    };

    let anyAvailableOptions = true;
    Object.entries(this.frequencyNameDropdownOptionsDisabled).forEach(([_, isDisabled]) => {
      if (isDisabled == false) {
        anyAvailableOptions = false;
      }
    });

    this.allFrequencyNameOptionsUsed = anyAvailableOptions;
  }

  searchGTIN(event) {
    const allBarcodes: InventoryBarcode[] = this.inventoryBarcodesArray.value;
    let matchingBarcodes;
    if (event.query) matchingBarcodes = allBarcodes.filter((barcode) => barcode.barcode.startsWith(event.query));
    // if empty query, means dropdown pressed
    else matchingBarcodes = [...allBarcodes];

    this.googleshoppingGTIN_suggestions = matchingBarcodes;
  }

  searchBrands(event) {
    const searchQuery = event.query.toLowerCase();
    const enabledBrands = this.lookup_brands.filter((item) => !item.disabled);
    let matchingBrands;
    if (searchQuery) matchingBrands = enabledBrands.filter((item) => item.label.toLowerCase().includes(searchQuery));
    else matchingBrands = [...enabledBrands];

    this.googleShoppingBrandsSuggestions = matchingBrands;
  }

  setFormArrays() {
    const zoneValidation = this.inventoryService.getValidationRules().inventoryZones;

    // when barcode form array changes
    this._myForm.setControl(
      "inventoryBarcodes",
      InventoryBarcodeComponent.setArray(this.fb, this._object.inventoryBarcodes)
    );
    this._myForm.setControl(
      "inventorySuppliers",
      InventorySupplierComponent.setArray(this.fb, this._object.inventorySuppliers)
    );
    this._myForm.setControl(
      "inventoryUsageDetails",
      InventoryUsageDetailComponent.setArray(this.fb, this._object.inventoryUsageDetails)
    );
    this._myForm.setControl("inventoryImages", InventoryImageComponent.setArray(this.fb, this._object.inventoryImages));
    this._myForm.setControl(
      "inventoryZones",
      InventoryZoneForm.setArray(this.fb, this._object.inventoryZones, zoneValidation)
    );
    this._myForm.setControl("inventoryStocks", InventoryStockComponent.setArray(this.fb, this._object.inventoryStocks));
    this._myForm.setControl(
      "inventorySaleChannels",
      InventorySaleChannelComponent.setArray(this.fb, this._object.inventorySaleChannels)
    );
    this._myForm.setControl(
      "inventoryVariantSettings",
      InventoryVariantSettingComponent.setArray(this.fb, this._object.inventoryVariantSettings)
    );
    this._myForm.setControl(
      "inventoryVariants",
      InventoryVariantSettingComponent.inventoryVariantsSetArray(this.fb, this._object.inventoryVariants)
    );
  }

  onRevert() {
    super.onRevert();
    this.inventoryService.populateStocksFormArray(this.ctrlInventoryStocks, InventoryComponent.CTRL_NAME_IS_VISIBLE);
    this.stocksPerZoneMap = this.getStocksPerZone();
  }

  onNew() {
    super.onNew();
    setTimeout(() => {
      this.inventoryService.populateStocksFormArray(this.ctrlInventoryStocks, InventoryComponent.CTRL_NAME_IS_VISIBLE);
      this.stocksPerZoneMap = this.getStocksPerZone();
    }, 0);
  }

  get googleGTINControl(): AbstractControl {
    return this._myForm.get("inventoryGoogleShopping.GTIN");
  }

  get googleBrandControl(): AbstractControl {
    return this._myForm.get("inventoryGoogleShopping.brand");
  }

  get googleMPNControl(): AbstractControl {
    return this._myForm.get("inventoryGoogleShopping.MPN");
  }

  get inventoryBarcodesArray(): UntypedFormArray {
    return <UntypedFormArray>this._myForm.get("inventoryBarcodes");
  }

  get inventoryUsageDetailsArray(): UntypedFormArray {
    return <UntypedFormArray>this._myForm.get("inventoryUsageDetails");
  }

  get inventoryImagesArray(): UntypedFormArray {
    return <UntypedFormArray>this._myForm.get("inventoryImages");
  }

  get googleShoppingForm(): UntypedFormGroup {
    return <UntypedFormGroup>this._myForm.get("inventoryGoogleShopping");
  }

  get stockTypeCtrl(): AbstractControl<Stock_type> {
    return this._myForm.get("stockType");
  }

  get ctrlInventoryZones(): UntypedFormArray {
    return <UntypedFormArray>this._myForm.get("inventoryZones");
  }

  get ctrlInventoryZonesFiltered(): AbstractControl[] {
    const ctrls = this.ctrlInventoryZones.controls;
    const zoneId = this.appSettingsService.getZoneId();
    if (this._isAdmin) return ctrls;
    else return ctrls.length ? [ctrls.find((c) => c.value.zoneId === zoneId)] : [];
  }

  initLookupsInventory(): void {
    this.subsList.push(
      this.inventoryService.get_lookup_uOfMeasure().subscribe((data) => (this.lookup_uOfMeasure = data))
    );
    this.subsList.push(this.inventoryService.get_lookup_brands().subscribe((data) => (this.lookup_brands = data)));
    this.subsList.push(
      this.inventoryService.get_lookup_categories().subscribe((data) => (this.lookup_categories = data))
    );
    this.subsList.push(
      this.inventoryService
        .get_lookup_defaultReportCategories()
        .subscribe((data) => (this.lookup_defaultReportCategories = data))
    );
    this.subsList.push(
      this.inventoryService
        .get_lookup_tax_inventory_category()
        .subscribe((data) => (this.lookup_tax_inventory_category = data))
    );
  }

  addInventoryBarcode() {
    if (!(this._myForm.controls["inventoryBarcodes"] instanceof UntypedFormArray)) {
      this._myForm.setControl("inventoryBarcodes", this.fb.array([]));
    }
    (<UntypedFormArray>this._myForm.controls["inventoryBarcodes"]).push(InventoryBarcodeComponent.init(this.fb));
  }

  addInventorySupplier() {
    if (!(this._myForm.controls["inventorySuppliers"] instanceof UntypedFormArray)) {
      this._myForm.setControl("inventorySuppliers", this.fb.array([]));
    }
    (<UntypedFormArray>this._myForm.controls["inventorySuppliers"]).push(InventorySupplierComponent.init(this.fb));
  }

  addInventoryUsageDetail(): void {
    if (!(this._myForm.controls["inventoryUsageDetails"] instanceof UntypedFormArray)) {
      this._myForm.setControl("inventoryUsageDetails", this.fb.array([]));
    }
    (<UntypedFormArray>this._myForm.controls["inventoryUsageDetails"]).push(
      InventoryUsageDetailComponent.init(this.fb)
    ); //TODO
    this._myForm.markAsDirty();
  }

  unlinkInventoryParent() {
    this.confirmationService.confirm({
      message: `unlinking this product from its parent SKU will remove all association. This action cannot be reversed.
      Are you sure you want to proceed?`,
      rejectButtonStyleClass: "p-button-link",
      accept: () => {
        this._myForm.get("parentInventoryId").setValue(null);
        this._myForm.setControl("inventoryVariants", this.fb.array([]));
        this._myForm.markAsDirty();
        // this.onSave();
      },
    });
  }

  showVariants() {
    this.dialogRef = this.dialogService.open(InventoryVariantSettingComponent, {
      styleClass: "inventory-gallery-dialog fullscreen-mobilemodal",
      contentStyle: {
        overflow: "auto",
        width: "85vw",
        maxWidth: "800pt",
        minWidth: "620pt",
        padding: "0",
      },
      showHeader: false,
      data: {
        inventoryFormGroup: this._myForm,
        inventoryComponent: this,
      },
    });
    this.dialogRef.onClose.subscribe((result) => {
      switch (result) {
        case "save":
          this.onSave();
          break;
        case "revert":
          this.onRevert();
          break;
        case "close":
          this.onRevert();
          this.accumulateInventoryVariantStocks();
          break;

        default:
          break;
      }
    });
  }

  removeInventoryBarcode(index: number) {
    const barcodesArray = <UntypedFormArray>this._myForm.get("inventoryBarcodes");
    if (barcodesArray.at(index).pristine) {
      this._removeFormArrayChild(barcodesArray, index);
      return;
    }

    this.confirmationService.confirm({
      message: "By deleting this barcode you will lose any changes. Are you sure?",
      rejectButtonStyleClass: "p-button-link",
      accept: () => {
        this._removeFormArrayChild(barcodesArray, index);
      },
    });
  }

  removeInventoryUsageDetail(index: number) {
    const usageDetailArray = <UntypedFormArray>this._myForm.get("inventoryUsageDetails");
    this._removeFormArrayChild(usageDetailArray, index);
  }

  removeInventorySupplier(index: number) {
    const inventorySupplierArray = <UntypedFormArray>this._myForm.get("inventorySuppliers");
    if (inventorySupplierArray.at(index).pristine) {
      this._removeFormArrayChild(inventorySupplierArray, index);
      return;
    }

    this.confirmationService.confirm({
      message: "By deleting this Inventory Supplier you will lose any changes. Are you sure?",
      rejectButtonStyleClass: "p-button-link",
      accept: () => {
        this._removeFormArrayChild(inventorySupplierArray, index);
      },
    });
  }

  initForm() {
    this._isAdmin = !this.appSettingsService.getStore().id && !this.appSettingsService.getBackoffice().id;
    this._model = "inventory";
    if (this._isAdmin) {
      this.appSettingsService.getBusinessDetails().subscribe((businessDetails) => {
        this._object = new Inventory(businessDetails.defaultCurrencyIsoCode);
        this._myForm = InventoryComponent.init(this.fb, this._object);
      });
    } else {
      this._object = new Inventory(this.appSettingsService.getZone().defaultCurrencyIsoCode);
      this._myForm = InventoryComponent.init(this.fb, this._object);
    }
  }

  loadObject() {
    if (!this._object.inventoryGoogleShopping) {
      this._object.inventoryGoogleShopping = new InventoryGoogleShopping();
    }
    super.loadObject();
    this.fetchIntegrationSettings();
    this.populateDynamicFields();
  }

  fetchIntegrationSettings() {
    this.subsList.push(
      this.inventoryService.backendGMCService
        .isAvailable()
        .subscribe((isAvailable) => (this.isGoogleShoppingAvailable = isAvailable))
    );
  }

  populateSearchFields(): any {
    // const inventoryDoc: InventoryDoc = _.merge(new InventoryDoc(), this._object);
    // const contactAccout = Account.GetContactAccount(inventoryDoc);
    // if (contactAccout) this.accountSearchComponent.textSearch = contactAccout.accountName;
  }

  populateDynamicFields(): void {
    this.inventoryService.populateStocksFormArray(this.ctrlInventoryStocks, InventoryComponent.CTRL_NAME_IS_VISIBLE);
    this.stocksPerZoneMap = this.getStocksPerZone();
    this.populateZonesFormArray(this.ctrlInventoryZones);

    this.populateGMCStatus();
  }

  populateZonesFormArray(formArray: UntypedFormArray): void {
    const zoneValidation = this.inventoryService.getValidationRules().inventoryZones;
    const fnCreateZoneForms = (zones: Zone[]) => {
      zones.forEach((zone) => {
        // First try to find actual zone into array
        let foundZone: AbstractControl = null;
        for (let i = 0; i < formArray.length; i++) {
          if (formArray.at(i).value.zoneId == zone.id) {
            foundZone = formArray.at(i);
            break;
          }
        }
        if (!foundZone) {
          // if actual zone is not in form array, means it's new zone for inventory
          const inventoryZone = Object.assign(new InventoryZone(), {
            customDutyValueCurrencyIsoCode: zone.defaultCurrencyIsoCode,
            landedCostCurrencyIsoCode: zone.defaultCurrencyIsoCode,
            standardCostCurrencyIsoCode: zone.defaultCurrencyIsoCode,
          });
          inventoryZone.zoneId = zone.id;
          inventoryZone.zone = zone;
          formArray.push(InventoryZoneForm.set(this.fb, inventoryZone, zoneValidation));
        }
      });
    };

    // Only create form for actual active Zone
    if (this.appSettingsService.getStoreId()) {
      fnCreateZoneForms([this.appSettingsService.getZone()]);
    } else {
      this.inventoryService
        .getRows("zone", JSON.stringify({ isActive: { value: true, matchMode: "equals" } }), 0, 100)
        .subscribe((result) => {
          fnCreateZoneForms(result.rows);
        });
    }
  }

  populateGMCStatus() {
    this._isFetchingStatus = true;
    this.subsList.push(
      this.gmcId$.subscribe((gmcId) => {
        if (!gmcId) return;

        this.subsList.push(
          this.inventoryService
            .getRow(this._model, this._id, {
              virtualFieldsType: "GMC",
              gmcAccountId: gmcId,
            })
            .subscribe((response) => {
              const inventoryData: Inventory = _.merge(new Inventory(), response);
              this._myForm.setControl(
                "gmcStatus",
                this.fb.group({
                  hasGMCIssues: inventoryData.hasGMCIssues,
                  googleStatusFormatted: inventoryData.googleStatusFormatted,
                  issuesSummary: inventoryData.googleErrorsSummary,
                })
              );
              // Update products status on current object
              this._object.productStatus = inventoryData.productStatus;
              this._isFetchingStatus = false;
            })
        );
      })
    );
  }

  get isGMBPushEnabled(): boolean {
    return this._myForm.get("isGMCEnabled").value && this._id && this._myForm.pristine;
  }

  get ctrlInventoryStocks(): UntypedFormArray {
    return <UntypedFormArray>this._myForm.get("inventoryStocks");
  }

  get gmcStatusForm(): UntypedFormGroup {
    return <UntypedFormGroup>this._myForm.get("gmcStatus");
  }

  initValidation() {
    const inventoryValidation = this.inventoryService.getValidationRules();
    this._validation = inventoryValidation;
    this.applyValidationToForm(inventoryValidation.inventoryGoogleShopping, this.googleShoppingForm);
  }

  onUpload(event: any) {
    for (const file of event.files) {
      this.uploadedFiles.push(file);
    }
  }

  openStockTypeDialog() {
    this.displayStockTypeDialog = true;
  }

  //openGoogleShoppingDialog() {
  //  this.displayGoogleShoppingDialog = true;
  //}

  physicalStockTypesAttributes(): string {
    const checkBoxesLabels = [];
    if (this._myForm.get("isBatch").value) {
      checkBoxesLabels.push("Batch");
    }

    if (this._myForm.get("isSerial").value) {
      checkBoxesLabels.push("Serial");
    }

    if (this._myForm.get("isKits").value) {
      checkBoxesLabels.push("Kits");
    }

    return checkBoxesLabels.join(", ");
  }

  private getStocksPerZone(): Record<number, AbstractControl[]> {
    const stocksPerZone: Record<number, AbstractControl[]> = {};

    let _ctrlInventoryStocks = this.ctrlInventoryStocks;
    if (this._myForm.get("isVariantParent").value) {
      _ctrlInventoryStocks = this._inventoryKidsStocksFormArray;
      this.inventoryService.populateStocksFormArray(_ctrlInventoryStocks, InventoryComponent.CTRL_NAME_IS_VISIBLE);
    }

    _ctrlInventoryStocks.controls.forEach((stockCtrl) => {
      const zoneId = stockCtrl.get("stock").value.zoneId as number;
      if (!stocksPerZone[zoneId]) {
        stocksPerZone[zoneId] = [];
      }
      stocksPerZone[zoneId].push(stockCtrl);
    });

    return stocksPerZone;
  }

  pushToGMC() {
    this.subsList.push(
      zip(this.gmcId$, this.gmbLocationId$).subscribe(([gmcId, gmbLocationId]) => {
        if (!gmcId || !gmbLocationId) {
          this.messageService.add(
            this.alertMessage.getErrorMessage(null, "ERROR Pushing to Google", "GMB Location not provided")
          );
          return;
        }

        this.subsList.push(
          this.inventoryService.getRow("gmbLocation", gmbLocationId).subscribe((gmbLocation) => {
            this.subsList.push(
              this.gmcProductService.pushToGoogle(gmcId, gmbLocation, this._object).subscribe((response) => {
                this.populateGMCStatus();
              })
            );
          })
        );
      })
    );
  }

  openGMCDetails() {
    const ref = this.dialogService.open(GMCProductErrorDetailsComponent, {
      width: "75%",
      data: this._object,
    });
  }

  /**
   * First try to find the current pinned picture,
   * if not present returns the first picture or null if gallery is empty
   */
  get mainPictureForm(): AbstractControl | null {
    const pinned = this.findPinnedPictureFromGallery();
    if (pinned) return pinned;

    if (this.inventoryImagesArray.length > 0) return this.inventoryImagesArray.at(0);

    return null;
  }

  get mainPictureSrc() {
    return this.mainPictureForm ? this.mainPictureForm.get("urlFileName")?.value : null;
  }

  get displayTitle() {
    if (this._myForm.get("parentInventoryId").value !== null) {
      return this._myForm
        .get("inventoryVariants")
        .value?.map((_inventoryVariant) => _inventoryVariant.inventoryOptionsSettingValue?.optionValue)
        .join(" / ");
    } else {
      return "Inventory";
    }
  }

  get inventoryVariantsTitle() {
    return this._myForm
      .get("inventoryVariants")
      .value?.map(
        (_inventoryVariant) =>
          _inventoryVariant.inventoryOptionsSetting?.optionName +
          ": " +
          _inventoryVariant.inventoryOptionsSettingValue?.optionValue
      )
      .join(" / ");
  }

  get iconName() {
    return this._myForm.get("parentInventoryId").value !== null ? "photo_size_select_small" : "";
  }

  findPinnedPictureFromGallery(): AbstractControl {
    // Find pinned picture and return it
    // Asummes there is only one pinned picture at a time
    for (let i = 0; i < this.inventoryImagesArray.length; i++) {
      const theImage = this.inventoryImagesArray.at(i);
      if (theImage.get("pinToPictureGallery").value) return theImage;
    }

    return null;
  }

  openGalleryDialog() {
    this.dialogService.open(InventoryGalleryDialogComponent, {
      styleClass: "inventory-gallery-dialog fullscreen-mobilemodal",
      contentStyle: {
        overflow: "auto",
        width: "85vw",
        maxWidth: "800pt",
        minWidth: "620pt",
        padding: "0",
      },
      data: {
        imagesFormArray: this.inventoryImagesArray,
        indexMainImage: _.findIndex(this.inventoryImagesArray.controls, this.mainPictureForm),
      },
    });
  }

  openLalelSettings() {
    this.dialogService.open(InventoryLabelSettingsComponent, {
      styleClass: "inventory-lable-settings fullscreen-mobilemodal",
      contentStyle: {
        overflow: "auto",
        width: "85vw",
        maxWidth: "800pt",
        minWidth: "620pt",
        padding: "7px",
      },
      data: {
        inventoriesArray: [this._object],
      },
    });
  }

  private updateHistoricalData() {
    this.subsList.push(
      this.inventoryService.getStatistics(this._object.id).subscribe((statsList) => {
        // Find stats corresponding to currency
        const inventoryCurrency = this._object.standardPriceCurrencyIsoCode;
        this.activeSaleStats = Object.assign(
          {},
          statsList.find((stats) => stats.currencyIsoCode === inventoryCurrency)
        );

        this.activeSaleStats.TotalSales = 0;
        this.activeSaleStats.TotalQtySales = 0;
        this.activeSaleStats.InvoiceCount = 0;
        this.activeSaleStats.LastTransaction = null;
        this.activeSaleStats.LastSalePrice = 0;

        const _saleStatsList = statsList.filter((stats) => {
          return (
            stats.currencyIsoCode === inventoryCurrency &&
            (stats.docType === SaleDocType.sales_invoice ||
              stats.docType === SaleDocType.online_sales_order ||
              stats.docType === SaleDocType.recurring_order ||
              stats.docType === SaleDocType.pre_order)
          );
        });

        _saleStatsList.map((_stats) => {
          this.activeSaleStats.TotalSales += _stats.TotalSales;
          this.activeSaleStats.TotalQtySales += _stats.TotalQtySales;
          this.activeSaleStats.InvoiceCount += _stats.InvoiceCount;
          if (_stats.LastTransaction > (this.activeSaleStats.LastTransaction || "")) {
            this.activeSaleStats.LastTransaction = _stats.LastTransaction;
          }
        });
        this.activeSaleStats.LastSalePrice = _saleStatsList.find(
          (stats) => stats.LastTransaction === this.activeSaleStats.LastTransaction
        )
          ? _saleStatsList.find((stats) => stats.LastTransaction === this.activeSaleStats.LastTransaction).LastSalePrice
          : 0;

        if (this.activeSaleStats) {
          if (this.activeSaleStats.LastTransaction) {
            const timeAgo = moment(this.activeSaleStats.LastTransaction).fromNow(true);
            // const timeAgo = moment.utc(this.activeSaleStats.LastTransaction).fromNow(true);
            this.lastTxrRelativeTime = TimeHelpers.ParseRelativeTimeString(timeAgo);
          } else {
            this.lastTxrRelativeTime = null;
          }
          if (this.activeSaleStats.InvoiceCount) {
            this.activeSaleStats.avgQtySale = this.activeSaleStats.TotalQtySales / this.activeSaleStats.InvoiceCount;
          } else {
            this.activeSaleStats.avgQtySale = null;
          }
        }

        this.activeReturnStats = statsList.find(
          (stats) => stats.currencyIsoCode === inventoryCurrency && stats.docType === SaleDocType.sales_return
        );
      })
    );
  }

  // clearAccountField() {
  //   this._myForm.get("accountId").reset();
  // }

  // onSearchResAccount(accountResult: SearchResultItem) {
  //   const accountId = accountResult.ID;
  //   this._myForm.get("accountId").setValue(accountId);
  //   this._myForm.get("accountId").markAsDirty();
  // }

  onSave() {
    this.subsList.push(
      this.validateBarcodes().subscribe((canSave) => {
        if (canSave) {
          super.onSave();
        }
      })
    );
  }

  validateBarcodes(): Observable<boolean> {
    const touchedBarcodes = [];
    this.inventoryBarcodesArray.controls.forEach((formControl) => {
      if (formControl.dirty) {
        touchedBarcodes.push(formControl.value.barcode);
      }
    });
    let touchedAndDuplicateBarcodes = [];

    if (touchedBarcodes.length === 0) {
      return of(true);
    } else {
      const inventoryFilter = {
        "inventoryBarcodes.barcode": {
          value: touchedBarcodes,
          matchMode: "in",
        },
      };

      return this.inventoryService.getRows("inventory", JSON.stringify(inventoryFilter)).pipe(
        mergeMap((inventories) => {
          if (inventories.count === 0) {
            return of(true);
          } else {
            touchedAndDuplicateBarcodes = touchedBarcodes.filter((touchedBarcode) =>
              inventories.rows.some((item) =>
                item.inventoryBarcodes.some((inventoryBarcode) => inventoryBarcode.barcode === touchedBarcode)
              )
            );

            const dispTouchedAndDuplicateBarcodes = [];
            touchedAndDuplicateBarcodes.forEach((barcode) => {
              inventories.rows.forEach((inventory) => {
                if (inventory.inventoryBarcodes.some((inventoryBarcode) => inventoryBarcode.barcode === barcode)) {
                  const formattedBarcode = `${barcode} (${inventory.sku})`;
                  dispTouchedAndDuplicateBarcodes.push(formattedBarcode);
                }
              });
            });

            return new Observable<boolean>((observer) => {
              this.confirmationService.confirm({
                header: "Duplicate Barcode Warning",
                message: `The following newly added barcode(s) are already used with other product(s): <br><br> ${dispTouchedAndDuplicateBarcodes.join(
                  "<br>"
                )} <br><br> Do you still want to proceed?`,
                rejectButtonStyleClass: "p-button-link",
                accept: () => {
                  observer.next(true);
                  observer.complete();
                },
                reject: () => {
                  observer.next(false);
                  observer.complete();
                },
              });
            });
          }
        })
      );
    }
  }

  getStoresInZone(e, zone: Zone) {
    const filter = {};
    filter["zoneId"] = {
      value: zone.id,
      matchmode: "equals",
    };
    this.inventoryService.getRows("store", JSON.stringify(filter), 0).subscribe((response) => {
      this.storesToDisplayInOverlay = response.rows;
      this.storesOfZoneOverlayEl.toggle(e);
    });
  }

  copyValueToClipboard(value: string) {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        // console.log("copied to clipboard!");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  inventoryOptionsSettingValuesChips(value) {
    return value ? value.map((row) => row.optionValue) : [];
  }

  onApplyVariantsCheckBoxClicked($event) {
    if (!$event.checked && this._object.isVariantParent) {
      this.confirmationService.confirm({
        header: "Warning:",
        message: ` Disabling Variants will unlink all associated products. This action cannot be reversed. Are you sure you want to proceed?`,
        rejectButtonStyleClass: "p-button-link",
        accept: () => {
          this._myForm.get("isVariantParent").setValue(false);
          (this._myForm.get("inventoryVariantSettings") as UntypedFormArray).clear();
          // save form
          // all kids needs to be independant
          if (this._inventoryVariantsList.length > 0) {
            const objectIds = this._inventoryVariantsList.map((object) => object.id);
            const filter = {
              id: { matchMode: "in", value: objectIds },
            };

            this.subsList.push(
              this.inventoryService
                .patchRows(
                  this._model,
                  {
                    parentInventoryId: null,
                    inventoryVariants: [],
                  },
                  JSON.stringify(filter)
                )
                .subscribe({
                  next: (response) => {
                    this.messageService.add(this.alertMessage.getMessage("multi-edit-success"));
                    this._inventoryVariantsList = [];
                  },
                  error: (error) => {
                    this.messageService.add(this.alertMessage.getErrorMessage(error, "", ""));
                  },
                })
            );
          }

          this._myForm.get("parentInventoryId").setValue(null);
          this._myForm.setControl("inventoryVariants", this.fb.array([]));
          this.onSave();
        },
        reject: () => {
          this._myForm.get("isVariantParent").setValue(this._object.isVariantParent);
          this._myForm.get("isVariantParent").markAsPristine();
        },
      });
    }
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
