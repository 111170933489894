/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
import { formatDate, getLocaleDirection } from "@angular/common";
import { Component, Inject, LOCALE_ID, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Col, RowSelectionType, FormListFilter } from "../../../form-list/form-list/form-list";
import { CashoutHistoryService } from "./cashout-history.service";
import * as moment from "moment";
import { Observable, of, Subscription } from "rxjs";
import { FormListComponent } from "src/app/form-list/form-list/form-list.component";
import { SelectItem } from "primeng/api";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";

@Component({
  selector: "taku-cashout-history",
  templateUrl: "./cashout-history.component.html",
  styleUrls: ["./cashout-history.component.scss"],
})
export class CashoutHistoryComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];
  lookup_stores$: Observable<SelectItem[]> = of([]);

  @ViewChild("formList") formListComponent: FormListComponent;

  constructor(
    private cashoutHistoryService: CashoutHistoryService,
    private fb: UntypedFormBuilder,
    private appSettingsService: AppSettingsStorageService,
    @Inject(LOCALE_ID) private defaultLocale: string
  ) {}

  private static readonly DATE_FORMAT = "yyyy-MM-dd";
  RowSelectionType = RowSelectionType;
  cashoutHistoryColumns: Col[];
  formGroup: UntypedFormGroup;
  showFormList = true;
  formFilter: {};
  isAdmin: boolean;

  ngOnInit() {
    this.cashoutHistoryColumns = this.cashoutHistoryService.getFormListColumns();
    const date = new Date();
    const y = date.getFullYear();
    const m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);
    const _storeId = this.appSettingsService.getStoreId();

    this.formGroup = this.fb.group({
      docNo: null,
      accountId: 0,
      dateFrom: formatDate(firstDay, CashoutHistoryComponent.DATE_FORMAT, this.defaultLocale),
      dateTo: formatDate(lastDay, CashoutHistoryComponent.DATE_FORMAT, this.defaultLocale),
      storeId: this.isAdmin ? 0 : _storeId,
    });

    this.subsList.push(
      this.cashoutHistoryService.getCashoutDetails.subscribe((cashout) => {
        this.formListComponent.goEdit(cashout);
      })
    );

    this.searchDocuments();

    const activeStore = this.appSettingsService.getStore();
    this.isAdmin = !activeStore.id;
    this.subsList.push(
      this.appSettingsService.activeStoreChanged$.subscribe((store) => {
        this.isAdmin = !store.id;
      })
    );

    this.lookup_stores$ = this.cashoutHistoryService.lookup_stores();
  }

  searchDocuments() {
    this.showFormList = true;
    const searchQuery = this.formGroup.value;
    const queryObject: FormListFilter = {};

    if (searchQuery.storeId !== 0) {
      queryObject["storeId"] = { value: searchQuery.storeId, matchMode: "equals" };
    }

    if (searchQuery.dateFrom || searchQuery.dateTo) {
      if (!searchQuery.dateFrom) {
        searchQuery.dateFrom = "1800-01-01";
      }

      if (!searchQuery.dateTo) {
        const newDateTo = moment().add(100, "years").toDate();
        searchQuery.dateTo = formatDate(newDateTo, CashoutHistoryComponent.DATE_FORMAT, this.defaultLocale);
      }

      queryObject.closingDate = { value: [searchQuery.dateFrom, searchQuery.dateTo], matchMode: "between" };
    }

    // TODO: Add filters for date range
    this.formFilter = queryObject;
  }

  clearSearch() {
    this.formGroup.reset();
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
