<div *ngIf="isLanding" class="card grid justify-content-center">
  <div class="sm:col-12 md:col-10 lg:col-6 lg:col-6">
    <h1><strong>TAKU Payments</strong></h1>
    <h3>Start getting paid</h3>
    <p>Take payments wherever you make sales.</p>
    <p>
      Turn on TAKU Pay to easily start accepting credit cards and other payments methods fully integrated into your
      point-of-sale system.
    </p>
    <ul>
      <li>Modern, reliable payment terminals</li>
      <li>Minimize human error</li>
      <li>Minimize the potential for theft</li>
      <li>Get paid faster</li>
    </ul>
    <p>
      <a href="https://help.takulabs.io/apps/payments/in-person-payments/taku" target="_blank">Learn more</a>
      about TAKU Payments
    </p>
    <div class="aligncenter col-12">
      <a pButton class="taku-button-large p-component" [routerLink]="'/integrations/my-apps/takuPaymentAccount/0'"
        >GET STARTED</a
      >
    </div>
    <p class="aligncenter">
      <img src="assets/layout/images/settings/integrations/taku-pay-terminal.png" style="width: 12vw" alt="TAKU Pay" />
    </p>
  </div>
</div>
<div *ngIf="!isLanding && !isLoading">
  <taku-settings-cards
    [pageTitle]="pageTitle"
    [pageIconStyleClass]="pageIconStyleClass"
    [cardsSections]="cardsInfo"
    [isAppsCard]="false"
  >
  </taku-settings-cards>
</div>
<router-outlet></router-outlet>
