/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
  inject,
} from "@angular/core";
import { MenuItem } from "primeng/api";
import { Col, RowSelectionType } from "../../../../form-list/form-list/form-list";
import { DBService } from "../../../../shared/services/db.service";
import { SearchResultItem } from "../../../../taku-ui/taku-search-accounts/SearchResultItem";
import { TakuSearchInventoryComponent } from "../../../../taku-ui/taku-search-inventory/taku-search-inventory.component";
import { Inventory, Non_physical_stock_type } from "../../../inventory/inventory/inventory";
import { StoreSettingLetterSalePrint } from "../../../settings/store-settings/full-page-salereceipt-builder/StoreSettingLetterSalePrint";
import { StorePoliciesSettings } from "../../../settings/store-settings/store-policies-settings/StorePoliciesSettings";
import { StoreSettingTapeSalePrint } from "../../../settings/store-settings/tape-salereceipt-builder/store-setting-tape-sale-print";
import { Zone } from "../../../settings/zone-settings/Zone";
import { DocumentSearchReturnColsServiceFactory } from "../../return-document/document-search-return/document-search-return.service";
import { GenericInvoiceSaleComponent } from "../generic-invoice-doc.component";
import { FullSizeDialogName, OverlayModalName } from "../generic-sale-doc.component";
import { ParkedSalesComponent } from "../parked-sales/parked-sales.component";
import { RevisitSaleComponent } from "../revisit-sale/revisit-sale.component";
import { SaleDoc, FulfillmentStatus } from "../sale-doc/sale-doc";
import { SaleDocComponent } from "../sale-doc/sale-doc.component";
import { SaleInvoiceStateService } from "../sale-invoice-state.service";
import { InvoiceKeyboardActionsService } from "./invoice-keyboard-actions.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { InvoiceTabsIndexes, SalesSideDialogName } from "../../types/invoice-enums.type";
import { PaymentGateway } from "src/app/core/settings/integration-settings/payment-gateways/payment-gateway";
import { StoreSettingLetterReturnPrint } from "src/app/core/settings/store-settings/full-page-returnreceipt-builder/StoreSettingLetterReturnPrint";
import { StoreSettingTapeReturnPrint } from "src/app/core/settings/store-settings/tape-returnreceipt-builder/store-setting-tape-return-print";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { SaleDocLineComponent } from "../sale-doc-line/sale-doc-line.component";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { SaleDocLine } from "../sale-doc-line/sale-doc-line";
import * as _ from "lodash";
import { SelfCheckoutItemDetailsComponent } from "../self-checkout-item-details/self-checkout-item-details.component";
import { UntypedFormBuilder } from "@angular/forms";
import { DocState, SaleDocType } from "../../doc/doc";
import { SaleDocTender } from "../sale-doc-tender/sale-doc-tender";
import { throttleTime, filter, take, mergeMap } from "rxjs/operators";
import { CreateVoucherComponent } from "../create-voucher/create-voucher.component";
import { Voucher } from "../create-voucher/voucher";
import { ModelFormGroup } from "src/app/utility/ModelFormGroup";
import { ReturnInvoiceService } from "../../return-document/return-invoice.service";
import { SaleDocService } from "../sale-doc/sale-doc.service";

export type TenderScreenData = {
  zone: Zone;
  builderSettings:
    | StoreSettingTapeSalePrint
    | StoreSettingLetterSalePrint
    | StoreSettingTapeReturnPrint
    | StoreSettingLetterReturnPrint;
  storePolicies: StorePoliciesSettings;
  paymentGateways: PaymentGateway[];
  origTakuPaySaleDocTenders: SaleDocTender[];
};

@Component({
  selector: "taku-sale-invoice-doc-base",
  template: "",
  styles: [],
})
export class SaleInvoiceDocBaseComponent
  extends GenericInvoiceSaleComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild("saleDocComponent", { static: true }) docComponent: SaleDocComponent;
  // Taku Search components
  @ViewChild("inventorySearchBox") inventorySearchComponent: TakuSearchInventoryComponent;

  @ViewChild(RevisitSaleComponent) _revisitComponent: RevisitSaleComponent;
  @ViewChild(ParkedSalesComponent) _parkedSalesComponent: ParkedSalesComponent;

  readonly FulfillmentStatus = FulfillmentStatus;
  readonly SaleDocType = SaleDocType;

  // Access rights flags
  isRevisitTabVisible = false;
  isRevisitTabEnabled = false;
  isCrmTabVisible = false;
  isCrmTabEnabled = false;

  // Fields for RETURN ITEM functionality
  returnDocsColumns: Col[];
  _returnDocPreviewSelected: EventEmitter<SaleDoc> = new EventEmitter();
  docForReturnPreview: SaleDoc;

  // DocType = DocType;
  RowSelectionType = RowSelectionType;
  activeTabIndex: number = InvoiceTabsIndexes.RevisitInvoices;
  SideDialogName = SalesSideDialogName;
  FullSizeDialogName = FullSizeDialogName;

  _returnsSearchExtraParams = {
    includes: "saleDocLine,saleDocTender",
  };

  inventorySearchResults: Inventory[] = [];
  // _activeDialog: SalesSideDialogName;

  // HEADER's properties
  docTypesMenu: MenuItem[] = [];
  private docMenuIntitialized = false;

  todayDate = new Date();

  orderEditLock = false;
  private returnInvoiceService = inject(ReturnInvoiceService);

  constructor(
    viewContainer: ViewContainerRef,
    keyboardActionsService: InvoiceKeyboardActionsService,
    protected dbService: SaleDocService,
    protected saleInvoiceStateService: SaleInvoiceStateService,
    private docSearchServiceFactory: DocumentSearchReturnColsServiceFactory,
    protected authService: AuthService,
    protected _route: ActivatedRoute,
    protected _router: Router,
    public dialogService: DialogService,
    public fb: UntypedFormBuilder,
    public ref: DynamicDialogRef
  ) {
    super(viewContainer, keyboardActionsService, saleInvoiceStateService, authService);
    // this.keyboardActionsService.lineSelected$.subscribe(lineIndex => {
    //   // Focus this component
    //   this.viewContainer.element.nativeElement.focus();
    // });

    this.subsList.push(
      this.keyboardActionsService.anyKeyPressed$
        .pipe(
          throttleTime(200), // Adjust the throttle time as needed
          filter(
            (event) =>
              this.keyboardActionsService._canAnnounceNumKeyPress &&
              !(document.activeElement instanceof HTMLInputElement) &&
              !(document.activeElement instanceof HTMLTextAreaElement) &&
              !isNaN(Number(event.key)) &&
              !this._activeDialog
          )
        )
        .subscribe(() => {
          this.inventorySearchComponent.focusSearchInput();
        })
    );

    this.orderEditLock = this._route.snapshot.queryParamMap.get("orderEditLock") == "true";

    this.subsList.push(
      this._router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.orderEditLock = this._route.snapshot.queryParamMap.get("orderEditLock") == "true";
        }
      })
    );
  }

  get transactionAreaDisabled(): boolean {
    return this.dbService.transactionAreaDisabled;
  }

  ngOnInit(): void {
    const activeFilter = {
      isActive: { matchMode: "equals", value: true },
    };
    this.subsList.push(
      this.dbService.getRows("takuPaymentGateway", JSON.stringify(activeFilter)).subscribe((result) => {
        if (result.count > 0) {
          if (!this.docMenuIntitialized) {
            if (this.docComponent?.selectedDocType === SaleDocType.sales_invoice) {
              this.docTypesMenu.push({
                label: "PRE-ORDERS",
                command: () => {
                  this.docComponent.switchDocType(SaleDocType.pre_order);
                  this.updateDocTypeMenuToSales();
                },
              });
            } else {
              this.docTypesMenu.push({
                label: "SALES",
                command: () => {
                  this.docComponent.switchDocType(SaleDocType.sales_invoice);
                  this.updateDocTypeMenuToPreOrders();
                },
              });
            }
          }
        }
      })
    );

    // When a document has been selected from formlist it should communicate to search return component
    this.subsList.push(
      this._returnDocPreviewSelected
        .pipe(mergeMap((saleDoc) => this.returnInvoiceService.getSaleDocToReturn(saleDoc.id)))
        .subscribe((data) => {
          this.docForReturnPreview = data;
          this.openRightDialog(SalesSideDialogName.RETURN_ITEM_SEARCH_FORM);
        })
    );

    this.returnDocsColumns = this.docSearchServiceFactory.build(this._returnDocPreviewSelected).getFormListColumns();
    if (this.saleInvoiceStateService.hasInvoiceState()) this.saleInvoiceStateService.restoreInvoiceState(this);

    this.isRevisitTabVisible = this.authService.hasVisiblePermit("Sell_Menu_Revisit");
    this.isRevisitTabEnabled = this.authService.hasEnablePermit("Sell_Menu_Revisit");

    // if the sub-tabs of the crm tab are all hidden/disabled, we want the crm tab itself to be hidden/disabled respectively.
    this.isCrmTabVisible =
      this.authService.hasVisiblePermit("Sell_Menu_Sales_Register_CRM_Tab_History") ||
      this.authService.hasVisiblePermit("Sell_Menu_Sales_Register_CRM_Tab_Profile");
    this.isCrmTabEnabled =
      this.authService.hasEnablePermit("Sell_Menu_Sales_Register_CRM_Tab_History") ||
      this.authService.hasEnablePermit("Sell_Menu_Sales_Register_CRM_Tab_Profile");

    // If the revisit tab is disabled, we want the default opened tab to be something other than the revisit tab.
    this.activeTabIndex = this.isRevisitTabEnabled ? this.activeTabIndex : InvoiceTabsIndexes.ParkedInvoices;

    this.activeTabIndex = 4;

    // This is only really applied when an existing preorder saleDoc is pre loaded onto the page
    if (this.docComponent?._myForm?.get("doc.docType")?.value) {
      this.subsList.push(
        this.docComponent._myForm
          .get("doc.docType")
          .valueChanges.pipe(take(1))
          .subscribe((docType: SaleDocType) => {
            if (docType !== this.docComponent?.selectedDocType) {
              if (docType === SaleDocType.pre_order) {
                this.docMenuIntitialized = true;
                this.docComponent.switchDocType(SaleDocType.pre_order);
                this.updateDocTypeMenuToSales();
              }
            }
          })
      );
    }
  }

  refreshDocTypeMenuState(): boolean {
    if (this.docComponent?.selectedDocType === SaleDocType.pre_order) {
      this.updateDocTypeMenuToSales();
    } else {
      this.updateDocTypeMenuToPreOrders();
    }
    return true;
  }

  updateDocTypeMenuToSales(): void {
    this.docTypesMenu.pop();
    this.docTypesMenu.push({
      label: "SALES",
      command: () => {
        this.docComponent.switchDocType(SaleDocType.sales_invoice);
        this.updateDocTypeMenuToPreOrders();
      },
    });
  }

  updateDocTypeMenuToPreOrders(): void {
    this.docTypesMenu.pop();
    this.docTypesMenu.push({
      label: "PRE-ORDERS",
      command: () => {
        const result = this.docComponent.switchDocType(SaleDocType.pre_order);
        if (result) this.updateDocTypeMenuToSales();
      },
    });
  }

  ngOnDestroy(): void {
    this.ref?.close(false);
    super.ngOnDestroy();
    this.saleInvoiceStateService.saveInvoiceState(this);
  }

  dialogExists(validDialogs: any, testDialog): boolean {
    return Object.values(validDialogs).includes(testDialog);
  }

  onInventoryItemSaved(inventory: Inventory) {
    if (this.inventorySearchComponent) this.inventorySearchComponent.cleanAndFocusSearchInput();

    if (this._activeDialog == SalesSideDialogName.INVENTORY_DETAILS) {
      this._activeDialogExtra = inventory;
    }
  }

  ngAfterViewInit(): void {
    // TODO: See where to put the code to give focus to inventory search field
    if (this.inventorySearchComponent) {
      this.inventorySearchComponent.cleanAndFocusSearchInput();
    }
  }

  onDocSaved(saleDoc: SaleDoc): void {
    if (this._revisitComponent) {
      this._revisitComponent.refreshData();
    }

    if (this._parkedSalesComponent) {
      this._parkedSalesComponent.refreshData();
    }
  }

  ///////////////////////
  // HEADER's methods //
  /////////////////////
  onClearAccBtnPressed($event) {
    this.docComponent.clearSelectedAccount();
  }

  ///////////////////////
  // FOOTER's methods //
  /////////////////////
  onInventorySearchResults(items: SearchResultItem[]) {
    if (items.length === 0) {
      // this.msgs = [
      //   {
      //     severity: "warn",
      //     summary: "Warn Message",
      //     detail: "No returned Data"
      //   }
      // ];
      // this.inventorySearchResults = null;
    } else {
      // more than 1 returned results???
      // this.msgs = [
      //   {
      //     severity: "warn",
      //     summary: "Warn Message",
      //     detail:
      //       "more than one returned data for searched item: " +
      //       items.length
      //   }
      // ];
      // adding the search result to the other component
      this.inventorySearchResults = [
        ...(items.map((item) => item.data) as Inventory[]),
        ...(this.inventorySearchResults?.filter((row) => !items.map((item) => item.data.id).includes(row.id)) || []),
      ];
    }
  }

  restoreActiveOverlay(
    activeOverlay: OverlayModalName,
    activeOverlayExtra: any,
    activeOverlayParams: any,
    rightTabIndex: number
  ) {
    // Set behaviour on children components
  }

  openRightDialog(dialog: SalesSideDialogName, extra = null) {
    this._activeDialog = dialog;
    this._activeDialogExtra = extra;
  }

  openTenderScreen(tenderData: TenderScreenData) {
    this.openRightDialog(SalesSideDialogName.TENDER_SCREEN, tenderData);
    this.saleInvoiceStateService.anounceTenderScreenVisibility(true);
  }

  itemReturnedAction(event) {
    this.openRightDialog(SalesSideDialogName.RETURN_ITEM_SEARCH_FORM, event);
  }

  itemReturnedNoReceipt(event) {
    this.openRightDialog(SalesSideDialogName.RETURN_ITEM_NOINVOICE);
  }

  openFormListForReturnSearch(filterQuery: {}) {
    this.openRightDialog(SalesSideDialogName.RETURN_ITEM_SEARCH_RESULTS, filterQuery);
  }

  onItemReturned(inventory: Inventory) {
    // Add as a SaleDoc Line as a return (negative quantity)
    if (inventory.isVariantParent) {
      const saleLine: SaleDocLine = _.merge(new SaleDocLine(), {
        unitPrice: inventory.standardPrice,
        unitCost: inventory.defaultInventoryZone?.standardCost || 0,
        qty: 1,
        // TODO: Fill out DocLine
        docLine: {
          id: 0,
          seqNo: 1,
          note: "",
          expiryDate: null,
          serialNo: "",
          refNo: 0,
          inventoryId: inventory.id,
          inventory: inventory,
        },
      });
      const docLineForm = SaleDocLineComponent.set(this.fb, saleLine, 0);

      this.ref = this.dialogService.open(SelfCheckoutItemDetailsComponent, {
        styleClass: "selfcheckout-mobile rounded-selfcheckout-dialog",
        contentStyle: {
          overflow: "auto",
          height: "60%",
          minWidth: "280px",
          maxWidth: "680px",
          maxHeight: "1100px",
        },
        data: {
          _saleForm: docLineForm,
          _isAddToCart: true,
        },
      });

      this.subsList.push(
        this.ref.onClose.subscribe((result) => {
          if (result && result.qtySelected > 0) {
            this.docComponent._addToSaleAsNewLine(docLineForm.value.docLine.inventory, result.qtySelected, true);
            // let itemIndexInCart = 0;
            // this.docComponent.saleDocLines.value.forEach((element, index) => {
            //   if (element.docLine.inventoryId === inventory.id) {
            //     itemIndexInCart = index;
            //   }
            // });
            // if (result !== undefined) {
            //   const newSaleDocLine =  this.docComponent.saleDocLines.at(itemIndexInCart);
            //   // const originalQty = newSaleDocLine.get('qty').value -1;
            //   // let newQty = 0;
            //   // newQty = originalQty + result.qtySelected;
            //   newSaleDocLine.patchValue({ qty: result.qtySelected*(-1) });
            this.closeRightDialog();
            // }
          }
        })
      );
    } else {
      this.docComponent._addToSaleAsNewLine(inventory, 1, true);
      this.closeRightDialog();
    }
  }

  onSalesLinesCleared() {
    this.inventorySearchComponent.cleanAndFocusSearchInput();
  }

  onDocReset() {
    this.inventorySearchComponent.cleanAndFocusSearchInput();

    void this._router.navigate(["/sell/saleDoc", 0], { state: { skipUnfinalizedSalesCheck: true } });
  }

  // closeRightDialog() {
  //   if (this._activeDialog === SalesSideDialogName.TENDER_SCREEN)
  //     this.saleInvoiceStateService.anounceTenderScreenVisibility(false);

  //   this._activeDialog = null;
  // }

  onSearchResInventory(item: SearchResultItem): void {
    const inventoryItem = item.data as Inventory;
    this.onInventorySelected(inventoryItem); // TODO: this method should be rewrritten
    this.inventorySearchComponent.cleanAndFocusSearchInput();
  }

  onInventorySelected(inventory: Inventory): void {
    if (inventory.nonePhysicalStockType === Non_physical_stock_type.voucher) {
      // Open special modal
      const copyInventory = _.cloneDeep(inventory);
      this.ref = this.dialogService.open(CreateVoucherComponent, {
        contentStyle: {
          height: "60%",
          maxWidth: "min(680px, calc(100vw - 2rem))",
        },
        data: {
          inventory: copyInventory,
          existingVouchers: (this.docComponent.saleDocLines.controls as ModelFormGroup<SaleDocLine>[])
            .filter((ctrl) => ctrl.controls.voucher?.value)
            .map((ctrl) => ctrl.controls.voucher.value),
        },
      });
      this.subsList.push(
        this.ref.onClose.subscribe((voucher: Voucher | null) => {
          if (voucher) {
            this.docComponent._addToSaleAsNewLine(copyInventory, 1, false, voucher);
          }
        })
      );
      return;
    }
    if (inventory.isVariantParent) {
      const saleLine: SaleDocLine = _.merge(new SaleDocLine(), {
        unitPrice: inventory.standardPrice,
        unitCost: inventory.defaultInventoryZone?.standardCost || 0,
        qty: 1,
        // TODO: Fill out DocLine
        docLine: {
          id: 0,
          seqNo: 1,
          note: "",
          expiryDate: null,
          serialNo: "",
          refNo: 0,
          inventoryId: inventory.id,
          inventory: inventory,
        },
      });
      const docLineForm = SaleDocLineComponent.set(this.fb, saleLine, 0);

      this.ref = this.dialogService.open(SelfCheckoutItemDetailsComponent, {
        styleClass: "selfcheckout-mobile rounded-selfcheckout-dialog",
        contentStyle: {
          overflow: "auto",
          height: "60%",
          minWidth: "280px",
          maxWidth: "680px",
          maxHeight: "1100px",
        },
        data: {
          _saleForm: docLineForm,
          _isAddToCart: true,
        },
      });

      this.subsList.push(
        this.ref.onClose.subscribe((result) => {
          if (result && result.qtySelected > 0) {
            this.docComponent.addToSaleDoc(docLineForm.value.docLine.inventory, result.qtySelected);
            // let itemIndexInCart = 0;
            // this.docComponent.saleDocLines.value.forEach((element, index) => {
            //   if (element.docLine.inventoryId === inventory.id) {
            //     itemIndexInCart = index;
            //   }
            // });
            // if (result !== undefined) {
            //   const newSaleDocLine =  this.docComponent.saleDocLines.at(itemIndexInCart);
            //   const originalQty = newSaleDocLine.get('qty').value -1;
            //   let newQty = 0;
            //   newQty = originalQty + result.qtySelected;
            //   newSaleDocLine.patchValue({ qty: newQty });
            // }
          }
        })
      );
    } else {
      this.docComponent.addToSaleDoc(inventory);
    }
  }

  openAddNewAccountDialog(e) {
    this.btnNewAccountsComponent.onDropdownButtonClick(e);
  }

  onSearchResAccount(account: SearchResultItem) {
    this.docComponent._setAsDocAccount(account);
    // only set the active tab to CRM if the user has visible and enabled permission on the CRM tab.
    if (this.isCrmTabVisible && this.isCrmTabEnabled) this.activeTabIndex = InvoiceTabsIndexes.InvoiceCRM;
    this.accountsSearchComponent.cleanAndFocusSearchInput();
  }

  onDocLinesDeleted(docNo: string) {
    if (this._returnsSearchComponent) this._returnsSearchComponent.undoAllReturns(docNo);
    if (this._saleDocListComponent) this._saleDocListComponent.undoAllSale();
  }

  docLineQtyChanged({ saleDocLines, saleDocLine, newQty }) {
    if (newQty < 0 || saleDocLine.qty < 0) {
      const _rows = saleDocLines.filter(
        (row) =>
          row.docLine.inventoryId === saleDocLine.docLine.inventoryId &&
          row.docLine.seqNo !== saleDocLine.docLine.seqNo &&
          row.qty < 0
      );
      if (this._returnsSearchComponent)
        this._returnsSearchComponent.returnQtyChanged(
          saleDocLine.id,
          -1 * newQty - _rows.map((row) => row.qty).reduce((a, b) => a + b, 0)
        );
    }
    const _rows = saleDocLines.filter(
      (row) =>
        row.docLine.inventoryId === saleDocLine.docLine.inventoryId && row.docLine.seqNo !== saleDocLine.docLine.seqNo
    );

    // this.docLineQtyChanged.emit({ saleDocLine: saleDocLine, qty: -1*_rows.map((row)=>row.qty).reduce((a,b)=>a+b,0) });
    if (this._saleDocListComponent)
      this._saleDocListComponent.saleQtyChanged(
        saleDocLine,
        -1 * newQty - _rows.map((row) => row.qty).reduce((a, b) => a + b, 0)
      );
  }

  openRevisitInFullScreen(componentState) {
    this.docComponent.openFullSizeDialog(FullSizeDialogName.REVISIT_SALES, componentState, {
      styleClass: "revisit-dialog",
    });
  }

  get showChangeDue() {
    return this.docComponent._lastChangeDue && this.docComponent._myForm.pristine;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
