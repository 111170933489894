import { Component } from "@angular/core";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { AisleService } from "./aisle.service";
import { Col, RowSelectionType } from "src/app/form-list/form-list/form-list";
import { FilterRows } from "src/app/utility/FormDataHelpers";
import { Aisle } from "./aisle";

@Component({
  selector: "taku-aisle",
  templateUrl: "./aisle.component.html",
  styleUrls: ["./aisle.component.scss"],
})
export class AisleComponent {
  stockId: number;
  formFilter: FilterRows<Aisle>;
  defaultNewRowValues: Record<string, unknown>;
  RowSelectionType = RowSelectionType;
  aisleColumns: Col[] = [];

  constructor(private aisleService: AisleService, private dialogConfig: DynamicDialogConfig) {
    this.aisleColumns = this.aisleService.getFormListColumns();
    this.stockId = this.dialogConfig.data.stockId;
    this.formFilter = { stockId: { value: this.stockId, matchMode: "equals" } };
    this.defaultNewRowValues = { stockId: this.stockId };
  }
}
