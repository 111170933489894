/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import * as _ from "lodash";
import * as moment from "moment";
import { NestedFormComponent } from "../../../../forms/nested-form/nested-form.component";
import { DBService } from "../../../../shared/services/db.service";
import { CommercialAccount } from "../../../contact-accounts/commercial-account/commercial-account";
import { PersonalAccount } from "../../../contact-accounts/personal-account/personal-account";
import { ContactAccountBase } from "src/app/core/contact-accounts/account/contact-account-base";
import { AuthService } from "src/app/shared/services/auth.service";
import { AccountType } from "src/app/core/contact-accounts/account/account";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { ConsentFormInfo, PersonComponent } from "src/app/core/contact-accounts/person/person.component";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { AccountCreditSummary, AccountService } from "src/app/core/contact-accounts/account/account.service";

export type CompoundAccount = CommercialAccount | PersonalAccount;

@Component({
  selector: "taku-crm-sale",
  templateUrl: "./crm-sale.component.html",
  styleUrls: ["./crm-sale.component.scss"],
})
export class CRMSaleComponent extends NestedFormComponent implements OnChanges, OnInit {
  isCrmProfileTabVisible = false;
  isCrmHistoryTabVisible = false;
  isCrmAccountDetailsVisible = false;
  isCrmAccountDetailsEnabled = false;

  @Input() accountInfo: CompoundAccount;
  @Input() shortenFormHeaderHeight = false;
  @Output() onAccountFormRequested: EventEmitter<CompoundAccount> = new EventEmitter();
  @Output() onCustomerConsentBtn: EventEmitter<{ personForm: UntypedFormGroup; accountData: PersonalAccount }> =
    new EventEmitter();
  accountInitials: string[] = [];
  _mainTabActiveIndex = 0;

  showCustomerConsent = false;
  personForm: UntypedFormGroup;

  isConsentApproved = false;
  consentDateTime: Date;
  enableConsent = false;
  @Output() consentApproved: EventEmitter<ConsentFormInfo> = new EventEmitter();
  _model: string;
  accountCreditSummary: AccountCreditSummary[] = [];

  constructor(
    public dbService: DBService,
    public fb: UntypedFormBuilder,
    protected appSettingsService: AppSettingsStorageService,
    private authService: AuthService,
    public ref: DynamicDialogRef,
    private accountService: AccountService
  ) {
    super(dbService, fb);

    this.isCrmProfileTabVisible = authService.hasVisiblePermit("Sell_Menu_Sales_Register_CRM_Tab_Profile");
    this.isCrmHistoryTabVisible = authService.hasVisiblePermit("Sell_Menu_Sales_Register_CRM_Tab_History");
  }

  trackByCreditSummary(item: AccountCreditSummary): number {
    return item.tenderTypeId;
  }

  onDetailsBtnClicked($event) {
    this.onAccountFormRequested.emit(this.accountInfo);
  }

  onCustomerConsentBtnClicked($event) {
    const personFormAndAccountData = { personForm: this.personForm, accountData: this.accountInfo as PersonalAccount };
    this.onCustomerConsentBtn.emit(personFormAndAccountData);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["accountInfo"]) {
      if (this.accountInfo) {
        this.accountInfo = ContactAccountBase.ConvertIntoTypedObject(this.accountInfo);

        if (this.accountInfo.account.accountType == AccountType.commercial) {
          this.isCrmAccountDetailsVisible = this.authService.hasVisiblePermit("Accounts_Menu_Commercial_Accounts");
          this.isCrmAccountDetailsEnabled = this.authService.hasEnablePermit("Accounts_Menu_Commercial_Accounts");
          this.enableConsent = false;
        } else {
          this.isCrmAccountDetailsVisible = this.authService.hasVisiblePermit("Accounts_Menu_Personal_Accounts");
          this.isCrmAccountDetailsEnabled = this.authService.hasEnablePermit("Accounts_Menu_Personal_Accounts");
          this.enableConsent = true;
          this.setConsentInformation();
          this.initForm();
        }
        this.subsList.push(
          this.accountService
            .getCreditSummary(this.accountInfo.accountId)
            .subscribe((response) => (this.accountCreditSummary = response))
        );
      }
    }
    this._mainTabActiveIndex = null;
    setTimeout(() => {
      this._mainTabActiveIndex = 0;
    }, 0);
  }

  initForm() {
    this._model = "personalAccount";
    // this._object = new CRMSale();
    this.personForm = PersonComponent.set(this.fb, (this.accountInfo as PersonalAccount).person);
  }

  setConsentInformation() {
    this.isConsentApproved = (this.accountInfo as PersonalAccount).consent;
    this.consentDateTime = (this.accountInfo as PersonalAccount).consentDateTime;
  }

  get accountPrimaryEmail(): string {
    return this.accountInfo.accountEmails?.length ? this.accountInfo.accountEmails[0] : null;
  }

  get accountPrimaryPhone(): string {
    return this.accountInfo.accountPhones?.length ? this.accountInfo.accountPhones[0] : null;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
