import { ElementRef, Injectable } from "@angular/core";
import { AbstractControl } from "@angular/forms";
declare var b2bapis: any;

@Injectable({
  providedIn: "root",
})
export class TizenBarcodeService {
  constructor() {}

  getBarcodeVersion() {
    let Version = null;
    try {
      Version = b2bapis.b2bbarcode.getVersion();
    } catch (e) {
      console.log(
        "[Barcode API][getVersion] call syncFunction exception [" +
          e.code +
          "] name: " +
          e.name +
          " message: " +
          e.message
      );
    }
    if (null !== Version) {
      console.log("[Barcode API][getVersion] call syncFunction type: " + Version);
    }
  }
  setBarcodeTimeOut() {
    try {
      const SCANTIME = "90";
      const time = parseInt(SCANTIME);
      console.log("[Barcode API][setScanTimeOut] " + time);
      const msgData = b2bapis.b2bbarcode.setScanTimeOut(time);
      console.log("[Barcode API][setScanTimeOut] " + msgData);
    } catch (e) {
      console.log(
        "[Barcode API][setScanTimeOut] call syncFunction exception " + e.code + " " + e.errorName + " " + e.errorMessage
      );
    }
  }
  startBarcode() {
    function barCodeListner(barCodeData) {
      if (barCodeData.data != "NOT_DETECTED") {
        console.log("[Barcode API][startScanBarCode] Barcode data is " + barCodeData.data);
      } else {
        console.log("[Barcode API][startScanBarCode] Scan timeout finished, please try again");
      }
    }

    try {
      console.log("[Barcode API][startScanBarCode] Calling Start - Scanning");
      const msgData = b2bapis.b2bbarcode.startScanBarcode(barCodeListner);
      console.log("[Barcode API][startScanBarCode] " + msgData);
    } catch (e) {
      console.log(
        "[Barcode API][startScanBarCode] call syncFunction exception " +
          e.code +
          " " +
          e.errorName +
          " " +
          e.errorMessage
      );
    }
  }
  stopBarcode() {
    try {
      console.log("[Barcode API][stopScanBarCode] Calling Stop");
      b2bapis.b2bbarcode.stopScanBarcode();
    } catch (e) {
      console.log(
        "[Barcode API][stopScanBarCode] exception [" + e.code + "] name: " + e.name + " message: " + e.message
      );
    }
  }

  startContinuousBarcodeScanning(_control: AbstractControl, _el: ElementRef) {
    function barCodeListner(barCodeData) {
      if (barCodeData.data != "NOT_DETECTED") {
        _control.setValue(barCodeData.data);
        _el.nativeElement.dispatchEvent(new KeyboardEvent("keydown", { key: "Enter" }));
        console.log("[Barcode API][startScanBarCode] Barcode data is " + barCodeData.data);
      } else {
        console.log("[Barcode API][startScanBarCode] Scan timeout finished, please try again");
      }
    }
    try {
      b2bapis.b2bbarcode.startScanBarcode(barCodeListner);
    } catch (e) {
      console.log(
        "[Barcode API][startScanBarCode] call syncFunction exception " +
          e.code +
          " " +
          e.errorName +
          " " +
          e.errorMessage
      );
    }
  }
}
