<div class="payment-section">
  <div class="subtotals-and-tax">
    <div class="col-12">
      <span class="">Subtotal</span>
      <span>
        {{ invoiceSubTotal | currency : currencyIsoCode : "symbol-narrow" : "1.2" | formatNegativeNumber }}
      </span>
    </div>
    <!-- <div class="col-12">
      <span class="">Shipping</span>
      <span>
        {{ invoiceTotalShipping | currency:currencyIsoCode:'symbol-narrow':'1.2' | formatNegativeNumber }}
      </span>
    </div> -->
    <div *ngIf="invoiceTotalTax === 0" class="col-12">
      <span class="">Tax</span>
      <span>
        {{ invoiceTotalTax | currency : currencyIsoCode : "symbol-narrow" : "1.2" | formatNegativeNumber }}
      </span>
    </div>
    <div *ngFor="let tax of groupedTaxes" class="col-12 each-line">
      <span>
        {{ tax?.saleTaxRule?.ruleName ? tax?.saleTaxRule?.ruleName : tax?.saleTaxRule?.tax?.shortDesc }}
      </span>
      <span>
        {{ tax?.total | currency : currencyIsoCode : "symbol-narrow" : "1.2" | formatNegativeNumber }}
      </span>
    </div>
  </div>
  <div class="pay-btn-container">
    <button
      type="button"
      pButton
      pRipple
      class="p-button-rounded"
      (click)="onSelfCheckoutPayBtnPressed()"
      [disabled]="saleDocLines.length == 0"
    >
      <div class="btn-label-container">
        <span>PAY</span>
        <span>{{ invoiceTotal | currency : currencyIsoCode : "symbol-narrow" : "1.2" | formatNegativeNumber }}</span>
      </div>
    </button>
  </div>
</div>
