import { Injectable, inject } from "@angular/core";
import { UntypedFormArray, UntypedFormGroup } from "@angular/forms";
import { ConfirmationService } from "primeng/api";
import { Observable, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class TenderWarningService {
  private confirmationService = inject(ConfirmationService);
  private hasWarnedForCurrentForm = false;
  private currentForm: UntypedFormGroup;

  private get saleDocTenders(): UntypedFormArray {
    return this.currentForm.controls.saleDocTenders as UntypedFormArray;
  }

  init(currentForm: UntypedFormGroup): void {
    this.hasWarnedForCurrentForm = false;
    this.currentForm = currentForm;
  }

  clear(): void {
    this.hasWarnedForCurrentForm = false;
    this.currentForm = null;
  }

  warnAboutTenders(acceptCallback: () => void, rejectCallback?: () => void): Observable<boolean> {
    if (this.hasWarnedForCurrentForm || this.saleDocTenders.length === 0) {
      acceptCallback();
      return of(true);
    }
    this.hasWarnedForCurrentForm = true;
    return new Observable<boolean>((observer) => {
      observer.add(() => this.confirmationService.close());
      this.confirmationService.confirm({
        header: "Warning",
        message:
          "There are already tenders applied to this transaction.<br /><br />Are you sure you want to remove this item?",
        rejectButtonStyleClass: "p-button-link",
        accept: () => {
          acceptCallback();
          observer.next(true);
          observer.complete();
        },
        reject: () => {
          rejectCallback?.();
          observer.next(false);
          observer.complete();
        },
      });
    });
  }
}
