/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { GenericPhoneComponent } from "../phone-shared/phone.component.shared";
import { PersonPhone, PersonPhoneType } from "./person-phone";

@Component({
  selector: "taku-person-phone",
  templateUrl: "../phone-shared/phone.component.shared.html",
  styleUrls: ["../phone-shared/phone.component.shared.scss"],
})
export class PersonPhoneComponent extends GenericPhoneComponent {
  @Input() disableForm?: boolean = false;

  enum_phone_type = this.dbService.enumSelectOptions(PersonPhoneType);

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    const personPhone_Group = fb.group(new PersonPhone());
    return personPhone_Group;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
