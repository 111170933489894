<div class="col-12">
  <strong>{{ countEnabled }}</strong> selected items are <strong>enabled</strong>
</div>
<div class="col-12">
  <strong>{{ countDisabled }}</strong> selected items are <strong>disabled</strong>
</div>
<div class="col-12">
  <taku-checkbox
    [disabled]="!countDisabled"
    [formGroup]="pushForm"
    fieldName="includeDisabled"
    fieldCaption="{{ checkBoxCaption }}"
  ></taku-checkbox>
</div>

<div class="col-12 question-message grid-align-right">
  <i class="pi pi-info-circle"></i> <span class="text" [innerHTML]="info"></span>
</div>

<div class="col-12 actionBtn-container grid-align-right">
  <button
    pButton
    icon="ui-icon-check"
    label="{{ submitButtonCaption }}"
    (click)="onActivateGMC()"
    [disabled]="totalToSubmit < 1"
  ></button>
</div>
