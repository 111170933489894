/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Location } from "@angular/common";
import { Component, Input, OnInit, ViewChild, Output, EventEmitter, OnDestroy } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { FormListColumnsService } from "../form-list-columns.service";
import { Col, SettingsColumn, RowSelectionType } from "../form-list/form-list";
import { FormListComponent } from "../form-list/form-list.component";
import * as _ from "lodash";
import { Observable, Subscription } from "rxjs";

@Component({
  selector: "taku-form-list-main",
  templateUrl: "./form-list-main.component.html",
  styleUrls: ["./form-list-main.component.scss"],
  providers: [FormListColumnsService],
})
export class FormListMainComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];

  // Form list fields
  _model: string;
  _formlistTitle: string;
  _cols: Col[] = [];
  _detailMode: "n" | "d" | "w" | "s";
  _settingsCol: SettingsColumn;
  _headerInfo: string;

  // Page fields
  _navigationText: string;
  _pageTitlePrefix: string;
  _pageIconStyleClass: string;
  _pageIconName: string;
  _disableBackLink = false;
  _rowSelectionType = RowSelectionType.MULTIPLE;
  saveFormState = true;

  @Input() _hideDeleteHeaderBtn = false;
  @Input() _disableAddRow = false;
  @Input() _filter?: string;
  @Input() beforeSaveAll?: (objects: any[], orgObjects: any[]) => Observable<boolean>;
  @Input() beforeDeleteRows?: (selectedObjects: any[]) => Observable<boolean>;
  @Input() pageTitleSuffix = "";
  @Input() _defaultRowValues: {};
  @Input() _sortField = "";
  @Input() extraButtonIconClass = "";
  @Input() _scrollHeight = "calc(100vh - 20rem)";
  @Output() extraButtonClicked = new EventEmitter();
  @ViewChild(FormListComponent, { static: true }) formListComponent: FormListComponent;

  _extraQueryParams: {};

  get _pageTitle() {
    return this._pageTitlePrefix ? `${this._pageTitlePrefix} ${this.pageTitleSuffix}` : "";
  }

  active = false;

  constructor(
    protected location: Location,
    protected _route: ActivatedRoute,
    protected formListColumnsService: FormListColumnsService
  ) {}

  ngOnInit() {
    this.subsList.push(
      this._route.queryParams.subscribe((params) => {
        this.active = false;
        setTimeout(() => (this.active = true), 0);
        this._formlistTitle = params["title"];

        if (params["queryExtraParams"]) {
          this._extraQueryParams = JSON.parse(params["queryExtraParams"]);
        }
      })
    );
    this._route.params.forEach((params: Params) => {
      this._detailMode = params["detailmode"];
      this._model = params["model"];

      if (params["filter"] && !this._filter) {
        this._filter = params["filter"];
      }
      this._cols = this.formListColumnsService.getDefaultColumns(this._model);
    });

    this.subsList.push(
      this._route.data.subscribe((data) => {
        this._navigationText = data.navigationText;
        this._pageTitlePrefix = data.pageTitle;
        this._pageIconStyleClass = data.pageIcon;
        this._pageIconName = data.pageIconName;
        this._settingsCol = data.settingsCol;
        this._disableBackLink = data.disableBackLink;
        this._disableAddRow = _.get(data, "disableAddRow", this._disableAddRow);
        this._rowSelectionType = _.get(data, "rowSelectionType", this._rowSelectionType);
        this._headerInfo = data.headerInfo;
        if (typeof data.saveFormState !== "undefined") {
          this.saveFormState = data.saveFormState;
        }
      })
    );

    // for Inventory List page formlist CAN'T DO THIS FOR REUSABILITY
    // this.subsList.push((this.formListColumnsService.getColumnsService('inventory') as InventoryService).getInventoryDetails.subscribe((inventory) => {
    //   this.formListComponent.goEdit(inventory);
    // }))

    // for Personal Account FormList
    // for Commercial Account FormList
  }

  goBack(): void {
    this.location.back();
  }

  emitExtraButtonClicked(e) {
    e.formListComponent = this.formListComponent;
    this.extraButtonClicked.emit(e);
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
