/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, Input, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { ChannelType } from "../../sale-channel/sale-channel";

export type SettingCardSection = {
  label: string;
  cards: SettingCardInfo[];
};

export type SettingCardInfo = {
  id?: string;
  routePath?: string[];
  channelType?: ChannelType;
  openAction?: EventEmitter<any>;
  iconStyleClass: string;
  iconName?: string;
  cardTitle: string;
  cardDesc: string[];
  routeQueryParams?: {};
  isVisible?: boolean;
  isEnabled?: boolean;
  isConnected?: boolean;
};

@Component({
  selector: "taku-settings-cards",
  templateUrl: "settings-cards.component.html",
  styleUrls: ["settings-cards.component.scss"],
})
export class SettingsCardsComponent implements OnInit, OnChanges {
  @Input() isAppsCard = false;
  @Input() pageTitle: string;
  @Input() pageIconStyleClass: string;
  @Input() cardsSections: SettingCardSection[];
  // Cards info with no group ultimately gets converted to a card section
  @Input() set cardsInfo(cards: SettingCardInfo[]) {
    this.cardsSections = [
      {
        label: null,
        cards,
      },
    ];
  }

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cardsSections || changes.cardsInfo) {
      this.cardsSections.forEach((section) => {
        section.cards = section.cards.filter((card) => {
          return card.isVisible == undefined || card.isVisible;
        });
      });
    }
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
