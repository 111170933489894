import { Inventory } from "src/app/core/inventory/inventory/inventory";
import { Store } from "../store/store";
import appDefaults from "src/app/shared/json/app-defaults.json";

const defaults = appDefaults.defaultKioskSettings;

export class KioskSetting {
  id: number;
  colorTheme: string;
  mainScreenBtnFontColor: string;
  categoryBtnBgColor: string;
  categoryBtnFontColor: string;
  openingScreenImageFileName: string;
  urlOpeningScreenImageFileName: string;
  password: string;
  storeId: number;
  inventories: Inventory[];
  allowPayAtCounter: boolean;

  constructor(store?: Store) {
    this.id = 0;
    this.colorTheme = defaults.mainButtonBgColor;
    this.mainScreenBtnFontColor = defaults.mainButtonFontColor;
    this.categoryBtnBgColor = defaults.categoryBgColor;
    this.categoryBtnFontColor = defaults.categoryFontColor;
    this.openingScreenImageFileName = "";
    this.urlOpeningScreenImageFileName = "";
    this.password = "";
    this.storeId = store ? store.id : 0;
    this.inventories = [];
    this.allowPayAtCounter = false;
  }
}
