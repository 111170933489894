<div *ngIf="!_isMobile" class="card">
  <div class="grid">
    <div *ngIf="activeStoreId" class="col-fixed" style="width: 125px">
      <i *ngIf="_channelTitle !== TAKUeCommerce" class="{{ saleChannelClass() }} icon-container"></i>
      <img
        *ngIf="_channelTitle === TAKUeCommerce"
        src="/assets/layout/images/TAKU-eCommerce Square.png"
        alt=" TAKUweb sales"
        class="taku-ecommerce-container"
      />
    </div>
    <div class="col">
      <h2>
        <strong> Get connected to start selling online today </strong>
      </h2>
      <hr />
      <p>
        Easily sell your products online by connecting to {{ _channelTitle }}. Take orders online to reach shoppers
        everywhere and grow your customer base. Simply use
        <button
          pButton
          pRipple
          type="button"
          icon="_"
          style="cursor: initial"
          class="p-button-rounded p-button-icon-only"
        >
          <i class="material-icons">playlist_add</i>
        </button>

        to add a new row. Remember that the connection is 1-way from the POS to the online store so products should be
        created and maintained from the POS.
      </p>
    </div>
  </div>
</div>

<div class="col-12">
  <taku-form-list
    [ngClass]="{ pullDown: true }"
    _model="saleChannel"
    _detailMode="w"
    _title="Sales Channel Setup"
    [_filter]="_formListFilter"
    [_bulkEditorEnabled]="false"
    [_saveFormState]="false"
    [_defaultRowValues]="saleChannelDefaultRow"
    [_disableViewCol]="'isConnected'"
    [_cols]="_formListCols"
    [_hideAddHeaderBtn]="true"
    [_hideAddLineHeaderBtn]="!isSuperAdmin"
    [_scrollHeight]="'full'"
  >
  </taku-form-list>
</div>
