/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit } from "@angular/core";

@Component({
  selector: "taku-form-footer",
  templateUrl: "./form-footer.component.html",
  styleUrls: ["./form-footer.component.css"],
})
export class FormFooterComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  OnCancelClick() {}

  OnSubmitClick() {}
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
