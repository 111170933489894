import { Component, ElementRef, Input, OnInit } from "@angular/core";
import { SaleDoc } from "src/app/core/document/sale-document/sale-doc/sale-doc";
import { StorePoliciesSettings } from "src/app/core/settings/store-settings/store-policies-settings/StorePoliciesSettings";
import { StoreSettingTapeSalePrint } from "src/app/core/settings/store-settings/tape-salereceipt-builder/store-setting-tape-sale-print";

@Component({
  selector: "taku-tender-receipt-tape-preview",
  templateUrl: "./tender-receipt-tape-preview.component.html",
  styleUrls: ["./tender-receipt-tape-preview.component.scss"],
})
export class TenderReceiptTapePreviewComponent implements OnInit {
  @Input("saleDocData") saledoc: SaleDoc;
  @Input("builderSettings") settings: StoreSettingTapeSalePrint;
  @Input() policies: StorePoliciesSettings;

  readonly STYLESHEET_PATH = "/assets/layout/taku/receipt-tape-preview.component.css";

  constructor(public elementRef: ElementRef) {}

  ngOnInit(): void {
    console.log(this.saledoc);
    console.log(this.settings);
    console.log(this.policies);
  }
}
