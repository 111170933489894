<div class="deactivate-kiosk-topWrapper">
  <!-- <div class="p-inputgroup">
    <span class="p-inputgroup-addon"><i class="pi pi-user"></i></span>
    <input type="text" pInputText placeholder="Enter password" [formControl]="passwordFieldControl">
  </div> -->
  <p-password
    [formControl]="passwordFieldControl"
    [toggleMask]="true"
    [feedback]="false"
    (keydown.enter)="onExitKioskModePressed()"
  ></p-password>
  <div class="forgot-pw-container">
    <a href="javascript://" (click)="needAdminAccessInfoPanel.toggle($event)">Forgot password?</a>
    <p-overlayPanel #needAdminAccessInfoPanel [showCloseIcon]="false" appendTo="body" style="max-width: 200px">
      <span>If you have forgotten the password, the administrator access is required to exit kiosk mode</span>
    </p-overlayPanel>
  </div>
  <div class="btns-container mt-3">
    <button
      pButton
      type="button"
      (click)="closeDialog(false)"
      label="Cancel"
      class="p-button-outlined p-button-rounded"
    ></button>
    <button
      pButton
      type="button"
      (click)="onExitKioskModePressed()"
      label="Exit Kiosk Mode"
      class="p-button-rounded"
    ></button>
  </div>
</div>
<div class="deactivate-kiosk-footer">
  <div class="app-version-label">Version {{ appVersion }}</div>
</div>
