/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { CommercialAccount } from "./commercial-account";

export class CommercialAccountHelper {
  constructor(private _account: CommercialAccount) {}

  prefillFromFullName(fullName: string) {
    Object.assign(this._account, {
      name: fullName,
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
