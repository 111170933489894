/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { HttpErrorResponse } from "@angular/common/http";
import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import * as _ from "lodash";
import { ConfirmationService, SelectItem } from "primeng/api";
import { interval, Observable, of, Subject, Subscription } from "rxjs";
import { catchError, debounce, map, switchMap, zip } from "rxjs/operators";
import { CommercialAccount } from "../../core/contact-accounts/commercial-account/commercial-account";
import { CommercialAccountHelper } from "../../core/contact-accounts/commercial-account/commercial-account-helper";
import { PersonalAccount } from "../../core/contact-accounts/personal-account/personal-account";
import { PersonalAccountHelper } from "../../core/contact-accounts/personal-account/personal-account-helper";
import { DBSearchService } from "../../shared/services/db-search.service";
import { TakuAutoCompleteComponent } from "../taku-auto-complete/taku-auto-complete.component";
import { SearchFilterIcons, SearchResultItem } from "./SearchResultItem";
import { AuthService } from "src/app/shared/services/auth.service";
import { InvoiceKeyboardActionsService } from "src/app/core/document/sale-document/sale-invoice-doc-base/invoice-keyboard-actions.service";
import { AccountRelationship, AccountType } from "src/app/core/contact-accounts/account/account";
import { FilterRows } from "src/app/utility/FormDataHelpers";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { BusinessDetail } from "src/app/core/settings/business-settings/business-detail/business-detail";

type EntityInfo = {
  label: string;
  imagePath: string;
  modelName: "personalAccount" | "commercialAccount";
  disabled: boolean;
  visible: boolean;
};

export type AutoCompleteSearchResults = { count: number; rows: SearchResultItem[]; filteredIsSellable?: boolean };

@Component({
  selector: "taku-search-accounts",
  templateUrl: "./taku-search-accounts.component.html",
  styleUrls: ["./taku-search-accounts.component.scss"],
})
export class TakuSearchAccountsComponent implements AfterViewChecked, OnInit, OnChanges, OnDestroy {
  // Initially show the TOP 10 results
  protected static readonly MAX_INITIAL_RESULTS = 10;
  protected static readonly MAX_MORE_RESULTS = 25;

  subsList: Subscription[] = [];
  @Input("placeholder") _placeholder: string;
  @Input() autoClearOnSelection = true;
  @Input() includeHeader = true;
  @Input() includeCreateNewBtns = true;
  @Input() _scrollHeight = "468px";
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() searchFilter: FilterRows<CommercialAccount | PersonalAccount>;
  @Input() extraQueryParams: Record<string, any>;
  @Input() showAnimatePlaceholder = true;
  @Input() accountType?: AccountType = null;
  @Input() accountRelationship?: AccountRelationship[] = null;
  @Output() itemSelected = new EventEmitter<SearchResultItem>();
  @Output() onSearchResults = new EventEmitter<SearchResultItem[]>();
  @Output() onNewItemClicked = new EventEmitter<PersonalAccount | CommercialAccount>();
  @Output() onSelectedItemCleared = new EventEmitter<any>();
  @Output() onUnsellableItemDialogHide = new EventEmitter<any>();

  _confirmationDialogKey: string;
  _searchItemsType = "accounts";

  // access rights flags
  addNewPersonalAccountVisible = false;
  addNewCommercialAccountVisible = false;
  addNewPersonalAccountEnabled = false;
  addNewCommercialAccountEnabled = false;

  @ViewChild(TakuAutoCompleteComponent, { static: true }) autocompleteComponent: TakuAutoCompleteComponent;

  @ViewChild("selectFilterContainer", { static: true }) refFilterHeader: ElementRef;
  @ViewChild("actionLinksContainer", { static: true }) refActionLinks: ElementRef;

  elAutoCompletePanel: HTMLElement;
  elFilterHeader: HTMLElement;
  elActionLinksFooter: HTMLElement;
  // _isHeaderAttached: boolean = false;

  textSearch: string;

  searchResults: SearchResultItem[];
  currentFetchLimit: number = TakuSearchAccountsComponent.MAX_INITIAL_RESULTS;
  showLoadMore = false;
  private searchTextSubject = new Subject<{ autoSelectSingleResult: boolean; searchText: string }>();

  // Button Selector component
  // Define types of filters for items including icon and label for primeng component
  filterTypes: SelectItem<string>[] = [
    { label: "All Types", value: "all" },
    { label: "Personal", value: "people", icon: SearchFilterIcons.PERSON },
    { label: "Companies", value: "company", icon: SearchFilterIcons.COMPANY },
  ];
  selectedFilter: string = this.filterTypes[0].value;

  _newItemsInfo: { [key: string]: EntityInfo } = {};
  // Text to show search returns no results
  _noResultsMsg = "No results found";
  protected _defaultSearchFilter = {};

  constructor(
    protected dataService: DBSearchService,
    protected confirmationService: ConfirmationService,
    protected auth: AuthService,
    protected keyboardActionsService: InvoiceKeyboardActionsService,
    protected appSettingService: AppSettingsStorageService
  ) {}

  ngOnInit(): void {
    // dataService.setFetchLimit(TakuSearchComponent.MAX_INITIAL_RESULTS+1);
    this.addNewPersonalAccountVisible = this.auth.hasVisiblePermit("Accounts_Menu_Personal_Accounts");
    this.addNewPersonalAccountEnabled = this.auth.hasEnablePermit("Accounts_Menu_Personal_Accounts");
    this.addNewCommercialAccountVisible = this.auth.hasVisiblePermit("Accounts_Menu_Commercial_Accounts");
    this.addNewCommercialAccountEnabled = this.auth.hasEnablePermit("Accounts_Menu_Commercial_Accounts");

    this._newItemsInfo = {};

    if (this.accountType === AccountType.personal || this.accountType == null) {
      this._newItemsInfo["people"] = {
        label: "Person",
        imagePath: SearchFilterIcons.PERSON,
        modelName: "personalAccount",
        disabled: !this.addNewPersonalAccountEnabled,
        visible: this.addNewPersonalAccountVisible,
      };
    }
    if (this.accountType === AccountType.commercial || this.accountType == null) {
      this._newItemsInfo["company"] = {
        label: "Company",
        imagePath: SearchFilterIcons.COMPANY,
        modelName: "commercialAccount",
        disabled: !this.addNewCommercialAccountEnabled,
        visible: this.addNewCommercialAccountVisible,
      };
    }

    if (!this.searchFilter) this.searchFilter = this._defaultSearchFilter;

    this._confirmationDialogKey = _.uniqueId("searchComponent-");
    // Combines multiples components into a single interface
    // First get references to the DOM container of elements and components
    this.elFilterHeader = this.refFilterHeader.nativeElement;
    this.elActionLinksFooter = this.refActionLinks.nativeElement;

    this.searchTextSubject
      .pipe(
        // If search is submitted with "Enter" key, don't debounce. Otherwise, debounce input
        debounce((searchReq) => {
          return interval(searchReq.autoSelectSingleResult ? 0 : 300);
        }),
        switchMap((searchReq) => {
          return this.fetchResults(searchReq.searchText).pipe(
            map((res) => ({ res, autoSelectSingleResult: searchReq.autoSelectSingleResult })),
            catchError((err) => {
              if (searchReq.autoSelectSingleResult) {
                // if submitted with "Enter" key, return empty result, so we can show message 'No results found'
                return of({
                  autoSelectSingleResult: searchReq.autoSelectSingleResult,
                  res: { count: 0, rows: [], filteredIsSellable: false },
                });
              }
            })
          );
        })
      )
      .subscribe(
        ({ autoSelectSingleResult, res }) => {
          if (autoSelectSingleResult && !res.rows?.length) {
            this.openNoResultsModal(this.textSearch, res.filteredIsSellable);
          } else if (autoSelectSingleResult && res.rows?.length === 1) {
            this.autoSelectOnSingleResult(res.rows);
          } else {
            this._updateStateWithSearchResults(res);
            this.currentFetchLimit = TakuSearchAccountsComponent.MAX_INITIAL_RESULTS;
          }
        },
        (err) => {
          if (err instanceof HttpErrorResponse && err.status == 404 && err.error.success === false) {
            this.showLoadMore = false;
            this.searchResults = [];
          }
        }
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["searchFilter"]) _.defaults(this.searchFilter, this._defaultSearchFilter);
  }

  openNoResultsModal(seachQuery: string, filteredIsSellable: boolean): void {
    this.autocompleteComponent.scrollHeight = "0px";
    setTimeout(() => {
      this.autocompleteComponent.hide();
      this.autocompleteComponent.scrollHeight = this._scrollHeight;
    }, 1000);
    this.keyboardActionsService._canAnnounceNumKeyPress = false;
    if (filteredIsSellable) {
      this.confirmationService.confirm({
        header: `NOTE`,
        message: `Sorry, this item  (${seachQuery}) cannot be sold.\n`,
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Okay",
        rejectVisible: false,
        accept: () => {
          this.cleanAndFocusSearchInput();
          this.keyboardActionsService._canAnnounceNumKeyPress = true;
          this.onUnsellableItemDialogHide.emit();
        },
      });
    } else {
      this.confirmationService.confirm({
        // isSellable not being filtered means that there is no item initially
        header: `${_.capitalize(this._searchItemsType)} search: No Results Found`,
        message: `We couldn't find any ${this._searchItemsType} matching your search of '${seachQuery}'\n`,
        // acceptLabel: `Add New ${activeNewLink.label}`,
        // rejectLabel: "Close",
        icon: "pi pi-exclamation-triangle",
        key: this._confirmationDialogKey,
        rejectButtonStyleClass: "p-button-link",
        accept: (modelName) => {
          this.newItemClicked(modelName);
          this.clearSearchInputOnly();
          this.keyboardActionsService._canAnnounceNumKeyPress = true;
        },
        reject: () => {
          this.cleanAndFocusSearchInput();
          this.keyboardActionsService._canAnnounceNumKeyPress = true;
        },
      });
    }
  }

  ngAfterViewChecked(): void {
    this.tryToAttachHeaderAndFooter();
  }

  onHeaderTabChanged(newFilter: string) {
    if (this.elAutoCompletePanel) {
      const elAutocompleteItems = this.elAutoCompletePanel.querySelector(".p-autocomplete-items");
      if (elAutocompleteItems.lastChild && elAutocompleteItems.lastChild.textContent == this._noResultsMsg)
        elAutocompleteItems.removeChild(elAutocompleteItems.lastChild);
    }

    this.selectedFilter = newFilter;
    this.search();
  }

  onFieldCleared(e: Event) {
    setTimeout(() => {
      this.autocompleteComponent.suggestionsUpdated = false;
      this.autocompleteComponent.overlayVisible = false;
    }, 0);
    this.cleanAndFocusSearchInput(e);
    this.onSelectedItemCleared.emit();
  }

  clearSearchInputOnly() {
    this.textSearch = "";
    this.searchResults = [];
  }

  focusSearchInput() {
    this.autocompleteComponent.focusInput();
  }

  cleanAndFocusSearchInput(e?: Event) {
    if (e) e.stopPropagation();

    this.clearSearchInputOnly();
    setTimeout(() => {
      this.autocompleteComponent.focusInput();
    }, 0);
  }

  setUpResultsItems(): void {
    if (!this.elAutoCompletePanel) return;

    // search only immediate children
    const elAutocompleteItems = this.elAutoCompletePanel.getElementsByClassName("p-autocomplete-items")[0];
    const oldAutoCompleteItems = this.elActionLinksFooter.getElementsByClassName("p-autocomplete-items")[0];

    if (elAutocompleteItems) {
      if (oldAutoCompleteItems) this.elActionLinksFooter.removeChild(oldAutoCompleteItems);
      this.elActionLinksFooter.insertBefore(elAutocompleteItems, this.elActionLinksFooter.firstChild);
    }
  }

  resultItemSelected(itemSelected: SearchResultItem) {
    this.autocompleteComponent.hide();

    this.itemSelected.emit(itemSelected);
    if (this.autoClearOnSelection) this.cleanAndFocusSearchInput();
  }

  get activeLinks(): EntityInfo[] {
    const filter = this.selectedFilter;
    if (filter === "all") {
      return Object.values(this._newItemsInfo);
    } else {
      return [this._newItemsInfo[filter]];
    }
  }

  checkPressedKey($event: KeyboardEvent): void {
    switch ($event.code) {
      case "Enter":
        if (this.textSearch) {
          // setTimeout needed to handle barcode scanning correctly. Otherwise the autoComplete component emits an overriding search after this search is fired.
          setTimeout(() => {
            this.highlightInput();
            this.searchTextSubject.next({ autoSelectSingleResult: true, searchText: this.textSearch });
          });
        }
        break;
    }
  }

  get _autoCompleteInput(): HTMLInputElement {
    return <HTMLInputElement>this.autocompleteComponent.inputEL.nativeElement;
  }

  private highlightInput() {
    this._autoCompleteInput.focus();
    this._autoCompleteInput.select();
  }

  private autoSelectOnSingleResult(searchResults: SearchResultItem[]) {
    if (searchResults.length !== 1)
      // if we have nothing or more than one results, this feature doesn't apply
      return false;

    this.autocompleteComponent.suggestions = null;
    if (this.autocompleteComponent.overlayVisible) this.autocompleteComponent.hide();

    if (!this.elAutoCompletePanel) {
      const overlaySubscription = this.autocompleteComponent.overlayVisibleChanged.subscribe(() => {
        overlaySubscription.unsubscribe();
        this.autocompleteComponent.overlayVisible = false;
      });
    }
    this.itemSelected.emit(searchResults[0]);
    return true;
  }

  tryToAttachHeaderAndFooter(): boolean {
    // where to put header and footer in autocomplete component
    this.elAutoCompletePanel = this.autocompleteComponent.el.nativeElement.querySelector(".p-autocomplete-panel");
    if (!this.elAutoCompletePanel) return false;

    // this._isHeaderAttached = true;
    // Move subcomponents inside autocomplete's suggestions box
    this.setUpSelectButtonsComponent();
    this.setUpActionLinks();
    // this.autocompleteComponent.hide();
    return true;
  }

  private setUpActionLinks() {
    if (this.elActionLinksFooter.parentNode == this.elAutoCompletePanel) return;

    this.elAutoCompletePanel.appendChild(this.elActionLinksFooter);
    // Stop child component hidding autocomplete suggestions box
    this.elActionLinksFooter.addEventListener("click", (e) => {
      e.stopPropagation();
    });
  }

  private setUpSelectButtonsComponent() {
    if (this.elFilterHeader.parentElement == this.elAutoCompletePanel) return;

    this.elAutoCompletePanel.insertBefore(this.elFilterHeader, this.elAutoCompletePanel.firstChild);
    // Stop child component hidding autocomplete suggestions box
    this.elFilterHeader.addEventListener("click", (e) => {
      e.stopPropagation();
    });
  }

  // Query the service in order to retrieve more search results
  search(e?: { query: string; originalEvent: InputEvent }): void {
    const searchQuery = e ? e.query : this.textSearch;
    this.searchTextSubject.next({ autoSelectSingleResult: false, searchText: searchQuery });
  }

  private _updateStateWithSearchResults(results: { count: number; rows: any[] }) {
    // If more results exists, enable 'Load More' link
    this.showLoadMore = this.currentFetchLimit !== undefined && results.count >= this.currentFetchLimit;
    // if (this.searchResults.length > 0)
    //   this.autocompleteComponent.noResults = false;

    this.searchResults = results.rows;
    setTimeout(() => {
      this.autocompleteComponent.suggestionsUpdated = true;
      this.autocompleteComponent.overlayVisible = true;
    }, 0);
    // if (this.searchResults.length > 0)
    this.onSearchResults.emit(results.rows);
  }

  searchAgain() {
    // If there nothing to search (empty query), do nothing
    if (!this.textSearch) return;

    setTimeout(() => {
      this.autocompleteComponent.suggestionsUpdated = true;
      this.autocompleteComponent.overlayVisible = true;

      this.search();
    }, 0);
  }

  onSuggestionsUpdated() {
    this.setUpResultsItems();

    // Scroll suggestions box all the way to the top
    this.refActionLinks.nativeElement.scrollTo(0, 0);
  }

  private fetchResults(query: string): Observable<AutoCompleteSearchResults> {
    if (this.accountType === AccountType.commercial) {
      this.selectedFilter = "company";
    } else if (this.accountType === AccountType.personal) {
      this.selectedFilter = "people";
    }

    // Depending on type of data, use a different function for fetching
    switch (this.selectedFilter) {
      case "people":
        return this._searchPeople(query);

      case "company":
        return this._searchCompanies(query);

      // case 'product':
      //   return this._searchInventory();

      default:
        return this._searchAll(query);
    }
  }

  private _searchPeople(query: string): Observable<AutoCompleteSearchResults> {
    const fetchLimit = this.currentFetchLimit || TakuSearchAccountsComponent.MAX_MORE_RESULTS;

    const filter = this.searchFilter;

    if (this.accountRelationship != null) {
      filter["account.accountRelationship"] = {
        matchMode: "in",
        value: this.accountRelationship,
      };
    }

    return this.dataService
      .searchPersonalAccount({
        searchValue: query,
        _filter: JSON.stringify(filter),
        _offset: 0,
        _limit: fetchLimit,
        extraQueryParams: this.extraQueryParams,
      })
      .pipe(
        map((searchResults: any) => {
          const items: SearchResultItem[] = [];
          searchResults.rows.forEach((item: PersonalAccount) => {
            items.push(SearchResultItem.build("personalAccount", item));
          });
          return { count: searchResults.count, rows: items };
        })
      );
  }

  private _searchCompanies(query: string): Observable<AutoCompleteSearchResults> {
    const fetchLimit = this.currentFetchLimit || TakuSearchAccountsComponent.MAX_MORE_RESULTS;

    const filter = this.searchFilter;

    if (this.accountRelationship != null) {
      filter["account.accountRelationship"] = {
        matchMode: "in",
        value: this.accountRelationship,
      };
    }

    return this.dataService
      .searchCommecialAccount({
        searchValue: query,
        _filter: JSON.stringify(filter),
        _offset: 0,
        _limit: fetchLimit,
        extraQueryParams: this.extraQueryParams,
      })
      .pipe(
        map((searchResults: any) => {
          const items: SearchResultItem[] = [];
          searchResults.rows.forEach((item: CommercialAccount) => {
            items.push(SearchResultItem.build("commercialAccount", item));
          });
          return { count: searchResults.count, rows: items };
        })
      );
  }

  newItemClicked(modelName: "personalAccount" | "commercialAccount", e?: Event): void {
    if (e) e.preventDefault();
    const defaultData$ = this.prefillDataFromSearchText(modelName);
    defaultData$.subscribe((defaultData) => {
      if (defaultData) {
        this.onNewItemClicked.emit(defaultData);
      }
    });
  }

  protected _searchAll(query: string): Observable<AutoCompleteSearchResults> {
    return this._searchPeople(query).pipe(
      zip(this._searchCompanies(query), (peopleResults: any, companiesResults: any) => {
        let countPeople, countCompanies;
        if (this.currentFetchLimit !== undefined) {
          const halfResultsCount = Math.ceil(this.currentFetchLimit / 2.0);
          if (companiesResults.count >= TakuSearchAccountsComponent.MAX_INITIAL_RESULTS - halfResultsCount)
            countPeople = Math.min(peopleResults.count, halfResultsCount);
          // Try to get half results comprised of people
          else
            countPeople = Math.min(
              peopleResults.count,
              TakuSearchAccountsComponent.MAX_INITIAL_RESULTS - companiesResults.count
            );

          countCompanies = Math.min(
            companiesResults.count,
            TakuSearchAccountsComponent.MAX_INITIAL_RESULTS - countPeople
          ); // Try to get the remaining from companies
        } else {
          countPeople = peopleResults.rows.length;
          countCompanies = companiesResults.rows.length;
        }
        return {
          count: peopleResults.count + companiesResults.count,
          rows: peopleResults.rows.slice(0, countPeople).concat(companiesResults.rows.slice(0, countCompanies)),
        };
      })
    );
  }

  // onAutocompleteHidden($event){
  //   this._isHeaderAttached = false;
  // }

  // loads the rest of results
  loadMore() {
    // this.dataService.setFetchLimit(DataService.INFINITE_LIMIT);
    this.currentFetchLimit = undefined;
    this.search();
    // // Restore default search limit
    // this.dataService.setFetchLimit(TakuSearchComponent.MAX_INITIAL_RESULTS+1);
  }

  prefillDataFromSearchText(modelName: string): Observable<any> {
    let defaultData = null;
    const accFullName = this.textSearch;

    switch (modelName) {
      case "personalAccount":
        if (this.addNewPersonalAccountVisible && this.addNewPersonalAccountEnabled) {
          defaultData = new PersonalAccount();
          if (accFullName) {
            const accHelper = new PersonalAccountHelper(defaultData);
            accHelper.prefillFromFullName(accFullName);
          }
          return of(defaultData);
        }
        break;

      case "commercialAccount":
        if (this.addNewCommercialAccountVisible && this.addNewCommercialAccountEnabled) {
          return this.appSettingService.getBusinessDetails().pipe(
            map((businessDetail: BusinessDetail) => {
              const defaultCurrencyIsoCode = businessDetail.defaultCurrencyIsoCode;
              defaultData = new CommercialAccount(defaultCurrencyIsoCode);
              if (accFullName) {
                const accHelper = new CommercialAccountHelper(defaultData);
                accHelper.prefillFromFullName(accFullName);
              }
              return defaultData;
            })
          );
        }
        break;
    }

    return of(defaultData);
  }

  get isDropdownOpen() {
    return this.autocompleteComponent.overlayVisible;
  }

  getInventoryIcon(_inventory) {
    switch (true) {
      case Boolean(_inventory.parentInventoryId):
        return "photo_size_select_small";

      case _inventory.isVariantParent:
        return "bookmarks";

      case _inventory.account?.accountType?.toLowerCase() === "personal":
        return "person";

      case _inventory.account?.accountType?.toLowerCase() === "commercial":
        return "store";

      default:
        return "bookmark_border";
    }
  }

  getInventoryVariantsStr(_inventory) {
    return _inventory.inventoryVariants
      ?.map((_inventoryVariant) => _inventoryVariant.inventoryOptionsSettingValue?.optionValue)
      .join(",");
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
