/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { map } from "rxjs/operators";
import { Col, RowSelectionType } from "../../../../../form-list/form-list/form-list";
import { GoogleShoppingStoresService } from "./google-shopping-stores.service";
import { Location } from "@angular/common";
import { DBService } from "../../../../../shared/services/db.service";
import { Observable, Subscription } from "rxjs";
import { GMCAccount } from "../gmc-accounts/GMCGoogleAccount";

@Component({
  selector: "taku-google-shopping-stores-list",
  templateUrl: "./google-shopping-stores-list.component.html",
  styleUrls: ["./google-shopping-stores-list.component.scss"],
})
export class GoogleShoppingStoresListComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];

  googleShoppingStoresCols: Col[];
  defaultRowValues: {};
  RowSelectionType = RowSelectionType;
  googleAccount$: Observable<GMCAccount>;

  constructor(
    private googleShoppingStoresService: GoogleShoppingStoresService,
    private route: ActivatedRoute,
    protected location: Location,
    private dbService: DBService
  ) {
    this.googleShoppingStoresCols = this.googleShoppingStoresService.getFormListColumns();
  }

  ngOnInit() {
    this.subsList.push(
      this.route.params.pipe(map((params: Params) => params.accountId)).subscribe((accountId) => {
        this.googleAccount$ = this.dbService.getRow("googleShoppingAccount", accountId);

        this.defaultRowValues = {
          googleShoppingAccountId: {
            matchMode: "equals",
            value: accountId,
          },
        };
      })
    );
  }

  goBack(): void {
    if (this.location) {
      this.location.back();
    }
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
