import { AfterViewInit, Component, Input, OnInit, ViewChild } from "@angular/core";
import { Account, AccountRelationship, AccountType } from "../../contact-accounts/account/account";
import { FormArray, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { InventorySupplier } from "./inventory-supplier";
import { SearchResultItem } from "src/app/taku-ui/taku-search-accounts/SearchResultItem";
import { TakuSearchAccountsComponent } from "src/app/taku-ui/taku-search-accounts/taku-search-accounts.component";
import { DBService } from "src/app/shared/services/db.service";
import { pairwise, startWith } from "rxjs/operators";
import { ConfirmationService } from "primeng/api";
import { Subject } from "rxjs";

@Component({
  selector: "taku-inventory-supplier",
  templateUrl: "./inventory-supplier.component.html",
  styleUrls: ["./inventory-supplier.component.scss"],
})
export class InventorySupplierComponent implements OnInit, AfterViewInit {
  @ViewChild("suppliersSearchBox", { static: true }) supplierSearchComponent: TakuSearchAccountsComponent;
  @Input() inventorySupplierFormGroup: UntypedFormGroup;
  private supplierChangedListenerSubject = new Subject<void>();
  $supplierChangedListener = this.supplierChangedListenerSubject.asObservable();
  AccountType = AccountType;
  AccountRelationship = AccountRelationship;
  supplierCurrencyIsoCode: string;

  vendorsSearchFilter = {
    "account.accountRelationship": {
      matchMode: "in",
      value: [AccountRelationship.supplier, AccountRelationship.both],
    },
  };

  constructor(private dbService: DBService, protected confirmationService: ConfirmationService) {}

  ngOnInit(): void {
    this.inventorySupplierFormGroup.controls.accountId.valueChanges
      .pipe(startWith(this.inventorySupplierFormGroup.controls.accountId.value), pairwise())
      .subscribe(([prev, next]) => {
        this.confirmSupplierChange(prev, next);
      });
  }

  ngAfterViewInit(): void {
    this._afterComponentLoadedAsyncInit();
  }

  private _afterComponentLoadedAsyncInit() {
    void Promise.resolve().then(() => {
      this.initSupplierSearchField();
    });
  }

  private async initSupplierSearchField() {
    if (this.inventorySupplierFormGroup.controls.account?.value?.accountName) {
      this.supplierSearchComponent.textSearch = this.inventorySupplierFormGroup.controls.account.value.accountName;
    }
  }

  private async confirmSupplierChange(prevAccountId: number, nextAccountId: number): Promise<void> {
    // if we go from empty to an actual accountId, then we already did the reset for the empty and don't need to do it again.
    // if (!prevAccountId && nextAccountId) {
    //   this.inventorySupplierFormGroup.controls.accountId.markAsDirty();
    //   this.supplierChangedListenerSubject.next();
    //   return;
    // }

    this.confirmationService.confirm({
      header: "Confirmation",
      message:
        "Changing suppliers will clear all of the Purchasing Information in the table. <br> <br>Do you still want to proceed?",
      acceptLabel: "Yes",
      rejectLabel: "No",
      rejectVisible: true,
      acceptVisible: true,
      rejectButtonStyleClass: "p-button-link",
      accept: () => {
        this.inventorySupplierFormGroup.controls.accountId.markAsDirty();
        const inventorySupplierProductDetails = this.inventorySupplierFormGroup.get(
          "inventorySupplierProductDetails"
        ) as FormArray;

        inventorySupplierProductDetails.controls.forEach((element) => {
          element
            .get("supplierCostCurrencyIsoCode")
            .setValue(
              this.inventorySupplierFormGroup?.get("commercialAccount")?.value?.supplierCurrencyIsoCode ||
                this.supplierCurrencyIsoCode
            );
        });

        this.supplierChangedListenerSubject.next();
      },
      reject: async () => {
        if (nextAccountId != prevAccountId) {
          this.inventorySupplierFormGroup.controls.accountId.setValue(prevAccountId, { emitEvent: false });
          const account: Account | any = await this.dbService.getRow("account", prevAccountId).toPromise();

          this.inventorySupplierFormGroup.controls.account.setValue(account);

          this.supplierSearchComponent.textSearch = account.accountName;
        }
      },
    });
  }

  onSearchResAccount(accountResult: SearchResultItem): void {
    const accountId = accountResult.ID;
    this.inventorySupplierFormGroup.controls.accountId.setValue(accountId);

    const account = accountResult.data?.account;
    if (account) {
      // NOTE: The accountResult: SearchResultItem passed in does not have the accountName field on the account object
      // but the headline field happens to be the accountName every time (personal or commercial), so we'll use that.
      account["accountName"] = accountResult.headline;
    }

    this.supplierCurrencyIsoCode = accountResult.data?.supplierCurrencyIsoCode;
    this.inventorySupplierFormGroup.controls.account.setValue(account || null);
    this.inventorySupplierFormGroup.controls.commercialAccount.setValue(accountResult.data || null);
  }

  clearAccountField(): void {
    this.inventorySupplierFormGroup.controls.accountId.setValue(null);
    this.inventorySupplierFormGroup.controls.account.setValue(null);
    this.inventorySupplierFormGroup.controls.commercialAccount.setValue(null);
  }

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    const inventorySupplier = fb.group(new InventorySupplier());
    return inventorySupplier;
  }

  static setArray(fb: UntypedFormBuilder, inventorySuppliers: InventorySupplier[]): UntypedFormArray {
    const formArray = fb.array([]);
    inventorySuppliers.forEach((inventorySupplier) => {
      const temp = fb.group(inventorySupplier);

      const ispdFormArray = fb.array([]);
      inventorySupplier.inventorySupplierProductDetails.forEach((inventorySupplierProductDetail) => {
        ispdFormArray.push(fb.group(inventorySupplierProductDetail));
      });

      temp.controls.inventorySupplierProductDetails = ispdFormArray;

      formArray.push(temp);
    });
    return formArray;
  }
}
