<ng-template [ngIf]="readonly">
  <taku-input
    [formGroup]="formGroup"
    [fieldName]="fieldName"
    [fieldCaption]="fieldCaption"
    [readonly]="readonly"
  ></taku-input>
</ng-template>

<ng-template [ngIf]="!readonly">
  <div [formGroup]="formGroup" [ngClass]="{ 'disabled-control': disabled }">
    <div class="col-12 p-0 p-float-label">
      <p-calendar
        [formControlName]="fieldName"
        (onClose)="onCalendarClosed()"
        showTime="true"
        hourFormat="24"
        [showIcon]="true"
        icon="pi pi-clock"
        dataType="string"
        timeOnly="true"
        (click)="onOverlayOpened()"
      ></p-calendar>
      <label for="{{ fieldName }}" [innerHTML]="fullFieldCaption"></label>
      <i
        class="material-icons clear-field"
        (click)="clear()"
        *ngIf="formGroup.controls[fieldName].value && !formGroup.controls[fieldName].disabled"
        >close</i
      >

      <form-validation-messages
        [errors]="formGroup.controls[fieldName].errors"
        [fieldName]="fieldName"
      ></form-validation-messages>
    </div>
  </div>
</ng-template>
