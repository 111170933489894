/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { DBService } from "../../../shared/services/db.service";
import { BusinessFormatsSettings } from "../../settings/business-settings/business-formats-settings/BusinessFormatsSettings";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { AccountType } from "./account";
import { SelectItem } from "primeng/api";
import { AcctGroupOptions } from "../../settings/store-settings/account-group/account-group";
import { SaleDocType } from "../../document/doc/doc";

export type AccountStats = {
  docType: SaleDocType;
  TotalSales: number;
  InvoiceCount: number;
  LastTransaction: Date;
  currencyIsoCode: string;
};

export type AccountCreditSummary = {
  /** This is a number formatted as a string to 4 decimals places */
  accountCredit: string | number;
  currencyIsoCode: string;
  tenderTypeId: number;
  zoneId: number;
};

@Injectable({
  providedIn: "root",
})
export class AccountService extends DBService {
  lookupAccountGroup(accountType: AccountType): Observable<SelectItem[]> {
    let relatedAcctGroupOption;
    switch (accountType) {
      case AccountType.commercial:
        relatedAcctGroupOption = AcctGroupOptions.Commercial;
        break;

      case AccountType.personal:
        relatedAcctGroupOption = AcctGroupOptions.Personal;
        break;
    }

    const accountGroupsFilter = {
      option: { matchMode: "in", value: [AcctGroupOptions.Both, relatedAcctGroupOption] },
    };
    return this.lookupMultiSelectOptions("acctGroup", "name", {
      lookupFilter: JSON.stringify(accountGroupsFilter),
      enableFieldName: "isEnabled",
    });
  }

  getStatistics(accountCode: string): Observable<AccountStats[]> {
    const endpoint = this.webUrl + "accountStatics/" + accountCode;
    return this._getRequest<AccountStats[]>(endpoint);
  }

  getCreditSummary(accountId: number, tenderTypeId?: number): Observable<AccountCreditSummary[]> {
    let endpoint = `${this.webUrl}account/${accountId}/creditSummary`;
    if (tenderTypeId) {
      endpoint += `/${tenderTypeId}`;
    }
    return this._getRequest<AccountCreditSummary[]>(endpoint);
  }

  getCreditHistory(accountId: number): Observable<AccountCreditSummary[]> {
    const endpoint = `${this.webUrl}account/${accountId}/creditHistory`;
    return this._getRequest<AccountCreditSummary[]>(endpoint);
  }

  get_business_formats(): Observable<BusinessFormatsSettings> {
    return this.getRow("businessFormat", 1);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
