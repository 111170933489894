<div [formGroup]="_myForm" class="form-container" style="height: 100%">
  <div class="main-container person-details" style="height: 100%">
    <p-panel class="main-panel" styleClass="panel-nopad">
      <ng-template pTemplate="header">
        <h2>Personal Details</h2>
        <button
          *ngIf="enableConsent"
          pButton
          label="Customer Consent"
          class="taku-button-small"
          type="button"
          [ngClass]="isConsentApproved ? 'p-button-success' : 'p-button-secondary'"
          (click)="openCustomerConsentDialog()"
        >
          <span class="material-icons md-18"> verified_user </span>
        </button>
      </ng-template>
      <p-tabView [(activeIndex)]="_mainTabActiveIndex">
        <p-tabPanel
          header="General"
          leftIcon="ui-icon-person"
          *ngIf="accountAccessRights.isGeneralTabVisible"
          [disabled]="!accountAccessRights.isGeneralTabEnabled"
        >
          <div class="grid">
            <!-- <div class="xl:col-12 lg:col-12 col-12"> -->
            <div class="col-12">
              <h5>Full Name</h5>
            </div>

            <div class="xl:col-4 lg:col-4 col-12 drop-container">
              <taku-dropdown
                [formGroup]="_myForm"
                [options]="enumTitleType"
                [fieldName]="'title'"
                [fieldCaption]="'Title'"
              >
              </taku-dropdown>
            </div>
            <div class="xl:col-8 lg:col-8 col-12">
              <taku-input id="first_name" [formGroup]="_myForm" [fieldName]="'firstName'" [fieldCaption]="'First Name'">
              </taku-input>
            </div>

            <div class="xl:col-4 lg:col-4 col-12">
              <taku-input [formGroup]="_myForm" [fieldName]="'middleName'" [fieldCaption]="'M.I.'"> </taku-input>
            </div>
            <div class="xl:col-8 lg:col-8 col-12">
              <taku-input [formGroup]="_myForm" [fieldName]="'lastName'" [fieldCaption]="'Last Name'"> </taku-input>
            </div>

            <!-- </div> -->

            <div class="single-picture-uploader xl:col-12 lg:col-12 col-12">
              <div class="grid">
                <div class="col-12">
                  <h5>Image</h5>
                </div>

                <div class="col-6">
                  <p-fileUpload
                    #fileUploader
                    name="image"
                    auto="true"
                    accept="image/*"
                    customUpload="true"
                    (uploadHandler)="myUploader($event)"
                  ></p-fileUpload>
                </div>
                <div class="col-6" style="margin-top: -2em">
                  <div *ngIf="_myForm.value.urlImageFileName && !imageUrlCtrl.dirty">
                    <i class="material-icons clear-field" (click)="clearImage()">close</i>
                    <div class="col-12 main-thumbnail-container">
                      <img [attr.src]="fileNameImageUrl" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <p-fieldset class="col-12 person-phoneNumbers taku-listable-container" formArrayName="personPhones">
              <ng-template pTemplate="header">
                <h5>Personal Phone Numbers</h5>
              </ng-template>

              <div class="add-button-container">
                <a class="add-button" (click)="addPersonPhone()">
                  <label>Add New</label>
                  <i class="material-icons">add_circle_outline</i>
                </a>
              </div>

              <div
                class="col-12 taku-listable-controlset"
                *ngFor="let personPhone of _myForm.controls['personPhones']['controls']; let i = index"
              >
                <div class="col-11">
                  <taku-person-phone [formGroup]="_myForm.get('personPhones')['controls'][i]"> </taku-person-phone>
                </div>
                <div class="col-1 remove-button-container">
                  <taku-action-button
                    [action]="ACTION_TYPE.REMOVE"
                    (clickAction)="removePersonPhone(i)"
                  ></taku-action-button>
                </div>
              </div>
            </p-fieldset>

            <p-fieldset class="col-12 taku-listable-container" formArrayName="personEmails">
              <ng-template pTemplate="header">
                <h5>Personal Email Addresses</h5>
              </ng-template>

              <div class="add-button-container">
                <a class="add-button" (click)="addPersonEmail()">
                  <label>Add New</label>
                  <i class="material-icons">add_circle_outline</i>
                </a>
              </div>

              <div
                class="col-12 taku-listable-controlset"
                *ngFor="let personEmail of _myForm.get('personEmails')['controls']; let i = index"
              >
                <div class="col-11">
                  <taku-person-email [formGroup]="_myForm.get('personEmails')['controls'][i]"> </taku-person-email>
                </div>

                <div class="col-1 remove-button-container">
                  <taku-action-button
                    [action]="ACTION_TYPE.REMOVE"
                    (clickAction)="removePersonEmail(i)"
                  ></taku-action-button>
                </div>
              </div>
            </p-fieldset>

            <p-fieldset class="col-12 additionalFields-wrapper taku-listable-container taku-expandable-block">
              <ng-template pTemplate="header">
                <h5>Additional (Optional)</h5>
              </ng-template>

              <div class="add-button-container">
                <a class="add-button" (click)="toggleAdditionalFieldsVisibility()">
                  <label>{{ additionalFieldsVisibility ? "Hide" : "Show" }}</label>
                  <i class="material-icons">{{
                    additionalFieldsVisibility ? "remove_circle_outline" : "add_circle_outline"
                  }}</i>
                </a>
              </div>

              <div class="grid expansion-fields" [ngClass]="{ 'container-hidden': !additionalFieldsVisibility }">
                <p-fieldset class="col-12 taku-listable-container" formArrayName="personInstantMsgs">
                  <ng-template pTemplate="header">
                    <h5>Personal Instant Messengers</h5>
                  </ng-template>

                  <div class="add-button-container">
                    <a class="add-button" (click)="addPersonInstantMsg()">
                      <label>Add New</label>
                      <i class="material-icons">add_circle_outline</i>
                    </a>
                  </div>
                  <div
                    class="col-12 taku-listable-controlset"
                    *ngFor="let personInstantMsg of instantMsgsCtrlArray['controls']; let i = index"
                  >
                    <div class="col-11">
                      <taku-person-instant-msg [formGroup]="instantMsgsCtrlArray.controls[i]">
                      </taku-person-instant-msg>
                    </div>
                    <div class="col-1 remove-button-container">
                      <taku-action-button
                        [action]="ACTION_TYPE.REMOVE"
                        (clickAction)="removePersonInstantMsg(i)"
                      ></taku-action-button>
                    </div>
                  </div>
                </p-fieldset>

                <p-fieldset class="col-12 taku-listable-container" formArrayName="personSocialProfiles">
                  <ng-template pTemplate="header">
                    <h5>Social Media</h5>
                  </ng-template>

                  <div class="add-button-container">
                    <a class="add-button" (click)="addPersonSocialProfile()">
                      <label>Add New</label>
                      <i class="material-icons">add_circle_outline</i>
                    </a>
                  </div>

                  <div
                    class="col-12 taku-listable-controlset"
                    *ngFor="let personSocialProfile of socialProfilesCtrlArray['controls']; let i = index"
                  >
                    <div class="col-11">
                      <taku-person-social-profile [formGroup]="socialProfilesCtrlArray.controls[i]">
                      </taku-person-social-profile>
                    </div>
                    <div class="col-1 remove-button-container">
                      <taku-action-button
                        [action]="ACTION_TYPE.REMOVE"
                        (clickAction)="removePersonSocialProfile(i)"
                      ></taku-action-button>
                    </div>
                  </div>
                </p-fieldset>

                <div class="col-12 xl:col-6">
                  <taku-input [formGroup]="_myForm" fieldName="jobTitle" fieldCaption="Job Title"> </taku-input>
                </div>
                <div class="col-12 xl:col-3 lg:col-6 drop-container">
                  <taku-dropdown
                    [formGroup]="_myForm"
                    [options]="enumGenderType"
                    fieldName="gender"
                    fieldCaption="Gender"
                    appendTo="body"
                  ></taku-dropdown>
                </div>

                <div class="col-12 xl:col-3 lg:col-6">
                  <taku-calendar-date
                    [formGroup]="_myForm"
                    [maxDate]="todayDate"
                    fieldName="birthDate"
                    fieldCaption="Birthday"
                    appendTo="body"
                  >
                  </taku-calendar-date>
                </div>
              </div>
            </p-fieldset>
          </div>
        </p-tabPanel>

        <p-tabPanel
          header="Address"
          leftIcon="ui-icon-place"
          class="tabview-nopad"
          *ngIf="accountAccessRights.isAddressTabVisible"
          [disabled]="!accountAccessRights.isAddressTabEnabled"
        >
          <div class="col-12 address-details">
            <div formArrayName="personAddresses">
              <div class="col-12 p-fluid">
                <p-selectButton
                  [options]="_addressTypes"
                  [(ngModel)]="_addressMgr.activeAddressFilter"
                  [ngModelOptions]="{ standalone: true }"
                ></p-selectButton>
              </div>

              <div class="col-12 address-tabview-container">
                <div class="grid address-action-btns justify-content-end">
                  <div class="col-6 lg:col-8 xl:col-5 add-button-container">
                    <a
                      class="add-button"
                      (click)="clonePersonalAddress()"
                      [ngClass]="{ 'btn-disabled': !_addressMgr.filteredAddresses.length }"
                    >
                      <label>Clone</label>
                      <i class="material-icons">content_copy</i>
                    </a>

                    <a class="add-button" (click)="addPersonAddress()">
                      <label>Add New</label>
                      <i class="material-icons">add_circle</i>
                    </a>
                  </div>
                </div>
              </div>

              <div class="col-12 address-tabview-container">
                <p-tabView
                  [scrollable]="true"
                  [controlClose]="true"
                  (onClose)="removePersonAddress($event)"
                  (onChange)="changePersonAddress($event)"
                >
                  <p-tabPanel
                    *ngFor="let personAddress of _addressMgr.filteredAddresses; let i = index"
                    [selected]="i === _addressTabIndex"
                    [closable]="true"
                    [headerStyle]="{ 'border-right': 'solid 1px lightgrey' }"
                    header="{{ i + 1 }}: {{ personAddress.get('address.addressLocationType').value }}"
                  >
                    <div class="grid col-12">
                      <taku-person-address [formGroup]="personAddress"></taku-person-address>
                    </div>
                  </p-tabPanel>
                </p-tabView>
              </div>
            </div>
          </div>
        </p-tabPanel>

        <p-tabPanel *ngIf="formCtrlAccount?.get('accountCreditCards')" [disabled]="!takuPayAccountEnabled">
          <ng-template pTemplate="header">
            <span>Credit Cards</span>
          </ng-template>
          <taku-account-credit-card
            [formGroup]="formCtrlAccount"
            (takuPayAccountEnabled)="getTakuPayAccountEnabled()"
          ></taku-account-credit-card>
        </p-tabPanel>
      </p-tabView>
    </p-panel>
  </div>
</div>
