import { Injectable } from '@angular/core';
import { CashoutPaymentBreakdown, DBCashoutService } from './db-cashout.service';
import { AppSettingsStorageService } from 'src/app/shared/app-settings-storage.service';
import { CashCalculationType, StoreSettingCashout } from '../settings/store-settings/cashout-settings/CashoutSettings';
import { Cashout, CashoutClosingFloat, CashoutSaveMethod } from './cashout/cashout';
import { MonetaryHelpers } from 'src/app/utility/MonetaryHelpers';

/**
 * This is not a state service, it is not injected into NgModule
 * don't put "providedIn": "root" here (which would make it a global singleton)
 * To use this, put it as part of the @Component() "providers" array in whatever component needs it
 * eg @Component({"providers": ServiceNameService})
 * This classe's purpose is essentially to provide a centralized place for common actions to be defined, and it's methods reused by any component 
 */
@Injectable()
export class CashoutClosingService {

    constructor(
        private dbCashoutService: DBCashoutService,
        private appSettings: AppSettingsStorageService,
    ) { }

    public async getSystemTotalsByTenderAndCashoutId(cashoutId: number): Promise<CashoutPaymentBreakdown[]> {
        return await this.dbCashoutService.getPaymentBreakdown(cashoutId).toPromise();
    }

    public async getCashSystemTotalByTenderAndCashoutId(cashoutPaymentBreakdown: CashoutPaymentBreakdown[]): Promise<number> {

        for (let breakdown of cashoutPaymentBreakdown) {
            if(breakdown.tenderDescription === "Cash"){
                return parseFloat(breakdown.systemTotal);
            }
        }

        return 0.00;
    }

    public async shouldPrePopulateActualCash(): Promise<boolean> {
        const cashoutSettings = await this.getCashoutSettings();
        return cashoutSettings.showSystemValue && cashoutSettings.cashCalculationType === CashCalculationType.by_actual_cash;
    }

    public getTotalClosingActualCash(cashout: Cashout): number {
        switch (cashout.closeSaveMethod) {
            case CashoutSaveMethod.TOTAL:
                return parseFloat((cashout.closeTotalRemainingCash?.toString()?.replace(/,/g, "") || "0") + "");

            case CashoutSaveMethod.DETAIL:
                return this.getTotalClosingActualCashDetails(cashout);
        }
    }

    public getTotalClosingRemainingCash(cashout: Cashout): number {
        switch (cashout.closeSaveMethod) {
            case CashoutSaveMethod.TOTAL:
                return parseFloat((cashout.closeTotalRemainingCash?.toString()?.replace(/,/g, "") || "0") + "");

            case CashoutSaveMethod.DETAIL:
                return this.getTotalClosingRemainingCashDetails(cashout);
        }
    }

    public getTotalOpeningFloat(cashout: Cashout): number {
        switch (cashout.openSaveMethod) {
          case CashoutSaveMethod.TOTAL:
            return parseFloat(cashout.openTotalNextFloat + "");
    
          case CashoutSaveMethod.DETAIL:
            return cashout.cashoutOpeningFloats.reduce((acc, openingFloat) => {
              return (
                acc +
                  MonetaryHelpers.roundToDecimalPlaces(
                    parseFloat((openingFloat.amount || "0") + "") * parseFloat((openingFloat.count || "0") + ""),
                    4
                  ) || 0
              );
            }, 0);
        }
    }

    public getTotalClosingFloatDetails(cashout: Cashout) {
        return cashout.cashoutClosingFloats.reduce((sum, closeFloat) => {
          return (
            sum +
            (MonetaryHelpers.roundToDecimalPlaces(
              parseFloat((closeFloat.amount || "0") + "") * parseFloat((closeFloat.nextFloatCount || "0") + ""),
              4
            ) || 0)
          );
        }, 0);
    }

    private async getCashoutSettings(): Promise<StoreSettingCashout>{
        return await this.appSettings.getStoreSettings("storeSettingCashout").toPromise();
    }

    private getTotalClosingRemainingCashDetails(cashout: Cashout) {
        return cashout.cashoutClosingFloats.reduce((acc, closeFloat) => {
          return (
            acc +
            (MonetaryHelpers.roundToDecimalPlaces(
              parseFloat((closeFloat.amount || "0") + "") * parseFloat((closeFloat.remainingFloatCount || "0") + ""),
              4
            ) || 0)
          );
        }, 0);
    }

    private getTotalClosingActualCashDetails(cashout: Cashout) {
        return cashout.cashoutClosingFloats.reduce((acc, closeFloat) => {
          return (
            acc +
            (MonetaryHelpers.roundToDecimalPlaces(
              parseFloat((closeFloat.amount || "0") + "") * parseFloat((closeFloat.remainingFloatCount || "0") + ""),
              4
            ) || 0)
          );
        }, 0);
    }
}