<form [formGroup]="form" class="p-dynamic-dialog">
  <div class="p-dialog-content">
    <div class="flex flex-wrap gap-4">
      <img [src]="image" />
      <div class="voucher-inputs">
        <h3>{{ inventory.description1 }}</h3>
        <div class="flex align-items-center mb-4">
          <taku-input
            [formGroup]="form"
            fieldName="uniqueCode"
            fieldCaption="Unique Code"
            autocomplete="off"
          ></taku-input>
          <button
            pButton
            pRipple
            class="p-button-rounded p-button-secondary p-button-icon-only ml-2 flex-none"
            icon="pi pi-info-circle"
            (click)="tip.toggle($event)"
          ></button>

          <p-overlayPanel #tip appendTo="body">
            <p>
              A unique code is automatically generated following the format specified in
              <a [routerLink]="'/settings/business-settings/business-formats'">Business Formats</a>. This can also be
              overriden with a different unique code.
            </p>
          </p-overlayPanel>
        </div>
        <taku-money
          [formGroup]="form"
          fieldName="value"
          fieldCaption="Price"
          [currencyIsReadonly]="true"
          [currencyCode]="currencyIsoCode"
        ></taku-money>
      </div>
    </div>
    <p class="mt-4" [innerHTML]="inventory.longDescription"></p>
  </div>
  <div class="p-dialog-footer">
    <button
      autofocus
      pButton
      type="button"
      class="p-button-outlined"
      label="Cancel"
      (click)="dialogRef.close()"
    ></button>
    <button
      pButton
      type="button"
      label="Add to cart"
      class="w-8rem"
      (click)="closeDialog()"
      [loading]="validatingUniqueCode"
      [disabled]="form.invalid || form.controls.value.invalid"
    ></button>
  </div>
</form>
