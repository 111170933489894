/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { PersonalAccount } from "../../core/contact-accounts/personal-account/personal-account";
import { Inventory } from "../../core/inventory/inventory/inventory";
import { CommercialAccount } from "../../core/contact-accounts/commercial-account/commercial-account";
import * as _ from "lodash";

export enum SearchFilterIcons {
  PERSON = "person",
  COMPANY = "business",
  INVENTORY = "local_offer",
}

type ResultTypes = Inventory | PersonalAccount | CommercialAccount;

export class SearchResultItem<T extends ResultTypes = any> {
  constructor(
    private _headline: string,
    private _description: string,
    private _image: string,
    private _model: string,
    private _id: number,
    private _data: T
  ) {}

  get ID(): number {
    return this._id;
  }

  get modelName(): string {
    return this._model;
  }

  get headline(): string {
    return this._headline;
  }

  get description(): string {
    return this._description;
  }

  get image(): string {
    return this._image;
  }

  get data(): T {
    return this._data;
  }

  toString(): string {
    return this.headline;
  }

  // Factory method for creating instance based on type
  public static build<T extends ResultTypes = any>(modelName: string, data: T): SearchResultItem<T> {
    const fieldSeparator = "<span class='inline-sep'> | </span>";
    let titleLine = "";
    let descLine = "";
    let icon = "";
    let ID = 0;
    switch (modelName) {
      case "inventory": {
        const tDataInv = data as Inventory;
        titleLine = tDataInv.description1;
        descLine = [
          `<span class='inv-sku'>SKU: ${tDataInv.sku}</span>`,
          `<span class='inv-price'>Price: ${tDataInv.standardPriceCurrencyIsoCode} ${tDataInv.standardPrice}</span>`,
        ].join(" ");
        icon = SearchFilterIcons.INVENTORY;
        ID = tDataInv.id;
        break;
      }
      case "personalAccount": {
        const tDataPerson = data as PersonalAccount;
        const personTitle = tDataPerson.person.jobTitle;
        const personEmail =
          tDataPerson.person.personEmails.length > 0 ? tDataPerson.person.personEmails[0].email : undefined;
        const personPhone =
          tDataPerson.person.personPhones.length > 0 ? tDataPerson.person.personPhones[0].tel : undefined;

        titleLine = [tDataPerson.person.firstName, tDataPerson.person.lastName].join(" ");
        descLine = [personTitle, personPhone, personEmail].filter(Boolean).join(fieldSeparator);
        icon = SearchFilterIcons.PERSON;
        if (tDataPerson.account) ID = tDataPerson.account.id;
        break;
      }
      case "commercialAccount": {
        const tDataCompany = data as CommercialAccount;
        const companyWebsite = tDataCompany.url;
        const companyPhone =
          tDataCompany.commercialAccountPhones.length > 0 ? tDataCompany.commercialAccountPhones[0].tel : undefined;
        const companyEmail =
          tDataCompany.commercialAccountEmails.length > 0 ? tDataCompany.commercialAccountEmails[0].email : undefined;

        titleLine = tDataCompany.name;
        descLine = [companyWebsite, companyPhone, companyEmail].filter(Boolean).join(fieldSeparator);
        icon = SearchFilterIcons.COMPANY;
        if (tDataCompany.account) ID = tDataCompany.account.id;
        break;
      }
      default:
        throw new Error(`Type: ${modelName} is not known`);
    }

    return new SearchResultItem(titleLine, descLine, icon, modelName, ID, data);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
