import { Injectable } from "@angular/core";
import { LocalDataService } from "src/app/shared/services/local-data.service";
import * as _ from "lodash";
@Injectable()
export class InventoryVariantSettingLocalDataService extends LocalDataService {
  readonly dataKey = "id";

  // constructor(private appSettingsService: AppSettingsStorageService,
  //   @Inject(LOCALE_ID) private localeId: string) {
  //   super();
  // }

  initialize(data: any[], _model: string) {
    data.forEach((row) => {
      row["id"] = row["id"] || _.uniqueId("-");
    });
    this._allLocalData[_model] = data;
  }
  addDataKey(object) {}
}
