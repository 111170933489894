/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { SelectItem } from "primeng/api";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { DBService } from "src/app/shared/services/db.service";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class RevisitSaleService extends DBService {
  constructor(
    protected http: HttpClient,
    protected authService: AuthService,
    private appSettings: AppSettingsStorageService
  ) {
    super(http, authService);
  }

  lookup_stores(): Observable<SelectItem[]> {
    //get all active stores that are part of an active zone
    const activeFilter = {
      isActive: { matchMode: "equals", value: true },
      "zone.isActive": { matchMode: "equals", value: true },
    };
    return this.lookupSelectOptions("store", "storeName", {
      lookupFilter: JSON.stringify(activeFilter),
      enableFieldName: "isActive",
    }).pipe(
      map((storeItems: SelectItem[]) => {
        return this.addDefaultValueToLookup(storeItems, "All Stores");
      })
    );
  }

  addDefaultValueToLookup(items: SelectItem[], defaultMessage: string) {
    items.unshift({
      label: defaultMessage,
      value: 0,
    });

    if (items.length >= 2 && items[1].label == "") {
      //if the second item is the default empty option. We want the empty option to be first.
      //so we swap the first and second items.
      const temp = items[1];
      items[1] = items[0];
      items[0] = temp;
    }
    return items;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
