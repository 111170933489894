<ng-template [ngIf]="true" [formGroup]="_myForm">
  <td class="col-currency">
    <span class="p-column-title">Currency:</span>
    <span>{{ _myForm.get("amount").value | currency : currencyISOCode : "symbol-narrow" : "1.2-3" }}</span>
  </td>

  <!-- Remaining Cash Column -->
  <td class="col-remainingcash col-field-count">
    <span class="p-column-title">Remaining Qty:</span>
    <taku-input
      [maxLength]="fieldCountMaxLength"
      keyFilter="pint"
      [tabIndex]="rowNumber * 2 + 1"
      [formGroup]="_myForm"
      keyFilter="int"
      fieldName="remainingFloatCount"
      fieldCaption="Qty"
      [ngClass]="{ 'text-right': isDesktopDisplayRange() }"
      [autoSelect]="true"
    >
    </taku-input>
  </td>

  <td class="col-remainingcash col-money">
    <span class="p-column-title">Cash for Deposit:</span>
    <span>{{
      _myForm.get("amount").value * _myForm.get("remainingFloatCount").value
        | currency : currencyISOCode : "symbol-narrow"
    }}</span>
  </td>

  <!-- Next Float Column -->
  <td class="col-nextfloat col-field-count">
    <span class="p-column-title">Next Float Qty:</span>
    <taku-input
      [maxLength]="fieldCountMaxLength"
      keyFilter="pint"
      [tabIndex]="rowNumber * 2"
      [formGroup]="_myForm"
      keyFilter="int"
      fieldName="nextFloatCount"
      fieldCaption="Qty"
      [ngClass]="{ 'text-right': isDesktopDisplayRange() }"
      [autoSelect]="true"
    >
    </taku-input>
  </td>

  <td class="col-nextfloat col-money">
    <span class="p-column-title">Next Float Cash:</span>
    <span>{{
      _myForm.get("amount").value * _myForm.get("nextFloatCount").value | currency : currencyISOCode : "symbol-narrow"
    }}</span>
  </td>

  <!-- Actual Cash Column -->
  <td class="colVal-cashresult col-field-count">
    <span class="p-column-title">Actual Qty:</span>
    <span class="actualCashCount-wrapper">
      <label class="field-caption" for="">Qty</label>
      <div class="actual-cash-count">
        <span>{{ actualCashCount }}</span>
      </div>
    </span>
  </td>

  <td class="colVal-cashresult col-money">
    <span class="p-column-title">Actual Cash:</span>
    <span>{{ _myForm.get("amount").value * actualCashCount | currency : currencyISOCode : "symbol-narrow" }}</span>
  </td>
</ng-template>
