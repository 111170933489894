/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { LocalDataService } from "src/app/shared/services/local-data.service";
import { SaleAnalysisReport } from "./sale-analysis-report";
import * as moment from "moment";
@Injectable({
  providedIn: "root",
})
export class SaleAnalysisReportLocalDataService extends LocalDataService {
  // constructor(private appSettingsService: AppSettingsStorageService,
  //   @Inject(LOCALE_ID) private localeId: string) {
  //   super();
  // }

  afterFetch(object, model: string) {
    // const currency = this.appSettingsService.getZone().defaultCurrencyIsoCode;
    // const currencyFormatter = new CurrencyPipe(this.localeId);
    // Object.keys(object)
    //   .filter(key => !Object.keys(new SaleAnalysisReport()).includes(key))
    //   .forEach(key => {
    //     object[key] = currencyFormatter.transform(object[key], currency, 'symbol-narrow');
    //   });
    // object.totalAmountCalculated = currencyFormatter.transform(object.totalAmountCalculated, currency, 'symbol-narrow');
  }

  getSaleAnalysisReport(
    storeId: number,
    stationId: number,
    dateFrom: Date | string,
    dateTo: Date | string,
    accountId = 0,
    userId = 0
  ) {
    const timeTo = moment(dateTo).format("HH:mm:ss");
    const timeFrom = moment(dateFrom).format("HH:mm:ss");
    dateTo = moment(dateTo).format("YYYY-MM-DD");
    dateFrom = moment(dateFrom).format("YYYY-MM-DD");
    const queryParams = {
      dateFrom,
      dateTo,
      timeFrom,
      timeTo,
      accountId,
      userId,
      stationId,
    };

    return this._getRequest<SaleAnalysisReport[]>(this.webUrl + "repStoreSaleAnalysis/" + storeId, queryParams);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
