<ng-template [ngIf]="true" [formGroup]="_myForm">
  <td class="col-3 col-currency">{{
    _myForm.get("amount").value | currency : currencyISOCode : "symbol-narrow" : "1.0"
  }}</td>
  <!-- <td *ngIf="_myForm.get('id').value == 0" class="col-3 col-currency">
        <div class="p-inputgroup">
            <span class="p-inputgroup-addon">$</span>
            <taku-input [formGroup]="_myForm" fieldName="amount" fieldCaption="Amount"></taku-input>
        </div>
    </td> -->
  <td class="col-timessign">X</td>
  <td class="col-4 col-count">
    <!-- <taku-input [formGroup]="_myForm" [maxLength]="fieldCountMaxLength" keyFilter="pint" fieldName="count"
            fieldCaption="Count {{formatDenominationLabel(_myForm.get('amount').value|number:'1.0')}}">
        </taku-input> -->
    <taku-input
      [formGroup]="_myForm"
      [maxLength]="fieldCountMaxLength"
      keyFilter="pint"
      fieldName="count"
      fieldCaption="Qty"
      ngClass="text-right"
      [autoSelect]="true"
    >
    </taku-input>
  </td>
  <td class="col-equalsign">=</td>
  <td class="col-4 col-total">{{
    _myForm.get("amount").value * _myForm.get("count").value | currency : currencyISOCode : "symbol-narrow"
  }}</td>
</ng-template>
