/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component } from "@angular/core";
import { GenericFormComponent } from "src/app/forms/generic-form/generic-form.component";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { SaleChannelPaymentMapping, SaleChannel } from "../sale-channel";
import { SelectItem } from "primeng/api";
import * as _ from "lodash";
import { environment } from "src/environments/environment";
import { APP_BRAND } from "src/app/utility/white-label";

@Component({
  selector: "taku-sale-channel-detail",
  templateUrl: "./sale-channel-detail.component.html",
  styleUrls: ["./sale-channel-detail.component.scss"],
})
export class SaleChannelDetailComponent extends GenericFormComponent {
  _pageTitle = "Sales Channel Setup";
  _toolbarTitle: string;
  lookup_tender_types: SelectItem[];
  protected webUrl = environment.apiUrl;
  branding = APP_BRAND;

  _taxUploading = false;
  _isSyncingCategories = false;

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    const tmpModel = new SaleChannel();
    const tmpForm = fb.group(tmpModel);
    tmpForm.setControl("saleChannelPaymentMappings", fb.array([]));
    return tmpForm;
  }

  closeStoreFront() {
    this.confirmationService.confirm({
      header: "Confirmation",
      message: `By clicking Yes below, you will be closing your online storefront.
        Closing your storefront will unpublish all of the products currently enabled in Sales Channel Inventory for ${this.branding.generic.companyName} eCommerce and will not allow anybody on the internet to make purchases in your ${this.branding.generic.companyName} eCommerce online store based on your Stock Control settings.
        Are you sure you want to proceed?`,
      acceptLabel: "Yes",
      rejectVisible: true,
      acceptVisible: true,
      rejectButtonStyleClass: "p-button-link",
      accept: () => {
        this._myForm.get("isStoreFrontOpen").setValue(false);
        this._myForm.get("isStoreFrontOpen").markAsDirty();
        this.onSubmit(false).subscribe();
      },
      reject: () => {},
    });
  }

  onSave() {
    this.confirmationService.confirm({
      header: "Confirmation",
      message: `Any new changes to sales channel settings will not impact products already published online. To apply any changes on current products, please re-submit them to your sales channel.`,
      acceptLabel: "OK",
      rejectVisible: false,
      acceptVisible: true,
      accept: () => {
        super.onSave();
      },
      reject: () => {},
    });
  }

  syncCategories() {
    this._isSyncingCategories = true;
    this.dbService
      ._postRequest(this.webUrl + "TAKUeCommerceSyncCategories/" + this._object.id, {})
      .subscribe((message: string) => {
        this.messageService.add({
          severity: "success",
          summary: "Confirmation",
          detail: "All categories are pushed successfully!",
        });
        this._isSyncingCategories = false;
      });
  }

  openStoreFront() {
    this.confirmationService.confirm({
      header: "Confirmation",
      message: `By clicking Yes below, you will be opening your online storefront.
        Opening your storefront will publish all of the products currently enabled in Sales Channel Inventory for ${this.branding.generic.companyName} eCommerce and allow anybody on the internet to make purchases in your ${this.branding.generic.companyName} eCommerce online store based on your Stock Control settings.
        Are you sure you want to proceed?`,
      acceptLabel: "Yes",
      rejectVisible: true,
      acceptVisible: true,
      rejectButtonStyleClass: "p-button-link",
      accept: () => {
        this._myForm.get("isStoreFrontOpen").setValue(true);
        this._myForm.get("isStoreFrontOpen").markAsDirty();
        this.onSubmit(false).subscribe();
      },
      reject: () => {},
    });
  }
  // static set(fb: FormBuilder, saleChannel: SaleChannel): FormGroup {
  //   const temp = fb.group(saleChannel);
  //   temp.setControl('saleChannelPaymentMappings', this.setSaleChannelPaymentMappingArray(fb, saleChannel.saleChannelPaymentMappings));
  //   return temp;
  // }

  // static setSaleChannelPaymentMappingArray(fb: FormBuilder, SaleChannelPaymentMappings: SaleChannelPaymentMapping[]) {
  //   let formArray = fb.array([]);
  //   SaleChannelPaymentMappings.map((saleChannelPaymentMapping) => {
  //     const temp = fb.group(saleChannelPaymentMapping);
  //     formArray.push(temp);
  //   });
  //   return formArray;
  // }

  ngOnInit() {
    super.ngOnInit();
    this.getLookup_tenderTypes();
    this._toolbarTitle = `<span class="formlist-title">${this._pageTitle}</span> <span class="secondary-text"> (${
      this._myForm.get("channelType").value
    })</span>`;
  }

  setFormArrays() {
    const formArray = this.fb.array([]);
    // if (this._object.saleChannelPaymentMappings.length === 0) {
    //   this._object.saleChannelPaymentMappings.push(new SaleChannelPaymentMapping());
    // }
    this._object.saleChannelPaymentMappings.map((saleChannelPaymentMapping) => {
      const temp = this.fb.group(saleChannelPaymentMapping);
      formArray.push(temp);
    });
    this._myForm.setControl("saleChannelPaymentMappings", formArray);
  }

  getLookup_tenderTypes() {
    const _lookupModel = "storeTenderTypeList";
    const _lookupLabel = "tenderType.description";
    const _options = {
      lookupFilter: JSON.stringify({
        storeId: { matchMode: "equals", value: this._myForm.get("storeId").value },
      }),
      enableFieldName: "tenderType.isEnabled",
      dataKey: "tenderType.id",
    };

    let _lookupCache = JSON.parse(localStorage.getItem("lookup_" + _lookupModel));
    if (!_lookupCache) {
      _lookupCache = [];
    }

    this.dbService
      .getRows(_lookupModel, _.get(_options, "lookupFilter") || "", 0, 100)
      .subscribe((_storeTenderTypeLists) => {
        const lookupOptions: SelectItem[] = [{ label: "", value: null }];
        // const lookupOptions: SelectItem[] = [];
        _storeTenderTypeLists.rows.forEach((myObject: any) => {
          myObject.storeTenderTypes.map((_storeTenderType) => {
            lookupOptions.push({
              label: _.get(_storeTenderType, _lookupLabel),
              value: _.get(_options, "dataKey")
                ? _.get(_storeTenderType, _.get(_options, "dataKey"))
                : _storeTenderType,
              // value: _lookupOptions?._dataKey ? _.get(myObject, _lookupOptions._dataKey) : myObject,
              disabled: _.get(_options, "enableFieldName")
                ? !_.get(_storeTenderType, _.get(_options, "enableFieldName"))
                : false,
              icon:
                _.get(_options, "icon") !== undefined
                  ? _.get(_options, "icon")
                  : (
                      _.get(_options, "enableFieldName")
                        ? !_.get(_storeTenderType, _.get(_options, "enableFieldName"))
                        : false
                    )
                  ? "pi pi-lock"
                  : "",
            });
          });
        });
        this.lookup_tender_types = lookupOptions;
      });
  }

  initForm() {
    this._model = "saleChannel";
    this._object = new SaleChannel();
    this._myForm = SaleChannelDetailComponent.init(this.fb);
  }

  launchControlPanel() {
    this.dbService._getRequest(this.webUrl + "TAKUeCommerceURL/" + this._object.id).subscribe((_url: string) => {
      window.open(_url, "_blank");
    });
  }

  uploadTaxes() {
    this._taxUploading = true;
    this.dbService
      ._postRequest(this.webUrl + "TAKUeCommerceUploadTaxes/" + this._object.id, {})
      .subscribe((message: string) => {
        this.messageService.add({
          severity: "success",
          summary: "Confirmation",
          detail: "Taxes pushed successfully!",
        });
        this._taxUploading = false;
      });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
