<div class="list-card wider-list-card grid" [ngStyle]="{ width: '100%', height: height + 'px' }">
  <!-- <div class="list-card" [ngStyle]="{width: '100%', height: height+'px'}"> -->
  <div class="item-left col-4">
    <i *ngIf="inventory.parentInventoryId" class="material-icons">photo_size_select_small</i>
    <i *ngIf="inventory.isVariantParent" class="material-icons">bookmarks</i>
    <i *ngIf="!inventory.parentInventoryId && !inventory.isVariantParent" class="material-icons">bookmark_border</i>
    <div class="inventory-img">
      <img
        [attr.src]="
          (inventory?.inventoryImages && InventoryImagePinnedToPictureGallery?.urlFileName150) ||
          '../../../../assets/layout/images/no-image.jfif'
        "
      />
    </div>
  </div>
  <div class="item-right col-7">
    <div class="name-and-price-container">
      <div class="inventory-name">
        <a *ngIf="!disabled" (click)="qtyCtrl.value >= 0 && openItemDetailsDlg($event)">
          <!-- <a *ngIf="!disabled"> -->
          {{ inventory.description1 }}
        </a>
        <span *ngIf="disabled">
          {{ inventory.description1 }}
        </span>
      </div>
      <div class="font-italic text-base">
        {{ inventoryVariantsStr }}
      </div>
      <div class="inventory-price">
        <b>{{ inventory.standardPrice | currency : "CAD" : "symbol-narrow" : "1.2" }}</b>
      </div>
    </div>
    <div class="qty-ctrl-container grid" *ngIf="!inventory.isVariantParent">
      <!-- <div class="col-4 chg-qty-btn"  [ngClass]="{disabledBtn: disabled || !isQtyValid(calculateQtyDecrement) }"> -->
      <!-- <i class="material-icons" (click)="decQty()">remove_circle_outline</i> -->
      <div class="col-4 chg-qty-btn" [ngClass]="{ disabledBtn: disabled }">
        <i class="material-icons" (click)="decreaseQty()">remove_circle_outline</i>
      </div>
      <div class="col-4 item-qty-in-cart">
        <b>{{ qtyCtrl.value }}</b>
      </div>
      <!-- <div class="col-4 chg-qty-btn" [ngClass]="{disabledBtn: disabled || !isQtyValid(calculateQtyIncrement) }">
        <i class="material-icons" (click)="incQty()">add_circle</i> -->
      <div class="col-4 chg-qty-btn" [ngClass]="{ disabledBtn: disabled }">
        <i class="material-icons" (click)="increaseQty()">add_circle</i>
      </div>
    </div>
  </div>
</div>
