<taku-settings-cards [pageTitle]="pageTitle" [pageIconStyleClass]="pageIconStyleClass" [cardsInfo]="cardsInfo">
  <div class="panel-toolbar">
    <taku-store-switcher
      *ngIf="_activeZoneId"
      (onStoreChanged)="storeChanged($event)"
      [selectedStoreId]="_activeStoreId"
      [activeZoneId]="_activeZoneId"
    ></taku-store-switcher>
  </div>
</taku-settings-cards>
