/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DBService } from "src/app/shared/services/db.service";
import { SaleDoc } from "../sale-document/sale-doc/sale-doc";
@Injectable({
  providedIn: "root",
})
export class ReturnInvoiceService {
  constructor(private dbService: DBService) {}

  getSaleDocToReturn(saleDocId: number): Observable<SaleDoc> {
    return this.dbService.getRow("saleDoc", saleDocId, {
      fields: "qtyReferred",
      includes: "saleDocLine.voucher.saleDocTender",
      excludes: "address",
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
