<div class="col-12">
  <strong>{{ countEnabled$ | async }}</strong> selected items are <strong>enabled</strong>
</div>
<div class="col-12">
  <strong>{{ countDisabled$ | async }}</strong> selected items are <strong>disabled</strong>
</div>
<div *ngIf="countDisabled$ | async as countDisabled" class="col-12">
  <taku-checkbox
    [disabled]="!countDisabled"
    [formGroup]="pushForm"
    fieldName="includeDisabled"
    fieldCaption="include disabled items when submitting"
  ></taku-checkbox>
</div>

<div class="col-12 question-message grid-align-right">
  <i class="pi pi-info-circle"></i>
  <span class="text">Are you sure you want to <strong>submit</strong> ALL items to GMC?</span>
</div>

<div class="col-12 actionBtn-container grid-align-right">
  <button pButton icon="ui-icon-check" label="SUBMIT & ENABLE" (click)="onActivateGMC()"></button>
</div>
