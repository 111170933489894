/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Stock } from "../../settings/business-settings/stock/stock";
import { ContentAPI_ProductStatuses } from "../../settings/integration-settings/google-shopping/gmc-api-data-definitions/ContentAPI_ProductStatutes";
import { Zone } from "../../settings/zone-settings/Zone";
import { TaxInventoryCategory } from "../../tax/tax-inventory-category/tax-inventory-category";
import { Brand } from "../brand/brand";
import { Category } from "../category/category";
import { InventoryBarcode } from "../inventory-barcode/inventory-barcode";
import { UOfMeasure } from "../u-of-measure/u-of-measure";
import { GMCHelpers } from "src/app/utility/GMCHelpers";
import { InventoryImage } from "../inventory-image/inventory-image";
import * as _ from "lodash";
import { InventorySaleChannel } from "../inventory-sale-channel/inventory-sale-channel";
import { DateTimeFormatPipe } from "src/app/shared/pipes/date-time-format.pipe";
import { AppConstants } from "src/app/shared/app-constants";
import { InventoryOptionsSetting } from "../inventory-options-setting/inventory-options-setting";
import { InventoryOptionsSettingValue } from "../inventory-options-setting-value/inventory-options-setting-value";
import { InventoryVariantSettingLine } from "../inventory-variant-setting-line/inventory-variant-setting-line";
import { InventoryUsageDetail } from "../inventory-usage-detail/inventory-usage-detail";
import { InventorySupplier } from "../inventory-supplier/inventory-supplier";
import { Aisle } from "../../aisle/aisle";
import { Shelf } from "../../shelf/shelf";
import { PackSize } from "../inventory-supplier-product-detail/inventory-supplier-product-detail";
export class Inventory {
  id: number;
  sku: string;
  description1: string;
  description2: string;
  longDescription: string;
  stockType: Stock_type;
  nonePhysicalStockType: Non_physical_stock_type;
  isEnabled: boolean;
  isSellable: boolean;
  isBuyable: boolean;
  isTrackQTY: boolean;
  note: string;
  policy: string;
  isBatch: boolean;
  isSerial: boolean;
  isKits: boolean;
  standardPrice: number | string;
  standardMinPrice: number | string;
  standardMaxPrice: number | string;
  standardPriceCurrencyIsoCode: string;
  /** TODO this is misnamed - when true, discounts are not allowed */
  allowDiscount: boolean;
  maxDiscountPercentage: number;
  maxDiscountAmount: number;
  isSalePriceEditable: boolean;
  isReturnPriceEditable: boolean;
  msrpPrice: number | string;
  msrpPriceCurrencyIsoCode: string;
  tags: JSON;
  length: number;
  width: number;
  height: number;
  weight: number;
  CBM: number;
  CUFT: number;
  uOfMeasureId: number;
  uOfMeasure: UOfMeasure;
  lengthUOfMeasureId: number;
  lengthUOfMeasure: UOfMeasure;
  massUOfMeasureId: number;
  massUOfMeasure: UOfMeasure;
  taxInventoryCategoryId: number;
  taxInventoryCategory: TaxInventoryCategory;
  categories: Category[];
  inventoryBarcodes: InventoryBarcode[];
  inventoryUsageDetails: InventoryUsageDetail[];
  inventoryImages: InventoryImage[];
  inventoryZones: InventoryZone[];
  inventoryStocks: InventoryStock[];
  inventoryStores: InventoryStore[];
  inventoryGoogleShopping: InventoryGoogleShopping;
  // inventoryGoogleShoppingId: number;
  brand: Brand;
  brandId: number;
  defaultReportCategory: Category;
  defaultReportCategoryId: number;
  // isGMCEnabled: boolean;
  productStatus: ContentAPI_ProductStatuses.ProductStatus;
  _activeStoreId: number;
  activeInventoryStore: InventoryStore;
  defaultInventoryStock: InventoryStock;
  defaultInventoryZone: InventoryZone;
  inventorySaleChannels: InventorySaleChannel[];
  createdAt: Date;
  updatedAt: Date;
  includedInGallery: boolean;
  isVariantParent: boolean;
  /** This is virtual type, used for CSV import */
  _inventoryVariantType?: Inventory_Variant_Type;
  parentInventoryId: number;
  parentInventory: Inventory;
  inventoryVariantSettings: InventoryVariantSettingLine[];
  inventoryVariants: InventoryVariant[];
  inventorySuppliers: InventorySupplier[];

  constructor(defaultCurrencyIsoCode?: string) {
    this.id = 0;
    this.sku = null;
    this.description1 = "";
    this.description2 = "";
    this.longDescription = "";
    this.stockType = Stock_type.physical;
    this.nonePhysicalStockType = null;
    this.isEnabled = true;
    this.isSellable = true;
    this.isBuyable = true;
    this.isTrackQTY = true;
    this.note = "";
    this.policy = "";
    this.isBatch = false;
    this.isSerial = false;
    this.isKits = false;
    this.standardPrice = null;
    this.standardMinPrice = 0;
    this.standardMaxPrice = 0;
    this.standardPriceCurrencyIsoCode = defaultCurrencyIsoCode;
    this.allowDiscount = false;
    this.maxDiscountPercentage = 0;
    this.maxDiscountAmount = 0;
    this.isSalePriceEditable = false;
    this.isReturnPriceEditable = false;
    this.msrpPrice = 0;
    this.msrpPriceCurrencyIsoCode = defaultCurrencyIsoCode;
    this.tags = null;
    this.length = null;
    this.width = null;
    this.height = null;
    this.weight = null;
    this.CBM = null;
    this.CUFT = null;
    this.uOfMeasureId = null;
    this.uOfMeasure = null;
    this.lengthUOfMeasureId = null;
    this.lengthUOfMeasure = null;
    this.massUOfMeasureId = null;
    this.massUOfMeasure = null;
    this.taxInventoryCategoryId = null;
    this.categories = [];
    this.inventoryBarcodes = [];
    this.inventoryUsageDetails = [];
    this.inventoryImages = [];
    this.inventoryZones = [];
    this.inventoryStocks = [];
    this.inventoryStores = [];
    this.inventoryGoogleShopping = new InventoryGoogleShopping();
    // this.inventoryGoogleShoppingId = 0;
    this.brand = null;
    this.brandId = null;
    this.defaultReportCategory = null;
    this.defaultReportCategoryId = null;
    // this.isGMCEnabled = true;
    this.productStatus = null;
    this.inventorySaleChannels = [];
    this.defaultInventoryStock = null;
    this.defaultInventoryZone = null;
    this.createdAt = null;
    this.updatedAt = null;
    this.includedInGallery = false;
    this.isVariantParent = false;
    this.parentInventoryId = null;
    this.parentInventory = null;
    this.inventoryVariantSettings = [];
    this.inventoryVariants = [];
    this.inventorySuppliers = [new InventorySupplier()];
  }

  get inventoryVariantType() {
    if (this.isVariantParent) {
      return Inventory_Variant_Type.VariantParent;
    } else if (this.parentInventoryId) {
      return Inventory_Variant_Type.VariantChild;
    } else return Inventory_Variant_Type.Single;
  }

  get createdAtDateAndTime() {
    if (this.createdAt == null) return null;

    try {
      return new DateTimeFormatPipe(AppConstants.DEFAULT_LOCALE_ID).transform(this.createdAt);
    } catch (err) {
      console.warn("Error when convertign time to local", err, this.createdAt);
      return `${this.createdAt}`;
    }
  }

  get updatedAtDateAndTime() {
    if (this.updatedAt == null) return null;

    try {
      return new DateTimeFormatPipe(AppConstants.DEFAULT_LOCALE_ID).transform(this.updatedAt);
    } catch (err) {
      console.warn("Error when convertign time to local", err, this.updatedAt);
      return `${this.updatedAt}`;
    }
  }

  get inventoryVariantsStr() {
    return _.sortBy(this.inventoryVariants, "inventoryOptionsSettingId")
      ?.map((_inventoryVariant) => _inventoryVariant?.inventoryOptionsSettingValue?.optionValue)
      .join(" / ");
  }

  get inventoryStocksMin() {
    return this.inventoryStocks;
  }

  get inventoryStocksMax() {
    return this.inventoryStocks;
  }

  get supplierCost(): number {
    const detail = this.inventorySuppliers[0].inventorySupplierProductDetails.find((row) => {
      return row.packSize === PackSize.SKU;
    });
    return detail ? detail.supplierCost : undefined;
  }

  get supplierCode(): string {
    const detail = this.inventorySuppliers[0].inventorySupplierProductDetails.find((row) => {
      return row.packSize === PackSize.SKU;
    });
    return detail ? detail.supplierCode : undefined;
  }

  get defaultQtyOH() {
    return _.get(this.defaultInventoryStock, "qtyOH", 0);
  }

  get defaultQtyAvail() {
    return InventoryStock.getQtyAvail(this.defaultInventoryStock);
  }

  get categoriesList() {
    return this.categories.map((row) => row.categoryName).join("; ");
  }

  get googleStatus(): ContentAPI_ProductStatuses.DestinationStatus_Status {
    if (!this.isGMCEnabled) return ContentAPI_ProductStatuses.DestinationStatus_Status.DISABLED;
    return GMCHelpers.GetProductStatus(this.productStatus);
  }

  get isGMCEnabled() {
    return this.activeInventoryStore && this.activeInventoryStore.isGMCEnabled;
  }

  set isGMCEnabled(enable) {
    this.activeInventoryStore.isGMCEnabled = enable;
  }

  get activeStoreId() {
    return this._activeStoreId;
  }

  get inventoryImagesCount() {
    return this.inventoryImages.length;
  }

  set activeStoreId(storeId) {
    let inventoryStore = this.inventoryStores.find((inventoryStore) => inventoryStore.storeId === storeId);
    if (!inventoryStore) {
      inventoryStore = Object.assign({}, new InventoryStore(), {
        storeId: storeId,
        isGMCEnabled: false,
      });
      // Add entry for active store if not available
      this.inventoryStores.push(inventoryStore);
    }

    this.activeInventoryStore = inventoryStore;
    this._activeStoreId = storeId;
  }

  get googleStatusFormatted(): string {
    return `<span class="status-${_.snakeCase(this.googleStatus)}">${_.startCase(this.googleStatus)}</span>`;
  }

  get googleErrorsSummary(): string {
    if ([ContentAPI_ProductStatuses.DestinationStatus_Status.DISABLED].includes(this.googleStatus))
      return this.googleStatusFormatted;

    if (this.googleStatus === ContentAPI_ProductStatuses.DestinationStatus_Status.NOT_AVAILABLE) {
      if (this.productStatus && (<any>this.productStatus).error)
        return `<span class="servability-error">Error: ${_.startCase(
          (<any>this.productStatus).error.error.error.message
        )}</span>`;
      else return this.googleStatusFormatted;
    }

    if (!this.productStatus || !this.productStatus.itemLevelIssues) {
      if (this.googleStatus === ContentAPI_ProductStatuses.DestinationStatus_Status.APPROVED)
        return `<span class="servability-noerrors">No problems found</span>`;
      else return `<span class="servability-error">Not available</span>`;
    }

    const servabilityCount: { [key: string]: number } = {};
    this.productStatus.itemLevelIssues.forEach((itemIssue) => {
      if (!servabilityCount[itemIssue.servability])
        // 0 as initial value for current servability
        servabilityCount[itemIssue.servability] = 0;

      // Increase count by one
      servabilityCount[itemIssue.servability]++;
    });

    const sortedKeys = _.sortBy(Object.keys(servabilityCount));
    const summaryLines: string[] = [];
    for (const key of sortedKeys) {
      const count = servabilityCount[key];
      summaryLines.push(`<span class="servability-${key}">${_.startCase(key)}: ${count}</span>`);
    }

    return summaryLines.join(", ");
  }

  get hasGMCIssues() {
    return (
      ![
        ContentAPI_ProductStatuses.DestinationStatus_Status.NOT_AVAILABLE,
        ContentAPI_ProductStatuses.DestinationStatus_Status.DISABLED,
      ].includes(this.googleStatus) &&
      this.productStatus.itemLevelIssues &&
      this.productStatus.itemLevelIssues.length
    );
  }

  get hasInventorySaleChannel() {
    return (this.inventorySaleChannels[0] ? this.inventorySaleChannels[0].channelInventoryId : false)
      ? "Published"
      : "Not Submitted";
  }
}

export class InventoryZone {
  costBasis: Cost_Basis;
  customDutyValue: number | string;
  customDutyValueCurrencyIsoCode: string;
  landedCost: number | string;
  landedCostCurrencyIsoCode: string;
  standardCost: number | string;
  standardCostCurrencyIsoCode: string;
  uOfMeasureId: number;
  zoneId: number;
  zone: Zone;

  constructor() {
    this.costBasis = Cost_Basis.standard;
    this.customDutyValue = 0;
    this.customDutyValueCurrencyIsoCode;
    this.landedCost = 0;
    this.landedCostCurrencyIsoCode;
    this.standardCost = 0;
    this.standardCostCurrencyIsoCode;
    this.zoneId = 0;
    this.zone = null;
    this.uOfMeasureId = 0;
  }
}

export class InventoryStock {
  id: number;
  minStock: number;
  maxStock: number;
  committedQty: number;
  onOrderQty: number;
  qtyOH: number;
  stockId: number;
  stock?: Stock;
  aisle?: Aisle;
  aisleId: number | null;
  shelf?: Shelf;
  shelfId: number | null;

  constructor() {
    this.id = 0;
    this.minStock = 0;
    this.maxStock = 0;
    this.committedQty = 0;
    this.onOrderQty = 0;
    this.qtyOH = 0;
    this.stockId = 0;
    this.stock = null;
    this.aisle = null;
    this.aisleId = null;
    this.shelfId = null;
    this.shelf = null;
  }

  static getQtyAvail(invStock: InventoryStock): number {
    if (!invStock) {
      return 0;
    }
    return isNaN(invStock.qtyOH - invStock.committedQty) ? 0 : invStock.qtyOH - invStock.committedQty;
  }

  get qtyAvail(): number {
    return InventoryStock.getQtyAvail(this);
  }
}

export class InventoryGoogleShopping {
  id: number;
  productLink: string;
  imageLink: string;
  brand: string;
  GTIN: string;
  MPN: string;
  productCategory: JSON;
  productType: string;
  condition: GoogleShoppingCondition;
  adult: boolean;
  multiPack: number;
  isBuldle: boolean;
  sizeType: GoogleShoppingSizeType;
  ageGroup: GoogleShoppingAgeGroup;
  gender: GoogleShoppingGender;
  material: string;
  pattern: string;
  sizeSystem: string;
  sizes: JSON;
  color: string;
  googleProductId: number;
  titleTemplate: GoogleShoppingTitleTemplate;

  public constructor() {
    this.id = 0;
    this.productLink = null;
    this.imageLink = null;
    this.brand = null;
    this.GTIN = null;
    this.MPN = null;
    this.productCategory = null;
    this.productType = null;
    this.condition = GoogleShoppingCondition.New;
    this.adult = false;
    this.multiPack = null;
    this.isBuldle = false;
    this.sizeType = GoogleShoppingSizeType.Regular;
    this.sizes = null;
    this.ageGroup = null;
    this.gender = null;
    this.material = null;
    this.pattern = null;
    this.sizeSystem = GoogleShoppingSize.US;
    this.color = null;
    this.googleProductId = null;
    this.titleTemplate = GoogleShoppingTitleTemplate.Basic;
  }
}

export class InventoryVariant {
  id: number;
  inventoryId: number;
  inventoryOptionsSettingId: number;
  inventoryOptionsSetting: InventoryOptionsSetting;
  inventoryOptionsSettingValueId: number;
  inventoryOptionsSettingValue: InventoryOptionsSettingValue;

  constructor() {
    this.id = 0;
    this.inventoryId = null;
    this.inventoryOptionsSettingId = null;
    this.inventoryOptionsSetting = null;
    this.inventoryOptionsSettingValueId = null;
    this.inventoryOptionsSettingValue = null;
  }
}

export class InventoryStore {
  id: number;
  isGMCEnabled: boolean;
  storeId: number;

  constructor() {
    this.id = 0;
    this.isGMCEnabled = true;
    this.storeId = null;
  }
}

export enum GoogleShoppingGTIN {
  INTERNAL = "Internal",
  SUPPLIER = "Supplier",
}

export enum GoogleShoppingSize {
  US = "US",
  UK = "UK",
  EU = "EU",
  DE = "DE",
  FR = "FR",
  JP = "JP",
  CN = "CN",
  IT = "IT",
  BR = "BR",
  MEX = "MEX",
  AU = "AU",
}

export enum GoogleShoppingCondition {
  New = "New",
  Refurbished = "Refurbished",
  Used = "Used",
}

export enum GoogleShoppingSizeType {
  Regular = "Regular",
  Petite = "Petite",
  Plus = "Plus",
  BigandTall = "Big and Tall",
  Maternity = "Maternity",
}

export enum GoogleShoppingAgeGroup {
  NewBorn = "New Born",
  Infant = "Infant",
  Toddler = "Toddler",
  Kids = "Kids",
  Adult = "Adult",
}

export enum GoogleShoppingGender {
  Male = "Male",
  Female = "Female",
  Unisex = "Unisex",
}

export enum GoogleShoppingTitleTemplate {
  Basic = "Basic",
  Apparel = "Apparel",
  HealthAndBeauty = "Health & Beauty",
  HardGoods = "Hard Goods",
  Electronics = "Electronics",
  Seasonal = "Seasonal",
  // Books = 'Books'
}

export enum Stock_type {
  physical = "Physical",
  non_physical = "Non-physical",
}

export enum Non_physical_stock_type {
  service = "Service",
  rental = "Rental",
  voucher = "Voucher",
  prepaid = "Prepaid",
  misc = "Misc",
}

export enum Cost_Basis {
  standard = "Standard",
  average = "Average",
  FIFO = "FIFO",
  LIFO = "LIFO",
}

export enum Inventory_Variant_Type {
  Single = "Single",
  VariantParent = "Parent",
  VariantChild = "Child",
}

export enum Product_Type {
  hideChild = "Hide Child",
  showChild = "Show Child",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
