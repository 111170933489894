/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { CommonModule } from "@angular/common";
import { AfterViewInit, Component, EventEmitter, HostBinding, Input, OnInit, Output, OnDestroy } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Params } from "@angular/router";
import { MenuItem } from "primeng/api";
import { ButtonModule } from "primeng/button";
import { MenuModule } from "primeng/menu";
import { ToolbarModule } from "primeng/toolbar";
import { of, Subscription } from "rxjs";
import { map, tap } from "rxjs/operators";
import { UnsavedChangesGuard } from "src/app/app-routing/UnsavedChanges.guard";
import { SafeHtmlPipe } from "src/app/shared/pipes/safe-html.pipe";

export enum FormHeaderDisplayMode {
  INLINE = "inline",
  WIZARD = "wizard",
  FULL = "full",
}

@Component({
  selector: "taku-form-header",
  templateUrl: "./form-header.component.html",
  styleUrls: ["./form-header.component.scss"],
  standalone: true,
  imports: [CommonModule, ToolbarModule, SafeHtmlPipe, ButtonModule, MenuModule],
})
export class FormHeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  subsList: Subscription[] = [];

  private readonly confirmationMsg = "You have unsaved changes. Are you sure you want to close this form?";

  @Input() formGroup?: UntypedFormGroup;
  @Input() toolbarTitle: string;
  @Input() iconStyleClass: string;
  @Input() iconName?: string;
  @Input() insideDialog = false;
  @Output() backClicked = new EventEmitter();
  // @Input() disableBack: boolean;
  @Output() saveClicked = new EventEmitter();
  // @Input() disableSave: boolean;
  @Output() revertClicked = new EventEmitter();
  // @Input() disableRevert: boolean;
  @Output() cloneClicked = new EventEmitter();
  @Output() newClicked = new EventEmitter();
  @Output() deleteClicked = new EventEmitter();
  @Input() showBackBtn = true;
  @Input() showActionBtns = true;
  @Input() showSecondaryBtns = true;
  @Input() displayMode: FormHeaderDisplayMode;
  @Input() customContentPos = "right";
  @Input() extraButtonIcon?: string = "";
  @Output() extraButtonClicked = new EventEmitter();
  @Input() disableButtons?: boolean = false;
  @Input() isSaving = false;
  @Input() shortenHeight = false;

  @HostBinding("class.reversed-direction")
  get hasReversedDirection() {
    return this.customContentPos === "left";
  }

  items: MenuItem[] = [];
  FormHeaderDisplayMode = FormHeaderDisplayMode;

  @HostBinding("class.inline-mode")
  get styleClassMode() {
    return [FormHeaderDisplayMode.INLINE, FormHeaderDisplayMode.WIZARD].includes(this.displayMode);
  }

  @HostBinding("class.displayMode-wizard")
  get isDisplayModeWizard() {
    return this.displayMode === FormHeaderDisplayMode.WIZARD;
  }

  constructor(private route: ActivatedRoute, private unsavedChangesGuard: UnsavedChangesGuard) {}

  static resizeBar(newWidth: number) {
    const headerEl = document.querySelector("taku-form-header:not(.inline-mode)");
    if (headerEl) {
      const newHeaderWidth = newWidth;
      (<HTMLElement>headerEl).style.width = `${newHeaderWidth - 33}px`;
    }
  }

  ngOnInit() {
    let displayMode$;
    if (this.displayMode) displayMode$ = of(this.displayMode);
    else
      displayMode$ = this.route.params.pipe(
        map((params: Params) => params["detailmode"]),
        map((detailmode) => (!detailmode || detailmode == "w" ? "full" : detailmode)),
        tap((displayMode: any) => (this.displayMode = displayMode))
      );

    this.subsList.push(
      displayMode$.subscribe((displayMode) => {
        if (displayMode == "full" && this.showBackBtn === undefined) this.showBackBtn = true;
      })
    );

    if (this.formGroup) {
      this.items = [
        {
          label: "Clone",
          command: (event) => {
            if (this.formGroup.dirty || this.formGroup.valid) {
              this.onClone();
            }
          },
          disabled: !(this.formGroup.dirty || this.formGroup.valid),
        },
        {
          label: "Delete",
          command: (event) => {
            if (this.formGroup.dirty || this.formGroup.valid) {
              this.onDelete();
            }
          },
          disabled: !(this.formGroup.dirty || this.formGroup.valid),
        },
      ];
    }
  }

  onBack() {
    if (this.insideDialog)
      this.subsList.push(
        this.unsavedChangesGuard.checkForm(this.formGroup.pristine, this.confirmationMsg).subscribe((shouldClose) => {
          if (shouldClose) this.backClicked.emit();
        })
      );
    else this.backClicked.emit();
  }

  onSave() {
    this.saveClicked.emit();
  }

  onRevert() {
    this.revertClicked.emit();
  }

  onClone() {
    this.cloneClicked.emit();
  }

  onNew() {
    this.newClicked.emit();
  }

  onDelete() {
    this.deleteClicked.emit();
  }

  ngAfterViewInit(): void {
    const elLayoutContent = document.getElementById("layout-main-content");
    if (elLayoutContent) {
      FormHeaderComponent.resizeBar(elLayoutContent.clientWidth);
    }
  }

  extraIconClicked(event) {
    this.extraButtonClicked.emit(event);
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
