import { BarcodeFormat } from "../inventory-barcode/inventory-barcode";
import { UOfMeasure } from "../u-of-measure/u-of-measure";

export class InventorySupplierProductDetail {
  id: number;
  isDefault: boolean;
  packSize: PackSize;
  skuQty: number;
  moqPackSize: number;
  supplierCode: string;
  supplierCost: number;
  supplierCostCurrencyIsoCode: string;
  barcode: string;
  barcodeFormat: BarcodeFormat;
  length: number;
  width: number;
  CBM: number;
  CUFT: number;
  weight: number;
  lengthUOfMeasureId: number;
  lengthUOfMeasure: UOfMeasure;
  massUOfMeasureId: number;
  massUOfMeasure: UOfMeasure;
  inventorySupplierId: number;
  createdAt: Date;
  updatedAt: Date;

  constructor(id?: number, inventorySupplierId?: number, defaultCurrencyIsoCode?: string, packSize?: PackSize) {
    this.id = id || 0;
    this.isDefault = false;
    this.packSize = packSize || PackSize.SKU;
    this.skuQty = 1;
    this.moqPackSize = 1;
    this.supplierCode = null;
    this.supplierCost = 0;
    this.supplierCostCurrencyIsoCode = defaultCurrencyIsoCode || null;
    this.barcode = "";
    this.barcodeFormat = null;
    this.length = null;
    this.width = null;
    this.CBM = null;
    this.CUFT = null;
    this.weight = null;
    this.lengthUOfMeasureId = null;
    this.lengthUOfMeasure = null;
    this.massUOfMeasureId = null;
    this.massUOfMeasure = null;
    this.inventorySupplierId = inventorySupplierId || 0;
    this.createdAt = null;
    this.updatedAt = null;
  }
}

export enum PackSize {
  SKU = "SKU",
  INNER = "Inner",
  OUTER = "Outer",
}
