/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { DBService } from "../../../../shared/services/db.service";
import { FormListModel } from "../../../../form-list/form-list/form-list-model.interface";
import { ValidatorFn, Validators } from "@angular/forms";
import { Col, DataType, FilterType } from "../../../../form-list/form-list/form-list";
import { RoleVisibility } from "./role-visibility";

@Injectable({
  providedIn: "root",
})
export class RoleVisibilityService extends DBService implements FormListModel {
  getValidationRules(): { [key: string]: ValidatorFn[] | {} } {
    return {
      isVisible: [Validators.required],
      isReadOnly: [Validators.required],
    };
  }

  getFormListColumns(): Col[] {
    const disableFieldPredicate = (rowData: RoleVisibility) => {
      //if the module name contains 'CRM Tab' we want to disable the "Enabled" field.
      return rowData.caption.includes("CRM Tab");
    };

    return [
      {
        field: "caption",
        header: "Description",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        styleObj: { "text-align": "left" },
      },
      {
        field: "isVisible",
        header: "Visible",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.checkbox,
        dataTypeOptions: { showStateLabel: true, labelStateFalse: "NO", labelStateTrue: "YES" },
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.enum,
      },
      {
        field: "isReadOnly",
        header: "Enabled",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.checkbox,
        dataTypeOptions: { showStateLabel: true, labelStateFalse: "NO", labelStateTrue: "YES" },
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.enum,
        readonlyPredicate: disableFieldPredicate,
      },
    ];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
