import { Injectable } from "@angular/core";
import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";

@Injectable({
  providedIn: "root",
})
export class IdleService {
  idleState = "";
  timedOut = false;

  constructor(public idle: Idle) {
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    idle.onIdleStart.subscribe(() => {
      if (!this.timedOut) this.idleState = "You've gone idle!";
    });
    idle.onTimeoutWarning.subscribe((countdown: number) => {
      if (!this.timedOut) this.idleState = "You will time out in " + countdown + " seconds!";
    });
    idle.onIdleEnd.subscribe(() => (this.idleState = ""));
  }

  reset() {
    this.idle.watch();
    this.idleState = "";
    this.timedOut = false;
  }

  public stop() {
    this.idle.stop();
    this.idleState = "";
    this.timedOut = true;
  }
}
