<p-dialog
  [modal]="true"
  header="Missing Data"
  [(visible)]="dialogVisible"
  (onHide)="onCloseDialog(); onDialogHide.emit($event)"
  appendTo="body"
>
  <div class="dialog-validation-errors">
    <p>Please review the following incomplete fields:</p>
    <section *ngFor="let stepErrors of validationMessages">
      <h3>{{ stepErrors.stepCaption }}</h3>
      <ul>
        <li *ngFor="let errorMessage of stepErrors.messages">
          <span *ngIf="errorMessage.fieldName" class="field-name">{{ _toSentenceCase(errorMessage.fieldName) }}:</span
          ><span class="validation-error">{{ errorMessage.validationError }}</span>
          <span *ngIf="!errorMessage.fieldName">{{ errorMessage }}</span>
        </li>
      </ul>
    </section>
    <div class="col-12 buttonsWrapper">
      <button pButton label="OK" class="p-button-secondary" (click)="onCloseDialog()"></button>
    </div>
  </div>
</p-dialog>
