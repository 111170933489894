/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CommercialAccount } from "../../core/contact-accounts/commercial-account/commercial-account";
import { PersonalAccount } from "../../core/contact-accounts/personal-account/personal-account";
import { Inventory } from "../../core/inventory/inventory/inventory";
import { DBService } from "./db.service";
import * as _ from "lodash";

@Injectable()
export class DBSearchService extends DBService {
  searchInventory({
    searchValue,
    _filter,
    _offset,
    _limit,
    _sortField,
    _sortOrder,
    extraQueryParams = {},
  }: {
    searchValue: string;
    _filter?: string;
    _offset?: number;
    _limit?: number;
    _sortField?: string;
    _sortOrder?: number;
    extraQueryParams?: {};
  }): Observable<{ count: number; rows: Inventory[] }> {
    const params = this._buildRequestParams(_filter, null, _offset, _limit, _sortField, _sortOrder);
    _.defaults(params, extraQueryParams);

    return this._getRequest<any[]>(`${this.webUrl}searchInventory/${searchValue}`, params).pipe(
      map((response: any) => {
        return {
          count: parseInt(response.count),
          rows: <Inventory[]>response.rows,
        };
      })
    );
  }

  searchPersonalAccount({
    searchValue,
    _filter,
    _offset,
    _limit,
    _sortField,
    _sortOrder,
    extraQueryParams = {},
  }: {
    searchValue: string;
    _filter?: string;
    _offset?: number;
    _limit?: number;
    _sortField?: string;
    _sortOrder?: number;
    extraQueryParams?: {};
  }): Observable<{ count: number; rows: PersonalAccount[] }> {
    const params = this._buildRequestParams(_filter, null, _offset, _limit, _sortField, _sortOrder);
    // Add extra params if not present in regular params
    _.defaults(params, extraQueryParams);

    return this._getRequest<any[]>(`${this.webUrl}searchAccount/personal/${searchValue}`, params).pipe(
      map((response: any) => {
        const account = response.personalAccount;
        return {
          count: parseInt(account.count),
          rows: <PersonalAccount[]>account.rows,
        };
      })
    );
  }

  searchCommecialAccount({
    searchValue,
    _filter,
    _offset,
    _limit,
    _sortField,
    _sortOrder,
    extraQueryParams = {},
  }: {
    searchValue: string;
    _filter?: string;
    _offset?: number;
    _limit?: number;
    _sortField?: string;
    _sortOrder?: number;
    extraQueryParams?: {};
  }): Observable<{ count: number; rows: CommercialAccount[] }> {
    const params = this._buildRequestParams(_filter, null, _offset, _limit, _sortField, _sortOrder);
    // Add extra params if not present in regular params
    _.defaults(params, extraQueryParams);

    return this._getRequest<any[]>(`${this.webUrl}searchAccount/commercial/${searchValue}`, params).pipe(
      map((response: any) => {
        const account = response.commercialAccount;
        return {
          count: parseInt(account.count),
          rows: <CommercialAccount[]>account.rows,
        };
      })
    );
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
