/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn } from "@angular/forms";
import { MenuItem, SelectItem } from "primeng/api";
import { Observable, Subject } from "rxjs";
import { tap, take, map } from "rxjs/operators";
import { Tender_Type } from "src/app/core/settings/business-settings/tender-type/tender-type";
import { TaxRule } from "src/app/core/tax/tax-rule/tax-rule";
import { DBTaxService } from "src/app/shared/services/db-tax.service";
import {
  Col,
  DataType,
  ExecuteEventDatatypeOptions,
  ExecuteEvent_DisplayMode,
  FilterType,
} from "../../../../form-list/form-list/form-list";
import { FormListModel } from "../../../../form-list/form-list/form-list-model.interface";
import { AuthService } from "../../../../shared/services/auth.service";
import { CurrencyFormatterService } from "../../../../shared/services/currency-formatter.service";
import { DBService, ExtendedSelectItem, LookupOptions } from "../../../../shared/services/db.service";
import { Doc, DocState, SaleDocType } from "../../doc/doc";
import { SaleDocLineTax } from "../sale-doc-line-tax/sale-doc-line-tax";
import { DeliveryStatus, FulfillmentStatus, PaymentStatus, SaleDoc, SaleDocSource } from "./sale-doc";
import { ChannelType } from "src/app/core/sale-channel/sale-channel";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { SaledocRevisitPreviewComponent } from "../saledoc-revisit-preview/saledoc-revisit-preview.component";
import { DeliveryMethod } from "src/app/core/sale-channel/sale-channel-orders/sale-channel-order";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { Repeat } from "./../sale-doc-line/sale-doc-line";
import { FrequencyName } from "src/app/core/inventory/inventory-usage-detail/inventory-usage-detail";
import _ from "lodash";
import { Account } from "src/app/core/contact-accounts/account/account";
import { AccountDialogWrapperComponent } from "src/app/core/contact-accounts/account-dialog-wrapper/account-dialog-wrapper.component";

@Injectable({
  providedIn: "root",
})
export class SaleDocService extends DBService implements FormListModel {
  private showSaleDocpreview = new EventEmitter<SaleDoc>();
  private showAccountInfo = new EventEmitter<Account>();

  private previewPopupClosedEvent = new Subject<void>();
  previewPopupClosedEvent$ = this.previewPopupClosedEvent.asObservable();
  transactionAreaDisabled = false;

  _model = "saleDoc";
  _inventoryId = 0;
  enum_doc_states = this.enumSelectOptions(DocState);
  enum_doc_types = this.enumSelectOptions(SaleDocType, {
    useKeyAsLabel: false,
    useNullAsValue: true,
    emptyRowCaption: "All Doc Types",
    emptyRowValue: 0,
  });
  enum_tenderTypes = this.enumSelectOptions(Tender_Type);
  enum_saleDocSource = this.enumSelectOptions(SaleDocSource);
  enum_payment_statuses = this.enumSelectOptions(PaymentStatus);
  enum_channelType = this.enumSelectOptions(ChannelType);
  enum_fulfillmentStatus = this.enumSelectOptions(FulfillmentStatus);
  enum_delivery_methods = this.enumSelectOptions(DeliveryMethod);
  enum_delivery_status = this.enumSelectOptions(DeliveryStatus);
  enum_repeat = this.enumSelectOptions(Repeat);
  enum_frequency_name_options: SelectItem[] = this.enumSelectOptions(FrequencyName);
  lookup_recurringOrderSetting;
  protected lookup_tender_types: SelectItem[];
  _disableCloning = true;

  constructor(
    private currencyFormatterService: CurrencyFormatterService,
    private dialogService: DialogService,
    public ref: DynamicDialogRef,
    protected http: HttpClient,
    protected authService: AuthService,
    private dbTaxService: DBTaxService,
    private fb: UntypedFormBuilder,
    protected appSettings: AppSettingsStorageService
  ) {
    super(http, authService);
    this.showSaleDocpreview.subscribe((saleDoc: SaleDoc) => {
      if (saleDoc.id) {
        this.getRow("saleDoc", saleDoc.id).subscribe((_saleDoc) => {
          this.ref = this.dialogService.open(SaledocRevisitPreviewComponent, {
            data: {
              _object: _saleDoc,
              _orgSaleDoc: saleDoc,
            },
            width: "90%",
            height: "90%",
            closable: false,
            showHeader: false,
          });
          this.ref.onClose.subscribe((_saleDoc: SaleDoc) => {
            if (_saleDoc) {
              saleDoc.paymentStatus = _saleDoc.paymentStatus;
              saleDoc.fulfillmentStatus = _saleDoc.fulfillmentStatus;
              saleDoc.deliveryStatus = _saleDoc.deliveryStatus;
              saleDoc.doc.state = _saleDoc.doc.state;
              saleDoc.doc.voidReason = _saleDoc.doc.voidReason;
            }
            this.previewPopupClosedEvent.next();
          });
        });
      }
    });
    this.showAccountInfo.subscribe((saleDoc: SaleDoc) => {
      if (saleDoc.personalAccount || saleDoc.commercialAccount) {
        this.ref = this.dialogService.open(AccountDialogWrapperComponent, {
          data: {
            account: saleDoc.personalAccount || saleDoc.commercialAccount,
            accountType: saleDoc,
          },
          width: "90%",
          height: "90%",
          closable: false,
          showHeader: false,
        });
      }
    });
  }

  postEmailReceipt(saleDocId: number, emailAddress: string, html: string, stylesheet: string) {
    return this._postRequest<any>(this.webUrl + "emailReceipt/" + saleDocId, {
      email: emailAddress,
      html,
      stylesheet,
    });
  }

  getValidationRules(): { [key: string]: {} | ValidatorFn[] } {
    return {};
  }

  getLookup_tenderTypes(): Observable<SelectItem[]> {
    return this.lookupMultiSelectOptions("tenderType", "description").pipe(
      tap((items: SelectItem[]) => {
        this.lookup_tender_types = items;
      })
    );
  }

  lookupRecurringOrderSetting(
    _options: LookupOptions = { dataKey: "id", emptyRowCaption: "", emptyRowValue: null }
  ): Observable<ExtendedSelectItem[]> {
    if (_.get(_options, "dataKey") === undefined) {
      _options.dataKey = "id";
    }
    if (_.get(_options, "emptyRowCaption") === undefined) {
      _options.emptyRowCaption = "";
    }
    if (_.get(_options, "emptyRowValue") === undefined) {
      _options.emptyRowValue = null;
    }

    return this.getRows("recurringOrderSetting", _.get(_options, "lookupFilter") || "", 0, 500).pipe(
      take(1),
      map((myObjects: any) => {
        const lookupOptions: ExtendedSelectItem[] = [
          { label: _options.emptyRowCaption, value: _options.emptyRowValue },
        ];
        // const lookupOptions: SelectItem[] = [];
        myObjects.rows.forEach((myObject: any) => {
          const discount = Math.round(_.get(myObject, "recuringDiscountPercent"));
          const recurringOrderFrequency = _.get(myObject, "recurringOrderFrequency");
          lookupOptions.push({
            label: `${recurringOrderFrequency} - Save ${discount}%`,
            value: _.get(_options, "dataKey") ? _.get(myObject, _.get(_options, "dataKey")) : myObject,
            // value: _lookupOptions?._dataKey ? _.get(myObject, _lookupOptions._dataKey) : myObject,
            disabled: _.get(_options, "enableFieldName") ? !_.get(myObject, _.get(_options, "enableFieldName")) : false,
            icon:
              _.get(_options, "icon") !== undefined
                ? _.get(_options, "icon")
                : (_.get(_options, "enableFieldName") ? !_.get(myObject, _.get(_options, "enableFieldName")) : false)
                ? "pi pi-lock"
                : "",
            object: myObject,
          });
        });
        return lookupOptions;
      })
    );
  }

  updateSaleTaxesInLineForm(
    lineForm: UntypedFormGroup,
    storeId,
    inStore: boolean,
    accountId,
    taxAccountCategoryId,
    saleDoc
  ): Observable<any> {
    const inventoryId = lineForm.get("docLine.inventoryId").value;
    const qty = lineForm.get("qty").value;
    const salePrice = lineForm.get("salePrice").value;

    return this.dbTaxService
      .calculateSaleTaxLine(
        inventoryId,
        salePrice,
        qty,
        storeId,
        inStore,
        accountId,
        taxAccountCategoryId,
        saleDoc.doc.docDate
      )
      .pipe(
        // catchError(error => {
        //   const lineDescription = lineForm.get('description').value;
        //   alert(`Error: Couldn't obtain tax for line ${lineDescription} [Inventory ID: ${inventoryId}]`);
        // }),
        tap((taxRules: TaxRule[]) => {
          const lineTaxes: SaleDocLineTax[] = [];
          taxRules.forEach((taxRule) => {
            lineTaxes.push({
              id: 0, // set as new tax line
              amount: taxRule.taxAmount,
              taxRuleId: taxRule.id,
              taxRule: taxRule,
            });
          });
          // Update form with new tax rules
          lineForm.setControl("saleDocLineTaxes", this.fb.array(lineTaxes.map((taxLine) => this.fb.group(taxLine))));
        })
      );
  }

  afterFetch(saleDoc: SaleDoc, model) {
    switch (model) {
      case this._model:
        if (saleDoc.saleDocLines && saleDoc.saleDocLines.length) {
          saleDoc.saleDocLines.forEach((saleLine) => {
            saleLine.discountAmount = this.currencyFormatterService.formatMonetaryAmount({
              currencyIsoCode: saleDoc.doc.currencyIsoCode,
              amount: saleLine.discountAmount,
            });
          });
        }
        if (this._inventoryId) {
          saleDoc["searchedInventoryId"] = this._inventoryId;
        }
        break;
    }
  }

  isActionDisabled = (rowData: SaleDoc): boolean => {
    return rowData.doc.state === DocState.voided;
  };

  isVoidLinkDisabled = (rowData: SaleDoc): boolean => {
    return (
      rowData.doc.state === DocState.voided ||
      rowData.cashout?.isClosed === true ||
      rowData.saleDocTenders.some((sdt) => sdt.tenderType?.type === Tender_Type.Store_Voucher)
    );
  };

  getTakuDocLinkCaption(rowData: SaleDoc): string {
    return rowData?.doc?.docNo || "View";
  }

  getAccountNameCaption(rowData: SaleDoc): string {
    return rowData ? rowData.accountName : "";
  }

  getFormListColumns(params?: {
    printItems: (rowData: SaleDoc) => MenuItem[];
    emailDocumentEvent: EventEmitter<any>;
    voidDocumentEvent: EventEmitter<any>;
  }): Col[] {
    const cols: Col[] = [
      {
        field: "doc.docNo",
        header: "Doc #",
        visible: true,
        readonly: true,
        frozen: true,
        dataType: DataType.execute_event,
        dataOptions: [],
        dataTypeOptions: [
          new ExecuteEventDatatypeOptions({
            displayMode: ExecuteEvent_DisplayMode.LINK,
            label: this.getTakuDocLinkCaption.bind(this),
            event: this.showSaleDocpreview,
            enabledOnEditMode: false,
            styleClass: "",
          }),
        ],
        filterType: FilterType.contains,
        colWidth: 90,
      },
      {
        field: "accountName",
        header: "Account",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.execute_event,
        dataOptions: [],
        dataTypeOptions: [
          new ExecuteEventDatatypeOptions({
            displayMode: ExecuteEvent_DisplayMode.LINK,
            label: this.getAccountNameCaption.bind(this),
            event: this.showAccountInfo,
            enabledOnEditMode: false,
            styleClass: "",
          }),
        ],
        filterType: FilterType.none,
        isNotSortable: true,
        colWidth: 100,
      },

      // { field: 'doc.state', header: 'Status', visible: true, readonly: true, frozen: false, dataType: DataType.enum, dataOptions: this.enum_doc_states, filterType: FilterType.none, colWidth: 90 },
      {
        field: "dailyNo",
        header: "Daily #",
        visible: false,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 105,
      },
      {
        field: "doc.docClosingDate",
        header: "Date",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.date_date_only,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 95,
      },
      {
        field: "doc.docClosingTime",
        header: "Time",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.date_time,
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 80,
        sortFields: ["doc.docClosingDate", "doc.docClosingTime"],
      },
      {
        field: "store.storeName",
        header: "Store Name",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 200,
      },
      {
        field: "customerSource",
        header: "Source",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enum_saleDocSource,
        filterType: FilterType.enum,
        colWidth: 200,
      },
      {
        field: "doc.docType",
        header: "Doc Type",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enum_doc_types,
        filterType: FilterType.enum,
        colWidth: 120,
      },
      {
        field: "saleChannel.channelType",
        header: "Channel Type",
        visible: false,
        readonly: true,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enum_channelType,
        filterType: FilterType.enum,
        colWidth: 200,
      },
      {
        field: "station.stationName",
        header: "Station Name",
        visible: false,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 200,
      },
      {
        field: "grandTotalCurrencyFormat",
        header: "Total",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.none,
        isNotSortable: true,
        colWidth: 100,
      },
      // { field: 'grandTotal', header: 'Total', visible: true, readonly: true, frozen: false, dataType: DataType.input, dataOptions: [], filterType: FilterType.contains, colWidth:  },
      {
        field: "tenderTypesList",
        header: "Pay Types",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.enumMultiselect,
        isNotSortable: true,
        colWidth: 150,
        filterOptions: this.enum_tenderTypes,
        filterField: "saleDocTenders.tenderType.type",
      },
      {
        field: "paymentStatus",
        header: "Payment Status",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enum_payment_statuses,
        filterType: FilterType.enum,
        colWidth: 200,
        classObj: "status-margin",
      },
      {
        field: "fulfillmentStatus",
        header: "Fulfillment Status",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enum_fulfillmentStatus,
        filterType: FilterType.enum,
        colWidth: 200,
        classObj: "status-margin",
      },
      // { field: 'accountName', header: 'Account', visible: true, readonly: true, frozen: false, dataType: DataType.input, dataOptions: [], filterType: FilterType.none, isNotSortable: true, colWidth: 100 },
      {
        field: "doc.user.userName",
        header: "User",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 120,
      },
      {
        field: "deliveryMethod",
        header: "Delivery Method",
        visible: false,
        readonly: true,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enum_delivery_methods,
        filterType: FilterType.enum,
        colWidth: 120,
      },
      {
        field: "deliveryStatus",
        header: "Delivery Status",
        visible: false,
        readonly: true,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enum_delivery_status,
        filterType: FilterType.enum,
        colWidth: 120,
        classObj: "status-margin",
      },
    ];
    if (params) {
      // Add columns for voiding doing only if event was provided
      const _dataTypeOptions = [];
      let _colWidth = 141; // was 130 before
      _dataTypeOptions.push(
        new ExecuteEventDatatypeOptions({
          icon: "pi pi-print",
          event: null,
          displayMode: ExecuteEvent_DisplayMode.SPLITBUTTON,
          fnIsRowDisabled: this.isActionDisabled,
          items: params.printItems,
          styleClass: "row-button",
        })
      );
      if (this.appSettings.getStore().id) {
        _dataTypeOptions.push(
          new ExecuteEventDatatypeOptions({
            icon: "pi pi-envelope",
            event: params.emailDocumentEvent,
            displayMode: ExecuteEvent_DisplayMode.BUTTON,
            fnIsRowDisabled: this.isActionDisabled,
            styleClass: "row-button",
          })
        );
        _colWidth += 40;
      }
      _dataTypeOptions.push(
        new ExecuteEventDatatypeOptions({
          icon: "pi pi-ban",
          event: params.voidDocumentEvent,
          displayMode: ExecuteEvent_DisplayMode.BUTTON,
          fnIsRowDisabled: this.isVoidLinkDisabled,
          styleClass: "row-button",
        })
      );
      cols.unshift({
        field: "deleteButton",
        header: "",
        visible: true,
        readonly: true,
        frozen: true,
        dataType: DataType.execute_event,
        dataTypeOptions: _dataTypeOptions,
        dataOptions: [],
        filterType: FilterType.none,
        isNotSortable: true,
        colWidth: _colWidth,
      });
    }
    return cols;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
