/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

export enum Transaction_Type {
  Sales_Charge = "Sales Charge",
  Sales_Payback = "Sales Payback",
  Sales_Returns = "Sales Returns",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
