/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Input } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { NestedFormComponent } from "../../../forms/nested-form/nested-form.component";
import { AddressEmailType, AddressEmail } from "./address-email";
import { ACTION_TYPE } from "src/app/shared/components/action-button/action-type.enum";

@Component({
  selector: "taku-address-email",
  templateUrl: "./address-email.component.html",
  styleUrls: ["./address-email.component.scss"],
})
export class AddressEmailComponent extends NestedFormComponent {
  @Input() disableForm?: boolean = false;
  public ACTION_TYPE: typeof ACTION_TYPE = ACTION_TYPE;

  enum_person_email_type = this.dbService.enumSelectOptions(AddressEmailType);

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    const addressEmail_Group = fb.group(new AddressEmail());
    return addressEmail_Group;
  }

  static set(fb: UntypedFormBuilder, adressEmail: AddressEmail): UntypedFormGroup {
    const addressEmail_Group = fb.group(adressEmail);
    return addressEmail_Group;
  }

  static setArray(fb: UntypedFormBuilder, addressEmails: AddressEmail[]) {
    const formArray = fb.array([]);
    addressEmails.map((AddressEmail) => {
      const temp = fb.group(AddressEmail);
      formArray.push(temp);
    });
    return formArray;
  }

  initValidation() {
    this._validation = {
      email: [Validators.email],
    };
  }

  copyEmailToClipboard() {
    this.copyValueToClipboard(this.emailCtrl.value);
  }

  get emailCtrl() {
    return this._myForm.get("email");
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
