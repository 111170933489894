/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, ElementRef, HostListener, Input, NgZone, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, AbstractControl } from "@angular/forms";
import * as moment from "moment";
import { SelectItem } from "primeng/api";
import { DomHandler } from "primeng/dom";
import { WebHelpers } from "src/app/utility/WebHelpers";
import { Calendar } from "primeng/calendar";
import { Subscription } from "rxjs";

export enum CalendarDateRangeType {
  TODAY = "Today",
  YESTERDAY = "Yesterday",
  THIS_WEEK = "This week",
  LAST_WEEK = "Last week",
  THIS_MONTH = "This month",
  LAST_MONTH = "Last month",
  THIS_QUARTER = "This quarter",
  LAST_QUARTER = "Last quarter",
  THIS_YEAR = "This year",
  LAST_YEAR = "Last year",
  CUSTOM = "Custom period",
}

export enum CalendarOverlayPosition {
  LEFT_POS,
  RIGHT_POS,
}

class DateRangeCalendar {
  type: CalendarDateRangeType;
  dateFrom: Date;
  dateTo: Date;
}

// class DateRangeSelectItem implements SelectItem {
//   value: DateRangeCalendar;
//   styleClass?: string;
//   icon?: string;
//   title?: string;
//   disabled?: boolean;
//   label: string;
// }

@Component({
  selector: "taku-taku-calendar-range",
  templateUrl: "./taku-calendar-range.component.html",
  styleUrls: ["./taku-calendar-range.component.scss"],
})
export class TakuCalendarRangeComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];

  @Input() formGroup: UntypedFormGroup;
  @Input() fieldCaption: string;
  @Input() fieldNameRangeType: string;
  @Input() fieldNameDateFrom: string;
  @Input() fieldNameDateTo: string;
  @Input() overlayPosition: CalendarOverlayPosition = CalendarOverlayPosition.RIGHT_POS;
  @Input() disabled?: boolean = false;
  @Input() showTimeRange = true;
  @Input() maxDateValue = new Date();
  @ViewChild("calendarOverlay", { read: ElementRef }) calendarOverlayEl: ElementRef;
  @ViewChild("calendarWrapper", { read: ElementRef }) calendarWrapperEl: ElementRef;
  @ViewChild(Calendar, { static: true }) calendarComponent: Calendar;
  _componentFormGroup: UntypedFormGroup;
  _lookup_datePeriods: SelectItem[];

  OverlayPosition = CalendarOverlayPosition;
  DateRangeType = CalendarDateRangeType;

  // Model for calendar component
  rangeDates: Date[] = [moment().startOf("day").toDate(), moment().endOf("day").toDate()];
  rangeTimeFrom: Date = moment().startOf("day").toDate();
  rangeTimeTo: Date = moment().endOf("day").toDate();

  // Options for ListBox component
  datePeriodsList: DateRangeCalendar[] = [];
  // Model for ListBox component
  validDateRegexp = /\d{4}-\d{2}-\d{2}/;
  dateInputMask = "9999-99-99";
  isOverlayOpen = false;
  fieldErrorMessages: { [key: string]: any } = {};
  _overlayOffset = 0;
  _prevVisibleMonths: number;
  _calendarVisibleMonths: number;

  constructor(private ngZone: NgZone, private fb: UntypedFormBuilder, protected elRef: ElementRef) {}

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.ngZone.run(() => {
      this._overlayOffset = this.calculateOverlayOffset();
      this._calendarVisibleMonths = this.calculateVisibleMonths();
      if (this._prevVisibleMonths && this._prevVisibleMonths !== this._calendarVisibleMonths) {
        this.isOverlayOpen = false;
        setTimeout(() => {
          this.calendarComponent.ngOnInit();
        }, 0);
      }
      this._prevVisibleMonths = this._calendarVisibleMonths;
    });
  }

  @HostListener("window:click", ["$event"])
  closeOverlayOnOutsideClick(e: MouseEvent) {
    if (!this.elRef.nativeElement.contains(event.target))
      // or some similar check
      this.isOverlayOpen = false;
  }

  private calculateVisibleMonths() {
    return WebHelpers.isMobileScreen() ? 1 : 2;
  }

  get selectedDatePeriod(): DateRangeCalendar {
    return this.datePeriodsList.find((period) => period.type === this.ctrlDatePeriod.value);
  }

  get ctrlDatePeriod(): AbstractControl {
    return this._componentFormGroup.get("datePeriod");
  }

  get isMobile() {
    return WebHelpers.isMobileScreen();
  }

  ngOnInit() {
    this._prevVisibleMonths = this._calendarVisibleMonths = this.calculateVisibleMonths();
    this._initPeriodList();

    const initialPeriod = this.formGroup.get(this.fieldNameRangeType).value;
    this._componentFormGroup = this.fb.group({
      datePeriod: initialPeriod ? initialPeriod : CalendarDateRangeType.TODAY,
    });

    // Create form controls for range type selection on mobile screens
    this._lookup_datePeriods = this.datePeriodsList.map(
      (period) =>
        <SelectItem>{
          label: period.type,
          value: period.type,
        }
    );

    this.subsList.push(
      this.ctrlDatePeriod.valueChanges.subscribe((datePeriodType: CalendarDateRangeType) => {
        this.datePeriodChanged(this.selectedDatePeriod);
      })
    );

    // update form accordingly
    this.formGroup.patchValue({
      [this.fieldNameRangeType]: this.selectedDatePeriod.type,
      [this.fieldNameDateFrom]: this.selectedDatePeriod.dateFrom,
      [this.fieldNameDateTo]: this.selectedDatePeriod.dateTo,
    });
    this.datePeriodChanged(this.selectedDatePeriod);
  }

  private _initPeriodList() {
    const now = moment();
    const yesterday = moment().subtract(1, "days");
    const lastWeek = moment().subtract(1, "week");
    const lastMonth = moment().subtract(1, "month");
    const lastQuarter = moment().subtract(1, "quarter");
    const lastYear = moment().subtract(1, "year");

    this.datePeriodsList = [
      {
        type: CalendarDateRangeType.TODAY,
        dateFrom: now.startOf("day").toDate(),
        dateTo: now.endOf("day").toDate(),
      },
      {
        type: CalendarDateRangeType.YESTERDAY,
        dateFrom: yesterday.startOf("day").toDate(),
        dateTo: yesterday.endOf("day").toDate(),
      },
      {
        type: CalendarDateRangeType.THIS_WEEK,
        dateFrom: moment(now).startOf("week").toDate(),
        dateTo: moment(now).endOf("week").toDate(),
      },
      {
        type: CalendarDateRangeType.LAST_WEEK,
        dateFrom: moment(lastWeek).startOf("week").toDate(),
        dateTo: moment(lastWeek).endOf("week").toDate(),
      },
      {
        type: CalendarDateRangeType.THIS_MONTH,
        dateFrom: moment(now).startOf("month").toDate(),
        dateTo: moment(now).endOf("month").toDate(),
      },
      {
        type: CalendarDateRangeType.LAST_MONTH,
        dateFrom: moment(lastMonth).startOf("month").toDate(),
        dateTo: moment(lastMonth).endOf("month").toDate(),
      },
      {
        type: CalendarDateRangeType.THIS_QUARTER,
        dateFrom: moment(now).startOf("quarter").toDate(),
        dateTo: moment(now).endOf("quarter").toDate(),
      },
      {
        type: CalendarDateRangeType.LAST_QUARTER,
        dateFrom: moment(lastQuarter).startOf("quarter").toDate(),
        dateTo: moment(lastQuarter).endOf("quarter").toDate(),
      },
      {
        type: CalendarDateRangeType.THIS_YEAR,
        dateFrom: moment(now).startOf("year").toDate(),
        dateTo: moment(now).endOf("year").toDate(),
      },
      {
        type: CalendarDateRangeType.LAST_YEAR,
        dateFrom: moment(lastYear).startOf("year").toDate(),
        dateTo: moment(lastYear).endOf("year").toDate(),
      },
      {
        type: CalendarDateRangeType.CUSTOM,
        dateFrom: this.formGroup.get("dateFrom").value,
        dateTo: this.formGroup.get("dateTo").value,
      },
    ];
  }

  datePeriodChanged(newDatePeriod: DateRangeCalendar) {
    if (newDatePeriod.type == CalendarDateRangeType.CUSTOM)
      // If custom dont' reset dates, use current ones
      return;

    this.rangeDates = [newDatePeriod.dateFrom, newDatePeriod.dateTo];

    this.rangeTimeFrom = new Date(
      this.rangeDates[0].getFullYear(),
      this.rangeDates[0].getMonth(),
      this.rangeDates[0].getDate(),
      this.rangeDates[0].getHours(),
      this.rangeDates[0].getMinutes(),
      this.rangeDates[0].getSeconds()
    );
    this.rangeTimeTo = new Date(
      this.rangeDates[1].getFullYear(),
      this.rangeDates[1].getMonth(),
      this.rangeDates[1].getDate(),
      this.rangeDates[1].getHours(),
      this.rangeDates[1].getMinutes(),
      this.rangeDates[1].getSeconds()
    );
  }

  private convertInputToDate(dateString: string): Date {
    if (!this.validDateRegexp.test(dateString)) return null;

    const date = moment(dateString);
    if (date == null || !date.isValid() || date.isAfter(this.maxDateValue)) throw new Error("date"); // means invalid date error type

    return date.endOf("day").toDate();
  }

  onInputDateFromChanged(dateString: string) {
    const fieldName = "dateFrom";
    try {
      const newDate = this.convertInputToDate(dateString);
      if (newDate) {
        if (moment(newDate).isAfter(this.rangeDates[1])) throw new Error("dateRangeStart");

        this.rangeDates[0] = newDate;
        this._setToCustomPeriod();
      }
      this.fieldErrorMessages[fieldName] = null;
    } catch (e) {
      const error: Error = e;
      // Error message can be of different types
      this.fieldErrorMessages[fieldName] = { [error.message]: null };
    }
  }

  onInputDateToChanged(dateString: string) {
    const fieldName = "dateTo";
    try {
      const newDate = this.convertInputToDate(dateString);
      if (newDate) {
        if (moment(newDate).isBefore(this.rangeDates[0])) throw new Error("dateRangeEnd");

        this.rangeDates[1] = newDate;
        this._setToCustomPeriod();
      }
      this.fieldErrorMessages[fieldName] = null;
    } catch (e) {
      const error: Error = e;
      // Error message can be of different types
      this.fieldErrorMessages[fieldName] = { [error.message]: null };
    }
  }

  onApplySelectedDates() {
    if (this.showTimeRange) {
      this.rangeDates[0].setHours(this.rangeTimeFrom.getHours());
      this.rangeDates[0].setMinutes(this.rangeTimeFrom.getMinutes());
      this.rangeDates[0].setSeconds(this.rangeTimeFrom.getSeconds());

      this.rangeDates[1].setHours(this.rangeTimeTo.getHours());
      this.rangeDates[1].setMinutes(this.rangeTimeTo.getMinutes());
      this.rangeDates[1].setSeconds(this.rangeTimeTo.getSeconds());
    }

    this.formGroup.patchValue({
      [this.fieldNameRangeType]: this.selectedDatePeriod.type,
      [this.fieldNameDateFrom]: this.rangeDates[0],
      [this.fieldNameDateTo]: this.rangeDates[1],
    });

    this.isOverlayOpen = false;
  }

  monthChanged(change: { month: number; year: number }) {
    // Not ideal, but this prevents the popup from closing when the month view is changed, using the previous/next buttons.
    event.stopPropagation();
  }

  // Called when calendar is changing dates
  datesOnCalendarChanged(calendarDates: Date[]) {
    this.rangeDates[0] = calendarDates[0];
    this.rangeDates[1] = moment(calendarDates[1] ? calendarDates[1] : calendarDates[0])
      .endOf("day")
      .toDate();

    this._setToCustomPeriod();
  }

  private _setToCustomPeriod() {
    // this.selectedDatePeriod = this.datePeriodsList.find(period => period.type == CalendarDateRangeType.CUSTOM);
    this.ctrlDatePeriod.setValue(CalendarDateRangeType.CUSTOM);
  }

  openCalendarOverlay() {
    this.isOverlayOpen = !this.isOverlayOpen;
    setTimeout(() => {
      this._overlayOffset = this.calculateOverlayOffset();
    }, 0);
  }

  calculateOverlayOffset() {
    let overlayDiff = 0;
    const lateralSpacing = 6;
    if (this.calendarOverlayEl) {
      const overlayWidth = DomHandler.getWidth(this.calendarOverlayEl.nativeElement);

      if (this.overlayPosition === CalendarOverlayPosition.LEFT_POS) {
        const targetPosOffset =
          DomHandler.getOffset(this.calendarWrapperEl.nativeElement).left +
          DomHandler.getWidth(this.calendarWrapperEl.nativeElement);
        overlayDiff = targetPosOffset - overlayWidth;
      } else {
        const targetPosOffset = DomHandler.getOffset(this.calendarWrapperEl.nativeElement).left;
        overlayDiff = document.body.clientWidth - (targetPosOffset + overlayWidth);
      }
    }

    return overlayDiff < 0 ? Math.round(overlayDiff - lateralSpacing) : 0;
  }

  get isSingleDay() {
    const dateFrom = this.formGroup.get(this.fieldNameDateFrom).value;
    const dateTo = this.formGroup.get(this.fieldNameDateTo).value;

    // if start and end time are in the same day
    return moment(dateFrom).isSame(dateTo, "day");
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
