/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as _ from "lodash";
import { Observable, of, throwError } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { DBService } from "../../../../shared/services/db.service";
import { GMBHelpers } from "../../../../utility/GMBHelpers";
import { Address } from "../../../contact-accounts/address/address";
import { Store } from "../../store-settings/store/store";
import { GMBVerificationState } from "./gmb-accounts-list/GMBGoogleAccount";
import { GMBLocation, GMBLocationVerificationStatus } from "./gmb-locations-list/GMBLocation";

@Injectable({
  providedIn: "root",
})
export class BackendGMBService extends DBService {
  // private _lookup_gmbGoogleAccounts: SelectItem[];

  // getLookup_gmbGoogleAccounts():Observable<SelectItem[]>{
  //     if (this._lookup_gmbGoogleAccounts)
  //         return of(this._lookup_gmbGoogleAccounts)
  //     else
  //         return this.lookupSelectOptions("gmbGoogleAccount", "googleAccount.email").pipe(tap(response => {
  //             this._lookup_gmbGoogleAccounts = response;
  //         }));
  // }

  exchangeToken(authorizationCode: string) {
    return this._postRequest(this.webUrl + "gmbExchangeToken", {
      authorization_code: authorizationCode,
    });
  }

  isIntegrationEnabled(): Observable<boolean> {
    const filterActive = {
      isActive: { matchMode: "equals", value: true },
    };
    return this.getRows("gmbGoogleAccount", JSON.stringify(filterActive)).pipe(
      shareReplay(1),
      map((response: any) => response.rows && response.rows.length > 0)
    );
  }

  getLocations(gmbAccountId: number): Observable<any[]> {
    return this._getRequest(this.webUrl + "gmbLocations/" + gmbAccountId);
  }

  getCategories(gmbAccountId: number, searchTerm: string, regionCode: string, languageCode = "en") {
    const queryParams = { searchTerm, regionCode, languageCode };
    return this._getRequest<any>(this.webUrl + "gmbCategoriesList/" + gmbAccountId, queryParams);
  }

  getVerificationsList(gmbAccountId: number, locationName: string) {
    return this._getRequest<any>(this.webUrl + "gmbLocationVerificationStatus/" + gmbAccountId + "/" + locationName);
  }

  getPendingVerifications(gmbAccountId: number, locationName: string): Observable<any[]> {
    return this.getVerificationsList(gmbAccountId, locationName).pipe(
      map((response: any) => {
        if (!response || !response.verifications) return [];

        return response.verifications.filter((verification) => verification.state === GMBVerificationState.PENDING);
      })
    );
  }

  getAvailableVerificationOptions(
    gmbAccountId: number,
    locationName: string,
    languageCode = "en"
  ): Observable<{ options: [] }> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("action", "fetchVerificationOptions");

    const body = {
      languageCode,
    };

    return this._postRequest(this.webUrl + "gmbLocationVerify/" + gmbAccountId + "/" + locationName, body, queryParams);
  }

  getLocationGroups(gmbAccountId: number) {
    return this._getRequest<any>(this.webUrl + "gmbLocationGroups/" + gmbAccountId);
  }

  requestVerification(gmbAccountId: number, locationName: string, verificationData) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("action", "verify");

    return this._postRequest(
      this.webUrl + "gmbLocationVerify/" + gmbAccountId + "/" + locationName,
      verificationData,
      queryParams
    );
  }

  confirmVerification(gmbAccountId: number, verificationName: string, verificationData): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("action", "complete");

    return this._postRequest(
      this.webUrl + "gmbLocationVerify/" + gmbAccountId + "/" + verificationName,
      verificationData,
      queryParams
    );
  }

  buildDbLocationFromGoogle(gmbAccountId: number, location): GMBLocation {
    const dbGMBLocation = Object.assign({}, new GMBLocation(), <GMBLocation>{
      name: location.name,
      storeCode: location.storeCode,
      locationName: location.locationName,
      primaryCategory: location.primaryCategory,
      additionalCategories: location.additionalCategories || [],
      description: location.profile ? location.profile.description : "",
      locationAddress: location.address ? GMBHelpers.PreviewGMBLocationAddress(location.address) : "",
      contentLanguage: location.languageCode,
      accountName: location.accountName,
      gmbAccountName: location.gmbAccountName,
      gmbAccountId,
    });

    // this.addDynamicGMBFields(dbGMBLocation, location);

    return dbGMBLocation;
  }

  buildGMBLocationFromDb(dbGMBLocation: GMBLocation, takuStore: Store): any {
    const takuAddress = _.merge(new Address(), takuStore.address);
    // Remove id field from hours, otherwise GMB will complain
    const storeHours = takuStore.storeHours.map((hours) => {
      // only take recognized fields from server db
      return {
        openDay: hours.startDay,
        openTime: hours.openTime,
        closeDay: hours.endDay,
        closeTime: hours.closeTime,
      };
    });

    return {
      languageCode: dbGMBLocation.contentLanguage,
      storeCode: dbGMBLocation.storeCode,
      locationName: dbGMBLocation.locationName,
      primaryCategory: dbGMBLocation.primaryCategory,
      additionalCategories: dbGMBLocation.additionalCategories || [],
      primaryPhone: takuAddress.addressPhone.fullPhoneNumber,
      address: {
        revision: 0,
        regionCode: takuAddress.countryIsoCode,
        languageCode: dbGMBLocation.contentLanguage,
        postalCode: takuAddress.postalCode,
        administrativeArea: takuAddress.subDivisionIsoCode,
        locality: takuAddress.city,
        addressLines: [takuAddress.line1, takuAddress.line2],
      },
      websiteUrl: takuStore.url,
      regularHours: {
        periods: storeHours,
      },
    };
  }

  // Just Modify the pass object, don't create a new one
  addDynamicGMBFields$(gmbAccountId: number, dbGMBLocation: GMBLocation, location): Observable<boolean> {
    if (location.locationState.isVerified) {
      dbGMBLocation.verificationStatus = GMBLocationVerificationStatus.VERIFIED;
      return of(true);
    } else {
      return this.getPendingVerifications(gmbAccountId, location.name).pipe(
        map((verifications: any) => {
          dbGMBLocation.verificationStatus = verifications.length ? "Pending" : "Unverified";

          return true;
        })
      );
    }
  }

  createGMBLocation(gmbLocationId: number, dbGMBLocation: GMBLocation, takuStore: Store): Observable<any> {
    const body = this.buildGMBLocationFromDb(dbGMBLocation, takuStore);
    return this._postRequest(this.webUrl + "gmbLocations/" + gmbLocationId, body);
  }

  updateGMBLocation(gmbLocationId: number, dbGMBLocation: GMBLocation, takuStore: Store): Observable<any> {
    if (!dbGMBLocation.name) {
      return throwError(() => new Error("Cannot update location: 'name' field for location cannot be empty"));
    }

    const body = this.buildGMBLocationFromDb(dbGMBLocation, takuStore);
    body.name = dbGMBLocation.name;
    return this._patchRequest(this.webUrl + "gmbLocations/" + gmbLocationId, body);
  }

  updateGMBLocationStoreInfo(dbGMBLocation: GMBLocation, takuStore: Store, gmbLocation: any): GMBLocation {
    takuStore = _.merge(new Store(), takuStore);
    dbGMBLocation.locationAddress = takuStore.addressPrettyPrint;
    if (gmbLocation.name) dbGMBLocation.name = gmbLocation.name;

    return dbGMBLocation;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
