/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { InventoryImage } from "./inventory-image";
import { NestedFormComponent } from "src/app/forms/nested-form/nested-form.component";

@Component({
  selector: "taku-inventory-image",
  templateUrl: "./inventory-image.component.html",
  styleUrls: ["./inventory-image.component.scss"],
})
export class InventoryImageComponent extends NestedFormComponent implements OnInit {
  ngOnInit(): void {
    this.subsList.push(
      this._myForm.controls.isWebEnabled.valueChanges.subscribe((isWebEnabled) =>
        InventoryImageComponent.enableWebDefault(isWebEnabled, this._myForm.controls.isWebDefault)
      )
    );
  }

  /** Enabling/disabling through template causes form to incorrectly become pristine, so
   *  handle it programatically instead. */
  private static enableWebDefault = (isWebEnabled: boolean, ctrl: AbstractControl): void => {
    if (isWebEnabled) {
      ctrl.enable();
    } else {
      ctrl.disable();
    }
  };

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    return InventoryImageComponent.set(fb, new InventoryImage());
  }

  static set(fb: UntypedFormBuilder, image: InventoryImage): UntypedFormGroup {
    const inventoryImage_Group = fb.group(image);
    inventoryImage_Group.controls.urlFileName.disable();
    InventoryImageComponent.enableWebDefault(
      inventoryImage_Group.controls.isWebEnabled.value,
      inventoryImage_Group.controls.isWebDefault
    );
    return inventoryImage_Group;
  }

  static setArray(fb: UntypedFormBuilder, inventoryImages: InventoryImage[]) {
    const formArray = fb.array([]);
    inventoryImages.map((inventoryImage) => {
      const temp = fb.group(inventoryImage);
      formArray.push(temp);
    });
    return formArray;
  }

  get altTitleCtrl() {
    return this._myForm.get("altTitle");
  }

  get urlCtrl() {
    return this._myForm.get("fileName");
  }

  get imageSrc(): string {
    return this._myForm.get("urlFileName").value;
  }

  webEnabledChanged($event) {
    if (!$event.checked) {
      this._myForm.get("isWebDefault").setValue(false);
    }
  }

  webDefaultChanged($event) {
    if ($event.checked) {
      this._myForm["_tag"] = true;

      (this._myForm.parent.controls as Array<UntypedFormGroup>)
        .filter((row) => !row["_tag"])
        .map((row) => {
          row.get("isWebDefault").setValue(false);
        });
    }
    (this._myForm.parent.controls as Array<UntypedFormGroup>).map((row) => {
      delete row["_tag"];
    });
  }

  copyToClipboard() {
    navigator.clipboard.writeText(this.imageSrc).catch((err) => {
      console.log(err);
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
