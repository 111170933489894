import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { WebHelpers } from "src/app/utility/WebHelpers";
import { SaleInvoiceDocMainComponent } from "../sale-invoice-doc-main/sale-invoice-doc-main.component";
import { SaleInvoiceStateService } from "../sale-invoice-state.service";
declare const Intercom: any;
@Component({
  selector: "taku-sale-doc-self-checkout-main",
  templateUrl: "./sale-doc-self-checkout-main.component.html",
  styleUrls: ["./sale-doc-self-checkout-main.component.scss"],
})
export class SaleDocSelfCheckoutMainComponent extends SaleInvoiceDocMainComponent implements AfterViewInit {
  constructor(private cd: ChangeDetectorRef, saleInvoiceStateService: SaleInvoiceStateService) {
    super(saleInvoiceStateService);
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
    if (!window.isOnSamsungKiosk) {
      Intercom("update", {
        hide_default_launcher: true,
      });
    }
  }

  // get useMobileDesign(){
  //   return WebHelpers.isMobileScreen();
  // }

  // get useTabletDesign(){
  //   return WebHelpers.isPortraitTabletScreen();
  // }

  // get useDesktopDesign(){
  //   return WebHelpers.isDesktopScreen();
  // }
}
