<span class="p-float-label" [ngClass]="{ 'inputctrl-nofilled': !isAutocompleteFilled }">
  <p-autoComplete
    [dropdown]="isDropDown"
    [suggestions]="autocompleteSuggestions"
    field="label"
    [ngModel]="autoCompleteValue"
    (ngModelChange)="setAutocompleteValue($event)"
    [ngClass]="{ 'inputctrl-filled': isAutocompleteFilled }"
    [appendTo]="_appendTo"
    (completeMethod)="onAutocompleteSearch($event)"
  >
    <ng-template let-object pTemplate="item">
      <div class="suggestion-item ui-helper-clearfix">{{ object.label }}</div>
    </ng-template>
  </p-autoComplete>

  <label *ngIf="fieldCaption" for="{{ fieldName }}" [innerHTML]="fullFieldCaption"></label>
  <i *ngIf="isAutocompleteFilled && !isDropDown" class="material-icons clear-field" (click)="clear()">close</i>

  <form-validation-messages
    *ngIf="showValidationErrors"
    [hidden]="ctrlField.valid || !ctrlField.dirty"
    [errors]="ctrlField.errors"
    [fieldName]="fieldName"
  ></form-validation-messages>
</span>
