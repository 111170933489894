/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, zip } from "rxjs";
import { catchError, map, take, mergeMap } from "rxjs/operators";
import { AppSettingsStorageService } from "../../shared/app-settings-storage.service";
import { AuthService } from "../../shared/services/auth.service";
import { DBService } from "../../shared/services/db.service";
import { CashoutOpeningFloat } from "../settings/store-settings/settings-cashout-opening-float/cashout-opening-float";
import { ZoneDenominationService } from "../settings/zone-settings/zone-denominations/zone-denominations.service";
import { Cashout } from "./cashout/cashout";
import {
  PresetOpeningFloatOption,
  TillCashoutClosingType,
} from "../settings/store-settings/cashout-settings/CashoutSettings";
import * as _ from "lodash";
import { ResponseTransaction } from "../settings/integration-settings/gateway-terminals/payment-terminal";

export type CashoutPaymentBreakdown = {
  tenderTypeId: number;
  tenderDescription: string;
  systemTotal: string;
  refTransaction: ResponseTransaction;
  count: number;
};

export class CashoutSystemPaymentBreakdown {
  tenderTypeId: number;
  tenderDescription: string;
  systemTotal: string;
  refTransaction: ResponseTransaction;
}

export class CashoutIntegratedPaymentBreakdown {
  tenderTypeId: number;
  tenderDescription: string;
  systemTotal: string;
  refTransaction: ResponseTransaction;
}

export class CashoutSummaryDoc {
  docType: string;
  state: string;
  count_doc: number;
  sum_subTotal: string;
  sum_totalLineTax: string;
  sum_shipperChargeAmount: string;
  sum_discountAmount: string;
  sum_cashRounding: string;

  get grandTotal(): number {
    return (
      parseFloat(this.sum_subTotal) +
      parseFloat(this.sum_totalLineTax) +
      parseFloat(this.sum_shipperChargeAmount) -
      parseFloat(this.sum_discountAmount)
    );
  }
}

@Injectable({
  providedIn: "root",
})
export class DBCashoutService extends DBService {
  private static readonly MODEL_NAME = "cashout";

  constructor(
    protected http: HttpClient,
    protected authService: AuthService,
    private zoneDominationService: ZoneDenominationService,
    private appSettings: AppSettingsStorageService
  ) {
    super(http, authService);
  }

  searchOpenCashout$(storeId, stationId): Observable<Cashout> {
    const stationCashoutFilter = {
      isClosed: {
        value: false,
        matchMode: "equals",
      },
      storeId: {
        value: storeId,
        matchMode: "equals",
      },
      stationId: {
        value: stationId,
        matchMode: "equals",
      },
    };

    const storeCashoutFilter = {
      isClosed: {
        value: false,
        matchMode: "equals",
      },
      storeId: {
        value: storeId,
        matchMode: "equals",
      },
      tillCashoutClosingType: {
        value: TillCashoutClosingType.by_store,
        matchMode: "equals",
      },
    };

    const fnFetchCashoutByFilter = (cashoutFilter: any): Observable<Cashout> => {
      return this.getRows(DBCashoutService.MODEL_NAME, JSON.stringify(cashoutFilter), 0, 1, null, null, {
        includes: "cashoutOpeningFloat,cashoutClosingFloat,cashoutClosingBreakDown",
      }).pipe(
        take(1),
        map((response: any) => (response.rows && response.rows.length ? response.rows[0] : null)),
        catchError((err) => of(null))
      );
    };

    return fnFetchCashoutByFilter(stationCashoutFilter).pipe(
      mergeMap((cashout) => {
        if (cashout) return of(cashout);
        else return fnFetchCashoutByFilter(storeCashoutFilter);
      })
    );
  }

  getPaymentBreakdown(cashoutId): Observable<CashoutPaymentBreakdown[]> {
    const endpointUrl = this.webUrl + `cashoutPaymentBreakDown/${cashoutId}`;
    return this._getRequest<CashoutPaymentBreakdown[]>(endpointUrl, {});
  }

  getSummary(cashoutId): Observable<CashoutSummaryDoc[]> {
    const endpointUrl = this.webUrl + `cashoutSummary/${cashoutId}`;
    const params = new HttpParams();
    return this._getRequest<CashoutSummaryDoc[]>(endpointUrl, params);
  }

  getCashoutDenominations(preloadDefaultFloats: boolean): Observable<CashoutOpeningFloat[]> {
    return zip(
      this.appSettings.getStoreSettings("storeSettingCashout"),
      this.zoneDominationService.getZoneDenominations()
    ).pipe(
      map(([cashoutSettings, zoneDenominations]) => {
        let cashoutOpeningFloats = _.cloneDeep(cashoutSettings.storeSettingCashoutOpeningFloats);
        const notCashoutDenominations: CashoutOpeningFloat[] = [];
        const nextFloatCashoutDenominations: CashoutOpeningFloat[] = [];
        // if there are not previous opening floats in store settings, OR setting for preloading defaults opening float it turned off
        // we want to start with an empty list
        if (!Array.isArray(cashoutOpeningFloats)) cashoutOpeningFloats = [];

        if (!preloadDefaultFloats) {
          cashoutOpeningFloats.map((row) => {
            row.count = 0;
            return row;
          });
          if (cashoutSettings.presetOpeningFloatOption === PresetOpeningFloatOption.next_float) {
            const _cashout = this.appSettings.getCashout();
            if (_cashout.isClosed) {
              // copy cash denomination from closed cashout
              _cashout.cashoutClosingFloats.map((row) => {
                const _foundIndex = cashoutOpeningFloats.findIndex((openingFloat) => {
                  return openingFloat.amount === row.amount;
                });

                if (_foundIndex < 0) {
                  cashoutOpeningFloats.push({
                    id: 0,
                    amount: row.amount,
                    count: row.nextFloatCount,
                  });
                } else {
                  cashoutOpeningFloats[_foundIndex].count = row.nextFloatCount;
                }
              });
            }
          }
        }
        // zoneDenominations.forEach(zoneDenomination => {
        //   const foundIndex = cashoutOpeningFloats.findIndex(openingFloat => {
        //     return openingFloat.amount === zoneDenomination.value
        //   })

        //   if (foundIndex < 0) // Create new cashout float with count 0
        //     notCashoutDenominations.push({
        //       id: 0,
        //       amount: zoneDenomination.value,
        //       count: 0
        //     });
        // });

        const allCashoutDenominations = cashoutOpeningFloats.concat(notCashoutDenominations);
        allCashoutDenominations.sort((a, b) => a.amount - b.amount);

        return allCashoutDenominations;
      })
    );
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
