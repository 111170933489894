/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/shared/services/auth.service";
import { SettingCardInfo } from "../settings-cards/setting-cards.component";

@Component({
  selector: "taku-user-management-cards",
  templateUrl: "./user-management-cards.component.html",
  styleUrls: ["./user-management-cards.component.scss"],
})
export class UserManagementCardsComponent implements OnInit {
  pageTitle = "User Management";
  pageIconStyleClass = "ui-icon-group";
  cardsInfo: SettingCardInfo[] = [];

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.cardsInfo = [
      {
        routePath: ["/settings/manage-roles/n/role"],
        routeQueryParams: { title: "Roles" },
        iconStyleClass: "material-icons",
        iconName: "vpn_key",
        cardTitle: "Manage Roles",
        cardDesc: ["Create or Edit Roles", "Define Role Access Rights by Data, Visibility or Privileges"],
        isVisible: this.authService.hasVisiblePermit("Settings_Menu_User_Management_Manage_Roles"),
        isEnabled: this.authService.hasEnablePermit("Settings_Menu_User_Management_Manage_Roles"),
      },
      {
        routePath: ["/settings/users"],
        routeQueryParams: { title: "Users" },
        iconStyleClass: "material-icons",
        iconName: "group",
        cardTitle: "Manage Users",
        cardDesc: ["Create or Edit Users", "Assign Roles by Store to Users"],
        isVisible: this.authService.hasVisiblePermit("Settings_Menu_User_Management_Manage_Users"),
        isEnabled: this.authService.hasEnablePermit("Settings_Menu_User_Management_Manage_Users"),
      },
    ];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
