/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import * as _ from "lodash";
import { Stock } from "../../../settings/business-settings/stock/stock";
import { Doc, DocState, InventoryDocType } from "../../doc/doc";
import { DocLine } from "../../doc-line/doc-line";
import { MonetaryHelpers } from "../../../../utility/MonetaryHelpers";
import { CommercialAccount } from "../../../contact-accounts/commercial-account/commercial-account";
import { AccountType, Account } from "../../../contact-accounts/account/account";
import { PackSize } from "src/app/core/inventory/inventory-supplier-product-detail/inventory-supplier-product-detail";
import { DiscountType, TotalBySaleTax } from "../../sale-document/sale-doc-line/sale-doc-line";
import { TaxRule } from "src/app/core/tax/tax-rule/tax-rule";
import { Zone } from "src/app/core/settings/zone-settings/Zone";
import { Store } from "src/app/core/settings/store-settings/store/store";
import { BackOffice } from "src/app/core/settings/backOffice-settings/backOffice/backOffice";
import { PaymentTerm } from "src/app/core/purchase-order-term/payment-term/payment-term";
import { ShippingTerm } from "src/app/core/purchase-order-term/shipping-term/shipping-term";

export type InventoryDocAccount = {
  commercialAccount: CommercialAccount;
} & Account;
export class InventoryDoc {
  id: number;
  subTotal: number;
  discountType: DiscountType;
  discountAmount: number;
  stockId: number;
  stock: Stock;
  paymentTermId: number;
  paymentTerm?: PaymentTerm;
  shippingTermId: number;
  shippingTerm?: ShippingTerm;
  shipVia: string;
  zoneId: number;
  zone: Zone;
  doc: Doc;
  inventoryDocLines: InventoryDocLine[];
  accountId: number;
  account: InventoryDocAccount;
  stockAdjustmentType: StockAdjustmentType;
  shipperChargeAmount: number;
  totalLineTax: number;
  taxID: string;
  storeId: number;
  store: Store;
  backOfficeId: number;
  backOffice: BackOffice;
  buyerName: string;

  constructor(
    docType: InventoryDocType = null,
    stockAdjustmentType: StockAdjustmentType = null,
    defaultZone = null,
    defaultCurrencyIsoCode: string = null
  ) {
    this.id = 0;
    this.subTotal = 0;
    this.discountType = DiscountType.Percentage;
    this.discountAmount = 0;
    this.stockId = null;
    this.stock = new Stock();
    this.paymentTermId = null;
    this.paymentTerm = new PaymentTerm();
    this.shippingTermId = null;
    this.shippingTerm = new ShippingTerm();
    this.shipVia = "";
    this.zoneId = defaultZone?.id || null;
    this.zone = defaultZone;
    this.doc = new Doc(docType, DocState.draft);
    this.inventoryDocLines = [];
    this.accountId = null;
    this.account = new Account(AccountType.commercial) as InventoryDocAccount;
    this.account.commercialAccount = new CommercialAccount(defaultCurrencyIsoCode);
    this.stockAdjustmentType = stockAdjustmentType;
    this.shipperChargeAmount = 0;
    this.totalLineTax = 0;
    this.taxID = null;
    this.storeId = null;
    this.backOfficeId = null;
    this.buyerName = null;
  }

  static convertIntoTypedObject(inventoryDoc: InventoryDoc): InventoryDoc {
    const typedInventoryDoc: InventoryDoc = _.merge(new InventoryDoc(), inventoryDoc);
    typedInventoryDoc.inventoryDocLines = typedInventoryDoc.inventoryDocLines.map((inventoryDocLine) =>
      InventoryDocLine.convertIntoTypedObject(inventoryDoc, inventoryDocLine)
    );
    typedInventoryDoc.discountAmount = MonetaryHelpers.roundToDecimalPlaces(typedInventoryDoc.discountAmount);

    return typedInventoryDoc;
  }

  get discountAmountMoney(): number {
    // Vinh noted: WHY subTotal is inserted to database with value (subTotal * conversionRate)
    //                 shipperChargeAmount and discountAmount does not?!
    switch (this.discountType) {
      case DiscountType.Fixed:
        return this.doc?.currencyConversionRate * this.discountAmount;

      case DiscountType.Percentage:
        return (this.subTotal * this.discountAmount) / 100;

      default:
        return 0;
    }
  }

  get groupedTaxLines(): TotalBySaleTax[] {
    const linesTaxes = _.flatten(
      this.inventoryDocLines.map((inventoryDocLine) => inventoryDocLine.inventoryDocLineTaxes)
    );
    const groupedTaxes = _.groupBy(linesTaxes, (lineTax: InventoryDocLineTax) => lineTax.taxRuleId);
    const totalGrouped = _.map(groupedTaxes, (taxes: InventoryDocLineTax[]) => {
      if (!taxes[0].taxRule)
        return {
          taxes: taxes,
          saleTaxRule: null,
          total: _.sumBy(taxes, (tax) => parseFloat(tax.amount + "")),
        };

      return {
        taxes: taxes,
        saleTaxRule: taxes[0].taxRule,
        total: _.sumBy(taxes, (tax) => parseFloat(tax.amount + "")),
      };
    });
    return totalGrouped;
  }

  get totalTax(): number {
    return _.sumBy(this.groupedTaxLines, (tax) => tax.total);
  }

  get grandTotal(): number {
    return (
      MonetaryHelpers.roundToDecimalPlaces(this.subTotal, 2) -
      MonetaryHelpers.roundToDecimalPlaces(this.discountAmountMoney, 2) +
      MonetaryHelpers.roundToDecimalPlaces(this.totalTax, 2) +
      MonetaryHelpers.roundToDecimalPlaces(this.shipperChargeAmount, 2)
    );
  }

  get totalItems(): number {
    let totalItems = 0;
    if (this.inventoryDocLines.length > 0) {
      totalItems = this.inventoryDocLines.map((line) => line.qty).reduce((total, qty) => total + qty);
    }
    return totalItems;
  }
}

export class InventoryDocLine {
  id: number;
  qty: number;
  unitCost: number;
  convRate: number;
  docLine: DocLine;
  uOfMeasureId: number;
  packSize: PackSize;
  invoiceNo: string;
  expiryDate: string;
  lotNo: string;
  /** This is a dynamic, front-end-only field that is added to be used for formatting in formlists */
  docReference: InventoryDoc;
  allocatedDiscountType: DiscountType;
  allocatedDiscountAmount: number;
  allocatedShipperChargeAmount: number;
  inventoryDocLineTaxes: InventoryDocLineTax[];
  private _errorMsg?: string;

  constructor(docReference: InventoryDoc) {
    this.id = 0;
    this.qty = 0;
    this.unitCost = 0;
    this.convRate = 1;
    this.docLine = new DocLine();
    this.uOfMeasureId = null;
    this.packSize = PackSize.SKU;
    this.invoiceNo = null;
    this.expiryDate = null;
    this.lotNo = null;
    this.docReference = docReference;
    this.allocatedDiscountType = DiscountType.Fixed;
    this.allocatedDiscountAmount = 0;
    this.allocatedShipperChargeAmount = 0;
    this.inventoryDocLineTaxes = [];
  }

  static convertIntoTypedObject(inventoryDoc: any, inventoryDocLine: any): InventoryDocLine {
    return _.merge(new InventoryDocLine(inventoryDoc), inventoryDocLine);
  }

  get errorMsg() {
    return this._errorMsg;
  }

  set errorMsg(errorMsg) {
    this._errorMsg = errorMsg;
  }

  get supplierCode(): string {
    const detail = this.docLine.inventory.inventorySuppliers[0].inventorySupplierProductDetails.find((row) => {
      return row.packSize === this.packSize;
    });
    return detail ? detail.supplierCode : undefined;
  }

  get skuQtyPerPack(): number {
    const detail = this.docLine.inventory.inventorySuppliers[0].inventorySupplierProductDetails.find((row) => {
      return row.packSize === this.packSize;
    });
    return detail ? detail.skuQty : 1;
  }

  get totalQty(): number {
    return (this.qty || 0) * (this.skuQtyPerPack || 1);
  }

  get convertedUnitCost(): number {
    if (this.docReference && this.docReference.doc) {
      return MonetaryHelpers.roundToDecimalPlaces(Number(this.unitCost) * this.docReference.doc.currencyConversionRate);
    }
    return MonetaryHelpers.roundToDecimalPlaces(Number(this.unitCost)); // or return 0 or any other default value if there's no reference or currencyConversionRate
  }

  get totalCost(): number {
    return InventoryDocLine.totalCost(this);
  }

  static totalCost(inventoryDocLine: InventoryDocLine): number {
    return (
      inventoryDocLine.subTotal -
      inventoryDocLine.convertedTotalDiscountAmount +
      inventoryDocLine.convertedAllocatedShipperChargeAmount +
      inventoryDocLine.totalTax
    );
  }

  get convertedAllocatedShipperChargeAmount() {
    return MonetaryHelpers.roundToDecimalPlaces(
      this.allocatedShipperChargeAmount * Number(this.docReference.doc.currencyConversionRate + "")
    );
  }

  get subTotal(): number {
    return MonetaryHelpers.roundToDecimalPlaces(this.qty * this.convertedUnitCost);
  }

  get totalDiscountAmount(): number {
    switch (this.allocatedDiscountType) {
      case DiscountType.Fixed:
        return MonetaryHelpers.roundToDecimalPlaces(+this.allocatedDiscountAmount);

      case DiscountType.Percentage:
        return MonetaryHelpers.roundToDecimalPlaces((+this.qty * +this.unitCost * +this.allocatedDiscountAmount) / 100);

      default:
        return 0;
    }
  }

  get convertedUnitDiscount(): number {
    return +this.qty === 0 ? 0 : MonetaryHelpers.roundToDecimalPlaces(this.convertedTotalDiscountAmount / +this.qty);
  }

  get convertedTotalDiscountAmount(): number {
    return MonetaryHelpers.roundToDecimalPlaces(
      this.totalDiscountAmount * Number(this.docReference.doc.currencyConversionRate + "")
    );
  }

  get inventoryVariantsStr(): string {
    return _.sortBy(this.docLine.inventory.inventoryVariants, "inventoryOptionsSettingId")
      ?.map((_inventoryVariant) => _inventoryVariant?.inventoryOptionsSettingValue?.optionValue)
      .join(" / ");
  }

  get totalTax(): number {
    return this.inventoryDocLineTaxes.reduce(
      (total, inventoryDocLineTax) => total + Number(inventoryDocLineTax.amount),
      0
    );
  }
}

export class InventoryDocLineTax {
  id: number;
  amount: number;
  taxRuleId: number;
  taxRule: TaxRule;

  constructor() {
    this.id = 0;
    this.amount = 0;
    this.taxRuleId = 0;
    this.taxRule = null;
  }
}

export enum StockAdjustmentType {
  In = "In",
  Out = "Out",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
