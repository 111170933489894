<div [formGroup]="_myForm">
  <div class="col-12">
    <taku-form-dialoge-header
      [pristine]="_myForm.pristine && formListComponent?.isPristine()"
      [valid]="_myForm.valid && formListComponent?.isValid()"
      (saveClicked)="onSave()"
      (revertClicked)="onRevert()"
      (cancelClicked)="onClosePressed()"
      [showDeleteBtn]="false"
      [toolbarTitle]="_myForm.get('description1').value"
    ></taku-form-dialoge-header>
  </div>
  <div class="col-12">
    Variants allow you to sell different options of the same product - e.g. different colours and sizes, etc. Each
    unique combination of options results in a variation listed in Inventory.
    <b>*Note that every variant requires at least 1 option.*</b>
    <!-- <taku-input [formGroup]="_formGroup" fieldName="gmcName" fieldCaption="Business Name">
    </taku-input> -->
  </div>

  <div class="col-12">
    <p-fieldset class="col-12 person-phoneNumbers taku-listable-container" formArrayName="inventoryVariantSettings">
      <ng-template pTemplate="header">
        <span>Create Variants with up to 4 options</span>
      </ng-template>
      <div class="add-button-container">
        <a class="add-button" (click)="addInventoryVariantSettingLine()">
          <label>Add New</label>
          <i class="material-icons">add_circle_outline</i>
        </a>
      </div>
      <div
        class="grid"
        *ngFor="let inventoryVariantSettingForm of _myForm.get('inventoryVariantSettings')['controls']; let i = index"
      >
        <div class="col-11">
          <taku-inventory-variant-setting-line [inventoryVariantSettingLineForm]="inventoryVariantSettingForm">
          </taku-inventory-variant-setting-line>
        </div>
        <div class="col-1 remove-button-container">
          <taku-action-button
            [action]="ACTION_TYPE.REMOVE"
            (clickAction)="removeInventoryVariantSettingLine(i)"
          ></taku-action-button>
        </div>
      </div>
      <div class="col-12 grid justify-content-end search-box-btns fields-line">
        <div class="sm:col-3 col-6 p-fluid">
          <button pButton label="Clear" class="p-button-secondary" (click)="clearAll()">
            <!-- <span class="hidden-icons p-inputgroup-addon"><i class="icon pi pi-times"></i></span> -->
          </button>
        </div>

        <div class="sm:col-3 col-6 p-fluid">
          <button pButton label="Apply Below" (click)="syncVariants()">
            <!-- <span class="hidden-icons p-inputgroup-addon"><i class="icon pi pi-search"></i></span> -->
          </button>
        </div>
      </div>
    </p-fieldset>
  </div>

  <div class="col-12">
    <p-fieldset class="col-12" legend="Combine Existing Products" [toggleable]="true" [collapsed]="true">
      <div class="p-inputgroup inputgroup-outlined field-searchInventory">
        <span class="p-inputgroup-addon"><i class="material-icons">local_offer</i></span>
        <taku-search-inventory
          id="inventory_search"
          #inventorySearchBox
          [autoClearOnSelection]="true"
          placeholder="Search for Items"
          (itemSelected)="onSearchResInventory($event)"
          [disabled]="_myForm.value.inventoryVariantSettings.length == 0"
          (onNewItemClicked)="openNewModelInDialog($event)"
          [showAnimatePlaceholder]="false"
          [searchFilter]="_searchFilter"
          [extraQueryParams]="_inventorySeachQueryParams"
        >
        </taku-search-inventory>
      </div>
    </p-fieldset>
  </div>

  <div id="data-progressbar">
    <p-progressBar mode="indeterminate" value="0" [style.visibility]="_isFetchingData ? 'visible' : 'hidden'">
    </p-progressBar>
  </div>
  <div class="col-12">
    <taku-form-list
      #formListInventoryVariantSetting
      *ngIf="_isFormListActive"
      [_hideViewCol]="false"
      [_disableViewCol]="false"
      [_model]="'inventory'"
      [_showCtrlsInEditMode]="true"
      [_cols]="inventoryVariantColumns"
      _title="Inventory Variants"
      [_hideAddLineHeaderBtn]="true"
      _detailMode="w"
      _scrollHeight="70vh"
      [_bulkEditorEnabled]="true"
      [_rowSelectionType]="RowSelectionType.MULTIPLE"
      [_hideOptionsHeaderBtn]="false"
      [_hideFilterControls]="false"
      [_modelService]="inventoryVariantSettingService"
      [_dataService]="inventoryVariantSettingLocalDataService"
      [_hideGlobalFilter]="true"
      [_saveFormState]="false"
      [_defaultRows]="100"
      [_hideSavingCtrls]="true"
      [_deleteRowEvent]="deleteRows"
      (_allRowsSaved)="onAllRowsSaved($event)"
      [_hideDeleteHeaderBtn]="true"
      [_modelValidation]="_formListRules"
    >
      <div takuFormListHeaderCustomBtns>
        <button
          id="print_label"
          pButton
          pTooltip="Print Label"
          tooltipPosition="left"
          class="header-button p-button-rounded"
          (click)="onPrintLabel()"
          icon="pi pi-tag"
        >
        </button>
      </div>
    </taku-form-list>
  </div>
</div>

<ng-template #unlinkTpl let-rowData>
  <button *ngIf="rowData.id > 0" pButton [label]="'Unlink'" (click)="onUnlinkBtnClicked(rowData)"></button>
  <div *ngIf="rowData.id <= 0">
    <button
      id="btn_delete"
      pButton
      pRipple
      type="button"
      (click)="onRemoveRowBtnClicked(rowData)"
      icon="_"
      class="header-button"
      pTooltip="Delete Row"
      tooltipPosition="top"
    >
      <i class="material-icons">delete</i>
    </button>
  </div>
</ng-template>
