<div class="grid" *ngIf="isLoading">
  <div class="col-12">
    <div class="card card-w-title">
      <!-- every where you need alert message you can use one of these components based on your need na dalso calling function in ts file -->
      <p-progressBar [value]="value">loading</p-progressBar>
      <br />
      <div *ngIf="err">
        <button pButton type="button" (click)="goBack()" icon="fa-chevron-left" label="Back"></button>
        <button pButton type="button" (click)="goDashboard()" icon="_" label="DASHBOARD">
          <i class="material-icons">dashboard</i>
        </button>
        <button pButton type="button" (click)="doRetry()" icon="pi pi-refresh" label="Retry"></button>
      </div>
    </div>
  </div>
</div>
