import { Location } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, MenuItem, MessageService } from "primeng/api";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { AppComponent } from "src/app/app.component";
import { Inventory } from "src/app/core/inventory/inventory/inventory";
import { Store } from "src/app/core/settings/store-settings/store/store";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";
import { DBSearchService } from "src/app/shared/services/db-search.service";
import {
  PrintingFactoryService,
  PrintingMgr,
  ReceiptPrintingSize,
} from "src/app/shared/services/printing-service.service";
import { MonetaryHelpers } from "src/app/utility/MonetaryHelpers";
import { SaleDocComponent } from "../sale-doc/sale-doc.component";
import { SaleDocService } from "../sale-doc/sale-doc.service";
import { InvoiceKeyboardActionsService } from "../sale-invoice-doc-base/invoice-keyboard-actions.service";
import { SaleInvoiceStateService } from "../sale-invoice-state.service";
import * as _ from "lodash";
import { environment } from "src/environments/environment";
import { SelfCheckoutItemDetailsComponent } from "../self-checkout-item-details/self-checkout-item-details.component";
import { Category } from "src/app/core/inventory/category/category";
import { SaleDocLine } from "src/app/core/document/sale-document/sale-doc-line/sale-doc-line";
import { SaleDocLineComponent } from "../sale-doc-line/sale-doc-line.component";
import { SaveDocActionType } from "../generic-sale-doc.component";
import { DocState } from "../../doc/doc";
import { TenderPrintingSettings } from "../tender-screen/tender-screen.component";
import { FulfillmentStatus, PaymentStatus, SaleDoc, SaleDocSource } from "../sale-doc/sale-doc";
import { FormDialogInputComponent } from "src/app/forms/form-dialog-input/form-dialog-input.component";
import { RevisitSaleService } from "../revisit-sale/revisit-sale.service";
import { TizenBarcodeService } from "src/app/shared/services/tizen/tizen-barcode.service";
import { TizenPrintService } from "src/app/shared/services/tizen/tizen-print.service";
import { SaleDocTender } from "../sale-doc-tender/sale-doc-tender";
import { StoreTenderType } from "src/app/core/settings/store-settings/store-tender-type-settings/TenderTypesSettings";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map, take, shareReplay } from "rxjs/operators";
import { CommandName } from "src/app/core/settings/integration-settings/gateway-terminals/payment-terminal";
import { PaymentGatewayType } from "src/app/core/settings/integration-settings/payment-gateways/payment-gateway";
import { TenderType, Tender_Type } from "src/app/core/settings/business-settings/tender-type/tender-type";
import { InvoiceHelpers } from "src/app/utility/InvoiceHelpers";
import { MonerisService } from "src/app/core/settings/integration-settings/moneris-terminal/moneris.service";
import { EconduitService } from "src/app/core/settings/integration-settings/econduit-terminal/econduit.service";
import { BlockingUIService } from "src/app/shared/services/ui/blocking-ui.service";
import {
  MenuTenderLineUndoName,
  ResponseTransaction,
  TenderLineStatusName,
} from "src/app/core/payment-processors/econduit";
import { SaleDocTenderComponent } from "../sale-doc-tender/sale-doc-tender.component";
import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";
import { KioskGuard } from "src/app/app-routing/kiosk.guard";
import { IdleService } from "src/app/idle.service";
import { PrinterManufacturer } from "src/app/core/settings/store-settings/printer/printer-settings";
import { WebHelpers } from "src/app/utility/WebHelpers";
import { TakuPayService } from "src/app/core/settings/integration-settings/taku-pay/taku-pay.service";
import {
  TakuPayCaptureMethod,
  TakuPayCommandName,
  TakuPayMethodType,
  TakuPaymentStatus,
} from "src/app/core/settings/integration-settings/taku-pay/taku-payment-gateway-terminals/taku-payment-terminal";
import { StoreTenderTypesSettingsService } from "src/app/core/settings/store-settings/store-tender-type-settings/tender-types-settings.service";
import { PriceLevelService } from "src/app/core/price-levels/price-level-service";

@Component({
  selector: "taku-sale-doc-self-checkout-base",
  templateUrl: "./sale-doc-self-checkout-base.component.html",
  styleUrls: ["./sale-doc-self-checkout-base.component.scss"],
  providers: [SaleInvoiceStateService],
})
export class SaleDocSelfCheckoutBaseComponent extends SaleDocComponent {
  protected static readonly MAX_INITIAL_RESULTS = 10;
  protected static readonly MAX_MORE_RESULTS = 25;
  appVersion = environment.common.APP_VERSION;

  inventories: Inventory[];
  orgInventories: Inventory[];
  categories: Category[];
  selfCheckoutSearchForm: UntypedFormGroup;
  searchFieldControl;
  // @ContentChild('searchInput') searchInput: ElementRef;
  @ViewChild("searchInput") searchField: ElementRef;
  // Parent's Templates
  @ContentChild("saleDocDataViewTemplate") dataViewTemplate: TemplateRef<ElementRef>;

  @Output() onReturnToCart: EventEmitter<any> = new EventEmitter();
  @Output() onStartOver: EventEmitter<any> = new EventEmitter();
  // @Output() onTopRightMenuIconClicked: EventEmitter<any> = new EventEmitter();

  _showClearButton = false;
  _productsLoading = false;
  _showItemsClicked = true;
  _showPaymentPage = false;
  // _showCategories = true;
  _showInventoryItems = false;
  activeStore: Store;
  paymentPageActiveIndex = 0;
  userReadyToPay = false;
  paymentIsSuccessful = false;
  payAtCounterChosen = false;
  supportsScroll = true;

  currentFetchLimit: number = SaleDocSelfCheckoutBaseComponent.MAX_INITIAL_RESULTS;
  lazyLoadFilter = "";
  // _extraQueryParams: {};
  totalRecords: number;
  totalCategories: number;
  allCategoriesFilter;
  currentCategory: Category;
  storeTenderTypes$: Observable<StoreTenderType[]>;
  selectedTender: TenderType;
  isWaiting = false;
  progressBar: DynamicDialogRef;

  @Input() dvContainerWidth: number;
  @Input() dvContainerHeight: number;

  @Input() topWrapperHeight: number;

  dvContainerHeightDesktop = 0;

  preferredListWidth = 320;
  preferredListHeight = 105;

  paymentPageSteps: MenuItem[] = [{ label: "Add-Ons" }, { label: "Payment" }];

  // protected exitPassword: string ='';
  addOnsFromKioskSettings: Inventory[];
  addOnsForm: UntypedFormArray;
  originalAddOnsForm: UntypedFormArray;
  tenderSettingsForm: UntypedFormGroup;

  printMgr: PrintingMgr;
  allowRedirectToMain = false;
  sendEmailBtnClicked = false;

  // for idle timeout
  timedOut = false;

  allowPayAtCounter: boolean;
  _isNewSaleButtonActive = false;

  /** Mark the tender buttons as loading (and disabled) while checking for terminals */
  terminalCheckComplete = new BehaviorSubject(false);

  constructor(
    public dataService: DBSearchService,
    public app: AppComponent,
    _router: Router,
    fb: UntypedFormBuilder,
    dbService: SaleDocService,
    location: Location,
    _route: ActivatedRoute,
    messageService: MessageService,
    alertMessage: AlertMessagesService,
    confirmationService: ConfirmationService,
    appSettingsService: AppSettingsStorageService,
    changeDetectorRef: ChangeDetectorRef,
    invoiceStateService: SaleInvoiceStateService,
    printingFactoryService: PrintingFactoryService,
    ngZone: NgZone,
    keyboardActionsService: InvoiceKeyboardActionsService,
    elRef: ElementRef,
    private econduitService: EconduitService,
    monerisService: MonerisService,
    priceLevelService: PriceLevelService,
    takuPayService: TakuPayService,
    private storeTenderTypesSettingsService: StoreTenderTypesSettingsService,
    private blockUIService: BlockingUIService,
    public ref: DynamicDialogRef,
    public dialogService: DialogService,
    private idle: Idle,
    kioskGuard: KioskGuard,
    public idleService: IdleService,
    private revisitSaleService: RevisitSaleService,
    public tizenBarcodeService: TizenBarcodeService,
    public tizenPrintService: TizenPrintService
  ) {
    super(
      app,
      _router,
      fb,
      dbService,
      location,
      _route,
      messageService,
      alertMessage,
      confirmationService,
      appSettingsService,
      changeDetectorRef,
      invoiceStateService,
      printingFactoryService,
      ngZone,
      keyboardActionsService,
      elRef,
      monerisService,
      takuPayService,
      storeTenderTypesSettingsService,
      priceLevelService
    );

    idle.setIdle(45);
    // // // user timed out 30 seconds after being idle
    idle.setTimeout(1);
    // // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.subsList.push(
      idle.onTimeout.subscribe(() => {
        idleService.idleState = "IDLE";
        if (this._myForm.dirty) {
          // idle.stop();
          if (this.timedOut) {
            this.confirmationService.close();
            kioskGuard?.ref.close();
            this._router.navigateByUrl("/kiosk/selfCheckout/landingPage");
            idleService.reset();
            this.ref.close();
          } else {
            this.timedOut = true;
            idle.watch();
            idleService.idleState = "";
            kioskGuard?.ref.close();
            this.confirmationService.confirm({
              header: "Finished?",
              message: "Do you need more time?",
              acceptLabel: "New Sale",
              rejectLabel: "Not Done Yet",
              // rejectButtonStyleClass: 'p-button-link',
              accept: () => {
                this._router.navigateByUrl("/kiosk/selfCheckout/landingPage");
                idleService.reset();
                this.ref.close();
                console.log("start new sale!");
              },
              reject: () => {
                idleService.reset();
                this.ref.close();
              },
            });
          }
        } else {
          kioskGuard?.ref.close();
          this._router.navigateByUrl("/kiosk/selfCheckout/landingPage");
        }
        console.log(idleService.idleState);
      })
    );

    idleService.reset();
    this.ref.close();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.activeStore = this.appSettingsService.getStore();

    const filter = {};
    filter["storeId"] = {
      value: this.appSettingsService.getStoreId(),
      matchMode: "equals",
    };

    this.subsList.push(
      this.dbService
        .getRows("storeSettingKiosk", JSON.stringify(filter))
        .pipe(
          take(1),
          map((myObjects) => myObjects.rows[0])
        )
        .subscribe((kioskSettingsData) => {
          this.addOnsFromKioskSettings = kioskSettingsData.inventories;
          // console.log(this.addOnsFromKioskSettings);
          const temp = [];
          this.addOnsFromKioskSettings.forEach((addOn) => {
            temp.push(this.convertInventoryToSaleDocLine(addOn));
          });
          this.originalAddOnsForm = this.fb.array(temp);
          this.addOnsForm = _.cloneDeep(this.originalAddOnsForm);

          this.allowPayAtCounter = kioskSettingsData.allowPayAtCounter;
          // console.log(this.allowPayAtCounter);
        })
    );

    const userPrefs = this.appSettingsService.getUserPreferences();
    //We don't want to reuse email address or sendEmail setting from previous transaction.
    if (userPrefs.tenderPrintingSettings) {
      userPrefs.tenderPrintingSettings.emailAddress = "";
      userPrefs.tenderPrintingSettings.sendEmail = false;
    }

    const tenderPrintSettings = userPrefs.tenderPrintingSettings || new TenderPrintingSettings();
    tenderPrintSettings.giftReceipt = false;
    this.tenderSettingsForm = this.fb.group(tenderPrintSettings);
    this.ctrlEmailAddress.setValidators(Validators.email);
    this.printingSizeCtrl.setValue(ReceiptPrintingSize.TAPE_SIZE);

    this.subsList.push(
      this.changedForm.subscribe((newSaleDoc) => {
        if (this.sendEmailBtnClicked) {
          this._onEmailDoc(newSaleDoc);
        }
      })
    );

    this.supportsScroll = WebHelpers.supportsScrollMethod();

    this.storeTenderTypes$ = this.storeTenderTypesSettingsService
      .getStoreTenderTypes(this.terminalCheckComplete)
      .pipe(shareReplay(1));

    this.subsList.push(
      this.storeTenderTypes$
        .pipe(
          map((storeTenderTypes: StoreTenderType[]) =>
            storeTenderTypes.find(
              (storeTenderType) =>
                storeTenderType.tenderType.type !== Tender_Type.Cash &&
                storeTenderType.tenderType.isIntegrated === true &&
                (storeTenderType.tenderType?.paymentTerminal?.stationId === this.appSettingsService.getStationId() ||
                  storeTenderType.tenderType?.takuPaymentTerminal?.stationId === this.appSettingsService.getStationId())
            )
          )
        )
        .subscribe((paymentTender) => {
          if (paymentTender) {
            this.selectedTender = paymentTender.tenderType;
          }
        })
    );
  }

  private addTenderTransaction(amount: number, tenderType: TenderType) {
    // Add tender payment to form in order to be saved later
    const newSaleDocTender = Object.assign(new SaleDocTender(), {
      amount: amount,
      tenderTypeId: tenderType.id,
      tenderType: tenderType,
      // isReturned: this._mode == TenderScreenMode.RETURNS,
      isTendered: amount >= 0,
      //paymentGatewayId: 0,
      paymentTerminalId: 0,
      takuPaymentTerminalId: 0,
      createdAt: new Date(),
    });

    // if (tenderType && tenderType.type != Tender_Type.Cash && tenderType.paymentTerminalId > 0) {
    let sTransactionType: string = CommandName.COMMAND_SALE;
    if (amount < 0) {
      sTransactionType = CommandName.COMMAND_REFUND;
    }
    this.callApiRunTransaction(newSaleDocTender, sTransactionType, InvoiceHelpers.generateRandomRefId());
    // }
  }

  private callApiRunTransaction(
    saleDocTender: SaleDocTender,
    apiCommand: string,
    refID: string,
    index = 0,
    sMenuType = ""
  ) {
    this.subsList.push(
      this.storeTenderTypes$
        .pipe(
          map((storeTenderTypes: StoreTenderType[]) =>
            storeTenderTypes.find(
              (storeTenderType) =>
                storeTenderType.tenderType.type !== Tender_Type.Cash &&
                storeTenderType.tenderType.id === saleDocTender.tenderTypeId
            )
          )
        )
        .subscribe((paymentTenders) => {
          if (paymentTenders.tenderType.paymentTerminalId > 0 && !saleDocTender.paymentTerminal) {
            saleDocTender.paymentTerminal = paymentTenders.tenderType.paymentTerminal;
            saleDocTender.paymentTerminalId = paymentTenders.tenderType.paymentTerminalId;
            saleDocTender["paymentGateway"] = paymentTenders.tenderType.paymentTerminal?.paymentGateway;
            saleDocTender["paymentGatewayId"] = paymentTenders.tenderType.paymentTerminal?.paymentGatewayId;
          } else if (paymentTenders.tenderType.takuPaymentTerminalId > 0 && !saleDocTender.takuPaymentTerminal) {
            saleDocTender.takuPaymentTerminal = paymentTenders.tenderType.takuPaymentTerminal;
            saleDocTender.takuPaymentTerminalId = paymentTenders.tenderType.takuPaymentTerminalId;
            saleDocTender["takuPaymentGateway"] = paymentTenders.tenderType.takuPaymentTerminal?.takuPaymentGateway;
            saleDocTender["takuPaymentGatewayId"] = paymentTenders.tenderType.takuPaymentTerminal?.takuPaymentGatewayId;
          }

          let apiBody;
          let paymentService;
          if (saleDocTender?.takuPaymentTerminal?.takuPaymentGateway) {
            paymentService = this.takuPayService;
            switch (apiCommand) {
              case TakuPayCommandName.COMMAND_SALE:
                apiCommand = TakuPayCommandName.COMMAND_INTENT;
                apiBody = {
                  amount: Math.abs(saleDocTender.amount as number),
                  payment_method_types: TakuPayMethodType.CARD_PRESENT,
                  capture_method: TakuPayCaptureMethod.AUTOMATIC,
                  invoiceNumber: this._object.id,
                };
                break;
              case TakuPayCommandName.COMMAND_VOID:
                apiCommand = TakuPayCommandName.COMMAND_CANCEL;
                apiBody = {
                  amount: Math.abs(saleDocTender.amount as number),
                  payment_id: saleDocTender.refTransaction?.PaymentId || null,
                  terminal_id: saleDocTender.takuPaymentTerminal.terminalId,
                  invoiceNumber: this._object.id,
                };
                break;
              case TakuPayCommandName.COMMAND_REFUND:
                apiBody = {
                  amount: Math.abs(saleDocTender.amount as number),
                  payment_id: saleDocTender.refTransaction?.PaymentId || null,
                  terminal_id: saleDocTender.takuPaymentTerminal.terminalId,
                  invoiceNumber: this._object.id,
                };
                break;
            }
          } else {
            apiBody = {
              amount: saleDocTender.amount,
              invoiceNumber: this._object.id,
              AuthCode: saleDocTender.refTransaction?.AuthCode || null,
            };

            switch (saleDocTender?.paymentTerminal?.paymentGateway?.paymentGatewayType) {
              case PaymentGatewayType.PAYROC:
                paymentService = this.econduitService;
                break;
              case PaymentGatewayType.MONERIS:
                paymentService = this.monerisService;
                if (apiCommand === CommandName.COMMAND_SALE) {
                  apiCommand = CommandName.COMMAND_PURCHASE;
                }
                if (apiCommand === CommandName.COMMAND_VOID) {
                  apiCommand = CommandName.COMMAND_PURCHASE_CORRECTION;
                }
                break;
            }
          }

          if (paymentService) {
            if (this.isWaiting) {
              return of(false);
            }
            this.isWaiting = true;
            this.progressBar = this.blockUIService.showProgressBar(this.dialogService, false, {
              note: "Insert or Tap your Card or Phone",
              imgUrl: "/assets/layout/images/checkout-payment.png",
            });

            if (saleDocTender?.takuPaymentTerminal?.takuPaymentGateway) {
              //-------------------------------------------------------------------------------------------------
              // Taku Payment Gateway Processing
              //-------------------------------------------------------------------------------------------------
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              this.subsList.push(
                paymentService
                  .runTransaction(apiCommand, saleDocTender?.takuPaymentTerminal?.takuPaymentGateway?.storeId, apiBody)
                  .subscribe({
                    next: (response) => {
                      if (apiCommand === TakuPayCommandName.COMMAND_INTENT) {
                        let authJson = {
                          amount: saleDocTender.amount,
                          payment_id: response.payment_id,
                          external_transaction_id: response.external_transaction_id,
                          terminal_id: saleDocTender.takuPaymentTerminal.terminalId,
                        };

                        if (apiBody.capture_method) {
                          // only putting this in here for our backend to tell the difference for what intent was used.
                          authJson = Object.assign(authJson, { capture_method: apiBody.capture_method });
                        }

                        paymentService
                          .runTransaction(
                            TakuPayCommandName.COMMAND_AUTH,
                            saleDocTender?.takuPaymentTerminal?.takuPaymentGateway?.storeId,
                            authJson
                          )
                          .subscribe({
                            next: (response) => {
                              this.progressBar.close();
                              this.isWaiting = false;

                              const responseTransaction: ResponseTransaction = new ResponseTransaction();
                              let taKuDisplayStatus = "";
                              if (apiCommand === TakuPayCommandName.COMMAND_VOID) {
                                if (sMenuType === MenuTenderLineUndoName.SALE_VOID) {
                                  taKuDisplayStatus = TenderLineStatusName.SALE_VOIDED;
                                } else {
                                  taKuDisplayStatus = TenderLineStatusName.REFUND_VOIDED;
                                }
                              } else if (apiCommand === TakuPayCommandName.COMMAND_REFUND) {
                                taKuDisplayStatus = TenderLineStatusName.SALE_REFUNDED;
                              } else {
                                taKuDisplayStatus = responseTransaction.Message;
                              }

                              const result = response["body"]?.["result"];
                              if (
                                result &&
                                (result["status"] === TakuPaymentStatus.STATUS_SUCCESS ||
                                  result["status"] === TakuPaymentStatus.STATUS_CAPTURE_REQUESTED ||
                                  result["status"] === TakuPaymentStatus.STATUS_REQUIRES_CAPTURE)
                              ) {
                                responseTransaction.TerminalID = saleDocTender.takuPaymentTerminal.terminalId;
                                responseTransaction.TransType = TakuPayCommandName.COMMAND_SALE;
                                responseTransaction.TakuDisplayStatus = taKuDisplayStatus;
                                responseTransaction.PaymentId = result["payment_id"];
                                //responseTransaction.AuthCode = result['authorization_code'];
                                responseTransaction.PaymentMethod = result["payment_method_id"];
                                responseTransaction.Amount = result["amount"];

                                responseTransaction.PaymentType = result["payment"]?.["type"];
                                responseTransaction.CardType = result["payment"]?.["brand"];
                                responseTransaction.Last4 = result["payment"]?.["last_4"];
                                responseTransaction.Name = result["payment"]?.["cardholder_name"];
                                responseTransaction.ExpDate = `${result["payment"]?.["exp_month"]}/${result["payment"]?.["exp_year"]}`;

                                responseTransaction.AuthCode = result["payment"]?.["receipt_data"]?.["emv_auth_code"];
                                responseTransaction.RefID =
                                  result["payment"]?.["receipt_data"]?.["emv_reference_number"];
                                responseTransaction.TerminalID = result["payment"]?.["receipt_data"]?.["emv_term_id"];
                                responseTransaction.EMV_App_Label =
                                  result["payment"]?.["receipt_data"]?.["emv_app_label"];
                                responseTransaction.EMV_App_Name = result["payment"]?.["receipt_data"]?.["emv_app_id"];
                                responseTransaction.Cryptogram =
                                  result["payment"]?.["receipt_data"]?.["emv_cryptogram"];
                                responseTransaction.EntryMethod =
                                  result["payment"]?.["receipt_data"]?.["card_entry_mode"];

                                saleDocTender.refTransaction = responseTransaction;
                                saleDocTender.refTransaction["customerReceiptTemplate"] =
                                  this.takuPayService.createPaymentReceipt(saleDocTender.refTransaction, false);
                                saleDocTender.refTransaction["merchantReceiptTemplate"] =
                                  this.takuPayService.createPaymentReceipt(saleDocTender.refTransaction, true);

                                // select correct sale doc tender based on card type
                                const cardType = responseTransaction.CardType;
                                this.storeTenderTypes$
                                  .pipe(
                                    map((storeTenderTypes: StoreTenderType[]) =>
                                      storeTenderTypes.find(
                                        (storeTenderType) =>
                                          storeTenderType.tenderType.type !== Tender_Type.Cash &&
                                          storeTenderType.tenderType.otherType === cardType
                                      )
                                    )
                                  )
                                  .subscribe((paymentTender) => {
                                    if (paymentTender) {
                                      saleDocTender.tenderTypeId = paymentTender.tenderTypeId;
                                      saleDocTender.tenderType = paymentTender.tenderType;
                                    }
                                  });

                                (<UntypedFormArray>this._myForm.get("saleDocTenders")).insert(
                                  1,
                                  SaleDocTenderComponent.set(this.fb, saleDocTender)
                                );
                                // this.closeFullSizeDialog();
                                // this._saveDocAction = { type: SaveDocActionType.PRINT_PREVIEW, data: tenderResult.printSettings }; // After saving it should show print preview
                                this._myForm.get("doc.state").setValue(DocState.finalized);
                                this._myForm.get("cashoutId").setValue(this.appSettingsService.getCashoutId());
                                this._myForm.get("paymentStatus").setValue(PaymentStatus.paid);
                                if (
                                  this._myForm.get("customerSource").value === SaleDocSource.Store &&
                                  !this._myForm.get("fulfillmentStatus").value
                                ) {
                                  this._myForm.get("fulfillmentStatus").setValue(FulfillmentStatus.completed);
                                }
                                this.onSubmit(true).subscribe(() => {
                                  try {
                                    //call payment and invoice print methods here
                                    let pinpadReceipt = saleDocTender.refTransaction["customerReceiptTemplate"];
                                    try {
                                      this.tizenPrintService.openSerialPrint();
                                      if (
                                        pinpadReceipt != null &&
                                        pinpadReceipt != undefined &&
                                        pinpadReceipt.length > 0
                                      ) {
                                        pinpadReceipt = pinpadReceipt.split("\r\n").join("</><e><l>");
                                        pinpadReceipt = pinpadReceipt.substr(6);
                                        pinpadReceipt = pinpadReceipt.substr(0, pinpadReceipt.length - 3);
                                        this.tizenPrintService.writeReceipt(pinpadReceipt);
                                        //this.tizenPrintService.closeSerialPrint();
                                      }
                                    } catch (error) {
                                      // print to browser print
                                      this.messageService.add({
                                        summary: "Printing Issue",
                                        severity: "error",
                                        detail:
                                          "There was an issue printing your receipt. To get a copy of your receipt, please go to the counter and ask for a copy of Order #",
                                      });
                                    }

                                    setTimeout(() => {
                                      //this.tizenPrintService.openSerialPrint();
                                      this.tizenPrintService.writeReceipt(
                                        this.tizenPrintService.createSamsungKioskReceiptText(this._object)
                                      );
                                      //this.tizenPrintService.closeSerialPrint();

                                      this.printMgr = this.printingFactoryService.build(this._object.storeId);
                                      if (
                                        this.appSettingsService.getStation() &&
                                        this.appSettingsService.getStation().prepPrinter?.manufacturer ===
                                          PrinterManufacturer.star_cloudPRNT
                                      ) {
                                        // send print to star printer
                                        this.printMgr.printStarCloudPRNTPrepInBatch([this._object]);
                                      }
                                      if (
                                        this.appSettingsService.getStation() &&
                                        this.appSettingsService.getStation().packingListPrinter?.manufacturer ===
                                          PrinterManufacturer.star_cloudPRNT
                                      ) {
                                        // send print to star printer
                                        this._object["isPackingList"] = true;
                                        this.printMgr.printStarCloudPRNTReceiptInBatch([this._object]);
                                      }

                                      setTimeout(() => {
                                        this._router.navigate(["/kiosk/selfCheckout/landingPage"]);
                                        // this.returnToMainScreen();
                                      }, 1500);
                                    }, 2000);
                                  } catch (error) {
                                    console.log(error);
                                  }
                                });

                                this.messageService.add({
                                  summary: "Success",
                                  severity: "success",
                                  detail: `Transaction Approved.`,
                                });
                              } else {
                                this.messageService.add({
                                  summary: "Error",
                                  severity: "error",
                                  detail: `Transaction Error.`,
                                });

                                this.confirmationService.confirm({
                                  message: "Transaction was declined.",
                                  header: "Confirmation",
                                  rejectButtonStyleClass: "p-button-link",
                                  key: "declineTransaction",
                                  accept: () => {},
                                  reject: () => {},
                                });
                              }
                            },
                            error: (errorResponse) => {
                              this.progressBar.close();
                              this.isWaiting = false;

                              if (errorResponse instanceof HttpErrorResponse && errorResponse.status !== 500) {
                                if (
                                  errorResponse.status == 0 &&
                                  errorResponse.statusText === paymentService.sMesageUnknownError
                                ) {
                                  this.messageService.add(
                                    this.alertMessage.getErrorMessage(
                                      null,
                                      "ERROR",
                                      `${errorResponse.statusText}\nPlease make another VERIFICATION REQUEST.`
                                    )
                                  );
                                } else if (errorResponse.error.error && errorResponse.error.error.message) {
                                  const errorMsg = <string>errorResponse.error.message;
                                  this.messageService.add(
                                    this.alertMessage.getErrorMessage(
                                      null,
                                      "ERROR",
                                      `${errorMsg}\nPlease make another VERIFICATION REQUEST.`
                                    )
                                  );
                                }
                              } else {
                                this.messageService.add(this.alertMessage.getErrorMessage(errorResponse));
                              }
                            },
                          });
                      } else {
                        this.progressBar.close();
                        this.isWaiting = false;

                        if (
                          response &&
                          (response["status"] === TakuPaymentStatus.STATUS_REFUNDED ||
                            response["status"] === TakuPaymentStatus.STATUS_PARTIALLY_REFUNDED ||
                            response["status"] === TakuPaymentStatus.STATUS_REFUND_REQUESTED ||
                            response["status"] === TakuPaymentStatus.STATUS_CANCEL_REQUESTED)
                        ) {
                          const responseTransaction: ResponseTransaction = new ResponseTransaction();
                          responseTransaction.TransType = apiCommand;
                          responseTransaction.TerminalID = saleDocTender.takuPaymentTerminal.terminalId;
                          responseTransaction.PaymentId = response["payment_id"];
                          //responseTransaction.AuthCode = response['authorization_code'];
                          responseTransaction.PaymentMethod = response["payment_method_id"];

                          responseTransaction.CardType = response["payment"]?.["brand"];
                          responseTransaction.Last4 = response["payment"]?.["last_4"];
                          responseTransaction.Name = response["payment"]?.["cardholder_name"];
                          responseTransaction.ExpDate = `${response["payment"]?.["exp_month"]}/${response["payment"]?.["exp_year"]}`;

                          responseTransaction.AuthCode = response["payment"]?.["receipt_data"]?.["emv_auth_code"];
                          responseTransaction.RefID = response["payment"]?.["receipt_data"]?.["emv_reference_number"];
                          responseTransaction.TerminalID = response["payment"]?.["receipt_data"]?.["emv_term_id"];
                          responseTransaction.EMV_App_Label = response["payment"]?.["receipt_data"]?.["emv_app_label"];
                          responseTransaction.EMV_App_Name = response["payment"]?.["receipt_data"]?.["emv_app_id"];
                          responseTransaction.Cryptogram = response["payment"]?.["receipt_data"]?.["emv_cryptogram"];
                          responseTransaction.EntryMethod = response["payment"]?.["receipt_data"]?.["card_entry_mode"];

                          // Make it negative for reversion
                          saleDocTender.amount = -1 * parseFloat(response.amount_refunded);
                          saleDocTender.refTransaction = responseTransaction;
                          saleDocTender.refTransaction["customerReceiptTemplate"] =
                            this.takuPayService.createPaymentReceipt(saleDocTender.refTransaction, false);
                          saleDocTender.refTransaction["merchantReceiptTemplate"] =
                            this.takuPayService.createPaymentReceipt(saleDocTender.refTransaction, true);

                          // select correct sale doc tender based on card type
                          const cardType = responseTransaction.CardType;
                          this.storeTenderTypes$
                            .pipe(
                              map((storeTenderTypes: StoreTenderType[]) =>
                                storeTenderTypes.find(
                                  (storeTenderType) =>
                                    storeTenderType.tenderType.type !== Tender_Type.Cash &&
                                    storeTenderType.tenderType.otherType === cardType
                                )
                              )
                            )
                            .subscribe((paymentTender) => {
                              if (paymentTender) {
                                saleDocTender.tenderTypeId = paymentTender.tenderTypeId;
                                saleDocTender.tenderType = paymentTender.tenderType;
                              }
                            });

                          (<UntypedFormArray>this._myForm.get("saleDocTenders")).insert(
                            1,
                            SaleDocTenderComponent.set(this.fb, saleDocTender)
                          );
                          // this.closeFullSizeDialog();
                          // this._saveDocAction = { type: SaveDocActionType.PRINT_PREVIEW, data: tenderResult.printSettings }; // After saving it should show print preview
                          this._myForm.get("doc.state").setValue(DocState.finalized);
                          this._myForm.get("cashoutId").setValue(this.appSettingsService.getCashoutId());
                          this._myForm.get("paymentStatus").setValue(PaymentStatus.paid);
                          if (
                            this._myForm.get("customerSource").value === SaleDocSource.Store &&
                            !this._myForm.get("fulfillmentStatus").value
                          ) {
                            this._myForm.get("fulfillmentStatus").setValue(FulfillmentStatus.completed);
                          }
                          this.onSubmit(true).subscribe(() => {
                            try {
                              //call payment and invoice print methods here
                              let pinpadReceipt = saleDocTender.refTransaction["customerReceiptTemplate"];
                              try {
                                this.tizenPrintService.openSerialPrint();
                                if (pinpadReceipt != null && pinpadReceipt != undefined && pinpadReceipt.length > 0) {
                                  pinpadReceipt = pinpadReceipt.split("\r\n").join("</><e><l>");
                                  pinpadReceipt = pinpadReceipt.substr(6);
                                  pinpadReceipt = pinpadReceipt.substr(0, pinpadReceipt.length - 3);
                                  this.tizenPrintService.writeReceipt(pinpadReceipt);
                                  //this.tizenPrintService.closeSerialPrint();
                                }
                              } catch (error) {
                                // print to browser print
                                this.messageService.add({
                                  summary: "Printing Issue",
                                  severity: "error",
                                  detail:
                                    "There was an issue printing your receipt. To get a copy of your receipt, please go to the counter and ask for a copy of Order #",
                                });
                              }

                              setTimeout(() => {
                                //this.tizenPrintService.openSerialPrint();
                                this.tizenPrintService.writeReceipt(
                                  this.tizenPrintService.createSamsungKioskReceiptText(this._object)
                                );
                                //this.tizenPrintService.closeSerialPrint();

                                this.printMgr = this.printingFactoryService.build(this._object.storeId);
                                if (
                                  this.appSettingsService.getStation() &&
                                  this.appSettingsService.getStation().prepPrinter?.manufacturer ===
                                    PrinterManufacturer.star_cloudPRNT
                                ) {
                                  // send print to star printer
                                  this.printMgr.printStarCloudPRNTPrepInBatch([this._object]);
                                }
                                if (
                                  this.appSettingsService.getStation() &&
                                  this.appSettingsService.getStation().packingListPrinter?.manufacturer ===
                                    PrinterManufacturer.star_cloudPRNT
                                ) {
                                  // send print to star printer
                                  this._object["isPackingList"] = true;
                                  this.printMgr.printStarCloudPRNTReceiptInBatch([this._object]);
                                }

                                setTimeout(() => {
                                  this._router.navigate(["/kiosk/selfCheckout/landingPage"]);
                                  // this.returnToMainScreen();
                                }, 1500);
                              }, 2000);
                            } catch (error) {}
                          });

                          this.messageService.add({
                            summary: "Success",
                            severity: "success",
                            detail: `Transaction Approved.`,
                          });
                        } else {
                          this.messageService.add({
                            summary: "Error",
                            severity: "error",
                            detail: `Transaction Error.`,
                          });
                        }
                      }
                    },
                    error: (errorResponse) => {
                      this.progressBar.close();
                      this.isWaiting = false;

                      if (errorResponse instanceof HttpErrorResponse && errorResponse.status !== 500) {
                        if (
                          errorResponse.status == 0 &&
                          errorResponse.statusText === paymentService.sMesageUnknownError
                        ) {
                          this.messageService.add(
                            this.alertMessage.getErrorMessage(
                              null,
                              "ERROR",
                              `${errorResponse.statusText}\nPlease make another VERIFICATION REQUEST.`
                            )
                          );
                        } else if (errorResponse.error.error && errorResponse.error.error.message) {
                          const errorMsg = <string>errorResponse.error.message;
                          this.messageService.add(
                            this.alertMessage.getErrorMessage(
                              null,
                              "ERROR",
                              `${errorMsg}\nPlease make another VERIFICATION REQUEST.`
                            )
                          );
                        }
                      } else {
                        this.messageService.add(this.alertMessage.getErrorMessage(errorResponse));
                      }
                    },
                  })
              );
            } else {
              //-------------------------------------------------------------------------------------------------
              // Payment Gateway Processing (MONERIS, PAYROC)
              //-------------------------------------------------------------------------------------------------
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              this.subsList.push(
                paymentService.runTransaction(apiCommand, saleDocTender.paymentTerminalId, apiBody).subscribe({
                  next: (response) => {
                    // const responseTransaction: ResponseTransaction = Object.assign({}, response);
                    this.progressBar.close();
                    this.isWaiting = false;
                    const responseTransaction: ResponseTransaction = new ResponseTransaction();

                    responseTransaction.AuthCode = response["AuthCode"];
                    responseTransaction.CardType = response["CardName"];
                    responseTransaction.Last4 = response["Pan"];
                    responseTransaction.RefID = response["ReferenceNumber"];
                    if (response && response["Error"] === "false" && response["ResponseCode"]) {
                      if (response["ResponseCode"] == null) {
                        this.messageService.add({
                          summary: "Incomplete",
                          severity: "error",
                          detail: "Request is incomplete", // paymentService.getResponseErrorCode(response['ErrorCode'])
                        });
                      } else if (Number(response["ResponseCode"]) < 50) {
                        this.messageService.add({
                          summary: "Success",
                          severity: "success",
                          detail: `Transaction Approved`,
                        });

                        let taKuDisplayStatus = "";
                        if (apiCommand === CommandName.COMMAND_VOID) {
                          if (sMenuType === MenuTenderLineUndoName.SALE_VOID) {
                            taKuDisplayStatus = TenderLineStatusName.SALE_VOIDED;
                          } else {
                            taKuDisplayStatus = TenderLineStatusName.REFUND_VOIDED;
                          }
                        } else if (apiCommand === CommandName.COMMAND_REFUND) {
                          taKuDisplayStatus = TenderLineStatusName.SALE_REFUNDED;
                        } else {
                          taKuDisplayStatus = responseTransaction.Message;
                        }
                        responseTransaction.TransType = apiCommand;
                        responseTransaction.TakuDisplayStatus = taKuDisplayStatus;
                        saleDocTender.refTransaction = responseTransaction;
                        saleDocTender.refTransaction["customerReceiptTemplate"] = response["customerReceiptTemplate"];
                        saleDocTender.refTransaction["merchantReceiptTemplate"] = response["merchantReceiptTemplate"];

                        if (response["PartialAuthAmount"]) {
                          saleDocTender.amount = Number(response["PartialAuthAmount"]);
                        }

                        // select correct sale doc tender based on card type
                        const cardType = this.monerisService.getCardtype(response["CardType"].trim());
                        this.storeTenderTypes$
                          .pipe(
                            map((storeTenderTypes: StoreTenderType[]) =>
                              storeTenderTypes.find(
                                (storeTenderType) =>
                                  storeTenderType.tenderType.type !== Tender_Type.Cash &&
                                  storeTenderType.tenderType.otherType === cardType
                              )
                            )
                          )
                          .subscribe((paymentTender) => {
                            if (paymentTender) {
                              saleDocTender.tenderTypeId = paymentTender.tenderTypeId;
                              saleDocTender.tenderType = paymentTender.tenderType;
                            }
                          });
                        // this.paymentPageActiveIndex = 2;

                        // needs editing
                        //   if (index > 0) {
                        //   this.tendersFormArray.insert(index + 1, SaleDocTenderComponent.set(this.fb, saleDocTender));
                        // } else {
                        //   this.tendersFormArray.push(SaleDocTenderComponent.set(this.fb, saleDocTender));
                        // }
                        (<UntypedFormArray>this._myForm.get("saleDocTenders")).insert(
                          1,
                          SaleDocTenderComponent.set(this.fb, saleDocTender)
                        );
                        // this.closeFullSizeDialog();
                        // this._saveDocAction = { type: SaveDocActionType.PRINT_PREVIEW, data: tenderResult.printSettings }; // After saving it should show print preview
                        this._myForm.get("doc.state").setValue(DocState.finalized);
                        this._myForm.get("cashoutId").setValue(this.appSettingsService.getCashoutId());
                        this._myForm.get("paymentStatus").setValue(PaymentStatus.paid);
                        if (
                          this._myForm.get("customerSource").value === SaleDocSource.Store &&
                          !this._myForm.get("fulfillmentStatus").value
                        ) {
                          this._myForm.get("fulfillmentStatus").setValue(FulfillmentStatus.completed);
                        }
                        this.onSubmit(true).subscribe(() => {
                          try {
                            //call payment and invoice print methods here
                            let pinpadReceipt = saleDocTender.refTransaction["customerReceiptTemplate"];
                            try {
                              this.tizenPrintService.openSerialPrint();
                              if (pinpadReceipt != null && pinpadReceipt != undefined && pinpadReceipt.length > 0) {
                                pinpadReceipt = pinpadReceipt.split("\r\n").join("</><e><l>");
                                pinpadReceipt = pinpadReceipt.substr(6);
                                pinpadReceipt = pinpadReceipt.substr(0, pinpadReceipt.length - 3);
                                this.tizenPrintService.writeReceipt(pinpadReceipt);
                                //this.tizenPrintService.closeSerialPrint();
                              }
                            } catch (error) {
                              // print to browser print
                              this.messageService.add({
                                summary: "Printing Issue",
                                severity: "error",
                                detail:
                                  "There was an issue printing your receipt. To get a copy of your receipt, please go to the counter and ask for a copy of Order #",
                              });
                            }

                            setTimeout(() => {
                              //this.tizenPrintService.openSerialPrint();
                              this.tizenPrintService.writeReceipt(
                                this.tizenPrintService.createSamsungKioskReceiptText(this._object)
                              );
                              //this.tizenPrintService.closeSerialPrint();

                              this.printMgr = this.printingFactoryService.build(this._object.storeId);
                              if (
                                this.appSettingsService.getStation() &&
                                this.appSettingsService.getStation().prepPrinter?.manufacturer ===
                                  PrinterManufacturer.star_cloudPRNT
                              ) {
                                // send print to star printer
                                this.printMgr.printStarCloudPRNTPrepInBatch([this._object]);
                              }
                              if (
                                this.appSettingsService.getStation() &&
                                this.appSettingsService.getStation().packingListPrinter?.manufacturer ===
                                  PrinterManufacturer.star_cloudPRNT
                              ) {
                                // send print to star printer
                                this._object["isPackingList"] = true;
                                this.printMgr.printStarCloudPRNTReceiptInBatch([this._object]);
                              }

                              setTimeout(() => {
                                this._router.navigate(["/kiosk/selfCheckout/landingPage"]);
                                // this.returnToMainScreen();
                              }, 1500);
                            }, 2000);
                          } catch (error) {}
                        });
                      } else {
                        this.messageService.add({
                          summary: "Declined",
                          severity: "error",
                          detail: "Transaction Declined.", // paymentService.getResponseErrorCode(response['ErrorCode'])
                        });
                        this.confirmationService.confirm({
                          message: "Transaction was declined.",
                          header: "Confirmation",
                          rejectButtonStyleClass: "p-button-link",
                          key: "declineTransaction",
                          accept: () => {},
                          reject: () => {},
                        });
                      }
                    } else {
                      // Illegal case check, recall status
                      if (
                        responseTransaction.ResultCode === paymentService.sResultCodeError &&
                        !JSON.parse(responseTransaction.ResultFinal)
                      ) {
                      }

                      this.messageService.add({
                        summary: "Error",
                        severity: "error",
                        detail: paymentService.getResponseErrorCode(response["ErrorCode"]),
                      });
                    }
                    // this.saleDocComponent.onSave();
                    // this.saveChanges.emit();
                    // this.selectedTender = null;
                  },
                  error: (errorResponse) => {
                    this.progressBar.close();
                    this.isWaiting = false;
                    if (errorResponse instanceof HttpErrorResponse && errorResponse.status !== 500) {
                      // This case net::ERR_FAILED
                      if (
                        errorResponse.status == 0 &&
                        errorResponse.statusText === paymentService.sMesageUnknownError
                      ) {
                        this.messageService.add(
                          this.alertMessage.getErrorMessage(
                            null,
                            "ERROR",
                            `${errorResponse.statusText}\nPlease make another VERIFICATION REQUEST.`
                          )
                        );
                      } else if (errorResponse.error.error && errorResponse.error.error.message) {
                        const errorMsg = <string>errorResponse.error.message;
                        this.messageService.add(
                          this.alertMessage.getErrorMessage(
                            null,
                            "ERROR",
                            `${errorMsg}\nPlease make another VERIFICATION REQUEST.`
                          )
                        );
                      }
                    } else {
                      this.messageService.add(this.alertMessage.getErrorMessage(errorResponse));
                    }
                  },
                })
              );
            }
          }
        })
    );
  }

  convertInventoryToSaleDocLine(inventory: Inventory): UntypedFormGroup {
    const saleLine: SaleDocLine = _.merge(new SaleDocLine(), {
      unitPrice: inventory.standardPrice,
      qty: 0,
      docLine: {
        id: 0,
        seqNo:
          (this.saleDocLines.value.length
            ? Math.max(
                ...this.saleDocLines.value.map((row) => {
                  return row.docLine.seqNo;
                })
              )
            : 0) + 1,
        note: "",
        expiryDate: null,
        serialNo: "",
        refNo: 0,
        inventoryId: inventory.id,
        inventory: inventory,
        userId: this._object.doc.userId,
      },
    });

    const docLineForm = SaleDocLineComponent.set(this.fb, saleLine);
    return docLineForm;
  }

  addToSaleDocFromAddOns(itemInfo: { inventory: Inventory; addQtyAmount: number }) {
    this.addToSaleDoc(itemInfo.inventory);

    // if (itemInfo.addQtyAmount !== 1) {
    const newItem = this.saleDocLines["controls"].find(
      (row) => row.value.docLine.inventoryId === itemInfo.inventory.id
    );
    newItem.get("qty").setValue(itemInfo.addQtyAmount);
    // }
  }

  onLineQtyChanged(newQty: number, saleDocLineForm: UntypedFormGroup) {
    // update corresponding addon item formgroup value
    const addOnCtrl = this.addOnsForm["controls"].find(
      (row) => row.value.docLine.inventoryId === saleDocLineForm.value.docLine.inventoryId
    );
    if (addOnCtrl) {
      addOnCtrl.get("qty").setValue(newQty);
    }

    super.onLineQtyChanged(newQty, saleDocLineForm);
  }

  deleteInvoiceLine(index: number, saleDocLineForm?: UntypedFormGroup) {
    // if deleted from cart, check
    if (saleDocLineForm) {
      const addOnCtrl = this.addOnsForm["controls"].find(
        (row) => row.value.docLine.inventoryId === saleDocLineForm.value.docLine.inventoryId
      );
      if (addOnCtrl) {
        addOnCtrl.get("qty").setValue(0);
      }
    }

    super.deleteInvoiceLine(index);
  }

  get storeName() {
    return this.activeStore.storeName;
  }

  get printCtrl() {
    return this.tenderSettingsForm.get("print");
  }

  get printingSizeCtrl() {
    return this.tenderSettingsForm.get("printingSize");
  }

  get giftReceiptCtrl() {
    return this.tenderSettingsForm.get("giftReceipt");
  }

  get ctrlEmailAddress() {
    return this.tenderSettingsForm.get("emailAddress");
  }

  get ctrlSendEmail() {
    return this.tenderSettingsForm.get("sendEmail");
  }

  get _countTotalItems(): number {
    let countItems = 0;
    this.saleDocLines.controls.forEach((docLineForm: UntypedFormGroup) => {
      const qty = parseFloat(docLineForm.get("qty").value);
      if (!isNaN(qty)) countItems += qty;
    });

    return MonetaryHelpers.roundToDecimalPlaces(countItems, 4);
  }

  openProductDetailsFromCart(saleForm: UntypedFormGroup, isMobile: boolean, isAddOnItem = false) {
    const originalQty = saleForm.get("qty").value;
    const dlgStyleClass = isMobile
      ? "selfcheckout-mobile rounded-selfcheckout-dialog"
      : "selfcheckout-desktop rounded-selfcheckout-dialog";

    this.ref = this.dialogService.open(SelfCheckoutItemDetailsComponent, {
      styleClass: dlgStyleClass,
      contentStyle: {
        overflow: "auto",
        height: "60%",
        minWidth: "280px",
        maxWidth: "680px",
        maxHeight: "1100px",
      },
      data: {
        _saleForm: saleForm,
        _isAddOnItem: isAddOnItem,
      },
    });

    this.subsList.push(
      this.ref.onClose.subscribe((doneBtnPressed) => {
        if (doneBtnPressed === undefined) {
          saleForm.patchValue({ qty: originalQty });
        }
      })
    );
  }

  onShowItemsPressed() {
    this._showItemsClicked = !this._showItemsClicked;
  }

  onSelfCheckoutPay(ref) {
    this.saleDocTotal = ref.invoiceTotal;
    ref.saleForm.valueChanges.subscribe((value) => {
      ref.updateInvoiceSubTotal();
      ref.updateInvoiceTotalTax();
      this.saleDocTotal = ref.invoiceTotal;
    });
    this._showPaymentPage = true;
    this._showItemsClicked = true;
  }

  returnToMainScreen() {
    this._clearSaleDocLines();
    this.returnToCart();
    this.addOnsForm = _.cloneDeep(this.originalAddOnsForm);
    this.allowRedirectToMain = false;
    this.payAtCounterChosen = false;
  }

  returnToCart() {
    this._showPaymentPage = false;
    this._showItemsClicked = false;
    this.paymentPageActiveIndex = 0;
    this.userReadyToPay = false;
    this.idleService.reset();
    this.ref.close();
    this.onReturnToCart.emit();
  }

  get storeLogoUrl() {
    const store = this.appSettingsService.getStore();
    // First try to use the Store's logo, if not available fallback to Business's logo
    const logoUrl = store.urlStoreLogoUrl150;
    return logoUrl; // WebHelpers.buildImageUrl(logoUrl);
  }

  onGreenBtnClicked() {
    if (this.paymentPageActiveIndex === 0) {
      this.addTenderTransaction(this.saleDocTotal, this.selectedTender);
      // this.paymentPageActiveIndex++;
      this.userReadyToPay = true;
      this._showItemsClicked = false;
    } else {
      this.addTenderTransaction(this.saleDocTotal, this.selectedTender);
      // this.paymentPageActiveIndex++;
      // this.userReadyToPay = true;
      // this._showItemsClicked = false;
    }
    this.idleService.stop();
  }

  onTopRightMenuIconClicked() {
    this._router.navigate(["/dashboard"]);
    // this.ref = this.dialogService.open(KioskDeactivateDialogComponent, {
    //   // styleClass: 'fullscreen-mobilemodal dialogSidebar',
    //   // contentStyle: {
    //   //   overflow: 'auto',
    //   //   height: 'calc(100vh - 40pt)',
    //   //   minWidth: '580pt',
    //   // },
    //   header: "Enter password to exit kiosk mode",
    //   data: {
    //     exitPw: this.exitPassword,
    //   },
    //   closable: false,
    //   closeOnEscape: false,
    // });
    // this.ref.onClose.subscribe(() => {
    //   console.log("pw dialog closed!");
    // });
  }

  onClearLines(e) {
    if (e.screenX === 0 || e.screenY === 0) {
      // trick to avoid showing dialog on key Enter key presses
      return false;
    }
    this.keyboardActionsService._canAnnounceNumKeyPress = false;
    // alert the user first
    this.confirmationService.confirm({
      message: "Do you want to delete the entire invoice?",
      header: "Delete Confirmation",
      icon: "ui-icon-delete-forever",
      rejectButtonStyleClass: "p-button-link",
      accept: () => {
        this.keyboardActionsService._canAnnounceNumKeyPress = true;
        this._clearSaleDocLines();
        this.onStartOver.emit(true);
      },
      reject: () => {
        this.keyboardActionsService._canAnnounceNumKeyPress = true;
        this.msgs = [{ severity: "info", summary: "Rejected", detail: "You have rejected the delete" }];
        this.messageService.addAll(this.msgs);
        this.onStartOver.emit(false);
      },
    });
  }

  _onDocStored(saleDocData) {
    super._onDocStored(saleDocData);
    // this is for redirection to main screen after printing
    if (this.allowRedirectToMain) {
      this.returnToMainScreen();
    }
  }
  saveAndPrintDoc() {
    const printSettings = <TenderPrintingSettings>this.tenderSettingsForm.value;
    this._saveDocAction = { type: SaveDocActionType.PRINT_PREVIEW, data: printSettings }; // After saving it should show print preview
    this._myForm.get("doc.state").setValue(DocState.finalized);
    this._myForm.get("cashoutId").setValue(this.appSettingsService.getCashoutId());
    this._myForm.get("paymentStatus").setValue(PaymentStatus.paid);
    if (
      this._myForm.get("customerSource").value === SaleDocSource.Store &&
      !this._myForm.get("fulfillmentStatus").value
    ) {
      this._myForm.get("fulfillmentStatus").setValue(FulfillmentStatus.completed);
    }
    this.allowRedirectToMain = true;
    this.onSave();
  }

  saveAndSendEmail() {
    this.sendEmailBtnClicked = true;
    this.onSave();
  }

  _onEmailDoc(saleDoc: SaleDoc) {
    // let currSaleDoc: SaleDoc = Object.assign(new SaleDoc, this._myForm.value);
    // console.log(currSaleDoc);
    const dialogRef = this.dialogService.open(FormDialogInputComponent, {
      showHeader: true,
      header: "Enter email address",
      data: {
        caption: "Email Address",
        message: "",
        acceptLabel: "Send Email",
        inputType: "email",
        required: true,
        // pattern:'^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'
        // rejectVisible: false,
      },
    });
    this.subsList.push(
      dialogRef.onClose.subscribe((result) => {
        this.sendEmailBtnClicked = false;
        if (result) {
          this.subsList.push(
            this.revisitSaleService.getRow("saleDoc", saleDoc.id).subscribe((data) => {
              const saleDoc = SaleDoc.convertIntoTypedObject(data);
              this.printMgr = this.printingFactoryService.build(saleDoc.storeId);
              this.subsList.push(
                this.printMgr
                  .sendInvoiceEmail(saleDoc, result, this.printPreviewHost, ReceiptPrintingSize.TAPE_SIZE)
                  .subscribe({
                    next: (response) => {
                      // Do nothing for NOW
                      this.returnToMainScreen();
                    },
                    error: (err) => {
                      console.log(err);
                    },
                    complete: () => {},
                  })
              );
            })
          );
        }
      })
    );
  }
  // onPark() {
  //   if (this.saleDocLines.length > 0) {
  //     if (_.sumBy(this._myForm.value.saleDocTenders, (row:SaleDocTender) => parseFloat(row.amount + '')) != 0) {
  //       this.confirmationService.confirm({
  //         header: 'Note',
  //         message: 'Transactions that have payments applied cannot be parked. To park this transaction, reverse all applied payments or clear the salesscreen <span class="material-icons delete-icon">delete_forever</span> to start a new transaction.',
  //         rejectVisible: false,
  //         acceptLabel: 'OK',
  //         accept: () => {
  //           // DO Nothing
  //         }
  //       });
  //     } else {
  //       this.confirmationService.confirm({
  //         header: 'Confirmation to Park Sale',
  //         message: 'Are you sure you want to PARK this sale?',
  //         rejectButtonStyleClass: 'p-button-link',
  //         accept: () => {
  //           // this._saveDocAction = { type: SaveDocActionType.RESET_SALEDOC };
  //           this._myForm.get('doc.state').setValue(DocState.draft);
  //           // console.log(this._myForm);
  //           this.onSave({ silentMode: false });
  //           this.onDocParked.emit();
  //           this.payAtCounterChosen = true;
  //           this.onGreenBtnClicked();
  //         }
  //       });
  //     }
  //   }
  //   else
  //     this.confirmationService.confirm({
  //       header: 'Parking Empty Invoice',
  //       message: 'Cannot park this invoice because it does not contain transaction lines',
  //       rejectVisible: false,
  //       acceptLabel: 'OK',
  //       accept: () => {
  //         // DO Nothing
  //       }
  //     });
  // }

  payAtCounterClicked() {
    // this.onPark();
    this._myForm.get("doc.state").setValue(DocState.draft);
    // console.log(this._myForm);
    this._showItemsClicked = false;
    this.paymentPageActiveIndex = 1;
    this.payAtCounterChosen = true;
    this._isNewSaleButtonActive = false;
    this.onSubmit(true).subscribe(() => {
      this.onSave({ silentMode: false });
      this.onDocParked.emit();
      setTimeout(() => {
        this._isNewSaleButtonActive = true;
      }, 2000);
      // this.onGreenBtnClicked();
      //-------------------------------------------------------------------------------------------------
      //2022/05/31 Vinh added lines to fix ticket [TPT-2778 Samsung Documentation for Scanner/Printer API]
      try {
        this.tizenPrintService.openSerialPrint();
        this.tizenPrintService.writeReceipt(this.tizenPrintService.createSamsungKioskOrderReceiptText(this._object));
        //this.tizenPrintService.closeSerialPrint();
      } catch (error) {}
      //-------------------------------------------------------------------------------------------------
      setTimeout(() => {
        if (this.payAtCounterChosen) {
          this._router.navigate(["/kiosk/selfCheckout/landingPage"]);
        }
        // this.returnToMainScreen();
      }, 7000);
    });
  }

  redirectToSalescreenForParked() {
    const saleDocId = this._myForm.value.id;
    this._router.navigate(["/sell/saleDoc", saleDocId]);
  }

  get parkDocTime() {
    let temp = this._myForm.value.doc.docTime;
    temp = temp.replace(":", "");
    return temp;
  }

  get dailyNo() {
    return this._myForm.value.dailyNo;
  }

  ngOnDestroy(): void {
    this.ref?.close(false);
    super.ngOnDestroy();
  }
}
