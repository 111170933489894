import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { Category } from "src/app/core/inventory/category/category";
import { Inventory } from "src/app/core/inventory/inventory/inventory";
import { TizenBarcodeService } from "src/app/shared/services/tizen/tizen-barcode.service";
import { TizenPrintService } from "src/app/shared/services/tizen/tizen-print.service";
import { MonetaryHelpers } from "src/app/utility/MonetaryHelpers";
import { WebHelpers } from "src/app/utility/WebHelpers";
import { DataViewLayoutOptions } from "../sale-doc-list/sale-doc-list.component";

@Component({
  selector: "taku-data-view-base",
  templateUrl: "./data-view-base.component.html",
  styleUrls: ["./data-view-base.component.scss"],
})
export class DataViewBaseComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  subsList: Subscription[] = [];
  protected static readonly SELF_CHECKOUT_GRID_CARD_WIDTH_OFFSET = 16;
  protected static readonly GALLERY_GRID_CARD_WIDTH_OFFSET = 28;
  protected static readonly GALLERY_GRID_CARD_HEIGHT_OFFSET = 16;

  _showClearButton = false;
  @Input() inSelfCheckout = false;
  @Input() showDataViewHeader = true;

  @Input() rows: number;
  rowsArray: number[];
  // @Input() totalRecords: number;
  @Input() productsLoading = false;
  @Input() saleForm: UntypedFormGroup;
  @Input() showEmptyMessage: boolean;

  @ViewChild("searchInput") searchField: ElementRef;
  selfCheckoutSearchForm: UntypedFormGroup;
  searchFieldControl;

  @Output() onSearchResults: EventEmitter<any> = new EventEmitter();
  @Output() onItemSelected: EventEmitter<Inventory | Category> = new EventEmitter();
  @Output() onLazyLoad: EventEmitter<any> = new EventEmitter();
  @Output() onSearchSubmit: EventEmitter<any> = new EventEmitter();
  @Output() onBackToCategories: EventEmitter<any> = new EventEmitter();
  @Output() onLayoutChange: EventEmitter<any> = new EventEmitter();

  // for mobile only
  @Output() openShoppingCart: EventEmitter<any> = new EventEmitter();

  fb: UntypedFormBuilder;

  @Input() dvContainerWidth: number;
  @Input() dvContainerHeight: number;
  @Input() dvLayoutOption = DataViewLayoutOptions.GRID;

  preferredListWidth = 320;
  preferredListHeight = 105;

  @ContentChild("saleDocGridCardTemplate") gridCardTemplate: TemplateRef<ElementRef>;
  @ContentChild("saleDocListCardTemplate") listCardTemplate: TemplateRef<ElementRef>;

  @Input() roundBtnsAndSearchField = true;
  @Input() searchFieldPlaceholder = "Tap or Scan Products";

  constructor(
    fb: UntypedFormBuilder,
    private tizenBarcodeService: TizenBarcodeService,
    private tizenPrintService: TizenPrintService
  ) {
    this.fb = fb;
  }

  ngOnInit(): void {
    // this.subsList.push(this.searchFieldControl.valueChanges.subscribe((value) => {
    //   if (value !== '') {
    //     this._showClearButton = true;
    //   } else {
    //     this._showClearButton = false;
    //   }
    //   // console.log(this._showClearButton);
    // }));
    // console.log(this.saleForm);
  }

  printTestReceipt() {
    this.tizenPrintService.openSerialPrint();
    this.tizenPrintService.writeReceipt("");
    this.tizenPrintService.closeSerialPrint();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["rows"]) {
      this.rowsArray = Array(this.rows)
        .fill(0)
        .map((x, i) => i);
    }
  }

  ngAfterViewInit(): void {
    // Setting up subscription for input value to see when to show clear button to the user
    this.selfCheckoutSearchForm = this.fb.group({
      searchField: new UntypedFormControl(""),
    });
    this.searchFieldControl = this.selfCheckoutSearchForm.controls["searchField"];

    this.subsList.push(
      this.searchFieldControl.valueChanges.subscribe((value) => {
        if (value !== "") {
          this._showClearButton = true;
        } else {
          this._showClearButton = false;
        }
        // console.log(this._showClearButton);
      })
    );
    this.autoSelectSearchField();
  }

  autoSelectSearchField() {
    if (this.showDataViewHeader) {
      setTimeout(() => {
        this.searchField.nativeElement.select();
        this.tizenBarcodeService.startContinuousBarcodeScanning(this.searchFieldControl, this.searchField);
      }, 0);
    }
  }

  onClearIconClicked() {
    this.clearSearchField();
    this.autoSelectSearchField();
  }

  clearSearchField() {
    this.searchField.nativeElement.value = "";
    this.searchFieldControl?.setValue("");
    this._showClearButton = false;
  }

  get preferredGridLength() {
    if (this.isMobile) {
      if (window.innerWidth < 416) {
        // return 105;
        return !this.inSelfCheckout ? 140 : 105;
      } else {
        return 176;
      }
    } else {
      // change value for ranges of ipad widths so that more grid-cards are shown in
      // salesscreen
      if (992 <= window.innerWidth && window.innerWidth < 1200 && !this.inSelfCheckout) {
        return 150;
      } else {
        return !this.inSelfCheckout ? 158 : 196;
      }
    }
  }

  newFetchLimit(dvLayoutOption?: string): number {
    // console.log(this.widthAfterOffset, this.heightAfterOffset, this.preferredGridLength);
    // console.log(this.desiredNumOfGridCols, this.desiredNumOfGridRows)
    // let result = this.desiredNumOfGridCols * this.desiredNumOfGridRows;
    // return result;
    let result;
    if (dvLayoutOption) {
      // grid item
      if (dvLayoutOption === DataViewLayoutOptions.GRID) {
        result = this.desiredNumOfGridCols * this.desiredNumOfGridRows;
      }
      // list item
      else {
        result = this.desiredNumOfListCols * this.desiredNumOfListRows;
      }
    } else {
      // calculate grid fetch limit by default if no parameter was provided
      result = this.desiredNumOfGridCols * this.desiredNumOfGridRows;
    }
    // console.log(result);
    return result;
  }

  get desiredNumOfGridCols() {
    const numOfCols = Math.floor(this.dvContainerWidth / this.preferredGridLength);
    return numOfCols <= 3 ? 3 : numOfCols;
  }

  get desiredNumOfGridRows() {
    const number = Math.floor(this.heightAfterOffset / (this.preferredGridLength - 35));

    return number <= 2 ? 2 : number;
  }

  get heightAfterOffset() {
    // let lengthToRemove = this.isMobile ? 190 : 200;
    // // lengthToRemove += 85; // 85 is the height of pagination
    // if (!this.isMobile) {
    //   if (this.isKioskDimensions()) {
    //     lengthToRemove += 65;
    //   } else {
    //     lengthToRemove += 20;
    //   }
    // } else {
    //   lengthToRemove += 75;
    // }
    // let heightToUse = this.dvContainerHeight;
    // let result = heightToUse - lengthToRemove;
    // // console.log(heightToUse, lengthToRemove);
    // return result;
    return this.dvContainerHeight - 57;
  }

  get gridItemWidth() {
    const result = this.dvContainerWidth / this.desiredNumOfGridCols;
    return this.inSelfCheckout
      ? result - DataViewBaseComponent.SELF_CHECKOUT_GRID_CARD_WIDTH_OFFSET
      : result - DataViewBaseComponent.GALLERY_GRID_CARD_WIDTH_OFFSET;
    // return this.isMobile ? result - 16 : result - 8;
  }
  get gridItemHeight() {
    // console.log(this.heightAfterOffset, this.desiredNumOfGridRows)
    const result = this.heightAfterOffset / this.desiredNumOfGridRows;
    return this.inSelfCheckout
      ? result - DataViewBaseComponent.SELF_CHECKOUT_GRID_CARD_WIDTH_OFFSET
      : result - DataViewBaseComponent.GALLERY_GRID_CARD_HEIGHT_OFFSET;
    // return result - 28;
  }

  get listItemWidth() {
    // console.log(this.widthAfterOffset, this.desiredNumOfListCols);
    return this.dvContainerWidth / this.desiredNumOfListCols - 50;
  }
  get listItemHeight() {
    return this.heightAfterOffset / this.desiredNumOfListRows;
  }

  get desiredNumOfListCols() {
    const result = Math.max(1, Math.floor(this.dvContainerWidth / this.preferredListWidth));
    // console.log(result);
    return result;
    // return Math.max(1, Math.floor(this.widthAfterOffset / this.preferredListWidth));
  }
  get desiredNumOfListRows() {
    return Math.max(1, Math.floor(this.heightAfterOffset / this.preferredListHeight));
  }

  get isMobile() {
    // return !WebHelpers.isDesktopScreen();
    return WebHelpers.isMobileScreen();
  }

  // isKioskDimensions() {
  //   return window.innerWidth === 1080 && window.innerHeight === 1920;
  // }

  onHomeBtnClicked() {
    this.onBackToCategories.emit();
    this.clearSearchField();
  }

  onChangeLayout(e) {
    this.onLayoutChange.emit(e);
  }

  focusSearchInput() {
    this.searchField.nativeElement.focus();
  }

  get saleDocLines(): UntypedFormArray {
    return this.saleForm.get("saleDocLines") as UntypedFormArray;
  }

  // for mobile only
  get _countTotalItems(): number {
    let countItems = 0;
    this.saleDocLines.controls.forEach((docLineForm: UntypedFormGroup) => {
      const qty = parseFloat(docLineForm.get("qty").value);
      if (!isNaN(qty)) countItems += qty;
    });

    return MonetaryHelpers.roundToDecimalPlaces(countItems, 4);
  }

  // for mobile only
  onShoppingCartClicked() {
    this.openShoppingCart.emit();
  }

  ngOnDestroy(): void {
    this.tizenBarcodeService.stopBarcode();
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}
