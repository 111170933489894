/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Inject, Injectable, LOCALE_ID, NgZone } from "@angular/core";
import { ValidatorFn, Validators } from "@angular/forms";
import * as _ from "lodash";
import { Message, MessageService, SelectItem } from "primeng/api";
import { Observable, of, throwError } from "rxjs";
import { catchError, concatMap, delay, map, mapTo, mergeMap, tap } from "rxjs/operators";
import {
  AutocompleteDatatypeOptions,
  AutocompleteTransformer,
  Col,
  DataType,
  ExecuteEventDatatypeOptions,
  ExecuteEvent_DisplayMode,
  FilterType,
  ChipsDatatypeOptions,
} from "../../../../../form-list/form-list/form-list";
import { FormListModel } from "../../../../../form-list/form-list/form-list-model.interface";
import { AppSettingsStorageService } from "../../../../../shared/app-settings-storage.service";
import { AlertMessagesService } from "../../../../../shared/services/alert-messages.service";
import { AuthService } from "../../../../../shared/services/auth.service";
import { CurrencyFormatterService } from "../../../../../shared/services/currency-formatter.service";
import { GMCHelpers } from "../../../../../utility/GMCHelpers";
import { Inventory, GoogleShoppingTitleTemplate } from "../../../../inventory/inventory/inventory";
import { InventoryService } from "../../../../inventory/inventory/inventory.service";
import { GMBCategory } from "../../google-my-business/gmb-locations-list/GMBLocationsService";
import { BackendGMCService } from "../BackendGMCService";
import { ContentAPI_ProductStatuses } from "../gmc-api-data-definitions/ContentAPI_ProductStatutes";
import { GoogleContentAPIService } from "../GoogleContentAPIService";
import { InventoryBarcodeService } from "src/app/core/inventory/inventory-barcode/InventoryBarcode.service";
import { InventoryStockService } from "src/app/core/inventory/inventory-stock/InventoryStock.service";
import { GMBLocation } from "../../google-my-business/gmb-locations-list/GMBLocation";

export type GMCBatchResponse = { batchSize: number; serverResponse: { productsInsert: any; posInventory: any } };

type GMCProductCategory = {
  code: string;
  value: string;
};

export class GMC_CategoryAutocompleteTransformer implements AutocompleteTransformer {
  toAutocompleteItem(category: GMCProductCategory): SelectItem {
    return {
      label: category.value,
      value: category.code,
    };
  }

  fromAutocompleteItem(item: SelectItem): GMCProductCategory {
    return {
      code: item.value,
      value: item.label,
    };
  }
}

@Injectable({
  providedIn: "root",
})
export class GoogleShoppingProductsService extends InventoryService implements FormListModel {
  constructor(
    private alertMessagesService: AlertMessagesService,
    private messageService: MessageService,
    @Inject(LOCALE_ID) protected defaultLocale,
    currencyService: CurrencyFormatterService,
    protected http: HttpClient,
    googleContentAPIService: GoogleContentAPIService,
    protected inventoryService: InventoryService,
    protected authService: AuthService,
    appSettings: AppSettingsStorageService,
    zone: NgZone,
    public backendGMCService: BackendGMCService,
    barcodeService: InventoryBarcodeService,
    stockService: InventoryStockService,
    appSettingsStorageService: AppSettingsStorageService
  ) {
    super(
      currencyService,
      http,
      googleContentAPIService,
      defaultLocale,
      authService,
      appSettings,
      zone,
      backendGMCService,
      barcodeService,
      stockService,
      appSettingsStorageService
    );
  }
  static readonly MODEL_NAME = "inventory";
  _lookup_enableGMC = this.backendGMCService.enumSelectOptions(
    {
      Enabled: true,
      Disabled: false,
    },
    { useKeyAsLabel: true }
  );
  enum_gmcTitleTemplates: SelectItem[] = this.enumSelectOptions(GoogleShoppingTitleTemplate);
  categoryTransformer = new GMC_CategoryAutocompleteTransformer();
  _activeStoreId: number;

  afterFetch(inventory: Inventory, model) {
    if (!this._activeStoreId || model !== GoogleShoppingProductsService.MODEL_NAME) return;

    inventory.activeStoreId = this._activeStoreId;
  }

  extractProductIdsByStatus(
    gmcAccountId: number,
    destinationStatus: ContentAPI_ProductStatuses.DestinationStatus_Status,
    maxResults = 200
  ) {
    const productIds = [];

    const fnGetStatusList = (nextPageToken?: string): Observable<any> => {
      return this.backendGMCService.getProductStatusList(gmcAccountId, nextPageToken, maxResults).pipe(
        concatMap((response: any) => {
          const matchingProductIds = response.resources
            .filter((productStatus) => GMCHelpers.GetProductStatus(productStatus) === destinationStatus)
            .map((productStatus) => productStatus.productId);
          productIds.push(...matchingProductIds);

          if (response.nextPageToken) return fnGetStatusList(response.nextPageToken);
          else return of(null);
        })
      );
    };

    return fnGetStatusList().pipe(mapTo(productIds));
  }

  pushInBatches(
    gmcAccountId: number,
    gmbLocation: GMBLocation,
    inventoryList: Inventory[],
    batchSize: number,
    silent = true
  ): Observable<GMCBatchResponse>[] {
    const batchRequests: Observable<GMCBatchResponse>[] = [];
    // TODO: Test code for generating Body for Product.Insert call, remove it later
    // inventoryList.forEach(inventory => GMCHelpers.BuildProductsInsertBody(inventory, gmbLocation));
    const inventoryIds = inventoryList.map((inventory) => inventory.id);

    while (inventoryIds.length > 0) {
      const inventoryBatch = inventoryIds.splice(0, batchSize);
      // Divide the number of milliseconds in a minute between the number of batch requests that can be made in a minute
      const posInventoryQuotaDelayMs = Math.ceil(60000 / (BackendGMCService.POS_QUOTA_PER_MIN / inventoryBatch.length));

      const request = this.backendGMCService.productsInsertBatch(gmbLocation.id, inventoryBatch).pipe(
        mergeMap((productsInsertResp: any) => {
          if (productsInsertResp.kind && productsInsertResp.entries) {
            const entries: any[] = productsInsertResp.entries.filter((entry) => !entry.errors);
            const posInventoryData = [];
            entries.forEach((entry) => {
              // In the request, server-side is using the inventory id as the batchId for each batch item
              const inventory = inventoryList.find((_inventory) => _inventory.id === entry.batchId);
              if (inventory)
                posInventoryData.push(GMCHelpers.BuildPosInventoryBody(entry.product.offerId, inventory, gmbLocation));
            });
            // Do a POS Inventory in a single request for all the current inventory/products
            return this.backendGMCService.posInventoryBatch(gmcAccountId, posInventoryData).pipe(
              delay(posInventoryQuotaDelayMs),
              // Returns object contains responses from both API Calls, so we can process them later
              map((posInventoryResp) => {
                return { productsInsert: productsInsertResp, posInventory: posInventoryResp };
              })
            );
          } else {
            return of({ productsInsert: productsInsertResp, posInventory: null });
          }
        }),
        catchError((error) => {
          if (!silent) {
            let errorMessage: Message;
            if (error instanceof HttpErrorResponse) {
              errorMessage = this.alertMessagesService.getErrorMessage(
                error,
                `Error inserting list of inventory to Google`
              );
            } else if (error instanceof Error) {
              errorMessage = this.alertMessagesService.getErrorMessage(null, error.name, error.message);
            }

            if (errorMessage)
              this.messageService.add(
                Object.assign(errorMessage, {
                  life: 800,
                })
              );
          }
          return of(error);
        }),
        map((response: any) => ({ batchSize: inventoryBatch.length, serverResponse: response }))
      );

      batchRequests.push(request);
    }

    return batchRequests;
  }

  pushToGoogle(gmcAccountId, gmbLocation, inventory: Inventory, silent = true): Observable<any> {
    return this.backendGMCService.productsInsert(gmbLocation.id, inventory.id).pipe(
      mergeMap((googleResponse: any) => {
        if (googleResponse.offerId) {
          const posBody = GMCHelpers.BuildPosInventoryBody(googleResponse.offerId, inventory, gmbLocation);
          return this.backendGMCService.posInventory(gmcAccountId, posBody);
        } else {
          // TODO: Throw error message
          return throwError(new Error(`Could't insert inventory ${inventory.description1} into GMC\n`));
        }
      }),
      tap((googleResponse) => {
        if (!silent) {
          this.messageService.clear();
          this.messageService.add({
            summary: "Successful Push to GMC",
            detail: `Inventory ${inventory.description1} was successfully sent to Google Merchant Center`,
            severity: "success",
          });
        }
      }),
      catchError((error) => {
        if (!silent) {
          let errorMessage: Message;
          if (error instanceof HttpErrorResponse) {
            errorMessage = this.alertMessagesService.getErrorMessage(
              error,
              `Error pushing inventory '${inventory.description1}'. SKU: ${inventory.sku}`
            );
          } else if (error instanceof Error) {
            errorMessage = this.alertMessagesService.getErrorMessage(null, error.name, error.message);
          }

          if (errorMessage)
            this.messageService.add(
              Object.assign(errorMessage, {
                life: 800,
              })
            );
        }

        return of(error);
      })
    );
  }

  getValidationRules(): { [key: string]: {} | ValidatorFn[] } {
    return _.merge({}, super.getValidationRules(), {
      inventoryGoogleShopping: {
        productCategory: [Validators.required],
      },
    });
  }

  categoryOnAutocomplete(event, rowData: Inventory): Observable<GMBCategory[]> {
    return this.backendGMCService.gmcProductCategory_autocomplete(event.query);
  }

  getFormListColumns(extraData?: any): Col[] {
    const columns: Col[] = [];
    // Modify some of the default inventory columns
    const fnLookupIndexByField = function (columns: Col[], fieldPath) {
      return columns.findIndex((col) => col.field === fieldPath);
    };

    if (extraData && extraData.viewDetailsEvent)
      columns.push({
        field: "viewLink",
        header: "",
        visible: true,
        readonly: false,
        frozen: true,
        dataType: DataType.execute_event,
        dataOptions: [],
        dataTypeOptions: [
          new ExecuteEventDatatypeOptions({
            displayMode: ExecuteEvent_DisplayMode.LINK,
            label: "View",
            event: extraData.viewDetailsEvent,
          }),
        ],
        filterType: FilterType.none,
        colWidth: 100,
      });

    columns.push({
      field: "isGMCEnabled",
      header: "Integration Status",
      visible: true,
      readonly: false,
      frozen: true,
      dataType: DataType.enum,
      dataOptions: this._lookup_enableGMC,
      filterType: FilterType.enum,
      filterField: "inventoryStores.isGMCEnabled",
      colWidth: 220,
      attachTemplate: extraData.integrationStatusTemplateOptions,
    });
    // Add Google Status column
    if (extraData && extraData.googleStatusTemplateOptions)
      columns.push({
        field: "googleStatus",
        header: "Google Status",
        visible: true,
        readonly: true,
        frozen: true,
        dataType: DataType.template,
        dataOptions: [],
        dataTypeOptions: extraData.googleStatusTemplateOptions,
        filterType: FilterType.none,
        colWidth: 195,
      });

    // Push inherited inventory columns
    columns.push(...this.inventoryService.getFormListColumns());

    const parentInventoryId = columns.find((row) => row.filterField === "parentInventoryId");
    parentInventoryId.filterType = FilterType.none;
    parentInventoryId.colWidth = 60;

    // Don't show those fields by default
    ["categories"].forEach((fieldPath) => {
      const colIndex = fnLookupIndexByField(columns, fieldPath);
      if (colIndex >= 0) {
        columns[colIndex].visible = false;
        columns[colIndex].frozen = false;
      }
    });
    // Unfreeze these columns
    ["isEnabled"].forEach((fieldPath) => {
      const colIndex = fnLookupIndexByField(columns, fieldPath);
      if (colIndex >= 0) {
        columns[colIndex].frozen = false;
      }
    });

    // Add Google Shopping columns after OH Quantity
    if (extraData && extraData.googleErrorsSummaryTemplateOptions) {
      const qtyOHColIndex = fnLookupIndexByField(columns, "defaultInventoryStock.qtyOH");
      const gmcColumns = [
        {
          field: "googleErrorsSummary",
          header: "Errors from GMC",
          visible: true,
          readonly: true,
          frozen: false,
          dataType: DataType.template,
          dataOptions: [],
          dataTypeOptions: extraData.googleErrorsSummaryTemplateOptions,
          filterType: FilterType.none,
          colWidth: 340,
        },
        {
          field: "inventoryGoogleShopping.titleTemplate",
          header: "Google Template for Title",
          visible: true,
          readonly: false,
          frozen: false,
          dataType: DataType.enum,
          dataOptions: this.enum_gmcTitleTemplates,
          filterType: FilterType.enum,
          colWidth: 250,
        },
        {
          field: "inventoryGoogleShopping.productCategory",
          header: "Google Product Category",
          visible: true,
          readonly: false,
          frozen: true,
          dataType: DataType.autocomplete,
          dataOptions: [],
          filterType: FilterType.contains,
          dataTypeOptions: new AutocompleteDatatypeOptions({
            completeMethod: this.categoryOnAutocomplete.bind(this),
            transformer: this.categoryTransformer,
          }),
          colWidth: 200,
          showValidationErrors: false,
        },

        {
          field: "inventoryGoogleShopping.productLink",
          header: `Google Product Link`,
          visible: true,
          readonly: false,
          frozen: false,
          dataType: DataType.input,
          dataOptions: [],
          filterType: FilterType.contains,
        },
        {
          field: "inventoryGoogleShopping.imageLink",
          header: `Google Image Link`,
          visible: true,
          readonly: false,
          frozen: false,
          dataType: DataType.input,
          dataOptions: [],
          filterType: FilterType.contains,
        },
        {
          field: "inventoryGoogleShopping.brand",
          header: `Google Brand`,
          visible: true,
          readonly: false,
          frozen: false,
          dataType: DataType.input,
          dataOptions: [],
          filterType: FilterType.contains,
        },
        {
          field: "inventoryGoogleShopping.GTIN",
          header: `Google GTIN`,
          visible: true,
          readonly: false,
          frozen: false,
          dataType: DataType.input,
          dataOptions: [],
          filterType: FilterType.contains,
        },
        {
          field: "inventoryGoogleShopping.MPN",
          header: `Google MPN`,
          visible: true,
          readonly: false,
          frozen: false,
          dataType: DataType.input,
          dataOptions: [],
          filterType: FilterType.contains,
        },
        {
          field: "inventoryGoogleShopping.productType",
          header: `Google Product Type`,
          visible: true,
          readonly: false,
          frozen: false,
          dataType: DataType.input,
          dataOptions: [],
          filterType: FilterType.contains,
        },
        {
          field: "inventoryGoogleShopping.adult",
          header: `Google Is Adult`,
          visible: true,
          readonly: false,
          frozen: false,
          dataType: DataType.toggle,
          dataOptions: Col.boolean_filter_enum,
          filterOptions: Col.boolean_filter_enum,
          filterType: FilterType.enum,
          colWidth: 190,
        },
        {
          field: "inventoryGoogleShopping.ageGroup",
          header: `Google Age Group`,
          visible: true,
          readonly: false,
          frozen: false,
          dataType: DataType.enum,
          dataOptions: this.inventoryService.enum_googleshoppingAgeGroup,
          filterType: FilterType.enum,
        },
        {
          field: "inventoryGoogleShopping.gender",
          header: `Google Gender`,
          visible: true,
          readonly: false,
          frozen: false,
          dataType: DataType.enum,
          dataOptions: this.inventoryService.enum_googleshoppingGender,
          filterType: FilterType.enum,
        },
        {
          field: "inventoryGoogleShopping.isBuldle",
          header: `Google Is Bundle`,
          visible: true,
          readonly: false,
          frozen: false,
          dataType: DataType.toggle,
          dataOptions: Col.boolean_filter_enum,
          filterOptions: Col.boolean_filter_enum,
          filterType: FilterType.enum,
          colWidth: 195,
        },
        {
          field: "inventoryGoogleShopping.multiPack",
          header: `Google Multipack`,
          visible: true,
          readonly: false,
          frozen: false,
          dataType: DataType.input,
          dataOptions: [],
          filterType: FilterType.contains,
        },
        {
          field: "inventoryGoogleShopping.condition",
          header: `Google Condition`,
          visible: true,
          readonly: false,
          frozen: false,
          dataType: DataType.enum,
          dataOptions: this.inventoryService.enum_googleshoppigCondition,
          filterType: FilterType.enum,
        },
        {
          field: "inventoryGoogleShopping.pattern",
          header: `Google Pattern`,
          visible: true,
          readonly: false,
          frozen: false,
          dataType: DataType.input,
          dataOptions: [],
          filterType: FilterType.contains,
        },
        {
          field: "inventoryGoogleShopping.color",
          header: `Google Color`,
          visible: true,
          readonly: false,
          frozen: false,
          dataType: DataType.input,
          dataOptions: [],
          filterType: FilterType.contains,
        },
        {
          field: "inventoryGoogleShopping.material",
          header: `Google Material`,
          visible: true,
          readonly: false,
          frozen: false,
          dataType: DataType.input,
          dataOptions: [],
          filterType: FilterType.contains,
        },
        {
          field: "inventoryGoogleShopping.sizeSystem",
          header: `Google Size System`,
          visible: true,
          readonly: false,
          frozen: false,
          dataType: DataType.enum,
          dataOptions: this.inventoryService.enum_googleshoppingSizeSystem,
          filterType: FilterType.enum,
        },
        {
          field: "inventoryGoogleShopping.sizeType",
          header: `Google Size Type`,
          visible: true,
          readonly: false,
          frozen: false,
          dataType: DataType.enum,
          dataOptions: this.inventoryService.enum_googleshoppingSizeType,
          filterType: FilterType.enum,
        },
        {
          field: "inventoryGoogleShopping.sizes",
          header: `Google Size`,
          visible: true,
          readonly: true,
          frozen: false,
          dataType: DataType.chips,
          dataOptions: [],
          dataTypeOptions: new ChipsDatatypeOptions({ maxEntries: 1 }),
          filterType: FilterType.contains,
        },
      ];
      // Add Erros from GMC column
      if (qtyOHColIndex >= 0) columns.splice(qtyOHColIndex + 1, 0, ...gmcColumns);
      else columns.push(...gmcColumns);
    }
    // Put "frozen: true" values first
    columns.sort((a, b) => Number(b.frozen) - Number(a.frozen));

    return columns;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
