<form [formGroup]="_myForm">
  <div class="grid responsive-container">
    <div class="col-12 lg:col-5 xl:col-4 md:col-12 sm:col-12 p-0 flex signup-left-side">
      <div class="signup-container">
        <div class="taku-logo-container">
          <img src="assets/layout/images/logo.png" />
        </div>
        <div class="header-container">
          <h1 class="header">Create a TAKU ID</h1>
        </div>
        <div class="signup-form-field-container grid">
          <div class="col-12 email-field">
            <taku-input [formGroup]="_myForm" fieldName="email" fieldCaption="Email"></taku-input>
          </div>
          <div class="col-6 first-name-field">
            <taku-input [formGroup]="_myForm" fieldName="firstName" fieldCaption="First Name"></taku-input>
          </div>
          <div class="col-6 last-name-field">
            <taku-input [formGroup]="_myForm" fieldName="lastName" fieldCaption="Last Name"> </taku-input>
          </div>
          <div class="col-12 business-name-field">
            <taku-input [formGroup]="_myForm" fieldName="businessName" fieldCaption="Business Name"></taku-input>
          </div>
          <div class="col-12 field-address-selection">
            <div class="p-inputgroup login">
              <span class="p-inputgroup-addon">takuretail.app/</span>
              <taku-input
                [formGroup]="_myForm"
                fieldName="accountName"
                fieldCaption="Your TAKU account name"
              ></taku-input>
            </div>
          </div>
        </div>
        <div class="grid button-container mt-5">
          <button
            pButton
            (click)="onSignUpFormSubmitted()"
            [disabled]="!_myForm.valid"
            [loading]="loading"
            iconPos="right"
            class="taku-button-large mb-2"
            label="Sign Up"
          >
          </button>
          <span class="consent"
            >By clicking, you consent to be contacted by TAKU Canada Ltd. Don't worry, if you change your mind, you can
            request to stop communications at any time.</span
          >
        </div>
        <div class="new-try-for-free-container mt-5">
          <span>Already have a TAKU ID?</span>
          <a routerLink="/login" class="try-for-free-link">Log In</a>
        </div>
      </div>
      <div class="signup-page-footer">
        <div class="mb-3 help-privacy-terms-container">
          <a href="https://help.takulabs.io/" target="_blank">Help</a>&nbsp; | &nbsp;<a
            href="https://takulabs.io/privacy"
            target="_blank"
            >Privacy</a
          >&nbsp; | &nbsp;<a href="https://takulabs.io/terms-policies" target="_blank">Terms</a>
        </div>
        <div class="mb-3 copyright"> © {{ copyrightYear }} TakuLabs Ltd. All Rights Reserved </div>
      </div>
    </div>

    <div class="lg:col-7 xl:col-8 p-0 billboard-container">
      <div class="right-side-img-topWrapper">
        <div class="billboard-text">
          <h2>Keep your retail business in season</h2>
          <h3>TAKU's resources help you stay on top of seasonal trends</h3>
          <a href="https://www.takulabs.io/blog" target="_blank" class="p-button p-button-outlined mt-4"
            >Visit our blog</a
          >
        </div>
        <div class="billboard-image">
          <img src="https://www.takuretail.app/images/Storefront_Login_Page.png" />
        </div>
      </div>
    </div>
  </div>
</form>
