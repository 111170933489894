<div class="bottom-left" [formGroup]="saleForm">
  <div class="col-12 lg:col-6 footer-left card">
    <div class="grid">
      <div class="col-12 invoice-note" formGroupName="doc">
        <taku-input-textarea
          id="invoiceNote"
          #invoiceNote
          [formGroup]="saleForm.get('doc')"
          fieldName="comment"
          fieldCaption="Invoice Note"
          [rows]="3"
        ></taku-input-textarea>
      </div>

      <div class="col-6 p-fluid noReceiptWrapper">
        <button
          *ngIf="allowNoInvoiceReturns"
          pButton
          type="button"
          label="No Receipt"
          (click)="onNoReceiptPressed()"
          [disabled]="isTenderScreenOpened"
        ></button>
      </div>

      <div class="col-6 total-items-wrapper">
        <span
          >Total Items: <strong>({{ _countTotalItems }})</strong></span
        >
      </div>
    </div>
  </div>

  <div class="col-12 lg:col-6 footer-right">
    <div class="card">
      <div class="col-12 invoice-summary">
        <div class="line">
          <div class="col1">
            <b>Sub-Total</b>
          </div>
          <div class="col2">
            <b>{{
              -1 * invoiceSubTotal | currency : currencyIsoCode : "symbol-narrow" : "1.2" | formatNegativeNumber
            }}</b>
          </div>
        </div>
        <!-- <div class="line">
        <div class="col1">
          <ins>Lines Discount</ins>
        </div>
        <div class="col2">{{ linesSubtotalDiscount | currency:'CAD':'symbol-narrow':'1.2' }}</div>
      </div> -->
        <div class="line general-discount-line">
          <div class="col1">
            <ins>- Discount</ins
            ><span
              class="hidden"
              *ngIf="saleForm.get('discountType').value === 'Percentage' && saleForm.get('discountAmount').value"
            >
              ( {{ saleForm.get("discountAmount").value | number : _decimalFormatting }}% )</span
            >
          </div>
          <div class="col2"
            >{{
              -1 * invoiceGeneralDiscount | currency : currencyIsoCode : "symbol-narrow" : "1.2" | formatNegativeNumber
            }}
          </div>

          <div class="overlay-arrowPointer card" [hidden]="!_overlaysVisibility.discount"></div>
          <div class="overlay-container card" [hidden]="!_overlaysVisibility.discount">
            <p-button
              type="button"
              class="p-button-rounded p-button-icon-only p-button close-btn"
              icon="pi pi-times"
              (onClick)="closeOverlay('discount')"
            ></p-button>
            <div class="col-12">
              <h4>General Discount</h4>
            </div>
            <div class="p-fluid col-4 chg-discount-type" [style.marginTop]="'3px'">
              <p-selectButton [options]="enumDiscountType" formControlName="discountType"></p-selectButton>
            </div>
            <div class="col-8 chg-discount">
              <div class="p-inputgroup chg-amount">
                <span *ngIf="saleForm.get('discountType').value === 'Percentage'" class="p-inputgroup-addon">%</span>
                <span *ngIf="saleForm.get('discountType').value === 'Fixed'" class="p-inputgroup-addon">$</span>
                <taku-input
                  #generalDiscountAmount
                  (click)="onDiscAmountClicked()"
                  [formGroup]="saleForm"
                  fieldName="discountAmount"
                  (onBlur)="setZeroIfEmpty('discountAmount')"
                  [fieldCaption]="saleForm.get('discountAmount').errors ? '' : 'Amount'"
                ></taku-input>
              </div>
            </div>
          </div>
        </div>
        <div class="line general-taxes-line">
          <div class="col1" (click)="toggleOverlay('taxes', $event)">
            <ins>+ Taxes</ins>
          </div>
          <div class="col2">{{
            -1 * invoiceTotalTax | currency : currencyIsoCode : "symbol-narrow" : "1.2" | formatNegativeNumber
          }}</div>

          <div class="overlay-container card" [hidden]="!_overlaysVisibility.taxes">
            <p-button
              type="button"
              class="p-button-rounded p-button-icon-only p-button close-btn"
              icon="pi pi-times"
              (onClick)="closeOverlay('taxes')"
            ></p-button>
            <div class="col-12 salesTaxes-overlay">
              <table *ngIf="groupedTaxes.length">
                <ng-template ngFor [ngForOf]="groupedTaxes" let-tax>
                  <tr class="tax-info">
                    <td class="tax-desc">
                      <span>{{
                        tax?.saleTaxRule?.ruleName ? tax.saleTaxRule.ruleName : tax.saleTaxRule.tax.shortDesc
                      }}</span>
                    </td>
                    <td class="tax-amount">
                      <span>{{
                        tax?.total | currency : currencyIsoCode : "symbol-narrow" : "1.2" | formatNegativeNumber
                      }}</span>
                    </td>
                  </tr>

                  <tr class="separator">
                    <td colspan="2">
                      <hr />
                    </td>
                  </tr>
                </ng-template>
              </table>

              <div *ngIf="!groupedTaxes.length" class="no-taxes">There are no taxes for this sale yet.</div>
            </div>
          </div>
        </div>
        <div class="line shipping-summary-line">
          <div class="col1" (click)="onDeliveryLinkPressed()">
            <ins>+ Shipping</ins> <span *ngIf="_shipperName">( {{ _shipperName }} )</span>
          </div>

          <div class="col2"
            >{{
              -1 * invoiceTotalShipping | currency : currencyIsoCode : "symbol-narrow" : "1.2" | formatNegativeNumber
            }}
          </div>
        </div>
      </div>

      <div class="p-fluid invoice-total">
        <button
          id="return_button"
          pButton
          type="button"
          class="p-button-returns-custom"
          [ngClass]="{ 'tender-warning': showTenderWarningStyles }"
          (click)="onPayBtnPressed()"
          [disabled]="!isPaymentEnabled"
        >
          <div class="line">
            <div class="col1">
              Return
              <span *ngIf="showTenderWarningStyles" class="tender-icon">$</span>
              <span class="keyboard-shortcut">(F9)</span>:
            </div>
            <div class="col2" fittext [activateOnResize]="true" [maxFontSize]="45" fontUnit="pt">
              <span>{{
                -1 * invoiceTotal | currency : currencyIsoCode : "symbol-narrow" : "1.2" | formatNegativeNumber
              }}</span>
            </div>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>
