import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { InventorySupplierProductDetailService } from "./inventory-supplier-product-detail.service";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Col, RowSelectionType, ValidityStatusChange } from "src/app/form-list/form-list/form-list";
import { LocalDataService } from "src/app/shared/services/local-data.service";
import { FormListComponent } from "src/app/form-list/form-list/form-list.component";
import { InventorySupplierProductDetail, PackSize } from "./inventory-supplier-product-detail";
import _ from "lodash";
import { Observable } from "rxjs";

@Component({
  selector: "taku-inventory-supplier-product-detail",
  templateUrl: "./inventory-supplier-product-detail.component.html",
  styleUrls: ["./inventory-supplier-product-detail.component.scss"],
})
export class InventorySupplierProductDetailComponent implements OnInit {
  @Input() inventorySupplierFormGroup: UntypedFormGroup;
  @Input() defaultCurrencyIsoCode: string;
  @Input() $supplierChangedListener: Observable<void>;
  inventorySupplierProductDetailsFormArray: UntypedFormArray;
  _formListCols: Col[];
  _formListFilter: Record<string, unknown>;

  RowSelectionType = RowSelectionType;

  @ViewChild("formListInventorySupplierProductDetails") formListComponent: FormListComponent;

  constructor(
    public dbService: InventorySupplierProductDetailService,
    public fb: UntypedFormBuilder,
    public localDataService: LocalDataService
  ) {}

  ngOnInit(): void {
    this.inventorySupplierProductDetailsFormArray = this.inventorySupplierFormGroup.controls
      .inventorySupplierProductDetails as UntypedFormArray;
    this.refreshFormList(this.inventorySupplierProductDetailsFormArray.value);

    this._formListCols = this.dbService.getFormListColumns();

    this.$supplierChangedListener.subscribe(() => {
      const inventorySupplierProductDetailPackSizeSku = this.inventorySupplierProductDetailsFormArray.value.find(
        (inventorySupplierProductDetail) => inventorySupplierProductDetail.packSize === PackSize.SKU
      );

      const inventorySupplierProductDetailPackSizeInner = this.inventorySupplierProductDetailsFormArray.value.find(
        (inventorySupplierProductDetail) => inventorySupplierProductDetail.packSize === PackSize.INNER
      );

      const inventorySupplierProductDetailPackSizeOuter = this.inventorySupplierProductDetailsFormArray.value.find(
        (inventorySupplierProductDetail) => inventorySupplierProductDetail.packSize === PackSize.OUTER
      );

      const arrayItems = [
        new InventorySupplierProductDetail(
          inventorySupplierProductDetailPackSizeSku.id,
          inventorySupplierProductDetailPackSizeSku.inventorySupplierId,
          inventorySupplierProductDetailPackSizeSku.supplierCostCurrencyIsoCode,
          inventorySupplierProductDetailPackSizeSku.packSize
        ),
        new InventorySupplierProductDetail(
          inventorySupplierProductDetailPackSizeInner.id,
          inventorySupplierProductDetailPackSizeInner.inventorySupplierId,
          inventorySupplierProductDetailPackSizeInner.supplierCostCurrencyIsoCode,
          inventorySupplierProductDetailPackSizeInner.packSize
        ),
        new InventorySupplierProductDetail(
          inventorySupplierProductDetailPackSizeOuter.id,
          inventorySupplierProductDetailPackSizeOuter.inventorySupplierId,
          inventorySupplierProductDetailPackSizeOuter.supplierCostCurrencyIsoCode,
          inventorySupplierProductDetailPackSizeOuter.packSize
        ),
      ];

      this.refreshFormList(arrayItems);
    });
  }

  private refreshFormList(inventorySupplierProductDetails: InventorySupplierProductDetail[]) {
    this.localDataService.initialize(_.cloneDeep(inventorySupplierProductDetails), "inventorySupplierProductDetail");
    setTimeout(() => {
      this.formListComponent.reloadData();
      this.formListComponent.enableEditMode();
      this.formListComponent._orgObjects = this.formListComponent._orgObjects.filter((row) => row.id > 0);

      this.inventorySupplierProductDetailsFormArray.controls[0].setValue(inventorySupplierProductDetails[0], {
        emitEvent: false,
      });
      this.inventorySupplierProductDetailsFormArray.controls[1].setValue(inventorySupplierProductDetails[1], {
        emitEvent: false,
      });
      this.inventorySupplierProductDetailsFormArray.controls[2].setValue(inventorySupplierProductDetails[2], {
        emitEvent: false,
      });
    }, 0);
  }

  public formListItemChanged($event: ValidityStatusChange) {
    const inventorySupplierProductDetailRow = $event.object;
    const isFormListPristine = this.formListComponent.isPristine();
    const ispds = this.inventorySupplierProductDetailsFormArray.value;

    for (let x = 0; x < ispds.length; x++) {
      if (ispds[x].packSize === inventorySupplierProductDetailRow.packSize) {
        const inventorySupplierProductDetail = _.pick(
          inventorySupplierProductDetailRow,
          _.keys(this.inventorySupplierProductDetailsFormArray.controls[x].value)
        ) as InventorySupplierProductDetail;

        this.inventorySupplierProductDetailsFormArray.controls[x].setValue(inventorySupplierProductDetail);
        if (!isFormListPristine) {
          this.inventorySupplierFormGroup.markAsDirty();
        }
      }
    }
  }
}
