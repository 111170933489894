<taku-form-dialoge-header
  [pristine]="_myForm.pristine"
  [valid]="_myForm.valid"
  (backClicked)="goBack()"
  (saveClicked)="onSave()"
  (revertClicked)="onRevert()"
  (newClicked)="onNew()"
  (cancelClicked)="closeModal()"
  (deleteClicked)="onDelete()"
  (cloneClicked)="onClone()"
  toolbarTitle="Tax Inventory Category Details"
>
</taku-form-dialoge-header>

<form [formGroup]="_myForm" novalidate *ngIf="active">
  <div class="card card-w-title">
    <div class="grid form-group">
      <div class="col-12">
        <taku-checkbox [formGroup]="_myForm" [fieldName]="'isEnabled'" fieldCaption="Is Active?"> </taku-checkbox>
      </div>

      <div class="col-12">
        <taku-input [formGroup]="_myForm" [fieldName]="'category'" [fieldCaption]="'Tax Inventory Category'">
        </taku-input>
      </div>
    </div>
  </div>
</form>

<taku-form-footer></taku-form-footer>
