/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Input } from "@angular/core";
import { AbstractControl, UntypedFormArray, UntypedFormBuilder } from "@angular/forms";
import { NestedFormComponent } from "../../../forms/nested-form/nested-form.component";
import { InventoryStock } from "../inventory/inventory";
import { InventoryStockService } from "./InventoryStock.service";
import { ExtendedSelectItem } from "src/app/shared/services/db.service";
import { InventoryStockDetail, QtyDetailsType } from "../inventory-stock-details/inventory-stock-details.component";

@Component({
  selector: "taku-inventory-stock",
  templateUrl: "./inventory-stock.component.html",
  styleUrls: ["./inventory-stock.component.scss"],
})
export class InventoryStockComponent extends NestedFormComponent {
  @Input() disableForm = false;
  @Input() showMinStock = true;
  @Input() showMaxStock = true;
  @Input() set aisles_lookup(val: ExtendedSelectItem[]) {
    const stockId = this._myForm.controls.stockId?.value;
    this.aisles = val?.filter((f) => f.object?.stockId === stockId || f.label === "");
  }
  @Input() set shelves_lookup(val: ExtendedSelectItem[]) {
    const stockId = this._myForm.controls.stockId?.value;
    this.shelves = val?.filter((f) => f.object?.stockId === stockId || f.label === "");
  }

  aisles: ExtendedSelectItem[];
  shelves: ExtendedSelectItem[];
  inventoryStockDetails: InventoryStockDetail[];
  protected readonly qtyDetailsType: typeof QtyDetailsType = QtyDetailsType;

  constructor(public dbService: InventoryStockService, public fb: UntypedFormBuilder) {
    super(dbService, fb);
  }

  static setArray(fb: UntypedFormBuilder, inventoryStocks: InventoryStock[]): UntypedFormArray {
    const formArray = fb.array([]);
    inventoryStocks.map((inventoryStock) => {
      const temp = fb.group(inventoryStock);
      formArray.push(temp);
    });

    return formArray;
  }

  static initArray(fb: UntypedFormBuilder): UntypedFormArray {
    const formArray = fb.array([]);
    return formArray;
  }

  initValidation(): void {
    this._validation = this.dbService.getValidationRules();
  }

  get stockCtrl(): AbstractControl<any, any> {
    return this._myForm.get("stock");
  }

  get qtyOHCtrl(): AbstractControl<any, any> {
    return this._myForm.get("qtyOH");
  }

  get onOrderQtyCtrl(): AbstractControl<any, any> {
    return this._myForm.get("onOrderQty");
  }

  get committedQtyCtrl(): AbstractControl<any, any> {
    return this._myForm.get("committedQty");
  }

  get minStockCtrl(): AbstractControl<any, any> {
    return this._myForm.get("minStock");
  }

  get maxStockCtrl(): AbstractControl<any, any> {
    return this._myForm.get("maxStock");
  }

  get onOrderQtyDetails(): InventoryStockDetail[] {
    if (
      this._myForm.controls?.onOrderQtyDetails?.value &&
      this._myForm.controls?.onOrderQtyDetails?.value?.length > 0
    ) {
      return <InventoryStockDetail[]>this._myForm.controls?.onOrderQtyDetails?.value;
    } else {
      return this.inventoryStockDetails;
    }
  }

  get committedQtyDetails(): InventoryStockDetail[] {
    if (
      this._myForm.controls?.committedQtyDetails?.value &&
      this._myForm.controls?.committedQtyDetails?.value?.length > 0
    ) {
      return <InventoryStockDetail[]>this._myForm.controls?.committedQtyDetails?.value;
    } else {
      return this.inventoryStockDetails;
    }
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
