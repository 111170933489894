/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, OnDestroy } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import * as _ from "lodash";
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from "primeng/dynamicdialog";
import { MessageService } from "primeng/api";
import { of, Subscription } from "rxjs";
import { UnsavedChangesGuard } from "../../../../../app-routing/UnsavedChanges.guard";
import { AlertMessagesService } from "../../../../../shared/services/alert-messages.service";
import { LocationService } from "../../../../../shared/services/location.service";
import { ToggleCaptionPosition } from "../../../../../taku-ui/taku-toggle/taku-toggle.component";
import { AddressEmail, AddressEmailType } from "../../../../contact-accounts/address-email/address-email";
import { AddressPhone, AddressPhoneType } from "../../../../contact-accounts/address-phone/address-phone";
import { Address } from "../../../../contact-accounts/address/address";
import { AddressComponent } from "../../../../contact-accounts/address/address.component";
import { GMBGoogleAccount } from "../../google-my-business/gmb-accounts-list/GMBGoogleAccount";
import { BackendGMCService } from "../BackendGMCService";
import { GMCAccount } from "../gmc-accounts/GMCGoogleAccount";
import { BlockingUIService } from "../../../../../shared/services/ui/blocking-ui.service";
import { finalize } from "rxjs/operators";
import { ContentAPI_Account } from "../gmc-api-data-definitions/ContentAPI_Account";

@Component({
  selector: "taku-gmc-account-details",
  templateUrl: "./gmc-account-details.component.html",
  styleUrls: ["./gmc-account-details.component.scss"],
  providers: [DialogService],
})
export class GmcAccountDetailsComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];
  _formGroup: UntypedFormGroup;
  ToggleCaptionPosition = ToggleCaptionPosition;
  _contentAPIAccount: ContentAPI_Account.Account;
  _progressBarDialog: DynamicDialogRef;
  gmcAccountId: number;

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private dbService: BackendGMCService,
    private lService: LocationService,
    private unsavedChangesGuard: UnsavedChangesGuard,
    private messageService: MessageService,
    private alertMessagesService: AlertMessagesService,
    private blockingUIService: BlockingUIService,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    this.gmcAccountId = this.dialogConfig.data.gmcAccountId;
    const ownerEmail = (<GMBGoogleAccount>this.dialogConfig.data.gmcGoogleAccount).googleAccount.email;
    this._formGroup = this.fb.group({
      gmcName: this.fb.control(null, Validators.required), // only Merchant Center Name is required
      gmcWebsite: null,
      adult: false,
    });
    this._formGroup.setControl("address", AddressComponent.init(this.fb));
    this.pullContentAPIAccount(this.gmcAccountId, ownerEmail);
  }

  pullContentAPIAccount(gmcAccountId, ownerEmail: string) {
    const accountObservable = gmcAccountId
      ? this.dbService.getMerchantAccount(gmcAccountId)
      : of(new ContentAPI_Account.Account(ownerEmail));

    // this part needs to be refactored by new dialog in primeng
    // this._progressBarDialog = this.blockingUIService.showProgressBar(this.dialogService);
    this.subsList.push(
      accountObservable
        .pipe(
          finalize(() => {
            // this._progressBarDialog.close();
            // Because we are showing dialog inside dialog, we need to add again class for hiding scrollbars
            document.body.classList.add("p-overflow-hidden");
          })
        )
        .subscribe({
          next: (account) => {
            this._contentAPIAccount = account;
            this.fillForm(account);
          },
          error: (error) => {
            this.messageService.add(this.alertMessagesService.getErrorMessage(error));
            this.dialogRef.close(null);
          },
        })
    );
  }

  private fillForm(account) {
    const takuAddress = this.buildTakuAddress(account);
    this._formGroup.patchValue({
      gmcName: account.name,
      gmcWebsite: account.websiteUrl,
      adult: account.adultContent,
    });
    this._formGroup.removeControl("address");
    setTimeout(() => {
      this._formGroup.setControl("address", AddressComponent.set(this.fb, takuAddress));
      // Automatically change the phone country when updating main adress country
      this.subsList.push(
        this._formGroup.get("address.countryIsoCode").valueChanges.subscribe((newCountryCode) => {
          this._formGroup.get("address.addressPhone.countryIsoCode").setValue(newCountryCode, { emitEvent: false });
        })
      );

      this._formGroup.markAsPristine();
    }, 0);
  }

  private buildTakuAddress(account): Address {
    const gmcAddress: ContentAPI_Account.AccountAddress = account.businessInformation.address;
    let gmcCountry = this.dialogConfig.data.gmcCountry || gmcAddress.country;
    let regionItem = null;

    if (!gmcCountry && account.id) gmcCountry = "CA";
    if (gmcCountry) {
      const subDivisionLookup = gmcCountry ? this.lService.lookupSubdivisionOptions(gmcCountry, false) : null;
      regionItem = subDivisionLookup.find((item) => item.label == gmcAddress.region);
    }

    const businessInformation = account.businessInformation;
    const takuAddress = new Address();
    const addressLines = gmcAddress.streetAddress ? gmcAddress.streetAddress.split("\n") : [];

    takuAddress.postalCode = gmcAddress.postalCode;
    takuAddress.city = gmcAddress.locality;
    takuAddress.countryIsoCode = gmcCountry;
    takuAddress.subDivisionIsoCode = regionItem ? regionItem.value : null;
    takuAddress.line1 = addressLines[0] || null;
    takuAddress.line2 = addressLines[1] || null;

    const takuPhone = new AddressPhone();
    const phoneParts = businessInformation.phoneNumber
      ? businessInformation.phoneNumber.match(/(\d+)\s*(ext\.\s*(\d+))?/)
      : [];
    takuPhone.countryIsoCode = gmcCountry;
    takuPhone.tel = phoneParts[1] || phoneParts[0];
    takuPhone.ext = phoneParts[3] || null;
    takuPhone.phoneType = AddressPhoneType.work;
    takuAddress.addressPhone = takuPhone;

    const takuEmail = new AddressEmail();
    takuEmail.email = businessInformation.customerService ? businessInformation.customerService.email : null;
    takuEmail.emailType = AddressEmailType.work;
    takuAddress.addressEmail = takuEmail;

    return takuAddress;
  }

  private buildAccountBusinessInfo(takuAddress: Address): ContentAPI_Account.AccountBusinessInformation {
    let regionItem = null;
    if (takuAddress.countryIsoCode) {
      const subDivisionLookup = this.lService.lookupSubdivisionOptions(takuAddress.countryIsoCode, false);
      regionItem = subDivisionLookup.find((item) => item.value == takuAddress.subDivisionIsoCode);
    }

    const address = new ContentAPI_Account.AccountAddress();
    address.country = takuAddress.countryIsoCode;
    address.region = regionItem ? regionItem.label : null;
    address.locality = takuAddress.city;
    address.postalCode = takuAddress.postalCode;
    address.streetAddress = [takuAddress.line1, takuAddress.line2].join("\n");

    const customerService = new ContentAPI_Account.AccountCustomerService();
    customerService.email = takuAddress.addressEmail.email;
    // Don't include URL because is not assignable using address form
    delete customerService.url;
    delete customerService.phoneNumber;

    return {
      address,
      customerService,
      phoneNumber: takuAddress.addressPhone.fullPhoneNumber,
    };
  }

  private buildContentAccount(formValue: any): any {
    let url = formValue.gmcWebsite;
    if (url && !url.match(/^[a-zA-Z]+:\/\//)) {
      url = "http://" + url;
    }

    const takuAddress = _.merge(new Address(), formValue.address);
    const account = {
      adultContent: formValue.adult,
      name: formValue.gmcName,
      websiteUrl: url,
      businessInformation: this.buildAccountBusinessInfo(takuAddress),
    };

    return account;
  }

  onSave() {
    const gmcAccountId = this.dialogConfig.data.gmcAccountId;
    const gmcGoogleAccountId = this.dialogConfig.data.gmcGoogleAccount.id;

    const formValue = this._formGroup.value;
    const account = _.defaultsDeep(this.buildContentAccount(formValue), this._contentAPIAccount);

    this._progressBarDialog = this.blockingUIService.showProgressBar(this.dialogService);
    if (gmcAccountId) {
      this.subsList.push(
        this.dbService
          .updateMerchantAccount(gmcAccountId, account)
          .pipe(
            finalize(() => {
              this._progressBarDialog.close();
            })
          )
          .subscribe({
            next: (response) => {
              this.messageService.add(this.alertMessagesService.getMessage("save-success", "Google Merchant Account"));
              this.dialogRef.close(null);
            },
            error: (error) => {
              this.messageService.add(this.alertMessagesService.getErrorMessage(error));
            },
          })
      );
    } else {
      this.subsList.push(
        this.dbService
          .addMerchantAccount(gmcGoogleAccountId, account)
          .pipe(
            finalize(() => {
              this._progressBarDialog.close();
            })
          )
          .subscribe({
            next: (response: GMCAccount) => {
              this.messageService.add(this.alertMessagesService.getMessage("save-success", "Google Merchant Account"));

              this.dialogRef.close(response);
            },
            error: (error) => {
              this.messageService.add(this.alertMessagesService.getErrorMessage(error));
            },
          })
      );
    }
  }

  onRevert() {
    this.fillForm(this._contentAPIAccount);
  }

  closeDialog() {
    // if (this._formGroup.dirty){
    //   this.unsavedChangesGuard.checkForm(this._formGroup).subscribe(shouldClose => {
    //     if (shouldClose) this.dialogRef.close(null);
    //   })
    // } else {
    //   this.dialogRef.close(null);
    // }
    this.dialogRef.close(null);
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
