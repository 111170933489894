/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
  Type,
  OnDestroy,
  AfterViewInit,
  inject,
} from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ConfirmationService, MenuItem, MessageService } from "primeng/api";
import { Observable, of, Subscription } from "rxjs";
import { take, startWith, pairwise } from "rxjs/operators";
import { StepValidationMessages } from "src/app/forms/dialog-validation-messages/dialog-validation-messages.component";
import { GenericFormService } from "src/app/forms/generic-form/generic-form.component";
import { ValidationMessagesService } from "src/app/shared/services/validation-messages.service";
import { StepChangedEvent } from "src/app/taku-ui/taku-steps/taku-steps.component";
import { FormDataHelpers } from "src/app/utility/FormDataHelpers";
import { AppSettingsStorageService } from "../../../shared/app-settings-storage.service";
import { AlertMessagesService } from "../../../shared/services/alert-messages.service";
import { CurrencyFormatterService } from "../../../shared/services/currency-formatter.service";
import { PrintingFactoryService, ReceiptPrintingSize } from "../../../shared/services/printing-service.service";
import { MonetaryHelpers } from "../../../utility/MonetaryHelpers";
import { ClosingCashoutSlipFullSizeComponent } from "../../previews/slips/cashout-slip-full-size/cashout-slip-full-size.component";
import { ClosingCashoutSlipTapeSizeComponent } from "../../previews/slips/cashout-slip-tape-size/cashout-slip-tape-size.component";
import {
  CashCalculationType,
  PresetOpeningFloatOption,
  StoreSettingCashout,
  TillCashoutClosingType,
} from "../../settings/store-settings/cashout-settings/CashoutSettings";
import { CashoutOpeningFloat } from "../../settings/store-settings/settings-cashout-opening-float/cashout-opening-float";
import { Cashout, CashoutClosingBreakDown, CashoutClosingFloat, CashoutSaveMethod } from "../cashout/cashout";
import { CashoutPaymentBreakdown, DBCashoutService } from "../db-cashout.service";
import { ToggleCaptionPosition } from "src/app/taku-ui/taku-toggle/taku-toggle.component";
import { CashoutSlipBase } from "../../previews/slips/CashoutSlipBase.interface";
import * as _ from "lodash";
import { CashoutClosingService } from "../cashout-closing.service";
import { UnfinalizedSalesService } from "../../document/unfinalized-sales.service";
import { Router } from "@angular/router";
import { ClosingCashoutReportTapeSizeComponent } from "../../previews/slips/cashout-report-tape-size/cashout-report-tape-size.component";

export type CashoutDeposits = CashoutClosingBreakDown & CashoutPaymentBreakdown;
enum CloseCashoutSteps {
  CASH_COUNT = 0,
  PAYMENT_BREAKDOWN = 1,
}

@Component({
  selector: "taku-cashout-closetill",
  templateUrl: "./cashout-closetill.component.html",
  styleUrls: ["./cashout-closetill.component.scss"],
  providers: [CashoutClosingService],
})
export class CashoutClosetillComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  subsList: Subscription[] = [];
  @Input() cashoutFormGroup: UntypedFormGroup;
  @Input() genericFormService?: GenericFormService;
  @Output() onCashoutClosed: EventEmitter<any> = new EventEmitter();
  @ViewChild("printPreviewHost", { read: ViewContainerRef, static: true }) printPreviewHost: ViewContainerRef;

  cashoutSteps: MenuItem[] = [{ label: "Cash Count" }, { label: "Payment Breakdown" }];
  cashoutSettings: StoreSettingCashout;
  closeSettingsForm: UntypedFormGroup;
  cashoutHelperForm: UntypedFormGroup;
  activeStep = 0;
  _currencyISOCode: string;
  _minValidClosingTime: Date;
  CashoutSaveMethod = CashoutSaveMethod;
  ToggleCaptionPosition = ToggleCaptionPosition;
  CashCalculationType = CashCalculationType;
  systemTotalsByTenderAndCashoutId: CashoutPaymentBreakdown[];

  // Dialog vars
  showValidationDialog = false;
  dialogValidationMessages: StepValidationMessages[] = [];

  MonetaryHelpers: MonetaryHelpers = MonetaryHelpers;
  cashout: Cashout;
  cashoutDefaultFloats: CashoutOpeningFloat[];
  skipValidation = false;
  enum_printing_sizes = this.dbCashoutService.enumSelectOptions(ReceiptPrintingSize);

  private unfinalizedSalesService = inject(UnfinalizedSalesService);
  constructor(
    private fb: UntypedFormBuilder,
    private appSettings: AppSettingsStorageService,
    private dbCashoutService: DBCashoutService,
    private cashoutClosingService: CashoutClosingService,
    private validationMessagesService: ValidationMessagesService,
    private confirmationService: ConfirmationService,
    private printingService: PrintingFactoryService,
    protected messageService: MessageService,
    protected alertMessage: AlertMessagesService,
    private currencyFormatterService: CurrencyFormatterService,
    private router: Router
  ) {
    this._currencyISOCode = this.appSettings.getZone().defaultCurrencyIsoCode;

    this.closeSettingsForm = this.fb.group({
      printReport: false,
      printShift: false,
      print: true,
      extraCopy: false,
      printingSize: ReceiptPrintingSize.TAPE_SIZE_CASHOUT_REPORT,
    });

    this.cashoutHelperForm = this.fb.group({
      actualCash: "0.00",
      totalOpeningFloat: "0.00",
    });
  }

  ngOnInit(): void {
    this.subsList.push(this.unfinalizedSalesService.promptForCashingOutWithUnfinalizedSales().subscribe());
  }

  async ngAfterViewInit(): Promise<void> {
    await this._afterComponentInitCompleteAsync();
  }

  private async _afterComponentInitCompleteAsync() {
    if (!this.cashoutFormGroup.get("closeTotalNextFloat").value) {
      this.cashoutFormGroup.get("closeTotalNextFloat").setValue("0.00");
    }

    // Set minimum closing time to today's time
    this._minValidClosingTime = new Date();

    if (!this.cashoutFormGroup.get("closeSaveMethod").value) {
      this.cashoutFormGroup.get("closeSaveMethod").setValue(CashoutSaveMethod.TOTAL);
    }

    this.cashoutSettings = await this.appSettings.getStoreSettings("storeSettingCashout").toPromise();

    const cashoutDefaultFloats = await this.dbCashoutService
      .getCashoutDenominations(
        this.cashoutSettings.presetOpeningFloatOption === PresetOpeningFloatOption.default_values
      )
      .toPromise();

    if (this.closingFloatsArray.length === 0) {
      this.setNextFloats(cashoutDefaultFloats);
    }

    this.cashoutDefaultFloats = cashoutDefaultFloats;

    this.setUpTotalsFieldValidation();
    this.setUpTotalsFieldChangeListeners();

    this.cashoutFormGroup.get("closeTotalRemainingCash").markAsDirty();
    this.cashoutFormGroup.get("closeTotalNextFloat").markAsDirty();
    this.cashoutHelperForm.get("actualCash").markAsDirty();
  }

  ngOnChanges(changes: SimpleChanges) {
    //TODO: Probabably want to take another look at this.
    if (changes["genericFormService"])
      this.subsList.push(
        this.genericFormService.objectLoaded$.subscribe((newCashout: Cashout) => {
          if (!this.isClosedCtrl.value && !parseFloat(newCashout.closeTotalRemainingCash + "")) {
            // newCashout.closeTotalRemainingCash = null;
            // this.cashoutFormGroup.get("closeTotalRemainingCash").setValue(null);
          }
        })
      );
  }

  private setUpTotalsFieldValidation() {
    this.subsList.push(
      this.cashoutFormGroup
        .get("closeSaveMethod")
        .valueChanges.pipe(startWith(this.cashoutFormGroup.get("closeSaveMethod").value))
        .subscribe((saveMethod) => {
          if (saveMethod === CashoutSaveMethod.TOTAL) {
            this.cashoutFormGroup.get("closeTotalNextFloat").setValidators(Validators.required);
          } else {
            this.cashoutFormGroup.get("closeTotalNextFloat").clearValidators();
          }

          if (this.cashoutSettings.cashCalculationType === CashCalculationType.by_actual_cash) {
            if (saveMethod === CashoutSaveMethod.TOTAL) {
              this.cashoutHelperForm.get("actualCash").setValidators(Validators.required);
            } else {
              this.cashoutHelperForm.get("actualCash").clearValidators();
            }
          } else {
            if (saveMethod === CashoutSaveMethod.TOTAL) {
              this.cashoutFormGroup.get("closeTotalRemainingCash").setValidators(Validators.required);
            } else {
              this.cashoutFormGroup.get("closeTotalRemainingCash").clearValidators();
            }
          }
        })
    );
  }

  private async setUpTotalsFieldChangeListeners() {
    // set necessary field initial values
    const totalOpeningFloat = this.cashoutClosingService.getTotalOpeningFloat(this.cashoutFormGroup.value);
    this.cashoutHelperForm.get("totalOpeningFloat").setValue(totalOpeningFloat);

    const shouldPrePopulateActualCash = await this.cashoutClosingService.shouldPrePopulateActualCash();

    let actualCash = "0.00";

    if (shouldPrePopulateActualCash) {
      actualCash = await this.prepopulateActualCashField(totalOpeningFloat);
    } else {
      this.updateCloseActualCash(
        this.cashoutFormGroup.get("closeTotalRemainingCash").value,
        this.cashoutFormGroup.get("closeTotalNextFloat").value
      );
    }

    if (this.cashoutSettings.cashCalculationType === CashCalculationType.by_actual_cash) {
      this.updateCloseTotalRemainingCash(this.cashoutFormGroup.get("closeTotalNextFloat").value, actualCash);
    } else {
      this.cashoutFormGroup.get("closeTotalRemainingCash").setValue("0.00");
    }

    // set field change listeners
    this.cashoutFormGroup.get("closeTotalRemainingCash").valueChanges.subscribe((remainingCashVal) => {
      this.updateCloseActualCash(remainingCashVal, this.cashoutFormGroup.get("closeTotalNextFloat").value);
    });

    this.cashoutFormGroup
      .get("closeTotalNextFloat")
      .valueChanges.pipe(startWith(this.cashoutFormGroup.get("closeTotalNextFloat").value), pairwise())
      .subscribe(([prevNextFloatVal, nextFloatVal]) => {
        if (this.cashoutSettings.cashCalculationType === CashCalculationType.by_actual_cash) {
          this.updateCloseTotalRemainingCash(
            nextFloatVal,
            this.cashoutHelperForm.get("actualCash").value,
            prevNextFloatVal
          );
        } else {
          this.updateCloseActualCash(this.cashoutFormGroup.get("closeTotalRemainingCash").value, nextFloatVal);
        }
      });

    this.cashoutHelperForm
      .get("actualCash")
      .valueChanges.pipe(startWith(this.cashoutHelperForm.get("actualCash").value), pairwise())
      .subscribe(([prevActualCash, actualCash]) => {
        this.updateCloseTotalRemainingCash(
          this.cashoutFormGroup.get("closeTotalNextFloat").value,
          actualCash,
          null,
          prevActualCash
        );
      });
  }

  private updateCloseTotalRemainingCash(nextFloatAmt, actualCashAmt, prevNextFloatVal = null, prevActualCash = null) {
    nextFloatAmt = parseFloat((nextFloatAmt?.toString()?.replace(/,/g, "") || "0") + "");
    actualCashAmt = parseFloat((actualCashAmt?.toString()?.replace(/,/g, "") || "0") + "");

    const remainingCashAmt = actualCashAmt - nextFloatAmt;

    this.cashoutFormGroup.get("closeTotalRemainingCash").setValue(remainingCashAmt, { emitEvent: false });
  }

  private updateCloseActualCash(remainingAmt, nextFloatAmt) {
    remainingAmt = parseFloat((remainingAmt?.toString()?.replace(/,/g, "") || "0") + "");
    nextFloatAmt = parseFloat((nextFloatAmt?.toString()?.replace(/,/g, "") || "0") + "");

    const actualCash = remainingAmt + nextFloatAmt;

    // const totalClosingActualCash: number = this.cashoutClosingService.getTotalClosingActualCash(this.cashoutFormGroup.value);

    // Format total actual cash
    const totalActualCash = this.currencyFormatterService.formatMonetaryAmount({
      currencyIsoCode: this._currencyISOCode,
      amount: actualCash,
    });
    this.cashoutHelperForm.get("actualCash").setValue(totalActualCash, { emitEvent: false });
  }

  private async prepopulateActualCashField(totalOpeningFloat: number): Promise<string> {
    const cashoutPaymentBreakdowns = await this.cashoutClosingService.getSystemTotalsByTenderAndCashoutId(
      this.cashoutFormGroup.get("id").value
    );
    const cashTenderSystemTotal = await this.cashoutClosingService.getCashSystemTotalByTenderAndCashoutId(
      cashoutPaymentBreakdowns
    );

    const total = cashTenderSystemTotal + totalOpeningFloat;

    const actualCash = parseFloat((total?.toString()?.replace(/,/g, "") || "0") + "");
    const formattedCash = this.currencyFormatterService.formatMonetaryAmount({
      currencyIsoCode: this._currencyISOCode,
      amount: actualCash,
    });

    this.cashoutHelperForm.get("actualCash").setValue(formattedCash);

    return formattedCash;
  }

  get totalRemainingCashCtrl() {
    return this.cashoutFormGroup.get("closeTotalRemainingCash");
  }

  get closingFloatsArray() {
    return <UntypedFormArray>this.cashoutFormGroup.get("cashoutClosingFloats");
  }
  get paymentBreakDownArray() {
    return <UntypedFormArray>this.cashoutFormGroup.get("cashoutClosingBreakDowns");
  }
  get closeSaveMethodCtrl() {
    return this.cashoutFormGroup.get("closeSaveMethod");
  }
  get openingDateCtrl() {
    return this.cashoutFormGroup.get("openingDate");
  }

  get openingTimeCtrl() {
    return this.cashoutFormGroup.get("openingTime");
  }
  get closingDateCtrl() {
    return this.cashoutFormGroup.get("closingDate");
  }

  get closingTimeCtrl() {
    return this.cashoutFormGroup.get("closingTime");
  }

  get isClosedCtrl() {
    return this.cashoutFormGroup.get("isClosed");
  }

  onOpenCashDrawer($event) {}

  private showValidationErrorsDialog(errors: StepValidationMessages[]) {
    this.dialogValidationMessages = errors;
    this.showValidationDialog = true;
  }

  hideValidationErrorsDialog() {
    this.dialogValidationMessages = [];
  }

  private setNextFloats(cashoutFloats: CashoutOpeningFloat[]) {
    this.closingFloatsArray.clear();

    cashoutFloats.forEach((openingFloat) => {
      this.closingFloatsArray.push(
        this.fb.group(
          Object.assign(new CashoutClosingFloat(), { amount: openingFloat.amount, nextFloatCount: openingFloat.count })
        )
      );
    });

    const totalClosingFloatDetails = this.cashoutClosingService.getTotalClosingFloatDetails(
      this.cashoutFormGroup.value
    );

    if (!this.isClosedCtrl.value) {
      this.cashoutFormGroup.get("closeTotalNextFloat").setValue(totalClosingFloatDetails);
    }
  }

  get liveCashoutData(): Cashout {
    return _.assign(new Cashout(), this.cashoutFormGroup.value);
  }

  get closedByType() {
    if (!this.cashoutSettings) return "";

    switch (this.cashoutFormGroup.value.tillCashoutClosingType) {
      case TillCashoutClosingType.by_stations:
        return "Station";
      case TillCashoutClosingType.by_store:
        return "Store";
      // case TillCashoutClosingType.by_users:
      //   return 'User';
    }
  }

  get closedByName() {
    if (!this.cashoutSettings) return "";

    switch (this.cashoutFormGroup.value.tillCashoutClosingType) {
      case TillCashoutClosingType.by_stations:
        return this.appSettings.getStation().stationName;
      case TillCashoutClosingType.by_store:
        return this.appSettings.getStore().storeName;
      // case TillCashoutClosingType.by_users:
      //   return this.appSettings.getUser().userName;
    }
  }

  onSecondStep(e) {
    this.activeStep = 1;
  }

  applyFloatDefaults(e) {
    this.confirmationService.confirm({
      message:
        "By <em>Applying Defaults</em> to Next Floats you will lose any changes made on this page.<br><br>Are you sure you would like to proceed?",
      rejectButtonStyleClass: "p-button-link",
      accept: () => {
        if (this.cashoutSettings.presetOpeningFloatOption === PresetOpeningFloatOption.default_values) {
          this.setNextFloats(this.cashoutSettings.storeSettingCashoutOpeningFloats);
        } else {
          this.closingFloatsArray.controls.forEach((c) => c.get("nextFloatCount").setValue(0));
        }
        this.messageService.add(this.alertMessage.getMessage("reset-defaults-success"));
      },
      reject: () => {},
    });
  }

  balanceInquiryPressed() {}

  settleCreditPressed() {}

  onCloseDay() {
    this.subsList.push(
      this.dbCashoutService
        .searchOpenCashout$(this.appSettings.getStoreId(), this.appSettings.getStationId())
        .subscribe({
          next: (cashout) => {
            if (cashout) {
              if (cashout.id === this.appSettings.getCashoutId()) {
                this.dbCashoutService.getPaymentBreakdown(cashout.id).subscribe((cashoutPaymentBreakdowns) => {
                  const cashoutClosingBreakDowns = this.cashoutFormGroup.get("cashoutClosingBreakDowns").value;
                  let discrepancyFound = false;

                  for (const closingBreakdown of cashoutClosingBreakDowns) {
                    const paymentBreakdownSystemTotal = cashoutPaymentBreakdowns
                      .filter((p) => p.tenderTypeId === closingBreakdown.tenderTypeId)
                      .reduce((row, current) => row + parseFloat(current.systemTotal), 0);

                    const hasDiscrepancy =
                      Math.abs(parseFloat(closingBreakdown.systemTotal) - paymentBreakdownSystemTotal) > 0.0001;

                    if (hasDiscrepancy) {
                      discrepancyFound = true;
                      // Abstracted the discrepancy handling into a separate method for better readability
                      this.confirmationService.confirm({
                        header: "Notice",
                        message:
                          "To close this cashout, the payment breakdown table must be updated as there are new transactions or changes since you started the closing. Please click OK to start again.",
                        acceptLabel: "OK",
                        rejectVisible: false,
                        accept: () => {
                          this.router.navigate(["/dashboard"]); // Go to dashboard
                        },
                      });
                      break;
                    }
                  }
                  if (!discrepancyFound) {
                    if (this.cashoutFormGroup.get("closeSaveMethod").value === CashoutSaveMethod.DETAIL) {
                      this.cashoutFormGroup.patchValue({
                        closeTotalNextFloat: this.liveCashoutData.totalClosingFloat,
                        closeTotalRemainingCash: this.cashoutClosingService.getTotalClosingRemainingCash(
                          this.cashoutFormGroup.value
                        ),
                      });
                    }
                    this.cashoutFormGroup.patchValue({
                      isClosed: true,
                      closingCashFloatUserId: this.appSettings.getUserId(),
                    });
                    this.onCashoutClosed.emit();

                    const deposits = <CashoutDeposits[]>this.cashoutFormGroup.get("cashoutClosingBreakDowns").value;
                    this.subsList.push(
                      this.genericFormService.formChanged$.pipe(take(1)).subscribe((cashout) => {
                        // console.log(deposits);
                        this.printCashoutSlip(cashout, deposits);
                        this.appSettings.setCashout(null);
                      })
                    );
                  }
                });
              } else {
                this.appSettings.setCashout(cashout);
                this.confirmationService.confirm({
                  header: "Notice",
                  message:
                    "The cashout closing you're making has already been closed. There's no further action required on your part.",
                  acceptLabel: "OK",
                  rejectVisible: false,
                  accept: () => {
                    this.router.navigate(["/dashboard"]); // Go to dashboard
                  },
                });
              }
            } else {
              this.confirmationService.confirm({
                header: "Notice",
                message: "This cashout has already been closed. There's no further action required on your part.",
                acceptLabel: "OK",
                rejectVisible: false,
                accept: () => {
                  this.router.navigate(["/dashboard"]); // Go to dashboard
                },
              });
            }
          },
        })
    );
  }

  printCashoutSlip(actualCashout, deposits: CashoutDeposits[]) {
    if (!this.closeSettingsForm.get("print").value)
      // if printing has NOT been enabled, do nothing
      return;

    let printComponent: Type<CashoutSlipBase>;
    switch (this.printingSizeCtrl.value) {
      case ReceiptPrintingSize.TAPE_SIZE:
        printComponent = ClosingCashoutSlipTapeSizeComponent;
        break;

      case ReceiptPrintingSize.LETTER_SIZE:
        printComponent = ClosingCashoutSlipFullSizeComponent;
        break;

      case ReceiptPrintingSize.TAPE_SIZE_CASHOUT_REPORT:
        printComponent = ClosingCashoutReportTapeSizeComponent;
        break;
    }

    if (!printComponent) {
      console.warn("Cashout slip printing size NOT recognized", this.printingSizeCtrl.value);
      return;
    }
    const cashouts: Cashout[] = [actualCashout];
    // Add additional cashout copy to array when respective checkbox is enabled
    if (this.extraCopyCtrl.value) cashouts.push(actualCashout);

    this.printingService
      .build(null)
      .printCashoutSlip(
        cashouts,
        deposits,
        this._currencyISOCode,
        this.cashoutSettings,
        this.printingSizeCtrl.value,
        this.printPreviewHost
      );
  }

  get extraCopyCtrl() {
    return this.closeSettingsForm.get("extraCopy");
  }

  get printingSizeCtrl() {
    return this.closeSettingsForm.get("printingSize");
  }

  onStepChanged(stepsInfo: StepChangedEvent) {
    // if (this.skipValidation) {
    //   this.skipValidation = false;
    //   return;
    // }
    // const errors = this.validateStep(stepsInfo.oldIndex);
    // if (errors) {
    //   this.showValidationErrorsDialog([errors]);
    //   this.skipValidation = true;
    //   this.activeStep = stepsInfo.oldIndex;
    // }
  }

  validateStep(currIndex: number, newIndex: number): Observable<boolean> {
    // if we are going backwards skip validation
    if (newIndex < currIndex) return of(true);

    const errors = this.getStepValidationMessages(currIndex);
    if (errors) {
      this.showValidationErrorsDialog([errors]);
      return of(false); // unsuccessful validation
    } else {
      return of(true); // Step is OK, so we can change current step
    }
  }

  private getStepValidationMessages(step: number): StepValidationMessages {
    const fieldsStepErrors = [];
    switch (step) {
      case CloseCashoutSteps.CASH_COUNT:
        if (this.cashoutFormGroup.get("closeSaveMethod").value == CashoutSaveMethod.TOTAL) {
          fieldsStepErrors.push(
            ...FormDataHelpers.getControlValidationErrors(
              "Total Next Float",
              this.cashoutFormGroup.get("closeTotalNextFloat")
            )
          );

          switch (this.cashoutSettings.cashCalculationType) {
            case CashCalculationType.by_actual_cash:
              fieldsStepErrors.push(
                ...FormDataHelpers.getControlValidationErrors("Actual Cash", this.cashoutHelperForm.get("actualCash"))
              );
              break;
            default:
              fieldsStepErrors.push(
                ...FormDataHelpers.getControlValidationErrors(
                  "Total Cash for Deposit",
                  this.cashoutFormGroup.get("closeTotalRemainingCash")
                )
              );
              break;
          }
        } else {
          for (let i = 0; i < this.closingFloatsArray.length; i++) {
            const floatForm = <UntypedFormGroup>this.closingFloatsArray.at(i);
            const formErrors = FormDataHelpers.getFormValidationErrors(floatForm);
            if (formErrors.length) {
              fieldsStepErrors.push(...formErrors);
              break;
            }
          }
        }
        break;

      case CloseCashoutSteps.PAYMENT_BREAKDOWN:
        for (let i = 0; i < this.paymentBreakDownArray.length; i++) {
          const formErrors = FormDataHelpers.getFormValidationErrors(
            <UntypedFormGroup>this.paymentBreakDownArray.at(i)
          );
          if (formErrors.length) {
            fieldsStepErrors.push(...formErrors);
            break;
          }
        }
        break;
    }

    if (!fieldsStepErrors.length) return null;

    return {
      stepCaption: this.cashoutSteps[step].label,
      messages: fieldsStepErrors.map((error) => {
        const validationError = this.validationMessagesService.getErrorMessage(error.error, error.value, error.control);
        return { fieldName: error.control, validationError };
      }),
    };
  }

  cashAmountChanged() {}

  // private totalActualCash(): number {
  //   switch (this.cashoutFormGroup.get("closeSaveMethod").value) {
  //     case CashoutSaveMethod.TOTAL:
  //       return parseFloat((this.closeSettingsForm.get('actualCash').value.toString()?.replace(/,/g, "") || "0") + "");

  //     // TODO: this
  //     // case CashoutSaveMethod.DETAIL:
  //     //   return this.totalClosingFloatDetails;
  //   }
  // }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
