/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Input } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { GenericPhoneComponent } from "../phone-shared/phone.component.shared";
import { CommercialAccountPhone, CommercialPhoneType } from "./commercial-account-phone";

@Component({
  selector: "taku-commercial-account-phone",
  templateUrl: "../phone-shared/phone.component.shared.html",
  styleUrls: ["../phone-shared/phone.component.shared.scss"],
})
export class CommercialAccountPhoneComponent extends GenericPhoneComponent {
  @Input() disableForm?: boolean = false;

  enum_phone_type = this.dbService.enumSelectOptions(CommercialPhoneType);

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    const commercialAccountPhone_Group = fb.group(new CommercialAccountPhone());
    return commercialAccountPhone_Group;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
