/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit } from "@angular/core";

@Component({
  selector: "taku-store-shipper",
  templateUrl: "./store-shipper.component.html",
  styleUrls: ["./store-shipper.component.scss"],
})
export class StoreShipperComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
