/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { AfterViewInit, Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { ConfirmationService } from "primeng/api";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { Col } from "../../../form-list/form-list/form-list";
import { FormListComponent, RowsSavedEvent } from "../../../form-list/form-list/form-list.component";
import { AppSettingsStorageService } from "../../../shared/app-settings-storage.service";
import { SaleChannelService } from "src/app/core/sale-channel/sale-channel.service";
import { UntypedFormGroup } from "@angular/forms";
import { ChannelType } from "../sale-channel";
import { WebHelpers } from "src/app/utility/WebHelpers";

@Component({
  selector: "taku-sale-channel-settings",
  templateUrl: "./sale-channel-settings.component.html",
  styleUrls: ["./sale-channel-settings.component.scss"],
  providers: [DialogService, SaleChannelService],
})
export class SaleChannelSettingsComponent implements OnInit, AfterViewInit, OnDestroy {
  private formListFormRows: UntypedFormGroup[];
  subsList: Subscription[] = [];
  @ViewChild(FormListComponent) _formListComponent: FormListComponent;

  Filter_StatusAll = "all";
  _title = "Setup All Sales Channels";
  _channelTitle: string = null;
  _model = "saleChannel";
  _defaultRowValues = {};
  _formListCols: Col[];
  _formListRules: {};
  _formListQueryParams = {};
  _formListFilter: any = {};
  _isDataReady: boolean;
  isAdmin: boolean;
  activeZoneId: number;
  activeStoreId;
  previousStoreId: number;
  currentStoreId: number;
  progressDialog: DynamicDialogRef;
  TAKUeCommerce = ChannelType.takuecommerce;
  saleChannelDefaultRow = {};
  isSuperAdmin = false;
  //_viewDetailsEvent: EventEmitter<Inventory> = new EventEmitter;

  constructor(
    public saleChannelService: SaleChannelService,
    private appSettings: AppSettingsStorageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  get _isMobile() {
    return WebHelpers.isMobileScreen();
  }

  ngOnInit() {
    this.isSuperAdmin = this.appSettings.getUser().isSuperAdmin;
    this.activatedRoute.queryParams.subscribe((params) => {
      this._channelTitle = params["title"];

      this.subsList.push(
        this.activatedRoute.params.pipe(map((params: Params) => +params["storeId"])).subscribe((storeId) => {
          if (!storeId && !isNaN(storeId)) {
            this.router.navigate([this.appSettings.getStoreId()], { relativeTo: this.activatedRoute });
            return;
          } else {
            this.activeStoreId = storeId;
            this.isAdmin = !this.activeStoreId;
            this._channelTitle = this.isAdmin ? "a Sale Channel" : this._channelTitle;
          }
          this._isDataReady = false;

          if (!this.isAdmin) {
            this._formListFilter["channelType"] = { matchMode: "equals", value: this._channelTitle };
            this._formListFilter["storeId"] = { matchMode: "equals", value: this.activeStoreId };
          } else {
            this._formListFilter = {};
          }
          this._formListCols = this.saleChannelService.getFormListColumns();
          this._isDataReady = true;

          if (!this.isAdmin) {
            this.saleChannelDefaultRow = {
              storeId: this.activeStoreId,
              channelType: this._channelTitle,
            };
          }
        })
      );
    });
  }

  saleChannelClass() {
    switch (this._channelTitle) {
      case ChannelType.takuecommerce:
        return "takuicon-shopping-bag-solid";
      case ChannelType.woocommerce:
        return "takuicon-wordpress-brands";
      case ChannelType.facebook:
        return "takuicon-facebook-brands";
      case ChannelType.instagram:
        return "takuicon-instagram-square-brands";
      case ChannelType.amazon:
        return "takuicon-amazon-brands";

      default:
        return "";
    }
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this._formListCols = this.saleChannelService.getFormListColumns();
      this._formListRules = this.saleChannelService.getValidationRules();
    }, 0);
  }

  // onSaveSaleChannel(event: RowsSavedEvent) {
  //   const changedSaleChannel = [];
  //   event.newValues.forEach(newValue => {
  //     const prevValue = event.oldValues.find((value) => { return value.id === newValue.id });
  //     // we have changed Payment Gateway from active to inactive
  //     if (prevValue && prevValue.isActive && !newValue.isActive)
  //       changedSaleChannel.push(newValue);
  //   });
  // }

  private showInactiveSaleChanneDialog(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      // If we are disabling the current payment gateway, disable all the terminals as well
      this.confirmationService.confirm({
        header: "Confirmation",
        message: "Would you like to inactive sales channels?",
        acceptLabel: "Yes",
        rejectLabel: "No",
        rejectButtonStyleClass: "p-button-link",
        accept: () => {
          observer.next(true);
          observer.complete();
        },
        reject: () => {
          observer.next(false);
          observer.complete();
        },
      });
    });
  }

  private showDisconnectSaleChannelDialog(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      // If we are disabling the current payment gateway, disable all the terminals as well
      this.confirmationService.confirm({
        header: "Confirmation",
        message: "Would you like to disconnect sales channels?",
        acceptLabel: "Yes",
        rejectLabel: "No",
        rejectButtonStyleClass: "p-button-link",
        accept: () => {
          observer.next(true);
          observer.complete();
        },
        reject: () => {
          observer.next(false);
          observer.complete();
        },
      });
    });
  }

  // registerRowFormsChanges() {
  //   this.formListFormRows = this._formListComponent.getAllFormGroups();
  //   this.formListFormRows.forEach(formGroup => {
  //     const isActiveCtrl = formGroup.get('isActive');
  //     this.subsList.push(isActiveCtrl.valueChanges.pipe(
  //       // skip(1),
  //     ).subscribe(isActive => {
  //       if (!this._formListComponent._isFormInEditMode || isActive) return;

  //       this.subsList.push(this.showInactiveSaleChanneDialog().subscribe(confirmation => {
  //         // Revert value of isActive checkbox if we rejected the confirmation dialog
  //         if (!confirmation)
  //           isActiveCtrl.setValue(!isActive, { emitEvent: false });
  //       }));
  //     }));
  //   })
  // }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
