<taku-form-header
  [formGroup]="_myForm"
  (backClicked)="goBack()"
  (saveClicked)="onSave()"
  (revertClicked)="onRevert()"
  (newClicked)="onNew()"
  (deleteClicked)="onDelete()"
  (cloneClicked)="onClone()"
  toolbarTitle="System User"
  [isSaving]="_isSaving"
>
  <button
    pButton
    label="Email Password Update"
    [disabled]="_object.id === 0 || _myForm.dirty"
    (click)="sendPasswordReset()"
    [loading]="_sendingEmail"
  ></button>
</taku-form-header>

<div [formGroup]="_myForm" class="grid pt-2">
  <div class="col-12 lg:col-3 md:col-5 user-details-container">
    <p-panel>
      <ng-template pTemplate="header">
        <h2>User Account Details</h2>
        <!-- <taku-toggle [formGroup]="_myForm" fieldName="isActive"></taku-toggle> -->
        <taku-checkbox
          [disabled]="isOwnerCtrl.value"
          fieldCaption="ACTIVE"
          [formGroup]="_myForm"
          fieldName="isActive"
        ></taku-checkbox>
      </ng-template>
      <div class="col-12 md:col-12">
        <taku-input [formGroup]="_myForm" fieldName="userName" fieldCaption="Username"></taku-input>
      </div>
      <div class="col-12 md:col-12">
        <taku-input [formGroup]="_myForm" fieldName="email" fieldCaption="Email"></taku-input>
      </div>

      <div class="col-12 btnRoles-wrapper">
        <button
          pButton
          label="Assigned Roles"
          class="p-button-secondary"
          (click)="assignedRoles()"
          [disabled]="_object.id === 0 || _myForm.dirty"
        ></button>
      </div>

      <div class="col-12 btnOwnership-wrapper">
        <taku-dropdown
          *ngIf="reAssignOwnershipStep === 1"
          [formGroup]="ownershipForm"
          [options]="lookup_users$ | async"
          fieldName="ownerUserId"
          fieldCaption="Re-Assign Ownership"
        ></taku-dropdown>
        <button
          *ngIf="reAssignOwnershipStep === 0"
          pButton
          label="Re-Assign Ownership"
          class="p-button-secondary"
          (click)="changeReAssignOwnerShipStep()"
          [disabled]="(!loggedInUser.isOwner || !_object.isOwner) && !loggedInUser.isSuperAdmin"
        ></button>
      </div>

      <div *ngIf="loggedInUser" class="isAdminField-wrapper">
        <taku-checkbox
          [disabled]="!loggedInUser.isOwner && !loggedInUser.isSuperAdmin"
          [formGroup]="_myForm"
          fieldName="isAdmin"
          fieldCaption="Is this user an Administrator?"
        ></taku-checkbox>
      </div>
      <!--
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i class="material-icons">enhanced_encryption</i>
          </span>
          <p-inplace closable="false">
            <span pInplaceDisplay>
              Edit Password
              <button pButton type="button" icon="ui-icon-mode-edit" class="p-button-info"></button>
            </span>
            <span pInplaceContent>
              <taku-input type="password" [formGroup]="_myForm" fieldName="password" fieldCaption="Password"></taku-input>
            </span>
          </p-inplace> -->
      <!-- <div class="col-12 md:col-12">
        <taku-input type="password" [formGroup]="_myForm" fieldName="password" fieldCaption="Password"></taku-input>
      </div> -->
      <!-- <div class="col-12 md:col-12">
          <taku-input type="password" [formGroup]="_myForm" [fieldName]="'confirmPassword'" [fieldCaption]="'Confirm Password'"> </taku-input>
      </div> -->
    </p-panel>
  </div>

  <div class="col-12 lg:col-9 md:col-7 person-details-container">
    <taku-person [formGroup]="_myForm.controls.person"></taku-person>
  </div>
</div>
<!-- <taku-form-footer></taku-form-footer> -->
