<div [formGroup]="_myForm">
  <div class="grid">
    <div class="grid col">
      <div class="col-12 status-container">
        <taku-dropdown
          [formGroup]="_myForm"
          [options]="enum_commecrial_social_profile_type"
          [fieldName]="'socialProfileType'"
          [fieldCaption]="'Social Profile Type'"
        >
        </taku-dropdown>
      </div>
      <div class="col-12">
        <taku-input [formGroup]="_myForm" [fieldName]="'socialProfile'" [fieldCaption]="'Social Profile'"> </taku-input>
      </div>
    </div>
    <div class="col-fixed copy-icon-container">
      <taku-action-button
        [action]="ACTION_TYPE.COPY"
        (clickAction)="copySocialProfileToClipboard()"
      ></taku-action-button>
    </div>
  </div>
</div>
