/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Address, AddressType } from "../address/address";
import { AddressPhoneType } from "../address-phone/address-phone";
import { AddressEmailType } from "../address-email/address-email";

export class PersonAddress {
  id: number;
  address: Address;

  constructor(addressType?: AddressType) {
    this.id = 0;
    this.address = new Address(null, addressType);
    this.address.addressPhone.phoneType = AddressPhoneType.home;
    this.address.addressEmail.emailType = AddressEmailType.personal;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
