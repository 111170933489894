<div [formGroup]="formGroup" class="p-float-label">
  <p-chips [formControlName]="fieldName" [max]="maxEntries" [placeholder]="fieldPlaceholder"></p-chips>
  <label *ngIf="fieldCaption" for="{{ fieldName }}" [innerHTML]="fullFieldCaption"></label>

  <form-validation-messages
    [hidden]="ctrlField.valid || !ctrlField.dirty"
    [errors]="ctrlField.errors"
    [fieldName]="fieldName"
  ></form-validation-messages>
</div>
