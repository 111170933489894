/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { AppSettingsStorageService } from "../shared/app-settings-storage.service";
import { DBCashoutService } from "../core/cashout/db-cashout.service";
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ReturnScreenGuard implements CanActivate {
  constructor(
    private appSettings: AppSettingsStorageService,
    private _router: Router,
    private dbCashoutService: DBCashoutService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.dbCashoutService
      .searchOpenCashout$(this.appSettings.getStoreId(), this.appSettings.getStationId())
      .pipe(
        map((cashout) => {
          if (!cashout || cashout.isClosed) {
            this._router.navigate(["/sell/cashout", 0, "saleDocument"]);
            return false;
          }
          if (cashout.id !== this.appSettings.getCashoutId()) {
            this.appSettings.setCashout(cashout);
          }
          return true;
        }),
        catchError((err) => {
          // Handle any errors here (e.g., redirect to an error page)
          console.error(err);
          this._router.navigate(["/error"]);
          return of(false); // Return an Observable that emits false
        })
      );
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
