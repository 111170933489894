/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, ElementRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { DBService } from "src/app/shared/services/db.service";
import { WebHelpers } from "src/app/utility/WebHelpers";
import { environment } from "src/environments/environment";
import { SaleDoc } from "../../document/sale-document/sale-doc/sale-doc";
import {
  SaleChannelFulfillmentStatus,
  OrderStatus,
  SaleChannelOrder,
  SaleChannelPaymentStatus,
  SaleChannelDeliveryStatus,
} from "../sale-channel-orders/sale-channel-order";
import { SaleChannelOrdersService } from "../sale-channel-orders/sale-channel-orders.service";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Account, AccountRelationship, AccountType } from "../../contact-accounts/account/account";
import { Person } from "../../contact-accounts/person/person";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";
import { TakuSearchAccountsComponent } from "src/app/taku-ui/taku-search-accounts/taku-search-accounts.component";
import { CommercialAccount } from "../../contact-accounts/commercial-account/commercial-account";
import { PersonalAccount } from "../../contact-accounts/personal-account/personal-account";
import { SearchResultItem } from "src/app/taku-ui/taku-search-accounts/SearchResultItem";
import { PersonEmail } from "../../contact-accounts/person-email/person-email";
import { CommercialAccountEmail } from "../../contact-accounts/commercial-account-email/commercial-account-email";
import { throwError } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";
import { BusinessDetail } from "../../settings/business-settings/business-detail/business-detail";

enum FullSizeDialogName {
  COMMERCIAL_ACCOUNT = "commercialAccount",
  PERSONAL_ACCOUNT = "personalAccount",
}
@Component({
  selector: "taku-sale-channel-order-preview",
  templateUrl: "./sale-channel-order-preview.component.html",
  styleUrls: ["./sale-channel-order-preview.component.scss"],
  providers: [DialogService],
})
export class SaleChannelOrderPreviewComponent {
  _saleChannelOrder: SaleChannelOrder;
  _headerTitle: string;
  _saleChannelOrderDetail = {};
  _isVoidDisabled = true;
  _isProceedDisabled = true;
  _saleDoc: SaleDoc;
  protected webUrl = environment.apiUrl;
  FullSizeDialogName = FullSizeDialogName;
  _isLoading = false;
  footerFormGroup: UntypedFormGroup;
  @ViewChild("accountsSearchBox", { static: true }) accountSearchComponent: TakuSearchAccountsComponent;
  @ViewChild("accountNamePanel") accountNamePanel: any;
  @ViewChild("saleLines", { static: true }) saleLinesWrapper: ElementRef;
  _activeFullDialog: FullSizeDialogName;
  _activeFullDialogExtra: PersonalAccount | CommercialAccount = null;
  addToTAKUDialogVisible = false;
  AccountType = AccountType;
  accountType: AccountType = AccountType.personal;

  constructor(
    private fb: UntypedFormBuilder,
    public config: DynamicDialogConfig,
    private dbService: DBService,
    private confirmationService: ConfirmationService,
    private saleChannelOrdersService: SaleChannelOrdersService,
    protected messageService: MessageService,
    private appSettingsService: AppSettingsStorageService,
    public ref: DynamicDialogRef,
    private _router: Router,
    private alertMessagesService: AlertMessagesService
  ) {
    this._saleChannelOrder = this.config.data._saleChannelOrder;
    this._headerTitle = `<span> ${this._saleChannelOrder.docNo} - Online Order (Preview)</span>`;
    this.footerFormGroup = this.fb.group({
      accountId: this.fb.control(null, Validators.required),
    });
  }

  get accountIdCtrl(): AbstractControl {
    return this.footerFormGroup?.get("accountId");
  }

  onTaxAdded(): void {
    this.fetchSaleChannelOrderDetail();
  }

  accountNameToggle($event) {
    this.accountNamePanel.toggle($event);
    this.populateSearchFields();
  }

  populateSearchFields(): any {
    this.accountSearchComponent.textSearch = this._saleChannelOrder["accountName"];
  }

  onNewPersonalAccountCreated(personalAccount: PersonalAccount): void {
    const accountResultItem = SearchResultItem.build("personalAccount", personalAccount);
    this._setAccount(accountResultItem);
    this.closeFullSizeDialog();
  }

  onNewCommercialAccountCreated(commercialAccount: CommercialAccount): void {
    const accountResultItem = SearchResultItem.build("commercialAccount", commercialAccount);
    this._setAccount(accountResultItem);
    this.closeFullSizeDialog();
  }

  _setAccount(account: SearchResultItem): void {
    if (account) {
      this.onAccountSelected(account);
    }
  }

  onAccountSelected(event): void {
    this.saleChannelOrdersService
      .patchRow("saleChannelOrder", { id: this._saleChannelOrder.id, accountId: event.data.accountId })
      .subscribe({
        next: () => {
          this._saleChannelOrder.accountId = event.data.accountId;
          this._saleChannelOrder.account = event.data.account;
          this._saleChannelOrder["accountName"] = this.getAccountName(event.data);
          this.messageService.add(this.alertMessagesService.getMessage("save-success"));
        },
        error: (error) => {
          this.footerFormGroup.reset(this._saleChannelOrder);
          this.messageService.add(this.alertMessagesService.getErrorMessage(error));
        },
      });
  }

  private getAccountName(searchResult): string {
    if (searchResult.account.accountType === AccountType.personal) {
      const { firstName, middleName, lastName } = searchResult.person as Person;
      return [firstName || "", middleName || "", lastName || ""].join(" ");
    }

    if (searchResult.account.accountType === AccountType.commercial) {
      return searchResult.name;
    }

    return "";
  }

  fetchSaleChannelOrderDetail(): void {
    this.dbService
      ._postRequest(
        `${this.webUrl}TAKUeCommerceOrderDetail/${this._saleChannelOrder.saleChannel.id}/${this._saleChannelOrder.docNo}`,
        {
          stationId: this.appSettingsService.getStationId(),
          storeId: this._saleChannelOrder.storeId,
          cashoutId: this.appSettingsService.getCashoutId(),
          fiscalYearId: this.appSettingsService.getFiscalYearId(),
        }
      )
      .subscribe((result: any) => {
        this._saleChannelOrderDetail = result;
        this._saleDoc = new SaleDoc();
        Object.assign(this._saleDoc, result.saleDoc);
        this._isProceedDisabled = this._saleDoc.groupedTaxLines.findIndex((row) => row.saleTaxRule === null) !== -1;
      });
  }

  ngOnInit(): void {
    this.fetchSaleChannelOrderDetail();
    this._isVoidDisabled =
      this._saleChannelOrder.orderStatus !== OrderStatus.new ||
      ![SaleChannelPaymentStatus.unpaid, SaleChannelPaymentStatus.paid, SaleChannelPaymentStatus.partlyPaid].includes(
        this._saleChannelOrder.paymentStatus
      ) ||
      this._saleChannelOrder.fulfillmentStatus === SaleChannelFulfillmentStatus.completed;
  }

  get _isMobile() {
    return WebHelpers.isMobileScreen();
  }

  get hasScrollbars() {
    const linesEl = this.saleLinesWrapper.nativeElement;
    return linesEl.scrollHeight > linesEl.clientHeight;
  }

  onClosePressed() {
    // this.dialogClosed.emit(this.saleDoc);
    // if (this._saleChannelOrder) {
    //   this.saleChannelOrdersService.ref.close(this._saleChannelOrder);
    // } else {
    if (this.ref) {
      this.ref.close();
    }
    // }
  }

  onVoidPressed() {
    let _message = "";
    if (
      this._saleChannelOrder.orderStatus === OrderStatus.new &&
      this._saleChannelOrder.paymentStatus === SaleChannelPaymentStatus.unpaid
    ) {
      _message = `By clicking Yes below, you will be VOIDING this online order. This is final and cannot be reversed.

      Are you sure you want to proceed?`;
    }
    if (
      this._saleChannelOrder.orderStatus === OrderStatus.new &&
      (this._saleChannelOrder.paymentStatus === SaleChannelPaymentStatus.paid ||
        this._saleChannelOrder.paymentStatus === SaleChannelPaymentStatus.partlyPaid)
    ) {
      _message = `By clicking Yes below, you will be VOIDING this online order. This is final and cannot be reversed.

      Please note that this order is already PAID and you will need to refund any payments already received. After voiding, this order will be marked as Pending Refund until you Mark as Refunded.

      Are you sure you want to proceed?`;
    }
    this.confirmationService.confirm({
      header: "Void Confirmation",
      message: `By Clicking Yes below, you will be VOIDING this online order. It may be necessary for you to refund any payment already received.
      Are you sure you want to proceed?`,
      accept: () => {
        // send API request to change Ecwid PS to Cancelled and FS to Will Not Deliver
        this.saleChannelOrdersService
          .editSaleChannelOrder(this._saleChannelOrder, {
            paymentStatus: "CANCELLED",
            fulfillmentStatus: "WILL_NOT_DELIVER",
          })
          .subscribe((result) => {
            this._saleChannelOrder.orderStatus =
              this._saleChannelOrder.paymentStatus === SaleChannelPaymentStatus.unpaid
                ? OrderStatus.voided
                : OrderStatus.pendingRefund;
            this._saleChannelOrder.fulfillmentStatus = SaleChannelFulfillmentStatus.voided;
            this._saleChannelOrder.paymentStatus = SaleChannelPaymentStatus.voided;
            this._saleChannelOrder.deliveryStatus = SaleChannelDeliveryStatus.voided;
            this.saleChannelOrdersService.editRow("saleChannelOrder", this._saleChannelOrder).subscribe((result) => {
              // close the dialog in back
              this.saleChannelOrdersService.ref.close(this._saleChannelOrder);
            });
          });
      },
    });
  }

  onProceedPressed(): void {
    this._isLoading = true;
    if (this.appSettingsService.getStationId()) {
      const cashout = this.appSettingsService.getCashout();
      if (!cashout || cashout.isClosed) {
        // show confirmation message for forwarding to cashout
        this.confirmationService.confirm({
          header: "Register is Currently Closed",
          message: `Please open it first in order to Proceed.`,
          acceptLabel: "OPEN REGISTER",
          rejectVisible: false,
          acceptVisible: true,
          accept: () => {
            this._isLoading = false;
            this.saleChannelOrdersService.ref.close();
            this._router.navigate(["/sell/cashout", 0, "saleChannelOrder", this.appSettingsService.getStoreId()]);
          },
          reject: () => {
            this._isLoading = false;
          },
        });
      } else {
        if (!this._saleChannelOrder["accountName"]) {
          this.addToTAKUDialogVisible = true;
        } else {
          this.acceptSaleChannelOrder();
        }
      }
    } else {
      this.messageService.add({
        severity: "error",
        summary: "Notification",
        detail: "To accept an order, please log in to a Store.",
      });
      this._isLoading = false;
    }
  }

  createAccountAndAccept(accountType): void {
    // create new personal account and
    if (accountType === AccountType.personal) {
      const newPersonalAccount = new PersonalAccount();
      newPersonalAccount.person.firstName = this._saleChannelOrderDetail["shippingPerson"]["name"];
      newPersonalAccount.person.lastName = " ";
      const newPersonEamil = new PersonEmail();
      newPersonEamil.email = this._saleChannelOrderDetail["email"];
      newPersonalAccount.person.personEmails.push(newPersonEamil);
      this.dbService.addRow("personalAccount", newPersonalAccount).subscribe((_personalAccount) => {
        this.saleChannelOrdersService
          .patchRow("saleChannelOrder", { id: this._saleChannelOrder.id, accountId: _personalAccount.accountId })
          .subscribe({
            next: () => {
              this._saleChannelOrder.accountId = _personalAccount.accountId;
              this._saleChannelOrder.account = _personalAccount.account;
              this._saleChannelOrder["accountName"] = this.getAccountName(_personalAccount);
              this.messageService.add(this.alertMessagesService.getMessage("save-success"));
              this.acceptSaleChannelOrder();
            },
            error: (error) => {
              this.footerFormGroup.reset(this._saleChannelOrder);
              this.messageService.add(this.alertMessagesService.getErrorMessage(error));
            },
          });
      });
    }
    if (accountType === AccountType.commercial) {
      // Fetch business details to get the defaultCurrencyIsoCode from appSettingService
      this.appSettingsService
        .getBusinessDetails()
        .pipe(
          switchMap((businessDetail: BusinessDetail) => {
            const defaultCurrencyIsoCode = businessDetail.defaultCurrencyIsoCode;
            const newCommercialAccount = new CommercialAccount(defaultCurrencyIsoCode);
            newCommercialAccount.name = this._saleChannelOrderDetail["shippingPerson"]["name"];
            const newCommercialEmail = new CommercialAccountEmail();
            newCommercialEmail.email = this._saleChannelOrderDetail["email"];
            newCommercialAccount.commercialAccountEmails.push(newCommercialEmail);

            return this.dbService.addRow("commercialAccount", newCommercialAccount).pipe(
              switchMap((_commercialAccount) => {
                return this.saleChannelOrdersService
                  .patchRow("saleChannelOrder", {
                    id: this._saleChannelOrder.id,
                    accountId: _commercialAccount.accountId,
                  })
                  .pipe(
                    tap(() => {
                      this._saleChannelOrder.accountId = _commercialAccount.accountId;
                      this._saleChannelOrder.account = _commercialAccount.account;
                      this._saleChannelOrder["accountName"] = this.getAccountName(_commercialAccount);
                      this.messageService.add(this.alertMessagesService.getMessage("save-success"));
                      this.acceptSaleChannelOrder();
                    }),
                    catchError((error) => {
                      this.footerFormGroup.reset(this._saleChannelOrder);
                      this.messageService.add(this.alertMessagesService.getErrorMessage(error));
                      return throwError(error); // Ensure the observable completes with an error
                    })
                  );
              })
            );
          })
        )
        .subscribe();
    }
  }

  private acceptSaleChannelOrder(): void {
    this.saleChannelOrdersService.acceptSaleChannelOrder(this._saleChannelOrder).subscribe(
      (_saleDoc: SaleDoc) => {
        if (_saleDoc) {
          this._saleChannelOrder.saleDocId = _saleDoc.id;
          this._saleChannelOrder.saleDoc = _saleDoc;
          this._saleChannelOrder.orderStatus = OrderStatus.accepted;
        }
        this.saleChannelOrdersService.editRow("saleChannelOrder", this._saleChannelOrder).subscribe((result) => {
          // this._saleChannelOrder = result;
          this._isLoading = false;
          this.saleChannelOrdersService.ref.close(this._saleChannelOrder);
        });
      },
      (err) => {
        this.messageService.add({
          severity: "error",
          summary: "Accepting Order Error",
          detail: err.error.message,
        });
        this._isLoading = false;
      }
    );
  }

  openNewModelInDialog(defaultData: any): void {
    let dialogName: FullSizeDialogName;

    if (defaultData instanceof CommercialAccount) {
      dialogName = FullSizeDialogName.COMMERCIAL_ACCOUNT;
      defaultData.account.accountRelationship = AccountRelationship.customer;
    }

    if (defaultData instanceof PersonalAccount) {
      dialogName = FullSizeDialogName.PERSONAL_ACCOUNT;
      defaultData.account.accountRelationship = AccountRelationship.customer;
    }

    this.openFullSizeDialog(dialogName, defaultData);
  }

  openFullSizeDialog(dialog: FullSizeDialogName, extra: CommercialAccount | PersonalAccount = null): void {
    this._activeFullDialog = dialog;
    this._activeFullDialogExtra = extra;
  }

  closeFullSizeDialog(): void {
    this._activeFullDialog = null;
    this._activeFullDialogExtra = null;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
