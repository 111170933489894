/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

// @ts-nocheck
import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  OnDestroy,
} from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { DialogService } from "primeng/dynamicdialog";
import { MessageService } from "primeng/api";
import { Observable, ReplaySubject, Subscription } from "rxjs";
import { map, mergeMap, tap } from "rxjs/operators";
import { FormListModel } from "../../../../../form-list/form-list/form-list-model.interface";
import { FormListComponent } from "../../../../../form-list/form-list/form-list.component";
import { AlertMessagesService } from "../../../../../shared/services/alert-messages.service";
import { GMBHelpers } from "../../../../../utility/GMBHelpers";
import { Store } from "../../../store-settings/store/store";
import { BackendGMBService } from "../BackendGMBService";
import { GmbLocationGroupSelectorComponent } from "../gmb-locationgroup-selector/gmb-locationgroup-selector.component";
import { VerificationMethodSelectorComponent } from "../verification-method-selector/verification-method-selector.component";
import { GMBLocation, GMBLocationVerificationStatus } from "./GMBLocation";
import { GMBLocationsFactoryService } from "./GMBLocationsService";
import { VerificationEnterPinComponent } from "../verification-enter-pin/verification-enter-pin.component";
import { GMBAccount } from "../gmb-accounts-list/GMBGoogleAccount";
import { Location } from "@angular/common";

@Component({
  selector: "taku-gmb-locations-list",
  templateUrl: "./gmb-locations-list.component.html",
  styleUrls: ["./gmb-locations-list.component.scss"],
  providers: [DialogService],
})
export class GmbLocationsListComponent implements OnInit, AfterViewInit, OnDestroy {
  subsList: Subscription[] = [];

  @ViewChild(FormListComponent, { static: true }) locationsFormListComponent: FormListComponent;
  @ViewChild("verificationColTpl", { static: true }) verificationColTpl: TemplateRef<any>;
  @ViewChild("templateContentHere", { read: ViewContainerRef }) templateContentHere: ViewContainerRef;

  gmbLocations: GMBLocation[] = [];
  _model = "gmbLocation";
  _gmbLocationsService: FormListModel;
  showStoreDetailsEvent = new EventEmitter<GMBLocation>();
  openedStore: Store;
  GMBLocationVerificationStatus = GMBLocationVerificationStatus;
  _confirmVerificationEvent = new EventEmitter<any>();
  _requestVerificationEvent = new EventEmitter<any>();
  _locationsFilter = {};
  _gmbAccountIdRouteParam$: Observable<number>;
  private _gmbAccountSubject$: ReplaySubject<GMBAccount> = new ReplaySubject(1);
  _gmbAccount: GMBAccount;
  _googleEmail: string;
  _defaultRowValues: { gmbAccountId: number };

  constructor(
    private route: ActivatedRoute,
    private dbService: BackendGMBService,
    private dialogService: DialogService,
    protected location: Location,
    private gmbLocationsFactoryService: GMBLocationsFactoryService,
    private messageService: MessageService,
    private alertMessagesService: AlertMessagesService
  ) {
    this._gmbAccountIdRouteParam$ = this.route.params.pipe(map((params: Params) => params.gmbAccountId));

    this.subsList.push(
      this.showStoreDetailsEvent.subscribe((gmbLocation: GMBLocation) => {
        if (!gmbLocation.storeId) return false;

        this.subsList.push(
          this.dbService.getRow("store", gmbLocation.storeId).subscribe((store) => {
            this.locationsFormListComponent.showSidebar(store, "store");
          })
        );
      })
    );

    this.subsList.push(this._gmbAccountSubject$.subscribe((gmbAccount) => (this._gmbAccount = gmbAccount)));
  }

  get pageSubTitle() {
    let pageTitle = "<h2>GMB Locations";
    if (this._gmbAccount) pageTitle += ` for <strong>${this._gmbAccount.accountName}</strong>`;

    if (this._googleEmail) pageTitle += ` &nbsp;<span class="google-email">(${this._googleEmail})</span>`;
    // pageTitle += `  <span class="secondary-text">( ${this._googleEmail} )</span>`;

    pageTitle += "</h2>";

    return pageTitle;
  }

  goBack(): void {
    this.location.back();
  }

  ngOnInit() {
    this.subsList.push(
      this.route.queryParams
        .pipe(map((params: Params) => params.email))
        .subscribe((email) => (this._googleEmail = email))
    );

    this.subsList.push(
      this._gmbAccountIdRouteParam$.subscribe((gmbAccountId) => {
        this.subsList.push(
          this.dbService.getRow("gmbAccount", gmbAccountId).subscribe((response) => {
            this._gmbAccountSubject$.next(response);
          })
        );

        this._subscribeConfirmVerification(gmbAccountId);
        this._subscribeRequestVerificationEvent(gmbAccountId);
      })
    );
  }

  private _subscribeRequestVerificationEvent(_gmbAccountId: number) {
    this.subsList.push(
      this._requestVerificationEvent
        .pipe(
          tap((rowData: any) => {
            if (!rowData.name)
              return Observable.throw(new Error("Missing 'name' field for location. Please create the location first"));
          })
        )
        .subscribe((rowData: GMBLocation) => {
          const dialogRef = this.dialogService.open(VerificationMethodSelectorComponent, {
            data: {
              gmbAccountId: _gmbAccountId,
              locationName: rowData.name,
              languageCode: rowData.contentLanguage,
            },
          });
          this.subsList.push(
            dialogRef.onClose.subscribe((verificationOption) => {
              if (!verificationOption) return;

              const verificationBody = GMBHelpers.VerificationOptionToInputBody(
                verificationOption,
                rowData.contentLanguage
              );
              if (verificationBody instanceof Error) {
                this.messageService.add(
                  this.alertMessagesService.getErrorMessage(null, verificationBody.name, verificationBody.message)
                );
                return;
              }

              this.subsList.push(
                this.dbService.requestVerification(_gmbAccountId, rowData.name, verificationBody).subscribe({
                  next: (response) => {
                    this.locationsFormListComponent.reloadData();
                    this.messageService.add(this.alertMessagesService.getMessage("gmb-location-verification-create"));
                  },
                  error: (error) => {
                    this.messageService.add(this.alertMessagesService.getErrorMessage(error));
                  },
                })
              );
            })
          );
        })
    );
  }

  private _subscribeConfirmVerification(gmbAccountId: number) {
    this.subsList.push(
      this._confirmVerificationEvent
        .pipe(
          tap((rowData: any) => {
            if (!rowData.name)
              return Observable.throw(new Error("Missing 'name' field for location. Please create the location first"));
          }),
          mergeMap((rowData: any) => {
            return this.dbService
              .getPendingVerifications(gmbAccountId, rowData.name)
              .pipe(map((verifications) => [rowData, verifications]));
          })
        )
        .subscribe(([rowData, verifications]) => {
          if (!verifications.length) {
            this.messageService.add(
              this.alertMessagesService.getErrorMessage(null, "ERROR", "Cannot find any available verification request")
            );
            return;
          }

          // TODO: Take the first verification for now, but should filter out based on which was issued from the App
          const dialogRef = this.dialogService.open(VerificationEnterPinComponent, {
            data: {
              gmbAccountId: gmbAccountId,
              locationTitle: rowData.locationName,
              verification: verifications[0],
            },
          });
          this.subsList.push(
            dialogRef.onClose.subscribe((data) => {
              // Refresh form-list after closing the dialog if PIN was correct, so we update Status caption in form-list
              if (data.stateChanged) this.locationsFormListComponent.reloadData();
            })
          );
        })
    );
  }

  ngAfterViewInit(): void {
    this.subsList.push(
      this._gmbAccountIdRouteParam$.subscribe((gmbAccountId) => {
        this._locationsFilter["gmbAccountId"] = {
          matchMode: "equals",
          value: gmbAccountId,
        };
        this._defaultRowValues = { gmbAccountId };
        const locationService = this.gmbLocationsFactoryService.build(
          gmbAccountId,
          this.dialogService,
          this.showStoreDetailsEvent,
          {
            templateRef: this.verificationColTpl,
            data: {
              requestVerifEvent: this._requestVerificationEvent,
              confirmVerifEvent: this._confirmVerificationEvent,
            },
          }
        );
        this._gmbLocationsService = locationService;
      })
    );
    // const templateView = this.templateContentHere.createEmbeddedView(this.verificationColTpl, {
    //   '$implicit': {text: "This is my text"}
    // });
    // this.templateContentHere.insert(templateView);
  }

  refreshLocationsPressed() {
    const subscription = this.locationsFormListComponent.onRowsFetched.subscribe((event) => {
      this.refreshGMBLocations(event.objects);
      subscription.unsubscribe();
    });
    this.locationsFormListComponent.reloadData();
  }

  onGMBLocationsFetched() {
    // Should only considered locations that has been saved in the form list
    const dbLocations = this.locationsFormListComponent._objects;
    this.refreshGMBLocations(dbLocations);
  }

  private refreshGMBLocations(dbLocations: any[]) {
    this.subsList.push(
      this._gmbAccountIdRouteParam$.subscribe((gmbAccountId) => {
        this.subsList.push(
          this.dbService.getLocations(gmbAccountId).subscribe((googleLocations) => {
            googleLocations.forEach((location) => {
              let dbLocation = dbLocations.find((dbLocation) => dbLocation.name === location.name);
              if (!dbLocation) {
                // if location couldn't be found on database, so create and add new row
                dbLocation = this.dbService.buildDbLocationFromGoogle(gmbAccountId, location);
                this.locationsFormListComponent.addNewRow(dbLocation, false);
              }

              this.subsList.push(
                this.dbService.addDynamicGMBFields$(gmbAccountId, dbLocation, location).subscribe((successful) => {
                  if (successful) {
                  }
                })
              );
            });
          })
        );
      })
    );
  }

  showAccountSelectionDialog = (rowData): Observable<any> => {
    return this._gmbAccountSubject$.pipe(
      mergeMap((gmbAccount) => {
        const dialogRef = this.dialogService.open(GmbLocationGroupSelectorComponent, {
          data: { rowData, gmbAccount: gmbAccount },
        });
        return dialogRef.onClose.pipe(map((data: any) => data.rowData));
      })
    );
  };
  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
