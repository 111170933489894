/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, InjectionToken, Input, Optional, Inject } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder } from "@angular/forms";
import { LocationService } from "src/app/shared/services/location.service";
import { NestedFormDisplayMode, DISPLAY_MODE_TOKEN } from "../nested-form/nested-form.component";

export const DATA_ARRAY_TOKEN = new InjectionToken<string>("array-form.data-array");

@Component({
  template: "",
  styles: [],
})
export class ArrayFormComponent {
  @Input() set _dataArray(data: any[]) {
    this._formArray = this.setArray(data);
  }
  get _dataArray() {
    return this._formArray.value;
  }
  @Input() _displayMode: NestedFormDisplayMode;

  _formArray: UntypedFormArray;

  constructor(
    protected fb: UntypedFormBuilder,
    @Optional() @Inject(DATA_ARRAY_TOKEN) injectedDataArray,
    @Optional() @Inject(DISPLAY_MODE_TOKEN) injectedDisplayMode
  ) {
    this._dataArray = injectedDataArray;
    this._displayMode = injectedDisplayMode;
  }

  // Default implementation is just create form groups and push them into array
  setArray(dataArray: any[]) {
    const formArray = this.fb.array([]);
    dataArray.forEach((object) => {
      formArray.push(this.fb.group(object));
    });

    return formArray;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
