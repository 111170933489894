/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { PrintHelpers } from "../../../../utility/PrintHelpers";
import { SaleDoc } from "../../../document/sale-document/sale-doc/sale-doc";
import { StorePoliciesSettings } from "../store-policies-settings/StorePoliciesSettings";
import { StoreSettingsGenericComponent } from "../StoreSettingsGenericComponent";
import { Font_Size, Sort_Order } from "../tape-salereceipt-builder/store-setting-tape-sale-print";
import { StoreSettingGiftReceipt } from "./StoreSettingGiftReceipt";
import { BusinessDetail } from "../../business-settings/business-detail/business-detail";

@Component({
  selector: "taku-gift-receipt-builder",
  templateUrl: "gift-receipt-builder.component.html",
  styleUrls: ["gift-receipt-builder.component.scss"],
})
export class GiftReceiptBuilderComponent extends StoreSettingsGenericComponent {
  lookup_font_size: any[];
  lookup_transaction_sorting: any[];

  previewSize = "NARROW";
  _pageTitlePrefix = "Gift Receipt";
  receiptDemoData: {
    saledoc: SaleDoc;
    policies: StorePoliciesSettings;
    businessDetail: BusinessDetail;
  };

  initLookups() {
    this.lookup_font_size = this.dbService.enumSelectOptions(Font_Size);
    this.lookup_transaction_sorting = this.dbService.enumSelectOptions(Sort_Order);
  }

  initValidation() {
    this._validation = {
      headerFontSize: Validators.required,
    };
  }

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    const tmpModel = new StoreSettingGiftReceipt();
    const tmpForm = fb.group(tmpModel);

    return tmpForm;
  }

  initForm() {
    this._model = "storeSettingGiftReceipt";
    this._object = new StoreSettingGiftReceipt();
    this._myForm = GiftReceiptBuilderComponent.init(this.fb);
  }

  ngOnInit() {
    super.ngOnInit();

    this.subsList.push(
      this.printingFactoryService.createDemoData().subscribe((demoData) => {
        this.receiptDemoData = demoData;
      })
    );
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
