/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Input, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  AbstractControl,
  ValidatorFn,
  ValidationErrors,
} from "@angular/forms";
import { MenuItem } from "primeng/api";
import { NestedFormComponent } from "../../../forms/nested-form/nested-form.component";
import { UserService } from "../user/user.service";
import { UserRole } from "./user-role";

@Component({
  selector: "taku-user-role",
  templateUrl: "./user-role.component.html",
  styleUrls: ["./user-role.component.scss"],
})
export class UserRoleComponent extends NestedFormComponent implements OnInit {
  @Input() lookup_roles: MenuItem[];
  @Input() lookup_stores: MenuItem[];
  @Input() lookup_backOffices: MenuItem[];

  constructor(public dbService: UserService, public fb: UntypedFormBuilder) {
    super(dbService, fb);
  }

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    const userRole_Group = fb.group(new UserRole());
    return userRole_Group;
  }

  static setArray(fb: UntypedFormBuilder, userRoles: UserRole[]) {
    const formArray = fb.array([]);
    userRoles.map((userRole) => {
      const temp = fb.group(userRole);
      formArray.push(temp);
    });
    return formArray;
  }

  ngOnInit() {
    super.ngOnInit();
    this._myForm.setValidators(this.storeBackofficeConditionallyRequiredValidator);
  }

  get backOfficesCtrl() {
    return this._myForm.get("backOffices");
  }

  get storesCtrl() {
    return this._myForm.get("stores");
  }

  get roleIdCtrl() {
    return this._myForm.get("roleId");
  }

  initValidation() {
    this._validation = {
      roleId: [Validators.required],
      // stores: [Validators.required],
      // backOffices: [Validators.required]
    };
    // this._myForm.setValidators(UserValidators.validateUserRole());
  }

  storeBackofficeConditionallyRequiredValidator(c: AbstractControl): { [key: string]: boolean } | null {
    if (
      (c.get("stores").value && c.get("stores").value.length !== 0) ||
      (c.get("backOffices").value && c.get("backOffices").value.length !== 0)
    ) {
      return null;
    }
    return { storeBackofficeSelection: true };
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
