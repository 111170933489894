/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import * as _ from "lodash";
import { FiscalYearsSettings } from "../../settings/business-settings/fiscal-years-settings/FiscalYearsSettings";
import { User } from "../../users/user/user";

export class Doc {
  id: number;
  docType: SaleDocType | InventoryDocType;
  docDate: string;
  docTime: string;
  docClosingDate: string;
  docClosingTime: string;
  docNo: string;
  comment: string;
  voidReason: string;
  entryDateTime: string;
  state: DocState;
  currencyIsoCode: string;
  fiscalYearId: number;
  fiscalYear: FiscalYearsSettings;
  userId: number;
  user: User;
  docSourceType: SaleDocType | InventoryDocType | "Sale Channel Order";
  currencyConversionRate: number;

  constructor(_docType: SaleDocType | InventoryDocType, _docState: DocState) {
    this.id = 0;
    this.docType = _docType;
    this.docDate = null;
    this.docTime = null;
    this.docClosingDate = null;
    this.docClosingTime = null;
    this.docNo = "";
    this.comment = "";
    this.voidReason = "";
    this.entryDateTime = "";
    this.state = _docState;
    this.currencyIsoCode = "CAD"; // this shuld be default currency for zone that store is located
    this.fiscalYearId = 1;
    this.fiscalYear = null;
    this.userId = 0; // it should be current user id that logged in
    this.user = null;
    this.docSourceType = null;
    this.currencyConversionRate = 1;
  }

  get localDocTime() {
    return this.docTime;

    // try {
    //   return new LocalTimePipe(AppConstants.DEFAULT_LOCALE_ID).transform(this.docTime);
    // } catch(err){
    //   console.warn("Error when convertign time to local", err, this.docTime);
    //   return this.docTime;
    // }
  }

  get dateAndTime() {
    return this.docTime;
    // try {
    //   return new DateTimeFormatPipe(AppConstants.DEFAULT_LOCALE_ID).transform(this.docDate, this.docTime);
    // } catch(err){
    //   console.warn("Error when convertign time to local", err, this.docTime);
    //   return this.docTime;
    // }
  }

  get documentTitle(): string {
    switch (this.docType) {
      case SaleDocType.sales_invoice:
        return "INVOICE";

      case SaleDocType.sales_return:
        return "RETURN";

      case SaleDocType.online_sales_order:
        return "ONLINE ORDER";

      case SaleDocType.recurring_order:
        return "RECURRING ORDER";

      case SaleDocType.pre_order:
        return "PRE ORDER";

      default:
        return _.startCase(this.docType);
    }
  }
}

export enum SaleDocType {
  sales_invoice = "Sales Invoice",
  online_sales_order = "Online Sales Order",
  pre_order = "Pre Order",
  recurring_order = "Recurring Order",
  // sales_order = 'Sales Order',
  // layaway = 'Layaway',
  // work_order = 'Work Order',
  // custom_order = 'Custom Order',
  // back_order = 'Back Order',
  // standing_order = 'Standing Order',
  // sales_quote = 'Sales Quote',
  sales_return = "Sales Return",
  // sales_buy_back = 'Sales Buy Back',
}

export enum InventoryDocType {
  inventory_transfer_in = "Inventory Transfer In",
  inventory_transfer_out = "Inventory Transfer Out",
  // inventory_damage = 'Inventory Damage',
  stock_receiving = "Stock Receiving",
  purchase_order = "Purchase Order",
  // inventory_adjustment = 'Inventory Adjustment',
}

export enum DocState {
  suspended = "Suspended",
  draft = "Draft",
  voided = "Voided",
  pending_approval = "Pending Approval",
  approved = "Approved",
  finalized = "Finalized",
  // confirmed = 'Confirmed',
  // flagged = 'Flagged'
}

export enum DocStateLabels {
  draft = "Parked",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
