import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { random } from "lodash";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { Observable, Subscription, of } from "rxjs";
import { delay, map, switchMap, tap } from "rxjs/operators";
import { Inventory } from "src/app/core/inventory/inventory/inventory";
import { BusinessFormatsSettings } from "src/app/core/settings/business-settings/business-formats-settings/BusinessFormatsSettings";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { DBService } from "src/app/shared/services/db.service";
import { FilterRows } from "src/app/utility/FormDataHelpers";
import { ApiListResponse } from "src/app/utility/types";
import { Voucher } from "./voucher";

type CreateVoucherConfig = {
  inventory: Inventory;
  existingVouchers: Voucher[];
};

@Component({
  selector: "taku-create-voucher",
  templateUrl: "./create-voucher.component.html",
  styleUrls: ["./create-voucher.component.scss"],
})
export class CreateVoucherComponent implements OnInit, OnDestroy {
  form = new FormGroup({
    uniqueCode: new FormControl(
      { value: "", disabled: true },
      [Validators.required, Validators.minLength(12), this.codeInSaleDocValidator.bind(this)],
      [this.uniqueCodeValidator.bind(this)]
    ),
    value: new FormControl<number>(null, [Validators.required, Validators.min(0)]),
  });
  inventory: Inventory;
  /** Vouchers that are in the saleDoc that may not yet have been persisted */
  existingVouchers: Voucher[];
  currencyIsoCode: string;
  image = "/assets/layout/images/no-image.jfif";
  loading = false;
  subsList: Subscription[] = [];
  validatingUniqueCode = false;

  constructor(
    config: DynamicDialogConfig<CreateVoucherConfig>,
    private dbService: DBService,
    public dialogRef: DynamicDialogRef,
    private appSettingsService: AppSettingsStorageService
  ) {
    this.inventory = config.data.inventory;
    this.existingVouchers = config.data.existingVouchers;
    const image =
      this.inventory.inventoryImages?.find((img) => img.pinToPictureGallery === true)?.urlFileName150 ??
      this.inventory.inventoryImages?.[0]?.urlFileName150;
    if (image) {
      this.image = image;
    }
  }

  ngOnInit(): void {
    this.currencyIsoCode = this.appSettingsService.getZone().defaultCurrencyIsoCode;
    this.subsList.push(
      this.dbService
        .getRow<BusinessFormatsSettings>("businessFormat", 1)
        .pipe(switchMap((businessFormat) => this.createUniqueCode(businessFormat)))
        .subscribe((uniqueCode) => {
          this.form.controls.uniqueCode.setValue(uniqueCode, { emitEvent: false });
          this.form.controls.uniqueCode.enable();
        })
    );
  }

  private codeInSaleDocValidator(control: FormControl<string>): ValidationErrors {
    if (this.existingVouchers.map((v) => v.uniqueCode).includes(control.value)) {
      return { generic: "This code is already used in this sale" };
    }
    return null;
  }

  private uniqueCodeValidator(control: FormControl<string>): Observable<ValidationErrors> {
    this.validatingUniqueCode = true;
    return of(control.value).pipe(
      delay(300), // Used to debounce validation
      switchMap((uniqueCode) => this.checkIfCodeAlreadyUsed(uniqueCode)),
      map((uniqueCode) => (uniqueCode !== null ? null : { generic: "This code is already in use" })),
      tap(() => (this.validatingUniqueCode = false))
    );
  }

  /** Tries to create a unique code, checks the database, and recurses if the code is already used */
  private createUniqueCode(businessFormat: BusinessFormatsSettings): Observable<string> {
    const code = this.createRandomCode(businessFormat);
    return this.checkIfCodeAlreadyUsed(code).pipe(
      switchMap((code) => {
        if (code) {
          return of(code);
        }
        return this.createUniqueCode(businessFormat);
      })
    );
  }

  private createRandomCode(businessFormat: BusinessFormatsSettings): string {
    const numDigits = businessFormat.voucherNoOfDigits;
    // generates an int that has the correct number of digits, and can start with leading 0s
    const randomNumber = random(0, 10 ** numDigits - 1)
      .toString()
      .padStart(numDigits, "0");

    return businessFormat.voucherPrefix + randomNumber + businessFormat.voucherSuffix;
  }

  private checkIfCodeAlreadyUsed(code: string): Observable<string | null> {
    const filter: FilterRows<Voucher> = {
      uniqueCode: { matchMode: "equals", value: code },
    };
    return this.dbService
      .getRows<ApiListResponse<Voucher>>("voucher", JSON.stringify(filter))
      .pipe(map((res) => (res.count ? null : code)));
  }

  closeDialog(): void {
    const voucher = new Voucher(
      this.appSettingsService.getZoneId(),
      this.inventory.id,
      this.form.controls.value.value,
      this.form.controls.uniqueCode.value
    );

    this.dialogRef.close(voucher);
  }

  ngOnDestroy(): void {
    this.subsList.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
