import { Component, ElementRef, Input, OnInit } from "@angular/core";
import { SaleDoc } from "src/app/core/document/sale-document/sale-doc/sale-doc";
import { BusinessDetail } from "src/app/core/settings/business-settings/business-detail/business-detail";
import { StorePoliciesSettings } from "src/app/core/settings/store-settings/store-policies-settings/StorePoliciesSettings";
import { StoreSettingTapeReturnPrint } from "src/app/core/settings/store-settings/tape-returnreceipt-builder/store-setting-tape-return-print";
import { StoreSettingTapeSalePrint } from "src/app/core/settings/store-settings/tape-salereceipt-builder/store-setting-tape-sale-print";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { LocationService } from "src/app/shared/services/location.service";

@Component({
  selector: "taku-open-cash-drawer-preview",
  templateUrl: "./open-cash-drawer-preview.component.html",
  styleUrls: ["./open-cash-drawer-preview.component.scss"],
})
export class OpenCashDrawerPreviewComponent implements OnInit {
  @Input("builderSettings") settings: StoreSettingTapeSalePrint | StoreSettingTapeReturnPrint;
  @Input("saleDocData") saledoc: SaleDoc;
  @Input() businessDetail: BusinessDetail;
  @Input() policies: StorePoliciesSettings;

  stationId: number;
  userName: string;
  date = new Date();
  time = new Date().getTime();

  readonly STYLESHEET_PATH = "/assets/layout/taku/receipt-tape-preview.component.css";

  constructor(
    public elementRef: ElementRef,
    private appSettingsService: AppSettingsStorageService,
    public lService: LocationService
  ) {}

  ngOnInit(): void {
    this.stationId = this.appSettingsService.getStationId();
    this.userName = this.appSettingsService.getUser().userName;
  }
}
