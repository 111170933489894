import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ClearCacheService {
  private clearModelSubject = new Subject<string>();
  public clearModel$ = this.clearModelSubject.asObservable();

  delete(modelName: string): void {
    this.clearModelSubject.next(modelName);
  }
}
