/* © 2018-2024 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Input, ViewChild, ElementRef } from "@angular/core";
import { InvoiceHelpers } from "src/app/utility/InvoiceHelpers";
import { DBService } from "src/app/shared/services/db.service";
import { MessageService } from "primeng/api";
import { Inventory, InventoryStock } from "src/app/core/inventory/inventory/inventory";
import { UntypedFormArray, UntypedFormBuilder } from "@angular/forms";
import { InventoryStockComponent } from "src/app/core/inventory/inventory-stock/inventory-stock.component";
import { NestedFormDisplayMode } from "src/app/forms/nested-form/nested-form.component";
import { WebHelpers } from "src/app/utility/WebHelpers";
import { DiscountType } from "../../sale-document/sale-doc/sale-doc";
import { PurchaseDoc, PurchaseDocLine } from "../purchase-document/purchase-document";
import { MonetaryHelpers } from "src/app/utility/MonetaryHelpers";

@Component({
  selector: "taku-purchase-doc-transactions",
  templateUrl: "./purchase-doc-transactions.component.html",
  styleUrls: ["./purchase-doc-transactions.component.scss"],
})
export class PurchaseDocTransactionsComponent {
  @Input() purchaseDoc: PurchaseDoc;
  @ViewChild("saleLines", { static: true }) saleLinesWrapper: ElementRef;

  DiscountType = DiscountType;
  inventoryStocksByInventoryId: Record<number, InventoryStock[]> = {};
  _activeDialog = false;
  _dialogContent = "";
  _displayMode: NestedFormDisplayMode = NestedFormDisplayMode.TABULAR_DATA;

  _inventoryStockFormArray: UntypedFormArray = InventoryStockComponent.initArray(this.fb);
  InvoiceHelpers = InvoiceHelpers;

  constructor(private dbService: DBService, private messageService: MessageService, public fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.retrieveInventoryStocks();
  }

  private retrieveInventoryStocks(): void {
    const _inventoryIds = [];
    const _inventoryStocksByInventoryId: Record<number, InventoryStock[]> = {};

    this.purchaseDoc.purchaseDocLines?.forEach((saleLine) => {
      if (saleLine.docLine?.inventory?.id) {
        _inventoryIds.push(saleLine.docLine?.inventory?.id);
      }
    });

    const _filter = {
      inventoryId: {
        value: _inventoryIds,
        matchMode: "in",
      },
    };

    this.dbService.getRows("inventoryStock", JSON.stringify(_filter), 0, 50).subscribe((results) => {
      if (results?.rows?.length > 0) {
        const _inventoryStocks = results.rows;

        _inventoryStocks.forEach((_inventoryStock) => {
          if (!_inventoryStocksByInventoryId.hasOwnProperty(_inventoryStock.inventoryId)) {
            _inventoryStocksByInventoryId[_inventoryStock.inventoryId] = [_inventoryStock];
          } else {
            _inventoryStocksByInventoryId[_inventoryStock.inventoryId].push(_inventoryStock);
          }
        });
      }

      if (Object.keys(_inventoryStocksByInventoryId).length !== 0) {
        this.inventoryStocksByInventoryId = _inventoryStocksByInventoryId;
      }
    });
  }

  get tableScrollHeight(): string {
    // calc(90vh - 45pt)
    return WebHelpers.isMobileScreen() ? "330pt" : "316pt";
  }

  get _isMobile(): boolean {
    return WebHelpers.isMobileScreen();
  }

  get hasScrollbars(): boolean {
    const linesEl = this.saleLinesWrapper.nativeElement;
    return linesEl.scrollHeight > linesEl.clientHeight;
  }

  get purchaseDocGeneralDiscount(): number {
    const discountType = this.purchaseDoc.discountType;
    const discountAmount = this.purchaseDoc.discountAmount;
    let totalDiscount = 0;

    if (discountAmount === 0 || discountAmount === null) return totalDiscount;

    switch (discountType) {
      case DiscountType.Fixed:
        totalDiscount = discountAmount;
        break;
      case DiscountType.Percentage:
        totalDiscount = (this.purchaseDoc.subTotal * this.purchaseDoc.discountAmount) / 100;
        break;
    }
    return MonetaryHelpers.roundToDecimalPlaces(totalDiscount);
  }

  populateStockAvailabilityDialog(inventoryId: number): void {
    this._inventoryStockFormArray = InventoryStockComponent.setArray(
      this.fb,
      this.inventoryStocksByInventoryId[inventoryId]
    );
  }

  inventoryVariantsStr(_inventory: Inventory): string {
    return _inventory.inventoryVariants
      ?.map((_inventoryVariant) => _inventoryVariant.inventoryOptionsSettingValue?.optionValue)
      .join(", ");
  }

  getTaxFontColor(): string {
    if (this.purchaseDoc.groupedTaxLines.findIndex((row) => row.saleTaxRule === null) !== -1) {
      return "red";
    } else {
      return "black";
    }
  }

  isTaxConflict(): boolean {
    return this.purchaseDoc.groupedTaxLines.findIndex((row) => row.saleTaxRule === null) !== -1;
  }

  unitCost(purchasDocLine: PurchaseDocLine): number {
    if (purchasDocLine.allocatedDiscountAmount !== 0) {
      if (purchasDocLine.allocatedDiscountType === DiscountType.Fixed) {
        return MonetaryHelpers.roundToDecimalPlaces(
          purchasDocLine.unitPrice -
            (purchasDocLine.qty !== 0 ? purchasDocLine.allocatedDiscountAmount / purchasDocLine.qty : 0),
          4
        );
      } else {
        return MonetaryHelpers.roundToDecimalPlaces(
          purchasDocLine.unitPrice - (purchasDocLine.allocatedDiscountAmount * purchasDocLine.unitPrice) / 100,
          4
        );
      }
    } else {
      return purchasDocLine.unitPrice;
    }
  }

  convertedUnitCost(purchaseDocLine: PurchaseDocLine): number {
    return MonetaryHelpers.roundToDecimalPlaces(Number(this.unitCost(purchaseDocLine)), 4);
  }

  convertedTotalDiscountAmount(purchaseDocLine: PurchaseDocLine): number {
    return MonetaryHelpers.roundToDecimalPlaces(purchaseDocLine.totalDiscountAmount);
  }

  convertedUnitDiscount(inventoryDocLine: PurchaseDocLine): number {
    return +inventoryDocLine.qty === 0
      ? 0
      : MonetaryHelpers.roundToDecimalPlaces(
          this.convertedTotalDiscountAmount(inventoryDocLine) / +inventoryDocLine.qty
        );
  }
}

/* © 2018-2024 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
