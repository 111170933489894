<div class="grid-card card" [ngStyle]="{ width: width + 'px', height: height + 'px' }">
  <div class="item-top short-img-height">
    <div class="card-img">
      <img
        [attr.src]="
          (inventory?.inventoryImages && InventoryImagePinnedToPictureGallery?.urlFileName150) ||
          '../../../../assets/layout/images/no-image.jfif'
        "
      />
    </div>
    <div class="item-top-right">
      <ng-content select="[detailTooltip]"></ng-content>
      <i *ngIf="inventory.parentInventoryId" class="material-icons">photo_size_select_small</i>
      <i *ngIf="inventory.isVariantParent" class="material-icons">bookmarks</i>
      <i *ngIf="!inventory.parentInventoryId && !inventory.isVariantParent" class="material-icons">bookmark_border</i>
    </div>
  </div>
  <div class="item-content">
    <div class="inventory-content">
      <div class="inventory-name">
        <b>{{ inventory?.description1 }}</b>
      </div>
      <div *ngIf="!_isMobile && inventory" class="inventory-sku"> SKU: {{ inventory.sku }} </div>
    </div>
  </div>
  <div class="item-bottom">
    <div class="inventory-bottom">
      <div class="inventory-price">
        <span>
          {{ inventory.standardPrice | currency : "CAD" : "symbol-narrow" : "1.2" }}
        </span>
      </div>
      <div class="inventory-stock" *ngIf="!inventory.isVariantParent">
        <span
          [class]="'material-icons ' + stockStatusClass"
          [ngClass]="{ 'hide-stock': !isStockVisible }"
          [style]="{ fontSize: '15px' }"
        >
          discount
        </span>
        <span [class]="stockStatusClass" [ngClass]="{ 'hide-stock': !isStockVisible }" style="padding-bottom: 2px">
          {{ qtyAvail - (inventory?.defaultInventoryStock?.qtyInCart || 0) }}
        </span>
      </div>
    </div>
    <div
      [ngClass]="
        inventory['defaultInventoryStock']['qtyInCart'] > 0
          ? 'shopping-cart-badge-container'
          : 'shopping-cart-no-badge-container'
      "
    >
      <i
        class="material-icons-outlined"
        pBadge
        severity="success"
        [value]="inventory['defaultInventoryStock']['qtyInCart']"
        style="font-size: 1.5rem"
        >shopping_cart</i
      >
    </div>
  </div>
</div>
