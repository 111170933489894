/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Directive } from "@angular/core";

@Directive({
  selector: "[takuAutocompleteFormfield]",
})
export class AutocompleteFormfieldDirective {
  constructor() {}
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
