/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { PersonalAccountsAccessRightsFields } from "../shared/personal-accounts-access-rights-fields";
import { AuthService } from "src/app/shared/services/auth.service";

export class PersonalAccountAccessRights implements PersonalAccountsAccessRightsFields {
  isNoteVisible: boolean;
  isGeneralTabVisible: boolean;
  isAddressTabVisible: boolean;
  isStatusVisible = false;
  isTaxCategoryVisible = false;
  isTaxIdVisible = false;
  isCreditLimitVisible = false;

  isStatusEnabled: boolean;
  isTaxCategoryEnabled: boolean;
  isTaxIdEnabled: boolean;
  isCreditLimitEnabled: boolean;
  isNoteEnabled: boolean;
  isGeneralTabEnabled: boolean;
  isAddressTabEnabled: boolean;

  constructor(authService: AuthService) {
    this.isNoteVisible = authService.hasVisiblePermit("Accounts_Menu_Personal_Accounts_Notes");
    this.isStatusVisible = authService.hasVisiblePermit("Accounts_Menu_Personal_Accounts_Status");
    this.isTaxCategoryVisible = authService.hasVisiblePermit("Accounts_Menu_Personal_Accounts_Tax_Category");
    this.isTaxIdVisible = authService.hasVisiblePermit("Accounts_Menu_Personal_Accounts_Tax ID");
    this.isCreditLimitVisible = authService.hasVisiblePermit("Accounts_Menu_Personal_Accounts_Credit_Limit");
    this.isGeneralTabVisible = authService.hasVisiblePermit("Accounts_Menu_Personal_Accounts_General_Tab");
    this.isAddressTabVisible = authService.hasVisiblePermit("Accounts_Menu_Personal_Accounts_Address_Tab ");

    this.isStatusEnabled = authService.hasEnablePermit("Accounts_Menu_Personal_Accounts_Status");
    this.isTaxCategoryEnabled = authService.hasEnablePermit("Accounts_Menu_Personal_Accounts_Tax_Category");
    this.isTaxIdEnabled = authService.hasEnablePermit("Accounts_Menu_Personal_Accounts_Tax ID");
    this.isCreditLimitEnabled = authService.hasEnablePermit("Accounts_Menu_Personal_Accounts_Credit_Limit");
    this.isNoteEnabled = authService.hasEnablePermit("Accounts_Menu_Personal_Accounts_Notes");
    this.isGeneralTabEnabled = authService.hasEnablePermit("Accounts_Menu_Personal_Accounts_General_Tab");
    this.isAddressTabEnabled = authService.hasEnablePermit("Accounts_Menu_Personal_Accounts_Address_Tab ");
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
