/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import * as pcValidator from "postal-codes-js/postal-codes";

@Injectable({
  providedIn: "root",
})
export class TakuAddressValidationService {
  constructor() {}

  /*
   * Check is the provided postal code is valid, returning true if valid, false otherwise
   * @param countryISOCode
   * @param postalCode
   */
  validatePostalCode(countryISOCode: string, postalCode: string): boolean {
    return pcValidator.validate(countryISOCode, postalCode) === true;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
