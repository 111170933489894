<a href="javascript://" class="material-icons md-18 -my-1 ml-2" (click)="inventoryStockDetailsOverlay.toggle($event)">
  info
</a>
<p-overlayPanel #inventoryStockDetailsOverlay appendTo="body">
  <button
    pButton
    pRipple
    class="p-ripple p-element p-overlaypanel-close p-link"
    (click)="inventoryStockDetailsOverlay.hide()"
    icon="pi pi-times"
  >
  </button>

  <div class="inventoryStockDetails-overlay">
    <taku-inventory-stock-details [inventoryStockDetails]="inventoryStockDetails" [qtyDetailsType]="qtyDetailsType">
    </taku-inventory-stock-details>
  </div>
</p-overlayPanel>
