import { Component, Input, OnDestroy, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import * as _ from "lodash";
import * as moment from "moment";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { Subscription } from "rxjs";
import { Account } from "src/app/core/contact-accounts/account/account";
import { AccountService, AccountStats } from "src/app/core/contact-accounts/account/account.service";
import { Col, RowSelectionType } from "src/app/form-list/form-list/form-list";
import { FormListComponent } from "src/app/form-list/form-list/form-list.component";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { RelativeTime, TimeHelpers } from "src/app/utility/TimeHelpers";
import { DocState, SaleDocType } from "../../doc/doc";
import { CRM_HistoryService } from "../crm-sale/crm-history.service";
import { SaleInvoiceStateService } from "../sale-invoice-state.service";

@Component({
  selector: "taku-crm-history",
  templateUrl: "./crm-history.component.html",
  styleUrls: ["./crm-history.component.scss"],
})
export class CrmHistoryComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];

  @Input() accountInfo: Account;
  crmHistoryColumns: Col[];
  historyFilter: {};
  accountSaleStats: AccountStats;
  accountReturnStats: AccountStats;
  RowSelectionType = RowSelectionType;
  lastTxrRelativeTime: RelativeTime;
  _digitsFormat = "1.2-2";

  accountId: number;
  accountCode: string;

  @ViewChild("formList") formListComp: FormListComponent;

  _crmSaleDocExtraParams = {
    includes: "saleDocTender",
  };

  _isAdmin = !this.appSettingsService.getStore().id && !this.appSettingsService.getBackoffice().id;

  constructor(
    protected appSettingsService: AppSettingsStorageService,
    private crmHistoryService: CRM_HistoryService,
    private saleInvoiceStateService: SaleInvoiceStateService,
    private accountService: AccountService,
    public config: DynamicDialogConfig
  ) {

    moment.updateLocale("en", {
      relativeTime: {
        s: "%d seconds",
      },
    });

    if (this.config && this.config.data) {
      if (this.config.data.accountInfo) {
        this.accountInfo = this.config.data.accountInfo;
      }
      this.updateHistoricalData();
    }
    this.crmHistoryColumns = this.crmHistoryService.getFormListColumns();
    this.subsList.push(
      this.saleInvoiceStateService.salesSaved$.subscribe((saleDoc) => {
        this.updateHistoricalData();
      })
    );
  }

  ngOnInit(): void {
    this.subsList.push(
      this.crmHistoryService.getCrmInvoiceDetails.subscribe((saleInvoice) => {
        this.formListComp.goEdit(saleInvoice);
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["accountInfo"]) {
      this.updateHistoricalData();
    }
  }

  updateHistoricalData() {
    // let accountId, accountCode;

    if (!this.accountInfo) return;
    // accountId = this.accountInfo.id;
    // accountCode = this.accountInfo.accountCode;
    // }
    // console.log(accountId, accountCode);
    // const account = this.accountInfo.account;
    this.historyFilter = {
      accountId: {
        matchMode: "equals",
        value: this.accountInfo.id,
      },
      "doc.state": {
        matchMode: "equals",
        value: DocState.finalized,
      },
      "doc.docType": {
        value: [
          SaleDocType.sales_invoice,
          SaleDocType.online_sales_order,
          SaleDocType.sales_return,
          SaleDocType.recurring_order,
          SaleDocType.pre_order,
        ],
        matchMode: "in",
      },
    };
    this.lastTxrRelativeTime = null;
    this.subsList.push(
      this.accountService.getStatistics(this.accountInfo.accountCode).subscribe((allStats) => {
        if (allStats && allStats.length) {
          // TODO: Interate over all stats instead of taking only the first one as the default
          this.accountSaleStats = Object.assign({}, allStats[0]);

          this.accountSaleStats.InvoiceCount = 0;
          this.accountSaleStats.TotalSales = 0;
          this.accountSaleStats.LastTransaction = null;

          const _saleStatsList = allStats.filter((stats) => {
            return (
              stats.docType === SaleDocType.sales_invoice ||
              stats.docType === SaleDocType.online_sales_order ||
              stats.docType === SaleDocType.recurring_order ||
              stats.docType === SaleDocType.pre_order
            );
          });

          _saleStatsList.map((_stats) => {
            this.accountSaleStats.TotalSales += Number(_stats.TotalSales);
            this.accountSaleStats.InvoiceCount += Number(_stats.InvoiceCount);
            if (_stats.LastTransaction > (this.accountSaleStats.LastTransaction || "")) {
              this.accountSaleStats.LastTransaction = _stats.LastTransaction;
            }
          });

          // const stats = allStats[0];
          if (this.accountSaleStats.LastTransaction) {
            const timeAgo = moment(this.accountSaleStats.LastTransaction).fromNow(true);
            // const timeAgo = moment.utc(stats.LastTransaction).fromNow(true);
            this.lastTxrRelativeTime = TimeHelpers.ParseRelativeTimeString(timeAgo);
          } else {
            this.lastTxrRelativeTime = null;
          }
          // this.accountSaleStats = stats;
          this.accountReturnStats = allStats.find((stats) => stats.docType === SaleDocType.sales_return);
        } else {
          // If the response is invalid, doesn't have data, empty previous stats variables
          this.lastTxrRelativeTime = null;
          this.accountSaleStats = null;
          this.accountReturnStats = null;
        }
      })
    );
    // this.dbService.getRows('saleDoc', JSON.stringify(this.historyFilter), 0, 1).subscribe(response => {
    //   if (response.rows.length){
    //     const lastDoc:SaleDoc = response.rows[0];
    //     this.lastTransactionTime = TimeHelpers.UnifyDateAndTime(lastDoc.doc.docDate, lastDoc.doc.docTime);
    //     const timeAgo = moment(this.lastTransactionTime).fromNow(true);
    //     this.lastTxrRelativeTime = TimeHelpers.ParseRelativeTimeString(timeAgo);
    //   }
    // })
  }

  get invoiceCountCaption() {
    let quantity = "0",
      invoiceWord = "Invoices";
    if (this.accountSaleStats && _.isNumber(this.accountSaleStats.InvoiceCount)) {
      quantity = this.accountSaleStats.InvoiceCount + "";
      if (this.accountSaleStats.InvoiceCount === 1) invoiceWord = "Invoice";
    }

    return `${quantity} ${invoiceWord}`;
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}
