import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SaleDocFooterComponent } from "../sale-doc-footer/sale-doc-footer.component";

@Component({
  selector: "taku-self-checkout-mobile-footer",
  templateUrl: "./self-checkout-mobile-footer.component.html",
  styleUrls: ["./self-checkout-mobile-footer.component.scss"],
})
export class SelfCheckoutMobileFooterComponent extends SaleDocFooterComponent {
  @Output() onSelfCheckoutPay: EventEmitter<any> = new EventEmitter();

  onSelfCheckoutPayBtnPressed() {
    // console.log("PAYING NOW IN MOBILE");
    this.onSelfCheckoutPay.emit(this);
  }
}
