/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "userRoleConversion",
})
export class UserRolePipe implements PipeTransform {
  transform(userRoleNum: number): string {
    switch (userRoleNum) {
      case 1:
        return "Guest";
      case 2:
        return "Cashier";
      case 4:
        return "Manager";
    }
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
