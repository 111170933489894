/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import {
  Component,
  Inject,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ConfirmationService, MenuItem, MessageService, SelectItem } from "primeng/api";
import { Observable, of, Subscription } from "rxjs";
import { Col, RowSelectionType } from "src/app/form-list/form-list/form-list";
import { FormListComponent } from "src/app/form-list/form-list/form-list.component";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import * as moment from "moment";
import * as _ from "lodash";
import { CalendarDateRangeType } from "src/app/taku-ui/taku-calendar-range/taku-calendar-range.component";
import { environment } from "src/environments/environment";
import {
  PrintingFactoryService,
  PrintingMgr,
  ReceiptPrintingSize,
} from "src/app/shared/services/printing-service.service";
import { SaleDoc } from "../sale-doc/sale-doc";
import { CommitmentsService } from "./commitments.service";
import { Commitments, CommitmentsState } from "./commitments";
import { SearchResultItem } from "src/app/taku-ui/taku-search-accounts/SearchResultItem";
import { SaleDocLine } from "../sale-doc-line/sale-doc-line";
import { TimeHelpers } from "src/app/utility/TimeHelpers";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";

@Component({
  selector: "taku-commitments",
  templateUrl: "./commitments.component.html",
  styleUrls: ["./commitments.component.scss"],
})
export class CommitmentsComponent implements OnInit, OnDestroy {
  private static readonly DATE_FORMAT = "yyyy-MM-DD";
  subsList: Subscription[] = [];
  protected webUrl = environment.apiUrl;
  _isDataReady: boolean;
  ReceiptPrintingSize = ReceiptPrintingSize;
  _isFetchingData = false;
  @ViewChild("formList", { static: false }) formListComponent: FormListComponent;
  @ViewChild("repeatCycleTpl", { static: true }) repeatCycleTpl: TemplateRef<any>;
  @ViewChild("orderAmountTpl", { static: true }) orderAmountTpl: TemplateRef<any>;

  // printDocumentEvent: EventEmitter<Commitments> = new EventEmitter();

  lookup_stores$: Observable<SelectItem[]> = of([]);
  isAdmin: boolean;

  enum_delivery_methods = this.commitmentService.enum_delivery_methods;
  enum_payment_statuses = this.commitmentService.enumSelectOptions(CommitmentsState);
  // enum_order_statuses = this.commitmentService.enum_order_statuses;
  enum_repeat_cycle(rowData) {
    return this.commitmentService.enumRepeatCycle(rowData);
  }
  getPaymentCycle(rowData, i) {
    return this.commitmentService.getPaymentCycle(rowData, i);
  }
  _headerTitle = "Commitment #";
  commitmentColumns: Col[] = [];
  formGroup: UntypedFormGroup;
  headerFormGroup: UntypedFormGroup;
  formFilter: {} = null;
  // showFormList: boolean;
  RowSelectionType = RowSelectionType;
  activeAccountName: string;
  printMgr: PrintingMgr;
  @ViewChild("printPreviewHost", { read: ViewContainerRef, static: true }) printPreviewHost: ViewContainerRef;

  constructor(
    private commitmentService: CommitmentsService,
    private fb: UntypedFormBuilder,
    @Inject(LOCALE_ID) private defaultLocale,
    private appSettingsService: AppSettingsStorageService,
    private printingMgrFactory: PrintingFactoryService,
    private messageService: MessageService,
    protected alertMessage: AlertMessagesService,
    protected confirmationService: ConfirmationService
  ) {
    this._isDataReady = false;
    const activeStore = this.appSettingsService.getStore();
    this.isAdmin = !activeStore.id;
    this.lookup_stores$ = this.commitmentService.lookup_stores();
    this.formGroup = this.fb.group(this.getDefaultValues());
    if (!activeStore.id) {
      this.formGroup.get("storeId").disable();
    }
    this.searchOrders();
    // setTimeout(() => {
    //   this._isDataReady = true;
    // }, 0);
    this.formGroup.valueChanges.subscribe((changes) => {
      this.searchOrders();
    });
    // this.subsList.push(this.printDocumentEvent.subscribe((saleChannelOrder: Commitments) => {
    //   this.printPackingList(saleChannelOrder, ReceiptPrintingSize.LETTER_SIZE);
    // }));
    // this._headerTitle = `<span> ${this._saleChannelOrder.docNo} - Online Order (Preview)</span>`;
  }

  printPackingList(commitments: Commitments, printSize: ReceiptPrintingSize) {
    if (commitments) {
      this.printMgr = this.printingMgrFactory.build(commitments.storeId);
      this.subsList.push(
        this.printMgr.isReady.subscribe({
          next: () => {
            this.commitmentService.getRow("saleDoc", commitments?.["refSaleDocId"]).subscribe((_saleDoc: SaleDoc) => {
              const _tempSaleDoc: SaleDoc = Object.assign({}, _saleDoc);
              _tempSaleDoc.saleDocLines.map((_saleDocLine) => {
                _saleDocLine.unitPrice = 0;
                _saleDocLine.salePrice = 0;
              });
              _tempSaleDoc.subTotal = 0;
              _tempSaleDoc.shipperChargeAmount = 0;
              _tempSaleDoc.discountAmount = 0;
              _tempSaleDoc.saleDocLines.map((_saleDocLine) => {
                _saleDocLine.saleDocLineTaxes = [];
              });
              _tempSaleDoc.saleDocTenders = [];
              _tempSaleDoc.cashRounding = 0;
              _tempSaleDoc.saleDocTaxes = [];
              _tempSaleDoc.totalLineTax = 0;
              _tempSaleDoc["isPackingList"] = true;

              // Change old sale doc information by commiments information
              const dateParts = TimeHelpers.SplitDateAndTime(commitments?.["createdAt"], false, true);
              _tempSaleDoc.doc.docDate = dateParts[0];
              _tempSaleDoc.doc.docTime = dateParts[1];
              const saleDocLines: SaleDocLine[] = [];
              _tempSaleDoc.saleDocLines.forEach((saleDocLine) => {
                commitments?.["commitmentLines"].forEach((commitment) => {
                  if (commitment?.inventoryId === saleDocLine?.docLine?.inventoryId) {
                    saleDocLine.qty = commitment.qty;
                    saleDocLine.unitPrice = commitment.unitPrice;
                    saleDocLine.discountAmount = commitment.discountAmount;
                    saleDocLines.push(saleDocLine);
                  }
                });
              });
              _tempSaleDoc.saleDocLines = saleDocLines;
              this.printMgr.printDocumentsWithSize([_tempSaleDoc], this.printPreviewHost, printSize);
            });
          },
          error: (error) => {
            alert("Error: Can't print because Receipt Builder Settings are not available");
          },
        })
      );
    }
  }

  getDefaultValues() {
    const dateFrom = new Date();
    dateFrom.setDate(dateFrom.getDate() - 31);
    const dateTo = new Date();
    const _storeId = this.appSettingsService.getStoreId();
    return {
      dateRangeType: CalendarDateRangeType.CUSTOM,
      dateFrom: dateFrom, // formatDate(dateFrom, SaleChannelOrdersComponent.DATE_FORMAT, this.defaultLocale),
      dateTo: dateTo, // formatDate(dateTo, SaleChannelOrdersComponent.DATE_FORMAT, this.defaultLocale),
      pickupDateFrom: null,
      pickupDateTo: null,
      saleChannelId: -1,
      customer: null,
      paymentStatus: null,
      fulfillmentStore: this.isAdmin ? -1 : _storeId,
      deliveryMethod: null,
    };
  }

  ngOnInit() {
    this._isFetchingData = true;
    this.downloadOrders().subscribe((result: string) => {
      // this.commitmentColumns = this.commitmentService.getFormListColumns(this.printDocumentEvent);
      this.commitmentColumns = this.commitmentService.getFormListColumns(
        {
          printItems: this.getPrintItems.bind(this),
          paymentItems: this.getPaymentItems.bind(this),
        },
        {
          repeatCycleTemplateOptions: {
            data: {},
            templateRef: this.repeatCycleTpl,
          },
          orderAmountTemplateOptions: {
            data: {},
            templateRef: this.orderAmountTpl,
          },
        }
      );
      if (this.formListComponent) this.formListComponent.unSelectAllRows();
      this._isDataReady = true;
      this._isFetchingData = false;
    });
  }

  downloadOrders(): Observable<string> {
    return this.commitmentService._postRequest(this.webUrl + "TAKUeCommerceDownloadOrders", {});
  }

  clearSearch() {
    this._isFetchingData = true;
    this.downloadOrders().subscribe((result: string) => {
      if (this.formListComponent) this.formListComponent.unSelectAllRows();
      this.formGroup.reset(this.getDefaultValues());
      this._isFetchingData = false;
    });

    this.clearSelectedAccount();
  }

  onGenerateRecurringOrder() {
    this.confirmationService.confirm({
      header: "Confirmation",
      message: `This will generate new Orders and assign the Next Charge Date to every selected row. Once generated, new Orders will immediately be visible in Upcoming Orders for fulfillment.
        <br><br>This action cannot be reversed.
        <br><br>Are you sure you want to proceed?`,
      acceptLabel: "Yes",
      rejectVisible: true,
      acceptVisible: true,
      rejectButtonStyleClass: "p-button-link",
      accept: () => {
        const body = this.formListComponent._selectedObjects.map((row) => row.id) || {};
        this.commitmentService._postRequest(this.webUrl + "RecurringGenOrders", { commitmentIds: body }).subscribe({
          next: (_response) => {},
          error: (_errorResponse) => {
            this.messageService.add(this.alertMessage.getErrorMessage(_errorResponse));
          },
          complete: () => {
            this.clearSearch();
          },
        });
      },
      reject: () => {},
    });
  }

  searchOrders(): void {
    const searchQuery = this.formGroup.value;
    const queryObject = {};

    if (searchQuery.dateFrom || searchQuery.dateTo) {
      const storeTimeZone = this.appSettingsService.getStore().storeTimeZone;

      if (!searchQuery.dateFrom) {
        searchQuery.dateFrom = "1800-01-01";
      }
      const storeDateFrom = moment(searchQuery.dateFrom).tz(storeTimeZone).format(CommitmentsComponent.DATE_FORMAT);

      if (!searchQuery.dateTo) {
        searchQuery.dateTo = new Date();
      }
      const storeDateTo = moment(searchQuery.dateTo).tz(storeTimeZone).format(CommitmentsComponent.DATE_FORMAT);

      queryObject["creationDate"] = { value: [storeDateFrom, storeDateTo], matchMode: "between" };
    }

    // if (searchQuery.pickupDateFrom || searchQuery.pickupDateTo) {
    //   if (!searchQuery.pickupDateFrom) {
    //     searchQuery.pickupDateFrom = '1800-01-01';
    //   }

    //   if (!searchQuery.pickupDateTo) {
    //     const newDateTo = moment().add(100, 'years').toDate();
    //     searchQuery.pickupDateTo = formatDate(newDateTo, CommitmentsComponent.DATE_FORMAT, this.defaultLocale);
    //   }

    //   queryObject['nextPickupDate'] = { value: [searchQuery.pickupDateFrom, searchQuery.pickupDateTo], matchMode: 'between' };
    // }
    if (searchQuery.customer) {
      queryObject["accountId"] = { value: searchQuery.customer, matchMode: "contains" };
    }
    if (searchQuery.saleChannelId !== -1) {
      queryObject["saleChannelId"] = { value: searchQuery.saleChannelId, matchMode: "equals" };
    }
    if (searchQuery.orderStatus) {
      queryObject["orderStatus"] = { value: searchQuery.orderStatus, matchMode: "equals" };
    }
    if (searchQuery.paymentStatus) {
      queryObject["state"] = { value: searchQuery.paymentStatus, matchMode: "equals" };
    }
    if (searchQuery.fulfillmentStore) {
      queryObject["storeId"] = { value: searchQuery.fulfillmentStore, matchMode: "equals" };
    }
    if (searchQuery.deliveryMethod) {
      queryObject["deliveryMethod"] = { value: searchQuery.deliveryMethod, matchMode: "equals" };
    }

    this.formFilter = queryObject;
  }

  getPrintItems(rowData: Commitments): MenuItem[] {
    return [
      {
        label: ReceiptPrintingSize.TAPE_SIZE,
        command: () => {
          // console.log(rowData);
          this.printPackingList(rowData, ReceiptPrintingSize.TAPE_SIZE);
        },
        icon: "takuicon-measuring-tape",
      },
      {
        label: ReceiptPrintingSize.LETTER_SIZE,
        command: () => {
          // console.log(rowData);
          this.printPackingList(rowData, ReceiptPrintingSize.LETTER_SIZE);
        },
        icon: "takuicon-invoice",
      },
    ];
  }

  onSearchResAccount(account: SearchResultItem) {
    this.formGroup.patchValue({
      customer: account.ID,
    });

    this.activeAccountName = account.headline;
  }

  openNewModelInDialog() {}

  clearSelectedAccount() {
    this.formGroup.get("customer").setValue(null);
    this.activeAccountName = null;
  }

  getPaymentItems(rowData: Commitments): MenuItem[] {
    return [
      {
        label: '<span class="material-icons">point_of_sale</span>  Open Register',
        command: () => {
          this.printPackingList(rowData, ReceiptPrintingSize.TAPE_SIZE);
        },
        escape: false,
      },
      {
        label: '<span class="material-icons">attach_money</span> Capture Payment',
        command: () => {
          this.printPackingList(rowData, ReceiptPrintingSize.LETTER_SIZE);
        },
        escape: false,
      },
    ];
  }

  onClosePressed() {}

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
