<div [ngClass]="{ grid: !currencyIsReadonly }" class="taku-money-topWrapper">
  <div *ngIf="fieldCaption && !currencyIsReadonly" class="col-12">
    <h5>{{ fieldCaption }}</h5>
  </div>

  <div *ngIf="!currencyIsReadonly" class="col-12 currency-field" [ngClass]="{ 'drop-container': !hasNoInputCaption }">
    <taku-currency
      [formGroup]="formGroup"
      [fieldName]="currencyFieldName"
      [fieldCaption]="currencyFieldCaption"
      [disabled]="disabled"
      [appendTo]="appendTo"
    ></taku-currency>
  </div>

  <div [ngClass]="{ 'col-12': !currencyIsReadonly }" class="amount-field-topWrapper">
    <div class="p-inputgroup amount-field">
      <span
        class="p-inputgroup-addon"
        style="{{ fieldCaption ? 'margin-top:0.36667em;' : '' }}{{ disabled ? 'opacity: 0.38' : '' }}"
        >{{ currencySymbol }}</span
      >
      <taku-input
        #inputAmount
        [formGroup]="formGroup"
        keyFilter="money"
        [fieldName]="fieldName"
        (onBlur)="onBlur.emit($event)"
        [fieldCaption]="fieldCaption"
        [disabled]="disabled"
        [autoSelect]="autoSelect"
      ></taku-input>
    </div>
  </div>
</div>
