import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { TakuCheckboxComponent } from "./taku-checkbox.component";
import { CheckboxModule } from "primeng/checkbox";

@NgModule({
  declarations: [TakuCheckboxComponent],
  exports: [TakuCheckboxComponent],
  imports: [CommonModule, ReactiveFormsModule, CheckboxModule],
})
export class TakuCheckboxModule {}
