<div id="data-progressbar">
  <p-progressBar mode="indeterminate" value="0" [style.visibility]="_isFetchingData ? 'visible' : 'hidden'">
  </p-progressBar>
</div>

<div class="col-12">
  <taku-form-list
    *ngIf="_isDataReady && _formListCols"
    [_model]="_model"
    [_modelService]="saleChannelInventoryService"
    [_dataService]="saleChannelInventoryService"
    _title="Sales Channel Inventory"
    _detailMode="w"
    [_cols]="_formListCols"
    [_modelValidation]="_formListRules"
    [_hideAddHeaderBtn]="true"
    [_hideClone]="true"
    [_hideDeleteHeaderBtn]="true"
    [_hideAddLineHeaderBtn]="true"
    _scrollHeight="calc(100vh - 26.5rem)"
    [_extraQueryParams]="_extraQueryParams"
    [_bulkEditorEnabled]="true"
    [_stateKey]="stateKey"
    [_filter]="_formListFilter"
    [_detailQueryParams]="{ defautTab: 'Details' }"
    [beforeSaveAll]="beforeSaveAll"
    [_onCustomizeFetchFilter]="_changeFormListFilter"
  >
    <!-- <div takuFormListHeaderCustomBtns>
      <button pButton pRipple type="button" icon="_" label="Submit Selected Rows" (click)="openSubmitSelectionDialog()">
        <i class="material-icons">cloud_upload</i>
      </button>
    </div> -->
  </taku-form-list>
</div>
