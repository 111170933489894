/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";

const countryData = [
  {
    alpha2: "CA",
    alpha3: "CAN",
    countryCallingCodes: ["+1"],
    currencies: ["CAD"],
    languages: ["eng", "fra"],
    name: "Canada",
  },
  {
    alpha2: "US",
    alpha3: "USA",
    countryCallingCodes: ["+1"],
    currencies: ["USD"],
    languages: ["eng"],
    name: "United States",
  },
];

@Injectable({
  providedIn: "root",
})
export class CountryDataService {
  dataByIsoCode(countryIsoCode) {
    return this.getCountryData("alpha2", countryIsoCode);
  }

  dataByCountryName(countryName) {
    return this.getCountryData("name", countryName);
  }

  private getCountryData(key, value) {
    return countryData.find((item) => item[key] === value);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
