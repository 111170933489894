<taku-form-header
  [shortenHeight]="shortenFormHeaderHeight"
  toolbarTitle="Revisit"
  displayMode="inline"
  [showActionBtns]="false"
  [showBackBtn]="false"
>
  <button
    class="p-button-rounded p-button-icon-only"
    *ngIf="enableFullScreen"
    pButton
    icon="_"
    (click)="onFullScreenPressed()"
  >
    <i class="material-icons">open_in_new</i>
  </button>
  <button
    style="margin-left: 5px"
    pButton
    label="Open Cash Drawer"
    class="p-button-primary-dark"
    (click)="openCashDrawer()"
  ></button>
</taku-form-header>
<div class="main-container">
  <!-- <footer> -->
  <!-- <footer class="col-12" [ngClass]="{'full-size': fullSizeMode}">
    <div class="grid">
      <div class=" col-8 p-fluid" [ngClass]="{'lg:col-10 xl:col-10 md:col-10': fullSizeMode}">
        <taku-input class="field-emailAddress" [formGroup]="headerFormGroup" fieldName="emailAddress"
          fieldCaption="Email address"></taku-input>
      </div>
      <div class="col-2 p-fluid" [ngClass]="{'lg:col-1 xl:col-1 md:col-1': fullSizeMode}">
        <button pButton (click)="menuEmailReceiptSizes.toggle($event)"
          class="btn-action email-btn p-button-secondary p-button-rounded" icon="pi pi-envelope"
          [disabled]="headerFormGroup.get('emailAddress').invalid || !headerFormGroup.get('emailAddress').value || !_hasSelectedDocs"></button>
      </div>

      <div class="col-2 p-fluid" [ngClass]="{'lg:col-1 xl:col-1 md:col-1': fullSizeMode}">
        <button pButton [disabled]="!printMgr.isReady || !_hasSelectedDocs"
          class="btn-action reprint-btn p-button-secondary p-button-rounded" icon="pi pi-print"
          (click)="menuPrintSizes.toggle($event)"></button>
      </div>
    </div>
  </footer> -->
  <!-- </footer> -->

  <!-- <p-menu #menuPrintSizes [popup]="true" [model]="printSizesMenuItems" appendTo="body"></p-menu> -->
  <p-menu #menuEmailReceiptSizes [popup]="true" [model]="emailReceiptSizesMenuItems" appendTo="body"></p-menu>

  <div id="revisit-search-box" [formGroup]="formGroup" class="col-12" [ngClass]="{ 'full-size': fullSizeMode }">
    <div class="grid revisit-fields-container" [ngClass]="{ inRightSideOfSaleDoc: inRightSideOfSaleDoc }">
      <div class="col-12 md:col-12 revisit-search-row" [ngClass]="{ 'lg:col-4 xl:col-4': fullSizeMode }">
        <div class="grid">
          <div class="col-6 md:col-6 field-datefrom" [ngClass]="{ 'lg:col-6': fullSizeMode }">
            <!-- <div class="field-datefrom" [ngClass]="isInDoubleLineRange() ? 'col-12' : 'col-6'"> -->
            <taku-calendar-date
              [disabled]="formGroup.get('docNo').value"
              [formGroup]="formGroup"
              fieldName="dateFrom"
              fieldCaption="Date From"
              appendTo="body"
            ></taku-calendar-date>
          </div>
          <div class="col-6 md:col-6 field-dateto" [ngClass]="{ 'lg:col-6': fullSizeMode }">
            <!-- <div class="field-dateto" [ngClass]="isInDoubleLineRange() ? 'col-12' : 'col-6'"> -->
            <taku-calendar-date
              [disabled]="formGroup.get('docNo').value"
              [formGroup]="formGroup"
              fieldName="dateTo"
              fieldCaption="Date To"
              appendTo="body"
            ></taku-calendar-date>
          </div>
        </div>
      </div>

      <div class="col-12 first-block revisit-search-row" [ngClass]="{ 'lg:col-8 xl:col-8': fullSizeMode }">
        <div class="grid">
          <div class="col-12 md:col-6 sm:col-6 field-docNo" [ngClass]="{ 'doc-fullsize lg:col-6': fullSizeMode }">
            <!-- <div class="field-docNo" [ngClass]="isInDoubleLineRange() ? 'col-12' : 'col-6'"> -->
            <taku-input
              (keyup)="onKeyPressedDocNo($event)"
              [formGroup]="formGroup"
              fieldName="docNo"
              class="docNum-input-field"
              fieldCaption="Doc #(e.g. 00590)"
              [leftIconClass]="'material-icons'"
              [leftIconName]="'receipt'"
              [rightIconClass]="'pi pi-search'"
            >
            </taku-input>
          </div>

          <div
            class="col-12 md:col-6 sm:col-6 field-account"
            [ngClass]="{ disabled: formGroup.get('docNo').value, 'field-account-fullsize lg:col-6': fullSizeMode }"
          >
            <!-- <div class="col-6 field-account"
            [ngClass]="{'disabled': formGroup.get('docNo').value, 'col-12' : isInDoubleLineRange()}"> -->
            <div class="p-inputgroup inputgroup-outlined">
              <span class="p-inputgroup-addon"><i class="material-icons">group</i></span>
              <taku-search-accounts
                id="account_search"
                #searchAccountsComp
                [disabled]="formGroup.get('docNo').value"
                placeholder="Account"
                [autoClearOnSelection]="false"
                [includeHeader]="false"
                [includeCreateNewBtns]="false"
                (itemSelected)="onSearchResAccount($event)"
                (onNewItemClicked)="openNewModelInDialog()"
                (onSelectedItemCleared)="clearSelectedAccount()"
              >
              </taku-search-accounts>
              <!-- <taku-input [formGroup]="formGroup" fieldName="account" fieldCaption="Account"></taku-input> -->
            </div>
          </div>
        </div>
      </div>

      <div
        class="col-12 md:col-12 search-box-btns revisit-search-row"
        [ngClass]="{ 'lg:col-12 xl:col-12': fullSizeMode, notInRightSideOfSaleDoc: !inRightSideOfSaleDoc }"
      >
        <div class="grid">
          <div class="col-6 lg:col-6 md:col-6 p-fluid xl:col-3" [ngClass]="{ 'lg:col-6 xl:col-3': fullSizeMode }">
            <taku-dropdown
              [formGroup]="formGroup"
              [options]="lookup_stores$ | async"
              fieldCaption="Store"
              fieldName="storeId"
            ></taku-dropdown>
          </div>
          <div
            class="col-6 lg:col-6 md:col-6 p-fluid xl:col-3 doc-type-dropdown-container"
            [ngClass]="{ 'lg:col-6 xl:col-3': fullSizeMode }"
          >
            <taku-dropdown
              [formGroup]="formGroup"
              [options]="docTypeOptions"
              fieldCaption="Doc Type"
              fieldName="docType"
            ></taku-dropdown>
          </div>
          <div
            class="col-6 lg:col-6 md:col-6 p-fluid xl:col-3 mt-2"
            [ngClass]="{ 'md:col-2 lg:col-2 xl:col-3': fullSizeMode }"
          >
            <button pButton label="Clear" class="p-button-secondary" (click)="clearSearch()"
              ><span class="hidden-icons p-inputgroup-addon"><i class="material-icons">clear</i></span></button
            >
          </div>
          <div
            class="col-6 lg:col-6 md:col-6 p-fluid xl:col-3 mt-2"
            [ngClass]="{ 'md:col-2 lg:col-2 xl:col-3': fullSizeMode }"
          >
            <button pButton label="Search" (click)="searchDocuments()"
              ><span class="hidden-icons p-inputgroup-addon"><i class="material-icons">search</i></span></button
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="showFormList" class="col-12 formlistWrapper">
    <taku-form-list
      #formList
      *ngIf="_isDataReady"
      [_filter]="formFilter ? formFilter : undefined"
      [_model]="'saleDoc'"
      _dialogStyleClass="fullscreen-mobilemodal"
      [_dialogShowHeader]="false"
      [_hideSavingCtrls]="true"
      [_showCtrlsInEditMode]="false"
      [_hideAddLineHeaderBtn]="true"
      [_hideOptionsHeaderBtn]="false"
      [_hideGlobalFilter]="true"
      [_hideAddHeaderBtn]="true"
      [_hideDeleteHeaderBtn]="true"
      [_title]="'Sale Documents'"
      [_rowSelectionType]="RowSelectionType.NONE"
      _detailMode="n"
      _scrollHeight="calc(100vh - 30.5rem)"
      [_extraQueryParams]="_revisitSaleDocExtraParams"
      [_cols]="saleDocColumns"
      _viewColIcon="_"
      _stateKey="revisitSale"
      [_hideFilterControls]="true"
      (_onRowSelect)="onDocSelected($event)"
      (_onRowUnselect)="onDocUnselected($event)"
      [_dialogDismissable]="true"
      [defaultSortField]="'doc.docClosingDate'"
      [defaultSortOrder]="-1"
    >
    </taku-form-list>
  </div>
</div>
<div [hidden]="true">
  <ng-template #printPreviewHost></ng-template>
</div>
