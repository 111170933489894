/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

// Salesscreen enums
export enum SideDialogNameDesktop {
  SEARCH_RESULTS = "search",
  GALLERY = "gallery",
  INVENTORY_DETAILS = "inventoryDetails",
  TENDER_SCREEN = "tenderScreen",
  RETURN_ITEM_NOINVOICE = "returnItemNoInvoice",
  RETURN_ITEM_SEARCH_FORM = "returnItemSearchForm",
  RETURN_ITEM_SEARCH_RESULTS = "returnItemSearchResults",
  SALE_DOC_LIST = "saleDocLists",
}

enum MobileOnlyDialogs {
  INVOICE_BILLTO = "InvoiceBillTo",
  INVOICE_CRM = "InvoiceCRM",
  PARKED_INVOICES = "ParkedInvoices",
  REVISIT_INVOICES = "RevisitInvoices",
  VOUCHER_BALANCE = "VoucherBalance",
}

export type SideDialogNameMobile = SideDialogNameDesktop | MobileOnlyDialogs;
export const SideDialogNameMobile = { ...SideDialogNameDesktop, ...MobileOnlyDialogs };

export type SalesSideDialogName = SideDialogNameDesktop | SideDialogNameMobile;
export const SalesSideDialogName = { ...SideDialogNameDesktop, ...SideDialogNameMobile };

export enum InvoiceTabsIndexes {
  RevisitInvoices,
  ParkedInvoices,
  InvoiceCRM,
  InvoiceBillTo,
}

// Return Screen enums
export enum ReturnsSideDialogNameDesktop {
  SEARCH_RESULTS = "search",
  INVENTORY_DETAILS = "inventoryDetails",
  TENDER_SCREEN = "tenderScreen",
  RETURN_ITEM_NOINVOICE = "returnItemNoInvoice",
  RETURN_ITEM_SEARCH_RESULTS = "returnItemSearchResults",
}

enum MobileOnlyDialogs {
  RETURN_ITEM_SEARCH_FORM = "returnItemSearchForm",
}

export type ReturnsSideDialogNameMobile = ReturnsSideDialogNameDesktop | MobileOnlyDialogs;
export const ReturnsSideDialogNameMobile = { ...ReturnsSideDialogNameDesktop, ...MobileOnlyDialogs };

export type ReturnsSideDialogName = ReturnsSideDialogNameDesktop | ReturnsSideDialogNameMobile;
export const ReturnsSideDialogName = { ...ReturnsSideDialogNameDesktop, ...ReturnsSideDialogNameMobile };

// Union/Base types
export type InvoiceSideDialogName = SalesSideDialogName | ReturnsSideDialogName;
export const InvoiceSideDialogName = { ...SalesSideDialogName, ...ReturnsSideDialogName };

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
