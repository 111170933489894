/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/shared/services/auth.service";
import { SettingCardInfo } from "../settings-cards/setting-cards.component";

@Component({
  selector: "taku-business-settings",
  templateUrl: "business-settings.component.html",
  styleUrls: ["business-settings.component.scss"],
})
export class BusinessSettingsComponent implements OnInit {
  pageTitle = "Business Settings";
  pageIconStyleClass = "ui-icon-business";

  cardsInfo: SettingCardInfo[] = [];

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.initializeCards();
  }

  initializeCards() {
    this.cardsInfo = [
      {
        routePath: ["/settings/business-settings/business-details"],
        iconStyleClass: "material-icons",
        iconName: "business",
        cardTitle: "Business Details",
        cardDesc: ["Business Name, Logo", "Operational Currency", "Contact Information"],
        isVisible: this.authService.hasVisiblePermit("Settings_Menu_Business_Settings_Business_Details"),
        isEnabled: this.authService.hasEnablePermit("Settings_Menu_Business_Settings_Business_Details"),
        id: "business-details",
      },
      {
        routePath: ["/settings/business-settings/financial-settings/n/fiscalYear"],
        routeQueryParams: { title: "Manage Fiscal Years" },
        iconStyleClass: "material-icons",
        iconName: "attach_money",
        cardTitle: "Financial Settings",
        cardDesc: ["Set Fiscal Period"],
        isVisible: this.authService.hasVisiblePermit("Settings_Menu_Business_Settings_Financial"),
        isEnabled: this.authService.hasEnablePermit("Settings_Menu_Business_Settings_Financial"),
        id: "financial_settings",
      },
      // {
      //   routePath: ["/business-settings/manage-zones/w/zone"],
      //   routeQueryParams: {title: "Zones"},
      //   iconStyleClass: "takuicon-create-zone-settings",
      //   cardTitle: "Create Zones",
      //   cardDesc: [
      //     "Description Line 1",
      //     "Description Line 2",
      //     "Settings long description",
      //     "Last description line 4"
      //   ]
      // },
      {
        routePath: ["/settings/taxes"],
        routeQueryParams: { title: "Sales Taxes" },
        iconStyleClass: "takuicon-taxes-settings",
        cardTitle: "Taxes",
        cardDesc: ["Set Sales Taxes", "Set Tax Rules", "Set Tax Categories"],
        isVisible: this.authService.hasVisiblePermit("Settings_Settings_Menu_Business_Settings_Taxes"),
        isEnabled: this.authService.hasEnablePermit("Settings_Settings_Menu_Business_Settings_Taxes"),
        id: "taxes",
      },
      {
        routePath: ["/settings/business-settings/business-formats"],
        iconStyleClass: "material-icons",
        iconName: "text_fields",
        cardTitle: "Business Formats",
        cardDesc: ["Set Field Formats", "(e.g. SKU, Account Number)"],
        isVisible: this.authService.hasVisiblePermit("Settings_Menu_Business_Settings_Business_Formats"),
        isEnabled: this.authService.hasEnablePermit("Settings_Menu_Business_Settings_Business_Formats"),
        id: "business_formats",
      },
      {
        routePath: ["/settings/business-settings/u-of-m/d/uOfMeasure"],
        routeQueryParams: { title: "Units of Measurement" },
        iconStyleClass: "material-icons",
        iconName: "straighten",
        cardTitle: "Unit of Measure",
        cardDesc: ["Set Up Units of Measure", "(e.g. Dozen, Feet...)"],
        isVisible: this.authService.hasVisiblePermit("Settings_Menu_Business_Settings_Unit_of_Measure"),
        isEnabled: this.authService.hasEnablePermit("Settings_Menu_Business_Settings_Unit_of_Measure"),
        id: "unit_of_measure",
      },
      {
        routePath: ["/settings/business-settings/inventory-cats/d/category"],
        routeQueryParams: { title: "Inventory Categories" },
        iconStyleClass: "takuicon-categories-settings",
        cardTitle: "Inventory Categories",
        cardDesc: ["Set Up Inventory Categories", "(e.g. Drinks, Home Decor...)"],
        isVisible: this.authService.hasVisiblePermit("Settings_Menu_Business_Settings_Inventory_Categories"),
        isEnabled: this.authService.hasEnablePermit("Settings_Menu_Business_Settings_Inventory_Categories"),
        id: "inventory_actegories",
      },
      {
        routePath: ["/settings/business-settings/account-groups/d/acctGroup"],
        routeQueryParams: { title: "Account Groups" },
        iconStyleClass: "takuicon-categories-settings",
        cardTitle: "Account Groups",
        cardDesc: ["Set Up Account Groups", "(e.g. VIP, Seniors...)"],
        isVisible: this.authService.hasVisiblePermit("Settings_Menu_Business_Settings_Account_Groups"),
        isEnabled: this.authService.hasEnablePermit("Settings_Menu_Business_Settings_Account_Groups"),
        id: "account_groups",
      },
      {
        routePath: ["/settings/business-settings/brands/d/brand"],
        routeQueryParams: { title: "Brands" },
        iconStyleClass: "takuicon-brands",
        cardTitle: "Brands",
        cardDesc: ["Set Up Brands", "(e.g. Levi's, Nike...)"],
        isVisible: this.authService.hasVisiblePermit("Settings_Menu_Business_Settings_Brands"),
        isEnabled: this.authService.hasEnablePermit("Settings_Menu_Business_Settings_Brands"),
        id: "brands",
      },
      {
        routePath: ["/settings/business-settings/inventoryOptionsSettings/n/inventoryOptionsSetting"],
        routeQueryParams: { title: "Inventory Options Settings" },
        iconStyleClass: "material-icons-outlined",
        iconName: "post_add",
        cardTitle: "Inventory Options Settings",
        cardDesc: ["Set Up Options", "(e.g. Size, Style...)"],
        isVisible: this.authService.hasVisiblePermit("Settings_Menu_Business_Settings_Inventory_Options_Settings"),
        isEnabled: this.authService.hasEnablePermit("Settings_Menu_Business_Settings_Inventory_Options_Settings"),
        id: "inventoryOptionsSettings",
      },
      {
        routePath: ["/settings/purchase-order-terms"],
        routeQueryParams: { title: "Payment Terms" },
        iconStyleClass: "material-icons",
        iconName: "list_alt",
        cardTitle: "Order Terms",
        cardDesc: ["Payment Terms", "Shipping Terms"],
        isVisible: this.authService.hasVisiblePermit("Settings_Settings_Menu_Business_Settings_Order_Terms"),
        isEnabled: this.authService.hasEnablePermit("Settings_Settings_Menu_Business_Settings_Order_Terms"),
        id: "order_terms",
      },
    ];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
