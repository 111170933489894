/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { DecimalPipe } from "@angular/common";
import { Component } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { GenericFormComponent, GenericFormService } from "../../../../forms/generic-form/generic-form.component";
import { BusinessFormatsSettings } from "./BusinessFormatsSettings";
import { ModelFormGroup } from "src/app/utility/ModelFormGroup";

type BusinessFormatRow = {
  fieldType: string;
  prefixFieldCaption: string;
  prefixFieldName: string;
  suffixFieldName: string;
  noOfDigitsFieldName: string;
  startingNumberFieldName: string;
};

@Component({
  selector: "taku-business-formats-settings",
  templateUrl: "./business-formats-settings.component.html",
  styleUrls: ["./business-formats-settings.component.scss"],
  providers: [GenericFormService],
})
export class BusinessFormatsSettingsComponent extends GenericFormComponent<
  BusinessFormatsSettings,
  ModelFormGroup<BusinessFormatsSettings>
> {
  decimalPipe: DecimalPipe;
  readonly businessFormatsRows: BusinessFormatRow[] = [
    {
      fieldType: "SKU",
      prefixFieldCaption: "SKU",
      prefixFieldName: "skuPrefix",
      noOfDigitsFieldName: "skuNoOfDigits",
      startingNumberFieldName: "skuStartingNumber",
      suffixFieldName: "skuSuffix",
    },
    {
      fieldType: "Account",
      prefixFieldCaption: "Account",
      prefixFieldName: "accountPrefix",
      noOfDigitsFieldName: "accountNoOfDigits",
      startingNumberFieldName: "accountStartingNumber",
      suffixFieldName: "accountSuffix",
    },
    {
      fieldType: "Voucher",
      prefixFieldCaption: "Voucher",
      prefixFieldName: "voucherPrefix",
      noOfDigitsFieldName: "voucherNoOfDigits",
      startingNumberFieldName: "voucherStartingNumber",
      suffixFieldName: "voucherSuffix",
    },
  ];

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    const businessFormat_group = fb.group(new BusinessFormatsSettings());
    return businessFormat_group;
  }

  initForm(): void {
    this._model = "businessFormat";
    this._object = new BusinessFormatsSettings();
    this._myForm = BusinessFormatsSettingsComponent.init(this.fb);
  }

  loadObject(): void {
    this.subsList.push(
      this.dbService.getRow<BusinessFormatsSettings>(this._model, 1).subscribe(
        (myObject) => {
          this._id = 1;
          this._object = myObject;
          // Set this manually since it's not persisted to the backend
          this._object.voucherStartingNumber = 1;
          super.loadObject();
        },
        (error) => {
          this._id = 0;
          super.loadObject();
        }
      )
    );
  }

  protected override resetForm(): void {
    this._object.voucherStartingNumber = 1;
    super.resetForm();
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
