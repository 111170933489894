<p-dialog
  [(visible)]="isVisible"
  appendTo="body"
  [modal]="_modal"
  [showHeader]="false"
  styleClass="dialog-numPad"
  (onShow)="onDialogOpened()"
  (onHide)="onDialogClosed()"
  [breakpoints]="{ '991px': '50vw', '640px': '100vw' }"
>
  <button
    pButton
    pRipple
    type="button"
    class="p-button-rounded numpad-x-button"
    icon="pi pi-times"
    (click)="cancelDialog()"
  ></button>
  <div id="numpadDialogWrapper-inner">
    <!-- Used for TakuPay card inputs and store credit input -->
    <ng-content></ng-content>

    <div #numPadDialog tabindex="0" id="numeric-pad" [ngClass]="{ hidden: numpadHidden }">
      <h3>
        <span class="pad-title"
          >{{ padTitle }} <ng-template [ngIf]="prefixQty">({{ prefixQty }})</ng-template></span
        ><br />
        <span *ngIf="tenderDescription" class="tender-desc">{{ tenderDescription }} </span>
      </h3>

      <input
        #inputDisplay
        [disabled]="true"
        pKeyFilter="money"
        type="text"
        class="big-input"
        [ngModel]="qtyValue"
        (ngModelChange)="updateQtyValue($event)"
        (focus)="onInputFocused($event)"
      />

      <div class="btns-outerWrapper">
        <div class="btns-container" (click)="inputDisplay.focus()">
          <button
            pButton
            type="button"
            class="p-button-rounded p-button-secondary"
            [disabled]="numpadDisabled"
            label="7"
            (click)="onCharacterBtnPressed($event)"
          ></button>
          <button
            pButton
            type="button"
            class="p-button-rounded p-button-secondary"
            [disabled]="numpadDisabled"
            label="8"
            (click)="onCharacterBtnPressed($event)"
          ></button>
          <button
            pButton
            type="button"
            class="p-button-rounded p-button-secondary"
            [disabled]="numpadDisabled"
            label="9"
            (click)="onCharacterBtnPressed($event)"
          ></button>
          <ng-template [ngIf]="buttonsArray[0]">
            <button
              pButton
              type="button"
              class="p-button-rounded p-button-secondary btn-money"
              [disabled]="numpadDisabled"
              [label]="prefixAmounts + buttonsArray[0]"
              (click)="insertAmount(buttonsArray[0])"
            ></button>
          </ng-template>
          <button
            pButton
            type="button"
            class="p-button-rounded p-button-secondary"
            [disabled]="numpadDisabled"
            label="4"
            (click)="onCharacterBtnPressed($event)"
          ></button>
          <button
            pButton
            type="button"
            class="p-button-rounded p-button-secondary"
            [disabled]="numpadDisabled"
            label="5"
            (click)="onCharacterBtnPressed($event)"
          ></button>
          <button
            pButton
            type="button"
            class="p-button-rounded p-button-secondary"
            [disabled]="numpadDisabled"
            label="6"
            (click)="onCharacterBtnPressed($event)"
          ></button>
          <ng-template [ngIf]="buttonsArray[1]">
            <button
              pButton
              type="button"
              class="p-button-rounded p-button-secondary btn-money"
              [disabled]="numpadDisabled"
              [label]="prefixAmounts + buttonsArray[1]"
              (click)="insertAmount(buttonsArray[1])"
            ></button>
          </ng-template>
          <button
            pButton
            type="button"
            class="p-button-rounded p-button-secondary"
            [disabled]="numpadDisabled"
            label="1"
            (click)="onCharacterBtnPressed($event)"
          ></button>
          <button
            pButton
            type="button"
            class="p-button-rounded p-button-secondary"
            [disabled]="numpadDisabled"
            label="2"
            (click)="onCharacterBtnPressed($event)"
          ></button>
          <button
            pButton
            type="button"
            class="p-button-rounded p-button-secondary"
            [disabled]="numpadDisabled"
            label="3"
            (click)="onCharacterBtnPressed($event)"
          ></button>
          <ng-template [ngIf]="buttonsArray[2]">
            <button
              pButton
              type="button"
              class="p-button-rounded p-button-secondary btn-money"
              [disabled]="numpadDisabled"
              [label]="prefixAmounts + buttonsArray[2]"
              (click)="insertAmount(buttonsArray[2])"
            ></button>
          </ng-template>
          <button
            pButton
            type="button"
            class="p-button-rounded p-button-secondary"
            [disabled]="numpadDisabled"
            label="."
            (click)="onCharacterBtnPressed($event)"
          ></button>
          <button
            pButton
            type="button"
            class="p-button-rounded p-button-secondary"
            [disabled]="numpadDisabled"
            label="0"
            (click)="onCharacterBtnPressed($event)"
          ></button>
          <button
            pButton
            type="button"
            class="p-button-rounded p-button-secondary"
            [disabled]="numpadDisabled"
            [label]="defaultQty === 0 ? 'C' : 'BAL'"
            (click)="resetInput()"
          ></button>
          <button
            pButton
            type="button"
            class="p-button-rounded p-button-secondary"
            [disabled]="numpadDisabled"
            (click)="clearCharacter()"
          >
            <i class="material-icons md-18">backspace</i>
          </button>

          <button
            pButton
            type="button"
            class="p-button-rounded p-button-warning cancel-btn"
            label="CANCEL"
            (click)="cancelDialog($event)"
          ></button>
          <button
            id="accept_btn"
            autofocus
            pButton
            type="button"
            class="p-button-rounded p-button-raised p-button-success accept-btn"
            label="ACCEPT"
            [disabled]="acceptButtonDisabled"
            (click)="submit()"
          ></button>
        </div>
      </div>
    </div>
    <div *ngIf="numpadHidden" class="flex justify-content-evenly alt-action-buttons">
      <button
        pButton
        type="button"
        class="p-button-rounded p-button-warning cancel-btn"
        label="CANCEL"
        (click)="cancelDialog($event)"
      ></button>
      <button
        autofocus
        pButton
        type="button"
        class="p-button-rounded p-button-raised p-button-success accept-btn"
        label="ACCEPT"
        [disabled]="acceptButtonDisabled"
        (click)="submit()"
      ></button>
    </div>
  </div>
</p-dialog>
