import {
  Component,
  OnInit,
  OnChanges,
  Input,
  ElementRef,
  SimpleChanges,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import {
  PurchaseDoc,
  PurchaseDocLineTax,
} from "src/app/core/document/purchase-document/purchase-document/purchase-document";
import { PurchaseDocLine } from "src/app/core/document/purchase-document/purchase-document/purchase-document";
import { Address, AddressType } from "../../../contact-accounts/address/address";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { BusinessDetail } from "src/app/core/settings/business-settings/business-detail/business-detail";
import { Store } from "src/app/core/settings/store-settings/store/store";
import { StorePoliciesSettings } from "../../../settings/store-settings/store-policies-settings/StorePoliciesSettings";
import { LocationService } from "src/app/shared/services/location.service";
import { DocState } from "src/app/core/document/doc/doc";
import * as _ from "lodash";
import * as JsBarcode from "jsbarcode";
import { ReplaySubject } from "rxjs";
import { MonetaryHelpers } from "src/app/utility/MonetaryHelpers";
import { TotalBySaleTax } from "src/app/core/document/sale-document/sale-doc-line/sale-doc-line";
import { DiscountType, SaleDoc } from "src/app/core/document/sale-document/sale-doc/sale-doc";
import { take } from "rxjs/operators";
import { Person } from "src/app/core/contact-accounts/person/person";

type PageInfo = {
  pageNumber: number;
  isFirstPage: boolean;
  isLastPage: boolean;
  purchaseDocLines: PurchaseDocLine[];
};

class PrintPaginator {
  static readonly LINES_PER_FULLPAGE = 10;
  static readonly LINES_BILLING_SHIPPING = 2;
  static readonly LINES_FOOTER = 4;

  readonly totalPages: number;

  constructor(private purchaseDoc: PurchaseDoc) {
    this.totalPages = Math.ceil(
      purchaseDoc.purchaseDocLines.filter((line) => line.qty !== 0).length / PrintPaginator.LINES_PER_FULLPAGE
    );
  }

  getPagesList(): PageInfo[] {
    const pagesInfo: PageInfo[] = [];

    for (let pageNo = 1; pageNo <= this.totalPages; pageNo++) {
      const linesPerCurrPage = PrintPaginator.LINES_PER_FULLPAGE;
      const isFirstPage = pageNo == 1;
      const isLastPage = pageNo == this.totalPages;

      //if (isFirstPage) linesPerCurrPage -= PrintPaginator.LINES_BILLING_SHIPPING;
      //if (isLastPage) linesPerCurrPage -= PrintPaginator.LINES_FOOTER;

      const pageDocLines = this.purchaseDoc.purchaseDocLines
        .filter((line) => line.qty !== 0)
        .slice(linesPerCurrPage * (pageNo - 1), linesPerCurrPage * pageNo);

      pagesInfo.push({
        pageNumber: pageNo,
        isFirstPage,
        isLastPage,
        purchaseDocLines: pageDocLines,
      });
    }

    return pagesInfo;
  }
}

@Component({
  selector: "taku-purchase-order-full-preview",
  templateUrl: "./purchase-order-full-preview.component.html",
  styleUrls: ["../../../../../assets/layout/taku/purchase-order-full-preview.component.css"],
})
export class PurchaseOrderFullPreviewComponent implements OnChanges, OnInit, AfterViewInit {
  @Input() purchaseDoc: PurchaseDoc;
  @Input() policies: StorePoliciesSettings;
  @Input() storeInfo: Store;
  @Input() businessDetail: BusinessDetail;
  @Input("saleDocData") saledoc: SaleDoc;
  @ViewChild("returnsBarcode") returnsBarcodeRef: ElementRef;

  private _paginator: PrintPaginator;
  readonly STYLESHEET_PATH = "/assets/layout/taku/purchase-order-full-preview.component.css";

  private _dataReadySubject = new ReplaySubject<boolean>(1);
  onDataReady$ = this._dataReadySubject.asObservable();
  businessName: string;

  hideNotReceiptWatermark = true;
  Math: Math = Math;
  pagesInfo: PageInfo[];
  totalPages: number;
  _fullAddressMap = "";
  _digitsFormat = "1.2-2";
  _currencyIsoCode = null;

  constructor(
    public elementRef: ElementRef,
    private appSettingsService: AppSettingsStorageService,
    public lService: LocationService,
    private appSettings: AppSettingsStorageService
  ) {}

  ngOnInit(): void {
    this.onPurchaseDocChanged();

    this.appSettings
      .getBusinessDetails()
      .pipe(take(1))
      .subscribe((businessDetails) => (this.businessName = businessDetails.businessName));

    this._fullAddressMap = [
      this.storeInfo?.address.line1,
      this.storeInfo?.address.line2,
      "".concat(
        this.storeInfo?.address.city,
        ", " +
          this.lService.lookupSubdivisionName(
            this.storeInfo?.address.countryIsoCode,
            this.storeInfo?.address.subDivisionIsoCode
          ) +
          ", " +
          this.lService.lookupCountryByIsoCode(this.storeInfo?.address.countryIsoCode) +
          " , ",
        this.storeInfo?.address.postalCode
      ),
    ]
      .filter(Boolean)
      .join(" ");

    this.hideNotReceiptWatermark =
      this.purchaseDoc.doc.state !== DocState.voided &&
      this.purchaseDoc.doc.state !== DocState.draft &&
      this.purchaseDoc.doc.state !== DocState.suspended;
  }

  ngAfterViewInit(): void {
    if (this.returnsBarcodeRef) {
      JsBarcode(this.returnsBarcodeRef.nativeElement, this.purchaseDoc.doc.docNo, {
        format: "CODE128",
        height: 120,
        margin: 0,
        fontSize: 30,
      });
    }
    this._dataReadySubject.next();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._currencyIsoCode = this.purchaseDoc.doc.currencyIsoCode;

    if (changes["purchasedoc"] && !changes["purchasedoc"].firstChange) {
      this.onPurchaseDocChanged();
    }
  }

  get businessLogoUrl(): string {
    this.storeInfo = this.purchaseDoc.store || this.storeInfo || this.appSettingsService.getStore();
    return this.purchaseDoc?.backOffice?.logoUrl
      ? this.purchaseDoc?.backOffice?.logoUrl
      : this.storeInfo?.urlStoreLogoUrl || this.businessDetail?.urlBusinessDetailBusinessLogoUrl;
  }

  get billingAddress(): Address {
    if (this.purchaseDoc?.backOfficeId > 0) {
      return this.purchaseDoc?.backOffice?.address;
    } else if (this.purchaseDoc?.storeId > 0) {
      return this.purchaseDoc?.store?.address;
    }
  }

  get supplierAddress(): Address {
    if (this.hasAddressInfo(this.purchaseDoc?.shippingAddress)) {
      return this.purchaseDoc?.shippingAddress;
    } else {
      const commercialAccountAddress = this.purchaseDoc?.account?.commercialAccount?.commercialAccountAddresses.filter(
        (addresses) => addresses?.address?.addressType == AddressType.shippingAddress && addresses?.address.isDefault
      );

      if (commercialAccountAddress.length > 0) {
        return commercialAccountAddress[0].address;
      }
    }
  }

  get supplierContact(): Person {
    if (this.purchaseDoc?.shippingContact) {
      return this.purchaseDoc?.shippingContact;
    } else {
      if (this.purchaseDoc.account.commercialAccount.contacts.length > 0) {
        return this.purchaseDoc.account.commercialAccount.contacts[0].person;
      }
    }
  }

  getStylesheets(): string[] {
    return [this.STYLESHEET_PATH];
  }

  getHTMLContent(): any {
    return this.elementRef.nativeElement;
  }

  categoriesPrettyPrint(purchaseDocLine: PurchaseDocLine): string {
    if (!purchaseDocLine.docLine) return "";
    return purchaseDocLine.docLine.inventory.categories.map((category) => category.categoryName).join(", ");
  }

  hasAddressInfo(address: Address): string {
    return (
      address &&
      (address.line1 ||
        address.line2 ||
        address.city ||
        address.postalCode ||
        address.attnFullPersonName ||
        (address.addressPhone && address.addressPhone.tel) ||
        (address.addressEmail && address.addressEmail.email))
    );
  }

  onPurchaseDocChanged(): void {
    this._paginator = new PrintPaginator(this.purchaseDoc);
    this.totalPages = this._paginator.totalPages;
    this.pagesInfo = this._paginator.getPagesList();
  }

  subdivisionDisplay(subDivisionIsoCode: string): string | undefined {
    return subDivisionIsoCode ? subDivisionIsoCode.split("-").pop() : "";
  }

  getLineTaxAcronyms(line: PurchaseDocLine): string {
    return line.purchaseDocLineTaxes
      .filter((lineTax) => lineTax.taxRule)
      .map((lineTax) => lineTax.taxRule.taxAcronym)
      .join("");
  }

  getSupplierCode(line: PurchaseDocLine): string {
    const detail = line.docLine.inventory.inventorySuppliers[0].inventorySupplierProductDetails.find((row) => {
      return row.packSize === line.packSize;
    });
    return detail ? detail.supplierCode : undefined;
  }

  inventoryVariantsStr(purchaseDocLine: PurchaseDocLine): string {
    return purchaseDocLine.docLine.inventory.inventoryVariants
      ?.map(
        (_inventoryVariant) =>
          _inventoryVariant.inventoryOptionsSetting.optionName +
          ": " +
          _inventoryVariant.inventoryOptionsSettingValue?.optionValue
      )
      .join(", ");
  }

  groupedTaxLines(purchaseDoc: PurchaseDoc): TotalBySaleTax[] {
    const linesTaxes = _.flatten(
      purchaseDoc.purchaseDocLines.map((purchaseDocLine) => purchaseDocLine.purchaseDocLineTaxes)
    );
    const groupedTaxes = _.groupBy(linesTaxes, (lineTax: PurchaseDocLineTax) => lineTax.taxRuleId);
    const totalGrouped = _.map(groupedTaxes, (taxes: PurchaseDocLineTax[]) => {
      if (!taxes[0].taxRule)
        return {
          taxes: taxes,
          saleTaxRule: null,
          total: _.sumBy(taxes, (tax) => parseFloat(String(tax.amount))),
        };

      return {
        taxes: taxes,
        saleTaxRule: taxes[0].taxRule,
        total: _.sumBy(taxes, (tax) => parseFloat(String(tax.amount))),
      };
    });
    return totalGrouped;
  }

  totalItems(purchaseDoc: PurchaseDoc): number {
    let totalItems = 0;
    if (purchaseDoc.purchaseDocLines.length > 0) {
      totalItems = purchaseDoc.purchaseDocLines.map((line) => line.qty).reduce((total, qty) => total + qty);
    }
    return totalItems;
  }

  purchaseDocSubTotal(purchaseDoc: PurchaseDoc): number {
    let subTotal = 0;
    if (purchaseDoc.purchaseDocLines) {
      const docLines: PurchaseDocLine[] = purchaseDoc.purchaseDocLines;
      if (docLines)
        docLines.forEach(
          (purchaseDocLine) => (subTotal += parseFloat(purchaseDocLine.unitPrice.toString()) * purchaseDocLine.qty)
        );
    }
    return subTotal;
  }

  purchaseDocTotalTax(purchaseDoc: PurchaseDoc): number {
    let totalTaxes = 0;
    const docLines: PurchaseDocLine[] = purchaseDoc.purchaseDocLines;
    if (docLines)
      docLines.forEach(
        (purchaseDocLine) =>
          (totalTaxes += purchaseDocLine?.purchaseDocLineTaxes
            .map((tax) => parseFloat(String(tax.amount)))
            .reduce((total, amount) => total + amount, 0))
      );

    return totalTaxes;
  }

  purchaseDocDiscount(purchaseDoc: PurchaseDoc): number {
    const discountType = purchaseDoc.discountType;
    const discountAmount = purchaseDoc.discountAmount;
    let totalDiscount = 0;
    if (discountAmount === 0 || discountAmount === null) return 0;

    switch (discountType) {
      case DiscountType.Fixed:
        totalDiscount = discountAmount;
        break;
      case DiscountType.Percentage:
        totalDiscount = (this.purchaseDocSubTotal(purchaseDoc) * discountAmount) / 100;
        break;
    }
    return MonetaryHelpers.roundToDecimalPlaces(totalDiscount);
  }

  purchaseDocTotal(purchaseDoc: PurchaseDoc): number {
    const total =
      MonetaryHelpers.roundToDecimalPlaces(this.purchaseDocSubTotal(purchaseDoc), 2) -
      MonetaryHelpers.roundToDecimalPlaces(this.purchaseDocDiscount(purchaseDoc), 2) +
      MonetaryHelpers.roundToDecimalPlaces(this.purchaseDocTotalTax(purchaseDoc), 2) +
      MonetaryHelpers.roundToDecimalPlaces(Number(purchaseDoc.shipperChargeAmount || 0), 2);
    return total;
  }
}
