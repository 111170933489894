/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  OnDestroy,
  Type,
  ViewChild,
  ViewContainerRef,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ConfirmationService, MenuItem } from "primeng/api";
import { AppSettingsStorageService } from "../../../shared/app-settings-storage.service";
import { CurrencyFormatterService } from "../../../shared/services/currency-formatter.service";
import { MonetaryHelpers } from "../../../utility/MonetaryHelpers";
import { Cashout, CashoutOpeningFloat, CashoutSaveMethod } from "../cashout/cashout";
import { DBCashoutService } from "../db-cashout.service";
import { mergeMap, take } from "rxjs/operators";
import {
  PresetOpeningFloatOption,
  StoreSettingCashout,
} from "../../settings/store-settings/cashout-settings/CashoutSettings";
import { Subscription } from "rxjs";
import { ToggleCaptionPosition } from "src/app/taku-ui/taku-toggle/taku-toggle.component";
import { PrintingFactoryService, ReceiptPrintingSize } from "src/app/shared/services/printing-service.service";
import { CashoutSlipBase } from "../../previews/slips/CashoutSlipBase.interface";
import { GenericFormService } from "src/app/forms/generic-form/generic-form.component";
import { CashoutDeposits } from "../cashout-closetill/cashout-closetill.component";
import * as _ from "lodash";
import { OpeningCashoutSlipTapeSizeComponent } from "../../previews/slips/opening-cashout-slip-tape-size/opening-cashout-slip-tape-size.component";
import { OpeningCashoutSlipFullSizeComponent } from "../../previews/slips/opening-cashout-slip-full-size/opening-cashout-slip-full-size.component";
import { Router } from "@angular/router";

@Component({
  selector: "taku-cashout-openflow",
  templateUrl: "./cashout-openflow.component.html",
  styleUrls: ["./cashout-openflow.component.scss"],
})
export class CashoutOpenflowComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];
  @Input() formGroup: UntypedFormGroup;
  @Input() genericFormService?: GenericFormService;
  @Output() onCashoutOpened: EventEmitter<any> = new EventEmitter();
  @ViewChild("printPreviewHost", { read: ViewContainerRef, static: true }) printPreviewHost: ViewContainerRef;

  cashoutSteps: MenuItem[] = [{ label: "General Information" }, { label: "Float Confirmation" }];
  activeStep = 0;
  expectedCash = 0;
  _currencyISOCode;
  CashoutSaveMethod = CashoutSaveMethod;
  cashoutSettings: StoreSettingCashout;

  openSettingsForm: UntypedFormGroup;
  cashout: Cashout;
  ToggleCaptionPosition = ToggleCaptionPosition;
  enum_printing_sizes = this.dbCashoutService
    .enumSelectOptions(ReceiptPrintingSize)
    .filter((item) => item.value !== ReceiptPrintingSize.TAPE_SIZE_CASHOUT_REPORT);

  constructor(
    private fb: UntypedFormBuilder,
    private dbCashoutService: DBCashoutService,
    private printingService: PrintingFactoryService,
    private appSettingsService: AppSettingsStorageService,
    private currencyFormatterService: CurrencyFormatterService,
    private router: Router,
    protected confirmationService: ConfirmationService
  ) {
    this._currencyISOCode = this.appSettingsService.getZone().defaultCurrencyIsoCode;
  }

  get openSaveMethodCtrl() {
    return this.formGroup.get("openSaveMethod");
  }

  get totalNextFloatCtrl() {
    return this.formGroup.get("openTotalNextFloat");
  }

  get openingFloatsArray() {
    return this.formGroup.get("cashoutOpeningFloats") as UntypedFormArray;
  }

  get openingDateCtrl() {
    return this.formGroup.get("openingDate");
  }

  get openingTimeCtrl() {
    return this.formGroup.get("openingTime");
  }

  ngOnInit() {
    this.openSettingsForm = this.fb.group({
      printReport: false,
      printShift: false,
      print: true,
      extraCopy: false,
      printingSize: ReceiptPrintingSize.TAPE_SIZE,
    });

    this.subsList.push(
      this.appSettingsService
        .getStoreSettings("storeSettingCashout")
        .pipe(
          mergeMap((cashoutSettings: StoreSettingCashout) => {
            this.cashoutSettings = cashoutSettings;
            return this.dbCashoutService.getCashoutDenominations(
              cashoutSettings.presetOpeningFloatOption === PresetOpeningFloatOption.default_values
            );
          })
        )
        .subscribe((cashoutFloats) => {
          let totalCash = 0;
          cashoutFloats.forEach((openingFloat) => {
            totalCash += MonetaryHelpers.roundToDecimalPlaces(openingFloat.amount) * openingFloat.count;
            this.openingFloatsArray.push(
              this.fb.group(
                Object.assign(new CashoutOpeningFloat(), { amount: openingFloat.amount, count: openingFloat.count })
              )
            );
          });
          this.expectedCash = totalCash;
          this.totalNextFloatCtrl.setValue(totalCash);
        })
    );
    // Update it when there are any changes on opening floats

    this.subsList.push(
      this.openingFloatsArray.valueChanges.subscribe((newValues) => {
        this.cashout = Object.assign({}, new Cashout(), { cashoutOpeningFloats: newValues });
        this.formatTotalsAmounts();
        // console.log(this.formGroup);
      })
    );

    this.totalNextFloatCtrl.addValidators([Validators.required]);
  }

  private formatTotalsAmounts() {
    // Initially update total cash using service as component is not initially available
    const totalCash = this.currencyFormatterService.formatMonetaryAmount({
      currencyIsoCode: this._currencyISOCode,
      amount: this.totalFloatCash(),
    });
    this.totalNextFloatCtrl.setValue(totalCash, { emitEvent: false });
  }

  get liveCashoutData(): Cashout {
    return _.assign(new Cashout(), this.formGroup.value);
  }

  onOpenCashDrawer($event) {}

  onOpenDay($event) {
    this.subsList.push(
      this.dbCashoutService
        .searchOpenCashout$(this.appSettingsService.getStoreId(), this.appSettingsService.getStationId())
        .subscribe({
          next: (cashout) => {
            if (!cashout) {
              // check to see of cashout is open
              this.onCashoutOpened.emit();
              // this needs to be similar to line 306 in cashout-closetill
              this.subsList.push(
                this.genericFormService.formChanged$.pipe(take(1)).subscribe((cashout) => {
                  // console.log(cashout);
                  this.printOpenCashoutSlip(cashout);
                })
              );
            } else {
              this.appSettingsService.setCashout(cashout);
              this.confirmationService.confirm({
                header: "Notice",
                message:
                  "This Station has already been cashed in. To start selling, navigate to Sell > Sales Register.",
                acceptLabel: "OK",
                rejectVisible: false,
                accept: () => {
                  this.router.navigate(["/dashboard"]); // Go to dashboard
                },
              });
            }
          },
        })
    );
  }

  totalFloatCash(): number {
    let totalFloat = 0;
    switch (this.openSaveMethodCtrl.value) {
      case CashoutSaveMethod.DETAIL:
        for (const currencyCtrl of this.openingFloatsArray.controls) {
          totalFloat += currencyCtrl.get("amount").value * currencyCtrl.get("count").value;
        }
        break;

      case CashoutSaveMethod.TOTAL:
        totalFloat = this.totalNextFloatCtrl.value;
        break;
    }

    return totalFloat;
  }

  printOpenCashoutSlip(actualCashout) {
    if (!this.openSettingsForm.get("print").value) {
      return;
    }
    let printComponent: Type<CashoutSlipBase>;
    switch (this.printingSizeCtrl.value) {
      case ReceiptPrintingSize.TAPE_SIZE:
        printComponent = OpeningCashoutSlipTapeSizeComponent;
        break;

      case ReceiptPrintingSize.LETTER_SIZE:
        printComponent = OpeningCashoutSlipFullSizeComponent;
        break;
    }
    if (!printComponent) {
      console.warn("Cashout slip printing size NOT recognized", this.printingSizeCtrl.value);
      return;
    }
    const cashouts: Cashout[] = [actualCashout];
    // Add additional cashout copy to array when respective checkbox is enabled
    if (this.extraCopyCtrl.value) cashouts.push(actualCashout);

    this.printingService
      .build(null)
      .printOpeningCashoutSlip(
        cashouts,
        this.expectedCash,
        parseInt(this.totalNextFloatCtrl.value),
        this._currencyISOCode,
        this.cashoutSettings,
        this.printingSizeCtrl.value,
        this.printPreviewHost
      );
  }

  get extraCopyCtrl() {
    return this.openSettingsForm.get("extraCopy");
  }

  get printingSizeCtrl() {
    return this.openSettingsForm.get("printingSize");
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
