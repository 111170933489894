/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { AppDefaultsService } from "src/app/shared/services/app-defaults.service";
import { Zone } from "../zone-settings/Zone";

export abstract class StoreSettingPrintBase {
  printPennyRounding: boolean;

  constructor(zone: Zone) {
    const pennyRoundingDefaults = AppDefaultsService.DefaultPennyRoundingForZone(zone);
    this.printPennyRounding = pennyRoundingDefaults ? pennyRoundingDefaults.hasPennyRounding : false;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
