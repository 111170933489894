/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { EventEmitter, Injectable } from "@angular/core";
import { DBService } from "src/app/shared/services/db.service";
import { FormListModel } from "src/app/form-list/form-list/form-list-model.interface";
import {
  Col,
  DataType,
  ExecuteEventDatatypeOptions,
  ExecuteEvent_DisplayMode,
  FilterType,
} from "src/app/form-list/form-list/form-list";
import * as _ from "lodash";
import { SaleDoc } from "../../document/sale-document/sale-doc/sale-doc";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { AuthService } from "src/app/shared/services/auth.service";
import { HttpClient } from "@angular/common/http";
import { SaledocRevisitPreviewComponent } from "../../document/sale-document/saledoc-revisit-preview/saledoc-revisit-preview.component";
import { SaleDocType } from "../../document/doc/doc";
import { Observable } from "rxjs";
import { SelectItem } from "primeng/api";
@Injectable({
  providedIn: "root",
})
export class InventorySalesHistoryService extends DBService implements FormListModel {
  private showSaleDocpreview: EventEmitter<SaleDoc> = new EventEmitter();
  enum_doc_types = this.enumSelectOptions(SaleDocType);
  _model = "saleDoc";

  constructor(
    private dialogService: DialogService,
    public ref: DynamicDialogRef,
    protected authService: AuthService,
    protected http: HttpClient
  ) {
    super(http, authService);
    this.showSaleDocpreview.subscribe((saleDoc: SaleDoc) => {
      if (saleDoc.id) {
        this.getRow("saleDoc", saleDoc.id).subscribe((_saleDoc) => {
          this.ref = this.dialogService.open(SaledocRevisitPreviewComponent, {
            data: {
              _object: _saleDoc,
              _orgSaleDoc: saleDoc,
            },
            width: "90%",
            height: "90%",
            closable: false,
            showHeader: false,
          });
        });
      }
    });
  }

  getLookup_stocks(): Observable<SelectItem[]> {
    return this.lookupSelectOptions("stock", "stockName", { enableFieldName: "isActive" });
  }

  getLookup_users(): Observable<SelectItem[]> {
    return this.lookupSelectOptions("user", "userName", { enableFieldName: "isActive" });
  }

  getValidationRules() {
    return {};
  }

  qtyInverseSign(qtyFilter: string) {
    const qty = parseFloat(qtyFilter);
    return qty ? _.toString(-1 * qty) : "";
  }

  qtyPositiveSign(qtyFilter: string) {
    const qty = parseFloat(qtyFilter);
    return qty ? _.toString(Math.abs(qty)) : "";
  }

  getTakuDocLinkCaption(rowData: SaleDoc) {
    return rowData ? rowData.doc.docNo : "";
  }

  getFormListColumns(): Col[] {
    return [
      {
        field: "doc.docNo",
        header: "Doc #",
        visible: true,
        readonly: true,
        frozen: true,
        dataType: DataType.execute_event,
        dataOptions: [],
        dataTypeOptions: [
          new ExecuteEventDatatypeOptions({
            displayMode: ExecuteEvent_DisplayMode.LINK,
            label: this.getTakuDocLinkCaption,
            event: this.showSaleDocpreview,
            enabledOnEditMode: false,
            styleClass: "",
          }),
        ],
        filterType: FilterType.contains,
        colWidth: 100,
      },
      // { field: 'doc.docNo', header: 'Doc #', visible: true, readonly: true, frozen: false, dataType: DataType.input, dataOptions: [], filterType: FilterType.contains, colWidth: 100 },
      {
        field: "doc.docDate",
        header: "Date",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.date_date_only,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 95,
      },
      {
        field: "doc.localDocTime",
        header: "Time",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.date_time,
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 80,
        sortFields: ["doc.docDate", "doc.docTime"],
      },
      {
        field: "doc.docType",
        header: "Doc Type",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enum_doc_types,
        filterType: FilterType.enum,
        colWidth: 120,
      },
      {
        field: "store.stockId",
        fieldValue: "store.stock.stockName",
        header: "Stock Name",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.lookup,
        dataOptions: this.getLookup_stocks(),
        filterType: FilterType.lookup,
        colWidth: 120,
        sortFields: ["stock.stockName"],
      },
      {
        field: "sumReturnQty",
        header: "Qty +",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        filterMatchMode: "equals",
        sortFields: ["saleDocLines.qty"],
        filterField: "saleDocLines.qty",
        filterTransformer: this.qtyInverseSign.bind(this),
        colWidth: 75,
      },
      {
        field: "sumSaleQty",
        header: "Qty -",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        filterMatchMode: "equals",
        sortFields: ["saleDocLines.qty"],
        filterField: "saleDocLines.qty",
        filterTransformer: this.qtyPositiveSign.bind(this),
        colWidth: 75,
      },
      {
        field: "firstLineUnitPriceCurrencyFormat",
        header: "Price",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        sortFields: ["saleDocLines.unitPrice"],
        filterField: "saleDocLines.unitPrice",
        filterMatchMode: "equals",
        colWidth: 120,
      },
      {
        field: "accountName",
        header: "Account Name",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.none,
        isNotSortable: true,
        colWidth: 120,
      },
      {
        field: "accountEmail",
        header: "Account Email",
        visible: false,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.none,
        isNotSortable: true,
        colWidth: 150,
      },
      {
        field: "accountPhone",
        header: "Account Phone",
        visible: false,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.none,
        isNotSortable: true,
        colWidth: 120,
      },
      {
        field: "doc.userId",
        fieldValue: "doc.user.userName",
        header: "User",
        visible: false,
        readonly: true,
        frozen: false,
        dataType: DataType.lookup,
        dataOptions: this.getLookup_users(),
        filterType: FilterType.lookup,
        colWidth: 110,
        sortFields: ["user.userName"],
      },
      {
        field: "saleDocLines[0].docLine.note",
        header: "Notes",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        sortFields: ["saleDocLines.docLine.note"],
        colWidth: 225,
      },
    ];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
