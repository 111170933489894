/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, OnDestroy } from "@angular/core";
import { RowSelectionType } from "src/app/form-list/form-list/form-list";
import { ActivatedRoute, Params } from "@angular/router";
import { map } from "rxjs/operators";
import { Subscription } from "rxjs";

@Component({
  selector: "taku-access-rights-details-filtered",
  templateUrl: "./access-rights-details-filtered.component.html",
  styleUrls: ["./access-rights-details-filtered.component.scss"],
})
export class AccessRightsDetailsFilteredComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];

  filter: { [key: string]: { matchMode: string; value: any } };
  rowSelectionType: RowSelectionType = RowSelectionType.NONE;
  initialSortField = "";

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.subsList.push(
      this.route.parent.params.pipe(map((params: Params) => params.id)).subscribe((roleId) => {
        this.filter = { roleId: { matchMode: "equals", value: roleId } };
      })
    );

    this.subsList.push(
      this.route.queryParams.subscribe((params) => {
        if (params.initialSortField) {
          this.initialSortField = params.initialSortField;
        }
      })
    );
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
