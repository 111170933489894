<div [formGroup]="_myForm">
  <div class="grid">
    <div class="col-12 lg:col-4 status-container">
      <taku-dropdown
        [formGroup]="_myForm"
        [options]="enum_store_social_profile_type"
        [fieldName]="'socialProfileType'"
        [fieldCaption]="'Social Type'"
      >
      </taku-dropdown>
    </div>
    <div class="col-12 lg:col-8">
      <taku-input [formGroup]="_myForm" [fieldName]="'socialProfile'" [fieldCaption]="'Social Profile'"> </taku-input>
    </div>
  </div>
</div>
