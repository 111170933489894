/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, EventEmitter, HostBinding, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ResponseTransaction } from "src/app/core/settings/integration-settings/gateway-terminals/payment-terminal";
import { NestedFormComponent } from "../../../../forms/nested-form/nested-form.component";
import { SaleDocTender } from "./sale-doc-tender";

@Component({
  selector: "taku-sale-doc-tender",
  templateUrl: "./sale-doc-tender.component.html",
  styleUrls: ["./sale-doc-tender.component.scss"],
})
export class SaleDocTenderComponent extends NestedFormComponent {
  @Output() onRefNoOpened: EventEmitter<UntypedFormGroup> = new EventEmitter();
  @Output() onPrintPaymentReceiptClicked: EventEmitter<UntypedFormGroup> = new EventEmitter();

  @HostBinding("class.is-return") get isReturned(): boolean {
    return this._myForm.get("isReturned").value;
  }
  @HostBinding("class.type-payback") get isPayBack() {
    return !this._myForm.get("isTendered").value;
  }

  decimalFormat = "1.2-2";

  // ngOnChanges(changes: SimpleChanges): void {
  //   if (changes['_myForm']){
  //     this.docTender = this._myForm.value;
  //   }
  // }

  // ngOnInit(): void {
  //   super.ngOnInit();
  //   console.log(this._myForm.value.refTransaction);
  //   console.log(this._myForm.value.refTransaction.AuthCode);
  //   console.log(this._myForm.value.refTransaction.CardType);
  //   console.log(this._myForm.value.refTransaction.RefID);
  // }

  static set(fb: UntypedFormBuilder, saleTender: SaleDocTender): UntypedFormGroup {
    const tmpForm = fb.group(saleTender);
    tmpForm.setControl("tenderType", fb.group(saleTender.tenderType));
    return tmpForm;
  }

  static setArray(fb: UntypedFormBuilder, saleTenders: SaleDocTender[]) {
    const formArray = fb.array([]);
    saleTenders.map((saleTender) => {
      const temp = SaleDocTenderComponent.set(fb, saleTender);
      formArray.push(temp);
    });
    return formArray;
  }

  openReferenceDetailsDialog(refTransaction: ResponseTransaction) {
    //We will call reference based on tender type. It is the same now.!
    if (typeof refTransaction === "undefined") {
      this.onRefNoOpened.emit(this._myForm);
    } else {
      this.onRefNoOpened.emit(this._myForm);
    }
  }

  initValidation() {
    this._validation = {
      refNo: [Validators.maxLength(100)],
    };
  }

  get inSingleLineRange() {
    return (600 <= window.innerWidth && window.innerWidth < 992) || 1600 < window.innerWidth;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
