<div class="main-container">
  <taku-form-header
    toolbarTitle="Cashout ID {{ cashout.id }} - Details"
    displayMode="inline"
    [showActionBtns]="false"
    [showBackBtn]="false"
  >
    <div class="p-toolbar-group-right">
      <!-- <button pButton pRipple type="button" (click)="onPrintPressed()" icon="pi pi-print" class="header-button p-button-rounded"></button> -->
      <p-splitButton icon="pi pi-print" [model]="printItems"></p-splitButton>
      <button
        pButton
        pRipple
        type="button"
        (click)="onClosePressed()"
        icon="pi pi-times"
        class="header-button p-button-rounded"
      ></button>
    </div>
  </taku-form-header>

  <div class="inner-container">
    <div id="doc-fields" class="col-12 grid">
      <div class="col-12 md:col-6">
        <table cellspacing="0">
          <tr>
            <td class="label-col">Cashout ID</td>
            <td class="value-col">{{ cashout.id }}</td>
          </tr>
          <tr>
            <td class="label-col">Station #</td>
            <td class="value-col">{{ cashout.station.stationNumber }}</td>
          </tr>
          <tr>
            <td class="label-col">Opening User</td>
            <td class="value-col">{{ cashout.openingCashFloatUser?.person.fullName }}</td>
          </tr>
          <tr>
            <td class="label-col">Closing User</td>
            <td class="value-col">
              {{ cashout.closingCashFloatUser ? cashout.closingCashFloatUser?.person.fullName : "---" }}</td
            >
          </tr>
          <tr>
            <td class="label-col">Store</td>
            <td class="value-col">{{ cashout.store.storeName }}</td>
          </tr>
        </table>
      </div>

      <div class="col-12 md:col-6">
        <table cellspacing="0">
          <tr>
            <td class="label-col">Opening Time</td>
            <td class="value-col">{{ cashout.openingDate | dateTimeFormat : cashout.openingTime }}</td>
          </tr>
          <tr>
            <td class="label-col">Closing Time</td>
            <td class="value-col">
              {{ cashout.closingDate ? (cashout.closingDate | dateTimeFormat : cashout.closingTime) : "---" }}
            </td>
          </tr>
          <tr>
            <td class="label-col">Over/Under</td>
            <td class="value-col">
              {{ cashout.totalOverUnder | currency : _currencyISOCode : "code" : "1.2" | formatNegativeNumber }}</td
            >
          </tr>
          <tr>
            <td class="label-col">Status</td>
            <td class="value-col">{{ cashout.status }}</td>
          </tr>
        </table>
      </div>
    </div>

    <div class="grid">
      <taku-cashout-payment-breakdown
        *ngIf="closingBreakDownArray"
        [closingBreakdownArray]="closingBreakDownArray"
        [cashout]="cashout"
        [_currencyISOCode]="_currencyISOCode"
        [isEditable]="false"
      >
      </taku-cashout-payment-breakdown>
    </div>

    <div *ngIf="cashout.openingNote" class="col-12 cashout-notes opening-notes">
      <h4>Opening Notes:</h4>
      <section class="content" [innerHTML]="cashout.openingNote | replaceLineBreaks"></section>
    </div>

    <div *ngIf="cashout.closingNote" class="col-12 cashout-notes closing-notes">
      <h4>Closing Notes:</h4>
      <section class="content" [innerHTML]="cashout.closingNote | replaceLineBreaks"></section>
    </div>
  </div>
</div>

<div [hidden]="true">
  <ng-template #printPreviewHost></ng-template>
</div>
