/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

export class StoreSettingEmail {
  id: number;
  smtpHost: string;
  port: string;
  encryption: SettingEmailEncryption;
  authentication: boolean;
  userName: string;
  password: string;
  storeId: number | null;
  zoneId: number | null;

  constructor(storeId?: number) {
    this.id = 0;
    this.smtpHost = null;
    this.port = null;
    this.encryption = null;
    this.authentication = false;
    this.userName = null;
    this.password = null;
    this.storeId = storeId ?? null;
    this.zoneId = null;
  }
}

export enum SettingEmailEncryption {
  None = "None",
  SSL = "SSL",
  TLS = "TLS",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
