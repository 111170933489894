/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { PersonColumns, Person } from "../person/person";

export class Contact {
  id: number;
  isDefault: boolean;
  person: Person;
  commercialAccountId: number;

  constructor() {
    this.id = 0;
    this.person = new Person();
    this.isDefault = false;
    this.commercialAccountId = null;
  }
}

export const PersonalAccountColumns = PersonColumns.concat([{ field: "jobTitle", header: "Job Title" }]);

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
