<ng-template [ngIf]="readonly">
  <taku-input
    [formGroup]="formGroup"
    [fieldName]="fieldName"
    [fieldCaption]="fieldCaption"
    [readonly]="readonly"
  ></taku-input>
</ng-template>

<ng-template [ngIf]="!readonly">
  <div [formGroup]="formGroup" [ngClass]="{ 'disabled-control': disabled }">
    <div class="p-float-label">
      <p-calendar
        [formControlName]="fieldName"
        (onClose)="onCalendarClosed()"
        [monthNavigator]="true"
        [yearNavigator]="true"
        yearRange="1900:2030"
        [inputId]="fieldName"
        [showIcon]="true"
        dataType="string"
        [style]="{ width: '100%' }"
        dateFormat="yy-mm-dd"
        [appendTo]="appendTo"
        [touchUI]="isMobile"
        [readonlyInput]="isMobile"
        [minDate]="minDate ? minDate : null"
        [maxDate]="maxDate ? maxDate : null"
        [readonlyInput]="readonly"
      >
      </p-calendar>
      <label for="{{ fieldName }}" [innerHTML]="fullFieldCaption"></label>

      <form-validation-messages
        [hidden]="formGroup.get(fieldName).valid || !formGroup.get(fieldName).dirty"
        [errors]="formGroup.get(fieldName).errors"
        [fieldName]="fieldName"
      ></form-validation-messages>
    </div>
  </div>
</ng-template>
