/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
import { Component, OnInit, ElementRef, Output, EventEmitter, ViewChild, Input } from "@angular/core";
import { Subscription } from "rxjs";
import "moment-timezone";
import { environment } from "src/environments/environment";
import { CoreOptions } from "@adyen/adyen-web/dist/types/core/types";
import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";

export class AdyenCardFormState {
  isValid: boolean;
  fields: AdyenFormFields;

  constructor() {
    (this.isValid = false), (this.fields = new AdyenFormFields());
  }
}

export class AdyenFormFields {
  card_number: number;
  card_month_exp: string;
  card_year_exp: string;
  card_cvv: number;
  postalCode: string;

  constructor() {
    (this.card_number = null),
      (this.card_month_exp = ""),
      (this.card_year_exp = ""),
      (this.card_cvv = null),
      (this.postalCode = "");
  }
}

export class AdyenOptionOverrides {
  showPayButton: boolean;
  payButtonTitle: string;
  translations: Record<string, any>;
  constructor(overrides?: AdyenOptionOverrides) {
    this.showPayButton = overrides?.showPayButton ? overrides.showPayButton : false;
    this.payButtonTitle = overrides?.payButtonTitle ? overrides.payButtonTitle : "Pay";
    this.translations = overrides?.translations
      ? overrides.translations
      : {
          "en-US": {
            payButton: this.payButtonTitle,
          },
        };
  }
}

@Component({
  selector: "taku-adyen-integration",
  templateUrl: "./adyen-integration.component.html",
  styleUrls: ["./adyen-integration.component.scss"],
})
export class AdyenIntegrationComponent implements OnInit {
  subsList: Subscription[] = [];
  @ViewChild("adyen-container", { static: true }) adyenContainer: ElementRef;

  private _adyenOptionOverrides: AdyenOptionOverrides = new AdyenOptionOverrides();
  get adyenOptionOverrides(): AdyenOptionOverrides {
    return this._adyenOptionOverrides;
  }
  @Input() set adyenOptionOverrides(overrides: AdyenOptionOverrides) {
    this._adyenOptionOverrides = new AdyenOptionOverrides(overrides);
  }
  @Output() adyenCardFormStateEmitter: EventEmitter<AdyenCardFormState> = new EventEmitter();
  @Output() adyenFormSubmit: EventEmitter<AdyenCardFormState> = new EventEmitter();
  adyenCardFormState: AdyenCardFormState = new AdyenCardFormState();
  clientKey = "";
  checkout: any;

  constructor() {
    this.adyenContainer = new ElementRef("");
  }

  ngOnInit() {
    this.clientKey = environment.takuPayClientKey;

    const configuration = {
      environment: environment.takuPayEnv,
      clientKey: this.clientKey,
      locale: "en_US",
      showPayButton: this.adyenOptionOverrides.showPayButton,
      translations: this.adyenOptionOverrides.translations,
      onChange: (state) => {
        const currFormState = this.getSimplifiedFormState(state);
        this.adyenCardFormState = currFormState;
        this.adyenCardFormStateEmitter.emit(currFormState);
      },
      onError: (error, component) => {
        console.error(error.name, error.message, error.stack, component);
      },
    };

    this.AdyenCheckout(configuration);
  }

  async AdyenCheckout(configuration: CoreOptions) {
    await AdyenCheckout(configuration).then((checkout) => {
      // using the "this" keyword inside the .create method below does not refer to the angular class
      // need to store a reference here, so we're for sure using the right/expected "this".
      const thisRef = this;
      this.checkout = checkout
        .create("card", {
          type: "card",
          billingAddressRequired: true,
          billingAddressMode: "partial",
          // enableStoreDetails: true, // shows checkbox for "save card for future use"
          brands: ["mc", "visa", "amex", "discover"],
          styles: {
            error: {
              color: "red",
            },
            validated: {
              color: "green",
            },
            placeholder: {
              color: "#d8d8d8",
            },
          },
          onError: (data) => {
            console.log(data);
          },
          onSubmit(state, element) {
            const currFormState = thisRef.getSimplifiedFormState(state);
            thisRef.adyenCardFormState = currFormState;
            thisRef.adyenFormSubmit.emit(currFormState);
          },
        })
        .mount("#adyen-container");
    });
  }

  private getSimplifiedFormState(state) {
    const fields = {
      card_number: state.data.paymentMethod.encryptedCardNumber,
      card_month_exp: state.data.paymentMethod.encryptedExpiryMonth,
      card_year_exp: state.data.paymentMethod.encryptedExpiryYear,
      card_cvv: state.data.paymentMethod.encryptedSecurityCode,
      postalCode: state.data.billingAddress.postalCode,
    };

    return {
      isValid: state.isValid,
      fields: fields,
    };
  }
}
