/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Input, ViewChild } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, Validators } from "@angular/forms";
import { SelectItem } from "primeng/api";
import { Dropdown } from "primeng/dropdown";
import { NestedFormComponent } from "../../../forms/nested-form/nested-form.component";
import { CurrencyFormatterService } from "../../../shared/services/currency-formatter.service";
import { DBService } from "../../../shared/services/db.service";
import { LocationService } from "../../../shared/services/location.service";
import { FormDataHelpers } from "../../../utility/FormDataHelpers";
import { DropdownItemCustomFiltering } from "../../../shared/ui/DropdownItemCustomFiltering";
import { AppSettingsStorageService } from "../../../shared/app-settings-storage.service";
import { CountryPhoneMaskService } from "src/app/shared/services/country-phone-mask.service";
import { CommonValidators } from "src/app/shared/validators/CommonValidators";
import { ACTION_TYPE } from "src/app/shared/components/action-button/action-type.enum";

@Component({
  template: "",
  styles: [],
})
export class GenericPhoneComponent extends NestedFormComponent {
  @ViewChild(Dropdown, { static: true }) dropdownComponent: Dropdown;
  @Input() showPhoneCopyControl = true;
  // Define enum variables
  enum_phone_type;

  // Define lookup variables
  lookup_countries: DropdownItemCustomFiltering[] = [];
  public ACTION_TYPE: typeof ACTION_TYPE = ACTION_TYPE;

  static setArray(fb: UntypedFormBuilder, phones: any[]): UntypedFormArray {
    const formArray: UntypedFormArray = fb.array([]);
    phones.map((addressPhone) => {
      const temp = fb.group(addressPhone);
      formArray.push(temp);
    });
    return formArray;
  }

  constructor(
    public dbService: DBService,
    public fb: UntypedFormBuilder,
    public lService: LocationService,
    public cfService: CurrencyFormatterService,
    public appSettings: AppSettingsStorageService,
    protected phoneMaskService: CountryPhoneMaskService
  ) {
    super(dbService, fb);
  }

  ngOnInit() {
    super.ngOnInit();

    this.setDynamicPhoneValidation(this.countryIsoCodeCtrl.value);
    this.subsList.push(
      this.countryIsoCodeCtrl.valueChanges.subscribe((countryCode) => {
        this.setDynamicPhoneValidation(countryCode);
      })
    );
  }

  get countryIsoCodeCtrl() {
    return this._myForm.get("countryIsoCode");
  }

  get telephoneCtrl() {
    return this._myForm.get("tel");
  }

  get phoneTypeCtrl() {
    return this._myForm.get("phoneType");
  }

  get phoneExtCtrl() {
    return this._myForm.get("ext");
  }

  private setDynamicPhoneValidation(countryCode) {
    if (!countryCode) return;
    const length = this.phoneMaskService.getMaximumPhoneLength(countryCode);
    this.telephoneCtrl.setValidators([
      CommonValidators.completeTelephone(length),
      CommonValidators.requiredTelephone(),
    ]);
    this.telephoneCtrl.updateValueAndValidity({ emitEvent: false });
  }

  ngOnChanges() {
    if (!this.countryIsoCodeCtrl.value && this.appSettings.getZone())
      this.countryIsoCodeCtrl.setValue(this.appSettings.getZone().countryIsoCode);
  }

  initLookups() {
    const countries = this.lService.lookupCountryOptions_full();
    countries.forEach((country: SelectItem) => {
      this.lookup_countries.push(Object.assign(new DropdownItemCustomFiltering(this.dropdownComponent), country));
    });
  }

  initValidation() {
    this._validation = {
      phoneType: [Validators.required],
      countryIsoCode: [Validators.required],
    };
  }

  get countryName() {
    return this.lService.lookupCountryByIsoCode(this.countryIsoCodeCtrl.value);
  }

  tryFocusFilterField($event) {
    return FormDataHelpers.putCursorIntoDropFilter($event);
  }

  copyPhoneNumberToClipboard() {
    this.copyValueToClipboard(this.removeAreaCode(this.telephoneCtrl.value));
  }

  removeAreaCode(phoneNumber: string): string {
    // only if it has an area number
    if (phoneNumber.charAt(0) === "+") {
      const index = phoneNumber.indexOf(" ");
      return phoneNumber.substring(index + 1);
    }
    return phoneNumber;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
