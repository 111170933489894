/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { HttpErrorResponse } from "@angular/common/http";
import { AlertMessagesService } from "../services/alert-messages.service";
import { MessageService } from "primeng/api";
import { Router } from "@angular/router";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Location } from "@angular/common";

@Component({
  selector: "taku-page-loading",
  templateUrl: "./page-loading.component.html",
  styleUrls: ["./page-loading.component.css"],
})
export class PageLoadingComponent implements OnInit {
  @Input() isLoading: boolean;
  @Input() err: HttpErrorResponse;
  @Output() retry: EventEmitter<any> = new EventEmitter<any>();

  value = 0;

  constructor(
    private location: Location,
    private router: Router,
    private messageService: MessageService,
    private alertMessage: AlertMessagesService
  ) {}

  ngOnInit() {
    const interval = setInterval(() => {
      this.value = this.value + Math.floor(Math.random() * 10) + 1;
      if (this.err !== undefined) {
        this.messageService.add(this.alertMessage.getErrorMessage(this.err, "", ""));
        clearInterval(interval);
      }
      if (this.value >= 100) {
        this.value = 100;
        clearInterval(interval);
      }
    }, 2000);
  }

  goBack() {
    this.location.back();
  }

  goDashboard() {
    this.router.navigate(["/dashboard"]);
  }

  doRetry() {
    this.messageService.clear();
    this.err = undefined;
    this.ngOnInit();
    this.retry.emit();
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
