<taku-form-list-main
  [pageTitleSuffix]="pageTitleSuffix"
  [_defaultRowValues]="_defaultRowValues"
  [_filter]="_filter"
  [beforeSaveAll]="beforeSaveAll"
>
  <div class="panel-toolbar">
    <!-- <taku-location-switcher (onLocationChanged)="storeChanged($event);"></taku-location-switcher> -->
  </div>
</taku-form-list-main>
