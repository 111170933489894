/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { PersonAddress } from "../person-address/person-address";
import { PersonPhone } from "../person-phone/person-phone";
import { PersonEmail } from "../person-email/person-email";
import { PersonInstantMsg } from "../person-instant-msg/person-instant-msg";
import { PersonSocialProfile } from "../person-social-profile/person-social-profile";

export class Person {
  id: number;
  updatedAt: string;
  jobTitle: string;
  firstName: string;
  middleName: string;
  lastName: string;
  gender: GenderType;
  birthDate: Date;
  title: TitleType;
  personAddresses: PersonAddress[];
  personPhones: PersonPhone[];
  personEmails: PersonEmail[];
  personInstantMsgs: PersonInstantMsg[];
  personSocialProfiles: PersonSocialProfile[];
  preferredMethodContactType: PreferredMethodContactType;
  consent = false;
  consentDateTime = "";
  imageFileName: string;
  urlImageFileName: string;
  urlImageFileName40: string;
  urlImageFileName150: string;
  urlImageFileName475: string;

  constructor() {
    this.id = 0;
    this.jobTitle = "";
    this.firstName = "";
    this.middleName = "";
    this.lastName = "";
    this.title = null;
    this.gender = null;
    this.birthDate = null;
    this.personAddresses = [];
    this.personPhones = [];
    this.personEmails = [];
    this.personInstantMsgs = [];
    this.personSocialProfiles = [];
    this.preferredMethodContactType = PreferredMethodContactType.email;
    this.imageFileName = "";
    this.urlImageFileName = "";
    this.urlImageFileName40 = "";
    this.urlImageFileName150 = "";
    this.urlImageFileName475 = "";
  }

  get fullName(): string {
    return `${this.firstName} ${this.middleName ? this.middleName + " " : ""}${this.lastName}`.trim();
  }

  get emailAddresses(): string[] {
    return this.personEmails.map((personEmail) => personEmail.email);
  }
}

export const PersonColumns = [
  { field: "firstName", header: "First Name" },
  { field: "lastName", header: "Last Name" },
  { field: "gender", header: "Gender" },
  { field: "birthDate", header: "Birth Date" },
  { field: "note", header: "Note" },
];

export enum PreferredMethodContactType {
  email = "Email",
  phone = "Phone",
  text = "Text",
}

export enum GenderType {
  male = "Male",
  female = "Female",
  other = "Other",
  rather_not_say = "Rather Not Say",
}

export enum TitleType {
  Mr = "Mr.",
  Ms = "Ms.",
  Miss = "Miss",
  Mrs = "Mrs.",
  Dr = "Dr.",
  Sir = "Sir",
  Madame = "Madame",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
