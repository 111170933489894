/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Location } from "@angular/common";
import { Component, EventEmitter, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, AbstractControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, MessageService, SelectItem } from "primeng/api";
import { Subscription } from "rxjs";
import { GenericFormComponent } from "../../../../forms/generic-form/generic-form.component";
import { AppSettingsStorageService } from "../../../../shared/app-settings-storage.service";
import { AlertMessagesService } from "../../../../shared/services/alert-messages.service";
import { AddressLocationType } from "../../../contact-accounts/address/address";
import { AddressComponent } from "../../../contact-accounts/address/address.component";
import { StoreInstantMsgComponent } from "../../../contact-accounts/store-instant-msg/store-instant-msg.component";
import { StoreSocialProfileComponent } from "../../../contact-accounts/store-social-profile/store-social-profile.component";
import { StoreHour, Week_Day } from "../store-hour/store-hour";
import { Store, Store_Type } from "./store";
import { StoreService } from "./store.service";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "src/app/shared/services/auth.service";
import { environment } from "src/environments/environment";
import { FileUpload } from "primeng/fileupload";

@Component({
  selector: "taku-store",
  templateUrl: "./store.component.html",
  styleUrls: ["./store.component.scss"],
})
export class StoreComponent extends GenericFormComponent implements OnDestroy, OnChanges {
  @Output() dialogClosed = new EventEmitter<any>();
  @ViewChild("fileUploader") _fileUploader: FileUpload;

  enum_store_types = this.dbService.enumSelectOptions(Store_Type);
  enum_week_days: SelectItem[] = this.dbService.enumSelectOptions(Week_Day);
  lookup_zones$ = this.dbService.lookup_zones();
  lookup_stocks: any[];

  private businessSetupSubscription: Subscription;

  constructor(
    protected authService: AuthService,
    protected _router: Router,
    public fb: UntypedFormBuilder,
    public dbService: StoreService,
    protected location: Location,
    public _route: ActivatedRoute,
    protected messageService: MessageService,
    protected alertMessage: AlertMessagesService,
    protected confirmationService: ConfirmationService,
    appSettings: AppSettingsStorageService,
    private httpClient: HttpClient
  ) {
    super(_router, fb, dbService, location, _route, messageService, alertMessage, confirmationService, appSettings);
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    this.subsList.push(
      this.changedForm.subscribe((store) => {
        if (store.id === this.appSettingsService.getStoreId()) {
          this.appSettingsService.updateStore(store);
        }
      })
    );

    if (this._id === 0) {
      if (this.businessSetupSubscription) this.businessSetupSubscription.unsubscribe();
      // this.businessSetupSubscription = this.changedForm.subscribe(store => {
      //   this.dbService.showBusinessSetupConfirmation(store.id);
      // });
    }
  }

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    const tmpModel = new Store();
    const tmpForm = fb.group(tmpModel);
    tmpForm.setControl("address", AddressComponent.init(fb, AddressLocationType.commercial));
    tmpForm.setControl("storeHours", fb.array([]));
    // tmpForm.setControl('storeEmails', fb.array([]));
    tmpForm.setControl("storeInstantMsgs", fb.array([]));
    tmpForm.setControl("storeSocialProfiles", fb.array([]));

    return tmpForm;
  }

  onRevert() {
    this._myForm.get("stockId").setValue(null);
    super.onRevert();
  }

  ngOnInit() {
    super.ngOnInit();

    this.subsList.push(
      this._myForm.get("zoneId").valueChanges.subscribe((newZoneId) => {
        const oldZoneId = this._myForm.value.zoneId;
        if (oldZoneId && oldZoneId !== newZoneId && this._myForm.value.stockId) {
          this.showZoneChangeConfirmationDialog(newZoneId, oldZoneId);
        } else {
          this.subsList.push(
            this.dbService
              .lookup_stocks(newZoneId, this._myForm.value.stockId)
              .subscribe((data) => (this.lookup_stocks = data))
          );
        }
      })
    );
  }
  private showZoneChangeConfirmationDialog(newZoneId, oldZoneId) {
    this.confirmationService.confirm({
      message: "By changing zone you will lose default stock. Are you sure?",
      rejectButtonStyleClass: "p-button-link",
      accept: () => {
        this._myForm.patchValue({ stockId: null });
        this.subsList.push(
          this.dbService
            .lookup_stocks(newZoneId, this._myForm.value.stockId)
            .subscribe((data) => (this.lookup_stocks = data))
        );
      },
      reject: () => {
        this._myForm.patchValue({ stockId: this._myForm.value.stockId, zoneId: oldZoneId });
      },
    });
  }

  closeSidebar() {
    this.dialogClosed.emit();
  }

  initLookups() {}

  initForm() {
    this._model = "store";
    this._object = new Store();
    this._myForm = StoreComponent.init(this.fb);
  }

  initValidation() {
    this._validation = this.dbService.getValidationRules();
  }

  loadObject() {
    super.loadObject();
    this.subsList.push(
      this.dbService
        .lookup_stocks(this._object.zoneId, this._object.stockId)
        .subscribe((data) => (this.lookup_stocks = data))
    );
  }

  deleteStoreHour(index) {
    const hoursArray: UntypedFormArray = <UntypedFormArray>this._myForm.get("storeHours");
    if (hoursArray.at(index).pristine) {
      this._removeFormArrayChild(hoursArray, index);
      return;
    }

    this.confirmationService.confirm({
      message: "By deleting this Store Hour you will lose your changes. Are you sure?",

      accept: () => {
        this._removeFormArrayChild(hoursArray, index);
      },
    });
  }

  addStoreHour() {
    const newHour = this.fb.group(new StoreHour());
    this.ctrlStoreHours.push(newHour);
    this.ctrlStoreHours.markAsDirty();
  }

  get ctrlStoreHours(): UntypedFormArray {
    return <UntypedFormArray>this._myForm.get("storeHours");
  }

  setFormArrays() {
    const formArray = this.fb.array([]);
    this._object.storeHours.map((storeHour) => {
      const temp = this.fb.group(storeHour);
      formArray.push(temp);
    });
    this._myForm.setControl("storeHours", formArray);

    // TODO: Uncomment this once storeEmails are implemented on server
    // (<FormGroup>this._myForm).setControl('storeEmails', StoreEmailComponent.setArray(this.fb, this._object.storeEmails));
    this._myForm.setControl(
      "storeInstantMsgs",
      StoreInstantMsgComponent.setArray(this.fb, this._object.storeInstantMsgs)
    );
    this._myForm.setControl(
      "storeSocialProfiles",
      StoreSocialProfileComponent.setArray(this.fb, this._object.storeSocialProfiles)
    );
  }

  // addStoreEmail() {
  //   if (!(this._myForm.controls['storeEmails'] instanceof FormArray)) {
  //     this._myForm.setControl('storeEmails', this.fb.array([]));
  //   }
  //   (<FormArray>this._myForm.controls['storeEmails']).push(StoreEmailComponent.init(this.fb));
  // }

  addStoreInstantMsg() {
    if (!(this.ctrlInstantMsgs instanceof UntypedFormArray)) {
      this._myForm.setControl("storeInstantMsgs", this.fb.array([]));
    }
    this.ctrlInstantMsgs.push(StoreInstantMsgComponent.init(this.fb));
    this.ctrlInstantMsgs.markAsDirty();
  }

  get ctrlInstantMsgs() {
    return <UntypedFormArray>this._myForm.controls["storeInstantMsgs"];
  }

  addStoreSocialProfile() {
    if (!(this.ctrlSocialProfiles instanceof UntypedFormArray)) {
      this._myForm.setControl("storeSocialProfiles", this.fb.array([]));
    }
    this.ctrlSocialProfiles.push(StoreSocialProfileComponent.init(this.fb));
    this.ctrlSocialProfiles.markAsDirty();
  }

  get ctrlSocialProfiles() {
    return <UntypedFormArray>this._myForm.get("storeSocialProfiles");
  }

  // removeStoreEmail(index: number) {
  //   const emailsArray:FormArray = (<FormArray>this._myForm.get('storeEmails'));
  //   if (emailsArray.at(index).pristine){
  //     this._removeFormArrayChild(emailsArray, index);
  //     return;
  //   }

  //   this.confirmationService.confirm({
  //     message: 'By deleting this Email you will lose your changes. Are you sure?',

  //     accept: () => {
  //       this._removeFormArrayChild(emailsArray, index);
  //     }
  //   });
  // }

  removeStoreInstantMsg(index: number) {
    if (this.ctrlInstantMsgs.at(index).pristine) {
      this._removeFormArrayChild(this.ctrlInstantMsgs, index);
      return;
    }

    this.confirmationService.confirm({
      message: "By deleting this IM you will lose your changes. Are you sure?",

      accept: () => {
        this._removeFormArrayChild(this.ctrlInstantMsgs, index);
      },
    });
  }

  removeStoreSocialProfile(index: number) {
    if (this.ctrlSocialProfiles.at(index).pristine) {
      this._removeFormArrayChild(this.ctrlSocialProfiles, index);
      return;
    }

    this.confirmationService.confirm({
      message: "By deleting this Social Profile you will lose your changes. Are you sure?",

      accept: () => {
        this._removeFormArrayChild(this.ctrlSocialProfiles, index);
      },
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.businessSetupSubscription) this.businessSetupSubscription.unsubscribe();
  }

  clearImage() {
    this.logoUrlCtrl.setValue(null);
    this.urlLogoUrlCtrl.setValue(null);
    this.logoUrlCtrl.markAsDirty();
  }

  myUploader(event) {
    // get signedUrl
    event.files.map((_file) => {
      if (_file.size <= 2 * 1024 * 1024) {
        this.subsList.push(
          this.dbService._getRequest<any>(environment.apiUrl + "/uploadURL/" + _file.name).subscribe(
            (response: any) => {
              this.subsList.push(
                this.httpClient.put(response.url, _file).subscribe(
                  (res) => {
                    this.logoUrlCtrl.setValue(response.fileName);
                    this.urlLogoUrlCtrl.setValue(response.bucketPath + "/" + response.fileName);
                    this.logoUrlCtrl.markAsDirty();
                  },
                  (err) => {}
                )
              );
            },
            (err) => {}
          )
        );
      } else {
        this.messageService.add(this.alertMessage.getMessage("max-upload-size-warning"));
        this._fileUploader.clear();
      }
    });
  }

  get logoImageUrl() {
    return this._myForm.value.urlStoreLogoUrl;
  }

  get logoUrlCtrl(): AbstractControl {
    return this._myForm.get("storeLogoUrl");
  }

  get urlLogoUrlCtrl(): AbstractControl {
    return this._myForm.get("urlStoreLogoUrl");
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
