<taku-form-header
  displayMode="full"
  [toolbarTitle]="_pageTitle"
  [showActionBtns]="false"
  [showBackBtn]="true"
  (backClicked)="goBack()"
></taku-form-header>

<div class="paneltitle-right">
  <ng-content select="*.panel-toolbar"></ng-content>
</div>
<div *ngIf="!isSynced" class="secondary-progressbar col-12" id="data-progressbar">
  <p-progressBar mode="indeterminate" value="0"> </p-progressBar>
</div>

<taku-form-list
  *ngIf="isSynced"
  #formList
  [ngClass]="{ pullDown: _pageTitle || _pageIconStyleClass }"
  [_model]="_model"
  [_detailMode]="_detailMode"
  [_cols]="_cols"
  [_title]="'TAKU Pay Accounts'"
  [_settingsCol]="_settingsCol"
  [_detailViewRoute]="_detailViewRoute"
  [_defaultRowValues]="_defaultRowValues"
  [_extraQueryParams]="_extraQueryParams"
  [_hideAddLineHeaderBtn]="true"
  [_hideDeleteHeaderBtn]="true"
  [_rowSelectionType]="_rowSelectionType"
  [defaultSortField]="_sortField"
  [_headerInfo]="_headerInfo"
  [_dialogShowHeader]="false"
  _dialogStyleClass="form-list-dialog-style"
  [_dataService]="dbService"
  [_deleteRowEvent]="checkIfCanDeleteDialog"
>
</taku-form-list>
