/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Inventory } from "../../inventory/inventory/inventory";
import { User } from "../../users/user/user";

export class DocLine {
  id: number;
  seqNo: number;
  note: string;
  batchNo: string;
  expiryDate: Date;
  serialNo: string;
  refNo: number;
  inventoryId: number;
  inventory: Inventory;
  userId: number;
  user: User;

  constructor() {
    this.id = 0;
    this.seqNo = 0;
    this.note = "";
    this.batchNo = "";
    this.expiryDate = null;
    this.serialNo = "";
    this.refNo = 0;
    this.inventoryId = 0;
    this.inventory = null;
    this.userId = 0;
    this.user = null;
  }

  isKeywordAMatch(keyword: string): boolean {
    // If there is a partial match on product description (case insensitive)
    if (this.inventory.description1.toLowerCase().includes(keyword.toLowerCase())) return true;

    // if there is an exact match on SKU
    if (this.inventory.sku == keyword) return true;

    // if there an exact match over one of the barcodes
    const barcodes = this.inventory.inventoryBarcodes.map((barcode) => barcode.barcode);
    if (barcodes.includes(keyword)) return true;

    return false;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
