/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { StoreSettingsGenericComponent } from "../StoreSettingsGenericComponent";
import { StorePoliciesSettings } from "./StorePoliciesSettings";
import { StorePoliciesSettingsService } from "./store-policies-settings.service";
import { Router, ActivatedRoute } from "@angular/router";
import { PrintingFactoryService } from "src/app/shared/services/printing-service.service";
import { MessageService, ConfirmationService } from "primeng/api";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { Location } from "@angular/common";
import { AppComponent } from "src/app/app.component";

@Component({
  selector: "taku-store-policies-settings",
  templateUrl: "./store-policies-settings.component.html",
  styleUrls: ["./store-policies-settings.component.scss"],
})
export class StorePoliciesSettingsComponent extends StoreSettingsGenericComponent {
  _pageTitlePrefix = "Current Store Policies";

  constructor(
    _router: Router,
    fb: UntypedFormBuilder,
    public dbService: StorePoliciesSettingsService,
    location: Location,
    printingFactoryService: PrintingFactoryService,
    _route: ActivatedRoute,
    messageService: MessageService,
    alertMessage: AlertMessagesService,
    confirmationService: ConfirmationService,
    appSettings: AppSettingsStorageService
  ) {
    super(
      _router,
      fb,
      dbService,
      location,
      printingFactoryService,
      _route,
      messageService,
      alertMessage,
      confirmationService,
      appSettings
    );
  }

  static init(fb: UntypedFormBuilder, storeSettings): UntypedFormGroup {
    // const tmpModel = new StorePoliciesSettings();
    const tmpForm = fb.group(storeSettings);

    return tmpForm;
  }

  initLookups() {}

  initValidation() {
    this._validation = this.dbService.getValidationRules();
  }

  initForm() {
    this._model = "storePolicy";
    this._object = new StorePoliciesSettings(this.appSettingsService.getStore());
    this._myForm = StorePoliciesSettingsComponent.init(this.fb, this._object);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
