/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import {
  ChangeDetectorRef,
  Component,
  Input,
  NgZone,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
  ViewChild,
  TemplateRef,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { concat, Observable, of, Subscription } from "rxjs";
import { catchError, map, share, take, toArray } from "rxjs/operators";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { TenderType, Tender_Type } from "../../settings/business-settings/tender-type/tender-type";
import { StoreSettingCashout } from "../../settings/store-settings/cashout-settings/CashoutSettings";
import {
  StoreTenderType,
  StoreTenderTypeList,
} from "../../settings/store-settings/store-tender-type-settings/TenderTypesSettings";
import { CashoutClosingBreakdownComponent } from "../cashout-closing-breakdown/cashout-closing-breakdown.component";
import { CashoutTenderTransactionsComponent } from "../cashout-tender-transactions/cashout-tender-transactions.component";
import { Cashout, CashoutClosingBreakDown } from "../cashout/cashout";
import { CashoutPaymentBreakdown, DBCashoutService } from "../db-cashout.service";
import { ActivatedRoute } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { MessageService } from "primeng/api";
import { Col, TemplateDatatypeOptions } from "src/app/form-list/form-list/form-list";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";
import {
  PaymentTerminal,
  RequestPairTerminal,
  ResponseCloseBatch,
} from "../../settings/integration-settings/gateway-terminals/payment-terminal";
import { GatewayTerminalsService } from "../../settings/integration-settings/gateway-terminals/gateway-terminals.service";
import * as _ from "lodash";
import { EconduitService } from "../../settings/integration-settings/econduit-terminal/econduit.service";
import { PaymentGatewayType } from "../../settings/integration-settings/payment-gateways/payment-gateway";
import { MonerisService } from "../../settings/integration-settings/moneris-terminal/moneris.service";
import { BatchCloseDetailsDialogComponent } from "../batch-close-details-dialog/batch-close-details-dialog.component";
import { MonerisOpenTotalDetailsDialogComponent } from "../moneris-open-total-details-dialog/moneris-open-total-details-dialog.component";

@Component({
  selector: "taku-cashout-payment-breakdown",
  templateUrl: "./cashout-payment-breakdown.component.html",
  styleUrls: ["./cashout-payment-breakdown.component.scss"],
  providers: [DialogService, RequestPairTerminal],
})
export class CashoutPaymentBreakdownComponent implements OnInit, OnChanges, OnDestroy {
  subsList: Subscription[] = [];
  @Input() closingBreakdownArray: UntypedFormArray;
  @Input() cashout: Cashout;
  @Input() _currencyISOCode: string;
  @Input() isEditable = true;
  @ViewChild("terminalPairingTpl", { static: true }) terminalPairingTpl: TemplateRef<any>;
  @ViewChildren(CashoutClosingBreakdownComponent) breakdownComponents: QueryList<CashoutClosingBreakdownComponent>;
  @Output() onCashActualAmountChanged: Observable<number> = of();

  _formListFilter = {};
  _formListCols: Col[];
  _formListModel = "paymentTerminal";
  _formListDefaultRowValues;
  _bCashoutIntegratedPaymentBreakdown = false;
  showSystemColumn = false;
  _settleBtnRunning = [];

  private _cashoutSettings: StoreSettingCashout;

  constructor(
    private dbService: DBCashoutService,
    private fb: UntypedFormBuilder,
    public dialogService: DialogService,
    private monerisService: MonerisService,
    public ref: DynamicDialogRef,
    private appSettings: AppSettingsStorageService,
    private ngZone: NgZone,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private alertMessagesService: AlertMessagesService,
    private dbSevice: GatewayTerminalsService,
    private econduitService: EconduitService,
    private requestPairTerminal: RequestPairTerminal
  ) {}

  ngOnInit() {
    const storeId = this.appSettings.getStoreId();
    const stationId = this.appSettings.getStationId();

    this.route.params.subscribe((params) => {
      this._formListFilter["stationId"] = {
        matchMode: "equals",
        value: stationId,
      };

      // this._formListDefaultRowValues = {
      //   stationId
      // }

      this._formListCols = this.dbSevice.getFormListColumnsCashoutScreen(
        stationId,
        new TemplateDatatypeOptions({ templateRef: this.terminalPairingTpl })
      );
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const storeTendersObservable = this.appSettings
      .getSettingsByStoreId("storeTenderTypeList", this.cashout.storeId)
      .pipe(map((storeTenderTypeList: StoreTenderTypeList) => storeTenderTypeList.storeTenderTypes));
    const fetchSettings = concat(
      storeTendersObservable,
      this.dbService.getPaymentBreakdown(this.cashout.id),
      this.appSettings
        .getSettingsByStoreId("storeSettingCashout", this.cashout.storeId)
        .pipe(catchError((error) => of(new StoreSettingCashout()))),
      this.dbService
        .getRows(
          "tenderType",
          JSON.stringify({
            zoneId: { value: this.cashout.store.zoneId, matchMode: "equals" },
            isEnabled: { value: true, matchMode: "equals" },
            otherType: { value: null, matchMode: "ne" },
          })
        )
        .pipe(map((result) => result.rows))
    ).pipe(toArray(), share());

    // Only this the first time, when the component initialize
    if (changes["cashout"] && changes["cashout"].firstChange)
      this.subsList.push(
        fetchSettings.subscribe(
          ([storeTenders, payments, cashoutSettings, integratedTenderTypes]: [
            StoreTenderType[],
            CashoutPaymentBreakdown[],
            StoreSettingCashout,
            TenderType[]
          ]) => {
            this._cashoutSettings = cashoutSettings;
            this.showSystemColumn = this._cashoutSettings && this._cashoutSettings.showSystemValue;

            const storeTenderIds = storeTenders.map((row) => row.tenderType.id);

            const combinedTenderTypes = [
              ...storeTenders.map((row) => row.tenderType),
              ...integratedTenderTypes.filter((row) => {
                return (
                  !storeTenderIds.includes(row.id) &&
                  storeTenders.some((storeRow) => storeRow.tenderType.type === row.type)
                );
              }),
            ];
            const cashoutStoreTenderTypes = combinedTenderTypes.filter((row) => {
              return row.type === Tender_Type.Cash || payments.map((row) => row.tenderTypeId).includes(row.id);
            });
            // if payment breakdown array is empty, prefill with payments made for cashout
            if (!this.closingBreakdownArray.length)
              cashoutStoreTenderTypes.forEach((tenderType) => {
                const closingBreakDown = new CashoutClosingBreakDown();
                closingBreakDown.actualAmount = this.cashout.isClosed ? 0 : null;
                closingBreakDown.tenderTypeId = tenderType.id;
                const breakdownForm = CashoutClosingBreakdownComponent.set(this.fb, closingBreakDown);
                // Attach new closing breakdown
                this.closingBreakdownArray.push(breakdownForm);
              });

            // Associate payment summary info (system, tender description) to array

            this._attachPaymentInfoToBreakdownArray(cashoutStoreTenderTypes, payments);
            this.updateCashBreakdown(this.cashout, null, storeTenders);
          }
        )
      );

    // After following changes in cashout, just update the actual amount
    if (changes["cashout"] && !changes["cashout"].firstChange) {
      const subscription = storeTendersObservable.pipe(take(1)).subscribe((tenders) => {
        this.ngZone.run(() => {
          this.updateCashBreakdown(this.cashout, changes["cashout"].previousValue, tenders);
        });
      });
      subscription.unsubscribe();
    }
  }

  updateCashBreakdown(cashout: Cashout, prevCashout: Cashout, tenders: StoreTenderType[]) {
    // If there is not changes in the cashout Actual Cash, do nothing

    if (prevCashout && cashout.actualCashTotal === prevCashout.actualCashTotal) return;

    for (let i = 0; i < this.closingBreakdownArray.length; i++) {
      const breakdownForm = <UntypedFormGroup>this.closingBreakdownArray.at(i);
      const formTenderId = breakdownForm.get("tenderTypeId").value;
      const tender = tenders.find((tender) => tender.tenderTypeId == formTenderId);
      // if this tender is not cash, try with the next one
      if (!tender || tender.tenderType.type != Tender_Type.Cash) continue;

      // Only update amount if there are changes in the cashout total as it's calculated
      breakdownForm.get("actualAmount").setValue(cashout.actualCashTotal - cashout.totalOpeningFloat);
    }

    this.changeDetectorRef.detectChanges();
  }

  private _attachPaymentInfoToBreakdownArray(tenderTypes: TenderType[], paymentsInfo: CashoutPaymentBreakdown[]) {
    for (let i = 0; i < this.closingBreakdownArray.length; i++) {
      const breakdownForm = <UntypedFormGroup>this.closingBreakdownArray.at(i);
      const formTenderId = breakdownForm.get("tenderTypeId").value;
      breakdownForm.addControl("tenderDescription", this.fb.control(""));
      breakdownForm.addControl("systemTotal", this.fb.control(0));
      breakdownForm.addControl("tenderTypeType", this.fb.control(null));

      const tenderDescCtrl = breakdownForm.get("tenderDescription");
      const systemTotalCtrl = breakdownForm.get("systemTotal");
      const tenderTypeCtrl = breakdownForm.get("tenderTypeType");
      const tenderType = tenderTypes.find((tenderType) => tenderType.id == formTenderId);

      //const payment = paymentsInfo.find(payment => payment.tenderTypeId == formTenderId);
      const payment = paymentsInfo.filter((row) => row.tenderTypeId == formTenderId);

      if (tenderType) {
        tenderTypeCtrl.setValue(tenderType.type);
        tenderDescCtrl.setValue(tenderType.description);
      }

      if (payment.length > 0) {
        //systemTotalCtrl.setValue(payment.systemTotal);
        //tenderDescCtrl.setValue(payment.tenderDescription);
        systemTotalCtrl.setValue(payment.reduce((row, current) => row + parseFloat(current.systemTotal), 0));
        tenderDescCtrl.setValue(payment[0].tenderDescription || tenderType.description);
      }

      /*
      if (tender && tender.tenderType.type == Tender_Type.Cash){
        systemTotalCtrl.setValue((payment ? payment.reduce((row, current) => row + parseFloat(current.systemTotal), 0) : 0) + cashout.totalOpeningFloat);
        this.onCashActualAmountChanged = breakdownForm.get('actualAmount').valueChanges.pipe(
          map(amount => parseFloat(amount + '')),
          distinctUntilChanged(),
        );
      }
      */
    }
  }

  get breakdownSystemTotal() {
    const initialVal = 0;
    if (!this.breakdownComponents) return initialVal;

    return this.breakdownComponents.reduce<number>((accumulator, breakdownComponent) => {
      return accumulator + breakdownComponent.systemAmount;
    }, initialVal);
  }

  get breakdownActualTotal() {
    const initialVal = 0;
    if (!this.breakdownComponents) return initialVal;

    return this.breakdownComponents.reduce<number>((accumulator, breakdownComponent) => {
      return accumulator + (breakdownComponent.actualAmount || 0);
    }, initialVal);
  }

  get breakdownOverUnderTotal() {
    const initialVal = 0;
    if (!this.breakdownComponents) return initialVal;

    return this.breakdownComponents.reduce<number>((accumulator, breakdownComponent) => {
      return accumulator + breakdownComponent.overUnderAmount;
    }, initialVal);
  }

  /*
  get systemBreakdownSystemTotal(){
    const initialVal = 0;
    if (!this.breakdownComponents) return initialVal;

    return this.breakdownComponents.filter(({isIntegrated})=> isIntegrated === false).reduce<number>((accumulator, breakdownComponent) => {
      return accumulator + breakdownComponent.systemAmount;
    }, initialVal);
  }

  get systemBreakdownActualTotal(){
    const initialVal = 0;
    if (!this.breakdownComponents) return initialVal;

    return this.breakdownComponents.filter(({isIntegrated})=> isIntegrated === false).reduce<number>((accumulator, breakdownComponent) => {
      return accumulator + (breakdownComponent.actualAmount || 0);
    }, initialVal);
  }

  get systemBreakdownOverUnderTotal(){
    const initialVal = 0;
    if (!this.breakdownComponents) return initialVal;

    return this.breakdownComponents.filter(({isIntegrated})=> isIntegrated === false).reduce<number>((accumulator, breakdownComponent) => {
      return accumulator + breakdownComponent.overUnderAmount;
    }, initialVal);
  }

  get integratedBreakdownSystemTotal(){
    const initialVal = 0;
    if (!this.breakdownComponents) return initialVal;

    return this.breakdownComponents.filter(({isIntegrated})=> isIntegrated === true).reduce<number>((accumulator, breakdownComponent) => {
      return accumulator + breakdownComponent.systemAmount;
    }, initialVal);
  }

  get integratedBreakdownActualTotal(){
    const initialVal = 0;
    if (!this.breakdownComponents) return initialVal;

    return this.breakdownComponents.filter(({isIntegrated})=> isIntegrated === true).reduce<number>((accumulator, breakdownComponent) => {
      return accumulator + (breakdownComponent.actualAmount || 0);
    }, initialVal);
  }

  get integratedBreakdownOverUnderTotal(){
    const initialVal = 0;
    if (!this.breakdownComponents) return initialVal;

    return this.breakdownComponents.filter(({isIntegrated})=> isIntegrated === true).reduce<number>((accumulator, breakdownComponent) => {
      return accumulator + breakdownComponent.overUnderAmount;
    }, initialVal);
  }
  */

  // get showSystemColumn(){
  //   return this._cashoutSettings && this._cashoutSettings.showSystemValue;
  // }

  get showOverUnderCol() {
    return this._cashoutSettings && this._cashoutSettings.showOverUnderValue;
  }

  onTransactionsDetails(paymentBreakdownIndex) {
    const activeBreakdownForm = this.closingBreakdownArray.at(paymentBreakdownIndex);
    const tenderDescField = activeBreakdownForm.get("tenderDescription") || activeBreakdownForm.get("tenderTypeId");
    this.dialogService.open(CashoutTenderTransactionsComponent, {
      data: {
        cashoutId: this.cashout.id,
        tenderTypeId: activeBreakdownForm.get("tenderTypeId").value,
        headerTitle: `Sale Documents (${tenderDescField.value})`,
      },
      // header: 'SALES DOCS (-)', // TODO this should be commented out when Paymentbreakdown API error is solved
      // header: `SALES DOCS (${tenderDescField.value})`,
      width: "85%",
      height: "fit-content",
    });
  }

  onColSettleBtnClicked(terminal: PaymentTerminal) {
    this._settleBtnRunning[terminal.id] = true;
    // this._settleBtnRunning = true;
    if (terminal.isPaired) {
      let paymentService;
      switch (terminal.paymentGateway.paymentGatewayType) {
        case PaymentGatewayType.PAYROC:
          paymentService = this.econduitService;
          break;
        case PaymentGatewayType.MONERIS:
          paymentService = this.monerisService;
          break;
      }
      // // using dummy data to test dialog opening
      // const dummyResponse = {
      //   TxnName: "BatchClose",
      //   TransType: '65',
      //   TransTime: '14:52:23',
      //   TransDate: '22-04-13',
      //   TerminalId: '66022529',
      //   ResponseCode: '007',
      //   totalsByCardType: {
      //     "Amex": {
      //       DeviceCorrectionCount: "0000",
      //       DeviceCorrectionTotal: "+0.00",
      //       DeviceCreditCount: "0000",
      //       DevicePaymentCorrectionCount: "0000",
      //       DevicePaymentCorrectionTotal: "+0.00",
      //       DevicePaymentCount: "0000",
      //       DevicePaymentTotal: "+0.00",
      //       DevicePurchaseCount: "0001",
      //       DevicePurchaseTotal: "+15.00",
      //       DeviceRefundCount: "0000",
      //       DeviceRefundTotal: "+0.00",
      //       HostCorrectionCount: "0000",
      //       HostCorrectionTotal: "+0.00",
      //       HostCreditCount: "0000",
      //       HostPaymentCorrectionCount: "0000",
      //       HostPaymentCorrectionTotal: "+0.00",
      //       HostPaymentCount: "0000",
      //       HostPaymentTotal: "+0.00",
      //       HostPurchaseCount: "0001",
      //       HostPurchaseTotal: "+15.00",
      //       HostRefundCount: "0000",
      //       HostRefundTotal: "+0.00",
      //     },
      //     "VISA": {
      //       DeviceCorrectionCount: "0000",
      //       DeviceCorrectionTotal: "+0.00",
      //       DeviceCreditCount: "0000",
      //       DevicePaymentCorrectionCount: "0000",
      //       DevicePaymentCorrectionTotal: "+0.00",
      //       DevicePaymentCount: "0000",
      //       DevicePaymentTotal: "+0.00",
      //       DevicePurchaseCount: "0001",
      //       DevicePurchaseTotal: "+15.00",
      //       DeviceRefundCount: "0000",
      //       DeviceRefundTotal: "+0.00",
      //       HostCorrectionCount: "0000",
      //       HostCorrectionTotal: "+0.00",
      //       HostCreditCount: "0000",
      //       HostPaymentCorrectionCount: "0000",
      //       HostPaymentCorrectionTotal: "+0.00",
      //       HostPaymentCount: "0000",
      //       HostPaymentTotal: "+0.00",
      //       HostPurchaseCount: "0001",
      //       HostPurchaseTotal: "+15.00",
      //       HostRefundCount: "0000",
      //       HostRefundTotal: "+0.00",
      //     },
      //     Totals: {
      //       BatchNumber: "123",
      //       ConditionCode: "006",
      //       DeviceCorrectionCount: "0000",
      //       DeviceCorrectionTotal: "+0.00",
      //       DeviceCreditCount: "0000",
      //       DevicePaymentCorrectionCount: "0000",
      //       DevicePaymentCorrectionTotal: "+0.00",
      //       DevicePaymentCount: "0000",
      //       DevicePaymentTotal: "+0.00",
      //       DevicePurchaseCount: "0001",
      //       DevicePurchaseTotal: "+15.00",
      //       DeviceRefundCount: "0000",
      //       DeviceRefundTotal: "+0.00",
      //       HostCorrectionCount: "0000",
      //       HostCorrectionTotal: "+0.00",
      //       HostCreditCount: "0000",
      //       HostPaymentCorrectionCount: "0000",
      //       HostPaymentCorrectionTotal: "+0.00",
      //       HostPaymentCount: "0000",
      //       HostPaymentTotal: "+0.00",
      //       HostPurchaseCount: "0001",
      //       HostPurchaseTotal: "+15.00",
      //       HostRefundCount: "0000",
      //       HostRefundTotal: "+0.00",
      //     },
      //   }
      // }
      // this.ref = this.dialogService.open(BatchCloseDetailsDialogComponent, {
      //   width: '90%',
      //   height: '90%',
      //   showHeader: false,
      //   closable: false,
      //   data: {
      //     _detailsObject: dummyResponse,
      //     _terminal: terminal
      //   }
      // });

      if (paymentService) {
        paymentService.closeBatch(terminal.id).subscribe({
          next: (response) => {
            //             006 – Batch Close was completed and is in balance.
            // 007 – Batch Close was completed and is out of balance
            this._settleBtnRunning[terminal.id] = false;
            // this._settleBtnRunning = false;

            const responseCloseBatch: ResponseCloseBatch = Object.assign({}, response);

            if (
              terminal.paymentGateway.paymentGatewayType === PaymentGatewayType.PAYROC &&
              response &&
              responseCloseBatch.RefID !== null &&
              responseCloseBatch.MessageCredit === paymentService.sMessageCloseBatchApproved
            ) {
              this.messageService.add(this.alertMessagesService.getMessage("econduit-close-batch-success"));
            } else if (
              terminal.paymentGateway.paymentGatewayType === PaymentGatewayType.MONERIS &&
              response &&
              (responseCloseBatch["totalsByCardType"].Totals.ConditionCode === "006" ||
                responseCloseBatch["totalsByCardType"].Totals.ConditionCode === "007")
            ) {
              if (responseCloseBatch["totalsByCardType"].Totals.ConditionCode === "006") {
                this.messageService.add(this.alertMessagesService.getMessage("moneris-close-batch-successful"));
                this.ref = this.dialogService.open(BatchCloseDetailsDialogComponent, {
                  width: "90%",
                  height: "90%",
                  showHeader: false,
                  closable: false,
                  data: {
                    _detailsObject: response,
                    _terminal: terminal,
                  },
                });
                // this.ref.onClose.subscribe(() => {
                //   console.log("batch details dialog is closed!");
                // });
              }
              if (responseCloseBatch["totalsByCardType"].Totals.ConditionCode === "007") {
                this.messageService.add(this.alertMessagesService.getMessage("moneris-close-batch-out-of-balance"));
              }
            } else {
              this.messageService.add({
                summary: "Error",
                severity: "error",
                detail: `${responseCloseBatch.MessageCredit}`,
                life: 6000,
              });
            }
          },
          error: (errorResponse) => {
            if (errorResponse instanceof HttpErrorResponse && errorResponse.status !== 500) {
              if (errorResponse.error.error && errorResponse.error.error.message) {
                const errorMsg = <string>errorResponse.error.error.message;
                this.messageService.add(
                  this.alertMessagesService.getErrorMessage(
                    null,
                    "ERROR",
                    `${errorMsg}\nPlease make another VERIFICATION REQUEST.`
                  )
                );
              } else {
                const errorMsg = errorResponse.message;
                this.messageService.add(
                  this.alertMessagesService.getErrorMessage(
                    null,
                    "ERROR",
                    `${errorMsg}\nPlease make another VERIFICATION REQUEST.`
                  )
                );
              }
            } else {
              this.messageService.add(this.alertMessagesService.getErrorMessage(errorResponse));
            }
            this._settleBtnRunning[terminal.id] = false;
            // this._settleBtnRunning = false;
          },
        });
      }
    } else {
      this.messageService.add(this.alertMessagesService.getMessage("econduit-close-batch-need-pair-terminal"));
    }
  }

  onColOpenTotalBtnClicked(terminal: PaymentTerminal) {
    this._settleBtnRunning[terminal.id] = true;
    // this._settleBtnRunning = true;
    if (terminal.isPaired) {
      let paymentService;
      switch (terminal.paymentGateway.paymentGatewayType) {
        case PaymentGatewayType.PAYROC:
          paymentService = this.econduitService;
          break;
        case PaymentGatewayType.MONERIS:
          paymentService = this.monerisService;
          break;
      }
      if (paymentService) {
        paymentService.openTotal(terminal.id).subscribe({
          next: (response) => {
            //             006 – Batch Close was completed and is in balance.
            // 007 – Batch Close was completed and is out of balance
            this._settleBtnRunning[terminal.id] = false;
            // this._settleBtnRunning = false;

            const responseCloseBatch: ResponseCloseBatch = Object.assign({}, response);
            if (
              terminal.paymentGateway.paymentGatewayType === PaymentGatewayType.PAYROC &&
              response &&
              responseCloseBatch.RefID !== null &&
              responseCloseBatch.MessageCredit === paymentService.sMessageCloseBatchApproved
            ) {
              this.messageService.add(this.alertMessagesService.getMessage("econduit-close-batch-success"));
            } else if (terminal.paymentGateway.paymentGatewayType === PaymentGatewayType.MONERIS && response) {
              this.ref = this.dialogService.open(MonerisOpenTotalDetailsDialogComponent, {
                width: "90%",
                height: "90%",
                showHeader: false,
                closable: false,
                data: {
                  _detailsObject: response,
                  _terminal: terminal,
                },
              });
            } else {
              this.messageService.add({
                summary: "Error",
                severity: "error",
                detail: `${responseCloseBatch.MessageCredit}`,
                life: 6000,
              });
            }
          },
          error: (errorResponse) => {
            if (errorResponse instanceof HttpErrorResponse && errorResponse.status !== 500) {
              if (errorResponse.error.error && errorResponse.error.error.message) {
                const errorMsg = <string>errorResponse.error.error.message;
                this.messageService.add(
                  this.alertMessagesService.getErrorMessage(
                    null,
                    "ERROR",
                    `${errorMsg}\nPlease make another VERIFICATION REQUEST.`
                  )
                );
              } else {
                const errorMsg = errorResponse.message;
                this.messageService.add(
                  this.alertMessagesService.getErrorMessage(
                    null,
                    "ERROR",
                    `${errorMsg}\nPlease make another VERIFICATION REQUEST.`
                  )
                );
              }
            } else {
              this.messageService.add(this.alertMessagesService.getErrorMessage(errorResponse));
            }
            this._settleBtnRunning[terminal.id] = false;
            // this._settleBtnRunning = false;
          },
        });
      }
    } else {
      this.messageService.add(this.alertMessagesService.getMessage("econduit-close-batch-need-pair-terminal"));
    }
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
