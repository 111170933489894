/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import {
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  pairwise,
  startWith,
  switchMap,
  tap,
} from "rxjs/operators";
import { Location } from "@angular/common";
import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  TemplateRef,
  HostListener,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from "lodash";
import { ConfirmationService, MenuItem, MessageService, SelectItem } from "primeng/api";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { Observable, forkJoin, of } from "rxjs";
import type { Stock } from "src/app/core/settings/business-settings/stock/stock";
import type { ExtendedSelectItem, ExtendedSelectItemGroup } from "src/app/shared/services/db.service";
import { FilterRows, FormDataHelpers } from "src/app/utility/FormDataHelpers";
import { TimeHelpers } from "src/app/utility/TimeHelpers";
import { Col, FormListExportFormat, RowSelectionType } from "../../../../form-list/form-list/form-list";
import { FormListComponent } from "../../../../form-list/form-list/form-list.component";
import { GenericFormComponent } from "../../../../forms/generic-form/generic-form.component";
import { AppSettingsStorageService } from "../../../../shared/app-settings-storage.service";
import { AlertMessagesService } from "../../../../shared/services/alert-messages.service";
import { LocalDataService } from "../../../../shared/services/local-data.service";
import { SearchResultItem } from "../../../../taku-ui/taku-search-accounts/SearchResultItem";
import { TakuSearchAccountsComponent } from "../../../../taku-ui/taku-search-accounts/taku-search-accounts.component";
import { TakuSearchInventoryComponent } from "../../../../taku-ui/taku-search-inventory/taku-search-inventory.component";
import { Account, AccountRelationship, AccountType } from "../../../contact-accounts/account/account";
import { CommercialAccount } from "../../../contact-accounts/commercial-account/commercial-account";
import { Inventory } from "../../../inventory/inventory/inventory";
import { DocState } from "../../doc/doc";
import { SelfCheckoutItemDetailsComponent } from "../../sale-document/self-checkout-item-details/self-checkout-item-details.component";

import { DocLine } from "../../doc-line/doc-line";

import { InventorySupplierListService } from "../../inventory-document/inventory-document/inventory-supplier-list.service";
import { PurchaseDoc, PurchaseDocAccount, PurchaseDocLine } from "./purchase-document";
import { PaymentTerm } from "src/app/core/purchase-order-term/payment-term/payment-term";
import { ShippingTerm } from "src/app/core/purchase-order-term/shipping-term/shipping-term";
import { Address, AddressType } from "src/app/core/contact-accounts/address/address";
import { CommercialAccountAddress } from "src/app/core/contact-accounts/commercial-account-address/commercial-account-address";
import { AddressComponent } from "src/app/core/contact-accounts/address/address.component";
import { TakuInputComponent } from "src/app/taku-ui/taku-input/taku-input.component";
import { MonetaryHelpers } from "src/app/utility/MonetaryHelpers";
import { DiscountType } from "../../sale-document/sale-doc/sale-doc";
import { TotalBySaleTax } from "../../sale-document/sale-doc-line/sale-doc-line";
import { Person } from "src/app/core/contact-accounts/person/person";
import { Contact } from "src/app/core/contact-accounts/contact/contact";
import { AddressPhone } from "src/app/core/contact-accounts/address-phone/address-phone";
import { AddressEmail } from "src/app/core/contact-accounts/address-email/address-email";
import { CommercialAccountComponent } from "src/app/core/contact-accounts/commercial-account/commercial-account.component";
import { PurchaseDocService } from "../purchase-doc-list/purchase-doc.service";
import {
  InventorySupplierProductDetail,
  PackSize,
} from "src/app/core/inventory/inventory-supplier-product-detail/inventory-supplier-product-detail";
import { InventorySupplier } from "src/app/core/inventory/inventory-supplier/inventory-supplier";
import { PrintingFactoryService } from "src/app/shared/services/printing-service.service";
import { Store } from "src/app/core/settings/store-settings/store/store";
import { BackOffice } from "src/app/core/settings/backOffice-settings/backOffice/backOffice";
import { BusinessDetail } from "src/app/core/settings/business-settings/business-detail/business-detail";
import { FormDialogInputComponent } from "src/app/forms/form-dialog-input/form-dialog-input.component";
import { TabMenu } from "primeng/tabmenu";

enum FullSizeDialogName {
  COMMERCIAL_ACCOUNT = "commercialAccount",
  PERSONAL_ACCOUNT = "personalAccount",
  NEW_INVENTORY = "newInventory",
  EDIT_INVENTORY = "editInventory",
}

type PurchaseDocLineFormListRow = { _row_local_id: string } & PurchaseDocLine;

@Component({
  selector: "taku-purchase-document",
  templateUrl: "./purchase-document.component.html",
  styleUrls: ["./purchase-document.component.scss"],
  providers: [LocalDataService, InventorySupplierListService],
})
export class PurchaseDocumentComponent extends GenericFormComponent implements OnInit {
  @ViewChild("formListInventory") formListInventory: FormListComponent;
  @ViewChild("accountsSearchBox") accountSearchComponent: TakuSearchAccountsComponent;
  @ViewChild("inventorySearchBox") inventorySearchComponent: TakuSearchInventoryComponent;
  @ViewChild("commercialAccountDialogForm") commercialAccountDialogForm: CommercialAccountComponent;
  @ViewChild("skuTpl", { static: true }) skuTpl: TemplateRef<any>;
  @ViewChild("generalDiscountAmount") discAmountInputComponent: TakuInputComponent;
  @ViewChild("generalShippingAmount") shippingAmountInputComponent: TakuInputComponent;
  @ViewChild("printPreviewHost", { read: ViewContainerRef, static: true }) printPreviewHost: ViewContainerRef;
  @ViewChild("supplierInventoryFormListModal") supplierInventoryFormListModal: FormListComponent;
  discountForm: UntypedFormGroup;
  shippingForm: UntypedFormGroup;
  isSavingDraft = false;
  isFinalizing = false;
  isSendingEmail = false;
  todayDate = new Date();

  private _openInventoryEvent: EventEmitter<PurchaseDocLine> = new EventEmitter();
  _pageTitle: string;
  _defaultRowValues = {};
  _formFilters = {};
  purchaseDocLineCols: Col[];
  AccountType = AccountType;
  AccountRelationship = AccountRelationship;

  enumDiscountType: SelectItem[] = [
    { label: "%", value: DiscountType.Percentage },
    { label: "$", value: DiscountType.Fixed },
  ];
  _overlaysVisibility: { [key: string]: boolean } = {
    discount: false,
    shipping: false,
    taxes: false,
  };

  $lookup_billToLocations: Observable<ExtendedSelectItemGroup<string, Store | BackOffice>[]>;
  $lookup_paymentTerms: Observable<ExtendedSelectItem<number, PaymentTerm>[]>;
  $lookup_shippingTerms: Observable<ExtendedSelectItem<number, ShippingTerm>[]>;

  @ViewChild("supplierDialogTabMenu") supplierDialogTabMenu: TabMenu;
  private _activeSupplierDialogTabId = "lowStock"; // Default to "lowStock" tab item. Changes on Tab menu change
  private onSupplierDialogTabMenuChange = (event?: any) => {
    if (this._activeSupplierDialogTabId === event.item.id) return;
    this._activeSupplierDialogTabId = event.item.id;
    this.initShowFullSupplierFormList();
  };
  readonly supplierDialogTabMenuItems: MenuItem[] = [
    { id: "all", label: "All", command: this.onSupplierDialogTabMenuChange },
    { id: "lowStock", label: "Low Stock Only", command: this.onSupplierDialogTabMenuChange },
  ];

  readonly _moneyFormat = "1.2";
  _decimalFormatting = "1.2-2";
  DocState = DocState;
  showStockAddressEditDialog = false;
  showEmailHistoryDialog = false;
  showFullSupplierListDialog = false;
  _showFullSupllierFormListCols: Col[];
  _showFullSupllierFormListRules: {};
  _isShowFullSupllierFormListDataReady = false;
  showFullSupllierFormListNoChildrenFilter: FilterRows<Inventory>;
  fullSupplierFormListExtraQueryParams: Record<string, any>;
  RowSelectionType = RowSelectionType;
  FullSizeDialogName = FullSizeDialogName;
  _activeFullDialog: FullSizeDialogName;
  _activeFullDialogExtra: Inventory | CommercialAccount = null;
  _isPrintLoading = false;
  _docLineOpened: any;
  shippingAddressOptions: ExtendedSelectItem<number, Address>[] = [];
  shippingAddressOptionsById: Record<number, Address> = {};
  shippingContactOptions: ExtendedSelectItem<number, Person>[] = [];
  shippingContactOptionsById: Record<number, Person> = {};
  stockOptions: ExtendedSelectItem<number, Stock>[];
  stockOptionsById: Record<number, Stock> = {};
  purchaseDocTotalTax = 0;
  groupedTaxes: TotalBySaleTax[] = [];
  docStateChangedFromDraftToFinalizedToFinalized = false;
  totalItems: number;
  tempStockAddress: Record<string, any>;
  searchInventoryItemsFilter = {
    isVariantParent: { matchMode: "equals", value: false },
    isBuyable: { matchMode: "equals", value: true },
    isEnabled: { matchMode: "equals", value: true },
  };

  shippingDateForm: ReturnType<typeof this.getNewShippingDateForm>;

  get isReadOnly() {
    return (
      this.docStateCtrl?.value === DocState.approved ||
      this.docStateCtrl?.value === DocState.finalized ||
      this.appSettingsService.isAdminLogin()
    );
  }

  get _searchVendorPlaceholder() {
    return FormDataHelpers.InputFieldCaption(`Supplier`, this.accountIdCtrl);
  }

  constructor(
    public inventorySupplierListService: InventorySupplierListService,
    protected _router: Router,
    public fb: UntypedFormBuilder,
    public dbService: PurchaseDocService,
    protected location: Location,
    public _route: ActivatedRoute,
    protected messageService: MessageService,
    protected alertMessage: AlertMessagesService,
    public localDataService: LocalDataService,
    protected confirmationService: ConfirmationService,
    appSettings: AppSettingsStorageService,
    public ref: DynamicDialogRef,
    public elRef: ElementRef,
    public dialogService: DialogService,
    private printingFactoryService: PrintingFactoryService
  ) {
    super(_router, fb, dbService, location, _route, messageService, alertMessage, confirmationService, appSettings);
  }

  private getNewShippingDateForm() {
    const form = new FormGroup({
      shippingDay: new FormControl<string>(null),
      shippingTime: new FormControl<string>(null),
    });
    this.subsList.push(
      form.valueChanges
        .pipe(
          filter(
            ({ shippingDay, shippingTime }) => !this.isReadOnly && !this._isSaving && !!shippingDay && !!shippingTime
          ),
          map(({ shippingDay, shippingTime }) =>
            TimeHelpers.UnifyDateAndTime(shippingDay, shippingTime, false).toISOString()
          ),
          filter((dateString) => dateString !== this._myForm.controls.shippingDate.value)
        )
        .subscribe((dateString) => {
          this._myForm.controls.shippingDate.setValue(dateString);
          this._myForm.controls.shippingDate.markAsDirty();
        })
    );
    return form;
  }

  private setNewShippingDateForm() {
    if (this.shippingDateForm) {
      this.shippingDateForm.reset();
    } else {
      this.shippingDateForm = this.getNewShippingDateForm();
    }
  }

  protected resetForm(): void {
    super.resetForm();

    this.syncShippingDate(this._myForm.controls.shippingDate.value);

    this._myForm.controls.locationId.setValue(this.getLocationId());

    if (this.docStateCtrl?.value === DocState.finalized) {
      const newClonedShippingAddress = this._myForm.controls.shippingAddress.value as Address;
      const newClonedShippingContact = this._myForm.controls.shippingContact.value as Person;

      if (newClonedShippingAddress?.id) {
        this.shippingAddressOptions = [
          {
            label: newClonedShippingAddress.line1,
            value: newClonedShippingAddress.id,
            object: newClonedShippingAddress,
          },
        ];
        this.shippingAddressOptionsById = { [newClonedShippingAddress.id]: newClonedShippingAddress };
      } else {
        this.shippingAddressOptions = [
          {
            label: "",
            value: null,
          },
        ];
        this.shippingAddressOptionsById = {};
      }

      if (newClonedShippingContact?.id) {
        this.shippingContactOptions = [
          {
            label: `${newClonedShippingContact.firstName} ${
              newClonedShippingContact.middleName ? newClonedShippingContact.middleName + " " : ""
            }${newClonedShippingContact.lastName}`.trim(),
            value: newClonedShippingContact.id,
            object: newClonedShippingContact,
          },
        ];
        this.shippingContactOptionsById = { [newClonedShippingContact.id]: newClonedShippingContact };
      } else {
        this.shippingContactOptions = [
          {
            label: "",
            value: null,
          },
        ];
        this.shippingContactOptionsById = {};
      }
    }

    this.discountForm = this.fb.group({
      discountType: this._myForm.get("discountType").value,
      discountAmount: this._myForm.get("discountAmount").value,
    });
    this.shippingForm = this.fb.group({
      shipperChargeAmount: this._myForm.get("shipperChargeAmount").value,
    });

    if (this.formListInventory) {
      this.formListInventory.clearAllRows();
      this.populateInventoryList();
      setTimeout(() => {
        this._myForm.markAsPristine(); // calling the two methods above makes the _myForm dirty.
      }, 500);
    }
  }

  onSelectionChange(selectedItem: ExtendedSelectItem<string>): void {
    // Optionally, if you need to update the form control based on this new field
    if (!selectedItem) {
      this._myForm.get("storeId").setValue(null);
      this._myForm.get("backOfficeId").setValue(null);
    } else {
      if (selectedItem.value[0] === "s") {
        this._myForm.get("storeId").setValue(Number(selectedItem.value.substring(1)));
        this._myForm.get("backOfficeId").setValue(null);
      }
      if (selectedItem.value[0] === "b") {
        this._myForm.get("backOfficeId").setValue(Number(selectedItem.value.substring(1)));
        this._myForm.get("storeId").setValue(null);
      }
    }
  }

  updatePurchaseDocTotalTax(): void {
    // TODO: This method should be revised with proper taxing system
    // let totalTax = 0;
    // for (var _i = 0; _i < this.saleDocLines.length; _i++) {
    //   const saleDocLine:SaleDocLine = SaleDocLine.convertIntoTypedObject( this.saleDocLines.at(_i).value );
    //   totalTax += saleDocLine.totalTaxes;
    // }
    // this.invoiceTotalTax = totalTax;
    this.attachFormObjects();
    this.groupedTaxes = PurchaseDoc.convertIntoTypedObject(this._myForm.value).groupedTaxLines;
    this.purchaseDocTotalTax = _.sumBy(this.groupedTaxes, (tax) => tax.total);
  }

  get maxDiscountAmount(): number {
    switch (this._myForm.get("discountType").value) {
      case DiscountType.Fixed:
        return this.purchaseDocSubTotal;

      case DiscountType.Percentage:
        return 100;
    }
  }

  sendEmail(): void {
    let defaultEmail = "";
    const contacts = this._myForm.get("account.commercialAccount.contacts").getRawValue();
    if (contacts?.length) {
      const defaultContact = contacts.find((contact) => contact.isDefault);
      if (defaultContact?.person?.personEmails?.length) {
        defaultEmail = defaultContact.person.personEmails[0].email;
      } else if (contacts[0]?.person?.personEmails?.length) {
        defaultEmail = contacts[0].person.personEmails[0].email;
      }
    }
    if (!defaultEmail) {
      const accEmail = this._myForm.get("account.commercialAccount.commercialAccountEmails").getRawValue()?.[0]?.email;
      if (accEmail) defaultEmail = accEmail;
    }

    const dialogRef = this.dialogService.open(FormDialogInputComponent, {
      showHeader: true,
      header: "Enter email address",
      data: {
        caption: "Email Address",
        message: "",
        acceptLabel: "Send Email",
        inputType: "email",
        required: true,
        defaultValue: defaultEmail,
        // pattern:'^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'
        // rejectVisible: false,
      },
    });
    this.subsList.push(
      dialogRef.onClose.subscribe((emailAddress) => {
        if (emailAddress) {
          this.isSendingEmail = true;
          this.subsList.push(
            this.getPurchaseDocForPrinting()
              .pipe(
                switchMap((purchaseDoc) => {
                  return this.printingFactoryService
                    .build(this.appSettingsService.getStoreId())
                    .sendPurchaseDocEmail(purchaseDoc, emailAddress, this.printPreviewHost);
                }),
                finalize(() => (this.isSendingEmail = false))
              )
              .subscribe((printResponse) => {
                if (printResponse.success) {
                  this._myForm.patchValue({ emailHistory: printResponse.emailHistory }, { emitEvent: false });
                  this._myForm.markAsPristine();
                  this._object.emailHistory = printResponse.emailHistory;
                }
              })
          );
        }
      })
    );
  }

  onDiscountApplyClick(): void {
    const discountType = this.discountForm.get("discountType").value;
    const discountAmount = +this.discountForm.get("discountAmount").value;

    this._myForm.get("discountType").setValue(discountType);
    this._myForm.get("discountAmount").setValue(discountAmount);

    const prev = _.cloneDeep(this.formListInventory._objects);
    const objects = this.formListInventory._objects;

    objects.forEach((object) => {
      object.allocatedDiscountAmount = 0;
    });

    if (discountType === DiscountType.Percentage) {
      objects.forEach((object) => {
        object.allocatedDiscountType = DiscountType.Percentage;
        object.allocatedDiscountAmount = discountAmount;
      });
    } else if (discountType === DiscountType.Fixed) {
      let summedSubTotals = 0;
      let summedTotalQtys = 0;

      const objectCount = objects.length;

      objects.forEach((object) => {
        summedSubTotals += object.subTotal;
        summedTotalQtys += object.totalQty;
      });

      if (summedSubTotals === 0 && summedTotalQtys === 0) {
        const equalShare = MonetaryHelpers.roundToDecimalPlaces(discountAmount / objectCount);
        objects.forEach((object) => {
          object.allocatedDiscountType = DiscountType.Fixed;
          object.allocatedDiscountAmount = equalShare;
        });
      } else {
        const divisor = summedSubTotals > 0 ? summedSubTotals : summedTotalQtys;
        const field = summedSubTotals > 0 ? "subTotal" : "totalQty";

        objects.forEach((object) => {
          object.allocatedDiscountType = DiscountType.Fixed;
          object.allocatedDiscountAmount = MonetaryHelpers.roundToDecimalPlaces(
            (discountAmount * object[field]) / divisor
          );
        });
      }
    }

    this.purchaseDocLineformListTaxDataChanged(prev);
    this._myForm.markAsDirty();
  }

  onNumpadShippingAmount(amount): void {
    this.shippingForm.get("shipperChargeAmount").setValue(amount);
  }

  onShippingApplyClick(): void {
    const shipperChargeAmount = +this.shippingForm.get("shipperChargeAmount").value;
    this._myForm.get("shipperChargeAmount").setValue(shipperChargeAmount);

    let summedSubTotals = 0;
    let summedTotalQtys = 0;

    const objects = this.formListInventory._objects;
    const objectCount = objects.length;

    objects.forEach((object) => {
      summedSubTotals += object.subTotal;
      summedTotalQtys += object.totalQty;
    });

    if (summedSubTotals === 0 && summedTotalQtys === 0) {
      const equalShare = MonetaryHelpers.roundToDecimalPlaces(shipperChargeAmount / objectCount);
      objects.forEach((object) => {
        object.allocatedShipperChargeAmount = equalShare;
      });
    } else {
      const divisor = summedSubTotals > 0 ? summedSubTotals : summedTotalQtys;
      const field = summedSubTotals > 0 ? "subTotal" : "totalQty";

      objects.forEach((object) => {
        object.allocatedShipperChargeAmount = MonetaryHelpers.roundToDecimalPlaces(
          (shipperChargeAmount * object[field]) / divisor
        );
      });
    }

    this._myForm.markAsDirty();
  }

  onNumpadDiscountAmount(amount) {
    this.discountForm.get("discountAmount").setValue(amount);
  }

  onDocLineDeleted(): void {
    this._updateForm();
    this._object.purchaseDocLines = this.formListInventory.getObjects();
    this._myForm.markAsDirty();
  }

  updatePurchaseLineTax(purchaseDocLine: PurchaseDocLine): Observable<any> {
    const purchaseDoc: PurchaseDoc = this._myForm.getRawValue();

    return this.dbService.updatePurchaseTaxesInLineForm(purchaseDocLine, purchaseDoc);
  }

  initForm(): void {
    this._model = "purchaseDoc";

    this.setNewShippingDateForm();

    const purchaseDoc = new PurchaseDoc(null, null, this.dbService.getDefaultZone());
    const [currentDate, currentTime] = TimeHelpers.SplitDateAndTime(new Date());

    purchaseDoc.doc.docDate = currentDate;
    purchaseDoc.doc.docTime = currentTime;

    this._object = purchaseDoc;
    // Assign default inventory doc fields
    _.merge(this._object, {
      doc: {
        userId: this.dbService.getCurrentUserId(),
      },
    });

    // Merge query parameters from the route into _object
    const queryParams = this._route.snapshot.queryParams;

    if (queryParams.defaultdataValues) {
      try {
        const parsedQueryParamData = JSON.parse(queryParams.defaultdataValues);

        const commercialAccountId = parsedQueryParamData.commercialAccountId;

        this.subsList.push(
          this.appSettingsService
            .getBusinessDetails()
            .pipe(
              switchMap((businessDetail: BusinessDetail) => {
                const defaultCurrencyIsoCode = businessDetail.defaultCurrencyIsoCode;
                return this.dbService.getRow("commercialAccount", commercialAccountId).pipe(
                  tap((commercialAccount: CommercialAccount) => {
                    const data = {};
                    if (commercialAccount.hasOwnProperty("account")) {
                      data["accountId"] = commercialAccount.account.id;
                      data["account"] = _.pick(commercialAccount.account, _.keys(new Account())) as PurchaseDocAccount;
                      data["account"]["commercialAccount"] = _.pick(
                        commercialAccount,
                        _.keys(new CommercialAccount(defaultCurrencyIsoCode))
                      );
                    }
                    _.merge(data, parsedQueryParamData);
                    _.merge(this._object, data);

                    this.initMyFormProp();
                    this.initFieldsExtraData();
                  })
                );
              })
            )
            .subscribe()
        );
      } catch (error) {
        console.error("Error parsing defaultdataValues from query parameters", error);
      }
    } else {
      this.initMyFormProp();
    }
  }

  ngOnInit(): void {
    if (!this._route.snapshot.queryParams.defaultdataValues) {
      this.initFieldsExtraData();
    }
  }

  private getLocationId(): string | null {
    if (this._object.storeId) {
      return "s" + String(this._object.storeId);
    } else if (this._object.backOfficeId) {
      return "b" + String(this._object.backOfficeId);
    } else if (!this.isReadOnly) {
      if (this.appSettingsService.getStoreId()) {
        return "s" + String(this.appSettingsService.getStoreId());
      } else if (this.appSettingsService.getBackofficeId()) {
        return "b" + String(this.appSettingsService.getBackofficeId());
      }
    }
    return null;
  }

  private initMyFormProp() {
    const locationId = this.getLocationId();
    this._myForm = PurchaseDocumentComponent.init(this.fb, this._object, locationId);
    this.syncShippingDate(this._object.shippingDate);

    this.discountForm = this.fb.group({
      discountType: this._myForm.get("discountType").value,
      discountAmount: this._myForm.get("discountAmount").value,
    });
    this.shippingForm = this.fb.group({
      shipperChargeAmount: this._myForm.get("shipperChargeAmount").value,
    });

    this.subsList.push(
      this._myForm.controls.locationId.valueChanges.subscribe((locationId: string) => {
        // Optionally, if you need to update the form control based on this new field
        if (!locationId) {
          this._myForm.get("storeId").setValue(null);
          this._myForm.get("backOfficeId").setValue(null);
        } else {
          if (locationId[0] === "s") {
            this._myForm.get("storeId").setValue(Number(locationId.substring(1)));
            this._myForm.get("backOfficeId").setValue(null);
          }
          if (locationId[0] === "b") {
            this._myForm.get("backOfficeId").setValue(Number(locationId.substring(1)));
            this._myForm.get("storeId").setValue(null);
          }
        }
      })
    );

    this.subsList.push(
      this.changedForm.subscribe((purDoc: PurchaseDoc) => {
        if (!this.formListInventory) return;

        if (this.isReadOnly) this.formListInventory.disableEditMode();
        else this.formListInventory.enableEditMode();
      })
    );
  }

  private initFieldsExtraData() {
    this.subsList.push(
      this._route.data.subscribe((routeData) => {
        this._pageTitle = routeData.pageTitle || "";

        if (routeData.docType) this._object.doc.docType = routeData.docType;

        if (routeData.stockAdjustmentType) this._object.stockAdjustmentType = routeData.stockAdjustmentType;

        this.resetForm();

        // call parent's method
        super.ngOnInit();
      })
    );

    // Update currency symbol in form list when it changes in form
    this.initStockFields();

    this.subsList.push(
      this._myForm.controls.stockId.valueChanges
        .pipe(startWith(this._myForm.controls.stockId.value), distinctUntilChanged())
        .subscribe((stockId: number) => {
          this.fullSupplierFormListExtraQueryParams = { stockId };
          this.inventorySupplierListService.selectedStockId = stockId;
        })
    );

    this.subsList.push(
      this._openInventoryEvent
        .pipe(
          tap((purchaseDocLine: PurchaseDocLine) => (this._docLineOpened = purchaseDocLine)),
          switchMap((purchaseDocLine: PurchaseDocLine) => {
            const inventoryId = purchaseDocLine.docLine.inventory.id;
            return this.dbService.getRow("inventory", inventoryId);
          })
        )
        .subscribe((inventory) => {
          this.openFullSizeDialog(FullSizeDialogName.EDIT_INVENTORY, inventory);
        })
    );

    this.subsList.push(
      this.inventorySupplierListService.addToRecieptFormListEvent.subscribe((inventory: Inventory) => {
        this._addInventoryItem(inventory);
      })
    );

    this.purchaseDocLineCols = this.dbService.purchaseDocLineService.getFormListColumns({
      skuTemplateOptions: {
        data: {
          showInventoryEvent: this._openInventoryEvent,
        },
        templateRef: this.skuTpl,
      },
    });

    setTimeout(() => {
      this.populateInventoryList();
      this.updatePurchaseDocTotalTax();
      this._updateForm();

      setTimeout(() => {
        this.subsList.push(
          this.formListInventory._formGroups.valueChanges
            .pipe(
              startWith(this.formListInventory._formGroups.value),
              debounceTime(500),
              distinctUntilChanged((prev, curr) => {
                // Create deep copies of prev and curr
                let prevCopy = _.cloneDeep(prev);
                let currCopy = _.cloneDeep(curr);

                // Remove '_row_local_id' from each item in the copies
                prevCopy.forEach((item) => delete item["_row_local_id"]);
                currCopy.forEach((item) => delete item["_row_local_id"]);

                // Compare the modified copies
                return _.isEqual(prevCopy, currCopy);
              }), // Only emit when the current value is different than the last
              pairwise()
            )
            .subscribe(([prev, curr]: [PurchaseDocLineFormListRow[], PurchaseDocLineFormListRow[]]) => {
              this.purchaseDocLineFormListDataChanged(prev, this.formListInventory.getObjects());
              this.onDiscountApplyClick();
              this.onShippingApplyClick();

              this.purchaseDocLineformListTaxDataChanged(prev);
            })
        );
        this.populateSearchFields();
        // Mark dirty on first page load of new
        if (this._route.snapshot.queryParams.defaultdataValues) {
          this._myForm.markAsDirty();
        }
      }, 100);
    }, 0);

    if (this._myForm.value.doc.state !== DocState.finalized) {
      this.initShippingAddressDropdown(
        this._myForm.controls.account.value.commercialAccount.commercialAccountAddresses.filter(
          (commercialAccountAddress) => commercialAccountAddress.address.addressType === AddressType.shippingAddress
        )
      );
    }
    this.subsList.push(
      this._myForm.controls.shippingAddressId.valueChanges.pipe(distinctUntilChanged()).subscribe((addressId) => {
        if (this.shippingAddressOptionsById[addressId]) {
          const address = this.stripAddressCreatedUpdatedAtProperties(this.shippingAddressOptionsById[addressId]);
          this._myForm.controls.shippingAddress.setValue(address);
        } else {
          const address = this.stripAddressCreatedUpdatedAtProperties(null);
          this._myForm.controls.shippingAddress.setValue(address);
        }
        // Handle edge case where finalizing save button makes these id's dirty (as they come back different from the server after being cloned during finalization)
        if (this._myForm.value.doc.state === DocState.finalized) {
          this._myForm.controls.shippingAddressId.markAsPristine();
        }
        if (this.formListInventory) {
          const updateOperations = this.formListInventory
            .getObjects()
            .map((purchaseDocLine: PurchaseDocLineFormListRow) => {
              return this.updatePurchaseLineTax(purchaseDocLine);
            });

          forkJoin(updateOperations).subscribe(() => {
            this.updatePurchaseDocTotalTax();
            this.purchaseDocLineformListTaxDataChanged(this.formListInventory.getObjects());
          });
        }
      })
    );
    if (this._myForm.value.doc.state !== DocState.finalized) {
      this.initShippingContactDropdown(this._myForm.controls.account.value.commercialAccount);
    }
    this.subsList.push(
      this._myForm.controls.shippingContactId.valueChanges.pipe(distinctUntilChanged()).subscribe((contactId) => {
        if (this.shippingContactOptionsById[contactId]) {
          const person = this.shippingContactOptionsById[contactId];
          this._myForm.controls.shippingContact.setValue(person);
        }
        // Handle edge case where finalizing save button makes these id's dirty (as they come back different from the server after being cloned during finalization)
        if (this._myForm.value.doc.state === DocState.finalized) {
          this._myForm.controls.shippingContactId.markAsPristine();
        }
      })
    );

    if (this._route.snapshot.queryParams.defaultdataValues) {
      this._myForm.controls.shippingAddressId.markAsDirty();
      this._myForm.controls.paymentTermId.markAsDirty();
      this._myForm.controls.shippingTermId.markAsDirty();
      (this._myForm.controls.stockAddress as FormGroup).controls.line1.markAsDirty();
      (this._myForm.controls.stockAddress as FormGroup).controls.city.markAsDirty();
      (this._myForm.controls.stockAddress as FormGroup).controls.subDivisionIsoCode.markAsDirty();
      (this._myForm.controls.stockAddress as FormGroup).controls.countryIsoCode.markAsDirty();
    }
  }

  private purchaseDocLineformListTaxDataChanged(prev: PurchaseDocLineFormListRow[]) {
    const purchaseDocLineByIdPrev: Record<string, PurchaseDocLineFormListRow> = prev.reduce(
      (purchaseDocLineById, purchaseDocLine) => {
        purchaseDocLineById[purchaseDocLine._row_local_id] = purchaseDocLine;
        return purchaseDocLineById;
      },
      {}
    );

    const updateOperations = this.formListInventory
      .getObjects()
      .map((purchaseDocLine: PurchaseDocLineFormListRow) => {
        return this.syncTaxWhenUnitPriceChangedOrNewRowAdded(purchaseDocLineByIdPrev, purchaseDocLine);
      })
      .filter((op) => op != null); // Filter out null operations (no updates needed)

    if (updateOperations.length > 0) {
      this.subsList.push(
        forkJoin(updateOperations).subscribe({
          complete: () => {
            this.updatePurchaseDocTotalTax();
          },
        })
      );
    } else {
      this.updatePurchaseDocTotalTax();
    }
  }

  private purchaseDocLineFormListDataChanged(
    prev: PurchaseDocLineFormListRow[],
    curr: PurchaseDocLineFormListRow[]
  ): void {
    const purchaseDocLineByIdPrev: Record<string, PurchaseDocLineFormListRow> = prev.reduce(
      (purchasDocLineById, purchaseDocLine) => {
        purchasDocLineById[purchaseDocLine._row_local_id] = purchaseDocLine;
        return purchasDocLineById;
      },
      {}
    );

    curr
      .filter((purchaseDocLineCurr) => {
        const purchaseDocLinePrev = purchaseDocLineByIdPrev[purchaseDocLineCurr._row_local_id];
        // Return true if it is a new row or if it has changed compared to the previous state
        return (
          (!purchaseDocLinePrev || purchaseDocLinePrev.packSize !== purchaseDocLineCurr.packSize) &&
          purchaseDocLineByIdPrev.hasOwnProperty(purchaseDocLineCurr._row_local_id)
        );
      })
      .forEach((purchaseDocLine: PurchaseDocLineFormListRow) => {
        // Only sync if the PackSize changed, or if it is a brand new row
        const inventorySupplierProductDetailOfNewPackSize = purchaseDocLine.docLine.inventory.inventorySuppliers
          .find(
            (inventorySupplier: InventorySupplier) =>
              inventorySupplier.accountId == this._myForm.controls.accountId.value
          )
          ?.inventorySupplierProductDetails.find(
            (inventorySupplierProducDetail: InventorySupplierProductDetail) =>
              inventorySupplierProducDetail.packSize === purchaseDocLine.packSize
          );

        purchaseDocLine.unitPrice = inventorySupplierProductDetailOfNewPackSize?.supplierCost || 0;
      });
  }

  private syncTaxWhenUnitPriceChangedOrNewRowAdded(
    purchaseDocLineByIdPrev: Record<string, PurchaseDocLineFormListRow>,
    purchaseDocLine: PurchaseDocLineFormListRow
  ): Observable<any> {
    const isNewRow = !purchaseDocLineByIdPrev.hasOwnProperty(purchaseDocLine._row_local_id);
    const unitPriceChanged =
      +purchaseDocLineByIdPrev[purchaseDocLine._row_local_id]?.unitPrice *
        purchaseDocLineByIdPrev[purchaseDocLine._row_local_id]?.qty !=
      +purchaseDocLine.unitPrice * purchaseDocLine.qty;
    const discountTypeChanged =
      purchaseDocLineByIdPrev[purchaseDocLine._row_local_id]?.allocatedDiscountType !=
      purchaseDocLine.allocatedDiscountType;
    const discountAmountChanged =
      purchaseDocLineByIdPrev[purchaseDocLine._row_local_id]?.allocatedDiscountAmount !=
      purchaseDocLine.allocatedDiscountAmount;

    if (isNewRow || unitPriceChanged || discountTypeChanged || discountAmountChanged) {
      // Call the updatePurchaseLineTax method and return its Observable
      return this.updatePurchaseLineTax(purchaseDocLine);
    } else {
      // If no update is needed, return an immediately completed Observable
      return of(null);
    }
  }

  // split time and date from single field used in database
  private syncShippingDate(shippingDate: Date | string): void {
    // split time and date from single field used in database
    const [shippingDay, shippingTime] = TimeHelpers.SplitDateAndTime(shippingDate, false, true);

    this.shippingDateForm.patchValue({ shippingDay, shippingTime }, { emitEvent: false });
  }

  static init(fb: UntypedFormBuilder, purchaseDoc: PurchaseDoc, storeOrBackOfficeId?: string): UntypedFormGroup {
    const tmpForm = fb.group(purchaseDoc);
    const tempAccountFormGroup = fb.group(purchaseDoc.account);
    tempAccountFormGroup.setControl("commercialAccount", fb.group(purchaseDoc.account.commercialAccount));
    tmpForm.setControl("account", tempAccountFormGroup);
    tmpForm.setControl("stock", fb.group(purchaseDoc.stock));
    tmpForm.setControl("doc", fb.group(purchaseDoc.doc));
    tmpForm.setControl("purchaseDocLines", fb.array([]));
    tmpForm.setControl("stockAddress", AddressComponent.init(fb, null, AddressType.shippingAddress));
    tmpForm.setControl("locationId", new FormControl(storeOrBackOfficeId, { nonNullable: true }));

    return tmpForm;
  }

  get purchaseDocGeneralDiscount(): number {
    const discountType = this._myForm.get("discountType").value;
    const discountAmount = this._myForm.get("discountAmount").value;
    let totalDiscount = 0;
    // If discount amount is empty just return the initial unitprice
    if (discountAmount === "" || discountAmount === null) return 0;

    switch (discountType) {
      case DiscountType.Fixed:
        totalDiscount = discountAmount;
        break;
      case DiscountType.Percentage:
        totalDiscount = (this.purchaseDocSubTotal * discountAmount) / 100;
        break;
    }
    // Round to only 2 decimal places
    return MonetaryHelpers.roundToDecimalPlaces(totalDiscount);
  }

  get purchaseDocSubTotal(): number {
    let subTotal = 0;
    if (this.formListInventory) {
      const docLines: PurchaseDocLine[] = this.formListInventory.getObjects();
      if (docLines)
        docLines.forEach(
          (purchaseDocLine) =>
            (subTotal += MonetaryHelpers.roundToDecimalPlaces(purchaseDocLine.unitPrice * purchaseDocLine.qty))
        );
    }
    return subTotal;
  }

  private initShippingAddressDropdown(
    commercialAccountAddresses: CommercialAccountAddress[],
    usePreviousSelectedValue = true
  ) {
    const previouslySavedShippingAddressId = usePreviousSelectedValue
      ? this._myForm.controls.shippingAddressId.value
      : null;

    this.shippingAddressOptions = [
      {
        label: "",
        value: null,
        object: null,
      },
    ];
    this.shippingAddressOptions.push(
      ...commercialAccountAddresses.map((commercialAccountAddress: CommercialAccountAddress) => {
        let address = commercialAccountAddress.address;

        if (previouslySavedShippingAddressId == address.id && usePreviousSelectedValue) {
          address = this._myForm.controls.shippingAddress.value;
        } else {
          this.stripAddressCreatedUpdatedAtProperties(address);
        }

        this.shippingAddressOptionsById[address.id] = address;
        return {
          label: address.line1,
          value: address.id,
          object: address,
        };
      })
    );

    if (
      previouslySavedShippingAddressId &&
      !this.shippingAddressOptionsById.hasOwnProperty(previouslySavedShippingAddressId)
    ) {
      const address = this._myForm.controls.shippingAddress.value;

      this.shippingAddressOptionsById[previouslySavedShippingAddressId] = address;
      this.shippingAddressOptions.push({
        label: address.line1,
        value: address.id,
        object: address,
      });
    }
  }

  private initShippingContactDropdown(commercialAccount: CommercialAccount, usePreviousSelectedValue = true) {
    const previouslySavedShippingContactId = usePreviousSelectedValue
      ? this._myForm.controls.shippingContactId.value
      : null;

    this.shippingContactOptions = [
      {
        label: "",
        value: null,
        object: null,
      },
    ];

    this.shippingContactOptions.push(
      ...commercialAccount.contacts.map((contact: Contact) => {
        let person = contact.person;

        if (previouslySavedShippingContactId == person.id && usePreviousSelectedValue) {
          person = this._myForm.controls.shippingContact.value;
        }

        this.shippingContactOptionsById[person.id] = person;
        return {
          label: `${person.firstName} ${person.middleName ? person.middleName + " " : ""}${person.lastName}`.trim(),
          value: person.id,
          object: person,
        };
      })
    );

    if (
      previouslySavedShippingContactId &&
      !this.shippingContactOptionsById.hasOwnProperty(previouslySavedShippingContactId)
    ) {
      const person = this._myForm.controls.shippingContact.value;

      this.shippingContactOptionsById[previouslySavedShippingContactId] = person;
      this.shippingContactOptions.push({
        label: `${person.firstName} ${person.middleName ? person.middleName + " " : ""}${person.lastName}`.trim(),
        value: person.id,
        object: person,
      });
    }
  }

  private initStockFields() {
    this.subsList.push(
      this.dbService.get_lookup_stocks().subscribe((stockOptions: ExtendedSelectItem<number, Stock>[]) => {
        this.stockOptions = stockOptions;

        stockOptions.forEach((stockOption) => {
          this.stockOptionsById[stockOption.value] = stockOption.object;
        });

        if (!this._myForm.controls.stockAddress.value.id) {
          let stockAddress = _.cloneDeep(
            this.stripAddressCreatedUpdatedAtProperties(
              this.stockOptionsById[this._myForm.controls.stockId.value].address
            )
          );

          FormDataHelpers.clearModelIDs(stockAddress);

          this._myForm.setControl("stockAddress", AddressComponent.set(this.fb, stockAddress));
          (this._myForm.controls.stockAddress as FormGroup).controls.line1.setValidators([Validators.required]);
          (this._myForm.controls.stockAddress as FormGroup).controls.city.setValidators([Validators.required]);
          (this._myForm.controls.stockAddress as FormGroup).controls.subDivisionIsoCode.setValidators([
            Validators.required,
          ]);
          (this._myForm.controls.stockAddress as FormGroup).controls.countryIsoCode.setValidators([
            Validators.required,
          ]);
        }
      })
    );
  }

  private stripAddressCreatedUpdatedAtProperties(address: Address): Address {
    if (!address) {
      // Return a new instance of Address or an object with empty/default values
      return new Address(); // Assuming Address constructor handles empty instantiation
    }

    address = _.pick(address, _.keys(new Address())) as Address;

    // Assuming addressPhone and addressEmail are optional, check if they exist
    if (address.addressPhone) {
      address.addressPhone = _.pick(address.addressPhone, _.keys(new AddressPhone())) as AddressPhone;
    } else {
      address.addressPhone = new AddressPhone(); // or set to an empty/default AddressPhone object
    }

    if (address.addressEmail) {
      address.addressEmail = _.pick(address.addressEmail, _.keys(new AddressEmail())) as AddressEmail;
    } else {
      address.addressEmail = new AddressEmail(); // or set to an empty/default AddressEmail object
    }

    return address;
  }

  populateSearchFields(): void {
    this.accountSearchComponent.textSearch = this._object.account?.commercialAccount?.name;
  }

  populateInventoryList(): void {
    for (let docLine of this._object.purchaseDocLines.sort((a, b) => a.docLine.seqNo - b.docLine.seqNo)) {
      docLine = Object.assign(new PurchaseDocLine(this._object), docLine);
      this.formListInventory.addNewRow(docLine, false);
    }
    this.localDataService._allLocalData["purchaseDocLine"] = this.formListInventory.getObjects();
    if (this.isReadOnly) this.formListInventory.disableEditMode();
  }

  showFullSupplierListDialogClicked(): void {
    this.initShowFullSupplierFormList();
    this.showFullSupplierListDialog = true;
  }

  private initShowFullSupplierFormList() {
    this.showFullSupllierFormListNoChildrenFilter = {
      "inventorySuppliers.accountId": { matchMode: "equals", value: this._myForm.controls.accountId.value },
      isVariantParent: { matchMode: "equals", value: false },
      isBuyable: { matchMode: "equals", value: true },
      isEnabled: { matchMode: "equals", value: true },
    };
    if (this._activeSupplierDialogTabId === "lowStock") {
      this.showFullSupllierFormListNoChildrenFilter = _.merge(this.showFullSupllierFormListNoChildrenFilter, {
        "inventoryStocks.qtyOH": { matchMode: "lte-col", value: "inventoryStocks.minStock" },
        "inventoryStocks.stockId": { matchMode: "equals", value: this._myForm.controls.stockId.value },
      });
    }

    this._showFullSupllierFormListCols = this.inventorySupplierListService.getFormListColumns();

    this._showFullSupllierFormListRules = this.inventorySupplierListService.getValidationRules();
    this._isShowFullSupllierFormListDataReady = true;

    setTimeout(() => {
      // This fixes a bug where the initial tab is not properly styled/underlined after this is rendered in a modal
      this.supplierDialogTabMenu.updateInkBar();
    }, 300);
  }

  initLookups(): void {
    this.$lookup_paymentTerms = this.dbService.get_lookup_paymentTerms();
    this.$lookup_shippingTerms = this.dbService.get_lookup_shippingTerms();
    this.$lookup_billToLocations = forkJoin({
      stores: this.dbService.get_lookup_stores().pipe(
        map((stores) =>
          stores
            .filter((store) => store.value != null)
            .map((store) => ({ ...store, value: "s" + store.value, fieldName: "storeId" }))
            .sort((a, b) => a.label.localeCompare(b.label))
        )
      ),
      backOffices: this.dbService.get_Lookup_backOffices().pipe(
        map((backoffices) =>
          backoffices
            .filter((backoffice) => backoffice.value != null)
            .map((backOffice) => ({ ...backOffice, value: "b" + backOffice.value, fieldName: "backOfficeId" }))
            .sort((a, b) => a.label.localeCompare(b.label))
        )
      ),
    }).pipe(
      map(({ stores, backOffices }) => {
        // Combine and structure as groups, here using a simple grouping logic
        return [
          {
            label: "Stores",
            value: null,
            items: stores,
          },
          {
            label: "Back Offices",
            value: null,
            items: backOffices,
          },
        ];
      })
    );
  }

  get purchaseDocTotal(): number {
    const total =
      this.purchaseDocSubTotal -
      (this.purchaseDocGeneralDiscount || 0) +
      Number(this._myForm.get("shipperChargeAmount")?.value || 0) +
      this.purchaseDocTotalTax;
    return total;
  }

  get docCurrencyControl(): AbstractControl {
    return this._myForm.get("doc.currencyIsoCode");
  }

  onSearchResAccount(accountResult: SearchResultItem): void {
    const accountId = accountResult.ID;
    this.accountIdCtrl.setValue(accountId);
    this.accountIdCtrl.markAsDirty();
  }

  get accountIdCtrl(): AbstractControl {
    return this._myForm?.get("accountId");
  }

  get docStateCtrl(): UntypedFormControl {
    return this._myForm?.get("doc.state") as UntypedFormControl;
  }

  onSearchResInventory(inventoryResult: SearchResultItem): void {
    this._addInventoryItem(inventoryResult.data);
  }

  onSaveDraft(): void {
    this.isSavingDraft = true;
    this.isFinalizing = false;
    const purchaseDocLinesEmptyQtys: PurchaseDocLine[] = this.formListInventory
      .getObjects()
      .filter((purchaseDocLine) => !Number(purchaseDocLine.qty));
    if (purchaseDocLinesEmptyQtys.length > 0) {
      purchaseDocLinesEmptyQtys.forEach((purchaseDocLine) => {
        if (!Number(purchaseDocLine.qty)) {
          purchaseDocLine.qty = 0;
        }
      });
    }

    this._myForm.markAsDirty();
    this.onSave();
    this.formListInventory.unSelectAllRows();
  }

  onVoid(): void {
    this._changeDocState(DocState.voided);

    this.onSave();
  }

  onDonePressed(): void {
    this.isSavingDraft = false;
    this.isFinalizing = true;
    this.formListInventory._isFetchingData = true;
    this._myForm.markAsDirty();
    if (this._object.doc.state === DocState.draft) {
      this._changeDocState(DocState.finalized);
      this.docStateChangedFromDraftToFinalizedToFinalized = true;
    }

    this.onSave();
  }

  _changeDocState(docState: DocState): void {
    this.docStateCtrl.setValue(docState);
    this.docStateCtrl.markAsDirty();
  }

  private _updateForm() {
    // update subtotal in form
    this._myForm.get("subTotal").setValue(this.purchaseDocTotal);
    // Attach form list objects to form
    this.attachFormObjects();
  }

  onSave(): void {
    this._updateForm();
    super.onSave();
    this.formListInventory.markAllAsPristine();
  }

  protected override prepareSaveObject(): any {
    const saveObject = super.prepareSaveObject();

    if (saveObject["shippingAddressId"] == null) {
      delete saveObject["shippingAddress"];
    }

    if (saveObject["shippingContactId"] == null) {
      delete saveObject["shippingContact"];
    }

    if (this.docStateCtrl.value === DocState.suspended || this.docStateCtrl.value === DocState.draft) {
      if (saveObject["shippingAddressId"] && saveObject.hasOwnProperty("shippingAddress")) {
        delete saveObject["shippingAddress"];
      }
      if (saveObject["shippingContactId"] && saveObject.hasOwnProperty("shippingContact")) {
        delete saveObject["shippingContact"];
      }
    }

    if (this.docStateChangedFromDraftToFinalizedToFinalized) {
      const shippingAddress = _.cloneDeep(this._myForm.controls.shippingAddress.value);
      FormDataHelpers.clearModelIDs(shippingAddress);
      const shippingContact = _.cloneDeep(this._myForm.controls.shippingContact.value);
      FormDataHelpers.clearModelIDs(shippingContact);

      delete saveObject["shippingAddressId"];
      saveObject["shippingAddress"] = shippingAddress;
      delete saveObject["shippingContactId"];
      saveObject["shippingContact"] = shippingContact;
      this.docStateChangedFromDraftToFinalizedToFinalized = false;
    }

    // remove unneccessary items from saveObject before pushing for save or insert
    saveObject.purchaseDocLines.forEach((purchaseDocLine) => {
      delete purchaseDocLine["docReference"];
      delete purchaseDocLine.docLine["inventory"];
      delete purchaseDocLine.docLine["user"];
      purchaseDocLine.purchaseDocLineTaxes.forEach((purchaseDocLineTax) => {
        delete purchaseDocLineTax["taxRule"];
      });
      purchaseDocLine.qty = purchaseDocLine.qty || 0;
      purchaseDocLine.unitPrice = purchaseDocLine.unitPrice || 0;
    });
    delete saveObject["stock"];
    delete saveObject["zone"];

    return saveObject;
  }

  clearAccountField() {
    this.accountIdCtrl.reset();
  }

  initValidation() {
    this._validation = this.dbService.getValidationRules();
  }

  get isInvalidForm(): boolean {
    return this._myForm.invalid || (this.formListInventory && !this.formListInventory.isValid());
  }

  get isPristineForm(): boolean {
    return this._myForm.pristine && this.formListInventory && this.formListInventory.isPristine();
  }

  get inventoryLinesFormArray() {
    return <UntypedFormArray>this._myForm.get("purchaseDocLines");
  }

  private attachFormObjects(): any {
    const isFormPristine = this.formListInventory.isPristine();
    const docLines: PurchaseDocLine[] = this.formListInventory.getObjects();

    const formArray = docLines.map((purchaseDocLine) => {
      // Create the form group for the current doc line, including nested form groups and arrays
      const docLineForm = this.fb.group({
        ...purchaseDocLine,
        docLine: this.fb.group(purchaseDocLine.docLine),
        purchaseDocLineTaxes: this.fb.array(purchaseDocLine.purchaseDocLineTaxes.map((tax) => this.fb.group(tax))),
      });
      return docLineForm;
    });

    this._myForm.setControl("purchaseDocLines", this.fb.array(formArray));

    if (!isFormPristine) {
      this.inventoryLinesFormArray.markAsDirty();
    }

    const totalNumItems = formArray.reduce((sum, formGroup) => {
      const qty = Number(formGroup.value.qty) || 0;
      return sum + qty;
    }, 0);
    this.totalItems = parseFloat(totalNumItems.toFixed(2));
  }

  openNewModelInDialog(defaultData: any) {
    let dialogName: FullSizeDialogName;

    if (defaultData instanceof CommercialAccount) {
      dialogName = FullSizeDialogName.COMMERCIAL_ACCOUNT;
    } else if (defaultData instanceof Inventory) {
      dialogName = FullSizeDialogName.NEW_INVENTORY;
    }

    this.openFullSizeDialog(dialogName, defaultData);
  }

  onNewCommercialAccountCreated(commecialAccount: CommercialAccount) {
    this.accountSearchComponent.textSearch = commecialAccount.name;
    this.accountIdCtrl.setValue(commecialAccount.account.id);
    this.accountIdCtrl.markAsDirty();
    this.closeFullSizeDialog();
  }

  onNewInventoryItem(inventory: Inventory) {
    this._addInventoryItem(inventory);
    this.closeFullSizeDialog();
  }

  private _addInventoryItem(
    inventory: Inventory,
    options: { unitPrice?: number; qty?: number; packSize?: PackSize } = {
      qty: null,
      packSize: PackSize.SKU,
    }
  ) {
    const { unitPrice = 0, qty = 1, packSize = PackSize.SKU } = options;
    if (this.formListInventory.getObjects().length >= 1000) {
      // Limit items so it doesn't flow into a 2nd page, which causes issues overwriting other pages of data (only currently viewed page is persisted).
      this.messageService.add({
        severity: "warn",
        summary: "Too many items",
        detail: "Limit of 1000 line items per receiving document",
        sticky: true,
      });
      return;
    }
    if (inventory.isVariantParent) {
      const purDocLine: PurchaseDocLine = _.merge(new PurchaseDocLine(this._object), {
        unitPrice: inventory.standardPrice,
        qty: qty,
        packSize: packSize,
        // TODO: Fill out DocLine
        docLine: {
          id: 0,
          seqNo: 1,
          note: "",
          expiryDate: null,
          serialNo: "",
          refNo: 0,
          inventoryId: inventory.id,
          inventory: inventory,
        } as DocLine,
      } as Partial<PurchaseDocLine>);
      const purDocLineForm = this.fb.group(purDocLine);
      const docLineForm = this.fb.group(purDocLine.docLine);
      purDocLineForm.setControl("docLine", docLineForm);

      this.ref = this.dialogService.open(SelfCheckoutItemDetailsComponent, {
        styleClass: "selfcheckout-mobile rounded-selfcheckout-dialog",
        contentStyle: {
          overflow: "auto",
          height: "60%",
          minWidth: "280px",
          maxWidth: "680px",
          maxHeight: "1100px",
        },
        data: {
          _saleForm: purDocLineForm,
          _isAddToCart: true,
          _showSalePrice: false,
          _doneButtonCaption: "Done",
        },
      });

      this.subsList.push(
        this.ref.onClose.subscribe((result) => {
          if (result && result.qtySelected > 0) {
            // this.docComponent.addToSaleDoc(docLineForm.value.docLine.inventory, result.qtySelected);
            inventory = purDocLineForm.get("docLine.inventory").value;
            const purDocLine = new PurchaseDocLine(this._object);
            _.merge(purDocLine, {
              docLine: {
                inventoryId: inventory.id,
                inventory: inventory,
                userId: this.dbService.getCurrentUserId(),
                seqNo:
                  (this.formListInventory._objects.length
                    ? Math.max(
                        ...this.formListInventory._objects.map((row) => {
                          return row.docLine.seqNo;
                        })
                      )
                    : 0) + 1,
              },
            });

            purDocLine.qty = result.qtySelected;
            // Add new row to form list
            this.formListInventory.addNewRow(purDocLine, false);
            this.localDataService._allLocalData["purchaseDocLine"] = this.formListInventory.getObjects();
            // Clear input field
            this.inventorySearchComponent.cleanAndFocusSearchInput();
            // When changing form list mark form as dirty
            this._myForm.markAsDirty();
          }
        })
      );
    } else {
      const purDocLine = new PurchaseDocLine(this._object);
      purDocLine.packSize = packSize;
      _.merge(purDocLine, {
        docLine: {
          inventoryId: inventory.id,
          inventory: inventory,
          userId: this.dbService.getCurrentUserId(),
          seqNo:
            (this.formListInventory._objects.length
              ? Math.max(
                  ...this.formListInventory._objects.map((row) => {
                    return row.docLine.seqNo;
                  })
                )
              : 0) + 1,
        } as DocLine,
      });
      // Set Current U of M to be the same as Default U of M the first time inventory is added
      purDocLine.uOfMeasureId = inventory.uOfMeasureId;
      const inventorySupplierProductDetailOfNewPackSize = inventory.inventorySuppliers
        .find((inventorySupplier: InventorySupplier) => inventorySupplier.accountId == this._object.accountId)
        ?.inventorySupplierProductDetails.find(
          (inventorySupplierProducDetail: InventorySupplierProductDetail) =>
            inventorySupplierProducDetail.packSize === packSize
        );

      purDocLine.unitPrice = unitPrice || inventorySupplierProductDetailOfNewPackSize?.supplierCost || 0;
      purDocLine.qty = 0;
      // Add new row to form list
      this.formListInventory.addNewRow(purDocLine, false);
      this.localDataService._allLocalData["purchaseDocLine"] = this.formListInventory.getObjects();
      // Clear input field
      this.inventorySearchComponent.cleanAndFocusSearchInput();
      // When changing form list mark form as dirty
      this._myForm.markAsDirty();
    }

    // There is some change-detection bug in the p-table code that makes us do this manually
    this.formListInventory.selectAllCheckBox.checked = this.formListInventory.selectAllCheckBox.updateCheckedState();
  }

  editCommercialAccount(commercialAccountId: number): void {
    this.subsList.push(
      this.dbService
        .getRow("commercialAccount", commercialAccountId)
        .subscribe((_commercialAccount: CommercialAccount) => {
          this.openFullSizeDialog(FullSizeDialogName.COMMERCIAL_ACCOUNT, _commercialAccount);
        })
    );
  }

  openFullSizeDialog(dialog: FullSizeDialogName, extra: CommercialAccount | Inventory = null): void {
    this._activeFullDialog = dialog;
    this._activeFullDialogExtra = extra;
  }

  closeFullSizeDialog() {
    if (this._activeFullDialog === FullSizeDialogName.COMMERCIAL_ACCOUNT) {
      this.shippingAddressOptions = [];
      this.shippingAddressOptionsById = {};
      this.shippingContactOptions = [];
      this.shippingContactOptionsById = {};

      // Fetch business details to get the defaultCurrencyIsoCode from appSettingService
      this.appSettingsService
        .getBusinessDetails()
        .pipe(
          tap((businessDetail: BusinessDetail) => {
            const defaultCurrencyIsoCode = businessDetail.defaultCurrencyIsoCode;
            const updatedCommercialAccount = _.pick(
              this.commercialAccountDialogForm._myForm.value,
              _.keys(new CommercialAccount(defaultCurrencyIsoCode))
            ) as CommercialAccount;

            this._myForm.get("account.commercialAccount").patchValue(updatedCommercialAccount);
            this.initShippingAddressDropdown(
              updatedCommercialAccount.commercialAccountAddresses.filter(
                (commercialAccountAddress) =>
                  commercialAccountAddress.address.addressType === AddressType.shippingAddress
              ),
              false
            );
            this.initShippingContactDropdown(updatedCommercialAccount, false);
          })
        )
        .subscribe();
    }

    this._activeFullDialog = null;
    this._activeFullDialogExtra = null;
  }

  setZeroIfEmpty(_formControlName: string): void {
    this._myForm.get(_formControlName).setValue(this._myForm.get(_formControlName).value || 0);
  }

  onChangedDocLineInventory(inventory: Inventory) {
    this._docLineOpened.docLine.inventory = inventory;
    // Reset dialog properties
    this._docLineOpened = null;
    this.closeFullSizeDialog();
    // When changing form list mark form as dirty
    this._myForm.markAsDirty();
  }

  @HostListener("window:click", ["$event"])
  closeOverlayOnOutsideClick(e: MouseEvent) {
    const queryOverlay = `${(<HTMLElement>this.elRef.nativeElement).tagName} .overlay-container`;
    // Close all the summary overlays only when the click was within the App's layout container (ex. NOT inside a dialog/modal) AND outside an overlay's contents
    if (e.target instanceof HTMLElement && e.target.closest("app-root") && !e.target.closest(queryOverlay))
      this.toggleOverlay(null);
  }

  closeOverlay(overlayName) {
    this._overlaysVisibility[overlayName] = false;
  }

  toggleOverlay(overlayName: string, event?: MouseEvent) {
    if (event) event.stopImmediatePropagation();

    for (const key in this._overlaysVisibility) {
      if (key === overlayName) {
        this._overlaysVisibility[key] = !this._overlaysVisibility[key];
        switch (key) {
          case "shipping":
            this.shippingForm.get("shipperChargeAmount").setValue(this._myForm.get("shipperChargeAmount").value);
            break;
          case "discount":
            this.discountForm.get("discountType").setValue(this._myForm.get("discountType").value);
            this.discountForm.get("discountAmount").setValue(this._myForm.get("discountAmount").value);
            break;

          default:
            break;
        }
      } else {
        this._overlaysVisibility[key] = false;
      }
    }
  }

  onDiscAmountClicked() {
    setTimeout(() => {
      this.discAmountInputComponent.focusAndSelectInput();
    }, 0);
  }

  onShippingAmountClicked() {
    setTimeout(() => {
      this.shippingAmountInputComponent.focusAndSelectInput();
    }, 0);
  }

  updatePoMaxStockQty(): void {
    this.formListInventory._selectedObjects.forEach((_object) => {
      _object.qty = this.calculateMaxStockInventory(_object);
    });
    this.localDataService.initialize(this.formListInventory._objects, "purchaseDocLine");
    this._myForm.markAsDirty();
  }

  calculateMaxStockInventory(_object: PurchaseDocLine) {
    const poStockId = this._myForm.get("stockId").value;
    const stockRow = _object.docLine.inventory.inventoryStocks.find((row) => row.stockId === poStockId);
    const maxStock = stockRow?.maxStock;
    const qtyOH = stockRow?.qtyOH;
    const onOrderQty = stockRow?.onOrderQty || 0;
    const commitedQty = stockRow?.committedQty;

    if (maxStock > 0) {
      const calc = (maxStock - qtyOH - onOrderQty + commitedQty) / Number(_object.skuQtyPerPack);
      return calc > 0 ? calc : 0;
    }
    return _object.qty;
  }

  promptOrderMaxStockQty(): void {
    this.confirmationService.confirm({
      header: "Confirmation",
      message:
        "This will calculate the Order Qty for each line, based on data for this POs selected Stock location, using the following formula:<br><br>'Max Stock Qty - On Hand Qty - On Order Qty + Committed Qty'.<br><br>If Max Stock Qty is not set for a product, the Order Qty will not be modified.<br>Do you still want to proceed?",
      rejectButtonStyleClass: "p-button-link",
      accept: () => {
        this.updatePoMaxStockQty();
      },
      reject: () => {
        // do nothing
      },
    });
  }

  exportPurchaseOrders(): void {
    this.formListInventory.onExportRows(FormListExportFormat.CSV);
  }

  openStockAddressPanel(): void {
    this.tempStockAddress = _.cloneDeep(this._myForm.controls.stockAddress.value);
    this.showStockAddressEditDialog = true;
  }

  onCloseStockAddressPannel(closePanel = true): void {
    this._myForm.controls.stockAddress.patchValue(this.tempStockAddress);
    this.showStockAddressEditDialog = !closePanel;
  }

  onSaveStockAddressPanel(): void {
    this.showStockAddressEditDialog = false;
  }

  private getPurchaseDocForPrinting(): Observable<PurchaseDoc> {
    const purchaseDoc: PurchaseDoc = this._myForm.getRawValue();
    purchaseDoc.stock = this.stockOptionsById[this._object.stockId];
    return forkJoin([this.$lookup_paymentTerms, this.$lookup_shippingTerms, this.$lookup_billToLocations]).pipe(
      map(([paymentTerms, shippingTerms, billToLocations]) => {
        if (this._myForm.get("paymentTermId").value !== null) {
          const paymentTerm = paymentTerms.find((item) => item.value == this._myForm.get("paymentTermId").value);
          purchaseDoc.paymentTerm = paymentTerm?.object;
        }
        if (this._myForm.get("shippingTermId").value !== null) {
          const shippingTerm = shippingTerms.find((item) => item.value == this._myForm.get("shippingTermId").value);
          purchaseDoc.shippingTerm = shippingTerm?.object;
        }
        if (purchaseDoc?.backOfficeId > 0) {
          const backOffice = billToLocations
            .find((item) => item.label == "Back Offices")
            .items.find((me) => me.object.id === purchaseDoc?.backOfficeId)?.object;
          purchaseDoc.backOffice = backOffice as BackOffice;
        } else if (purchaseDoc?.storeId > 0) {
          const billingStore = billToLocations
            .find((item) => item.label == "Stores")
            .items.find((me) => me.object.id === purchaseDoc?.storeId)?.object;
          purchaseDoc.store = billingStore as Store;
        }
        return purchaseDoc;
      })
    );
  }

  printPurchaseOrder($event): void {
    this._isPrintLoading = true;
    this.subsList.push(
      this.getPurchaseDocForPrinting()
        .pipe(
          tap((purchaseDoc) => {
            this.printingFactoryService
              .build(this.appSettingsService.getStoreId())
              .printPurchaseDocsInBatch([purchaseDoc], this.appSettingsService.getStore(), this.printPreviewHost);
          }),
          finalize(() => (this._isPrintLoading = false))
        )
        .subscribe()
    );
  }

  addSelectedRowsToPO(): void {
    this.supplierInventoryFormListModal.getSelectedRows().forEach((item: Inventory) => this._addInventoryItem(item));
    this.supplierInventoryFormListModal.unSelectAllRows();
    this.messageService.add({
      severity: "success",
      summary: "Success!",
      detail: "All items added to Purchase Order.",
      life: 3000,
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
