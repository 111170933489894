/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { SearchResultItem } from "../../../../taku-ui/taku-search-accounts/SearchResultItem";
import { FormDataHelpers } from "../../../../utility/FormDataHelpers";
import { InvoiceHelpers } from "../../../../utility/InvoiceHelpers";
import { AddressEmailComponent } from "../../../contact-accounts/address-email/address-email.component";
import { AddressPhoneComponent } from "../../../contact-accounts/address-phone/address-phone.component";
import { Address, AddressType } from "../../../contact-accounts/address/address";
import { StoreSettingSaleDoc } from "../../../settings/store-settings/sales-doc-settings/sales-doc-settings";

@Component({
  selector: "taku-invoice-details",
  templateUrl: "./invoice-details.component.html",
  styleUrls: ["./invoice-details.component.scss"],
})
export class InvoiceDetailsComponent implements OnChanges {
  @Input() account: SearchResultItem;
  @Input() saleForm: UntypedFormGroup;
  @Input() salesDocSettings: StoreSettingSaleDoc;
  @Input() shortenFormHeaderHeight = false;
  accountBillingAddress: Address;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["account"] || changes["salesDocSettings"]) {
      if (this.account)
        this.accountBillingAddress = InvoiceHelpers.findAccountAddressByType(AddressType.billingAddress, this.account);

      // Don't Auto Copy by default when 'Sale Doc Settings' has not been created yet
      if (this.salesDocSettings && this.salesDocSettings.autoCopyAccountAddress) {
        this.copyBillingAddressFromAccount();
      }
    }
  }

  ///////////////////////////
  // For Invoice Details ///
  /////////////////////////
  copyBillingAddressFromAccount() {
    if (this.accountBillingAddress)
      // check if address is valid
      this.saleForm.get("billingAddress").patchValue(this.accountBillingAddress);
  }

  onCloneAddress(destAddress: UntypedFormGroup, sourceAddress: UntypedFormGroup) {
    // Before reseting address backup destination address type
    const addressType = destAddress.get("addressType").value;

    const sourceData: Address = sourceAddress.value;
    FormDataHelpers.clearModelIDs(sourceData);
    destAddress.reset(sourceData);
    destAddress.setControl("addressEmail", AddressEmailComponent.set(this.fb, sourceData.addressEmail));
    destAddress.setControl("addressPhone", AddressPhoneComponent.set(this.fb, sourceData.addressPhone));

    destAddress.patchValue({ addressType });
  }

  onClearAddress(address: AbstractControl) {
    // Before reseting address backup curent address type
    const addressType = address.get("addressType").value;
    address.reset();
    address.patchValue({ addressType });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
