<div id="formWrapper" class="grid" [ngClass]="{ 'edit-mode': gmcAccountId }">
  <div class="col-12">
    <taku-form-dialoge-header
      [pristine]="_formGroup.pristine"
      [valid]="_formGroup.valid"
      (saveClicked)="onSave()"
      (revertClicked)="onRevert()"
      (cancelClicked)="closeDialog()"
      [showDeleteBtn]="false"
    ></taku-form-dialoge-header>
  </div>
  <div class="col-12">
    <taku-input [formGroup]="_formGroup" fieldName="gmcName" fieldCaption="Business Name"> </taku-input>
  </div>

  <div class="col-12 md:col-7 website-wrapper">
    <taku-input [formGroup]="_formGroup" fieldName="gmcWebsite" fieldCaption="Website"> </taku-input>
  </div>

  <div class="col-12 md:col-5 adultContent-wrapper">
    <taku-toggle
      [formGroup]="_formGroup"
      fieldName="adult"
      fieldCaption="Adult Content?"
      [captionPosition]="ToggleCaptionPosition.LEFT"
      enabledStateLabel="YES"
      disabledStateLabel="NO"
    >
    </taku-toggle>
  </div>

  <div class="col-12 address-wrapper">
    <div class="col-12">
      <h3>Business Information</h3>
    </div>
    <div class="col-12">
      <taku-address [formGroup]="_formGroup.get('address')"></taku-address>
    </div>
  </div>
</div>
