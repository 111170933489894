<div [formGroup]="_myForm">
  <div class="grid">
    <div class="col-12 xl:col-4 lg:col-6 status-container">
      <taku-dropdown
        [formGroup]="_myForm"
        [options]="enum_store_instant_msg_type"
        [fieldName]="'instantMsgType'"
        [fieldCaption]="'IM Type'"
      >
      </taku-dropdown>
    </div>
    <div class="col-12 xl:col-8">
      <taku-input [formGroup]="_myForm" [fieldName]="'instantMsg'" [fieldCaption]="'Instant Msg'"> </taku-input>
    </div>
  </div>
</div>
