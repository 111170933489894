/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, Input, OnChanges, SimpleChanges } from "@angular/core";
import { FormGroup, AbstractControl } from "@angular/forms";
import { TakuInputCtrlBase } from "../TakuInputCtrlBase";

@Component({
  selector: "taku-postal-code",
  templateUrl: "./taku-postal-code.component.html",
  styleUrls: ["./taku-postal-code.component.scss"],
})
export class TakuPostalCodeComponent extends TakuInputCtrlBase implements OnChanges {
  @Input() countryIsoCode: string;

  clear() {
    this.ctrlField.reset();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.ctrlField.updateValueAndValidity({ emitEvent: false });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
