<div class="grid col-12">
  <taku-form-header
    (backClicked)="goBack()"
    toolbarTitle="Taxes"
    iconStyleClass="takuicon-taxes-settings"
    [showActionBtns]="false"
    [displayMode]="FormHeaderDisplayMode.INLINE"
  ></taku-form-header>
  <p-tabMenu [model]="menuTaxes" [activeItem]="menuTaxes[_activeTab]"></p-tabMenu>
  <div *ngIf="!_isMobile" class="card">
    <div class="grid">
      <div class="col">
        <h2>
          <strong> Collect and Track Sales Taxes </strong>
        </h2>
        <hr />
        <p>
          Whether you need standard sales taxes or unique exceptions, TAKU lets you easily add sales taxes to your
          in-person and online invoices and track how much you owe with its unique, flexible tax settings. Remember to
          always speak with a tax expert to determine what taxes you need to setup in TAKU to charge for your in-person
          sales.
        </p>
        <u>Tips for Setting Up Sales Taxes in TAKU</u>
        <ol>
          <li>
            In-person sales taxes are always calculated based on the store the user is logged into and the store address
            saved in Settings > Stores > Manage Stores > View for that store. If the location where you are selling has
            changed, you may change the existing store address or create a new store with a new address. This will not
            affect any past sales documents and there is no charge to set up additional stores.
          </li>
          <li> We calculate in-person sales taxes based on the tax settings you setup in TAKU. </li>
          <li>
            We calculate online sales taxes based on default tax rates which are calculated automatically OR non-default
            tax rates (e.g. exceptions, etc.) which are based on tax settings you setup in TAKU.
          </li>
        </ol>
      </div>
    </div>
  </div>

  <router-outlet></router-outlet>
</div>
