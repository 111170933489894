/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

@Component({
  selector: "taku-gmb-consent-confirmation",
  templateUrl: "./gmb-consent-confirmation.component.html",
  styleUrls: ["./gmb-consent-confirmation.component.scss"],
})
export class GMBConsentConfirmationComponent implements OnInit {
  constructor(private dialogRef: DynamicDialogRef, private dialogConfig: DynamicDialogConfig) {}

  ngOnInit() {}

  onContinuePressed() {
    this.dialogRef.close({
      success: true,
    });
  }

  anotherIntegratorLinkPressed() {
    this.dialogRef.close();
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
