/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable, Inject, LOCALE_ID } from "@angular/core";
import { LocalDataService } from "src/app/shared/services/local-data.service";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { HttpClient } from "@angular/common/http";
import { InventoryHistory } from "src/app/core/reports/reports-list/inventory-history-report/inventory-history";

@Injectable({
  providedIn: "root",
})
export class InventoryHistoryLocalDataService extends LocalDataService {
  constructor(
    protected http: HttpClient,
    protected authService: AuthService,
    private appSettingsService: AppSettingsStorageService,
    @Inject(LOCALE_ID) private localeId: string
  ) {
    super(http, authService);
  }

  getInventoryHistoryReport(inventoryId: number) {
    return this._getRequest<InventoryHistory[]>(this.webUrl + "inventoryHistory/" + inventoryId);
  }

  afterFetch(object, model: string) {}
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
