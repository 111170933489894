/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Address } from "../../../contact-accounts/address/address";
import { AddressEmailType } from "src/app/core/contact-accounts/address-email/address-email";

export class BusinessDetail {
  id: number;
  businessName: string;
  businessType: Business_Type;
  defaultCurrencyIsoCode: string;
  BusinessDetailBusinessLogoUrl: string;
  urlBusinessDetailBusinessLogoUrl: string;
  website: string;
  address: Address;
  businessTimeZone: string;

  constructor() {
    this.id = 0;
    this.businessName = null;
    this.businessType = Business_Type.retail;
    this.defaultCurrencyIsoCode = null;
    // TODO: Set to blank this field
    this.BusinessDetailBusinessLogoUrl = null;
    this.urlBusinessDetailBusinessLogoUrl = null;
    this.website = "";
    this.address = new Address();
    this.address.addressEmail.emailType = AddressEmailType.work;
    this.businessTimeZone = "America/Toronto";
  }
}

export enum Business_Type {
  retail = "Retail",
  wholesale = "Wholesale",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
