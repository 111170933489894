/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit } from "@angular/core";
import { DynamicDialogRef, DynamicDialogConfig } from "primeng/dynamicdialog";
import { ContentAPI_ProductStatuses } from "../gmc-api-data-definitions/ContentAPI_ProductStatutes";
import { Inventory } from "../../../../inventory/inventory/inventory";

@Component({
  selector: "taku-gmc-product-error-details",
  templateUrl: "./gmc-product-error-details.component.html",
  styleUrls: ["./gmc-product-error-details.component.scss"],
})
export class GMCProductErrorDetailsComponent implements OnInit {
  _itemLevelIssues: ContentAPI_ProductStatuses.ItemLevelIssue[];
  _inventory: Inventory;

  constructor(public dialogRef: DynamicDialogRef, public dialogConfig: DynamicDialogConfig) {
    this._inventory = <Inventory>dialogConfig.data;
    this._itemLevelIssues = this._inventory.productStatus.itemLevelIssues;
  }

  ngOnInit() {}
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
