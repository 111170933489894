<div
  *ngFor="let page of pagesInfo"
  id="fullpage-preview-main"
  [ngStyle]="{ fontSize: fontSizeToNumber(settings?.headerFontSize) + 'px' }"
>
  <header>
    <section id="retailer-info">
      <div class="left-side">
        <div *ngIf="settings?.printLogo" class="retailer-logo-wrapper">
          <!-- <div *ngIf="settings.printLogo" class="retailer-logo-wrapper" [ngStyle]="{backgroundImage: businessLogoUrl ? 'url('+businessLogoUrl+')': null}"> -->
          <img *ngIf="businessLogoUrl" [attr.src]="businessLogoUrl" [attr.alt]="businessDetail.businessName" />
          <span *ngIf="!businessLogoUrl" class="retailer-logo-placeholder">YOUR LOGO HERE</span>
        </div>
      </div>

      <div class="right-side">
        <h2 *ngIf="settings?.printStoreName" class="retailer-name">{{ saledoc.store.storeName }}</h2>
        <a href="https://maps.google.com/?q={{ _fullAddressMap }}&t=m" target="_blank">
          <div *ngIf="settings?.printAddress && saledoc.store.address" class="retailer-address">
            <div *ngIf="saledoc.store.address.line1 | trim as line1" class="address-line">{{ line1 }}</div>
            <div *ngIf="saledoc.store.address.line2 | trim as line2" class="address-line">{{ line2 }}</div>
            <div class="address-city"
              >{{ saledoc.store.address.city }},
              {{ subdivisionDisplay(saledoc.store.address.subDivisionIsoCode) }}
              {{ saledoc.store.address.postalCode }}</div
            >
            <div class="address-country">{{ saledoc.store.address.countryIsoCode }}</div>
          </div>
        </a>

        <div class="retailer-bottom-right">
          <div *ngIf="settings?.printPhoneNo && saledoc.store.address?.addressPhone" class="retailer-phone">
            <span class="phone-label">Phone:</span>
            {{ saledoc.store.address?.addressPhone.fullPhoneNumber }}
          </div>

          <div *ngIf="settings?.printEmail" class="retailer-email">
            {{ saledoc.store.address?.addressEmail.email }}
          </div>
        </div>
        <div *ngIf="settings?.printStoreTaxNumber && saledoc?.uniqueTaxAccountNos.length > 0" class="retailer-tax-info">
          Store Tax#: {{ saledoc.uniqueTaxAccountNos.join(", ") }}
        </div>
      </div>
    </section>

    <div class="right-side">
      <div class="printpage-no">-- Page {{ page.pageNumber }} of {{ totalPages }} --</div>
      <section id="receipt-details">
        <h1>
          <span class="receipt-doctype"
            ><ng-template [ngIf]="!hideNotReceiptWatermark">PENDING </ng-template
            >{{ saledoc["isPackingList"] ? "Packing List" : saledoc.doc.documentTitle }}</span
          >
          <span *ngIf="settings?.printDocumentNo" class="receipt-docno">No: {{ saledoc.doc.docNo }}</span>
        </h1>
        <ul class="left-side">
          <li *ngIf="settings?.printDate">
            <span class="receipt-field-label">Date:</span> {{ saledoc.doc.docDate | dateFormat }}
          </li>

          <li *ngIf="settings?.printAccountInfo && saledoc.accountName">
            <span class="receipt-field-label">Account:</span> {{ saledoc.accountName }}
          </li>

          <li *ngIf="saledoc.doc.user">
            <span class="receipt-field-label">User:</span> {{ saledoc.doc.user?.userName }}
          </li>
        </ul>

        <ul class="right-side">
          <li> <span class="receipt-field-label">Time:</span> {{ saledoc.doc.docTime }} </li>

          <li> <span class="receipt-field-label">Store:</span> {{ saledoc.store.storeID }} </li>

          <li> <span class="receipt-field-label">Till:</span> {{ saledoc.station?.stationNumber }} </li>
        </ul>
      </section>
    </div>

    <ng-container *ngTemplateOutlet="invoiceShippingInfo; context: { page: page }"></ng-container>
  </header>

  <main>
    <table
      *ngIf="settings?.printSummary"
      id="doc-lines"
      cellspacing="0"
      [ngStyle]="{ fontSize: fontSizeToNumber(settings?.lineFontSize) + 'px' }"
    >
      <thead>
        <th *ngIf="settings?.printSKU" class="col-SKU">SKU</th>
        <th [attr.colspan]="settings?.printSKU ? 1 : 4" class="col-description">PRODUCT TITLE</th>
        <th class="col-qty">QTY</th>
        <th class="col-price">UNIT PRICE</th>
        <th class="col-amount">TOTAL</th>
      </thead>

      <ng-template ngFor [ngForOf]="page.saleDocLines" let-line>
        <tbody class="transaction-lines">
          <tr class="item-line1">
            <td *ngIf="settings?.printSKU"></td>
            <td [attr.colspan]="settings?.printSKU ? 1 : 4" class="item-description">
              <span class="item-desc-inner">
                <ng-container *ngIf="saledoc['isPackingList']">[&nbsp;&nbsp;&nbsp;]&nbsp;</ng-container>
                {{ line.docLine?.inventory.description1 }}
                <span *ngIf="line.voucher?.uniqueCode">(Unique Code: {{ line.voucher.uniqueCode }})</span>
              </span>
            </td>
            <td class="item-qty">{{ line.qty * (isReturnDoc ? -1 : 1) | formatNegativeNumber }}</td>
            <td class="item-sale-price" [ngClass]="{ 'add-border-right': saledoc['isPackingList'] }">
              <div *ngIf="!saledoc['isPackingList']">
                {{ line.salePrice | currency : _currencyIsoCode : "symbol-narrow" : _digitsFormat
                }}<ng-template [ngIf]="settings?.printUofM && line.docLine?.inventory.uOfMeasure">
                  /{{ line.docLine?.inventory.uOfMeasure?.name }}</ng-template
                >
              </div>
            </td>
            <td class="item-total">
              <span *ngIf="!saledoc['isPackingList']">
                {{
                  line.salePrice * line.qty * (isReturnDoc ? -1 : 1)
                    | currency : _currencyIsoCode : "symbol-narrow" : _digitsFormat
                    | formatNegativeNumber
                }}
                <span *ngIf="settings?.printTaxInventoryType" class="item-tax-name">{{
                  getLineTaxAcronyms(line)
                }}</span>
              </span>
            </td>
          </tr>
          <tr class="item-line2">
            <td *ngIf="settings?.printSKU" class="item-SKU">{{ line.docLine?.inventory.sku }}</td>
            <td [attr.colspan]="settings?.printSKU ? 1 : 4" class="item-variants">
              <span *ngIf="settings?.printVariants">{{ inventoryVariantsStr(line) }} </span>
              <span
                *ngIf="
                  settings?.printVariants &&
                  inventoryVariantsStr(line) &&
                  settings?.printCategoryInLine &&
                  inventoryVariantsStr(line)
                "
              >
                |
              </span>
              <span *ngIf="settings?.printCategoryInLine">{{ categoriesPrettyPrint(line) }}</span>
              <span *ngIf="!settings?.printCategoryInLine"></span>
            </td>
            <td></td>
            <td class="item-price-stock">
              <ng-template [ngIf]="line.discountAmount > 0 && settings.printOriginalPrice && !saledoc['isPackingList']">
                {{ line.unitPrice | currency : _currencyIsoCode : "symbol-narrow" : _digitsFormat
                }}<ng-template [ngIf]="settings.printUofM && line.docLine?.inventory.uOfMeasure">
                  /{{ line.docLine?.inventory.uOfMeasure?.name }}</ng-template
                >
              </ng-template>
            </td>
            <td></td>
          </tr>
          <tr class="item-line3">
            <td *ngIf="settings?.printSKU"></td>
            <td [attr.colspan]="settings?.printSKU ? 1 : 4" class="item-barcode">
              <ng-template [ngIf]="settings?.printBarcodeNo && line.docLine?.inventory.inventoryBarcodes.length > 0">
                {{ line.docLine?.inventory.inventoryBarcodes[0].barcode }}</ng-template
              >
            </td>
            <ng-template [ngIf]="!line.docLine?.note">
              <td></td>
              <td></td>
              <td></td>
            </ng-template>
            <td *ngIf="line.docLine?.note" class="item-note" colspan="3">
              <span class="item-note-inner"
                >NOTE: <em>{{ line.docLine?.note }}</em></span
              >
            </td>
          </tr>
          <tr
            class="item-line4"
            *ngIf="
              line.saleDocLineReturn &&
              line.saleDocLineReturn.storeSettingReturnReason &&
              line.saleDocLineReturn.storeSettingReturnReason.returnReason
            "
          >
            <!-- <tr class="item-line4"> -->
            <td></td>
            <td></td>
            <td colspan="3" class="item-return-reason"
              >** Return Reason:
              <span>{{ line.saleDocLineReturn?.storeSettingReturnReason?.returnReason }}</span> **</td
            >
            <!-- <td colspan="3" class="item-return-reason">** Return Reason: In-Transit Damage **</td> -->
          </tr>
        </tbody>
      </ng-template>

      <!-- <tbody class="transaction-lines">
              <tr class="item-line1">
                  <td colspan="2" class="item-description"><span class="item-desc-inner">CHICKEN NUGETS 10-PACK
                          WITH CHEESE</span></td>
                  <td class="item-qty">2</td>
                  <td class="item-price-stock">$25.00/ea</td>
                  <td class="item-total">$50.00 <span class="item-tax-name">H</span></td>
              </tr>
              <tr class="item-line2">
                  <td class="item-SKU">5456454564</td>
                  <td class="item-variants">Salami Flavor, Light</td>
                  <td></td>
                  <td class="item-sale-price"></td>
                  <td></td>
              </tr>
              <tr class="item-line3">
                  <td></td>
                  <td class="item-barcode">4564654879879877878</td>
                  <td class="item-note" colspan="3">
                      <span class="item-note-inner">NOTE: <em>Discount applied due to loyal customer</em></span>
                  </td>
              </tr>
          </tbody> -->
    </table>
  </main>

  <ng-container *ngTemplateOutlet="invoiceFooter; context: { page: page }"></ng-container>
  <div *ngIf="!hideNotReceiptWatermark" class="not-receipt-watermark first">NOT A RECEIPT</div>
</div>

<ng-template let-page="page" #invoiceShippingInfo>
  <div style="height: 100% !important">
    <section
      *ngIf="
        page.isFirstPage &&
        settings?.printShippingAddress &&
        hasAddressInfo(saledoc.shippingAddress) &&
        saledoc.shippingAddress as shipping
      "
      id="ship-to"
      class="billing-info-block"
    >
      <div class="header"><span class="label">SHIP TO:</span></div>
      <b>{{ shipping.attnCompanyName }}</b>
      <div *ngIf="settings?.printShippingAddress" class="ship-address">
        <div class="address-line1">{{ shipping.line1 }}</div>
        <div *ngIf="shipping.line2" class="address-line2">{{ shipping.line2 }}</div>
        <div class="address-city">
          {{ shipping.city || "" }}{{ (shipping.city ? ", " : "") + subdivisionDisplay(shipping.subDivisionIsoCode)
          }}{{ (subdivisionDisplay(shipping.subDivisionIsoCode) ? ", " : "") + (shipping.postalCode || "")
          }}{{ (shipping.postalCode ? " " : "") + (shipping.countryIsoCode || "") }}
        </div>
        <!-- <div *ngIf="shipping.countryIsoCode" class="address-country"><span *ngIf="shipping.postalCode">&nbsp;</span>{{shipping.countryIsoCode}}</div> -->
      </div>
      <div *ngIf="shipping.note" class="ship-to-note">
        <div>Note: {{ shipping.note }}</div>
      </div>
      <div *ngIf="settings?.printShippingAddress" class="ship-attn">
        <div *ngIf="shipping.attnFullPersonName" class="attn-name">
          <b><span class="field-label">ATTN:&nbsp;</span>{{ shipping.attnFullPersonName }}</b>
        </div>
        <div *ngIf="shipping.addressPhone?.tel" class="attn-phone">
          <b>{{ shipping.addressPhone.fullPhoneNumber }}</b>
        </div>
        <div *ngIf="shipping.addressEmail?.email" class="attn-email">
          <b>{{ shipping.addressEmail.email }}</b>
        </div>
      </div>
    </section>
  </div>
  <div style="height: 100% !important">
    <section
      *ngIf="
        page.isFirstPage &&
        settings?.printBillingAddress &&
        hasAddressInfo(saledoc.billingAddress) &&
        saledoc.billingAddress as billing
      "
      id="bill-to"
      class="billing-info-block"
    >
      <div class="header"><span class="label">BILL TO:</span></div>
      <b>{{ billing.attnCompanyName }}</b>
      <div class="bill-address">
        <div *ngIf="billing.line1" class="address-line1">{{ billing.line1 }}</div>
        <div *ngIf="billing.line2" class="address-line2">{{ billing.line2 }}</div>
        <div class="address-city">
          {{ billing.city || "" }}{{ (billing.city ? ", " : "") + subdivisionDisplay(billing.subDivisionIsoCode)
          }}{{ (subdivisionDisplay(billing.subDivisionIsoCode) ? ", " : "") + (billing.postalCode || "")
          }}{{ (billing.postalCode ? " " : "") + (billing.countryIsoCode || "") }}
        </div>
        <!-- <div *ngIf="billing.countryIsoCode" class="address-country"><span *ngIf="billing.postalCode">&nbsp;</span>{{billing.countryIsoCode}}</div> -->
      </div>
      <div *ngIf="billing.note" class="bill-note">
        <div>Note: {{ billing.note }}</div>
      </div>
      <div class="bill-to-attn">
        <div *ngIf="billing.attnFullPersonName" class="attn-name">
          <b><span class="field-label">ATTN:&nbsp;</span>{{ billing.attnFullPersonName }}</b>
        </div>
        <div *ngIf="billing.addressPhone?.tel" class="attn-phone">
          <b>{{ billing.addressPhone.fullPhoneNumber }}</b>
        </div>
        <div *ngIf="billing.addressEmail?.email" class="attn-email">
          <b>{{ billing.addressEmail.email }}</b>
        </div>
      </div>
      <div *ngIf="settings?.printTaxNumber && saledoc.taxID" class="retailer-tax-info"
        >Account Tax ID#: {{ saledoc.taxID }}</div
      >
    </section>
  </div>
  <section
    *ngIf="
      !page.isFirstPage && settings?.printShippingAddress && saledoc.shippingAddress?.attnCompanyName as companyName
    "
    id="ship-to"
    class="billing-info-block single-line"
  >
    <div class="header"><span class="label">SHIP TO:</span> {{ companyName }}</div>
  </section>

  <section
    *ngIf="!page.isFirstPage && settings?.printBillingAddress && saledoc.billingAddress?.attnCompanyName as companyName"
    id="bill-to"
    class="billing-info-block single-line"
  >
    <div class="header"><span class="label">BILL TO:</span> {{ companyName }}</div>
  </section>
</ng-template>

<ng-template let-page="page" #invoiceFooter>
  <section *ngIf="page.isLastPage && saledoc.doc.comment" id="document-note">
    <span class="field-label">INVOICE NOTE:</span> {{ saledoc.doc.comment }}
  </section>

  <div *ngIf="!saledoc['isPackingList']">
    <footer *ngIf="page.isLastPage">
      <div class="left-side">
        <div id="payment-wrapper">
          <section *ngIf="settings?.printTransactionRecord" id="transaction-record">
            <div class="transaction-integration">
              CREDIT CARD INTEGRATION GOES HERE <br />
              (Only shown when one of the payments is a Card)
            </div>
          </section>

          <section id="payment-section">
            <section
              *ngIf="settings?.printTenderDetail && saledoc.saleDocTenders.length > 0"
              id="cards-payments"
              class="payment-summary"
            >
              <table>
                <thead>
                  <tr>
                    <th colspan="3">PAY TYPE DETAILS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let tenderTransaction of saledoc.saleDocTenders; let i = index">
                    <td class="line-no">Line {{ i + 1 }}</td>
                    <td class="label">
                      <div>{{ tenderTransaction.tenderType.description }}</div>
                      <div *ngIf="tenderTransaction.voucher?.uniqueCode">
                        Unique Code {{ tenderTransaction.voucher.uniqueCode }}
                      </div>
                      <div *ngIf="tenderTransaction.refNo">Ref # {{ tenderTransaction.refNo }}</div>
                    </td>
                    <td class="amount">
                      {{
                        tenderTransaction.amount
                          | currency : _currencyIsoCode : "symbol-narrow" : _digitsFormat
                          | formatNegativeNumber
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>

              <!-- <ul class="ref-info">
                            <ng-template ngFor [ngForOf]="_cardPayments" let-cardPayment let-i="index">
                                <li *ngIf="cardPayment.refNo">Line {{i+1}}: Ref # {{cardPayment.refNo}}</li>
                            </ng-template>
                        </ul> -->
            </section>

            <!-- <section *ngIf="settings.printTenderDetail && _otherPayments.length > 0" id="other-payments" class="payment-summary">
                        <table>
                            <thead>
                                <tr>
                                    <th colspan="2">OTHER PAYMENTS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let payment of _otherPayments">
                                    <td class="label">{{payment.tenderType.description}}</td>
                                    <td class="amount">{{payment.amount|currency:_currencyIsoCode:'symbol-narrow':_digitsFormat|formatNegativeNumber}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </section> -->

            <section
              *ngIf="settings?.printTenderDetail && this._totalCashPayments != 0"
              id="cash-payment"
              class="payment-summary"
            >
              <div *ngIf="settings?.printTransactionRecord" class="divider"></div>

              <table>
                <thead>
                  <tr>
                    <th colspan="2">CASH PAYMENT</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngIf="settings?.printPennyRounding" class="penny-rounding">
                    <td class="label">Cash Rounding</td>
                    <td class="amount">
                      {{
                        saledoc.cashRounding
                          | currency : _currencyIsoCode : "symbol-narrow" : _digitsFormat
                          | formatNegativeNumber
                      }}
                    </td>
                  </tr>
                  <tr class="rounded-balance">
                    <td class="label">Rounded Balance</td>
                    <td class="amount">
                      {{
                        _roundedBalanceBeforeCash
                          | currency : _currencyIsoCode : "symbol-narrow" : _digitsFormat
                          | formatNegativeNumber
                      }}
                    </td>
                  </tr>
                  <tr class="cash-payment">
                    <td class="label">Payment</td>
                    <td class="amount">
                      {{
                        _totalCashPayments
                          | currency : _currencyIsoCode : "symbol-narrow" : _digitsFormat
                          | formatNegativeNumber
                      }}
                    </td>
                  </tr>
                  <tr class="change-due">
                    <td class="label">Change Due</td>
                    <td class="amount">
                      {{
                        Math.max(0, _totalCashPayments - _roundedBalanceBeforeCash)
                          | currency : _currencyIsoCode : "symbol-narrow" : _digitsFormat
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>

            <div *ngIf="!isReturnDoc && printInvoiceSavingLine && saledoc.totalSavings > 0" id="saving-amount">
              YOU SAVED: {{ saledoc.totalSavings | currency : _currencyIsoCode : "symbol-narrow" : _digitsFormat }}
            </div>
            <!-- <div *ngIf="!isReturnDoc && saledoc.totalSavings > 0" id="saving-amount">
              YOU SAVED: {{saledoc.totalSavings|currency:_currencyIsoCode:'symbol-narrow':_digitsFormat}}
            </div> -->
          </section>
        </div>

        <section *ngIf="printReturnPolicy && policies?.returnPolicy" id="returns-policies">
          <!-- <section *ngIf="policies?.returnPolicy" id="returns-policies"> -->
          <span class="title">STORE POLICY:</span>
          <span class="content" [innerHTML]="policies.returnPolicy"></span>
        </section>

        <section *ngIf="printSalePolicy && policies?.salesPolicy" id="sales-policies">
          <!-- <section *ngIf="policies?.salesPolicy" id="sales-policies"> -->
          <span class="title">SALE POLICY:</span>
          <span class="content" [innerHTML]="policies.salesPolicy"></span>
        </section>
      </div>

      <div class="right-side">
        <section id="receipt-summary-totals">
          <table cellspacing="0">
            <tr class="receipt-subtotal">
              <td class="label" colspan="2">SUBTOTAL</td>
              <td class="amount" colspan="2">
                {{
                  saledoc.subTotal * (isReturnDoc ? -1 : 1)
                    | currency : _currencyIsoCode : "symbol-narrow" : _digitsFormat
                    | formatNegativeNumber
                }}
              </td>
            </tr>
            <tr class="receipt-discount">
              <td class="label" colspan="2"
                >DISCOUNT <span *ngIf="saledoc.discountType === 'Percentage'">({{ saledoc.discountAmount }}%)</span></td
              >
              <td class="amount" colspan="2">
                {{
                  (isReturnDoc ? 1 : -1) * saledoc.discountAmountMoney
                    | currency : _currencyIsoCode : "symbol-narrow" : _digitsFormat
                    | formatNegativeNumber
                }}
              </td>
            </tr>
            <tr *ngFor="let linetax of saledoc.groupedTaxLines" class="receipt-tax">
              <td class="label" colspan="2">TAX ({{ linetax.saleTaxRule?.tax?.shortDesc }})</td>
              <td class="amount" colspan="2">
                {{
                  linetax.total * (isReturnDoc ? -1 : 1)
                    | currency : _currencyIsoCode : "symbol-narrow" : _digitsFormat
                    | formatNegativeNumber
                }}
              </td>
            </tr>
            <!-- <tr *ngFor="let saletax of saledoc.saleDocTaxes" class="receipt-tax">
                        <td class="label" colspan="2">TAX <ng-template [ngIf]="saletax.tax">{{saletax.tax.shortDesc}}</ng-template>
                            (?%)</td>
                        <td class="amount" colspan="2">{{saletax.amount * (isReturnDoc ? -1 : 1)|currency:_currencyIsoCode:'symbol-narrow':_digitsFormat|formatNegativeNumber}}</td>
                    </tr> -->
            <tr class="receipt-shipping">
              <td class="label" colspan="2">SHIPPING</td>
              <td class="amount" colspan="2">
                {{
                  saledoc.shipperChargeAmount * (isReturnDoc ? -1 : 1)
                    | currency : _currencyIsoCode : "symbol-narrow" : _digitsFormat
                    | formatNegativeNumber
                }}
              </td>
            </tr>
            <tr class="receipt-cash-rounding">
              <td class="label" colspan="2">CASH ROUNDING</td>
              <td class="amount" colspan="2">
                {{
                  saledoc.cashRounding
                    | currency : _currencyIsoCode : "symbol-narrow" : _digitsFormat
                    | formatNegativeNumber
                }}
              </td>
            </tr>
            <tr class="receipt-grand-total">
              <th colspan="2">
                <span class="item-count">{{ saledoc.totalItems }} items</span>
                <span class="label">TOTAL</span>
              </th>
              <th class="amount" colspan="2">
                {{
                  saledoc.grandTotal * (isReturnDoc ? -1 : 1)
                    | currency : _currencyIsoCode : "symbol-narrow" : _digitsFormat
                    | formatNegativeNumber
                }}
              </th>
            </tr>
          </table>
        </section>

        <section *ngIf="!isReturnDoc && saledoc.doc.docNo" id="returns-barcode">
          <!-- <div class="instructions">PRESENT THIS BARCODE FOR RETURNS</div> -->
          <div class="barcodeWrapper">
            <svg class="barcode-render" #returnsBarcode></svg>
          </div>
        </section>

        <section *ngIf="settings?.customerNote" id="acknowledge-note">
          <!-- <h3 class="header">"Thank you for your preference"</h3> -->
          <span>{{ settings?.customerNote }}</span>
        </section>

        <a href="https://takulabs.io/sign-up-today" target="_blank">
          <section *ngIf="settings?.printPoweredByTalulabs" id="powered-by">
            <img src="/assets/components/receipt-preview/images/taku-logo.png" alt="TakuLabs" class="app-logo" />
            <span class="text">Powered by TAKU</span>
          </section>
        </a>

        <div *ngIf="!hideNotReceiptWatermark" class="not-receipt-watermark second">NOT A RECEIPT</div>
      </div>
    </footer>
  </div>
  <footer *ngIf="!page.isLastPage">
    <div id="receipt-total-line"
      >INVOICE TOTAL: {{ saledoc.grandTotal | currency : _currencyIsoCode : "symbol-narrow" : _digitsFormat }}</div
    >
  </footer>
</ng-template>
