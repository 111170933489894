/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { TAKUPayoutsReportLocalDataService } from "./taku-payouts-report-local-data.service";
import { Subscription } from "rxjs";
import { FormListComponent } from "src/app/form-list/form-list/form-list.component";
import { RowSelectionType } from "src/app/form-list/form-list/form-list";
import { MessageService } from "primeng/api";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";
import { ReportParamsService } from "../../report-params.service";
import { ReportFilterFields } from "../../report-filter-fields";
import { TAKUPayoutsReport } from "./taku-payouts-report";
import { TAKUPayoutsReportCols, TAKUPayoutsReportColsFactoryService } from "./taku-payouts-cols.service";
import { resetReportRowState } from "../../report";
import { LocalDataService } from "src/app/shared/services/local-data.service";

@Component({
  selector: "taku-taku-payouts-report",
  templateUrl: "./taku-payouts-report.component.html",
  styleUrls: ["./taku-payouts-report.component.scss"],
  providers: [TAKUPayoutsReportLocalDataService],
})
export class TAKUPayoutsReportComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];
  @ViewChild("formListReport") formListComponent: FormListComponent;

  _model = "takuPayoutsReport";
  _stateKey = "FL-" + this._model;
  RowSelectionType = RowSelectionType;
  public reportCols: TAKUPayoutsReportCols;
  dynamicFields: string[] = [];

  constructor(
    private reportColsFactoryService: TAKUPayoutsReportColsFactoryService,
    public takuPayoutsReportLocalService: TAKUPayoutsReportLocalDataService,
    private messageService: MessageService,
    private alertMessagesService: AlertMessagesService,
    public reportParamsService: ReportParamsService,
    private localDataService: LocalDataService
  ) {
    this.reportCols = this.reportColsFactoryService.build([]);
  }

  ngOnInit() {
    this.subsList.push(
      this.reportParamsService.reportParamsChanged$.subscribe((filters: ReportFilterFields) => {
        this.updateReport(filters);

        resetReportRowState(this._stateKey);
      })
    );
  }

  private updateReport(filters: ReportFilterFields) {
    this.reportParamsService._isFetchingData = true;
    this.subsList.push(
      this.takuPayoutsReportLocalService
        .getTAKUPayoutsReport(filters.takuPaymentAccountId, filters.dateFrom, filters.dateTo)
        .subscribe({
          next: (rows) => {
            this.buildFormlistCols(rows && rows.length ? rows[0] : null);
            this.refreshFormList(rows || []);
            this.reportParamsService._isFetchingData = false;
            this.localDataService.updateNewObject(rows);
          },
          error: (error) => {
            this.buildFormlistCols(null);
            // Show toast with error message
            this.messageService.add(this.alertMessagesService.getErrorMessage(error));
            // Empty form list
            this.refreshFormList([]);
            this.reportParamsService._isFetchingData = false;
          },
        })
    );
  }

  private buildFormlistCols(rowData: TAKUPayoutsReport) {
    this.dynamicFields = rowData
      ? Object.keys(rowData).filter((key) => !Object.keys(new TAKUPayoutsReport()).includes(key))
      : [];
    this.reportCols = this.reportColsFactoryService.build(this.dynamicFields);
  }

  private refreshFormList(data: any[]) {
    data.forEach((takuPayouts) => {
      this.dynamicFields.forEach((key) => {
        takuPayouts[key] = parseFloat(takuPayouts[key]) || 0;
      });
    });
    this.takuPayoutsReportLocalService.initialize(data, this._model);
    setTimeout(() => {
      this.formListComponent.reloadData();
    }, 0);
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
