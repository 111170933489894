<div class="grid page-container">
  <div class="secondary-progressbar col-12" id="data-progressbar">
    <p-progressBar mode="indeterminate" value="0" [style.visibility]="_isFetchingData ? 'visible' : 'hidden'">
    </p-progressBar>
  </div>

  <div *ngIf="!_isMobile && _bulkEditorEnabled && active && _isFormInEditMode" class="col-12 bulk-editor">
    <taku-form-list-bulk-editor
      [hidden]="!_isFormInEditMode"
      [_visibleCols]="_allVisibleCols"
      [_selectedRows]="getSelectedRows()"
      [_orgObjects]="_orgObjects"
      [_selectedForms]="getSelectedFormGroups()"
      [_totalVisibleRows]="_objects.length"
      (onSelectAllRows)="selectAllRows()"
      (onUnselectAllRows)="unSelectAllRows()"
      (onEditingColSelected)="setHighLightedCol($event)"
      [_model]="_model"
    ></taku-form-list-bulk-editor>
  </div>

  <!-- There is a bug that make frozen columns not show when scrollable is set to false. It doesn't affect mobile because it doesn't use frozen columns, but desktop only. -->
  <div
    #tableWrapper
    class="col-12 table-container"
    [hidden]="_newForm"
    [ngStyle]="{ '--formlist-scroll-height': tableScrollHeight }"
    [ngClass]="{
      'hide-header': !_showHeader,
      'has-paginator': showPagination,
      'table-hasVertical-scrollbar':
        _elScrollableBody && _elScrollableBody.scrollHeight > _elScrollableBody.clientHeight,
      'table-hasHorizontal-scrollbar': _hasAnyScrolling,
      'has-left-scroll': _hasLeftScrolling,
      'has-right-scroll': _hasRightScrolling
    }"
  >
    <!-- in PrimeNG 14, the guiding arrows when you are re-ordering a column are broken https://github.com/primefaces/primeng/issues/11778 -->
    <p-table
      #dt
      [hidden]="!active"
      [columns]="visibleScrollableCols"
      [value]="_objects"
      [lazy]="true"
      [rows]="defaultRows"
      [first]="_first"
      [paginator]="showPagination"
      [pageLinks]="3"
      responsiveLayout="stack"
      [breakpoint]="MobileBreakpoint"
      [columnResizeMode]="columnResizeMode"
      [exportFunction]="tableExportFn"
      [rowsPerPageOptions]="pageSizes"
      [resizableColumns]="true"
      [(selection)]="_selectedObjects"
      (onColReorder)="onColReorder($event)"
      (onColResize)="refreshStickyColumns()"
      (onRowSelect)="onRowSelected($event)"
      (onRowUnselect)="onRowUnselected($event)"
      paginatorDropdownAppendTo="body"
      exportFilename="takuCSV"
      [reorderableColumns]="!_isFormInEditMode"
      [totalRecords]="totalRecords"
      (onLazyLoad)="loadObjectsLazy($event)"
      styleClass="p-datatable-striped"
      [tableStyle]="_isMobile ? '' : 'table-layout:fixed'"
      [scrollable]="!_isMobile"
      [scrollHeight]="tableBodyHeight"
      (onStateSave)="onSaveState($event)"
      dataKey="_uniqueid"
      [stateStorage]="_stateStorage"
      [stateKey]="_stateKey"
      [sortField]="_sortField"
      [sortOrder]="_sortOrder"
      [lazyLoadOnInit]="false"
      [resetPageOnSort]="false"
      (onPage)="pageChange($event)"
      [loading]="_isFetchingData"
    >
      <ng-template pTemplate="caption">
        <ng-container *ngIf="_showHeader">
          <div class="grid inner-header">
            <div class="grid col-fixed buttons-left">
              <ng-template [ngIf]="_isEditable">
                <p-multiSelect
                  *ngIf="_optionCols.length > 2 && !_isMobile"
                  (onPanelShow)="onColSelectorOpened()"
                  class="col-fixed active-columns-selector mr-3"
                  [options]="_optionCols"
                  [ngModel]="_selectorColsChecked"
                  (ngModelChange)="changeVisibleColumns($event)"
                  [maxSelectedLabels]="0"
                  selectedItemsLabel="{0} columns"
                  placeholder="Choose Columns"
                  (onChange)="columnListChange($event)"
                  scrollHeight="370px"
                  appendTo="body"
                  [ngStyle]="{ 'width.px': 125 }"
                  #columnSelector
                >
                  <ng-template let-item pTemplate="item">
                    <span class="ui-helper-clearfix colSelectorItem-wrapper">
                      <i *ngIf="item.icon" class="item-icon {{ item.icon }}"></i>
                      <span class="item-label">{{ item.label }}</span>
                    </span>
                  </ng-template>
                </p-multiSelect>

                <div *ngIf="!_hideSavingCtrls" class="flex mt-1 ml-2 buttons-group">
                  <button
                    pButton
                    type="button"
                    class="p-button-rounded header-button"
                    [disabled]="_isFormInEditMode"
                    icon="pi pi-pencil"
                    (click)="enableEditMode()"
                    pTooltip="Edit table on page"
                    tooltipPosition="top"
                  >
                  </button>

                  <!-- <button pButton type="button" [disabled]="!_isFormInEditMode" (click)="saveAll()" label="Save"></button> -->
                  <button
                    pButton
                    type="button"
                    class="p-button-rounded header-button"
                    [ngClass]="{ 'p-button-ternary-custom': !isPristine() }"
                    icon="takuicon-save-all"
                    [disabled]="
                      !_isFormInEditMode || hasActiveValidationErrors || (_submitAttempted && !areAllFieldsValid)
                    "
                    (click)="saveAll()"
                    pTooltip="Save All"
                    tooltipPosition="top"
                  ></button>

                  <button
                    pButton
                    type="button"
                    class="p-button-rounded header-button p-button-icon-only"
                    icon="_"
                    [disabled]="!_isFormInEditMode"
                    (click)="discardAll()"
                    pTooltip="Discard All"
                    tooltipPosition="top"
                    ><i class="material-icons"> undo </i></button
                  >
                </div>
              </ng-template>
            </div>
            <div class="col table-title-wrapper">
              <h3>
                <span *ngIf="_isFormInEditMode" class="editMode-title">(Edit Mode)</span>
                <span class="formlist-title">{{ tableFullTitle }}</span>
                <a
                  *ngIf="_headerInfo"
                  href="javascript://"
                  class="pi pi-info-circle"
                  style="padding: 5px; color: white"
                  (click)="globalSearchInfoPanel.toggle($event)"
                >
                  <p-overlayPanel #globalSearchInfoPanel [showCloseIcon]="false" appendTo="body">
                    <div class="tooltip-styling" [innerHTML]="_headerInfo"> </div>
                  </p-overlayPanel>
                </a>
              </h3>
            </div>
            <div class="col-fixed buttons-right">
              <div FormListHeaderCustomBtns class="headerCustomBtns-wrapper">
                <ng-content select="[takuFormListHeaderCustomBtns]"></ng-content>
              </div>

              <taku-form-list-header
                (newLineClicked)="newRowPressed()"
                (newClicked)="goAdd()"
                (deleteClicked)="deleteSelectedRows()"
                (exportClicked)="onExportRows($event)"
                (cloneClicked)="onCloneRows()"
                [hideAdd]="_detailMode === 'n' || _hideAddHeaderBtn"
                [hideAddLine]="!_isEditable || _hideAddLineHeaderBtn"
                [hideClone]="_hideClone"
                [hideDelete]="_rowSelectionType === RowSelectionType.NONE || _hideDeleteHeaderBtn"
                [hideMoreOptions]="_hideOptionsHeaderBtn"
                [itemDescription]="_model | camelToSentence"
                [modelName]="_model"
                [cloneModeEnabled]="_cloneModeEnabled"
                [modelColsService]="_modelColsService"
              >
              </taku-form-list-header>
            </div>
          </div>

          <div *ngIf="!_hideGlobalFilter" class="grid globalFilter-wrapper">
            <input
              type="text"
              pInputText
              [(ngModel)]="_inputFilterValues['global']"
              placeholder="Search by Keyword"
              (input)="dt.filterGlobal($event.target.value, 'contains')"
            />
            <span class="input-icons">
              <a
                href="javascript://"
                class="pi pi-times"
                [hidden]="!inputGlobalFilter"
                (click)="clearGlobalFilter()"
              ></a>
              <i class="pi pi-search"></i>
            </span>
            <a
              href="javascript://"
              class="pi pi-info-circle"
              (click)="globalSearchInfoPanel.toggle($event)"
              style="padding-top: 20px"
            >
              <p-overlayPanel #globalSearchInfoPanel [showCloseIcon]="false" appendTo="body">
                <div class="tooltip-styling">
                  <h5>Global Filter Tips</h5>
                  <ul>
                    <li>Keywords are partial searches</li>
                    <li>Numbers will return exact match or partial match for alpha-numeric fields</li>
                    <li>Dropdown fields with multiple values cannot be searched at this time</li>
                  </ul>
                </div>
              </p-overlayPanel>
            </a>
          </div>
        </ng-container>
      </ng-template>

      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <col *ngFor="let col of visibleScrollableCols; trackBy: trackByColDef" />
        </colgroup>
      </ng-template>

      <ng-template pTemplate="header" let-columns>
        <tr *ngIf="!_isMobile" class="row-columnheaders">
          <th
            class="coltype-selectrow"
            *ngIf="_rowSelectionType !== RowSelectionType.NONE"
            [style.width]="FrozenColsWidths.selectrow"
            pFrozenColumn
          >
            <div [ngSwitch]="_rowSelectionType">
              <p-tableHeaderCheckbox
                #selectAllCheckBox
                *ngSwitchCase="RowSelectionType.MULTIPLE"
              ></p-tableHeaderCheckbox>
            </div>
          </th>

          <th
            class="viewdetail-column"
            *ngIf="showDetailsColumn"
            pFrozenColumn
            [style.width]="_viewColIcon ? FrozenColsWidths.execute_event : FrozenColsWidths.viewrow"
          >
            <button
              pButton
              pRipple
              type="button"
              [disabled]="_dt && !_dt.multiSortMeta && !_dt.sortField"
              class="clear-sorting-btn p-button-secondary p-button-rounded"
              icon="takuicon-clear-sorting-icon"
              pTooltip="Remove sorting"
              tooltipPosition="left"
              (click)="clearAllSorting()"
            ></button>
          </th>

          <th class="settings-column" *ngIf="_settingsCol" pFrozenColumn [style.width]="FrozenColsWidths.settings">
            <button
              *ngIf="_detailMode === 'n'"
              pButton
              pRipple
              type="button"
              [disabled]="_dt && !_dt.multiSortMeta && !_dt.sortField"
              class="clear-sorting-btn p-button-secondary p-button-rounded"
              icon="takuicon-clear-sorting-icon"
              pTooltip="Remove sorting"
              tooltipPosition="left"
              (click)="clearAllSorting()"
            ></button>
          </th>

          <th
            class="deleterow-column"
            *ngIf="_showDeleteCol"
            pFrozenColumn
            [style.width]="FrozenColsWidths.deleterow"
          ></th>

          <th
            *ngIf="!hasSystemColumns && !_hideFilterControls"
            class="clearstate-column"
            pFrozenColumn
            [style.width]="FrozenColsWidths.clear_state"
          >
            <button
              pButton
              pRipple
              type="button"
              [disabled]="_dt && !_dt.multiSortMeta && !_dt.sortField"
              class="clear-sorting-btn p-button-secondary p-button-rounded"
              icon="takuicon-clear-sorting-icon"
              pTooltip="Remove sorting"
              tooltipPosition="left"
              (click)="clearAllSorting()"
            ></button>
          </th>

          <th
            [class]="'coltype-' + col.dataType"
            *ngFor="let col of colsFrozen; trackBy: trackByColDef"
            pFrozenColumn
            [pSortableColumn]="areControlsEnabled && !col.isNotSortable ? col.field : null"
            [pSortableColumnDisabled]="!areControlsEnabled || col.isNotSortable"
            pResizableColumn
            [style.width.px]="getColWidth(col)"
            [ngClass]="{ 'highlighted-col': isColHighlighted(col) }"
          >
            <div class="headcell-wrapper">
              <!-- <i class="takuicon-draggable-item draggable-icon"></i> -->
              <h3 [innerHTML]="getColumnHeader(col)"></h3>
              <p-sortIcon *ngIf="areControlsEnabled && !col.isNotSortable" [field]="col.field"></p-sortIcon>
              <a
                *ngIf="col.info"
                href="javascript://"
                class="pi pi-info-circle ui-cilckable"
                (click)="$event.stopPropagation(); globalSearchInfoPanel.toggle($event)"
              >
                <p-overlayPanel #globalSearchInfoPanel [showCloseIcon]="false" appendTo="body" style="max-width: 190pt">
                  <div [innerHTML]="col.info"></div>
                </p-overlayPanel>
              </a>
            </div>
          </th>
          <th
            [class]="'coltype-' + col.dataType"
            *ngFor="let col of visibleScrollableCols; trackBy: trackByColDef"
            [pSortableColumn]="areControlsEnabled && !col.isNotSortable ? col.field : null"
            [pSortableColumnDisabled]="!areControlsEnabled || col.isNotSortable"
            pResizableColumn
            pReorderableColumn
            [pReorderableColumnDisabled]="_isFormInEditMode"
            [style.width.px]="getColWidth(col)"
            [ngClass]="{ 'highlighted-col': isColHighlighted(col) }"
          >
            <div class="headcell-wrapper">
              <i class="takuicon-draggable-item draggable-icon"></i>
              <h3 class="text-center" [innerHTML]="getColumnHeader(col)"></h3>
              <p-sortIcon *ngIf="areControlsEnabled && !col.isNotSortable" [field]="col.field"></p-sortIcon>
              <a
                *ngIf="col.info"
                href="javascript://"
                class="pi pi-info-circle ui-cilckable"
                (click)="$event.stopPropagation(); globalSearchInfoPanel.toggle($event)"
              >
                <p-overlayPanel #globalSearchInfoPanel [showCloseIcon]="false" appendTo="body">
                  <div [innerHTML]="col.info"></div>
                </p-overlayPanel>
              </a>
            </div>
          </th>
        </tr>

        <tr *ngIf="!_isMobile && !_hideFilterControls" class="row-filters">
          <th
            *ngIf="_rowSelectionType !== RowSelectionType.NONE"
            class="coltype-selectrow"
            pFrozenColumn
            [style.width]="FrozenColsWidths.selectrow"
            [ngClass]="{ hasClearFilter: _detailMode === 'n' && !_settingsCol }"
          >
            <button
              *ngIf="_detailMode === 'n' && !_settingsCol"
              pButton
              pRipple
              type="button"
              [disabled]="_dt && (_dt.filters | json) === '{}'"
              class="clear-filters-btn p-button-secondary p-button-rounded"
              icon="takuicon-clear-filters"
              pTooltip="Remove all filters"
              tooltipPosition="left"
              (click)="clearAllFilters()"
            ></button>
          </th>
          <th
            class="viewdetail-column"
            *ngIf="showDetailsColumn"
            pFrozenColumn
            [style.width]="FrozenColsWidths.viewrow"
          >
            <button
              pButton
              type="button"
              [disabled]="_dt && (_dt.filters | json) === '{}'"
              class="clear-filters-btn p-button-secondary p-button-rounded"
              icon="takuicon-clear-filters"
              pTooltip="Remove all filters"
              tooltipPosition="left"
              (click)="clearAllFilters()"
            ></button>
          </th>
          <th class="settings-column" *ngIf="_settingsCol" pFrozenColumn [style.width]="FrozenColsWidths.settings">
            <button
              *ngIf="_detailMode === 'n'"
              pButton
              type="button"
              [disabled]="_dt && (_dt.filters | json) === '{}'"
              class="clear-filters-btn p-button-secondary p-button-rounded"
              icon="takuicon-clear-filters"
              pTooltip="Remove all filters"
              tooltipPosition="left"
              (click)="clearAllFilters()"
            ></button>
          </th>

          <th
            class="deleterow-column"
            *ngIf="_showDeleteCol"
            pFrozenColumn
            [style.width]="FrozenColsWidths.deleterow"
          ></th>

          <th
            *ngIf="!hasSystemColumns && !_hideFilterControls"
            class="clearstate-column"
            pFrozenColumn
            [style.width]="FrozenColsWidths.clear_state"
          >
            <button
              pButton
              type="button"
              [disabled]="_dt && (_dt.filters | json) === '{}'"
              class="clear-filters-btn p-button-rounded p-button-secondary"
              icon="takuicon-clear-filters"
              pTooltip="Remove all filters"
              tooltipPosition="left"
              (click)="clearAllFilters()"
            ></button>
          </th>

          <ng-container
            *ngTemplateOutlet="filtersColumnsTpl; context: { $implicit: colsFrozen, dt: dt }"
          ></ng-container>
          <ng-container *ngTemplateOutlet="filtersColumnsTpl; context: { $implicit: visibleScrollableCols, dt: dt }">
          </ng-container>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
        <tr *ngIf="_isMobile && getRowOffset(rowIndex) === 0" class="mobileFeatures-row">
          <td style="width: 100% !important; max-width: 100% !important">
            <div class="p-g-8 colSorting-wrapper">
              <taku-dropdown
                [disabled]="_isFormInEditMode"
                class="sort-col"
                [options]="_lookupSortableCols"
                [formGroup]="_sortingForm"
                fieldName="sortField"
                fieldCaption="Sort By"
                appendTo="body"
              ></taku-dropdown>
              <a
                [hidden]="!_sortField"
                href="javascript://"
                class="sort-direction pi pi-sort"
                (click)="_sortOrder = -1 * _sortOrder"
                [ngClass]="{
                  'disabled-link': _isFormInEditMode,
                  'desc-sorting': _sortOrder == -1,
                  'asc-sorting': _sortOrder == 1
                }"
              ></a>
            </div>
          </td>
        </tr>
        <tr
          [formGroup]="this._getFormGroup(rowIndex)"
          [pSelectableRow]="rowData"
          class="column-container"
          (click)="onCellClicked(rowIndex)"
          [ngClass]="{ 'highlighted-row': isCellHighlighted(rowIndex) }"
        >
          <td
            *ngIf="!_isMobile && _rowSelectionType !== RowSelectionType.NONE"
            class="coltype-selectrow"
            pFrozenColumn
            [style.width]="FrozenColsWidths.selectrow"
            [style.height.px]="_rowHeight"
          >
            <div [ngSwitch]="_rowSelectionType">
              <p-tableCheckbox
                *ngSwitchCase="RowSelectionType.MULTIPLE"
                [value]="rowData"
                [disabled]="isRowSelectionDisabled(rowData)"
              ></p-tableCheckbox>
              <p-tableRadioButton
                *ngSwitchCase="RowSelectionType.SINGLE"
                [value]="rowData"
                [disabled]="isRowSelectionDisabled(rowData)"
              ></p-tableRadioButton>
            </div>
          </td>

          <td
            class="viewdetail-column"
            *ngIf="!_isMobile && showDetailsColumn"
            pFrozenColumn
            [style.width]="_viewColIcon ? FrozenColsWidths.execute_event : FrozenColsWidths.viewrow"
            [style.height.px]="_rowHeight"
          >
            <div>
              <button
                *ngIf="_viewColIcon"
                pButton
                pRipple
                class="p-button-secondary p-button-rounded p-button-icon-only"
                [disabled]="_isFormInEditMode"
                [icon]="_viewColIcon"
                (click)="goEdit(rowData)"
              >
                <i *ngIf="_viewColIconName" class="material-icons">{{ _viewColIconName }}</i>
              </button>
              <strong *ngIf="!_viewColIcon">
                <a
                  *ngIf="areControlsEnabled && (_disableViewCol ? rowData[_disableViewCol] : true)"
                  (click)="goEdit(rowData)"
                  >View</a
                >
                <span
                  class="disabled-link"
                  *ngIf="
                    (_isFormInEditMode && rowData[_disableViewCol]) ||
                    (_disableViewCol ? !rowData[_disableViewCol] : false)
                  "
                  >View</span
                >
              </strong>
            </div>
          </td>

          <td
            class="settings-column"
            *ngIf="!_isMobile && _settingsCol"
            pFrozenColumn
            [style.width]="FrozenColsWidths.settings"
            [style.height.px]="_rowHeight"
          >
            <div>
              <strong>
                <a *ngIf="areControlsEnabled" (click)="goSettingsScreen(rowData)">{{ _settingsCol.caption }}</a>
                <span *ngIf="_isFormInEditMode" class="disabled-link">{{ _settingsCol.caption }}</span>
              </strong>
            </div>
          </td>

          <td
            class="deleterow-column"
            *ngIf="!_isMobile && _showDeleteCol"
            pFrozenColumn
            [style.width]="FrozenColsWidths.deleterow"
          >
            <button
              pButton
              pRipple
              class="p-button-secondary p-button-rounded p-button-icon-only"
              [disabled]="_isFormInEditMode || _disableDeleteRowButton?.(rowData)"
              icon="_"
              (click)="deleteRowsAction([rowData])"
            >
              <i class="material-icons">delete</i>
            </button>
          </td>

          <!-- Add empty cell on frozen row -->
          <td
            *ngIf="!_isMobile && !hasSystemColumns && !_hideFilterControls"
            class="clearstate-column"
            pFrozenColumn
            [style.width]="FrozenColsWidths.clear_state"
            [style.height.px]="_rowHeight"
          >
          </td>

          <td
            class="rowactions-column"
            style="width: 100% !important; max-width: 100% !important"
            *ngIf="_isMobile && hasSystemColumns"
            [style.height.px]="_rowHeight"
          >
            <div [ngSwitch]="_rowSelectionType">
              <p-tableCheckbox
                *ngSwitchCase="RowSelectionType.MULTIPLE"
                [value]="rowData"
                [ngClass]="{ 'rowactions-remove-margin': !showDetailsColumn && !_settingsCol }"
                [disabled]="isRowSelectionDisabled(rowData)"
              ></p-tableCheckbox>
              <p-tableRadioButton
                *ngSwitchCase="RowSelectionType.SINGLE"
                [value]="rowData"
                [ngClass]="{ 'rowactions-remove-margin': !showDetailsColumn && !_settingsCol }"
                [disabled]="isRowSelectionDisabled(rowData)"
              ></p-tableRadioButton>

              <strong *ngIf="showDetailsColumn" class="viewdetail-col-text">
                <a *ngIf="areControlsEnabled" (click)="goEdit(rowData)">View</a>
                <span *ngIf="_isFormInEditMode && rowData[_disableViewCol]" class="disabled-link">View</span>
                <span class="field-separator"></span>
              </strong>
              <strong *ngIf="_settingsCol" class="settings-col-text">
                <a *ngIf="areControlsEnabled" (click)="goSettingsScreen(rowData)">{{ _settingsCol.caption }}</a>
                <span *ngIf="_isFormInEditMode" class="disabled-link">{{ _settingsCol.caption }}</span>
                <span class="field-separator"></span>
              </strong>
            </div>
          </td>

          <td
            pFrozenColumn
            [frozen]="!_isMobile && col.frozen"
            pEditableColumn
            [pEditableColumnDisabled]="isCellOutput(col)"
            *ngFor="let col of _selectorColsChecked; trackBy: trackByColDef"
            (click)="autoSelectInput($event, col)"
            [ngClass]="{
              'invalid-cell': isInvalidCell(rowIndex, col.field),
              'modified-cell': isModifiedCell(rowIndex, col.field)
            }"
            [class]="'coltype-' + col.dataType + ' ' + (col.classObj || '')"
            [style.height.px]="_rowHeight"
            [style.width]="_isMobile ? '100% !important' : getColWidth(col) + 'px'"
            [style.max-width]="_isMobile ? '100% !important' : ''"
            [ngStyle]="col.styleObj || {}"
          >
            <!-- Sets cell title in mobile view -->
            <span class="p-column-title" [innerHTML]="getColumnHeader(col)"></span>

            <ng-template [ngIf]="!['toggle', 'checkbox', 'execute_event'].includes(col.dataType)">
              <p-cellEditor *ngIf="this._getFormGroup(rowIndex) as formGroup">
                <ng-template pTemplate="input">
                  <div [ngSwitch]="col.dataType" #bodyCell>
                    <div *ngSwitchCase="'input'">
                      <ng-container
                        *ngTemplateOutlet="
                          inputTypeColumnTpl;
                          context: { $implicit: col, rowData: rowData, formGroup: formGroup, isOutput: false }
                        "
                      >
                      </ng-container>
                    </div>

                    <div *ngSwitchCase="'number'">
                      <ng-container
                        *ngTemplateOutlet="
                          numberTypeColumnTpl;
                          context: { $implicit: col, rowData: rowData, formGroup: formGroup, isOutput: false }
                        "
                      >
                      </ng-container>
                    </div>

                    <div *ngSwitchCase="'enum'">
                      <ng-container
                        *ngTemplateOutlet="
                          enumTypeColumnTpl;
                          context: { $implicit: col, rowData: rowData, formGroup: formGroup, isOutput: false }
                        "
                      >
                      </ng-container>
                    </div>

                    <div *ngSwitchCase="'lookup'">
                      <ng-container
                        *ngTemplateOutlet="
                          lookupTypeColumnTpl;
                          context: { $implicit: col, rowData: rowData, formGroup: formGroup, isOutput: false }
                        "
                      >
                      </ng-container>
                    </div>

                    <div *ngSwitchCase="'multiselect'">
                      <ng-container
                        *ngTemplateOutlet="
                          multiselectTypeColumnTpl;
                          context: { $implicit: col, rowData: rowData, formGroup: formGroup, isOutput: false }
                        "
                      >
                      </ng-container>
                    </div>

                    <div *ngSwitchCase="'enumMultiselect'">
                      <ng-container
                        *ngTemplateOutlet="
                          enumMultiselectTypeColumnTpl;
                          context: { $implicit: col, rowData: rowData, formGroup: formGroup, isOutput: false }
                        "
                      >
                      </ng-container>
                    </div>

                    <div *ngSwitchCase="'chips'">
                      <ng-container
                        *ngTemplateOutlet="
                          chipsTypeColumnTpl;
                          context: { $implicit: col, rowData: rowData, formGroup: formGroup, isOutput: false }
                        "
                      >
                      </ng-container>
                    </div>

                    <div *ngSwitchCase="'open_details_link'">
                      <ng-container
                        *ngTemplateOutlet="
                          openDetailsLinkTypeColumnTpl;
                          context: { $implicit: col, rowData: rowData, formGroup: formGroup, isOutput: false }
                        "
                      >
                      </ng-container>
                    </div>

                    <div *ngSwitchCase="'date_date_only'">
                      <ng-container
                        *ngTemplateOutlet="
                          dateTypeColumnTpl;
                          context: { $implicit: col, rowData: rowData, formGroup: formGroup, isOutput: false }
                        "
                      >
                      </ng-container>
                    </div>

                    <div *ngSwitchCase="'date_date_time'">
                      <ng-container
                        *ngTemplateOutlet="
                          dateDateTimeTypeColumnTpl;
                          context: { $implicit: col, rowData: rowData, formGroup: formGroup, isOutput: false }
                        "
                      >
                      </ng-container>
                    </div>

                    <div *ngSwitchCase="'date_time'">
                      <ng-container
                        *ngTemplateOutlet="
                          dateTimeTypeColumnTpl;
                          context: { $implicit: col, rowData: rowData, formGroup: formGroup, isOutput: false }
                        "
                      >
                      </ng-container>
                    </div>

                    <div *ngSwitchCase="'autocomplete'">
                      <ng-container
                        *ngTemplateOutlet="
                          autocompleteTypeColumnTpl;
                          context: {
                            $implicit: col,
                            rowData: rowData,
                            formGroup: formGroup,
                            rowIndex: rowIndex,
                            isOutput: false
                          }
                        "
                      >
                      </ng-container>
                    </div>

                    <div *ngSwitchCase="'template'">
                      <ng-container
                        *ngTemplateOutlet="
                          col.dataTypeOptions?.templateRef;
                          context: {
                            $implicit: rowData,
                            data: col.dataTypeOptions?.data,
                            formGroup: formGroup,
                            isOutput: false
                          }
                        "
                      >
                      </ng-container>
                    </div>

                    <div *ngSwitchCase="'array'">
                      <ng-container
                        *ngTemplateOutlet="
                          arrayTypeColumnTpl;
                          context: { $implicit: col, rowData: rowData, formGroup: formGroup }
                        "
                      >
                      </ng-container>
                    </div>
                  </div>
                  <form-validation-messages
                    *ngIf="_isMobile"
                    [errors]="formGroup?.controls[col.field]?.errors"
                    [fieldName]="col.field"
                    [showRequiredValidation]="true"
                  ></form-validation-messages>
                  <form-list-validation-messages
                    [hidden]="_isMobile || !(col.showValidationErrors || _submitAttempted)"
                    [invalid]="isInvalidCell(rowIndex, col.field)"
                    [pointerPosition]="'up'"
                    [appendTo]="isValidationFloating(findObjectIndex(rowData)) ? 'body' : null"
                    [errors]="this._getFormGroup(rowIndex).controls[col.field]?.errors"
                    [field]="col.field"
                    [elementPosRef]="bodyCell"
                  >
                  </form-list-validation-messages>
                </ng-template>

                <ng-template pTemplate="output">
                  <div class="editable-marker" *ngIf="_isFormInEditMode && !col.readonly && _isMobile"></div>
                  <div class="readonly-value" [ngSwitch]="col.dataType">
                    <div *ngSwitchCase="'enum'">
                      <ng-container
                        *ngTemplateOutlet="
                          enumTypeColumnTpl;
                          context: { $implicit: col, rowData: rowData, formGroup: formGroup, isOutput: true }
                        "
                      >
                      </ng-container>
                    </div>

                    <div *ngSwitchCase="'lookup'">
                      <ng-container
                        *ngTemplateOutlet="
                          lookupTypeColumnTpl;
                          context: { $implicit: col, rowData: rowData, formGroup: formGroup, isOutput: true }
                        "
                      >
                      </ng-container>
                    </div>

                    <div *ngSwitchCase="'multiselect'">
                      <ng-container
                        *ngTemplateOutlet="
                          multiselectTypeColumnTpl;
                          context: { $implicit: col, rowData: rowData, formGroup: formGroup, isOutput: true }
                        "
                      >
                      </ng-container>
                    </div>

                    <div *ngSwitchCase="'enumMultiselect'">
                      <ng-container
                        *ngTemplateOutlet="
                          enumMultiselectTypeColumnTpl;
                          context: { $implicit: col, rowData: rowData, formGroup: formGroup, isOutput: true }
                        "
                      >
                      </ng-container>
                    </div>

                    <div *ngSwitchCase="'chips'">
                      <ng-container
                        *ngTemplateOutlet="
                          chipsTypeColumnTpl;
                          context: { $implicit: col, rowData: rowData, formGroup: formGroup, isOutput: true }
                        "
                      >
                      </ng-container>
                    </div>

                    <div *ngSwitchCase="'input'">
                      <ng-container
                        *ngTemplateOutlet="
                          inputTypeColumnTpl;
                          context: { $implicit: col, rowData: rowData, formGroup: formGroup, isOutput: true }
                        "
                      >
                      </ng-container>
                    </div>

                    <div *ngSwitchCase="'number'">
                      <ng-container
                        *ngTemplateOutlet="
                          numberTypeColumnTpl;
                          context: { $implicit: col, rowData: rowData, formGroup: formGroup, isOutput: true }
                        "
                      >
                      </ng-container>
                    </div>

                    <div *ngSwitchCase="'open_details_link'">
                      <ng-container
                        *ngTemplateOutlet="
                          openDetailsLinkTypeColumnTpl;
                          context: { $implicit: col, rowData: rowData, formGroup: formGroup, isOutput: true }
                        "
                      >
                      </ng-container>
                    </div>

                    <div *ngSwitchCase="'date_date_only'">
                      <ng-container
                        *ngTemplateOutlet="
                          dateTypeColumnTpl;
                          context: { $implicit: col, rowData: rowData, formGroup: formGroup, isOutput: true }
                        "
                      >
                      </ng-container>
                    </div>

                    <div *ngSwitchCase="'date_date_time'">
                      <ng-container
                        *ngTemplateOutlet="
                          dateDateTimeTypeColumnTpl;
                          context: { $implicit: col, rowData: rowData, formGroup: formGroup, isOutput: true }
                        "
                      >
                      </ng-container>
                    </div>

                    <div *ngSwitchCase="'autocomplete'">
                      <ng-container
                        *ngTemplateOutlet="
                          autocompleteTypeColumnTpl;
                          context: {
                            $implicit: col,
                            rowData: rowData,
                            formGroup: formGroup,
                            rowIndex: rowIndex,
                            isOutput: true
                          }
                        "
                      >
                      </ng-container>
                    </div>

                    <div *ngSwitchCase="'template'">
                      <ng-container
                        *ngTemplateOutlet="
                          col.dataTypeOptions?.templateRef;
                          context: {
                            $implicit: rowData,
                            data: col.dataTypeOptions?.data,
                            formGroup: formGroup,
                            isOutput: true
                          }
                        "
                      >
                      </ng-container>
                    </div>

                    <div *ngSwitchCase="'array'">
                      <ng-container
                        *ngTemplateOutlet="
                          arrayTypeColumnTpl;
                          context: { $implicit: col, rowData: rowData, formGroup: formGroup }
                        "
                      >
                      </ng-container>
                    </div>

                    <div *ngSwitchDefault>{{ getValue(rowData, col.field) }}</div>
                  </div>
                  <i class="material-icons error-symbol">error</i>
                  <form-validation-messages
                    *ngIf="_isMobile"
                    [errors]="this._getFormGroup(rowIndex)?.controls[col.field]?.errors"
                    [fieldName]="col.field"
                    [showRequiredValidation]="true"
                  ></form-validation-messages>
                </ng-template>
              </p-cellEditor>
            </ng-template>

            <ng-template [ngIf]="col.dataType === 'execute_event'">
              <ng-container
                *ngTemplateOutlet="
                  executeEventTypeColumnTpl;
                  context: { $implicit: col, rowData: rowData, formGroup: this._getFormGroup(rowIndex) }
                "
              >
              </ng-container>
            </ng-template>

            <ng-template [ngIf]="col.dataType === 'toggle'">
              <ng-container
                *ngTemplateOutlet="
                  toggleTypeColumnTpl;
                  context: {
                    $implicit: col,
                    rowData: rowData,
                    formGroup: this._getFormGroup(rowIndex),
                    isOutput: isCellOutput(col)
                  }
                "
              >
              </ng-container>
            </ng-template>

            <ng-template [ngIf]="col.dataType === 'checkbox'">
              <ng-container
                *ngTemplateOutlet="
                  checkboxTypeColumnTpl;
                  context: {
                    $implicit: col,
                    rowData: rowData,
                    formGroup: this._getFormGroup(rowIndex),
                    isOutput: isCellOutput(col)
                  }
                "
              >
              </ng-container>
            </ng-template>

            <ng-template [ngIf]="col.attachTemplate">
              <ng-container
                *ngTemplateOutlet="
                  col.attachTemplate.templateRef;
                  context: {
                    $implicit: rowData,
                    data: col.dataTypeOptions?.data,
                    isInputCell: !isCellOutput(col)
                  }
                "
              >
              </ng-container>
            </ng-template>

            <div class="editable-marker" *ngIf="_isFormInEditMode && !col.readonly && !_isMobile"></div>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="footer" let-columns>
        <tr class="reports-footer" *ngIf="_showFooter && _objects.length">
          <td
            *ngIf="!_isMobile && !hasSystemColumns && !_hideFilterControls"
            class="clearstate-column"
            pFrozenColumn
            [style.width]="FrozenColsWidths.clear_state"
          ></td>
          <td
            *ngFor="let col of _selectorColsChecked; trackBy: trackByColDef"
            [style.width.px]="getColWidth(col)"
            pFrozenColumn
            [frozen]="col.frozen"
          >
            <div *ngIf="col.footer">
              <span *ngIf="currencyISOCode">{{
                getColSummary(col) | currency : currencyISOCode : "code" : "1.2"
              }}</span>
              <span *ngIf="!currencyISOCode">{{ getColSummary(col) | number }}</span>
            </div>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="paginatorleft" let-state>
        <!-- Only showing counting if selection is multi select and has at least one element -->
        <span class="rowselection-count" *ngIf="_selectedObjects && _selectedObjects[0]"
          >{{ _selectedObjects.length }} of {{ _objects.length }} selected</span
        >
      </ng-template>

      <ng-template pTemplate="paginatorright" let-state>
        <div *ngIf="!_isFetchingData" class="totalRecords-wrapper">
          <span class="totalrows-count">{{ totalRecords }} item(s)</span>
        </div>
      </ng-template>
    </p-table>

    <div *ngIf="!_isFetchingData && _objects && !_objects.length" class="noresults-row">
      <span class="caption">No results found</span>
    </div>
  </div>
</div>

<p-dialog
  *ngIf="displayDialog"
  header="{{ modelNamePrettyPrint }} Details"
  [(visible)]="displayDialog"
  appendTo="body"
  [showHeader]="_dialogShowHeader"
  [styleClass]="_dialogStyleClass"
  showEffect="fade"
  [modal]="true"
  [blockScroll]="true"
  [dismissableMask]="_dialogDismissable"
  [resizable]="false"
  [contentStyle]="{ overflow: 'auto', 'max-height': 'calc(100vh - 40pt)' }"
  [closable]="_dialogClosable"
>
  <taku-form-dialoge-select
    [object]="_objectDialog"
    [model]="_modelDialog"
    [id]="_objectDialog.id"
    (changedForm)="_formChanged($event)"
    [detailedViewComponent]="_dialogDetailedViewDirective?.component"
    (dialogClosed)="closeDialog()"
  >
    <ng-content select="[taku-formListDetailedView]"></ng-content>
  </taku-form-dialoge-select>
</p-dialog>

<p-sidebar
  *ngIf="displaySidebar"
  [(visible)]="displaySidebar"
  [modal]="true"
  [showCloseIcon]="false"
  position="right"
  appendTo="body"
  [blockScroll]="true"
  [style]="{ 'overflow-y': 'auto', width: _isMobile ? '100%' : '75%' }"
  [closeOnEscape]="false"
  [dismissible]="false"
  styleClass="formlist-sidebar"
>
  <taku-form-dialoge-select
    [object]="_objectDialog"
    [model]="_modelDialog"
    [id]="_objectDialog.id"
    (changedForm)="_formChanged($event)"
    [detailedViewComponent]="_dialogDetailedViewDirective?.component"
    (dialogClosed)="closeSidebar()"
  >
    <ng-content select="[taku-formListDetailedView]"></ng-content>
  </taku-form-dialoge-select>
</p-sidebar>

<ng-template #filtersColumnsTpl let-tableCols let-dt="dt">
  <th
    *ngFor="let col of tableCols; trackBy: trackByColDef"
    [ngSwitch]="col.filterType"
    [style.width.px]="getColWidth(col)"
    pFrozenColumn
    [frozen]="col.frozen"
    class="filters-column-tpl"
  >
    <div *ngSwitchCase="'contains'">
      <div class="p-inputgroup inputgroup-outlined">
        <span class="p-inputgroup-addon" [ngClass]="{ 'input-addon-disabled': !areControlsEnabled }"
          ><i class="pi pi-search"></i
        ></span>
        <input
          [disabled]="!areControlsEnabled"
          type="text"
          [(ngModel)]="_inputFilterValues[col.field]"
          name="filter-{{ col.field }}"
          pInputText
          (input)="executeColumnFilter($event.target.value, col)"
        />
      </div>
    </div>
    <div *ngSwitchCase="'compound'">
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon" [ngClass]="{ 'input-addon-disabled': !areControlsEnabled }"
          ><i class="pi pi-search"></i
        ></span>
        <input
          [disabled]="!areControlsEnabled"
          type="text"
          [(ngModel)]="_inputFilterValues[col.field]"
          name="filter-{{ col.field }}"
          pInputText
          (input)="executeColumnFilter($event.target.value, col)"
        />
      </div>
    </div>
    <div *ngSwitchCase="'enum'">
      <p-dropdown
        [disabled]="!areControlsEnabled"
        name="filter-{{ col.field }}"
        [(ngModel)]="_inputFilterValues[col.field]"
        appendTo="body"
        [options]="_filterOptions(col)"
        (onChange)="executeColumnFilter($event.value, col)"
        [autoDisplayFirst]="true"
      >
        <ng-template let-item pTemplate="item">
          <div class="ui-helper-clearfix item-wrapper">
            <span class="item-label">{{ item.label }}</span>
            <span *ngIf="item.title"> - {{ item.title }}</span>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
    <div *ngSwitchCase="'lookup'">
      <p-dropdown
        [disabled]="!areControlsEnabled"
        name="filter-{{ col.field }}"
        [(ngModel)]="_inputFilterValues[col.field]"
        appendTo="body"
        [options]="$any(_filterOptions(col)) | async"
        (onChange)="executeColumnFilter($event.value, col)"
        placeholder="Select"
      ></p-dropdown>
    </div>
    <div *ngSwitchCase="'multiselect'">
      <p-multiSelect
        [disabled]="!areControlsEnabled"
        name="filter-{{ col.field }}"
        [(ngModel)]="_inputFilterValues[col.field]"
        [dataKey]="col.dataKey"
        appendTo="body"
        placeholder="All"
        [options]="$any(_filterOptions(col)) | async"
        (onChange)="executeColumnFilter($event.value, col)"
      >
      </p-multiSelect>
    </div>
    <div *ngSwitchCase="'enumMultiselect'">
      <p-multiSelect
        [disabled]="!areControlsEnabled"
        name="filter-{{ col.field }}"
        [(ngModel)]="_inputFilterValues[col.field]"
        appendTo="body"
        placeholder="All"
        [options]="_filterOptions(col)"
        (onChange)="executeColumnFilter($event.value, col)"
      ></p-multiSelect>
    </div>
    <div *ngSwitchCase="'checkbox'">
      <p-checkbox
        [disabled]="!areControlsEnabled"
        name="filter-{{ col.field }}"
        [(ngModel)]="_inputFilterValues[col.field]"
        appendTo="body"
        (onChange)="executeColumnFilter($event.value, col)"
      >
      </p-checkbox>
    </div>
    <div *ngSwitchCase="'clear_filters'">
      <button
        *ngIf="_detailMode === 'n'"
        pButton
        type="button"
        [disabled]="_dt && (_dt.filters | json) === '{}'"
        class="clear-filters-btn p-button-rounded p-button-secondary"
        icon="takuicon-clear-filters"
        pTooltip="Remove all filters"
        tooltipPosition="left"
        (click)="clearAllFilters()"
      ></button>
    </div>
  </th>
</ng-template>

<!-- Toggle Datatype -->
<ng-template #toggleTypeColumnTpl let-col let-rowData="rowData" let-formGroup="formGroup" let-isOutput="isOutput">
  <span [formGroup]="formGroup" class="cellInput-wrapper readonly-value">
    <span
      *ngIf="col.dataTypeOptions?.showStateLabel"
      class="toggle-label label-left"
      [ngClass]="{ 'label-emphasis': !getValue(rowData, col.field) }"
      >{{ col.dataTypeOptions?.labelStateFalse || "Inactive" }}</span
    >
    <p-inputSwitch
      *ngIf="!isOutput"
      [formControlName]="col.field"
      [ngModel]="getValue(rowData, col.field)"
      (ngModelChange)="setValue(rowData, col.field, $event)"
    ></p-inputSwitch>
    <p-inputSwitch
      *ngIf="isOutput"
      disabled="true"
      [ngModelOptions]="{ standalone: true }"
      [ngModel]="getValue(rowData, col.field)"
    ></p-inputSwitch>
    <span
      *ngIf="col.dataTypeOptions?.showStateLabel"
      class="toggle-label label-right"
      [ngClass]="{ 'label-emphasis': getValue(rowData, col.field) }"
      >{{ col.dataTypeOptions?.labelStateTrue || "Active" }}</span
    >
  </span>
</ng-template>

<!-- Number Datatype -->
<ng-template #numberTypeColumnTpl let-col let-rowData="rowData" let-formGroup="formGroup" let-isOutput="isOutput">
  <span [formGroup]="formGroup" class="colinput-fieldWrapper cellInput-wrapper">
    <span
      *ngIf="col.dataTypeOptions?.prefix"
      class="input-preffix"
      [innerHTML]="col.dataTypeOptions.prefix(rowData)"
    ></span>

    <input
      *ngIf="!isOutput"
      type="text"
      [formControlName]="col.field"
      [ngModel]="getValue(rowData, col.field)"
      (ngModelChange)="setValue(rowData, col.field, $event)"
    />
    <ng-template [ngIf]="isOutput">{{
      col.displayFn ? col.displayFn(rowData) : getValue(rowData, col.fieldValue || col.field)
    }}</ng-template>

    <span
      *ngIf="col.dataTypeOptions?.suffix"
      class="input-suffix"
      [innerHTML]="col.dataTypeOptions.suffix(rowData)"
    ></span>
  </span>
</ng-template>

<!-- Array Datatype -->
<ng-template #arrayTypeColumnTpl let-col let-rowData="rowData" let-formGroup="formGroup" let-isOutput="isOutput">
  <span *ngIf="getValue(rowData, col.field) as items">
    <a href="javascript://">
      <i class="arrayType-expandicon pi pi-chevron-right" (click)="op.toggle($event)"></i>
      <span *ngIf="col.dataTypeOptions.showSummary" class="arrayType-count" (click)="op.toggle($event)"
        >({{ items.length }})</span
      >
      <span class="arrayType-rowvalue" (click)="op.toggle($event)">{{
        arrayType_rowContents(items[0] || null, items, col)
      }}</span>
    </a>

    <p-overlayPanel #op [showCloseIcon]="true" appendTo="body" styleClass="formlist-panelOverlay">
      <ng-container
        *ngComponentOutlet="col.dataTypeOptions.component; injector: createNestedFormInjector(rowData, items, col)"
      >
      </ng-container>
    </p-overlayPanel>
  </span>
</ng-template>

<ng-template #checkboxTypeColumnTpl let-col let-rowData="rowData" let-formGroup="formGroup" let-isOutput="isOutput">
  <span [formGroup]="formGroup" class="cellInput-wrapper readonly-value">
    <p-checkbox
      *ngIf="!col.readonlyPredicate || !col.readonlyPredicate(rowData); else colNotApplicable"
      [disabled]="isOutput"
      [formControlName]="col.field"
      [ngModel]="getValue(rowData, col.field)"
      (ngModelChange)="setValue(rowData, col.field, $event)"
      binary="true"
    ></p-checkbox>
    <ng-template #colNotApplicable>
      <!-- when the column is not applicable for this row, we display a dash -->
      <span>-</span>
    </ng-template>
  </span>
</ng-template>

<!-- Chips Datatype -->
<ng-template #chipsTypeColumnTpl let-col let-rowData="rowData" let-formGroup="formGroup" let-isOutput="isOutput">
  <span [formGroup]="formGroup" (keydown)="preventCommitCellOnEnter($event)" class="cellInput-wrapper">
    <p-chips
      *ngIf="!isOutput"
      [formControlName]="col.field"
      [max]="col.dataTypeOptions?.maxEntries"
      [ngModel]="getValue(rowData, col.field)"
      (ngModelChange)="setValue(rowData, col.field, $event)"
    ></p-chips>

    <p-chips
      *ngIf="isOutput"
      [disabled]="true"
      [max]="col.dataTypeOptions?.maxEntries"
      [ngModelOptions]="{ standalone: true }"
      [ngModel]="getValue(rowData, col.field)"
    ></p-chips>
  </span>
</ng-template>

<!-- Date Datatype  -->
<ng-template #dateTypeColumnTpl let-col let-rowData="rowData" let-formGroup="formGroup" let-isOutput="isOutput">
  <span [formGroup]="formGroup">
    <p-calendar
      *ngIf="!isOutput"
      type="text"
      [formControlName]="col.field"
      [ngModel]="getValue(rowData, col.field)"
      (ngModelChange)="setValue(rowData, col.field, $event)"
      [monthNavigator]="true"
      [yearNavigator]="true"
      yearRange="1900:2030"
      [showIcon]="true"
      dataType="string"
      [style]="{ width: '100%' }"
      [touchUI]="_isMobile"
      dateFormat="yy-mm-dd"
      appendTo="body"
    ></p-calendar>
  </span>

  <ng-template [ngIf]="isOutput">{{ getValue(rowData, col.field) | dateFormat }}</ng-template>
</ng-template>

<!-- Input Datatype -->
<ng-template #inputTypeColumnTpl let-col let-rowData="rowData" let-formGroup="formGroup" let-isOutput="isOutput">
  <span [formGroup]="formGroup" class="cellInput-wrapper">
    <span
      *ngIf="col.dataTypeOptions?.prefix"
      class="input-preffix"
      [innerHTML]="col.dataTypeOptions.prefix(rowData)"
    ></span>

    <input
      *ngIf="!isOutput"
      type="text"
      [formControlName]="col.field"
      [ngModel]="getValue(rowData, col.field)"
      (ngModelChange)="setValue(rowData, col.field, $event)"
    />
    <ng-template [ngIf]="isOutput">{{ getValue(rowData, col.field) }}</ng-template>

    <span
      *ngIf="col.dataTypeOptions?.suffix"
      class="input-suffix"
      [innerHTML]="col.dataTypeOptions.suffix(rowData)"
    ></span>
  </span>
</ng-template>

<!-- Enum Datatype -->
<ng-template #enumTypeColumnTpl let-col let-rowData="rowData" let-formGroup="formGroup" let-isOutput="isOutput">
  <span
    [formGroup]="formGroup"
    class="cellInput-wrapper"
    [ngClass]="'col-field-' + col.field + '-' + getValue(rowData, col.field)?.toString().replaceAll(' ', '-')"
  >
    <p-dropdown
      *ngIf="!isOutput"
      appendTo="body"
      [options]="_dataOptions(col, rowData)"
      [formControlName]="col.field"
      [ngModel]="getValue(rowData, col.field)"
      (ngModelChange)="setValue(rowData, col.field, $event)"
      placeholder="Select"
      (onChange)="col.onModelChange?.(rowData, _orgObjects, formGroup)"
      style="display: grid"
    >
      <ng-template let-group pTemplate="group">
        <div class="optionGroup-wrapper">{{ group.label }}</div>
      </ng-template>

      <ng-template let-item pTemplate="item">
        <div class="ui-helper-clearfix item-wrapper">
          <i *ngIf="item.icon" class="item-icon {{ item.icon }}"></i>
          <span class="item-label">{{ item.label }}</span>
          <span *ngIf="item.title"> - {{ item.title }}</span>
        </div>
      </ng-template>
    </p-dropdown>
    <ng-template [ngIf]="isOutput">{{
      getArrValue(rowData, col.field, _dataOptions(col, rowData)) || getValue(rowData, col.field)
    }}</ng-template>
  </span>
</ng-template>

<!-- Lookup Datatype -->
<ng-template #lookupTypeColumnTpl let-col let-rowData="rowData" let-formGroup="formGroup" let-isOutput="isOutput">
  <span [formGroup]="formGroup" class="cellInput-wrapper">
    <p-dropdown
      *ngIf="!isOutput"
      appendTo="body"
      [options]="_dataOptions(col, rowData, true) | async"
      [formControlName]="col.field"
      [ngModel]="getValue(rowData, col.field)"
      (ngModelChange)="setValue(rowData, col.field, $event)"
      placeholder="Select"
      (onChange)="col.onModelChange?.(rowData, _orgObjects, formGroup)"
      style="display: grid"
    >
      <ng-template let-group pTemplate="group">
        <div class="optionGroup-wrapper">{{ group.label }}</div>
      </ng-template>

      <ng-template let-item pTemplate="item">
        <div class="ui-helper-clearfix item-wrapper">
          <i *ngIf="item.icon" class="item-icon {{ item.icon }}"></i>
          <span class="item-label">{{ item.label }}</span>
        </div>
      </ng-template>
    </p-dropdown>
    <ng-template [ngIf]="isOutput">{{
      getArrValue(rowData, col.field, _dataOptions(col, rowData, true) | async) || getValue(rowData, col.field)
    }}</ng-template>
  </span>
</ng-template>

<!-- Multiselect Datatype -->
<ng-template #multiselectTypeColumnTpl let-col let-rowData="rowData" let-formGroup="formGroup" let-isOutput="isOutput">
  <span [formGroup]="formGroup" class="cellInput-wrapper">
    <p-multiSelect
      *ngIf="!isOutput"
      [options]="_dataOptions(col, rowData, true) | async"
      (onChange)="onMultiSelectChange($event, rowData, col.field, col.dataKey, _dataOptions(col, col.field))"
      [formControlName]="col.field"
      [ngModel]="getValue(rowData, col.field)"
      (ngModelChange)="setValue(rowData, col.field, $event)"
      maxSelectedLabels="100"
      appendTo="body"
      [dataKey]="col.dataKey"
      placeholder="Choose "
    ></p-multiSelect>

    <ng-template [ngIf]="isOutput">{{
      getMultiselectValue(rowData, col.field, col.dataKey, _dataOptions(col, rowData, true) | async)
    }}</ng-template>
  </span>
</ng-template>

<!-- Enum Multiselect Datatype -->
<ng-template
  #enumMultiselectTypeColumnTpl
  let-col
  let-rowData="rowData"
  let-formGroup="formGroup"
  let-isOutput="isOutput"
>
  <span [formGroup]="formGroup" class="cellInput-wrapper">
    <p-multiSelect
      *ngIf="!isOutput"
      [options]="_dataOptions(col, rowData)"
      [dataKey]="col.dataKey"
      appendTo="body"
      [formControlName]="col.field"
      [ngModel]="getValue(rowData, col.field)"
      (ngModelChange)="setValue(rowData, col.field, $event)"
      maxSelectedLabels="100"
      placeholder="Choose "
    >
    </p-multiSelect>
    <ng-template [ngIf]="isOutput"
      >{{ getMultiselectValue(rowData, col.field, col.dataKey, _dataOptions(col, rowData)) }}
    </ng-template>
  </span>
</ng-template>

<!-- Execute Event Datatype -->
<ng-template #executeEventTypeColumnTpl let-col let-rowData="rowData" let-formGroup="formGroup">
  <span class="execute-event-col-wrapper">
    <span *ngFor="let _dataTypeOption of col.dataTypeOptions">
      <ng-container *ngIf="_dataTypeOption.displayMode === ExecuteEvent_DisplayMode.SPLITBUTTON">
        <p-menu #eventMenu [popup]="true" [model]="_dataTypeOption.items(rowData)" appendTo="body"></p-menu>
        <button
          type="button"
          pButton
          [icon]="_dataTypeOption.icon"
          [disabled]="
            (_isFormInEditMode && !_dataTypeOption.enabledOnEditMode) ||
            isCellDisabledByColOptions(rowData, _dataTypeOption)
          "
          (click)="eventMenu.toggle($event); splitButtonDropdownClick()"
          [label]="
            isFunction(_dataTypeOption.label)
              ? _dataTypeOption.label(rowData, _modelColsService)
              : _dataTypeOption.label
          "
          [ngClass]="'pl-6 pr-6 ' + _dataTypeOption.styleClass"
        >
          <i class="pi pi-chevron-down ml-4"></i>
        </button>
      </ng-container>
      <!-- Bug after upgrading to primeng14 - the splitButton in this setup enters an infinite loop onMouseEnter (hover)-->
      <!-- <p-splitButton #spBtn *ngIf="_dataTypeOption.displayMode == ExecuteEvent_DisplayMode.SPLITBUTTON" (onClick)="spBtn.onDropdownButtonClick(null)"
        [disabled]="_isFormInEditMode && !_dataTypeOption.enabledOnEditMode || isCellDisabledByColOptions(rowData,_dataTypeOption)"
        appendTo="body" (onDropdownClick)="splitButtonDropdownClick()"
        [label]="isFunction(_dataTypeOption.label) ? _dataTypeOption.label(rowData,_modelColsService) : _dataTypeOption.label"
        [icon]="_dataTypeOption.icon" [attr.class]="_dataTypeOption.styleClass" [model]="_dataTypeOption.items(rowData)">
        <i *ngIf="_dataTypeOption.iconName" class="material-icons">{{_dataTypeOption.iconName}}</i>
      </p-splitButton> -->
      <button
        *ngIf="_dataTypeOption.displayMode == ExecuteEvent_DisplayMode.BUTTON"
        [disabled]="
          (_isFormInEditMode && !_dataTypeOption.enabledOnEditMode) ||
          isCellDisabledByColOptions(rowData, _dataTypeOption)
        "
        pButton
        pRipple
        (click)="_dataTypeOption.event.emit(rowData)"
        [label]="
          isFunction(_dataTypeOption.label) ? _dataTypeOption.label(rowData, _modelColsService) : _dataTypeOption.label
        "
        [icon]="isFunction(_dataTypeOption.icon) ? _dataTypeOption.icon(rowData) : _dataTypeOption.icon"
        [ngClass]="_dataTypeOption.styleClass + (_dataTypeOption.label ? '' : ' p-button-icon-only')"
      >
        <i *ngIf="_dataTypeOption.iconName" class="material-icons">{{ _dataTypeOption.iconName }}</i>
      </button>
      <a
        *ngIf="_dataTypeOption.displayMode == ExecuteEvent_DisplayMode.LINK"
        [ngClass]="{
          'disabled-link':
            (_isFormInEditMode && !_dataTypeOption.enabledOnEditMode) ||
            isCellDisabledByColOptions(rowData, _dataTypeOption)
        }"
        href="javascript://"
        (click)="_dataTypeOption.event.emit(rowData)"
        >{{
          isFunction(_dataTypeOption.label) ? _dataTypeOption.label(rowData, _modelColsService) : _dataTypeOption.label
        }}</a
      >
    </span>
  </span>
</ng-template>
<!-- ***************************************************************************** -->
<!-- Date Date Time Datatype -->
<ng-template #dateDateTimeTypeColumnTpl let-col let-rowData="rowData" let-formGroup="formGroup" let-isOutput="isOutput">
  <span [formGroup]="formGroup" class="cellInput-wrapper">
    <p-calendar
      *ngIf="!isOutput"
      type="text"
      [formControlName]="col.field"
      [ngModel]="getDateTimeValue(rowData, col.field)"
      (ngModelChange)="setDateTimeValue(rowData, col.field, $event)"
      [monthNavigator]="true"
      [yearNavigator]="true"
      yearRange="1900:2030"
      [showIcon]="true"
      [style]="{ width: '100%' }"
      [touchUI]="_isMobile"
      appendTo="body"
    ></p-calendar>
  </span>
  <ng-template [ngIf]="isOutput">{{ getDateTimeValue(rowData, col.field) | dateTimeFormat }}</ng-template>
</ng-template>
<!-- ***************************************************************************** -->
<!-- Date Time Datatype -->
<ng-template #dateTimeTypeColumnTpl let-col let-rowData="rowData" let-formGroup="formGroup" let-isOutput="isOutput">
  <span [formGroup]="formGroup" class="cellInput-wrapper">
    <p-calendar
      *ngIf="!isOutput"
      type="text"
      [formControlName]="col.field"
      [ngModel]="getValue(rowData, col.field)"
      (ngModelChange)="setValue(rowData, col.field, $event)"
      showTime="true"
      hourFormat="24"
      [showIcon]="true"
      dataType="string"
      timeOnly="true"
      [touchUI]="_isMobile"
      appendTo="body"
    ></p-calendar>
  </span>

  <ng-template [ngIf]="isOutput">{{ getValue(rowData, col.field) | dateFormat }}</ng-template>
</ng-template>

<!-- Autocomplete Datatype -->
<ng-template
  #autocompleteTypeColumnTpl
  let-col
  let-rowData="rowData"
  let-formGroup="formGroup"
  let-rowIndex="rowIndex"
  let-isOutput="isOutput"
>
  <span [formGroup]="formGroup" class="cellInput-wrapper">
    <p-autoComplete
      *ngIf="!isOutput"
      [formControlName]="col.field"
      [ngModel]="getAutocompleteValue(rowIndex, rowData, col)"
      (ngModelChange)="setAutocompleteValue(rowIndex, rowData, col, $event)"
      field="label"
      [suggestions]="autocompleteSuggestions(col)"
      (completeMethod)="onAutocompleteSearch($event, col, rowData)"
      [multiple]="col.dataTypeOptions?.isMultiple"
      appendTo="body"
      [dropdown]="true"
    >
      <ng-template let-object pTemplate="item">
        <div class="formlist-autocompleteItem ui-helper-clearfix">{{ object.label }}</div>
      </ng-template>
      <ng-template let-object pTemplate="selectedItem">
        <div class="formlist-autocompleteItem ui-helper-clearfix">{{ object.label }}</div>
      </ng-template>
    </p-autoComplete>

    <ng-template [ngIf]="isOutput">{{ getAutocompleteCaptions(rowIndex, rowData, col) }}</ng-template>
  </span>
</ng-template>

<!-- Open Details Link Datatype -->
<ng-template
  #openDetailsLinkTypeColumnTpl
  let-col
  let-rowData="rowData"
  let-formGroup="formGroup"
  let-rowIndex="rowIndex"
  let-isOutput="isOutput"
>
  <span
    *ngIf="col.dataTypeOptions?.prefix"
    class="input-preffix"
    [innerHTML]="col.dataTypeOptions.prefix(rowData)"
  ></span>

  <input
    *ngIf="!isOutput"
    type="text"
    [formControlName]="col.field"
    [ngModel]="getValue(rowData, col.field)"
    (ngModelChange)="setValue(rowData, col.field, $event)"
  />
  <a *ngIf="isOutput" href="javascript://" (click)="goEdit(rowData)">{{ getValue(rowData, col.field) }}</a>

  <span
    *ngIf="col.dataTypeOptions?.suffix"
    class="input-suffix"
    [innerHTML]="col.dataTypeOptions.suffix(rowData)"
  ></span>
</ng-template>
