/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Location } from "@angular/common";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";
import { PrintingFactoryService } from "src/app/shared/services/printing-service.service";
import { CashDenomination } from "../../zone-settings/zone-denominations/cash-denomination";
import { ZoneDenominationService } from "../../zone-settings/zone-denominations/zone-denominations.service";
import { CashoutOpeningFloat } from "../settings-cashout-opening-float/cashout-opening-float";
import { StoreSettingsGenericComponent } from "../StoreSettingsGenericComponent";
import { StoreCashoutSettingsService } from "./cashout-settings.service";
import {
  CashCalculationType,
  CashCalculationTypeLabels,
  PresetOpeningFloatOption,
  StoreSettingCashout,
  TillCashoutClosingType,
} from "./CashoutSettings";
import { Subscription } from "rxjs";
import { ACTION_TYPE } from "src/app/shared/components/action-button/action-type.enum";

@Component({
  selector: "taku-cashout-settings",
  templateUrl: "./cashout-settings.component.html",
  styleUrls: ["./cashout-settings.component.scss"],
})
export class CashoutSettingsComponent extends StoreSettingsGenericComponent implements OnInit, OnDestroy {
  public ACTION_TYPE: typeof ACTION_TYPE = ACTION_TYPE;
  subsList: Subscription[] = [];
  currencyISOCode = "";
  PresetOpeningFloatOption = PresetOpeningFloatOption;

  enum_cashout_calculation_method = this.dbService.enumSelectOptions(TillCashoutClosingType).map((item) => {
    if (item.value === TillCashoutClosingType.by_store) {
      item.disabled = true;
      item.icon = "pi pi-lock";
    }

    return item;
  });

  enum_cash_calculation_type = this.dbService.enumSelectOptions(
    this.dbService.getMappedEnum(CashCalculationType, CashCalculationTypeLabels),
    {
      useKeyAsLabel: false,
      useNullAsValue: false,
      emptyRowCaption: "",
      emptyRowValue: null,
    }
  );

  _zoneCashDenominations: CashDenomination[];
  _hasPreviousOpeningFloat = false;
  _pageTitlePrefix = "Cashout Settings";

  constructor(
    private zoneDenominationService: ZoneDenominationService,
    _router: Router,
    fb: UntypedFormBuilder,
    dbService: StoreCashoutSettingsService,
    location: Location,
    printingFactoryService: PrintingFactoryService,
    _route: ActivatedRoute,
    messageService: MessageService,
    alertMessage: AlertMessagesService,
    confirmationService: ConfirmationService,
    appSettings: AppSettingsStorageService
  ) {
    super(
      _router,
      fb,
      dbService,
      location,
      printingFactoryService,
      _route,
      messageService,
      alertMessage,
      confirmationService,
      appSettings
    );
  }

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    const tmpModel = new StoreSettingCashout();
    const tmpForm = fb.group(tmpModel);
    tmpForm.setControl("storeSettingCashoutOpeningFloats", fb.array([]));

    return tmpForm;
  }

  initForm() {
    this._model = "storeSettingCashout";
    this._object = new StoreSettingCashout();
    this._myForm = CashoutSettingsComponent.init(this.fb);
  }

  setFormArrays() {
    const formArray = this.fb.array([]);
    this._object.storeSettingCashoutOpeningFloats.map((openingFloat) => {
      const temp = this.fb.group(openingFloat);
      formArray.push(temp);
    });

    this._myForm.setControl("storeSettingCashoutOpeningFloats", formArray);
  }

  totalFloatCash(): number {
    const openingFloatsArray: UntypedFormArray = <UntypedFormArray>this._myForm.get("storeSettingCashoutOpeningFloats");
    let totalFloat = 0;
    for (const currencyCtrl of openingFloatsArray.controls) {
      totalFloat += currencyCtrl.get("amount").value * currencyCtrl.get("count").value;
    }
    return totalFloat;
  }

  initLookups() {}

  addCashoutFloat() {
    const openingFloats = <UntypedFormArray>this._myForm.get("storeSettingCashoutOpeningFloats");
    openingFloats.insert(0, this.fb.group(new CashoutOpeningFloat()));
  }

  removeCashoutFloat(index: number) {
    const cashoutFloatsArray = <UntypedFormArray>this._myForm.get("storeSettingCashoutOpeningFloats");

    if (cashoutFloatsArray.at(index).pristine) {
      this._removeFormArrayChild(cashoutFloatsArray, index);
      return;
    }

    this.confirmationService.confirm({
      message: "By deleting this Currency you will lose this change. Are you sure?",

      accept: () => {
        this._removeFormArrayChild(cashoutFloatsArray, index);
      },
    });
  }

  syncWithZoneDenom() {
    this.confirmationService.confirm({
      message:
        "This action will remove rows that do not match an existing zone denomination, and will add rows for any missing zone denominations.",

      accept: () => {
        const denomSet = new Set(this._zoneCashDenominations.map((z) => z.value));
        const newDenoms: CashoutOpeningFloat[] = [];
        (<UntypedFormArray>this._myForm.get("storeSettingCashoutOpeningFloats")).controls
          .map((c) => c.value)
          .forEach((prevFloat: CashoutOpeningFloat) => {
            // If this float value exists in zone denoms, add it to new list, and delete from set
            if (denomSet.delete(prevFloat.amount)) {
              newDenoms.push(prevFloat);
            }
          });
        // Add floats for denoms that were absent, to start of array
        const absentDenoms = Array.from(denomSet).map((d) => new CashoutOpeningFloat(d));
        const full = absentDenoms.concat(newDenoms);

        // Create new control array
        const newFloats = this.fb.array(full.map((d) => this.fb.group(d)));
        this._myForm.setControl("storeSettingCashoutOpeningFloats", newFloats);
        this._myForm.get("storeSettingCashoutOpeningFloats").markAsDirty();
      },
    });
  }

  // Override StoreSettingsGenericComponent's in order to perform custom action
  loadObject() {
    this.currencyISOCode = "CAD"; // TODO Get Currency from Active Store Settings

    this.subsList.push(
      this._route.params.subscribe((params) => {
        if (params["storeId"]) {
          const storeId = params["storeId"];
          this.subsList.push(
            this._fetchAndStoreFormObject$(storeId).subscribe((cashoutSettings: StoreSettingCashout) => {
              this._updatePageTitle(cashoutSettings.store);
              const zoneId = cashoutSettings.store.zoneId;
              // If doesn't have default opening float settings, then prepopulate with Zoneal Denominations
              this._hasPreviousOpeningFloat = cashoutSettings?.storeSettingCashoutOpeningFloats.length !== 0;

              this.subsList.push(
                this.zoneDenominationService.getZoneDenominations(zoneId).subscribe((cashDenominations) => {
                  // Assigned to component's property the zoneal denominations
                  this._zoneCashDenominations = cashDenominations;

                  // Create form array with defaults
                  if (!this._hasPreviousOpeningFloat) {
                    this._buildFormArrayWithDefaultDenominations(this._zoneCashDenominations);
                  }
                })
              );
            })
          );
        }
      })
    );
  }

  _buildFormArrayWithDefaultDenominations(denominations: CashDenomination[]): void {
    const openingFloatsArray: UntypedFormArray = <UntypedFormArray>this._myForm.get("storeSettingCashoutOpeningFloats");
    denominations.forEach((denomination: CashDenomination) => {
      openingFloatsArray.push(
        this.fb.group({
          id: 0,
          amount: denomination.value,
          count: 0,
        })
      );
    });
  }

  initValidation() {
    this._validation = {
      tillCashoutClosingType: [Validators.required],
      // tillStationClosingType: [Validators.required],
    };
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
