/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, Input, ViewChild, OnDestroy } from "@angular/core";
import { BackendGMBService } from "../BackendGMBService";
import { MessageService } from "primeng/api";
import { DynamicDialogRef, DynamicDialogConfig } from "primeng/dynamicdialog";
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import * as _ from "lodash";
import { GMBLocationVerificationStatus } from "../gmb-locations-list/GMBLocation";
import { AlertMessagesService } from "../../../../../shared/services/alert-messages.service";
import { HttpErrorResponse } from "@angular/common/http";
import { TakuInputComponent } from "../../../../../taku-ui/taku-input/taku-input.component";
import { take } from "rxjs/operators";
import { GMBVerificationMethod } from "../gmb-accounts-list/GMBGoogleAccount";
import { Subscription } from "rxjs";

@Component({
  selector: "taku-verification-enter-pin",
  templateUrl: "./verification-enter-pin.component.html",
  styleUrls: ["./verification-enter-pin.component.scss"],
})
export class VerificationEnterPinComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];
  @ViewChild("pinInput", { static: true }) _pinInputComponent: TakuInputComponent;
  _formGroup: UntypedFormGroup;
  _locationTitle: string;
  _verification;
  _errorMessage: string;
  _invalidPIN_Message = "Invalid PIN. Please try again.";

  constructor(
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private gmbService: BackendGMBService,
    private fb: UntypedFormBuilder,
    private messageService: MessageService,
    private alertMessagesService: AlertMessagesService
  ) {
    this._formGroup = this.fb.group({
      pinCode: null,
    });
  }

  ngOnInit() {
    this._verification = this.dialogConfig.data.verification;
    this._locationTitle = this.dialogConfig.data.locationTitle;
  }

  onCancelPressed() {
    // Doesn't pass any data
    this.dialogRef.close({
      stateChanged: false,
    });
  }

  get ctrlPinCode() {
    return this._formGroup.get("pinCode");
  }

  onAcceptPressed() {
    // Issue request to Google to verify code
    const gmbAccountId = this.dialogConfig.data.gmbAccountId;
    const pinCode = this.ctrlPinCode.value;
    this.subsList.push(
      this.gmbService
        .confirmVerification(gmbAccountId, this._verification.name, {
          pin: pinCode,
        })
        .subscribe({
          next: (response) => {
            // if verification was sucessfull, display success notification and close dialog
            if (response && response.verification === GMBLocationVerificationStatus.VERIFIED) {
              this.dialogRef.close({
                stateChanged: true,
              });
              this.messageService.add(this.alertMessagesService.getMessage("gmb-location-verificationpin-success"));
            } else this._processInvalidPIN();
          },
          error: (errorResponse) => {
            if (errorResponse instanceof HttpErrorResponse && errorResponse.status !== 500) {
              if (errorResponse.error.error && errorResponse.error.error.message) {
                const errorMsg = <string>errorResponse.error.error.message;
                if (errorMsg.includes("PIN")) this._processInvalidPIN();
                else {
                  this.messageService.add(
                    this.alertMessagesService.getErrorMessage(
                      null,
                      "ERROR",
                      `${errorMsg}\nPlease make another VERIFICATION REQUEST.`
                    )
                  );
                  this.dialogRef.close({
                    stateChanged: true,
                  });
                }
              }
            } else {
              this.messageService.add(this.alertMessagesService.getErrorMessage(errorResponse));
            }
          },
        })
    );
  }

  private _processInvalidPIN() {
    this._errorMessage = this._invalidPIN_Message;
    this.messageService.add(this.alertMessagesService.getMessage("gmb-location-verificationpin-fail"));

    if (this._pinInputComponent) this._pinInputComponent.focusAndSelectInput();

    this.subsList.push(
      this.ctrlPinCode.valueChanges.pipe(take(1)).subscribe((newValue) => {
        this._errorMessage = "";
      })
    );
  }

  get deliveryMethod(): string {
    const verificationMethod = this._verification.method;
    if (verificationMethod === GMBVerificationMethod.ADDRESS) return "postcard";
    else return _.startCase(verificationMethod);
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
