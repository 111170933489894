/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { retryWhen, map } from "rxjs/operators";
import { AppSettingsStorageService } from "../../../../shared/app-settings-storage.service";
import { AuthService } from "../../../../shared/services/auth.service";
import { DBService } from "../../../../shared/services/db.service";
import { AsyncHelpers } from "../../../../utility/AsyncHelpers";
import { GMCAccount } from "./gmc-accounts/GMCGoogleAccount";
import { ContentAPI_AccountStatuses } from "./gmc-api-data-definitions/ContentAPI_AccountStatus";
import { ContentAPI_Account } from "./gmc-api-data-definitions/ContentAPI_Account";
import { ContentAPI_POS } from "./gmc-api-data-definitions/ContentAPI_PosInventory";
import { ContentAPI_ProductStatuses } from "./gmc-api-data-definitions/ContentAPI_ProductStatutes";
import * as _ from "lodash";

@Injectable({
  providedIn: "root",
})
export class BackendGMCService extends DBService {
  static readonly POS_QUOTA_PER_MIN = 5000;
  static readonly PRODUCTS_QUOTA_PER_MIN = 60000;

  static readonly DEFAULT_DATAPROVIDER_ID = 128629586;
  readonly retryStrategyConfig = {
    maxRetryAttempts: 8,
    scalingDuration: 1500,
  };

  constructor(
    protected http: HttpClient,
    protected authService: AuthService,
    private appSettingsStorageService: AppSettingsStorageService
  ) {
    super(http, authService);
  }

  exchangeToken(authorizationCode: string) {
    return this._postRequest(this.webUrl + "gmcExchangeToken", {
      authorization_code: authorizationCode,
    });
  }

  isIntegrationEnabled(): Observable<boolean> {
    const filterActive = {
      isActive: { matchMode: "equals", value: true },
    };
    return this.getRows("gmcGoogleAccount", JSON.stringify(filterActive)).pipe(
      map((response: any) => response.rows && response.rows.length > 0)
    );
  }

  getConnectedGMBAccounts(gmcAccountId: number) {
    return this._getRequest<any>(this.webUrl + "gmcConnectedGMBAccounts/" + gmcAccountId, null).pipe(
      retryWhen(AsyncHelpers.genericRetryStrategy(this.retryStrategyConfig))
    );
  }

  getLiaStatus(gmcAccountId: number) {
    return this._getRequest<any>(this.webUrl + "gmcLiaStatus/" + gmcAccountId, null).pipe(
      retryWhen(AsyncHelpers.genericRetryStrategy(this.retryStrategyConfig))
    );
  }

  getProductStatusList(gmcAccountId: number, pageToken?: string, maxResults?: number) {
    const queryParams = _.pickBy({ pageToken, maxResults }, _.identity);
    return this._getRequest<ContentAPI_ProductStatuses.ProductstatusesList>(
      this.webUrl + "gmcProductStatusList/" + gmcAccountId,
      queryParams
    );
  }

  connectGMBAccount(gmcAccountId: number, gmbEmail: string) {
    return this._postRequest<any>(this.webUrl + "gmcConnectGMBAccount/" + gmcAccountId, null, {
      gmbEmail,
    }).pipe(retryWhen(AsyncHelpers.genericRetryStrategy(this.retryStrategyConfig)));
  }

  getAccountStatus(gmcAccountId: number) {
    return this._getRequest<any>(this.webUrl + "gmcAccountStatus/" + gmcAccountId).pipe(
      retryWhen(AsyncHelpers.genericRetryStrategy(this.retryStrategyConfig))
    );
  }

  setSetLiaInventoryContact(gmcAccountId: number, email: string, name: string, country: string, language = "EN") {
    return this._postRequest<any>(this.webUrl + "gmcSetLiaConntact/" + gmcAccountId, null, {
      contactEmail: email,
      contactName: name,
      country,
      language,
    }).pipe(retryWhen(AsyncHelpers.genericRetryStrategy(this.retryStrategyConfig)));
  }

  setPosDataProvider(
    gmcAccountId: number,
    country: string,
    posDataProviderId = BackendGMCService.DEFAULT_DATAPROVIDER_ID
  ) {
    return this._postRequest<any>(this.webUrl + "gmcSetPosDataProvider/" + gmcAccountId, null, {
      country,
      posDataProviderId,
    }).pipe(retryWhen(AsyncHelpers.genericRetryStrategy(this.retryStrategyConfig)));
  }

  fetch_googleShoopingSettings(): Observable<GMCAccount> {
    // TODO: Changed it for real API calls
    return of(null);
  }

  getMerchantAccount(gmcAccountId: number) {
    return this._getRequest<ContentAPI_Account.Account>(this.webUrl + "gmcAccount/" + gmcAccountId).pipe(
      retryWhen(AsyncHelpers.genericRetryStrategy(this.retryStrategyConfig))
    );
  }

  addMerchantAccount(gmcGoogleAccountId: number, body: ContentAPI_Account.Account) {
    return this._postRequest<GMCAccount>(this.webUrl + "gmcAccount/" + gmcGoogleAccountId, body).pipe(
      retryWhen(AsyncHelpers.genericRetryStrategy(this.retryStrategyConfig))
    );
  }

  updateMerchantAccount(gmcAccountId: number, body: ContentAPI_Account.Account) {
    return this._patchRequest<ContentAPI_Account.Account>(this.webUrl + "gmcAccount/" + gmcAccountId, body).pipe(
      retryWhen(AsyncHelpers.genericRetryStrategy(this.retryStrategyConfig))
    );
  }

  isAvailable(): Observable<boolean> {
    return of(true);
    // return this.fetch_googleShoopingSettings().pipe(map(shoppingSettings => {
    //     return !!shoppingSettings;
    // }));
  }

  productsInsert(gmbLocationId: number, inventoryId: number) {
    return this._postRequest<any>(this.webUrl + `gmcProductsInsert/${gmbLocationId}/${inventoryId}`, null);
  }

  productsInsertBatch(gmbLocationId: number, inventoryIds: number[]) {
    return this._postRequest<any>(this.webUrl + `gmcProductsInsertBatch/${gmbLocationId}`, {
      inventories: inventoryIds,
    });
  }

  posInventory(gmcAccountId: number, posBody: any) {
    return this._postRequest<any>(this.webUrl + "gmcPosInventory/" + gmcAccountId, posBody);
  }

  posInventoryBatch(gmcAccountId: number, posInventoryData: ContentAPI_POS.PosInventory[]) {
    return this._postRequest<any>(this.webUrl + "gmcPosInventoryBatch/" + gmcAccountId, posInventoryData);
  }

  gmcProductCategory_autocomplete(value: string) {
    return this._getRequest<any>(this.webUrl + `gmcGoogleProductCategory/${value}`);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
