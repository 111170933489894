/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { CurrencyPipe } from "@angular/common";
import { AppConstants } from "../../../shared/app-constants";
import { DateTimeFormatPipe } from "../../../shared/pipes/date-time-format.pipe";
import { FormatNegativeNumberPipe } from "../../../shared/pipes/format-negative-number.pipe";
import { Station } from "../../settings/store-settings/station/station";
import { User } from "../../users/user/user";
import { MonetaryHelpers } from "src/app/utility/MonetaryHelpers";
import { Store } from "../../settings/store-settings/store/store";
import {
  CashCalculationType,
  TillCashoutClosingType,
} from "../../settings/store-settings/cashout-settings/CashoutSettings";

export class Cashout {
  static readonly MAX_COUNT = 10000000;

  id: number;
  openingDate: Date;
  openingTime: string;
  closingDate: Date;
  closingTime: string;
  cashCalculationType: CashCalculationType;
  cashoutOpeningFloats: CashoutOpeningFloat[];
  cashoutClosingFloats: CashoutClosingFloat[];
  storeId: number;
  stationId: number;
  openingCashFloatUserId: number;
  closingCashFloatUserId: number;
  isClosed: boolean;
  openingNote: string;
  closingNote: string;
  cashoutClosingBreakDowns: CashoutClosingBreakDown[];
  openTotalNextFloat: number;
  // openTotalRemainingCash: number;
  closeTotalNextFloat: number | string;
  closeTotalRemainingCash: number | string;
  closeSaveMethod: CashoutSaveMethod;
  openSaveMethod: CashoutSaveMethod;

  // Extra relationships for get-rows
  openingCashFloatUser: User;
  closingCashFloatUser: User;
  station: Station;
  store: Store;
  // calculated fields
  systemTotal: string;
  actualTotal: string;
  docsCount: number;
  tillCashoutClosingType: TillCashoutClosingType;

  constructor() {
    this.id = 0;
    this.openingDate = null;
    this.openingTime = null;
    this.closingDate = null;
    this.closingTime = null;
    this.cashCalculationType = CashCalculationType.by_actual_cash;
    this.cashoutOpeningFloats = [];
    this.cashoutClosingFloats = [];
    this.cashoutClosingBreakDowns = [];
    this.storeId = 0;
    this.store = null;

    this.stationId = 0;
    this.openingCashFloatUserId = 0;
    this.closingCashFloatUserId = 0;
    this.isClosed = false;
    this.openingNote = "";
    this.closingNote = "";
    this.openTotalNextFloat = 0;
    // this.openTotalRemainingCash = 0;
    this.closeTotalNextFloat = 0;
    this.closeTotalRemainingCash = 0;
    this.closeSaveMethod = CashoutSaveMethod.TOTAL;
    this.openSaveMethod = CashoutSaveMethod.TOTAL;

    // Initialize relation in order to be able to access getters inside
    this.openingCashFloatUser = new User();
    this.closingCashFloatUser = new User();
    this.station = new Station();
    this.tillCashoutClosingType = null;
  }

  get totalOverUnderFormated() {
    let output: string | number = "";
    try {
      const currency = this.store.zone.defaultCurrencyIsoCode;
      output = new CurrencyPipe(AppConstants.DEFAULT_LOCALE_ID).transform(this.totalOverUnder, currency, "code");
    } catch (e) {
      output = this.totalOverUnder;
    }

    return new FormatNegativeNumberPipe().transform(output);
  }

  get systemTotalFormated() {
    let output: string | number = "";
    try {
      const currency = this.store.zone.defaultCurrencyIsoCode;
      output = new CurrencyPipe(AppConstants.DEFAULT_LOCALE_ID).transform(this.systemTotal, currency, "code");
    } catch (e) {
      output = this.systemTotal;
    }

    return new FormatNegativeNumberPipe().transform(output);
  }

  get actualTotalFormated() {
    let output: string | number = "";
    try {
      const currency = this.store.zone.defaultCurrencyIsoCode;
      output = new CurrencyPipe(AppConstants.DEFAULT_LOCALE_ID).transform(this.actualTotal, currency, "code");
    } catch (e) {
      output = this.actualTotal;
    }

    return new FormatNegativeNumberPipe().transform(output);
  }

  get totalOverUnder() {
    return MonetaryHelpers.roundToDecimalPlaces(
      parseFloat(this.actualTotal || "0") - parseFloat(this.systemTotal || "0"),
      4
    );
  }

  get totalOpeningFloat(): number {
    switch (this.openSaveMethod) {
      case CashoutSaveMethod.TOTAL:
        return parseFloat(this.openTotalNextFloat + "");

      case CashoutSaveMethod.DETAIL:
        return this.cashoutOpeningFloats.reduce((acc, openingFloat) => {
          return (
            acc +
              MonetaryHelpers.roundToDecimalPlaces(
                parseFloat((openingFloat.amount || "0") + "") * parseFloat((openingFloat.count || "0") + ""),
                4
              ) || 0
          );
        }, 0);
    }
  }

  get totalClosingFloat(): number {
    switch (this.closeSaveMethod) {
      case CashoutSaveMethod.TOTAL:
        return parseFloat((this.closeTotalNextFloat?.toString()?.replace(/,/g, "") || "0") + "");

      case CashoutSaveMethod.DETAIL:
        return this.totalClosingFloatDetails;
    }
  }

  get totalClosingFloatDetails() {
    return this.cashoutClosingFloats.reduce((sum, closeFloat) => {
      return (
        sum +
        (MonetaryHelpers.roundToDecimalPlaces(
          parseFloat((closeFloat.amount || "0") + "") * parseFloat((closeFloat.nextFloatCount || "0") + ""),
          4
        ) || 0)
      );
    }, 0);
  }

  get totalClosingRemainingCash(): number {
    switch (this.closeSaveMethod) {
      case CashoutSaveMethod.TOTAL:
        return parseFloat((this.closeTotalRemainingCash?.toString()?.replace(/,/g, "") || "0") + "");

      case CashoutSaveMethod.DETAIL:
        return this.totalClosingRemainingCashDetails;
    }
  }

  get totalClosingRemainingCashDetails() {
    return this.cashoutClosingFloats.reduce((acc, closeFloat) => {
      return (
        acc +
        (MonetaryHelpers.roundToDecimalPlaces(
          parseFloat((closeFloat.amount || "0") + "") * parseFloat((closeFloat.remainingFloatCount || "0") + ""),
          4
        ) || 0)
      );
    }, 0);
  }

  get status(): string {
    return this.isClosed ? "Closed" : "Open";
  }

  get openingDateAndTime() {
    if (this.openingDate == null && this.openingTime == null) return null;

    try {
      return new DateTimeFormatPipe(AppConstants.DEFAULT_LOCALE_ID).transform(this.openingDate, this.openingTime);
    } catch (err) {
      console.warn("Error when convertign time to local", err, this.openingDate);
      return `${this.openingDate} ${this.openingTime}`;
    }
  }

  get closingDateAndTime() {
    if (this.closingDate == null && this.closingTime == null) return null;

    try {
      return new DateTimeFormatPipe(AppConstants.DEFAULT_LOCALE_ID).transform(this.closingDate, this.closingTime);
    } catch (err) {
      console.warn("Error when convertign time to local", err, this.closingDate);
      return `${this.closingDate} ${this.closingTime}`;
    }
  }

  get actualCashTotal() {
    return MonetaryHelpers.roundToDecimalPlaces(this.totalClosingFloat + this.totalClosingRemainingCash, 2) || 0;
  }
}

export class CashoutOpeningFloat {
  id: number;
  amount: number;
  count: number;
  constructor() {
    this.id = 0;
    this.amount = 0;
    this.count = 0;
  }

  get total() {
    return parseFloat((this.amount || "0") + "") * parseFloat((this.count || "0") + "");
  }
}

export class CashoutClosingFloat {
  id: number;
  amount: number;
  nextFloatCount: number;
  remainingFloatCount: number;

  constructor() {
    this.id = 0;
    this.amount = 0;
    this.nextFloatCount = 0;
    this.remainingFloatCount = null;
  }
}

export class CashoutClosingBreakDown {
  id: number;
  actualAmount: string | number;
  tenderTypeId: number;
  isIntegrated: boolean;
  //isIntegratedPayment: boolean;
  count: number;

  constructor() {
    this.id = 0;
    this.actualAmount = null;
    this.tenderTypeId = null;
    this.isIntegrated = null;
    this.count = 0;
  }
}

export enum CashoutSaveMethod {
  TOTAL = "Total",
  DETAIL = "Detail",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
