/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
import { SaleDocType } from "src/app/core/document/doc/doc";

export class SaleDocFormat {
  id: number;
  docType: SaleDocType;
  prefix: string;
  startingNumber: number;
  noOfDigits: number;
  suffix: string;

  constructor() {
    this.id = 0;
    this.docType = null;
    this.prefix = "";
    this.startingNumber = 0;
    this.noOfDigits = 0;
    this.suffix = "";
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
