<taku-form-header toolbarTitle="Commitments" displayMode="inline" [showActionBtns]="false" [showBackBtn]="false">
</taku-form-header>

<div id="sale-channel-search-box" class="col-12">
  <div class="grid fields-line">
    <div class="col-12 sm:col-6 lg:col-3 p-fluid">
      <taku-taku-calendar-range
        [formGroup]="formGroup"
        fieldCaption="Date Range"
        fieldNameRangeType="dateRangeType"
        fieldNameDateFrom="dateFrom"
        fieldNameDateTo="dateTo"
      ></taku-taku-calendar-range>
    </div>
    <div class="col-12 md:col-6 sm:col-6 lg:col-3 field-account">
      <div class="p-inputgroup inputgroup-outlined">
        <span class="p-inputgroup-addon"><i class="material-icons">group</i></span>
        <taku-search-accounts
          id="account_search"
          #searchAccountsComp
          placeholder="Account"
          [autoClearOnSelection]="false"
          [includeHeader]="false"
          [includeCreateNewBtns]="false"
          (itemSelected)="onSearchResAccount($event)"
          (onNewItemClicked)="openNewModelInDialog()"
          (onSelectedItemCleared)="clearSelectedAccount()"
        >
        </taku-search-accounts>
      </div>
    </div>
    <div class="col-12 sm:col-6 lg:col-3 p-fluid">
      <taku-dropdown
        [formGroup]="formGroup"
        [options]="enum_payment_statuses"
        fieldName="paymentStatus"
        fieldCaption="Payment Status"
      ></taku-dropdown>
    </div>
    <div class="col-12 sm:col-6 lg:col-3 p-fluid"> </div>
    <div class="col-12 sm:col-6 lg:col-3 p-fluid">
      <taku-taku-calendar-range
        [formGroup]="formGroup"
        fieldCaption="Outgoing/Delivery Date"
        fieldNameRangeType="dateRangeType"
        fieldNameDateFrom="pickupDateFrom"
        fieldNameDateTo="pickupDateTo"
        [disabled]="true"
      ></taku-taku-calendar-range>
    </div>
    <div class="col-12 sm:col-6 lg:col-3 p-fluid">
      <taku-dropdown
        [formGroup]="formGroup"
        [options]="lookup_stores$ | async"
        fieldCaption="Fulfillment Store"
        fieldName="fulfillmentStore"
      ></taku-dropdown>
    </div>
    <div class="col-12 sm:col-6 lg:col-3 p-fluid">
      <taku-dropdown
        [formGroup]="formGroup"
        [options]="enum_delivery_methods"
        fieldName="deliveryMethod"
        fieldCaption="Delivery Method"
      ></taku-dropdown>
    </div>
    <div class="col-12 sm:col-6 lg:col-3 p-fluid">
      <button
        pButton
        pRipple
        type="button"
        label="Refresh"
        class="p-button-raised p-button-secondary mt-2"
        (click)="clearSearch()"
      ></button>
    </div>
  </div>

  <div id="data-progressbar">
    <p-progressBar mode="indeterminate" value="0" [style.visibility]="_isFetchingData ? 'visible' : 'hidden'">
    </p-progressBar>
  </div>

  <div class="col-12 formlistWrapper">
    <taku-form-list
      #formList
      *ngIf="_isDataReady"
      [_filter]="formFilter ? formFilter : undefined"
      [_model]="'commitment'"
      _dialogStyleClass="fullscreen-mobilemodal"
      [_dialogShowHeader]="false"
      [_hideSavingCtrls]="false"
      [_showCtrlsInEditMode]="false"
      [_hideAddLineHeaderBtn]="true"
      [_hideOptionsHeaderBtn]="false"
      [_hideGlobalFilter]="true"
      [_hideAddHeaderBtn]="true"
      [_hideDeleteHeaderBtn]="true"
      [_title]="'Commitments'"
      [_rowSelectionType]="RowSelectionType.MULTIPLE"
      _detailMode="n"
      _scrollHeight="calc(100vh - 30rem)"
      [_hideClone]="true"
      _viewColIcon="_"
      _viewColIconName="remove_red_eye"
      [_cols]="commitmentColumns"
      [_dialogDismissable]="true"
    >
      <div takuFormListHeaderCustomBtns>
        <button
          id="create_orders"
          pButton
          pTooltip="Create Orders"
          tooltipPosition="left"
          [disabled]="formList._selectedObjects?.length === null || formList._selectedObjects?.length === 0"
          class="header-button p-button-rounded p-button-icon-only"
          (click)="onGenerateRecurringOrder()"
        >
          <i class="material-icons"> move_to_inbox </i>
        </button>
      </div>
    </taku-form-list>
  </div>
</div>

<ng-template #repeatCycleTpl let-rowData let-isInputCell="isInputCell">
  <div class="flex align-items-center justify-content-center">
    <div>{{ enum_repeat_cycle(rowData) }}</div>
    <button
      pButton
      pRipple
      icon="pi pi-info-circle"
      class="ml-2 p-button-text p-button-rounded p-button-icon-only"
      (click)="commitmentPanel.toggle($event)"
    ></button>
    <p-overlayPanel #commitmentPanel [showCloseIcon]="true" appendTo="body">
      <taku-form-header
        [toolbarTitle]="_headerTitle"
        displayMode="inline"
        [showActionBtns]="false"
        [showBackBtn]="false"
      >
        <!-- <button pButton class="p-button-rounded" type="button" (click)="onClosePressed()" icon="pi pi-times"></button> -->
      </taku-form-header>
      <p-table
        [value]="rowData.commitmentCycles"
        styleClass="p-datatable-gridlines"
        [tableStyle]="{ 'min-width': '50rem' }"
      >
        <ng-template pTemplate="header">
          <tr>
            <th>Doc#</th>
            <th>Pay Type</th>
            <th>Payment Date</th>
            <th>Payment Cycle</th>
            <th>Status</th>
            <th>Ref #</th>
            <th>Total</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-cycle let-rowIndex="rowIndex">
          <tr *ngIf="cycle.refSaleDoc">
            <td>{{ cycle.refSaleDoc?.docId }}</td>
            <td>{{ cycle.refSaleDoc?.saleDocTenders[0]?.tenderType?.type }}</td>
            <td>{{ cycle.refSaleDoc?.saleDocTenders[0]?.createdAt | dateFormat }}</td>
            <td>{{ getPaymentCycle(rowData, rowIndex + 1) }}</td>
            <td>{{ cycle.refSaleDoc?.paymentStatus === "Paid" ? "Captured" : "Scheduled" }}</td>
            <td class="col-refNo">
              <ng-template [ngIf]="!cycle.refSaleDoc?.saleDocTenders[0]?.refTransaction">
                <span class="p-column-title">Ref #:</span>
                {{ cycle.refSaleDoc?.saleDocTenders[0]?.refNo }}
              </ng-template>
              <ng-template
                [ngIf]="
                  cycle.refSaleDoc?.saleDocTenders[0]?.refTransaction &&
                  cycle.refSaleDoc?.saleDocTenders[0]?.refTransaction.RefID !== ''
                "
              >
                <div class="line-transaction">
                  <div class="line-transaction-title">Trans Type:</div>
                  <div class="line-transaction-value">{{
                    cycle.refSaleDoc?.saleDocTenders[0]?.refTransaction.TransType | titlecase
                  }}</div>
                </div>
                <div class="line-transaction">
                  <div class="line-transaction-title">Auth Code:</div>
                  <div class="line-transaction-value">{{
                    cycle.refSaleDoc?.saleDocTenders[0]?.refTransaction.AuthCode
                  }}</div>
                </div>
                <div class="line-transaction">
                  <div class="line-transaction-title">Card Type:</div>
                  <div class="line-transaction-value">{{
                    cycle.refSaleDoc?.saleDocTenders[0]?.refTransaction.CardType
                  }}</div>
                </div>
                <div class="line-transaction">
                  <div class="line-transaction-title">Last 4:</div>
                  <div class="line-transaction-value">{{
                    cycle.refSaleDoc?.saleDocTenders[0]?.refTransaction.Last4
                  }}</div>
                </div>
                <div class="line-transaction">
                  <div class="line-transaction-title">Ref ID:</div>
                  <div class="line-transaction-value">{{
                    cycle.refSaleDoc?.saleDocTenders[0]?.refTransaction.RefID
                  }}</div>
                </div>
                <div class="line-transaction">
                  <div class="line-transaction-title">Entry Method:</div>
                  <div class="line-transaction-value">{{
                    cycle.refSaleDoc?.saleDocTenders[0]?.refTransaction.EntryMethod
                  }}</div>
                </div>
              </ng-template>
            </td>
            <td>{{
              cycle.refSaleDoc?.subTotal
                | currency : cycle.refSaleDoc?.saleDocTenders[0]?.tenderType.currencyIsoCode : "symbol-narrow" : "1.2-2"
                | formatNegativeNumber
            }}</td>
          </tr>
          <!-- <tr *ngIf="!cycle.refSaleDoc">
                <td colspan="5" class="nopayments-col">
                  There are no commitments to show.
                </td>
              </tr> -->
        </ng-template>
      </p-table>
    </p-overlayPanel>
  </div>
</ng-template>

<ng-template #orderAmountTpl let-rowData let-isInputCell="isInputCell">
  <div class="flex align-items-center justify-content-center">
    <span>{{ rowData.subTotalCurrencyFormat }}</span>
    <button
      pButton
      pRipple
      icon="pi pi-info-circle"
      class="ml-2 p-button-text p-button-rounded p-button-icon-only"
      (click)="panel.toggle($event)"
    ></button>
    <p-overlayPanel #panel [showCloseIcon]="true" appendTo="body">
      <div class="tooltip-styling">
        <h5>NOTE:</h5>
        <ul>
          <li>This is the original recurring order amount.</li>
          <li>The next scheduled order will be based on the current unit price. </li>
        </ul>
      </div>
    </p-overlayPanel>
  </div>
</ng-template>

<div [hidden]="true">
  <ng-template #printPreviewHost></ng-template>
</div>
