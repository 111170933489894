<td class="col-doctype">
  <span class="p-column-title">{{ colTypeTitle }}:</span> {{ docType }}
</td>
<td class="col-prefix">
  <span class="p-column-title">Prefix:</span>
  <taku-input
    [formGroup]="_myForm"
    [fieldName]="prefixFieldName"
    fieldCaption="{{ prefixFieldCaption }} Prefix"
  ></taku-input>
</td>
<td class="col-numdigits">
  <span class="p-column-title"># of Digits:</span>
  <taku-input
    [formGroup]="_myForm"
    keyFilter="pint"
    [fieldName]="noOfDigitsFieldName"
    fieldCaption="{{ prefixFieldCaption }} # Digits"
  ></taku-input>
</td>
<td class="col-firstNumber">
  <span class="p-column-title">{{ captionForStartingDocNo }}:</span>
  <taku-input
    [disabled]="docType === 'Voucher'"
    [formGroup]="_myForm"
    keyFilter="pint"
    [fieldName]="startingNumberFieldName"
    [fieldCaption]="captionForStartingDocNo"
    (onBlur)="onBlurStartingNoField()"
  ></taku-input>
</td>
<td class="col-suffix">
  <span class="p-column-title">Suffix:</span>
  <taku-input
    [formGroup]="_myForm"
    [fieldName]="suffixFieldName"
    fieldCaption="{{ prefixFieldCaption }} Suffix"
  ></taku-input>
</td>
<td class="col-preview">
  <span class="p-column-title">Preview:</span>
  <ng-template [ngIf]="_myForm.valid">
    <div class="flex align-items-center">
      {{ prefixCtrl.value + startingNumberCtrl.value + suffixCtrl.value }}
      <button
        *ngIf="docType === 'Voucher'"
        pButton
        pRipple
        class="p-button-rounded p-button-secondary p-button-icon-only ml-2 flex-none"
        icon="pi pi-info-circle"
        (click)="priorityTip.toggle($event)"
      ></button>
      <p-overlayPanel #priorityTip>
        <p> Voucher code is randomly generated (not sequential) </p>
      </p-overlayPanel>
    </div>
  </ng-template>
</td>
