/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  Output,
  ViewChild,
  EventEmitter,
  AfterViewInit,
} from "@angular/core";
import { AppSettingsStorageService } from "../../shared/app-settings-storage.service";
import { CurrencyFormatterService } from "../../shared/services/currency-formatter.service";
import { TakuInputComponent } from "../taku-input/taku-input.component";
import { TakuInputCtrlBase } from "../TakuInputCtrlBase";

@Component({
  selector: "taku-money",
  templateUrl: "./taku-money.component.html",
  styleUrls: ["./taku-money.component.scss"],
})
export class TakuMoneyComponent extends TakuInputCtrlBase {
  @Input() appendTo?: any;
  @Input() currencyFieldName: string;
  @Input() currencyFieldCaption?: string = "Currency";

  @HostBinding("class.readonly-currency")
  @Input()
  currencyIsReadonly = false;

  @Input() autoSelect = false;

  // Currency fieldName and currencyCode should be exclude each other
  @Input() currencyCode?: string; // override currency form value
  @Output() onBlur = new EventEmitter();

  @ViewChild("inputAmount", { static: true })
  _inputAmount: TakuInputComponent;

  constructor(
    public el: ElementRef,
    private currencyFormatterService: CurrencyFormatterService,
    private appSettingsService: AppSettingsStorageService
  ) {
    super(el);
  }

  clear() {
    this.ctrlField.reset();
    this.ctrlField.markAsDirty();
  }

  get ctrlCurrency() {
    return this.formGroup.get(this.currencyFieldName);
  }

  get currencyIsoCode(): string {
    return this.currencyCode || this.ctrlCurrency.value;
  }

  get currencySymbol() {
    const currency = this.currencyFormatterService.getCurrency(this.currencyIsoCode);
    return currency ? currency.code : "-";
  }

  // ngOnInit(){
  //   super.ngOnInit();

  // if (!this.ctrlField.value){
  //   const defaultCurrency = this.appSettingsService.getZone().defaultCurrencyIsoCode;
  //   if(this.ctrlCurrency) this.ctrlCurrency.setValue( defaultCurrency );
  // }
  // // Delayed update when changing amount
  // this.ctrlField.valueChanges.pipe(
  //   filter(newValue => !_.isEmpty(newValue)),
  //   debounceTime( AppConstants.Currency.INPUT_DEBOUNCE_TIME ),
  // ).subscribe(newValue => {
  //   this._updateAmountField(newValue, this.currencyIsoCode);
  // });

  // if (this.ctrlCurrency)
  //   // Update when changing currency
  //   this.ctrlCurrency.valueChanges.subscribe(newCurrency => {
  //     this._updateAmountField(this.ctrlField.value, newCurrency);
  //   });
  // }

  focusAndSelectInput() {
    this._inputAmount.focusAndSelectInput();
  }

  // private _updateAmountField(amount, currency){
  //   const formattedValue = this.currencyFormatterService.formatInputAmount(
  //     currency, amount
  //   )

  //   this.ctrlField.setValue(formattedValue, {emitEvent: false});
  // }

  // ngAfterViewInit() {
  //   console.log(this._inputAmount.formGroup);
  // }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
