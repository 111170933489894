/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  AfterContentChecked,
  DoCheck,
  AfterViewInit,
  OnDestroy,
} from "@angular/core";
import { SelectItem } from "primeng/api";
import { DBService } from "../../services/db.service";
import { Observable, of, Subscription } from "rxjs";
import { AppSettingsStorageService } from "../../app-settings-storage.service";
import { User } from "src/app/core/users/user/user";
import { UserRole } from "src/app/core/users/user-role/user-role";
import { map } from "rxjs/operators";

@Component({
  selector: "taku-store-switcher",
  templateUrl: "./store-switcher.component.html",
  styleUrls: ["./store-switcher.component.scss"],
})
export class StoreSwitcherComponent implements OnInit, OnDestroy {
  lookup_stores: Observable<SelectItem[]>;
  isAdmin: boolean;
  subsList: Subscription[] = [];

  @Output("onStoreChanged") storeChanged: EventEmitter<any> = new EventEmitter();
  @Input() activeZoneId: number;
  @Input() selectedStoreId = 0;

  constructor(private dbService: DBService, private appSettings: AppSettingsStorageService) {
    const activeStore = this.appSettings.getStore();
    this.isAdmin = !(activeStore ? activeStore.id : false);

    this.subsList.push(
      this.appSettings.activeStoreChanged$.subscribe((store) => {
        this.isAdmin = !store.id;
      })
    );
  }

  ngOnInit(): void {
    // Use this extra parameter to get nested property (userRoles) of the user.
    const extraUserParam = {
      includes: "userRole",
    };

    const activeFilter = {
      zoneId: { value: this.activeZoneId, matchMode: "equals" },
    };

    this.subsList.push(
      this.dbService.getRow("user", this.appSettings.getUserId(), extraUserParam).subscribe(
        (res: User) => {
          if (res.isOwner || res.isAdmin || res.isSuperAdmin) {
            this.lookup_stores = this.dbService
              .lookupSelectOptions("store", "storeName", {
                lookupFilter: JSON.stringify(activeFilter),
                enableFieldName: "isActive",
              })
              .pipe(map((rows) => rows.filter((row) => row.value !== null)));
          } else {
            // The list of stores that the user can log into is the list of stores that the user has a role on.
            this.lookup_stores = of(this.convertUserRolesDataToStores(res.userRoles)).pipe(
              map((rows) => rows.filter((row) => row.value !== null))
            );
          }
        },
        (err) => {
          //this.lookup_stores = this.dbService.lookupSelectOptions('store', 'storeName', { lookupFilter: JSON.stringify(activeFilter), enableFieldName: 'isActive' });
        }
      )
    );
  }

  onStoreChanged($event): void {
    const storeId = $event.value;
    this.storeChanged.emit(storeId);
  }

  /**
   * Given a list of user roles, this method returns an array of SelectItems which contain the stores the user has a role on.
   * @param userRoles
   * @returns List Store Role
   */
  convertUserRolesDataToStores(userRoles: UserRole[]): SelectItem[] {
    const res: SelectItem[] = [];
    //Add an empty option
    res.push({ label: "Select a store to change", value: null });

    //loop through each user role
    userRoles.forEach((role) => {
      if (role.stores) {
        //for each user role, loop through each store that role applies to.
        role.stores.forEach((store) => {
          //add the store to the result array if it's zone is active and the store itself is active.
          if (store.zone && store.zone.isActive && store.isActive) {
            res.push({ label: store.storeName, value: store.id });
          }
        });
      }
    });
    return res;
  }

  ngOnDestroy(): void {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
