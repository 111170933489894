/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as _ from "lodash";
import { SelectItem } from "primeng/api";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { Col, DataType, FilterType, TemplateDatatypeOptions } from "src/app/form-list/form-list/form-list";
import { FormListModel } from "src/app/form-list/form-list/form-list-model.interface";
import { AuthService } from "src/app/shared/services/auth.service";
import { DBService } from "src/app/shared/services/db.service";
import { AbstractControl, ValidationErrors, UntypedFormGroup, Validators, ValidatorFn } from "@angular/forms";
import { PaymentTerminal } from "./payment-terminal";
import { FilterRows } from "src/app/utility/FormDataHelpers";
import { Station } from "../../store-settings/station/station";

@Injectable({
  providedIn: "root",
})
export class GatewayTerminalsService extends DBService implements FormListModel {
  constructor(http: HttpClient, authService: AuthService) {
    super(http, authService);
  }

  getLookup_storeStations(storeId: number): Observable<SelectItem[]> {
    return this.lookupSelectOptions("station", "stationName", {
      lookupFilter: JSON.stringify({
        storeId: { matchMode: "equals", value: storeId },
      } as FilterRows<Station>),
      enableFieldName: "isActive",
    });
  }

  getLookup_storeStationsCashoutScreen(stationId: number): Observable<SelectItem[]> {
    return this.lookupSelectOptions("station", "stationName", {
      lookupFilter: JSON.stringify({
        id: { matchMode: "equals", value: stationId },
      }),
      enableFieldName: "isActive",
    });
  }

  getValidationRules(): { [key: string]: {} | ValidatorFn[] } {
    return {
      terminalName: [Validators.required],
      stationId: [Validators.required],
    };
  }

  getAsyncValidationRules() {
    return {
      serialNo: [this.validateUniqueSerialNumber.bind(this)],
      stationId: [this.validateUniqueStation.bind(this)],
    };
  }

  validateUniqueSerialNumber(
    control: AbstractControl,
    formGroup: UntypedFormGroup,
    rowsForms: UntypedFormGroup[]
  ): Observable<ValidationErrors> {
    const extraSerials = _.difference(rowsForms, [formGroup]).map((formGroup) => formGroup.get("serialNo").value);
    return this.checkUniqueSerialNumber(control.value, formGroup.value, extraSerials).pipe(
      map((res: boolean) => {
        return res ? null : { terminalSerialNumberNotUnique: true };
      })
    );
  }

  validateUniqueStation(
    control: AbstractControl,
    formGroup: UntypedFormGroup,
    rowsForms: UntypedFormGroup[]
  ): Observable<ValidationErrors> {
    const localStations = _.difference(rowsForms, [formGroup]).map((formGroup) => formGroup.get("stationId").value);
    return this.checkUniqueStation(control.value, formGroup.value, localStations).pipe(
      map((res: boolean) => {
        return res ? null : { terminalStationNotUnique: true };
      })
    );
  }

  checkUniqueStation(
    stationId: number,
    terminal: PaymentTerminal,
    localStationIds: number[] = []
  ): Observable<boolean> {
    // Validate first rows that are only in client-side
    if (localStationIds.findIndex((theStationId) => theStationId == stationId) >= 0) return of(false);

    // Have to search if there are not other Terminals with the same *Station* in other pages
    const filter = {
      stationId: { value: stationId, matchMode: "equals" },
      paymentGatewayId: { value: terminal.paymentGatewayId, matchMode: "equals" },
    };

    if (terminal.id) filter["id"] = { matchMode: "ne", value: terminal.id };

    return this.getRows("paymentTerminal", JSON.stringify(filter), 0, 1).pipe(
      map((res: any) => !(res.rows && res.rows.length))
    );
  }

  checkUniqueSerialNumber(
    serialNo: string,
    terminal: PaymentTerminal,
    extraSerials: string[] = []
  ): Observable<boolean> {
    // Validate first rows that are only in client-side
    if (extraSerials.findIndex((theSerial) => theSerial && theSerial == serialNo) >= 0) return of(false);

    // Have to search if there are not other Terminals with the same *Serial Number* in other pages
    const filter = {
      serialNo: { value: serialNo, matchMode: "equals" },
      paymentGatewayId: { value: terminal.paymentGatewayId, matchMode: "equals" },
    };

    if (terminal.id) filter["id"] = { matchMode: "ne", value: terminal.id };

    return this.getRows("paymentTerminal", JSON.stringify(filter), 0, 1).pipe(
      map((res: any) => !(res.rows && res.rows.length))
    );
  }

  getFormListColumns(gatewayStoreId?, isPairedOptions?: TemplateDatatypeOptions): Col[] {
    return <Col[]>[
      {
        field: "isActive",
        header: "Active",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.checkbox,
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.enum,
      },
      // { field: 'serialNo', header: 'Serial Number', visible: true, readonly: false, frozen: false, dataType: DataType.input, dataOptions: [], filterType: FilterType.contains, },
      {
        field: "terminalName",
        header: "Terminal Name",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 350,
      },
      gatewayStoreId
        ? {
            field: "stationId",
            header: "Station ID/Name",
            visible: true,
            readonly: false,
            frozen: false,
            dataType: DataType.lookup,
            dataOptions: this.getLookup_storeStations(gatewayStoreId),
            filterType: FilterType.lookup,
            colWidth: 350,
            sortFields: ["station.stationNumber", "station.stationName"],
          }
        : null,
      // isPairedOptions ? { field: 'isPaired', header: 'Action', visible: true, readonly: true, frozen: false, dataType: DataType.template, dataTypeOptions: isPairedOptions, dataOptions: [], filterType: FilterType.none } : null,
    ].filter(Boolean);
  }

  getFormListColumnsCashoutScreen(
    stationId?,
    isPairedOptions?: TemplateDatatypeOptions,
    bDisplayAtCashout = false
  ): Col[] {
    return _.compact(<Col[]>[
      {
        field: "terminalName",
        header: "Terminal Name",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 220,
      },
      {
        field: "stationId",
        header: "Station ID/Name",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.lookup,
        dataOptions: this.getLookup_storeStationsCashoutScreen(stationId),
        filterType: FilterType.lookup,
        colWidth: 250,
        sortFields: ["station.stationNumber", "station.stationName"],
      },
      isPairedOptions
        ? {
            field: "isPaired",
            header: "Action",
            visible: true,
            readonly: true,
            frozen: false,
            dataType: DataType.template,
            dataTypeOptions: isPairedOptions,
            dataOptions: [],
            filterType: FilterType.none,
            colWidth: 130,
          }
        : null,
    ]);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
