<div [formGroup]="_myForm" class="grid">
  <div class="col-7">
    <img class="preview-picture" [src]="imageSrc" [alt]="altTitleCtrl.value" />
  </div>

  <div class="col-5 preview-checkboxes-container">
    <taku-checkbox
      [formGroup]="_myForm"
      fieldName="isWebEnabled"
      fieldCaption="Web Enabled"
      (onChange)="webEnabledChanged($event)"
    ></taku-checkbox>
    <taku-checkbox
      [formGroup]="_myForm"
      fieldName="isWebDefault"
      fieldCaption="Default Web Image"
      (onChange)="webDefaultChanged($event)"
    ></taku-checkbox>
    <taku-checkbox [formGroup]="_myForm" fieldName="pinToPictureGallery" fieldCaption="PIN to Picture Gallery">
    </taku-checkbox>
  </div>

  <div class="col">
    <taku-input [formGroup]="_myForm" fieldName="urlFileName" fieldCaption="Image URL"></taku-input>
  </div>
  <div class="col-fixed mt-2">
    <p-button
      icon="pi pi-copy"
      (onClick)="copyToClipboard()"
      styleClass="p-button-lg"
      class="p-button p-button-icon-only p-button-rounded"
    ></p-button>
  </div>

  <div class="col-12">
    <taku-input [formGroup]="_myForm" fieldName="caption" fieldCaption="Caption"></taku-input>
  </div>

  <div class="col-12">
    <taku-input [formGroup]="_myForm" fieldName="altTitle" fieldCaption="Alternative Title"></taku-input>
  </div>
</div>

<!-- {{_myForm.value|json}} -->
