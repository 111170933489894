/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { StoreSettingReturnReason } from "../return-reason/StoreSettingReturnReason";
import { StoreSettingsGenericComponent } from "../StoreSettingsGenericComponent";
import { ReturnSetting } from "./ReturnSettings";

@Component({
  selector: "taku-return-settings",
  templateUrl: "./return-settings.component.html",
  styleUrls: ["./return-settings.component.scss"],
})
export class ReturnSettingsComponent extends StoreSettingsGenericComponent {
  _pageTitlePrefix = "Return Settings";
  takuPayEnabled = false;

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    const tmpModel = new ReturnSetting();
    const tmpForm = fb.group(tmpModel);
    tmpForm.setControl("storeSettingReturnReasons", fb.array([]));

    return tmpForm;
  }

  initForm() {
    this._model = "storeSettingReturn";
    this._object = new ReturnSetting();
    this._myForm = ReturnSettingsComponent.init(this.fb);
  }

  setFormArrays() {
    const formArray = this.fb.array([]);
    this._object.storeSettingReturnReasons.map((storeSettingReturnReason) => {
      const temp = this.fb.group(storeSettingReturnReason);
      formArray.push(temp);
    });

    this._myForm.setControl("storeSettingReturnReasons", formArray);

    const filter = {
      isActive: { value: 1, matchMode: "equals" },
      storeId: { value: this._object.storeId, matchMode: "equals" },
    };
    this.subsList.push(
      this.dbService.getRows("takuPaymentGateway", JSON.stringify(filter)).subscribe((result) => {
        if (result.count > 0) {
          this.takuPayEnabled = true;
          this._myForm.patchValue({ canReturnWithoutInvoice: false });
        }
      })
    );
  }

  addReturnReason() {
    const reasonsArray: UntypedFormArray = <UntypedFormArray>this._myForm.get("storeSettingReturnReasons");
    reasonsArray.push(this.fb.group(new StoreSettingReturnReason()));
  }

  removeReturnReason(index) {
    const reasonsArray: UntypedFormArray = <UntypedFormArray>this._myForm.get("storeSettingReturnReasons");
    if (reasonsArray.at(index).pristine) {
      this._removeFormArrayChild(reasonsArray, index);
      return;
    }

    this.confirmationService.confirm({
      message: "By deleting this Return Reason you will lose your changes. Are you sure?",

      accept: () => {
        this._removeFormArrayChild(reasonsArray, index);
      },
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
