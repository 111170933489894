/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SelectItem } from "primeng/api";
import { DBService } from "../../../shared/services/db.service";
import { LocationService } from "../../../shared/services/location.service";
import { AuthService } from "../../../shared/services/auth.service";
import { DataType, FilterType, Col } from "../../../form-list/form-list/form-list";
import { CurrencyFormatterService } from "../../../shared/services/currency-formatter.service";
import { merge, Observable } from "rxjs";
import { take, map } from "rxjs/operators";
import { FormListModel } from "../../../form-list/form-list/form-list-model.interface";
import { ValidatorFn, Validators } from "@angular/forms";
import { Cost_Basis } from "../../inventory/inventory/inventory";
import { Zone } from "./Zone";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { AppDefaultsService } from "src/app/shared/services/app-defaults.service";
import { CashDenomination, Cash_Type } from "./zone-denominations/cash-denomination";
import { TenderType, TenderTypeTransactionType, Tender_Type } from "../business-settings/tender-type/tender-type";
import { ZoneTenderTypesService } from "./zone-tender-types/zone-tender-types.service";
import { Transaction_Type } from "src/app/shared/services/transaction-types";
import { InventoryDocFormat, Inventory_Doc_Type } from "./inventory-doc-formats/InventoryDocFormat";
import * as _ from "lodash";
import { Stock } from "../business-settings/stock/stock";
import { AddressLocationType } from "../../contact-accounts/address/address";

@Injectable({
  providedIn: "root",
})
export class ZoneService extends DBService implements FormListModel {
  lookup_zones: any[];
  static readonly MODEL_NAME = "zone";

  constructor(
    private locationService: LocationService,
    protected http: HttpClient,
    protected authService: AuthService,
    private currencyService: CurrencyFormatterService,
    private appSettings: AppSettingsStorageService,
    private appDefaultsService: AppDefaultsService,
    private dbService: DBService,
    private zoneTenderTypesService: ZoneTenderTypesService
  ) {
    super(http, authService);
  }

  getValidationRules(): { [key: string]: ValidatorFn[] | {} } {
    return {
      defaultCurrencyIsoCode: [Validators.required],
      countryIsoCode: [Validators.required],
      cashRounding: [Validators.required],
      defaultCostBasis: [Validators.required],
    };
  }

  afterSave(zone: Zone, model) {
    if (model == ZoneService.MODEL_NAME && zone.id == this.appSettings.getZoneId()) this.appSettings.updateZone(zone);
  }

  afterCreate(zone: Zone, model) {
    // create Dinomination     defaultDenominations
    const _defaultDenominations = this.appDefaultsService.defaultDenominations(zone.defaultCurrencyIsoCode);
    const _denominationObjects: CashDenomination[] = [];
    _defaultDenominations.forEach((denomination) => {
      const newDenomination = Object.assign({}, new CashDenomination(), {
        zoneId: zone.id,
        value: denomination.value,
        cashType: denomination.type === "coin" ? Cash_Type.coin : Cash_Type.paper,
      });
      _denominationObjects.push(newDenomination);
    });

    // create tender types    defaultTenders
    const _defaultTenders = this.appDefaultsService.defaultTenders(zone.defaultCurrencyIsoCode);
    const _tenderObjects: TenderType[] = [];
    const allTransactionTypes = this.zoneTenderTypesService.all_tenderTypeTransactionTypes;

    _defaultTenders.forEach((tender) => {
      const zoneTender = Object.assign({}, new TenderType(), {
        type: tender.type,
        description: tender.description,
        currencyIsoCode: zone.defaultCurrencyIsoCode,
        zoneId: zone.id,
        transactionTypes:
          tender.type === Tender_Type.Cash
            ? allTransactionTypes
            : allTransactionTypes.filter((type) => [Transaction_Type.Sales_Charge].includes(type.transactionType)),
      });
      _tenderObjects.push(zoneTender);
    });
    // create inventory doc format  defaultInventoryDocFormats
    const _inventoryDocFormatObjects: InventoryDocFormat[] = [];
    Object.keys(Inventory_Doc_Type).forEach((key) => {
      const docType = Inventory_Doc_Type[key];
      const formatsDefaults = this.appDefaultsService.defaultInventoryDocFormats(docType);
      if (!_.isEmpty(formatsDefaults)) {
        const inventoryFormat = Object.assign({}, new InventoryDocFormat(), formatsDefaults, {
          docType,
          zoneId: zone.id,
        });
        _inventoryDocFormatObjects.push(inventoryFormat);
      }
    });
    merge(
      this.dbService.batchAddRows("cashDenomination", _denominationObjects),
      this.dbService.batchAddRows("tenderType", _tenderObjects),
      this.dbService.batchAddRows("inventoryDocFormat", _inventoryDocFormatObjects),
      this.dbService.addRow(
        "stock",
        Object.assign(new Stock(), {
          stockName: `Stock - ${zone.zoneName}`,
          zoneId: zone.id,
          isActive: true,
          address: {
            countryIsoCode: zone.countryIsoCode,
            addressLocationType: AddressLocationType.commercial,
          },
        })
      )
    ).subscribe({
      next: () => {},
      error: (error) => {},
    });
  }

  get_enumCountries(): SelectItem[] {
    return this.locationService.lookupCountryOptions_full();
  }

  get_enum_cost_basis(): SelectItem[] {
    return this.enumSelectOptions(Cost_Basis).map((item) => {
      if ([Cost_Basis.FIFO, Cost_Basis.LIFO, Cost_Basis.average].includes(item.value)) {
        item.disabled = true;
        item.icon = "pi pi-lock";
      }
      return item;
    });
  }

  get_lookup_zones(): Observable<SelectItem[]> {
    // if (this.lookup_zones) {
    //   return new Observable(observer => { observer.next(this.lookup_zones); });
    // } else {
    return this.lookupSelectOptions("zone", "zoneName", { enableFieldName: "isActive" }).pipe(
      take(1),
      map((data: SelectItem[]) => {
        this.lookup_zones = data;
        return data;
      })
    );
    // }
  }

  getFormListColumns() {
    const taxCategoryEnabled: boolean = this.authService.hasEnablePermit(
      "Accounts_Menu_Personal_Accounts_Tax_Category"
    );
    const lookup_taxAccountCategory = this.lookupSelectOptions("taxAccountCategory", "category", {
      enableFieldName: "isEnabled",
    });
    return [
      {
        field: "isActive",
        header: "Active",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.checkbox,
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.enum,
      },
      {
        field: "countryIsoCode",
        header: "Country",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.get_enumCountries(),
        filterType: FilterType.enum,
      },
      {
        field: "zoneName",
        header: "Zone Name",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "defaultCurrencyIsoCode",
        header: "Default Currency",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.currencyService.getCurrencyCodeList(),
        filterType: FilterType.enum,
      },
      {
        field: "cashRounding",
        header: "Cash Rounding",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "defaultCostBasis",
        header: "Default Cost Basis",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.get_enum_cost_basis(),
        filterType: FilterType.enum,
      },
      {
        field: "taxAccountCategoryId",
        fieldValue: "taxAccountCategory.category",
        header: "Supplier Tax Category",
        visible: false,
        readonly: !taxCategoryEnabled,
        frozen: false,
        dataType: DataType.lookup,
        dataOptions: lookup_taxAccountCategory,
        filterType: FilterType.lookup,
        sortFields: ["taxAccountCategory.category"],
      },
    ];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
