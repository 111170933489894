/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
import { Component, OnInit, AfterViewInit, ElementRef, OnDestroy, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MessageService } from "primeng/api";
import { Observable, of, Subject, Subscription } from "rxjs";
import { catchError, share, map } from "rxjs/operators";
import { UnsavedChangesGuard } from "src/app/app-routing/UnsavedChanges.guard";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";
import { StorePoliciesSettings } from "../../settings/store-settings/store-policies-settings/StorePoliciesSettings";
import { StepValidationMessages } from "src/app/forms/dialog-validation-messages/dialog-validation-messages.component";
import { FormDataHelpers } from "src/app/utility/FormDataHelpers";
import { ValidationMessagesService } from "src/app/shared/services/validation-messages.service";
import * as _ from "lodash";
import * as moment from "moment";
import "moment-timezone";
import { ConsentFormInfo } from "../person/person.component";
import { WebHelpers } from "src/app/utility/WebHelpers";
import { AppConstants } from "src/app/shared/app-constants";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { PersonalAccount } from "../personal-account/personal-account";
import { DBService } from "src/app/shared/services/db.service";

@Component({
  selector: "taku-customer-consent",
  templateUrl: "./customer-consent.component.html",
  styleUrls: ["./customer-consent.component.scss"],
})
export class CustomerConsentComponent implements OnInit, AfterViewInit, OnDestroy {
  subsList: Subscription[] = [];
  personForm: UntypedFormGroup;
  consentForm: UntypedFormGroup;
  consentMsg$: Observable<string>;
  onSave$: Subject<ConsentFormInfo>;
  consentApproved = false;
  validationMessages: StepValidationMessages[];
  validationDialogVisible = false;
  keyCodes = AppConstants.KEY_CODES;
  consentDateTime: Date;

  @Input() personFormAndAccountData: { personForm: UntypedFormGroup; accountData: PersonalAccount };
  accountData: PersonalAccount;
  @Input() openedFromSaleScreen = false;
  @Output() closeDialogInSaleScreen: EventEmitter<any> = new EventEmitter();
  @Output() changedForm: EventEmitter<PersonalAccount> = new EventEmitter();

  get canSave() {
    // return this.personForm.valid && this.consentForm.valid;
    return this.consentCtrl.dirty;
  }

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private fb: UntypedFormBuilder,
    private appSettings: AppSettingsStorageService,
    private unsavedChangesGuard: UnsavedChangesGuard,
    private messageService: MessageService,
    private alertMessage: AlertMessagesService,
    public dbService: DBService,
    private validationMessagesService: ValidationMessagesService,
    private el: ElementRef
  ) {
    // if (!this.openedFromSaleScreen) {
    //   this.personForm = dialogConfig.data.formGroup;
    //   this.personForm.markAsPristine();
    //   this.onSave$ = dialogConfig.data.onSave;
    // }
  }

  get consentCtrl() {
    return this.consentForm.get("consent");
  }

  closeDialogPressed() {
    this.subsList.push(
      this.unsavedChangesGuard.checkForm(this.personForm.pristine).subscribe((accepted) => {
        if (accepted) {
          if (!this.openedFromSaleScreen) {
            this.dialogRef.close(this.personForm);
          } else {
            this.closeDialogInSaleScreen.emit();
          }
        }
      })
    );
  }

  ngOnInit() {
    if (!this.openedFromSaleScreen) {
      this.personForm = this.dialogConfig.data.formGroup;
      this.personForm.markAsPristine();
      this.onSave$ = this.dialogConfig.data.onSave;
    } else {
      this.personForm = this.personFormAndAccountData.personForm;
      this.personForm.markAsPristine();
      this.accountData = this.personFormAndAccountData.accountData;
    }

    this.consentForm = this.fb.group({
      consent: this.fb.control(
        this.openedFromSaleScreen ? this.accountData.consent : this.dialogConfig.data.consentApproved
      ),
    });

    this.consentDateTime = this.openedFromSaleScreen
      ? this.accountData.consentDateTime
      : this.dialogConfig.data.consentDateTime;

    this.consentApproved = this.openedFromSaleScreen
      ? this.accountData.consent
      : this.dialogConfig.data.consentApproved;

    // this.subsList.push(merge(this.personForm.valueChanges, this.consentForm.valueChanges).subscribe(() => {
    //   // this updates the consentApproved property every time the checkbox value changes
    //   // might not need these lines of code?
    //   this.consentApproved = false;
    // }));

    const store = this.appSettings.getStore();
    this.consentMsg$ = this.appSettings.getStoreSettings("storePolicy").pipe(
      catchError((error) => of(new StorePoliciesSettings(store))),
      map((policies: any) => policies.consent || new StorePoliciesSettings(store).consent),
      share()
    );
  }

  private validateForm() {
    const personValidation = _.uniqBy(FormDataHelpers.getFormValidationErrors(this.personForm), "control").map(
      (error) => ({
        fieldName: error.control,
        validationError: this.validationMessagesService.getErrorMessage(error.error, error.value, error.control),
      })
    );

    const consentValidation = FormDataHelpers.getControlValidationErrors(
      "Consent",
      this.consentForm.get("consent")
    ).map((error) => ({
      fieldName: error.control,
      validationError: "Your approval to use your personal information is required",
    }));

    // If there are not validation errors
    if (consentValidation.length + personValidation.length === 0) return null;
    else
      return [
        consentValidation.length
          ? {
              stepCaption: "Customer Consent",
              messages: consentValidation,
            }
          : null,
        personValidation.length
          ? {
              stepCaption: "Personal Details",
              messages: personValidation,
            }
          : null,
      ].filter(Boolean);
  }

  onCheckPressed() {
    // this.validationMessages = this.validateForm();
    // if (this.validationMessages) {
    //   this.validationDialogVisible = true;
    //   return;
    // }

    // // Make sure that consent message is fetched
    // this.consentMsg$.subscribe(consentMessage => {
    //   // Comunicate changes to Person component or other subscriber
    //   this.onSave$.next({
    //     consentMsg: consentMessage,
    //     consentTime: new Date,
    //     person: this.personForm.value,
    //   });
    // });

    // Comunicate changes to Person component or other subscriber
    const lastConsentTime = new Date();
    if (this.consentApproved !== this.consentCtrl.value) {
      this.consentDateTime = lastConsentTime;
    }
    // console.log(this.consentDateTime, this.consentApproved);
    this.consentApproved = this.consentCtrl.value;

    if (this.openedFromSaleScreen) {
      this.updateAccountData();
      // calling api to update the corresponding personalAccount in the database
      this.dbService.editRow("personalAccount", this.accountData).subscribe((result) => {
        if (result) {
          // console.log("consent status in associated personal account is updated in the backend");
        }
      });
      this.changedForm.emit(this.accountData);
    } else {
      this.onSave$.next({
        consentTime: this.consentDateTime,
        person: this.personForm.value,
        consentApproved: this.consentApproved,
      });
    }

    this.personForm.markAsPristine();
    this.messageService.clear();
    this.messageService.add(Object.assign(this.alertMessage.getMessage("save-success")));
  }

  ngAfterViewInit(): void {
    WebHelpers.CancelKeydownBubbling(this.el.nativeElement, this.keyCodes.TAB.code, this.keyCodes.TAB.name);
  }

  get formattedConsentTime(): string {
    return moment(this.consentDateTime).tz(moment.tz.guess()).format("MMMM D, YYYY @ h:mma z");
  }

  updateAccountData() {
    this.accountData.person = this.personForm.value;
    this.accountData.consent = this.consentApproved;
    this.accountData.consentDateTime = this.consentDateTime;
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
