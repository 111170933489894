/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { ValidatorFn } from "@angular/forms";
import { Col, DataType, FilterType } from "../../../../form-list/form-list/form-list";
import { FormListModel } from "../../../../form-list/form-list/form-list-model.interface";
import { DBService } from "../../../../shared/services/db.service";

@Injectable({
  providedIn: "root",
})
export class TAKUPayoutsReportColsFactoryService {
  constructor(public dbService: DBService) {}

  build(dynamicFields: string[]) {
    return new TAKUPayoutsReportCols(this, dynamicFields);
  }
}

export class TAKUPayoutsReportCols implements FormListModel {
  constructor(private factoryService: TAKUPayoutsReportColsFactoryService, private dynamicFields: string[]) {}

  _disableCloning = true;
  getValidationRules(): { [key: string]: {} | ValidatorFn[] } {
    return {};
  }

  getFormListColumns(): Col[] {
    const cols: Col[] = [
      {
        field: "date_paid",
        header: "Date Paid",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 80,
        isNotSortable: true,
      },
      {
        field: "status",
        header: "Status",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 80,
        isNotSortable: true,
      },
      {
        field: "collected_amount",
        header: "Collected Payments",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 80,
        isNotSortable: true,
        footer: true,
      },
      {
        field: "refunds",
        header: "Refunds",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 80,
        isNotSortable: true,
        footer: true,
      },
      {
        field: "fees",
        header: "Processing Fees",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 80,
        isNotSortable: true,
        footer: true,
      },
      {
        field: "transfer_amount",
        header: "Total Payout",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 80,
        isNotSortable: true,
        footer: true,
      },
    ];

    return cols;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
