/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "../../../../shared/services/auth.service";
import { DataType, FilterType, Col } from "../../../../form-list/form-list/form-list";
import { FormListModel } from "../../../../form-list/form-list/form-list-model.interface";
import { ValidatorFn, Validators } from "@angular/forms";
import { CancellationPolicy, FrequencyType, RecurringOrderSetting } from "./recurring-order-settings";
import { DBService } from "src/app/shared/services/db.service";

@Injectable({
  providedIn: "root",
})
export class RecurringOrderSettingsService extends DBService implements FormListModel {
  static readonly MODEL_NAME = "recurringOrderSetting";

  private frequencyEnumSelect = this.enumSelectOptions(FrequencyType);
  private cancellationEnumSelect = this.enumSelectOptions(CancellationPolicy);

  constructor(protected http: HttpClient, protected authService: AuthService) {
    super(http, authService);
  }

  getValidationRules(): Partial<Record<keyof RecurringOrderSetting, ValidatorFn[]>> {
    return {
      recurringOrderName: [Validators.required],
      recurringOrderFrequency: [Validators.required],
    };
  }

  getFormListColumns(): Col[] {
    const storesLookup = this.lookupMultiSelectOptions("store", "storeName", {
      enableFieldName: "isActive",
    });
    return [
      {
        field: "recurringOrderName",
        header: "Recurring Order Name",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "recurringOrderFrequency",
        header: "Recurring Frequencies",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.frequencyEnumSelect,
        filterType: FilterType.enum,
      },
      {
        field: "isActive",
        header: "Active",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.checkbox,
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.enum,
      },
      {
        field: "recuringDiscountPercent",
        header: "Recurring Discount (%)",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "cancellationPolicy",
        header: "Cancellation Policy",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.cancellationEnumSelect,
        filterType: FilterType.enum,
      },
      {
        field: "storePolicy",
        header: "Store Policy",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.none,
      },
      {
        field: "fulfillmentLeadTime",
        header: "Fulfillment Leadtime",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "stores",
        fieldValue: "storeList",
        header: "Stores",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.multiselect,
        dataOptions: storesLookup,
        filterType: FilterType.lookup,
        colWidth: 250,
        isNotSortable: true,
      },
    ];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
