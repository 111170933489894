<div #mainContainer class="main-container grid">
  <div class="left-container col-12 lg:col-6">
    <!-- Left Side -->
    <div class="top-left grid-nogutter" [ngClass]="{ 'is-disabled': transactionAreaDisabled }">
      <ng-container
        *ngTemplateOutlet="
          headerTemplate;
          context: {
            $implicit: _myForm,
            saleStation: _object.station,
            cashout: _cashout,
            documentUser: _object.doc.user,
            saleAccount: _selectedAccount,
            activePriceLevel
          }
        "
      ></ng-container>
    </div>

    <div #invoicePlaceholder class="invoice-placeholder">
      <form [formGroup]="_myForm" class="main" (ngSubmit)="onSubmit()" novalidate>
        <div class="middle-left card" [ngClass]="{ 'is-disabled': transactionAreaDisabled }" #transactionsArea>
          <ng-container *ngTemplateOutlet="transactionsHeader"></ng-container>

          <!-- <div #scrollMe class="invoice-content"> -->
          <p-scrollPanel
            id="docLines-wrapper"
            #scrollMe
            [style]="{ width: '100%', height: 'calc(100% - 46px)' }"
            styleClass="custombar-salesscreen"
          >
            <span *ngFor="let saleDocLine of saleDocLines['controls']; let index = index">
              <ng-container
                *ngTemplateOutlet="saleDocLineComponent; context: { $implicit: saleDocLine, index: index }"
              ></ng-container>
            </span>
          </p-scrollPanel>
          <!-- </div> -->
        </div>
        <div *ngIf="!isMobile" class="transactionsFooter-desktop">
          <ng-container *ngTemplateOutlet="docSummary"></ng-container>
        </div>

        <div *ngIf="isMobile" class="transactionsFooter-mobile">
          <taku-sale-doc-footer-compact
            [allowReturns]="canMakeReturns"
            [saleForm]="_myForm"
            [currencyIsoCode]="documentCurrency"
            (saleParked)="onPark($event)"
            (itemReturned)="itemReturnedAction($event)"
            (onSalePaid)="onPay($event)"
            (docTotalChanged)="updateSaleTotal($event)"
            (openDeliveryDialog)="openDeliveryMethodDialog()"
            (summaryOpened)="onDocSummaryOpened($event)"
          ></taku-sale-doc-footer-compact>
        </div>
      </form>
      <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
    </div>
  </div>

  <div class="right-container col-12 lg:col-6">
    <ng-container
      *ngTemplateOutlet="
        rightSideTemplate;
        context: {
          $implicit: _myForm,
          saleStation: _object.station,
          documentUser: _object.doc.user,
          saleAccount: _selectedAccount,
          invoiceSettings: _invoiceSettings,
          saleTotal: saleDocTotal
        }
      "
    ></ng-container>
  </div>
</div>

<p-dialog
  *ngIf="!isMobile"
  [(visible)]="_activeFullDialog"
  [contentStyle]="_fullDialogParams?.contentStyle"
  [blockScroll]="true"
  [modal]="true"
  appendTo="body"
  [showHeader]="_fullDialogParams?.showHeader"
  (onHide)="_fullDialogParams?.onHide ? _fullDialogParams?.onHide() : null"
  [styleClass]="_fullDialogParams?.styleClass"
  [closable]="_fullDialogParams?.closable"
>
  <ng-container *ngTemplateOutlet="modalOverlayContents; context: { $implicit: _activeFullDialog }"></ng-container>
</p-dialog>

<p-dialog
  *ngIf="isMobile"
  [(visible)]="_activeFullDialog"
  [blockScroll]="true"
  [modal]="true"
  appendTo="body"
  (onHide)="_fullDialogParams?.onHide ? _fullDialogParams?.onHide() : null"
  styleClass="fullscreen-mobilemodal sidebarSales {{ _fullDialogParams?.styleClass }}"
  [showHeader]="_fullDialogParams?.showHeader"
>
  <ng-container *ngTemplateOutlet="modalOverlayContents; context: { $implicit: _activeFullDialog }"></ng-container>
</p-dialog>

<ng-template #docSummary>
  <taku-sale-doc-footer
    [allowReturns]="canMakeReturns"
    [saleForm]="_myForm"
    [currencyIsoCode]="documentCurrency"
    (saleParked)="onPark($event)"
    (itemReturned)="itemReturnedAction($event)"
    (onSalePaid)="onPay($event)"
    (docTotalChanged)="updateSaleTotal($event)"
    (openDeliveryDialog)="openDeliveryMethodDialog()"
    (applyBulkDiscount)="applyBulkDiscount($event)"
  ></taku-sale-doc-footer>
</ng-template>

<ng-template #transactionsHeader let-insideOverlay="insideOverlay">
  <div class="invoice-header">
    <div *ngIf="!insideOverlay" class="header-collapse-all">
      <i *ngIf="!isMobile" class="material-icons" (click)="collapseAll()"> vertical_align_center</i>
    </div>

    <div class="grid grid-nogutter invoice-header-wrapper">
      <div *ngIf="!isMobile" class="header-desc" [ngClass]="inDocLineFormatChangeRange() ? 'col-1' : 'col-2'">
        <span *ngIf="!inDocLineFormatChangeRange()">PRODUCT</span>
      </div>
      <div *ngIf="insideOverlay" class="col-1"></div>
      <div
        class="grid lg:col-4 quantity-tab"
        [ngClass]="[
          isNotDesktopDisplay() ? 'col-5 mobile-quantity-tab' : 'col-5',
          insideOverlay ? 'overlay-quantity-tab' : ''
        ]"
      >
        <div class="col-4 qty-chg-btn header-qty-dec" [ngClass]="{ disabled: !canDecreaseQty() }" (click)="decQty()">
          <i class="material-icons">remove_circle</i>
        </div>
        <a
          href="javascript://"
          (click)="onQtyColPressed()"
          class="col-4 header-qty"
          [ngClass]="{ 'disabled-link': !isSelectedIndexValid }"
        >
          <span class="qty-chg-text">QTY</span><br /><span class="keyboard-shortcut">(F2)</span>
        </a>
        <div class="col-4 qty-chg-btn header-qty-inc" [ngClass]="{ disabled: !canIncreaseQty() }" (click)="incQty()">
          <i class="material-icons">add_circle</i>
        </div>
      </div>
      <div
        class="grid header-prices grid-nogutter"
        [ngClass]="[inDocLineFormatChangeRange() ? 'lg:col-7' : 'lg:col-6', insideOverlay ? 'col-6' : 'col-7']"
      >
        <div class="header-price" [ngClass]="insideOverlay ? 'col-4' : 'col-7'">
          <span *ngIf="insideOverlay" [ngClass]="{ 'overlay-price-header-adjust': inDocLineFormatChangeRange() }"
            >PRICE</span
          >
          <span *ngIf="!insideOverlay" [ngClass]="{ 'non-desktop-price-header': isNotDesktopDisplay() }">PRICE</span>
        </div>
        <div class="header-total" [ngClass]="insideOverlay ? 'col-8' : 'col-5'">
          <span [ngClass]="isNotDesktopDisplay() ? 'mobile-total-price-header' : 'total-price-header'">TOTAL</span>
        </div>
      </div>
    </div>
    <div class="col grid-nogutter header-invoice-clear">
      <button
        icon="_"
        pButton
        pRipple
        [disabled]="saleDocLines.length === 0 || tenderTotal !== 0"
        class="p-button-primary-dark p-button-icon-only"
        (click)="onClearLines($event)"
        [ngClass]="{ 'clear-lines-btn': insideOverlay }"
      >
        <i class="material-icons">delete_forever</i>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #modalOverlayContents let-activeDialog>
  <div id="modalOverlay-wrapper">
    <span [ngSwitch]="activeDialog">
      <div *ngSwitchCase="FullSizeDialogName.COMMERCIAL_ACCOUNT" class="new-commercialAccount-panel">
        <taku-commercial-account
          (changedForm)="onNewCommercialAccountCreated($event)"
          [_isDialog]="true"
          [_object]="_activeFullDialogExtra || undefined"
          (dialogClosed)="closeFullSizeDialog()"
        ></taku-commercial-account>
      </div>

      <div *ngSwitchCase="FullSizeDialogName.PERSONAL_ACCOUNT" class="new-personalAccount-panel">
        <taku-personal-account
          (changedForm)="onNewPersonalAccountCreated($event)"
          [_isDialog]="true"
          [_object]="_activeFullDialogExtra || undefined"
          (dialogClosed)="closeFullSizeDialog()"
        ></taku-personal-account>
      </div>

      <div *ngSwitchCase="FullSizeDialogName.EDIT_INVENTORY" class="inventory-panel">
        <taku-inventory
          (changedForm)="onInventoryItemSaved($event)"
          [_isDialog]="true"
          [_object]="_activeFullDialogExtra"
          (dialogClosed)="closeFullSizeDialog()"
        ></taku-inventory>
      </div>

      <div *ngSwitchCase="FullSizeDialogName.NEW_INVENTORY" class="inventory-panel">
        <taku-inventory
          (changedForm)="onNewInventoryItem($event)"
          [_isDialog]="true"
          [_object]="_activeFullDialogExtra"
          (dialogClosed)="closeFullSizeDialog()"
        ></taku-inventory>
      </div>

      <div *ngSwitchCase="FullSizeDialogName.TENDER_REF_NO" class="tender-reference">
        <taku-sale-doc-tender-reference
          [formGroup]="_activeFullDialogExtra"
          (onDialogClosed)="closeFullSizeDialog()"
        ></taku-sale-doc-tender-reference>
      </div>

      <div *ngSwitchCase="FullSizeDialogName.DOC_DETAILS" class="doc-details">
        <ng-container
          *ngTemplateOutlet="
            headerDialogTemplate;
            context: {
              $implicit: _myForm,
              saleStation: _object.station,
              cashout: _cashout,
              saleAccount: _selectedAccount
            }
          "
        ></ng-container>
      </div>

      <div *ngSwitchCase="FullSizeDialogName.DELIVERY_METHOD" class="delivery-method">
        <taku-saledoc-delivery-method
          [saleForm]="_myForm"
          [account]="_selectedAccount"
          [salesDocSettings]="_invoiceSettings.salesDocSettings"
          (closed)="closeFullSizeDialog()"
          (deliveryMethodEvent)="onDeliveryMethod($event)"
        ></taku-saledoc-delivery-method>
      </div>

      <div *ngSwitchCase="FullSizeDialogName.REVISIT_SALES" class="revisit-sales">
        <taku-revisit-sale [fullSizeMode]="true" [componentState]="_activeFullDialogExtra"></taku-revisit-sale>
      </div>

      <div *ngSwitchCase="FullSizeDialogName.DOCLINE_DETAILS" class="docline-details">
        <ng-container *ngTemplateOutlet="transactionsHeader; context: { insideOverlay: true }"></ng-container>
        <ng-container
          *ngTemplateOutlet="
            saleDocLineComponent;
            context: {
              $implicit: _activeFullDialogExtra?.docLineForm,
              index: _activeFullDialogExtra?.index,
              isExpanded: true,
              alwaysNoSelected: true
            }
          "
        ></ng-container>
      </div>

      <div *ngSwitchCase="FullSizeDialogName.DOC_SUMMARY" class="doc-summary-panel">
        <ng-container *ngTemplateOutlet="docSummary"></ng-container>
      </div>

      <div *ngSwitchCase="FullSizeDialogName.CUSTOMER_CONSENT" class="account-consent">
        <taku-customer-consent
          [personFormAndAccountData]="_activeFullDialogExtra"
          [openedFromSaleScreen]="true"
          (closeDialogInSaleScreen)="closeFullSizeDialog()"
          (changedForm)="onNewPersonalAccountCreated($event)"
        >
        </taku-customer-consent>
      </div>
    </span>

    <ng-container
      *ngTemplateOutlet="
        extraModalTemplate;
        context: {
          $implicit: activeDialog,
          dialogExtra: _activeFullDialogExtra,
          saleForm: _myForm,
          saleStation: _object.station,
          documentUser: _object.doc.user,
          saleAccount: _selectedAccount,
          invoiceSettings: _invoiceSettings,
          saleTotal: saleDocTotal
        }
      "
    ></ng-container>
  </div>
</ng-template>

<ng-template
  #saleDocLineComponent
  let-docLineForm
  let-i="index"
  let-isExpanded="isExpanded"
  let-alwaysNoSelected="alwaysNoSelected"
>
  <taku-sale-doc-line
    #docLineComponent
    [formGroup]="docLineForm"
    [lineIndex]="i"
    [isSelected]="i == selectedLineIndex && !alwaysNoSelected"
    [activePriceLevel]="activePriceLevel"
    [storeId]="storeIdCtrl.value"
    [docSource]="customerSourceCtrl.value"
    [accountId]="_selectedAccount?.ID"
    [taxAccountCategoryId]="taxAccountCategoryIdCtrl.value"
    [saleDoc]="_myForm.value"
    (deleted)="deleteInvoiceLine(i)"
    (qtyChanged)="onLineQtyChanged($event, docLineForm)"
    [returnReasons]="returnReasons"
    [isExpanded]="isExpanded"
    [currencyIsoCode]="documentCurrency"
    (selected)="onLineClick(i)"
    (viewProductDetails)="viewProductDetailsClicked($event)"
    (lineExpansion)="onLineToggleExpansion($event, docLineComponent, i)"
  ></taku-sale-doc-line>
</ng-template>

<div [hidden]="true">
  <ng-template #printPreviewHost></ng-template>
</div>

<div class="col-12" *ngIf="debug"> </div>
