/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit } from "@angular/core";
import { MenuItem } from "primeng/api";
import { Router } from "@angular/router";
import { Event as NavigationEvent } from "@angular/router";
import { NavigationEnd } from "@angular/router";
import { Location } from "@angular/common";
import { FormHeaderDisplayMode } from "src/app/forms/form-header/form-header.component";
import { WebHelpers } from "src/app/utility/WebHelpers";
import { Subscription } from "rxjs";

@Component({
  selector: "taku-purchase-order-term-settings",
  templateUrl: "./purchase-order-term-settings.component.html",
  styleUrls: ["./purchase-order-term-settings.component.scss"],
})
export class PurchaseOrderTermSettingsComponent implements OnInit {
  menuPurchaseOrderTerms: MenuItem[];
  FormHeaderDisplayMode = FormHeaderDisplayMode;
  subsList: Subscription[] = [];

  _activeTab = 1;
  _activeZoneId: number;
  _activeStoreId;

  constructor(private location: Location, private router: Router) {
    this.subsList.push(
      this.router.events.subscribe((event: NavigationEvent): void => {
        if (event instanceof NavigationEnd) {
          this._activeTab = this.findActiveTab();
        }
      })
    );
  }

  findActiveTab(): number {
    if (this.router.url.includes("/paymentTerm")) {
      return 1;
    } else {
      return 0;
    }
  }

  ngOnInit() {
    this.menuPurchaseOrderTerms = [
      {
        id: "payment_terms",
        label: "Payment Terms",
        routerLink: ["list/n/paymentTerm"],
        queryParams: { title: "Payment Terms" },
      },
      {
        id: "shipping_terms",
        label: "Shipping Terms",
        routerLink: ["list/n/shippingTerm"],
        queryParams: { title: "Shipping Terms" },
      },
    ];
  }

  goBack() {
    this.location.back();
  }

  ngOnDestroy(): void {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }

  get _isMobile() {
    return WebHelpers.isMobileScreen();
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
