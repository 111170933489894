/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import * as _ from "lodash";
import { Dropdown } from "primeng/dropdown";
import { CurrencyFormatterService } from "../../shared/services/currency-formatter.service";
import { DropdownItemCustomFiltering } from "../../shared/ui/DropdownItemCustomFiltering";
import { FormDataHelpers } from "../../utility/FormDataHelpers";
import { TakuInputCtrlBase } from "../TakuInputCtrlBase";

@Component({
  selector: "taku-currency",
  templateUrl: "./taku-currency.component.html",
  styleUrls: ["./taku-currency.component.scss"],
})
export class TakuCurrencyComponent extends TakuInputCtrlBase {
  @Input() appendTo?: any;
  @Output("onChange") changeEvent: EventEmitter<any> = new EventEmitter();

  _currencyCodes: any[];
  _formattedCurrencyCodes: any[];
  @ViewChild(Dropdown, { static: true }) _dropdownComponent: Dropdown;

  constructor(public el: ElementRef, private currencyFormatService: CurrencyFormatterService) {
    super(el);
  }

  ngOnInit() {
    this._currencyCodes = this.currencyFormatService.getCurrencyCodeList();
    // Format the currency code list so that the top 6 most used are at the top
    this._formattedCurrencyCodes = this._currencyCodes.filter((value) => {
      return value.value === null;
    });
    this._formattedCurrencyCodes = this._formattedCurrencyCodes.concat(
      this._currencyCodes.filter((value) => {
        return value.value === "USD";
      })
    );
    this._formattedCurrencyCodes = this._formattedCurrencyCodes.concat(
      this._currencyCodes.filter((value) => {
        return value.value === "CAD";
      })
    );
    this._formattedCurrencyCodes = this._formattedCurrencyCodes.concat(
      this._currencyCodes.filter((value) => {
        return value.value === "GBP";
      })
    );
    this._formattedCurrencyCodes = this._formattedCurrencyCodes.concat(
      this._currencyCodes.filter((value) => {
        return value.value === "EUR";
      })
    );
    this._formattedCurrencyCodes = this._formattedCurrencyCodes.concat(
      this._currencyCodes.filter((value) => {
        return value.value === "AUD";
      })
    );
    this._formattedCurrencyCodes = this._formattedCurrencyCodes.concat(
      this._currencyCodes.filter((value) => {
        return value.value === "CNY";
      })
    );
    this._formattedCurrencyCodes = this._formattedCurrencyCodes.concat({ label: "-", inactive: true });
    this._formattedCurrencyCodes = this._formattedCurrencyCodes.concat(
      _.difference(this._currencyCodes, this._formattedCurrencyCodes)
    );

    this._formattedCurrencyCodes = this._formattedCurrencyCodes.map((selectItem) =>
      Object.assign(new DropdownItemCustomFiltering(this._dropdownComponent), selectItem)
    );

    this.emitValueChanged(this.formGroup.get(this.fieldName).value);
  }

  onValuedChanged(e) {
    this.emitValueChanged(e.value);
  }

  private emitValueChanged(isoCode) {
    const newCurrency = this.currencyFormatService.getCurrency(isoCode);
    this.changeEvent.emit(newCurrency);
  }

  tryFocusSearchFilter($event) {
    return FormDataHelpers.putCursorIntoDropFilter($event);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
