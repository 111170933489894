<div class="layout-topbar shadow-4">
  <div class="layout-topbar-left">
    <a
      class="layout-topbar-logo"
      [routerLink]="activeStore ? '/dashboard' : null"
      [style.backgroundImage]="branding.textLogoCss"
      [ngClass]="{ unactiveLink: !activeStore }"
    >
      <img id="app-logo" [src]="branding.mainLogo" />
    </a>

    <a class="layout-menu-button shadow-6" (click)="app.onMenuButtonClick($event)" pRipple>
      <i class="pi pi-chevron-right"></i>
    </a>

    <a class="layout-topbar-mobile-button" (click)="app.onTopbarMobileButtonClick($event)" pRipple>
      <i class="pi pi-ellipsis-v fs-large"></i>
    </a>
  </div>

  <div
    class="layout-topbar-right"
    [ngClass]="{ 'layout-topbar-mobile-active': app.mobileTopbarActive }"
    (click)="app.onMobilePanelClick($event)"
  >
    <div class="layout-topbar-actions-left">
      <!-- <p-megaMenu [model]="model" styleClass="layout-megamenu"></p-megaMenu> -->
    </div>

    <div class="layout-topbar-actions-right">
      <ul class="layout-topbar-items">
        <li *ngIf="activeStore" class="layout-topbar-item">
          <a *ngIf="activeStore?.id" href="javascript://" class="storeName">{{ activeStore.storeName }}</a>
          <a *ngIf="activeBackOffice?.id" href="javascript://" class="storeName"
            >Back Office ({{ activeBackOffice.backOfficeName }})</a
          >
          <a *ngIf="!activeStore?.id && !activeBackOffice?.id" href="javascript://" class="storeName">Admin Mode</a>
        </li>

        <!-- <li class="appcues-menuitem">
                <a href="javascript://" (click)="onAppCuesPressed($event)">
                    <span id="appcues-widget"></span>
                    <span class="topbar-item-name">Quick Guides</span>
                </a>
            </li> -->

        <li class="layout-topbar-item" *ngIf="!isOnSamsungKiosk">
          <a id="intercom_launcher_link" href="mailto:qjcbjndy@takulabs-ltd.intercom-mail.com">
            <span class="material-icons md-36" [ngClass]="{ 'ml-2': !app.isRTL, 'mr-2': app.isRTL }"> chat </span>
            <!-- <span class="topbar-item-name">Help</span> -->
            <span *ngIf="intercomUnreadMsgs$ | async as unreadMsgs" class="topbar-badge">{{ unreadMsgs }}</span>
          </a>
        </li>
        <!-- [ngClass]="{'active-top-menu':app.activeTopbarItem === profile || !app.isDesktop()} -->
        <li class="layout-topbar-item">
          <a
            class="layout-topbar-action flex p-dir-row justify-content-center align-items-center px-2 rounded-circle"
            (click)="app.onTopbarItemClick($event, 'profile')"
            pRipple
          >
            <img src="assets/layout/images/KWW.png" alt="avatar" style="width: 32px; height: 32px" />
            <div class="profile-info">
              <!-- <span class="topbar-item-name profile-name" *ngIf="userService.isUser">Profile</span> -->
              <span class="topbar-item-name profile-role" *ngIf="userService.role">{{
                userService.role | userRoleConversion
              }}</span>
            </div>
          </a>
          <ul
            class="layout-topbar-action-panel shadow-6"
            [@topbarActionPanelAnimation]="'visible'"
            *ngIf="app.activeTopbarItem === 'profile'"
          >
            <li class="layout-topbar-action-item" *ngIf="isSetUpVisible" [ngClass]="{ disabledBtn: !isSetUpEnabled }">
              <a class="flex flex-row align-items-center" pRipple [routerLink]="['/businessSetup']">
                <span class="material-icons" [ngClass]="{ 'mr-2': !app.isRTL, 'ml-2': app.isRTL }">
                  business_center
                </span>
                <span>Set-Up Assistant</span>
              </a>
            </li>
            <li *ngIf="numberOfServerErrors > 0" class="layout-topbar-action-item" pRipple (click)="showErrorLogs()">
              <a class="flex flex-row align-items-center">
                <span class="material-icons" [ngClass]="{ 'mr-2': !app.isRTL, 'ml-2': app.isRTL }">
                  error_outline
                </span>
                <span class="mr-2">Error log</span>
                <p-badge [value]="serverErrorLabel" severity="danger"></p-badge>
              </a>
            </li>
            <li class="layout-topbar-action-item" pRipple (click)="onLogout()">
              <a class="flex flex-row align-items-center">
                <span class="material-icons" [ngClass]="{ 'mr-2': !app.isRTL, 'ml-2': app.isRTL }"
                  >power_settings_new</span
                >
                <span>Logout</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</div>
