/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Input } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { NestedFormComponent } from "../../../forms/nested-form/nested-form.component";
import { AddressType } from "../address/address";
import { AddressComponent } from "../address/address.component";
import { PersonAddress } from "./person-address";

@Component({
  selector: "taku-person-address",
  templateUrl: "./person-address.component.html",
  styleUrls: ["./person-address.component.scss"],
})
export class PersonAddressComponent extends NestedFormComponent {
  @Input() disableForm?: boolean = false;

  static init(fb: UntypedFormBuilder, addressType: AddressType, isDefaultAddress = false): UntypedFormGroup {
    const newAddress = new PersonAddress(addressType);
    newAddress.address.isDefault = isDefaultAddress;
    return PersonAddressComponent.set(fb, newAddress);
  }

  static setArray(fb: UntypedFormBuilder, personAddresses: PersonAddress[]) {
    const formArray = fb.array([]);
    personAddresses.forEach((personAddress) => {
      formArray.push(PersonAddressComponent.set(fb, personAddress || new PersonAddress()));
    });
    return formArray;
  }

  static set(fb: UntypedFormBuilder, personAddress: PersonAddress) {
    const formGroup = fb.group(personAddress);
    formGroup.setControl("address", AddressComponent.set(fb, personAddress.address));
    return formGroup;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
