/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit } from "@angular/core";
import { ReturnSaleDocComponent } from "../return-sale-doc/return-sale-doc.component";
import { FullSizeDialogName } from "../../sale-document/generic-sale-doc.component";

@Component({
  selector: "taku-return-doc-mobile",
  templateUrl: "../return-sale-doc/return-sale-doc.component.html",
  styleUrls: ["../return-sale-doc/return-sale-doc.component.scss"],
})
export class ReturnDocMobileComponent extends ReturnSaleDocComponent {
  _isMobile = true;

  onLineToggleExpansion(event, saleLineComponent, index) {
    // If we are calling expansion from sidebar, just execute regular code
    if (this._activeFullDialog === FullSizeDialogName.DOCLINE_DETAILS)
      return super.onLineToggleExpansion(event, saleLineComponent, index);

    // if we are not in sidebar, just open it
    event.stopPropagation();
    const docLineForm = this.saleDocLines.at(index);
    // alert("Expansion toggle pressed");
    this.openFullSizeDialog(
      FullSizeDialogName.DOCLINE_DETAILS,
      {
        docLineForm,
        index,
      },
      {
        styleClass: "sidebarDocLine",
      }
    );
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
