<taku-settings-cards
  [pageTitle]="pageTitle"
  [pageIconStyleClass]="pageIconStyleClass"
  [cardsSections]="cardsInfo"
  [isAppsCard]="true"
>
  <div class="panel-toolbar">
    <label>Filter Apps:</label>
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
      <span class="p-float-label">
        <input type="text" pInputText [(ngModel)]="_filterText" placeholder="All Apps" />
      </span>
    </div>
  </div>
</taku-settings-cards>
