<taku-form-header [toolbarTitle]="_pageTitle" displayMode="inline" [showActionBtns]="false" (backClicked)="goBack()">
</taku-form-header>

<div class="card">
  <div class="grid">
    <div class="col">
      <h2>
        <strong> Pair Payment Terminals </strong>
      </h2>
      <hr />
      <p>
        Whether you're selling in-store or on-the-go, TAKU lets you easily accept in-person payments and track how much
        you have accepted per terminal. Remember to always check our list of supported payment terminals before
        finalizing your merchant services arrangement. For the latest list, visit
        <a class="text-link" href="https://help.takulabs.io/apps/payments/in-person-payments" target="_blank"
          >In-Person Payments</a
        >
      </p>
      <p>
        To pair your terminal(s) with TAKU, click
        <button pButton pRipple type="button" icon="_" style="cursor: initial" class="p-button-rounded">
          <i class="material-icons">add</i>
        </button>
        to add a new terminal, complete all of the required fields, and click Save.
      </p>
      <p>
        Further instructions for each column are available by clicking
        <i class="pi pi-info-circle"></i>
        in the column header.
      </p>
    </div>
  </div>
</div>

<taku-form-list
  *ngIf="_formListCols && _formListFilter"
  [_model]="_formListModel"
  _detailMode="w"
  [_filter]="_formListFilter"
  [_cols]="_formListCols"
  [_defaultRowValues]="_formListDefaultRowValues"
  [_hideAddLineHeaderBtn]="true"
  (_allRowsSaved)="onPaymentTerminalsSaved($event)"
  [_detailViewRoute]="terminalRout"
></taku-form-list>

<ng-template #terminalPairingTpl let-rowData>
  <button
    pButton
    [label]="rowData.isPaired ? 'Unpair' : 'Pair'"
    class="pairing-btn p-button-secondary"
    (click)="onColPairBtnClicked(rowData)"
  ></button>
  <!-- <button pButton [label]="rowData.isPaired ? 'Settle' : 'Settle'" class="pairing-btn p-button-secondary"
    (click)="onColSettleBtnClicked(rowData)"></button> -->
</ng-template>
