<span [hidden]="displayMode !== NestedFormDisplayMode.EDITABLE_FORM">
  <div class="col-12 top-wrapper">
    <div class="grid">
      <div class="col grid">
        <div class="col-6 md:col-6 lg:col-4 drop-container">
          <taku-dropdown
            [disabled]="disableForm"
            [formGroup]="_myForm"
            [options]="enum_frequency_name"
            fieldName="frequencyName"
            fieldCaption="Frequency Name"
          ></taku-dropdown>
        </div>
        <div class="col-6 md:col-6 lg:col-4 drop-container">
          <taku-dropdown
            [disabled]="disableForm"
            [formGroup]="_myForm"
            [options]="enum_barcode_unit_Of_Measure"
            fieldName="refillFrequencyUOfM"
            fieldCaption="Time Unit Of Measure"
          ></taku-dropdown>
        </div>
        <div class="col-12 md:col-12 lg:col-4">
          <taku-input
            [disabled]="disableForm"
            #barcodeNumber
            [formGroup]="_myForm"
            fieldName="refillFrequencyamount"
            fieldCaption="Refill Frequency"
          ></taku-input>
        </div>
      </div>
      <div class="col-fixed copy-icon-container">
        <taku-action-button [action]="ACTION_TYPE.COPY" (clickAction)="copyBarcodeToClipboard()"></taku-action-button>
      </div>
    </div>
  </div>
</span>

<ng-template [ngIf]="displayMode === NestedFormDisplayMode.TABULAR_DATA">
  <td class="barcode-format-col">
    <span class="p-column-title">Frequency Name:</span>
    {{ frequencyNameCtrl.value }}
  </td>

  <td class="barcode-type-col">
    <span class="p-column-title">Unit of Measure:</span>
    {{ unitOfMeasureCtrl.value }}
  </td>

  <td class="barcode-number-col">
    <span class="p-column-title">Refill Frequency #:</span>
    {{ refillFrequencyCtrl.value }}
  </td>
</ng-template>
