<div [formGroup]="_myForm" style="height: 100%">
  <div style="height: 100%">
    <p-panel style="width: 100%">
      <ng-template pTemplate="header">
        <h2>Account Details</h2>
        <taku-checkbox fieldCaption="ACTIVE" [formGroup]="_myForm" fieldName="isEnabled"></taku-checkbox>
        <!-- <taku-checkbox [formGroup]="_myForm" fieldName="isEnabled" fieldCaption="ACTIVE"> </taku-checkbox> -->
        <!-- <taku-toggle [_disabled]="!accountAccessRights.isStatusEnabled" class="form-enableSwitch" [formGroup]="_myForm"
          fieldName="isEnabled" *ngIf="accountAccessRights.isStatusVisible"></taku-toggle> -->
      </ng-template>

      <!-- <div class="col-12 md:col-5 drop-container">
        <taku-dropdown [formGroup]="_myForm" [options]="enumAccountStatus" fieldName="status" fieldCaption="Status"></taku-dropdown>
      </div> -->
      <div class="grid">
        <div class="col-12 drop-container">
          <taku-dropdown
            [formGroup]="_myForm"
            [options]="enumAccountRelationship"
            fieldName="accountRelationship"
            fieldCaption="Account Type"
          ></taku-dropdown>
        </div>

        <div class="col-12">
          <!-- <div class="p-inputgroup">
                <span class="p-inputgroup-addon">PRE</span> -->
          <taku-input [formGroup]="_myForm" fieldName="accountCode" fieldCaption="Account Code"> </taku-input>
          <!-- <span class="p-inputgroup-addon">SUF</span>
            </div> -->
        </div>

        <div class="col-12 drop-container">
          <taku-multiselect
            [formGroup]="_myForm"
            [options]="lookup_acctGroup$ | async"
            [fieldName]="'acctGroups'"
            [fieldCaption]="'Account Groups'"
          >
          </taku-multiselect>
        </div>

        <div class="col-12 md:col-5 lg:col-12 xl:col-5" *ngIf="accountAccessRights.isTaxIdVisible">
          <taku-input
            [disabled]="!accountAccessRights.isTaxIdEnabled"
            [formGroup]="_myForm"
            fieldName="taxID"
            fieldCaption="Tax ID"
          ></taku-input>
        </div>

        <div
          class="col-12 md:col-7 lg:col-12 xl:col-7 drop-container tax-category"
          *ngIf="accountAccessRights.isTaxCategoryVisible"
        >
          <taku-dropdown
            [disabled]="!accountAccessRights.isTaxCategoryEnabled"
            [formGroup]="_myForm"
            [options]="lookup_taxAccountCategory$ | async"
            [fieldName]="'taxAccountCategoryId'"
            [fieldCaption]="'Tax Category'"
          >
          </taku-dropdown>
        </div>

        <div class="col-12 drop-container">
          <taku-multiselect
            [formGroup]="_myForm"
            [options]="enumChannelType"
            fieldName="channelTypes"
            fieldCaption="Channel Type"
            [dataKey]="null"
          >
          </taku-multiselect>
        </div>

        <hr class="fields-divider" *ngIf="accountAccessRights.isTaxCategoryVisible" />

        <div class="col-12">
          <h5>Activity History</h5>
          <div class="mt-2">
            <button
              pButton
              pRipple
              type="button"
              label="History"
              icon="pi pi-window-maximize"
              (click)="openAccountHistoryDialog()"
              [loading]="historyDialogOpening"
            ></button>
          </div>
        </div>

        <div class="col-12 grid-nogutter credit-limit-container" *ngIf="accountAccessRights.isCreditLimitVisible">
          <taku-money
            [disabled]="!accountAccessRights.isCreditLimitEnabled"
            [formGroup]="_myForm"
            fieldName="creditLimit"
            fieldCaption="Credit Limit"
            currencyFieldName="defaultCurrencyIsoCode"
            currencyFieldCaption="Currency"
            appendTo="body"
          >
          </taku-money>
        </div>

        <!-- <hr class="fields-divider" />

      <div class="col-12">
        <div class="col-12">
          <h5>Tags</h5>
        </div>

        <div class="grid-nogutter col-12">
          <taku-chips [formGroup]="_myForm" fieldName="tags" fieldPlaceholder="Press Enter to Save Tags"></taku-chips>
        </div>
      </div> -->
      </div>
    </p-panel>
  </div>
</div>
