/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { AuthService } from "../../../shared/services/auth.service";
import { MessageService } from "primeng/api";
import { AlertMessagesService } from "../../../shared/services/alert-messages.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { environment } from "../../../../environments/environment";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";

@Component({
  selector: "taku-cancel-recurring-order",
  templateUrl: "./cancel-recurring-order.component.html",
  styleUrls: ["./cancel-recurring-order.component.scss"],
})
export class CancelRecurringOrderComponent {
  subsList: Subscription[] = [];
  formGroup: UntypedFormGroup;
  showConfirmation = false;

  constructor(
    private fb: UntypedFormBuilder,
    protected _router: Router,
    private http: HttpClient,
    private messageService: MessageService,
    protected alertMessage: AlertMessagesService,
    private _route: ActivatedRoute
  ) {}

  cancelRecurringOrder() {
    this.subsList.push(
      this._route.queryParams.subscribe((qparams) => {
        const { merchantAccount, token } = qparams;

        this.subsList.push(
          this.cancelCommitmentApi(merchantAccount, token).subscribe(
            () => {
              this.showConfirmation = true;
            },
            (errorResponse: HttpErrorResponse) => {
              try {
                this.messageService.add(
                  this.alertMessage.getErrorMessage(errorResponse, "", errorResponse.error.message)
                );
              } catch (e) {
                this.messageService.add(
                  this.alertMessage.getErrorMessage(errorResponse, "", "An unknown error has occurred.")
                );
              }
            }
          )
        );
      })
    );
  }

  private cancelCommitmentApi(merchantAccount, token) {
    return this.http.post(
      environment.apiUrl + "cancel-commitment/" + `${merchantAccount}/${token}`,
      {},
      {
        responseType: "json",
      }
    );
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
