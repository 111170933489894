/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Address, AddressType, AddressLocationType } from "../../../contact-accounts/address/address";

export class Stock {
  id: number;
  stockName: string;
  shortName: string;
  stockID: string;
  stockType: Stock_Type;
  isActive: boolean;
  address: Address;
  zoneId: number;

  constructor() {
    this.id = 0;
    this.stockName = null;
    this.shortName = null;
    this.stockID = null;
    this.stockType = Stock_Type.Store;
    this.isActive = true;
    this.address = new Address(AddressLocationType.commercial, AddressType.shippingAddress);
    this.zoneId = 0;
  }
}

export enum Stock_Type {
  Store = "Store",
  Warehouse = "Warehouse",
  In_Transit = "In Transit",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
