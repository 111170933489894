/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { MessageService } from "primeng/api";
import { RowSelectionType } from "../../../../form-list/form-list/form-list";
import { FormListComponent } from "../../../../form-list/form-list/form-list.component";
import { SalesReportCols, SalesReportColsFactoryService } from "./sales-report-cols.service";
import { ReportFilterFields } from "../../report-filter-fields";
import { ReportParamsService } from "../../report-params.service";
import { Subscription } from "rxjs";
import { SaleReportLocalDataService } from "./sale-report-local-data.service";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";
import { SaleDocReport } from "./sale-doc-report";
import { resetReportRowState } from "../../report";
import { LocalDataService } from "src/app/shared/services/local-data.service";

@Component({
  selector: "taku-sales-report",
  templateUrl: "./sales-report.component.html",
  styleUrls: ["./sales-report.component.scss"],
  providers: [SaleReportLocalDataService],
})
export class SalesReportComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];
  @ViewChild("formListReport") formListComponent: FormListComponent;

  _model = "saleDocReport";
  _stateKey = "FL-" + this._model;
  RowSelectionType = RowSelectionType;
  public reportCols: SalesReportCols;
  dynamicFields: string[] = [];

  constructor(
    private reportColsFactoryService: SalesReportColsFactoryService,
    public saleReportLocalService: SaleReportLocalDataService,
    private messageService: MessageService,
    private alertMessagesService: AlertMessagesService,
    public reportParamsService: ReportParamsService,
    private localDataService: LocalDataService
  ) {
    this.reportCols = this.reportColsFactoryService.build([]);
  }

  ngOnInit() {
    this.subsList.push(
      this.reportParamsService.reportParamsChanged$.subscribe((filters: ReportFilterFields) => {
        this.updateReport(filters);

        resetReportRowState(this._stateKey);
      })
    );
  }

  private updateReport(filters: ReportFilterFields) {
    this.reportParamsService._isFetchingData = true;
    this.subsList.push(
      this.saleReportLocalService
        .getSalesDailyReport(filters.storeId, filters.stationId, filters.dateFrom, filters.dateTo, 0, 0)
        .subscribe({
          next: (rows) => {
            this.buildFormlistCols(rows && rows.length ? rows[0] : null);
            this.refreshFormList(rows || []);
            this.reportParamsService._isFetchingData = false;
            this.localDataService.updateNewObject(rows);
          },
          error: (error) => {
            this.buildFormlistCols(null);
            // Show toast with error message
            this.messageService.add(this.alertMessagesService.getErrorMessage(error));
            // Empty form list
            this.refreshFormList([]);
            this.reportParamsService._isFetchingData = false;
          },
        })
    );
  }

  private buildFormlistCols(rowData: SaleDocReport) {
    this.dynamicFields = rowData
      ? Object.keys(rowData).filter((key) => !Object.keys(new SaleDocReport()).includes(key))
      : [];
    this.reportCols = this.reportColsFactoryService.build(this.dynamicFields);
  }

  private refreshFormList(data: any[]) {
    data.forEach((saleDay) => {
      const saleDocReport = Object.assign(new SaleDocReport(), saleDay);
      // This makes store getter result into plain property
      saleDay.totalAmountCalculated = saleDocReport.totalAmount;

      this.dynamicFields.forEach((key) => {
        saleDay[key] = parseFloat(saleDay[key]) || 0;
      });
    });
    this.saleReportLocalService.initialize(data, this._model);
    setTimeout(() => {
      this.formListComponent.reloadData();
    }, 0);
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
