<div
  [formGroup]="_myForm"
  class="acc-item ui-docline"
  [ngClass]="{
    'acc-selected': isSelected,
    'acc-expanded': isExpanded,
    'acc-evenline': lineIndex % 2 === 0,
    'acc-oddline': lineIndex % 2 === 1
  }"
  (click)="onLineClick($event)"
>
  <div id="sale_doc_line_arrow_button" class="acc-bar" (click)="lineExpansion.emit($event)">
    <div class="line-number-container">
      <span
        class="toggle-expansion-icon"
        [ngClass]="{ 'pi pi-angle-down': !isExpanded, 'takuicon-collapse-row': isExpanded }"
      ></span>
    </div>
  </div>

  <div class="acc-wrapper">
    <div id="hey" class="acc-header item-lines-container">
      <div class="row1">
        <div class="col-7 item-desc" [ngClass]="{ 'col-7': hasDiscount, 'col-12': !hasDiscount }">
          <span class="desc-text">{{ _myForm.get("description").value }}</span>
          <span *ngIf="!hasDiscount" class="tax-acronym">{{ lineTaxesAcronyms }}</span>
        </div>
        <div class="col-5 total-before-disc" *ngIf="hasDiscount">
          <span class="tax-acronym mr-2">{{ lineTaxesAcronyms }}</span>
          <del>{{ unitPriceCtrl.value * qtyCtrl.value | currency : currencyIsoCode : "symbol-narrow" : "1.2" }}</del>
        </div>
      </div>

      <div class="row2" [ngClass]="{ 'row2-margin-bottom': inDocLineFormatChangeRange() }">
        <!-- <div class="col-2 item-attr">{{ _myForm.get('categories').value }}</div> -->
        <!-- <div class="col-3 grid-nogutter" [ngClass]="inDocLineFormatChangeRange() ? 'col-offset-1' : 'col-offset-2'"> -->
        <div class="col-1">
          <i *ngIf="_myForm.get('docLine.inventory').value.parentInventoryId" class="material-icons"
            >photo_size_select_small</i
          >
          <i *ngIf="_myForm.get('docLine.inventory').value.isVariantParent" class="material-icons">bookmarks</i>
          <i
            *ngIf="
              !_myForm.get('docLine.inventory').value.parentInventoryId &&
              !_myForm.get('docLine.inventory').value.isVariantParent
            "
            class="material-icons"
            >bookmark_border</i
          >
          <div class="p-1"> {{ inventoryVariantsStr }}</div>
        </div>
        <div
          class="col-3 grid-nogutter"
          [ngClass]="{
            'col-offset-1':
              (isNotDesktopDisplay() && isExpanded) || (!isNotDesktopDisplay() && !inDocLineFormatChangeRange())
          }"
        >
          <div class="grid">
            <div class="col-12">
              <div class="grid">
                <a
                  class="col-4 qty-chg-btn qty-dec-btn"
                  (click)="decQty($event)"
                  [ngClass]="{ disabledBtn: !isQtyValid(calculateQtyDecrement) || _myForm.controls.voucher?.value }"
                >
                  <i class="material-icons">remove_circle</i>
                </a>
                <div class="col-4 item-qty">
                  <strong>
                    <label *ngIf="!qtyCtrl.errors || qtyCtrl.value < 0">{{
                      qtyCtrl.value | formatNegativeNumber
                    }}</label>
                  </strong>
                </div>
                <a
                  class="col-4 qty-chg-btn qty-inc-btn"
                  (click)="incQty($event)"
                  [ngClass]="{ disabledBtn: !isQtyValid(calculateQtyIncrement) || _myForm.controls.voucher?.value }"
                >
                  <i class="material-icons">add_circle</i>
                </a>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class='item-price col-7' [ngClass]="inDocLineFormatChangeRange() ? 'col-8' : 'col-7'"> -->
        <div
          class="item-price col-7"
          [ngClass]="{
            'col-8': inDocLineFormatChangeRange(),
            'col-7': !inDocLineFormatChangeRange(),
            'col-offset-1': !inDocLineFormatChangeRange() && isNotDesktopDisplay() && !isExpanded
          }"
        >
          <div class="grid">
            <div
              class="item-price"
              (click)="onPriceClick()"
              [ngClass]="
                inDocLineFormatChangeRange() &&
                isNotDesktopDisplay() &&
                _myForm.get('docLine').value?.inventory?.isSalePriceEditable
                  ? 'col-4'
                  : 'col-6'
              "
            >
              <span>{{ unitPriceCtrl.value | currency : currencyIsoCode : "symbol-narrow" : "1.2" }}</span>
            </div>
            <div
              *ngIf="_myForm.get('docLine').value?.inventory?.isSalePriceEditable"
              [ngClass]="inDocLineFormatChangeRange() && isNotDesktopDisplay() ? 'col-2' : 'col-1'"
            >
              <a href="javascript://" class="pi pi-pencil" (click)="unitPriceEdit.toggle($event)">
                <p-overlayPanel
                  #unitPriceEdit
                  [showCloseIcon]="false"
                  appendTo="body"
                  (onHide)="setZeroIfEmpty('unitPrice', _myForm.get('docLine.inventory').value['standardPrice'])"
                  (onShow)="focusPriceEdit()"
                >
                  <taku-input
                    #unitPriceInput
                    [formGroup]="_myForm"
                    fieldName="unitPrice"
                    [fieldCaption]="'Unit Price'"
                    style="width: auto"
                    [autoSelect]="true"
                    keyFilter="money"
                  ></taku-input>
                  <p-button icon="takuicon-calculator" styleClass="squared-corners" (onClick)="numpadUnitPrice.open()">
                  </p-button>
                  <taku-numpad
                    #numpadUnitPrice
                    padTitle="Enter Unit Price"
                    [numpadType]="'monetary'"
                    [initialQty]="unitPriceCtrl.value"
                    (submitQty)="onUnitPriceChanged($event)"
                  ></taku-numpad>
                </p-overlayPanel>
              </a>
            </div>
            <div
              *ngIf="!_myForm.get('docLine').value?.inventory?.isSalePriceEditable"
              [ngClass]="{ 'col-1': !inDocLineFormatChangeRange() || !isNotDesktopDisplay() }"
            >
            </div>
            <div
              class="price-total"
              [ngClass]="inDocLineFormatChangeRange() && isNotDesktopDisplay() ? 'col-6' : 'col-5'"
            >
              <span class="total-amount">{{
                salePriceCtrl.value * qtyCtrl.value
                  | currency : currencyIsoCode : "symbol-narrow" : "1.2"
                  | formatNegativeNumber
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="acc-content item-lines-container">
      <div class="grid row3-and-row4">
        <div class="grid my-row-3" [ngClass]="inDocLineFormatChangeRange() ? 'col-12 row3-width-adjust' : 'col-5'">
          <div [ngClass]="isInDoubleLineRange() ? 'col-12 sku-barcode-double-line' : 'col-6 sku-barcode'">
            <div class="line-sku-barcode">
              SKU:
              <a *ngIf="isInventoryDetailsVisible" (click)="showItemDetailsDlg($event)" class="sku-underline">
                <strong>
                  <label #sku style="cursor: pointer">{{ _myForm.get("sku").value }} </label>
                  <!-- <i class="material-icons" style="font-size: 12px;">remove_red_eye</i> -->
                </strong>
              </a>
              <strong *ngIf="!isInventoryDetailsVisible">
                <label #sku>{{ _myForm.get("sku").value }} </label>
              </strong>
            </div>
            <div *ngIf="_myForm.get('docLine').get('inventory').value.inventoryBarcodes[0]" class="line-sku-barcode">
              Barcode:
              <label>{{ _myForm.get("docLine").get("inventory").value.inventoryBarcodes[0].barcode }}</label>
            </div>
            <div *ngIf="_myForm.controls.voucher?.value" class="line-sku-barcode">
              Code:
              <label>{{ _myForm.controls.voucher.value.uniqueCode }}</label>
            </div>
          </div>
          <div
            [ngClass]="{
              'col-12 chg-qty-double-line': isInDoubleLineRange(),
              'col-6 chg-qty': !isInDoubleLineRange(),
              'chg-qty-docline-format-change': inDocLineFormatChangeRange()
            }"
          >
            <!-- <div [ngClass]="isInDoubleLineRange() ? 'col-12 chg-qty-double-line' : 'col-6 chg-qty'"> -->
            <div class="p-inputgroup change-qty" *ngIf="!_myForm.controls.voucher?.value">
              <taku-input
                #qtyInput
                [formGroup]="_myForm"
                [unmask]="false"
                data-docline-field
                fieldName="qty"
                [fieldCaption]="qtyCtrl.errors ? '' : 'Quantity'"
                (click)="onQtyClicked($event)"
                keyFilter="pnum"
                [inputMask]="isPositiveQty ? null : '-?aaaaaaaaaaa'"
                maskCharacterPattern="[0-9.]"
                [slotChar]="''"
                [autoSelect]="true"
              >
              </taku-input>
              <!-- <taku-input #qtyInput [formGroup]="_myForm" [unmask]="false" data-docline-field fieldName="qty"
                [fieldCaption]="qtyCtrl.errors ? '' : 'Quantity'" (click)="onQtyClicked($event)"
                keyFilter="pnum" [slotChar]="''" [autoSelect]="true">
              </taku-input> -->
              <span class="p-inputgroup-addon pt-2">
                <p-button icon="takuicon-calculator" styleClass="squared-corners" (onClick)="numpadQty.open()">
                </p-button>
                <taku-numpad
                  #numpadQty
                  padTitle="Enter Quantity"
                  numpadType="numeric"
                  [initialQty]="(isPositiveQty ? 1 : -1) * qtyCtrl.value"
                  (onCancel)="onNumpadCancelled()"
                  (submitQty)="onNumpadItemQty($event)"
                  [maximumQty]="calcMaximumQty"
                ></taku-numpad>
              </span>
            </div>
          </div>
        </div>

        <div
          [ngClass]="inDocLineFormatChangeRange() ? 'col-12 row4-margin-top-adjust' : 'col-7 row4-margin-left-adjust'"
        >
          <p-fieldset
            class="discounts-fieldset"
            legend="Unit Discount"
            [ngClass]="{ 'fieldset-height-adjust': discountTypeCtrl.value == DiscountType.Percentage }"
          >
            <div class="grid">
              <div
                [ngClass]="
                  isInDoubleLineRange() ? 'p-fluid col-12 chg-discount-type' : 'p-fluid col-6 chg-discount-type'
                "
              >
                <p-selectButton
                  [disabled]="isPositiveQty ? false : true"
                  [options]="enumDiscountType"
                  formControlName="discountType"
                ></p-selectButton>
              </div>
              <div [ngClass]="isInDoubleLineRange() ? 'col-12 chg-discount' : 'col-6 chg-discount'">
                <div class="p-inputgroup chg-amount">
                  <ng-template [ngIf]="discountTypeCtrl.value == DiscountType.Percentage">
                    <!-- <span class='p-inputgroup-addon' [ngClass]="{'chg-border': isPositiveQty}">%</span> -->
                    <span class="p-inputgroup-addon chg-border" [ngClass]="{ 'return-border': !isPositiveQty }">%</span>
                    <taku-input
                      class="extra-margin-correction"
                      [ngClass]="{
                        'takuinput-margin-error-correction': !isInDoubleLineRange() || inDocLineFormatChangeRange()
                      }"
                      #lineDiscountAmount
                      [disabled]="isPositiveQty ? false : true"
                      keyFilter="pnum"
                      [formGroup]="_myForm"
                      (onBlur)="setZeroIfEmpty('discountAmount')"
                      fieldName="discountAmount"
                      [fieldCaption]="'Amount'"
                      [showValidationErrors]="false"
                      (click)="onDiscAmountClicked()"
                      (input)="userInputAmount($event)"
                    ></taku-input>
                  </ng-template>

                  <ng-template [ngIf]="[DiscountType.Fixed].includes(discountTypeCtrl.value)">
                    <taku-money
                      #lineDiscountAmount
                      [disabled]="isPositiveQty ? false : true"
                      [formGroup]="_myForm"
                      fieldName="discountAmount"
                      [fieldCaption]="'Amount'"
                      (click)="onDiscAmountClicked()"
                      [currencyCode]="currencyIsoCode"
                      [currencyIsReadonly]="true"
                      (onBlur)="setZeroIfEmpty('discountAmount')"
                      [showValidationErrors]="false"
                    ></taku-money>
                  </ng-template>

                  <ng-template [ngIf]="[DiscountType.Final_Price].includes(discountTypeCtrl.value)">
                    <taku-money
                      #lineDiscountAmount
                      [formGroup]="_myForm"
                      fieldName="discountAmount"
                      [fieldCaption]="'Amount'"
                      [disabled]="!isPositiveQty || _myForm.value.docLine.inventory.allowDiscount"
                      [showValidationErrors]="false"
                      (click)="onDiscAmountClicked()"
                      [currencyCode]="currencyIsoCode"
                      [currencyIsReadonly]="true"
                      (onBlur)="setZeroIfEmpty('discountAmount', _myForm.get('unitPrice').value)"
                    ></taku-money>
                  </ng-template>

                  <span
                    class="p-inputgroup-addon pt-1"
                    [ngClass]="{ 'taku-calc-margin-adjust': discountTypeCtrl.value == DiscountType.Percentage }"
                  >
                    <p-button
                      [disabled]="isPositiveQty ? false : true"
                      icon="takuicon-calculator"
                      styleClass="squared-corners"
                      (onClick)="numpadDiscount.open()"
                    >
                    </p-button>
                    <taku-numpad
                      #numpadDiscount
                      padTitle="Enter Discount"
                      [numpadType]="discountTypeCtrl.value === 'Percentage' ? 'percentage' : 'monetary'"
                      [initialQty]="discountAmountCtrl.value"
                      [maximumQty]="maxDiscountAmount"
                      (submitQty)="onNumpadDiscountAmount($event)"
                    ></taku-numpad>
                  </span>
                </div>
              </div>
            </div>
          </p-fieldset>
        </div>
      </div>

      <div
        class="row5 grid"
        formGroupName="docLine"
        [ngClass]="{
          'row5-margin-top-adjust': inDocLineFormatChangeRange(),
          'row5-mt-adjust-on-percentage': discountTypeCtrl.value === DiscountType.Percentage
        }"
      >
        <div
          *ngIf="isReturnReasonActive"
          class="col-12 sm:col-3 lg:col-12 xl:col-4 drop-container return-reason-setting"
        >
          <taku-dropdown
            [formGroup]="_myForm.get('saleDocLineReturn')"
            [options]="_returnReasonsEnumOptions"
            fieldName="storeSettingReturnReasonId"
            appendTo="body"
            fieldCaption="Return Reason"
          ></taku-dropdown>
        </div>
        <ng-container
          *ngIf="takuPaymentAccountExist && !isReturnReasonActive && recurringOrderSettingOptions.length > 1"
        >
          <div class="p-fluid line-note col-12 md:col-5">
            <taku-dropdown
              [formGroup]="_myForm"
              [options]="recurringOrderSettingOptions"
              [disabled]="!saleDoc.accountId || hasReturnItem()"
              fieldName="recurringOrderSettingId"
              appendTo="body"
              fieldCaption="Recurring?"
              pTooltip=""
            ></taku-dropdown>
          </div>
          <ng-container *ngIf="!saleDoc.accountId || hasReturnItem()">
            <a class="pi pi-info-circle col-7" (click)="recurring.toggle($event)">
              <p-overlayPanel #recurring [showCloseIcon]="false" appendTo="body">
                <div class="tooltip-styling">
                  <h5>NOTE:</h5>
                  <ul>
                    <li>Add an Account to add Recurring options</li>
                    <li
                      >Returns cannot be processed together with Recurring items. Remove any Returns to enable these
                      Recurring fields</li
                    >
                  </ul>
                </div>
              </p-overlayPanel>
            </a>
          </ng-container>
          <div
            class="p-fluid col-12 md:col-4"
            *ngIf="saleDoc.accountId && !hasReturnItem() && enum_frequency_name.length > 1"
          >
            <taku-dropdown
              [formGroup]="_myForm"
              [options]="enum_frequency_name"
              [disabled]="!(refillFrequency | lowercase).includes('default')"
              fieldName="frequencyName"
              appendTo="body"
              fieldCaption="Frequency Name"
              pTooltip=""
            ></taku-dropdown>
          </div>
          <div class="p-fluid col-6 md:col-3 refil-frequency" *ngIf="saleDoc.accountId && !hasReturnItem()">
            <b>Refill Frequency: </b>{{ refillFrequency }}
          </div>
          <div class="p-fluid col-12 md:col-5 sm:col-12">
            <taku-dropdown
              [formGroup]="_myForm"
              [options]="enum_repeat"
              [disabled]="!saleDoc.accountId || hasReturnItem() || !this._myForm.get('recurringOrderSettingId').value"
              fieldName="repeatCycle"
              appendTo="body"
              fieldCaption="Repeat?"
            ></taku-dropdown>
          </div>
          <div class="p-fluid col-6 md:col-4 first-last-order-date" *ngIf="saleDoc.accountId && !hasReturnItem()">
            <div class="first-order-date"
              ><b>Next Outgoing Date: </b>
              <p>{{ nextOutgoingDate | dateFormat }}</p>
            </div>
            <div
              ><b>Last Repeat Date: </b>
              <p>{{ lastRepeatDate | dateFormat }}</p>
            </div>
          </div>
          <div class="p-fluid col-6 md:col-3 order-sub-total" *ngIf="saleDoc.accountId && !hasReturnItem()">
            <b>Order Sub-Total: </b>{{ recurringSubTotal | currency : currencyIsoCode : "symbol-narrow" : "1.2" }}
            <span *ngIf="recurringDiscount">({{ recurringDiscount }}% off)</span>
          </div>
        </ng-container>
        <div class="p-fluid col-12" [ngClass]="{ 'sm:col-9 lg:col-12 xl:col-8': isReturnReasonActive }">
          <taku-input [formGroup]="_myForm.get('docLine')" fieldName="note" fieldCaption="Line Note"></taku-input>
        </div>
      </div>
    </div>
  </div>
  <button pButton icon="pi pi-times" class="p-button-text p-button-icon-only line-delete" (click)="onDelete()"></button>
</div>
