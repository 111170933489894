/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { SelectItem } from "primeng/api";
import { Dropdown } from "primeng/dropdown";

export class DropdownItemCustomFiltering implements SelectItem {
  label?: string;
  value: any;
  styleClass?: string;
  icon?: string;
  title?: string;
  disabled?: boolean;

  constructor(public dropdownComponent: Dropdown, public filterField = "label") {}

  get startsWithFilter(): string {
    const searchLength = this.dropdownComponent.filterValue.length;
    // Only search for the first n characters that has been typed in search component
    return this[this.filterField].substring(0, searchLength);
  }

  get containsFilter(): string {
    return this["title"];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
