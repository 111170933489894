<div id="dashboard" class="col-12 dashboard">
  <div class="grid inner-wrapper">
    <div class="col-12">
      <h1
        >Welcome back, <strong>{{ _loggedinUser.person.fullName }}</strong></h1
      >
    </div>

    <div class="col-12">
      <div class="grid">
        <div id="cashout-card" class="col-12 lg:col-6" *ngIf="!isAdmin && isCashoutVisible">
          <div class="col-12 user-card">
            <div class="grid">
              <div class="col-5 sm:col-2">
                <h3>Cashout</h3>
              </div>
              <div class="col-7 sm:col-3 display-field">
                <label>{{ isCashoutOpened ? "Opened by:" : "Closed by:" }}</label>
                <div class="value">{{ _cashoutUser?.person?.fullName || _cashoutUser?.userName || 0 }}</div>
              </div>
              <div class="col-5 sm:col-3 display-field">
                <label>Date:</label>
                <div class="value">{{ (_cashoutDate | date : "EE, MMMM dd") || 0 }}</div>
              </div>
              <div class="col-3 sm:col-2 display-field">
                <label>Time:</label>
                <div class="value">{{ _cashoutTime || 0 }}</div>
              </div>
              <div
                class="col-4 sm:col-2 cashoutStatus-wrapper"
                [attr.data-cashout-status]="isCashoutOpened ? 'open' : 'closed'"
              >
                <img src="/assets/components/cashout/images/till.png" alt="Till" class="till-picture" />
                <div class="status-label">
                  {{ isCashoutOpened ? "OPEN" : "CLOSED" }}
                  <i
                    class="status-icon"
                    [ngClass]="{ 'ui-icon-check': isCashoutOpened, 'ui-icon-block': !isCashoutOpened }"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 dateSelector" [ngClass]="{ 'lg:col-6 ': !isAdmin, flexAdmin: isAdmin }">
          <div class="grid col-12">
            <div
              *ngIf="isDateRangeVisible"
              class="grid label-previousRange"
              [ngClass]="{
                'xl:col-4 lg:col-5 md:col-4 col-5': !isAdmin,
                'col-12 xl:col-6 lg:col-6 md:col-12 sm:col-12 centerRange   ': isAdmin
              }"
            >
              <div
                class="capitalized-label"
                [innerHTML]="'Compared to ' + previousDateRange"
                [ngClass]="{ 'xl:col-6 lg:col-6 md:col-4': isAdmin }"
              >
              </div>
              <div class="calendarRange-wrapper col-12 xl:col-6 lg:col-6 md:col-6 sm:col-12" *ngIf="isAdmin">
                <taku-taku-calendar-range
                  [formGroup]="dashboardForm"
                  fieldCaption="Date Range"
                  fieldNameRangeType="dateRangeType"
                  fieldNameDateFrom="dateFrom"
                  fieldNameDateTo="dateTo"
                  [overlayPosition]="CalendarOverlayPosition.LEFT_POS"
                  [disabled]="!isDateRangeEnabled"
                >
                </taku-taku-calendar-range>
              </div>
            </div>

            <div
              class="grid"
              [ngClass]="{
                'xl:col-8 lg:col-7 md:col-8 col-7': !isAdmin,
                'col-12 xl:col-6 lg:col-6 md:col-12 sm:col-12 centerRange': isAdmin
              }"
            >
              <div
                class="calendarRange-wrapper col-12 sm:col-12"
                *ngIf="!isAdmin && isDateRangeVisible"
                [ngClass]="{ 'xl:col-6 lg:col-12 md:col-6': !isAdmin, 'xl:col-4 lg:col-4 md:col-12': isAdmin }"
              >
                <taku-taku-calendar-range
                  [formGroup]="dashboardForm"
                  fieldCaption="Date Range"
                  fieldNameRangeType="dateRangeType"
                  fieldNameDateFrom="dateFrom"
                  fieldNameDateTo="dateTo"
                  [overlayPosition]="CalendarOverlayPosition.LEFT_POS"
                  [disabled]="!isDateRangeEnabled"
                >
                </taku-taku-calendar-range>
              </div>

              <!-- If the store id is set to 0, we know we are in admin mode. We only want to display this drop down in admin mode. -->
              <div *ngIf="isAdmin" class="col-12 xl:col-6 lg:col-6 md:col-10 sm:col-12 dropdownFilter-wrapper">
                <taku-dropdown
                  [formGroup]="dashboardForm"
                  [options]="lookup_stores$ | async"
                  fieldCaption="Store"
                  fieldName="storeId"
                ></taku-dropdown>
              </div>

              <div
                *ngIf="isStationDropDownVisible"
                class="dropdownFilter-wrapper col-12 xl:col-6 sm:col-12"
                [ngClass]="{ 'lg:col-12 md:col-6': !isAdmin, 'lg:col-6 md:col-10': isAdmin }"
              >
                <taku-dropdown
                  [formGroup]="dashboardForm"
                  [options]="lookup_stations$ | async"
                  fieldCaption="Station"
                  fieldName="stationId"
                  appendTo="body"
                  [disabled]="(isAdmin && !dashboardForm.controls.storeId.value) || !isStationDropDownEnabled"
                >
                </taku-dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="grid">
        <div class="col-12 lg:col-6 figures-card columns3-card">
          <div class="user-card" *ngIf="isSalesReturnsDiscountsVisible">
            <div class="grid col-12">
              <ng-container
                *ngTemplateOutlet="
                  headerMetric;
                  context: { $implicit: 'sales', caption: 'Gross Sales', isCurrency: true }
                "
              >
              </ng-container>
              <ng-container
                *ngTemplateOutlet="
                  headerMetric;
                  context: { $implicit: 'returns', caption: 'Returns', isCurrency: true }
                "
              >
              </ng-container>
              <ng-container
                *ngTemplateOutlet="
                  headerMetric;
                  context: { $implicit: 'netSales', caption: 'Net Sales', isCurrency: true }
                "
              >
              </ng-container>
            </div>
          </div>
        </div>

        <div class="col-12 lg:col-6 figures-card columns3-card">
          <div class="user-card" *ngIf="isTransactionsBasketValueSizeVisible">
            <div class="grid col-12">
              <ng-container
                *ngTemplateOutlet="
                  headerMetric;
                  context: { $implicit: 'transactions', caption: 'Transactions', isCurrency: false }
                "
              >
              </ng-container>
              <ng-container
                *ngTemplateOutlet="
                  headerMetric;
                  context: { $implicit: 'basketValue', caption: 'Basket Value', isCurrency: true }
                "
              >
              </ng-container>
              <ng-container
                *ngTemplateOutlet="
                  headerMetric;
                  context: { $implicit: 'basketSize', caption: 'Basket Size', isCurrency: false }
                "
              >
              </ng-container>
            </div>
          </div>
        </div>

        <div class="col-12 figures-card columns2-card">
          <div class="user-card" *ngIf="isSalesReturnsDiscountsVisible">
            <div class="grid col-12">
              <ng-container
                *ngTemplateOutlet="
                  headerMetric;
                  context: { $implicit: 'sales', caption: 'Gross Sales', isCurrency: true }
                "
              >
              </ng-container>
              <ng-container
                *ngTemplateOutlet="
                  headerMetric;
                  context: { $implicit: 'returns', caption: 'Returns', isCurrency: true }
                "
              >
              </ng-container>
            </div>
          </div>
        </div>

        <div class="col-12 figures-card columns2-card">
          <div class="user-card" *ngIf="isSalesReturnsDiscountsVisible || isTransactionsBasketValueSizeVisible">
            <div class="grid col-12">
              <ng-container
                *ngTemplateOutlet="
                  headerMetric;
                  context: { $implicit: 'netSales', caption: 'Net Sales', isCurrency: true }
                "
              >
              </ng-container>
              <ng-container
                *ngTemplateOutlet="
                  headerMetric;
                  context: { $implicit: 'transactions', caption: 'Transactions', isCurrency: false }
                "
              >
              </ng-container>
            </div>
          </div>
        </div>

        <div class="col-12 figures-card columns2-card">
          <div class="user-card" *ngIf="isTransactionsBasketValueSizeVisible">
            <div class="grid col-12">
              <ng-container
                *ngTemplateOutlet="
                  headerMetric;
                  context: { $implicit: 'basketValue', caption: 'Basket Value', isCurrency: true }
                "
              >
              </ng-container>
              <ng-container
                *ngTemplateOutlet="
                  headerMetric;
                  context: { $implicit: 'basketSize', caption: 'Basket Size', isCurrency: false }
                "
              >
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="grid">
        <div class="col-12 lg:col-6 chart-card" *ngIf="isSalesByHourVisible">
          <div class="col-12 user-card">
            <div class="grid col-12" style="height: 100%">
              <div class="col-12 card-title">
                <h3>Gross Sales by Hour</h3>
              </div>
              <div class="col-12">
                <p-chart type="bar" [data]="salesData" height="100%" [options]="_chartOptions"></p-chart>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 lg:col-6 table-card" *ngIf="isTopSellingInventoryVisible">
          <div class="user-card col-12">
            <!-- <div class="col-12"> -->
            <div class="col-12 card-title">
              <h3>Top-Selling Inventory</h3>
            </div>
            <div class="col-12">
              <p-table [value]="topInventory" class="topInventory-table" styleClass="p-datatable-striped">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="col-description">Product Title</th>
                    <th class="col-qty">Qty</th>
                    <th class="col-netSales">Gross Sales</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-inventory>
                  <tr>
                    <td class="col-description">{{ inventory.description1 }}</td>
                    <td class="col-qty">{{ inventory.totalQty }}</td>
                    <td class="col-netSales">
                      {{ inventory.netSale | currency : _currencyIsoCode : "symbol-narrow" : _digitsFormat }}</td
                    >
                  </tr>
                </ng-template>
              </p-table>
            </div>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-12"
      *ngIf="
        isCashoutVisible ||
        isSalesReturnsDiscountsVisible ||
        isTransactionsBasketValueSizeVisible ||
        isSalesByHourVisible ||
        isTopSellingInventoryVisible
      "
    >
      Note: Only zone Default Currency transactions are included.
    </div>
  </div>
</div>

<ng-template #headerMetric let-fieldName let-caption="caption" let-isCurrency="isCurrency">
  <div class="figureStats-wrapper grid col-4 lg:col-4">
    <div class="figures-text col-12" [ngClass]="fieldName">
      <h3>{{ caption }}</h3>
      <p *ngIf="isCurrency">{{
        (_summaryData?.totals[fieldName] | currency : _currencyIsoCode : "symbol-narrow" : _currencyFormat) || 0
      }}</p>
      <p *ngIf="!isCurrency">{{ (_summaryData?.totals[fieldName] | number : _decimalsFormat) || 0 }}</p>
    </div>
    <div
      *ngIf="_summaryData?.percentageDeltas[fieldName] as percentageAmount"
      class="figures-arrow col-12"
      [ngClass]="{ 'arrow-positive': percentageAmount >= 0, 'arrow-negative': percentageAmount < 0 }"
    >
      <i class="material-icons">{{ percentageAmount >= 0 ? "arrow_drop_up" : "arrow_drop_down" }}</i>
      <!-- [ngClass]="{'ui-icon-arrow-drop-up': percentageAmount >= 0, 'ui-icon-arrow-drop-down': percentageAmount < 0}"></i> -->
      <p>{{ (percentageAmount | number : _decimalsFormat) || "--" }}%</p>
    </div>

    <div *ngIf="!_summaryData?.percentageDeltas[fieldName]" class="figures-arrow arrow-neutral col-12">
      <i class="material-icons">remove</i>
      <p>--%</p>
    </div>
  </div>
</ng-template>
