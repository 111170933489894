<div class="card grid justify-content-center">
  <div class="sm:col-12 md:col-10 lg:col-6 lg:col-6">
    <h1
      ><strong>{{ branding.generic.companyName }} eCommerce</strong></h1
    >
    <p>Unify your in-store and online sales in one seamless platform.</p>
    <p>
      Turn on this integration to automatically sync inventory and sales data in real-time between
      {{ branding.generic.companyName }} Retail POS and {{ branding.generic.companyName }} eCommerce. Work less and sell
      more with:
    </p>
    <ul>
      <li>Omnichannel returns and exchanges</li>
      <li>A single view of sales, inventory and customers</li>
      <li>Unified reporting across your entire business</li>
      <li>Customizable staff roles and permissions</li>
    </ul>
    <p>
      <a [href]="branding.salesChannelComponent.aboutLink" target="_blank">Learn more</a>
      about {{ branding.generic.companyName }} eCommerce
    </p>
    <div class="aligncenter col-12">
      <button
        pButton
        type="submit"
        (click)="onGetStartedPressed()"
        class="p-button taku-button-large p-component"
        label="GET STARTED"
      ></button>
    </div>
    <p class="aligncenter">
      <img src="/assets/layout/images/WebSales.png" alt="Web sales" />
    </p>
  </div>
</div>
