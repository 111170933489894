import { Component } from "@angular/core";
import { AdyenCardFormState } from "../adyen-integration/adyen-integration.component";
import { AdyenIntegrationService } from "../adyen-integration/adyen-integration.service";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { PersonalAccount } from "../personal-account/personal-account";
import { CommercialAccount } from "../commercial-account/commercial-account";

@Component({
  selector: "taku-adyen-integration-dialog-wrapper",
  templateUrl: "./adyen-integration-dialog-wrapper.component.html",
  styleUrls: ["./adyen-integration-dialog-wrapper.component.scss"],
})
export class AdyenIntegrationDialogWrapperComponent {
  account: PersonalAccount | CommercialAccount;

  constructor(
    private adyenIntegrationService: AdyenIntegrationService,
    dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef
  ) {
    this.account = dialogConfig.data.account;
    adyenIntegrationService.dialogRef = dialogRef;
  }

  closeDialogPressed(): void {
    this.dialogRef.close();
  }

  submit($event: AdyenCardFormState): void {
    this.adyenIntegrationService.submitPreAuthorization(this.account.account, $event.fields);
  }
}
