/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, EventEmitter, Inject, Input, LOCALE_ID, Output } from "@angular/core";
import { DateTimeFormatPipe } from "src/app/shared/pipes/date-time-format.pipe";
import { SaleDoc } from "../sale-doc/sale-doc";

@Component({
  selector: "taku-parked-sales-preview",
  templateUrl: "./parked-sales-preview.component.html",
  styleUrls: ["./parked-sales-preview.component.scss"],
})
export class ParkedSalesPreviewComponent {
  dateTimePipe = new DateTimeFormatPipe(this.localeId);

  @Input() set _object(docData: SaleDoc) {
    this.saleDoc = SaleDoc.convertIntoTypedObject(docData);
  }
  @Output() dialogClosed: EventEmitter<any> = new EventEmitter();
  @Input() saleDoc: SaleDoc;
  @Input() fullSizeMode;
  @Output() onContinueSale: EventEmitter<SaleDoc> = new EventEmitter();

  constructor(@Inject(LOCALE_ID) private localeId: string) {}

  onClosePressed() {
    this.dialogClosed.emit(this.saleDoc);
  }

  onContinueSalePressed() {
    this.dialogClosed.emit();
    this.onContinueSale.emit(this.saleDoc);
  }

  get dialogTitle() {
    const docDateFormat = this.dateTimePipe.transform(this.saleDoc.doc.docDate, this.saleDoc.doc.docTime);
    return `Parked on ${docDateFormat}`;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
