import { TakuPayService } from "./../taku-pay.service";
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { DBMode, GenericFormComponent } from "src/app/forms/generic-form/generic-form.component";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";
import { DBService } from "src/app/shared/services/db.service";
import { Location } from "@angular/common";
import { TakuPayAccountDetail } from "./taku-pay-account-details";
import { AddressComponent } from "src/app/core/contact-accounts/address/address.component";
import { AddressLocationType } from "src/app/core/contact-accounts/address/address";
import { throwError } from "rxjs";
import { catchError, map, shareReplay, tap } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { TakuPaymentAccountStatus } from "../taku-pay";

@Component({
  selector: "taku-taku-pay-account-details",
  templateUrl: "./taku-pay-account-details.component.html",
  styleUrls: ["./taku-pay-account-details.component.scss"],
})
export class TakuPayAccountDetailsComponent extends GenericFormComponent implements OnInit {
  mccList: any[] = [];
  isSuperAdmin = false;
  _isShowActionBtn = true;
  _creatingAccountDetail = false;

  constructor(
    protected _router: Router,
    public fb: UntypedFormBuilder,
    public dbService: DBService,
    protected location: Location,
    public _route: ActivatedRoute,
    private takuPayService: TakuPayService,
    protected messageService: MessageService,
    protected alertMessage: AlertMessagesService,
    protected confirmationService: ConfirmationService,
    private appSettings: AppSettingsStorageService
  ) {
    super(_router, fb, dbService, location, _route, messageService, alertMessage, confirmationService, appSettings);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.takuPayService.getMccList().subscribe((mcc) => {
      this.mccList = mcc;
    });
    this.mccList = [{ label: "", value: null }, ...this.mccList];
    this.isSuperAdmin = this.appSettingsService.getUser().isSuperAdmin;

    if (!this._myForm.get("id").value) {
      this.subsList.push(
        this.appSettings.getBusinessDetails().subscribe((data) => {
          data.address.addressEmail.id = 0;
          data.address.addressPhone.id = 0;
          this._myForm.patchValue({
            name: data.businessName,
            userFirstName: data.address.attnFirstName,
            userLastName: data.address.attnLastName,
            userEmail: data.address.addressEmail.email,
            address: { ...data.address, id: 0 },
            countryIsoCode: data.address.countryIsoCode,
          });
        })
      );
    } else {
      if (this._myForm.get("status").value === TakuPaymentAccountStatus.Enabled) {
        this._isShowActionBtn = false;
      } else if (
        this._myForm.get("status").value === TakuPaymentAccountStatus.Pending ||
        this._myForm.get("status").value === ""
      ) {
        this._isShowActionBtn = true;
      } else {
        this._isShowActionBtn = true;
      }
    }
  }

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    const tmpModel = new TakuPayAccountDetail();
    const tmpForm = fb.group(tmpModel);
    tmpForm.setControl("address", AddressComponent.init(fb, AddressLocationType.commercial));
    return tmpForm;
  }

  initForm() {
    this._model = "takuPaymentAccount";
    this._object = new TakuPayAccountDetail();
    this._myForm = TakuPayAccountDetailsComponent.init(this.fb);
  }

  initValidation() {
    this._validation = this.takuPayService.getValidationRules();
  }

  onNew() {
    if (this.dbMode !== DBMode.insert) {
      this.subsList.push(
        this.onSubmit().subscribe(() => {
          this._router.navigate(["integrations/my-apps/takuPaymentAccount/" + 0]);
          this.initForm();
          this._id = 0;

          this.subsList.push(
            this.appSettings.getBusinessDetails().subscribe((data) => {
              data.address.addressEmail.id = 0;
              data.address.addressPhone.id = 0;
              Object.assign(this._object, {
                name: data.businessName,
                userFirstName: data.address.attnFirstName,
                userLastName: data.address.attnLastName,
                userEmail: data.address.addressEmail.email,
                countryIsoCode: data.address.countryIsoCode,
                subDivisionIsoCode: data.address.subDivisionIsoCode,
                address: {
                  ...data.address,
                  id: 0,
                  addressEmail: { ...data.address.addressEmail, id: 0 },
                  addressPhone: { ...data.address.addressPhone, id: 0 },
                },
              });

              this.loadObject();
              this._initialFormValue = JSON.stringify(this._myForm.value).valueOf();
            })
          );
        })
      );
    }
  }

  onDelete() {
    if (this._myForm.get("status").value === TakuPaymentAccountStatus.Pending) {
      super.onDelete();
    } else {
      if (!this.isSuperAdmin && this._myForm.get("status").value !== TakuPaymentAccountStatus.Enabled) {
        this.confirmationService.confirm({
          header: "Note",
          message: "Please contact TAKU Help for assistance.",
          acceptLabel: "OK",
          rejectVisible: false,
        });
      } else {
        this.confirmationService.confirm({
          message: "Are you sure that you want to delete this record?",
          icon: "ui-icon-delete",
          rejectButtonStyleClass: "p-button-link",
          accept: () => {
            const filter = {
              takuPaymentAccountId: { value: this._myForm.get("id").value, matchMode: "equals" },
            };
            this.dbService
              .getRows("takuPaymentGateway", JSON.stringify(filter), 0, 1)
              .pipe(
                shareReplay(1),
                map((res: any) => !(res.rows && res.rows.length))
              )
              .subscribe((data: boolean) => {
                if (!data) {
                  this.messageService.add(this.alertMessage.getMessage("taku-payment-account-delete-failure"));
                  return;
                } else {
                  this.removeTakuPayAccount(this._myForm.get("id").value);
                }
              });
          },
        });
      }
    }
  }

  createTakuPayAccount(id: number) {
    this._creatingAccountDetail = true;
    this.takuPayService
      .createTakuPayAccount(id)
      .pipe(
        catchError((errorResponse) => {
          this._creatingAccountDetail = false;
          this.processServerError(errorResponse, false);
          return throwError(errorResponse);
        }),
        tap((result) => {
          if (result) {
            this._object = result.data;
            this.resetForm();

            this.messageService.add({ severity: "success", summary: "Service Message", detail: result.message });

            // CALL approve domain api
            this.takuPayService.approveDomain(id).subscribe({
              next: (res) => {
                this.messageService.add({ severity: "success", summary: "Service Message", detail: res.message });
              },
              error: (err) => {
                this.messageService.add({ severity: "error", summary: "Service Message", detail: err.message });
              },
            });
          }
        })
      )
      .subscribe(() => {
        this._creatingAccountDetail = false;
      });
  }

  removeTakuPayAccount(id: number) {
    this.takuPayService
      .removeTakuPayAccount(id)
      .pipe(
        catchError((errorResponse) => {
          this.processServerError(errorResponse, false);
          return throwError(errorResponse);
        }),
        tap((result) => {
          if (result) {
            this.dbService.deleteRow(this._model, this._myForm.get("id").value).subscribe(
              (data) => {
                this.messageService.add(this.alertMessage.getMessage("delete-success"));
                if (this._isDialog) {
                  this.changedForm.emit("Deleted");
                } else {
                  this.goBack();
                }
              },
              (error: HttpErrorResponse) => {
                this.messageService.add(this.alertMessage.getErrorMessage(error));
              },
              () => {}
            );
          }
        })
      )
      .subscribe(() => {});
  }
}
