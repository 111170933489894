/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit } from "@angular/core";
import { AppSettingsStorageService } from "../../../../../shared/app-settings-storage.service";

@Component({
  selector: "taku-store-connected-confirmation",
  templateUrl: "./store-connected-confirmation.component.html",
  styleUrls: ["./store-connected-confirmation.component.scss"],
})
export class StoreConnectedConfirmationComponent implements OnInit {
  _storeName: string;

  constructor(appSettings: AppSettingsStorageService) {
    this._storeName = appSettings.getStore().storeName;
  }

  ngOnInit() {}
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
