/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { animate, style, transition, trigger } from "@angular/animations";
import { Component, NgZone, AfterViewInit, OnDestroy } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { AppComponent } from "../app.component";
import { BackOffice } from "../core/settings/backOffice-settings/backOffice/backOffice";
import { Store } from "../core/settings/store-settings/store/store";
import { IdleService } from "../idle.service";
import { AppSettingsStorageService } from "../shared/app-settings-storage.service";
import { AuthService } from "../shared/services/auth.service";
import { UserProfileService } from "../shared/services/user-profile.service";
import { APP_BRAND } from "../utility/white-label";
import { AllServerErrors$ } from "../shared/services/http-interceptors/trace-id-error-interceptor.service";
import { DialogService } from "primeng/dynamicdialog";
import { ErrorLogsComponent } from "../shared/components/error-logs/error-logs.component";
declare const Intercom: any;

@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"],
  animations: [
    trigger("topbarActionPanelAnimation", [
      transition(":enter", [
        style({ opacity: 0, transform: "scaleY(0.8)" }),
        animate(".12s cubic-bezier(0, 0, 0.2, 1)", style({ opacity: 1, transform: "*" })),
      ]),
      transition(":leave", [animate(".1s linear", style({ opacity: 0 }))]),
    ]),
  ],
})
export class AppTopbarComponent implements AfterViewInit, OnDestroy {
  subsList: Subscription[] = [];
  activeStore: Store;
  activeBackOffice: BackOffice;
  intercomUnreadMsgs$ = new BehaviorSubject<number>(0);
  isSetUpVisible = false;
  isSetUpEnabled = false;
  branding = APP_BRAND;
  isOnSamsungKiosk = false;
  numberOfServerErrors = 0;
  serverErrorLabel = "0";

  constructor(
    private idleService: IdleService,
    public app: AppComponent,
    private auth: AuthService,
    public userService: UserProfileService,
    private ngZone: NgZone,
    private dialogService: DialogService,
    private appSettingsStorageService: AppSettingsStorageService
  ) {
    this.isOnSamsungKiosk = window.isOnSamsungKiosk;
    this.activeStore = this.appSettingsStorageService.getStore();
    this.subsList.push(
      this.appSettingsStorageService.activeStoreChanged$.subscribe((store) => {
        this.activeStore = store;
      })
    );

    this.activeBackOffice = this.appSettingsStorageService.getBackoffice();
    this.subsList.push(
      this.appSettingsStorageService.activeBackOfficeChanged$.subscribe((backOffice) => {
        this.activeBackOffice = backOffice;
      })
    );

    // The setup page has many inner pages,
    // the user needs to have visibile access rights on all these pages in order to have visible access rights on the
    // set up button.
    const setUpInnerVisible =
      auth.hasVisiblePermit("Settings_Menu_Business_Settings_Business_Details") &&
      auth.hasVisiblePermit("Settings_Menu_Zones_Current_Zone_Denominations") &&
      auth.hasVisiblePermit("Settings_Menu_Zones_Current_Zone_Tender_Types") &&
      auth.hasVisiblePermit("Settings_Menu_Stores_Current_Store_Payment_Settings") &&
      auth.hasVisiblePermit("Settings_Menu_Stores_Current_Store_Sales_Doc_Formats") &&
      auth.hasVisiblePermit("Settings_Menu_Zones_Current_Zone_Inventory_Doc_Formats");

    // The user needs to have enable access on all the inner pages in order to have enable access on the set up button.
    const setUpInnerEnabled =
      auth.hasEnablePermit("Settings_Menu_Business_Settings_Business_Details") &&
      auth.hasEnablePermit("Settings_Menu_Zones_Current_Zone_Denominations") &&
      auth.hasEnablePermit("Settings_Menu_Zones_Current_Zone_Tender_Types") &&
      auth.hasEnablePermit("Settings_Menu_Stores_Current_Store_Payment_Settings") &&
      auth.hasEnablePermit("Settings_Menu_Stores_Current_Store_Sales_Doc_Formats") &&
      auth.hasEnablePermit("Settings_Menu_Zones_Current_Zone_Inventory_Doc_Formats");

    this.isSetUpVisible =
      this.activeStore.id !== 0 && auth.hasVisiblePermit("Settings_Menu_Stores_Manage_Stores") && setUpInnerVisible;
    this.isSetUpEnabled =
      this.activeStore.id !== 0 && auth.hasEnablePermit("Settings_Menu_Stores_Manage_Stores") && setUpInnerEnabled;

    this.subsList.push(
      AllServerErrors$.subscribe((allServerErrors) => {
        this.numberOfServerErrors = allServerErrors.length;
        this.serverErrorLabel = allServerErrors.length > 9 ? "9+" : String(allServerErrors.length);
      })
    );
  }

  showErrorLogs(): void {
    this.dialogService.open(ErrorLogsComponent, { style: { maxWidth: "calc(100% - 2rem)" } });
  }

  onLogout() {
    this.app.topbarMenuActive = false;
    this.auth.logout();
    this.idleService.stop();
  }

  ngAfterViewInit(): void {
    this.customizeIntercomLauncher();
  }

  private customizeIntercomLauncher() {
    if (!window.isOnSamsungKiosk) {
      Intercom("onUnreadCountChange", (unreadCount: number) => {
        this.ngZone.run(() => {
          this.intercomUnreadMsgs$.next(unreadCount);
        });
      });
    }
  }

  // onAppCuesPressed($event: Event) {
  //   $event.preventDefault();
  //   $event.stopPropagation();
  // }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
