/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, ElementRef, HostBinding, Input, ViewChild } from "@angular/core";
import { CountryPhoneMaskService } from "../../shared/services/country-phone-mask.service";
import { TakuInputCtrlBase } from "../TakuInputCtrlBase";
import { TakuInputMaskComponent } from "../taku-inputmask/taku-inputmask.component";

@Component({
  selector: "taku-phone",
  templateUrl: "./taku-phone.component.html",
  styleUrls: ["./taku-phone.component.scss"],
})
export class TakuPhoneComponent extends TakuInputCtrlBase {
  @Input() set alpha2(code: string) {
    if (code.length >= 2) {
      this._phoneMask = this.cpmService.getPhoneMask(code);
    }
  }
  @ViewChild(TakuInputMaskComponent, { static: true }) inputMaskComponent;
  _phoneMask = "";

  constructor(private cpmService: CountryPhoneMaskService, el: ElementRef) {
    super(el);
  }

  clear() {
    this.ctrlField.reset();
    this.ctrlField.markAsDirty();
  }

  showInvalidInputMsg(keyCode) {
    this.ctrlField.setErrors({ "phone.invalidCharInput": true });
  }

  // onTelInputClicked(event){
  //   if (!this.inputMaskComponent) return;

  //   this.inputMaskComponent.onInputFocus(event);
  //   event.preventDefault();
  // }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
