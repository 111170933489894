/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Cost_Basis } from "../../inventory/inventory/inventory";
import { TaxAccountCategory } from "../../tax/tax-account-category/tax-account-category";

export class Zone {
  id: number;
  countryIsoCode: string;
  zoneName: string;
  isActive: boolean;
  cashRounding: string;
  defaultCurrencyIsoCode: string;
  defaultCostBasis: Cost_Basis;
  taxAccountCategoryId: number;
  taxAccountCategory: TaxAccountCategory;

  constructor() {
    this.id = 0;
    this.countryIsoCode = null;
    this.zoneName = "";
    this.isActive = true;
    this.cashRounding = "0.01";
    this.defaultCurrencyIsoCode = null;
    this.taxAccountCategoryId = null;
    this.defaultCostBasis = Cost_Basis.standard;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
