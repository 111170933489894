/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, EventEmitter, Input, Output, SimpleChanges, OnChanges } from "@angular/core";
import { MenuItem } from "primeng/api";

import { FormListExportFormat } from "../form-list/form-list";
import { FormListModel } from "../form-list/form-list-model.interface";

@Component({
  selector: "taku-form-list-header",
  templateUrl: "./form-list-header.component.html",
  styleUrls: ["./form-list-header.component.scss"],
})
export class FormListHeaderComponent implements OnChanges {
  @Input() modelName: string;
  @Output() backClicked = new EventEmitter();
  @Input() disableBack: boolean;
  @Output() editClicked = new EventEmitter();
  @Input() disableEdit: boolean;
  @Output() addClicked = new EventEmitter();
  @Input() hideDelete = false;
  @Input() hideAdd = false;
  @Input() hideClone? = false;
  @Input() hideAddLine = false;
  @Input() hideMoreOptions = false;
  @Output() deleteClicked = new EventEmitter();
  @Input() disableDelete: boolean;
  @Input() itemDescription: string;
  @Input() cloneModeEnabled = false;
  @Input() modelColsService: FormListModel;

  @Output() saveClicked = new EventEmitter();
  @Output() discardClicked = new EventEmitter();
  @Output() cancelClicked = new EventEmitter();
  @Output() newClicked = new EventEmitter();
  @Output() newLineClicked = new EventEmitter();
  @Output() exportClicked = new EventEmitter();
  @Output() cloneClicked = new EventEmitter();

  items: MenuItem[];
  items_extended: MenuItem[];

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    // Should change the dropdown menu whenever the form list changes
    if (changes.modelColsService) {
      this.items = [];
      if (!this.hideClone && this.modelColsService && !this.modelColsService._disableCloning)
        this.items.push({
          label: "Clone",
          command: (event) => {
            this.cloneClicked.emit();
          },
        });

      // Only add to the menu import and export options if they are availble for import/export
      this.items.push(
        ...[
          {
            label: "Export CSV",
            command: (event) => {
              this.exportClicked.emit(FormListExportFormat.CSV);
            },
          },
          {
            label: "Export XLSX",
            command: (event) => {
              this.exportClicked.emit(FormListExportFormat.EXCEL);
            },
          },
          {
            label: "Export PDF",
            command: (event) => {
              this.exportClicked.emit(FormListExportFormat.PDF);
            },
          },
        ]
      );

      this.updateItemsExtended(this.hideAdd, this.hideAddLine, this.hideDelete);
      this.items_extended = [...this.items_extended, ...this.items];
    }
  }

  updateItemsExtended(hideAddBtn: boolean, hideAddLineBtn: boolean, hideDeleteBtn: boolean): void {
    this.items_extended = [];
    if (!hideAddLineBtn) {
      this.items_extended.push({
        label: "Add New Row",
        command: (event) => {
          this.newLineClicked.emit();
        },
      });
    }
    if (!hideAddBtn) {
      this.items_extended.push({
        label: "Add New",
        command: (event) => {
          this.newClicked.emit();
        },
      });
    }
    if (!hideDeleteBtn) {
      this.items_extended.push({
        label: "Delete",
        command: (event) => {
          this.deleteClicked.emit();
        },
      });
    }
  }

  hideBtnsDisplay(): boolean {
    return window.innerWidth <= 414 && !this.hideMoreOptions;
  }

  onBack() {
    this.backClicked.emit();
  }
  onEdit() {
    this.editClicked.emit();
  }
  onAdd() {
    this.addClicked.emit();
  }
  onDelete() {
    this.deleteClicked.emit();
  }

  onSave() {
    this.saveClicked.emit();
  }

  onDiscard() {
    this.discardClicked.emit();
  }

  onCancel() {
    this.cancelClicked.emit();
  }

  onNew() {
    this.newClicked.emit();
  }

  onNewLine() {
    this.newLineClicked.emit();
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
