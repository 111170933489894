/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { TaxRule } from "../../../tax/tax-rule/tax-rule";

export class SaleDocTax {
  id: number;
  amount: number;
  taxRuleId: number;
  taxRule: TaxRule;

  constructor() {
    this.id = 0;
    this.amount = 0;
    this.taxRuleId = 0;
    this.taxRule = null;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
