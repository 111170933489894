<div id="data-progressbar">
  <p-progressBar mode="indeterminate" value="0" [style.visibility]="!formFilter ? 'visible' : 'hidden'">
  </p-progressBar>
</div>

<div *ngIf="formFilter && defaultNewRowValues" class="col-12 formlistWrapper">
  <taku-form-list
    #formList
    [_filter]="formFilter"
    [_model]="'shelf'"
    _dialogStyleClass="fullscreen-mobilemodal"
    [_dialogShowHeader]="true"
    [_hideSavingCtrls]="false"
    [_showCtrlsInEditMode]="false"
    [_hideAddLineHeaderBtn]="false"
    [_hideOptionsHeaderBtn]="false"
    [_hideGlobalFilter]="false"
    [_hideAddHeaderBtn]="false"
    [_hideDeleteHeaderBtn]="false"
    [_title]="'Shelves'"
    [_rowSelectionType]="RowSelectionType.MULTIPLE"
    _detailMode="n"
    _scrollHeight="60vh"
    [_hideClone]="true"
    _viewColIcon="_"
    _viewColIconName="remove_red_eye"
    [_cols]="shelfColumns"
    [_defaultRowValues]="defaultNewRowValues"
    [_dialogDismissable]="true"
  >
  </taku-form-list>
</div>

<div [hidden]="true">
  <ng-template #printPreviewHost></ng-template>
</div>
