/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Inject, Optional } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { ArrayFormComponent, DATA_ARRAY_TOKEN } from "src/app/forms/array-form/array-form.component";
import { DISPLAY_MODE_TOKEN } from "src/app/forms/nested-form/nested-form.component";
import { LocationService } from "src/app/shared/services/location.service";

@Component({
  selector: "taku-phone-array",
  templateUrl: "./phone-array.component.html",
  styleUrls: ["./phone-array.component.scss"],
})
export class PhoneArrayComponent extends ArrayFormComponent {
  constructor(
    fb: UntypedFormBuilder,
    @Optional() @Inject(DATA_ARRAY_TOKEN) injectedDataArray,
    @Optional() @Inject(DISPLAY_MODE_TOKEN) injectedDisplayMode,
    public lService: LocationService
  ) {
    super(fb, injectedDataArray, injectedDisplayMode);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
