/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Input } from "@angular/core";
import { NestedFormComponent } from "../../../forms/nested-form/nested-form.component";
import { PersonInstantMsgType, PersonInstantMsg } from "./person-instant-msg";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ACTION_TYPE } from "src/app/shared/components/action-button/action-type.enum";

@Component({
  selector: "taku-person-instant-msg",
  templateUrl: "./person-instant-msg.component.html",
  styleUrls: ["./person-instant-msg.component.scss"],
})
export class PersonInstantMsgComponent extends NestedFormComponent {
  @Input() disableForm?: boolean = false;

  // Define enum variables
  // enum_commecrial_phone_type = this.dbService.enumSelectOptions(CommercialAccountPhoneType, 'Type');
  enum_person_instant_msg_type = this.dbService.enumSelectOptions(PersonInstantMsgType);
  public ACTION_TYPE: typeof ACTION_TYPE = ACTION_TYPE;

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    const personInstantMsg_Group = fb.group(new PersonInstantMsg());
    return personInstantMsg_Group;
  }

  static setArray(fb: UntypedFormBuilder, personInstantMsgs: PersonInstantMsg[]) {
    const formArray = fb.array([]);
    personInstantMsgs.map((personInstantMsg) => {
      const temp = fb.group(personInstantMsg);
      formArray.push(temp);
    });
    return formArray;
  }

  initValidation() {
    this._validation = {
      instantMsgType: [Validators.required],
      instantMsg: [Validators.required],
    };
  }

  copyInstantMsgToClipboard() {
    this.copyValueToClipboard(this.instantMsgCtrl.value);
  }

  get instantMsgCtrl() {
    return this._myForm.get("instantMsg");
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
