/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, Input } from "@angular/core";
import { NestedFormComponent } from "../../../../forms/nested-form/nested-form.component";
import { FormGroup, UntypedFormBuilder } from "@angular/forms";
import { StoreSettingReturnReason } from "./StoreSettingReturnReason";

@Component({
  selector: "taku-return-reason",
  templateUrl: "./return-reason.component.html",
  styleUrls: ["./return-reason.component.scss"],
})
export class ReturnReasonComponent extends NestedFormComponent {
  static init(fb: UntypedFormBuilder) {
    const tmp = fb.group(new StoreSettingReturnReason());
    return tmp;
  }

  static setArray(fb: UntypedFormBuilder, returnReasons: StoreSettingReturnReason[]) {
    const tmpArray = fb.array([]);
    returnReasons.forEach((reason) => {
      tmpArray.push(fb.group(reason));
    });

    return tmpArray;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
