<span [hidden]="displayMode !== NestedFormDisplayMode.EDITABLE_FORM">
  <div [formGroup]="_myForm" class="grid">
    <div
      *ngIf="showLocationTypeControl"
      class="col-12 drop-container locationType-wrapper"
      [ngClass]="{ 'lg:col-6': showIsDefaultControl }"
    >
      <taku-dropdown
        [disabled]="disableForm"
        [formGroup]="_myForm"
        [options]="enum_address_location_type"
        [fieldName]="'addressLocationType'"
        [fieldCaption]="'Location Type'"
      >
      </taku-dropdown>
    </div>

    <div *ngIf="showIsDefaultControl" class="col-12 lg:col-6 address-is-default">
      <taku-toggle
        [formGroup]="_myForm"
        fieldName="isDefault"
        fieldCaption="Default Address"
        enabledStateLabel="ON"
        disabledStateLabel="OFF"
      ></taku-toggle>
    </div>

    <div *ngIf="showAttentionControl" class="col-12">
      <p-fieldset class="attention-wrapper taku-listable-container taku-expandable-block">
        <ng-template pTemplate="header">
          <h5>Attention (Optional)</h5>
        </ng-template>

        <div class="add-button-container">
          <a class="add-button" (click)="toggleAttentionVisibility()">
            <label>{{ attentionVisibility ? "Hide" : "Show" }}</label>
            <i class="material-icons">{{ attentionVisibility ? "remove_circle_outline" : "add_circle_outline" }}</i>
          </a>
        </div>

        <div class="col-12 grid expansion-fields" [ngClass]="{ 'container-hidden': !attentionVisibility }">
          <div class="col-12 companyName-wrapper">
            <taku-input
              [disabled]="disableForm"
              [formGroup]="_myForm"
              [fieldName]="'attnCompanyName'"
              [fieldCaption]="'Company Name'"
            >
            </taku-input>
          </div>

          <div class="col-12 sm:col-6 firstName-wrapper">
            <taku-input
              [disabled]="disableForm"
              [formGroup]="_myForm"
              [fieldName]="'attnFirstName'"
              [fieldCaption]="'First Name'"
            >
            </taku-input>
          </div>

          <div class="col-12 sm:col-6 lastName-wrapper">
            <taku-input
              [disabled]="disableForm"
              [formGroup]="_myForm"
              [fieldName]="'attnLastName'"
              [fieldCaption]="'Last Name'"
            >
            </taku-input>
          </div>
        </div>
      </p-fieldset>
    </div>

    <div class="col-12 line1-wrapper">
      <taku-input
        [disabled]="disableForm"
        [formGroup]="_myForm"
        [fieldName]="'line1'"
        [fieldCaption]="'Address Line 1'"
      >
      </taku-input>
    </div>

    <div class="col-12 line2-wrapper">
      <taku-input
        [disabled]="disableForm"
        [formGroup]="_myForm"
        [fieldName]="'line2'"
        [fieldCaption]="'Address Line 2 (Suite, Unit, etc.)'"
      >
      </taku-input>
    </div>

    <div class="col-12">
      <div class="grid">
        <div class="col-12 lg:col-6 drop-container country-wrapper">
          <taku-country
            [disabled]="disableForm"
            [formGroup]="_myForm"
            [fieldName]="'countryIsoCode'"
            [fieldCaption]="'Country'"
          ></taku-country>
        </div>

        <div class="col-12 lg:col-6 drop-container region-wrapper">
          <taku-sub-region
            [disabled]="!countryIsoCodeCtrl.value || disableForm"
            [formGroup]="_myForm"
            fieldName="subDivisionIsoCode"
            fieldCaption="Region"
            [countryIsoCode]="countryIsoCodeCtrl.value"
          ></taku-sub-region>
        </div>

        <div class="col-12 lg:col-6 city-wrapper">
          <span class="p-float-label">
            <p-autoComplete
              [disabled]="!countryIsoCodeCtrl.value || disableForm"
              formControlName="city"
              (completeMethod)="lookupCity($event)"
              [suggestions]="lookup_city"
              [style]="{ width: '100%' }"
              appendTo="body"
            ></p-autoComplete>
            <label [innerHTML]="cityCaption"></label>
            <i class="material-icons clear-field" (click)="clear()" *ngIf="cityCtrl.value">close</i>
            <!-- <form-validation-messages [hidden]="cityCtrl.valid || !cityCtrl.dirty" [errors]="cityCtrl.errors" field="city"></form-validation-messages> -->
          </span>
        </div>

        <div class="col-12 lg:col-6 postalCode-wrapper">
          <taku-postal-code
            [disabled]="!countryIsoCodeCtrl.value || disableForm"
            #postalCode
            [formGroup]="_myForm"
            [fieldName]="'postalCode'"
            fieldCaption="Postal/Zip"
            [countryIsoCode]="countryIsoCodeCtrl.value"
          >
          </taku-postal-code>
        </div>
      </div>
    </div>

    <!-- <div class="col-12 pinaddress-container">
    <p-radioButton label="Pin this address" name="isDefault"></p-radioButton>
  </div> -->

    <div class="spacing2"></div>

    <div class="email-wrapper col-12 grid-nogutter" *ngIf="showEmailControl">
      <div class="col-12">
        <h5>Email Address</h5>
      </div>
      <div class="col-12">
        <taku-address-email [disableForm]="disableForm" [formGroup]="_myForm.get('addressEmail')"></taku-address-email>
      </div>
    </div>

    <div class="spacing2"></div>

    <div class="phone-wrapper col-12 grid-nogutter">
      <div class="col-12">
        <h5>Phone Number</h5>
      </div>
      <div class="col-12">
        <taku-address-phone
          [showPhoneCopyControl]="showPhoneCopyControl"
          [disableForm]="disableForm"
          [formGroup]="_myForm.get('addressPhone')"
        ></taku-address-phone>
      </div>
    </div>

    <div *ngIf="showNoteControl" class="col-12 notes-wrapper">
      <taku-input-textarea
        [disabled]="disableForm"
        [formGroup]="_myForm"
        [fieldName]="'note'"
        [fieldCaption]="'Note'"
        [rows]="4"
      >
      </taku-input-textarea>
    </div>
  </div>
</span>

<ng-template [ngIf]="displayMode === NestedFormDisplayMode.TABULAR_DATA">
  <td class="locationType-col">
    <span class="p-column-title">Location Type</span>
    {{ locationTypeCtrl.value }}
  </td>

  <td class="addressType-col">
    <span class="p-column-title">Address Type</span>
    {{ addressTypeCtrl.value }} <span *ngIf="isDefaultCtrl.value">*</span>
  </td>

  <td class="line1-col">
    <span class="p-column-title">Line 1</span>
    {{ line1Ctrl.value }}
  </td>

  <td class="line2-col">
    <span class="p-column-title">Line 2</span>
    {{ line2Ctrl.value }}
  </td>

  <td class="city-col">
    <span class="p-column-title">City</span>
    {{ cityCtrl.value }}
  </td>

  <td class="region-col">
    <span class="p-column-title">Region</span>
    {{ subdivisionFullName }}
  </td>

  <td class="postalCode-col">
    <span class="p-column-title">Postal Code</span>
    {{ postalCodeCtrl.value }}
  </td>

  <td class="country-col">
    <span class="p-column-title">Country</span>
    {{ countryFullName }}
  </td>
</ng-template>
