import { Component } from "@angular/core";
import { GenericFormComponent } from "src/app/forms/generic-form/generic-form.component";
import { PaymentTerminal } from "../gateway-terminals/payment-terminal";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { GatewayTerminalsService } from "../gateway-terminals/gateway-terminals.service";
import { ConfirmationService, MessageService } from "primeng/api";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { Location } from "@angular/common";
import { EconduitService } from "./econduit.service";

export class EConduitTerminalInfo {
  serialNo: string;
  terminalID: string;
  API_Key: string;
  API_Password: string;
  enableGetSignature: boolean;

  constructor() {
    this.serialNo = null;
    this.terminalID = null;
    this.API_Key = null;
    this.API_Password = null;
    this.enableGetSignature = false;
  }
}

@Component({
  selector: "taku-econduit-terminal",
  templateUrl: "./econduit-terminal.component.html",
  styleUrls: ["./econduit-terminal.component.scss"],
})
export class EconduitTerminalComponent extends GenericFormComponent {
  constructor(
    protected _router: Router,
    public fb: UntypedFormBuilder,
    public dbService: GatewayTerminalsService,
    protected location: Location,
    public _route: ActivatedRoute,
    protected messageService: MessageService,
    protected alertMessage: AlertMessagesService,
    protected confirmationService: ConfirmationService,
    protected appSettingsService: AppSettingsStorageService,
    private authService: AuthService,
    public econduitService: EconduitService
  ) {
    super(
      _router,
      fb,
      dbService,
      location,
      _route,
      messageService,
      alertMessage,
      confirmationService,
      appSettingsService
    );
  }

  get pageTitle() {
    return `${this._object.terminalName} (${this._object.serialNo})`;
  }

  get infoFormGroup() {
    return this._myForm.get("info") as UntypedFormGroup;
  }

  get terminalIDCtrl() {
    return this.infoFormGroup.get("terminalID");
  }

  initForm() {
    const newTerminal = new PaymentTerminal();
    const terminalInfo = (newTerminal.info = new EConduitTerminalInfo());
    this._object = newTerminal;
    this._model = "paymentTerminal";
    const formDef = Object.assign({}, newTerminal, {
      info: this.fb.group(Object.assign({}, terminalInfo)),
    });
    this._myForm = this.fb.group(formDef);
  }

  initValidation() {
    this._validation = this.dbService.getValidationRules();
    this._validation["info"] = {
      serialNo: [Validators.required],
    };
  }

  setFormArrays() {
    if (!this._object.info) this._object.info = new EConduitTerminalInfo();
  }
}
