/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { DBService } from "src/app/shared/services/db.service";
import { ValidatorFn, UntypedFormArray, AbstractControl, UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import { CommonValidators } from "src/app/shared/validators/CommonValidators";
import { InventoryStock } from "../inventory/inventory";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "src/app/shared/services/auth.service";
import { Observable, of } from "rxjs";
import { Stock } from "../../settings/business-settings/stock/stock";
import { map, tap } from "rxjs/operators";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";

@Injectable({
  providedIn: "root",
})
export class InventoryStockService extends DBService {
  protected zoneStocks: Stock[];
  protected override cleanUpAfterLogout(): void {
    this.zoneStocks = null;
  }

  constructor(
    private appSettings: AppSettingsStorageService,
    private fb: UntypedFormBuilder,
    http: HttpClient,
    authService: AuthService
  ) {
    super(http, authService);
  }

  getValidationRules(): { [key: string]: ValidatorFn[] | any } {
    return {
      minStock: [CommonValidators.digits()],
      qtyOH: [CommonValidators.number()],
    };
  }

  getActiveZoneStocks(): Observable<Stock[]> {
    // if (this.lookup_zone_stocks) return of(this.lookup_zone_stocks);
    if (this.zoneStocks) {
      return of(this.zoneStocks);
    } else {
      let zoneId,
        filter = {};
      if ((zoneId = this.appSettings.getZoneId()))
        filter = {
          zoneId: { matchMode: "equals", value: zoneId },
        };
      return this.getRows("stock", JSON.stringify(filter), 0, 100).pipe(
        map((data: any) => {
          return (<Stock[]>data.rows).sort((s1, s2) => s1.id - s2.id);
        }),
        tap((stocks: Stock[]) => (this.zoneStocks = stocks))
      );
    }
  }

  populateFormArray(formArray: UntypedFormArray, stockIsVisibleCtrl?: string) {
    const defaultStockId = this.appSettings.getStore().stockId;
    this.getActiveZoneStocks().subscribe((stocks) => {
      stocks.forEach((stock) => {
        // First try to find actual stock into array
        let foundStock: AbstractControl = null;
        for (let i = 0; i < formArray.length; i++) {
          if (formArray.at(i).value.stockId === stock.id) {
            foundStock = formArray.at(i);
            break;
          }
        }

        let zoneStock: UntypedFormGroup;
        if (foundStock) {
          zoneStock = <UntypedFormGroup>foundStock;
        } else {
          // if actual stock is not in form array, means it's new stock for inventory
          const inventoryStock = new InventoryStock();
          inventoryStock.stockId = stock.id;
          inventoryStock.stock = stock;
          zoneStock = this.fb.group(inventoryStock);
          formArray.push(zoneStock);
        }

        if (stockIsVisibleCtrl) {
          // add virtual field to mark this stock as visible in order to show in UI
          if (zoneStock.get(stockIsVisibleCtrl)) zoneStock.get(stockIsVisibleCtrl).setValue(true);
          else zoneStock.addControl(stockIsVisibleCtrl, this.fb.control(true));
        }
      });

      for (let i = 0; i < formArray.length; i++) {
        const formCtrl = formArray.at(i);
        if (formCtrl.value.stockId == defaultStockId) {
          formArray.removeAt(i); // Remove control from current position
          formArray.insert(0, formCtrl); // Insert at the beginning of array
          break;
        }
      }
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
