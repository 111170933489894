/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, Output, EventEmitter, AfterViewInit, ViewChild } from "@angular/core";
import { Inventory } from "../../inventory/inventory/inventory";
import { SearchResultItem } from "../../../taku-ui/taku-search-accounts/SearchResultItem";
import { TakuSearchInventoryComponent } from "../../../taku-ui/taku-search-inventory/taku-search-inventory.component";

@Component({
  selector: "taku-return-item",
  templateUrl: "./return-item.component.html",
  styleUrls: ["./return-item.component.scss"],
})
export class ReturnItemComponent implements OnInit, AfterViewInit {
  @Output() onItemReturned: EventEmitter<Inventory> = new EventEmitter();
  @Output() onNewInventory: EventEmitter<string> = new EventEmitter();

  @ViewChild(TakuSearchInventoryComponent, { static: true }) inventorySearchComponent: TakuSearchInventoryComponent;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    // Focus input on search component
    setTimeout(() => {
      if (this.inventorySearchComponent) this.inventorySearchComponent.cleanAndFocusSearchInput();
    }, 0);
  }

  itemSelected(inventorySearchRes: SearchResultItem) {
    this.onItemReturned.emit(inventorySearchRes.data);
  }

  onNewItem(modelName: string) {
    this.onNewInventory.emit(modelName);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
