<div class="topWrapper">
  <div class="col-12 formlist-wrapper">
    <taku-form-list
      [_showHeader]="true"
      [_filter]="formlistFilter"
      _model="saleDoc"
      [_rowSelectionType]="RowSelectionType.NONE"
      [_detailMode]="'d'"
      [_dataService]="dbService"
      [_showCtrlsInEditMode]="false"
      [_hideAddLineHeaderBtn]="true"
      [_hideOptionsHeaderBtn]="false"
      [_scrollHeight]="'full'"
      [_hideGlobalFilter]="true"
      [_hideAddHeaderBtn]="true"
      [_hideDeleteHeaderBtn]="true"
      [_title]="config.data.headerTitle"
      [_hideSavingCtrls]="true"
      [_cols]="cashoutTenderColumns"
      [_saveFormState]="false"
      [_extraQueryParams]="_formListExtraParams"
      _viewColIcon="_"
      _viewColIconName="remove_red_eye"
    ></taku-form-list>
  </div>

  <div class="close-btn">
    <button pButton label="CLOSE" class="p-button-secondary" (click)="onCloseBtnPressed()"></button>
  </div>
</div>
