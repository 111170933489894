/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Input, OnInit, AfterViewInit } from "@angular/core";
import { UntypedFormBuilder, Validators, UntypedFormControl } from "@angular/forms";
import { NestedFormComponent } from "../../../forms/nested-form/nested-form.component";
import { MonetaryHelpers } from "../../../utility/MonetaryHelpers";
import { CommonValidators } from "../../../shared/validators/CommonValidators";
import { CashoutOpeningFloat, Cashout } from "../cashout/cashout";

@Component({
  selector: "taku-cashout-opening-float",
  templateUrl: "./cashout-opening-float.component.html",
  styleUrls: ["./cashout-opening-float.component.scss"],
})
export class CashoutOpeningFloatComponent extends NestedFormComponent implements OnInit {
  @Input() currencyISOCode;
  amountCtrl: UntypedFormControl;
  fieldCountMaxLength: number;

  static setArray(fb: UntypedFormBuilder, cashOpeningsFloats: CashoutOpeningFloat[]) {
    const formArray = fb.array([]);
    cashOpeningsFloats.map((cashOpeningsFloat) => {
      const temp = fb.group(cashOpeningsFloat);
      formArray.push(temp);
    });
    return formArray;
  }

  ngOnInit(): void {
    super.ngOnInit();

    // Set max amount of inputable characters
    // const maxValidCountValue = Math.trunc((CashoutOpeningFloatComponent.MAX_TOTAL-1) / parseFloat(this.amountCtrl.value))
    // this.fieldCountMaxLength = (maxValidCountValue+'').length + 1;
    const maxValidCountValue = Cashout.MAX_COUNT - 1;
    this.fieldCountMaxLength = (maxValidCountValue + "").length;
  }

  initValidation() {
    this.amountCtrl = <UntypedFormControl>this._myForm.get("amount");

    this._validation = {
      count: [Validators.min(0), CommonValidators.digits(), Validators.required],
    };
  }

  formatDenominationLabel(amount) {
    return MonetaryHelpers.pluralizeDenomination(amount, this.currencyISOCode);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
