/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import * as _ from "lodash";

@Injectable({
  providedIn: "root",
})
export class ServerErrorMessagesService {
  constructor() {}

  getErrorMessage(errorName: string, errorData: any, fields: { [key: string]: any }, triggerFieldName: string): string {
    const fieldsNames = Object.keys(fields);
    switch (errorName) {
      case "SequelizeUniqueConstraintError":
        const fieldsString = fieldsNames.map((field) => _.capitalize(field.toLocaleLowerCase())).join(", ");
        return `This ${fieldsString} already exists.<br> Only unique ${fieldsString}s are allowed.`;

      default:
        return errorName;
    }
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
