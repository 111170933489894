/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component } from "@angular/core";
import { OverlayModalName } from "../../sale-document/generic-sale-doc.component";
import { ReturnInvoiceDocBaseComponent } from "../return-invoice-doc-base/return-invoice-doc-base.component";
import { InvoiceKeyboardActionsService } from "../../sale-document/sale-invoice-doc-base/invoice-keyboard-actions.service";
import { ReturnsSideDialogNameMobile, ReturnsSideDialogName } from "../../types/invoice-enums.type";

@Component({
  selector: "taku-return-invoice-doc-mobile",
  templateUrl: "./return-invoice-doc-mobile.component.html",
  styleUrls: ["./return-invoice-doc-mobile.component.scss"],
  providers: [InvoiceKeyboardActionsService],
})
export class ReturnInvoiceDocMobileComponent extends ReturnInvoiceDocBaseComponent {
  closeRightDialog() {
    super.closeRightDialog();
    this.docComponent.closeFullSizeDialog();
  }

  restoreActiveOverlay(activeOverlay: OverlayModalName, activeOverlayExtra: any, activeOverlayParams: any) {
    if ((Object.values(ReturnsSideDialogNameMobile) as string[]).includes(activeOverlay))
      this.openRightDialog(activeOverlay as ReturnsSideDialogNameMobile, activeOverlayExtra);
    else this.docComponent.openFullSizeDialog(activeOverlay, activeOverlayExtra, activeOverlayParams);
  }

  // Ovewrite parent implementation, so we open component in dialog/sidebar instead as right-side overlay
  openRightDialog(dialogName: ReturnsSideDialogName, extra = null) {
    super.openRightDialog(dialogName, extra);

    const dialogParams = {};
    const savePreviousOverlay = true;

    // When we close tender screen updates it's visibility in oder to renable UI buttons
    if (dialogName === ReturnsSideDialogName.TENDER_SCREEN)
      dialogParams["onHide"] = () => {
        this.returnInvoiceStateService.anounceTenderScreenVisibility(false);
      };

    this.docComponent.openFullSizeDialog(dialogName, extra, dialogParams, savePreviousOverlay);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
