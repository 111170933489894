/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { ValidationErrors } from "@angular/forms";
import { startCase } from "lodash";

import { ServerErrorMessagesService } from "./server-error-messages.service";

@Injectable({
  providedIn: "root",
})
export class ValidationMessagesService {
  constructor(private serverErrorMessagesService: ServerErrorMessagesService) {}

  getErrorMessage(errorType: string, error: any, field?: string): string {
    switch (errorType) {
      case "server-error":
        return this.serverErrorMessagesService.getErrorMessage(error.errorName, error.errorData, error.fields, field);
      case "required":
        return this.getRequiredErrorMsg(field);
      case "pattern":
        return "The input value does not match the pattern required";
      case "mask":
        return "Please enter the remaining characters";
      case "maskAccountCode":
        return "Please complete the code. Enter only numbers.";
      case "email":
        return "Invalid email format";
      case "minlength":
        return "Minimum length is " + error.requiredLength;
      case "maxlength":
        return "Maximum length is " + error.requiredLength;
      case "minNumber":
        return "Minimal value is " + error.requiredRange;
      case "maxNumber":
        return "Maximal value is " + error.requiredRange;
      case "greaterThan":
        return "Must be greater than " + error.min;
      case "noEmpty":
        return "Only blank spaces are not allowed";
      case "rangeLength":
        return "Must be between " + error.rangeMin + " and " + error.rangeMax + " characters long";
      case "range":
        return "The input must be between " + error.rangeMin + " and " + error.rangeMax;
      case "digits":
        return "Can only be numbers";
      case "negativeNumber":
        return "Can only be negative";
      case "integer":
        return "Must be an integer";
      case "pinteger":
        return "Must be a positive integer";
      case "equal":
        return "The input must be equal to " + error.comparer;
      case "url":
        return "Enter a valid URL";
      case "date":
        return "Enter a valid date";
      case "dateRangeStart":
        return "Cannot be after end date";
      case "dateRangeEnd":
        return "Cannot be before start date";
      case "areEqual":
        return "The values in the group must match";
      case "passwords":
        return 'Both fields "Password" and "Confirm Password" must match';
      case "unknownError":
        return "Unknown Error";
      case "postalcode_nocountry":
        return "Please select a valid country";
      case "postalcode":
        return "Invalid postal code for selected country";
      // Password's validations
      case "password.specialCharacters":
        return `Special characters (${error})`;
      case "password.upperCase":
        return "Upper case letters (A-Z)";
      case "password.containNumbers":
        return "At least 1 number (i.e. 0-9)";
      case "password.length":
        return `${error.minLength} to ${error.maxLength} characters long`;
      // Phone validations
      case "phone.invalidCharInput":
        return "Can only be numbers";
      case "invoice.discountAmount":
        return error;
      case "invoice.returnMaxQty":
        return error;
      case "max":
        return `Cannot be over ${error.max}`;
      case "min":
        return `Cannot be under ${error.min}`;
      case "cashout.openFloatMaximumTotal":
        return "Float total must be under " + error.maxAmount;
      case "barcode.format":
        return "Entry is NOT a valid " + error.format + " barcode";
      case "minLengthArray":
        return `Must contain at least ${error.length} element`;
      case "urlSegment":
        return `Can only contain alphanumeric characters <br>(numbers and letters from a to z)`;
      case "userRole.NoStoreOrBackoffice":
        return `Please select at least one Store or one Back office`;
      case "lookup_singleSelect":
        return `'${error.value}' is not a valid option. Please select from the list`;
      case "enum_singleSelect":
        return `'${error.value}' is not a valid option. Please select from the list`;
      case "enum_selectItem":
        return `'${error.value}' is not a valid option. Please select from the list`;
      case "boolean_value":
        return `'${error.value}' is not a boolean (Must be 'Yes' or 'No')`;
      case "zoneDenominationNotUnique":
        return `Combination of 'Value' and 'Cash Type' should be unique.`;
      case "zoneShipperNameNotUnique":
        return `Shipper Name should be unique.`;
      case "zoneShortNameNotUnique":
        return `Short Name should be unique.`;
      case "terminalSerialNumberNotUnique":
        return "Serial Number should be unique";
      case "terminalStationNotUnique":
        return "Station should be unique";
      case "lookup_multiSelect":
        const valuesGlued = error.values.join(", ");
        if (error.values.length <= 1) return `${valuesGlued} is not a valid option.`;
        else return `${valuesGlued} are not valid options.`;
      case "invalidPast":
        return `Cannot be set before current time`;
      case "invalidFuture":
        return `Cannot be set after current time`;
      case "incomplete_tel":
        return `Number is not expected length (${error.expected} digits)`;
      case "decimal":
        return `Amount cannot excede ${error.maxDigits} digits`;
      case "storeBackofficeSelection":
        return "Either a Store or a Back Office must be selected";
      case "generic":
        return error;
      default:
        return "Validation error";
    }
  }

  protected getRequiredErrorMsg(field?: string): string {
    switch (field) {
      case "tel":
        return "Required and numbers only";
      // case 'firstName': return 'Cannot be blank';
      default:
        return "Required field";
    }
  }

  formControlErrorsToString = (
    objectErrors: Record<string, ValidationErrors>,
    fieldsCaptions: Record<string, string>
  ): { field: string; errorMessages: string }[] => {
    // Get error messages for each column
    return Object.entries(objectErrors).map(([fieldName, errors]) => {
      // If no caption, fallback to fieldName
      const fieldCaption = fieldsCaptions?.[fieldName] ?? startCase(fieldName);

      // Translate error type to error message
      const messages = Object.entries(errors).map(([errorType, errorData]) =>
        this.getErrorMessage(errorType, errorData)
      );

      return {
        field: fieldCaption,
        errorMessages: messages.join(", "),
      };
    });
  };
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
