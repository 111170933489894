/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { SelectItem } from "primeng/api/selectitem";
import * as _ from "lodash";
import * as countries from "i18n-iso-countries";
import * as iso3166 from "iso-3166-2";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AuthService } from "./auth.service";
import { map } from "rxjs/operators";
import countriesENjson from "i18n-iso-countries/langs/en.json";

countries.registerLocale(countriesENjson);

// Object for country data returned by framework
const allCountries = countries.getNames("en");

export class Country {
  name: string;
  alpha2: string;
}

@Injectable({
  providedIn: "root",
})
export class LocationService {
  private webUrl = environment.apiUrl;

  _countryList: Country[] = [];

  constructor(private http: HttpClient, private authService: AuthService) {
    // Loop through the returned country data to create array of Country class data
    Object.keys(allCountries).forEach((key) => {
      this._countryList.push({
        name: allCountries[key].toString(),
        alpha2: key,
      });
    });
  }

  lookupCountryByIsoCode(countryIsoCode): string {
    const foundCountry = this._countryList.find((country) => country.alpha2 == countryIsoCode);
    return foundCountry ? foundCountry.name : null;
  }

  lookupCountryOptions_full(): SelectItem[] {
    const lookupOptions: SelectItem[] = [];
    this._countryList.forEach((country) => {
      lookupOptions.push({
        label: country.name,
        value: country.alpha2,
      });
    });
    return lookupOptions;
  }

  lookupCountryOptions_alpha2(): SelectItem[] {
    let lookupOptions: SelectItem[] = [];
    // const lookupOptions: SelectItem[] = [{label: '- Country -', value: ''}];
    this._countryList.forEach((country) => {
      lookupOptions = [
        ...lookupOptions,
        {
          label: "+ " + country.alpha2,
          value: country.alpha2,
        },
      ];
      // lookupOptions.push({
      //     label: '+ ' + country.alpha2,
      //     value: country.alpha2
      // });
    });
    return lookupOptions;
  }

  lookupSubdivisionName(countryIsoCode: string, subdivisionIsoCode: string) {
    const foundSubdivision = this.lookupSubdivisionOptions(countryIsoCode, false)
      .filter((item) => item.value == subdivisionIsoCode)
      .map((item) => item.label);
    return foundSubdivision.length ? foundSubdivision[0] : null;
  }

  lookupSubdivisionOptions(country: string, includeType = true): SelectItem[] {
    if (!country) return [];

    const subdivisionData = iso3166.country(country).sub;
    return this.subdivisionToSelectItem(subdivisionData, includeType);
  }

  lookupCityOptions(subDivisionIsoCode: string, searchValue: string) {
    return this.http
      .get<any[]>(`${this.webUrl}searchCity/${subDivisionIsoCode}/${searchValue}`, {
        headers: new HttpHeaders().set("Authorization", this.authService.getToken()),
      })
      .pipe(
        map((response: any) => {
          return response.rows;
        })
      );
  }

  lookupAllSubdivisions(includeType = false): SelectItem[] {
    const subvisionItems = [];
    Object.entries(iso3166.data).forEach(([countryCode, data]: [string, any]) => {
      subvisionItems.push(...this.subdivisionToSelectItem(data.sub, includeType));
    });

    return subvisionItems;
  }

  private subdivisionToSelectItem(subdivisionData, includeType): SelectItem[] {
    const lookupOptions: SelectItem[] = [];
    const codeList = Object.keys(subdivisionData);
    codeList.forEach((code) => {
      lookupOptions.push({
        label: (includeType ? `${subdivisionData[code].type}: ` : "") + `${subdivisionData[code].name}`,
        value: code,
      });
    });

    return _.sortBy(lookupOptions, ["value"]);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
