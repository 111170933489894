import { TAKU_BRAND } from "./white-label-brands/taku";
import { Branding } from "./white-label-brands/types";
import { VITAHEALTH_BRAND } from "./white-label-brands/vitahealth";

const getAppBranding = (): Branding => {
  switch (location.hostname) {
    // Debug case:
    // case "localhost":
    case "vitahealthpos.app":
      return VITAHEALTH_BRAND;
  }
  return TAKU_BRAND;
};

const APP_BRAND = getAppBranding();

export { APP_BRAND };

(window as any).brand = APP_BRAND;
