<div *ngIf="!results">
  <h2>No Accounts</h2>
</div>
<p-dataView
  *ngIf="results"
  [value]="results"
  scrollable="true"
  scrollHeight="460px"
  [rows]="10"
  [lazy]="true"
  (onLazyLoad)="loadData($event)"
>
  <ng-template let-searchedItem pTemplate="item">
    <div class="grid p-fluid car-item">
      <!-- <img src="assets/showcase/images/demo/car/{{car.brand}}.png"> -->
      <!-- <i class="fa fa-search" (click)="onSelectItem(searchedItem)" style="cursor:pointer;float:center;margin-top:15px"></i> -->
      <div class="add-item-col">
        <a (click)="onSelectItem(searchedItem)" style="cursor: pointer">
          <i class="material-icons">add_circle_outline</i>
        </a>
      </div>

      <div class="car-details">
        <taku-searchresult-line
          searchQuery=""
          [headline]="searchedItem.headline"
          [subline]="searchedItem.description"
          [imageIcon]="searchedItem.image"
        ></taku-searchresult-line>
      </div>
    </div>
    <hr />
  </ng-template>
</p-dataView>
