<taku-form-list
  #formListInventorySupplierProductDetails
  _model="inventorySupplierProductDetail"
  _detailMode="n"
  _viewColIcon="_"
  _viewColIconName="remove_red_eye"
  _dialogStyleClass="fullscreen-mobilemodal"
  [_dialogShowHeader]="false"
  [_cols]="_formListCols"
  _scrollHeight="full"
  _title="Purchasing Information"
  [_hideSavingCtrls]="true"
  [_showCtrlsInEditMode]="false"
  [_hideAddLineHeaderBtn]="true"
  [_hideOptionsHeaderBtn]="true"
  [_hideGlobalFilter]="true"
  [_hideAddHeaderBtn]="true"
  [_hideDeleteHeaderBtn]="true"
  [_hideClone]="true"
  [_showHeader]="true"
  [_rowSelectionType]="RowSelectionType.NONE"
  [_dataService]="localDataService"
  (_onRowValidityChanged)="formListItemChanged($event)"
>
</taku-form-list>
