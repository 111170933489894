/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { CurrencyPipe, getCurrencySymbol } from "@angular/common";
import * as _ from "lodash";

export class MonetaryHelpers {
  static pluralizeDenomination(amount: number, currencyIsoCode: string) {
    amount = parseFloat((amount + "").replace(/[^0-9.]/, ""));
    if (amount >= 1) {
      const symbol = getCurrencySymbol(currencyIsoCode, "narrow");
      return `${symbol}${amount}s`;
    } else {
      return amount * 100 + ` Cents`;
    }
  }

  static roundToDecimalPlaces(amount: number, decimalPlaces = 2): number {
    const power = Math.pow(10, decimalPlaces);
    return Math.round(amount * power) / power;
  }

  static roundToDecimalPlacesHalfUp(amount: number, decimalPlaces = 2): number {
    const power = Math.pow(10, decimalPlaces);
    return (
      Math.round(
        amount * power + (decimalPlaces > 0 ? 1 : 0) * (Math.sign(amount) * (10 / Math.pow(100, decimalPlaces)))
      ) / power
    );
  }

  static calculatePennyRounding(amount: number, rounding: number): number {
    const ratio = amount / rounding;
    const aproxRounding = rounding * (Math.round(ratio) - ratio);

    // Round to centecimals precision for monetary treatment
    return _.round(aproxRounding, 2);
  }

  static calculateRounding(amount: number, rounding: number): number {
    const ratio = amount / rounding;
    const aproxRounding = amount + rounding * (Math.round(ratio) - ratio);
    const decimalPart = rounding.toString().split(".")[1];
    const numDecimals = decimalPart ? decimalPart.length : 0;
    return _.round(aproxRounding, numDecimals);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
