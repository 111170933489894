import { Component } from "@angular/core";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { ShelfService } from "./shelf.service";
import { Col, RowSelectionType } from "src/app/form-list/form-list/form-list";
import { FilterRows } from "src/app/utility/FormDataHelpers";
import { Shelf } from "./shelf";

@Component({
  selector: "taku-shelf",
  templateUrl: "./shelf.component.html",
  styleUrls: ["./shelf.component.scss"],
})
export class ShelfComponent {
  stockId: number;
  formFilter: FilterRows<Shelf>;
  defaultNewRowValues: Record<string, unknown>;
  RowSelectionType = RowSelectionType;
  shelfColumns: Col[] = [];

  constructor(private shelfService: ShelfService, private dialogConfig: DynamicDialogConfig) {
    this.shelfColumns = this.shelfService.getFormListColumns();
    this.stockId = this.dialogConfig.data.stockId;
    this.formFilter = { stockId: { value: this.stockId, matchMode: "equals" } };
    this.defaultNewRowValues = { stockId: this.stockId };
  }
}
