<taku-form-header
  (backClicked)="goBack()"
  toolbarTitle="GMB Integration (Google My Business)"
  iconStyleClass="takuicon-google-integration mr-1"
  [showActionBtns]="false"
  [showBackBtn]="true"
  displayMode="inline"
></taku-form-header>

<p-panel id="gmbLocations-wrapper" class="grid col-12 card">
  <ng-template pTemplate="header">
    <span [innerHTML]="pageSubTitle"></span>
    <a href="javascript://" class="btn-gmblocations-refresh pi pi-refresh" (click)="refreshLocationsPressed()"></a>
  </ng-template>

  <taku-form-list
    [_model]="_model"
    _title="Google My Business Locations"
    _detailMode="n"
    _scrollHeight="full"
    [_hideOptionsHeaderBtn]="true"
    [_modelService]="_gmbLocationsService"
    [_requiredColsComeFirst]="false"
    [_filter]="_locationsFilter"
    (onComplete)="onGMBLocationsFetched()"
    [_newRowEvent]="showAccountSelectionDialog"
    [_defaultRowValues]="_defaultRowValues"
  >
  </taku-form-list>
</p-panel>

<ng-template
  #verificationColTpl
  let-rowData
  let-requestVerifEvent="data.requestVerifEvent"
  let-confirmVerifEvent="data.confirmVerifEvent"
>
  <div class="verification-status-col state-{{ rowData.verificationStatus | lowercase }}">
    <!-- <span class="verification-text">{{rowData.verificationStatus}}</span> -->
    <span [ngSwitch]="rowData.verificationStatus" class="verification-action">
      <span *ngSwitchCase="GMBLocationVerificationStatus.VERIFIED" class="verification-text">{{
        rowData.verificationStatus
      }}</span>
      <button
        *ngSwitchCase="GMBLocationVerificationStatus.UNVERIFIED"
        class="verification-btn"
        label="Request Verification"
        (click)="requestVerifEvent.emit(rowData)"
        >Request Verification</button
      >
      <button
        *ngSwitchCase="GMBLocationVerificationStatus.PENDING"
        class="verification-btn"
        label="Enter PIN Code"
        (click)="confirmVerifEvent.emit(rowData)"
      ></button>
    </span>
  </div>
</ng-template>
