/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { InventoryOptionsSettingValue } from "../inventory-options-setting-value/inventory-options-setting-value";

export class InventoryOptionsSetting {
  id: number;
  optionName: string;
  isActive: boolean;
  inventoryOptionsSettingValues: InventoryOptionsSettingValue[];

  constructor() {
    this.id = 0;
    this.optionName = "";
    this.isActive = true;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
