<div class="grid-card card" [ngStyle]="{ width: width + 'px', height: height + 'px' }">
  <div class="item-top" [ngClass]="{ 'short-img-height': _isMobile }">
    <div class="card-img">
      <img
        [attr.src]="
          (inventory?.inventoryImages && InventoryImagePinnedToPictureGallery?.urlFileName150) ||
          '../../../../assets/layout/images/no-image.jfif'
        "
      />
    </div>
    <!-- <i *ngIf="inventory.parentInventoryId" class="material-icons">photo_size_select_small</i>
    <i *ngIf="inventory.isVariantParent" class="material-icons">bookmarks</i>
    <i *ngIf="!inventory.parentInventoryId && !inventory.isVariantParent" class="material-icons">bookmark_border</i> -->
  </div>
  <div class="item-content mt-2 mb-1">
    <div class="inventory-name">
      <b>{{ inventory?.description1 }}</b>
    </div>
  </div>
  <div class="item-bottom">
    <div class="inventory-price">
      <span>
        {{ inventory.standardPrice | currency : "CAD" : "symbol-narrow" : "1.2" }}
      </span>
    </div>
    <!-- <div class="shopping-cart-badge-container">
      <i class="material-icons-outlined" pBadge severity="success" [value]="inventory.clickCount" style="font-size: 1.5rem">shopping_cart</i>
    </div> -->
  </div>
</div>
