/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import {
  distinctUntilChanged,
  tap,
  catchError,
  pairwise,
  startWith,
  debounceTime,
  switchMap,
  map,
} from "rxjs/operators";
import { Location } from "@angular/common";
import { Component, ElementRef, EventEmitter, HostListener, OnInit, TemplateRef, ViewChild } from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from "lodash";
import { ConfirmationService, MessageService, SelectItem } from "primeng/api";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { Observable, forkJoin, throwError } from "rxjs";
import type { Stock } from "src/app/core/settings/business-settings/stock/stock";
import type { ExtendedSelectItem, ExtendedSelectItemGroup } from "src/app/shared/services/db.service";
import { FormDataHelpers } from "src/app/utility/FormDataHelpers";
import { TimeHelpers } from "src/app/utility/TimeHelpers";
import { Col, RowSelectionType } from "../../../../form-list/form-list/form-list";
import { FormListComponent } from "../../../../form-list/form-list/form-list.component";
import { GenericFormComponent } from "../../../../forms/generic-form/generic-form.component";
import { AppSettingsStorageService } from "../../../../shared/app-settings-storage.service";
import { AlertMessagesService } from "../../../../shared/services/alert-messages.service";
import { LocalDataService } from "../../../../shared/services/local-data.service";
import { SearchResultItem } from "../../../../taku-ui/taku-search-accounts/SearchResultItem";
import { TakuSearchAccountsComponent } from "../../../../taku-ui/taku-search-accounts/taku-search-accounts.component";
import { TakuSearchInventoryComponent } from "../../../../taku-ui/taku-search-inventory/taku-search-inventory.component";
import { Account, AccountRelationship, AccountType } from "../../../contact-accounts/account/account";
import { CommercialAccount } from "../../../contact-accounts/commercial-account/commercial-account";
import { Inventory } from "../../../inventory/inventory/inventory";
import { DocState, InventoryDocType } from "../../doc/doc";
import { SelfCheckoutItemDetailsComponent } from "../../sale-document/self-checkout-item-details/self-checkout-item-details.component";
import { InventoryDocService } from "../inventory-doc-list/inventory-doc.service";
import { InventoryDoc, InventoryDocAccount, InventoryDocLine } from "./inventory-document";
import { DocLine } from "../../doc-line/doc-line";
import { MonetaryHelpers } from "src/app/utility/MonetaryHelpers";
import { DiscountType, TotalBySaleTax } from "../../sale-document/sale-doc-line/sale-doc-line";
import { TakuInputComponent } from "src/app/taku-ui/taku-input/taku-input.component";
import { InvoiceHelpers } from "src/app/utility/InvoiceHelpers";
import { InventorySupplierListService } from "./inventory-supplier-list.service";
import {
  PurchaseDoc,
  PurchaseDocLine,
  PurchaseDocLineTax,
} from "../../purchase-document/purchase-document/purchase-document";
import { ApiListResponse } from "src/app/utility/types";
import { InventorySupplier } from "src/app/core/inventory/inventory-supplier/inventory-supplier";
import { InventorySupplierProductDetail } from "src/app/core/inventory/inventory-supplier-product-detail/inventory-supplier-product-detail";

type InventoryDocLineFormListRow = { _row_local_id: string } & InventoryDocLine;

type BalanceComparision = {
  inventoryDocLine: InventoryDocLineFormListRow;
  purchaseDocLine: PurchaseDocLine;
  error: string;
};
import { PackSize } from "src/app/core/inventory/inventory-supplier-product-detail/inventory-supplier-product-detail";
import { HttpErrorResponse } from "@angular/common/http";
import { BackOffice } from "src/app/core/settings/backOffice-settings/backOffice/backOffice";
import { Store } from "src/app/core/settings/store-settings/store/store";
import { BusinessDetail } from "src/app/core/settings/business-settings/business-detail/business-detail";
import { ContactAccountBase } from "src/app/core/contact-accounts/account/contact-account-base";
import { PaymentTerm } from "src/app/core/purchase-order-term/payment-term/payment-term";
import { ShippingTerm } from "src/app/core/purchase-order-term/shipping-term/shipping-term";
import { Address, AddressType } from "src/app/core/contact-accounts/address/address";

enum FullSizeDialogName {
  COMMERCIAL_ACCOUNT = "commercialAccount",
  PERSONAL_ACCOUNT = "personalAccount",
  NEW_INVENTORY = "newInventory",
  EDIT_INVENTORY = "editInventory",
  VIEW_ERRORS = "viewErrors",
}

@Component({
  selector: "taku-inventory-document",
  templateUrl: "./inventory-document.component.html",
  styleUrls: ["./inventory-document.component.scss"],
  providers: [LocalDataService, InventorySupplierListService],
})
export class InventoryDocumentComponent extends GenericFormComponent implements OnInit {
  @ViewChild("formListInventory") formListInventory: FormListComponent;
  @ViewChild("accountsSearchBox") accountSearchComponent: TakuSearchAccountsComponent;
  @ViewChild("inventorySearchBox") inventorySearchComponent: TakuSearchInventoryComponent;
  @ViewChild("skuTpl", { static: true }) skuTpl: TemplateRef<any>;
  @ViewChild("supplierInventoryFormListModal") supplierInventoryFormListModal: FormListComponent;

  private _openInventoryEvent: EventEmitter<InventoryDocLine> = new EventEmitter();
  private _openErrorSummaryEvent: EventEmitter<InventoryDocLine> = new EventEmitter();
  @ViewChild("generalDiscountAmount") discAmountInputComponent: TakuInputComponent;
  @ViewChild("generalShippingAmount") shippingAmountInputComponent: TakuInputComponent;
  @ViewChild("generalCurrencyConversionRate") currencyConversionRateInputComponent: TakuInputComponent;

  parseFloat = parseFloat;
  discountForm: UntypedFormGroup;
  shippingForm: UntypedFormGroup;
  currencyConversionRateForm: UntypedFormGroup;
  selectPOsDialogVisible = false;
  loadPOsButtonDisabled = true;
  selectPOsToLoadTableDataFormGroup: UntypedFormGroup;
  _pageTitle: string;
  _defaultRowValues = {};
  _formFilters = {};
  inventoryDocLineCols: Col[];
  AccountType = AccountType;
  AccountRelationship = AccountRelationship;
  isSavingDraft = false;
  isFinalizing = false;
  enumDiscountType: SelectItem[] = [
    { label: "%", value: DiscountType.Percentage },
    { label: "$", value: DiscountType.Fixed },
  ];
  _overlaysVisibility: { [key: string]: boolean } = {
    discount: false,
    shipping: false,
    taxes: false,
    currencyConversionRate: false,
  };
  $lookup_billToLocations: Observable<ExtendedSelectItemGroup<string, Store | BackOffice>[]>;
  $lookup_paymentTerms: Observable<ExtendedSelectItem<number, PaymentTerm>[]>;
  $lookup_shippingTerms: Observable<ExtendedSelectItem<number, ShippingTerm>[]>;
  $lookup_stocks: Observable<ExtendedSelectItem<number, Stock>[]>;
  readonly _moneyFormat = "1.2";
  _decimalFormatting = "1.2-2";
  DocState = DocState;
  showFullSupplierListDialog = false;
  _showFullSupllierFormListCols: Col[];
  _showFullSupllierFormListRules: {};
  _isShowFullSupllierFormListDataReady = false;
  showFullSupllierFormListNoChildrenFilter: Record<string, unknown>;
  fullSupplierFormListExtraQueryParams: Record<string, any>;
  RowSelectionType = RowSelectionType;
  FullSizeDialogName = FullSizeDialogName;
  _activeFullDialog: FullSizeDialogName;
  _activeFullDialogExtra: Inventory | { balanceComparision: BalanceComparision[] } | CommercialAccount = null;
  _docLineOpened: any;
  inventoryDocTotalTax = 0;
  groupedTaxes: TotalBySaleTax[] = [];
  selectPOsToLoadTableLoading = false;
  isProceedLoading = false;
  private preFinalizedActionDocState = DocState.draft;

  get isReadOnly(): boolean {
    return (
      this.docStateCtrl?.value === DocState.approved ||
      this.docStateCtrl?.value === DocState.finalized ||
      this.appSettingsService.isAdminLogin()
    );
  }

  get _searchVendorPlaceholder() {
    return FormDataHelpers.InputFieldCaption(`Supplier`, this.accountIdCtrl);
  }

  constructor(
    public inventorySupplierListService: InventorySupplierListService,
    protected _router: Router,
    public fb: UntypedFormBuilder,
    public dbService: InventoryDocService,
    protected location: Location,
    public _route: ActivatedRoute,
    protected messageService: MessageService,
    protected alertMessage: AlertMessagesService,
    public localDataService: LocalDataService,
    protected confirmationService: ConfirmationService,
    appSettings: AppSettingsStorageService,
    public elRef: ElementRef,
    public ref: DynamicDialogRef,
    public dialogService: DialogService
  ) {
    super(_router, fb, dbService, location, _route, messageService, alertMessage, confirmationService, appSettings);
  }

  onNumpadShippingAmount(amount): void {
    this.shippingForm.get("shipperChargeAmount").setValue(amount);
  }

  onNumpadCurrencyConversionRate(amount): void {
    this.currencyConversionRateForm.get("currencyConversionRate").setValue(amount);
  }

  onShippingApplyClick(): void {
    const shipperChargeAmount = +this.shippingForm.get("shipperChargeAmount").value;
    this._myForm.get("shipperChargeAmount").setValue(shipperChargeAmount);

    let totalCost = 0;
    let totalQty = 0;

    const objects = this.formListInventory._objects;
    const objectCount = objects.length;

    objects.forEach((object) => {
      totalCost += object.totalCost;
      totalQty += object.totalQty;
    });

    if (totalCost === 0 && totalQty === 0) {
      const equalShare = MonetaryHelpers.roundToDecimalPlaces(shipperChargeAmount / objectCount);
      objects.forEach((object) => {
        object.allocatedShipperChargeAmount = equalShare;
      });
    } else {
      const divisor = totalCost > 0 ? totalCost : totalQty;
      const field = totalCost > 0 ? "totalCost" : "totalQty";

      objects.forEach((object) => {
        object.allocatedShipperChargeAmount = MonetaryHelpers.roundToDecimalPlaces(
          (shipperChargeAmount * object[field]) / divisor
        );
      });
    }

    this._myForm.markAsDirty();
  }

  onCurrencyConversionRateApplyClick(): void {
    const currencyConversionRate = +this.currencyConversionRateForm.get("currencyConversionRate").value;
    this._myForm.get("doc.currencyConversionRate").setValue(currencyConversionRate);

    this._myForm.markAsDirty();

    this.onSaveDraft();
  }

  protected override prepareSaveObject(): any {
    const saveObject = super.prepareSaveObject();
    // remove unneccessary items from saveObject before pushing for save or insert
    delete saveObject["account"];
    delete saveObject["doc"]["user"];
    saveObject.inventoryDocLines.forEach((inventoryDocLine) => {
      delete inventoryDocLine["docReference"];
      delete inventoryDocLine.docLine["inventory"];
      delete inventoryDocLine.docLine["user"];
      inventoryDocLine.inventoryDocLineTaxes.forEach((inventoryDocLineTax) => {
        delete inventoryDocLineTax["taxRule"];
      });
      inventoryDocLine.qty = inventoryDocLine.qty || 0;
      inventoryDocLine.unitPrice = inventoryDocLine.unitPrice || 0;
    });
    delete saveObject["stock"];
    delete saveObject["zone"];

    return saveObject;
  }

  onChangeDiscType(): void {
    InvoiceHelpers.switchDiscountType(
      this._myForm.get("discountType"),
      this._myForm.get("discountAmount"),
      this._myForm.get("subTotal"),
      this.confirmationService,
      "Stock Receipt Subtotal"
    );

    setTimeout(() => {
      if (this.discAmountInputComponent) this.discAmountInputComponent.focusAndSelectInput();
    }, 100);
  }

  updateInventoryDocTotalTax(): void {
    this.attachFormListRowsTo_myForm();
    this.groupedTaxes = InventoryDoc.convertIntoTypedObject(this._myForm.value).groupedTaxLines;
    this.inventoryDocTotalTax = _.sumBy(this.groupedTaxes, (tax) => tax.total);
  }

  protected resetForm(): void {
    super.resetForm();

    this._myForm.controls.locationId.setValue(this.getLocationId());

    this.discountForm = this.fb.group({
      discountType: this._myForm.get("discountType").value,
      discountAmount: this._myForm.get("discountAmount").value,
    });
    this.shippingForm = this.fb.group({
      shipperChargeAmount: this._myForm.get("shipperChargeAmount").value,
    });

    this.currencyConversionRateForm = this.fb.group({
      currencyConversionRate: this._myForm.get("doc.currencyConversionRate").value,
    });

    if (this.formListInventory) {
      this.formListInventory.clearAllRows();
      this.populateInventoryList();
      setTimeout(() => {
        this._myForm.markAsPristine(); // calling the two methods above makes the _myForm dirty.
      }, 500);
    }
  }

  ngOnInit(): void {
    if (!this._route.snapshot.queryParams.defaultdataValues) {
      this.initFieldsExtraData();
    }
  }

  get inventoryDocTotal(): number {
    const total =
      this.inventoryDocSubTotal -
      Number(this.inventoryDocGeneralDiscount || 0) +
      Number(this._myForm.get("shipperChargeAmount")?.value || 0) *
        Number(this._myForm.get("doc.currencyConversionRate")?.value || 1) +
      this.inventoryDocTotalTax;
    return total;
  }

  onDiscountApplyClick(): void {
    const discountType = this.discountForm.get("discountType").value;
    const discountAmount = +this.discountForm.get("discountAmount").value;

    this._myForm.get("discountType").setValue(discountType);
    this._myForm.get("discountAmount").setValue(discountAmount);

    const prev = _.cloneDeep(this.formListInventory._objects);
    const objects = this.formListInventory._objects;

    objects.forEach((object) => {
      object.allocatedDiscountAmount = 0;
    });

    if (discountType === DiscountType.Percentage) {
      objects.forEach((object) => {
        object.allocatedDiscountType = DiscountType.Percentage;
        object.allocatedDiscountAmount = discountAmount;
      });
    } else if (discountType === DiscountType.Fixed) {
      let totalCost = 0;
      let totalQty = 0;

      const objectCount = objects.length;

      objects.forEach((object) => {
        totalCost += object.totalCost;
        totalQty += object.totalQty;
      });

      if (totalCost === 0 && totalQty === 0) {
        const equalShare = MonetaryHelpers.roundToDecimalPlaces(discountAmount / objectCount);
        objects.forEach((object) => {
          object.allocatedDiscountType = DiscountType.Fixed;
          object.allocatedDiscountAmount = equalShare;
        });
      } else {
        const divisor = totalCost > 0 ? totalCost : totalQty;
        const field = totalCost > 0 ? "totalCost" : "totalQty";

        objects.forEach((object) => {
          object.allocatedDiscountType = DiscountType.Fixed;
          object.allocatedDiscountAmount = MonetaryHelpers.roundToDecimalPlaces(
            (discountAmount * object[field]) / divisor
          );
        });
      }
    }

    this.inventoryDocLineformListTaxDataChanged(prev);
    this._myForm.markAsDirty();
  }

  onNumpadDiscountAmount(amount): void {
    this.discountForm.get("discountAmount").setValue(amount);
  }

  onDocLineDeleted(): void {
    this._updateForm();
    this._object.inventoryDocLines = this.formListInventory.getObjects();
    this._myForm.markAsDirty();
  }

  updateInventoryDocLineTax(inventoryDocLine: InventoryDocLine): any {
    const inventoryDoc: InventoryDoc = this._myForm.getRawValue();

    this.subsList.push(this.dbService.updateInventoryDocTaxesInLineForm(inventoryDocLine, inventoryDoc).subscribe());
  }

  get maxDiscountAmount(): number {
    switch (this._myForm.get("discountType").value) {
      case DiscountType.Fixed:
        return this.inventoryDocSubTotal;

      case DiscountType.Percentage:
        return 100;
    }
  }

  initForm(): void {
    this._model = "inventoryDoc";

    const inventoryDoc = new InventoryDoc(null, null, this.dbService.getDefaultZone());
    const [currentDate, currentTime] = TimeHelpers.SplitDateAndTime(new Date());

    inventoryDoc.doc.docDate = currentDate;
    inventoryDoc.doc.docTime = currentTime;

    this._object = inventoryDoc;
    // Assign default inventory doc fields
    _.merge(this._object, {
      doc: {
        userId: this.dbService.getCurrentUserId(),
      },
    });

    // Merge query parameters from the route into _object
    const queryParams = this._route.snapshot.queryParams;
    if (queryParams.defaultdataValues) {
      try {
        const parsedQueryParamData = JSON.parse(queryParams.defaultdataValues);

        const commercialAccountId = parsedQueryParamData.commercialAccountId;

        this.subsList.push(
          this.appSettingsService
            .getBusinessDetails()
            .pipe(
              switchMap((businessDetail: BusinessDetail) => {
                const defaultCurrencyIsoCode = businessDetail.defaultCurrencyIsoCode;
                return this.dbService.getRow("commercialAccount", commercialAccountId).pipe(
                  tap((commercialAccount: CommercialAccount) => {
                    const data = {};
                    if (commercialAccount.hasOwnProperty("account")) {
                      const convertedAccount = ContactAccountBase.ConvertIntoTypedObject(
                        commercialAccount,
                        defaultCurrencyIsoCode
                      );
                      data["accountId"] = convertedAccount.account.id;
                      data["account"] = _.pick(convertedAccount.account, _.keys(new Account())) as InventoryDocAccount;
                      data["account"]["commercialAccount"] = _.pick(
                        convertedAccount,
                        _.keys(new CommercialAccount(defaultCurrencyIsoCode))
                      );
                    }
                    _.merge(data, parsedQueryParamData);
                    _.merge(this._object, data);

                    this.initMyFormProp();
                    this.initFieldsExtraData();
                  })
                );
              })
            )
            .subscribe()
        );
      } catch (error) {
        console.error("Error parsing defaultdataValues from query parameters", error);
      }
    } else {
      this.initMyFormProp();
    }
  }

  private getLocationId(): string | null {
    if (this._object.storeId) {
      return "s" + String(this._object.storeId);
    } else if (this._object.backOfficeId) {
      return "b" + String(this._object.backOfficeId);
    } else if (!this.isReadOnly) {
      if (this.appSettingsService.getStoreId()) {
        return "s" + String(this.appSettingsService.getStoreId());
      } else if (this.appSettingsService.getBackofficeId()) {
        return "b" + String(this.appSettingsService.getBackofficeId());
      }
    }
    return null;
  }

  private initMyFormProp() {
    const locationId = this.getLocationId();
    this._myForm = InventoryDocumentComponent.init(this.fb, this._object, locationId);

    this.discountForm = this.fb.group({
      discountType: this._myForm.get("discountType").value,
      discountAmount: this._myForm.get("discountAmount").value,
    });
    this.shippingForm = this.fb.group({
      shipperChargeAmount: this._myForm.get("shipperChargeAmount").value,
    });

    this.subsList.push(
      this.changedForm.subscribe((invDoc: InventoryDoc) => {
        if (!this.formListInventory) return;

        if (this.isReadOnly) this.formListInventory.disableEditMode();
        else this.formListInventory.enableEditMode();
      })
    );

    this.subsList.push(
      this._myForm.controls.locationId.valueChanges.subscribe((locationId: string) => {
        // Optionally, if you need to update the form control based on this new field
        if (!locationId) {
          this._myForm.get("storeId").setValue(null);
          this._myForm.get("backOfficeId").setValue(null);
        } else {
          if (locationId[0] === "s") {
            this._myForm.get("storeId").setValue(Number(locationId.substring(1)));
            this._myForm.get("backOfficeId").setValue(null);
          }
          if (locationId[0] === "b") {
            this._myForm.get("backOfficeId").setValue(Number(locationId.substring(1)));
            this._myForm.get("storeId").setValue(null);
          }
        }
      })
    );

    const purchaseDocFormArray = this.fb.array([]);

    this.selectPOsToLoadTableDataFormGroup = this.fb.group({
      markAllAsReceived: false,
      purchaseDocs: purchaseDocFormArray,
    });

    this.subsList.push(
      this.selectPOsToLoadTableDataFormGroup.controls.purchaseDocs.valueChanges.subscribe((formGroups) => {
        this.loadPOsButtonDisabled = !formGroups.some((formGroup) => formGroup?.isSelected);
      })
    );
  }

  private initFieldsExtraData() {
    this.subsList.push(
      this._openInventoryEvent.subscribe((inventoryDocLine: InventoryDocLine) => {
        this._docLineOpened = inventoryDocLine;
        const inventoryId = inventoryDocLine.docLine.inventory.id;

        this.subsList.push(
          this.dbService.getRow("inventory", inventoryId).subscribe((inventory) => {
            this.openFullSizeDialog(FullSizeDialogName.EDIT_INVENTORY, inventory);
          })
        );
      })
    );

    this.subsList.push(
      this._myForm.controls.stockId.valueChanges
        .pipe(startWith(this._myForm.controls.stockId.value), distinctUntilChanged())
        .subscribe((stockId: number) => {
          this.fullSupplierFormListExtraQueryParams = { stockId };
          this.inventorySupplierListService.selectedStockId = stockId;
        })
    );

    this.subsList.push(
      this._route.data.subscribe((routeData) => {
        this._pageTitle = routeData.pageTitle || "";

        if (routeData.docType) this._object.doc.docType = routeData.docType;

        if (routeData.stockAdjustmentType) this._object.stockAdjustmentType = routeData.stockAdjustmentType;

        this.resetForm();

        // call parent's method
        super.ngOnInit();
      })
    );

    this.subsList.push(
      this.inventorySupplierListService.addToRecieptFormListEvent.subscribe((inventory: Inventory) => {
        this._addInventoryItem(inventory);
      })
    );

    this.inventoryDocLineCols = this.dbService.inventoryDocLineService.getFormListColumns({
      skuTemplateOptions: {
        data: {
          showInventoryEvent: this._openInventoryEvent,
        },
        templateRef: this.skuTpl,
      },
    });

    setTimeout(() => {
      this.populateInventoryList();
      this.updateInventoryDocTotalTax();
      this._updateForm();

      setTimeout(() => {
        this.subsList.push(
          this.formListInventory._formGroups.valueChanges
            .pipe(
              startWith(this.formListInventory._formGroups.value),
              distinctUntilChanged((prev, curr) => {
                // Create deep copies of prev and curr
                let prevCopy = _.cloneDeep(prev);
                let currCopy = _.cloneDeep(curr);

                // Remove '_row_local_id' from each item in the copies
                prevCopy.forEach((item) => delete item["_row_local_id"]);
                currCopy.forEach((item) => delete item["_row_local_id"]);

                // Compare the modified copies
                return _.isEqual(prevCopy, currCopy);
              }), // Only emit when the current value is different than the last
              debounceTime(500),
              pairwise()
            )
            .subscribe(([prev, curr]: [InventoryDocLineFormListRow[], InventoryDocLineFormListRow[]]) => {
              this.inventoryDocLineFormListDataChanged(prev, this.formListInventory.getObjects());
              this.onDiscountApplyClick();
              this.onShippingApplyClick();
              this.inventoryDocLineformListTaxDataChanged(prev);
            })
        );
        this.populateSearchFields();
        // Mark dirty on first page load of new
        if (this._route.snapshot.queryParams.defaultdataValues) {
          this._myForm.markAsDirty();
        }
      }, 100);
    }, 0);
  }

  private inventoryDocLineformListTaxDataChanged(prev: InventoryDocLineFormListRow[]) {
    const inventoryDocLineByIdPrev: Record<string, InventoryDocLineFormListRow> = prev.reduce(
      (inventoryDocLineById, inventoryDocLine) => {
        inventoryDocLineById[inventoryDocLine._row_local_id] = inventoryDocLine;
        return inventoryDocLineById;
      },
      {}
    );

    this.formListInventory.getObjects().forEach((inventoryDocLine: InventoryDocLineFormListRow) => {
      // Only sync if the PackSize changed, or if it is a brand new row
      this.syncTaxWhenUnitCostChangedOrNewRowAdded(inventoryDocLineByIdPrev, inventoryDocLine);
    });
    setTimeout(() => {
      this.updateInventoryDocTotalTax();
    }, 1000);
  }

  private inventoryDocLineFormListDataChanged(
    prev: InventoryDocLineFormListRow[],
    curr: InventoryDocLineFormListRow[]
  ): void {
    const inventoryDocLineByIdPrev: Record<string, InventoryDocLineFormListRow> = prev.reduce(
      (inventoryDocLineById, inventoryDocLine) => {
        inventoryDocLineById[inventoryDocLine._row_local_id] = inventoryDocLine;
        return inventoryDocLineById;
      },
      {}
    );

    curr
      .filter((inventoryDocLineCurr) => {
        const inventoryDocLinePrev = inventoryDocLineByIdPrev[inventoryDocLineCurr._row_local_id];
        // Return true if it is a new row or if it has changed compared to the previous state
        return (
          (!inventoryDocLinePrev || inventoryDocLinePrev.packSize !== inventoryDocLineCurr.packSize) &&
          inventoryDocLineByIdPrev.hasOwnProperty(inventoryDocLineCurr._row_local_id)
        );
      })
      .forEach((inventoryDocLine: InventoryDocLineFormListRow) => {
        const inventorySupplierProductDetailOfNewPackSize = inventoryDocLine.docLine.inventory.inventorySuppliers
          .find(
            (inventorySupplier: InventorySupplier) =>
              inventorySupplier.accountId == this._myForm.controls.accountId.value
          )
          ?.inventorySupplierProductDetails.find(
            (inventorySupplierProducDetail: InventorySupplierProductDetail) =>
              inventorySupplierProducDetail.packSize === inventoryDocLine.packSize
          );

        inventoryDocLine.unitCost = inventorySupplierProductDetailOfNewPackSize?.supplierCost || 0;
      });
  }

  private syncTaxWhenUnitCostChangedOrNewRowAdded(
    inventoryDocLineByIdPrev: Record<string, InventoryDocLineFormListRow>,
    inventoryDocLine: InventoryDocLineFormListRow
  ) {
    const isNewRow = !inventoryDocLineByIdPrev.hasOwnProperty(inventoryDocLine._row_local_id);
    const unitCostChanged =
      +inventoryDocLineByIdPrev[inventoryDocLine._row_local_id]?.unitCost *
        inventoryDocLineByIdPrev[inventoryDocLine._row_local_id]?.qty !=
      +inventoryDocLine.unitCost * inventoryDocLine.qty;
    const discountTypeChanged =
      inventoryDocLineByIdPrev[inventoryDocLine._row_local_id]?.allocatedDiscountType !=
      inventoryDocLine.allocatedDiscountType;
    const discountAmountChanged =
      inventoryDocLineByIdPrev[inventoryDocLine._row_local_id]?.allocatedDiscountAmount !=
      inventoryDocLine.allocatedDiscountAmount;

    if (isNewRow || unitCostChanged || discountTypeChanged || discountAmountChanged) {
      this.updateInventoryDocLineTax(inventoryDocLine);
    }
  }

  static init(fb: UntypedFormBuilder, inventoryDoc: InventoryDoc, storeOrBackOfficeId?: string): UntypedFormGroup {
    const tmpForm = fb.group(inventoryDoc);
    const tempAccountFormGroup = fb.group(inventoryDoc.account);
    tempAccountFormGroup.setControl("commercialAccount", fb.group(inventoryDoc.account.commercialAccount));
    tmpForm.setControl("account", tempAccountFormGroup);
    tmpForm.setControl("stock", fb.group(inventoryDoc.stock));
    tmpForm.setControl("doc", fb.group(inventoryDoc.doc));
    tmpForm.setControl("inventoryDocLines", fb.array([]));
    tmpForm.setControl("locationId", new FormControl(storeOrBackOfficeId, { nonNullable: true }));
    tmpForm.setControl("zone", fb.group(inventoryDoc.zone));

    return tmpForm;
  }

  get inventoryDocGeneralDiscount(): number {
    const discountType = this._myForm.get("discountType").value;
    const discountAmount = this._myForm.get("discountAmount").value;
    let totalDiscount = 0;

    // If discount amount is empty, just return 0
    if (discountAmount === "" || discountAmount === null) return 0;

    switch (discountType) {
      case DiscountType.Fixed:
        totalDiscount = discountAmount * this._myForm.get("doc.currencyConversionRate").value;
        break;
      case DiscountType.Percentage:
        // Calculate the discount based on the subtotal without discount
        totalDiscount = 0;
        if (this.formListInventory) {
          const docLines: InventoryDocLine[] = this.formListInventory.getObjects();
          if (docLines)
            docLines.forEach(
              (inventoryDocLine) =>
                (totalDiscount += MonetaryHelpers.roundToDecimalPlaces(
                  inventoryDocLine.totalDiscountAmount * this._myForm.get("doc.currencyConversionRate").value
                ))
            );
        }
        break;
    }
    // Round to only 2 decimal places
    return MonetaryHelpers.roundToDecimalPlaces(totalDiscount);
  }

  get inventoryDocSubTotal(): number {
    let subTotal = 0;
    if (this.formListInventory) {
      const docLines: InventoryDocLine[] = this.formListInventory.getObjects();
      if (docLines) docLines.forEach((inventoryDocLine) => (subTotal += inventoryDocLine.subTotal));
    }
    return subTotal;
  }

  populateSearchFields(): any {
    this.accountSearchComponent.textSearch = this._object.account?.commercialAccount?.name;
  }

  populateInventoryList(): void {
    for (let docLine of this._object.inventoryDocLines.sort((a, b) => a.docLine.seqNo - b.docLine.seqNo)) {
      docLine = Object.assign(new InventoryDocLine(this._object), docLine);
      this.formListInventory.addNewRow(docLine, false);
    }
    this.localDataService._allLocalData["inventoryDocLine"] = this.formListInventory.getObjects();
    if (this.isReadOnly) this.formListInventory.disableEditMode();
  }

  showFullSupplierListDialogClicked(): void {
    this.initShowFullSupplierFormList();
    this.showFullSupplierListDialog = true;
  }

  private initShowFullSupplierFormList() {
    this.showFullSupllierFormListNoChildrenFilter = {
      isVariantParent: { matchMode: "equals", value: false },
      "inventorySuppliers.accountId": { matchMode: "equals", value: this._myForm.controls.accountId.value },
      isBuyable: { matchMode: "equals", value: true },
      isEnabled: { matchMode: "equals", value: true },
    };

    this._showFullSupllierFormListCols = this.inventorySupplierListService.getFormListColumns();

    this._showFullSupllierFormListRules = this.inventorySupplierListService.getValidationRules();
    this._isShowFullSupllierFormListDataReady = true;
  }

  initLookups(): void {
    this.$lookup_stocks = this.dbService.get_lookup_stocks();
    this.$lookup_paymentTerms = this.dbService.get_lookup_paymentTerms();
    this.$lookup_shippingTerms = this.dbService.get_lookup_shippingTerms();
    this.$lookup_billToLocations = forkJoin({
      stores: this.dbService.get_lookup_stores().pipe(
        map((stores) =>
          stores
            .filter((store) => store.value != null)
            .map((store) => ({ ...store, value: "s" + store.value, fieldName: "storeId" }))
            .sort((a, b) => a.label.localeCompare(b.label))
        )
      ),
      backOffices: this.dbService.get_Lookup_backOffices().pipe(
        map((backoffices) =>
          backoffices
            .filter((backoffice) => backoffice.value != null)
            .map((backOffice) => ({ ...backOffice, value: "b" + backOffice.value, fieldName: "backOfficeId" }))
            .sort((a, b) => a.label.localeCompare(b.label))
        )
      ),
    }).pipe(
      map(({ stores, backOffices }) => {
        // Combine and structure as groups, here using a simple grouping logic
        return [
          {
            label: "Stores",
            value: null,
            items: stores,
          },
          {
            label: "Back Offices",
            value: null,
            items: backOffices,
          },
        ];
      })
    );
  }

  get docCurrencyControl(): AbstractControl {
    return this._myForm.get("doc.currencyIsoCode");
  }

  @HostListener("window:click", ["$event"])
  closeOverlayOnOutsideClick(e: MouseEvent) {
    const queryOverlay = `${(<HTMLElement>this.elRef.nativeElement).tagName} .overlay-container`;
    // Close all the summary overlays only when the click was within the App's layout container (ex. NOT inside a dialog/modal) AND outside an overlay's contents
    if (e.target instanceof HTMLElement && e.target.closest("app-root") && !e.target.closest(queryOverlay))
      this.toggleOverlay(null);
  }

  toggleOverlay(overlayName: string, event?: MouseEvent): void {
    if (event) event.stopImmediatePropagation();

    for (const key in this._overlaysVisibility) {
      if (key === overlayName) {
        this._overlaysVisibility[key] = !this._overlaysVisibility[key];
        switch (key) {
          case "shipping":
            this.shippingForm.get("shipperChargeAmount").setValue(this._myForm.get("shipperChargeAmount").value);
            break;
          case "discount":
            this.discountForm.get("discountType").setValue(this._myForm.get("discountType").value);
            this.discountForm.get("discountAmount").setValue(this._myForm.get("discountAmount").value);
            break;
          case "currencyConversionRate":
            this.currencyConversionRateForm
              .get("currencyConversionRate")
              .setValue(this._myForm.get("doc.currencyConversionRate").value);
            break;

          default:
            break;
        }
      } else {
        this._overlaysVisibility[key] = false;
      }
    }
  }

  onDiscAmountClicked(): void {
    setTimeout(() => {
      this.discAmountInputComponent.focusAndSelectInput();
    }, 0);
  }

  onShippingAmountClicked(): void {
    setTimeout(() => {
      this.shippingAmountInputComponent.focusAndSelectInput();
    }, 0);
  }

  onCurrencyConversionRateClicked(): void {
    setTimeout(() => {
      this.currencyConversionRateInputComponent.focusAndSelectInput();
    }, 0);
  }

  setZeroIfEmpty(_formControlName: string): void {
    this._myForm.get(_formControlName).setValue(this._myForm.get(_formControlName).value || 0);
  }

  closeOverlay(overlayName): void {
    this._overlaysVisibility[overlayName] = false;
  }

  onSearchResAccount(accountResult: SearchResultItem): void {
    const accountId = accountResult.ID;
    this.accountIdCtrl.setValue(accountId);
    this.accountIdCtrl.markAsDirty();
  }

  get accountIdCtrl(): AbstractControl {
    return this._myForm?.get("accountId");
  }

  get docStateCtrl(): FormControl<string> {
    return this._myForm?.get("doc.state") as UntypedFormControl;
  }

  onSearchResInventory(inventoryResult: SearchResultItem): void {
    this._addInventoryItem(inventoryResult.data);
  }

  onSaveDraft(): void {
    this.isSavingDraft = true;
    this.isFinalizing = false;
    this._isSaving = true;
    const inventoryDocLinesEmptyQtys: InventoryDocLine[] = this.formListInventory
      .getObjects()
      .filter((inventoryDocLine) => !Number(inventoryDocLine.qty));

    if (inventoryDocLinesEmptyQtys.length > 0) {
      inventoryDocLinesEmptyQtys.forEach((inventoryDocLine) => {
        if (!Number(inventoryDocLine.qty)) {
          inventoryDocLine.qty = 0;
        }
      });
    }

    this.attachFormListRowsTo_myForm();
    this.onSave();
    this.formListInventory.unSelectAllRows();

    /*
    if (inventoryDocLinesEmptyQtys.length > 0) {
      this.confirmationService.confirm({
        header: "Confirmation",
        message: "There are empty fields in the Quantity Received. Do you want to enter 0 for all empty fields?",
        acceptLabel: "Yes",
        rejectLabel: "Cancel",
        rejectVisible: true,
        acceptVisible: true,
        rejectButtonStyleClass: "p-button-link",
        accept: () => {
          inventoryDocLinesEmptyQtys.forEach((inventoryDocLine) => {
            if (!Number(inventoryDocLine.qty)) {
              inventoryDocLine.qty = 0;
            }
          });
          this.attachFormListRowsTo_myForm();
          this.onSave();
        },
        reject: () => {
          // Do nothing
          this._isSaving = false;
        },
      });
    } else {
      this.attachFormListRowsTo_myForm();
      this.onSave();
    }
    */
  }

  onVoid(): void {
    this._changeDocState(DocState.voided);

    this.onSave();
  }

  private onServerError(errorResponse: HttpErrorResponse): boolean {
    if (this.docStateCtrl.value === DocState.finalized && this.preFinalizedActionDocState !== this.docStateCtrl.value) {
      this.docStateCtrl.setValue(this.preFinalizedActionDocState);
      setTimeout(() => {
        this.docStateCtrl.markAsPristine();
      }, 1000);

      if (errorResponse.status === 409 && errorResponse.error?.body?.hasOwnProperty("balanceComparision")) {
        //this.messageService.add(this.alertMessage.getMessage("stock-receiving-without-purchase-order-warning"));
        if (this._activeFullDialog === FullSizeDialogName.VIEW_ERRORS) {
          this._activeFullDialogExtra = errorResponse.error?.body;
          this.forceFinalize(this._activeFullDialogExtra as { balanceComparision: BalanceComparision[] });
        } else {
          this.openFullSizeDialog(FullSizeDialogName.VIEW_ERRORS, errorResponse.error?.body);
        }
        return true;
      }
    }
    return false;
  }

  onDonePressed(): void {
    this.isSavingDraft = false;
    this.isFinalizing = true;
    this.formListInventory._isFetchingData = true;
    const inventoryDocLinesEmptyQtys: InventoryDocLine[] = this.formListInventory
      .getObjects()
      .filter((inventoryDocLine) => !inventoryDocLine.qty);

    if (inventoryDocLinesEmptyQtys.length > 0) {
      this.confirmationService.confirm({
        header: "Confirmation",
        message: "There are empty or 0 fields in the Quantity Received.\nDo you want to enter 1 for all empty fields?",
        key: "confirmationDialog",
      });
    } else {
      if (this.docStateCtrl.value === DocState.draft) {
        this.preFinalizedActionDocState = this.docStateCtrl.value;
        this._changeDocState(DocState.finalized);
      }
      this.attachFormListRowsTo_myForm();
      this.onSave();
    }
  }

  _changeDocState(docState: DocState): void {
    this.docStateCtrl.setValue(docState);
    this.docStateCtrl.markAsDirty();
  }

  private _updateForm() {
    // update subtotal in form
    this._myForm.get("subTotal").setValue(this.inventoryDocSubTotal);
    // Attach form list objects to form
    this.attachFormListRowsTo_myForm();
  }

  onSave(): void {
    this._updateForm();
    super.onSave();
    this.formListInventory.markAllAsPristine();
  }

  override processServerError(errorResponse: HttpErrorResponse, silentMode = false): void {
    const isOverbalanceError = this.onServerError(errorResponse);
    if (!isOverbalanceError) {
      super.processServerError(errorResponse, silentMode);
    }
  }

  clearAccountField() {
    this.accountIdCtrl.reset();
  }

  initValidation() {
    this._validation = this.dbService.getValidationRules();
  }

  get isInvalidForm(): boolean {
    return this._myForm.invalid || (this.formListInventory && !this.formListInventory.isValid());
  }

  get isPristineForm(): boolean {
    return this._myForm.pristine && this.formListInventory && this.formListInventory.isPristine();
  }

  get inventoryLinesFormArray() {
    return <UntypedFormArray>this._myForm.get("inventoryDocLines");
  }

  discountAmountMoney(purchaseDoc: PurchaseDoc): number {
    if (purchaseDoc.discountType === DiscountType.Fixed) {
      return purchaseDoc.discountAmount;
    } else {
      return (purchaseDoc.subTotal * purchaseDoc.discountAmount) / 100;
    }
  }

  /**
   * Copy data from child formlist to _myForm, so that when _myForm is saved, it has the data from the form list rows.
   * This method should be used right before saving, or when a change to the form list needs to be reflected in the _myForm
   */
  private attachFormListRowsTo_myForm(): any {
    const isFormPristine = this.formListInventory.isPristine();
    const docLines: InventoryDocLine[] = this.formListInventory.getObjects();

    const formArray = docLines.map((inventoryDocLine) => {
      const docLineForm = this.fb.group({
        ...inventoryDocLine,
        docLine: this.fb.group(inventoryDocLine.docLine),
        inventoryDocLineTaxes: this.fb.array(inventoryDocLine.inventoryDocLineTaxes.map((tax) => this.fb.group(tax))),
      });
      return docLineForm;
    });

    this._myForm.setControl("inventoryDocLines", this.fb.array(formArray));

    if (!isFormPristine) {
      this.inventoryLinesFormArray.markAsDirty();
    }
  }

  openNewModelInDialog(defaultData: any) {
    let dialogName: FullSizeDialogName;

    if (defaultData instanceof CommercialAccount) {
      dialogName = FullSizeDialogName.COMMERCIAL_ACCOUNT;
    } else if (defaultData instanceof Inventory) {
      dialogName = FullSizeDialogName.NEW_INVENTORY;
    }

    this.openFullSizeDialog(dialogName, defaultData);
  }

  onNewCommercialAccountCreated(commecialAccount: CommercialAccount) {
    this.accountSearchComponent.textSearch = commecialAccount.name;
    this.accountIdCtrl.setValue(commecialAccount.account.id);
    this.accountIdCtrl.markAsDirty();
    this.closeFullSizeDialog();
  }

  onNewInventoryItem(inventory: Inventory) {
    this._addInventoryItem(inventory);
    this.closeFullSizeDialog();
  }

  private _addInventoryItem(
    inventory: Inventory,
    options: { refNo?: number; unitPrice?: number; remainingQty?: number; packSize?: PackSize } = {
      refNo: null,
      unitPrice: 0,
      remainingQty: null,
      packSize: PackSize.SKU,
    }
  ) {
    const { refNo = null, unitPrice = 0, remainingQty = null, packSize = PackSize.SKU } = options;
    if (this.formListInventory.getObjects().length >= 1000) {
      // Limit items so it doesn't flow into a 2nd page, which causes issues overwriting other pages of data (only currently viewed page is persisted).
      this.messageService.add({
        severity: "warn",
        summary: "Too many items",
        detail: "Limit of 1000 line items per receiving document",
        sticky: true,
      });
      return;
    }

    if (inventory.isVariantParent) {
      const invDocLine: InventoryDocLine = _.merge(new InventoryDocLine(this._object), {
        unitCost: inventory.standardPrice,
        qty: remainingQty,
        packSize: packSize,
        // TODO: Fill out DocLine
        docLine: {
          id: 0,
          seqNo: 1,
          note: "",
          expiryDate: null,
          serialNo: "",
          refNo: refNo,
          inventoryId: inventory.id,
          inventory: inventory,
        } as DocLine,
      } as Partial<InventoryDocLine>);
      const invDocLineForm = this.fb.group(invDocLine);
      const docLineForm = this.fb.group(invDocLine.docLine);
      invDocLineForm.setControl("docLine", docLineForm);

      this.ref = this.dialogService.open(SelfCheckoutItemDetailsComponent, {
        styleClass: "selfcheckout-mobile rounded-selfcheckout-dialog",
        contentStyle: {
          overflow: "auto",
          height: "60%",
          minWidth: "280px",
          maxWidth: "680px",
          maxHeight: "1100px",
        },
        data: {
          _saleForm: invDocLineForm,
          _isAddToCart: true,
          _showSalePrice: false,
          _doneButtonCaption: "Done",
        },
      });

      this.subsList.push(
        this.ref.onClose.subscribe((result) => {
          if (result && result.qtySelected > 0) {
            // this.docComponent.addToSaleDoc(docLineForm.value.docLine.inventory, result.qtySelected);
            inventory = invDocLineForm.get("docLine.inventory").value;
            const invDocLine = new InventoryDocLine(this._object);
            _.merge(invDocLine, {
              docLine: {
                refNo: refNo,
                inventoryId: inventory.id,
                inventory: inventory,
                userId: this.dbService.getCurrentUserId(),
                seqNo:
                  (this.formListInventory._objects.length
                    ? Math.max(
                        ...this.formListInventory._objects.map((row) => {
                          return row.docLine.seqNo;
                        })
                      )
                    : 0) + 1,
              },
            });
            // Set Current U of M to be the same as Default U of M the first time inventory is added
            invDocLine.qty = result.qtySelected;
            invDocLine.uOfMeasureId = inventory.parentInventory.uOfMeasureId;
            // Add new row to form list
            this.formListInventory.addNewRow(invDocLine, false);
            this.localDataService._allLocalData["inventoryDocLine"] = this.formListInventory.getObjects();
            // Clear input field
            this.inventorySearchComponent.cleanAndFocusSearchInput();
            // When changing form list mark form as dirty
            this._myForm.markAsDirty();
          }
        })
      );
    } else {
      const invDocLine = new InventoryDocLine(this._object);

      invDocLine.qty = remainingQty;
      invDocLine.packSize = packSize;
      _.merge(invDocLine, {
        docLine: {
          refNo: refNo,
          inventoryId: inventory.id,
          inventory: inventory,
          userId: this.dbService.getCurrentUserId(),
          seqNo:
            (this.formListInventory._objects.length
              ? Math.max(
                  ...this.formListInventory._objects.map((row) => {
                    return row.docLine.seqNo;
                  })
                )
              : 0) + 1,
        } as DocLine,
      });
      // Set Current U of M to be the same as Default U of M the first time inventory is added
      invDocLine.uOfMeasureId = inventory.uOfMeasureId;
      const inventorySupplierProductDetailOfNewPackSize = inventory.inventorySuppliers
        .find((inventorySupplier: InventorySupplier) => inventorySupplier.accountId == this._object.accountId)
        ?.inventorySupplierProductDetails.find(
          (inventorySupplierProducDetail: InventorySupplierProductDetail) =>
            inventorySupplierProducDetail.packSize === packSize
        );
      invDocLine.unitCost = unitPrice || inventorySupplierProductDetailOfNewPackSize?.supplierCost || 0;
      // Add new row to form list
      this.formListInventory.addNewRow(invDocLine, false);
      this.localDataService._allLocalData["inventoryDocLine"] = this.formListInventory.getObjects();
      // Clear input field
      this.inventorySearchComponent.cleanAndFocusSearchInput();
      // When changing form list mark form as dirty
      this._myForm.markAsDirty();
    }
    // There is some change-detection bug in the p-table code that makes us do this manually
    this.formListInventory.selectAllCheckBox.checked = this.formListInventory.selectAllCheckBox.updateCheckedState();
  }

  openFullSizeDialog(
    dialog: FullSizeDialogName,
    extra: CommercialAccount | { balanceComparision: BalanceComparision[] } | Inventory = null
  ) {
    this._activeFullDialog = dialog;
    this._activeFullDialogExtra = extra;
  }

  closeFullSizeDialog() {
    if (this._activeFullDialog === FullSizeDialogName.VIEW_ERRORS) {
      this.formListInventory.enableEditMode();
    }
    this._activeFullDialog = null;
    this._activeFullDialogExtra = null;
  }

  get balanceComparisonOverBalanceLength(): number {
    if (this._activeFullDialogExtra && "balanceComparision" in this._activeFullDialogExtra) {
      return (
        this._activeFullDialogExtra.balanceComparision.filter(
          (row) =>
            row.purchaseDocLine && row.inventoryDocLine.qty > row.purchaseDocLine.qty - row.purchaseDocLine.receivedQty
        ).length ?? 0
      );
    }
    return 0;
  }

  get balanceComparisonLinesWithoutPOLength(): number {
    if (this._activeFullDialogExtra && "balanceComparision" in this._activeFullDialogExtra) {
      return this._activeFullDialogExtra.balanceComparision.filter((row) => !row.purchaseDocLine).length ?? 0;
    }
    return 0;
  }

  forceFinalize(info: { balanceComparision: BalanceComparision[] } | Inventory | CommercialAccount): void {
    if ("balanceComparision" in info) {
      const overBalanceLines = info.balanceComparision.filter(
        (row) =>
          row.purchaseDocLine && row.inventoryDocLine.qty > row.purchaseDocLine.qty - row.purchaseDocLine.receivedQty
      );

      // If there are over-balance lines (lines with greater qty than any single purchaseDocLine),
      // we need to split the excess into a new row, then attempt to finalize again
      if (overBalanceLines.length > 0) {
        this.isProceedLoading = true;
        overBalanceLines.forEach((element) => {
          const foundObject: InventoryDocLine = this.formListInventory
            .getObjects()
            .find((invDocLine) => invDocLine._row_local_id === element.inventoryDocLine._row_local_id);
          const newInventoryDocLine = _.cloneDeep(foundObject);
          newInventoryDocLine.id = 0;
          newInventoryDocLine.docLine.id = 0;
          newInventoryDocLine.docLine.refNo = null;
          newInventoryDocLine.qty = foundObject.qty - element.purchaseDocLine.qty + element.purchaseDocLine.receivedQty;

          // update tax rules of new objects
          newInventoryDocLine.inventoryDocLineTaxes.forEach((tax) => {
            tax.id = 0;
            tax.amount = (newInventoryDocLine.qty * tax.amount) / foundObject.qty;
          });

          // update tax rules of existing objects
          foundObject.inventoryDocLineTaxes.forEach((tax) => {
            tax.amount =
              ((element.purchaseDocLine.qty - element.purchaseDocLine.receivedQty) * tax.amount) / foundObject.qty;
          });
          // splice allocatedShipperChargeAmount into new rows and existing ones
          newInventoryDocLine.allocatedShipperChargeAmount = MonetaryHelpers.roundToDecimalPlaces(
            (foundObject.allocatedShipperChargeAmount * newInventoryDocLine.qty) / foundObject.qty
          );
          foundObject.allocatedShipperChargeAmount = MonetaryHelpers.roundToDecimalPlaces(
            (foundObject.allocatedShipperChargeAmount *
              (element.purchaseDocLine.qty - element.purchaseDocLine.receivedQty)) /
              foundObject.qty
          );
          // splice allocateddiscountAmount into new rows and existing ones if fixed discount
          if (newInventoryDocLine.allocatedDiscountType === DiscountType.Fixed) {
            newInventoryDocLine.allocatedDiscountAmount = MonetaryHelpers.roundToDecimalPlaces(
              (foundObject.allocatedDiscountAmount * newInventoryDocLine.qty) / foundObject.qty
            );
            foundObject.allocatedDiscountAmount = MonetaryHelpers.roundToDecimalPlaces(
              (foundObject.allocatedDiscountAmount *
                (element.purchaseDocLine.qty - element.purchaseDocLine.receivedQty)) /
                foundObject.qty
            );
          }
          foundObject.qty = element.purchaseDocLine.qty - element.purchaseDocLine.receivedQty;

          const foundFormGroup = this.formListInventory._formGroups.controls.find(
            (control) => control.value._row_local_id === element.inventoryDocLine._row_local_id
          );
          foundFormGroup.get("qty").setValue(foundObject.qty);
          foundFormGroup.get("allocatedShipperChargeAmount").setValue(foundObject.allocatedShipperChargeAmount);
          foundFormGroup.get("allocatedDiscountAmount").setValue(foundObject.allocatedDiscountAmount);

          this.formListInventory.addNewRow(newInventoryDocLine, false);
        });

        this._changeDocState(DocState.finalized);
        this.localDataService._allLocalData["inventoryDocLine"] = this.formListInventory.getObjects();

        this._updateForm();
        this._isSaving = true;
        this.subsList.push(
          this.onSubmit(true).subscribe(
            () => {
              this._isSaving = false;
              this.isProceedLoading = false;
              this.closeFullSizeDialog();
            },
            (err) => {
              this._isSaving = false;
              this.isProceedLoading = false;
            }
          )
        );
        this.formListInventory.markAllAsPristine();
      } else {
        // This code should only run after the over-balance lines have been resolved.
        // It will find any lines that do not have a corresponding purchaseDocLine and create a new PO for them.
        const linesWithoutPO = info.balanceComparision.filter((row) => !row.purchaseDocLine);

        if (linesWithoutPO.length > 0) {
          this.isProceedLoading = true;
          const purchaseDoc = new PurchaseDoc(InventoryDocType.purchase_order, null, this.dbService.getDefaultZone());
          const [currentDate, currentTime] = TimeHelpers.SplitDateAndTime(new Date());
          purchaseDoc.doc.docDate = currentDate;
          purchaseDoc.doc.docTime = currentTime;

          if (this._myForm.controls.storeId.value) {
            purchaseDoc.storeId = this._myForm.controls.storeId.value;
          } else if (this._myForm.controls.backOfficeId.value) {
            purchaseDoc.backOfficeId = this._myForm.controls.backOfficeId.value;
          }

          const commercialAccountAddress =
            this._myForm.value.account?.commercialAccount.commercialAccountAddresses.filter(
              (addresses) => addresses.address.addressType == AddressType.shippingAddress && addresses.address.isDefault
            );
          if (commercialAccountAddress.length > 0) {
            purchaseDoc.shippingAddress = null;
            purchaseDoc.shippingAddressId = commercialAccountAddress[0]?.address?.id;
          }

          if (this._myForm.value.account?.commercialAccount?.contacts.length > 0) {
            purchaseDoc.shippingContact = null;
            purchaseDoc.shippingContactId = this._myForm.value.account?.commercialAccount?.contacts[0].person?.id;
          }

          if (this.hasAddressInfo(this._myForm.value.stock?.address)) {
            const stockAddress = _.cloneDeep(this._myForm.value.stock?.address);
            FormDataHelpers.clearModelIDs(stockAddress);
            purchaseDoc.stockAddress = stockAddress;
          }

          purchaseDoc.accountId = this._myForm.value.accountId;
          purchaseDoc.stockId = this._myForm.get("stockId").value;
          purchaseDoc.doc.comment = `Stock Receipt #${this._object?.doc?.docNo}. Amendment to Purchase Order for Receiving Additional Items Beyond the Finalized PO.`;
          purchaseDoc.doc.userId = this.appSettingsService.getUserId();
          purchaseDoc.doc.currencyIsoCode = this._myForm.get("doc.currencyIsoCode").value;
          purchaseDoc.discountType = this._myForm.value.discountType;
          if (purchaseDoc.discountType === DiscountType.Percentage) {
            purchaseDoc.discountAmount = this._myForm.value.discountAmount;
          }
          purchaseDoc.doc.state = DocState.finalized;
          let totalAllocatedDiscountAmount = 0;
          let totalAllocatedShipperChargeAmount = 0;
          let subTotal = 0;

          linesWithoutPO.forEach((element, index: number) => {
            // if for some reason, the inventoryDocLine has a refNo, but is returned without a PO docLine, that means
            // something else has changed (e.g. receivedQty was updated), and we should delete the refNo
            if (element.inventoryDocLine.docLine.refNo) {
              console.warn("RefNo found on inventoryDocLine without a PO docLine, deleting refNo");
              element.inventoryDocLine.docLine.refNo = null;
              // clear the form list row
              const invDocLine = this.formListInventory
                .getObjects()
                .find((invDocLine) => invDocLine._row_local_id === element.inventoryDocLine._row_local_id);
              if (invDocLine) {
                invDocLine.docLine.refNo = null;
                console.info("RefNo cleared from form list row");
              }
            }

            const newPurchaseDocLine: PurchaseDocLine = new PurchaseDocLine(purchaseDoc);
            newPurchaseDocLine.docLine.inventoryId = element.inventoryDocLine.docLine.inventoryId;
            newPurchaseDocLine.uOfMeasureId = element.inventoryDocLine.uOfMeasureId;
            newPurchaseDocLine.qty = element.inventoryDocLine.qty;
            newPurchaseDocLine.packSize = element.inventoryDocLine.packSize;
            newPurchaseDocLine.unitPrice = element.inventoryDocLine.unitCost;
            newPurchaseDocLine.docLine.userId = this.appSettingsService.getUserId();
            newPurchaseDocLine.docLine.seqNo = index + 1;
            // copy Taxes and then revise calculation
            element.inventoryDocLine.inventoryDocLineTaxes.forEach((tax) => {
              const purchaseDocLineTax = new PurchaseDocLineTax();
              purchaseDocLineTax.taxRuleId = tax.taxRuleId;
              purchaseDocLineTax.amount =
                element.inventoryDocLine.qty === 0
                  ? 0
                  : (tax.amount / element.inventoryDocLine.qty) * newPurchaseDocLine.qty;

              newPurchaseDocLine.purchaseDocLineTaxes.push(purchaseDocLineTax);
            });

            newPurchaseDocLine.allocatedDiscountType = element.inventoryDocLine.allocatedDiscountType;
            newPurchaseDocLine.allocatedDiscountAmount = Number(element.inventoryDocLine.allocatedDiscountAmount);
            if (newPurchaseDocLine.allocatedDiscountType === DiscountType.Fixed) {
              totalAllocatedDiscountAmount += newPurchaseDocLine.allocatedDiscountAmount;
            }
            newPurchaseDocLine.allocatedShipperChargeAmount = Number(
              element.inventoryDocLine.allocatedShipperChargeAmount
            );
            totalAllocatedShipperChargeAmount += newPurchaseDocLine.allocatedShipperChargeAmount;
            subTotal += newPurchaseDocLine.unitPrice * newPurchaseDocLine.qty;

            delete newPurchaseDocLine.docReference;
            purchaseDoc.purchaseDocLines.push(newPurchaseDocLine);
          });
          if (purchaseDoc.discountType === DiscountType.Fixed) {
            purchaseDoc.discountAmount = totalAllocatedDiscountAmount;
          }

          purchaseDoc.shipperChargeAmount = totalAllocatedShipperChargeAmount;

          // create new PO with same info as stock receiving as draft
          this.subsList.push(
            this.dbService
              .addRow("purchaseDoc", purchaseDoc)
              .pipe(
                catchError((errorResponse) => {
                  this.processServerError(errorResponse, false);
                  this.isProceedLoading = false;
                  return throwError(errorResponse);
                }),
                tap((newPurchaseDoc) => {
                  // update the refNo of the inventoryDocLine with the new PO docLine id
                  linesWithoutPO.forEach((element) => {
                    const purchaseDocLine = newPurchaseDoc.purchaseDocLines.find(
                      (purchaseDocLine) =>
                        purchaseDocLine.docLine.inventoryId === element.inventoryDocLine.docLine.inventoryId
                    );
                    if (purchaseDocLine) {
                      const invDocLine = this.formListInventory
                        .getObjects()
                        .find((invDocLine) => invDocLine._row_local_id === element.inventoryDocLine._row_local_id);
                      if (invDocLine) {
                        invDocLine.docLine.refNo = purchaseDocLine.id;
                      }
                    }
                  });
                  this.messageService.add(this.alertMessage.getMessage("save-success", this.successMessageEntityName));
                  this.closeFullSizeDialog();
                  this.onDonePressed();
                  this.isProceedLoading = false;
                })
              )
              .subscribe()
          );
        }
      }
    }
  }

  onChangedDocLineInventory(inventory: Inventory): void {
    this._docLineOpened.docLine.inventory = inventory;
    // Reset dialog properties
    this._docLineOpened = null;
    this.closeFullSizeDialog();
    // When changing form list mark form as dirty
    this._myForm.markAsDirty();
  }

  async loadPONumberClicked(poNumber: string): Promise<void> {
    try {
      const filter = {
        "doc.docNo": {
          matchMode: "equals",
          value: poNumber,
        },
        "doc.state": {
          matchMode: "in",
          value: [DocState.draft, DocState.finalized, DocState.approved],
        },
      };

      const getPOByPONumberRepsonse = await this.dbService
        .getRows<ApiListResponse<PurchaseDoc>>("purchaseDoc", JSON.stringify(filter))
        .toPromise();

      if (getPOByPONumberRepsonse?.count === 0) {
        this.messageService.add({
          severity: "warn",
          summary: "PO Not Found",
          detail: `PO with Number '${poNumber}' does not exist`,
          sticky: false,
          life: 5000,
        });
        return;
      }

      if (getPOByPONumberRepsonse.rows[0].doc.state === DocState.approved) {
        this.messageService.add({
          severity: "warn",
          summary: "PO Approved",
          detail: `PO with Number '${poNumber}' is already approved.`,
          sticky: false,
          life: 5000,
        });
        return;
      }

      if (getPOByPONumberRepsonse.rows[0].purchaseDocLines.length < 1) {
        this.messageService.add({
          severity: "warn",
          summary: "PO Empty",
          detail: `PO with Number '${poNumber}' found, but contains no items`,
          sticky: false,
          life: 5000,
        });
        return;
      }

      const purchaseDocId = getPOByPONumberRepsonse.rows[0].id;

      const purchaseDoc = await this.dbService.getRow<PurchaseDoc>("purchaseDoc", purchaseDocId).toPromise();

      const receivedPercentage = PurchaseDoc.totalReceivedPercentage(purchaseDoc);

      if (receivedPercentage >= 100) {
        this.messageService.add({
          severity: "warn",
          summary: "Notification",
          detail: "This PO has already been fully received",
          sticky: false,
          life: 5000,
        });
        return;
      }

      purchaseDoc.purchaseDocLines
        .sort((a, b) => {
          return a.docLine.seqNo - b.docLine.seqNo;
        })
        .forEach((purchaseDocLine) => {
          // NOTE: the calculateRemainingQtyAndPackSize method call below will always return
          // [null, PackSize.SKU] right now, because we haven't created dialog to check on this.selectPOsToLoadTableDataFormGroup?.controls.markAllAsReceived.value,
          // for this loadPONumberClicked() method (yet, or maybe we don't want that).
          const [remainingQty, packSize] = this.calculateRemainingQtyAndPackSize(purchaseDocLine);
          this._addInventoryItem(purchaseDocLine.docLine.inventory, {
            refNo: purchaseDocLine.id,
            unitPrice: purchaseDocLine.unitPrice,
            remainingQty: remainingQty,
            packSize: packSize,
          });
        });

      this.messageService.add({
        summary: "Success",
        severity: "success",
        detail: `Items for PO # ${poNumber} have been loaded successfully`,
      });
    } catch (error) {
      this.messageService.add({
        summary: "An Unknown Error Occured",
        severity: "error",
        detail: `Items for PO # ${poNumber} could not be loaded due to error ${error?.message || ""}`,
        sticky: true,
      });
    }
  }

  async selectPOsToLoadClicked(): Promise<void> {
    this.selectPOsDialogVisible = true;
    this.selectPOsToLoadTableLoading = true;

    try {
      // Using this method to reset the form array, because any other way would cancel the subscription to this.selectPOsToLoadTableDataFormGroup.controls.purchaseDocs.valueChanges
      this.clearFormArray(this.selectPOsToLoadTableDataFormGroup.controls.purchaseDocs as UntypedFormArray);

      const filter = {
        "doc.state": {
          matchMode: "equals",
          value: DocState.finalized,
        },
        accountId: {
          matchMode: "equals",
          value: this.accountIdCtrl.value,
        },
      };

      const getPOsRepsonse = await this.dbService
        .getRows<ApiListResponse<PurchaseDoc>>("purchaseDoc", JSON.stringify(filter), 0, 1000, null, null, {
          includes: "purchaseDocLine.docLine.inventory",
        })
        .toPromise();

      if (getPOsRepsonse?.count === 0) {
        this.messageService.add({
          severity: "warn",
          summary: "No Open POs",
          detail: `There are no open POs to load.`,
          sticky: false,
          life: 5000,
        });
        this.selectPOsDialogVisible = false;
        this.selectPOsToLoadTableLoading = false;
        return;
      }

      const purchaseDocsContainingLines = getPOsRepsonse.rows.filter(
        (purchaseDoc) => purchaseDoc.purchaseDocLines.length >= 1
      );

      if (purchaseDocsContainingLines.length == 0) {
        this.messageService.add({
          severity: "warn",
          summary: "All Open POs Are Empty",
          detail: `POs found '${getPOsRepsonse.rows
            .map((purchaseDoc) => purchaseDoc.doc.docNo)
            .join(", ")}', but all contain no items`,
          sticky: false,
          life: 5000,
        });
        this.selectPOsDialogVisible = false;
        this.selectPOsToLoadTableLoading = false;
        return;
      }

      const purchaseDocsNotFullyRecieved = purchaseDocsContainingLines.filter((purchaseDoc) => {
        const receivedPercentage = PurchaseDoc.totalReceivedPercentage(purchaseDoc);
        return receivedPercentage < 100;
      });

      if (purchaseDocsNotFullyRecieved.length == 0) {
        this.messageService.add({
          severity: "warn",
          summary: "Notification",
          detail: "No open POs for this supplier",
          sticky: false,
          life: 5000,
        });
        this.selectPOsDialogVisible = false;
        this.selectPOsToLoadTableLoading = false;
        return;
      }

      purchaseDocsNotFullyRecieved.forEach((purchaseDoc) => {
        (this.selectPOsToLoadTableDataFormGroup.controls.purchaseDocs as UntypedFormArray).push(
          this.fb.group({
            purchaseDoc: this.fb.group(purchaseDoc),
            // For some reason the above line creates nested the purchaseDocLines form array as a single form group, instead of a form array; hence this line below
            purchaseDocLines: this.fb.array(purchaseDoc.purchaseDocLines),
            isSelected: false,
          })
        );
      });

      this.selectPOsToLoadTableDataFormGroup.controls.markAllAsReceived.setValue(false);

      this.selectPOsToLoadTableLoading = false;
    } catch (error) {
      this.selectPOsDialogVisible = false;
      this.selectPOsToLoadTableLoading = false;
      this.messageService.add({
        summary: "An Unknown Error Occured",
        severity: "error",
        detail: `POs could not be loaded due to error ${error?.message || ""}`,
        sticky: true,
      });
    }
  }

  private clearFormArray(formArray: UntypedFormArray): void {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }

  selectAllCheckboxPOTableBtnClicked(): void {
    let allCheckboxesAlreadySelected = true;

    (this.selectPOsToLoadTableDataFormGroup.controls.purchaseDocs as UntypedFormArray).controls.forEach(
      (formGroup: FormGroup) => {
        if (!formGroup.value.isSelected) {
          allCheckboxesAlreadySelected = false;
          formGroup.controls.isSelected.setValue(true);
        }
      }
    );

    if (allCheckboxesAlreadySelected) {
      (this.selectPOsToLoadTableDataFormGroup.controls.purchaseDocs as UntypedFormArray).controls.forEach(
        (formGroup: FormGroup) => {
          formGroup.controls.isSelected.setValue(false);
        }
      );
    }
  }

  async loadSelectedPOsInvetories(): Promise<void> {
    this.selectPOsToLoadTableLoading = true;
    try {
      const inventoryIds: number[] = [];
      const pOLineById = new Map<number, PurchaseDocLine>();

      (this.selectPOsToLoadTableDataFormGroup.controls.purchaseDocs as UntypedFormArray).controls.forEach(
        (formGroup) => {
          if (formGroup.value.isSelected) {
            const purchaseDocLines: PurchaseDocLine[] = formGroup.value.purchaseDocLines;

            purchaseDocLines
              .sort((a, b) => {
                return a.docLine.seqNo - b.docLine.seqNo;
              })
              .forEach((purchaseDocLine: PurchaseDocLine) => {
                const [remainingQty] = this.calculateRemainingQtyAndPackSize(purchaseDocLine);

                if (
                  this.selectPOsToLoadTableDataFormGroup?.controls.markAllAsReceived.value !== true ||
                  remainingQty > 0
                ) {
                  inventoryIds.push(purchaseDocLine.docLine.inventory.id);
                  pOLineById.set(purchaseDocLine.id, purchaseDocLine);
                }
              });
          }
        }
      );

      if (inventoryIds.length === 0) {
        this.selectPOsToLoadTableLoading = false;
        return;
      }

      const filter = {
        id: {
          matchMode: "in",
          value: inventoryIds,
        },
      };

      const getInventoriesResponse = await this.dbService
        .getRows<ApiListResponse<Inventory>>("inventory", JSON.stringify(filter), 0, 1000)
        .toPromise();

      const inventoryById: Record<number, Inventory> = getInventoriesResponse.rows.reduce((acc, inventory) => {
        acc[inventory.id] = inventory;
        return acc;
      }, {});

      for (const [poLineId, purchaseDocLine] of pOLineById) {
        // doing this because calculateRemainingQtyAndPackSize depends on inventory having supplier attached,
        // which isn't happening unless get it from inventory Api call above "getInventoriesResponse"
        purchaseDocLine.docLine.inventory = inventoryById[purchaseDocLine.docLine.inventory.id];

        const [remainingQty, packSize] = this.calculateRemainingQtyAndPackSize(purchaseDocLine);

        this._addInventoryItem(inventoryById[purchaseDocLine.docLine.inventory.id], {
          refNo: poLineId,
          unitPrice: purchaseDocLine.unitPrice,
          remainingQty: remainingQty,
          packSize: packSize,
        });
      }

      this.selectPOsDialogVisible = false;
      // Not doing full form reset, because any other way would cancel the subscription to this.selectPOsToLoadTableDataFormGroup.controls.purchaseDocs.valueChanges
      // We need to set this to false right after, so that the other methods of adding invDocLines don't factor this value in
      this.selectPOsToLoadTableDataFormGroup.controls.markAllAsReceived.setValue(false);

      this.selectPOsToLoadTableLoading = false;

      this.messageService.add({
        summary: "Success",
        severity: "success",
        detail: `Selected POs loaded successfully`,
      });
    } catch (error) {
      this.selectPOsToLoadTableLoading = false;
      this.messageService.add({
        summary: "An Unknown Error Occured",
        severity: "error",
        detail: `POs could not be loaded due to error ${error?.message || ""}`,
        sticky: true,
      });
    }
  }

  private calculateRemainingQtyAndPackSize(purchaseDocLine: PurchaseDocLine): [number, PackSize] {
    // Defaults
    let remainingQty: number;
    let packSize = purchaseDocLine.packSize;

    if (this.selectPOsToLoadTableDataFormGroup?.controls.markAllAsReceived.value === true) {
      const skuQtyPerPack = Number(PurchaseDocLine.getSkuQtyPerPack(purchaseDocLine));
      const remainingSkuQty = purchaseDocLine.qty * skuQtyPerPack - purchaseDocLine.receivedQty;

      if (remainingSkuQty % skuQtyPerPack === 0) {
        remainingQty = remainingSkuQty / skuQtyPerPack;
      } else {
        remainingQty = remainingSkuQty;
        packSize = PackSize.SKU;
      }
    } else {
      remainingQty = null; // make them manually fill in the value if the checkbox isn't checked.
    }

    return [remainingQty, packSize];
  }

  addSelectedRowsToInventoryDoc(): void {
    this.supplierInventoryFormListModal.getSelectedRows().forEach((item: Inventory) => this._addInventoryItem(item));
    this.supplierInventoryFormListModal.unSelectAllRows();
    this.messageService.add({
      severity: "success",
      summary: "Success!",
      detail: "All items added to Purchase Order.",
      life: 3000,
    });
  }

  cancelDialogClicked(): void {
    this.isFinalizing = false;
    this.formListInventory._isFetchingData = false;
  }

  deleteEmptyOrZeroClicked(): void {
    this.formListInventory.deleteRowsAction(
      this.formListInventory.getObjects().filter((inventoryDocLine) => !inventoryDocLine.qty),
      true
    );
    if (this.docStateCtrl.value === DocState.draft) {
      this.preFinalizedActionDocState = this.docStateCtrl.value;
      this._changeDocState(DocState.finalized);
    }

    this.attachFormListRowsTo_myForm();
    this.onSave();
    this.formListInventory.unSelectAllRows();
  }

  public fillEmptyOrZeroClicked(): void {
    const inventoryDocLinesEmptyQtys: InventoryDocLine[] = this.formListInventory
      .getObjects()
      .filter((inventoryDocLine) => !inventoryDocLine.qty);

    inventoryDocLinesEmptyQtys.forEach((inventoryDocLine) => {
      if (!inventoryDocLine.qty) {
        inventoryDocLine.qty = 1;
      }
    });

    if (this.docStateCtrl.value === DocState.draft) {
      this.preFinalizedActionDocState = this.docStateCtrl.value;
      this._changeDocState(DocState.finalized);
    }

    this.attachFormListRowsTo_myForm();
    this.onSave();
    this.formListInventory.unSelectAllRows();
  }

  hasAddressInfo(address: Address): string {
    return (
      address &&
      (address.line1 ||
        address.line2 ||
        address.city ||
        address.postalCode ||
        address.attnFullPersonName ||
        (address.addressPhone && address.addressPhone.tel) ||
        (address.addressEmail && address.addressEmail.email))
    );
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
