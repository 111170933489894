<div *ngIf="labelSize == enumLabelSizes.barcode_1Inchx1Inch">
  <div *ngFor="let item of _forLoop; let index = index" style="width: 1in; height: 1in">
    <section>
      <div *ngIf="isShowPrice" style="display: inline-block; height: 0.15in; margin-left: 2; font-size: x-small">
        {{ price(item) | currency : item.standardPriceCurrencyIsoCode : "symbol-narrow" : _digitsFormat }}
      </div>
      <div *ngIf="isShowBarcode" style="height: 0.3in; overflow: hidden">
        <svg attr.id="barcode_{{ item.sku | replaceSpace : '' }}"></svg>
      </div>
      <div
        style="
          padding-top: 0.15in;
          height: 0.4in;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: wordwrap;
          margin-left: 2;
          width: 100%;
          font-size: x-small;
        "
      >
        {{ labelDescription(item) }}
        <br />
        {{ inventoryVariantsStr(item) }}
      </div>
    </section>
  </div>
</div>

<div *ngIf="labelSize == enumLabelSizes.barcode_2And1QuaterInchx1And1QuaterInch">
  <div
    *ngFor="let item of _forLoop; let index = index"
    style="width: 2.25in; height: 1.25in; margin-bottom: -8px; margin-top: -8px"
  >
    <section>
      <div
        *ngIf="isShowPrice"
        style="display: inline-block; overflow: hidden; margin-left: 2; height: 0.25in; width: 100%"
      >
        <strong>{{
          price(item) | currency : item.standardPriceCurrencyIsoCode : "symbol-narrow" : _digitsFormat
        }}</strong>
      </div>
      <div *ngIf="isShowBarcode" style="height: 0.35in; width: 100%">
        <svg attr.id="barcode_{{ item.sku | replaceSpace : '' }}"></svg>
      </div>
      <div
        style="
          padding-top: 0.06in;
          height: 0.59in;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: wordwrap;
          margin-left: 2;
          width: 100%;
        "
      >
        {{ labelDescription(item) }}
        <br />
        {{ inventoryVariantsStr(item) }}
      </div>
    </section>
  </div>
</div>

<div *ngIf="labelSize == enumLabelSizes.barcode_2And1QuaterInchx3QuaterInch">
  <div
    *ngFor="let item of _forLoop; let index = index"
    style="width: 2.25in; height: 0.75in; margin-bottom: -8px; margin-top: 8px"
  >
    <section>
      <div
        *ngIf="isShowPrice"
        style="display: inline-block; overflow: hidden; margin-left: 2; height: 0.11in; width: 100%; font-size: x-small"
      >
        <span style="float: left; margin-left: 0px; font-size: x-small">
          {{ price(item) | currency : item.standardPriceCurrencyIsoCode : "symbol-narrow" : _digitsFormat }}</span
        >
        <span *ngIf="inventoryVariantsStr(item) !== ''" style="float: right; margin-right: 10; font-size: x-small">{{
          inventoryVariantsStr(item)
        }}</span>
      </div>
      <div *ngIf="isShowBarcode" style="height: 0.29in; width: 100%">
        <svg attr.id="barcode_{{ item.sku | replaceSpace : '' }}"></svg>
      </div>
      <div
        style="
          padding-top: 0.11in;
          height: 0.24in;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: wordwrap;
          margin-left: 2;
          width: 100%;
          font-size: x-small;
        "
      >
        {{ labelDescription(item) }}
      </div>
    </section>
  </div>
</div>

<div *ngIf="labelSize == enumLabelSizes.shelf_2And1HalfInchx1and1QuaterInch">
  <div
    *ngFor="let item of _forLoop; let index = index"
    style="width: 2.5in; height: 1.25in; margin-bottom: -8px; margin-top: -8px"
  >
    <section>
      <div
        *ngIf="isShowPrice"
        style="display: inline-block; overflow: hidden; margin-left: 2; height: 0.2in; width: 100%"
      >
        <strong>{{
          price(item) | currency : item.standardPriceCurrencyIsoCode : "symbol-narrow" : _digitsFormat
        }}</strong>
      </div>
      <div
        style="
          height: 0.25in;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: wordwrap;
          margin-left: 2;
          width: 100%;
          font-size: x-small;
        "
      >
        {{ labelDescription(item) }}
        <br />
        {{ inventoryVariantsStr(item) }}
      </div>
      <div *ngIf="isShowBarcode" style="height: 0.6in; padding-top: 0.2in; width: 100%; bottom: 0">
        <svg attr.id="barcode_{{ item.sku | replaceSpace : '' }}"></svg>
      </div>
    </section>
  </div>
</div>
