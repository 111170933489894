<div class="col-12 topWrapper">
  <taku-form-header
    [toolbarTitle]="branding.businessSetupComponent.businessName"
    displayMode="inline"
    [showActionBtns]="false"
    [showBackBtn]="false"
  ></taku-form-header>
  <div class="col-12">
    <taku-steps
      class="col-12"
      [model]="wizardSteps"
      [(activeIndex)]="activeStep"
      lastStepLabel="FINISH"
      (onStepsCompleted)="onFinishWizard()"
      (onStepChanged)="onStepChanged($event)"
    ></taku-steps>
    <!-- Active Step: {{activeStep}} -->

    <div [hidden]="activeStep !== WizardSteps.BUSINESS_DETAILS" class="grid col-12 main-step" id="cashout-step-1">
      <taku-business-detail
        [_isDialog]="true"
        [_object]="currentBusiness"
        [insideWizard]="true"
        [customHeaderTitle]="getWizardStepTitle(activeStep) | uppercase"
        (formStatusChanged)="onStepStatusChanged($event, WizardSteps.BUSINESS_DETAILS)"
      ></taku-business-detail>
    </div>

    <div [hidden]="activeStep !== WizardSteps.DENOMINATIONS" class="grid main-step" id="cashout-step-2">
      <h1 class="col-12 pl-3"
        ><i class="icon takuicon-money" style="font-size: 24px"></i>
        {{ getWizardStepTitle(activeStep) | uppercase }}</h1
      >
      <p-progressBar
        mode="indeterminate"
        class="col-12"
        value="0"
        [style.visibility]="_isFetchingDenomination ? 'visible' : 'hidden'"
      >
      </p-progressBar>
      <taku-form-list
        #denominationsFormList
        class="col-12"
        _model="cashDenomination"
        [_defaultRows]="30"
        _scrollHeight="full"
        [_cols]="zoneDenominationsCols"
        _detailMode="n"
        [_title]="denominationsFormlistTitle"
        [_filter]="zoneFilter"
        [_defaultRowValues]="zoneDefaultRow"
      ></taku-form-list>
    </div>

    <div [hidden]="activeStep !== WizardSteps.ZONE_TENDERS" class="grid main-step" id="cashout-step-3">
      <h1 class="col-12 pl-3"
        ><i class="icon pi pi-credit-card" style="font-size: 24px"></i>
        {{ getWizardStepTitle(activeStep) | uppercase }}</h1
      >
      <p-progressBar mode="indeterminate" class="col-12" value="0" [style.visibility]="'hidden'"> </p-progressBar>
      <taku-form-list
        #zoneTendersFormList
        class="col-12"
        _model="tenderType"
        [_cols]="zoneTenderTypesCols"
        _detailMode="n"
        [_title]="tenderTypesFormlistTitle"
        [_filter]="zoneFilter"
        [_defaultRowValues]="zoneDefaultRow"
        id="tender-list"
      >
      </taku-form-list>
    </div>

    <div
      [hidden]="activeStep !== WizardSteps.STORE_TENDERS || hideStepsFlags[WizardSteps.STORE_TENDERS]"
      class="grid main-step"
      id="cashout-step-4"
    >
      <taku-tender-types-settings
        *ngIf="_currentStore?.id && !reloadComponentsFlags[WizardSteps.STORE_TENDERS]"
        class="col-12"
        [insideWizard]="true"
        [customHeaderTitle]="getWizardStepTitle(activeStep) | uppercase"
        [autoAddNewToSelected]="true"
        [storeId]="_currentStore.id"
        (formStatusChanged)="onStepStatusChanged($event, WizardSteps.STORE_TENDERS)"
      ></taku-tender-types-settings>
    </div>

    <div [hidden]="activeStep !== WizardSteps.SALE_DOC_FORMATS" class="grid main-step" id="cashout-step-5">
      <taku-sales-formats-settings
        class="col-12"
        *ngIf="_currentStore?.id"
        [insideWizard]="true"
        [customHeaderTitle]="getWizardStepTitle(activeStep) | uppercase"
        [storeId]="_currentStore.id"
        (formStatusChanged)="onStepStatusChanged($event, WizardSteps.SALE_DOC_FORMATS)"
      ></taku-sales-formats-settings>
    </div>

    <div [hidden]="activeStep !== WizardSteps.INVENTORY_FORMATS" class="grid inventory-doc-format" id="cashout-step-6">
      <h1 class="col-12 pl-3 pt-3"
        ><i class="icon material-icons">text_fields</i>{{ getWizardStepTitle(activeStep) | uppercase }}</h1
      >

      <taku-form-list
        #inventoryFormatsFormList
        class="col-12"
        _model="inventoryDocFormat"
        [_cols]="inventoryFormatsCols"
        _detailMode="n"
        [_title]="inventoryFormatsFormlistTitle"
        [_filter]="zoneFilter"
        [_defaultRowValues]="zoneDefaultRow"
        [_hideAddLineHeaderBtn]="true"
        [_hideOptionsHeaderBtn]="true"
        [_hideFilterControls]="true"
        [_rowSelectionType]="RowSelectionType.NONE"
        id="inventory-formats"
      ></taku-form-list>
    </div>
  </div>
</div>

<taku-dialog-validation-messages
  [validationMessages]="dialogValidationMessages"
  [(dialogVisible)]="showValidationDialog"
  (onDialogHide)="hideValidationErrorsDialog()"
>
</taku-dialog-validation-messages>

<!-- <p-dialog [modal]="true" header="Missing Data" [(visible)]="showValidationDialog" (onHide)="hideValidationErrorsDialog()">
  <div class="dialog-validation-errors">
    <p>Please review the following incomplete fields:</p>
    <section *ngFor="let stepErrors of dialogValidationMessages">
      <h3>{{wizardSteps[stepErrors.stepNumber].label}}</h3>
      <ul>
        <li *ngFor="let errorMessage of stepErrors.messages">
          <span *ngIf="errorMessage.fieldName" class="field-name">{{_toSentenceCase(errorMessage.fieldName)}}:</span><span class="validation-error">{{errorMessage.validationError}}</span>
          <span *ngIf="!errorMessage.fieldName">{{errorMessage}}</span>
        </li>
      </ul>
    </section>
    <div class="col-12 buttonsWrapper">
      <button pButton label="OK" class="p-button-secondary" (click)="hideValidationErrorsDialog()"></button>
    </div>
  </div>
</p-dialog> -->
