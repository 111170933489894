<taku-form-header
  [formGroup]="_myForm"
  (backClicked)="goBack()"
  (saveClicked)="onSave()"
  (revertClicked)="onRevert()"
  [toolbarTitle]="pageTitle"
  iconStyleClass="takuicon-not_listed_location"
  [showSecondaryBtns]="false"
></taku-form-header>

<div [formGroup]="_myForm">
  <p-card class="col-12" styleClass="panel-nopad">
    <!-- <ng-template pTemplate="header">
      <i class="icon takuicon-not_listed_location"></i> ACTIVE STORE POLICIES
    </ng-template> -->
    <p-tabView class="col-12">
      <p-tabPanel header="Sales">
        <div class="col-12 lg:col-6">
          <taku-editor
            [formGroup]="_myForm"
            fieldName="salesPolicy"
            fieldCaption="Sales Policy"
            _height="400px"
            [maxCharLength]="2400"
          ></taku-editor>
        </div>
      </p-tabPanel>

      <p-tabPanel header="Shipping">
        <div class="col-12 lg:col-6">
          <taku-editor
            [formGroup]="_myForm"
            fieldName="shippingPolicy"
            fieldCaption="Shipping Policy"
            _height="400px"
            [maxCharLength]="2400"
          ></taku-editor>
        </div>
      </p-tabPanel>

      <p-tabPanel header="Return">
        <div class="col-12 lg:col-6">
          <taku-editor
            [formGroup]="_myForm"
            fieldName="returnPolicy"
            fieldCaption="Return Policy"
            _height="400px"
            [maxCharLength]="2400"
          ></taku-editor>
        </div>
      </p-tabPanel>

      <p-tabPanel header="Gift Receipt">
        <div class="col-12 lg:col-6">
          <taku-editor
            [formGroup]="_myForm"
            fieldName="giftReceiptPolicy"
            fieldCaption="Gift Receipt Policy"
            _height="400px"
            [maxCharLength]="400"
          ></taku-editor>
        </div>
      </p-tabPanel>

      <p-tabPanel header="Consent">
        <div class="col-12 lg:col-6">
          <taku-editor
            [formGroup]="_myForm"
            fieldName="consent"
            fieldCaption="Customer Consent Message"
            _height="400px"
            [maxCharLength]="400"
          ></taku-editor>
        </div>
      </p-tabPanel>
    </p-tabView>
  </p-card>
</div>
