/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { ValidatorFn, AbstractControl } from "@angular/forms";
import * as JsBarcode from "jsbarcode";

export class BarcodeValidators {
  static validateCodeAgainstFormat(barcodeFormatControl: AbstractControl): ValidatorFn {
    return (c: AbstractControl): { [key: string]: any } | null => {
      const selectedFormat: string = barcodeFormatControl.value;
      if (selectedFormat === null) return null;

      const barcodeNumber = c.value;
      const formatParts = selectedFormat.match(/(\w+)-?([\w\d]+)?/);
      let barcodeFormat = null;

      //remove dashed in order to fit to jsBarcode's aming conventions
      if (formatParts[1] == "UPC" && formatParts[2] == "A") {
        barcodeFormat = formatParts[1];
      } else if (["ISBN", "ISSN"].includes(formatParts[1])) {
        barcodeFormat = "EAN13";
      } else {
        barcodeFormat = formatParts[1];
        if (formatParts[2]) barcodeFormat += formatParts[2];
      }

      //  = selectedFormat.replace("-", "");
      let isValid = true;
      try {
        const barcode = JsBarcode({}, barcodeNumber, {
          format: barcodeFormat,
          valid: (valid) => {
            isValid = valid;
          },
        });
      } catch (err) {}

      if (!isValid) return { "barcode.format": { format: selectedFormat, barcode: barcodeNumber } };

      return null;
    };
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
