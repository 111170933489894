/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit } from "@angular/core";
import { MenuItem } from "primeng/api";
import { Router } from "@angular/router";
import { Event as NavigationEvent } from "@angular/router";
import { NavigationEnd } from "@angular/router";
import { Location } from "@angular/common";
import { FormHeaderDisplayMode } from "src/app/forms/form-header/form-header.component";
import { WebHelpers } from "src/app/utility/WebHelpers";
import { Subscription } from "rxjs";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";

@Component({
  selector: "taku-tax-settings",
  templateUrl: "./tax-settings.component.html",
  styleUrls: ["./tax-settings.component.scss"],
})
export class TaxSettingsComponent implements OnInit {
  menuTaxes: MenuItem[];
  FormHeaderDisplayMode = FormHeaderDisplayMode;
  subsList: Subscription[] = [];

  _activeTab = 1;
  _activeZoneId: number;
  _activeStoreId;

  constructor(
    private location: Location,
    // private appSettings: AppSettingsStorageService,
    private router: Router
  ) {
    // this._activeZoneId = this.appSettings.getZoneId();
    this.subsList.push(
      this.router.events.subscribe((event: NavigationEvent): void => {
        if (event instanceof NavigationEnd) {
          // const routeStoreId = +event.url.replace(/.*\/(\d+)\??.*/g, '$1');
          // this._activeStoreId = routeStoreId || this.appSettings.getStoreId();

          this._activeTab = this.findActiveTab();
        }
      })
    );
  }

  findActiveTab(): number {
    if (this.router.url.includes("/taxRule")) {
      return 1;
    } else if (this.router.url.includes("/categories")) {
      return 2;
    } else {
      return 0;
    }
  }

  ngOnInit() {
    this.menuTaxes = [
      { id: "sales_taxes", label: "Sales Taxes", routerLink: ["list/s/tax"], queryParams: { title: "Sales Taxes" } },
      { id: "rules", label: "Rules", routerLink: ["list/s/taxRule"], queryParams: { title: "Rules" } },
      { id: "categories", label: "Categories", routerLink: ["categories"], queryParams: { title: "Categories" } },
    ];
  }

  goBack() {
    this.location.back();
  }

  ngOnDestroy(): void {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }

  get _isMobile() {
    return WebHelpers.isMobileScreen();
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
