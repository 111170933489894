<taku-form-header
  toolbarTitle="CONFIRM VERIFICATION FOR GMB LOCATION"
  displayMode="inline"
  [showActionBtns]="false"
  [showBackBtn]="false"
></taku-form-header>

<div [formGroup]="_formGroup" class="col-12">
  <div class="top-note col-12">
    In order to complete the verification of your GMB Location "{{ _locationTitle }}",<br />
    please enter the <strong>PIN Code</strong> you received by {{ deliveryMethod }}:
  </div>

  <div class="pin-wrapper col-12">
    <taku-input #pinInput [formGroup]="_formGroup" fieldName="pinCode" fieldCaption="PIN Code"></taku-input>
    <div [hidden]="!_errorMessage" class="error-note">{{ _errorMessage }}</div>
  </div>

  <div class="bottom-note col-12">
    The verification request was submitted on <em>{{ _verification.createTime | dateTimeFormat }}</em>
  </div>

  <div class="col-3 p-fluid col-offset-6">
    <button pButton label="CANCEL" class="p-button-secondary" (click)="onCancelPressed()"></button>
  </div>
  <div class="col-3 p-fluid">
    <button pButton label="ACCEPT" (click)="onAcceptPressed()"></button>
  </div>
</div>
