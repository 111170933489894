<form [formGroup]="_myForm">
  <div class="grid responsive-container">
    <div class="col-12 lg:col-5 xl:col-4 md:col-12 sm:col-12 p-0 flex login-left-side">
      <div class="login-container">
        <div class="taku-logo-container">
          <img [src]="branding.mainLogoDark" />
        </div>
        <div class="header-container">
          <h2 class="header">{{ stepsTitles[currentStep] }}</h2>
        </div>
        <div *ngIf="currentStep === LoginSteps.SELECT_STORE_ADDRESS" class="select-store-address-container">
          <div class="col-12 grid-nogutter" id="data-progressbar">
            <p-progressBar mode="indeterminate" value="0" [style.visibility]="_isFetchingData ? 'visible' : 'hidden'">
            </p-progressBar>
          </div>
          <div *ngIf="_loginErrors" class="col-12 grid-nogutter p-fluid loginErrorMsg-wrapper">
            <p-message severity="error" [text]="_loginErrors.message"></p-message>
          </div>
          <div class="input-fields">
            <div class="col-12 field-address-selection">
              <div class="p-inputgroup login">
                <span class="p-inputgroup-addon">{{ branding.loginComponent.url }}</span>
                <taku-input
                  [formGroup]="addressSelectionForm"
                  fieldName="accountAddress"
                  [fieldCaption]="branding.loginComponent.accountAddress"
                ></taku-input>
              </div>
            </div>
          </div>
          <div class="col-12 grid-nogutter button-container">
            <button
              pButton
              (click)="onStoreAddressConfirmed()"
              [disabled]="addressSelectionForm.pristine || !addressSelectionForm.valid"
              class="p-button taku-button-large p-component"
              label="Next"
            >
            </button>
          </div>
        </div>
        <div
          *ngIf="currentStep === LoginSteps.ENTER_CREDENTIALS && !auth.isLoggedIn()"
          class="username-pw-form-container"
        >
          <div *ngIf="_loginErrors" class="col-12 grid-nogutter p-fluid loginErrorMsg-wrapper">
            <p-message severity="error" [text]="_loginErrors.message"></p-message>
          </div>
          <div class="input-fields">
            <div class="col-12 field-username">
              <taku-input
                [formGroup]="_myForm"
                fieldName="userName"
                fieldCaption="Username"
                [showValidationErrors]="false"
                autocomplete="off"
              ></taku-input>
            </div>

            <div class="col-12 field-password">
              <taku-input
                #loginPasswordField
                [type]="'password'"
                [formGroup]="_myForm"
                fieldName="password"
                [showValidationErrors]="false"
                fieldCaption="Password"
                [autoSelect]="true"
              ></taku-input>
            </div>
          </div>

          <div class="grid col-12 grid-nogutter button-container">
            <div class="col-6 grid-align-left">
              <a href="javascript://" (click)="goPreviousStep()" class="goBack-link"
                ><span class="pi pi-chevron-left"></span> BACK</a
              >
            </div>

            <div class="col-6 grid-align-right">
              <button
                pButton
                type="submit"
                (click)="onLogin()"
                [disabled]="_myForm.pristine || !_myForm.valid || _signInClicked"
                icon="_"
                label="Sign In"
              >
                <i class="material-icons">person</i>
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="currentStep === LoginSteps.SELECT_LOCATION && auth.isLoggedIn()" class="select-location-container">
          <div class="col-12 grid-nogutter" id="data-progressbar">
            <p-progressBar mode="indeterminate" value="0" [style.visibility]="_isFetchingData ? 'visible' : 'hidden'">
            </p-progressBar>
          </div>
          <div *ngIf="loggedInUser" class="input-fields" [formGroup]="storeSelectionForm">
            <div *ngIf="adminModeEnabled" class="col-12 adminMode-selection">
              <p-radioButton
                name="locationType"
                [value]="LocationTypes.ADMIN_MODE"
                formControlName="locationType"
                label="Administrator Mode"
                labelStyleClass="font-bold ml-3"
                role="radio"
              >
              </p-radioButton>
            </div>

            <div class="col-12 field-backOffice-selection">
              <p-radioButton name="locationType" [value]="LocationTypes.BACK_OFFICE" formControlName="locationType">
              </p-radioButton>
              <taku-dropdown
                (dropDownClicked)="onBackOfficeTypeStoreSelected()"
                [formGroup]="storeSelectionForm"
                [options]="backOffices_lookup$ | async"
                [showValidationErrors]="false"
                fieldName="selectedBackOffice"
                fieldCaption="Back Office"
              ></taku-dropdown>
            </div>

            <div class="col-12 field-store-selection">
              <p-radioButton
                name="locationType"
                [value]="LocationTypes.STORE"
                formControlName="locationType"
              ></p-radioButton>
              <taku-dropdown
                (dropDownClicked)="onLocationTypeStoreSelected()"
                [formGroup]="storeSelectionForm"
                [options]="stores_lookup$ | async"
                [showValidationErrors]="false"
                fieldName="selectedStore"
                fieldCaption="Store"
              ></taku-dropdown>
            </div>
            <div
              *ngIf="locationTypeCtrl.value === LocationTypes.STORE && storeSelectionForm.get('selectedStore').value"
              class="col-12 field-station-selection"
            >
              <label for="selectedStation">Station</label>
              <div class="p-fluid station-list-wrapper" [ngSwitch]="!!storeSelectionForm.get('selectedStore').value">
                <p-listbox
                  *ngSwitchCase="true"
                  [filter]="true"
                  [options]="stations_lookup$ | async"
                  id="selectedStation"
                  filterPlaceHolder="Filter stations"
                  formControlName="selectedStation"
                  [disabled]="locationTypeCtrl.value !== LocationTypes.STORE"
                >
                </p-listbox>
                <div *ngSwitchCase="false" class="stations-nostore-msg"> Select a store first to see stations </div>
              </div>
            </div>
          </div>

          <div class="col-12 grid grid-nogutter button-container">
            <div class="col-6 grid-align-left">
              <a href="javascript://" (click)="onLogout()">Log Out</a>
            </div>

            <div class="col-6 grid-align-right">
              <button
                pButton
                type="submit"
                (click)="onGetStartedPressed()"
                [disabled]="storeSelectionForm.invalid || _getStartedClicked"
                class="p-button taku-button-large p-component"
                label="Get Started"
              >
              </button>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="currentStep !== LoginSteps.SELECT_LOCATION" class="login-page-footer">
        <div class="new-try-for-free-container" *ngIf="branding.loginComponent.showSignupLink">
          <span>New to TAKU?</span>
          <a routerLink="/signup" class="try-for-free-link">Try for Free</a>
        </div>
        <div class="col-12 loginBox-footer">
          <a *ngIf="hideForgotPasswordLink()" [routerLink]="['/reset-login', getAccountName()]" class="forgot-pw-link"
            >Forgot password?</a
          >
          &nbsp;
          <a class="help-link" href="https://help.takulabs.io/" target="_blank">Help</a>
        </div>
        <div class="mb-3 copyright">
          ver. {{ appVersion }} © {{ copyrightYear }} {{ branding.generic.versionFooter }}. All Rights Reserved
        </div>
      </div>
    </div>

    <div
      class="lg:col-7 xl:col-8 p-0 billboard-container"
      [ngStyle]="{ backgroundColor: branding.loginComponent.backgroundColor }"
    >
      <div class="right-side-img-topWrapper">
        <div class="billboard-text">
          <h2 [ngStyle]="{ color: branding.loginComponent.headingColor }">{{ branding.loginComponent.heading }} </h2>
          <h3>{{ branding.loginComponent.subHeading }}</h3>
          <a
            [href]="branding.loginComponent.getStarted.buttonUrl"
            target="_blank"
            class="p-button p-button-outlined mt-4"
            [ngStyle]="{ color: branding.colors.contrast }"
            >{{ branding.loginComponent.getStarted.buttonLabel }}</a
          >
        </div>
        <div class="billboard-image">
          <img [src]="branding.loginComponent.background" />
        </div>
      </div>
    </div>
  </div>
</form>
