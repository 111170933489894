/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { Inventory } from "../../inventory/inventory/inventory";

@Component({
  selector: "taku-inventory-doc-preview",
  templateUrl: "./inventory-doc-preview.component.html",
  styleUrls: ["./inventory-doc-preview.component.scss"],
})
export class InventoryDocPreviewComponent implements OnChanges {
  ngOnChanges(changes: SimpleChanges): void {
    if (changes["inventoryItem"]) {
      this._inventoryCatsNames = this.inventoryItem.categories.map((cat) => cat.categoryName);
    }
  }

  @Input() inventoryItem: Inventory;
  @Output() onBack = new EventEmitter();
  @Output() onOpenEditForm = new EventEmitter<Inventory>();
  _inventoryCatsNames: string[];

  constructor() {}

  ngOnInit() {}

  goBack() {
    this.onBack.emit(this.inventoryItem);
  }

  openInventoryEditForm() {
    this.onOpenEditForm.emit(this.inventoryItem);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
