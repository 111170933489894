/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Input } from "@angular/core";
import { TakuInputCtrlBase } from "../TakuInputCtrlBase";
import { WebHelpers } from "src/app/utility/WebHelpers";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "taku-calendar-date",
  templateUrl: "./taku-calendar-date.component.html",
  styleUrls: ["./taku-calendar-date.component.scss"],
})
export class TakuCalendarDateComponent extends TakuInputCtrlBase {
  @Input() minDate?: string | Date;
  @Input() maxDate?: string | Date;
  @Input() appendTo?: any;

  clear() {
    this.ctrlField.reset();
  }

  onCalendarClosed() {
    WebHelpers.reattachBodyClassesForDialog(this.el.nativeElement);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
