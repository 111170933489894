/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

export class TaxAccountCategory {
  id: number;
  category: string;
  isEnabled: boolean;

  constructor(i = 0) {
    this.id = 0;
    this.category = "";
    this.isEnabled = true;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
