/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
import { Component, Input } from "@angular/core";
import { TakuInputCtrlBase } from "../TakuInputCtrlBase";
import { WebHelpers } from "src/app/utility/WebHelpers";

@Component({
  selector: "taku-calendar-time",
  templateUrl: "./taku-calendar-time.component.html",
  styleUrls: ["./taku-calendar-time.component.scss"],
})
export class TakuCalendarTimeComponent extends TakuInputCtrlBase {
  clear() {
    this.ctrlField.reset();
  }

  onOverlayOpened() {
    // if time has not been assigned yet, set current time as default when opening overlay
    if (!this.ctrlField.value) this.ctrlField.setValue(new Date());
  }

  onCalendarClosed() {
    WebHelpers.reattachBodyClassesForDialog(this.el.nativeElement);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
