<ng-template [ngIf]="true" [formGroup]="_myForm">
  <div class="flex align-items-center justify-content-end">
    <td *ngIf="_myForm.get('id').value != 0" class="col-4 col-currency">
      {{ _myForm.get("amount").value | currency : currencyISOCode : "symbol-narrow" : "1.0" }}
    </td>
    <td *ngIf="_myForm.get('id').value == 0" class="col-4 col-currency">
      <div class="p-inputgroup amount-field">
        <span class="p-inputgroup-addon p-0">$</span>
        <taku-input [formGroup]="_myForm" fieldName="amount" fieldCaption="Amount"></taku-input>
      </div>
    </td>
    <td class="col-timessign">X</td>
    <td class="col-3 sm:col-4 col-count">
      <taku-input
        [formGroup]="_myForm"
        [maxLength]="fieldCountMaxLength"
        keyFilter="pint"
        fieldName="count"
        fieldCaption="Qty"
      ></taku-input>
    </td>
    <td class="col-equalsign">=</td>
    <td class="col-3 col-total">{{
      _myForm.get("amount").value * _myForm.get("count").value | currency : currencyISOCode : "symbol-narrow"
    }}</td>
  </div>
</ng-template>
