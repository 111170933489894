<div id="tape-preview-main">
  <header>
    <section id="retailer-info" [ngStyle]="{ fontSize: fontSizeToNumber(settings.headerFontSize) + 'px' }">
      <div *ngIf="settings.printLogo" class="retailer-logo-wrapper">
        <img *ngIf="businessLogoUrl" [attr.src]="businessLogoUrl" [attr.alt]="businessDetail.businessName" />
        <span *ngIf="!businessLogoUrl" class="retailer-logo-placeholder">YOUR LOGO HERE</span>
      </div>
      <h2 *ngIf="settings.printStoreName" class="retailer-name">{{ saledoc.store.storeName }}</h2>
      <a href="https://maps.google.com/?q={{ _fullAddressMap }}&t=m" target="_blank">
        <div *ngIf="settings.printAddress" class="retailer-address">
          <div class="address-line1">{{ saledoc.store.address?.line1 }}</div>
          <div class="address-city"
            >{{ saledoc.store.address?.city }}, {{ subdivisionDisplay(saledoc.store.address?.subDivisionIsoCode)
            }}{{ saledoc.store.address?.postalCode }}</div
          >
        </div>
      </a>
      <div *ngIf="settings.printStoreTaxNumber && saledoc?.uniqueTaxAccountNos.length > 0" class="retailer-tax-info">
        Store Tax#: {{ saledoc.uniqueTaxAccountNos.join(", ") }}
      </div>

      <div *ngIf="settings.printPhoneNo && saledoc.store.address?.addressPhone" class="retailer-phone">
        <span class="phone-label">Phone:</span> {{ saledoc.store.address?.addressPhone.fullPhoneNumber }}
      </div>

      <div *ngIf="settings.printWebsite" class="retailer-website">{{ saledoc.store.url }}</div>
      <div *ngIf="settings.printEmail" class="retailer-email">
        {{ saledoc.store.address?.addressEmail.email }}
      </div>
    </section>

    <section *ngIf="settings.printBusinessHours" id="retailer-business-hours">
      <div *ngFor="let storeHour of saledoc.store.storeHours; let index = index" class="storeHours-topWrapper">
        <div class="each-storeHour-container">
          <span *ngIf="startDateAndEndIsSame(index)">{{ storeHour.startDay }}</span>
          <span *ngIf="!startDateAndEndIsSame(index)">{{ storeHour.startDay }}-{{ storeHour.endDay }}</span>
          <span>{{ storeHour.openTime }} - {{ storeHour.closeTime }}</span>
        </div>
      </div>
      <!-- <div class="weekday-hours">
        <span>Mon-Fri</span>
        <span>{{ saledoc.store.storeHours[0].openTime }} - {{ saledoc.store.storeHours[0].closeTime }}</span>
      </div>
      <div class="saturday-hours">
        <span>Sat</span>
        <span>Closed</span>
      </div>
      <div class="sunday-hours">
        <span>Sun</span>
        <span>Closed</span>
      </div> -->
    </section>

    <section
      *ngIf="
        settings.printShippingAddress && hasAddressInfo(saledoc.shippingAddress) && saledoc.shippingAddress as shipping
      "
      id="retailer-account-info"
    >
      <div class="ship-to address-container">
        <span class="ship-to-label">SHIP TO:</span>
        <b>{{ shipping.attnCompanyName }}</b>
        <div class="mt-1 mb-1 shipping-address-container">
          <span *ngIf="shipping.line1">{{ shipping.line1 }}<br /></span>
          <span *ngIf="shipping.line2">{{ shipping.line2 }}<br /></span>
          <span>
            {{ shipping.city || "" }}{{ (shipping.city ? ", " : "") + subdivisionDisplay(shipping.subDivisionIsoCode)
            }}{{ (subdivisionDisplay(shipping.subDivisionIsoCode) ? ", " : "") + (shipping.postalCode || "")
            }}{{ (shipping.postalCode ? " " : "") + (shipping.countryIsoCode || "") }}
          </span>
          <!-- <span *ngIf="shipping.countryIsoCode" class="address-country"><span *ngIf="shipping.postalCode">&nbsp;</span>{{shipping.countryIsoCode}}</span> -->
        </div>
        <!-- <span *ngIf="shouldDisplayShippingAddress && settings.printShippingAddress"> -->
        <!-- </span> -->
        <div class="ship-attn">
          <div *ngIf="shipping.attnFullPersonName" class="attn-name mb-1">
            <b><span class="field-label">ATTN:&nbsp;</span>{{ shipping.attnFullPersonName }}</b>
          </div>
          <div *ngIf="shipping.addressPhone?.tel" class="attn-phone mb-1">
            <b>{{ shipping.addressPhone.fullPhoneNumber }}</b>
          </div>
          <div *ngIf="shipping.addressEmail?.email" class="attn-email">
            <b>{{ shipping.addressEmail.email }}</b>
          </div>
        </div>
      </div>
    </section>

    <section
      *ngIf="
        settings.printBillingAddress && hasAddressInfo(saledoc.billingAddress) && saledoc.billingAddress as billing
      "
      id="retailer-account-info"
    >
      <div class="bill-to address-container">
        <span class="ship-to-label">BILL TO:</span>
        <b class="mb-1">{{ billing.attnCompanyName }}</b>
        <span *ngIf="billing.line1">{{ billing.line1 }}<br /></span>
        <span *ngIf="billing.line2">{{ billing.line2 }}<br /></span>
        <span class="mb-1">
          <span>
            {{ billing.city || "" }}{{ (billing.city ? ", " : "") + subdivisionDisplay(billing.subDivisionIsoCode)
            }}{{ (subdivisionDisplay(billing.subDivisionIsoCode) ? ", " : "") + (billing.postalCode || "")
            }}{{ (billing.postalCode ? " " : "") + (billing.countryIsoCode || "") }}
          </span>
          <!-- <span *ngIf="billing.countryIsoCode" class="address-country">{{billing.countryIsoCode}}</span> -->
        </span>
        <div class="ship-attn">
          <div *ngIf="billing.attnFullPersonName" class="attn-name mb-1">
            <b><span class="field-label">ATTN:&nbsp;</span>{{ billing.attnFullPersonName }}</b>
          </div>
          <div *ngIf="billing.addressPhone?.tel" class="attn-phone mb-1">
            <b>{{ billing.addressPhone.fullPhoneNumber }}</b>
          </div>
          <div *ngIf="billing.addressEmail?.email" class="attn-email">
            <b>{{ billing.addressEmail.email }}</b>
          </div>
        </div>
        <div *ngIf="settings.printTaxNumber && saledoc?.taxID" class="retailer-tax-info">
          Tax ID#: {{ saledoc.taxID }}
        </div>
      </div>
    </section>

    <section id="receipt-details" [ngStyle]="{ fontSize: fontSizeToNumber(settings.headerFontSize) + 'px' }">
      <ul class="receipt-details-col-left">
        <li *ngIf="settings.printDate">
          <span class="receipt-field-label">Date:</span> {{ saledoc.doc.docDate | dateFormat }}
        </li>
        <li *ngIf="settings.printDocumentNo">
          <span class="receipt-field-label">Doc #:</span> {{ saledoc.doc.docNo }}
        </li>
        <li> <span class="receipt-field-label">Store:</span> {{ saledoc.store.storeID }} </li>
      </ul>

      <ul class="receipt-details-col-right">
        <li> <span class="receipt-field-label">Time:</span> {{ saledoc.doc.docTime }} </li>
        <li *ngIf="settings.printAccountInfo && saledoc.accountName">
          <span class="receipt-field-label">Account:</span> {{ saledoc.accountName }}
        </li>
        <li> <span class="receipt-field-label">User:</span> {{ saledoc.doc.user?.userName }} </li>
        <li> <span class="receipt-field-label">Till:</span> {{ saledoc.station?.stationNumber }} </li>
        <!-- <li *ngIf="settings.printStationNumber">
          <span class="receipt-field-label">Till:</span> {{saledoc.station.stationNumber}}
        </li>
        <li *ngIf="settings.printStationName">
          <span class="receipt-field-label">Till:</span> {{saledoc.station.stationName}}
        </li> -->
      </ul>
    </section>
    <section *ngIf="settings['printDailyNo']">
      <h1 style="text-align: center; border-style: solid; font-size: 2.5rem">#{{ saledoc.dailyNo }}</h1>
    </section>
  </header>

  <main>
    <!-- <h1>{{saledoc.doc.documentTitle}}</h1> -->
    <h1>
      <ng-template [ngIf]="!hideNotReceiptWatermark">PENDING </ng-template>
      {{ saledoc["isPackingList"] ? "Packing List" : saledoc.doc.documentTitle }}</h1
    >
    <table id="doc-lines" [ngStyle]="{ fontSize: fontSizeToNumber(settings.lineFontSize) + 'px' }">
      <ng-template [ngIf]="settings.printSummary">
        <thead>
          <th class="col-item">ITEM</th>
          <th class="col-qty">QTY</th>
          <ng-container *ngIf="!saledoc['isPackingList']">
            <th class="col-price">PRICE $</th>
            <th class="col-amount">AMOUNT $</th>
          </ng-container>
        </thead>

        <tbody>
          <!--
          <tr *ngIf="settings.printCategoryHeader" class="category-header">
            <th colspan="4"><span class="category-inner">FOOD</span></th>
          </tr>
          -->
          <ng-template ngFor [ngForOf]="saledoc.saleDocLines" let-line let-inventory="line.docLine.inventory">
            <tr class="item-description">
              <td colspan="4">
                <span class="item-desc-inner">
                  <ng-container *ngIf="saledoc['isPackingList']">[&nbsp;&nbsp;&nbsp;]&nbsp;</ng-container>
                  {{ line.docLine.inventory.description1 }}
                  <span *ngIf="line.voucher?.uniqueCode">(Unique Code: {{ line.voucher.uniqueCode }})</span>
                </span>
                <span *ngIf="settings.printDescription2 && line.docLine?.inventory.description2">
                  {{ line.docLine.inventory.description2 }}
                </span>
              </td>
            </tr>
            <tr *ngIf="settings.printVariants && inventoryVariantsStr(line)" class="item-categories-row">
              <td colspan="4"
                ><span class="item-categories-label">&nbsp;&nbsp;&nbsp;{{ inventoryVariantsStr(line) }}</span></td
              >
            </tr>
            <tr *ngIf="settings.printCategoryInLine" class="item-categories-row">
              <td colspan="4"
                ><span class="item-categories-label">{{ categoriesPrettyPrint(line) }}</span></td
              >
            </tr>
            <tr class="item-line2" [ngClass]="{ 'has-discount': line.discountAmount > 0 }">
              <td class="item-sku">
                <ng-template [ngIf]="settings.printSKU">{{ line.docLine.inventory.sku }}</ng-template>
              </td>
              <td class="item-qty">{{ line.qty * (isReturnDoc ? -1 : 1) | formatNegativeNumber }}</td>
              <td class="item-price-stock">
                <div *ngIf="!saledoc['isPackingList']">
                  <ng-template [ngIf]="line.discountAmount == 0 || settings.printOriginalPrice">
                    {{ line.unitPrice | number : "1.2-4" }}
                    <ng-template [ngIf]="settings.printUofM && line.docLine.inventory.uOfMeasure">
                      /{{ line.docLine.inventory.uOfMeasure?.name }}
                    </ng-template>
                  </ng-template>
                </div>
              </td>
              <td class="item-total" *ngIf="!saledoc['isPackingList']">
                <span
                  *ngIf="(line.discountAmount == 0 || settings.printOriginalPrice) && !saledoc['isPackingList']"
                  class="item-total-amount"
                >
                  {{
                    roundToDecimalPlacesHalfUp(line.unitPrice * line.qty) * (isReturnDoc ? -1 : 1)
                      | number : _digitsFormat
                      | formatNegativeNumber
                  }}
                </span>
                <span *ngIf="settings.printTaxInventoryType" class="item-tax-name">{{ getLineTaxAcronyms(line) }}</span>
              </td>
            </tr>
            <!--
              <tr class="item-line3">
                <td class="item-variants" colspan="3">[*variants placeholder*]</td>
                <td class="item-tax-name">{{line.saleDocLineTaxes[0].tax.taxAcronym}}</td>
              </tr>
            -->
            <tr *ngIf="settings.printBarcodeNo || line.discountAmount > 0" class="item-line4">
              <td class="item-barcode" colspan="2">
                <ng-template [ngIf]="settings.printBarcodeNo && line.docLine.inventory.inventoryBarcodes.length > 0">{{
                  line.docLine.inventory.inventoryBarcodes[0].barcode
                }}</ng-template>
              </td>
              <td class="item-sale-price">
                <ng-template [ngIf]="line.discountAmount > 0 && !saledoc['isPackingList']"
                  >{{ line.salePrice | number : "1.2-4" }}
                  <ng-template [ngIf]="settings.printUofM && line.docLine.inventory.uOfMeasure"
                    >/{{ line.docLine.inventory.uOfMeasure?.name }}</ng-template
                  ></ng-template
                >
              </td>
              <td class="item-total-sale">
                <ng-template [ngIf]="line.discountAmount > 0 && !saledoc['isPackingList']">{{
                  line.salePrice * line.qty * (isReturnDoc ? -1 : 1) | number : _digitsFormat | formatNegativeNumber
                }}</ng-template>
              </td>
            </tr>
            <tr *ngIf="line.docLine.note" class="item-note">
              <td colspan="4">** NOTE: {{ line.docLine.note }} **</td>
            </tr>
            <tr
              *ngIf="line.saleDocLineReturn && line.saleDocLineReturn.storeSettingReturnReason"
              class="item-return-reason"
            >
              <td colspan="4"
                >** Return Reason:
                <span>{{ line.saleDocLineReturn?.storeSettingReturnReason?.returnReason }}</span> **</td
              >
            </tr>
            <!--
            <tr class="item-return-reason">
              <td colspan="4">** Return Reason: <span>In-Transit Damage</span> **</td>
            </tr>
            -->
          </ng-template>
        </tbody>
      </ng-template>

      <tfoot *ngIf="!saledoc['isPackingList']">
        <tr class="receipt-subtotal">
          <td class="label" colspan="2">SUBTOTAL</td>
          <td class="amount" colspan="2">{{
            saledoc.subTotal * (isReturnDoc ? -1 : 1)
              | currency : _currencyIsoCode : "symbol-narrow" : _digitsFormat
              | formatNegativeNumber
          }}</td>
        </tr>
        <tr class="receipt-discount">
          <td class="label" colspan="2"
            >DISCOUNT <span *ngIf="saledoc.discountType === 'Percentage'">({{ saledoc.discountAmount }}%)</span></td
          >
          <td class="amount" colspan="2">{{
            saledoc.discountAmountMoney * (isReturnDoc ? 1 : -1)
              | currency : _currencyIsoCode : "symbol-narrow" : _digitsFormat
              | formatNegativeNumber
          }}</td>
        </tr>

        <tr *ngFor="let linetax of saledoc.groupedTaxLines" class="receipt-tax">
          <td class="label" colspan="2">TAX ({{ linetax.saleTaxRule?.tax?.shortDesc }})</td>
          <td class="amount" colspan="2">{{
            linetax.total * (isReturnDoc ? -1 : 1)
              | currency : _currencyIsoCode : "symbol-narrow" : _digitsFormat
              | formatNegativeNumber
          }}</td>
        </tr>
        <!--
          <tr class="receipt-tax" *ngFor="let saletax of saledoc.saleDocTaxes">
            <td class="label" colspan="2">TAX <ng-template [ngIf]="saletax.tax">{{saletax.tax.shortDesc}}</ng-template></td>
            <td class="amount" colspan="2">{{saletax.amount* (isReturnDoc ? -1 : 1)|currency:_currencyIsoCode:'symbol-narrow':_digitsFormat|formatNegativeNumber}}</td>
        </tr>
        -->
        <tr class="receipt-shipping">
          <td class="label" colspan="2">SHIPPING</td>
          <td class="amount" colspan="2">{{
            saledoc.shipperChargeAmount * (isReturnDoc ? -1 : 1)
              | currency : _currencyIsoCode : "symbol-narrow" : _digitsFormat
              | formatNegativeNumber
          }}</td>
        </tr>
        <tr class="receipt-grand-total">
          <th class="label" colspan="2">
            <span class="item-count">({{ saledoc.totalItems }} items)</span>
            TOTAL
          </th>
          <th class="amount" colspan="2">{{
            saledoc.grandTotal * (isReturnDoc ? -1 : 1)
              | currency : _currencyIsoCode : "symbol-narrow" : _digitsFormat
              | formatNegativeNumber
          }}</th>
        </tr>
      </tfoot>
    </table>

    <section
      *ngIf="settings.printTenderDetail && saledoc.saleDocTenders.length > 0 && !saledoc['isPackingList']"
      id="cards-payments"
      class="payment-summary"
    >
      <table>
        <thead>
          <tr>
            <th></th>
            <th>PAY TYPE DETAILS</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let tenderTransaction of saledoc.saleDocTenders; let i = index">
            <td class="line-no">Line {{ i + 1 }}</td>
            <td class="label">
              <div>{{ tenderTransaction.tenderType.description }}</div>
              <div *ngIf="tenderTransaction.voucher?.uniqueCode">
                Unique Code {{ tenderTransaction.voucher.uniqueCode }}
              </div>
              <div *ngIf="tenderTransaction.refNo">Ref # {{ tenderTransaction.refNo }}</div>
            </td>
            <td class="amount">{{
              tenderTransaction.amount
                | currency : _currencyIsoCode : "symbol-narrow" : _digitsFormat
                | formatNegativeNumber
            }}</td>
          </tr>
        </tbody>
      </table>

      <!-- <ul class="ref-info">
          <ng-template ngFor [ngForOf]="_cardPayments" let-cardPayment let-i="index">
            <li *ngIf="cardPayment.refNo">Line {{i+1}}: Ref # {{cardPayment.refNo}}</li>
          </ng-template>
      </ul> -->
    </section>

    <!-- <section *ngIf="settings.printTenderDetail && _otherPayments.length > 0" id="other-payments" class="payment-summary">
      <table>
        <thead>
          <tr>
            <th>OTHER PAYMENTS</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let payment of _otherPayments">
            <td class="label">{{payment.tenderType.description}}</td>
            <td class="amount">{{payment.amount|currency:_currencyIsoCode:'symbol-narrow':_digitsFormat|formatNegativeNumber}}</td>
          </tr>
        </tbody>
      </table>
    </section> -->

    <section
      *ngIf="settings.printTenderDetail && this._totalCashPayments !== 0 && !saledoc['isPackingList']"
      id="cash-payment"
      class="payment-summary"
    >
      <table>
        <thead>
          <tr>
            <th>CASH PAYMENT</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="settings.printPennyRounding" class="penny-rounding">
            <td class="label">Cash Rounding</td>
            <td class="amount">{{
              this.saledoc.cashRounding
                | currency : _currencyIsoCode : "symbol-narrow" : _digitsFormat
                | formatNegativeNumber
            }}</td>
          </tr>
          <tr class="rounded-balance">
            <td class="label">Rounded Balance</td>
            <td class="amount">{{
              this._roundedBalanceBeforeCash
                | currency : _currencyIsoCode : "symbol-narrow" : _digitsFormat
                | formatNegativeNumber
            }}</td>
          </tr>
          <tr class="cash-payment">
            <td class="label">Payment</td>
            <td class="amount">{{
              this._totalCashPayments
                | currency : _currencyIsoCode : "symbol-narrow" : _digitsFormat
                | formatNegativeNumber
            }}</td>
          </tr>
          <tr class="change-due">
            <td class="label">Change Due</td>
            <td class="amount">{{
              Math.max(0, this._totalCashPayments - this._roundedBalanceBeforeCash)
                | currency : _currencyIsoCode : "symbol-narrow" : _digitsFormat
            }}</td>
          </tr>
        </tbody>
      </table>
    </section>

    <div
      *ngIf="
        settings['printInvoiceSavingLine'] &&
        !isReturnDoc &&
        saledoc.totalLineDiscounts + saledoc.discountAmountMoney as savings
      "
      id="saving-amount"
    >
      <!-- <div *ngIf="!isReturnDoc && saledoc.totalLineDiscounts + saledoc.discountAmountMoney as savings" id="saving-amount"> -->
      <ng-container *ngIf="!saledoc['isPackingList']">
        YOU SAVED: {{ savings | currency : _currencyIsoCode : "symbol-narrow" : _digitsFormat }}
      </ng-container>
    </div>

    <div *ngIf="!saledoc['isPackingList']" class="tick-line-bottom"></div>

    <section *ngIf="settings.printTransactionRecord" id="transaction-record">
      <h3 class="header"><span class="header-inner">TRANSACTION RECORD</span></h3>
      <div class="transaction-integration">
        CREDIT CARD INTEGRATION GOES HERE <br />
        (Only shown when one of the payments is a Card)
      </div>
      <div class="transaction-status">APPROVED</div>
    </section>

    <section
      *ngIf="!hideNotReceiptWatermark || saledoc.doc.comment"
      id="document-note"
      [ngClass]="{ 'tick-line-top': !saledoc['isPackingList'] }"
    >
      <!-- <section id="document-note"> -->
      <div *ngIf="!hideNotReceiptWatermark" class="not-receipt-watermark second">NOT A RECEIPT</div>
      <span class="title" *ngIf="saledoc.doc.comment">INVOICE NOTE:</span>
      {{ saledoc.doc.comment }}
    </section>

    <section id="returns" *ngIf="!saledoc['isPackingList']">
      <div *ngIf="!isReturnDoc && saledoc.doc.docNo" class="returns-barcode" style="text-align: center">
        <!-- <div class="instructions">PRESENT THIS BARCODE FOR RETURNS</div> -->
        <div class="barcodeWrapper">
          <svg #returnBarcodeImage></svg>
        </div>
      </div>

      <div *ngIf="settings['printReturnPolicy'] && policies?.returnPolicy" class="returns-policies">
        <!-- <div *ngIf="policies?.returnPolicy" class="returns-policies"> -->
        <span class="title">STORE POLICY:</span>
        <span class="content" [innerHTML]="policies.returnPolicy"></span>
      </div>

      <div *ngIf="settings['printSalePolicy'] && policies?.salesPolicy" class="sales-policies">
        <!-- <div *ngIf="policies?.salesPolicy" class="sales-policies"> -->
        <span class="title">SALES POLICY:</span>
        <span class="content" [innerHTML]="policies.salesPolicy"></span>
      </div>
    </section>

    <!--
    <section id="document-number" *ngIf="saledoc['isPackingList'] && saledoc.doc.docNo">
      <span>{{ saledoc.doc.docNo }}</span>
    </section>
    -->
  </main>

  <footer *ngIf="!saledoc['isPackingList']">
    <section *ngIf="settings.printSocialIcons" id="social-links">
      <div class="social-links-inner">
        <div
          *ngFor="let socialProfile of saledoc.store.storeSocialProfiles"
          class="social-account {{ socialProfile.socialProfileType }}-account"
        >
          <ng-template [ngIf]="socialProfile.siteURL">
            <span class="social-logo-wrapper"><img [src]="socialProfile.socialIconURL" /></span>
            <span class="social-link">{{ socialProfile.socialProfile }}</span>
          </ng-template>
        </div>
      </div>
    </section>

    <section *ngIf="settings.customerNote" id="acknowledge-note">
      <!-- <h3 class="header">"Thank you for your preference"</h3> -->
      <span>{{ settings.customerNote }}</span>
    </section>

    <a href="https://takulabs.io/sign-up-today" target="_blank">
      <section *ngIf="settings.printPoweredByTalulabs" id="powered-by">
        <img src="/assets/components/receipt-preview/images/taku-logo.png" alt="TakuLabs" class="app-logo" />
        <span class="text">Powered by TAKU</span>
      </section>
    </a>
  </footer>

  <section *ngIf="isTakuPayTransaction && !saledoc['isPackingList']" id="cards-payments" class="payment-summary">
    <table>
      <tbody *ngFor="let tenderTransaction of saledoc.saleDocTenders; let i = index">
        <ng-container *ngIf="tenderTransaction?.refTransaction?.TransType">
          <tr>
            <td class="label"><b>Payment Method</b></td>
            <td class="amount"><b>Amount</b></td>
          </tr>
          <tr>
            <td class="label"
              >{{ tenderTransaction?.refTransaction?.CardType || tenderTransaction?.tenderType?.type }}
              {{
                tenderTransaction?.refTransaction?.CardType ? "x" + tenderTransaction?.refTransaction?.Last4 : ""
              }}</td
            >
            <td class="amount">{{
              tenderTransaction?.amount
                | currency : _currencyIsoCode : "symbol-narrow" : _digitsFormat
                | formatNegativeNumber
            }}</td>
          </tr>
          <tr>
            <td class="label">Trans Type</td>
            <td class="amount">
              {{
                tenderTransaction?.refTransaction?.TransType.charAt(0).toUpperCase() +
                  tenderTransaction?.refTransaction?.TransType?.slice(1)
              }}
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="saledoc?.['isMerchantReceipt']">
          <tr>
            <td class="label">Terminal Id</td>
            <td class="amount">{{ tenderTransaction?.refTransaction?.TerminalID }}</td>
          </tr>
          <tr>
            <td class="label">Trans Source</td>
            <td class="amount">{{ tenderTransaction?.refTransaction?.EntryMethod }}</td>
          </tr>
          <tr>
            <td class="label">Auth Code</td>
            <td class="amount">{{ tenderTransaction?.refTransaction?.AuthCode }}</td>
          </tr>
          <tr>
            <td class="label">EMV App Label</td>
            <td class="amount">{{ tenderTransaction?.refTransaction?.EMV_App_Label }}</td>
          </tr>
          <tr>
            <td class="label">EMV App Id</td>
            <td class="amount">{{ tenderTransaction?.refTransaction?.EMV_App_Name }}</td>
          </tr>
        </ng-container>
        <ng-container *ngIf="i >= 0">
          <tr>
            <td class="label"></td>
            <td class="amount"></td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </section>

  <section *ngIf="isTakuPayTransaction && !saledoc['isPackingList']" id="acknowledge-note">
    <ng-container *ngIf="saledoc['isMerchantReceipt']">
      <br /><br /><br />
      <span>x_____________________________</span><br />
      <span>Signature</span><br /><br />
      <span>I AGREE TO PAY ABOVE TOTAL AMOUNT</span><br />
      <span>ACCORDING TO CARD ISSUER AGREEMENT</span><br />
      <span>(MERCHANT AGREEMENT IF CREDIT VOUCHER)</span><br /><br />
      <span>MERCHANT COPY</span><br />
    </ng-container>

    <ng-container *ngIf="saledoc['isCustomerReceipt']"> <span>CUSTOMER COPY</span><br /> </ng-container>
  </section>

  <div *ngIf="!hideNotReceiptWatermark" class="not-receipt-watermark first">NOT A RECEIPT</div>
</div>

<!-- <div class="col-12">
  {{settings|json}}
</div> -->
