import { SaleDocLine } from "../sale-doc-line/sale-doc-line";
import { SaleDocTender } from "../sale-doc-tender/sale-doc-tender";

export class Voucher {
  id = 0;
  saleDocLines?: SaleDocLine[];
  saleDocTender?: SaleDocTender;
  /** only set if voucher was imported */
  importDatetime: string | null;

  constructor(
    public zoneId: number,
    public inventoryId: number,
    public value: number | string = 0,
    public uniqueCode = ""
  ) {}
}
