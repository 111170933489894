/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { DBService } from "../../../../shared/services/db.service";
import { DataType, FilterType, Col } from "../../../../form-list/form-list/form-list";
import { Shipper_Type, ShippingMethod } from "./shipper";
import { ZoneService } from "../../zone-settings/zone.service";
import { AuthService } from "../../../../shared/services/auth.service";
import { HttpClient } from "@angular/common/http";
import { LocationService } from "../../../../shared/services/location.service";
import { SelectItem } from "primeng/api";
import { FormListModel } from "../../../../form-list/form-list/form-list-model.interface";
import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { SaleChannelOrdersService } from "src/app/core/sale-channel/sale-channel-orders/sale-channel-orders.service";
import * as _ from "lodash";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";
@Injectable({
  providedIn: "root",
})
export class ShipperService extends DBService implements FormListModel {
  enum_shipper_type = this.enumSelectOptions(Shipper_Type);
  enum_delivery_methods = this.saleChannelOrdersService.enum_delivery_methods;
  enumMultiShippingMethod = this.enumMultiselectOptions(ShippingMethod);
  enumShippingMethod = this.enumSelectOptions(ShippingMethod);
  lookup_subdivisions: { [countryIsoCode: string]: SelectItem[] } = {};

  get_enumCountries(): SelectItem[] {
    return this.locationService.lookupCountryOptions_full();
  }

  constructor(
    protected http: HttpClient,
    protected authService: AuthService,
    private router: Router,
    private zoneService: ZoneService,
    private locationService: LocationService,
    private saleChannelOrdersService: SaleChannelOrdersService
  ) {
    super(http, authService);
  }

  getAsyncValidationRules?(): { [key: string]: AsyncValidatorFn | AsyncValidatorFn[] | {} } {
    return {
      // special key that means this validation should be applied to the whole form/object
      $global: [this.validateShipperNameIsUnique.bind(this), this.validateShortNameIsUnique.bind(this)],
    };
  }

  validateShortNameIsUnique(control: AbstractControl): Observable<ValidationErrors> {
    return this.checkUniqueShortName(control).pipe(
      map((res: boolean) => {
        return res ? null : { zoneShortNameNotUnique: true };
      })
    );
  }

  validateShipperNameIsUnique(control: AbstractControl): Observable<ValidationErrors> {
    return this.checkUniqueShipperName(control).pipe(
      map((res: boolean) => {
        return res ? null : { zoneShipperNameNotUnique: true };
      })
    );
  }

  checkUniqueShortName(control: AbstractControl): Observable<boolean> {
    let route = this.router.routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }

    const zoneId = +route.snapshot.params["zoneId"];
    if (!zoneId) return of(true);

    const filter: any = {
      shortName: { matchMode: "equals", value: control.get("shortName").value },
      zoneId: { matchMode: "equals", value: zoneId },
    };
    // Do not take into account current record
    // exclude itself, so it's not initially invalid.
    if (control.value.id) filter.id = { matchMode: "ne", value: control.value.id };

    return this.getRows("shipper", JSON.stringify(filter), 0, 1).pipe(
      map((res: any) => {
        return !(res.rows && res.rows.length);
      })
    );
  }

  checkUniqueShipperName(control: AbstractControl): Observable<boolean> {
    let route = this.router.routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }

    const zoneId = +route.snapshot.params["zoneId"];
    if (!zoneId) return of(true);

    const filter: any = {
      shipperName: { matchMode: "equals", value: control.get("shipperName").value },
      zoneId: { matchMode: "equals", value: zoneId },
    };
    // Do not take into account current record
    // exclude itself, so it's not initially invalid.
    if (control.value.id) filter.id = { matchMode: "ne", value: control.value.id };

    return this.getRows("shipper", JSON.stringify(filter), 0, 1).pipe(
      map((res: any) => {
        return !(res.rows && res.rows.length);
      })
    );
  }

  getValidationRules(): { [key: string]: ValidatorFn[] | {} } {
    return {
      shipperName: [Validators.required],
      shipperType: [Validators.required],
      shippingMethod: [Validators.required],
      deliveryMethod: [Validators.required],
      shortName: [Validators.required],
      "address.countryIsoCode": [Validators.required],
    };
  }

  get_lookup_subdivisions(countryIsoCode): SelectItem[] {
    if (!countryIsoCode) return;

    if (!this.lookup_subdivisions[countryIsoCode]) {
      this.lookup_subdivisions[countryIsoCode] = this.locationService.lookupSubdivisionOptions(countryIsoCode, false);
    }
    return this.lookup_subdivisions[countryIsoCode];
  }

  formlist_lookup_subdivisions(rowData): SelectItem[] {
    return this.get_lookup_subdivisions(_.get(rowData, "address.countryIsoCode"));
  }

  formlist_filter_subdivisions(colsFilters): SelectItem[] {
    if (colsFilters["address.countryIsoCode"])
      return this.get_lookup_subdivisions(colsFilters["address.countryIsoCode"]);
    else return [];
  }

  getFormListColumns() {
    return [
      {
        field: "isActive",
        header: "Active",
        visible: true,
        readonly: false,
        frozen: true,
        dataType: DataType.checkbox,
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.enum,
      },
      // { field: 'zoneId', header: 'Zone', visible: true, readonly: false, frozen: true, dataType: DataType.lookup, dataOptions: this.zoneService.lookup_zones(), filterType: FilterType.equals },
      {
        field: "shipperName",
        header: "Shipper Name",
        visible: true,
        readonly: false,
        frozen: true,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "shipperType",
        header: "Shipper Type",
        visible: true,
        readonly: false,
        frozen: true,
        dataType: DataType.enum,
        dataOptions: this.enum_shipper_type,
        filterType: FilterType.enum,
      },
      {
        field: "shippingMethod",
        header: "Shipping Method",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enumMultiselect,
        dataKey: null,
        filterDataKey: null,
        dataOptions: this.enumMultiShippingMethod,
        filterType: FilterType.enum,
        filterOptions: this.enumMultiShippingMethod,
        filterMatchMode: "contains",
        filterField: "`shippingMethod`",
        colWidth: 250,
      },
      {
        field: "deliveryMethod",
        header: "Delivery Method",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enum_delivery_methods,
        filterType: FilterType.enum,
        colWidth: 120,
      },
      {
        field: "shortName",
        header: "Shipper Short Name",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "accountNumber",
        header: "Account Number",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "address.countryIsoCode",
        header: "Country",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.get_enumCountries(),
        filterType: FilterType.contains,
      },

      {
        field: "address.subDivisionIsoCode",
        header: "Region",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.formlist_lookup_subdivisions.bind(this),
        filterType: FilterType.enum,
        filterOptions: this.formlist_filter_subdivisions.bind(this),
        colWidth: 250,
      },
      {
        field: "address.city",
        header: "City",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "address.postalCode",
        header: "Postal Code",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "address.line1",
        header: "Address Line 1",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "address.line2",
        header: "Address Line 2",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
    ];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
