/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { CurrencyPipe } from "@angular/common";
import { Store } from "src/app/core/settings/store-settings/store/store";
import { AppConstants } from "src/app/shared/app-constants";
import { FormatNegativeNumberPipe } from "src/app/shared/pipes/format-negative-number.pipe";
import { MonetaryHelpers } from "src/app/utility/MonetaryHelpers";
import { DiscountType, SaleDoc } from "../sale-doc/sale-doc";
import { Repeat, SaleDocLine } from "../sale-doc-line/sale-doc-line";
import { Address } from "src/app/core/contact-accounts/address/address";
import { PersonalAccount } from "src/app/core/contact-accounts/personal-account/personal-account";
import * as _ from "lodash";
import { RecurringOrderSetting } from "src/app/core/settings/zone-settings/recurring-order-settings/recurring-order-settings";
import { FrequencyName } from "src/app/core/inventory/inventory-usage-detail/inventory-usage-detail";
import { CommercialAccount } from "src/app/core/contact-accounts/commercial-account/commercial-account";
import { Inventory } from "src/app/core/inventory/inventory/inventory";
import { Account } from "src/app/core/contact-accounts/account/account";

export class Commitments {
  id: number;
  docNo: number;
  paymentStatus: CommitmentsState;
  state: CommitmentsState;
  creationDate: Date;
  repeatCycle: Repeat;
  frequencyName: FrequencyName;
  totalCycleAmount: number;
  deliveryMethod: DeliveryMethod;
  shipperChargeAmount: number;
  currencyIsoCode: string;
  storeId: number;
  store: Store;
  recurringOrderSettingId: number;
  recurringOrderSetting: RecurringOrderSetting;
  commitmentCycles: CommitmentCycle[];
  commitmentLines: CommitmentLine[];
  accountId: number;
  accountName: string;
  account?: Account;
  userId: number;
  shippingAddress: Address;
  /** Dynamic server calculated field */
  nextChargeDate: Date;
  /** Dynamic server calculated field */
  nextPickupDate: Date;
  /** Dynamic server calculated field */
  subTotal: number;
  refSaleDoc: SaleDoc;
  refSaleDocId: number;
  saleDocLines: SaleDocLine[];
  commercialAccount: CommercialAccount;
  accountEmail: string[];
  personalAccount?: PersonalAccount;

  constructor() {
    this.id = 0;
    this.docNo = 0;
    this.paymentStatus = null;
    this.state = null;
    this.creationDate = null;
    this.repeatCycle = null;
    this.frequencyName = null;
    this.totalCycleAmount = 0;
    this.deliveryMethod = DeliveryMethod.storePickup;
    this.shipperChargeAmount = 0;
    this.currencyIsoCode = null;
    this.storeId = null;
    this.store = null;
    this.recurringOrderSettingId = 0;
    this.recurringOrderSetting = null;
    this.commitmentLines = null;
    this.commitmentCycles = [];
    this.accountId = 0;
    this.accountName = "";
    this.accountEmail = [];
    this.userId = 0;
    this.shippingAddress = null;
    this.nextChargeDate = null;
    this.nextPickupDate = null;
    this.refSaleDoc = new SaleDoc();
    this.refSaleDocId = 0;
    this.saleDocLines = null;
    this.personalAccount = null;
    this.commercialAccount = null;
  }

  get subTotalCurrencyFormat(): string {
    let formattedTotal: string;

    const discountedPrice = this.subTotal;

    const _currencyIsoCode = "CAD";
    if (_currencyIsoCode)
      formattedTotal = new CurrencyPipe(AppConstants.DEFAULT_LOCALE_ID).transform(
        discountedPrice,
        _currencyIsoCode,
        "code"
      );
    else formattedTotal = MonetaryHelpers.roundToDecimalPlaces(discountedPrice).toFixed(2);
    return new FormatNegativeNumberPipe().transform(formattedTotal) as string;
  }

  get discountTotal(): number {
    return this.commitmentLines
      .map((line) => ((line.unitPrice * +line.discountAmount) / 100) * line.qty)
      .reduce((a, b) => a + b);
  }

  get grandTotal(): number {
    return (
      MonetaryHelpers.roundToDecimalPlaces(this.subTotal, 2) +
      MonetaryHelpers.roundToDecimalPlaces(parseFloat(this.shipperChargeAmount + ""), 2)
    );
  }

  get shippingFeesCurrencyFormat(): string {
    let formattedTotal;
    const _currencyIsoCode = "CAD";
    if (_currencyIsoCode)
      formattedTotal = new CurrencyPipe(AppConstants.DEFAULT_LOCALE_ID).transform(
        this.shipperChargeAmount,
        _currencyIsoCode,
        "code"
      );
    else formattedTotal = MonetaryHelpers.roundToDecimalPlaces(this.shipperChargeAmount).toFixed(2);
    return new FormatNegativeNumberPipe().transform(formattedTotal);
  }

  get discountAmountMoney() {
    switch (this.refSaleDoc.discountType) {
      case DiscountType.Fixed:
        return this.refSaleDoc.discountAmount;

      case DiscountType.Percentage:
        return (this.refSaleDoc.subTotal * this.refSaleDoc.discountAmount) / 100;

      default:
        return 0;
    }
  }

  get notReturnedTenders() {
    // return this.saleDocTenders.filter(line => !line.isReturned);
    return this.refSaleDoc.saleDocTenders;
  }

  static convertIntoTypedObject(commitments: Commitments): Commitments {
    const typedSaleDoc: Commitments = _.merge(new Commitments(), commitments);
    typedSaleDoc.saleDocLines = typedSaleDoc.commitmentLines.map((line) => SaleDocLine.convertIntoTypedObject(line));
    typedSaleDoc.accountEmail = typedSaleDoc.commercialAccount
      ? typedSaleDoc.commercialAccount.commercialAccountEmails.map((m) => m.email)
      : typedSaleDoc.personalAccount.person.personEmails.map((m) => m.email);
    return typedSaleDoc;
  }
}

export class CommitmentLine {
  id: number;
  discountAmount: number | string;
  discountType: DiscountType;
  qty: number;
  unitPrice: number;
  inventoryId: number;
  inventory?: Inventory;

  constructor() {
    this.id = 0;
    this.discountAmount = 0;
    this.discountType = DiscountType.Percentage;
    this.qty = 0;
    this.unitPrice = 0;
    this.inventoryId = 0;
  }
}

export class CommitmentCycle {
  id: number;
  cycleDate: Date;
  refSaleDocId: number;
  refSaleDoc: SaleDoc;
  commitmentId: number;

  constructor() {
    this.id = 0;
    this.cycleDate = null;
    this.refSaleDocId = 0;
    this.refSaleDoc = null;
    this.commitmentId = 0;
  }
}

export enum CommitmentsState {
  Active = "Active",
  Declined = "Declined",
  Paused = "Paused",
  Voided = "Voided",
  Completed = "Completed",
}

export enum DeliveryMethod {
  storePickup = "Store Pickup",
  localDelivery = "Local Delivery",
  shipOut = "Ship Out",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
