<span [hidden]="displayMode !== NestedFormDisplayMode.EDITABLE_FORM">
  <div class="col-12 top-wrapper">
    <div class="grid">
      <div class="col grid">
        <div class="col-12 md:col-6 lg:col-3 drop-container">
          <taku-dropdown
            [disabled]="disableForm"
            [formGroup]="_myForm"
            [options]="enum_barcode_format"
            fieldName="barcodeFormat"
            fieldCaption="Format"
          ></taku-dropdown>
        </div>
        <div class="col-12 md:col-6 lg:col-3 drop-container">
          <taku-dropdown
            [disabled]="disableForm"
            [formGroup]="_myForm"
            [options]="enum_barcode_type"
            fieldName="barcodeType"
            fieldCaption="Type"
          ></taku-dropdown>
        </div>
        <div class="col-12 lg:col-6 bar-code-container">
          <taku-input
            [disabled]="disableForm"
            #barcodeNumber
            [formGroup]="_myForm"
            fieldName="barcode"
            fieldCaption="Barcode #"
          ></taku-input>
        </div>
      </div>
      <div class="col-fixed copy-icon-container">
        <taku-action-button [action]="ACTION_TYPE.COPY" (clickAction)="copyBarcodeToClipboard()"></taku-action-button>
      </div>
    </div>
  </div>
</span>

<ng-template [ngIf]="displayMode === NestedFormDisplayMode.TABULAR_DATA">
  <td class="barcode-format-col">
    <span class="p-column-title">Format:</span>
    {{ barcodeFormatCtrl.value }}
  </td>

  <td class="barcode-type-col">
    <span class="p-column-title">Type:</span>
    {{ barcodeTypeCtrl.value }}
  </td>

  <td class="barcode-number-col">
    <span class="p-column-title">Barcode #:</span>
    {{ barcodeCtrl.value }}
  </td>
</ng-template>
