<div class="card">
  <taku-form-header
    toolbarTitle="Reports"
    iconStyleClass="ui-icon-assessment"
    displayMode="inline"
    [showActionBtns]="false"
    [showBackBtn]="false"
    (backClicked)="goBack()"
  ></taku-form-header>

  <div class="grid controls-container">
    <div class="report-type-container col-12 xl:col-2">
      <span class="p-float-label">
        <p-treeSelect
          [(ngModel)]="selectedReportType"
          [options]="sidebarMenuItems"
          placeholder="Select Report"
          [panelStyle]="{ width: '100%' }"
          [containerStyle]="{ width: '100%', marginTop: '0.36em' }"
          (onNodeSelect)="onNodeSelect($event)"
        ></p-treeSelect>
        <label class="report-type-floating-label">Select Report</label>
      </span>
    </div>

    <div
      class="col-12"
      [ngClass]="{
        'xl:col-8': selectedReportType?.data[1] !== ReportName.INVENTORY_VALUE_REPORT,
        'xl:col-2': selectedReportType?.data[1] === ReportName.INVENTORY_VALUE_REPORT
      }"
    >
      <ng-template reportParams-host></ng-template>
    </div>

    <div class="col-12 xl:col-2">
      <button
        pButton
        class="p-button-salesregister-custom w-full"
        [style]="{ marginTop: '0.36em' }"
        label="Apply Filters"
        (click)="applyFilterParams()"
        [loading]="reportParamsService._isFetchingData"
      ></button>
    </div>
  </div>

  <ng-template reportFormList-host></ng-template>
</div>
