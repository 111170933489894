/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { AuthService } from "src/app/shared/services/auth.service";
import { CommercialAccountsAccessRightsFields } from "../shared/commercial-accounts-access-rights-fields";

export class CommercialAccountAccessRights implements CommercialAccountsAccessRightsFields {
  //interface properties
  isNoteVisible: boolean;
  isStatusVisible: boolean;
  isTaxCategoryVisible: boolean;
  isTaxIdVisible: boolean;
  isCreditLimitVisible: boolean;
  isGeneralTabVisible: boolean;
  isAddressTabVisible: boolean;
  isNoteEnabled: boolean;
  isStatusEnabled: boolean;
  isTaxCategoryEnabled: boolean;
  isTaxIdEnabled: boolean;
  isCreditLimitEnabled: boolean;
  isGeneralTabEnabled: boolean;
  isAddressTabEnabled: boolean;

  //custom properties
  isContactsTabVisible: boolean;
  isContactsTabEnabled: boolean;

  constructor(authService: AuthService) {
    this.isNoteVisible = authService.hasVisiblePermit("Accounts_Menu_Commercial_Accounts_Note");
    this.isStatusVisible = authService.hasVisiblePermit("Accounts_Menu_Commercial_Accounts_Status");
    this.isTaxCategoryVisible = authService.hasVisiblePermit("Accounts_Menu_Commercial_Accounts_Tax_Category");
    this.isTaxIdVisible = authService.hasVisiblePermit("Accounts_Menu_Commercial_Accounts_Tax_ID");
    this.isCreditLimitVisible = authService.hasVisiblePermit("Accounts_Menu_Commercial_Accounts_Credit_Limit");
    this.isGeneralTabVisible = authService.hasVisiblePermit("Accounts_Menu_Commercial_Accounts_ General_Tab");
    this.isAddressTabVisible = authService.hasVisiblePermit("Accounts_Menu_Commercial_Accounts_Address_Tab");
    this.isContactsTabVisible = authService.hasVisiblePermit("Accounts_Menu_Commercial_Accounts_Contacts_Tab");

    this.isStatusEnabled = authService.hasEnablePermit("Accounts_Menu_Commercial_Accounts_Status");
    this.isTaxCategoryEnabled = authService.hasEnablePermit("Accounts_Menu_Commercial_Accounts_Tax_Category");
    this.isTaxIdEnabled = authService.hasEnablePermit("Accounts_Menu_Commercial_Accounts_Tax_ID");
    this.isCreditLimitEnabled = authService.hasEnablePermit("Accounts_Menu_Commercial_Accounts_Credit_Limit");
    this.isNoteEnabled = authService.hasEnablePermit("Accounts_Menu_Commercial_Accounts_Note");
    this.isGeneralTabEnabled = authService.hasEnablePermit("Accounts_Menu_Commercial_Accounts_ General_Tab");
    this.isAddressTabEnabled = authService.hasEnablePermit("Accounts_Menu_Commercial_Accounts_Address_Tab");
    this.isContactsTabEnabled = authService.hasEnablePermit("Accounts_Menu_Commercial_Accounts_Contacts_Tab");
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
