<div class="grid bottom-left" [formGroup]="saleForm">
  <div class="col-12 p-fluid invoiceSummary-wrapper pb-0">
    <button
      pButton
      class="p-button-primary-dark"
      [label]="invoiceSummaryLabel(_countTotalItems | formatNegativeNumber)"
      [disabled]="isTenderScreenOpened"
      (click)="summaryOpened.emit($event)"
    ></button>
  </div>

  <div class="p-fluid parkSales-wrapper" [ngClass]="allowReturns ? 'col-3' : 'col-6'">
    <button
      pButton
      class="p-button-primary-dark"
      label="Park"
      (click)="onParkBtnPressed()"
      [disabled]="orderEditLock || isTenderScreenOpened"
    ></button>
  </div>

  <div *ngIf="allowReturns" class="col-3 p-fluid returnItem-wrapper">
    <button
      pButton
      class="p-button-primary-dark"
      label="Exchange"
      (click)="itemReturnedPressed()"
      [disabled]="orderEditLock || isTenderScreenOpened || isReturnEnabled || preorderSelected"
    ></button>
  </div>

  <div class="col-6 p-fluid invoiceTotal-wrapper">
    <button
      pButton
      [ngClass]="{
        'p-button-salesregister-custom': invoiceTotal >= 0,
        'p-button-returns-custom': invoiceTotal < 0,
        'tender-warning': showTenderWarningStyles
      }"
      (click)="onPayBtnPressed()"
      [disabled]="saleDocLines.length == 0 || isTenderScreenOpened || shouldDisablePay"
    >
      <div class="line">
        <div class="col1">
          <i
            class="icon relative"
            [ngClass]="{ 'pi pi-shopping-cart': invoiceTotal >= 0, 'material-icons': invoiceTotal < 0 }"
          >
            {{ invoiceTotal < 0 ? "remove_shopping_cart" : "" }}
            <span *ngIf="showTenderWarningStyles" class="tender-icon-mobile">$</span>
          </i>
        </div>
        <div fittext [activateOnResize]="true" [maxFontSize]="40" fontUnit="pt" class="col2">
          <span>{{ invoiceTotal | currency : currencyIsoCode : "symbol-narrow" : "1.2" | formatNegativeNumber }}</span>
        </div>
      </div>
    </button>
  </div>
</div>
