/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { DBService } from "../../../shared/services/db.service";
import { FormListModel } from "../../../form-list/form-list/form-list-model.interface";
import { ValidatorFn } from "@angular/forms";
import {
  DataType,
  FilterType,
  Col,
  ExecuteEventDatatypeOptions,
  ExecuteEvent_DisplayMode,
} from "../../../form-list/form-list/form-list";
import { EventEmitter, Injectable } from "@angular/core";
import { SelectItem } from "primeng/api";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { User } from "../../users/user/user";
import * as _ from "lodash";
import { Cashout } from "../cashout/cashout";
import { ApiListResponse } from "src/app/utility/types";

@Injectable({
  providedIn: "root",
})
export class CashoutHistoryService extends DBService implements FormListModel {
  enum_status: SelectItem[] = [
    { label: "Open", value: false },
    { label: "Closed", value: true },
  ];

  getCashoutDetails = new EventEmitter<Cashout>();

  _disableCloning = true;

  get_lookup_users(): Observable<SelectItem[]> {
    return this.getRows<ApiListResponse<User>>("user", "", 0, 500).pipe(
      map((myObjects) => {
        return myObjects.rows.map((myObject) => {
          const user: User = _.merge(new User(), myObject);
          return { label: user.person.fullName, value: user.id };
        });
      })
    );
  }

  lookup_stores(): Observable<SelectItem[]> {
    //get all active stores that are part of an active zone
    const activeFilter = {
      isActive: { matchMode: "equals", value: true },
      "zone.isActive": { matchMode: "equals", value: true },
    };
    return this.lookupSelectOptions("store", "storeName", {
      lookupFilter: JSON.stringify(activeFilter),
      enableFieldName: "isActive",
    }).pipe(
      map((storeItems: SelectItem[]) => {
        return this.addDefaultValueToLookup(storeItems, "All Stores");
      })
    );
  }

  addDefaultValueToLookup(items: SelectItem[], defaultMessage: string) {
    items.unshift({
      label: defaultMessage,
      value: 0,
    });

    if (items.length >= 2 && items[1].label == "") {
      //if the second item is the default empty option. We want the empty option to be first.
      //so we swap the first and second items.
      const temp = items[1];
      items[1] = items[0];
      items[0] = temp;
    }
    return items;
  }

  getTakuDocLinkCaption(rowData: Cashout) {
    return rowData.id ? rowData.id : "No ID";
  }

  getValidationRules(): { [key: string]: {} | ValidatorFn[] } {
    return {};
  }

  getFormListColumns() {
    return <Col[]>[
      {
        field: "id",
        header: "Cashout ID",
        visible: true,
        readonly: true,
        frozen: true,
        dataType: DataType.execute_event,
        dataOptions: [],
        dataTypeOptions: [
          new ExecuteEventDatatypeOptions({
            displayMode: ExecuteEvent_DisplayMode.LINK,
            label: this.getTakuDocLinkCaption,
            event: this.getCashoutDetails,
            enabledOnEditMode: false,
            styleClass: "",
          }),
        ],
        filterType: FilterType.contains,
        colWidth: 120,
      },
      {
        field: "store.storeName",
        header: "Store Name",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 200,
      },
      {
        field: "station.stationNumber",
        header: "Station #",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 150,
      },
      {
        field: "status",
        header: "Status",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: this.enum_status,
        filterType: FilterType.enum,
        colWidth: 100,
        filterField: "isClosed",
      },
      {
        field: "docsCount",
        header: "# of Docs",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 100,
        isNotSortable: true,
      },
      {
        field: "openingCashFloatUserId",
        fieldValue: "openingCashFloatUser.userName",
        header: "Opening User",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.lookup,
        dataOptions: this.get_lookup_users(),
        filterType: FilterType.lookup,
        colWidth: 150,
        sortFields: ["openingCashFloatUser.userName"],
      },
      {
        field: "closingCashFloatUserId",
        fieldValue: "closingCashFloatUser.userName",
        header: "Closing User",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.lookup,
        dataOptions: this.get_lookup_users(),
        filterType: FilterType.lookup,
        colWidth: 150,
        sortFields: ["closingCashFloatUser.userName"],
      },
      {
        field: "openingDateAndTime",
        header: "Opening Date",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 150,
        filterField: "openingDate",
        sortFields: ["openingDate", "openingTime"],
      },
      {
        field: "closingDateAndTime",
        header: "Closing Date",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 150,
        filterField: "closingDate",
        sortFields: ["closingDate", "closingTime"],
      },
      {
        field: "systemTotalFormated",
        header: "System Total",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 150,
        isNotSortable: true,
      },
      {
        field: "actualTotalFormated",
        header: "Actual Total",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 150,
        isNotSortable: true,
      },
      {
        field: "totalOverUnderFormated",
        header: "Over/Under (+/-)",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 150,
        isNotSortable: true,
      },
    ];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
