/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ValidatorFn, Validators } from "@angular/forms";
import { DataType, FilterType, Col } from "../../../../form-list/form-list/form-list";
import { FormListModel } from "../../../../form-list/form-list/form-list-model.interface";
import { AuthService } from "../../../../shared/services/auth.service";
import { DBService } from "../../../../shared/services/db.service";
import { StoreService } from "../store/store.service";
import { CommonValidators } from "../../../../shared/validators/CommonValidators";
import { Observable } from "rxjs";
import { SelectItem } from "primeng/api";
import { Station, Station_Type } from "./station";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";

@Injectable({
  providedIn: "root",
})
export class StationService extends DBService implements FormListModel {
  enumStationTypes = this.enumSelectOptions(Station_Type);

  constructor(
    protected http: HttpClient,
    protected authService: AuthService,
    private storeService: StoreService,
    private appSettings: AppSettingsStorageService
  ) {
    super(http, authService);
  }

  lookupAllPrinters(): Observable<SelectItem[]> {
    return this.lookupSelectOptions("printer", "name", { enableFieldName: "isActive" });
  }

  getValidationRules(): { [key: string]: ValidatorFn[] | {} } {
    return {
      stationName: [Validators.required],
      stationNumber: [Validators.required, CommonValidators.positiveInteger()],
    };
  }

  afterSave(station: Station, model: string) {
    if (model === "station" && station.id === this.appSettings.getStationId()) this.appSettings.updateStation(station);
  }

  getFormListColumns() {
    const lookupAllPrinters = this.lookupAllPrinters();
    return [
      {
        field: "storeId",
        exportable: false,
        header: "Store",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.lookup,
        dataOptions: this.storeService.lookup_stores(),
        filterType: FilterType.lookup,
        sortFields: ["store.storeName"],
      },
      {
        field: "isActive",
        header: "Active",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.checkbox,
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.enum,
      },
      {
        field: "stationName",
        header: "Station Name",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "stationNumber",
        header: "Station #",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "stationType",
        header: "Station Type",
        visible: true,
        readonly: !this.appSettings.getUser().isSuperAdmin,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enumStationTypes,
        filterType: FilterType.enum,
      },
      {
        field: "receiptPrinterId",
        header: "Receipt Printer",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.lookup,
        dataOptions: lookupAllPrinters,
        filterType: FilterType.lookup,
      },
      {
        field: "prepPrinterId",
        header: "Prep Printer",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.lookup,
        dataOptions: lookupAllPrinters,
        filterType: FilterType.lookup,
      },
      {
        field: "packingListPrinterId",
        header: "Packing-List Printer",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.lookup,
        dataOptions: lookupAllPrinters,
        filterType: FilterType.lookup,
      },
    ];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
