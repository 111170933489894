/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ElementRef,
  OnChanges,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { AngularFittextDirective } from "angular-fittext";
import * as _ from "lodash";
import { ConfirmationService, SelectItem } from "primeng/api";
import { DBService } from "../../../../shared/services/db.service";
import { MonetaryHelpers } from "../../../../utility/MonetaryHelpers";
import { GenericSaleDocFooterComponent } from "../../sale-document/generic-sale-doc-footer.component";
import { TotalBySaleTax } from "../../sale-document/sale-doc-line/sale-doc-line";
import { DiscountType, SaleDoc } from "../../sale-document/sale-doc/sale-doc";
import { InvoiceKeyboardActionsService } from "../../sale-document/sale-invoice-doc-base/invoice-keyboard-actions.service";
import { ReturnInvoiceStateService } from "../return-invoice-state.service";
import { TakuInputComponent } from "src/app/taku-ui/taku-input/taku-input.component";
import { SaleDocService } from "../../sale-document/sale-doc/sale-doc.service";

@Component({
  selector: "taku-return-sale-doc-footer",
  templateUrl: "./return-sale-doc-footer.component.html",
  styleUrls: ["./return-sale-doc-footer.component.scss"],
})
export class ReturnSaleDocFooterComponent extends GenericSaleDocFooterComponent implements OnInit, OnChanges {
  @Input() allowNoInvoiceReturns = false;
  @Input() currencyIsoCode: string;
  // @Output() saleParked: EventEmitter<number> = new EventEmitter;
  @Output() openDeliveryDialog: EventEmitter<number> = new EventEmitter();
  @Output() docTotalChanged: EventEmitter<number> = new EventEmitter();
  @Output() noReceiptItem: EventEmitter<any> = new EventEmitter();
  @ViewChild("generalDiscountAmount", { static: true }) discAmountInputComponent: TakuInputComponent;
  @ViewChild(AngularFittextDirective, { static: true }) invoiceTotalAmountDirective: AngularFittextDirective;
  groupedTaxes: TotalBySaleTax[] = [];
  _decimalFormatting = "1.2-2";

  get invoiceSubTotal(): number {
    return this.saleForm.get("subTotal").value;
  }
  set invoiceSubTotal(newSubtotal) {
    this.saleForm.get("subTotal").setValue(newSubtotal, { emitEvent: false });
  }

  lookupShippers: SelectItem[];
  invoiceTotalTax = 0;
  enumDiscountType: SelectItem[] = [
    { label: "%", value: DiscountType.Percentage },
    { label: "$", value: DiscountType.Fixed },
  ];
  // Overlays visibility
  _overlaysVisibility: { [key: string]: boolean } = {
    discount: false,
    shipping: false,
    taxes: false,
  };

  constructor(
    confirmationService: ConfirmationService,
    dbService: SaleDocService,
    invoiceStateService: ReturnInvoiceStateService,
    keyboardActionsService: InvoiceKeyboardActionsService,
    elRef: ElementRef
  ) {
    super(confirmationService, dbService, invoiceStateService, keyboardActionsService, elRef);
  }

  onDiscAmountClicked() {
    setTimeout(() => {
      this.discAmountInputComponent.focusAndSelectInput();
    }, 0);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subsList.push(
      this.docTotalChanged.subscribe((newTotal) => {
        if (this.invoiceTotalAmountDirective) this.invoiceTotalAmountDirective.onWindowResize();
      })
    );
  }

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes.saleForm) {
      this.updateInvoiceSubTotal();
      this.updateInvoiceTotalTax();
      this.subsList.push(
        this.saleForm.valueChanges.subscribe((newValue) => {
          this.updateInvoiceSubTotal();
          this.updateInvoiceTotalTax();
        })
      );
    }
  }

  onNoReceiptPressed() {
    this.noReceiptItem.emit();
  }

  onDeliveryLinkPressed() {
    this.openDeliveryDialog.emit();
  }

  get _countTotalItems(): number {
    let countItems = 0;
    this.saleDocLines.controls.forEach((docLineForm: UntypedFormGroup) => {
      const qty = parseFloat(docLineForm.get("qty").value);
      if (!isNaN(qty)) countItems += qty;
    });

    return MonetaryHelpers.roundToDecimalPlaces(countItems, 4);
  }

  updateInvoiceSubTotal() {
    let subTotal = 0;
    for (let _i = 0; _i < this.saleDocLines.length; _i++) {
      subTotal += this.saleDocLines.at(_i).get("qty").value * this.saleDocLines.at(_i).get("salePrice").value;
    }
    // Update respective form field for server-side
    // TODO: Move this to another method, as right now it's causing Angular warnings
    // about the view being modified after checked
    // this.saleForm.get('subTotal').setValue(subTotal);

    this.invoiceSubTotal = subTotal;
  }

  get linesSubtotalDiscount(): number {
    // TODO: This method should be revised with proper promotion system
    // for now i assumed discount can be a percentage
    let totalDisc = 0;
    for (let _i = 0; _i < this.saleDocLines.length; _i++) {
      totalDisc +=
        (this.saleDocLines.at(_i).get("qty").value *
          (this.saleDocLines.at(_i).get("discountAmount").value * this.saleDocLines.at(_i).get("salePrice").value)) /
        100;
    }
    return totalDisc;
  }

  get invoiceGeneralDiscount(): number {
    const discountType = this.saleForm.get("discountType").value;
    const discountAmount = this.saleForm.get("discountAmount").value;
    let totalDiscount = 0;
    // If discount amount is empty just return the initial unitprice
    if (discountAmount === "" || discountAmount === null) return 0;

    switch (discountType) {
      case DiscountType.Fixed:
        totalDiscount = discountAmount;
        break;
      case DiscountType.Percentage:
        totalDiscount = (this.invoiceSubTotal * discountAmount) / 100;
        break;
    }
    // Round to only 2 decimal places
    return MonetaryHelpers.roundToDecimalPlaces(totalDiscount);
  }

  updateInvoiceTotalTax() {
    // let totalTax = 0;
    // for (var _i = 0; _i < this.saleDocLines.length; _i++) {
    //   const saleDocLine:SaleDocLine = SaleDocLine.convertIntoTypedObject( this.saleDocLines.at(_i).value );
    //   totalTax += saleDocLine.totalTaxes;
    // }
    // this.invoiceTotalTax = totalTax;
    this.groupedTaxes = SaleDoc.convertIntoTypedObject(this.saleForm.value).groupedTaxLines;
    this.invoiceTotalTax = _.sumBy(this.groupedTaxes, (tax) => tax.total);
  }

  get invoiceTotal(): number {
    const total = this.invoiceSubTotal - this.invoiceGeneralDiscount + this.invoiceTotalShipping + this.invoiceTotalTax;

    // for (var _i = 0; _i < this.saleDocLines.length; _i++) {
    //   total += (this.saleDocLines.at(_i).get('qty').value * this.saleDocLines.at(_i).get('discountAmount').value);
    // }
    return total;
  }

  get invoiceTotalShipping(): number {
    const shippingCharge = this.saleForm.get("shipperChargeAmount").value;
    if (shippingCharge === "" || shippingCharge === null) return 0;

    return MonetaryHelpers.roundToDecimalPlaces(parseFloat(shippingCharge));
  }

  get _shipperName(): string {
    if (!this.lookupShippers) return null;

    const shipperId = this.saleForm.get("shipperId").value;
    const selectedShipper = this.lookupShippers.find((shipper) => shipper.value === shipperId);
    if (!selectedShipper) return null;

    return selectedShipper.label;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
