/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { isNumber, isBoolean, isEmpty, escapeRegExp } from "lodash";
import * as moment from "moment";

export class CommonValidators {
  static websiteURL(): ValidatorFn {
    return (c: AbstractControl): { [key: string]: any } | null => {
      // Source: Diego Perini. https://gist.github.com/dperini/729294
      const regexp =
        /^(?:(?:(?:https?|ftp):)?\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
      const fieldValue: string = c.value;
      if (c.pristine || !fieldValue) return null;

      const matches = fieldValue.match(regexp);
      if (matches === null) {
        return { url: true };
      }

      return null;
    };
  }

  static boolean_value() {
    return (c: AbstractControl): { [key: string]: any } | null => {
      if (c.value && !isBoolean(c.value)) {
        return { boolean_value: { value: c.value } };
      }

      return null;
    };
  }

  static completeTelephone(length) {
    return (c: AbstractControl): { [key: string]: any } | null => {
      if (c.value) {
        const cleanTel = c.value.replace(/^\+\d+/, "").replace(/[^\d]/g, "");
        if (cleanTel && cleanTel.length !== length) return { incomplete_tel: { value: c.value } };
      }

      return null;
    };
  }

  static requiredTelephone() {
    return (c: AbstractControl): { [key: string]: any } | null => {
      if (c.value) {
        const cleanTel = c.value.replace(/^\+\d+/, "").replace(/[^\d]/g, "");
        if (!cleanTel.length) return { required: { value: c.value } };
      }

      return null;
    };
  }

  static requiresField(fieldName: string, errorMsg?: string) {
    return (c: AbstractControl): { [key: string]: any } | null => {
      if (!c.parent.controls[fieldName]?.value) {
        return { generic: errorMsg ?? `Missing required field ${fieldName}` };
      }
      return Validators.required(c.parent.controls[fieldName]);
    };
  }

  static requiredIfFieldNotEmpty(fieldName: string) {
    return (c: AbstractControl): { [key: string]: any } | null => {
      if (c.parent.controls[fieldName]?.value) {
        return Validators.required(c);
      }
      return null;
    };
  }

  static lookup_singleSelect(genericErrorMessage?: string): ValidatorFn {
    return (c: AbstractControl): { [key: string]: any } | null => {
      if (c.value && !isNumber(c.value)) {
        if (genericErrorMessage) {
          return { generic: genericErrorMessage };
        }
        return { lookup_singleSelect: { value: c.value } };
      }

      return null;
    };
  }

  static lookup_multiSelect(): ValidatorFn {
    return (c: AbstractControl): { [key: string]: any } | null => {
      if (Array.isArray(c.value)) {
        const invalidValues = c.value.filter((item) => !item || !isNumber(item.id)).map((value) => `'${value}'`);
        if (!isEmpty(invalidValues)) return { lookup_multiSelect: { values: invalidValues } };
      }

      return null;
    };
  }

  // static enum_selectItem(validOptions:SelectItem[]): ValidatorFn {
  //     return (c:AbstractControl): {[key: string]: any} | null => {
  //         if (c.value && !validOptions.find(item => item.value == c.value || item.label == c.value))
  //             return { 'enum_selectItem': {value: c.value} }

  //         return null;
  //     }
  // }

  static enum_singleSelect(validEnumOptions: any[], genericErrorMessage?: string): ValidatorFn {
    return (c: AbstractControl): { [key: string]: any } | null => {
      if (c.value && !validEnumOptions.includes(c.value)) {
        if (genericErrorMessage) {
          return { generic: genericErrorMessage };
        }
        return { enum_singleSelect: { value: c.value } };
      }

      return null;
    };
  }

  static minLengthArray(min: number) {
    return (c: AbstractControl): { [key: string]: any } => {
      if (c.value.length >= min) return null;

      return { minLengthArray: { length: min } };
    };
  }

  static greaterThan(min: number): ValidatorFn {
    return (c: AbstractControl): { [key: string]: any } => {
      if (Number(c.value) > min) return null;

      return { greaterThan: { min } };
    };
  }

  static inputMask(mask: string, numericChar, alphaChar, errorType = "mask") {
    let regexpStr = escapeRegExp(mask);
    if (numericChar) regexpStr = regexpStr.replace(new RegExp(escapeRegExp(numericChar), "g"), "\\d");
    if (alphaChar) regexpStr = regexpStr.replace(new RegExp(escapeRegExp(alphaChar), "g"), "\\w");
    regexpStr = `^${regexpStr}$`; // Validation from begining to end of field value

    return CommonValidators.regexMatchValidator(new RegExp(regexpStr), { [errorType]: true });
  }

  static phoneNumber(): ValidatorFn {
    return this.regexMatchValidator(/^[0-9()-\s]+$/, { phoneNumber: true });
  }

  static digits(): ValidatorFn {
    return this.regexMatchValidator(/^[0-9.]+$/, { digits: true });
  }

  static number(): ValidatorFn {
    return this.regexMatchValidator(/^[0-9.-]+$/, { digits: true });
  }

  static decimal(precision: number, decimals: number): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      if (!c.value?.length) return null;

      const num = parseFloat(c.value);
      const maxInts = precision - decimals;

      if (isNaN(num) || Math.trunc(num).toString().length > maxInts) return { decimal: { maxDigits: maxInts } };

      return null;
    };
  }

  static negativeNumber(): ValidatorFn {
    return this.regexMatchValidator(/^-[0-9.,]+$/, { negativeNumber: true });
  }

  static integer(): ValidatorFn {
    return this.regexMatchValidator(/^[0-9-]+$/, { integer: true });
  }

  static positiveInteger(): ValidatorFn {
    return this.regexMatchValidator(/^[0-9]+$/, { pinteger: true });
  }

  static urlAddressSegment(): ValidatorFn {
    return this.regexMatchValidator(/^[0-9a-zA-Z]+$/, { urlSegment: true });
  }

  static serverValidator(origErrorState, errorMessage, errorData, ctrlName): ValidatorFn {
    return (c: AbstractControl): { [key: string]: any } | null => {
      if (c.value === origErrorState)
        return {
          "server-error": { errorName: errorMessage, errorData: errorData, fields: ctrlName },
        };
      else return null;
    };
  }

  private static regexMatchValidator(regexp: RegExp, errorObject): ValidatorFn {
    return (c: AbstractControl): { [key: string]: any } | null => {
      let fieldValue: string = c.value;
      if (!fieldValue) return null;

      fieldValue += ""; // Ensure fieldValue is converted to string in order to use match function
      const matches = fieldValue.match(regexp);
      if (matches === null) {
        return errorObject;
      }

      return null;
    };
  }

  public static currentTimeValidator(validPast: boolean, validFuture: boolean): ValidatorFn {
    return (c: AbstractControl): { [key: string]: any } | null => {
      const validatorErrors = {};

      const fieldDate: Date = moment(c.value).toDate();
      if (!fieldDate)
        // if field is empty, we don't validate. Let's delegate this to required vaidator
        return null;

      const currTime = new Date();
      if (!validPast && fieldDate.getTime() < currTime.getTime()) validatorErrors["invalidPast"] = { value: fieldDate };

      if (!validFuture && fieldDate.getTime() > currTime.getTime())
        validatorErrors["invalidFuture"] = { value: fieldDate };

      if (!Object.keys(validatorErrors).length)
        // We don't have validation errors
        return null;

      return validatorErrors;
    };
  }

  public static dateTimeValidator: ValidatorFn = (control) => {
    if (!control.value || moment(control.value).isValid()) {
      return null;
    }
    return { errorType: "date" };
  };
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
