<div [formGroup]="formGroup">
  <span class="p-float-label">
    <input [attr.disabled]="disabled ? true : null" pInputText formControlName="{{ fieldName }}" />
    <label for="{{ fieldName }}" [innerHTML]="fullFieldCaption"></label>
    <i class="material-icons clear-field" (click)="clear()" *ngIf="formGroup.controls[fieldName].value">close</i>
  </span>
  <form-validation-messages
    [hidden]="formGroup.get(fieldName).valid || !formGroup.get(fieldName).dirty"
    [errors]="formGroup.get(fieldName).errors"
    [fieldName]="fieldName"
  ></form-validation-messages>
  <!-- <form-validation-messages *ngIf="formGroup.get(fieldName).touched" [errors]="formGroup.get(fieldName).errors"></form-validation-messages> -->
</div>
