/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, ElementRef, Input } from "@angular/core";
import { TakuInputCtrlBase } from "../TakuInputCtrlBase";

@Component({
  selector: "taku-checkbox",
  templateUrl: "./taku-checkbox.component.html",
  styleUrls: ["./taku-checkbox.component.scss"],
})
export class TakuCheckboxComponent extends TakuInputCtrlBase {
  constructor(element: ElementRef) {
    super(element);
  }

  ngOnInit() {
    super.ngOnInit();
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
