import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { Category } from "src/app/core/inventory/category/category";
import { Inventory, InventoryStock } from "src/app/core/inventory/inventory/inventory";
import { Store } from "src/app/core/settings/store-settings/store/store";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { DBService } from "src/app/shared/services/db.service";
import { DocumentSearchReturnColsServiceFactory } from "../../return-document/document-search-return/document-search-return.service";
import { InvoiceKeyboardActionsService } from "../sale-invoice-doc-base/invoice-keyboard-actions.service";
import { SaleInvoiceDocBaseComponent } from "../sale-invoice-doc-base/sale-invoice-doc-base.component";
import { SaleInvoiceStateService } from "../sale-invoice-state.service";
import * as _ from "lodash";
import { DBSearchService } from "src/app/shared/services/db-search.service";
import { MonetaryHelpers } from "src/app/utility/MonetaryHelpers";
import { SaleDocService } from "../sale-doc/sale-doc.service";
import { InventoryDataViewComponent } from "../inventory-data-view/inventory-data-view.component";
import { CategoryDataViewComponent } from "../category-data-view/category-data-view.component";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { SaleDocLine } from "../sale-doc-line/sale-doc-line";
import { ConfirmationService } from "primeng/api";
import { ActivatedRoute, Router } from "@angular/router";
import { SaleDocLineComponent } from "../sale-doc-line/sale-doc-line.component";
import { SelfCheckoutItemDetailsComponent } from "../self-checkout-item-details/self-checkout-item-details.component";
import { throttleTime, filter } from "rxjs/operators";
import { SaleDocType } from "../../doc/doc";
import { FilterRows } from "src/app/utility/FormDataHelpers";
import { IdleService } from "src/app/idle.service";

@Component({
  selector: "taku-sale-doc-self-checkout-wrapper-base",
  templateUrl: "./sale-doc-self-checkout-wrapper-base.component.html",
  styleUrls: ["./sale-doc-self-checkout-wrapper-base.component.scss"],
})
export class SaleDocSelfCheckoutWrapperBaseComponent extends SaleInvoiceDocBaseComponent implements OnInit, OnDestroy {
  protected static readonly MAX_INITIAL_RESULTS = 10;
  protected static readonly MAX_MORE_RESULTS = 25;

  // these two input properties are only for mobile
  // @Input() dvWidth: number;
  // @Input() dvHeight: number;

  inventories: Inventory[];
  orgInventories: Inventory[];
  categories: Category[];

  _showInventoryItems = false;
  _productsLoading = false;
  activeStore: Store;

  currentFetchLimit: number = SaleDocSelfCheckoutWrapperBaseComponent.MAX_INITIAL_RESULTS;
  lazyLoadFilter = "";
  // _extraQueryParams: {};
  totalRecords: number;
  totalCategories: number;
  private readonly allCategoriesFilter: FilterRows<Category> = {
    isEnabled: {
      value: true,
      matchMode: "equals",
    },
    includedInGallery: {
      value: true,
      matchMode: "equals",
    },
  };
  currentCategory: Category;
  lastSelectedCategory: Category;

  appSettingsService: AppSettingsStorageService;
  // protected exitPassword: string ='';
  saleDocDBService: SaleDocService;
  searchingFromSearchField = false;

  @ViewChild("saleDocDataView") saleDocDataViewComponent: InventoryDataViewComponent | CategoryDataViewComponent;
  // @ViewChild('categoryDataView') categoryDataViewComponent: CategoryDataViewComponent;

  constructor(
    public dataService: DBSearchService,
    saleDocService: SaleDocService,
    viewContainer: ViewContainerRef,
    keyboardActionsService: InvoiceKeyboardActionsService,
    dbService: SaleDocService,
    protected saleInvoiceStateService: SaleInvoiceStateService,
    docSearchServiceFactory: DocumentSearchReturnColsServiceFactory,
    protected authService: AuthService,
    public dialogService: DialogService,
    appSettingsStorageService: AppSettingsStorageService,
    protected confirmationService: ConfirmationService,
    protected _route: ActivatedRoute,
    protected _router: Router,
    public fb: UntypedFormBuilder,
    public ref: DynamicDialogRef,
    public idleService: IdleService
  ) {
    super(
      viewContainer,
      keyboardActionsService,
      dbService,
      saleInvoiceStateService,
      docSearchServiceFactory,
      authService,
      _route,
      _router,
      dialogService,
      fb,
      ref
    );

    this.saleDocDBService = saleDocService;
    this.appSettingsService = appSettingsStorageService;

    this.subsList.push(
      this.keyboardActionsService.anyKeyPressed$
        .pipe(
          throttleTime(200), // Adjust the throttle time as needed
          filter(
            (event) =>
              this.keyboardActionsService._canAnnounceNumKeyPress &&
              !(document.activeElement instanceof HTMLInputElement) &&
              !(document.activeElement instanceof HTMLTextAreaElement) &&
              !isNaN(Number(event.key)) &&
              !this._activeDialog
          )
        )
        .subscribe(() => {
          this.saleDocDataViewComponent.focusSearchInput();
        })
    );
  }

  ngOnInit(): void {
    super.ngOnInit();

    setTimeout(() => {
      // this.getAllCategories(0, this.newFetchLimit);
      this.getAllCategories(0, this.saleDocDataViewComponent?.newFetchLimit());
      // this.saleDocDataViewComponent.autoSelectSearchField();
    }, 0);

    this.activeStore = this.appSettingsService.getStore();

    // this.subsList.push(this.appSettingsService.getKioskStoreSettingDetails().subscribe((kioskSettingsData) => {
    //   this.exitPassword = kioskSettingsData.password;
    // }))
  }

  ngOnDestroy(): void {
    this.ref?.close(false);
    super.ngOnDestroy();
  }

  get storeName(): string {
    return this.activeStore.storeName;
  }

  searchInventory(offset: number, query?: string, _updatedFetchLimit?: number, categoryId?: number): void {
    this.idleService.reset();
    const activeFilter: FilterRows<Inventory> = {
      parentInventoryId: { matchMode: "equals", value: null, allowNull: true },
      isSellable: { matchMode: "equals", value: true },
      includedInGallery: { matchMode: "equals", value: true },
    };
    this._productsLoading = true;
    const defaultStockId = this.appSettingsService.getStore().stockId;
    const zoneId = this.appSettingsService.getZoneId();
    const _extraQueryParams = {
      stockId: defaultStockId,
      zoneId: zoneId,
    };
    let searchQuery: string;
    this.saleDocDataViewComponent.clearSearchField();
    offset = offset || 0;
    if (query) {
      searchQuery = query;
    } else {
      searchQuery = this.saleDocDataViewComponent?.searchFieldControl?.value || "";
    }
    if (_updatedFetchLimit) {
      this.updateFetchLimit(_updatedFetchLimit);
    }
    if (categoryId) {
      activeFilter["categories.id"] = { matchMode: "equals", value: categoryId };
    }

    this.subsList.push(
      this.dataService
        .searchInventory({
          searchValue: searchQuery,
          _filter: JSON.stringify(activeFilter),
          _offset: offset,
          _limit: 500,
          extraQueryParams: _extraQueryParams,
        })
        .subscribe({
          next: (results) => {
            if (results) {
              this.totalRecords = results.count;
              if (results.count > 0) {
                const sellableInventories = results.rows; //.filter((inventory) => inventory.isSellable);
                // if(results.count === 1 && searchQuery) {
                if (sellableInventories.length === 1 && searchQuery) {
                  this.searchingFromSearchField = false;
                  if (sellableInventories[0].isVariantParent) {
                    const saleLine: SaleDocLine = _.merge(new SaleDocLine(), {
                      unitPrice: sellableInventories[0].standardPrice,
                      qty: 1,
                      // TODO: Fill out DocLine
                      docLine: {
                        id: 0,
                        seqNo: 1,
                        note: "",
                        expiryDate: null,
                        serialNo: "",
                        refNo: 0,
                        inventoryId: sellableInventories[0].id,
                        inventory: sellableInventories[0],
                      },
                    });
                    const docLineForm = SaleDocLineComponent.set(this.fb, saleLine, 0);

                    this.ref = this.dialogService.open(SelfCheckoutItemDetailsComponent, {
                      styleClass: "selfcheckout-mobile rounded-selfcheckout-dialog",
                      contentStyle: {
                        overflow: "auto",
                        height: "60%",
                        minWidth: "280px",
                        maxWidth: "680px",
                        maxHeight: "1100px",
                      },
                      data: {
                        _saleForm: docLineForm,
                        _isAddToCart: true,
                      },
                    });

                    this.subsList.push(
                      this.ref.onClose.subscribe((result) => {
                        if (result && result.qtySelected > 0) {
                          this.docComponent.addToSaleDoc(docLineForm.value.docLine.inventory, result.qtySelected);
                          // let itemIndexInCart = 0;
                          // this.docComponent.saleDocLines.value.forEach((element, index) => {
                          //   if (element.docLine.inventoryId === sellableInventories[0].id ) {
                          //     itemIndexInCart = index;
                          //   }
                          // });
                          // if (result !== undefined) {
                          //   const newSaleDocLine =  this.docComponent.saleDocLines.at(itemIndexInCart);
                          //   const originalQty = newSaleDocLine.get('qty').value -1;
                          //   let newQty = 0;
                          //   newQty = originalQty + result.qtySelected;
                          //   newSaleDocLine.patchValue({ qty: newQty });
                          // }
                          this.saleDocDataViewComponent.clearSearchField();
                          this.saleDocDataViewComponent.autoSelectSearchField();
                        }
                      })
                    );
                  } else {
                    this.docComponent.addToSaleDoc(sellableInventories[0]);
                    this.saleDocDataViewComponent.clearSearchField();
                    this.saleDocDataViewComponent.autoSelectSearchField();
                  }
                } else if (sellableInventories.length > 0) {
                  // this.inventories = [...results.rows];
                  // this.orgInventories = _.cloneDeep(results.rows);
                  this.inventories = [...sellableInventories];
                  this.orgInventories = _.cloneDeep(sellableInventories);
                  this.inventories.map((_inventory) => {
                    if (!_inventory["defaultInventoryStock"]) {
                      _inventory["defaultInventoryStock"] = new InventoryStock();
                    }
                    _inventory["defaultInventoryStock"]["committedQty"] = MonetaryHelpers.roundToDecimalPlaces(
                      (_inventory?.defaultInventoryStock?.committedQty || 0) +
                        this.docComponent._myForm.value.saleDocLines
                          .filter((row) => row.docLine.inventoryId === _inventory.id)
                          .map((row) => row.qty)
                          .reduce((a, b) => a + b, 0),
                      4
                    );
                  });
                  // this.totalRecords = results.count;
                  // this.totalRecords = sellableInventories.length;

                  if (this._showInventoryItems) {
                    // this.currentCategory = undefined;
                    // this will override the currentCategory name as search result. Can be removed when no side effects
                  } else {
                    this._showInventoryItems = true;
                  }
                } else {
                  this.searchingFromSearchField = false;
                  // this item cannot be sold confirmationservice
                  this.confirmationService.confirm({
                    header: `Inventory search: Item Cannot Be Sold`,
                    message: `This item cannot be sold, please speak to the manager\n`,
                    acceptLabel: "Okay",
                    rejectVisible: false,
                    accept: () => {
                      this.saleDocDataViewComponent.clearSearchField();
                      this.saleDocDataViewComponent.autoSelectSearchField();
                    },
                  });
                }
              } else {
                this.searchingFromSearchField = false;
                //Inventory search: No Results Found
                this.confirmationService.confirm({
                  header: `Inventory search: No Results Found`,
                  message: this.currentCategory
                    ? `It looks like there isn't any inventory under this category`
                    : `We couldn't find any inventory matching your search of '${searchQuery}'\n`,
                  acceptLabel: "Okay",
                  rejectVisible: false,
                  accept: () => {
                    this.saleDocDataViewComponent.clearSearchField();
                    this.saleDocDataViewComponent.autoSelectSearchField();
                  },
                });
              }
            }
          },
          error: (err) => {
            console.log("ERROR");
            if (err instanceof HttpErrorResponse) {
              if (err.status == 404 && err.error.success === false) {
                this.inventories = [];
                this.orgInventories = [];
                this.totalRecords = 0;
                this._productsLoading = false;
                // this.lastSelectedCategory = this.currentCategory;
                // this.currentCategory = undefined;
                if (!categoryId) {
                  this.currentCategory = undefined;
                }

                if (this.searchingFromSearchField && err.error.message.includes("could not find this description: ")) {
                  console.log(err.error.message);
                  this.confirmationService.confirm({
                    header: "Note: This item is not scannable",
                    message: `No results could be found. Please speak to an associate for assistance.`,
                    icon: "pi pi-exclamation-triangle",
                    rejectButtonStyleClass: "p-button-link",
                    acceptVisible: false,
                    rejectLabel: "Close",
                    reject: () => {
                      this.searchingFromSearchField = false;
                      if (this._showInventoryItems) {
                        this.currentCategory = undefined;
                        this._showInventoryItems = false;
                      }
                      this.saleDocDataViewComponent.autoSelectSearchField();
                    },
                  });
                }
              }
            }
          },
          complete: () => {
            // console.log("inventories", this.inventories);
            this._productsLoading = false;
            // this.lastSelectedCategory = this.currentCategory;
            if (!categoryId) {
              this.currentCategory = undefined;
            }
          },
        })
    );
  }

  updateFetchLimit(limit: number): void {
    this.currentFetchLimit = limit;
  }

  loadInventoryLazy(event): void {
    if (!this.searchingFromSearchField) {
      let categoryId: number;
      if (!this.saleDocDataViewComponent?.searchFieldControl?.value) {
        if (this.currentCategory) {
          categoryId = this.currentCategory.id;
        }
      }
      this.searchInventory(
        event.first,
        this.saleDocDataViewComponent?.searchFieldControl?.value,
        this.saleDocDataViewComponent?.newFetchLimit(),
        categoryId
      );
    }
  }

  loadCategoryLazy(event): void {
    setTimeout(() => {
      this.getAllCategories(event.first, this.saleDocDataViewComponent?.newFetchLimit());
    }, 0);
  }

  onSearchFieldSubmit(): void {
    this.searchingFromSearchField = true;
    this.searchInventory(
      0,
      this.saleDocDataViewComponent?.searchFieldControl?.value,
      this.saleDocDataViewComponent?.newFetchLimit()
    );
  }

  getAllCategories(_offset: number, newLimit: number): void {
    this._productsLoading = true;
    if (newLimit) {
      this.updateFetchLimit(newLimit);
    }
    this.subsList.push(
      this.saleDocDBService
        .getRows("category", JSON.stringify(this.allCategoriesFilter), _offset, 500, "categoryName", 1)
        .subscribe({
          next: (categories) => {
            if (categories) {
              this.totalCategories = categories.count;
              this.categories = [...categories.rows];
            }
          },
          error: (err) => {
            console.log("ERROR");
            if (err instanceof HttpErrorResponse) {
              if (err.status == 404 && err.error.success === false) {
                this.categories = [];
                this.totalCategories = 0;
                this._productsLoading = false;
              }
            }
          },
          complete: () => {
            // console.log("categories", this.categories);
            this._productsLoading = false;
          },
        })
    );
  }

  goBackToCategories(): void {
    this.searchingFromSearchField = false;
    this._showInventoryItems = false;
    this.currentCategory = undefined;
    this.saleDocDataViewComponent.clearSearchField();
    this.saleDocDataViewComponent.autoSelectSearchField();
    // need to reset inventories
    this.inventories = [];
  }

  showItemsOfCategory(category: Category): void {
    // e.stopPropagation();
    this._showInventoryItems = true;
    this.currentCategory = category;

    // // might NOT need to call the next 4 lines of code? to prevent calling searchInventory twice
    // // loadinventorylazy already calls searchInventory once
    // let categoryFilter = {
    //   categoryId: category.id
    // };
    // categoryFilter = {...this.allCategoriesFilter, ...categoryFilter}
    // this.searchInventory(0, undefined, this.saleDocDataViewComponent?.newFetchLimit, categoryFilter);
  }

  onReturnToCart(): void {
    setTimeout(() => {
      this._showInventoryItems = false;
      this.currentCategory = undefined;
      // this.getAllCategories(0, this.saleDocDataViewComponent?.newFetchLimit);
      // this.saleDocDataViewComponent?.autoSelectSearchField();
    }, 0);
  }

  override onDocReset(): void {
    this.inventorySearchComponent.cleanAndFocusSearchInput();

    this.docComponent.switchDocType(SaleDocType.sales_invoice);
    this.updateDocTypeMenuToPreOrders();
  }

  onStartOverPressed(startOverAccepted: boolean): void {
    this._showInventoryItems = !startOverAccepted;
  }

  onItemsCleared(): void {
    this.saleDocDataViewComponent?.autoSelectSearchField();
  }

  onSelfCheckoutDocReset(): void {
    this.saleDocDataViewComponent?.autoSelectSearchField();
  }
}
