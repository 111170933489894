/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
  inject,
} from "@angular/core";
import { FormArray, FormBuilder, FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { invert, round, cloneDeep, keyBy, differenceBy, defaults } from "lodash";
import { ConfirmationService, SelectItem, MenuItem, Message } from "primeng/api";
import { BehaviorSubject, Observable, combineLatest, of, Subject, forkJoin } from "rxjs";
import { catchError, filter, map, pairwise, scan, shareReplay, startWith, take, tap } from "rxjs/operators";
import { NestedFormComponent } from "../../../../forms/nested-form/nested-form.component";
import { AppSettingsStorageService } from "../../../../shared/app-settings-storage.service";
import { DBService } from "../../../../shared/services/db.service";
import { TakuNumpadComponent } from "../../../../taku-ui/taku-numpad/taku-numpad.component";
import { SearchResultItem } from "../../../../taku-ui/taku-search-accounts/SearchResultItem";
import { TakuSearchAccountsComponent } from "../../../../taku-ui/taku-search-accounts/taku-search-accounts.component";
import { ToggleCaptionPosition } from "../../../../taku-ui/taku-toggle/taku-toggle.component";
import { FormDataHelpers } from "../../../../utility/FormDataHelpers";
import { InvoiceHelpers } from "../../../../utility/InvoiceHelpers";
import { MonetaryHelpers } from "../../../../utility/MonetaryHelpers";
import {
  CardNotPresentDetails,
  Card_Not_Present_Type,
  TenderType,
  Tender_Type,
} from "../../../settings/business-settings/tender-type/tender-type";
import { StorePoliciesSettings } from "../../../settings/store-settings/store-policies-settings/StorePoliciesSettings";
import { Zone } from "../../../settings/zone-settings/Zone";
import { SaleDocTender } from "../sale-doc-tender/sale-doc-tender";
import { SaleDocTenderComponent } from "../sale-doc-tender/sale-doc-tender.component";
import { FulfillmentStatus, SaleDoc } from "../sale-doc/sale-doc";
import { WebHelpers } from "src/app/utility/WebHelpers";
import { StoreSettingEmail } from "src/app/core/settings/store-settings/store-mail-settings/StoreSettingEmail";
import {
  PrintingFactoryService,
  PrintingMgr,
  ReceiptPrintingSize,
} from "src/app/shared/services/printing-service.service";
import { AppConstants } from "src/app/shared/app-constants";
import { HttpErrorResponse } from "@angular/common/http";
import { MessageService } from "primeng/api";
import { BlockingUIService } from "src/app/shared/services/ui/blocking-ui.service";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";
import {
  PaymentGateway,
  PaymentGatewayType,
} from "src/app/core/settings/integration-settings/payment-gateways/payment-gateway";
import {
  CommandName,
  MenuTenderLineUndoName,
  ResponseTransaction,
  TenderLineStatusName,
} from "src/app/core/settings/integration-settings/gateway-terminals/payment-terminal";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { SaleInvoiceStateService } from "../sale-invoice-state.service";
import { EconduitService } from "src/app/core/settings/integration-settings/econduit-terminal/econduit.service";
import { MonerisService } from "src/app/core/settings/integration-settings/moneris-terminal/moneris.service";
import {
  StoreTenderType,
  StoreTenderTypeList,
} from "src/app/core/settings/store-settings/store-tender-type-settings/TenderTypesSettings";
import { DocState, SaleDocType } from "../../doc/doc";
import {
  TakuPaymentStatus,
  TakuPayCaptureMethod,
  TakuPayMethodType,
  TakuPayCommandName,
} from "src/app/core/settings/integration-settings/taku-pay/taku-payment-gateway-terminals/taku-payment-terminal";
import { TakuPayService } from "src/app/core/settings/integration-settings/taku-pay/taku-pay.service";
import { PrintHelpers } from "src/app/utility/PrintHelpers";
import { Account, AccountType } from "src/app/core/contact-accounts/account/account";
import { AccountCreditCard, AccountCreditCardStatus } from "src/app/core/contact-accounts/account-credit-card";
import { PersonEmail } from "src/app/core/contact-accounts/person-email/person-email";
import { CommercialAccountEmail } from "src/app/core/contact-accounts/commercial-account-email/commercial-account-email";
import { StoreTenderTypesSettingsService } from "src/app/core/settings/store-settings/store-tender-type-settings/tender-types-settings.service";
import { CommercialAccount } from "src/app/core/contact-accounts/commercial-account/commercial-account";
import { Person } from "src/app/core/contact-accounts/person/person";
import { AccountCreditSummary, AccountService } from "src/app/core/contact-accounts/account/account.service";
import { PersonalAccount } from "src/app/core/contact-accounts/personal-account/personal-account";
import { Voucher } from "../create-voucher/voucher";
import { ModelFormGroup } from "src/app/utility/ModelFormGroup";
import { AdyenCardFormState } from "src/app/core/contact-accounts/adyen-integration/adyen-integration.component";
import { DBCashoutService } from "src/app/core/cashout/db-cashout.service";
import { Router } from "@angular/router";

enum DialogName {
  COMMERCIAL_ACCOUNT = "newCommercialAccount",
  PERSONAL_ACCOUNT = "newPersonalAccount",
  PRINT_PREVIEW = "printPreview",
}

enum TenderTab {
  TENDERS = "TENDERS",
  PAYBACKS = "PAYBACKS",
}

export enum TenderScreenMode {
  RETURNS,
  SALES,
}

export class TenderPrintingSettings {
  print: boolean;
  extraCopy: boolean;
  sendEmail: boolean;
  emailAddress: string;
  giftReceipt: boolean;
  printingSize: ReceiptPrintingSize;

  constructor() {
    this.print = true;
    this.extraCopy = false;
    this.sendEmail = false;
    this.emailAddress = null;
    this.giftReceipt = false;
    this.printingSize = ReceiptPrintingSize.TAPE_SIZE;
  }
}

export type TenderScreenResult = {
  printSettings: TenderPrintingSettings;
  changeDue: number;
  isTenderCompleted: boolean;
};

type PaymentServiceDetails = {
  paymentService: TakuPayService | EconduitService | MonerisService;
  apiBody: Record<string, unknown>;
  apiCommand: string;
  saleDocTender: SaleDocTender;
};

export type TenderResult = {
  printSettings: any;
  changeDue: number;
};

@Component({
  selector: "taku-tender-screen",
  templateUrl: "./tender-screen.component.html",
  styleUrls: ["./tender-screen.component.scss"],
  providers: [DialogService, SaleInvoiceStateService],
})
export class TenderScreenComponent extends NestedFormComponent implements OnChanges, OnInit {
  @ViewChild("printPreviewHost", { read: ViewContainerRef, static: true }) printPreviewHost: ViewContainerRef;
  @Input("formGroup") _myForm: UntypedFormGroup;
  @Input() amountToPay: number;
  @Input() zoneSettings: Zone;
  @Input() paymentGateways: PaymentGateway[];
  // @Input() builderSettings: StoreSettingTapePrint;
  @Input() storePolicies: StorePoliciesSettings;
  @Input() docAccount: SearchResultItem<PersonalAccount | CommercialAccount> = null;
  @Input() _mode: TenderScreenMode;
  @Input() origTakuPaySaleDocTenders: SaleDocTender[];

  // @Output() screenClosed:EventEmitter<any> = new EventEmitter;
  @Output() changesAccepted = new EventEmitter<TenderScreenResult>();
  @Output() onDocTenderRefDialog: EventEmitter<UntypedFormGroup> = new EventEmitter();
  // @Output() accountSelected:EventEmitter<SearchResultItem> = new EventEmitter;

  @ViewChild("numpad", { static: true }) _numpadComponent: TakuNumpadComponent;
  @ViewChild("transactionsWrapper", { static: true }) transactionsWrapper: ElementRef;
  // Taku Search components
  @ViewChild("accountsSearchBox") accountsSearchComponent: TakuSearchAccountsComponent;
  // @ViewChild('printPreviewWrapper', {read: ElementRef}) _printPreviewEl:ElementRef;
  typedFb = inject(FormBuilder);

  @Output() onPaymentProcessed: EventEmitter<any> = new EventEmitter();
  printingMgr: PrintingMgr;
  hasTakuPaymentTerminal: boolean;
  isTakuPayEnabled = false;
  selectedOrigCard: SaleDocTender;
  isPayWithCardCNP = false;
  selectedPreAuthorizationCard: AccountCreditCard;
  saleDocHasRecurring = false;
  saveCardForFutureUse = false;
  savedCardDropdownOptions: SelectItem[] = [];
  adyenExtraFieldsForm = this.typedFb.group({
    saveCardForFutureUse: [false],
    cardOnFile: [""],
  });
  cardNotPresentDetails?: CardNotPresentDetails;
  numpadTenderDescription: string;
  cardInfoManualEntry: any;
  adyenCardFormState: AdyenCardFormState;
  readonly SaleDocType = SaleDocType;
  readonly DocState = DocState;
  readonly Card_Not_Present_Type = Card_Not_Present_Type; // reference to enum for template ngSwitch
  TakuPayCommandName = TakuPayCommandName;
  private terminalPaymentPromptReadyParamsSubject: Subject<Record<string, unknown>> = new Subject<
    Record<string, unknown>
  >();
  terminalPaymentPromptReadyParams$: Observable<Record<string, unknown>> =
    this.terminalPaymentPromptReadyParamsSubject.asObservable();

  saleTenderUndoItems(tenderForm: ModelFormGroup<SaleDocTender>, i: number): MenuItem[] {
    let menuItems: MenuItem[];
    if (typeof tenderForm.value.refTransaction !== "undefined" && tenderForm.value.refTransaction !== null) {
      const refTransaction = tenderForm.value.refTransaction as ResponseTransaction;
      if (
        refTransaction.CardType.toUpperCase() === TakuPayMethodType.INTERAC.toUpperCase() &&
        this.hasTakuPaymentTerminal
      ) {
        menuItems = [
          {
            label: MenuTenderLineUndoName.SALE_REFUND,
            command: () => {
              this.undoPaymentTransaction(tenderForm, i, MenuTenderLineUndoName.SALE_REFUND);
            },
          },
        ];
      } else if (refTransaction.TransType == CommandName.COMMAND_REFUND) {
        menuItems = [
          {
            label: MenuTenderLineUndoName.VOID_REFUND,
            command: () => {
              this.undoPaymentTransaction(tenderForm, i, MenuTenderLineUndoName.VOID_REFUND);
            },
            //icon: 'takuicon-measuring-tape'
          },
        ];
      } else {
        menuItems = [
          {
            label: MenuTenderLineUndoName.SALE_REFUND,
            command: () => {
              this.undoPaymentTransaction(tenderForm, i, MenuTenderLineUndoName.SALE_REFUND);
            },
            //icon: 'takuicon-measuring-tape'
          },
          {
            label: MenuTenderLineUndoName.SALE_VOID,
            command: () => {
              this.undoPaymentTransaction(tenderForm, i, MenuTenderLineUndoName.SALE_VOID);
            },
            //icon: 'takuicon-invoice'
          },
        ];
      }
    }

    return menuItems;
  }

  // @ViewChild('accountsSearchBox') _searchComponent:TakuSearchAccountsComponent;
  enum_printing_sizes = this.dbService
    .enumSelectOptions(ReceiptPrintingSize)
    .filter((item) => item.value !== ReceiptPrintingSize.TAPE_SIZE_CASHOUT_REPORT);

  decimalFormat = "1.2-2";
  storeTenderTypes$: Observable<StoreTenderType[]>;
  storeTenderTypes: StoreTenderType[];
  origSaleDocTenders$: Observable<SaleDocTender[]>;
  tenderTypesEnable$: Observable<TenderType[]>;
  selectedTender: TenderType;
  tenderTypeCNP: TenderType;
  tenderSettingsForm: UntypedFormGroup;
  // Assign enum for access inside template
  readonly TenderScreenMode = TenderScreenMode;
  readonly ToggleCaptionPosition = ToggleCaptionPosition;
  readonly TenderTab = TenderTab;
  readonly Tender_Type = Tender_Type;
  /** Used to map tender types back to their enum key (without spaces) so it can be applied as a css class */
  readonly Tender_Type_Map = invert(Tender_Type);

  sendEmailEnabled = false;

  // Tender Tabs (payment and paybacks)
  tenderTabsDesktop: SelectItem[] = [
    { label: TenderTab.TENDERS, value: TenderTab.TENDERS },
    { label: TenderTab.PAYBACKS, value: TenderTab.PAYBACKS },
  ];
  tenderTabsMobile: SelectItem[] = [
    { icon: "attach_money", value: TenderTab.TENDERS },
    { icon: "money_off", value: TenderTab.PAYBACKS },
  ];
  selectedTenderTab: TenderTab;
  _activeFullDialog: DialogName;
  _activeFullDialogExtra: any;
  saleDoc: SaleDoc;
  invoiceHelper: InvoiceHelpers;
  progressBar: DynamicDialogRef;
  isWaiting = false;
  isUnFinalizedPreOrder = false;
  /** Mark the tender buttons as loading (and disabled) while checking for terminals */
  terminalCheckComplete = new BehaviorSubject(false);
  voidDocumentEvent = new EventEmitter<SaleDoc>();
  /** Map from tenderTypeId to accountCreditSummary. This contains the persisted credit summary from the database */
  accountCreditSummariesMap: Record<number, AccountCreditSummary> = {};
  /** This tracks changes from the current transaction against the active account's store credit */
  accountCreditForTransactionMap: Record<number, AccountCreditSummary> = {};
  /** Null if last voucher scanned was not valid */
  lastValidVoucherFound?: Voucher;
  vouchersInTransaction?: Voucher[];

  /** This subject fires with updates about the state of an auto-persisted tender request */
  private autoPersistTenderStatusUpdates = new Subject<{ index: number; pending: boolean }>();

  /** This observable collects the current state of all auto-persisted tender requests, and emits the latest total state with each change */
  private autoPersistTenderState = this.autoPersistTenderStatusUpdates.pipe(
    scan((pendingState, newStateUpdate) => {
      if (newStateUpdate.pending) {
        pendingState[newStateUpdate.index] = true;
      } else {
        delete pendingState[newStateUpdate.index];
      }
      return pendingState;
    }, {} as Record<number, boolean>),
    startWith({}),
    shareReplay(1)
  );

  constructor(
    protected confirmationService: ConfirmationService,
    public dbService: DBService,
    public fb: UntypedFormBuilder,
    private appSettingsService: AppSettingsStorageService,
    private rootEl: ElementRef,
    private econduitService: EconduitService,
    private monerisService: MonerisService,
    private takuPayService: TakuPayService,
    private messageService: MessageService,
    private blockUIService: BlockingUIService,
    private dialogService: DialogService,
    private accountService: AccountService,
    private alertMessagesService: AlertMessagesService,
    private storeTenderTypesSettingsService: StoreTenderTypesSettingsService,
    private printingFactory: PrintingFactoryService,
    private router: Router,
    private dbCashoutService: DBCashoutService
  ) {
    super(dbService, fb);
  }

  @HostListener("window:keydown", ["$event"])
  onKeyDown(keyEvent: KeyboardEvent) {
    if (this.isTenderCompleted && keyEvent.keyCode == AppConstants.KEY_CODES.ENTER.code) {
      this.onClosePressed(null);

      // this.finalizeTenderScreen();
    }
  }

  finalizeTenderScreen(): void {
    const printSettings = <TenderPrintingSettings>this.tenderSettingsForm.getRawValue();

    if (this.isUnFinalizedPreOrder) {
      this.waitForPendingAutoSaveTenders().subscribe(() => {
        this.changesAccepted.emit({ printSettings, changeDue: 0, isTenderCompleted: this.isTenderCompleted });
      });
      return;
    }

    if (this.hasChangeDue) {
      // if we have pending change, try to pay automatically with cash

      const cashTender = this.storeTenderTypes.find(
        (storeTenderType) => storeTenderType.tenderType.type === Tender_Type.Cash
      );
      if (cashTender) {
        const lastRemainingBalance = this.remainingBalance;
        this.addTenderTransaction(lastRemainingBalance, cashTender.tenderType);
        this.waitForPendingAutoSaveTenders().subscribe(() => {
          this.changesAccepted.emit({
            printSettings,
            changeDue: -1 * lastRemainingBalance,
            isTenderCompleted: this.isTenderCompleted,
          });
        });
      } else {
        alert("Cannot pay change due because Cash Tender doesn't exist");
      }
    } else {
      const lastTransaction = this.getLastTransaction();
      let changeDue = 0;
      // If last transaction is Cash and negative that means we owe change
      if (lastTransaction && lastTransaction.tenderType.type === Tender_Type.Cash && lastTransaction.amount < 0)
        changeDue = -1 * parseFloat(lastTransaction.amount + "");

      this.waitForPendingAutoSaveTenders().subscribe(() => {
        this.changesAccepted.emit({ printSettings, changeDue, isTenderCompleted: this.isTenderCompleted });
      });
    }
  }

  private getLastTransaction() {
    return this.tendersFormArray.length ? this.tendersFormArray.at(this.tendersFormArray.length - 1).value : null;
  }

  get totalPaid(): number {
    let total = 0;
    for (let i = 0; i < this.tendersFormArray.length; i++) {
      total += Number(this.tendersFormArray.at(i).controls.amount.value);
    }
    return round(total, 2);
  }

  get totalNoCashPayments(): number {
    const total = (<SaleDocTender[]>this.tendersFormArray.value)
      .filter((saleTender) => saleTender.tenderType.type !== Tender_Type.Cash)
      .reduce((a, b) => a + parseFloat(b.amount + ""), 0);
    return round(total, 2);
  }

  get totalCashPayments(): number {
    const total = (<SaleDocTender[]>this.tendersFormArray.value)
      .filter((saleTender) => saleTender.tenderType.type === Tender_Type.Cash)
      .reduce((a, b) => a + parseFloat(b.amount + ""), 0);
    return round(total, 2);
  }

  get pennyRounding(): number {
    const rounding = parseFloat(this.zoneSettings.cashRounding);
    return MonetaryHelpers.calculatePennyRounding(this.grossBalance, rounding);
  }

  get isLastPaymentCash(): boolean {
    if (this.hasNoPayments) return false;

    return this.tendersFormArray.at(this.tendersFormArray.length - 1).get("tenderType.type").value == Tender_Type.Cash;
  }

  get hasNoPayments(): boolean {
    return this.tendersFormArray.length === 0;
  }

  get remainingBalance(): number {
    // let balance = this.grossBalance;
    // if (this.isPennyRoundingApplicable)
    //   balance += this.pennyRounding;
    // return round(balance, 2);

    if (this.isPennyRoundingApplicable) return this.pennyRoundingBalance;
    else return this.grossBalance;
  }

  get isTenderCompleted(): boolean {
    return this.remainingBalance === 0 || this.hasChangeDue;
    // const balance = this.invoiceHelper.totalCashPayments() > 0 ? this.pennyRoundingBalance : this.grossBalance;
    // return balance === 0;
  }

  get grossBalance(): number {
    //TPT-4142 Rounding of fractional pricing and quantities in the salesscreen (fix for case return)
    //return round(this.amountToPay - this.totalPaid, 2);
    const grossBalance = round(this.amountToPay - this.totalPaid, 4);
    if (-0.005 <= grossBalance && grossBalance <= 0.005) {
      return 0;
    } else {
      return grossBalance;
    }
  }

  get pennyRoundingBalance(): number {
    return round(this.grossBalance + this.pennyRounding, 2);
  }

  get isPennyRoundingApplicable(): boolean {
    // return ( this.isLastPaymentCash && Math.abs(this.invoiceHelper.totalCashPayments()) > 0 )  || this.hasNoPayments;
    // if (!this.isTenderCompleted)
    //   return true;

    // if we have payments in cash apply, we need to use penny rounding
    return this.totalCashPayments !== 0;
  }

  get amountForCalculator(): number {
    let amount = 0;

    // if (this.selectedTender && this.selectedTender.type == Tender_Type.Cash && this.hasOnlyCashPayments)
    if (this.selectedTender && this.selectedTender.type === Tender_Type.Cash) {
      amount = this.pennyRoundingBalance;
    } else if (
      this.selectedTender?.type === Tender_Type.Store_Voucher &&
      this.saleDoc.accountId &&
      this.lastValidVoucherFound
    ) {
      amount = Number(this.lastValidVoucherFound.value);
    } else {
      amount = this.grossBalance;
    }

    return Math.abs(amount);
    // return this._mode == TenderScreenMode.RETURNS ? -1*amount : amount;
  }

  get maximumAmountForCalc(): number {
    // if cash it doesn't have maxiumun limit
    if (!this.selectedTender || this.selectedTender.type === Tender_Type.Cash) return null;

    let amount = 0;
    if (this._mode == TenderScreenMode.RETURNS || this.remainingBalance < 0) {
      amount = this.selectedTenderTab === TenderTab.TENDERS ? this.totalPaid : this.amountForCalculator;
    } else {
      amount = this.selectedTenderTab === TenderTab.TENDERS ? this.amountForCalculator : this.totalPaid;
      if (this.selectedTender.type === Tender_Type.Store_Credit) {
        const accountCreditForTender = this.accountCreditForTransactionMap[this.selectedTender.id];
        // Maximum is the lower of either the amount left or the total account credit
        amount = Math.min(amount, accountCreditForTender ? Number(accountCreditForTender.accountCredit) : 0);
      } else if (this.selectedTender.type === Tender_Type.Store_Voucher) {
        // If there is no valid voucher, set max amount to non-zero value that will be displayed as zero due to rounding/formatting, because if we set 0, numpad interprets it as no maximum.
        if (!this.lastValidVoucherFound) {
          amount = 0.0001;
        } else if (this.saleDoc.accountId) {
          // If there's an account, we can use excess value (above `amount` towards store credit)
          amount = Number(this.lastValidVoucherFound.value);
        } else {
          // If there's no account, we cannot use any overflow value from the voucher
          amount = Math.min(amount, Number(this.lastValidVoucherFound.value));
        }
      }
    }

    return Math.abs(amount);
  }

  get printCtrl() {
    return this.tenderSettingsForm.get("print");
  }

  get giftReceiptCtrl() {
    return this.tenderSettingsForm.get("giftReceipt");
  }

  get ctrlEmailAddress(): FormControl<string> {
    return this.tenderSettingsForm.get("emailAddress") as FormControl<string>;
  }

  get ctrlSendEmail(): FormControl<boolean> {
    return this.tenderSettingsForm.controls.sendEmail as FormControl<boolean>;
  }

  _hasReturnedPair(formIndex: number, actualTender: SaleDocTender): boolean {
    const nextTenderIndex = formIndex + 1;
    if (this.tendersFormArray.length <= nextTenderIndex) return false;

    const nextSaleDocTender = this.tendersFormArray.at(nextTenderIndex).value;
    return nextSaleDocTender.isReturned && Number(nextSaleDocTender.amount) === -1 * Number(actualTender.amount);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.subsList.push(this.getEnableTenderTypes().subscribe((response) => (this.tenderTypesEnable$ = of(response))));

    const _activeFilter = {
      isActive: { matchMode: "equals", value: true },
    };
    this.subsList.push(
      this.dbService.getRows("takuPaymentAccount", JSON.stringify(_activeFilter)).subscribe((result) => {
        if (result.count > 0) {
          this.isTakuPayEnabled = true;
        }
      })
    );

    this.subsList.push(
      this.checkTakuPaymentTerminal(this.appSettingsService.getStation().id).subscribe((result) => {
        this.hasTakuPaymentTerminal = result;
      })
    );

    const userPrefs = this.appSettingsService.getUserPreferences();

    //We don't want to reuse email address or sendEmail setting from previous transaction.
    if (userPrefs.tenderPrintingSettings) {
      userPrefs.tenderPrintingSettings.emailAddress = "";
      userPrefs.tenderPrintingSettings.sendEmail = false;
    }

    const tenderPrintSettings = userPrefs.tenderPrintingSettings || new TenderPrintingSettings();
    tenderPrintSettings.emailAddress = this.getAccountEmail();
    tenderPrintSettings.sendEmail = !!tenderPrintSettings.emailAddress;
    tenderPrintSettings.giftReceipt = false;
    this.tenderSettingsForm = this.fb.group(tenderPrintSettings);

    if (this._myForm?.get("doc.docType").value == SaleDocType.pre_order) {
      this.ctrlEmailAddress.setValidators([Validators.email, Validators.required]);
    } else {
      this.ctrlEmailAddress.setValidators(Validators.email);
    }

    // making sure gift receipt toggle is initially off
    this.giftReceiptCtrl.setValue(false);

    this.subsList.push(
      this.printCtrl.valueChanges.subscribe((print) => {
        if (print) {
          this.giftReceiptCtrl.enable();
        } else {
          this.giftReceiptCtrl.disable();
          this.giftReceiptCtrl.setValue(false);
        }
      })
    );

    const fnToggleEmailValidations = (newValue) => {
      // Onlly validate email address when send email is activated/on
      if (newValue) this.ctrlEmailAddress.setValidators([Validators.email, Validators.required]);
      else this.ctrlEmailAddress.clearValidators();

      this.ctrlEmailAddress.updateValueAndValidity();
    };

    fnToggleEmailValidations(this.ctrlSendEmail.value);
    // this.ctrlEmailAddress.setValidators(Validators.email)
    this.subsList.push(this.ctrlSendEmail.valueChanges.subscribe(fnToggleEmailValidations));

    this.saleDocHasRecurring = this._myForm.get("saleDocLines").value.some((line) => line.recurringOrderSettingId > 0);
    this.adyenExtraFieldsForm.get("saveCardForFutureUse").setValue(this.saleDocHasRecurring);

    const disabledEmailToggle =
      this._myForm?.get("fulfillmentStatus")?.value == FulfillmentStatus.unfulfilled ||
      (this.saleDocHasRecurring && !!this.docAccount) ||
      this._myForm?.get("doc.docType")?.value == SaleDocType.pre_order;
    if (disabledEmailToggle) {
      this.tenderSettingsForm.controls.sendEmail.setValue(true, { emitEvent: false });
      this.tenderSettingsForm.controls.sendEmail.disable({ emitEvent: false });
      this.ctrlEmailAddress.setValidators([Validators.email, Validators.required]);
    }

    this.subsList.push(
      this.appSettingsService.getStoreSettings<StoreSettingEmail>("storeSettingEmail").subscribe({
        next: (settings) => {
          if (!settings || !settings.id || !settings.userName || !settings.password) {
            this.ctrlSendEmail.disable();
          } else {
            this.sendEmailEnabled = true;
          }
        },
        error: (error) => {
          this.ctrlSendEmail.disable();
        },
      })
    );

    this._updateSavedCardDropdownOptions(this.docAccount?.data?.account?.accountCreditCards);

    this.subsList.push(
      combineLatest([
        this._myForm
          .get("doc.docType")
          .valueChanges.pipe(startWith(this._myForm.get("doc.docType").value as SaleDocType)),
        this._myForm.get("doc.state").valueChanges.pipe(startWith(this._myForm.get("doc.state").value as DocState)),
      ]).subscribe(([docType, docState]) => {
        this.isUnFinalizedPreOrder =
          docType == SaleDocType.pre_order && docState != DocState.finalized && docState != DocState.approved;
      })
    );

    this.subsList.push(
      this.tendersFormArray.valueChanges
        .pipe(
          startWith(this.tendersFormArray.value ?? []),
          pairwise(),
          // Ignore updates to the values, like from patching the id after persisting
          filter(([prevTenders, newTenders]) => prevTenders.length !== newTenders.length)
        )
        .subscribe(([prevTenders, newTenders]) => {
          const numNewTenders = newTenders.filter((tender) => !tender.id).length;
          if (numNewTenders > 1) {
            // I don't think this should happen, but post a warning just in case.
            this.messageService.add({
              severity: "warn",
              summary: "Unexpected multiple new tenders",
              detail: `Expected only 1 new tender but got ${numNewTenders}`,
            });
          }

          const newTenderIndex = newTenders.findIndex((tender) => !tender.id);
          if (newTenderIndex > -1) {
            if (newTenderIndex === newTenders.length - 1) {
              // If a new tender is added at the end of the list, just create it normally
              this.persistNewTender(newTenderIndex).subscribe();
            } else {
              // If a reverted tender is added (not at the end), to maintain the sort order (by id), we reassign the ids before updating/creating
              // Get all ids, minus 0 id
              const existingIds = newTenders.map((t) => t.id).filter((t) => !!t);

              // Apply ids in order, and set last id to 0 to be assigned by server
              for (let i = newTenderIndex; i < newTenders.length - 1; i++) {
                this.tendersFormArray.at(i).patchValue({ id: existingIds[i] });
              }
              this.tendersFormArray.at(-1).patchValue({ id: 0 });

              // Send PUT requests for existing ids and POST for "new" one
              const updated = this.tendersFormArray.value
                .slice(newTenderIndex, -1)
                .map((t) => this.cleanTenderBeforePersisting(t));
              forkJoin([
                this.dbService.batchEditRows("saleDocTender", updated).pipe(
                  tap((results) => {
                    const error = results.find((r) => r.response.code > 299);
                    if (error) {
                      this.messageService.add({
                        severity: "error",
                        summary: "Failed to revert tender",
                        detail: "Unexpected error: " + error.response.result["customMessage"],
                        life: 8000,
                      });
                    }
                  })
                ),
                this.persistNewTender(this.tendersFormArray.length - 1),
              ]).subscribe();
            }
          }
          // Vouchers are the only tender that is removed instead of voided (and Store Credit can be removed if the account is removed)
          const removedTenders = differenceBy(prevTenders, newTenders, "id");
          removedTenders.forEach((tender) => {
            this.dbService.deleteRow("saleDocTender", tender.id).subscribe();
          });
        })
    );

    /** Maintain one subscription to ensure state in shareReplay(1) is up to date. */
    this.subsList.push(this.autoPersistTenderState.subscribe());

    this.collectUsedVouchers();
    this.ensureVoucherValueTransfersToStoreCredit();
    this.getAccountCreditSummariesMap();
  }

  /** The returned observable will emit and complete when there are no pending tender requests.
   *  It could emit immediately if this case is true upon subscription.
   */
  private waitForPendingAutoSaveTenders = () =>
    this.autoPersistTenderState.pipe(
      filter((t) => Object.keys(t).length === 0),
      take(1)
    );

  /** Adds docId and reduces request size by cleaning unnecessary joined entities */
  private cleanTenderBeforePersisting(tender: ModelFormGroup<SaleDocTender>["value"]) {
    return defaults({ saleDocId: this.saleDoc.id, tenderType: null, paymentGateway: null, voucher: null }, tender);
  }

  private persistNewTender(index: number) {
    const tenderCopy = this.cleanTenderBeforePersisting(this.tendersFormArray.at(index).value);
    this.autoPersistTenderStatusUpdates.next({ index, pending: true });
    this.tendersFormArray.at(index).patchValue({ id: -1 }); // Use a placeholder id to prevent two requests from being sent for the same tender while the request is pending
    return this.dbService.addRow("saleDocTender", tenderCopy).pipe(
      tap((persisted) => {
        this.tendersFormArray.at(index).patchValue({ id: persisted.id });
        this.autoPersistTenderStatusUpdates.next({ index, pending: false });
      }),
      catchError((error) => {
        if (this.tendersFormArray.at(index).controls.id.value < 0) {
          this.tendersFormArray.at(index).patchValue({ id: 0 });
        }
        this.autoPersistTenderStatusUpdates.next({ index, pending: false });
        console.error(error);
        this.messageService.add({
          severity: "warn",
          summary: "Error saving payment",
          detail: `Failed to eagerly save the payment of '${tenderCopy.amount}'`,
        });
        return of();
      })
    );
  }

  private getAccountCreditSummariesMap(): void {
    if (this.docAccount?.data?.accountId) {
      this.subsList.push(
        this.accountService
          .getCreditSummary(this.docAccount.data.accountId)
          .subscribe(
            (res) => (this.accountCreditSummariesMap = this.accountCreditForTransactionMap = keyBy(res, "tenderTypeId"))
          )
      );
    }
  }

  private getAccountEmail(): string {
    switch (this.docAccount?.data?.account?.accountType) {
      case AccountType.commercial:
        return (this.docAccount.data as CommercialAccount).commercialAccountEmails.map((m) => m.email)[0];
      case AccountType.personal:
        return (this.docAccount.data as PersonalAccount).person.personEmails.map((m) => m.email)[0];
      default:
        return "";
    }
  }

  get tendersFormArray(): FormArray<ModelFormGroup<SaleDocTender>> {
    return this._myForm.get("saleDocTenders") as FormArray<ModelFormGroup<SaleDocTender>>;
  }

  get hasChangeDue(): boolean {
    return this.remainingBalance < 0 && this._mode !== TenderScreenMode.RETURNS;
  }

  get origCardsList() {
    if (this.origTakuPaySaleDocTenders) {
      return this.origTakuPaySaleDocTenders.map((v) => ({
        name: `${v.refTransaction?.CardType === "Mastercard" ? "MC" : v.refTransaction?.CardType}(...${
          v.refTransaction?.Last4
        })(${v.refTransaction?.ExpDate})`,
        code: v,
      }));
    }
  }

  // get authorizationCardsList() {
  //   if (this.docAccount && this.docAccount.data?.account?.accountCreditCards.length > 0) {
  //     this.docAccount.data?.account?.accountCreditCards.sort((a, b) => b.id - a.id);
  //     return this.docAccount.data?.account?.accountCreditCards.map(v => ({ name: `${v?.cardType === "Mastercard" ? "MC" : v?.cardType}(...${v?.last4Digits.slice(-2)})(${v?.expiryDate})`, code: v }));
  //   }
  // }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["_myForm"]) {
      this._setupSaleDoc(this._myForm.value);

      this.subsList.push(
        this._myForm.valueChanges.subscribe((newValue) => {
          this._setupSaleDoc(newValue);

          // Reevaluate penny rounding every time payments change
          const cashRounding = this.isPennyRoundingApplicable ? this.pennyRounding : 0;
          this._myForm.get("cashRounding").setValue(cashRounding, { emitEvent: false });
        })
      );
    }

    if (changes["amountToPay"]) {
      this._updatePaymentTabs();
      this.ensureVoucherValueTransfersToStoreCredit();
    }

    if (changes["docAccount"]) {
      // immediately persist account changes/removals while tender screen is open, for improved failure recovery
      if (this.saleDoc.id && !changes["docAccount"].isFirstChange()) {
        this.dbService
          .patchRow("saleDoc", { id: this.saleDoc.id, accountId: this.docAccount?.data?.accountId || null })
          .subscribe();
      }
      // Put current Account's name into search account text field
      if (this.docAccount) {
        if (this.accountsSearchComponent) this.accountsSearchComponent.textSearch = this.docAccount.headline;
        //If the user has an email, populate the email field with the user's email.
        const email = this.getAccountEmail();
        if (this.tenderSettingsForm) {
          this.tenderSettingsForm.get("emailAddress").setValue(email, { emitEvent: false });
          if (!this.ctrlSendEmail.disabled) {
            this.ctrlSendEmail.setValue(!!email, { emitEvent: false });
          }
        }

        this.ensureVoucherValueTransfersToStoreCredit();
        this._updateSavedCardDropdownOptions(this.docAccount.data?.account?.accountCreditCards);
        this.getAccountCreditSummariesMap();
      } else {
        this.accountCreditSummariesMap = {};
        this.accountCreditForTransactionMap = {};
        // if account is removed, remove any tendered store credit
        this.tendersFormArray.controls = this.tendersFormArray.controls.filter(
          (ctrl) => ctrl.value.tenderType.type !== Tender_Type.Store_Credit
        );
        this.tendersFormArray.updateValueAndValidity();
      }
    }

    this.selectedTenderTab =
      this._mode === TenderScreenMode.RETURNS || this.remainingBalance < 0 ? TenderTab.PAYBACKS : TenderTab.TENDERS;
  }

  /** check voucher usage is maximum value after adding an account */
  private ensureVoucherValueTransfersToStoreCredit() {
    if (this.vouchersInTransaction?.length && this.docAccount) {
      const updatedVoucherTenders = this.tendersFormArray.controls
        .filter((ctrl) => ctrl.value.voucher && ctrl.value.voucher.value !== ctrl.value.amount)
        .map((ctrl) => {
          ctrl.controls.amount.setValue(ctrl.value.voucher.value);
          return ctrl.value.voucher.value;
        });

      const balance = this.grossBalance;
      if (updatedVoucherTenders.length || balance < 0) {
        this.addOrUpdateStoreCredit(balance);
      }
    }
  }

  private addOrUpdateStoreCredit(balance: number) {
    const existingCreditCtrl = this.tendersFormArray.controls.find(
      (ctrl) => ctrl.value.tenderType.type === Tender_Type.Store_Credit
    );
    if (existingCreditCtrl) {
      const prevAmount = Number(existingCreditCtrl.controls.amount.value);
      if (balance) {
        existingCreditCtrl.controls.amount.setValue(prevAmount + balance);
      }
    } else {
      this.addStoreCreditTenderForRemainingBalance();
    }
  }

  private _updateSavedCardDropdownOptions(accountCreditCards?: AccountCreditCard[]): void {
    const savedCardOptions = [];

    if (accountCreditCards && accountCreditCards.length > 0) {
      savedCardOptions.push({ value: "" });
      savedCardOptions.push(
        ...accountCreditCards
          .filter((card) => card.status == "Active")
          .map((card) => {
            return {
              value: card.token,
              label: card.cardType + " (..." + card.last4Digits + ")(" + card.expiryDate + ")",
            } as SelectItem;
          })
      );
    }

    this.savedCardDropdownOptions = savedCardOptions;
  }

  _setupSaleDoc(formValue) {
    this.saleDoc = SaleDoc.convertIntoTypedObject(formValue);
    this.invoiceHelper = new InvoiceHelpers(this.saleDoc);
  }

  // onReprintDoc(){

  //   PrintHelpers.printElementContents(
  //     this._printPreviewEl.nativeElement,
  //     ['/assets/layout/taku/receipt-tape-preview.component.css'],
  //     'Receipt - Print Preview',
  //   );

  //   // this.openFullSizeDialog(DialogName.PRINT_PREVIEW, saleDoc);
  // }

  initLookups(): void {
    // Get tender for Manual Entry
    this.appSettingsService
      .getStoreSettings("storeTenderTypeList")
      .pipe(
        map((storeTenderTypeList: StoreTenderTypeList) => {
          return storeTenderTypeList.storeTenderTypes.find(
            (tender) =>
              tender.tenderType.type === Tender_Type.Credit_Card &&
              tender.tenderType.isIntegrated === true &&
              tender.tenderType.isEnabled === true
          );
        })
      )
      .subscribe((res) => {
        this.tenderTypeCNP = res?.tenderType;
      });

    // Make all 3 requests at the same time, and when the terminal requests return, map them onto the tender type.
    this.storeTenderTypes$ = this.storeTenderTypesSettingsService
      .getStoreTenderTypes(this.terminalCheckComplete)
      .pipe(shareReplay(1));

    this.subsList.push(
      this.storeTenderTypes$.subscribe((tenderTypes) => {
        this.storeTenderTypes = tenderTypes;
      })
    );
  }

  onNumpadCancel(isCancelled?: boolean): void {
    this.cardNotPresentDetails = null;
    this.adyenCardFormState = null;
    if (isCancelled) {
      this.lastValidVoucherFound = null;
      this.selectedTender = null;
    }
  }

  enterTenderPayment(tenderType: TenderType): void {
    this.selectedTender = tenderType;
    this.cardNotPresentDetails = null;
    this.numpadTenderDescription = this.selectedTender?.description;
    this.isPayWithCardCNP = false;
    this.adyenExtraFieldsForm.get("cardOnFile").clearValidators();
    this.adyenExtraFieldsForm.get("saveCardForFutureUse").setValue(this.saleDocHasRecurring);
    this.adyenExtraFieldsForm.get("saveCardForFutureUse").enable();

    if (
      this.selectedTender?.takuPaymentTerminal &&
      this.saleDoc.doc.docType === SaleDocType.pre_order &&
      this.saleDoc.doc.state === DocState.approved
    ) {
      // If using TakuPay terminal to finalize payment on an approved pre-order, skip pinpad and just process full amount.
      this.onPaymentSubmitted(this.amountForCalculator);
      return;
    }

    if (this.isUnFinalizedPreOrder) {
      /* CP (TakuPay) */
      if (this.selectedTender?.takuPaymentTerminal) {
        this.adyenExtraFieldsForm.get("saveCardForFutureUse").setValue(true);
        this.adyenExtraFieldsForm.get("saveCardForFutureUse").disable();
      }
    }

    if (this.selectedTender.type === Tender_Type.Store_Credit && !this.docAccount?.data?.accountId) {
      this.confirmationService.confirm({
        message: "Please note that an Account is necessary to issue credit on a return. Please add an Account first.",
        rejectVisible: false,
        acceptLabel: "OK",
        dismissableMask: true,
      });
    } else {
      this._numpadComponent.open();
    }
  }

  openCNPNumpadComponent(numpadTitle: string): void {
    this.selectedTender = null;
    this.numpadTenderDescription = numpadTitle;
    const cardNotPresentType = numpadTitle.replace(" ", "") as Card_Not_Present_Type;
    this.isPayWithCardCNP = true;
    this.adyenExtraFieldsForm.get("cardOnFile").clearValidators();
    this.adyenExtraFieldsForm.get("saveCardForFutureUse").setValue(false);
    this.adyenExtraFieldsForm.get("saveCardForFutureUse").enable();

    if (cardNotPresentType === Card_Not_Present_Type.SavedCards) {
      this.adyenExtraFieldsForm.get("cardOnFile").setValidators(Validators.required);
      this.adyenExtraFieldsForm.get("saveCardForFutureUse").setValue(false); // intentional false. api breaks if we try to save card when it's already saved.
      this.adyenExtraFieldsForm.get("saveCardForFutureUse").disable();
    }

    // Must force save the card if it's a pay later pre-order, or if it's a recurring sale
    if (
      cardNotPresentType !== Card_Not_Present_Type.SavedCards &&
      (this.saleDocHasRecurring || this.isUnFinalizedPreOrder)
    ) {
      this.adyenExtraFieldsForm.get("saveCardForFutureUse").setValue(true);
      this.adyenExtraFieldsForm.get("saveCardForFutureUse").disable();
    }

    this.cardNotPresentDetails = new CardNotPresentDetails(this.docAccount.data.accountId, cardNotPresentType);
    this._numpadComponent.open();
  }

  undoPaymentTransaction(tenderForm: ModelFormGroup<SaleDocTender>, index: number, sMenuType = ""): void {
    let sMessage = "Are you sure you want to reverse this payment?";
    if (sMenuType === MenuTenderLineUndoName.SALE_REFUND) {
      sMessage =
        "Are you sure you want to refund this payment? This action cannot be reversed once the payment has been refunded.";
    } else if (sMenuType === MenuTenderLineUndoName.SALE_VOID) {
      sMessage =
        "Are you sure you want to void this payment? This action cannot be reversed once the payment has been voided.";
    } else if (sMenuType === MenuTenderLineUndoName.VOID_REFUND) {
      sMessage =
        "Are you sure you want to void this refund? This action cannot be reversed once the payment has been voided.";
    }

    this.confirmationService.confirm({
      header: "Reverse payment confirmation",
      message: sMessage,
      rejectButtonStyleClass: "p-button-link",
      accept: () => {
        const saleDocTender = cloneDeep(tenderForm.value) as unknown as SaleDocTender;

        FormDataHelpers.clearModelIDs(saleDocTender);
        saleDocTender.createdAt = new Date();
        saleDocTender.updatedAt = undefined;
        saleDocTender.refNo = String(tenderForm.controls.id.value);
        saleDocTender.amount = -1 * Number(saleDocTender.amount); // Make it negative for reversion
        saleDocTender.isReturned = true;

        if (typeof tenderForm.value.refTransaction !== "undefined" && tenderForm.value.refTransaction !== null) {
          let sTransactionType: string = MenuTenderLineUndoName.SALE_VOID;
          if (sMenuType === MenuTenderLineUndoName.SALE_VOID) {
            sTransactionType = CommandName.COMMAND_VOID;
          } else if (sMenuType === MenuTenderLineUndoName.SALE_REFUND) {
            sTransactionType = CommandName.COMMAND_REFUND;
          } else if (sMenuType === MenuTenderLineUndoName.VOID_REFUND) {
            sTransactionType = CommandName.COMMAND_VOID;
          }
          void this.callApiRunTransaction(saleDocTender, sTransactionType, index, sMenuType);
        } else if (tenderForm.value.tenderType.type === Tender_Type.Store_Voucher) {
          // Voucher transactions are removed instead of reverted, to keep only a single tender line per voucher
          this.tendersFormArray.removeAt(index);
        } else {
          this.tendersFormArray.insert(index + 1, SaleDocTenderComponent.set(this.fb, saleDocTender));
        }
        this.collectUsedVouchers();
        this.calculateTransactionStoreCredit();
        this._updatePaymentTabs();
        this.scrollTransactionsDown();

        setTimeout(() => {
          WebHelpers.reattachBodyClassesForDialog(this.rootEl.nativeElement);
        }, 0);
      },
      reject: () => {
        setTimeout(() => {
          WebHelpers.reattachBodyClassesForDialog(this.rootEl.nativeElement);
        }, 0);
      },
    });
  }

  onPaymentSubmitted(paymentAmount: number): void {
    if (this.savedCardDropdownOptions) {
      // this.submitToken.emit(this.adyenExtraFieldsForm.get("cardOnFile").value);
      this.selectedPreAuthorizationCard = new AccountCreditCard();
      this.selectedPreAuthorizationCard.token = this.adyenExtraFieldsForm.get("cardOnFile").value;
    }

    if (this.cardNotPresentDetails?.cardNotPresentType === this.Card_Not_Present_Type.ManualEntry) {
      // this.submitCardInfo.emit(this.adyenCardFormState.fields);
      this.cardInfoManualEntry = {
        card_number: this.adyenCardFormState.fields.card_number,
        card_month_exp: this.adyenCardFormState.fields.card_month_exp,
        card_year_exp: this.adyenCardFormState.fields.card_year_exp,
        card_cvv: this.adyenCardFormState.fields.card_cvv,
        billing_address: { zip: this.adyenCardFormState.fields.postalCode },
      };
      this.adyenCardFormState = null;
    }

    if (this.selectedTenderTab === TenderTab.PAYBACKS) {
      paymentAmount *= -1;
    }

    const cardNotPresentType = this.numpadTenderDescription.replace(" ", "") as Card_Not_Present_Type;
    if (
      cardNotPresentType === Card_Not_Present_Type.SavedCards ||
      cardNotPresentType === Card_Not_Present_Type.ManualEntry
    ) {
      this.selectedTender = this.tenderTypeCNP;
    }

    if (this.isUnFinalizedPreOrder) {
      switch (true) {
        case !!this.selectedTender.takuPaymentTerminal: // CP
          paymentAmount = 0.5;
          break;
        case cardNotPresentType === Card_Not_Present_Type.ManualEntry: // CNP
          paymentAmount = 0.0;
          break;
        case cardNotPresentType === Card_Not_Present_Type.SavedCards: // COF (also technically CNP)
          paymentAmount = 0.5;
          break;
      }
    }

    this.saveCardForFutureUse = this.adyenExtraFieldsForm.get("saveCardForFutureUse").value;

    if (this.selectedTender.type === Tender_Type.Store_Voucher) {
      this.confirmVoucherTransaction(paymentAmount, this.selectedTender);
    } else {
      this.addTenderTransaction(paymentAmount, this.selectedTender);
    }
  }

  private confirmVoucherTransaction(amount: number, tenderType: TenderType) {
    if (amount !== Number(this.lastValidVoucherFound.value) && !this.saleDoc.accountId) {
      this.confirmationService.confirm({
        message:
          "Please note that an Account is necessary to save the remaining balance of a used Voucher. " +
          "If you finalize this transaction without an Account, any remaining balance will be forfeit. <br/><br/>" +
          "Are you sure you want to proceed?",
        dismissableMask: true,
        rejectButtonStyleClass: "p-button-link",
        accept: () => this.addTenderTransaction(amount, tenderType),
        reject: () => (this.lastValidVoucherFound = null),
      });
    } else {
      this.addTenderTransaction(amount, this.selectedTender);
    }
  }

  private addTenderTransaction(amount: number, tenderType: TenderType) {
    // Add tender payment to form in order to be saved later
    const newSaleDocTender = Object.assign(new SaleDocTender(), {
      amount: amount,
      tenderTypeId: tenderType.id,
      tenderType: tenderType,
      isTendered: amount >= 0,
      createdAt: new Date(),
    } as Partial<SaleDocTender>);

    if (tenderType.type === Tender_Type.Store_Voucher && this.lastValidVoucherFound) {
      newSaleDocTender.voucherId = this.lastValidVoucherFound.id;
      newSaleDocTender.voucher = this.lastValidVoucherFound;
      // Remove reference so that numpad does not re-use this.
      this.lastValidVoucherFound = null;
    }

    const isRefundByOriginalCard =
      this.selectedTenderTab === TenderTab.PAYBACKS &&
      tenderType.id === this.selectedOrigCard?.tenderType.id &&
      this.isTakuPayEnabled &&
      this.selectedOrigCard.tenderType?.type !== Tender_Type.Debit_Card;

    const isCardTransaction =
      this.selectedTender &&
      this.selectedTender.type != Tender_Type.Cash &&
      (this.selectedTender.paymentTerminalId > 0 ||
        this.selectedTender.takuPaymentTerminalId > 0 ||
        this.isPayWithCardCNP ||
        isRefundByOriginalCard);

    if (isCardTransaction) {
      let sTransactionType: string = CommandName.COMMAND_SALE;
      if (amount < 0) {
        sTransactionType = CommandName.COMMAND_REFUND;
      }
      void this.callApiRunTransaction(newSaleDocTender, sTransactionType);
    } else {
      this.tendersFormArray.push(SaleDocTenderComponent.set(this.fb, newSaleDocTender));

      // If a voucher is redeemed, attempt to transfer any excess value to the associated accounts store credit
      const balance = this.grossBalance;
      if (newSaleDocTender.voucher && this.saleDoc.accountId && balance < 0) {
        // this.addStoreCreditTenderForRemainingBalance();
        this.addOrUpdateStoreCredit(balance);
      }

      // update available store credit
      this.calculateTransactionStoreCredit();
      this.collectUsedVouchers();

      this.selectedTender = null;
      this._updatePaymentTabs();
      this.scrollTransactionsDown();
    }
  }

  private addStoreCreditTenderForRemainingBalance() {
    const storeCreditTenderType = this.storeTenderTypes.find(
      (stt) => stt.tenderType.type === Tender_Type.Store_Credit
    )?.tenderType;
    if (storeCreditTenderType) {
      const secondarySaleDocTender = Object.assign(new SaleDocTender(), {
        amount: this.grossBalance,
        tenderTypeId: storeCreditTenderType.id,
        tenderType: storeCreditTenderType,
        isTendered: false,
        createdAt: new Date(),
      } as Partial<SaleDocTender>);
      this.tendersFormArray.push(SaleDocTenderComponent.set(this.fb, secondarySaleDocTender));
    }
  }

  /** Summarizes the not-yet-persisted store credit usage onto the persisted usage */
  private calculateTransactionStoreCredit() {
    this.accountCreditForTransactionMap = cloneDeep(this.accountCreditSummariesMap);
    (this.tendersFormArray.value as SaleDocTender[])
      .filter((tender) => tender.tenderType.type === Tender_Type.Store_Credit)
      .forEach((tender) => {
        const storeCredit = this.accountCreditForTransactionMap[tender.tenderTypeId];
        if (storeCredit) {
          storeCredit.accountCredit = Number(storeCredit.accountCredit) - Number(tender.amount);
        } else {
          this.accountCreditForTransactionMap[tender.tenderTypeId] = {
            accountCredit: Number(tender.amount),
            currencyIsoCode: tender.tenderType.currencyIsoCode,
            tenderTypeId: tender.tenderTypeId,
            zoneId: tender.tenderType.zoneId,
          };
        }
      });
  }

  private collectUsedVouchers() {
    this.vouchersInTransaction = (this.tendersFormArray.value as SaleDocTender[])
      .filter((tender) => tender.tenderType.type === Tender_Type.Store_Voucher)
      .map((tender) => tender.voucher);
  }

  validVoucherFound(voucher: Voucher): void {
    this.lastValidVoucherFound = voucher;
  }

  private mapPaymenTenderToSaleDocTender(paymentTender: StoreTenderType, saleDocTender: SaleDocTender): SaleDocTender {
    if (paymentTender.tenderType.paymentTerminalId > 0 && !saleDocTender.paymentTerminal) {
      saleDocTender.paymentTerminal = paymentTender.tenderType.paymentTerminal;
      saleDocTender.paymentTerminalId = paymentTender.tenderType.paymentTerminalId;
      saleDocTender["paymentGateway"] = paymentTender.tenderType.paymentTerminal.paymentGateway;
      saleDocTender["paymentGatewayId"] = paymentTender.tenderType.paymentTerminal.paymentGatewayId;
    } else if (paymentTender.tenderType.takuPaymentTerminalId > 0 && !saleDocTender.takuPaymentTerminal) {
      saleDocTender.takuPaymentTerminal = paymentTender.tenderType.takuPaymentTerminal;
      saleDocTender.takuPaymentTerminalId = paymentTender.tenderType.takuPaymentTerminalId;
      saleDocTender["takuPaymentGateway"] = paymentTender.tenderType.takuPaymentTerminal.takuPaymentGateway;
      saleDocTender["takuPaymentGatewayId"] = paymentTender.tenderType.takuPaymentTerminal.takuPaymentGatewayId;
    }

    return saleDocTender;
  }

  private generatePaymentServiceDetails(saleDocTender: SaleDocTender, apiCommand: string): PaymentServiceDetails {
    const paymentTender: StoreTenderType = this.storeTenderTypes.find(
      (storeTenderType) =>
        storeTenderType.tenderType.type !== Tender_Type.Cash &&
        storeTenderType.tenderType.id === saleDocTender.tenderTypeId
    );

    if (paymentTender) {
      saleDocTender = this.mapPaymenTenderToSaleDocTender(paymentTender, saleDocTender);
    }

    let apiBody: Record<string, unknown>;
    let paymentService: TakuPayService | EconduitService | MonerisService;

    const isRefundByOriginalCard =
      this.selectedTenderTab === TenderTab.PAYBACKS &&
      this.selectedOrigCard &&
      this.isTakuPayEnabled &&
      this.selectedOrigCard.tenderType?.type !== Tender_Type.Debit_Card;
    if (
      saleDocTender.refTransaction?.PaymentId ||
      saleDocTender.takuPaymentTerminal?.takuPaymentGateway ||
      this.isPayWithCardCNP ||
      isRefundByOriginalCard
    ) {
      paymentService = this.takuPayService;
      switch (apiCommand) {
        case TakuPayCommandName.COMMAND_SALE:
          {
            apiCommand = TakuPayCommandName.COMMAND_INTENT;

            let takuPayMethodType;

            if (this.isPayWithCardCNP) {
              if (this.cardInfoManualEntry) {
                takuPayMethodType = TakuPayMethodType.CARD_NOT_PRESENT_MOTO;
              } else {
                takuPayMethodType = TakuPayMethodType.CARD_NOT_PRESENT;
              }
            } else {
              takuPayMethodType = TakuPayMethodType.CARD_PRESENT;
            }

            let takuPayCaptureMethod = TakuPayCaptureMethod.AUTOMATIC;

            // This is a pre-order pay later scenario, so we do manual capture method, always
            if (this.isUnFinalizedPreOrder) {
              takuPayCaptureMethod = TakuPayCaptureMethod.MANUAL;
            }

            apiBody = {
              amount: Math.abs(saleDocTender.amount as number),
              payment_method_types: takuPayMethodType,
              capture_method: takuPayCaptureMethod,
              allow_saving_card: this.saveCardForFutureUse,
              external_customer_id:
                this.isPayWithCardCNP || this.saveCardForFutureUse ? (this.docAccount?.data?.accountId).toString() : "",
              invoiceNumber: this.saleDoc.id,
            };

            if (!this.saveCardForFutureUse && this.selectedPreAuthorizationCard?.token !== "") {
              apiBody = Object.assign(apiBody, {
                payment_method_id: this.isPayWithCardCNP ? this.selectedPreAuthorizationCard?.token : "",
              });
            }
          }
          break;
        case TakuPayCommandName.COMMAND_VOID:
          apiCommand = TakuPayCommandName.COMMAND_CANCEL;
          apiBody = {
            amount: Math.abs(saleDocTender.amount as number),
            payment_id: saleDocTender.refTransaction?.PaymentId || null,
            terminal_id: this.isPayWithCardCNP ? null : saleDocTender?.takuPaymentTerminal?.terminalId,
            invoiceNumber: this.saleDoc.id,
          };
          break;
        case TakuPayCommandName.COMMAND_REFUND:
          apiBody = {
            amount: Math.abs(saleDocTender.amount as number),
            payment_id: saleDocTender.refTransaction?.PaymentId || this.selectedOrigCard?.refTransaction?.PaymentId,
            terminal_id: this.isPayWithCardCNP ? null : saleDocTender?.takuPaymentTerminal?.terminalId,
            invoiceNumber: this.saleDoc.id,
          };
          break;
      }
    } else {
      apiBody = {
        amount: saleDocTender.amount,
        invoiceNumber: this.saleDoc.id,
        AuthCode: saleDocTender.refTransaction?.AuthCode || null,
      };

      switch (saleDocTender?.paymentTerminal?.paymentGateway?.paymentGatewayType) {
        case PaymentGatewayType.PAYROC:
          paymentService = this.econduitService;
          break;
        case PaymentGatewayType.MONERIS:
          paymentService = this.monerisService;
          if (apiCommand === CommandName.COMMAND_SALE) {
            apiCommand = CommandName.COMMAND_PURCHASE;
          }
          if (apiCommand === CommandName.COMMAND_VOID) {
            if (this.saleDoc.doc.docType === SaleDocType.sales_return) {
              apiCommand = CommandName.COMMAND_REFUND_CORRECTION;
            } else {
              apiCommand = CommandName.COMMAND_PURCHASE_CORRECTION;
            }
          }
          break;
      }
    }

    return { apiBody, paymentService, apiCommand, saleDocTender } as PaymentServiceDetails;
  }

  private processTakuTransaction(
    saleDocTender: SaleDocTender,
    apiCommand: string,
    apiBody: Record<string, unknown>,
    paymentService: TakuPayService,
    index = 0,
    sMenuType = ""
  ): void {
    //-------------------------------------------------------------------------------------------------
    // Taku Payment Gateway Processing
    //-------------------------------------------------------------------------------------------------

    const storeId = saleDocTender?.takuPaymentTerminal?.takuPaymentGateway?.storeId
      ? saleDocTender?.takuPaymentTerminal?.takuPaymentGateway?.storeId
      : this.saleDoc.storeId;

    this.subsList.push(
      paymentService.runTransaction(apiCommand, storeId, apiBody).subscribe({
        next: (intentResponse) => {
          if (apiBody.payment_method_types === TakuPayMethodType.CARD_PRESENT && intentResponse?.payment_id) {
            this.terminalPaymentPromptReadyParamsSubject.next({
              storeId: storeId,
              paymentId: intentResponse.payment_id,
            });
          }

          const isPayment =
            apiCommand === TakuPayCommandName.COMMAND_INTENT &&
            apiBody?.payment_method_types !== TakuPayMethodType.INTERAC;
          if (isPayment) {
            this.processTakuPayment(
              intentResponse,
              saleDocTender,
              apiCommand,
              apiBody,
              storeId,
              paymentService,
              index,
              sMenuType
            );
          } else {
            // It's likely refund or cancel, but let's make sure
            const isRefundOrCancel =
              intentResponse &&
              (intentResponse["status"] === TakuPaymentStatus.STATUS_REFUNDED ||
                intentResponse["status"] === TakuPaymentStatus.STATUS_PARTIALLY_REFUNDED ||
                intentResponse["status"] === TakuPaymentStatus.STATUS_REFUND_REQUESTED ||
                intentResponse["status"] === TakuPaymentStatus.STATUS_CANCEL_REQUESTED);

            if (!isRefundOrCancel) {
              this.handlePaymentServiceError();
              return;
            }

            this.addSaleDocTenderFromCardResultToTenderScreen(
              saleDocTender,
              intentResponse,
              apiCommand,
              index,
              sMenuType
            );

            this.handlePaymentServiceSuccess();
          }
        },
        error: (errorResponse) => {
          this.handleTakuPaymentServiceApiError(errorResponse);
        },
      })
    );
  }

  private processTakuPayment(
    intentResponse: Record<string, any>,
    saleDocTender: SaleDocTender,
    initialApiCommand: string,
    initialApiBody: Record<string, unknown>,
    storeId: number,
    paymentService: TakuPayService | EconduitService | MonerisService,
    index: number,
    sMenuType: string
  ) {
    let authJson = {
      amount: saleDocTender.amount,
      payment_id: intentResponse.payment_id,
      external_transaction_id: intentResponse.external_transaction_id,
      terminal_id: this.isPayWithCardCNP ? null : saleDocTender.takuPaymentTerminal.terminalId,
    };

    // When do we need the address
    if (this.isPayWithCardCNP && this.cardInfoManualEntry) {
      authJson = Object.assign(authJson, this.cardInfoManualEntry);
    }

    if (initialApiBody.capture_method) {
      // only putting this in here for our backend to tell the difference for what intent was used.
      authJson = Object.assign(authJson, { capture_method: initialApiBody.capture_method });
    }

    const shouldWaitForCNPAuthorizationCode = this.isPayWithCardCNP && !this.isUnFinalizedPreOrder;
    if (shouldWaitForCNPAuthorizationCode) {
      authJson["wait_for_cnp_authorization_code"] = true;
    }

    paymentService.runTransaction(TakuPayCommandName.COMMAND_AUTH, storeId, authJson).subscribe({
      next: (response) => {
        const cardAuthResult: Record<string, any> = response?.body?.result;

        if (!cardAuthResult) {
          this.handlePaymentServiceError("Card processing unsuccessful: No cardAuthResult returned");
          return;
        }

        const isAuthSuccess =
          cardAuthResult &&
          (cardAuthResult["status"] === TakuPaymentStatus.STATUS_SUCCESS ||
            cardAuthResult["status"] === TakuPaymentStatus.STATUS_CAPTURE_REQUESTED ||
            cardAuthResult["status"] === TakuPaymentStatus.STATUS_REQUIRES_CAPTURE);

        if (!isAuthSuccess) {
          this.handlePaymentServiceError(`Card processing unsuccessful:
            ${cardAuthResult["notes"] ? `Note ${cardAuthResult["notes"]}` : ""}
            ${cardAuthResult["avs_response_code"] ? `avs_response_code ${cardAuthResult["avs_response_code"]}` : ""}
            ${
              cardAuthResult["avs_response_message"]
                ? `avs_response_message ${cardAuthResult["avs_response_message"]}`
                : ""
            }
          `);
          return;
        }

        this.addSaleDocTenderFromCardResultToTenderScreen(
          saleDocTender,
          cardAuthResult,
          TakuPayCommandName.COMMAND_SALE,
          index,
          sMenuType
        );

        // this is pay later pre-order 0.50 card check, so we need to cancel it
        if (this.isUnFinalizedPreOrder && saleDocTender.amount == 0.5) {
          const authCancelJson = {
            payment_id: authJson.payment_id,
          };

          paymentService.runTransaction(TakuPayCommandName.COMMAND_CANCEL, storeId, authCancelJson).subscribe({
            next: (cancelResult) => {
              const saleDocTenderCancel = Object.assign({}, cloneDeep(saleDocTender), {
                refNo: null,
                createdAt: new Date(),
              });
              // tenderForm.addControl('hasReturnedPair', this.fb.control(true));

              FormDataHelpers.clearModelIDs(saleDocTenderCancel);

              // add another tender line to the screen for the -0.50
              this.addSaleDocTenderFromCardResultToTenderScreen(
                saleDocTenderCancel,
                cancelResult,
                TakuPayCommandName.COMMAND_CANCEL,
                index,
                sMenuType
              );
              if (this.saveCardForFutureUse) {
                this.saveNewCardToTaku(cardAuthResult).subscribe(
                  () => this.handlePaymentServiceSuccess(),
                  (err) => this.handlePaymentServiceError("Error saving card data")
                );
              } else {
                this.handlePaymentServiceSuccess();
              }
            },
            error: (errorResponse) => {
              this.handleTakuPaymentServiceApiError(errorResponse);
            },
          });
        } else {
          if (this.saveCardForFutureUse) {
            this.saveNewCardToTaku(cardAuthResult).subscribe(
              () => this.handlePaymentServiceSuccess(),
              (err) => this.handlePaymentServiceError("Error saving card data")
            );
          } else {
            this.handlePaymentServiceSuccess();
          }
          return;
        }
      },
      error: (errorResponse) => {
        this.handleTakuPaymentServiceApiError(errorResponse);
      },
    });
  }

  private addSaleDocTenderFromCardResultToTenderScreen(
    saleDocTender: SaleDocTender,
    cardResponse: any,
    apiCommand: string,
    index: number,
    sMenuType: string
  ) {
    const responseTransaction: ResponseTransaction = new ResponseTransaction();
    responseTransaction.TransType = apiCommand;
    responseTransaction.TakuDisplayStatus = this.takuTenderDisplayStatus(apiCommand, sMenuType);
    responseTransaction.TerminalID = saleDocTender?.takuPaymentTerminal?.terminalId;
    responseTransaction.PaymentId = cardResponse["payment_id"];
    responseTransaction.AuthCode =
      cardResponse["payment"]?.["receipt_data"]?.["emv_auth_code"] ?? cardResponse["authorization_code"];
    responseTransaction.PaymentMethod = cardResponse["payment_method_id"];
    responseTransaction.Amount = cardResponse["amount"];

    responseTransaction.PaymentType = cardResponse["payment"]?.["type"];
    responseTransaction.CardType = cardResponse["payment"]?.["brand"];
    responseTransaction.Last4 = cardResponse["payment"]?.["last_4"];
    responseTransaction.Name = cardResponse["payment"]?.["cardholder_name"];
    responseTransaction.ExpDate = `${cardResponse["payment"]?.["exp_month"]}/${cardResponse["payment"]?.["exp_year"]}`;

    responseTransaction.RefID = cardResponse["payment_method_id"]
      ? cardResponse["payment_method_id"]
      : cardResponse["payment"]?.["receipt_data"]?.["emv_reference_number"];
    responseTransaction.TerminalID = cardResponse["payment"]?.["receipt_data"]?.["emv_term_id"];
    responseTransaction.EMV_App_Label = cardResponse["payment"]?.["receipt_data"]?.["emv_app_label"];
    responseTransaction.EMV_App_Name = cardResponse["payment"]?.["receipt_data"]?.["emv_app_id"];
    responseTransaction.Cryptogram = cardResponse["payment"]?.["receipt_data"]?.["emv_cryptogram"];
    responseTransaction.EntryMethod = cardResponse["payment"]?.["receipt_data"]?.["card_entry_mode"];

    if (apiCommand == TakuPayCommandName.COMMAND_CANCEL) {
      saleDocTender.isReturned = true;
      saleDocTender.isTendered = false;
      saleDocTender.amount = -1 * parseFloat(cardResponse.amount);
    }

    saleDocTender.refTransaction = responseTransaction;
    saleDocTender.refTransaction["customerReceiptTemplate"] = this.takuPayService.createPaymentReceipt(
      saleDocTender.refTransaction,
      false
    );
    saleDocTender.refTransaction["merchantReceiptTemplate"] = this.takuPayService.createPaymentReceipt(
      saleDocTender.refTransaction,
      true
    );

    const otherTypePaymentTender = this.storeTenderTypes.find(
      (storeTenderType) =>
        storeTenderType.tenderType.type !== Tender_Type.Cash &&
        storeTenderType.tenderType.otherType === responseTransaction.CardType
    );

    if (otherTypePaymentTender) {
      saleDocTender.tenderTypeId = otherTypePaymentTender.tenderTypeId;
      saleDocTender.tenderType = otherTypePaymentTender.tenderType;
    }

    const cardType = this.takuPayService.getCardtype(responseTransaction.CardType);
    this.tenderTypesEnable$
      .pipe(
        map((tenderTypes: TenderType[]) =>
          tenderTypes.find((tenderType) => tenderType.type !== Tender_Type.Cash && tenderType.otherType === cardType)
        )
      )
      .subscribe((paymentTender) => {
        if (paymentTender) {
          saleDocTender.tenderTypeId = paymentTender.id;
          saleDocTender.tenderType = paymentTender;
        }
      });

    if (index > 0 || saleDocTender.isReturned) {
      this.tendersFormArray.insert(index + 1, SaleDocTenderComponent.set(this.fb, saleDocTender));
    } else {
      this.tendersFormArray.push(SaleDocTenderComponent.set(this.fb, saleDocTender));
    }
  }

  /**
   * This method is used to save the new card to the taku account.
   * It also automatically checks if there's a card on file with the same details.
   */
  private saveNewCardToTaku(cardAuthResult: any): Observable<any> {
    if (cardAuthResult?.["payment_method_id"] && this.saleDoc.accountId > 0) {
      const filteredCard: AccountCreditCard[] = this.saleDoc.account?.accountCreditCards.filter(
        (card: AccountCreditCard) =>
          card.accountId === this.saleDoc.accountId &&
          card.token === cardAuthResult?.["payment_method_id"] &&
          card.status === AccountCreditCardStatus.ACTIVE
      );
      const selectedCardOnFileAlreadyExists = filteredCard.length > 0;

      if (!selectedCardOnFileAlreadyExists) {
        const card = new AccountCreditCard();
        card.accountId = this.saleDoc.accountId;
        card.token = cardAuthResult?.["payment_method_id"];
        card.status = AccountCreditCardStatus.ACTIVE;
        card.cardType = cardAuthResult?.["payment"]?.["brand"];
        card.last4Digits = cardAuthResult?.["payment"]?.["last_4"];
        card.expiryDate =
          (cardAuthResult?.["payment"]?.["exp_month"]).toString() +
          "/" +
          (cardAuthResult?.["payment"]?.["exp_year"]).toString().slice(-2);
        card.cardHolderName = cardAuthResult?.["payment"]?.["cardholder_name"];
        card.isDefault = cardAuthResult?.["payment_method_id"] !== null ? true : false;
        card.postalCode = this.cardInfoManualEntry?.billing_address?.zip
          ? this.cardInfoManualEntry?.billing_address?.zip
          : null;

        const account: Account = this.saleDoc.account;
        account.accountCreditCards.push(card);
        const patchUpdate: Partial<Account> = {
          id: account.id,
          updatedAt: account.updatedAt,
          accountCreditCards: account.accountCreditCards,
        };
        return this.dbService.patchRow("account", patchUpdate);
      }
    }
    return of({});
  }

  private processMonerisPayrocTransaction(
    saleDocTender: SaleDocTender,
    apiCommand: string,
    apiBody: Record<string, unknown>,
    paymentService: TakuPayService | EconduitService | MonerisService,
    index = 0,
    sMenuType = ""
  ): void {
    //-------------------------------------------------------------------------------------------------
    // Payment Gateway Processing (MONERIS, PAYROC)
    //-------------------------------------------------------------------------------------------------
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    this.subsList.push(
      paymentService.runTransaction(apiCommand, saleDocTender.paymentTerminalId, apiBody).subscribe({
        next: (response) => {
          // const responseTransaction: ResponseTransaction = Object.assign({}, response);
          this.progressBar.close();
          this.isWaiting = false;
          const responseTransaction: ResponseTransaction = new ResponseTransaction();

          responseTransaction.AuthCode = response["AuthCode"];
          responseTransaction.CardType = response["CardName"];
          responseTransaction.Last4 = response["Pan"];
          responseTransaction.RefID = response["ReferenceNumber"];

          if (response && response["Error"] === "false" && response["ResponseCode"]) {
            if (response["ResponseCode"] == null) {
              this.messageService.add({
                summary: "Incomplete",
                severity: "error",
                detail: "Request is incomplete", // paymentService.getResponseErrorCode(response['ErrorCode'])
              });
            } else if (Number(response["ResponseCode"]) < 50) {
              this.messageService.add({
                summary: "Success",
                severity: "success",
                detail: `Transaction Approved`,
              });

              responseTransaction.TransType = apiCommand;
              responseTransaction.TakuDisplayStatus = this.takuTenderDisplayStatus(apiCommand, sMenuType);
              saleDocTender.refTransaction = responseTransaction;
              saleDocTender.refTransaction["customerReceiptTemplate"] = response["customerReceiptTemplate"];
              saleDocTender.refTransaction["merchantReceiptTemplate"] = response["merchantReceiptTemplate"];

              if (response["PartialAuthAmount"]) {
                saleDocTender.amount = Number(response["PartialAuthAmount"]);
              }

              // select correct sale doc tender based on card type
              const cardType = this.monerisService.getCardtype(response["CardType"].trim());
              this.storeTenderTypes$
                .pipe(
                  map((storeTenderTypes: StoreTenderType[]) =>
                    storeTenderTypes.find(
                      (storeTenderType) =>
                        storeTenderType.tenderType.type !== Tender_Type.Cash &&
                        storeTenderType.tenderType.otherType === cardType
                    )
                  )
                )
                .subscribe((paymentTender) => {
                  if (paymentTender) {
                    saleDocTender.tenderTypeId = paymentTender.tenderTypeId;
                    saleDocTender.tenderType = paymentTender.tenderType;
                  }
                });

              if (index > 0 || saleDocTender.isReturned) {
                this.tendersFormArray.insert(index + 1, SaleDocTenderComponent.set(this.fb, saleDocTender));
              } else {
                this.tendersFormArray.push(SaleDocTenderComponent.set(this.fb, saleDocTender));
              }
            } else {
              this.messageService.add({
                summary: "Declined",
                severity: "error",
                detail: "Transaction Declined.", // paymentService.getResponseErrorCode(response['ErrorCode'])
              });
            }
            // print transactions
            // this.printingMgr = this.printingFactory.build(this.saleDoc.storeId);
            // this.monerisService.printPaymentReceipt(this.printingMgr,saleDocTender,this.saleDoc,this.printPreviewHost);
          } else {
            // Illegal case check, recall status
            // if (responseTransaction.ResultCode === paymentService.sResultCodeError && !JSON.parse(responseTransaction.ResultFinal)) {
            //
            // }

            this.messageService.add({
              summary: "Error",
              severity: "error",
              detail: (paymentService as MonerisService).getResponseErrorCode(response["ErrorCode"]),
              life: 8000,
            });
          }
          // this.saleDocComponent.onSave();
          this.selectedTender = null;
        },
        error: (errorResponse) => {
          this.handlePaymentServiceApiError(paymentService, errorResponse);
        },
      })
    );
  }

  private callApiRunTransaction(
    saleDocTender: SaleDocTender,
    apiCommand: string,
    index = 0,
    sMenuType = ""
  ): Promise<void> {
    if (this.isWaiting) {
      return;
    }

    this.isWaiting = true;

    const paymentServiceDetails: PaymentServiceDetails = this.generatePaymentServiceDetails(saleDocTender, apiCommand);

    const apiBody = paymentServiceDetails.apiBody;
    const paymentService = paymentServiceDetails.paymentService;
    apiCommand = paymentServiceDetails.apiCommand;
    saleDocTender = paymentServiceDetails.saleDocTender;

    if (!paymentService) {
      this.handlePaymentServiceError("An unknown error occurred during payment service initialization.");
      return;
    }

    const isRefundByOriginalCard =
      this.selectedTenderTab === TenderTab.PAYBACKS &&
      this.selectedOrigCard &&
      this.isTakuPayEnabled &&
      this.selectedOrigCard.tenderType?.type !== Tender_Type.Debit_Card;

    if (
      saleDocTender.refTransaction?.PaymentId ||
      saleDocTender?.takuPaymentTerminal?.takuPaymentGateway ||
      this.isPayWithCardCNP ||
      isRefundByOriginalCard
    ) {
      const takuPaymentService = paymentService as TakuPayService;

      if (apiBody.payment_method_types === TakuPayMethodType.CARD_PRESENT) {
        this.progressBar = this.blockUIService.showProgressBar(
          this.dialogService,
          false,
          {},
          {
            btnText: "Cancel",
            onClickReadyParams$: this.terminalPaymentPromptReadyParams$,
            onClick: (event: { storeId: number; paymentId: string }) => {
              // hit quilt cancel transaction api
              // Server polling, from the auth call, will discover that it was cancelled all on it's own, so we do nothing on this api call success.
              takuPaymentService.cancelTerminalPaymentPrompt(event.storeId, event.paymentId).subscribe({
                // let server polling, on auth call, handle success
                error: (errorResponse) => {
                  this.handleTakuPaymentServiceApiError(errorResponse);
                },
              });
            },
          }
        );
      } else {
        this.progressBar = this.blockUIService.showProgressBar(this.dialogService, false);
      }

      this.processTakuTransaction(saleDocTender, apiCommand, apiBody, takuPaymentService, index, sMenuType);
    } else {
      this.progressBar = this.blockUIService.showProgressBar(this.dialogService, false);
      this.processMonerisPayrocTransaction(saleDocTender, apiCommand, apiBody, paymentService, index, sMenuType);
    }
  }

  private handlePaymentServiceSuccess() {
    this.progressBar.close();
    this.isWaiting = false;

    this.messageService.add({
      summary: "Success",
      severity: "success",
      detail: `Transaction Approved.`,
    });

    this.selectedTender = null;
  }

  private handlePaymentServiceError(message = `Transaction Error.`) {
    this.progressBar.close();
    this.isWaiting = false;

    this.messageService.add({
      summary: "Error",
      severity: "error",
      detail: message,
      life: 5000,
    });

    this.selectedTender = null;
  }

  private handlePaymentServiceApiError(
    paymentService: TakuPayService | EconduitService | MonerisService,
    errorResponse: HttpErrorResponse
  ) {
    this.progressBar.close();
    this.isWaiting = false;
    if (errorResponse instanceof HttpErrorResponse && errorResponse.status !== 500) {
      // This case net::ERR_FAILED
      if (errorResponse.status == 0 && errorResponse.statusText === paymentService.sMesageUnknownError) {
        this.messageService.add(
          this.alertMessagesService.getErrorMessage(
            null,
            "ERROR",
            `${errorResponse.statusText}\nPlease make another VERIFICATION REQUEST.`
          )
        );
      } else if (errorResponse.error.error && errorResponse.error.error.message) {
        const errorMsg = (errorResponse.error.message || errorResponse.error.error.message) as string;
        this.messageService.add(
          this.alertMessagesService.getErrorMessage(
            null,
            "ERROR",
            `${errorMsg}\nPlease make another VERIFICATION REQUEST.`
          )
        );
      }
    } else {
      this.messageService.add(this.alertMessagesService.getErrorMessage(errorResponse));
    }
  }

  private handleTakuPaymentServiceApiError(errorResponse: HttpErrorResponse) {
    if (this.progressBar) {
      this.progressBar.close();
    }
    this.isWaiting = false;
    const messageAlertObj: Message = this.alertMessagesService.getErrorMessage(errorResponse);

    messageAlertObj["life"] = 5000;

    this.messageService.add(messageAlertObj);
  }

  printPaymentReceipt(_saleDocTender: SaleDocTender): void {
    this.printingMgr = this.printingFactory.build(this.saleDoc.storeId);
    if (PrintHelpers.isTakuPayTransaction(this.saleDoc)) {
      this.takuPayService.printPaymentReceipt(this.printingMgr, _saleDocTender, this.saleDoc, this.printPreviewHost);
    } else {
      this.monerisService.printPaymentReceipt(
        this.printingMgr,
        _saleDocTender,
        this.saleDoc,
        this.printPreviewHost,
        true
      );
    }
  }

  private _updatePaymentTabs() {
    const updateTenderTab = (tabs: SelectItem[]) => {
      const tab = tabs.find((tab) => tab.value == TenderTab.TENDERS);
      tab.disabled = this.areTendersDisabled();
    };

    const updatePaybackTab = (tabs: SelectItem[]) => {
      const tab = tabs.find((tab) => tab.value == TenderTab.PAYBACKS);
      tab.disabled = this.arePaybacksDisabled();
    };

    // Update tenders
    updateTenderTab(this.tenderTabsDesktop);
    updateTenderTab(this.tenderTabsMobile);

    // Update payback
    updatePaybackTab(this.tenderTabsDesktop);
    updatePaybackTab(this.tenderTabsMobile);

    // Automatically switch the tender tab according to new status (balance positive or negative)
    if (this.remainingBalance >= 0) this.selectedTenderTab = TenderTab.TENDERS;
    else this.selectedTenderTab = TenderTab.PAYBACKS;
  }

  areTendersDisabled(): boolean {
    // return this._mode != TenderScreenMode.RETURNS && this.remainingBalance <= 0 ||
    //        this._mode == TenderScreenMode.RETURNS && this.totalPaid >= 0;
    return this.remainingBalance === 0 || (this.remainingBalance < 0 && this.totalPaid >= 0);
  }

  arePaybacksDisabled(): boolean {
    // return this._mode != TenderScreenMode.RETURNS && this.remainingBalance >= 0 ||
    //        this._mode == TenderScreenMode.RETURNS && this.totalPaid <= 0;
    return this.remainingBalance === 0 || (this.remainingBalance > 0 && this.totalPaid <= 0);
  }

  scrollTransactionsDown(): void {
    setTimeout(() => {
      const nativeEl = this.transactionsWrapper.nativeElement;
      nativeEl.scrollTop = nativeEl.scrollHeight;

      // if (this.transactionsWrapper)
      //  this.transactionsWrapper.scrollTop( this.transactionsWrapper.contentViewChild.nativeElement.scrollHeight );
    }, 0);
  }

  // goBack($event){
  //   this.screenClosed.emit();
  // }

  onClosePressed($event): void {
    $event.preventDefault();
    $event.stopPropagation();
    $event.currentTarget.disabled = true;

    this.appSettingsService.updateTenderPrintSettings(this.tenderSettingsForm.value);
    const isEmailRequired = this.ctrlEmailAddress.hasValidator(Validators.required);
    if (isEmailRequired) {
      this.addEmailAddressToAccount();
    }

    setTimeout(() => {
      this.subsList.push(
        this.dbCashoutService
          .searchOpenCashout$(this.appSettingsService.getStoreId(), this.appSettingsService.getStationId())
          .subscribe({
            next: (cashout) => {
              if (cashout) {
                if (cashout.id !== this.appSettingsService.getCashoutId()) {
                  this.appSettingsService.setCashout(cashout);
                }
                this.finalizeTenderScreen();
                this.onPaymentProcessed.emit();
              } else {
                this.confirmationService.confirm({
                  header: "Warning",
                  message: `This Station has already been cashed out. You will need to cash in to be able to process sales. This transaction will be automatically suspended. You can recall it from Parked Transactions to finalize.`,
                  acceptLabel: "OK",
                  rejectVisible: false,
                  acceptVisible: true,
                  accept: () => {
                    this.router.navigate(["/sell/cashout", 0, "saleDocument"], {
                      state: { bypassGuards: true },
                    });
                  },
                });
              }
            },
          })
      );
    }, 1000);
  }

  private addEmailAddressToAccount() {
    if (this.saleDoc.account && this.ctrlEmailAddress.value) {
      this.updatePersonalAccount();
      this.updateCommercialAccount();
    }
  }

  private updatePersonalAccount() {
    if (this.saleDoc.personalAccount) {
      const existingPersonalEmail = this.saleDoc.personalAccount.person.personEmails.find(
        (row) => row.email === this.ctrlEmailAddress.value
      );
      if (!existingPersonalEmail) {
        const personEmail: PersonEmail = new PersonEmail();
        personEmail.email = this.ctrlEmailAddress.value;
        this.saleDoc.personalAccount.person.personEmails.push(personEmail);
        const patchUpdate: Partial<Person> = {
          id: this.saleDoc.personalAccount.person.id,
          updatedAt: this.saleDoc.personalAccount.person.updatedAt,
          personEmails: this.saleDoc.personalAccount.person.personEmails,
        };
        this.subsList.push(this.dbService.patchRow("person", patchUpdate).subscribe());
      }
    }
  }

  private updateCommercialAccount() {
    if (this.saleDoc.commercialAccount) {
      const existingCommercialEmail = this.saleDoc.commercialAccount.commercialAccountEmails.find(
        (row) => row.email === this.ctrlEmailAddress.value
      );
      if (!existingCommercialEmail) {
        const commercialAccountEmail: CommercialAccountEmail = new CommercialAccountEmail();
        commercialAccountEmail.email = this.ctrlEmailAddress.value;
        this.saleDoc.commercialAccount.commercialAccountEmails.push(commercialAccountEmail);
        const patchUpdate: Partial<CommercialAccount> = {
          id: this.saleDoc.commercialAccount.id,
          updatedAt: this.saleDoc.commercialAccount.updatedAt,
          commercialAccountEmails: this.saleDoc.commercialAccount.commercialAccountEmails,
        };
        this.subsList.push(this.dbService.patchRow("commercialAccount", patchUpdate).subscribe());
      }
    }
  }

  // onAccountSelected(account: SearchResultItem){
  //   this.accountSelected.emit(account);

  // }

  // onNewPersonalAccountCreated(personalAccount: PersonalAccount) {
  //   const accountResultItem = SearchResultItem.build("personalAccount", personalAccount);
  //   this.onAccountSelected(accountResultItem);
  //   this.closeFullSizeDialog();
  // }

  // onNewCommercialAccountCreated(commecialAccount: CommercialAccount) {
  //   const accountResultItem = SearchResultItem.build("commercialAccount", commecialAccount);
  //   this.onAccountSelected(accountResultItem);
  //   this.closeFullSizeDialog();
  // }

  closeFullSizeDialog() {
    this._activeFullDialog = null;
  }

  onNewAccount(modelName) {
    let dialogName: DialogName;
    switch (modelName) {
      case "personalAccount":
        dialogName = DialogName.PERSONAL_ACCOUNT;
        break;
      case "commercialAccount":
        dialogName = DialogName.COMMERCIAL_ACCOUNT;
        break;
    }

    const defaultData$ = this.accountsSearchComponent.prefillDataFromSearchText(modelName);
    defaultData$.subscribe((defaultData) => {
      if (defaultData) {
        this.openFullSizeDialog(dialogName, defaultData);
      }
    });
  }

  openFullSizeDialog(dialog: DialogName, extra = null): any {
    this._activeFullDialog = dialog;
    this._activeFullDialogExtra = extra;
  }

  // clearDocAccount(){
  //   this.accountSelected.emit(null);
  // }

  get isSalesInvoice() {
    return this._myForm.get("doc").value.docType === SaleDocType.sales_invoice;
  }

  checkTakuPaymentTerminal(stationId: number): Observable<boolean> {
    const filter = {
      stationId: { value: stationId, matchMode: "equals" },
      isPaired: { value: true, matchMode: "equals" },
    };

    return this.takuPayService
      .getRows("takuPaymentTerminal", JSON.stringify(filter), 0, 1)
      .pipe(map((res: any) => res.rows && res.rows.length));
  }

  takuTenderDisplayStatus(apiCommand: string, sMenuType: string): string {
    let taKuDisplayStatus = "";
    if (apiCommand === CommandName.COMMAND_VOID || apiCommand === CommandName.COMMAND_PURCHASE_CORRECTION) {
      if (sMenuType === MenuTenderLineUndoName.SALE_VOID) {
        taKuDisplayStatus = TenderLineStatusName.SALE_VOIDED;
      } else {
        taKuDisplayStatus = TenderLineStatusName.REFUND_VOIDED;
      }
    } else if (apiCommand === CommandName.COMMAND_REFUND) {
      taKuDisplayStatus = TenderLineStatusName.SALE_REFUNDED;
    } else if (apiCommand === TakuPayCommandName.COMMAND_CANCEL) {
      taKuDisplayStatus = TenderLineStatusName.SALE_VOIDED;
    } else {
      taKuDisplayStatus = TenderLineStatusName.SALE_APPROVED;
    }

    return taKuDisplayStatus;
  }

  private getEnableTenderTypes(): Observable<TenderType[]> {
    const zoneFilter = JSON.stringify({
      zoneId: { value: this.appSettingsService.getZoneId(), matchMode: "equals" },
      isEnabled: { value: true, matchMode: "equals" },
    });
    return this.dbService.getRows("tenderType", zoneFilter, 0, 1000).pipe(map((response) => response.rows));
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
