/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { DBService } from "../../../shared/services/db.service";
import { FormListModel } from "../../../form-list/form-list/form-list-model.interface";
import { ValidatorFn } from "@angular/forms";
import { ArrayDatatypeOptions, DataType, FilterType } from "../../../form-list/form-list/form-list";
import { SaleDocTenderArrayComponent } from "../../document/sale-document/sale-doc-tender-array/sale-doc-tender-array.component";
import { SaleDoc } from "../../document/sale-document/sale-doc/sale-doc";
import { SaleDocTender } from "../../document/sale-document/sale-doc-tender/sale-doc-tender";
import { MonetaryHelpers } from "src/app/utility/MonetaryHelpers";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "src/app/shared/services/auth.service";
import { CurrencyFormatterService } from "src/app/shared/services/currency-formatter.service";
import { DocState, SaleDocType } from "../../document/doc/doc";
@Injectable({
  providedIn: "root",
})
export class CashoutTenderDocsService extends DBService implements FormListModel {
  enum_doc_types = this.enumSelectOptions(SaleDocType);
  enum_doc_states = this.enumSelectOptions(DocState);

  _model = "saleDoc";
  _tenderTypeId: number;
  constructor(
    protected http: HttpClient,
    protected authService: AuthService,
    protected currencyService: CurrencyFormatterService
  ) {
    super(http, authService);
  }

  getValidationRules(): { [key: string]: {} | ValidatorFn[] } {
    return {};
  }

  afterFetch(saleDoc: SaleDoc, model: string) {
    switch (model) {
      case this._model:
        saleDoc.saleDocTenders = saleDoc.saleDocTenders.filter((row) => row.tenderTypeId === this._tenderTypeId);
    }
  }

  totalAmount(currentSaleDocTender: SaleDocTender, allSaleDocTenders: SaleDocTender[]) {
    return this.currencyService.formatMonetaryAmount({
      currencyIsoCode: currentSaleDocTender.tenderType.currencyIsoCode,
      amount: MonetaryHelpers.roundToDecimalPlaces(
        allSaleDocTenders
          .filter((row) => row.tenderTypeId === this._tenderTypeId)
          .map((row) => Number(row.amount))
          .reduce((a, b) => a + b, 0)
      ),
    });
  }

  getFormListColumns() {
    return [
      {
        field: "doc.docNo",
        header: "Doc #",
        visible: true,
        readonly: true,
        frozen: true,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "doc.state",
        header: "Status",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enum_doc_states,
        filterType: FilterType.enum,
        colWidth: 70,
      },
      {
        field: "doc.docType",
        header: "Doc Type",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enum_doc_types,
        filterType: FilterType.enum,
        colWidth: 120,
      },
      {
        field: "doc.docDate",
        header: "Date",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.date_date_only,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 95,
      },
      {
        field: "doc.localDocTime",
        header: "Time",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.date_time,
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 80,
        sortFields: ["doc.docDate", "doc.docTime"],
      },
      // { field: 'doc.dateAndTime', header: 'Date and Time', visible: true, readonly: true, frozen: false, dataType: DataType.input, dataOptions: [], filterType: FilterType.contains, colWidth: 90, filterField: 'doc.docDate' },
      // { field: 'doc.localDocTime', header: 'Time', visible: true, readonly: true, frozen: false, dataType: DataType.date_time, dataOptions: [], filterType: FilterType.contains, colWidth: 60 },
      {
        field: "accountName",
        header: "Account",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.none,
        isNotSortable: true,
        colWidth: 100,
      },
      {
        field: "doc.user.userName",
        header: "User",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        isNotSortable: true,
        colWidth: 100,
      },
      {
        field: "saleDocTenders",
        header: "Total",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.array,
        dataOptions: [],
        dataTypeOptions: new ArrayDatatypeOptions({
          component: SaleDocTenderArrayComponent,
          displayField: this.totalAmount.bind(this),
          showSummary: true,
        }),
        filterType: FilterType.none,
        isNotSortable: true,
        colWidth: 85,
      },
    ];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
