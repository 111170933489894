<div class="col-12 md:col-8 lg:col-5 content-container grid">
  <div class="col-12 body bgColor-white">
    <div class="brand-container">
      <div class="logo-container">
        <img src="assets/layout/images/logo.png" />
      </div>
    </div>

    <div class="cancel-recurring-label-container">
      <h2>Are you sure you want to cancel this recurring order?</h2>
    </div>

    <div *ngIf="!showConfirmation">
      <button pButton class="p-button-lg cancelRecurringBtn mt-3" (click)="cancelRecurringOrder()" label="Yes, cancel">
      </button>
    </div>
  </div>
</div>

<div class="confirm-container" *ngIf="showConfirmation">
  <h3>Your order has been cancelled successfully</h3>
</div>
