<taku-form-header
  toolbarTitle="REQUEST LOCATION VERIFICATION"
  displayMode="inline"
  [showActionBtns]="false"
  [showBackBtn]="false"
></taku-form-header>

<div [formGroup]="_formGroup" class="col-12">
  <div class="col-12">
    <label class="field-caption">Verification Method</label>
  </div>

  <div class="col-12" *ngIf="!_availableVerificationOptions">
    <p-progressBar mode="indeterminate" [style]="{ height: '8px' }"></p-progressBar>
  </div>

  <div *ngIf="_availableVerificationOptions">
    <div *ngFor="let method of _availableVerificationOptions" class="col-12 verificationMethods-wrapper">
      <div [ngSwitch]="method.verificationMethod" class="verification-method">
        <div *ngSwitchCase="VerificationMethods.PHONE_CALL" class="method-phonecall">
          <p-radioButton
            name="verificationMethod"
            [value]="method.verificationMethod"
            formControlName="verificationMethod"
            label="Phone Call"
          ></p-radioButton>
        </div>
        <div *ngSwitchCase="VerificationMethods.SMS" class="method-sms">
          <p-radioButton
            name="verificationMethod"
            [value]="method.verificationMethod"
            formControlName="verificationMethod"
            label="SMS Message"
          ></p-radioButton>
        </div>
        <div *ngSwitchCase="VerificationMethods.EMAIL" class="method-email">
          <p-radioButton
            name="verificationMethod"
            [value]="method.verificationMethod"
            formControlName="verificationMethod"
            label="Email"
          ></p-radioButton>
        </div>
        <div *ngSwitchCase="VerificationMethods.ADDRESS" class="method-postcard">
          <p-radioButton
            name="verificationMethod"
            [value]="method.verificationMethod"
            formControlName="verificationMethod"
            label="Postcard to Physical Address"
          ></p-radioButton>
          <div class="alert-note">NOTE: May take 2-3 weeks</div>
        </div>
        <div *ngSwitchDefault class="method-other">
          <p-radioButton
            name="verificationMethod"
            [value]="method.verificationMethod"
            formControlName="verificationMethod"
            [label]="method.verificationMethod"
          ></p-radioButton>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 notes" [ngSwitch]="ctrlVerificationMethod.value">
    <span *ngSwitchCase="VerificationMethods.PHONE_CALL">
      You will receive a phone call with a verification PIN at
      <strong>{{ selectedVerificationOption.phoneData.phoneNumber }}</strong
      >. The PIN is used to complete verification with Google.
    </span>
    <span *ngSwitchCase="VerificationMethods.SMS">
      You will receive a SMS message with a verification PIN at
      <strong>{{ selectedVerificationOption.phoneData.phoneNumber }}</strong
      >. The PIN is used to complete verification with Google.
    </span>
    <span *ngSwitchCase="VerificationMethods.EMAIL">
      You will receive a message with a verification PIN at
      <strong
        >{{ selectedVerificationOption.emailData.userName }}@{{
          selectedVerificationOption.emailData.domainName
        }}</strong
      >
    </span>
    <span *ngSwitchCase="VerificationMethods.ADDRESS">
      You will receive a postcard with a verification PIN at the folling address: <br />
      <em>{{ selectedVerificationOption.addressData.businessName }}</em
      ><br />
      <em
        [innerHTML]="
          GMBHelpers.PreviewGMBLocationAddress(selectedVerificationOption.addressData.address) | replaceLineBreaks
        "
      ></em
      ><br /><br />
      The PIN is used to complete verification with Google.
    </span>
  </div>
  <div class="grid">
    <div class="col-6 p-fluid">
      <button pButton label="ACCEPT" (click)="onAcceptPressed()"></button>
    </div>
    <div class="col-6 p-fluid">
      <button pButton label="CANCEL" class="p-button-secondary" (click)="onCancelPressed()"></button>
    </div>
  </div>
</div>
