/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, ViewChild } from "@angular/core";
import { WebHelpers } from "src/app/utility/WebHelpers";
import { ReturnInvoiceDocBaseComponent } from "../return-invoice-doc-base/return-invoice-doc-base.component";
import { ReturnInvoiceStateService } from "../return-invoice-state.service";

@Component({
  selector: "taku-return-invoice-doc-main",
  templateUrl: "./return-invoice-doc-main.component.html",
  styleUrls: ["./return-invoice-doc-main.component.scss"],
  providers: [ReturnInvoiceStateService],
})
export class ReturnInvoiceDocMainComponent implements OnInit {
  @ViewChild("invoiceDocComponent") invoiceDocComponent: ReturnInvoiceDocBaseComponent;
  readonly cssClassName = "sale-invoice-component";

  constructor(private invoiceStateService: ReturnInvoiceStateService) {}

  ngOnInit() {
    document.body.classList.add(this.cssClassName);
  }

  get useMobileDesign() {
    return WebHelpers.isMobileScreen();
  }

  get useTabletDesign() {
    return WebHelpers.isPortraitTabletScreen();
  }

  get useDesktopDesign() {
    return WebHelpers.isDesktopScreen();
  }

  ngOnDestroy(): void {
    this.invoiceStateService.clearDocState();
    this.invoiceStateService.clearInvoiceState(this.invoiceDocComponent);
    document.body.classList.remove(this.cssClassName);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
