/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
import { SaleDocType } from "src/app/core/document/doc/doc";

export class SaleAnalysisSummaryReport {
  id: number;
  docId: number;
  docType: SaleDocType;
  docClosingDate: string;
  totalAmountCalculated: number;

  taxAccountCategory: string;
  accountName: string;
  docNo: string;
  taxID: string;
  totalSoldPrice: number;
  totalStandardCost: number;

  currencyIsoCode: string;
  subTotal: number;
  totalTaxAmount: number | string;
  shipperChargeAmount: number | string;
  discountAmount: number | string;

  state: string;
  accountCode: string;
  stationName: string;
  customerSource: string;
  channelName: string;

  marginPercentCalculated: number;
  marginAmountCalculated: number;

  saleDocId: number;

  constructor() {
    this.id = 0;
    this.docId = null;
    this.docType = null;
    this.docClosingDate = null;
    this.totalAmountCalculated = null;

    this.accountName = null;
    this.taxAccountCategory = null;
    this.docNo = null;
    this.taxID = null;
    this.currencyIsoCode = null;
    this.totalSoldPrice = null;
    this.totalStandardCost = null;
    this.subTotal = null;
    this.totalTaxAmount = null;
    this.shipperChargeAmount = 0;
    this.discountAmount = 0;
    this.marginPercentCalculated = 0;
    this.marginAmountCalculated = 0;

    this.state = "";
    this.accountCode = "";
    this.stationName = "";
    this.customerSource = "";
    this.channelName = "";

    this.saleDocId = 0;
  }

  get totalAmount() {
    return (
      parseFloat(this.subTotal + "") -
      parseFloat(this.discountAmount + "") +
      parseFloat(this.totalTaxAmount + "") +
      parseFloat(this.shipperChargeAmount + "")
    );
  }
  get marginPercent() {
    // const _subTotal = this.subTotal * (this.docType == SaleDocType.sales_return ? -1 : 1);
    if (this.subTotal == 0) return "0";
    if (Number.isNaN((this.subTotal - this.totalStandardCost) / this.subTotal)) return "0";
    return (((this.subTotal - this.totalStandardCost) / this.subTotal) * 100).toFixed(2);
  }

  get marginAmount() {
    return this.subTotal - this.totalStandardCost; // * (this.docType == SaleDocType.sales_return ? -1 : 1)
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
