<taku-form-header
  toolbarTitle="Manage Users"
  iconStyleClass="ui-icon-group"
  displayMode="inline"
  [showActionBtns]="false"
  [showBackBtn]="true"
  (backClicked)="onBackClicked()"
></taku-form-header>

<taku-form-list
  _model="user"
  _detailMode="w"
  _scrollHeight="calc(100vh - 24.5rem)"
  [_cols]="userCols"
  [_hideAddLineHeaderBtn]="true"
  [_filter]="activeFilter"
></taku-form-list>
