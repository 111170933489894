/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable, Component } from "@angular/core";
import { FormListMainComponent } from "../form-list/form-list-main/form-list-main.component";
import { UnsavedChangesGuard } from "./UnsavedChanges.guard";
import { Observable } from "rxjs";
import { MultiFormListComponent } from "../form-list/form-list-main/multiple-form-lists.interface";

@Injectable()
export class UnsavedFormListGuard extends UnsavedChangesGuard {
  canDeactivate(component: Component): boolean | Observable<boolean> {
    if (this._router.getCurrentNavigation()?.extras?.state?.bypassGuards) {
      // Used for auto-logout due to idleness
      return true;
    }
    if (component instanceof FormListMainComponent) {
      if (component.formListComponent && component.formListComponent.hasPendingChanges)
        return this.showConfirmationDialog();
    }

    // Check if the component has multiple function for retrieving formlist components
    if (typeof (component as MultiFormListComponent).retrieveFormLists === "function") {
      const formLists = (component as MultiFormListComponent).retrieveFormLists();
      for (const formListComponent of formLists) {
        if (formListComponent.hasPendingChanges) return this.showConfirmationDialog();
      }
    }

    return true;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
