<div id="opening-cashout-tape-slip">
  <header>
    <section *ngIf="store" id="retailer-info">
      <div class="retailer-logo-wrapper">
        <img
          *ngIf="businessLogoUrl"
          [attr.src]="businessLogoUrl"
          [attr.alt]="businessDetail.businessName"
          style="max-width: 130px"
        />
        <span *ngIf="!businessLogoUrl" class="retailer-logo-placeholder">YOUR LOGO HERE</span>
      </div>

      <h2 class="retailer-name">{{ store.storeName }}</h2>

      <div *ngIf="store.address" class="retailer-address">
        <div class="address-line1">{{ store.address?.line1 }}</div>
        <div class="address-city"
          >{{ store.address?.city }}, {{ subdivisionDisplay(store.address?.subDivisionIsoCode) }}
          {{ _countryName | uppercase }} {{ store.address?.postalCode }}</div
        >
      </div>

      <div *ngIf="store.address?.addressPhone" class="retailer-phone">{{
        store.address?.addressPhone.fullPhoneNumber
      }}</div>
    </section>
  </header>
  <main>
    <section id="slip-details">
      <h1>OPEN REGISTER REPORT</h1>
      <ul>
        <li>
          <span class="slip-field-label">Printed:</span>
          <span class="slip-field-contents">{{ _printedDate | dateTimeFormat : printedDate.toTimeString() }}</span>
        </li>
        <li *ngIf="cashout.openingDate">
          <span class="slip-field-label">Opening Date:</span>
          <span class="slip-field-contents">{{ cashout.openingDate | dateTimeFormat : cashout.openingTime }}</span>
        </li>
        <li *ngIf="openingUser">
          <span class="slip-field-label">Opening User:</span>
          <span class="slip-field-contents">{{ openingUser.person.fullName | uppercase }}</span>
        </li>
        <li>
          <span class="slip-field-label">Cashout ID:</span>
          <span class="slip-field-contents">{{ cashout.id }}</span>
        </li>
        <li>
          <span class="slip-field-label">Station ID:</span>
          <span class="slip-field-contents">{{ cashout.station.stationNumber }}</span>
        </li>
      </ul>
    </section>

    <table id="slip-documents">
      <tbody>
        <tr class="category-header">
          <th class="col-description">
            <div class="inner-wrapper"> DEPOSITS </div>
          </th>
          <th class="col-system">
            <div class="inner-wrapper"><ng-template [ngIf]="showSystemValues">SYSTEM</ng-template></div>
          </th>
          <th class="col-total">
            <div class="inner-wrapper"> ACTUAL {{ currencyIsoCode | currencySymbol }} </div>
          </th>
        </tr>

        <!-- <tr *ngFor="let deposit of deposits">
          <td class="col-description">{{deposit.tenderDescription}}</td>
          <td class="col-system"><ng-template [ngIf]="showSystemValues">{{deposit.systemTotal || 0 |number:_digitsFormat}}</ng-template></td>
          <td class="col-total">{{deposit.actualAmount || 0 |number:_digitsFormat}}</td>
        </tr> -->
        <tr>
          <td class="col-description">Cash</td>
          <td class="col-system"
            ><ng-template [ngIf]="showSystemValues">{{
              openingExpectedCash || 0 | number : _digitsFormat
            }}</ng-template></td
          >
          <td class="col-total">{{ openingActualAmount || 0 | number : _digitsFormat }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr class="total-row">
          <td class="col-description"></td>
          <td class="col-system"
            ><ng-template [ngIf]="showSystemValues">{{
              openingExpectedCash | currency : currencyIsoCode : "symbol-narrow" : _digitsFormat
            }}</ng-template></td
          >
          <td class="col-total">{{
            openingActualAmount | currency : currencyIsoCode : "symbol-narrow" : _digitsFormat
          }}</td>
        </tr>
        <tr *ngIf="cashout.openingNote">
          <td colspan="3">
            <div class="notes" style="text-align: left"> Notes: {{ cashout.openingNote }} </div>
          </td>
        </tr>
        <tr *ngIf="showSignatureLine">
          <td class="signature" colspan="4">
            <!-- <hr> -->
            <div class="text">Signature</div>
          </td>
        </tr>
      </tfoot>
    </table>
  </main>
</div>
