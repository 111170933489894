import { Injectable, inject } from "@angular/core";
import { switchMap } from "rxjs/operators";
import { DBService } from "src/app/shared/services/db.service";
import { environment } from "src/environments/environment";
import { SaleDoc } from "./sale-document/sale-doc/sale-doc";
import { ConfirmationService } from "primeng/api";
import { Observable, of } from "rxjs";
import { Router } from "@angular/router";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import {
  StoreSettingCashout,
  TillCashoutClosingType,
} from "../settings/store-settings/cashout-settings/CashoutSettings";
import { WebHelpers } from "src/app/utility/WebHelpers";
import { Doc } from "./doc/doc";
import moment from "moment";
import { Location } from "@angular/common";

type UnfinalizedSaleDoc = Pick<SaleDoc, "id" | "stationId" | "updatedAt"> & {
  doc: Pick<Doc, "id" | "docNo" | "state" | "docType">;
  tenderTotal: string | number;
  tenderCount: number;
};

@Injectable({
  providedIn: "root",
})
export class UnfinalizedSalesService {
  private dbService = inject(DBService);
  private confirmationService = inject(ConfirmationService);
  private router = inject(Router);
  private appSettingsService = inject(AppSettingsStorageService);
  private location = inject(Location);

  private getSaleDetails(saleDoc: UnfinalizedSaleDoc) {
    const date = moment(saleDoc.updatedAt).tz(this.appSettingsService.getStore().storeTimeZone).format("LLL");
    return `<br/><br/>Date: ${date}`;
  }

  private getUnfinalizedSalesDocs(storeId: number, stationId?: number) {
    let url = `${environment.apiUrl}/saleDocs/unfinalizedTenders/${storeId}`;
    if (stationId) {
      url = `${url}/${stationId}`;
    }
    return this.dbService._getRequest<UnfinalizedSaleDoc[]>(url);
  }

  promptForUnfinalizedSales(
    storeId = this.appSettingsService.getStoreId(),
    stationId = this.appSettingsService.getStationId()
  ): Observable<boolean> {
    if (history.state?.skipUnfinalizedSalesCheck) {
      // This is to avoid re-triggering the warning when we are loading a saleDoc from the warning dialog
      return of(false);
    }
    return this.getUnfinalizedSalesDocs(storeId, stationId).pipe(
      switchMap((saleDocs) => {
        if (!saleDocs.length) {
          return of(false);
        }
        const saleDoc = saleDocs[0];
        const details = this.getSaleDetails(saleDoc);

        return WebHelpers.waitForConfirmationDialogRemoval(this.confirmationService).pipe(
          switchMap(
            () =>
              new Observable<boolean>((observer) => {
                observer.add(() => this.confirmationService.close());

                this.confirmationService.confirm({
                  header: "Warning",
                  message:
                    `Payments have been applied to <strong>${saleDocs.length} unfinished</strong> transaction(s) from this station. ` +
                    `You will need to finish any such transactions or reverse the payments.<br/><br/>Do you want to reload the latest one?${details}`,
                  accept: () => {
                    observer.next(true);
                    observer.complete();
                    void this.router.navigate(["/sell/saleDoc", saleDoc.id], {
                      state: { skipUnfinalizedSalesCheck: true },
                    });
                  },
                  rejectButtonStyleClass: "p-button-link",
                  reject: () => {
                    observer.next(false);
                    observer.complete();
                  },
                });
              })
          )
        );
      })
    );
  }

  promptForCashingOutWithUnfinalizedSales(
    storeId = this.appSettingsService.getStoreId(),
    stationId = this.appSettingsService.getStationId()
  ): Observable<boolean> {
    let _storeSettingCashout: StoreSettingCashout;
    return this.appSettingsService.getStoreSettings<StoreSettingCashout>("storeSettingCashout").pipe(
      switchMap((storeSettingCashout) => {
        _storeSettingCashout = storeSettingCashout;
        return this.getUnfinalizedSalesDocs(
          storeId,
          storeSettingCashout.tillCashoutClosingType === TillCashoutClosingType.by_stations ? stationId : undefined
        );
      }),
      switchMap((saleDocs) => {
        if (!saleDocs.length) {
          return of(false);
        }
        const saleDoc = saleDocs[0];
        const details = this.getSaleDetails(saleDoc);

        const place =
          _storeSettingCashout.tillCashoutClosingType === TillCashoutClosingType.by_stations ? "station" : "store";
        const message =
          `Cashout cannot be closed as there are <strong>${saleDocs.length} unfinished</strong> transaction(s) with payments applied from this ${place}. ` +
          `Please finish the transaction(s) or reverse any applied payments.${details}`;

        return WebHelpers.waitForConfirmationDialogRemoval(this.confirmationService).pipe(
          switchMap(
            () =>
              new Observable<boolean>((observer) => {
                observer.add(() => this.confirmationService.close());

                this.confirmationService.confirm({
                  header: "Warning",
                  message,
                  icon: "pi pi-exclamation-triangle",
                  acceptIcon: null,
                  acceptLabel: "Reload latest transaction",
                  accept: () => {
                    observer.next(true);
                    observer.complete();
                    void this.router.navigate(["/sell/saleDoc", saleDoc.id], {
                      state: { skipUnfinalizedSalesCheck: true },
                    });
                  },
                  rejectIcon: "pi pi-arrow-left",
                  rejectLabel: "Back",
                  rejectButtonStyleClass: "p-button-link",
                  reject: () => {
                    observer.next(false);
                    observer.complete();
                    this.location.back();
                  },
                });
              })
          )
        );
      })
    );
  }
}
