/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, HostBinding, Input, Output, EventEmitter } from "@angular/core";
import { TakuInputCtrlBase } from "../TakuInputCtrlBase";

@Component({
  selector: "taku-input-number",
  templateUrl: "./taku-input-number.component.html",
  styleUrls: ["./taku-input-number.component.scss"],
})
export class TakuInputNumberComponent extends TakuInputCtrlBase {
  @Input() min = 0;
  @Input() max = 100;
  @HostBinding("class.read-only")
  @Input()
  tabIndex?: number = null;
  // By default component doesn't validate input
  @Input() keyFilter: string | RegExp = /.*/g;

  @Output() onBlur = new EventEmitter();

  clear() {
    this.ctrlField.setValue("");
    this.ctrlField.markAsDirty();
  }

  focusAndSelectInput() {
    const inputField = this.el.nativeElement.querySelector("p-inputNumber");
    inputField.focus();
    inputField.select();
  }

  ngOnChanges() {
    // this.formGroup.setControl(this.fieldName, new FormControl(this.ctrlField.value, {
    //   validators: this.ctrlField.validator, updateOn: 'blur'
    // }));
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
