/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { LocalDataService } from "src/app/shared/services/local-data.service";
import { InventoryValueReport } from "./inventory-value-report";
import * as _ from "lodash";
import { tap } from "rxjs/operators";
import { Observable, of } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class InventoryValueReportLocalDataService extends LocalDataService {
  // constructor(private appSettingsService: AppSettingsStorageService,
  //   @Inject(LOCALE_ID) private localeId: string) {
  //   super();
  // }

  queryParams = null;

  afterFetch(object, model: string) {
    const inventoryValueReport = Object.assign(new InventoryValueReport(), object);
    // This makes store getter result into plain property
    object.marginPercentCalculated = inventoryValueReport.marginPercent;
    object.marginAmountCalculated = inventoryValueReport.marginAmount;
    object.totalSoldPriceCalculated = inventoryValueReport.totalSoldPrice;
    object.totalCostCalculated = inventoryValueReport.totalCost;

    // const currency = this.appSettingsService.getZone().defaultCurrencyIsoCode;
    // const currencyFormatter = new CurrencyPipe(this.localeId);

    // Object.keys(object)
    //   .filter(key => !Object.keys(new InventoryValueReport()).includes(key))
    //   .forEach(key => {
    //     object[key] = currencyFormatter.transform(object[key], currency, 'symbol-narrow');
    //   });

    // object.totalAmountCalculated = currencyFormatter.transform(object.totalAmountCalculated, currency, 'symbol-narrow');
  }

  getRows(
    _model: string,
    _filter?: string,
    _offset?: number,
    _limit?: number,
    _sortField?: string,
    _sortOrder?: number,
    extraParams = {},
    _orFilter?
  ): Observable<any> {
    const params = this._buildRequestParams(_filter, _orFilter, _offset, _limit, _sortField, _sortOrder);
    // Add extra params if not present in regular params
    _.defaults(params, extraParams);
    _.defaults(params, this.queryParams);
    if (this.queryParams) {
      return this._getRequest<any>(this.webUrl + "repInventoryValue/", params).pipe(
        tap((response: any) => {
          if (response.rows && response.rows.length) response.rows.forEach((object) => this.afterFetch(object, _model));
        })
      );
    } else {
      return of({
        rows: [],
        count: 0,
      });
    }
  }

  // getRows(_model: string, _filter?: any, _offset: number = 0, _limit?: number, _sortField?: string,
  //   _sortOrder?: number, _extraParams?: any): Observable<any> {
  //   if (_filter && typeof _filter.valueOf() === 'string') _filter = JSON.parse(_filter);
  //   let filteredRows = _.cloneDeep(this.getRawModelData(_model));
  //   _limit = _limit || filteredRows.length;
  //   const startOffset = _offset;
  //   const endOffset = _offset + _limit;
  //   // Add virtual fields to each object
  //   filteredRows.forEach(object => Object.assign(object, this.extractVirtualFields(object, _model)));

  //   if (_filter)
  //     _.forOwn(_filter, (filter, field) => {
  //       const fieldValue = (filter.value + '').toLowerCase();
  //       if (filter.matchMode === 'equals')
  //         filteredRows = filteredRows.filter(object => (_.get(object, field) + '').toLowerCase() === fieldValue);
  //       else if (filter.matchMode === 'contains')
  //         filteredRows = filteredRows.filter(object => (_.get(object, field) + '').toLowerCase().includes(fieldValue));
  //     })
  //   if (_sortField)
  //     filteredRows.sort((row1, row2) => {
  //       const getFieldLabel = (rowData, field) => {
  //         return _.get(rowData, field);
  //       };

  //       const row1Val = getFieldLabel(row1, _sortField);
  //       const row2Val = getFieldLabel(row2, _sortField);

  //       if (row1Val > row2Val)
  //         return _sortOrder;
  //       else if (row1Val < row2Val)
  //         return -1 * _sortOrder;
  //       else
  //         return 0;
  //     });

  //   const countTotal = filteredRows.length;
  //   // Extract the data keys of found rows and then return raw/original data to remove new virtual fields and avoid potential errors
  //   const filteredDataKeys = filteredRows.slice(startOffset, endOffset).map(object => object[this.dataKey]);
  //   // filteredRows = this.getRawModelData(_model).filter(object => filteredDataKeys.includes(object[this.dataKey]));
  //   filteredRows = filteredDataKeys.map(key => this.getRawModelData(_model).find(object => object[this.dataKey] === key))

  //   return of({
  //     count: countTotal,
  //     rows: _.cloneDeep(filteredRows)
  //   }).pipe(
  //     tap((response:any) => {
  //       response.rows.forEach((object: any) => this.afterFetch(object, _model))
  //     })
  //   );
  // }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
