/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from "@angular/core";
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  UntypedFormArray,
} from "@angular/forms";
import * as _ from "lodash";
import { ConfirmationService, SelectItem } from "primeng/api";
import { DBService } from "../../../../shared/services/db.service";
import { CommonValidators } from "../../../../shared/validators/CommonValidators";
import { InvoiceValidators } from "../../../../shared/validators/InvoiceValidators";
import { StoreSettingReturnReason } from "../../../settings/store-settings/return-reason/StoreSettingReturnReason";
import { GenericSaleDocLineComponent } from "../../sale-document/generic-sale-doc-line.component";
import { SaleDocLine, SaleDocLineReturn } from "../../sale-document/sale-doc-line/sale-doc-line";
import { SaleDocService } from "../../sale-document/sale-doc/sale-doc.service";
import { InvoiceKeyboardActionsService } from "../../sale-document/sale-invoice-doc-base/invoice-keyboard-actions.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { SaleDocLineTax } from "../../sale-document/sale-doc-line-tax/sale-doc-line-tax";
import { CurrencyFormatterService } from "src/app/shared/services/currency-formatter.service";
import { AppComponent } from "src/app/app.component";
import { TakuInputComponent } from "src/app/taku-ui/taku-input/taku-input.component";
import { SaleDoc } from "../../sale-document/sale-doc/sale-doc";

@Component({
  selector: "taku-return-sale-doc-line",
  templateUrl: "./return-sale-doc-line.component.html",
  styleUrls: ["./return-sale-doc-line.component.scss"],
})
export class ReturnSaleDocLineComponent extends GenericSaleDocLineComponent implements OnInit, OnChanges {
  @ViewChild("qtyInput", { static: true }) _qtyComponent: TakuInputComponent;
  // @ViewChild('lineDiscountAmount') discAmountComponent: TakuInputComponent;
  @Input() set saleDoc(_saleDoc: SaleDoc) {
    this._saleDoc = _saleDoc;
  }

  get saleDoc() {
    return this._saleDoc;
  }

  isInventoryDetailsVisible = false;
  isInventoryDetailsEnabled = false;
  confirmationDialogMsg = "Assigning a zero or negative quantity will remove the line. Are you sure?";

  @Input() returnReasons: StoreSettingReturnReason[] = [];
  @Output() viewProductDetails = new EventEmitter<number>();

  _returnReasonsEnumOptions: SelectItem[] = [];

  constructor(
    public app: AppComponent,
    public dbService: DBService,
    public fb: UntypedFormBuilder,
    protected elementRef: ElementRef,
    saleDocService: SaleDocService,
    confirmationService: ConfirmationService,
    keyboardActionsService: InvoiceKeyboardActionsService,
    private authService: AuthService,
    protected currencyFormatterService: CurrencyFormatterService
  ) {
    super(
      app,
      dbService,
      fb,
      elementRef,
      saleDocService,
      confirmationService,
      keyboardActionsService,
      currencyFormatterService
    );

    this.isInventoryDetailsVisible = authService.hasVisiblePermit("Inventory_Menu_Inventory_List");
    this.isInventoryDetailsEnabled = authService.hasEnablePermit("Inventory_Menu_Inventory_List");
  }

  onNumpadItemQty(qty: number): void {
    this._myForm.get("qty").setValue(qty);
  }

  override _deleteOnInvalidQty(newQty: string | number, msg?: string): void {
    super._deleteOnInvalidQty(newQty, this.confirmationDialogMsg);
  }

  onNumpadDiscountAmount(amount) {
    this._myForm.get("discountAmount").setValue(amount);
  }

  showItemDetailsDlg(e) {
    e.stopPropagation();

    const inventoryId = this._myForm.get("docLine.inventoryId").value;
    if (!inventoryId) {
      // TODO: Change way to log or present error
      alert("Error: Inventory not found for sale line");
      return;
    }

    this.viewProductDetails.emit(inventoryId);
  }

  onQtyClicked($event) {
    //this._focusAndSelectTakuInput($event.target);
  }

  onDiscAmountClicked($event) {
    this.discAmountComponent.focusAndSelectInput();
  }

  static setTaxesArray(fb: UntypedFormBuilder, saleLineTaxes: SaleDocLineTax[]): UntypedFormArray {
    return fb.array(saleLineTaxes.map((taxLine) => fb.group(taxLine)));
  }

  static set(fb: UntypedFormBuilder, saleLine: SaleDocLine, maxQty: number) {
    const tmpForm = fb.group(saleLine);
    // Fillout derived form fields when data comes from server, using incorporated inventory object in response
    if (saleLine.id !== 0 && !saleLine.docLine.refNo)
      // Set reference number to keep track of which sale line was returned
      _.extend(saleLine.docLine, {
        refNo: saleLine.docLine.id,
      });
    tmpForm.setControl("docLine", fb.group(saleLine.docLine));
    tmpForm.setControl("saleDocLineReturn", fb.group(saleLine.saleDocLineReturn || new SaleDocLineReturn()));
    tmpForm.setControl("saleDocLineTaxes", ReturnSaleDocLineComponent.setTaxesArray(fb, saleLine.saleDocLineTaxes));
    ReturnSaleDocLineComponent.filloutDerivedFormFields(tmpForm, saleLine, maxQty);
    return tmpForm;
  }

  static setArray(fb: UntypedFormBuilder, salesLines: SaleDocLine[]) {
    const formArray = fb.array([]);
    salesLines.map((saleLine) => {
      const temp = ReturnSaleDocLineComponent.set(fb, saleLine, saleLine.qty);
      formArray.push(temp);
    });
    return formArray;
  }

  static filloutDerivedFormFields(saleLineForm: UntypedFormGroup, saleLine: SaleDocLine, maxQty: number) {
    saleLine = _.merge(new SaleDocLine(), saleLine);
    // Extra fields for data presentation
    const derivedFields = {
      salePrice: saleLine.salePrice,
      description: saleLine.docLine.inventory.description1,
      sku: saleLine.docLine.inventory.sku,
      categories: saleLine.docLine.inventory.categories.map((cat) => cat.categoryName).join(", "),
      maxQty: maxQty,
      "docLine.refNo": saleLine.docLine.refNo || saleLine.docLine.id,
    };
    // attach fields to current form
    for (const key in derivedFields) {
      let formControl: AbstractControl;
      const value = derivedFields[key];
      if ((formControl = saleLineForm.get(key))) formControl.setValue(value);
      else saleLineForm.addControl(key, new UntypedFormControl(value));
    }
  }

  _focusAndSelectTakuInput(fieldInput: HTMLInputElement) {
    fieldInput.focus();
    fieldInput.select();
  }

  onPriceClick() {
    this.selected.emit();
    // document.getElementById('salePriceInput').focus();
  }

  ///////////////////////////////////////////// Buttons //////////////////////////////////////////////////////////////////////
  isQtyValid(newQty: number): boolean {
    return !isNaN(newQty) && (this.maxQtyCtrl.value == null || newQty <= this.maxQtyCtrl.value);
  }

  // This set the actual quantity in the form
  _setActualQty(newQty) {
    if (!this.isQtyValid(newQty)) return;

    // if ( Math.sign(newQty) !== Math.sign(this.qtyCtrl.value) ) {
    if (newQty <= 0) {
      this._deleteOnInvalidQty(newQty, this.confirmationDialogMsg);
    } else
      this._myForm.patchValue({
        qty: newQty,
      });
  }

  initValidation() {
    // Add validator to show error message when setting qtys to values greater that max
    this._validation = {
      discountAmount: InvoiceValidators.discountAmount(this.discountTypeCtrl, this.unitPriceCtrl, "unit price"),
      qty: [CommonValidators.digits()],
      //Validators.max(this.maxQtyCtrl.value)]
    };
    if (this.maxQtyCtrl.value) {
      this._validation["qty"].push(InvoiceValidators.returnMaxQty(this.maxQtyCtrl.value));
    }
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
