import { Account } from "../../contact-accounts/account/account";
import {
  InventorySupplierProductDetail,
  PackSize,
} from "../inventory-supplier-product-detail/inventory-supplier-product-detail";

export class InventorySupplier {
  id: number;
  accountId: number;
  account: Account;
  inventorySupplierProductDetails: InventorySupplierProductDetail[];

  constructor(defaultCurrencyIsoCode?: string) {
    this.id = 0;
    this.accountId = null;
    this.account = null; // It's not coming from the server yet, so we're commenting it out until we fix server side
    this.inventorySupplierProductDetails = [
      new InventorySupplierProductDetail(0, this.id, defaultCurrencyIsoCode || null, PackSize.SKU),
      new InventorySupplierProductDetail(0, this.id, defaultCurrencyIsoCode || null, PackSize.INNER),
      new InventorySupplierProductDetail(0, this.id, defaultCurrencyIsoCode || null, PackSize.OUTER),
    ];
  }
}
