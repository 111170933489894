/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

export class AccountCreditCard {
  id: number;
  cardHolderName: string;
  cardType: string;
  last4Digits: string;
  expiryDate: string;
  token: string;
  status: AccountCreditCardStatus;
  isDefault: boolean;
  accountId: number;
  postalCode: string;

  constructor() {
    this.id = 0;
    this.cardHolderName = "";
    this.cardType = "";
    this.last4Digits = "";
    this.expiryDate = "";
    this.token = "";
    this.status = null;
    this.isDefault = false;
    this.accountId = 0;
    this.postalCode = "";
  }
}

export enum AccountCreditCardStatus {
  ACTIVE = "Active",
  DECLINED = "Declined",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
