/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, OnDestroy } from "@angular/core";
import { DynamicDialogRef, DynamicDialogConfig } from "primeng/dynamicdialog";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { BehaviorSubject, Subscription } from "rxjs";
import { DBService } from "src/app/shared/services/db.service";
import { map } from "rxjs/operators";

@Component({
  selector: "taku-google-shopping-push-dialog",
  templateUrl: "./google-shopping-push-dialog.component.html",
  styleUrls: ["./google-shopping-push-dialog.component.scss"],
})
export class GoogleShoppingPushAllDialogComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];

  pushForm: UntypedFormGroup;
  countEnabled$ = new BehaviorSubject<string | number>("-");
  countDisabled$ = new BehaviorSubject<string | number>("-");

  constructor(
    private fb: UntypedFormBuilder,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private dbService: DBService
  ) {}

  ngOnInit() {
    this.pushForm = this.fb.group({
      includeDisabled: false,
    });

    // Fetch count of enable GMC Inventory
    this.subsList.push(
      this.dbService
        .getRows(
          "inventory",
          JSON.stringify({
            isEnabled: { matchMode: "equals", value: true },
            isGMCEnabled: { matchMode: "equals", value: true },
          }),
          0,
          1
        )
        .pipe(map((result: any) => result.count))
        .subscribe({
          next: (count) => this.countEnabled$.next(count),
        })
    );

    // Fetch count of disabled GMC Inventory
    this.subsList.push(
      this.dbService
        .getRows(
          "inventory",
          JSON.stringify({
            isEnabled: { matchMode: "equals", value: true },
            isGMCEnabled: { matchMode: "equals", value: false },
          }),
          0,
          1
        )
        .pipe(map((result: any) => result.count))
        .subscribe({
          next: (count) => this.countDisabled$.next(count),
        })
    );
  }

  onActivateGMC() {
    const pushOptions = this.pushForm.value;
    this.ref.close({ pushOptions, actionInitiated: true });
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
