/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as _ from "lodash";
import { SelectItem } from "primeng/api/selectitem";
import { Observable, of, isObservable, merge } from "rxjs";
import { map, tap, concatMap, defaultIfEmpty, take } from "rxjs/operators";
import { Col, FilterType, DataType, NestedCol } from "src/app/form-list/form-list/form-list";
import { environment } from "../../../environments/environment";
import { AuthService } from "./auth.service";
import { DataServiceInterface } from "./DataService.interface";
import { LabelValuePair } from "src/app/utility/types";

export type BatchResponse<T> = {
  batchId: number;
  response: {
    code: number;
    result: T;
  };
};

@Injectable({
  providedIn: "root",
})
export class DBService implements DataServiceInterface {
  webUrl = environment.apiUrl;

  dataKey = "id";
  constructor(protected http: HttpClient, protected authService: AuthService) {
    this.authService.logoutSource$.subscribe(() => this.cleanUpAfterLogout && this.cleanUpAfterLogout());
    this.authService.loginSource$.subscribe(() => this.cacheAfterLogin && this.cacheAfterLogin());
  }

  protected cacheAfterLogin?(): void;
  protected cleanUpAfterLogout?(): void;

  getRows<T = any>(
    _model: string,
    _filter?: string,
    _offset?: number,
    _limit?: number,
    _sortField?: string,
    _sortOrder?: number,
    extraParams = {},
    _orFilter?
  ): Observable<T> {
    const params = this._buildRequestParams(_filter, _orFilter, _offset, _limit, _sortField, _sortOrder);
    // Add extra params if not present in regular params
    _.defaults(params, extraParams);

    return this._getRequest<T>(this.webUrl + "model-list/" + _model, params).pipe(
      tap((response: any) => {
        if (response.rows && response.rows.length) response.rows.forEach((object) => this.afterFetch(object, _model));
      })
    );
  }

  protected _buildRequestParams(
    _filter?: string,
    _orFilter?,
    _offset?: number,
    _limit?: number,
    _sortField?: string,
    _sortOrder?: number
  ): {} {
    const Params = {};
    if (_filter != null && _filter !== "" && _filter !== "{}") {
      Params["filter"] = _filter;
    }
    if (_orFilter != null && _orFilter !== "" && _orFilter !== "{}") {
      Params["orFilter"] = _.toString(JSON.stringify(_orFilter));
    }
    if (_limit != null) {
      Params["limit"] = _.toString(_limit);
    }
    if (_offset != null) {
      Params["offset"] = _.toString(_offset);
    }
    if (_sortField != null) {
      Params["sortField"] = _.toString(_sortField);
      if (_sortOrder != null) {
        Params["sortOrder"] = _.toString(_sortOrder);
      }
    }

    return Params;
  }

  /**
   *
   * @param endpoint
   * @param params dictionary of parameter names and values, by default they are sent in the URL as  query string
   * @param headerParamsMap dictionary of parameter name and Http Header name to be send in the request's headers
   */
  _getRequest<T>(endpoint: string, params = {}, headerParamsMap: { [paramName: string]: string } = {}) {
    const qsParams = _.omit(params, Object.keys(headerParamsMap));
    const headerParams = _.mapKeys(
      _.pick(params, Object.keys(headerParamsMap)),
      (value, key) => headerParamsMap[key] // Change keys to use the ones defined by the map/dictionary
    );

    // Authorization header is mandatory in order to authorize the request in server
    const httpHeaders = new HttpHeaders(
      Object.assign({}, { Authorization: this.authService.getToken() }, headerParams)
    );

    return this.http.get<T>(endpoint, {
      headers: httpHeaders,
      params: qsParams,
    });
  }

  protected beforeSave(object: any, model: string) {}

  protected afterSave(object: any, model: string) {}

  protected afterCreate(object: any, model: string) {}

  protected afterFetch(object: any, model: string) {}

  protected _getTextFile(endpoint: string, _params = {}) {
    return this.http.get(endpoint, {
      headers: new HttpHeaders().set("Authorization", this.authService.getToken()),
      params: _params,
      responseType: "text",
    });
  }

  /**
   *
   * @param endpoint
   * @param body the data to pass as the request's body
   * @param params the params as query string
   * @param bodyParamsMap dictionary of original parameter name and body field's name to append to the body
   */
  _postRequest<T>(endpoint: string, body, params = {}, bodyParamsMap?) {
    const qsParams = _.omit(params, Object.keys(bodyParamsMap || {}));
    const bodyParams = bodyParamsMap
      ? _.mapKeys(_.pick(params, Object.keys(bodyParamsMap)), (value, key) => bodyParamsMap[key])
      : null;

    return this.http.post<T>(endpoint, Object.assign({}, body, bodyParams), {
      headers: new HttpHeaders().set("Authorization", this.authService.getToken()),
      params: qsParams,
    });
  }

  _putRequest<T>(endpoint: string, body, params = {}, bodyParamsMap?) {
    const qsParams = _.omit(params, Object.keys(bodyParamsMap || {}));
    const bodyParams = bodyParamsMap
      ? _.mapKeys(_.pick(params, Object.keys(bodyParamsMap)), (value, key) => bodyParamsMap[key])
      : null;

    return this.http.put<T>(endpoint, Object.assign({}, body, bodyParams), {
      headers: new HttpHeaders().set("Authorization", this.authService.getToken()),
      params: qsParams,
    });
  }

  _deleteRequest<T>(endpoint: string, body, params = {}, bodyParamsMap?) {
    const qsParams = _.omit(params, Object.keys(bodyParamsMap || {}));
    return this.http.delete<T>(endpoint, {
      headers: new HttpHeaders().set("Authorization", this.authService.getToken()),
      params: qsParams,
    });
  }

  protected _patchRequest<T>(endpoint, body, _params = {}) {
    return this.http.patch<T>(endpoint, body, {
      headers: new HttpHeaders().set("Authorization", this.authService.getToken()),
      params: _params,
    });
  }

  getRow<T = any>(_model: string, id: number, queryParams = {}): Observable<T> {
    return this.http
      .get<T>(this.webUrl + "model/" + _model + "/" + id, {
        headers: new HttpHeaders().set("Authorization", this.authService.getToken()),
        params: queryParams,
      })
      .pipe(tap((object) => this.afterFetch(object, _model)));
  }

  batchAddRows<T = any>(_model: string, _objects: T[], queryParams = {}): Observable<T[]> {
    _objects.map((_object) => {
      this.beforeSave(_object, _model);
    });
    const _entries = [];
    _objects.map((_object) => {
      _entries.push({
        batchId: _object["batchId"] || 0,
        body: _object,
      });
      delete _object["batchId"];
    });

    return this.http
      .post<T[]>(
        this.webUrl + "batch/" + _model + "/post",
        {
          entries: _entries,
        },
        {
          headers: new HttpHeaders().set("Authorization", this.authService.getToken()),
          params: queryParams,
        }
      )
      .pipe(
        tap((results: any[]) =>
          results.map((_result) => {
            if (_result["response"].result.success) this.afterCreate(_result["response"].result.body, _model);
          })
        ),
        tap((results: any[]) =>
          results.map((_result) => {
            if (_result["response"].result.success) this.afterSave(_result["response"].result.body, _model);
          })
        ),
        tap((results: any[]) =>
          results.map((_result) => {
            if (_result["response"].result.success) this.afterFetch(_result["response"].result.body, _model);
          })
        )
      );
  }

  addRow<T = any>(_model: string, _object: T): Observable<T> {
    this.beforeSave(_object, _model);

    return this.http
      .post<T>(this.webUrl + "model/" + _model, _object, {
        headers: new HttpHeaders().set("Authorization", this.authService.getToken()),
      })
      .pipe(
        tap((object) => this.afterCreate(object, _model)),
        tap((object) => this.afterSave(object, _model)),
        tap((object) => this.afterFetch(object, _model))
      );
  }

  addRowTmp(_model: string, _object: any): Observable<any> {
    return of(_object);
  }

  batchDeleteRows<T = any>(_model: string, ids: number[]): Observable<T[]> {
    const _entries = [];
    ids.map((_id) => {
      _entries.push({
        batchId: _id,
        id: _id,
      });
    });

    return this.http.post<T[]>(
      this.webUrl + "batch/" + _model + "/delete",
      {
        entries: _entries,
      },
      {
        headers: new HttpHeaders().set("Authorization", this.authService.getToken()),
      }
    );
  }

  deleteRow(_model: string, id: number): Observable<any> {
    return this.http.delete(this.webUrl + "model/" + _model + "/" + id, {
      headers: new HttpHeaders().set("Authorization", this.authService.getToken()),
    });
  }

  batchEditRows<T = any>(_model: string, _objects: T[], queryParams = {}): Observable<BatchResponse<T>[]> {
    _objects.map((_object) => {
      this.beforeSave(_object, _model);
    });
    const _entries = [];
    _objects.map((_object) => {
      _entries.push({
        batchId: _object["batchId"] || 0,
        id: _object["id"],
        body: _object,
      });
      delete _object["batchId"];
    });

    return this.http
      .post<BatchResponse<T>[]>(
        this.webUrl + "batch/" + _model + "/put",
        {
          entries: _entries,
        },
        {
          headers: new HttpHeaders().set("Authorization", this.authService.getToken()),
          params: queryParams,
        }
      )
      .pipe(
        tap((results: any) =>
          results.map((_result: any) => {
            if (_result["response"].result.success) this.afterSave(_result["response"].result.body, _model);
          })
        ),
        tap((results: any) =>
          results.map((_result: any) => {
            if (_result["response"].result.success) this.afterFetch(_result["response"].result.body, _model);
          })
        )
      );
  }

  editRow<T = any>(_model: string, _object: T): Observable<T> {
    this.beforeSave(_object, _model);

    return this.http
      .put<T>(this.webUrl + "model/" + _model + "/" + (_object as any).id, _object, {
        headers: new HttpHeaders().set("Authorization", this.authService.getToken()),
      })
      .pipe(
        tap((object) => this.afterSave(object, _model)),
        tap((object) => this.afterFetch(object, _model))
      );
  }

  patchRow<T = any>(_model: string, _object: T): Observable<T> {
    this.beforeSave(_object, _model);

    if (!_object["updatedAt"]) {
      console.warn(
        'Please include "updatedAt" field in patch request so backend can determine if there has been a more recent update and prevent dirty writes'
      );
    }

    return this.http
      .patch<T>(this.webUrl + "model/" + _model + "/" + (_object as any).id, _object, {
        headers: new HttpHeaders().set("Authorization", this.authService.getToken()),
      })
      .pipe(
        tap((object) => this.afterSave(object, _model)),
        tap((object) => this.afterFetch(object, _model))
      );
  }

  patchRows(_model: string, patchingFields, _filter: string) {
    const Params = {};
    Params["filter"] = _filter;

    return this._patchRequest(this.webUrl + "model-list/" + _model, patchingFields, Params);
  }

  enumSelectOptions(
    _enumup: any,
    _options: EnumOptions = { useKeyAsLabel: false, useNullAsValue: true, emptyRowCaption: "", emptyRowValue: null }
  ): SelectItem[] {
    let options: SelectItem[] = [];
    if (_options.useNullAsValue) {
      options = [{ label: _options.emptyRowCaption, value: _options.emptyRowValue }];
    }

    Object.keys(_enumup).forEach((key) => {
      let label, value;

      // Check the structure of the enum item
      if (
        typeof _enumup[key] === "object" &&
        _enumup[key] !== null &&
        "label" in _enumup[key] &&
        "value" in _enumup[key]
      ) {
        // New structure
        label = _enumup[key].label;
        value = _enumup[key].value;
      } else {
        // Original structure
        label = _options.useKeyAsLabel ? key : _enumup[key];
        value = _enumup[key];
      }

      options.push({ label: label, value: value });
    });

    return options;
  }

  enumMultiselectOptions(_enumup: any, useKeyAsLabel = false): SelectItem[] {
    const options: SelectItem[] = [];

    Object.keys(_enumup).forEach((key) => {
      options.push({ label: useKeyAsLabel ? key : _enumup[key], value: _enumup[key] });
    });

    return options;
  }

  getMappedEnum<T extends { [key: string]: any }>(
    enumeration: T,
    labels: { [key in keyof T]?: string } = {}
  ): { [key: string]: LabelValuePair | string } {
    return Object.keys(enumeration).reduce((acc, key) => {
      if (labels[key as keyof T]) {
        acc[key] = { label: labels[key as keyof T] || key, value: enumeration[key as keyof T] };
      } else {
        acc[key] = enumeration[key as keyof T];
      }
      return acc;
    }, {} as { [key: string]: LabelValuePair | string });
  }

  lookupSelectOptions<TValue = any, TObject = any>(
    _lookupModel: string,
    _lookupLabel: string,
    _options: LookupOptions = { dataKey: "id", emptyRowCaption: "", emptyRowValue: null }
  ): Observable<ExtendedSelectItem<TValue, TObject>[]> {
    if (_.get(_options, "dataKey") === undefined) {
      _options.dataKey = "id";
    }
    if (_.get(_options, "emptyRowCaption") === undefined) {
      _options.emptyRowCaption = "";
    }
    if (_.get(_options, "emptyRowValue") === undefined) {
      _options.emptyRowValue = null;
    }

    return this.getRows(_lookupModel, _.get(_options, "lookupFilter") || "", 0, -1).pipe(
      take(1),
      map((myObjects: any) => {
        const lookupOptions: ExtendedSelectItem<TValue, TObject>[] = [
          { label: _options.emptyRowCaption, value: _options.emptyRowValue },
        ];
        // const lookupOptions: SelectItem[] = [];
        myObjects.rows.forEach((myObject: any) => {
          lookupOptions.push({
            label: _.get(myObject, _lookupLabel),
            value: _.get(_options, "dataKey") ? _.get(myObject, _.get(_options, "dataKey")) : myObject,
            // value: _lookupOptions?._dataKey ? _.get(myObject, _lookupOptions._dataKey) : myObject,
            disabled: _.get(_options, "enableFieldName") ? !_.get(myObject, _.get(_options, "enableFieldName")) : false,
            icon:
              _.get(_options, "icon") !== undefined
                ? _.get(_options, "icon")
                : (_.get(_options, "enableFieldName") ? !_.get(myObject, _.get(_options, "enableFieldName")) : false)
                ? "pi pi-lock"
                : "",
            object: myObject,
          });
        });
        return lookupOptions;
      })
    );
  }

  lookupMultiSelectOptions<T = any>(
    _lookupModel,
    _lookupLabel: string,
    _options?: LookupOptions
  ): Observable<SelectItem<T>[]> {
    return this.getRows<T>(_lookupModel, _.get(_options, "lookupFilter") || "", 0, 500).pipe(
      map((myObjects: any) => {
        const lookupOptions: SelectItem[] = [];
        myObjects.rows.forEach((myObject: any) => {
          lookupOptions.push({
            label: myObject[_lookupLabel],
            value: myObject,
            disabled: _.get(_options, "enableFieldName") ? !_.get(myObject, _.get(_options, "enableFieldName")) : false,
            icon:
              _.get(_options, "icon") !== undefined
                ? _.get(_options, "icon")
                : (_.get(_options, "enableFieldName") ? !_.get(myObject, _.get(_options, "enableFieldName")) : false)
                ? "pi pi-lock"
                : "",
          });
        });
        return lookupOptions;
      })
    );
  }

  buildFilterParamsForCol(value, col: Col | NestedCol, overrideMatchMode?: string): [any, string, string] {
    if (_.isFunction(col.filterTransformer)) value = col.filterTransformer(value);

    let dataTypeMatchMode: string;
    switch (col.dataType) {
      case DataType.input:
      case DataType.array:
      case DataType.chips:
        dataTypeMatchMode = "contains";
        break;

      default:
        dataTypeMatchMode = "equals";
    }

    // With arrays by default we filter using 'in' but we can override it
    const filterMatchMode =
      overrideMatchMode || (Array.isArray(value) ? "in" : col.filterMatchMode || dataTypeMatchMode);
    // If the value is an array but the matchmode is not array compatible, take the first element from array
    if (Array.isArray(value) && value.length && !["in", "notIn"].includes(filterMatchMode)) value = value[0];
    // access to specific field specified by the dataKey
    if (Array.isArray(value)) value = value.map((item) => _.get(item, col.filterDataKey, item));
    else value = _.get(value, col.filterDataKey, value);

    // When we are using scalar value and we don't specify matchmode
    // in col definition, we fallback to default match mode for the datatype
    switch (col.filterType) {
      case FilterType.contains:
        return [value, col.filterField || col.field, filterMatchMode];

      case FilterType.enum:
        return [value, col.filterField || col.field, filterMatchMode];

      case FilterType.lookup:
        return [value, col.filterField || col.field, filterMatchMode];

      case FilterType.multiselect:
        return [value, col.filterField || col.field, filterMatchMode];

      case FilterType.enumMultiselect:
        return [value, col.filterField || col.field, filterMatchMode];

      case FilterType.checkbox:
        return [value, col.filterField || col.field, "equals"];
    }
  }

  createOrFilter(
    visibleCols: Col[] | NestedCol[],
    searchTerm: string,
    defaultMatchMode: string,
    parentField?
  ): Observable<any> {
    searchTerm = searchTerm.trim();
    const _globalFilters = {};
    const fnMatchDataOptions = (_searchTerm: string, dataOptions: SelectItem[]): any[] => {
      return Array.isArray(dataOptions)
        ? dataOptions.filter((option) => option.label && option.label.toLowerCase().includes(_searchTerm.toLowerCase()))
        : [];
    };
    const filterableDropdownTypes: FilterType[] = [
      FilterType.lookup,
      FilterType.multiselect,
      FilterType.enum,
      FilterType.enumMultiselect,
    ];
    const filterableInputTypes: FilterType[] = [FilterType.contains];

    const findMatchingTerms$ = (_searchTerm: string, col): Observable<any> => {
      // We are ignoring numeric columns when search value is not numeric
      if (col.dataType === DataType.number && Number.isNaN(parseFloat(_searchTerm))) return of(null);

      if (filterableDropdownTypes.includes(col.dataType)) {
        const colDataOptions = col.globalFilterDataOptions || col.dataOptions;
        const dataOptions$: Observable<SelectItem[]> = isObservable(colDataOptions)
          ? colDataOptions
          : of(colDataOptions);
        return dataOptions$.pipe(
          map((dataOptions: SelectItem[]) => fnMatchDataOptions(_searchTerm, dataOptions).map((item) => item.value))
        );
      }

      return of(searchTerm);
    };

    const matchedOptions$: Observable<[any, Col | NestedCol]>[] = [];
    const compundFilters$: Observable<[any, Col]>[] = [];
    return new Observable<any>((observer) => {
      visibleCols.forEach((col: Col | NestedCol) => {
        // Excluded dataypes are booleans (checkboxes, toggles) and dates
        if ([DataType.checkbox, DataType.toggle].includes(col.dataType)) return;

        if (col.enableGlobalFilter && col.filterType !== FilterType.none) {
          // Only process filters for text and number fields, for now
          if (filterableInputTypes.concat(filterableDropdownTypes).includes(col.filterType)) {
            matchedOptions$.push(
              findMatchingTerms$(searchTerm, col).pipe(
                take(1),
                map((matchedOption) => [matchedOption, col])
              )
            );
          } else if (col instanceof Col && col.filterType === FilterType.compound)
            compundFilters$.push(
              this.createOrFilter(col.children, searchTerm, defaultMatchMode, col.field).pipe(
                take(1),
                map((orFilters) => [orFilters, col])
              )
            );
        }
      });

      merge(...matchedOptions$)
        .pipe(
          defaultIfEmpty([null, null]),
          tap(([matches, col]) => {
            if (matches == null || (Array.isArray(matches) && !matches.length)) return;

            const [value, field, matchMode] = this.buildFilterParamsForCol(matches, col, col.globalFilterMatchMode);
            const fullField = [parentField, field].filter(Boolean).join(".");
            _globalFilters[fullField] = { value, matchMode: matchMode || defaultMatchMode };
          }),
          concatMap(() =>
            merge(...compundFilters$).pipe(
              defaultIfEmpty([null, null]),
              tap(([orFilters, col]) => {
                if (!orFilters) return;
                Object.assign(_globalFilters, orFilters);
              })
            )
          )
        )
        .subscribe({
          next: ([orFilters, col]) => {},
          complete: () => {
            observer.next(_globalFilters);
            observer.complete();
          },
          error: (error) => {
            console.warn("Error on searching term on lookup", error);
          },
        });
    });
  }
}

export type ExtendedSelectItem<TValue = any, TObject = any> = { object?: TObject } & SelectItem<TValue>;
export type ExtendedSelectItemGroup<TValue = any, TObject = any> = {
  label: string;
  value?: any;
  items: ExtendedSelectItem<TValue, TObject>[];
};

export interface LookupOptions {
  lookupFilter?: string;
  dataKey?: string;
  enableFieldName?: string;
  icon?: string;
  emptyRowCaption?: string;
  emptyRowValue?: any;
}

export interface EnumOptions {
  useKeyAsLabel?: boolean;
  useNullAsValue?: boolean;
  emptyRowCaption?: string;
  emptyRowValue?: any;
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
