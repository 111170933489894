/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from "lodash";
import { ConfirmationService, MenuItem, MessageService } from "primeng/api";
import { concat, forkJoin, merge, Observable, of, Subscription } from "rxjs";
import { catchError, concatAll, map, mergeMap, take, tap, toArray, zipAll } from "rxjs/operators";
import { Category } from "src/app/core/inventory/category/category";
import { UOfMeasure } from "src/app/core/inventory/u-of-measure/u-of-measure";
import { BackOffice } from "src/app/core/settings/backOffice-settings/backOffice/backOffice";
import { BusinessFormatsSettings } from "src/app/core/settings/business-settings/business-formats-settings/BusinessFormatsSettings";
import {
  TenderType,
  TenderTypeTransactionType,
  Tender_Type,
} from "src/app/core/settings/business-settings/tender-type/tender-type";
import { AccountGroup } from "src/app/core/settings/store-settings/account-group/account-group";
import { StoreSettingLetterReturnPrint } from "src/app/core/settings/store-settings/full-page-returnreceipt-builder/StoreSettingLetterReturnPrint";
import { StoreSettingLetterSalePrint } from "src/app/core/settings/store-settings/full-page-salereceipt-builder/StoreSettingLetterSalePrint";
import { StoreSettingGiftReceipt } from "src/app/core/settings/store-settings/gift-receipt-builder/StoreSettingGiftReceipt";
import { KioskSetting } from "src/app/core/settings/store-settings/kiosk-settings/kioskSettings";
import { StoreSettingReturnReason } from "src/app/core/settings/store-settings/return-reason/StoreSettingReturnReason";
import { ReturnSetting } from "src/app/core/settings/store-settings/return-settings/ReturnSettings";
import {
  StoreTenderType,
  StoreTenderTypeList,
} from "src/app/core/settings/store-settings/store-tender-type-settings/TenderTypesSettings";
import { StoreSettingTapeReturnPrint } from "src/app/core/settings/store-settings/tape-returnreceipt-builder/store-setting-tape-return-print";
import { StoreSettingTapeSalePrint } from "src/app/core/settings/store-settings/tape-salereceipt-builder/store-setting-tape-sale-print";
import {
  InventoryDocFormat,
  Inventory_Doc_Type,
} from "src/app/core/settings/zone-settings/inventory-doc-formats/InventoryDocFormat";
import { CashDenomination, Cash_Type } from "src/app/core/settings/zone-settings/zone-denominations/cash-denomination";
import { TaxAccountCategory } from "src/app/core/tax/tax-account-category/tax-account-category";
import { TaxInventoryCategory } from "src/app/core/tax/tax-inventory-category/tax-inventory-category";
import { TaxRule } from "src/app/core/tax/tax-rule/tax-rule";
import { Tax } from "src/app/core/tax/tax/tax";
import { Role } from "src/app/core/users/role/role";
import { User } from "src/app/core/users/user/user";
import { UserService } from "src/app/core/users/user/user.service";
import { RowSelectionType } from "src/app/form-list/form-list/form-list";
import { StepValidationMessages } from "src/app/forms/dialog-validation-messages/dialog-validation-messages.component";
import { GenericFormComponent } from "src/app/forms/generic-form/generic-form.component";
import { AppDefaultsService } from "src/app/shared/services/app-defaults.service";
import { Transaction_Type } from "src/app/shared/services/transaction-types";
import { DBCashoutService } from "../../core/cashout/db-cashout.service";
import { BusinessDetail } from "../../core/settings/business-settings/business-detail/business-detail";
import { BusinessDetailComponent } from "../../core/settings/business-settings/business-detail/business-detail.component";
import { FiscalYearsSettings } from "../../core/settings/business-settings/fiscal-years-settings/FiscalYearsSettings";
import { Stock } from "../../core/settings/business-settings/stock/stock";
import { SalesFormatsSettingsComponent } from "../../core/settings/store-settings/sales-formats-settings/sales-formats-settings.component";
import { Station } from "../../core/settings/store-settings/station/station";
import { TenderTypesSettingsComponent } from "../../core/settings/store-settings/store-tender-type-settings/tender-types-settings.component";
import { Store } from "../../core/settings/store-settings/store/store";
import { InventoryDocFormatsService } from "../../core/settings/zone-settings/inventory-doc-formats/InventoryDocFormats.service";
import { Zone } from "../../core/settings/zone-settings/Zone";
import { ZoneDenominationService } from "../../core/settings/zone-settings/zone-denominations/zone-denominations.service";
import { ZoneTenderTypesService } from "../../core/settings/zone-settings/zone-tender-types/zone-tender-types.service";
import { FormListComponent } from "../../form-list/form-list/form-list.component";
import { AppSettingsStorageService } from "../../shared/app-settings-storage.service";
import { AuthService } from "../../shared/services/auth.service";
import { DBService } from "../../shared/services/db.service";
import { LocationService } from "../../shared/services/location.service";
import { ValidationMessagesService } from "../../shared/services/validation-messages.service";
import { StepChangedEvent } from "../../taku-ui/taku-steps/taku-steps.component";
import { FormDataHelpers } from "../../utility/FormDataHelpers";
import { BusinessSetupResolvedData } from "./business-setup-resolver.service";
import { APP_BRAND } from "src/app/utility/white-label";
import { StoreSettingSaleDoc } from "src/app/core/settings/store-settings/sales-doc-settings/sales-doc-settings";

enum WizardSteps {
  BUSINESS_DETAILS = 0,
  DENOMINATIONS = 1,
  ZONE_TENDERS = 2,
  STORE_TENDERS = 3,
  SALE_DOC_FORMATS = 4,
  INVENTORY_FORMATS = 5,
  FINISH_WIZARD = 999,
}

@Component({
  selector: "taku-business-setup",
  templateUrl: "./business-setup.component.html",
  styleUrls: ["./business-setup.component.scss"],
})
export class BusinessSetupComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];
  WizardSteps = WizardSteps;
  RowSelectionType = RowSelectionType;

  reloadComponentsFlags: { [key: number]: boolean } = {};
  hideStepsFlags: { [key: number]: boolean } = {};
  validationMessages: StepValidationMessages[];
  private greaterVisitedStep: WizardSteps = WizardSteps.BUSINESS_DETAILS;

  // Zone Denominations
  denominationsFormlistTitle = "Monetary Denominations";
  zoneFilter;
  zoneDefaultRow;
  zoneDenominationsCols;

  // Zone Tender Types
  tenderTypesFormlistTitle = "Zone Tender Types";
  zoneTenderTypesCols;

  // Zone Inventory Doc Formats
  inventoryFormatsFormlistTitle = "Inventory Doc Formats";
  inventoryFormatsCols;

  wizardSteps: MenuItem[] = [
    { label: "Business Details" },
    { label: "Denominations" },
    { label: "Tender Types" },
    { label: "Store Tenders" },
    { label: "Sale Doc Formats" },
    { label: "Inventory Doc Formats" },
  ];
  activeStep = 0;
  currentBusiness: BusinessDetail;
  _currentUser: User;
  _currentZone: Zone;
  _currentStore: Store;
  _currentStation: Station;
  _currentStock: Stock;
  _currentFiscalYear: FiscalYearsSettings;
  // _backBtnEnabled = true;
  // _nextBtnEnabled = true;

  // Dialog vars
  showValidationDialog = false;
  dialogValidationMessages: StepValidationMessages[] = [];
  _isFetchingDenomination = true;

  // Nested forms
  @ViewChild(BusinessDetailComponent, { static: true }) businessDetailsComponent: BusinessDetailComponent;
  @ViewChild(TenderTypesSettingsComponent) storeTenderTypesComponent: TenderTypesSettingsComponent;
  @ViewChild(SalesFormatsSettingsComponent) saleDocFormatsComponent: SalesFormatsSettingsComponent;
  // form list components
  @ViewChild("denominationsFormList") denominationsFormListComponent: FormListComponent;
  @ViewChild("zoneTendersFormList") zoneTendersFormListComponent: FormListComponent;
  @ViewChild("inventoryFormatsFormList") inventoryFormatsFormListComponent: FormListComponent;
  defaultDenominations: { value: number; type: string }[];
  ignoreStepChange = false;
  branding = APP_BRAND;

  constructor(
    private dbService: DBService,
    private locationService: LocationService,
    private appSettingsService: AppSettingsStorageService,
    zoneDenominationService: ZoneDenominationService,
    private zoneTenderTypesService: ZoneTenderTypesService,
    private authService: AuthService,
    private cashoutService: DBCashoutService,
    inventoryDocFormats: InventoryDocFormatsService,
    private validationMessagesService: ValidationMessagesService,
    private userService: UserService,
    private router: Router,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private appDefaultsService: AppDefaultsService,
    private confirmationService: ConfirmationService
  ) {
    this.zoneDenominationsCols = zoneDenominationService.getFormListColumns();
    this.zoneTenderTypesCols = zoneTenderTypesService.getFormListColumns();
    this.inventoryFormatsCols = inventoryDocFormats.getFormListColumns();
  }

  ngOnInit() {
    // Access route's resolved data and assigned them to current component's state
    const defaultData = this.route.snapshot.data.defaults;
    if (defaultData instanceof BusinessSetupResolvedData) {
      this._currentStore = defaultData.store;
      this._currentZone = defaultData.zone;
      this._currentStock = defaultData.stock;
    }

    const newBusinessData = new BusinessDetail();
    this.subsList.push(
      this.dbService
        .getRow("businessDetail", 1)
        .pipe(catchError((error) => of(newBusinessData)))
        .subscribe((business) => {
          business = business || newBusinessData;
          this.currentBusiness = business;
        })
    );

    this.enableStepsBtns();
  }

  onFinishWizard() {
    if (this.hasPendingChanges(this.activeStep))
      this.showPendingChangesConfirmation({
        onAccept: () => {
          this.saveComponentData(this.activeStep);
          this.finalizeWizard();
        },
        onReject: null,
        isFinalAction: true,
      });
    else this.finalizeWizard();
  }

  private fetchUserData() {
    return this.userService.getLoggedinUser().pipe(
      tap((user: User) => {
        this._currentUser = user;
      })
    );
  }

  private finalizeWizard() {
    this.subsList.push(
      this.validateWizard().subscribe((wizardValidation) => {
        if (wizardValidation.length > 0) {
          // If wizard has any validation errors, show dialog and prevent from finishing it
          this.showValidationErrorsDialog(wizardValidation);
        } else {
          this.subsList.push(
            merge(
              this.saveStep(this.activeStep),
              this.fetchUserData(),
              this.saveFiscalYear(),
              this.createDefaultTaxes(),
              this.createDefaultUnitsOfMeasurement(),
              this.createBusinessFormats(),
              this.createDefaultInventoryCategories(),
              this.createDefaultAccountGroups(),
              this.createDefaultReturnReasons(),
              this.createDefaultUserRoles()
            ).subscribe({
              next: () => {
                this.closeAndFinishWizard();
              },
              error: (error) => {
                this.handleBackendErrors(error);
              },
            })
          );
        }
      })
    );
  }

  createDefaultUserRoles(): Observable<Role[]> {
    const userRoles = this.appDefaultsService.defaultUserRoles();

    return this.dbService.getRows("role").pipe(
      map((response: any) => response.rows || []),
      mergeMap((roles: Role[]) => {
        if (roles.length) return of(roles);
        else
          return merge(
            ...userRoles.map((seedData) => {
              const role = Object.assign({}, new Role(), seedData);
              return this.dbService.addRow<Role>("role", role);
            })
          ).pipe(toArray());
      })
    );
  }

  createDefaultReturnReasons(): Observable<StoreSettingReturnReason> {
    const settingsFilter = this._createStoreFilter(this._currentStore);

    return this.dbService.getRows("storeSettingReturn", JSON.stringify(settingsFilter), 0, 1).pipe(
      mergeMap((response: any) => {
        if (response.rows && response.rows.length) return of(response.rows[0]);
        else
          return this.dbService.addRow(
            "storeSettingReturn",
            Object.assign(new ReturnSetting(), { storeId: this._currentStore.id })
          );
      })
    );
  }

  private createBusinessFormats(): Observable<BusinessFormatsSettings> {
    return this.dbService.getRows("businessFormat", null, 0, 1).pipe(
      mergeMap((response: any) => {
        if (response.rows && response.rows.length) return of(response.rows[0]);
        else return this.dbService.addRow("businessFormat", new BusinessFormatsSettings());
      })
    );
  }

  private createDefaultTaxAccountCategories(): Observable<TaxAccountCategory[]> {
    const country = this._currentZone.countryIsoCode;
    const taxCategories: any[] = this.appDefaultsService.defaultTaxAccountCategories(country);

    return this.dbService.getRows("taxAccountCategory").pipe(
      mergeMap((response: any) => {
        if (response.rows && response.rows.length) return of(response.rows); // return current tax categories
        else
          return merge(
            taxCategories.map((seedData) => {
              const taxCat: TaxAccountCategory = Object.assign({}, new TaxAccountCategory(), seedData);
              return this.dbService.addRow("taxAccountCategory", taxCat);
            })
          ).pipe(
            zipAll(),
            tap((categories) => {})
          );
      })
    );
  }

  private createDefaultTaxInventoryCategories(): Observable<TaxInventoryCategory[]> {
    const country = this._currentZone.countryIsoCode;
    const taxCategories: any[] = this.appDefaultsService.defaultTaxInventoryCategories(country);

    return this.dbService.getRows("taxInventoryCategory").pipe(
      mergeMap((response: any) => {
        if (response.rows && response.rows.length) return of(response.rows); // return current tax categories
        else
          return merge(
            taxCategories.map((seedData) => {
              const taxCat: TaxInventoryCategory = Object.assign({}, new TaxInventoryCategory(), seedData);
              return this.dbService.addRow("taxInventoryCategory", taxCat);
            })
          ).pipe(
            zipAll(),
            tap((categories) => {})
          );
      })
    );
  }

  private createDefaultUnitsOfMeasurement(): Observable<UOfMeasure[]> {
    const defaultUOfM: any[] = this.appDefaultsService.defaultUnitsOfMeasurement();

    return this.dbService.getRows("uOfMeasure").pipe(
      map((response: any) => response.rows),
      mergeMap((dbUOfM: UOfMeasure[]) => {
        if (dbUOfM && dbUOfM.length) return of(dbUOfM);
        else
          return merge(
            defaultUOfM.map((seedData) => {
              const uOfM = Object.assign({}, new UOfMeasure(), seedData);
              return this.dbService.addRow<UOfMeasure>("uOfMeasure", uOfM);
            })
          ).pipe(
            zipAll(),
            tap((uOfM) => {})
          );
      })
    );
  }

  private createDefaultInventoryCategories(): Observable<Category[]> {
    const defaultInventoryCats: any[] = this.appDefaultsService.defaultInventoryCats();

    return this.dbService.getRows("category").pipe(
      map((response: any) => response.rows),
      mergeMap((dbCategories: Category[]) => {
        if (dbCategories && dbCategories.length) return of(dbCategories);
        else
          return merge(
            defaultInventoryCats.map((seedData) => {
              const category = Object.assign({}, new Category(), seedData);
              return this.dbService.addRow<Category>("category", category);
            })
          ).pipe(
            zipAll(),
            tap((category) => {})
          );
      })
    );
  }

  private createDefaultAccountGroups(): Observable<AccountGroup[]> {
    const defaultAccountGroups: any[] = this.appDefaultsService.defaultAccountGroups();

    return this.dbService.getRows("acctGroup").pipe(
      map((response: any) => response.rows),
      mergeMap((dbAcctGroups: AccountGroup[]) => {
        if (dbAcctGroups && dbAcctGroups.length) return of(dbAcctGroups);
        else
          return merge(
            defaultAccountGroups.map((seedData) => {
              const acctGroup = Object.assign({}, new AccountGroup(), seedData);
              return this.dbService.addRow<AccountGroup>("acctGroup", acctGroup);
            })
          ).pipe(
            zipAll(),
            tap((accountGroups) => {})
          );
      })
    );
  }

  createDefaultTaxes(): Observable<any[]> {
    const country = this._currentZone.countryIsoCode;
    const defaultTaxes: any[] = this.appDefaultsService.defaultTaxes(country);

    return concat([
      this.dbService.getRows("tax").pipe(map((response: any) => response.rows)),
      this.createDefaultTaxAccountCategories(),
      this.createDefaultTaxInventoryCategories(),
    ]).pipe(
      zipAll(),
      mergeMap(
        ([dbTaxes, dbAccountCategories, dbInventoryCategories]: [
          Tax[],
          TaxAccountCategory[],
          TaxInventoryCategory[]
        ]) => {
          if (dbTaxes && dbTaxes.length) return of(dbTaxes); // return current taxes
          else
            return merge(
              defaultTaxes.map((seedData) => this.dbService.addRow<Tax>("tax", Object.assign({}, new Tax(), seedData)))
            ).pipe(
              zipAll(),
              tap((salesTaxes) => {}),
              mergeMap((dbSalesTaxes: Tax[]) => {
                const taxRuleSeedData = this.appDefaultsService.defaultTaxRules(country);
                return taxRuleSeedData.map((seedData) => {
                  const newTaxRule = Object.assign({}, new TaxRule(), seedData);
                  const foundTax = dbSalesTaxes.find((tax) => tax.shortDesc == newTaxRule.taxShortDesc);
                  if (!foundTax) {
                    console.warn(`Cannot find tax with acronym: ${newTaxRule.tax}`);
                    return of();
                  }
                  newTaxRule.taxId = foundTax.id;
                  newTaxRule.ruleName = foundTax.shortDesc;
                  newTaxRule.subDivisions = (<string[]>newTaxRule.subDivisions).map((subDivision) => ({
                    id: 0,
                    subDivisionIsoCode: subDivision,
                  }));
                  // Associate Tax Account Categories
                  newTaxRule.taxAccountCategories = (<string[]>newTaxRule.taxAccountCategories)
                    .map((taxCatName) => dbAccountCategories.find((dbTaxCat) => dbTaxCat.category == taxCatName))
                    .filter((taxCat) => !!taxCat);

                  // Associate Tax Inventory Categories
                  newTaxRule.taxInventoryCategories = (<string[]>newTaxRule.taxInventoryCategories)
                    .map((inventoryCatName) =>
                      dbInventoryCategories.find((dbInventoryCat) => dbInventoryCat.category == inventoryCatName)
                    )
                    .filter((inventoryCat) => !!inventoryCat);

                  return this.dbService.addRow<TaxRule>("taxRule", newTaxRule);
                });
              }),
              zipAll()
            );
        }
      ),
      zipAll()
    );
  }

  closeAndFinishWizard() {
    // TODO: Implement this method when wizard is put in context (inside modal after signing in)
    // for now just redirect to home

    if (this.authService.isLoginCompleted()) {
      this.router.navigate(["/dashboard"]);
    } else {
      // If store was selected try to find Cashout
      this.subsList.push(
        this.cashoutService.searchOpenCashout$(this._currentStore.id, this._currentStation.id).subscribe({
          next: (cashout) => {
            this.appSettingsService.initStorage(
              this._currentZone,
              this._currentStore,
              this._currentStation,
              null,
              this._currentUser,
              cashout,
              "Store"
            );
            this.authService.setLoginCompleted(true);
            this.router.navigate(["/dashboard"]); // Go to dashboard
          },
          error: (error) => {
            this.handleBackendErrors(error);
          },
        })
      );
    }
  }

  private saveFiscalYear(): Observable<FiscalYearsSettings> {
    const currDate = new Date(),
      y = currDate.getFullYear();
    const newFiscalYearFields = {
      fiscalYearName: y,
      startDate: new Date(y, 0, 1),
      endDate: new Date(y, 12, 0),
      isCurrent: true,
    };
    let fiscalYearObservable: Observable<FiscalYearsSettings>;
    const fiscalYearFilter = {
      fiscalYearName: { matchMode: "equals", value: y + "" },
    };
    if (!this._currentFiscalYear)
      fiscalYearObservable = this.dbService.getRows("fiscalYear", JSON.stringify(fiscalYearFilter), 0, 1).pipe(
        mergeMap((myObjects: any) => {
          return myObjects.rows.length
            ? this.dbService.getRow("fiscalYear", myObjects.rows[0].id)
            : of(new FiscalYearsSettings());
        })
      );
    else fiscalYearObservable = of(this._currentFiscalYear);

    return fiscalYearObservable.pipe(
      mergeMap((fiscalYear: FiscalYearsSettings) => {
        this._currentFiscalYear = Object.assign(fiscalYear, newFiscalYearFields);

        if (this._currentFiscalYear.id == 0) {
          return this.dbService.addRow("fiscalYear", this._currentFiscalYear).pipe(
            tap((savedFiscalYear: FiscalYearsSettings) => {
              this._currentFiscalYear = savedFiscalYear;
            })
          );
        } else {
          return this.dbService.editRow("fiscalYear", this._currentFiscalYear).pipe(
            tap((savedFiscalYear: FiscalYearsSettings) => {
              this._currentFiscalYear = savedFiscalYear;
            })
          );
        }
      })
    );
  }

  private saveStock(business: BusinessDetail, zone: Zone): Observable<Stock> {
    // if we have already set up a stock, use that one
    if (this._currentStock) return of(this._currentStock);

    const stockName = `Stock - ${business.businessName}`;
    const zoneId = zone.id;
    const newStockFields = {
      stockName,
      zoneId,
      isActive: true,
    };
    const stocksFilter = JSON.stringify(this._createZoneFilter(zone));

    return this.dbService.getRows("stock", stocksFilter, 0, 1).pipe(
      mergeMap((myObjects: any) => {
        if (myObjects.rows && myObjects.rows.length)
          // we found stocks return the first one
          return of(myObjects.rows[0]);
        // we don't have stocks, create one with default data
        else return this.dbService.addRow("stock", Object.assign(new Stock(), newStockFields));
      }),
      tap((stock: Stock) => {
        if (stock && stock.id !== 0) this._currentStock = stock;
      })
    );
  }

  private saveStation(store: Store): Observable<Station> {
    const stationName = `Station 1`;
    // That needs to be changed later in order to use a number generated by the database
    // const stationNumber = Math.round( Math.random()*1000 );
    const stationNumber = 1;
    const storeId = store.id;
    const newStationFields = {
      stationName,
      stationNumber,
      storeId,
      isActive: true,
    };
    const stationsFilter = JSON.stringify(
      Object.assign(this._createStoreFilter(store), {
        stationName: { matchMode: "equals", value: stationName },
      })
    );
    let stationObservable: Observable<Station>;
    if (!this._currentStation) {
      if (this.appSettingsService.getStation() && this.appSettingsService.getStation().storeId === store.id)
        stationObservable = of(this.appSettingsService.getStation());
      else
        stationObservable = this.dbService.getRows("station", stationsFilter, 0, 1).pipe(
          mergeMap((myObjects: any) => {
            return myObjects.rows.length ? this.dbService.getRow("station", myObjects.rows[0].id) : of(new Station());
          })
        );
    } else stationObservable = of(this._currentStation);

    return stationObservable.pipe(
      mergeMap((station: Station) => {
        if (station.id === 0) {
          Object.assign(station, newStationFields);
          return this.dbService.addRow("station", station).pipe(
            tap((savedStation: Station) => {
              this._currentStation = savedStation;
            })
          );
        } else {
          this._currentStation = station;
          return of(this._currentStation);
          // return this.dbService.editRow('station', this._currentStation).pipe(tap(savedStation => {
          //   this._currentStation = savedStation;
          // }))
        }
      })
    );
  }

  private saveBusinessDetails(): Observable<boolean> {
    if (!this.businessDetailsComponent) return of(false);

    return this.businessDetailsComponent.onSubmit(true).pipe(
      mergeMap((bussiness) => {
        this.currentBusiness = bussiness ? bussiness : this.businessDetailsComponent._object;

        return this.saveZone(this.currentBusiness).pipe(
          //save backOffice
          mergeMap((zone: Zone) => this.saveBackOffice(this.currentBusiness, zone).pipe(map((backOffice) => zone))),
          // Create or retrieve a stock
          mergeMap((zone: Zone) => this.saveStock(this.currentBusiness, zone).pipe(map((stock) => [zone, stock]))),
          // Create/retrive the Store
          mergeMap(([zone, stock]: [Zone, Stock]) => {
            return this.saveStore(this.currentBusiness, zone, stock);
          }),
          // Create a station if necessary
          mergeMap((store: Store) => {
            return this.saveStation(store);
          }),
          map((station) => {
            return true;
          })
        );
      })
    );
  }

  private saveStore(business: BusinessDetail, zone: Zone, stock: Stock): Observable<Store> {
    const storeName = `Store - ${business.businessName}`;
    const address = business.address;
    const zoneId = zone.id;
    const newStoreFields: Partial<Store> = {
      storeName,
      address: FormDataHelpers.clearModelIDs(address),
      zoneId,
      isActive: true,
      stockId: stock.id,
      storeID: `Store_1`,
    };

    let storeObservable: Observable<Store>;
    const _zoneFilter = JSON.stringify(this._createZoneFilter(zone));

    if (!this._currentStore) {
      if (this.appSettingsService.getStore()) storeObservable = of(this.appSettingsService.getStore());
      else
        storeObservable = this.dbService.getRows("store", _zoneFilter, 0, 1).pipe(
          mergeMap((myObjects: any) => {
            return myObjects.rows.length ? this.dbService.getRow("store", myObjects.rows[0].id) : of(new Store());
          })
        );
    } else storeObservable = of(this._currentStore);

    return storeObservable.pipe(
      mergeMap((store: Store) => {
        if (store.id === 0) {
          Object.assign(store, newStoreFields);
          return this.dbService.addRow("store", store).pipe(
            tap((savedStore: Store) => {
              this._currentStore = savedStore;
            }),
            map((store: Store) => this.createStoreSettings(store, zone)),
            concatAll(),
            zipAll(),
            map(([store]) => store)
          );
        } else {
          this._currentStore = store;
          return merge(this.createStoreSettings(this._currentStore, zone)).pipe(
            zipAll(),
            map((responses: any[]) => {
              return store; // For now we don't need to use the print settings responses, so just return the saved store
            })
          );

          // return this.dbService.editRow('store', this._currentStore).pipe(tap(savedStore => {
          //   this._currentStore = savedStore;
          // }))
        }
      })
    );
  }

  private saveBackOffice(business: BusinessDetail, zone: Zone): Observable<BackOffice> {
    const backOfficeName = `Backoffice - ${business.businessName}`;
    const address = business.address;
    const zoneId = zone.id;
    const newBackOfficeFields = new BackOffice();

    Object.assign(newBackOfficeFields, {
      backOfficeName,
      address: FormDataHelpers.clearModelIDs(address),
      zoneId,
      isActive: true,
      backOfficeID: `BackOffice_1`,
    });
    return this.dbService.addRow("backOffice", newBackOfficeFields);
  }

  private createStoreSettings(store: Store, zone: Zone) {
    const settingsFilter = this._createStoreFilter(store);
    const fnCreateSettingsObservable = (modelName, settings) => {
      return this.dbService.getRows(modelName, JSON.stringify(settingsFilter), 0, 1).pipe(
        catchError((error) => {
          console.error("Error saving a setting in Wizard", error);
          return of({});
        }),
        mergeMap((response: any) => {
          if (response.rows && response.rows.length) return of(response.rows[0]);
          else return this.dbService.addRow(modelName, Object.assign(settings, { storeId: store.id }));
        })
      );
    };

    return [
      fnCreateSettingsObservable("storeSettingLetterSalePrint", new StoreSettingLetterSalePrint(zone)),
      fnCreateSettingsObservable("storeSettingLetterReturnPrint", new StoreSettingLetterReturnPrint(zone)),
      fnCreateSettingsObservable("storeSettingTapeSalePrint", new StoreSettingTapeSalePrint(zone)),
      fnCreateSettingsObservable("storeSettingTapeReturnPrint", new StoreSettingTapeReturnPrint(zone)),
      fnCreateSettingsObservable("storeSettingGiftReceipt", new StoreSettingGiftReceipt()),
      fnCreateSettingsObservable("storeSettingKiosk", new KioskSetting(store)),
      fnCreateSettingsObservable("storeSettingSaleDoc", new StoreSettingSaleDoc(store)),
    ];
  }

  private saveZone(business: BusinessDetail): Observable<Zone> {
    const countryIsoCode = business.address.countryIsoCode;
    // Set defaults for zone
    const newZoneFields = _.defaults(this.appDefaultsService.defaultZoneInfo(countryIsoCode), {
      zoneName: this.locationService.lookupCountryByIsoCode(countryIsoCode),
      defaultCurrencyIsoCode: business.defaultCurrencyIsoCode,
      countryIsoCode: countryIsoCode,
    });
    newZoneFields.cashRounding = AppDefaultsService.DefaultPennyRoundingForZone(newZoneFields).cashRounding || "0.01";

    let zoneObservable: Observable<Zone>;
    if (!this._currentZone) {
      if (this.appSettingsService.getZone()) zoneObservable = of(this.appSettingsService.getZone());
      else
        zoneObservable = this.dbService.getRows("zone", "", 0, 1).pipe(
          mergeMap((myObjects: any) => {
            return myObjects.rows.length ? this.dbService.getRow("zone", myObjects.rows[0].id) : of(new Zone());
          })
        );
    } else zoneObservable = of(this._currentZone);

    return zoneObservable.pipe(
      mergeMap((zone: Zone) => {
        if (zone.id === 0) {
          Object.assign(zone, newZoneFields);
          return this.dbService.addRow("zone", zone).pipe(
            tap((savedZone: Zone) => {
              this._currentZone = savedZone;
            })
          );
        } else {
          this._currentZone = zone;
          return of(this._currentZone);
          // return this.dbService.editRow('zone', this._currentZone).pipe(tap(savedZone => {
          //   this._currentZone = savedZone;
          // }))
        }
      })
    );
  }

  private saveStep(stepNumber): Observable<any> {
    switch (stepNumber) {
      case WizardSteps.BUSINESS_DETAILS: // Save business details step
        return this.saveBusinessDetails().pipe(
          tap((result) => {
            if (result) {
              this.zoneFilter = this._createZoneFilter(this._currentZone);
              this.zoneDefaultRow = { zoneId: this._currentZone.id };
              // setTimeout(() => {
              this.setDefaultDenominations(this.currentBusiness.defaultCurrencyIsoCode, this._currentZone.id);
              this.setDefaultZoneTenders(this.currentBusiness.defaultCurrencyIsoCode, this._currentZone.id);
              this.setDefaultInventoryDocFormats(this._currentZone.id);
              // }, 0);
            } else {
              throw Error("Couldn't save business details");
            }
          })
        );

      case WizardSteps.STORE_TENDERS:
        return this.saveStoreTenders();

      case WizardSteps.SALE_DOC_FORMATS:
        return this.saveSaleDocFormats();
    }

    return of(); // return empty observable if step doesn't required being saved
  }

  private setDefaultInventoryDocFormats(zoneId) {
    if (!this.inventoryFormatsFormListComponent) return;

    this.subsList.push(
      this.inventoryFormatsFormListComponent.onComplete.subscribe((status) => {
        const dbInventoryFormats = this.inventoryFormatsFormListComponent.getObjects();
        if (!dbInventoryFormats || !dbInventoryFormats.length) {
          Object.keys(Inventory_Doc_Type).forEach((key) => {
            const docType = Inventory_Doc_Type[key];
            const formatsDefaults = this.appDefaultsService.defaultInventoryDocFormats(docType);
            if (!_.isEmpty(formatsDefaults)) {
              const inventoryFormat = Object.assign({}, new InventoryDocFormat(), formatsDefaults, { docType, zoneId });
              this.inventoryFormatsFormListComponent.addNewRow(inventoryFormat, false);
            }
          });
          // Saves in database all inventory formats that were added to the form list
          this.inventoryFormatsFormListComponent.saveAll(true);
        }
        this.inventoryFormatsFormListComponent.enableEditMode();
      })
    );
  }

  private setDefaultZoneTenders(currencyIsoCode: string, zoneId: number) {
    const defaultTenders = this.appDefaultsService.defaultTenders(currencyIsoCode);
    if (!defaultTenders || !this.zoneTendersFormListComponent) return;

    this.subsList.push(
      this.zoneTendersFormListComponent.onComplete.subscribe({
        next: (status) => {
          const dbTenders = this.zoneTendersFormListComponent.getObjects();
          if (!dbTenders || !dbTenders.length) {
            const allTransactionTypes = this.zoneTenderTypesService.all_tenderTypeTransactionTypes;

            defaultTenders.forEach((tender) => {
              const zoneTender = Object.assign({}, new TenderType(), {
                type: tender.type,
                description: tender.description,
                currencyIsoCode: currencyIsoCode,
                zoneId: zoneId,
                transactionTypes:
                  tender.type === Tender_Type.Cash
                    ? allTransactionTypes
                    : allTransactionTypes.filter((type) =>
                        [Transaction_Type.Sales_Charge].includes(type.transactionType)
                      ),
              });
              this.zoneTendersFormListComponent.addNewRow(zoneTender, false);
            });
            // Save new tenders
            this.zoneTendersFormListComponent.saveAll(true);
          }
        },
      })
    );
  }

  private setDefaultDenominations(currencyIsoCode: string, zoneId: number) {
    this.defaultDenominations = this.appDefaultsService.defaultDenominations(currencyIsoCode);
    // Do nothing, if we cannot find denomination for this currency OR form list is not available
    if (!this.defaultDenominations || !this.denominationsFormListComponent) return;

    this.subsList.push(
      this.denominationsFormListComponent.onComplete.subscribe({
        next: (status) => {
          const dbDenominations = this.denominationsFormListComponent.getObjects();
          // if we don't any denominations in database yet, use the defaults
          if (!dbDenominations || !dbDenominations.length) {
            this.defaultDenominations.forEach((denomination) => {
              const newDenomination = Object.assign({}, new CashDenomination(), {
                zoneId,
                value: denomination.value,
                cashType: denomination.type === "coin" ? Cash_Type.coin : Cash_Type.paper,
              });
              this.denominationsFormListComponent.addNewRow(newDenomination, false);
            });
            // Save new denominations
            this.denominationsFormListComponent.saveAll(true);
          }
          this._isFetchingDenomination = false;
        },
      })
    );
  }

  private updateWizard(newStep) {
    // Update store tenders when this step is selected
    if (newStep === WizardSteps.STORE_TENDERS) {
      this.hideStepsFlags[WizardSteps.STORE_TENDERS] = true;
      this.subsList.push(
        concat(this.saveStoreTenders(), this.storeTenderTypesComponent.addNewTenderTypes()).subscribe(() => {
          this.reinitStepComponents(WizardSteps.STORE_TENDERS);
        })
      );
    }
  }

  onStepChanged(stepsInfo: StepChangedEvent) {
    if (this.ignoreStepChange) return;

    const fnCompleteStep = () => {
      this.greaterVisitedStep = Math.max(this.greaterVisitedStep, this.activeStep);
      // Disable/renable navigation buttons based on current form status
      this.updateNavigationPerFormValidity();
      this.updateWizard(stepsInfo.newIndex);
      this.subsList.push(
        this.saveStep(stepsInfo.oldIndex).subscribe({
          error: (error) => {
            this.handleBackendErrors(error);
          },
        })
      );
    };
    // first see if it has errors
    const wizardStepValidation$ = this.validateWizardStep(stepsInfo.oldIndex, stepsInfo.newIndex).pipe(take(1));
    this.subsList.push(
      wizardStepValidation$.subscribe((stepValidation) => {
        if (stepValidation) {
          // Don't validate when rolling back to previous step
          this.ignoreStepChange = true;
          this.activeStep = stepsInfo.oldIndex; // Return to old step
          // make sure that Angular has finished executed listener before disabling this flag again
          setTimeout(() => (this.ignoreStepChange = false), 50);
          // Show dialog with errors
          this.showValidationErrorsDialog([stepValidation]);
        } else {
          if (this.hasPendingChanges(stepsInfo.oldIndex)) {
            this.ignoreStepChange = true;
            this.activeStep = stepsInfo.oldIndex;
            this.showPendingChangesConfirmation({
              onAccept: () => {
                this.saveComponentData(stepsInfo.oldIndex);
                this.activeStep = stepsInfo.newIndex;
                // this.handleStepValidation(stepsInfo);
                // make sure that Angular has finished executed listener before disabling this flag again
                setTimeout(() => (this.ignoreStepChange = false), 50);

                fnCompleteStep();
              },

              onReject: () => {
                this.ignoreStepChange = false;
              },
            });
          } else fnCompleteStep();
        }
      })
    );
  }

  private showPendingChangesConfirmation({
    onAccept,
    onReject,
    isFinalAction = false,
  }: {
    onAccept: Function;
    onReject: Function;
    isFinalAction?: boolean;
  }) {
    this.confirmationService.confirm({
      header: "Confirmation",
      message: "You have unsaved changes. Are you sure you want to proceed?",
      rejectButtonStyleClass: "p-button-link",
      acceptLabel: `Yes, ${isFinalAction ? "Finish" : "Next"}`,
      rejectLabel: "No",
      accept: onAccept,
      reject: onReject,
    });
  }

  private hasPendingChanges(step: WizardSteps): boolean {
    const currComponent = this.getStepComponent(step);
    if (currComponent instanceof GenericFormComponent) return currComponent._myForm.dirty;
    else if (currComponent instanceof FormListComponent) return currComponent.hasPendingChanges;

    return false;
  }

  private saveComponentData(step: WizardSteps) {
    const currComponent = this.getStepComponent(step);
    if (currComponent instanceof FormListComponent) currComponent.saveAll();
  }

  private updateNavigationPerFormValidity() {
    const activeComponent = this.getStepComponent(this.activeStep);
    if (activeComponent instanceof GenericFormComponent) {
      if (activeComponent._myForm.valid && activeComponent._myForm.pristine) this.enableStepsBtns();
      else this.disableStepsBtns();
    }
  }

  private handleBackendErrors(error) {
    if (error instanceof HttpErrorResponse)
      this.messageService.add({
        summary: "SERVER ERROR",
        detail: `An error has ocurred when saving your data.\n${error.message}`,
        severity: "error",
      });
    else
      this.messageService.add({
        summary: "ERROR",
        detail: `An error has ocurred when saving your data.\n${error}`,
        severity: "error",
      });
  }

  private showValidationErrorsDialog(errors: StepValidationMessages[]) {
    this.dialogValidationMessages = errors;
    this.showValidationDialog = true;
  }

  hideValidationErrorsDialog() {
    this.showValidationDialog = false;
    this.dialogValidationMessages = [];
  }

  private _createZoneFilter(zone: Zone) {
    return {
      zoneId: { value: zone.id, matchMode: "equals" },
    };
  }

  private _createStoreFilter(store: Store): any {
    return {
      storeId: { value: store.id, matchMode: "equals" },
    };
  }

  private saveStoreTenders(): Observable<StoreTenderTypeList> {
    // Tries to add zone tender as store tenders automatically when database is empty
    return this.dbService
      .getRows("storeTenderTypeList", JSON.stringify(this._createStoreFilter(this._currentStore)))
      .pipe(
        map((response: any) => (response.rows && response.rows.length ? response.rows[0] : null)),
        mergeMap((storeTenders: StoreTenderTypeList) => {
          // If already have store tender list with at least one selected tender
          if (storeTenders && storeTenders.storeTenderTypes && storeTenders.storeTenderTypes.length)
            return this.storeTenderTypesComponent.onSubmit(true);
          // if not store tenders are found, try to create new ones from Zone Tender Types
          else
            return this.dbService.getRows("tenderType", JSON.stringify(this._createZoneFilter(this._currentZone))).pipe(
              mergeMap((response: any) => {
                if (response.rows && response.rows.length) {
                  const zoneTenders: TenderType[] = response.rows;
                  const storeTendersList = new StoreTenderTypeList();
                  storeTendersList.storeId = this._currentStore.id;
                  storeTendersList.storeTenderTypes = zoneTenders
                    .filter((tender) => tender.type === Tender_Type.Cash) // only include Cash tender
                    .map((tender, index) =>
                      Object.assign(new StoreTenderType(), { tenderTypeId: tender.id, priority: index })
                    );
                  return this.dbService.addRow("storeTenderTypeList", storeTendersList);
                } else return of(new StoreTenderTypeList());
              })
            );
        })
      )
      .pipe(
        tap(() => {
          if (this.storeTenderTypesComponent) this.storeTenderTypesComponent._myForm.markAsPristine();
        })
      );
  }

  private saveSaleDocFormats(): Observable<any> {
    return this.saleDocFormatsComponent.onSubmit(true);
  }

  private reinitStepComponents(step: WizardSteps) {
    // Remove component in order to destroy and generate again next time
    this.reloadComponentsFlags[step] = true;
    setTimeout(() => {
      // Re-render component again forcing to refresh data from server
      this.reloadComponentsFlags[step] = false;
    }, 0);

    setTimeout(() => {
      this.hideStepsFlags[step] = false;
    }, 300);
  }

  private validateWizard(): Observable<StepValidationMessages[]> {
    const wizardStepsKeys = Object.keys(WizardSteps).map((key) => WizardSteps[key]);
    const wizardSteps$ = wizardStepsKeys.map((step) => this.validateWizardStep(step, WizardSteps.FINISH_WIZARD)); // Remove steps with no validation errors
    return forkJoin(wizardSteps$).pipe(map((validations: any[]) => validations.filter(Boolean)));
  }

  private validateWizardStep(actualStep: number, newStep: number): Observable<StepValidationMessages> {
    let modelName: string;

    switch (actualStep) {
      case WizardSteps.BUSINESS_DETAILS:
        if (!this.businessDetailsComponent) return of(null);
        const businessErrors = FormDataHelpers.getFormValidationErrors(this.businessDetailsComponent._myForm);
        if (businessErrors.length)
          return of({
            stepCaption: this.getWizardStepTitle(actualStep),
            messages: businessErrors.map((error) => {
              return {
                fieldName: error.control,
                validationError: this.validationMessagesService.getErrorMessage(
                  error.error,
                  error.value,
                  error.control
                ),
              };
            }),
          });
        break;

      case WizardSteps.DENOMINATIONS:
        if (!this.denominationsFormListComponent) return of(null);
        modelName = _.lowerCase(this.getWizardStepTitle(actualStep)).replace(/s$/, "");
        return this.validateFormList(this.denominationsFormListComponent, actualStep, newStep, modelName);

      case WizardSteps.ZONE_TENDERS:
        if (!this.zoneTendersFormListComponent) return of(null);
        modelName = _.lowerCase(this.getWizardStepTitle(actualStep)).replace(/s$/, "");
        return this.validateFormList(this.zoneTendersFormListComponent, actualStep, newStep, modelName);

      case WizardSteps.STORE_TENDERS:
        if (!this.storeTenderTypesComponent) return of(null);
        const storeTendersErrors = FormDataHelpers.getFormValidationErrors(this.storeTenderTypesComponent._myForm);
        if (storeTendersErrors.length)
          return of({
            stepCaption: this.getWizardStepTitle(actualStep),
            messages: storeTendersErrors.map((error) => {
              const validationError = this.validationMessagesService.getErrorMessage(
                error.error,
                error.value,
                error.control
              );
              const fieldName = error.control === "storeTenderTypes" ? "Selected Tenders" : error.control;
              return { fieldName, validationError };
            }),
          });
        break;

      case WizardSteps.SALE_DOC_FORMATS:
        if (!this.saleDocFormatsComponent) return of(null);
        const saleFormatsErrors = FormDataHelpers.getFormValidationErrors(this.saleDocFormatsComponent._myForm);
        if (saleFormatsErrors.length)
          return of({
            stepCaption: this.getWizardStepTitle(actualStep),
            messages: saleFormatsErrors.map((error) => {
              return {
                fieldName: error.control,
                validationError: this.validationMessagesService.getErrorMessage(
                  error.error,
                  error.value,
                  error.control
                ),
              };
            }),
          });
        break;

      case WizardSteps.INVENTORY_FORMATS:
        if (!this.inventoryFormatsFormListComponent) return of(null);
        actualStep = WizardSteps.INVENTORY_FORMATS;
        modelName = _.lowerCase(this.getWizardStepTitle(actualStep));
        return this.validateFormList(this.inventoryFormatsFormListComponent, actualStep, newStep, modelName);
    } // end switch

    return of(null);
  }

  getWizardStepTitle(stepNumber) {
    return this.wizardSteps[stepNumber].label;
  }

  private validateFormList(
    formlistComponent: FormListComponent,
    actualStep: number,
    newStep: number,
    modelName: string
  ): Observable<StepValidationMessages> {
    const fnCheckData = (formlist: FormListComponent) => {
      const totalRows = formlist.getObjects().length;
      // First validates that we have enter some data (content is NOT empty, only applied when moving forward)
      if (totalRows === 0 && actualStep < newStep)
        return {
          stepCaption: this.getWizardStepTitle(actualStep),
          messages: [`Please create at least one ${modelName}`],
        };

      // Then checks for validity of existing content (form list rows)
      if (!formlist.isValid()) {
        formlist._submitAttempted = true; // This hightlights all validation errors in form list
        return {
          stepCaption: this.getWizardStepTitle(actualStep),
          messages: [`There are some validation problems, please fix them first`],
        };
      }
      return null;
    };

    if (formlistComponent.active) return of(fnCheckData(formlistComponent));
    else
      return formlistComponent.onComplete.pipe(
        take(1),
        map((success) => fnCheckData(formlistComponent))
      );
  }

  _toSentenceCase(fieldName: string) {
    return _.startCase(fieldName);
  }

  onStepStatusChanged(event: { statusData: string; form: UntypedFormGroup }, step: WizardSteps) {
    if (step !== this.activeStep) return; // if we are not in the same step simply do nothing

    if (event.statusData.toUpperCase() === "VALID")
      // Disable navigation buttons is form is invalid
      this.enableStepsBtns();
    else this.disableStepsBtns();
  }

  private disableStepsBtns() {
    this.wizardSteps.forEach((step) => (step.disabled = true));
    // this._backBtnEnabled = false;
    // this._nextBtnEnabled = false;
  }

  private enableStepsBtns() {
    this.wizardSteps.forEach((step, index) => {
      // Only enable previous steps when this wizard is called when there is not previous store created/selected in login
      if (this.appSettingsService.getStoreId() || index <= this.greaterVisitedStep + 1) step.disabled = false;
      else step.disabled = true;
    });
    // this._backBtnEnabled = true;
    // this._nextBtnEnabled = true;
  }

  getStepComponent(step: WizardSteps) {
    switch (step) {
      case WizardSteps.BUSINESS_DETAILS:
        return this.businessDetailsComponent;
      case WizardSteps.DENOMINATIONS:
        return this.denominationsFormListComponent;
      case WizardSteps.ZONE_TENDERS:
        return this.zoneTendersFormListComponent;
      case WizardSteps.STORE_TENDERS:
        return this.storeTenderTypesComponent;
      case WizardSteps.SALE_DOC_FORMATS:
        return this.saleDocFormatsComponent;
      case WizardSteps.INVENTORY_FORMATS:
        return this.inventoryFormatsFormListComponent;
    }
  }

  ngDoCheck(): void {
    const activeComponent = this.getStepComponent(this.activeStep);
    if (activeComponent instanceof FormListComponent) {
      if (activeComponent.isValid()) this.enableStepsBtns();
      else this.disableStepsBtns();
    }
  }

  ngOnDestroy() {
    // this.subsList.map((sub) => { sub.unsubscribe(); });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
