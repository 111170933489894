/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Role } from "../../role/role";

export class RolePrivilege {
  id: number;
  feature: string;
  caption: string;
  privilege: RolePrivilegeName;
  roleId: number;
  role: Role;

  constructor() {
    this.id = 0;
    this.feature = null;
    this.caption = null;
    this.privilege = null;
    this.roleId = null;
    this.role = null;
  }
}

export enum RolePrivilegeName {
  Allow = "Allow",
  Restrict = "Restrict",
  User_Credential = "User Credential",
  Manager_Credential = "Manager Credential",
  Administrator_Credential = "Administrator Credential",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
