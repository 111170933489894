/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import * as googlePhone from "google-libphonenumber";

const PNF = googlePhone.PhoneNumberFormat;
const phoneUtil = googlePhone.PhoneNumberUtil.getInstance();

@Injectable()
export class CountryPhoneMaskService {
  constructor() {}

  getPhoneMask(locale: string) {
    // Create an example number for the locale/country
    const maxPhoneLength = this.getMaximumPhoneLength(locale);
    const phoneMask = "9".repeat(maxPhoneLength);

    const phoneNumber = phoneUtil.parseAndKeepRawInput(phoneMask, locale);
    const intFormatNumber: string = phoneUtil.format(phoneNumber, PNF.INTERNATIONAL);
    // Separate country code from local number part
    const matches = intFormatNumber.match(/(\+\d+)\s+(.*)/);
    // Use '#' as mask for local number segment.
    // 9 will not be interpreted as a mask on new developed component (TakuMaskInput)
    return matches[1] + " " + matches[2].replace(/9/g, "#");
  }

  getMaximumPhoneLength(locale: string) {
    // Get phone metadata from specified locale
    const phoneDesc = phoneUtil.getMetadataForRegion(locale).getGeneralDesc();
    // Returns the longest phone number from all the possible lengths
    return Math.max(...phoneDesc.possibleLengthArray());
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
