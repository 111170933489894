/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Account, AccountType } from "../account/account";
import { Contact } from "../contact/contact";
import { CommercialAccountAddress } from "../commercial-account-address/commercial-account-address";
import { CommercialAccountPhone } from "../commercial-account-phone/commercial-account-phone";
import { CommercialAccountInstantMsg } from "../commercial-account-instant-msg/commercial-account-instant-msg";
import { CommercialAccountEmail } from "../commercial-account-email/commercial-account-email";
import { CommercialAccountSocialProfile } from "../commercial-account-social-profile/commercial-account-social-profile";
import { ContactAccountBase } from "../account/contact-account-base";
import { GenericPhone } from "../phone-shared/GenericPhone";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Zone } from "../../settings/zone-settings/Zone";

export class CommercialAccount implements ContactAccountBase {
  id: number;
  updatedAt: string;
  name: string;
  accountBusinessNum: string;
  accountId: number;
  account: Account;
  contacts: Contact[];
  commercialAccountAddresses: CommercialAccountAddress[];
  commercialAccountPhones: CommercialAccountPhone[];
  commercialAccountEmails: CommercialAccountEmail[];
  commercialAccountInstantMsgs: CommercialAccountInstantMsg[];
  commercialAccountSocialProfiles: CommercialAccountSocialProfile[];
  commercialAccountSupplierDetails: CommercialAccountSupplierDetail[];
  url: string;
  supplierCurrencyIsoCode: string;

  constructor(_supplierCurrencyIsoCode: string) {
    this.id = 0;
    this.name = "";
    this.accountBusinessNum = "";
    this.account = new Account(AccountType.commercial);
    this.contacts = [];
    this.commercialAccountAddresses = [];
    this.commercialAccountPhones = [];
    this.commercialAccountEmails = [];
    this.commercialAccountInstantMsgs = [];
    this.commercialAccountSocialProfiles = [];
    this.commercialAccountSupplierDetails = [];
    this.url = "";
    this.supplierCurrencyIsoCode = _supplierCurrencyIsoCode;
  }

  get accountName(): string {
    return this.name;
  }

  get accountEmails(): string[] {
    return this.commercialAccountEmails.map((commercialEmail) => commercialEmail.email);
  }

  get accountPhones(): string[] {
    return this.commercialAccountPhones.map((phone) => GenericPhone.FormatNumber(phone));
  }

  get accountInitials(): string[] {
    return this.name
      .split(/\s+/)
      .slice(0, 2)
      .map((word) => word.charAt(0).toUpperCase());
  }
}

export class CommercialAccountSupplierDetail {
  id: number;
  zoneId: number;
  zone: Zone;
  minOrderAmount: number;

  constructor() {
    this.id = 0;
    this.zoneId = null;
    this.zone = new Zone();
    this.minOrderAmount = 0;
  }

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    const commercialZone = fb.group(new CommercialAccountSupplierDetail());
    return commercialZone;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
