/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Location } from "@angular/common";
import { AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren, Input, inject } from "@angular/core";
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, MessageService, SelectItem } from "primeng/api";
import { Sidebar } from "primeng/sidebar";
import { Observable, Observer } from "rxjs";
import { DBMode, GenericFormComponent } from "../../../forms/generic-form/generic-form.component";
import { AppSettingsStorageService } from "../../../shared/app-settings-storage.service";
import { AlertMessagesService } from "../../../shared/services/alert-messages.service";
import { FormDataHelpers } from "../../../utility/FormDataHelpers";
import { WebHelpers } from "../../../utility/WebHelpers";
import { AccountRelationship, AccountType } from "../account/account";
import { AccountComponent } from "../account/account.component";
import { AddressType } from "../address/address";
import { AddressComponent } from "../address/address.component";
import { AddressesManager, AddressFilterChangeObserver } from "../address/AddressesManager";
import { CommercialAccountAddress } from "../commercial-account-address/commercial-account-address";
import { CommercialAccountAddressComponent } from "../commercial-account-address/commercial-account-address.component";
import { CommercialAccountEmailComponent } from "../commercial-account-email/commercial-account-email.component";
import { CommercialAccountInstantMsgComponent } from "../commercial-account-instant-msg/commercial-account-instant-msg.component";
import { CommercialAccountPhoneComponent } from "../commercial-account-phone/commercial-account-phone.component";
import { CommercialAccountSocialProfileComponent } from "../commercial-account-social-profile/commercial-account-social-profile.component";
import { Contact } from "../contact/contact";
import { ContactComponent } from "../contact/contact.component";
import { CommercialAccount, CommercialAccountSupplierDetail } from "./commercial-account";
import { CommercialAccountService } from "./commercial-account.service";
import { CommercialAccountAccessRights } from "./commercial-account-access-rights";
import { AuthService } from "src/app/shared/services/auth.service";
import { CommercialAccountsAccessRightsFields } from "../shared/commercial-accounts-access-rights-fields";
import { CommercialAccountDetailsComponent } from "../commercial-account-details/commercial-account-details.component";
import { CommercialAccountDetailService } from "../commercial-account-details/commercial-account-details.service";
import { ExtendedSelectItem } from "src/app/shared/services/db.service";
import { take, tap } from "rxjs/operators";
import { ACTION_TYPE } from "src/app/shared/components/action-button/action-type.enum";
import { BusinessDetail } from "../../settings/business-settings/business-detail/business-detail";

@Component({
  selector: "taku-commercial-account",
  templateUrl: "./commercial-account.component.html",
  styleUrls: ["./commercial-account.component.scss"],
})
export class CommercialAccountComponent extends GenericFormComponent implements AfterViewInit, OnInit {
  @Input() accountAccessRights?: CommercialAccountsAccessRightsFields = new CommercialAccountAccessRights(
    this.authService
  );

  @ViewChild(Sidebar, { static: true }) contactsSidebar: Sidebar;

  public ACTION_TYPE: typeof ACTION_TYPE = ACTION_TYPE;
  AccountRelationship = AccountRelationship;
  _pageTitle = "Commercial Account";
  _toolbarTitle: string = this._pageTitle;
  // Selected contact from table
  selectedContact: Contact;
  zones: ExtendedSelectItem[];
  zonesFilled = false;

  takuPayAccountEnabled = false;

  // Variable for index of associated contacts
  _rowIndex: number;
  _addressMgr: AddressesManager;
  _addressTypes: SelectItem[] = [
    { label: AddressType.billingAddress, value: AddressType.billingAddress, icon: "ui-icon-receipt" },
    { label: AddressType.shippingAddress, value: AddressType.shippingAddress, icon: "ui-icon-local-shipping" },
  ];
  _mainTabActiveIndex = 0;
  commercialAccountSupplierService = inject(CommercialAccountDetailService);
  _filterChangeObserver: AddressFilterChangeObserver = {
    onFilterChanged: (value) => {
      this._addressTabIndex = 0;
    },
  };
  get _isMobile() {
    return WebHelpers.isMobileScreen();
  }

  get _isPortraitTabletScreen() {
    return WebHelpers.isPortraitTabletScreen();
  }

  constructor(
    protected _router: Router,
    public fb: UntypedFormBuilder,
    public dbService: CommercialAccountService,
    appSettings: AppSettingsStorageService,
    protected location: Location,
    public _route: ActivatedRoute,
    protected messageService: MessageService,
    protected alertMessage: AlertMessagesService,
    protected confirmationService: ConfirmationService,
    protected authService: AuthService
  ) {
    super(_router, fb, dbService, location, _route, messageService, alertMessage, confirmationService, appSettings);
  }

  ngAfterViewInit(): void {
    this._afterComponentInitCompleteAsync();
    // this.addressList.changes.subscribe(
    //   data => {
    //     this._addressTabIndex = data.length - 1;
    //   }
    // );
    // Replace click event on 'close button' by custom event that close sidebar based on form state
    // const btnCloseSidebar = this.contactsSidebar.el.nativeElement.querySelector('.p-overlaypanel-close');
    // const newCloseBtn = btnCloseSidebar.cloneNode(true);
    // btnCloseSidebar.parentNode.replaceChild(newCloseBtn, btnCloseSidebar);
    // this.contactsSidebar.renderer.listen(newCloseBtn, 'click', (event: Event) => {
    //   event.preventDefault();
    //   this.closeContactSidebar();
    // });
  }

  private _afterComponentInitCompleteAsync() {
    void Promise.resolve().then(() => {
      if (this.dbMode === DBMode.insert && this._myForm.status === "VALID") {
        this._myForm.markAsDirty();
      }
    });
  }

  get rowIndex() {
    return this._rowIndex;
  }

  // rowIndex and selectedContact should reflect the current selected contact all the time
  // So each time the index is changed, it triggers changing selected contact
  set rowIndex(index: number) {
    this._rowIndex = index;
    this.selectedContact = this._myForm.get("contacts").value[index];
  }

  // Variable for controlling tabview index
  _addressTabIndex = 0;
  @ViewChildren(CommercialAccountAddressComponent) addressList: QueryList<CommercialAccountAddressComponent>;

  // Variable for opening Dialogue
  _showAddContact = false;
  _tmpContactFormGroup: UntypedFormGroup;

  // Define enum variables
  // contactsInsertMode: boolean;

  static init(fb: UntypedFormBuilder, defaultCurrencyIsoCode: string): UntypedFormGroup {
    const tmpForm = fb.group(new CommercialAccount(defaultCurrencyIsoCode));
    tmpForm.setControl("account", AccountComponent.init(fb, AccountType.commercial));
    tmpForm.setControl("commercialAccountAddresses", fb.array([]));
    tmpForm.setControl("commercialAccountPhones", fb.array([]));
    tmpForm.setControl("commercialAccountEmails", fb.array([]));
    tmpForm.setControl("commercialAccountInstantMsgs", fb.array([]));
    tmpForm.setControl("commercialAccountSocialProfiles", fb.array([]));
    tmpForm.setControl("contacts", fb.array([]));
    tmpForm.setControl("commercialAccountSupplierDetails", fb.array([]));
    tmpForm.setControl("accountCreditCards", fb.array([]));

    return tmpForm;
  }

  protected _updateToolbarTitle() {
    if (!this._object) {
      return;
    }

    const companyName = this._object.name;
    this._toolbarTitle = this._pageTitle;
    if (this._id !== 0)
      this._toolbarTitle += `&nbsp;<span class='secondary-text'>${
        companyName ? `( ${companyName} )` : "&nbsp;"
      }</span>`;
  }

  ngOnInit() {
    super.ngOnInit();
    this.getZones();
    this._addressMgr = new AddressesManager(
      this._myForm,
      "commercialAccountAddresses",
      AddressType.billingAddress,
      this._filterChangeObserver
    );

    this.createAndUpdateContactsIndexCtrl();
    this.subsList.push(
      this.changedForm.subscribe(() => {
        this.createAndUpdateContactsIndexCtrl();
      })
    );

    this._mainTabActiveIndex = null;
    setTimeout(() => {
      this._mainTabActiveIndex = 0;
    }, 0);
  }

  loadObject() {
    super.loadObject();

    this._updateToolbarTitle();
  }

  private createAndUpdateContactsIndexCtrl() {
    const controlName = "selectedContactIndex";
    const defaultContactIndex = this.getDefaultContactIndex();
    const selectContactCtrl: AbstractControl = this._myForm.get(controlName);

    if (!selectContactCtrl) {
      this._myForm.addControl(controlName, this.fb.control(defaultContactIndex));
    } else {
      selectContactCtrl.setValue(defaultContactIndex);
    }
  }

  // Define lookup Variables
  initLookups() {}

  initForm(): void {
    this._model = "commercialAccount";

    // Fetch business details to get the defaultCurrencyIsoCode from appSettingService
    this.subsList.push(
      this.appSettingsService
        .getBusinessDetails()
        .pipe(
          tap((businessDetail: BusinessDetail) => {
            const defaultCurrencyIsoCode = businessDetail.defaultCurrencyIsoCode;
            this._object = new CommercialAccount(defaultCurrencyIsoCode);

            // By default every new Commercial Account will have one billing (default address) and one shipping address
            // const billingAddress = new CommercialAccountAddress(AddressType.billingAddress);
            // billingAddress.address.isDefault = true;
            // const shippingAddress = new CommercialAccountAddress(AddressType.shippingAddress);
            // this._object.commercialAccountAddresses = [ billingAddress, shippingAddress ];

            this._myForm = CommercialAccountComponent.init(this.fb, defaultCurrencyIsoCode);
            this._updateToolbarTitle();
            // Update titlebar after saving data
            this.subsList.push(
              this.changedForm.subscribe(() => {
                this._updateToolbarTitle();
              })
            );

            this.createAndUpdateContactsIndexCtrl();
          })
        )
        .subscribe()
    );
  }

  setFormArrays() {
    this._myForm.setControl("account", AccountComponent.set(this.fb, this._object.account));
    this._myForm.setControl(
      "commercialAccountAddresses",
      CommercialAccountAddressComponent.setArray(this.fb, this._object.commercialAccountAddresses)
    );
    this._myForm.setControl(
      "commercialAccountPhones",
      CommercialAccountPhoneComponent.setArray(this.fb, this._object.commercialAccountPhones)
    );
    this._myForm.setControl(
      "commercialAccountEmails",
      CommercialAccountEmailComponent.setArray(this.fb, this._object.commercialAccountEmails)
    );
    this._myForm.setControl(
      "commercialAccountInstantMsgs",
      CommercialAccountInstantMsgComponent.setArray(this.fb, this._object.commercialAccountInstantMsgs)
    );
    this._myForm.setControl(
      "commercialAccountSocialProfiles",
      CommercialAccountSocialProfileComponent.setArray(this.fb, this._object.commercialAccountSocialProfiles)
    );
    this._myForm.setControl(
      "commercialAccountSupplierDetails",
      CommercialAccountDetailsComponent.setArray(this.fb, this._object.commercialAccountSupplierDetails)
    );
    this._myForm.setControl("contacts", ContactComponent.setArray(this.fb, this._object.contacts));
  }

  get addressesCtrlArray() {
    return <UntypedFormArray>this._myForm.get("commercialAccountAddresses");
  }

  get contactsCtrlArray() {
    return <UntypedFormArray>this._myForm.get("contacts");
  }

  getTakuPayAccountEnabled() {
    this.takuPayAccountEnabled = true;
  }

  addCommercialAccountAddress(): UntypedFormGroup {
    if (!this.addressesCtrlArray) this._myForm.setControl("commercialAccountAddresses", this.fb.array([]));

    const qtyAddresses = this._addressMgr.filteredAddresses.length;
    const newAddress = CommercialAccountAddressComponent.init(
      this.fb,
      this._addressMgr.activeAddressFilter,
      qtyAddresses == 0
    );
    this.addressesCtrlArray.push(newAddress);
    this._addressTabIndex = qtyAddresses; // Make the new address active by default

    this._addressMgr.subscribeToggleDefaultChanges(newAddress);
    this._myForm.markAsDirty();

    return newAddress;
  }

  private _fillAccountAddress(addressControl: UntypedFormGroup, data: CommercialAccountAddress) {
    FormDataHelpers.clearModelIDs(data);
    addressControl.reset(data);
    addressControl.setControl("address", AddressComponent.set(this.fb, data.address));

    this._addressMgr.subscribeToggleDefaultChanges(addressControl);
    this._myForm.markAsDirty();
  }

  cloneCommercialAccountAddress() {
    // Take data from selected tab
    const addressData: CommercialAccountAddress = this._addressMgr.filteredAddresses[this._addressTabIndex].value;
    // Clone addresses are not Default unless changed manually
    addressData.address.isDefault = false;

    const newAddress = this.addCommercialAccountAddress();
    this._fillAccountAddress(newAddress, addressData);
  }

  changedTab(e: any) {
    this._addressTabIndex = e.index;
  }

  addCommercialAccountPhone() {
    if (!(this._myForm.controls["commercialAccountPhones"] instanceof UntypedFormArray)) {
      this._myForm.setControl("commercialAccountPhones", this.fb.array([]));
    }
    (<UntypedFormArray>this._myForm.controls["commercialAccountPhones"]).push(
      CommercialAccountPhoneComponent.init(this.fb)
    );
  }

  addCommercialAccountEmail() {
    if (!(this._myForm.controls["commercialAccountEmails"] instanceof UntypedFormArray)) {
      this._myForm.setControl("commercialAccountEmails", this.fb.array([]));
    }
    (<UntypedFormArray>this._myForm.controls["commercialAccountEmails"]).push(
      CommercialAccountEmailComponent.init(this.fb)
    );
  }

  addCommercialAccountInstantMsg() {
    if (!(this._myForm.controls["commercialAccountInstantMsgs"] instanceof UntypedFormArray)) {
      this._myForm.setControl("commercialAccountInstantMsgs", this.fb.array([]));
    }
    (<UntypedFormArray>this._myForm.controls["commercialAccountInstantMsgs"]).push(
      CommercialAccountInstantMsgComponent.init(this.fb)
    );
  }

  addCommercialAccountSocialProfile() {
    if (!(this._myForm.controls["commercialAccountSocialProfiles"] instanceof UntypedFormArray)) {
      this._myForm.setControl("commercialAccountSocialProfiles", this.fb.array([]));
    }
    (<UntypedFormArray>this._myForm.controls["commercialAccountSocialProfiles"]).push(
      CommercialAccountSocialProfileComponent.init(this.fb)
    );
  }

  addSupplierMinAmmount() {
    if (!(this._myForm.controls["commercialAccountSupplierDetails"] instanceof UntypedFormArray)) {
      this._myForm.setControl("commercialAccountSupplierDetails", this.fb.array([]));
    }
    (<UntypedFormArray>this._myForm.controls["commercialAccountSupplierDetails"]).push(
      CommercialAccountSupplierDetail.init(this.fb)
    );
    this.handleZonesFilled();
  }

  handleZonesFilled() {
    this.zonesFilled =
      this._myForm.get("commercialAccountSupplierDetails")["controls"].length === this.zones.length - 1;
  }

  // addContact() {
  //   if (!(this._myForm.controls['contacts'] instanceof FormArray)) {
  //     this._myForm.setControl('contacts', this.fb.array([]));
  //   }
  //   (<FormArray>this._myForm.controls['contacts']).push(ContactComponent.init(this.fb));
  // }

  private _deleteAddressTab(addressesArray: UntypedFormArray, index: number) {
    // const addressToRemove = addressesArray.at(index);
    // const defaultAddressPath = 'address.isDefault';

    addressesArray.removeAt(index);
    // when removing the active tab, select automatically the next one
    // if (this._addressTabIndex === index) {
    //   this._addressTabIndex = (this._addressTabIndex - 1) % addressesArray.length;
    // }
    this._addressTabIndex = 0;
    this._myForm.markAsDirty();

    // If this address was set as default, make the first the new default
    // if (addressToRemove.get(defaultAddressPath).value) {
    //   this._addressMgr.filteredAddresses[0].get(defaultAddressPath).setValue(true);
    // }
  }

  removeCommercialAccountAddress(event: any) {
    // if (this._addressMgr.filteredAddresses.length <= 1) {
    //   return;
    // }
    const addressToRemove = this._addressMgr.filteredAddresses[event.index];
    const index = this._addressMgr.findAddressPosition(addressToRemove);

    if (this.addressesCtrlArray.at(index).pristine) {
      this._deleteAddressTab(this.addressesCtrlArray, index);
      return;
    }

    this.confirmationService.confirm({
      message: "By deleting this Address you will lose your changes. Are you sure?",
      rejectButtonStyleClass: "p-button-link",

      accept: () => {
        this._deleteAddressTab(this.addressesCtrlArray, index);
      },
    });
  }

  removeCommercialAccountPhone(index: number) {
    const phonesArray: UntypedFormArray = <UntypedFormArray>this._myForm.get("commercialAccountPhones");
    if (phonesArray.at(index).pristine) {
      this._removeFormArrayChild(phonesArray, index);
      return;
    }

    this.confirmationService.confirm({
      message: "By deleting this Phone you will lose your changes. Are you sure?",
      rejectButtonStyleClass: "p-button-link",

      accept: () => {
        this._removeFormArrayChild(phonesArray, index);
      },
    });
  }

  removeCommercialAccountEmail(index: number) {
    const emailsArray: UntypedFormArray = <UntypedFormArray>this._myForm.get("commercialAccountEmails");
    if (emailsArray.at(index).pristine) {
      this._removeFormArrayChild(emailsArray, index);
      return;
    }

    this.confirmationService.confirm({
      message: "By deleting this Email you will lose your changes. Are you sure?",
      rejectButtonStyleClass: "p-button-link",

      accept: () => {
        this._removeFormArrayChild(emailsArray, index);
      },
    });
  }

  removeCommercialAccountInstantMsg(index: number) {
    const IMsArray: UntypedFormArray = <UntypedFormArray>this._myForm.get("commercialAccountInstantMsgs");
    if (IMsArray.at(index).pristine) {
      this._removeFormArrayChild(IMsArray, index);
      return;
    }

    this.confirmationService.confirm({
      message: "By deleting this IM you will lose your changes. Are you sure?",
      rejectButtonStyleClass: "p-button-link",

      accept: () => {
        this._removeFormArrayChild(IMsArray, index);
      },
    });
  }

  removeCommercialAccountSocialProfile(index: number) {
    const socialProfilesArray: UntypedFormArray = <UntypedFormArray>this._myForm.get("commercialAccountSocialProfiles");
    if (socialProfilesArray.at(index).pristine) {
      this._removeFormArrayChild(socialProfilesArray, index);
      return;
    }

    this.confirmationService.confirm({
      message: "By deleting this Social Profile you will lose your changes. Are you sure?",
      rejectButtonStyleClass: "p-button-link",

      accept: () => {
        this._removeFormArrayChild(socialProfilesArray, index);
      },
    });
  }

  cloneCommercialAddress() {}

  private _restoreContactForm() {
    const currentContact = this.contactsCtrlArray.at(this.rowIndex).value;
    this._tmpContactFormGroup = ContactComponent.set(this.fb, currentContact);
  }

  // Commercial Contact Dialog methods
  openAddContact() {
    this._tmpContactFormGroup = ContactComponent.init(this.fb, this._object.id);
    this.rowIndex = -1;
    this._showAddContact = !this._showAddContact;
  }

  closeContactSidebar() {
    // if (this._tmpContactFormGroup.dirty) {
    //   const closeSidebarMsg = 'You have unsaved changes. Are you sure you want to close this Contact form?';
    //   this._showConfirmationDialog(closeSidebarMsg).subscribe((result) => {
    //     if (result) { this._showAddContact = !this._showAddContact; }
    //   });
    // } else { // If changes havent made just hide sidebar
    //   this._showAddContact = !this._showAddContact;
    // }

    this._showAddContact = false;
  }

  private _showConfirmationDialog(message: string): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.confirmationService.confirm({
        message,
        rejectButtonStyleClass: "p-button-link",
        accept: () => {
          observer.next(true);
          observer.complete();
        },
        reject: () => {
          observer.next(false);
          observer.complete();
        },
      });
    });
  }

  deleteContact() {
    const index = this.rowIndex;
    if (this.contactsCtrlArray.at(index).pristine) {
      this._removeFormArrayChild(this.contactsCtrlArray, index);
      this._showAddContact = !this._showAddContact;
      return;
    }

    this.confirmationService.confirm({
      message: "By deleting this Contact you will lose your changes. Are you sure?",
      rejectButtonStyleClass: "p-button-link",

      accept: () => {
        this._removeFormArrayChild(this.contactsCtrlArray, index);
        this._showAddContact = !this._showAddContact;
      },
    });
  }

  // saveAndNew() {
  //   this.addContact();
  //   this.rowIndex = (<FormArray>this._myForm.get('contacts')).length - 1;
  // }

  saveContactAndClose() {
    const savedContact: Contact = this._tmpContactFormGroup.value;
    const contactForm = ContactComponent.set(this.fb, savedContact);

    if (this.rowIndex === -1)
      //new row
      this.contactsCtrlArray.push(contactForm);
    else {
      // Replace form in place
      this.contactsCtrlArray.removeAt(this.rowIndex);
      this.contactsCtrlArray.insert(this.rowIndex, contactForm);
    }

    this.contactsCtrlArray.markAsDirty();
    this._tmpContactFormGroup.markAsPristine();
    this.selectedContact = contactForm.value;
    this.closeContactSidebar();
  }

  selectContactRow(rowIndex: number) {
    // this.contactsInsertMode = false;
    this.rowIndex = rowIndex;
    this._restoreContactForm();
    this._showAddContact = !this._showAddContact;
  }

  revertContact(event: any) {
    if (this._tmpContactFormGroup.dirty) {
      const revertContactMsg = "You have unsaved changes. Are you sure you want to REVERT to the latest saved changes?";
      this.subsList.push(
        this._showConfirmationDialog(revertContactMsg).subscribe((result) => {
          if (result) {
            this._restoreContactForm();
          }
        })
      );
    } else {
      this._restoreContactForm();
    }
  }

  initValidation() {
    this._validation = this.dbService.getValidationRules();
  }

  onSave() {
    this.updateContactsFieldDefault();
    super.onSave();
  }

  // Prevent opening sidebar for selected contact
  selectedDefaultContact($event: Event) {
    $event.stopPropagation();
  }

  /**
   * Returns the index of first contact that is set as default
   */
  getDefaultContactIndex(): number {
    const contacts: UntypedFormArray = <UntypedFormArray>this._myForm.get("contacts");
    for (let i = 0; i < contacts.length; i++) {
      const theContact: UntypedFormGroup = <UntypedFormGroup>contacts.at(i);
      if (theContact.get("isDefault") && theContact.get("isDefault").value === true) {
        return i;
      }
    }

    return -1;
  }

  /**
   * Updates default flag for each contact
   */
  updateContactsFieldDefault() {
    const selectedIndex = parseInt(this._myForm.get("selectedContactIndex").value);
    const contacts: UntypedFormArray = <UntypedFormArray>this._myForm.get("contacts");

    for (let i = 0; i < contacts.length; i++) {
      const theContact: UntypedFormGroup = <UntypedFormGroup>contacts.at(i);
      if (i === selectedIndex) {
        theContact.patchValue({ isDefault: true });
      } else {
        theContact.patchValue({ isDefault: false });
      }
    }
  }

  getZones(): void {
    this.commercialAccountSupplierService
      .lookup_zones()
      .pipe(take(1))
      .subscribe((z) => {
        this.zones = z;
        this.handleZonesFilled();
      });
  }

  setNewZones(zones: any[]): void {
    this.zones = zones;
  }

  removeZoneMoa(index: number, item: Record<string, any>) {
    const formArray = this._myForm.get("commercialAccountSupplierDetails") as UntypedFormArray;
    const selectedZone = this.zones.find((f) => f.value === item.zoneId);
    if (selectedZone) selectedZone.disabled = false;
    formArray.removeAt(index);
    this.handleZonesFilled();
    this._myForm.markAsDirty();
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
