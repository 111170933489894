<taku-form-header
  [formGroup]="_myForm"
  (backClicked)="goBack()"
  (saveClicked)="onSave()"
  (revertClicked)="onRevert()"
  [toolbarTitle]="pageTitle"
  iconStyleClass="takuicon-measuring-tape"
  [showSecondaryBtns]="false"
></taku-form-header>
<div [formGroup]="_myForm">
  <p-tabView class="grid col-12 tabview-nopad">
    <p-tabPanel header="Store">
      <div class="col-12 tabpanel-main">
        <div class="invoice-preview-wrapper">
          <h3>{{ _pageTitlePrefix }} Preview</h3>
          <p-card class="grid col-12 invoice-preview">
            <!-- <img src="/assets/layout/images/settings/preview-tape-invoice.png" class="image-placeholder" /> -->
            <taku-receipt-tape-preview
              *ngIf="receiptDemoData"
              [saleDocData]="receiptDemoData.saledoc"
              [storeInfo]="_object.store"
              [builderSettings]="_myForm.value"
              [policies]="receiptDemoData.policies"
              [businessDetail]="receiptDemoData.businessDetail"
            ></taku-receipt-tape-preview>
          </p-card>
        </div>

        <div class="settings-columns">
          <h3>Builder Options</h3>
          <div class="grid">
            <p-card class="col-12 lg:col-6">
              <div class="col-12 inputctrl-wrapper">
                <!-- <label>Font Size: </label> -->
                <taku-dropdown
                  [formGroup]="_myForm"
                  fieldName="headerFontSize"
                  fieldCaption="Header Font Size"
                  [options]="lookup_font_size"
                ></taku-dropdown>
              </div>

              <div class="col-12 inputctrl-wrapper">
                <taku-toggle
                  [formGroup]="_myForm"
                  fieldName="printLogo"
                  fieldCaption="Logo"
                  enabledStateLabel="ON"
                  disabledStateLabel="OFF"
                ></taku-toggle>
              </div>

              <div class="col-12 inputctrl-wrapper">
                <taku-toggle
                  [formGroup]="_myForm"
                  fieldName="printStoreName"
                  fieldCaption="Store Name"
                  enabledStateLabel="ON"
                  disabledStateLabel="OFF"
                ></taku-toggle>
              </div>

              <div class="col-12 inputctrl-wrapper">
                <taku-toggle
                  [formGroup]="_myForm"
                  fieldName="printWebsite"
                  fieldCaption="Website"
                  enabledStateLabel="ON"
                  disabledStateLabel="OFF"
                ></taku-toggle>
              </div>

              <div class="col-12 inputctrl-wrapper">
                <taku-toggle
                  [formGroup]="_myForm"
                  fieldName="printEmail"
                  fieldCaption="Email"
                  enabledStateLabel="ON"
                  disabledStateLabel="OFF"
                ></taku-toggle>
              </div>

              <div class="col-12 inputctrl-wrapper">
                <taku-toggle
                  [formGroup]="_myForm"
                  fieldName="printPhoneNo"
                  fieldCaption="Phone Number"
                  enabledStateLabel="ON"
                  disabledStateLabel="OFF"
                ></taku-toggle>
              </div>

              <div class="col-12 inputctrl-wrapper">
                <taku-toggle
                  [formGroup]="_myForm"
                  fieldName="printAddress"
                  fieldCaption="Address"
                  enabledStateLabel="ON"
                  disabledStateLabel="OFF"
                ></taku-toggle>
              </div>
            </p-card>

            <p-card class="col-12 lg:col-6">
              <div class="col-12 inputctrl-wrapper">
                <i class="pi pi-lock locked-feature"></i>
                <taku-toggle
                  [formGroup]="_myForm"
                  fieldName="printStoreTaxNumber"
                  fieldCaption="Show Store Tax#"
                  enabledStateLabel="ON"
                  disabledStateLabel="OFF"
                  [disabled]="true"
                ></taku-toggle>
              </div>
              <div class="col-12 inputctrl-wrapper">
                <i class="pi pi-lock locked-feature"></i>
                <taku-toggle
                  [formGroup]="_myForm"
                  fieldName="printTaxNumber"
                  fieldCaption="Show Account Tax ID"
                  enabledStateLabel="ON"
                  disabledStateLabel="OFF"
                  [disabled]="true"
                ></taku-toggle>
              </div>

              <div class="col-12 inputctrl-wrapper">
                <i class="pi pi-lock locked-feature"></i>
                <taku-toggle
                  [formGroup]="_myForm"
                  fieldName="printBusinessHours"
                  fieldCaption="Show Business Hours"
                  enabledStateLabel="ON"
                  disabledStateLabel="OFF"
                  [disabled]="true"
                ></taku-toggle>
              </div>

              <div class="col-12 inputctrl-wrapper">
                <taku-toggle
                  [formGroup]="_myForm"
                  fieldName="printAccountInfo"
                  fieldCaption="Show Account Information"
                  enabledStateLabel="ON"
                  disabledStateLabel="OFF"
                ></taku-toggle>
              </div>

              <div class="col-12 inputctrl-wrapper">
                <i class="pi pi-lock locked-feature"></i>
                <taku-toggle
                  [formGroup]="_myForm"
                  fieldName="printSocialIcons"
                  fieldCaption="Show Social Icons"
                  enabledStateLabel="ON"
                  disabledStateLabel="OFF"
                  [disabled]="true"
                ></taku-toggle>
              </div>

              <div class="col-12 inputctrl-wrapper">
                <i class="pi pi-lock locked-feature"></i>
                <taku-toggle
                  [formGroup]="_myForm"
                  fieldName="printPoweredByTalulabs"
                  fieldCaption="Show 'Powered by TAKU'"
                  enabledStateLabel="ON"
                  disabledStateLabel="OFF"
                  [disabled]="true"
                ></taku-toggle>
              </div>
            </p-card>
          </div>
        </div>
      </div>
    </p-tabPanel>

    <p-tabPanel header="Transaction">
      <div class="col-12 tabpanel-main">
        <div class="invoice-preview-wrapper">
          <h3>{{ _pageTitlePrefix }} Preview</h3>
          <p-card class="grid col-12 invoice-preview">
            <taku-receipt-tape-preview
              *ngIf="receiptDemoData"
              [saleDocData]="receiptDemoData.saledoc"
              [storeInfo]="_object.store"
              [builderSettings]="_myForm.value"
              [policies]="receiptDemoData.policies"
              [businessDetail]="receiptDemoData.businessDetail"
            ></taku-receipt-tape-preview>
          </p-card>
        </div>

        <div class="settings-columns">
          <h3>Builder Options</h3>
          <div class="grid">
            <p-card class="col-12 lg:col-6">
              <div class="col-12 inputctrl-wrapper">
                <taku-dropdown
                  [formGroup]="_myForm"
                  fieldName="lineFontSize"
                  fieldCaption="Line Font Size"
                  [options]="lookup_font_size"
                ></taku-dropdown>
              </div>

              <div class="col-12 inputctrl-wrapper">
                <taku-dropdown
                  [formGroup]="_myForm"
                  fieldName="lineSortOrder"
                  fieldCaption="Sort by"
                  [options]="lookup_transaction_sorting"
                ></taku-dropdown>
              </div>

              <div class="col-12 inputctrl-wrapper">
                <taku-toggle
                  [formGroup]="_myForm"
                  fieldName="printDocumentNo"
                  fieldCaption="Doc #"
                  enabledStateLabel="ON"
                  disabledStateLabel="OFF"
                ></taku-toggle>
              </div>

              <div class="col-12 inputctrl-wrapper">
                <taku-toggle
                  [formGroup]="_myForm"
                  fieldName="printDate"
                  fieldCaption="Date"
                  enabledStateLabel="ON"
                  disabledStateLabel="OFF"
                ></taku-toggle>
              </div>

              <div class="col-12 inputctrl-wrapper">
                <!-- <i class="pi pi-lock locked-feature"></i> -->
                <taku-toggle
                  [formGroup]="_myForm"
                  fieldName="printBillingAddress"
                  fieldCaption="Billing Address"
                  enabledStateLabel="ON"
                  disabledStateLabel="OFF"
                ></taku-toggle>
              </div>

              <div class="col-12 inputctrl-wrapper">
                <!-- <i class="pi pi-lock locked-feature"></i> -->
                <taku-toggle
                  [formGroup]="_myForm"
                  fieldName="printShippingAddress"
                  fieldCaption="Shipping Address"
                  enabledStateLabel="ON"
                  disabledStateLabel="OFF"
                ></taku-toggle>
              </div>

              <div class="col-12 inputctrl-wrapper">
                <taku-input-textarea
                  [formGroup]="_myForm"
                  fieldName="customerNote"
                  fieldCaption="Custom Note"
                ></taku-input-textarea>
              </div>
            </p-card>

            <p-card class="col-12 lg:col-6">
              <div class="col-12 inputctrl-wrapper">
                <taku-toggle
                  [formGroup]="_myForm"
                  fieldName="printSummary"
                  fieldCaption="Show Line Items"
                  enabledStateLabel="ON"
                  disabledStateLabel="OFF"
                ></taku-toggle>
              </div>

              <div class="col-12 inputctrl-wrapper">
                <i class="pi pi-lock locked-feature"></i>
                <taku-toggle
                  [formGroup]="_myForm"
                  fieldName="printDescription2"
                  fieldCaption="Show Alt Product Title"
                  enabledStateLabel="ON"
                  disabledStateLabel="OFF"
                  [disabled]="true || !_myForm.get('printSummary').value"
                ></taku-toggle>
              </div>

              <div class="col-12 inputctrl-wrapper">
                <taku-toggle
                  [formGroup]="_myForm"
                  fieldName="printSKU"
                  fieldCaption="Show SKU"
                  enabledStateLabel="ON"
                  [disabled]="!_myForm.get('printSummary').value"
                  disabledStateLabel="OFF"
                ></taku-toggle>
              </div>

              <div class="col-12 inputctrl-wrapper">
                <!-- <i class="pi pi-lock locked-feature"></i> -->
                <taku-toggle
                  [formGroup]="_myForm"
                  fieldName="printVariants"
                  fieldCaption="Show Variants"
                  [disabled]="!_myForm.get('printSummary').value"
                  enabledStateLabel="ON"
                  disabledStateLabel="OFF"
                ></taku-toggle>
              </div>

              <div class="col-12 inputctrl-wrapper">
                <taku-toggle
                  [formGroup]="_myForm"
                  fieldName="printBarcodeNo"
                  fieldCaption="Show Barcode#"
                  [disabled]="!_myForm.get('printSummary').value"
                  enabledStateLabel="ON"
                  disabledStateLabel="OFF"
                ></taku-toggle>
              </div>

              <div class="col-12 inputctrl-wrapper">
                <taku-toggle
                  [formGroup]="_myForm"
                  fieldName="printOriginalPrice"
                  fieldCaption="Show Original Price"
                  [disabled]="!_myForm.get('printSummary').value"
                  enabledStateLabel="ON"
                  disabledStateLabel="OFF"
                ></taku-toggle>
              </div>

              <div class="col-12 inputctrl-wrapper">
                <taku-toggle
                  [formGroup]="_myForm"
                  fieldName="printTaxInventoryType"
                  fieldCaption="Show Tax Acronym"
                  [disabled]="!_myForm.get('printSummary').value"
                  enabledStateLabel="ON"
                  disabledStateLabel="OFF"
                ></taku-toggle>
              </div>

              <div class="col-12 inputctrl-wrapper">
                <!-- <i class="pi pi-lock locked-feature"></i> -->
                <taku-toggle
                  [formGroup]="_myForm"
                  fieldName="printCategoryInLine"
                  fieldCaption="Show Category in Line"
                  [disabled]="!_myForm.get('printSummary').value"
                  enabledStateLabel="ON"
                  disabledStateLabel="OFF"
                ></taku-toggle>
              </div>

              <div class="col-12 inputctrl-wrapper">
                <!-- <i class="pi pi-lock locked-feature"></i> -->
                <taku-toggle
                  [formGroup]="_myForm"
                  fieldName="printUofM"
                  fieldCaption="Show U of M"
                  enabledStateLabel="ON"
                  disabledStateLabel="OFF"
                  [disabled]="!_myForm.get('printSummary').value"
                ></taku-toggle>
              </div>
            </p-card>
          </div>
        </div>
      </div>
    </p-tabPanel>

    <p-tabPanel header="Payment">
      <div class="col-12 tabpanel-main">
        <div class="invoice-preview-wrapper">
          <h3>{{ _pageTitlePrefix }} Preview</h3>
          <p-card class="grid col-12 invoice-preview">
            <taku-receipt-tape-preview
              *ngIf="receiptDemoData"
              [saleDocData]="receiptDemoData.saledoc"
              [storeInfo]="_object.store"
              [builderSettings]="_myForm.value"
              [policies]="receiptDemoData.policies"
              [businessDetail]="receiptDemoData.businessDetail"
            ></taku-receipt-tape-preview>
          </p-card>
        </div>

        <div class="settings-columns">
          <h3>Builder Options</h3>
          <p-card class="col-12 lg:col-6">
            <div class="col-12 inputctrl-wrapper">
              <i class="pi pi-lock locked-feature"></i>
              <taku-toggle
                [formGroup]="_myForm"
                fieldName="printTaxSummary"
                fieldCaption="Tax Summary"
                enabledStateLabel="ON"
                disabledStateLabel="OFF"
                [disabled]="true"
              ></taku-toggle>
            </div>

            <div class="col-12 inputctrl-wrapper">
              <taku-toggle
                [formGroup]="_myForm"
                fieldName="printTenderDetail"
                fieldCaption="Tender Details"
                enabledStateLabel="ON"
                disabledStateLabel="OFF"
              ></taku-toggle>
            </div>

            <div class="col-12 inputctrl-wrapper">
              <taku-toggle
                [formGroup]="_myForm"
                fieldName="printPennyRounding"
                fieldCaption="Show Cash Rounding"
                enabledStateLabel="ON"
                disabledStateLabel="OFF"
              ></taku-toggle>
            </div>

            <div class="col-12 inputctrl-wrapper">
              <i class="pi pi-lock locked-feature"></i>
              <taku-toggle
                [formGroup]="_myForm"
                fieldName="printTransactionRecord"
                fieldCaption="Show Transaction Record"
                enabledStateLabel="ON"
                disabledStateLabel="OFF"
                [disabled]="true"
              ></taku-toggle>
            </div>
          </p-card>

          <!-- <p-card class="grid col-12 lg:col-6">
              </p-card> -->
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>
  <!-- </p-panel> -->
</div>
