/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, ElementRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { DBService } from "src/app/shared/services/db.service";
import { WebHelpers } from "src/app/utility/WebHelpers";
import { environment } from "src/environments/environment";
import { SaleDoc } from "../../document/sale-document/sale-doc/sale-doc";
import {
  OrderStatus,
  SaleChannelDeliveryStatus,
  SaleChannelFulfillmentStatus,
  SaleChannelOrder,
  SaleChannelPaymentStatus,
} from "../../sale-channel/sale-channel-orders/sale-channel-order";
import { AllUpcomingOrdersService } from "./../all-upcoming-orders/all-upcoming-orders.service";
import { SaleChannelOrdersService } from "../../sale-channel/sale-channel-orders/sale-channel-orders.service";

@Component({
  selector: "taku-all-upcoming-order-preview",
  templateUrl: "./all-upcoming-order-preview.component.html",
  styleUrls: ["./all-upcoming-order-preview.component.scss"],
  providers: [DialogService],
})
export class AllUpcomingOrderPreviewComponent {
  _saleChannelOrder: SaleChannelOrder;
  _headerTitle: string;
  _saleChannelOrderDetail = {};
  _isVoidDisabled = true;
  _isProceedDisabled = true;
  _saleDoc: SaleDoc;
  protected webUrl = environment.apiUrl;
  _isLoading = false;

  @ViewChild("saleLines", { static: true }) saleLinesWrapper: ElementRef;

  constructor(
    public config: DynamicDialogConfig,
    private dbService: DBService,
    private confirmationService: ConfirmationService,
    private allUpcomingOrdersService: AllUpcomingOrdersService,
    protected messageService: MessageService,
    private appSettingsService: AppSettingsStorageService,
    private saleChannelOrdersService: SaleChannelOrdersService,
    public ref: DynamicDialogRef,
    private _router: Router
  ) {
    this._saleChannelOrder = this.config.data._saleChannelOrder;
    this._headerTitle = `<span> ${this._saleChannelOrder.docNo} - Online Order (Preview)</span>`;
  }

  ngOnInit(): void {
    this.dbService
      ._postRequest(
        `${this.webUrl}TAKUeCommerceOrderDetail/${this._saleChannelOrder.saleChannel.id}/${this._saleChannelOrder.docNo}`,
        {
          stationId: this.appSettingsService.getStationId(),
          storeId: this._saleChannelOrder.storeId,
          cashoutId: this.appSettingsService.getCashoutId(),
          fiscalYearId: this.appSettingsService.getFiscalYearId(),
        }
      )
      .subscribe((result: any) => {
        this._saleChannelOrderDetail = result;
        this._saleDoc = new SaleDoc();
        Object.assign(this._saleDoc, result.saleDoc);
        // this._saledoc.doc.currencyIsoCode =
        // saleDoc.subTotal
        // saleDoc.discountAmountMoney
        // saleDoc.groupedTaxLines
        // saleDoc.totalTax
        // saleDoc.shipperChargeAmount
        // saleDoc.totalPayments
        // saleDoc.grandTotal
        // this.saledoc.docLines
        this._isProceedDisabled = this._saleDoc.groupedTaxLines.findIndex((row) => row.saleTaxRule === null) !== -1;
      });
    this._isVoidDisabled =
      this._saleChannelOrder.orderStatus !== OrderStatus.new ||
      ![SaleChannelPaymentStatus.unpaid, SaleChannelPaymentStatus.paid, SaleChannelPaymentStatus.partlyPaid].includes(
        this._saleChannelOrder.paymentStatus
      ) ||
      this._saleChannelOrder.fulfillmentStatus === SaleChannelFulfillmentStatus.completed;
  }

  get _isMobile() {
    return WebHelpers.isMobileScreen();
  }

  get hasScrollbars() {
    const linesEl = this.saleLinesWrapper.nativeElement;
    return linesEl.scrollHeight > linesEl.clientHeight;
  }

  onClosePressed() {
    // this.dialogClosed.emit(this.saleDoc);
    // if (this._saleChannelOrder) {
    //   this.sllUpcomingOrdersService.ref.close(this._saleChannelOrder);
    // } else {
    if (this.ref) {
      this.ref.close();
    }
    // }
  }

  onVoidPressed() {
    let _message = "";
    if (
      this._saleChannelOrder.orderStatus === OrderStatus.new &&
      this._saleChannelOrder.paymentStatus === SaleChannelPaymentStatus.unpaid
    ) {
      _message = `By clicking Yes below, you will be VOIDING this online order. This is final and cannot be reversed.

      Are you sure you want to proceed?`;
    }
    if (
      this._saleChannelOrder.orderStatus === OrderStatus.new &&
      (this._saleChannelOrder.paymentStatus === SaleChannelPaymentStatus.paid ||
        this._saleChannelOrder.paymentStatus === SaleChannelPaymentStatus.partlyPaid)
    ) {
      _message = `By clicking Yes below, you will be VOIDING this online order. This is final and cannot be reversed.

      Please note that this order is already PAID and you will need to refund any payments already received. After voiding, this order will be marked as Pending Refund until you Mark as Refunded.

      Are you sure you want to proceed?`;
    }
    this.confirmationService.confirm({
      header: "Void Confirmation",
      message: `By Clicking Yes below, you will be VOIDING this online order. It may be necessary for you to refund any payment already received.
      Are you sure you want to proceed?`,
      accept: () => {
        // send API request to change Ecwid PS to Cancelled and FS to Will Not Deliver
        this.saleChannelOrdersService
          .editSaleChannelOrder(this._saleChannelOrder, {
            paymentStatus: "CANCELLED",
            fulfillmentStatus: "WILL_NOT_DELIVER",
          })
          .subscribe((result) => {
            this._saleChannelOrder.orderStatus =
              this._saleChannelOrder.paymentStatus === SaleChannelPaymentStatus.unpaid
                ? OrderStatus.voided
                : OrderStatus.pendingRefund;
            this._saleChannelOrder.fulfillmentStatus = SaleChannelFulfillmentStatus.voided;
            this._saleChannelOrder.paymentStatus = SaleChannelPaymentStatus.voided;
            this._saleChannelOrder.deliveryStatus = SaleChannelDeliveryStatus.voided;
            this.allUpcomingOrdersService.editRow("saleChannelOrder", this._saleChannelOrder).subscribe((result) => {
              // close the dialog in back
              this.allUpcomingOrdersService.ref.close(this._saleChannelOrder);
            });
          });
      },
    });
  }

  onProceedPressed() {
    this._isLoading = true;
    if (this.appSettingsService.getStationId()) {
      const cashout = this.appSettingsService.getCashout();
      if (!cashout || cashout.isClosed) {
        // show confirmation message for forwarding to cashout
        this.confirmationService.confirm({
          header: "Register is Currently Closed",
          message: `Please open it first in order to Proceed.`,
          acceptLabel: "OPEN REGISTER",
          rejectVisible: false,
          acceptVisible: true,
          accept: () => {
            this._isLoading = false;
            this.allUpcomingOrdersService.ref.close();
            this._router.navigate(["/sell/cashout", 0, "saleChannelOrder", this.appSettingsService.getStoreId()]);
          },
          reject: () => {
            this._isLoading = false;
          },
        });
      } else {
        this.saleChannelOrdersService.acceptSaleChannelOrder(this._saleChannelOrder).subscribe(
          (_saleDoc: SaleDoc) => {
            if (_saleDoc) {
              this._saleChannelOrder.saleDocId = _saleDoc.id;
              this._saleChannelOrder.saleDoc = _saleDoc;
              this._saleChannelOrder.orderStatus = OrderStatus.accepted;
            }
            this.allUpcomingOrdersService.editRow("saleChannelOrder", this._saleChannelOrder).subscribe((result) => {
              // this._saleChannelOrder = result;
              this._isLoading = false;
              this.allUpcomingOrdersService.ref.close(this._saleChannelOrder);

              // this.dbService.getRow('saleDoc',this._saleChannelOrder.saleDocId).subscribe((_saleDoc)=>{
              //   this.sllUpcomingOrdersService.ref = this.dialogService.open(SaledocRevisitPreviewComponent, {
              //     data: {
              //       _object: _saleDoc,
              //       _saleChannelOrder: this._saleChannelOrder
              //     },
              //     width: '850px',
              //     height: '650px',
              //   });
              // });
            });
          },
          (err) => {
            this.messageService.add({
              severity: "error",
              summary: "Accepting Order Error",
              detail: err.error.message,
            });
            this._isLoading = false;
          }
        );
      }
    } else {
      this.messageService.add({
        severity: "error",
        summary: "Notification",
        detail: "To accept an order, please log in to a Store.",
      });
      this._isLoading = false;
    }
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
