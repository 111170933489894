/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, OnDestroy } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { SelectItem } from "primeng/api/selectitem";
import { DBService } from "src/app/shared/services/db.service";
import { shareReplay } from "rxjs/operators";
import { ReportParamsService } from "../report-params.service";

@Component({
  selector: "taku-reports-params-takupay-accounts",
  templateUrl: "./reports-params-takupay-accounts.component.html",
  styleUrls: ["./reports-params-takupay-accounts.component.scss"],
})
export class ReportsParamsTAKUPayAccountsComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];
  formGroup = null;
  lookupTAKUPayAccounts$: Observable<SelectItem[]>;

  constructor(private dbService: DBService, private reportParamsService: ReportParamsService) {}

  ngOnInit(): void {
    this.lookupTAKUPayAccounts$ = this.dbService
      .lookupSelectOptions("takuPaymentAccount", "name", {
        enableFieldName: "isActive",
      })
      .pipe(shareReplay(1));
    setTimeout(() => {
      this.formGroup.get("takuPaymentAccountId").setValue(0);
    }, 0);
    this.subsList.push(
      this.formGroup.valueChanges.subscribe((newValue) => {
        this.reportParamsService.setReportParam(newValue);
      })
    );
  }

  get selectedTakuPaymentAccountCtrl() {
    return this.formGroup.get("takuPaymentAccountId");
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
