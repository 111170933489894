import { LocalDataService } from "src/app/shared/services/local-data.service";
/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { SaleAnalysisSummaryReportLocalDataService } from "./sale-analysis-summary-report-local-data.service";
import { Subscription } from "rxjs";
import { FormListComponent } from "src/app/form-list/form-list/form-list.component";
import { RowSelectionType } from "src/app/form-list/form-list/form-list";
import {
  SaleAnalysisSummaryReportCols,
  SaleAnalysisSummaryReportColsFactoryService,
} from "./sale-analysis-summary-report-cols.service";
import { MessageService } from "primeng/api";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";
import { ReportParamsService } from "../../report-params.service";
import { ReportFilterFields } from "../../report-filter-fields";
import { SaleAnalysisSummaryReport } from "./sale-analysis-summary-report";
import { resetReportRowState } from "../../report";

@Component({
  selector: "taku-sales-alanysis-summary-report",
  templateUrl: "./sales-alanysis-summary-report.component.html",
  styleUrls: ["./sales-alanysis-summary-report.component.scss"],
  providers: [SaleAnalysisSummaryReportLocalDataService],
})
export class SalesAlanysisSummaryReportComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];
  @ViewChild("formListReport") formListComponent: FormListComponent;

  _model = "saleAnalysisSummaryReport";
  _stateKey = "FL-" + this._model;
  RowSelectionType = RowSelectionType;
  public reportCols: SaleAnalysisSummaryReportCols;
  dynamicFields: string[] = [];

  constructor(
    private reportColsFactoryService: SaleAnalysisSummaryReportColsFactoryService,
    public saleReportLocalService: SaleAnalysisSummaryReportLocalDataService,
    private messageService: MessageService,
    private alertMessagesService: AlertMessagesService,
    public reportParamsService: ReportParamsService,
    private localDataService: LocalDataService
  ) {
    this.reportCols = this.reportColsFactoryService.build([]);
  }

  ngOnInit() {
    this.subsList.push(
      this.reportParamsService.reportParamsChanged$.subscribe((filters: ReportFilterFields) => {
        this.updateReport(filters);

        resetReportRowState(this._stateKey);
      })
    );
  }

  private updateReport(filters: ReportFilterFields) {
    this.reportParamsService._isFetchingData = true;
    this.subsList.push(
      this.saleReportLocalService
        .getSaleAnalysisSummaryReport(filters.storeId, filters.stationId, filters.dateFrom, filters.dateTo, 0, 0)
        .subscribe({
          next: (rows) => {
            this.buildFormlistCols(rows && rows.length ? rows[0] : null);
            this.refreshFormList(rows || []);
            this.reportParamsService._isFetchingData = false;
            this.localDataService.updateNewObject(rows);
          },
          error: (error) => {
            this.buildFormlistCols(null);
            // Show toast with error message
            this.messageService.add(this.alertMessagesService.getErrorMessage(error));
            // Empty form list
            this.refreshFormList([]);
            this.reportParamsService._isFetchingData = false;
          },
        })
    );
  }

  private buildFormlistCols(rowData: SaleAnalysisSummaryReport) {
    this.dynamicFields = rowData
      ? Object.keys(rowData).filter((key) => !Object.keys(new SaleAnalysisSummaryReport()).includes(key))
      : [];
    this.reportCols = this.reportColsFactoryService.build(this.dynamicFields);
  }

  private refreshFormList(data: any[]) {
    data.forEach((saleAnalysisSummary) => {
      const saleAnalysisSummaryReport = Object.assign(new SaleAnalysisSummaryReport(), saleAnalysisSummary);
      // This makes store getter result into plain property
      saleAnalysisSummary.totalAmountCalculated = saleAnalysisSummaryReport.totalAmount;
      saleAnalysisSummary.marginPercentCalculated = saleAnalysisSummaryReport.marginPercent;
      saleAnalysisSummary.marginAmountCalculated = saleAnalysisSummaryReport.marginAmount;

      this.dynamicFields.forEach((key) => {
        saleAnalysisSummary[key] = parseFloat(saleAnalysisSummary[key]) || 0;
      });
    });
    this.saleReportLocalService.initialize(data, this._model);
    setTimeout(() => {
      this.formListComponent.reloadData();
    }, 0);
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
