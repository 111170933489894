/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Location } from "@angular/common";
import { Component, OnInit, ViewChild, TemplateRef, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Col, TemplateDatatypeOptions } from "src/app/form-list/form-list/form-list";
import { TakuPaymentGatewayTerminalsService } from "./taku-payment-gateway-terminals.service";
import {
  TakuPaymentTerminal,
  RequestPairTerminal,
  ResponseCloseBatch,
  ResponsePairTerminal,
  ResponseUnpairTerminal,
} from "./taku-payment-terminal";
import { RowsSavedEvent } from "src/app/form-list/form-list/form-list.component";
import { mergeMap, tap, catchError, shareReplay } from "rxjs/operators";
import { PaymentGateway, PaymentGatewayType, PaymentGatewayTypes } from "../../payment-gateways/payment-gateway";
import { of, throwError, Subscription, Observable, merge } from "rxjs";
import { ConfirmationService, MessageService } from "primeng/api";
import { HttpErrorResponse } from "@angular/common/http";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";
import { EconduitService } from "../../econduit-terminal/econduit.service";
import { MonerisService } from "../../moneris-terminal/moneris.service";
import { TakuPaymentGateway } from "../taku-payment-gateways-list/taku-payment-gateway";
import { TakuPayService } from "../taku-pay.service";
import { GenericFormComponent } from "src/app/forms/generic-form/generic-form.component";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { UntypedFormBuilder } from "@angular/forms";

@Component({
  selector: "taku-taku-payment-gateway-terminals",
  templateUrl: "./taku-payment-gateway-terminals.component.html",
  styleUrls: ["./taku-payment-gateway-terminals.component.scss"],
  providers: [RequestPairTerminal],
})
export class TakuPaymentGatewayTerminalsComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];

  @ViewChild("terminalPairingTpl", { static: true }) terminalPairingTpl: TemplateRef<any>;

  _formListFilter = {};
  _formListCols: Col[];
  _formListModel = "takuPaymentTerminal";
  _formListDefaultRowValues: { takuPaymentGatewayId: any };
  _pageTitle = "TAKU Pay Terminals";
  confirmDialogKey = "takuPaymentTerminal-deleteRows";

  constructor(
    protected _router: Router,
    public fb: UntypedFormBuilder,
    public location: Location,
    private route: ActivatedRoute,
    public dbService: TakuPaymentGatewayTerminalsService,
    protected confirmationService: ConfirmationService,
    public messageService: MessageService,
    private alertMessagesService: AlertMessagesService,
    private econduitService: EconduitService,
    private monerisService: MonerisService,
    private takuPayService: TakuPayService
  ) {}

  get terminalRout() {
    return "takupay/takuPaymentTerminal";
  }

  ngOnInit() {
    this.subsList.push(
      this.route.params.subscribe((params) => {
        const takuPaymentGatewayId = params.paymentGatewayId;
        const gatewayStoreId = params.gatewayStoreId;
        if (takuPaymentGatewayId) {
          this._formListFilter["takuPaymentGateway.id"] = {
            matchMode: "equals",
            value: takuPaymentGatewayId,
          };
          this._formListDefaultRowValues = {
            takuPaymentGatewayId,
          };
        }

        if (gatewayStoreId)
          this._formListCols = this.dbService.getFormListColumns(
            gatewayStoreId,
            new TemplateDatatypeOptions({
              templateRef: this.terminalPairingTpl,
            })
          );
      })
    );
  }

  onColPairBtnClicked(terminal: TakuPaymentTerminal) {
    if (terminal.isPaired) {
      this.subsList.push(
        this.econduitService.unpairTerminal(terminal.id).subscribe({
          next: (response) => {
            const responseUnPairTerminal: ResponseUnpairTerminal = Object.assign({}, response);
            if (response && responseUnPairTerminal.Status === "Success") {
              terminal.isPaired = false;
              this.messageService.add(this.alertMessagesService.getMessage("econduit-unpair-terminal-success"));
            } else {
              this.messageService.add({
                summary: "Error",
                severity: "error",
                detail: `${responseUnPairTerminal.ErrorMessage}`,
                life: 6000,
              });
            }
          },
          error: (errorResponse) => {
            if (errorResponse instanceof HttpErrorResponse && errorResponse.status !== 500) {
              if (errorResponse.error.error && errorResponse.error.error.message) {
                const errorMsg = errorResponse.error.error.message as string;
                this.messageService.add(
                  this.alertMessagesService.getErrorMessage(
                    null,
                    "ERROR",
                    `${errorMsg}\nPlease make another VERIFICATION REQUEST.`
                  )
                );
              }
            } else {
              this.messageService.add(this.alertMessagesService.getErrorMessage(errorResponse));
            }
          },
        })
      );
    } else {
      this.subsList.push(
        this.econduitService.pairTerminal(terminal.id).subscribe({
          next: (response) => {
            const responsePairTerminal: ResponsePairTerminal = Object.assign({}, response);
            if (response && responsePairTerminal.Status === "Success") {
              terminal.isPaired = true;
              this.messageService.add(this.alertMessagesService.getMessage("econduit-pair-terminal-success"));
            } else {
              this.messageService.add({
                summary: "Error",
                severity: "error",
                detail: `${responsePairTerminal.ErrorMessage}`,
                life: 6000,
              });
            }
          },
          error: (errorResponse) => {
            if (errorResponse instanceof HttpErrorResponse && errorResponse.status !== 500) {
              if (errorResponse.error.error && errorResponse.error.error.message) {
                const errorMsg = errorResponse.error.error.message as string;
                this.messageService.add(
                  this.alertMessagesService.getErrorMessage(
                    null,
                    "ERROR",
                    `${errorMsg}\nPlease make another VERIFICATION REQUEST.`
                  )
                );
              }
            } else {
              this.messageService.add(this.alertMessagesService.getErrorMessage(errorResponse));
            }
          },
        })
      );
    }
  }

  onColSettleBtnClicked(terminal: TakuPaymentTerminal) {
    if (terminal.isPaired) {
      let paymentService;
      switch (terminal.takuPaymentGateway.paymentGatewayType) {
        case PaymentGatewayType.PAYROC:
          paymentService = this.econduitService;
          break;
        case PaymentGatewayType.MONERIS:
        case "takuPaymentGateway":
          paymentService = this.monerisService;
          break;
      }
      if (paymentService) {
        paymentService.closeBatch(terminal.id).subscribe({
          next: (response) => {
            const responseCloseBatch: ResponseCloseBatch = Object.assign({}, response);
            if (
              response &&
              responseCloseBatch.RefID !== null &&
              responseCloseBatch.MessageCredit === this.econduitService.sMessageCloseBatchApproved
            ) {
              this.messageService.add(this.alertMessagesService.getMessage("econduit-close-batch-success"));
            } else {
              this.messageService.add({
                summary: "Error",
                severity: "error",
                detail: `${responseCloseBatch.MessageCredit}`,
                life: 6000,
              });
            }
          },
          error: (errorResponse) => {
            if (errorResponse instanceof HttpErrorResponse && errorResponse.status !== 500) {
              if (errorResponse.error.error && errorResponse.error.error.message) {
                const errorMsg = <string>errorResponse.error.error.message;
                this.messageService.add(
                  this.alertMessagesService.getErrorMessage(
                    null,
                    "ERROR",
                    `${errorMsg}\nPlease make another VERIFICATION REQUEST.`
                  )
                );
              } else {
                const errorMsg = errorResponse.message;
                this.messageService.add(
                  this.alertMessagesService.getErrorMessage(
                    null,
                    "ERROR",
                    `${errorMsg}\nPlease make another VERIFICATION REQUEST.`
                  )
                );
              }
            } else {
              this.messageService.add(this.alertMessagesService.getErrorMessage(errorResponse));
            }
          },
        });
      }
    } else {
      this.messageService.add(this.alertMessagesService.getMessage("econduit-close-batch-need-pair-terminal"));
    }
  }

  goBack() {
    this.location.back();
  }

  onPaymentTerminalsSaved(event: RowsSavedEvent) {
    const activeTerminals = (event.newValues as TakuPaymentTerminal[]).filter((terminal) => terminal.isActive);
    if (activeTerminals.length) {
      // If there is any active terminal, we should make sure its Gateway is active as well
      const paymentGatewayId = activeTerminals[0].takuPaymentGatewayId;
      this.subsList.push(
        this.dbService
          .getRow<TakuPaymentGateway>("takuPaymentGateway", paymentGatewayId)
          .pipe(
            shareReplay(1),
            mergeMap((gateway: TakuPaymentGateway) => {
              if (gateway.isActive) return of(gateway);
              else {
                // If associated payment gateway is found disabled
                gateway.isActive = true;
                // Enable Payment Gateway
                return this.dbService.editRow("takuPaymentGateway", gateway).pipe(
                  tap((_gateway: TakuPaymentGateway) => {
                    this.messageService.add({
                      summary: "Payment Gateway updated",
                      severity: "success",
                      detail: `Payment Gateway '${_gateway.paymentGatewayType}' enabled`,
                    });
                  }),
                  catchError((error) => {
                    this.messageService.add({
                      summary: "Error",
                      severity: "error",
                      detail: `Couldn't enable Payment Gateway '${gateway.paymentGatewayType}'`,
                    });

                    return throwError(error);
                  })
                );
              }
            })
          )
          .subscribe({
            next: (gateway) => {},
            error: (error) => {},
          })
      );
    }
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }

  beforeDeleteRows(selectedRows: any[]): Observable<boolean> {
    return of(false);
  }

  checkIfCanDeleteDialog = (rowsToDelete: TakuPaymentTerminal[]): Observable<any> => {
    return new Observable((observer) => {
      const notDeletableTerminalIds: number[] = [];
      this.subsList.push(
        merge(
          ...rowsToDelete.map((terminal) => {
            if (terminal.terminalId) {
              return of(terminal);
            } else {
              return of(null);
            }
          })
        ).subscribe({
          next: (response) => {
            //if (response)
            //notDeletableTerminalIds.push(response.id)
          },
          complete: () => {
            rowsToDelete.forEach((row) => {
              if (row.id > 0 && row.terminalId) {
                this.removeTakuPayTerminal(row.id);
              }
            });

            if (!notDeletableTerminalIds.length) {
              observer.next(rowsToDelete);
              observer.complete();
              return;
            }

            // Exclude from the selected rows, those which cannot be deleted
            rowsToDelete = rowsToDelete.filter((terminal) => !notDeletableTerminalIds.includes(terminal.id));
            observer.next(rowsToDelete);
            observer.complete();

            this.confirmationService.confirm({
              key: this.confirmDialogKey,
              header: "Some records couldn't be deleted",
              message: "In order to delete a Payment Gateway, you first need to delete all their configured Terminals.",
              acceptLabel: "OK",
              rejectVisible: false,
            });
          },
        })
      );
    });
  };

  removeTakuPayTerminal(id: number) {
    this.takuPayService
      .unPairTerminal(id)
      .pipe(
        catchError((errorResponse) => {
          return throwError(errorResponse);
        }),
        tap((result) => {
          if (result) {
            //this.messageService.add({ severity: 'success', summary: 'Service Message', detail: result.message });
          }
        })
      )
      .subscribe(() => {});
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
