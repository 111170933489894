/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, OnDestroy } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators, ValidatorFn } from "@angular/forms";
import * as _ from "lodash";
import { ConfirmationService, SelectItem } from "primeng/api";
import { isObservable, Observable, of, Subscription } from "rxjs";
import { pairwise, startWith, map } from "rxjs/operators";
import { DBService } from "src/app/shared/services/db.service";
import { MonetaryHelpers } from "src/app/utility/MonetaryHelpers";
import { Col, DataType } from "../form-list/form-list";
import { FormListColumnsService } from "src/app/form-list/form-list-columns.service";

class ObjectEditActions {
  constructor(private object: any, private form: UntypedFormGroup, private col: Col) {}

  private isPartialMatch(val, searchValue, matchLetterCaseValue: boolean) {
    if (val && typeof val.valueOf() === "string")
      return matchLetterCaseValue ? val.includes(searchValue) : val.toLowerCase().includes(searchValue.toLowerCase());
    else if (this.isMultiselectType)
      // means controls is a multiselect, we should compare item by item
      return this.findElementInMultiselect(this.fieldValue, searchValue) !== -1;
    else return this.isFullMatch(val, searchValue, matchLetterCaseValue);
  }

  private findElementInMultiselect(multiselectItems: any[], searchItem: any): number {
    for (let i = 0; i < multiselectItems.length; i++) {
      if (this.isFullMatch(multiselectItems[i], searchItem)) return i;
    }

    return -1;
  }

  private get isMultiselectType() {
    return [DataType.multiselect, DataType.enumMultiselect].includes(this.col.dataType);
  }

  private get isNumericType() {
    return [DataType.number].includes(this.col.dataType);
  }

  private isFullMatch(val, searchValue, matchLetterCaseValue?: boolean) {
    if (_.has(val, "id") && _.has(searchValue, "id")) return val.id === searchValue.id;
    else return matchLetterCaseValue ? val === searchValue : val.toLowerCase() === searchValue.toLowerCase();
  }

  private calculateDelta(amount: string, adjustmentType: QtyAdjustmentType): number {
    switch (adjustmentType) {
      case QtyAdjustmentType.Fixed:
        return parseFloat(amount);
      case QtyAdjustmentType.Percentage:
        return (parseFloat(this.fieldValue) * parseFloat(amount)) / 100;
    }
  }

  clear() {
    this.setColValue(null);
  }

  resetDefaults() {
    const defaultObj = new this.object.constructor();
    const defaultVal = _.get(defaultObj, this.col.field);
    this.setColValue(defaultVal);
  }

  replaceWith(newValue) {
    this.setColValue(newValue);
  }

  private setColValue(newValue) {
    _.set(this.object, this.col.field, newValue);
    let formCtrl: AbstractControl;
    if ((formCtrl = this.form.controls[this.col.field])) {
      formCtrl.setValue(newValue);
      formCtrl.markAsDirty();
    }
  }

  clearIfMatches(searchValue, matchLetterCaseValue: boolean) {
    if (
      (this.isNumericType &&
        !this.isFullMatch(_.toNumber(this.fieldValue), _.toNumber(searchValue), matchLetterCaseValue)) ||
      (!this.isNumericType && !this.isPartialMatch(this.fieldValue, searchValue, matchLetterCaseValue))
    )
      return;

    if (this.isMultiselectType) {
      // we should replace only the matching item
      const val = this.fieldValue;
      const index = this.findElementInMultiselect(val, searchValue);
      if (index >= 0) delete val[index];
      this.setColValue(val);
    } else {
      this.clear();
    }
  }

  replaceIfContains(searchValue, matchLetterCaseValue: boolean, newValue) {
    // If it doesn't match, do nothing
    if (!this.isPartialMatch(this.fieldValue, searchValue, matchLetterCaseValue)) return;

    const val = this.fieldValue;
    if (val && typeof val.valueOf() === "string")
      newValue = val.replace(new RegExp(_.escapeRegExp(searchValue), matchLetterCaseValue ? "g" : "gi"), newValue);
    else if (this.isMultiselectType) {
      // we should replace only the matching item
      const index = this.findElementInMultiselect(val, searchValue);
      if (index >= 0) val[index] = newValue;
      newValue = val;
    }

    this.setColValue(newValue);
  }

  replaceAllIfContains(searchValue, matchLetterCaseValue: boolean, newValue) {
    // Completely replace content if it matches
    if (this.isPartialMatch(this.fieldValue, searchValue, matchLetterCaseValue)) this.setColValue(newValue);
  }

  fullMatchReplace(searchValue, matchLetterCaseValue: boolean, newValue) {
    if (this.isFullMatch(this.fieldValue, searchValue, matchLetterCaseValue)) this.setColValue(newValue);
  }

  copyFromCol(srcCol: Col) {
    const newValue = _.get(this.object, srcCol.field);
    this.setColValue(newValue);
  }

  changeCase(caseOption: LetterCaseOption) {
    let newVal = this.fieldValue;
    switch (caseOption) {
      case LetterCaseOption.ALL_CAPS:
        newVal = _.upperCase(newVal);
        break;
      case LetterCaseOption.CAPITALIZE:
        newVal = _.capitalize(newVal);
        break;
      case LetterCaseOption.LOWERCASE:
        newVal = _.lowerCase(newVal);
        break;
      case LetterCaseOption.SENTENCE_CASE:
        newVal = _.startCase(newVal);
        break;
    }
    this.setColValue(newVal);
  }

  addBefore(prefix: string) {
    const val = this.fieldValue;
    if (val && typeof val.valueOf() === "string") {
      // only has effect for strings
      this.setColValue(`${prefix}${val}`);
    }
  }

  addAfter(suffix: string) {
    const val = this.fieldValue;
    if (val && typeof val.valueOf() === "string") {
      // only has effect for strings
      this.setColValue(`${val}${suffix}`);
    }
  }

  increaseValue(amount: string, adjustmentType: QtyAdjustmentType, rounding: string) {
    const delta = this.calculateDelta(amount, adjustmentType);
    this.changeValue(delta, rounding);
  }

  decreaseValue(amount: string, adjustmentType: QtyAdjustmentType, rounding: string) {
    const delta = -1 * this.calculateDelta(amount, adjustmentType);
    this.changeValue(delta, rounding);
  }

  addItemsToList(items: any[]) {
    let val = this.fieldValue;
    if (!Array.isArray(val)) val = [];

    const newVal = (<any[]>val).concat(items);
    this.setColValue(newVal);
  }

  removeItemsIfMatch(items: any[], ignoreCase = true) {
    let newVal: any[] = this.fieldValue;
    if (!Array.isArray(newVal)) newVal = [];

    items.forEach((item) => {
      const foundIndex = _.map(newVal, ignoreCase ? _.toLower : _.identity).indexOf(
        ignoreCase ? item.toLowerCase() : item
      );
      if (foundIndex >= 0) delete newVal[foundIndex];
    });
    newVal = newVal.filter(Boolean); // fix indexes, so those run in sequence

    if (this.fieldValue && this.fieldValue.length !== newVal.length) this.setColValue(newVal);
  }

  private changeValue(delta: number, rounding: string) {
    const val = Number(this.fieldValue);
    const roundingAmount = Number(rounding);
    // If values are not numbers, just skip them
    if (Number.isNaN(val) || Number.isNaN(delta)) return;

    let newVal = val + delta;
    if (roundingAmount) newVal = MonetaryHelpers.calculateRounding(newVal, roundingAmount);

    this.setColValue(newVal);
  }

  get fieldValue() {
    return _.get(this.object, this.col.field);
  }
}

enum BulkEditOption {
  CLEAR_ALL,
  RESET_TO_DEFAULT,
  FILL_ALL_WITH,
  ADD_THIS_BEFORE_EXISTING,
  ADD_THIS_AFTER_EXISTING,
  FIND_AND_CLEAR,
  REPLACE_IF_CONTAINS,
  COPY_FROM,
  CHANGE_LETTER_CASE,
  REPLACE_ALL_IF_CONTAINS,
  FIND_AND_REPLACE,
  INCREASE_VALUE,
  DECREASE_VALUE,
  ADD_CHIPS,
  REMOVE_CHIPS,
}

enum QtyAdjustmentType {
  Fixed = "Fixed",
  Percentage = "Percentage",
}

enum BulkFieldType {
  NOT_AVAILABLE,
  TEXT,
  DROPDOWN,
  NUMERIC,
  CHIPS,
}

enum LetterCaseOption {
  ALL_CAPS = "ALL CAPS",
  CAPITALIZE = "Capitalize",
  LOWERCASE = "lowercase",
  SENTENCE_CASE = "Sentence Case",
}

class BulkEditForm {
  editingField: Col;
  editOption: BulkEditOption;
  newValue: any;
  searchValue: any;
  matchLetterCase: boolean;
  copyField: Col;
  letterCaseChange: LetterCaseOption;
  qtyAdjustmentType: QtyAdjustmentType;
  qtyAdjustmentAmount: number;
  qtyRoundTo: number;
  allRowsSelection: boolean;
  chips: any[];

  constructor() {
    this.editingField = null;
    this.editOption = null;
    this.newValue = null;
    this.searchValue = null;
    this.matchLetterCase = true;
    this.copyField = null;
    this.letterCaseChange = null;
    this.qtyAdjustmentType = QtyAdjustmentType.Percentage;
    this.qtyAdjustmentAmount = null;
    this.qtyRoundTo = null;
    this.allRowsSelection = false;
    this.chips = null;
  }
}

enum FieldCtrlType {
  INPUT = "input",
  DROPDOWN = "dropdown",
  MULTISELECT = "multiselect",
  ENUMMULTISELECT = "enumMultiselect",
  CHIPS = "chips",
}

@Component({
  selector: "taku-form-list-bulk-editor",
  templateUrl: "./form-list-bulk-editor.component.html",
  styleUrls: ["./form-list-bulk-editor.component.scss"],
})
export class FormListBulkEditorComponent implements OnInit, OnChanges, OnDestroy {
  subsList: Subscription[] = [];

  @Input() _visibleCols: Col[] = [];
  @Input() _selectedRows: any[] = [];
  @Input() _orgObjects: any[] = [];
  @Input() _selectedForms: UntypedFormGroup[] = [];
  @Input() _totalVisibleRows: number;
  @Input() _model = "";

  @Output() onSelectAllRows: EventEmitter<any> = new EventEmitter();
  @Output() onUnselectAllRows: EventEmitter<any> = new EventEmitter();
  @Output() onEditingColSelected: EventEmitter<Col> = new EventEmitter();

  private editOptions_common: SelectItem[] = [
    { label: "", value: null },
    { label: "Clear All", value: BulkEditOption.CLEAR_ALL },
  ];

  private editOptions_textField: SelectItem[] = [
    ...this.editOptions_common,
    { label: "Reset to default (if any)", value: BulkEditOption.RESET_TO_DEFAULT },
    { label: "Fill all with", value: BulkEditOption.FILL_ALL_WITH },
    { label: "Before existing, add this", value: BulkEditOption.ADD_THIS_BEFORE_EXISTING },
    { label: "After existing, add this", value: BulkEditOption.ADD_THIS_AFTER_EXISTING },
    { label: "Find this and clear", value: BulkEditOption.FIND_AND_CLEAR },
    { label: "Replace if contains", value: BulkEditOption.REPLACE_IF_CONTAINS },
    { label: "Find and replace all", value: BulkEditOption.REPLACE_ALL_IF_CONTAINS },
    { label: "Find exact match and replace", value: BulkEditOption.FIND_AND_REPLACE },
    { label: "Copy from", value: BulkEditOption.COPY_FROM },
    { label: "Change letter case", value: BulkEditOption.CHANGE_LETTER_CASE },
  ];

  private editOptions_dropdownField: SelectItem[] = [
    ...this.editOptions_common,
    { label: "Reset to default (if any)", value: BulkEditOption.RESET_TO_DEFAULT },
    { label: "Fill all with", value: BulkEditOption.FILL_ALL_WITH },
    { label: "Find this and clear", value: BulkEditOption.FIND_AND_CLEAR },
    { label: "Find and replace", value: BulkEditOption.REPLACE_IF_CONTAINS },
  ];

  private editOptions_numericField: SelectItem[] = [
    ...this.editOptions_common,
    { label: "Reset to default (if any)", value: BulkEditOption.RESET_TO_DEFAULT },
    { label: "Fill all with", value: BulkEditOption.FILL_ALL_WITH },
    { label: "Find this and clear", value: BulkEditOption.FIND_AND_CLEAR },
    { label: "Increase", value: BulkEditOption.INCREASE_VALUE },
    { label: "Decrease", value: BulkEditOption.DECREASE_VALUE },
  ];

  private editOptions_chipField: SelectItem[] = [
    ...this.editOptions_common,
    { label: "Add new chips", value: BulkEditOption.ADD_CHIPS },
    { label: "Remove chips", value: BulkEditOption.REMOVE_CHIPS },
  ];

  fieldLookupOptions: Observable<SelectItem[]>;
  fieldMultiselectOptions: Observable<SelectItem[]>;
  bulkEditorForm: UntypedFormGroup;
  // The active edit options, will vary according to the selected field type
  enum_editingFields: SelectItem[];
  enum_copyingFields: SelectItem[];
  enum_editOptions: SelectItem[];
  enum_adjustmentType: SelectItem[] = [
    { label: "%", value: QtyAdjustmentType.Percentage },
    { label: "$", value: QtyAdjustmentType.Fixed },
  ];
  groupedeEditOptions = new Map<number, BulkEditOption[]>();
  enum_letterCase = this.dbService.enumSelectOptions(LetterCaseOption);
  // enums in template
  ColumnDataType = DataType;
  FieldCtrlType = FieldCtrlType;
  BulkEditOption = BulkEditOption;
  BulkFieldType = BulkFieldType;
  // tooltipRounding = `<h5>Examples</h5>
  //                   <ul>
  //                     <li>0.05 : Rounds to 5 centecimals</li>
  //                     <li>10 : Rounds to tens</li>
  //                   </ul>`

  constructor(
    private fb: UntypedFormBuilder,
    private dbService: DBService,
    private confirmationService: ConfirmationService,
    protected formListColumnsService: FormListColumnsService
  ) {
    this.groupedeEditOptions.set(0, [BulkEditOption.CLEAR_ALL, BulkEditOption.RESET_TO_DEFAULT]);
    this.groupedeEditOptions.set(1, [
      BulkEditOption.FILL_ALL_WITH,
      BulkEditOption.FILL_ALL_WITH,
      BulkEditOption.ADD_THIS_BEFORE_EXISTING,
      BulkEditOption.ADD_THIS_AFTER_EXISTING,
      BulkEditOption.FIND_AND_CLEAR,
      BulkEditOption.ADD_CHIPS,
      BulkEditOption.REMOVE_CHIPS,
      BulkEditOption.COPY_FROM,
      BulkEditOption.CHANGE_LETTER_CASE,
    ]);
    this.groupedeEditOptions.set(2, [
      BulkEditOption.REPLACE_IF_CONTAINS,
      BulkEditOption.REPLACE_ALL_IF_CONTAINS,
      BulkEditOption.FIND_AND_REPLACE,
      BulkEditOption.INCREASE_VALUE,
      BulkEditOption.DECREASE_VALUE,
    ]);
  }

  ngOnInit(): void {
    this.bulkEditorForm = this.fb.group(new BulkEditForm());
    // this.editingFieldCtrl.setValidators(Validators.required);
    this.editOptionCtrl.setValidators(Validators.required);

    this.subsList.push(
      this.editingFieldCtrl.valueChanges
        .pipe(startWith(null), pairwise())
        .subscribe(([prevCol, newCol]: [Col, Col]) => {
          const currCol = (<BulkEditForm>this.bulkEditorForm.value).editingField;
          this.editingFieldCtrl.markAsPristine();
          const fnChangeColumn = () => {
            // Clear all the fields except selected column
            this.bulkEditorForm.setValue(
              Object.assign({}, new BulkEditForm(), {
                editingField: newCol,
              }),
              { emitEvent: false }
            );
            if (this.editingFieldCtrl.value.dataType === FieldCtrlType.ENUMMULTISELECT) {
              this.fieldMultiselectOptions = this.lookupOptions(newCol);
              this.fieldLookupOptions = this.lookupOptions(newCol).pipe(
                map((result: SelectItem[]) => [<SelectItem>{ label: "", value: null }].concat(result))
              );
            } else {
              this.fieldLookupOptions = this.lookupOptions(newCol);
            }
            this.bulkEditorForm.markAsPristine();

            const fieldType = this.getBulkFieldType(newCol);
            switch (fieldType) {
              case BulkFieldType.TEXT:
                this.enum_editOptions = this.editOptions_textField;
                break;
              case BulkFieldType.NUMERIC:
                this.enum_editOptions = this.editOptions_numericField;
                break;
              case BulkFieldType.DROPDOWN:
                this.enum_editOptions = this.editOptions_dropdownField;
                break;
              case BulkFieldType.CHIPS:
                this.enum_editOptions = this.editOptions_chipField;
                break;
              default:
                this.enum_editOptions = null;
            }

            //get the database service for this model.
            const modelDbService = this.formListColumnsService.getColumnsService(this._model);
            const validationRules = modelDbService.getValidationRules();
            //Check if the selected column is a required field by analyzing the validation rules of the model.
            if (
              validationRules.hasOwnProperty(newCol.field) &&
              Array.isArray(validationRules[newCol.field]) &&
              (<ValidatorFn[]>validationRules[newCol.field]).includes(Validators.required)
            ) {
              //Since the column is required we want to remove the options for "Clear All" and "Find this and clear" if they exist
              this.enum_editOptions = this.enum_editOptions.filter((option) => {
                return option.value != BulkEditOption.CLEAR_ALL && option.value != BulkEditOption.FIND_AND_CLEAR;
              });
            }

            this.onEditingColSelected.emit(newCol);
          };
          if (this.bulkEditorForm.dirty) {
            this.confirmationService.confirm({
              header: "Confirmation",
              message: "You have unsaved changes in the Bulk Editor. Are you sure you want to change the column?",
              rejectButtonStyleClass: "p-button-link",
              accept: fnChangeColumn,
              reject: () => {
                this.editingFieldCtrl.setValue(currCol, { emitEvent: false });
              },
            });
          } else {
            fnChangeColumn();
          }
        })
    );

    this.subsList.push(
      this.editOptionCtrl.valueChanges.subscribe((newOption: BulkEditOption) => {
        // Clear value for multi-select field in order to avoid errors due to invalid type
        if (
          this.currentCtrlType === FieldCtrlType.MULTISELECT ||
          this.currentCtrlType === FieldCtrlType.ENUMMULTISELECT
        ) {
          this.newValueCtrl.setValue(null, { emitEvent: false });
        }

        // apply validation dynamically
        this.clearFormValidators();
        if ([BulkEditOption.ADD_CHIPS, BulkEditOption.REMOVE_CHIPS].includes(newOption)) {
          this.chipsCtrl.setValidators(Validators.required);
        } else if (newOption === BulkEditOption.COPY_FROM) {
          this.copyFieldCtrl.setValidators(Validators.required);
        } else if (newOption === BulkEditOption.CHANGE_LETTER_CASE) {
          this.letterCaseChangeCtrl.setValidators(Validators.required);
        } else if (this.showNumericControls) {
          this.qtyAdjustmentTypeCtrl.setValidators(Validators.required);
          this.qtyAdjustmentAmountCtrl.setValidators(Validators.required);
        } else if (this.groupedeEditOptions.get(1).includes(newOption)) {
          if (newOption === BulkEditOption.FIND_AND_CLEAR) this.searchValueCtrl.setValidators(Validators.required);
          else this.newValueCtrl.setValidators(Validators.required);
        } else if (this.groupedeEditOptions.get(2).includes(newOption)) {
          this.searchValueCtrl.setValidators(Validators.required);
          this.newValueCtrl.setValidators(Validators.required);
        }

        // this.refreshFormValidators();
      })
    );
  }

  private clearFormValidators() {
    Object.entries(this.bulkEditorForm.controls).forEach(([key, control]) => {
      control.clearValidators();
      control.updateValueAndValidity({ emitEvent: false });
    });
  }

  // private refreshFormValidators(){
  //   Object.entries(this.bulkEditorForm.controls).forEach(([key, control]) => {
  //     control.updateValueAndValidity();
  //   });
  // }

  getBulkFieldType(col: Col): BulkFieldType {
    if (!col) return BulkFieldType.NOT_AVAILABLE;

    switch (col.dataType) {
      case DataType.input:
        return BulkFieldType.TEXT;

      case DataType.number:
        return BulkFieldType.NUMERIC;

      case DataType.enum:
      case DataType.enumMultiselect:
      case DataType.lookup:
      case DataType.multiselect:
      case DataType.checkbox:
      case DataType.toggle:
        return BulkFieldType.DROPDOWN;

      case DataType.chips:
        return BulkFieldType.CHIPS;

      default:
        return BulkFieldType.NOT_AVAILABLE;
    }
  }

  get showNumericControls() {
    return (
      BulkFieldType.NUMERIC === this.getBulkFieldType(this.editingFieldCtrl.value) &&
      [BulkEditOption.INCREASE_VALUE, BulkEditOption.DECREASE_VALUE].includes(this.editOptionCtrl.value)
    );
  }

  get editingFieldCtrl() {
    return this.bulkEditorForm.get("editingField");
  }

  get editOptionCtrl() {
    return this.bulkEditorForm.get("editOption");
  }

  get newValueCtrl() {
    return this.bulkEditorForm.get("newValue");
  }

  get searchValueCtrl() {
    return this.bulkEditorForm.get("searchValue");
  }

  get matchLetterCaseCtrl() {
    return this.bulkEditorForm.get("matchLetterCase");
  }

  get copyFieldCtrl() {
    return this.bulkEditorForm.get("copyField");
  }

  get letterCaseChangeCtrl() {
    return this.bulkEditorForm.get("letterCaseChange");
  }

  get qtyAdjustmentTypeCtrl() {
    return this.bulkEditorForm.get("qtyAdjustmentType");
  }

  get qtyAdjustmentAmountCtrl() {
    return this.bulkEditorForm.get("qtyAdjustmentAmount");
  }

  get qtyRoundToCtrl() {
    return this.bulkEditorForm.get("qtyRoundTo");
  }

  get chipsCtrl() {
    return this.bulkEditorForm.get("chips");
  }

  get currentCtrlType(): FieldCtrlType {
    if (!this.editingFieldCtrl.value) return null;

    switch (this.editingFieldCtrl.value.dataType) {
      case DataType.input:
      case DataType.number:
        return FieldCtrlType.INPUT;

      case DataType.enum:
      case DataType.lookup:
      case DataType.checkbox:
      case DataType.toggle:
        return FieldCtrlType.DROPDOWN;

      case DataType.enumMultiselect:
        return FieldCtrlType.ENUMMULTISELECT;
      case DataType.multiselect:
        return FieldCtrlType.MULTISELECT;

      case DataType.chips:
        return FieldCtrlType.CHIPS;

      default:
        return null;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // Disable columns that are not editable
    if (changes["_visibleCols"]) {
      this.enum_editingFields = [{ label: "", value: null }].concat(
        this._visibleCols.map((col) => ({
          label: col.header,
          value: col,
          disabled: col.readonly || this.getBulkFieldType(col) === BulkFieldType.NOT_AVAILABLE,
        }))
      );

      this.enum_copyingFields = [{ label: "", value: null }].concat(
        this._visibleCols.map((col) => ({
          label: col.header,
          value: col,
          disabled: this.getBulkFieldType(col) !== BulkFieldType.TEXT,
        }))
      );
    }
  }

  private lookupOptions(col: Col): Observable<any> {
    if (!col) return of([]);
    else if (col.dataType === DataType.checkbox || col.dataType === DataType.toggle)
      return of([<SelectItem>{ label: "", value: null }].concat(Col.boolean_filter_enum));
    // else if (col.dataType === DataType.enumMultiselect)
    //   return of([<SelectItem>{ label: '', value: null }].concat(<SelectItem[]>col.dataOptions));
    else if (isObservable(col.dataOptions)) return col.dataOptions;
    else return of(col.dataOptions);
  }

  _includes(options: any[], needle) {
    return options.includes(needle);
  }

  selectAllRowsChanged(isChecked: boolean) {
    if (isChecked) this.onSelectAllRows.emit();
    else this.onUnselectAllRows.emit();
  }

  get isApplyBtnDisabled(): boolean {
    return !this._selectedRows.length || this.bulkEditorForm.invalid;
  }

  applyBulkChanges() {
    const col: Col = this.editingFieldCtrl.value;
    const editOption = this.editOptionCtrl.value;
    const newValue = this.newValueCtrl.value;
    const searchValue = this.searchValueCtrl.value;
    const matchLetterCaseValue = this.matchLetterCaseCtrl.value;
    const copyCol: Col = this.copyFieldCtrl.value;
    const letterCaseOption: LetterCaseOption = this.letterCaseChangeCtrl.value;
    // Numeric fields
    const qtyAdjustmentType: QtyAdjustmentType = this.qtyAdjustmentTypeCtrl.value;
    const qtyAdjustmentAmount: string = this.qtyAdjustmentAmountCtrl.value;
    const qtyRounding: string = this.qtyRoundToCtrl.value;
    const chips: string[] = this.chipsCtrl.value;

    this._selectedRows.forEach((rowData, index) => {
      const rowForm = this._selectedForms[index];
      const editor = new ObjectEditActions(rowData, rowForm, col);
      switch (editOption) {
        case BulkEditOption.CLEAR_ALL:
          editor.clear();
          break;
        case BulkEditOption.RESET_TO_DEFAULT:
          editor.resetDefaults();
          break;
        case BulkEditOption.FILL_ALL_WITH:
          editor.replaceWith(newValue);
          break;
        case BulkEditOption.ADD_THIS_BEFORE_EXISTING:
          editor.addBefore(newValue);
          break;
        case BulkEditOption.ADD_THIS_AFTER_EXISTING:
          editor.addAfter(newValue);
          break;
        case BulkEditOption.FIND_AND_CLEAR:
          editor.clearIfMatches(searchValue, matchLetterCaseValue);
          break;
        case BulkEditOption.REPLACE_IF_CONTAINS:
          editor.replaceIfContains(searchValue, matchLetterCaseValue, newValue);
          break;
        case BulkEditOption.REPLACE_ALL_IF_CONTAINS:
          editor.replaceAllIfContains(searchValue, matchLetterCaseValue, newValue);
          break;
        case BulkEditOption.FIND_AND_REPLACE:
          editor.fullMatchReplace(searchValue, matchLetterCaseValue, newValue);
          break;
        case BulkEditOption.COPY_FROM:
          editor.copyFromCol(copyCol);
          break;
        case BulkEditOption.CHANGE_LETTER_CASE:
          editor.changeCase(letterCaseOption);
          break;
        case BulkEditOption.INCREASE_VALUE:
          editor.increaseValue(qtyAdjustmentAmount, qtyAdjustmentType, qtyRounding);
          break;
        case BulkEditOption.DECREASE_VALUE:
          editor.decreaseValue(qtyAdjustmentAmount, qtyAdjustmentType, qtyRounding);
          break;
        case BulkEditOption.ADD_CHIPS:
          editor.addItemsToList(chips);
          break;
        case BulkEditOption.REMOVE_CHIPS:
          editor.removeItemsIfMatch(chips);
          break;
      }
      if (col.onModelChange) {
        col.onModelChange(rowData, this._orgObjects, rowForm);
      }
    });

    this.bulkEditorForm.markAsPristine();
  }

  onResetForm() {
    const fnResetForm = () => {
      this.bulkEditorForm.setValue(new BulkEditForm(), { emitEvent: false });
      this.bulkEditorForm.markAsPristine();
      this.onEditingColSelected.emit(this.editOptionCtrl.value);
    };

    if (this.bulkEditorForm.dirty) {
      this.confirmationService.confirm({
        header: "Confirmation",
        message: "You have unsaved changes in the Bulk Editor. Are you sure you want to reset the form?",
        accept: fnResetForm,
      });
    } else {
      fnResetForm();
    }
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
