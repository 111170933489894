<taku-form-dialoge-header
  [pristine]="_myForm.pristine"
  [valid]="_myForm.valid"
  (backClicked)="goBack()"
  (saveClicked)="onSave()"
  (revertClicked)="onRevert()"
  (cancelClicked)="closeSidebar()"
  (newClicked)="onNew()"
  (deleteClicked)="onDelete()"
  (cloneClicked)="onClone()"
  toolbarTitle="SHIPPER DETAILS"
></taku-form-dialoge-header>

<div [formGroup]="_myForm" class="grid">
  <div class="xl:col-4 md:col-5 lg:col-5 col-12">
    <p-panel>
      <ng-template pTemplate="header">
        <h2>General Details</h2>

        <taku-checkbox [formGroup]="_myForm" fieldName="isActive" fieldCaption="Active"></taku-checkbox>
      </ng-template>

      <div class="col-12">
        <taku-input [formGroup]="_myForm" fieldName="shipperName" fieldCaption="Shipper Name"></taku-input>
      </div>

      <div class="col-12">
        <taku-input [formGroup]="_myForm" fieldName="shortName" fieldCaption="Shipper Short Name"></taku-input>
      </div>

      <div class="col-12 drop-container">
        <taku-dropdown
          [formGroup]="_myForm"
          [options]="enum_shipper_type"
          fieldName="shipperType"
          fieldCaption="Shipper Type"
        ></taku-dropdown>
      </div>

      <div class="col-12 drop-container">
        <taku-multiselect
          [formGroup]="_myForm"
          [options]="shipperService.enumMultiShippingMethod"
          fieldName="shippingMethod"
          fieldCaption="Shipping Method"
          [dataKey]="null"
        >
        </taku-multiselect>
      </div>

      <div class="col-12 drop-container">
        <taku-dropdown
          [formGroup]="_myForm"
          [options]="shipperService.enum_delivery_methods"
          fieldName="deliveryMethod"
          fieldCaption="Delivery Method"
        ></taku-dropdown>
      </div>

      <div class="col-12">
        <taku-input [formGroup]="_myForm" fieldName="accountNumber" fieldCaption="Account Number"></taku-input>
      </div>

      <div class="col-12 drop-container">
        <taku-dropdown
          [formGroup]="_myForm"
          [options]="lookup_zones | async"
          fieldName="zoneId"
          fieldCaption="Zone"
        ></taku-dropdown>
      </div>
    </p-panel>
  </div>

  <div class="xl:col-8 md:col-7 lg:col-7 col-12">
    <p-panel>
      <ng-template pTemplate="header">
        <h2>Shipper's Address</h2>
      </ng-template>

      <taku-address [formGroup]="_myForm.get('address')"></taku-address>
    </p-panel>
  </div>
</div>
