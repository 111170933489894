/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";
import { AppConstants } from "../app-constants";

@Pipe({
  name: "localTime",
  standalone: true,
})
export class LocalTimePipe extends DatePipe implements PipeTransform {
  transform(time: string | Date, args?: any): any {
    let localTime;
    if (time instanceof Date) localTime = moment(time).local().toDate();
    else {
      const dateString = moment.utc().format("YYYY-MM-DD");
      localTime = moment.utc(`${dateString} ${time}`).local().toDate();
    }

    return super.transform(localTime, AppConstants.TIME_FMT);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
