/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable, EventEmitter, OnDestroy } from "@angular/core";
import { FormListModel } from "src/app/form-list/form-list/form-list-model.interface";
import { FormListComponent } from "src/app/form-list/form-list/form-list.component";
import { HttpClient } from "@angular/common/http";
import { ValidatorFn, Validators } from "@angular/forms";
import { SelectItem, ConfirmationService, MessageService } from "primeng/api";
import { DBService } from "src/app/shared/services/db.service";
import { Observable, Subscription } from "rxjs";
import { tap } from "rxjs/operators";
import {
  Col,
  DataType,
  ExecuteEventDatatypeOptions,
  ExecuteEvent_DisplayMode,
  FilterType,
  FormListFilter,
} from "../../form-list/form-list/form-list";
import { AppSettingsStorageService } from "../../shared/app-settings-storage.service";
import { AuthService } from "../../shared/services/auth.service";
import * as _ from "lodash";
import { SaleChannel, ChannelType } from "./sale-channel";
import { Router } from "@angular/router";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";
import { APP_BRAND } from "src/app/utility/white-label";

@Injectable({
  providedIn: "root",
})
export class SaleChannelService extends DBService implements FormListModel, OnDestroy {
  private connectToSaleChannel: EventEmitter<SaleChannel> = new EventEmitter();
  isAdmin: boolean;
  subsList: Subscription[] = [];
  protected lookupStores: SelectItem[];
  branding = APP_BRAND;
  enumChannelTypes(): SelectItem[] {
    const rows = this.enumMultiselectOptions(ChannelType);
    rows.map((row) => {
      if (row.value !== "TAKU eCommerce") {
        row.icon = "pi pi-lock";
        row.disabled = true;
      } else {
        row.disabled = false;
      }
    });

    return rows;
  }

  disconnectEvent: EventEmitter<SaleChannel> = new EventEmitter<SaleChannel>();

  constructor(
    protected _router: Router,
    protected http: HttpClient,
    protected authService: AuthService,
    private appSettings: AppSettingsStorageService,
    private confirmationService: ConfirmationService,
    private dbService: DBService,
    private messageService: MessageService,
    protected alertMessage: AlertMessagesService
  ) {
    super(http, authService);

    const activeStore = this.appSettings.getStore();
    this.isAdmin = !(activeStore ? activeStore.id : false);

    this.subsList.push(
      this.appSettings.activeStoreChanged$.subscribe((store) => {
        this.isAdmin = !store.id;
      })
    );

    this.connectToSaleChannel.subscribe((saleChannel: SaleChannel) => {
      if (saleChannel.store && saleChannel.store.address.addressEmail.email) {
        this.confirmationService.confirm({
          header: "Confirmation",
          message: saleChannel.isConnected
            ? `By clicking Yes below you will be disconnecting your ${this.branding.generic.companyName} eCommerce channel. This will immediately un-publish your online store if it is currently live. Note that you and other users will still have access to your ${this.branding.generic.companyName} eCommerce Control Panel but you will not be able to make online sales.
            Are you sure you would like to proceed?`
            : `By clicking Yes below, you will be activating ${this.branding.generic.companyName} eCommerce.
          Once your online store is activated, you will need to choose which products to sell on ${this.branding.generic.companyName} eCommerce, check your Stock Control settings and publish your online storefront to start selling.
          Are you sure you want to proceed?`,
          acceptLabel: "Yes",
          rejectVisible: true,
          acceptVisible: true,
          rejectButtonStyleClass: "p-button-link",
          accept: () => {
            saleChannel.isConnected = !saleChannel.isConnected;
            if (!saleChannel.isConnected) {
              saleChannel.isStoreFrontOpen = false;
            }
            this.subsList.push(
              this.dbService.editRow("saleChannel", saleChannel).subscribe({
                next: (_response) => {
                  saleChannel = _response;
                  if (saleChannel.isConnected) {
                    this._router.navigate(["/saleChannel", saleChannel.id]);
                  }
                },
                error: (_errorResponse) => {
                  saleChannel.isConnected = !saleChannel.isConnected;
                  if (_errorResponse.error.customMessage === "E-mail already registered") {
                    this.messageService.add({
                      severity: "error",
                      summary: "Error Message",
                      detail: `This store email is already registered with another online store in this sales channel. To proceed, please change your store email or contact us at ${this.branding.generic.supportEmail}} to request for your previous online store to be deleted.`,
                      life: 6000,
                    });
                  } else {
                    this.messageService.add(this.alertMessage.getErrorMessage(_errorResponse));
                  }
                },
              })
            );
          },
          reject: () => {},
        });
      } else {
        this.messageService.add({
          severity: "error",
          summary: "Information Missing",
          detail: `Store email is required to connect a sales channel. Please add a store email at Settings > Stores > Manage Stores > View your current store. If you do not have access to store settings, please speak to your ${this.branding.generic.companyName} Administrator.`,
          life: 6000,
        });
      }
    });
  }

  _formListComponent?: FormListComponent;
  _disableCloning?: boolean;
  _defaultRowValues = {};
  _formFilters: FormListFilter = {};
  _activeStoreId: number;

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }

  getValidationRules(): {
    [key: string]: {} | ValidatorFn[];
  } {
    return {
      channelType: [Validators.required],
      channelName: [Validators.required],
      storeId: [Validators.required],
    };
  }

  getLookupStores(): Observable<SelectItem[]> {
    return this.lookupSelectOptions("store", "storeName", {
      enableFieldName: "isActive",
    }).pipe(tap((stores: SelectItem[]) => (this.lookupStores = stores)));
  }

  getSaleChannelConnectionButtonCaption(rowData: SaleChannel, _saleChannelService: SaleChannelService) {
    return rowData.isConnected ? "Disconnect Channel" : "Connect Channel";
  }

  getFormListColumns(): // isAdmin: boolean,
  // clickManageChannelEvent?: EventEmitter<any>
  Col[] {
    return <Col[]>[
      // {
      //   field: "isActive",
      //   header: "Active",
      //   visible: true,
      //   readonly: false,
      //   frozen: false,
      //   dataType: DataType.checkbox,
      //   dataOptions: Col.boolean_filter_enum,
      //   filterType: FilterType.enum,
      // },
      {
        field: "isConnected",
        header: "Connection",
        visible: true,
        readonly: true,
        frozen: true,
        dataType: DataType.execute_event,
        dataOptions: [],
        dataTypeOptions: [
          new ExecuteEventDatatypeOptions({
            displayMode: ExecuteEvent_DisplayMode.BUTTON,
            label: this.getSaleChannelConnectionButtonCaption,
            event: this.connectToSaleChannel,
            enabledOnEditMode: false,
            styleClass: "",
          }),
        ],
        filterType: FilterType.none,
        colWidth: 150,
      },
      {
        field: "isStoreFrontOpenCaption",
        header: "Status",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.none,
        isNotSortable: true,
      },
      {
        field: "channelType",
        header: "Channel Type",
        visible: true,
        readonly: !this.isAdmin,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enumChannelTypes(),
        filterType: FilterType.enum,
      },
      {
        field: "channelName",
        header: "Channel Name",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "storeId",
        header: "Store Name",
        visible: true,
        readonly: !this.isAdmin,
        frozen: false,
        dataType: DataType.lookup,
        dataOptions: this.getLookupStores(),
        filterType: FilterType.lookup,
        sortFields: ["store.storeName"],
      },
      {
        field: "createdAtDateAndTime",
        header: "Created At",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.date_time,
        filterType: FilterType.none,
        sortFields: ["createdAt"],
      },
      {
        field: "updatedAtDateAndTime",
        header: "Updated At",
        visible: false,
        readonly: true,
        frozen: false,
        dataType: DataType.date_time,
        filterType: FilterType.none,
        sortFields: ["updatedAt"],
      },
    ].filter(Boolean);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
