/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import { ReturnSaleDocFooterComponent } from "../return-sale-doc-footer/return-sale-doc-footer.component";

@Component({
  selector: "taku-return-doc-footer-compact",
  templateUrl: "./return-doc-footer-compact.component.html",
  styleUrls: ["./return-doc-footer-compact.component.scss"],
})
export class ReturnDocFooterCompactComponent extends ReturnSaleDocFooterComponent {
  @Output() summaryOpened: EventEmitter<any> = new EventEmitter();
  @Output() searchReceiptsOpened: EventEmitter<any> = new EventEmitter();
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
