/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MenuItem } from "primeng/api";
import { UnsavedChangesGuard } from "src/app/app-routing/UnsavedChanges.guard";
import { Subscription } from "rxjs";

@Component({
  selector: "taku-form-dialoge-header",
  templateUrl: "./form-dialoge-header.component.html",
  styleUrls: ["./form-dialoge-header.component.scss"],
})
export class FormDialogeHeaderComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];

  private readonly confirmationMsg = "You have unsaved changes. Are you sure you want to close this form?";

  @Input() pristine: boolean;
  @Input() valid: boolean;
  @Input() toolbarTitle?: string;
  @Input() showDeleteBtn = true;
  @Input() isSaving = false;
  // @Input() disableBack: boolean;
  @Output() saveClicked = new EventEmitter();
  // @Input() disableSave: boolean;
  @Output() revertClicked = new EventEmitter();
  @Output() deleteClicked = new EventEmitter();
  // @Input() disableRevert: boolean;
  @Output() newClicked = new EventEmitter();
  @Output() cancelClicked = new EventEmitter();

  saveItems: MenuItem[];

  constructor(private unsavedChangesGuard: UnsavedChangesGuard) {}

  ngOnInit() {
    this.saveItems = [{ label: "Save & New", command: () => this.onSave() }];
  }

  onSave() {
    this.saveClicked.emit();
  }

  onRevert() {
    this.revertClicked.emit();
  }

  onDelete() {
    this.deleteClicked.emit();
  }

  onNew() {
    this.newClicked.emit();
  }

  onCancel() {
    this.subsList.push(
      this.unsavedChangesGuard.checkForm(this.pristine, this.confirmationMsg).subscribe((shouldClose) => {
        if (shouldClose) this.cancelClicked.emit();
      })
    );
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
