/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  ViewChild,
  TemplateRef,
} from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  AbstractControl,
  FormControl,
} from "@angular/forms";
import { ConfirmationService, MessageService, SelectItem } from "primeng/api";
import { NestedFormComponent } from "../../../forms/nested-form/nested-form.component";
import { DBService } from "../../../shared/services/db.service";
import { ToggleCaptionPosition } from "../../../taku-ui/taku-toggle/taku-toggle.component";
import { FormDataHelpers } from "../../../utility/FormDataHelpers";
import { AddressType } from "../address/address";
import { AddressComponent } from "../address/address.component";
import { AddressesManager, AddressFilterChangeObserver } from "../address/AddressesManager";
import { PersonAddress } from "../person-address/person-address";
import { PersonAddressComponent } from "../person-address/person-address.component";
import { PersonEmailComponent } from "../person-email/person-email.component";
import { PersonInstantMsgComponent } from "../person-instant-msg/person-instant-msg.component";
import { PersonPhoneComponent } from "../person-phone/person-phone.component";
import { PersonSocialProfileComponent } from "../person-social-profile/person-social-profile.component";
import { GenderType, Person, TitleType } from "./person";
import { CustomerConsentComponent } from "../customer-consent/customer-consent.component";
import { Subject } from "rxjs";
import * as _ from "lodash";
import { PersonalAccountsAccessRightsFields } from "../shared/personal-accounts-access-rights-fields";
import { EmptyPersonalAccountsAccessRights } from "../shared/empty-personal-accounts-access-rights";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { DialogService } from "primeng/dynamicdialog";
import { FileUpload } from "primeng/fileupload";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";
import { ACTION_TYPE } from "src/app/shared/components/action-button/action-type.enum";

export type ConsentFormInfo = {
  person: Person;
  consentTime: Date;
  consentApproved: boolean;
};

@Component({
  selector: "taku-person",
  templateUrl: "./person.component.html",
  styleUrls: ["./person.component.scss"],
  providers: [DialogService],
})
export class PersonComponent extends NestedFormComponent implements OnInit, OnChanges {
  @Input() accountAccessRights?: PersonalAccountsAccessRightsFields = new EmptyPersonalAccountsAccessRights();
  @Input() enableConsent = false;
  @Input() isConsentApproved = false;
  @Input() consentDateTime = false;
  @Input() disableForm = false;
  @Input() cardTemplate: TemplateRef<any>;
  @Input() showCards = false;
  @Input() formCtrlAccount: FormControl;
  @Output() consentApproved: EventEmitter<ConsentFormInfo> = new EventEmitter();
  @ViewChild("fileUploader") _fileUploader: FileUpload;
  // Tabview variable
  _addressTabIndex = 0;
  todayDate = new Date();
  public ACTION_TYPE: typeof ACTION_TYPE = ACTION_TYPE;

  _addressTypes: SelectItem[] = [
    { label: AddressType.billingAddress, value: AddressType.billingAddress, icon: "ui-icon-receipt" },
    { label: AddressType.shippingAddress, value: AddressType.shippingAddress, icon: "ui-icon-local-shipping" },
  ];
  _filterChangeObserver: AddressFilterChangeObserver = {
    onFilterChanged: (value) => {
      this._addressTabIndex = 0;
    },
  };
  _addressMgr: AddressesManager;
  _mainTabActiveIndex = 0;
  takuPayAccountEnabled = false;

  // Define enum variables
  enumGenderType = this.dbService.enumSelectOptions(GenderType);
  enumTitleType = this.dbService.enumSelectOptions(TitleType);
  ToggleCaptionPosition = ToggleCaptionPosition;
  additionalFieldsVisibility = false;

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    const personGroup = fb.group(new Person());
    personGroup.setControl("personAddresses", fb.array([]));
    personGroup.setControl("personPhones", fb.array([]));
    personGroup.setControl("personEmails", fb.array([]));
    personGroup.setControl("personInstantMsgs", fb.array([]));
    personGroup.setControl("personSocialProfiles", fb.array([]));

    return personGroup;
  }

  static set(fb: UntypedFormBuilder, person: Person): UntypedFormGroup {
    const temp = fb.group(person);
    PersonComponent.setNestedForms(fb, person, temp);

    return temp;
  }

  static setNestedForms(fb: UntypedFormBuilder, person: Person, form: UntypedFormGroup) {
    form.setControl("personAddresses", PersonAddressComponent.setArray(fb, person.personAddresses));
    form.setControl("personPhones", PersonPhoneComponent.setArray(fb, person.personPhones));
    form.setControl("personEmails", PersonEmailComponent.setArray(fb, person.personEmails));
    form.setControl("personInstantMsgs", PersonInstantMsgComponent.setArray(fb, person.personInstantMsgs));
    form.setControl("personSocialProfiles", PersonSocialProfileComponent.setArray(fb, person.personSocialProfiles));
  }

  constructor(
    protected confirmationService: ConfirmationService,
    private httpClient: HttpClient,
    public dbService: DBService,
    public fb: UntypedFormBuilder,
    private dialogService: DialogService,
    private messageService: MessageService,
    protected alertMessage: AlertMessagesService
  ) {
    super(dbService, fb);
  }

  ngOnInit() {
    super.ngOnInit();
    this.additionalFieldsVisibility = this.additionalFieldsHaveContent();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes._myForm) {
      this._addressMgr = new AddressesManager(
        this._myForm,
        "personAddresses",
        AddressType.billingAddress,
        this._filterChangeObserver
      );

      this._mainTabActiveIndex = null;
      setTimeout(() => {
        this._mainTabActiveIndex = 0;
      }, 0);
    }
  }
  get addressesCtrlArray() {
    return this._myForm.get("personAddresses") as UntypedFormArray;
  }

  get instantMsgsCtrlArray() {
    return this._myForm.get("personInstantMsgs") as UntypedFormArray;
  }

  get socialProfilesCtrlArray() {
    return this._myForm.get("personSocialProfiles") as UntypedFormArray;
  }

  get jobTitleCtrl() {
    return this._myForm.get("jobTitle");
  }

  get genderCtrl() {
    return this._myForm.get("gender");
  }

  get birthDateCtrl() {
    return this._myForm.get("birthDate");
  }

  // Define lookup Variables
  initLookups() {}

  additionalFieldsCtrls(): AbstractControl[] {
    return [
      this.instantMsgsCtrlArray,
      this.socialProfilesCtrlArray,
      this.jobTitleCtrl,
      this.genderCtrl,
      this.birthDateCtrl,
    ];
  }

  getTakuPayAccountEnabled() {
    this.takuPayAccountEnabled = true;
  }

  additionalFieldsHaveContent(): boolean {
    return this.additionalFieldsCtrls().filter((control) => control.value && control.value.length).length > 0;
  }

  addPersonAddress(): UntypedFormGroup {
    if (!this.addressesCtrlArray) this._myForm.setControl("personAddresses", this.fb.array([]));

    const qtyAddresses = this._addressMgr.filteredAddresses.length;
    const newAddress = PersonAddressComponent.init(this.fb, this._addressMgr.activeAddressFilter, qtyAddresses === 0);
    this.addressesCtrlArray.push(newAddress);
    this._addressTabIndex = qtyAddresses; // Make the new address active by default

    this._addressMgr.subscribeToggleDefaultChanges(newAddress);
    this._myForm.markAsDirty();

    return newAddress;
  }

  addPersonPhone() {
    if (!(this._myForm.controls.personPhones instanceof UntypedFormArray)) {
      this._myForm.setControl("personPhones", this.fb.array([]));
    }
    (this._myForm.controls.personPhones as UntypedFormArray).push(PersonPhoneComponent.init(this.fb));
  }

  addPersonEmail() {
    if (!(this._myForm.controls.personEmails instanceof UntypedFormArray)) {
      this._myForm.setControl("personEmails", this.fb.array([]));
    }
    (this._myForm.controls.personEmails as UntypedFormArray).push(PersonEmailComponent.init(this.fb));
  }

  addPersonInstantMsg() {
    if (!(this.instantMsgsCtrlArray instanceof UntypedFormArray)) {
      this._myForm.setControl("personInstantMsgs", this.fb.array([]));
    }
    this.instantMsgsCtrlArray.push(PersonInstantMsgComponent.init(this.fb));
  }

  addPersonSocialProfile() {
    if (!(this.socialProfilesCtrlArray instanceof UntypedFormArray)) {
      this._myForm.setControl("personSocialProfiles", this.fb.array([]));
    }
    this.socialProfilesCtrlArray.push(PersonSocialProfileComponent.init(this.fb));
  }

  removePersonAddress(event: any) {
    if (!this.disableForm) {
      // const defaultAddressPath = 'address.isDefault';
      // if (this._addressMgr.filteredAddresses.length > 1) {
      const addressToRemove = this._addressMgr.filteredAddresses[event.index];
      this.addressesCtrlArray.removeAt(this._addressMgr.findAddressPosition(addressToRemove));
      // // If this address was set as default, make the first the new default
      // if (addressToRemove.get(defaultAddressPath).value){
      //   this._addressMgr.filteredAddresses[0].get(defaultAddressPath).setValue(true);
      // }
      this._myForm.markAsDirty();
      // }
      this._addressTabIndex = 0;
    }
  }

  changePersonAddress(event: any) {
    this._addressTabIndex = event.index;
  }

  private _removeFormArrayChild(formArray: UntypedFormArray, index: number) {
    formArray.removeAt(index);
    this._myForm.markAsDirty();
  }

  removePersonPhone(index: number) {
    const phonesArray: UntypedFormArray = this._myForm.get("personPhones") as UntypedFormArray;
    if (phonesArray.at(index).pristine) {
      this._removeFormArrayChild(phonesArray, index);
      return;
    }

    this.confirmationService.confirm({
      message: "By deleting this Phone you will lose your changes. Are you sure?",
      rejectButtonStyleClass: "p-button-link",

      accept: () => {
        this._removeFormArrayChild(phonesArray, index);
      },
    });
  }

  removePersonEmail(index: number) {
    const emailsArray: UntypedFormArray = this._myForm.get("personEmails") as UntypedFormArray;
    if (emailsArray.at(index).pristine) {
      this._removeFormArrayChild(emailsArray, index);
      return;
    }

    this.confirmationService.confirm({
      message: "By deleting this Email you will lose your changes. Are you sure?",
      rejectButtonStyleClass: "p-button-link",

      accept: () => {
        this._removeFormArrayChild(emailsArray, index);
      },
    });
  }

  removePersonInstantMsg(index: number) {
    if (this.instantMsgsCtrlArray.at(index).pristine) {
      this._removeFormArrayChild(this.instantMsgsCtrlArray, index);
      return;
    }

    this.confirmationService.confirm({
      message: "By deleting this IM you will lose your changes. Are you sure?",
      rejectButtonStyleClass: "p-button-link",

      accept: () => {
        this._removeFormArrayChild(this.instantMsgsCtrlArray, index);
      },
    });
  }

  removePersonSocialProfile(index: number) {
    if (this.socialProfilesCtrlArray.at(index).pristine) {
      this._removeFormArrayChild(this.socialProfilesCtrlArray, index);
      return;
    }

    this.confirmationService.confirm({
      message: "By deleting this Social Profile you will lose your changes. Are you sure?",
      rejectButtonStyleClass: "p-button-link",

      accept: () => {
        this._removeFormArrayChild(this.socialProfilesCtrlArray, index);
      },
    });
  }

  static get validators() {
    return {
      firstName: [Validators.required],
      lastName: [Validators.required],
    };
  }

  initValidation() {
    this._validation = PersonComponent.validators;
  }

  clonePersonalAddress() {
    // Take data from selected tab
    const personalAddressData: PersonAddress = this._addressMgr.filteredAddresses[this._addressTabIndex].value;
    // Clone addresses are not Default unless changed manually
    personalAddressData.address.isDefault = false;

    const newAddress = this.addPersonAddress();
    this._fillAddress(newAddress, personalAddressData);
  }

  private _fillAddress(addressControl: UntypedFormGroup, data: PersonAddress) {
    FormDataHelpers.clearModelIDs(data);
    addressControl.reset(data);
    addressControl.setControl("address", AddressComponent.set(this.fb, data.address));
    this._addressMgr.subscribeToggleDefaultChanges(addressControl);
    this._myForm.markAsDirty();
  }

  openCustomerConsentDialog() {
    const onSave$ = new Subject<ConsentFormInfo>();
    this.subsList.push(
      onSave$.subscribe((consentInfo) => {
        // Save and updated Person fields
        const newPerson = _.cloneDeep(consentInfo.person);
        this._myForm.patchValue(newPerson);
        PersonComponent.setNestedForms(this.fb, newPerson, this._myForm);
        this.additionalFieldsVisibility = this.additionalFieldsHaveContent();
        this._myForm.markAsDirty();

        // Sent event to Personal Account component in order to update Account fields
        this.consentApproved.emit(consentInfo);
      })
    );

    this.dialogService.open(CustomerConsentComponent, {
      styleClass: "fullscreen-mobilemodal dialogSidebar dialogForNewItemInSaleScreen",
      contentStyle: {
        overflow: "auto",
        // height: 'calc(100vh - 40pt)',
        // minWidth: '580pt',
        height: "100%",
      },
      showHeader: false,
      // style: {
      //   minHeight: "80vh",
      // },
      data: {
        formGroup: _.cloneDeep(this._myForm),
        onSave: onSave$,
        consentApproved: this.isConsentApproved,
        consentDateTime: this.consentDateTime,
      },
    });
  }

  toggleAdditionalFieldsVisibility() {
    this.additionalFieldsVisibility = !this.additionalFieldsVisibility;
  }

  clearImage() {
    this.imageUrlCtrl.setValue(null);
    this.urlImageUrlCtrl.setValue(null);
    this.imageUrlCtrl.markAsDirty();
  }

  myUploader(event: { files: any[] }) {
    // get signedUrl
    event.files.map((_file) => {
      if (_file.size <= 2 * 1024 * 1024) {
        this.dbService._getRequest<any>(environment.apiUrl + "/uploadURL/" + _file.name).subscribe(
          (response: any) => {
            this.subsList.push(
              this.httpClient.put(response.url, _file).subscribe(
                (res) => {
                  this.imageUrlCtrl.setValue(response.fileName);
                  this.urlImageUrlCtrl.setValue(response.bucketPath + "/" + response.fileName);
                  this.imageUrlCtrl.markAsDirty();
                },
                (err) => console.log(err)
              )
            );
          },
          (err) => console.log(err)
        );
      } else {
        this.messageService.add(this.alertMessage.getMessage("max-upload-size-warning"));
        this._fileUploader.clear();
      }
    });
  }

  get fileNameImageUrl() {
    return this._myForm.value.urlImageFileName;
  }

  get imageUrlCtrl(): AbstractControl {
    return this._myForm.get("imageFileName");
  }

  get urlImageUrlCtrl(): AbstractControl {
    return this._myForm.get("urlImageFileName");
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
