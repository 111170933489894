/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewContainerRef,
  OnInit,
  OnDestroy,
  OnChanges,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ConfirmationService, MenuItem, MessageService, SelectItem } from "primeng/api";
import { Tender_Type } from "src/app/core/settings/business-settings/tender-type/tender-type";
import { AppSettingsStorageService } from "../../../../shared/app-settings-storage.service";
import {
  PrintingFactoryService,
  PrintingMgr,
  ReceiptPrintingSize,
} from "../../../../shared/services/printing-service.service";
import { WebHelpers } from "../../../../utility/WebHelpers";
import { DocState } from "../../doc/doc";
import { StoreSettingEmail } from "src/app/core/settings/store-settings/store-mail-settings/StoreSettingEmail";
import { Subscription } from "rxjs";
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { Router } from "@angular/router";

import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";
import * as _ from "lodash";
import { MonerisService } from "src/app/core/settings/integration-settings/moneris-terminal/moneris.service";
import { TakuPayService } from "src/app/core/settings/integration-settings/taku-pay/taku-pay.service";
import { pairwise } from "rxjs/operators";
import { DBService } from "src/app/shared/services/db.service";
import moment from "moment";
import { OverlayPanel } from "primeng/overlaypanel";
import { Clipboard } from "@angular/cdk/clipboard";
import { AccountDialogWrapperComponent } from "src/app/core/contact-accounts/account-dialog-wrapper/account-dialog-wrapper.component";
import { ACTION_TYPE, BUTTON_SIZE } from "src/app/shared/components/action-button/action-type.enum";
import { InventoryDoc } from "../inventory-document/inventory-document";

enum DialogName {
  PRINT_PREVIEW = "printPreview",
}

@Component({
  selector: "taku-inventory-document-preview",
  templateUrl: "./inventory-document-preview.component.html",
  styleUrls: ["./inventory-document-preview.component.scss"],
  providers: [DialogService],
})
export class InventoryDocumentPreviewComponent implements OnChanges, OnInit, OnDestroy {
  public ACTION_TYPE: typeof ACTION_TYPE = ACTION_TYPE;
  public BUTTON_SIZE: typeof BUTTON_SIZE = BUTTON_SIZE;
  subsList: Subscription[] = [];
  ReceiptPrintingSize = ReceiptPrintingSize;
  @Input() _object;
  @Input() accountName;
  @Output() dialogClosed: EventEmitter<any> = new EventEmitter();
  @Output() changedForm: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild("printPreviewHost", { read: ViewContainerRef, static: true }) printPreviewHost: ViewContainerRef;
  @ViewChild("deliveryDatePanel", { static: false }) deliveryDatePanel: OverlayPanel;
  _enabledEditMode = true;

  private emailSettings: StoreSettingEmail;
  enum_saledoc_fulfillment_status: SelectItem[];
  enum_saledoc_delivery_method: SelectItem[];
  enum_saledoc_delivery_status: SelectItem[];
  progressBar: DynamicDialogRef;

  Tender_Type = Tender_Type;
  inventoryDoc: InventoryDoc;
  tendersPrettyPrint: string;
  _activeFullDialog: DialogName;
  _activeFullDialogExtra: any;
  footerFormGroup: UntypedFormGroup;
  _isStore: boolean;
  printSizesMenuItems: MenuItem[] = [];
  _currencyISOCode = "CAD";
  hasTakuPaymentTerminal: boolean;
  orderCreationDateObj: Date;
  isVoidDisabled = false;

  printMgr: PrintingMgr;
  _headerTitle = "";
  decimalFormat = "1.2-2";

  constructor(
    private fb: UntypedFormBuilder,
    appSettings: AppSettingsStorageService,
    protected _router: Router,
    printingMgrFactory: PrintingFactoryService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    protected confirmationService: ConfirmationService,
    private appSettingsService: AppSettingsStorageService,
    private messageService: MessageService,
    private alertMessagesService: AlertMessagesService,
    private dialogService: DialogService,
    public monerisService: MonerisService,
    private dbService: DBService,
    public takuPayService: TakuPayService,
    private clipboard: Clipboard
  ) {
    this._isStore = appSettings.getStoreId() ? true : false;
    this._currencyISOCode = appSettings.getZone().defaultCurrencyIsoCode;
    if (this.config && this.config.data && this.config.data._object) {
      this._object = this.config.data._object;
      this.accountName = this.config.data.accountName;
      this._enabledEditMode = this.config.data._enabledEditMode || this._enabledEditMode;
      this.inventoryDoc = InventoryDoc.convertIntoTypedObject(this._object);

      this.printMgr = printingMgrFactory.build(appSettings.getStoreId());
    } else {
      this.printMgr = printingMgrFactory.build(appSettings.getStoreId());
    }
    this.footerFormGroup = this.fb.group({
      emailAddress: this.fb.control(null, Validators.email),
      docDate: this.fb.control(this.inventoryDoc ? this.inventoryDoc.doc.docDate : null),
    });

    if (this.inventoryDoc?.doc?.docDate) {
      this.orderCreationDateObj = moment(this.inventoryDoc.doc.docDate).toDate();
    } else {
      this.orderCreationDateObj = new Date();
    }
  }

  ngOnInit(): void {
    this.subsList.push(
      this.footerFormGroup.controls.docDate.valueChanges.pipe(pairwise()).subscribe(([prev, next]) => {
        if (prev != next) {
          this.onDocDateChanged();
          this.deliveryDatePanel.toggle(null, this.deliveryDatePanel.el.nativeElement);
        }
      })
    );
    this.printSizesMenuItems = [
      {
        label: "Receipt (Tape)",
        command: () => {
          // this.onReprintDoc(ReceiptPrintingSize.TAPE_SIZE);
        },
        icon: "takuicon-measuring-tape",
      },
      {
        label: "Receipt (Letter)",
        command: () => {
          // this.onReprintDoc(ReceiptPrintingSize.LETTER_SIZE);
        },
        icon: "takuicon-invoice",
      },
      {
        label: "Card Receipt",
        command: () => {
          // this.onReceiptCardPrintPressed();
        },
        icon: "text-3xl pi pi-credit-card",
      },
      {
        label: "Gift Receipt",
        command: () => {
          // this.onReprintGiftReceipt();
        },
        icon: "takuicon-gift",
      },
      {
        label: "Packing List (Tape)",
        command: () => {
          // this.onPrintPackingList(ReceiptPrintingSize.TAPE_SIZE);
        },
        icon: "takuicon-measuring-tape",
        disabled: this.inventoryDoc.doc.state === "Voided",
      },
      {
        label: "Packing List (Letter)",
        command: () => {
          // this.onPrintPackingList(ReceiptPrintingSize.LETTER_SIZE);
        },
        icon: "takuicon-invoice",
        disabled: this.inventoryDoc.doc.state === "Voided",
      },
      {
        label: "Prep Order (Tape)",
        command: () => {
          // Print prep
          // this.onPrintPrep();
        },
        icon: "takuicon-measuring-tape",
        disabled: this.inventoryDoc.doc.state === "Voided" || !this.appSettingsService.getStation()?.prepPrinter,
      },
    ];

    this.subsList.push(
      this.appSettingsService.getStoreSettings("storeSettingEmail").subscribe({
        next: (settings) => {
          this.emailSettings = settings;
        },
        error: (error) => {
          this.emailSettings = null;
        },
      })
    );

    this.ngOnChanges();
  }

  ngOnChanges(): void {
    this.inventoryDoc = InventoryDoc.convertIntoTypedObject(this._object);
    this._headerTitle = `<span>DOC# ${this.inventoryDoc.doc.docNo} -&nbsp;</span><span style="color: orange;">${this.inventoryDoc.doc.state}</span>`;

    this.footerFormGroup.setValue({
      emailAddress: this.inventoryDoc?.account?.commercialAccount?.commercialAccountEmails[0]?.email || "",
      docDate: this.inventoryDoc.doc.docDate,
    });
    this.setIsVoidDisabled();
  }

  get sendEmailEnabled() {
    return this.emailSettings && this.emailSettings.id;
  }

  get _isMobile() {
    return WebHelpers.isMobileScreen();
  }

  get isVoided() {
    return this.inventoryDoc.doc.state === DocState.voided;
  }

  setIsVoidDisabled(): void {
    this.isVoidDisabled = this.isVoided;
  }

  onClosePressed() {
    this.dialogClosed.emit(this.inventoryDoc);
    if (this.ref) {
      this.ref.close();
    }
  }

  closeFullSizeDialog() {
    this._activeFullDialog = null;
  }

  // onPrintPackingList(printSize: ReceiptPrintingSize) {
  //   const _tempSaleDoc = Object.assign({}, this.inventoryDoc);
  //   _tempSaleDoc["isPackingList"] = true;
  //   this.printMgr.printDocumentsWithSize([_tempSaleDoc], this.printPreviewHost, printSize);
  // }

  // onPrintPrep() {
  //   this.printMgr.printStarCloudPRNTPrepInBatch([this.inventoryDoc]);
  // }

  // onReprintDoc(printSize: ReceiptPrintingSize) {
  //   if (PrintHelpers.isTakuPayTransaction(this.inventoryDoc)) {
  //     Object.assign(this.inventoryDoc, { isMerchantReceipt: false }, { isCustomerReceipt: true });
  //     this.printMgr.printDocumentsWithSize([this.inventoryDoc], this.printPreviewHost, printSize);
  //   } else {
  //     this.printMgr.printDocumentsWithSize([this.inventoryDoc], this.printPreviewHost, printSize);
  //   }
  // }

  onDocDateChanged() {
    this.dbService
      .patchRow("saleDoc", { id: this.inventoryDoc.id, "doc.docDate": this.footerFormGroup.get("docDate").value })
      .subscribe({
        next: () => {
          this.inventoryDoc.doc.docDate = this.footerFormGroup.get("docDate").value;

          this.messageService.add(this.alertMessagesService.getMessage("save-success"));
        },
        error: (error) => {
          this.footerFormGroup.reset(this.inventoryDoc);
          this.messageService.add(this.alertMessagesService.getErrorMessage(error));
        },
      });
  }

  onVoidPressed() {
    this.dbService
      .patchRow("inventoryDoc", {
        id: this.inventoryDoc.id,
        "doc.docDate": this.footerFormGroup.get("docDate").value,
      })
      .subscribe({
        next: () => {
          this.inventoryDoc.doc.docDate = this.footerFormGroup.get("docDate").value;
          this.messageService.add(this.alertMessagesService.getMessage("save-success"));
        },
        error: (error) => {
          this.footerFormGroup.reset(this.inventoryDoc);
          this.messageService.add(this.alertMessagesService.getErrorMessage(error));
        },
      });
  }

  // onEmailDoc(printSize: ReceiptPrintingSize) {
  //   this.subsList.push(
  //     this.printMgr
  //       .sendInvoiceEmail(this.inventoryDoc, this.emailAddressCtrl.value, this.printPreviewHost, printSize)
  //       .subscribe({
  //         next: (response) => {
  //           // Do nothing for NOW
  //         },
  //       })
  //   );
  // }

  openFullSizeDialog(dialog: DialogName, extra = null) {
    this._activeFullDialog = dialog;
    this._activeFullDialogExtra = extra;
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }

  copyEmailToClipboard(email: string): void {
    this.clipboard.copy(email);
  }

  openCustomerDetails(): void {
    this.config.data.account = this.inventoryDoc.account.commercialAccount;
    this.dialogService.open(AccountDialogWrapperComponent, this.config);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
