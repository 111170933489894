/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
import { PaymentGateway } from "../payment-gateways/payment-gateway";

export class PaymentTerminal {
  id: number;
  terminalName: string;
  info: any;
  isPaired: boolean;
  isActive: boolean;
  stationId: number; // lookup for station
  paymentGatewayId: number; // lookup
  paymentGateway: PaymentGateway;

  constructor() {
    this.id = 0;
    this.terminalName = null;
    this.info = null;
    this.isPaired = false;
    this.isActive = false;
    this.stationId = null;
    this.paymentGatewayId = null;
    this.paymentGateway = null; // lookup
  }
}

import { Injectable } from "@angular/core";
export class RequestTransaction {
  command = "";
  key = "";
  password = "";
  amount = "";
  refID = "";
  terminalID = "";
}

export class ResponseTransaction {
  TerminalID = "";
  ResultFinal = "";
  AuthCode = "";
  VoidAuthCode?: string;
  TransType = "";
  Amount = "";
  AmountString = "";
  CardType = "";
  Last4 = "";
  Name = "";
  CashBack = "";
  CashBackString = "";
  RefID = "";
  receiptTerminal = "";
  merchantReceipt = "";
  CardToken = "";
  GiftCardBalance = "";
  GiftCardBalanceString = "";
  ProcessorExtraData1 = "";
  ProcessorExtraData2 = "";
  ProcessorExtraData3 = "";
  SignatureData = "";
  Track1 = "";
  Track2 = "";
  EMV_TC = "";
  EMV_TVR = "";
  EMV_AID = "";
  EMV_TSI = "";
  EMV_ATC = "";
  EMV_App_Label = "";
  EMV_App_Name = "";
  EMV_ARC = "";
  EMV_CVM = "";
  CardBin = "";
  EntryMethod = "";
  PaymentMethod = "";
  TransactionDate = "";
  TransactionDateUtc = "";
  InvoiceNumber = "";
  ExpDate = "";
  GiftPAN = "";
  BatchNumber = "";
  SurchargeFee = "";
  ResultCode = "";
  Message = "";
  TakuDisplayStatus = "";
  /** TakuPay only */
  PaymentId = "";
  /** TakuPay only */
  Cryptogram = "";
  /** TakuPay only */
  PaymentType = "";
}

export class RequestCheckStatus {
  command = "";
  key = "";
  password = "";
  terminalId = "";
  refID = "";
  date = "";
  merchantID = "";
}

export class RequestGetSignature {
  terminalId = "";
  key = "";
  password = "";
}

export class RequestCloseBatch {
  key = "";
  password = "";
  terminalId = "";
  refID = "";
  merchantID = "";
}

export class ResponseCloseBatch {
  TerminalID = "";
  RefID = "";
  ResultCodeGift = "";
  MessageCredit = "";
  MessageGift = "";
  BatchNumCredit = "";
  BatchNumGift = "";
  TransCntTotal = "";
  AmtTotal = "";
  AmtTotalString = "";
  TransCntCredit = "";
  AmtCredit = "";
  AmtCreditString = "";
  TransCntGift = "";
  AmtGift = "";
  AmtGiftString = "";
}

@Injectable()
export class RequestPairTerminal {
  // Required Fields
  resellerName = "takuretail";
  referenceID = "";
  terminalSerialNumber = "";
  businessName = "Taku Retail POS";
  contactName = "Taku Retail POS";
  phone = "1-855-660-8258";
  country = "Canada";
  // Optional Fields
  address = "";
  city = "";
  state = "";
  zipCode = "";
  email = "";
}

export class ResponsePairTerminal {
  TerminalId = "";
  ApiKey = "";
  ApiPassword = "";
  Status = "";
  ErrorMessage = "";
}

export class RequestUnpairTerminal {
  terminalId = "";
  apiKey = "";
  apiPassword = "";
}

export class ResponseUnpairTerminal {
  TerminalID = "";
  ApiKey = "";
  ApiPassword = "";
  Status = "";
  ErrorMessage = "";
}

export enum CommandName {
  COMMAND_SALE = "sale",
  COMMAND_VOID = "void",
  COMMAND_REFUND = "refund",
  COMMAND_CAPTURE = "capture",
  COMMAND_PURCHASE = "purchase",
  COMMAND_TIP_ADJUST = "TipAdjust",
  COMMAND_REFUND_CORRECTION = "refundCorrection",
  COMMAND_PURCHASE_CORRECTION = "purchaseCorrection",
}

export enum MenuTenderLineUndoName {
  SALE_VOID = "Sale Void",
  SALE_REFUND = "Sale Refund",
  VOID_REFUND = "Void Refund",
}

export enum TenderLineStatusName {
  SALE_APPROVED = "Approved",
  SALE_VOIDED = "Voided",
  SALE_REFUNDED = "Refunded",
  REFUND_VOIDED = "Refund Voided",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
