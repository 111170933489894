/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { DataServiceInterface } from "./DataService.interface";
import { Observable, of, Subject } from "rxjs";
import * as _ from "lodash";
import { map, tap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { DBService } from "./db.service";

@Injectable({
  providedIn: "root",
})
export class LocalDataService extends DBService implements DataServiceInterface {
  public _allLocalData = {};
  // inherited from dataservice
  dataKey = "_row_local_id";
  private objectSubject = new Subject<any>();
  _newObject$ = this.objectSubject.asObservable();

  updateNewObject(data: any) {
    this.objectSubject.next(data);
  }

  initialize(data: any[], _model: string) {
    data.forEach((row) => {
      this.addDataKey(row);
    });
    this._allLocalData[_model] = data;
  }

  protected afterFetch(object: any, model: string) {}

  getRows(
    _model: string,
    _filter?: any,
    _offset = 0,
    _limit?: number,
    _sortField?: string,
    _sortOrder?: number,
    _extraParams?: any
  ): Observable<any> {
    if (_filter && typeof _filter.valueOf() === "string") _filter = JSON.parse(_filter);
    let filteredRows = _.cloneDeep(this.getRawModelData(_model));
    _limit = _limit || filteredRows.length;
    const startOffset = _offset;
    const endOffset = _offset + _limit;
    // Add virtual fields to each object
    filteredRows.forEach((object) => Object.assign(object, this.extractVirtualFields(object, _model)));

    if (_filter)
      _.forOwn(_filter, (filter, field) => {
        const fieldValue = (filter.value + "").toLowerCase();
        if (filter.matchMode === "equals")
          filteredRows = filteredRows.filter((object) => (_.get(object, field) + "").toLowerCase() === fieldValue);
        else if (filter.matchMode === "contains")
          filteredRows = filteredRows.filter((object) =>
            (_.get(object, field) + "").toLowerCase().includes(fieldValue)
          );
      });
    if (_sortField)
      filteredRows.sort((row1, row2) => {
        const row1Val = _.get(row1, _sortField);
        const row2Val = _.get(row2, _sortField);

        if (row1Val > row2Val) return _sortOrder;
        else if (row1Val < row2Val) return -1 * _sortOrder;
        else return 0;
      });

    const countTotal = filteredRows.length;
    // Extract the data keys of found rows and then return raw/original data to remove new virtual fields and avoid potential errors
    const filteredDataKeys = filteredRows.slice(startOffset, endOffset).map((object) => object[this.dataKey]);
    // filteredRows = this.getRawModelData(_model).filter(object => filteredDataKeys.includes(object[this.dataKey]));
    filteredRows = filteredDataKeys.map((key) =>
      this.getRawModelData(_model).find((object) => object[this.dataKey] === key)
    );

    return of({
      count: countTotal,
      rows: _.cloneDeep(filteredRows),
    }).pipe(
      tap((response: any) => {
        response.rows.forEach((object: any) => this.afterFetch(object, _model));
      })
    );
  }

  getRow<T = any>(_model: string, id: number, queryParams: any = {}): Observable<T> {
    const foundObject = this.getRawModelData(_model).find((row) => row[this.dataKey] === id);
    return of(_.cloneDeep(foundObject)).pipe(tap((object) => this.afterFetch(object, _model)));
  }

  batchAddRows(_model: string, _objects: any[]): Observable<any[]> {
    return of();
  }

  batchDeleteRows(_model: string, ids: number[]): Observable<any[]> {
    this._allLocalData[_model] = this.getRawModelData(_model).filter((row) => !ids.includes(row[this.dataKey]));
    return of(
      ids.map((id) => ({
        batchId: id,
        response: { result: { success: true } },
      }))
    );
  }

  batchEditRows(_model: string, _objects: any[]): Observable<any[]> {
    this.getRawModelData(_model).forEach((row) => {
      const matchObj = _objects.find((obj) => obj[this.dataKey] === row[this.dataKey]);
      if (matchObj) {
        row = matchObj;
      }
    });
    return of(
      _objects.map((obj) => ({
        batchId: obj[this.dataKey],
        response: {
          result: {
            body: obj,
            success: true,
          },
        },
      }))
    );
  }

  addRow(_model: string, _object: any): Observable<any> {
    this.addDataKey(_object);
    // this.getRawModelData(_model).unshift(_object);
    return of(_object).pipe(tap((object) => this.afterFetch(object, _model)));
  }

  addRowTmp(_model: string, _object: any): Observable<any> {
    return this.addRow(_model, _object);
  }

  deleteRow(_model: string, id: number): Observable<any> {
    return this.getRow(_model, id).pipe(
      tap(() => {
        _.remove(this.getRawModelData(_model), (object) => object[this.dataKey] === id);
      })
    );
  }

  editRow(_model: string, _object: any): Observable<any> {
    return this.getRow(_model, _object[this.dataKey]).pipe(
      map((row) => Object.assign(row, _object)),
      tap((object) => this.afterFetch(object, _model))
    );
  }

  protected addDataKey(row) {
    row[this.dataKey] = _.uniqueId();
  }

  protected getRawModelData(_model: string): any[] {
    if (!this._allLocalData[_model]) this._allLocalData[_model] = [];

    return this._allLocalData[_model];
  }

  protected extractVirtualFields(_object, _model) {
    const newObject = _.cloneDeep(_object);
    this.afterFetch(newObject, _model);

    // return all new object fields/keys created after processing data
    const virtualKeys = _.difference(Object.keys(newObject), Object.keys(_object));
    return _.pick(newObject, virtualKeys);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
