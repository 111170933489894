/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

export class SaleBankReconciliationReport {
  id: number;
  tenderTypesType: string;
  tenderTypesDescription: string;
  tenderTypesCurrencyIsoCode: string;
  sumSaleDocTendersAmount: number;
  sumSaleDocTendersPercentageFee: number;
  sumSaleDocTendersFlatFee: number;

  constructor() {
    this.id = 0;
    this.tenderTypesType = null;
    this.tenderTypesDescription = null;
    this.tenderTypesCurrencyIsoCode = null;
    this.sumSaleDocTendersAmount = null;
    this.sumSaleDocTendersPercentageFee = null;
    this.sumSaleDocTendersFlatFee = null;
  }

  get netDeposit() {
    return (
      this.sumSaleDocTendersAmount -
      this.sumSaleDocTendersFlatFee -
      this.sumSaleDocTendersAmount * this.sumSaleDocTendersPercentageFee
    );

    // parseFloat(this.subTotal + '') - parseFloat(this.discountAmount + '')
    //   + parseFloat(this.totalTaxAmount + '') + parseFloat(this.shipperChargeAmount + '');
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
