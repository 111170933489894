/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Location } from "@angular/common";
import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { ActivatedRoute, NavigationCancel, NavigationEnd, Params, Router } from "@angular/router";
import { MenuItem } from "primeng/api";
import { TabMenu } from "primeng/tabmenu";
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { DBService } from "../../../../shared/services/db.service";
import { Role } from "../../role/role";

@Component({
  selector: "taku-access-rights",
  templateUrl: "./access-rights.component.html",
  styleUrls: ["./access-rights.component.scss"],
})
export class AccessRightsComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];

  // actionsCols: Col[] = [];
  // visibilityCols: Col[] = [];
  // privilegesCols: Col[] = [];
  // RowSelectionType = RowSelectionType;
  roleData$: Observable<Role>;
  menuAccessRights: MenuItem[];
  roleId: number;

  @ViewChild(TabMenu, { static: true }) menu: TabMenu;
  lastActiveItem: MenuItem;

  constructor(
    private router: Router,
    private dbService: DBService,
    protected location: Location,
    private route: ActivatedRoute
  ) {
    // this.actionsCols = this.roleDatasService.getFormListColumns();
    // this.visibilityCols = this.roleVisibilityService.getFormListColumns();
    // this.privilegesCols = this.rolePrivilegesService.getFormListColumns();
  }

  ngOnInit() {
    this.subsList.push(
      this.route.params.pipe(map((params: Params) => params.id)).subscribe((roleId) => {
        this.roleId = roleId;
        this.roleData$ = this.dbService.getRow("role", roleId);
      })
    );

    this.menuAccessRights = [
      { label: "Data", routerLink: ["list/n/roleData"], queryParams: { title: "Data" } },
      { label: "Visibility", routerLink: ["list/n/roleVisibility"], queryParams: { title: "Visibility" } },
      {
        label: "Privileges",
        routerLink: ["list/n/rolePrivilege"],
        queryParams: { title: "Privileges" },
        visible: false,
      },
    ];

    this.subsList.push(
      this.router.events.subscribe((e) => {
        if (e instanceof NavigationEnd) {
          this.lastActiveItem = this.menu.activeItem;
        }
        if (e instanceof NavigationCancel) {
          this.menu.activeItem = this.lastActiveItem;
        }
      })
    );
  }

  goBack() {
    if (this.location) {
      this.location.back();
    }
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
