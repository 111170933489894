/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { EventEmitter, OnInit, Component, OnDestroy, Output, Input } from "@angular/core";
import { DialogService } from "primeng/dynamicdialog";
import { AuthService } from "src/app/shared/services/auth.service";
import { Subscription } from "rxjs";
import { InventorySaleChannelComponent } from "../inventory-sale-channel/inventory-sale-channel.component";
import { InventorySaleChannel } from "../inventory-sale-channel/inventory-sale-channel";
import { UntypedFormArray, UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import { DBService } from "src/app/shared/services/db.service";
import { ChannelType } from "../../sale-channel/sale-channel";
import { ConfirmationService } from "primeng/api";

export type SettingCardSection = {
  label: string;
  cards: SettingCardInfo[];
};

export type SettingCardInfo = {
  routePath?: string[];
  channelType?: ChannelType;
  openAction?: EventEmitter<any>;
  iconStyleClass: string;
  iconName?: string;
  cardTitle: string;
  cardDesc: string[];
  routeQueryParams?: {};
  isVisible?: boolean;
  isEnabled?: boolean;
  isConnected?: boolean;
};
@Component({
  selector: "taku-inventory-integration-list",
  templateUrl: "./inventory-integration-list.component.html",
  styleUrls: ["./inventory-integration-list.component.scss"],
})
export class InventoryIntegrationListComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];
  isGoogleShoppingVisible = false;
  isGoogleShoppingEnabled = false;
  isTakuEcommerceVisible = false;
  isTakuEcommerceEnabled = false;
  // isWooCommerceVisible = false;
  // isWooCommerceEnabled = false;
  // isFacebookVisible = false;
  // isFacebookEnabled = false;
  // isInstagramVisible = false;
  // isInstagramEnabled = false;
  // isAmazonVisible = false;
  // isAmazonEnabled = false;

  cardsInfo: SettingCardSection[] = [];
  _saleChannelAction = new EventEmitter<any>();
  _googleShoppingAction = new EventEmitter<any>();
  dialogVisible = false;
  _currFormGroup;

  @Input("formGroup") _myForm: UntypedFormGroup;
  @Output() isDisplayGoogleShoppingEventEmitter = new EventEmitter<boolean>();
  @Output() inventorySaleChannel = new EventEmitter<InventorySaleChannel>();

  /*
  _takuEcommerceAction = new EventEmitter<any>();
  _wooCommerceAction = new EventEmitter<any>();
  _facebookAction = new EventEmitter<any>();
  _instagramAction = new EventEmitter<any>();
  _amazonAction = new EventEmitter<any>();
  */

  constructor(
    private authService: AuthService,
    private dbService: DBService,
    public fb: UntypedFormBuilder,
    protected confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.isGoogleShoppingVisible = this.authService.hasVisiblePermit("Apps_Menu_Google_Shopping_Card");
    this.isGoogleShoppingEnabled = this.authService.hasEnablePermit("Apps_Menu_Google_Shopping_Card");
    this.isTakuEcommerceEnabled = this.authService.hasEnablePermit("Apps_Menu_Taku_eCommerce");
    this.isTakuEcommerceVisible = this.authService.hasVisiblePermit("Apps_Menu_Taku_eCommerce");

    this.initializeCards();

    this.subsList.push(
      this._googleShoppingAction.subscribe(() => {
        if (this.isGoogleShoppingEnabled && this.isGoogleShoppingVisible) {
          this.onGoogleShoppingOpened();
        }
      })
    );

    this.subsList.push(
      this._saleChannelAction.subscribe((channelType: ChannelType) => {
        this.onSaleChannelOpened(channelType);
      })
    );
  }

  private initializeCards() {
    this.cardsInfo = [
      {
        label: "Sales Channels",
        cards: [
          {
            channelType: ChannelType.takuecommerce,
            openAction: this._saleChannelAction,
            iconStyleClass: "takuicon-shopping-bag-solid",
            cardTitle: "TAKU eCommerce",
            cardDesc: ["Manage this item in your TAKU eCommerce Sales Channel"],
            isConnected: true,
            isEnabled: this.isTakuEcommerceEnabled,
            isVisible: this.isTakuEcommerceVisible,
          },
          {
            channelType: ChannelType.shopify,
            openAction: this._saleChannelAction,
            iconStyleClass: "takuicon-shopify-brands",
            cardTitle: "Shopify",
            cardDesc: ["Manage this app in your Shopify Sales Channel"],
            isConnected: false,
            isEnabled: false,
          },
          {
            channelType: ChannelType.woocommerce,
            openAction: this._saleChannelAction,
            iconStyleClass: "takuicon-wordpress-brands",
            cardTitle: "WooCommerce",
            cardDesc: ["Manage this item in your WooCommerce Sales Channel"],
            isEnabled: false,
            isConnected: false,
          },
          {
            channelType: ChannelType.facebook,
            openAction: this._saleChannelAction,
            iconStyleClass: "takuicon-facebook-brands",
            cardTitle: "Facebook",
            cardDesc: ["Manage this item in your Facebook Sales Channel"],
            isEnabled: false,
            isConnected: false,
          },
          {
            channelType: ChannelType.instagram,
            openAction: this._saleChannelAction,
            iconStyleClass: "takuicon-instagram-square-brands",
            cardTitle: "Instagram",
            cardDesc: ["Manage this item in your Instagram Sales Channel"],
            isEnabled: false,
            isConnected: false,
          },
          {
            channelType: ChannelType.amazon,
            openAction: this._saleChannelAction,
            iconStyleClass: "takuicon-amazon-brands",
            cardTitle: "Amazon",
            cardDesc: ["Manage this item in your Amazon Sales Channel"],
            isEnabled: false,
            isConnected: false,
          },
        ],
      },
      {
        label: "Marketing",
        cards: [
          {
            openAction: this._googleShoppingAction,
            iconStyleClass: "takuicon-google-integration",
            cardTitle: "Google Shopping",
            cardDesc: ["Manage this item in your Google Merchant Center Account"],
            isVisible: this.isGoogleShoppingVisible,
            isEnabled: this.isGoogleShoppingEnabled,
            isConnected: true,
          },
        ],
      },
    ];
  }

  private onSaleChannelOpened(channelType: ChannelType) {
    const channelTypeFormGroupArray = (this._myForm.get("inventorySaleChannels") as UntypedFormArray).controls.filter(
      (row) => {
        return row.value.saleChannel.channelType === channelType && row.value.saleChannel.isConnected;
      }
    );
    let channelTypeFormGroup: UntypedFormGroup;
    if (channelTypeFormGroupArray.length === 0) {
      this._myForm.markAsDirty();
      channelTypeFormGroup = InventorySaleChannelComponent.init(this.fb);
      const queryObject = {
        channelType: {
          value: channelType,
          matchMode: "equals",
        },
        isConnected: {
          value: true,
          matchMode: "equals",
        },
      };
      this.dbService.getRows("saleChannel", JSON.stringify(queryObject)).subscribe((saleChannels) => {
        if (saleChannels.count === 0) {
          this.confirmationService.confirm({
            header: "Confirmation",
            message: "There is no sale channel created or connected for this channel type",
            rejectLabel: "Close",
            rejectVisible: true,
            acceptVisible: false,
          });
        } else {
          channelTypeFormGroup.get("saleChannelId").setValue(saleChannels.rows[0].id);
          channelTypeFormGroup.get("saleChannel").setValue(saleChannels.rows[0]);
          channelTypeFormGroup.get("uploadStatus").setValue(saleChannels.rows[0].autoEnableNewProduct ? "Yes" : "No");
          channelTypeFormGroup
            .get("onlineStatus")
            .setValue(saleChannels.rows[0].autoPublishNewProduct ? "Published" : "Unpublished");
          (this._myForm.get("inventorySaleChannels") as UntypedFormArray).push(channelTypeFormGroup);

          this.openSaleChannelDialog(channelTypeFormGroup);
        }
      });
    } else {
      channelTypeFormGroup = channelTypeFormGroupArray[0] as UntypedFormGroup;

      this.openSaleChannelDialog(channelTypeFormGroup);
    }
  }

  private openSaleChannelDialog(formGroup: UntypedFormGroup) {
    // this.dialogService.open(InventorySaleChannelComponent, {
    //   // styleClass: 'inventory-gallery-dialog fullscreen-mobilemodal',
    //   contentStyle: {
    //     overflow: 'auto',
    //     // width: '85vw',
    //     maxWidth: '600pt',
    //     // minWidth: '420pt',
    //     padding: '0',
    //   },
    //   data: {
    //     formGroup: formGroup
    //   }
    // });
    this._currFormGroup = formGroup;
    this.dialogVisible = true;
  }

  private onGoogleShoppingOpened() {
    this.isDisplayGoogleShoppingEventEmitter.emit(true);
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
