/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { LocationStrategy, PathLocationStrategy } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserModule, Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
// import { AngularResizeEventModule } from 'angular-resize-event';
import { AccordionModule } from "primeng/accordion";
import { AutoCompleteModule } from "primeng/autocomplete";
import { BlockUIModule } from "primeng/blockui";
import { ButtonModule } from "primeng/button";
import { CalendarModule } from "primeng/calendar";
import { CarouselModule } from "primeng/carousel";
import { ChartModule } from "primeng/chart";
import { CheckboxModule } from "primeng/checkbox";
import { ChipModule } from "primeng/chip";
import { ChipsModule } from "primeng/chips";
import { CodeHighlighterModule } from "primeng/codehighlighter";
import { ColorPickerModule } from "primeng/colorpicker";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ContextMenuModule } from "primeng/contextmenu";
import { DialogModule } from "primeng/dialog";
import { CardModule } from "primeng/card";
import { DragDropModule } from "primeng/dragdrop";
import { DropdownModule } from "primeng/dropdown";
import { DividerModule } from "primeng/divider";
import { DialogService, DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef } from "primeng/dynamicdialog";
import { EditorModule } from "primeng/editor";
import { FieldsetModule } from "primeng/fieldset";
import { FileUploadModule } from "primeng/fileupload";
import { GalleriaModule } from "primeng/galleria";
import { GMapModule } from "primeng/gmap";
import { InputMaskModule } from "primeng/inputmask";
import { InputSwitchModule } from "primeng/inputswitch";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { ListboxModule } from "primeng/listbox";
import { MegaMenuModule } from "primeng/megamenu";
import { MenuModule } from "primeng/menu";
import { MenubarModule } from "primeng/menubar";
import { MessageModule } from "primeng/message";
import { MessagesModule } from "primeng/messages";
import { MultiSelectModule } from "primeng/multiselect";
import { OrderListModule } from "primeng/orderlist";
import { OrganizationChartModule } from "primeng/organizationchart";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { PaginatorModule } from "primeng/paginator";
import { PanelModule } from "primeng/panel";
import { PanelMenuModule } from "primeng/panelmenu";
import { PasswordModule } from "primeng/password";
import { PickListModule } from "primeng/picklist";
import { ProgressBarModule } from "primeng/progressbar";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { RadioButtonModule } from "primeng/radiobutton";
import { RatingModule } from "primeng/rating";
import { SelectButtonModule } from "primeng/selectbutton";
import { SidebarModule } from "primeng/sidebar";
import { SliderModule } from "primeng/slider";
import { InputNumberModule } from "primeng/inputnumber";
import { SpinnerModule } from "primeng/spinner";
import { SplitButtonModule } from "primeng/splitbutton";
import { StepsModule } from "primeng/steps";
import { TableModule } from "primeng/table";
import { TagModule } from "primeng/tag";
import { TabMenuModule } from "primeng/tabmenu";
import { TabViewModule } from "primeng/tabview";
import { TieredMenuModule } from "primeng/tieredmenu";
import { ToggleButtonModule } from "primeng/togglebutton";
import { ToolbarModule } from "primeng/toolbar";
import { TooltipModule } from "primeng/tooltip";
import { TreeModule } from "primeng/tree";
import { TreeSelectModule } from "primeng/treeselect";
import { SkeletonModule } from "primeng/skeleton";
// Added when upgrading Barcelona theme to v6
import { DataViewModule } from "primeng/dataview";
import { KeyFilterModule } from "primeng/keyfilter";
import { SelectItem, MessageService, ConfirmationService, SharedModule } from "primeng/api";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { ScrollTopModule } from "primeng/scrolltop";
import { ToastModule } from "primeng/toast";
import { TriStateCheckboxModule } from "primeng/tristatecheckbox";
import { AppMenuComponent } from "./app-menu/app-menu.component";
import { AuthGuard } from "./app-routing/auth.guard";
import { CashoutResolverService } from "./app-routing/cashout-resolver.service";
import { ObjectDetailResolverService } from "./app-routing/object-detail-resolver.service";
import { UnsavedFormListGuard } from "./app-routing/unsaved-form-list.guard";
import { AppRoutingModule } from "./app-routing/routing.module";
import { UnsavedChangesGuard } from "./app-routing/UnsavedChanges.guard";
import { InvoiceGuard } from "./app-routing/invoice.guard";
import { AppTopbarComponent } from "./app-topbar/topbar.component";
import { AppComponent } from "./app.component";
import { CashoutClosetillComponent } from "./core/cashout/cashout-closetill/cashout-closetill.component";
import { CashoutClosingBreakdownComponent } from "./core/cashout/cashout-closing-breakdown/cashout-closing-breakdown.component";
import { CashoutClosingFloatComponent } from "./core/cashout/cashout-closing-float/cashout-closing-float.component";
import { CashoutHistoryPreviewComponent } from "./core/cashout/cashout-history-preview/cashout-history-preview.component";
import { CashoutHistoryComponent } from "./core/cashout/cashout-history/cashout-history.component";
import { CashoutOpenflowComponent } from "./core/cashout/cashout-openflow/cashout-openflow.component";
import { CashoutOpeningFloatComponent } from "./core/cashout/cashout-opening-float/cashout-opening-float.component";
import { CashoutPaymentBreakdownComponent } from "./core/cashout/cashout-payment-breakdown/cashout-payment-breakdown.component";
import { CashoutTenderTransactionsComponent } from "./core/cashout/cashout-tender-transactions/cashout-tender-transactions.component";
import { CashoutComponent } from "./core/cashout/cashout/cashout.component";
import { AccountComponent } from "./core/contact-accounts/account/account.component";
import { AddressEmailComponent } from "./core/contact-accounts/address-email/address-email.component";
import { AddressPhoneComponent } from "./core/contact-accounts/address-phone/address-phone.component";
import { AddressComponent } from "./core/contact-accounts/address/address.component";
import { CommercialAccountAddressComponent } from "./core/contact-accounts/commercial-account-address/commercial-account-address.component";
import { CommercialAccountEmailComponent } from "./core/contact-accounts/commercial-account-email/commercial-account-email.component";
import { CommercialAccountInstantMsgComponent } from "./core/contact-accounts/commercial-account-instant-msg/commercial-account-instant-msg.component";
import { CommercialAccountPhoneComponent } from "./core/contact-accounts/commercial-account-phone/commercial-account-phone.component";
import { CommercialAccountSocialProfileComponent } from "./core/contact-accounts/commercial-account-social-profile/commercial-account-social-profile.component";
import { CommercialAccountComponent } from "./core/contact-accounts/commercial-account/commercial-account.component";
import { ContactComponent } from "./core/contact-accounts/contact/contact.component";
import { PersonAddressComponent } from "./core/contact-accounts/person-address/person-address.component";
import { PersonEmailComponent } from "./core/contact-accounts/person-email/person-email.component";
import { PersonInstantMsgComponent } from "./core/contact-accounts/person-instant-msg/person-instant-msg.component";
import { PersonPhoneComponent } from "./core/contact-accounts/person-phone/person-phone.component";
import { PersonSocialProfileComponent } from "./core/contact-accounts/person-social-profile/person-social-profile.component";
import { PersonComponent } from "./core/contact-accounts/person/person.component";
import { PersonalAccountComponent } from "./core/contact-accounts/personal-account/personal-account.component";
import { StoreEmailComponent } from "./core/contact-accounts/store-email/store-email.component";
import { StoreInstantMsgComponent } from "./core/contact-accounts/store-instant-msg/store-instant-msg.component";
import { StoreSocialProfileComponent } from "./core/contact-accounts/store-social-profile/store-social-profile.component";
import { AccountsSearchResComponent } from "./core/document/accounts-search-res/accounts-search-res.component";
import { InventoryDocPreviewComponent } from "./core/document/inventory-doc-preview/inventory-doc-preview.component";
import { InventoryDocumentComponent } from "./core/document/inventory-document/inventory-document/inventory-document.component";
import { ItemSearchResComponent } from "./core/document/item-search-res/item-search-res.component";
import { DocumentSearchForReturnComponent } from "./core/document/return-document/document-search-return/document-search-return.component";
import { ReturnSaleDocFooterComponent } from "./core/document/return-document/return-sale-doc-footer/return-sale-doc-footer.component";
import { ReturnSaleDocLineComponent } from "./core/document/return-document/return-sale-doc-line/return-sale-doc-line.component";
import { ReturnSaleDocComponent } from "./core/document/return-document/return-sale-doc/return-sale-doc.component";
import { CRMSaleComponent } from "./core/document/sale-document/crm-sale/crm-sale.component";
import { InvoiceDetailsComponent } from "./core/document/sale-document/invoice-details/invoice-details.component";
import { ParkedSalesPreviewComponent } from "./core/document/sale-document/parked-sales-preview/parked-sales-preview.component";
import { ParkedSalesComponent } from "./core/document/sale-document/parked-sales/parked-sales.component";
import { RevisitSaleComponent } from "./core/document/sale-document/revisit-sale/revisit-sale.component";
import { SaleDocFooterComponent } from "./core/document/sale-document/sale-doc-footer/sale-doc-footer.component";
import { SaleDocLineTaxComponent } from "./core/document/sale-document/sale-doc-line-tax/sale-doc-line-tax.component";
import { SaleDocLineComponent } from "./core/document/sale-document/sale-doc-line/sale-doc-line.component";
import { SaleDocTaxComponent } from "./core/document/sale-document/sale-doc-tax/sale-doc-tax.component";
import { SaleDocTenderComponent } from "./core/document/sale-document/sale-doc-tender/sale-doc-tender.component";
import { SaleDocTransactionsComponent } from "./core/document/sale-document/sale-doc-transactions/sale-doc-transactions.component";
import { SaleDocComponent } from "./core/document/sale-document/sale-doc/sale-doc.component";
import { SaledocRevisitPreviewComponent } from "./core/document/sale-document/saledoc-revisit-preview/saledoc-revisit-preview.component";
import { TenderScreenComponent } from "./core/document/sale-document/tender-screen/tender-screen.component";
import { CategoryComponent } from "./core/inventory/category/category.component";
import { InventoryBarcodeComponent } from "./core/inventory/inventory-barcode/inventory-barcode.component";
import { InventoryGalleryDialogComponent } from "./core/inventory/inventory-gallery-dialog/inventory-gallery-dialog.component";
import { InventoryStockComponent } from "./core/inventory/inventory-stock/inventory-stock.component";
import { InventoryComponent } from "./core/inventory/inventory/inventory.component";
import { InventoryService } from "./core/inventory/inventory/inventory.service";
import { UOfMeasureComponent } from "./core/inventory/u-of-measure/u-of-measure.component";
import { ReceiptFullPreviewComponent } from "./core/previews/receipt/receipt-full-preview/receipt-full-preview.component";
import { ReceiptTapePreviewComponent } from "./core/previews/receipt/receipt-tape-preview/receipt-tape-preview.component";
import { ClosingCashoutSlipFullSizeComponent } from "./core/previews/slips/cashout-slip-full-size/cashout-slip-full-size.component";
import { ClosingCashoutSlipTapeSizeComponent } from "./core/previews/slips/cashout-slip-tape-size/cashout-slip-tape-size.component";
import { ReportsContainerComponent } from "./core/reports/reports-container/reports-container.component";
import { BackofficeComponent } from "./core/settings/backOffice-settings/backOffice/backOffice.component";
import { BusinessSettingsComponent } from "./core/settings/business-settings-cards/business-settings.component";
import { BusinessDetailComponent } from "./core/settings/business-settings/business-detail/business-detail.component";
import { BusinessFormatsSettingsComponent } from "./core/settings/business-settings/business-formats-settings/business-formats-settings.component";
import { ShipperComponent } from "./core/settings/business-settings/shipper/shipper.component";
import { StockComponent } from "./core/settings/business-settings/stock/stock.component";
import { SettingsCardsComponent } from "./core/settings/settings-cards/setting-cards.component";
import { StoreSettingsCardsComponent } from "./core/settings/store-settings-cards/location-settings.component";
import { AccountGroupComponent } from "./core/settings/store-settings/account-group/account-group.component";
import { CashoutSettingsComponent } from "./core/settings/store-settings/cashout-settings/cashout-settings.component";
import { FullPageSaleReceiptBuilderComponent } from "./core/settings/store-settings/full-page-salereceipt-builder/full-page-salereceipt-builder.component";
import { GiftReceiptBuilderComponent } from "./core/settings/store-settings/gift-receipt-builder/gift-receipt-builder.component";
import { ReturnReasonComponent } from "./core/settings/store-settings/return-reason/return-reason.component";
import { ReturnSettingsComponent } from "./core/settings/store-settings/return-settings/return-settings.component";
import { FieldsFormatComponent } from "./core/settings/store-settings/sale-doc-format/fields-format.component";
import { SalesDocSettingsComponent } from "./core/settings/store-settings/sales-doc-settings/sales-doc-settings.component";
import { SalesFormatsSettingsComponent } from "./core/settings/store-settings/sales-formats-settings/sales-formats-settings.component";
import { SettingsCashoutOpeningFloatComponent } from "./core/settings/store-settings/settings-cashout-opening-float/settings-cashout-opening-float.component";
import { StationComponent } from "./core/settings/store-settings/station/station.component";
import { StoreFormListComponent } from "./core/settings/store-settings/store-form-list/store-form-list.component";
import { StoreHourComponent } from "./core/settings/store-settings/store-hour/store-hour.component";
import { StorePoliciesSettingsComponent } from "./core/settings/store-settings/store-policies-settings/store-policies-settings.component";
import { StoreShipperComponent } from "./core/settings/store-settings/store-shipper/store-shipper.component";
import { TenderTypesSettingsComponent } from "./core/settings/store-settings/store-tender-type-settings/tender-types-settings.component";
import { StoreComponent } from "./core/settings/store-settings/store/store.component";
import { TapeSaleReceiptBuilderComponent } from "./core/settings/store-settings/tape-salereceipt-builder/tape-salereceipt-builder.component";
import { ZoneSettingsCardsComponent } from "./core/settings/zone-settings-cards/zone-settings-cards.component";
import { ZoneFormListComponent } from "./core/settings/zone-settings/zone-form-list/zone-form-list.component";
import { ZoneSwitcherComponent } from "./core/settings/zone-settings/zone-switcher/zone-switcher.component";
import { TaxAccountCategoryComponent } from "./core/tax/tax-account-category/tax-account-category.component";
import { TaxCategoriesComponent } from "./core/tax/tax-categories/tax-categories.component";
import { TaxInventoryCategoryComponent } from "./core/tax/tax-inventory-category/tax-inventory-category.component";
import { TaxRuleComponent } from "./core/tax/tax-rule/tax-rule.component";
import { TaxSettingsComponent } from "./core/tax/tax-settings/tax-settings.component";
import { TaxComponent } from "./core/tax/tax/tax.component";
import { ForgotLoginComponent } from "./core/users/forgot-login/forgot-login.component";
import { LoginComponent } from "./core/users/login/login.component";
import { UserComponent } from "./core/users/user/user.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { FormListHeaderComponent } from "./form-list/form-list-header/form-list-header.component";
import { FormListMainComponent } from "./form-list/form-list-main/form-list-main.component";
import {
  FormListComponent,
  TakuFormListDetailedViewDirective,
  TakuFormListHeaderCustomBtnsDirective,
} from "./form-list/form-list/form-list.component";
import { FormDialogeHeaderComponent } from "./forms/form-dialoge-header/form-dialoge-header.component";
import { FormDialogeSelectComponent } from "./forms/form-dialoge-select/form-dialoge-select.component";
import { FormFooterComponent } from "./forms/form-footer/form-footer.component";
import { FormHeaderComponent } from "./forms/form-header/form-header.component";
import { FormListValidationMessagesComponent } from "./forms/form-list-validation-messages/form-list-validation-messages.component";
import { StoreSwitcherComponent } from "./shared/components/store-switcher/store-switcher.component";
import { AutofocusDirective } from "./shared/directives/autofocus.directive";
import { PageLoadingComponent } from "./shared/page-loading/page-loading.component";
import { PageNotFoundComponent } from "./shared/page-not-found/page-not-found.component";
import { CamelToSentencePipe } from "./shared/pipes/camel-to-sentence.pipe";
import { CurrencySymbolPipe } from "./shared/pipes/currency-symbol.pipe";
import { DateFormatPipe } from "./shared/pipes/date-format.pipe";
import { DateTimeFormatPipe } from "./shared/pipes/date-time-format.pipe";
import { FileSizePipe } from "./shared/pipes/file-size.pipe";
import { FormatNegativeNumberPipe } from "./shared/pipes/format-negative-number.pipe";
import { LocalTimePipe } from "./shared/pipes/local-time.pipe";
import { ObjectToArrayPipe } from "./shared/pipes/object-to-array.pipe";
import { ReplaceLineBreaksPipe } from "./shared/pipes/replace-line-breaks.pipe";
import { AlertMessagesService } from "./shared/services/alert-messages.service";
import { AuthService } from "./shared/services/auth.service";
import { CountryPhoneMaskService } from "./shared/services/country-phone-mask.service";
import { CurrencyFormatterService } from "./shared/services/currency-formatter.service";
import { DBSearchService } from "./shared/services/db-search.service";
import { UnauthorizedRequestInterceptor } from "./shared/services/http-interceptors/unauthorized-request-interceptor.service";
import { LocationService } from "./shared/services/location.service";
import { TakuAddressValidationService } from "./shared/services/taku-address-validation.service";
import { UserProfileService } from "./shared/services/user-profile.service";
import { UserRolePipe } from "./shared/user-role.pipe";
import { TakuAutoCompleteComponent } from "./taku-ui/taku-auto-complete/taku-auto-complete.component";
import { TakuCalendarDateComponent } from "./taku-ui/taku-calendar-date/taku-calendar-date.component";
import { TakuCalendarDatetimeComponent } from "./taku-ui/taku-calendar-datetime/taku-calendar-datetime.component";
import { TakuCalendarRangeComponent } from "./taku-ui/taku-calendar-range/taku-calendar-range.component";
import { TakuCalendarTimeComponent } from "./taku-ui/taku-calendar-time/taku-calendar-time.component";
import { TakuChipsComponent } from "./taku-ui/taku-chips/taku-chips.component";
import { TakuCountryComponent } from "./taku-ui/taku-country/taku-country.component";
import { TakuCurrencyComponent } from "./taku-ui/taku-currency/taku-currency.component";
import { TakuDropdownComponent } from "./taku-ui/taku-dropdown/taku-dropdown.component";
import { TakuEditorComponent } from "./taku-ui/taku-editor/taku-editor.component";
import { TakuInputTextareaComponent } from "./taku-ui/taku-input-textarea/taku-input-textarea.component";
import { TakuMoneyComponent } from "./taku-ui/taku-money/taku-money.component";
import { TakuMultiSubRegionComponent } from "./taku-ui/taku-multi-sub-region/taku-multi-sub-region.component";
import { TakuMultiselectComponent } from "./taku-ui/taku-multiselect/taku-multiselect.component";
import { TakuNumpadComponent } from "./taku-ui/taku-numpad/taku-numpad.component";
import { TakuPhoneComponent } from "./taku-ui/taku-phone/taku-phone.component";
import { TakuPostalCodeComponent } from "./taku-ui/taku-postal-code/taku-postal-code.component";
// Search main component and sub-components
import { TakuSearchAccountsComponent } from "./taku-ui/taku-search-accounts/taku-search-accounts.component";
import { TakuSearchInventoryComponent } from "./taku-ui/taku-search-inventory/taku-search-inventory.component";
import { TakuSearchResultLineComponent } from "./taku-ui/taku-searchresult-line/taku-searchresult-line.component";
import { TakuStepsComponent } from "./taku-ui/taku-steps/taku-steps.component";
import { TakuSubRegionComponent } from "./taku-ui/taku-sub-region/taku-sub-region.component";
import { TakuToggleComponent } from "./taku-ui/taku-toggle/taku-toggle.component";
import { SaledocDeliveryMethodComponent } from "./core/document/sale-document/saledoc-delivery-method/saledoc-delivery-method.component";
import { SaleDocTenderReferenceComponent } from "./core/document/sale-document/sale-doc-tender-reference/sale-doc-tender-reference.component";
import { IntegrationSettingsCardsComponent } from "./core/settings/integration-settings-cards/integration-settings-cards.component";
import { GoogleShoppingStoreComponent } from "./core/settings/integration-settings/google-shopping/google-shopping-store/google-shopping-store.component";
import { GoogleShoppingStoresListComponent } from "./core/settings/integration-settings/google-shopping/google-shopping-stores-list/google-shopping-stores-list.component";
import { AutocompleteFormfieldDirective } from "./shared/directives/autocomplete-formfield.directive";
import { TakuFormfieldDecoratorComponent } from "./taku-ui/taku-formfield-decorator/taku-formfield-decorator.component";
import { BrandComponent } from "./core/inventory/brand/brand.component";
import { GiftReceiptTapePreviewComponent } from "./core/previews/receipt/gift-receipt-tape-preview/gift-receipt-tape-preview.component";
import { TrimPipe } from "./shared/pipes/trim.pipe";
import { BusinessSetupComponent } from "./wizards/business-setup/business-setup.component";
import { UserManagementCardsComponent } from "./core/settings/user-management-cards/user-management-cards.component";
import { InventoryDocListComponent } from "./core/document/inventory-document/inventory-doc-list/inventory-doc-list.component";
import { AccessRightsComponent } from "./core/users/access-rights/access-rights-details/access-rights.component";
import { RolePermissionsComponent } from "./core/users/role-permissions/role-permissions.component";
import { UserRoleComponent } from "./core/users/user-role/user-role.component";
import { UserListComponent } from "./core/users/user-list/user-list.component";
import { ObjectmodelValidationMessagesComponent } from "./taku-ui/objectmodel-validation-messages/objectmodel-validation-messages.component";
import { GenericPhoneComponent } from "./core/contact-accounts/phone-shared/phone.component.shared";
import { FullPageReturnReceiptBuilderComponent } from "./core/settings/store-settings/full-page-returnreceipt-builder/full-page-returnreceipt-builder.component";
import { TapeReturnReceiptBuilderComponent } from "./core/settings/store-settings/tape-returnreceipt-builder/tape-returnreceipt-builder.component";
import { StoreMailSettingsComponent } from "./core/settings/store-settings/store-mail-settings/store-mail-settings.component";
import { ReturnItemComponent } from "./core/document/return-item/return-item.component";
import { GenericSaleDocComponent } from "./core/document/sale-document/generic-sale-doc.component";
import { ZonesListComponent } from "./core/settings/zone-settings/zones-list/zones-list.component";
import { GmbAccountsListComponent } from "./core/settings/integration-settings/google-my-business/gmb-accounts-list/gmb-accounts-list.component";
import { GmbLocationsListComponent } from "./core/settings/integration-settings/google-my-business/gmb-locations-list/gmb-locations-list.component";
import { GmbLocationDescriptionComponent } from "./core/settings/integration-settings/gmb-location-description/gmb-location-description.component";
import { GmbLocationGroupSelectorComponent } from "./core/settings/integration-settings/google-my-business/gmb-locationgroup-selector/gmb-locationgroup-selector.component";
import { VerificationMethodSelectorComponent } from "./core/settings/integration-settings/google-my-business/verification-method-selector/verification-method-selector.component";
import { VerificationEnterPinComponent } from "./core/settings/integration-settings/google-my-business/verification-enter-pin/verification-enter-pin.component";
import { GMCAccountsComponent } from "./core/settings/integration-settings/google-shopping/gmc-accounts/gmc-accounts.component";
import { GmcAccountDetailsComponent } from "./core/settings/integration-settings/google-shopping/gmc-account-details/gmc-account-details.component";
import { ProgressDialogComponent } from "./shared/components/progress-dialog/progress-dialog.component";
import { TakuInputSpinnerComponent } from "./taku-ui/taku-input-spinner/taku-input-spinner.component";
import { GoogleShoppingMainComponent } from "./core/settings/integration-settings/google-shopping/google-shopping-main/google-shopping-main.component";
import { GoogleShoppingProductsComponent } from "./core/settings/integration-settings/google-shopping/google-shopping-products/google-shopping-products.component";
import { GMCProductErrorDetailsComponent } from "./core/settings/integration-settings/google-shopping/gmc-product-error-details/gmc-product-error-details.component";
import { GoogleShoppingConsentConfirmationComponent } from "./core/settings/integration-settings/shared/google-shopping-consent-confirmation/google-shopping-consent-confirmation.component";
import { StoreConnectedConfirmationComponent } from "./core/settings/integration-settings/shared/store-connected-confirmation/store-connected-confirmation.component";
import { GMBConsentConfirmationComponent } from "./core/settings/integration-settings/shared/gmb-consent-confirmation/gmb-consent-confirmation.component";
import { SaleInvoiceDocMobileComponent } from "./core/document/sale-document/sale-invoice-doc-mobile/sale-invoice-doc-mobile.component";
import { SaleInvoiceDocDesktopComponent } from "./core/document/sale-document/sale-invoice-doc-desktop/sale-invoice-doc-desktop.component";
import { SaleInvoiceDocBaseComponent } from "./core/document/sale-document/sale-invoice-doc-base/sale-invoice-doc-base.component";
import { SaleInvoiceDocDetailsComponent } from "./core/document/sale-document/sale-invoice-doc-details/sale-invoice-doc-details.component";
import { SaleDocMobileComponent } from "./core/document/sale-document/sale-doc-mobile/sale-doc-mobile.component";
import { SaleDocFooterCompactComponent } from "./core/document/sale-document/sale-doc-footer-compact/sale-doc-footer-compact.component";
import { AngularFittextModule } from "angular-fittext";
import { SaleInvoiceDocMainComponent } from "./core/document/sale-document/sale-invoice-doc-main/sale-invoice-doc-main.component";
import { SalesReportComponent } from "./core/reports/reports-list/sales-report/sales-report.component";
import { InventoryHistoryReportComponent } from "./core/reports/reports-list/inventory-history-report/inventory-history.component";
import { MenuService } from "./app.menu.service";
import { ReturnInvoiceDocMainComponent } from "./core/document/return-document/return-invoice-doc-main/return-invoice-doc-main.component";
import { ReturnInvoiceDocBaseComponent } from "./core/document/return-document/return-invoice-doc-base/return-invoice-doc-base.component";
import { ReturnInvoiceDocDesktopComponent } from "./core/document/return-document/return-invoice-doc-desktop/return-invoice-doc-desktop.component";
import { ReturnInvoiceDocMobileComponent } from "./core/document/return-document/return-invoice-doc-mobile/return-invoice-doc-mobile.component";
import { ReturnDocMobileComponent } from "./core/document/return-document/return-doc-mobile/return-doc-mobile.component";
import { ReturnDocFooterCompactComponent } from "./core/document/return-document/return-doc-footer-compact/return-doc-footer-compact.component";
import { StoreListComponent } from "./core/settings/store-settings/store-list/store-list.component";
import { GenericInvoiceSaleComponent } from "./core/document/sale-document/generic-invoice-doc.component";
import { DialogValidationMessagesComponent } from "./forms/dialog-validation-messages/dialog-validation-messages.component";
import { GoogleShoppingPushAllDialogComponent } from "./core/settings/integration-settings/google-shopping/google-shopping-push-all-dialog/google-shopping-push-all-dialog.component";
import { GoogleShoppingPushSelectedDialogComponent } from "./core/settings/integration-settings/google-shopping/google-shopping-push-selected-dialog/google-shopping-push-selected-dialog.component";
import { CustomerConsentComponent } from "./core/contact-accounts/customer-consent/customer-consent.component";
import { InventoryImageComponent } from "./core/inventory/inventory-image/inventory-image.component";
import { InventoryBarcodesArrayComponent } from "./core/inventory/inventory-barcodes-array/inventory-barcodes-array.component";
import { ArrayFormComponent } from "./forms/array-form/array-form.component";
import { SaledocVoidDialogComponent } from "./core/document/sale-document/saledoc-void-dialog/saledoc-void-dialog.component";
import { FormListBulkEditorComponent } from "./form-list/form-list-bulk-editor/form-list-bulk-editor.component";
import { PersonAddressArrayComponent } from "./core/contact-accounts/person-address-array/person-address-array.component";
import { TakuInputAutocompleteComponent } from "./taku-ui/taku-input-autocomplete/taku-input-autocomplete.component";
import { InventoryStockLevelsArrayComponent } from "./core/inventory/inventory-stocklevels-array/inventory-stocklevels-array.component";
import { GenericSaleDocFooterComponent } from "./core/document/sale-document/generic-sale-doc-footer.component";
import { GatewayTerminalsComponent } from "./core/settings/integration-settings/gateway-terminals/gateway-terminals.component";
import { EconduitTerminalComponent } from "./core/settings/integration-settings/econduit-terminal/econduit-terminal.component";
import { PaymentGatewaysListComponent } from "./core/settings/integration-settings/payment-gateways/payment-gateways-list.component";
import { AccessRightsDetailsFilteredComponent } from "./core/users/access-rights/access-rights-details-filtered/access-rights-details-filtered.component";
import { TimeZoneAutocompleteInputComponent } from "./core/settings/shared/time-zone-autocomplete-input/time-zone-autocomplete-input.component";
import { ManageRolesComponent } from "./core/users/access-rights/manage-roles/manage-roles.component";
import { AppMenuitemComponent } from "./app.menuitem.component";
import { NgIdleModule } from "@ng-idle/core";
import { InvoiceKeyboardActionsService } from "./core/document/sale-document/sale-invoice-doc-base/invoice-keyboard-actions.service";
import { ReportsParamsComponent } from "./core/reports/reports-params/reports-params.component";
import { ReportsFormListComponent } from "./core/reports/reports-form-list/reports-form-list.component";
import { ReportFormListDirective } from "./core/reports/reports-directives/report-form-list.directive";
import { ReportParamsDirective } from "./core/reports/reports-directives/report-params.directive";
import { GmcInventoryContactInfoComponent } from "./core/settings/integration-settings/google-shopping/inventory-contact-info/inventory-contact-info.component";
import { SaleTaxReportComponent } from "./core/reports/reports-list/sale-tax-report/sale-tax-report.component";
import { InventoryValueReportComponent } from "./core/reports/reports-list/inventory-value-report/inventory-value-report.component";
import { InventorySaleChannelComponent } from "./core/inventory/inventory-sale-channel/inventory-sale-channel.component";
import { InventoryIntegrationListComponent } from "./core/inventory/inventory-integration-list/inventory-integration-list.component";
import { SaleChannelContainerComponent } from "./core/sale-channel/sale-channel-container/sale-channel-container.component";
import { SaleChannelSettingsComponent } from "./core/sale-channel/sale-channel-settings/sale-channel-settings.component";
import { SaleChannelLandingComponent } from "./core/sale-channel/sale-channel-landing/sale-channel-landing.component";
import { SaleChannelInventoryComponent } from "./core/sale-channel/sale-channel-inventory/sale-channel-inventory.component";
import { SaleChannelDetailComponent } from "./core/sale-channel/sale-channel-detail/sale-channel-detail.component";
import { SalesAnalysisReportComponent } from "./core/reports/reports-list/sales-analysis-report/sales-analysis-report.component";
import { SalesAlanysisSummaryReportComponent } from "./core/reports/reports-list/sales-alanysis-summary-report/sales-alanysis-summary-report.component";
import { SaleBankReconciliationReportComponent } from "./core/reports/reports-list/sale-bank-reconciliation-report/sale-bank-reconciliation-report.component";
import { SaleChannelOrdersComponent } from "./core/sale-channel/sale-channel-orders/sale-channel-orders.component";
import { SaleChannelOrderPreviewComponent } from "./core/sale-channel/sale-channel-order-preview/sale-channel-order-preview.component";
import { FormDialogInputComponent } from "./forms/form-dialog-input/form-dialog-input.component";
import { TakuInputNumberComponent } from "./taku-ui/taku-input-number/taku-input-number.component";
import { SaleDocTenderArrayComponent } from "./core/document/sale-document/sale-doc-tender-array/sale-doc-tender-array.component";
import { ShipperService } from "./core/settings/business-settings/shipper/shipper.service";
import { RippleModule } from "primeng/ripple";
import { EmailArrayComponent } from "./core/contact-accounts/email-array/email-array.component";
import { AppInlineProfileComponent } from "./app.inlinemenu.component";
import { SafeHtmlPipe } from "./shared/pipes/safe-html.pipe";
import { PhoneArrayComponent } from "./core/contact-accounts/phone-array/phone-array.component";
import { OpeningCashoutSlipTapeSizeComponent } from "./core/previews/slips/opening-cashout-slip-tape-size/opening-cashout-slip-tape-size.component";
import { CrmHistoryComponent } from "./core/document/sale-document/crm-history/crm-history.component";
import { SaleDocListComponent } from "./core/document/sale-document/sale-doc-list/sale-doc-list.component";
import { GalleryGridCardComponent } from "./core/document/sale-document/gallery-grid-card/gallery-grid-card.component";
import { GalleryListCardComponent } from "./core/document/sale-document/gallery-list-card/gallery-list-card.component";
import { SaleInvoiceStateService } from "./core/document/sale-document/sale-invoice-state.service";
import { CategoryGridCardComponent } from "./core/document/sale-document/category-grid-card/category-grid-card.component";
import { MonerisTerminalComponent } from "./core/settings/integration-settings/moneris-terminal/moneris-terminal.component";
import { KioskSettingsComponent } from "./core/settings/store-settings/kiosk-settings/kiosk-settings.component";
import { SaleDocSelfCheckoutMainComponent } from "./core/document/sale-document/sale-doc-self-checkout-main/sale-doc-self-checkout-main.component";
import { SaleDocSelfCheckoutDesktopComponent } from "./core/document/sale-document/sale-doc-self-checkout-desktop/sale-doc-self-checkout-desktop.component";
import { SaleDocSelfCheckoutMobileComponent } from "./core/document/sale-document/sale-doc-self-checkout-mobile/sale-doc-self-checkout-mobile.component";
import { SaleDocSelfCheckoutGridCardComponent } from "./core/document/sale-document/sale-doc-self-checkout-grid-card/sale-doc-self-checkout-grid-card.component";
import { SaleDocSelfCheckoutListCardComponent } from "./core/document/sale-document/sale-doc-self-checkout-list-card/sale-doc-self-checkout-list-card.component";
import { SelfCheckoutDesktopFooterComponent } from "./core/document/sale-document/self-checkout-desktop-footer/self-checkout-desktop-footer.component";
import { SelfCheckoutItemDetailsComponent } from "./core/document/sale-document/self-checkout-item-details/self-checkout-item-details.component";
import { SelfCheckoutMobileFooterComponent } from "./core/document/sale-document/self-checkout-mobile-footer/self-checkout-mobile-footer.component";
import { SelfCheckoutFooterLabelsComponent } from "./core/document/sale-document/self-checkout-footer-labels/self-checkout-footer-labels.component";
import { SaleDocSelfCheckoutBaseComponent } from "./core/document/sale-document/sale-doc-self-checkout-base/sale-doc-self-checkout-base.component";
import { SelfCheckoutLandingPageComponent } from "./core/document/sale-document/self-checkout-landing-page/self-checkout-landing-page.component";
import { SaleDocSelfCheckoutWrapperDesktopComponent } from "./core/document/sale-document/sale-doc-self-checkout-wrapper-desktop/sale-doc-self-checkout-wrapper-desktop.component";
import { SaleDocSelfCheckoutWrapperMobileComponent } from "./core/document/sale-document/sale-doc-self-checkout-wrapper-mobile/sale-doc-self-checkout-wrapper-mobile.component";
import { CategoryDataViewComponent } from "./core/document/sale-document/category-data-view/category-data-view.component";
import { InventoryDataViewComponent } from "./core/document/sale-document/inventory-data-view/inventory-data-view.component";
import { DataViewBaseComponent } from "./core/document/sale-document/data-view-base/data-view-base.component";
import { SaleDocSelfCheckoutWrapperBaseComponent } from "./core/document/sale-document/sale-doc-self-checkout-wrapper-base/sale-doc-self-checkout-wrapper-base.component";
import { KioskDeactivateDialogComponent } from "./core/document/sale-document/kiosk-deactivate-dialog/kiosk-deactivate-dialog.component";
import { BadgeModule } from "primeng/badge";
import { OpeningCashoutSlipFullSizeComponent } from "./core/previews/slips/opening-cashout-slip-full-size/opening-cashout-slip-full-size.component";
import { SelfCheckoutAddonListCardComponent } from "./core/document/sale-document/self-checkout-addon-list-card/self-checkout-addon-list-card.component";
import { TenderReceiptTapePreviewComponent } from "./core/previews/receipt/tender-receipt-tape-preview/tender-receipt-tape-preview.component";
import { BatchCloseDetailsDialogComponent } from "./core/cashout/batch-close-details-dialog/batch-close-details-dialog.component";
import { ClosedBatchDetailsTapePreviewComponent } from "./core/previews/receipt/closed-batch-details-tape-preview/closed-batch-details-tape-preview.component";
import { KioskGuard } from "./app-routing/kiosk.guard";
import { IdleService } from "./idle.service";
import { MonerisOpenTotalDetailsDialogComponent } from "./core/cashout/moneris-open-total-details-dialog/moneris-open-total-details-dialog.component";
import { InventoryOptionsSettingValueFormListComponent } from "./core/inventory/inventory-options-setting-value-form-list/inventory-options-setting-value-form-list.component";
import { InventoryVariantSettingComponent } from "./core/inventory/inventory-variant-setting/inventory-variant-setting.component";
import { InventoryVariantSettingLineComponent } from "./core/inventory/inventory-variant-setting-line/inventory-variant-setting-line.component";
import { InventoryLabelPrintingComponent } from "./core/inventory/inventory-label-printing/inventory-label-printing.component";
import { InventoryLabelSettingsComponent } from "./core/inventory/inventory-label-settings/inventory-label-settings.component";
import { ReplaceSpacePipe } from "./shared/pipes/replace-space.pipe";
import { InventoryFormListComponent } from "./core/inventory/inventory-form-list/inventory-form-list.component";
import { ReportsParamsStocksComponent } from "./core/reports/reports-params-stocks/reports-params-stocks.component";
import { SignupModule } from "./core/users/signup/signup.module";
import { TakuInputModule } from "./taku-ui/taku-input/taku-input.module";
import { TakuCheckboxModule } from "./taku-ui/taku-checkbox/taku-checkbox.module";
import { TakuInputMaskModule } from "./taku-ui/taku-inputmask/taku-inputmask.module";
import { FormValidationMessageModule } from "./forms/form-validation-messages/form-validation-message.module";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { TakuPayComponent } from "./core/settings/integration-settings/taku-pay/taku-pay.component";
import { TakuPayAccountDetailsComponent } from "./core/settings/integration-settings/taku-pay/taku-pay-account-details/taku-pay-account-details.component";
import { TakuPayFormListComponent } from "./core/settings/integration-settings/taku-pay/taku-pay-form-list/taku-pay-form-list.component";
import { PasswordResetComponent } from "./core/users/password-reset/password-reset.component";
import { CommitmentsComponent } from "./core/document/sale-document/commitments/commitments.component";
import { AllUpcomingOrderPreviewComponent } from "./core/all-upcoming-orders/all-upcoming-order-preview/all-upcoming-order-preview.component";
import { AllUpcomingOrdersComponent } from "./core/all-upcoming-orders/all-upcoming-orders/all-upcoming-orders.component";
import { SaleDocAllUpcomingOrderPreviewComponent } from "./core/document/sale-document/sale-doc-all-upcoming-order-preview/sale-doc-all-upcoming-order-preview.component";
import { RecurringOrdersFormListComponent } from "./core/settings/zone-settings/recurring-order-settings/recurring-order-settings-form-list.component";
import { OpenCashDrawerPreviewComponent } from "./core/previews/receipt/open-cash-drawer-preview/open-cash-drawer-preview.component";
import { TakuPaymentGatewaysListComponent } from "./core/settings/integration-settings/taku-pay/taku-payment-gateways-list/taku-payment-gateways-list.component";
import { TakuPaymentGatewayTerminalsComponent } from "./core/settings/integration-settings/taku-pay/taku-payment-gateway-terminals/taku-payment-gateway-terminals.component";
import { TakuPayTerminalComponent } from "./core/settings/integration-settings/taku-pay/taku-pay-terminal/taku-pay-terminal.component";
import { TakuPayLocationDetailComponent } from "./core/settings/integration-settings/taku-pay/taku-pay-location-detail/taku-pay-location-detail.component";
import { InventoryUsageDetailComponent } from "./core/inventory/inventory-usage-detail/inventory-usage-detail.component";
import { CommitmentsPreviewComponent } from "./core/document/sale-document/commitments-preview/commitments-preview.component";
import { CommitmentsTransactionsComponent } from "./core/document/sale-document/commitments-transactions/commitments-transactions.component";
import { TakuAccountCreditCardComponent } from "./taku-ui/taku-account-credit-card/taku-account-credit-card.component";
import { AdyenIntegrationComponent } from "./core/contact-accounts/adyen-integration/adyen-integration.component";
import { CancelRecurringOrderComponent } from "./core/users/cancel-recurring-order/cancel-recurring-order.component";
import { AdyenIntegrationDialogWrapperComponent } from "./core/contact-accounts/adyen-integration-dialog-wrapper/adyen-integration-dialog-wrapper.component";
import { PriceLevelComponent } from "./core/price-levels/price-level.component";
import { CacheInterceptor } from "./shared/services/http-interceptors/cache-interceptor.service";
import { AccountDialogWrapperComponent } from "./core/contact-accounts/account-dialog-wrapper/account-dialog-wrapper.component";
import { PurchaseOrderTermSettingsComponent } from "./core/purchase-order-term/purchase-order-term-settings/purchase-order-term-settings.component";
import { AisleComponent } from "./core/aisle/aisle.component";
import { ShelfComponent } from "./core/shelf/shelf.component";
import { InventorySupplierComponent } from "./core/inventory/inventory-supplier/inventory-supplier.component";
import { InventorySupplierProductDetailComponent } from "./core/inventory/inventory-supplier-product-detail/inventory-supplier-product-detail.component";
import { InventoryDocumentAddDialogComponent } from "./core/document/inventory-document/inventory-document-add-dialog/inventory-document-add-dialog.component";
import { PurchaseDocumentComponent } from "./core/document/purchase-document/purchase-document/purchase-document.component";
import { PurchaseDocListComponent } from "./core/document/purchase-document/purchase-doc-list/purchase-doc-list.component";
import { PurchaseDocumentAddDialogComponent } from "./core/document/purchase-document/purchase-document-add-dialog/purchase-document-add-dialog.component";
import { ServerVersionInterceptor } from "./shared/services/http-interceptors/server-version-interceptor.service";
import { CreateVoucherComponent } from "./core/document/sale-document/create-voucher/create-voucher.component";
import { VoucherBalanceComponent } from "./core/document/sale-document/voucher-balance/voucher-balance.component";
import { PromotionComponent } from "./core/promotion/promotion.component";
import { CommercialAccountDetailsComponent } from "./core/contact-accounts/commercial-account-details/commercial-account-details.component";
import { ActionButtonComponent } from "./shared/components/action-button/action-button.component";
import { PurchaseOrderFullPreviewComponent } from "./core/previews/purchase/purchase-order-full-preview/purchase-order-full-preview.component";
import { InventoryDocumentPreviewComponent } from "./core/document/inventory-document/inventory-document-preview/inventory-document-preview.component";
import { InventoryDocTransactionsComponent } from "./core/document/inventory-document/inventory-doc-transactions/inventory-doc-transactions.component";
import { RouteReuseStrategy } from "@angular/router";
import { CustomRouteReuseStrategy } from "./app-routing/custom-route-strategy";
import { TAKUPayoutsReportComponent } from "./core/reports/reports-list/taku-payouts-report/taku-payouts-report.component";
import { ReportsParamsTAKUPayAccountsComponent } from "./core/reports/reports-params-taku-pay-accounts/reports-params-takupay-accounts.component";
import { PayTypeDetailsComponent } from "./core/document/sale-document/pay-type-details/pay-type-details.component";
import { TenderDetailsOverlayComponent } from "./core/document/sale-document/tender-details-overlay/tender-details-overlay.component";
import { ClosingCashoutReportTapeSizeComponent } from "./core/previews/slips/cashout-report-tape-size/cashout-report-tape-size.component";
import { TraceIdErrorInterceptor } from "./shared/services/http-interceptors/trace-id-error-interceptor.service";
import { ErrorLogsComponent } from "./shared/components/error-logs/error-logs.component";
import { ZoneEmailSettingsComponent } from "./core/settings/zone-settings/zone-email-settings/zone-email-settings.component";
import { InventoryStockDetailsComponent } from "./core/inventory/inventory-stock-details/inventory-stock-details.component";
import { InventoryStockDetailsOverlayComponent } from "./core/inventory/inventory-stock-details-overlay/inventory-stock-details-overlay.component";
import { PurchaseDocumentPreviewComponent } from './core/document/purchase-document/purchase-document-preview/purchase-document-preview.component';
import { PurchaseDocTransactionsComponent } from './core/document/purchase-document/purchase-doc-transactions/purchase-doc-transactions.component';

@NgModule({
  declarations: [
    // [Nazy] for view classes which belongs to this module. view class in angular => component, directive or pipe
    AppComponent,
    AppTopbarComponent,
    AppMenuitemComponent,
    AppInlineProfileComponent,
    InventoryComponent,
    FormFooterComponent,
    FormListMainComponent,
    FormDialogeSelectComponent,
    AppMenuComponent,
    DashboardComponent,
    PageNotFoundComponent,
    PageLoadingComponent,
    TakuDropdownComponent,
    TakuMultiselectComponent,
    UserComponent,
    LoginComponent,
    UOfMeasureComponent,
    CategoryComponent,
    UserRolePipe,
    PersonalAccountComponent,
    CommercialAccountComponent,
    ItemSearchResComponent,
    TakuCalendarDateComponent,
    PersonPhoneComponent,
    AccountComponent,
    CommercialAccountAddressComponent,
    CommercialAccountPhoneComponent,
    ContactComponent,
    PersonComponent,
    PersonAddressComponent,
    PersonPhoneComponent,
    AddressComponent,
    FormListHeaderComponent,
    FormDialogeHeaderComponent,
    TaxAccountCategoryComponent,
    TaxComponent,
    TaxRuleComponent,
    TakuCalendarTimeComponent,
    ForgotLoginComponent,
    AddressPhoneComponent,
    CommercialAccountEmailComponent,
    CommercialAccountInstantMsgComponent,
    CommercialAccountSocialProfileComponent,
    PersonEmailComponent,
    PersonInstantMsgComponent,
    PersonSocialProfileComponent,
    StoreEmailComponent,
    StoreInstantMsgComponent,
    StoreSocialProfileComponent,
    TakuEditorComponent,
    TakuToggleComponent,
    TakuCountryComponent,
    TakuPhoneComponent,
    TakuMoneyComponent,
    TakuSubRegionComponent,
    TaxInventoryCategoryComponent,
    TakuCurrencyComponent,
    TakuPostalCodeComponent,
    TakuCurrencyComponent,
    // Search component
    TakuSearchAccountsComponent,
    TakuSearchInventoryComponent,
    TakuSearchResultLineComponent,
    TakuAutoCompleteComponent,
    FormListComponent,
    InventoryBarcodeComponent,
    TenderTypesSettingsComponent,
    StorePoliciesSettingsComponent,
    BusinessFormatsSettingsComponent,
    SalesFormatsSettingsComponent,
    FullPageSaleReceiptBuilderComponent,
    FullPageReturnReceiptBuilderComponent,
    TapeSaleReceiptBuilderComponent,
    TapeReturnReceiptBuilderComponent,
    GiftReceiptBuilderComponent,
    ReturnSettingsComponent,
    CashoutSettingsComponent,
    InventoryBarcodeComponent,
    TakuInputTextareaComponent,
    StoreSwitcherComponent,
    TaxSettingsComponent,
    TaxCategoriesComponent,
    InventoryGalleryDialogComponent,
    TakuInputTextareaComponent,
    StoreSettingsCardsComponent,
    BusinessSettingsComponent,
    AccountGroupComponent,
    FieldsFormatComponent,
    BusinessDetailComponent,
    StoreHourComponent,
    SettingsCashoutOpeningFloatComponent,
    StockComponent,
    StationComponent,
    StoreComponent,
    ZoneSettingsCardsComponent,
    SettingsCardsComponent,
    ZoneFormListComponent,
    StoreFormListComponent,
    ZoneSwitcherComponent,
    ShipperComponent,
    StoreShipperComponent,
    ReturnReasonComponent,
    SaleDocComponent,
    ReturnSaleDocComponent,
    SaleDocLineComponent,
    ReturnSaleDocLineComponent,
    SaleDocTaxComponent,
    SaleDocLineTaxComponent,
    CRMSaleComponent,
    AccountsSearchResComponent,
    InventoryDocPreviewComponent,
    InventoryDocumentPreviewComponent,
    SalesDocSettingsComponent,
    ReceiptTapePreviewComponent,
    GiftReceiptTapePreviewComponent,
    AddressEmailComponent,
    TakuNumpadComponent,
    TakuMultiSubRegionComponent,
    TakuChipsComponent,
    SaleDocTenderComponent,
    TenderScreenComponent,
    RevisitSaleComponent,
    SaledocRevisitPreviewComponent,
    ParkedSalesComponent,
    CashoutOpenflowComponent,
    TakuCalendarDatetimeComponent,
    CashoutClosetillComponent,
    CashoutComponent,
    CashoutClosingFloatComponent,
    CashoutOpeningFloatComponent,
    SaleDocTransactionsComponent,
    FormListValidationMessagesComponent,
    ReceiptFullPreviewComponent,
    SaleDocFooterComponent,
    DocumentSearchForReturnComponent,
    ReturnSaleDocFooterComponent,
    SaleInvoiceDocDesktopComponent,
    InvoiceDetailsComponent,
    ParkedSalesPreviewComponent,
    ReportsContainerComponent,
    TakuCalendarRangeComponent,
    AutofocusDirective,
    TakuFormListDetailedViewDirective,
    TakuFormListHeaderCustomBtnsDirective,
    InventoryDocumentComponent,
    InventoryDocListComponent,
    TakuStepsComponent,
    InventoryStockComponent,
    BackofficeComponent,
    CashoutHistoryComponent,
    SaledocDeliveryMethodComponent,
    SaleDocTenderReferenceComponent,
    ClosingCashoutSlipTapeSizeComponent,
    CashoutClosingBreakdownComponent,
    CashoutTenderTransactionsComponent,
    ClosingCashoutSlipFullSizeComponent,
    CashoutPaymentBreakdownComponent,
    CashoutHistoryPreviewComponent,
    IntegrationSettingsCardsComponent,
    GoogleShoppingStoreComponent,
    GMCAccountsComponent,
    GoogleShoppingStoresListComponent,
    AutocompleteFormfieldDirective,
    TakuFormfieldDecoratorComponent,
    BrandComponent,
    BusinessSetupComponent,
    UserManagementCardsComponent,
    AccessRightsComponent,
    RolePermissionsComponent,
    UserRoleComponent,
    UserListComponent,
    ObjectmodelValidationMessagesComponent,
    GenericPhoneComponent,
    StoreMailSettingsComponent,
    ReturnItemComponent,
    GenericSaleDocComponent,
    GenericSaleDocFooterComponent,
    ZonesListComponent,
    GmbAccountsListComponent,
    GmbLocationsListComponent,
    GmbLocationDescriptionComponent,
    GmbLocationGroupSelectorComponent,
    VerificationMethodSelectorComponent,
    VerificationEnterPinComponent,
    GmcInventoryContactInfoComponent,
    GmcAccountDetailsComponent,
    ProgressDialogComponent,
    TakuInputSpinnerComponent,
    GoogleShoppingMainComponent,
    GoogleShoppingProductsComponent,
    GMCProductErrorDetailsComponent,
    GoogleShoppingConsentConfirmationComponent,
    GMBConsentConfirmationComponent,
    StoreConnectedConfirmationComponent,
    SaleInvoiceDocMobileComponent,
    SaleInvoiceDocBaseComponent,
    SaleInvoiceDocDetailsComponent,
    SaleDocMobileComponent,
    SaleDocFooterCompactComponent,
    SaleInvoiceDocMainComponent,
    SalesReportComponent,
    InventoryHistoryReportComponent,
    ReturnInvoiceDocMainComponent,
    ReturnInvoiceDocBaseComponent,
    ReturnInvoiceDocDesktopComponent,
    ReturnInvoiceDocMobileComponent,
    ReturnDocMobileComponent,
    ReturnDocFooterCompactComponent,
    StoreListComponent,
    GenericInvoiceSaleComponent,
    DialogValidationMessagesComponent,
    GoogleShoppingPushAllDialogComponent,
    GoogleShoppingPushSelectedDialogComponent,
    CustomerConsentComponent,
    InventoryImageComponent,
    InventoryBarcodesArrayComponent,
    ArrayFormComponent,
    SaledocVoidDialogComponent,
    FormListBulkEditorComponent,
    PhoneArrayComponent,
    PersonAddressArrayComponent,
    TakuInputAutocompleteComponent,
    InventoryStockLevelsArrayComponent,
    GatewayTerminalsComponent,
    PaymentGatewaysListComponent,
    EconduitTerminalComponent,
    AccessRightsDetailsFilteredComponent,
    TimeZoneAutocompleteInputComponent,
    ManageRolesComponent,
    ReportsParamsComponent,
    ReportsFormListComponent,
    ReportParamsDirective,
    ReportFormListDirective,
    SaleTaxReportComponent,
    InventoryValueReportComponent,
    TAKUPayoutsReportComponent,
    InventorySaleChannelComponent,
    InventoryIntegrationListComponent,
    SaleChannelContainerComponent,
    SaleChannelSettingsComponent,
    SaleChannelLandingComponent,
    SaleChannelInventoryComponent,
    SaleChannelDetailComponent,
    SalesAnalysisReportComponent,
    SalesAlanysisSummaryReportComponent,
    SaleBankReconciliationReportComponent,
    SaleChannelOrdersComponent,
    SaleChannelOrderPreviewComponent,
    FormDialogInputComponent,
    TakuInputNumberComponent,
    SaleDocTenderArrayComponent,
    EmailArrayComponent,
    OpeningCashoutSlipTapeSizeComponent,
    CrmHistoryComponent,
    SaleDocListComponent,
    GalleryGridCardComponent,
    GalleryListCardComponent,
    CategoryGridCardComponent,
    MonerisTerminalComponent,
    KioskSettingsComponent,
    SaleDocSelfCheckoutMainComponent,
    SaleDocSelfCheckoutDesktopComponent,
    SaleDocSelfCheckoutMobileComponent,
    SaleDocSelfCheckoutGridCardComponent,
    SaleDocSelfCheckoutListCardComponent,
    SelfCheckoutDesktopFooterComponent,
    SelfCheckoutItemDetailsComponent,
    SelfCheckoutMobileFooterComponent,
    SelfCheckoutFooterLabelsComponent,
    SaleDocSelfCheckoutBaseComponent,
    SelfCheckoutLandingPageComponent,
    SaleDocSelfCheckoutWrapperDesktopComponent,
    SaleDocSelfCheckoutWrapperMobileComponent,
    CategoryDataViewComponent,
    InventoryDataViewComponent,
    DataViewBaseComponent,
    SaleDocSelfCheckoutWrapperBaseComponent,
    KioskDeactivateDialogComponent,
    OpeningCashoutSlipFullSizeComponent,
    SelfCheckoutAddonListCardComponent,
    TenderReceiptTapePreviewComponent,
    BatchCloseDetailsDialogComponent,
    ClosedBatchDetailsTapePreviewComponent,
    MonerisOpenTotalDetailsDialogComponent,
    InventoryOptionsSettingValueFormListComponent,
    InventoryVariantSettingComponent,
    InventoryVariantSettingLineComponent,
    InventoryLabelPrintingComponent,
    InventoryLabelSettingsComponent,
    InventoryFormListComponent,
    ReportsParamsStocksComponent,
    ReportsParamsTAKUPayAccountsComponent,
    TakuPayComponent,
    TakuPayAccountDetailsComponent,
    TakuPayFormListComponent,
    PasswordResetComponent,
    CommitmentsComponent,
    AllUpcomingOrdersComponent,
    AllUpcomingOrderPreviewComponent,
    SaleDocAllUpcomingOrderPreviewComponent,
    RecurringOrdersFormListComponent,
    OpenCashDrawerPreviewComponent,
    TakuPaymentGatewaysListComponent,
    TakuPaymentGatewayTerminalsComponent,
    TakuPayTerminalComponent,
    TakuPayLocationDetailComponent,
    InventoryUsageDetailComponent,
    CommitmentsPreviewComponent,
    CommitmentsTransactionsComponent,
    InventoryDocTransactionsComponent,
    TakuAccountCreditCardComponent,
    AdyenIntegrationComponent,
    CancelRecurringOrderComponent,
    AdyenIntegrationDialogWrapperComponent,
    PriceLevelComponent,
    AccountDialogWrapperComponent,
    PurchaseOrderTermSettingsComponent,
    AisleComponent,
    ShelfComponent,
    InventorySupplierComponent,
    InventorySupplierProductDetailComponent,
    InventoryDocumentAddDialogComponent,
    PurchaseDocumentComponent,
    PurchaseDocListComponent,
    PurchaseDocumentAddDialogComponent,
    CreateVoucherComponent,
    VoucherBalanceComponent,
    PromotionComponent,
    CommercialAccountDetailsComponent,
    PurchaseOrderFullPreviewComponent,
    PayTypeDetailsComponent,
    TenderDetailsOverlayComponent,
    ClosingCashoutReportTapeSizeComponent,
    ErrorLogsComponent,
    ZoneEmailSettingsComponent,
    InventoryStockDetailsComponent,
    InventoryStockDetailsOverlayComponent,
    PurchaseDocumentPreviewComponent,
    PurchaseDocTransactionsComponent,
  ],
  imports: [
    CamelToSentencePipe,
    SafeHtmlPipe,
    ReplaceSpacePipe,
    TrimPipe,
    ReplaceLineBreaksPipe,
    LocalTimePipe,
    FormatNegativeNumberPipe,
    CurrencySymbolPipe,
    FileSizePipe,
    DateFormatPipe,
    DateTimeFormatPipe,
    ObjectToArrayPipe,
    FormHeaderComponent,
    BadgeModule,
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    // AngularResizeEventModule,
    ScrollingModule,
    AccordionModule,
    AutoCompleteModule,
    BlockUIModule,
    ButtonModule,
    CalendarModule,
    CarouselModule,
    ChartModule,
    CheckboxModule,
    TriStateCheckboxModule,
    ChipModule,
    ChipsModule,
    CodeHighlighterModule,
    ColorPickerModule,
    ConfirmDialogModule,
    ContextMenuModule,
    SharedModule,
    DialogModule,
    DynamicDialogModule,
    DragDropModule,
    DropdownModule,
    DividerModule,
    EditorModule,
    FieldsetModule,
    FileUploadModule,
    GalleriaModule,
    GMapModule,
    InputMaskModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    ListboxModule,
    MegaMenuModule,
    MenuModule,
    MenubarModule,
    MessageModule,
    KeyFilterModule,
    MessagesModule,
    MultiSelectModule,
    OrderListModule,
    OrganizationChartModule,
    OverlayPanelModule,
    PaginatorModule,
    PanelModule,
    PanelMenuModule,
    PasswordModule,
    PickListModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    RadioButtonModule,
    RatingModule,
    RippleModule,
    ScrollPanelModule,
    ScrollTopModule,
    SelectButtonModule,
    SidebarModule,
    SliderModule,
    InputNumberModule,
    SpinnerModule,
    SplitButtonModule,
    StepsModule,
    TabMenuModule,
    TabViewModule,
    TieredMenuModule,
    ToggleButtonModule,
    ToolbarModule,
    TooltipModule,
    TreeModule,
    TreeSelectModule,
    TableModule,
    TagModule,
    SkeletonModule,
    // Added when upgrading Barcelona theme to v6
    DataViewModule,
    ToastModule,
    CardModule,
    DialogModule,
    AngularFittextModule,
    FormValidationMessageModule,
    TakuInputModule,
    TakuCheckboxModule,
    TakuInputMaskModule,
    SignupModule,
    NgIdleModule.forRoot(),
    ActionButtonComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedRequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerVersionInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TraceIdErrorInterceptor,
      multi: true,
    },
    {
      provide: "CountriesSelectItem",
      deps: [LocationService],
      useFactory: (location: LocationService): SelectItem[] => {
        return location.lookupCountryOptions_full();
      },
    },
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy,
    },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    DBSearchService,
    ObjectDetailResolverService,
    CashoutResolverService,
    Title,
    AuthGuard,
    AuthService,
    AlertMessagesService,
    MessageService,
    ConfirmationService,
    UserProfileService,
    UnsavedChangesGuard,
    InvoiceGuard,
    UnsavedFormListGuard,
    KioskGuard,
    LocationService,
    CountryPhoneMaskService,
    CurrencyFormatterService,
    TakuAddressValidationService,
    InventoryService,
    ShipperService,
    MenuService,
    InvoiceKeyboardActionsService,
    DialogService,
    DynamicDialogRef,
    DynamicDialogConfig,
    SaleInvoiceStateService,
    IdleService,
  ], // [Nazy] providers allow us to import services to our module
  bootstrap: [AppComponent],
  /* [Nazy] the component that will launch when the app starts, the bootstrap is actually initiated from another file: main.ts
   1. main.ts ==> runs and executes the bootstrap method against app.module.ts
   2. app.module.ts ==> launches app.component.ts
   3. app.component.ts ==> initial component displayed by app

   we can also add exports to our decorator for example ==>
   exports: [ AppCompnent ]
   ==> this allows us to export decorations to use in other components although it's not necessary in the root module */
})
export class AppModule {
  // [Nazy] Diagnostic only: inspect router configuration
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
