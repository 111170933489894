/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Input } from "@angular/core";
import { TakuInputCtrlBase } from "../TakuInputCtrlBase";

@Component({
  selector: "taku-input-spinner",
  templateUrl: "./taku-input-spinner.component.html",
  styleUrls: ["./taku-input-spinner.component.scss"],
})
export class TakuInputSpinnerComponent extends TakuInputCtrlBase {
  @Input() keyFilter: string | RegExp = /.*/g;
  @Input() min?: number;
  @Input() max?: number;
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
