/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, ElementRef, OnInit, OnDestroy } from "@angular/core";
import * as _ from "lodash";
import { forkJoin, ReplaySubject, Subscription } from "rxjs";
import { LocationService } from "../../../../shared/services/location.service";
import { PrintHelpers } from "../../../../utility/PrintHelpers";
import { CashoutSummaryDoc, DBCashoutService } from "../../../cashout/db-cashout.service";
import { Store } from "../../../settings/store-settings/store/store";
import { User } from "../../../users/user/user";
import { CashoutSlipBase } from "../CashoutSlipBase.interface";
import { BusinessDetail } from "src/app/core/settings/business-settings/business-detail/business-detail";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { Font_Size } from "src/app/core/settings/store-settings/gift-receipt-builder/StoreSettingGiftReceipt";

@Component({
  selector: "taku-cashout-slip-tape-size",
  templateUrl: "./cashout-slip-tape-size.component.html",
  styleUrls: ["../../../../../assets/layout/taku/cashout-slip-tape-size.component.css"],
})
export class ClosingCashoutSlipTapeSizeComponent extends CashoutSlipBase implements OnInit, OnDestroy {
  businessDetail: BusinessDetail;

  constructor(
    elementRef: ElementRef,
    private locationService: LocationService,
    private dbService: DBCashoutService,
    private appSettings: AppSettingsStorageService
  ) {
    super(elementRef);
  }
  private static readonly COMPILED_CSS_URL = "/assets/layout/taku/cashout-slip-tape-size.component.css";
  subsList: Subscription[] = [];

  // openingUser: User;
  closingUser: User;
  printedDate: Date;
  _countryName: string = null;
  _digitsFormat = "1.2-2";
  _printedDate = new Date();

  private _dataReadySubject = new ReplaySubject<boolean>(1);
  onDataReady$ = this._dataReadySubject.asObservable();

  ngOnInit() {
    this.subsList.push(
      forkJoin([
        this.dbService.getRow<Store>("store", this.cashout.storeId),
        this.dbService.getSummary(this.cashout.id),
        // this.dbService.getRow<User>('user', this.cashout.openingCashFloatUserId),
        this.dbService.getRow<User>("user", this.cashout.closingCashFloatUserId),
        this.appSettings.getBusinessDetails(),
      ]).subscribe(
        ([store, cashoutsSummary, user, businessDetail]: [Store, CashoutSummaryDoc[], User, BusinessDetail]) => {
          this.store = _.merge(new Store(), store);
          this.docsSummaries = cashoutsSummary.map((doc) => _.merge(new CashoutSummaryDoc(), doc));
          this.closingUser = _.merge(new User(), user);
          this.businessDetail = businessDetail;

          this.printedDate = new Date();
          if (this.store.address)
            this._countryName = this.locationService.lookupCountryByIsoCode(this.store.address.countryIsoCode);

          this._dataReadySubject.next(true);
        }
      )
    );
  }

  getStylesheets() {
    return [ClosingCashoutSlipTapeSizeComponent.COMPILED_CSS_URL];
  }

  sendToPrinter() {
    PrintHelpers.printElementContents(this.elementRef.nativeElement, [
      ClosingCashoutSlipTapeSizeComponent.COMPILED_CSS_URL,
    ]);
  }

  get businessLogoUrl() {
    // First try to use the Store's logo, if not available fallback to Business's logo
    const logoUrl = this.store.urlStoreLogoUrl || this.businessDetail.urlBusinessDetailBusinessLogoUrl;
    return logoUrl; // WebHelpers.buildImageUrl(logoUrl);
    // return WebHelpers.buildImageUrl(this.businessDetail.BusinessDetailBusinessLogoUrl);
  }
  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
