import { Component, Input } from "@angular/core";
import { Inventory } from "src/app/core/inventory/inventory/inventory";
import { DataViewBaseComponent } from "../data-view-base/data-view-base.component";

@Component({
  selector: "taku-inventory-data-view",
  templateUrl: "./inventory-data-view.component.html",
  styleUrls: ["./inventory-data-view.component.scss"],
})
export class InventoryDataViewComponent extends DataViewBaseComponent {
  @Input() inventories: Inventory[];
  @Input() totalRecords: number;
  @Input() currentCategoryName = "";

  ngOnInit(): void {
    super.ngOnInit();
  }
}
