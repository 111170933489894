/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
  HostBinding,
  ElementRef,
  AfterViewChecked,
  NgZone,
} from "@angular/core";
import { ValidationMessagesService } from "../../shared/services/validation-messages.service";
import { DomHandler } from "primeng/dom";

@Component({
  selector: "form-list-validation-messages",
  templateUrl: "./form-list-validation-messages.component.html",
  styleUrls: ["./form-list-validation-messages.component.scss"],
  providers: [ValidationMessagesService],
})
export class FormListValidationMessagesComponent implements OnChanges, AfterViewChecked, OnInit {
  @Input() errors;
  @Input() pointerPosition = "up";
  @Input() elementPosRef?: HTMLElement;
  @Input() appendTo?: any;
  @Input() invalid = false;
  @Input("field") fieldName: string;
  _errorMessages: Array<string> = [];
  _canUpdatePos = true;

  @HostBinding("class.pointer-up") get styleClass_pointerUp() {
    return this.pointerPosition == "up";
  }

  @HostBinding("style.display") get styleDisplay() {
    return this.invalid ? "block" : "none";
  }

  @HostBinding("class.pointer-down") get styleClass_pointerDown() {
    return this.pointerPosition == "down";
  }

  @HostBinding("class.is-floating") get styleClass_positionInline() {
    return !!this.appendTo;
  }

  constructor(
    private validationMessagesService: ValidationMessagesService,
    private el: ElementRef,
    private ngZone: NgZone
  ) {}

  ngOnInit(): void {
    // Add the validation message to the body, so it can float on top of form list
    // this.appendTo.appendChild(this.el.nativeElement);
    if (this.appendTo) this.attachToNewParent();
  }

  attachToNewParent() {
    if (this.appendTo === "body") this.appendTo = document.body;
    DomHandler.appendChild(this.el.nativeElement, this.appendTo);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["elementPosRef"] && this.appendTo) {
      this.updatePosition();
    }

    if (changes["errors"]) {
      this._errorMessages = [];
      for (const key in this.errors) {
        if (this.errors.hasOwnProperty(key)) {
          this._errorMessages.push(
            this.validationMessagesService.getErrorMessage(key, this.errors[key], this.fieldName)
          );
        }
      }
    }
  }
  setMaximumWidth() {
    const refWidth = DomHandler.getOuterWidth(this.elementPosRef);
    this.el.nativeElement.style.maxWidth = `${refWidth}px`;
  }

  ngAfterViewChecked(): void {
    // Updates not more frequent than 10ms
    if (this.appendTo && this._canUpdatePos) {
      this._canUpdatePos = false;
      // run out side angular zone, so it doesn't trigger Change detection angular messages
      this.ngZone.runOutsideAngular(() => {
        setTimeout(() => {
          this._canUpdatePos = true;
          this.updatePosition();
        }, 5);
      });
    }
  }

  private updatePosition() {
    if (!this.el || !this.elementPosRef) return false;

    // Show nothing if the cell isn't marked as invalid
    if (!this.invalid) {
      return false;
    }

    this.setMaximumWidth();
    const spacingOffset = 5; // add some additional offset in order to make a little space
    let offsetX = Math.round(
      (DomHandler.getOuterWidth(this.elementPosRef) - DomHandler.getOuterWidth(this.el.nativeElement)) / 2
    );
    let offsetY = 0;
    // add up container scrolling offset
    // Fix issues repositioning the overlay when scrolling
    // TODO: Should also take into account element's relative scroll
    offsetX += window.pageXOffset || document.documentElement.scrollLeft;
    offsetY += window.pageYOffset || document.documentElement.scrollTop;

    if (this.pointerPosition === "up") offsetY -= spacingOffset;
    else
      offsetY -=
        DomHandler.getOuterHeight(this.elementPosRef) +
        DomHandler.getOuterHeight(this.el.nativeElement) -
        spacingOffset;

    DomHandler.absolutePosition(this.el.nativeElement, this.elementPosRef);
    const currPos = DomHandler.getOffset(this.el.nativeElement);
    this.el.nativeElement.style.left = `${currPos.left + offsetX}px`;
    this.el.nativeElement.style.top = `${currPos.top + offsetY}px`;
  }

  ngOnDestroy() {
    // remove element when destroying component
    if (this.appendTo)
      try {
        DomHandler.removeChild(this.el.nativeElement, this.appendTo);
      } catch (e) {}
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
