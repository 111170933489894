/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

export class InventoryBarcode {
  id: number;
  barcodeFormat: BarcodeFormat;
  barcodeType: BarcodeType;
  barcode: string;

  constructor() {
    this.id = 0;
    this.barcodeFormat = null;
    this.barcodeType = BarcodeType.INTERNAL;
    this.barcode = "";
  }
}

export enum BarcodeType {
  INTERNAL = "Internal",
  MANUFACTURER = "Manufacturer",
  OTHER = "Other",
}

export enum BarcodeFormat {
  UPC_A = "UPC-A",
  UPC_E = "UPC-E",
  EAN_13 = "EAN-13",
  EAN_8 = "EAN-8",
  JAN_13 = "JAN-13",
  ISBN = "ISBN",
  ISSN = "ISSN",
  CODE_39 = "CODE-39",
  CODE_128 = "CODE-128",
  ITF = "ITF",
  CODE_93 = "CODE-93",
  GS1 = "GS1",
  MSI = "MSI",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
