<!-- <p-panel class="grid panel-pagetitle" styleClass="panel-nopad" [style]="{width: '100%'}">
  <ng-template pTemplate="header">
    <h3><span class="formlist-title">{{_pageTitle}}</span> <span class="channel-title"> ({{_channelTitle}})</span></h3>
    <taku-store-switcher *ngIf="isAdmin && _activeZoneId" (onStoreChanged)="storeChanged($event)"
      [selectedStoreId]="_activeStoreId" [activeZoneId]="_activeZoneId"></taku-store-switcher>
  </ng-template> -->
<taku-form-header
  displayMode="full"
  toolbarTitle="Sales Channel <span class='secondary-text'>(eCommerce)</span>"
  [showActionBtns]="false"
  [showBackBtn]="false"
>
  <!-- <span class="channel-title"> ({{_channelTitle}})</span> -->
  <taku-store-switcher
    *ngIf="isAdmin && _activeZoneId"
    (onStoreChanged)="storeChanged($event)"
    [selectedStoreId]="_activeStoreId"
    [activeZoneId]="_activeZoneId"
  >
  </taku-store-switcher>
</taku-form-header>

<div *ngIf="!isAdmin && active">
  <!-- <p-tabMenu [model]="arrTabSelections" [activeItem]="arrTabSelections[_activeTab]"></p-tabMenu> -->
  <router-outlet></router-outlet>
</div>

<div *ngIf="isAdmin">
  <taku-sale-channel-settings> </taku-sale-channel-settings>
</div>

<!-- </p-panel> -->
