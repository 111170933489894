<div class="col-12 topWrapper">
  <div class="closeBtn-wrapper">
    <button pButton class="p-button p-button-rounded" icon="pi pi-times" (click)="closeDialogPressed()"></button>
  </div>

  <taku-form-header
    [formGroup]="personForm"
    toolbarTitle="NEW CUSTOMER FORM"
    displayMode="inline"
    [showActionBtns]="false"
    [showSecondaryBtns]="false"
    [showBackBtn]="false"
  >
    <button
      pButton
      pRipple
      type="button"
      [disabled]="!personForm.dirty && !canSave"
      (click)="onCheckPressed()"
      icon="_"
      class="p-button-rounded"
      [ngClass]="{ 'check-btn-success': consentApproved }"
    >
      <i class="pi pi-check-circle" style="font-size: 2em"></i>
    </button>
  </taku-form-header>

  <div class="consent-form col-12">
    <taku-checkbox [formGroup]="consentForm" fieldName="consent"></taku-checkbox>
    <div class="consentMsg-wrapper" *ngIf="consentMsg$ | async as customerConsent" [innerHTML]="customerConsent"></div>
  </div>

  <div *ngIf="consentDateTime" class="consent-dateTime col-12">
    <span *ngIf="consentApproved">Last Date of Consent: {{ formattedConsentTime }}</span>
    <span *ngIf="!consentApproved">Date Consent Withdrawn: {{ formattedConsentTime }}</span>
  </div>

  <div class="col-12 bodyWrapper">
    <!-- <p-scrollPanel [style]="{width: '100%', height: '100%', display: 'block'}" styleClass="scrollpanel-custom"> -->
    <taku-person [formGroup]="personForm"></taku-person>
    <!-- </p-scrollPanel> -->
  </div>
</div>

<taku-dialog-validation-messages
  [validationMessages]="validationMessages"
  [(dialogVisible)]="validationDialogVisible"
></taku-dialog-validation-messages>
