<div class="col-12">
  <figure class="header-image">
    <img src="/assets/layout/images/settings/integrations/gmb-logo.svg" alt="GMB" class="google-logo" />
    <figcaption>Google My Business</figcaption>
  </figure>

  <h1>Connect your Google My Business Account to TAKU Retail</h1>

  <div>Here's what to expect when you connect to TAKU Retail:</div>
  <div class="termsConditions-wrapper">
    <p
      >When you integrate your Google account with the TAKU Retail platform, you can connect a number of Google services
      to your TAKU Retail account. By doing so, TAKU Retail may be able to access data and information from your Google
      account. Those services and types of data are outlined below:</p
    >
    <ul>
      <li
        >Google My Business: You can send and receive business details and location details from the TAKU Retail app.
        You will also be able to update business details and store details in-app. TAKU Retail will have access to your
        Google My Business data such as your account name, account email, access token, GMB verification status and all
        integrated business and location details.</li
      >
    </ul>

    <p
      >We’ll only use your Google data to power different parts of the TAKU Retail tools you use every day. It's your
      data. We just want to help you grow better and work more efficiently.</p
    >

    <p
      >You can remove the integration in the TAKU Retail app by navigating to
      <em>Settings > Integrations > Marketing Integrations > Google My Business</em> and clicking the red delete button
      for every associated account. You can also revoke TAKU Retail’s access from within your Google account by
      following the instructions listed
      <a href="https://support.google.com/accounts/answer/3466521" target="_blank">here</a>.</p
    >

    <p
      >For more information on the Google integration and the types of data we can access, please see our
      <a href="https://www.takulabs.io/privacy" target="_blank">Privacy Policy</a>.</p
    >
  </div>

  <footer>
    <div class="continueBtn-wrapper">
      <button pButton label="CONTINUE" (click)="onContinuePressed()"></button>
    </div>

    <div class="otherLink-wrapper">
      <a href="javascript://" (click)="anotherIntegratorLinkPressed()">&lt; Choose a different integration provider</a>
    </div>
  </footer>
</div>
