import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { Subscription } from "rxjs";
import { AccountCreditCard } from "src/app/core/contact-accounts/account-credit-card";
import { DBService } from "src/app/shared/services/db.service";
import { AdyenIntegrationDialogWrapperComponent } from "src/app/core/contact-accounts/adyen-integration-dialog-wrapper/adyen-integration-dialog-wrapper.component";
import { ModelFormGroup } from "src/app/utility/ModelFormGroup";
import { Account } from "src/app/core/contact-accounts/account/account";

@Component({
  selector: "taku-account-credit-card",
  templateUrl: "./taku-account-credit-card.component.html",
  styleUrls: ["./taku-account-credit-card.component.scss"],
})
export class TakuAccountCreditCardComponent implements OnInit {
  @Input("formGroup") myForm: ModelFormGroup<Account>;
  @Input() subTitle = "";
  @Output() takuPayAccountEnabled = new EventEmitter<boolean>();
  subsList: Subscription[] = [];

  constructor(
    private dbService: DBService,
    private fb: UntypedFormBuilder,
    private dialogService: DialogService,
    private ref: DynamicDialogRef
  ) {}

  static init(fb: UntypedFormBuilder, accountCreditCard: AccountCreditCard): UntypedFormGroup {
    const group = fb.group(accountCreditCard);

    // DEBUG - FAKE DATA
    // const group = fb.group({
    //   id: 1,
    //   cardHolderName: 'Damian Halvorson',
    //   cardType: 'Visa',
    //   last4Digits: '1234',
    //   expiryDate: '05/25',
    //   status: AccountCreditCardStatus.DECLINED,
    //   token: null,
    //   isDefault: false,
    //   accountId: 0
    // });

    return group;
  }

  static setArray(fb: UntypedFormBuilder, accountCreditCards: AccountCreditCard[]) {
    const formArray = fb.array([]);
    accountCreditCards.forEach((accountCreditCard) => {
      formArray.push(fb.group(accountCreditCard));
    });
    return formArray;
  }

  ngOnInit(): void {
    const activeFilter = {
      isActive: { matchMode: "equals", value: true },
    };
    this.subsList.push(
      this.dbService.getRows("takuPaymentAccount", JSON.stringify(activeFilter)).subscribe((result) => {
        if (result.count > 0) {
          this.takuPayAccountEnabled.emit(true);
        }
      })
    );

    // Set params to get accountId
    // const _filter = {
    //   personId: { value: this.myForm.get('id').value, matchMode: 'equals' }
    // };
    // this.subsList.push(
    //   this.dbService.getRows('personalAccount', JSON.stringify(_filter)).pipe(take(1)).subscribe(personalAccount => {
    //     this.accountId = personalAccount?.rows[0]?.accountId;

    //     // Display all account credit cards
    //     this.dbService.getRows("accountCreditCard", JSON.stringify({ accountId: { value: this.accountId, matchMode: 'equals' } })).subscribe(
    //       result => {
    //         if (result.count > 0) {
    //           result.rows.map(row => {
    //             const newCard = TakuAccountCreditCardComponent.init(this.fb, row);
    //             this.formArray.push(newCard);
    //           });
    //         }
    //       }
    //     )
    //   })
    // );
  }
  makeDefault(rowIndex: number): void {
    for (const cc of this.myForm.controls.accountCreditCards.controls) {
      cc.controls.isDefault.setValue(false);
    }
    this.myForm.controls.accountCreditCards.at(rowIndex).controls.isDefault.setValue(true);
    this.myForm.controls.accountCreditCards.markAsDirty();
  }

  removeCard(rowIndex: number): void {
    this.myForm.controls.accountCreditCards.removeAt(rowIndex);
    this.myForm.controls.accountCreditCards.markAsDirty();
    const defaultCard = this.myForm.controls.accountCreditCards.controls.find((cc) => cc.controls.isDefault.value);
    if (!defaultCard && this.myForm.controls.accountCreditCards.length) {
      this.myForm.controls.accountCreditCards.at(0).controls.isDefault.setValue(true);
    }
  }

  onAddCreditCard(): void {
    this.ref = this.dialogService.open(AdyenIntegrationDialogWrapperComponent, {
      // styleClass: 'fullscreen-mobilemodal dialogSidebar',
      // contentStyle: {
      //   //overflow: 'auto',
      //   //height: 'calc(100vh - 40pt)',
      //   //minWidth: '580pt',
      //   //height: '100%',
      // },
      // showHeader: false,
      style: {
        maxWidth: "34rem",
      },
      data: {
        account: this.myForm?.parent?.getRawValue(),
      },
    });

    this.subsList.push(
      this.ref.onClose.subscribe((result: AccountCreditCard) => {
        if (result && result.accountId > 0 && result.token !== null) {
          if (!(this.myForm.get("accountCreditCards") instanceof UntypedFormArray)) {
            this.myForm.setControl("accountCreditCards", this.fb.array([]));
          }
          (this.myForm.get("accountCreditCards") as UntypedFormArray).push(
            TakuAccountCreditCardComponent.init(this.fb, result)
          );

          this.myForm.get("accountCreditCards").markAsDirty();
        }
      })
    );
  }
}
