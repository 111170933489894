/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  inject,
} from "@angular/core";
import { AbstractControl, UntypedFormArray, UntypedFormBuilder } from "@angular/forms";
import { ConfirmationService, SelectItem } from "primeng/api";
import { distinctUntilChanged, pairwise, startWith, switchMap } from "rxjs/operators";
import { InvoiceHelpers } from "src/app/utility/InvoiceHelpers";
import { MonetaryHelpers } from "src/app/utility/MonetaryHelpers";
import { NestedFormComponent } from "../../../forms/nested-form/nested-form.component";
import { DBService } from "../../../shared/services/db.service";
import { TakuInputComponent } from "../../../taku-ui/taku-input/taku-input.component";
import { TakuMoneyComponent } from "../../../taku-ui/taku-money/taku-money.component";
import { StoreSettingReturnReason } from "../../settings/store-settings/return-reason/StoreSettingReturnReason";
import { DiscountType, SaleDocLine } from "./sale-doc-line/sale-doc-line";
import { SaleDoc, SaleDocSource } from "./sale-doc/sale-doc";
import { SaleDocService } from "./sale-doc/sale-doc.service";
import { InvoiceKeyboardActionsService } from "./sale-invoice-doc-base/invoice-keyboard-actions.service";
import { TakuNumpadComponent } from "src/app/taku-ui/taku-numpad/taku-numpad.component";
import { CurrencyFormatterService } from "src/app/shared/services/currency-formatter.service";
import { AppComponent } from "src/app/app.component";
import { CurrencyPipe } from "@angular/common";
import { AppConstants } from "src/app/shared/app-constants";
import { throttleTime, filter } from "rxjs/operators";
import { TenderWarningService } from "./tender-warning.service";
import { Subject, of } from "rxjs";
import { WebHelpers } from "src/app/utility/WebHelpers";
@Component({
  template: "",
  styles: [],
})
export abstract class GenericSaleDocLineComponent extends NestedFormComponent implements OnChanges {
  @Input() isSelected: boolean;
  @Input() isExpanded = false;
  @Input() currencyIsoCode: string;
  @Input() lineIndex: number;
  @Input() accountId: number;
  @Input() taxAccountCategoryId: number;
  @Input() storeId: number;
  @Input() docSource: SaleDocSource;
  @Input() returnReasons: StoreSettingReturnReason[] = [];

  @Output() deleted = new EventEmitter();
  @Output() selected = new EventEmitter();
  @Output() lineExpansion = new EventEmitter();
  @Output() qtyChanged = new EventEmitter<number>();

  @ViewChild("qtyInput", { static: true }) _qtyComponent: TakuInputComponent;
  @ViewChild("lineDiscountAmount") discAmountComponent: TakuInputComponent | TakuMoneyComponent;
  @ViewChild("unitPriceInput") unitPriceInputComponent: TakuInputComponent;
  @ViewChild(TakuNumpadComponent, { static: true }) numpadComponent: TakuNumpadComponent;
  // @ViewChild('rootContainer', {read: ElementRef}) rootContainer: ElementRef;
  DiscountType = DiscountType;
  enumDiscountType: SelectItem[] = [
    { label: "%", value: DiscountType.Percentage },
    { label: "$", value: DiscountType.Fixed },
    { label: "Final Price", value: DiscountType.Final_Price },
  ];
  _returnReasonsEnumOptions: SelectItem[] = [];
  protected _lastFocusInput: HTMLInputElement;
  _saleDoc: SaleDoc;

  /** This subject is switchMapped to ensure only the latest request is used, avoiding race conditions */
  private updateSaleLineTaxSubject = new Subject<void>();

  protected tenderWarningService = inject(TenderWarningService);
  constructor(
    public app: AppComponent,
    public dbService: DBService,
    public fb: UntypedFormBuilder,
    protected elementRef: ElementRef,
    protected saleDocService: SaleDocService,
    protected confirmationService: ConfirmationService,
    protected keyboardActionsService: InvoiceKeyboardActionsService,
    protected currencyFormatterService: CurrencyFormatterService
  ) {
    super(dbService, fb);
    // Function keys pressed
    this.subsList.push(
      this.keyboardActionsService.anyKeyPressed$
        .pipe(
          throttleTime(200), // Adjust the throttle time as needed
          filter((event) => (event.key === "F2" || event.key === "Delete") && this.isSelected && !event.repeat)
        )
        .subscribe((event) => {
          switch (event.key) {
            case "F2":
              if (document.activeElement instanceof HTMLInputElement) {
                this._lastFocusInput = document.activeElement;
              }
              this.openNumPad();
              break;

            case "Delete":
              // If we have focused on an input field do nothing in order to avoid undesired line change
              if (!(document.activeElement instanceof HTMLInputElement)) {
                this.deleted.emit();
              }
              break;
          }
        })
    );

    // (-) key was pressed
    this.subsList.push(
      this.keyboardActionsService.minusKeyPressed$
        .pipe(
          throttleTime(50) // Adjust the throttle time as needed
        )
        .subscribe((event) => {
          if (this.isSelected) this.decQty();
        })
    );

    // (+) key was pressed
    this.subsList.push(
      this.keyboardActionsService.plusKeyPressed$
        .pipe(
          throttleTime(50) // Adjust the throttle time as needed
        )
        .subscribe((event) => {
          if (this.isSelected) this.incQty();
        })
    );

    // This is in the constructor instead of ngOnInit because it needs to run before ngOnChanges
    this.subsList.push(
      this.updateSaleLineTaxSubject
        .pipe(
          switchMap(() => {
            const inStore = !this.docSource || this.docSource === SaleDocSource.Store;
            return this.saleDocService.updateSaleTaxesInLineForm(
              this._myForm,
              this.storeId,
              inStore,
              this.accountId,
              this.taxAccountCategoryId,
              this._saleDoc
            );
          })
        )
        .subscribe()
    );
  }

  protected refocusInput() {
    if (this._lastFocusInput) {
      this._lastFocusInput.focus();
      this._lastFocusInput = null;
    }
  }

  openNumPad() {
    this.numpadComponent.open();
  }

  onNumpadCancelled() {
    this.refocusInput();
  }

  focusPriceEdit() {
    setTimeout(() => {
      this.unitPriceInputComponent.focusAndSelectInput();
    }, 0);
  }

  ngOnInit() {
    super.ngOnInit();

    // Listen to any change into discount amount for updating derived fields like Sale Price
    this.subsList.push(
      this.discountAmountCtrl.valueChanges.pipe(distinctUntilChanged()).subscribe((valuechange) => {
        this.onChangeDiscAmount();
      })
    );

    this.subsList.push(
      this.discountTypeCtrl.valueChanges
        .pipe(
          distinctUntilChanged() // only emits if previous discount value was different
        )
        .subscribe((newDiscountType: DiscountType) => {
          if (newDiscountType === DiscountType.Fixed || newDiscountType === DiscountType.Percentage) {
            this.discountAmountCtrl.setValue(0);
          } else {
            const formattedUnitPrice = new CurrencyPipe(AppConstants.DEFAULT_LOCALE_ID).transform(
              this.unitPriceCtrl.value,
              this.currencyIsoCode,
              ""
            );
            this.discountAmountCtrl.setValue(formattedUnitPrice);
          }
          this.onChangeDiscType();
        })
    );

    this.subsList.push(
      this.salePriceCtrl.valueChanges.pipe(distinctUntilChanged()).subscribe((newSalePrice) => {
        if (newSalePrice !== null) this.updateSaleLineTax();
      })
    );

    this.subsList.push(
      this.unitPriceCtrl.valueChanges.pipe(distinctUntilChanged()).subscribe((newUnitPrice) => {
        if (newUnitPrice !== null) {
          const salePrice = this._recalculateSellPrice();
          this._myForm.get("salePrice").setValue(salePrice);
        }
      })
    );

    this.subsList.push(
      this.qtyCtrl.valueChanges
        .pipe(
          distinctUntilChanged(),
          startWith(this.qtyCtrl.value),
          pairwise(),
          switchMap(([prevQty, newQty]) => {
            console.log(prevQty, newQty);
            if (newQty >= prevQty) {
              this.onQtyCtrlValueChange(newQty);
              return of(true);
            }
            return this.tenderWarningService.warnAboutTenders(
              () => this.onQtyCtrlValueChange(newQty),
              () => this.qtyCtrl.patchValue(prevQty)
            );
          })
        )
        .subscribe()
    );
  }

  private onQtyCtrlValueChange(qtyVal: string | number) {
    const qtyNum = Number(qtyVal);
    if (!isNaN(qtyNum)) {
      if (qtyNum === 0) {
        this._deleteOnInvalidQty(qtyNum);
      } else {
        // Communicate to Return Invoice that qty has changed for this line
        this.qtyChanged.emit(qtyNum);
      }

      // update taxes when quantity changes
      this.updateSaleLineTax();
    } else if (qtyVal === "") {
      // if field is empty, that is interpreted as attempt to delete line
      this._deleteOnInvalidQty(0);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes["accountId"] && !changes["accountId"].isFirstChange()) ||
      (changes["storeId"] && !changes["storeId"].isFirstChange()) ||
      (changes["docSource"] && !changes["docSource"].isFirstChange()) ||
      changes["_myForm"] ||
      changes["taxAccountCategoryId"]
    ) {
      // only update taxes if this is a brand new sale docline
      // if (this._myForm.get('id').value == 0)
      this.updateSaleLineTax();
    }

    if (changes["returnReasons"] && this.returnReasons) {
      this._returnReasonsEnumOptions = this.returnReasons.map((reason) => {
        return <SelectItem>{
          label: reason.returnReason,
          value: reason.id,
        };
      });
      this._returnReasonsEnumOptions = [{ label: "", value: null }, ...this._returnReasonsEnumOptions];
    }
  }

  onChangeDiscAmount() {
    if (this.discountAmountCtrl.invalid && this.discountAmountCtrl.value === this._myForm.value.discountAmount) {
      let validationMsgs = Object.values(this.discountAmountCtrl.validator(this.discountAmountCtrl)).join("<br>");

      if (
        this.discountAmountCtrl.validator(this.discountAmountCtrl) &&
        this.discountAmountCtrl.validator(this.discountAmountCtrl).max &&
        parseFloat(this._myForm.value.discountAmount) !== 0
      ) {
        if (
          this.discountAmountCtrl.validator(this.discountAmountCtrl) &&
          this.discountAmountCtrl.validator(this.discountAmountCtrl).max.actual == 0
        ) {
          validationMsgs = "This item cannot be discounted. Please remove the discount to proceed.";
        } else {
          validationMsgs = "Discount cannot exceed ";
          if (this.discountTypeCtrl.value === DiscountType.Percentage) {
            validationMsgs +=
              parseInt(
                this.discountAmountCtrl.validator(this.discountAmountCtrl)
                  ? this.discountAmountCtrl.validator(this.discountAmountCtrl).max.max
                  : 0
              ) + "%";
          } else {
            validationMsgs += this.currencyFormatterService.formatMonetaryAmount({
              currencyIsoCode: this.currencyIsoCode,
              amount: this.discountAmountCtrl.validator(this.discountAmountCtrl)
                ? this.discountAmountCtrl.validator(this.discountAmountCtrl).max.max
                : 0,
              showSymbol: true,
              showThousandsSep: true,
            });
          }
          validationMsgs += ". Please revise the amount to proceed.";
        }
      }
      if (
        this.discountAmountCtrl.validator(this.discountAmountCtrl) &&
        this.discountAmountCtrl.validator(this.discountAmountCtrl).min
      ) {
        validationMsgs = "Cannot be lower than ";
        if (this.discountTypeCtrl.value === DiscountType.Percentage) {
          validationMsgs +=
            parseInt(
              this.discountAmountCtrl.validator(this.discountAmountCtrl)
                ? this.discountAmountCtrl.validator(this.discountAmountCtrl).min.min
                : 0
            ) + "%";
        } else {
          validationMsgs += this.currencyFormatterService.formatMonetaryAmount({
            currencyIsoCode: this.currencyIsoCode,
            amount: this.discountAmountCtrl.validator(this.discountAmountCtrl)
              ? this.discountAmountCtrl.validator(this.discountAmountCtrl).min.min
              : 0,
            showSymbol: true,
            showThousandsSep: true,
          });
        }
      }

      this.keyboardActionsService._canAnnounceNumKeyPress = false;
      this.confirmationService.confirm({
        header: "Invalid Discount Amount",
        message: validationMsgs,
        rejectButtonStyleClass: "p-button-link",
        accept: () => {
          if (this.discountTypeCtrl.value === DiscountType.Final_Price) {
            this.discountAmountCtrl.reset(this._myForm.get("unitPrice").value);
          } else {
            this.discountAmountCtrl.reset(0);
          }
          this.discAmountComponent.focusAndSelectInput();
          this.keyboardActionsService._canAnnounceNumKeyPress = true;
        },
        reject: () => {
          if (this.discountTypeCtrl.value === DiscountType.Final_Price) {
            this.discountAmountCtrl.reset(this._myForm.get("unitPrice").value);
          } else {
            this.discountAmountCtrl.reset(0);
          }
          this.discAmountComponent.focusAndSelectInput();
          this.keyboardActionsService._canAnnounceNumKeyPress = true;
        },
        acceptLabel: "OK",
        rejectVisible: false,
      });
    } else {
      const salePrice = this._recalculateSellPrice();
      this._myForm.get("salePrice").setValue(salePrice);
    }
  }

  onChangeDiscType() {
    InvoiceHelpers.switchDiscountType(
      this.discountTypeCtrl,
      this.discountAmountCtrl,
      this.unitPriceCtrl,
      this.confirmationService,
      "Line Price"
    );

    setTimeout(() => {
      this.discAmountComponent.focusAndSelectInput();
    }, 0);
    // const inputField = this.discAmountComponent.el.nativeElement;
    // this._focusAndSelectTakuInput(inputField.querySelector('input'));
  }

  private _recalculateSellPrice(): number {
    const unitPrice = this.unitPriceCtrl.value;
    const discountType = this.discountTypeCtrl.value;
    const discountAmount = this.discountAmountCtrl.value;

    const docLine: SaleDocLine = Object.assign(new SaleDocLine(), {
      unitPrice,
      discountType,
      discountAmount,
    });
    return docLine.salePrice;
  }

  // Attempts to increase quantity (user action)
  incQty(e?: Event): void {
    if (e) e.stopPropagation();
    this._setActualQty(this.calculateQtyIncrement);
  }

  // Attempts to decrease quantity (user action)
  decQty(e?: Event): void {
    if (e) e.stopPropagation();
    this._setActualQty(this.calculateQtyDecrement);
  }

  // This set the actual quantity in the form
  _setActualQty(newQty: number): void {
    if (!this.isQtyValid(newQty)) return;

    if (newQty >= this.qtyCtrl.value) {
      this._setActualQtyAfterValidation(newQty);
      return;
    }

    this.tenderWarningService.warnAboutTenders(() => this._setActualQtyAfterValidation(newQty)).subscribe();
  }

  private _setActualQtyAfterValidation(newQty: number): void {
    if (newQty >= 0 !== this.qtyCtrl.value >= 0) this._deleteOnInvalidQty(newQty);
    else
      this._myForm.patchValue({
        qty: newQty,
      });
  }

  abstract isQtyValid(newQty: number): boolean;

  _deleteOnInvalidQty(newQty: string | number, msg?: string): void {
    // Grab previous qty value
    const oldQty = this._myForm.value.qty;
    this.keyboardActionsService._canAnnounceNumKeyPress = false;

    WebHelpers.waitForConfirmationDialogRemoval(this.confirmationService).subscribe(() => {
      this.confirmationService.confirm({
        header: "Confirmation of Line Deletion",
        message: msg || "Assigning a zero quantity will remove the line. Are you sure?",
        rejectButtonStyleClass: "p-button-link",
        accept: () => {
          this.keyboardActionsService._canAnnounceNumKeyPress = true;
          this.deleted.emit();
        },
        reject: () => {
          this.keyboardActionsService._canAnnounceNumKeyPress = true;
          // Revert to previous qty value
          this._myForm.patchValue({ qty: oldQty });
          this._qtyComponent?.focusAndSelectInput();
          this.isSelected = true;
        },
      });
    });
  }

  get ctrlSaleDocLineTaxes(): UntypedFormArray {
    return <UntypedFormArray>this._myForm.get("saleDocLineTaxes");
  }

  get lineTaxesAcronyms(): string {
    const saleLine = SaleDocLine.convertIntoTypedObject(this._myForm.value);

    return InvoiceHelpers.GetCombinedTaxAcronyms(saleLine);
  }

  get ctrlDocLineRefNo(): AbstractControl {
    return this._myForm.get("docLine.refNo");
  }

  get salePriceCtrl() {
    return this._myForm.get("salePrice");
  }

  get unitPriceCtrl() {
    return this._myForm.get("unitPrice");
  }

  get qtyCtrl() {
    return this._myForm.get("qty");
  }

  get skuCtrl() {
    return this._myForm.get("sku");
  }

  get hasDiscount() {
    return this.salePriceCtrl.value != this.unitPriceCtrl.value;
  }

  get discountTypeCtrl(): AbstractControl {
    return this._myForm.get("discountType");
  }

  get discountAmountCtrl(): AbstractControl {
    return this._myForm.get("discountAmount");
  }

  get minQtyCtrl() {
    return this._myForm.get("minQty");
  }

  get maxQtyCtrl() {
    return this._myForm.get("maxQty");
  }

  get inventoryVariantsStr() {
    return this._myForm
      .get("docLine.inventory")
      .value.inventoryVariants?.map((_inventoryVariant) => _inventoryVariant.inventoryOptionsSettingValue?.optionValue)
      .join(",");
  }

  private calculateNormalizedDeltaQty(deltaSign: number) {
    const actualQty = this.qtyCtrl.value;
    const newQty = actualQty + deltaSign;
    const upperLimit: number = this.maxQtyCtrl ? this.maxQtyCtrl.value : null;
    const lowerLimit: number = this.minQtyCtrl ? this.minQtyCtrl.value : null;
    let diff = 0;
    if (lowerLimit != null && newQty < lowerLimit) diff = lowerLimit - newQty;
    else if (upperLimit != null && newQty > upperLimit) diff = upperLimit - newQty;

    let delta;
    if (diff != 0 && Math.abs(diff) < 1 && Math.abs(diff) > 0) delta = diff + newQty - actualQty;
    else {
      if (deltaSign > 0) {
        delta = deltaSign * Math.abs(actualQty % 1 ? 1 - (actualQty % 1) : 1);
      } else {
        delta = deltaSign * Math.abs(actualQty % 1 ? actualQty % 1 : 1);
      }
    }
    // delta = deltaSign * Math.abs(actualQty % 1 || 1);

    return MonetaryHelpers.roundToDecimalPlaces(delta, 4);
  }

  // Just return what would be the new increased qty with modifying the form
  get calculateQtyIncrement(): number {
    const currentQty: number = parseFloat(this.qtyCtrl.value);
    const delta = this.calculateNormalizedDeltaQty(Math.sign(currentQty) || 1);

    return MonetaryHelpers.roundToDecimalPlaces(currentQty + delta, 4);
  }

  // Just return what would be the new decreased qty with modifying the form
  get calculateQtyDecrement(): number {
    const currentQty: number = parseFloat(this.qtyCtrl.value);
    const delta = this.calculateNormalizedDeltaQty(-Math.sign(currentQty) || -1);
    return MonetaryHelpers.roundToDecimalPlaces(currentQty + delta, 4);
  }

  onDelete() {
    this.deleted.emit();
  }

  onLineClick($event) {
    const srcElement: HTMLElement = $event.srcElement;
    const dropdownParent = srcElement.closest("p-dropdown");

    // Only emit selected event when clicked area is not a control
    if (
      srcElement.className.indexOf("p-button") < 0 &&
      (!this.isSelected || !(srcElement.tagName.toLowerCase() === "input" || dropdownParent))
    ) {
      this.selected.emit();
    }
  }

  expandRow() {
    // this.rootContainer.nativeElement.className += ' acc-expanded';
    this.isExpanded = true;
    this.discAmountComponent.focusAndSelectInput();
  }

  collapseRow() {
    // this.rootContainer.nativeElement.className = this.rootContainer.nativeElement.className.replace(' acc-expanded', ''); // removing the class for closing the accordion
    this.isExpanded = false;
  }

  // get isExpanded():boolean{
  //   return this.rootContainer.nativeElement.className.indexOf('acc-expanded') >= 0;
  // }

  _focusAndSelectTakuInput(fieldInput: HTMLInputElement) {
    fieldInput.focus();
    fieldInput.select();
  }

  updateSaleLineTax(): any {
    if (!this.saleDocService.transactionAreaDisabled) {
      this.updateSaleLineTaxSubject.next();
    }
  }

  leftSideBarOpen() {
    return this.app.menuActive;
  }

  isInDoubleLineRange(): boolean {
    if (this.leftSideBarOpen()) {
      return (992 <= window.innerWidth && window.innerWidth <= 1720) || window.innerWidth <= 726;
    } else {
      return (992 <= window.innerWidth && window.innerWidth <= 1460) || window.innerWidth <= 726;
    }
  }

  inDocLineFormatChangeRange(): boolean {
    if (this.leftSideBarOpen()) {
      return (992 <= window.innerWidth && window.innerWidth <= 1186) || window.innerWidth <= 452;
    } else {
      return window.innerWidth <= 452;
    }
  }

  isNotDesktopDisplay(): boolean {
    return window.innerWidth < 992;
  }

  onUnitPriceChanged(amount) {
    this.unitPriceCtrl.setValue(amount);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
