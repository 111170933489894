/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef, TemplateRef } from "@angular/core";
import { Col, FormListFilter } from "../../../../form-list/form-list/form-list";
import { ActivatedRoute, Params } from "@angular/router";
import { map, catchError } from "rxjs/operators";
import { FormListComponent } from "../../../../form-list/form-list/form-list.component";
import { DocState } from "../../doc/doc";
import { PurchaseDocService } from "./purchase-doc.service";
import { MessageService } from "primeng/api";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";
import { concat, of, Subscription } from "rxjs";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { Person } from "src/app/core/contact-accounts/person/person";
import { PurchaseDocumentAddDialogComponent } from "../purchase-document-add-dialog/purchase-document-add-dialog.component";

@Component({
  selector: "taku-purchase-doc-list",
  templateUrl: "./purchase-doc-list.component.html",
  styleUrls: ["./purchase-doc-list.component.scss"],
})
export class PurchaseDocListComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];
  @ViewChild("formList", { static: true }) _formListComponent: FormListComponent;
  @ViewChild("totalReceivedTpl", { static: true }) totalReceivedTpl: TemplateRef<any>;
  purchaseOrderCols: Col[];

  formlistTitle: string;
  _defaultRowValues = {};
  _formFilters: FormListFilter = {};
  _detailViewRoute = "";
  _modelName = "purchaseDoc";
  _formListExtraParams = {
    includes: "stock",
  };

  constructor(
    private purchaseDocService: PurchaseDocService,
    private _route: ActivatedRoute,
    private messageService: MessageService,
    private appSettings: AppSettingsStorageService,
    private alertMessage: AlertMessagesService,
    private dialogRef: DynamicDialogRef,
    private dialogService: DialogService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.purchaseOrderCols = this.purchaseDocService.getFormListColumns({
      totalReceivedTemplateOptions: {
        data: Math.floor(0 + Math.random() * (100 - 0 + 1)),
        templateRef: this.totalReceivedTpl,
      },
    });

    this.subsList.push(
      this._route.params.pipe(map((params: Params) => +params.zoneId)).subscribe((zoneId) => {
        // Set filters to show only the current zone
        this._formFilters.zoneId = { value: zoneId, matchMode: "equals" };
        this._defaultRowValues = { zoneId };
      })
    );

    this.subsList.push(
      this._route.data.subscribe((routeData) => {
        this.formlistTitle = routeData.pageTitle || "";
        this._detailViewRoute = routeData.detailedViewRoute;

        // TODO: if we decide to crete inventory doc inside form list we need to specify docType as default row values
        if (routeData.docType) this._formFilters["doc.docType"] = { value: routeData.docType, matchMode: "equals" };
      })
    );
  }

  isAdminMode(): boolean {
    return this.appSettings.isAdminLogin();
  }

  voidSelectedDocs(): void {
    // Only void finalized docs
    const finalizedRows = this._formListComponent
      .getSelectedRows()
      .map((purchaseDoc) => purchaseDoc.doc)
      .filter((doc) => doc.state === DocState.finalized);

    if (!finalizedRows.length) {
      this.messageService.add({
        severity: "info",
        summary: "Nothing to void",
        detail: "There are NO finalized documents that could be voided",
        life: 8000,
      });

      return;
    }

    this.patchSelectedFormListRows(finalizedRows, DocState.voided);

    // this._formListComponent.saveAll();
  }

  confirmSelectedDocs(): void {
    // Only finalized finalized drafted docs
    const draftedRows = this._formListComponent
      .getSelectedRows()
      .map((purchaseDoc) => purchaseDoc.doc)
      .filter((doc) => doc.state === DocState.draft);

    if (!draftedRows.length) {
      this.messageService.add({
        severity: "info",
        summary: "Nothing to finalize",
        detail: "There are NO drafted documents that could be finalized",
        life: 8000,
      });

      return;
    }

    this.patchSelectedFormListRows(draftedRows, DocState.finalized);
    // this._formListComponent.saveAll();
  }

  markAsComplete(): void {
    const draftedRows = this._formListComponent
      .getSelectedRows()
      .map((purchaseDoc) => purchaseDoc.doc)
      .filter((doc) => doc.state === DocState.finalized);

    if (!draftedRows.length) {
      this.messageService.add({
        severity: "info",
        summary: "Nothing to complete",
        detail: "An order can only be completed if it's finalized.",
        life: 8000,
      });

      return;
    }

    this.patchSelectedFormListRows(draftedRows, DocState.approved);
  }

  addNewDoc(): void {
    this.dialogRef = this.dialogService.open(PurchaseDocumentAddDialogComponent, {
      data: {},
      width: "90%",
      height: "90%",
      closable: false,
      showHeader: false,
    });
    this.subsList.push(
      this.dialogRef.onClose.subscribe((purchaseOrderDefault) => {
        if (purchaseOrderDefault) {
          const { stockId, commercialAccountId, currencyIsoCode, currencyConversionRate } = purchaseOrderDefault;

          this._defaultRowValues = {
            ...this._defaultRowValues,
            stockId,
            commercialAccountId,
            accountName: this.getAccountName(purchaseOrderDefault),
            doc: { currencyIsoCode, currencyConversionRate },
          };

          this.changeDetectorRef.detectChanges();
          this._formListComponent.goAdd();
        }
      })
    );
  }

  private getAccountName(purchaseOrderDefault: any): string {
    const { personalAccount, commercialAccount } = purchaseOrderDefault;
    if (personalAccount?.id) {
      const { firstName, middleName, lastName } = personalAccount.person as Person;
      return `${firstName} ${middleName} ${lastName}`;
    }

    if (commercialAccount?.id) {
      return commercialAccount.name;
    }

    return "";
  }

  deleteSelectedDocs(): void {
    // Can remove only drafted docs
    const draftRows = this._formListComponent.getSelectedRows().filter((invDoc) => invDoc.doc.state === DocState.draft);

    if (!draftRows.length) {
      this.messageService.add({
        severity: "info",
        summary: "Notification",
        detail: "Only Drafts Can Be Deleted",
        life: 8000,
      });

      return;
    }

    // Shold make a request for each row to delete
    this.subsList.push(
      concat(...draftRows.map((row) => this.purchaseDocService.deleteRow(this._modelName, row.id)))
        .pipe(
          catchError((error) => {
            this.messageService.add(this.alertMessage.getErrorMessage(error, "", ""));

            return of({ success: false });
          }),
          map((response: any) => response.success)
        )
        .subscribe({
          next: (success) => {
            // For each sucessful deletion
            if (success) this.messageService.add(this.alertMessage.getMessage("delete-success"));
          },
          // once all rows are removed/process reload form list so it reflects the new data
          complete: () => {
            this._formListComponent.reloadData();
          },
        })
    );
  }

  private patchSelectedFormListRows(rows: any[], patchFields: DocState) {
    const objectIds = rows.map((object) => object.id);
    const filter = {
      id: { matchMode: "in", value: objectIds },
    };
    this.subsList.push(
      this.purchaseDocService
        .patchRows(
          "doc",
          {
            state: patchFields,
          },
          JSON.stringify(filter)
        )
        .subscribe({
          next: (response) => {
            this.messageService.add(this.alertMessage.getMessage("multi-edit-success"));
            this._formListComponent.reloadData();
          },
          error: (error) => {
            this.messageService.add(this.alertMessage.getErrorMessage(error, "", ""));
          },
        })
    );
  }

  ngOnDestroy(): void {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
