/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";

import { DBService } from "../../../shared/services/db.service";
import { DataType, FilterType, Col } from "../../../form-list/form-list/form-list";
import { FormListModel } from "../../../form-list/form-list/form-list-model.interface";
import { ValidatorFn, Validators } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class InventoryCategoryService extends DBService implements FormListModel {
  getValidationRules(): { [key: string]: ValidatorFn[] | {} } {
    return { categoryName: [Validators.required] };
  }

  getFormListColumns() {
    return [
      {
        field: "isEnabled",
        header: "Active",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.checkbox,
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.enum,
      },
      {
        field: "categoryName",
        header: "Category Name",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "includedInGallery",
        header: "Show in Gallery",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.checkbox,
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.enum,
      },
    ];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
