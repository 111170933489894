/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnDestroy, OnChanges } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import * as _ from "lodash";
import { ConfirmationService, SelectItem } from "primeng/api";
import { UnsavedChangesGuard } from "../../../../app-routing/UnsavedChanges.guard";
import { DBService } from "../../../../shared/services/db.service";
import { SearchResultItem } from "../../../../taku-ui/taku-search-accounts/SearchResultItem";
import { InvoiceHelpers } from "../../../../utility/InvoiceHelpers";
import { Address, AddressType } from "../../../contact-accounts/address/address";
import { StoreSettingSaleDoc } from "../../../settings/store-settings/sales-doc-settings/sales-doc-settings";
import { Subscription } from "rxjs";
import { DeliveryMethod } from "src/app/core/sale-channel/sale-channel-orders/sale-channel-order";
import { ShipperService } from "src/app/core/settings/business-settings/shipper/shipper.service";

@Component({
  selector: "taku-saledoc-delivery-method",
  templateUrl: "./saledoc-delivery-method.component.html",
  styleUrls: ["./saledoc-delivery-method.component.scss"],
})
export class SaledocDeliveryMethodComponent implements OnInit, OnChanges, OnDestroy {
  subsList: Subscription[] = [];
  @Input() account: SearchResultItem;
  @Input() saleForm: UntypedFormGroup;
  @Input() salesDocSettings: StoreSettingSaleDoc;
  // @Output() onSaved: EventEmitter<{shipperName: string}> = new EventEmitter;
  @Output() closed: EventEmitter<any> = new EventEmitter();
  @Output() deliveryMethodEvent: EventEmitter<string> = new EventEmitter();

  pageTitle = "Shipping";
  lookupShippers: SelectItem[];
  tmpSaveForm: UntypedFormGroup;
  // temporary object for shipper changes
  tmpShipper: Record<string, any> = {};
  // shipping method entered by the user
  selectedDeliveryMethod: DeliveryMethod;
  Deliverymethod = DeliveryMethod;
  accountShippingAddress: Address;
  enumShippingMethod = this.shipperService.enumShippingMethod;
  todayDate = new Date();

  constructor(
    public dbService: DBService,
    private fb: UntypedFormBuilder,
    private confirmationService: ConfirmationService,
    private unsavedChangesGuard: UnsavedChangesGuard,
    public shipperService: ShipperService
  ) {}

  ngOnInit() {
    //initial set or remove validator based on the delivery method pre-selection
    if (this.tmpSaveForm.get("deliveryMethod").value !== DeliveryMethod.storePickup) {
      this.addValidators();
    } else {
      this.removeValidators();
    }

    const activeFilter = {
      zoneId: { matchMode: "equals", value: this.saleForm.value.store.zoneId },
      deliveryMethod: { matchMode: "equals", value: this.saleForm.value.deliveryMethod },
    };
    this.subsList.push(
      this.dbService
        .lookupSelectOptions("shipper", "shipperName", {
          dataKey: null,
          enableFieldName: "isActive",
          lookupFilter: JSON.stringify(activeFilter),
        })
        .subscribe((shippers) => {
          this.lookupShippers = shippers;
          const shipperId = this.tmpSaveForm.value?.shipperId;
          if (typeof shipperId === "number") {
            this.tmpSaveForm.get("shipperId").patchValue(shippers.find((f) => f?.object?.id === shipperId).value);
          }
        })
    );

    this.subsList.push(
      this.tmpSaveForm.get("shipperId").valueChanges.subscribe((newShipper) => {
        this.tmpSaveForm.get("shipperChargeAmount").setValue(0);
        if (newShipper) {
          this.enumShippingMethod = this.shipperService.enumShippingMethod.filter((row) =>
            row.value === null ||
            this.lookupShippers.find((row) => {
              return row.value && row.value.id === newShipper.id;
            }).value.shippingMethod
              ? this.lookupShippers
                  .find((row) => {
                    return row.value && row.value.id === newShipper.id;
                  })
                  .value.shippingMethod.includes(row.value)
              : false
          );
        }
        this.tmpSaveForm.get("shippingMethod").setValue(this.enumShippingMethod[0].value); // select first option
      })
    );
    this.subsList.push(
      this.tmpSaveForm.get("shippingMethod").valueChanges.subscribe((newShippingMethod) => {
        if (!newShippingMethod) {
          this.tmpSaveForm.get("shipperChargeAmount").setValue(0);
        }
      })
    );
    this.subsList.push(
      this.tmpSaveForm.get("deliveryMethod").valueChanges.subscribe((newDeliveryMethod) => {
        //dynamically add or remove when selection changes
        if (newDeliveryMethod !== DeliveryMethod.storePickup) {
          this.addValidators();
        } else {
          this.removeValidators();
        }

        if (newDeliveryMethod) {
          this.deliveryMethodEvent.emit(newDeliveryMethod);
          const activeFilter = {
            zoneId: { matchMode: "equals", value: this.saleForm.value.store.zoneId },
            deliveryMethod: { matchMode: "equals", value: newDeliveryMethod },
          };
          this.subsList.push(
            this.dbService
              .lookupSelectOptions("shipper", "shipperName", {
                dataKey: null,
                enableFieldName: "isActive",
                lookupFilter: JSON.stringify(activeFilter),
              })
              .subscribe((shippers) => {
                this.lookupShippers = shippers;
                this.selectedDeliveryMethod = newDeliveryMethod;

                if (this.tmpShipper[this.removeSpacesFromString(newDeliveryMethod)]) {
                  setTimeout(() => {
                    this.tmpSaveForm
                      .get("shipperId")
                      .patchValue({ ...this.tmpShipper[this.removeSpacesFromString(newDeliveryMethod)] });
                  }, 0);
                } else {
                  this.tmpSaveForm.get("shipperId").setValue(null);
                }
              })
          );
        }
      })
    );

    // checks if there is a pre-selected delivery method and saves a copy of the
    // `shipperId` in the `tmpShipper` variable.
    if (!Object.entries(this.tmpShipper).length) {
      this.selectedDeliveryMethod = this.tmpSaveForm.get("deliveryMethod").value;
      this.onItemSelected({ value: this.tmpSaveForm.get("shipperId").value });
    }
  }

  removeSpacesFromString(input: string): string {
    return input.split(" ").join("");
  }

  /**
   * Takes in the user selection from the `taku-dropdown` component
   * and adds it to the `tmpShipper` object. This object contains the
   * current types of shipping methods the application supports.
   * @param {originalEvent: PointerEvent, value: any} e: Data emmited
   * from `taku-dropdown`.
   */
  onItemSelected(e: { value: any; originalEvent?: PointerEvent }): void {
    const selectedObj = {};
    selectedObj[this.removeSpacesFromString(this.selectedDeliveryMethod)] = e.value;
    Object.assign(this.tmpShipper, selectedObj);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.saleForm) {
      this._copyFromSaleForm();
    }

    if (changes.account || changes.salesDocSettings) {
      if (this.account)
        this.accountShippingAddress = InvoiceHelpers.findAccountAddressByType(
          AddressType.shippingAddress,
          this.account
        );

      // Don't Auto Copy by default when 'Sale Doc Settings' has not been created yet,
      // additionally check if address haven't been set in order to prevent overwrite
      if (
        this.salesDocSettings &&
        this.salesDocSettings.autoCopyAccountAddress &&
        this.saleForm.get("shippingAddress").pristine
      ) {
        this.copyShippingAddressFromAccount();
      }
    }
  }

  _copyFromSaleForm() {
    this.tmpSaveForm = this.fb.group({
      shipperId: _.cloneDeep(this.getFormShipperId(this.saleForm)),
      shippingMethod: _.cloneDeep(this.saleForm.get("shippingMethod")),
      shipperChargeAmount: _.cloneDeep(this.getFormShipperChargeAmount(this.saleForm)),
      shippingAddress: this.setRequiredAddressFields(),
      deliveryMethod: _.cloneDeep(this.getFormDeliveryMethod(this.saleForm)),
      deliveryDate: _.cloneDeep(this.getFormDeliveryDate(this.saleForm)),
    });

    if (!this.tmpSaveForm.get("deliveryDate")?.value) {
      this.tmpSaveForm.get("deliveryDate").setValue(this.todayDate);
      this.tmpSaveForm.get("deliveryDate").markAsDirty();
      this.tmpSaveForm.get("deliveryDate").updateValueAndValidity();
    }
  }

  copyShippingAddressFromAccount() {
    if (this.accountShippingAddress) {
      const shippingAddressForm = this.getFormShippingAddress(this.tmpSaveForm);
      shippingAddressForm.patchValue(this.accountShippingAddress);
      shippingAddressForm.markAsDirty();
    }
  }

  private setRequiredAddressFields() {
    const addressFormControl = _.cloneDeep(this.getFormShippingAddress(this.saleForm));
    addressFormControl.get("line1").setValidators([Validators.required]);
    addressFormControl.get("city").setValidators([Validators.required]);
    return addressFormControl;
  }

  private addValidators() {
    this.tmpSaveForm.get("shipperId").setValidators([Validators.required]);
    this.tmpSaveForm.get("shippingAddress.line1").setValidators([Validators.required]);
    this.tmpSaveForm.get("shippingAddress.city").setValidators([Validators.required]);

    this.tmpSaveForm.get("shipperId").updateValueAndValidity();
    this.tmpSaveForm.get("shippingAddress.line1").updateValueAndValidity();
    this.tmpSaveForm.get("shippingAddress.city").updateValueAndValidity();
  }

  private removeValidators() {
    this.tmpSaveForm.get("shipperId").clearValidators();
    this.tmpSaveForm.get("shippingAddress.line1").clearValidators();
    this.tmpSaveForm.get("shippingAddress.city").clearValidators();

    this.tmpSaveForm.get("shipperId").updateValueAndValidity();
    this.tmpSaveForm.get("shippingAddress.line1").updateValueAndValidity();
    this.tmpSaveForm.get("shippingAddress.city").updateValueAndValidity();
  }

  private getFormDeliveryMethod(formGroup: AbstractControl): AbstractControl {
    return formGroup.get("deliveryMethod");
  }

  private getFormDeliveryDate(formGroup: AbstractControl): AbstractControl {
    return formGroup.get("deliveryDate");
  }

  private getFormShipperId(formGroup: AbstractControl): AbstractControl {
    return formGroup.get("shipperId");
  }

  private getFormShipperChargeAmount(formGroup: AbstractControl): AbstractControl {
    return formGroup.get("shipperChargeAmount");
  }

  getFormShippingAddress(formGroup: AbstractControl): AbstractControl {
    return formGroup.get("shippingAddress");
  }

  onSavedClicked() {
    const deliveryMethod = this.getFormDeliveryMethod(this.tmpSaveForm).value;
    const deliveryDate = this.getFormDeliveryDate(this.tmpSaveForm).value;
    this.saleForm.patchValue({
      deliveryMethod,
      deliveryDate,
    });
    if (deliveryMethod !== DeliveryMethod.storePickup) {
      // only updated shipping related fields when Ship Out is selected
      // this.saleForm.patchValue({
      //   shipperId: this.tmpSaveForm.get('shipperId').value,
      //   shipperChargeAmount: this.tmpSaveForm.get('shipperChargeAmount').value,
      //   shippingAddress: this.tmpSaveForm.get('shippingAddress').value,
      // });
      this.getFormShipperId(this.saleForm).setValue(this.getFormShipperId(this.tmpSaveForm).value);

      this.saleForm.get("shippingMethod").setValue(this.tmpSaveForm.get("shippingMethod").value);

      this.getFormShipperChargeAmount(this.saleForm).setValue(this.getFormShipperChargeAmount(this.tmpSaveForm).value);

      this.getFormShippingAddress(this.saleForm).patchValue(this.getFormShippingAddress(this.tmpSaveForm).value);
    } else {
      this.saleForm.patchValue({
        shipperId: null,
        shippingMethod: null,
        shipperChargeAmount: 0,
      });
      this.clearAddress(this.getFormShippingAddress(this.saleForm));
    }

    this._copyFromSaleForm();
    this.closed.emit();
  }

  onRevertClicked() {
    this._copyFromSaleForm();
    this.tmpShipper = {};
  }

  // onTryToClose() {
  //   this.unsavedChangesGuard.checkForm(this.tmpSaveForm).subscribe(canClose => {
  //     if (canClose) this.closed.emit();
  //   })
  // }

  onClose() {
    // Guarding against unsaved changes will be done by the Form Header component
    // so when this method is called that means that user already confirmed to close the dialog
    this.closed.emit();
  }

  clearAddress(address: AbstractControl) {
    // Before reseting address backup current address type
    const _addressType = address.get("addressType").value;
    const _id = address.get("id").value;
    const _addressEmailId = address.get("addressEmail").get("id").value;
    const _addressPhoneId = address.get("addressPhone").get("id").value;

    const _newAddress = new Address(address.get("addressLocationType").value, address.get("addressType").value);
    address.reset(_newAddress);
    address.patchValue({
      id: _id,
      addressEmail: {
        id: _addressEmailId,
      },
      addressPhone: {
        id: _addressPhoneId,
      },
    });
    // address.markAsDirty();
  }
  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
