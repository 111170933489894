/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
export class AppConstants {
  static readonly DATE_FMT = "yyyy-MM-dd";
  static readonly TIME_FMT = "hh:mm a";
  static readonly TIME_SECS_FMT = "hh:mm:ss a";
  static readonly DATE_TIME_FMT = AppConstants.DATE_FMT + " @ " + AppConstants.TIME_FMT;
  static readonly DATE_TIME_SECS_FMT = AppConstants.DATE_FMT + " @ " + AppConstants.TIME_SECS_FMT;

  static readonly DEFAULT_LOCALE_ID = "en-US";

  static readonly TAKU_MERCHANT_ID = "132842056";
  static readonly CONTENT_API_CLIENT_ID = "260558601920-eunnmlf7im7rr56puojnlhclnk79rtgo.apps.googleusercontent.com";
  static readonly GMB_API_CLIENT_ID = "260558601920-eunnmlf7im7rr56puojnlhclnk79rtgo.apps.googleusercontent.com";
  // static readonly GOOGLE_CLIENT_ID = '260558601920-eunnmlf7im7rr56puojnlhclnk79rtgo.apps.googleusercontent.com';

  static Currency = {
    INPUT_DEBOUNCE_TIME: 1500,
  };

  static KEY_CODES = {
    TAB: {
      name: "Tab",
      code: 9,
    },
    ENTER: {
      code: 13,
    },
    BACKSPACE: {
      code: 8,
    },
    ARROW_UP: {
      code: 38,
    },
    ARROW_DOWN: {
      code: 40,
    },
    ESCAPE: {
      code: 27,
    },
  };
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
