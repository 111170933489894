/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { BehaviorSubject, Observable, Subject, merge } from "rxjs";
import { tap } from "rxjs/operators";
import { AuthService } from "../auth.service";

export type ServerErrorTrace = {
  timestamp: string;
  message: string;
  traceId: string;
};

let allServerErrors: ServerErrorTrace[] = [];
const allServerErrorsSubject = new BehaviorSubject<ServerErrorTrace[]>(allServerErrors);
export const AllServerErrors$ = allServerErrorsSubject.asObservable();

const serverErrorSubject = new Subject<ServerErrorTrace>();
serverErrorSubject.subscribe((newServerError) => {
  allServerErrors.unshift(newServerError);
  allServerErrorsSubject.next(allServerErrors);
});

@Injectable({
  providedIn: "root",
})
export class TraceIdErrorInterceptor implements HttpInterceptor {
  constructor(authService: AuthService) {
    merge(authService.logoutSource$, authService.loginSource$).subscribe(() => {
      allServerErrors = [];
      allServerErrorsSubject.next(allServerErrors);
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap({
        error: (err: any) => {
          if (err instanceof HttpErrorResponse && err.status >= 500) {
            const traceId = err.headers.get("x-cloud-trace-context");
            const innerErrors = !Array.isArray(err.error.errors)
              ? ""
              : err.error.errors.map((e) => e.message).join(". ");
            const message =
              (err.error.customMessage || err.error.message) + (innerErrors ? `: ${innerErrors}` : "") ||
              JSON.stringify(err.error);

            serverErrorSubject.next({ timestamp: new Date().toISOString(), message, traceId });
          }
        },
      })
    );
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
