/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import * as _ from "lodash";
import { Stock } from "../../../settings/business-settings/stock/stock";
import { Doc, DocState, InventoryDocType } from "../../doc/doc";
import { DocLine } from "../../doc-line/doc-line";
import { MonetaryHelpers } from "../../../../utility/MonetaryHelpers";
import { CommercialAccount } from "../../../contact-accounts/commercial-account/commercial-account";
import { AccountType, Account } from "../../../contact-accounts/account/account";
import { PaymentTerm } from "src/app/core/purchase-order-term/payment-term/payment-term";
import { ShippingTerm } from "src/app/core/purchase-order-term/shipping-term/shipping-term";
import { Address } from "src/app/core/contact-accounts/address/address";
import { DiscountType } from "../../sale-document/sale-doc/sale-doc";
import { TaxRule } from "src/app/core/tax/tax-rule/tax-rule";
import {
  InventorySupplierProductDetail,
  PackSize,
} from "src/app/core/inventory/inventory-supplier-product-detail/inventory-supplier-product-detail";
import {
  TotalBySaleTax,
  DiscountType as SaleDocLineDiscountType,
} from "../../sale-document/sale-doc-line/sale-doc-line";
import { Person } from "src/app/core/contact-accounts/person/person";
import { Zone } from "src/app/core/settings/zone-settings/Zone";
import { InventoryStock } from "src/app/core/inventory/inventory/inventory";
import { Store } from "src/app/core/settings/store-settings/store/store";
import { BackOffice } from "src/app/core/settings/backOffice-settings/backOffice/backOffice";

export type EmailTuple = {
  email: string;
  name: string;
};

export type EmailRecipient = EmailTuple & {
  cc?: EmailTuple[];
};

export type PurchaseDocEmailHistory = EmailRecipient & {
  timestamp: string;
};

export type PurchaseDocAccount = {
  commercialAccount: CommercialAccount;
} & Account;
export class PurchaseDoc {
  id: number;
  shippingDate: Date;
  subTotal: number;
  discountType: DiscountType;
  discountAmount: number;
  stockId: number;
  stock: Stock;
  paymentTermId: number;
  paymentTerm: PaymentTerm;
  shippingTermId: number;
  shippingTerm: ShippingTerm;
  shippingAddressId: number;
  shippingAddress: Address;
  stockAddress: Address;
  shippingContactId: number;
  shippingContact: Person;
  zoneId: number;
  zone: Zone;
  doc: Doc;
  purchaseDocLines: PurchaseDocLine[];
  accountId: number;
  account: PurchaseDocAccount;
  stockAdjustmentType: StockAdjustmentType;
  deliveryInstruction: string;
  supplierInstruction: string;
  shipVia: string;
  shipperChargeAmount: number;
  totalLineTax: number;
  taxID: string;
  purchaseDocTaxes: PurchaseDocTax[];
  updatedAt: string;
  createdAt: string;
  storeId: number;
  store: Store;
  backOfficeId: number;
  backOffice: BackOffice;
  buyerName: string;
  emailHistory: null | PurchaseDocEmailHistory[] = null;

  constructor(
    docType: InventoryDocType = null,
    stockAdjustmentType: StockAdjustmentType = null,
    defaultZone = null,
    defaultCurrencyIsoCode?: string
  ) {
    this.id = 0;
    this.shippingDate = new Date();
    this.subTotal = 0;
    this.discountType = DiscountType.Percentage;
    this.discountAmount = 0;
    this.stockId = null;
    this.stock = new Stock();
    this.paymentTermId = null;
    this.paymentTerm = new PaymentTerm();
    this.shippingTermId = null;
    this.shippingTerm = new ShippingTerm();
    this.shippingAddressId = null;
    this.shippingAddress = new Address();
    this.stockAddress = new Address();
    this.shippingContactId = null;
    this.shippingContact = new Person();
    this.zoneId = defaultZone?.id || null;
    this.zone = defaultZone;
    this.doc = new Doc(docType, DocState.draft);
    this.purchaseDocLines = [];
    this.accountId = null;
    this.account = new Account(AccountType.commercial) as PurchaseDocAccount;
    this.account.commercialAccount = new CommercialAccount(defaultCurrencyIsoCode);
    this.stockAdjustmentType = stockAdjustmentType;
    this.deliveryInstruction = "";
    this.supplierInstruction = "";
    this.shipVia = "";
    this.shipperChargeAmount = 0;
    this.totalLineTax = 0;
    this.taxID = null;
    this.purchaseDocTaxes = [];
    this.updatedAt = null;
    this.createdAt = null;
    if (defaultZone) {
      this.doc.currencyIsoCode = defaultZone.defaultCurrencyIsoCode;
    }
    this.storeId = null;
    this.store = null;
    this.backOfficeId = null;
    this.backOffice = null;
    this.buyerName = null;
  }

  static convertIntoTypedObject(purchaseDoc: PurchaseDoc): PurchaseDoc {
    const typedPurchaseDoc: PurchaseDoc = _.merge(new PurchaseDoc(), purchaseDoc);
    typedPurchaseDoc.purchaseDocLines = typedPurchaseDoc.purchaseDocLines.map((purchaseDocLine) =>
      PurchaseDocLine.convertIntoTypedObject(purchaseDoc, purchaseDocLine)
    );
    typedPurchaseDoc.discountAmount = MonetaryHelpers.roundToDecimalPlaces(typedPurchaseDoc.discountAmount);

    return typedPurchaseDoc;
  }

  get groupedTaxLines(): TotalBySaleTax[] {
    const linesTaxes = _.flatten(this.purchaseDocLines.map((purchaseDocLine) => purchaseDocLine.purchaseDocLineTaxes));
    const groupedTaxes = _.groupBy(linesTaxes, (lineTax: PurchaseDocLineTax) => lineTax.taxRuleId);
    const totalGrouped = _.map(groupedTaxes, (taxes: PurchaseDocLineTax[]) => {
      if (!taxes[0].taxRule)
        return {
          taxes: taxes,
          saleTaxRule: null,
          total: _.sumBy(taxes, (tax) => parseFloat(tax.amount + "")),
        };

      return {
        taxes: taxes,
        saleTaxRule: taxes[0].taxRule,
        total: _.sumBy(taxes, (tax) => parseFloat(tax.amount + "")),
      };
    });
    return totalGrouped;
  }

  get totalQty(): number {
    return PurchaseDoc.totalQty(this as PurchaseDoc);
  }

  static totalQty(purchaseDoc: PurchaseDoc): number {
    return purchaseDoc.purchaseDocLines.reduce((acc, purchaseDocLine) => acc + purchaseDocLine.qty, 0);
  }

  get totalReceivedQty(): number {
    return PurchaseDoc.totalReceivedQty(this as PurchaseDoc);
  }

  static totalReceivedQty(purchaseDoc: PurchaseDoc): number {
    return purchaseDoc.purchaseDocLines.reduce((acc, purchaseDocLine) => acc + purchaseDocLine.receivedQty, 0);
  }

  get totalReceivedPercentage(): number {
    return PurchaseDoc.totalReceivedPercentage(this as PurchaseDoc);
  }

  static totalReceivedPercentage(purchaseDoc: PurchaseDoc): number {
    const totalQty = PurchaseDoc.totalQty(purchaseDoc);
    if (totalQty === 0) {
      return 0;
    }
    const percentage = (PurchaseDoc.totalReceivedQty(purchaseDoc) * 100) / totalQty;
    return +percentage.toFixed(2);
  }

  get totalReceivedAmount(): number {
    return this.purchaseDocLines.reduce(
      (acc, purchaseDocLine) => acc + purchaseDocLine.receivedQty * purchaseDocLine.unitPrice,
      0
    );
  }

  get totalPendingAmount(): number {
    return this.subTotal - this.totalReceivedAmount;
  }

  get grandTotal(): number {
    const subTotal = Number(this.subTotal);
    const discountAmount = Number(this.discountAmount);
    const shipperChargeAmount = Number(this.shipperChargeAmount);
    const totalLineTax = Number(this.totalLineTax);

    const total =
      subTotal -
      (this.discountType === DiscountType.Fixed ? discountAmount : (subTotal * discountAmount) / 100) +
      shipperChargeAmount +
      totalLineTax;

    return total;
  }
}

export class PurchaseDocTax {
  id: number;
  amount: number;
  taxRuleId: number;
  taxRule: TaxRule;

  constructor() {
    this.id = 0;
    this.amount = 0;
    this.taxRuleId = 0;
    this.taxRule = null;
  }
}
export class PurchaseDocLine {
  id: number;
  qty: number;
  receivedQty: number;
  unitPrice: number;
  docLine: DocLine;
  uOfMeasureId: number;
  packSize: PackSize;
  /** This is a dynamic, front-end-only field that is added to be used for formatting in formlists */
  docReference: PurchaseDoc;
  allocatedDiscountType: SaleDocLineDiscountType;
  allocatedDiscountAmount: number;
  allocatedShipperChargeAmount: number;
  purchaseDocLineTaxes: PurchaseDocLineTax[];

  constructor(docReference: PurchaseDoc) {
    this.id = 0;
    this.qty = 0;
    this.receivedQty = 0;
    this.unitPrice = 0;
    this.docLine = new DocLine();
    this.uOfMeasureId = null;
    this.packSize = PackSize.SKU;
    this.docReference = docReference;
    this.allocatedDiscountType = DiscountType.Fixed;
    this.allocatedDiscountAmount = 0;
    this.allocatedShipperChargeAmount = 0;
    this.purchaseDocLineTaxes = [];
  }

  static convertIntoTypedObject(purchaseDoc: any, purchaseDocLine: any): PurchaseDocLine {
    return _.merge(new PurchaseDocLine(purchaseDoc), purchaseDocLine);
  }

  get supplierCode(): string {
    const detail = this.docLine.inventory.inventorySuppliers[0].inventorySupplierProductDetails.find((row) => {
      return row.packSize === this.packSize;
    });
    return detail ? detail.supplierCode : undefined;
  }

  get skuQtyPerPack(): number {
    return PurchaseDocLine.getSkuQtyPerPack(this);
  }

  get inventoryVariantsStr() {
    return _.sortBy(this.docLine.inventory.inventoryVariants, "inventoryOptionsSettingId")
      ?.map((_inventoryVariant) => _inventoryVariant?.inventoryOptionsSettingValue?.optionValue)
      .join(" / ");
  }

  static getSkuQtyPerPack(purchasDocLine: PurchaseDocLine): number {
    let detail: InventorySupplierProductDetail;
    if (purchasDocLine.docLine?.inventory?.inventorySuppliers?.length) {
      detail = purchasDocLine.docLine.inventory.inventorySuppliers[0].inventorySupplierProductDetails.find((row) => {
        return row.packSize === purchasDocLine.packSize;
      });
    }

    return detail ? detail.skuQty : 1;
  }

  get totalQty(): number {
    return (this.qty || 0) * (this.skuQtyPerPack || 1);
  }

  get poInventoryStock(): InventoryStock {
    return this.docLine.inventory.inventoryStocks.find((row) => row.stockId === this.docReference.stockId);
  }

  get convertedUnitPrice(): number {
    if (this.docReference && this.docReference.doc) {
      return MonetaryHelpers.roundToDecimalPlaces(
        Number(this.unitPrice) * this.docReference.doc.currencyConversionRate
      );
    }
    return MonetaryHelpers.roundToDecimalPlaces(Number(this.unitPrice)); // or return 0 or any other default value if there's no reference or currencyConversionRate
  }

  get totalPrice(): number {
    return (
      this.subTotal -
      Number(this.totalDiscountAmount) +
      Number(this.allocatedShipperChargeAmount) +
      Number(this.totalTax)
    );
  }

  get qtyOrder(): number {
    return MonetaryHelpers.roundToDecimalPlaces(+this.qty);
  }

  get totalDiscountAmount(): number {
    switch (this.allocatedDiscountType) {
      case DiscountType.Fixed:
        return MonetaryHelpers.roundToDecimalPlaces(+this.allocatedDiscountAmount);

      case DiscountType.Percentage:
        return MonetaryHelpers.roundToDecimalPlaces(
          (+this.qty * +this.unitPrice * +this.allocatedDiscountAmount) / 100
        );

      default:
        return 0;
    }
  }

  get unitDiscount(): number {
    return +this.qty === 0 ? 0 : MonetaryHelpers.roundToDecimalPlaces(this.totalDiscountAmount / +this.qty);
  }

  get totalTax(): number {
    return this.purchaseDocLineTaxes.reduce(
      (total, purchaseDocLineTax) => total + Number(purchaseDocLineTax.amount),
      0
    );
  }

  get subTotal(): number {
    return MonetaryHelpers.roundToDecimalPlaces(+this.qty * this.unitPrice);
  }
}

export class PurchaseDocLineTax {
  id: number;
  amount: number;
  taxRuleId: number;
  taxRule: TaxRule;

  constructor() {
    this.id = 0;
    this.amount = 0;
    this.taxRuleId = 0;
    this.taxRule = null;
  }
}
export enum StockAdjustmentType {
  In = "In",
  Out = "Out",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
