/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit } from "@angular/core";
import { MenuItem, SelectItem } from "primeng/api";
import { InvoiceKeyboardActionsService } from "../sale-invoice-doc-base/invoice-keyboard-actions.service";
import { SaleInvoiceDocBaseComponent } from "../sale-invoice-doc-base/sale-invoice-doc-base.component";
import { OverlayModalName } from "../generic-sale-doc.component";
import { SalesSideDialogName, SideDialogNameMobile } from "../../types/invoice-enums.type";
import { WebHelpers } from "src/app/utility/WebHelpers";
import { SaleDocSource } from "../sale-doc/sale-doc";
import { SaleDocType } from "../../doc/doc";

@Component({
  selector: "taku-sale-invoice-doc-mobile",
  templateUrl: "./sale-invoice-doc-mobile.component.html",
  styleUrls: ["./sale-invoice-doc-mobile.component.scss"],
})
export class SaleInvoiceDocMobileComponent extends SaleInvoiceDocBaseComponent implements OnInit {
  mainMenuItems: MenuItem[];
  enum_saledoc_sources: SelectItem[];

  ngOnInit() {
    super.ngOnInit();
    this.enum_saledoc_sources = this.dbService.enumSelectOptions(SaleDocSource);

    this.mainMenuItems = this.buildMainMenu(this.isTenderScreenOpened);

    this.subsList.push(
      this.saleInvoiceStateService.tenderScreenOpen$.subscribe((isOpen) => {
        this.mainMenuItems = this.buildMainMenu(isOpen);
      })
    );
  }

  getDocTypeLabel() {
    if (this.docComponent.selectedDocType == SaleDocType.sales_invoice) return "SALES";
    else return "PRE-ORDERS";
  }

  buildMainMenu(isDisabled: boolean): MenuItem[] {
    return [
      {
        label: `<i class="material-icons">list</i> <span>Lists</span>`,
        escape: false,
        command: () => {
          this.openRightDialog(SalesSideDialogName.SALE_DOC_LIST);
        },
        disabled: isDisabled || false,
        visible: true,
      },
      {
        label: `<i class="material-icons">supervisor_account</i> <span>CRM</span>`,
        escape: false,
        command: () => {
          this.openRightDialog(SalesSideDialogName.INVOICE_CRM);
        },
        disabled: isDisabled || !this.isCrmTabEnabled,
        visible: this.isCrmTabVisible,
      },
      {
        label: `<i class="material-icons">book</i> <span>Parked</span>`,
        escape: false,
        command: () => {
          this.openRightDialog(SalesSideDialogName.PARKED_INVOICES);
        },
        disabled: isDisabled,
      },
      {
        label: `<i class="material-icons">receipt</i> <span>Revisit</span>`,
        escape: false,
        command: () => {
          this.openRightDialog(SalesSideDialogName.REVISIT_INVOICES);
        },
        disabled: isDisabled || !this.isRevisitTabEnabled,
        visible: this.isRevisitTabVisible,
      },
      {
        label: `<i class="material-icons">contact_mail</i> <span>Bill To</span>`,
        escape: false,
        command: () => {
          this.openRightDialog(SalesSideDialogName.INVOICE_BILLTO);
        },
        disabled: isDisabled,
      },
      {
        label: `<i class="material-icons">redeem</i> <span>Vouchers</span>`,
        escape: false,
        command: () => {
          this.openRightDialog(SalesSideDialogName.VOUCHER_BALANCE);
        },
        disabled: isDisabled,
      },
    ];
  }

  restoreActiveOverlay(
    activeOverlay: OverlayModalName,
    activeOverlayExtra: any,
    activeOverlayParams: any,
    rightTabIndex: number
  ) {
    this.activeTabIndex = rightTabIndex;

    if ((Object.values(SideDialogNameMobile) as string[]).includes(activeOverlay))
      this.openRightDialog(<SideDialogNameMobile>activeOverlay, activeOverlayExtra);
    else this.docComponent.openFullSizeDialog(activeOverlay, activeOverlayExtra, activeOverlayParams);
  }

  // Ovewrite parent implementation, so we open component in dialog/sidebar instead as right-side overlay
  openRightDialog(dialogName: SideDialogNameMobile, extra = null) {
    super.openRightDialog(dialogName, extra);

    const dialogParams = {};
    let savePreviousOverlay = true;

    // When we close tender screen updates it's visibility in oder to renable UI buttons
    if (dialogName === SalesSideDialogName.TENDER_SCREEN)
      dialogParams["onHide"] = () => {
        this.saleInvoiceStateService.anounceTenderScreenVisibility(false);
      };

    if (SalesSideDialogName.RETURN_ITEM_SEARCH_FORM) savePreviousOverlay = false;

    this.docComponent.openFullSizeDialog(dialogName, extra, dialogParams, savePreviousOverlay);
  }

  get isTablet() {
    return !WebHelpers.isMobileScreen();
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
