/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import * as _ from "lodash";
import { UntypedFormGroup, ValidationErrors, AbstractControl, Validators, UntypedFormArray } from "@angular/forms";
import { CommonValidators } from "../shared/validators/CommonValidators";

type MatchModeBase = "equals" | "contains" | "ne" | "in" | "notIn" | "between" | "gt" | "gte" | "lt" | "lte";
/** allow any of the above comparators suffixed with '-col' to allow the value to be the name of another column for comparison */
type MatchMode = MatchModeBase | `${MatchModeBase}-col`;
/** For type `T`, pass the main entity that you are requesting from `getRows()` */
export type FilterRows<T> = Partial<
  Record<
    keyof T | `${string & keyof T}.${string}`,
    {
      matchMode: MatchMode;
      value: any;
      /** Set to `true` to pass a `null` value as a filter (otherwise the key/value pair will be removed from the filter) */
      allowNull?: boolean;
    }
  >
>;

export class FormDataHelpers {
  public static putCursorIntoDropFilter($event) {
    // Find filter text field by selector
    const filterInput: HTMLInputElement = $event.target.closest("p-dropdown").querySelector(".p-dropdown-filter");
    if (filterInput) {
      filterInput.focus();
    }

    return true;
  }

  public static EmptyFormArray(formArray: UntypedFormArray) {
    let i: number = formArray.length;
    for (i; i >= 0; i--) {
      formArray.removeAt(i);
    }
  }

  public static RemoveEmptyValues(object) {
    Object.entries(object).forEach(([k, v]) => {
      if (v && typeof v === "object") {
        FormDataHelpers.RemoveEmptyValues(v);
      }
      if ((v && typeof v === "object" && !Object.keys(v).length) || v === null || v === undefined) {
        if (Array.isArray(object)) {
          object.splice(parseInt(k), 1);
        } else {
          delete object[k];
        }
      }
    });
  }

  public static applyValidationToForm(formGroup: AbstractControl, validationRules: any) {
    Object.keys(validationRules).forEach((key) => {
      if (formGroup.get(key)) formGroup.get(key).setValidators(validationRules[key]);
    });
  }

  public static makeRegexpFromFormats(prefix, digitsLength, suffix) {
    const prefixSegment = _.escapeRegExp(prefix);
    const digitsSegment = `\\d{${digitsLength}}`;
    const sufixSegment = _.escapeRegExp(suffix);

    return new RegExp(`^${prefixSegment}${digitsSegment}${sufixSegment}$`);
  }

  public static clearModelIDs(data: any) {
    for (const i in data) {
      if (!data.hasOwnProperty(i)) {
        break;
      }
      const dataType = typeof data[i];
      if (i === "id" && dataType === "number") {
        data[i] = 0;
      } else if (dataType === "object") {
        FormDataHelpers.clearModelIDs(data[i]);
      }
    }

    return data;
  }

  public static deleteModelIDs(data: any) {
    for (const i in data) {
      if (!data.hasOwnProperty(i)) {
        break;
      }
      const dataType = typeof data[i];
      if (i === "id" && dataType === "number") {
        delete data[i];
      } else if (dataType === "object") {
        FormDataHelpers.deleteModelIDs(data[i]);
      }
    }

    return data;
  }

  public static getFormValidationErrors(form: UntypedFormGroup): any[] {
    const result: { control: string; error: string; value: any }[] = [];
    if (form.errors)
      // if it has global validation errors
      Object.keys(form.errors).forEach((keyError) => {
        result.push({
          control: "Form",
          error: keyError,
          value: form.errors[keyError],
        });
      });

    Object.keys(form.controls).forEach((key) => {
      const control = form.get(key);
      result.push(...FormDataHelpers.getControlValidationErrors(key, control));
    });

    return result;
  }

  public static getControlValidationErrors(fieldName: string, control: AbstractControl) {
    const result: { control: string; error: string; value: any }[] = [];
    const fnPushControlErrors = (control) => {
      const ctrlErrors: ValidationErrors = control.errors;
      if (ctrlErrors)
        Object.keys(ctrlErrors).forEach((keyError) => {
          result.push({
            control: fieldName,
            error: keyError,
            value: ctrlErrors[keyError],
          });
        });
    };
    if (control instanceof UntypedFormArray) {
      fnPushControlErrors(control);
      for (let i = 0; i < control.length; i++) {
        result.push(...this.getControlValidationErrors(fieldName, control.at(i)));
      }
    } else if (control instanceof UntypedFormGroup) {
      result.push(...this.getFormValidationErrors(control));
    } else {
      fnPushControlErrors(control);
    }

    return result;
  }

  public static InputFieldCaption(fieldCaption: string, ctrlField: AbstractControl, htmlVersion = true) {
    const fieldValidatorFn = ctrlField.validator;
    const labelRequired = htmlVersion ? ` <span class="validation-hint">(required)</span> ` : " (required) ";

    return (
      fieldCaption +
      (fieldValidatorFn && fieldValidatorFn(ctrlField) && fieldValidatorFn(ctrlField).required ? labelRequired : "")
    );
  }

  public static BuildFixedDigitsValidators(noDigits) {
    return [
      Validators.required,
      Validators.min(1),
      Validators.max(Math.pow(10, noDigits) - 1),
      CommonValidators.integer(),
    ];
  }

  // public static clearServerTimestamps(data: any){
  //   if (data.createdAt)
  //     delete data.createdAt;

  //   if (data.updatedAt)
  //     delete data.updatedAt;

  // }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
