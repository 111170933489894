/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, OnDestroy } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { RolesService } from "../../role/roles.service";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";
import { MessageService } from "primeng/api";
import { Subscription } from "rxjs";

@Component({
  selector: "taku-manage-roles",
  templateUrl: "./manage-roles.component.html",
  styleUrls: ["./manage-roles.component.scss"],
})
export class ManageRolesComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];

  constructor(
    private rolesService: RolesService,
    private alertService: AlertMessagesService,
    private messageService: MessageService
  ) {}

  ngOnInit() {}

  //The user clicked the sync button. This is meant to sync new access rights with all the roles.
  refreshClicked(event) {
    this.subsList.push(
      this.rolesService.syncRoles().subscribe(
        (res) => {
          //sync was a success
          this.messageService.add(this.alertService.getMessage("sync-roles-success"));

          //Now that the sync is done, we want to hide the progress bar.
          if (event.formListComponent) event.formListComponent._isFetchingData = false;
        },
        (err) => {
          //Hide the progress bar.
          if (event.formListComponent) event.formListComponent._isFetchingData = false;

          if (err instanceof Error) {
            // A client-side or network error occurred. Handle it accordingly.
            this.messageService.add(
              this.alertService.getErrorMessage(
                null,
                "Error when syncing roles",
                `An unexpected error has occurred. Please try again.<br>Details: ${err.message}`
              )
            );
          } else if (err instanceof HttpErrorResponse) {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            this.messageService.add(this.alertService.getErrorMessage(err, "Error when syncing roles", ""));
          }
        }
      )
    );
    // Check if the formListMain component passed the form list component in the event.
    if (event.formListComponent) {
      // Setting this property to true will display the progress bar in the form list.
      event.formListComponent._isFetchingData = true;
    }
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
