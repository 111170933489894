<taku-steps
  class="col-12"
  [model]="cashoutSteps"
  [(activeIndex)]="activeStep"
  [lastStepEnabled]="false"
  (onStepsCompleted)="onOpenDay($event)"
  [nextBtnEnabled]="formGroup.valid"
  [maxSteps]="cashoutSteps.length"
></taku-steps>

<div *ngIf="activeStep == 0" [formGroup]="formGroup" class="grid col-12 main-step" id="cashout-step-1">
  <div class="col-12 card" id="status-message-box">
    <div class="img-container">
      <img src="/assets/components/cashout/images/till.png" alt="Till is Closed" />
    </div>
    <strong>Register is Currently Closed</strong>
    <p> Please <em>open it</em> first in order to be able to sell your items. </p>
  </div>

  <main class="col-12 grid">
    <div *ngIf="openingDateCtrl.value" class="opening-time col-12 md:col-8 lg:col-5">
      <div class="col-3 field-label">Opening Time:</div>
      <div class="col-9 field">
        {{ openingDateCtrl.value | dateTimeFormat : openingTimeCtrl.value }}
        <!-- <taku-calendar-datetime [formGroup]="formGroup" fieldName="openingDateTime" fieldCaption="Opening Date and Time"></taku-calendar-datetime> -->
      </div>
    </div>
  </main>
</div>

<div *ngIf="activeStep == 1" [formGroup]="formGroup" class="grid col-12 main-step" id="cashout-step-2">
  <div class="grid col-12 headerWrapper">
    <header>
      <h2>Confirm Opening Cashout Float</h2>
      <div class="expected-total"
        >Expected Total Cash: {{ expectedCash | currency : _currencyISOCode : "symbol-narrow" }}</div
      >
    </header>

    <!-- <div class="grid col-12 lg:col-4    drawerBtn-wrapper">
      <button class="open-drawer-btn taku-button-small" pButton label="OPEN CASH DRAWER" (click)="onOpenCashDrawer($event)"></button>
    </div> -->
  </div>

  <main id="cashout_open_calculation_method">
    <div class="col-12 calculationMethod-wrapper">
      <div class="field-label">Calculation Method</div>
      <div>
        <p-radioButton
          name="openSaveMethod"
          [value]="CashoutSaveMethod.TOTAL"
          label="TOTAL"
          formControlName="openSaveMethod"
        ></p-radioButton>
        <p-radioButton
          id="cashout_open_calculation_method_details"
          name="openSaveMethod"
          [value]="CashoutSaveMethod.DETAIL"
          label="DETAILS"
          formControlName="openSaveMethod"
        ></p-radioButton>
      </div>
    </div>

    <div [hidden]="openSaveMethodCtrl.value !== CashoutSaveMethod.TOTAL" class="taku-openingfloat-table">
      <table class="table-float-total">
        <thead>
          <tr class="total-row">
            <th class="col-currency">Total Cash:</th>
            <th class="col-total">
              <taku-money
                [formGroup]="formGroup"
                fieldName="openTotalNextFloat"
                fieldCaption="Total Cash"
                [currencyCode]="_currencyISOCode"
                [currencyIsReadonly]="true"
                [autoSelect]="true"
              ></taku-money>
            </th>
          </tr>
        </thead>
      </table>
      <div class="mt-3 grid printing-container total-cashout-opening">
        <div class="grid col-fixed">
          <div class="col toggle-container">
            <taku-toggle
              [captionPosition]="ToggleCaptionPosition.LEFT"
              [showStateLabel]="false"
              [formGroup]="openSettingsForm"
              fieldName="print"
              fieldCaption="Print"
            >
            </taku-toggle>
          </div>
          <div class="col-fixed">
            <taku-dropdown [formGroup]="openSettingsForm" [options]="enum_printing_sizes" fieldName="printingSize">
            </taku-dropdown>
            <taku-checkbox [formGroup]="openSettingsForm" fieldName="extraCopy" fieldCaption="Extra Copy">
            </taku-checkbox>
          </div>
        </div>
        <!-- <div class="col-3 sm:col-2 "> -->
        <button
          pButton
          pRipple
          class="p-button-primary-dark col-fixed open-button col-3 sm:col-2"
          [disabled]="openSettingsForm.invalid || formGroup.invalid"
          (click)="onOpenDay($event)"
          label="OPEN"
        >
          <span class="btn-icon material-icons" style="width: 40%; text-align: right">receipt</span>
        </button>
        <!-- </div> -->
      </div>
    </div>

    <div
      [hidden]="openSaveMethodCtrl.value !== CashoutSaveMethod.DETAIL"
      class="col-12 taku-tablestyle-controllist taku-openingfloat-table"
    >
      <table class="col-12 table-float-details">
        <thead>
          <tr class="grid total-row">
            <th class="col-3 sm:col-6 col-currency">Total Cash:</th>
            <th class="col-9 sm:col-6 col-total">{{
              totalFloatCash() | currency : _currencyISOCode : "symbol-narrow"
            }}</th>
            <!-- <th class="col-1">&nbsp;</th> -->
          </tr>
          <tr class="grid captions-row">
            <th class="col-3 col-currency">Denomination</th>
            <th class="col-1 col-timessign"></th>
            <th class="col-3 col-count">Count</th>
            <th class="col-1 col-equalsign"></th>
            <th class="col-4 col-total">Total</th>
            <!-- <th class="col-1"></th> -->
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let openingFloatsForm of formGroup.get('cashoutOpeningFloats')['controls']; let i = index">
            <taku-cashout-opening-float
              class="col-12"
              [formGroup]="openingFloatsForm"
              [currencyISOCode]="_currencyISOCode"
            ></taku-cashout-opening-float>
          </tr>
        </tbody>
      </table>

      <div class="col-12 adjustments-notes-wrapper">
        <taku-input-textarea
          [formGroup]="formGroup"
          fieldName="openingNote"
          fieldCaption="Notes for Adjustments"
          [rows]="3"
        ></taku-input-textarea>
      </div>

      <div class="mt-3 grid printing-container detail-cashout-opening">
        <div class="grid col-fixed">
          <div class="col toggle-container">
            <taku-toggle
              [captionPosition]="ToggleCaptionPosition.LEFT"
              [showStateLabel]="false"
              [formGroup]="openSettingsForm"
              fieldName="print"
              fieldCaption="Print"
            >
            </taku-toggle>
          </div>
          <div class="col-fixed">
            <taku-dropdown [formGroup]="openSettingsForm" [options]="enum_printing_sizes" fieldName="printingSize">
            </taku-dropdown>
            <taku-checkbox [formGroup]="openSettingsForm" fieldName="extraCopy" fieldCaption="Extra Copy">
            </taku-checkbox>
          </div>
        </div>
        <!-- <div class="col-3 sm:col-2"> -->
        <button
          pButton
          pRipple
          class="p-button-primary-dark col-fixed open-button col-3 sm:col-2"
          [disabled]="openSettingsForm.invalid || formGroup.invalid"
          (click)="onOpenDay($event)"
          label="OPEN"
        >
          <span class="btn-icon material-icons" style="width: 40%; text-align: right">receipt</span>
        </button>
        <!-- </div> -->
      </div>
    </div>
  </main>
</div>

<div [hidden]="true">
  <ng-template #printPreviewHost></ng-template>
</div>
