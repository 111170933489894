import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, MessageService, SelectItem } from "primeng/api";
import { GenericFormComponent } from "src/app/forms/generic-form/generic-form.component";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";
import { DBService } from "src/app/shared/services/db.service";
import { Location } from "@angular/common";
import { AddressComponent } from "src/app/core/contact-accounts/address/address.component";
import { AddressLocationType } from "src/app/core/contact-accounts/address/address";
import { TakuPaymentGateway } from "../taku-payment-gateways-list/taku-payment-gateway";
import { TakuPaymentGatewayService } from "../taku-payment-gateways-list/taku-payment-gateway.service";
import { Observable, Subscription } from "rxjs";
import { TakuPayService } from "../taku-pay.service";
import { throwError } from "rxjs";
import { catchError, map, shareReplay, take, tap } from "rxjs/operators";
import { StoreTenderTypeList } from "../../../store-settings/store-tender-type-settings/TenderTypesSettings";
import { Tender_Type } from "../../../business-settings/tender-type/tender-type";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "taku-taku-pay-location-detail",
  templateUrl: "./taku-pay-location-detail.component.html",
  styleUrls: ["./taku-pay-location-detail.component.scss"],
})
export class TakuPayLocationDetailComponent extends GenericFormComponent implements OnInit {
  stores_lookup$: Observable<SelectItem[]>;
  takupay_accounts_lookup$: Observable<SelectItem[]>;
  getLookupMulti_cardTypes$: Observable<SelectItem[]>;
  getLookupTakuPaymentGateway$: Observable<SelectItem[]>;
  isAdmin = false;

  constructor(
    protected _router: Router,
    public fb: UntypedFormBuilder,
    public dbService: DBService,
    protected location: Location,
    public _route: ActivatedRoute,
    private takuPaymentGateService: TakuPaymentGatewayService,
    protected messageService: MessageService,
    protected alertMessagesService: AlertMessagesService,
    protected confirmationService: ConfirmationService,
    private appSettings: AppSettingsStorageService,
    private takuPayService: TakuPayService
  ) {
    super(
      _router,
      fb,
      dbService,
      location,
      _route,
      messageService,
      alertMessagesService,
      confirmationService,
      appSettings
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (!this._object.id) {
      this.subsList.push(
        this.appSettings.getBusinessDetails().subscribe((data) => {
          this._myForm.patchValue({
            storeId: this.appSettings.getStoreId(),
            address: {
              ...data.address,
              id: 0,
              addressEmail: { ...data.address.addressEmail, id: 0 },
              addressPhone: { ...data.address.addressPhone, id: 0 },
            },
          });
        })
      );
    }

    this.isAdmin = this.appSettings.isAdminLogin();
    this.stores_lookup$ = this.dbService.lookupSelectOptions("store", "storeName", { enableFieldName: "isActive" });

    const takuPayAccountFilter = {
      isActive: { matchMode: "equals", value: true },
      externalAccountId: { matchMode: "ne", value: "" },
    };
    this.takupay_accounts_lookup$ = this.dbService.lookupSelectOptions("takuPaymentAccount", "name", {
      lookupFilter: JSON.stringify(takuPayAccountFilter),
      enableFieldName: "isActive",
    });

    this.getLookupMulti_cardTypes$ = this.appSettings.getStoreSettings("storeTenderTypeList").pipe(
      map((storeTenderTypeList: StoreTenderTypeList) =>
        storeTenderTypeList.storeTenderTypes
          .filter((tender) => tender.tenderType.isIntegrated === true)
          .map((tender) => ({ label: tender.tenderType.description, value: tender.tenderType }))
      ),
      shareReplay(1)
    );

    this._myForm.get("storeId").markAsDirty();
  }

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    const tmpModel = new TakuPaymentGateway();
    const tmpForm = fb.group(tmpModel);
    tmpForm.setControl("address", AddressComponent.init(fb, AddressLocationType.commercial));
    return tmpForm;
  }

  initForm() {
    this._model = "takuPaymentGateway";
    this._object = new TakuPaymentGateway();
    this._myForm = TakuPayLocationDetailComponent.init(this.fb);
  }

  initValidation() {
    this._validation = {
      storeId: [Validators.required],
      takuPaymentAccountId: [Validators.required],
      tenderTypes: [Validators.required],
    };
  }

  get selectedTakuPaymentAccountCtrl() {
    console.log(this._myForm.get("takuPaymentAccountId"));
    return this._myForm.get("takuPaymentAccountId");
  }

  onSave() {
    this.subsList.push(
      this.onSubmit(false).subscribe((any) => {
        if (this._object.id > 0) {
          if (!this._object.locationId) {
            this.createTakuPayLocation(this._object.id);
          } else {
            this.alertMessage.getMessage("taku-payment-gateway-failure");
          }
        } else {
          this.alertMessage.getMessage("taku-payment-gateway-failure");
        }
      })
    );
  }

  onDelete() {
    if (this._object.id > 0 && this._object.locationId) {
      this.confirmationService.confirm({
        message: "Are you sure that you want to delete this record?",
        icon: "ui-icon-delete",
        rejectButtonStyleClass: "p-button-link",
        accept: () => {
          const filter = {
            takuPaymentGatewayId: { value: this._object.id, matchMode: "equals" },
          };
          this.dbService
            .getRows("takuPaymentTerminal", JSON.stringify(filter), 0, 1)
            .pipe(
              shareReplay(1),
              map((res: any) => !(res.rows && res.rows.length))
            )
            .subscribe((data: boolean) => {
              if (!data) {
                this.messageService.add(this.alertMessagesService.getMessage("taku-payment-gateway-delete-failure"));
                return;
              } else {
                this.removeTakuPayLocation(this._object.id);
              }
            });
        },
      });
    } else {
      super.onDelete();
    }
  }

  createTakuPayLocation(id: number) {
    this.takuPayService
      .createTakuPayLocation(id)
      .pipe(
        catchError((errorResponse) => {
          this.processServerError(errorResponse, false);
          return throwError(errorResponse);
        }),
        tap((result) => {
          if (result) {
            this._object = result.data;
            this.resetForm();
            this.messageService.add({ severity: "success", summary: "Service Message", detail: result.message });
          }
        })
      )
      .subscribe(() => {});
  }

  removeTakuPayLocation(id: number) {
    this.takuPayService
      .removeTakuPayLocation(id)
      .pipe(
        catchError((errorResponse) => {
          this.processServerError(errorResponse, false);
          return throwError(errorResponse);
        }),
        tap((result) => {
          if (result) {
            this.messageService.add({ severity: "success", summary: "Service Message", detail: result.message });
            this.dbService.deleteRow(this._model, this._object.id).subscribe(
              (data) => {
                this.messageService.add(this.alertMessage.getMessage("delete-success"));
                if (this._isDialog) {
                  this.changedForm.emit("Deleted");
                } else {
                  this.goBack();
                }
              },
              (error: HttpErrorResponse) => {
                this.messageService.add(this.alertMessage.getErrorMessage(error));
              },
              () => {}
            );
          }
        })
      )
      .subscribe(() => {});
  }
}
