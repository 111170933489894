<ng-template [ngIf]="displayMode === NestedFormDisplayMode.EDITABLE_FORM">
  <td class="stockName-col">
    {{ stockCtrl.value?.stockName }}
  </td>

  <!-- <td class="avail-col">
    <taku-input [disabled]="disableForm" [formGroup]="_myForm" fieldName="qtyAvail" [autoSelect]="true"></taku-input>
  </td> -->

  <td class="onHand-col">
    <taku-input [disabled]="disableForm" [formGroup]="_myForm" fieldName="qtyOH" [autoSelect]="true"></taku-input>
  </td>

  <td class="committedQty-col">
    <span>{{ committedQtyCtrl?.value }}</span>
    <taku-inventory-stock-details-overlay
      [inventoryStockDetails]="committedQtyDetails"
      [qtyDetailsType]="qtyDetailsType.Committed"
    ></taku-inventory-stock-details-overlay>
  </td>

  <td class="onOrderQty-col">
    {{ onOrderQtyCtrl?.value }}
    <taku-inventory-stock-details-overlay
      [inventoryStockDetails]="onOrderQtyDetails"
      [qtyDetailsType]="qtyDetailsType.OnOrdered"
    ></taku-inventory-stock-details-overlay>
  </td>

  <td class="minStock-col" *ngIf="showMinStock">
    <taku-input [disabled]="disableForm" [formGroup]="_myForm" fieldName="minStock" [autoSelect]="true"></taku-input>
  </td>

  <td class="maxStock-col" *ngIf="showMaxStock">
    <taku-input [disabled]="disableForm" [formGroup]="_myForm" fieldName="maxStock" [autoSelect]="true"></taku-input>
  </td>

  <td class="aisle-col">
    <taku-dropdown
      [formGroup]="_myForm"
      [options]="aisles"
      [fieldName]="'aisleId'"
      [disabled]="disableForm"
      appendTo="body"
    ></taku-dropdown>
  </td>
  <td class="shelf-col">
    <taku-dropdown
      [formGroup]="_myForm"
      [options]="shelves"
      [fieldName]="'shelfId'"
      [disabled]="disableForm"
      appendTo="body"
    ></taku-dropdown>
  </td>
</ng-template>

<ng-template [ngIf]="displayMode === NestedFormDisplayMode.TABULAR_DATA">
  <td class="stockName-col">
    <span class="p-column-title">Stock:</span>
    {{ stockCtrl.value?.stockName }}
  </td>

  <!-- <td class="avail-col">
    <span class="p-column-title">Avail Qty:</span>
    {{ qtyAvailCtrl?.value }}
  </td> -->

  <td class="onHand-col">
    <span class="p-column-title">OH Qty:</span>
    {{ qtyOHCtrl?.value }}
  </td>

  <td class="committedQty-col">
    <span class="p-column-title">Committed Qty:</span>
    {{ committedQtyCtrl?.value }}
  </td>

  <td class="onOrder-col">
    <span class="p-column-title">On Order Qty:</span>
    {{ onOrderQtyCtrl?.value }}
  </td>

  <td class="minStock-col">
    <span class="p-column-title">Min Stock Qty:</span>
    {{ minStockCtrl?.value }}
  </td>

  <td class="maxStock-col">
    <span class="p-column-title">Max Stock Qty:</span>
    {{ maxStockCtrl?.value }}
  </td>
</ng-template>
