<taku-personal-account
  *ngIf="accountType === 'Personal'"
  [_isDialog]="true"
  [_object]="account || undefined"
  (dialogClosed)="closeDialogPressed()"
></taku-personal-account>
<taku-commercial-account
  *ngIf="accountType === 'Commercial'"
  [_isDialog]="true"
  [_object]="account || undefined"
  (dialogClosed)="closeDialogPressed()"
></taku-commercial-account>
