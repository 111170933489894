<p-toolbar styleClass="my-toolbar">
  <div class="toolbar-grid-container">
    <div class="p-toolbar-group-left">
      <button
        id="close_btn"
        pButton
        pRipple
        type="button"
        class="header-button p-button-rounded"
        (click)="onCancel()"
        icon="pi pi-times"
      ></button>
      <h1 *ngIf="toolbarTitle">{{ toolbarTitle }}</h1>
    </div>
    <div class="p-toolbar-group-right">
      <button
        id="save_button"
        pButton
        pRipple
        type="button"
        class="header-button p-button-rounded"
        (click)="onSave()"
        [disabled]="pristine || !valid"
        icon="pi pi-save"
        [ngClass]="{ 'p-button-ternary-custom': !pristine && valid }"
        [loading]="isSaving"
      ></button>
      <button
        pButton
        pRipple
        type="button"
        class="header-button p-button-rounded p-button-icon-only"
        (click)="onRevert()"
        [disabled]="pristine"
        icon="_"
        ><i class="material-icons"> undo </i></button
      >
      <!-- <button pButton type="button" class="header-button" (click)="onNew()" [disabled]="formGroup.pristine || !formGroup.valid" icon="pi pi-plus"></button> -->
      <button
        *ngIf="showDeleteBtn"
        pButton
        pRipple
        type="button"
        class="header-button p-button-rounded p-button-icon-only"
        (click)="onDelete()"
        [disabled]="!pristine || !valid"
        icon="_"
      >
        <i class="material-icons">delete</i>
      </button>
      <!-- <button pButton type="button"  (click)="onCancel()" icon="pi pi-times" class="close-button"></button> -->
    </div>
  </div>
</p-toolbar>
