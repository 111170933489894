<taku-form-header
  [formGroup]="_myForm"
  (backClicked)="goBack()"
  (saveClicked)="onSave()"
  (revertClicked)="onRevert()"
  [toolbarTitle]="pageTitle"
  iconStyleClass="takuicon-gift"
  [showSecondaryBtns]="false"
></taku-form-header>
<div [formGroup]="_myForm">
  <div class="tabpanel-main col-12 card tabview-nopad">
    <div class="invoice-preview-wrapper">
      <h3>{{ _pageTitlePrefix }} Preview</h3>
      <p-card class="grid col-12 invoice-preview">
        <!-- <img src="/assets/layout/images/settings/preview-tape-invoice.png" class="image-placeholder" /> -->
        <taku-gift-receipt-tape-preview
          *ngIf="receiptDemoData"
          [saleDocData]="receiptDemoData.saledoc"
          [storeInfo]="_object.store"
          [builderSettings]="_myForm.value"
          [policies]="receiptDemoData.policies"
          [businessDetail]="receiptDemoData.businessDetail"
        ></taku-gift-receipt-tape-preview>
      </p-card>
    </div>

    <div class="settings-columns">
      <h3>Builder Options</h3>
      <div class="grid">
        <p-card class="col-12 lg:col-6">
          <div class="col-12 inputctrl-wrapper">
            <!-- <label>Font Size: </label> -->
            <taku-dropdown
              [formGroup]="_myForm"
              fieldName="headerFontSize"
              fieldCaption="Header Font Size"
              [options]="lookup_font_size"
            ></taku-dropdown>
          </div>

          <div class="col-12 inputctrl-wrapper">
            <taku-toggle
              [formGroup]="_myForm"
              fieldName="printLogo"
              fieldCaption="Logo"
              enabledStateLabel="ON"
              disabledStateLabel="OFF"
            ></taku-toggle>
          </div>

          <div class="col-12 inputctrl-wrapper">
            <taku-toggle
              [formGroup]="_myForm"
              fieldName="printStoreName"
              fieldCaption="Store Name"
              enabledStateLabel="ON"
              disabledStateLabel="OFF"
            ></taku-toggle>
          </div>

          <div class="col-12 inputctrl-wrapper">
            <taku-toggle
              [formGroup]="_myForm"
              fieldName="printWebsite"
              fieldCaption="Website"
              enabledStateLabel="ON"
              disabledStateLabel="OFF"
            ></taku-toggle>
          </div>

          <div class="col-12 inputctrl-wrapper">
            <taku-toggle
              [formGroup]="_myForm"
              fieldName="printEmail"
              fieldCaption="Email"
              enabledStateLabel="ON"
              disabledStateLabel="OFF"
            ></taku-toggle>
          </div>

          <div class="col-12 inputctrl-wrapper">
            <taku-toggle
              [formGroup]="_myForm"
              fieldName="printPhoneNo"
              fieldCaption="Phone Number"
              enabledStateLabel="ON"
              disabledStateLabel="OFF"
            ></taku-toggle>
          </div>

          <div class="col-12 inputctrl-wrapper">
            <taku-toggle
              [formGroup]="_myForm"
              fieldName="printAddress"
              fieldCaption="Address"
              enabledStateLabel="ON"
              disabledStateLabel="OFF"
            ></taku-toggle>
          </div>

          <div class="col-12 inputctrl-wrapper">
            <taku-toggle
              [formGroup]="_myForm"
              fieldName="printDocumentNo"
              fieldCaption="Doc #"
              enabledStateLabel="ON"
              disabledStateLabel="OFF"
            ></taku-toggle>
          </div>
        </p-card>

        <p-card class="col-12 lg:col-6">
          <div class="col-12 inputctrl-wrapper">
            <taku-toggle
              [formGroup]="_myForm"
              fieldName="printDate"
              fieldCaption="Date"
              enabledStateLabel="ON"
              disabledStateLabel="OFF"
            ></taku-toggle>
          </div>

          <div class="col-12 inputctrl-wrapper">
            <i class="pi pi-lock locked-feature"></i>
            <taku-toggle
              [formGroup]="_myForm"
              fieldName="printBusinessHours"
              fieldCaption="Show Business Hours"
              enabledStateLabel="ON"
              disabledStateLabel="OFF"
              [disabled]="true"
            ></taku-toggle>
          </div>

          <div class="col-12 inputctrl-wrapper">
            <taku-toggle
              [formGroup]="_myForm"
              fieldName="printReturnPolicy"
              fieldCaption="Show Return Policy"
              enabledStateLabel="ON"
              disabledStateLabel="OFF"
            ></taku-toggle>
          </div>

          <div class="col-12 inputctrl-wrapper">
            <taku-toggle
              [formGroup]="_myForm"
              fieldName="printSalePolicy"
              fieldCaption="Show Sales Policy"
              enabledStateLabel="ON"
              disabledStateLabel="OFF"
            ></taku-toggle>
          </div>

          <div class="col-12 inputctrl-wrapper">
            <taku-toggle
              [formGroup]="_myForm"
              fieldName="printShippingPolicy"
              fieldCaption="Show Shipping Policy"
              enabledStateLabel="ON"
              disabledStateLabel="OFF"
            ></taku-toggle>
          </div>

          <div class="col-12 inputctrl-wrapper">
            <i class="pi pi-lock locked-feature"></i>
            <taku-toggle
              [formGroup]="_myForm"
              fieldName="printAccountInfo"
              fieldCaption="Show Account Information"
              enabledStateLabel="ON"
              disabledStateLabel="OFF"
              [disabled]="true"
            ></taku-toggle>
          </div>

          <div class="col-12 inputctrl-wrapper">
            <i class="pi pi-lock locked-feature"></i>
            <taku-toggle
              [formGroup]="_myForm"
              fieldName="printSocialIcons"
              fieldCaption="Show Social Icons"
              enabledStateLabel="ON"
              disabledStateLabel="OFF"
              [disabled]="true"
            ></taku-toggle>
          </div>

          <div class="col-12 inputctrl-wrapper">
            <i class="pi pi-lock locked-feature"></i>
            <taku-toggle
              [formGroup]="_myForm"
              fieldName="printPoweredByTalulabs"
              fieldCaption="Show 'Powered by TAKU'"
              enabledStateLabel="ON"
              disabledStateLabel="OFF"
              [disabled]="true"
            ></taku-toggle>
          </div>
        </p-card>
      </div>
    </div>
  </div>
  <!-- </p-panel> -->
</div>
