<h3>Barcodes</h3>
<p-table
  id="main-table"
  [scrollable]="true"
  tableStyle="table-layout:fixed"
  responsiveLayout="stack"
  breakpoint="640px"
  [value]="_formArray['controls']"
  styleClass="p-datatable-striped"
>
  <ng-template pTemplate="header">
    <tr>
      <th class="barcodeFormat-col">Format</th>
      <th class="barcodeType-col">Type</th>
      <th class="barcode-col">Barcode #</th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-formGroup>
    <tr>
      <taku-inventory-barcode [formGroup]="formGroup" [displayMode]="_displayMode"></taku-inventory-barcode>
    </tr>
  </ng-template>
</p-table>
