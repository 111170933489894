<div id="saleTransactions-wrapper">
  <div class="sales-line-header" [ngClass]="{ 'has-scrollbars': hasScrollbars }">
    <div class="sku">Product</div>
    <div class="qty">Qty</div>
    <div class="line-discount">{{ _isMobile ? "Unit Disc." : "Unit Discount" }}</div>
    <div class="sale-price-col">Unit Cost</div>
    <div class="total-price">Total</div>
  </div>
  <div class="sale-lines" #saleLines>
    <div *ngFor="let inventoryDocLine of inventoryDoc.inventoryDocLines" class="sale-line">
      <div class="line-desc">
        {{ inventoryDocLine.docLine?.inventory?.description1 }}
      </div>
      <div class="line-body">
        <div class="sku">
          SKU:
          <a
            (click)="
              skuStockPanel.toggle($event); populateStockAvailabilityDialog(inventoryDocLine.docLine?.inventory?.id)
            "
          >
            {{ inventoryDocLine.docLine?.inventory?.sku }}
          </a>
        </div>

        <p-overlayPanel #skuStockPanel [showCloseIcon]="true" appendTo="body" styleClass="formlist-panelOverlay">
          <h3>Stock Levels</h3>
          <p-table
            id="main-table"
            [scrollable]="true"
            [scrollHeight]="tableScrollHeight"
            tableStyle="table-layout:fixed"
            responsiveLayout="stack"
            breakpoint="640px"
            [value]="_inventoryStockFormArray['controls']"
            styleClass="p-datatable-striped"
          >
            <ng-template pTemplate="header">
              <tr>
                <th class="stockName-col">Stock</th>
                <th class="onHand-col">OH Qty</th>
                <th class="committedQty-col">Commited Qty</th>
                <th class="onOrder-col">On Order Qty</th>
                <th class="minStock-col">Min Stock Qty</th>
                <th class="maxStock-col">Max Stock Qty</th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-formGroup>
              <tr>
                <taku-inventory-stock [formGroup]="formGroup" [displayMode]="_displayMode"></taku-inventory-stock>
              </tr>
            </ng-template>
          </p-table>
        </p-overlayPanel>
        <div class="qty">{{ inventoryDocLine.qty | formatNegativeNumber }}</div>
        <div class="line-discount">
          <ng-template [ngIf]="inventoryDocLine.allocatedDiscountAmount > 0">
            {{
              convertedUnitDiscount(inventoryDocLine)
                | currency : inventoryDoc?.stock?.zone?.defaultCurrencyIsoCode : "symbol-narrow" : "1.2"
            }}
            <span *ngIf="inventoryDoc.discountType === DiscountType.Percentage"
              ><br />({{ inventoryDoc.discountAmount | number : "1.2" }}%)</span
            >
          </ng-template>
        </div>
        <div class="sale-price-col">
          <div class="sale-price">
            {{
              convertedUnitCost(inventoryDocLine)
                | currency : inventoryDoc?.stock?.zone?.defaultCurrencyIsoCode : "symbol-narrow" : "1.2"
            }}
          </div>
          <div *ngIf="inventoryDocLine.allocatedDiscountAmount != 0" class="original-price">
            {{
              inventoryDocLine.unitCost * inventoryDoc.doc.currencyConversionRate
                | currency : inventoryDoc?.stock?.zone?.defaultCurrencyIsoCode : "symbol-narrow" : "1.2"
            }}
          </div>
        </div>
        <div class="total-price">
          <span class="total-amount">
            {{
              inventoryDocLine.subTotal - inventoryDocLine.convertedTotalDiscountAmount
                | currency : inventoryDoc?.stock?.zone?.defaultCurrencyIsoCode : "symbol-narrow" : "1.2"
                | formatNegativeNumber
            }}
          </span>
          <span class="tax-acronyms">{{ InvoiceHelpers.GetCombinedTaxAcronyms(inventoryDocLine) }}</span>
        </div>
      </div>
      <div class="saleTransactions-inventoryDocLine-details">
        <span
          ><i class="material-icons">bookmark_border</i>
          {{ inventoryVariantsStr(inventoryDocLine.docLine?.inventory) }}</span
        >
      </div>
      <div *ngIf="inventoryDocLine.docLine?.note" class="line-notes">Note: {{ inventoryDocLine.docLine?.note }}</div>
    </div>
  </div>

  <div class="summary-wrapper">
    <div *ngIf="inventoryDoc.doc.comment" class="invoice-notes">
      <h3>Invoice Notes</h3>
      <p>
        {{ inventoryDoc.doc.comment }}
      </p>
    </div>
    <div class="summary">
      <table cellspacing="0">
        <tr>
          <td class="label-col">Sub-total:</td>
          <td class="amount-col">
            {{
              inventoryDocSubTotal - inventoryDocGeneralDiscount
                | currency : inventoryDoc?.stock?.zone?.defaultCurrencyIsoCode : "symbol-narrow" : "1.2"
                | formatNegativeNumber
            }}
          </td>
        </tr>

        <tr>
          <td class="label-col">- Discount:</td>
          <td class="amount-col">
            {{
              0
                | currency : inventoryDoc?.stock?.zone?.defaultCurrencyIsoCode : "symbol-narrow" : "1.2"
                | formatNegativeNumber
            }}
          </td>
        </tr>

        <tr>
          <td class="label-col">
            <a
              *ngIf="isTaxConflict()"
              href="javascript://"
              (click)="taxesPanel.toggle($event)"
              [style.color]="getTaxFontColor()"
              class="pi pi-info-circle"
            >
            </a>
            <a href="javascript://" (click)="taxesPanel.toggle($event)" style="float: right">
              <ins [style.color]="getTaxFontColor()">+ Taxes:</ins>
            </a>
            <p-overlayPanel #taxesPanel [showCloseIcon]="false" appendTo="body">
              <div class="col-12">
                <table *ngIf="inventoryDoc.groupedTaxLines?.length">
                  <ng-template ngFor [ngForOf]="inventoryDoc.groupedTaxLines" let-tax>
                    <tr class="tax-info">
                      <td class="tax-desc">
                        <span>{{ tax?.saleTaxRule?.tax?.shortDesc }}</span>
                      </td>
                      <td class="tax-amount">
                        <span>{{
                          tax?.total
                            | currency : inventoryDoc?.stock?.zone?.defaultCurrencyIsoCode : "symbol-narrow" : "1.2"
                            | formatNegativeNumber
                        }}</span>
                      </td>
                    </tr>
                    <tr class="separator">
                      <td colspan="3">
                        <hr />
                      </td>
                    </tr>
                  </ng-template>
                </table>
                <div *ngIf="!inventoryDoc?.groupedTaxLines?.length" class="no-taxes"
                  >There are no taxes for this sale yet.
                </div>
              </div>
            </p-overlayPanel>
          </td>
          <td class="amount-col">
            {{
              inventoryDoc.totalTax
                | currency : inventoryDoc?.stock?.zone?.defaultCurrencyIsoCode : "symbol-narrow" : "1.2"
                | formatNegativeNumber
            }}
          </td>
        </tr>

        <tr>
          <td class="label-col">+ Shipping:</td>
          <td class="amount-col">
            {{
              inventoryDoc.shipperChargeAmount * inventoryDoc?.doc?.currencyConversionRate
                | currency : inventoryDoc?.stock?.zone?.defaultCurrencyIsoCode : "symbol-narrow" : "1.2"
                | formatNegativeNumber
            }}
          </td>
        </tr>

        <tr class="total-line">
          <td class="label-col">TOTAL:</td>
          <td class="amount-col">
            {{
              inventoryDocTotal
                | currency : inventoryDoc?.stock?.zone?.defaultCurrencyIsoCode : "symbol-narrow" : "1.2"
                | formatNegativeNumber
            }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>

<p-dialog
  [(visible)]="_activeDialog"
  [contentStyle]="{ maxHeight: '100%' }"
  header="Confirmation"
  styleClass="fixedsize-modal"
  [blockScroll]="true"
  [modal]="true"
>
  <p>
    {{ _dialogContent }}
  </p>
  <ng-template pTemplate="footer">
    <p-button
      icon="pi pi-check"
      (click)="_activeDialog = false"
      label="Ok"
      styleClass="p-button-text"
    ></p-button> </ng-template
></p-dialog>
