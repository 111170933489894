import { HttpClient } from "@angular/common/http";
import { EventEmitter, Inject, Injectable, LOCALE_ID, NgZone } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { merge } from "lodash";
import { Observable } from "rxjs";
import { InventoryBarcodeService } from "src/app/core/inventory/inventory-barcode/InventoryBarcode.service";
import { InventoryStockService } from "src/app/core/inventory/inventory-stock/InventoryStock.service";
import { InventoryStockLevelsArrayComponent } from "src/app/core/inventory/inventory-stocklevels-array/inventory-stocklevels-array.component";
import { Inventory, InventoryStock } from "src/app/core/inventory/inventory/inventory";
import { InventoryService } from "src/app/core/inventory/inventory/inventory.service";
import { BackendGMCService } from "src/app/core/settings/integration-settings/google-shopping/BackendGMCService";
import { GoogleContentAPIService } from "src/app/core/settings/integration-settings/google-shopping/GoogleContentAPIService";
import {
  ArrayDatatypeOptions,
  Col,
  DataType,
  ExecuteEventDatatypeOptions,
  ExecuteEvent_DisplayMode,
  FilterType,
} from "src/app/form-list/form-list/form-list";
import { FormListModel } from "src/app/form-list/form-list/form-list-model.interface";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { CurrencyFormatterService } from "src/app/shared/services/currency-formatter.service";

@Injectable() // This service should never be used as global singleton due to each consumer of the service needing their own separate selectedStockId. Do not change to providedIn: root
export class InventorySupplierListService extends InventoryService implements FormListModel {
  navigateInventory = new EventEmitter<Inventory>();
  addToRecieptFormListEvent = new EventEmitter<Inventory>();

  selectedStockId?: number;

  override minStock(currentStock: InventoryStock, allStocks: InventoryStock[]): number | string {
    const stockId = this.selectedStockId;
    if (stockId) {
      const stock = allStocks.find((row) => row.stockId === stockId);
      if (stock) {
        return stock.minStock;
      }
    }

    return "View";
  }

  override maxStock(currentStock: InventoryStock, allStocks: InventoryStock[]): number | string {
    const stockId = this.selectedStockId;
    if (stockId) {
      const stock = allStocks.find((row) => row.stockId === stockId);
      if (stock) {
        return stock.maxStock;
      }
    }

    return "View";
  }

  constructor(
    protected currencyService: CurrencyFormatterService,
    protected http: HttpClient,
    protected googleContentAPIService: GoogleContentAPIService,
    @Inject(LOCALE_ID) protected defaultLocale,
    protected authService: AuthService,
    protected appSettings: AppSettingsStorageService,
    protected zone: NgZone,
    public backendGMCService: BackendGMCService,
    protected fb: UntypedFormBuilder,
    public barcodeService: InventoryBarcodeService,
    public stockService: InventoryStockService,
    protected appSettingsStorageService: AppSettingsStorageService
  ) {
    super(
      currencyService,
      http,
      googleContentAPIService,
      defaultLocale,
      authService,
      appSettings,
      zone,
      backendGMCService,
      barcodeService,
      stockService,
      appSettingsStorageService
    );
  }

  getRows(
    _model: string,
    _filter?: string,
    _offset?: number,
    _limit?: number,
    _sortField = "module",
    _sortOrder = 1,
    extraParams = {},
    _orFilter?
  ): Observable<any> {
    return super.getRows(
      _model,
      _filter,
      _offset,
      _limit,
      _sortField,
      _sortOrder,
      merge(extraParams, { fields: ["inventoryStocks.onOrderQty"] }),
      _orFilter
    );
  }

  getinventorySKUCaption(rowData: Inventory, _service: InventorySupplierListService) {
    return rowData ? rowData.sku : null;
  }

  _disableCloning = true;
  override getFormListColumns(): Col[] {
    const cols = super.getFormListColumns(true, this.selectedStockId);
    const isEnabledCol = cols.find((row) => row.field === "isEnabled");
    isEnabledCol.visible = false;
    isEnabledCol.frozen = false;
    const skuCol = cols.find((row) => row.field === "sku");
    skuCol.frozen = false;
    // skuCol.dataType = DataType.execute_event;
    // skuCol.dataTypeOptions = [
    //   new ExecuteEventDatatypeOptions({
    //     displayMode: ExecuteEvent_DisplayMode.LINK,
    //     event: this.navigateInventory,
    //     label: this.getinventorySKUCaption,
    //     enabledOnEditMode: false,
    //     styleClass: "",
    //   }),
    // ];
    skuCol.colWidth = 110;
    cols.find((row) => row.field === "uOfMeasureId").visible = false;
    cols.find((row) => row.field === "msrpPrice").visible = false;

    const parentInventoryIdIndex = cols.findIndex((row) => row.field === "parentInventoryId");
    if (parentInventoryIdIndex !== -1) {
      cols.splice(parentInventoryIdIndex, 1);
    }
    const defaultQtyAvailIndex = cols.findIndex((row) => row.field === "defaultQtyAvail");
    if (defaultQtyAvailIndex !== -1) {
      cols.splice(defaultQtyAvailIndex, 1);
    }

    const addToPoCol = {
      field: "addToPo",
      header: "Add to PO",
      headerHTML: "Add to<br>PO",
      visible: true,
      readonly: false,
      frozen: true,
      dataType: DataType.execute_event,
      dataOptions: [],
      dataTypeOptions: [
        new ExecuteEventDatatypeOptions({
          displayMode: ExecuteEvent_DisplayMode.BUTTON,
          event: this.addToRecieptFormListEvent,
          icon: "pi pi-plus",
          enabledOnEditMode: false,
          styleClass: "",
        }),
      ],
      filterType: FilterType.none,
      colWidth: 80,
      isNotSortable: true,
    };

    cols.splice(1, 0, addToPoCol);

    // replace min/max stock qty columns, so we can make the value based on the selected stock
    const inventoryStocksMinIdx = cols.findIndex((row) => row.field === "inventoryStocksMin");
    const inventoryStocksMaxIdx = cols.findIndex((row) => row.field === "inventoryStocksMax");

    cols[inventoryStocksMinIdx] = {
      field: "inventoryStocksMin",
      header: "Min Stock Qty",
      headerHTML: "Min<br>Stock Qty",
      visible: true,
      readonly: true,
      frozen: false,
      dataType: DataType.array,
      dataTypeOptions: new ArrayDatatypeOptions({
        component: InventoryStockLevelsArrayComponent,
        displayField: this.minStock.bind(this),
        showSummary: false,
      }),
      dataOptions: [],
      filterType: FilterType.none,
      colWidth: 145,
      sortFields: ["inventoryStocksMin.minStock"],
      isNotSortable: true,
    };

    cols[inventoryStocksMaxIdx] = {
      field: "inventoryStocksMax",
      header: "Max Stock Qty",
      headerHTML: "Max<br>Stock Qty",
      visible: true,
      readonly: true,
      frozen: false,
      dataType: DataType.array,
      dataTypeOptions: new ArrayDatatypeOptions({
        component: InventoryStockLevelsArrayComponent,
        displayField: this.maxStock.bind(this),
        showSummary: false,
      }),
      dataOptions: [],
      filterType: FilterType.none,
      colWidth: 145,
      sortFields: ["inventoryStocksMax.maxStock"],
      isNotSortable: true,
    };

    return cols;
  }
}
