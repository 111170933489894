/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import * as moment from "moment";
import { Observable } from "rxjs";
import { DBService } from "../shared/services/db.service";
import { SalesPerHourStats, SalesSummaryStats, TopInventoryStats } from "./dashboard.component";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "../shared/services/auth.service";
import { AppSettingsStorageService } from "../shared/app-settings-storage.service";
import { SelectItem } from "primeng/api";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class DashboardDBService extends DBService {
  constructor(
    protected http: HttpClient,
    protected authService: AuthService,
    private appSettings: AppSettingsStorageService
  ) {
    super(http, authService);
  }

  lookup_stations(storeId = -1): Observable<SelectItem[]> {
    const storeFilter = {
      storeId: { matchMode: "equals", value: storeId == -1 ? this.appSettings.getStoreId() : storeId },
    };

    return this.lookupSelectOptions("station", "stationName", {
      lookupFilter: JSON.stringify(storeFilter),
      enableFieldName: "isActive",
      emptyRowCaption: "All Stations",
      emptyRowValue: -1,
    });
  }

  lookup_stores(): Observable<SelectItem[]> {
    //get all active stores that are part of an active zone
    const activeFilter = {
      isActive: { matchMode: "equals", value: true },
      "zone.isActive": { matchMode: "equals", value: true },
    };
    return this.lookupSelectOptions("store", "storeName", {
      lookupFilter: JSON.stringify(activeFilter),
      enableFieldName: "isActive",
      emptyRowCaption: "All Stores",
      emptyRowValue: -1,
    });
  }

  addDefaultValueToLookup(items: SelectItem[], defaultMessage: string) {
    items.unshift({
      label: defaultMessage,
      value: 0,
    });

    if (items.length >= 2 && items[1].label == "") {
      //if the second item is the default empty option. We want the empty option to be first.
      //so we swap the first and second items.
      const temp = items[1];
      items[1] = items[0];
      items[0] = temp;
    }
    return items;
  }

  // Station ID of 0 means ALL Stores
  getSalesPerHour({
    storeId,
    stationId = 0,
    dateFrom,
    dateTo,
  }: {
    storeId: number;
    stationId?: number;
    dateFrom: Date;
    dateTo: Date;
  }): Observable<SalesPerHourStats[]> {
    const params = {
      dateFrom: this.prepareDate(dateFrom),
      dateTo: this.prepareDate(dateTo),
      stationId,
    };

    return this._getRequest(this.webUrl + "repStoreSaleHourly/" + storeId, params);
  }

  getSalesSummary({
    storeId,
    stationId = 0,
    dateFrom,
    dateTo,
  }: {
    storeId: number;
    stationId?: number;
    dateFrom: Date;
    dateTo: Date;
  }): Observable<SalesSummaryStats[]> {
    const params = {
      dateFrom: this.prepareDate(dateFrom),
      dateTo: this.prepareDate(dateTo),
      stationId,
    };

    return this._getRequest(this.webUrl + "repStoreSaleSummary/" + storeId, params);
  }

  getTopInventory({
    storeId,
    stationId = 0,
    dateFrom,
    dateTo,
    limit = 10,
  }: {
    storeId: number;
    stationId?: number;
    dateFrom: Date;
    dateTo: Date;
    limit?: number;
  }): Observable<TopInventoryStats[]> {
    const params = {
      dateFrom: this.prepareDate(dateFrom),
      dateTo: this.prepareDate(dateTo),
      stationId,
      limit,
    };

    return this._getRequest(this.webUrl + "repStoreSaleTopInventory/" + storeId, params);
  }

  protected prepareDate(date: Date) {
    return moment(date).format("YYYY-MM-DD");
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
