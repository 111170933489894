/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { AfterViewInit, Component, ViewChild, Optional, Inject, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import {
  NestedFormComponent,
  FORM_GROUP_TOKEN,
  DISPLAY_MODE_TOKEN,
  NestedFormDisplayMode,
} from "../../../forms/nested-form/nested-form.component";
import { BarcodeValidators } from "../../../shared/validators/BarcodeValidators";
import { TakuInputComponent } from "../../../taku-ui/taku-input/taku-input.component";
import { InventoryBarcode } from "./inventory-barcode";
import { InventoryBarcodeService } from "./InventoryBarcode.service";
import { ACTION_TYPE } from "src/app/shared/components/action-button/action-type.enum";

@Component({
  selector: "taku-inventory-barcode",
  templateUrl: "./inventory-barcode.component.html",
  styleUrls: ["./inventory-barcode.component.scss"],
})
export class InventoryBarcodeComponent extends NestedFormComponent implements AfterViewInit {
  @ViewChild("barcodeNumber", { static: true }) barcodeNumberComponent: TakuInputComponent;

  @Input() disableForm?: boolean = false;
  @Output() copyToClipboard: EventEmitter<string> = new EventEmitter();

  // Define enum variables
  enum_barcode_type = this.dbService.get_enum_barcode_type(); // this.dbService.enumSelectOptions(Stock_type, 'Stock Type');
  enum_barcode_format = this.dbService.get_enum_barcode_format();
  public ACTION_TYPE: typeof ACTION_TYPE = ACTION_TYPE;

  constructor(
    public dbService: InventoryBarcodeService,
    public fb: UntypedFormBuilder,
    @Optional() @Inject(FORM_GROUP_TOKEN) formGroup?,
    @Optional() @Inject(DISPLAY_MODE_TOKEN) displayMode?
  ) {
    super(dbService, fb, formGroup, displayMode);
  }

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    const inventoryBarcode_Group = fb.group(new InventoryBarcode());
    return inventoryBarcode_Group;
  }

  static setArray(fb: UntypedFormBuilder, inventoryBarcodes: InventoryBarcode[]) {
    const formArray = fb.array([]);
    inventoryBarcodes.map((inventoryBarcode) => {
      const temp = fb.group(inventoryBarcode);
      formArray.push(temp);
    });
    return formArray;
  }

  ngAfterViewInit() {
    // Auto focus barcode number field when adding new component (barcode line)
    if (!this.barcodeTypeCtrl.value) this.barcodeNumberComponent.focusAndSelectInput();
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.barcodeCtrl) {
      this.barcodeCtrl.updateValueAndValidity({ emitEvent: false });
      if (this.barcodeCtrl.invalid) this.barcodeCtrl.markAsDirty();
    }

    this.subsList.push(
      this._myForm.valueChanges.subscribe((newFormat) => {
        if (this.barcodeCtrl) {
          this.barcodeCtrl.updateValueAndValidity({ emitEvent: false });
          if (this.barcodeCtrl.invalid) this.barcodeCtrl.markAsDirty();
        }
      })
    );
  }

  get barcodeFormatCtrl() {
    return this._myForm.get("barcodeFormat");
  }

  get barcodeCtrl() {
    return this._myForm.get("barcode");
  }

  get barcodeTypeCtrl() {
    return this._myForm.get("barcodeType");
  }

  initValidation() {
    this._validation = {
      barcode: [Validators.required, BarcodeValidators.validateCodeAgainstFormat(this.barcodeFormatCtrl)],
      barcodeType: [Validators.required],
      // barcodeFormat: [Validators.required],
    };
  }

  copyBarcodeToClipboard() {
    this.copyToClipboard.emit(this.barcodeCtrl.value);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
