<div class="bottom-left" [formGroup]="saleForm">
  <div class="col-12 lg:col-7 footer-left card">
    <div class="grid">
      <div class="col-12 invoice-note" formGroupName="doc">
        <taku-input-textarea
          id="invoiceNote"
          #invoiceNote
          [formGroup]="saleForm.get('doc')"
          fieldName="comment"
          fieldCaption="Invoice Note"
          [rows]="3"
        ></taku-input-textarea>
      </div>

      <div class="p-fluid parkSales-wrapper" [ngClass]="allowReturns ? 'col-4' : 'col-8'">
        <button
          pButton
          type="button"
          class="p-button-primary-dark"
          label="Park"
          (click)="onParkBtnPressed()"
          [disabled]="orderEditLock || isTenderScreenOpened"
        ></button>
      </div>

      <div *ngIf="allowReturns" class="col-4 p-fluid returnItem-wrapper">
        <button
          pButton
          type="button"
          class="p-button-primary-dark"
          label="Exchange"
          (click)="itemReturnedPressed()"
          [disabled]="orderEditLock || isTenderScreenOpened || isReturnEnabled || preorderSelected"
        ></button>
      </div>

      <div class="col-4 total-items-wrapper">
        <span
          >Total Items: <strong>{{ _countTotalItems | formatNegativeNumber }}</strong></span
        >
      </div>
    </div>
  </div>

  <div class="col-12 lg:col-5 footer-right">
    <div class="card">
      <div class="col-12 invoice-summary">
        <div class="line">
          <div class="col1">
            <b>Sub-Total</b>
          </div>
          <div class="col2">
            <b>{{ invoiceSubTotal | currency : currencyIsoCode : "symbol-narrow" : "1.2" | formatNegativeNumber }}</b>
          </div>
        </div>

        <div class="line general-discount-line" [ngClass]="{ 'is-disabled': transactionAreaDisabled }">
          <div class="col1" (click)="toggleOverlay('discount', $event)">
            <ins>- Discount</ins
            ><span
              class="hidden"
              *ngIf="saleForm.get('discountType').value === 'Percentage' && saleForm.get('discountAmount').value"
              >( {{ saleForm.get("discountAmount").value | number : _decimalFormatting }}% )</span
            >
          </div>
          <div class="col2">{{ invoiceGeneralDiscount | currency : currencyIsoCode : "symbol-narrow" : "1.2" }}</div>

          <!-- <div class="overlay-arrowPointer card" [hidden]="!_overlaysVisibility.discount"></div> -->
          <div class="overlay-container card grid" [hidden]="!_overlaysVisibility.discount">
            <p-button
              type="button"
              class="p-button-rounded p-button p-button-icon-only close-btn"
              icon="pi pi-times"
              (onClick)="closeOverlay('discount')"
            ></p-button>

            <div class="col-12 general_disccount-label">
              <h4>Bulk Line Discount</h4>
            </div>
            <div class="p-fluid col-12 sm:col-4 chg-discount-type" [style.marginTop]="'3px'">
              <p-selectButton
                [options]="enumBulkDiscountType"
                [formGroup]="bulkSaleForm"
                formControlName="discountType"
              ></p-selectButton>
            </div>
            <div class="col-12 sm:col-8 chg-discount">
              <div class="p-inputgroup chg-amount">
                <span *ngIf="saleForm.get('discountType').value === 'Percentage'" class="p-inputgroup-addon chg-border"
                  >%</span
                >
                <span *ngIf="saleForm.get('discountType').value === 'Fixed'" class="p-inputgroup-addon chg-border"
                  >$</span
                >
                <taku-input
                  #bulkDiscountAmount
                  (click)="onBulkDiscAmountClicked()"
                  [formGroup]="bulkSaleForm"
                  fieldName="discountAmount"
                  [fieldCaption]="bulkSaleForm.get('discountAmount').errors ? '' : 'Amount'"
                  (onBlur)="setZeroIfEmpty('discountAmount')"
                ></taku-input>
                <span class="p-inputgroup-addon pt-0">
                  <p-button
                    icon="takuicon-calculator"
                    styleClass="squared-corners"
                    (onClick)="numpadBulkDiscount.open()"
                  >
                  </p-button>
                  <taku-numpad
                    #numpadBulkDiscount
                    padTitle="Enter Discount"
                    [numpadType]="bulkSaleForm.get('discountType').value === 'Percentage' ? 'percentage' : 'monetary'"
                    [initialQty]="bulkSaleForm.get('discountAmount').value"
                    [maximumQty]="maxDiscountAmount"
                    (submitQty)="onNumpadBulkDiscountAmount($event)"
                  ></taku-numpad>
                </span>
              </div>
            </div>
            <div class="col-12 grid">
              <span class="col-9 setting-note"
                >Existing line discounts will be overwritten up to the max % for that SKU.
              </span>
              <button class="col-3" pButton label="Apply" (click)="onApplyClick()"> </button>
            </div>

            <div class="line" style="height: 1px"></div>
            <div class="col-12 general_disccount-label">
              <h4>Discount on Sub-Total</h4>
            </div>
            <div class="p-fluid col-12 sm:col-4 chg-discount-type" [style.marginTop]="'3px'">
              <p-selectButton [options]="enumDiscountType" formControlName="discountType"></p-selectButton>
            </div>
            <div class="col-12 sm:col-8 chg-discount">
              <div class="p-inputgroup chg-amount">
                <span *ngIf="saleForm.get('discountType').value === 'Percentage'" class="p-inputgroup-addon chg-border"
                  >%</span
                >
                <span *ngIf="saleForm.get('discountType').value === 'Fixed'" class="p-inputgroup-addon chg-border"
                  >$</span
                >
                <taku-input
                  #generalDiscountAmount
                  (click)="onDiscAmountClicked()"
                  [formGroup]="saleForm"
                  fieldName="discountAmount"
                  [fieldCaption]="saleForm.get('discountAmount').errors ? '' : 'Amount'"
                  (onBlur)="setZeroIfEmpty('discountAmount')"
                ></taku-input>
                <span class="p-inputgroup-addon pt-0">
                  <p-button icon="takuicon-calculator" styleClass="squared-corners" (onClick)="numpadDiscount.open()">
                  </p-button>
                  <taku-numpad
                    #numpadDiscount
                    padTitle="Enter Discount"
                    [numpadType]="saleForm.get('discountType').value === 'Percentage' ? 'percentage' : 'monetary'"
                    [initialQty]="saleForm.get('discountAmount').value"
                    [maximumQty]="maxDiscountAmount"
                    (submitQty)="onNumpadDiscountAmount($event)"
                  ></taku-numpad>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="line general-taxes-line">
          <div class="col1" (click)="toggleOverlay('taxes', $event)">
            <ins>+ Taxes</ins>
          </div>
          <div class="col2">
            {{ invoiceTotalTax | currency : currencyIsoCode : "symbol-narrow" : "1.2" | formatNegativeNumber }}</div
          >

          <div class="overlay-container card" [hidden]="!_overlaysVisibility.taxes">
            <p-button
              type="button"
              class="p-button-rounded p-button-icon-only p-button close-btn"
              icon="pi pi-times"
              (onClick)="closeOverlay('taxes')"
            ></p-button>
            <div class="col-12 salesTaxes-overlay">
              <table *ngIf="groupedTaxes.length">
                <ng-template ngFor [ngForOf]="groupedTaxes" let-tax>
                  <tr class="tax-info">
                    <td class="tax-desc">
                      <span>{{
                        tax?.saleTaxRule?.ruleName ? tax?.saleTaxRule?.ruleName : tax?.saleTaxRule?.tax?.shortDesc
                      }}</span>
                    </td>
                    <td class="tax-amount">
                      <span>{{
                        tax?.total | currency : currencyIsoCode : "symbol-narrow" : "1.2" | formatNegativeNumber
                      }}</span>
                    </td>
                  </tr>

                  <tr class="separator">
                    <td colspan="2">
                      <hr />
                    </td>
                  </tr>
                </ng-template>
              </table>

              <div *ngIf="!groupedTaxes.length" class="no-taxes">There are no taxes for this sale yet.</div>
            </div>
          </div>
        </div>

        <div class="line shipping-summary-line" [ngClass]="{ 'is-disabled': transactionAreaDisabled }">
          <div class="col1" (click)="onDeliveryLinkPressed()">
            <ins>+ Shipping <ng-template [ngIf]="invoiceSubTotal < 0">Refund</ng-template></ins>
            <span *ngIf="_shipperName">({{ _shipperName }})</span>
          </div>

          <div class="col2">
            {{
              invoiceTotalShipping | currency : currencyIsoCode : "symbol-narrow" : "1.2" | formatNegativeNumber
            }}</div
          >
        </div>
      </div>

      <div class="p-fluid invoice-total">
        <button
          id="pay_button"
          pButton
          type="button"
          [ngClass]="{
            'p-button-salesregister-custom': invoiceTotal >= 0,
            'p-button-returns-custom': invoiceTotal < 0,
            'tender-warning': showTenderWarningStyles
          }"
          (click)="onPayBtnPressed()"
          [disabled]="!isPaymentEnabled || shouldDisablePay"
        >
          <div class="line">
            <div class="col1">
              {{ invoiceTotal >= 0 ? "Pay" : "Return" }}
              <span *ngIf="showTenderWarningStyles" class="tender-icon">$</span>
              <span class="keyboard-shortcut">(F9)</span>:
            </div>
            <div class="col2" fittext [activateOnResize]="true" [maxFontSize]="45" fontUnit="pt">
              <span>{{
                invoiceTotal | currency : currencyIsoCode : "symbol-narrow" : "1.2" | formatNegativeNumber
              }}</span>
            </div>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>
