<div class="grid">
  <div class="col-fixed" style="width: 2em">
    <i class="material-icons picture">{{ imageIcon }}</i>
  </div>
  <div class="col text">
    <h3 [innerHTML]="headlineHTML"></h3>
    <p *ngIf="infoline" class="flex flex-wrap justify-content-between" [innerHTML]="infolineHTML"></p>
    <p class="flex flex-wrap justify-content-between" [innerHTML]="sublineHTML"></p>
  </div>
</div>
