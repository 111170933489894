/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "taku-searchresult-line",
  templateUrl: "./taku-searchresult-line.component.html",
  styleUrls: ["./taku-searchresult-line.component.scss"],
})
export class TakuSearchResultLineComponent implements OnInit {
  @Input() headline;
  @Input() infoline;
  @Input() subline;
  @Input() imageIcon;
  @Input() searchQuery: string;

  headlineHTML: string;
  infolineHTML: string;
  sublineHTML: string;
  htmlTagName = "i";

  constructor() {}

  ngOnInit() {
    // Highlight matching text in search results
    if (this.searchQuery) {
      this.headlineHTML = this.formatMatchingText(this.headline);
      //this.sublineHTML = this.formatMatchingText(this.subline);
    } else {
      this.headlineHTML = this.headline;
    }
    this.infolineHTML = this.infoline;
    this.sublineHTML = this.subline;
  }

  formatMatchingText(text: string) {
    const searchStr = this.searchQuery.toLowerCase();
    let startIndex = 0;
    let currMatchPos;
    const matchingPositions = [];
    // Look for the search query in text and take notes of the position where it was found
    while ((currMatchPos = text.toLowerCase().indexOf(searchStr, startIndex)) > -1) {
      matchingPositions.push(currMatchPos);
      startIndex = currMatchPos + searchStr.length;
    }
    let formattedText = "";
    let lastEndPos = 0;
    // Wrap matching text into a HTML tag in order to apply custom styles
    for (const currStartPos of matchingPositions) {
      const noMatchingFrag = text.substring(lastEndPos, currStartPos);
      lastEndPos = currStartPos + searchStr.length;
      const matchingFrag = text.substring(currStartPos, lastEndPos);
      formattedText += `${noMatchingFrag}<${this.htmlTagName}>${matchingFrag}</${this.htmlTagName}>`;
    }

    if (lastEndPos < text.length) {
      formattedText += text.substring(lastEndPos);
    }

    return formattedText;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
