<div [formGroup]="_myForm">
  <div class="grid">
    <div class="col-12 lg:col-4 xl:col-3 drop-container">
      <taku-dropdown
        [formGroup]="_myForm"
        [options]="enum_store_email_type"
        [fieldName]="'emailType'"
        [fieldCaption]="'Email Type'"
      >
      </taku-dropdown>
    </div>
    <div class="col-12 lg:col-8 xl:col-9">
      <taku-input [formGroup]="_myForm" [fieldName]="'email'" [fieldCaption]="'Email'"></taku-input>
    </div>
  </div>
</div>
