/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable, Inject, LOCALE_ID } from "@angular/core";
import { LocalDataService } from "src/app/shared/services/local-data.service";
import { CurrencyPipe } from "@angular/common";
import { SaleDocReport } from "./sale-doc-report";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { HttpClient } from "@angular/common/http";
import * as moment from "moment";
@Injectable({
  providedIn: "root",
})
export class SaleReportLocalDataService extends LocalDataService {
  constructor(
    protected http: HttpClient,
    protected authService: AuthService,
    private appSettingsService: AppSettingsStorageService,
    @Inject(LOCALE_ID) private localeId: string
  ) {
    super(http, authService);
  }

  getSalesDailyReport(
    storeId: number,
    stationId: number,
    dateFrom: Date | string,
    dateTo: Date | string,
    accountId = 0,
    userId = 0
  ) {
    const timeTo = moment(dateTo).format("HH:mm:ss");
    const timeFrom = moment(dateFrom).format("HH:mm:ss");
    dateTo = moment(dateTo).format("YYYY-MM-DD");
    dateFrom = moment(dateFrom).format("YYYY-MM-DD");
    const queryParams = {
      dateFrom,
      dateTo,
      timeFrom,
      timeTo,
      accountId,
      userId,
      stationId,
    };

    return this._getRequest<SaleDocReport[]>(this.webUrl + "repStoreSaleDaily/" + storeId, queryParams);
  }

  afterFetch(object, model: string) {
    const currency = this.appSettingsService.getZone().defaultCurrencyIsoCode;
    const currencyFormatter = new CurrencyPipe(this.localeId);

    Object.keys(object)
      .filter((key) => !Object.keys(new SaleDocReport()).includes(key))
      .forEach((key) => {
        object[key] = currencyFormatter.transform(object[key], currency, "code", "1.2");
      });

    object.totalAmountCalculated = currencyFormatter.transform(object.totalAmountCalculated, currency, "code", "1.2");
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
