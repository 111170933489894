import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import _ from "lodash";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { Subscription } from "rxjs";
import { Inventory } from "src/app/core/inventory/inventory/inventory";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { DBService } from "src/app/shared/services/db.service";
import { WebHelpers } from "src/app/utility/WebHelpers";
import { ApiListResponse } from "src/app/utility/types";

type VariantName = string;
type VariantValue = string;
type Variant = {
  name: string;
  value: string;
  disabled?: boolean;
};

type Item = {
  id: number;
  sku: string;
  variants: Variant[];
};

type InventoryOptions = {
  id: number;
  optionName: string;
  values: any[];
};

@Component({
  selector: "taku-self-checkout-item-details",
  templateUrl: "./self-checkout-item-details.component.html",
  styleUrls: ["./self-checkout-item-details.component.scss"],
})
export class SelfCheckoutItemDetailsComponent implements OnInit, OnDestroy {
  saleForm: UntypedFormGroup;
  inventoryOptions: InventoryOptions[] = [];
  selectedOpt: Variant[] = [];
  selectedInventory: Inventory;
  parentInventory: Inventory;
  isSearching = false;
  supportsScroll = true;
  isParentItem = false;

  // properties for desktop
  originalQty: number;
  updatedQty: number;

  // properties for mobile
  qtyToAdd = 1;
  isAddToCart = false;
  isAddOnItem = false;
  showSalePrice = false;
  doneButtonCaption;

  responsiveOptions: any[] = [
    {
      // this is the default value until a smaller breakpoint is reached
      breakpoint: "99999px",
      numVisible: 3,
    },
    {
      breakpoint: "768px",
      numVisible: 2,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
    },
  ];

  variantMapExistenceSet = new Map<VariantName, Set<VariantValue>>();
  items: Item[] = [];
  subsList: Subscription[] = [];

  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    protected dbService: DBService,
    private appSettingsService: AppSettingsStorageService
  ) {
    if (this.config && this.config.data) {
      if (this.config.data._saleForm) {
        this.saleForm = this.config.data._saleForm;
        this.originalQty = this.qtyCtrl.value;
        this.updatedQty = this.originalQty;
        // console.log(this.saleForm);
      }
      if (this.config.data._isAddToCart) {
        this.isAddToCart = this.config.data._isAddToCart;
      }
      if (this.config.data._isAddOnItem) {
        this.isAddOnItem = config.data._isAddOnItem;
        if (this.updatedQty == 0) {
          this.updatedQty = 1;
        }
      }
      this.showSalePrice = this.config.data._showSalePrice == undefined ? true : this.config.data._showSalePrice;
      this.doneButtonCaption = this.config.data._doneButtonCaption;
    }
  }

  get areAllOptionsSelected() {
    return Object.keys(this.selectedOpt).length == this.parentInventory?.inventoryVariantSettings.length;
  }

  getAllMatchedVariants = (allItems: Item[], selectedOptions: Variant[]): Map<VariantName, Set<VariantValue>> => {
    const map = new Map<VariantName, Set<VariantValue>>();

    // Get only items/variants that match selected variants (if any)
    const matchingItems =
      selectedOptions.length < 1
        ? allItems
        : allItems.filter((item) =>
            item.variants.some((itemVariant) =>
              selectedOptions.some((opt) => opt.name === itemVariant.name && opt.value === itemVariant.value)
            )
          );

    // For all items that match selected variants, collect sets of existing values for each variantOption
    matchingItems.forEach((item) => {
      item.variants.forEach((variant) => {
        if (!map.has(variant.name)) {
          // Create set if it doesn't yet exist for variantOption
          map.set(variant.name, new Set());
        }
        map.get(variant.name).add(variant.value);
      });
    });
    return map;
  };

  calculateSelectionIntersection = (allItems: Item[], selectedOptions: Variant[]): Map<string, Set<string>> => {
    const allSelectionVariants = new Map<VariantName, Map<VariantName, Set<VariantValue>>>();
    selectedOptions.forEach((selectedOption) => {
      // Calculate which other variants values are used with this one.
      const selectionVariants = this.getAllMatchedVariants(allItems, [selectedOption]);
      selectionVariants.delete(selectedOption.name);
      allSelectionVariants.set(selectedOption.name, selectionVariants);
    });
    const selectedIntersection = new Map<VariantName, Set<VariantValue>>();

    this.variantMapExistenceSet.forEach((setOfValues, optionName) => {
      let intersection = Array.from(setOfValues);
      for (const setMap of allSelectionVariants.values()) {
        if (setMap.has(optionName)) {
          intersection = _.intersection(intersection, Array.from(setMap.get(optionName)));
        }
      }
      selectedIntersection.set(optionName, new Set(intersection));
    });

    return selectedIntersection;
  };

  lookup_inventoryOptionValue(inventoryOption: any) {
    return [...inventoryOption.values];
  }

  updateInventoryOptions() {
    const selectedIntersection = this.calculateSelectionIntersection(this.items, this.selectedOpt);

    this.inventoryOptions.forEach((inventoryOption) => {
      inventoryOption.values.forEach((inventoryOptionValue) => {
        if (!selectedIntersection.get(inventoryOptionValue.name).has(inventoryOptionValue.value))
          inventoryOptionValue.disabled = true;
        else inventoryOptionValue.disabled = false;
      });
    });
  }

  isSelectedItemAvailable() {
    return (
      this.selectedOpt.every((item) => item.disabled === false) &&
      this.selectedOpt.length === this.inventoryOptions.length
    );
  }

  optionChange() {
    this.updateInventoryOptions();

    if (this.isSelectedItemAvailable()) {
      this.isSearching = true;

      if (this.items.length > 0) {
        const _selectedInventories = this.items.filter((item) => {
          return (
            item.variants
              .map((item) => item.value)
              .sort()
              .join(",") ==
            this.selectedOpt
              .map((o) => o.value)
              .sort()
              .join(",")
          );
        });
        if (_selectedInventories.length > 0) {
          const defaultStockId = this.appSettingsService.getStore().stockId;
          const zoneId = this.appSettingsService.getZoneId();
          const _extraQueryParams = { stockId: defaultStockId, zoneId: zoneId };
          this.subsList.push(
            this.dbService.getRow("inventory", _selectedInventories[0].id, _extraQueryParams).subscribe((result) => {
              this.selectedInventory = result;
              this.isSearching = false;
            })
          );
        } else {
          this.selectedInventory = null;
          this.isSearching = false;
        }
      } else {
        this.selectedInventory = null;
        this.isSearching = false;
      }
    } else {
      this.selectedInventory = null;
    }
  }

  ngOnInit(): void {
    this.isSearching = true;
    let _filter = {};
    this.selectedOpt = [];

    if (this.associatedInventory.isVariantParent) {
      this.isParentItem = true;
      _filter = {
        parentInventoryId: { value: this.associatedInventory.id, matchMode: "equals" },
      };
      this.parentInventory = this.associatedInventory;
    } else if (this.associatedInventory.parentInventoryId) {
      this.isSearching = true;
      _filter = {
        parentInventoryId: { value: this.associatedInventory.parentInventoryId, matchMode: "equals" },
      };
      this.subsList.push(
        this.dbService.getRow("inventory", this.associatedInventory.parentInventoryId).subscribe((result) => {
          this.parentInventory = result;
          this.associatedInventory.parentInventory = this.parentInventory;
          this.selectedInventory = this.associatedInventory;
          this.associatedInventory.inventoryVariants.map((_inventoryVariant) => {
            this.selectedOpt.push(_inventoryVariant.inventoryOptionsSettingValue);
          });
        })
      );
    } else {
      this.selectedInventory = this.associatedInventory;
    }
    this.subsList.push(
      this.dbService
        .getRows<ApiListResponse<Inventory>>("inventory", JSON.stringify(_filter), 0, -1, null, null, {
          excludes: [
            "category",
            "taxInventoryCategory",
            "brand",
            "inventoryBarcode",
            "inventoryUsageDetail",
            "inventorySaleChannel",
            "inventoryZone",
            "inventoryStock",
            "inventorySupplier",
            "inventoryStore",
            "inventoryImage",
            "uOfMeasure",
            "inventoryGoogleShopping",
            "inventoryVariantSetting",
          ].join(","),
        })
        .subscribe((result) => {
          this.items = result.rows.map((row) => {
            const variants = row.inventoryVariants.map((inventoryVariant) => {
              return {
                name: inventoryVariant.inventoryOptionsSetting.optionName,
                value: inventoryVariant.inventoryOptionsSettingValue.optionValue,
              };
            });
            return { id: row.id, sku: row.sku, variants: variants };
          });
          this.variantMapExistenceSet = this.getAllMatchedVariants(this.items, []);
          this.isSearching = false;
          this.initializeInventoryOptions();
          this.updateInventoryOptions();
        })
    );

    this.supportsScroll = WebHelpers.supportsScrollMethod();
  }

  private initializeInventoryOptions() {
    this.parentInventory.inventoryVariantSettings.forEach((inventoryVariantSetting) => {
      const arr = inventoryVariantSetting.inventoryOptionsSettingValues.map((row) => {
        let isDisabled = false;
        if (this.items.length == 0 && !this.selectedInventory)
          //no item exist
          isDisabled = true;

        return {
          value: row.optionValue,
          name: inventoryVariantSetting.inventoryOptionsSetting.optionName,
          disabled: isDisabled,
        };
      });

      this.inventoryOptions.push({
        id: inventoryVariantSetting.inventoryOptionsSettingId,
        optionName: inventoryVariantSetting.inventoryOptionsSetting.optionName,
        values: arr,
      });
    });
  }

  ngOnDestroy(): void {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }

  onCancelPressed() {
    if (!this.isAddToCart) {
      // this.saleForm.patchValue({qty: this.originalQty});
      this.qtyCtrl.setValue(this.originalQty);
    }
    this.ref.close();
  }

  get disableRemoveBtn(): boolean {
    if (this.isAddOnItem) {
      return this.isAddToCart ? this.qtyToAdd === 0 : this.updatedQty === 0;
    } else {
      return this.isAddToCart ? this.qtyToAdd === 1 : this.updatedQty === 1;
    }
  }

  onDoneBtnPressed() {
    const _value = {
      description: this.selectedInventory.description1,
      docLine: {
        inventory: this.selectedInventory,
        inventoryId: this.selectedInventory.id,
      },
      sku: this.selectedInventory.sku,
      unitPrice: this.selectedInventory.standardPrice,
    };
    // this.saleForm.get('description').setValue(this.selectedInventory.description1);
    // this.saleForm.get('docLine.inventory').setValue(this.selectedInventory);
    // this.saleForm.get('docLine.inventoryId').setValue(this.selectedInventory.id);
    // this.saleForm.get('sku').setValue(this.selectedInventory.sku);
    // this.saleForm.get('unitPrice').setValue(this.selectedInventory.standardPrice);
    if (this.isAddToCart) {
      this.saleForm.patchValue(_value);
      this.ref.close({
        qtySelected: this.qtyToAdd,
        qtyToAdd: this.qtyToAdd - 1,
      });
    } else {
      // this.saleForm.patchValue({qty: this.updatedQty});
      // this.qtyCtrl.setValue(this.updatedQty);
      _value["qty"] = this.updatedQty;
      this.saleForm.patchValue(_value);
      this.ref.close(true);
    }
  }

  onRemoveQtyPressed() {
    // dialog called from add-on item
    if (this.isAddOnItem) {
      // let newQty = this.qtyCtrl.value - 1;
      // this.saleForm.patchValue({qty: newQty});
      if (this.updatedQty > 0) {
        this.updatedQty = this.updatedQty - 1;
      }
    }
    // dialog NOT called from add-on item
    else {
      if (!this.isAddToCart) {
        if (this.updatedQty > 1) {
          // let newQty = this.qtyCtrl.value - 1;
          // this.saleForm.patchValue({qty: newQty});
          this.updatedQty = this.updatedQty - 1;
        }
      } else {
        if (this.qtyToAdd > 1) {
          this.qtyToAdd -= 1;
        }
      }
    }
  }

  onAddQtyPressed() {
    if (!this.isAddToCart) {
      // let newQty = this.qtyCtrl.value + 1;
      // if (this.originalQty === 0) {

      // }
      this.updatedQty = this.updatedQty + 1;
      // this.saleForm.patchValue({qty: newQty});
    } else {
      this.qtyToAdd += 1;
    }
  }

  get associatedInventory() {
    return this.saleForm.get("docLine").get("inventory").value;
  }

  get InventoryImagePinnedToPictureGallery() {
    if (this.selectedInventory && this.selectedInventory.parentInventory) {
      return [
        ...this.selectedInventory.inventoryImages,
        ...this.selectedInventory.parentInventory.inventoryImages,
      ].find((row) => row.pinToPictureGallery == true);
    } else {
      return this.associatedInventory.inventoryImages.find((row) => row.pinToPictureGallery == true);
    }
  }

  get otherImages() {
    if (this.selectedInventory && this.selectedInventory.parentInventory) {
      return (
        [...this.selectedInventory.inventoryImages, ...this.selectedInventory.parentInventory.inventoryImages].filter(
          (row) => row.pinToPictureGallery != true
        ) || []
      );
    } else {
      return this.associatedInventory.inventoryImages.filter((row) => row.pinToPictureGallery != true) || [];
    }
  }

  get longDescription() {
    return this.selectedInventory?.longDescription || this.parentInventory?.longDescription;
  }

  get qtyCtrl() {
    return this.saleForm.get("qty");
  }
}
