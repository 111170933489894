<div class="formlist-header-btn-container">
  <button
    id="btn_add_list"
    *ngIf="!hideBtnsDisplay() && !hideAddLine"
    pButton
    pRipple
    type="button"
    (click)="onNewLine()"
    icon="_"
    class="header-button p-button-rounded p-button-icon-only"
    pTooltip="Add Row"
    tooltipPosition="top"
  >
    <i class="material-icons">playlist_add</i>
  </button>
  <button
    id="btn_add"
    aria-label="New"
    *ngIf="!hideBtnsDisplay() && !hideAdd"
    pButton
    pRipple
    type="button"
    (click)="onNew()"
    icon="pi pi-plus"
    class="header-button p-button-rounded"
    [pTooltip]="'New' + (itemDescription ? ' ' + itemDescription : '')"
    tooltipPosition="top"
  ></button>
  <button
    id="btn_delete"
    *ngIf="!hideBtnsDisplay() && !hideDelete"
    pButton
    pRipple
    type="button"
    (click)="onDelete()"
    icon="_"
    class="header-button p-button-rounded p-button-icon-only"
    pTooltip="Delete Rows"
    tooltipPosition="top"
  >
    <i class="material-icons">delete</i>
  </button>
  <button
    *ngIf="!hideMoreOptions"
    pButton
    pRipple
    type="button"
    class="header-button more-options-btn p-button-rounded"
    icon="pi pi-ellipsis-v"
    (click)="menu.toggle($event)"
    pTooltip="More Options"
    tooltipPosition="left"
  ></button>
</div>
<p-menu
  #menu
  popup="true"
  [appendTo]="'body'"
  [model]="hideBtnsDisplay() ? items_extended : items"
  [style]="{ width: '100px' }"
></p-menu>
