/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Location } from "@angular/common";
import { Component, OnInit, ViewChild, TemplateRef, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Col, TemplateDatatypeOptions } from "src/app/form-list/form-list/form-list";
import { GatewayTerminalsService } from "./gateway-terminals.service";
import {
  PaymentTerminal,
  RequestPairTerminal,
  ResponseCloseBatch,
  ResponsePairTerminal,
  ResponseUnpairTerminal,
} from "./payment-terminal";
import { RowsSavedEvent } from "src/app/form-list/form-list/form-list.component";
import { mergeMap, tap, catchError, shareReplay } from "rxjs/operators";
import { PaymentGateway, PaymentGatewayType, PaymentGatewayTypes } from "../payment-gateways/payment-gateway";
import { of, throwError, Subscription } from "rxjs";
import { MessageService } from "primeng/api";
import { HttpErrorResponse } from "@angular/common/http";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";
import { EconduitService } from "../econduit-terminal/econduit.service";
import { MonerisService } from "../moneris-terminal/moneris.service";

@Component({
  selector: "taku-gateway-terminals",
  templateUrl: "./gateway-terminals.component.html",
  styleUrls: ["./gateway-terminals.component.scss"],
  providers: [RequestPairTerminal],
})
export class GatewayTerminalsComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];

  @ViewChild("terminalPairingTpl", { static: true }) terminalPairingTpl: TemplateRef<any>;

  _formListFilter = {};
  _formListCols: Col[];
  _formListModel = "paymentTerminal";
  _formListDefaultRowValues: { paymentGatewayId: any };

  private pageTitleSuffix = "Terminals";
  _pageTitle = this.pageTitleSuffix;
  gatewayName = "";

  constructor(
    private locationService: Location,
    private route: ActivatedRoute,
    private dbSevice: GatewayTerminalsService,
    private messageService: MessageService,
    private alertMessagesService: AlertMessagesService,
    private econduitService: EconduitService,
    private monerisService: MonerisService
  ) {}

  get terminalRout() {
    switch (this.gatewayName) {
      case PaymentGatewayTypes["PayRoc"]:
        return "eConduit/paymentTerminal";
      case PaymentGatewayTypes.Moneris:
        return "Moneris/paymentTerminal";

      default:
        return "";
    }
    // return 'paymentTerminal/econduit';
  }

  ngOnInit() {
    this.subsList.push(
      this.route.params.subscribe((params) => {
        const paymentGatewayId = params.paymentGatewayId;
        this.gatewayName = params.gatewayName;
        const gatewayStoreId = params.gatewayStoreId;
        if (paymentGatewayId) {
          this._formListFilter["paymentGatewayId"] = {
            matchMode: "equals",
            value: paymentGatewayId,
          };
          this._formListDefaultRowValues = {
            paymentGatewayId,
          };
        }

        if (this.gatewayName) {
          this._pageTitle = `${this.gatewayName} ${this.pageTitleSuffix}`;
        }

        if (gatewayStoreId)
          this._formListCols = this.dbSevice.getFormListColumns(
            gatewayStoreId,
            new TemplateDatatypeOptions({
              templateRef: this.terminalPairingTpl,
            })
          );
      })
    );
  }

  onColPairBtnClicked(terminal: PaymentTerminal) {
    if (terminal.isPaired) {
      this.subsList.push(
        this.econduitService.unpairTerminal(terminal.id).subscribe({
          next: (response) => {
            const responseUnPairTerminal: ResponseUnpairTerminal = Object.assign({}, response);
            if (response && responseUnPairTerminal.Status === "Success") {
              terminal.isPaired = false;
              this.messageService.add(this.alertMessagesService.getMessage("econduit-unpair-terminal-success"));
            } else {
              this.messageService.add({
                summary: "Error",
                severity: "error",
                detail: `${responseUnPairTerminal.ErrorMessage}`,
                life: 6000,
              });
            }
          },
          error: (errorResponse) => {
            if (errorResponse instanceof HttpErrorResponse && errorResponse.status !== 500) {
              if (errorResponse.error.error && errorResponse.error.error.message) {
                const errorMsg = errorResponse.error.error.message as string;
                this.messageService.add(
                  this.alertMessagesService.getErrorMessage(
                    null,
                    "ERROR",
                    `${errorMsg}\nPlease make another VERIFICATION REQUEST.`
                  )
                );
              }
            } else {
              this.messageService.add(this.alertMessagesService.getErrorMessage(errorResponse));
            }
          },
        })
      );
    } else {
      this.subsList.push(
        this.econduitService.pairTerminal(terminal.id).subscribe({
          next: (response) => {
            const responsePairTerminal: ResponsePairTerminal = Object.assign({}, response);
            if (response && responsePairTerminal.Status === "Success") {
              terminal.isPaired = true;
              this.messageService.add(this.alertMessagesService.getMessage("econduit-pair-terminal-success"));
            } else {
              this.messageService.add({
                summary: "Error",
                severity: "error",
                detail: `${responsePairTerminal.ErrorMessage}`,
                life: 6000,
              });
            }
          },
          error: (errorResponse) => {
            if (errorResponse instanceof HttpErrorResponse && errorResponse.status !== 500) {
              if (errorResponse.error.error && errorResponse.error.error.message) {
                const errorMsg = errorResponse.error.error.message as string;
                this.messageService.add(
                  this.alertMessagesService.getErrorMessage(
                    null,
                    "ERROR",
                    `${errorMsg}\nPlease make another VERIFICATION REQUEST.`
                  )
                );
              }
            } else {
              this.messageService.add(this.alertMessagesService.getErrorMessage(errorResponse));
            }
          },
        })
      );
    }
  }

  onColSettleBtnClicked(terminal: PaymentTerminal) {
    if (terminal.isPaired) {
      let paymentService;
      switch (terminal.paymentGateway.paymentGatewayType) {
        case PaymentGatewayType.PAYROC:
          paymentService = this.econduitService;
          break;
        case PaymentGatewayType.MONERIS:
          paymentService = this.monerisService;
          break;
      }
      if (paymentService) {
        paymentService.closeBatch(terminal.id).subscribe({
          next: (response) => {
            const responseCloseBatch: ResponseCloseBatch = Object.assign({}, response);
            if (
              response &&
              responseCloseBatch.RefID !== null &&
              responseCloseBatch.MessageCredit === this.econduitService.sMessageCloseBatchApproved
            ) {
              this.messageService.add(this.alertMessagesService.getMessage("econduit-close-batch-success"));
            } else {
              this.messageService.add({
                summary: "Error",
                severity: "error",
                detail: `${responseCloseBatch.MessageCredit}`,
                life: 6000,
              });
            }
          },
          error: (errorResponse) => {
            if (errorResponse instanceof HttpErrorResponse && errorResponse.status !== 500) {
              if (errorResponse.error.error && errorResponse.error.error.message) {
                const errorMsg = <string>errorResponse.error.error.message;
                this.messageService.add(
                  this.alertMessagesService.getErrorMessage(
                    null,
                    "ERROR",
                    `${errorMsg}\nPlease make another VERIFICATION REQUEST.`
                  )
                );
              } else {
                const errorMsg = errorResponse.message;
                this.messageService.add(
                  this.alertMessagesService.getErrorMessage(
                    null,
                    "ERROR",
                    `${errorMsg}\nPlease make another VERIFICATION REQUEST.`
                  )
                );
              }
            } else {
              this.messageService.add(this.alertMessagesService.getErrorMessage(errorResponse));
            }
          },
        });
      }
    } else {
      this.messageService.add(this.alertMessagesService.getMessage("econduit-close-batch-need-pair-terminal"));
    }
  }

  goBack() {
    this.locationService.back();
  }

  onPaymentTerminalsSaved(event: RowsSavedEvent) {
    const activeTerminals = (event.newValues as PaymentTerminal[]).filter((terminal) => terminal.isActive);
    if (activeTerminals.length) {
      // If there is any active terminal, we should make sure its Gateway is active as well
      const paymentGatewayId = activeTerminals[0].paymentGatewayId;
      this.subsList.push(
        this.dbSevice
          .getRow<PaymentGateway>("paymentGateway", paymentGatewayId)
          .pipe(
            shareReplay(1),
            mergeMap((gateway: PaymentGateway) => {
              if (gateway.isActive) return of(gateway);
              else {
                // If associated payment gateway is found disabled
                gateway.isActive = true;
                // Enable Payment Gateway
                return this.dbSevice.editRow("paymentGateway", gateway).pipe(
                  tap((_gateway: PaymentGateway) => {
                    this.messageService.add({
                      summary: "Payment Gateway updated",
                      severity: "success",
                      detail: `Payment Gateway '${_gateway.paymentGatewayType}' enabled`,
                    });
                  }),
                  catchError((error) => {
                    this.messageService.add({
                      summary: "Error",
                      severity: "error",
                      detail: `Couldn't enable Payment Gateway '${gateway.paymentGatewayType}'`,
                    });

                    return throwError(error);
                  })
                );
              }
            })
          )
          .subscribe({
            next: (gateway) => {},
            error: (error) => {},
          })
      );
    }
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
