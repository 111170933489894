/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, Validators } from "@angular/forms";
import { NestedFormComponent } from "../../../../forms/nested-form/nested-form.component";
import { CommonValidators } from "../../../../shared/validators/CommonValidators";
import { MonetaryHelpers } from "../../../../utility/MonetaryHelpers";
import { Cashout } from "../../../cashout/cashout/cashout";
import { CashoutOpeningFloat } from "./cashout-opening-float";

@Component({
  selector: "taku-settings-cashout-opening-float",
  templateUrl: "./settings-cashout-opening-float.component.html",
  styleUrls: ["./settings-cashout-opening-float.component.scss"],
})
export class SettingsCashoutOpeningFloatComponent extends NestedFormComponent implements OnInit {
  @Input() currencyISOCode;
  amountField: UntypedFormControl;
  fieldCountMaxLength: number;

  static setArray(fb: UntypedFormBuilder, cashOpeningsFloats: CashoutOpeningFloat[]) {
    const formArray = fb.array([]);
    cashOpeningsFloats.map((cashOpeningsFloat) => {
      const temp = fb.group(cashOpeningsFloat);
      formArray.push(temp);
    });
    return formArray;
  }

  ngOnInit(): void {
    super.ngOnInit();

    const maxValidCountValue = Cashout.MAX_COUNT - 1;
    this.fieldCountMaxLength = (maxValidCountValue + "").length;

    if (this._myForm.get("id").value == 0) {
      // If this is a newly added row, mark 'amount' dirty to force user to validate non-zero value
      this.amountField.markAsDirty();
    }
  }

  initValidation() {
    this.amountField = <UntypedFormControl>this._myForm.get("amount");

    this._validation = {
      amount: [Validators.required, CommonValidators.greaterThan(0)],
      count: [Validators.required, Validators.min(0), CommonValidators.positiveInteger()],
    };
  }

  formatDenominationLabel(amount) {
    return MonetaryHelpers.pluralizeDenomination(amount, this.currencyISOCode);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
