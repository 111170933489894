/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, Input, ViewChild, OnDestroy } from "@angular/core";
import { TakuInputCtrlBase } from "../TakuInputCtrlBase";
import { AutocompleteDatatypeOptions } from "src/app/form-list/form-list/form-list";
import { SelectItem } from "primeng/api";
import { AutoComplete } from "primeng/autocomplete";
import { Subscription } from "rxjs";
import { pairwise, startWith, take } from "rxjs/operators";
import _ from "lodash";

@Component({
  selector: "taku-input-autocomplete",
  templateUrl: "./taku-input-autocomplete.component.html",
  styleUrls: ["./taku-input-autocomplete.component.scss"],
})
export class TakuInputAutocompleteComponent extends TakuInputCtrlBase implements OnInit, OnDestroy {
  subsList: Subscription[] = [];
  @Input() _autocompleteOptions: AutocompleteDatatypeOptions;
  @Input() _appendTo?: any = "body";
  @Input() isDropDown?: boolean = false;
  @ViewChild(AutoComplete, { static: true }) autoCompleteComponent: AutoComplete;

  autoCompleteValue: SelectItem = null;
  autocompleteSuggestions: SelectItem[] = [];

  ngOnInit(): void {
    super.ngOnInit();
    this.bindReactiveFieldChangesToInputValue();
  }

  /**
   * @description
   * Binds value changes on the reactive form field into the input field value.
   * @private
   */
  private bindReactiveFieldChangesToInputValue(): void {
    // Set initial value of the input field to the value from the reactive form.
    if (!this.autoCompleteValue && this.ctrlField.value) {
      this.autoCompleteValue = this._autocompleteOptions.transformer.toAutocompleteItem(this.ctrlField.value);
    }

    // Sync the reactive form value updates into the input field value
    this.ctrlField.valueChanges.pipe(startWith(""), pairwise()).subscribe(([prev, curr]) => {
      if (prev != curr) {
        // We still want to set null values from this.ctrlField.setValue(null) calls, so we do this first.
        if (!curr && this.autoCompleteValue != curr) {
          this.autoCompleteValue = curr;
          return;
          // If it's null, and they are the same, we don't need to do anything.
        } else if (!curr) {
          return;
        }

        // if they changed reactive field value (converted) is not the same as input field value,
        // then update the input field value.
        const convertedValue = this._autocompleteOptions.transformer.toAutocompleteItem(curr);
        if (!_.isEqual(convertedValue, this.autoCompleteValue)) {
          this.autoCompleteValue = this._autocompleteOptions.transformer.toAutocompleteItem(curr);
        }
      }
    });
  }

  /**
   * @description
   * Binds value changes (user selecting auto-complete value), on the input field, into the reactive form field.
   */
  setAutocompleteValue(selectItem: SelectItem): boolean | void {
    let convertedVal;
    if (!selectItem) convertedVal = null;
    else {
      if (!selectItem.label || !selectItem.value) return false;
      convertedVal = this._autocompleteOptions.transformer.fromAutocompleteItem(selectItem);
    }

    this.autoCompleteValue = selectItem;
    this.ctrlField.setValue(convertedVal);
    this.ctrlField.markAsDirty();
  }

  onAutocompleteSearch($event: any): void {
    this.subsList.push(
      this._autocompleteOptions.completeMethod($event.query).subscribe({
        next: (rawSuggestions: []) => {
          if (rawSuggestions)
            this.autocompleteSuggestions = rawSuggestions.map((suggestion) =>
              this._autocompleteOptions.transformer.toAutocompleteItem(suggestion)
            );
        },
        error: () => {
          this.autocompleteSuggestions = [];
        },
      })
    );
  }

  clear(): void {
    this.ctrlField.setValue("");
    this.ctrlField.markAsDirty();
    this.autocompleteInputEl.value = "";
  }

  get autocompleteInputEl(): HTMLInputElement {
    const inputEL = this.autoCompleteComponent?.inputEL;
    if (!inputEL) return null;

    return inputEL.nativeElement;
  }

  get isAutocompleteFilled(): boolean {
    return this.isFilled || (this.autocompleteInputEl && !!this.autocompleteInputEl.value);
  }

  ngOnDestroy(): void {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
