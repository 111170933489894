<div id="tender-screen">
  <div class="big-output" [ngClass]="{ hasChangeDue: remainingBalance < 0 }">
    <div class="caption">{{
      remainingBalance > 0 || _mode === TenderScreenMode.RETURNS ? "BALANCE" : "CHANGE DUE"
    }}</div>
    <div class="amount-line">{{
      remainingBalance
        | currency : zoneSettings.defaultCurrencyIsoCode : "symbol-narrow" : decimalFormat
        | formatNegativeNumber
    }}</div>
  </div>

  <main>
    <section class="left-side">
      <div id="main-amounts">
        <div class="main-line total-line">
          <span class="caption">TOTAL:</span>
          <span class="amount">{{
            amountToPay
              | currency : zoneSettings.defaultCurrencyIsoCode : "symbol-narrow" : decimalFormat
              | formatNegativeNumber
          }}</span>
        </div>

        <div class="main-line pennyrounding-line">
          <span class="caption">Cash Rounding:</span>
          <span class="amount"
            >{{ pennyRounding > 0 ? "+" : ""
            }}{{
              (isPennyRoundingApplicable ? pennyRounding : 0)
                | currency : zoneSettings.defaultCurrencyIsoCode : "symbol-narrow" : decimalFormat
                | formatNegativeNumber
            }}</span
          >
        </div>

        <div class="main-line payments-line">
          <span class="caption">Paid:</span>
          <span class="amount">{{
            -1 * totalPaid
              | currency : zoneSettings.defaultCurrencyIsoCode : "symbol-narrow" : decimalFormat
              | formatNegativeNumber
          }}</span>
        </div>

        <div class="main-line balance-line">
          <span class="caption">Balance:</span>
          <span class="amount">{{
            remainingBalance
              | currency : zoneSettings.defaultCurrencyIsoCode : "symbol-narrow" : decimalFormat
              | formatNegativeNumber
          }}</span>
        </div>
      </div>

      <div id="transactions" #transactionsWrapper [style]="{ width: '100%', height: '100%' }">
        <table cellspacing="0" style="width: 100%">
          <tr
            *ngFor="let tenderForm of tendersFormArray['controls']; let i = index"
            [ngClass]="{
              'has-returned-pair': _hasReturnedPair(i, tenderForm.value),
              'is-return': tenderForm.controls.isReturned.value,
              'is-payback': !tenderForm.controls.isTendered.value
            }"
          >
            <td class="sale-doc-tender">
              <taku-sale-doc-tender
                [formGroup]="tenderForm"
                (onRefNoOpened)="onDocTenderRefDialog.emit($event)"
                (onPrintPaymentReceiptClicked)="printPaymentReceipt($event)"
              ></taku-sale-doc-tender>
            </td>
            <td class="transaction-undo-btn">
              <button
                *ngIf="
                  !tenderForm.controls.refTransaction.value &&
                  !tenderForm.controls.isReturned.value &&
                  !_hasReturnedPair(i, tenderForm.value)
                "
                pButton
                class="p-button-text p-button-icon-only"
                (click)="undoPaymentTransaction(tenderForm, i)"
              >
                <i class="material-icons">{{
                  tenderForm.value.tenderType.type === Tender_Type.Store_Voucher ? "close" : "undo"
                }}</i>
              </button>
              <button
                *ngIf="
                  tenderForm.controls.refTransaction.value &&
                  !tenderForm.controls.isReturned.value &&
                  !_hasReturnedPair(i, tenderForm.value)
                "
                pButton
                class="mt-1 p-button-rounded p-button-outlined p-button-icon-only tender-menu-btn"
                (click)="menuSaleTenderUndoItems.toggle($event)"
              >
                <i class="material-icons md-18">more_horiz</i>
              </button>
            </td>
            <p-menu
              #menuSaleTenderUndoItems
              popup="true"
              [appendTo]="'body'"
              [model]="saleTenderUndoItems(tenderForm, i)"
            ></p-menu>
          </tr>
        </table>
      </div>
    </section>

    <section class="right-side">
      <div class="col-12 p-0 p-fluid">
        <p-selectButton
          class="tenderTabs-full"
          [options]="tenderTabsDesktop"
          [(ngModel)]="selectedTenderTab"
        ></p-selectButton>
        <p-selectButton
          class="tenderTabs-compact"
          [options]="tenderTabsDesktop"
          [(ngModel)]="selectedTenderTab"
        ></p-selectButton>
      </div>

      <div id="tender_pay_btns" class="tenders-btns payment-btns" *ngIf="selectedTenderTab === TenderTab.TENDERS">
        <button
          *ngIf="docAccount && isTakuPayEnabled"
          pButton
          (click)="openCNPNumpadComponent('Saved Cards')"
          [disabled]="areTendersDisabled() || docAccount?.data?.account?.accountCreditCards?.length < 1"
          class="tender-type-btn bg-green-900"
          label="Saved Cards"
        ></button>

        <button
          *ngIf="docAccount && isTakuPayEnabled"
          pButton
          (click)="openCNPNumpadComponent('Manual Entry')"
          [disabled]="areTendersDisabled()"
          class="tender-type-btn bg-green-900"
          label="Manual Entry"
        ></button>

        <p-progressSpinner
          *ngIf="(storeTenderTypes$ | async) === null"
          styleClass="w-full overflow-hidden"
        ></p-progressSpinner>

        <button
          *ngFor="let storeTenderType of storeTenderTypes$ | async"
          pButton
          [disabled]="
            areTendersDisabled() ||
            (!storeTenderType.tenderType.takuPaymentTerminal &&
              storeTenderType.tenderType.type !== Tender_Type.Store_Credit &&
              storeTenderType.tenderType.type !== Tender_Type.Check_Cheque &&
              storeTenderType.tenderType.type !== Tender_Type.Fund_Transfer_Bank &&
              storeTenderType.tenderType.type !== Tender_Type.Fund_Transfer_Processor &&
              (saleDocHasRecurring || _myForm?.get('doc.docType')?.value === SaleDocType.pre_order)) ||
            (storeTenderType.tenderType.isIntegrated &&
              (terminalCheckComplete | async) === true &&
              !(storeTenderType.tenderType.paymentTerminalId || storeTenderType.tenderType.takuPaymentTerminalId)) ||
            (storeTenderType.tenderType.type === Tender_Type.Store_Credit &&
              !(accountCreditForTransactionMap[storeTenderType.tenderType.id]?.accountCredit > 0))
          "
          [loading]="storeTenderType.tenderType.isIntegrated && (terminalCheckComplete | async) === false"
          class="tender-type-btn p-button-success"
          [ngClass]="'tenderButton-' + Tender_Type_Map[storeTenderType.tenderType.type]"
          (click)="enterTenderPayment(storeTenderType.tenderType)"
          label="{{ storeTenderType.tenderType.description }} {{
            storeTenderType.tenderType.paymentGatewayShortname
              ? storeTenderType.tenderType.paymentGatewayShortname
              : storeTenderType.tenderType.takuPaymentGatewayShortname
          }}"
        >
        </button>
      </div>

      <div
        *ngIf="!isTakuPayEnabled && selectedTenderTab === TenderTab.PAYBACKS"
        id="tender_payback_btns"
        class="tenders-btns payback-btns"
      >
        <button
          *ngFor="let storeTenderType of storeTenderTypes$ | async"
          pButton
          [disabled]="
            arePaybacksDisabled() ||
            (storeTenderType.tenderType.isIntegrated &&
              (terminalCheckComplete | async) === true &&
              !(storeTenderType.tenderType.paymentTerminalId || storeTenderType.tenderType.takuPaymentTerminalId))
          "
          [loading]="storeTenderType.tenderType.isIntegrated && (terminalCheckComplete | async) === false"
          class="tender-type-btn p-button-returns-custom"
          [ngClass]="'returnButton-' + Tender_Type_Map[storeTenderType.tenderType.type]"
          (click)="enterTenderPayment(storeTenderType.tenderType)"
          label="{{ storeTenderType.tenderType.description }} {{
            storeTenderType.tenderType.paymentGatewayShortname
              ? storeTenderType.tenderType.paymentGatewayShortname
              : storeTenderType.tenderType.takuPaymentGatewayShortname
          }}"
        >
        </button>
      </div>

      <ng-container *ngIf="isTakuPayEnabled && selectedTenderTab === TenderTab.PAYBACKS">
        <div
          *ngIf="origCardsList?.length > 0"
          id="tender_payback_orig_cards_list"
          class="tenders-orig-cards-list payback-btns"
        >
          <p-dropdown
            [options]="origCardsList"
            [(ngModel)]="selectedOrigCard"
            optionLabel="name"
            optionValue="code"
          ></p-dropdown>
        </div>
      </ng-container>

      <div *ngIf="selectedTenderTab === TenderTab.PAYBACKS" id="tender_payback_btns" class="tenders-btns payback-btns">
        <ng-container *ngIf="origCardsList?.length > 0 && selectedOrigCard">
          <button
            *ngIf="isTakuPayEnabled && selectedOrigCard.tenderType?.type !== Tender_Type.Debit_Card"
            pButton
            [disabled]="arePaybacksDisabled()"
            class="tender-type-btn p-button-returns-custom"
            (click)="enterTenderPayment(selectedOrigCard.tenderType)"
            label="Use Original Card"
          >
          </button>
        </ng-container>

        <ng-container *ngFor="let storeTenderType of storeTenderTypes$ | async; let i = index">
          <button
            *ngIf="
              storeTenderType.tenderType.type === Tender_Type.Cash ||
              storeTenderType.tenderType.type === Tender_Type.Store_Credit ||
              (storeTenderType.tenderType.type === Tender_Type.Credit_Card &&
                storeTenderType.tenderType.paymentTerminalId > 0)
            "
            pButton
            [disabled]="arePaybacksDisabled()"
            class="tender-type-btn p-button-returns-custom"
            [ngClass]="'returnButton-' + Tender_Type_Map[storeTenderType.tenderType.type]"
            (click)="enterTenderPayment(storeTenderType.tenderType)"
            label="{{ storeTenderType.tenderType.description }}"
          >
          </button>
        </ng-container>
      </div>
    </section>
  </main>

  <footer class="card">
    <!-- <button pButton class="btn-action email-btn p-button-secondary" label="EMAIL" icon="ui-icon-email" [disabled]="true"></button> -->
    <table class="tender-saveSettings" cellspacing="0">
      <tr class="print-settings">
        <td class="switch">
          <taku-toggle
            [captionPosition]="ToggleCaptionPosition.LEFT"
            [showStateLabel]="false"
            [formGroup]="tenderSettingsForm"
            fieldName="print"
            fieldCaption="Print"
          >
          </taku-toggle>
        </td>
        <td class="extra">
          <div class="printing-size">
            <taku-dropdown
              [formGroup]="tenderSettingsForm"
              [options]="enum_printing_sizes"
              fieldName="printingSize"
            ></taku-dropdown>
          </div>

          <div class="extra-copy">
            <taku-checkbox [formGroup]="tenderSettingsForm" fieldName="extraCopy" fieldCaption="Merchant Copy">
            </taku-checkbox>
          </div>
        </td>
      </tr>
      <tr class="email-settings">
        <td class="switch">
          <taku-toggle
            [captionPosition]="ToggleCaptionPosition.LEFT"
            [showStateLabel]="false"
            [formGroup]="tenderSettingsForm"
            fieldName="sendEmail"
            fieldCaption="Send Email"
          >
          </taku-toggle>
        </td>
        <td class="extra">
          <taku-input
            *ngIf="sendEmailEnabled"
            [disabled]="!ctrlSendEmail.value"
            [formGroup]="tenderSettingsForm"
            fieldName="emailAddress"
          ></taku-input>
          <div *ngIf="!sendEmailEnabled" class="taku-warningMsg">
            <p-message severity="warn" text="Email Settings needs to be set up before sending emails."></p-message>
          </div>
        </td>
      </tr>
      <tr *ngIf="isSalesInvoice && amountToPay >= 0" class="giftreceipt-settings">
        <td class="switch">
          <taku-toggle
            [captionPosition]="ToggleCaptionPosition.LEFT"
            [showStateLabel]="false"
            [formGroup]="tenderSettingsForm"
            fieldName="giftReceipt"
            fieldCaption="Gift Receipt"
          >
          </taku-toggle>
        </td>
      </tr>
    </table>
    <button
      id="done_btn"
      pButton
      pRipple
      type="button"
      class="taku-megaBtn p-button-primary-dark"
      [disabled]="
        !(
          tenderSettingsForm.valid &&
          ((_myForm.get('doc.docType').value === SaleDocType.pre_order &&
            _myForm.get('doc.state').value === DocState.suspended) ||
            isTenderCompleted)
        )
      "
      (click)="onClosePressed($event)"
      label="{{ hasChangeDue ? 'CHANGE DONE' : 'DONE' }}"
    >
      <span class="btn-icon material-icons">receipt</span>
    </button>
  </footer>
</div>

<taku-numpad
  #numpad
  [padTitle]="'Amount'"
  numpadType="monetary"
  [currencyIsoCode]="zoneSettings.defaultCurrencyIsoCode"
  [initialQty]="amountForCalculator"
  [tenderDescription]="
    numpadTenderDescription +
    (selectedTender?.paymentGatewayShortname ? '(' + selectedTender?.paymentGatewayShortname + ')' : '')
  "
  [selectedTenderType]="selectedTender"
  [defaultQty]="amountForCalculator"
  [maximumQty]="maximumAmountForCalc"
  [keyCaptureDisabled]="cardNotPresentDetails?.cardNotPresentType === Card_Not_Present_Type.ManualEntry"
  [numpadDisabled]="
    saleDocHasRecurring ||
    (selectedTender?.type !== Tender_Type.Store_Credit && _myForm.get('doc.docType').value === SaleDocType.pre_order)
  "
  [numpadHidden]="
    selectedTender?.type !== Tender_Type.Store_Credit && _myForm.get('doc.docType').value === SaleDocType.pre_order
  "
  [acceptButtonDisabled]="
    (selectedTender?.type === Tender_Type.Store_Voucher && !lastValidVoucherFound) ||
    (cardNotPresentDetails &&
      (adyenExtraFieldsForm.invalid ||
        (cardNotPresentDetails?.cardNotPresentType === Card_Not_Present_Type.ManualEntry &&
          !adyenCardFormState?.isValid)))
  "
  (submitQty)="onPaymentSubmitted($event)"
  (onCancel)="onNumpadCancel($event)"
>
  <div *ngIf="selectedTender?.type === Tender_Type.Store_Voucher && numpad.isVisible" class="numpad-content">
    <div class="mb-3 p-3 numpad-header">
      <h3>Apply {{ selectedTender.description }}</h3>
    </div>
    <taku-voucher-balance
      [vouchersInTransaction]="vouchersInTransaction"
      (validVoucherFound)="validVoucherFound($event)"
    ></taku-voucher-balance>
  </div>
  <div
    *ngIf="cardNotPresentDetails || (selectedTender?.takuPaymentTerminal && isUnFinalizedPreOrder)"
    id="cardOffHandFormContent"
  >
    <div class="mb-3 p-3 numpad-header">
      <h3>{{ numpadTenderDescription }}</h3>
    </div>

    <ng-container [ngSwitch]="cardNotPresentDetails?.cardNotPresentType">
      <div *ngSwitchCase="Card_Not_Present_Type.SavedCards">
        <h6>Use Card on File</h6>
        <div class="grid">
          <div class="col-12">
            <taku-dropdown
              [formGroup]="adyenExtraFieldsForm"
              fieldName="cardOnFile"
              [options]="savedCardDropdownOptions"
              fieldCaption="Card On File"
              appendTo="body"
            ></taku-dropdown>
          </div>
        </div>
      </div>
      <div *ngSwitchCase="Card_Not_Present_Type.ManualEntry">
        <h6>Use New Card</h6>
        <taku-adyen-integration (adyenCardFormStateEmitter)="adyenCardFormState = $event"></taku-adyen-integration>
      </div>
    </ng-container>
  </div>

  <div
    *ngIf="
      _myForm?.get('account')?.value &&
      (selectedTender?.takuPaymentTerminal ||
        cardNotPresentDetails?.cardNotPresentType === Card_Not_Present_Type.SavedCards ||
        cardNotPresentDetails?.cardNotPresentType === Card_Not_Present_Type.ManualEntry)
    "
    class="grid"
  >
    <div class="col-12">
      <taku-checkbox
        [formGroup]="adyenExtraFieldsForm"
        fieldName="saveCardForFutureUse"
        fieldCaption="Save Card for Future Use"
      >
      </taku-checkbox>
    </div>
  </div>
</taku-numpad>

<!--
  <p-listbox [options]="saleActionItems" [(ngModel)]="selectedSaleActionItem"></p-listbox>
-->

<!--
<div #printPreviewWrapper [hidden]="true">
  <taku-receipt-tape-preview *ngIf="builderSettings && saleDoc"  [saleDocData]="saleDoc" [builderSettings]="builderSettings" [policies]="storePolicies"></taku-receipt-tape-preview>
</div>
-->

<!-- <code>FORM: {{_myForm.value|json}}</code> -->
<div [hidden]="true">
  <ng-template #printPreviewHost></ng-template>
</div>
