<div [formGroup]="formGroup">
  <span class="p-float-label">
    <taku-inputMask
      slotChar="#"
      numericMaskChar="#"
      (invalidCharInput)="showInvalidInputMsg($event)"
      [formControlName]="fieldName"
      [disabled]="disabled"
      [mask]="_phoneMask"
      [unmask]="false"
      [style]="{ width: '100%' }"
      [autoClear]="false"
      [placeholder]="!fieldCaption ? 'Type phone number' : null"
    ></taku-inputMask>
    <label *ngIf="ctrlField.value && fieldCaption" [innerHTML]="fullFieldCaption"></label>
    <i class="material-icons clear-field" (click)="clear()" *ngIf="ctrlField.value && !(readonly || disabled)">close</i>
  </span>

  <form-validation-messages
    [hidden]="ctrlField.valid || !ctrlField.dirty"
    [errors]="ctrlField.errors"
    [fieldName]="fieldName"
  ></form-validation-messages>
  <!-- <form-validation-messages *ngIf="ctrlField.touched" [errors]="ctrlField.errors"></form-validation-messages> -->
</div>
