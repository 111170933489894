/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Store } from "../store/store";

export class StoreSettingSaleDoc {
  id: number;
  entryMethod: SaleDocEntryMethod;
  autoCopyAccountAddress: boolean;
  saleScreenDefaultTab: SaleScreenDefaultTab;
  autoDisplayShortFallQtyList: boolean;
  storeId: number;

  constructor(store?: Store) {
    this.id = 0;
    this.entryMethod = SaleDocEntryMethod.AccumulateLastLine;
    this.autoCopyAccountAddress = true;
    this.saleScreenDefaultTab = SaleScreenDefaultTab.Revisit;
    this.autoDisplayShortFallQtyList = false;
    this.storeId = store ? store.id : 0;
  }
}

export enum SaleDocEntryMethod {
  AccumulateLastLine = "Accumulate Last Line",
  CombineQty = "Combine Qty",
  NewLine = "New Line",
}

export enum SaleScreenDefaultTab {
  Revisit = "Revisit",
  BillTo = "Bill To",
  Lists = "Lists",
}
/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
