/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "replaceLineBreaks",
  standalone: true,
})
export class ReplaceLineBreaksPipe implements PipeTransform {
  transform(value: string): string {
    return value.replace(/\n/g, "<br/>");
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
