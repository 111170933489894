/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit } from "@angular/core";
import { NestedFormComponent } from "../../../forms/nested-form/nested-form.component";
import { StoreEmailType, StoreEmail } from "./store-email";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";

@Component({
  selector: "taku-store-email",
  templateUrl: "./store-email.component.html",
  styleUrls: ["./store-email.component.scss"],
})
export class StoreEmailComponent extends NestedFormComponent {
  // Define enum variables
  // enum_commecrial_phone_type = this.dbService.enumSelectOptions(CommercialAccountPhoneType, 'Type');
  enum_store_email_type = this.dbService.enumSelectOptions(StoreEmailType);

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    const storeEmail_Group = fb.group(new StoreEmail());
    return storeEmail_Group;
  }

  static setArray(fb: UntypedFormBuilder, storeEmails: StoreEmail[]) {
    const formArray = fb.array([]);
    storeEmails.map((storeEmail) => {
      const temp = fb.group(storeEmail);
      formArray.push(temp);
    });
    return formArray;
  }

  initValidation() {
    this._validation = {
      email: [Validators.email],
    };
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
