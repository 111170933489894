/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Input, Output, EventEmitter, inject, OnInit } from "@angular/core";
import { TakuInputCtrlBase } from "../TakuInputCtrlBase";
import { SelectItem } from "primeng/api";
import { Platform } from "@angular/cdk/platform";

@Component({
  selector: "taku-dropdown",
  templateUrl: "./taku-dropdown.component.html",
  styleUrls: ["./taku-dropdown.component.scss"],
})
export class TakuDropdownComponent extends TakuInputCtrlBase implements OnInit {
  private platform = inject(Platform);
  @Input() dataKey?: string;
  @Input() appendTo?: any;
  @Input() group? = false;
  @Input() useFilter = false;

  // @Input() set options(items){
  //   if (items) items.unshift( { value: null, label: ""} );
  //   this._options = items;
  // }
  // get options(){
  //   return this._options;
  // }
  // _options: SelectItem[];
  @Input() options: SelectItem[];
  // @Input() placeholder: string;

  isMobileDevice = false;

  @Output() dropDownClicked: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {
    this.isMobileDevice = this.platform.IOS || this.platform.ANDROID;
  }

  handleClick(e: any) {
    this.dropDownClicked.emit(e);
  }

  handleChange(e: any) {
    this.onChange.emit(e);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
