<h3>Stock Levels</h3>
<p-table
  id="main-table"
  [scrollable]="true"
  [scrollHeight]="tableScrollHeight"
  tableStyle="table-layout:fixed"
  responsiveLayout="stack"
  breakpoint="640px"
  [value]="_formArray['controls']"
  styleClass="p-datatable-striped"
>
  <ng-template pTemplate="header">
    <tr>
      <th class="stockName-col">Stock</th>
      <th class="onHand-col">OH Qty</th>
      <th class="committedQty-col">Commited Qty</th>
      <th class="onOrder-col">On Order Qty</th>
      <th class="minStock-col">Min Stock Qty</th>
      <th class="maxStock-col">Max Stock Qty</th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-formGroup>
    <tr>
      <taku-inventory-stock [formGroup]="formGroup" [displayMode]="_displayMode"></taku-inventory-stock>
    </tr>
  </ng-template>
</p-table>
