<div class="topWrapper">
  <taku-form-header [toolbarTitle]="_headerTitle" displayMode="inline" [showActionBtns]="false" [showBackBtn]="false">
    <button pButton class="p-button-rounded" type="button" (click)="onClosePressed()" icon="pi pi-times"></button>
  </taku-form-header>
  <div class="bodyWrapper">
    <main>
      <div id="doc-fields">
        <table cellspacing="0">
          <tr>
            <td class="label-col">Doc#</td>
            <td class="value-col">{{ saleDoc.doc.docNo }}</td>
          </tr>
          <tr>
            <td class="label-col">Type</td>
            <td class="value-col">{{ saleDoc.doc.docType }}</td>
          </tr>
          <tr>
            <td class="label-col">Date & Time</td>
            <td class="value-col">{{ saleDoc.doc.docDate | dateTimeFormat : saleDoc.doc.docTime }}</td>
          </tr>
          <tr>
            <td class="label-col">Store (Station) User</td>
            <td class="value-col"
              >{{ saleDoc.store.storeName }} ({{ saleDoc.station ? saleDoc.station.stationName : "" }})
              {{ saleDoc.doc.user?.userName }}</td
            >
          </tr>
          <tr>
            <td class="label-col">Channel Name {{ saleChannelOrder?.docNo ? "(Ref#)" : "" }}</td>
            <td class="value-col"
              >{{ saleDoc?.saleChannel?.channelType }}
              {{ saleChannelOrder?.docNo ? "(" + saleChannelOrder?.docNo + ")" : "" }}</td
            >
          </tr>
          <tr>
            <td class="label-col">Customer</td>
            <td class="value-col"
              ><span class="cursor-pointer link" (click)="openCustomerDetails()">{{ saleDoc.accountName }}</span></td
            >
          </tr>
          <tr>
            <td class="label-col">Customer Email</td>
            <td class="value-col link">
              <a href="{{ 'mailto:' + saleDoc.accountEmail }}">{{ saleDoc.accountEmail }}</a>
              <taku-action-button
                [size]="BUTTON_SIZE.SMALL"
                [action]="ACTION_TYPE.COPY"
                (clickAction)="copyEmailToClipboard(saleDoc.accountEmail)"
              ></taku-action-button>
            </td>
          </tr>
        </table>

        <table cellspacing="0">
          <tr>
            <td class="label-col">Total</td>
            <td class="value-col">{{ saleDoc.grandTotal | currency : _currencyISOCode : "symbol-narrow" : "1.2" }}</td>
          </tr>
          <tr class="payType-line">
            <td class="label-col">Pay Type</td>
            <td class="value-col">
              <span class="flex align-items-center">
                <span>{{ tendersPrettyPrint }}</span>
                <taku-tender-details-overlay [saleDocTenders]="saleDoc.saleDocTenders"></taku-tender-details-overlay>
              </span>
            </td>
          </tr>
          <tr>
            <td class="label-col">Payment Status</td>
            <td class="value-col">{{ saleDoc?.paymentStatus }}</td>
          </tr>
          <tr>
            <td class="label-col">Outgoing Date</td>
            <td class="value-col">
              {{ footerFormGroup.get("deliveryDate")?.value ?? (saleChannelOrder?.pickupDate | dateTimeFormat) }}
              <a
                *ngIf="_enabledEditMode"
                href="javascript://"
                (click)="deliveryDatePanel.toggle($event)"
                style="float: right"
                [class.disabled-link]="
                  saleChannelOrder?.orderStatus === 'VOIDED' ||
                  isVoided ||
                  footerFormGroup.get('fulfillmentStatus').value === FulfillmentStatus.completed
                "
                class="material-icons md-18"
              >
                edit
                <p-overlayPanel #deliveryDatePanel [showCloseIcon]="false" appendTo="body">
                  <taku-calendar-date
                    style="width: 200px"
                    [formGroup]="footerFormGroup"
                    fieldName="deliveryDate"
                    [minDate]="orderCreationDateObj"
                    fieldCaption="Outgoing Date"
                    appendTo="body"
                    [disabled]="footerFormGroup.get('fulfillmentStatus').value === FulfillmentStatus.completed"
                  >
                  </taku-calendar-date>
                </p-overlayPanel>
              </a>
            </td>
          </tr>
          <tr>
            <td class="label-col">Fulfillment Status</td>
            <!-- <td class="value-col">{{saleDoc.fulfillmentStatus}}</td> -->
            <td class="value-col">
              {{ footerFormGroup.get("fulfillmentStatus").value }}
              <a
                *ngIf="_enabledEditMode"
                href="javascript://"
                (click)="fulfillmentStatusPanel.toggle($event)"
                style="float: right"
                [class.disabled-link]="saleChannelOrder?.orderStatus === 'VOIDED' || isVoided"
                class="material-icons md-18"
              >
                edit
                <p-overlayPanel #fulfillmentStatusPanel [showCloseIcon]="false" appendTo="body">
                  <taku-dropdown
                    style="width: 200px"
                    [formGroup]="footerFormGroup"
                    [options]="enum_saledoc_fulfillment_status"
                    fieldName="fulfillmentStatus"
                    fieldCaption="Fulfillment Status"
                    appendTo="body"
                    (onChange)="onFulfillmetStatusChanged()"
                    [disabled]="footerFormGroup.get('fulfillmentStatus').value === 'Fulfilled'"
                  >
                  </taku-dropdown>
                </p-overlayPanel>
              </a>
            </td>
          </tr>
          <tr>
            <td class="label-col">Delivery Method</td>
            <td class="value-col">
              {{ footerFormGroup.get("deliveryMethod").value }}
              {{ saleDoc.shipper ? "| " + saleDoc.shipper.shipperName : "" }}
              <a
                *ngIf="_enabledEditMode"
                href="javascript://"
                class="material-icons md-18"
                (click)="deliveryMethodPanel.toggle($event)"
                [class.disabled-link]="saleChannelOrder?.orderStatus === 'VOIDED' || isVoided"
                style="float: right"
                >edit
                <p-overlayPanel #deliveryMethodPanel [showCloseIcon]="false" appendTo="body">
                  <taku-dropdown
                    style="width: 200px"
                    [formGroup]="footerFormGroup"
                    [options]="enum_saledoc_delivery_method"
                    fieldName="deliveryMethod"
                    fieldCaption="Delivery Method"
                    appendTo="body"
                    (onChange)="onDeliveryMethodChanged()"
                  >
                  </taku-dropdown>
                </p-overlayPanel>
              </a>
            </td>
          </tr>
          <tr>
            <td class="label-col">Delivery Status</td>
            <td class="value-col">
              {{ footerFormGroup.get("deliveryStatus").value }}
              <a
                *ngIf="_enabledEditMode"
                href="javascript://"
                class="material-icons md-18"
                (click)="deliveryStatusPanel.toggle($event)"
                [class.disabled-link]="saleChannelOrder?.orderStatus === 'VOIDED' || isVoided"
                style="float: right"
                >edit
                <p-overlayPanel #deliveryStatusPanel [showCloseIcon]="false" appendTo="body">
                  <taku-dropdown
                    style="width: 200px"
                    [formGroup]="footerFormGroup"
                    [options]="enum_saledoc_delivery_status"
                    fieldName="deliveryStatus"
                    fieldCaption="Delivery Status"
                    appendTo="body"
                    (onChange)="onDeliveryStatusChanged()"
                  >
                  </taku-dropdown>
                </p-overlayPanel>
              </a>
            </td>
          </tr>
          <tr>
            <td class="label-col">Notes</td>
            <td class="value-col"
              >{{ saleChannelOrder?.deliveryMethodName }}
              {{
                saleDoc.doc.voidReason
                  ? (saleChannelOrder?.deliveryMethodName ? "| " : "") + saleDoc.doc.voidReason
                  : ""
              }}</td
            >
          </tr>
        </table>
      </div>
    </main>

    <taku-sale-doc-transactions *ngIf="saleDoc.saleDocLines" [saleDoc]="saleDoc"></taku-sale-doc-transactions>

    <footer>
      <div class="footer-btns grid">
        <div class="sendEmailWrapper" *ngIf="_isStore">
          <taku-input
            *ngIf="sendEmailEnabled"
            class="field-emailAddress"
            [disabled]="isVoided"
            [formGroup]="footerFormGroup"
            fieldName="emailAddress"
            fieldCaption="Email address"
          ></taku-input>
          <div *ngIf="!sendEmailEnabled" class="taku-warningMsg">
            <p-message
              severity="warn"
              text="&nbsp;Email Settings needs to be set up before sending emails."
            ></p-message>
          </div>
          <div class="email-input-container">
            <button
              pButton
              class="email-btn squared-corners"
              [disabled]="
                isVoided || footerFormGroup.get('emailAddress').invalid || !footerFormGroup.get('emailAddress').value
              "
              (click)="menuEmailReceiptSizes.toggle($event)"
            >
              <i class="material-icons"> email </i>
              <span class="ml-2 font-bold">Send</span>
            </button>
          </div>
        </div>
        <!-- <button pButton class="btn-action gift-receipt p-button-secondary" label="Gift Receipt" icon="takuicon-gift" [disabled]="true"></button> -->
        <div class="btn-container-wrapper">
          <div class="btn-container">
            <button
              pButton
              [disabled]="!printMgr.isReady || saleDoc.doc.state === 'Voided'"
              class="btn-action reprint-btn squared-corners"
              [label]="'Reprint'"
              icon="pi pi-print"
              (click)="menuPrintSizes.toggle($event)"
            >
            </button>
          </div>
          <div *ngIf="_enabledEditMode" class="btn-container">
            <button
              pButton
              class="btn-action"
              label="Void"
              icon="pi pi-ban"
              [disabled]="isVoidDisabled"
              (click)="onVoidPressed()"
            ></button>
          </div>
          <!-- <div *ngIf="saleDoc.doc.docSourceType === 'Sale Channel Order'"> -->
          <div
            *ngIf="saleDoc.doc.docSourceType === 'Sale Channel Order' && _isStore && _enabledEditMode"
            class="btn-container"
          >
            <!-- <div class="btn-container"> -->
            <button
              pButton
              class="btn-action"
              label="Pay"
              [disabled]="
                saleChannelOrder?.paymentStatus === 'Paid' ||
                saleChannelOrder?.paymentStatus === 'Refunded' ||
                saleChannelOrder?.paymentStatus === 'Disputed' ||
                saleChannelOrder?.paymentStatus === 'Voided' ||
                saleDoc.doc.state === 'Voided'
              "
              (click)="onOpenSalesScreen()"
            ></button>
            <!-- [disabled]="saleChannelOrder?.fulfillmentStatus === 'Fulfilled' || saleChannelOrder?.fulfillmentStatus === 'Returned To Stock' || saleChannelOrder?.fulfillmentStatus === 'Voided' || saleDoc.doc.state === 'Voided'" -->
          </div>
        </div>
      </div>
    </footer>

    <p-menu #menuPrintSizes [popup]="true" [model]="printSizesMenuItems" appendTo="body"></p-menu>
    <p-menu #menuEmailReceiptSizes [popup]="true" [model]="emailReceiptSizesMenuItems" appendTo="body"></p-menu>
  </div>
</div>

<p-dialog
  [(visible)]="_activeFullDialog"
  [ngSwitch]="_activeFullDialog"
  [contentStyle]="{ 'max-height': 'calc(100% - 50px)' }"
  class="fullsize-modal"
  [blockScroll]="true"
  [modal]="false"
  appendTo="body"
  [showHeader]="true"
  (onHide)="closeFullSizeDialog()"
  [styleClass]="_activeFullDialog !== 'printPreview' ? 'fixedsize-modal' : null"
>
  <div *ngSwitchCase="'printPreview'" class="print-preview"> </div>
</p-dialog>

<div [hidden]="true">
  <ng-template #printPreviewHost></ng-template>
</div>
