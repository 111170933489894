<taku-form-header
  [toolbarTitle]="
    'Manage Access Rights&nbsp;<span class=\'secondary-text\'>(' + (roleData$ | async)?.roleName + ')</span>'
  "
  [showActionBtns]="false"
  [showBackBtn]="true"
  (backClicked)="goBack()"
></taku-form-header>

<p-tabMenu [model]="menuAccessRights" [activeItem]="menuAccessRights[0]"></p-tabMenu>
<router-outlet></router-outlet>
