/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Input, OnChanges, ElementRef, SimpleChanges, OnInit } from "@angular/core";
import { LocationService } from "../../shared/services/location.service";
import { TakuInputCtrlBase } from "../TakuInputCtrlBase";
import { SelectItem } from "primeng/api";

@Component({
  selector: "taku-sub-region",
  templateUrl: "./taku-sub-region.component.html",
  styleUrls: ["./taku-sub-region.component.scss"],
})
export class TakuSubRegionComponent extends TakuInputCtrlBase implements OnChanges, OnInit {
  @Input() countryIsoCode: string;
  lookup_subdivisions: SelectItem[];

  constructor(public locationService: LocationService, public el: ElementRef) {
    super(el);
  }

  // Don't input fieldName into p-dropdown until options are loaded, or the dropdown will clear the form value
  hasLoadedOptions(): boolean {
    return this.lookup_subdivisions.length > 1;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["countryIsoCode"]) {
      this.lookup_subdivisions = this.locationService.lookupSubdivisionOptions(this.countryIsoCode);
      this.lookup_subdivisions.unshift({
        label: "",
        value: null,
      });
    }
  }

  ngOnInit(): void {
    this.formGroup.controls[this.fieldName].addValidators((ctrl) => {
      if (!ctrl.value || !this.lookup_subdivisions) {
        return null;
      }
      const matchedCtrl = this.lookup_subdivisions.find((sub) => sub.value === ctrl.value);
      if (!matchedCtrl) {
        return { generic: `Selected region code '${ctrl.value}' not found` };
      }
      return null;
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
