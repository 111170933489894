/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { DialogService } from "primeng/dynamicdialog";
import { Subscription } from "rxjs";
import { FormListComponent } from "src/app/form-list/form-list/form-list.component";
import { Col } from "../../../form-list/form-list/form-list";
import { DBService } from "../../../shared/services/db.service";
import { RolePermissionsComponent } from "../role-permissions/role-permissions.component";
import { UserService } from "../user/user.service";
import { MultiFormListComponent } from "src/app/form-list/form-list-main/multiple-form-lists.interface";
import { Location } from "@angular/common";
import { FilterRows } from "src/app/utility/FormDataHelpers";
import { User } from "../user/user";

@Component({
  selector: "taku-user-list",
  templateUrl: "./user-list.component.html",
  styleUrls: ["./user-list.component.scss"],
  providers: [DialogService],
})
export class UserListComponent implements OnInit, OnDestroy, MultiFormListComponent {
  subsList: Subscription[] = [];
  @ViewChild(FormListComponent, { static: true }) formListComponent: FormListComponent;
  activeFilter: FilterRows<User> = {
    userType: { matchMode: "equals", value: "Merchant" },
    isSuperAdmin: { matchMode: "equals", value: false },
  };
  userCols: Col[] = [];

  constructor(
    private userService: UserService,
    private dialogService: DialogService,
    private dbService: DBService,
    private location: Location
  ) {
    this.userCols = this.userService.getFormListColumns();
  }

  ngOnInit(): void {
    this.subsList.push(
      this.userService.openPermissionsEvent.subscribe((rowData) => {
        const userId = rowData.id;
        this.subsList.push(
          this.dbService.getRow("user", userId).subscribe((userData) => {
            this.dialogService.open(RolePermissionsComponent, {
              data: {
                user: userData,
              },
              closable: false,
              styleClass: "rolePermission-dialog-style",
              // width: '60%',
              height: "600px",
              contentStyle: {
                height: "calc(100% - 40px)",
                overflow: "auto",
              },
            });
          })
        );
      })
    );
  }

  ngOnDestroy(): void {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }

  retrieveFormLists(): FormListComponent[] {
    return [this.formListComponent];
  }

  onBackClicked(): void {
    this.location.back();
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
