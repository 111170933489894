<div class="grid col-12 top-panels">
  <!-- <div class="col-4">
      <p-panel>
        <ng-template pTemplate="header">Amount Due</ng-template>
        <div class="main-content">
          -
        </div>
        <ng-template pTemplate="footer">Status: Active</ng-template>
      </p-panel>
    </div> -->

  <div class="col-12 xl:col-6 lg:col-6 md:col-6 statistics-card total-sales-card">
    <p-panel>
      <ng-template pTemplate="header">Total Sales</ng-template>
      <div class="main-content">
        {{
          accountSaleStats?.TotalSales
            ? (accountSaleStats.TotalSales
              | currency : accountSaleStats.currencyIsoCode : "symbol-narrow" : _digitsFormat
              | formatNegativeNumber)
            : 0
        }}
      </div>
      <ng-template pTemplate="footer"
        >Total Returns
        {{
          accountReturnStats?.TotalSales
            ? (accountReturnStats.TotalSales
              | currency : accountReturnStats.currencyIsoCode : "symbol-narrow" : _digitsFormat
              | formatNegativeNumber)
            : 0
        }}</ng-template
      >
      <!-- <ng-template pTemplate="footer">{{invoiceCountCaption}}</ng-template> -->
    </p-panel>
  </div>

  <div class="col-12 xl:col-6 lg:col-6 md:col-6 statistics-card last-transaction-card">
    <p-panel>
      <ng-template pTemplate="header">Last Sale</ng-template>
      <div class="main-content">
        {{ lastTxrRelativeTime ? lastTxrRelativeTime.amount : "N/A" }}
      </div>
      <ng-template pTemplate="footer">{{
        lastTxrRelativeTime ? lastTxrRelativeTime.timeUnit + " ago" : "N/A"
      }}</ng-template>
    </p-panel>
  </div>

  <div class="col-12">
    <!-- <h3>ACTIVITY HISTORY</h3> -->
    <div *ngIf="_isAdmin"> Note: Only business Default Currency transactions are included </div>
    <div *ngIf="!_isAdmin"> Note: Only zone Default Currency transactions are included </div>
    <taku-form-list
      #formList
      [_showHeader]="true"
      [_filter]="historyFilter ? historyFilter : undefined"
      [_model]="'saleDoc'"
      [_rowSelectionType]="RowSelectionType.NONE"
      [_detailMode]="'d'"
      _scrollHeight="full"
      [_title]="'ACTIVITY HISTORY'"
      [_hideSavingCtrls]="true"
      [_extraQueryParams]="_crmSaleDocExtraParams"
      [_showCtrlsInEditMode]="false"
      [_hideAddLineHeaderBtn]="true"
      [_hideOptionsHeaderBtn]="false"
      [_hideGlobalFilter]="true"
      [_hideAddHeaderBtn]="true"
      [_hideDeleteHeaderBtn]="true"
      [_dialogShowHeader]="false"
      [_cols]="crmHistoryColumns"
      _viewColIcon="_"
      _viewColIconName="remove_red_eye"
      [_saveFormState]="false"
      _stateKey="crmHistory"
      [_dialogClosable]="false"
      [_hideViewCol]="true"
    >
    </taku-form-list>
  </div>
</div>
