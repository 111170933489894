<div *ngIf="displayMode === NestedFormDisplayMode.EDITABLE_FORM" [formGroup]="_myForm">
  <div class="grid phone-mainWrapper">
    <div class="grid col">
      <div class="col-12 sm:col-4 mg:col-5 lg:col-6 xl:col-5 field-phone-type">
        <taku-dropdown
          [disabled]="disableForm"
          [formGroup]="_myForm"
          [options]="enum_phone_type"
          fieldName="phoneType"
          fieldCaption="Phone Type"
        >
        </taku-dropdown>
      </div>

      <div class="col-12 sm:col-8 mg:col-7 lg:col-6 xl:col-7 field-country">
        <taku-country
          [formGroup]="_myForm"
          fieldName="countryIsoCode"
          fieldCaption="Country"
          [hideSelectedItemFlag]="false"
          [hideSelectedItemLabel]="false"
          [disabled]="disableForm"
        ></taku-country>
      </div>

      <div class="field-phone" [ngClass]="phoneTypeCtrl.value === 'Work' ? 'col-8' : 'col-12'">
        <taku-phone
          *ngIf="countryIsoCodeCtrl.value"
          [formGroup]="_myForm"
          fieldName="tel"
          fieldCaption="Telephone"
          [alpha2]="countryIsoCodeCtrl.value"
          [disabled]="disableForm"
        ></taku-phone>
      </div>

      <div class="col-4 field-extension" *ngIf="phoneTypeCtrl.value === 'Work'">
        <taku-input
          [disabled]="disableForm"
          *ngIf="countryIsoCodeCtrl.value"
          inputMask="ext. ?99999"
          slotChar=" "
          [formGroup]="_myForm"
          fieldName="ext"
          fieldCaption="Extension"
        >
        </taku-input>
      </div>
    </div>

    <div *ngIf="showPhoneCopyControl" class="col-fixed copy-icon-container">
      <taku-action-button [action]="ACTION_TYPE.COPY" (clickAction)="copyPhoneNumberToClipboard()"></taku-action-button>
    </div>
  </div>
</div>

<ng-template [ngIf]="displayMode === NestedFormDisplayMode.TABULAR_DATA">
  <td class="phone-type-col">
    <span class="p-column-title">Type:</span>
    {{phoneTypeCtrl.value}}
  </td>

  <td class="phone-country-col">
    <span class="p-column-title">Country:</span>
    {{countryName}}
  </td>

  <td class="phone-number-col">
    <span class="p-column-title">Telephone #:</span>
    {{telephoneCtrl.value}}
  </td>

  <td class="phone-ext-col">
    <span class="p-column-title">Ext. #:</span>
    {{phoneExtCtrl.value}}
  </td>
</ng-template>
