<taku-form-header
  [toolbarTitle]="formlistTitle"
  displayMode="inline"
  [showActionBtns]="false"
  [showBackBtn]="false"
></taku-form-header>
<div class="col-12 actionsBtn-wrapper">
  <button *ngIf="!isAdminMode()" pButton label="New" (click)="addNewDoc()"></button>
  <button pButton label="Void" (click)="voidSelectedDocs()"></button>
  <button pButton label="Delete" (click)="deleteSelectedDocs()"></button>
  <button pButton label="Confirm" (click)="confirmSelectedDocs()"></button>
</div>

<div class="col-12">
  <taku-form-list
    #formList
    [_model]="_modelName"
    [_detailMode]="'w'"
    _scrollHeight="calc(100vh - 27rem)"
    [_filter]="_formFilters"
    [_isEditable]="true"
    [_cols]="stockReceivingCols"
    [_title]="formlistTitle"
    [_defaultRowValues]="_defaultRowValues"
    [_showHeader]="true"
    [_hideAddLineHeaderBtn]="true"
    [_hideSavingCtrls]="true"
    [_showCtrlsInEditMode]="true"
    [_detailViewRoute]="_detailViewRoute"
    [_detailQueryParams]="{ model: 'inventoryDoc' }"
    [_hideClone]="true"
    [_hideFilterControls]="false"
    [_hideDeleteHeaderBtn]="true"
    [_hideAddHeaderBtn]="true"
    [_hideGlobalFilter]="true"
    [_hideOptionsHeaderBtn]="true"
  >
  </taku-form-list>
</div>
