/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { SaleDocFormat } from "../sale-doc-format/sale-doc-format";
import { Store } from "../store/store";

export class SalesFormatsSetting {
  id: number;
  cashoutPrefix: string;
  cashoutNoOfDigits: number;
  cashoutStartingNumber: number;
  cashoutSuffix: string;
  commitmentPrefix: string;
  commitmentNoOfDigits: number;
  commitmentStartingNumber: number;
  commitmentSuffix: string;
  saleDocFormats: SaleDocFormat[];
  storeId: number;
  store: Store;

  constructor(store?: Store) {
    this.id = 0;
    this.cashoutPrefix = "";
    this.cashoutNoOfDigits = 5;
    this.cashoutStartingNumber = 1;
    this.cashoutSuffix = "";
    this.commitmentPrefix = "";
    this.commitmentNoOfDigits = 5;
    this.commitmentStartingNumber = 1;
    this.commitmentSuffix = "";
    this.saleDocFormats = [];
    this.storeId = store ? store.id : 0;
    this.store = store || null;

    // TODO: add more sale formats later
    // this.saleDocFormats = [
    //     {id: 0, docType: DocType.sales_invoice, prefix: '', startingNumber: 1000, suffix: '', noOfDigits: 5},
    //     {id: 0, docType: DocType.sales_return, prefix: '', startingNumber: 1, suffix: '', noOfDigits: 5}
    // ];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
