/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { DBService } from "../../../shared/services/db.service";
import { FormListModel } from "../../../form-list/form-list/form-list-model.interface";
import { ValidatorFn } from "@angular/forms";
import { DataType, FilterType, Col } from "../../../form-list/form-list/form-list";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class RolesService extends DBService implements FormListModel {
  _disableCloning = true;

  getValidationRules(): { [key: string]: ValidatorFn[] | {} } {
    return {};
  }

  //This method is used to sync roles when new access rights are created.
  syncRoles(): Observable<unknown> {
    const endPoint = this.webUrl + "syncRoles";
    return this._postRequest(endPoint, {});
  }

  getFormListColumns(): Col[] {
    return [
      {
        field: "isActive",
        header: "Active",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.checkbox,
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.enum,
      },
      {
        field: "roleName",
        header: "Role Name",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 350,
      },
    ];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
