<a
  *ngIf="saleDocTenders?.length"
  href="javascript://"
  class="material-icons md-18 -my-1 ml-2"
  (click)="tenderDetailsOverlay.toggle($event)"
>
  info
</a>
<p-overlayPanel #tenderDetailsOverlay appendTo="body">
  <div class="tenderDetails-overlay">
    <taku-form-header
      class="secondary"
      toolbarTitle="Pay Type Details"
      displayMode="inline"
      [showActionBtns]="false"
      [showBackBtn]="false"
    >
      <button
        pButton
        type="button"
        class="close-button p-button-rounded"
        (click)="tenderDetailsOverlay.hide()"
        icon="pi pi-times"
      >
      </button>
    </taku-form-header>
    <taku-pay-type-details [saleDocTenders]="saleDocTenders"></taku-pay-type-details>
  </div>
</p-overlayPanel>
