<div class="grid" [formGroup]="saleForm">
  <div class="col-12 p-fluid invoiceSummary-wrapper">
    <button
      pButton
      class="p-button-primary-dark"
      label="Invoice Summary"
      [disabled]="isTenderScreenOpened"
      (click)="summaryOpened.emit($event)"
    ></button>
  </div>

  <!-- <div class="col-3 p-fluid noReceipt-wrapper pt-0">
      <button pButton type="button" label="No Receipt" (click)="onNoReceiptPressed()"></button>
    </div> -->

  <div class="col-4 p-fluid searchReceipt-wrapper pt-0">
    <button pButton type="button" label="Search" (click)="searchReceiptsOpened.emit($event)"></button>
  </div>

  <div class="col-8 p-fluid invoiceTotal-wrapper pt-0">
    <button
      pButton
      class="p-button-returns-custom"
      (click)="onPayBtnPressed()"
      [disabled]="saleDocLines.length == 0 || isTenderScreenOpened"
    >
      <div class="line">
        <div class="col1">
          <i class="icon material-icons">remove_shopping_cart</i>
        </div>
        <div fittext [activateOnResize]="true" [maxFontSize]="40" fontUnit="pt" class="col2">
          <span>{{ invoiceTotal | currency : "CAD" : "symbol-narrow" : "1.2" | formatNegativeNumber }}</span>
        </div>
      </div>
    </button>
  </div>
</div>
