<div class="col-12">
  <taku-form-dialoge-header
    [pristine]="_myForm.pristine"
    [valid]="_myForm.valid"
    (backClicked)="goBack()"
    (saveClicked)="onSave()"
    (revertClicked)="onRevert()"
    (cancelClicked)="closeSidebar()"
    toolbarTitle="BACK OFFICE DETAILS"
    iconStyleClass="ui-icon-store"
    [isSaving]="_isSaving"
  ></taku-form-dialoge-header>

  <div [formGroup]="_myForm" class="grid">
    <div class="lg:col-4 col-12">
      <p-panel>
        <ng-template pTemplate="header">
          <h3>Basic Information</h3>

          <taku-checkbox fieldCaption="ACTIVE" [formGroup]="_myForm" fieldName="isActive"></taku-checkbox>
        </ng-template>

        <div class="col-12">
          <taku-input [formGroup]="_myForm" fieldName="backOfficeName" fieldCaption="Back Office Name"></taku-input>
        </div>

        <div class="col-12">
          <taku-input [formGroup]="_myForm" fieldName="backOfficeID" fieldCaption="Back Office #"></taku-input>
        </div>

        <div class="col-12 drop-container">
          <taku-time-zone-autocomplete-input [formGroup]="_myForm" fieldName="backOfficeTimeZone" [isDropDown]="true">
          </taku-time-zone-autocomplete-input>
        </div>

        <div class="drop-container col-12">
          <taku-dropdown [formGroup]="_myForm" [options]="lookup_zones | async" fieldName="zoneId" fieldCaption="Zone">
          </taku-dropdown>
        </div>

        <hr class="fields-divider" />

        <div class="single-picture-uploader">
          <div class="col-12">
            <strong>Back Office Logo</strong>
          </div>

          <div class="col-12">
            <p-fileUpload
              #fileUploader
              name="image"
              auto="true"
              accept="image/*"
              customUpload="true"
              (uploadHandler)="myUploader($event)"
            ></p-fileUpload>
          </div>

          <div class="col-12 picture-container" *ngIf="_myForm.value.logoUrl">
            <i class="material-icons clear-field" (click)="clearImage()">close</i>
            <img [attr.src]="_myForm.value.logoUrl" />
          </div>
        </div>
      </p-panel>
    </div>

    <div class="lg:col-8 col-12">
      <p-panel styleClass="panel-nopad">
        <ng-template pTemplate="header">
          <h3>Additional Information</h3>
        </ng-template>

        <div class="col-12">
          <div class="col-12 address-mainCaption">
            <h3>Back Office Address</h3>
          </div>

          <div class="col-12">
            <taku-address [formGroup]="_myForm.get('address')"></taku-address>
          </div>
        </div>
      </p-panel>
    </div>
  </div>
</div>
