<div class="grid topWrapper">
  <taku-form-header
    [formGroup]="_myForm"
    (backClicked)="onClose()"
    (saveClicked)="onSave()"
    (revertClicked)="onRevert()"
    [insideDialog]="true"
    (newClicked)="onNew()"
    (deleteClicked)="onDelete()"
    (cloneClicked)="onClone()"
    [toolbarTitle]="_toolbarTitle"
    displayMode="inline"
    customContentPos="left"
    [showSecondaryBtns]="false"
  >
  </taku-form-header>

  <form [formGroup]="_myForm" class="col-12" (ngSubmit)="onSubmit()">
    <div class="col-12 taku-listable-container userRoles-wrapper" formArrayName="userRoles">
      <div class="col-12 notes-wrapper">
        TAKU allows different roles to be assigned to a single user for different stores. Every user can only have 1
        role per store. <br />
        To assign a role to a user, select the store from <strong>Choose Stores</strong> below and then select the
        <strong>Role</strong>. <br />
        By default, a Store is not visible to a user until a store Role is assigned to that user.
      </div>

      <div class="col-12 add-button-container">
        <a class="add-button" (click)="addUserRole()">
          <label>Add New</label>
          <i class="material-icons">add_circle_outline</i>
        </a>
      </div>

      <div
        class="col-12 taku-listable-controlset"
        *ngFor="let userRoleForm of _myForm.controls['userRoles']['controls']; let i = index"
      >
        <taku-user-role
          [formGroup]="userRoleForm"
          [lookup_roles]="lookup_roles[i]"
          [lookup_stores]="lookup_stores[i]"
          [lookup_backOffices]="lookup_back_offices[i]"
        ></taku-user-role>
        <div class="col-1 remove-button-container">
          <a (click)="removeUserRole(i)">
            <i class="material-icons remove-button">remove_circle_outline</i>
          </a>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- <div class="col-12">
  {{ _myForm.value | json }}
</div> -->
