<taku-form-header
  [displayMode]="_isDialog ? 'inline' : null"
  [formGroup]="_myForm"
  (backClicked)="goBack()"
  (saveClicked)="onSave()"
  (revertClicked)="onRevert()"
  (newClicked)="onNew()"
  (deleteClicked)="onDelete()"
  (cloneClicked)="onClone()"
  [toolbarTitle]="pageTitle"
  [insideDialog]="_isDialog"
  [isSaving]="_isSaving"
>
</taku-form-header>
<div class="col-12 pl-0 pr-0">
  <div [formGroup]="_myForm" class="grid terminal-topWrapper">
    <div class="col-12 lg:col-4 xl:col-3 md:col-6 terminalCredentials-wrapper">
      <p-panel>
        <ng-template pTemplate="header">
          <h2>Terminal Assignment</h2>
          <taku-checkbox [formGroup]="_myForm" fieldName="isActive" fieldCaption="Active"></taku-checkbox>
        </ng-template>

        <div class="grid">
          <div class="col-12 grid">
            <div class="col">
              <taku-input [formGroup]="_myForm" fieldName="terminalName" fieldCaption="Terminal Name"></taku-input>
            </div>
            <span class="align-self-center">
              <a href="javascript://" class="pi pi-info-circle" (click)="terminalIdPanel.toggle($event)">
                <p-overlayPanel #terminalIdPanel [showCloseIcon]="false" appendTo="body">
                  <div class="tooltip-styling">
                    <h5>NOTE:</h5>
                    <ul>
                      <li
                        >8-character alphanumeric ID. This is the ECR number of the particular payment terminal you are
                        pairing.</li
                      >
                    </ul>
                  </div>
                </p-overlayPanel>
              </a>
            </span>
          </div>
          <div class="col-12 grid">
            <div class="col">
              <taku-dropdown
                [formGroup]="_myForm"
                [options]="storeStations$ | async"
                fieldName="stationId"
                fieldCaption="Station ID/Name"
              ></taku-dropdown>
            </div>
            <span class="align-self-center">
              <a href="javascript://" class="pi pi-info-circle" (click)="stationIdPanel.toggle($event)">
                <p-overlayPanel #stationIdPanel [showCloseIcon]="false" appendTo="body">
                  <div class="tooltip-styling">
                    <h5>NOTE:</h5>
                    <ul>
                      <li
                        >Select the TAKU station you would like to pair your payment terminal with from the dropdown
                        menu.</li
                      >
                    </ul>
                  </div>
                </p-overlayPanel>
              </a>
            </span>
          </div>
          <div class="col-6 pairUnpair-btn-container">
            <!-- (click)="_myForm.get('isPaired').value? monerisService.apiTakuEconduitUnpairTerminal(_myForm.get('id').value) :monerisService.apiTakuEconduitPairTerminal(_myForm.get('id').value)"> -->
            <button
              *ngIf="!_myForm.get('isPaired').value"
              pButton
              pRipple
              type="button"
              [label]="_myForm.get('isPaired').value ? 'Unpair' : 'Pair'"
              class="p-button-raised p-button-text col-12"
              [disabled]="_myForm.dirty"
              (click)="
                _myForm.get('isPaired').value
                  ? unPairTerminal(_myForm.get('id').value)
                  : pairTerminal(_myForm.get('id').value)
              "
            >
            </button>
            <p-splitButton
              *ngIf="_myForm.get('isPaired').value"
              class="col-12"
              label="Unpair"
              (onClick)="unPairTerminal(_myForm.get('id').value)"
              [disabled]="_myForm.dirty"
              [model]="unpairItems"
              styleClass="p-button-raised p-button-secondary"
            >
            </p-splitButton>
          </div>
          <div class="col-6 initialize-btn-container">
            <button
              pButton
              pRipple
              type="button"
              [label]="'Initialize'"
              class="p-button-raised p-button-text col-12"
              [disabled]="_myForm.dirty || !_myForm.get('isPaired').value"
              (click)="initializeTerminal(_myForm.get('id').value)"
            ></button>
          </div>
        </div>
      </p-panel>
    </div>

    <div class="col-12 lg:col-4 xl:col-3 md:col-6 terminalCredentials-wrapper">
      <p-panel>
        <ng-template pTemplate="header">
          <h2>Terminal Credentials</h2>
        </ng-template>

        <div class="grid">
          <div class="col-12 grid">
            <div class="col">
              <taku-input
                [formGroup]="infoFormGroup"
                [readonly]="false"
                fieldName="merchantID"
                fieldCaption="Merchant ID"
              ></taku-input>
            </div>
            <span class="align-self-center">
              <a href="javascript://" class="pi pi-info-circle" (click)="merchantIDPanel.toggle($event)">
                <p-overlayPanel #merchantIDPanel [showCloseIcon]="false" appendTo="body">
                  <div class="tooltip-styling">
                    <h5>NOTE:</h5>
                    <ul>
                      <li
                        >50-character alphanumeric ID. This is the unique merchant ID provided by your payment processor
                        during account setup.</li
                      >
                    </ul>
                  </div>
                </p-overlayPanel>
              </a>
            </span>
          </div>

          <div class="col-12 grid">
            <div class="col">
              <taku-input
                [formGroup]="infoFormGroup"
                [readonly]="false"
                fieldName="terminalID"
                fieldCaption="Terminal ID"
              ></taku-input>
            </div>
            <span class="align-self-center">
              <a href="javascript://" class="pi pi-info-circle" (click)="terminalIDPanel.toggle($event)">
                <p-overlayPanel #terminalIDPanel [showCloseIcon]="false" appendTo="body">
                  <div class="tooltip-styling">
                    <h5>NOTE:</h5>
                    <ul>
                      <li
                        >8-character alphanumeric ID. This is the ECR number of the particular payment terminal you are
                        pairing.</li
                      >
                    </ul>
                  </div>
                </p-overlayPanel>
              </a>
            </span>
          </div>

          <div class="col-12 grid">
            <div class="col">
              <taku-input [formGroup]="infoFormGroup" [readonly]="false" fieldName="API_Token" fieldCaption="API Token">
              </taku-input>
            </div>
            <span class="align-self-center">
              <a href="javascript://" class="pi pi-info-circle" (click)="API_TokenPanel.toggle($event)">
                <p-overlayPanel #API_TokenPanel [showCloseIcon]="false" appendTo="body">
                  <div class="tooltip-styling">
                    <h5>NOTE:</h5>
                    <ul>
                      <li
                        >50-character alphanumeric token. This is the unique token assigned by your payment processor
                        during account activation.</li
                      >
                    </ul>
                  </div>
                </p-overlayPanel>
              </a>
            </span>
          </div>

          <div class="col-12 grid">
            <div class="col">
              <taku-input
                [formGroup]="infoFormGroup"
                [readonly]="false"
                fieldName="Pairing_Token"
                fieldCaption="Pairing Token"
              ></taku-input>
            </div>
            <span class="align-self-center">
              <a href="javascript://" class="pi pi-info-circle" (click)="Pairing_TokenPanel.toggle($event)">
                <p-overlayPanel #Pairing_TokenPanel [showCloseIcon]="false" appendTo="body">
                  <div class="tooltip-styling">
                    <h5>NOTE:</h5>
                    <ul>
                      <li>This value will be provided by the terminal during the pairing process.</li>
                    </ul>
                  </div>
                </p-overlayPanel>
              </a>
            </span>
          </div>
        </div>
      </p-panel>
    </div>

    <div class="col-12 lg:col-4 xl:col-3 md:col-6 terminalCredentials-wrapper">
      <p-panel>
        <ng-template pTemplate="header">
          <h2>Tokenization Settings</h2>
        </ng-template>

        <div class="grid">
          <div class="col-6">
            <taku-checkbox [formGroup]="infoFormGroup" fieldName="setTokenization" fieldCaption="Set Tokenization">
            </taku-checkbox>
          </div>
          <div class="col-6">
            <button
              pButton
              pRipple
              type="button"
              label="Set Tokenization"
              [disabled]="_myForm.dirty"
              class="p-button-raised p-button-text col-12"
              (click)="setTokenization(_myForm.get('id').value)"
            ></button>
          </div>
        </div>
      </p-panel>
    </div>

    <div class="col-12 lg:col-4 xl:col-3 md:col-6 terminalCredentials-wrapper">
      <p-panel>
        <ng-template pTemplate="header">
          <h2>Hardware Settings</h2>
        </ng-template>

        <div class="grid">
          <div class="col-6">
            <p>Screen Calibration</p>
          </div>
          <div class="col-6">
            <button
              pButton
              pRipple
              type="button"
              label="Calibrate"
              [disabled]="_myForm.dirty"
              class="p-button-raised p-button-text col-12"
              (click)="calibrate(_myForm.get('id').value)"
            ></button>
          </div>
          <div class="col-6">
            <taku-dropdown
              [formGroup]="infoFormGroup"
              [options]="enum_beep_volume"
              fieldName="beepVolume"
              fieldCaption="Set Beep Volume"
            ></taku-dropdown>
          </div>
          <div class="col-6">
            <button
              pButton
              pRipple
              type="button"
              label="Set Beep"
              class="p-button-raised p-button-text col-12"
              [disabled]="_myForm.dirty"
              (click)="setBeep(_myForm.get('id').value)"
            ></button>
          </div>
          <div class="col-6">
            <taku-dropdown
              [formGroup]="infoFormGroup"
              [options]="enum_moneris_language"
              fieldName="language"
              fieldCaption="Set Language"
            ></taku-dropdown>
          </div>
          <div class="col-6">
            <button
              pButton
              pRipple
              type="button"
              label="Set Language"
              class="p-button-raised p-button-text col-12"
              [disabled]="_myForm.dirty"
              (click)="setLanguage(_myForm.get('id').value)"
            ></button>
          </div>
          <div class="col-6">
            <p>PED Code Download</p>
          </div>
          <div class="col-6">
            <button
              pButton
              pRipple
              type="button"
              label="Download"
              class="p-button-raised p-button-text col-12"
              [disabled]="_myForm.dirty"
              (click)="pedCodeDownload(_myForm.get('id').value)"
              [loading]="_isPEDCodeDownloading"
            ></button>
          </div>
        </div>
      </p-panel>
    </div>

    <div class="col-12 lg:col-8 xl:col-12 terminalOptions-wrapper">
      <p-panel>
        <ng-template pTemplate="header">
          <h2>Admin Settings</h2>
        </ng-template>

        <div class="grid">
          <div class="col-12">
            <taku-checkbox [formGroup]="infoFormGroup" fieldName="setTip" fieldCaption="Set Tip"></taku-checkbox>
          </div>
          <div class="col-12" *ngIf="infoFormGroup.get('setTip').value">
            <div class="col-12">
              <span [formGroup]="infoFormGroup">
                <h5>Percentage</h5>
                <div class="col-12">
                  <taku-input [formGroup]="infoFormGroup" fieldCaption="First Option" fieldName="tipPercent1">
                  </taku-input>
                </div>

                <div class="col-12">
                  <taku-input [formGroup]="infoFormGroup" fieldCaption="Second Option" fieldName="tipPercent2">
                  </taku-input>
                </div>

                <div class="col-12">
                  <taku-input [formGroup]="infoFormGroup" fieldCaption="Third Option" fieldName="tipPercent3">
                  </taku-input>
                </div>
                <h5 class="mt-4">Custom Tip</h5>
                <div class="col-12">
                  <taku-checkbox
                    [formGroup]="infoFormGroup"
                    fieldName="customByPercentage"
                    fieldCaption="By Percentage"
                  >
                  </taku-checkbox>
                </div>
                <div class="col-12">
                  <taku-checkbox [formGroup]="infoFormGroup" fieldName="customByAmount" fieldCaption="By Amount">
                  </taku-checkbox>
                </div>
              </span>
            </div>
          </div>
          <div class="col-12">
            <taku-checkbox [formGroup]="infoFormGroup" fieldName="panMaskingSetting" fieldCaption="Pan Masking Setting">
            </taku-checkbox>
          </div>
        </div>
      </p-panel>
    </div>
  </div>
</div>

<p-dialog
  [(visible)]="_isPairingOrUnpairing || _isInitializing || _isCalibrating || _isPEDCodeDownloading"
  styleClass="terminalPairUnpairProgressDlg"
>
  <taku-progress-dialog></taku-progress-dialog>
</p-dialog>
