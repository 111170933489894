import { HttpClient } from "@angular/common/http";
import { EventEmitter, Inject, Injectable, LOCALE_ID, NgZone } from "@angular/core";
import * as _ from "lodash";
import { Observable } from "rxjs";
import { InventoryBarcodeService } from "src/app/core/inventory/inventory-barcode/InventoryBarcode.service";
import { InventoryStockService } from "src/app/core/inventory/inventory-stock/InventoryStock.service";
import { Inventory } from "src/app/core/inventory/inventory/inventory";
import { InventoryService } from "src/app/core/inventory/inventory/inventory.service";
import { BackendGMCService } from "src/app/core/settings/integration-settings/google-shopping/BackendGMCService";
import { GoogleContentAPIService } from "src/app/core/settings/integration-settings/google-shopping/GoogleContentAPIService";
import {
  Col,
  DataType,
  ExecuteEventDatatypeOptions,
  ExecuteEvent_DisplayMode,
  FilterType,
} from "src/app/form-list/form-list/form-list";
import { FormListModel } from "src/app/form-list/form-list/form-list-model.interface";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { CurrencyFormatterService } from "src/app/shared/services/currency-formatter.service";

@Injectable({
  providedIn: "root",
})
export class SaleDocListService extends InventoryService implements FormListModel {
  navigateInventory: EventEmitter<Inventory> = new EventEmitter();
  addToSaleDoc: EventEmitter<Inventory> = new EventEmitter();

  constructor(
    protected currencyService: CurrencyFormatterService,
    protected http: HttpClient,
    protected googleContentAPIService: GoogleContentAPIService,
    @Inject(LOCALE_ID) protected defaultLocale,
    protected authService: AuthService,
    protected appSettings: AppSettingsStorageService,
    protected zone: NgZone,
    public backendGMCService: BackendGMCService,
    public barcodeService: InventoryBarcodeService,
    public stockService: InventoryStockService,
    protected appSettingsStorageService: AppSettingsStorageService
  ) {
    super(
      currencyService,
      http,
      googleContentAPIService,
      defaultLocale,
      authService,
      appSettings,
      zone,
      backendGMCService,
      barcodeService,
      stockService,
      appSettingsStorageService
    );
  }

  getRows(
    _model: string,
    _filter?: string,
    _offset?: number,
    _limit?: number,
    _sortField = "module",
    _sortOrder = 1,
    extraParams = {},
    _orFilter?
  ): Observable<any> {
    _.defaults(extraParams, {
      stockId: this.appSettings.getStore().stockId,
      zoneId: this.appSettings.getStore().zoneId,
    });
    return super.getRows(_model, _filter, _offset, _limit, _sortField, _sortOrder, extraParams, _orFilter);
  }

  getinventorySKUCaption(rowData: Inventory, _service: SaleDocListService) {
    return rowData ? rowData.sku : null;
  }

  _disableCloning = true;
  getFormListColumns(): Col[] {
    const cols = super.getFormListColumns();
    cols.find((row) => row.field === "isEnabled").visible = false;
    cols.find((row) => row.field === "isEnabled").frozen = false;
    cols.find((row) => row.field === "sku").frozen = false;
    cols.find((row) => row.field === "sku").dataType = DataType.execute_event;
    cols.find((row) => row.field === "sku").dataTypeOptions = [
      new ExecuteEventDatatypeOptions({
        displayMode: ExecuteEvent_DisplayMode.LINK,
        event: this.navigateInventory,
        label: this.getinventorySKUCaption,
        enabledOnEditMode: false,
        styleClass: "",
      }),
    ];
    cols.find((row) => row.field === "sku").colWidth = 110;
    cols.find((row) => row.filterField === "parentInventoryId").colWidth = 60;
    cols.find((row) => row.field === "uOfMeasureId").visible = false;
    // cols.find((row) => row.field === "inventoryBarcodes").visible = false;
    cols.find((row) => row.field === "msrpPrice").visible = false;

    cols.splice(0, 0, {
      field: "addToSale",
      header: "Add to Sale",
      headerHTML: "Add to<br>Sale",
      visible: true,
      readonly: false,
      frozen: true,
      dataType: DataType.execute_event,
      dataOptions: [],
      dataTypeOptions: [
        new ExecuteEventDatatypeOptions({
          displayMode: ExecuteEvent_DisplayMode.BUTTON,
          event: this.addToSaleDoc,
          icon: "pi pi-plus",
          enabledOnEditMode: false,
          styleClass: "",
        }),
      ],
      filterType: FilterType.none,
      colWidth: 80,
      isNotSortable: true,
    });

    return cols;
  }
}
