/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { HttpClient } from "@angular/common/http";
import { Injectable, EventEmitter } from "@angular/core";
import { ConfirmationService, MenuItem, MessageService, SelectItem } from "primeng/api";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { Observable, Subscription } from "rxjs";
import { tap } from "rxjs/operators";
import {
  Col,
  DataType,
  ExecuteEventDatatypeOptions,
  ExecuteEvent_DisplayMode,
  FilterType,
} from "src/app/form-list/form-list/form-list";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { DBService } from "src/app/shared/services/db.service";
import { LocationService } from "src/app/shared/services/location.service";
import { PrintingFactoryService, PrintingMgr } from "src/app/shared/services/printing-service.service";
import { DeliveryStatus, FulfillmentStatus, SaleDoc } from "../../document/sale-document/sale-doc/sale-doc";
import { SaledocRevisitPreviewComponent } from "../../document/sale-document/saledoc-revisit-preview/saledoc-revisit-preview.component";
import { SaleChannelOrderPreviewComponent } from "../sale-channel-order-preview/sale-channel-order-preview.component";
import {
  DeliveryMethod,
  SaleChannelDeliveryStatus,
  SaleChannelFulfillmentStatus,
  OrderStatus,
  SaleChannelPaymentStatus,
  SaleChannelOrder,
} from "./sale-channel-order";
import { ValidatorFn } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class SaleChannelOrdersService extends DBService {
  protected lookupStores: SelectItem[];
  private postOrder: EventEmitter<SaleChannelOrder> = new EventEmitter();

  voidDocumentEvent: EventEmitter<SaleChannelOrder> = new EventEmitter();
  printDocumentEvent: EventEmitter<SaleChannelOrder> = new EventEmitter();

  printMgr: PrintingMgr;
  subsList: Subscription[] = [];
  enum_delivery_methods = this.enumSelectOptions(DeliveryMethod);
  enum_payment_statuses = this.enumSelectOptions(SaleChannelPaymentStatus, { useNullAsValue: false });
  enum_order_statuses = this.enumSelectOptions(OrderStatus);
  enum_fulfillment_statuses = this.enumSelectOptions(SaleChannelFulfillmentStatus, { useNullAsValue: false });
  enum_delivery_statuses = this.enumSelectOptions(SaleChannelDeliveryStatus, { useNullAsValue: false });
  get_enumCountries(): SelectItem[] {
    return this.locationService.lookupCountryOptions_full();
  }

  constructor(
    protected http: HttpClient,
    protected authService: AuthService,
    private dialogService: DialogService,
    public ref: DynamicDialogRef,
    private appSettingsService: AppSettingsStorageService,
    protected confirmationService: ConfirmationService,
    private locationService: LocationService,
    private printingMgrFactory: PrintingFactoryService,
    protected messageService: MessageService
  ) {
    super(http, authService);
    this.subsList.push(
      this.voidDocumentEvent.subscribe((saleChannelOrder: SaleChannelOrder) => {
        // this.openVoidDocDialog(saleDoc)
      })
    );
    this.postOrder.subscribe((saleChannelOrder: SaleChannelOrder) => {
      if (saleChannelOrder.saleDocId) {
        this.getRow("saleDoc", saleChannelOrder.saleDocId).subscribe((_saleDoc) => {
          this.ref = this.dialogService.open(SaledocRevisitPreviewComponent, {
            data: {
              _object: _saleDoc,
              _saleChannelOrder: saleChannelOrder,
            },
            width: "90%",
            height: "90%",
            closable: false,
            showHeader: false,
          });
        });
      } else {
        this.ref = this.dialogService.open(SaleChannelOrderPreviewComponent, {
          data: {
            _saleChannelOrder: saleChannelOrder,
          },
          width: "850px",
          height: "650px",
          closable: false,
        });
        this.ref.onClose.subscribe((_saleChannelOrder: SaleChannelOrder) => {
          saleChannelOrder = _saleChannelOrder;
          if (saleChannelOrder && saleChannelOrder.saleDocId) {
            this.getRow("saleDoc", saleChannelOrder.saleDocId).subscribe((_saleDoc) => {
              setTimeout(() => {
                this.ref = this.dialogService.open(SaledocRevisitPreviewComponent, {
                  data: {
                    _object: _saleDoc,
                    _saleChannelOrder: saleChannelOrder,
                  },
                  width: "850px",
                  height: "650px",
                  closable: false,
                });
              }, 200);
            });
          }
        });
      }
    });
  }

  lookup_stores(): Observable<SelectItem[]> {
    //get all active stores that are part of an active zone
    const activeFilter = {
      isActive: { matchMode: "equals", value: true },
      "zone.isActive": { matchMode: "equals", value: true },
    };
    return this.lookupSelectOptions("store", "storeName", {
      lookupFilter: JSON.stringify(activeFilter),
      enableFieldName: "isActive",
      emptyRowCaption: "All Stores",
      emptyRowValue: -1,
    });
  }

  lookup_sale_channels(): Observable<SelectItem[]> {
    //get all active stores that are part of an active zone
    const activeFilter = {
      isConnected: { matchMode: "equals", value: true },
      // 'zone.isActive': { matchMode: 'equals', value: true },
    };
    return this.lookupSelectOptions("saleChannel", "channelName", {
      lookupFilter: JSON.stringify(activeFilter),
      enableFieldName: "isConnected",
      emptyRowCaption: "All Sale Channels",
      emptyRowValue: -1,
    });
  }

  addDefaultValueToLookup(items: SelectItem[], defaultMessage: string) {
    items.unshift({
      label: defaultMessage,
      value: 0,
    });

    if (items.length >= 2 && items[1].label == "") {
      //if the second item is the default empty option. We want the empty option to be first.
      //so we swap the first and second items.
      const temp = items[1];
      items[1] = items[0];
      items[0] = temp;
    }
    return items;
  }

  onFulfillmentStatusChanged(rowData, _orgObjects) {
    const orgObject = _orgObjects.find((row) => row.id === rowData.id);
    if (orgObject) {
      if (rowData.fulfillmentStatus === SaleChannelFulfillmentStatus.completed) {
        this.confirmationService.confirm({
          header: "Confirmation",
          message:
            "Once this order is marked as fulfilled, it will not be possible to make further edits. Are you sure you want to proceed?",
          acceptLabel: "Yes",
          rejectLabel: "No",
          rejectVisible: true,
          acceptVisible: true,
          rejectButtonStyleClass: "p-button-link",
          accept: () => {
            rowData.deliveryStatus = SaleChannelDeliveryStatus.readyForPickup;
            rowData.saleDoc.deliveryStatus = DeliveryStatus.readyForPickup;
          },
          reject: () => {
            rowData.fulfillmentStatus = orgObject.fulfillmentStatus;
            rowData.deliveryStatus = orgObject.deliveryStatus;
            rowData.saleDoc.fulfillmentStatus = orgObject.saledoc.fulfillmentStatus;
            rowData.saleDoc.deliveryStatus = orgObject.saledoc.deliveryStatus;
          },
        });
      } else if (rowData.fulfillmentStatus === SaleChannelFulfillmentStatus.backToStock) {
        rowData.deliveryStatus = SaleChannelDeliveryStatus.returned;
        rowData.saleDoc.deliveryStatus = DeliveryStatus.returned;
      } else if (rowData.fulfillmentStatus === SaleChannelFulfillmentStatus.voided) {
        rowData.deliveryStatus = SaleChannelDeliveryStatus.voided;
        rowData.saleDoc.deliveryStatus = DeliveryStatus.voided;
      } else if (
        rowData.fulfillmentStatus === SaleChannelFulfillmentStatus.unfulfilled ||
        rowData.fulfillmentStatus === SaleChannelFulfillmentStatus.partlyFulfilled ||
        rowData.fulfillmentStatus === SaleChannelFulfillmentStatus.processing
      ) {
        rowData.deliveryStatus = SaleChannelDeliveryStatus.pending;
        rowData.saleDoc.deliveryStatus = DeliveryStatus.pending;
      }
    }
  }

  onPaymentStatusChanged(rowData, _orgObjects) {
    const orgObject = _orgObjects.find((row) => row.id === rowData.id);
    // and user is not admin
    if (
      orgObject &&
      rowData.paymentStatus !== orgObject.paymentStatus &&
      rowData.fulfillmentStatus === SaleChannelFulfillmentStatus.completed &&
      !this.appSettingsService.getUser().isAdmin
    ) {
      rowData.paymentStatus = orgObject.paymentStatus;
      this.messageService.add({
        severity: "error",
        summary: "Order is fulfilled",
        detail: "After fulfilling order, payment status can not be changed except by admin",
      });
    }
  }

  onFulfillmentStoreChanged(rowData, _orgObjects) {
    const orgObject = _orgObjects.find((row) => row.id === rowData.id);
    if (
      orgObject &&
      rowData.storeId !== orgObject.storeId &&
      rowData.fulfillmentStatus === SaleChannelFulfillmentStatus.completed
    ) {
      rowData.storeId = orgObject.storeId;
      this.messageService.add({
        severity: "error",
        summary: "Order is fulfilled",
        detail: "After fulfilling order, store can not be changed",
      });
    }
  }

  onDeliveryMethodChanged(rowData, _orgObjects) {
    const orgObject = _orgObjects.find((row) => row.id === rowData.id);
    if (orgObject) {
      rowData.saleDoc.deliveryMethod = rowData.deliveryMethod;
    }
  }

  editSaleChannelOrder(_salechannelOrder: SaleChannelOrder, body: any): Observable<SaleChannelOrder> {
    return this._putRequest<any>(
      this.webUrl +
        "TAKUeCommerceOrderInvoice/" +
        _salechannelOrder.saleChannelId +
        "/" +
        _salechannelOrder.channelOrderId,
      body
    );
  }

  acceptSaleChannelOrder(_salechannelOrder: SaleChannelOrder): Observable<SaleDoc> {
    return this._postRequest<any>(
      this.webUrl +
        "TAKUeCommerceAcceptOrder/" +
        _salechannelOrder.saleChannelId +
        "/" +
        _salechannelOrder.channelOrderId,
      {
        stationId: this.appSettingsService.getStationId(),
        storeId: _salechannelOrder.storeId,
        cashoutId: this.appSettingsService.getCashoutId(),
        fiscalYearId: this.appSettingsService.getFiscalYearId(),
      }
    );
  }

  getValidationRules(): {
    [key: string]: {} | ValidatorFn[];
  } {
    return {
      // channelType: [Validators.required],
      // channelName: [Validators.required],
      // storeId: [Validators.required],
    };
  }

  getLookupStores(): Observable<SelectItem[]> {
    return this.lookupSelectOptions("store", "storeName", {
      enableFieldName: "isActive",
    }).pipe(tap((stores: SelectItem[]) => (this.lookupStores = stores)));
  }

  isVoidLinkDisabled(rowData: SaleChannelOrder) {
    return true; //rowData.doc.state !== DocState.finalized;
  }

  getTakuDocLinkCaption(rowData: SaleChannelOrder) {
    return rowData.saleDoc ? rowData.saleDoc.doc.docNo : "View";
  }

  isPrintLinkDisabled(rowData: SaleChannelOrder) {
    return !rowData.saleDoc;
  }

  formlist_lookup_delivery_statuses(rowData): SelectItem[] {
    return this.enum_delivery_statuses.map((row) => {
      if (
        rowData.fulfillmentStatus == SaleChannelFulfillmentStatus.completed &&
        [SaleChannelDeliveryStatus.returned, SaleChannelDeliveryStatus.voided].includes(row.value)
      ) {
        row.disabled = true;
        row.icon = "pi pi-lock";
      } else {
        row.disabled = false;
        row.icon = "";
      }
      return row;
    });
  }

  getFormListColumns(
    params?: { printItems: (rowData: SaleChannelOrder) => MenuItem[] }
    // printDocumentEvent: EventEmitter<SaleChannelOrder>
    // isAdmin: boolean,
    // clickManageChannelEvent?: EventEmitter<any>
  ): Col[] {
    return <Col[]>[
      {
        field: "saleDoc.doc.docNo",
        header: "Doc #",
        visible: true,
        readonly: true,
        frozen: true,
        dataType: DataType.execute_event,
        dataOptions: [],
        dataTypeOptions: [
          new ExecuteEventDatatypeOptions({
            displayMode: ExecuteEvent_DisplayMode.LINK,
            label: this.getTakuDocLinkCaption,
            event: this.postOrder,
            enabledOnEditMode: false,
            styleClass: "",
          }),
        ],
        filterType: FilterType.contains,
        isNotSortable: false,
        colWidth: 100,
      },
      // { field: '', header: 'Packing List', visible: true, readonly: true, frozen: true, dataType: DataType.execute_event, dataTypeOptions: [new ExecuteEventDatatypeOptions({ label: "Print", event: printDocumentEvent, displayMode: ExecuteEvent_DisplayMode.LINK, fnIsRowDisabled: this.isPrintLinkDisabled })], dataOptions: [], filterType: FilterType.none, isNotSortable: true, colWidth: 120 },
      {
        field: "printPackingList",
        header: "Packing List",
        visible: true,
        readonly: true,
        frozen: true,
        dataType: DataType.execute_event,
        dataOptions: [],
        dataTypeOptions: [
          new ExecuteEventDatatypeOptions({
            icon: "pi pi-print",
            event: null,
            displayMode: ExecuteEvent_DisplayMode.SPLITBUTTON,
            fnIsRowDisabled: this.isPrintLinkDisabled,
            items: params.printItems,
            styleClass: "",
          }),
        ],
        filterType: FilterType.none,
        isNotSortable: true,
        colWidth: 120,
      },
      {
        field: "orderStatus",
        header: "Order Status",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enum_order_statuses,
        filterType: FilterType.enum,
        isNotSortable: false,
      },
      {
        field: "docNo",
        header: "Channel Ref #",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        isNotSortable: false,
      },
      {
        field: "docDate",
        header: "Date",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        isNotSortable: false,
      },
      {
        field: "shipTo.attnFirstName",
        header: "Customer",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        isNotSortable: false,
      },
      {
        field: "subTotalCurrencyFormat",
        header: "Total",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
        isNotSortable: false,
      },
      {
        field: "docTime",
        header: "Time",
        visible: false,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        isNotSortable: true,
      },
      {
        field: "saleChannel.channelName",
        header: "Channel Name",
        visible: false,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        isNotSortable: false,
      },
      {
        field: "paymentStatus",
        header: "Payment Status",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enum_payment_statuses,
        filterType: FilterType.enum,
        isNotSortable: false,
        onModelChange: this.onPaymentStatusChanged.bind(this),
        classObj: "status-margin",
      },
      {
        field: "fulfillmentStatus",
        header: "Fulfillment Status",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enum_fulfillment_statuses,
        filterType: FilterType.enum,
        isNotSortable: false,
        onModelChange: this.onFulfillmentStatusChanged.bind(this),
        classObj: "status-margin",
      },
      {
        field: "itemsCount",
        header: "# of Items",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
        isNotSortable: false,
        colWidth: 120,
      },
      {
        field: "storeId",
        header: "Fulfillment Store",
        visible: false,
        readonly: false,
        frozen: false,
        dataType: DataType.lookup,
        dataOptions: this.getLookupStores(),
        filterType: FilterType.lookup,
        sortFields: ["store.storeName"],
        onModelChange: this.onFulfillmentStoreChanged.bind(this),
      },
      {
        field: "shipTo.addressPhone.tel",
        header: "Customer Phone",
        visible: false,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        isNotSortable: false,
      },
      {
        field: "email",
        header: "Customer Email",
        visible: false,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        isNotSortable: false,
      },
      {
        field: "shipTo.line1",
        header: "Customer Address",
        visible: false,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        isNotSortable: false,
      },
      {
        field: "shipTo.city",
        header: "Customer City",
        visible: false,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        isNotSortable: false,
      },
      {
        field: "shipTo.countryIsoCode",
        header: "Customer Country",
        visible: false,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.get_enumCountries(),
        filterType: FilterType.enum,
        isNotSortable: false,
      },
      {
        field: "shipTo.postalCode",
        header: "Customer Postal Code",
        visible: false,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        isNotSortable: false,
      },
      {
        field: "deliveryStatus",
        header: "Delivery Status",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.formlist_lookup_delivery_statuses.bind(this),
        filterType: FilterType.enum,
        isNotSortable: false,
        classObj: "status-margin",
        filterOptions: this.enum_delivery_statuses,
      },
      {
        field: "deliveryMethod",
        header: "Delivery Method",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enum_delivery_methods,
        filterType: FilterType.enum,
        isNotSortable: false,
        onModelChange: this.onDeliveryMethodChanged.bind(this),
      },
      {
        field: "deliveryMethodName",
        header: "Delivery Method Name",
        visible: false,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        isNotSortable: false,
      },
      {
        field: "pickupDate",
        header: "Outgoing Date",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.date_date_time,
        dataOptions: [],
        filterType: FilterType.contains,
        isNotSortable: false,
      },
      {
        field: "notes",
        header: "Notes",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        isNotSortable: false,
      },
    ].filter(Boolean);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
