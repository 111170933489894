/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { NestedFormComponent } from "../../../forms/nested-form/nested-form.component";
import { CashoutClosingBreakDown } from "../cashout/cashout";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { CommonValidators } from "../../../shared/validators/CommonValidators";
import { Tender_Type } from "../../settings/business-settings/tender-type/tender-type";
import { WebHelpers } from "src/app/utility/WebHelpers";

@Component({
  selector: "taku-cashout-closing-breakdown",
  templateUrl: "./cashout-closing-breakdown.component.html",
  styleUrls: ["./cashout-closing-breakdown.component.scss"],
})
export class CashoutClosingBreakdownComponent extends NestedFormComponent implements OnChanges {
  @Input() currencyISOCode: string;
  @Input() isEditable = true;
  @Input() index: number;
  @Input() showSystemValue: boolean;
  @Input() showOverUnderValue: boolean;
  @Output() onShowDetails: EventEmitter<number> = new EventEmitter();

  static setArray(fb: UntypedFormBuilder, closingBreakdowns: CashoutClosingBreakDown[]) {
    const formArray = fb.array([]);
    closingBreakdowns.forEach((closingBreakdown) =>
      formArray.push(CashoutClosingBreakdownComponent.set(fb, closingBreakdown))
    );

    return formArray;
  }

  static set(fb: UntypedFormBuilder, closingBreakdown: CashoutClosingBreakDown) {
    return fb.group(closingBreakdown);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes._myForm) {
      this.subsList.push(
        this.actualAmountCtrl.valueChanges.subscribe((newAmount: string) => {
          if (newAmount && typeof newAmount.valueOf() === "string") {
            // Don't allow negatives for cash
            const regexpInvalidChars = this.isCash ? /[^0-9.]/g : /[^0-9.-]/g;
            newAmount = newAmount.replace(regexpInvalidChars, "");
            this.actualAmountCtrl.setValue(newAmount, { emitEvent: false });
          }
        })
      );
    }
  }

  initValidation() {
    this._validation = {
      // Don't allow negatives for cash
      // actualAmount: [Validators.required, this.isCash ? CommonValidators.digits() : CommonValidators.number()] // allows negative values
      actualAmount: [Validators.required, CommonValidators.number()], // allows negative values
    };
  }

  get isCash() {
    return this.tenderTypeCtrl?.value === Tender_Type.Cash;
  }

  get isIntegrated() {
    return this._myForm.get("isIntegrated").value === true;
  }

  //get isIntegratedPayment(){
  //  return this._myForm.get('isIntegratedPayment').value === true;
  //}

  get actualAmountCtrl() {
    return this._myForm.get("actualAmount");
  }

  get systemAmount(): number {
    return parseFloat(this._myForm.get("systemTotal") ? this._myForm.get("systemTotal").value : 0);
  }

  get actualAmount(): number {
    return parseFloat(this.actualAmountCtrl.value);
  }

  get overUnderAmount(): number {
    return this.actualAmount - this.systemAmount;
  }

  get tenderTypeCtrl() {
    return this._myForm.get("tenderTypeType");
  }

  onShowDetailsPressed() {
    this.onShowDetails.emit(this._myForm.get("tenderTypeId").value);
  }

  isMobile(): boolean {
    return WebHelpers.isMobileScreen();
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
