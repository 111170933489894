/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component } from "@angular/core";
import { OverlayModalName } from "../../sale-document/generic-sale-doc.component";
import { ReturnInvoiceDocBaseComponent } from "../return-invoice-doc-base/return-invoice-doc-base.component";
import { InvoiceKeyboardActionsService } from "../../sale-document/sale-invoice-doc-base/invoice-keyboard-actions.service";
import { ReturnsSideDialogName, ReturnsSideDialogNameDesktop } from "../../types/invoice-enums.type";

@Component({
  selector: "taku-return-invoice-doc-desktop",
  templateUrl: "./return-invoice-doc-desktop.component.html",
  styleUrls: ["./return-invoice-doc-desktop.component.scss"],
  providers: [InvoiceKeyboardActionsService],
})
export class ReturnInvoiceDocDesktopComponent extends ReturnInvoiceDocBaseComponent {
  SideDialogNameDesktop = ReturnsSideDialogName;

  restoreActiveOverlay(activeOverlay: OverlayModalName, activeOverlayExtra: any, activeOverlayParams: any) {
    // If this overlay doesn't exist on desktop (for instance it is always visible, do NOTHING)
    if (!(Object.values(ReturnsSideDialogNameDesktop) as string[]).includes(activeOverlay)) return;

    if ((Object.values(ReturnsSideDialogName) as string[]).includes(activeOverlay))
      this.openRightDialog(<ReturnsSideDialogName>activeOverlay, activeOverlayExtra);
    else this.docComponent.openFullSizeDialog(activeOverlay, activeOverlayExtra, activeOverlayParams);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
