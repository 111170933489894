/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

@Component({
  selector: "taku-gmb-location-description",
  templateUrl: "./gmb-location-description.component.html",
  styleUrls: ["./gmb-location-description.component.scss"],
})
export class GmbLocationDescriptionComponent implements OnInit {
  _formGroup: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig
  ) {
    this._formGroup = this.fb.group(this.dialogConfig.data);
  }

  ngOnInit() {}

  onSave() {
    this.dialogRef.close(this._formGroup.value);
  }

  onRevert() {
    this._formGroup.reset(this.dialogConfig.data);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
