/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { SaleAnalysisReportLocalDataService } from "./sale-analysis-report-local-data.service";
import { Subscription } from "rxjs";
import { FormListComponent } from "src/app/form-list/form-list/form-list.component";
import { RowSelectionType } from "src/app/form-list/form-list/form-list";
import { SaleAnalysisReportCols, SaleAnalysisReportColsFactoryService } from "./sale-analysis-report-cols.service";
import { MessageService } from "primeng/api";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";
import { ReportParamsService } from "../../report-params.service";
import { ReportFilterFields } from "../../report-filter-fields";
import { SaleAnalysisReport } from "./sale-analysis-report";
import { resetReportRowState } from "../../report";
import { LocalDataService } from "src/app/shared/services/local-data.service";

@Component({
  selector: "taku-sales-analysis-report",
  templateUrl: "./sales-analysis-report.component.html",
  styleUrls: ["./sales-analysis-report.component.scss"],
  providers: [SaleAnalysisReportLocalDataService],
})
export class SalesAnalysisReportComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];
  @ViewChild("formListReport") formListComponent: FormListComponent;

  _model = "saleAnalysisReport";
  _stateKey = "FL-" + this._model;
  RowSelectionType = RowSelectionType;
  public reportCols: SaleAnalysisReportCols;
  dynamicFields: string[] = [];

  constructor(
    private reportColsFactoryService: SaleAnalysisReportColsFactoryService,
    public saleReportLocalService: SaleAnalysisReportLocalDataService,
    private messageService: MessageService,
    private alertMessagesService: AlertMessagesService,
    public reportParamsService: ReportParamsService,
    private localDataService: LocalDataService
  ) {
    this.reportCols = this.reportColsFactoryService.build([]);
  }

  ngOnInit() {
    this.subsList.push(
      this.reportParamsService.reportParamsChanged$.subscribe((filters: ReportFilterFields) => {
        this.updateReport(filters);

        resetReportRowState(this._stateKey);
      })
    );

    // this.subsList.push(this.reportColsFactoryService.build([]).getInventoryDetails.subscribe({
    //   next: (inventory) => {
    //     console.log(inventory);
    //   },
    //   error: (error) => {
    //     console.log(error);
    //   },
    //   complete: () => {
    //     console.log("COMPLETED!");
    //   }
    // }));
  }

  private updateReport(filters: ReportFilterFields) {
    this.reportParamsService._isFetchingData = true;
    this.subsList.push(
      this.saleReportLocalService
        .getSaleAnalysisReport(filters.storeId, filters.stationId, filters.dateFrom, filters.dateTo, 0, 0)
        .subscribe({
          next: (rows) => {
            this.buildFormlistCols(rows && rows.length ? rows[0] : null);
            this.refreshFormList(rows || []);
            this.reportParamsService._isFetchingData = false;
            this.localDataService.updateNewObject(rows);
          },
          error: (error) => {
            this.buildFormlistCols(null);
            // Show toast with error message
            this.messageService.add(this.alertMessagesService.getErrorMessage(error));
            // Empty form list
            this.refreshFormList([]);
            this.reportParamsService._isFetchingData = false;
          },
        })
    );
  }

  private buildFormlistCols(rowData: SaleAnalysisReport) {
    this.dynamicFields = rowData
      ? Object.keys(rowData).filter((key) => !Object.keys(new SaleAnalysisReport()).includes(key))
      : [];
    this.reportCols = this.reportColsFactoryService.build(this.dynamicFields);
  }

  private refreshFormList(data: any[]) {
    data.forEach((saleAnalysis) => {
      const saleAnalysisReport = Object.assign(new SaleAnalysisReport(), saleAnalysis);
      // This makes store getter result into plain property
      saleAnalysis.marginPercentCalculated = saleAnalysisReport.marginPercent;
      saleAnalysis.marginAmountCalculated = saleAnalysisReport.marginAmount;
      saleAnalysis.totalSoldPriceCalculated = saleAnalysisReport.totalSoldPrice;
      saleAnalysis.totalCostCalculated = saleAnalysisReport.totalCost;

      this.dynamicFields.forEach((key) => {
        saleAnalysis[key] = parseFloat(saleAnalysis[key]) || 0;
      });
    });
    this.saleReportLocalService.initialize(data, this._model);
    setTimeout(() => {
      this.formListComponent.reloadData();
    }, 0);
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
