/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, ViewChildren, QueryList } from "@angular/core";
import { FormListColumnsService } from "../../../form-list/form-list-columns.service";
import { Col } from "../../../form-list/form-list/form-list";
import { MultiFormListComponent } from "src/app/form-list/form-list-main/multiple-form-lists.interface";
import { FormListComponent } from "src/app/form-list/form-list/form-list.component";

@Component({
  selector: "taku-tax-categories",
  templateUrl: "./tax-categories.component.html",
  styleUrls: ["./tax-categories.component.scss"],
  providers: [FormListColumnsService],
})
export class TaxCategoriesComponent implements OnInit, MultiFormListComponent {
  @ViewChildren(FormListComponent) formlistsComponents: QueryList<FormListComponent>;

  _inventoryCatsList: { model: string; detailMode: string; cols: Col[] };
  _accountsCatsList: { model: string; detailMode: string; cols: Col[] };

  constructor(private formListColumnsService: FormListColumnsService) {}

  ngOnInit() {
    // Inventory Cats Formlist
    const inventoryModel = "taxInventoryCategory";
    this._inventoryCatsList = {
      model: inventoryModel,
      detailMode: "d",
      cols: this.formListColumnsService.getDefaultColumns(inventoryModel),
    };

    // Accounts Cats Formlist
    const accountsModel = "taxAccountCategory";
    this._accountsCatsList = {
      model: accountsModel,
      detailMode: "d",
      cols: this.formListColumnsService.getDefaultColumns(accountsModel),
    };
  }

  retrieveFormLists(): FormListComponent[] {
    return this.formlistsComponents.map((formlist) => formlist);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
