/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit } from "@angular/core";
import { StoreSettingsGenericComponent } from "../StoreSettingsGenericComponent";
import { StoreSettingEmail, SettingEmailEncryption } from "./StoreSettingEmail";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ToggleCaptionPosition } from "../../../../taku-ui/taku-toggle/taku-toggle.component";

@Component({
  selector: "taku-store-mail-settings",
  templateUrl: "./store-mail-settings.component.html",
  styleUrls: ["./store-mail-settings.component.scss"],
})
export class StoreMailSettingsComponent extends StoreSettingsGenericComponent {
  _pageTitlePrefix = "Email Settings";

  SettingEmailEncryption = SettingEmailEncryption;
  ToggleCaptionPosition = ToggleCaptionPosition;

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    const tmpModel = new StoreSettingEmail();
    const tmpForm = fb.group(tmpModel);

    return tmpForm;
  }

  initLookups() {}

  initForm() {
    this._model = "storeSettingEmail";
    this._object = new StoreSettingEmail();
    this._myForm = StoreMailSettingsComponent.init(this.fb);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
