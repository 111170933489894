/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, NgZone, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { map, shareReplay } from "rxjs/operators";
import { AlertMessagesService } from "../../../../../shared/services/alert-messages.service";
import { GMB_APIService } from "../../google-shopping/GMBAPI.service";
import { BackendGMBService } from "../BackendGMBService";
import { GMBAccount, GMBGoogleAccount } from "./GMBGoogleAccount";
import { GoogleAccount } from "./GoogleAccount";
import { Location } from "@angular/common";
import * as _ from "lodash";
import { Subscription } from "rxjs";
import { ACTION_TYPE } from "src/app/shared/components/action-button/action-type.enum";

@Component({
  selector: "taku-gmb-accounts-list",
  templateUrl: "./gmb-accounts-list.component.html",
  styleUrls: ["./gmb-accounts-list.component.scss"],
})
export class GmbAccountsListComponent implements OnInit, OnDestroy {
  public ACTION_TYPE: typeof ACTION_TYPE = ACTION_TYPE;
  subsList: Subscription[] = [];
  _modelName = "gmbGoogleAccount";
  gmbGoogleAccounts: GMBGoogleAccount[] = [];

  constructor(
    private zone: NgZone,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private alertMessagesService: AlertMessagesService,
    protected locationService: Location,
    private dbService: BackendGMBService,
    private router: Router,
    private gmbAPIService: GMB_APIService
  ) {}

  ngOnInit() {
    this.gmbAPIService.initOAuth(() => {});

    const gmbAccountsFilter = {
      isActive: { matchMode: "equals", value: true },
    };
    this.subsList.push(
      this.dbService
        .getRows(this._modelName, JSON.stringify(gmbAccountsFilter))
        .pipe(
          shareReplay(1),
          map((response: any) => response.rows)
        )
        .subscribe((accounts) => {
          this.gmbGoogleAccounts = accounts;
        })
    );
  }

  isActive(account: GMBGoogleAccount) {
    return account.isActive;
  }

  addGoogleAccount() {
    if (!this.isAddAccountEnabled) return false;

    const newGMBAccount = new GMBGoogleAccount();
    newGMBAccount.googleAccount = new GoogleAccount();
    newGMBAccount.gmbAccounts.push(new GMBAccount());

    this.gmbGoogleAccounts.push(newGMBAccount);
  }

  connectAccount() {
    this.gmbAPIService.oAuthSignIn().then((response: any) => {
      if (!response || !response.code) {
        alert("Authorization was not completed.");
        return false;
      }
      // Run inside Angular zone so change detection works
      this.zone.run(() => {
        this.subsList.push(
          this.dbService.exchangeToken(response.code).subscribe({
            next: (_response: GMBGoogleAccount) => {
              // try to find existing account and remove it from list
              _.remove(this.gmbGoogleAccounts, (account) => account.id === _response.id);
              this.gmbGoogleAccounts.unshift(_response);
            },
            error: (error) => {
              this.messageService.add(this.alertMessagesService.getErrorMessage(error));
            },
          })
        );
      });
    });
  }

  tryToRemoveGMBAccount(gmbAccountLink: GMBGoogleAccount, gmbAccount: GMBAccount) {
    if (gmbAccount.id === 0) {
      this.unlinkGMBAccount(gmbAccountLink, gmbAccount);
    } else {
      this.confirmationService.confirm({
        message:
          "By deleting this GMB Account you will lose any configurations on the account including Locations. Are you sure?",
        accept: () => {
          this.subsList.push(
            this.dbService.deleteRow("gmbAccount", gmbAccount.id).subscribe(() => {
              this.unlinkGMBAccount(gmbAccountLink, gmbAccount);

              this.messageService.add(this.alertMessagesService.getMessage("delete-success", this._modelName));
            })
          );
        },
      });
    }
  }

  private unlinkGMBAccount(gmbAccountLink: GMBGoogleAccount, gmbAccount: GMBAccount) {
    _.remove(gmbAccountLink.gmbAccounts, {
      id: gmbAccount.id,
    });
  }

  get isAddAccountEnabled() {
    // Enabled when there is no previous empty, no connected accounts
    return (
      this.gmbGoogleAccounts.findIndex((value) => {
        return value.id === 0;
      }) < 0
    );
  }

  disconnectAccount(gmbAccountLink: GMBGoogleAccount) {
    this.gmbAPIService.disconnect();

    _.remove(this.gmbGoogleAccounts, gmbAccountLink);
    gmbAccountLink.isActive = false;
    this.subsList.push(this.dbService.editRow(this._modelName, gmbAccountLink).subscribe());
  }

  viewLocations(gmbAccountLink: GMBGoogleAccount, account: GMBAccount) {
    this.router.navigate(["/integrations/my-apps/gmb/locations", account.id], {
      queryParams: { email: gmbAccountLink.googleAccount.email },
    });
  }

  goBack(): void {
    this.locationService.back();
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
