/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "objectToArray",
  standalone: true,
})
export class ObjectToArrayPipe implements PipeTransform {
  transform(value: any, args?: any[]): Object[] {
    const keyArr: any[] = Object.keys(value),
      dataArr = [],
      keyName = args[0];

    keyArr.forEach((key: any) => {
      value[key][keyName] = key;
      dataArr.push(value[key]);
    });

    if (args[1]) {
      dataArr.sort((a: Object, b: Object): number => {
        return a[keyName] > b[keyName] ? 1 : -1;
      });
    }

    return dataArr;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
