/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Output, EventEmitter, ViewChild } from "@angular/core";
import { FormControl, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { GenericFormComponent } from "../../../../forms/generic-form/generic-form.component";
import { BackOffice } from "./backOffice";
import { AddressComponent } from "../../../contact-accounts/address/address.component";
import { AddressLocationType } from "../../../contact-accounts/address/address";
import { BackofficeService } from "./backOffice.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { MessageService, ConfirmationService } from "primeng/api";
import { AlertMessagesService } from "../../../../shared/services/alert-messages.service";
import { AppSettingsStorageService } from "../../../../shared/app-settings-storage.service";
import { FileUpload } from "primeng/fileupload";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { map, switchMap } from "rxjs/operators";

@Component({
  selector: "taku-backOffice",
  templateUrl: "./backOffice.component.html",
  styleUrls: ["./backOffice.component.scss"],
})
export class BackofficeComponent extends GenericFormComponent {
  @Output() dialogClosed = new EventEmitter<any>();
  @ViewChild("fileUploader") _fileUploader: FileUpload;

  lookup_zones = this.dbService.lookupSelectOptions("zone", "zoneName", { enableFieldName: "isActive" });

  constructor(
    protected _router: Router,
    public fb: UntypedFormBuilder,
    public dbService: BackofficeService,
    protected location: Location,
    public _route: ActivatedRoute,
    protected messageService: MessageService,
    protected alertMessage: AlertMessagesService,
    protected confirmationService: ConfirmationService,
    private httpClient: HttpClient,
    appSettings: AppSettingsStorageService
  ) {
    super(_router, fb, dbService, location, _route, messageService, alertMessage, confirmationService, appSettings);
  }

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    const tmpModel = new BackOffice();
    const tmpForm = fb.group(tmpModel);
    tmpForm.setControl("address", AddressComponent.init(fb, AddressLocationType.commercial));

    return tmpForm;
  }

  myUploader(event): void {
    // get signedUrl
    const _file = event.files[0];
    if (_file.size > 2 * 1024 * 1024) {
      this.messageService.add(this.alertMessage.getMessage("max-upload-size-warning"));
      this._fileUploader.clear();
      return;
    }
    this.subsList.push(
      this.dbService
        ._getRequest<{ fileName: string; bucketPath: string; url: string }>(
          environment.apiUrl + "/uploadURL/" + _file.name
        )
        .pipe(
          switchMap((response) => {
            return this.httpClient.put(response.url, _file).pipe(map(() => response));
          })
        )
        .subscribe(
          (response) => {
            this.logoFileNameCrtl.setValue(response.fileName);
            this.logoFileNameCrtl.markAsDirty();
            this.logoUrlCtrl.setValue(response.bucketPath + "/" + response.fileName);
          },
          (err) => {
            console.error(err);
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: "An error occurred while uploading the file",
            });
            this._fileUploader.clear();
          }
        )
    );
  }
  get logoFileNameCrtl(): FormControl<string> {
    return this._myForm.controls.logoFileName as FormControl<string>;
  }

  get logoUrlCtrl(): FormControl<string> {
    return this._myForm.controls.logoUrl as FormControl<string>;
  }

  clearImage(): void {
    this.logoFileNameCrtl.setValue(null);
    this.logoUrlCtrl.setValue(null);
    this.logoUrlCtrl.markAsDirty();
  }

  closeSidebar() {
    this.dialogClosed.emit();
  }

  initValidation() {
    this._validation = this.dbService.getValidationRules();
  }

  initForm() {
    this._model = "backOffice";
    this._object = new BackOffice();
    this._myForm = BackofficeComponent.init(this.fb);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
