<span [formGroup]="formGroup" class="p-float-label">
  <textarea
    id="{{ fieldName }}"
    [attr.autofocus]="_autofocus"
    [rows]="rows"
    pInputTextarea
    [autoResize]="autoResize"
    [formControlName]="fieldName"
    [attr.readonly]="readonly || null"
    [attr.disabled]="disabled || null"
  ></textarea>
  <label for="{{ fieldName }}" [innerHTML]="fullFieldCaption"></label>
  <i
    class="material-icons clear-field"
    (click)="clear()"
    *ngIf="formGroup.controls[fieldName].value && !(readonly || disabled)"
    >close</i
  >
</span>
<form-validation-messages
  [hidden]="ctrlField.valid || !ctrlField.dirty"
  [errors]="formGroup.get(fieldName).errors"
  [fieldName]="fieldName"
></form-validation-messages>
