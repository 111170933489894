/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { LocationStrategy } from "@angular/common";
import { Component, Injectable } from "@angular/core";
import { CanDeactivate, Router } from "@angular/router";
import { ConfirmationService } from "primeng/api";
import { Observable, Observer, of } from "rxjs";
import { GenericFormComponent } from "../forms/generic-form/generic-form.component";

@Injectable()
export class UnsavedChangesGuard implements CanDeactivate<Component> {
  protected readonly confirmationMsg = "You have unsaved changes. Are you sure you want to leave this page?";

  constructor(
    protected confirmationService: ConfirmationService,
    protected _router: Router,
    protected location: LocationStrategy
  ) {}

  canDeactivate(component: Component): Observable<boolean> | boolean {
    if (this._router.getCurrentNavigation()?.extras?.state?.bypassGuards) {
      // Used for auto-logout due to idleness
      return true;
    }
    if (component instanceof GenericFormComponent) return this.checkForm(component._myForm.pristine);
    else return true;
  }

  checkForm(pristine: boolean, confirmationMessage: string = this.confirmationMsg): Observable<boolean> {
    if (pristine) {
      return of(true);
    }

    return this.showConfirmationDialog(confirmationMessage);
  }

  protected showConfirmationDialog(confirmationMessage: string = this.confirmationMsg): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.confirmationService.confirm({
        header: "Confirmation",
        message: confirmationMessage,
        acceptLabel: "Yes",
        rejectLabel: "No",
        rejectVisible: true,
        acceptVisible: true,
        rejectButtonStyleClass: "p-button-link",
        accept: () => {
          observer.next(true);
          observer.complete();
        },
        reject: () => {
          if (this.location) {
            this.location.pushState({}, "", this._router.url, "");
          }
          observer.next(false);
          observer.complete();
        },
      });
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
