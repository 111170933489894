/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import * as moment from "moment";

import { CalendarDateRangeType } from "../taku-ui/taku-calendar-range/taku-calendar-range.component";

const DateTimeRangeType = {
  ...CalendarDateRangeType,
  TWO_DAYS_AGO: "2 days ago",
  TWO_WEEKS_AGO: "2 weeks ago",
  TWO_MONTHS_AGO: "2 months ago",
  TWO_QUATERS_AGO: "2 quarters ago",
  TWO_YEARS_AGO: "2 years ago",
};

const _previousDateMapping = new Map<CalendarDateRangeType, string>([
  [CalendarDateRangeType.TODAY, DateTimeRangeType.YESTERDAY],
  [CalendarDateRangeType.YESTERDAY, DateTimeRangeType.TWO_DAYS_AGO],
  [CalendarDateRangeType.THIS_WEEK, DateTimeRangeType.LAST_WEEK],
  [CalendarDateRangeType.LAST_WEEK, DateTimeRangeType.TWO_WEEKS_AGO],
  [CalendarDateRangeType.THIS_MONTH, DateTimeRangeType.LAST_MONTH],
  [CalendarDateRangeType.LAST_MONTH, DateTimeRangeType.TWO_MONTHS_AGO],
  [CalendarDateRangeType.THIS_QUARTER, DateTimeRangeType.LAST_QUARTER],
  [CalendarDateRangeType.LAST_QUARTER, DateTimeRangeType.TWO_QUATERS_AGO],
  [CalendarDateRangeType.THIS_YEAR, DateTimeRangeType.LAST_YEAR],
  [CalendarDateRangeType.LAST_YEAR, DateTimeRangeType.TWO_YEARS_AGO],
]);

export type RelativeTime = {
  amount: number;
  timeUnit: string;
};

export class TimeHelpers {
  static UnifyDateAndTime(dateStr: string, timeStr: string, asLocalTime = true): Date {
    let momentTime = moment(`${dateStr} ${timeStr}`);
    if (asLocalTime) {
      momentTime = moment.utc(`${dateStr} ${timeStr}`);
      momentTime = momentTime.local();
    }

    return momentTime.toDate();
  }

  static GetPreviousRange(dateRange: CalendarDateRangeType): string {
    return _previousDateMapping.get(dateRange);
  }

  static GetPreviousDateRange(dateFrom, dateTo): { dateFrom: Date; dateTo: Date } {
    const to = moment(dateTo);
    const from = moment(dateFrom);
    const diffDays = to.diff(from, "days") + 1;

    return {
      dateFrom: from.clone().subtract(diffDays, "days").toDate(),
      dateTo: to.clone().subtract(diffDays, "days").toDate(),
    };
  }

  static Convert24To12Hrs(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:[0-5]\d)?(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[4] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  }

  /**
   *
   * @param date
   * @param srcTimeLocal Indicates whether or not *source* time should be treated as local or UTC time
   * @param destTimeLocal Indicates whether or not *destination* time should be formatted using local or UTC time
   */
  static SplitDateAndTime(date: Date | string, srcTimeLocal = true, destTimeLocal = true): [string, string] {
    let momentDate = srcTimeLocal ? moment(date) : moment.utc(date); // local or UTC date
    momentDate = destTimeLocal ? momentDate.local() : momentDate.utc(); // prepare time to be formatted either in LOCAL or UTC time

    return [momentDate.format("YYYY-MM-DD"), momentDate.format("HH:mm")];
  }

  static splitDateAndTimeTZ(date: Date | string, tz: string): [string, string] {
    const momentDate = moment(date).tz(tz);
    return [momentDate.format("YYYY-MM-DD"), momentDate.format("HH:mm")];
  }

  static ParseRelativeTimeString(relativeTime: string): RelativeTime {
    const regexpTimeParts = /^([\w\d]+)\s+.*?(\w+)$/;
    const timeParts = relativeTime.match(regexpTimeParts);
    const [amount, timeUnit] = [parseInt(timeParts[1]) || 1, timeParts[2]];

    return { amount, timeUnit };
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
