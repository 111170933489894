/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { DBService } from "src/app/shared/services/db.service";
import { Injectable } from "@angular/core";
import { StoreSettingCashout } from "./CashoutSettings";

@Injectable({
  providedIn: "root",
})
export class StoreCashoutSettingsService extends DBService {
  static readonly MODEL_NAME = "storeSettingCashout";

  afterFetch(cashoutSettings: StoreSettingCashout, model) {
    switch (model) {
      case StoreCashoutSettingsService.MODEL_NAME:
        cashoutSettings.storeSettingCashoutOpeningFloats.sort((a, b) => a.amount - b.amount);
        break;
    }
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
