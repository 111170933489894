/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Input } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import * as _ from "lodash";
import { SaleDocType } from "src/app/core/document/doc/doc";
import { FormHeaderDisplayMode } from "src/app/forms/form-header/form-header.component";
import { GenericFormService } from "src/app/forms/generic-form/generic-form.component";
import { AppDefaultsService } from "src/app/shared/services/app-defaults.service";
import { SaleDocFormat } from "../sale-doc-format/sale-doc-format";
import { StoreSettingsGenericComponent } from "../StoreSettingsGenericComponent";
import { SalesFormatsSetting } from "./SalesFormatsSettings";

@Component({
  selector: "taku-sales-formats-settings",
  templateUrl: "./sales-formats-settings.component.html",
  styleUrls: ["./sales-formats-settings.component.scss"],
  providers: [GenericFormService],
})
export class SalesFormatsSettingsComponent extends StoreSettingsGenericComponent {
  @Input() insideWizard = false;
  @Input() customHeaderTitle: string;

  readonly _pageTitlePrefix = "Sales Doc# Formats";
  FormHeaderDisplayMode = FormHeaderDisplayMode;

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    const tmpModel = new SalesFormatsSetting();
    const tmpForm = fb.group(tmpModel);
    tmpForm.setControl("saleDocFormats", fb.array(tmpModel.saleDocFormats.map((i) => fb.group(i))));

    return tmpForm;
  }

  setFormArrays() {
    const formArray = this.fb.array([]);
    this._object.saleDocFormats.map((saleDocFormat) => {
      const temp = this.fb.group(saleDocFormat);
      formArray.push(temp);
    });

    this._myForm.setControl("saleDocFormats", formArray);
  }

  initForm() {
    this._model = "saleFormat";
    const saleFormats = new SalesFormatsSetting();
    Object.keys(SaleDocType).forEach((key) => {
      const docType = SaleDocType[key];
      const docDefaults = AppDefaultsService.DefaultSaleDocFormats(docType);
      if (!_.isEmpty(docDefaults))
        saleFormats.saleDocFormats.push(Object.assign({}, new SaleDocFormat(), docDefaults, { docType }));
    });

    this._object = saleFormats;
    this._myForm = SalesFormatsSettingsComponent.init(this.fb);
  }

  // initValidation(){
  //   this._validation = {
  //     cashoutNoOfDigits: [Validators.required, Validators.min(3), Validators.max(10), CommonValidators.integer()],
  //     cashoutStartingNumber: [Validators.required, Validators.min(1), Validators.max(100000), CommonValidators.integer()],
  //   };
  // }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
