/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { DBService } from "../../../../shared/services/db.service";
import { FormListModel } from "../../../../form-list/form-list/form-list-model.interface";
import { ValidatorFn, Validators } from "@angular/forms";
import { Col, DataType, FilterType } from "../../../../form-list/form-list/form-list";
import { Inventory_Doc_Type } from "./InventoryDocFormat";
import * as _ from "lodash";
import { SelectItem } from "primeng/api";
import { CommonValidators } from "../../../../shared/validators/CommonValidators";

@Injectable({
  providedIn: "root",
})
export class InventoryDocFormatsService extends DBService implements FormListModel {
  enum_document_types = this.enumSelectOptions(Inventory_Doc_Type);
  // Store previous created enum controls for docTypes
  enums_docTypesRows: Map<number, SelectItem[]> = new Map();

  getValidationRules(): { [key: string]: ValidatorFn[] | {} } {
    return {
      docType: [Validators.required],
      noOfDigits: [Validators.required, Validators.min(3), Validators.max(10), CommonValidators.integer()],
      startingNumber: [Validators.required, Validators.min(1), Validators.max(100000), CommonValidators.integer()],
    };
  }

  buildEnum_docTypes(rowData, rows: any[]) {
    const rowId = rowData._uniqueid || rowData.id;
    // Returns previously stored object to avoid return a new object and having to click twice in control
    const docTypes_enum = this.enums_docTypesRows.get(rowId) || _.cloneDeep(this.enum_document_types);
    // Re-enabled any previously disabled select control
    docTypes_enum.forEach((docType) => (docType.disabled = false));
    rows.forEach((theRow) => {
      if ((rowData._uniqueid && theRow._uniqueid == rowData._uniqueid) || (rowData.id && theRow.id == rowData.id))
        return;
      const foundItemIndex = docTypes_enum
        .map((docType) => docType.value)
        .findIndex((docTypeValue) => docTypeValue == theRow.docType);
      if (foundItemIndex >= 0) {
        // disable this especific entry in the dropdown
        docTypes_enum[foundItemIndex].disabled = true;
      }
    });

    this.enums_docTypesRows.set(rowId, docTypes_enum);
    return docTypes_enum;
  }

  getFormListColumns(): Col[] {
    return [
      {
        field: "docType",
        header: "Document Type",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.buildEnum_docTypes.bind(this),
        filterType: FilterType.enum,
        filterOptions: this.enum_document_types,
      },
      {
        field: "prefix",
        header: "Prefix",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "noOfDigits",
        header: "# of Digits",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "startingNumber",
        header: "Starting Number",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "suffix",
        header: "Suffix",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "preview",
        header: "Preview",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
    ];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
