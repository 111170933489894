/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { Observable } from "rxjs";
import { SelectItem } from "primeng/api";
import { DBService } from "../../../../shared/services/db.service";
import { map } from "rxjs/operators";

@Component({
  selector: "taku-zone-switcher",
  templateUrl: "./zone-switcher.component.html",
  styleUrls: ["./zone-switcher.component.scss"],
})
export class ZoneSwitcherComponent implements OnInit {
  @Output("onZoneChanged") zoneChanged: EventEmitter<number> = new EventEmitter();
  @Input() selectedZoneId = 0;

  lookup_zones: Observable<SelectItem[]> = this.dbService
    .lookupSelectOptions("zone", "zoneName", { enableFieldName: "isActive" })
    .pipe(map((rows) => rows.filter((row) => row.value !== null)));

  constructor(private dbService: DBService) {}

  ngOnInit(): void {}

  onZoneChanged($event): void {
    const zoneId = $event.value;

    this.zoneChanged.emit(zoneId);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
