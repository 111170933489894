/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import moment from "moment";

export class InventoryUsageDetail {
  id: number;
  frequencyName: FrequencyName;
  refillFrequencyUOfM: UnitOfTimeMeasure;
  refillFrequencyamount: number;

  constructor() {
    this.id = 0;
    this.frequencyName = FrequencyName.ADULT;
    this.refillFrequencyUOfM = UnitOfTimeMeasure.DAYS;
    this.refillFrequencyamount = 1;
  }

  private static calculateDateTimesNumberOfRecurring = (usage: InventoryUsageDetail, numTimes: number): Date => {
    const momentUnitOfTime = usage.refillFrequencyUOfM.toLowerCase() as "days" | "weeks" | "years";
    return moment()
      .add(usage.refillFrequencyamount * numTimes, momentUnitOfTime)
      .toDate();
  };

  public static calculateEndDate = (usage: InventoryUsageDetail, numTimes: number): Date => {
    return this.calculateDateTimesNumberOfRecurring(usage, numTimes);
  };

  public static calculateNextDate = (usage: InventoryUsageDetail): Date => {
    return this.calculateDateTimesNumberOfRecurring(usage, 1);
  };
}

export enum FrequencyName {
  ADULT = "Adult",
  CHILDREN = "Children",
  PET = "Pet",
}

export enum UnitOfTimeMeasure {
  DAYS = "Days",
  WEEKS = "Weeks",
  YEARS = "Years",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
