/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Store } from "../store/store";

export class StoreSettingGiftReceipt {
  id: number;
  headerFontSize: Font_Size;
  printLogo: boolean;
  printStoreName: boolean;
  printWebsite: boolean;
  printEmail: boolean;
  printPhoneNo: boolean;
  printAddress: boolean;
  printBusinessHours: boolean;
  printReturnPolicy: boolean;
  printSalePolicy: boolean;
  printShippingPolicy: boolean;
  printAccountInfo: boolean;
  // printDiscountedPrice: boolean;
  printDocumentNo: boolean;
  printDate: boolean;
  printSocialIcons: boolean;
  printPoweredByTalulabs: boolean;
  storeId: number;
  store: Store;

  constructor(store?: Store) {
    this.id = 0;
    this.headerFontSize = Font_Size.small;
    this.printLogo = true;
    this.printStoreName = true;
    this.printWebsite = true;
    this.printEmail = true;
    this.printPhoneNo = true;
    this.printAddress = true;
    this.printBusinessHours = true;
    this.printReturnPolicy = true;
    this.printSalePolicy = false;
    this.printShippingPolicy = false;
    this.printAccountInfo = true;
    // this.printDiscountedPrice = true;
    this.printSocialIcons = true;
    this.printPoweredByTalulabs = true;
    this.printDate = true;
    this.storeId = store ? store.id : 0;
    this.store = store || null;
    this.printDocumentNo = true;
    this.printDate = true;
  }
}

export enum Font_Size {
  small = "Small",
  medium = "Medium",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
