/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Location } from "@angular/common";
import {
  AfterViewInit,
  Component,
  EventEmitter,
  NgZone,
  OnInit,
  TemplateRef,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { ConfirmationService, MenuItem, MessageService } from "primeng/api";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { concat, Observable, of, Subscription } from "rxjs";
import { finalize, map, mergeMapTo, tap, toArray, mergeMap } from "rxjs/operators";
import { BlockingUIService } from "src/app/shared/services/ui/blocking-ui.service";
import { GMCHelpers } from "src/app/utility/GMCHelpers";
import { Col, TemplateDatatypeOptions } from "../../../../../form-list/form-list/form-list";
import { FormListComponent } from "../../../../../form-list/form-list/form-list.component";
import { AppSettingsStorageService } from "../../../../../shared/app-settings-storage.service";
import { Inventory } from "../../../../inventory/inventory/inventory";
import { GMBLocation } from "../../google-my-business/gmb-locations-list/GMBLocation";
import { BackendGMCService } from "../BackendGMCService";
import { GMCAccountsService } from "../gmc-accounts/GMCAccountsService";
import { GMCAccount } from "../gmc-accounts/GMCGoogleAccount";
import { ContentAPI_AccountStatuses } from "../gmc-api-data-definitions/ContentAPI_AccountStatus";
import { ContentAPI_ProductStatuses } from "../gmc-api-data-definitions/ContentAPI_ProductStatutes";
import { GMCProductErrorDetailsComponent } from "../gmc-product-error-details/gmc-product-error-details.component";
import { GoogleShoppingPushAllDialogComponent } from "../google-shopping-push-all-dialog/google-shopping-push-all-dialog.component";
import { GoogleShoppingPushSelectedDialogComponent } from "../google-shopping-push-selected-dialog/google-shopping-push-selected-dialog.component";
import { GMCBatchResponse, GoogleShoppingProductsService } from "./GoogleShoppingProductsService";
import * as _ from "lodash";
import { CountryDataService } from "src/app/shared/services/internalization/country-data.service";

@Component({
  selector: "taku-google-shopping-products",
  templateUrl: "./google-shopping-products.component.html",
  styleUrls: ["./google-shopping-products.component.scss"],
  providers: [DialogService],
})
export class GoogleShoppingProductsComponent implements OnInit, AfterViewInit, OnDestroy {
  subsList: Subscription[] = [];
  @ViewChild(FormListComponent) _formListComponent: FormListComponent;
  @ViewChild("gmcErrorSummaryTpl", { static: true }) gmcErrorSummaryTpl: TemplateRef<any>;
  @ViewChild("gmcStatusTpl", { static: true }) gmcStatusTpl: TemplateRef<any>;
  @ViewChild("integrationStatusTpl", { static: true }) integrationStatusTpl: TemplateRef<any>;

  readonly customBatchSize: number = 500;
  DestinationStatus_Status = ContentAPI_ProductStatuses.DestinationStatus_Status;
  Filter_StatusAll = "all";
  _model = "inventory";
  _formListCols: Col[];
  _formListRules: {};
  _formListQueryParams = {};
  _formListFilter: any = {
    isEnabled: { value: true, matchModel: "equals" },
    isSellable: { value: true, matchModel: "equals" },
    parentInventoryId: { matchMode: "equals", value: null, allowNull: true },
  };
  _isDataReady;
  _activeGMBLocation: GMBLocation;
  _activeGMCAccount: GMCAccount;
  _productStatistics: ContentAPI_AccountStatuses.ProductsStatistics;
  _onViewGMCErrorDetails: EventEmitter<Inventory> = new EventEmitter();
  _viewDetailsEvent: EventEmitter<Inventory> = new EventEmitter();
  previousStoreId: number;
  currentStoreId: number;
  gmcPushSelectItems: MenuItem[] = [
    {
      label: "Submit ALL Pages to GMC",
      command: () => {
        this._formListComponent.selectAllRows();
        const dialogRef = this.dialogService.open(GoogleShoppingPushAllDialogComponent, {
          header: "Confirmation to Submit ALL",
          width: "300pt",
        });

        this.subsList.push(
          dialogRef.onClose.subscribe((result) => {
            if (result && result.actionInitiated) this.pushAllToGMC(result.pushOptions.includeDisabled);
            else this._formListComponent.unSelectAllRows();
          })
        );
      },
    },
  ];
  gmcPushSelectItemsMobile: MenuItem[] = [
    {
      label: "Submit selected pages to GMC",
      command: () => {
        const allSelectedInventory: Inventory[] = this._formListComponent._selectedObjects;
        const onlyEnableInventory = allSelectedInventory.filter((inventory) => inventory.isGMCEnabled);
        const onlyDisabledInventory = allSelectedInventory.filter((inventory) => !inventory.isGMCEnabled);
        const dialog = this.dialogService.open(GoogleShoppingPushSelectedDialogComponent, {
          header: "Confirmation to Submit Selection",
          width: "300pt",
          data: {
            countEnabled: onlyEnableInventory.length,
            countDisabled: onlyDisabledInventory.length,
            submitToCaption: "GMC",
          },
        });

        this.subsList.push(
          dialog.onClose.subscribe((result) => {
            if (result && result.actionInitiated) {
              setTimeout(() => {
                this.createProgressDialog();
              }, 500);

              if (result.pushOptions.includeDisabled) this.pushAndHandleResponseFromGMC(allSelectedInventory, true);
              else this.pushAndHandleResponseFromGMC(onlyEnableInventory, false);
            } else {
              if (this._formListComponent) this._formListComponent.unSelectAllRows();
            }
          })
        );
      },
    },
    ...this.gmcPushSelectItems,
  ];
  progressDialog: DynamicDialogRef;

  constructor(
    private countryDataService: CountryDataService,
    public productsService: GoogleShoppingProductsService,
    private route: ActivatedRoute,
    private appSettings: AppSettingsStorageService,
    private dialogService: DialogService,
    private confirmationService: ConfirmationService,
    private locationService: Location,
    private gmcAccountsService: GMCAccountsService,
    private backendGMCService: BackendGMCService,
    private router: Router,
    private blockingUIService: BlockingUIService,
    private messagesService: MessageService,
    private ngZone: NgZone
  ) {}

  ngOnInit() {
    this._isDataReady = false;
    // if we don't have status param, append it immediately
    this.subsList.push(
      this.route.queryParams.pipe(map((qs) => qs["status"])).subscribe((statusParam: string) => {
        if (statusParam == null) {
          this.router.navigate([], { queryParams: { status: this.Filter_StatusAll } });
        } else {
          if (statusParam && statusParam != this.Filter_StatusAll)
            this.updateProductsFilter(
              this._activeGMCAccount.id,
              <ContentAPI_ProductStatuses.DestinationStatus_Status>statusParam
            );
          else this._formListFilter = _.omit(this._formListFilter, ["inventoryGoogleShopping.googleProductId"]);
        }
      })
    );

    this.subsList.push(
      this._onViewGMCErrorDetails.subscribe((inventory) => {
        const ref = this.dialogService.open(GMCProductErrorDetailsComponent, {
          width: "75%",
          data: inventory,
        });

        // ref.onClose.subscribe(newData => {
        // });
      })
    );

    this.subsList.push(
      this._viewDetailsEvent.subscribe((inventory) => {
        const queryParams = {};
        if (this._activeGMCAccount) queryParams["gmcId"] = this._activeGMCAccount.id;
        if (this._activeGMBLocation) queryParams["gmbLocationId"] = this._activeGMBLocation.id;

        this.router.navigate(["/inventory", inventory.id], queryParams);
      })
    );

    this.subsList.push(
      this.route.params.pipe(map((params: Params) => +params["storeId"])).subscribe((storeId) => {
        // const progressBar = this.blockingUIService.showProgressBar(this.dialogService);
        if (!storeId) {
          this.router.navigate([this.appSettings.getStoreId()], { relativeTo: this.route });
          return;
        }

        this.previousStoreId = this.currentStoreId;
        this.currentStoreId = storeId;
        this.productsService._activeStoreId = storeId;

        // If this settings associated with this store were alredy fetched, do nothing
        if (this._activeGMBLocation && this._activeGMBLocation.storeId === storeId) return;

        this.subsList.push(
          this.gmcAccountsService.fetchGoogleSettingsAssociatedToStore(storeId).subscribe((response) => {
            if (response.gmcAccount && response.gmbLocation) {
              this._activeGMCAccount = response.gmcAccount;
              this._activeGMBLocation = response.gmbLocation;

              this.refreshProductCounters();
              this.updateInventoryCurrencyFilter(this._activeGMBLocation);
              this._formListQueryParams = {
                virtualFieldsType: "GMC",
                gmcAccountId: this._activeGMCAccount.id,
                stockId: this.appSettings.getStore().stockId,
                zoneId: this.appSettings.getZoneId(),
              };
            } else {
              if (this.previousStoreId) this.locationService.back();
              // this._productStatistics = null;
              // this._activeGMBLocation = null;

              // Coulnd't found associated GMC account
              this.confirmationService.confirm({
                header: "Merchant Account NOT FOUND",
                message:
                  "The selected store doesn't have any associated Google Merchant Account.<br><br>" +
                  "Please select other store or navigate to the tab Settings and associated a Merchant Account with a GMB Account location for the selected store",
                rejectVisible: false,
                icon: "ui-icon-error",
                acceptLabel: "OK",
              });
            }
            this._isDataReady = true;
          })
        );
      })
    );
  }

  private updateInventoryCurrencyFilter(gmbLocation: GMBLocation) {
    const countryData = this.countryDataService.dataByIsoCode(gmbLocation.targetCountry);
    if (!countryData) return;

    this._formListFilter["standardPriceCurrencyIsoCode"] = {
      matchMode: "in",
      value: countryData.currencies,
    };
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this._formListCols = this.productsService.getFormListColumns({
        googleErrorsSummaryTemplateOptions: <TemplateDatatypeOptions>{
          data: { showDetailsEvent: this._onViewGMCErrorDetails },
          templateRef: this.gmcErrorSummaryTpl,
        },
        googleStatusTemplateOptions: {
          data: {},
          templateRef: this.gmcStatusTpl,
        },
        integrationStatusTemplateOptions: {
          data: {},
          templateRef: this.integrationStatusTpl,
        },
        viewDetailsEvent: this._viewDetailsEvent,
      });

      this._formListRules = this.productsService.getValidationRules();
    }, 0);
  }

  updateProductsFilter(gmcAccountId, destinationStatus: ContentAPI_ProductStatuses.DestinationStatus_Status) {
    this.createProgressDialog();
    this.subsList.push(
      this.productsService
        .extractProductIdsByStatus(gmcAccountId, destinationStatus)
        .pipe(
          finalize(() => {
            // Ensure that progress bar is closed
            if (this.progressDialog) this.progressDialog.close();
          })
        )
        .subscribe((productIds) => {
          // if we don't have any product, push extra -1 productId in order to avoid server errors in next request
          if (!productIds.length) productIds.push(-1);
          this._formListFilter = Object.assign({}, this._formListFilter, {
            "inventoryGoogleShopping.googleProductId": { value: productIds, matchMode: "in" },
          });
        })
    );
  }

  refreshProductCounters() {
    this._productStatistics = null;

    this.subsList.push(
      this.backendGMCService.getAccountStatus(this._activeGMCAccount.id).subscribe((response) => {
        const shoppingDestination = response.products.find(
          (product) =>
            product.channel == "local" &&
            product.destination === GMCHelpers.DEFAULT_DESTINATION_NAME &&
            product.country == this._activeGMBLocation.targetCountry
        );
        if (shoppingDestination) this._productStatistics = shoppingDestination.statistics;
      })
    );
  }

  private parseGMCBatchResponse(response: GMCBatchResponse[]): {
    countSucesses: number;
    countFailures: number;
    inventorySucceeded: number[];
  } {
    const inventorySucceeded: number[] = [];
    let countSucesses = 0;
    let countFailures = 0;
    response.forEach((batchResp) => {
      const productsInsertResp = batchResp.serverResponse.productsInsert;
      const posInventoryResp = batchResp.serverResponse.posInventory;

      if (
        batchResp instanceof Error ||
        !productsInsertResp ||
        !productsInsertResp.kind ||
        !posInventoryResp ||
        !posInventoryResp.kind
      )
        // The whole batch had problems
        countFailures += batchResp.batchSize;
      else {
        (<any[]>productsInsertResp.entries).forEach((productsEntry) => {
          if (productsEntry.errors) {
            // Problems in specific entries
            countFailures++;
          } else {
            // try to find current product into the Pos.inventory
            const posEntry = (<any[]>posInventoryResp.entries).find(
              (entry2) => entry2.batchId === productsEntry.batchId
            );
            if (!posEntry || posEntry.errors) {
              countFailures++;
            } else {
              inventorySucceeded.push(productsEntry.batchId);
              countSucesses++;
            }
          }
        });
      }
    });

    return {
      countFailures,
      countSucesses,
      inventorySucceeded,
    };
  }

  // private displayGMCSubmissionResult(response:GMCBatchResponse[]){

  // }

  // pushSelectionToGMC(inventory:Inventory[], enableDisabled){
  //   const insertRequests:Observable<any>[] = [];
  //   inventory.forEach(inventory => {
  //     const inventoryObservable = this.productsService.pushToGoogle(this._activeGMCAccount.id, this._activeGMBLocation, inventory);
  //     insertRequests.push(inventoryObservable);
  //   })

  //   if (enableDisabled){
  //     const inventoryIds = inventory.map(inventory => inventory.id);
  //     insertRequests.push( this.enableGMCInventoryRequest(inventoryIds) );
  //   }

  //   this.pushSubscription = forkJoin(insertRequests).subscribe({
  //     next: (response:any[]) => {
  //       this.displayGMCSubmissionResult(response);
  //     },
  //     complete: () => {
  //       // Reload data after finishing pushing products, so we can have new Google status if any
  //       this._formListComponent.reloadData();
  //     },
  //     error: error => {
  //     }
  //   })
  // }

  openSubmitSelectionDialog() {
    const allSelectedInventory: Inventory[] = this._formListComponent._selectedObjects;
    const onlyEnableInventory = allSelectedInventory.filter((inventory) => inventory.isGMCEnabled);
    const onlyDisabledInventory = allSelectedInventory.filter((inventory) => !inventory.isGMCEnabled);
    const dialog = this.dialogService.open(GoogleShoppingPushSelectedDialogComponent, {
      header: "Confirmation to Submit Selection",
      width: "300pt",
      data: {
        countEnabled: onlyEnableInventory.length,
        countDisabled: onlyDisabledInventory.length,
        submitToCaption: "GMC",
      },
    });

    this.subsList.push(
      dialog.onClose.subscribe((result) => {
        if (result && result.actionInitiated) {
          setTimeout(() => {
            this.createProgressDialog();
          }, 500);

          if (result.pushOptions.includeDisabled) this.pushAndHandleResponseFromGMC(allSelectedInventory, true);
          else this.pushAndHandleResponseFromGMC(onlyEnableInventory, false);
        } else {
          if (this._formListComponent) this._formListComponent.unSelectAllRows();
        }
      })
    );
  }

  pushAllToGMC(includeDisabled) {
    this.createProgressDialog();
    const inventoryFilter = {
      isEnabled: { matchMode: "eq", value: true },
    };
    if (!includeDisabled) inventoryFilter["isGMCEnabled"] = { matchMode: "eq", value: true };

    this.subsList.push(
      this.backendGMCService
        .getRows("inventory", JSON.stringify(inventoryFilter), 0, 10000000)
        .subscribe((response) => {
          this.pushAndHandleResponseFromGMC(response ? response.rows : [], includeDisabled);
        })
    );
  }

  private createProgressDialog() {
    if (!this.progressDialog) {
      this.progressDialog = this.blockingUIService.showProgressBar(this.dialogService, true);
      this.subsList.push(
        this.progressDialog.onClose.subscribe(() => {
          if (this._formListComponent) this._formListComponent.unSelectAllRows();
          this.progressDialog = null;
        })
      );
    }

    return this.progressDialog;
  }

  private pushAndHandleResponseFromGMC(inventoryData: Inventory[], enableInventory: boolean) {
    if (!inventoryData || !inventoryData.length) {
      // if we don't have inventory to push, just show message
      this.confirmationService.confirm({
        header: "No Inventory Found",
        message: "There is no inventory to push into Google Shopping",
        rejectVisible: false,
        acceptLabel: "OK",
      });
      return;
    }

    const batchInsertRequests = this.productsService.pushInBatches(
      this._activeGMCAccount.id,
      this._activeGMBLocation,
      inventoryData,
      this.customBatchSize
    );
    this.subsList.push(
      concat(...batchInsertRequests)
        .pipe(
          toArray(),
          map((responses: GMCBatchResponse[]) => this.parseGMCBatchResponse(responses)),
          tap((summary: any) => {
            this.messagesService.clear();
            this.messagesService.add({
              summary: "Submission Summary",
              detail: `
            <strong>${summary.countSucesses}</strong> items submitted <strong>successfully!</strong></li><br>
            <strong>${summary.countFailures}</strong> items <strong>failed</strong> to be submitted</li>
          `,
              severity: "info",
              life: 6000,
            });
          }),
          mergeMap((summary: any) =>
            enableInventory ? this.enableGMCInventoryRequest(this.currentStoreId, summary.inventorySucceeded) : of()
          ),
          finalize(() => {
            if (this.progressDialog) this.progressDialog.close();
            if (this._formListComponent) {
              this._formListComponent.unSelectAllRows();
              // Reload data after finishing pushing products, so we can have new Google status if any
              this._formListComponent.reloadData();
            }
          })
        )
        .subscribe({
          next: (response) => {},
          complete: () => {},
          error: (error) => {},
        })
    );
  }

  private enableGMCInventoryRequest(storeId, inventoryIds): Observable<any> {
    const patchFilter = {
      // isEnabled: { matchMode: 'eq', value: true },
      isGMCEnabled: { value: false, matchMode: "equals" },
      storeId: { value: storeId, matchMode: "equals" },
      inventoryId: { value: inventoryIds, matchMode: "in" },
    };

    return this.backendGMCService.patchRows(
      "inventoryStore",
      {
        isGMCEnabled: true,
      },
      JSON.stringify(patchFilter)
    );
  }

  isNearIphoneFiveDisplay(): boolean {
    return window.innerWidth <= 375;
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }

  _changeFormListFilter = (filter) => {
    let gmcEnabledFilter;
    if ((gmcEnabledFilter = filter["inventoryStores.isGMCEnabled"])) {
      const isGMCEnabled = gmcEnabledFilter.value;
      // Add filter for selected store
      filter["inventoryStores.storeId"] = {
        matchMode: "in",
        value: [this.currentStoreId, 0],
        "fn.isnull": 0,
      };
      // Add filter for GMC Is Enabled
      filter["inventoryStores.isGMCEnabled"] = {
        matchMode: "in",
        value: [isGMCEnabled],
        "fn.isnull": false,
      };
    }
  };
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
