/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, EventEmitter, Output } from "@angular/core";
import { SaleDocFooterComponent } from "../sale-doc-footer/sale-doc-footer.component";

@Component({
  selector: "taku-sale-doc-footer-compact",
  templateUrl: "./sale-doc-footer-compact.component.html",
  styleUrls: ["./sale-doc-footer-compact.component.scss"],
})
export class SaleDocFooterCompactComponent extends SaleDocFooterComponent {
  @Output() summaryOpened: EventEmitter<any> = new EventEmitter();

  invoiceSummaryLabel(itemCount: number | string): string {
    return `Invoice Summary (${itemCount} items)`;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
