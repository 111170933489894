<div class="topWrapper">
  <taku-form-header
    toolbarTitle="Open Total Details"
    displayMode="inline"
    [showActionBtns]="false"
    [showBackBtn]="false"
  >
    <button pButton class="p-button-rounded" type="button" (click)="onClosePressed()" icon="pi pi-times"></button>
  </taku-form-header>
  <div class="bodyWrapper">
    <div class="body-top">
      <table cellspacing="0">
        <tr>
          <td class="label-col">Transaction Name</td>
          <td class="value-col">{{ detailsObject.TxnName }}</td>
        </tr>
        <tr>
          <td class="label-col">Transaction Type</td>
          <td class="value-col">{{ detailsObject.TransType }}</td>
        </tr>
        <tr>
          <td class="label-col">Condition Code</td>
          <td class="value-col">{{ detailsObject.ConditionCode }}</td>
        </tr>
        <tr>
          <td class="label-col">Terminal ID</td>
          <td class="value-col">{{ detailsObject.TerminalId }}</td>
        </tr>
      </table>

      <table cellspacing="0">
        <tr>
          <td class="label-col">Batch Number</td>
          <td class="value-col">{{ detailsObject.BatchNumber }}</td>
        </tr>
        <tr>
          <td class="label-col">Response Code</td>
          <td class="value-col">{{ detailsObject.ResponseCode }}</td>
        </tr>
        <tr>
          <td class="label-col">Transaction Date</td>
          <td class="value-col">{{ detailsObject.TransDate }}</td>
        </tr>
        <tr>
          <td class="label-col">Transaction Time</td>
          <td class="value-col">{{ detailsObject.TransTime }}</td>
        </tr>
      </table>
    </div>
    <div class="body-bottom">
      <div class="cardType-container">
        <div class="main-table">
          <table cellspacing="0" class="header-table">
            <tr class="header-row">
              <th>Totals</th>
              <th>Host Count</th>
              <th>Host Total</th>
              <th>Device Count</th>
              <th>Device Total</th>
            </tr>
            <tr class="body-row first-body-row">
              <td>Correction</td>
              <td>{{ detailsObject.HostCorrectionCount | number : _digitsFormat }}</td>
              <td>{{ detailsObject.HostCorrectionTotal }}</td>
              <td>{{ detailsObject.DeviceCorrectionCount | number : _digitsFormat }}</td>
              <td>{{ detailsObject.DeviceCorrectionTotal }}</td>
            </tr>
            <tr class="body-row">
              <td>Credit</td>
              <td>{{ detailsObject.HostCreditCount | number : _digitsFormat }}</td>
              <td></td>
              <td>{{ detailsObject.DeviceCreditCount | number : _digitsFormat }}</td>
              <td></td>
            </tr>
            <tr class="body-row">
              <td>Payment Correction</td>
              <td>{{ detailsObject.HostPaymentCorrectionCount | number : _digitsFormat }}</td>
              <td>{{ detailsObject.HostPaymentCorrectionTotal }}</td>
              <td>{{ detailsObject.DevicePaymentCorrectionCount | number : _digitsFormat }}</td>
              <td>{{ detailsObject.DevicePaymentCorrectionTotal }}</td>
            </tr>
            <tr class="body-row">
              <td>Payment</td>
              <td>{{ detailsObject.HostPaymentCount | number : _digitsFormat }}</td>
              <td>{{ detailsObject.HostPaymentTotal }}</td>
              <td>{{ detailsObject.DevicePaymentCount | number : _digitsFormat }}</td>
              <td>{{ detailsObject.DevicePaymentTotal }}</td>
            </tr>
            <tr class="body-row">
              <td>Purchase</td>
              <td>{{ detailsObject.HostPurchaseCount | number : _digitsFormat }}</td>
              <td>{{ detailsObject.HostPurchaseTotal }}</td>
              <td>{{ detailsObject.DevicePurchaseCount | number : _digitsFormat }}</td>
              <td>{{ detailsObject.DevicePurchaseTotal }}</td>
            </tr>
            <tr class="body-row">
              <td>Refund</td>
              <td>{{ detailsObject.HostRefundCount | number : _digitsFormat }}</td>
              <td>{{ detailsObject.HostRefundTotal }}</td>
              <td>{{ detailsObject.DeviceRefundCount | number : _digitsFormat }}</td>
              <td>{{ detailsObject.DeviceRefundTotal }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
