<div class="grid">
  <div class="col-12 xl:col-4 drop-container">
    <taku-dropdown
      [formGroup]="formGroup"
      [options]="lookupStores$ | async"
      fieldName="storeId"
      fieldCaption="Store"
    ></taku-dropdown>
  </div>

  <div class="col-12 xl:col-4 field-station-selection">
    <taku-dropdown
      [formGroup]="formGroup"
      [options]="lookupStations$ | async"
      fieldName="stationId"
      fieldCaption="Station"
    ></taku-dropdown>
  </div>

  <div class="col-12 xl:col-4">
    <taku-taku-calendar-range
      [formGroup]="formGroup"
      fieldCaption="Date Range"
      fieldNameRangeType="dateRangeType"
      fieldNameDateFrom="dateFrom"
      fieldNameDateTo="dateTo"
    ></taku-taku-calendar-range>
  </div>
</div>
