<div [formGroup]="formGroup" class="multiselect-container">
  <span class="md-multiselectfield p-float-label" [ngClass]="{ 'inputctrl-nofilled': !isFilled }">
    <p-multiSelect
      [disabled]="disabled"
      [appendTo]="_appendTo"
      [options]="options"
      formControlName="{{ fieldName }}"
      maxSelectedLabels="100"
      placeholder="&nbsp;"
      [dataKey]="_dataKey"
      (onPanelShow)="tryFocusFilterField($event)"
      [ngClass]="{ 'inputctrl-filled': isFilled }"
    ></p-multiSelect>
    <label *ngIf="fieldCaption" for="{{ fieldName }}" [innerHTML]="fullFieldCaption"></label>
    <form-validation-messages
      *ngIf="showValidationErrors"
      [hidden]="ctrlField.valid || !ctrlField.dirty"
      [errors]="ctrlField.errors"
      [fieldName]="fieldName"
    ></form-validation-messages>
  </span>
</div>
