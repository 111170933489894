/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Location } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  Injectable,
  Optional,
  OnChanges,
  OnDestroy,
} from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import * as _ from "lodash";
import { MessageService } from "primeng/api";
import { ConfirmationService } from "primeng/api";
import { Observable, throwError, Subject, ReplaySubject, Subscription, of } from "rxjs";
import { map, catchError, tap } from "rxjs/operators";
import { AlertMessagesService } from "../../shared/services/alert-messages.service";
import { DBService } from "../../shared/services/db.service";
import { AppSettingsStorageService } from "../../shared/app-settings-storage.service";
import * as _flatten from "flat";
import { BaseEntity } from "src/app/utility/types";

@Injectable()
export class GenericFormService {
  _formChangedSource = new Subject();
  formChanged$ = this._formChangedSource.asObservable();

  _objectLoaded = new ReplaySubject(1);
  objectLoaded$ = this._objectLoaded.asObservable();
}

@Component({
  template: "",
  styles: [],
})
export abstract class GenericFormComponent<
  EntityT extends BaseEntity = any,
  FormEntityT extends UntypedFormGroup = UntypedFormGroup
> implements OnInit, OnChanges, OnDestroy
{
  subsList: Subscription[] = [];
  /** The original object from the database, not supposed to be mutated after being fetched */
  @Input() _object: EntityT;
  @Input() _isDialog = false;
  @Output() changedForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() formStatusChanged = new EventEmitter<{ statusData: any; form: UntypedFormGroup }>();
  @Output() dialogClosed = new EventEmitter<any>();

  _id: number;
  _model: string;
  successMessageEntityName?: string;

  dbMode: DBMode;
  active = false;

  _initialFormValue: string;
  _myForm: FormEntityT;
  _validation = {};
  _isSaving = false;

  constructor(
    protected _router: Router,
    protected fb: UntypedFormBuilder,
    protected dbService: DBService,
    protected location: Location,
    protected _route: ActivatedRoute,
    protected messageService: MessageService,
    protected alertMessage: AlertMessagesService,
    protected confirmationService: ConfirmationService,
    protected appSettingsService: AppSettingsStorageService,
    @Optional() protected formService?: GenericFormService
  ) {
    this.initLookups();
    this.initForm();
    // this.initLookups();

    if (this.formService) {
      this.subsList.push(
        this.changedForm.subscribe((object) => {
          this.formService._formChangedSource.next(object);
        })
      );
    }
  }

  protected initLookups() {}

  protected initForm() {}

  protected setFormArrays() {}

  protected initValidation() {
    this._validation = {};
  }

  protected setValidation() {
    this.applyValidationToForm(this._validation, this._myForm);
  }

  protected applyValidationToForm(validationRules: any, form: UntypedFormGroup) {
    const flatRules = _flatten(validationRules, { safe: true });
    Object.keys(flatRules).forEach((key) => {
      if (form.get(key)) form.get(key).setValidators(flatRules[key]);
    });
  }

  protected resetForm() {
    if (this._object) {
      this._myForm.reset(this._object);
    }
  }

  protected loadObject() {
    this.prepareForm();
    this.initValidation();
    this.setValidation();

    if (this.formService) this.formService._objectLoaded.next(this._object);
  }

  ngOnInit() {
    this._route.params.forEach((params: Params) => {
      // if (!params['detailmode']) {
      // this._model = params['model'];
      this._id = +params.id;
      // }
    });

    this.subsList.push(
      this._myForm.statusChanges.subscribe((status) =>
        this.formStatusChanged.emit({ statusData: status, form: this._myForm })
      )
    );

    if (!this._id) {
      this.subsList.push(
        this._route.data.subscribe((data) => {
          if (data[this._model]) this._id = data[this._model].id;
          else if (Number.isNaN(this._id)) this._id = 0;
        })
      );
    }

    this._checkObjectChanges();
    if (this._object != null) this.loadObject();

    if (this.dbMode === DBMode.insert) this._myForm.markAsDirty();
  }

  private _checkObjectChanges() {
    if (this._isDialog) {
      if (this._object == null) {
        this._id = 0;
      } else {
        this._id = this._object.id;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes._object) {
      this._checkObjectChanges();
      this.loadObject();
    }

    if (changes._model && !changes._model.firstChange && !changes._object) this.loadObject();
  }

  protected prepareForm(): void {
    // if 'id' is 0 it means it's a new object to be created (inserted) otherwise it's an edit for an existing object
    this.dbMode = this._id === 0 ? DBMode.insert : DBMode.edit;
    if (this.dbMode !== DBMode.insert) {
      // edit mode for an existing object
      if (this._isDialog === false) {
        this.subsList.push(
          this._route.data.subscribe((myObject) => {
            if (myObject[this._model]) {
              this._object = myObject[this._model];
            }
            this.prepareFormForEdit();
          })
        );
      } else {
        this.prepareFormForEdit();
      }
    } else {
      // insert mode for creating new object
      // 2 lines tha were commented before is commented out to be able to reset form to objec
      this.setFormArrays();
      this.resetForm();
      this.active = true;
    }
  }

  private prepareFormForEdit() {
    this.active = true;
    this.setFormArrays();
    this.resetForm();
    this._initialFormValue = JSON.stringify(this._myForm.getRawValue()).valueOf();
    this.subsList.push(
      this._myForm.valueChanges.subscribe(() => {
        if (this._initialFormValue === JSON.stringify(this._myForm.getRawValue())) {
          this._myForm.markAsPristine();
        }
      })
    );
  }

  onSubmit(silentMode = false, silentErrors = false): Observable<any> {
    this.dbMode = this._id === 0 ? DBMode.insert : DBMode.edit;
    if (this.dbMode === DBMode.insert) {
      // this._object = this.prepareSaveObject();
      return this.dbService.addRow(this._model, this.prepareSaveObject()).pipe(
        catchError((errorResponse) => {
          this.processServerError(errorResponse, silentErrors);
          return throwError(errorResponse);
        }),
        tap((myObject: any) => {
          // this._router.navigate([this._model + '/' + myObject.id]);
          this._id = myObject.id;
          this._object = myObject;
          this.dbMode = DBMode.edit;
          this.setFormArrays();
          this.resetForm();
          this._initialFormValue = JSON.stringify(this._myForm.getRawValue()).valueOf();
          this.changedForm.emit(this._object);
          if (!silentMode)
            this.messageService.add(this.alertMessage.getMessage("save-success", this.successMessageEntityName));
          // if (!this._isDialog) {
          //   this.goBack();
          // }
        })
      );
    } else {
      // const tempUpdatedAt = this._object.updatedAt;
      if (this._myForm.dirty) {
        const _tempObject = this.prepareSaveObject();
        _tempObject.updatedAt = this._object.updatedAt;
        return this.dbService.editRow(this._model, _tempObject).pipe(
          catchError((errorResponse) => {
            this.processServerError(errorResponse, silentErrors);
            return throwError(errorResponse);
          }),
          tap((myObject) => {
            this._object = myObject;
            this.setFormArrays();
            this.resetForm();
            this._initialFormValue = JSON.stringify(this._myForm.getRawValue()).valueOf();
            this.changedForm.emit(this._object);
            // if (!this._isDialog) {
            //   this.goBack();
            // }
            if (!silentMode)
              this.messageService.add(this.alertMessage.getMessage("save-success", this.successMessageEntityName));
          })
        );
      } else {
        return of(this._object);
      }
    }
  }

  protected prepareSaveObject(): any {
    return Object.assign({}, this._myForm.getRawValue());
  }

  onRevert() {
    this.setFormArrays();
    this.resetForm();
  }

  onDelete() {
    if (this.dbMode === DBMode.insert) {
      if (!this._isDialog) {
        this.goBack();
      } else {
        this.changedForm.emit(this._object);
      }
    } else {
      this.confirmationService.confirm({
        message: "Are you sure that you want to delete this record?",
        icon: "ui-icon-delete",
        rejectButtonStyleClass: "p-button-link",
        accept: () => {
          // Actual logic to perform a confirmation
          this.subsList.push(
            this.dbService.deleteRow(this._model, this._object.id).subscribe(
              (data) => {
                this.messageService.add(this.alertMessage.getMessage("delete-success"));
                if (this._isDialog) {
                  this.changedForm.emit("Deleted");
                } else {
                  this.goBack();
                }
              },
              (error: HttpErrorResponse) => {
                this.messageService.add(this.alertMessage.getErrorMessage(error));
              },
              () => {}
            )
          );
        },
      });
    }
  }

  goBack(): void {
    if (this._isDialog) this.dialogClosed.emit();
    else this.location.back();
  }

  onSave({ silentMode = false }: { silentMode?: boolean } = {}) {
    this._isSaving = true;
    this.subsList.push(
      this.onSubmit(silentMode).subscribe(
        () => {
          this._isSaving = false;
        },
        (err) => {
          this._isSaving = false;
        }
      )
    );
  }

  onNew() {
    if (this.dbMode !== DBMode.insert) {
      this.subsList.push(
        this.onSubmit().subscribe(() => {
          this._router.navigate([this._model + "/" + 0]);
          this.initForm();
          this._id = 0;
          this.loadObject();
          // this.prepareForm();
          // this.setFormArrays();
          // this.resetForm();
          // this.setValidation();
          this._initialFormValue = JSON.stringify(this._myForm.getRawValue()).valueOf();
        })
      );
    }
  }

  onClone() {
    // copying all nested objects to new object should be handled
    // this.onSubmit().subscribe(() => {
    // let tmpObject = {};
    // Object.assign(tmpObject, this._object)
    // this._router.navigate([this._model + '/' + 0]);
    // this.initForm();
    // this._id = 0;
    // this.prepareForm();
    // this.resetForm();
    // this._initialFormValue = JSON.stringify(this._myForm.value).valueOf();
    // Object.assign(this._object, tmpObject);
    // this._object.id = 0
    // this.setFormArrays();
    // this.resetForm();
    // });
  }

  protected _formChanged(event) {
    this.changedForm.emit(event);
  }

  protected _removeFormArrayChild(formArray: UntypedFormArray, index: number) {
    formArray.removeAt(index);
    this._myForm.markAsDirty();
  }

  protected processServerError(errorResponse: HttpErrorResponse, silentMode = false) {
    let showedToast = false;
    const formGroup = this._myForm;
    if (errorResponse.error) {
      const errorsInfo = errorResponse.error;
      if (errorsInfo.errors)
        errorsInfo.errors.forEach((theError) => {
          if (theError.validatorKey === "not_unique") {
            // Sanitize unique validation messages
            const suffixErrorRegexp = /_UNIQUE/;
            theError.message = (theError.message as string).replace(suffixErrorRegexp, "");
            theError.path = (theError.path as string).replace(suffixErrorRegexp, "");
            if (theError?.path === "userName_unique_for_merchant") {
              errorsInfo.customMessage = "This username already exists";
            }
          }

          const fnSearchControl = (_formGroup: UntypedFormGroup, fieldName: string) => {
            for (const [key, ctrl] of Object.entries(_formGroup.controls)) {
              if (ctrl instanceof UntypedFormGroup) {
                const finding = fnSearchControl(ctrl, fieldName);
                if (finding) return finding;
              } else if (key.includes(ctrlName)) {
                return { name: key, control: ctrl };
              }
            }

            return null;
          };

          let ctrlName = theError.path;
          let formControl = formGroup.get(ctrlName);
          if (!formControl) {
            const finding = fnSearchControl(formGroup, ctrlName);
            if (finding) {
              ctrlName = finding.name;
              formControl = finding.control;
            }
          }

          if (formControl)
            formControl.setErrors({
              "server-error": {
                errorName: _.capitalize(_.startCase(theError.message).toLowerCase()),
                errorData: theError,
                fields: ctrlName,
              },
            });

          // const fieldPath = theError.path;
          // const formControl = this._myForm.get(fieldPath);
          // if (formControl) formControl.setErrors({
          //   'server-error': { errorName: errorsInfo.name, errorData: theError, fields: errorsInfo.fields }
          // })
        });
      else if (errorsInfo.custom && errorResponse.error.message && !silentMode) {
        this.messageService.add({
          severity: "error",
          summary: "COULDN'T SAVE CHANGES",
          detail: errorResponse.error.message,
          life: 8000,
        });
        showedToast = true;
      } else if (errorsInfo.customMessage) {
        if (errorsInfo.customMessage.includes("Incorrect decimal value")) {
          this.messageService.add({
            severity: "error",
            summary: "Error message",
            detail: "Incorrect decimal value",
            life: 8000,
          });
          showedToast = true;
        }
      }
    }

    if (!silentMode && !showedToast) this.messageService.add(this.alertMessage.getErrorMessage(errorResponse));
  }

  ngOnDestroy() {
    this.subsList.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}

export enum DBMode {
  insert,
  edit,
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
