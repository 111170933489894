import { Component, ElementRef, Input, OnInit } from "@angular/core";
import * as _ from "lodash";
import { forkJoin, ReplaySubject, Subscription } from "rxjs";
import { CashoutSummaryDoc, DBCashoutService } from "src/app/core/cashout/db-cashout.service";
import { BusinessDetail } from "src/app/core/settings/business-settings/business-detail/business-detail";
import { Store } from "src/app/core/settings/store-settings/store/store";
import { User } from "src/app/core/users/user/user";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { LocationService } from "src/app/shared/services/location.service";
import { PrintHelpers } from "src/app/utility/PrintHelpers";
import { CashoutSlipBase } from "../CashoutSlipBase.interface";

@Component({
  selector: "taku-opening-cashout-slip-tape-size",
  templateUrl: "./opening-cashout-slip-tape-size.component.html",
  styleUrls: ["../../../../../assets/layout/taku/opening-cashout-slip-tape-size.component.css"],
})
export class OpeningCashoutSlipTapeSizeComponent extends CashoutSlipBase implements OnInit {
  businessDetail: BusinessDetail;

  private static readonly COMPILED_CSS_URL = "/assets/layout/taku/opening-cashout-slip-tape-size.component.css";
  subsList: Subscription[] = [];

  openingUser: User;
  printedDate: Date;
  _countryName: string = null;
  _digitsFormat = "1.2-2";
  _printedDate = new Date();

  private _dataReadySubject = new ReplaySubject<boolean>(1);
  onDataReady$ = this._dataReadySubject.asObservable();

  constructor(
    elementRef: ElementRef,
    private locationService: LocationService,
    private dbService: DBCashoutService,
    private appSettings: AppSettingsStorageService
  ) {
    super(elementRef);
  }

  ngOnInit(): void {
    this.subsList.push(
      forkJoin([
        this.dbService.getRow<Store>("store", this.cashout.storeId),
        this.dbService.getSummary(this.cashout.id),
        // this.dbService.getRow<User>('user', this.cashout.openingCashFloatUserId),
        this.dbService.getRow<User>("user", this.cashout.openingCashFloatUserId),
        this.appSettings.getBusinessDetails(),
      ]).subscribe(
        ([store, cashoutsSummary, user, businessDetail]: [Store, CashoutSummaryDoc[], User, BusinessDetail]) => {
          this.store = _.merge(new Store(), store);
          this.docsSummaries = cashoutsSummary.map((doc) => _.merge(new CashoutSummaryDoc(), doc));
          this.openingUser = _.merge(new User(), user);
          this.businessDetail = businessDetail;

          this.printedDate = new Date();
          if (this.store.address)
            this._countryName = this.locationService.lookupCountryByIsoCode(this.store.address.countryIsoCode);

          this._dataReadySubject.next(true);
        }
      )
    );
  }

  getStylesheets() {
    return [OpeningCashoutSlipTapeSizeComponent.COMPILED_CSS_URL];
  }

  sendToPrinter() {
    PrintHelpers.printElementContents(this.elementRef.nativeElement, [
      OpeningCashoutSlipTapeSizeComponent.COMPILED_CSS_URL,
    ]);
  }

  get businessLogoUrl() {
    // First try to use the Store's logo, if not available fallback to Business's logo
    const logoUrl = this.store.urlStoreLogoUrl || this.businessDetail.urlBusinessDetailBusinessLogoUrl;
    return logoUrl; // WebHelpers.buildImageUrl(logoUrl);
    // return WebHelpers.buildImageUrl(this.businessDetail.BusinessDetailBusinessLogoUrl);
  }
  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}
