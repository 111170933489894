import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MessageService } from "primeng/api";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { Subscription } from "rxjs";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "taku-kiosk-deactivate-dialog",
  templateUrl: "./kiosk-deactivate-dialog.component.html",
  styleUrls: ["./kiosk-deactivate-dialog.component.scss"],
})
export class KioskDeactivateDialogComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];

  correctExitPw: string;
  // @ViewChild('passwordInput') passwordField: ElementRef;
  passwordForm: UntypedFormGroup;
  passwordFieldControl;
  isAdmin: boolean;
  appVersion = environment.common.APP_VERSION;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private fb: UntypedFormBuilder,
    private appSettings: AppSettingsStorageService,
    protected messageService: MessageService,
    protected alertMessage: AlertMessagesService
  ) {
    // if (dialogConfig.data?.exitPw) {
    //   this.correctExitPw = dialogConfig.data.exitPw
    // }

    const activeStore = this.appSettings.getStore();
    this.isAdmin = !(activeStore ? activeStore.id : false);

    this.subsList.push(
      this.appSettings.activeStoreChanged$.subscribe((store) => {
        this.isAdmin = !store.id;
      })
    );

    this.subsList.push(
      this.appSettings.getKioskStoreSettingDetails().subscribe((kioskSettingsData) => {
        // console.log(kioskSettingsData);
        this.correctExitPw = kioskSettingsData.password;
      })
    );
  }

  ngOnInit(): void {
    this.passwordForm = this.fb.group({
      passwordField: new UntypedFormControl(""),
    });
    this.passwordFieldControl = this.passwordForm.controls["passwordField"];

    // this.subsList.push(this.passwordFieldControl.valueChanges.subscribe((newValue) => {
    //   console.log(newValue);
    // }));
  }

  closeDialog(leaveKioskPage: boolean) {
    this.dialogRef.close(leaveKioskPage);
  }

  onExitKioskModePressed() {
    const domNode = !window.isOnSamsungKiosk ? document.getElementById("intercom-container") : null;
    if (this.isAdmin) {
      this.closeDialog(true);
      if (domNode) domNode.style.display = "inline";
      this.messageService.add(this.alertMessage.getMessage("kiosk-password-success"));
    } else {
      const pwIsCorrect = this.validateExitPassword(this.passwordFieldControl.value);
      if (pwIsCorrect) {
        this.closeDialog(true);
        if (domNode) domNode.style.display = "inline";
        this.messageService.add(this.alertMessage.getMessage("kiosk-password-success"));
      } else {
        // alert("Password is incorrect");
        this.messageService.add(this.alertMessage.getMessage("kiosk-password-fail"));
      }
    }
  }

  validateExitPassword(inputPassword: string): boolean {
    // console.log(inputPassword);
    // console.log(this.correctExitPw);
    return inputPassword === this.correctExitPw;
  }

  ngOnDestroy(): void {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}
