/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Inject, Optional, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import { DBService } from "src/app/shared/services/db.service";
import {
  FORM_GROUP_TOKEN,
  DISPLAY_MODE_TOKEN,
  NestedFormComponent,
} from "src/app/forms/nested-form/nested-form.component";
import {
  InventorySaleChannel,
  StockControl,
  PriceControl,
  ProductDetails,
  UploadStatus,
  OnlineStatus,
} from "./inventory-sale-channel";
import { ChannelType } from "../../sale-channel/sale-channel";
import { SaleChannelInventoryService } from "../../sale-channel/sale-channel-inventory/sale-channel-inventory.service";
import { ConfirmationService } from "primeng/api";

@Component({
  selector: "taku-inventory-sale-channel",
  templateUrl: "./inventory-sale-channel.component.html",
  styleUrls: ["./inventory-sale-channel.component.scss"],
})
export class InventorySaleChannelComponent extends NestedFormComponent {
  @Input() channelType: ChannelType;
  @Input() showUseChannelSetting = true;
  @Input() isRefreshingCategories: boolean;
  @Output() onSyncCategoriesClick: EventEmitter<any> = new EventEmitter();

  enumStockControl = StockControl;
  enumPriceControl = PriceControl;
  enumProductDetails = ProductDetails;
  enum_upload_status = this.dbService.enum_upload_status;
  enum_online_status = this.dbService.enum_online_status;

  constructor(
    public fb: UntypedFormBuilder,
    public dbService: SaleChannelInventoryService,
    protected confirmationService: ConfirmationService,
    @Optional() @Inject(FORM_GROUP_TOKEN) formGroup?,
    @Optional() @Inject(DISPLAY_MODE_TOKEN) displayMode?
  ) {
    super(dbService, fb, formGroup, displayMode);
  }

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    return InventorySaleChannelComponent.set(fb, new InventorySaleChannel());
  }

  static set(fb: UntypedFormBuilder, inventorySaleChannel: InventorySaleChannel): UntypedFormGroup {
    const inventorySaleChannelGroup = fb.group(inventorySaleChannel);
    return inventorySaleChannelGroup;
  }

  static setArray(fb: UntypedFormBuilder, inventorySaleChannels: InventorySaleChannel[]) {
    const formArray = fb.array([]);
    inventorySaleChannels.map((inventorySaleChannel) => {
      const temp = fb.group(inventorySaleChannel);
      formArray.push(temp);
    });
    return formArray;
  }

  uploadStatusChange($event) {
    if (this._myForm.value.uploadStatus === UploadStatus.Yes) {
      this._myForm.get("onlineStatus").setValue(OnlineStatus.Published);
    } else if (this._myForm.value.uploadStatus === UploadStatus.No && this._myForm.value.channelInventoryId) {
      this.confirmationService.confirm({
        message:
          "Product that is marked as No under Upload Status will be removed from your online store. Are you sure you want to proceed?",
        rejectButtonStyleClass: "p-button-link",
        accept: () => {
          this._myForm.get("onlineStatus").setValue(OnlineStatus.Unpublished);
        },
        reject: () => {
          this._myForm.get("uploadStatus").setValue(UploadStatus.Yes);
        },
      });
    } else {
      this._myForm.get("onlineStatus").setValue(OnlineStatus.Unpublished);
    }
  }

  navigateSaleChannelProduct() {
    this.dbService
      ._getRequest(
        `${this.dbService.webUrl}TAKUeCommerceProductURL/${this._myForm.value.saleChannelId}/${this._myForm.value.inventoryId}`
      )
      .subscribe((_url: string) => {
        window.open(_url, "_blank");
      });
  }

  autoEnableNewProductChange() {
    if (!this._myForm.get("autoEnableNewProduct").value) {
      this._myForm.get("autoPublishNewProduct").setValue(false);
    }
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
