import { Store } from "../../store-settings/store/store";

export enum FrequencyType {
  DEFAULT_REFILL_FREQUENCY = "Default Refill Frequency",
  EVERY_30_DAYS = "Every 30 Days",
  EVERY_WEEK = "Every Week",
  EVERY_60_DAYS = "Every 60 Days",
}

export const frequencyTypeToNumDays = (freq: FrequencyType): number => {
  switch (freq) {
    case FrequencyType.EVERY_WEEK:
      return 7;
    case FrequencyType.EVERY_30_DAYS:
      return 30;
    case FrequencyType.EVERY_60_DAYS:
      return 60;
    default:
      console.error("Cannot calculate days for Default");
      return 0;
  }
};

export enum CancellationPolicy {
  ONLY_ALLOW_AFTER_3_RENEWALS = "Only Allow after 3 renewals",
  ONLY_ALLOW_AFTER_6_RENEWALS = "Only Allow after 6 renewals",
}

export enum FulfillmentLeadtime {
  IMMEDIATE = "Immediate (Max 1 Cycle)",
  ONE_DAY = "1 Day",
  TWO_DAYS = "2 Days",
  THREE_DAYS = "3 Days",
  ONE_WEEK = "1 Week",
  FOUR_WEEKS = "4 Weeks",
}

export class RecurringOrderSetting {
  id: number;
  recurringOrderName: string;
  recurringOrderFrequency: FrequencyType;
  isActive: boolean;
  recuringDiscountPercent: number;
  cancellationPolicy: CancellationPolicy;
  fulfillmentLeadTime: number;
  zoneId: number;
  stores: Store;

  constructor() {
    this.id = 0;
    this.recurringOrderName = "";
    this.recurringOrderFrequency = FrequencyType.EVERY_30_DAYS;
    this.isActive = true;
    this.recuringDiscountPercent = 0;
    this.cancellationPolicy = CancellationPolicy.ONLY_ALLOW_AFTER_3_RENEWALS;
    this.fulfillmentLeadTime = 0;
    this.stores = null;
  }
}
