<div class="topWrapper">
  <taku-form-header [toolbarTitle]="_headerTitle" displayMode="inline" [showActionBtns]="false" [showBackBtn]="false">
    <button pButton class="p-button-rounded" type="button" (click)="onClosePressed()" icon="pi pi-times"></button>
  </taku-form-header>
  <div class="bodyWrapper">
    <main>
      <div id="doc-fields">
        <table cellspacing="0">
          <tr>
            <td class="label-col">Doc#</td>
            <td class="value-col">
              {{ _saleChannelOrderDetail["vendorOrderNumber"] }}
            </td>
          </tr>
          <tr>
            <td class="label-col">Sale Channel</td>
            <td class="value-col">
              {{ _saleChannelOrder.saleChannel.channelName }}
            </td>
          </tr>
          <tr>
            <td class="label-col">Date & Time</td>
            <td class="value-col">
              {{ _saleChannelOrder.docDate | dateTimeFormat : _saleChannelOrder.docTime }}
              <!-- {{(new Date(_saleChannelOrder.createDate)).getFullYear() + "-" + ((new Date(_saleChannelOrder.createDate)).getMonth() + 1) + "-" + (new Date(_saleChannelOrder.createDate)).getDate() +","+ (new Date(_saleChannelOrder.createDate)).getHours() + ":" + (new Date(_saleChannelOrder.createDate)).getMinutes()}} -->
            </td>
          </tr>
          <tr>
            <td class="label-col">Customer Address</td>
            <td class="value-col">
              {{
                _saleChannelOrderDetail
                  ? _saleChannelOrderDetail["shippingPerson"]
                    ? _saleChannelOrderDetail["shippingPerson"]["street"]
                    : ""
                  : ""
              }}
              {{
                _saleChannelOrderDetail
                  ? _saleChannelOrderDetail["shippingPerson"]
                    ? _saleChannelOrderDetail["shippingPerson"]["city"]
                    : ""
                  : ""
              }}
              {{
                _saleChannelOrderDetail
                  ? _saleChannelOrderDetail["shippingPerson"]
                    ? _saleChannelOrderDetail["shippingPerson"]["stateOrProvinceCode"]
                    : ""
                  : ""
              }}
              {{
                _saleChannelOrderDetail
                  ? _saleChannelOrderDetail["shippingPerson"]
                    ? _saleChannelOrderDetail["shippingPerson"]["countryCode"]
                    : ""
                  : ""
              }}
              {{
                _saleChannelOrderDetail
                  ? _saleChannelOrderDetail["shippingPerson"]
                    ? _saleChannelOrderDetail["shippingPerson"]["postalCode"]
                    : ""
                  : ""
              }}
            </td>
          </tr>
          <tr>
            <td class="label-col">Customer Phone</td>
            <td class="value-col">
              {{ _saleChannelOrderDetail["shippingPerson"] ? _saleChannelOrderDetail["shippingPerson"]["phone"] : "" }}
              <!-- {{ _saleChannelOrderDetail['billingPerson']?(_saleChannelOrderDetail['billingPerson']['phone']):(_saleChannelOrderDetail['shippingPerson']?(_saleChannelOrderDetail['shippingPerson']['phone']):'') }} -->
            </td>
          </tr>
        </table>

        <table cellspacing="0">
          <tr>
            <td class="label-col">Total</td>
            <td class="value-col">
              {{ _saleChannelOrderDetail["total"] | currency : "CAD" : "symbol-narrow" : "1.2" }}
            </td>
          </tr>
          <tr class="payType-line">
            <td class="label-col">Payment Method</td>
            <td class="value-col">
              {{ _saleChannelOrderDetail["paymentMethod"] }}
            </td>
          </tr>
          <tr>
            <td class="label-col">Customer Name</td>
            <td class="value-col">
              {{
                _saleChannelOrderDetail
                  ? _saleChannelOrderDetail["shippingPerson"]
                    ? _saleChannelOrderDetail["shippingPerson"]["name"]
                    : ""
                  : ""
              }}
              <!-- {{_saleChannelOrderDetail? (_saleChannelOrderDetail['billingPerson']?_saleChannelOrderDetail['billingPerson']['companyName']: ''):'' +' ' + _saleChannelOrderDetail? (_saleChannelOrderDetail['billingPerson']? _saleChannelOrderDetail['billingPerson']['name']: ''): ''}} -->
            </td>
          </tr>
          <tr>
            <td class="label-col">Customer Email</td>
            <td class="value-col">
              {{ _saleChannelOrderDetail["email"] }}
            </td>
          </tr>
          <tr>
            <td class="label-col">Delivery Method</td>
            <td class="value-col">
              {{ _saleChannelOrderDetail["saleDoc"] ? _saleChannelOrderDetail["saleDoc"]["deliveryMethod"] : "" }}
            </td>
          </tr>
          <tr>
            <td class="label-col">Delivery Status</td>
            <td class="value-col">
              {{ _saleChannelOrderDetail["saleDoc"] ? _saleChannelOrderDetail["saleDoc"]["deliveryStatus"] : "" }}
            </td>
          </tr>
        </table>
      </div>
    </main>

    <taku-sale-doc-transactions *ngIf="_saleDoc?.saleDocLines" [saleDoc]="_saleDoc"></taku-sale-doc-transactions>

    <footer class="col-12 pb-4">
      <div class="right-btns col-12">
        <button
          pButton
          class="btn-action p-button-primary"
          label="Proceed"
          icon="pi pi-check"
          (click)="onProceedPressed()"
          [disabled]="_isProceedDisabled"
          [loading]="_isLoading"
        ></button>

        <button
          pButton
          pRipple
          class="btn-action p-button-secondary"
          label="Void"
          icon="pi pi-ban"
          (click)="onVoidPressed()"
          [disabled]="_isVoidDisabled"
        ></button>
      </div>
    </footer>
  </div>
</div>
