/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, OnDestroy } from "@angular/core";
import { Location } from "@angular/common";
import { MenuItem } from "primeng/api";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { Subscription } from "rxjs";
import { ActivatedRoute, Router, Event as NavigationEvent, NavigationEnd } from "@angular/router";
import { APP_BRAND } from "src/app/utility/white-label";

@Component({
  selector: "taku-sale-channel-container",
  templateUrl: "./sale-channel-container.component.html",
  styleUrls: ["./sale-channel-container.component.scss"],
})
export class SaleChannelContainerComponent implements OnInit, OnDestroy {
  private pageTitlePrefix = "Sales Channels";
  _pageTitle: string = this.pageTitlePrefix;
  _channelTitle: string = null;
  isAdmin: boolean;
  subsList: Subscription[] = [];
  _activeZoneId: number;
  _activeStoreId;
  _activeStore;
  channelType: string;
  _activeTab = 1;
  arrTabSelections: MenuItem[] = [
    // { label: "Inventory", routerLink: ["inventory", this._activeStoreId], queryParams: { title: this._channelTitle } },
    // { label: "Settings", routerLink: ["settings", this._activeStoreId], queryParams: { title: this._channelTitle, force: true } }
  ];
  active = true;
  branding = APP_BRAND;

  constructor(
    public locationService: Location,
    protected location: Location,
    private appSettings: AppSettingsStorageService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this._activeZoneId = appSettings.getZoneId();
    this._activeStoreId = appSettings.getStoreId();
    this._activeStore = appSettings.getStore();
    this.subsList.push(
      this.activatedRoute.queryParams.subscribe((params) => {
        this._channelTitle = params["title"];
        if (this._activeStoreId) {
          this.arrTabSelections = [
            {
              label: "Inventory",
              command: () => {
                this.menuClicked(0);
              },
              routerLink: ["inventory", this._activeStoreId],
              queryParams: {
                title: this._channelTitle,
                queryExtraParams: JSON.stringify({ stockId: this._activeStore.stock.id, zoneId: this._activeZoneId }),
              },
            },
            // { label: "Settings", command:() => {this.menuClicked(1)},routerLink: ["settings", this._activeStoreId], queryParams: { title: this._channelTitle, force: true } }
          ];
        }
      })
    );

    this.subsList.push(
      this.router.events.subscribe((event: NavigationEvent): void => {
        if (event instanceof NavigationEnd) {
          const routeStoreId = +event.url.replace(/.*\/(\d+)\??.*/g, "$1");
          this._activeStoreId = routeStoreId || this.appSettings.getStoreId();
          const _backOfficeId = this.appSettings.getBackofficeId();
          this.isAdmin = !this._activeStoreId;
          this._channelTitle =
            !this._activeStoreId && !_backOfficeId ? "Admin Mode" : _backOfficeId ? "Back Office" : this._channelTitle;
          // if (!this.isAdmin) {
          //   this.arrTabSelections = [
          //     { label: "Inventory",command:() => {this.menuClicked(0)}, routerLink: ["inventory", this._activeStoreId], queryParams: { title: this._channelTitle, queryExtraParams: JSON.stringify({ stockId: this._activeStore.stock.id, zoneId: this._activeZoneId }) } },
          //     { label: "Settings", command:() => {this.menuClicked(1)},routerLink: ["settings", this._activeStoreId], queryParams: { title: this._channelTitle, force: true } }
          //   ];
          // }
          this.active = false;
          setTimeout(() => (this.active = true), 0);
          this._activeTab = this.router.url.includes("/integrations/my-apps/sale-channel/settings/") ? 1 : 0;
        }
      })
    );

    this.subsList.push(
      this.appSettings.activeStoreChanged$.subscribe((store) => {
        this.isAdmin = !store.id;
        this._channelTitle = this.isAdmin ? "Admin Mode" : this._channelTitle;
        // if (!this.isAdmin) {
        //   this.arrTabSelections = [
        //     { label: "Inventory", command:() => {this.menuClicked(0)},routerLink: ["inventory", this._activeStoreId], queryParams: { title: this._channelTitle, queryExtraParams: JSON.stringify({ stockId: this._activeStore.stock.id, zoneId: this._activeZoneId }) } },
        //     { label: "Settings", command:() => {this.menuClicked(1)},routerLink: ["settings", this._activeStoreId], queryParams: { title: this._channelTitle, force: true } }
        //   ];
        // }
      })
    );
  }

  menuClicked(event) {
    this._activeTab = event;
  }

  onBack() {
    this.location.back();
  }

  ngOnInit(): void {
    // this._activeTab = this.router.url.includes('/sale-channel/settings/')?1:0;
  }

  ngOnDestroy(): void {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }

  storeChanged(storeId: number) {
    this.router.navigate(["/integrations/my-apps/sale-channel/settings", storeId], { queryParamsHandling: "preserve" });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
