<div class="col-12 progressBar-wrapper">
  <p-progressBar mode="indeterminate" [style]="{ height: '8pt' }"></p-progressBar>
  <div class="col-12 note">
    <span>{{ _note }}</span>
    <div class="img-container">
      <img *ngIf="_imgUrl" src="{{ _imgUrl }}" />
    </div>
    <div *ngIf="_optionalCancelBtnDetails">
      <button
        pButton
        pRipple
        class="p-button-raised p-button-danger mt-4"
        icon="pi pi-times"
        type="button"
        [label]="_optionalCancelBtnDetails.btnText"
        (click)="optionalCancelBtnClicked()"
        [disabled]="!_optionalCancelBtnOnClickReadyParams || _optionalCancelBtnClicked"
      ></button>
    </div>
  </div>
</div>
