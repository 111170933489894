/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, ElementRef, OnInit, OnDestroy } from "@angular/core";
import * as _ from "lodash";
import { forkJoin, ReplaySubject, Subscription } from "rxjs";
import { LocationService } from "../../../../shared/services/location.service";
import { PrintHelpers } from "../../../../utility/PrintHelpers";
import { CashoutSummaryDoc, DBCashoutService } from "../../../cashout/db-cashout.service";
import { Store } from "../../../settings/store-settings/store/store";
import { User } from "../../../users/user/user";
import { CashoutSlipBase } from "../CashoutSlipBase.interface";
import { BusinessDetail } from "src/app/core/settings/business-settings/business-detail/business-detail";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { SaleDoc } from "src/app/core/document/sale-document/sale-doc/sale-doc";
import { ApiListResponse } from "src/app/utility/types";
import { DocState, SaleDocType } from "src/app/core/document/doc/doc";
import {
  DiscountType,
  SaleDocLine,
  TotalBySaleTax,
} from "src/app/core/document/sale-document/sale-doc-line/sale-doc-line";
import { CashoutDeposits } from "src/app/core/cashout/cashout-closetill/cashout-closetill.component";
import { SaleDocLineTax } from "src/app/core/document/sale-document/sale-doc-line-tax/sale-doc-line-tax";
import { Category } from "src/app/core/inventory/category/category";

@Component({
  selector: "taku-cashout-report-tape-size",
  templateUrl: "./cashout-report-tape-size.component.html",
  styleUrls: ["../../../../../assets/layout/taku/cashout-report-tape-size.component.css"],
})
export class ClosingCashoutReportTapeSizeComponent extends CashoutSlipBase implements OnInit, OnDestroy {
  businessDetail: BusinessDetail;

  constructor(
    elementRef: ElementRef,
    private locationService: LocationService,
    private dbService: DBCashoutService,
    private appSettings: AppSettingsStorageService
  ) {
    super(elementRef);
  }
  private static readonly COMPILED_CSS_URL = "/assets/layout/taku/cashout-report-tape-size.component.css";
  subsList: Subscription[] = [];
  saleDocs: SaleDoc[];
  closingUser: User;
  printedDate: Date;
  _countryName: string = null;
  _digitsFormat = "1.2-2";
  _printedDate = new Date();

  categories: Category[];
  cashoutReportDetails: CashoutReportDetails[] = [];
  cashoutReportRefunds: CashoutReportRefunds[] = [];
  cashoutDeposits: CashoutDeposits[] = this.deposits;
  cashoutReportSalesSummary: CashoutReportSalesSummary[] = [];
  cashoutReportSalesCategory: CashoutReportSalesCategory[] = [];
  cashoutReportSalesTax: CashoutReportSalesTax[] = [];

  private _dataReadySubject = new ReplaySubject<boolean>(1);
  onDataReady$ = this._dataReadySubject.asObservable();

  ngOnInit(): void {
    const cashoutFilter = {
      cashoutId: {
        value: this.cashout.id,
        matchMode: "equals",
      },
    };

    this.subsList.push(
      forkJoin([
        this.dbService.getRow<Store>("store", this.cashout.storeId),
        this.dbService.getSummary(this.cashout.id),
        this.dbService.getRow<User>("user", this.cashout.closingCashFloatUserId),
        this.appSettings.getBusinessDetails(),
        this.dbService.getRows<ApiListResponse<SaleDoc>>("saleDoc", JSON.stringify(cashoutFilter), 0, -1, null, null, {
          includes:
            "saleDocTender,saleDocLine.saleDoc.doc,saleDocLine.docLine.inventory,saleDocLine.saleDocLineTax.taxRule",
        }),
        this.dbService.getRows<ApiListResponse<Category>>("category", null, 0, -1, null, null, null),
      ]).subscribe(
        ([store, cashoutsSummary, user, businessDetail, T, C]: [
          Store,
          CashoutSummaryDoc[],
          User,
          BusinessDetail,
          ApiListResponse<SaleDoc>,
          ApiListResponse<Category>
        ]) => {
          this.store = _.merge(new Store(), store);
          this.docsSummaries = cashoutsSummary.map((doc) => _.merge(new CashoutSummaryDoc(), doc));
          this.closingUser = _.merge(new User(), user);
          this.businessDetail = businessDetail;
          this.saleDocs = T.rows;
          this.categories = C.rows;

          //console.log(this.docsSummaries);
          //console.log(this.saleDocs);

          //--------------------------------------------------------------------------------------------------
          const saleDocRefunds = this.saleDocs.filter(
            (doc) => doc.doc.docType === SaleDocType.sales_return && doc.doc.state === DocState.finalized
          );

          const saleDocTenders = _.flatten(this.saleDocs.map((doc) => doc.saleDocTenders));
          const depositsCount = saleDocTenders.reduce((countList, { tenderTypeId }) => {
            const key = tenderTypeId;
            countList[key] = countList[key] || { tenderTypeId: key, count: 0 };
            countList[key].count += 1;
            return countList;
          }, {});

          for (let i = 0; i < this.deposits.length; i++) {
            const isExists = depositsCount[this.deposits[i].tenderTypeId] ? true : false;
            this.deposits[i].count = isExists ? depositsCount[this.deposits[i].tenderTypeId]["count"] : 0;
          }

          this.cashoutDeposits = this.deposits;
          if (saleDocRefunds.length > 0) {
            this.cashoutDeposits.forEach((element) => {
              saleDocRefunds.forEach((saleRefund) => {
                let isDeductQty = false;
                const refund = saleRefund.saleDocTenders.filter((line) => line.tenderTypeId === element.tenderTypeId);
                if (refund.some((line) => line.tenderTypeId === element.tenderTypeId)) {
                  isDeductQty = true;
                  element.count -= isDeductQty ? 1 : 0;
                }

                if (refund.length > 0) {
                  refund.forEach((line) => {
                    element.actualAmount =
                      parseFloat((element.actualAmount || "0") + "") - parseFloat((line.amount || "0") + "");
                    element.systemTotal = String(
                      parseFloat((element.systemTotal || "0") + "") - parseFloat((line.amount || "0") + "")
                    );
                  });
                }
              });
            });
          }

          let cashoutReportDetailLine: CashoutReportDetails;
          cashoutReportDetailLine = new CashoutReportDetails();
          cashoutReportDetailLine.docType = "Customer Count";
          const uniqueCustomer = this.saleDocs
            .filter((doc) => doc.accountId !== null)
            .reduce((countList, { accountId }) => {
              countList[accountId] = countList[accountId] === undefined ? 1 : (countList[accountId] += 1);
              return countList;
            }, {});
          cashoutReportDetailLine.docCount = Object.keys(uniqueCustomer).length;
          this.cashoutReportDetails.push(cashoutReportDetailLine);

          //--------------------------------------------------------------------------------------------------
          cashoutReportDetailLine = new CashoutReportDetails();
          cashoutReportDetailLine.docType = "Sales Docs, Avg";
          cashoutReportDetailLine.docCount = 0;
          cashoutReportDetailLine.docAverage = 0;
          let saleDetails = this.docsSummaries.filter(
            (doc) => doc.docType === SaleDocType.sales_invoice && doc.state === DocState.finalized
          );
          if (saleDetails.length > 0) {
            cashoutReportDetailLine.docCount = saleDetails[0].count_doc;
            cashoutReportDetailLine.docAverage =
              saleDetails[0].count_doc != 0 ? saleDetails[0].grandTotal / saleDetails[0].count_doc : 0;
          }
          this.cashoutReportDetails.push(cashoutReportDetailLine);

          //--------------------------------------------------------------------------------------------------
          cashoutReportDetailLine = new CashoutReportDetails();
          cashoutReportDetailLine.docType = "Line Discounts, Avg";
          let isLineDiscount = false;
          let lineDiscountCount = 0;
          let lineDiscountAmount = 0;
          this.saleDocs.forEach((element) => {
            /*
            if (
              element.saleDocLines &&
              element.saleDocLines.some((line) => line.discountAmount !== "0.0000") &&
              element.doc.docType === SaleDocType.sales_invoice &&
              element.doc.state === DocState.finalized
            ) {
              isLineDiscount = true;
              lineDiscountCount += isLineDiscount ? 1 : 0;
            }
            */
            const lineDocsDiscount = element.saleDocLines.filter(
              (line) =>
                line.discountAmount !== 0 &&
                line.discountAmount !== "0.0000" &&
                line.discountSetManually === true &&
                element.doc.state === DocState.finalized &&
                (element.doc.docType === SaleDocType.sales_invoice || element.doc.docType === SaleDocType.sales_return)
            );

            lineDiscountCount += lineDocsDiscount.length;

            if (
              element.doc.state === DocState.finalized &&
              (element.doc.docType === SaleDocType.sales_invoice || element.doc.docType === SaleDocType.sales_return)
            ) {
              element.saleDocLines.forEach((line) => {
                let qty = line.qty;
                if (element.doc.docType === SaleDocType.sales_invoice && element.doc.state === DocState.finalized) {
                  qty = line.qty;
                } else if (
                  element.doc.docType === SaleDocType.sales_return &&
                  element.doc.state === DocState.finalized
                ) {
                  qty = -line.qty;
                }

                if (line.discountAmount !== 0) {
                  switch (line.discountType) {
                    case DiscountType.Fixed:
                      lineDiscountAmount +=
                        line.discountSetManually === true ? qty * parseFloat((line.discountAmount || "0") + "") : 0;
                      break;
                    case DiscountType.Percentage:
                      lineDiscountAmount +=
                        line.discountSetManually === true
                          ? (qty * (line.unitPrice * parseFloat((line.discountAmount || "0") + ""))) / 100
                          : 0;
                      break;
                    case DiscountType.Final_Price:
                      lineDiscountAmount +=
                        line.discountSetManually === true ? qty * (line.unitPrice - +line.discountAmount) : 0;
                      break;
                  }
                }
              });
            }
          });
          cashoutReportDetailLine.docCount = lineDiscountCount;
          cashoutReportDetailLine.docAverage = lineDiscountCount != 0 ? lineDiscountAmount / lineDiscountCount : 0;
          this.cashoutReportDetails.push(cashoutReportDetailLine);

          //--------------------------------------------------------------------------------------------------
          cashoutReportDetailLine = new CashoutReportDetails();
          cashoutReportDetailLine.docType = "Tender Discounts, Avg";
          cashoutReportDetailLine.docCount = this.saleDocs.filter(
            (doc) =>
              parseFloat((doc.discountAmount || "0") + "") !== 0 &&
              doc.doc.state === DocState.finalized &&
              (doc.doc.docType === SaleDocType.sales_invoice || doc.doc.docType === SaleDocType.sales_return)
          ).length;

          let tenderDiscountAmount = 0;
          const saleTenderDiscounts = this.saleDocs.filter(
            (doc) =>
              parseFloat((doc.discountAmount || "0") + "") !== 0 &&
              doc.doc.state === DocState.finalized &&
              (doc.doc.docType === SaleDocType.sales_invoice || doc.doc.docType === SaleDocType.sales_return)
          );

          saleTenderDiscounts.forEach((element) => {
            switch (element.discountType) {
              case DiscountType.Fixed:
                tenderDiscountAmount += element.discountAmount;
                break;
              case DiscountType.Percentage:
                tenderDiscountAmount += (element.subTotal * element.discountAmount) / 100;
                break;
            }
          });
          cashoutReportDetailLine.docAverage =
            saleTenderDiscounts.length != 0 ? tenderDiscountAmount / saleTenderDiscounts.length : 0;
          this.cashoutReportDetails.push(cashoutReportDetailLine);

          //--------------------------------------------------------------------------------------------------
          cashoutReportDetailLine = new CashoutReportDetails();
          cashoutReportDetailLine.docType = "Return Docs, Avg";
          saleDetails = this.docsSummaries.filter((doc) => doc.docType === SaleDocType.sales_return);
          cashoutReportDetailLine.docCount = 0;
          cashoutReportDetailLine.docAverage = 0;
          if (saleDetails.length > 0) {
            cashoutReportDetailLine.docCount = saleDetails[0].count_doc;
            cashoutReportDetailLine.docAverage =
              saleDetails[0].count_doc != 0 ? saleDetails[0].grandTotal / saleDetails[0].count_doc : 0;
          }
          this.cashoutReportDetails.push(cashoutReportDetailLine);

          //--------------------------------------------------------------------------------------------------
          cashoutReportDetailLine = new CashoutReportDetails();
          cashoutReportDetailLine.docType = "Voided Docs, Avg";
          cashoutReportDetailLine.docCount = 0;
          cashoutReportDetailLine.docAverage = 0;
          saleDetails = this.docsSummaries.filter(
            (doc) => doc.docType === SaleDocType.sales_invoice && doc.state === DocState.voided
          );
          if (saleDetails.length > 0) {
            cashoutReportDetailLine.docCount = saleDetails[0].count_doc;
            cashoutReportDetailLine.docAverage =
              saleDetails[0].count_doc != 0 ? saleDetails[0].grandTotal / saleDetails[0].count_doc : 0;
          }
          this.cashoutReportDetails.push(cashoutReportDetailLine);

          //--------------------------------------------------------------------------------------------------
          let totalRefunds = 0;
          let totalCashRoundingRefunds = 0;
          if (saleDocRefunds.length > 0) {
            saleDocRefunds.forEach((element) => {
              totalCashRoundingRefunds += element.cashRounding;
              element.saleDocTenders.forEach((tender) => (totalRefunds += parseFloat((tender.amount || "0") + "")));
            });
          }
          const cashoutReportRefundLine = new CashoutReportRefunds();
          cashoutReportRefundLine.docType = "Total Refunds";
          cashoutReportRefundLine.docCount = saleDocRefunds.length;
          cashoutReportRefundLine.cashRounding = totalCashRoundingRefunds;
          cashoutReportRefundLine.systemAmount = totalRefunds;
          cashoutReportRefundLine.actualAmount = totalRefunds;
          this.cashoutReportRefunds.push(cashoutReportRefundLine);

          //--------------------------------------------------------------------------------------------------
          let cashoutReportSalesSummaryLine = new CashoutReportSalesSummary();
          cashoutReportSalesSummaryLine.docType = "Sales";
          cashoutReportSalesSummaryLine.docCount = this.saleDocs.length;
          let summarySaleAmount = 0;
          let summaryLineDiscount = 0;
          let summaryTenderDiscount = 0;
          this.saleDocs.forEach((saleDoc) => {
            let subTotal = 0;
            let docLineDiscounts = 0;
            let docTenderDiscounts = 0;
            if (saleDoc.doc.docType === SaleDocType.sales_invoice && saleDoc.doc.state === DocState.finalized) {
              subTotal = saleDoc.subTotal;
            } else if (saleDoc.doc.docType === SaleDocType.sales_invoice && saleDoc.doc.state === DocState.voided) {
              subTotal = 0;
            } else if (saleDoc.doc.docType === SaleDocType.sales_return && saleDoc.doc.state === DocState.finalized) {
              subTotal = -1 * saleDoc.subTotal;
            }

            summarySaleAmount += parseFloat((subTotal || "0") + "");

            /*
            if (
              parseFloat((saleDoc.discountAmount || "0") + "") !== 0 &&
              saleDoc.doc.docType === SaleDocType.sales_invoice &&
              saleDoc.doc.state === DocState.finalized
            ) {
              switch (saleDoc.discountType) {
                case DiscountType.Fixed:
                  tenderDiscountAmount += parseFloat((saleDoc.discountAmount || "0") + "");
                  docTenderDiscounts = parseFloat((saleDoc.discountAmount || "0") + "");
                  break;
                case DiscountType.Percentage:
                  tenderDiscountAmount += (subTotal * saleDoc.discountAmount) / 100;
                  docTenderDiscounts = (subTotal * saleDoc.discountAmount) / 100;
                  break;
              }
            }
            summarySaleAmount += docTenderDiscounts;
            */

            saleDoc.saleDocLines.forEach((line) => {
              let qty = line.qty;
              if (saleDoc.doc.docType === SaleDocType.sales_invoice && saleDoc.doc.state === DocState.finalized) {
                qty = line.qty;
              } else if (saleDoc.doc.docType === SaleDocType.sales_invoice && saleDoc.doc.state === DocState.voided) {
                qty = 0;
              } else if (saleDoc.doc.docType === SaleDocType.sales_return && saleDoc.doc.state === DocState.finalized) {
                qty = -line.qty;
              }

              if (line.discountAmount !== 0 && line.discountAmount !== "0.0000" && line.discountSetManually === true) {
                switch (line.discountType) {
                  case DiscountType.Fixed:
                    summaryLineDiscount += qty * parseFloat((line.discountAmount || "0") + "");
                    docLineDiscounts += qty * parseFloat((line.discountAmount || "0") + "");
                    break;
                  case DiscountType.Percentage:
                    summaryLineDiscount +=
                      (qty * (line.unitPrice * parseFloat((line.discountAmount || "0") + ""))) / 100;
                    docLineDiscounts += (qty * (line.unitPrice * parseFloat((line.discountAmount || "0") + ""))) / 100;
                    break;
                  case DiscountType.Final_Price:
                    summaryLineDiscount += qty * (line.unitPrice - parseFloat((line.discountAmount || "0") + ""));
                    docLineDiscounts += qty * (line.unitPrice - parseFloat((line.discountAmount || "0") + ""));
                    break;
                }
              }
            });

            summarySaleAmount += docLineDiscounts;
          });
          cashoutReportSalesSummaryLine.systemAmount = summarySaleAmount;
          this.cashoutReportSalesSummary.push(cashoutReportSalesSummaryLine);

          //--------------------------------------------------------------------------------------------------
          cashoutReportSalesSummaryLine = new CashoutReportSalesSummary();
          cashoutReportSalesSummaryLine.docType = "Line Discounts";
          cashoutReportSalesSummaryLine.docCount = lineDiscountCount;
          cashoutReportSalesSummaryLine.systemAmount = -summaryLineDiscount;
          this.cashoutReportSalesSummary.push(cashoutReportSalesSummaryLine);

          //--------------------------------------------------------------------------------------------------
          cashoutReportSalesSummaryLine = new CashoutReportSalesSummary();
          cashoutReportSalesSummaryLine.docType = "Tender Discounts";
          cashoutReportSalesSummaryLine.docCount = saleTenderDiscounts.length;
          cashoutReportSalesSummaryLine.systemAmount = -tenderDiscountAmount;
          this.cashoutReportSalesSummary.push(cashoutReportSalesSummaryLine);

          //--------------------------------------------------------------------------------------------------
          const saleDocsRounding = this.saleDocs.filter(
            (doc) =>
              ((doc.doc.docType === SaleDocType.sales_invoice && doc.doc.state === DocState.finalized) ||
                (doc.doc.docType === SaleDocType.sales_return && doc.doc.state === DocState.finalized)) &&
              parseFloat((doc.cashRounding || "0") + "") !== 0
          );
          cashoutReportSalesSummaryLine = new CashoutReportSalesSummary();
          cashoutReportSalesSummaryLine.docType = "Adjustment, Rounding";
          cashoutReportSalesSummaryLine.docCount = saleDocsRounding.length;
          cashoutReportSalesSummaryLine.systemAmount = saleDocsRounding.reduce((acc, rounding) => {
            return acc + parseFloat(rounding.cashRounding + "");
          }, 0);
          this.cashoutReportSalesSummary.push(cashoutReportSalesSummaryLine);

          //--------------------------------------------------------------------------------------------------
          const saleDocLines = _.flatten(this.saleDocs.map((doc) => doc.saleDocLines));

          const totalTaxSale = this.groupedTaxLines(
            saleDocLines.filter(
              (saleDocLine) =>
                saleDocLine.saleDoc.doc.docType === SaleDocType.sales_invoice &&
                saleDocLine.saleDoc.doc.state === DocState.finalized
            )
          );

          const totalTaxRetun = this.groupedTaxLines(
            saleDocLines.filter(
              (saleDocLine) =>
                saleDocLine.saleDoc.doc.docType === SaleDocType.sales_return &&
                saleDocLine.saleDoc.doc.state === DocState.finalized
            )
          );

          totalTaxSale.forEach((tax) => {
            const cashoutReportSalesTaxline = new CashoutReportSalesTax();
            cashoutReportSalesTaxline.docType = tax.saleTaxRule.ruleName;
            cashoutReportSalesTaxline.systemAmount = tax.total;
            this.cashoutReportSalesTax.push(cashoutReportSalesTaxline);
          });

          totalTaxRetun.forEach((tax) => {
            const temp = this.cashoutReportSalesTax.filter((line) => line.docType == tax.saleTaxRule.ruleName);
            if (temp.length === 0) {
              const cashoutReportSalesTaxline = new CashoutReportSalesTax();
              cashoutReportSalesTaxline.docType = tax.saleTaxRule.ruleName;
              cashoutReportSalesTaxline.systemAmount = tax.total;
              this.cashoutReportSalesTax.push(cashoutReportSalesTaxline);
            } else {
              this.cashoutReportSalesTax.forEach((line) => {
                if (line.docType == tax.saleTaxRule.ruleName) {
                  line.systemAmount -= tax.total;
                }
              });
            }
          });

          saleDocLines.forEach((saleDocLine) => {
            let categorySaleAmount = 0;
            let qty = saleDocLine.qty;
            if (
              saleDocLine.saleDoc.doc.docType === SaleDocType.sales_invoice &&
              saleDocLine.saleDoc.doc.state === DocState.finalized
            ) {
              qty = saleDocLine.qty;
            } else if (
              saleDocLine.saleDoc.doc.docType === SaleDocType.sales_invoice &&
              saleDocLine.saleDoc.doc.state === DocState.voided
            ) {
              qty = 0;
            } else if (
              saleDocLine.saleDoc.doc.docType === SaleDocType.sales_return &&
              saleDocLine.saleDoc.doc.state === DocState.finalized
            ) {
              qty = -saleDocLine.qty;
            }
            categorySaleAmount += parseFloat((saleDocLine.unitPrice * qty || "0") + "");

            if (saleDocLine.discountAmount !== 0 && saleDocLine.discountAmount !== "0.0000") {
              switch (saleDocLine.discountType) {
                case DiscountType.Fixed:
                  categorySaleAmount -= qty * parseFloat((saleDocLine.discountAmount || "0") + "");
                  break;
                case DiscountType.Percentage:
                  categorySaleAmount -=
                    (qty * (saleDocLine.unitPrice * parseFloat((saleDocLine.discountAmount || "0") + ""))) / 100;
                  break;
                case DiscountType.Final_Price:
                  categorySaleAmount -= qty * (saleDocLine.unitPrice - +saleDocLine.discountAmount);
                  break;
              }
            }

            const temp = this.cashoutReportSalesCategory.filter(
              (line) => line.categoryId == saleDocLine.docLine.inventory.defaultReportCategoryId
            );

            if (temp.length === 0) {
              const cashoutReportSalesCategoryLine = new CashoutReportSalesCategory();
              const categoryName = this.categories.find(
                (category) => category.id === saleDocLine.docLine.inventory.defaultReportCategoryId
              )?.categoryName;
              cashoutReportSalesCategoryLine.categoryId = saleDocLine.docLine.inventory.defaultReportCategoryId;
              cashoutReportSalesCategoryLine.docCount = saleDocLine.qty;
              cashoutReportSalesCategoryLine.systemAmount = categorySaleAmount;
              cashoutReportSalesCategoryLine.docType = categoryName === undefined ? "No Category" : categoryName;
              this.cashoutReportSalesCategory.push(cashoutReportSalesCategoryLine);
            } else {
              this.cashoutReportSalesCategory.forEach((line) => {
                if (line.categoryId === saleDocLine.docLine.inventory.defaultReportCategoryId) {
                  line.docCount += saleDocLine.qty;
                  line.systemAmount += categorySaleAmount;
                }
              });
            }
          });

          // Sort by category
          this.cashoutReportSalesCategory.sort((A, B) => {
            const docTypeA = A.docType.toUpperCase();
            const docTypeB = B.docType.toUpperCase();
            if (docTypeA > docTypeB) {
              return 1;
            } else if (docTypeA < docTypeB) {
              return -1;
            }
            return 0;
          });

          this.printedDate = new Date();
          if (this.store.address)
            this._countryName = this.locationService.lookupCountryByIsoCode(this.store.address.countryIsoCode);

          this._dataReadySubject.next(true);
        }
      )
    );
  }

  getStylesheets(): string[] {
    return [ClosingCashoutReportTapeSizeComponent.COMPILED_CSS_URL];
  }

  sendToPrinter(): void {
    PrintHelpers.printElementContents(this.elementRef.nativeElement, [
      ClosingCashoutReportTapeSizeComponent.COMPILED_CSS_URL,
    ]);
  }

  get businessLogoUrl(): string {
    const logoUrl = this.store.urlStoreLogoUrl || this.businessDetail.urlBusinessDetailBusinessLogoUrl;
    return logoUrl;
  }

  ngOnDestroy(): void {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }

  get totalSystemAmount(): number {
    const initialVal = 0;
    if (!this.cashoutDeposits) return initialVal;
    return this.cashoutDeposits.reduce((acc, deposit) => {
      return acc + parseFloat(deposit.systemTotal);
    }, initialVal);
  }

  get totalActualAmount(): number {
    const initialVal = 0;
    if (!this.cashoutDeposits) return initialVal;

    return this.cashoutDeposits.reduce((acc, deposit) => {
      return acc + parseFloat(deposit.actualAmount + "");
    }, initialVal);
  }

  get totalQtyPayout(): number {
    const initialVal = 0;
    if (!this.cashoutReportRefunds) return initialVal;
    return this.cashoutReportRefunds.reduce((acc, refund) => {
      return acc + Number(refund?.docCount);
    }, initialVal);
  }

  get totalSystemAmountPayout(): number {
    const initialVal = 0;
    if (!this.cashoutReportRefunds) return initialVal;
    return this.cashoutReportRefunds.reduce((acc, refund) => {
      return acc + Number(refund?.systemAmount);
    }, initialVal);
  }

  get totalActualAmountPayout(): number {
    const initialVal = 0;
    if (!this.cashoutReportRefunds) return initialVal;
    return this.cashoutReportRefunds.reduce((acc, refund) => {
      return acc + Number(refund?.actualAmount);
    }, initialVal);
  }

  get totalQtyNetTender(): number {
    const initialVal = 0;
    if (!this.docsSummaries) return initialVal;
    return this.docsSummaries.reduce((acc, docsSummary) => {
      return acc + Number(docsSummary?.count_doc);
    }, initialVal);
  }

  get totalSystemAmountNetTender(): number {
    const initialVal = 0;
    if (!this.totalSystemAmount) return initialVal;
    return this.totalSystemAmount + this.totalSystemAmountPayout;
  }

  get totalActualAmountNetTender(): number {
    const initialVal = 0;
    if (!this.totalActualAmount) return initialVal;
    return this.totalActualAmount + this.totalActualAmountPayout;
  }

  get totalQtySalesSummary(): number {
    const initialVal = 0;
    if (!this.cashoutReportSalesSummary) return initialVal;
    return this.cashoutReportSalesSummary.reduce((acc, salesSummary) => {
      return acc + Number(salesSummary?.docCount);
    }, initialVal);
  }

  get totalSystemSalesSummary(): number {
    const initialVal = 0;
    if (!this.cashoutReportSalesSummary) return initialVal;
    return this.cashoutReportSalesSummary.reduce((acc, salesSummary) => {
      return acc + Number(salesSummary?.systemAmount);
    }, initialVal);
  }

  get totalSystemTax(): number {
    const initialVal = 0;
    if (!this.cashoutReportSalesTax) return initialVal;
    return this.cashoutReportSalesTax.reduce((acc, salesTax) => {
      return acc + Number(salesTax?.systemAmount);
    }, initialVal);
  }

  get totalSystemSalesCategory(): number {
    const initialVal = 0;
    if (!this.cashoutReportSalesCategory) return initialVal;
    return this.cashoutReportSalesCategory.reduce((acc, salesCategory) => {
      return acc + Number(salesCategory?.systemAmount);
    }, initialVal);
  }

  groupedTaxLines(saleDocLines: SaleDocLine[]): TotalBySaleTax[] {
    const linesTaxes = _.flatten(saleDocLines.map((saleLine) => saleLine.saleDocLineTaxes));
    const groupedTaxes = _.groupBy(linesTaxes, (lineTax: SaleDocLineTax) => lineTax.taxRuleId);

    const totalGrouped = _.map(groupedTaxes, (taxes: SaleDocLineTax[]) => {
      if (!taxes[0].taxRule)
        return {
          taxes: taxes,
          saleTaxRule: null,
          total: _.sumBy(taxes, (tax) => parseFloat(tax.amount + "")),
        };

      return {
        taxes: taxes,
        saleTaxRule: taxes[0].taxRule,
        total: _.sumBy(taxes, (tax) => parseFloat(tax.amount + "")),
      };
    });

    return totalGrouped;
  }
}

export class CashoutReportDetails {
  docType: string;
  docCount: number;
  docAverage: number;
}

export class CashoutReportRefunds {
  docType: string;
  docCount: number;
  systemAmount: number;
  actualAmount: number;
  cashRounding: number;
}

export class CashoutReportSalesSummary {
  docType: string;
  docCount: number;
  systemAmount: number;
  cashRounding: number;
}

export class CashoutReportSalesCategory {
  categoryId: number;
  docType: string;
  docCount: number;
  systemAmount: number;
  cashRounding: number;
}

export class CashoutReportSalesTax {
  docType: string;
  systemAmount: number;
  cashRounding: number;
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
