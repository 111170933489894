/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { RecurringOrderSettingsService } from "./recurring-order-settings.service";
import { Location } from "@angular/common";
import { FormListMainComponent } from "src/app/form-list/form-list-main/form-list-main.component";
import { DialogService } from "primeng/dynamicdialog";
import { FormListColumnsService } from "src/app/form-list/form-list-columns.service";

@Component({
  selector: "taku-recurring-order-settings-form-list",
  templateUrl: "./recurring-order-settings-form-list.component.html",
})
export class RecurringOrdersFormListComponent extends FormListMainComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];

  activeZoneName = "";

  activeFilter = {};

  constructor(
    protected location: Location,
    protected _route: ActivatedRoute,
    protected formListColumnsService: FormListColumnsService,
    public dialogService: DialogService,
    public recurringOrderSettingsService: RecurringOrderSettingsService
  ) {
    super(location, _route, formListColumnsService);
  }

  get _pageTitle() {
    return (
      "Recurring Order Setup" +
      (this.activeZoneName ? `&nbsp;<span class="secondary-text">(${this.activeZoneName})</span>` : "")
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.subsList.push(
      this._route.params.subscribe((params) => {
        if (params.zoneId) {
          const zoneId = +params.zoneId;
          // Set filters to show only the current zone
          this.activeFilter = {
            zoneId: { value: zoneId, matchMode: "equals" },
          };
          this._defaultRowValues = { zoneId };
        }
      })
    );
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }

  goBack(): void {
    this.location.back();
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
