<div *ngFor="let page of pagesInfo" id="fullpage-preview-main">
  <header>
    <section *ngIf="store" id="retailer-info">
      <h2 class="retailer-name">{{ store.storeName }}</h2>
      <div class="left-side">
        <div
          class="retailer-logo-wrapper"
          [ngStyle]="{ backgroundImage: businessLogoUrl ? 'url(' + businessLogoUrl + ')' : null }"
        >
          <img *ngIf="businessLogoUrl" [attr.src]="businessLogoUrl" [attr.alt]="businessDetail.businessName" />
          <span *ngIf="!businessLogoUrl" class="retailer-logo-placeholder">YOUR LOGO HERE</span>
        </div>
      </div>

      <div class="right-side">
        <div *ngIf="store.address" class="retailer-address">
          <div class="address-line1">{{ store.address?.line1 }}</div>
          <div class="address-city"
            >{{ store.address?.city }}, {{ subdivisionDisplay(store.address?.subDivisionIsoCode) }}
            {{ store.address?.postalCode }}</div
          >
          <div class="address-country">{{ _countryName | uppercase }}</div>
        </div>

        <div class="retailer-bottom-right">
          <div *ngIf="store.address?.addressPhone" class="retailer-phone">
            <span class="phone-label">Phone:</span>
            {{ store.address?.addressPhone.fullPhoneNumber }}
          </div>

          <div *ngIf="store.address?.addressEmail" class="retailer-email">
            <span class="email-label">Email:</span>
            {{ store.address?.addressEmail.email }}
          </div>
        </div>
      </div>
    </section>

    <div class="right-side">
      <div class="printpage-no">-- Page {{ page.pageNumber }} of {{ totalPages }} --</div>
      <section id="slip-details">
        <h1>
          <span class="slip-doctype">CASHOUT REPORT</span>
          <span class="slip-docno">ID: {{ cashout.id }}</span>
        </h1>
        <ul>
          <li>
            <span class="slip-field-label">Printed:</span>
            {{ printedDate | dateTimeFormat : printedDate.toTimeString() }}
          </li>

          <li *ngIf="cashout.closingDate">
            <span class="slip-field-label">Closing Date:</span>
            {{ cashout.closingDate | dateTimeFormat : cashout.closingTime }}
          </li>

          <li *ngIf="closingUser">
            <span class="slip-field-label">Closing User:</span> {{ closingUser.person.fullName | uppercase }}
          </li>

          <li> <span class="slip-field-label">Cashout ID:</span> {{ cashout.id }} </li>
          <li> <span class="slip-field-label">Station ID:</span> {{ cashout.station.stationNumber }} </li>
        </ul>
      </section>
    </div>
  </header>

  <main>
    <table
      *ngIf="page.docsSummaries"
      id="document-summary"
      cellspacing="0"
      [ngClass]="{ 'no-system-col': !showSystemValues }"
    >
      <thead>
        <tr>
          <th colspan="3" class="table-title">DOCUMENT SUMMARY</th>
        </tr>
        <tr>
          <th class="col-description">DESCRIPTION</th>
          <th class="col-qty">QTY</th>
          <th class="col-amount">TOTAL</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let doc of page.docsSummaries">
          <td class="col-description" [ngClass]="{ disabled: doc.state === 'Voided' }"
            >{{ doc.state === "Voided" ? "Voided " : "" }}{{ doc.docType }}</td
          >
          <td class="col-qty" [ngClass]="{ disabled: doc.state === 'Voided' }">{{ doc.count_doc }}</td>
          <td class="col-amount" [ngClass]="{ disabled: doc.state === 'Voided' }">{{
            doc.grandTotal | currency : currencyIsoCode : "symbol-narrow" : _digitsFormat
          }}</td>
        </tr>
        <tr *ngIf="!page.docsSummaries || !page.docsSummaries.length">
          <td colspan="3" class="nofound-docs">- No documents found for this cashout -</td>
        </tr>
      </tbody>
    </table>

    <table
      *ngIf="page.deposits"
      id="deposit-summary"
      cellspacing="0"
      [ngClass]="{ 'no-system-col': !showSystemValues }"
    >
      <thead>
        <tr>
          <th [attr.colspan]="showSystemValues ? 3 : 2" class="table-title">DEPOSIT SUMMARY</th>
        </tr>
        <tr>
          <th class="col-description">DEPOSITS</th>
          <th *ngIf="showSystemValues" class="col-system">SYSTEM</th>
          <th class="col-amount">ACTUAL</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let deposit of page.deposits">
          <td class="col-description">{{ deposit.tenderDescription }}</td>
          <td *ngIf="showSystemValues" class="col-system">{{
            deposit.systemTotal || 0 | currency : currencyIsoCode : "symbol-narrow" : _digitsFormat
          }}</td>
          <td class="col-amount">{{
            deposit.actualAmount || 0 | currency : currencyIsoCode : "symbol-narrow" : _digitsFormat
          }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr class="total-row">
          <td *ngIf="showSystemValues" class="col-system" colspan="2">{{
            totalSystemAmount | currency : currencyIsoCode : "symbol-narrow" : _digitsFormat
          }}</td>
          <td class="col-amount" [attr.colspan]="!showSystemValues ? 2 : 1">{{
            totalActualAmount | currency : currencyIsoCode : "symbol-narrow" : _digitsFormat
          }}</td>
        </tr>
        <tr>
          <td class="col-description" [attr.colspan]="showSystemValues ? 2 : 1">CASHOUT CASH ROUNDING</td>
          <td class="col-amount">{{
            totalPennyRounding | currency : currencyIsoCode : "symbol-narrow" : _digitsFormat | formatNegativeNumber
          }}</td>
        </tr>
      </tfoot>
    </table>

    <table *ngIf="cashout.closingNote" id="notes-table" cellspacing="0" style="border: none">
      <thead>
        <tr>
          <td class="notes" [attr.colspan]="showSystemValues ? 3 : 2" style="border: none">
            <div class="text">Notes: {{ cashout.closingNote }}</div>
          </td>
        </tr>
      </thead>
    </table>

    <table
      *ngIf="showSignatureLine"
      id="signature-table"
      cellspacing="0"
      [ngClass]="{ 'no-system-col': !showSystemValues }"
    >
      <thead>
        <tr>
          <td class="signature" [attr.colspan]="showSystemValues ? 4 : 3">
            <div class="text">Signature</div>
          </td>
        </tr>
      </thead>
    </table>

    <!-- <table id="deposit-summary" class="no-system-col" cellspacing="0">
          <thead>
              <tr>
                  <th colspan="2" class="table-title">DEPOSIT SUMMARY</th>
              </tr>
              <tr>
                  <th class="col-description">DEPOSITS</th>
                  <th class="col-amount">ACTUAL</th>
              </tr>
          </thead>

          <tbody>
              <tr>
                  <td class="col-description">CASH FOR DEPOSIT</td>
                  <td class="col-amount">$9,999.99</td>
              </tr>
              <tr>
                  <td class="col-description">CREDIT CARD - VISA</td>
                  <td class="col-amount">$9,999.99</td>
              </tr>
              <tr>
                  <td class="col-description">CREDIT CARD - MASTER CARD</td>
                  <td class="col-amount">$9,999.99</td>
              </tr>
              <tr>
                  <td class="col-description">DEBIT CARD</td>
                  <td class="col-amount">$9,999.99</td>
              </tr>
              <tr>
                  <td class="col-description">STORE GIFT CARDS</td>
                  <td class="col-amount">$9,999.99</td>
              </tr>
              <tr>
                  <td class="col-description">PAYPAL</td>
                  <td class="col-amount">$9,999.99</td>
              </tr>
              <tr>
                  <td class="col-description">CHECK/CHEQUE</td>
                  <td class="col-amount">$9,999.99</td>
              </tr>
              <tr>
                  <td class="col-description">DIGITAL TOKEN</td>
                  <td class="col-amount">$9,999.99</td>
              </tr>
              <tr>
                  <td class="col-description">STORE CREDITS</td>
                  <td class="col-amount">$9,999.99</td>
              </tr>
              <tr>
                  <td class="col-description">FUND TRANSFER</td>
                  <td class="col-amount">$9,999.99</td>
              </tr>
          </tbody>
          <tfoot>
              <tr class="total-row">
                  <td class="col-system" colspan="1">$100,050.00</td>
                  <td class="col-amount">$90,800.84</td>
              </tr>
              <tr>
                  <td class="col-description" colspan="1">CASHOUT PENNY ROUNDING</td>
                  <td class="col-amount">($10.01)</td>
              </tr>
          </tfoot>
      </table> -->
  </main>

  <footer>
    <div class="printpage-no">-- Page {{ page.pageNumber }} of {{ totalPages }} --</div>
  </footer>
</div>
