/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Role } from "../role/role";
import { BackOffice } from "../../settings/backOffice-settings/backOffice/backOffice";
import { Store } from "../../settings/store-settings/store/store";

export class UserRole {
  id: number;
  roleId: number;
  role: Role;
  stores: Store[];
  backOffices: BackOffice[];

  constructor() {
    this.id = 0;
    this.roleId = null;
    this.role = null;
    this.stores = [];
    this.backOffices = [];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
