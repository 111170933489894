/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Input, ViewChild } from "@angular/core";
import { MultiSelect } from "primeng/multiselect";
import { TakuInputCtrlBase } from "../TakuInputCtrlBase";

@Component({
  selector: "taku-multiselect",
  templateUrl: "./taku-multiselect.component.html",
  styleUrls: ["./taku-multiselect.component.scss"],
})
export class TakuMultiselectComponent extends TakuInputCtrlBase {
  @Input("appendTo") _appendTo? = null;
  @Input("dataKey") _dataKey? = "id";
  @Input() options;
  @ViewChild(MultiSelect, { static: true }) multiselectComponent;

  tryFocusFilterField($event) {
    const filterInput: HTMLInputElement = this.multiselectComponent.el.nativeElement.querySelector(".p-inputtext");
    if (filterInput) {
      filterInput.focus();
    }
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
