/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
import { Injectable } from "@angular/core";
import { TakuPaymentGateway } from "../taku-payment-gateways-list/taku-payment-gateway";

export class TakuPaymentTerminal {
  id: number;
  name: string;
  info: any;
  isPaired: boolean;
  isActive: boolean;
  stationId: number; // lookup for station
  takuPaymentGatewayId: number; // lookup
  takuPaymentGateway: TakuPaymentGateway;
  requestId: string;
  pairingCode: string;
  terminalId: string;
  locationId: string;
  serialNo: string;
  deviceType: string;

  constructor() {
    this.id = 0;
    this.name = null;
    this.info = null;
    this.isPaired = false;
    this.isActive = false;
    this.stationId = null;
    this.takuPaymentGatewayId = null;
    this.takuPaymentGateway = null;
    this.requestId = "";
    this.pairingCode = null;
    this.terminalId = null;
    this.locationId = null;
    this.serialNo = null;
    this.deviceType = null;
  }
}

export class RequestTransaction {
  command = "";
  key = "";
  password = "";
  amount = "";
  refID = "";
  terminalID = "";
}

export class RequestCheckStatus {
  command = "";
  key = "";
  password = "";
  terminalId = "";
  refID = "";
  date = "";
  merchantID = "";
}

export class RequestGetSignature {
  terminalId = "";
  key = "";
  password = "";
}

export class RequestCloseBatch {
  key = "";
  password = "";
  terminalId = "";
  refID = "";
  merchantID = "";
}

export class ResponseCloseBatch {
  TerminalID = "";
  RefID = "";
  ResultCodeGift = "";
  MessageCredit = "";
  MessageGift = "";
  BatchNumCredit = "";
  BatchNumGift = "";
  TransCntTotal = "";
  AmtTotal = "";
  AmtTotalString = "";
  TransCntCredit = "";
  AmtCredit = "";
  AmtCreditString = "";
  TransCntGift = "";
  AmtGift = "";
  AmtGiftString = "";
}

@Injectable()
export class RequestPairTerminal {
  // Required Fields
  resellerName = "takuretail";
  referenceID = "";
  terminalSerialNumber = "";
  businessName = "Taku Retail POS";
  contactName = "Taku Retail POS";
  phone = "1-855-660-8258";
  country = "Canada";
  // Optional Fields
  address = "";
  city = "";
  state = "";
  zipCode = "";
  email = "";
}

export class ResponsePairTerminal {
  TerminalId = "";
  ApiKey = "";
  ApiPassword = "";
  Status = "";
  ErrorMessage = "";
}

export class RequestUnpairTerminal {
  terminalId = "";
  apiKey = "";
  apiPassword = "";
}

export class ResponseUnpairTerminal {
  TerminalID = "";
  ApiKey = "";
  ApiPassword = "";
  Status = "";
  ErrorMessage = "";
}

export enum MenuTenderLineUndoName {
  SALE_VOID = "Sale Void",
  SALE_REFUND = "Sale Refund",
  VOID_REFUND = "Void Refund",
}

export enum TenderLineStatusName {
  SALE_APPROVED = "Approved",
  SALE_VOIDED = "Voided",
  SALE_REFUNDED = "Refunded",
  REFUND_VOIDED = "Refund Voided",
}

export enum TakuPayCommandName {
  COMMAND_SALE = "sale", //This isn't Taku Pay Command
  COMMAND_VOID = "void", //This isn't Taku Pay Command
  COMMAND_AUTH = "auth",
  COMMAND_INTENT = "intent",
  COMMAND_CANCEL = "cancel",
  COMMAND_REFUND = "refund",
  COMMAND_CAPTURE = "capture",
}

export enum TakuPayMethodType {
  CARD = "card",
  CARD_PRESENT = "card_present",
  CARD_NOT_PRESENT = "card_not_present",
  INTERAC = "interac",
  AFFIRM = "affirmac",
  KLARNA = "klarna",
  AFTERPAY = "afterpay",
  CARD_NOT_PRESENT_MOTO = "card_not_present_moto",
}

export enum TakuPayCaptureMethod {
  MANUAL = "manual",
  AUTOMATIC = "automatic",
}

export enum TakuPaymentStatus {
  STATUS_ERROR = "error",
  STATUS_SUCCESS = "success",
  STATUS_SUCCEEDED = "succeeded",
  STATUS_REQUIRES_PAYMENT_METHOD = "requires_payment_method",
  STATUS_REQUIRES_CAPTURE = "requires_capture",
  STATUS_CAPTURE_REQUESTED = "capture_requested",
  STATUS_CANCELED = "canceled",
  STATUS_CANCEL_REQUESTED = "cancel_requested",
  STATUS_REFUNDED = "refunded",
  STATUS_REFUND_REQUESTED = "refund_requested",
  STATUS_PARTIALLY_REFUNDED = "partially_refunded",
}

export enum TakuPayTerminalStatus {
  ONLINE = "online",
  OFFLINE = "offline",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
