/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { TenderType } from "../../business-settings/tender-type/tender-type";
import { PaymentTerminal } from "../../integration-settings/gateway-terminals/payment-terminal";
import { TakuPaymentTerminal } from "../../integration-settings/taku-pay/taku-payment-gateway-terminals/taku-payment-terminal";
import { Store } from "../store/store";

export class StoreTenderTypeList {
  id: number;
  storeId: number;
  store: Store;
  storeTenderTypes: StoreTenderType[];
  updatedAt: string;

  constructor() {
    this.id = 0;
    this.storeId = 0;
    this.store = null;
    this.storeTenderTypes = [];
  }
}

export class StoreTenderType {
  id: number;
  tenderTypeId: number;
  tenderType: TenderType;
  priority: number;
  // TODO remove? These seem to not be used, but I'm not certain.
  /** @deprecated */
  paymentTerminalId: number;
  /** @deprecated */
  paymentTerminal: PaymentTerminal;
  /** @deprecated */
  takuPaymentTerminalId: number;
  /** @deprecated */
  takuPaymentTerminal: TakuPaymentTerminal;

  constructor() {
    this.id = 0;
    this.tenderTypeId = 0;
    this.tenderType = null;
    this.priority = null;
    this.paymentTerminalId = 0;
    this.paymentTerminal = null;
    this.takuPaymentTerminalId = 0;
    this.takuPaymentTerminal = null;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
