import { Component, Input } from "@angular/core";
import { InventoryImage } from "src/app/core/inventory/inventory-image/inventory-image";
import { Inventory, InventoryStock, Stock_type } from "src/app/core/inventory/inventory/inventory";

@Component({
  selector: "taku-gallery-grid-card",
  templateUrl: "./gallery-grid-card.component.html",
  styleUrls: ["./gallery-grid-card.component.scss"],
})
export class GalleryGridCardComponent {
  private _inventory: Inventory;
  get inventory(): Inventory {
    return this._inventory;
  }
  @Input() set inventory(inv: Inventory) {
    this._inventory = inv;
    this.stockStatusClass = this.getStockStatusClass();
    this.isStockVisible = this.getIsStockVisible();
    this.InventoryImagePinnedToPictureGallery = this.getInventoryImagePinnedToPictureGallery();
    this.addedQty = this.getAddedQty();
    this.qtyAvail = InventoryStock.getQtyAvail(this.inventory?.defaultInventoryStock);
  }
  @Input() width: number;
  @Input() _isMobile = false;
  @Input() height: number;

  stockStatusClass = "stock-good-status";
  isStockVisible = true;
  InventoryImagePinnedToPictureGallery?: InventoryImage;
  addedQty: number | null = null;
  qtyAvail = 0;

  private getStockStatusClass(): string {
    const avail = InventoryStock.getQtyAvail(this.inventory?.defaultInventoryStock) || 0;
    const min = this.inventory?.defaultInventoryStock?.minStock || 0;
    if (avail <= min && avail > 0) {
      return "stock-warning-status";
    } else if ((avail || 0) <= 0) {
      return "stock-bad-status";
    } else {
      return "stock-good-status";
    }
  }

  private getIsStockVisible(): boolean {
    const isDefaultInventoryStockEmpty =
      this.inventory.defaultInventoryStock && Object.keys(this.inventory.defaultInventoryStock).length === 0;

    return (
      !isDefaultInventoryStockEmpty && this.inventory.stockType === Stock_type.physical && this.inventory.isTrackQTY
    );
  }

  private getInventoryImagePinnedToPictureGallery(): InventoryImage | undefined {
    return this.inventory?.inventoryImages.find((row) => row.pinToPictureGallery == true);
  }

  private getAddedQty(): number | null {
    const diff = this.inventory.defaultInventoryStock.committedQty;
    return diff === 0 ? null : diff;
  }
}
