/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Store } from "../settings/store-settings/store/store";
import { TenderType } from "../settings/business-settings/tender-type/tender-type";
import { StockControl, PriceControl, ProductDetails } from "../inventory/inventory-sale-channel/inventory-sale-channel";
import { AppConstants } from "src/app/shared/app-constants";
import { DateTimeFormatPipe } from "src/app/shared/pipes/date-time-format.pipe";

export class SaleChannel {
  id: number;
  isConnected: boolean;
  isStoreFrontOpen: boolean;
  channelType: ChannelType;
  channelName: string;
  stockControl: StockControl;
  autoEnableNewProduct: boolean;
  autoPublishNewProduct: boolean;
  priceControl: PriceControl;
  canPushRelatedProducts: boolean;
  productDetails: ProductDetails;
  onlineStoreUrl: string;
  onlineStoreName: string;
  onlineStoreEmail: string;
  canPushInventoyCategories: boolean;
  enableAutomaticTax: boolean;
  enableAutoOrderAccept: boolean;
  store: Store;
  storeId: number; // it is lookup
  saleChannelPaymentMappings: SaleChannelPaymentMapping[];
  createdAt: Date;
  updatedAt: Date;

  constructor(_storeId?: number) {
    this.id = 0;
    this.isConnected = false;
    this.isStoreFrontOpen = false;
    this.channelType = null;
    this.channelName = null;
    this.storeId = _storeId ? _storeId : null;
    this.stockControl = StockControl.Quantity;
    this.autoEnableNewProduct = false;
    this.autoPublishNewProduct = false;
    this.priceControl = PriceControl.SellPrice;
    this.canPushRelatedProducts = true;
    this.productDetails = ProductDetails.MainProductTitle;
    this.onlineStoreUrl = null;
    this.onlineStoreName = null;
    this.onlineStoreEmail = null;
    this.canPushInventoyCategories = false;
    this.enableAutomaticTax = false;
    this.enableAutoOrderAccept = false;
    this.saleChannelPaymentMappings = [];
    this.createdAt = null;
    this.updatedAt = null;
  }

  get createdAtDateAndTime() {
    if (this.createdAt == null) return null;

    try {
      return new DateTimeFormatPipe(AppConstants.DEFAULT_LOCALE_ID).transform(this.createdAt);
    } catch (err) {
      console.warn("Error when convertign time to local", err, this.createdAt);
      return `${this.createdAt}`;
    }
  }

  get updatedAtDateAndTime() {
    if (this.updatedAt == null) return null;

    try {
      return new DateTimeFormatPipe(AppConstants.DEFAULT_LOCALE_ID).transform(this.updatedAt);
    } catch (err) {
      console.warn("Error when convertign time to local", err, this.updatedAt);
      return `${this.updatedAt}`;
    }
  }

  get isStoreFrontOpenCaption() {
    return this.isStoreFrontOpen ? "Storefront Open" : "Storefront Closed";
  }
}

export class SaleChannelPaymentMapping {
  id: number;
  paymentName: string;
  tenderType: TenderType;
  tenderTypeId: number;

  constructor() {
    this.id = 0;
    this.paymentName = "";
    this.tenderTypeId = null;
  }
}

export enum ChannelType {
  takuecommerce = "TAKU eCommerce",
  woocommerce = "WooCommerce",
  amazon = "Amazon",
  facebook = "Facebook",
  instagram = "Instagram",
  shopify = "Shopify",
}

export enum ChannelCategory {
  eCommerce = "E-Commerce",
  SocialMedia = "Social Media",
  Marketplace = "Marketplace",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
