import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { Category } from "src/app/core/inventory/category/category";
import { KioskSetting } from "src/app/core/settings/store-settings/kiosk-settings/kioskSettings";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { TizenBarcodeService } from "src/app/shared/services/tizen/tizen-barcode.service";
import { TizenPrintService } from "src/app/shared/services/tizen/tizen-print.service";
import { DataViewBaseComponent } from "../data-view-base/data-view-base.component";

@Component({
  selector: "taku-category-data-view",
  templateUrl: "./category-data-view.component.html",
  styleUrls: ["./category-data-view.component.scss"],
})
export class CategoryDataViewComponent extends DataViewBaseComponent {
  @Input() categories: Category[];
  @Input() totalCategories: number;

  categoryBgColor: string;
  categoryFontColor: string;

  constructor(
    fb: UntypedFormBuilder,
    tizenBarcodeService: TizenBarcodeService,
    tizenPrintService: TizenPrintService,
    private appSettingsService: AppSettingsStorageService
  ) {
    super(fb, tizenBarcodeService, tizenPrintService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.subsList.push(
      this.appSettingsService.getKioskStoreSettingDetails().subscribe((kioskSettingsData: KioskSetting) => {
        this.categoryBgColor = kioskSettingsData.categoryBtnBgColor;
        this.categoryFontColor = kioskSettingsData.categoryBtnFontColor;
        // console.log(this.categoryBgColor, this.categoryFontColor);
      })
    );
  }
}
