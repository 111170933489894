/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, LOCALE_ID, inject } from "@angular/core";
import { ValidatorFn, Validators } from "@angular/forms";
import { Col, DataType, FilterType } from "../../../../form-list/form-list/form-list";
import { FormListModel } from "../../../../form-list/form-list/form-list-model.interface";
import { CurrencySymbolPipe } from "../../../../shared/pipes/currency-symbol.pipe";
import { AuthService } from "../../../../shared/services/auth.service";
import { DBService } from "../../../../shared/services/db.service";
import { PackSize } from "src/app/core/inventory/inventory-supplier-product-detail/inventory-supplier-product-detail";
import { PurchaseDocLine } from "./purchase-document";
import { DiscountType } from "../../sale-document/sale-doc/sale-doc";
import { DataNormalizerService } from "src/app/shared/services/data-normalizes.service";
import { MonetaryHelpers } from "src/app/utility/MonetaryHelpers";

@Injectable({
  providedIn: "root",
})
export class PurchaseDocLineService extends DBService implements FormListModel {
  enum_pack_size = this.enumSelectOptions(PackSize);
  normalizeService = inject(DataNormalizerService);

  constructor(
    @Inject(LOCALE_ID) private defaultLocale: string,
    protected http: HttpClient,
    protected authService: AuthService
  ) {
    super(http, authService);
  }

  getValidationRules(): { [key: string]: ValidatorFn[] | {} } {
    return {
      convRate: [Validators.required],
      unitCost: [Validators.required],
    };
  }

  moneyPrefix(rowData: PurchaseDocLine) {
    return rowData.docReference.doc.currencyIsoCode;
  }

  discountPrefix(rowData: PurchaseDocLine) {
    if (rowData.allocatedDiscountType == DiscountType.Fixed) {
      return new CurrencySymbolPipe(this.defaultLocale).transform(rowData.docReference.doc.currencyIsoCode);
    } else return "";
  }

  discountSuffix(rowData: PurchaseDocLine) {
    if (rowData.allocatedDiscountType == DiscountType.Percentage) {
      return " " + MonetaryHelpers.roundToDecimalPlaces(rowData.allocatedDiscountAmount) + "%";
    } else return "";
  }

  getFormListColumns(extraData?: any): Col[] {
    const cols: Col[] = [];
    if (extraData && extraData.skuTemplateOptions)
      cols.push({
        field: "docLine.inventory.sku",
        header: "SKU",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.template,
        dataOptions: [],
        dataTypeOptions: extraData.skuTemplateOptions,
        filterType: FilterType.contains,
        filterMatchMode: FilterType.contains,
        isNotSortable: true,
      });

    cols.push(
      ...[
        // supplier sku
        {
          field: "supplierCode",
          header: "Supplier Code",
          visible: true,
          readonly: true,
          frozen: false,
          dataType: DataType.input,
          dataOptions: [],
          // dataTypeOptions: extraData.skuTemplateOptions,
          filterType: FilterType.none,
          isNotSortable: true,
        },
        {
          field: "docLine.inventory.description1",
          header: "Product Title",
          visible: true,
          readonly: true,
          frozen: false,
          dataType: DataType.input,
          dataOptions: [],
          filterType: FilterType.contains,
          isNotSortable: true,
        },
        {
          field: "inventoryVariantsStr",
          header: "Variant",
          visible: true,
          readonly: true,
          frozen: false,
          dataType: DataType.input,
          dataOptions: [],
          dataTypeOptions: DataType.input,
          filterType: FilterType.contains,
          isNotSortable: true,
          colWidth: 140,
          filterMatchMode: FilterType.contains,
        },
        {
          field: "packSize",
          header: "Pack Size",
          visible: true,
          readonly: false,
          frozen: false,
          dataType: DataType.enum,
          dataOptions: this.enum_pack_size,
          filterType: FilterType.enum,
          colWidth: 130,
          isNotSortable: true,
        },
        {
          field: "qty",
          fieldValue: "qtyOrder",
          header: "Order Qty",
          visible: true,
          readonly: false,
          frozen: false,
          dataType: DataType.number,
          dataOptions: [],
          filterType: FilterType.contains,
          isNotSortable: true,
          colWidth: 100,
          displayFn: (rowData: PurchaseDocLine) => {
            return this.normalizeService.normalizeNumbers(rowData.qty);
          },
        },
        {
          field: "skuQtyPerPack",
          header: "SKU Qty / Pack",
          visible: true,
          readonly: true,
          frozen: false,
          dataType: DataType.number,
          dataOptions: [],
          filterType: FilterType.contains,
          isNotSortable: true,
          displayFn: (rowData: PurchaseDocLine) => {
            return this.normalizeService.normalizeNumbers(rowData.skuQtyPerPack);
          },
        },
        {
          field: "totalQty",
          header: "Total Ordered Qty",
          visible: true,
          readonly: true,
          frozen: false,
          dataType: DataType.number,
          dataOptions: [],
          filterType: FilterType.contains,
          isNotSortable: true,
          displayFn: (rowData: PurchaseDocLine) => {
            return this.normalizeService.normalizeNumbers(rowData.totalQty);
          },
        },
        {
          field: "poInventoryStock.onOrderQty",
          header: "On Order Qty",
          visible: true,
          readonly: true,
          frozen: false,
          dataType: DataType.number,
          dataOptions: [],
          filterType: FilterType.contains,
          isNotSortable: true,
        },
        {
          field: "poInventoryStock.committedQty",
          header: "Committed Qty",
          visible: true,
          readonly: true,
          frozen: false,
          dataType: DataType.number,
          dataOptions: [],
          filterType: FilterType.contains,
          isNotSortable: true,
        },
        {
          field: "receivedQty",
          header: "Received Qty",
          visible: true,
          readonly: true,
          frozen: false,
          dataType: DataType.number,
          dataOptions: [],
          filterType: FilterType.contains,
          isNotSortable: true,
        },
        {
          field: "unitPrice",
          header: "PO/Supplier Cost",
          visible: true,
          readonly: false,
          frozen: false,
          dataType: DataType.number,
          dataOptions: [],
          dataTypeOptions: { prefix: this.moneyPrefix.bind(this) },
          filterType: FilterType.contains,
          isNotSortable: true,
          displayFn: (rowData: PurchaseDocLine) => {
            return this.normalizeService.currencyNormalizer(
              rowData.unitPrice,
              rowData.docReference.doc.currencyIsoCode
            );
          },
        },
        {
          field: "subTotal",
          header: "Sub-Total",
          visible: true,
          readonly: true,
          frozen: false,
          dataType: DataType.number,
          dataOptions: [],
          dataTypeOptions: { prefix: this.moneyPrefix.bind(this) },
          filterType: FilterType.contains,
          isNotSortable: true,
          displayFn: (rowData: PurchaseDocLine) => {
            return this.normalizeService.currencyNormalizer(rowData.subTotal, rowData.docReference.doc.currencyIsoCode);
          },
        },
        {
          field: "allocatedDiscountType",
          header: "Discount Type",
          visible: true,
          readonly: true,
          frozen: false,
          dataType: DataType.input,
          dataOptions: [],
          dataTypeOptions: { suffix: this.discountSuffix.bind(this) },
          filterType: FilterType.none,
          isNotSortable: true,
          colWidth: 130,
        },
        {
          field: "totalDiscountAmount",
          header: "Allocated Discount",
          visible: true,
          readonly: true,
          frozen: false,
          dataType: DataType.number,
          dataOptions: [],
          dataTypeOptions: { prefix: this.moneyPrefix.bind(this) },
          filterType: FilterType.none,
          isNotSortable: true,
          displayFn: (rowData: PurchaseDocLine) => {
            return this.normalizeService.currencyNormalizer(
              rowData.totalDiscountAmount,
              rowData.docReference.doc.currencyIsoCode
            );
          },
        },
        {
          field: "allocatedShipperChargeAmount",
          header: "Allocated Freight",
          visible: true,
          readonly: true,
          frozen: false,
          dataType: DataType.number,
          dataOptions: [],
          dataTypeOptions: { prefix: this.moneyPrefix.bind(this) },
          filterType: FilterType.contains,
          isNotSortable: true,
          displayFn: (rowData: PurchaseDocLine) => {
            return this.normalizeService.currencyNormalizer(
              rowData.allocatedShipperChargeAmount,
              rowData.docReference.doc.currencyIsoCode
            );
          },
        },
        // {
        //   field: "convertedUnitPrice",
        //   header: "Converted Unit Cost",
        //   visible: true,
        //   readonly: true,
        //   frozen: false,
        //   dataType: DataType.number,
        //   dataOptions: [],
        //   dataTypeOptions: { prefix: this.moneyPrefix.bind(this) },
        //   filterType: FilterType.contains,
        //   displayFn: (rowData: PurchaseDocLine) => {
        //     return this.normalizeService.currencyNormalizer(
        //       rowData.convertedUnitPrice,
        //       rowData.docReference.doc.currencyIsoCode
        //     );
        //   },
        // },
        {
          field: "totalTax",
          header: "Total Tax",
          visible: true,
          readonly: true,
          frozen: false,
          dataType: DataType.number,
          dataOptions: [],
          dataTypeOptions: { prefix: this.moneyPrefix.bind(this) },
          filterType: FilterType.contains,
          isNotSortable: true,
          displayFn: (rowData: PurchaseDocLine) => {
            return this.normalizeService.currencyNormalizer(
              rowData.totalTax,
              rowData.docReference.zone.defaultCurrencyIsoCode
            );
          },
        },
        {
          field: "totalPrice",
          header: "Total Cost",
          visible: true,
          readonly: true,
          frozen: false,
          dataType: DataType.number,
          dataOptions: [],
          dataTypeOptions: { prefix: this.moneyPrefix.bind(this) },
          filterType: FilterType.contains,
          isNotSortable: true,
          displayFn: (rowData: PurchaseDocLine) => {
            return this.normalizeService.currencyNormalizer(
              rowData.totalPrice,
              rowData.docReference.doc.currencyIsoCode
            );
          },
        },
        {
          field: "docLine.note",
          header: "Line Note",
          visible: true,
          readonly: false,
          frozen: false,
          dataType: DataType.input,
          dataOptions: [],
          filterType: FilterType.contains,
          isNotSortable: true,
        },
      ]
    );

    return cols;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
