/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, ViewChild, HostListener, ViewContainerRef, HostBinding, OnDestroy } from "@angular/core";
import { DocumentSearchForReturnComponent } from "../return-document/document-search-return/document-search-return.component";
import { InvoiceKeyboardActionsService } from "./sale-invoice-doc-base/invoice-keyboard-actions.service";
import { throttleTime } from "rxjs/operators";
import { GenericSaleDocComponent, FullSizeDialogName } from "./generic-sale-doc.component";
import { PersonalAccount } from "../../contact-accounts/personal-account/personal-account";
import { CommercialAccount } from "../../contact-accounts/commercial-account/commercial-account";
import { Inventory } from "../../inventory/inventory/inventory";
import { MenuItem } from "primeng/api";
import { TakuSearchAccountsComponent } from "src/app/taku-ui/taku-search-accounts/taku-search-accounts.component";
import { SplitButton } from "primeng/splitbutton";
import { AppConstants } from "src/app/shared/app-constants";
import { GenericInvoiceStateService } from "../generic-invoice-state.service";
import { InvoiceSideDialogName } from "../types/invoice-enums.type";
import { AuthService } from "src/app/shared/services/auth.service";
import { Subscription } from "rxjs";
import { SaleDocListComponent } from "./sale-doc-list/sale-doc-list.component";
import { ParkedSalesComponent } from "./parked-sales/parked-sales.component";

@Component({
  template: "",
  styles: [],
})
export class GenericInvoiceSaleComponent implements OnDestroy {
  subsList: Subscription[] = [];

  @ViewChild(DocumentSearchForReturnComponent) _returnsSearchComponent: DocumentSearchForReturnComponent;
  @ViewChild(SaleDocListComponent) _saleDocListComponent: SaleDocListComponent;
  @ViewChild("parkedSalesComponent") _parkedSalesComponent: ParkedSalesComponent;
  @ViewChild("accountsSearchBox") accountsSearchComponent: TakuSearchAccountsComponent;
  @ViewChild("btnNewAccounts") btnNewAccountsComponent: SplitButton;

  isAddPersonalAccountVisible = false;
  isAddCommercialAccountVisible = false;
  isAddPersonalAccountEnabled = false;
  isAddCommercialAccountEnabled = false;

  docComponent: GenericSaleDocComponent;
  isTenderScreenOpened = false;
  _activeDialog: InvoiceSideDialogName;
  _activeDialogExtra: any;

  // RIGHT SIDE's properties
  // New Accounts Dropdown Definition
  newAccountMenu: MenuItem[] = [];

  constructor(
    protected viewContainer: ViewContainerRef,
    protected keyboardActionsService: InvoiceKeyboardActionsService,
    protected invoiceStateService: GenericInvoiceStateService,
    protected auth: AuthService
  ) {
    this.subsList.push(
      this.invoiceStateService.tenderScreenOpen$.subscribe((isOpen) => {
        this.isTenderScreenOpened = isOpen;
      })
    );

    this.subsList.push(
      this.keyboardActionsService.anyKeyPressed$
        .pipe(
          throttleTime(200) // Adjust the throttle time as needed
        )
        .subscribe((event) => {
          switch (event.keyCode) {
            case AppConstants.KEY_CODES.ESCAPE.code:
              if (this.isTenderScreenOpened) this.closeRightDialog();
              break;
          }
        })
    );

    this.isAddPersonalAccountVisible = auth.hasVisiblePermit("Accounts_Menu_Personal_Accounts");
    this.isAddCommercialAccountVisible = auth.hasVisiblePermit("Accounts_Menu_Commercial_Accounts");

    this.isAddPersonalAccountEnabled = auth.hasEnablePermit("Accounts_Menu_Personal_Accounts");
    this.isAddCommercialAccountEnabled = auth.hasEnablePermit("Accounts_Menu_Commercial_Accounts");

    this.newAccountMenu = [
      {
        label: "New Personal Account",
        command: (event) => {
          const defaultData$ = this.accountsSearchComponent.prefillDataFromSearchText("personalAccount");
          defaultData$.subscribe((defaultData) => {
            if (defaultData) {
              this.openNewModelInDialog(defaultData);
            }
          });
        },
        visible: this.isAddPersonalAccountVisible,
        disabled: !this.isAddPersonalAccountEnabled,
      },
      {
        label: "New Commercial Account",
        command: (event) => {
          const defaultData$ = this.accountsSearchComponent.prefillDataFromSearchText("commercialAccount");
          defaultData$.subscribe((defaultData) => {
            if (defaultData) {
              this.openNewModelInDialog(defaultData);
            }
          });
        },
        visible: this.isAddCommercialAccountVisible,
        disabled: !this.isAddCommercialAccountEnabled,
      },
    ];
  }

  @HostBinding("attr.tabindex") get tabIndex() {
    return 1000;
  }

  @HostListener("window:keydown", ["$event"])
  onKeyDown(event: KeyboardEvent) {
    this.keyboardActionsService.announceKeyPressed(event);

    const componentEl: HTMLElement = this.viewContainer.element.nativeElement;
    const plusKeyCodes = [107, 187],
      minusKeyCodes = [109, 189];
    const eventTarget: HTMLElement = <HTMLElement>event.target;
    if (componentEl === event.target || eventTarget.closest("[data-docline-field]")) {
      if (minusKeyCodes.includes(event.keyCode)) {
        // Minus (-) key pressed
        this.keyboardActionsService.announceMinusKeyPressed(event.keyCode, eventTarget);
      }

      if (plusKeyCodes.includes(event.keyCode)) {
        // Plus (+) key pressed
        this.keyboardActionsService.announcePlusKeyPressed(event.keyCode, eventTarget);
      }
    }
  }

  closeRightDialog() {
    if (this._activeDialog === InvoiceSideDialogName.TENDER_SCREEN)
      this.invoiceStateService.anounceTenderScreenVisibility(false);

    this._activeDialog = null;
  }

  onTabViewChange(e) {
    this.closeRightDialog();
    // call search if user clicked on the Parked tab in the salescreen
    if (e.index === 1) {
      this._parkedSalesComponent.searchDocuments();
    }
  }

  openNewModelInDialog(defaultData: any) {
    let dialogName: FullSizeDialogName;

    if (defaultData instanceof PersonalAccount) {
      dialogName = FullSizeDialogName.PERSONAL_ACCOUNT;
    } else if (defaultData instanceof CommercialAccount) {
      dialogName = FullSizeDialogName.COMMERCIAL_ACCOUNT;
    } else if (defaultData instanceof Inventory) {
      dialogName = FullSizeDialogName.NEW_INVENTORY;
    }

    this.docComponent.openFullSizeDialog(dialogName, defaultData, {
      showHeader: false,
      contentStyle: {
        height: "100%",
        maxHeight: "unset",
      },
      styleClass: "dialogForNewItemInSaleScreen",
    });
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
