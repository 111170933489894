<div *ngIf="detailedViewComponent">
  <ng-content></ng-content>
</div>

<!-- If it couldn't found component parameter for dialog, resort to defaulted components -->
<ng-template [ngIf]="!detailedViewComponent">
  <span [ngSwitch]="model">
    <taku-u-of-measure
      *ngSwitchCase="'uOfMeasure'"
      [_object]="object"
      [_isDialog]="true"
      (changedForm)="_formChanged($event)"
      (dialogClosed)="_dialogClosed()"
    ></taku-u-of-measure>

    <taku-tax-account-category
      *ngSwitchCase="'taxAccountCategory'"
      [_object]="object"
      [_isDialog]="true"
      (changedForm)="_formChanged($event)"
      (dialogClosed)="_dialogClosed()"
    ></taku-tax-account-category>

    <taku-tax-inventory-category
      *ngSwitchCase="'taxInventoryCategory'"
      [_object]="object"
      [_isDialog]="true"
      (changedForm)="_formChanged($event)"
      (dialogClosed)="_dialogClosed()"
    ></taku-tax-inventory-category>

    <taku-tax
      *ngSwitchCase="'tax'"
      [_object]="object"
      [_isDialog]="true"
      (changedForm)="_formChanged($event)"
      (dialogClosed)="_dialogClosed()"
    ></taku-tax>

    <taku-tax-rule
      *ngSwitchCase="'taxRule'"
      [_object]="object"
      [_isDialog]="true"
      (changedForm)="_formChanged($event)"
      (dialogClosed)="_dialogClosed()"
    ></taku-tax-rule>

    <taku-category
      *ngSwitchCase="'category'"
      [_object]="object"
      [_isDialog]="true"
      (changedForm)="_formChanged($event)"
      (dialogClosed)="_dialogClosed()"
    ></taku-category>

    <taku-account-group
      *ngSwitchCase="'acctGroup'"
      [_object]="object"
      [_isDialog]="true"
      (changedForm)="_formChanged($event)"
      (dialogClosed)="_dialogClosed()"
    ></taku-account-group>

    <taku-shipper
      *ngSwitchCase="'shipper'"
      [_object]="object"
      [_isDialog]="true"
      (changedForm)="_formChanged($event)"
      (dialogClosed)="_dialogClosed()"
    ></taku-shipper>

    <taku-store
      *ngSwitchCase="'store'"
      [_object]="object"
      [_isDialog]="true"
      (changedForm)="_formChanged($event)"
      (dialogClosed)="_dialogClosed()"
    ></taku-store>

    <taku-saledoc-revisit-preview
      *ngSwitchCase="'saleDoc'"
      [_object]="object"
      (changedForm)="_formChanged($event)"
      (dialogClosed)="_dialogClosed()"
    ></taku-saledoc-revisit-preview>

    <taku-stock
      *ngSwitchCase="'stock'"
      [_object]="object"
      [_isDialog]="true"
      (changedForm)="_formChanged($event)"
      (dialogClosed)="_dialogClosed()"
    ></taku-stock>

    <taku-inventory
      *ngSwitchCase="'inventory'"
      [_object]="object"
      [_isDialog]="true"
      (changedForm)="_formChanged($event)"
      (dialogClosed)="_dialogClosed()"
    ></taku-inventory>

    <taku-backOffice
      *ngSwitchCase="'backOffice'"
      [_object]="object"
      [_isDialog]="true"
      (changedForm)="_formChanged($event)"
      (dialogClosed)="_dialogClosed()"
    ></taku-backOffice>

    <taku-google-shopping-store
      *ngSwitchCase="'storeSettingGoogleShopping'"
      [_object]="object"
      [_isDialog]="true"
      (changedForm)="_formChanged($event)"
    ></taku-google-shopping-store>

    <taku-brand
      *ngSwitchCase="'brand'"
      [_object]="object"
      [_isDialog]="true"
      (changedForm)="_formChanged($event)"
      (dialogClosed)="_dialogClosed()"
    ></taku-brand>

    <taku-cashout-history-preview
      *ngSwitchCase="'cashout'"
      [_object]="object"
      (dialogClosed)="_dialogClosed()"
    ></taku-cashout-history-preview>

    <taku-sale-channel-detail
      *ngSwitchCase="'saleChannel'"
      [_object]="object"
      [_isDialog]="true"
      (changedForm)="_formChanged($event)"
      (dialogClosed)="_dialogClosed()"
    ></taku-sale-channel-detail>

    <taku-price-level
      *ngSwitchCase="'priceLevel'"
      [_object]="object"
      [_isDialog]="true"
      (changedForm)="_formChanged($event)"
      (dialogClosed)="_dialogClosed()"
    ></taku-price-level>

    <taku-promotion
      *ngSwitchCase="'promotion'"
      [_object]="object"
      [_isDialog]="true"
      (changedForm)="_formChanged($event)"
      (dialogClosed)="_dialogClosed()"
    ></taku-promotion>

    <span *ngSwitchDefault>URL link is not correct - see form-dialoge-select.component</span>
  </span>
</ng-template>
