/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { PersonAddress } from "../person-address/person-address";
import { CommercialAccountAddress } from "../commercial-account-address/commercial-account-address";
import { AbstractControl, UntypedFormArray, UntypedFormGroup, FormControl } from "@angular/forms";
import { AddressType, Address } from "./address";
import { OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";

type GenericAddressType = PersonAddress | CommercialAccountAddress;

export interface AddressFilterChangeObserver {
  onFilterChanged: (value: any) => void;
}

export class AddressesManager {
  constructor(
    private _formGroup: UntypedFormGroup,
    private fieldName: string,
    private _activeAddressFilter: AddressType,
    private _filterChangeObserver: AddressFilterChangeObserver
  ) {
    this.subscribeToggleDefaultChanges(this.addressesCtrlArray);
  }

  get activeAddressFilter() {
    return this._activeAddressFilter;
  }

  set activeAddressFilter(addressType: AddressType) {
    this._activeAddressFilter = addressType;
    this._filterChangeObserver.onFilterChanged(addressType);
  }

  findAddressPosition(addressCtrl: AbstractControl) {
    return this.addressesCtrlArray.controls.findIndex((currCtrl) => currCtrl == addressCtrl);
  }

  get addressesCtrlArray() {
    return <UntypedFormArray>this._formGroup.get(this.fieldName);
  }

  get filteredAddresses(): AbstractControl[] {
    return this.getAddressesCtrlsOfType(this._activeAddressFilter);
  }

  private getAddressesCtrlsOfType(addressType: AddressType) {
    return this.addressesCtrlArray.controls.filter((ctrl) => {
      return ctrl.get("address.addressType").value == addressType;
    });
  }

  subscribeToggleDefaultChanges(personAddresses: UntypedFormArray | UntypedFormGroup) {
    const addressesCtrls: AbstractControl[] =
      personAddresses instanceof UntypedFormArray ? personAddresses.controls : [personAddresses];
    addressesCtrls.forEach((currentAddressCtrl) =>
      currentAddressCtrl.valueChanges.subscribe((currentAddress: GenericAddressType) => {
        // If the current address is not a default one, do nothing
        if (!currentAddress.address.isDefault) return;

        const sameTypeAddresses = this.getAddressesCtrlsOfType(currentAddress.address.addressType);
        // Because toggle is now turned on, I need to turn the rest of toggles off
        for (const theAddressCtrl of sameTypeAddresses) {
          if (currentAddressCtrl !== theAddressCtrl)
            // Exclude current control
            theAddressCtrl.get("address.isDefault").setValue(false, { emitEvent: false });
        }
      })
    );
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
