import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "replaceSpace",
  standalone: true,
})
export class ReplaceSpacePipe implements PipeTransform {
  transform(subject: string, replacement: string): string {
    return subject.split(" ").join(replacement);
  }
}
