/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { InventoryOptionsSettingValue } from "../inventory-options-setting-value/inventory-options-setting-value";
import { InventoryOptionsSetting } from "../inventory-options-setting/inventory-options-setting";

export class InventoryVariantSettingLine {
  id: number;
  inventoryOptionsSettingId: number;
  inventoryOptionsSetting: InventoryOptionsSetting;
  inventoryOptionsSettingValues: InventoryOptionsSettingValue[];

  constructor() {
    this.id = 0;
    this.inventoryOptionsSettingId = null;
    this.inventoryOptionsSetting = null;
    this.inventoryOptionsSettingValues = [];
  }
}
/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
