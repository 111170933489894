/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
import {
  PaymentTerminal,
  ResponseTransaction,
} from "src/app/core/settings/integration-settings/gateway-terminals/payment-terminal";
import { TenderType } from "../../../settings/business-settings/tender-type/tender-type";
import { TakuPaymentTerminal } from "src/app/core/settings/integration-settings/taku-pay/taku-payment-gateway-terminals/taku-payment-terminal";
import { Voucher } from "../create-voucher/voucher";
import { merge } from "lodash";

export class SaleDocTender {
  id: number;
  amount: number | string;
  isReturned: boolean;
  tenderTypeId: number;
  tenderType?: TenderType;
  refNo: string;
  isTendered: boolean;
  tenderedType: string;
  paymentTerminalId: number;
  paymentTerminal: PaymentTerminal;
  takuPaymentTerminalId: number;
  takuPaymentTerminal: TakuPaymentTerminal;
  refTransaction: ResponseTransaction;
  voucherId: number | null;
  voucher?: Voucher;
  saleDocId: number;
  createdAt: string | Date;
  updatedAt: string | Date;

  constructor() {
    this.id = 0;
    this.amount = 0;
    this.isReturned = false;
    this.tenderTypeId = 0;
    this.tenderType = null;
    this.refNo = "";
    this.isTendered = true;
    this.paymentTerminalId = 0;
    this.paymentTerminal = null;
    this.takuPaymentTerminalId = 0;
    this.takuPaymentTerminal = null;
    this.refTransaction = null;
    this.voucherId = null;
  }

  static convertIntoTypedObject(saleDocTender: SaleDocTender): SaleDocTender {
    const typedSaleDoc: SaleDocTender = merge(new SaleDocTender(), saleDocTender);
    return typedSaleDoc;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
