/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { DataType, FilterType, Col } from "../../../../form-list/form-list/form-list";
import { DBService } from "../../../../shared/services/db.service";
import { FormListModel } from "../../../../form-list/form-list/form-list-model.interface";
import { ValidatorFn, Validators } from "@angular/forms";
import { AcctGroupOptions } from "./account-group";

@Injectable({
  providedIn: "root",
})
export class AccountGroupService extends DBService implements FormListModel {
  get_enum_options() {
    return this.enumSelectOptions(AcctGroupOptions);
  }

  getValidationRules(): { [key: string]: ValidatorFn[] | {} } {
    return { name: [Validators.required] };
  }

  getFormListColumns() {
    return [
      {
        field: "isEnabled",
        header: "Active",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.checkbox,
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.enum,
      },
      {
        field: "name",
        header: "Description",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "option",
        header: "Option",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.get_enum_options(),
        filterType: FilterType.enum,
      },
    ];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
