import { Inject, Injectable, Type, ViewContainerRef, LOCALE_ID } from "@angular/core";
import { SaleDocType } from "src/app/core/document/doc/doc";
import { SaleDocService } from "src/app/core/document/sale-document/sale-doc/sale-doc.service";
import { DiscountType, SaleDoc } from "../../core/document/sale-document/sale-doc/sale-doc";
import { GiftReceiptTapePreviewComponent } from "../../core/previews/receipt/gift-receipt-tape-preview/gift-receipt-tape-preview.component";
import { ReceiptFullPreviewComponent } from "../../core/previews/receipt/receipt-full-preview/receipt-full-preview.component";
import { ReceiptTapePreviewComponent } from "../../core/previews/receipt/receipt-tape-preview/receipt-tape-preview.component";
import { StoreSettingTapeSalePrint } from "../../core/settings/store-settings/tape-salereceipt-builder/store-setting-tape-sale-print";
import { PrintHelpers } from "../../utility/PrintHelpers";
import { AppSettingsStorageService } from "../app-settings-storage.service";
import { MessageService } from "primeng/api";
import { StoreSettingTapeReturnPrint } from "src/app/core/settings/store-settings/tape-returnreceipt-builder/store-setting-tape-return-print";
import { CurrencySymbolPipe } from "src/app/shared/pipes/currency-symbol.pipe";
import * as _ from "lodash";
import { TenderReceiptTapePreviewComponent } from "src/app/core/previews/receipt/tender-receipt-tape-preview/tender-receipt-tape-preview.component";
import { ClosedBatchDetailsTapePreviewComponent } from "src/app/core/previews/receipt/closed-batch-details-tape-preview/closed-batch-details-tape-preview.component";
import { PrinterManufacturer } from "src/app/core/settings/store-settings/printer/printer-settings";
import { MonetaryHelpers } from "src/app/utility/MonetaryHelpers";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { InvoiceHelpers } from "../../utility/InvoiceHelpers";
import { Observable, Observer } from "rxjs";
import { SaleDocTender } from "src/app/core/document/sale-document/sale-doc-tender/sale-doc-tender";
import { TimeHelpers } from "src/app/utility/TimeHelpers";

type ReceiptPreviewBaseComponent =
  | ReceiptFullPreviewComponent
  | ReceiptTapePreviewComponent
  | GiftReceiptTapePreviewComponent
  | TenderReceiptTapePreviewComponent
  | ClosedBatchDetailsTapePreviewComponent;

@Injectable({
  providedIn: "root",
})
export class StarCloudPrintServiceService {
  protected webUrl = environment.apiUrl;
  public tapeSizeBuilderSettings: StoreSettingTapeSalePrint | StoreSettingTapeReturnPrint;
  constructor(
    private dbService: SaleDocService,
    public appSettingsService: AppSettingsStorageService,
    private messageService: MessageService,
    private http: HttpClient,
    @Inject(LOCALE_ID) private _locale: string
  ) {}

  get businessLogoUrl() {
    const store = this.appSettingsService.getStore();
    const logoUrl = store.urlStoreLogoUrl150;
    return logoUrl;
  }

  getCurrencySymbol(sSymbol) {
    return new CurrencySymbolPipe(this._locale).transform(sSymbol, "symbol-narrow");
  }

  printStarCloudPRNTOpenCash(tapeSizeBuilderSettings: StoreSettingTapeSalePrint | StoreSettingTapeReturnPrint) {
    this.tapeSizeBuilderSettings = tapeSizeBuilderSettings;
    this.printStarCloudTapeOpenCashReceipt();
  }

  printStarCloudPRNT(
    saleDoc: SaleDoc,
    tapeSizeBuilderSettings: StoreSettingTapeSalePrint | StoreSettingTapeReturnPrint
  ) {
    this.tapeSizeBuilderSettings = tapeSizeBuilderSettings;
    this.printStarCloudTapeReceipt(saleDoc);
  }

  printStarCloudPrepPRNT(
    saleDoc: SaleDoc,
    tapeSizeBuilderSettings: StoreSettingTapeSalePrint | StoreSettingTapeReturnPrint
  ) {
    this.tapeSizeBuilderSettings = tapeSizeBuilderSettings;
    this.printStarCloudPrepReceipt(saleDoc);
  }

  /**
   * Print card tape receipt
   * @param saleDoc
   */
  printStarCloudCardReceipt(saleDoc: SaleDoc) {
    const textPrint = this.createTransactionRecord(saleDoc);

    if (textPrint.length > 0) {
      this.dbService
        ._postRequest(`${this.webUrl}cloudprint/push`, {
          template: textPrint,
          uniqueAddress: this.appSettingsService.getStation().receiptPrinter?.uniqueAddress,
        })
        .subscribe((message: string) => {
          this.messageService.add({
            summary: "PRINT SENT",
            detail: "Print was sent successfully",
            severity: "success",
          });
        });
    }
  }

  /**
   * Print tape receipt
   * @param saleDoc
   */
  printStarCloudPrepReceipt(saleDoc: SaleDoc) {
    const textPrint =
      this.createStarCloudTapeReceiptTextHeader(saleDoc, true) + this.createStarCloudPrepTapeReceiptTextDetail(saleDoc);

    this.dbService
      ._postRequest(`${this.webUrl}cloudprint/push`, {
        template: textPrint,
        uniqueAddress: this.appSettingsService.getStation().prepPrinter?.uniqueAddress,
      })
      .subscribe((message: string) => {
        this.messageService.add({
          summary: "PRINT SENT",
          detail: "Print was sent successfully",
          severity: "success",
        });
      });
  }

  /**
   * Print StarCloudTapeOpenCashReceipt
   * @param saleDoc
   */
  printStarCloudTapeOpenCashReceipt() {
    const textPrint: string =
      this.createStarCloudTapeReceiptTextOpenCashHeader() + this.createStarCloudTapeReceiptOpenCashTextDetail();

    this.dbService
      ._postRequest(`${this.webUrl}cloudprint/push`, {
        template: textPrint,
        uniqueAddress: this.appSettingsService.getStation().receiptPrinter?.uniqueAddress,
      })
      .subscribe((message: string) => {
        this.messageService.add({
          summary: "PRINT SENT",
          detail: "Print was sent successfully",
          severity: "success",
        });
      });
  }

  printStarCloudTapeReceipt(saleDoc: SaleDoc): void {
    let textPrint: string;
    let base64Image: any;
    let textPrintTakuPay: string;
    const isTakuPayTransaction = PrintHelpers.isTakuPayTransaction(saleDoc);

    if (this.tapeSizeBuilderSettings.printLogo && this.businessLogoUrl != null && this.businessLogoUrl != undefined) {
      this.getBase64ImageFromURL(this.businessLogoUrl).subscribe((data: string) => {
        base64Image = data;
        base64Image = base64Image.replace(/^data:image\/(png|jpg);base64,/, "");
        if (base64Image != null && base64Image != undefined) {
          //textPrint = `{i:${base64Image}}` + "\n" + this.createStarCloudTapeReceiptTextHeader(saleDoc) + this.createStarCloudTapeReceiptTextDetail(saleDoc) + (saleDoc['isPackingList'] ? '' : this.createTransactionRecord(saleDoc));
          textPrint =
            `{i:${base64Image}}` +
            "\n" +
            this.createStarCloudTapeReceiptTextHeader(saleDoc) +
            this.createStarCloudTapeReceiptTextDetail(saleDoc);
        } else {
          //textPrint = this.createStarCloudTapeReceiptTextHeader(saleDoc) + this.createStarCloudTapeReceiptTextDetail(saleDoc) + (saleDoc['isPackingList'] ? '' : this.createTransactionRecord(saleDoc));
          textPrint =
            this.createStarCloudTapeReceiptTextHeader(saleDoc) + this.createStarCloudTapeReceiptTextDetail(saleDoc);
        }

        if (isTakuPayTransaction) {
          if (this.appSettingsService.getUserPreferences()?.tenderPrintingSettings?.extraCopy) {
            textPrintTakuPay = textPrint + this.createTransactionRecordTakuPay(saleDoc, true);
            textPrintTakuPay += "=\n";
          }
          textPrintTakuPay += textPrint + this.createTransactionRecordTakuPay(saleDoc, false);
          textPrint = textPrintTakuPay;
        }

        this.dbService
          ._postRequest(`${this.webUrl}cloudprint/push`, {
            template: textPrint,
            uniqueAddress: saleDoc["isPackingList"]
              ? this.appSettingsService.getStation().packingListPrinter?.uniqueAddress
              : this.appSettingsService.getStation().receiptPrinter?.uniqueAddress,
          })
          .subscribe((message: string) => {
            this.messageService.add({
              summary: "PRINT SENT",
              detail: "Print was sent successfully",
              severity: "success",
            });
          });
      });
    } else {
      //textPrint = this.createStarCloudTapeReceiptTextHeader(saleDoc) + this.createStarCloudTapeReceiptTextDetail(saleDoc) + (saleDoc['isPackingList'] ? '' : this.createTransactionRecord(saleDoc));
      textPrint =
        this.createStarCloudTapeReceiptTextHeader(saleDoc) + this.createStarCloudTapeReceiptTextDetail(saleDoc);
      if (isTakuPayTransaction) {
        textPrintTakuPay = textPrint + this.createTransactionRecordTakuPay(saleDoc, true);
        textPrintTakuPay += "=\n";
        textPrintTakuPay += textPrint + this.createTransactionRecordTakuPay(saleDoc, false);
        textPrint = textPrintTakuPay;
      }

      this.dbService
        ._postRequest(`${this.webUrl}cloudprint/push`, {
          template: textPrint,
          uniqueAddress: saleDoc["isPackingList"]
            ? this.appSettingsService.getStation().packingListPrinter?.uniqueAddress
            : this.appSettingsService.getStation().receiptPrinter?.uniqueAddress,
        })
        .subscribe((message: string) => {
          this.messageService.add({
            summary: "PRINT SENT",
            detail: "Print was sent successfully",
            severity: "success",
          });
        });
    }
  }

  /**
   * Create transaction record
   * @param saleDoc
   * @returns textPrint
   */
  createTransactionRecord(saleDoc: SaleDoc): string {
    let textPrint = "";
    let customerReceipt = "";
    let merchantReceipt = "";

    // Customer receipt
    saleDoc.saleDocTenders.forEach((element) => {
      if (element.refTransaction != null) {
        customerReceipt += element.refTransaction["customerReceiptTemplate"].split(" ").join("~");
      }
    });

    if (customerReceipt != null && customerReceipt != undefined && customerReceipt.length > 0) {
      textPrint += "{w:*}\n" + customerReceipt;
    }

    // Merchant receipt
    if (this.appSettingsService.getUserPreferences()?.tenderPrintingSettings?.extraCopy) {
      saleDoc.saleDocTenders.forEach((element) => {
        if (element.refTransaction != null) {
          merchantReceipt += element.refTransaction["merchantReceiptTemplate"].split(" ").join("~");
        }
      });
    }

    if (merchantReceipt != null && merchantReceipt != undefined && merchantReceipt.length > 0) {
      textPrint += "=\n";
      textPrint += merchantReceipt;
    }

    return textPrint;
  }

  /**
   * Create tape receipt TakuPay
   * @param saleDoc
   * @returns sTextPrint
   */
  createTransactionRecordTakuPay(saleDoc: SaleDoc, bMerchantReceipt = true): string {
    let sTextPrint = "";
    const sCurrencySymbol = this.getCurrencySymbol(saleDoc.doc.currencyIsoCode);

    saleDoc.saleDocTenders.forEach((saleDocTender: SaleDocTender) => {
      if (saleDocTender?.refTransaction?.PaymentId) {
        sTextPrint += "\n{w:21,*,a:center}\n";
        sTextPrint += "|`Payment Method | `Amount|" + "\n";
        sTextPrint += `|${saleDocTender?.refTransaction?.CardType} x${
          saleDocTender?.refTransaction?.Last4
        } | ${sCurrencySymbol}${MonetaryHelpers.roundToDecimalPlaces(
          parseFloat(saleDocTender?.refTransaction?.Amount)
        ).toFixed(2)}|\n`;
        sTextPrint += `|Trans Type | ${
          saleDocTender?.refTransaction?.TransType?.charAt(0).toUpperCase() +
          saleDocTender?.refTransaction?.TransType?.slice(1)
        }|\n`;
        if (bMerchantReceipt) {
          sTextPrint += `|Terminal Id | ${saleDocTender?.refTransaction?.TerminalID}|\n`;
          sTextPrint += `|Trans Source | ${
            saleDocTender?.refTransaction?.EntryMethod ? saleDocTender?.refTransaction?.EntryMethod : ""
          }|\n`;
          sTextPrint += `|Auth Code | ${saleDocTender?.refTransaction?.AuthCode}|\n`;
          sTextPrint += `|EMV App Label | ${saleDocTender?.refTransaction?.EMV_App_Label}|\n`;
          sTextPrint += `|EMV App Id | ${saleDocTender?.refTransaction?.EMV_App_Name}|\n`;
          sTextPrint += `{w:*, a:center}\n\n\n\n`;
          sTextPrint += "|x_\\_\\_\\_\\_\\_\\_\\_\\_\\_\\_\\_\\_\\_\\_\\_\\_\\_\\_\\_\\_\\_\\_\\_\\_\\_\\_|\n";
          sTextPrint += `|Signature| \n\n`;
          sTextPrint += `|I AGREE TO PAY ABOVE TOTAL AMOUNT|\n`;
          sTextPrint += `|ACCORDING TO CARD ISSUER AGREEMENT|\n`;
          sTextPrint += `|(MERCHANT AGREEMENT IF CREDIT VOUCHER)|\n\n`;
        } else {
          sTextPrint += `\n`;
        }
      }
    });

    if (sTextPrint !== "") {
      if (bMerchantReceipt) {
        sTextPrint += `{w:*,a:center}\n\n`;
        sTextPrint += `|MERCHANT COPY| \n\n`;
      } else {
        sTextPrint += `{w:*,a:center}\n\n`;
        sTextPrint += `|CUSTOMER COPY| \n\n`;
      }
    }

    return sTextPrint;
  }

  /**
   * Create tape receipt text header
   * @param null
   * @returns sTextPrint
   */
  createStarCloudTapeReceiptTextOpenCashHeader() {
    let sTextPrint = "";
    const dateParts = TimeHelpers.SplitDateAndTime(new Date(), true, false);

    sTextPrint += `|StationId: ${this.appSettingsService.getStationId()} | Date: ${dateParts[0]}|\n`;
    sTextPrint += `|User: ${this.appSettingsService.getUser().userName} | Time: ${dateParts[1]}|\n`;

    return sTextPrint;
  }

  createStarCloudTapeReceiptTextHeader(saleDoc: SaleDoc, prepOrder = false) {
    let textPrint = "";
    let sDate = "";
    let sTime = "";
    let sDateTime = "";
    let sDoc = "";
    let sUser = "";
    let sDocUser = "";
    let sStore = "";
    let sTill = "";
    let sStoreTill = "";
    let isPackingList = false;

    // Store name
    if (
      this.tapeSizeBuilderSettings.printStoreName &&
      this.tapeSizeBuilderSettings.printStoreName != undefined &&
      this.tapeSizeBuilderSettings.printStoreName != null
    ) {
      textPrint = saleDoc.store.storeName.toLocaleUpperCase() + "\n";
    }

    if (!prepOrder) {
      // Address
      if (
        this.tapeSizeBuilderSettings.printAddress &&
        saleDoc.store.address &&
        saleDoc.store.address != undefined &&
        saleDoc.store.address != null
      ) {
        if (
          saleDoc.store.address.line1 &&
          saleDoc.store.address.line1 != undefined &&
          saleDoc.store.address.line1 != null &&
          saleDoc.store.address.line1 != ""
        ) {
          textPrint += saleDoc.store.address.line1 + "\n";
        }
        if (
          saleDoc.store.address.line2 &&
          saleDoc.store.address.line2 != undefined &&
          saleDoc.store.address.line2 != null &&
          saleDoc.store.address.line2 != ""
        ) {
          textPrint += saleDoc.store.address.line2 + "\n";
        }
        if (
          saleDoc.store.address.city &&
          saleDoc.store.address.city != undefined &&
          saleDoc.store.address.city != null &&
          saleDoc.store.address.city != ""
        ) {
          textPrint += saleDoc.store.address.city;
        }
        if (
          saleDoc.store.address.subDivisionIsoCode &&
          saleDoc.store.address.subDivisionIsoCode != undefined &&
          saleDoc.store.address.subDivisionIsoCode != null &&
          saleDoc.store.address.subDivisionIsoCode != ""
        ) {
          textPrint += ", " + saleDoc.store.address.subDivisionIsoCode;
        }
        if (
          saleDoc.store.address.postalCode &&
          saleDoc.store.address.postalCode != undefined &&
          saleDoc.store.address.postalCode != null &&
          saleDoc.store.address.postalCode != ""
        ) {
          textPrint += " " + saleDoc.store.address.postalCode + "\n";
        }
      }

      // Tax ID
      if (
        this.tapeSizeBuilderSettings.printTaxNumber &&
        saleDoc.taxID != undefined &&
        saleDoc.taxID != null &&
        saleDoc.taxID != ""
      ) {
        textPrint += "Account Tax ID#: " + saleDoc.taxID + "\n";
      }

      // Phone number
      if (
        this.tapeSizeBuilderSettings.printPhoneNo &&
        this.tapeSizeBuilderSettings.printPhoneNo != undefined &&
        this.tapeSizeBuilderSettings.printPhoneNo != null
      ) {
        if (
          saleDoc.store.address?.addressPhone &&
          saleDoc.store.address?.addressPhone != null &&
          saleDoc.store.address?.addressPhone != undefined
        ) {
          textPrint += "Phone: " + saleDoc.store.address?.addressPhone.tel + "\n";
        }
      }

      // Website
      if (
        this.tapeSizeBuilderSettings.printWebsite &&
        this.tapeSizeBuilderSettings.printWebsite != undefined &&
        this.tapeSizeBuilderSettings.printWebsite != null
      ) {
        if (saleDoc.store.url) {
          textPrint += saleDoc.store.url + "\n";
        }
      }

      // Email
      if (
        this.tapeSizeBuilderSettings.printEmail &&
        this.tapeSizeBuilderSettings.printEmail != undefined &&
        this.tapeSizeBuilderSettings.printEmail != null
      ) {
        if (saleDoc.store.address?.addressEmail) {
          textPrint += saleDoc.store.address.addressEmail.email + "\n";
        }
      }
    }

    textPrint += "---\n";

    // Date
    if (
      this.tapeSizeBuilderSettings.printDate &&
      this.tapeSizeBuilderSettings.printDate != undefined &&
      this.tapeSizeBuilderSettings.printDate != null
    ) {
      sDate = "|Date: " + saleDoc.doc.docDate;
      sTime = "Time: " + saleDoc.doc.docTime + "|";
      sDateTime = sDate + " | " + sTime;
      textPrint += sDateTime + "\n";
    }

    // Document
    if (
      this.tapeSizeBuilderSettings.printDocumentNo &&
      this.tapeSizeBuilderSettings.printDocumentNo != undefined &&
      this.tapeSizeBuilderSettings.printDocumentNo != null &&
      !prepOrder
    ) {
      sDoc = "|Doc #: " + saleDoc.doc.docNo;
      sUser = "User: " + saleDoc.doc.user?.userName + "|";
      sDocUser = sDoc + " | " + sUser;
      textPrint += sDocUser + "\n";
    }

    sStore = "|Store: " + saleDoc.store.storeID;
    sTill = "Till: " + saleDoc.station?.stationNumber + "|";
    sStoreTill = sStore + " | " + sTill;
    textPrint += sStoreTill + "\n";

    isPackingList = saleDoc["isPackingList"];

    // Receipt document title
    textPrint += "---\n";
    if (prepOrder) {
      textPrint += "^^^PREP ORDER\n";
      if (saleDoc.dailyNo != null && saleDoc.dailyNo != undefined) {
        textPrint += "^^^#" + saleDoc.dailyNo + "\n";
      }
    } else {
      if (saleDoc.dailyNo != null && saleDoc.dailyNo != undefined) {
        textPrint += "^^^#" + saleDoc.dailyNo + "\n";
      }
      if (isPackingList) {
        textPrint += "^^^Packing List\n";
      } else {
        textPrint += `^^^${saleDoc.doc.documentTitle}\n`;
      }
    }

    return textPrint;
  }

  /**
   * Create tape receipt text detail
   * @param null
   * @returns sTextPrint
   */
  createStarCloudTapeReceiptOpenCashTextDetail() {
    const sTextPrint = `^^^Open Cash Drawer`;
    return sTextPrint;
  }

  createStarCloudTapeReceiptTextDetail(saleDoc: SaleDoc) {
    let sTextPrint = "";
    let sTextLine = "";

    let sSkuDisp: string;
    const sSkuColumn = "";

    let sQtyDisp: string;
    const sQtyColumn = "";

    let sPriceDisp: string;
    const sPriceColumn = "";

    let sAmountDisp: string;
    const sAmountColumn = "";

    let isPackingList = false;
    const sCurrencySymbol = this.getCurrencySymbol(saleDoc.doc.currencyIsoCode);
    const lineFormatDescription = "{w:*}\n";
    const lineFormatSkuQtyPriceAmount = "{w:16,5,10,12}\n";
    const lineFormatDiscount = "{w:4,*}\n";
    const lineIndent = "{w:3,*}\n";

    isPackingList = saleDoc["isPackingList"];

    sTextPrint += "---" + "\n";

    // Receipt column title
    sTextPrint += lineFormatSkuQtyPriceAmount;
    if (isPackingList) {
      sTextPrint += "|ITEM | QTY|" + "\n";
    } else {
      sTextPrint += "|ITEM | QTY| PRICE $| AMOUNT $|" + "\n";
    }
    sTextPrint += "---\n";

    // Receip detail
    for (const itemDetail of saleDoc.saleDocLines) {
      // Description
      sTextPrint += lineFormatDescription;
      sTextPrint +=
        "|" +
        (isPackingList ? "[   ] " : "") +
        itemDetail.docLine.inventory.description1.split("|").join("\\|") +
        " |\n";
      if (itemDetail.docLine.inventory.inventoryVariants.length > 0) {
        sTextPrint += lineIndent;
        sTextPrint += `|   |${itemDetail.docLine.inventory.inventoryVariants
          .map(
            (_inventoryVariant) =>
              _inventoryVariant.inventoryOptionsSetting?.optionName +
              ": " +
              _inventoryVariant.inventoryOptionsSettingValue?.optionValue
          )
          .join(", ")
          .split("|")
          .join("\\|")} |\n`;
      }

      // Price
      sPriceDisp = "";
      sAmountDisp = "";
      if (!isPackingList) {
        sPriceDisp = itemDetail.salePrice.toFixed(2);
        //sPriceDisp = (itemDetail.unitPrice * 1).toFixed(2);
        if (
          this.tapeSizeBuilderSettings.printUofM &&
          itemDetail.docLine.inventory.uOfMeasure &&
          itemDetail.docLine.inventory.uOfMeasure?.name
        ) {
          sPriceDisp +=
            itemDetail.docLine.inventory.uOfMeasure.name.length > 0
              ? `{\${itemDetail.docLine.inventory.uOfMeasure.name}}`
              : "";
        }
        // Amount
        sAmountDisp = (
          itemDetail.salePrice *
          itemDetail.qty *
          (saleDoc.doc.docType == SaleDocType.sales_return ? -1 : 1)
        ).toFixed(2);
      }

      // Text line
      if (isPackingList) {
        sTextPrint += lineFormatSkuQtyPriceAmount;
        sTextLine = "|" + itemDetail.docLine.inventory.sku + " | " + itemDetail.qty.toString() + "| | |\n";
      } else {
        sTextPrint += lineFormatSkuQtyPriceAmount;
        sTextLine =
          "|" +
          itemDetail.docLine.inventory.sku +
          " | " +
          itemDetail.qty.toString() +
          "| " +
          sPriceDisp +
          "| " +
          sAmountDisp +
          "|\n";
        if (
          itemDetail.discountAmount != 0 &&
          this.tapeSizeBuilderSettings.printOriginalPrice &&
          itemDetail.unitPrice != itemDetail.salePrice
        ) {
          sPriceDisp = (itemDetail.unitPrice * 1).toFixed(2);
          sTextLine +=
            lineFormatDiscount +
            `||Reg: ${sPriceDisp} ea, Save: ${(itemDetail.unitPrice - itemDetail.salePrice).toFixed(2)}\n`;
        }
      }

      sTextPrint += sTextLine;
    }

    sTextPrint += "---\n";

    if (!isPackingList) {
      // Subtotal
      sAmountDisp =
        sCurrencySymbol + (saleDoc.subTotal * (saleDoc.doc.docType == SaleDocType.sales_return ? -1 : 1)).toFixed(2);
      sTextPrint += "{w:23,*}\n";
      sTextPrint += "SUBTOTAL| " + sAmountDisp + "|\n";

      // Discount
      if (saleDoc.discountAmount != 0) {
        let sDiscountDescColumn = "DISCOUNT";
        if (saleDoc.discountType == "Percentage") {
          sDiscountDescColumn = "DISCOUNT (" + (saleDoc.discountAmount * 1).toFixed(2) + "%)";
        }
        sAmountDisp =
          sCurrencySymbol +
          (saleDoc.discountAmount * (saleDoc.doc.docType == SaleDocType.sales_return ? -1 : 1)).toFixed(2);
        sTextPrint += sDiscountDescColumn + "| " + sAmountColumn + "|\n";
      }

      const sTaxDescColumn = "TAX";
      sAmountDisp =
        sCurrencySymbol +
        (saleDoc.totalLineTax * (saleDoc.doc.docType == SaleDocType.sales_return ? -1 : 1)).toFixed(2);
      sTextPrint += sTaxDescColumn + "| " + sAmountDisp + "|\n";

      // SHIPPING
      if (saleDoc.shipperChargeAmount != 0) {
        const sShippingDescColumn = "SHIPPING";
        sAmountDisp =
          sCurrencySymbol +
          (saleDoc.shipperChargeAmount * (saleDoc.doc.docType == SaleDocType.sales_return ? -1 : 1)).toFixed(2);
        sTextPrint += sShippingDescColumn + "| " + sAmountDisp + "|\n";
      }

      //TOTAL
      const grandTotal =
        MonetaryHelpers.roundToDecimalPlaces(saleDoc.subTotal, 2) -
        MonetaryHelpers.roundToDecimalPlaces(saleDoc.discountAmount, 2) +
        MonetaryHelpers.roundToDecimalPlaces(saleDoc.totalLineTax, 2) +
        MonetaryHelpers.roundToDecimalPlaces(parseFloat(saleDoc.shipperChargeAmount + ""), 2);

      sTextPrint += "---\n";
      const sTotalDescColumn = "TOTAL";
      sAmountDisp =
        sCurrencySymbol + (grandTotal * (saleDoc.doc.docType == SaleDocType.sales_return ? -1 : 1)).toFixed(2);
      sTextPrint += "^^" + sTotalDescColumn + "| ^^" + sAmountDisp + "|\n";
      sTextPrint += "---\n";

      // PAY TYPE DETAILS
      if (this.tapeSizeBuilderSettings.printTenderDetail && saleDoc.saleDocTenders.length > 0) {
        sTextPrint += "PAY TYPE DETAILS\n";
        let sPayDescColumn = "";
        for (const payDetail of saleDoc.saleDocTenders) {
          sPayDescColumn = " ".repeat(10) + payDetail.tenderType.description;
          sAmountDisp = sCurrencySymbol + parseFloat(payDetail.amount + "").toFixed(2);
          sTextPrint += sPayDescColumn + "| " + sAmountDisp + "|\n";
        }
        sTextPrint += "---\n";
      }

      //
      const invoiceHelper = new InvoiceHelpers(saleDoc);
      const roundedBalanceBeforeCash: number = invoiceHelper.roundedBalanceBeforeCash();
      const totalCashPayments: number = invoiceHelper.totalCashPayments();
      if (this.tapeSizeBuilderSettings.printTenderDetail && totalCashPayments > 0) {
        sTextPrint += "CASH PAYMENT\n";
        let sPayDescColumn = "";
        if (this.tapeSizeBuilderSettings.printPennyRounding) {
          sPayDescColumn = " ".repeat(10) + "Cash Rounding";
          sAmountDisp = sCurrencySymbol + parseFloat(saleDoc.cashRounding + "").toFixed(2);
          sTextPrint += sPayDescColumn + "| " + sAmountDisp + "|\n";
        }

        sPayDescColumn = " ".repeat(10) + "Rounded Balance";
        sAmountDisp = sCurrencySymbol + parseFloat(roundedBalanceBeforeCash + "").toFixed(2);
        sTextPrint += sPayDescColumn + "| " + sAmountDisp + "|\n";

        sPayDescColumn = " ".repeat(10) + "Payment";
        sAmountDisp = sCurrencySymbol + parseFloat(totalCashPayments + "").toFixed(2);
        sTextPrint += sPayDescColumn + "| " + sAmountDisp + "|\n";

        sPayDescColumn = " ".repeat(10) + "Change Due";
        sAmountDisp =
          sCurrencySymbol + parseFloat(Math.max(0, totalCashPayments - roundedBalanceBeforeCash) + "").toFixed(2);
        sTextPrint += sPayDescColumn + "| " + sAmountDisp + "|\n";

        sTextPrint += "---\n";
      }
    }

    // YOU SAVE
    if (
      saleDoc.doc.docType != SaleDocType.sales_return &&
      saleDoc.totalLineDiscounts + saleDoc.discountAmountMoney > 0
    ) {
      sTextPrint += "{w:*, a:center}\n";
      sTextPrint +=
        '"' +
        "YOU SAVED: " +
        sCurrencySymbol +
        parseFloat(saleDoc.totalLineDiscounts + saleDoc.discountAmountMoney + "").toFixed(2) +
        '"' +
        "\n";
    }

    // Barcode
    if (saleDoc.doc.docNo != "" && saleDoc.doc.docNo != null && !isPackingList) {
      sTextPrint += `{code:${saleDoc.doc.docNo}; option:code128,2,72,hri}` + "\n";
    }

    return sTextPrint;
  }

  /**
   * Create tape receipt text detail
   * @param saleDoc
   * @returns sTextPrint
   */
  createStarCloudPrepTapeReceiptTextDetail(saleDoc: SaleDoc) {
    let iIndex = 0;
    let sTextPrint = "";
    const lineFormatDescription = "{w:35,*}\n";
    const lineIndent = "{w:3,*}\n";

    sTextPrint += "---" + "\n";

    // Receipt column title
    sTextPrint += "|^^ITEM | ^^QTY|" + "\n";
    sTextPrint += "---\n";

    // Receip detail
    for (const itemDetail of saleDoc.saleDocLines) {
      ++iIndex;
      sTextPrint += lineFormatDescription;
      sTextPrint += `|^^${itemDetail.docLine.inventory.description1.split("|").join("\\|")} | ^^${itemDetail.qty}|\n`;
      if (itemDetail.docLine.inventory.inventoryVariants.length > 0) {
        sTextPrint += lineIndent;
        sTextPrint += `|   |^^${itemDetail.docLine.inventory.inventoryVariants
          .map(
            (_inventoryVariant) =>
              _inventoryVariant.inventoryOptionsSetting?.optionName +
              ": " +
              _inventoryVariant.inventoryOptionsSettingValue?.optionValue
          )
          .join(", ")
          .split("|")
          .join("\\|")} |\n`;
      }
      sTextPrint += lineFormatDescription;
      sTextPrint += `|^^${itemDetail.docLine.inventory.sku}\n`;
      if (iIndex !== saleDoc.saleDocLines.length) {
        sTextPrint += `\n`;
      }
    }

    sTextPrint += "---\n";
    return sTextPrint;
  }

  /**
   * Method to fetch image from Url
   * @param url
   * @returns
   */
  getBase64ImageFromURL(url: string): Observable<string> {
    return Observable.create((observer: Observer<string>) => {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = url;
      if (!img.complete) {
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }

  /**
   * Method to create base64Data Url from fetched imag
   * @param img
   * @returns base64
   */
  getBase64Image(img: HTMLImageElement): string {
    const canvas: HTMLCanvasElement = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx: CanvasRenderingContext2D = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    const dataURL: string = canvas.toDataURL("image/png");
    return dataURL;
  }
}
