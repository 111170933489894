/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { CurrencyPipe } from "@angular/common";
import * as _ from "lodash";
import { AppConstants } from "../../../../shared/app-constants";
import { MonetaryHelpers } from "../../../../utility/MonetaryHelpers";
import { Account } from "../../../contact-accounts/account/account";
import { Address, AddressType } from "../../../contact-accounts/address/address";
import { CommercialAccount } from "../../../contact-accounts/commercial-account/commercial-account";
import { PersonalAccount } from "../../../contact-accounts/personal-account/personal-account";
import { Shipper } from "../../../settings/business-settings/shipper/shipper";
import { Station } from "../../../settings/store-settings/station/station";
import { Store } from "../../../settings/store-settings/store/store";
import { Doc, DocState, SaleDocType } from "../../doc/doc";
import { SaleDocLineTax } from "../sale-doc-line-tax/sale-doc-line-tax";
import { SaleDocLine, TotalBySaleTax } from "../sale-doc-line/sale-doc-line";
import { SaleDocTax } from "../sale-doc-tax/sale-doc-tax";
import { SaleDocTender } from "../sale-doc-tender/sale-doc-tender";
import { FormatNegativeNumberPipe } from "../../../../shared/pipes/format-negative-number.pipe";
import { SaleChannel } from "src/app/core/sale-channel/sale-channel";
import { DeliveryMethod } from "src/app/core/sale-channel/sale-channel-orders/sale-channel-order";
import { Cashout } from "src/app/core/cashout/cashout/cashout";

export class SaleDoc {
  id: number;
  deliveryDate: Date;
  trackNo: string;
  subTotal: number;
  discountType: DiscountType;
  discountAmount: number;
  accountId: number;
  account: Account;
  personalAccount: PersonalAccount;
  commercialAccount: CommercialAccount;
  shipperId: number;
  shipper: Shipper;
  stationId: number;
  station: Station;
  storeId: number;
  store: Store;
  shippingAddress: Address;
  billingAddress: Address;
  doc: Doc;
  saleDocLines: SaleDocLine[];
  shippingMethod: JSON;
  shipperChargeAmount: number;
  saleDocTaxes: SaleDocTax[];
  saleDocTenders: SaleDocTender[];
  cashRounding: number;
  cashoutId: number;
  cashout: Cashout;
  deliveryMethod: DeliveryMethod;
  deliveryStatus: DeliveryStatus;
  totalLineTax: number;
  taxID: string;
  taxAccountCategoryId: number;
  fulfillmentStatus: FulfillmentStatus;
  paymentStatus: PaymentStatus;
  customerSource: SaleDocSource;
  accountName: string;
  saleChannelId: number;
  saleChannel: SaleChannel;
  dailyNo: number;
  updatedAt: string;

  constructor(docType: SaleDocType = SaleDocType.sales_invoice, defaultCurrencyIsoCode?: string) {
    this.id = 0;
    this.deliveryDate = null;
    this.trackNo = "";
    this.subTotal = 0;
    this.discountType = DiscountType.Percentage;
    this.discountAmount = 0;
    this.accountId = null;
    this.account = new Account();
    this.personalAccount = new PersonalAccount();
    this.commercialAccount = new CommercialAccount(defaultCurrencyIsoCode);
    this.shipperId = null;
    this.shipper = new Shipper();
    this.stationId = null;
    this.station = new Station();
    this.storeId = null;
    this.store = null;
    this.shippingAddress = new Address(null, AddressType.shippingAddress);
    this.billingAddress = new Address(null, AddressType.billingAddress);
    this.doc = new Doc(docType, DocState.suspended);
    this.saleDocLines = [];
    this.shippingMethod = null;
    this.shipperChargeAmount = 0;
    this.saleDocTaxes = [];
    this.saleDocTenders = [];
    this.cashRounding = 0;
    this.cashoutId = null;
    this.cashout = null;
    this.deliveryMethod = DeliveryMethod.storePickup;
    this.deliveryStatus = DeliveryStatus.pending;
    this.totalLineTax = 0;
    this.taxID = null;
    this.taxAccountCategoryId = null;
    this.fulfillmentStatus = null;
    this.paymentStatus = PaymentStatus.unpaid;
    this.customerSource = SaleDocSource.Store;
    this.accountName = null;
    this.saleChannelId = null;
    this.saleChannel = null;
    this.dailyNo = null;
  }

  static getFinalStoreDeliveryStatus(docType: SaleDocType): DeliveryStatus {
    switch (docType) {
      case SaleDocType.sales_return:
        return DeliveryStatus.returned;
      case SaleDocType.pre_order:
        return DeliveryStatus.pending;
      case SaleDocType.sales_invoice:
      default:
        return DeliveryStatus.pickedUp;
    }
  }

  // get dailyNoStr() {
  //   if(this.dailyNo) {
  //     var s = "000000000" + this.dailyNo;
  //     return s.substr(s.length-3);
  //   } else {
  //     return '000';
  //   }
  // }

  get accountEmail() {
    if (!this.account) {
      return null;
    }

    const contactAccount = Account.GetContactAccount(this);
    return contactAccount ? _.get(contactAccount.accountEmails, "[0]", null) : null;
  }

  get accountPhone() {
    if (!this.account) {
      return null;
    }

    const contactAccount = Account.GetContactAccount(this);
    return contactAccount ? _.get(contactAccount.accountPhones, "[0]", null) : null;
  }

  get discountAmountMoney() {
    switch (this.discountType) {
      case DiscountType.Fixed:
        return this.discountAmount;

      case DiscountType.Percentage:
        return (this.subTotal * this.discountAmount) / 100;

      default:
        return 0;
    }
  }

  get groupedTaxLines(): TotalBySaleTax[] {
    const linesTaxes = _.flatten(this.saleDocLines.map((saleLine) => saleLine.saleDocLineTaxes));
    const groupedTaxes = _.groupBy(linesTaxes, (lineTax: SaleDocLineTax) => lineTax.taxRuleId);
    const totalGrouped = _.map(groupedTaxes, (taxes: SaleDocLineTax[]) => {
      if (!taxes[0].taxRule)
        return {
          taxes: taxes,
          saleTaxRule: null,
          total: _.sumBy(taxes, (tax) => parseFloat(tax.amount + "")),
        };

      return {
        taxes: taxes,
        saleTaxRule: taxes[0].taxRule,
        total: _.sumBy(taxes, (tax) => parseFloat(tax.amount + "")),
      };
    });
    return totalGrouped;
  }

  get uniqueTaxAccountNos(): string[] {
    const uniqueTaxAccountNos: string[] = [];

    this.groupedTaxLines.forEach((row) => {
      const taxAccountNo = row.saleTaxRule.tax.taxAccountNo;
      if (!uniqueTaxAccountNos.includes(taxAccountNo)) {
        uniqueTaxAccountNos.push(taxAccountNo);
      }
    });

    return uniqueTaxAccountNos;
  }

  get grandTotal(): number {
    return (
      MonetaryHelpers.roundToDecimalPlaces(this.subTotal, 2) -
      MonetaryHelpers.roundToDecimalPlaces(this.discountAmountMoney, 2) +
      MonetaryHelpers.roundToDecimalPlaces(this.totalTax, 2) +
      MonetaryHelpers.roundToDecimalPlaces(parseFloat(this.shipperChargeAmount + ""), 2)
    );
    // MonetaryHelpers.roundToDecimalPlaces(parseFloat(this.shipperChargeAmount + ''), 2) +
    // MonetaryHelpers.roundToDecimalPlaces(this.cashRounding, 2);
  }

  get totalTax() {
    // First calculate line taxes
    // let totalTaxes = _.sumBy(this.groupedTaxLines, taxTotal => taxTotal.total);
    let totalTaxes = parseFloat(this.totalLineTax + "");
    // them sum up document taxes
    if (this.saleDocTaxes.length > 0) {
      totalTaxes += this.saleDocTaxes
        .map((tax) => parseFloat(tax.amount + ""))
        .reduce((total, amount) => total + amount);
    }

    return totalTaxes;
  }

  get grandTotalDecimalFormat(): string {
    return this.grandTotal.toFixed(2);
  }

  get grandTotalCurrencyFormat(): string {
    let formattedTotal;
    if (this.doc.currencyIsoCode)
      formattedTotal = new CurrencyPipe(AppConstants.DEFAULT_LOCALE_ID).transform(
        this.doc.docType === SaleDocType.sales_return ? this.grandTotal * -1 : this.grandTotal,
        this.doc.currencyIsoCode,
        "code",
        "1.2-2"
      );
    else formattedTotal = this.grandTotalDecimalFormat;

    return new FormatNegativeNumberPipe().transform(formattedTotal);
  }

  get totalItems() {
    let totalItems = 0;
    if (this.saleDocLines.length > 0) {
      totalItems = this.saleDocLines.map((line) => line.qty).reduce((total, qty) => total + qty);
    }

    return totalItems;
  }

  get totalLineDiscounts(): number {
    let lineDiscounts = 0;
    if (this.saleDocLines.length > 0) {
      lineDiscounts = this.saleDocLines.map((line) => line.lineDiscount).reduce((total, amount) => total + amount);
    }

    return lineDiscounts;
  }

  get totalSavings(): number {
    return this.totalLineDiscounts + this.discountAmountMoney;
  }

  get totalPayments(): number {
    let payments = 0;
    if (this.saleDocTenders.length > 0) {
      payments = this.notReturnedTenders
        .map((line) => parseFloat(line.amount + ""))
        .reduce((total, amount) => total + amount);
    }

    return payments;
  }

  get tenderTypesList(): string {
    return [
      ...new Set(
        this.notReturnedTenders.map((docTender) =>
          docTender.refTransaction != null
            ? docTender.refTransaction.CardType
            : docTender.tenderType
            ? docTender.tenderType.description
            : null
        )
      ),
    ]
      .filter(Boolean)
      .join(", ");
  }

  get notReturnedTenders() {
    // return this.saleDocTenders.filter(line => !line.isReturned);
    return this.saleDocTenders;
  }

  static convertIntoTypedObject(saleDoc: SaleDoc): SaleDoc {
    const typedSaleDoc: SaleDoc = _.merge(new SaleDoc(), saleDoc);
    typedSaleDoc.saleDocLines = typedSaleDoc.saleDocLines.map((saleLine) =>
      SaleDocLine.convertIntoTypedObject(saleLine)
    );
    typedSaleDoc.discountAmount = MonetaryHelpers.roundToDecimalPlaces(typedSaleDoc.discountAmount);
    typedSaleDoc.store = Store.convertIntoTypedObject(typedSaleDoc.store);

    return typedSaleDoc;
  }

  static getStockAdjustmentType(docType) {
    switch (docType) {
      case SaleDocType.sales_invoice:
      case SaleDocType.online_sales_order:
      case SaleDocType.pre_order:
      case SaleDocType.recurring_order:
        return "Out";
      case "Sales Return":
        return "In";
      default:
        return "Out";
    }
  }
}

export enum DiscountType {
  Fixed = "Fixed",
  Percentage = "Percentage",
}

export enum SaleDocSource {
  Store = "Store",
  Phone = "Phone",
  Online = "Online",
}

export enum FulfillmentStatus {
  unfulfilled = "Unfulfilled",
  processing = "Processing",
  partly_Fulfillment = "Partly Fulfilled",
  completed = "Completed",
  backToStock = "Back to Stock",
  voided = "Voided",
}

export enum PaymentStatus {
  unpaid = "Unpaid",
  authorized = "Authorized",
  partlyPaid = "Partly Paid",
  paid = "Paid",
  voided = "Voided",
  partlyRefunded = "Partly Refunded",
  refunded = "Refunded",
  incomplete = "Incomplete",
  disputed = "Disputed",
}

export enum DeliveryStatus {
  pending = "Pending",
  readyForPickup = "Ready for Pickup",
  pickedUp = "Picked Up",
  delivered = "Delivered",
  returned = "Returned",
  voided = "Voided",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
