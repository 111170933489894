/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Store } from "../store/store";

export class PrinterSettings {
  id: number;
  name: string;
  uniqueAddress: string;
  manufacturer: PrinterManufacturer;
  storeId: number;
  store: Store;
  isActive: boolean;

  constructor() {
    this.id = 0;
    this.name = "";
    this.uniqueAddress = "";
    this.manufacturer = null;
    this.store = null;
    this.isActive = false;
  }
}

export enum PrinterManufacturer {
  star_cloudPRNT = "Star CloudPRNT",
}
/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
