/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
export class Tax {
  id: number;
  shortDesc: string;
  longDesc: string;
  taxAccountNo: string;
  fundReceiver: string;
  isFlatTax: boolean;
  isRefundable: boolean;
  taxTotal: TaxTotal;
  taxLocationBasis: TaxLocationBasis;
  isRecoverable: boolean;
  taxPriceBasis: TaxPriceBasis;
  isTaxIncluded: boolean;
  isShippingIncluded: boolean;
  isCompound: boolean;
  compoundSeq: number;
  effectiveDate: Date;
  applyEffectiveDate: boolean;
  endDate: Date;
  currencyIsoCode: string;
  isEnabled: boolean;
  isOnlineTax: boolean;
  printOnReceipt: boolean;

  constructor() {
    this.id = 0;
    this.shortDesc = "";
    this.longDesc = "";
    this.taxAccountNo = "";
    this.fundReceiver = "";
    this.isFlatTax = false;
    this.isRefundable = true;
    this.taxTotal = TaxTotal.byLine;
    this.taxLocationBasis = TaxLocationBasis.destinationBased;
    this.isRecoverable = true;
    this.taxPriceBasis = TaxPriceBasis.salePrice;
    this.isTaxIncluded = false;
    this.isShippingIncluded = false;
    this.isCompound = false;
    this.compoundSeq = 0;
    this.effectiveDate = null;
    this.applyEffectiveDate = false;
    this.endDate = null;
    this.isEnabled = true;
    this.currencyIsoCode = "CAD";
    this.isOnlineTax = false;
    this.printOnReceipt = true;
  }
}

export enum TaxPriceBasis {
  salePrice = "Sale Price",
  landedCost = "Landed Cost",
  customValue = "Custom Value",
}

export enum TaxTotal {
  byLine = "By Line",
  ByTotal = "By Total",
}

export enum TaxLocationBasis {
  originBased = "Origin",
  destinationBased = "Destination",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
