/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { BehaviorSubject, Subject } from "rxjs";
import { GenericSaleDocComponent } from "./sale-document/generic-sale-doc.component";
import { SaleDoc } from "./sale-document/sale-doc/sale-doc";
import { SearchDocForReturnStateService } from "./return-document/document-search-return/search-document-return-state.service";
import { GenericInvoiceSaleComponent } from "./sale-document/generic-invoice-doc.component";
import { Injectable } from "@angular/core";
import { SaleDocType } from "./doc/doc";

@Injectable()
export abstract class GenericInvoiceStateService {
  // Observable string sources
  // By default tender screen is closed
  private tenderScreenOpenSource = new BehaviorSubject<boolean>(false);
  // Sales has been saved on scren
  private salesSavedSource = new Subject<SaleDoc>();
  private saleDocTypeSource = new BehaviorSubject<SaleDocType>(null);

  // Observable string streams
  tenderScreenOpen$ = this.tenderScreenOpenSource.asObservable();
  salesSaved$ = this.salesSavedSource.asObservable();
  docType$ = this.saleDocTypeSource.asObservable();

  constructor(private searchDocForReturnStateService: SearchDocForReturnStateService) {}

  // Service message commands
  anounceTenderScreenVisibility(isOpen: boolean) {
    this.tenderScreenOpenSource.next(isOpen);
  }

  anounceSalesSaved(saleDoc) {
    this.salesSavedSource.next(saleDoc);
  }

  docTypeChanged(docType: SaleDocType) {
    this.saleDocTypeSource.next(docType);
  }

  abstract saveDocState(component: GenericSaleDocComponent);

  saveInvoiceState(component: GenericInvoiceSaleComponent) {
    // If 'Return Item Search' overlay is present/available save its state, together with the parent document
    if (component._returnsSearchComponent)
      this.searchDocForReturnStateService.saveComponentState(component._returnsSearchComponent);
  }

  abstract restoreDocState(newComponent: GenericSaleDocComponent);

  abstract restoreInvoiceState(newComponent: GenericInvoiceSaleComponent);

  abstract hasDocState(): boolean;

  abstract hasInvoiceState(): boolean;

  abstract clearDocState();

  clearInvoiceState(component: GenericInvoiceSaleComponent) {
    this.searchDocForReturnStateService.clearState();
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
