/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

export class PersonSocialProfile {
  id: number;
  socialProfileType: PersonSocialProfileType;
  socialProfile: string;

  constructor() {
    this.id = 0;
    this.socialProfileType = null;
    this.socialProfile = "";
  }
}

export enum PersonSocialProfileType {
  facebook = "Facebook",
  instagram = "Instagram",
  twitter = "Twitter",
  pinterest = "Pinterest",
  linkedIn = "LinkedIn",
  other = "Other",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
