/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DBSearchService } from "src/app/shared/services/db-search.service";

@Injectable({
  providedIn: "root",
})
export class EconduitService extends DBSearchService {
  //Result code which return from eConduit Cloud
  public sResultCodeError = "Error";
  public sResultCodeSuccess = "Success";
  public sResultCodeApproved = "Approved";
  public sResultCodeDeclined = "Declined";
  public sResultCodeCancelled = "Cancelled";
  public sResultCodeTerminalBusy = "Terminal Busy";
  public sResultCodeConnectionTimeout = "Connection Timeout";

  public sMesageUnknownError = "Unknown Error";
  public sMesageCommError = "No response from terminal, unable to determine transaction approval or decline.";
  public sMesageTerminalOffline = "Terminal is offline.";
  public sMessageCloseBatchApproved = "APPROVED";
  public sMessageCloseBatchAlreadySettled = "Batch Already Settled";
  public sMesageCheckStatusTransaction = "Check Status is not supported for transactions older than 45 days.";

  pairTerminal(id: number): Observable<any> {
    return this._postRequest(this.webUrl + "eConduitPairTerminal/" + id, {}, {});
  }

  unpairTerminal(id: number): Observable<any> {
    return this._postRequest(this.webUrl + "eConduitUnPairTerminal/" + id, {}, {});
  }

  runTransaction(command: string, id: number, body: any): Observable<any> {
    return this._postRequest(this.webUrl + "eConduitRunTransaction/" + command + "/" + id, body, {});
  }

  checkStatus(command: string, id: number, body: any): Observable<any> {
    return this._postRequest(this.webUrl + "eConduitCheckStatus/" + command + "/" + id, body, {});
  }

  closeBatch(id: number): Observable<any> {
    return this._postRequest(this.webUrl + "eConduitCloseBatch/" + id, {}, {});
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
