<taku-form-dialoge-header
  [pristine]="_myForm.pristine"
  [valid]="_myForm.valid"
  (backClicked)="goBack()"
  (saveClicked)="onSave()"
  (revertClicked)="onRevert()"
  (cancelClicked)="closeModal()"
  (newClicked)="onNew()"
  (deleteClicked)="onDelete()"
  (cloneClicked)="onClone()"
  toolbarTitle="Account Group Details"
>
</taku-form-dialoge-header>

<form [formGroup]="_myForm" novalidate *ngIf="active">
  <div class="card card-w-title">
    <div class="grid form-group">
      <div class="col-12 xl:col-10 xl:col-offset-1">
        <taku-checkbox [formGroup]="_myForm" [fieldName]="'isEnabled'" fieldCaption="Is Active?"> </taku-checkbox>
      </div>

      <div class="col-12 xl:col-10 xl:col-offset-1">
        <taku-input [formGroup]="_myForm" [fieldName]="'name'" [fieldCaption]="'Account Groups'"> </taku-input>
      </div>

      <div class="col-12 xl:col-10 xl:col-offset-1 drop-container">
        <taku-dropdown
          [formGroup]="_myForm"
          [options]="enum_options"
          [fieldName]="'option'"
          [fieldCaption]="'Option'"
          [appendTo]="'body'"
        >
        </taku-dropdown>
      </div>
    </div>
  </div>
</form>

<taku-form-footer></taku-form-footer>
