/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component } from "@angular/core";
import { ArrayFormComponent } from "src/app/forms/array-form/array-form.component";

@Component({
  selector: "taku-email-array",
  templateUrl: "./email-array.component.html",
  styleUrls: ["./email-array.component.scss"],
})
export class EmailArrayComponent extends ArrayFormComponent {}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
