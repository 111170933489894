/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Input, OnChanges, ElementRef, SimpleChanges } from "@angular/core";
import { LocationService } from "../../shared/services/location.service";
import { TakuInputCtrlBase } from "../TakuInputCtrlBase";

@Component({
  selector: "taku-sub-region",
  templateUrl: "./taku-sub-region.component.html",
  styleUrls: ["./taku-sub-region.component.scss"],
})
export class TakuSubRegionComponent extends TakuInputCtrlBase implements OnChanges {
  @Input() countryIsoCode: string;
  lookup_subdivisions: any[];

  constructor(public locationService: LocationService, public el: ElementRef) {
    super(el);
  }

  // Don't input fieldName into p-dropdown until options are loaded, or the dropdown will clear the form value
  hasLoadedOptions() {
    return this.lookup_subdivisions.length > 1;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["countryIsoCode"]) {
      this.lookup_subdivisions = this.locationService.lookupSubdivisionOptions(this.countryIsoCode);
      this.lookup_subdivisions.unshift({
        label: "",
        value: null,
      });
    }
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
