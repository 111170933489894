import { AfterViewInit, Component, ElementRef, ViewChild } from "@angular/core";
import { MenuItem } from "primeng/api";
import { Inventory } from "src/app/core/inventory/inventory/inventory";
import * as _ from "lodash";
import { SaleDocSelfCheckoutBaseComponent } from "../sale-doc-self-checkout-base/sale-doc-self-checkout-base.component";
import { ScrollPanel } from "primeng/scrollpanel";

enum DesktopSelfCheckoutPaymentSteps {
  ADD_ONS = 0,
  PAYMENT = 1,
}

@Component({
  selector: "taku-sale-doc-self-checkout-desktop",
  templateUrl: "./sale-doc-self-checkout-desktop.component.html",
  styleUrls: ["./sale-doc-self-checkout-desktop.component.scss"],
})
export class SaleDocSelfCheckoutDesktopComponent extends SaleDocSelfCheckoutBaseComponent implements AfterViewInit {
  originalRightSideBodyHeight: number;
  heightAdjustCount = 0;

  @ViewChild("selfCheckoutScrollPanel") selfCheckoutScrollPanelContainer: ScrollPanel;
  @ViewChild("rightSideBody") rightSideBodyDiv: ElementRef;

  ngAfterViewInit(): void {
    this.originalRightSideBodyHeight = this.rightSideBodyDiv.nativeElement.offsetHeight;
  }

  adjustShoppingCartListHeight(adjustCount: number): void {
    this.heightAdjustCount = adjustCount;
  }

  _highlightAndShowLine(index: number): void {
    const scrollToLineFn = (index: number) => {
      let scrollPos = 0;
      const prevLineEl = this.saleLinesEls.toArray()[index - 1];
      // Scroll to the bottom of the previous line, that is, the beginning of the desired line
      if (prevLineEl) scrollPos = prevLineEl.nativeElement.offsetTop + prevLineEl.nativeElement.offsetHeight;

      this.selfCheckoutScrollPanelContainer?.scrollTop(scrollPos);
    };

    this.selectedLineIndex = index;
    if (index > this.saleLinesEls.length - 1) {
      const subscription = this.saleLinesEls.changes.subscribe((queryList) => {
        if (index === queryList.length - 1) scrollToLineFn(index);

        subscription.unsubscribe();
      });
    } else scrollToLineFn(index);
  }

  // dvWidthAfterOffset(dvWidth: number): number {
  //   return this.dvWidth;
  // }

  dvHeightAfterOffset(dvHeight: number): number {
    let lengthToRemove = 300;
    if (this.isKioskDimensions()) {
      lengthToRemove += 85;
    } else {
      lengthToRemove += 20;
    }
    return dvHeight - lengthToRemove;
  }

  isKioskDimensions(): boolean {
    return window.innerWidth === 1080 && window.innerHeight === 1920;
  }
}
