import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Inventory } from "src/app/core/inventory/inventory/inventory";
import { SaleDocLine } from "../sale-doc-line/sale-doc-line";
import { SaleDocLineComponent } from "../sale-doc-line/sale-doc-line.component";
import { SaleDoc } from "../sale-doc/sale-doc";

@Component({
  selector: "taku-sale-doc-self-checkout-list-card",
  templateUrl: "./sale-doc-self-checkout-list-card.component.html",
  styleUrls: ["./sale-doc-self-checkout-list-card.component.scss"],
})
export class SaleDocSelfCheckoutListCardComponent extends SaleDocLineComponent {
  @Input() width: number;
  @Input() height: number;
  @Input() disabled = false;
  @Input() inDesktopMainPageCart = true;
  @Input() set saleDoc(_saleDoc: SaleDoc) {
    this._saleDoc = _saleDoc;
  }

  get saleDoc() {
    return this._saleDoc;
  }

  @Output() viewSelfCheckoutItemDetails: EventEmitter<UntypedFormGroup> = new EventEmitter();

  openItemDetailsDlg(e: MouseEvent) {
    e.stopImmediatePropagation();

    this.viewSelfCheckoutItemDetails.emit(this._myForm);
  }

  get qtyCtrl() {
    return this._myForm.get("qty");
  }

  onRemoveClicked() {
    // add validation here for deleting, then call next line
    this.confirmationService.confirm({
      header: "Confirmation of Item Removal",
      message: "Are you sure you want to remove this item from your cart?",
      rejectButtonStyleClass: "p-button-link",
      accept: () => {
        this.onDelete();
      },
      reject: () => {
        console.log("user rejected item removal!!");
      },
    });
  }

  get inventoryImagesUrl() {
    let result =
      this._myForm.get("docLine")?.get("inventory")?.value?.inventoryImages &&
      this._myForm
        .get("docLine")
        ?.get("inventory")
        ?.value.inventoryImages.find((row) => row.pinToPictureGallery == true)?.urlFileName150;
    if (!result) {
      result =
        this._myForm.get("docLine")?.get("inventory")?.value?.parentInventory?.inventoryImages &&
        this._myForm
          .get("docLine")
          ?.get("inventory")
          ?.value.parentInventory?.inventoryImages.find((row) => row.pinToPictureGallery == true)?.urlFileName150;
    }
    return result;
  }
}
