/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { AddressPhone } from "../address-phone/address-phone";
import { AddressEmail } from "../address-email/address-email";

export class Address {
  id: number;
  unitNum: string;
  line1: string;
  line2: string;
  countryIsoCode: string;
  subDivisionIsoCode: string;
  city: string;
  postalCode: string;
  addressType: AddressType;
  addressLocationType: AddressLocationType;
  attnCompanyName: string;
  attnFirstName: string;
  attnLastName: string;
  note: string;
  addressPhone: AddressPhone;
  addressEmail: AddressEmail;
  isDefault: boolean;

  constructor(addressLocationType: AddressLocationType = null, addressType?: AddressType) {
    this.id = 0;
    this.unitNum = "";
    this.line1 = "";
    this.line2 = "";
    this.countryIsoCode = null;
    this.subDivisionIsoCode = null;
    this.city = "";
    this.postalCode = "";
    this.addressType = addressType || AddressType.billingAddress;
    this.addressLocationType = addressLocationType || AddressLocationType.residential;
    this.attnCompanyName = "";
    this.attnFirstName = "";
    this.attnLastName = "";
    this.note = "";
    this.addressPhone = new AddressPhone();
    this.addressEmail = new AddressEmail();
    this.isDefault = false;
  }

  get attnFullPersonName() {
    if (!this.attnFirstName && !this.attnLastName) return "";

    return `${this.attnFirstName} ${this.attnLastName}`;
  }
}

export enum AddressType {
  billingAddress = "Billing Address",
  shippingAddress = "Shipping Address",
}

export enum AddressLocationType {
  residential = "Residential",
  commercial = "Commercial",
  poBox = "PO Box",
  other = "Other",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
