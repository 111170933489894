import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import * as JsBarcode from "jsbarcode";
import { get, sumBy } from "lodash";
import { DialogService } from "primeng/dynamicdialog";
import { Subject } from "rxjs";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import {
  LabelSizes,
  PrintQty,
  ProductTitle,
  ShowBarcode,
  ShowPrice,
} from "../inventory-label-settings/inventory-label-settings";
import { Inventory, InventoryStock } from "../inventory/inventory";

@Component({
  selector: "taku-inventory-label-printing",
  templateUrl: "./inventory-label-printing.component.html",
  styleUrls: ["./inventory-label-printing.component.scss"],
  providers: [DialogService],
})
export class InventoryLabelPrintingComponent implements OnInit, AfterViewInit {
  @Input() inventories: Inventory[];
  @Input() formGroup: UntypedFormGroup;
  @ViewChild("labelBarcode") labelBarcodeRef: ElementRef;
  @ViewChild("printPreviewHost", { read: ViewContainerRef, static: true }) printPreviewHost: ViewContainerRef;
  public enumLabelSizes = LabelSizes;
  readonly STYLESHEET_PATH = "/assets/layout/taku/inventory-label-printing.component.css";
  px2mmFactor: number;
  _digitsFormat = "1.2-2";
  _forLoop: Inventory[] = [];

  private _dataReadySubject = new Subject<void>();
  onDataReady$ = this._dataReadySubject.asObservable();

  constructor(public elementRef: ElementRef, private appSettings: AppSettingsStorageService) {}

  ngOnInit(): void {
    const activeStore = this.appSettings.getStore();
    const isAdmin = !activeStore.id;
    const storeId = this.appSettings.getStoreId();
    const defaultStockId = storeId ? this.appSettings.getStore().stockId : null;
    const backOfficeId = this.appSettings.getBackofficeId();
    this.inventories.forEach((inventory) => {
      const defaultStock: InventoryStock = inventory.inventoryStocks.find(
        (inventoryStock) => inventoryStock.stockId == defaultStockId
      );

      let _printQty = 0;
      if (this.formGroup.get("printQty").value == PrintQty.perSelected) {
        _printQty = this.formGroup.get("inputQty").value > 0 ? parseInt(this.formGroup.get("inputQty").value) : 0;
      } else {
        if (isAdmin || backOfficeId > 0) {
          _printQty = this.totalQtyOH(inventory.inventoryStocks);
        } else {
          _printQty = this.defaultQtyOH(defaultStock);
        }
      }

      for (let i = 0; i < _printQty; i++) {
        this._forLoop.push(inventory);
      }
    });
  }

  getBarcode(inventory: Inventory): string {
    if (this.formGroup.get("showBarcode").value === ShowBarcode.upc) {
      const firstBarcode = inventory.inventoryBarcodes.find((barcode) => typeof barcode !== "undefined");
      return firstBarcode?.barcode || inventory.sku;
    } else {
      return inventory.sku;
    }
  }

  private getBarcodeOptions() {
    switch (this.labelSize) {
      case LabelSizes.barcode_1Inchx1Inch:
        return {
          fontSize: 10,
          width: 0.6,
          height: 15,
          margin: 2,
          displayValue: true,
          ratio: 0.75,
        };
      case LabelSizes.barcode_2And1QuaterInchx1And1QuaterInch:
        return {
          fontSize: 10,
          width: 1.45,
          height: 20,
          margin: 2,
          displayValue: true,
          ratio: 1,
        };
      case LabelSizes.barcode_2And1QuaterInchx3QuaterInch:
        return {
          fontSize: 10,
          width: 1.45,
          height: 15,
          margin: 2,
          displayValue: true,
          ratio: 1,
        };
      case LabelSizes.shelf_2And1HalfInchx1and1QuaterInch:
        return {
          fontSize: 15,
          width: 1.55,
          height: 20,
          margin: 2,
          displayValue: true,
          ratio: 1,
        };
      default:
        return {
          fontSize: 10,
          width: 1,
          height: 20,
          margin: 2,
          displayValue: true,
          ratio: 1,
        };
    }
  }

  ngAfterViewInit(): void {
    if (this.formGroup.get("isShowBarcode").value === true) {
      const options = this.getBarcodeOptions();
      this.inventories.forEach((inventory) => {
        const barcode = inventory.sku
          .split(" ")
          .join("")
          .replace(/'/g, "\\'")
          .replace(/!/g, "\\!")
          .replace(/&/g, "\\&")
          .replace(/\./g, "\\.")
          .replace(/\?/g, "\\?");
        JsBarcode(`#barcode_${barcode}`, this.getBarcode(inventory), { ...options });
      });
    }
    this._dataReadySubject.next();
  }

  getStylesheets() {
    return [this.STYLESHEET_PATH];
  }

  getHTMLContent() {
    return this.elementRef.nativeElement;
  }

  defaultQtyOH(currentStock: InventoryStock): number {
    return get(currentStock, "qtyOH", 0);
  }

  totalQtyOH(allStocks: InventoryStock[]): number {
    return sumBy(allStocks, "qtyOH");
  }

  get labelSize(): string {
    return this.formGroup.get("labelSize").value;
  }

  labelDescription(inventory: Inventory): string {
    if (this.formGroup.get("productTitle").value === ProductTitle.altProductTitle) {
      return inventory.description2 || inventory.description1;
    } else {
      return inventory.description1;
    }
  }

  inventoryVariantsStr(inventory: Inventory): string {
    return inventory.inventoryVariants
      ?.map((_inventoryVariant) => _inventoryVariant.inventoryOptionsSettingValue?.optionValue)
      .join("/");
  }

  price(inventory: Inventory): string | number {
    if (this.formGroup.get("showPrice").value === ShowPrice.sellPrice) {
      return inventory.standardPrice;
    } else if (this.formGroup.get("showPrice").value === ShowPrice.altPrice) {
      return inventory.msrpPrice || inventory.standardPrice;
    } else {
      return inventory.standardPrice;
    }
  }

  get isShowPrice(): boolean {
    return this.formGroup.get("isShowPrice").value;
  }

  get isShowBarcode(): boolean {
    return this.formGroup.get("isShowBarcode").value;
  }
}
