<taku-form-header
  displayMode="full"
  (backClicked)="goBack()"
  toolbarTitle="Payment Settings"
  [showActionBtns]="false"
  [showBackBtn]="false"
></taku-form-header>

<taku-form-list
  *ngIf="active"
  [_deleteRowEvent]="checkIfCanDeleteDialog"
  [ngClass]="{ pullDown: true }"
  _model="paymentGateway"
  _detailMode="n"
  [_title]="_formlistTitle"
  [_filter]="_formListFilter"
  [_bulkEditorEnabled]="false"
  (onRowsFetched)="registerRowFormsChanges()"
  (_onRowsDiscarded)="registerRowFormsChanges()"
  (_allRowsSaved)="onPaymentGatewaysSaved($event)"
></taku-form-list>

<p-confirmDialog
  [key]="confirmDialogKey"
  header="Confirmation"
  icon="ui-icon-help"
  [style]="{ width: '300pt', maxWidth: 'calc(100vw - 18pt)' }"
></p-confirmDialog>
