/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { EventEmitter, Injectable, TemplateRef } from "@angular/core";
import { ValidatorFn, Validators } from "@angular/forms";
import { SelectItem, MessageService } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { Observable, of } from "rxjs";
import { map, mergeMap, shareReplay } from "rxjs/operators";
import {
  AutocompleteDatatypeOptions,
  AutocompleteTransformer,
  DataType,
  ExecuteEvent_DisplayMode,
} from "../../../../../form-list/form-list/form-list";
import {
  FilterType,
  TemplateDatatypeOptions,
  ExecuteEventDatatypeOptions,
  Col,
} from "../../../../../form-list/form-list/form-list";
import { FormListModel } from "../../../../../form-list/form-list/form-list-model.interface";
import { GmbLocationDescriptionComponent } from "../../gmb-location-description/gmb-location-description.component";
import { BackendGMBService } from "../BackendGMBService";
import { GMBLocation } from "./GMBLocation";
import { Store } from "../../../store-settings/store/store";
import * as _ from "lodash";
import { AlertMessagesService } from "../../../../../shared/services/alert-messages.service";
import { FormListComponent } from "../../../../../form-list/form-list/form-list.component";

export type GMBCategory = {
  displayName: string;
  categoryId: string;
};

class GMBCategoryAutocompleteTransformer implements AutocompleteTransformer {
  toAutocompleteItem(category: GMBCategory): SelectItem {
    return {
      label: category.displayName,
      value: category.categoryId,
    };
  }

  fromAutocompleteItem(item: SelectItem): GMBCategory {
    return {
      categoryId: item.value,
      displayName: item.label,
    };
  }
}

@Injectable({
  providedIn: "root",
})
export class GMBLocationsFactoryService {
  constructor(
    public dbService: BackendGMBService,
    public alertMessagesService: AlertMessagesService,
    public messageService: MessageService
  ) {}

  build(
    gmbAccountId,
    dialogService: DialogService,
    showStoreDetailsEvent: EventEmitter<GMBLocation>,
    verificationColOptions: TemplateDatatypeOptions
  ): GMBLocations {
    return new GMBLocations(this, gmbAccountId, dialogService, showStoreDetailsEvent, verificationColOptions);
  }
}

export class GMBLocations implements FormListModel {
  private _pushToGMB: EventEmitter<GMBLocation> = new EventEmitter();
  _formListComponent: FormListComponent;

  setRowDescriptionEvent: EventEmitter<GMBLocation> = new EventEmitter();
  categoryTransformer = new GMBCategoryAutocompleteTransformer();
  enumTargetCountry: SelectItem[] = [
    { label: "Canada", value: "CA" },
    { label: "United States", value: "US" },
  ];
  enumContentLanguage: SelectItem[] = [
    { label: "English", value: "en" },
    { label: "French", value: "fr" },
  ];

  constructor(
    private factoryService: GMBLocationsFactoryService,
    private gmbAccountId: number,
    private dialogService: DialogService,
    private showStoreDetailsEvent: EventEmitter<GMBLocation>,
    private verificationColOptions: TemplateDatatypeOptions
  ) {
    this.setRowDescriptionEvent.subscribe((gmbLocation: GMBLocation) => {
      const ref = this.dialogService.open(GmbLocationDescriptionComponent, {
        width: "80%",
        data: {
          description: gmbLocation.description,
        },
      });

      ref.onClose.subscribe((newData) => {
        // Reemplace edited data on current row
        Object.assign(gmbLocation, newData);
      });
    });

    this._pushToGMB.subscribe((dbGMBLocation: GMBLocation) => {
      this.factoryService.dbService
        .getRow("store", dbGMBLocation.storeId)
        .pipe(
          mergeMap((store: Store) => {
            if (dbGMBLocation.name)
              return this.factoryService.dbService
                .updateGMBLocation(dbGMBLocation.id, dbGMBLocation, store)
                .pipe(map((response: any) => [response, store]));
            else
              return this.factoryService.dbService
                .createGMBLocation(dbGMBLocation.id, dbGMBLocation, store)
                .pipe(map((response: any) => [response, store]));
          }),
          mergeMap(([googleResponse, store]) => {
            dbGMBLocation = this.factoryService.dbService.updateGMBLocationStoreInfo(
              dbGMBLocation,
              store,
              googleResponse
            );
            return this.factoryService.dbService.editRow("gmbLocation", dbGMBLocation);
          })
        )
        .subscribe({
          next: (response) => {
            this._formListComponent.reloadData();
            this.factoryService.messageService.add(
              this.factoryService.alertMessagesService.getMessage("gmb-location-create")
            );
          },
          error: (error) => {
            this.factoryService.messageService.add(this.factoryService.alertMessagesService.getGMB_errorMessage(error));
          },
        });
    });
  }

  getLookup_stores(): Observable<SelectItem[]> {
    return this.factoryService.dbService
      .lookupSelectOptions("store", "storeName", { enableFieldName: "isActive" })
      .pipe(shareReplay(1));
  }

  onStoreChanged(rowData) {
    this.factoryService.dbService.getRow("store", rowData.storeId).subscribe((storeData) => {
      rowData.store = _.extend(new Store(), storeData);

      if (!rowData.name || !rowData.locationAddress) {
        // if not name, means location is not created yet on GMB
        const newAddress = rowData.store ? rowData.store.addressPrettyPrint : "";
        if (this._formListComponent) this._formListComponent.setFieldValue(rowData, "locationAddress", newAddress);
        else rowData.locationAddress = newAddress;
      }
    });
  }

  categoryOnAutocomplete(event, rowData: GMBLocation): Observable<GMBCategory[]> {
    const regionCode = rowData.targetCountry || "CA";
    return this.factoryService.dbService
      .getCategories(this.gmbAccountId, event.query, regionCode)
      .pipe(map((response: any) => response.categories as GMBCategory[]));
  }

  getValidationRules(): { [key: string]: ValidatorFn[] | {} } {
    return {
      name: [Validators.required],
      storeCode: [Validators.required],
      locationName: [Validators.required],
      locationAddress: [Validators.required],
      primaryCategory: [Validators.required],
      targetCountry: [Validators.required],
      contentLanguage: [Validators.required],
      description: [Validators.required],
      storeId: [Validators.required],
      gmbAccountId: [Validators.required],
    };
  }

  getFormListColumns(): Col[] {
    return [
      {
        field: "pushToGMCButton",
        header: "",
        visible: true,
        readonly: true,
        frozen: true,
        dataType: DataType.execute_event,
        dataOptions: [],
        dataTypeOptions: [
          new ExecuteEventDatatypeOptions({
            displayMode: ExecuteEvent_DisplayMode.BUTTON,
            label: "PUSH TO GMB",
            event: this._pushToGMB,
            enabledOnEditMode: false,
            styleClass: "",
          }),
        ],
        filterType: FilterType.none,
        colWidth: 185,
      },
      {
        field: "storeDetailsButton",
        header: "",
        visible: true,
        readonly: true,
        frozen: true,
        dataType: DataType.execute_event,
        dataOptions: [],
        dataTypeOptions: [
          new ExecuteEventDatatypeOptions({
            displayMode: ExecuteEvent_DisplayMode.LINK,
            label: "Store Details",
            event: this.showStoreDetailsEvent,
            enabledOnEditMode: false,
          }),
        ],
        filterType: FilterType.none,
        colWidth: 175,
      },
      {
        field: "verificationStatus",
        header: "Verification Status",
        visible: true,
        readonly: true,
        frozen: true,
        dataType: DataType.template,
        dataOptions: [],
        dataTypeOptions: this.verificationColOptions,
        filterType: FilterType.none,
        colWidth: 200,
      },
      {
        field: "descriptionColumn",
        header: "Description",
        visible: true,
        readonly: true,
        frozen: true,
        dataType: DataType.execute_event,
        dataOptions: [],
        dataTypeOptions: [
          new ExecuteEventDatatypeOptions({
            displayMode: ExecuteEvent_DisplayMode.LINK,
            label: "Set Description",
            event: this.setRowDescriptionEvent,
          }),
        ],
        filterType: FilterType.none,
        colWidth: 195,
      },
      {
        field: "storeId",
        header: "TAKU Store",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.lookup,
        dataOptions: this.getLookup_stores(),
        onModelChange: this.onStoreChanged.bind(this),
        filterType: FilterType.contains,
        sortFields: ["store.storeName"],
        colWidth: 200,
      },
      {
        field: "store.stock.stockName",
        header: "Default Stock",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.none,
      },
      {
        field: "storeCode",
        header: "Store Code",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "locationName",
        header: "Location Name",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "accountName",
        header: "Parent Group/Account",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "locationAddress",
        header: "Location Address",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 250,
      },
      {
        field: "primaryCategory",
        header: "Primary Category",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.autocomplete,
        dataOptions: [],
        filterType: FilterType.contains,
        dataTypeOptions: new AutocompleteDatatypeOptions({
          completeMethod: this.categoryOnAutocomplete.bind(this),
          transformer: this.categoryTransformer,
        }),
      },
      {
        field: "additionalCategories",
        header: "Additional Categories",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.autocomplete,
        dataOptions: [],
        filterType: FilterType.contains,
        dataTypeOptions: new AutocompleteDatatypeOptions({
          completeMethod: this.categoryOnAutocomplete.bind(this),
          transformer: this.categoryTransformer,
          isMultiple: true,
        }),
        colWidth: 350,
      },
      {
        field: "targetCountry",
        header: "Target Country",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enumTargetCountry,
        filterType: FilterType.contains,
      },
      {
        field: "contentLanguage",
        header: "Content Language",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enumContentLanguage,
        filterType: FilterType.contains,
      },
    ];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
