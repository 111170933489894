/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewContainerRef,
  OnInit,
  OnDestroy,
  OnChanges,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ConfirmationService, MenuItem, MessageService, SelectItem } from "primeng/api";
import { GiftReceiptTapePreviewComponent } from "src/app/core/previews/receipt/gift-receipt-tape-preview/gift-receipt-tape-preview.component";
import { Tender_Type } from "src/app/core/settings/business-settings/tender-type/tender-type";
import { AppSettingsStorageService } from "../../../../shared/app-settings-storage.service";
import {
  PrintingFactoryService,
  PrintingMgr,
  ReceiptPrintingSize,
} from "../../../../shared/services/printing-service.service";
import { WebHelpers } from "../../../../utility/WebHelpers";
import { DeliveryStatus, FulfillmentStatus, PaymentStatus, SaleDoc } from "../sale-doc/sale-doc";
import { DocState } from "../../doc/doc";
import { StoreSettingEmail } from "src/app/core/settings/store-settings/store-mail-settings/StoreSettingEmail";
import { Subscription } from "rxjs";
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { Router } from "@angular/router";
import { SaleChannelOrder, DeliveryMethod } from "src/app/core/sale-channel/sale-channel-orders/sale-channel-order";
import { AllUpcomingOrdersService } from "./../../../all-upcoming-orders/all-upcoming-orders/all-upcoming-orders.service";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";
import * as _ from "lodash";
import { MonerisService } from "src/app/core/settings/integration-settings/moneris-terminal/moneris.service";
import { CommitmentsService } from "../commitments/commitments.service";
import { Repeat, SaleDocLine } from "../sale-doc-line/sale-doc-line";
import { SaleDocService } from "../sale-doc/sale-doc.service";
import { RecurringOrderSetting } from "src/app/core/settings/zone-settings/recurring-order-settings/recurring-order-settings";
import { TimeHelpers } from "src/app/utility/TimeHelpers";
import { CommitmentCycle, Commitments, CommitmentsState } from "../commitments/commitments";
import { Clipboard } from "@angular/cdk/clipboard";
import { AccountDialogWrapperComponent } from "src/app/core/contact-accounts/account-dialog-wrapper/account-dialog-wrapper.component";
import { FilterRows } from "src/app/utility/FormDataHelpers";
import { ACTION_TYPE, BUTTON_SIZE } from "src/app/shared/components/action-button/action-type.enum";
import { PrintHelpers } from "src/app/utility/PrintHelpers";
import { TakuPayService } from "src/app/core/settings/integration-settings/taku-pay/taku-pay.service";

enum DialogName {
  PRINT_PREVIEW = "printPreview",
}

@Component({
  selector: "taku-commitments-preview",
  templateUrl: "./commitments-preview.component.html",
  styleUrls: ["./commitments-preview.component.scss"],
})
export class CommitmentsPreviewComponent implements OnChanges, OnInit, OnDestroy {
  subsList: Subscription[] = [];
  ReceiptPrintingSize = ReceiptPrintingSize;
  @Input() _object;
  @Output() dialogClosed: EventEmitter<any> = new EventEmitter();
  @Output() changedForm: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild("printPreviewHost", { read: ViewContainerRef, static: true }) printPreviewHost: ViewContainerRef;
  _enabledEditMode = true;
  private emailSettings: StoreSettingEmail;
  enum_saledoc_fulfillment_status: SelectItem[];
  enum_saledoc_delivery_method: SelectItem[];
  enum_saledoc_delivery_status: SelectItem[];
  progressBar: DynamicDialogRef;
  public ACTION_TYPE: typeof ACTION_TYPE = ACTION_TYPE;
  public BUTTON_SIZE: typeof BUTTON_SIZE = BUTTON_SIZE;

  Tender_Type = Tender_Type;
  commitments: Commitments;
  saleDoc: SaleDoc; //TODO: remove
  saleChannelOrder: SaleChannelOrder;
  tendersPrettyPrint: string;
  _activeFullDialog: DialogName;
  _activeFullDialogExtra: any;
  _saleChannelOrder: SaleChannelOrder;
  footerFormGroup: UntypedFormGroup;
  _isStore: boolean;
  printSizesMenuItems: MenuItem[] = [];
  _currencyISOCode = "CAD";
  selectedPayType;
  lookup_recurringOrderSetting;

  printMgr: PrintingMgr;
  _headerTitle = "";
  paymentCycleTitle = "";
  decimalFormat = "1.2-2";

  constructor(
    private fb: UntypedFormBuilder,
    appSettings: AppSettingsStorageService,
    protected _router: Router,
    printingMgrFactory: PrintingFactoryService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    protected confirmationService: ConfirmationService,
    private allUpcomingOrdersService: AllUpcomingOrdersService,
    private appSettingsService: AppSettingsStorageService,
    private messageService: MessageService,
    private alertMessagesService: AlertMessagesService,
    public monerisService: MonerisService,
    private takuPayService: TakuPayService,
    private commitmentsService: CommitmentsService,
    private saleDocService: SaleDocService,
    private clipboard: Clipboard,
    private dialogService: DialogService
  ) {
    this._isStore = appSettings.isStoreLogin();
    this._currencyISOCode = appSettings.getZone().defaultCurrencyIsoCode;
    if (this.config && this.config.data && this.config.data._object) {
      this._object = this.config.data._object;
      this._enabledEditMode = this.config.data._enabledEditMode || this._enabledEditMode;
      this._saleChannelOrder = this.config.data ? this.config.data._saleChannelOrder : null;
      this.commitments = Commitments.convertIntoTypedObject(this._object);
      this.tendersPrettyPrint = [
        ...new Set(
          this.commitments.commitmentCycles[0]?.refSaleDoc?.saleDocTenders?.map((t) =>
            t.refTransaction != null ? t.refTransaction?.CardType : t.tenderType ? t.tenderType.description : null
          )
        ),
      ]
        .filter(Boolean)
        .join(", ");

      // let _filter = {
      //   saledocId: {
      //     value: this.saleDoc.id,
      //     matchMode: 'equals',
      //   }
      // };

      // this.allUpcomingOrdersService.getRows('saleChannelOrder', JSON.stringify(_filter)).subscribe((_saleChannelOrders) => {
      //   if (_saleChannelOrders.count > 0) {
      //     this.saleChannelOrder = _saleChannelOrders.rows[0];
      //   }
      // })

      // this._headerTitle = `<span>DOC# ${this.saleDoc.doc.docNo} -&nbsp;</span><span>Revisit Details</span>`;
      this.printMgr = printingMgrFactory.build(this.commitments.storeId);
    } else {
      this.printMgr = printingMgrFactory.build(appSettings.getStoreId());
    }
    this.footerFormGroup = this.fb.group({
      // emailAddress: this.fb.control(null, Validators.email),
      fulfillmentStatus: this.fb.control(this.commitments ? this.commitments.refSaleDoc.fulfillmentStatus : null),
      deliveryMethod: this.fb.control(this.commitments ? this.commitments.deliveryMethod : null),
      deliveryStatus: this.fb.control(this.commitments ? this.commitments.refSaleDoc.deliveryStatus : null),
      shipperChargeAmount: this.fb.control(this.commitments ? this.commitments.shipperChargeAmount : null),
      recurringOrderSettingId: this.fb.control(this.commitments ? this.commitments.recurringOrderSettingId : null),
    });
  }

  identify(index: number, el: string) {
    return el;
  }

  ngOnInit(): void {
    const filter: FilterRows<RecurringOrderSetting> = {
      zoneId: { matchMode: "equals", value: this.commitments.store.zoneId },
    };
    this.subsList.push(
      this.saleDocService
        .lookupRecurringOrderSetting({ lookupFilter: JSON.stringify(filter) })
        .subscribe((settings) => {
          this.lookup_recurringOrderSetting = settings;
        })
    );

    this.printSizesMenuItems = [
      {
        label: ReceiptPrintingSize.TAPE_SIZE,
        command: () => {
          this.onReprintDoc(ReceiptPrintingSize.TAPE_SIZE);
        },
        icon: "takuicon-measuring-tape",
      },
      {
        label: ReceiptPrintingSize.LETTER_SIZE,
        command: () => {
          this.onReprintDoc(ReceiptPrintingSize.LETTER_SIZE);
        },
        icon: "takuicon-invoice",
      },
    ];

    this.enum_saledoc_fulfillment_status = this.allUpcomingOrdersService
      .enumSelectOptions(FulfillmentStatus)
      .map((opt) => {
        if (opt.value === FulfillmentStatus.voided) {
          opt.disabled = true;
          opt.icon = "pi pi-lock";
        }
        return opt;
      });
    this.enum_saledoc_delivery_method = this.allUpcomingOrdersService.enumSelectOptions(DeliveryMethod);
    //this.enum_saledoc_delivery_status = this.allUpcomingOrdersService.enumSelectOptions(DeliveryStatus);
    this.enum_saledoc_delivery_status = this.getDeliveryStatusBasedOnFulfillmentStatus();

    this.subsList.push(
      this.appSettingsService.getStoreSettings("storeSettingEmail").subscribe({
        next: (settings) => {
          this.emailSettings = settings;
        },
        error: (error) => {
          this.emailSettings = null;
        },
      })
    );

    // if(!this.saleChannelOrder) {
    //   let _filter = {
    //     saledocId: {
    //       value: this.saleDoc.id,
    //       matchMode: 'equals',
    //     }
    //   };

    //   this.allUpcomingOrdersService.getRows('saleChannelOrder', JSON.stringify(_filter)).subscribe((_saleChannelOrders) => {
    //     if (_saleChannelOrders.count > 0) {
    //       this.saleChannelOrder = _saleChannelOrders.rows[0];
    //     }
    //   });
    // }
    this.ngOnChanges();
  }

  getPayType(): SelectItem[] {
    return this.commitments.commitmentCycles
      .filter((cycle) => cycle.refSaleDoc?.saleDocTenders[0]?.refTransaction)
      .map((cycle) => {
        const refTransaction = cycle.refSaleDoc?.saleDocTenders[0]?.refTransaction;
        return {
          label: `${refTransaction.CardType} (...${refTransaction.Last4})(${refTransaction.ExpDate})`,
          value: cycle.refSaleDoc?.saleDocTenders,
        };
      });
  }

  getRepeatCycle(): string {
    const count = this.commitments.commitmentCycles.filter((cycle) => cycle.refSaleDocId).length;
    let result;
    if (this.commitments.repeatCycle === Repeat.YES_ONGOING) {
      result = `${count} / -`;
    } else {
      result = `${count} / ${this.commitments.repeatCycle?.split(" ")[0]}`;
    }
    return result;
  }

  getIcon() {
    if (
      this.commitments.state == CommitmentsState.Active ||
      this.commitments.state == CommitmentsState.Completed ||
      this.commitments.state == CommitmentsState.Voided
    )
      return "pi pi-pause";
    else return "pi pi-play";
  }

  onVoidBtnClicked() {
    this.confirmationService.confirm({
      header: "Confirmation",
      message: `Status of commitment has changed to Voided`,
      acceptLabel: "Yes",
      rejectVisible: true,
      acceptVisible: true,
      rejectButtonStyleClass: "p-button-link",
      accept: () => {
        this.commitmentsService
          .patchRow("commitment", { id: this.commitments.id, state: CommitmentsState.Voided })
          .subscribe({
            next: (_response) => {
              this.commitments.state = _response.state;
              if (this.config && this.config.data && this.config.data._orgSaleDoc) {
                this.config.data._orgSaleDoc.state = this.commitments.state as unknown as CommitmentsState;
              }
            },
            error: (_errorResponse) => {
              this.messageService.add(this.alertMessagesService.getErrorMessage(_errorResponse));
            },
          });
      },
      reject: () => {},
    });
  }

  onPauseBtnClicked() {
    this.confirmationService.confirm({
      header: "Confirmation",
      message: `Status of commitment has changed to ${
        this.commitments.state === CommitmentsState.Paused ? CommitmentsState.Active : CommitmentsState.Paused
      }`,
      acceptLabel: "Yes",
      rejectVisible: true,
      acceptVisible: true,
      rejectButtonStyleClass: "p-button-link",
      accept: () => {
        const status = this.commitments.state;
        this.subsList.push(
          this.commitmentsService
            .patchRow("commitment", {
              id: this.commitments.id,
              state: status === CommitmentsState.Paused ? CommitmentsState.Active : CommitmentsState.Paused,
            })
            .subscribe({
              next: (_response) => {
                this.commitments.state = _response.state;
                if (this.config && this.config.data && this.config.data._orgSaleDoc) {
                  this.config.data._orgSaleDoc.state = this.commitments.state as unknown as CommitmentsState;
                }
              },
              error: (_errorResponse) => {
                this.messageService.add(this.alertMessagesService.getErrorMessage(_errorResponse));
              },
            })
        );

        if (status === CommitmentsState.Paused) {
          const commitmentCycle = new CommitmentCycle();
          commitmentCycle.commitmentId = this.commitments.id;
          commitmentCycle.cycleDate = this.commitments.creationDate;
          commitmentCycle.refSaleDocId = null;
          this.subsList.push(this.commitmentsService.addRow("commitmentCycle", commitmentCycle).subscribe());
        }
      },
      reject: () => {},
    });
  }

  onSkipBtnClicked() {
    this.confirmationService.confirm({
      header: "Confirmation",
      message: `Skip the next charge. Note that this action cannot be reversed.
        Do you still want to proceed?`,
      acceptLabel: "Yes",
      rejectVisible: true,
      acceptVisible: true,
      rejectButtonStyleClass: "p-button-link",
      accept: () => {
        const commitmentCycle = new CommitmentCycle();
        commitmentCycle.refSaleDocId = null;
        commitmentCycle.commitmentId = this.commitments.id;
        commitmentCycle.cycleDate = this.commitments.nextPickupDate;

        this.commitmentsService.addRow("commitmentCycle", commitmentCycle).subscribe({
          next: (_response) => {},
          error: (_errorResponse) => {
            this.messageService.add(this.alertMessagesService.getErrorMessage(_errorResponse));
          },
          complete: () => {
            this.commitmentsService.getRow("commitment", this.commitments.id).subscribe((result) => {
              this.commitments.nextPickupDate = result.nextPickupDate;
              this.commitments.nextChargeDate = result.nextChargeDate;
              if (this.config && this.config.data && this.config.data._orgSaleDoc) {
                this.config.data._orgSaleDoc.nextPickupDate = this.commitments.nextPickupDate;
                this.config.data._orgSaleDoc.nextChargeDate = this.commitments.nextChargeDate;
              }
            });
          },
        });
      },
      reject: () => {},
    });
  }

  isVoidedButtonDisabled() {
    return this.commitments.state === CommitmentsState.Voided || this.commitments.state === CommitmentsState.Completed;
  }

  isPauseButtonDisabled() {
    return this.commitments.state === CommitmentsState.Voided || this.commitments.state === CommitmentsState.Completed;
  }

  isSkipButtonDisabled() {
    return (
      this.commitments.state === CommitmentsState.Voided ||
      this.commitments.state === CommitmentsState.Completed ||
      this.commitments.state === CommitmentsState.Declined
    );
  }

  getPaymentCycle(i) {
    return this.commitmentsService.getPaymentCycle(this.commitments, i);
  }

  ngOnChanges() {
    this._headerTitle = `<span>COMMITMENT# ${this.commitments.docNo} -&nbsp;</span><span style="color: orange;">${this.commitments.state}</span>`;
    this.paymentCycleTitle = `<span>COMMITMENT# ${this.commitments.docNo}</span>`;
    if (
      this.commitments.state === CommitmentsState.Active &&
      this.commitments.refSaleDoc.paymentStatus === PaymentStatus.paid
    ) {
      this._headerTitle += `&nbsp;<i class="pl-2 pi pi-check-circle" style="color: green; font-size: 1.2em;"></i>`;
    } else {
      this._headerTitle += `&nbsp;<i class="pl-2 pi pi-check-circle" style="color: grey; font-size: 1.2em;"></i>`;
    }

    this.footerFormGroup.setValue({
      fulfillmentStatus: this.commitments.refSaleDoc.fulfillmentStatus,
      deliveryMethod: this.commitments.deliveryMethod,
      deliveryStatus: this.commitments.refSaleDoc.deliveryStatus,
      shipperChargeAmount: this.commitments.shipperChargeAmount,
      recurringOrderSettingId: this.commitments.recurringOrderSettingId,
    });
  }

  get sendEmailEnabled() {
    return this.emailSettings && this.emailSettings.id;
  }

  get _isMobile() {
    return WebHelpers.isMobileScreen();
  }

  get isVoided() {
    return this.commitments.refSaleDoc.doc.state === DocState.voided;
  }

  onClosePressed() {
    this.dialogClosed.emit(this.saleDoc);
    if (this._saleChannelOrder) {
      this.allUpcomingOrdersService.ref.close(this._saleChannelOrder);
    } else {
      if (this.ref) {
        this.ref.close();
      }
    }
  }

  getCommitmentsStatus() {
    switch (this.commitments.state) {
      case CommitmentsState.Active:
        return "info";
      case CommitmentsState.Voided:
        return "primary";
      case CommitmentsState.Paused:
        return "warning";
      case CommitmentsState.Declined:
        return "danger";
      case CommitmentsState.Completed:
        return "success";
    }
  }

  closeFullSizeDialog() {
    this._activeFullDialog = null;
  }

  onPrintPackingList(printSize: ReceiptPrintingSize) {
    const _tempSaleDoc = Object.assign({}, this.saleDoc);
    _tempSaleDoc["isPackingList"] = true;
    this.printMgr.printDocumentsWithSize([_tempSaleDoc], this.printPreviewHost, printSize);
  }

  onPrintPrep() {
    this.printMgr.printStarCloudPRNTPrepInBatch([this.saleDoc]);
  }

  onReprintDoc(printSize: ReceiptPrintingSize) {
    if (this.commitments) {
      this.subsList.push(
        this.printMgr.isReady.subscribe({
          next: () => {
            this.commitmentsService
              .getRow("saleDoc", this.commitments?.["refSaleDocId"])
              .subscribe((_saleDoc: SaleDoc) => {
                const _tempSaleDoc: SaleDoc = Object.assign({}, _saleDoc);
                _tempSaleDoc.saleDocLines.map((_saleDocLine) => {
                  _saleDocLine.unitPrice = 0;
                  _saleDocLine.unitCost = 0;
                  _saleDocLine.salePrice = 0;
                });
                _tempSaleDoc.subTotal = 0;
                _tempSaleDoc.shipperChargeAmount = 0;
                _tempSaleDoc.discountAmount = 0;
                _tempSaleDoc.saleDocLines.map((_saleDocLine) => {
                  _saleDocLine.saleDocLineTaxes = [];
                });
                _tempSaleDoc.saleDocTenders = [];
                _tempSaleDoc.cashRounding = 0;
                _tempSaleDoc.saleDocTaxes = [];
                _tempSaleDoc.totalLineTax = 0;
                _tempSaleDoc["isPackingList"] = true;

                // Change old sale doc information by commiments information
                const dateParts = TimeHelpers.SplitDateAndTime(this.commitments?.["createdAt"], false, true);
                _tempSaleDoc.doc.docDate = dateParts[0];
                _tempSaleDoc.doc.docTime = dateParts[1];
                const saleDocLines: SaleDocLine[] = [];
                _tempSaleDoc.saleDocLines.forEach((saleDocLine) => {
                  this.commitments?.["commitmentLines"].forEach((commitment) => {
                    if (commitment?.inventoryId === saleDocLine?.docLine?.inventoryId) {
                      saleDocLine.qty = commitment.qty;
                      saleDocLine.unitPrice = commitment.unitPrice;
                      saleDocLine.discountAmount = commitment.discountAmount;
                      saleDocLines.push(saleDocLine);
                    }
                  });
                });
                _tempSaleDoc.saleDocLines = saleDocLines;
                this.printMgr.printDocumentsWithSize([_tempSaleDoc], this.printPreviewHost, printSize);
              });
          },
          error: (error) => {
            alert("Error: Can't print because Receipt Builder Settings are not available");
          },
        })
      );
    }
  }

  onReceiptCardPrintPressed() {
    for (let index = 0; index < this.saleDoc.saleDocTenders.length; index++) {
      const _saleDocTender = this.saleDoc.saleDocTenders[index];
      if (
        _saleDocTender.refTransaction &&
        (_saleDocTender.refTransaction["customerReceiptTemplate"] ||
          _saleDocTender.refTransaction["merchantReceiptTemplate"])
      ) {
        if (PrintHelpers.isTakuPayTransaction(this.saleDoc)) {
          this.takuPayService.printPaymentReceipt(this.printMgr, _saleDocTender, this.saleDoc, this.printPreviewHost);
        } else {
          this.monerisService.printPaymentReceipt(this.printMgr, _saleDocTender, this.saleDoc, this.printPreviewHost);
        }
      }
    }
  }

  onReprintGiftReceipt() {
    this.printMgr.printSaleDocsInBatch([this.saleDoc], this.printPreviewHost, GiftReceiptTapePreviewComponent);
  }

  onPaymentCycleChanged() {
    this.commitmentsService
      .patchRow("commitment", {
        id: this.commitments.id,
        recurringOrderSettingId: this.footerFormGroup.get("recurringOrderSettingId").value,
        recurringOrderSetting: null,
      })
      .subscribe({
        next: (result) => {
          this.commitments.recurringOrderSetting.recurringOrderFrequency =
            result.recurringOrderSetting.recurringOrderFrequency;
          this.commitments.recurringOrderSettingId = this.footerFormGroup.get("recurringOrderSettingId").value;

          if (this.config && this.config.data && this.config.data._orgSaleDoc) {
            this.config.data._orgSaleDoc.recurringOrderSetting = this.commitments
              .recurringOrderSetting as unknown as RecurringOrderSetting;
          }

          this.messageService.add(this.alertMessagesService.getMessage("save-success"));
        },
        error: (error) => {
          this.footerFormGroup.reset(this.commitments);
          this.messageService.add(this.alertMessagesService.getErrorMessage(error));
        },
      });
  }

  onDeliveryMethodChanged() {
    this.commitmentsService
      .patchRow("commitment", {
        id: this.commitments.id,
        deliveryMethod: this.footerFormGroup.get("deliveryMethod").value,
      })
      .subscribe({
        next: () => {
          this.commitments.deliveryMethod = this.footerFormGroup.get("deliveryMethod").value;
          if (this._saleChannelOrder) {
            this._saleChannelOrder.deliveryMethod = this.commitments.deliveryMethod as unknown as DeliveryMethod;
          }
          if (this.config && this.config.data && this.config.data._orgSaleDoc) {
            this.config.data._orgSaleDoc.deliveryMethod = this.commitments.deliveryMethod as unknown as DeliveryMethod;
          }
          this.messageService.add(this.alertMessagesService.getMessage("save-success"));
        },
        error: (error) => {
          this.footerFormGroup.reset(this.commitments);
          this.messageService.add(this.alertMessagesService.getErrorMessage(error));
        },
      });
  }

  onShippingFeesChanged() {
    this.commitmentsService
      .patchRow("commitment", {
        id: this.commitments.id,
        shipperChargeAmount: this.footerFormGroup.get("shipperChargeAmount").value,
      })
      .subscribe({
        next: () => {
          this.commitments.shipperChargeAmount = this.footerFormGroup.get("shipperChargeAmount").value;
          // if (this._saleChannelOrder) {
          //   this._saleChannelOrder.deliveryMethod = (this.commitments.deliveryMethod as unknown) as DeliveryMethod;
          // }
          if (this.config && this.config.data && this.config.data._orgSaleDoc) {
            this.config.data._orgSaleDoc.shipperChargeAmount = this.commitments
              .shipperChargeAmount as unknown as DeliveryMethod;
          }
          this.messageService.add(this.alertMessagesService.getMessage("save-success"));
        },
        error: (error) => {
          this.footerFormGroup.reset(this.commitments);
          this.messageService.add(this.alertMessagesService.getErrorMessage(error));
        },
      });
  }

  openFullSizeDialog(dialog: DialogName, extra = null) {
    this._activeFullDialog = dialog;
    this._activeFullDialogExtra = extra;
  }

  get noCashTenders() {
    return this.saleDoc.saleDocTenders.filter((tender) => tender.tenderType.type !== Tender_Type.Cash);
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }

  copyEmailToClipboard(email: string): void {
    this.clipboard.copy(email);
  }

  /**
   * Get delivery status based on fulfillment status
   * @returns this.enum_saledoc_delivery_status : SelectItem[]
   */
  getDeliveryStatusBasedOnFulfillmentStatus(): SelectItem[] {
    if (this.commitments.refSaleDoc.fulfillmentStatus == FulfillmentStatus.completed) {
      return this.allUpcomingOrdersService.enumSelectOptions(DeliveryStatus).map((item) => {
        if ([DeliveryStatus.returned, DeliveryStatus.voided].includes(item.value)) {
          item.disabled = true;
          item.icon = "pi pi-lock";
        }
        return item;
      });
    } else {
      return this.allUpcomingOrdersService.enumSelectOptions(DeliveryStatus);
    }
  }

  openCustomerDetails() {
    this.config.data.account = this.commitments.commercialAccount || this.commitments.personalAccount;
    this.dialogService.open(AccountDialogWrapperComponent, this.config);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
