/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

export namespace ContentAPI_ProductStatuses {
  export class ProductStatus {
    productId: string;
    title: string;
    link: string;
    destinationStatuses: DestinationStatus[];
    itemLevelIssues: ItemLevelIssue[];
    creationDate: string;
    lastUpdateDate: string;
    googleExpirationDate: string;
  }

  export class DestinationStatus {
    destination: string;
    status: DestinationStatus_Status;
  }

  export enum DestinationStatus_Status {
    APPROVED = "approved",
    DISAPPROVED = "disapproved",
    PENDING = "pending",
    NOT_AVAILABLE = "not available",
    DISABLED = "disabled",
    EXPIRING = "expiring",
    ACTIVE = "active",
  }

  export class ItemLevelIssue {
    code: string;
    servability: string;
    resolution: string;
    attributeName: string;
    destination: string;
    description: string;
    detail: string;
    documentation: string;
  }

  export class ProductstatusesList {
    kind: string;
    nextPageToken: string;
    resources: ProductStatus[];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
