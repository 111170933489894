import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { SignupComponent } from "./signup.component";
import { SignUpService } from "./signup.service";
import { TakuInputModule } from "src/app/taku-ui/taku-input/taku-input.module";
import { TakuCheckboxModule } from "src/app/taku-ui/taku-checkbox/taku-checkbox.module";
import { SignupRoutingModule } from "./signup-routing.module";
import { ButtonModule } from "primeng/button";

@NgModule({
  declarations: [SignupComponent],
  providers: [SignUpService],
  imports: [CommonModule, SignupRoutingModule, TakuInputModule, TakuCheckboxModule, ReactiveFormsModule, ButtonModule],
})
export class SignupModule {}
