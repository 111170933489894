<div
  class="landing-page-top-wrapper"
  [ngStyle]="{
    'background-image': this.customBgImageUrl ? 'url(' + this.customBgImageUrl + ')' : 'none',
    'background-size': 'cover'
  }"
>
  <div class="topHeader">
    <div class="header-texts-container">
      <p-skeleton *ngIf="!displayContents" size="6em" width="10em"></p-skeleton>
      <p-skeleton *ngIf="!displayContents" size="4em" styleClass="mt-2" width="8em"></p-skeleton>
      <span *ngIf="displayContents" class="store-name">{{ storeName }}</span>
      <span *ngIf="displayContents" class="self-checkout-label">Self-Checkout</span>
    </div>
    <div #logoContainer class="logo-container">
      <p-skeleton *ngIf="!displayContents" width="9em" size="12em"></p-skeleton>
      <img *ngIf="displayContents" [attr.src]="storeLogoUrl || businessLogoUrl" />
    </div>
  </div>
  <div class="start-btn-topWrapper">
    <p-skeleton *ngIf="!displayContents" shape="circle" size="32em"></p-skeleton>
    <div
      *ngIf="displayContents"
      class="tap-to-start-btn"
      (click)="onTapBtnClicked()"
      [ngStyle]="{ color: startBtnCustomFontColor, 'background-color': startBtnCustomBgColor }"
    >
      <!-- <img src="../../../../../assets/layout/images/kiosk_tap_button.png"> -->
      <span class="material-icons-outlined"> touch_app </span>
      <span>Tap to Start</span>
    </div>
  </div>
  <taku-self-checkout-footer-labels></taku-self-checkout-footer-labels>
</div>
