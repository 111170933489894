<taku-form-header
  toolbarTitle="Item Buyback"
  displayMode="inline"
  [showActionBtns]="false"
  [showBackBtn]="false"
></taku-form-header>

<div class="col-12">
  <div class="col-12 inventory-search-wrapper">
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon"><i class="material-icons">local_offer</i></span>
      <taku-search-inventory
        id="inventory_search"
        #inventorySearchBox
        placeholder="Scan or Search for Items"
        [includeCreateNewBtns]="false"
        (itemSelected)="itemSelected($event)"
        (onNewItemClicked)="onNewItem($event)"
      ></taku-search-inventory>
    </div>
  </div>
  <div class="col-12 invoice-note">
    NOTE: Any items added to the salesscreen are not associated to invoices or accounts.
  </div>
</div>
