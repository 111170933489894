<div
  class="grid-card card"
  [ngStyle]="{ width: width + 'px', height: height + 'px', 'background-color': categoryBgColor }"
>
  <div class="category-name" [ngStyle]="{ color: categoryFontColor }">
    <span>{{ category.categoryName }}</span>
  </div>
  <div class="item-top" [ngClass]="{ 'short-img-height': _isMobile }">
    <div class="card-img">
      <img [attr.src]="category.urlImageFileName150 || '../../../../assets/layout/images/no-image.jfif'" />
    </div>
    <ng-content select="[detailTooltip]"></ng-content>
  </div>
  <div class="item-bottom" [ngStyle]="{ color: categoryFontColor }">
    <div class="category-bottom">
      <span>{{ category?.inventoryGalleryCount }} item(s)</span>
    </div>
  </div>
</div>
