/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { MessageService } from "primeng/api";
import { Observable, of } from "rxjs";
import { map, take } from "rxjs/operators";
import { AlertMessagesService } from "../shared/services/alert-messages.service";
import { DBService } from "../shared/services/db.service";
import { FormListColumnsService } from "../form-list/form-list-columns.service";

@Injectable()
export class ObjectDetailResolverService implements Resolve<any> {
  constructor(
    private db: DBService,
    private formListColumnsService: FormListColumnsService,
    private router: Router,
    private messageService: MessageService,
    private alertService: AlertMessagesService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const id = route.paramMap.get("id") == null ? null : +route.paramMap.get("id");
    const _model = route.queryParams.model || route.url.toString().split(",")[route.url.length - 2];
    const dbService = this.formListColumnsService.getDbService(_model) || this.db;

    if (id !== 0 && id !== null) {
      return dbService.getRow(_model, id).pipe(
        take(1),
        map((object) => {
          if (object) {
            return object;
          } else {
            // id not found
            this.messageService.add(this.alertService.getErrorMessage(null, "Error", "Try again"));
            this.router.navigate(["/dashboard"]);
            return null;
          }
        })
      );
    } else {
      return null;
    }
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
