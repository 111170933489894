/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { SearchResultItem } from "../../../taku-ui/taku-search-accounts/SearchResultItem";
import { Inventory } from "../../inventory/inventory/inventory";

@Component({
  selector: "taku-accounts-search-res",
  templateUrl: "./accounts-search-res.component.html",
  styleUrls: ["./accounts-search-res.component.scss"],
})
export class AccountsSearchResComponent implements OnInit {
  @Input() results: SearchResultItem[];
  @Output() itemSelected = new EventEmitter<SearchResultItem>();

  constructor() {}

  ngOnInit() {}

  onSelectItem(item: SearchResultItem) {
    this.itemSelected.emit(item);
  }

  loadData(event: any) {}
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
