/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Type } from "@angular/core";
import { WebHelpers } from "src/app/utility/WebHelpers";

export class Report {
  reportParamsComponent: Type<any>;
  reportFormListComponent: Type<any>;

  constructor(reportParamsComponent: Type<any>, reportFormListComponent: Type<any>) {
    this.reportParamsComponent = reportParamsComponent;
    this.reportFormListComponent = reportFormListComponent;
  }
}

export const resetReportRowState = (stateKey: string): void => {
  const formState = JSON.parse(localStorage.getItem(stateKey));
  const hasKey = formState?.rows !== undefined;
  if (formState && !hasKey) {
    formState.rows = WebHelpers.isMobileScreen() ? 5 : 10;
    delete formState.selection;
    localStorage.setItem(stateKey, JSON.stringify(formState));
  }
};

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
