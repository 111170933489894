/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Input } from "@angular/core";
import { trigger, state, transition, style, animate } from "@angular/animations";
import { AppComponent } from "./app.component";
import { UserProfileService } from "./shared/services/user-profile.service";
import { AuthService } from "./shared/services/auth.service";

@Component({
  selector: "app-inline-menu",
  templateUrl: "./app.inlinemenu.component.html",
  animations: [
    trigger("menu", [
      state(
        "hiddenAnimated",
        style({
          height: "0px",
          paddingBottom: "0px",
          overflow: "hidden",
        })
      ),
      state(
        "visibleAnimated",
        style({
          height: "*",
          overflow: "visible",
        })
      ),
      state(
        "visible",
        style({
          opacity: 1,
          "z-index": 100,
        })
      ),
      state(
        "hidden",
        style({
          opacity: 0,
          "z-index": "*",
        })
      ),
      transition("visibleAnimated => hiddenAnimated", animate("400ms cubic-bezier(0.86, 0, 0.07, 1)")),
      transition("hiddenAnimated => visibleAnimated", animate("400ms cubic-bezier(0.86, 0, 0.07, 1)")),
      transition("visible => hidden", animate(".1s linear")),
      transition("hidden => visible", [
        style({ transform: "scaleY(0.8)" }),
        animate(".12s cubic-bezier(0, 0, 0.2, 1)"),
      ]),
    ]),
  ],
})
export class AppInlineProfileComponent {
  @Input() key = "inline-menu";

  @Input() style: any;

  @Input() styleClass: string;

  active: boolean;

  constructor(public app: AppComponent, private auth: AuthService, public userService: UserProfileService) {}

  onLogout() {
    this.auth.logout();
  }

  onClick(event) {
    this.app.onInlineMenuClick(event, this.key);
    this.active = !this.active;
    event.preventDefault();
  }

  get isTooltipDisabled() {
    return !(this.app.isSlim() && !this.app.isMobile());
  }

  get tabIndex() {
    return !this.app.inlineMenuActive ? "-1" : null;
  }

  isHorizontalActive() {
    return this.app.isHorizontal() && !this.app.isMobile();
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
