/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Location } from "@angular/common";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { AuthService } from "../../../shared/services/auth.service";
import { MessageService } from "primeng/api";
import { AlertMessagesService } from "../../../shared/services/alert-messages.service";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { APP_BRAND } from "src/app/utility/white-label";

@Component({
  selector: "taku-forgot-login",
  templateUrl: "./forgot-login.component.html",
  host: {
    class: "grid page-container",
  },
  styleUrls: ["./forgot-login.component.scss"],
})
export class ForgotLoginComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];
  formGroup: UntypedFormGroup;
  msgs: any[];

  branding = APP_BRAND;

  _sendingEmail = false;

  constructor(
    private fb: UntypedFormBuilder,
    private location: Location,
    private auth: AuthService,
    private messageService: MessageService,
    protected alertMessage: AlertMessagesService,
    private _route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.formGroup = this.fb.group({
      accountUsername: this.fb.control(""),
    });
  }

  onBack($event) {
    this.location.back();
  }

  sendResetPasswordEmail($event) {
    this._sendingEmail = true;
    this.subsList.push(
      this._route.params.subscribe((params) => {
        if (params["accountName"]) {
          const _accountName = params["accountName"];
          this.msgs = [];
          this.subsList.push(
            this.auth.forgotPassowrd(_accountName, this.formGroup.get("accountUsername").value).subscribe(
              (data) => {
                // this.msgs.push(this.alertMessage.getMessage('email-sent-success'));
                this.messageService.add(this.alertMessage.getMessage("email-sent-success"));
                this._sendingEmail = false;
              },
              (error) => {
                // this.msgs.push(this.alertMessage.getErrorMessage(error, '', ''));
                this.messageService.add(this.alertMessage.getErrorMessage(error, "", ""));
                this._sendingEmail = false;
              }
            )
          );
        }
      })
    );
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
