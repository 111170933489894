/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { GenericFormComponent, GenericFormService } from "../../../forms/generic-form/generic-form.component";
import { Input, Component, Optional } from "@angular/core";
import { Subscription, Observable, of } from "rxjs";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { UntypedFormBuilder } from "@angular/forms";
import { DBService } from "../../../shared/services/db.service";
import { MessageService, ConfirmationService } from "primeng/api";
import { AlertMessagesService } from "../../../shared/services/alert-messages.service";
import { AppSettingsStorageService } from "../../../shared/app-settings-storage.service";
import { Store } from "./store/store";
import { map, mergeAll } from "rxjs/operators";
import { Location } from "@angular/common";
import * as _ from "lodash";
import { PrintingFactoryService } from "src/app/shared/services/printing-service.service";
import { BaseEntity } from "src/app/utility/types";

type StoreSettingsGeneric = BaseEntity & {
  storeId: number;
  store: Store;
};

@Component({
  template: "",
  styles: [],
})
export abstract class StoreSettingsGenericComponent<
  T extends StoreSettingsGeneric = any
> extends GenericFormComponent<T> {
  @Input() storeId;
  readonly _pageTitlePrefix;
  pageTitle;
  storeId$: Observable<number>;

  constructor(
    _router: Router,
    fb: UntypedFormBuilder,
    dbService: DBService,
    location: Location,
    protected printingFactoryService: PrintingFactoryService,
    _route: ActivatedRoute,
    messageService: MessageService,
    alertMessage: AlertMessagesService,
    confirmationService: ConfirmationService,
    appSettings: AppSettingsStorageService,
    @Optional() formService?: GenericFormService
  ) {
    super(
      _router,
      fb,
      dbService,
      location,
      _route,
      messageService,
      alertMessage,
      confirmationService,
      appSettings,
      formService
    );
  }

  ngOnInit() {
    this.pageTitle = this._pageTitlePrefix;
    if (this.storeId == null) this.storeId$ = this._route.params.pipe(map((params: Params) => +params["storeId"]));
    else this.storeId$ = of(this.storeId);

    super.ngOnInit();

    this.subsList.push(
      this.changedForm.subscribe((settings) => {
        if (this.storeId$) {
          this.subsList.push(
            this.storeId$.subscribe((storeId) => {
              if (storeId === this.appSettingsService.getStoreId()) {
                this.appSettingsService.patchStoreSettings(this._model, settings);
              }
            })
          );
        }
      })
    );
  }

  ngOnChanges(changes) {
    if (changes["storeId"]) this.storeId$ = of(this.storeId);

    super.ngOnChanges(changes);
  }

  _updatePageTitle(store: Store) {
    this.pageTitle = `${this._pageTitlePrefix}&nbsp;<span class="secondary-text">(${store.storeName})</span>`;
  }

  _fetchAndStoreFormObject$(storeId): Observable<any> {
    const filter = {};
    filter["storeId"] = {};
    filter["storeId"]["value"] = storeId;
    filter["storeId"]["matchMode"] = "equals";
    return this.dbService.getRows(this._model, JSON.stringify(filter)).pipe(
      map((myObjects: any) => {
        if (myObjects.rows.length === 0) {
          this._id = 0;
          super.loadObject();
          this._object.storeId = +storeId;
          this._myForm.patchValue(this._object);
          return this.dbService.getRow("store", storeId).pipe(
            map((store) => {
              this._object.store = store;
              return this._object;
            })
          );
        } else {
          this._object = myObjects.rows[0];
          this._id = myObjects.rows[0]["id"];
          super.loadObject();
          return of(this._object);
        }
      }),
      mergeAll()
    );
  }

  loadObject() {
    this.subsList.push(
      this.storeId$.subscribe((storeId) => {
        this.subsList.push(
          this._fetchAndStoreFormObject$(storeId).subscribe((storeSettings) => {
            this._updatePageTitle(storeSettings.store);
          })
        );
      })
    );
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
