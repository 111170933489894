<taku-form-header
  [shortenHeight]="shortenFormHeaderHeight"
  toolbarTitle="CRM"
  displayMode="inline"
  [showActionBtns]="false"
  [showBackBtn]="false"
></taku-form-header>

<p-tabView
  *ngIf="accountInfo"
  [formGroup]="_myForm"
  class="grid grid-nogutter col-12 tabview-nopad"
  [(activeIndex)]="_mainTabActiveIndex"
>
  <p-tabPanel *ngIf="isCrmProfileTabVisible" header="PROFILE" leftIcon="ui-icon-person">
    <div class="grid col-12">
      <div #profileContainer class="col-6 md:col-4 profile-photo-wrapper">
        <div class="col-12 profile-photo">
          <div *ngIf="!$any(accountInfo).person?.urlImageFileName150">
            <span class="account-initial" *ngFor="let initial of accountInfo.accountInitials">{{ initial }}</span>
          </div>
          <div *ngIf="$any(accountInfo).person?.urlImageFileName150" class="picture-container">
            <img [attr.src]="$any(accountInfo).person?.urlImageFileName150" />
          </div>
        </div>

        <div class="col-12 p-fluid" *ngIf="isCrmAccountDetailsVisible">
          <button
            [disabled]="!isCrmAccountDetailsEnabled"
            [label]="profileContainer.offsetWidth < 105 ? 'Acct Details' : 'Account Details'"
            pButton
            class=""
            (click)="onDetailsBtnClicked($event)"
            [ngClass]="{ 'label-smaller-font': profileContainer.offsetWidth < 105 }"
          >
            <i *ngIf="profileContainer.offsetWidth >= 130" class="material-icons">person</i>
          </button>
          <button
            *ngIf="enableConsent"
            pButton
            [label]="profileContainer.offsetWidth < 105 ? 'Consent' : 'Customer Consent'"
            class="taku-button-small mt-2"
            [ngClass]="{
              'p-button-success': isConsentApproved,
              'p-button-secondary': !isConsentApproved,
              'label-smaller-font': profileContainer.offsetWidth < 105
            }"
            (click)="onCustomerConsentBtnClicked($event)"
          >
            <span *ngIf="profileContainer.offsetWidth >= 130" class="material-icons md-18"> verified_user </span>
          </button>
        </div>
      </div>

      <dl class="col-6 md:col-8 account-details">
        <dt class="col-12">Name:</dt>
        <dd class="col-12">{{ accountInfo.accountName }}</dd>

        <ng-container *ngIf="$any(accountInfo).person?.birthDate">
          <dt class="col-12">Birthdate:</dt>
          <dd class="col-12">{{ $any(accountInfo).person.birthDate }}</dd>
        </ng-container>

        <dt class="col-12">Email:</dt>
        <dd class="col-12">{{ accountPrimaryEmail }}</dd>

        <dt class="col-12">Phone:</dt>
        <dd class="col-12">{{ accountPrimaryPhone }}</dd>

        <ng-container *ngIf="accountInfo.account.note">
          <dt class="col-12">Notes:</dt>
          <dd class="col-12" [innerHTML]="accountInfo.account.note | replaceLineBreaks"></dd>
        </ng-container>

        <ng-container *ngIf="!!accountCreditSummary.length">
          <dt class="col-12">Credit Balance:</dt>
          <dd class="col-12">
            <div *ngFor="let creditSummary of accountCreditSummary; trackBy: trackByCreditSummary">{{ creditSummary.currencyIsoCode }} {{ creditSummary.accountCredit | currency : creditSummary.currencyIsoCode : "symbol-narrow" }}</div>
          </dd>
        </ng-container>
      </dl>
    </div>
    <!-- <div class="col-12">
      {{accountInfo|json}}
    </div> -->
  </p-tabPanel>

  <p-tabPanel *ngIf="isCrmHistoryTabVisible" header="HISTORY" leftIcon="ui-icon-trending-up" class="history-tab">
    <taku-crm-history #historyTab [accountInfo]="accountInfo?.account"> </taku-crm-history>
  </p-tabPanel>
</p-tabView>

<div *ngIf="!accountInfo" class="noAccountMsg-wrapper col-12">
  <em>There is no account selected.</em>
</div>
