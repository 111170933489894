/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable, Inject, LOCALE_ID } from "@angular/core";
// extracted only en-US part of currency-list. Total currency-list is approx. 10M
import currencyList from "../json/currency-list.json";

import { SelectItem } from "primeng/api";

const currencies = Object.values(currencyList);

@Injectable()
export class CurrencyFormatterService {
  _currencyCodeList: SelectItem[] = [];

  constructor(@Inject(LOCALE_ID) private localeId: string) {
    this._currencyCodeList.push({
      label: "",
      value: null,
    });

    currencies.forEach((currency) => {
      this._currencyCodeList.push({
        label: currency.code,
        value: currency.code,
        title: currency.name,
        icon: currency.symbol_native,
      });
    });
  }

  getCurrencyCodeList() {
    return this._currencyCodeList;
  }

  // getFormattedCurrency(value: any, codeValue: string) {
  //     return currencyFormatter.format(value, { code: codeValue });
  // }

  getCurrency(CurrencyIsoCode: string) {
    return currencyList[CurrencyIsoCode];
  }

  formatMonetaryAmount({
    currencyIsoCode,
    amount,
    showSymbol = false,
    showThousandsSep = false,
    limitFractionsToCurrency = false,
  }: {
    currencyIsoCode: string;
    amount;
    showSymbol?: boolean;
    showThousandsSep?: boolean;
    limitFractionsToCurrency?: boolean;
  }): string {
    if (amount == null || amount === "") return "";

    try {
      let formattedAmount = new Intl.NumberFormat(this.localeId, {
        currency: currencyIsoCode,
        style: "currency",
        currencyDisplay: "code",
        maximumFractionDigits: limitFractionsToCurrency ? undefined : 20, // 20 is the largest number of fraction digits that Intl can accept
      }).format(amount);

      if (!showSymbol) formattedAmount = formattedAmount.replace(currencyIsoCode, "").trim();

      if (!showThousandsSep) formattedAmount = formattedAmount.replace(/,/g, "");

      return formattedAmount;
    } catch (e) {
      return amount;
    }
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
