/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { formatDate } from "@angular/common";
import {
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MenuItem, MessageService, SelectItem } from "primeng/api";
import { Observable, of, Subscription } from "rxjs";
import { Col, RowSelectionType } from "src/app/form-list/form-list/form-list";
import { FormListComponent } from "src/app/form-list/form-list/form-list.component";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { SaleChannelOrdersService } from "./sale-channel-orders.service";
import * as moment from "moment";
import * as _ from "lodash";
import { CalendarDateRangeType } from "src/app/taku-ui/taku-calendar-range/taku-calendar-range.component";
import { environment } from "src/environments/environment";
import {
  PrintingFactoryService,
  PrintingMgr,
  ReceiptPrintingSize,
} from "src/app/shared/services/printing-service.service";
import { SaleChannelFulfillmentStatus, SaleChannelOrder, SaleChannelPaymentStatus } from "./sale-channel-order";
import { SaleDoc } from "../../document/sale-document/sale-doc/sale-doc";

@Component({
  selector: "taku-sale-channel-orders",
  templateUrl: "./sale-channel-orders.component.html",
  styleUrls: ["./sale-channel-orders.component.scss"],
})
export class SaleChannelOrdersComponent implements OnInit, OnDestroy {
  private static readonly DATE_FORMAT = "yyyy-MM-dd";
  subsList: Subscription[] = [];
  protected webUrl = environment.apiUrl;
  _isDataReady: boolean;
  ReceiptPrintingSize = ReceiptPrintingSize;
  _isFetchingData = false;
  @ViewChild("formList", { static: false }) formListComponent: FormListComponent;
  // printDocumentEvent: EventEmitter<SaleChannelOrder> = new EventEmitter();

  lookup_stores$: Observable<SelectItem[]> = of([]);
  lookup_sale_channels$: Observable<SelectItem[]> = of([]);
  isAdmin: boolean;

  enum_delivery_methods = this.saleChannelOrdersService.enum_delivery_methods;
  enum_payment_statuses = this.saleChannelOrdersService.enumSelectOptions(SaleChannelPaymentStatus);
  enum_order_statuses = this.saleChannelOrdersService.enum_order_statuses;
  enum_fulfillment_statuses = this.saleChannelOrdersService.enumSelectOptions(SaleChannelFulfillmentStatus);

  saleChannelOrderColumns: Col[] = [];
  formGroup: UntypedFormGroup;
  headerFormGroup: UntypedFormGroup;
  formFilter: {} = null;
  // showFormList: boolean;
  RowSelectionType = RowSelectionType;
  activeAccountName: string;
  printMgr: PrintingMgr;
  @ViewChild("printPreviewHost", { read: ViewContainerRef, static: true }) printPreviewHost: ViewContainerRef;

  constructor(
    private saleChannelOrdersService: SaleChannelOrdersService,
    private fb: UntypedFormBuilder,
    @Inject(LOCALE_ID) private defaultLocale,
    private appSettingsService: AppSettingsStorageService,
    private printingMgrFactory: PrintingFactoryService,
    private messageService: MessageService
  ) {
    this._isDataReady = false;
    const activeStore = this.appSettingsService.getStore();
    this.isAdmin = !activeStore.id;
    this.lookup_stores$ = this.saleChannelOrdersService.lookup_stores();
    this.lookup_sale_channels$ = this.saleChannelOrdersService.lookup_sale_channels();
    this.formGroup = this.fb.group(this.getDefaultValues());
    if (!activeStore.id) {
      this.formGroup.get("storeId").disable();
    }
    this.searchOrders();
    // setTimeout(() => {
    //   this._isDataReady = true;
    // }, 0);
    this.formGroup.valueChanges.subscribe((changes) => {
      this.searchOrders();
    });
    // this.subsList.push(this.printDocumentEvent.subscribe((saleChannelOrder: SaleChannelOrder) => {
    //   this.printPackingList(saleChannelOrder, ReceiptPrintingSize.LETTER_SIZE);
    // }));
  }

  printPackingList(saleChannelOrder: SaleChannelOrder, printSize: ReceiptPrintingSize) {
    if (saleChannelOrder.saleDoc) {
      this.printMgr = this.printingMgrFactory.build(saleChannelOrder.saleDoc.storeId);
      this.subsList.push(
        this.printMgr.isReady.subscribe({
          next: () => {
            this.saleChannelOrdersService
              .getRow("saleDoc", saleChannelOrder.saleDoc.id)
              .subscribe((_saleDoc: SaleDoc) => {
                const _tempSaleDoc: SaleDoc = Object.assign({}, _saleDoc);
                _tempSaleDoc.saleDocLines.map((_saleDocLine) => {
                  _saleDocLine.unitPrice = 0;
                  _saleDocLine.salePrice = 0;
                });
                _tempSaleDoc.subTotal = 0;
                _tempSaleDoc.shipperChargeAmount = 0;
                _tempSaleDoc.discountAmount = 0;
                _tempSaleDoc.saleDocLines.map((_saleDocLine) => {
                  _saleDocLine.saleDocLineTaxes = [];
                });
                _tempSaleDoc.saleDocTenders = [];
                _tempSaleDoc.cashRounding = 0;
                _tempSaleDoc.saleDocTaxes = [];
                _tempSaleDoc.totalLineTax = 0;
                // this.printMgr = this.printingMgrFactory.build(_saleDoc.storeId);
                _tempSaleDoc["isPackingList"] = true;
                this.printMgr.printDocumentsWithSize([_tempSaleDoc], this.printPreviewHost, printSize);
              });
          },
          error: (error) => {
            alert("Error: Can't print because Receipt Builder Settings are not available");
          },
        })
      );
    }
  }

  getDefaultValues() {
    const dateFrom = new Date();
    dateFrom.setDate(dateFrom.getDate() - 31);
    const dateTo = new Date();
    const _storeId = this.appSettingsService.getStoreId();
    return {
      dateRangeType: CalendarDateRangeType.CUSTOM,
      dateFrom: dateFrom, // formatDate(dateFrom, SaleChannelOrdersComponent.DATE_FORMAT, this.defaultLocale),
      dateTo: dateTo, // formatDate(dateTo, SaleChannelOrdersComponent.DATE_FORMAT, this.defaultLocale),
      saleChannelId: -1,
      orderStatus: null,
      paymentStatus: null,
      fulfillmentStatus: null,
      storeId: this.isAdmin ? -1 : _storeId,
      deliveryMethod: null,
    };
  }

  ngOnInit() {
    this._isFetchingData = true;
    this.downloadOrders().subscribe((result: string) => {
      // this.saleChannelOrderColumns = this.saleChannelOrdersService.getFormListColumns(this.printDocumentEvent);
      this.saleChannelOrderColumns = this.saleChannelOrdersService.getFormListColumns({
        printItems: this.getPrintItems.bind(this),
      });
      if (this.formListComponent) this.formListComponent.unSelectAllRows();
      this._isDataReady = true;
      this._isFetchingData = false;
    });
  }

  downloadOrders(): Observable<string> {
    return this.saleChannelOrdersService._postRequest(this.webUrl + "TAKUeCommerceDownloadOrders", {});
  }

  clearSearch() {
    this._isFetchingData = true;
    this.downloadOrders().subscribe((result: string) => {
      if (this.formListComponent) this.formListComponent.unSelectAllRows();
      this.formGroup.reset(this.getDefaultValues());
      this._isFetchingData = false;
    });
  }

  searchOrders() {
    const searchQuery = this.formGroup.value;
    const queryObject = {};

    if (searchQuery.dateFrom || searchQuery.dateTo) {
      if (!searchQuery.dateFrom) {
        searchQuery.dateFrom = "1800-01-01";
      }

      if (!searchQuery.dateTo) {
        const newDateTo = moment().add(100, "years").toDate();
        searchQuery.dateTo = formatDate(newDateTo, SaleChannelOrdersComponent.DATE_FORMAT, this.defaultLocale);
      }

      queryObject["docDate"] = { value: [searchQuery.dateFrom, searchQuery.dateTo], matchMode: "between" };
    }
    if (searchQuery.storeId !== -1) {
      queryObject["storeId"] = { value: searchQuery.storeId, matchMode: "equals" };
    }
    if (searchQuery.saleChannelId !== -1) {
      queryObject["saleChannelId"] = { value: searchQuery.saleChannelId, matchMode: "equals" };
    }
    if (searchQuery.orderStatus) {
      queryObject["orderStatus"] = { value: searchQuery.orderStatus, matchMode: "equals" };
    }
    if (searchQuery.paymentStatus) {
      queryObject["paymentStatus"] = { value: searchQuery.paymentStatus, matchMode: "equals" };
    }
    if (searchQuery.fulfillmentStatus) {
      queryObject["fulfillmentStatus"] = { value: searchQuery.fulfillmentStatus, matchMode: "equals" };
    }
    if (searchQuery.deliveryMethod) {
      queryObject["deliveryMethod"] = { value: searchQuery.deliveryMethod, matchMode: "equals" };
    }

    this.formFilter = queryObject;
  }

  getPrintItems(rowData: SaleChannelOrder): MenuItem[] {
    return [
      {
        label: ReceiptPrintingSize.TAPE_SIZE,
        command: () => {
          // console.log(rowData);
          this.printPackingList(rowData, ReceiptPrintingSize.TAPE_SIZE);
        },
        icon: "takuicon-measuring-tape",
      },
      {
        label: ReceiptPrintingSize.LETTER_SIZE,
        command: () => {
          // console.log(rowData);
          this.printPackingList(rowData, ReceiptPrintingSize.LETTER_SIZE);
        },
        icon: "takuicon-invoice",
      },
    ];
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
