/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Input } from "@angular/core";
import { PersonSocialProfileType, PersonSocialProfile } from "./person-social-profile";
import { NestedFormComponent } from "../../../forms/nested-form/nested-form.component";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ACTION_TYPE } from "src/app/shared/components/action-button/action-type.enum";

@Component({
  selector: "taku-person-social-profile",
  templateUrl: "./person-social-profile.component.html",
  styleUrls: ["./person-social-profile.component.scss"],
})
export class PersonSocialProfileComponent extends NestedFormComponent {
  @Input() disableForm?: boolean = false;

  // Define enum variables
  // enum_commecrial_phone_type = this.dbService.enumSelectOptions(CommercialAccountPhoneType, 'Type');
  enum_person_social_profile_type = this.dbService.enumSelectOptions(PersonSocialProfileType);
  public ACTION_TYPE: typeof ACTION_TYPE = ACTION_TYPE;

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    const personSocialProfile_Group = fb.group(new PersonSocialProfile());
    return personSocialProfile_Group;
  }

  static setArray(fb: UntypedFormBuilder, personPSocialProfiles: PersonSocialProfile[]) {
    const formArray = fb.array([]);
    personPSocialProfiles.map((personSocialProfile) => {
      const temp = fb.group(personSocialProfile);
      formArray.push(temp);
    });
    return formArray;
  }

  initValidation() {
    this._validation = {
      socialProfileType: [Validators.required],
      socialProfile: [Validators.required],
    };
  }

  copySocialProfileToClipboard() {
    this.copyValueToClipboard(this.socialProfileCtrl.value);
  }

  get socialProfileCtrl() {
    return this._myForm.get("socialProfile");
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
