<taku-form-header
  [formGroup]="_myForm"
  (backClicked)="goBack()"
  (saveClicked)="onSave()"
  (revertClicked)="onRevert()"
  (deleteClicked)="onDelete()"
  [toolbarTitle]="'TAKU Pay'"
  [isSaving]="_isSaving"
>
</taku-form-header>
<div class="col-12 pl-0 pr-0">
  <div [formGroup]="_myForm" class="grid">
    <div class="col-12">
      <p-panel>
        <ng-template pTemplate="header">
          <h3>Location Details</h3>
        </ng-template>

        <div class="grid">
          <div class="col-12 lg:col-6 grid">
            <div class="col">
              <taku-dropdown
                [formGroup]="_myForm"
                [options]="stores_lookup$ | async"
                fieldName="storeId"
                fieldCaption="Store Name"
                placeholder="Store Name"
                [showValidationErrors]="false"
                [readonly]="!isAdmin"
              ></taku-dropdown>
            </div>
            <div class="align-self-center col-fixed" style="width: 30px">
              <a href="javascript://" class="pi pi-info-circle" (click)="companyNamePanel.toggle($event)">
                <p-overlayPanel #companyNamePanel [showCloseIcon]="false" appendTo="body">
                  <div class="tooltip-styling">
                    <h5>NOTE:</h5>
                    <ul>
                      <li>This should be your official company name</li>
                    </ul>
                  </div>
                </p-overlayPanel>
              </a>
            </div>
          </div>
        </div>

        <div class="grid">
          <div class="col-12 lg:col-6 drop-container country-wrapper">
            <taku-dropdown
              [formGroup]="_myForm"
              [options]="takupay_accounts_lookup$ | async"
              fieldName="takuPaymentAccountId"
              fieldCaption="TAKU Pay Account Name"
              placeholder="Taku Pay Account"
              [showValidationErrors]="false"
            ></taku-dropdown>
          </div>

          <div class="col-12 lg:col-6 drop-container region-wrapper">
            <taku-multiselect
              [formGroup]="_myForm"
              [options]="getLookupMulti_cardTypes$ | async"
              fieldName="tenderTypes"
              fieldCaption="Card Types"
              placeholder="Card Types"
              [showValidationErrors]="false"
            ></taku-multiselect>
          </div>
        </div>

        <div class="grid">
          <div class="col-12 grid-nogutter">
            <div class="col-12">
              <h6>Business Address</h6>
            </div>
            <taku-address
              [formGroup]="_myForm.get('address')"
              [autosetPhoneCountry]="true"
              [showIsDefaultControl]="false"
              [showAttentionControl]="false"
              [showLocationTypeControl]="false"
              [showNoteControl]="false"
              [showEmailControl]="false"
              [showPhoneCopyControl]="false"
            >
            </taku-address>
          </div>
        </div>
      </p-panel>
    </div>
  </div>
</div>
