/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Location } from "@angular/common";
import { Component, OnInit, ViewChild, ComponentFactoryResolver } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { TreeNode } from "primeng/api";
import { AppSettingsStorageService } from "../../../shared/app-settings-storage.service";
import { ReportService, ReportName } from "../reports-list/report.service";
import { Report } from "../report";
import { ReportFormListDirective } from "../reports-directives/report-form-list.directive";
import { ReportFilterFields } from "../report-filter-fields";
import { ReportParamsDirective } from "../reports-directives/report-params.directive";
import { ActivatedRoute, Router } from "@angular/router";
import { ReportParamsService } from "../report-params.service";

@Component({
  selector: "taku-reports-container",
  templateUrl: "./reports-container.component.html",
  styleUrls: ["./reports-container.component.scss"],
})
export class ReportsContainerComponent implements OnInit {
  reportFilterForm: UntypedFormGroup;
  sidebarMenuItems: TreeNode[];
  _report: Report;
  selectedReportType: any;
  @ViewChild(ReportParamsDirective, { static: true }) reportParamsHost: ReportParamsDirective;
  @ViewChild(ReportFormListDirective, { static: true }) reportFormListHost: ReportFormListDirective;

  ReportName = ReportName;

  // activeReport: DisplayReport;
  // DisplayReport = DisplayReport;

  constructor(
    private fb: UntypedFormBuilder,
    private appSettings: AppSettingsStorageService,
    protected location: Location,
    private reportService: ReportService,
    public reportParamsService: ReportParamsService,
    protected _route: ActivatedRoute,
    protected _router: Router,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  ngOnInit() {
    this.reportFilterForm = this.fb.group(
      Object.assign(new ReportFilterFields(), {
        stockId: 0,
        storeId: this.appSettings.getStoreId(),
        stationId: 0,
      })
    );

    this.sidebarMenuItems = this.reportService.getReportsList();

    if (!this.selectedReportType) {
      this.selectedReportType = this.sidebarMenuItems[0].children[0];
    }

    // Listen for changes in report param in order to know which report type to load
    this._route.children[0].params.subscribe((params) => {
      this._report = this.reportService.getRport(params["reportName"]);

      // make tree select have it's chosen element as whatever is in the url, on page load, or if the url changes independently
      for (let x = 0; x < this.sidebarMenuItems.length; x++) {
        const reportType = this.sidebarMenuItems[x].children.find(
          (node: TreeNode) => node.data[1] == params["reportName"]
        );

        if (reportType) {
          this.selectedReportType = reportType;
          break;
        }
      }

      this.loadComponents();
    });
  }

  onNodeSelect($event: any) {
    const node: TreeNode = $event?.node;
    if (!node) return;

    if ($event?.node?.data[0]) {
      this._router.navigate(node.data);
    }
  }

  loadComponents() {
    const paramsComponentFactory = this.componentFactoryResolver.resolveComponentFactory(
      this._report.reportParamsComponent
    );
    const viewParamsContainerRef = this.reportParamsHost.viewContainerRef;
    viewParamsContainerRef.clear();
    const paramsComponentRef = viewParamsContainerRef.createComponent(paramsComponentFactory);
    paramsComponentRef.instance.formGroup = this.reportFilterForm;

    const formListComponentFactory = this.componentFactoryResolver.resolveComponentFactory(
      this._report.reportFormListComponent
    );
    const viewFormListContainerRef = this.reportFormListHost.viewContainerRef;
    viewFormListContainerRef.clear();
    const formListComponentRef = viewFormListContainerRef.createComponent(formListComponentFactory);
    formListComponentRef.instance.filterForm = this.reportFilterForm;
  }

  goBack(): void {
    if (this.location) {
      this.location.back();
    }
  }

  applyFilterParams() {
    this.reportParamsService.applyReportParam();
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
