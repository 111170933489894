import { AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import {
  DISPLAY_MODE_TOKEN,
  FORM_GROUP_TOKEN,
  NestedFormComponent,
} from "src/app/forms/nested-form/nested-form.component";
import { InventoryUsageDetail } from "./inventory-usage-detail";
import { InventoryUsageDetailService } from "./inventory-usage-detail.service";
import { pairwise, startWith } from "rxjs/operators";
import { SelectItem } from "primeng/api";
import { ACTION_TYPE } from "src/app/shared/components/action-button/action-type.enum";

@Component({
  selector: "taku-inventory-usage-detail",
  templateUrl: "./inventory-usage-detail.component.html",
  styleUrls: ["./inventory-usage-detail.component.scss"],
})
export class InventoryUsageDetailComponent extends NestedFormComponent implements AfterViewInit {
  @Input() disableForm?: boolean = false;

  private _disabledOptions: Record<string, boolean>;
  @Input() set frequencyNameDropdownOptionsDisabled(disabledOptions: Record<string, boolean>) {
    this._disabledOptions = disabledOptions;

    this.enum_frequency_name = this.enum_frequency_name.map((option) => {
      if (this._myForm.get("frequencyName").value != option.label) {
        option["disabled"] = disabledOptions[option.label];
      }
      return option;
    });
  }
  public ACTION_TYPE: typeof ACTION_TYPE = ACTION_TYPE;

  get frequencyNameDropdownOptionsDisabled(): Record<string, boolean> {
    return this._disabledOptions;
  }

  @Output() frequencyNameDropdownOptionChanged: EventEmitter<Record<string, boolean>> = new EventEmitter();
  @Output() copyToClipboard: EventEmitter<string> = new EventEmitter();

  enum_frequency_name: SelectItem[] = this.inventoryUsageDetailService.get_enum_frequency_name(); // this.dbService.enumSelectOptions(Stock_type, 'Stock Type');
  enum_barcode_unit_Of_Measure = this.inventoryUsageDetailService.get_enum_unit_Of_Measure();

  constructor(
    public inventoryUsageDetailService: InventoryUsageDetailService,
    public fb: UntypedFormBuilder,
    @Optional() @Inject(FORM_GROUP_TOKEN) formGroup?,
    @Optional() @Inject(DISPLAY_MODE_TOKEN) displayMode?
  ) {
    super(inventoryUsageDetailService, fb, formGroup, displayMode);
  }

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    const inventoryUsageDetail_Group = fb.group(new InventoryUsageDetail());
    return inventoryUsageDetail_Group;
  }

  static setArray(fb: UntypedFormBuilder, invUsageDetails: InventoryUsageDetail[]) {
    const formArray = fb.array([]);
    invUsageDetails.map((ud) => {
      const temp = fb.group(ud);
      formArray.push(temp);
    });
    return formArray;
  }

  ngAfterViewInit() {
    // Auto focus barcode number field when adding new component (barcode line)
    // if (!this.barcodeFormatCtrl.value)
    //   this.barcodeNumberComponent.focusAndSelectInput();
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.refillFrequencyCtrl) {
      this.refillFrequencyCtrl.updateValueAndValidity({ emitEvent: false });
      if (this.refillFrequencyCtrl.invalid) this.refillFrequencyCtrl.markAsDirty();
    }

    // FrequencyName Disabled Options Inititalization (make newly used option not available)
    if (this.frequencyNameDropdownOptionsDisabled[this._myForm.get("frequencyName").value] == true) {
      const nonDisabledOption = this.getNextAvailableNonDisabledOption();
      this._myForm.get("frequencyName").setValue(nonDisabledOption);
      this.frequencyNameDropdownOptionChanged.emit({ [nonDisabledOption]: true });
    } else {
      const curr = this._myForm.get("frequencyName").value;
      this.frequencyNameDropdownOptionChanged.emit({ [curr]: true });
    }

    // FrequencyName Disabled Options Change Listener (make newly used option not available, and previous unused option available again)
    this.subsList.push(
      this._myForm
        .get("frequencyName")
        .valueChanges.pipe(startWith(this._myForm.get("frequencyName").value), pairwise())
        .subscribe(([prev, curr]) => {
          this.frequencyNameDropdownOptionChanged.emit({ [prev]: false, [curr]: true });
        })
    );

    this.subsList.push(
      this._myForm.valueChanges.subscribe((newFormat) => {
        if (this.refillFrequencyCtrl) {
          this.refillFrequencyCtrl.updateValueAndValidity({ emitEvent: false });
          if (this.refillFrequencyCtrl.invalid) this.refillFrequencyCtrl.markAsDirty();
        }
      })
    );
  }

  private getNextAvailableNonDisabledOption(): string | null {
    for (const [option, isDisabled] of Object.entries(this.frequencyNameDropdownOptionsDisabled)) {
      if (isDisabled == false) {
        return option;
      }
    }
    return null;
  }

  get frequencyNameCtrl() {
    return this._myForm.get("frequencyName");
  }

  get unitOfMeasureCtrl() {
    return this._myForm.get("refillFrequencyUOfM");
  }

  get refillFrequencyCtrl() {
    return this._myForm.get("refillFrequencyamount");
  }

  initValidation() {
    this._validation = {
      frequencyName: [Validators.required],
      refillFrequencyUOfM: [Validators.required],
      refillFrequencyamount: [Validators.required],
    };
  }

  copyBarcodeToClipboard() {
    this.copyToClipboard.emit(this.refillFrequencyCtrl.value);
  }

  ngOnDestroy(): void {
    // FrequencyName Disabled Options Form Deletion (make newly unused option available again)
    const curr = this._myForm.get("frequencyName").value;
    this.frequencyNameDropdownOptionChanged.emit({ [curr]: false });
  }
}
