/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

export class PersonEmail {
  id: number;
  emailType: PersonEmailType;
  email: string;

  constructor() {
    this.id = 0;
    this.emailType = PersonEmailType.personal;
    this.email = "";
  }
}

export enum PersonEmailType {
  personal = "Personal",
  work = "Work",
  other = "Other",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
