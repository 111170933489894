/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { HttpClient } from "@angular/common/http";
import { Injectable, Inject, LOCALE_ID } from "@angular/core";
import { DataType, FilterType, Col } from "../../../../form-list/form-list/form-list";
import { AuthService } from "../../../../shared/services/auth.service";
import { CurrencyFormatterService } from "../../../../shared/services/currency-formatter.service";
import { DBService } from "../../../../shared/services/db.service";
import {
  Tender_Type,
  TenderTypeTransactionType,
  TenderType,
  Other_Type_Labels,
  Other_Type,
} from "../../business-settings/tender-type/tender-type";
import { FormListModel } from "../../../../form-list/form-list/form-list-model.interface";
import { UntypedFormGroup, Validators } from "@angular/forms";
import { CurrencySymbolPipe } from "../../../../shared/pipes/currency-symbol.pipe";
import { Transaction_Type } from "../../../../shared/services/transaction-types";
import { SelectItem } from "primeng/api";
import { of } from "rxjs";
import { cloneDeep } from "lodash";
import { FilterRows } from "src/app/utility/FormDataHelpers";
import { TakuPayAccountDetail } from "../../integration-settings/taku-pay/taku-pay-account-details/taku-pay-account-details";
import { ApiListResponse, ValidatorMapType } from "src/app/utility/types";

@Injectable({
  providedIn: "root",
})
export class ZoneTenderTypesService extends DBService implements FormListModel {
  enum_tender_types = this.enumSelectOptions(Tender_Type);
  enum_other_types = this.enumSelectOptions(this.getMappedEnum(Other_Type, Other_Type_Labels));
  takuPaymentEnabled = false;

  readonly enum_transactionTypes = this.enumMultiselectOptions(Transaction_Type).map((item) => {
    // TODO: For some reason the transactionType is stored in the db as an array of json objects, all with
    // id: 0, which is probably unnecessary. Until fixed, we have to do this weird mapping.
    const tenderTransactionType = new TenderTypeTransactionType(item.value);
    item.value = tenderTransactionType;
    return item;
  });

  private readonly takuPayTransactionTypes = cloneDeep(this.enum_transactionTypes).map((item) => {
    if ([Transaction_Type.Sales_Payback, Transaction_Type.Sales_Returns].includes(item.value.transactionType)) {
      item.disabled = true;
    }
    return item;
  });

  private readonly disableAllTransactionTypes = cloneDeep(this.enum_transactionTypes).map((item) => {
    item.disabled = true;
    return item;
  });

  readonly all_tenderTypeTransactionTypes: TenderTypeTransactionType[] = Object.values(Transaction_Type).map(
    (type) => new TenderTypeTransactionType(type)
  );

  constructor(
    @Inject(LOCALE_ID) protected defaultLocale: string,
    private currencyService: CurrencyFormatterService,
    protected http: HttpClient,
    protected authService: AuthService
  ) {
    super(http, authService);
    this.cacheAfterLogin();
  }

  override cacheAfterLogin(): void {
    const filter: FilterRows<TakuPayAccountDetail> = {
      isActive: { value: true, matchMode: "equals" },
    };
    this.getRows<ApiListResponse<TakuPayAccountDetail>>("takuPaymentAccount", JSON.stringify(filter)).subscribe(
      (response) => {
        this.takuPaymentEnabled = !!response.count;
      }
    );
  }

  protected override cleanUpAfterLogout(): void {
    this.takuPaymentEnabled = false;
  }

  private get_lookup_transaction_types(rowData: TenderType): SelectItem[] {
    if (rowData.isIntegrated && this.takuPaymentEnabled) {
      return this.takuPayTransactionTypes;
    }
    if (rowData.type === Tender_Type.Store_Credit || rowData.type === Tender_Type.Store_Voucher) {
      return this.disableAllTransactionTypes;
    }
    return this.enum_transactionTypes;
  }

  percentageFeeSuffix(rowData: TenderType): string {
    if (rowData.percentageFee != null) return "%";
    else return "";
  }

  flatFeePrefix(rowData: TenderType): string {
    const currencySymbol = new CurrencySymbolPipe(this.defaultLocale);
    if (rowData.flatFee != null && rowData.currencyIsoCode) {
      return currencySymbol.transform(rowData.currencyIsoCode);
    } else {
      return "";
    }
  }

  getValidationRules(): ValidatorMapType<TenderType> {
    return {
      type: [Validators.required],
      description: [Validators.required],
      currencyIsoCode: [Validators.required],
      transactionTypes: [Validators.required],
      percentageFee: [Validators.required],
      flatFee: [Validators.required],
    };
  }

  onTypeChanged(rowData: TenderType, originalRows: TenderType[], formGroup: UntypedFormGroup): void {
    if (rowData.type === Tender_Type.Store_Credit) {
      // Store credit can only be used for these transaction types
      const types = [
        new TenderTypeTransactionType(Transaction_Type.Sales_Charge),
        new TenderTypeTransactionType(Transaction_Type.Sales_Returns),
      ];
      rowData.transactionTypes = types;
      formGroup.controls.transactionTypes.setValue(types);
    } else if (rowData.type === Tender_Type.Store_Voucher) {
      // Store voucher can only be used for Sales
      const types = [new TenderTypeTransactionType(Transaction_Type.Sales_Charge)];
      rowData.transactionTypes = types;
      formGroup.controls.transactionTypes.setValue(types);
    }
  }

  getFormListColumns(): Col[] {
    return [
      {
        field: "isEnabled",
        header: "Is Enabled",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.checkbox,
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.enum,
      },
      {
        field: "type",
        header: "Tender Type",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enum_tender_types,
        filterType: FilterType.enum,
        onModelChange: this.onTypeChanged.bind(this),
      },
      {
        field: "otherType",
        header: "Other Type",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enum_other_types,
        filterType: FilterType.enum,
      },
      {
        field: "description",
        header: "Button Name",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "currencyIsoCode",
        header: "Currency",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.currencyService.getCurrencyCodeList(),
        filterType: FilterType.enum,
      },
      {
        field: "transactionTypes",
        header: "Transaction Type",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enumMultiselect,
        dataOptions: this.get_lookup_transaction_types.bind(this),
        dataKey: "transactionType",
        filterOptions: this.enum_transactionTypes,
        filterDataKey: "transactionType",
        filterType: FilterType.enum,
        filterMatchMode: "contains",
        filterField: "`transactionTypes`",
      },
      {
        field: "percentageFee",
        header: "Percentage Fee",
        visible: false,
        readonly: false,
        frozen: false,
        dataType: DataType.number,
        dataTypeOptions: { suffix: this.percentageFeeSuffix.bind(this) },
        dataOptions: of([]),
        filterType: FilterType.contains,
      },
      {
        field: "flatFee",
        header: "Flat Fee",
        visible: false,
        readonly: false,
        frozen: false,
        dataType: DataType.number,
        dataTypeOptions: { prefix: this.flatFeePrefix.bind(this) },
        dataOptions: of([]),
        filterType: FilterType.contains,
      },
      {
        field: "isIntegrated",
        header: "Is Integrated",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.checkbox,
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.enum,
      },
    ];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
