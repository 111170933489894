<div class="topWrapper">
  <taku-form-header [toolbarTitle]="_headerTitle" displayMode="inline" [showActionBtns]="false" [showBackBtn]="false">
    <button pButton class="p-button-rounded" type="button" (click)="onClosePressed()" icon="pi pi-times"></button>
  </taku-form-header>
  <div class="bodyWrapper">
    <main>
      <div id="doc-fields">
        <table cellspacing="0">
          <tr>
            <td class="label-col">Doc#</td>
            <td class="value-col">{{ inventoryDoc.doc.docNo }}</td>
          </tr>
          <tr>
            <td class="label-col">Type</td>
            <td class="value-col">{{ inventoryDoc.doc.docType }}</td>
          </tr>
          <tr>
            <td class="label-col">Date & Time</td>
            <td class="value-col">{{ inventoryDoc.doc.docDate | dateTimeFormat : inventoryDoc.doc.docTime }}</td>
          </tr>
          <tr>
            <td class="label-col">Supplier</td>
            <td class="value-col"
              ><span class="cursor-pointer link" (click)="openCustomerDetails()">
                {{ accountName }}
              </span></td
            >
          </tr>
          <tr>
            <td class="label-col">Supplier Email</td>
            <td class="value-col link">
              <a href="{{ 'mailto:' + this.footerFormGroup.value.emailAddress }}">{{
                this.footerFormGroup.value.emailAddress
              }}</a>
              <taku-action-button
                [size]="BUTTON_SIZE.SMALL"
                [action]="ACTION_TYPE.COPY"
                (clickAction)="copyEmailToClipboard(this.footerFormGroup.value.emailAddress)"
              ></taku-action-button>
            </td>
          </tr>
        </table>

        <table cellspacing="0">
          <tr>
            <td class="label-col">Total</td>
            <td class="value-col">{{
              inventoryDoc.grandTotal | currency : _currencyISOCode : "symbol-narrow" : "1.2"
            }}</td>
          </tr>
          <tr>
            <td class="label-col">Receiving Date</td>
            <td class="value-col">
              {{ inventoryDoc.doc.docDate }}
            </td>
          </tr>
          <tr>
            <td class="label-col">Notes</td>
            <td class="value-col">
              {{ inventoryDoc.doc.voidReason }}
            </td>
          </tr>
        </table>
      </div>
    </main>

    <taku-inventory-doc-transactions
      *ngIf="inventoryDoc.inventoryDocLines"
      [inventoryDoc]="inventoryDoc"
    ></taku-inventory-doc-transactions>

    <footer>
      <div class="footer-btns grid">
        <!-- <div class="sendEmailWrapper" *ngIf="_isStore">
          <taku-input
            *ngIf="sendEmailEnabled"
            class="field-emailAddress"
            [disabled]="true"
            [formGroup]="footerFormGroup"
            fieldName="emailAddress"
            fieldCaption="Email address"
          ></taku-input>
          <div *ngIf="!sendEmailEnabled" class="taku-warningMsg">
            <p-message
              severity="warn"
              text="&nbsp;Email Settings needs to be set up before sending emails."
            ></p-message>
          </div>
          <div class="email-input-container">
            <button pButton class="email-btn squared-corners" [disabled]="true">
              <i class="material-icons"> email </i>
              <span class="ml-2 font-bold">Send</span>
            </button>
          </div>
        </div> -->
        <div class="btn-container-wrapper">
          <!-- <div class="btn-container">
            <button
              pButton
              [disabled]="!printMgr.isReady || inventoryDoc.doc.state === 'Voided'"
              class="btn-action reprint-btn squared-corners"
              [label]="'Reprint'"
              icon="pi pi-print"
              (click)="menuPrintSizes.toggle($event)"
            >
            </button>
          </div> -->
          <!-- Disabling this VOID button for now -->
          <!-- <div *ngIf="_enabledEditMode" class="btn-container">
            <button
              pButton
              class="btn-action"
              label="Void"
              icon="pi pi-ban"
              [disabled]="isVoidDisabled"
              (click)="onVoidPressed()"
            ></button>
          </div> -->
        </div>
      </div>
    </footer>

    <p-menu #menuPrintSizes [popup]="true" [model]="printSizesMenuItems" appendTo="body"></p-menu>
    <!-- <p-menu #menuEmailReceiptSizes [popup]="true" [model]="emailReceiptSizesMenuItems" appendTo="body"></p-menu> -->
  </div>
</div>

<p-dialog
  [(visible)]="_activeFullDialog"
  [ngSwitch]="_activeFullDialog"
  [contentStyle]="{ 'max-height': 'calc(100% - 50px)' }"
  class="fullsize-modal"
  [blockScroll]="true"
  [modal]="false"
  appendTo="body"
  [showHeader]="true"
  (onHide)="closeFullSizeDialog()"
  [styleClass]="_activeFullDialog !== 'printPreview' ? 'fixedsize-modal' : null"
>
  <div *ngSwitchCase="'printPreview'" class="print-preview"> </div>
</p-dialog>

<div [hidden]="true">
  <ng-template #printPreviewHost></ng-template>
</div>
