/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { StoreSettingsGenericComponent } from "../StoreSettingsGenericComponent";
import { SaleDocEntryMethod, StoreSettingSaleDoc, SaleScreenDefaultTab } from "./sales-doc-settings";

@Component({
  selector: "taku-sales-doc-settings",
  templateUrl: "./sales-doc-settings.component.html",
  styleUrls: ["./sales-doc-settings.component.scss"],
})
export class SalesDocSettingsComponent extends StoreSettingsGenericComponent {
  _pageTitlePrefix = "Sales Doc Settings";
  lookup_entry_method = this.dbService.enumSelectOptions(SaleDocEntryMethod);
  lookup_default_tab = this.dbService.enumSelectOptions(SaleScreenDefaultTab);

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    const tmpModel = new StoreSettingSaleDoc();
    const tmpForm = fb.group(tmpModel);

    return tmpForm;
  }

  initLookups() {}

  initForm() {
    this._model = "storeSettingSaleDoc";
    this._object = new StoreSettingSaleDoc();
    this._myForm = SalesDocSettingsComponent.init(this.fb);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
