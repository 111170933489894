<span [formGroup]="formGroup" class="p-float-label">
  <p-inputNumber
    [attr.tabindex]="tabIndex"
    [pKeyFilter]="keyFilter"
    [attr.disabled]="disabled || null"
    [attr.readonly]="readonly || null"
    [formControlName]="fieldName"
    (blur)="onBlur.emit($event)"
    [min]="min"
    [max]="max"
  >
  </p-inputNumber>
  <label for="{{ fieldName }}" [innerHTML]="fullFieldCaption"></label>
  <i *ngIf="ctrlField.value && !(readonly || disabled)" class="material-icons clear-field" (click)="clear()">close</i>
</span>

<form-validation-messages
  *ngIf="showValidationErrors"
  [hidden]="ctrlField.valid || !ctrlField.dirty"
  [errors]="ctrlField.errors"
  [fieldName]="fieldName"
></form-validation-messages>
<!-- <form-validation-messages *ngIf="ctrlField.touched" [errors]="ctrlField.errors"></form-validation-messages> -->
