/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { AbstractControl } from "@angular/forms";
import * as _ from "lodash";
import { ConfirmationService } from "primeng/api";
import { Address, AddressType } from "../core/contact-accounts/address/address";
import { CommercialAccount } from "../core/contact-accounts/commercial-account/commercial-account";
import { PersonalAccount } from "../core/contact-accounts/personal-account/personal-account";
import { SaleDocType } from "../core/document/doc/doc";
import { DiscountType, SaleDocLine } from "../core/document/sale-document/sale-doc-line/sale-doc-line";
import { SaleDoc } from "../core/document/sale-document/sale-doc/sale-doc";
import { Tender_Type } from "../core/settings/business-settings/tender-type/tender-type";
import { SearchResultItem } from "../taku-ui/taku-search-accounts/SearchResultItem";
import { FormDataHelpers } from "./FormDataHelpers";
import { InventoryDocLine } from "../core/document/inventory-document/inventory-document/inventory-document";

export class InvoiceHelpers {
  constructor(private saledoc: SaleDoc) {}

  get isReturnDoc(): boolean {
    return this.saledoc.doc.docType === SaleDocType.sales_return;
  }

  roundedBalanceBeforeCash(): any {
    const noCashPayments: number[] = this.saledoc.notReturnedTenders
      .filter((tender) => {
        return Tender_Type.Cash != tender.tenderType.type;
      })
      .map((tender) => parseFloat(tender.amount + ""));
    const totalNoCash = _.sum(noCashPayments);

    return (
      (this.isReturnDoc ? -1 : 1) * this.saledoc.grandTotal - totalNoCash + parseFloat(this.saledoc.cashRounding + "")
    );
  }

  totalCashPayments() {
    const cashPayments = this.saledoc.notReturnedTenders.filter((tender, index) => {
      return (
        Tender_Type.Cash == tender.tenderType.type &&
        (tender.amount !== 0 || index < this.saledoc.notReturnedTenders.length - 1)
      );
    });
    return _.sum(cashPayments.map((tender) => parseFloat(tender.amount + "")));
  }

  static GetCombinedTaxAcronyms(saleLine: SaleDocLine | InventoryDocLine) {
    const maxLength = 6;
    const separatorToken = "";
    const taxAcronyms = [];

    const docLineTaxes =
      (saleLine as SaleDocLine).saleDocLineTaxes ?? (saleLine as InventoryDocLine).inventoryDocLineTaxes ?? [];

    for (let i = 0; i < docLineTaxes.length; i++) {
      const lineTax = docLineTaxes[i];
      if (lineTax && lineTax.taxRule) {
        const acronym = lineTax.taxRule.taxAcronym;
        if ([...taxAcronyms, acronym].join(separatorToken).length > maxLength) break;
        taxAcronyms.push(acronym);
      } else {
        console.warn("Cannot fetch Tax Acronym for Line: ", saleLine);
      }
    }

    return taxAcronyms.join(separatorToken);
  }

  static switchDiscountType(
    discountTypeCtrl: AbstractControl,
    discountAmountCtrl: AbstractControl,
    priceCtrl: AbstractControl,
    confirmationService: ConfirmationService,
    priceCaption: string
  ) {
    const newDiscountType = discountTypeCtrl.value;
    const discountAmount = parseFloat(discountAmountCtrl.value);
    const unitPrice = parseFloat(priceCtrl.value);

    const sharedConfimationMsg = {
      header: "Notice",
      rejectVisible: false,
      acceptLabel: "OK",
      icon: "pi pi-exclamation-triangle",
    };

    // const validationErrorsMsg = "Unable to change Discount Type:<br /> - Amount has validation errors";
    let errorMessage = "";
    switch (newDiscountType) {
      case DiscountType.Fixed:
      case DiscountType.Final_Price:
        if (discountAmount > unitPrice) {
          errorMessage =
            "<strong>Unable to change Discount Type</strong><br >&nbsp;&nbsp;- Amount cannot exceed " + priceCaption;

          confirmationService.confirm(
            Object.assign(sharedConfimationMsg, {
              message: errorMessage,
              accept: () => {
                // Revert to previous discount type
                discountTypeCtrl.setValue(DiscountType.Percentage);
              },
            })
          );
        }
        break;

      case DiscountType.Percentage: // previous discount was fixed
        if (discountAmount > 100) {
          errorMessage = "<strong>Unable to change Discount Type</strong><br >&nbsp;&nbsp;- Amount cannot exceed 100%";

          confirmationService.confirm(
            Object.assign(sharedConfimationMsg, {
              message: errorMessage,
              accept: () => {
                // Revert to previous discount type
                discountTypeCtrl.setValue(DiscountType.Fixed);
              },
            })
          );
        }
        break;
    }

    // Update discount field in order to trigger sale price onchange event
    discountAmountCtrl.updateValueAndValidity();
  }

  // static retrieveAddressprefillAddress(addressType: AddressType, account: SearchResultItem):Address {
  //   switch (account.modelName) {
  //     case 'personalAccount':
  //       const accPerson: PersonalAccount = <PersonalAccount>account.data;
  //       const personAddresses = accPerson.person.personAddresses.map(adress => adress.address);

  //   }
  // }

  static findAccountAddressByType(addressType: AddressType, account: SearchResultItem): Address | null {
    switch (account.modelName) {
      case "personalAccount":
        const accPerson: PersonalAccount = <PersonalAccount>account.data;
        // prefill addresses
        const personAddresses = accPerson.person.personAddresses.map((adress) => adress.address);
        if (addressType == AddressType.billingAddress)
          return InvoiceHelpers.searchAddressByType(personAddresses, AddressType.billingAddress);
        else if (addressType == AddressType.shippingAddress)
          return InvoiceHelpers.searchAddressByType(personAddresses, AddressType.shippingAddress);
        break;

      case "commercialAccount":
        const accCommercial: CommercialAccount = <CommercialAccount>account.data;
        // prefill addresses
        const companyAddresses = accCommercial.commercialAccountAddresses.map((adress) => adress.address);
        if (addressType == AddressType.billingAddress)
          return InvoiceHelpers.searchAddressByType(companyAddresses, AddressType.billingAddress);
        else if (addressType == AddressType.shippingAddress)
          return InvoiceHelpers.searchAddressByType(companyAddresses, AddressType.shippingAddress);
        break;
    }
  }

  private static searchAddressByType(addresses: Address[], type: AddressType): Address {
    if (!addresses || addresses.length === 0) return null;

    const filteredAddresses = addresses.filter((address) => address.addressType === type);
    let addressMatch;
    // if we found addresses of the same type, try to pick the one set as default. If none is default, use the first address
    if (filteredAddresses.length >= 1)
      addressMatch = filteredAddresses.find((address) => address.isDefault) || filteredAddresses[0];

    if (!addressMatch) return null;
    // Clone in order to not use the same address for shipping and billing
    const foundAddress = _.cloneDeep(addressMatch);
    // Set id to 0, so we don't have issues when saving
    FormDataHelpers.deleteModelIDs(foundAddress);

    return foundAddress;
  }

  /**
   * This function create string of number with format XXX-XXX-XXX
   */
  public static generateRandomRefId(): string {
    let sRandom: string = Math.floor(Math.random() * 1000000000).toString();
    sRandom = sRandom.slice(0, 3) + "-" + sRandom.slice(3, 6) + "-" + sRandom.slice(6);
    return sRandom;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
