/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from "lodash";
import { ConfirmationService, MessageService, SelectItem } from "primeng/api";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { Observable, of } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { UnsavedChangesGuard } from "src/app/app-routing/UnsavedChanges.guard";
import { GenericFormComponent } from "../../../forms/generic-form/generic-form.component";
import { AppSettingsStorageService } from "../../../shared/app-settings-storage.service";
import { AlertMessagesService } from "../../../shared/services/alert-messages.service";
import { Store } from "../../settings/store-settings/store/store";
import { UserRole } from "../user-role/user-role";
import { UserRoleComponent } from "../user-role/user-role.component";
import { User } from "../user/user";
import { UserService } from "../user/user.service";
import { BackOffice } from "../../settings/backOffice-settings/backOffice/backOffice";
import { AppComponent } from "src/app/app.component";

@Component({
  selector: "taku-role-permissions",
  templateUrl: "./role-permissions.component.html",
  styleUrls: ["./role-permissions.component.scss"],
})
export class RolePermissionsComponent extends GenericFormComponent implements OnInit {
  lookup_roles: SelectItem[][] = [];
  lookup_stores: SelectItem[][] = [];
  lookup_back_offices: SelectItem[][] = [];

  constructor(
    protected _router: Router,
    public fb: UntypedFormBuilder,
    public dbService: UserService,
    protected location: Location,
    public _route: ActivatedRoute,
    protected messageService: MessageService,
    protected alertMessage: AlertMessagesService,
    protected confirmationService: ConfirmationService,
    appSettings: AppSettingsStorageService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private unsavedChangesGuard: UnsavedChangesGuard
  ) {
    super(_router, fb, dbService, location, _route, messageService, alertMessage, confirmationService, appSettings);
    this._object = config.data.user;
    this._isDialog = true;
  }

  static init(fb): UntypedFormGroup {
    const tmpForm = fb.group(new User());
    return tmpForm;
  }

  ngOnInit() {
    super.ngOnInit();

    const fnInitAndDisableLookups = (allUserRoles: UserRole[]) => {
      allUserRoles.forEach((userRole, index) => {
        // Lookups for Roles
        let lookupRoles$: Observable<SelectItem[]>;
        if (this.lookup_roles[index]) lookupRoles$ = of(this.lookup_roles[index]);
        else
          lookupRoles$ = this.dbService.getLookup_roles().pipe(
            shareReplay(1),
            map((lookupRoles: SelectItem[]) => {
              this.lookup_roles[index] = _.cloneDeep(lookupRoles);
              return this.lookup_roles[index];
            })
          );

        this.subsList.push(
          lookupRoles$.subscribe((roleItems) => {
            this.disableAssignedUserRoles(roleItems, allUserRoles, userRole);
          })
        );

        // Lookups for Stores
        let lookupStores$: Observable<SelectItem[]>;
        if (this.lookup_stores[index]) lookupStores$ = of(this.lookup_stores[index]);
        else
          lookupStores$ = this.dbService.getLookupMulti_stores().pipe(
            shareReplay(1),
            map((lookupStores: SelectItem[]) => {
              this.lookup_stores[index] = _.cloneDeep(lookupStores);
              return this.lookup_stores[index];
            })
          );

        this.subsList.push(
          lookupStores$.subscribe((storeItems) => {
            this.disableAssignedUserStores(storeItems, allUserRoles, userRole);
          })
        );

        // Lookups for backOffices
        let lookupBackOffices$: Observable<SelectItem[]>;
        if (this.lookup_back_offices[index]) lookupBackOffices$ = of(this.lookup_back_offices[index]);
        else
          lookupBackOffices$ = this.dbService.getLookupMulti_backOffices().pipe(
            shareReplay(1),
            map((lookupBackOffices: SelectItem[]) => {
              this.lookup_back_offices[index] = _.cloneDeep(lookupBackOffices);
              return this.lookup_back_offices[index];
            })
          );

        this.subsList.push(
          lookupBackOffices$.subscribe((backOfficeItems) => {
            this.disableAssignedUserBackOffices(backOfficeItems, allUserRoles, userRole);
          })
        );
      });
    };

    // Process initial values
    fnInitAndDisableLookups(this.userRolesArray.value);
    // Process new values, or update UI when value changes
    this.subsList.push(this.userRolesArray.valueChanges.subscribe(fnInitAndDisableLookups));
  }

  initForm() {
    this._model = "user";
    this._object = new User();
    this._myForm = RolePermissionsComponent.init(this.fb);
  }

  setFormArrays() {
    this._myForm.setControl("userRoles", UserRoleComponent.setArray(this.fb, this._object.userRoles));
  }

  get userNameCtrl() {
    return this._myForm.get("userName");
  }

  get userRolesArray() {
    return <UntypedFormArray>this._myForm.controls["userRoles"];
  }

  addUserRole() {
    if (!(this.userRolesArray instanceof UntypedFormArray)) {
      this._myForm.setControl("userRoles", this.fb.array([]));
    }
    this.userRolesArray.push(UserRoleComponent.init(this.fb));
  }

  removeUserRole(index: number) {
    if (this.userRolesArray.at(index).pristine) {
      this._removeFormArrayChild(this.userRolesArray, index);
      return;
    }

    this.confirmationService.confirm({
      message: "By deleting this User Role you will lose your changes. Are you sure?",

      accept: () => {
        this._removeFormArrayChild(this.userRolesArray, index);
      },
    });
  }

  loadObject() {
    super.loadObject();
    if (!this.userRolesArray.length) this.addUserRole();
  }

  closeDialogPressed() {
    this.subsList.push(
      this.unsavedChangesGuard.checkForm(this._myForm.pristine).subscribe((accepted) => {
        if (accepted) this.ref.close();
      })
    );
  }

  disableAssignedUserRoles(rolesLookup: SelectItem[], allUserRoles: UserRole[], currentUserRole: UserRole) {
    const userRolesToDisable = _.difference(allUserRoles, [currentUserRole]);
    rolesLookup.forEach((item) => {
      Object.assign(item, {
        disabled: item.value && userRolesToDisable.map((userRole) => userRole.roleId).includes(item.value),
      });
    });
  }

  disableAssignedUserStores(storesLookup: SelectItem[], allUserRoles: UserRole[], currentUserRole: UserRole) {
    const userRolesToDisable = _.difference(allUserRoles, [currentUserRole]);
    storesLookup.forEach((item) => {
      const currStoreId = (<Store>item.value).id;
      Object.assign(item, {
        disabled: _.flatten(
          userRolesToDisable.map((userRole) => (userRole.stores || []).map((store) => store.id))
        ).includes(currStoreId),
      });
    });
  }

  disableAssignedUserBackOffices(backOfficesLookup: SelectItem[], allUserRoles: UserRole[], currentUserRole: UserRole) {
    const userRolesToDisable = _.difference(allUserRoles, [currentUserRole]);
    backOfficesLookup.forEach((item) => {
      const currBackOfficeId = (<BackOffice>item.value).id;
      Object.assign(item, {
        disabled: _.flatten(
          userRolesToDisable.map((userRole) => (userRole.backOffices || []).map((backOffice) => backOffice.id))
        ).includes(currBackOfficeId),
      });
    });
  }

  get _toolbarTitle() {
    return `Assigned Roles <span class='secondary-text'>(${this.userNameCtrl.value})</span>`;
  }

  onClose() {
    // Guarding against unsaved changes will be done by the Form Header component
    // so when this method is called that means that user already confirmed to close the dialog
    this.ref.close();
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
