/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, ElementRef, forwardRef, Output, EventEmitter, Input } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { DomHandler } from "primeng/dom";
import { InputMask } from "primeng/inputmask";

// Access parent class component's decorator in order to simulate decorator inheritance
// const parentDecorator: ComponentDecorator = (<any>InputMask).__annotations__[0];
const INPUTMASK_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TakuInputMaskComponent),
  multi: true,
};

@Component({
  template:
    '<input #input pInputText class="p-inputmask" (focusout)="onFocusOut.emit($event)" [attr.id]="inputId" [attr.type]="type" [attr.name]="name" [ngStyle]="style" [ngClass]="styleClass" [attr.placeholder]="placeholder" [attr.title]="title"\n        [attr.size]="size" [attr.autocomplete]="autocomplete" [attr.maxlength]="maxlength" [attr.tabindex]="tabindex" [attr.aria-label]="ariaLabel" [attr.aria-required]="ariaRequired" [disabled]="disabled" [readonly]="readonly" [attr.required]="required"\n        (focus)="onInputFocus($event)" (blur)="onInputBlur($event)" (keydown)="onInputKeydown($event)" (keypress)="onKeyPress($event)" [attr.autofocus]="autoFocus"\n        (input)="onInputChange($event)" (paste)="handleInputChange($event)">',
  host: {
    "[class.p-inputwrapper-filled]": "filled",
    "[class.p-inputwrapper-focus]": "focused",
  },
  selector: "taku-inputMask",
  providers: [INPUTMASK_VALUE_ACCESSOR, DomHandler],
})
export class TakuInputMaskComponent extends InputMask {
  static readonly DEFAULT_ALPHA_MASK = "a";
  static readonly DEFAULT_NUMERIC_MASK = "9";
  private _defs: any;
  @Input() alphaMaskChar: string;
  @Input() numericMaskChar: string;
  // Emits event when invalid input providing KeyCode of invalid input
  @Output() invalidCharInput = new EventEmitter<number>();
  @Output() onFocusOut = new EventEmitter();

  constructor(public el: ElementRef) {
    super(el, null);
  }

  // @ts-ignore
  set defs(value) {
    // Change character for numberic mask to # instead of 9
    if (this.alphaMaskChar || this.alphaMaskChar === "") {
      // Replace built-in alpha mask char by custom
      value[this.alphaMaskChar] = value[TakuInputMaskComponent.DEFAULT_ALPHA_MASK];
      if (this.alphaMaskChar !== TakuInputMaskComponent.DEFAULT_ALPHA_MASK)
        delete value[TakuInputMaskComponent.DEFAULT_ALPHA_MASK];
    }

    if (this.numericMaskChar || this.numericMaskChar === "") {
      // Replace built-in numeric mask char by custom
      value[this.numericMaskChar] = value[TakuInputMaskComponent.DEFAULT_NUMERIC_MASK];
      if (this.numericMaskChar !== TakuInputMaskComponent.DEFAULT_NUMERIC_MASK)
        delete value[TakuInputMaskComponent.DEFAULT_NUMERIC_MASK];
    }

    this._defs = value;
  }

  get defs() {
    return this._defs;
  }

  onKeyPress(e) {
    const bufferBefore = this.buffer.join("");
    const pos = this.caret();

    super.onKeyPress(e);

    const bufferAfter = this.buffer.join("");
    if (bufferBefore === bufferAfter && pos.begin <= this.lastRequiredNonMaskPos) {
      const k = e.which || e.keyCode;
      this.invalidCharInput.emit(k);
    }
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
