/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Store } from "../../../store-settings/store/store";
import { GMBAccount } from "../gmb-accounts-list/GMBGoogleAccount";

export class GMBLocation {
  id: number;
  name: string;
  storeCode: string;
  locationName: string;
  locationAddress: string;
  primaryCategory: JSON;
  additionalCategories: JSON[];
  targetCountry: string;
  contentLanguage: string;
  description: string;
  storeId: number;
  store: Store;
  gmbAccountId: number;
  gmbAccount: GMBAccount;
  accountName: string;
  gmbAccountName: string;
  verificationStatus?: string;

  constructor() {
    this.id = 0;
    this.name = "";
    this.storeCode = null;
    this.locationName = null;
    this.locationAddress = null;
    this.primaryCategory = null;
    this.additionalCategories = [];
    this.targetCountry = null;
    this.contentLanguage = null;
    this.description = "";
    this.storeId = null;
    this.store = null;
    this.gmbAccountId = null;
    this.gmbAccount = null;
    this.accountName = "";
    this.gmbAccountName = "";
  }
}

export enum GMBLocationVerificationStatus {
  VERIFIED = "Verified",
  UNVERIFIED = "Unverified",
  PENDING = "Pending",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
