import { Component, OnDestroy, OnInit } from "@angular/core";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { Router } from "@angular/router";
import { AppComponent } from "src/app/app.component";
import { Subscription } from "rxjs";
import { KioskSetting } from "src/app/core/settings/store-settings/kiosk-settings/kioskSettings";

@Component({
  selector: "taku-self-checkout-landing-page",
  templateUrl: "./self-checkout-landing-page.component.html",
  styleUrls: ["./self-checkout-landing-page.component.scss"],
})
export class SelfCheckoutLandingPageComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];

  public storeName: string;
  public storeLogoUrl: string;
  public businessLogoUrl: string;

  startBtnCustomBgColor: string;
  startBtnCustomFontColor: string;
  customBgImageUrl: string;
  displayContents = false;

  constructor(public app: AppComponent, private appSettingsService: AppSettingsStorageService, private router: Router) {
    const store = this.appSettingsService.getStore();
    this.storeName = store.storeName;
    this.storeLogoUrl = store.urlStoreLogoUrl475;
  }

  ngOnInit(): void {
    this.app.onMenuButtonClick();
    this.subsList.push(
      this.appSettingsService.getBusinessDetails().subscribe((businessDetails) => {
        this.businessLogoUrl = businessDetails.urlBusinessDetailBusinessLogoUrl;
      })
    );

    this.subsList.push(
      this.appSettingsService.getKioskStoreSettingDetails().subscribe((kioskSettingsData: KioskSetting) => {
        this.startBtnCustomBgColor = kioskSettingsData.colorTheme;
        this.startBtnCustomFontColor = kioskSettingsData.mainScreenBtnFontColor;
        this.customBgImageUrl =
          kioskSettingsData.urlOpeningScreenImageFileName || "/assets/layout/images/defaultKioskBackground.png";
        setTimeout(() => {
          this.displayContents = true;
        }, 50);
      })
    );
  }

  onTapBtnClicked() {
    this.router.navigateByUrl("/kiosk/selfCheckout/saleDoc/0");
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}
