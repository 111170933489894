<ng-template [ngIf]="saleDoc">
  <div class="topWrapper">
    <taku-form-header [toolbarTitle]="dialogTitle" displayMode="inline" [showActionBtns]="false" [showBackBtn]="false">
      <button
        class="p-button p-button-rounded"
        pButton
        type="button"
        (click)="onClosePressed()"
        icon="pi pi-times"
      ></button>
    </taku-form-header>

    <div class="bodyWrapper">
      <main>
        <taku-sale-doc-transactions [saleDoc]="saleDoc"></taku-sale-doc-transactions>
      </main>

      <footer class="col-12">
        <div class="left-btns">
          <button pButton class="btn-action void-btn p-button-secondary" label="Void" [disabled]="true"></button>
          <button pButton class="btn-action copy-btn p-button-secondary" label="Copy" [disabled]="true"></button>
          <!-- <button pButton class="btn-action continuesale-btn" label="CONTINUE SALE&nbsp;&nbsp;" icon="ui-icon-shopping-basket" iconPos="right" (click)="onContinueSalePressed($event)"></button> -->
          <button
            pButton
            class="btn-action continuesale-btn"
            label="Continue Sale"
            (click)="onContinueSalePressed()"
          ></button>
        </div>

        <button
          pButton
          class="btn-action close-btn p-button-rounded p-button-secondary"
          label="Close"
          icon="pi pi-times"
          (click)="onClosePressed()"
        ></button>
      </footer>
    </div>
  </div>
</ng-template>
