<div class="grid">
  <taku-form-header
    toolbarTitle="GMC ERRORS: Inventory SKU {{ _inventory.sku }}"
    displayMode="inline"
    [showActionBtns]="false"
    [showBackBtn]="false"
  ></taku-form-header>

  <div class="col-12">
    <h2>Merchant Center Errors for Inventory '{{ _inventory.description1 }}'</h2>
  </div>

  <div class="col-12 errorDetails-wrapper">
    <table>
      <thead>
        <tr>
          <th>Servability</th>
          <th>Attribute Name</th>
          <th>Code</th>
          <th>Description</th>
          <th>Detail</th>
          <th>Documentation</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let issue of _itemLevelIssues; let i = index" class="servability-{{ issue.servability }}">
          <td>{{ issue.servability }}</td>
          <td>{{ issue.attributeName }}</td>
          <td>{{ issue.code }}</td>
          <td>{{ issue.description }}</td>
          <td>{{ issue.detail }}</td>
          <td
            ><a [attr.href]="issue.documentation" target="_blank">{{ issue.documentation }}</a></td
          >
        </tr>
      </tbody>
    </table>
  </div>
</div>
