import { CommitmentsComponent } from "./../core/document/sale-document/commitments/commitments.component";
/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CashoutHistoryComponent } from "../core/cashout/cashout-history/cashout-history.component";
import { CashoutComponent } from "../core/cashout/cashout/cashout.component";
import { CommercialAccountComponent } from "../core/contact-accounts/commercial-account/commercial-account.component";
import { PersonalAccountComponent } from "../core/contact-accounts/personal-account/personal-account.component";
import { InventoryDocListComponent } from "../core/document/inventory-document/inventory-doc-list/inventory-doc-list.component";
import { StockAdjustmentType } from "../core/document/inventory-document/inventory-document/inventory-document";
import { InventoryDocumentComponent } from "../core/document/inventory-document/inventory-document/inventory-document.component";
import { ReturnInvoiceDocMainComponent } from "../core/document/return-document/return-invoice-doc-main/return-invoice-doc-main.component";
import { ParkedSalesComponent } from "../core/document/sale-document/parked-sales/parked-sales.component";
import { RevisitSaleComponent } from "../core/document/sale-document/revisit-sale/revisit-sale.component";
import { SaleInvoiceDocMainComponent } from "../core/document/sale-document/sale-invoice-doc-main/sale-invoice-doc-main.component";
import { BrandComponent } from "../core/inventory/brand/brand.component";
import { InventoryComponent } from "../core/inventory/inventory/inventory.component";
import { UOfMeasureComponent } from "../core/inventory/u-of-measure/u-of-measure.component";
import { ReportsContainerComponent } from "../core/reports/reports-container/reports-container.component";
import { BusinessSettingsComponent } from "../core/settings/business-settings-cards/business-settings.component";
import { BusinessDetailComponent } from "../core/settings/business-settings/business-detail/business-detail.component";
import { BusinessFormatsSettingsComponent } from "../core/settings/business-settings/business-formats-settings/business-formats-settings.component";
import { ShipperComponent } from "../core/settings/business-settings/shipper/shipper.component";
import { IntegrationSettingsCardsComponent } from "../core/settings/integration-settings-cards/integration-settings-cards.component";
import { GatewayTerminalsComponent } from "../core/settings/integration-settings/gateway-terminals/gateway-terminals.component";
import { GmbAccountsListComponent } from "../core/settings/integration-settings/google-my-business/gmb-accounts-list/gmb-accounts-list.component";
import { GmbLocationsListComponent } from "../core/settings/integration-settings/google-my-business/gmb-locations-list/gmb-locations-list.component";
import { GMCAccountsComponent } from "../core/settings/integration-settings/google-shopping/gmc-accounts/gmc-accounts.component";
import { GoogleShoppingMainComponent } from "../core/settings/integration-settings/google-shopping/google-shopping-main/google-shopping-main.component";
import { GoogleShoppingProductsComponent } from "../core/settings/integration-settings/google-shopping/google-shopping-products/google-shopping-products.component";
import { StoreSettingsCardsComponent } from "../core/settings/store-settings-cards/location-settings.component";
import { AccountGroupComponent } from "../core/settings/store-settings/account-group/account-group.component";
import { CashoutSettingsComponent } from "../core/settings/store-settings/cashout-settings/cashout-settings.component";
import { FullPageReturnReceiptBuilderComponent } from "../core/settings/store-settings/full-page-returnreceipt-builder/full-page-returnreceipt-builder.component";
import { FullPageSaleReceiptBuilderComponent } from "../core/settings/store-settings/full-page-salereceipt-builder/full-page-salereceipt-builder.component";
import { GiftReceiptBuilderComponent } from "../core/settings/store-settings/gift-receipt-builder/gift-receipt-builder.component";
import { ReturnSettingsComponent } from "../core/settings/store-settings/return-settings/return-settings.component";
import { SalesDocSettingsComponent } from "../core/settings/store-settings/sales-doc-settings/sales-doc-settings.component";
import { SalesFormatsSettingsComponent } from "../core/settings/store-settings/sales-formats-settings/sales-formats-settings.component";
import { StoreFormListComponent } from "../core/settings/store-settings/store-form-list/store-form-list.component";
import { StoreListComponent } from "../core/settings/store-settings/store-list/store-list.component";
import { StoreMailSettingsComponent } from "../core/settings/store-settings/store-mail-settings/store-mail-settings.component";
import { StorePoliciesSettingsComponent } from "../core/settings/store-settings/store-policies-settings/store-policies-settings.component";
import { TenderTypesSettingsComponent } from "../core/settings/store-settings/store-tender-type-settings/tender-types-settings.component";
import { StoreComponent } from "../core/settings/store-settings/store/store.component";
import { TapeReturnReceiptBuilderComponent } from "../core/settings/store-settings/tape-returnreceipt-builder/tape-returnreceipt-builder.component";
import { TapeSaleReceiptBuilderComponent } from "../core/settings/store-settings/tape-salereceipt-builder/tape-salereceipt-builder.component";
import { UserManagementCardsComponent } from "../core/settings/user-management-cards/user-management-cards.component";
import { ZoneSettingsCardsComponent } from "../core/settings/zone-settings-cards/zone-settings-cards.component";
import { ZoneFormListComponent } from "../core/settings/zone-settings/zone-form-list/zone-form-list.component";
import { ZonesListComponent } from "../core/settings/zone-settings/zones-list/zones-list.component";
import { TaxCategoriesComponent } from "../core/tax/tax-categories/tax-categories.component";
import { TaxRuleComponent } from "../core/tax/tax-rule/tax-rule.component";
import { TaxSettingsComponent } from "../core/tax/tax-settings/tax-settings.component";
import { TaxComponent } from "../core/tax/tax/tax.component";
import { AccessRightsComponent } from "../core/users/access-rights/access-rights-details/access-rights.component";
import { ForgotLoginComponent } from "../core/users/forgot-login/forgot-login.component";
import { LoginComponent } from "../core/users/login/login.component";
import { UserListComponent } from "../core/users/user-list/user-list.component";
import { UserComponent } from "../core/users/user/user.component";
import { DashboardComponent } from "../dashboard/dashboard.component";
import { FormListMainComponent } from "../form-list/form-list-main/form-list-main.component";
import { BusinessSetupResolverService } from "../wizards/business-setup/business-setup-resolver.service";
import { BusinessSetupComponent } from "../wizards/business-setup/business-setup.component";
import { AuthBusinessSetupGuard } from "./auth-business-setup.guard";
import { AuthGuard } from "./auth.guard";
import { CashoutResolverService } from "./cashout-resolver.service";
import { ObjectDetailResolverService } from "./object-detail-resolver.service";
import { InvoiceSettingsResolverService } from "./salesscreen-settings-resolver.service";
import { SalesscreenGuard } from "./salesscreen.guard";
import { UnsavedFormListGuard } from "./unsaved-form-list.guard";
import { UnsavedChangesGuard } from "./UnsavedChanges.guard";
import { InvoiceGuard } from "./invoice.guard";
import { AccessRightsGuard } from "./access-rights.guard";
import { PaymentGatewaysListComponent } from "../core/settings/integration-settings/payment-gateways/payment-gateways-list.component";
import { AccessRightsDetailsFilteredComponent } from "../core/users/access-rights/access-rights-details-filtered/access-rights-details-filtered.component";
import { ManageRolesComponent } from "../core/users/access-rights/manage-roles/manage-roles.component";
import { ReturnScreenGuard } from "./returnscreen.guard";
import { PageNotFoundComponent } from "../shared/page-not-found/page-not-found.component";
import { SaleChannelContainerComponent } from "../core/sale-channel/sale-channel-container/sale-channel-container.component";
import { SaleChannelSettingsComponent } from "../core/sale-channel/sale-channel-settings/sale-channel-settings.component";
import { SaleChannelGuard } from "../core/sale-channel/sale-channel.guard";
import { SaleChannelLandingComponent } from "../core/sale-channel/sale-channel-landing/sale-channel-landing.component";
import { SaleChannelInventoryComponent } from "../core/sale-channel/sale-channel-inventory/sale-channel-inventory.component";
import { SaleChannelDetailComponent } from "../core/sale-channel/sale-channel-detail/sale-channel-detail.component";
import { SaleChannelOrdersComponent } from "../core/sale-channel/sale-channel-orders/sale-channel-orders.component";
import { InventoryDocType } from "../core/document/doc/doc";
import { EconduitTerminalComponent } from "../core/settings/integration-settings/econduit-terminal/econduit-terminal.component";
import { MonerisTerminalComponent } from "../core/settings/integration-settings/moneris-terminal/moneris-terminal.component";
import { KioskSettingsComponent } from "../core/settings/store-settings/kiosk-settings/kiosk-settings.component";
import { SaleDocSelfCheckoutMainComponent } from "../core/document/sale-document/sale-doc-self-checkout-main/sale-doc-self-checkout-main.component";
import { SelfCheckoutLandingPageComponent } from "../core/document/sale-document/self-checkout-landing-page/self-checkout-landing-page.component";
import { KioskGuard } from "./kiosk.guard";
import { InventoryOptionsSettingValueFormListComponent } from "../core/inventory/inventory-options-setting-value-form-list/inventory-options-setting-value-form-list.component";
import { InventoryFormListComponent } from "../core/inventory/inventory-form-list/inventory-form-list.component";
import { InventoryDetailResolverService } from "./inventory-detail-resolver.service";
import { TakuPayComponent } from "./../core/settings/integration-settings/taku-pay/taku-pay.component";
import { TakuPayFormListComponent } from "../core/settings/integration-settings/taku-pay/taku-pay-form-list/taku-pay-form-list.component";
import { TakuPayAccountDetailsComponent } from "./../core/settings/integration-settings/taku-pay/taku-pay-account-details/taku-pay-account-details.component";
import { PasswordResetComponent } from "../core/users/password-reset/password-reset.component";
import { CancelRecurringOrderComponent } from "../core/users/cancel-recurring-order/cancel-recurring-order.component";
import { AllUpcomingOrdersComponent } from "../core/all-upcoming-orders/all-upcoming-orders/all-upcoming-orders.component";
import { RecurringOrdersFormListComponent } from "../core/settings/zone-settings/recurring-order-settings/recurring-order-settings-form-list.component";
import { TakuPaymentGatewaysListComponent } from "../core/settings/integration-settings/taku-pay/taku-payment-gateways-list/taku-payment-gateways-list.component";
import { TakuPaymentGatewayTerminalsComponent } from "../core/settings/integration-settings/taku-pay/taku-payment-gateway-terminals/taku-payment-gateway-terminals.component";
import { TakuPayTerminalComponent } from "../core/settings/integration-settings/taku-pay/taku-pay-terminal/taku-pay-terminal.component";
import { TakuPayLocationDetailComponent } from "../core/settings/integration-settings/taku-pay/taku-pay-location-detail/taku-pay-location-detail.component";
import { PurchaseOrderTermSettingsComponent } from "../core/purchase-order-term/purchase-order-term-settings/purchase-order-term-settings.component";
import { PurchaseDocListComponent } from "../core/document/purchase-document/purchase-doc-list/purchase-doc-list.component";
import { PurchaseDocumentComponent } from "../core/document/purchase-document/purchase-document/purchase-document.component";
import { ZoneEmailSettingsComponent } from "../core/settings/zone-settings/zone-email-settings/zone-email-settings.component";

const routes: Routes = [
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "reset-login/:accountName",
    component: ForgotLoginComponent,
  },
  {
    path: "password-reset",
    component: PasswordResetComponent,
  },
  {
    path: "cancel-recurring-order",
    component: CancelRecurringOrderComponent,
  },
  {
    path: "signup",
    loadChildren: () => import("src/app/core/users/signup/signup.module").then((m) => m.SignupModule),
  },

  // Wizards
  {
    path: "businessSetup",
    canActivate: [AuthBusinessSetupGuard],
    children: [
      {
        path: ":storeId",
        component: BusinessSetupComponent,
        resolve: { defaults: BusinessSetupResolverService },
        canActivate: [AccessRightsGuard],
        data: {
          accessRightFeatureNames: [
            "Settings_Menu_Stores_Manage_Stores",
            "Settings_Menu_Business_Settings_Business_Details",
            "Settings_Menu_Zones_Current_Zone_Denominations",
            "Settings_Menu_Zones_Current_Zone_Tender_Types",
            "Settings_Menu_Stores_Current_Store_Payment_Settings",
            "Settings_Menu_Stores_Current_Store_Sales_Doc_Formats",
            "Settings_Menu_Zones_Current_Zone_Inventory_Doc_Formats",
          ],
        },
      },
      {
        path: "",
        component: BusinessSetupComponent,
        canActivate: [AccessRightsGuard],
        data: {
          accessRightFeatureNames: [
            "Settings_Menu_Stores_Manage_Stores",
            "Settings_Menu_Business_Settings_Business_Details",
            "Settings_Menu_Zones_Current_Zone_Denominations",
            "Settings_Menu_Zones_Current_Zone_Tender_Types",
            "Settings_Menu_Stores_Current_Store_Payment_Settings",
            "Settings_Menu_Stores_Current_Store_Sales_Doc_Formats",
            "Settings_Menu_Zones_Current_Zone_Inventory_Doc_Formats",
          ],
        },
      },
    ],
  },

  {
    path: "",
    canActivate: [AuthGuard],
    children: [
      { path: "", redirectTo: "/dashboard", pathMatch: "full" },
      // Reports section
      {
        path: "reports",
        component: ReportsContainerComponent,
        canActivate: [AccessRightsGuard],
        data: {
          accessRightFeatureNames: ["Reports_Menu"],
        },
        children: [
          {
            path: ":reportName",
            component: ReportsContainerComponent,
            data: {
              accessRightFeatureNames: ["Reports_Menu"],
            },
          },
        ],
      },
      {
        path: "settings/importer",
        loadChildren: () =>
          import("src/app/core/import/data-importer/data-importer.module").then((m) => m.DataImporterModule),
      },

      // Sales Docs
      {
        path: "sell/saleDoc/:id",
        component: SaleInvoiceDocMainComponent,
        resolve: { saleDoc: ObjectDetailResolverService, settings: InvoiceSettingsResolverService },
        canDeactivate: [InvoiceGuard],
        canActivate: [SalesscreenGuard, AccessRightsGuard],
        data: {
          accessRightFeatureNames: ["Sell_Menu_Sales_Register"],
          hideInAdminMode: true,
        },
      },
      {
        path: "sell/saleDoc/returns/:id",
        component: ReturnInvoiceDocMainComponent,
        resolve: { saleDoc: ObjectDetailResolverService, settings: InvoiceSettingsResolverService },
        canDeactivate: [InvoiceGuard],
        canActivate: [ReturnScreenGuard, AccessRightsGuard],
        data: {
          accessRightFeatureNames: ["Sell_Menu_Returns"],
          hideInAdminMode: true,
        },
      },
      {
        path: "sell/saleDoc/commitments/:storeId",
        component: CommitmentsComponent,
        canActivate: [AccessRightsGuard],
        data: {
          accessRightFeatureNames: ["Sell_Menu_Commitments"],
          hideInAdminMode: true,
        },
      },
      {
        // path: 'saleDoc/documents/parked-sales/:storeId',
        path: "sell/saleDoc/parked-sales/:storeId",
        component: ParkedSalesComponent,
        canActivate: [AccessRightsGuard],
        data: {
          accessRightFeatureNames: ["Sell_Menu_Parked_Transactions"],
          hideInAdminMode: true,
        },
      },
      {
        path: "sell/cashout/:id",
        component: CashoutComponent,
        resolve: { cashout: CashoutResolverService },
        canActivate: [AccessRightsGuard],
        data: {
          accessRightFeatureNames: ["Sell_Menu_Cashout"],
          hideInAdminMode: true,
        },
      },
      {
        path: "sell/cashout/:id/:ref",
        component: CashoutComponent,
        resolve: { cashout: CashoutResolverService },
        canActivate: [AccessRightsGuard],
        data: {
          accessRightFeatureNames: ["Sell_Menu_Cashout"],
          hideInAdminMode: true,
        },
      },
      {
        path: "sell/cashout/:id/:ref/:storeId",
        component: CashoutComponent,
        resolve: { cashout: CashoutResolverService },
        canActivate: [AccessRightsGuard],
        data: {
          accessRightFeatureNames: ["Sell_Menu_Cashout"],
          hideInAdminMode: true,
        },
      },
      {
        path: "sell/cashout",
        component: CashoutComponent,
        resolve: { cashout: CashoutResolverService },
        canActivate: [AccessRightsGuard],
        data: {
          accessRightFeatureNames: ["Sell_Menu_Cashout"],
          hideInAdminMode: true,
        },
      },

      /*** HISTORY TAB ***/
      {
        path: "history/documents/revisit/:storeId",
        component: RevisitSaleComponent,
        canActivate: [AccessRightsGuard],
        data: {
          accessRightFeatureNames: ["Sell_Menu_Revisit"],
          hideInAdminMode: false,
        },
      },
      {
        path: "history/all-upcoming-orders/:storeId",
        component: AllUpcomingOrdersComponent,
        canActivate: [AccessRightsGuard],
        data: {
          accessRightFeatureNames: ["Sell_Menu_All_Upcoming_Orders"],
        },
      },
      {
        path: "history/cashout-history",
        component: CashoutHistoryComponent,
        canActivate: [AccessRightsGuard],
        data: {
          accessRightFeatureNames: ["Sell_Menu_Cashout_History"],
        },
      },

      /*** SELF-CHECKOUT ***/
      {
        path: "kiosk/selfCheckout/saleDoc/:id",
        component: SaleDocSelfCheckoutMainComponent,
        canDeactivate: [KioskGuard],
        canActivate: [KioskGuard],
        resolve: { saleDoc: ObjectDetailResolverService, settings: InvoiceSettingsResolverService },
        data: {
          hideInAdminMode: true,
        },
      },
      {
        path: "kiosk/selfCheckout/landingPage",
        component: SelfCheckoutLandingPageComponent,
        canActivate: [KioskGuard],
        // resolve: { saleDoc: ObjectDetailResolverService, settings: InvoiceSettingsResolverService },
        data: {
          hideInAdminMode: true,
        },
      },

      /*** SALES CHANNELS ***/
      {
        path: "sale-channel/landing/:storeId",
        component: SaleChannelLandingComponent,
      },
      {
        path: "sale-channel/orders/:storeId",
        component: SaleChannelOrdersComponent,
        canActivate: [AccessRightsGuard],
        data: {
          accessRightFeatureNames: ["SalesChannels_Menu_TAKUeCommerce"],
        },
      },
      {
        path: "sale-channel",
        component: SaleChannelContainerComponent,
        children: [
          {
            path: "admin-mode",
            component: SaleChannelSettingsComponent,
            canActivate: [AccessRightsGuard],
            data: {
              accessRightFeatureNames: ["SalesChannels_Menu_AdminMode"],
            },
          },
          // {
          //   path: 'settings/:storeId',
          //   component: SaleChannelSettingsComponent,
          //   canActivate: [AccessRightsGuard,SaleChannelGuard],
          //   data: {
          //     accessRightFeatureNames: ['SalesChannels_Menu_TAKUeCommerce']
          //   }
          // },
          {
            path: "inventory/:storeId",
            component: SaleChannelInventoryComponent,
            canActivate: [AccessRightsGuard],
            data: {
              accessRightFeatureNames: ["SalesChannels_Menu_TAKUeCommerce"],
            },
          },
        ],
      },

      /*** INVENTORY TAB ***/
      {
        path: "inventory-list/model-list/:detailmode/:model",
        component: InventoryFormListComponent,
        canActivate: [AuthGuard, AccessRightsGuard],
        canDeactivate: [UnsavedFormListGuard],
        data: {
          accessRightFeatureNames: ["Inventory_Menu_Inventory_List"],
        },
      },

      /*** INVENTORY DOCS ***/
      // Purchase Orders
      {
        path: "purchasing-list/purchaseDoc/purchase-order/list/:zoneId",
        component: PurchaseDocListComponent, // TODO make this component, and change this to use the new component
        // component: PurchaseDocumentComponent,
        canActivate: [AccessRightsGuard],
        data: {
          pageTitle: "Purchase Order List",
          docType: InventoryDocType.purchase_order,
          detailedViewRoute: "purchasing-list/purchaseDoc/purchase-order",
          accessRightFeatureNames: ["Purchasing_Menu_Purchase_Orders"],
        },
      },
      {
        path: "purchasing-list/purchaseDoc/purchase-order/:id",
        component: PurchaseDocumentComponent,
        data: {
          pageTitle: "Purchase Order",
          docType: InventoryDocType.purchase_order,
          stockAdjustmentType: StockAdjustmentType.In,
          accessRightFeatureNames: ["Purchasing_Menu_Purchase_Orders"],
        },
        resolve: { purchaseDoc: ObjectDetailResolverService },
        canDeactivate: [UnsavedChangesGuard],
        canActivate: [AccessRightsGuard],
      },
      // Stock Receiving
      {
        path: "purchasing-list/inventoryDoc/stock-receiving/list/:zoneId",
        component: InventoryDocListComponent,
        canActivate: [AccessRightsGuard],
        data: {
          pageTitle: "Stock Receiving List",
          docType: InventoryDocType.stock_receiving,
          detailedViewRoute: "purchasing-list/inventoryDoc/stock-receiving",
          accessRightFeatureNames: ["Purchasing_Menu_Stock_Receiving"],
        },
      },
      {
        path: "purchasing-list/inventoryDoc/stock-receiving/:id",
        component: InventoryDocumentComponent,
        data: {
          pageTitle: "Stock Receiving",
          docType: InventoryDocType.stock_receiving,
          stockAdjustmentType: StockAdjustmentType.In,
          accessRightFeatureNames: ["Purchasing_Menu_Stock_Receiving"],
        },
        resolve: { inventoryDoc: ObjectDetailResolverService },
        canDeactivate: [UnsavedChangesGuard],
        canActivate: [AccessRightsGuard],
      },
      // Inventory Transfer In
      {
        path: "inventoryDoc/transfer-in/list/:zoneId",
        component: InventoryDocListComponent,
        data: {
          pageTitle: "List of Inventory Transfer In",
          docType: InventoryDocType.inventory_transfer_in,
          detailedViewRoute: "inventoryDoc/transfer-in",
        },
      },
      {
        path: "inventoryDoc/transfer-in/:id",
        component: InventoryDocumentComponent,
        data: {
          pageTitle: "Inventory Transfer In",
          docType: InventoryDocType.inventory_transfer_in,
          stockAdjustmentType: StockAdjustmentType.In,
        },
        resolve: { inventoryDoc: ObjectDetailResolverService },
        canDeactivate: [UnsavedChangesGuard],
      },
      // Inventory Transfer Out
      {
        path: "inventoryDoc/transfer-out/list/:zoneId",
        component: InventoryDocListComponent,
        data: {
          pageTitle: "List of Inventory Transfer Out",
          docType: InventoryDocType.inventory_transfer_out,
          detailedViewRoute: "inventoryDoc/transfer-out",
        },
      },
      {
        path: "inventoryDoc/transfer-out/:id",
        component: InventoryDocumentComponent,
        data: {
          pageTitle: "Inventory Transfer Out",
          docType: InventoryDocType.inventory_transfer_out,
          stockAdjustmentType: StockAdjustmentType.Out,
        },
        resolve: { inventoryDoc: ObjectDetailResolverService },
        canDeactivate: [UnsavedChangesGuard],
      },
      {
        path: "inventory-list/price-level/model-list/:detailmode/:model",
        component: FormListMainComponent,
        canActivate: [AuthGuard],
        canDeactivate: [UnsavedFormListGuard],
        data: {
          // TODO
          // accessRightFeatureNames: ['Accounts_Menu_Personal_Accounts']
        },
      },
      {
        path: "inventory-list/promotion/model-list/:detailmode/:model",
        component: FormListMainComponent,
        canActivate: [AuthGuard],
        canDeactivate: [UnsavedFormListGuard],
        data: {},
      },
      // END INVENTORY DOCS

      { path: "settings/business-settings", component: BusinessSettingsComponent },

      // Business Details
      {
        path: "settings/business-settings/business-details",
        component: BusinessDetailComponent,
        canDeactivate: [UnsavedChangesGuard],
        canActivate: [AccessRightsGuard],
        data: {
          accessRightFeatureNames: ["Settings_Menu_Business_Settings_Business_Details"],
        },
      },
      {
        path: "settings/business-settings/business-formats",
        component: BusinessFormatsSettingsComponent,
        canDeactivate: [UnsavedChangesGuard],
        canActivate: [AccessRightsGuard],
        data: {
          accessRightFeatureNames: ["Settings_Menu_Business_Settings_Business_Formats"],
        },
      },
      // Unit of Measure
      {
        path: "settings/business-settings/u-of-m/:detailmode/:model",
        component: FormListMainComponent,
        canActivate: [AuthGuard, AccessRightsGuard],
        canDeactivate: [UnsavedFormListGuard],
        data: {
          navigationText: "Business Settings",
          pageTitle: "Unit Of Measure",
          pageIcon: "takuicon-measuring-tape",
          accessRightFeatureNames: ["Settings_Menu_Business_Settings_Unit_of_Measure"],
        },
      },
      // Brands
      {
        path: "settings/business-settings/brands/:detailmode/:model",
        component: FormListMainComponent,
        canActivate: [AuthGuard, AccessRightsGuard],
        canDeactivate: [UnsavedFormListGuard],
        data: {
          navigationText: "Business Settings",
          pageTitle: "Brands",
          pageIcon: "takuicon-brands",
          accessRightFeatureNames: ["Settings_Menu_Business_Settings_Brands"],
        },
      },
      // Inventory Options Settings
      {
        path: "settings/business-settings/inventoryOptionsSettings/:detailmode/:model",
        component: FormListMainComponent,
        canActivate: [AuthGuard, AccessRightsGuard],
        canDeactivate: [UnsavedFormListGuard],
        data: {
          navigationText: "Business Settings",
          pageTitle: "Inventory Options Settings",
          pageIcon: "material-icons-outlined",
          pageIconName: "post_add",
          settingsCol: {
            caption: "Add / Edit",
            routeLink: "settings/business-settings/inventoryOptionsSettingsValue/n/inventoryOptionsSettingValue/:id",
            routeQueryParams: { title: "Inventory Options Setting Values" },
          },
          accessRightFeatureNames: ["Inventory_Options_Settings"],
        },
      },
      // Inventory Options Settings Values
      {
        path: "settings/business-settings/inventoryOptionsSettingsValue/:detailmode/:model/:inventoryOptionsSettingId",
        component: InventoryOptionsSettingValueFormListComponent,
        canActivate: [AuthGuard, AccessRightsGuard],
        canDeactivate: [UnsavedFormListGuard],
        data: {
          navigationText: "Business Settings",
          pageTitle: "Inventory Options Setting Values",
          pageIcon: "material-icons-outlined",
          pageIconName: "post_add",
          accessRightFeatureNames: ["Inventory_Options_Settings_Value"],
        },
      },
      // Manage Backoffices
      {
        path: "settings/backOffices/manage/:detailmode/:model",
        component: FormListMainComponent,
        canActivate: [AuthGuard],
        canDeactivate: [UnsavedFormListGuard],
        data: {
          disableBackLink: true,
          pageTitle: "Manage Backoffices",
          pageIcon: "takuicon-manage-locations-settings",
          // settingsCol: {
          //   caption: 'Settings',
          //   routeLink: 'settings/backOffice-settings/:id'
          // }
        },
      },
      // Manage Stores
      {
        path: "settings/stores/manage-stores/:detailmode/:model",
        component: StoreListComponent,
        canActivate: [AuthGuard, AccessRightsGuard],
        canDeactivate: [UnsavedFormListGuard],
        data: {
          disableBackLink: true,
          pageTitle: "Manage Stores",
          pageIcon: "takuicon-manage-locations-settings",
          settingsCol: {
            caption: "Settings",
            routeLink: "settings/stores/current-store/location-settings/:id",
          },
          accessRightFeatureNames: ["Settings_Menu_Stores_Manage_Stores"],
        },
      },

      // Inventory Categories
      {
        path: "settings/business-settings/inventory-cats/:detailmode/:model",
        component: FormListMainComponent,
        canActivate: [AuthGuard, AccessRightsGuard],
        canDeactivate: [UnsavedFormListGuard],
        data: {
          navigationText: "Business Settings",
          pageTitle: "Inventory Categories",
          pageIcon: "takuicon-categories-settings",
          accessRightFeatureNames: ["Settings_Menu_Business_Settings_Inventory_Categories"],
        },
      },

      // Account Groups
      {
        path: "settings/business-settings/account-groups/:detailmode/:model",
        component: FormListMainComponent,
        canActivate: [AuthGuard, AccessRightsGuard],
        canDeactivate: [UnsavedFormListGuard],
        data: {
          navigationText: "Business Settings",
          pageTitle: "Account Groups",
          pageIcon: "takuicon-categories-settings",
          accessRightFeatureNames: ["Settings_Menu_Business_Settings_Account_Groups"],
        },
      },
      // Fiscal Years
      {
        path: "settings/business-settings/financial-settings/:detailmode/:model",
        component: FormListMainComponent,
        canActivate: [AuthGuard, AccessRightsGuard],
        canDeactivate: [UnsavedFormListGuard],
        data: {
          navigationText: "Business Settings",
          pageTitle: "Financial Settings",
          pageIcon: "material-icons",
          pageIconName: "attach_money",
          accessRightFeatureNames: ["Settings_Menu_Business_Settings_Financial"],
        },
      },
      // Manage Zones
      {
        path: "settings/zones/manage-zones/:detailmode/:model",
        component: ZonesListComponent,
        canActivate: [AuthGuard, AccessRightsGuard],
        canDeactivate: [UnsavedFormListGuard],
        data: {
          disableBackLink: true,
          navigationText: "Business Settings",
          pageTitle: "Manage Zones",
          pageIcon: "takuicon-create-zone-settings",
          settingsCol: {
            caption: "Settings",
            routeLink: "settings/zones/current-zone/:id",
          },
          accessRightFeatureNames: ["Settings_Menu_Zones_Manage_Zones"],
        },
      },

      /*** STORE SETTINGS ***/
      // Redirect default store route generate from form list to new one
      { path: "settings/stores/current-store/location-settings/:storeId", component: StoreSettingsCardsComponent },
      {
        path: "settings/stores/current-store/location-settings/tender-types/:storeId",
        component: TenderTypesSettingsComponent,
        canDeactivate: [UnsavedChangesGuard],
        canActivate: [AccessRightsGuard],
        data: {
          accessRightFeatureNames: ["Settings_Menu_Stores_Current_Store_Payment_Settings"],
        },
      },
      {
        path: "settings/stores/current-store/location-settings/sales-formats/:storeId",
        component: SalesFormatsSettingsComponent,
        canDeactivate: [UnsavedChangesGuard],
        canActivate: [AccessRightsGuard],
        data: {
          accessRightFeatureNames: ["Settings_Menu_Stores_Current_Store_Sales_Doc_Formats"],
        },
      },
      {
        path: "settings/stores/current-store/location-settings/store-policies/:storeId",
        component: StorePoliciesSettingsComponent,
        canDeactivate: [UnsavedChangesGuard],
        canActivate: [AccessRightsGuard],
        data: {
          accessRightFeatureNames: ["Settings_Menu_Stores_Current_Store_Store_Policies"],
        },
      },
      {
        path: "settings/stores/current-store/location-settings/receipt-builders/full-page/sales/:storeId",
        component: FullPageSaleReceiptBuilderComponent,
        canDeactivate: [UnsavedChangesGuard],
        canActivate: [AccessRightsGuard],
        data: {
          accessRightFeatureNames: ["Settings_Menu_Stores_Current_Store_Sales_Receipt_Builder_(Full_Page)"],
        },
      },
      {
        path: "settings/stores/current-store/location-settings/receipt-builders/full-page/returns/:storeId",
        component: FullPageReturnReceiptBuilderComponent,
        canDeactivate: [UnsavedChangesGuard],
        canActivate: [AccessRightsGuard],
        data: {
          accessRightFeatureNames: ["Settings_Menu_Stores_Current_Store_Return_Receipt_Builder_(Full_Page)"],
        },
      },
      {
        path: "settings/stores/current-store/location-settings/receipt-builders/tape/sales/:storeId",
        component: TapeSaleReceiptBuilderComponent,
        canDeactivate: [UnsavedChangesGuard],
        canActivate: [AccessRightsGuard],
        data: {
          accessRightFeatureNames: ["Settings_Menu_Stores_Current_Store_Sales_Receipt_Builder_(Tape)"],
        },
      },
      {
        path: "settings/stores/current-store/location-settings/receipt-builders/tape/returns/:storeId",
        component: TapeReturnReceiptBuilderComponent,
        canDeactivate: [UnsavedChangesGuard],
        canActivate: [AccessRightsGuard],
        data: {
          accessRightFeatureNames: ["Settings_Menu_Stores_Current_Store_Return_Receipt_Builder_(Tape)"],
        },
      },
      {
        path: "settings/stores/current-store/location-settings/gift-receipt-builders/tape/:storeId",
        component: GiftReceiptBuilderComponent,
        canDeactivate: [UnsavedChangesGuard],
        canActivate: [AccessRightsGuard],
        data: {
          accessRightFeatureNames: ["Settings_Menu_Stores_Current_Store_Gift_Receipt_Builder_(Tape)"],
        },
      },
      {
        path: "settings/stores/current-store/location-settings/return-settings/:storeId",
        component: ReturnSettingsComponent,
        canDeactivate: [UnsavedChangesGuard],
        canActivate: [AccessRightsGuard],
        data: {
          accessRightFeatureNames: ["Settings_Menu_Stores_Current_Store_Return_Settings"],
        },
      },
      {
        path: "settings/stores/current-store/location-settings/cashout-settings/:storeId",
        component: CashoutSettingsComponent,
        canDeactivate: [UnsavedChangesGuard],
        canActivate: [AccessRightsGuard],
        data: {
          accessRightFeatureNames: ["Settings_Menu_Stores_Current_Store_Cashout_Settings"],
        },
      },
      {
        // path: 'settings/store-settings/sales-doc-settings/:storeId',
        path: "settings/stores/current-store/location-settings/sales-doc-settings/:storeId",
        component: SalesDocSettingsComponent,
        canDeactivate: [UnsavedChangesGuard],
        canActivate: [AccessRightsGuard],
        data: {
          accessRightFeatureNames: ["Settings_Menu_Stores_Current_Store_Sales_Doc_Settings"],
        },
      },
      {
        path: "store-settings/kiosk-settings/:storeId",
        component: KioskSettingsComponent,
        canDeactivate: [UnsavedChangesGuard],
        canActivate: [AccessRightsGuard],
        data: {
          accessRightFeatureNames: ["Settings_Menu_Stores_Current_Store_Kiosk_Settings"],
        },
      },
      {
        path: "settings/printers/model-list/:detailmode/:model/:storeId",
        component: StoreFormListComponent,
        canActivate: [AuthGuard, AccessRightsGuard],
        canDeactivate: [UnsavedFormListGuard],
        data: {
          navigationText: "Current Store Settings",
          pageTitle: "Printer Settings",
          pageIcon: "material-icons",
          pageIconName: "local_printshop",
          accessRightFeatureNames: ["Settings_Menu_Store_Settings_Printer_Settings"],
        },
      },
      {
        // path: 'store-settings/mail-settings/:storeId',
        // path: 'settings/store-settings/mail-settings/:storeId',
        path: "settings/stores/current-store/location-settings/mail-settings/:storeId",
        component: StoreMailSettingsComponent,
        canDeactivate: [UnsavedChangesGuard],
        canActivate: [AccessRightsGuard],
        data: {
          accessRightFeatureNames: ["Settings_Menu_Stores_Current_Store_Email_Settings"],
        },
      },
      {
        path: "store/:id",
        component: StoreComponent,
        canActivate: [AuthGuard],
        canDeactivate: [UnsavedChangesGuard],
        resolve: { store: ObjectDetailResolverService },
      },
      // { path: 'settings/store-settings/store-details', component: StoreComponent },
      // Stations Form List
      {
        path: "settings/stations/model-list/:detailmode/:model/:storeId",
        component: StoreFormListComponent,
        canActivate: [AuthGuard, AccessRightsGuard],
        canDeactivate: [UnsavedFormListGuard],
        data: {
          navigationText: "Current Store Settings",
          pageTitle: "Stations",
          pageIcon: "takuicon-manage-stations-settings",
          accessRightFeatureNames: ["Settings_Menu_Stations"],
        },
      },
      // END STORE SETTINGS

      // ZONE SETTINGS ROUTES
      // Redirect default zone route generate from form list to new one
      { path: "settings/zones/current-zone/:zoneId", component: ZoneSettingsCardsComponent },
      // Tender Types
      {
        path: "settings/zones/current-zone/tenders/model-list/:detailmode/:model/:zoneId",
        component: ZoneFormListComponent,
        canActivate: [AuthGuard, AccessRightsGuard],
        canDeactivate: [UnsavedFormListGuard],
        data: {
          navigationText: "Current Zone Settings",
          pageTitle: "Zone Tenders",
          pageIcon: "pi pi-credit-card",
          accessRightFeatureNames: ["Settings_Menu_Zones_Current_Zone_Tender_Types"],
        },
      },
      // Shippers
      {
        path: "settings/zones/current-zone/shippers/model-list/:detailmode/:model/:zoneId",
        component: ZoneFormListComponent,
        canActivate: [AuthGuard, AccessRightsGuard],
        canDeactivate: [UnsavedFormListGuard],
        data: {
          navigationText: "Current Zone Settings",
          pageTitle: "Zone Shippers",
          pageIcon: "material-icons",
          pageIconName: "local_shipping",
          accessRightFeatureNames: ["Settings_Menu_Zones_Current_Zone_Shippers"],
        },
      },
      // Monetary Denominations
      {
        path: "settings/zones/current-zone/denominations/model-list/:detailmode/:model/:zoneId",
        component: ZoneFormListComponent,
        canActivate: [AuthGuard, AccessRightsGuard],
        canDeactivate: [UnsavedFormListGuard],
        data: {
          navigationText: "Current Zone Settings",
          pageTitle: "Monetary Denominations",
          pageIcon: "takuicon-money",
          accessRightFeatureNames: ["Settings_Menu_Zones_Current_Zone_Denominations"],
        },
      },
      // Stock
      {
        path: "settings/zones/current-zone/stock/model-list/:detailmode/:model/:zoneId",
        component: ZoneFormListComponent,
        canActivate: [AuthGuard, AccessRightsGuard],
        canDeactivate: [UnsavedFormListGuard],
        data: {
          navigationText: "Current Zone Settings",
          pageTitle: "Stock",
          pageIcon: "takuicon-stock-settings",
          accessRightFeatureNames: ["Settings_Menu_Zones_Current_Zone_Stock"],
        },
      },
      // Inventory Doc Formats
      {
        path: "settings/zones/current-zone/inventory-formats/model-list/:detailmode/:model/:zoneId",
        component: ZoneFormListComponent,
        canActivate: [AuthGuard, AccessRightsGuard],
        canDeactivate: [UnsavedFormListGuard],
        data: {
          navigationText: "Current Zone Settings",
          pageTitle: "Inventory Docs Formats",
          pageIcon: "material-icons",
          pageIconName: "text_fields",
          accessRightFeatureNames: ["Settings_Menu_Zones_Current_Zone_Inventory_Doc_Formats"],
        },
      },
      // Inventory Doc Formats
      {
        path: "settings/zones/current-zone/recurring-orders/model-list/:detailmode/:model/:zoneId",
        component: RecurringOrdersFormListComponent,
        // canActivate: [AuthGuard, AccessRightsGuard],
        canDeactivate: [UnsavedFormListGuard],
        data: {
          navigationText: "Current Zone Settings",
          pageTitle: "Recurring Order Setup",
          pageIcon: "material-icons",
          pageIconName: "event_repeat",
          // accessRightFeatureNames: ['Settings_Menu_Zones_Current_Zone_Inventory_Doc_Formats']
        },
      },
      {
        path: "settings/zones/current-zone/mail-settings/:zoneId",
        component: ZoneEmailSettingsComponent,
        canDeactivate: [UnsavedChangesGuard],
        canActivate: [AccessRightsGuard],
        data: {
          accessRightFeatureNames: ["Settings_Menu_Zones_Manage_Zones"],
        },
      },
      // { path: 'zone-settings/monetary-denominations/:zoneId', component: ZoneDenominationsComponent },
      {
        path: "shipper/:id",
        component: ShipperComponent,
        canActivate: [AuthGuard],
        canDeactivate: [UnsavedChangesGuard],
        resolve: { uOfMeasure: ObjectDetailResolverService },
      },
      // END ZONE SETTINGS ROUTES
      {
        path: "dashboard",
        component: DashboardComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "acctGroup/:id",
        component: AccountGroupComponent,
        canActivate: [AuthGuard],
        canDeactivate: [UnsavedChangesGuard],
        resolve: { inventory: ObjectDetailResolverService },
      },
      {
        path: "inventory/:id",
        component: InventoryComponent,
        canActivate: [AuthGuard],
        canDeactivate: [UnsavedChangesGuard],
        resolve: { inventory: InventoryDetailResolverService },
      },
      {
        path: "uOfMeasure/:id",
        component: UOfMeasureComponent,
        canActivate: [AuthGuard],
        canDeactivate: [UnsavedChangesGuard],
        resolve: { uOfMeasure: ObjectDetailResolverService },
      },
      {
        path: "brand/:id",
        component: BrandComponent,
        canActivate: [AuthGuard],
        canDeactivate: [UnsavedChangesGuard],
        resolve: { brand: ObjectDetailResolverService },
      },
      {
        path: "personalAccount/:id",
        component: PersonalAccountComponent,
        canActivate: [AuthGuard],
        canDeactivate: [UnsavedChangesGuard],
        resolve: { personalAccount: ObjectDetailResolverService },
      },
      {
        path: "commercialAccount/:id",
        component: CommercialAccountComponent,
        canActivate: [AuthGuard],
        canDeactivate: [UnsavedChangesGuard],
        resolve: { commercialAccount: ObjectDetailResolverService },
      },
      {
        path: "saleChannel/:id",
        component: SaleChannelDetailComponent,
        canActivate: [AuthGuard],
        canDeactivate: [UnsavedChangesGuard],
        resolve: { saleChannel: ObjectDetailResolverService },
      },
      // Users Formlist
      {
        path: "settings/users",
        component: UserListComponent,
        canDeactivate: [UnsavedFormListGuard],
        canActivate: [AccessRightsGuard],
        data: {
          accessRightFeatureNames: ["Settings_Menu_User_Management_Manage_Users"],
        },
      },
      // Roles Formlist
      {
        path: "settings/manage-roles/:detailmode/:model",
        component: ManageRolesComponent,
        canActivate: [AuthGuard, AccessRightsGuard],
        canDeactivate: [UnsavedFormListGuard],
        data: {
          pageTitle: "Manage Roles",
          pageIcon: "material-icons",
          pageIconName: "vpn_key",
          settingsCol: {
            caption: "Access Rights",
            routeLink: "settings/access-rights/:id",
            // When we go to the Access Rights Data page, we want to have the form to be initially sorted by the 'module' column.
            routeQueryParams: { title: "Data", initialSortField: "module" },
          },
          accessRightFeatureNames: ["Settings_Menu_User_Management_Manage_Roles"],
        },
      },
      {
        path: "settings/access-rights/:id",
        component: AccessRightsComponent,
        children: [
          {
            path: "",
            redirectTo: "list/n/roleData",
            pathMatch: "full",
          },
          {
            path: "list/:detailmode/:model",
            component: AccessRightsDetailsFilteredComponent,
            canActivate: [AuthGuard, AccessRightsGuard],
            canDeactivate: [UnsavedFormListGuard],
            data: {
              disableAddRow: true,
              accessRightFeatureNames: ["Settings_Menu_User_Management_Manage_Roles"],
              settingsCol: {
                caption: "",
                routeLink: "",
                routeQueryParams: {},
              },
            },
          },
        ],
      },
      {
        path: "user/:id",
        component: UserComponent,
        canActivate: [AuthGuard, AccessRightsGuard],
        canDeactivate: [UnsavedChangesGuard],
        resolve: { user: ObjectDetailResolverService },
        data: {
          accessRightFeatureNames: ["Settings_Menu_User_Management_Manage_Users"],
        },
      },

      // Taxes routes
      {
        path: "settings/taxes",
        component: TaxSettingsComponent,
        children: [
          // { path: 'rules',  : '/model-list/w/taxRule' },
          {
            path: "",
            redirectTo: "list/s/tax",
            pathMatch: "full",
          },
          {
            path: "list/:detailmode/:model",
            component: FormListMainComponent,
            canActivate: [AuthGuard, AccessRightsGuard],
            canDeactivate: [UnsavedFormListGuard],
            data: {
              accessRightFeatureNames: ["Settings_Settings_Menu_Business_Settings_Taxes"],
              saveFormState: false,
            },
          },
          {
            path: "categories",
            component: TaxCategoriesComponent,
            canDeactivate: [UnsavedFormListGuard],
            canActivate: [AccessRightsGuard],
            data: {
              accessRightFeatureNames: ["Settings_Settings_Menu_Business_Settings_Taxes"],
            },
          },
        ],
      },
      // Purchase Order Term routes
      {
        path: "settings/purchase-order-terms",
        component: PurchaseOrderTermSettingsComponent,
        children: [
          {
            path: "",
            redirectTo: "list/n/paymentTerm",
            pathMatch: "full",
          },
          {
            path: "list/:detailmode/:model",
            component: FormListMainComponent,
            canActivate: [AuthGuard, AccessRightsGuard],
            canDeactivate: [UnsavedFormListGuard],
            data: {
              accessRightFeatureNames: ["Purchasing_Menu_Purchase_Orders"],
            },
          },
        ],
      },
      // user management
      {
        path: "settings/user-management",
        component: UserManagementCardsComponent,
      },
      {
        path: "taxRule/:id",
        component: TaxRuleComponent,
        canActivate: [AuthGuard],
        canDeactivate: [UnsavedChangesGuard],
        resolve: { taxRule: ObjectDetailResolverService },
      },
      {
        path: "tax/:id",
        component: TaxComponent,
        canActivate: [AuthGuard],
        canDeactivate: [UnsavedChangesGuard],
        resolve: { tax: ObjectDetailResolverService },
      },
      {
        path: "accounts/personal/model-list/:detailmode/:model",
        component: FormListMainComponent,
        canActivate: [AuthGuard, AccessRightsGuard],
        canDeactivate: [UnsavedFormListGuard],
        data: {
          accessRightFeatureNames: ["Accounts_Menu_Personal_Accounts"],
        },
      },
      {
        path: "accounts/commercial/model-list/:detailmode/:model",
        component: FormListMainComponent,
        canActivate: [AuthGuard, AccessRightsGuard],
        canDeactivate: [UnsavedFormListGuard],
        data: {
          accessRightFeatureNames: ["Accounts_Menu_Commercial_Accounts"],
        },
      },
      // {
      //   path: 'inventory-list/model-list/:detailmode/:model',
      //   component: FormListMainComponent,
      //   canActivate: [AuthGuard, AccessRightsGuard],
      //   canDeactivate: [UnsavedFormListGuard],
      //   data: {
      //     accessRightFeatureNames: ['Inventory_Menu_Inventory_List']
      //   }
      // },
      {
        path: "model-list/:detailmode/:model",
        component: FormListMainComponent,
        canActivate: [AuthGuard],
        canDeactivate: [UnsavedFormListGuard],
      },
      {
        path: "model-list/:detailmode/:model/:filter",
        component: FormListMainComponent,
        canActivate: [AuthGuard],
        canDeactivate: [UnsavedFormListGuard],
      },

      /*******************/
      /* Integrations */
      /*****************/
      {
        path: "integrations/all",
        component: IntegrationSettingsCardsComponent,
        // data: {
        //   showOnlyConnected: false,
        //   pageTitle: 'Browse Apps',
        // }
      },
      // {
      //   path: 'integrations/my-apps/:storeId',
      //   component: IntegrationSettingsCardsComponent,
      //   data: {
      //     showOnlyConnected: true,
      //     pageTitle: 'My Apps',
      //   }
      // },
      {
        path: "integrations/my-apps/sale-channel",
        component: SaleChannelContainerComponent,
        children: [
          // TAKU eCommerce
          {
            path: "settings/:storeId",
            component: SaleChannelSettingsComponent,
            canActivate: [AccessRightsGuard, SaleChannelGuard],
            data: {
              accessRightFeatureNames: ["Apps_Menu_Taku_eCommerce"],
            },
          },
          // Shopify
          // WooCommerce
          // Facebook
          // Instagram
          // Amazon
        ],
      },
      // Taku Pay
      {
        path: "integrations/my-apps/takupay",
        component: TakuPayComponent,
      },
      {
        path: "integrations/my-apps/takuPaymentAccount/:id",
        component: TakuPayAccountDetailsComponent,
        resolve: { takuPaymentAccount: ObjectDetailResolverService },
        canActivate: [AuthGuard],
        canDeactivate: [UnsavedChangesGuard],
      },
      {
        path: "takuPaymentGateway/:id",
        component: TakuPayLocationDetailComponent,
        resolve: { takuPaymentGateway: ObjectDetailResolverService },
      },
      {
        path: "integrations/my-apps/takupay/model-list/:detailmode/:model",
        component: TakuPayFormListComponent,
      },
      {
        path: "integrations/my-apps/taku-payment-gateways/list/:paymentGatewayType/:gatewayStoreId",
        component: TakuPaymentGatewaysListComponent,
        canActivate: [AuthGuard],
        canDeactivate: [UnsavedFormListGuard],
      },
      {
        path: "integrations/my-apps/taku-payment-gateways/terminals/:paymentGatewayId/:gatewayName/:gatewayStoreId/:takuPaymentGatewayId",
        component: TakuPaymentGatewayTerminalsComponent,
      },
      {
        path: "takupay/takuPaymentTerminal/:id",
        component: TakuPayTerminalComponent,
        canDeactivate: [UnsavedChangesGuard],
        resolve: { takuPaymentTerminal: ObjectDetailResolverService },
      },
      // Payment Gateways
      {
        path: "integrations/my-apps/payment-gateways/terminals/:paymentGatewayId/:gatewayName/:gatewayStoreId",
        component: GatewayTerminalsComponent,
      },
      {
        path: "integrations/my-apps/payment-gateways/list/:paymentGatewayType/:gatewayStoreId",
        component: PaymentGatewaysListComponent,
        canActivate: [AuthGuard],
        canDeactivate: [UnsavedFormListGuard],
      },
      {
        path: "eConduit/paymentTerminal/:id",
        component: EconduitTerminalComponent,
        canDeactivate: [UnsavedChangesGuard],
        resolve: { paymentTerminal: ObjectDetailResolverService },
      },
      {
        path: "Moneris/paymentTerminal/:id",
        component: MonerisTerminalComponent,
        canDeactivate: [UnsavedChangesGuard],
        resolve: { paymentTerminal: ObjectDetailResolverService },
      },
      // Google shopping landing/main page
      {
        path: "integrations/my-apps/google-shopping",
        component: GoogleShoppingMainComponent,
        children: [
          // Google shopping Accounts listing page
          {
            path: "settings",
            component: GMCAccountsComponent,
            canActivate: [AccessRightsGuard],
            data: {
              accessRightFeatureNames: ["Apps_Menu_Google_Shopping_Card"],
            },
          },
          // Google shopping products page
          {
            path: "products/:storeId",
            component: GoogleShoppingProductsComponent,
            canActivate: [AccessRightsGuard],
            data: {
              accessRightFeatureNames: ["Apps_Menu_Google_Shopping_Card"],
            },
          },
          {
            path: "products",
            component: GoogleShoppingProductsComponent,
            canActivate: [AccessRightsGuard],
            data: {
              accessRightFeatureNames: ["Apps_Menu_Google_Shopping_Card"],
            },
          },
        ],
      },
      // GMB Accounts List
      {
        path: "integrations/my-apps/gmb/main",
        component: GmbAccountsListComponent,
        canActivate: [AccessRightsGuard],
        data: {
          accessRightFeatureNames: ["Apps_Menu_Google_My_Business_(GMB)_Card"],
        },
      },
      // GMB Locations List
      {
        path: "integrations/my-apps/gmb/locations/:gmbAccountId",
        component: GmbLocationsListComponent,
        canActivate: [AccessRightsGuard],
        data: {
          accessRightFeatureNames: ["Apps_Menu_Google_My_Business_(GMB)_Card"],
        },
      },

      { path: "**", component: PageNotFoundComponent, canActivate: [AuthGuard] },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {
  // public constructor(private router: Router) {
  //   this.applyRoutesForScreen();
  //   this.router.events.subscribe(event => {
  //     if (event instanceof NavigationEnd) {
  //       this.applyRoutesForScreen();
  //     }
  //   });
  // }
  // applyRoutesForScreen(){
  //   if (WebHelpers.isMobileScreen()) {
  // // This is causing issues with state of active routes
  //     this.router.resetConfig(mobile_routes);
  //   } else {
  //     this.router.resetConfig(routes);
  //   }
  // }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
