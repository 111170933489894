/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { DBService } from "../../../../shared/services/db.service";
import { FormListModel } from "../../../../form-list/form-list/form-list-model.interface";
import { ValidatorFn, Validators } from "@angular/forms";
import { Injectable } from "@angular/core";
import { DataType, Col, FilterType, AutocompleteDatatypeOptions } from "../../../../form-list/form-list/form-list";
import { Observable, of } from "rxjs";
import { SelectItem } from "primeng/api";
import { TimeZoneAutocompleteTransformer } from "../../shared/time-zone-autocomplete-input/time-zone-autocomplete-transformer";
import { LocationService } from "src/app/shared/services/location.service";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "src/app/shared/services/auth.service";

@Injectable({
  providedIn: "root",
})
export class BackofficeService extends DBService implements FormListModel {
  timeZones: string[] = [];

  lookup_zones(): Observable<SelectItem[]> {
    return this.lookupSelectOptions("zone", "zoneName", { enableFieldName: "isActive" });
  }

  constructor(http: HttpClient, authService: AuthService, private locationService: LocationService) {
    super(http, authService);
  }

  get_enumCountries(): SelectItem[] {
    return this.locationService.lookupCountryOptions_full();
  }

  getValidationRules(): { [key: string]: ValidatorFn[] | {} } {
    return {
      isActive: [Validators.required],
      backOfficeName: [Validators.required],
      backOfficeID: [Validators.required],
      zoneId: [Validators.required],
      backOfficeTimeZone: [Validators.required],
    };
  }

  searchTimeZone(event): Observable<string[]> {
    // return all times zones that contain the query as a substring.
    return of(
      this.timeZones.filter((timeZone) => {
        return timeZone.toLocaleLowerCase().includes(event.query.toLocaleLowerCase());
      })
    );
  }

  getFormListColumns() {
    return [
      {
        field: "isActive",
        header: "Active",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.checkbox,
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.enum,
      },
      {
        field: "backOfficeID",
        header: "Backoffice ID",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "backOfficeName",
        header: "Backoffice Name",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "zoneId",
        fieldValue: "zone.zoneName",
        header: "Zone Name",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.lookup,
        dataOptions: this.lookup_zones(),
        filterType: FilterType.contains,
        sortFields: ["zone.zoneName"],
      },
      {
        field: "backOfficeTimeZone",
        header: "Time Zone",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.autocomplete,
        dataOptions: [],
        filterType: FilterType.contains,
        dataTypeOptions: new AutocompleteDatatypeOptions({
          completeMethod: this.searchTimeZone.bind(this),
          transformer: new TimeZoneAutocompleteTransformer(),
        }),
        colWidth: 300,
        showValidationErrors: true,
      },
      {
        field: "address.countryIsoCode",
        header: "Country",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.get_enumCountries(),
        filterType: FilterType.enum,
      }, // { field: 'storeNumber', header: 'Store Number', visible: true, readonly: false, frozen: false, dataType: DataType.input, dataOptions: [], filterType: FilterType.contains },
      //{ field: '', header: '# of Stations', visible: true, readonly: true, frozen: false, dataType: DataType.input, dataOptions: [], filterType: FilterType.equals },
    ];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
