/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ValidatorFn } from "@angular/forms";
import { ArrayDatatypeOptions, Col, DataType, FilterType } from "../../../form-list/form-list/form-list";
import { FormListModel } from "../../../form-list/form-list/form-list-model.interface";
import { AuthService } from "../../../shared/services/auth.service";
import { CurrencyFormatterService } from "../../../shared/services/currency-formatter.service";
import { DBService } from "../../../shared/services/db.service";
import { AccountRelationship, AccountStatus, AccountType } from "../account/account";
import { AccountComponent } from "../account/account.component";
import { GenderType, TitleType } from "../person/person";
import { PersonComponent } from "../person/person.component";
import { PersonalAccount } from "./personal-account";
import { AccountService } from "../account/account.service";
import { SaleChannelService } from "../../sale-channel/sale-channel.service";
import { EmailArrayComponent } from "../email-array/email-array.component";
import { PhoneArrayComponent } from "../phone-array/phone-array.component";
import { CommonValidators } from "src/app/shared/validators/CommonValidators";

@Injectable({
  providedIn: "root",
})
export class PersonalAccountService extends DBService implements FormListModel {
  _model = "personalAccount";
  enumAccountStatus = this.enumSelectOptions(AccountStatus);
  enumGenderType = this.enumSelectOptions(GenderType);
  enumCurrencies = this.currencyService.getCurrencyCodeList();
  enumTitleType = this.enumSelectOptions(TitleType);
  enumAccountRelationship = this.enumSelectOptions(AccountRelationship);

  constructor(
    private currencyService: CurrencyFormatterService,
    protected http: HttpClient,
    protected authService: AuthService,
    private accountService: AccountService,
    private saleChannelService: SaleChannelService
  ) {
    super(http, authService);
  }

  afterFetch(personalAccount: PersonalAccount, model: string) {
    switch (model) {
      case this._model:
        const account = personalAccount.account;
        if (account.defaultCurrencyIsoCode)
          account.creditLimit = this.currencyService.formatMonetaryAmount({
            currencyIsoCode: account.defaultCurrencyIsoCode,
            amount: account.creditLimit,
          });
        break;
    }
  }

  getValidationRules(): { [key: string]: ValidatorFn[] | {} } {
    return {
      consent: [CommonValidators.boolean_value()],
      consentDateTime: [CommonValidators.dateTimeValidator],
      person: PersonComponent.validators,
      account: AccountComponent.validators,
    };
  }

  getFormListColumns(): Col[] {
    const firstNameVisible: boolean = this.authService.hasVisiblePermit("Accounts_Menu_Personal_Accounts_General_Tab");
    const firstNameEnabled: boolean = this.authService.hasEnablePermit("Accounts_Menu_Personal_Accounts_General_Tab");

    const lastNameVisible: boolean = this.authService.hasVisiblePermit("Accounts_Menu_Personal_Accounts_General_Tab");
    const lastNameEnabled: boolean = this.authService.hasEnablePermit("Accounts_Menu_Personal_Accounts_General_Tab");

    const activeVisible: boolean = this.authService.hasVisiblePermit("Accounts_Menu_Personal_Accounts_Status");
    const activeEnabled: boolean = this.authService.hasEnablePermit("Accounts_Menu_Personal_Accounts_Status");

    const taxIdVisible: boolean = this.authService.hasVisiblePermit("Accounts_Menu_Personal_Accounts_Tax ID");
    const taxIdEnabled: boolean = this.authService.hasEnablePermit("Accounts_Menu_Personal_Accounts_Tax ID");

    const taxCategoryVisible: boolean = this.authService.hasVisiblePermit(
      "Accounts_Menu_Personal_Accounts_Tax_Category"
    );
    const taxCategoryEnabled: boolean = this.authService.hasEnablePermit(
      "Accounts_Menu_Personal_Accounts_Tax_Category"
    );

    const creditLimitVisible: boolean = this.authService.hasVisiblePermit(
      "Accounts_Menu_Personal_Accounts_Credit_Limit"
    );
    const creditLimitEnabled: boolean = this.authService.hasEnablePermit(
      "Accounts_Menu_Personal_Accounts_Credit_Limit"
    );

    const currencyVisible: boolean = this.authService.hasVisiblePermit("Accounts_Menu_Personal_Accounts_Credit_Limit");
    const currencyEnabled: boolean = this.authService.hasEnablePermit("Accounts_Menu_Personal_Accounts_Credit_Limit");

    const titleVisible: boolean = this.authService.hasVisiblePermit("Accounts_Menu_Personal_Accounts_General_Tab");
    const titleEnabled: boolean = this.authService.hasEnablePermit("Accounts_Menu_Personal_Accounts_General_Tab");

    const middleNameVisible: boolean = this.authService.hasVisiblePermit("Accounts_Menu_Personal_Accounts_General_Tab");
    const middleNameEnabled: boolean = this.authService.hasEnablePermit("Accounts_Menu_Personal_Accounts_General_Tab");

    const jobTitleVisible: boolean = this.authService.hasVisiblePermit("Accounts_Menu_Personal_Accounts_General_Tab");
    const jobTitleEnabled: boolean = this.authService.hasEnablePermit("Accounts_Menu_Personal_Accounts_General_Tab");

    const genderVisible: boolean = this.authService.hasVisiblePermit("Accounts_Menu_Personal_Accounts_General_Tab");
    const genderEnabled: boolean = this.authService.hasEnablePermit("Accounts_Menu_Personal_Accounts_General_Tab");

    const birthdayVisible: boolean = this.authService.hasVisiblePermit("Accounts_Menu_Personal_Accounts_General_Tab");
    const birthdayEnabled: boolean = this.authService.hasEnablePermit("Accounts_Menu_Personal_Accounts_General_Tab");

    const lookup_taxAccountCategory = this.lookupSelectOptions("taxAccountCategory", "category", {
      enableFieldName: "isEnabled",
    });

    return [
      ...(activeVisible
        ? [
            {
              field: "account.isEnabled",
              header: "Active",
              visible: true,
              readonly: !activeEnabled,
              frozen: true,
              dataType: DataType.checkbox,
              dataOptions: Col.boolean_filter_enum,
              filterType: FilterType.enum,
              colWidth: 120,
            },
          ]
        : []),
      // { field: 'account.status', header: 'Status', visible: true, readonly: false, frozen: false, dataType: DataType.enum, dataOptions: this.enumAccountStatus, filterType: FilterType.enum, colWidth: 120 },
      ...(firstNameVisible
        ? [
            {
              field: "person.firstName",
              header: "First Name",
              importingOptions: { header: "FULL NAME First Name" },
              visible: true,
              readonly: !firstNameEnabled,
              frozen: true,
              dataType: DataType.input,
              dataOptions: [],
              filterType: FilterType.contains,
              colWidth: 160,
            },
          ]
        : []),
      ...(lastNameVisible
        ? [
            {
              field: "person.lastName",
              header: "Last Name",
              importingOptions: { header: "FULL NAME Last Name" },
              visible: true,
              readonly: !lastNameEnabled,
              frozen: true,
              dataType: DataType.input,
              dataOptions: [],
              filterType: FilterType.contains,
              colWidth: 160,
            },
          ]
        : []),
      {
        field: "account.accountRelationship",
        header: "Account Type",
        visible: true,
        readonly: false,
        frozen: true,
        dataType: DataType.enum,
        dataOptions: this.enumAccountRelationship,
        filterType: FilterType.enum,
        colWidth: 150,
      },
      ...(titleVisible
        ? [
            {
              field: "person.title",
              header: "Title",
              importingOptions: { header: "FULL NAME Title", colWidth: 140 },
              visible: false,
              readonly: !titleEnabled,
              frozen: false,
              dataType: DataType.enum,
              dataOptions: this.enumTitleType,
              filterType: FilterType.none,
              colWidth: 120,
            },
          ]
        : []),
      ...(middleNameVisible
        ? [
            {
              field: "person.middleName",
              header: "Middle Name",
              visible: false,
              readonly: !middleNameEnabled,
              frozen: false,
              dataType: DataType.input,
              dataOptions: [],
              filterType: FilterType.contains,
            },
          ]
        : []),
      ...(jobTitleVisible
        ? [
            {
              field: "person.jobTitle",
              header: "Job Title",
              visible: false,
              readonly: !jobTitleEnabled,
              frozen: false,
              dataType: DataType.input,
              dataOptions: [],
              filterType: FilterType.contains,
            },
          ]
        : []),
      ...(genderVisible
        ? [
            {
              field: "person.gender",
              header: "Gender",
              visible: false,
              readonly: !genderEnabled,
              frozen: false,
              dataType: DataType.enum,
              dataOptions: this.enumGenderType,
              filterType: FilterType.none,
              colWidth: 130,
            },
          ]
        : []),
      ...(birthdayVisible
        ? [
            {
              field: "person.birthData",
              header: "Birthday",
              visible: false,
              readonly: !birthdayEnabled,
              frozen: false,
              dataType: DataType.date_date_only,
              dataOptions: [],
              filterType: FilterType.none,
            },
          ]
        : []),
      {
        field: "person.personPhones",
        header: `Phones`,
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.array,
        dataTypeOptions: new ArrayDatatypeOptions({
          component: PhoneArrayComponent,
          displayField: "tel",
          showSummary: true,
        }),
        dataOptions: [],
        filterType: FilterType.contains,
        filterField: "person.personPhones.tel",
        isNotSortable: true,
        sortFields: ["person.personPhones.tel"],
      },
      {
        field: "person.personEmails",
        header: `Emails`,
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.array,
        dataTypeOptions: new ArrayDatatypeOptions({
          component: EmailArrayComponent,
          displayField: "email",
          showSummary: true,
        }),
        dataOptions: [],
        filterType: FilterType.contains,
        filterField: "person.personEmails.email",
        isNotSortable: true,
        sortFields: ["person.personPhones.email"],
      },
      {
        field: "account.accountCode",
        header: "Account Code",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 150,
      },
      {
        field: "account.acctGroups",
        fieldValue: "account.acctGroupsList",
        header: "Account Groups",
        visible: true,
        importingOptions: { header: "Account Groups" },
        readonly: false,
        frozen: false,
        dataType: DataType.multiselect,
        dataOptions: this.accountService.lookupAccountGroup(AccountType.personal),
        filterType: FilterType.multiselect,
        isNotSortable: true,
      },
      {
        field: "consent",
        header: "Consent",
        visible: false,
        readonly: true,
        frozen: false,
        dataType: DataType.checkbox,
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.enum,
        colWidth: 120,
      },
      {
        field: "consentDateTime",
        header: "Consent Time Stamp",
        visible: false,
        readonly: true,
        frozen: false,
        dataType: DataType.date_date_time,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 120,
      },
      {
        field: "account.channelTypes",
        header: "Channel Types",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enumMultiselect,
        dataKey: null,
        filterDataKey: null,
        dataOptions: this.saleChannelService.enumChannelTypes(),
        filterType: FilterType.enum,
        filterOptions: this.saleChannelService.enumChannelTypes(),
        filterMatchMode: "contains",
        filterField: "`account.channelTypes`",
        colWidth: 250,
      },
      ...(taxCategoryVisible
        ? [
            {
              field: "account.taxAccountCategoryId",
              fieldValue: "account.taxAccountCategory.category",
              header: "Tax Category",
              visible: false,
              readonly: !taxCategoryEnabled,
              frozen: false,
              dataType: DataType.lookup,
              dataOptions: lookup_taxAccountCategory,
              filterType: FilterType.none,
              sortFields: ["account.taxAccountCategory.category"],
            },
          ]
        : []),
      ...(currencyVisible
        ? [
            {
              field: "account.defaultCurrencyIsoCode",
              header: "Currency",
              importingOptions: { header: "CREDIT LIMIT Currency", colWidth: 155 },
              visible: false,
              readonly: !currencyEnabled,
              frozen: false,
              dataType: DataType.enum,
              dataOptions: this.enumCurrencies,
              filterType: FilterType.none,
            },
          ]
        : []),
      ...(creditLimitVisible
        ? [
            {
              field: "account.creditLimit",
              header: "Credit Limit",
              importingOptions: { header: "CREDIT LIMIT Amount", colWidth: 165 },
              visible: true,
              readonly: !creditLimitEnabled,
              frozen: false,
              dataType: DataType.number,
              dataOptions: [],
              filterType: FilterType.contains,
              colWidth: 140,
            },
          ]
        : []),
      {
        field: "account.tags",
        header: `Tags`,
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.chips,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "account.note",
        header: `Internal Notes`,
        visible: false,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 300,
      },
    ];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
