<taku-form-header
  [formGroup]="_myForm"
  (backClicked)="goBack()"
  (saveClicked)="onSave()"
  (revertClicked)="onRevert()"
  (newClicked)="onNew()"
  (deleteClicked)="onDelete()"
  [toolbarTitle]="'TAKU Pay'"
  [isSaving]="_isSaving"
  [showActionBtns]="_isShowActionBtn"
>
  <div
    *ngIf="
      !(
        !isSuperAdmin ||
        _myForm.get('externalAccountId').value !== '' ||
        _myForm.get('id').value === 0 ||
        _myForm.dirty
      )
    "
  >
    <button
      pButton
      label="APPROVE & CREATE"
      [disabled]="
        !isSuperAdmin || _myForm.get('externalAccountId').value !== '' || _myForm.get('id').value === 0 || _myForm.dirty
      "
      (click)="createTakuPayAccount(_myForm.get('id').value)"
      [loading]="_creatingAccountDetail"
    >
    </button>
  </div>
</taku-form-header>
<div class="col-12 pl-0 pr-0">
  <div [formGroup]="_myForm" class="grid">
    <div class="col-12">
      <p-panel>
        <ng-template pTemplate="header">
          <h3>Account Details</h3>
        </ng-template>

        <div class="grid">
          <div class="col-12 lg:col-6 grid">
            <div class="col">
              <taku-input [formGroup]="_myForm" [fieldName]="'name'" [fieldCaption]="'Company Name'"> </taku-input>
            </div>
            <span class="align-self-center col-fixed" style="width: 30px">
              <a href="javascript://" class="pi pi-info-circle" (click)="companyNamePanel.toggle($event)">
                <p-overlayPanel #companyNamePanel [showCloseIcon]="false" appendTo="body">
                  <div class="tooltip-styling">
                    <h5>NOTE:</h5>
                    <ul>
                      <li>This should be your official company name</li>
                    </ul>
                  </div>
                </p-overlayPanel>
              </a>
            </span>
          </div>
          <div class="col-12 lg:col-6 grid">
            <div class="col">
              <taku-input
                [formGroup]="_myForm"
                [fieldName]="'statementDescriptor'"
                [fieldCaption]="'Statement Descriptor'"
              >
              </taku-input>
            </div>
            <span class="align-self-center info-icon col-fixed" style="width: 30px">
              <a href="javascript://" class="pi pi-info-circle" (click)="statementDescriptorPanel.toggle($event)">
                <p-overlayPanel #statementDescriptorPanel [showCloseIcon]="false" appendTo="body">
                  <div class="tooltip-styling">
                    <h5>NOTE:</h5>
                    <ul>
                      <li
                        >This is the statement descriptor that will show up on the customers credit card statement. It
                        will be truncated to 22 characters</li
                      >
                    </ul>
                  </div>
                </p-overlayPanel>
              </a>
            </span>
          </div>
          <div class="col-12 lg:col-6 grid">
            <div class="col">
              <taku-dropdown
                [formGroup]="_myForm"
                [options]="mccList"
                fieldName="mcc"
                fieldCaption="MCC Code"
                placeholder="MCC Code"
              ></taku-dropdown>
            </div>
            <span class="align-self-center info-icon col-fixed" style="width: 30px">
              <a href="javascript://" class="pi pi-info-circle" (click)="mccCodePanel.toggle($event)">
                <p-overlayPanel #mccCodePanel [showCloseIcon]="false" appendTo="body">
                  <div class="tooltip-styling">
                    <h5>NOTE:</h5>
                    <ul>
                      <li>The MCC code needs to be set for your type of business</li>
                    </ul>
                  </div>
                </p-overlayPanel>
              </a>
            </span>
          </div>
          <div class="col-12 lg:col-6 grid">
            <div class="col">
              <taku-country [formGroup]="_myForm" fieldName="countryIsoCode" fieldCaption="Country"> </taku-country>
            </div>
            <span class="align-self-center info-icon col-fixed" style="width: 30px"></span>
          </div>
          <div class="col-12 grid-nogutter">
            <div class="col-12">
              <h6>Full Name</h6>
            </div>
            <div class="col-12 grid">
              <div class="col-12 lg:col-6 grid">
                <div class="col">
                  <taku-input [formGroup]="_myForm" [fieldName]="'userFirstName'" [fieldCaption]="'First Name'">
                  </taku-input>
                </div>
                <span class="align-self-center info-icon col-fixed" style="width: 18px"></span>
              </div>

              <div class="col-12 lg:col-6 grid">
                <div class="col last-name">
                  <taku-input [formGroup]="_myForm" [fieldName]="'userLastName'" [fieldCaption]="'Last Name'">
                  </taku-input>
                </div>
                <span class="align-self-center info-icon col-fixed" style="width: 18px"></span>
              </div>
            </div>
          </div>
          <div class="col-12 grid-nogutter">
            <div class="col-12">
              <h6>Email Address</h6>
            </div>
            <div class="col-12 lg:col-6 grid">
              <div class="col">
                <taku-input [formGroup]="_myForm" [fieldName]="'userEmail'" [fieldCaption]="'Email'"></taku-input>
              </div>
              <div class="align-self-center col-fixed" style="width: 35px">
                <a href="javascript://" class="pi pi-info-circle" (click)="emailPanel.toggle($event)">
                  <p-overlayPanel #emailPanel [showCloseIcon]="false" appendTo="body">
                    <div class="tooltip-styling">
                      <h5>NOTE:</h5>
                      <ul>
                        <li
                          >This is needed so TakuPay can email you instructions for logging into the Virtual Terminal.
                        </li>
                        <li
                          >The email will also include instructions for getting the account update link for activating
                          your account</li
                        >
                      </ul>
                    </div>
                  </p-overlayPanel>
                </a>
              </div>
            </div>
          </div>
          <div class="col-12 grid-nogutter">
            <div class="col-12">
              <h6>Business Address</h6>
            </div>
            <taku-address
              [formGroup]="_myForm.get('address')"
              [autosetPhoneCountry]="true"
              [showIsDefaultControl]="false"
              [showAttentionControl]="false"
              [showLocationTypeControl]="false"
              [showNoteControl]="false"
              [showEmailControl]="false"
              [showPhoneCopyControl]="false"
            >
            </taku-address>
          </div>
        </div>
      </p-panel>
    </div>
  </div>
</div>
