/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component } from "@angular/core";
import { NestedFormComponent } from "../../../forms/nested-form/nested-form.component";
import { CommercialEmailType, CommercialAccountEmail } from "./commercial-account-email";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ACTION_TYPE } from "src/app/shared/components/action-button/action-type.enum";

@Component({
  selector: "taku-commercial-account-email",
  templateUrl: "./commercial-account-email.component.html",
  styleUrls: ["./commercial-account-email.component.scss"],
})
export class CommercialAccountEmailComponent extends NestedFormComponent {
  public ACTION_TYPE: typeof ACTION_TYPE = ACTION_TYPE;

  // Define enum variables
  // enum_commecrial_phone_type = this.dbService.enumSelectOptions(CommercialAccountPhoneType, 'Type');
  enum_commecrial_email_type = this.dbService.enumSelectOptions(CommercialEmailType);

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    const commercialAccountEmail_Group = fb.group(new CommercialAccountEmail());
    return commercialAccountEmail_Group;
  }

  static setArray(fb: UntypedFormBuilder, commercialAccountEmails: CommercialAccountEmail[]) {
    const formArray = fb.array([]);
    commercialAccountEmails.map((commercialAccountEmail) => {
      const temp = fb.group(commercialAccountEmail);
      formArray.push(temp);
    });
    return formArray;
  }

  initValidation() {
    this._validation = {
      email: Validators.email,
    };
  }

  copyEmailToClipboard() {
    this.copyValueToClipboard(this.emailCtrl.value);
  }

  get emailCtrl() {
    return this._myForm.get("email");
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
