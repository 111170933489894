<div [formGroup]="_myForm">
  <div class="grid">
    <div class="col grid">
      <div class="col-12 status-container">
        <taku-dropdown
          [disabled]="disableForm"
          [formGroup]="_myForm"
          [options]="enum_person_instant_msg_type"
          [fieldName]="'instantMsgType'"
          [fieldCaption]="'IM Type'"
        >
        </taku-dropdown>
      </div>
      <div class="col-12">
        <taku-input
          [disabled]="disableForm"
          [formGroup]="_myForm"
          [fieldName]="'instantMsg'"
          [fieldCaption]="'Instant Msg'"
        >
        </taku-input>
      </div>
    </div>
    <div class="col-fixed copy-icon-container">
      <taku-action-button [action]="ACTION_TYPE.COPY" (clickAction)="copyInstantMsgToClipboard()"></taku-action-button>
    </div>
  </div>
</div>
