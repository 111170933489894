/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, Inject, LOCALE_ID, OnDestroy } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import {
  CalendarOverlayPosition,
  CalendarDateRangeType,
} from "../taku-ui/taku-calendar-range/taku-calendar-range.component";
import { TimeHelpers } from "../utility/TimeHelpers";
import { DashboardDBService } from "./DashboardDBService";
import { AppSettingsStorageService } from "../shared/app-settings-storage.service";
import * as _ from "lodash";
import { zip, of, Observable, Subscription } from "rxjs";
import { Cashout } from "../core/cashout/cashout/cashout";
import { User } from "../core/users/user/user";
import * as moment from "moment";
import { CurrencyPipe } from "@angular/common";
import { SelectItem } from "primeng/api";
import { AuthService } from "../shared/services/auth.service";
import { SaleDocType } from "../core/document/doc/doc";
import type { ChartOptions } from "chart.js";

type DashboardFormData = {
  storeId: number;
  stationId: number;
  dateRangeType: any;
  dateFrom: Date;
  dateTo: Date;
};

export type TopInventoryStats = {
  inventoryId: number;
  description: string;
  netSale: number;
  totalQty: number;
  description1: string;
};

export type SalesPerHourStats = {
  hour: string;
  subTotal: number;
  discountAmount: number;
  TotalSales: number;
};

export type SalesSummaryStats = {
  TotalSales: number;
  discountAmount: number;
  docType: SaleDocType;
  docsCount: number;
  qty: number;
  subTotal: number;
};

// type VariationSingleItem = {
//   total: number;
//   percentageDelta: number;
// }

// type VariationStats = {
//   sales: VariationSingleItem;
//   returns: VariationSingleItem;
//   discounts: VariationSingleItem;
//   transactions: VariationSingleItem;
//   basketValue: VariationSingleItem;
//   basketSize: VariationSingleItem;
// }

type DocsStats = {
  sales: number;
  returns: number;
  // This field has been replaced with Net Sales for now
  // discounts: number;
  netSales: number;
  transactions: number;
  basketValue: number;
  basketSize: number;
};

type VariationStats = {
  totals: DocsStats;
  percentageDeltas: DocsStats;
};

@Component({
  selector: "taku-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];

  // visibility flags
  isCashoutVisible = false;
  isSalesByHourVisible = false;
  isSalesReturnsDiscountsVisible = false;
  isTopSellingInventoryVisible = false;
  isTransactionsBasketValueSizeVisible = false;
  isStationDropDownVisible = false;
  isDateRangeVisible = false;
  isCashoutEnabled = false;
  isSalesByHourEnabled = false;
  isSalesReturnsDiscountsEnabled = false;
  isTopSellingInventoryEnabled = false;
  isTransactionsBasketValueSizeEnabled = false;
  isStationDropDownEnabled = false;
  isDateRangeEnabled = false;

  salesData: any;
  topInventory: TopInventoryStats[] = [];
  _summaryData: VariationStats;
  dashboardForm: UntypedFormGroup;
  isCashoutOpen = false;
  CalendarOverlayPosition = CalendarOverlayPosition;
  _currencyFormat = "1.2-2";
  _decimalsFormat = "1.0-2";
  _digitsFormat = "1.2-2";
  _currencyIsoCode?: string;
  _cashout: Cashout;
  _loggedinUser: User;
  _cashoutUser: User;
  _cashoutDate: Date;
  _cashoutTime: string;
  _chartOptions: ChartOptions<"bar"> = {};
  lookup_stations$ = this.dashboardDBService.lookup_stations();
  // list of stores. this is used in admin mode to display a dropdown of stores in the dashboard page.
  lookup_stores$: Observable<SelectItem[]> = of([]);
  isAdmin: boolean;
  storeChanged = false;

  constructor(
    private fb: UntypedFormBuilder,
    private dashboardDBService: DashboardDBService,
    private appSettingsService: AppSettingsStorageService,
    @Inject(LOCALE_ID) private localeID,
    private authService: AuthService
  ) {
    const currencyPipe = new CurrencyPipe(this.localeID);
    this._chartOptions = {
      // TODO Fix tooltip configuration for charts v3
      // tooltips: {
      //   callbacks: {
      //     label: (tooltipItem, data) => {
      //       let label = data.datasets[tooltipItem.datasetIndex].label || '';

      //       if (label) {
      //         label += ': ';
      //       }
      //       label += currencyPipe.transform(tooltipItem.yLabel, this._currencyIsoCode, 'symbol-narrow', this._currencyFormat);
      //       return label;
      //     }
      //   }
      // },
      scales: {
        y: {
          ticks: {
            callback: (value, index, values) => {
              value = value.toString();
              value = currencyPipe.transform(value, this._currencyIsoCode, "symbol-narrow", "1.0");
              return value;
            },
          },
        },
      },
    };

    const activeStore = this.appSettingsService.getStore();
    this.isAdmin = !activeStore.id;
    this.subsList.push(
      this.appSettingsService.activeStoreChanged$.subscribe((store) => {
        this.isAdmin = !store.id;
      })
    );

    // if we are in admin mode, we need to display a drop down with a list of the stores.
    if (this.isAdmin) this.lookup_stores$ = this.dashboardDBService.lookup_stores();
  }

  ngOnInit() {
    this._currencyIsoCode = this.appSettingsService.getZone().defaultCurrencyIsoCode;
    this._loggedinUser = this.appSettingsService.getUser();
    const currStation = this.appSettingsService.getStation();
    this._cashout = this.appSettingsService.getCashout();
    if (this._cashout) {
      if (this._cashout.isClosed) {
        this._cashoutUser = this._cashout.closingCashFloatUser;
        this._cashoutDate = this._cashout.closingDate;
        this._cashoutTime = this._cashout.closingTime;
      } else {
        this._cashoutUser = this._cashout.openingCashFloatUser;
        this._cashoutDate = this._cashout.openingDate;
        this._cashoutTime = this._cashout.openingTime;
      }
    }

    this.dashboardForm = this.fb.group({
      storeId: this.isAdmin ? -1 : null,
      stationId: currStation ? currStation.id : -1,
      dateRangeType: CalendarDateRangeType.TODAY,
      dateFrom: null,
      dateTo: null,
    } as DashboardFormData);

    const storeId = this.appSettingsService.getStoreId();

    if (this.isAdmin) {
      this.subsList.push(
        this.dashboardForm.valueChanges.subscribe((formData: DashboardFormData) => {
          // When the store changes, we reset the station to "All Stations"
          if (this.storeChanged) {
            formData.stationId = -1;
            this.dashboardForm.controls.stationId.setValue(-1, { emitEvent: false });

            this.storeChanged = false;
            // update the list of stations using the new store id
            this.lookup_stations$ = this.dashboardDBService.lookup_stations(formData.storeId);
          }

          this.updateDashBoard(formData.storeId, formData);
        })
      );

      this.subsList.push(
        this.dashboardForm.controls.storeId.valueChanges.subscribe(() => {
          this.storeChanged = true;
        })
      );
    } else {
      this.subsList.push(
        this.dashboardForm.valueChanges.subscribe((formData: DashboardFormData) => {
          this.updateDashBoard(storeId, formData);
        })
      );
    }

    // visibility flags
    this.isCashoutVisible = this.authService.hasVisiblePermit("Dashboard_Cashout_Section");
    this.isSalesByHourVisible = this.authService.hasVisiblePermit("Dashboard_Sales_by_Hour");
    this.isSalesReturnsDiscountsVisible = this.authService.hasVisiblePermit("Dashboard_Sales_Returns_Discounts");
    this.isTopSellingInventoryVisible = this.authService.hasVisiblePermit("Dashboard_Top_Selling_Inventory");
    this.isTransactionsBasketValueSizeVisible = this.authService.hasVisiblePermit(
      "Dashboard_Transactions_Basket_Value_Size"
    );

    this.isStationDropDownVisible =
      this.isCashoutVisible ||
      this.isSalesByHourVisible ||
      this.isSalesReturnsDiscountsVisible ||
      this.isTopSellingInventoryVisible ||
      this.isTransactionsBasketValueSizeVisible;
    this.isDateRangeVisible = this.isStationDropDownVisible;

    this.isCashoutEnabled = this.authService.hasEnablePermit("Dashboard_Cashout_Section");
    this.isSalesByHourEnabled = this.authService.hasEnablePermit("Dashboard_Sales_by_Hour");
    this.isSalesReturnsDiscountsEnabled = this.authService.hasEnablePermit("Dashboard_Sales_Returns_Discounts");
    this.isTopSellingInventoryEnabled = this.authService.hasEnablePermit("Dashboard_Top_Selling_Inventory");
    this.isTransactionsBasketValueSizeEnabled = this.authService.hasEnablePermit(
      "Dashboard_Transactions_Basket_Value_Size"
    );

    this.isStationDropDownEnabled =
      this.isCashoutEnabled &&
      this.isSalesByHourEnabled &&
      this.isSalesReturnsDiscountsEnabled &&
      this.isTopSellingInventoryEnabled &&
      this.isTransactionsBasketValueSizeEnabled;
    this.isDateRangeEnabled = this.isStationDropDownEnabled;
  }

  updateDashBoard(storeId: number, formData: DashboardFormData) {
    storeId = storeId === -1 ? 0 : storeId;
    formData.stationId = formData.stationId === -1 ? 0 : formData.stationId;

    if (formData.stationId == null || formData.stationId === -1) {
      // if the blank option is selected for station, we want the dashboard to show the default (empty) data.
      this.updateDashBoardDefault();
    } else {
      this.subsList.push(
        this.dashboardDBService
          .getSalesPerHour({
            storeId,
            stationId: formData.stationId,
            dateFrom: formData.dateFrom,
            dateTo: formData.dateTo,
          })
          .subscribe((response) => {
            this.salesData = this.buildSalesDataForChart(response);
          })
      );

      const previousDateRange = TimeHelpers.GetPreviousDateRange(formData.dateFrom, formData.dateTo);
      this.subsList.push(
        zip(
          this.dashboardDBService.getSalesSummary({
            storeId,
            stationId: formData.stationId,
            dateFrom: formData.dateFrom,
            dateTo: formData.dateTo,
          }),
          this.dashboardDBService.getSalesSummary({
            storeId,
            stationId: formData.stationId,
            dateFrom: previousDateRange.dateFrom,
            dateTo: previousDateRange.dateTo,
          })
        ).subscribe(([actualSummaryData, previousSummaryData]) => {
          this._summaryData = this.buildVariationStats(actualSummaryData, previousSummaryData);
        })
      );
      this.subsList.push(
        this.dashboardDBService
          .getTopInventory({
            storeId,
            stationId: formData.stationId,
            dateFrom: formData.dateFrom,
            dateTo: formData.dateTo,
          })
          .subscribe((response) => {
            this.topInventory = response;
          })
      );
    }
  }

  updateDashBoardDefault() {
    this.salesData = this.buildSalesDataForChart([]);
    this._summaryData = this.buildVariationStats([], []);
    this.topInventory = [];
  }

  get isCashoutOpened(): boolean {
    return this._cashout && !this._cashout.isClosed;
  }

  get dateRangeTypeCtrl() {
    return this.dashboardForm.get("dateRangeType");
  }

  get dateFromCtrl() {
    return this.dashboardForm.get("dateFrom");
  }

  get dateToCtrl() {
    return this.dashboardForm.get("dateTo");
  }

  get previousDateRange(): string {
    const currentRange: CalendarDateRangeType = this.dateRangeTypeCtrl.value;
    const previousPredefinedRange = TimeHelpers.GetPreviousRange(currentRange);
    if (previousPredefinedRange) {
      return _.startCase(previousPredefinedRange);
    } else {
      const dateFormat = "YYYY-MM-DD";
      const previousDates = TimeHelpers.GetPreviousDateRange(this.dateFromCtrl.value, this.dateToCtrl.value);
      const [fromDateStr, toDateStr] = [
        moment(previousDates.dateFrom).format(dateFormat),
        moment(previousDates.dateTo).format(dateFormat),
      ];
      return `Period: <br>${fromDateStr} to ${toDateStr}`;
    }
  }

  protected buildVariationStats(
    actualSummaryData: SalesSummaryStats[],
    previousSummaryData: SalesSummaryStats[]
  ): VariationStats {
    const actualTotals = this.extractDocStats(actualSummaryData);
    const previousTotals = this.extractDocStats(previousSummaryData);

    return {
      totals: actualTotals,
      percentageDeltas: {
        sales: this.calculatePercentageDelta(actualTotals.sales, previousTotals.sales),
        returns: this.calculatePercentageDelta(actualTotals.returns, previousTotals.returns),
        // We have replaced discounts with net sales for now
        // discounts: this.calculatePercentageDelta(actualTotals.discounts, previousTotals.discounts),
        netSales: this.calculatePercentageDelta(actualTotals.netSales, previousTotals.netSales),
        transactions: this.calculatePercentageDelta(actualTotals.transactions, previousTotals.transactions),
        basketValue: this.calculatePercentageDelta(actualTotals.basketValue, previousTotals.basketValue),
        basketSize: this.calculatePercentageDelta(actualTotals.basketSize, previousTotals.basketSize),
      },
    };
  }

  private extractDocStats(summaryData): DocsStats {
    const salesSummary = summaryData.find((data) => data.docType === SaleDocType.sales_invoice);
    const onlineSalesDocSummary = summaryData.find((data) => data.docType === SaleDocType.online_sales_order);
    const preOrderDocSummary = summaryData.find((data) => data.docType === SaleDocType.pre_order);
    const recurringOrderDocSummary = summaryData.find((data) => data.docType === SaleDocType.recurring_order);

    const _salesSummary = Object.assign(
      {},
      salesSummary || onlineSalesDocSummary || preOrderDocSummary || recurringOrderDocSummary
    );
    if (_salesSummary) {
      _salesSummary.TotalSales = 0;
      _salesSummary.discountAmount = 0;
      _salesSummary.docsCount = 0;
      _salesSummary.qty = 0;
      _salesSummary.subTotal = 0;

      _salesSummary.TotalSales =
        Number(salesSummary ? salesSummary.TotalSales : 0) +
        Number(onlineSalesDocSummary ? onlineSalesDocSummary.TotalSales : 0) +
        Number(preOrderDocSummary ? preOrderDocSummary.TotalSales : 0) +
        Number(recurringOrderDocSummary ? recurringOrderDocSummary.TotalSales : 0);
      _salesSummary.discountAmount =
        (salesSummary ? salesSummary.discountAmount : 0) +
        (onlineSalesDocSummary ? onlineSalesDocSummary.discountAmount : 0) +
        (preOrderDocSummary ? preOrderDocSummary.discountAmount : 0) +
        (recurringOrderDocSummary ? recurringOrderDocSummary.discountAmount : 0);
      _salesSummary.docsCount =
        (salesSummary ? salesSummary.docsCount : 0) + (onlineSalesDocSummary ? onlineSalesDocSummary.docsCount : 0);
      _salesSummary.qty =
        (salesSummary ? salesSummary.qty : 0) +
        (onlineSalesDocSummary ? onlineSalesDocSummary.qty : 0) +
        (preOrderDocSummary ? preOrderDocSummary.qty : 0) +
        (recurringOrderDocSummary ? recurringOrderDocSummary.qty : 0);
      _salesSummary.subTotal =
        Number(salesSummary ? salesSummary.subTotal : 0) +
        Number(onlineSalesDocSummary ? onlineSalesDocSummary.subTotal : 0) +
        +Number(preOrderDocSummary ? preOrderDocSummary.subTotal : 0) +
        +Number(recurringOrderDocSummary ? recurringOrderDocSummary.subTotal : 0);
    }

    const returnsSummary = summaryData.find((data) => data.docType === SaleDocType.sales_return);
    const docsCountTotal = _salesSummary ? _salesSummary.docsCount : 0;
    // const docsCountTotal = _.sum(summaryData.map(data => data.docsCount));

    const salesVal = _salesSummary ? _salesSummary.TotalSales : 0;
    const returnsVal = returnsSummary ? returnsSummary.TotalSales : 0;

    return {
      sales: salesVal,
      returns: returnsVal,
      // We have replaced discount with net sales for now
      // discounts: salesSummary ? salesSummary.discountAmount : null,
      netSales: salesVal - returnsVal,
      transactions: docsCountTotal,
      basketSize: _salesSummary && docsCountTotal !== 0 ? _salesSummary.qty / docsCountTotal : 0,
      basketValue: _salesSummary && docsCountTotal !== 0 ? _salesSummary.subTotal / docsCountTotal : 0,
    };
  }

  private calculatePercentageDelta(actual, previous): number {
    if (parseFloat(actual || 0) === 0 && parseFloat(previous || 0) === 0) return 0;
    if (parseFloat(previous || 0) === 0) return 100;
    if (actual == null || previous == null || parseFloat(previous) === 0) return null;

    return (actual / previous - 1) * 100;
  }

  protected buildSalesDataForChart(salesPerHour: any[]) {
    const totalSalesData = [];
    const labels = [];

    salesPerHour.forEach((sales) => {
      labels.push(TimeHelpers.Convert24To12Hrs(sales.hour));
      totalSalesData.push(parseFloat(sales.TotalSales));
    });

    return {
      labels: labels,
      datasets: [
        {
          label: "Total Gross Sales",
          backgroundColor: "#42A5F5",
          borderColor: "#1E88E5",
          data: totalSalesData,
        },
        // {
        //     label: 'My Second dataset',
        //     backgroundColor: '#9CCC65',
        //     borderColor: '#7CB342',
        //     data: [28, 48, 40, 19, 86, 27, 90]
        // }
      ],
    };
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
