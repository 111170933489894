/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
import { EventEmitter, Injectable } from "@angular/core";
import { ValidatorFn } from "@angular/forms";
import {
  DataType,
  ExecuteEventDatatypeOptions,
  ExecuteEvent_DisplayMode,
  FilterType,
} from "../../../../form-list/form-list/form-list";
import { FormListModel } from "../../../../form-list/form-list/form-list-model.interface";
import { DBService } from "../../../../shared/services/db.service";
import { DocState, SaleDocType } from "../../doc/doc";

@Injectable({
  providedIn: "root",
})
export class CRM_HistoryService extends DBService implements FormListModel {
  getCrmInvoiceDetails: EventEmitter<any> = new EventEmitter();

  enum_doc_states = this.enumSelectOptions(DocState);
  enum_doc_types = this.enumSelectOptions(SaleDocType);

  getValidationRules(): { [key: string]: {} | ValidatorFn[] } {
    return {};
  }

  getDocNo(rowData) {
    return rowData.doc ? rowData.doc.docNo : "no Sale Doc Info";
  }

  getFormListColumns() {
    return [
      {
        field: "doc.docNo",
        header: "Doc #",
        visible: true,
        readonly: true,
        frozen: true,
        dataType: DataType.execute_event,
        dataOptions: [],
        dataTypeOptions: [
          new ExecuteEventDatatypeOptions({
            displayMode: ExecuteEvent_DisplayMode.LINK,
            label: this.getDocNo,
            event: this.getCrmInvoiceDetails,
            enabledOnEditMode: false,
            styleClass: "",
          }),
        ],
        filterType: FilterType.contains,
        colWidth: 90,
      },
      {
        field: "doc.docType",
        header: "Doc Type",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enum_doc_types,
        filterType: FilterType.enum,
        colWidth: 105,
      },
      {
        field: "doc.state",
        header: "Status",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enum_doc_states,
        filterType: FilterType.enum,
        colWidth: 85,
      },
      {
        field: "doc.docDate",
        header: "Date",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.date_date_only,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 95,
      },
      {
        field: "doc.localDocTime",
        header: "Time",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.date_time,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 70,
        sortFields: ["doc.docTime"],
      },
      {
        field: "grandTotalCurrencyFormat",
        header: "Total",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.none,
        isNotSortable: true,
        colWidth: 100,
      },
      {
        field: "tenderTypesList",
        header: "Pay Types",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.none,
        isNotSortable: true,
        colWidth: 110,
      },
      {
        field: "store.storeName",
        header: "Store",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.none,
        isNotSortable: true,
        colWidth: 110,
      },
    ];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
