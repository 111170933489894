<taku-return-sale-doc
  #saleDocComponent
  (inventoryItemSaved)="onInventoryItemSaved($event)"
  (noReceiptItem)="onReturnNoReceiptItem()"
  (onDocLinesDeleted)="onDocLinesDeleted($event)"
  (tenderScreenOpened)="openTenderScreen($event)"
  (docLineQtyChanged)="docLineQtyChanged($event)"
  (onDocReset)="onDocReset($event)"
>
  <ng-template
    #saleDocHeaderDialog
    let-saleForm
    let-station="saleStation"
    let-account="saleAccount"
    let-cashout="cashout"
  >
    <taku-sale-invoice-doc-details [saleForm]="saleForm" [station]="station" [account]="account" [cashout]="cashout">
    </taku-sale-invoice-doc-details>
  </ng-template>

  <ng-template
    #saleDocHeader
    let-saleForm
    let-station="saleStation"
    let-user="documentUser"
    let-account="saleAccount"
    let-cashout="cashout"
  >
    <div id="invoiceHeader" class="card col-12">
      <div id="doc-main-heading" class="col-12">
        <div class="col-12 doc-type-block">
          <span class="doc-type cursor-pointer flex align-items-center" (click)="switchDocMenu.toggle($event)">
            RETURNS
            <i class="switchdoc-dropdown pi pi-caret-down"></i>
          </span>
          <p-menu
            #switchDocMenu
            [style]="{ fontWeight: 700 }"
            [model]="docTypesMenu"
            [popup]="true"
            appendTo="body"
          ></p-menu>
          <span *ngIf="station" class="till-number">
            <span class="label">STATION#</span> {{ station.stationNumber }}
          </span>
        </div>
      </div>

      <div id="top-info-cards" class="col-12">
        <div class="flex justify-content-between">
          <div class="doc-info-box account-info">
            <dl>
              <dt>Account:</dt>
              <dd
                >{{ account ? account.headline : "-" }}
                <a *ngIf="account" class="pi pi-times icon-clear-account" (click)="onClearAccBtnPressed()"></a
              ></dd>
            </dl>
          </div>
          <div class="doc-info-ctrls">
            <button
              pButton
              label="Invoice Details"
              class="p-button-secondary"
              (click)="docComponent.openDocDetailsDialog()"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template
    #saleDocRightSide
    let-saleForm
    let-station="saleStation"
    let-user="documentUser"
    let-account="saleAccount"
    let-invoiceSettings="invoiceSettings"
    let-saleTotal="saleTotal"
  >
    <div id="searchFields-wrapper" class="card">
      <h3>Account Selection</h3>
      <!-- **********search box for Customer**************** -->
      <div class="grid col-12 customer-search-wrapper">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="material-icons">group</i></span>
          <taku-search-accounts
            id="account_search"
            #accountsSearchBox
            placeholder="Search for Account or Add New"
            (itemSelected)="onSearchResAccount($event)"
            (onNewItemClicked)="openNewModelInDialog($event)"
            [showAnimatePlaceholder]="false"
          >
          </taku-search-accounts>
        </div>

        <p-splitButton
          class="btnNewAccounts"
          #btnNewAccounts
          label=""
          icon="pi pi-plus"
          appendTo="body"
          (onClick)="openAddNewAccountDialog($event)"
          [model]="newAccountMenu"
          *ngIf="isAddPersonalAccountVisible || isAddCommercialAccountVisible"
          [disabled]="!isAddPersonalAccountEnabled && !isAddCommercialAccountEnabled"
        ></p-splitButton>
      </div>
      <!-- ********** end of add a customer icon **************** -->
    </div>

    <div id="rightside-tabs" class="card">
      <div
        id="rightsideDialog"
        class="dialog-{{ _activeDialog }}"
        [hidden]="!_activeDialog || !dialogExists(SideDialogNameDesktop, _activeDialog)"
      >
        <div class="dialog-header">
          <button
            pButton
            pRipple
            type="button"
            class="close-btn p-button-rounded"
            icon="pi pi-times"
            (click)="closeRightDialog()"
          ></button>
        </div>

        <div class="dialog-body" [ngSwitch]="_activeDialog">
          <div *ngSwitchCase="SideDialogName.TENDER_SCREEN" class="tender-screen">
            <taku-tender-screen
              *ngIf="_activeDialogExtra.zone"
              [formGroup]="saleForm"
              [docAccount]="account"
              [amountToPay]="-1 * saleTotal"
              [zoneSettings]="_activeDialogExtra.zone"
              [storePolicies]="_activeDialogExtra.storePolicies"
              [origTakuPaySaleDocTenders]="_activeDialogExtra.origTakuPaySaleDocTenders"
              (changesAccepted)="closeRightDialog(); docComponent.onTendersProcessed($event)"
              (onDocTenderRefDialog)="docComponent.openTenderRefNoDialog($event)"
              [_mode]="TenderScreenMode.RETURNS"
            >
            </taku-tender-screen>
          </div>

          <div *ngSwitchCase="SideDialogName.RETURN_ITEM_NOINVOICE" class="return-item-panel">
            <taku-return-item (onItemReturned)="onItemReturned($event)" (onNewInventory)="openNewModelInDialog($event)">
            </taku-return-item>
          </div>

          <div
            id="return_invoice_search_results"
            *ngSwitchCase="SideDialogName.RETURN_ITEM_SEARCH_RESULTS"
            class="returnItem-searchresults"
          >
            <taku-form-header
              toolbarTitle="Search Results"
              displayMode="inline"
              [showActionBtns]="false"
              [showBackBtn]="false"
            ></taku-form-header>
            <taku-form-list
              [_rowSelectionType]="RowSelectionType.NONE"
              [_showHeader]="true"
              [_filter]="_activeDialogExtra ? _activeDialogExtra : undefined"
              [_hideSavingCtrls]="true"
              [_hideFilterControls]="true"
              [_showCtrlsInEditMode]="false"
              [_hideAddLineHeaderBtn]="true"
              [_hideOptionsHeaderBtn]="false"
              [_hideGlobalFilter]="true"
              [_hideAddHeaderBtn]="true"
              [_hideDeleteHeaderBtn]="true"
              [_title]="'Sale Documents'"
              [_extraQueryParams]="_returnsSearchExtraParams"
              _model="saleDoc"
              _detailMode="n"
              _scrollHeight="calc(100vh - 30.8rem)"
              [_cols]="returnDocsColumns"
              [_saveFormState]="false"
            ></taku-form-list>
          </div>
        </div>
      </div>

      <taku-document-search-return
        (singleQtyReturned)="docComponent.addLineForReturn($event)"
        [allowNoInvoiceReturns]="docComponent.canNoInvoiceReturns"
        [inputSaleDoc]="docForReturnPreview"
        (entireDocReturned)="docComponent.setReturnedDoc($event)"
        (multipleResultsReturned)="openFormListForReturnSearch($event)"
        (noReceiptItem)="onReturnNoReceiptItem()"
        [docAccount]="account"
      ></taku-document-search-return>
    </div>
  </ng-template>
</taku-return-sale-doc>
