<div class="col-12">
  <taku-form-header
    [formGroup]="_myForm"
    [showBackBtn]="false"
    [showSecondaryBtns]="false"
    (saveClicked)="onSave()"
    (revertClicked)="onRevert()"
    toolbarTitle="GOOGLE SHOPPING DETAILS"
    iconStyleClass="takuicon-google-integration"
  ></taku-form-header>
</div>
<div [formGroup]="_myForm" class="col-12">
  <div class="grid col-12">
    <p-panel>
      <ng-template pTemplate="header">
        <h3>General Details</h3>
      </ng-template>

      <div class="col-12 drop-container">
        <taku-dropdown
          [formGroup]="_myForm"
          [options]="lookup_stores$ | async"
          fieldName="storeId"
          fieldCaption="Store"
        ></taku-dropdown>
      </div>

      <div class="col-12">
        <taku-input
          [formGroup]="_myForm"
          fieldName="merchantId"
          fieldCaption="Merchant ID"
          [readonly]="true"
        ></taku-input>
      </div>

      <div class="col-12">
        <taku-input [formGroup]="_myForm" fieldName="storeCode" fieldCaption="Store Code"></taku-input>
      </div>

      <div class="col-12 drop-container">
        <taku-dropdown
          [formGroup]="_myForm"
          [options]="enum_targetCountry"
          fieldName="targetCountry"
          fieldCaption="Target Country"
        ></taku-dropdown>
      </div>

      <div class="col-12 drop-container">
        <taku-dropdown
          [formGroup]="_myForm"
          [options]="enum_contentLanguage"
          fieldName="contentLanguage"
          fieldCaption="Content Language"
        ></taku-dropdown>
      </div>
    </p-panel>
  </div>
</div>

<!-- <div class="col-12">
    {{_myForm.value|json}}
  </div> -->
