/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

export class InventoryLabelSettings {
  labelSize: string;
  printQty: string;
  inputQty: number;
  productTitle: string;
  isShowBarcode: boolean;
  isShowPrice: boolean;
  showBarcode: string;
  showPrice: string;

  constructor(qty = 0) {
    this.labelSize = LabelSizes.barcode_2And1QuaterInchx1And1QuaterInch;
    this.printQty = PrintQty.baseOnOHQty;
    this.inputQty = qty;
    this.productTitle = ProductTitle.productTitle;
    this.isShowBarcode = true;
    this.isShowPrice = true;
    this.showBarcode = ShowBarcode.sku;
    this.showPrice = ShowPrice.sellPrice;
  }
}

export enum LabelSizes {
  barcode_1Inchx1Inch = 'Barcode - 1" x 1"',
  barcode_2And1QuaterInchx1And1QuaterInch = 'Barcode - 2 1/4" x 1 1/4"',
  barcode_2And1QuaterInchx3QuaterInch = 'Barcode - 2 1/4" x 3/4"',
  shelf_2And1HalfInchx1and1QuaterInch = 'Shelf - 2 1/2" x 1 1/4"',
}

export enum PrintQty {
  baseOnOHQty = "baseOnOHQty",
  perSelected = "perSelected",
}

export enum ProductTitle {
  productTitle = "productTitle",
  altProductTitle = "altProductTitle",
}

export enum ShowBarcode {
  sku = "sku",
  upc = "upc",
}

export enum ShowPrice {
  sellPrice = "sellPrice",
  altPrice = "altPrice",
  discountPrice = "discountPrice",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
