/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { SearchResultItem } from "../../../../taku-ui/taku-search-accounts/SearchResultItem";
import { FormDataHelpers } from "../../../../utility/FormDataHelpers";
import { InvoiceHelpers } from "../../../../utility/InvoiceHelpers";
import { AddressEmailComponent } from "../../../contact-accounts/address-email/address-email.component";
import { AddressPhoneComponent } from "../../../contact-accounts/address-phone/address-phone.component";
import { Address, AddressType } from "../../../contact-accounts/address/address";
import { StoreSettingSaleDoc } from "../../../settings/store-settings/sales-doc-settings/sales-doc-settings";
import { AppDefaultsService } from "src/app/shared/services/app-defaults.service";

@Component({
  selector: "taku-invoice-details",
  templateUrl: "./invoice-details.component.html",
  styleUrls: ["./invoice-details.component.scss"],
})
export class InvoiceDetailsComponent implements OnChanges, OnInit {
  @Input() account: SearchResultItem;
  @Input() saleForm: UntypedFormGroup;
  @Input() salesDocSettings: StoreSettingSaleDoc;
  @Input() shortenFormHeaderHeight = false;
  private accountBillingAddress: Address;
  private defaultCountryCode: string;

  constructor(private fb: UntypedFormBuilder, private appDefaultsService: AppDefaultsService) {}

  ngOnInit(): void {
    this.appDefaultsService.getDefaultAddressCountry().subscribe((defaultCountryCode) => {
      this.defaultCountryCode = defaultCountryCode;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["account"] || changes["salesDocSettings"]) {
      if (this.account) {
        this.accountBillingAddress = InvoiceHelpers.findAccountAddressByType(AddressType.billingAddress, this.account);
      } else if (changes["account"].previousValue) {
        this.accountBillingAddress = null;
        this.onClearAddress();
      }

      // Don't Auto Copy by default when 'Sale Doc Settings' has not been created yet
      if (this.salesDocSettings && this.salesDocSettings.autoCopyAccountAddress) {
        this.copyBillingAddressFromAccount();
      }
    } else if (changes["saleForm"]) {
      this.accountBillingAddress = null;
      this.onClearAddress();
    }
  }

  ///////////////////////////
  // For Invoice Details ///
  /////////////////////////
  copyBillingAddressFromAccount(): void {
    if (this.accountBillingAddress) {
      // check if address is valid
      this.saleForm.controls.billingAddress.patchValue(this.accountBillingAddress);
    }
  }

  onCloneAddress(destAddress: UntypedFormGroup, sourceAddress: UntypedFormGroup): void {
    // Before reseting address backup destination address type
    const addressType = destAddress.controls.addressType.value;

    const sourceData: Address = sourceAddress.value;
    FormDataHelpers.clearModelIDs(sourceData);
    destAddress.reset(sourceData);
    destAddress.setControl("addressEmail", AddressEmailComponent.set(this.fb, sourceData.addressEmail));
    destAddress.setControl("addressPhone", AddressPhoneComponent.set(this.fb, sourceData.addressPhone));

    destAddress.patchValue({ addressType });
  }

  onClearAddress(): void {
    const newAddress = new Address();
    newAddress.countryIsoCode = newAddress.addressPhone.countryIsoCode = this.defaultCountryCode;

    this.saleForm.controls.billingAddress.reset(newAddress);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
