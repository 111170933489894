/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { DBService } from "../../../shared/services/db.service";
import { map } from "rxjs/operators";
import { AuthService } from "src/app/shared/services/auth.service";
import { SettingCardInfo } from "../settings-cards/setting-cards.component";
import { Subscription } from "rxjs";

@Component({
  selector: "taku-location-settings",
  templateUrl: "location-settings.component.html",
  styleUrls: ["../settings-cards/settings-cards.component.scss"],
})
export class StoreSettingsCardsComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];

  readonly _pageTitlePrefix = "Current Store Settings";
  pageTitle = this._pageTitlePrefix;
  pageIconStyleClass = "ui-icon-store";
  _activeStoreId = 0;
  _activeZoneId = 0;

  cardsInfo: SettingCardInfo[] = [];

  updatePageTitle(storeId: number) {
    this.subsList.push(
      this.dbService.getRow("store", storeId).subscribe((store) => {
        this.pageTitle = `${this._pageTitlePrefix}&nbsp;<span class="secondary-text">(${store.storeName})</span>`;
        this._activeZoneId = store.zoneId;
      })
    );
  }

  updateCardsInfo(storeId) {
    this.cardsInfo = [
      {
        routePath: ["/settings/stores/current-store/location-settings/sales-formats", storeId],
        iconStyleClass: "material-icons",
        iconName: "text_fields",
        cardTitle: "Sales Doc# Formats",
        cardDesc: ["Set Up Sales Doc# Formats", "(e.g. Cashout, Sales Invoice...)"],
        isVisible: this.authService.hasVisiblePermit("Settings_Menu_Stores_Current_Store_Sales_Doc_Formats"),
        isEnabled: this.authService.hasEnablePermit("Settings_Menu_Stores_Current_Store_Sales_Doc_Formats"),
      },
      {
        routePath: ["/settings/stores/current-store/location-settings/store-policies", storeId],
        iconStyleClass: "takuicon-not_listed_location",
        cardTitle: "Store Policies",
        cardDesc: ["Set Up Store Policies for Sales, Shipping, Returns and Gift Receipts"],
        isVisible: this.authService.hasVisiblePermit("Settings_Menu_Stores_Current_Store_Store_Policies"),
        isEnabled: this.authService.hasEnablePermit("Settings_Menu_Stores_Current_Store_Store_Policies"),
      },
      {
        routePath: ["/settings/stores/current-store/location-settings/return-settings", storeId],
        iconStyleClass: "takuicon-refund",
        cardTitle: "Return Settings",
        cardDesc: ["Add Return Reasons", "Allow Returns without Invoices", "Allow Returns on Salesscreen"],
        isVisible: this.authService.hasVisiblePermit("Settings_Menu_Stores_Current_Store_Return_Settings"),
        isEnabled: this.authService.hasEnablePermit("Settings_Menu_Stores_Current_Store_Return_Settings"),
      },
      {
        routePath: ["/settings/stores/current-store/location-settings/tender-types", storeId],
        iconStyleClass: "pi pi-credit-card",
        cardTitle: "Payment Settings",
        cardDesc: ["Select Tender Types for Payments"],
        isVisible: this.authService.hasVisiblePermit("Settings_Menu_Stores_Current_Store_Payment_Settings"),
        isEnabled: this.authService.hasEnablePermit("Settings_Menu_Stores_Current_Store_Payment_Settings"),
      },
      {
        routePath: ["/settings/stores/current-store/location-settings/cashout-settings", storeId],
        iconStyleClass: "material-icons",
        iconName: "attach_money",
        cardTitle: "Cashout Settings",
        cardDesc: ["Set Up General Cashout Settings", "Set Up Cashout Slip Settings", "Set Up Default Opening Float"],
        isVisible: this.authService.hasVisiblePermit("Settings_Menu_Stores_Current_Store_Cashout_Settings"),
        isEnabled: this.authService.hasEnablePermit("Settings_Menu_Stores_Current_Store_Cashout_Settings"),
      },
      {
        routePath: ["/settings/stores/current-store/location-settings/sales-doc-settings", storeId],
        iconStyleClass: "material-icons",
        iconName: "receipt",
        cardTitle: "Sales Doc Settings",
        cardDesc: ["Salesscreen Entry Method", "Copy Pinned Address to Invoices"],
        isVisible: this.authService.hasVisiblePermit("Settings_Menu_Stores_Current_Store_Sales_Doc_Settings"),
        isEnabled: this.authService.hasEnablePermit("Settings_Menu_Stores_Current_Store_Sales_Doc_Settings"),
      },
      {
        routePath: ["/settings/stores/current-store/location-settings/receipt-builders/tape/sales", storeId],
        iconStyleClass: "takuicon-measuring-tape",
        cardTitle: "Sales Receipt Builder <span class='sub'>(Tape)</span>",
        cardDesc: ["Build Your Own Sales Tape Receipts"],
        isVisible: this.authService.hasVisiblePermit("Settings_Menu_Stores_Current_Store_Sales_Receipt_Builder_(Tape)"),
        isEnabled: this.authService.hasEnablePermit("Settings_Menu_Stores_Current_Store_Sales_Receipt_Builder_(Tape)"),
      },
      {
        routePath: ["/settings/stores/current-store/location-settings/receipt-builders/tape/returns", storeId],
        iconStyleClass: "takuicon-measuring-tape",
        cardTitle: "Return Receipt Builder <span class='sub'>(Tape)</span>",
        cardDesc: ["Build Your Own Return Tape Receipts"],
        isVisible: this.authService.hasVisiblePermit(
          "Settings_Menu_Stores_Current_Store_Return_Receipt_Builder_(Tape)"
        ),
        isEnabled: this.authService.hasEnablePermit("Settings_Menu_Stores_Current_Store_Return_Receipt_Builder_(Tape)"),
      },
      {
        routePath: ["/settings/stores/current-store/location-settings/receipt-builders/full-page/sales", storeId],
        iconStyleClass: "takuicon-invoice",
        cardTitle: "Sales Receipt Builder <span class='sub'>(Full Page)</span>",
        cardDesc: ["Build Your Own Full-Page Sales Receipts"],
        isVisible: this.authService.hasVisiblePermit(
          "Settings_Menu_Stores_Current_Store_Sales_Receipt_Builder_(Full_Page)"
        ),
        isEnabled: this.authService.hasEnablePermit(
          "Settings_Menu_Stores_Current_Store_Sales_Receipt_Builder_(Full_Page)"
        ),
      },
      {
        routePath: ["/settings/stores/current-store/location-settings/receipt-builders/full-page/returns", storeId],
        iconStyleClass: "takuicon-invoice",
        cardTitle: "Return Receipt Builder <span class='sub'>(Full Page)</span>",
        cardDesc: ["Build Your Own Full-Page Return Receipts"],
        isVisible: this.authService.hasVisiblePermit(
          "Settings_Menu_Stores_Current_Store_Return_Receipt_Builder_(Full_Page)"
        ),
        isEnabled: this.authService.hasEnablePermit(
          "Settings_Menu_Stores_Current_Store_Return_Receipt_Builder_(Full_Page)"
        ),
      },
      {
        routePath: ["/settings/stores/current-store/location-settings/gift-receipt-builders/tape", storeId],
        iconStyleClass: "takuicon-gift",
        cardTitle: "Gift Receipt Builder <span class='sub'>(Tape)</span>",
        cardDesc: ["Build Your Own Gift Tape Receipts"],
        isVisible: this.authService.hasVisiblePermit("Settings_Menu_Stores_Current_Store_Gift_Receipt_Builder_(Tape)"),
        isEnabled: this.authService.hasEnablePermit("Settings_Menu_Stores_Current_Store_Gift_Receipt_Builder_(Tape)"),
      },
      {
        routePath: ["/store-settings/kiosk-settings", storeId],
        iconStyleClass: "material-icons",
        iconName: "dock",
        cardTitle: "Kiosk Settings",
        cardDesc: ["Customize kiosk opening screen", "Activate kiosk mode with password"],
        isVisible: this.authService.hasVisiblePermit("Settings_Menu_Stores_Current_Store_Kiosk_Settings"),
        isEnabled: this.authService.hasEnablePermit("Settings_Menu_Stores_Current_Store_Kiosk_Settings"),
      },
      {
        routePath: ["/settings/printers/model-list/n/printer", storeId],
        iconStyleClass: "material-icons",
        iconName: "local_printshop",
        cardTitle: "Printer Settings",
        cardDesc: ["Set Up Receipt Printers for POS or Back Office Use"],
        isVisible: this.authService.hasVisiblePermit("Settings_Menu_Store_Settings_Printer_Settings"),
        isEnabled: this.authService.hasEnablePermit("Settings_Menu_Store_Settings_Printer_Settings"),
      },
      {
        // routePath: ['/store-settings/mail-settings', storeId],
        routePath: ["/settings/stores/current-store/location-settings/mail-settings", storeId],
        iconStyleClass: "material-icons",
        iconName: "mail",
        cardTitle: "Email Settings",
        cardDesc: ["Set Up SMTP Server Options for Outgoing Sales Emails", "Enable Encryption"],
        isVisible: this.authService.hasVisiblePermit("Settings_Menu_Stores_Current_Store_Email_Settings"),
        isEnabled: this.authService.hasEnablePermit("Settings_Menu_Stores_Current_Store_Email_Settings"),
      },
      // {
      //   routePath: ["/store-settings/stations/model-list/d/station", storeId],
      //   iconStyleClass: "takuicon-manage-stations-settings",
      //   cardTitle: "Manage Stations",
      //   cardDesc: [
      //     "Description Line 1",
      //     "Description Line 2",
      //     "Settings long description",
      //     "Last description line 4"
      //   ]
      // },
      // {
      //   routePath: ["", storeId],
      //   iconStyleClass: "takuicon-active-station-settings",
      //   cardTitle: "Current Station Settings",
      //   cardDesc: [
      //     "Description Line 1",
      //     "Description Line 2",
      //     "Settings long description",
      //     "Last description line 4"
      //   ]
      // }
    ];
  }

  storeChanged(storeId) {
    this._router.navigate(["../", storeId], { relativeTo: this._route });
  }

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private dbService: DBService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.subsList.push(
      this._route.params.pipe(map((params: Params) => +params["storeId"])).subscribe((storeId) => {
        // this.cardsInfo = [...this.cardsInfo];
        this.updateCardsInfo(storeId);
        this.updatePageTitle(storeId);
        this._activeStoreId = storeId;
      })
    );
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
