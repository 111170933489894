<div class="pos-container">
  <taku-form-header
    (backClicked)="goBack()"
    toolbarTitle="Order Terms"
    iconStyleClass="material-icons"
    iconName="list_alt"
    [showActionBtns]="false"
    [displayMode]="FormHeaderDisplayMode.INLINE"
  ></taku-form-header>

  <p-tabMenu [model]="menuPurchaseOrderTerms" [activeItem]="menuPurchaseOrderTerms[_activeTab]"></p-tabMenu>

  <router-outlet></router-outlet>
</div>
