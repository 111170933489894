<div
  class="list-card grid"
  [ngClass]="{ 'wider-list-card': !inDesktopMainPageCart }"
  [ngStyle]="{ width: '100%', height: height + 'px' }"
>
  <div class="item-left col-4">
    <div class="inventory-img">
      <img [attr.src]="inventoryImagesUrl || '../../../../assets/layout/images/no-image.jfif'" />
    </div>
  </div>
  <div class="item-right" [ngClass]="inDesktopMainPageCart ? 'col-6' : 'col-7'">
    <div class="name-and-price-container">
      <div class="inventory-name">
        <!-- <ng-content select="[detailLink]"></ng-content> -->
        <a *ngIf="!disabled" (click)="openItemDetailsDlg($event)">
          {{ _myForm.get("description").value }}
        </a>
        <span *ngIf="disabled">
          {{ _myForm.get("description").value }}
        </span>
      </div>
      <div class="font-italic text-base">
        {{ inventoryVariantsStr }}
      </div>
      <div class="inventory-price">
        <b>{{ unitPriceCtrl.value | currency : "CAD" : "symbol-narrow" : "1.2" }}</b>
      </div>
      <div *ngIf="!inDesktopMainPageCart" class="inventory-remove-container">
        <a *ngIf="!disabled" (click)="onRemoveClicked()">Remove</a>
      </div>
    </div>
    <div class="qty-ctrl-container grid" [ngClass]="{ 'remove-qty-ctrl-container-margin': inDesktopMainPageCart }">
      <div class="col-4 chg-qty-btn" [ngClass]="{ disabledBtn: disabled || !isQtyValid(calculateQtyDecrement) }">
        <!-- <div class="col-4 chg-qty-btn"  [ngClass]="{disabledBtn: disabled || qtyCtrl.value === 1 || !isQtyValid(calculateQtyDecrement) }"> -->
        <i class="material-icons" (click)="decQty()">remove_circle_outline</i>
      </div>
      <div class="col-4 item-qty-in-cart">
        <b>{{ qtyCtrl.value }}</b>
      </div>
      <div class="col-4 chg-qty-btn" [ngClass]="{ disabledBtn: disabled || !isQtyValid(calculateQtyIncrement) }">
        <i class="material-icons" (click)="incQty()">add_circle</i>
      </div>
    </div>
    <div *ngIf="inDesktopMainPageCart" class="inventory-remove-container">
      <a *ngIf="!disabled" (click)="onRemoveClicked()">Remove</a>
    </div>
  </div>
</div>
