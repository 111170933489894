/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { AuthService } from "../auth.service";
import { MessageService } from "primeng/api";

@Injectable({
  providedIn: "root",
})
export class UnauthorizedRequestInterceptor implements HttpInterceptor {
  constructor(private router: Router, private authService: AuthService, private messageService: MessageService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // If the response is unauthorized, redirect user to login screen
            this.authService.resetAuthState();
            void this.router.navigate(["/login"], { state: { bypassGuards: true } });

            if (!request.url.includes("login")) {
              const reason = err.headers.get("www-authenticate");
              this.messageService.add({
                severity: "warn",
                summary: "Logged out",
                detail: reason ?? "Your login has expired",
                life: 15000,
              });
            }
          }
        }
      )
    );
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
