import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SaleDocSelfCheckoutBaseComponent } from "../sale-doc-self-checkout-base/sale-doc-self-checkout-base.component";
import * as _ from "lodash";

enum MobileSelfCheckoutPaymentSteps {
  CHECKOUT = 0,
  METHOD = 1,
  PAYMENT = 2,
}
@Component({
  selector: "taku-sale-doc-self-checkout-mobile",
  templateUrl: "./sale-doc-self-checkout-mobile.component.html",
  styleUrls: ["./sale-doc-self-checkout-mobile.component.scss"],
})
export class SaleDocSelfCheckoutMobileComponent extends SaleDocSelfCheckoutBaseComponent {
  showShoppingCart = false;

  @Output() backToSearchScreen: EventEmitter<any> = new EventEmitter();

  // mobilePaymentPageSteps: MenuItem[] = [
  //   { label: 'Checkout' },
  //   // { label: 'Method' },
  //   { label: 'Payment' },
  // ];

  ngOnInit(): void {
    super.ngOnInit();
    // this.activeStore = this.appSettingsService.getStore();
    // this.dvContainerHeight = window.innerHeight - 56 - 56 - 156;
    // console.log(this.dvContainerWidth, this.dvContainerHeight);
  }

  // returnToMainScreen(e) {
  //   this.returnToCart();
  //   this.goBackToSearchScreen();
  // }

  // had to override this method as in mobile self-checkout, we do not have any view of
  // the current shopping cart (saleDocLines), or this.saleLinesComponents
  incQty(index = this.selectedLineIndex) {
    if (!this._isLineIndexValid(index)) return;

    const docLineForm = this.saleDocLines.at(index);
    const originalQty = docLineForm.get("qty").value;
    docLineForm.patchValue({ qty: originalQty + 1 });
  }

  _isLineIndexValid(index) {
    // console.log("this one is called");
    return index >= 0 && index < this.saleDocLines.length;
  }

  onSelfCheckoutPay(ref) {
    super.onSelfCheckoutPay(ref);
    this.showShoppingCart = false;
    // console.log(this._myForm);
  }

  // onGreenBtnClicked() {
  //   if (this.paymentPageActiveIndex === 1) {
  //     this.userReadyToPay = true;
  //     this._showItemsClicked = false;
  //   }
  //   // 2 for mobile, 1 for desktop
  //   if (this.paymentPageActiveIndex < this.mobilePaymentPageSteps.length - 1) {
  //     this.paymentPageActiveIndex++;
  //   } else {
  //     console.log("------WE ARE PAYING NOW!!------");
  //   }
  // }

  returnToCartMobile() {
    super.returnToCart();
    this.showShoppingCart = true;
  }

  openShoppingCart() {
    this.showShoppingCart = true;
  }

  goBackToSearchScreen() {
    this.showShoppingCart = false;
    this.backToSearchScreen.emit();
  }

  protected _clearSaleDocLines() {
    super._clearSaleDocLines();
    this.goBackToSearchScreen();
  }

  get chosenDeliveryMethod() {
    return this._myForm.get("deliveryMethod").value;
  }
}
