import { OpenCashDrawerPreviewComponent } from "./../../core/previews/receipt/open-cash-drawer-preview/open-cash-drawer-preview.component";
/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { ComponentRef, Injectable, Type, ViewContainerRef } from "@angular/core";
import { forkJoin, Observable, ReplaySubject, throwError, of, from } from "rxjs";
import { map, tap, catchError, switchMap, take } from "rxjs/operators";
import { Account, AccountType } from "src/app/core/contact-accounts/account/account";
import { AddressEmail } from "src/app/core/contact-accounts/address-email/address-email";
import { AddressPhone } from "src/app/core/contact-accounts/address-phone/address-phone";
import { Address, AddressLocationType, AddressType } from "src/app/core/contact-accounts/address/address";
import {
  StoreSocialProfile,
  StoreSocialProfileType,
} from "src/app/core/contact-accounts/store-social-profile/store-social-profile";
import { DocLine } from "src/app/core/document/doc-line/doc-line";
import { Doc, DocState, SaleDocType } from "src/app/core/document/doc/doc";
import { SaleDocLineTax } from "src/app/core/document/sale-document/sale-doc-line-tax/sale-doc-line-tax";
import { SaleDocLine } from "src/app/core/document/sale-document/sale-doc-line/sale-doc-line";
import { SaleDocTax } from "src/app/core/document/sale-document/sale-doc-tax/sale-doc-tax";
import { SaleDocTender } from "src/app/core/document/sale-document/sale-doc-tender/sale-doc-tender";
import { SaleDocService } from "src/app/core/document/sale-document/sale-doc/sale-doc.service";
import { Inventory } from "src/app/core/inventory/inventory/inventory";
import { BusinessDetail } from "src/app/core/settings/business-settings/business-detail/business-detail";
import { Tender_Type } from "src/app/core/settings/business-settings/tender-type/tender-type";
import { Station } from "src/app/core/settings/store-settings/station/station";
import { Store } from "src/app/core/settings/store-settings/store/store";
import { User } from "src/app/core/users/user/user";
import { CashoutDeposits } from "../../core/cashout/cashout-closetill/cashout-closetill.component";
import { Cashout } from "../../core/cashout/cashout/cashout";
import { DiscountType, SaleDoc } from "../../core/document/sale-document/sale-doc/sale-doc";
import { GiftReceiptTapePreviewComponent } from "../../core/previews/receipt/gift-receipt-tape-preview/gift-receipt-tape-preview.component";
import { ReceiptFullPreviewComponent } from "../../core/previews/receipt/receipt-full-preview/receipt-full-preview.component";
import { ReceiptTapePreviewComponent } from "../../core/previews/receipt/receipt-tape-preview/receipt-tape-preview.component";
import { CashoutSlipBase } from "../../core/previews/slips/CashoutSlipBase.interface";
import { StoreSettingLetterSalePrint } from "../../core/settings/store-settings/full-page-salereceipt-builder/StoreSettingLetterSalePrint";
import { StoreSettingGiftReceipt } from "../../core/settings/store-settings/gift-receipt-builder/StoreSettingGiftReceipt";
import { StorePoliciesSettings } from "../../core/settings/store-settings/store-policies-settings/StorePoliciesSettings";
import { StoreSettingTapeSalePrint } from "../../core/settings/store-settings/tape-salereceipt-builder/store-setting-tape-sale-print";
import { PrintHelpers } from "../../utility/PrintHelpers";
import { AppSettingsStorageService } from "../app-settings-storage.service";
import { MessageService } from "primeng/api";
import { StoreSettingCashout } from "src/app/core/settings/store-settings/cashout-settings/CashoutSettings";
import { ClosingCashoutSlipTapeSizeComponent } from "src/app/core/previews/slips/cashout-slip-tape-size/cashout-slip-tape-size.component";
import { ClosingCashoutSlipFullSizeComponent } from "src/app/core/previews/slips/cashout-slip-full-size/cashout-slip-full-size.component";
import { OpeningCashoutSlipTapeSizeComponent } from "src/app/core/previews/slips/opening-cashout-slip-tape-size/opening-cashout-slip-tape-size.component";
import { OpeningCashoutSlipFullSizeComponent } from "src/app/core/previews/slips/opening-cashout-slip-full-size/opening-cashout-slip-full-size.component";
import { StoreHour, Week_Day } from "src/app/core/settings/store-settings/store-hour/store-hour";
import { StoreSettingLetterReturnPrint } from "src/app/core/settings/store-settings/full-page-returnreceipt-builder/StoreSettingLetterReturnPrint";
import { StoreSettingTapeReturnPrint } from "src/app/core/settings/store-settings/tape-returnreceipt-builder/store-setting-tape-return-print";
import { StarCloudPrintServiceService } from "src/app/shared/services/star-cloud-print-service.service";
import { UntypedFormGroup } from "@angular/forms";
import { TenderReceiptTapePreviewComponent } from "src/app/core/previews/receipt/tender-receipt-tape-preview/tender-receipt-tape-preview.component";
import { ClosedBatchDetailsTapePreviewComponent } from "src/app/core/previews/receipt/closed-batch-details-tape-preview/closed-batch-details-tape-preview.component";
import { PrinterManufacturer } from "src/app/core/settings/store-settings/printer/printer-settings";
import { InventoryLabelPrintingComponent } from "src/app/core/inventory/inventory-label-printing/inventory-label-printing.component";
import { PurchaseDoc } from "src/app/core/document/purchase-document/purchase-document/purchase-document";
import { PurchaseOrderFullPreviewComponent } from "src/app/core/previews/purchase/purchase-order-full-preview/purchase-order-full-preview.component";
import { PrintingQueueService } from "./print-queue.service";
import { ClosingCashoutReportTapeSizeComponent } from "src/app/core/previews/slips/cashout-report-tape-size/cashout-report-tape-size.component";
import { PurchaseDocService } from "src/app/core/document/purchase-document/purchase-doc-list/purchase-doc.service";
import { HttpErrorResponse } from "@angular/common/http";

type ReceiptPreviewBaseComponent =
  | ReceiptFullPreviewComponent
  | ReceiptTapePreviewComponent
  | GiftReceiptTapePreviewComponent
  | TenderReceiptTapePreviewComponent
  | ClosedBatchDetailsTapePreviewComponent
  | OpenCashDrawerPreviewComponent
  | PurchaseOrderFullPreviewComponent;

export enum ReceiptPrintingSize {
  TAPE_SIZE = "Tape Size",
  LETTER_SIZE = "Letter Size",
  TAPE_SIZE_CASHOUT_REPORT = "Cashout Report",
}

@Injectable({
  providedIn: "root",
})
export class PrintingFactoryService {
  constructor(
    private dbService: SaleDocService,
    private purchaseDocService: PurchaseDocService,
    public appSettingsService: AppSettingsStorageService,
    private messageService: MessageService,
    private printQueue: PrintingQueueService,
    private starCloudPrintServiceService: StarCloudPrintServiceService
  ) {}

  public build(storeId): PrintingMgr {
    const printingMgr = new PrintingMgr(
      this.dbService,
      this.purchaseDocService,
      this.appSettingsService,
      this.messageService,
      this.printQueue,
      this.starCloudPrintServiceService
    );
    printingMgr._checkAndPullMissingSettings(storeId);

    return printingMgr;
  }

  public buildWithSettings(
    storeId: number,
    letterSizeBuilderSettings: StoreSettingLetterSalePrint | StoreSettingLetterReturnPrint,
    tapeSizeBuilderSettings: StoreSettingTapeSalePrint | StoreSettingTapeReturnPrint,
    giftReceiptBuilderSettings: StoreSettingGiftReceipt,
    storePolicies: StorePoliciesSettings
  ): PrintingMgr {
    const printingMgr = new PrintingMgr(
      this.dbService,
      this.purchaseDocService,
      this.appSettingsService,
      this.messageService,
      this.printQueue,
      this.starCloudPrintServiceService
    );
    printingMgr.letterSizeBuilderSettings = letterSizeBuilderSettings;
    printingMgr.tapeSizeBuilderSettings = tapeSizeBuilderSettings;
    printingMgr.giftReceiptBuilderSettings = giftReceiptBuilderSettings;
    printingMgr.storePolicies = storePolicies;

    printingMgr._checkAndPullMissingSettings(storeId);

    return printingMgr;
  }

  createDemoData(): Observable<{ saledoc; policies; businessDetail }> {
    const saledoc = Object.assign(new SaleDoc(), {
      account: Object.assign(new Account(AccountType.commercial), {
        accountCode: "ACC12466526",
      }),
      cashRounding: 0.02,
      subTotal: 3710.03,
      discountAmount: 10,
      discountType: DiscountType.Percentage,
      accountName: "Damian Lovak",
      taxID: "124546541241",
      store: Object.assign(new Store(), {
        storeName: "My Company - Toronto Store",
        storeID: "01",
        url: "https://www.mywebsite.com",
        address: Object.assign(new Address(), {
          unitNum: 5,
          line1: "3600 Stelees Avenue East",
          line2: "Unit B-XYZ",
          countryIsoCode: "CA",
          subDivisionIsoCode: "CA-ON",
          city: "Toronto",
          postalCode: "M3J 0A9",
          addressType: AddressType.billingAddress,
          addressLocationType: AddressLocationType.commercial,
          addressEmail: Object.assign(new AddressEmail(), {
            emailType: "Work",
            email: "sales@mycompanyltd.ca",
          }),
          addressPhone: Object.assign(new AddressPhone(), {
            tel: "1 (437) 8565-8965",
            ext: "2025",
          }),
        }),
        storeHours: [
          Object.assign(new StoreHour(), {
            id: "123412341234",
            startDay: Week_Day.monday,
            endDay: Week_Day.friday,
            openTime: "9:00",
            closeTime: "18:00",
          }),
        ],
        storeSocialProfiles: [
          Object.assign(new StoreSocialProfile(), {
            socialProfileType: StoreSocialProfileType.facebook,
            socialProfile: "facebook.com/mycompany-fb",
          }),
          Object.assign(new StoreSocialProfile(), {
            socialProfileType: StoreSocialProfileType.twitter,
            socialProfile: "twitter.com/mycompany-tag",
          }),
          Object.assign(new StoreSocialProfile(), {
            socialProfileType: StoreSocialProfileType.instagram,
            socialProfile: "instagram.com/mycompany-inst",
          }),
        ],
      }),
      station: Object.assign(new Station(), {
        stationNumber: 10,
      }),
      billingAddress: Object.assign(new Address(), {
        unitNum: 10,
        line1: "47 Saffrom St",
        // line2: "Office Building",
        countryIsoCode: "CA",
        subDivisionIsoCode: "CA-ON",
        city: "Markham",
        postalCode: "L6E 1L3",
        addressType: AddressType.billingAddress,
        addressLocationType: AddressLocationType.commercial,
        attnCompanyName: "My Main Store",
        attnFirstName: "Christopher",
        attnLastName: "Ewan",
        addressPhone: Object.assign(new AddressPhone(), {
          countryIsoCode: "CA",
          tel: "(647) 5632-89652",
          ext: "123",
        }),
        addressEmail: Object.assign(new AddressEmail(), {
          email: "cwean@gmail.ca",
        }),
        note: "Demodata Bill To Note",
      }),
      shippingAddress: Object.assign(new Address(), {
        unitNum: 5,
        line1: "1950 Meadowvale Blvd",
        // line2: "Opposite grocery store",
        countryIsoCode: "CA",
        subDivisionIsoCode: "CA-ON",
        city: "Mississauga",
        postalCode: "L5N 8L9",
        addressType: AddressType.shippingAddress,
        addressLocationType: AddressLocationType.residential,
        attnCompanyName: "My Local Store #1",
        attnFirstName: "Joseph",
        attnLastName: "Reynolds",
        addressPhone: Object.assign(new AddressPhone(), {
          countryIsoCode: "CA",
          tel: "(905) 568-0434",
          ext: "456",
        }),
        addressEmail: Object.assign(new AddressEmail(), {
          email: "joseph.reynolds-cabrera@hotmail.com",
        }),
        note: "Demodata Ship To Note",
      }),
      doc: Object.assign(new Doc(SaleDocType.sales_invoice, DocState.finalized), {
        docDate: new Date(),
        docTime: "18:32",
        docNo: "IN03931246",
        currencyIsoCode: "CAD",
        fiscalYearId: 1,
        user: Object.assign(new User(), {
          userName: "ksiwadi",
        }),
        comment:
          "Suspendisse dapibus consectetur maximus. Phasellus lectus nisi, gravida eget feugiat porta, commodo a nulla. Vivamus tempor purus a enim convallis dictum. Vestibulum non laoreet felis.",
      }),
      saleDocLines: [
        Object.assign(new SaleDocLine(), {
          discountType: DiscountType.Fixed,
          discountAmount: 12.5,
          qty: 12,
          unitPrice: 100,
          saleDocLineTaxes: [
            Object.assign(new SaleDocLineTax(), {
              amount: 13.0,
              taxRule: {
                tax: {
                  shortDesc: "HST",
                },
                taxAcronym: "H",
              },
            }),
          ],
          docLine: Object.assign(new DocLine(), {
            note: "Discount applied due to loyal customer",
            refNo: 1,
            inventory: Object.assign(new Inventory("CAD"), {
              uOfMeasure: {
                name: "box",
              },
              sku: "100012556",
              description1: "PIZZA PANS LOREM IPSUM DOLOR SIT AMET",
              description2: "女裝",
              standardPrice: 100,
              standardPriceCurrencyIsoCode: "CAD",
              inventoryBarcodes: [
                {
                  barcode: "4564654879879877878",
                },
              ],
              categories: [
                {
                  categoryName: "Food",
                },
                {
                  categoryName: "Familiar",
                },
                {
                  categoryName: "Snacks",
                },
              ],
              inventoryVariants: [
                {
                  inventoryOptionsSetting: {
                    optionName: "Size",
                  },
                  inventoryOptionsSettingValue: {
                    optionValue: "XL",
                  },
                },
                {
                  inventoryOptionsSetting: {
                    optionName: "Color",
                  },
                  inventoryOptionsSettingValue: {
                    optionValue: "RED",
                  },
                },
              ],
            }),
          }),
        }),
        Object.assign(new SaleDocLine(), {
          // discountType: DiscountType.Percentage,
          // discountAmount: 20,
          qty: 5,
          unitPrice: 49.99,
          saleDocLineTaxes: [
            Object.assign(new SaleDocLineTax(), {
              amount: 13.0,
              taxRule: {
                tax: {
                  shortDesc: "HST",
                },
                taxAcronym: "H",
              },
            }),
          ],
          docLine: Object.assign(new DocLine(), {
            refNo: 1,
            inventory: Object.assign(new Inventory("CAD"), {
              uOfMeasure: {
                name: "each",
              },
              sku: "45895113501",
              description1: "DRESSING PANTS VAN HEUSEN",
              standardPrice: 49.99,
              standardPriceCurrencyIsoCode: "CAD",
              inventoryBarcodes: [
                {
                  barcode: "985635751345262",
                },
              ],
              categories: [
                {
                  categoryName: "Apparel",
                },
                {
                  categoryName: "Men",
                },
                {
                  categoryName: "Formal",
                },
                {
                  categoryName: "Designer",
                },
              ],
            }),
          }),
        }),
        Object.assign(new SaleDocLine(), {
          discountType: DiscountType.Fixed,
          discountAmount: 12.5,
          qty: 12,
          unitPrice: 100,
          saleDocLineTaxes: [
            Object.assign(new SaleDocLineTax(), {
              amount: 13.42,
              taxRule: {
                tax: {
                  shortDesc: "HST",
                },
                taxAcronym: "H",
              },
            }),
          ],
          docLine: Object.assign(new DocLine(), {
            note: "Discount applied due to loyal customer",
            refNo: 1,
            inventory: Object.assign(new Inventory("CAD"), {
              uOfMeasure: {
                name: "box",
              },
              sku: "100012556",
              description1: "PIZZA PANS LOREM IPSUM DOLOR SIT AMET",
              standardPrice: 100,
              standardPriceCurrencyIsoCode: "CAD",
              inventoryBarcodes: [
                {
                  barcode: "4564654879879877878",
                },
              ],
              categories: [
                {
                  categoryName: "Food",
                },
                {
                  categoryName: "Familiar",
                },
                {
                  categoryName: "Snacks",
                },
              ],
            }),
          }),
        }),
        Object.assign(new SaleDocLine(), {
          // discountType: DiscountType.Percentage,
          // discountAmount: 20,
          qty: 5,
          unitPrice: 49.99,
          saleDocLineTaxes: [
            Object.assign(new SaleDocLineTax(), {
              amount: 13.0,
              taxRule: {
                tax: {
                  shortDesc: "HST",
                },
                taxAcronym: "H",
              },
            }),
          ],
          docLine: Object.assign(new DocLine(), {
            refNo: 1,
            inventory: Object.assign(new Inventory("CAD"), {
              uOfMeasure: {
                name: "each",
              },
              sku: "45895113501",
              description1: "DRESSING PANTS VAN HEUSEN",
              standardPrice: 49.99,
              standardPriceCurrencyIsoCode: "CAD",
              inventoryBarcodes: [
                {
                  barcode: "985635751345262",
                },
              ],
              categories: [
                {
                  categoryName: "Apparel",
                },
                {
                  categoryName: "Men",
                },
                {
                  categoryName: "Formal",
                },
                {
                  categoryName: "Designer",
                },
              ],
            }),
          }),
        }),
        Object.assign(new SaleDocLine(), {
          discountType: DiscountType.Fixed,
          discountAmount: 12.5,
          qty: 12,
          unitPrice: 100,
          saleDocLineTaxes: [
            Object.assign(new SaleDocLineTax(), {
              amount: 13.0,
              taxRule: {
                tax: {
                  shortDesc: "HST",
                },
                taxAcronym: "H",
              },
            }),
          ],
          docLine: Object.assign(new DocLine(), {
            note: "Discount applied due to loyal customer",
            refNo: 1,
            inventory: Object.assign(new Inventory("CAD"), {
              uOfMeasure: {
                name: "box",
              },
              sku: "100012556",
              description1: "PIZZA PANS LOREM IPSUM DOLOR SIT AMET",
              standardPrice: 100,
              standardPriceCurrencyIsoCode: "CAD",
              inventoryBarcodes: [
                {
                  barcode: "4564654879879877878",
                },
              ],
              categories: [
                {
                  categoryName: "Food",
                },
                {
                  categoryName: "Familiar",
                },
                {
                  categoryName: "Snacks",
                },
              ],
            }),
          }),
        }),
        // Object.assign(new SaleDocLine, {
        //   // discountType: DiscountType.Percentage,
        //   // discountAmount: 20,
        //   qty: 5,
        //   unitPrice: 49.99,
        //   saleDocLineTaxes: [
        //     Object.assign(new SaleDocLineTax, {
        //       amount: 13.00,
        //       taxRule:{
        //         tax: {
        //           shortDesc: "HST",
        //           taxAcronym: "H"
        //         }
        //       }
        //     })
        //   ],
        //   docLine: Object.assign(new DocLine, {
        //     refNo: 1,
        //     inventory: Object.assign(new Inventory, {
        //       uOfMeasure: {
        //         name: "each"
        //       },
        //       sku: "45895113501",
        //       description1: "DRESSING PANTS VAN HEUSEN",
        //       standardPrice: 49.99,
        //       standardPriceCurrencyIsoCode: "CAD",
        //       inventoryBarcodes: [
        //         {
        //           barcode: "985635751345262"
        //         }
        //       ],
        //       categories: [
        //         {
        //           categoryName: "Apparel",
        //         },
        //         {
        //           categoryName: "Men",
        //         },
        //         {
        //           categoryName: "Formal",
        //         },
        //         {
        //           categoryName: "Designer",
        //         },
        //       ],
        //     }),
        //   })
        // }),
      ],
      saleDocTaxes: [
        Object.assign(new SaleDocTax(), {
          amount: 185.5,
          taxRule: {
            tax: {
              shortDesc: "HST",
            },
            taxAcronym: "H",
          },
        }),
      ],
      saleDocTenders: [
        Object.assign(new SaleDocTender(), {
          amount: 1700,
          tenderType: {
            type: Tender_Type.Credit_Card,
            description: "Visa Credit",
            currencyIsoCode: "CAD",
          },
          refNo: "78978978978944544",
        }),
        Object.assign(new SaleDocTender(), {
          amount: 550,
          tenderType: {
            type: Tender_Type.Debit_Card,
            description: "Debit Card",
            currencyIsoCode: "CAD",
          },
        }),
        Object.assign(new SaleDocTender(), {
          amount: 750,
          tenderType: {
            type: Tender_Type.Credit_Card,
            description: "Credit Card",
            currencyIsoCode: "USD",
          },
          refNo: "Visa card authorization number after PIN pad failed. 1213115632186489",
        }),
        Object.assign(new SaleDocTender(), {
          amount: 524.55,
          tenderType: {
            type: Tender_Type.Cash,
            description: "Cash",
            currencyIsoCode: "CAD",
          },
        }),
      ],
    });

    const policies = Object.assign(new StorePoliciesSettings(), {
      salesPolicy: "Sales Policy",
      shippingPolicy: "Shipping Policy",
      returnPolicy: `
      <p>
        The Store has a 30-days refund policy: customers can buy the product, try it and if they are
        unsatisfied, they can return it within 30 days.
      </p>
      `,
      giftReceiptPolicy: `
      <p>
        This is an example of Gift Receipt policy.
      </p>
      <p>
        May be valid for store credit. See return policy in store or online for details on specific items.
      </p>
      `,
    });

    return this.appSettingsService
      .getBusinessDetails()
      .pipe(map((businessDetail) => ({ saledoc, policies, businessDetail })));
  }
}

export class PrintingMgr {
  private _isReadySource = new ReplaySubject<any>(1);
  isReady = this._isReadySource.asObservable();

  letterSizeBuilderSettings: StoreSettingLetterSalePrint | StoreSettingLetterReturnPrint;
  tapeSizeBuilderSettings: StoreSettingTapeSalePrint | StoreSettingTapeReturnPrint;
  letterSizeReturnBuilderSettings: StoreSettingLetterReturnPrint;
  tapeSizeReturnBuilderSettings: StoreSettingTapeReturnPrint;
  giftReceiptBuilderSettings: StoreSettingGiftReceipt;
  storePolicies: StorePoliciesSettings;
  businessDetail: BusinessDetail;

  constructor(
    private dbService: SaleDocService,
    private purchaseDocService: PurchaseDocService,
    private appSettingsService: AppSettingsStorageService,
    private messageService: MessageService,
    private printQueue: PrintingQueueService,
    private starCloudPrintServiceService: StarCloudPrintServiceService
  ) {}

  static PrintPreviewBuilder = class {
    private componentRef: ComponentRef<ReceiptPreviewBaseComponent>;
    _componentInstance: ReceiptPreviewBaseComponent;

    constructor(
      private printMgr: PrintingMgr,
      previewHost: ViewContainerRef,
      component: Type<ReceiptPreviewBaseComponent>
    ) {
      previewHost.clear();
      this.componentRef = previewHost.createComponent(component);
      this._componentInstance = this.componentRef.instance;

      if (this._componentInstance instanceof ReceiptFullPreviewComponent) {
        this._componentInstance.settings = this.printMgr.letterSizeBuilderSettings;
      } else if (this._componentInstance instanceof ReceiptTapePreviewComponent) {
        this._componentInstance.settings = this.printMgr.tapeSizeBuilderSettings;
      } else if (this._componentInstance instanceof GiftReceiptTapePreviewComponent) {
        this._componentInstance.settings = this.printMgr.giftReceiptBuilderSettings;
      } else if (this._componentInstance instanceof OpenCashDrawerPreviewComponent) {
        this._componentInstance.settings = null;
        // } else if (this._componentInstance instanceof PurchaseOrderFullPreviewComponent) {
        //   this._componentInstance.settings = null;
      }
      if (
        !(this._componentInstance instanceof TenderReceiptTapePreviewComponent) &&
        !(this._componentInstance instanceof ClosedBatchDetailsTapePreviewComponent)
      ) {
        this._componentInstance.policies = this.printMgr.storePolicies;
        this._componentInstance.businessDetail = this.printMgr.businessDetail;
      }
    }

    buildWithSaleDoc(saleDoc: SaleDoc): void {
      if (saleDoc.doc.docType === SaleDocType.sales_return) {
        if (this._componentInstance instanceof ReceiptFullPreviewComponent) {
          this._componentInstance.settings = this.printMgr.letterSizeReturnBuilderSettings;
        } else if (this._componentInstance instanceof ReceiptTapePreviewComponent) {
          this._componentInstance.settings = this.printMgr.tapeSizeReturnBuilderSettings;
        }
      }

      this._componentInstance.saledoc = saleDoc;

      this.componentRef.changeDetectorRef.detectChanges();
    }

    buildWithPurchaseDoc(purchaseDoc: PurchaseDoc, StoreInfo: Store): void {
      this._componentInstance["purchaseDoc"] = purchaseDoc;
      this._componentInstance["storeInfo"] = StoreInfo;

      this.componentRef.changeDetectorRef.detectChanges();
    }

    buildWithoutSaleDoc(): void {
      if (this._componentInstance instanceof ReceiptFullPreviewComponent) {
        this._componentInstance.settings = this.printMgr.letterSizeReturnBuilderSettings;
      } else if (this._componentInstance instanceof ReceiptTapePreviewComponent) {
        this._componentInstance.settings = this.printMgr.tapeSizeReturnBuilderSettings;
      }

      // this.componentRef.changeDetectorRef.detectChanges();
    }

    get componentInstance(): ReceiptPreviewBaseComponent {
      return this._componentInstance;
    }
  };

  _checkAndPullMissingSettings(storeId: number) {
    const currStoreFilter = JSON.stringify({
      storeId: { value: storeId, matchMode: "equals" },
    });

    const [
      letterSalePrintSettings$,
      tapeSalePrintSettings$,
      giftReceiptSettings$,
      storePolicySettings$,
      letterReturnPrintSettings$,
      tapeReturnPrintSettings$,
    ] = [
      "storeSettingLetterSalePrint",
      "storeSettingTapeSalePrint",
      "storeSettingGiftReceipt",
      "storePolicy",
      "storeSettingLetterReturnPrint",
      "storeSettingTapeReturnPrint",
    ].map((settingModelName) => {
      // If store requested is the same as Current Store, so ask to service
      if (this.appSettingsService.getStoreId() === storeId)
        return this.appSettingsService.getStoreSettings(settingModelName);
      else
        return this.dbService
          .getRows(settingModelName, currStoreFilter)
          .pipe(map((response: any) => (response.rows.length ? response.rows[0] : null)));
    });
    const businessDetails$ = this.appSettingsService.getBusinessDetails();

    const settingsRequests: Observable<any>[] = [];
    if (!this.letterSizeBuilderSettings)
      settingsRequests.push(
        letterSalePrintSettings$.pipe(tap((settings: any) => (this.letterSizeBuilderSettings = settings)))
      );

    if (!this.tapeSizeBuilderSettings)
      settingsRequests.push(
        tapeSalePrintSettings$.pipe(tap((settings: any) => (this.tapeSizeBuilderSettings = settings)))
      );

    if (!this.giftReceiptBuilderSettings)
      settingsRequests.push(
        giftReceiptSettings$.pipe(
          tap((settings: StoreSettingGiftReceipt) => (this.giftReceiptBuilderSettings = settings))
        )
      );

    if (!this.storePolicies)
      settingsRequests.push(storePolicySettings$.pipe(tap((settings: any) => (this.storePolicies = settings))));

    if (!this.businessDetail)
      settingsRequests.push(businessDetails$.pipe(tap((settings: any) => (this.businessDetail = settings))));

    if (!this.letterSizeReturnBuilderSettings)
      settingsRequests.push(
        letterReturnPrintSettings$.pipe(tap((settings: any) => (this.letterSizeReturnBuilderSettings = settings)))
      );

    if (!this.tapeSizeReturnBuilderSettings)
      settingsRequests.push(
        tapeReturnPrintSettings$.pipe(tap((settings: any) => (this.tapeSizeReturnBuilderSettings = settings)))
      );

    const fnNotifyIsReady = () => {
      this._isReadySource.next(true);
      this._isReadySource.complete();
    };

    // If there is nothing to fetch, just make it ready immediately
    if (settingsRequests.length == 0) {
      fnNotifyIsReady();
      return;
    }

    forkJoin(settingsRequests).subscribe({
      next: (responses) => {
        fnNotifyIsReady();
      },
      error: (error) => {
        this._isReadySource.error(error);
      },
    });
  }

  private _printCssCache: Partial<Record<ReceiptPrintingSize, string>> = {};
  getPrintCss = async (printSize: ReceiptPrintingSize): Promise<string> => {
    // If fetched previously, return from cache
    if (this._printCssCache[printSize]) {
      return Promise.resolve(this._printCssCache[printSize]);
    }

    const invoiceUrl =
      "assets/layout/taku/" +
      (printSize === ReceiptPrintingSize.LETTER_SIZE
        ? "receipt-full-preview.component.css"
        : "receipt-tape-preview.component.css");
    const res = await fetch(invoiceUrl);
    let cssString = await res.text();

    // Remove comments in CSS file
    cssString = cssString.replace(/\/\*[\s\S]*?\*\/|([^\\:]|^)\/\/.*$/gm, "");

    // set in cache
    this._printCssCache[printSize] = cssString;
    return cssString;
  };

  getPurchasePrintCss = async (): Promise<string> => {
    // If fetched previously, return from cache
    if (this._printCssCache["PurchaseOrder"]) {
      return Promise.resolve(this._printCssCache["PurchaseOrder"]);
    }

    const invoiceUrl = "assets/layout/taku/purchase-order-full-preview.component.css";
    const res = await fetch(invoiceUrl);
    let cssString = await res.text();

    // Remove comments in CSS file
    cssString = cssString.replace(/\/\*[\s\S]*?\*\/|([^\\:]|^)\/\/.*$/gm, "");

    // set in cache
    this._printCssCache["PurchaseOrder"] = cssString;
    return cssString;
  };

  sendInvoiceEmail(
    saleDoc: SaleDoc,
    emailAddress: string,
    previewHost: ViewContainerRef,
    printSize: ReceiptPrintingSize,
    silent = false
  ): Observable<any> {
    const previewComponent = this.getReceiptComponentFromSize(printSize);
    if (!previewComponent) return of();

    const previewBuilder = new PrintingMgr.PrintPreviewBuilder(this, previewHost, previewComponent);
    previewBuilder.buildWithSaleDoc(saleDoc);
    let invoiceHTML = (<HTMLElement>previewBuilder.componentInstance.elementRef.nativeElement).innerHTML;
    // Remove comments from HTML
    invoiceHTML = invoiceHTML.replace(/<!--[\s\S]*?-->/g, "");

    // Get css files from asset folder
    return from(this.getPrintCss(printSize)).pipe(
      switchMap((invoiceCSS) => this.dbService.postEmailReceipt(saleDoc.id, emailAddress, invoiceHTML, invoiceCSS)),
      tap((response: any) => {
        if (!silent) {
          this.messageService.add({
            summary: "EMAIL SENT",
            detail: response.message || "Email was sent successfully",
            severity: "success",
          });
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (!silent) {
          this.messageService.add({
            summary: "EMAIL NOT SENT",
            detail:
              error.error.message ?? error.error.customMessage ?? "An error ocurred when trying to send receipt email",
            severity: "error",
          });
        }
        return throwError(error);
      })
    );
  }

  sendPurchaseDocEmail(
    purchaseDoc: PurchaseDoc,
    emailAddress: string,
    previewHost: ViewContainerRef,
    silent = false
  ): Observable<any> {
    const previewBuilder = new PrintingMgr.PrintPreviewBuilder(this, previewHost, PurchaseOrderFullPreviewComponent);
    previewBuilder.buildWithPurchaseDoc(purchaseDoc, this.appSettingsService.getStore());
    let invoiceHTML = (<HTMLElement>previewBuilder.componentInstance.elementRef.nativeElement).innerHTML;
    // Remove comments from HTML
    invoiceHTML = invoiceHTML.replace(/<!--[\s\S]*?-->/g, "");
    // Get css files from asset folder
    return from(this.getPurchasePrintCss()).pipe(
      switchMap((invoiceCSS) =>
        this.purchaseDocService.postEmailReceipt(purchaseDoc.id, emailAddress, invoiceHTML, invoiceCSS)
      ),
      tap((response: any) => {
        if (!silent && response.success) {
          this.messageService.add({
            summary: "EMAIL SENT",
            detail: response.message || "Email was sent successfully",
            severity: "success",
          });
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (!silent) {
          this.messageService.add({
            summary: "EMAIL NOT SENT",
            detail:
              error.error.message ?? error.error.customMessage ?? "An error ocurred when trying to send receipt email",
            severity: "error",
          });
        }
        return throwError(error);
      })
    );
  }

  printOpeningCashoutSlip(
    cashouts: Cashout[],
    expectedCash: number,
    actualAmount: number,
    currencyIsoCode: string,
    cashoutSettings: StoreSettingCashout,
    printSize: ReceiptPrintingSize,
    previewHost: ViewContainerRef
  ) {
    let openingCashoutComponent: Type<CashoutSlipBase>;
    switch (printSize) {
      case ReceiptPrintingSize.TAPE_SIZE:
        openingCashoutComponent = OpeningCashoutSlipTapeSizeComponent;
        break;

      case ReceiptPrintingSize.LETTER_SIZE:
        openingCashoutComponent = OpeningCashoutSlipFullSizeComponent;
        break;
    }

    if (!openingCashoutComponent) {
      console.warn("Cashout slip printing size NOT recognized", printSize);
      return;
    }

    previewHost.clear();
    const componentRef = previewHost.createComponent(openingCashoutComponent);
    const compInstance = componentRef.instance;
    compInstance.openingExpectedCash = expectedCash;
    compInstance.openingActualAmount = actualAmount;
    compInstance.currencyIsoCode = currencyIsoCode;
    compInstance.cashoutSettings = cashoutSettings;

    this.printDocsInBatch(cashouts, compInstance.getStylesheets(), "Opening Cashout Slip", (index) => {
      compInstance.cashout = cashouts[index];

      return compInstance.onDataReady$.pipe(
        map((status) => {
          if (!status) return of();

          componentRef.changeDetectorRef.detectChanges();
          return compInstance.getHTMLContent();
        })
      );
    });
  }

  printCashoutSlip(
    cashouts: Cashout[],
    deposits: CashoutDeposits[],
    currencyIsoCode: string,
    cashoutSettings: StoreSettingCashout,
    printSize: ReceiptPrintingSize,
    previewHost: ViewContainerRef
  ) {
    let cashoutComponent: Type<CashoutSlipBase>;
    switch (printSize) {
      case ReceiptPrintingSize.TAPE_SIZE:
        cashoutComponent = ClosingCashoutSlipTapeSizeComponent;
        break;

      case ReceiptPrintingSize.LETTER_SIZE:
        cashoutComponent = ClosingCashoutSlipFullSizeComponent;
        break;

      case ReceiptPrintingSize.TAPE_SIZE_CASHOUT_REPORT:
        cashoutComponent = ClosingCashoutReportTapeSizeComponent;
        break;
    }

    if (!cashoutComponent) {
      console.warn("Cashout slip printing size NOT recognized", printSize);
      return;
    }

    previewHost.clear();
    const componentRef = previewHost.createComponent(cashoutComponent);
    const compInstance = componentRef.instance;
    compInstance.deposits = deposits;
    compInstance.currencyIsoCode = currencyIsoCode;
    compInstance.cashoutSettings = cashoutSettings;

    this.printDocsInBatch(cashouts, compInstance.getStylesheets(), "Cashout Slip", (index) => {
      compInstance.cashout = cashouts[index];

      return compInstance.onDataReady$.pipe(
        map((status) => {
          if (!status) return of();

          componentRef.changeDetectorRef.detectChanges();
          return compInstance.getHTMLContent();
        })
      );
    });
  }

  getReceiptComponentFromSize(printSize: ReceiptPrintingSize): Type<ReceiptPreviewBaseComponent> {
    switch (printSize) {
      case ReceiptPrintingSize.LETTER_SIZE:
        return ReceiptFullPreviewComponent;

      case ReceiptPrintingSize.TAPE_SIZE:
        return ReceiptTapePreviewComponent;
    }
  }

  printStarCloudPRNTPrepInBatch(saleDocs: SaleDoc[]) {
    for (let index = 0; index < saleDocs.length; index++) {
      setTimeout(() => {
        this.starCloudPrintServiceService.printStarCloudPrepPRNT(saleDocs[index], this.tapeSizeBuilderSettings);
      }, 200);
    }
  }

  printStarCloudPRNTReceiptOpenCashInBatch() {
    setTimeout(() => {
      this.starCloudPrintServiceService.printStarCloudPRNTOpenCash(this.tapeSizeBuilderSettings);
    }, 200);
  }

  printStarCloudPRNTReceiptInBatch(saleDocs: SaleDoc[]) {
    for (let index = 0; index < saleDocs.length; index++) {
      setTimeout(() => {
        this.starCloudPrintServiceService.printStarCloudPRNT(saleDocs[index], this.tapeSizeBuilderSettings);
      }, 200);
    }
  }

  printDocumentsWithSize(saleDocs: SaleDoc[], previewHost: ViewContainerRef, printSize: ReceiptPrintingSize): void {
    const previewComponent = this.getReceiptComponentFromSize(printSize);
    if (!previewComponent) return;
    if (
      printSize === ReceiptPrintingSize.TAPE_SIZE &&
      this.appSettingsService.getStation() &&
      ((saleDocs[0]["isPackingList"] &&
        this.appSettingsService.getStation().packingListPrinter?.manufacturer === PrinterManufacturer.star_cloudPRNT) ||
        (!saleDocs[0]["isPackingList"] &&
          this.appSettingsService.getStation().receiptPrinter?.manufacturer === PrinterManufacturer.star_cloudPRNT))
    ) {
      this.printStarCloudPRNTReceiptInBatch(saleDocs);
    } else {
      this.printSaleDocsInBatch(saleDocs, previewHost, previewComponent);
    }
  }

  printOpenCashDrawer(previewHost: ViewContainerRef, printSize: ReceiptPrintingSize): void {
    const previewComponent = OpenCashDrawerPreviewComponent;
    if (!previewComponent) return;
    if (
      printSize === ReceiptPrintingSize.TAPE_SIZE &&
      this.appSettingsService.getStation() &&
      (this.appSettingsService.getStation().packingListPrinter?.manufacturer === PrinterManufacturer.star_cloudPRNT ||
        this.appSettingsService.getStation().receiptPrinter?.manufacturer === PrinterManufacturer.star_cloudPRNT)
    ) {
      this.printStarCloudPRNTReceiptOpenCashInBatch();
    } else {
      this.printOpenCashDrawerInBatch(previewHost, previewComponent);
    }
  }

  printOpenCashDrawerInBatch<T extends ReceiptPreviewBaseComponent>(previewHost: ViewContainerRef, component: Type<T>) {
    const previewBuilder = new PrintingMgr.PrintPreviewBuilder(this, previewHost, component);

    this.printCashDrawerInBatch([previewBuilder.componentInstance.STYLESHEET_PATH], "Open Cash Drawer", (index) => {
      // const saleDoc = SaleDoc.convertIntoTypedObject(saleDocs[index]);

      previewBuilder.buildWithoutSaleDoc();
      return of(<HTMLElement>previewBuilder.componentInstance.elementRef.nativeElement);
    });
  }

  printSaleDocsInBatch<T extends ReceiptPreviewBaseComponent>(
    saleDocs: SaleDoc[],
    previewHost: ViewContainerRef,
    component: Type<T>
  ): void {
    for (const saleDocToPrint of saleDocs) {
      this.printQueue.enqueuePrintJob(() => {
        const previewBuilder = new PrintingMgr.PrintPreviewBuilder(this, previewHost, component);
        const saleDoc = SaleDoc.convertIntoTypedObject(saleDocToPrint);

        previewBuilder.buildWithSaleDoc(saleDoc);

        return PrintHelpers.printElementContents(
          previewBuilder.componentInstance.elementRef.nativeElement,
          [previewBuilder.componentInstance.STYLESHEET_PATH],
          "Receipt - Print Preview"
        );
      });
    }
  }

  printTemplate<T extends ReceiptPreviewBaseComponent>(
    template: any,
    previewHost: ViewContainerRef,
    component: Type<T>,
    title: string
  ): void {
    const previewBuilder = new PrintingMgr.PrintPreviewBuilder(this, previewHost, component);

    this.printOne(template, [previewBuilder.componentInstance.STYLESHEET_PATH], title);
  }

  printTendersTemplates<T extends ReceiptPreviewBaseComponent>(
    templates: [SaleDocTender, string][],
    previewHost: ViewContainerRef,
    component: Type<T>,
    title: string
  ): void {
    for (const template of templates) {
      this.printQueue.enqueuePrintJob(() => {
        const previewBuilder = new PrintingMgr.PrintPreviewBuilder(this, previewHost, component);

        return PrintHelpers.printElementContents(
          template[1] as unknown as HTMLElement,
          [previewBuilder.componentInstance.STYLESHEET_PATH],
          title
        );
      });
    }
  }

  private printDocsInBatch(
    docs: any[],
    stylesheets: string[],
    title: string,
    htmlGenerator: (index: number) => Observable<HTMLElement>
  ) {
    docs.forEach((doc, index) => {
      htmlGenerator(index).subscribe((componentEl) => {
        this.printQueue.enqueuePrintJob(() => {
          return PrintHelpers.printElementContents(componentEl, stylesheets, title);
        });
      });
    });
  }

  private printOne(doc: HTMLElement, stylesheets: string[], title: string) {
    this.printQueue.enqueuePrintJob(() => {
      return PrintHelpers.printElementContents(doc, stylesheets, title);
    });
  }

  private printCashDrawerInBatch(
    stylesheets: string[],
    title: string,
    htmlGenerator: (index: number) => Observable<HTMLElement>
  ) {
    htmlGenerator(0).subscribe((componentEl) => {
      this.printQueue.enqueuePrintJob(() => {
        return PrintHelpers.printElementContents(componentEl, stylesheets, title);
      });
    });
  }

  printInventoryLabels(inventories: Inventory[], formGroup: UntypedFormGroup, previewHost: ViewContainerRef): void {
    previewHost.clear();
    const componentRef = previewHost.createComponent(InventoryLabelPrintingComponent);
    const compInstance = componentRef.instance;
    compInstance.formGroup = formGroup;
    compInstance.inventories = inventories;

    compInstance.onDataReady$.pipe(take(1)).subscribe(() => {
      this.printOne(compInstance.getHTMLContent(), compInstance.getStylesheets(), "Labels Printing");
    });
  }

  printPurchaseDocsInBatch(purchaseDocs: PurchaseDoc[], storeInfo: Store, previewHost: ViewContainerRef): void {
    previewHost.clear();
    const componentRef = previewHost.createComponent(PurchaseOrderFullPreviewComponent);
    const compInstance = componentRef.instance;

    this.printDocsInBatch(purchaseDocs, compInstance.getStylesheets(), "Purchase - Print Preview", (index) => {
      compInstance.purchaseDoc = purchaseDocs[index];
      compInstance.storeInfo = storeInfo;

      return compInstance.onDataReady$.pipe(map(() => compInstance.getHTMLContent()));
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
