/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import {
  Component,
  OnInit,
  OnChanges,
  Input,
  ElementRef,
  SimpleChanges,
  ViewChild,
  AfterViewInit,
  AfterViewChecked,
} from "@angular/core";
import {
  StoreSettingLetterSalePrint,
  Sort_Order,
  Font_Size,
} from "../../../settings/store-settings/full-page-salereceipt-builder/StoreSettingLetterSalePrint";
import { SaleDoc } from "../../../document/sale-document/sale-doc/sale-doc";
import { StorePoliciesSettings } from "../../../settings/store-settings/store-policies-settings/StorePoliciesSettings";
import { SaleDocTender } from "../../../document/sale-document/sale-doc-tender/sale-doc-tender";
import { Tender_Type } from "../../../settings/business-settings/tender-type/tender-type";
import { InvoiceHelpers } from "../../../../utility/InvoiceHelpers";
import { SaleDocLine } from "../../../document/sale-document/sale-doc-line/sale-doc-line";
import * as _ from "lodash";
import { Address } from "../../../contact-accounts/address/address";
import * as JsBarcode from "jsbarcode";
import { BusinessDetail } from "src/app/core/settings/business-settings/business-detail/business-detail";
import { Store } from "src/app/core/settings/store-settings/store/store";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { LocationService } from "src/app/shared/services/location.service";
import { DocState, SaleDocType } from "src/app/core/document/doc/doc";
import { StoreSettingLetterReturnPrint } from "src/app/core/settings/store-settings/full-page-returnreceipt-builder/StoreSettingLetterReturnPrint";

type PageInfo = {
  pageNumber: number;
  isFirstPage: boolean;
  isLastPage: boolean;
  saleDocLines: SaleDocLine[];
};

class PrintPaginator {
  static readonly LINES_PER_FULLPAGE = 10;
  static readonly LINES_BILLING_SHIPPING = 2;
  static readonly LINES_FOOTER = 4;

  readonly totalPages: number;

  constructor(private saleDoc: SaleDoc) {
    this.totalPages = Math.ceil(saleDoc.saleDocLines.length / PrintPaginator.LINES_PER_FULLPAGE);
  }

  getPagesList(): PageInfo[] {
    const pagesInfo: PageInfo[] = [];

    for (let pageNo = 1; pageNo <= this.totalPages; pageNo++) {
      const linesPerCurrPage = PrintPaginator.LINES_PER_FULLPAGE;
      const isFirstPage = pageNo == 1;
      const isLastPage = pageNo == this.totalPages;

      //if (isFirstPage) linesPerCurrPage -= PrintPaginator.LINES_BILLING_SHIPPING;
      //if (isLastPage) linesPerCurrPage -= PrintPaginator.LINES_FOOTER;

      const pageDocLines = this.saleDoc.saleDocLines.slice(linesPerCurrPage * (pageNo - 1), linesPerCurrPage * pageNo);

      pagesInfo.push({
        pageNumber: pageNo,
        isFirstPage,
        isLastPage,
        saleDocLines: pageDocLines,
      });
    }

    return pagesInfo;
  }
}

@Component({
  selector: "taku-receipt-full-preview",
  templateUrl: "./receipt-full-preview.component.html",
  styleUrls: ["../../../../../assets/layout/taku/receipt-full-preview.component.css"],
})
export class ReceiptFullPreviewComponent implements OnChanges, OnInit, AfterViewChecked {
  @Input("saleDocData") saledoc: SaleDoc;
  @Input("builderSettings") settings: StoreSettingLetterSalePrint | StoreSettingLetterReturnPrint;
  @Input() policies: StorePoliciesSettings;
  @Input() storeInfo?: Store;
  @Input() businessDetail: BusinessDetail;
  @ViewChild("returnsBarcode") returnsBarcodeRef: ElementRef;

  hideNotReceiptWatermark = true;

  private _paginator: PrintPaginator;
  readonly STYLESHEET_PATH = "/assets/layout/taku/receipt-full-preview.component.css";
  _cardPayments: SaleDocTender[] = [];
  _cashPayments: SaleDocTender[] = [];
  _otherPayments: SaleDocTender[] = [];

  _totalCashPayments = 0;
  _roundedBalanceBeforeCash = 0;
  Math: Math = Math;
  pagesInfo: PageInfo[];
  totalPages: number;
  _fullAddressMap = "";

  _digitsFormat = "1.2-2";
  _currencyIsoCode = null;

  constructor(
    public elementRef: ElementRef,
    private appSettingsService: AppSettingsStorageService,
    public lService: LocationService
  ) {}

  get businessLogoUrl(): string {
    const store = this.storeInfo || this.appSettingsService.getStore();
    // First try to use the Store's logo, if not available fallback to Business's logo
    const logoUrl = store.urlStoreLogoUrl || this.businessDetail.urlBusinessDetailBusinessLogoUrl;
    return logoUrl; // WebHelpers.buildImageUrl(logoUrl);
  }

  ngOnInit(): void {
    this.onSaleDocChanged();
    this.onSettingsChanged();
    this._fullAddressMap = [
      this.saledoc.store.address.line1,
      this.saledoc.store.address.line2,
      "".concat(
        this.saledoc.store.address.city,
        ", " +
          this.lService.lookupSubdivisionName(
            this.saledoc.store.address.countryIsoCode,
            this.saledoc.store.address.subDivisionIsoCode
          ) +
          ", " +
          this.lService.lookupCountryByIsoCode(this.saledoc.store.address.countryIsoCode) +
          " , ",
        this.saledoc.store.address.postalCode
      ),
    ]
      .filter(Boolean)
      .join(" ");
    this.hideNotReceiptWatermark =
      this.saledoc.doc.state !== DocState.voided &&
      this.saledoc.doc.state !== DocState.draft &&
      this.saledoc.doc.state !== DocState.suspended;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._currencyIsoCode = this.saledoc.doc.currencyIsoCode;

    if (changes["saledoc"] && !changes["saledoc"].firstChange) {
      this.onSaleDocChanged();
    }

    if (changes["settings"] && !changes["settings"].firstChange) {
      this.onSettingsChanged();
    }
  }

  onSaleDocChanged(): void {
    this._paginator = new PrintPaginator(this.saledoc);
    this.totalPages = this._paginator.totalPages;
    this.pagesInfo = this._paginator.getPagesList();

    this._cardPayments = this.saledoc.notReturnedTenders.filter((tender) => {
      return [Tender_Type.Credit_Card, Tender_Type.Debit_Card].includes(tender.tenderType.type);
    });

    this._cashPayments = this.saledoc.notReturnedTenders.filter((tender) => {
      return Tender_Type.Cash == tender.tenderType.type;
    });

    // contains the rest of payment tenders
    this._otherPayments = _.difference(this.saledoc.notReturnedTenders, this._cardPayments, this._cashPayments);
    const invoiceHelper = new InvoiceHelpers(this.saledoc);
    this._roundedBalanceBeforeCash = invoiceHelper.roundedBalanceBeforeCash();
    this._totalCashPayments = invoiceHelper.totalCashPayments();
  }

  onSettingsChanged(): void {
    this.sortLines(this.settings?.lineSortOrder);
  }

  ngAfterViewChecked(): void {
    if (this.returnsBarcodeRef)
      JsBarcode(this.returnsBarcodeRef.nativeElement, this.saledoc.doc.docNo, {
        format: "CODE128",
        height: 120,
        margin: 0,
        fontSize: 30,
      });
  }

  categoriesPrettyPrint(saleLine: SaleDocLine): string {
    if (!saleLine.docLine) return "";

    return saleLine.docLine.inventory.categories.map((category) => category.categoryName).join(", ");
  }

  hasAddressInfo(address: Address) {
    return (
      address &&
      (address.line1 ||
        address.line2 ||
        address.city ||
        address.postalCode ||
        address.attnFullPersonName ||
        (address.addressPhone && address.addressPhone.tel) ||
        (address.addressEmail && address.addressEmail.email))
    );
  }

  // get shouldDisplayShippingAddress(): boolean {
  //   // should display shipping address only if it is NOT a store sale
  //   return this.saledoc.doc.docType !== SaleDocType.sales_invoice;
  // }

  sortLines(sortMethod: Sort_Order): any {
    let fieldAccessFn;

    switch (sortMethod) {
      case Sort_Order.Category:
        fieldAccessFn = (line: SaleDocLine) => {
          return line.docLine ? line.docLine.inventory.description1 : null;
        };
        break;

      case Sort_Order.Discount:
        fieldAccessFn = (line: SaleDocLine) => {
          return line.lineDiscount;
        };
        break;

      case Sort_Order.SKU:
        fieldAccessFn = (line: SaleDocLine) => {
          return line.docLine ? line.docLine.inventory.sku : null;
        };
        break;

      case Sort_Order.Tax_Inventory_Category:
        fieldAccessFn = (line: SaleDocLine) => {
          return line.saleDocLineTaxes[0].taxRuleId;
        };
        break;
    }

    if (fieldAccessFn) {
      this.saledoc.saleDocLines.sort((line1: SaleDocLine, line2: SaleDocLine) => {
        const cmpField1 = fieldAccessFn(line1);
        const cmpField2 = fieldAccessFn(line2);

        if (cmpField1 == cmpField2) {
          return 0;
        } else {
          return cmpField1 < cmpField2 ? -1 : 1;
        }
      });
    }
  }

  subdivisionDisplay(subDivisionIsoCode: string): string {
    return subDivisionIsoCode ? subDivisionIsoCode.split("-").pop() : "";
  }

  fontSizeToNumber(fontSize: Font_Size): number {
    switch (fontSize) {
      case Font_Size.medium:
        return 13;

      case Font_Size.small:
        return 11;
    }
  }

  getLineTaxAcronyms(line: SaleDocLine): string {
    return line.saleDocLineTaxes
      .filter((lineTax) => lineTax.taxRule)
      .map((lineTax) => lineTax.taxRule.taxAcronym)
      .join("");
  }

  inventoryVariantsStr(saleLine: SaleDocLine): string {
    return saleLine.docLine.inventory.inventoryVariants
      ?.map(
        (_inventoryVariant) =>
          _inventoryVariant.inventoryOptionsSetting.optionName +
          ": " +
          _inventoryVariant.inventoryOptionsSettingValue?.optionValue
      )
      .join(", ");
  }

  get isReturnDoc(): boolean {
    return this.saledoc.doc.docType === SaleDocType.sales_return;
  }

  get printSalePolicy(): boolean {
    return this.settings?.["printSalePolicy"];
  }

  get printReturnPolicy(): boolean {
    return this.settings?.["printReturnPolicy"];
  }

  get printInvoiceSavingLine(): boolean {
    return this.settings?.["printInvoiceSavingLine"];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
