/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Input, OnInit } from "@angular/core";
import { Observable, of } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { Inventory } from "../../core/inventory/inventory/inventory";
import { SearchFilterIcons, SearchResultItem } from "../taku-search-accounts/SearchResultItem";
import {
  AutoCompleteSearchResults,
  TakuSearchAccountsComponent,
} from "../taku-search-accounts/taku-search-accounts.component";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { DBSearchService } from "src/app/shared/services/db-search.service";
import { ConfirmationService } from "primeng/api";
import * as _ from "lodash";
import { AuthService } from "src/app/shared/services/auth.service";
import { InvoiceKeyboardActionsService } from "src/app/core/document/sale-document/sale-invoice-doc-base/invoice-keyboard-actions.service";
import { FilterRows } from "src/app/utility/FormDataHelpers";

@Component({
  selector: "taku-search-inventory",
  templateUrl: "../taku-search-accounts/taku-search-accounts.component.html",
  styleUrls: ["../taku-search-accounts/taku-search-accounts.component.scss"],
})
export class TakuSearchInventoryComponent extends TakuSearchAccountsComponent implements OnInit {
  @Input() currentFetchLimit: number;
  @Input() override searchFilter: FilterRows<Inventory>;

  //access rights flags
  addInventoryVisible = false;
  addInventoryEnabled = false;

  filterTypes = [{ label: "Inventory", value: "product", icon: SearchFilterIcons.INVENTORY }];
  _searchItemsType = "inventory";

  _newItemsInfo = {};

  protected _defaultQueryParams = {};
  protected _defaultSearchFilter = {
    // active: { matchMode: 'equals', value: true }
  };

  constructor(
    dataService: DBSearchService,
    protected appSettings: AppSettingsStorageService,
    confirmationService: ConfirmationService,
    authService: AuthService,
    keyboardActionsService: InvoiceKeyboardActionsService
  ) {
    super(dataService, confirmationService, authService, keyboardActionsService, appSettings);

    this._defaultQueryParams = {
      zoneId: this.appSettings.getZoneId(),
    };

    if (this.appSettings.getStore().stockId) this._defaultQueryParams["stockId"] = this.appSettings.getStore().stockId;

    this.addInventoryVisible = authService.hasVisiblePermit("Inventory_Menu_Inventory_List");
    this.addInventoryEnabled = authService.hasEnablePermit("Inventory_Menu_Inventory_List");
  }

  ngOnInit(): void {
    super.ngOnInit();

    this._newItemsInfo = {
      product: {
        label: "Product",
        imagePath: SearchFilterIcons.INVENTORY,
        modelName: "inventory",
        visible: this.addInventoryVisible,
        disabled: !this.addInventoryEnabled,
      },
    };
  }

  private _searchInventory(query: string): Observable<AutoCompleteSearchResults> {
    const fetchLimit = this.currentFetchLimit || TakuSearchAccountsComponent.MAX_MORE_RESULTS;
    const extraQueryParams = _.defaults({}, this.extraQueryParams, this._defaultQueryParams);
    return this.dataService
      .searchInventory({
        searchValue: query,
        _filter: JSON.stringify(this.searchFilter),
        _offset: 0,
        _limit: fetchLimit,
        extraQueryParams,
      })
      .pipe(
        map((searchResults) => {
          const items: SearchResultItem[] = [];
          let filteredIsSellable = false;
          if (searchResults.rows && searchResults.rows.length > 0) {
            searchResults.rows.forEach((item: Inventory) => {
              // filtering out unsellable items from the response
              if (item.isSellable) {
                items.push(SearchResultItem.build("inventory", item));
              }
            });
            filteredIsSellable = true;
          }
          return { count: Number(searchResults.count), rows: items, filteredIsSellable };
        }),
        catchError(() => of({ count: 0, rows: [], filteredIsSellable: false }))
      );
  }

  protected override _searchAll(query: string): Observable<AutoCompleteSearchResults> {
    return this._searchInventory(query);
  }

  override prefillDataFromSearchText(modelName: string): Observable<Inventory> {
    const defaultData = new Inventory(this.appSettings.getZone()?.defaultCurrencyIsoCode);

    if (/^\d+$/.test(this.textSearch)) {
      // if only digits, this is barcode number
      defaultData.inventoryBarcodes = [
        {
          barcode: this.textSearch,
          barcodeFormat: null,
          barcodeType: null,
          id: 0,
        },
      ];
    } else {
      // It's description
      defaultData.description1 = this.textSearch;
    }

    return of(defaultData);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
