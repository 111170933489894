/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, ViewChild } from "@angular/core";
import { Col } from "src/app/form-list/form-list/form-list";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Observable, Observer, of, Subscription } from "rxjs";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { map } from "rxjs/operators";
import { SaleChannelInventoryService } from "./sale-channel-inventory.service";
import { ConfirmationService, MessageService } from "primeng/api";
import { FormListComponent } from "src/app/form-list/form-list/form-list.component";
import { Inventory } from "../../inventory/inventory/inventory";
import { DBService } from "src/app/shared/services/db.service";
import { Location } from "@angular/common";
import { SaleChannel } from "../sale-channel";
import { DialogService } from "primeng/dynamicdialog";
import { GoogleShoppingPushSelectedDialogComponent } from "../../settings/integration-settings/google-shopping/google-shopping-push-selected-dialog/google-shopping-push-selected-dialog.component";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";
import { OnlineStatus, UploadStatus } from "../../inventory/inventory-sale-channel/inventory-sale-channel";
import * as _ from "lodash";
@Component({
  selector: "taku-sale-channel-inventory",
  templateUrl: "./sale-channel-inventory.component.html",
  styleUrls: ["./sale-channel-inventory.component.scss"],
  providers: [],
})
export class SaleChannelInventoryComponent implements OnInit {
  _isFetchingData = false;
  subsList: Subscription[] = [];
  @ViewChild(FormListComponent) _formListComponent: FormListComponent;
  _isDataReady = false;
  _formListCols: Col[];
  _model = "inventory";
  _formListRules: {};
  _channelTitle: string = null;
  activeStoreId;
  isAdmin: boolean;
  _formListFilter: any = {};
  _saleChannel: SaleChannel;
  _extraQueryParams = {};
  stateKey = "saleChannelInventory";

  constructor(
    public saleChannelInventoryService: SaleChannelInventoryService,
    private activatedRoute: ActivatedRoute,
    private appSettings: AppSettingsStorageService,
    private router: Router,
    protected confirmationService: ConfirmationService,
    private dbService: DBService,
    protected location: Location,
    private dialogService: DialogService,
    private alertMessagesService: AlertMessagesService,
    protected messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this._channelTitle = params["title"];

      this.subsList.push(
        this.activatedRoute.params.pipe(map((params: Params) => +params["storeId"])).subscribe((storeId) => {
          if (!storeId) {
            this.router.navigate([this.appSettings.getStoreId()], { relativeTo: this.activatedRoute });
            return;
          } else {
            this.activeStoreId = storeId;
            this.isAdmin = !this.activeStoreId;
            this._channelTitle = this.isAdmin ? "Admin Mode" : this._channelTitle;
            // find first sale channel
            if (!this.isAdmin) {
              const queryObject = {
                channelType: {
                  value: this._channelTitle,
                  matchMode: "equals",
                },
                storeId: {
                  value: this.activeStoreId,
                  matchMode: "equals",
                },
                isConnected: {
                  value: true,
                  matchMode: "equals",
                },
              };

              this.dbService.getRows("saleChannel", JSON.stringify(queryObject)).subscribe((saleChannels) => {
                if (saleChannels.count === 0) {
                  this.router.navigate(["/integrations/my-apps/sale-channel/settings", this.activeStoreId], {
                    queryParams: { title: this._channelTitle },
                  });

                  // this.confirmationService.confirm({
                  //   header: 'Confirmation',
                  //   message: 'There is no sale channel created for this channel type',
                  //   rejectLabel: 'Close',
                  //   rejectVisible: true,
                  //   acceptVisible: false,
                  //   reject: () => {
                  //     this.location.back();
                  //   }
                  // });
                } else {
                  this._saleChannel = saleChannels.rows[0];

                  this.saleChannelInventoryService._saleChannel = this._saleChannel;
                  // if (!this.isAdmin) {
                  // this._formListFilter['channelType'] = { matchMode: 'equals', value: this._channelTitle }
                  // this._formListFilter['inventorySaleChannels.saleChannelId'] = { matchMode: 'equals', value: this._saleChannel.id }
                  // } else {
                  //   this._formListFilter = {};
                  // }
                  this._formListFilter = {
                    isEnabled: { matchMode: "equals", value: true },
                    isSellable: { matchMode: "equals", value: true },
                    parentInventoryId: { matchMode: "equals", value: null, allowNull: true },
                  };
                  // this._formListCols = this.saleChannelService.getFormListColumns(this.isAdmin);
                  if (params["queryExtraParams"]) {
                    this._extraQueryParams = JSON.parse(params["queryExtraParams"]);
                  }

                  this._isDataReady = true;
                }
              });
            }
          }
          this._isDataReady = false;
        })
      );
    });

    const formState = JSON.parse(localStorage.getItem(this.stateKey));
    if (formState?.selection) {
      delete formState.selection;
      localStorage.setItem(this.stateKey, JSON.stringify(formState));
    }
    // this._isDataReady = true;
  }

  beforeSaveAll(AllObjects, _orgObjects): Observable<boolean> {
    const _editingObjects = [];
    AllObjects.forEach((row, index) => {
      if (!row.inventorySaleChannels[0].onlineStatus || row.inventorySaleChannels[0].uploadStatus === UploadStatus.No) {
        row.inventorySaleChannels[0].onlineStatus = OnlineStatus.Unpublished;
      }
      if (
        !_.isEqual(
          row,
          _orgObjects.find((orgRow) => orgRow.id === row.id)
        )
      ) {
        row.batchId = index;
        _editingObjects.push(row);
      }
    });

    const onlyUploadPublished = _editingObjects.filter(
      (inventory) =>
        inventory.inventorySaleChannels[0].uploadStatus === UploadStatus.Yes &&
        inventory.inventorySaleChannels[0].onlineStatus === OnlineStatus.Published
    );
    const onlyUploadUnPublished = _editingObjects.filter(
      (inventory) =>
        inventory.inventorySaleChannels[0].uploadStatus === UploadStatus.Yes &&
        inventory.inventorySaleChannels[0].onlineStatus !== OnlineStatus.Published
    );
    const allDeletingCandidate = _editingObjects.filter(
      (inventory) =>
        inventory.inventorySaleChannels[0].uploadStatus === UploadStatus.No &&
        inventory.inventorySaleChannels[0].channelInventoryId
    );

    if (onlyUploadPublished.length + onlyUploadUnPublished.length + allDeletingCandidate.length > 0) {
      if (
        [...onlyUploadPublished, ...onlyUploadUnPublished, ...allDeletingCandidate][0].inventorySaleChannels[0]
          .saleChannel.isStoreFrontOpen
      ) {
        return new Observable((observer: Observer<boolean>) => {
          this.confirmationService.confirm({
            header: "Confirmation",
            message: `All products marked as <strong>Yes</strong> under Upload Status will be added to your online store.
            <br>
            <br>
          Products that are marked as <strong>No</strong> under Upload Status will be removed from your online store.<br>
            <br>
          <strong>${onlyUploadPublished.length}</strong> products will be <strong>Uploaded</strong> as Published <br>
          <strong>${onlyUploadUnPublished.length}</strong> products will be <strong>Uploaded</strong> as Unpublished<br>
          <strong>${allDeletingCandidate.length}</strong> products will be <strong>Removed</strong> from your online store<br>
          <br>
          Are you sure you want to proceed?
          `,
            acceptLabel: "Yes",
            rejectLabel: "No",
            rejectVisible: true,
            acceptVisible: true,
            rejectButtonStyleClass: "p-button-link",
            accept: () => {
              observer.next(true);
              observer.complete();
            },
            reject: () => {
              observer.next(false);
              observer.complete();
            },
          });
        });
      } else {
        this.messageService.add({
          severity: "warn",
          summary: "Warning",
          detail:
            "The channel storefront is closed. To submit any products, please open the online storefront from Sales Channels > Settings tab or speak with your system administrator.",
        });
        return of(false);
      }
    } else {
      return of(false);
    }
  }

  openSubmitSelectionDialog() {
    const allSelectedInventory: Inventory[] = this._formListComponent._selectedObjects;
    if (this._formListComponent) {
      this._formListComponent.unSelectAllRows();
    }
    const onlyEnableInventory = allSelectedInventory.filter((inventory) =>
      inventory.inventorySaleChannels[0] ? inventory.inventorySaleChannels[0].uploadStatus : UploadStatus.No
    );
    const onlyDisabledInventory = allSelectedInventory.filter((inventory) =>
      inventory.inventorySaleChannels[0] ? !inventory.inventorySaleChannels[0].uploadStatus : UploadStatus.Yes
    );

    if (allSelectedInventory.length > 0) {
      if (this._saleChannel.isStoreFrontOpen) {
        const dialog = this.dialogService.open(GoogleShoppingPushSelectedDialogComponent, {
          header: "Confirmation to Submit Selection",
          width: "300pt",
          data: {
            countEnabled: onlyEnableInventory.length,
            countDisabled: onlyDisabledInventory.length,
            checkBoxCaption:
              "Include disabled items when submitting. Disabled items are not published and not visible in the sales channel.",
            info: "This will overwrite all data (price, quantity, description, etc.) for the selected products in your channel storefront. <br> <br> Are you sure you want to proceed?",
            submitToCaption: this._channelTitle,
            submitButtonCaption: "Submit",
          },
        });

        this.subsList.push(
          dialog.onClose.subscribe((result) => {
            if (result && result.actionInitiated) {
              // setTimeout(() => { this.createProgressDialog(); }, 500);

              if (result.pushOptions.includeDisabled)
                this.pushAndHandleResponseFromSaleChannel(allSelectedInventory, true);
              else this.pushAndHandleResponseFromSaleChannel(onlyEnableInventory, false);
            }
          })
        );
      } else {
        this.messageService.add({
          severity: "warn",
          summary: "Warning",
          detail:
            "The channel storefront is closed. To submit any products, please open the online storefront from Sales Channels > Settings tab or speak with your system administrator.",
        });
      }
    } else {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "You must select 1 or more rows.",
      });
    }
  }

  private pushAndHandleResponseFromSaleChannel(inventoryData: Inventory[], enableInventory: boolean) {
    const inventoryIds = inventoryData.map((inventory) => inventory.id);
    this._isFetchingData = true;
    this.subsList.push(
      this.dbService
        ._postRequest(this.dbService.webUrl + "TAKUeCommerceInventories/" + this._saleChannel.id, {
          inventories: inventoryIds,
        })
        .subscribe({
          next: (result: any) => {
            if (result.success) {
              const formState = JSON.parse(localStorage.getItem(this.stateKey));
              if (formState) {
                formState.saveFirst = true;
                delete formState.selection;
                localStorage.setItem(this.stateKey, JSON.stringify(formState));
              }

              this._isDataReady = false;
              this.messageService.add({
                severity: "success",
                summary: "Service Message",
                detail: result.message,
              });
              setTimeout(() => {
                this._isDataReady = true;
                this._isFetchingData = false;
              }, 3000);
            } else {
              this._isFetchingData = false;
              this.messageService.add({
                severity: "error",
                summary: "Service Message",
                detail: result.message,
              });
            }
          },
          error: (error) => {
            this.messageService.add(this.alertMessagesService.getErrorMessage(error));
            this._isFetchingData = false;
          },
        })
    );
  }

  _changeFormListFilter = (filter) => {
    if (filter["inventorySaleChannels.uploadStatus"] || filter["inventorySaleChannels.onlineStatus"]) {
      // const isGMCEnabled = gmcEnabledFilter.value;
      // Add filter for selected store
      filter["inventorySaleChannels.saleChannelId"] = {
        matchMode: "in",
        value: [this._saleChannel.id, 0],
        "fn.isnull": 0,
      };
    }
  };

  ngAfterViewInit() {
    setTimeout(() => {
      this._formListCols = this.saleChannelInventoryService.getFormListColumns();

      this._formListRules = this.saleChannelInventoryService.getValidationRules();
    }, 0);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
