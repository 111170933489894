import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import * as _ from "lodash";
import { SaleDocType } from "src/app/core/document/doc/doc";
import { SaleDocLineTax } from "src/app/core/document/sale-document/sale-doc-line-tax/sale-doc-line-tax";
import { SaleDoc } from "src/app/core/document/sale-document/sale-doc/sale-doc";
import { MonetaryHelpers } from "src/app/utility/MonetaryHelpers";
import { AppConstants } from "../../app-constants";
import { PrintingFactoryService } from "../printing-service.service";

declare var b2bapis: any;

@Injectable({
  providedIn: "root",
})
export class TizenPrintService {
  //constructor(printingFactoryService: PrintingFactoryService) { }

  getSerialPrintVersion() {
    let Version = null;
    try {
      Version = b2bapis.serialprint.getVersion();
    } catch (e) {
      console.log(
        "[SerialPrint API][getVersion] call syncFunction exception [" +
          e.code +
          "] name: " +
          e.name +
          " message: " +
          e.message
      );
    }
    if (null !== Version) {
      console.log("[SerialPrint API][getVersion] call syncFunction type: " + Version);
    }
  }

  openSerialPrint() {
    console.log("[SerialPrint API][open] function call");
    console.log("[SerialPrint API][open] BaudRate" + BAUDRATE);
    let option = null;
    let result = false;
    option = {
      baudRate: BAUDRATE,
      parity: PARITY.PARITY_NONE,
      dataBits: DATA_BITS.DATA_BITS_8,
      stopBits: STOP_BITS.STOP_BITS_ONE,
    };
    const printPort = PRINT_PORT.PRINT_PORT_1;

    function onlistener(printSerialData) {
      console.log(
        "[SerialPrint API] Print serial data is " +
          printSerialData.data +
          ", Print serial Port is === " +
          printSerialData.result
      );
    }
    try {
      result = b2bapis.serialprint.open(printPort, option, onlistener);
      if (result == true) {
        console.log("[SerialPrint API]Success to open print serial port");
      } else {
        console.log("[SerialPrint API]Fail to open print serial port" + result);
      }
    } catch (e) {
      console.log(
        "[SerialPrint API][open] call syncFunction exception " + e.code + " " + e.errorName + " " + e.errorMessage
      );
    }
  }

  closeSerialPrint() {
    console.log("[SerialPrint API][close] function call");
    let result = false;
    const printPort = PRINT_PORT.PRINT_PORT_1;

    try {
      result = b2bapis.serialprint.close(printPort);
      if (result == false) {
        console.log("[SerialPrint API]Fail to close print serial port");
      }
    } catch (e) {
      console.log(
        "[SerialPrint API][close] call syncFunction exception " + e.code + " " + e.errorName + " " + e.errorMessage
      );
    }
  }

  printerNotifications(statushex) {
    console.log("[SerialPrint API][write] function call");
    let result = false;
    const printPort = PRINT_PORT.PRINT_PORT_1;
    const data = statushex;
    try {
      result = b2bapis.serialprint.writeData(printPort, data, data.length);
      console.log("[SerialPrint API][writeData_0] writeData size is " + result);
    } catch (e) {
      console.log(
        "[SerialPrint API][writeData] call syncFunction exception " + e.code + " " + e.errorName + " " + e.errorMessage
      );
    }
  }

  writeReceipt(sTextPrint: string) {
    console.log("[SerialPrint API][write] function call");
    let result = false;
    const printPort = PRINT_PORT.PRINT_PORT_1;
    //var receiptData = this.generateReceiptData()
    const receiptData = sTextPrint;
    const data = this.stringToHex(receiptData);
    try {
      result = b2bapis.serialprint.writeData(printPort, data, data.length);
      console.log("[SerialPrint API][writeData_0] writeData size is " + result);
    } catch (e) {
      console.log(
        "[SerialPrint API][writeData] call syncFunction exception " + e.code + " " + e.errorName + " " + e.errorMessage
      );
    }
  }

  generateReceiptData() {
    const receiptData =
      "<l>ALIGNMENT LEFT </><e>" +
      "<c>ALIGNMENT CENTER </><e>" +
      "<r>ALIGNMENT RIGHT </><e>" +
      "<b>BOLD TEXT </><e>" +
      "<u>UNDERLINED TEXT </><e>" +
      "<1>TEXT1</><e>" +
      "<2>TEXT2</><e>" +
      "<3>TEXT3</><e>" +
      "<4>TEXT4</><e>" +
      "<5>TEXT5</><e>" +
      "<6>TEXT6</><e>" +
      "<7>TEXT7</><e>" +
      "<w></><e>";
    return receiptData;
  }

  generateDemoTransactionReceiptData() {
    const receiptData =
      "<l>----------- TRANSACTION RECORD -----------</><e>" +
      "<l> </><e>" +
      "<l>                 Purchase                 </><e>" +
      "<l>Jun 02,2022                       22:24:23</><e>" +
      "<l>MASTERCARD                ************2344</><e>" +
      "<l>TID: 66023539           Entry: Tap EMV (H)</><e>" +
      "<l>Sequence: 135                   Batch: 123</><e>" +
      "<l>Auth#: 523274             Response: 01-027</><e>" +
      "<l>UID: 1B2153806635200                      </><e>" +
      "<l> </><e>" +
      "<l>Amount                              $16.09</><e>" +
      "<l>Total                               $16.09</><e>" +
      "<l>A0000000041010                            </><e>" +
      "<l>MASTERCARD                                </><e>" +
      "<l>TVR 8000008001                            </><e>" +
      "<l>           Approved - Thank You           </><e>" +
      "<l>          Signature Not Required          </><e>" +
      "<l>Important:Retain this copy for your record</><e>" +
      "<l>             CARDHOLDER COPY              </><e>";
    return receiptData;
  }

  stringToHex(tmp) {
    let str = "";
    const tmp_len = tmp.length;
    let c;
    let tag;

    for (let i = 0; i < tmp_len; i++) {
      if (tmp[i] === "<") {
        tag = true;
      } else if (tmp[i] === ">") {
        tag = false;
      } else {
        if (tag) {
          switch (tmp[i]) {
            // --Text Align Left
            case "l":
              c = "1B6100";
              break;
            // --Text Align Center
            case "c":
              c = "1B6101";
              break;
            // --Text Align Right
            case "r":
              c = "1B6102";
              break;
            // --Underline Text
            case "u":
              c = "1B2D01";
              break;
            // --Bold Text
            case "b":
              c = "1B4701";
              break;
            // --Text Size 1
            case "1":
              //width/height
              var size = "00";
              c = "1D21" + size;
              break;
            // --Text Size 2
            case "2":
              var size = "11";
              c = "1D21" + size;
            // --Text Size 3
            case "3":
              var size = "22";
              c = "1D21" + size;
              break;
            // --Text Size 4
            case "4":
              var size = "33";
              c = "1D21" + size;
              break;
            // --Text Size 5
            case "5":
              var size = "44";
              c = "1D21" + size;
              break;
            // --Text Size 6
            case "6":
              var size = "55";
              c = "1D21" + size;
              break;
            // --Text Size 7
            case "7":
              var size = "66";
              c = "1D21" + size;
              break;
            // --Barcode
            case "w":
              //-- Setting Barcode Size. Max size 08
              var size = "00";
              c = "1D77" + size;
              //-- Setting Barcode String. 11 Digits.
              var string = "12345678912";
              var barcodeString = this.generateBarcode(string);
              var hexString = this.stringToHex(barcodeString);
              c = c + "1D6B00" + hexString + "0A0A";
              break;
            // --Enter
            case "e":
              c = "0A";
              break;
            // --Set Default Values
            case "/":
              c = "1B61001B2D001B47001D2100";
              break;
            default:
              break;
          }
        } else {
          c = tmp.charCodeAt(i).toString(16);
        }

        str += c.toString(16);
      }

      if (i == tmp_len - 1) str += "0A0A0A0A1B69";
    }
    return str;
  }

  generateBarcode(barcode) {
    let oddNumber = 0;
    let evenNumber = 0;
    for (let i = 0; i < barcode.length; i++) {
      i % 2 ? (evenNumber += parseInt(barcode[i])) : (oddNumber += parseInt(barcode[i]));
    }
    let keyNumber = 10 - ((oddNumber * 3 + evenNumber) % 10);
    if (keyNumber === 10) keyNumber = 0;
    return barcode + keyNumber;
  }

  /**
   * Create the Samsung Kiosh Order Receipt Text
   * @param formGroup
   * @returns sTextPrint
   */
  createSamsungKioskOrderReceiptText(saleDoc: SaleDoc): string {
    let sTextPrint = "";

    /*
    let store = printingFactoryService.appSettingsService.getStore();
    let station = this.appSettingsService.getStation();
    let saleDocLines: FormArray = formGroup.get('saleDocLines') as FormArray;
    */

    //console.log(formGroup);

    sTextPrint =
      SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_CENTER_TAG +
      SamsungKioskReceiptSpecialTags.SK_BOLD_TEXT_TAG +
      saleDoc.store.storeName.toLocaleUpperCase() +
      SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;

    //Store name
    if (saleDoc.store.address && saleDoc.store.address != undefined && saleDoc.store.address != null) {
      if (
        saleDoc.store.address.line1 &&
        saleDoc.store.address.line1 != undefined &&
        saleDoc.store.address.line1 != null &&
        saleDoc.store.address.line1 != ""
      ) {
        sTextPrint +=
          SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_CENTER_TAG +
          saleDoc.store.address.line1 +
          SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;
      }
      if (
        saleDoc.store.address.line2 &&
        saleDoc.store.address.line2 != undefined &&
        saleDoc.store.address.line2 != null &&
        saleDoc.store.address.line2 != ""
      ) {
        sTextPrint +=
          SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_CENTER_TAG +
          saleDoc.store.address.line2 +
          SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;
      }
      if (
        saleDoc.store.address.city &&
        saleDoc.store.address.city != undefined &&
        saleDoc.store.address.city != null &&
        saleDoc.store.address.city != ""
      ) {
        sTextPrint += SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_CENTER_TAG + saleDoc.store.address.city;
      }
      if (
        saleDoc.store.address.subDivisionIsoCode &&
        saleDoc.store.address.subDivisionIsoCode != undefined &&
        saleDoc.store.address.subDivisionIsoCode != null &&
        saleDoc.store.address.subDivisionIsoCode != ""
      ) {
        sTextPrint += ", " + saleDoc.store.address.subDivisionIsoCode;
      }
      if (
        saleDoc.store.address.postalCode &&
        saleDoc.store.address.postalCode != undefined &&
        saleDoc.store.address.postalCode != null &&
        saleDoc.store.address.postalCode != ""
      ) {
        sTextPrint += " " + saleDoc.store.address.postalCode + SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;
      }
    }

    sTextPrint +=
      SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_LEFT_TAG +
      "-".repeat(PrintingTapeReceiptSetting.CHARACTERS_PER_LINE) +
      SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;
    const now = new Date();
    const dateYmd = new DatePipe(AppConstants.DEFAULT_LOCALE_ID).transform(now, "yyyy-MM-dd");
    const timeHis = new DatePipe(AppConstants.DEFAULT_LOCALE_ID).transform(now, "HH:mm:ss");
    console.log(dateYmd);

    const sDate = "Date: " + dateYmd;
    const sTime = "Time: " + timeHis;
    let sDateTime = sDate + sTime;
    sDateTime = sDate + " ".repeat(PrintingTapeReceiptSetting.CHARACTERS_PER_LINE - sDateTime.length) + sTime;
    sTextPrint +=
      SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_CENTER_TAG +
      sDateTime +
      SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;

    const sStore = "Store: " + saleDoc.store.storeID;
    const sTill = "Till: " + saleDoc.station?.stationNumber;
    let sStoreTill = sStore + sTill;
    sStoreTill = sStore + " ".repeat(PrintingTapeReceiptSetting.CHARACTERS_PER_LINE - sStoreTill.length) + sTill;
    sTextPrint +=
      SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_CENTER_TAG +
      sStoreTill +
      SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;

    sTextPrint +=
      SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_LEFT_TAG +
      "-".repeat(PrintingTapeReceiptSetting.CHARACTERS_PER_LINE) +
      SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;

    sTextPrint +=
      SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_CENTER_TAG +
      SamsungKioskReceiptSpecialTags.SK_BOLD_TEXT_TAG +
      SamsungKioskReceiptSpecialTags.SK_FONT_SIZE_2 +
      "ORDER" +
      SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;

    sTextPrint +=
      SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_CENTER_TAG +
      SamsungKioskReceiptSpecialTags.SK_BOLD_TEXT_TAG +
      SamsungKioskReceiptSpecialTags.SK_FONT_SIZE_2 +
      "#" +
      saleDoc.dailyNo +
      SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;

    sTextPrint +=
      SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_LEFT_TAG +
      "-".repeat(PrintingTapeReceiptSetting.CHARACTERS_PER_LINE) +
      SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;

    let iIndex = 0;
    saleDoc.saleDocLines.forEach((saleDocLine) => {
      ++iIndex;
      console.log(iIndex);

      console.log(saleDoc.saleDocLines.length);
      const qty = saleDocLine.qty;
      const desc = saleDocLine.docLine.inventory.description1;
      if (!isNaN(qty)) qty == 1;
      const lineDetail = `${qty} x ${desc}`;
      sTextPrint +=
        SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_LEFT_TAG +
        SamsungKioskReceiptSpecialTags.SK_BOLD_TEXT_TAG +
        lineDetail +
        SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;

      sTextPrint +=
        SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_LEFT_TAG +
        SamsungKioskReceiptSpecialTags.SK_BOLD_TEXT_TAG +
        "   " +
        saleDocLine.docLine.inventory.inventoryVariants
          .map(
            (_inventoryVariant) =>
              _inventoryVariant.inventoryOptionsSetting?.optionName +
              ": " +
              _inventoryVariant.inventoryOptionsSettingValue?.optionValue
          )
          .join(", ") +
        SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;

      if (iIndex !== saleDoc.saleDocLines.length) {
        sTextPrint += SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;
      }
    });

    let sAmountDisp: string;
    let sAmountColumn = "";
    const sCurrencySymbol = "$";
    sTextPrint +=
      SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_LEFT_TAG +
      "-".repeat(PrintingTapeReceiptSetting.CHARACTERS_PER_LINE) +
      SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;
    sTextPrint +=
      SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_CENTER_TAG +
      SamsungKioskReceiptSpecialTags.SK_BOLD_TEXT_TAG +
      SamsungKioskReceiptSpecialTags.SK_FONT_SIZE_2 +
      "UNPAID" +
      SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;
    sTextPrint +=
      SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_CENTER_TAG +
      SamsungKioskReceiptSpecialTags.SK_BOLD_TEXT_TAG +
      SamsungKioskReceiptSpecialTags.SK_FONT_SIZE_1 +
      "Please pay at the counter" +
      SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;
    sTextPrint +=
      SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_LEFT_TAG +
      "-".repeat(PrintingTapeReceiptSetting.CHARACTERS_PER_LINE) +
      SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;

    //Subtotal
    sAmountDisp =
      sCurrencySymbol + (saleDoc.subTotal * (saleDoc.doc.docType == SaleDocType.sales_return ? -1 : 1)).toFixed(2);
    sAmountColumn = " ".repeat(PrintingTapeReceiptSetting.DESC_TOTAL_COLUMN_LENGTH - sAmountDisp.length) + sAmountDisp;
    sTextPrint +=
      SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_LEFT_TAG +
      " ".repeat(PrintingTapeReceiptSetting.DESC_TOTAL_COLUMN_LENGTH - "SUBTOTAL".length) +
      "SUBTOTAL" +
      sAmountColumn +
      SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;

    //TAX
    /*
    let sTaxDescColumn = ""
    for (let taxDetail of saleDoc.groupedTaxLines) {
      sTaxDescColumn = "TAX (" + taxDetail.saleTaxRule?.tax?.shortDesc + ")"
      sAmountDisp = sCurrencySymbol + (taxDetail.total * (saleDoc.doc.docType == SaleDocType.sales_return ? -1 : 1)).toFixed(2);
      sAmountColumn = ' '.repeat(PrintingTapeReceiptSetting.DESC_TOTAL_COLUMN_LENGTH - sAmountDisp.length) + sAmountDisp;
      sTextPrint += SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_LEFT_TAG + ' '.repeat(PrintingTapeReceiptSetting.DESC_TOTAL_COLUMN_LENGTH - sTaxDescColumn.length) + sTaxDescColumn + sAmountColumn + SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;
    }
    */

    const sTaxDescColumn = "TAX";
    //sAmountDisp = sCurrencySymbol + (saleDoc.grandTotal * (saleDoc.doc.docType == SaleDocType.sales_return ? -1 : 1)).toFixed(2);
    sAmountDisp =
      sCurrencySymbol + (saleDoc.totalLineTax * (saleDoc.doc.docType == SaleDocType.sales_return ? -1 : 1)).toFixed(2);
    sAmountColumn = " ".repeat(PrintingTapeReceiptSetting.DESC_TOTAL_COLUMN_LENGTH - sAmountDisp.length) + sAmountDisp;
    sTextPrint +=
      SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_LEFT_TAG +
      " ".repeat(PrintingTapeReceiptSetting.DESC_TOTAL_COLUMN_LENGTH - sTaxDescColumn.length) +
      sTaxDescColumn +
      sAmountColumn +
      SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;

    const grandTotal =
      MonetaryHelpers.roundToDecimalPlaces(saleDoc.subTotal, 2) -
      MonetaryHelpers.roundToDecimalPlaces(saleDoc.discountAmount, 2) +
      MonetaryHelpers.roundToDecimalPlaces(saleDoc.totalLineTax, 2) +
      MonetaryHelpers.roundToDecimalPlaces(parseFloat(saleDoc.shipperChargeAmount + ""), 2);

    //TOTAL
    const sTotalDescColumn = "TOTAL";
    //sAmountDisp = sCurrencySymbol + (saleDoc.grandTotal * (saleDoc.doc.docType == SaleDocType.sales_return ? -1 : 1)).toFixed(2);
    sAmountDisp =
      sCurrencySymbol + (grandTotal * (saleDoc.doc.docType == SaleDocType.sales_return ? -1 : 1)).toFixed(2);
    sAmountColumn = " ".repeat(PrintingTapeReceiptSetting.DESC_TOTAL_COLUMN_LENGTH - sAmountDisp.length) + sAmountDisp;
    sTextPrint +=
      SamsungKioskReceiptSpecialTags.SK_BOLD_TEXT_TAG +
      " ".repeat(PrintingTapeReceiptSetting.DESC_TOTAL_COLUMN_LENGTH - sTotalDescColumn.length) +
      sTotalDescColumn +
      sAmountColumn +
      SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;

    sTextPrint +=
      SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_LEFT_TAG +
      "-".repeat(PrintingTapeReceiptSetting.CHARACTERS_PER_LINE) +
      SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;
    sTextPrint +=
      SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_CENTER_TAG +
      "Thank you for your order" +
      SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;
    return sTextPrint;
  }

  createSamsungKioskReceiptText(saleDoc: SaleDoc) {
    return this.createSamsungKioskReceiptTextHeader(saleDoc) + this.createSamsungKioskReceiptTextDetail(saleDoc);
  }

  createSamsungKioskReceiptTextHeader(saleDoc: SaleDoc, bInvoice = true) {
    let sTextPrint = "";
    let sDate = "";
    let sTime = "";
    let sDateTime = "";
    let sDoc = "";
    let sUser = "";
    let sDocUser = "";
    let sStore = "";
    let sTill = "";
    let sStoreTill = "";

    //if (this.tizenPrintService.appSettingsService.printStoreName && this._componentInstance.settings.printStoreName != undefined && this._componentInstance.settings.printStoreName != null)
    {
      sTextPrint =
        SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_CENTER_TAG +
        SamsungKioskReceiptSpecialTags.SK_BOLD_TEXT_TAG +
        saleDoc.store.storeName.toLocaleUpperCase() +
        SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;
    }

    //if (this_componentInstance.settings.printAddress && this._componentInstance.settings.printAddress != undefined && this._componentInstance.settings.printAddress != null) {
    if (saleDoc.store.address && saleDoc.store.address != undefined && saleDoc.store.address != null) {
      if (
        saleDoc.store.address.line1 &&
        saleDoc.store.address.line1 != undefined &&
        saleDoc.store.address.line1 != null &&
        saleDoc.store.address.line1 != ""
      ) {
        sTextPrint +=
          SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_CENTER_TAG +
          saleDoc.store.address.line1 +
          SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;
      }
      if (
        saleDoc.store.address.line2 &&
        saleDoc.store.address.line2 != undefined &&
        saleDoc.store.address.line2 != null &&
        saleDoc.store.address.line2 != ""
      ) {
        sTextPrint +=
          SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_CENTER_TAG +
          saleDoc.store.address.line2 +
          SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;
      }
      if (
        saleDoc.store.address.city &&
        saleDoc.store.address.city != undefined &&
        saleDoc.store.address.city != null &&
        saleDoc.store.address.city != ""
      ) {
        sTextPrint += SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_CENTER_TAG + saleDoc.store.address.city;
      }
      if (
        saleDoc.store.address.subDivisionIsoCode &&
        saleDoc.store.address.subDivisionIsoCode != undefined &&
        saleDoc.store.address.subDivisionIsoCode != null &&
        saleDoc.store.address.subDivisionIsoCode != ""
      ) {
        sTextPrint += ", " + saleDoc.store.address.subDivisionIsoCode;
      }
      if (
        saleDoc.store.address.postalCode &&
        saleDoc.store.address.postalCode != undefined &&
        saleDoc.store.address.postalCode != null &&
        saleDoc.store.address.postalCode != ""
      ) {
        sTextPrint += " " + saleDoc.store.address.postalCode + SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;
      }
    }
    //}

    //if (this._componentInstance.settings.printPhoneNo && this._componentInstance.settings.printPhoneNo != undefined && this._componentInstance.settings.printPhoneNo != null)
    {
      if (
        saleDoc.store.address?.addressPhone &&
        saleDoc.store.address?.addressPhone != null &&
        saleDoc.store.address?.addressPhone != undefined
      ) {
        //sTextPrint += SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_CENTER_TAG + "Phone: " + saleDoc.store.address?.addressPhone.fullPhoneNumber + SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;
      }
    }

    //if (this._componentInstance.settings.printWebsite && this._componentInstance.settings.printWebsite != undefined && this._componentInstance.settings.printWebsite != null)
    {
      if (saleDoc.store.url) {
        sTextPrint +=
          SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_CENTER_TAG +
          saleDoc.store.url +
          SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;
      }
    }

    //if (this._componentInstance.settings.printEmail && this._componentInstance.settings.printEmail != undefined && this._componentInstance.settings.printEmail != null)
    {
      if (saleDoc.store.address?.addressEmail) {
        sTextPrint +=
          SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_CENTER_TAG +
          saleDoc.store.address.addressEmail.email +
          SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;
      }
    }

    sTextPrint +=
      SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_LEFT_TAG +
      "-".repeat(PrintingTapeReceiptSetting.CHARACTERS_PER_LINE) +
      SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;

    //if (this._componentInstance.settings.printDate && this._componentInstance.settings.printDate != undefined && this._componentInstance.settings.printDate != null)
    {
      sDate = "Date: " + saleDoc.doc.docDate;
      sTime = "Time: " + saleDoc.doc.docTime;
      sDateTime = sDate + sTime;
      sDateTime = sDate + " ".repeat(PrintingTapeReceiptSetting.CHARACTERS_PER_LINE - sDateTime.length) + sTime;
      sTextPrint +=
        SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_CENTER_TAG +
        sDateTime +
        SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;
    }

    //if (this._componentInstance.settings.printDocumentNo && this._componentInstance.settings.printDocumentNo != undefined && this._componentInstance.settings.printDocumentNo != null)
    {
      sDoc = "Doc #: " + saleDoc.doc.docNo;
      sUser = "User: " + saleDoc.doc.user?.userName;
      sDocUser = sDoc + sUser;
      sDocUser = sDoc + " ".repeat(PrintingTapeReceiptSetting.CHARACTERS_PER_LINE - sDocUser.length) + sUser;
      sTextPrint +=
        SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_CENTER_TAG +
        sDocUser +
        SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;
    }

    sStore = "Store: " + saleDoc.store.storeID;
    sTill = "Till: " + saleDoc.station?.stationNumber;
    sStoreTill = sStore + sTill;
    sStoreTill = sStore + " ".repeat(PrintingTapeReceiptSetting.CHARACTERS_PER_LINE - sStoreTill.length) + sTill;
    sTextPrint +=
      SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_CENTER_TAG +
      sStoreTill +
      SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;

    return sTextPrint;
  }

  createSamsungKioskReceiptTextDetail(saleDoc: SaleDoc) {
    let sTextPrint = "";
    let sTextLine = "";

    let sSkuDisp: string;
    let sSkuColumn = "";

    let sQtyDisp: string;
    let sQtyColumn = "";

    let sPriceDisp: string;
    let sPriceColumn = "";

    let sAmountDisp: string;
    let sAmountColumn = "";

    //---------------42 characters--------------
    //------------------------------------------
    //ITEM              QTY   PRICE $   AMOUNT $
    //------------------------------------------
    //--------------
    //              -------
    //                     ---------
    //                              ------------
    //------------------------------------------

    const nDescColumnLength = 21;
    const nSkuColumnLength = 14;
    const nQtyColumnLength = 7;
    const nPriceColumnLength = 9;
    const nAmountColumnLength = 12;

    const sCurrencySymbol = "$"; //this.getCurrencySymbol(saleDoc.doc.currencyIsoCode);

    //Receipt document title
    sTextPrint +=
      SamsungKioskReceiptSpecialTags.SK_BOLD_TEXT_TAG +
      "-".repeat(PrintingTapeReceiptSetting.CHARACTERS_PER_LINE) +
      SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;
    //sTextPrint += SamsungKioskReceiptSpecialTags.SK_BOLD_TEXT_TAG + saleDoc.doc.documentTitle + SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;
    sTextPrint +=
      SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_CENTER_TAG +
      SamsungKioskReceiptSpecialTags.SK_BOLD_TEXT_TAG +
      SamsungKioskReceiptSpecialTags.SK_FONT_SIZE_2 +
      "#" +
      saleDoc.dailyNo +
      SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;
    sTextPrint +=
      SamsungKioskReceiptSpecialTags.SK_BOLD_TEXT_TAG +
      "INVOICE" +
      SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;
    sTextPrint +=
      SamsungKioskReceiptSpecialTags.SK_BOLD_TEXT_TAG +
      "-".repeat(PrintingTapeReceiptSetting.CHARACTERS_PER_LINE) +
      SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;

    //Receipt column title
    sTextPrint +=
      SamsungKioskReceiptSpecialTags.SK_BOLD_TEXT_TAG +
      "ITEM              QTY   PRICE $   AMOUNT $" +
      SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;
    sTextPrint +=
      SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_LEFT_TAG +
      "-".repeat(PrintingTapeReceiptSetting.CHARACTERS_PER_LINE) +
      SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;

    //Receip detail
    for (const itemDetail of saleDoc.saleDocLines) {
      //Description
      //sTextPrint += SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_LEFT_TAG + itemDetail.docLine.inventory.description1.substring(0, PrintingTapeReceiptSetting.CHARACTERS_PER_LINE) + SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;
      sTextPrint +=
        SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_LEFT_TAG +
        itemDetail.docLine.inventory.description1 +
        SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;
      if (itemDetail.docLine.inventory.inventoryVariants.length > 0) {
        sTextPrint +=
          SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_LEFT_TAG +
          "   " +
          itemDetail.docLine.inventory.inventoryVariants
            .map(
              (_inventoryVariant) =>
                _inventoryVariant.inventoryOptionsSetting?.optionName +
                ": " +
                _inventoryVariant.inventoryOptionsSettingValue?.optionValue
            )
            .join(", ") +
          SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;
      }

      //Sku
      sSkuColumn =
        itemDetail.docLine.inventory.sku + " ".repeat(nSkuColumnLength - itemDetail.docLine.inventory.sku.length);

      //Qty
      sQtyColumn = " ".repeat(nQtyColumnLength - itemDetail.qty.toString().length) + itemDetail.qty.toString();

      //Price
      //sPriceDisp = itemDetail.salePrice.toFixed(2)
      //let fUnitPrice = (itemDetail.unitPrice * 1).toFixed(2)
      sPriceDisp = (itemDetail.unitPrice * 1).toFixed(2);
      sPriceColumn = " ".repeat(nPriceColumnLength - sPriceDisp.length) + sPriceDisp;

      //Amount
      //sAmountDisp = (itemDetail.salePrice * itemDetail.qty * (saleDoc.doc.docType == SaleDocType.sales_return ? -1 : 1)).toFixed(2);
      sAmountDisp = (
        itemDetail.unitPrice *
        itemDetail.qty *
        (saleDoc.doc.docType == SaleDocType.sales_return ? -1 : 1)
      ).toFixed(2);
      sAmountColumn = " ".repeat(nAmountColumnLength - sAmountDisp.length) + sAmountDisp;

      //Text line
      sTextLine = sSkuColumn + sQtyColumn + sPriceColumn + sAmountColumn;

      sTextPrint +=
        SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_LEFT_TAG +
        sTextLine +
        SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;
    }

    sTextPrint +=
      SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_LEFT_TAG +
      "-".repeat(PrintingTapeReceiptSetting.CHARACTERS_PER_LINE) +
      SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;

    //Subtotal
    sAmountDisp =
      sCurrencySymbol + (saleDoc.subTotal * (saleDoc.doc.docType == SaleDocType.sales_return ? -1 : 1)).toFixed(2);
    sAmountColumn = " ".repeat(nDescColumnLength - sAmountDisp.length) + sAmountDisp;
    sTextPrint +=
      SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_LEFT_TAG +
      " ".repeat(nDescColumnLength - "SUBTOTAL".length) +
      "SUBTOTAL" +
      sAmountColumn +
      SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;

    //Discount
    let sDiscountDescColumn = "DISCOUNT";
    if (saleDoc.discountType == "Percentage") {
      sDiscountDescColumn = "DISCOUNT (" + (saleDoc.discountAmount * 1).toFixed(2) + "%)";
    }
    //sAmountDisp = sCurrencySymbol + (saleDoc.discountAmountMoney * (saleDoc.doc.docType == SaleDocType.sales_return ? -1 : 1)).toFixed(2);
    sAmountDisp =
      sCurrencySymbol +
      (saleDoc.discountAmount * (saleDoc.doc.docType == SaleDocType.sales_return ? -1 : 1)).toFixed(2);
    sAmountColumn = " ".repeat(nDescColumnLength - sAmountDisp.length) + sAmountDisp;
    sTextPrint +=
      SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_LEFT_TAG +
      " ".repeat(nDescColumnLength - sDiscountDescColumn.length) +
      sDiscountDescColumn +
      sAmountColumn +
      SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;

    //TAX
    /*
    let sTaxDescColumn = ""
    for (let taxDetail of saleDoc.groupedTaxLines) {
      sTaxDescColumn = "TAX (" + taxDetail.saleTaxRule?.tax?.shortDesc + ")"
      sAmountDisp = sCurrencySymbol + (taxDetail.total * (saleDoc.doc.docType == SaleDocType.sales_return ? -1 : 1)).toFixed(2);
      sAmountColumn = ' '.repeat(nDescColumnLength - sAmountDisp.length) + sAmountDisp;
      sTextPrint += SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_LEFT_TAG + ' '.repeat(nDescColumnLength - sTaxDescColumn.length) + sTaxDescColumn + sAmountColumn + SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;
    }
    */

    const sTaxDescColumn = "TAX";
    //sAmountDisp = sCurrencySymbol + (saleDoc.grandTotal * (saleDoc.doc.docType == SaleDocType.sales_return ? -1 : 1)).toFixed(2);
    sAmountDisp =
      sCurrencySymbol + (saleDoc.totalLineTax * (saleDoc.doc.docType == SaleDocType.sales_return ? -1 : 1)).toFixed(2);
    sAmountColumn = " ".repeat(PrintingTapeReceiptSetting.DESC_TOTAL_COLUMN_LENGTH - sAmountDisp.length) + sAmountDisp;
    sTextPrint +=
      SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_LEFT_TAG +
      " ".repeat(PrintingTapeReceiptSetting.DESC_TOTAL_COLUMN_LENGTH - sTaxDescColumn.length) +
      sTaxDescColumn +
      sAmountColumn +
      SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;

    //SHIPPING
    if (saleDoc.shipperChargeAmount != 0) {
      const sShippingDescColumn = "SHIPPING";
      sAmountDisp =
        sCurrencySymbol +
        (saleDoc.shipperChargeAmount * (saleDoc.doc.docType == SaleDocType.sales_return ? -1 : 1)).toFixed(2);
      sAmountColumn = " ".repeat(nDescColumnLength - sAmountDisp.length) + sAmountDisp;
      sTextPrint +=
        SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_LEFT_TAG +
        " ".repeat(nDescColumnLength - sShippingDescColumn.length) +
        sShippingDescColumn +
        sAmountColumn +
        SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;
    }

    //TOTAL

    const grandTotal =
      MonetaryHelpers.roundToDecimalPlaces(saleDoc.subTotal, 2) -
      MonetaryHelpers.roundToDecimalPlaces(saleDoc.discountAmount, 2) +
      MonetaryHelpers.roundToDecimalPlaces(saleDoc.totalLineTax, 2) +
      MonetaryHelpers.roundToDecimalPlaces(parseFloat(saleDoc.shipperChargeAmount + ""), 2);

    sTextPrint +=
      SamsungKioskReceiptSpecialTags.SK_BOLD_TEXT_TAG +
      "-".repeat(PrintingTapeReceiptSetting.CHARACTERS_PER_LINE) +
      SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;
    const sTotalDescColumn = "TOTAL";
    //sAmountDisp = sCurrencySymbol + (saleDoc.grandTotal * (saleDoc.doc.docType == SaleDocType.sales_return ? -1 : 1)).toFixed(2);
    sAmountDisp =
      sCurrencySymbol + (grandTotal * (saleDoc.doc.docType == SaleDocType.sales_return ? -1 : 1)).toFixed(2);
    sAmountColumn = " ".repeat(nDescColumnLength - sAmountDisp.length) + sAmountDisp;
    sTextPrint +=
      SamsungKioskReceiptSpecialTags.SK_BOLD_TEXT_TAG +
      " ".repeat(nDescColumnLength - sTotalDescColumn.length) +
      sTotalDescColumn +
      sAmountColumn +
      SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;

    sTextPrint +=
      SamsungKioskReceiptSpecialTags.SK_BOLD_TEXT_TAG +
      "-".repeat(PrintingTapeReceiptSetting.CHARACTERS_PER_LINE) +
      SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;

    // PAY TYPE DETAILS
    sTextPrint +=
      SamsungKioskReceiptSpecialTags.SK_BOLD_TEXT_TAG +
      "PAY TYPE DETAILS" +
      SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;
    let sPayDescColumn = "";
    for (const payDetail of saleDoc.saleDocTenders) {
      sPayDescColumn = " ".repeat(10) + payDetail.tenderType.description;
      sAmountDisp = sCurrencySymbol + parseFloat(payDetail.amount + "").toFixed(2);
      sAmountColumn =
        " ".repeat(PrintingTapeReceiptSetting.CHARACTERS_PER_LINE - sPayDescColumn.length - sAmountDisp.length) +
        sAmountDisp;
      sTextPrint +=
        SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_LEFT_TAG +
        sPayDescColumn +
        sAmountColumn +
        SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;
    }
    sTextPrint +=
      SamsungKioskReceiptSpecialTags.SK_BOLD_TEXT_TAG +
      "-".repeat(PrintingTapeReceiptSetting.CHARACTERS_PER_LINE) +
      SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;

    // YOU SAVE
    if (
      saleDoc.doc.docType != SaleDocType.sales_return &&
      saleDoc.totalLineDiscounts + saleDoc.discountAmountMoney > 0
    ) {
      sTextPrint +=
        SamsungKioskReceiptSpecialTags.SK_ALIGNMENT_CENTER_TAG +
        SamsungKioskReceiptSpecialTags.SK_BOLD_TEXT_TAG +
        "YOU SAVED: " +
        sCurrencySymbol +
        (saleDoc.totalLineDiscounts + saleDoc.discountAmountMoney).toFixed(2) +
        SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;
      sTextPrint +=
        SamsungKioskReceiptSpecialTags.SK_BOLD_TEXT_TAG +
        "-".repeat(PrintingTapeReceiptSetting.CHARACTERS_PER_LINE) +
        SamsungKioskReceiptSpecialTags.SK_END_AND_NEWLINE_TAG;
    }

    return sTextPrint;
  }
}

//-- LOGS COMPONENT DATA
const _LOGS_LIST = null,
  _LOGS_CONT = null,
  _LOG_COUNT = 0;

//-- PRINTER API DATA
var PRINT_PORT = {
  PRINT_PORT_0: "PRINTERPORT0",
  PRINT_PORT_1: "PRINTERPORT1",
  PRINT_PORT_2: "PRINTERPORT2",
};

var BAUDRATE = 115200; //9600

var PARITY = {
  PARITY_NONE: "NONE",
  PAIRTY_ODD: "ODD",
  PARITY_EVEN: "EVEN",
};

var STOP_BITS = {
  STOP_BITS_ONE: "1",
  STOP_BITS_ONE_POINT_FIVE: "1.5",
  STOP_BITS_TWO: "2",
};

var DATA_BITS = {
  DATA_BITS_5: "BITS5",
  DATA_BITS_6: "BITS6",
  DATA_BITS_7: "BITS7",
  DATA_BITS_8: "BITS8",
};

export enum PrintingTapeReceiptSetting {
  CHARACTERS_PER_LINE = 42,
  DESC_TOTAL_COLUMN_LENGTH = 21,
}

export enum SamsungKioskReceiptSpecialTags {
  SK_END_TAG = "</>",
  SK_NEWLINE_TAG = "<e>",
  SK_END_AND_NEWLINE_TAG = "</><e>",
  SK_ALIGNMENT_LEFT_TAG = "<l>",
  SK_ALIGNMENT_CENTER_TAG = "<c>",
  SK_ALIGNMENT_RIGHT_TAG = "<r>",
  SK_BOLD_TEXT_TAG = "<b>",
  SK_UNDERLINED_TEXT_TAG = "<l>",
  SK_STRIKETHROUGHT_TEXT_TAG = "<s>",
  SK_FONT_SIZE_1 = "<1>",
  SK_FONT_SIZE_2 = "<2>",
  SK_FONT_SIZE_3 = "<3>",
  SK_FONT_SIZE_4 = "<4>",
  SK_FONT_SIZE_5 = "<5>",
  SK_FONT_SIZE_6 = "<6>",
  SK_FONT_SIZE_7 = "<7>",
}
