<!-- <td class="col-details">
  <a href="javascript://" (click)="onShowDetailsPressed()" [attr.tabindex]="-1">Details</a>
</td> -->

<td class="col-payment-type">
  <span class="p-column-title">Payment:</span>
  <a href="javascript:void(0)" (click)="onShowDetailsPressed()" tabindex="-1">
    {{ isCash ? _myForm.get("tenderTypeType")?.value : _myForm.get("tenderDescription")?.value }}
  </a>
</td>

<td *ngIf="showSystemValue" class="col-system-amount">
  <span class="p-column-title">System:</span>
  {{ _myForm.get("systemTotal")?.value | currency : currencyISOCode : "code" | formatNegativeNumber }}
</td>

<td class="col-actual-amount">
  <span class="p-column-title">Reported Amount:</span>
  <ng-template [ngIf]="isEditable"
    ><taku-input
      [formGroup]="_myForm"
      fieldName="actualAmount"
      keyFilter="num"
      [ngClass]="{ 'text-right': !isMobile() }"
      [autoSelect]="true"
    ></taku-input
  ></ng-template>
  <ng-template [ngIf]="!isEditable">{{
    _myForm.get("actualAmount")?.value | currency : currencyISOCode : "code" | formatNegativeNumber
  }}</ng-template>
</td>

<td *ngIf="showOverUnderValue" class="col-over-under">
  <span class="p-column-title">Over/Under:</span>
  {{ overUnderAmount | currency : currencyISOCode : "code" | formatNegativeNumber }}
</td>
