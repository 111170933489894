/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import * as _ from "lodash";
import { AppConstants } from "../../../../shared/app-constants";
declare var gapi: any;

@Injectable({
  providedIn: "root",
})
export class GMB_APIService {
  initOAuth(fn) {
    gapi.load("client:auth2", () => {
      const auth = gapi.auth2.init({
        client_id: AppConstants.GMB_API_CLIENT_ID,
      });
      fn(auth);
    });
  }

  oAuthSignIn(signInOptions = {}): Promise<any> {
    // const defaultSignInOptions = { scope: "https://www.googleapis.com/auth/content" };
    const defaultSignInOptions = { scope: "https://www.googleapis.com/auth/plus.business.manage" };
    _.defaults(signInOptions, defaultSignInOptions);

    return gapi.auth2
      .getAuthInstance()
      .grantOfflineAccess(signInOptions)
      .then(
        function (resp) {
          return resp;
        },
        function (err) {
          console.error("Error signing in", err);
        }
      );
  }

  oAuthAuthorize(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      gapi.auth2.authorize(
        {
          client_id: AppConstants.GMB_API_CLIENT_ID,
          scope: "email profile openid https://www.googleapis.com/auth/plus.business.manage",
          prompt: "select_account",
          response_type: "code",
        },
        (response: any) => {
          if (response.error) {
            reject(response);
          } else {
            resolve(response);
          }
        }
      );
    });
  }

  disconnect() {
    // gapi.auth2.getAuthInstance().signOut();
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
