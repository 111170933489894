<taku-form-dialoge-header
  [pristine]="_myForm.pristine"
  [valid]="_myForm.valid"
  (backClicked)="goBack()"
  (saveClicked)="onSave()"
  (revertClicked)="onRevert()"
  (newClicked)="onNew()"
  (cancelClicked)="closeSidebar()"
  (deleteClicked)="onDelete()"
  (cloneClicked)="onClone()"
  toolbarTitle="TAX RULE DETAILS"
></taku-form-dialoge-header>

<form [formGroup]="_myForm" class="col-12">
  <p-panel>
    <ng-template pTemplate="header">
      <div>
        <h1>Tax Rule Information</h1>
      </div>
      <taku-checkbox [formGroup]="_myForm" [fieldName]="'isEnabled'" fieldCaption="Active"> </taku-checkbox>
    </ng-template>

    <div class="grid col-12">
      <div class="grid col-12 lg:col-6">
        <div class="col-12">
          <strong>General Information</strong>
        </div>
        <div class="col-6">
          <taku-input [formGroup]="_myForm" fieldName="ruleName" fieldCaption="Rule Name"></taku-input>
        </div>
        <div id="tax_acronym" class="col-6">
          <taku-input [formGroup]="_myForm" fieldName="taxAcronym" fieldCaption="Printer Acronym"></taku-input>
        </div>
        <div id="tax_name" class="col-12 lg:col-6 drop-container">
          <taku-dropdown [formGroup]="_myForm" [options]="lookup_taxes" fieldName="taxId" fieldCaption="Tax Name">
          </taku-dropdown>
        </div>
        <div class="col-12 lg:col-6">
          <!-- <taku-input [formGroup]="_myForm" fieldName="taxRate"
          fieldCaption="Tax Rate" [rightIconClass]="isFlatTax ? 'pi pi-dollar' : 'pi pi-percentage'">
        </taku-input> -->

          <div class="p-inputgroup">
            <span class="p-inputgroup-addon" *ngIf="isFlatTax">{{ rateCurrency?.code }}</span>
            <span class="p-inputgroup-addon" *ngIf="!isFlatTax">%</span>
            <span class="p-float-label">
              <input pInputText pKeyFilter="money" formControlName="taxRate" />
              <label>Tax Rate</label>
              <i
                class="material-icons clear-field"
                (click)="clearField('taxRate')"
                *ngIf="_myForm.controls['taxRate'].value"
                >close</i
              >
            </span>
          </div>
        </div>

        <div class="col-12 lg:col-12">
          <taku-checkbox [formGroup]="_myForm" fieldName="applyEffectiveDate" fieldCaption="Apply Effective Date">
          </taku-checkbox>
        </div>

        <div class="col-12 lg:col-6">
          <taku-calendar-date
            [formGroup]="_myForm"
            fieldName="effectiveDate"
            fieldCaption="Effective Date"
            [disabled]="!_myForm.get('applyEffectiveDate').value"
          >
          </taku-calendar-date>
        </div>

        <div class="col-12 lg:col-6">
          <taku-calendar-date
            [formGroup]="_myForm"
            fieldName="endDate"
            fieldCaption="End Date"
            [disabled]="!_myForm.get('applyEffectiveDate').value"
          ></taku-calendar-date>
        </div>

        <hr class="fields-divider" />

        <div class="col-12">
          <strong>Geography</strong>
        </div>

        <div class="col-12 lg:col-6 drop-container">
          <taku-country [formGroup]="_myForm" fieldName="countryIsoCode" fieldCaption="Country"> </taku-country>
        </div>
        <div class="col-12 lg:col-6">
          <!-- <taku-postal-code #postalCode [formGroup]="_myForm" fieldName="postalCodes" fieldCaption="Postal/Zip" [countryIsoCode]="_myForm.get('countryIsoCode').value">
          </taku-postal-code> -->
          <taku-input [formGroup]="_myForm" fieldName="postalCodes" fieldCaption="Postal/Zip Codes"></taku-input>
        </div>

        <div class="col-12">
          <taku-multiselect
            [formGroup]="_myForm"
            [options]="enum_subdivisions"
            dataKey="subDivisionIsoCode"
            fieldName="subDivisions"
            fieldCaption="Regions"
            appendTo="body"
          ></taku-multiselect>
        </div>

        <div class="col-12">
          <taku-chips
            [formGroup]="_myForm"
            fieldName="cities"
            fieldPlaceholder="Press Enter to Save Cities"
          ></taku-chips>
        </div>
      </div>

      <div class="grid col-12 lg:col-6">
        <div class="col-12">
          <strong>Time Limits</strong>
        </div>

        <div class="col-12">
          <taku-checkbox [formGroup]="_myForm" fieldName="isTimeLimited" fieldCaption="Time Based"> </taku-checkbox>
        </div>

        <div class="col-12 lg:col-6">
          <taku-calendar-time [formGroup]="_myForm" fieldName="timeFrom" fieldCaption="Starting Time">
          </taku-calendar-time>
        </div>

        <div class="col-12 lg:col-6">
          <taku-calendar-time [formGroup]="_myForm" fieldName="timeTo" fieldCaption="Ending Time"> </taku-calendar-time>
        </div>

        <hr class="fields-divider" />

        <div id="tax_categories" class="col-12">
          <strong>Tax Categories</strong>
        </div>
        <div class="col-12 lg:col-6">
          <taku-multiselect
            [formGroup]="_myForm"
            [options]="lookup_taxAccountCategories$ | async"
            fieldName="taxAccountCategories"
            fieldCaption="Account Categories"
          ></taku-multiselect>
        </div>

        <div class="col-12 lg:col-6">
          <taku-multiselect
            [formGroup]="_myForm"
            [options]="lookup_taxInventoryCategories$ | async"
            fieldName="taxInventoryCategories"
            fieldCaption="Inventory Categories"
          ></taku-multiselect>
        </div>

        <hr class="fields-divider" />

        <div class="col-12">
          <strong>Others</strong>
        </div>

        <div id="tax_calculation_type" class="col-12 lg:col-6 drop-container">
          <taku-dropdown
            [formGroup]="_myForm"
            [options]="enum_tax_calc_type"
            fieldName="taxCalcType"
            fieldCaption="Tax Calculation Type"
          ></taku-dropdown>
        </div>

        <div class="col-12 lg:col-6">
          <taku-input-spinner [formGroup]="_myForm" [min]="0" fieldName="priority" fieldCaption="Tax Rule Priority">
          </taku-input-spinner>
        </div>

        <div class="col-12 lg:col-12">
          <taku-checkbox [formGroup]="_myForm" fieldName="isOnlineTax" fieldCaption="Online Tax" [disabled]="true">
          </taku-checkbox>
        </div>
      </div>
    </div>
  </p-panel>
</form>
