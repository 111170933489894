/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, OnDestroy } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MessageService } from "primeng/api";
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from "primeng/dynamicdialog";
import { AlertMessagesService } from "../../../../../shared/services/alert-messages.service";
import { BackendGMCService } from "../BackendGMCService";
import { LIAInventoryContact } from "../gmc-accounts/GMCGoogleAccount";
import * as _ from "lodash";
import { finalize } from "rxjs/operators";
import { BlockingUIService } from "../../../../../shared/services/ui/blocking-ui.service";
import { Subscription } from "rxjs";

@Component({
  selector: "taku-inventory-contact-info",
  templateUrl: "./inventory-contact-info.component.html",
  styleUrls: ["./inventory-contact-info.component.scss"],
  providers: [DialogService],
})
export class GmcInventoryContactInfoComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];
  _formGroup: UntypedFormGroup;
  _gmcAccountId: number;
  _inventoryContact: LIAInventoryContact;
  _progressBarDialog: DynamicDialogRef;

  constructor(
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private dialogService: DialogService,
    private fb: UntypedFormBuilder,
    private backendGMCService: BackendGMCService,
    private alertMessagesService: AlertMessagesService,
    private blockingUIService: BlockingUIService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this._gmcAccountId = this.dialogConfig.data.gmcAccountId;
    // If contact info can't be found, pass an empty objec with necessary fields
    this._inventoryContact = _.defaults(this.dialogConfig.data.contactInfo, {
      inventoryVerificationContactName: null,
      inventoryVerificationContactEmail: null,
    });

    this._formGroup = this.fb.group(this._inventoryContact);
  }

  onSave() {
    Object.assign(this._inventoryContact, this._formGroup.value);
    const contactEmail = this._inventoryContact.inventoryVerificationContactEmail;
    const contactName = this._inventoryContact.inventoryVerificationContactName;
    const dialogData = this.dialogConfig.data;

    this._progressBarDialog = this.blockingUIService.showProgressBar(this.dialogService);
    this.subsList.push(
      this.backendGMCService
        .setSetLiaInventoryContact(
          this._gmcAccountId,
          contactEmail,
          contactName,
          dialogData.country,
          dialogData.language
        )
        .pipe(finalize(() => this._progressBarDialog.close()))
        .subscribe({
          next: (response) => {
            this.messageService.add(this.alertMessagesService.getMessage("gmc-lia-inventorycontact-success"));
            this.dialogRef.close({
              success: false,
            });
          },
          error: (error) => {
            this.messageService.add(this.alertMessagesService.getMessage("gmc-lia-inventorycontact-error"));
            this._formGroup.markAsDirty();
          },
        })
    );
  }

  closeDialog() {
    this.dialogRef.close({
      success: false,
    });
  }

  onRevert() {
    this._formGroup.setValue(this._inventoryContact);
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
