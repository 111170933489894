import { Component, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { Station } from "../../settings/store-settings/station/station";
import { Store } from "../../settings/store-settings/store/store";

@Component({
  selector: "taku-moneris-open-total-details-dialog",
  templateUrl: "./moneris-open-total-details-dialog.component.html",
  styleUrls: ["./moneris-open-total-details-dialog.component.scss"],
})
export class MonerisOpenTotalDetailsDialogComponent implements OnInit {
  detailsObject;
  _digitsFormat = "1.0-0";
  store: Store;
  station: Station;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private appSettingsService: AppSettingsStorageService
  ) {
    if (this.dialogConfig.data._detailsObject) {
      this.detailsObject = this.dialogConfig.data._detailsObject;
    }
    this.store = this.appSettingsService.getStore();
    this.station = this.appSettingsService.getStation();
  }

  ngOnInit(): void {}

  onClosePressed() {
    this.dialogRef.close();
  }
}
