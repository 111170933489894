/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { NestedFormComponent } from "../../../forms/nested-form/nested-form.component";
import { AddressType } from "../address/address";
import { AddressComponent } from "../address/address.component";
import { CommercialAccountAddress } from "./commercial-account-address";

@Component({
  selector: "taku-commercial-account-address",
  templateUrl: "./commercial-account-address.component.html",
  styleUrls: ["./commercial-account-address.component.scss"],
})
export class CommercialAccountAddressComponent extends NestedFormComponent {
  // Define enum variables

  static init(fb: UntypedFormBuilder, addressType: AddressType, isDefaultAddress = false): UntypedFormGroup {
    const newAddress = new CommercialAccountAddress(addressType);
    newAddress.address.isDefault = isDefaultAddress;
    return CommercialAccountAddressComponent.set(fb, newAddress);
  }

  static setArray(fb: UntypedFormBuilder, commercialAccountAddresses: CommercialAccountAddress[]) {
    const formArray = fb.array([]);
    commercialAccountAddresses.forEach((commercialAccountAddress) => {
      formArray.push(CommercialAccountAddressComponent.set(fb, commercialAccountAddress));
    });
    return formArray;
  }

  static set(fb: UntypedFormBuilder, commercialAccountAddress: CommercialAccountAddress) {
    const formGroup = fb.group(commercialAccountAddress);
    formGroup.setControl("address", AddressComponent.set(fb, commercialAccountAddress.address));
    return formGroup;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
