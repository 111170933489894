/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { SalesReportComponent } from "./sales-report/sales-report.component";
import { ReportsParamsComponent } from "../reports-params/reports-params.component";
import { Report } from "../report";
import { SaleTaxReportComponent } from "./sale-tax-report/sale-tax-report.component";
import { SalesAnalysisReportComponent } from "./sales-analysis-report/sales-analysis-report.component";
import { SalesAlanysisSummaryReportComponent } from "./sales-alanysis-summary-report/sales-alanysis-summary-report.component";
import { SaleBankReconciliationReportComponent } from "./sale-bank-reconciliation-report/sale-bank-reconciliation-report.component";
import { InventoryValueReportComponent } from "./inventory-value-report/inventory-value-report.component";
import { ReportsParamsStocksComponent } from "../reports-params-stocks/reports-params-stocks.component";
import { TreeNode } from "primeng/api";
import { TAKUPayoutsReportComponent } from "./taku-payouts-report/taku-payouts-report.component";
import { ReportsParamsTAKUPayAccountsComponent } from "../reports-params-taku-pay-accounts/reports-params-takupay-accounts.component";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";

@Injectable({
  providedIn: "root",
})
export class ReportService {
  constructor(private appSetting: AppSettingsStorageService) {}

  getRport(repotName: ReportName): Report {
    switch (repotName) {
      case ReportName.DAILY_SALES:
        return new Report(ReportsParamsComponent, SalesReportComponent);
      case ReportName.TAX_SALES:
        return new Report(ReportsParamsComponent, SaleTaxReportComponent);
      case ReportName.SALES_ANALYSIS:
        return new Report(ReportsParamsComponent, SalesAnalysisReportComponent);
      case ReportName.SALES_ANALYSIS_SUMMARY:
        return new Report(ReportsParamsComponent, SalesAlanysisSummaryReportComponent);
      case ReportName.SALES_BANK_RECONCILIATION:
        return new Report(ReportsParamsComponent, SaleBankReconciliationReportComponent);
      case ReportName.INVENTORY_VALUE_REPORT:
        return new Report(ReportsParamsStocksComponent, InventoryValueReportComponent);
      case ReportName.TAKU_PAYOUTS_REPORT:
        return new Report(ReportsParamsTAKUPayAccountsComponent, TAKUPayoutsReportComponent);
      default:
        return null;
    }
  }

  getReportsList(): TreeNode[] {
    return [
      {
        label: "Sales",
        data: "Sales Reports",
        expandedIcon: "pi pi-shopping-cart",
        collapsedIcon: "pi pi-shopping-cart",
        selectable: false,
        children: [
          {
            label: "Daily Sales Report",
            data: ["/reports", ReportName.DAILY_SALES],
          },
          {
            label: "Sales Analysis Report",
            data: ["/reports", ReportName.SALES_ANALYSIS],
          },
          {
            label: "Sales Analysis Summary Report",
            data: ["/reports", ReportName.SALES_ANALYSIS_SUMMARY],
          },
        ],
      },
      {
        label: "Financials",
        data: "Financials Reports",
        expandedIcon: "pi pi-tag",
        collapsedIcon: "pi pi-tag",
        selectable: false,
        children: [
          {
            label: "Sales Tax Report",
            data: ["/reports", ReportName.TAX_SALES],
          },
          {
            label: "Sales Bank Reconciliation Report",
            data: ["/reports", ReportName.SALES_BANK_RECONCILIATION],
          },
          ...(this.appSetting.isTAKUPayAccountExists()
            ? [
                {
                  label: "TAKU Pay Payouts Report",
                  data: ["/reports", ReportName.TAKU_PAYOUTS_REPORT],
                },
              ]
            : []),
        ],
      },
      {
        label: "Inventory",
        data: "Inventory Reports",
        expandedIcon: "pi pi-tag",
        collapsedIcon: "pi pi-tag",
        selectable: false,
        children: [
          {
            label: "OH Inventory Value Report",
            data: ["/reports", ReportName.INVENTORY_VALUE_REPORT],
          },
        ],
      },
    ];
  }
}

export enum ReportName {
  DAILY_SALES = "dailySales",
  TAX_SALES = "taxSales",
  SALES_ANALYSIS = "salesAnalysis",
  SALES_ANALYSIS_SUMMARY = "salesAnalysisSummary",
  SALES_BANK_RECONCILIATION = "salesBankReconciliation",
  INVENTORY_VALUE_REPORT = "inventoryValue",
  TAKU_PAYOUTS_REPORT = "takuPayouts",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
