/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { ValidationMessagesService } from "../../shared/services/validation-messages.service";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "form-validation-messages",
  templateUrl: "./form-validation-messages.component.html",
  styleUrls: ["./form-validation-messages.component.scss"],
  providers: [ValidationMessagesService],
})
export class FormValidationMessagesComponent implements OnChanges, OnInit {
  @Input() errors;
  @Input() fieldName: string;
  @Input() showRequiredValidation = false;
  @Input() severity = "error";

  _severityClassStyle = "";
  _errorMessages: Array<string>;
  constructor(private validationMessagesService: ValidationMessagesService) {}

  ngOnChanges(changes: SimpleChanges) {
    this._errorMessages = [];
    for (const key in this.errors) {
      if (this.errors.hasOwnProperty(key) && (this.showRequiredValidation || key !== "required")) {
        this._errorMessages.push(this.validationMessagesService.getErrorMessage(key, this.errors[key], this.fieldName));
      }
    }
  }

  ngOnInit(): void {
    this._severityClassStyle = this.mapSeverityCSSClass();
  }

  private mapSeverityCSSClass(): string {
    switch (this.severity) {
      case "warning":
        return "p-messages-warn";
      default:
        return "p-messages-error";
    }
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
