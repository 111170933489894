/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable, EventEmitter } from "@angular/core";
import { ValidatorFn } from "@angular/forms";
import {
  DataType,
  FilterType,
  ExecuteEvent_DisplayMode,
  ExecuteEventDatatypeOptions,
} from "../../../../form-list/form-list/form-list";
import { FormListModel } from "../../../../form-list/form-list/form-list-model.interface";
import { DBService } from "../../../../shared/services/db.service";
import { AuthService } from "../../../../shared/services/auth.service";
import { SaleDoc } from "../../sale-document/sale-doc/sale-doc";
import { HttpClient } from "@angular/common/http";
import { SaleDocType } from "../../doc/doc";

@Injectable({
  providedIn: "root",
})
export class DocumentSearchReturnColsServiceFactory {
  constructor(protected http: HttpClient, protected authService: AuthService) {}

  build(previewReturnDocEvent: EventEmitter<SaleDoc>) {
    return new DocumentSearchReturnColsService(this.http, this.authService, previewReturnDocEvent);
  }
}

export class DocumentSearchReturnColsService extends DBService implements FormListModel {
  // enum_doc_states = this.enumSelectOptions(DocState);
  enum_doc_types = this.enumSelectOptions(SaleDocType);

  constructor(
    protected http: HttpClient,
    protected authService: AuthService,
    private previewReturnDocEvent: EventEmitter<SaleDoc>
  ) {
    super(http, authService);
  }

  getValidationRules(): { [key: string]: {} | ValidatorFn[] } {
    return {};
  }

  getFormListColumns() {
    return [
      {
        field: "selectButton",
        header: "",
        visible: true,
        readonly: true,
        frozen: true,
        dataType: DataType.execute_event,
        dataOptions: [],
        dataTypeOptions: [
          new ExecuteEventDatatypeOptions({
            displayMode: ExecuteEvent_DisplayMode.BUTTON,
            label: "SELECT",
            event: this.previewReturnDocEvent,
          }),
        ],
        filterType: FilterType.none,
        colWidth: 80,
      },
      {
        field: "doc.docType",
        header: "Doc Type",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enum_doc_types,
        filterType: FilterType.enum,
        colWidth: 120,
        isNotSortable: true,
      },
      {
        field: "doc.docNo",
        header: "Doc #",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
        isNotSortable: true,
      },
      {
        field: "accountName",
        header: "Account",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.none,
        isNotSortable: true,
        colWidth: 120,
      },
      {
        field: "doc.docDate",
        header: "Date",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.date_date_only,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 90,
        isNotSortable: true,
      },
      {
        field: "doc.localDocTime",
        header: "Time",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.date_time,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 70,
        sortFields: ["doc.docTime"],
        isNotSortable: true,
      },
      {
        field: "grandTotalCurrencyFormat",
        header: "Total",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.none,
        isNotSortable: true,
        colWidth: 85,
      },
      // { field: 'grandTotal', header: 'Total', visible: true, readonly: true, frozen: false, dataType: DataType.input, dataOptions: [], filterType: FilterType.contains, colWidth:  },
      {
        field: "tenderTypesList",
        header: "Pay Types",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.none,
        isNotSortable: true,
        colWidth: 90,
      },
    ];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
