<taku-form-header
  [formGroup]="_myForm"
  (backClicked)="goBack()"
  (saveClicked)="onSave()"
  (revertClicked)="onRevert()"
  [showSecondaryBtns]="false"
  [toolbarTitle]="customHeaderTitle || pageTitle"
  [displayMode]="insideWizard ? FormHeaderDisplayMode.WIZARD : null"
  iconStyleClass="material-icons"
  iconName="text_fields"
></taku-form-header>

<div [formGroup]="_myForm" class="grid">
  <p-card class="col-12">
    <div id="saleDocFormatList" class="col-12 lg:col-10 lg:col-offset-1 xl:col-8 xl:col-offset-2">
      <p-table
        id="docFormatsTable"
        class="col-12 grid-table"
        [value]="_myForm.get('saleDocFormats')['controls']"
        responsiveLayout="stack"
        breakpoint="640px"
        styleClass="p-datatable-striped"
      >
        <ng-template pTemplate="header">
          <tr>
            <th class="col-doctype">Document Type</th>
            <th class="col-prefix">Prefix</th>
            <th class="col-numdigits"># of Digits</th>
            <th class="col-firstNumber">Starting Doc #</th>
            <th class="col-suffix">Suffix</th>
            <th class="col-preview">Preview</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-docFormatsForm let-i="rowIndex">
          <tr *ngIf="i === 0">
            <taku-fields-format
              [formGroup]="_myForm"
              docType="Cashout"
              prefixFieldName="cashoutPrefix"
              suffixFieldName="cashoutSuffix"
              noOfDigitsFieldName="cashoutNoOfDigits"
              startingNumberFieldName="cashoutStartingNumber"
            ></taku-fields-format>
          </tr>
          <tr *ngIf="i === 0">
            <taku-fields-format
              [formGroup]="_myForm"
              docType="Commitment"
              prefixFieldName="commitmentPrefix"
              suffixFieldName="commitmentSuffix"
              noOfDigitsFieldName="commitmentNoOfDigits"
              startingNumberFieldName="commitmentStartingNumber"
            ></taku-fields-format>
          </tr>
          <tr>
            <taku-fields-format
              [formGroup]="docFormatsForm"
              [docType]="docFormatsForm.get('docType').value"
            ></taku-fields-format>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <!-- <hr class="fields-divider" /> -->
  </p-card>
</div>
