/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import * as moment from "moment";

export class FiscalYearsSettings {
  id: number;
  fiscalYearName: string;
  startDate: Date;
  endDate: Date;
  isCurrent = false;

  constructor() {
    this.id = 0;
    this.fiscalYearName = moment().format("YYYY");
    this.startDate = moment().startOf("year").toDate();
    this.endDate = moment().endOf("year").toDate();
    this.isCurrent = this._isCurrent();
  }

  _isCurrent(): boolean {
    const currentDate = new Date();
    return currentDate <= this.endDate && currentDate >= this.startDate;
  }

  // isCurrernt is just local variable and is not part of data base

  // manageYears: FiscalYear[];

  // constructor(){
  //     this.manageYears = [
  //         {yearName: 'Fiscal Year 2018', startDate: '2018-01-01', endDate: '2018-12-31', isCurrent: true},
  //         {yearName: 'Fiscal Year 2017', startDate: '2017-01-01', endDate: '2017-12-31', isCurrent: false}
  //     ];
  // }
}

// export class FiscalYear {
//     yearName: string;
//     startDate: string;
//     endDate: string;
//     isCurrent: boolean;
// }

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
