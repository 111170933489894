<taku-form-header
  [displayMode]="_isDialog ? 'inline' : null"
  [formGroup]="_myForm"
  (backClicked)="goBack()"
  (saveClicked)="onSave()"
  (revertClicked)="onRevert()"
  (newClicked)="onNew()"
  (deleteClicked)="onDelete()"
  (cloneClicked)="onClone()"
  toolbarTitle="{{ displayTitle }}"
  [insideDialog]="_isDialog"
  [isSaving]="_isSaving"
  [iconStyleClass]="'material-icons'"
  [iconName]="iconName"
>
  <button
    id="print_label"
    pButton
    pTooltip="Print Label"
    tooltipPosition="left"
    class="header-button p-button-rounded"
    (click)="openLalelSettings()"
    icon="pi pi-tag"
  ></button>
</taku-form-header>

<div class="col-12 pl-0 pr-0">
  <div [formGroup]="_myForm" class="grid inventory-top-container">
    <div class="col-12 lg:col-4 xl:col-3 md:col-5 inventory-basicinfo-container">
      <p-panel>
        <ng-template pTemplate="header">
          <h2>Basic Info</h2>
          <taku-checkbox
            *ngIf="isStatusVisible"
            fieldCaption="ACTIVE"
            _disabled="!isStatusEnabled"
            class="form-enableSwitch"
            [formGroup]="_myForm"
            fieldName="isEnabled"
          ></taku-checkbox>
        </ng-template>

        <div class="grid">
          <div class="col-12 flex justify-content-end" *ngIf="_myForm.get('parentInventoryId').value">
            <span class="pt-1">Shares with Parent</span>
            <i class="material-icons" style="width: 20px">bookmarks</i>
          </div>
          <div class="col-12">
            <taku-input [formGroup]="_myForm" fieldName="sku" fieldCaption="SKU"></taku-input>
          </div>

          <div class="col-12">
            <taku-input-textarea [formGroup]="_myForm" fieldName="description1" fieldCaption="Product Title">
            </taku-input-textarea>
          </div>
          <div class="col-12" *ngIf="inventoryVariantsTitle">
            {{ inventoryVariantsTitle }}
          </div>

          <!-- VARIANTS -->
          <div class="col-12" *ngIf="!_myForm.get('parentInventoryId').value">
            <taku-checkbox
              [formGroup]="_myForm"
              fieldName="isVariantParent"
              fieldCaption="Apply Variants (e.g. size, etc.)"
              (onChange)="onApplyVariantsCheckBoxClicked($event)"
              [disabled]="_myForm.controls['id'].value === 0"
            >
            </taku-checkbox>
            <button
              pButton
              type="button"
              label="Variants"
              *ngIf="_myForm.get('isVariantParent').value"
              (click)="showVariants()"
              [disabled]="_myForm.dirty"
            >
              <i class="material-icons">open_in_new</i>
              <!-- <strong>Variants</strong> -->
            </button>
            <div
              class="grid"
              *ngFor="
                let inventoryVariantSettingForm of _myForm.get('inventoryVariantSettings')['controls'];
                let i = index
              "
            >
              <div class="col-12">
                <div
                  class="col-12 font-bold text-xl"
                  *ngIf="inventoryVariantSettingForm?.value?.inventoryOptionsSetting != null"
                >
                  {{ inventoryVariantSettingForm?.value?.inventoryOptionsSetting?.optionName }}:
                </div>
                <div class="col-12">
                  <div class="grid p-fluid">
                    <span
                      *ngFor="
                        let item of inventoryOptionsSettingValuesChips(
                          inventoryVariantSettingForm.get('inventoryOptionsSettingValues').value
                        )
                      "
                      class="p-1"
                    >
                      <p-chip [label]="item"> </p-chip>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12" *ngIf="_myForm.controls.parentInventoryId.value && !_isDialog">
            <h4 class="parent-sku">
              Associated with parent SKU
              <a class="underline" [routerLink]="['/inventory', _myForm.value.parentInventoryId]">{{
                _myForm.value.parentInventory.sku
              }}</a>
            </h4>

            <br />
            <button
              pButton
              type="button"
              label="Unlink from parent SKU"
              *ngIf="_myForm.get('isVariantParent').value"
              (click)="unlinkInventoryParent()"
              [disabled]="_myForm.dirty"
            >
              <!-- <strong>Variants</strong> -->
            </button>
          </div>

          <div class="col-12 drop-container" [ngClass]="{ grid: _myForm.get('parentInventoryId').value }">
            <taku-dropdown
              [formGroup]="_myForm"
              [options]="lookup_uOfMeasure"
              fieldName="uOfMeasureId"
              [ngClass]="{ col: _myForm.get('parentInventoryId').value }"
              fieldCaption="Unit of Measure"
              [disabled]="_myForm.get('parentInventoryId').value"
            ></taku-dropdown>
            <i *ngIf="_myForm.get('parentInventoryId').value" class="material-icons bookmarks">bookmarks</i>
          </div>

          <div class="col-12 drop-container">
            <taku-money
              *ngIf="isSellPriceVisible"
              [disabled]="!isSellPriceEnabled"
              [formGroup]="_myForm"
              fieldName="standardPrice"
              fieldCaption="Sell Price"
              currencyFieldName="standardPriceCurrencyIsoCode"
              currencyFieldCaption="Currency"
            ></taku-money>
          </div>

          <div class="col-12 drop-container">
            <taku-money
              *ngIf="isAltPriceVisible"
              [disabled]="!isAltPriceEnabled"
              [formGroup]="_myForm"
              fieldName="msrpPrice"
              fieldCaption="Alt Price"
              currencyFieldName="msrpPriceCurrencyIsoCode"
              currencyFieldCaption="Currency"
            ></taku-money>
          </div>
          <p-fieldset
            *ngIf="isPriceOptionsVisible"
            class="col-12"
            legend="Pricing Options"
            [toggleable]="true"
            [collapsed]="true"
          >
            <div class="grid">
              <div class="col-12">
                <taku-checkbox
                  [formGroup]="_myForm"
                  fieldName="isSalePriceEditable"
                  fieldCaption="Allow price editing in salesscreen"
                  [disabled]="!isPriceOptionsEnabled"
                ></taku-checkbox>
              </div>
              <div class="col-6" *ngIf="false">
                <taku-input
                  [formGroup]="_myForm"
                  fieldName="standardMinPrice"
                  fieldCaption="Min Price"
                  [disabled]="!isPriceOptionsEnabled || !_myForm.get('isSalePriceEditable').value"
                ></taku-input>
              </div>
              <div class="col-6" *ngIf="false">
                <taku-input
                  [formGroup]="_myForm"
                  fieldName="standardMaxPrice"
                  fieldCaption="Max Price"
                  [disabled]="!isPriceOptionsEnabled || !_myForm.get('isSalePriceEditable').value"
                ></taku-input>
              </div>
              <div class="col-12">
                <taku-checkbox
                  [formGroup]="_myForm"
                  fieldName="isReturnPriceEditable"
                  fieldCaption="Allow price editing in return screen"
                  [disabled]="!isPriceOptionsEnabled"
                ></taku-checkbox>
              </div>
              <div class="col-12">
                <taku-checkbox
                  [formGroup]="_myForm"
                  fieldName="allowDiscount"
                  fieldCaption="Max line discounting in salesscreen"
                  [disabled]="!isPriceOptionsEnabled"
                ></taku-checkbox>
              </div>

              <div class="col-6" [style.marginBottom]="'15px'">
                <taku-input
                  [disabled]="!_myForm.get('allowDiscount').value"
                  [formGroup]="_myForm"
                  fieldName="maxDiscountPercentage"
                  fieldCaption="Max %"
                >
                </taku-input>
              </div>

              <div class="col-6" [style.marginBottom]="'15px'">
                <taku-input
                  [disabled]="!_myForm.get('allowDiscount').value"
                  [formGroup]="_myForm"
                  fieldName="maxDiscountAmount"
                  fieldCaption="Max Amount"
                >
                </taku-input>
              </div>
            </div>
          </p-fieldset>
        </div>
        <p-card class="card card-header" styleClass="panel-nopad mt-2">
          <ng-template pTemplate="header">
            <h4 class="col-12">Images</h4>
          </ng-template>
          <div class="grid col-12 gallery-wrapper">
            <div class="col-12 sm:col-12 main-thumbnail-container">
              <img *ngIf="mainPictureForm" [src]="mainPictureSrc" [alt]="mainPictureForm.get('altTitle').value" />
            </div>
            <div class="col-12 sm:col-12 p-fluid">
              <button
                pButton
                label="Open Gallery"
                icon="ui-icon-open-in-new"
                class="p-button-secondary"
                (click)="openGalleryDialog()"
              ></button>
            </div>
          </div>
        </p-card>
      </p-panel>
    </div>

    <div class="right-side-panels col-12 xl:col-9 lg:col-8 md:col-7">
      <div class="inventory-details-container" [ngStyle]="{ flexGrow: 1 }">
        <p-panel styleClass="panel-nopad">
          <ng-template pTemplate="header">
            <h2>Inventory Details</h2>
          </ng-template>
          <p-tabView class="tabview-nopad" [(activeIndex)]="_mainTabActiveIndex">
            <p-tabPanel header="Details">
              <div class="col-12 p-0 cols-aligned">
                <div class="col-12" *ngIf="isStockLevelPanelVisible">
                  <p-card class="card col-12 card-header stockLevel-card card-tableheader">
                    <ng-template pTemplate="header" class="grid">
                      <h4 class="col-12"
                        >Stock Level {{ _myForm.get("isVariantParent").value ? "(Total of All Variants)" : "" }}
                        <a
                          *ngIf="_myForm.get('isVariantParent').value"
                          href="javascript://"
                          class="pi pi-info-circle"
                          (click)="stockLevelPanel.toggle($event)"
                        >
                          <p-overlayPanel #stockLevelPanel [showCloseIcon]="false" appendTo="body">
                            <div class="tooltip-styling">
                              <h5>NOTE:</h5>
                              <ul>
                                <li
                                  >Stock quantities can only be edited at the inventory variant level. Click the
                                  Variants button under Product Type below to edit quantities.</li
                                >
                              </ul>
                            </div>
                          </p-overlayPanel>
                        </a>
                      </h4>
                    </ng-template>
                    <p-table
                      *ngIf="ctrlInventoryZones"
                      class="table-card"
                      [value]="ctrlInventoryZonesFiltered"
                      dataKey="controls.zoneId.value"
                      [expandedRowKeys]="zoneExpandedRowKeys"
                      scrollHeight="320pt"
                      responsiveLayout="stack"
                      [breakpoint]="'640px'"
                      styleClass="p-datatable-striped no-row-borders"
                    >
                      <ng-template pTemplate="header" let-columns>
                        <tr>
                          <th class="expander">
                            <button
                              type="button"
                              pButton
                              pRipple
                              (click)="closeAllZoneRows()"
                              class="p-button-text p-button-rounded p-button-icon-only"
                            >
                              <i class="material-icons">vertical_align_center</i>
                            </button>
                          </th>
                          <th>
                            <h5>Zone Name</h5>
                          </th>
                          <th style="min-width: 10rem">
                            <h5>Cost</h5>
                          </th>
                          <th>
                            <h5>Cost Basis</h5>
                          </th>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-zoneForm let-expanded="expanded">
                        <tr *ngIf="_isMobile">
                          <td class="justify-content-center expander-mobile">
                            <button
                              type="button"
                              pButton
                              pRipple
                              [pRowToggler]="zoneForm"
                              class="p-button-text p-button-rounded p-button-plain"
                              [label]="expanded ? 'Collapse' : 'Expand'"
                              [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                            ></button>
                          </td>
                        </tr>
                        <tr class="sticky-row">
                          <td *ngIf="!_isMobile" class="expander">
                            <button
                              type="button"
                              pButton
                              pRipple
                              [pRowToggler]="zoneForm"
                              class="p-button-text p-button-rounded p-button-plain"
                              [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                            ></button>
                          </td>
                          <td>
                            <span class="p-column-title">Zone Name:</span>
                            <span>{{ zoneForm.get("zone")?.value?.zoneName }}</span>
                          </td>
                          <td>
                            <span class="p-column-title">Cost:</span>
                            <taku-money
                              [disabled]="!isCostingPanelEnabled"
                              [formGroup]="zoneForm"
                              currencyIsReadonly="true"
                              fieldName="standardCost"
                              currencyFieldName="standardCostCurrencyIsoCode"
                              [currencyFieldCaption]="null"
                              appendTo="body"
                            ></taku-money>
                          </td>
                          <td class="cost-basis-row">
                            <span class="p-column-title">Cost Basis:</span>
                            <taku-dropdown
                              [formGroup]="zoneForm"
                              [disabled]="!isCostingPanelEnabled"
                              [options]="enum_cost_basis"
                              fieldName="costBasis"
                              appendTo="body"
                            ></taku-dropdown>
                          </td>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="rowexpansion" let-zoneForm let-columns>
                        <tr>
                          <td colspan="5" class="expanded-row">
                            <p-table
                              styleClass="p-datatable-striped no-row-borders"
                              class="table-card nested-table"
                              [value]="stocksPerZoneMap[zoneForm.get('zoneId').value]"
                            >
                              <ng-template pTemplate="header" let-columns>
                                <tr>
                                  <th class="stockName-col">
                                    <h5>Stock Name</h5>
                                  </th>
                                  <!-- <th class="avail-col">
                                    <h5>Available Qty</h5>
                                  </th> -->
                                  <th class="onHand-col">
                                    <h5>On Hand Qty</h5>
                                  </th>
                                  <th class="committedQty-col">
                                    <h5>Committed Qty</h5>
                                  </th>
                                  <th class="onOrderQty-col">
                                    <h5
                                      >On Order Qty
                                      <i class="pi pi-info-circle" (click)="showTooltipInfoPanel.toggle($event)">
                                        <p-overlayPanel #showTooltipInfoPanel [showCloseIcon]="false" appendTo="body">
                                          <div class="tooltip-panel">
                                            <b>On Order Qty</b> is allocated to each stock location according to the
                                            <b>Receiving Location</b> associated to every finalized
                                            <b>Purchase Order</b>
                                            <br />
                                          </div>
                                        </p-overlayPanel>
                                      </i>
                                    </h5>
                                  </th>
                                  <th *ngIf="!_myForm.get('isVariantParent').value">
                                    <h5>Min Stock Qty</h5>
                                  </th>
                                  <th *ngIf="!_myForm.get('isVariantParent').value">
                                    <h5>Max Stock Qty</h5>
                                  </th>
                                  <th>
                                    <h5>Aisle</h5>
                                  </th>
                                  <th>
                                    <h5>Shelf</h5>
                                  </th>
                                </tr>
                              </ng-template>
                              <ng-template pTemplate="body" let-stockForm>
                                <tr [ngClass]="{ 'is-default': _defaultStockId === stockForm.get('stockId').value }">
                                  <taku-inventory-stock
                                    [disableForm]="!isStockLevelPanelEnabled || _myForm.get('isVariantParent').value"
                                    [showMinStock]="!_myForm.get('isVariantParent').value"
                                    [showMaxStock]="!_myForm.get('isVariantParent').value"
                                    [formGroup]="stockForm"
                                    [aisles_lookup]="lookup_aisles"
                                    [shelves_lookup]="lookup_shelves"
                                  >
                                  </taku-inventory-stock>
                                </tr>
                              </ng-template>
                            </p-table>
                          </td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </p-card>
                </div>

                <div class="col-12 taku-listable-container barcodes-wrapper" formArrayName="inventoryUsageDetails">
                  <p-card class="col-12 card card-header barcodes-card">
                    <ng-template pTemplate="header" class="grid">
                      <h4 class="col-12">Usage Detail</h4>

                      <div class="col-12 add-button-container">
                        <a
                          class="add-button"
                          (click)="addInventoryUsageDetail()"
                          [ngClass]="{ disableAnchor: !isBarcodesEnabled || allFrequencyNameOptionsUsed }"
                        >
                          <label>Add New</label>
                          <i class="material-icons">add_circle_outline</i>
                        </a>
                      </div>
                    </ng-template>

                    <div
                      class="barcodeFieldsWrapper col-11"
                      *ngIf="$any(_myForm.get('inventoryUsageDetails')).length === 0"
                    >
                    </div>
                    <div
                      class="col-12 taku-listable-controlset"
                      *ngFor="let usageDetailForm of _myForm.get('inventoryUsageDetails')['controls']; let i = index"
                    >
                      <div class="barcodeFieldsWrapper col-11">
                        <taku-inventory-usage-detail
                          [disableForm]="!isBarcodesEnabled"
                          [formGroup]="usageDetailForm"
                          [frequencyNameDropdownOptionsDisabled]="frequencyNameDropdownOptionsDisabled"
                          (frequencyNameDropdownOptionChanged)="setFrequencyNameDropdownOptionsDisabled($event)"
                          (copyToClipboard)="copyValueToClipboard($event)"
                        >
                        </taku-inventory-usage-detail>
                      </div>
                      <div class="col-1 remove-button-container">
                        <a (click)="removeInventoryUsageDetail(i)" [ngClass]="{ disableAnchor: !isBarcodesEnabled }">
                          <i class="material-icons remove-button">remove_circle_outline</i>
                        </a>
                      </div>
                    </div>
                  </p-card>
                </div>
                <div class="col-12 taku-listable-container barcodes-wrapper">
                  <p-card class="col-12 card card-header">
                    <ng-template pTemplate="header" class="grid">
                      <h4 class="col-12">Product Type</h4>
                    </ng-template>
                    <div class="grid">
                      <p-fieldset *ngIf="isListTypeVisible" class="col-12 md:col-6 mb-5 md:mb-0 stock-type">
                        <button
                          pButton
                          pRipple
                          [icon]="editStockType ? 'pi pi-minus' : 'pi pi-plus'"
                          class="legend p-button-primary p-button-text"
                          type="button"
                          (click)="editStockType = !editStockType"
                          >Stock Type</button
                        >
                        <div class="grid">
                          <div class="col-7 md:col-12">
                            <p-button
                              (click)="openStockTypeDialog()"
                              label="Set up Stock Type"
                              styleClass="p-button-link"
                              class="link-style-button"
                              [disabled]="!isListTypeEnabled"
                            ></p-button>

                            <ul class="types-enabled-options">
                              <li *ngIf="stockTypeCtrl.value">
                                <span class="option-label">Stock Type:</span>
                                <span class="option-value"
                                  ><strong>{{ stockTypeCtrl.value }}</strong></span
                                >
                              </li>

                              <ng-template
                                *ngIf="
                                  stockTypeCtrl.value === 'Physical';
                                  then physicalInventoryOptions;
                                  else nonPhysicalInventoryOptions
                                "
                              >
                              </ng-template>
                            </ul>

                            <ng-template #physicalInventoryOptions>
                              <li>
                                <span class="option-value"
                                  >Track Quantity: {{ _myForm.get("isTrackQTY").value ? "Yes" : "No" }}</span
                                >
                              </li>
                              <li *ngIf="physicalStockTypesAttributes() as stockAttributes">
                                <span class="option-value">{{ stockAttributes }}</span>
                              </li>
                            </ng-template>

                            <ng-template #nonPhysicalInventoryOptions>
                              <li *ngIf="_myForm.get('nonePhysicalStockType').value as nonePhysicalStockType">
                                <span class="option-value">Non-Physical: {{ nonePhysicalStockType }}</span>
                              </li>
                            </ng-template>
                          </div>

                          <div class="col-5 md:col-12 grid" *ngIf="editStockType">
                            <div class="col-12 md:col-6 checkboxStockType-wrapper">
                              <taku-checkbox
                                [disabled]="!isListTypeEnabled"
                                [formGroup]="_myForm"
                                fieldName="isSellable"
                                fieldCaption="Sellable"
                              ></taku-checkbox>
                            </div>

                            <div class="col-12 md:col-6 checkboxStockType-wrapper">
                              <taku-checkbox
                                [disabled]="!isListTypeEnabled"
                                [formGroup]="_myForm"
                                fieldName="isBuyable"
                                fieldCaption="Buyable"
                              ></taku-checkbox>
                            </div>
                          </div>
                        </div>
                      </p-fieldset>

                      <p-fieldset *ngIf="isListTypeVisible" class="col-12 md:col-6 classification">
                        <button
                          pButton
                          pRipple
                          [icon]="editClassification ? 'pi pi-minus' : 'pi pi-plus'"
                          class="legend p-button-primary p-button-text"
                          type="button"
                          (click)="editClassification = !editClassification"
                          >Classification</button
                        >
                        <div class="grid mt-2">
                          <p class="col-12">Inventory Categories:</p>
                          <span class="ml-2 mb-5">{{ inventoryCategories || "Empty" }}</span>
                        </div>
                        <div class="grid" *ngIf="editClassification">
                          <div
                            class="col-12"
                            *ngIf="isListClassificationVisible"
                            [ngClass]="{ grid: _myForm.get('parentInventoryId').value }"
                          >
                            <taku-multiselect
                              [disabled]="!isListClassificationEnabled || _myForm.get('parentInventoryId').value"
                              [formGroup]="_myForm"
                              [options]="lookup_categories"
                              fieldName="categories"
                              fieldCaption="Inventory Categories"
                              [ngClass]="{ col: _myForm.get('parentInventoryId').value }"
                            ></taku-multiselect>
                            <i *ngIf="_myForm.get('parentInventoryId').value" class="material-icons bookmarks"
                              >bookmarks</i
                            >
                          </div>

                          <div
                            class="col-12 drop-container"
                            *ngIf="isListClassificationVisible"
                            [ngClass]="{ grid: _myForm.get('parentInventoryId').value }"
                          >
                            <taku-dropdown
                              [disabled]="!isListClassificationEnabled || _myForm.get('parentInventoryId').value"
                              [formGroup]="_myForm"
                              [options]="lookup_brands"
                              fieldName="brandId"
                              fieldCaption="Brand"
                              [useFilter]="true"
                              [ngClass]="{ col: _myForm.get('parentInventoryId').value }"
                            >
                            </taku-dropdown>
                            <i *ngIf="_myForm.get('parentInventoryId').value" class="material-icons bookmarks"
                              >bookmarks</i
                            >
                          </div>

                          <div class="col-12 drop-container" *ngIf="isListClassificationVisible">
                            <taku-dropdown
                              [disabled]="!isListClassificationEnabled"
                              [options]="lookup_tax_inventory_category"
                              fieldName="taxInventoryCategoryId"
                              fieldCaption="Tax Categories"
                              [formGroup]="_myForm"
                            ></taku-dropdown>
                          </div>

                          <div
                            class="col-12 drop-container"
                            *ngIf="isListClassificationVisible"
                            [ngClass]="{ grid: _myForm.get('parentInventoryId').value }"
                          >
                            <taku-dropdown
                              [disabled]="!isListClassificationEnabled || _myForm.get('parentInventoryId').value"
                              [formGroup]="_myForm"
                              [options]="lookup_defaultReportCategories"
                              fieldName="defaultReportCategoryId"
                              fieldCaption="Default Report Category"
                              [useFilter]="true"
                              [ngClass]="{ col: _myForm.get('parentInventoryId').value }"
                            >
                            </taku-dropdown>
                            <i *ngIf="_myForm.get('parentInventoryId').value" class="material-icons bookmarks"
                              >bookmarks</i
                            >
                          </div>
                        </div>
                      </p-fieldset>
                      <div class="col-12" [ngClass]="{ grid: _myForm.get('parentInventoryId').value }">
                        <taku-checkbox
                          [formGroup]="_myForm"
                          fieldName="includedInGallery"
                          fieldCaption="Show in Gallery"
                          [disabled]="_myForm.get('parentInventoryId').value"
                          [ngClass]="{ col: _myForm.get('parentInventoryId').value }"
                        ></taku-checkbox>
                        <i *ngIf="_myForm.get('parentInventoryId').value" class="material-icons bookmarks">bookmarks</i>
                      </div>
                    </div>
                  </p-card>
                </div>
              </div>
              <div class="p-2" *ngIf="isNotesVisible">
                <p-card class="col-12 card card-header">
                  <ng-template pTemplate="header" class="grid">
                    <h4 class="col-12">Notes</h4>
                  </ng-template>
                  <div class="col-12">
                    <taku-input-textarea
                      [disabled]="!isNotesEnabled"
                      [formGroup]="_myForm"
                      fieldName="note"
                      fieldCaption="Inventory Notes"
                      [rows]="6"
                    >
                    </taku-input-textarea>
                  </div>
                </p-card>
              </div>
            </p-tabPanel>

            <p-tabPanel
              header="Additional"
              class="detailsTabPanel"
              [disabled]="!isDetailsTabEnabled"
              *ngIf="isDetailsTabVisible"
            >
              <!-- [selected]="_detailTabIsSelected" -->
              <ng-template pTemplate="content">
                <div class="grid">
                  <div class="col-12 xl:col-6 ng-star-inserted">
                    <p-card class="col-12 card" styleClass="panel-nopad">
                      <ng-template pTemplate="header">
                        <h4 class="col-12">Alt Product Details</h4>
                      </ng-template>
                      <div class="col-12" [style.marginBottom]="'15px'">
                        <taku-input [formGroup]="_myForm" fieldName="description2" fieldCaption="Alt Product Title">
                        </taku-input>
                      </div>
                      <taku-editor
                        [formGroup]="_myForm"
                        fieldName="longDescription"
                        fieldCaption="Long Description"
                        _height="150px"
                        [maxCharLength]="9600"
                      ></taku-editor>
                    </p-card>
                    <div class="pt-2">
                      <p-card class="col-12 card" styleClass="panel-nopad">
                        <ng-template pTemplate="header">
                          <h4 class="col-12">Policy</h4>
                        </ng-template>
                        <taku-editor
                          [formGroup]="_myForm"
                          fieldName="policy"
                          fieldCaption="Policy"
                          _height="200px"
                          [maxCharLength]="2400"
                        ></taku-editor>
                      </p-card>
                    </div>
                  </div>
                  <div class="col-12 xl:col-6">
                    <p-card class="col-12 card card-header" styleClass="panel-nopad">
                      <ng-template pTemplate="header">
                        <h4 class="col-12">Tags</h4>
                      </ng-template>
                      <div class="col-12">
                        <taku-chips [formGroup]="_myForm" fieldName="tags"></taku-chips>
                      </div>
                    </p-card>
                  </div>
                </div>
              </ng-template>
            </p-tabPanel>

            <p-tabPanel header="History" [disabled]="!isHistoryTabEnabled" *ngIf="isHistoryTabVisible">
              <!-- NOTE: wrapping everything in this ng-template pTemplate="content" below makes it lazy load -->
              <ng-template pTemplate="content">
                <div class="grid historyPanel-wrapper">
                  <div class="col-12 md:col-6 xl:col-3 statistics-card totalSales-card">
                    <p-card class="card">
                      <ng-template pTemplate="header">
                        <h4 class="col-12">Total Sales</h4>
                      </ng-template>
                      <div class="main-content">
                        {{
                          activeSaleStats?.TotalSales || 0
                            | currency : _object.standardPriceCurrencyIsoCode : "symbol-narrow" : _digitsFormat
                            | formatNegativeNumber
                        }}
                      </div>
                      <ng-template pTemplate="footer">
                        <div class="card-footer-container">
                          <span>Total Returns</span>
                          <span>{{
                            activeReturnStats?.TotalSales || 0
                              | currency : _object.standardPriceCurrencyIsoCode : "symbol-narrow" : _digitsFormat
                              | formatNegativeNumber
                          }}</span>
                        </div>
                        <!-- <span [ngPlural]="activeSaleStats?.InvoiceCount || 0">
                          <ng-template ngPluralCase="=1">Invoice</ng-template>
                          <ng-template ngPluralCase="other">Invoices</ng-template>
                        </span> -->
                      </ng-template>
                    </p-card>
                  </div>

                  <div class="col-12 md:col-6 xl:col-3 statistics-card totalQtySold-card">
                    <p-card class="card">
                      <ng-template pTemplate="header">
                        <h4 class="col-12"
                          >Total Qty Sold
                          <a
                            href="javascript://"
                            class="pi pi-info-circle"
                            style="font-size: 0.89em; color: white"
                            (click)="totalQtySoldInfoPanel.toggle($event)"
                          >
                            <p-overlayPanel #totalQtySoldInfoPanel [showCloseIcon]="false" appendTo="body">
                              <div class="tooltip-styling"> Sales only </div>
                            </p-overlayPanel>
                          </a>
                        </h4>
                      </ng-template>
                      <div class="main-content">
                        {{ activeSaleStats?.TotalQtySales || 0 | number : "1.0-2" }}
                      </div>
                      <ng-template pTemplate="footer">
                        <div class="card-footer-container">
                          <span>Avg Sale Qty</span>
                          <span>{{ activeSaleStats?.avgQtySale || 0 | number : "1.0-2" }} </span>
                        </div>
                      </ng-template>
                    </p-card>
                  </div>

                  <div class="col-12 md:col-6 xl:col-3 statistics-card lastSellPrice-card">
                    <p-card class="card">
                      <ng-template pTemplate="header">
                        <h4 class="col-12">Last Sell Price</h4>
                      </ng-template>
                      <div class="main-content">
                        {{
                          activeSaleStats?.LastSalePrice || 0
                            | currency : _object.standardPriceCurrencyIsoCode : "symbol-narrow" : _digitsFormat
                        }}
                      </div>
                      <ng-template pTemplate="footer">
                        <div class="card-footer-container">
                          <span>Avg Unit Price</span>
                          <span>{{
                            (activeSaleStats ? activeSaleStats.TotalSales / activeSaleStats.TotalQtySales : 0)
                              | currency : _object.standardPriceCurrencyIsoCode : "symbol-narrow" : _digitsFormat
                          }}</span>
                        </div>
                      </ng-template>
                    </p-card>
                  </div>

                  <div class="col-12 md:col-6 xl:col-3 statistics-card lastTransaction-card">
                    <p-card class="card">
                      <ng-template pTemplate="header">
                        <h4 class="col-12">Last Sale</h4>
                      </ng-template>
                      <div class="main-content">
                        {{ lastTxrRelativeTime ? lastTxrRelativeTime.amount : "N/A" }}
                      </div>
                      <ng-template pTemplate="footer">
                        <div class="card-footer-container">
                          <!-- <span>hours</span> -->
                          <span>{{ lastTxrRelativeTime ? lastTxrRelativeTime.timeUnit + " ago" : "N/A" }}</span>
                        </div>
                      </ng-template>
                    </p-card>
                  </div>

                  <div *ngIf="_historyFormListCols && _historyFormListFilter" class="col-12">
                    <!-- <h3>SALES HISTORY</h3> -->
                    <div *ngIf="_isAdmin"> Note: Only business Default Currency transactions are included </div>
                    <div *ngIf="!_isAdmin"> Note: Only zone Default Currency transactions are included </div>
                    <taku-inventory-history
                      [loadInventoryHistoryReportParams]="loadInventoryHistoryReportParams"
                    ></taku-inventory-history>
                  </div>
                </div>
              </ng-template>
            </p-tabPanel>

            <p-tabPanel [header]="'Supplier'">
              <ng-template pTemplate="content">
                <div class="grid">
                  <div
                    *ngIf="isBarcodesVisible"
                    class="col-12 sm:col-12 md:col-12 xl:col-5 taku-listable-container barcodes-wrapper"
                    formArrayName="inventoryBarcodes"
                  >
                    <p-card class="col-12 card card-header barcodes-card">
                      <ng-template pTemplate="header" class="grid">
                        <h4 class="col-12">SKU Details</h4>

                        <div class="col-12 add-button-container">
                          <a
                            class="add-button"
                            (click)="addInventoryBarcode()"
                            [ngClass]="{ disableAnchor: !isBarcodesEnabled }"
                          >
                            <label>Add New</label>
                            <i class="material-icons">add_circle_outline</i>
                          </a>
                        </div>
                      </ng-template>

                      <div
                        class="barcodeFieldsWrapper col-11"
                        *ngIf="$any(_myForm.get('inventoryBarcodes')).length === 0"
                      >
                      </div>
                      <div
                        class="col-12 taku-listable-controlset"
                        *ngFor="let barcodeForm of _myForm.get('inventoryBarcodes')['controls']; let i = index"
                      >
                        <div class="barcodeFieldsWrapper col-11">
                          <taku-inventory-barcode
                            [disableForm]="!isBarcodesEnabled"
                            [formGroup]="barcodeForm"
                            (copyToClipboard)="copyValueToClipboard($event)"
                          >
                          </taku-inventory-barcode>
                        </div>
                        <div class="col-1 remove-button-container">
                          <a (click)="removeInventoryBarcode(i)" [ngClass]="{ disableAnchor: !isBarcodesEnabled }">
                            <i class="material-icons remove-button">remove_circle_outline</i>
                          </a>
                        </div>
                      </div>
                    </p-card>
                  </div>

                  <div class="col-12 sm:col-12 md:col-12 xl:col-3">
                    <p-card class="card" styleClass="panel-nopad">
                      <ng-template pTemplate="header">
                        <h4 class="col-12">SKU Dimensions</h4>
                      </ng-template>
                      <div class="grid">
                        <div
                          class="col-6 drop-container"
                          [ngStyle]="{ 'padding-right': _myForm.get('parentInventoryId').value ? '25px' : null }"
                        >
                          <div [ngClass]="{ grid: _myForm.get('parentInventoryId').value }">
                            <taku-dropdown
                              [options]="lookup_uOfMeasure"
                              fieldName="lengthUOfMeasureId"
                              fieldCaption="U of M"
                              [formGroup]="_myForm"
                              [disabled]="_myForm.get('parentInventoryId').value"
                              [ngClass]="{ col: _myForm.get('parentInventoryId').value }"
                            ></taku-dropdown>
                            <i *ngIf="_myForm.get('parentInventoryId').value" class="material-icons bookmarks"
                              >bookmarks</i
                            >
                          </div>
                        </div>
                        <div class="col-6">
                          <taku-input [formGroup]="_myForm" fieldName="length" fieldCaption="Length"></taku-input>
                        </div>
                        <div class="col-6">
                          <taku-input [formGroup]="_myForm" fieldName="width" fieldCaption="Width"></taku-input>
                        </div>
                        <div class="col-6">
                          <taku-input [formGroup]="_myForm" fieldName="height" fieldCaption="Height"></taku-input>
                        </div>
                      </div>
                    </p-card>
                  </div>
                  <div class="col-12 sm:col-12 md:col-12 xl:col-2">
                    <div>
                      <p-card class="card" styleClass="panel-nopad">
                        <ng-template pTemplate="header">
                          <h4 class="col-12">SKU Volume</h4>
                        </ng-template>
                        <div class="grid">
                          <div class="col-12">
                            <taku-input [formGroup]="_myForm" fieldName="CBM" fieldCaption="CBM"></taku-input>
                          </div>
                          <div class="col-12">
                            <taku-input [formGroup]="_myForm" fieldName="CUFT" fieldCaption="CUFT"></taku-input>
                          </div>
                        </div>
                      </p-card>
                    </div>
                  </div>
                  <div class="col-12 sm:col-12 md:col-12 xl:col-2">
                    <div>
                      <p-card class="card" styleClass="panel-nopad">
                        <ng-template pTemplate="header">
                          <h4 class="col-12">SKU Weight</h4>
                        </ng-template>
                        <div class="grid">
                          <div
                            class="col-12 drop-container"
                            [ngStyle]="{ 'padding-right': _myForm.get('parentInventoryId').value ? '25px' : null }"
                          >
                            <div [ngClass]="{ grid: _myForm.get('parentInventoryId').value }">
                              <taku-dropdown
                                [options]="lookup_uOfMeasure"
                                fieldName="massUOfMeasureId"
                                fieldCaption="Unit of Measure"
                                [formGroup]="_myForm"
                                [disabled]="_myForm.get('parentInventoryId').value"
                                [ngClass]="{ col: _myForm.get('parentInventoryId').value }"
                              ></taku-dropdown>
                              <i *ngIf="_myForm.get('parentInventoryId').value" class="material-icons bookmarks"
                                >bookmarks</i
                              >
                            </div>
                          </div>
                          <div class="col-12">
                            <taku-input [formGroup]="_myForm" fieldName="weight" fieldCaption="Weight"></taku-input>
                          </div>
                        </div>
                      </p-card>
                    </div>
                  </div>
                </div>

                <div class="grid">
                  <div class="col-12">
                    <p-card class="card card-header">
                      <ng-template pTemplate="header">
                        <h4 class="col-12">Supplier Details</h4>
                        <!-- TODO: Add this back in, and style, post launch.
                        -->
                        <!-- <div class="col-12 add-button-container">
                          <a class="add-button" (click)="addInventorySupplier()">
                            <label>Add New</label>
                            <i class="material-icons">add_circle_outline</i>
                          </a>
                        </div> -->
                      </ng-template>

                      <div
                        *ngFor="let inventorySupplier of _myForm.get('inventorySuppliers')['controls']; let i = index"
                      >
                        <!-- TODO: Add this back in, and style, post launch -->
                        <!-- <div class="remove-button-container remove-inventorySupplier-arrayItem-button">
                          <a (click)="removeInventorySupplier(i)">
                            <label>Remove</label>
                            <i class="material-icons remove-button">remove_circle_outline</i>
                          </a>
                        </div> -->

                        <taku-inventory-supplier
                          [inventorySupplierFormGroup]="inventorySupplier"
                        ></taku-inventory-supplier>
                      </div>
                    </p-card>
                  </div>
                </div>
              </ng-template>
            </p-tabPanel>

            <p-tabPanel [header]="'Integrations'">
              <ng-template pTemplate="content">
                <taku-inventory-integration-list
                  [formGroup]="_myForm"
                  (isDisplayGoogleShoppingEventEmitter)="getDisplayGoogleShoppingFromChild($event)"
                >
                </taku-inventory-integration-list>
              </ng-template>
            </p-tabPanel>
          </p-tabView>
        </p-panel>
      </div>
    </div>
  </div>
</div>
<!-- <div class="col-12">
  {{_myForm.value|json}}
</div> -->

<p-dialog
  header="Stock Type"
  [(visible)]="displayStockTypeDialog"
  [style]="{ width: '480px' }"
  [resizable]="false"
  [modal]="!_isDialog"
>
  <div [formGroup]="_myForm" class="col-12 grid">
    <div class="col-10 col-offset-1">
      <ng-template ngFor [ngForOf]="enum_stock_type" let-type>
        <div
          class="col-12 stock-type-option-box"
          *ngIf="type.value"
          [style.grid-auto-rows]="type.value !== 'Non-physical' && '1fr'"
        >
          <p-radioButton
            name="stockType"
            formControlName="stockType"
            [value]="type.value"
            [label]="type.label"
            labelStyleClass="font-bold"
            [disabled]="_myForm.get('parentInventoryId').value"
          >
          </p-radioButton>
          <i *ngIf="_myForm.get('parentInventoryId').value" class="material-icons bookmarks">bookmarks</i>
          <!-- Shows Options for Physical Inventory -->
          <ng-container *ngIf="type.value === 'Physical' && stockTypeCtrl.value == type.value">
            <label for="isTrackQTY">Track Quantity?</label>
            <taku-toggle
              [formGroup]="_myForm"
              fieldName="isTrackQTY"
              class="trackQtyToggle"
              [disabled]="_myForm.get('parentInventoryId').value"
            ></taku-toggle>

            <taku-checkbox
              [formGroup]="_myForm"
              fieldCaption="Batch"
              fieldName="isBatch"
              [disabled]="_myForm.get('parentInventoryId').value"
            ></taku-checkbox>
            <taku-checkbox
              [formGroup]="_myForm"
              fieldCaption="Serial"
              fieldName="isSerial"
              [disabled]="_myForm.get('parentInventoryId').value"
            ></taku-checkbox>
            <taku-checkbox
              [formGroup]="_myForm"
              fieldCaption="Kits"
              fieldName="isKits"
              [disabled]="_myForm.get('parentInventoryId').value"
            ></taku-checkbox>

            <ng-container *ngIf="_myForm.get('parentInventoryId').value">
              <i class="material-icons bookmarks">bookmarks</i>
              <i class="material-icons bookmarks">bookmarks</i>
              <i class="material-icons bookmarks">bookmarks</i>
              <i class="material-icons bookmarks">bookmarks</i>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="type.value === 'Non-physical' && stockTypeCtrl.value == type.value">
            <taku-dropdown
              [formGroup]="_myForm"
              [options]="enum_nonphysical_stock_type"
              [style.height]="!_myForm.get('parentInventoryId').value && '175px'"
              fieldName="nonePhysicalStockType"
              fieldCaption="Stock Type"
              [disabled]="_myForm.get('parentInventoryId').value"
            ></taku-dropdown>
            <i *ngIf="_myForm.get('parentInventoryId').value" class="material-icons bookmarks">bookmarks</i>
          </ng-container>
        </div>
      </ng-template>
    </div>

    <div class="col-12 set-btns-dialog">
      <!-- <p-button label="CANCEL" class="p-button-raised p-button-secondary" (click)="cancelStockTypeDialog($event)"></p-button> -->
      <p-button label="ACCEPT" class="p-button-raised" (click)="displayStockTypeDialog = false"></p-button>
    </div>
  </div>
</p-dialog>

<!-- <div class="col-12">
  {{_myForm.get('inventoryStocks').value|json}}
</div> -->

<p-dialog
  *ngIf="false"
  header="Google Shopping"
  [(visible)]="displayGoogleShoppingDialog"
  [style]="{ width: '1200px' }"
  [resizable]="false"
  [modal]="!_isDialog"
>
  <div class="grid">
    <div class="col-12 lg:col-6">
      <p-card *ngIf="gmcStatusForm" class="grid col-12 card card-header">
        <ng-template pTemplate="header" class="grid">
          <h4 class="col-12">GMC Status</h4>
        </ng-template>

        <div class="col-12 grid gmcStatus-wrapper">
          <div class="col-8">
            <div class="col-4 display-field">
              <label>Status</label>
              <div class="value" [innerHTML]="gmcStatusForm?.get('googleStatusFormatted').value"></div>
            </div>

            <div class="grid col-8 gmcIssuesSummary-wrapper">
              <div class="display-field">
                <label>Issues Summary</label>
                <div class="value" [innerHTML]="gmcStatusForm?.get('issuesSummary').value"></div>
              </div>
              <a *ngIf="gmcStatusForm?.get('hasGMCIssues').value" href="javascript://" (click)="openGMCDetails()"
                >See Details</a
              >
            </div>
          </div>

          <div class="col-4 pushGMC-wrapper">
            <a
              href="javascript://"
              class="pi pi-refresh gmc-refresh-btn"
              (click)="populateGMCStatus()"
              [ngClass]="{ 'disabled-link': _isFetchingStatus }"
            ></a>
            <button
              pButton
              [disabled]="!isGMBPushEnabled"
              label="Push to GMC&nbsp;&nbsp;"
              icon="pi pi-cloud-upload"
              iconPos="right"
              (click)="pushToGMC()"
            ></button>
          </div>
          <div class="col-12 note-wrapper"> Note: This process will take several minutes. </div>
        </div>
      </p-card>

      <p-card class="grid col-12 card card-header">
        <ng-template pTemplate="header" class="grid">
          <h4 class="col-12">Product Category</h4>
        </ng-template>

        <div class="gmcCard-fields clearfix">
          <div class="col-12">
            <taku-dropdown
              [formGroup]="googleShoppingForm"
              [options]="enum_googleshoppingTitleTemplate"
              fieldName="titleTemplate"
              fieldCaption="Product Title Template"
            ></taku-dropdown>
          </div>

          <div class="col-12">
            <!-- <taku-input [formGroup]="googleShoppingForm" fieldName="productCategory"
                  fieldCaption="Google Product Category"></taku-input> -->
            <taku-input-autocomplete
              [formGroup]="googleShoppingForm"
              [_autocompleteOptions]="googleProductCategoryOptions"
              fieldName="productCategory"
              fieldCaption="Google Product Category"
            ></taku-input-autocomplete>
          </div>

          <div class="col-12">
            <taku-input [formGroup]="googleShoppingForm" fieldName="productType" fieldCaption="Product Type">
            </taku-input>
          </div>
        </div>
      </p-card>

      <p-card class="grid col-12 card card-header">
        <ng-template pTemplate="header" class="grid">
          <h4 class="col-12">Basic Product Data</h4>
        </ng-template>

        <div class="grid gmcBasicData-fields gmcCard-fields clearfix">
          <div class="col-6">
            <taku-input [formGroup]="googleShoppingForm" fieldName="productLink" fieldCaption="Product Link">
            </taku-input>
          </div>

          <div class="col-6">
            <taku-input [formGroup]="googleShoppingForm" fieldName="imageLink" fieldCaption="Image Link"> </taku-input>
          </div>
        </div>
      </p-card>

      <p-card class="grid col-12 card card-header">
        <ng-template pTemplate="header" class="grid">
          <h4 class="col-12">Product Identifiers</h4>
        </ng-template>

        <div class="grid gmcCard-fields clearfix">
          <div class="col-12">
            <!-- <taku-formfield-decorator fieldCaption="Brand"> -->
            <p-autoComplete
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="selectedGMCBrand"
              [suggestions]="googleShoppingBrandsSuggestions"
              [dropdown]="true"
              field="label"
              appendTo="body"
              [ngClass]="{ 'inputctrl-filled': selectedGMCBrand }"
              (completeMethod)="searchBrands($event)"
            >
              <!-- <ng-template let-inventoryBrand pTemplate="item">
                      <div class="item-barcode">
                        <div class="barcode-number">{{inventoryBarcode.barcode}}</div>
                      </div>
                    </ng-template> -->
            </p-autoComplete>
            <!-- </taku-formfield-decorator> -->
            <!-- <taku-input [formGroup]="googleShoppingForm" fieldName="brand" fieldCaption="Brand"></taku-input> -->
          </div>

          <div class="col-6">
            <taku-formfield-decorator fieldCaption="GTIN">
              <p-autoComplete
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="selectedGTINBarcode"
                [suggestions]="googleshoppingGTIN_suggestions"
                [dropdown]="true"
                field="barcode"
                appendTo="body"
                [ngClass]="{ 'inputctrl-filled': selectedGTINBarcode }"
                (completeMethod)="searchGTIN($event)"
              >
                <ng-template let-inventoryBarcode pTemplate="item">
                  <div class="item-barcode">
                    <div class="barcode-number">{{ inventoryBarcode.barcode }}</div>
                    <div class="barcode-type">{{ inventoryBarcode.barcodeType }}</div>
                  </div>
                </ng-template>
              </p-autoComplete>
            </taku-formfield-decorator>
          </div>

          <div class="col-6">
            <taku-input [formGroup]="googleShoppingForm" fieldName="MPN" fieldCaption="MPN"> </taku-input>
          </div>
        </div>
      </p-card>
    </div>

    <div class="col-12 lg:col-6">
      <p-card class="grid card card-header">
        <ng-template pTemplate="header" class="grid">
          <h4 class="col-12">Detailed Product Description</h4>
        </ng-template>

        <div class="grid col-12 info-toggle-wrapper">
          <div class="col-12 xl:col-4 drop-container info-toggle">
            <taku-toggle
              [formGroup]="googleShoppingForm"
              fieldName="adult"
              fieldCaption="Adult"
              [captionPosition]="ToggleCaptionPosition.LEFT"
              enabledStateLabel="YES"
              disabledStateLabel="NO"
            >
            </taku-toggle>
            <i class="pi pi-info-circle" pTooltip="Indicate a product includes sexually suggestive content"></i>
          </div>

          <div class="col-6 xl:col-4 drop-container">
            <taku-dropdown
              [formGroup]="googleShoppingForm"
              [options]="enum_googleshoppingAgeGroup"
              fieldName="ageGroup"
              fieldCaption="Age Group"
            ></taku-dropdown>
          </div>

          <div class="col-6 xl:col-4 xl:col-offset-0 drop-container">
            <taku-dropdown
              [formGroup]="googleShoppingForm"
              [options]="enum_googleshoppingGender"
              fieldName="gender"
              fieldCaption="Gender"
            ></taku-dropdown>
          </div>
        </div>

        <div class="grid col-12 info-toggle-wrapper">
          <div class="col-12 xl:col-4 drop-container info-toggle">
            <taku-toggle
              [formGroup]="googleShoppingForm"
              fieldName="isBuldle"
              fieldCaption="Is Bundle"
              [captionPosition]="ToggleCaptionPosition.LEFT"
              enabledStateLabel="YES"
              disabledStateLabel="NO"
            >
            </taku-toggle>
            <i
              class="pi pi-info-circle"
              pTooltip="Indicates a product is a merchant-defined custom group of different products featuring one main product"
            ></i>
          </div>

          <div class="col-6 xl:col-4">
            <taku-input [formGroup]="googleShoppingForm" fieldName="multiPack" fieldCaption="MultiPack"> </taku-input>
          </div>

          <div class="col-6 xl:col-4 drop-container">
            <taku-dropdown
              [formGroup]="googleShoppingForm"
              [options]="enum_googleshoppigCondition"
              fieldName="condition"
              fieldCaption="Condition"
            ></taku-dropdown>
          </div>
        </div>

        <div class="grid col-12">
          <div class="col-6 xl:col-4">
            <taku-input [formGroup]="googleShoppingForm" fieldName="pattern" fieldCaption="Pattern"> </taku-input>
          </div>

          <div class="col-6 xl:col-4">
            <taku-input [formGroup]="googleShoppingForm" fieldName="color" fieldCaption="Color"></taku-input>
          </div>

          <div class="col-6 xl:col-4">
            <taku-input [formGroup]="googleShoppingForm" fieldName="material" fieldCaption="Material"> </taku-input>
          </div>
        </div>

        <div class="grid col-12">
          <div class="col-6 xl:col-4 drop-container">
            <taku-dropdown
              [formGroup]="googleShoppingForm"
              [options]="enum_googleshoppingSizeSystem"
              fieldName="sizeSystem"
              fieldCaption="Size System"
            ></taku-dropdown>
          </div>

          <div class="col-6 xl:col-4 drop-container">
            <taku-dropdown
              [formGroup]="googleShoppingForm"
              [options]="enum_googleshoppingSizeType"
              fieldName="sizeType"
              fieldCaption="Size Type"
            ></taku-dropdown>
          </div>
        </div>

        <div class="col-12">
          <taku-chips [formGroup]="googleShoppingForm" fieldName="sizes" fieldCaption="Size" [maxEntries]="1">
          </taku-chips>
        </div>
      </p-card>
    </div>
  </div>
</p-dialog>
