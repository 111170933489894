<div [formGroup]="_myForm" class="col-12">
  <taku-form-header
    [toolbarTitle]="cashoutPageTitle"
    iconStyleClass="material-icons"
    iconName="attach_money"
    displayMode="inline"
    [showActionBtns]="false"
    [showBackBtn]="true"
    (backClicked)="goBack()"
  ></taku-form-header>

  <div class="main-container col-12" [ngSwitch]="activeCashoutWorkFlow">
    <taku-cashout-openflow
      *ngSwitchCase="CashoutActiveWorkflow.OPEN_CASHOUT"
      [formGroup]="_myForm"
      [genericFormService]="genericFormService"
      (onCashoutOpened)="openCashout()"
    ></taku-cashout-openflow>
    <taku-cashout-closetill
      *ngSwitchCase="CashoutActiveWorkflow.CLOSE_CASHOUT"
      [cashoutFormGroup]="_myForm"
      [genericFormService]="genericFormService"
      (onCashoutClosed)="closeCashout()"
    ></taku-cashout-closetill>
  </div>

  <!--
  <div class="col-12">
    {{_myForm.value|json}}
  </div>
  -->
</div>
