/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { FormListComponent } from "src/app/form-list/form-list/form-list.component";
import { RowSelectionType } from "src/app/form-list/form-list/form-list";
import {
  SaleBankReconciliationReportCols,
  SaleBankReconciliationReportColsFactoryService,
} from "./sale-bank-reconciliation-report-col.service";
import { MessageService } from "primeng/api";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";
import { ReportParamsService } from "../../report-params.service";
import { ReportFilterFields } from "../../report-filter-fields";
import { SaleBankReconciliationReport } from "./sale-bank-reconciliation-report";
import { SaleBankReconciliationReportLocalDataService } from "./sale-bank-reconciliation-report-local-data.service";
import { resetReportRowState } from "../../report";
import { LocalDataService } from "src/app/shared/services/local-data.service";

@Component({
  selector: "taku-sale-bank-reconciliation-report",
  templateUrl: "./sale-bank-reconciliation-report.component.html",
  styleUrls: ["./sale-bank-reconciliation-report.component.scss"],
  providers: [SaleBankReconciliationReportLocalDataService],
})
export class SaleBankReconciliationReportComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];
  @ViewChild("formListReport") formListComponent: FormListComponent;

  _model = "saleBankReconciliationReport";
  _stateKey = "FL-" + this._model;
  RowSelectionType = RowSelectionType;
  public reportCols: SaleBankReconciliationReportCols;
  dynamicFields: string[] = [];

  constructor(
    private reportColsFactoryService: SaleBankReconciliationReportColsFactoryService,
    public saleReportLocalService: SaleBankReconciliationReportLocalDataService,
    private messageService: MessageService,
    private alertMessagesService: AlertMessagesService,
    public reportParamsService: ReportParamsService,
    private localDataService: LocalDataService
  ) {
    this.reportCols = this.reportColsFactoryService.build([]);
  }

  ngOnInit() {
    this.subsList.push(
      this.reportParamsService.reportParamsChanged$.subscribe((filters: ReportFilterFields) => {
        this.updateReport(filters);

        resetReportRowState(this._stateKey);
      })
    );
  }

  private updateReport(filters: ReportFilterFields) {
    this.reportParamsService._isFetchingData = true;
    this.subsList.push(
      this.saleReportLocalService
        .getSaleBankReconciliationReport(filters.storeId, filters.stationId, filters.dateFrom, filters.dateTo, 0, 0)
        .subscribe({
          next: (rows) => {
            this.buildFormlistCols(rows && rows.length ? rows[0] : null);
            this.refreshFormList(rows || []);
            this.reportParamsService._isFetchingData = false;
            this.localDataService.updateNewObject(rows);
          },
          error: (error) => {
            this.buildFormlistCols(null);
            // Show toast with error message
            this.messageService.add(this.alertMessagesService.getErrorMessage(error));
            // Empty form list
            this.refreshFormList([]);
            this.reportParamsService._isFetchingData = false;
          },
        })
    );
  }

  private buildFormlistCols(rowData: SaleBankReconciliationReport) {
    this.dynamicFields = rowData
      ? Object.keys(rowData).filter((key) => !Object.keys(new SaleBankReconciliationReport()).includes(key))
      : [];
    this.reportCols = this.reportColsFactoryService.build(this.dynamicFields);
  }

  private refreshFormList(data: any[]) {
    data.forEach((saleBankReconciliation) => {
      const saleBankReconciliationReport = Object.assign(new SaleBankReconciliationReport(), saleBankReconciliation);
      // This makes store getter result into plain property
      saleBankReconciliation.totalAmountCalculated = saleBankReconciliationReport.totalAmount;
      saleBankReconciliation.marginPercentCalculated = saleBankReconciliationReport.marginPercent;
      saleBankReconciliation.marginAmountCalculated = saleBankReconciliationReport.marginAmount;
      saleBankReconciliation.netDepositCalculated = saleBankReconciliationReport.netDeposit;

      this.dynamicFields.forEach((key) => {
        saleBankReconciliation[key] = parseFloat(saleBankReconciliation[key]) || 0;
      });
    });
    this.saleReportLocalService.initialize(data, this._model);
    setTimeout(() => {
      this.formListComponent.reloadData();
    }, 0);
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
