import _ from "lodash";
import { Account } from "../../../contact-accounts/account/account";
import { CommercialAccount } from "../../../contact-accounts/commercial-account/commercial-account";
import { PersonalAccount } from "../../../contact-accounts/personal-account/personal-account";
import { Stock } from "../../../settings/business-settings/stock/stock";
import { Doc, SaleDocType } from "../../../document/doc/doc";
import {
  InventoryDoc,
  InventoryDocLine,
} from "../../../document/inventory-document/inventory-document/inventory-document";
import { DiscountType, SaleDocLine } from "../../../document/sale-document/sale-doc-line/sale-doc-line";
import { CurrencyPipe } from "@angular/common";
import { AppConstants } from "src/app/shared/app-constants";
import { MonetaryHelpers } from "src/app/utility/MonetaryHelpers";
import { SaleDoc } from "src/app/core/document/sale-document/sale-doc/sale-doc";
import { Inventory_Doc_Type } from "src/app/core/settings/zone-settings/inventory-doc-formats/InventoryDocFormat";

export class InventoryHistory {
  id: number;
  doc: Doc;
  inventoryHistoryDocLines: (SaleDocLine | InventoryDocLine)[];
  accountName: string;
  account: Account;
  personalAccount: PersonalAccount;
  commercialAccount: CommercialAccount;
  stock: Stock;
  qtyPos: number | null;
  qtyNeg: number | null;
  note: string;
  price: string | number; // set by the firstLineUnitPriceCurrencyFormat method below, in the InventoryHistoryReportComponent.refreshFormList(data: any[]) method
  totalCost: string | number; // set in the InventoryHistoryReportComponent.refreshFormList(data: any[]) method
  inventoryId: number;
  saleDocId?: number;
  saleDoc?: SaleDoc;
  inventoryDocId?: number;
  inventoryDoc?: InventoryDoc;

  constructor() {}

  get firstLineUnitPriceCurrencyFormat(): string | number {
    if (!this.inventoryHistoryDocLines.length) return 0;
    let linePrice = 0;
    this.inventoryHistoryDocLines.map((_saleDocLine) => {
      _saleDocLine = SaleDocLine.convertIntoTypedObject(_saleDocLine);
      linePrice += _saleDocLine.salePrice * _saleDocLine.qty;
    });

    if (
      this.doc?.docType === SaleDocType.sales_invoice ||
      this.doc?.docType === SaleDocType.sales_return ||
      this.doc?.docType === SaleDocType.recurring_order ||
      this.doc?.docType === SaleDocType.online_sales_order ||
      this.doc?.docType === SaleDocType.pre_order
    ) {
      if (this.doc.currencyIsoCode)
        return new CurrencyPipe(AppConstants.DEFAULT_LOCALE_ID).transform(linePrice, this.doc.currencyIsoCode, "code");
      else return linePrice.toString();
    } else {
      return "";
    }
  }

  inventoryDocLinesTotalCost(currencyIsoCode: string): string | number {
    if (this.inventoryDocId) {
      const inventoryDoc = InventoryDoc.convertIntoTypedObject(this.inventoryDoc);

      const totalCost = _.sumBy(inventoryDoc.inventoryDocLines, (inventoryDocLine) => {
        return Number(inventoryDocLine.totalCost.toString());
      });

      if (currencyIsoCode) {
        return new CurrencyPipe(AppConstants.DEFAULT_LOCALE_ID).transform(+totalCost, currencyIsoCode, "code");
      } else {
        return totalCost.toString();
      }
    } else {
      return 0;
    }
  }
}
