/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Inject, EventEmitter, Injectable, LOCALE_ID, NgZone } from "@angular/core";
import { InventoryService } from "../../inventory/inventory/inventory.service";
import {
  Col,
  DataType,
  FilterType,
  ExecuteEventDatatypeOptions,
  ExecuteEvent_DisplayMode,
} from "src/app/form-list/form-list/form-list";
import { FormListModel } from "src/app/form-list/form-list/form-list-model.interface";
import { Inventory } from "../../inventory/inventory/inventory";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { InventoryStockService } from "../../inventory/inventory-stock/InventoryStock.service";
import { InventoryBarcodeService } from "../../inventory/inventory-barcode/InventoryBarcode.service";
import { BackendGMCService } from "../../settings/integration-settings/google-shopping/BackendGMCService";
import { AuthService } from "src/app/shared/services/auth.service";
import { GoogleContentAPIService } from "../../settings/integration-settings/google-shopping/GoogleContentAPIService";
import { HttpClient } from "@angular/common/http";
import { CurrencyFormatterService } from "src/app/shared/services/currency-formatter.service";
import { SaleChannel } from "../sale-channel";
import {
  InventorySaleChannel,
  OnlineStatus,
  UploadStatus,
} from "../../inventory/inventory-sale-channel/inventory-sale-channel";
import { APP_BRAND } from "src/app/utility/white-label";

@Injectable({
  providedIn: "root",
})
export class SaleChannelInventoryService extends InventoryService implements FormListModel {
  _saleChannel: SaleChannel;

  navigateSaleChannelProduct: EventEmitter<Inventory> = new EventEmitter();
  branding = APP_BRAND;

  constructor(
    protected currencyService: CurrencyFormatterService,
    protected http: HttpClient,
    protected googleContentAPIService: GoogleContentAPIService,
    @Inject(LOCALE_ID) protected defaultLocale,
    protected authService: AuthService,
    protected appSettings: AppSettingsStorageService,
    protected zone: NgZone,
    public backendGMCService: BackendGMCService,
    public barcodeService: InventoryBarcodeService,
    public stockService: InventoryStockService,
    protected appSettingsStorageService: AppSettingsStorageService
  ) {
    super(
      currencyService,
      http,
      googleContentAPIService,
      defaultLocale,
      authService,
      appSettings,
      zone,
      backendGMCService,
      barcodeService,
      stockService,
      appSettingsStorageService
    );

    this.navigateSaleChannelProduct.subscribe((inventory) => {
      this._getRequest(
        `${this.webUrl}TAKUeCommerceProductURL/${
          inventory.inventorySaleChannels.find((row) => row.saleChannelId === this._saleChannel.id).saleChannelId
        }/${inventory.id}`
      ).subscribe((_url: string) => {
        window.open(_url, "_blank");
      });
    });
  }

  enum_upload_status = this.enumSelectOptions(UploadStatus, { useNullAsValue: false });
  enum_online_status = this.enumSelectOptions(OnlineStatus);

  onUploadStatusChanged(rowData, _orgObjects) {
    if (rowData.inventorySaleChannels[0].uploadStatus === UploadStatus.No) {
      rowData.inventorySaleChannels[0].onlineStatus = OnlineStatus.Unpublished;
    } else {
      rowData.inventorySaleChannels[0].onlineStatus = OnlineStatus.Published;
    }
  }

  getChannelSKUCaption(rowData: Inventory, _service: SaleChannelInventoryService) {
    const _inventorySaleChannel = rowData
      ? rowData.inventorySaleChannels.find((row) => row.saleChannelId === _service._saleChannel.id)
      : null;

    return _inventorySaleChannel ? _inventorySaleChannel.channelInventoryId : "";
  }

  afterFetch(inventory: Inventory, model: string) {
    super.afterFetch(inventory, model);
    switch (model) {
      case this._model:
        const _inventorySaleChannel = inventory.inventorySaleChannels.find(
          (row) => row.saleChannelId === this._saleChannel.id
        );
        if (_inventorySaleChannel) {
          inventory.inventorySaleChannels.splice(
            inventory.inventorySaleChannels.findIndex((row) => row.saleChannelId === this._saleChannel.id),
            1
          );
          inventory.inventorySaleChannels = [_inventorySaleChannel, ...inventory.inventorySaleChannels];
        } else {
          const newInventorysaleChannel = new InventorySaleChannel();
          newInventorysaleChannel.saleChannelId = this._saleChannel.id;
          newInventorysaleChannel.saleChannel = this._saleChannel;
          newInventorysaleChannel.uploadStatus = UploadStatus.No;
          inventory.inventorySaleChannels = [newInventorysaleChannel, ...inventory.inventorySaleChannels];
        }
    }
  }

  _disableCloning = true;
  getFormListColumns(): Col[] {
    const cols = super.getFormListColumns();

    const skuCol = cols.find((row) => row.field === "sku");
    skuCol.header = "eCommerce SKU";
    skuCol.headerHTML = "eCommerce<br>SKU";

    const isEnabledColIndex = cols.findIndex((row) => row.field === "isEnabled");
    const isEnabledCol = cols.splice(isEnabledColIndex, 1)[0];
    isEnabledCol.visible = false;
    isEnabledCol.frozen = false;

    const parentInventoryId = cols.find((row) => row.filterField === "parentInventoryId");
    parentInventoryId.colWidth = 60;
    parentInventoryId.filterType = FilterType.none;

    cols.splice(
      cols.findIndex((row) => row.field === "sku"),
      0,
      {
        field: "inventorySaleChannels[0].uploadStatus",
        header: "Upload Status",
        headerHTML: "Upload<br>Status",
        visible: true,
        readonly: false,
        frozen: true,
        dataType: DataType.enum,
        dataOptions: this.enum_upload_status,
        filterType: FilterType.enum,
        filterField: "inventorySaleChannels.uploadStatus",
        onModelChange: this.onUploadStatusChanged.bind(this),
        colWidth: 140,
      },
      {
        field: "inventorySaleChannels[0].onlineStatus",
        header: "Online Status",
        headerHTML: "Online<br>Status",
        visible: true,
        readonly: false,
        frozen: true,
        dataType: DataType.enum,
        dataOptions: this.enum_online_status,
        filterType: FilterType.enum,
        filterField: "inventorySaleChannels.onlineStatus",
        colWidth: 140,
      },
      {
        field: "inventorySaleChannels[0].channelInventoryId",
        header: "Channel SKU",
        headerHTML: "Channel<br>SKU",
        visible: true,
        readonly: true,
        frozen: true,
        dataType: DataType.execute_event,
        dataOptions: [],
        dataTypeOptions: [
          new ExecuteEventDatatypeOptions({
            displayMode: ExecuteEvent_DisplayMode.LINK,
            event: this.navigateSaleChannelProduct,
            label: this.getChannelSKUCaption,
            enabledOnEditMode: false,
            styleClass: "",
          }),
        ],
        filterType: FilterType.none,
        colWidth: 120,
        isNotSortable: true,
      }
    );
    cols.push(
      isEnabledCol,
      {
        field: "createdAtDateAndTime",
        header: "Created At",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.date_time,
        dataOptions: [],
        filterType: FilterType.none,
        sortFields: ["createdAt"],
        colWidth: 175,
      },
      {
        field: "updatedAtDateAndTime",
        header: "Updated At",
        visible: false,
        readonly: true,
        frozen: false,
        dataType: DataType.date_time,
        dataOptions: [],
        filterType: FilterType.none,
        sortFields: ["updatedAt"],
      }
    );
    return cols;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
