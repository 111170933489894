/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { formatDate } from "@angular/common";
import { Component, EventEmitter, Inject, Input, LOCALE_ID, inject } from "@angular/core";
import { OnInit, Output, OnChanges, SimpleChanges, ViewChild, ElementRef, OnDestroy } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import * as _ from "lodash";
import { DBService } from "../../../../shared/services/db.service";
import { SearchResultItem } from "../../../../taku-ui/taku-search-accounts/SearchResultItem";
import { SaleDocLine } from "../../sale-document/sale-doc-line/sale-doc-line";
import { SaleDoc } from "../../sale-document/sale-doc/sale-doc";
import { TakuSearchAccountsComponent } from "../../../../taku-ui/taku-search-accounts/taku-search-accounts.component";
import { TakuSearchInventoryComponent } from "../../../../taku-ui/taku-search-inventory/taku-search-inventory.component";
import { StorePoliciesSettings } from "../../../settings/store-settings/store-policies-settings/StorePoliciesSettings";
import { ConfirmationService, MessageService } from "primeng/api";
import { WebHelpers } from "../../../../utility/WebHelpers";
import { AlertMessagesService } from "../../../../shared/services/alert-messages.service";
import { SearchDocForReturnStateService } from "./search-document-return-state.service";
import { DomHandler } from "primeng/dom";
import { Subscription } from "rxjs";
import { Col } from "src/app/form-list/form-list/form-list";
import { SaleDocType } from "../../doc/doc";
import moment from "moment";
import { ReturnInvoiceService } from "../return-invoice.service";

export type SingleQtyReturned = { saleDoc: SaleDoc; docLineId: number; maxQty: number };

@Component({
  selector: "taku-document-search-return",
  templateUrl: "./document-search-return.component.html",
  styleUrls: ["./document-search-return.component.scss"],
})
export class DocumentSearchForReturnComponent implements OnChanges, OnInit, OnDestroy {
  _isLoading = false;
  isReturnAllButtonDisabled = false;

  constructor(
    private fb: UntypedFormBuilder,
    private dbService: DBService,
    private alertService: AlertMessagesService,
    private messageService: MessageService,
    @Inject(LOCALE_ID) private defaultLocale: string,
    private confirmationService: ConfirmationService,
    private searchDocForReturnStateService: SearchDocForReturnStateService
  ) {
    const dateTo = moment(); // Today's Date
    const dateFrom = dateTo.clone().subtract(14, "days");

    this.docsSearchForm = this.fb.group({
      docNo: null,
      inventoryId: 0,
      accountId: 0,
      dateFrom: formatDate(dateFrom.toDate(), "yyyy-MM-dd", this.defaultLocale),
      dateTo: formatDate(dateTo.toDate(), "yyyy-MM-dd", this.defaultLocale),
    });
  }
  private returnInvoiceService = inject(ReturnInvoiceService);

  get accountIdCtrl() {
    return this.docsSearchForm.get("accountId");
  }

  get filterCriteria(): string {
    return this._filterCriteria;
  }

  get _isMobile(): boolean {
    return WebHelpers.isMobileScreen();
  }

  // Update both filters in order to maintain them sync
  set filterCriteria(filter: string) {
    this._filterCriteria = this.tmpInputCriteria = filter;
  }

  get filteredSaleLines(): SaleDocLine[] {
    if (!this.filterCriteria)
      // if filter criteria is empty or null just return all current sale lines
      return this.saleDoc.saleDocLines;

    return this.saleDoc.saleDocLines.filter((saleLine) => saleLine.docLine.isKeywordAMatch(this.filterCriteria));
  }

  get _transactionsScrollHeight() {
    const parentHeight = DomHandler.getInnerHeight(this._docTransactionsEl.nativeElement);
    return `${parentHeight - 75}px`;
  }
  static LIMIT_SHOWED_LINES = 200;
  subsList: Subscription[] = [];
  // singleDocSearchForm: FormGroup;
  docsSearchForm: UntypedFormGroup;
  multipleSearchFilter: {} = null;
  returnDocsColumns: Col[] = [];
  storePolicies: StorePoliciesSettings;
  saleDoc: SaleDoc;
  tmpInputCriteria: string;

  private _filterCriteria: string;
  @Input() inputSaleDoc: SaleDoc;
  @Input() returnDocHasChanges = false;
  @Input() allowNoInvoiceReturns = false;
  @Input() docAccount?: SearchResultItem;
  @Output() singleQtyReturned = new EventEmitter<SingleQtyReturned>();
  @Output() entireDocReturned = new EventEmitter<SaleDoc>();
  @Output() multipleResultsReturned = new EventEmitter<any>();
  @Output() noReceiptItem = new EventEmitter<any>();

  @ViewChild("searchAccountsComp", { static: true }) _searchAccountsComp: TakuSearchAccountsComponent;
  @ViewChild("searchInventoryComp", { static: true }) _searchInventoryComp: TakuSearchInventoryComponent;
  @ViewChild("docTransactions", { static: true }) _docTransactionsEl: ElementRef;

  tendersPrettyPrint = "";
  _origSaleDocLines: SaleDocLine[];
  decimalFormat = "1.2-2";

  ngOnInit(): void {
    if (this.searchDocForReturnStateService.hasState()) this.searchDocForReturnStateService.restoreComponentState(this);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inputSaleDoc && this.inputSaleDoc) {
      this.inputSaleDoc = SaleDoc.convertIntoTypedObject(this.inputSaleDoc);
      this._setNewSaleDoc(this.inputSaleDoc);
      this.updateIsReturnAllButtonDisabled();
    }

    if (changes.docAccount && this.docAccount) {
      this.accountIdCtrl.setValue(this.docAccount.ID);
      this._searchAccountsComp.textSearch = this.docAccount.headline;
    }
  }

  onNoReceiptPressed() {
    this.noReceiptItem.emit();
    this.clearSearchFields();
  }

  _fetchStorePolicies() {
    const currStoreFilter = JSON.stringify({
      storeId: { value: this.saleDoc.storeId, matchMode: "equals" },
    });

    this.subsList.push(
      this.dbService.getRows("storePolicy", currStoreFilter).subscribe((data) => {
        if (data.rows.length > 0) this.storePolicies = data.rows[0];
      })
    );
  }

  onKeyPressedDocNo(e: KeyboardEvent) {
    if (e.keyCode === 13) {
      // If 'Enter' was pressed made the search automatically
      this.searchMultipleDocuments();
    }
  }

  onTransactionFilterKeyup(e: KeyboardEvent) {
    if (e.keyCode === 13) {
      // if user hit enter perform search
      this.filterCriteria = this.tmpInputCriteria;

      if (this.filteredSaleLines.length === 1 && this.filterCriteria !== "") {
        this.onReturnSingleItem(this.filteredSaleLines[0]);
        this.resetInvoiceFilter();
      }
    }
  }

  resetInvoiceFilter() {
    this.filterCriteria = null;
  }

  // reverseReturnedLine(docLineId:number){
  //   const reversedLine = this._origSaleDocLines.find(currDocLine => currDocLine.id == docLineId);
  //   this.saleDoc.saleDocLines.unshift(reversedLine);
  // }

  undoAllReturns(saleDocNo: string) {
    // if (this.saleDoc && this.saleDoc.doc.docNo === saleDocNo) { // only make changes if document on left-side is the same that right-side
    if (this._origSaleDocLines) {
      this.saleDoc.saleDocLines = this._cloneSaleDocLines(this._origSaleDocLines);
    }
    // }
  }

  returnQtyChanged(docLineId: number, qty: number) {
    if (!this.saleDoc)
      // If there isn't an associated invoice, do nothing
      return;

    let currentLine = this.saleDoc.saleDocLines.find((currDocLine) => currDocLine.id === docLineId);
    const originalLine = this._origSaleDocLines.find((currDocLine) => currDocLine.id === docLineId);

    if (originalLine) {
      if (!currentLine) {
        // if didn't exist previously take it from original backup lines
        currentLine = this._cloneSingleDocLine(originalLine);
        this.saleDoc.saleDocLines.unshift(currentLine);
      }

      const newQty = originalLine.qty - qty;
      // find current line and remove it is less than 0, otherwhise just decrease qty
      if (newQty <= 0) _.remove(this.saleDoc.saleDocLines, currentLine);
      else currentLine.qty = newQty;
    }
  }

  searchMultipleDocuments() {
    // If left-side has changes, so we should ask first before performing search
    this._isLoading = true;

    const performSearch = () => {
      const searchQuery = this.docsSearchForm.value;
      const queryObject = {
        "doc.docType": {
          value: [
            SaleDocType.sales_invoice,
            SaleDocType.online_sales_order,
            SaleDocType.recurring_order,
            SaleDocType.pre_order,
          ],
          matchMode: "in",
        },
        "doc.state": { value: "Finalized", matchMode: "equals" },
      };

      if (searchQuery.docNo) {
        queryObject["doc.docNo"] = { value: searchQuery.docNo, matchMode: "equals" };
      } else {
        if (searchQuery.accountId) queryObject["accountId"] = { value: searchQuery.accountId, matchMode: "equals" };

        if (searchQuery.inventoryId)
          queryObject["saleDocLines.docLine.inventoryId"] = { value: searchQuery.inventoryId, matchMode: "equals" };

        if (searchQuery.dateFrom && searchQuery.dateTo)
          queryObject["doc.docDate"] = { value: [searchQuery.dateFrom, searchQuery.dateTo], matchMode: "between" };
      }

      this.subsList.push(
        this.dbService
          .getRows("saleDoc", JSON.stringify(queryObject), 0, 1, null, null, {
            includes: "saleDocLine,saleDocTender",
          })
          .subscribe((saleDocs) => {
            const count = saleDocs.count;
            if (count === 0) {
              this.confirmationService.confirm({
                acceptLabel: "OK",
                rejectVisible: false,
                message: "No results found.",
              });

              this.saleDoc = this._origSaleDocLines = null;
            } else if (count === 1) {
              const saleDocId = saleDocs.rows[0].id;
              // Query server again in order to obtain sale lines
              this.subsList.push(
                this.returnInvoiceService.getSaleDocToReturn(saleDocId).subscribe((object) => {
                  this._setNewSaleDoc(SaleDoc.convertIntoTypedObject(object));
                  this._isLoading = false;
                })
              );
            } else {
              // Show Form List
              this.multipleResultsReturned.emit(queryObject);
            }
            this._isLoading = false;
          })
      );
      // TODO: Add filters for date range
      // this.multipleSearchFilter = queryObject;
    };

    if (!this.returnDocHasChanges) performSearch();
    // if there are pending unsaved changes, ask for confirmation first
    else
      this.confirmationService.confirm({
        message:
          "Current Return Document has unsaved changes.<br>" +
          "By switching to another invoice, you will lose your changes.<br><br>Are you sure?",
        header: "Unsaved Changes",
        accept: () => {
          performSearch();
        },
      });
  }

  private _setNewSaleDoc(newSaleDoc: SaleDoc) {
    // First make a backup of previous saleDoc
    const oldSaleDoc: SaleDoc = this.saleDoc;
    this.saleDoc = newSaleDoc;

    if (!this.saleDoc || this.saleDoc.id === 0) {
      this._origSaleDocLines = this.storePolicies = this.saleDoc = null;
      return;
    }

    this.filterCriteria = null;
    // Exclude lines with negative quantities
    this.saleDoc.saleDocLines = this.saleDoc.saleDocLines.filter((line) => line.qty > 0);
    this._origSaleDocLines = this._cloneSaleDocLines(this.saleDoc.saleDocLines);
    // Only shows the first n lines, it's caped for this screen but when returning all it includes every line
    this.saleDoc.saleDocLines.splice(DocumentSearchForReturnComponent.LIMIT_SHOWED_LINES);
    // this.tendersPrettyPrint = _.uniqBy(this.saleDoc.notReturnedTenders, 'tenderType.id').map(t => t.tenderType.description).join(', ');
    this.tendersPrettyPrint = [
      ...new Set(
        this.saleDoc.notReturnedTenders.map((t) =>
          t.refTransaction != null ? t.refTransaction.CardType : t.tenderType ? t.tenderType.description : null
        )
      ),
    ]
      .filter(Boolean)
      .join(", ");

    // Update left-side with current document, but don't transfer lines
    const saleDocShallowCopy = Object.assign({}, this.saleDoc);
    saleDocShallowCopy.saleDocLines = [];

    // if store has changes, fetch policies again
    if (!oldSaleDoc || oldSaleDoc.storeId !== this.saleDoc.storeId) {
      this.storePolicies = null;
      this._fetchStorePolicies();
    }

    this.entireDocReturned.emit(saleDocShallowCopy);
  }

  _cloneSaleDocLines(docLines: SaleDocLine[]) {
    const clonedLines: SaleDocLine[] = [];
    docLines.forEach((docLine) => {
      clonedLines.push(this._cloneSingleDocLine(docLine));
    });
    return clonedLines;
  }

  _cloneSingleDocLine(docLine: SaleDocLine) {
    return _.cloneDeep(docLine);
  }

  clearSearchFields() {
    this.docsSearchForm.reset();
    if (this._searchAccountsComp) this._searchAccountsComp.clearSearchInputOnly();
    if (this._searchInventoryComp) this._searchInventoryComp.clearSearchInputOnly();

    this._setNewSaleDoc(null);
  }

  onReturnAll() {
    // First create a safe of current saleDoc and lines
    const saleDocShallowCopy = Object.assign({}, this.saleDoc);

    const updatedSaleDocLines = this._origSaleDocLines
      .filter((row) => row.qty > row.qtyReferred && !row.voucher?.saleDocTender)
      .map((row) => ({
        ...row, // Keep all other properties as they are
        qty: row.qty - row.qtyReferred,
      })) as SaleDocLine[];

    saleDocShallowCopy.saleDocLines = this._cloneSaleDocLines(updatedSaleDocLines);
    saleDocShallowCopy.saleDocLines.forEach((line) => this.setupReturnedLine(line, this.saleDoc));
    this.entireDocReturned.emit(saleDocShallowCopy);

    // Then set current saleDoc to not having lines, so is shows empty lines in current component
    this.saleDoc.saleDocLines = [];
  }

  updateIsReturnAllButtonDisabled() {
    this.isReturnAllButtonDisabled = this.saleDoc.saleDocLines.some(
      (line) => line.recurringOrderSettingId != null || line.qtyNotReferred <= 0
    );
  }

  private setupReturnedLine(line: SaleDocLine, saleDoc: SaleDoc) {
    line.docLine.note = `Invoice #${saleDoc.doc.docNo}. ${line.docLine.note}`;
  }

  onReturnSingleItem(saleLine: SaleDocLine) {
    const qtyToReturn = Math.min(1, saleLine.qtyNotReferred);

    const originalLine = this._origSaleDocLines.find((currDocLine) => currDocLine.id === saleLine.id);
    if (!originalLine) alert("Error, original line not found");

    // Clone current line and set quantity to 1, so by default we only return 1 qty
    const saleDocShallowCopy = Object.assign({}, this.saleDoc);
    const saleLineDeepCopy = this._cloneSingleDocLine(saleLine);
    saleLineDeepCopy.qty = qtyToReturn;
    this.setupReturnedLine(saleLineDeepCopy, this.saleDoc);
    saleDocShallowCopy.saleDocLines = [saleLineDeepCopy];

    // Change current line for actual component visualization
    saleLine.qty -= qtyToReturn; // Decrease quantity in 1 for rightside
    // If new qty is 0 or less removed line from preview
    if (saleLine.qtyNotReferred <= 0) {
      // find current line and remove it
      _.remove(this.saleDoc.saleDocLines, (currSaleLine) => currSaleLine.id === saleLine.id);
    }

    this.singleQtyReturned.emit({
      saleDoc: saleDocShallowCopy,
      docLineId: saleLineDeepCopy.id,
      maxQty: originalLine.qtyNotReferred,
    });

    if (this._isMobile) {
      const itemDesc = saleLine.docLine.inventory.description1;
      this.messageService.add(this.alertService.getMessage("returns-item-moved", itemDesc));
    }
  }

  // onInventorySingleSearch(inventory:SearchResultItem){
  //   this.singleDocSearchForm.patchValue({
  //     inventoryId: inventory.ID
  //   });
  // }

  onInventorySearch(inventory: SearchResultItem) {
    this.docsSearchForm.patchValue({
      inventoryId: inventory.ID,
    });
  }

  onAccountSearch(account: SearchResultItem) {
    this.docsSearchForm.patchValue({
      accountId: account.ID,
    });
  }

  clearAccountField() {
    this.docsSearchForm.patchValue({
      accountId: 0,
    });
  }

  clearInventoryField() {
    this.docsSearchForm.patchValue({
      inventoryId: 0,
    });
  }

  returnSelectedDocs() {
    // TODO Imoplement method
  }

  ngOnDestroy(): void {
    this.searchDocForReturnStateService.saveComponentState(this, ["docsSearchForm"]);
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
