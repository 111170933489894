<div [formGroup]="formGroup" class="md-dropdownfield p-float-label" [ngClass]="{ 'inputctrl-nofilled': !isFilled }">
  <p-dropdown
    [options]="_countries"
    [formControlName]="fieldName"
    filter="true"
    filterBy="startsWithFilter"
    [ngClass]="{ 'inputctrl-filled': isFilled }"
    [virtualScroll]="true"
    [virtualScrollItemSize]="35"
    [virtualScrollOptions]="_scrollOptions"
    [panelStyle]="{ width: '300px' }"
    resetFilterOnHide="true"
    (click)="tryFocusFilterField($event)"
    appendTo="body"
    [readonly]="readonly"
  >
    <ng-template pTemplate="selectedItem" let-country>
      <img
        *ngIf="!hideSelectedItemFlag && country.value"
        class="selected-flag"
        src="assets/flag_16/{{ country.value }}.png"
      />
      <span *ngIf="!hideSelectedItemLabel" class="selected-label">{{ country.label }}</span>
    </ng-template>

    <ng-template pTemplate="item" let-country>
      <div class="grid country-drop-item">
        <img
          *ngIf="country.value"
          class="country-flag"
          src="assets/flag_16/{{ country.value }}.png"
          onerror="this.style.display='none'"
        />
        <span class="country-label">{{ country.label }}</span>
      </div>
    </ng-template>
  </p-dropdown>

  <label *ngIf="fieldCaption" for="{{ fieldName }}" [innerHTML]="fullFieldCaption"></label>

  <form-validation-messages
    [hidden]="formGroup.get(fieldName).valid || !formGroup.get(fieldName).dirty"
    [errors]="formGroup.get(fieldName).errors"
    [fieldName]="fieldName"
  ></form-validation-messages>
</div>
