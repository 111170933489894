import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import {
  AllServerErrors$,
  ServerErrorTrace,
} from "../../services/http-interceptors/trace-id-error-interceptor.service";

@Component({
  selector: "taku-error-logs",
  templateUrl: "./error-logs.component.html",
})
export class ErrorLogsComponent implements OnInit {
  subsList: Subscription[] = [];
  allServerErrors: ServerErrorTrace[] = [];

  ngOnInit(): void {
    this.subsList.push(AllServerErrors$.subscribe((allServerErrors) => (this.allServerErrors = allServerErrors)));
  }
}
