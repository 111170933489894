/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { DBService } from "src/app/shared/services/db.service";
import { Injectable } from "@angular/core";
import { SelectItem } from "primeng/api";
import { BarcodeType, BarcodeFormat } from "./inventory-barcode";

@Injectable({
  providedIn: "root",
})
export class InventoryBarcodeService extends DBService {
  get_enum_barcode_type(): SelectItem[] {
    return this.enumSelectOptions(BarcodeType);
  }
  get_enum_barcode_format(): SelectItem[] {
    return this.enumSelectOptions(BarcodeFormat);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
