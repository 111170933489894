<div id="detailsDialog-topWrapper" class="grid">
  <taku-form-header
    toolbarTitle="Invoice Details"
    displayMode="inline"
    [showActionBtns]="false"
    [showBackBtn]="false"
  ></taku-form-header>

  <div class="row-fields col-12" [ngStyle]="{ marginTop: '10pt' }">
    <div class="grid">
      <div class="col-4 sm:col-5 doc-info-box doc-info">
        <dl>
          <dt>Invoice Number:</dt>
          <dd *ngIf="saleForm.get('doc.docNo').value as docNumber">{{ docNumber }}</dd>
        </dl>
      </div>

      <div class="col-7 col-offset-1 sm:col-offset-0 field-taxAccountCats">
        <taku-dropdown
          [formGroup]="saleForm"
          [options]="lookup_taxAccountCategories$ | async"
          fieldName="taxAccountCategoryId"
          fieldCaption="Tax Account Category"
          appendTo="body"
        ></taku-dropdown>
      </div>
    </div>
  </div>

  <div class="row-fields col-12">
    <div class="grid">
      <div class="col-4 sm:col-5 doc-info-box cashout-info" *ngIf="cashout">
        <!-- <h4><span class="text">CASHOUT</span>
            <hr class="crossline">
          </h4> -->
        <dl>
          <dt>Cashout ID:</dt>
          <dd>{{ cashout.id }}</dd>
        </dl>
      </div>

      <div class="col-7 col-offset-1 sm:col-offset-0 field-TaxID">
        <taku-input [formGroup]="saleForm" fieldName="taxID" fieldCaption="Tax ID"></taku-input>
      </div>
    </div>
  </div>

  <div class="row-fields col-12">
    <div class="grid">
      <div class="col-4 sm:col-5 doc-info-box cashout-info" *ngIf="cashout">
        <dl>
          <dt>Opened at:</dt>
          <dd>{{ cashout.openingDate | dateTimeFormat : cashout.openingTime }}</dd>
        </dl>
      </div>
    </div>
  </div>

  <div class="row-fields col-12">
    <div class="grid">
      <div class="col-4 sm:col-5 doc-info-box cashout-info" *ngIf="cashout">
        <dl>
          <dt>Posted at:</dt>
          <dd>{{ cashout.closingDate | dateTimeFormat : cashout.closingTime }}</dd>
        </dl>
      </div>

      <div class="col-7 col-offset-1 sm:col-offset-0 field-customerSource">
        <taku-dropdown
          [formGroup]="saleForm"
          [options]="enum_saledoc_fulfillment_status"
          fieldName="fulfillmentStatus"
          fieldCaption="Fulfillment Status"
          appendTo="body"
          (onChange)="onFulfillmetStatusChanged()"
        ></taku-dropdown>
      </div>
    </div>
  </div>
</div>

<p-dialog [modal]="true" header="Confirmation" [(visible)]="dialogVisible" appendTo="body">
  <div class="dialog-validation-errors">
    <p
      >Please note that an Account is necessary if this transaction is not fulfilled immediately. Please add an Account
      first.</p
    >

    <!-- <div class="col-12 buttonsWrapper">
      <button pButton label="OK" class="p-button-secondary" (click)="onCloseDialog()"></button>
    </div> -->
  </div>
</p-dialog>
