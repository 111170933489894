/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { DBService } from "../../../../../shared/services/db.service";
import { GMBLocation } from "../../google-my-business/gmb-locations-list/GMBLocation";
import { GMCAccount, GMCGoogleAccount } from "./GMCGoogleAccount";

@Injectable({
  providedIn: "root",
})
export class GMCAccountsService extends DBService {
  // construct a series of request in order to obtain the GMC Accounts that have been linked/associated with the  store
  fetchGoogleSettingsAssociatedToStore(
    storeId: number
  ): Observable<{ gmbLocation: GMBLocation; gmcAccount: GMCAccount }> {
    return this.getRows(
      "gmbLocation",
      JSON.stringify({
        storeId: { matchMode: "equals", value: storeId },
      })
    ).pipe(
      mergeMap((response: any) => {
        if (response.rows.length) {
          const gmbLocation = response.rows.length ? <GMBLocation>response.rows[0] : null;
          // const gmbAccountsIds = (<GMBLocation[]>response.rows).map(gmbLocation => gmbLocation.gmbAccountId );
          if (gmbLocation)
            return this.getRows(
              "gmcAccount",
              JSON.stringify({
                gmbAccountId: { matchMode: "equals", value: gmbLocation.gmbAccountId },
              })
            ).pipe(
              map((response: any) => {
                const gmcAccount = response.rows.length ? <GMCAccount>response.rows[0] : null;
                return { gmbLocation, gmcAccount };
              })
            );
          else return of({ gmbLocation, gmcAccount: null });
        } else {
          return of({ gmbLocation: null, gmcAccount: null });
        }
      }),
      mergeMap((result: any) => {
        if (result.gmcAccount)
          return this.getRow("gmcGoogleAccount", result.gmcAccount.gmcGoogleAccountId).pipe(
            map((gmcGoogleAccount: GMCGoogleAccount) =>
              gmcGoogleAccount.isActive ? result : { gmbLocation: result.gmbLocation, gmcAccount: null }
            )
          );
        else return of(result);
      })
    );
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
