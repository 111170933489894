<!-- <div class="col-12">
  <code>
    Selected Period Type: {{selectedDatePeriod|json}} <br>
    Calendar Dates: {{rangeDates|json}} <br>
    Form: {{formGroup.value|json}} <br>
    </code>
</div> -->
<div #calendarWrapper class="calendarRange-topWrapper">
  <div class="form-field">
    <a href="javascript://" (click)="openCalendarOverlay()">
      <div class="value" [ngSwitch]="formGroup.get(fieldNameRangeType).value">
        <span *ngSwitchCase="DateRangeType.CUSTOM">
          {{ formGroup.get(fieldNameDateFrom).value | dateFormat
          }}<ng-template [ngIf]="!isSingleDay"
            ><span class="date-separator">-</span>{{ formGroup.get(fieldNameDateTo).value | dateFormat }}</ng-template
          >
        </span>

        <span *ngSwitchDefault>
          {{ formGroup.get(fieldNameRangeType).value }}
        </span>
      </div>
    </a>
    <a href="javascript://" class="calendar-icon pi pi-calendar" (click)="openCalendarOverlay()"></a>
  </div>
  <label>{{ fieldCaption }}</label>

  <div
    #calendarOverlay
    [hidden]="!isOverlayOpen"
    class="calendarRange-overlay"
    [ngClass]="{
      leftPos: overlayPosition === OverlayPosition.LEFT_POS,
      rightPos: overlayPosition === OverlayPosition.RIGHT_POS
    }"
    [ngStyle]="{
      right: overlayPosition === OverlayPosition.LEFT_POS ? _overlayOffset + 'px' : null,
      left: overlayPosition === OverlayPosition.RIGHT_POS ? _overlayOffset + 'px' : null
    }"
  >
    <div class="inner-wrapper card grid">
      <div class="rangetype-wrapper" [formGroup]="_componentFormGroup">
        <taku-dropdown
          [readonly]="disabled"
          *ngIf="isMobile"
          class="rangetype-mobile"
          [formGroup]="_componentFormGroup"
          [options]="_lookup_datePeriods"
          fieldName="datePeriod"
          fieldCaption="Period Type"
        ></taku-dropdown>
        <p-listbox
          [disabled]="disabled"
          *ngIf="!isMobile"
          class="rangetype-desktop"
          [options]="_lookup_datePeriods"
          formControlName="datePeriod"
        ></p-listbox>
      </div>
      <div class="calendar-wrapper">
        <p-calendar
          [disabled]="disabled"
          selectionMode="range"
          [ngModel]="rangeDates"
          [maxDate]="maxDateValue"
          [inline]="true"
          [numberOfMonths]="_calendarVisibleMonths"
          (ngModelChange)="datesOnCalendarChanged($event)"
          (onMonthChange)="monthChanged($event)"
        >
        </p-calendar>
        <div class="grid col-12 calendar-bottom">
          <div class="col-9 input-dates" *ngIf="showTimeRange">
            <p-calendar [(ngModel)]="rangeTimeFrom" [timeOnly]="true" hourFormat="24" showSeconds="true"></p-calendar>
            <span class="date-separator">-</span>
            <p-calendar [(ngModel)]="rangeTimeTo" [timeOnly]="true" hourFormat="24" showSeconds="true"></p-calendar>
          </div>
          <div class="col-9 input-dates">
            <div class="dateField-wrapper">
              <p-inputMask
                [disabled]="disabled"
                class="dateInput"
                [mask]="dateInputMask"
                slotChar="#"
                [ngModel]="rangeDates[0] | dateFormat"
                (ngModelChange)="onInputDateFromChanged($event)"
              ></p-inputMask>
              <form-validation-messages [errors]="fieldErrorMessages.dateFrom" field="calendarDateFrom">
              </form-validation-messages>
            </div>

            <span class="date-separator">-</span>

            <div class="dateField-wrapper">
              <p-inputMask
                [disabled]="disabled"
                class="dateInput"
                [mask]="dateInputMask"
                slotChar="#"
                [ngModel]="rangeDates[1] | dateFormat"
                (ngModelChange)="onInputDateToChanged($event)"
              ></p-inputMask>
              <form-validation-messages [errors]="fieldErrorMessages.dateTo" field="calendarDateTo">
              </form-validation-messages>
            </div>
          </div>

          <div class="col-3 p-fluid applyBtn-wrapper">
            <button [disabled]="disabled" pButton label="Select" (click)="onApplySelectedDates()"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
