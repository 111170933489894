/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, Subject, throwError } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { LoginResponse } from "../../core/users/login/login-resposne";
import { UserProfileService } from "./user-profile.service";
import { MessageService } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";

type LogoutResponse = {
  success: boolean;
  message: string;
};

@Injectable()
export class AuthService {
  private tokenStorageKey: string;
  private isCompletedStorageKey: string;
  private isAdmin;
  private logoutSource = new Subject<void>();
  private loginSource = new Subject<void>();

  /** Event pushed after a successful logout */
  logoutSource$ = this.logoutSource.asObservable();
  /** Event pushed after a successful login */
  loginSource$ = this.loginSource.asObservable();
  webUrl = environment.apiUrl;

  // store the URL so we can redirect after logging in
  redirectUrl: string;

  constructor(
    private router: Router,
    private http: HttpClient,
    private userService: UserProfileService,
    private messageService: MessageService,
    private dialogService: DialogService,
    private ngZone: NgZone
  ) {
    this.tokenStorageKey = "token";
    this.isCompletedStorageKey = "loginCompleted";
  }

  protected processAuthResponse(loginRes: LoginResponse): boolean {
    // login successful if there's a jwt token in the response
    if (loginRes && loginRes.success && loginRes.token) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      this.setToken(loginRes.token);
      this.userService.setUser(loginRes);
      this.loginSource.next();
      return true;
      // this.router.navigate([this.redirectUrl]);
    } else {
      // login errors
      //   if (!loginRes.success) {
      //     this.alertService.getErrorMessage(null,'Error','Try again');
      //   }
      // }
      return false;
    }
  }

  login(_object: any, accountName, _force = false): Observable<any> {
    return this.http
      .post<LoginResponse>(this.webUrl + "login/" + accountName, _object, {
        params: new HttpParams().append("force", _force.toString()),
      })
      .pipe(
        tap((loginRes: LoginResponse) => {
          this.processAuthResponse(loginRes);
        })
      );
  }

  fetchNewToken(storeId: number, stationId: number, backOfficeId: number): Observable<any> {
    let url: string;
    if (backOfficeId) {
      url = `${this.webUrl}genToken/backoffice/${backOfficeId}`;
    } else {
      url = `${this.webUrl}genToken/store/${storeId}/${stationId}`;
    }

    return this.http
      .post(
        url,
        {},
        {
          headers: new HttpHeaders().set("Authorization", this.getToken()),
        }
      )
      .pipe(
        tap((response: any) => {
          if (!this.processAuthResponse(response)) throwError({ success: false });
        })
      );
  }

  setToken(token: string) {
    sessionStorage.setItem(this.tokenStorageKey, token);
  }

  getToken(): string {
    if (sessionStorage.getItem(this.tokenStorageKey)) {
      return sessionStorage.getItem(this.tokenStorageKey);
    } else {
      return null;
    }
  }

  setLoginCompleted(isCompleted: boolean) {
    sessionStorage.setItem(this.isCompletedStorageKey, JSON.stringify(isCompleted));
  }

  isLoginCompleted(): boolean {
    if (sessionStorage.getItem(this.isCompletedStorageKey)) {
      return JSON.parse(sessionStorage.getItem(this.isCompletedStorageKey));
    } else {
      return false;
    }
  }

  isLoggedIn(): boolean {
    // checkes if there's a token in the designated storage
    return this.getToken() != null;
  }

  async logout(navigationState = { bypassGuards: false }): Promise<boolean> {
    // Close any dynamic dialogs that may be open
    this.dialogService.dialogComponentRefMap.forEach((ref) => ref.destroy());

    const _token = this.getToken();
    let successfulNavigation = true;
    if (!this.router.isActive("/login", true)) {
      if (NgZone.isInAngularZone()) {
        successfulNavigation = await this.router.navigate(["/login"], { state: navigationState });
      } else {
        successfulNavigation = await this.ngZone.run(() =>
          this.router.navigate(["/login"], { state: navigationState })
        );
      }
    }
    if (successfulNavigation) {
      this.resetAuthState();
      return this.callServerLogout(_token);
    } else {
      return successfulNavigation;
    }
  }

  private async callServerLogout(token: string): Promise<boolean> {
    if (!token) {
      console.debug("No token present to call logout endpoint");
      return false;
    }
    try {
      const response = await this.http
        .post<LogoutResponse>(this.webUrl + "logout", {}, { headers: new HttpHeaders().set("Authorization", token) })
        .toPromise();

      if (!response.success) {
        this.messageService.add({
          severity: "error",
          summary: "Logout error",
          detail: response.message,
        });
      }
      return response.success;
    } catch (error) {
      this.messageService.add({
        severity: "error",
        summary: "Logout error",
        detail: error.message,
      });
      return false;
    }
  }

  resetAuthState(): void {
    this.logoutSource.next();
    this.setLoginCompleted(false);
    // remove user from local storage and route it to login page to log user out
    this.removeUserTokens();
  }

  private removeUserTokens() {
    sessionStorage.removeItem(this.tokenStorageKey);
    this.userService.removeUser();
    // TODO: if there are any other not needed data in storages it should be removed here
  }

  forgotPassowrd(accountName: string, userName: string): Observable<any> {
    const _user = { userName: userName };
    return this.http.post(this.webUrl + "forgotPassword/" + accountName, _user, {
      headers: new HttpHeaders(),
    });
  }

  resetPassword(merchantAccount: string, token: string, password: string, confirmPassword: string): Observable<any> {
    return this.http.post(this.webUrl + "reset/" + `${merchantAccount}/${token}`, { password, confirmPassword });
  }

  accountNameExists$(accountName: string): Observable<any> {
    return this.http.get(this.webUrl + `merchantAccount/${accountName}`);
  }

  hasVisiblePermit(_feature: string): boolean {
    const _role = this.userService.getRole();
    if (!_role) {
      return true;
    } else if (_role.isAdmin || _role.isOwner || _role.isSuperAdmin) {
      return true;
    } else if (!_role || _role.roleVisibilities.length === 0) {
      return false;
    } else {
      const _permition = _role.roleVisibilities.find((row) => row.feature === _feature);
      return _permition ? _permition.isVisible : false;
    }
  }

  hasEnablePermit(_feature: string): boolean {
    const _role = this.userService.getRole();
    if (!_role) {
      return true;
    } else if (_role.isAdmin || _role.isOwner || _role.isSuperAdmin) {
      return true;
    } else if (!_role || _role.roleVisibilities.length === 0) {
      return false;
    } else {
      const _permition = _role.roleVisibilities.find((row) => row.feature === _feature);
      return _permition ? _permition.isReadOnly : false;
    }
  }

  hasPriviledgePermit(_feature: string): boolean {
    const _role = this.userService.getRole();
    if (_role || _role.rolePrivileges.length === 0) {
      return false;
    } else {
      const _permition = _role.rolePrivileges.find((row) => row.feature === _feature);
      return _permition;
    }
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
