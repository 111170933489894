/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit } from "@angular/core";
import { ArrayFormComponent } from "src/app/forms/array-form/array-form.component";
import { InventoryBarcodeComponent } from "../inventory-barcode/inventory-barcode.component";

@Component({
  selector: "taku-inventory-barcodes-array",
  templateUrl: "./inventory-barcodes-array.component.html",
  styleUrls: ["./inventory-barcodes-array.component.scss"],
})
export class InventoryBarcodesArrayComponent extends ArrayFormComponent {
  // Override default setArray
  setArray(data: any[]) {
    return InventoryBarcodeComponent.setArray(this.fb, data);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
