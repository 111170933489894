<div
  [formGroup]="_myForm"
  class="acc-item ui-docline"
  [ngClass]="{
    'acc-selected': isSelected,
    'acc-expanded': isExpanded,
    'acc-evenline': lineIndex % 2 === 0,
    'acc-oddline': lineIndex % 2 === 1
  }"
  (click)="onLineClick($event)"
>
  <div class="acc-bar" (click)="lineExpansion.emit($event)">
    <div class="line-number-container">
      <span
        class="toggle-expansion-icon"
        [ngClass]="{ 'pi pi-angle-down': !isExpanded, 'takuicon-collapse-row': isExpanded }"
      ></span>
    </div>
  </div>

  <div class="acc-wrapper">
    <div id="hey" class="acc-header item-lines-container">
      <div class="row1">
        <div class="col-7 item-desc" [ngClass]="{ 'col-7': hasDiscount, 'col-12': !hasDiscount }">
          <span class="desc-text">{{ _myForm.get("description").value }}</span>
          <span *ngIf="!hasDiscount" class="tax-acronym">{{ lineTaxesAcronyms }}</span>
        </div>
        <div class="col-5 total-before-disc" *ngIf="hasDiscount">
          <span class="tax-acronym mr-2">{{ lineTaxesAcronyms }}</span>
          <del>{{ unitPriceCtrl.value * qtyCtrl.value | currency : currencyIsoCode : "symbol-narrow" : "1.2" }}</del>
        </div>
      </div>

      <div class="row2" [ngClass]="{ 'row2-margin-bottom': inDocLineFormatChangeRange() }">
        <!-- <div class="col-4 item-attr">{{ _myForm.get('categories').value }}</div> -->
        <div class="col-1">
          <i *ngIf="_myForm.get('docLine.inventory').value.parentInventoryId" class="material-icons"
            >photo_size_select_small</i
          >
          <i *ngIf="_myForm.get('docLine.inventory').value.isVariantParent" class="material-icons">bookmarks</i>
          <i
            *ngIf="
              !_myForm.get('docLine.inventory').value.parentInventoryId &&
              !_myForm.get('docLine.inventory').value.isVariantParent
            "
            class="material-icons"
            >bookmark_border</i
          >
          <div class="p-1"> {{ inventoryVariantsStr }}</div>
        </div>
        <div class="col-3 grid-nogutter" [ngClass]="inDocLineFormatChangeRange() ? '' : 'col-offset-1'">
          <div class="grid">
            <div class="col-12">
              <div class="grid">
                <a
                  class="col-4 qty-chg-btn qty-dec-btn"
                  (click)="decQty($event)"
                  [ngClass]="{ disabledBtn: !isQtyValid(calculateQtyDecrement) }"
                >
                  <i class="material-icons">remove_circle</i>
                </a>
                <div class="col-4 item-qty">
                  <strong>
                    <label *ngIf="!qtyCtrl.errors || qtyCtrl.value < 0">{{
                      qtyCtrl.value | formatNegativeNumber
                    }}</label>
                  </strong>
                </div>
                <a
                  class="col-4 qty-chg-btn qty-inc-btn"
                  (click)="incQty($event)"
                  [ngClass]="{ disabledBtn: !isQtyValid(calculateQtyIncrement) }"
                >
                  <i class="material-icons">add_circle</i>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="item-price col-7" [ngClass]="inDocLineFormatChangeRange() ? 'col-8' : 'col-7'">
          <div class="grid">
            <div
              class="item-price"
              (click)="onPriceClick()"
              [ngClass]="
                inDocLineFormatChangeRange() &&
                isNotDesktopDisplay() &&
                _myForm.get('docLine').value?.inventory?.isReturnPriceEditable
                  ? 'col-4'
                  : 'col-6'
              "
            >
              <span>{{ unitPriceCtrl.value | currency : currencyIsoCode : "symbol-narrow" : "1.2" }}</span>
            </div>
            <div
              *ngIf="_myForm.get('docLine').value?.inventory?.isReturnPriceEditable"
              [ngClass]="inDocLineFormatChangeRange() && isNotDesktopDisplay() ? 'col-2' : 'col-1'"
            >
              <a href="javascript://" class="pi pi-pencil" (click)="unitPriceEdit.toggle($event)">
                <p-overlayPanel
                  #unitPriceEdit
                  [showCloseIcon]="false"
                  appendTo="body"
                  (onHide)="setZeroIfEmpty('unitPrice', _myForm.get('docLine.inventory').value['standardPrice'])"
                  (onShow)="focusPriceEdit()"
                >
                  <taku-input
                    #unitPriceInput
                    [formGroup]="_myForm"
                    [autoSelect]="true"
                    fieldName="unitPrice"
                    [fieldCaption]="'Unit Price'"
                    style="width: auto"
                  ></taku-input>
                  <p-button icon="takuicon-calculator" styleClass="squared-corners" (onClick)="numpadUnitPrice.open()">
                  </p-button>
                  <taku-numpad
                    #numpadUnitPrice
                    padTitle="Enter Unit Price"
                    [numpadType]="'monetary'"
                    [initialQty]="unitPriceCtrl.value"
                    (submitQty)="onUnitPriceChanged($event)"
                  ></taku-numpad>
                </p-overlayPanel>
              </a>
            </div>
            <div
              *ngIf="!_myForm.get('docLine').value?.inventory?.isReturnPriceEditable"
              [ngClass]="{ 'col-1': !inDocLineFormatChangeRange() || !isNotDesktopDisplay() }"
            >
            </div>
            <div
              *ngIf="qtyCtrl.valid"
              class="price-total"
              [ngClass]="inDocLineFormatChangeRange() && isNotDesktopDisplay() ? 'col-6' : 'col-5'"
            >
              ({{ salePriceCtrl.value * qtyCtrl.value | currency : currencyIsoCode : "symbol-narrow" : "1.2" }})
            </div>
          </div>
          <!-- <div class="col-1 line-delete">
            <i class="pi pi-times" (click)="onDelete()"></i>
          </div> -->
        </div>
      </div>
    </div>

    <div class="acc-content item-lines-container">
      <div class="grid row3-and-row4">
        <div class="grid my-row-3" [ngClass]="inDocLineFormatChangeRange() ? 'col-12 row3-width-adjust' : 'col-5'">
          <div [ngClass]="isInDoubleLineRange() ? 'col-12 sku-barcode-double-line' : 'col-6 sku-barcode'">
            <div class="line-sku-barcode">
              SKU:
              <a *ngIf="isInventoryDetailsVisible" (click)="showItemDetailsDlg($event)" class="sku-underline">
                <strong>
                  <label #sku style="cursor: pointer">{{ _myForm.get("sku").value }} </label>
                </strong>
              </a>
              <strong *ngIf="!isInventoryDetailsVisible">
                <label #sku>{{ _myForm.get("sku").value }} </label>
              </strong>
            </div>
            <div *ngIf="_myForm.get('docLine').get('inventory').value.inventoryBarcodes[0]" class="line-sku-barcode">
              Barcode:
              <label>{{ _myForm.get("docLine").get("inventory").value.inventoryBarcodes[0].barcode }}</label>
            </div>
            <div *ngIf="_myForm.controls.voucher?.value" class="line-sku-barcode">
              Code:
              <label>{{ _myForm.controls.voucher.value.uniqueCode }}</label>
            </div>
          </div>
          <div
            [ngClass]="{
              'col-12 chg-qty-double-line': isInDoubleLineRange(),
              'col-6 chg-qty': !isInDoubleLineRange(),
              'chg-qty-docline-format-change': inDocLineFormatChangeRange()
            }"
          >
            <!-- <div [ngClass]="isInDoubleLineRange() ? 'col-12 chg-qty-double-line' : 'col-6 chg-qty'"> -->
            <div class="change-qty p-inputgroup">
              <taku-input
                #qtyInput
                [formGroup]="_myForm"
                data-docline-field
                fieldName="qty"
                keyFilter="pnum"
                [fieldCaption]="qtyCtrl.errors ? '' : 'Quantity'"
                (click)="onQtyClicked($event)"
                [autoSelect]="true"
              ></taku-input>
              <span class="p-inputgroup-addon">
                <p-button
                  icon="takuicon-calculator"
                  styleClass="squared-corners"
                  (onClick)="numpadQty.open()"
                ></p-button>
                <taku-numpad
                  #numpadQty
                  padTitle="Enter Quantity"
                  numpadType="numeric"
                  [initialQty]="qtyCtrl.value"
                  [maximumQty]="maxQtyCtrl.value"
                  (submitQty)="onNumpadItemQty($event)"
                ></taku-numpad>
              </span>
            </div>
          </div>
        </div>
        <div
          [ngClass]="inDocLineFormatChangeRange() ? 'col-12 row4-margin-top-adjust' : 'col-7 row4-margin-left-adjust'"
        >
          <p-fieldset class="discounts-fieldset" legend="Unit Discount">
            <div class="grid">
              <div
                [ngClass]="
                  isInDoubleLineRange() ? 'p-fluid col-12 chg-discount-type' : 'p-fluid col-12 chg-discount-type'
                "
              >
                <p-selectButton
                  [disabled]="true"
                  [options]="enumDiscountType"
                  formControlName="discountType"
                ></p-selectButton>
              </div>
              <div [ngClass]="isInDoubleLineRange() ? 'col-12 chg-discount' : 'col-12 chg-discount'">
                <div class="p-inputgroup chg-amount">
                  <ng-template [ngIf]="discountTypeCtrl.value == DiscountType.Percentage">
                    <span class="p-inputgroup-addon return-chg-border">%</span>
                    <taku-input
                      class="extra-margin-correction"
                      [ngClass]="{
                        'takuinput-margin-error-correction': !isInDoubleLineRange() || inDocLineFormatChangeRange()
                      }"
                      #lineDiscountAmount
                      [disabled]="true"
                      keyFilter="pnum"
                      [formGroup]="_myForm"
                      fieldName="discountAmount"
                      [fieldCaption]="discountAmountCtrl.errors ? '' : 'Amount'"
                      (click)="onDiscAmountClicked($event)"
                      (onBlur)="setZeroIfEmpty('discountAmount')"
                    ></taku-input>
                  </ng-template>

                  <ng-template [ngIf]="[DiscountType.Fixed, DiscountType.Final_Price].includes(discountTypeCtrl.value)">
                    <taku-money
                      #lineDiscountAmount
                      [disabled]="true"
                      [formGroup]="_myForm"
                      fieldName="discountAmount"
                      [fieldCaption]="discountAmountCtrl.errors ? '' : 'Amount'"
                      (click)="onDiscAmountClicked($event)"
                      [currencyCode]="currencyIsoCode"
                      [currencyIsReadonly]="true"
                      (onBlur)="setZeroIfEmpty('discountAmount')"
                    ></taku-money>
                  </ng-template>
                  <span class="p-inputgroup-addon pt-1">
                    <p-button
                      class="mt-1"
                      [disabled]="true"
                      icon="takuicon-calculator"
                      styleClass="squared-corners"
                      (onClick)="numpadDiscount.open()"
                    ></p-button>
                    <taku-numpad
                      #numpadDiscount
                      padTitle="Enter Discount"
                      [numpadType]="discountTypeCtrl.value === 'Percentage' ? 'percentage' : 'monetary'"
                      [initialQty]="discountAmountCtrl.value"
                      (submitQty)="onNumpadDiscountAmount($event)"
                    ></taku-numpad>
                  </span>
                </div>
              </div>
            </div>
          </p-fieldset>
        </div>
      </div>

      <div class="row5 grid" formGroupName="docLine">
        <!-- <div class="col-3 p-fluid disc-options">
          <button class="p-button-primary-dark" pButton label="DISCOUNT OPTIONS" (click)="showDiscOptionsDlg($event)"></button>
        </div> -->

        <div class="col-12 sm:col-5 return-reason-setting">
          <taku-dropdown
            [formGroup]="_myForm.get('saleDocLineReturn')"
            [options]="_returnReasonsEnumOptions"
            fieldName="storeSettingReturnReasonId"
            appendTo="body"
            fieldCaption="Return Reason"
          ></taku-dropdown>
          <!-- <taku-dropdown *ngIf="_myForm.get('saleDocLineReturn.storeSettingReturnReasonId')" [formGroup]="_myForm.get('saleDocLineReturn')" [options]="_returnReasonsEnumOptions" fieldName="storeSettingReturnReasonId" fieldCaption="Return Reason" appendTo="body"></taku-dropdown> -->
        </div>

        <div class="col-12 sm:col-7 p-fluid line-note">
          <!-- <textarea formControlName="note" pInputTextarea placeholder="Line Note"></textarea> -->
          <taku-input [formGroup]="_myForm.get('docLine')" fieldName="note" fieldCaption="Line Note"></taku-input>
        </div>
      </div>
    </div>
  </div>
  <button pButton icon="pi pi-times" class="p-button-text p-button-icon-only line-delete" (click)="onDelete()"></button>
</div>
