/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { Person } from "../../contact-accounts/person/person";
import { UserRole } from "../user-role/user-role";

export class User {
  id: number;
  userType: "Support" | "Merchant";
  merchant: string;
  userName: string;
  password?: string;
  email: string;
  person: Person;
  // role: string;
  isActive: boolean;
  userRoles: UserRole[];
  uuid: string;
  isOwner: boolean;
  isAdmin: boolean;
  isSuperAdmin: boolean;

  constructor() {
    this.id = 0;
    this.userType = "Merchant";
    this.merchant = AppSettingsStorageService.getLoggedInAccount().accountName;
    this.userName = null;
    this.email = null;
    // this.confirmPassword = '';
    this.person = new Person();
    // this.role = '110';
    this.isActive = true;
    this.userRoles = [];
    this.isOwner = false;
    this.isAdmin = false;
    this.isSuperAdmin = false;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
