/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { FormListModel } from "src/app/form-list/form-list/form-list-model.interface";
import { Validators } from "@angular/forms";
import { DBService } from "src/app/shared/services/db.service";

@Injectable({
  providedIn: "root",
})
export class StorePoliciesSettingsService extends DBService implements FormListModel {
  getValidationRules() {
    return {
      salesPolicy: [Validators.maxLength(2400)],
      shippingPolicy: [Validators.maxLength(2400)],
      returnPolicy: [Validators.maxLength(2400)],
      giftReceiptPolicy: [Validators.maxLength(2400)],
      consent: [Validators.maxLength(2400)],
    };
  }

  getFormListColumns() {
    return [];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
