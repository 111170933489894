<div Class="mobile-self-checkout-topWrapper" [ngStyle]="{ height: topWrapperHeight + 'px' }">
  <div class="main-topWrapper" [ngClass]="{ secondaryBgColor: showShoppingCart || _showPaymentPage }">
    <div class="header">
      <div class="store-logo-container">
        <img *ngIf="storeLogoUrl" [attr.src]="storeLogoUrl" />
      </div>
      <div class="header-mid">
        <b *ngIf="!showShoppingCart" class="store-name">{{ storeName }}</b>
        <div *ngIf="showShoppingCart" class="shopping-cart-label">
          <b>Your Cart</b>
          <b class="shopping-cart-count">{{ _countTotalItems | formatNegativeNumber }} Items</b>
        </div>
      </div>
      <div class="material-icons menu-icon" (click)="onTopRightMenuIconClicked()"> menu </div>
    </div>
    <div *ngIf="!showShoppingCart && !_showPaymentPage" class="dataView-wrapper">
      <ng-container *ngTemplateOutlet="dataViewTemplate; context: { $implicit: _myForm }"> </ng-container>
    </div>
    <div *ngIf="showShoppingCart && !_showPaymentPage" class="mobile-shopping-cart-topWrapper">
      <div class="header-btns cart">
        <div class="keep-shopping-container" (click)="goBackToSearchScreen()">
          <button pButton type="button" pRipple icon="pi pi-arrow-left" class="p-button-rounded"></button>
          <span>Keep Shopping</span>
        </div>
        <div class="start-over-btn-container">
          <button
            (click)="onClearLines($event)"
            type="button"
            pButton
            pRipple
            label="Start Over"
            class="start-over-btn p-button-lg p-button-rounded"
            [disabled]="saleDocLines['controls'].length === 0"
          ></button>
        </div>
      </div>
      <!-- <hr class="fields-divider"> -->
      <div class="shopping-cart-list">
        <!-- <div *ngIf="saleDocLines['controls'].length === 0">
          <h3 class="mt-3">No items selected yet !</h3>
        </div> -->
        <p-scrollPanel
          id="selfCheckout-docLines-wrapper"
          [style]="{ width: '100%', height: 'calc(100% - 10px)' }"
          styleClass="custombar-salesscreen"
        >
          <span *ngFor="let saleDocLine of saleDocLines['controls']; let index = index">
            <ng-container
              *ngTemplateOutlet="selfCheckoutSaleDocLineComponent; context: { $implicit: saleDocLine, index: index }"
            ></ng-container>
          </span>
          <p-scrollTop
            *ngIf="supportsScroll"
            target="parent"
            styleClass="custom-scrollTop"
            icon="pi pi-arrow-up"
            [threshold]="200"
          ></p-scrollTop>
        </p-scrollPanel>
      </div>
      <div class="mobile-shopping-cart-footer">
        <taku-self-checkout-mobile-footer
          [saleForm]="_myForm"
          [currencyIsoCode]="documentCurrency"
          (onSelfCheckoutPay)="onSelfCheckoutPay($event)"
          (itemReturned)="itemReturnedAction($event)"
          (onSalePaid)="onPay($event)"
          (docTotalChanged)="updateSaleTotal($event)"
          (openDeliveryDialog)="openDeliveryMethodDialog()"
          (applyBulkDiscount)="applyBulkDiscount($event)"
        >
        </taku-self-checkout-mobile-footer>
        <taku-self-checkout-footer-labels [isBgColorWhite]="true"></taku-self-checkout-footer-labels>
      </div>
    </div>
    <div *ngIf="!showShoppingCart && _showPaymentPage" class="payment-page-topWrapper">
      <div class="header-btns payment">
        <div
          [ngClass]="{ 'disabled-show-items-container': paymentPageActiveIndex === 2 }"
          class="show-items-btn-container"
          (click)="onShowItemsPressed()"
        >
          <div class="cart-icon-container">
            <i
              class="material-icons-outlined mt-2"
              pBadge
              [value]="_countTotalItems | formatNegativeNumber"
              severity="success"
              >shopping_cart</i
            >
          </div>
          <div class="show-items-container">
            <span *ngIf="!_showItemsClicked">Show Items</span>
            <span *ngIf="_showItemsClicked">Hide Items</span>
            <span *ngIf="!_showItemsClicked" class="material-icons">navigate_next</span>
            <span *ngIf="_showItemsClicked" class="material-icons">expand_more</span>
          </div>
        </div>
        <div class="sale-doc-total-container">
          <!-- {{ paymentPageActiveIndex === 2 ? 'Paid' : saleDocTotal | currency:documentCurrency:'symbol-narrow':'1.2' | formatNegativeNumber}} -->
          <span *ngIf="!payAtCounterChosen">{{
            paymentPageActiveIndex === 2
              ? "Paid"
              : (saleDocTotal | currency : documentCurrency : "symbol-narrow" : "1.2" | formatNegativeNumber)
          }}</span>
          <span *ngIf="payAtCounterChosen">Pending</span>
        </div>
      </div>
      <div class="items-shown-container">
        <div *ngIf="_showItemsClicked && saleDocLines['controls'].length !== 0" class="no-items-label">
          <h2 class="mt-3">Your Cart</h2>
        </div>
        <div *ngIf="_showItemsClicked && saleDocLines['controls'].length === 0" class="no-items-label">
          <h2 class="mt-3">There are currently no items in the cart</h2>
        </div>
        <p-scrollPanel
          *ngIf="_showItemsClicked"
          id="selfCheckout-docLines-wrapper"
          [style]="{ width: '100%', height: '250px' }"
          styleClass="custombar-salesscreen"
        >
          <span *ngFor="let saleDocLine of saleDocLines['controls']; let index = index">
            <ng-container
              *ngTemplateOutlet="selfCheckoutSaleDocLineComponent; context: { $implicit: saleDocLine, index: index }"
            ></ng-container>
          </span>
          <p-scrollTop
            *ngIf="supportsScroll"
            target="parent"
            styleClass="custom-scrollTop"
            icon="pi pi-arrow-up"
            [threshold]="200"
          ></p-scrollTop>
        </p-scrollPanel>
      </div>
      <div class="payment-steps-container">
        <!-- <taku-steps class="col-12" [model]="paymentPageSteps" [(activeIndex)]="paymentPageActiveIndex"
          [lastStepVisible]="false" [backBtnEnabled]="false" [nextBtnEnabled]="false">
        </taku-steps> -->
        <div
          *ngIf="paymentPageActiveIndex === 0"
          [formGroup]="_myForm"
          class="step-addOns payment-steps"
          id="payment-step-1"
        >
          <div *ngIf="addOnsFromKioskSettings.length > 0" class="addons-header"><b>Add this to your order!</b></div>
          <!-- <div class="radioBtn-container">
            <p-radioButton name="deliveryMethod" label="Store Pickup" formControlName="deliveryMethod"
              value="Store Pickup"></p-radioButton>
          </div>
          <div class="radioBtn-container">
            <p-radioButton name="deliveryMethod" label="Ship Out" formControlName="deliveryMethod"
              value="Ship Out"></p-radioButton>
          </div> -->
          <!-- <div *ngIf="addOnsFromKioskSettings.length === 0" class="border-1">
            <h1>I have to put a list of plastic bags and checkout items here later</h1>
          </div> -->
          <p-scrollPanel
            *ngIf="addOnsFromKioskSettings.length > 0"
            [style]="{ width: '100%', height: '100%' }"
            styleClass="custombar-salesscreen"
          >
            <span *ngFor="let addOnItem of addOnsForm['controls']; let index = index">
              <ng-container
                *ngTemplateOutlet="
                  selfCheckoutAddOnItemComponent;
                  context: { $implicit: addOnItem, index: index, saleDocLines }
                "
              ></ng-container>
            </span>
            <p-scrollTop
              *ngIf="supportsScroll"
              target="parent"
              styleClass="custom-scrollTop"
              icon="pi pi-arrow-up"
              [threshold]="200"
            ></p-scrollTop>
          </p-scrollPanel>
        </div>
        <!-- <div *ngIf="paymentPageActiveIndex === 1" class="step-method payment-steps" id="payment-step-2">
          <div *ngIf="chosenDeliveryMethod === 'Store Pickup'">
            The user chose Store Pickup
          </div>
          <div *ngIf="chosenDeliveryMethod === 'Ship Out'">
            The use chose Ship Out
          </div>
        </div> -->
        <div
          *ngIf="paymentPageActiveIndex === 1 || paymentPageActiveIndex === 2"
          class="step-payment payment-steps"
          id="payment-step-3"
        >
          <taku-self-checkout-desktop-footer
            [saleForm]="_myForm"
            [currencyIsoCode]="documentCurrency"
            (itemReturned)="itemReturnedAction($event)"
            (onSalePaid)="onPay($event)"
            (docTotalChanged)="updateSaleTotal($event)"
            (openDeliveryDialog)="openDeliveryMethodDialog()"
            (applyBulkDiscount)="applyBulkDiscount($event)"
            [inPaymentPage]="true"
          >
          </taku-self-checkout-desktop-footer>
        </div>
      </div>
      <div class="payment-page-bottom">
        <div class="last-clickables">
          <div class="next-btn-container" [ngClass]="{ 'pay-buttons': paymentPageActiveIndex === 0 }">
            <button
              *ngIf="paymentPageActiveIndex === 0 && saleDocTotal !== 0 && allowPayAtCounter"
              type="button"
              pButton
              pRipple
              class="pay-at-counter-button p-button-rounded"
              (click)="payAtCounterClicked()"
            >
              <div class="btn-label-container">
                <span>PAY AT COUNTER</span>
              </div>
            </button>
            <button
              *ngIf="paymentPageActiveIndex === 0"
              type="button"
              pButton
              pRipple
              class="pay-now-button p-button-rounded"
              (click)="onGreenBtnClicked()"
            >
              <div class="btn-label-container">
                <span>NEXT</span>
              </div>
            </button>
            <div *ngIf="paymentPageActiveIndex === 2" class="payment-successful-msg-container">
              <h2>Thank you for your payment!</h2>
              <h4>How would you like to receive your receipt?</h4>
              <div class="email-receipt-container">
                <span>Email Receipt</span>
                <p-button icon="pi pi-envelope" (click)="saveAndSendEmail()"></p-button>
              </div>
              <div class="print-receipt-container">
                <span>Print Receipt</span>
                <p-button icon="pi pi-print" (click)="saveAndPrintDoc()"></p-button>
              </div>
              <div class="no-receipt-or-new-sale-container">
                <button
                  type="button"
                  pButton
                  pRipple
                  label="No Receipt"
                  class="p-button-rounded"
                  (click)="returnToMainScreen()"
                ></button>
                <button
                  type="button"
                  pButton
                  pRipple
                  label="New Sale"
                  class="p-button-rounded p-button-outlined"
                  (click)="returnToMainScreen()"
                ></button>
              </div>
              <span class="agreement-note"
                >By providing your contact information, you agree that businesses you frequent may send you digital
                receipts, marketing and messages via TAKU. See your digital receipt for privacy and preferences.</span
              >
            </div>
            <button
              *ngIf="paymentPageActiveIndex === 1 && !payAtCounterChosen"
              type="button"
              pButton
              pRipple
              class="pay-now-button p-button-rounded"
              (click)="onGreenBtnClicked()"
            >
              <div class="btn-label-container">
                <span>PAY NOW<i class="pi pi-credit-card"></i></span>
              </div>
            </button>
            <div *ngIf="paymentPageActiveIndex === 1 && payAtCounterChosen" class="payment-page-pay-at-counter">
              <span class="thank-you-line">Thank you for your order!</span>
              <span class="parked-number">{{ dailyNo }}</span>
              <span class="redirect-to-salesscreen-label"> Go to the counter to pay for your order </span>
              <button
                pButton
                pRipple
                type="button"
                label="New Sale"
                class="p-button-outlined p-button-rounded"
                (click)="returnToMainScreen()"
                [disabled]="!_isNewSaleButtonActive"
              >
              </button>
              <!-- <span class="footer-label">
                By providing your contact information, you agree that businesses you frequent may send you digital receipts, marketing and messages via TAKU. See your digital receipt for privacy policy and preferences.
              </span> -->

              <!-- <div *ngIf="paymentPageActiveIndex === 1" class="payment-page-last-step">
              <span>Insert or Tap your Card or Phone</span>
              <div class="pay-now-img-container">
                <img src="/assets/layout/images/checkout-payment.png">
              </div>
            </div> -->
            </div>
            <div *ngIf="paymentPageActiveIndex !== 2 && !payAtCounterChosen" class="return-to-cart-container">
              <span class="material-icons return-to-cart-icon">navigate_before</span>
              <span class="btn-label" (click)="returnToCartMobile()">Return to Cart</span>
            </div>
          </div>
          <taku-self-checkout-footer-labels></taku-self-checkout-footer-labels>
        </div>
      </div>
    </div>
  </div>

  <ng-template
    #selfCheckoutSaleDocLineComponent
    let-docLineForm
    let-i="index"
    let-isExpanded="isExpanded"
    let-alwaysNoSelected="alwaysNoSelected"
  >
    <taku-sale-doc-self-checkout-list-card
      #docLineComponent
      [formGroup]="docLineForm"
      [lineIndex]="i"
      [isSelected]="i == selectedLineIndex && !alwaysNoSelected"
      [storeId]="storeIdCtrl.value"
      [docSource]="customerSourceCtrl.value"
      [accountId]="_selectedAccount?.ID"
      [taxAccountCategoryId]="taxAccountCategoryIdCtrl.value"
      [saleDoc]="_myForm.value"
      (deleted)="deleteInvoiceLine(i, docLineForm)"
      (qtyChanged)="onLineQtyChanged($event, docLineForm)"
      [returnReasons]="returnReasons"
      [isExpanded]="isExpanded"
      [currencyIsoCode]="documentCurrency"
      (selected)="onLineClick(i)"
      (viewSelfCheckoutItemDetails)="openProductDetailsFromCart($event, true)"
      (lineExpansion)="onLineToggleExpansion($event, docLineComponent, i)"
      (isInMobile)="(true)"
      [disabled]="userReadyToPay"
      [inDesktopMainPageCart]="false"
    >
    </taku-sale-doc-self-checkout-list-card>
  </ng-template>

  <ng-template #selfCheckoutAddOnItemComponent let-docLineForm let-i="index" let-docLinesForm="saleDocLines">
    <taku-self-checkout-addon-list-card
      #addOnItemComponent
      [_myForm]="docLineForm"
      [cartDocLinesForm]="docLinesForm"
      (viewSelfCheckoutItemDetails)="openProductDetailsFromCart($event, true, true)"
      (addItemToCart)="addToSaleDocFromAddOns($event)"
      (deleteItemFromCart)="deleteInvoiceLine($event)"
    >
    </taku-self-checkout-addon-list-card>
  </ng-template>

  <div [hidden]="true">
    <ng-template #printPreviewHost></ng-template>
  </div>

  <p-confirmDialog
    #cd
    appendTo="body"
    key="declineTransaction"
    [style]="{ width: '300pt', maxWidth: 'calc(100vw - 18pt)' }"
    [closable]="false"
  >
    <ng-template pTemplate="footer">
      <button type="button" pButton label="Back to Cart" (click)="returnToCart(); cd.hide()"></button>
      <button type="button" pButton label="Pay at Counter" (click)="payAtCounterClicked(); cd.hide()"></button>
      <button type="button" pButton label="Try Card Again" (click)="onGreenBtnClicked(); cd.hide()"></button>
    </ng-template>
  </p-confirmDialog>
</div>
