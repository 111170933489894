<div *ngIf="useMobileDesign">
  <!-- <code>** Mobile Design activated**</code> -->
  <taku-sale-invoice-doc-mobile #invoiceDocComponent></taku-sale-invoice-doc-mobile>
</div>

<div *ngIf="useTabletDesign">
  <!-- <code>** Tablet Design activated**</code> -->
  <taku-sale-invoice-doc-mobile #invoiceDocComponent></taku-sale-invoice-doc-mobile>
</div>

<div *ngIf="useDesktopDesign">
  <!-- <code>** Desktop Design activated**</code> -->
  <taku-sale-invoice-doc-desktop #invoiceDocComponent></taku-sale-invoice-doc-desktop>
</div>
