/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Input, SimpleChanges, OnChanges, HostBinding } from "@angular/core";
import { ValidationMessagesService } from "../../shared/services/validation-messages.service";
import { isEmpty } from "lodash";
import { ValidationErrors } from "@angular/forms";

@Component({
  selector: "taku-objectmodel-validation-messages",
  templateUrl: "./objectmodel-validation-messages.component.html",
  styleUrls: ["./objectmodel-validation-messages.component.scss"],
})
export class ObjectmodelValidationMessagesComponent implements OnChanges {
  @Input() objectErrors: Record<string, ValidationErrors>;
  // @Input() severity = 'error';
  @Input() fieldsCaptions?: { [field: string]: string };
  @HostBinding("class.is-empty") get isEmpty() {
    return isEmpty(this.objectErrors);
  }
  @Input() expanded = false;

  // _severityClassStyle = '';
  _errorsMessages: { field: string; errorMessages: string }[];
  constructor(private validationMessagesService: ValidationMessagesService) {}

  ngOnChanges(changes: SimpleChanges) {
    // this._isExpanded = false;
    // this._severityClassStyle = this.mapSeverityCSSClass();
    this.validationMessagesService.formControlErrorsToString(this.objectErrors, this.fieldsCaptions);
  }

  // private mapSeverityCSSClass(): string {
  //     switch (this.severity) {
  //         case 'warning': return 'p-messages-warn';
  //         default: return 'p-message-error';
  //     }
  // }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
