<div id="tape-cashout-slip">
  <header>
    <section *ngIf="store" id="retailer-info">
      <div class="retailer-logo-wrapper">
        <img
          *ngIf="businessLogoUrl"
          [attr.src]="businessLogoUrl"
          [attr.alt]="businessDetail.businessName"
          style="max-width: 130px"
        />
        <span *ngIf="!businessLogoUrl" class="retailer-logo-placeholder">YOUR LOGO HERE</span>
      </div>

      <h2 class="retailer-name">{{ store.storeName }}</h2>

      <div *ngIf="store.address" class="retailer-address">
        <div class="address-line1">{{ store.address?.line1 }}</div>
        <div class="address-city"
          >{{ store.address?.city }}, {{ subdivisionDisplay(store.address?.subDivisionIsoCode) }}
          {{ _countryName | uppercase }} {{ store.address?.postalCode }}</div
        >
      </div>

      <div *ngIf="store.address?.addressPhone" class="retailer-phone">{{
        store.address?.addressPhone.fullPhoneNumber
      }}</div>
    </section>
  </header>

  <main>
    <section id="slip-details">
      <h1>CASHOUT REPORT</h1>
      <ul>
        <li>
          <span class="slip-field-label">Printed:</span>
          <span class="slip-field-contents">{{ _printedDate | dateTimeFormat : printedDate.toTimeString() }}</span>
        </li>
        <li *ngIf="cashout.closingDate">
          <span class="slip-field-label">Closing Date:</span>
          <span class="slip-field-contents">{{ cashout.closingDate | dateTimeFormat : cashout.closingTime }}</span>
        </li>
        <li *ngIf="closingUser">
          <span class="slip-field-label">Closing User:</span>
          <span class="slip-field-contents">{{ closingUser.person.fullName | uppercase }}</span>
        </li>
        <li>
          <span class="slip-field-label">Cashout ID:</span> <span class="slip-field-contents">{{ cashout.id }}</span>
        </li>
        <li>
          <span class="slip-field-label">Station ID:</span>
          <span class="slip-field-contents">{{ cashout.station.stationNumber }}</span>
        </li>
      </ul>

      <br />
      <table id="slip-documents">
        <tbody>
          <tr class="category-header">
            <th colspan="2" class="col-description">
              <div class="inner-wrapper"> DETAILS </div>
            </th>
            <th class="col-qty">
              <div class="inner-wrapper"> QTY </div>
            </th>
            <th class="col-total">
              <div class="inner-wrapper"> TOTAL{{ currencyIsoCode | currencySymbol }} </div>
            </th>
          </tr>
          <tr *ngFor="let doc of cashoutReportDetails">
            <td colspan="2" class="col-description">{{ doc.docType }}</td>
            <td class="col-qty">{{ doc.docCount }}</td>
            <td class="col-total">
              {{ doc.docType === "Customer Count" ? "" : (doc.docAverage | number : _digitsFormat) }}
            </td>
          </tr>
          <tr *ngFor="let doc of docsSummaries">
            <td colspan="2" class="col-description" [ngClass]="{ disabled: doc.state === 'Voided' }"
              >{{ doc.state === "Voided" ? "Voided " : "" }}{{ doc.docType }}</td
            >
            <td class="col-qty" [ngClass]="{ disabled: doc.state === 'Voided' }">{{ doc.count_doc }}</td>
            <td class="col-total" [ngClass]="{ disabled: doc.state === 'Voided' }">{{
              doc.grandTotal | number : _digitsFormat
            }}</td>
          </tr>
        </tbody>
      </table>

      <br />
      <table id="slip-documents">
        <tbody>
          <tr class="category-header">
            <th class="col-description">
              <div class="inner-wrapper">
                <span class="hide-deposit-label">DEPOSITS</span>
              </div>
            </th>
            <th class="col-qty">
              <div class="inner-wrapper">
                <span class="hide-deposit-label">QTY</span>
              </div>
            </th>
            <th class="col-system">
              <div class="inner-wrapper"><ng-template [ngIf]="showSystemValues">SYSTEM</ng-template></div>
            </th>
            <th class="col-total">
              <div class="inner-wrapper"> ACTUAL{{ currencyIsoCode | currencySymbol }} </div>
            </th>
          </tr>

          <tr *ngFor="let deposit of cashoutDeposits">
            <td class="col-description">{{ deposit.tenderDescription }}</td>
            <td class="col-qty">{{ deposit.count || 0 }}</td>
            <td class="col-system">
              <ng-template [ngIf]="showSystemValues">{{
                deposit.systemTotal || 0 | number : _digitsFormat
              }}</ng-template>
            </td>
            <td class="col-total">{{ deposit.actualAmount || 0 | number : _digitsFormat }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr class="total-row">
            <td class="col-description">Total Sales</td>
            <td class="col-qty">{{ totalQtyCashoutReport }}</td>
            <td class="col-system"
              ><ng-template [ngIf]="showSystemValues">{{
                totalSystemAmount | currency : currencyIsoCode : "symbol-narrow" : _digitsFormat
              }}</ng-template></td
            >
            <td class="col-total">{{
              totalActualAmount | currency : currencyIsoCode : "symbol-narrow" : _digitsFormat
            }}</td>
          </tr>
        </tfoot>
      </table>
      <br />
      <table id="slip-documents">
        <tbody>
          <tr class="category-header">
            <th class="col-description">
              <div class="inner-wrapper">
                <span class="hide-deposit-label">PAYOUTS</span>
              </div>
            </th>
            <th class="col-qty">
              <div class="inner-wrapper">
                <span class="hide-deposit-label">QTY</span>
              </div>
            </th>
            <th class="col-system">
              <div class="inner-wrapper"><ng-template [ngIf]="showSystemValues">SYSTEM</ng-template></div>
            </th>
            <th class="col-total">
              <div class="inner-wrapper"> ACTUAL{{ currencyIsoCode | currencySymbol }} </div>
            </th>
          </tr>

          <tr *ngFor="let payout of cashoutReportRefunds">
            <td class="col-description">{{ payout.docType }}</td>
            <td class="col-qty">{{ payout.docCount || 0 }}</td>
            <td class="col-system">
              <ng-template [ngIf]="showSystemValues">{{
                payout.systemAmount || 0 | number : _digitsFormat
              }}</ng-template>
            </td>
            <td class="col-total">{{ payout.actualAmount || 0 | number : _digitsFormat }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr class="total-row">
            <td class="col-description">Total Payouts</td>
            <td class="col-qty">{{ totalQtyPayout }}</td>
            <td class="col-system"
              ><ng-template [ngIf]="showSystemValues">{{
                totalSystemAmountPayout | currency : currencyIsoCode : "symbol-narrow" : _digitsFormat
              }}</ng-template></td
            >
            <td class="col-total">{{
              totalActualAmountPayout | currency : currencyIsoCode : "symbol-narrow" : _digitsFormat
            }}</td>
          </tr>
        </tfoot>
      </table>

      <br />
      <table id="slip-documents">
        <tbody>
          <tr class="category-header">
            <th class="col-description">
              <div class="inner-wrapper">
                <span class="hide-deposit-label">NET TENDERS</span>
              </div>
            </th>
            <th class="col-qty">
              <div class="inner-wrapper">
                <span class="hide-deposit-label">QTY</span>
              </div>
            </th>
            <th class="col-system">
              <div class="inner-wrapper"><ng-template [ngIf]="showSystemValues">SYSTEM</ng-template></div>
            </th>
            <th class="col-total">
              <div class="inner-wrapper"> ACTUAL{{ currencyIsoCode | currencySymbol }} </div>
            </th>
          </tr>
        </tbody>
        <tfoot>
          <tr class="total-row">
            <td class="col-description">Total Sales</td>
            <td class="col-qty">{{ totalQtyNetTender }}</td>
            <td class="col-system"
              ><ng-template [ngIf]="showSystemValues">{{
                totalSystemAmountNetTender | currency : currencyIsoCode : "symbol-narrow" : _digitsFormat
              }}</ng-template></td
            >
            <td class="col-total">{{
              totalActualAmountNetTender | currency : currencyIsoCode : "symbol-narrow" : _digitsFormat
            }}</td>
          </tr>
          <tr class="penny-rounding">
            <td class="col-description" colspan="3">Cash Rounding</td>
            <td class="col-total">{{
              totalPennyRounding | currency : currencyIsoCode : "symbol-narrow" : _digitsFormat | formatNegativeNumber
            }}</td>
          </tr>
          <tr *ngIf="cashout.closingNote">
            <td colspan="3">
              <div class="notes" style="text-align: left"> Notes: {{ cashout.closingNote }} </div>
            </td>
          </tr>
          <tr *ngIf="showSignatureLine">
            <td class="signature" colspan="4">
              <!-- <hr> -->
              <div class="text">Signature</div>
            </td>
          </tr>
        </tfoot>
      </table>

      <ng-template [ngIf]="showSalesReportSummary">
        <br />
        <table id="slip-documents">
          <tbody>
            <tr class="category-header">
              <th class="col-description">
                <div class="inner-wrapper">
                  <span class="hide-deposit-label">SALES SUMMARY</span>
                </div>
              </th>
              <th class="col-qty">
                <div class="inner-wrapper">
                  <span class="hide-deposit-label">QTY</span>
                </div>
              </th>
              <th class="col-system">
                <div class="inner-wrapper">SYSTEM</div>
              </th>
            </tr>

            <tr *ngFor="let salesSummary of cashoutReportSalesSummary">
              <td class="col-description">{{ salesSummary.docType }}</td>
              <td class="col-qty">{{ salesSummary.docCount || 0 }}</td>
              <td class="col-system">
                {{ salesSummary.systemAmount || 0 | number : _digitsFormat }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr class="total-row">
              <td class="col-description">Total Sales</td>
              <td class="col-qty"></td>
              <td class="col-system">
                {{ totalSystemSalesSummary | currency : currencyIsoCode : "symbol-narrow" : _digitsFormat }}
              </td>
            </tr>
          </tfoot>
        </table>

        <br />
        <table id="slip-documents">
          <tbody>
            <tr class="category-header">
              <th class="col-description">
                <div class="inner-wrapper">
                  <span class="hide-deposit-label">SALES TAXES</span>
                </div>
              </th>
              <th class="col-system">
                <div class="inner-wrapper">SYSTEM</div>
              </th>
            </tr>

            <tr *ngFor="let saleTax of cashoutReportSalesTax">
              <td class="col-description">{{ saleTax.docType }}</td>
              <td class="col-system">
                {{ saleTax.systemAmount || 0 | number : _digitsFormat }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr class="total-row">
              <td class="col-description">Total Taxes</td>
              <td class="col-system">
                {{ totalSystemTax | currency : currencyIsoCode : "symbol-narrow" : _digitsFormat }}
              </td>
            </tr>
            <tr class="total-row">
              <td class="col-description">Sales + Taxes</td>
              <td class="col-system">
                {{
                  totalSystemTax + totalSystemSalesSummary
                    | currency : currencyIsoCode : "symbol-narrow" : _digitsFormat
                }}
              </td>
            </tr>
          </tfoot>
        </table>
      </ng-template>

      <ng-template [ngIf]="showSalesCategories">
        <br />
        <table id="slip-documents">
          <tbody>
            <tr class="category-header">
              <th class="col-description">
                <div class="inner-wrapper">
                  <span class="hide-deposit-label">SALES CATEGORY</span>
                  <span>&nbsp;&#9432;</span>
                </div>
              </th>
              <th class="col-qty">
                <div class="inner-wrapper">
                  <span class="hide-deposit-label">SKU QTY</span>
                </div>
              </th>
              <th class="col-system">
                <div class="inner-wrapper">SYSTEM</div>
              </th>
            </tr>

            <tr *ngFor="let salesCategory of cashoutReportSalesCategory">
              <td class="col-description">{{ salesCategory.docType }}</td>
              <td class="col-qty">{{ salesCategory.docCount || 0 }}</td>
              <td class="col-system">
                {{ salesCategory.systemAmount || 0 | number : _digitsFormat }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr class="total-row">
              <td class="col-description">Total Sales</td>
              <td class="col-qty"></td>
              <td class="col-system">
                {{ totalSystemSalesCategory | currency : currencyIsoCode : "symbol-narrow" : _digitsFormat }}
              </td>
            </tr>
          </tfoot>
        </table>
        <span>&#9432;</span>
        Sales category system values exclude tender discounts
      </ng-template>
    </section>
  </main>
</div>
