/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Location } from "@angular/common";
import { Component } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { GenericFormComponent } from "../../../../../forms/generic-form/generic-form.component";
import { AlertMessagesService } from "../../../../../shared/services/alert-messages.service";
import { GoogleShoppingStore } from "./GoogleShoppingStore";
import { GoogleShoppingStoresService } from "../google-shopping-stores-list/google-shopping-stores.service";
import { AppSettingsStorageService } from "../../../../../shared/app-settings-storage.service";
import { AppComponent } from "src/app/app.component";

@Component({
  selector: "taku-google-shopping-store",
  templateUrl: "./google-shopping-store.component.html",
  styleUrls: ["./google-shopping-store.component.scss"],
})
export class GoogleShoppingStoreComponent extends GenericFormComponent {
  lookup_stores$ = this.dbService.get_lookup_stores();
  enum_targetCountry = this.dbService.enum_targetCountry;
  enum_contentLanguage = this.dbService.enum_contentLanguage;

  constructor(
    protected _router: Router,
    public fb: UntypedFormBuilder,
    public dbService: GoogleShoppingStoresService,
    protected location: Location,
    public _route: ActivatedRoute,
    protected messageService: MessageService,
    protected alertMessage: AlertMessagesService,
    protected confirmationService: ConfirmationService,
    appSettings: AppSettingsStorageService
  ) {
    super(_router, fb, dbService, location, _route, messageService, alertMessage, confirmationService, appSettings);
  }

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    const tmpModel = new GoogleShoppingStore();
    const tmpForm = fb.group(tmpModel);

    return tmpForm;
  }

  initForm() {
    this._model = "googleShoppingStore";
    this._object = new GoogleShoppingStore();
    this._myForm = GoogleShoppingStoreComponent.init(this.fb);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
