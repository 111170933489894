<div class="col-12">
  <div class="col-12">
    <taku-input [formGroup]="_formGroup" fieldName="inventoryVerificationContactName" fieldCaption="Contact Name">
    </taku-input>
  </div>

  <div class="col-12">
    <taku-input [formGroup]="_formGroup" fieldName="inventoryVerificationContactEmail" fieldCaption="Contact Email">
    </taku-input>
  </div>

  <div class="col-12">
    <taku-form-dialoge-header
      [pristine]="_formGroup.pristine"
      [valid]="_formGroup.valid"
      (saveClicked)="onSave()"
      (revertClicked)="onRevert()"
      (cancelClicked)="closeDialog()"
      [showDeleteBtn]="false"
    ></taku-form-dialoge-header>
  </div>
</div>
