/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Store } from "../store/store";
import { CashoutOpeningFloat } from "../settings-cashout-opening-float/cashout-opening-float";
import { CashDenomination } from "../../zone-settings/zone-denominations/cash-denomination";

export class StoreSettingCashout {
  id: number;
  tillCashoutClosingType: TillCashoutClosingType;
  cashCalculationType: CashCalculationType;
  presetOpeningFloatOption: PresetOpeningFloatOption;
  isIncludeFloatInPaymentBreakdown: boolean;
  isCashTenderType: boolean;
  storeId: number;
  store: Store;
  storeSettingCashoutOpeningFloats: CashoutOpeningFloat[];
  printsignitureLine: boolean;
  showSystemValue: boolean;
  showOverUnderValue: boolean;
  showSalesReportSummary: boolean;
  showSalesCategories: boolean;

  constructor(store?: Store, denominations?: CashDenomination[]) {
    this.id = 0;
    this.tillCashoutClosingType = TillCashoutClosingType.by_stations;
    this.cashCalculationType = CashCalculationType.by_actual_cash;
    this.presetOpeningFloatOption = PresetOpeningFloatOption.no_preset;
    this.isIncludeFloatInPaymentBreakdown = true;
    this.isCashTenderType = true;
    this.storeId = store ? store.id : 0;
    this.store = store || null;
    //TODO: Set field to blank when connected to server-side
    if (denominations) {
      this.storeSettingCashoutOpeningFloats = denominations.map((row) => new CashoutOpeningFloat(row.value));
    } else {
      this.storeSettingCashoutOpeningFloats = [];
    }
    this.printsignitureLine = true;
    this.showSystemValue = false;
    this.showOverUnderValue = true;
    this.showSalesReportSummary = false;
    this.showSalesCategories = false;
  }
}
export enum TillCashoutClosingType {
  by_store = "By Store",
  by_stations = "By Stations",
  // by_users = 'By Users'
}

export enum CashCalculationType {
  by_actual_cash = "By Actual Cash",
  by_remaining_cash = "By Remaining Cash",
}

export const CashCalculationTypeLabels = {
  by_remaining_cash: "By Cash for Deposit",
};

export enum PresetOpeningFloatOption {
  no_preset = "No Preset",
  next_float = "Next Float",
  default_values = "Default Values",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
