<ng-template [ngIf]="true" [formGroup]="_myForm">
  <td class="col-startDay">
    <taku-dropdown
      [formGroup]="_myForm"
      [options]="enum_week_days"
      fieldName="startDay"
      fieldCaption="Week Day"
    ></taku-dropdown>
  </td>

  <td class="col-endDay">
    <taku-dropdown
      [formGroup]="_myForm"
      [options]="enum_week_days"
      fieldName="endDay"
      fieldCaption="Week Day"
    ></taku-dropdown>
  </td>

  <td class="col-openTime">
    <taku-calendar-time [formGroup]="_myForm" fieldName="openTime" fieldCaption="Time"> </taku-calendar-time>
  </td>

  <td class="col-closeTime">
    <taku-calendar-time [formGroup]="_myForm" fieldName="closeTime" fieldCaption="Time"> </taku-calendar-time>
  </td>

  <td class="col-1 col-remove-btn">
    <a (click)="onRemoveBtnPressed.emit($event)">
      <i class="material-icons remove-button">remove_circle_outline</i>
    </a>
  </td>
</ng-template>
