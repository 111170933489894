/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Role } from "../../role/role";

export class RoleVisibility {
  id: number;
  feature: string;
  caption: string;
  isVisible: boolean;
  roleId: number;
  role: Role;
  isReadOnly: boolean;
  isEditable: boolean;

  constructor() {
    this.id = 0;
    this.feature = null;
    this.caption = null;
    this.isVisible = true;
    this.roleId = null;
    this.role = null;
    this.isReadOnly = false;
    this.isEditable = true;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
