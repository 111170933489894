/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { HttpErrorResponse } from "@angular/common/http";
import {
  Component,
  ContentChild,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  OnInit,
  Output,
  ReflectiveInjector,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
  OnDestroy,
  ViewChildren,
  QueryList,
  OnChanges,
  inject,
  AfterViewInit,
  NgZone,
} from "@angular/core";
import {
  AbstractControl,
  AsyncValidatorFn,
  FormArray,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
} from "@angular/forms";
import { Router } from "@angular/router";
import * as _ from "lodash";
import { MessageService } from "primeng/api";
import { FrozenColumn, Table, TableHeaderCheckbox } from "primeng/table";
import { ConfirmationService, SelectItem } from "primeng/api";
import { concat, isObservable, Observable, of, Subject, Subscription, throwError } from "rxjs";
import { catchError, debounceTime, distinctUntilChanged, map, tap } from "rxjs/operators";
import { DATA_ARRAY_TOKEN as DATA_ARRAY_TOKEN } from "src/app/forms/array-form/array-form.component";
import { DISPLAY_MODE_TOKEN, NestedFormDisplayMode } from "src/app/forms/nested-form/nested-form.component";
import { CloningService } from "src/app/shared/services/cloning.service";
import { AlertMessagesService } from "../../shared/services/alert-messages.service";
import { DataServiceInterface } from "../../shared/services/DataService.interface";
import { DBService } from "../../shared/services/db.service";
import { FormListColumnsService } from "../form-list-columns.service";
import { WebHelpers } from "../../utility/WebHelpers";
import {
  AutocompleteDatatypeOptions,
  Col,
  DataType,
  DefaultColsWidths,
  ExecuteEvent_DisplayMode,
  FilterType,
  FormListExportFormat,
  FormListStorageState,
  FrozenColsWidths,
  RowSelectionEvent,
  RowSelectionType,
  SettingsColumn,
  ValidityStatusChange,
} from "./form-list";
import { FormListModel } from "./form-list-model.interface";
import { FormListService } from "./form-list.service";
import { CommonValidators } from "src/app/shared/validators/CommonValidators";
import { MultiSelect } from "primeng/multiselect";
import { InvoiceKeyboardActionsService } from "src/app/core/document/sale-document/sale-invoice-doc-base/invoice-keyboard-actions.service";
import * as FileSaver from "file-saver";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { LocalDataService } from "src/app/shared/services/local-data.service";
import { FilterRows } from "src/app/utility/FormDataHelpers";
import { DialogService } from "primeng/dynamicdialog";

export type RowsFetchedEvent = { totalCount: number; objects: any[] };
export type RowsSavedEvent = { newValues: any[]; oldValues: any[] };

@Directive({
  selector: "[taku-formListDetailedView]",
})
export class TakuFormListDetailedViewDirective implements OnInit {
  component: any;
  constructor(private _view: ViewContainerRef) {}

  ngOnInit() {
    this.component =
      (this._view as any)._data && (this._view as any)._data.componentView
        ? (this._view as any)._data.componentView.component
        : null;
  }
}

@Directive({
  selector: "[takuFormListHeaderCustomBtns]",
})
export class TakuFormListHeaderCustomBtnsDirective implements OnInit {
  component: any;
  constructor(private _view: ViewContainerRef) {}

  ngOnInit() {
    const data = (<any>this._view)._data;
    this.component = data && data.componentView ? data.componentView.component : null;
  }
}

@Component({
  selector: "taku-form-list",
  templateUrl: "./form-list.component.html",
  styleUrls: ["./form-list.component.scss"],
})
export class FormListComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  subsList: Subscription[] = [];
  currSubscription: Subscription;
  currencyISOCode: string;

  protected readonly MobileBreakpoint = `${WebHelpers.MOBILE_BREAKPOINT}px`;
  static readonly DEFAULT_COLUMN_WIDTH = 200;
  _isMobile = WebHelpers.isMobileScreen();

  @Input() defaultSortField?: string; // Initial number of sort Field
  @Input() defaultSortOrder?: number; // Initial number of sort Order
  private _defaultSortFirstLoadUsed = false;
  @Input() _detailViewRoute: string;
  @Input() _model: string;

  /** "w" - it will show detail pages in new windows
   *  "d" - it will show detail page in dialog box
   *  "s" - it will show detail page in sidebar
   *  "n" - means form-list doesn't have any detail page
   */
  @Input() _detailMode: "w" | "d" | "s" | "n";
  @Input() _filter: FilterRows<any>; // if we are using this componenet that needs to have predefined filter we can pass the parameter
  @Input() _cols: Col[] = []; // the most important part is _cols array that it will be created in service of every compoenent.
  // we need to add 2 lines to form-list-columns.service.ts that is exlpaine the same file
  @Input() _settingsCol?: SettingsColumn;
  @Input() _viewColIcon?: string;
  @Input() _viewColIconName?: string;
  @Input() _hideViewCol? = false;
  @Input() _disableViewCol? = null;
  @Input() _headerInfo? = null;
  @Input() _hideFilterControls? = false;
  @Input() _viewColActionEvent?: EventEmitter<any> = null;
  @Input() _defaultRowValues?: {};
  @Input() _title: string;
  @Input() _showHeader = true;
  @Input() _defaultRows: number;
  @Input() _scrollHeight = "calc(100vh - 20rem)"; // Default table's contents height
  @Input() _rowSelectionType = RowSelectionType.MULTIPLE;
  @Input() _showDeleteCol = false;
  @Input() _disableDeleteRowButton?: (any) => boolean;
  @Input() _pageSizes: number[];
  @Input() _saveFormState = true; // Initial number of sort Order
  @Input() _stateKey: string = null; // optional state key
  @Input() _isEditable = true;
  @Input() _hideOptionsHeaderBtn = false; // Hide Options dropdown when is not desired in design
  @Input() _hideAddLineHeaderBtn = false; // Hide Options dropdown when is not desired in design
  @Input() _hideDeleteHeaderBtn = false; // Hide delete button when is not desired in design
  @Input() _hideGlobalFilter = false; // Hide delete button when is not desired in design
  @Input() _hideAddHeaderBtn = false; // Hide add button when is not desired in design
  @Input() _hideClone = false; // Hide add button when is not desired in design
  @Input() _requiredColsComeFirst = true;
  @Input() _dialogClosable = false;
  @Input() _dialogDismissable = false;
  @Input() _dialogStyleClass: string;
  @Input() _dialogShowHeader = true;
  @Input() _importMode = false;
  @Input() _modelValidation: { [key: string]: {} | Function[] };
  @Input() _modelAsyncValidation: { [key: string]: {} | Observable<any> };
  @Input("_modelService") _modelColsService: FormListModel;
  @Input() _newRowEvent: (n: any) => Observable<any>;
  @Input() _deleteRowEvent: (rows: any[]) => Observable<any>;
  @Input() _extraQueryParams = {};
  @Input() _dataService: DBService;
  @Input() _hideSavingCtrls = false;
  @Input() _showCtrlsInEditMode = false;
  @Input() _showFooterCurrency = false;
  @Input() _numFloatingValidationRows = 1;
  @Input() columnResizeMode: "fit" | "expand" = "expand";
  @Input() _disableSelectionWhenInvalid = false;
  @Input() _bulkEditorEnabled = true;
  @Input() _onCustomizeFetchFilter: (filter: any) => void;

  @Output() onRowsFetched = new EventEmitter<RowsFetchedEvent>();
  @Output() _onRowHighlighted: EventEmitter<any> = new EventEmitter();
  @Output() _onRowValidityChanged = new EventEmitter<ValidityStatusChange>();
  @Output() _onRowSelect = new EventEmitter<RowSelectionEvent>();
  @Output() _onRowUnselect: EventEmitter<any> = new EventEmitter();
  @Output() _onRowDeleted: EventEmitter<any> = new EventEmitter();
  @Output() _onNewRowSaved: EventEmitter<any> = new EventEmitter();
  @Output() _onRowSaved: EventEmitter<any> = new EventEmitter();
  @Output() _allRowsSaved = new EventEmitter<RowsSavedEvent>();
  @Output() _onRowsDiscarded = new EventEmitter<any[]>();
  // Emits value true if the form list loaded data successfully, otherwise return false
  @Output() onComplete = new Subject<boolean>();
  @Input() _detailQueryParams = {};
  @Input() beforeSaveAll?: (objects: any[], orgObjects: any[]) => Observable<boolean>;
  @Input() beforeDeleteRows?: (selectedObjects: any[]) => Observable<boolean>;

  DefaultColsWidths = DefaultColsWidths;
  FrozenColsWidths = FrozenColsWidths;
  RowSelectionType = RowSelectionType;
  ExecuteEvent_DisplayMode = ExecuteEvent_DisplayMode;
  _defaultRowsOrig;
  _formListModel: FormListModel;

  @ViewChild("dt", { static: true }) _dt: Table;
  @ViewChild("tableWrapper", { static: true }) _tableWrapper: ElementRef;
  @ViewChild("columnSelector") _colSelectorComponent: MultiSelect;
  @ViewChild("selectAllCheckBox") selectAllCheckBox: TableHeaderCheckbox;
  @ContentChild(TakuFormListDetailedViewDirective) _dialogDetailedViewDirective: any;
  @ContentChild(TakuFormListHeaderCustomBtnsDirective) _headerCustomBtnsDirective: any;
  @ViewChildren(FrozenColumn) _frozenColumns: QueryList<FrozenColumn>;

  tableFullTitle: string;
  _objects: any[];
  _orgObjects: any[];
  _orgRows: any[];
  _optionCols: any[] = [];
  _selectorColsChecked: Col[] = [];
  visibleScrollableCols: Col[] = [];

  displayDialog: boolean;
  displaySidebar: boolean;
  _selectedObjects: any[] | any = [];
  _selectedRow: any;
  _objectDialog: any;
  _modelDialog: string;
  _first = 0;
  // _object: any;
  _id: number;
  _inputFilterValues = {};
  totalRecords: number;
  active = false;
  _activeRow = -1;
  _newForm = true; // this shows if if it comes from dialog/sideBar or totally new page
  _error: HttpErrorResponse;
  _isFormInEditMode = false;
  _rowHeight = 47; // Fixed height of each row in pixels

  _isFetchingData = false;
  _elScrollableBody: HTMLElement;
  _hasLeftScrolling = false;
  _hasRightScrolling = !this._isMobile;
  _hasAnyScrolling = this._hasLeftScrolling || this._hasRightScrolling;
  _formGroups: FormArray = this.fb.array([]);
  _submitAttempted = false;
  _dataKey: string;
  _stateStorage: "session" | "local" = "local";
  private storage = localStorage;
  _dynamicFilterOptions: { [key: string]: SelectItem[] } = {}; // This store dynamic filter as they come, so we can compare later and avoid rebuild options each time angular change detection runs
  _dynamicFilterOptionsALL: { [key: string]: SelectItem[] } = {}; // This store the final filter including ALL option (for not filtering)
  _autocompleteSuggestions: { [key: string]: SelectItem[] } = {};
  _autocompleteValues: { [key: string]: SelectItem }[] | { [key: string]: SelectItem }[][] = [];
  _formArrayInjectors: Injector[][] = [];
  _scaleFactor = 1;
  _allVisibleCols: Col[] = [];
  _showFooter = false;
  _lookupSortableCols: SelectItem[] = [];
  _highlightedCol: Col;
  _cloneModeEnabled = false;
  _sortOrder = 1; // Initial number of sort Order
  _sortField = ""; // Initial value of sort Field

  /** This will fire when the formlist is visible in the viewport */
  private visibleInViewportIntersectionObserver: IntersectionObserver;

  /** This will fire after the datatable wrapper has been resized (after menu expands/collapses, or initial rendering) */
  private wrapperResizeSubject = new Subject<void>();
  private wrapperResizeObserver: ResizeObserver;

  /** This will fire after the datatable header has been resized (after loading) */
  private tableHeaderResizeSubject = new Subject<ResizeObserverEntry>();
  private tableHeaderResizeObserver: ResizeObserver;
  private tableHeaderHeight: number;

  private autoHighlightActive = false;
  private readonly _globalValidationKey = "$global";
  private dataFetchSubscription: Subscription;
  pageSizes: number[];
  _sortingForm: UntypedFormGroup;
  dialogService = inject(DialogService);
  private ngZone = inject(NgZone);

  constructor(
    protected _router: Router,
    protected dbService: DBService,
    protected confirmationService: ConfirmationService,
    protected messageService: MessageService,
    protected alertMessage: AlertMessagesService,
    protected formListService: FormListService,
    protected formListColumnsService: FormListColumnsService,
    protected fb: UntypedFormBuilder,
    private injector: Injector,
    protected cloningService: CloningService,
    protected keyboardActionsService: InvoiceKeyboardActionsService,
    private appSettingsService: AppSettingsStorageService,
    private localDataService: LocalDataService
  ) {}

  pageChange($event) {
    this._dt.resetScrollTop();
    window.scroll(0, 0);
  }

  isFunction(val) {
    return _.isFunction(val);
  }

  get defaultRows() {
    // Initial number of items per page
    return this._defaultRows || this.calculateDefaultRows(this._isMobile);
  }

  get defaultRowsOrig() {
    // Initial number of items per page
    return this._defaultRowsOrig || this.calculateDefaultRows(this._isMobile);
  }

  private calculateDefaultRows(isMobile) {
    return isMobile ? 5 : 10;
  }

  private calculateDefaultPageSizes(isMobile) {
    return [5, 10, 20].concat(isMobile ? [] : [100]);
    // return [5, 10, 20].concat(isMobile ? [] : [100]);
  }

  get tableBodyHeight(): string {
    if (this._scrollHeight === "full") {
      return null;
    }

    return `max(${this._scrollHeight}, 330px)`;
  }

  private _visibleColFilter(col: Col) {
    return col.visible || col.static;
  }

  private lockedColFilter(col: Col) {
    return col.frozen || col.static;
  }

  _getValidationMsgIndex(rowIndex: number, fieldName: string): number {
    let counter = 0;
    for (const loopField in this._getFormGroup(rowIndex).controls) {
      if (loopField == fieldName) {
        return counter;
      }

      if (this.isInvalidCell(rowIndex, loopField)) {
        counter++;
      }
    }

    return counter;
  }

  isValidationFloating(rowIndex) {
    return this._objects.length - rowIndex <= this._numFloatingValidationRows;
  }

  protected _getColByFieldName(field: string): Col {
    return this._cols.find((col) => col.field === field || col.fieldValue === field);
  }

  private _getFormControl(rowIndex: number, fieldName): AbstractControl {
    const formGroup = this._getFormGroup(rowIndex);
    if (!formGroup) return;

    return formGroup.controls[fieldName];
  }

  _getFormGroup(rowIndex: number): UntypedFormGroup {
    return this._formGroups.at(this.getRowOffset(rowIndex)) as UntypedFormGroup;
  }

  isInvalidCell(rowIndex, fieldName): boolean {
    let fieldCtrl;
    if (!(fieldCtrl = this._getFormControl(rowIndex, fieldName))) return false;

    return !fieldCtrl.valid && (this._submitAttempted || fieldCtrl.dirty);
  }

  isModifiedCell(rowIndex, fieldName): boolean {
    let fieldCtrl;
    if (!(fieldCtrl = this._getFormControl(rowIndex, fieldName))) return false;

    return fieldCtrl.dirty;
  }

  preventCommitCellOnEnter(keyEvent: KeyboardEvent): void {
    if (keyEvent.code === "Enter") {
      keyEvent.stopPropagation();
    }
  }
  private horizontalScrollListener = new Subject<number>();
  ngAfterViewInit(): void {
    this.visibleInViewportIntersectionObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // If a formlist is loaded in a container that is not visible in the DOM (like in a tab in the salescreen),
          // then the sticky columns may not have had their 'left' positioning set correctly, so refresh it when
          // the formlist becomes visible in the viewport
          this.refreshStickyColumns();
        }
      });
    });
    this.visibleInViewportIntersectionObserver.observe(this._dt.el.nativeElement);

    this._elScrollableBody = this._findScrollableBody();

    this.wrapperResizeObserver = new ResizeObserver(() => this.wrapperResizeSubject.next());
    this.wrapperResizeObserver.observe(this._elScrollableBody);
    this.subsList.push(
      this.wrapperResizeSubject.pipe(debounceTime(100)).subscribe(() => {
        this.ngZone.run(() => {
          this.setScrollingProperties();
        });
      })
    );

    this._elScrollableBody.addEventListener("scroll", () => {
      this.horizontalScrollListener.next(this._elScrollableBody.scrollLeft);
    });
    this.subsList.push(
      this.horizontalScrollListener
        .pipe(
          distinctUntilChanged()
          // TODO add this after figuring out why it takes so long to fire initially (too many setTimeouts firing in the event loop?)
          // debounceTime(30)
        )
        .subscribe(() => {
          this.setScrollingProperties();
        })
    );

    const innerTableHeader = this._dt.el.nativeElement.querySelector("thead.p-datatable-thead");

    this.tableHeaderResizeObserver = new ResizeObserver((entries) => this.tableHeaderResizeSubject.next(entries[0]));
    this.tableHeaderResizeObserver.observe(innerTableHeader);
    this.subsList.push(
      this.tableHeaderResizeSubject
        .pipe(
          map((entry) => entry.contentRect.height),
          distinctUntilChanged()
        )
        .subscribe((height) => {
          this.tableHeaderHeight = height;
        })
    );
  }

  /** This is used to set the height of the pseudoelement right-side scroll shadow. It uses a CSS custom property since it is otherwise hard to dynamically set properties on pseudoelements */
  get tableScrollHeight(): string {
    return `${(this._objects?.length || 0) * this._rowHeight + this.tableHeaderHeight}px`;
  }

  trackByColDef(index: number, item: Col): string {
    return item.field || item.header;
  }

  onColReorder(event: { columns: Col[]; dragIndex: number; dropIndex: number }): void {
    // pTable doesn't return frozen columns, so get the diff between lengths to properly do the column reorder
    const numFrozenCols = this._selectorColsChecked.length - event.columns.length;
    const movedCol = this._selectorColsChecked.splice(event.dragIndex + numFrozenCols, 1)[0];
    this._selectorColsChecked.splice(event.dropIndex + numFrozenCols, 0, movedCol);
  }

  /** This recalculates the 'style.left' value on sticky <th> and <td> elements */
  refreshStickyColumns(): void {
    this._frozenColumns.forEach((col) => {
      if (!this._isMobile && col.frozen) {
        col.updateStickyPosition();
      } else {
        // For some reason these properties are set by PrimeNG and not cleared for not-frozen columns, or when we've hit the mobile breakpoint
        // @ts-ignore we are accessing a TS private property
        col.el.nativeElement.style.removeProperty("left");
      }
    });
  }

  splitButtonDropdownClick() {
    if (!document.querySelector(".p-menu")) {
      setTimeout(() => {
        const pMenu = document.querySelector<HTMLElement>(".p-menu");
        const top = pMenu.style.top.replace("px", "");
        pMenu.style.top = `${Number(top) - 118}px`;
      });
    }
  }

  clearAllFilters() {
    this._dt.filteredValue = null;
    this._dt.filters = {};
    // Empty out form filters' input controls
    this.inputGlobalFilter = null;
    this._cols.forEach((row) => {
      this._inputFilterValues[row.field] = null;
    });
    this._dt.onLazyLoad.emit(this._dt.createLazyLoadMetadata());

    const formState = this.getFormState();
    if (formState && this._stateKey && this._saveFormState) {
      delete formState.filters;
      this.storage.setItem(this._stateKey, JSON.stringify(formState));
    }
  }

  clearAllSorting(): void {
    this._dt._sortField = undefined;
    this._dt._sortOrder = this._dt.defaultSortOrder;
    this._dt._multiSortMeta = undefined;
    this._dt.tableService.onSort(null);
    this._dt.onLazyLoad.emit(this._dt.createLazyLoadMetadata());

    const formState = this.getFormState();
    if (!_.isEmpty(formState) && this._stateKey && this._saveFormState) {
      delete formState.sortField;
      delete formState.sortOrder;
      this.storage.setItem(this._stateKey, JSON.stringify(formState));
    }
  }

  _buildOptionsForColSelector(): SelectItem[] {
    // const cols =  _.cloneDeep(this._cols);
    const sortedCols = this.formListColumnsService.sortColumnsForSelector(this._cols);

    return sortedCols
      .map((col) => ({
        label: this._importMode && col.importingOptions ? col.importingOptions.header : col.header,
        value: col,
        disabled: col.disabled || (col.field ? false : true) || col.frozen || col.static,
        icon: col.selectorIcon,
        disabled_new: this.lockedColFilter(col),
      }))
      .filter((item) => !!item.label);
  }

  _getInitColsCheckedForSelector(): Col[] {
    return this.colsFrozen.concat(this._colsScrollable().filter(this._visibleColFilter));
  }

  setScrollingProperties(): void {
    if (!this._elScrollableBody) {
      return;
    }
    this._hasLeftScrolling = this._elScrollableBody.scrollLeft > 0;
    this._hasRightScrolling =
      this._elScrollableBody.scrollLeft + this._elScrollableBody.clientWidth < this._elScrollableBody.scrollWidth;
    this._hasAnyScrolling = this._hasLeftScrolling || this._hasRightScrolling;
  }

  _findScrollableBody(): HTMLElement {
    return this._dt.el.nativeElement.querySelector(".p-datatable-wrapper");
  }

  onCellClicked(rowIndex) {
    this.highLightRow(rowIndex);
  }

  highLightRow(rowIndex) {
    this.autoHighlightActive =
      this._isFormInEditMode && this._objects[this.getRowOffset(rowIndex)][this._dataKey] === 0;
    if (this._importMode) {
      this.markRowAsPristine(this._activeRow); // remove validation errors from previous selected row
      this.markRowAsDirty(rowIndex); // activate validation error for current row
    }

    this._activeRow = rowIndex;
    if (this._isFormInEditMode) this._onRowHighlighted.emit(rowIndex);
  }

  isCellHighlighted(rowIndex) {
    return (
      this._isFormInEditMode &&
      ((rowIndex === 0 &&
        this._activeRow <= 0 &&
        this._objects[this.getRowOffset(rowIndex)] &&
        this._objects[this.getRowOffset(rowIndex)][this._dataKey] === 0 &&
        this.autoHighlightActive) ||
        this._activeRow === rowIndex)
    );
  }

  ngOnInit(): void {
    if (this._showFooterCurrency) this.currencyISOCode = this.appSettingsService.getZone().defaultCurrencyIsoCode;
    this._defaultRowsOrig = this._defaultRows;

    this._dt.isEditingCellValid = function () {
      return this.editingCell;
    };

    if (this.defaultSortField) {
      this._sortField = this.defaultSortField;
    }

    if (this.defaultSortOrder) {
      this._sortOrder = this.defaultSortOrder;
    }

    this._sortingForm = this.fb.group({
      sortField: null,
    });
    // NOTE: this only happens on mobile, as this is a dropdown field that only exists on mobile -
    // for selecting which column to sort by.
    this.subsList.push(
      this.sortFieldCtrl.valueChanges.subscribe((sortingField) => {
        this._sortField = sortingField;
        if (sortingField == null) this.clearAllSorting();
      })
    );
  }

  get sortFieldCtrl() {
    return this._sortingForm.get("sortField");
  }

  onSaveState(savedState): void {
    this.updateFormState((state) => {
      // if (state.filters && state.filters['global'])
      //   delete state.filters['global'];

      // We need to store in the session the state of the column chooser AGAIN
      // because it gets automatically removed whenever PrimeNG's update table state
      this.saveColChooserState(state);
    }, savedState);
  }

  private restoreFormState(changes: SimpleChanges) {
    let oldStateKey = this._stateKey;
    if (changes["_stateKey"]) oldStateKey = changes["_stateKey"].previousValue;
    else if (!this._stateKey && changes["_model"]) this._stateKey = "FL-" + this._model;

    if (!this._saveFormState) {
      this._stateKey = null;
      return {};
    }

    if (!this._stateKey.startsWith("FL-")) {
      this._stateKey = "FL-" + this._stateKey;
    }

    // restore state of columns
    // restore filter
    const formState = this.getFormState();
    formState["filters"] = formState["filters"] || {};

    // if we have a new state key, we must reset some state in the form list
    if (_.isEmpty(formState) || oldStateKey !== this._stateKey) {
      this._dt.filters = {};
      this._inputFilterValues = {};

      // set first no
      this._dt.first = this._first = formState?.first || 0;
      // set number of rows
      this._dt.rows = this._defaultRows =
        formState?.rows || changes["_defaultRows"]?.currentValue || this.calculateDefaultRows(this._isMobile);

      if (_.isEmpty(formState)) return null;

      // TODO (Kiko): Cleanup Logic: if the user passes in a new value here, it's going to set the sort fields to null, unless there is something in the state.
      // set sort field, unless this is the initial load of the form list, and the user of this component provided a value for the _sortField parameter.
      /**
       * If _sortField doesn't exist OR
       * If _sortField exists but doesn't have a currentValue OR
       * If _sortField exists but it's not the first change
       */
      const userProvidedDefaultSortFieldFirstLoad =
        changes["defaultSortField"]?.currentValue && changes["defaultSortField"].isFirstChange();
      if (!userProvidedDefaultSortFieldFirstLoad) {
        if (formState?.sortField) {
          this._dt.sortField = formState.sortField;
          this._sortField = formState.sortField;
        } else {
          this._sortField = this._dt.sortField || null;
        }
      }

      const userProvidedSortOrderFirstLoad =
        changes["_sortOrder"] && changes["_sortOrder"].currentValue && changes["_sortOrder"].isFirstChange();
      if (!userProvidedSortOrderFirstLoad) {
        if (formState?.sortOrder) {
          this._dt.sortOrder = formState.sortOrder;
          this._sortOrder = formState.sortOrder;
        } else if (this._dt.sortOrder) {
          this._sortOrder = this._dt.sortOrder;
        } else {
          this._dt.sortOrder = 1;
          this._sortOrder = 1;
        }
      }

      //////////////////////////////////////
      //  If we have any previous state  //
      ////////////////////////////////////

      if (!formState.saveFirst) {
        formState.first = this._first = this._dt.first = 0;
      }
      delete formState.saveFirst;
      this.storage.setItem(this._stateKey, JSON.stringify(formState));

      // set filters
      this._dt.filters = formState["filters"];
      for (const [field, filter] of Object.entries(formState["filters"])) {
        this._inputFilterValues[field] = filter["value"];
      }

      // restore active columns in chooser
      const columnOrder = formState.columnOrder;
      if (columnOrder) {
        let lastIndex = -1;
        columnOrder.forEach((colName) => {
          const actualIndex = this._cols.findIndex((col) => col.field === colName);
          if (actualIndex < 0) return;

          // the column is behind, so we have to move it next to the last index
          if (actualIndex < lastIndex) this._cols.splice(lastIndex++, 0, ...this._cols.splice(actualIndex, 1));
          else lastIndex = actualIndex;
        });
      }

      if (formState.columnWidths) {
        formState.columnWidths.split(",").forEach((colWidth, index) => {
          if (!this._cols[index]) return;

          this._cols[index].colWidth = parseInt(colWidth);
        });
        this._dt.restoreColumnWidths();
      }

      this.restoreColChooserState(formState);
    }

    return formState;
  }

  protected initColumnsWidths(): void {
    // Set default with to columns of certain types
    this._cols.forEach((col) => {
      col.colWidth =
        this.getColWidthDefinition(col) || DefaultColsWidths[col.dataType] || FormListComponent.DEFAULT_COLUMN_WIDTH;
    });
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: Event): void {
    const newIsMobile = WebHelpers.isMobileScreen();

    if (newIsMobile !== this._isMobile) {
      // Only update when there is a transition
      this._isMobile = newIsMobile;
      if (this._isMobile) {
        // Clear calculated width on table element in non-mobile view
        this._dt.setResizeTableWidth("");
      }
      this.pageSizes = this._pageSizes || this.calculateDefaultPageSizes(newIsMobile);
      this._dt.editingCell = null;
      this.visibleScrollableCols = this._getVisibleScrollableCols();
      this.updateVisibleCols();

      // Trigger p-table to update style sheets for mobile view
      this._dt.destroyResponsiveStyle();
      this._dt.createResponsiveStyle();

      // Fetch additional objects (ex: switching from mobile to desktop)
      if (this._objects.length < this.defaultRows && (this._dt.first || 0) + this.defaultRows < this._dt.totalRecords)
        setTimeout(() => {
          this.reloadData();
        });

      if (this._objects.length > this.defaultRows) this._objects.splice(this.defaultRows);

      this.setScrollingProperties();
      setTimeout(() => this.refreshStickyColumns());
    }
  }

  _getVisibleScrollableCols() {
    return this._selectorColsChecked.filter((col) => !col.frozen);
  }

  _checkIsMobile(viewportWidth: number): boolean {
    return viewportWidth <= WebHelpers.MOBILE_BREAKPOINT;
  }

  getDateTimeValue(obj, key, defaultValue = null) {
    return _.get(obj, key, defaultValue);
  }

  getValue(obj, key, defaultValue = null) {
    return _.get(obj, key, defaultValue);
  }

  setDateTimeValue(obj, key, val) {
    _.set(obj, key, val);
  }

  setValue(obj, key, val) {
    _.set(obj, key, val);
  }

  getArrValue(obj, key, dataOptions) {
    if (dataOptions) {
      return _.get(
        dataOptions.find((value) => {
          return value.value === _.get(obj, key);
        }),
        "label"
      );
    } else {
      return [];
    }
  }

  onMultiSelectChange(event, obj, key, dataKey, dataOptions) {
    // remove invalid values
    if (isObservable(dataOptions))
      this.subsList.push(
        dataOptions.subscribe((options: any[]) => {
          this.setValue(
            obj,
            key,
            (_.get(obj, key) || []).filter((row1) => {
              // returns only valid values
              return (
                row1[dataKey] &&
                options.filter((row) => (row1[dataKey] + "").indexOf(_.get(_.get(row, "value"), dataKey)) !== -1).length
              );
            })
          );
        })
      );
  }

  getMultiselectValue(obj, key, dataKey, dataOptions) {
    if (dataOptions) {
      return (_.get(obj, key) || [])
        .map((row1) => {
          if (row1[dataKey]) {
            const optionsVal = dataOptions
              .filter((row) => (row1[dataKey] + "").indexOf(_.get(_.get(row, "value"), dataKey)) !== -1)
              .map((row) => row.label);
            return _.get(optionsVal, "[0]", this._importMode ? row1 : null);
          } else if (dataKey) {
            return this._importMode ? row1 : null;
          } else {
            return row1;
          }
        })
        .filter((val) => val != null)
        .join(", ");

      // return dataOptions
      //   .filter(
      //     row =>
      //       (_.get(obj, key) || [])
      //         .map(row1 => row1[dataKey])
      //         .indexOf(_.get(_.get(row, 'value'), dataKey)) !== -1
      //   )
      //   .map(row => row.label)
      //   .join(', ');
    } else {
      return [];
    }
  }

  private _addALLFilterOption(options, label = "All"): SelectItem[] {
    const allFilterOptions = options
      ? [
          <SelectItem>{
            label: label,
            value: null,
          },
        ].concat(
          (Array.isArray(options) ? options.filter((option) => option.value != null) : options).map((row) => {
            const row1 = { ...row };
            // delete row1.disabled;
            return row1;
          })
        )
      : [];

    // By default, we add 'All' filter options to dropdown in formlist.
    // By passing options value with 'removeAll', we remove 'All' filter in dropdown
    // For reference, refer to the inventory.service.ts with dataOptions: [{ label: '', value: 'removeAll' }
    if (Array.isArray(options) && options[0]?.value == "removeAll") {
      return allFilterOptions.splice(2, 2);
    }

    return allFilterOptions;
  }

  // Watch any change to any of the @Input() properties, and class state properties
  ngOnChanges(changes: SimpleChanges): void {
    this.pageSizes = this._pageSizes || this.calculateDefaultPageSizes(this._isMobile);

    if (changes["_modelColsService"] && this._modelColsService) {
      this._modelColsService._formListComponent = this;
      if (!changes["_cols"]) this._cols = this._modelColsService.getFormListColumns();
      if (!changes["_modelValidation"]) this._modelValidation = this._modelColsService.getValidationRules();
      if (!changes["_modelAsyncValidation"])
        this._modelAsyncValidation = this._modelColsService.getAsyncValidationRules
          ? this._modelColsService.getAsyncValidationRules()
          : {};
    } else if (changes["_model"]) {
      if (!changes["_cols"]) this._cols = this.formListColumnsService.getDefaultColumns(this._model);
      if (!changes["_modelValidation"]) this._modelValidation = this.formListColumnsService.getValidation(this._model);
      if (!changes["_modelAsyncValidation"])
        this._modelAsyncValidation = this.formListColumnsService.getAsyncValidation(this._model);
    }

    if (changes["_model"]) {
      if (!changes["_modelColsService"])
        this._modelColsService = this.formListColumnsService.getColumnsService(this._model);
      if (!changes["_dataService"])
        this._dataService = this.formListColumnsService.getDbService(this._model) || this.dbService;
      this._dataKey = this._dataService.dataKey;
      // If _detailViewRoute wasn't specified fallback to model's default view
      if (!changes["_detailViewRoute"]) this._detailViewRoute = this._model;
    }

    if (changes["_defaultRows"]) this._defaultRowsOrig = this._defaultRows;

    if (changes["_cols"] || changes["_modelColsService"] || changes["_model"]) {
      // Cols definition has changes or new, so rebuild filter options
      this._highlightedCol = null;
      this._cols = this.formListColumnsService.formatColumnsHeader(
        this._cols,
        this._modelValidation,
        this._requiredColsComeFirst
      );
      this._cols.forEach((col) => {
        const filterOptions = col.filterOptions || col.dataOptions;
        if (isObservable(filterOptions)) {
          col.filterOptions = filterOptions.pipe(map((options: SelectItem[]) => this._addALLFilterOption(options)));
        } else if (!_.isFunction(filterOptions)) {
          col.filterOptions = this._addALLFilterOption(filterOptions);
        }

        // col.filterOptions = col.filterOptions || filterOptions;
        // this._filtersOptions[col.field] = filterOptions;
      });
    }

    this.initColumnsWidths();
    this.tableFullTitle = this._title || _.startCase(this._model);
    this._inputFilterValues["global"] = null;
    this._cols.forEach((row) => {
      this._inputFilterValues[row.field] = null;
    });

    // this._frozenWidth = this.calculateFrozenWidth();

    this.active = false;
    this.disableEditMode();

    this._objects = [];

    if (!this._newForm) {
      this.active = false;
    } else {
      this.active = true;
    }

    this._optionCols = this._buildOptionsForColSelector();
    this._selectorColsChecked = this._getInitColsCheckedForSelector();
    this.visibleScrollableCols = this._getVisibleScrollableCols();
    this.updateVisibleCols();
    // this._frozenWidth = this.calculateFrozenWidth();

    // Validated-related stuff
    this._setValidations();

    const myFormState = this.restoreFormState(changes);
    let params;

    if (myFormState) {
      params = myFormState;

      // Ensure filters property exists on params
      params.filters = params.filters || {};
    } else {
      // it seems like this never happens. A state is always being set; as "null" state key, if not explicitly provided.
      this._defaultRows = this._defaultRowsOrig;
      params = { filters: {}, first: 0, rows: this.defaultRows };
    }

    const paramsFiltersEmpty = Object.keys(params.filters).length == 0;

    // Should automatically prefilled filter controls with input filter if they are present in column service
    if (changes["_filter"] && this._filter)
      Object.entries(this._filter).forEach(([filterField, filterOptions]) => {
        if (paramsFiltersEmpty) params.filters[filterField] = filterOptions;

        if (
          this._cols.map((col) => col.field).includes(filterField) &&
          ["equals", "contains"].includes(filterOptions.matchMode)
        )
          this._inputFilterValues[filterField] = filterOptions.value;
      });
    if (this._modelColsService && this._modelColsService.headerInfo) {
      this._headerInfo = this._modelColsService.headerInfo() || this._headerInfo;
    }
    this.localDataService._newObject$.subscribe((data) => (this._orgRows = data));
    setTimeout(() => void this.loadObjectsLazy(params), 0);
  }

  get hasActiveValidationErrors(): boolean {
    for (const formGroup of this.getAllFormGroups()) {
      for (const name in formGroup.controls) {
        const control = formGroup.controls[name];
        if (control.invalid && control.dirty) return true;
      }
    }
    return false;

    // return this._dt.editingCell &&
    //        this._dt.domHandler.find(this._dt.editingCell, '.ng-invalid.ng-dirty').length !== 0;
  }

  _buildRowFormGroup(object): UntypedFormGroup {
    const formData = _.mapValues(
      _.keyBy(this._cols, (col) => col.field),
      (col, field) => _.get(object, field)
    );
    // Put arrays inside another array in order to avoid Angular to treat value as control definition
    _.forOwn(formData, (value, key) => {
      if (Array.isArray(value)) formData[key] = [value];
    });
    // add object key: id for remote data
    formData[this._dataKey] = object[this._dataKey];

    const formGroup = this.fb.group(formData);
    // apply validation to every formgroup
    // set global validation
    let hasValidators, hasAsyncValidators;
    if ((hasValidators = this._modelValidation[this._globalValidationKey]))
      formGroup.setValidators(<ValidatorFn[]>this._modelValidation[this._globalValidationKey]);

    if ((hasAsyncValidators = this._modelAsyncValidation[this._globalValidationKey]))
      formGroup.setAsyncValidators(
        <AsyncValidatorFn | AsyncValidatorFn[]>this._modelAsyncValidation[this._globalValidationKey]
      );

    this.subsList.push(
      formGroup.valueChanges.subscribe((newValue) => {
        // clean stale error on child controls if there are global (formgroup level) validators active
        if (hasValidators || hasAsyncValidators)
          Object.keys(formGroup.controls).forEach((key) => {
            formGroup.get([key]).updateValueAndValidity({ onlySelf: true, emitEvent: false });
          });
      })
    );

    // set field-specific validation
    this._cols.forEach((col) => {
      const fieldName = col.field;
      const control: AbstractControl = formGroup.controls[fieldName];

      if (!control) return;

      const ctrlValidators = _.get(this._modelValidation, fieldName);
      const ctrlAsyncValidators = _.get(this._modelAsyncValidation, fieldName);
      this.applyValidatorsToCtrl(formGroup, control, ctrlValidators);
      this.applyAsyncValidatorsToCtrl(formGroup, control, ctrlAsyncValidators);

      // Allow to customize the form group control of the current column/field in order
      // to setup things like dynamic validation
      if (col.setupFormControl) col.setupFormControl.setupControl(control, formGroup, col);

      // check for global validation affecting current control
      this.subsList.push(
        control.valueChanges.subscribe((newValue) => {
          // check regular validators
          if (formGroup.validator) control.setErrors(formGroup.validator(formGroup));

          // check async validators
          if (formGroup.asyncValidator) {
            // We need to create a new formGroup instance, because current one always has old value
            const updateFormGroup = _.cloneDeep(formGroup);
            // Update form with new value and avoid emitting additional events
            updateFormGroup.patchValue(
              {
                [fieldName]: newValue,
              },
              { emitEvent: false }
            );
            const asyncValidator = formGroup.asyncValidator(updateFormGroup);
            if (isObservable(asyncValidator)) {
              if (this.currSubscription) this.currSubscription.unsubscribe();

              this.currSubscription = asyncValidator.subscribe((validationErrors) => {
                if (validationErrors) control.setErrors(validationErrors);
              });
            }
          }
        })
      );
    });

    this.subsList.push(
      formGroup.statusChanges.subscribe((newStatus) => {
        this._onRowValidityChanged.emit({
          rowIndex: this.findObjectIndex(object),
          object: this.updateObjWithFormData(object, formGroup),
          status: newStatus,
        });
      })
    );

    return formGroup;
  }

  private enhanceValidators(formGroup, validators) {
    // regular validators
    if (Array.isArray(validators))
      return validators.map((validator) => {
        if (_.isFunction(validator))
          return (formCtrl) => {
            return validator(formCtrl, formGroup, this._formGroups);
          };
        else return validator;
      });
    else if (_.isFunction(validators))
      return (formCtrl) => {
        return validators(formCtrl, formGroup, this._formGroups);
      };
    else return validators;
  }

  protected applyAsyncValidatorsToCtrl(formGroup: UntypedFormGroup, control: AbstractControl, asyncValidators) {
    asyncValidators = this.enhanceValidators(formGroup, asyncValidators);
    if (Array.isArray(asyncValidators) || _.isFunction(asyncValidators)) control.setAsyncValidators(asyncValidators);
    else control.clearAsyncValidators();
  }

  protected applyValidatorsToCtrl(formGroup: UntypedFormGroup, control: AbstractControl, validators) {
    validators = this.enhanceValidators(formGroup, validators);
    if (Array.isArray(validators) || _.isFunction(validators))
      control.setValidators(<ValidatorFn | ValidatorFn[]>validators);
    else control.clearValidators();
  }

  updateObjWithFormData(rowObject: any, formGroup: UntypedFormGroup): any {
    const updatedObj = _.cloneDeep(rowObject);
    _.forOwn(formGroup.value, (value, key) => {
      if (_.has(updatedObj, key)) _.set(updatedObj, key, value);
    });

    return updatedObj;
  }

  _setValidations(): any {
    this._submitAttempted = false;
    if (this._dt) {
      this._dt.editingCell = null;
    }
    this.getAllFormGroups().length = 0;
    // Creates formgroups, one per object
    this._objects.forEach((object) => {
      const tmpForm = this._buildRowFormGroup(object);
      this._formGroups.push(tmpForm);
    });
  }

  showDialog(object, modelName = this._model) {
    this._objectDialog = object;
    this._modelDialog = modelName;
    this.displayDialog = true;
    this.keyboardActionsService._canAnnounceNumKeyPress = false;
  }

  showSidebar(object, modelName = this._model) {
    this._objectDialog = object;
    this._modelDialog = modelName;
    this.displaySidebar = true;
    this.keyboardActionsService._canAnnounceNumKeyPress = false;
  }

  goSettingsScreen(rowData): void {
    const id = rowData["id"];
    const settingsLink = this._settingsCol.routeLink.replace(":id", id);
    this._router.navigate([settingsLink], { queryParams: this._settingsCol.routeQueryParams });
  }

  goEdit(selectedRow: any): void {
    const formState = this.getFormState();
    if (formState && this._stateKey && this._saveFormState) {
      formState.saveFirst = true;
      this.storage.setItem(this._stateKey, JSON.stringify(formState));
    }

    this._selectedRow = selectedRow;

    if (this._viewColActionEvent) {
      this._viewColActionEvent.emit(selectedRow);
      return;
    }

    if (this._selectedRow !== undefined) {
      this.openDetailedView(this._selectedRow);
    } else {
      // TODO: whatif _selectedObject is undefined?? is it an error?
    }
  }

  getObjectDialog(object): Observable<any> {
    if (object) {
      if (_.isNumber(object)) return this._dataService.getRow(this._model, object);
      else return this._dataService.getRow(this._model, object[this._dataKey]);
    } else {
      const newRow = this.formListService.newRow(this._model, this._defaultRowValues);
      return of(newRow);
    }
  }

  openDetailedView(selectedRow) {
    this._id = selectedRow.id;
    const objectId = _.isNumber(selectedRow) ? selectedRow : selectedRow.id;

    if (objectId != 0) {
      switch (this._detailMode) {
        case "w": {
          this._router.navigate([this._detailViewRoute + "/" + objectId], { queryParams: this._detailQueryParams });
          this.dialogService.dialogComponentRefMap.forEach((dialogRef) => dialogRef?.destroy());
          break;
        }
        case "d": {
          this.subsList.push(
            this.getObjectDialog(selectedRow).subscribe((dialogObject) => {
              this.showDialog(dialogObject);
            })
          );
          break;
        }
        case "s": {
          this.subsList.push(
            this.getObjectDialog(selectedRow).subscribe((dialogObject) => {
              this.showSidebar(dialogObject);
            })
          );
          break;
        }
        case "n": {
          break;
        }
        default: {
        }
      }
    }
  }

  goAdd(): void {
    this._id = 0;
    // this._selectedRow = this.formListService.newRow(this._modelNewRow, this._defaultRowValues);
    this._selectedRow = null;

    switch (this._detailMode) {
      case "w": {
        // Temp change
        // this._router.navigate([this._detailViewRoute + '/0']);
        this._router.navigate([this._detailViewRoute + "/0"], {
          queryParams: { defaultdataValues: JSON.stringify(this._defaultRowValues) },
        });
        break;
      }
      case "d": {
        this.subsList.push(
          this.getObjectDialog(null).subscribe((dialogObject) => {
            this.showDialog(dialogObject);
          })
        );
        break;
      }
      case "s": {
        this.subsList.push(
          this.getObjectDialog(null).subscribe((dialogObject) => {
            this.showSidebar(dialogObject);
          })
        );
        break;
      }
      default: {
      } // TODO: what is the default for this switch?
    }
  }

  protected _deleteAccepted(deletingObjects: any[], silentMode = false) {
    // Actual logic to perform a confirmation
    const _ids: number[] = [];
    deletingObjects.forEach((deletingObject) => {
      if (deletingObject[this._dataKey] === 0) {
        this._removeRow(deletingObject);
      } else {
        _ids.push(deletingObject[this._dataKey]);
      }
    });
    // this.reloadData();
    this.subsList.push(
      this._dataService.batchDeleteRows(this._model, _ids.reverse()).subscribe(
        (results) => {
          results.map((_result) => {
            if (_result.response.result.success) {
              this._removeRow(deletingObjects.find((row) => row[this._dataKey] === _result.batchId));
              this.messageService.add(this.alertMessage.getMessage("delete-success"));
            } else {
              if (!silentMode)
                this.messageService.add(this.alertMessage.getErrorMessage(_result.response.result, "", ""));
            }
          });
          this.reloadData();
        },
        (error: HttpErrorResponse) => {
          // this.processServerError(error, deletingObject);
          if (!silentMode) this.messageService.add(this.alertMessage.getErrorMessage(error, "", ""));
        },
        () => {
          // if (!silentMode)
          //   this.messageService.add(
          //     this.alertMessage.getMessage('delete-success')
          //   );
        }
      )
    );
  }

  _removeRow(deletingObject) {
    const index = this.findObjectIndex(deletingObject);

    this._objects.splice(index, 1);
    this._orgObjects.splice(index, 1);
    this._formGroups.removeAt(index);

    delete this._autocompleteValues[index];
    delete this._formArrayInjectors[index];
    // Send message that row was deleted
    this._onRowDeleted.emit(deletingObject);
  }

  deleteRowsAction(deletingObjects: any[], silentConfirmation = false) {
    if (deletingObjects.length === 0) return;

    const fnDeleteAction = () => {
      const deleteRows$ = this._deleteRowEvent ? this._deleteRowEvent(deletingObjects) : of(deletingObjects);
      this.subsList.push(
        deleteRows$.subscribe((rowsToDelete) => {
          if (rowsToDelete) {
            this._deleteAccepted(rowsToDelete, silentConfirmation);
            this.unSelectAllRows();
          }
        })
      );
    };

    if (silentConfirmation) {
      fnDeleteAction();
    } else {
      // this._id = this._selectedObjects['id'];
      this.keyboardActionsService._canAnnounceNumKeyPress = false;
      this.confirmationService.confirm({
        message: "Are you sure that you want to delete this record?",
        icon: "ui-icon-delete",
        rejectButtonStyleClass: "p-button-link",
        accept: () => {
          this.keyboardActionsService._canAnnounceNumKeyPress = true;
          fnDeleteAction();
        },
        reject: () => {
          this.keyboardActionsService._canAnnounceNumKeyPress = true;
        },
      });
    }
  }

  deleteSelectedRows() {
    if (this.beforeDeleteRows) {
      this.beforeDeleteRows(this._selectedObjects).subscribe((result) => {
        if (result) {
          this.deleteRowsAction(this._selectedObjects);
        }
      });
    } else {
      this.deleteRowsAction(this._selectedObjects);
    }
  }

  selectAllRows() {
    this._selectedObjects = this._objects;
  }

  unSelectAllRows() {
    this._selectedObjects = [];
  }

  discardAll() {
    this._objects = this._orgObjects.map((orgObject) => {
      const _object = this._objects[this.findObjectIndex(orgObject)];
      delete _object["batchId"];
      return _.assign(_object || {}, _.cloneDeep(orgObject));
    });
    this._autocompleteValues = [];
    this._formArrayInjectors = [];

    this._setValidations();
    // this._objects = this._orgObjects.map(
    //   (row) => Object.assign({}, row)
    // );
    setTimeout(() => {
      this.disableEditMode();
    }, 0);
    this.messageService.add(this.alertMessage.getMessage("discard-all-success"));

    this._onRowsDiscarded.emit(this._objects);
    this.unSelectAllRows();
    this._formGroups.markAsPristine();
  }

  get areAllFieldsValid() {
    for (const formGroup of this.getAllFormGroups()) {
      if (formGroup.invalid) {
        return false;
      }
    }

    return true;
  }

  saveAll(silent = false) {
    if (this.beforeSaveAll) {
      this.beforeSaveAll(this._objects, this._orgObjects).subscribe((result) => {
        if (result) {
          this.saveAllFn(this._dataService, silent);
        } else {
          // this.disableEditMode();
          // this._formGroups.forEach(formGroup => formGroup.markAsPristine());
        }
      });
    } else {
      this.saveAllFn(this._dataService, silent);
    }
  }

  saveAllFn(_dataService: DBService, silent = false, queryParams = {}): void {
    this._submitAttempted = true;
    // Check for errors before attempting to save, do nothing if errors found
    if (!this.areAllFieldsValid) {
      this.messageService.add(this.alertMessage.getMessage("invalid-formlist-records"));
      return;
    }

    // set to true for progress bar to appear
    this._isFetchingData = true;

    // find all rows that are edited and put to server and if fine replace response with both _object and _orgObject
    const observableBatches = [];
    const _editingObjects = [];
    const _addingObjects = [];

    this._objects.forEach((row, index) => {
      if (row.id === 0) {
        row.batchId = index;
        _addingObjects.push(row);
      } else {
        if (
          !_.isEqual(
            row,
            this._orgObjects.find((orgRow) => orgRow.id === row.id)
          )
        ) {
          row.batchId = index;
          _editingObjects.push(row);
        }
      }
    });

    if (_addingObjects.length > 0) {
      observableBatches.push(
        _dataService.batchAddRows(this._model, _addingObjects.reverse(), queryParams).pipe(
          catchError((errorResponse: HttpErrorResponse) => {
            // this.processServerError(errorResponse, row);
            return throwError(errorResponse);
          }),
          tap((myResults: any) => {
            myResults.map((_myResult) => {
              const formGroup = this._formGroups.at(_myResult.batchId);
              if (_myResult.response.result.body) {
                formGroup.get("id").setValue(_myResult.response.result.body.id);
              }
              this.restoreRowValidators(this._objects[_myResult.batchId]);
            });
          }),
          map(
            (myResults: any) => {
              myResults.map((_myResult) => {
                if (_myResult.response.result.success) {
                  const row = Object.assign(
                    {},
                    this.formListService.newRow(this._model),
                    _myResult.response.result.body
                  );
                  Object.assign(this._objects[_myResult.batchId], row);
                  this._orgObjects.unshift(_.cloneDeep(this._objects[_myResult.batchId]));
                  this._onNewRowSaved.emit(_myResult.response.result.body);
                  this._onRowSaved.emit(_myResult.response.result.body);
                } else {
                  this.processServerError(_myResult.response.result, this._objects[_myResult.batchId]);
                }
              });
            },
            (error) => {
              this.messageService.add(this.alertMessage.getErrorMessage(error, "", ""));
            }
          )
        )
      );
    }
    if (_editingObjects.length > 0) {
      // Editing rows
      observableBatches.push(
        _dataService.batchEditRows(this._model, _editingObjects.reverse(), queryParams).pipe(
          catchError((errorResponse) => {
            // this.processServerError(errorResponse, row);
            return throwError(errorResponse);
          }),
          tap((myResults) => {
            myResults.map((_myResult) => {
              this.restoreRowValidators(this._objects[_myResult.batchId]);
            });
          }),
          map(
            (myResults) => {
              myResults.map((_myResult) => {
                if (_myResult.response.result.success) {
                  const row = Object.assign(
                    {},
                    this.formListService.newRow(this._model),
                    _myResult.response.result.body
                  );
                  Object.assign(this._objects[_myResult.batchId], row);
                  Object.assign(
                    this._orgObjects[this._orgObjects.findIndex((orgRow) => orgRow.id === row.id)],
                    _.cloneDeep(this._objects[_myResult.batchId])
                  );
                  this._onRowSaved.emit(_myResult.response.result.body);
                } else {
                  this.processServerError(_myResult.response.result, this._objects[_myResult.batchId]);
                }
              });
            },
            (error) => {
              this.messageService.add(this.alertMessage.getErrorMessage(error, "", ""));
            }
          )
        )
      );
    }

    if (observableBatches.length === 0) {
      this.disableEditMode();
      this._isFetchingData = false;
      this._submitAttempted = false;
      return;
    }

    const oldRowValues = _.cloneDeep(this._orgObjects);
    const _formList = this;
    this.subsList.push(
      concat(...observableBatches).subscribe({
        next: (val) => {
          if (_.isEqual(_.sortBy(_formList._objects, "id"), _.sortBy(_formList._orgObjects, "id"))) {
            this.disableEditMode();
            this._submitAttempted = false;
            this._allRowsSaved.emit({ newValues: _formList._objects, oldValues: oldRowValues });

            if (!silent) this.messageService.add(this.alertMessage.getMessage("multi-edit-success"));
            this._isFetchingData = false;
          }
        },
        error: (errorResponse) => {
          this.messageService.add(this.alertMessage.getErrorMessage(errorResponse));
          this._isFetchingData = false;
        },
        complete: () => {
          this.getAllFormGroups().forEach((formGroup) => formGroup.markAsPristine());
          this._isFetchingData = false;
        },
      })
    );
  }

  processServerError(errorResponse: HttpErrorResponse, row: number) {
    const rowIndex = this.findObjectIndex(row);
    const errorsInfo = errorResponse.error || <any>errorResponse;
    if (!errorsInfo) return;

    // switch(errorsInfo.name){
    //   case "SequelizeUniqueConstraintError":
    //     const suffixErrorRegexp = /_UNIQUE$/;
    //     Object.keys(errorsInfo.fields).forEach(key => {
    //       const newKey = key.replace(suffixErrorRegexp, "");
    //       errorsInfo.fields[newKey] =  errorsInfo.fields[key];
    //       delete errorsInfo.fields[key];
    //     });
    //     errorsInfo.errors.forEach(theError => theError.path = theError.path.replace(suffixErrorRegexp, ""))
    //   break;
    // }
    const formGroup = this._formGroups.at(rowIndex) as UntypedFormGroup;

    if (!Array.isArray(errorsInfo.errors)) {
      if (errorsInfo.message != undefined || errorsInfo.message != "") {
        this.messageService.add(this.alertMessage.getErrorMessage(errorsInfo, "Error", null));
      }
      return;
    }

    errorsInfo.errors.forEach((theError) => {
      if (theError.validatorKey === "not_unique") {
        // Sanitize unique validation messages
        const suffixErrorRegexp = /_UNIQUE/;
        theError.message = (<string>theError.message).replace(suffixErrorRegexp, "");
        theError.path = (<string>theError.path).replace(suffixErrorRegexp, "");
      }

      let ctrlName = theError.path;
      let formControl = formGroup.get(ctrlName);
      if (!formControl) {
        ctrlName = _.findKey(formGroup.controls, (ctrl, key) => {
          return key.includes(ctrlName);
        });
        if (ctrlName) formControl = formGroup.controls[ctrlName];
      }
      if (!formControl) return;

      const errorMessage = _.capitalize(_.startCase(theError.message).toLowerCase());
      const serverValidator = CommonValidators.serverValidator(formControl.value, errorMessage, theError, ctrlName);
      let ctrlValidators = _.get(this._modelValidation, ctrlName);
      if (Array.isArray(ctrlValidators)) ctrlValidators.push(serverValidator);
      else ctrlValidators = [ctrlValidators, serverValidator].filter(Boolean);

      this.applyValidatorsToCtrl(formGroup, formControl, ctrlValidators);
      formControl.updateValueAndValidity();
      // formControl.setErrors({
      //   'server-error': { errorName: theError.message, errorData: theError, fields: ctrlName }
      // })
    });
  }

  closeDialog() {
    this.displayDialog = false;
    this.keyboardActionsService._canAnnounceNumKeyPress = true;
  }

  closeSidebar() {
    this.displaySidebar = false;
    this.keyboardActionsService._canAnnounceNumKeyPress = true;
  }

  _formChanged(event) {
    this.displayDialog = false;
    this.displaySidebar = false;

    if (this._modelDialog && this._modelDialog !== this._model)
      // if model in dialog is of different model, stop here
      return;
    if (event.constructor.name === "Object") {
      // if models are differents, then create row object type based on detailed view object
      const rowObject = event;
      if (!this._selectedRow) {
        rowObject._uniqueid = _.uniqueId();
        this._objects = [rowObject, ...this._objects];
        const tmpForm = this._buildRowFormGroup(rowObject);
        this._formGroups.insert(0, tmpForm);
        if (this._objects.length > this._dt.rows) {
          this._objects.pop();
          if (this._formGroups.length > 0) {
            this._formGroups.removeAt(this._formGroups.length - 1);
          }
        }
        this.totalRecords++;
        this._orgObjects = _.cloneDeep(this._objects);
      } else {
        Object.assign(
          this._objects[
            this._objects
              .map((object) => {
                return object.id;
              })
              .indexOf(event.id)
          ],
          rowObject
        );
      }

      // if (this._id === 0) {
      //   this._objects = [event, ...this._objects];
      // } else {
      //   Object.assign(
      //     this._objects[
      //     this._objects.map(object => object.id).indexOf(this._selectedRow.id)
      //     ],
      //     event
      //   );
      // }
    } else if (event === "Deleted") {
      this._objects.splice(this._objects.map((object) => object.id).indexOf(this._selectedRow.id), 1);
      this._objects = this._objects.slice();
    } else if (event.constructor.name !== "Event") {
      this.loadObjectsLazy({
        filters: this._filter,
        first: 0,
        rows: this.defaultRows,
      });
    }
  }

  prepareCols(_object: any) {
    this._cols = [];
    Object.keys(_object).forEach((key) => {
      this._cols.push(new Col(key));
    });
  }

  get colsFrozen() {
    // if (this._isMobile) { return []; }

    if (this._cols) {
      return this._cols.filter((col) => col.frozen);
    } else {
      return [];
    }
  }

  _colsScrollable() {
    if (this._cols) {
      return this._cols.filter((col) => !col.frozen);
    } else {
      return [];
    }
  }

  getRowOffset(rowIndex: number) {
    const firstRow = this._dt ? this._dt.first : 0;
    return rowIndex >= firstRow ? rowIndex - firstRow : rowIndex;
  }

  // Overload function to convince TS that this function can be subscribed to with | async pipe
  _dataOptions(col: Col, rowData: any): SelectItem[] | Observable<SelectItem[]>;
  _dataOptions(col: Col, rowData: any, isAsync: true): Observable<SelectItem[]>;
  _dataOptions(col: Col, rowData: any, isAsync = false) {
    if (_.isFunction(col.dataOptions)) {
      const opts = rowData ? col.dataOptions(rowData, this._objects, this._orgObjects) : [];
      return isAsync ? of(opts) : opts;
    } else {
      return col.dataOptions;
    }
  }

  _dataTypeOptions(col, icon) {
    if (_.isFunction(icon)) return col.dataTypeOptions(icon);
    else return icon;
  }

  _filterOptions(col: Col) {
    if (_.isFunction(col.filterOptions)) {
      const newOptions = col.filterOptions(this._inputFilterValues);
      const prevOptions = this._dynamicFilterOptions[col.field];
      if (newOptions != prevOptions && !_.isEqual(newOptions, prevOptions)) {
        this._dynamicFilterOptions[col.field] = newOptions;
        this._dynamicFilterOptionsALL[col.field] = this._addALLFilterOption(newOptions);
        // as we build a new set of options, we should reset associated filter values and filter again
        this._inputFilterValues[col.field] = null;
        if (this._dt) this._dt.filter(null, col.filterField || col.field, col.filterType);
      }
      return this._dynamicFilterOptionsALL[col.field];
    } else {
      return col.filterOptions;
    }
  }

  // This code prevents not having any column select/shown in the formlist
  columnListChange(event?) {
    // Make sure disabled columns are always checked
    this._optionCols
      .filter((col) => col.disabled_new)
      .forEach((col) => {
        if (!this._selectorColsChecked.find((checkCol) => checkCol.field == col.value.field))
          this._selectorColsChecked.push(col.value);
      });

    this.visibleScrollableCols = this._getVisibleScrollableCols();
    this.updateVisibleCols();
    setTimeout(() => this.refreshStickyColumns());
  }

  newRowPressed() {
    const newObject = this.formListService.newRow(this._model, this._defaultRowValues);
    const observableNewRow = this._newRowEvent ? this._newRowEvent(newObject) : of(newObject);
    this.subsList.push(
      observableNewRow.subscribe((newObject) => {
        this.addNewRow(newObject);
      })
    );
  }

  addNewRow(newObject, prepend = true) {
    newObject._uniqueid = _.uniqueId();
    this.subsList.push(
      this._dataService.addRowTmp(this._model, newObject).subscribe(() => {
        const tmpForm = this._buildRowFormGroup(newObject);
        if (prepend) {
          this._objects.unshift(newObject);
          this._formGroups.insert(0, tmpForm);
        } else {
          this._objects.push(newObject);
          this._formGroups.push(tmpForm);
        }

        this.enableEditMode();
        this.autoHighlightActive = true;
        // guarantee that new row is visible by scrolling to the top
        const scrollableTableBodyEls: NodeList =
          this._dt.el.nativeElement.querySelectorAll(".p-datatable-scrollable-body");
        for (let i = 0; i < scrollableTableBodyEls.length; i++) {
          const bodyContainer = <HTMLElement>scrollableTableBodyEls[i];
          bodyContainer.scrollTop = 0;
        }
      })
    );
  }

  findObjectIndex(theObject) {
    return this._objects.findIndex((currObject) => {
      return currObject === theObject || currObject[this._dataKey] === theObject[this._dataKey];
    });
  }

  onColSelectorOpened() {
    const nodes = this._colSelectorComponent.el.nativeElement.querySelectorAll("li.p-multiselect-item");
    for (let i = 0; nodes[i]; i++) {
      const node = nodes[i] as HTMLElement;
      if (this._colSelectorComponent.options[i].disabled_new) {
        node.classList.add("colselector-disabled");
      } else {
        node.classList.remove("colselector-disabled");
      }
    }
  }

  onRowSelected(event) {
    this._onRowSelect.emit({
      data: event.data,
      rowIndex: event.index,
    });
  }

  onRowUnselected(event) {
    this._onRowUnselect.emit(event.data);
  }

  get areControlsEnabled() {
    return !this._formGroups.dirty && (!this._isFormInEditMode || this._showCtrlsInEditMode);
  }

  getObjects() {
    return this._objects;
  }

  editRows(fnEditObject: Function) {
    this._objects.forEach((object) => fnEditObject(object));
  }

  editSelectedRows(fnEditObject: Function) {
    this._selectedObjects.forEach((object) => fnEditObject(object));
  }

  isValid(): boolean {
    for (const formGroup of this.getAllFormGroups()) {
      if (formGroup.invalid) return false;
    }

    return true;
  }

  isPristine(): boolean {
    for (const formGroup of this.getAllFormGroups()) {
      if (formGroup.dirty) return false;
    }

    return true;
  }

  markAllAsPristine() {
    for (const formGroup of this.getAllFormGroups()) {
      formGroup.markAsPristine();
    }
  }

  get hasSystemColumns(): boolean {
    // When form list doesn't have neither View Details column, nor settings and select row column, that means there are not system columns activated
    return (
      (!this._hideViewCol && this._detailMode !== "n") ||
      !!this._settingsCol ||
      this._rowSelectionType !== RowSelectionType.NONE
    );
  }

  autoSelectInput(event, col: Col) {
    if (!this._isFormInEditMode || ![DataType.input, DataType.number].includes(col.dataType)) return;

    if (event.target instanceof HTMLInputElement) {
      event.target.select();
    } else {
      const parentCell = event.target.closest("td");
      if (!parentCell) return;

      setTimeout(() => {
        const inputField = parentCell.querySelector("input");
        if (inputField) inputField.select();
      }, 0);
    }
  }

  // getHighlightedRowData(){
  //   if (!this._highlightActive)
  //     return null;

  //   return this._objects.find((object, index) => this.isCellHighlighted(index))
  // }

  getRowErrors(rowIndex: number): Record<string, ValidationErrors> {
    // const highlightedRow = this.getHighlightedRowData();
    // if (!highlightedRow)
    //   return null;

    // const rowIndex = this.findObjectIndex(highlightedRow);
    const formGroup = this._getFormGroup(rowIndex);
    if (!formGroup) return {};

    const rowErrors: Record<string, ValidationErrors> = {};
    for (const controlName in formGroup.controls) {
      let controlErrors;
      formGroup.controls[controlName].updateValueAndValidity({ emitEvent: false });
      if ((controlErrors = formGroup.controls[controlName].errors)) rowErrors[controlName] = controlErrors;
    }
    return rowErrors;
  }

  clearAllRows() {
    this._objects = [];
    this._formGroups.clear();
  }

  getColumnHeader(col: Col) {
    const base = this._importMode && col.importingOptions ? col.importingOptions : col;
    return base.headerHTML ? base.headerHTML : base.header;
  }

  getFieldCaptions(): Record<string, string> {
    const fieldCaptions = {};
    this._cols.forEach((col) => {
      fieldCaptions[col.field] = this._importMode && col.importingOptions ? col.importingOptions.header : col.header;
    });

    return fieldCaptions;
  }

  markRowAsDirty(rowIndex) {
    const formGroup = this._getFormGroup(rowIndex);
    if (!formGroup) return {};

    for (const controlName in formGroup.controls) {
      formGroup.controls[controlName].markAsDirty();
    }
  }

  markRowAsPristine(rowIndex) {
    const formGroup = this._getFormGroup(rowIndex);
    if (!formGroup) return {};

    for (const controlName in formGroup.controls) {
      formGroup.controls[controlName].markAsPristine();
    }
  }

  private getColExportVal(cellVal: any, col: Col, exportFormat: FormListExportFormat = FormListExportFormat.CSV) {
    if (cellVal == null || cellVal == "") return 0;

    if (col.dataType === DataType.array) {
      return `${this.arrayType_rowContents(cellVal[0] || null, cellVal, col)}`;
    } else if (
      col.dataType === DataType.number &&
      (exportFormat === FormListExportFormat.CSV || exportFormat === FormListExportFormat.EXCEL)
    ) {
      if (typeof cellVal === "number") {
        return cellVal;
      }
      // Reformats prices that are displayed as strings with currencies, e.g. CAD1.23 or (CAD1.23)
      const numericValue = cellVal.replace(/[^0-9.-]/g, "");
      // If it was a negative number (formatted with brackets), add the negative sign back
      if (cellVal.startsWith("(") && cellVal.endsWith(")")) {
        return "-" + numericValue;
      }
      return numericValue;
    } else {
      return cellVal;
    }
  }

  tableExportFn = (event) => {
    const col = this._getColByFieldName(event.field);
    return this.getColExportVal(event.data, col);
  };

  onExportRows(exportFormat: FormListExportFormat) {
    const colsWidth = _.sum(this._cols.map((col) => col.colWidth));
    const exportCols = this._cols.map((col) => col.header);
    const exportData = (this._orgRows ?? this._objects).map((row) => {
      return _.mapValues(
        _.mapKeys(this._cols, (col) => col.header),
        (col) => this.getColExportVal(_.get(row, col.fieldValue || col.field), col, exportFormat)
      );
    });

    this.messageService.add({
      severity: "success",
      summary: "Export",
      detail: "TAKU is generating the export file",
      life: 3000,
    });

    switch (exportFormat) {
      case FormListExportFormat.CSV:
        // this.exportCSV();
        this.customExportCSV(exportData);
        break;

      case FormListExportFormat.PDF:
        this.exportPdf(exportCols, exportData, colsWidth);
        break;

      case FormListExportFormat.EXCEL:
        this.exportExcel(exportData);
        break;
    }
  }

  private exportCSV() {
    this.visibleScrollableCols = this._cols;
    this.visibleScrollableCols.forEach((col) => {
      if (col.fieldValue) {
        [col.field, col.fieldValue] = [col.fieldValue, col.field];
      }
    });

    setTimeout(() => {
      this._dt.exportCSV();
      this.visibleScrollableCols.forEach((col) => {
        if (col.fieldValue) {
          [col.field, col.fieldValue] = [col.fieldValue, col.field];
        }
      });
      this.visibleScrollableCols = this._getVisibleScrollableCols();
    }, 0);
  }

  getColWidth(col: Col) {
    return this.getColWidthDefinition(col) || DefaultColsWidths[col.dataType] || FormListComponent.DEFAULT_COLUMN_WIDTH;
  }

  getColSummary(col: Col) {
    if (!col.footer || this._objects.length === 0) {
      return 0;
    } else {
      return this._objects
        .map((row) => Number((row[col.field] + "").replace(/[^0-9.-]/g, "")))
        .reduce((a, b) => {
          return a + b;
        });
    }
  }

  private getColWidthDefinition(col: Col) {
    const importingColWidth = col.importingOptions ? col.importingOptions.colWidth : null;
    if (this._importMode) return importingColWidth || col.colWidth;
    else return col.colWidth;
  }

  get modelNamePrettyPrint() {
    return _.startCase(this._model);
  }

  onAutocompleteSearch($event, col: Col, rowData) {
    if (col.dataTypeOptions) {
      const transformer = (<AutocompleteDatatypeOptions>col.dataTypeOptions).transformer;
      this.subsList.push(
        (<AutocompleteDatatypeOptions>col.dataTypeOptions)
          .completeMethod($event, rowData)
          .subscribe((rawSuggestions) => {
            if (rawSuggestions)
              this._autocompleteSuggestions[col.field] = rawSuggestions.map((suggestion) =>
                transformer.toAutocompleteItem(suggestion)
              );
          })
      );
    }
  }

  getAutocompleteValue(rowIndex: number, rowData, col: Col): SelectItem {
    if (!this._autocompleteValues[rowIndex]) this._autocompleteValues[rowIndex] = {};

    const rowValue = this._autocompleteValues[rowIndex];
    if (!rowValue[col.field] || (col.dataTypeOptions.isMultiple && !rowValue[col.field].length)) {
      const value = this.getValue(rowData, col.field);
      const transformer = (<AutocompleteDatatypeOptions>col.dataTypeOptions).transformer;
      let storeValue;
      if (!value) {
        storeValue = null;
      } else if (col.dataTypeOptions.isMultiple && Array.isArray(value)) {
        storeValue = value.map((singleValue) => transformer.toAutocompleteItem(singleValue));
      } else {
        storeValue = transformer.toAutocompleteItem(value);
      }
      rowValue[col.field] = storeValue;
    }

    return rowValue[col.field];
  }

  getAutocompleteCaptions(rowIndex: number, rowData, col: Col) {
    const value = this.getAutocompleteValue(rowIndex, rowData, col);
    const items: any[] = Array.isArray(value) ? value : [value];
    return items.map((item) => (item ? item.label : "")).join(", ");
  }

  setAutocompleteValue(rowIndex: number, rowData, col, selectItem) {
    const rowValue = this._autocompleteValues[rowIndex];
    const transformer = (<AutocompleteDatatypeOptions>col.dataTypeOptions).transformer;
    let convertedValue;
    if (!selectItem) {
      convertedValue = null;
    } else if (col.dataTypeOptions && col.dataTypeOptions.isMultiple && Array.isArray(selectItem)) {
      convertedValue = [];
      for (const singleItem of selectItem) {
        if (!singleItem.label || !singleItem.value) return false;

        convertedValue.push(transformer.fromAutocompleteItem(singleItem));
      }
    } else {
      if (!selectItem.label || !selectItem.value) return false;
      convertedValue = transformer.fromAutocompleteItem(selectItem);
    }

    if (rowValue && rowValue[col.field]) rowValue[col.field] = selectItem;

    this.setValue(rowData, col.field, convertedValue);
  }

  autocompleteSuggestions(col: Col): SelectItem[] {
    if (!this._autocompleteSuggestions[col.field]) this._autocompleteSuggestions[col.field] = [];

    return this._autocompleteSuggestions[col.field];
  }

  setFieldValue(rowData: any, fieldName: string, value: any) {
    _.set(rowData, fieldName, value);
    const index = this.findObjectIndex(rowData);
    if (index >= 0) {
      this._formGroups.at(index).get(fieldName).setValue(value);
    }
  }

  updateRowValidation(rowData: any) {
    const index = this.findObjectIndex(rowData);
    if (index >= 0) {
      this._formGroups.at(index).setValue(rowData);
    }
  }

  async loadObjectsLazy(event) {
    if (this.dataFetchSubscription) {
      this.dataFetchSubscription.unsubscribe();
      this.dataFetchSubscription = null;
    }

    const filter = {};
    let orFilter = {};

    if (!_.isEmpty(this._filter)) {
      _.merge(filter, this._filter);
    }

    // Filters coming from the event (dropdown UI) should have priority and override input filters
    if (!_.isEmpty(event.filters)) {
      _.merge(filter, event.filters);
    }

    let globalFilter;
    if ((globalFilter = filter["global"])) {
      // global filter has been set, and it PrimeNG uses 'global' as field name
      orFilter = _.merge(
        orFilter,
        await this.dbService
          .createOrFilter(this._allVisibleCols, globalFilter.value, globalFilter.matchMode)
          .toPromise()
      );
      //delete filter['global'];
    }

    for (const [field, filterContent] of Object.entries(filter)) {
      const col = this._getColByFieldName(field);
      if (col && col.filterType === FilterType.compound) {
        orFilter = _.merge(
          orFilter,
          await this.dbService
            .createOrFilter(col.children, filterContent["value"], filterContent["matchMode"], field)
            .toPromise()
        );
        delete filter[field];
      }
    }

    // Remove empty object if no filters are set
    if (!Object.keys(orFilter).length) {
      orFilter = null;
    }

    // Clear all filters that has been nullify by the dropdowns the user has selected
    // TODO: Fix this, has problems with pagination

    this.clearUnusedFilterParams(filter);
    // Allow for customize fetch filter before issuing request
    if (_.isFunction(this._onCustomizeFetchFilter)) this._onCustomizeFetchFilter(filter);

    let sortCol: Col;
    let sortField = event.sortField || undefined;
    if ((sortCol = this._getColByFieldName(event.sortField))) {
      if (sortCol.sortFields && sortCol.sortFields.length) sortField = sortCol.sortFields.join(",");
    }

    if (!sortField) {
      sortField = !this._defaultSortFirstLoadUsed ? this._sortField : this._dt._sortField;
    }

    let sortOrder = event.sortOrder;
    if (!sortOrder) {
      sortOrder = !this._defaultSortFirstLoadUsed ? this._sortOrder : this._dt._sortOrder;
    }

    this._isFetchingData = true;
    this._selectedObjects = null;
    this._defaultSortFirstLoadUsed = true;
    this.dataFetchSubscription = this._dataService
      .getRows(
        this._model,
        JSON.stringify(_.omit(filter, "global")),
        event.first,
        // event.first + event.rows,
        event.rows,
        sortField,
        sortOrder,
        this._extraQueryParams,
        orFilter
      )
      .subscribe(
        (result) => {
          this._objects = result.rows.map((row) => {
            const emptyTemplate = this.formListService.newRow(this._model);
            return _.defaults(_.merge(emptyTemplate, row), {
              _uniqueid: _.uniqueId(), // Add unique id to new rows fetched from server
            });
          });
          // reset autocomplete values cache
          this._autocompleteValues = [];
          this._formArrayInjectors = [];
          // deep copy data from _objects to _orgObjects

          this._orgObjects = _.cloneDeep(this._objects);
          // this._orgObjects = this._objects.map(
          //   (row) => Object.assign({}, row)
          // );

          this.totalRecords = result.count;
          this._setValidations();

          this.onRowsFetched.emit({
            totalCount: this.totalRecords,
            objects: this._objects,
          });

          // if (this._newForm)
          this.onComplete.next(true);
        },
        (error: HttpErrorResponse) => {
          this._error = error;
          this._isFetchingData = false;
          if (this._newForm) this.onComplete.next(false);
        },
        () => {
          this._newForm = false;
          // this.active = false;
          this.active = true;
          if (this._cols.length === 0) {
            this.prepareCols(this._objects[0]);
          }
          // this._optionCols = this._buildOptionsForColSelector();
          // this._selectorColsChecked = this._getInitColsCheckedForSelector();
          // this._frozenWidth = this.calculateFrozenWidth();
          // this.zone.runOutsideAngular( () => this._setValidations());
          this._isFetchingData = false;

          // Fixes column reorder bug when changing model, column definition and after first-time load
          setTimeout(() => {
            this.changeVisibleColumns(this._selectorColsChecked);
            this.visibleScrollableCols = this._getVisibleScrollableCols();
            this.updateVisibleCols();
            this.refreshStickyColumns();
          }, 0);
        }
      );
  }

  private shouldRemoveMultiSelectArraySearchParam(searchFieldArrayValue: unknown[]): boolean {
    if (searchFieldArrayValue.length == 0) {
      return true;
    }

    if (searchFieldArrayValue.length == 1 && searchFieldArrayValue[0] == null) {
      return true;
    }

    return false;
  }

  /**
   * Clears empty filters from filter object automatically.
   * If a search filter is empty, a typical user would expect that to mean "All", or "No filter",
   * and that can only be achieved by completely removing the empty search filter field from the filter object.
   */
  clearUnusedFilterParams(filter: Record<string, unknown>): void {
    Object.entries(filter).forEach(([key, match]: [string, any]) => {
      const shouldRemoveMultiSelectArraySearchParam = Array.isArray(match.value)
        ? this.shouldRemoveMultiSelectArraySearchParam(match.value)
        : false;
      const hasEmptyValueAndNullNotAllowed = !match.allowNull && !_.isBoolean(match.value) && !match.value;
      const shouldRemoveFilterKey = hasEmptyValueAndNullNotAllowed || shouldRemoveMultiSelectArraySearchParam;

      if (shouldRemoveFilterKey) {
        delete filter[key];
      }
    });
  }

  reloadData(): void {
    this._dt.onLazyLoad.emit(this._dt.createLazyLoadMetadata());
  }

  enableEditMode(): void {
    this._isFormInEditMode = true;
    this._isFetchingData = false;
  }

  disableEditMode(): void {
    this._isFormInEditMode = false;
    this._cloneModeEnabled = false;
  }

  changeVisibleColumns(visibleCols: Col[]): void {
    const sortedCols = [];
    // Sort columns according to input column definition
    this._cols.forEach((col) => {
      const foundCol = col.field ? visibleCols.find((col2) => col2.field === col.field) : col;
      if (foundCol) sortedCols.push(foundCol);
    });

    this._selectorColsChecked = sortedCols;
    if (this._stateKey) {
      this.updateFormState((state) => {
        this.saveColChooserState(state);
      });
    }
    setTimeout(() => this.setScrollingProperties());
  }

  private saveColChooserState(state: FormListStorageState) {
    // save checked/selected columns in chooser
    state["chooserActiveCols"] = this._selectorColsChecked.map((col) => col.field);
  }

  private updateFormState(fnUpdateState: (state: FormListStorageState) => void, state = this.getFormState()) {
    fnUpdateState(state);
    // set filter for the initial change
    state.filters = this._dt.filters;
    for (const [field, filter] of Object.entries(this._dt.filters)) {
      this._inputFilterValues[field] = filter["value"];
    }
    if (this._stateKey && this._saveFormState) {
      this.storage.setItem(this._stateKey, JSON.stringify(state));
    }
  }

  private getFormState(): FormListStorageState {
    return JSON.parse(this.storage.getItem(this._stateKey)) || {};
  }

  private restoreColChooserState(state: FormListStorageState) {
    const chooserCols = state.chooserActiveCols;
    if (chooserCols) {
      const allActiveCols = _.union(
        this._cols.filter((col) => this.lockedColFilter(col)).map((col) => col.field),
        chooserCols
      );
      this._selectorColsChecked = this._cols.filter((col) => allActiveCols.find((colName) => colName === col.field));
    }
  }

  get showDetailsColumn(): boolean {
    return this._detailMode !== "n" && !this._hideViewCol;
  }

  get hasPendingChanges(): boolean {
    return this._formGroups.dirty;
  }

  isRowSelectionDisabled(rowData) {
    if (!this._disableSelectionWhenInvalid) return false;

    const rowFormGroup = this._formGroups.at(this.findObjectIndex(rowData));
    return rowFormGroup && rowFormGroup.invalid;
  }

  getSelectedRows(): any[] {
    const selectedRows = [];
    if (Array.isArray(this._selectedObjects)) selectedRows.push(...this._selectedObjects);
    else if (!_.isNil(this._selectedObjects)) selectedRows.push(this._selectedObjects);

    return selectedRows;
  }

  getFormGroupForRow(rowData) {
    return this._formGroups.at(this.findObjectIndex(rowData));
  }

  isCellDisabledByColOptions(rowData, dataTypeOption) {
    return _.isFunction(dataTypeOption.fnIsRowDisabled) && dataTypeOption.fnIsRowDisabled(rowData);
  }

  exportPdf(exportColumns, exportRows, colsWidth) {
    const pageWidth = Math.round(colsWidth / 1.5);
    const pageHeight = Math.round(pageWidth / 1.3);
    // const exportColumns = this._cols.map(col => ({title: col.header, dataKey: col.field}));
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then((x) => {
        // const doc = new jsPDF({
        //   orientation: 'landscape',
        //   unit: 'in',
        //   format: [4, 2]
        // })
        const doc = new jsPDF.default("l", "px", [pageWidth, pageHeight]);
        // doc.deletePage(0);
        // doc.addPage('tabloid', 'landscape');
        doc["autoTable"](
          exportColumns,
          exportRows.map((row) => Object.values(row))
        );
        doc.save(`${this._model}_export_${new Date().getTime()}.pdf`);
      });
    });
  }

  exportExcel(exportRows) {
    import("xlsx").then((xlsx) => {
      exportRows.forEach((row) => {
        for (const key in row) {
          if (row.hasOwnProperty(key)) {
            const col = this._cols.find((c) => c.header === key);
            if (col && col.dataType === DataType.number) {
              row[key] = parseFloat(row[key]);
            }
          }
        }
      });
      const worksheet = xlsx.utils.json_to_sheet(exportRows);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: "xlsx", type: "array" });
      this.saveAsExcelFile(excelBuffer, `${this._model}`);
    });
  }

  customExportCSV(exportRows) {
    import("xlsx").then((xlsx) => {
      const fieldSeparator = ",";
      const recordSeparator = "\n";

      // Preprocess exportRows to handle numbers with leading zeros
      const processedExportRows = exportRows.map((row) => {
        return Object.fromEntries(
          Object.entries(row).map(([key, value]) => {
            // Ensure value is either a string or number before comparison
            if (typeof value === "string" || typeof value === "number") {
              const stringValue = String(value);
              // Check if value is a number-like string and has leading zeros
              if (!isNaN(Number(stringValue)) && stringValue !== String(Number(stringValue))) {
                // Format as text to retain leading zeros
                return [key, `="${stringValue}"`];
              }
            }
            return [key, value];
          })
        );
      });

      const spreadsheet = xlsx.utils.json_to_sheet(processedExportRows);
      const csvBuffer = xlsx.utils.sheet_to_csv(spreadsheet, {
        FS: fieldSeparator,
        RS: recordSeparator,
        forceQuotes: true,
      });
      this.saveAsCSVFile(csvBuffer, `${this._model}`);
    });
  }

  saveAsCSVFile(buffer: any, fileName: string) {
    const DOCUMENT_TYPE = "application/csv";
    const DOCUMENT_EXTENSION = ".csv";
    const COMPLETE_FILENAME = fileName + DOCUMENT_EXTENSION;
    const BOM = "\uFEFF";
    const ENCODING = "utf-8";
    const data: Blob = new Blob([BOM + buffer], {
      type: DOCUMENT_TYPE + ";charset=" + ENCODING,
    });
    FileSaver.saveAs(data, COMPLETE_FILENAME);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION);
  }

  updateVisibleCols() {
    this._allVisibleCols = [...new Set(this.colsFrozen.concat(this.visibleScrollableCols))];
    this._showFooter = this._allVisibleCols.filter((row) => row.footer).length !== 0;
    this._lookupSortableCols = [{ label: "", value: null }].concat(
      // Dont't show columns with empty header (no caption) or that has been marked as no sortable
      this._allVisibleCols
        .filter((col) => !col.isNotSortable && col.header)
        .map((col) => ({ label: col.header, value: col.field }))
    );
  }

  isCellOutput(col: Col) {
    return col.readonly || !this._isFormInEditMode;
  }

  setHighLightedCol(col: Col) {
    this._highlightedCol = col;
    this.refreshStickyColumns();
  }

  isColHighlighted(col: Col) {
    return this._highlightedCol && col.field === this._highlightedCol.field && this._isFormInEditMode;
  }

  getSelectedFormGroups() {
    const formGroups = [];
    const selectedRows = this.getSelectedRows();

    selectedRows.forEach((row) => {
      formGroups.push(this.getFormGroupForRow(row));
    });

    return formGroups;
  }

  createNestedFormInjector(rowData, data: any[], col: Col) {
    const rowIndex = this.findObjectIndex(rowData);
    let rowInjectors = this._formArrayInjectors[rowIndex];
    if (!rowInjectors) this._formArrayInjectors[rowIndex] = rowInjectors = [];

    if (!rowInjectors[col.field])
      rowInjectors[col.field] = ReflectiveInjector.resolveAndCreate(
        [
          { provide: DATA_ARRAY_TOKEN, useValue: data },
          { provide: DISPLAY_MODE_TOKEN, useValue: NestedFormDisplayMode.TABULAR_DATA },
        ],
        this.injector
      );

    return rowInjectors[col.field];
  }

  arrayType_rowContents(singleItem, allItems, col: Col) {
    const displayField = col.dataTypeOptions.displayField;
    if (_.isFunction(displayField)) return displayField(singleItem, allItems);
    else return singleItem ? singleItem[displayField] : "";
  }

  onCloneRows() {
    const selectedRows = this.getSelectedRows();
    if (selectedRows.length !== 1) {
      const dialogMessage =
        selectedRows.length === 0
          ? "Please select the row you want to clone."
          : "Cloning multiple rows is not allowed, make sure to select only one row";
      this.confirmationService.confirm({
        header: "Cloning Instructions",
        message: dialogMessage,
        rejectVisible: false,
        acceptLabel: "OK",
        icon: "pi pi-exclamation-triangle",
      });

      return;
    }

    // We have selected the correct amount of rows
    this._cloneModeEnabled = true;
    // Prepare cloned object/row
    const clonedObject = this.cloningService.cloneObject(selectedRows[0], this._model, ["_uniqueid"]);
    // Add it to the form list
    this.addNewRow(clonedObject);
  }

  ngOnDestroy() {
    if (this.currSubscription) {
      this.currSubscription.unsubscribe();
    }
    if (this.dataFetchSubscription) {
      this.dataFetchSubscription.unsubscribe();
    }
    if (this._modelColsService && this._modelColsService.destroy) {
      this._modelColsService.destroy();
    }
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
    this.visibleInViewportIntersectionObserver?.disconnect();
    this.wrapperResizeObserver?.disconnect();
    this.tableHeaderResizeObserver?.disconnect();
  }

  executeColumnFilter(value, col: Col) {
    return this._dt.filter(...this.dbService.buildFilterParamsForCol(value, col));
  }

  clearGlobalFilter() {
    this.inputGlobalFilter = null;
    this._dt.filterGlobal(null, "contains");
  }

  getAllFormGroups(): UntypedFormGroup[] {
    return this._formGroups.controls as UntypedFormGroup[];
  }

  get inputGlobalFilter() {
    return this._inputFilterValues["global"];
  }

  set inputGlobalFilter(newValue) {
    this._inputFilterValues["global"] = newValue;
  }

  get showPagination() {
    return this.totalRecords > this.defaultRowsOrig && this.areControlsEnabled;
  }

  protected restoreRowValidators(row: any): void {
    const rowIndex = this.findObjectIndex(row);
    const formGroup = this._formGroups.at(rowIndex) as FormGroup;
    Object.entries(formGroup.controls).forEach(([ctrlName, ctrl]) => {
      const validators = _.get(this._modelValidation, ctrlName);
      const asyncValidators = _.get(this._modelAsyncValidation, ctrlName);

      this.applyValidatorsToCtrl(formGroup as UntypedFormGroup, ctrl, validators);
      this.applyAsyncValidatorsToCtrl(formGroup as UntypedFormGroup, ctrl, asyncValidators);
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
