<taku-form-header
  toolbarTitle="Cashout History"
  displayMode="inline"
  [showActionBtns]="false"
  [showBackBtn]="false"
></taku-form-header>

<div id="cashout-search-box" [formGroup]="formGroup" class="grid col-12">
  <!-- <div class="col-12 fields-line second-block" [ngClass]="{'lg:col-7 xl:col-6': fullSizeMode}"> -->
  <div class="grid col-12 md:col-6 lg:col-8 fields-line">
    <div class="col-12 sm:col-6 md:col-12 lg:col-6 field-datefrom">
      <taku-calendar-date
        [disabled]="formGroup.get('docNo').value"
        [formGroup]="formGroup"
        fieldName="dateFrom"
        fieldCaption="Closing Date From:"
      ></taku-calendar-date>
    </div>

    <div class="col-12 sm:col-6 md:col-12 lg:col-6 field-dateto">
      <taku-calendar-date
        [disabled]="formGroup.get('docNo').value"
        [formGroup]="formGroup"
        fieldName="dateTo"
        fieldCaption="Closing Date To:"
      ></taku-calendar-date>
    </div>
  </div>
  <div class="col-12 lg:col-4"></div>

  <div class="grid col-12 md:col-6 lg:col-8 fields-line">
    <div class="col-12 sm:col-6 md:col-12 lg:col-6 field-datefrom">
      <taku-dropdown
        [formGroup]="formGroup"
        [options]="lookup_stores$ | async"
        fieldCaption="Store"
        fieldName="storeId"
      ></taku-dropdown>
    </div>

    <div class="grid col-12 sm:col-6 md:col-12 lg:col-6 search-box-btns mt-0" style="width: 51%">
      <div class="col-6 p-fluid">
        <button pButton label="Clear" class="p-button-secondary" (click)="clearSearch()"
          ><span class="hidden-icons p-inputgroup-addon"><i class="icon pi pi-times"></i></span
        ></button>
      </div>
      <div class="col-6 p-fluid">
        <button pButton label="Search" (click)="searchDocuments()"
          ><span class="hidden-icons p-inputgroup-addon"><i class="icon pi pi-search"></i></span
        ></button>
      </div>
    </div>
  </div>
  <div class="col-12 lg:col-4"></div>
</div>

<div *ngIf="showFormList" class="col-12 formlistWrapper">
  <taku-form-list
    #formList
    [_filter]="formFilter"
    _model="cashout"
    [_hideSavingCtrls]="true"
    _dialogStyleClass="fullscreen-mobilemodal"
    [_dialogShowHeader]="false"
    [_showCtrlsInEditMode]="false"
    [_hideAddLineHeaderBtn]="true"
    [_hideOptionsHeaderBtn]="false"
    [_hideGlobalFilter]="true"
    [_hideAddHeaderBtn]="true"
    [_title]="'Cashout History'"
    [_rowSelectionType]="RowSelectionType.NONE"
    _detailMode="d"
    _scrollHeight="calc(100vh - 31rem)"
    [_cols]="cashoutHistoryColumns"
    _viewColIcon="_"
    _stateKey="cashoutHistory"
    [_dialogDismissable]="true"
    [_hideViewCol]="true"
  >
  </taku-form-list>
</div>
<!-- [_hideFilterControls]="true" [_hideViewCol]="true" -->
