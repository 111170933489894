/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class InvoiceKeyboardActionsService {
  _canAnnounceNumKeyPress = true;
  // Observable string sources
  private minusKeyPressSource = new Subject<{ keyCode: number; sourceEl: HTMLElement }>();
  private plusKeyPressSource = new Subject<{ keyCode: number; sourceEl: HTMLElement }>();
  private anyKeyPressSource = new Subject<KeyboardEvent>();
  private lineSelectionSource = new Subject<number>();

  // Observable string streams
  minusKeyPressed$ = this.minusKeyPressSource.asObservable();
  plusKeyPressed$ = this.plusKeyPressSource.asObservable();
  lineSelected$ = this.lineSelectionSource.asObservable();
  anyKeyPressed$ = this.anyKeyPressSource.asObservable();

  // Service message commands
  announceMinusKeyPressed(keyCode: number, sourceEl: HTMLElement) {
    this.minusKeyPressSource.next({ keyCode, sourceEl });
  }

  announcePlusKeyPressed(keyCode: number, sourceEl: HTMLElement) {
    this.plusKeyPressSource.next({ keyCode, sourceEl });
  }

  announceKeyPressed(event: KeyboardEvent) {
    this.anyKeyPressSource.next(event);
  }

  announceLineSelected(lineIndex: number) {
    this.lineSelectionSource.next(lineIndex);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
