/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
import { SaleDocType } from "src/app/core/document/doc/doc";

export class SaleDocReport {
  docType: SaleDocType;
  docClosingDate: string;
  currencyIsoCode: string;
  id: number;
  docsCount: number;
  subTotal: number | string;
  totalTaxAmount: number | string;
  shipperChargeAmount: number | string;
  discountAmount: number | string;
  totalAmountCalculated: number;

  constructor() {
    this.id = 0;
    this.docType = null;
    this.docClosingDate = null;
    this.currencyIsoCode = null;
    this.subTotal = null;
    this.docsCount = null;
    this.totalTaxAmount = null;
    this.shipperChargeAmount = null;
    this.discountAmount = null;
    this.totalAmountCalculated = null;
  }

  get totalAmount() {
    return (
      parseFloat(this.subTotal + "") -
      parseFloat(this.discountAmount + "") +
      parseFloat(this.totalTaxAmount + "") +
      parseFloat(this.shipperChargeAmount + "")
    );
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
