<span [formGroup]="formGroup" class="p-float-label" [ngClass]="{ 'inputctrl-nofilled': !isFilled }">
  <p-dropdown
    [ngClass]="{ 'inputctrl-filled': isFilled }"
    [readonly]="readonly"
    [options]="_formattedCurrencyCodes"
    formControlName="{{ fieldName }}"
    filter="true"
    optionDisabled="inactive"
    filterBy="startsWithFilter,containsFilter"
    resetFilterOnHide="true"
    (onChange)="onValuedChanged($event)"
    (click)="tryFocusSearchFilter($event)"
    [appendTo]="appendTo"
  >
    <ng-template let-item pTemplate="item">
      <div class="ui-helper-clearfix item-wrapper">
        <i *ngIf="item.icon" class="item-icon {{ item.icon }}"></i>
        <span *ngIf="item.label && item.label !== '-'" class="item-label">{{ item.label }} - {{ item.title }}</span>
        <p-divider *ngIf="item.label === '-'"></p-divider>
      </div>
    </ng-template>
    <ng-template let-item pTemplate="selectedItem">
      <div class="ui-helper-clearfix item-wrapper">
        <i *ngIf="item.icon" class="item-icon {{ item.icon }}"></i>
        <span *ngIf="item.label && item.label !== '-'" class="item-label">{{ item.label }} - {{ item.title }}</span>
      </div>
    </ng-template>
  </p-dropdown>
  <label *ngIf="fieldCaption" for="{{ fieldName }}" [innerHTML]="fullFieldCaption"></label>
</span>
