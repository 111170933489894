/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

export class AccountGroup {
  id: number;
  name: string;
  option: AcctGroupOptions;
  isEnabled: boolean;

  constructor() {
    this.id = 0;
    this.name = "";
    this.option = AcctGroupOptions.Both;
    this.isEnabled = true;
  }
}

export enum AcctGroupOptions {
  Commercial = "Commercial",
  Personal = "Personal",
  Both = "Both",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
