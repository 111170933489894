/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
import { Injectable, EventEmitter } from "@angular/core";
import { DBService } from "src/app/shared/services/db.service";
import { FormListModel } from "src/app/form-list/form-list/form-list-model.interface";
import { ValidatorFn } from "@angular/forms";
import {
  Col,
  FilterType,
  DataType,
  ExecuteEventDatatypeOptions,
  ExecuteEvent_DisplayMode,
} from "src/app/form-list/form-list/form-list";
import { SaleDocType } from "src/app/core/document/doc/doc";
import { SaleDoc } from "src/app/core/document/sale-document/sale-doc/sale-doc";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { SaledocRevisitPreviewComponent } from "src/app/core/document/sale-document/saledoc-revisit-preview/saledoc-revisit-preview.component";
import { SaleAnalysisSummaryReport } from "./sale-analysis-summary-report";
import { DataNormalizerService } from "src/app/shared/services/data-normalizes.service";

@Injectable({
  providedIn: "root",
})
export class SaleAnalysisSummaryReportColsFactoryService {
  constructor(
    public dbService: DBService,
    private dialogService: DialogService,
    public ref: DynamicDialogRef,
    private normalizerService: DataNormalizerService
  ) {}

  build(dynamicFields: string[]) {
    return new SaleAnalysisSummaryReportCols(
      this,
      dynamicFields,
      this.dialogService,
      this.ref,
      this.dbService,
      this.normalizerService
    );
  }
}

export class SaleAnalysisSummaryReportCols implements FormListModel {
  showSaleDocPreview: EventEmitter<SaleAnalysisSummaryReport> = new EventEmitter();
  normalizerService: DataNormalizerService;

  constructor(
    private factoryService: SaleAnalysisSummaryReportColsFactoryService,
    private dynamicFields: string[],
    private dialogService: DialogService,
    public ref: DynamicDialogRef,
    public dbService: DBService,
    normalizerService: DataNormalizerService
  ) {
    this.normalizerService = normalizerService;
    // this.showSaleDocPreview.subscribe((saleAnalysisSummmaryReport: SaleAnalysisSummaryReport) => {
    //   console.log(saleAnalysisSummmaryReport);
    // })
    this.showSaleDocPreview.subscribe((saleAnalysisSummmaryReport: SaleAnalysisSummaryReport) => {
      this.dbService.getRow("saleDoc", saleAnalysisSummmaryReport.saleDocId).subscribe((_saleDoc) => {
        this.ref = this.dialogService.open(SaledocRevisitPreviewComponent, {
          data: {
            _object: _saleDoc,
          },
          width: "90%",
          height: "90%",
          closable: false,
          showHeader: false,
        });
      });
    });
  }
  enumDocumentTypes = this.factoryService.dbService.enumSelectOptions(SaleDocType).filter((row) => {
    return (
      row.value === SaleDocType.sales_invoice ||
      row.value === SaleDocType.sales_return ||
      row.value === SaleDocType.pre_order ||
      row.value === SaleDocType.recurring_order
    );
  });

  _disableCloning = true;
  getValidationRules(): { [key: string]: {} | ValidatorFn[] } {
    return {};
  }

  getDocNo(rowData) {
    return rowData ? rowData.docNo : "Sale doc has no Doc #";
  }

  getFormListColumns(): Col[] {
    const cols: Col[] = [
      {
        field: "docNo",
        header: "Doc No",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.execute_event,
        dataOptions: [],
        dataTypeOptions: [
          new ExecuteEventDatatypeOptions({
            displayMode: ExecuteEvent_DisplayMode.LINK,
            label: this.getDocNo,
            event: this.showSaleDocPreview,
            enabledOnEditMode: false,
            styleClass: "",
          }),
        ],
        filterType: FilterType.contains,
        colWidth: 80,
      },
      {
        field: "docType",
        header: "Document Type",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enumDocumentTypes,
        filterType: FilterType.enum,
        colWidth: 120,
      },
      {
        field: "docClosingDate",
        header: "Date",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
      },
      {
        field: "totalStandardCost",
        header: "Total Cost $",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
        footer: true,
      },
      {
        field: "marginPercentCalculated",
        header: "Margin %",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
        displayFn: (rowData: SaleAnalysisSummaryReport) => {
          return this.normalizerService.normalizeNumbers(rowData.marginPercentCalculated, 2, 2);
        },
      },
      {
        field: "marginAmountCalculated",
        header: "Margin $",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
        footer: true,
      },
      {
        field: "subTotal",
        header: "Sub Total Amount",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
        footer: true,
      },
      {
        field: "discountAmount",
        header: "Discount Amount $",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
        footer: true,
      },
    ];

    this.dynamicFields.forEach((field, index) => {
      const _header = field;

      cols.push({
        field,
        header: _header,
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
        footer: true,
      });
    });
    cols.push(
      {
        field: "shipperChargeAmount",
        header: "Shipping charges $",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
        footer: true,
      },
      {
        field: "totalAmountCalculated",
        header: "Total Amount",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
        footer: true,
      },
      {
        field: "customerSource",
        header: "Customer Source",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
      },
      {
        field: "channelName",
        header: "Channel Name",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
      },
      {
        field: "stationName",
        header: "Station Name",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 80,
      },
      {
        field: "accountCode",
        header: "Account Code",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
      },
      {
        field: "accountName",
        header: "Account Name",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
      },
      {
        field: "taxAccountCategory",
        header: "Tax Account Category",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
      },
      {
        field: "tags",
        header: "Inventory Tags",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.chips,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
      }
      // {
      //   field: 'state', header: 'State', visible: true, readonly: true, frozen: false, dataType: DataType.input,
      //   dataOptions: [], filterType: FilterType.contains, colWidth: 80
      // }
    );

    return cols;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
