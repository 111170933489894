/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Cashout } from "../../../cashout/cashout/cashout";
import { Station } from "../../../settings/store-settings/station/station";
import { Observable, concat } from "rxjs";
import { SelectItem } from "primeng/api";
import { DBService } from "../../../../shared/services/db.service";
import { FulfillmentStatus, SaleDocSource } from "../sale-doc/sale-doc";
import { map } from "rxjs/operators";
import { Account } from "src/app/core/contact-accounts/account/account";

@Component({
  selector: "taku-sale-invoice-doc-details",
  templateUrl: "./sale-invoice-doc-details.component.html",
  styleUrls: ["./sale-invoice-doc-details.component.scss"],
})
export class SaleInvoiceDocDetailsComponent implements OnInit {
  @Input() saleForm: UntypedFormGroup;
  @Input() station: Station;
  @Input() account: Account;
  @Input() cashout: Cashout;
  dialogVisible = false;

  enum_saledoc_sources: SelectItem[];
  enum_saledoc_fulfillment_status: SelectItem[];
  lookup_taxAccountCategories$: Observable<SelectItem[]>;

  constructor(private dbService: DBService) {}

  ngOnInit(): void {
    this.lookup_taxAccountCategories$ = this.dbService.lookupSelectOptions("taxAccountCategory", "category", {
      enableFieldName: "isEnabled",
    });
    this.enum_saledoc_sources = this.dbService.enumSelectOptions(SaleDocSource);
    this.enum_saledoc_fulfillment_status = this.dbService.enumSelectOptions(FulfillmentStatus);
  }

  onFulfillmetStatusChanged(): void {
    if (
      !this.saleForm.value.accountId &&
      this.saleForm.get("fulfillmentStatus").value == FulfillmentStatus.unfulfilled
    ) {
      this.dialogVisible = true;
      this.saleForm.get("fulfillmentStatus").setValue(null);
    }
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
