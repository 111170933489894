import { Component, EventEmitter, Input, Output, inject } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { NestedFormComponent } from "src/app/forms/nested-form/nested-form.component";
import { CommercialAccountSupplierDetail } from "../commercial-account/commercial-account";
import { ExtendedSelectItem } from "src/app/shared/services/db.service";
import { CommercialAccountDetailService } from "./commercial-account-details.service";

@Component({
  selector: "taku-commercial-details",
  templateUrl: "./commercial-account-details.component.html",
  styleUrls: ["./commercial-account-details.component.scss"],
})
export class CommercialAccountDetailsComponent extends NestedFormComponent {
  @Input() set zoneList(val: ExtendedSelectItem[]) {
    this._zones = val;
    const data = this._zones.find((f) => f.value === this._myForm.value["zoneId"]);
    if (data) {
      data.disabled = true;
      this.previousItem = data;
    }
    this.setDisabledItem();
  }

  @Output() zoneListChange = new EventEmitter();
  @Output() removeItem = new EventEmitter();
  previousItem: ExtendedSelectItem;

  _zones: ExtendedSelectItem[];
  comercialService = inject(CommercialAccountDetailService);
  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    const commercialAccountSupplierDetails_Group = fb.group(new CommercialAccountSupplierDetail());
    return commercialAccountSupplierDetails_Group;
  }

  static setArray(fb: UntypedFormBuilder, commercialAccountSupplierDetails: CommercialAccountSupplierDetail[]) {
    const formArray = fb.array([]);
    commercialAccountSupplierDetails.map((supplierDetail) => {
      const temp = fb.group(supplierDetail);
      formArray.push(temp);
    });
    return formArray;
  }

  ngOnInit(): void {
    super.ngOnInit();
    // look into preselected items to disabled it
    this._myForm.get("zoneId").valueChanges.subscribe((_zoneId) => {
      const zone = this.sortSelectedItem(_zoneId);
      this.setDisabledItem();
    });
  }

  setDisabledItem() {
    if (!this._zones) return;
    this.zoneListChange.emit(this._zones);
  }

  sortSelectedItem(itemId: number): void {
    if (this.previousItem?.value) {
      const previousItemInZoneArr = this._zones.find((f) => f.value === this.previousItem.value);
      previousItemInZoneArr.disabled = false;
    }

    const selectedItem = this._zones.find((f) => f.value === itemId);

    selectedItem.disabled = true;
    this.previousItem = selectedItem;
    this._myForm.get("zone").value["defaultCurrencyIsoCode"] = selectedItem?.object["defaultCurrencyIsoCode"] || "";
    this.setDisabledItem();
  }

  initValidation() {
    this._validation = {};
  }

  // listen to form changes

  initLookups(): void {}

  onChangeItem(item: Record<string, any>) {
    this.sortSelectedItem(Number(item.value));
  }

  remove() {
    this.removeItem.emit(this._myForm.value);
  }
}
