/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Input } from "@angular/core";
import { FormControl, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { shareReplay } from "rxjs/operators";
import { NestedFormComponent } from "../../../forms/nested-form/nested-form.component";
import { CommonValidators } from "../../../shared/validators/CommonValidators";
import { BusinessFormatsSettings } from "../../settings/business-settings/business-formats-settings/BusinessFormatsSettings";
import { Account, AccountRelationship, AccountStatus, AccountType } from "./account";
import { AccountService } from "./account.service";
import { PersonalAccountsAccessRightsFields } from "../shared/personal-accounts-access-rights-fields";
import { EmptyPersonalAccountsAccessRights } from "../shared/empty-personal-accounts-access-rights";
import { SaleChannelService } from "../../sale-channel/sale-channel.service";
import { CrmHistoryComponent } from "../../document/sale-document/crm-history/crm-history.component";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { TakuAccountCreditCardComponent } from "src/app/taku-ui/taku-account-credit-card/taku-account-credit-card.component";

@Component({
  selector: "taku-account",
  templateUrl: "./account.component.html",
  styleUrls: ["./account.component.scss"],
  providers: [DialogService],
})
export class AccountComponent extends NestedFormComponent {
  @Input()
  accountAccessRights?: PersonalAccountsAccessRightsFields = new EmptyPersonalAccountsAccessRights();

  historyDialogOpening = false;
  get_business_formats$: Observable<BusinessFormatsSettings> = this.dbService
    .get_business_formats()
    .pipe(shareReplay(1));

  static get validators() {
    return {
      creditLimit: [CommonValidators.decimal(15, 4)],
    };
  }
  // Define enum variables
  enum_account_type = this.dbService.enumSelectOptions(AccountType);
  enumAccountRelationship = this.dbService.enumSelectOptions(AccountRelationship);
  enumAccountStatus = this.dbService.enumSelectOptions(AccountStatus);
  enumChannelType = this.saleChannelService.enumChannelTypes();

  // Define lookup Variables
  lookup_acctGroup$: Observable<any>;
  lookup_acctAssociation$: Observable<any>;
  lookup_taxAccountCategory$: Observable<any>;

  static init(fb: UntypedFormBuilder, accountType: AccountType): UntypedFormGroup {
    const account_Group = fb.group(new Account(accountType));
    account_Group.setControl("accountCreditCards", fb.array([]));
    return account_Group;
  }

  static set(fb: UntypedFormBuilder, account: Account): UntypedFormGroup {
    const temp = fb.group({
      ...account,
      tags: new FormControl(account.tags || []),
      acctGroups: new FormControl(account.acctGroups || []),
    });
    temp.setControl("accountCreditCards", TakuAccountCreditCardComponent.setArray(fb, account.accountCreditCards));
    return temp;
  }

  constructor(
    protected dbService: AccountService,
    protected fb: UntypedFormBuilder,
    private saleChannelService: SaleChannelService,
    private dialogService: DialogService,
    public ref: DynamicDialogRef
  ) {
    super(dbService, fb);
  }

  // ngOnInit(){
  //   super.ngOnInit();

  //   const statusBoolean = this.statusCtrl.value === AccountStatus.active;
  //   this._myForm.addControl('_statusToggle', this.fb.control(statusBoolean));
  //   this.statusToggleCtrl.valueChanges.subscribe(newStatusVal => {
  //     if (newStatusVal)
  //       this.statusCtrl.setValue(AccountStatus.active, {emitEvent: false});
  //     else
  //       this.statusCtrl.setValue(AccountStatus.inactive, {emitEvent: false});
  //   })

  // }

  initLookups() {
    this.lookup_acctGroup$ = this.dbService.lookupAccountGroup(this._myForm.get("accountType").value);
    // this.dbService.lookupSelectOptions('currency', 'currencyName').subscribe(data => this.lookup_defaultCurrency = data);
    this.lookup_acctAssociation$ = this.dbService.lookupSelectOptions("acctAssociation", "name");
    // this.lookup_currencies = this.cfService.getCurrencyCodeList();
    this.lookup_taxAccountCategory$ = this.dbService.lookupSelectOptions("taxAccountCategory", "category", {
      enableFieldName: "isEnabled",
    });
  }

  initValidation() {
    this._validation = AccountComponent.validators;
  }

  get statusCtrl() {
    return this._myForm.get("status");
  }

  get statusToggleCtrl() {
    return this._myForm.get("_statusToggle");
  }

  openAccountHistoryDialog() {
    this.historyDialogOpening = true;
    this.ref = this.dialogService.open(CrmHistoryComponent, {
      styleClass: "fullscreen-mobilemodal dialogSidebar",
      contentStyle: {
        overflow: "auto",
        height: "calc(100vh - 40pt)",
        minWidth: "580pt",
      },
      style: {
        minHeight: "80vh",
      },
      data: {
        accountInfo: this._myForm.value,
      },
    });
    this.ref.onClose.subscribe((result) => {
      this.historyDialogOpening = false;
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
