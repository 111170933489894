/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Directive, ViewContainerRef } from "@angular/core";

@Directive({
  selector: "[reportFormList-host]",
})
export class ReportFormListDirective {
  constructor(public viewContainerRef: ViewContainerRef) {}
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
