import { Location } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Component, Optional, ViewChild } from "@angular/core";
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { FileUpload } from "primeng/fileupload";
import { InventoryZoneForm } from "src/app/core/inventory/inventory-zone/inventory-zone-form";
import { Inventory } from "src/app/core/inventory/inventory/inventory";
import { InventoryComponent } from "src/app/core/inventory/inventory/inventory.component";
import { InventoryService } from "src/app/core/inventory/inventory/inventory.service";
import { GenericFormService } from "src/app/forms/generic-form/generic-form.component";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { DBService } from "src/app/shared/services/db.service";
import { PrintingFactoryService } from "src/app/shared/services/printing-service.service";
import { SearchResultItem } from "src/app/taku-ui/taku-search-accounts/SearchResultItem";
import { environment } from "src/environments/environment";
import { StoreSettingsGenericComponent } from "../StoreSettingsGenericComponent";
import { KioskSetting } from "./kioskSettings";
import appDefaults from "src/app/shared/json/app-defaults.json";

const defaultSettings = appDefaults.defaultKioskSettings;
@Component({
  selector: "taku-kiosk-settings",
  templateUrl: "./kiosk-settings.component.html",
  styleUrls: ["./kiosk-settings.component.scss"],
})
export class KioskSettingsComponent extends StoreSettingsGenericComponent {
  _pageTitlePrefix = "Kiosk Settings";

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    const tmpModel = new KioskSetting();
    const tmpForm = fb.group(tmpModel);
    tmpForm.setControl("inventories", fb.array([]));
    return tmpForm;
  }

  @ViewChild("fileUploader") _fileUploader: FileUpload;

  isInventoryDetailsVisible = false;
  isInventoryDetailsEnabled = false;

  constructor(
    private httpClient: HttpClient,
    _router: Router,
    fb: UntypedFormBuilder,
    dbService: DBService,
    location: Location,
    protected printingFactoryService: PrintingFactoryService,
    _route: ActivatedRoute,
    messageService: MessageService,
    alertMessage: AlertMessagesService,
    confirmationService: ConfirmationService,
    appSettings: AppSettingsStorageService,
    private auth: AuthService,
    private dialogService: DialogService,
    public ref: DynamicDialogRef,
    public inventoryService: InventoryService,
    @Optional() formService?: GenericFormService
  ) {
    super(
      _router,
      fb,
      dbService,
      location,
      printingFactoryService,
      _route,
      messageService,
      alertMessage,
      confirmationService,
      appSettings,
      formService
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.isInventoryDetailsVisible = this.auth.hasVisiblePermit("Inventory_Menu_Inventory_List");
    this.isInventoryDetailsEnabled = this.auth.hasEnablePermit("Inventory_Menu_Inventory_List");
  }

  setFormArrays() {
    this._myForm.setControl(
      "inventories",
      this.fb.array(
        (<Inventory[]>this._object.inventories).map((inventory) => {
          delete inventory.tags;
          return this.fb.group(inventory);
        })
      )
    );
  }

  onSearchResInventory(inventoryResult: SearchResultItem<Inventory>): void {
    // console.log('onSearch', inventoryResult.data);
    this._addInventoryItem(inventoryResult.data);
  }

  private _addInventoryItem(inventory: Inventory) {
    if (this._myForm.value.inventories.find((cell) => cell.id === inventory.id)) {
      this.messageService.add({
        severity: "warn",
        summary: "Duplicate Item",
        detail: "Selected item is already exists in the list below.",
      });
    } else {
      delete inventory.tags;
      const inventoryCtrl = InventoryComponent.init(this.fb, inventory);
      inventoryCtrl.setControl(
        "inventoryZones",
        InventoryZoneForm.setArray(
          this.fb,
          inventory.inventoryZones,
          this.inventoryService.getValidationRules().inventoryZones
        )
      );
      if (!(this._myForm.controls["inventories"] instanceof UntypedFormArray)) {
        this._myForm.setControl("inventories", this.fb.array([]));
      }
      (<UntypedFormArray>this._myForm.controls["inventories"]).push(inventoryCtrl);
      this._myForm.markAsDirty();
    }
  }

  applyDefaultColors() {
    this.colorThemeCtrl.setValue(defaultSettings.mainButtonBgColor);
    this.mainScreenBtnColorCtrl.setValue(defaultSettings.mainButtonFontColor);
    this.categoryBtnBgColorCtrl.setValue(defaultSettings.categoryBgColor);
    this.categoryBtnFontColorCtrl.setValue(defaultSettings.categoryFontColor);
    this._myForm.markAsDirty();
  }

  // removeInventoryItem(inventories: Inventory[]) {
  //   if(inventories.length !== 0) {
  //     const index = this._myForm.get('inventories').value.findIndex((row)=>row.id === inventories[0].id);
  //     (<FormArray>this._myForm.get('inventories')).removeAt(index);
  //     this.selectionInventories = [];
  //     this._myForm.markAsDirty();
  //   }
  // }

  removeInventoryItem(inventory: Inventory, e: Event) {
    e.stopPropagation();

    const index = this._myForm.get("inventories").value.findIndex((row) => row.id === inventory.id);
    (<UntypedFormArray>this._myForm.get("inventories")).removeAt(index);
    this._myForm.markAsDirty();

    // this.removeFromSelectedInventories(index);
    // console.log(this.selectionInventories);
  }

  get fileNameImageUrl() {
    return this._myForm.value.urlOpeningScreenImageFileName;
  }

  get imageUrlCtrl(): AbstractControl {
    return this._myForm.get("openingScreenImageFileName");
  }

  get urlImageUrlCtrl(): AbstractControl {
    return this._myForm.get("urlOpeningScreenImageFileName");
  }

  get inventories() {
    return this._myForm.get("inventories").value;
  }

  get colorThemeCtrl() {
    return this._myForm.get("colorTheme");
  }

  get mainScreenBtnColorCtrl() {
    return this._myForm.get("mainScreenBtnFontColor");
  }

  get categoryBtnBgColorCtrl() {
    return this._myForm.get("categoryBtnBgColor");
  }

  get categoryBtnFontColorCtrl() {
    return this._myForm.get("categoryBtnFontColor");
  }

  myUploader(event: { files: any[] }) {
    // get signedUrl
    event.files.map((_file) => {
      if (_file.size <= 4 * 1024 * 1024) {
        this.dbService._getRequest<any>(environment.apiUrl + "/uploadURL/" + _file.name).subscribe(
          (response: any) => {
            this.subsList.push(
              this.httpClient.put(response.url, _file).subscribe(
                (res) => {
                  this.imageUrlCtrl.setValue(response.fileName);
                  this.urlImageUrlCtrl.setValue(response.bucketPath + "/" + response.fileName);
                  this.imageUrlCtrl.markAsDirty();
                },
                (err) => console.log(err)
              )
            );
          },
          (err) => console.log(err)
        );
      } else {
        this.messageService.add(this.alertMessage.getMessage("max-upload-size-warning"));
        this._fileUploader.clear();
      }
    });
  }

  clearImage() {
    this.imageUrlCtrl.setValue(null);
    this.urlImageUrlCtrl.setValue(null);
    this.imageUrlCtrl.markAsDirty();
  }

  initForm() {
    this._model = "storeSettingKiosk";
    this._object = new KioskSetting();
    this._myForm = KioskSettingsComponent.init(this.fb);
  }

  onClickedViewDetails(inventory: Inventory, e: Event) {
    e.stopPropagation();

    const index = this._myForm.get("inventories").value.findIndex((row) => row.id === inventory.id);

    this.ref = this.dialogService.open(InventoryComponent, {
      // stylesClass: '',
      // contentStyle: {

      // },
      // style: {

      // },
      showHeader: false,
      closable: false,
      data: {
        inventoryForm: (<UntypedFormArray>this._myForm.controls["inventories"]).at(index),
        isDialog: true,
      },
    });
    this.ref.onClose.subscribe((result) => {
      console.log(result, "closing dialog now");
    });
  }
}
