/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, Input, EventEmitter, Output, ViewContainerRef, ViewChild, OnDestroy } from "@angular/core";
import { Cashout, CashoutClosingBreakDown } from "../cashout/cashout";
import { ActivatedRoute } from "@angular/router";
import * as _ from "lodash";
import { CashoutClosingBreakdownComponent } from "../cashout-closing-breakdown/cashout-closing-breakdown.component";
import { UntypedFormBuilder, UntypedFormArray } from "@angular/forms";
import { Location } from "@angular/common";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { PrintingFactoryService, ReceiptPrintingSize } from "src/app/shared/services/printing-service.service";
import { StoreSettingCashout } from "../../settings/store-settings/cashout-settings/CashoutSettings";
import { CashoutPaymentBreakdown } from "../db-cashout.service";
import { MenuItem } from "primeng/api";
import { DBService } from "src/app/shared/services/db.service";
import { Subscription } from "rxjs";
import { Zone } from "../../settings/zone-settings/Zone";

export type CashoutDeposits = CashoutClosingBreakDown & CashoutPaymentBreakdown;
@Component({
  selector: "taku-cashout-history-preview",
  templateUrl: "./cashout-history-preview.component.html",
  styleUrls: ["./cashout-history-preview.component.scss"],
})
export class CashoutHistoryPreviewComponent implements OnInit, OnDestroy {
  @Input() _object;
  @Output() dialogClosed: EventEmitter<any> = new EventEmitter();
  @ViewChild("printPreviewHost", { read: ViewContainerRef, static: true }) printPreviewHost: ViewContainerRef;

  cashout: Cashout;
  _model = "cashout";
  closingBreakDownArray: UntypedFormArray;
  _currencyISOCode;
  cashoutSettings: StoreSettingCashout;
  printItems: MenuItem[];
  subsList: Subscription[] = [];

  constructor(
    private _route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private appSettings: AppSettingsStorageService,
    protected location: Location,
    private printingService: PrintingFactoryService,
    private dbService: DBService
  ) {
    this._currencyISOCode = this.appSettings.getZone().defaultCurrencyIsoCode;
  }

  onClosePressed() {
    this.dialogClosed.emit(this.cashout);
  }

  onPrintPressed(printingSize: ReceiptPrintingSize) {
    const deposits = <CashoutDeposits[]>this.closingBreakDownArray.value;
    this.printingService
      .build(null)
      .printCashoutSlip(
        [this.cashout],
        deposits,
        this._currencyISOCode,
        this.cashoutSettings,
        printingSize,
        this.printPreviewHost
      );
  }

  ngOnInit() {
    this.cashout = _.merge(new Cashout(), this._object);
    this.closingBreakDownArray = CashoutClosingBreakdownComponent.setArray(
      this.fb,
      this.cashout.cashoutClosingBreakDowns
    );

    this.subsList.push(
      this.dbService.getRow("zone", this.cashout.store.zoneId).subscribe((zone) => {
        this._currencyISOCode = zone.defaultCurrencyIsoCode;
      })
    );

    // this.appSettings.getStoreSettings('storeSettingCashout').subscribe((_cashoutSettings) => {
    //   this.cashoutSettings = _cashoutSettings;
    //   console.log(this.cashoutSettings);
    // }
    // )

    this.appSettings.getSettingsByStoreId("storeSettingCashout", this.cashout.storeId).subscribe((_cashoutSettings) => {
      this.cashoutSettings = _cashoutSettings;

      // this._currencyISOCode = this.cashoutSettings.store.zone.defaultCurrencyIsoCode;
    });

    this.printItems = [
      {
        label: ReceiptPrintingSize.TAPE_SIZE,
        command: () => {
          this.onPrintPressed(ReceiptPrintingSize.TAPE_SIZE);
        },
        icon: "takuicon-measuring-tape",
      },
      {
        label: ReceiptPrintingSize.LETTER_SIZE,
        command: () => {
          this.onPrintPressed(ReceiptPrintingSize.LETTER_SIZE);
        },
        icon: "takuicon-invoice",
      },
      {
        label: ReceiptPrintingSize.TAPE_SIZE_CASHOUT_REPORT,
        command: () => {
          this.onPrintPressed(ReceiptPrintingSize.TAPE_SIZE_CASHOUT_REPORT);
        },
        icon: "takuicon-measuring-tape",
      },
    ];
  }

  ngOnDestroy(): void {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
