/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, LOCALE_ID, inject } from "@angular/core";
import { UntypedFormBuilder, ValidatorFn, Validators } from "@angular/forms";
import { Observable, of } from "rxjs";
import { Col, DataType, FilterType } from "../../../../form-list/form-list/form-list";
import { FormListModel } from "../../../../form-list/form-list/form-list-model.interface";
import { AppSettingsStorageService } from "../../../../shared/app-settings-storage.service";
import { AuthService } from "../../../../shared/services/auth.service";
import { DBService, ExtendedSelectItem, LookupOptions } from "../../../../shared/services/db.service";
import { DocState } from "../../doc/doc";
import { InventoryDocLineService } from "../inventory-document/inventory-doc-line.service";
import { InventoryDoc, InventoryDocLine, InventoryDocLineTax } from "../inventory-document/inventory-document";
import { TimeHelpers } from "src/app/utility/TimeHelpers";
import type { Stock } from "src/app/core/settings/business-settings/stock/stock";
import { DBTaxService } from "src/app/shared/services/db-tax.service";
import { tap } from "rxjs/operators";
import { TaxRule } from "src/app/core/tax/tax-rule/tax-rule";
import { Zone } from "src/app/core/settings/zone-settings/Zone";
import { DataNormalizerService } from "src/app/shared/services/data-normalizes.service";
import { CurrencySymbolPipe } from "src/app/shared/pipes/currency-symbol.pipe";
import { Store } from "src/app/core/settings/store-settings/store/store";
import { BackOffice } from "src/app/core/settings/backOffice-settings/backOffice/backOffice";
import { PaymentTerm } from "src/app/core/purchase-order-term/payment-term/payment-term";
import { ShippingTerm } from "src/app/core/purchase-order-term/shipping-term/shipping-term";

@Injectable({
  providedIn: "root",
})
export class InventoryDocService extends DBService implements FormListModel {
  enum_doc_states = this.enumSelectOptions(DocState);
  normalizeService = inject(DataNormalizerService);

  getValidationRules(): { [key: string]: {} | ValidatorFn[] } {
    return {
      stockId: [Validators.required],
      "doc.currencyIsoCode": [Validators.required],
      "doc.docDate": [Validators.required],
      "doc.docTime": [Validators.required],
      accountId: [Validators.required],
    };
  }

  constructor(
    private appSettings: AppSettingsStorageService,
    public inventoryDocLineService: InventoryDocLineService,
    protected http: HttpClient,
    protected authService: AuthService,
    private dbTaxService: DBTaxService,
    @Inject(LOCALE_ID) private defaultLocale: string
  ) {
    super(http, authService);
  }

  updateInventoryDocTaxesInLineForm(line: InventoryDocLine, inventoryDoc: InventoryDoc): Observable<any> {
    const inventoryId = line["docLine"]["inventoryId"];
    const qty = +line["qty"];
    const convertedUnitCost = +line["convertedUnitCost"];
    const convertedUnitDiscount = +line["convertedUnitDiscount"];

    return this.dbTaxService
      .calculatePurchaseTaxLine(
        inventoryId,
        convertedUnitCost - convertedUnitDiscount,
        qty,
        inventoryDoc.stockId,
        null,
        inventoryDoc.zone?.taxAccountCategoryId || null,
        inventoryDoc.doc.docDate
      )
      .pipe(
        tap((taxRules: TaxRule[]) => {
          this.updateTaxLines(line, taxRules);
        })
      );
  }

  private updateTaxLines(line: InventoryDocLine, taxRules: TaxRule[]): void {
    const lineTaxes: InventoryDocLineTax[] = [];
    taxRules.forEach((taxRule) => {
      lineTaxes.push({
        id: 0, // set as new tax line
        amount: taxRule.taxAmount || 0,
        taxRuleId: taxRule.id,
        taxRule: taxRule,
      });
    });
    line["inventoryDocLineTaxes"] = lineTaxes;
  }

  get_lookup_paymentTerms(): Observable<ExtendedSelectItem<number, PaymentTerm>[]> {
    const options: LookupOptions = {
      enableFieldName: "isActive",
    };

    return this.lookupSelectOptions<number, PaymentTerm>("paymentTerm", "name", options);
  }

  get_lookup_shippingTerms(): Observable<ExtendedSelectItem<number, ShippingTerm>[]> {
    const options: LookupOptions = {
      enableFieldName: "isActive",
    };

    return this.lookupSelectOptions<number, ShippingTerm>("shippingTerm", "name", options);
  }

  get_lookup_stocks(): Observable<ExtendedSelectItem<number, Stock>[]> {
    const options: LookupOptions = {
      enableFieldName: "isActive",
    };
    // Only filter stock dropdown-options for non-admin
    if (this.getDefaultZoneId() !== 0) {
      options.lookupFilter = JSON.stringify({ zoneId: { matchMode: "equals", value: this.getDefaultZoneId() } });
    }
    return this.lookupSelectOptions<number, Stock>("stock", "stockName", options);
  }

  get_lookup_stores(): Observable<ExtendedSelectItem<string, Store>[]> {
    const options: LookupOptions = {
      enableFieldName: "isActive",
    };

    return this.lookupSelectOptions<string, Store>("store", "storeName", options);
  }

  get_Lookup_backOffices(): Observable<ExtendedSelectItem<string, BackOffice>[]> {
    const options: LookupOptions = {
      enableFieldName: "isActive",
    };

    return this.lookupSelectOptions<string, BackOffice>("backOffice", "backOfficeName", options);
  }

  getDefaultZoneId(): number {
    return this.appSettings.getZoneId();
  }

  moneyPrefix(rowData: InventoryDoc) {
    return new CurrencySymbolPipe(this.defaultLocale).transform(rowData.doc.currencyIsoCode);
  }

  getDefaultZone(): Zone {
    return this.appSettings.getZone();
  }

  getCurrentUserId(): number {
    return this.appSettings.getUserId();
  }

  getFormListColumns(): Col[] {
    return [
      {
        field: "account.commercialAccount.name",
        header: "Supplier",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 130,
      },
      {
        field: "stockId",
        fieldValue: "stock.stockName",
        header: "Receiving Location",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.lookup,
        dataOptions: this.get_lookup_stocks(),
        filterType: FilterType.lookup,
        sortFields: ["stock.stockName"],
      },
      {
        field: "doc.state",
        header: "Doc Status",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enum_doc_states,
        filterType: FilterType.enum,
        colWidth: 160,
      },
      {
        field: "doc.docNo",
        header: "Stock Receipt",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 180,
      },
      {
        field: "doc.docDate",
        header: "Date",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.date_date_only,
        dataOptions: of([]),
        filterType: FilterType.contains,
      },
      {
        field: "doc.docTime",
        header: "Time",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.date_time,
        dataOptions: of([]),
        filterType: FilterType.contains,
        colWidth: 90,
        sortFields: ["doc.docDate", "doc.docTime"],
      },
      {
        field: "subTotal",
        header: "Total Cost",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: of([]),
        dataTypeOptions: { prefix: this.moneyPrefix.bind(this) },
        filterType: FilterType.contains,
        displayFn: (rowData: InventoryDoc) => {
          return this.normalizeService.currencyNormalizer(rowData.subTotal, rowData.doc.currencyIsoCode);
        },
      },
      {
        field: "totalItems",
        header: "# of Items",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: of([]),
        filterType: FilterType.none,
        isNotSortable: true,
        displayFn: (rowData: InventoryDoc) => {
          return this.normalizeService.normalizeNumbers(rowData.totalItems);
        },
      },
      {
        field: "stock.pos",
        header: "Associated POs",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: of([]),
        filterType: FilterType.contains,
      },
      {
        field: "buyerName",
        header: "Buyer's Name",
        visible: false,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: of([]),
        filterType: FilterType.contains,
        colWidth: 110,
      },
    ];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
