<div class="grid col-12 topWrapper">
  <taku-form-header
    toolbarTitle="Reference Details"
    displayMode="inline"
    [showActionBtns]="false"
    [showBackBtn]="false"
  ></taku-form-header>
  <div class="col-12">
    <ng-template [ngIf]="!_myForm.get('refTransaction').value">
      <div class="col-12">
        <taku-input-textarea [formGroup]="_myForm" fieldName="refNo" fieldCaption="Reference #"></taku-input-textarea>
      </div>
      <div class="col-2 col-offset-10 p-fluid">
        <button class="ok-btn" pButton label="OK" (click)="okBtnPressed()" [disabled]="!_myForm.valid"></button>
      </div>
    </ng-template>

    <ng-template [ngIf]="_myForm.get('refTransaction').value && _myForm.get('refTransaction.RefID')?.value !== ''">
      <div class="col-12">
        <table class="table-detail">
          <ng-container *ngFor="let refDetail of _myForm.get('refTransaction').value | keyvalue">
            <tr *ngIf="refDetail.key === 'TransType'">
              <td class="td-line">Trans Type: </td>
              <td class="td-line">{{ refDetail.value | titlecase }}</td>
            </tr>
            <tr *ngIf="refDetail.key === 'AuthCode'">
              <td class="td-line">Auth Code: </td>
              <td class="td-line">{{ refDetail.value }}</td>
            </tr>
            <tr *ngIf="refDetail.key === 'CardType'">
              <td class="td-line">Card Type: </td>
              <td class="td-line">{{ refDetail.value }}</td>
            </tr>
            <tr *ngIf="refDetail.key === 'Last4'">
              <td class="td-line">Last 4: </td>
              <td class="td-line">{{ refDetail.value }}</td>
            </tr>
            <tr *ngIf="refDetail.key === 'RefID'">
              <td class="td-line">Ref ID: </td>
              <td class="td-line">{{ refDetail.value }}</td>
            </tr>
            <tr *ngIf="refDetail.key === 'EntryMethod'">
              <td class="td-line">Entry Method: </td>
              <td class="td-line">{{ refDetail.value }}</td>
            </tr>
          </ng-container>
        </table>
      </div>
    </ng-template>
  </div>
</div>
