/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { Col, DataType, FilterType } from "../../../../form-list/form-list/form-list";
import { FormListModel } from "../../../../form-list/form-list/form-list-model.interface";
import { AuthService } from "../../../../shared/services/auth.service";
import { DBService } from "../../../../shared/services/db.service";
import { CommonValidators } from "../../../../shared/validators/CommonValidators";
import { Cash_Type, CashDenomination } from "./cash-denomination";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";

@Injectable({
  providedIn: "root",
})
export class ZoneDenominationService extends DBService implements FormListModel {
  static readonly MODEL_NAME = "cashDenomination";
  enum_cash_types = this.enumSelectOptions(Cash_Type);

  constructor(
    protected http: HttpClient,
    protected authService: AuthService,
    private router: Router,
    private appSettings: AppSettingsStorageService
  ) {
    super(http, authService);
  }

  getRows(
    _model: string,
    _filter?: string,
    _offset?: number,
    _limit?: number,
    _sortField = "value",
    _sortOrder = 1,
    extraParams = {}
  ): Observable<any> {
    return super.getRows(_model, _filter, _offset, _limit, _sortField, _sortOrder, extraParams);
  }

  getZoneDenominations(zoneId = this.appSettings.getZoneId()): Observable<CashDenomination[]> {
    const zoneFilter = {
      zoneId: { value: zoneId, matchMode: "equals" },
    };
    return this.getRows("cashDenomination", JSON.stringify(zoneFilter), 0, 1000).pipe(
      map((response: any) => response.rows)
    );
  }

  getValidationRules(): { [key: string]: ValidatorFn[] | {} } {
    return {
      value: [Validators.required, CommonValidators.digits(), Validators.min(0.0001)],
      cashType: [Validators.required],
    };
  }

  getAsyncValidationRules?(): { [key: string]: AsyncValidatorFn | AsyncValidatorFn[] | {} } {
    return {
      // special key that means this validation should be applied to the whole form/object
      $global: this.validateDenominationIsUnique.bind(this),
    };
  }

  validateDenominationIsUnique(control: AbstractControl): Observable<ValidationErrors> {
    return this.checkUniqueDenomination(control.value).pipe(
      map((res: boolean) => {
        return res ? null : { zoneDenominationNotUnique: true };
      })
    );
  }

  // true means OK, false means validation error
  checkUniqueDenomination(denomination: CashDenomination): Observable<boolean> {
    let route = this.router.routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }

    const zoneId = +route.snapshot.params["zoneId"];
    if (!zoneId) return of(true);

    const filter: any = {
      value: { matchMode: "equals", value: parseFloat(denomination.value + "") },
      cashType: { matchMode: "equals", value: denomination.cashType },
      zoneId: { matchMode: "equals", value: zoneId },
    };
    // Do not take into account current record
    // exclude itself, so it's not initially invalid.
    if (denomination.id) filter.id = { matchMode: "ne", value: denomination.id };

    return this.getRows(ZoneDenominationService.MODEL_NAME, JSON.stringify(filter), 0, 1).pipe(
      map((res: any) => {
        return !(res.rows && res.rows.length);
      })
    );
  }

  getFormListColumns(): Col[] {
    return [
      {
        field: "value",
        header: "Value",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "cashType",
        header: "Cash Type",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enum_cash_types,
        filterType: FilterType.enum,
      },
    ];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
