/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable, inject } from "@angular/core";
import { DBService } from "src/app/shared/services/db.service";
import { FormListModel } from "src/app/form-list/form-list/form-list-model.interface";
import { ValidatorFn } from "@angular/forms";
import { Col, FilterType, DataType } from "src/app/form-list/form-list/form-list";
import { SaleDocType } from "src/app/core/document/doc/doc";
import { SaleBankReconciliationReport } from "./sale-bank-reconciliation-report";
import { DataNormalizerService } from "src/app/shared/services/data-normalizes.service";

@Injectable({
  providedIn: "root",
})
export class SaleBankReconciliationReportColsFactoryService {
  constructor(public dbService: DBService, private normalizerService: DataNormalizerService) {}

  build(dynamicFields: string[]) {
    return new SaleBankReconciliationReportCols(this, dynamicFields, this.normalizerService);
  }
}

export class SaleBankReconciliationReportCols implements FormListModel {
  normalizerService: DataNormalizerService;
  constructor(
    private factoryService: SaleBankReconciliationReportColsFactoryService,
    private dynamicFields: string[],
    normalizerService: DataNormalizerService
  ) {
    this.normalizerService = normalizerService;
  }
  enumDocumentTypes = this.factoryService.dbService.enumSelectOptions(SaleDocType).filter((row) => {
    return (
      row.value === SaleDocType.sales_invoice ||
      row.value === SaleDocType.sales_return ||
      row.value === SaleDocType.pre_order ||
      row.value === SaleDocType.recurring_order
    );
  });

  _disableCloning = true;
  getValidationRules(): { [key: string]: {} | ValidatorFn[] } {
    return {};
  }

  getFormListColumns(): Col[] {
    const cols: Col[] = [
      {
        field: "tenderTypesType",
        header: "Tender Type",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 120,
      },
      {
        field: "tenderTypesDescription",
        header: "Tender Description",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
      },
      {
        field: "tenderTypesCurrencyIsoCode",
        header: "Currency",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
      },
      {
        field: "sumSaleDocTendersAmount",
        header: "Gross Deposit",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
        footer: true,
        displayFn: (rowData: SaleBankReconciliationReport) => {
          return this.normalizerService.currencyNormalizer(
            rowData.sumSaleDocTendersAmount,
            rowData.tenderTypesCurrencyIsoCode
          );
        },
      },
      {
        field: "sumSaleDocTendersPercentageFee",
        header: "Percentage Fee",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
        displayFn: (rowData: SaleBankReconciliationReport) => {
          return this.normalizerService.normalizeNumbers(rowData.sumSaleDocTendersPercentageFee, 2, 2);
        },
      },
      {
        field: "sumSaleDocTendersFlatFee",
        header: "Flat Fee",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
        footer: true,
        displayFn: (rowData: SaleBankReconciliationReport) => {
          return this.normalizerService.currencyNormalizer(
            rowData.sumSaleDocTendersFlatFee,
            rowData.tenderTypesCurrencyIsoCode
          );
        },
      },
      {
        field: "netDepositCalculated",
        header: "Net Deposit",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 80,
        footer: true,
      },
    ];

    this.dynamicFields.forEach((field, index) => {
      // let _header = field;
      // cols.push(
      //   {
      //     field, header: _header, visible: index % 5 === 0, readonly: true, frozen: false, dataType: DataType.input,
      //     dataOptions: [], filterType: FilterType.contains, colWidth: 80
      //   }
      // )
    });

    return cols;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
