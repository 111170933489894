/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, HostBinding, Input, Output, EventEmitter } from "@angular/core";
import { TakuInputCtrlBase } from "../TakuInputCtrlBase";

@Component({
  selector: "taku-input",
  templateUrl: "./taku-input.component.html",
  styleUrls: ["./taku-input.component.scss"],
})
export class TakuInputComponent extends TakuInputCtrlBase {
  @Input() type = "text";
  @Input() inputmode?: string;

  @HostBinding("class.read-only")
  @Input()
  inputMask?: string;
  @Input() leftIconClass?: string;
  @Input() leftIconName?: string;
  @Input() rightIconClass?: string;
  @Input() rightIconName?: string;
  @Input() slotChar = "_";
  @Input() maxLength?: number;
  @Input() tabIndex?: number = null;
  @Input("numericMaskChar") _numericMaskChar?: string;
  @Input("alphaMaskChar") _alphaMaskChar?: string;
  @Input() unmask = true;
  // By default component doesn't validate input
  @Input() keyFilter: string | RegExp = /.*/g;
  @Input() maskCharacterPattern: string;
  @Input() autoSelect = false;
  /** See possible values here: https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete */
  @Input() autocomplete?: string;

  @Output() onBlur = new EventEmitter();
  @Output() onFocusOut = new EventEmitter();
  @Output() onInput = new EventEmitter<string>();

  isPasswordField = false;

  clear() {
    this.ctrlField.setValue("");
    this.ctrlField.markAsDirty();
  }

  ngOnInit(): void {
    if (this.type === "password") {
      this.isPasswordField = true;
    }
  }

  togglePasswordVisibility() {
    if (this.isPasswordField) {
      if (this.type === "password") {
        this.type = "text";
      } else if (this.type === "text") {
        this.type = "password";
      }
    }
  }

  focusAndSelectInput() {
    const inputField = this.el.nativeElement.querySelector("input");
    inputField.focus();
    inputField.select();
  }

  ngOnChanges() {
    // this.formGroup.setControl(this.fieldName, new FormControl(this.ctrlField.value, {
    //   validators: this.ctrlField.validator, updateOn: 'blur'
    // }));
  }

  inputChanges(event: Record<string, any>): void {
    this.onInput.emit(event.target.value);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
