/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivate } from "@angular/router";
import { Observable, of } from "rxjs";
import { DBService } from "src/app/shared/services/db.service";
import { map } from "rxjs/operators";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";

@Injectable({
  providedIn: "root",
})
export class SaleChannelGuard implements CanActivate {
  constructor(
    private router: Router,
    private dbService: DBService,
    private appSettingsService: AppSettingsStorageService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!next.queryParams.force) {
      const queryObject = {
        channelType: {
          value: next.queryParams.title,
          matchMode: "equals",
        },
        storeId: { value: next.params.storeId, matchMode: "equals" },
      };

      return this.dbService.getRows("saleChannel", JSON.stringify(queryObject)).pipe(
        map((result: any) => {
          if (result.count === 0) {
            if (this.appSettingsService.getStoreId()) {
              const queryParams = {};
              queryParams["title"] = next.queryParams.title;
              this.router.navigate(["/sale-channel/landing", next.params.storeId], { queryParams: queryParams });
            }
            return false;
          } else {
            return true;
          }
        })
      );
    } else {
      return true;
    }
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
