<div class="p-inputgroup inputgroup-outlined field-searchVendor">
  <span class="p-inputgroup-addon"><i class="material-icons">group</i></span>

  <taku-search-accounts
    id="supplier_search"
    #suppliersSearchBox
    [autoClearOnSelection]="false"
    [includeHeader]="false"
    [includeCreateNewBtns]="false"
    [searchFilter]="vendorsSearchFilter"
    placeholder="Supplier"
    (itemSelected)="onSearchResAccount($event)"
    (onSelectedItemCleared)="clearAccountField()"
    [accountType]="AccountType.commercial"
    [accountRelationship]="[AccountRelationship.supplier, AccountRelationship.both]"
  >
  </taku-search-accounts>
</div>
<div [ngClass]="{ 'is-disabled': !this.inventorySupplierFormGroup.controls.accountId.value }">
  <taku-inventory-supplier-product-detail
    [inventorySupplierFormGroup]="inventorySupplierFormGroup"
    [$supplierChangedListener]="$supplierChangedListener"
  ></taku-inventory-supplier-product-detail>
</div>
