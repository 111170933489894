/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

export class StoreHour {
  id: number;
  startDay: Week_Day;
  endDay: Week_Day;
  openTime: string;
  closeTime: string;

  constructor() {
    this.id = 0;
    this.startDay = Week_Day.monday;
    this.endDay = Week_Day.friday;
    this.openTime = "8:00";
    this.closeTime = "17:00";
  }
}

export enum Week_Day {
  monday = "Monday",
  Tuesday = "Tuesday",
  wednesday = "Wednesday",
  thursday = "Thursday",
  friday = "Friday",
  saturday = "Saturday",
  sunday = "Sunday",
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
