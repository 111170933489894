import { AccountType } from "./account/account";
import { CommercialAccount } from "./commercial-account/commercial-account";
import { PersonalAccount } from "./personal-account/personal-account";

export const isPersonalAccount = (acc: PersonalAccount | CommercialAccount): acc is PersonalAccount => {
  return acc?.account?.accountType === AccountType.personal;
};

export const isCommercialAccount = (acc: PersonalAccount | CommercialAccount): acc is CommercialAccount => {
  return acc?.account?.accountType === AccountType.commercial;
};
