/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { DBService } from "./db.service";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { BusinessDetail } from "../../core/settings/business-settings/business-detail/business-detail";
import { Zone } from "src/app/core/settings/zone-settings/Zone";
import appDefaults from "../json/app-defaults.json";
import * as _ from "lodash";
import { Inventory_Doc_Type } from "src/app/core/settings/zone-settings/inventory-doc-formats/InventoryDocFormat";
import { SaleDocType } from "src/app/core/document/doc/doc";

@Injectable({
  providedIn: "root",
})
export class AppDefaultsService {
  static readonly DEFAULT_KEY = "Default";

  static DefaultPennyRoundingForZone(zone: Zone) {
    const currency = zone.defaultCurrencyIsoCode;
    const key = _.has(appDefaults.pennyRounding, currency) ? currency : AppDefaultsService.DEFAULT_KEY;
    return appDefaults.pennyRounding[key];
  }

  static DefaultSaleDocFormats(docType: SaleDocType) {
    if (_.has(appDefaults.saleDocFormats, docType)) return appDefaults.saleDocFormats[docType];
    else return {};
  }

  constructor(private dbService: DBService) {}
  // returns iso code
  getDefaultAddressCountry(): Observable<string> {
    return this.dbService.getRow("businessDetail", 1).pipe(
      map((business: BusinessDetail) => {
        return business.address.countryIsoCode;
      })
    );
  }

  defaultZoneInfo(countryIsoCode: string) {
    if (_.has(appDefaults.zoneDefaults, countryIsoCode)) return appDefaults.zoneDefaults[countryIsoCode];
    else return {};
  }

  defaultTenders(currencyIsoCode: string) {
    if (_.has(appDefaults.defaultTenders, currencyIsoCode)) return appDefaults.defaultTenders[currencyIsoCode];
    else return [];
  }

  defaultDenominations(currencyIsoCode: string) {
    if (_.has(appDefaults.defaultDenominations, currencyIsoCode))
      return appDefaults.defaultDenominations[currencyIsoCode];
    else return [];
  }

  defaultInventoryDocFormats(inventoryDocType: Inventory_Doc_Type) {
    if (_.has(appDefaults.inventoryDocFormats, inventoryDocType))
      return appDefaults.inventoryDocFormats[inventoryDocType];
    else return {};
  }

  defaultTaxes(countryIsoCode: string) {
    if (_.has(appDefaults.defaultTaxes, countryIsoCode)) return appDefaults.defaultTaxes[countryIsoCode];
    else return [];
  }

  defaultTaxRules(countryIsoCode: string) {
    if (_.has(appDefaults.defaultTaxRules, countryIsoCode)) {
      return appDefaults.defaultTaxRules[countryIsoCode];
    } else return [];
  }

  // static DefaultTaxRules(countryIsoCode:string, taxAcronym:string){
  //     if (_.has(appDefaults.defaultTaxRules, countryIsoCode)){
  //         const taxRules:any[] = appDefaults.defaultTaxRules[countryIsoCode];
  //         return taxRules.filter(taxRule => taxRule.tax === taxAcronym);
  //     } else return [];
  // }

  defaultTaxAccountCategories(countryIsoCode: string) {
    if (_.has(appDefaults.defaultTaxAccountCategories, countryIsoCode))
      return appDefaults.defaultTaxAccountCategories[countryIsoCode];
    else return [];
  }

  defaultTaxInventoryCategories(countryIsoCode: string) {
    if (_.has(appDefaults.defaultTaxInventoryCategories, countryIsoCode))
      return appDefaults.defaultTaxInventoryCategories[countryIsoCode];
    else return [];
  }

  defaultUnitsOfMeasurement() {
    return appDefaults.defaultUnitsOfMeasurement || [];
  }

  defaultInventoryCats() {
    return appDefaults.defaultInventoryCategories || [];
  }

  defaultAccountGroups() {
    return appDefaults.defaultAccountGroups || [];
  }

  defaultUserRoles(): any[] {
    return appDefaults.defaultRoles || [];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
