<div [formGroup]="_myForm">
  <div class="grid inventory-sale-channel pt-2">
    <div class="col-4 xl:col-4 sm:col-12">
      <p-card class="card-header card-tableheader">
        <ng-template pTemplate="header" class="grid">
          <h2 class="col-12"> General </h2>
        </ng-template>
        <h3 class="col-12">
          <span *ngIf="!showUseChannelSetting">New Products</span>
          <span>
            <a
              *ngIf="!showUseChannelSetting"
              href="javascript://"
              class="pi pi-info-circle"
              (click)="globalSearchInfoPanel.toggle($event)"
            >
              <p-overlayPanel #globalSearchInfoPanel [showCloseIcon]="false" appendTo="body">
                <div class="tooltip-styling">
                  <h5>NOTE:</h5>
                  <ul>
                    <li>These settings will apply to all new products when they are added to TAKU.</li>
                  </ul>
                </div>
              </p-overlayPanel>
            </a>
          </span>
        </h3>
        <taku-checkbox
          *ngIf="!showUseChannelSetting"
          [formGroup]="_myForm"
          fieldName="autoEnableNewProduct"
          fieldCaption="Automatically upload new products"
          (onChange)="autoEnableNewProductChange()"
        >
        </taku-checkbox>
        <taku-checkbox
          *ngIf="!showUseChannelSetting"
          [formGroup]="_myForm"
          fieldName="autoPublishNewProduct"
          fieldCaption="Automatically publish new products"
          [disabled]="!_myForm.get('autoEnableNewProduct').value"
        >
        </taku-checkbox>

        <div class="grid">
          <div class="col-6" *ngIf="showUseChannelSetting">
            <taku-dropdown
              *ngIf="showUseChannelSetting"
              [formGroup]="_myForm"
              fieldName="uploadStatus"
              [options]="enum_upload_status"
              fieldCaption="Upload Status"
              appendTo="body"
              (onChange)="uploadStatusChange($event)"
            >
            </taku-dropdown>
          </div>
          <div class="col-6" *ngIf="showUseChannelSetting">
            <taku-dropdown
              *ngIf="showUseChannelSetting"
              [formGroup]="_myForm"
              fieldName="onlineStatus"
              [options]="enum_online_status"
              fieldCaption="Online Status"
              appendTo="body"
              [disabled]="_myForm.get('uploadStatus').value === 'No'"
            >
            </taku-dropdown>
          </div>
          <div class="col-12" *ngIf="showUseChannelSetting">
            <div>
              Channel SKU:
              <strong
                ><u
                  ><a (click)="navigateSaleChannelProduct()">{{ _myForm?.get("channelInventoryId")?.value }}</a></u
                ></strong
              >
            </div>
          </div>
        </div>
        <div class="col-12" *ngIf="showUseChannelSetting">
          <taku-checkbox
            *ngIf="showUseChannelSetting"
            [formGroup]="_myForm"
            fieldName="isFeatured"
            [disabled]="true"
            fieldCaption="Show as Featured"
          >
          </taku-checkbox>
          <span class="setting-note">NOTE: This applies to only linked sales channels that offer this option.</span>
        </div>
      </p-card>
    </div>

    <div class="col-4 xl:col-4 sm:col-12">
      <p-card class="products card-header card-tableheader">
        <ng-template pTemplate="header" class="grid">
          <h2 class="col-12"> Products </h2>
        </ng-template>
        <h3 class="col-12"> Product Details </h3>
        <div class="col-12" *ngIf="showUseChannelSetting">
          <p-radioButton
            name="productDetails"
            [value]="enumProductDetails.ChannelSetting"
            formControlName="productDetails"
            label="Use Channel Settings for this product"
          ></p-radioButton>
          <br />
        </div>

        <div class="col-12">
          <p-radioButton
            name="productDetails"
            [value]="enumProductDetails.MainProductTitle"
            formControlName="productDetails"
            label="Use Main Product Title"
          ></p-radioButton>
          <br />
        </div>

        <div class="col-12">
          <p-radioButton
            name="productDetails"
            [value]="enumProductDetails.AltProductTitle"
            formControlName="productDetails"
            label="Use Alt Product Title"
          ></p-radioButton>
          <br />
        </div>

        <hr *ngIf="!showUseChannelSetting" class="fields-divider" />

        <h3 *ngIf="!showUseChannelSetting" class="col-12"> Related Products </h3>
        <taku-checkbox
          *ngIf="!showUseChannelSetting"
          class="col-12"
          [formGroup]="_myForm"
          fieldName="canPushRelatedProducts"
          fieldCaption="Enable to show online shoppers other products they may like"
        >
        </taku-checkbox>
        <hr *ngIf="!showUseChannelSetting" class="fields-divider" />

        <h3 *ngIf="!showUseChannelSetting" class="col-12">
          <span>Categories</span>
          <span>
            <a href="javascript://" class="pi pi-info-circle" (click)="globalSearchInfoPanel.toggle($event)">
              <p-overlayPanel #globalSearchInfoPanel [showCloseIcon]="false" appendTo="body">
                <div class="tooltip-styling">
                  <h5>NOTE:</h5>
                  <ul>
                    <li
                      >All categories are linked 1-way from TAKU Inventry Categories to your online sales channel the
                      first time the above checkbox is enabled.</li
                    >
                    <li>
                      <span
                        >To update your categories everywhere, always update them in TAKU Inventory Categories first and
                        then click the refresh button above to update your online channel categories.</span
                      >
                    </li>
                  </ul>
                </div>
              </p-overlayPanel>
            </a>
          </span>
        </h3>
        <div *ngIf="!showUseChannelSetting" class="grid">
          <taku-checkbox
            class="col-9 xl:col-10"
            [formGroup]="_myForm"
            fieldName="canPushInventoyCategories"
            fieldCaption="Create from TAKU Inventory Category"
          >
          </taku-checkbox>
          <span class="refresh-btn col-3 xl:col-2">
            <button
              pButton
              pRipple
              type="button"
              icon="pi pi-refresh"
              (click)="onSyncCategoriesClick.emit()"
              class=""
              [loading]="isRefreshingCategories"
            ></button>
            <!-- <a href="javascript://" class="pi pi-refresh" (click)="onSyncCategoriesClick.emit()"
              style="font-size: 1.5rem;"></a> -->
          </span>
        </div>
      </p-card>
    </div>

    <div class="col-4 xl:col-4 sm:col-12">
      <p-card class="card-header card-tableheader">
        <ng-template pTemplate="header" class="grid">
          <h2 class="col-12"> Quantity </h2>
        </ng-template>
        <h3 class="col-12"> Stock Control </h3>
        <taku-checkbox
          *ngIf="!showUseChannelSetting"
          [formGroup]="_myForm"
          fieldName="enableAutoOrderAccept"
          fieldCaption="Approve channel orders & auto-deduct Available Qty immediately"
        >
        </taku-checkbox>
        <div *ngIf="showUseChannelSetting" class="col-12">
          <p-radioButton
            name="stockControl"
            [value]="enumStockControl.ChannelSetting"
            formControlName="stockControl"
            label="Use Channel Settings for this product"
          ></p-radioButton>
        </div>
        <div class="col-12">
          <p-radioButton
            name="stockControl"
            [value]="enumStockControl.Quantity"
            formControlName="stockControl"
            label="Track Stock Quantity"
          ></p-radioButton>
        </div>
        <div class="col-12">
          <p-radioButton
            name="stockControl"
            [value]="enumStockControl.Unlimited"
            formControlName="stockControl"
            label="Do Not Track Stock Quantity"
          ></p-radioButton>
        </div>
        <hr class="fields-divider" />

        <h3 class="col-12"> Price </h3>
        <div *ngIf="showUseChannelSetting" class="col-12">
          <p-radioButton
            name="priceControl"
            [value]="enumPriceControl.ChannelSetting"
            formControlName="priceControl"
            label="Use Channel Settings for this product"
          ></p-radioButton>
        </div>
        <div class="col-12">
          <p-radioButton
            name="priceControl"
            [value]="enumPriceControl.SellPrice"
            formControlName="priceControl"
            label="Use Sell Price as base price online"
          ></p-radioButton>
        </div>

        <div class="col-12">
          <p-radioButton
            name="priceControl"
            [value]="enumPriceControl.AltPrice"
            formControlName="priceControl"
            label="Use Alt Price as base price online"
          ></p-radioButton>
        </div>

        <div class="col-12">
          <p-radioButton
            name="priceControl"
            [value]="enumPriceControl.SellAltPrice"
            formControlName="priceControl"
            label="Use Sell Price as discounted price and Alt Price as base price"
          ></p-radioButton>
        </div>
        <div class="col-12">
          <img
            src="/assets/layout/images/sale-channel/sale-channel-price-setting.png"
            alt=" TAKU Sale Channel Price Setting"
            style="height: 50px"
          />
        </div>
      </p-card>
    </div>
  </div>
</div>
