/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Address } from "src/app/core/contact-accounts/address/address";
import { TenderType } from "../../../business-settings/tender-type/tender-type";
import { Store } from "../../../store-settings/store/store";
import { TakuPayAccountDetail } from "../taku-pay-account-details/taku-pay-account-details";

export class TakuPaymentGateway {
  id: number;
  locationId: string;
  externalLocationId: string;
  displayName: string;
  paymentGatewayType: string;
  requestId: string;
  isActive: boolean;
  storeId: number;
  store: Store;
  tenderTypes: TenderType[];
  accountId: string;
  takuPaymentAccount: TakuPayAccountDetail;
  takuPaymentAccountId: number;
  address: Address;

  constructor() {
    this.id = 0;
    this.locationId = null;
    this.externalLocationId = "";
    this.displayName = "";
    this.paymentGatewayType = "takuPaymentGateway";
    this.requestId = "";
    this.isActive = true;
    this.storeId = null;
    this.store = null;
    this.tenderTypes = [];
    this.accountId = "";
    this.takuPaymentAccountId = null;
    this.address = new Address();
  }
}

export enum TakuPaymentGatewayShortname {
  TAKUPAY_SHORTNAME = "TP",
}
/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
