<taku-form-header
  [toolbarTitle]="_pageTitle"
  displayMode="inline"
  [showActionBtns]="false"
  [showBackBtn]="true"
  (backClicked)="goBack()"
>
  <span
    class="doc-state"
    [ngClass]="
      _myForm?.get('doc.state')?.value ? 'text-' + _myForm?.get('doc.state')?.value?.replace('', '') + '-Color' : ''
    "
  >
    {{ _myForm?.get("doc.state").value }}
  </span>
</taku-form-header>

<div *ngIf="_myForm" class="col-12 purchase-container">
  <div class="grid">
    <div class="col-12 md:col-6 xl:col-3">
      <p-card class="card-header card-tableheader auto-height">
        <ng-template pTemplate="header">
          <h2 class="col editIconHeader">
            <span>Supplier Details</span>
            <i
              class="material-icons"
              [ngClass]="{ 'is-disabled': isReadOnly }"
              (click)="editCommercialAccount(_myForm.controls.account.value.commercialAccount.id)"
            >
              edit
            </i>
          </h2>
        </ng-template>

        <div class="col-12">
          <div
            class="p-inputgroup inputgroup-outlined field-searchVendor"
            [ngClass]="{ 'disabled-control': isReadOnly }"
          >
            <span class="p-inputgroup-addon"><i class="material-icons">group</i></span>
            <taku-search-accounts
              id="account_search"
              #accountsSearchBox
              [autoClearOnSelection]="false"
              (onSelectedItemCleared)="clearAccountField()"
              [placeholder]="_searchVendorPlaceholder"
              (itemSelected)="onSearchResAccount($event)"
              (onNewItemClicked)="openNewModelInDialog($event)"
              [accountType]="AccountType.commercial"
              [accountRelationship]="[AccountRelationship.supplier, AccountRelationship.both]"
              [disabled]="true"
            >
            </taku-search-accounts>
          </div>
        </div>

        <div class="col-12">
          <div class="shipping-address-id">
            <taku-dropdown
              [formGroup]="_myForm"
              [options]="shippingAddressOptions"
              fieldName="shippingAddressId"
              fieldCaption="Supplier Address"
              [disabled]="isReadOnly || _isSaving"
            ></taku-dropdown>
          </div>

          <div class="shipping-contact mt-3">
            <taku-dropdown
              [formGroup]="_myForm"
              [options]="shippingContactOptions"
              fieldName="shippingContactId"
              fieldCaption="Contact"
              [disabled]="isReadOnly || _isSaving"
            ></taku-dropdown>
          </div>
        </div>

        <div class="col-12 pt-0">
          <taku-input
            [formGroup]="_myForm"
            fieldName="supplierInstruction"
            fieldCaption="Supplier Instructions"
            [disabled]="isReadOnly || _isSaving"
          >
          </taku-input>
        </div>
      </p-card>
    </div>
    <div class="col-12 md:col-6 xl:col-3">
      <p-card class="card-header card-tableheader auto-height">
        <ng-template pTemplate="header">
          <h2 class="col">Shipping Details </h2>
        </ng-template>
        <div class="grid">
          <div class="col-12 field-stockName">
            <taku-dropdown
              [formGroup]="_myForm"
              [options]="stockOptions"
              fieldName="stockId"
              fieldCaption="Receiving Location"
              [disabled]="isReadOnly || _isSaving"
              [readonly]="true"
            ></taku-dropdown>
          </div>

          <div class="col-12 grid">
            <div
              class="col-10 shipToAddressText"
              [ngClass]="{
                underline: !isReadOnly,
                'cursor-pointer': !isReadOnly,
                'is-disabled': isReadOnly || _isSaving,
                'ship-to-address-required':
                  !_myForm.controls.stockAddress.valid && !_myForm.controls.stockAddress.pristine
              }"
              (click)="openStockAddressPanel()"
            >
              Ship To:
              {{
                _myForm.controls.stockAddress.valid
                  ? _myForm.controls.stockAddress.value.line1
                  : "[Missing Required Fields]"
              }}
            </div>

            <div class="col-2" *ngIf="!isReadOnly">
              <button
                pButton
                pRipple
                icon="pi pi-pencil"
                class="mt-1 p-button-text p-button-rounded p-button-icon-only"
                [ngClass]="{
                  'p-button-danger': !_myForm.controls.stockAddress.valid && !_myForm.controls.stockAddress.pristine
                }"
                (click)="openStockAddressPanel()"
              ></button>
            </div>
          </div>

          <div class="col-12 field-comment">
            <taku-input
              [formGroup]="_myForm"
              fieldName="deliveryInstruction"
              fieldCaption="Delivery Instructions"
              [disabled]="isReadOnly || _isSaving"
            >
            </taku-input>
          </div>

          <div class="col-6">
            <taku-calendar-date
              [formGroup]="shippingDateForm"
              fieldName="shippingDay"
              [minDate]="todayDate"
              fieldCaption="Ship Date"
              [disabled]="isReadOnly || _isSaving"
            >
            </taku-calendar-date>
          </div>
          <div class="col-6">
            <taku-calendar-time
              [formGroup]="shippingDateForm"
              fieldName="shippingTime"
              fieldCaption="Ship Time"
              [disabled]="isReadOnly || _isSaving"
            >
            </taku-calendar-time>
          </div>

          <div class="col-6">
            <taku-dropdown
              [formGroup]="_myForm"
              [options]="$lookup_shippingTerms | async"
              fieldName="shippingTermId"
              fieldCaption="Shipping Terms"
              [disabled]="isReadOnly || _isSaving"
            ></taku-dropdown>
          </div>

          <div class="col-6">
            <taku-input
              [formGroup]="_myForm"
              fieldName="shipVia"
              fieldCaption="Ship Via"
              [disabled]="isReadOnly || _isSaving"
            >
            </taku-input>
          </div>
        </div>
      </p-card>
    </div>
    <div class="col-12 xl:col-6">
      <p-card class="card-header card-tableheader auto-height">
        <ng-template pTemplate="header">
          <h2 class="col">PO Details </h2>
        </ng-template>
        <div class="grid">
          <div class="col-12 md:col-12 xl:col-6 grid">
            <div class="col-6">
              <taku-input
                [formGroup]="_myForm.controls['doc']"
                fieldName="docNo"
                fieldCaption="PO Doc #"
                [disabled]="isReadOnly || _isSaving"
              >
              </taku-input>
            </div>

            <div class="col-6 field-currency">
              <taku-input
                [formGroup]="_myForm.controls['doc']"
                fieldName="currencyIsoCode"
                fieldCaption="Currency"
                [disabled]="isReadOnly || _isSaving"
                [readonly]="true"
              >
              </taku-input>
            </div>

            <div class="col-6">
              <taku-calendar-date
                [formGroup]="_myForm.controls['doc']"
                fieldName="docDate"
                fieldCaption="Order Date"
                [disabled]="isReadOnly || _isSaving"
              >
              </taku-calendar-date>
            </div>
            <div class="col-6">
              <taku-calendar-time
                [formGroup]="_myForm.controls['doc']"
                fieldName="docTime"
                fieldCaption="Order Time"
                [disabled]="isReadOnly || _isSaving"
              >
              </taku-calendar-time>
            </div>

            <!-- Guess we don't need to display this? -->
            <!-- <div class="col-6 field-currency">
                <taku-input
                  [formGroup]="_myForm.get('doc')"
                  fieldName="currencyConversionRate"
                  fieldCaption="Currency Conversion"
                  [disabled]="isReadOnly"
                  [readonly]="true"
                >
                </taku-input>
              </div> -->
            <div class="col-12">
              <taku-dropdown
                [formGroup]="_myForm"
                [options]="$lookup_billToLocations | async"
                fieldName="locationId"
                fieldCaption="Bill to Location"
                [disabled]="isReadOnly || _isSaving"
                [group]="true"
              ></taku-dropdown>
            </div>

            <div class="col-6">
              <taku-input
                [formGroup]="_myForm"
                fieldName="buyerName"
                fieldCaption="Buyer's Name"
                [disabled]="isReadOnly || _isSaving"
              >
              </taku-input>
            </div>

            <div class="col-6">
              <taku-dropdown
                [formGroup]="_myForm"
                [options]="$lookup_paymentTerms | async"
                fieldName="paymentTermId"
                fieldCaption="Payment Terms"
                [disabled]="isReadOnly || _isSaving"
              ></taku-dropdown>
            </div>

            <div class="col-12 field-comment">
              <taku-input
                [formGroup]="_myForm.controls['doc']"
                fieldName="comment"
                fieldCaption="Internal Notes"
                [disabled]="isReadOnly || _isSaving"
              >
              </taku-input>
            </div>
          </div>
          <div class="col-12 md:col-12 xl:col-6 grid">
            <div class="col-6 p-fluid">
              <button
                label="Save Draft"
                pButton
                class="p-button-secondary"
                (click)="onSaveDraft()"
                [disabled]="
                  isReadOnly || _myForm.get('doc.state').value !== DocState.draft || isInvalidForm || isPristineForm
                "
                [loading]="isSavingDraft && _isSaving"
              ></button>
            </div>

            <div class="col-6 col-offset-0 p-fluid">
              <button
                label="Finalize"
                pButton
                (click)="onDonePressed()"
                [disabled]="
                  isReadOnly ||
                  isInvalidForm ||
                  !(isPristineForm && _myForm.get('doc.state').value === DocState.draft) ||
                  isFinalizing ||
                  _isSaving
                "
                [loading]="isFinalizing && _isSaving"
              ></button>
            </div>

            <div class="col-12 subTotal-container">
              <div class="card">
                <div class="col-12 invoice-summary">
                  <div class="line">
                    <div class="col1">
                      <b>Sub-Total</b>
                    </div>
                    <div class="col2">
                      <b>{{ docCurrencyControl.value }} {{ purchaseDocSubTotal | number : _moneyFormat }}</b>
                    </div>
                  </div>

                  <div class="line general-discount-line">
                    <div
                      class="col1 subTotalDialogAction"
                      [ngClass]="{ readonly: isReadOnly }"
                      (click)="toggleOverlay('discount', $event); onDiscAmountClicked()"
                    >
                      <ins>- Discount</ins>
                      <span
                        class="hidden"
                        *ngIf="
                          _myForm.controls.discountType.value === 'Percentage' && _myForm.controls.discountAmount.value
                        "
                      >
                        ( {{ _myForm.controls.discountAmount.value | number : _decimalFormatting }}% )
                      </span>
                    </div>
                    <div class="col2"
                      >{{ docCurrencyControl.value }} {{ purchaseDocGeneralDiscount | number : _moneyFormat }}</div
                    >

                    <!-- <div class="overlay-arrowPointer card" [hidden]="!_overlaysVisibility.discount"></div> -->
                    <div class="overlay-container card grid" [hidden]="!_overlaysVisibility.discount">
                      <p-button
                        type="button"
                        class="p-button-rounded p-button p-button-icon-only close-btn"
                        icon="pi pi-times"
                        (onClick)="closeOverlay('discount')"
                      ></p-button>

                      <div class="col-12 general_disccount-label">
                        <h4>Discount on Sub-Total</h4>
                      </div>
                      <div class="p-fluid col-12 sm:col-4 chg-discount-type" [style.marginTop]="'3px'">
                        <form [formGroup]="discountForm">
                          <p-selectButton
                            [options]="enumDiscountType"
                            [formGroup]="discountForm"
                            formControlName="discountType"
                          ></p-selectButton>
                        </form>
                      </div>
                      <div class="col-12 sm:col-8 chg-discount">
                        <div class="p-inputgroup chg-amount">
                          <span
                            *ngIf="discountForm.controls.discountType.value === 'Percentage'"
                            class="p-inputgroup-addon chg-border"
                            >%</span
                          >
                          <span
                            *ngIf="discountForm.controls.discountType.value === 'Fixed'"
                            class="p-inputgroup-addon chg-border"
                            >{{ _myForm.get("doc.currencyIsoCode").value }}</span
                          >
                          <taku-input
                            #generalDiscountAmount
                            (click)="onDiscAmountClicked()"
                            [formGroup]="discountForm"
                            fieldName="discountAmount"
                            [fieldCaption]="discountForm.controls.discountAmount.errors ? '' : 'Amount'"
                            (onBlur)="setZeroIfEmpty('discountAmount')"
                            inputmode="numeric"
                          ></taku-input>
                          <span class="p-inputgroup-addon pt-0">
                            <p-button
                              icon="takuicon-calculator"
                              styleClass="squared-corners"
                              (onClick)="numpadDiscount.open()"
                            >
                            </p-button>
                            <taku-numpad
                              #numpadDiscount
                              padTitle="Enter Discount"
                              [numpadType]="
                                discountForm.controls.discountType.value === 'Percentage' ? 'percentage' : 'monetary'
                              "
                              [initialQty]="discountForm.controls.discountAmount.value"
                              [maximumQty]="maxDiscountAmount"
                              (submitQty)="onNumpadDiscountAmount($event)"
                            ></taku-numpad>
                          </span>
                        </div>
                      </div>
                      <div class="col-12 grid">
                        <span class="col-9"> </span>
                        <button
                          class="col-3"
                          pButton
                          label="Apply"
                          (click)="onDiscountApplyClick(); closeOverlay('discount')"
                        >
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="line general-taxes-line">
                    <div
                      class="col1 subTotalDialogAction"
                      [ngClass]="{ readonly: isReadOnly }"
                      (click)="toggleOverlay('taxes', $event)"
                    >
                      <ins>+ Taxes</ins>
                    </div>
                    <div class="col2">
                      {{ docCurrencyControl.value }} {{ purchaseDocTotalTax | number : _moneyFormat }}</div
                    >

                    <div class="overlay-container card" [hidden]="!_overlaysVisibility.taxes">
                      <p-button
                        type="button"
                        class="p-button-rounded p-button-icon-only p-button close-btn"
                        icon="pi pi-times"
                        (onClick)="closeOverlay('taxes')"
                      ></p-button>
                      <div class="col-12 salesTaxes-overlay">
                        <table *ngIf="groupedTaxes.length">
                          <ng-template ngFor [ngForOf]="groupedTaxes" let-tax>
                            <tr class="tax-info">
                              <td class="tax-desc">
                                <span>{{
                                  tax?.saleTaxRule?.ruleName
                                    ? tax?.saleTaxRule?.ruleName
                                    : tax?.saleTaxRule?.tax?.shortDesc
                                }}</span>
                              </td>
                              <td class="tax-amount">
                                <span>
                                  {{ _myForm.get("doc.currencyIsoCode").value }}
                                  {{ tax?.total | number : _moneyFormat }}
                                </span>
                              </td>
                            </tr>

                            <tr class="separator">
                              <td colspan="2">
                                <hr />
                              </td>
                            </tr>
                          </ng-template>
                        </table>

                        <div *ngIf="!groupedTaxes.length" class="no-taxes">There are no taxes for this sale yet.</div>
                      </div>
                    </div>
                  </div>
                  <div class="line general-shipping-line">
                    <div
                      class="col1 subTotalDialogAction"
                      [ngClass]="{ readonly: isReadOnly }"
                      (click)="toggleOverlay('shipping', $event); onShippingAmountClicked()"
                    >
                      <ins>+ Freight</ins>
                      <span class="hidden" *ngIf="_myForm.controls.shipperChargeAmount.value">
                        ( {{ _myForm.controls.shipperChargeAmount.value | number : _decimalFormatting }}% )
                      </span>
                    </div>
                    <div class="col2">
                      {{ docCurrencyControl.value }}
                      {{ _myForm.controls.shipperChargeAmount.value | number : _moneyFormat }}
                    </div>

                    <!-- <div class="overlay-arrowPointer card" [hidden]="!_overlaysVisibility.discount"></div> -->
                    <div class="overlay-container card grid" [hidden]="!_overlaysVisibility.shipping">
                      <p-button
                        type="button"
                        class="p-button-rounded p-button p-button-icon-only close-btn"
                        icon="pi pi-times"
                        (onClick)="closeOverlay('shipping')"
                      ></p-button>

                      <div class="col-12 general_shipping-label">
                        <h4>Freight on Sub-Total</h4>
                      </div>
                      <div class="col-12 sm:col-8 chg-discount">
                        <div class="p-inputgroup chg-amount">
                          <span class="col mt-3">{{ _myForm.get("doc.currencyIsoCode").value }}</span>
                          <taku-input
                            #generalShippingAmount
                            (click)="onShippingAmountClicked()"
                            [formGroup]="shippingForm"
                            fieldName="shipperChargeAmount"
                            [fieldCaption]="'Freight'"
                            (onBlur)="setZeroIfEmpty('shipperChargeAmount')"
                            inputmode="numeric"
                          ></taku-input>
                          <span class="p-inputgroup-addon pt-0">
                            <p-button
                              icon="takuicon-calculator"
                              styleClass="squared-corners"
                              (onClick)="numpadShipping.open()"
                            >
                            </p-button>
                            <taku-numpad
                              #numpadShipping
                              padTitle="Enter Freight"
                              [numpadType]="'monetary'"
                              [initialQty]="shippingForm.controls.shipperChargeAmount.value"
                              (submitQty)="onNumpadShippingAmount($event)"
                            ></taku-numpad>
                          </span>
                        </div>
                      </div>
                      <div class="col-12 grid">
                        <span class="col-9"> </span>
                        <button
                          class="col-3"
                          pButton
                          label="Apply"
                          (click)="onShippingApplyClick(); closeOverlay('shipping')"
                        >
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 lg:col-6 sm:col-6 col-offset-0 p-fluid">
              <button
                label="Void"
                pButton
                class="p-button-secondary"
                (click)="onVoid()"
                [disabled]="isReadOnly || _myForm.get('doc.state').value !== DocState.finalized || isInvalidForm"
              ></button>
            </div>

            <div class="col-12 lg:col-6 sm:col-6 col-offset-0 p-fluid">
              <div class="doc-summary card grid">
                <span class="total-label">TOTAL:</span>
                <span class="total-value">
                  {{ docCurrencyControl.value }} {{ purchaseDocTotal | number : _moneyFormat }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </p-card>
    </div>
  </div>

  <footer class="col-12">
    <p-card class="card-header card-tableheader">
      <ng-template pTemplate="header">
        <h2 class="col">Purchase Order</h2>
      </ng-template>
      <div class="grid">
        <div class="col-12 lg:col-3">
          <button
            pButton
            pRipple
            label="Show Full Supplier List"
            class="p-button-primary w-full"
            (click)="showFullSupplierListDialogClicked()"
            [disabled]="isReadOnly || _isSaving"
          ></button>
        </div>
        <div class="col-12 lg:col-3"></div>
        <div class="col-12 lg:col-6">
          <div class="p-inputgroup inputgroup-outlined field-searchInventory" [ngClass]="{ 'is-disabled': isReadOnly }">
            <span class="p-inputgroup-addon"><i class="material-icons">local_offer</i></span>
            <taku-search-inventory
              id="inventory_search"
              #inventorySearchBox
              [autoClearOnSelection]="true"
              placeholder="Search for Items"
              (itemSelected)="onSearchResInventory($event)"
              (onNewItemClicked)="openNewModelInDialog($event)"
              [disabled]="isReadOnly || _isSaving"
              [showAnimatePlaceholder]="false"
              [searchFilter]="searchInventoryItemsFilter"
            >
            </taku-search-inventory>
          </div>
        </div>
      </div>
    </p-card>
  </footer>

  <div class="col-12 inventory-wrapper">
    <taku-form-list
      *ngIf="purchaseDocLineCols"
      #formListInventory
      [_hideViewCol]="true"
      _model="purchaseDocLine"
      [_hideSavingCtrls]="true"
      [_showCtrlsInEditMode]="true"
      _title="Purchase Order"
      [_hideAddLineHeaderBtn]="true"
      _detailMode="n"
      (_onRowDeleted)="onDocLineDeleted()"
      _scrollHeight="calc(100vh - 17rem)"
      [_filter]="_formFilters"
      [_cols]="purchaseDocLineCols"
      [_defaultRowValues]="_defaultRowValues"
      [_hideClone]="true"
      [_defaultRows]="1000"
      [_pageSizes]="[1000]"
      [_requiredColsComeFirst]="false"
      [_dataService]="localDataService"
      [_hideDeleteHeaderBtn]="isReadOnly"
      [_hideGlobalFilter]="true"
      [defaultSortField]="'docLine.seqNo'"
    >
      <div takuFormListHeaderCustomBtns>
        <button
          *ngIf="!isReadOnly"
          label="Order Max Stock Qty"
          pButton
          pRipple
          type="button"
          class="header-button"
          [disabled]="!(formListInventory._selectedObjects?.length > 0)"
          (click)="promptOrderMaxStockQty()"
        ></button>
      </div>
    </taku-form-list>
  </div>

  <div class="grid col footer">
    <div class="col-12 md:col-6 actionsBtn-wrapper">
      <button
        pButton
        icon="pi pi-print"
        label="Print"
        (click)="printPurchaseOrder($event)"
        [loading]="_isPrintLoading"
        [disabled]="_myForm.dirty || _isSaving"
      ></button>
      <button
        pButton
        icon="pi pi-envelope"
        label="Email Order"
        [disabled]="_myForm.get('doc.state').value !== DocState.finalized"
        [loading]="isSendingEmail"
        (click)="sendEmail()"
      ></button>
      <button
        pButton
        icon="pi pi-history"
        label="Email History"
        [disabled]="!_myForm.controls.emailHistory.value?.length"
        (click)="showEmailHistoryDialog = true"
      ></button>
      <button
        pButton
        icon="pi pi-download"
        label="Export"
        (click)="exportPurchaseOrders()"
        [disabled]="_myForm.dirty || _isSaving"
      ></button>
    </div>

    <div class="col-12 md:col-2 total-items">
      <p
        >Total Items: <b>{{ totalItems }}</b></p
      >
    </div>
    <div class="col-12 md:col-4 date-container">
      <p *ngIf="this._myForm.controls.createdAt.value"
        >Creation date: {{ this._myForm.controls.createdAt.value | dateTimeFormat }}</p
      >
      <p *ngIf="this._myForm.controls.updatedAt.value"
        >Last modified date: {{ this._myForm.controls.updatedAt.value | dateTimeFormat }}</p
      >
    </div>
  </div>

  <!-- </p-card> -->

  <p-dialog
    [(visible)]="showFullSupplierListDialog"
    [showHeader]="true"
    (onHide)="showFullSupplierListDialog = false"
    [style]="{ maxWidth: '90%' }"
    class="fullsize-modal"
    [blockScroll]="false"
    [modal]="false"
    appendTo="body"
  >
    <div class="flex">
      <p-tabMenu
        #supplierDialogTabMenu
        [model]="supplierDialogTabMenuItems"
        [activeItem]="supplierDialogTabMenuItems[1]"
      ></p-tabMenu>
      <button
        pButton
        pRipple
        icon="pi pi-info-circle"
        class="order-completion-menu-info-tooltip p-button-text p-button-rounded p-button-icon-only"
        (click)="tabMenuInfoPanel.toggle($event)"
      >
        <p-overlayPanel #tabMenuInfoPanel [showCloseIcon]="false" appendTo="body">
          <div class="tooltip-styling">
            <h5>What is "Low Stock Only"?</h5>
            <p>
              This filter will only display products where the <i>Default OH Qty</i> is less than or equal to the
              <i>Min Stock Qty</i> for this supplier
            </p>
          </div>
        </p-overlayPanel>
      </button>
    </div>
    <taku-form-list
      #supplierInventoryFormListModal
      *ngIf="_isShowFullSupllierFormListDataReady && _showFullSupllierFormListCols"
      _model="inventory"
      _detailMode="n"
      _title="Inventory"
      [_cols]="_showFullSupllierFormListCols"
      [_filter]="showFullSupllierFormListNoChildrenFilter"
      [_extraQueryParams]="fullSupplierFormListExtraQueryParams"
      [_dataService]="inventorySupplierListService"
      [_modelService]="inventorySupplierListService"
      _scrollHeight="calc(90vh - 23rem)"
      [_hideViewCol]="true"
      [_bulkEditorEnabled]="true"
      [_dialogShowHeader]="false"
      [_hideSavingCtrls]="true"
      [_showCtrlsInEditMode]="false"
      [_hideAddLineHeaderBtn]="true"
      [_hideOptionsHeaderBtn]="true"
      [_hideAddHeaderBtn]="true"
      [_hideDeleteHeaderBtn]="true"
      [_hideClone]="true"
      [_showHeader]="true"
      [_rowSelectionType]="RowSelectionType.MULTIPLE"
      [_modelValidation]="_showFullSupllierFormListRules"
      _dialogStyleClass="form-list-dialog-style"
      _stateKey="fullSupplierListDialog"
      [_saveFormState]="false"
    >
      <div takuFormListHeaderCustomBtns>
        <button
          label="Add Selected Items to PO"
          pButton
          pRipple
          type="button"
          class="header-button"
          [disabled]="!supplierInventoryFormListModal._selectedObjects?.length"
          (click)="addSelectedRowsToPO()"
        ></button>
      </div>
    </taku-form-list>
  </p-dialog>

  <p-dialog
    [(visible)]="_activeFullDialog"
    [ngSwitch]="_activeFullDialog"
    [contentStyle]="{ maxHeight: '100%' }"
    styleClass="fixedsize-modal"
    [blockScroll]="true"
    [modal]="true"
    [showHeader]="false"
  >
    <div *ngSwitchCase="FullSizeDialogName.COMMERCIAL_ACCOUNT" class="new-commercialAccount-panel">
      <taku-commercial-account
        #commercialAccountDialogForm
        (changedForm)="onNewCommercialAccountCreated($event)"
        [_isDialog]="true"
        [_object]="_activeFullDialogExtra"
        (dialogClosed)="closeFullSizeDialog()"
      ></taku-commercial-account>
    </div>

    <div *ngSwitchCase="FullSizeDialogName.NEW_INVENTORY" class="inventory-panel">
      <taku-inventory
        (changedForm)="onNewInventoryItem($event)"
        [_isDialog]="true"
        [_object]="_activeFullDialogExtra"
        (dialogClosed)="closeFullSizeDialog()"
      ></taku-inventory>
    </div>

    <div *ngSwitchCase="FullSizeDialogName.EDIT_INVENTORY" class="inventory-panel">
      <taku-inventory
        (changedForm)="onChangedDocLineInventory($event)"
        [_isDialog]="true"
        [_object]="_activeFullDialogExtra"
        (dialogClosed)="closeFullSizeDialog()"
      ></taku-inventory>
    </div>
  </p-dialog>

  <p-dialog
    [(visible)]="showStockAddressEditDialog"
    [blockScroll]="true"
    [style]="{ maxWidth: '90%' }"
    (onHide)="showStockAddressEditDialog = false"
    [modal]="true"
    [closable]="false"
    [showHeader]="false"
  >
    <taku-form-header
      [showBackBtn]="false"
      [showActionBtns]="true"
      [showSecondaryBtns]="false"
      [formGroup]="_myForm.controls.stockAddress"
      toolbarTitle="Ship To Address"
      displayMode="inline"
      (revertClicked)="onCloseStockAddressPannel(false)"
      (saveClicked)="onSaveStockAddressPanel()"
      [iconStyleClass]="'material-icons'"
    >
      <button
        pButton
        class="p-button-rounded"
        type="button"
        (click)="onCloseStockAddressPannel()"
        icon="pi pi-times"
      ></button>
    </taku-form-header>
    <taku-address class="col-12" [formGroup]="_myForm.controls.stockAddress"></taku-address>
  </p-dialog>
  <p-dialog
    [(visible)]="showEmailHistoryDialog"
    [blockScroll]="true"
    [style]="{ maxWidth: '90%' }"
    (onHide)="showEmailHistoryDialog = false"
    [modal]="true"
    [closable]="false"
    [showHeader]="false"
  >
    <taku-form-header
      [showBackBtn]="false"
      [showActionBtns]="false"
      [showSecondaryBtns]="false"
      toolbarTitle="Email History"
      displayMode="inline"
    >
      <button
        pButton
        class="p-button-rounded"
        type="button"
        (click)="showEmailHistoryDialog = false"
        icon="pi pi-times"
      ></button>
    </taku-form-header>
    <p-table
      [value]="_myForm.controls.emailHistory?.value"
      styleClass="p-datatable-striped"
      responsiveLayout="stack"
      breakpoint="640px"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>Date/Time</th>
          <th>Email</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-email>
        <tr>
          <td class="p-3">
            <span class="p-column-title">Date/Time</span>
            {{ email.timestamp | dateTimeFormat }}
          </td>
          <td class="p-3">
            <span class="p-column-title">Email</span>
            {{ email.email }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </p-dialog>
</div>

<!-- Template for SKU Link -->
<ng-template #skuTpl let-rowData let-showDetailsEvent="data.showInventoryEvent">
  <div class="sku-col">
    <a href="javascript://" (click)="showDetailsEvent.emit(rowData)">{{ rowData.docLine?.inventory?.sku }}</a>
  </div>
</ng-template>

<div [hidden]="true">
  <ng-template #printPreviewHost></ng-template>
</div>
