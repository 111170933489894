/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../shared/services/auth.service";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private auth: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // Store the attempted URL for redirecting
    const url: string = state.url;
    return this.checkLogin(url, true);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(route, state);
  }

  protected checkLogin(url: string, checkIsLoginCompleted: boolean): boolean {
    if (this.auth.isLoggedIn() && (!checkIsLoginCompleted || this.auth.isLoginCompleted())) {
      // logged in so return true
      return true;
    }
    // Store the attempted URL for redirecting
    this.auth.redirectUrl = url;

    // not logged in so redirect to login page with the return url
    // this.router.navigate(['/login']);
    this.auth.logout();
    return false;
  }

  protected isActivatedComponent(route, componentType): boolean {
    return route.children.length && route.children[0].component instanceof componentType;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
