<taku-form-header
  [displayMode]="_isDialog ? 'inline' : null"
  [formGroup]="_myForm"
  (backClicked)="goBack()"
  (saveClicked)="onSave()"
  (revertClicked)="onRevert()"
  (newClicked)="onNew()"
  (deleteClicked)="onDelete()"
  (cloneClicked)="onClone()"
  [toolbarTitle]="pageTitle"
  [insideDialog]="_isDialog"
  [isSaving]="_isSaving"
>
</taku-form-header>
<div>
  <p style="margin: 5px 0 0 10px">To pair with a TAKU Payments card terminal:</p>
  <ol>
    <li>Enter a <b>Terminal Name</b></li>
    <li>Select the <b>Station ID/Name</b> from the dropdown menu</li>
    <li>Select the <b>Device Type</b> from the dropdown menu</li>
    <li>Enter the <b>Serial Number</b> of the terminal</li>
  </ol>
  <p
    >Click
    <a href="https://help.takulabs.io/apps/payments/in-person-payments/taku" target="_blank" style="color: blue"
      >here</a
    >
    for more information about TAKU Pay</p
  >
</div>
<div class="col-12 pl-0 pr-0">
  <div [formGroup]="_myForm" class="grid terminal-topWrapper">
    <div class="col-12 lg:col-4 xl:col-3 md:col-6 terminalCredentials-wrapper">
      <p-panel>
        <ng-template pTemplate="header">
          <h2>Terminal Assignment</h2>
          <taku-checkbox [formGroup]="_myForm" fieldName="isActive" fieldCaption="Active"></taku-checkbox>
        </ng-template>

        <div class="grid">
          <div class="col-12 grid">
            <div class="col">
              <taku-input [formGroup]="_myForm" fieldName="name" fieldCaption="Terminal Name"></taku-input>
            </div>
            <span class="align-self-center">
              <a href="javascript://" class="pi pi-info-circle" (click)="terminalIdPanel.toggle($event)">
                <p-overlayPanel #terminalIdPanel [showCloseIcon]="false" appendTo="body">
                  <div class="tooltip-styling">
                    <h5>NOTE:</h5>
                    <ul>
                      <li
                        >8-character alphanumeric ID. This is the ECR number of the particular payment terminal you are
                        pairing.</li
                      >
                    </ul>
                  </div>
                </p-overlayPanel>
              </a>
            </span>
          </div>
          <div class="col-12 grid">
            <div class="col">
              <taku-dropdown
                [formGroup]="_myForm"
                [options]="storeStations$ | async"
                fieldName="stationId"
                fieldCaption="Station ID/Name"
              ></taku-dropdown>
            </div>
            <span class="align-self-center">
              <a href="javascript://" class="pi pi-info-circle" (click)="stationIdPanel.toggle($event)">
                <p-overlayPanel #stationIdPanel [showCloseIcon]="false" appendTo="body">
                  <div class="tooltip-styling">
                    <h5>NOTE:</h5>
                    <ul>
                      <li
                        >Select the TAKU station you would like to pair your payment terminal with from the dropdown
                        menu.</li
                      >
                    </ul>
                  </div>
                </p-overlayPanel>
              </a>
            </span>
          </div>
          <div class="col-12 grid">
            <div class="col">
              <taku-dropdown
                [formGroup]="_myForm"
                [options]="deviceTypes"
                fieldName="deviceType"
                fieldCaption="Device Type"
              >
              </taku-dropdown>
            </div>
            <span class="align-self-center">
              <a href="javascript://" class="pi pi-info-circle" (click)="stationIdPanel.toggle($event)">
                <p-overlayPanel #stationIdPanel [showCloseIcon]="false" appendTo="body">
                  <div class="tooltip-styling">
                    <h5>NOTE:</h5>
                    <ul>
                      <li>Select the device type you have from the dropdown menu.</li>
                    </ul>
                  </div>
                </p-overlayPanel>
              </a>
            </span>
          </div>
          <div class="col-6 pairUnpair-btn-container">
            <!-- (click)="_myForm.get('isPaired').value? monerisService.apiTakuEconduitUnpairTerminal(_myForm.get('id').value) :monerisService.apiTakuEconduitPairTerminal(_myForm.get('id').value)"> -->
            <button
              *ngIf="!_myForm.get('isPaired').value"
              pButton
              pRipple
              type="button"
              [label]="_myForm.get('isPaired').value ? 'Unpair' : 'Pair'"
              class="p-button-raised p-button-text col-12"
              [disabled]="_myForm.dirty"
              (click)="
                _myForm.get('isPaired').value
                  ? unPairTerminal(_myForm.get('id').value)
                  : pairTerminal(_myForm.get('id').value)
              "
            >
            </button>
            <p-splitButton
              *ngIf="_myForm.get('isPaired').value"
              class="col-12"
              label="Unpair"
              (onClick)="unPairTerminal(_myForm.get('id').value)"
              [disabled]="_myForm.dirty"
              [model]="unpairItems"
              styleClass="p-button-raised p-button-secondary"
            >
            </p-splitButton>
          </div>
          <!--
          <div class="col-6 initialize-btn-container">
            <button pButton pRipple type="button" [label]="'Initialize'" class="p-button-raised p-button-text col-12" [disabled]="_myForm.dirty || !_myForm.get('isPaired').value"
            (click)="initializeTerminal(_myForm.get('id').value)"></button>
          </div>
          -->
        </div>
      </p-panel>
    </div>

    <div class="col-12 lg:col-4 xl:col-3 md:col-6 terminalCredentials-wrapper">
      <p-panel>
        <ng-template pTemplate="header">
          <h2>Terminal Credentials</h2>
        </ng-template>

        <div class="grid">
          <div class="col-12 grid">
            <div class="col">
              <taku-input
                [formGroup]="_myForm"
                [readonly]="false"
                fieldName="pairingCode"
                fieldCaption="Pairing Code"
              ></taku-input>
            </div>
            <span class="align-self-center">
              <a href="javascript://" class="pi pi-info-circle" (click)="merchantIDPanel.toggle($event)">
                <p-overlayPanel #merchantIDPanel [showCloseIcon]="false" appendTo="body">
                  <div class="tooltip-styling">
                    <h5>NOTE:</h5>
                    <ul>
                      <li
                        >The pairing code from the reader. This is only applicable if pairing a stripe reader or Wiseasy
                        P5.</li
                      >
                    </ul>
                  </div>
                </p-overlayPanel>
              </a>
            </span>
          </div>

          <div class="col-12 grid">
            <div class="col">
              <taku-input
                [formGroup]="_myForm"
                [disabled]="true"
                [readonly]="true"
                fieldName="terminalId"
                fieldCaption="Terminal ID"
              ></taku-input>
            </div>
            <span class="align-self-center">
              <a href="javascript://" class="pi pi-info-circle" (click)="terminalIDPanel.toggle($event)">
                <p-overlayPanel #terminalIDPanel [showCloseIcon]="false" appendTo="body">
                  <div class="tooltip-styling">
                    <h5>NOTE:</h5>
                    <ul>
                      <li
                        >8-character alphanumeric ID. This is the ECR number of the particular payment terminal you are
                        pairing.</li
                      >
                    </ul>
                  </div>
                </p-overlayPanel>
              </a>
            </span>
          </div>

          <div class="col-12 grid">
            <div class="col">
              <taku-input
                [formGroup]="_myForm.get('takuPaymentGateway')"
                [disabled]="true"
                [readonly]="true"
                fieldName="locationId"
                fieldCaption="Location ID"
              >
              </taku-input>
            </div>
            <span class="align-self-center">
              <a href="javascript://" class="pi pi-info-circle" (click)="API_TokenPanel.toggle($event)">
                <p-overlayPanel #API_TokenPanel [showCloseIcon]="false" appendTo="body">
                  <div class="tooltip-styling">
                    <h5>NOTE:</h5>
                    <ul>
                      <li>Unique location ID</li>
                    </ul>
                  </div>
                </p-overlayPanel>
              </a>
            </span>
          </div>

          <div class="col-12 grid">
            <div class="col">
              <taku-input
                [formGroup]="_myForm"
                [readonly]="false"
                fieldName="serialNo"
                fieldCaption="Serial Number"
              ></taku-input>
            </div>
            <span class="align-self-center">
              <a href="javascript://" class="pi pi-info-circle" (click)="Pairing_TokenPanel.toggle($event)">
                <p-overlayPanel #Pairing_TokenPanel [showCloseIcon]="false" appendTo="body">
                  <div class="tooltip-styling">
                    <h5>NOTE:</h5>
                    <ul>
                      <li
                        >This is the serial number from the reader and is only applicable when pairing a Castle S1E or
                        S1F2.</li
                      >
                    </ul>
                  </div>
                </p-overlayPanel>
              </a>
            </span>
          </div>
        </div>
      </p-panel>
    </div>
  </div>
</div>

<p-dialog [(visible)]="_isPairingOrUnpairing || _isInitializing" styleClass="terminalPairUnpairProgressDlg">
  <taku-progress-dialog></taku-progress-dialog>
</p-dialog>
