/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { DBService } from "../../../../shared/services/db.service";
import { DataType, FilterType } from "../../../../form-list/form-list/form-list";
import { Injectable } from "@angular/core";
import { FormListModel } from "../../../../form-list/form-list/form-list-model.interface";
import { ValidatorFn, Validators } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class FiscalYearsService extends DBService implements FormListModel {
  getValidationRules(): { [key: string]: ValidatorFn[] | {} } {
    return {
      fiscalYearName: [Validators.required],
      startDate: [Validators.required],
      endDate: [Validators.required],
    };
  }

  getFormListColumns() {
    return [
      {
        field: "fiscalYearName",
        header: "Fiscal Year Name",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "startDate",
        header: "Start Date",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.date_date_only,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "endDate",
        header: "End Date",
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.date_date_only,
        dataOptions: [],
        filterType: FilterType.contains,
      },
    ];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
