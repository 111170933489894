/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { SaleDoc } from "../../../document/sale-document/sale-doc/sale-doc";
import { Zone } from "../../zone-settings/Zone";
import { StorePoliciesSettings } from "../store-policies-settings/StorePoliciesSettings";
import { StoreSettingsGenericComponent } from "../StoreSettingsGenericComponent";
import { Font_Size, Sort_Order, StoreSettingTapeSalePrint } from "./store-setting-tape-sale-print";
import { BusinessDetail } from "../../business-settings/business-detail/business-detail";

@Component({
  selector: "taku-tape-salereceipt-builder",
  templateUrl: "tape-salereceipt-builder.component.html",
  styleUrls: ["tape-salereceipt-builder.component.scss"],
})
export class TapeSaleReceiptBuilderComponent extends StoreSettingsGenericComponent {
  lookup_font_size: any[];
  lookup_transaction_sorting: any[];

  previewSize = "NARROW";
  _pageTitlePrefix = "Sales Tape Receipt";
  receiptDemoData: {
    saledoc: SaleDoc;
    policies: StorePoliciesSettings;
    businessDetail: BusinessDetail;
  };

  initLookups() {
    this.lookup_font_size = this.dbService.enumSelectOptions(Font_Size);
    this.lookup_transaction_sorting = this.dbService.enumSelectOptions(Sort_Order).map((item) => {
      // if (item.value !== Sort_Order.Scan_Order && item.value !== null) {
      //   item.disabled = true;
      //   item.icon = 'pi pi-lock';
      // }

      return item;
    });
  }

  initValidation() {
    this._validation = {
      headerFontSize: Validators.required,
      lineFontSize: Validators.required,
      lineSortOrder: Validators.required,
    };
  }

  static init(fb: UntypedFormBuilder, zone: Zone): UntypedFormGroup {
    const tmpModel = new StoreSettingTapeSalePrint(zone);
    const tmpForm = fb.group(tmpModel);

    return tmpForm;
  }

  initForm() {
    const activeZone = this.appSettingsService.getZone();
    this._model = "storeSettingTapeSalePrint";
    this._object = new StoreSettingTapeSalePrint(activeZone);
    this._myForm = TapeSaleReceiptBuilderComponent.init(this.fb, activeZone);
  }

  ngOnInit() {
    super.ngOnInit();

    this.subsList.push(
      this.printingFactoryService.createDemoData().subscribe((demoData) => {
        this.receiptDemoData = demoData;
      })
    );
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
