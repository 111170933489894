<p-dataView
  class="categoriesDataView"
  [ngClass]="{
    'move-paginator': inSelfCheckout,
    'round-btns-and-search-field': roundBtnsAndSearchField,
    'kiosk-font-size': inSelfCheckout
  }"
  [value]="categories"
  [rows]="rows"
  [lazy]="true"
  (onLazyLoad)="onLazyLoad.emit($event)"
  [totalRecords]="totalCategories"
  layout="grid"
  emptyMessage="Hmmmm, it looks like you don't have any categories showing in Gallery. To show your categories in Gallery, go to Business Settings > Inventory Categories."
>
  <ng-template pTemplate="header">
    <div *ngIf="!isMobile" class="grid header-topWrapper">
      <div class="p-inputgroup col-12" *ngIf="searchFieldControl">
        <input
          #searchInput
          type="text"
          pInputText
          [placeholder]="searchFieldPlaceholder"
          [formControl]="searchFieldControl"
          (keydown.enter)="onSearchSubmit.emit()"
          (click)="autoSelectSearchField()"
        />
        <i class="material-icons close-icon" (click)="onClearIconClicked()" *ngIf="_showClearButton">close</i>
        <button
          type="button"
          pButton
          pRipple
          icon="pi pi-search"
          (click)="onSearchSubmit.emit()"
          [loading]="productsLoading"
        ></button>
      </div>
      <div class="col-12 grid dv-header-row2">
        <div class="home-icon-container col-fixed" (click)="onHomeBtnClicked()">
          <i class="material-icons">home</i>
        </div>
        <div class="category-label-container col">
          <span>All Categories</span>
          <!-- <span>WAO: {{ widthAfterOffset }}, HAO: {{ heightAfterOffset }}</span> -->
          <!-- <span style="font-size:0.5em;">WAO: {{ widthAfterOffset }}, height: {{ dvContainerHeight }}</span> -->
        </div>
      </div>
    </div>
    <div *ngIf="isMobile" class="grid header-topWrapper mobile-dv-header">
      <div class="col-12 grid">
        <div class="p-inputgroup col" *ngIf="searchFieldControl">
          <input
            #searchInput
            type="text"
            pInputText
            placeholder="Scan or enter keyword"
            [formControl]="searchFieldControl"
            (keydown.enter)="onSearchSubmit.emit()"
            (click)="autoSelectSearchField()"
          />
          <i class="material-icons close-icon" (click)="onClearIconClicked()" *ngIf="_showClearButton">close</i>
          <button
            type="button"
            pButton
            pRipple
            icon="pi pi-search"
            (click)="onSearchSubmit.emit()"
            [loading]="productsLoading"
          ></button>
        </div>
        <div *ngIf="inSelfCheckout" class="cart-icon-container col-fixed" (click)="onShoppingCartClicked()">
          <i
            class="material-icons-outlined mt-1"
            pBadge
            style="font-size: 2rem"
            [value]="_countTotalItems | formatNegativeNumber"
            severity="success"
            >shopping_cart</i
          >
        </div>
      </div>
      <div class="col-12 grid">
        <div class="home-icon-container col-fixed" (click)="onHomeBtnClicked()">
          <i class="material-icons">home</i>
        </div>
        <div class="category-label-container col">
          <span>All Categories</span>
          <!-- <span>WAO: {{ widthAfterOffset }}, HAO: {{ heightAfterOffset }}</span> -->
          <!-- <span style="font-size:0.5em;">WAO: {{ widthAfterOffset }}, height: {{ dvContainerHeight }}</span> -->
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template let-category pTemplate="gridItem">
    <taku-category-grid-card
      [category]="category"
      (click)="onItemSelected.emit(category)"
      [width]="gridItemWidth"
      [height]="gridItemWidth * 1.2"
      [_isMobile]="isMobile"
      [categoryBgColor]="categoryBgColor"
      [categoryFontColor]="categoryFontColor"
    >
    </taku-category-grid-card>
  </ng-template>
  <ng-template pTemplate="empty">
    <div *ngIf="productsLoading" class="skeleton-container">
      <p-skeleton
        *ngFor="let _ of rowsArray"
        [width]="gridItemWidth + 'px'"
        [height]="gridItemHeight + 'px'"
        borderRadius="16px"
      ></p-skeleton>
    </div>
  </ng-template>
  <ng-template pTemplate="paginatorleft"> </ng-template>
  <ng-template pTemplate="paginatorright">
    <span>{{ totalCategories }} categories</span>
  </ng-template>
</p-dataView>
