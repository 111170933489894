<p-table
  scrollHeight="300px"
  responsiveLayout="stack"
  breakpoint="640px"
  [value]="saleDocTenders"
  styleClass="p-datatable-striped mt-2"
>
  <ng-template pTemplate="header">
    <tr>
      <th class="col-payType">Pay Type</th>
      <th class="col-date">Date</th>
      <th class="col-time">Time</th>
      <th class="col-refNo">Ref #</th>
      <th class="col-total">Total</th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-tender>
    <tr>
      <td class="col-payType">
        <span class="p-column-title">Pay Type:</span>
        <ng-template [ngIf]="tender.refTransaction">
          {{ tender.refTransaction.CardType }}
        </ng-template>
        <ng-template [ngIf]="!tender.refTransaction">
          {{ tender.tenderType.description }}
        </ng-template>
      </td>
      <td class="col-date">
        <span class="p-column-title">Date:</span>
        {{ tender.createdAt | dateFormat }}
      </td>
      <td class="col-time">
        <span class="p-column-title">Time:</span>
        {{ tender.createdAt | date : "HH:mm:ss" }}
      </td>
      <td class="col-refNo">
        <span class="p-column-title">Ref #:</span>
        <ng-template [ngIf]="!tender.refTransaction">
          {{ tender.voucher?.uniqueCode || tender.refNo }}
        </ng-template>
        <div class="refNo-table" *ngIf="tender.refTransaction && tender.refTransaction.RefID !== ''">
          <span class="line-transaction-title">Trans Type:</span>
          <span class="line-transaction-value">{{ tender.refTransaction.TransType | titlecase }}</span>
          <span class="line-transaction-title">Auth Code:</span>
          <span class="line-transaction-value">{{ tender.refTransaction.AuthCode }}</span>
          <ng-container *ngIf="tender.refTransaction.VoidAuthCode && tender.refTransaction.VoidAuthCode !== ''">
            <span class="line-transaction-title">Void Auth Code:</span>
            <span class="line-transaction-value">{{ tender.refTransaction.VoidAuthCode }}</span>
          </ng-container>
          <span class="line-transaction-title">Card Type:</span>
          <span class="line-transaction-value">{{ tender.refTransaction.CardType }}</span>
          <span class="line-transaction-title">Last 4:</span>
          <span class="line-transaction-value">{{ tender.refTransaction.Last4 }}</span>
          <span class="line-transaction-title">Ref ID:</span>
          <span class="line-transaction-value">{{ tender.refTransaction.RefID }}</span>
          <span class="line-transaction-title">Entry Method:</span>
          <span class="line-transaction-value">{{ tender.refTransaction.EntryMethod }}</span>
        </div>
      </td>
      <td class="col-total">
        <span class="p-column-title">Total:</span>
        {{
          tender.amount
            | currency : tender.tenderType.currencyIsoCode : "symbol-narrow" : decimalFormat
            | formatNegativeNumber
        }}
      </td>
    </tr>
  </ng-template>
</p-table>
