<p-dataView
  class="itemsDataView"
  [ngClass]="{
    'move-paginator': inSelfCheckout,
    'round-btns-and-search-field': roundBtnsAndSearchField,
    'kiosk-font-size': inSelfCheckout
  }"
  [value]="inventories"
  [rows]="rows"
  layout="grid"
  [layout]="dvLayoutOption"
  (onChangeLayout)="onChangeLayout($event)"
  [lazy]="true"
  (onLazyLoad)="onLazyLoad.emit($event)"
  [totalRecords]="totalRecords"
  [showCurrentPageReport]="true"
  emptyMessage="Hmmmm, it looks like there isn't any inventory under this category."
>
  <ng-template *ngIf="showDataViewHeader" pTemplate="header">
    <div *ngIf="!isMobile" class="grid header-topWrapper">
      <div class="p-inputgroup col-12" *ngIf="searchFieldControl">
        <input
          #searchInput
          type="text"
          pInputText
          [placeholder]="searchFieldPlaceholder"
          [formControl]="searchFieldControl"
          (keydown.enter)="onSearchSubmit.emit()"
          (click)="autoSelectSearchField()"
        />
        <i class="material-icons close-icon" (click)="onClearIconClicked()" *ngIf="_showClearButton">close</i>
        <button
          type="button"
          pButton
          pRipple
          icon="pi pi-search"
          (click)="onSearchSubmit.emit()"
          [loading]="productsLoading"
        ></button>
      </div>
      <div class="col-12 grid dv-header-row2">
        <div class="home-icon-container col-fixed" (click)="onHomeBtnClicked()">
          <i class="material-icons">arrow_back</i>
        </div>
        <div class="category-label-container col-fixed">
          <span>{{ currentCategoryName || "Search results:" }}</span>
          <!-- <span> WAO: {{ widthAfterOffset }}, HAO: {{ heightAfterOffset }}</span> -->
          <!-- <span style="font-size:0.5em;">WAO: {{ widthAfterOffset }}, height: {{ dvContainerHeight }}</span> -->
        </div>
        <!-- <div class="dv-layout-options-container col" [ngClass]="dataViewHeaderMobileRange() ? 'col-12' : 'col-fixed'"> -->
        <div *ngIf="!inSelfCheckout" class="dv-layout-options-container col">
          <p-dataViewLayoutOptions></p-dataViewLayoutOptions>
        </div>
      </div>
    </div>
    <div *ngIf="isMobile" class="grid header-topWrapper mobile-dv-header">
      <div class="col-12 grid">
        <div class="p-inputgroup col" *ngIf="searchFieldControl">
          <input
            #searchInput
            type="text"
            pInputText
            placeholder="Scan or enter keyword"
            [formControl]="searchFieldControl"
            (keydown.enter)="onSearchSubmit.emit()"
            (click)="autoSelectSearchField()"
          />
          <i class="material-icons close-icon" (click)="onClearIconClicked()" *ngIf="_showClearButton">close</i>
          <button
            type="button"
            pButton
            pRipple
            icon="pi pi-search"
            (click)="onSearchSubmit.emit()"
            [loading]="productsLoading"
          ></button>
        </div>
        <div *ngIf="inSelfCheckout" class="cart-icon-container col-fixed" (click)="onShoppingCartClicked()">
          <i
            class="material-icons-outlined mt-1"
            pBadge
            style="font-size: 2rem"
            [value]="_countTotalItems | formatNegativeNumber"
            severity="success"
            >shopping_cart</i
          >
        </div>
      </div>
      <div class="col-12 grid">
        <div class="home-icon-container col-fixed" (click)="onHomeBtnClicked()">
          <i class="material-icons">arrow_back</i>
        </div>
        <div class="category-label-container col-fixed">
          <span>{{ currentCategoryName || "Search results:" }}</span>
          <!-- <span>WAO: {{ widthAfterOffset }}, HAO: {{ heightAfterOffset }}</span> -->
          <!-- <span style="font-size:0.5em;">WAO: {{ widthAfterOffset }}, height: {{ dvContainerHeight }}</span> -->
        </div>
        <!-- <div class="dv-layout-options-container col" [ngClass]="dataViewHeaderMobileRange() ? 'col-12' : 'col-fixed'"> -->
        <div *ngIf="!inSelfCheckout" class="dv-layout-options-container col">
          <p-dataViewLayoutOptions></p-dataViewLayoutOptions>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template let-inventory pTemplate="gridItem">
    <ng-container
      *ngTemplateOutlet="
        gridCardTemplate;
        context: { $implicit: inventory, width: gridItemWidth, height: gridItemWidth * 1.1 }
      "
    >
    </ng-container>
    <!-- <p-skeleton *ngIf="productsLoading" [width]="gridItemWidth + 'px'" [height]="gridItemHeight + 'px'" borderRadius="16px"></p-skeleton> -->
  </ng-template>
  <ng-template let-inventory pTemplate="listItem">
    <ng-container
      *ngTemplateOutlet="
        listCardTemplate;
        context: { $implicit: inventory, width: listItemWidth, height: listItemHeight }
      "
    ></ng-container>
    <!-- <p-skeleton *ngIf="productsLoading" [width]="listItemWidth + 'px'" [height]="listItemHeight + 'px'" borderRadius="16px"></p-skeleton> -->
  </ng-template>
  <ng-template pTemplate="empty">
    <div *ngIf="productsLoading" class="skeleton-container">
      <p-skeleton
        *ngFor="let _ of rowsArray"
        [width]="gridItemWidth + 'px'"
        [height]="gridItemHeight + 'px'"
        borderRadius="16px"
      ></p-skeleton>
    </div>
    <span *ngIf="!productsLoading && inventories?.length === 0 && showEmptyMessage">
      Hmmmm, it looks like there isn't any inventory under this category.
    </span>
  </ng-template>
  <ng-template pTemplate="paginatorleft"> </ng-template>
  <ng-template pTemplate="paginatorright">
    <span>{{ totalRecords }} item(s)</span>
  </ng-template>
</p-dataView>
