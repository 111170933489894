/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, OnDestroy } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { SelectItem } from "primeng/api/selectitem";
import { DBService } from "src/app/shared/services/db.service";
import { shareReplay } from "rxjs/operators";
import { ReportParamsService } from "../report-params.service";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";

@Component({
  selector: "taku-reports-params-stocks",
  templateUrl: "./reports-params-stocks.component.html",
  styleUrls: ["./reports-params-stocks.component.scss"],
})
export class ReportsParamsStocksComponent implements OnInit, OnDestroy {
  subsList: Subscription[] = [];
  formGroup = null;
  lookupStocks$: Observable<SelectItem[]>;

  constructor(
    private dbService: DBService,
    private reportParamsService: ReportParamsService,
    private appSettings: AppSettingsStorageService
  ) {}

  ngOnInit(): void {
    let _stocksFilter = {};
    if (this.appSettings.getStoreId()) {
      _stocksFilter = {
        zoneId: { matchMode: "equals", value: this.appSettings.getStore().zoneId },
      };
    } else if (this.appSettings.getBackofficeId()) {
      _stocksFilter = {
        zoneId: { matchMode: "equals", value: this.appSettings.getBackoffice().zoneId },
      };
    }

    this.lookupStocks$ = this.dbService
      .lookupSelectOptions("stock", "stockName", {
        lookupFilter: JSON.stringify(_stocksFilter),
        enableFieldName: "isActive",
        emptyRowCaption: "All Stocks",
        emptyRowValue: 0,
      })
      .pipe(shareReplay(1));
    setTimeout(() => {
      this.formGroup.get("stockId").setValue(0);
    }, 0);
    this.subsList.push(
      this.formGroup.valueChanges.subscribe((newValue) => {
        this.reportParamsService.setReportParam(newValue);
      })
    );
  }

  get selectedStoockCtrl() {
    return this.formGroup.get("stockId");
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
