/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { EventEmitter, Injectable } from "@angular/core";
import { ValidatorFn } from "@angular/forms";
import { InventoryDocType, SaleDocType } from "src/app/core/document/doc/doc";
import {
  Col,
  DataType,
  ExecuteEventDatatypeOptions,
  ExecuteEvent_DisplayMode,
  FilterType,
} from "../../../../form-list/form-list/form-list";
import { FormListModel } from "../../../../form-list/form-list/form-list-model.interface";
import { DBService } from "../../../../shared/services/db.service";
import { InventoryHistory } from "src/app/core/reports/reports-list/inventory-history-report/inventory-history";
import { Observable } from "rxjs";
import { SelectItem } from "primeng/api";
import _ from "lodash";
import { SaledocRevisitPreviewComponent } from "src/app/core/document/sale-document/saledoc-revisit-preview/saledoc-revisit-preview.component";
import { DialogService } from "primeng/dynamicdialog";
import { InventoryDocPreviewComponent } from "src/app/core/document/inventory-doc-preview/inventory-doc-preview.component";
import { InventoryDocumentPreviewComponent } from "src/app/core/document/inventory-document/inventory-document-preview/inventory-document-preview.component";

@Injectable({
  providedIn: "root",
})
export class InventoryHistoryReportColsFactoryService {
  constructor(public dbService: DBService, public dialogService: DialogService) {}

  build() {
    return new InventoryHistoryCols(this);
  }
}

export class InventoryHistoryCols implements FormListModel {
  private showSaleDocpreview: EventEmitter<InventoryHistory> = new EventEmitter();

  constructor(private factoryService: InventoryHistoryReportColsFactoryService) {
    this.showSaleDocpreview.subscribe((inventoryHistory: InventoryHistory) => {
      if (inventoryHistory.saleDocId) {
        this.factoryService.dbService.getRow("saleDoc", inventoryHistory.saleDocId).subscribe((_saleDoc) => {
          this.factoryService.dialogService.open(SaledocRevisitPreviewComponent, {
            data: {
              _object: _saleDoc,
              _orgSaleDoc: inventoryHistory.saleDoc,
            },
            width: "90%",
            height: "90%",
            closable: false,
            showHeader: false,
          });
        });
      } else if (inventoryHistory.inventoryDocId) {
        this.factoryService.dbService
          .getRow("inventoryDoc", inventoryHistory.inventoryDocId)
          .subscribe((_inventoryDoc) => {
            this.factoryService.dialogService.open(InventoryDocumentPreviewComponent, {
              data: {
                _object: _inventoryDoc,
                _orgInventoryDoc: inventoryHistory.inventoryDoc,
                accountName: inventoryHistory.accountName,
              },
              width: "90%",
              height: "90%",
              closable: false,
              showHeader: false,
            });
          });
      }
    });
  }

  enumDocumentTypes = [
    ...this.factoryService.dbService.enumSelectOptions(SaleDocType),
    this.factoryService.dbService
      .enumSelectOptions(InventoryDocType)
      .find((selectItem: SelectItem<InventoryDocType>) => selectItem.value === InventoryDocType.stock_receiving),
  ];

  _disableCloning = true;

  getLookup_stocks(): Observable<SelectItem[]> {
    return this.factoryService.dbService.lookupSelectOptions("stock", "stockName", { enableFieldName: "isActive" });
  }

  getLookup_users(): Observable<SelectItem[]> {
    return this.factoryService.dbService.lookupSelectOptions("user", "userName", { enableFieldName: "isActive" });
  }

  getValidationRules(): { [key: string]: {} | ValidatorFn[] } {
    return {};
  }

  qtyInverseSign(qtyFilter: string) {
    const qty = parseFloat(qtyFilter);
    return qty ? _.toString(-1 * qty) : "";
  }

  qtyPositiveSign(qtyFilter: string) {
    const qty = parseFloat(qtyFilter);
    return qty ? _.toString(Math.abs(qty)) : "";
  }

  getTakuDocLinkCaption(rowData: InventoryHistory) {
    return rowData ? rowData.doc.docNo : "";
  }

  getFormListColumns(): Col[] {
    const cols = [
      {
        field: "doc.docNo",
        header: "Doc #",
        visible: true,
        readonly: true,
        frozen: true,
        dataType: DataType.execute_event,
        dataOptions: [],
        dataTypeOptions: [
          new ExecuteEventDatatypeOptions({
            displayMode: ExecuteEvent_DisplayMode.LINK,
            label: this.getTakuDocLinkCaption,
            event: this.showSaleDocpreview,
            enabledOnEditMode: false,
            styleClass: "",
          }),
        ],
        filterType: FilterType.contains,
        colWidth: 100,
      },
      {
        field: "doc.docDate",
        header: "Date",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.date_date_only,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 95,
      },
      {
        field: "doc.docTime",
        header: "Time",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.date_time,
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 80,
        sortFields: ["doc.docDate", "doc.docTime"],
      },
      {
        field: "doc.docType",
        header: "Doc Type",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.enumDocumentTypes,
        filterType: FilterType.enum,
        colWidth: 120,
      },
      {
        field: "stock.id",
        fieldValue: "stock.stockName",
        header: "Stock Name",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.lookup,
        dataOptions: this.getLookup_stocks(),
        filterType: FilterType.lookup,
        colWidth: 120,
        sortFields: ["stock.stockName"],
      },
      {
        field: "qtyPos",
        header: "Qty +",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        filterMatchMode: "equals",
        filterTransformer: this.qtyInverseSign.bind(this),
        colWidth: 75,
      },
      {
        field: "qtyNeg",
        header: "Qty -",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        filterMatchMode: "equals",
        filterTransformer: this.qtyPositiveSign.bind(this),
        colWidth: 75,
      },
      // price field is set by the firstLineUnitPriceCurrencyFormat method below, in the InventoryHistoryReportComponent.refreshFormList(data: any[]) method
      {
        field: "price",
        header: "Total Price",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.none,
        isNotSortable: true,
        colWidth: 120,
      },
      {
        field: "totalCost",
        header: "Total Cost",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.none,
        isNotSortable: true,
        colWidth: 120,
      },
      {
        field: "accountName",
        header: "Account Name",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.none,
        isNotSortable: true,
        colWidth: 120,
      },
      {
        field: "accountEmail",
        header: "Account Email",
        visible: false,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.none,
        isNotSortable: true,
        colWidth: 150,
      },
      {
        field: "accountPhone",
        header: "Account Phone",
        visible: false,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.none,
        isNotSortable: true,
        colWidth: 120,
      },
      {
        field: "doc.userId",
        fieldValue: "doc.user.userName",
        header: "User",
        visible: false,
        readonly: true,
        frozen: false,
        dataType: DataType.lookup,
        dataOptions: this.getLookup_users(),
        filterType: FilterType.lookup,
        colWidth: 110,
        sortFields: ["user.userName"],
      },
      {
        field: "note",
        header: "Notes",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 225,
      },
    ];
    return cols;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
