/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Input, OnInit, InjectionToken, Optional, Inject, HostBinding, OnDestroy } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { DBService } from "../../shared/services/db.service";
import { Subscription } from "rxjs";
import * as _flatten from "flat";

export const FORM_GROUP_TOKEN = new InjectionToken<string>("nested-form.form-group");
export const DISPLAY_MODE_TOKEN = new InjectionToken<string>("nested-form.display-mode");
export enum NestedFormDisplayMode {
  EDITABLE_FORM = "EditableForm",
  TABULAR_DATA = "TabularData",
}

@Component({
  template: "",
  styles: [],
})
export abstract class NestedFormComponent<FormEntityT extends UntypedFormGroup = UntypedFormGroup>
  implements OnInit, OnDestroy
{
  subsList: Subscription[] = [];
  @Input("formGroup") _myForm: FormEntityT;
  @Input() displayMode: NestedFormDisplayMode;

  @HostBinding("class.tabular-displaymode")
  get isTabularDisplay() {
    return this.displayMode === NestedFormDisplayMode.TABULAR_DATA;
  }

  NestedFormDisplayMode = NestedFormDisplayMode;
  _validation = {};

  constructor(
    protected dbService: DBService,
    protected fb: UntypedFormBuilder,
    @Optional() @Inject(FORM_GROUP_TOKEN) injectedFormGroup?: FormEntityT,
    @Optional() @Inject(DISPLAY_MODE_TOKEN) injectedDisplayMode?: NestedFormDisplayMode
  ) {
    this._myForm = injectedFormGroup;
    this.displayMode = injectedDisplayMode || NestedFormDisplayMode.EDITABLE_FORM;
  }

  setValidation() {
    this.applyValidationToForm(this._validation, this._myForm);
  }

  protected applyValidationToForm(validationRules: any, form: UntypedFormGroup) {
    validationRules = _flatten(validationRules, { safe: true });
    Object.keys(validationRules).forEach((key) => {
      if (form.get(key)) form.get(key).setValidators(validationRules[key]);
    });
  }

  ngOnInit(): void {
    this.initLookups();
    this.initValidation();
    this.setValidation();
  }

  setZeroIfEmpty(_formControlName, defaultValue = 0) {
    this._myForm.get(_formControlName).setValue(this._myForm.get(_formControlName).value || defaultValue);
  }

  initLookups() {}

  initValidation() {
    this._validation = {};
  }

  copyValueToClipboard(value: string) {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        // console.log("copied to clipboard!");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
