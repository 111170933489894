/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { round } from "lodash";

export class SaleAnalysisReport {
  id: number;
  SKU: string;
  categoryName: string;
  description: string;
  qtyOH: number;
  qtySold: number;
  unitSoldPrice: number;
  totalSoldPrice: number;
  standardCost: number;
  tags: string;
  marginPercentCalculated: number;
  marginAmountCalculated: number;

  constructor() {
    this.id = 0;
    this.SKU = null;
    this.categoryName = null;
    this.description = null;
    this.qtyOH = null;
    this.qtySold = null;
    this.unitSoldPrice = null;
    this.totalSoldPrice = null;
    this.standardCost = null;
    this.tags = null;
    this.marginPercentCalculated = 0;
    this.marginAmountCalculated = 0;
  }

  get marginPercent() {
    if (this.unitSoldPrice == 0) return "0";
    if (Number.isNaN((this.unitSoldPrice - this.standardCost) / this.unitSoldPrice)) return "0";
    return (((this.unitSoldPrice - this.standardCost) / this.unitSoldPrice) * 100).toFixed(2);
  }

  get marginAmount() {
    return this.totalSoldPrice - this.totalCost;
  }

  get totalCost() {
    return this.qtySold * this.standardCost;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
