import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FormListColumnsService } from "src/app/form-list/form-list-columns.service";
import { FormListMainComponent } from "src/app/form-list/form-list-main/form-list-main.component";
import { InventoryLabelSettingsComponent } from "../inventory-label-settings/inventory-label-settings.component";
import { Location } from "@angular/common";
import { DialogService } from "primeng/dynamicdialog";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { Product_Type } from "../inventory/inventory";

@Component({
  selector: "taku-inventory-form-list",
  templateUrl: "./inventory-form-list.component.html",
  styleUrls: ["./inventory-form-list.component.scss"],
})
export class InventoryFormListComponent extends FormListMainComponent implements OnInit {
  activeFilter = {};

  constructor(
    protected location: Location,
    protected _route: ActivatedRoute,
    protected formListColumnsService: FormListColumnsService,
    public dialogService: DialogService
  ) {
    super(location, _route, formListColumnsService);
  }

  ngOnInit(): void {
    this.activeFilter = {
      parentInventoryId: { matchMode: "equals", value: null, allowNull: true },
    };
    super.ngOnInit();
  }

  onPrintLabel() {
    this.dialogService.open(InventoryLabelSettingsComponent, {
      styleClass: "inventory-lable-settings fullscreen-mobilemodal",
      contentStyle: {
        overflow: "auto",
        width: "85vw",
        maxWidth: "800pt",
        minWidth: "620pt",
        padding: "7px",
      },
      data: {
        inventoriesArray: this.formListComponent._selectedObjects,
      },
    });
  }

  _changeFormListFilter = (filter) => {
    const enabledFilter = filter["parentInventoryId"].value;
    if (enabledFilter == Product_Type.showChild) {
      delete filter["parentInventoryId"];
    } else {
      filter["parentInventoryId"] = {
        matchMode: "equals",
        value: null,
        allowNull: true,
      };
    }
  };
}
