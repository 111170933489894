/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit } from "@angular/core";
import { ArrayFormComponent } from "src/app/forms/array-form/array-form.component";
import { SaleDocTenderComponent } from "../sale-doc-tender/sale-doc-tender.component";

@Component({
  selector: "taku-sale-doc-tender-array",
  templateUrl: "./sale-doc-tender-array.component.html",
  styleUrls: ["./sale-doc-tender-array.component.scss"],
})
export class SaleDocTenderArrayComponent extends ArrayFormComponent {
  decimalFormat = "1.2-2";
  // Override default setArray
  setArray(data: any[]) {
    return SaleDocTenderComponent.setArray(this.fb, data);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
