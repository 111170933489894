<taku-form-header [toolbarTitle]="_pageTitle" displayMode="inline" [showActionBtns]="false" (backClicked)="goBack()">
</taku-form-header>

<div class="card">
  <div class="grid">
    <div class="col">
      <h2>
        <strong> Pair Payment Terminals </strong>
      </h2>
      <hr />
      <p>
        To pair your terminal(s) with TAKU, click
        <button
          pButton
          pRipple
          type="button"
          icon="_"
          style="cursor: initial"
          class="p-button-rounded p-button-icon-only"
        >
          <i class="material-icons">add</i>
        </button>
        to add a new terminal, complete all of the required fields, and click Save.
      </p>
      <p>
        Further instructions for each column are available by clicking
        <i class="pi pi-info-circle"></i>
        in the column header.
      </p>
    </div>
  </div>
</div>

<taku-form-list
  *ngIf="_formListCols && _formListFilter"
  [_model]="_formListModel"
  _detailMode="w"
  _title="TAKU Pay Terminals"
  [_filter]="_formListFilter"
  [_cols]="_formListCols"
  [_defaultRowValues]="_formListDefaultRowValues"
  [_hideAddLineHeaderBtn]="true"
  (_allRowsSaved)="onPaymentTerminalsSaved($event)"
  [_detailViewRoute]="terminalRout"
  [_deleteRowEvent]="checkIfCanDeleteDialog"
></taku-form-list>

<ng-template #terminalPairingTpl let-rowData>
  <button
    pButton
    [label]="rowData.isPaired ? 'Unpair' : 'Pair'"
    class="pairing-btn p-button-secondary"
    (click)="onColPairBtnClicked(rowData)"
  ></button>
  <!-- <button pButton [label]="rowData.isPaired ? 'Settle' : 'Settle'" class="pairing-btn p-button-secondary"
    (click)="onColSettleBtnClicked(rowData)"></button> -->
</ng-template>
