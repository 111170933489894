import { Branding } from "./types";

export const VITAHEALTH_BRAND: Branding = Object.freeze({
  mainLogo: "/assets/layout/images/vitahealth/vitahealth-logo-light.svg",
  mainLogoDark: "/assets/layout/images/vitahealth/vitahealth-logo-dark.svg",
  textLogoCss: "",
  colors: {
    contrast: "#FFF",
  },
  generic: {
    versionFooter: "TAKU Canada Ltd",
    companyName: "VitaHealth",
    loadingLogo: "/assets/layout/images/vitahealth/vitahealth-logo-symbol.svg",
    supportEmail: "help@vitahealthpos.com",
  },
  loginComponent: {
    accountAddress: "Your VitaHealth name",
    url: "vitahealthpos.app/",
    background: "/assets/layout/images/vitahealth/vitahealth-login.png",
    backgroundColor: "#317a9e",
    heading: "Grow no-effort revenue today",
    headingColor: "#FFF",
    subHeading: " ",
    getStarted: {
      buttonLabel: "Get started today",
      buttonUrl: "https://vitahealthpos.com/schedule-a-demo",
    },
    showSignupLink: true,
  },
  businessSetupComponent: {
    businessName: "Welcome to VitaHealth - Set-Up your Business",
  },
  appMenuComponent: {
    eCommerce: "VitaHealth eCommerce",
  },
  salesChannelComponent: {
    aboutLink: "https://vitahealthpos.com/online-orders",
  },
});
