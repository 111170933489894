/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, Input, ViewChild, ElementRef } from "@angular/core";
import { DiscountType } from "../sale-doc-line/sale-doc-line";
import { WebHelpers } from "../../../../utility/WebHelpers";
import { DBService } from "src/app/shared/services/db.service";
import { MessageService } from "primeng/api";
import { Inventory } from "src/app/core/inventory/inventory/inventory";
import { Commitments } from "../commitments/commitments";

@Component({
  selector: "taku-commitments-transactions",
  templateUrl: "./commitments-transactions.component.html",
  styleUrls: ["./commitments-transactions.component.scss"],
})
export class CommitmentsTransactionsComponent {
  @Input() commitments: Commitments;
  @ViewChild("saleLines", { static: true }) saleLinesWrapper: ElementRef;

  DiscountType = DiscountType;
  _activeDialog = false;
  _dialogContent = "";
  constructor(private dbService: DBService, private messageService: MessageService) {}

  get _isMobile(): boolean {
    return WebHelpers.isMobileScreen();
  }

  inventoryVariantsStr(_inventory: Inventory): string {
    return _inventory.inventoryVariants
      ?.map((_inventoryVariant) => _inventoryVariant.inventoryOptionsSettingValue?.optionValue)
      .join(", ");
  }

  get hasScrollbars(): boolean {
    const linesEl = this.saleLinesWrapper.nativeElement;
    return linesEl.scrollHeight > linesEl.clientHeight;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
