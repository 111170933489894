<taku-form-dialoge-header
  [pristine]="_myForm.pristine"
  [valid]="_myForm.valid"
  (backClicked)="goBack()"
  (saveClicked)="onSave()"
  (revertClicked)="onRevert()"
  (newClicked)="onNew()"
  (deleteClicked)="onDelete()"
  (cloneClicked)="onClone()"
  (cancelClicked)="closeModal()"
  toolbarTitle="Category Details"
>
</taku-form-dialoge-header>

<form [formGroup]="_myForm" novalidate *ngIf="active">
  <div class="card card-w-title">
    <div class="grid form-group">
      <div class="col-12">
        <taku-checkbox [formGroup]="_myForm" [fieldName]="'isEnabled'" fieldCaption="Is Active?"> </taku-checkbox>
      </div>

      <div class="col-12">
        <taku-checkbox
          [formGroup]="_myForm"
          fieldName="includedInGallery"
          fieldCaption="Show in Gallery"
        ></taku-checkbox>
      </div>

      <div class="col-12">
        <taku-input
          id="inventory_category"
          [formGroup]="_myForm"
          [fieldName]="'categoryName'"
          [fieldCaption]="'Inventory Category'"
        >
        </taku-input>
      </div>
      <div class="single-picture-uploader xl:col-12 lg:col-12 col-12">
        <div class="grid">
          <div class="col-12">
            <h5>Image</h5>
          </div>

          <div class="col-6">
            <p-fileUpload
              #fileUploader
              name="image"
              auto="true"
              accept="image/*"
              customUpload="true"
              (uploadHandler)="myUploader($event)"
            ></p-fileUpload>
          </div>
          <div class="col-6" style="margin-top: -2em">
            <div *ngIf="_myForm.value.urlImageFileName && !imageUrlCtrl.dirty">
              <i class="material-icons clear-field" (click)="clearImage()">close</i>
              <div class="col-12 main-thumbnail-container">
                <img [attr.src]="fileNameImageUrl" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<taku-form-footer></taku-form-footer>
