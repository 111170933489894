/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Injectable } from "@angular/core";
import * as _ from "lodash";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ReportParamsService {
  private reportParams: any;
  private reportParamsSubject$ = new Subject<any>();
  reportParamsChanged$ = this.reportParamsSubject$.asObservable();
  _isFetchingData = false;

  setReportParam(_reportParams: any) {
    this.reportParams = _reportParams;
    // if (!_.isEqual(this.reportParams, _reportParams)) {
    //   this.reportParamsSubject$.next(_reportParams);
    // }
  }

  applyReportParam() {
    if (this.reportParams) {
      this.reportParamsSubject$.next(this.reportParams);
    }
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
