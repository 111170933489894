/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Location } from "@angular/common";
import { Component, EventEmitter, Output } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { GenericFormComponent } from "../../../forms/generic-form/generic-form.component";
import { AlertMessagesService } from "../../../shared/services/alert-messages.service";
import { ToggleCaptionPosition } from "../../../taku-ui/taku-toggle/taku-toggle.component";
import { Brand } from "./brand";
import { BusinessBrandService } from "./brand.service";
import { AppSettingsStorageService } from "../../../shared/app-settings-storage.service";
import { AppComponent } from "src/app/app.component";

@Component({
  selector: "taku-brand",
  templateUrl: "./brand.component.html",
  styleUrls: ["./brand.component.scss"],
})
export class BrandComponent extends GenericFormComponent {
  ToggleCaptionPosition = ToggleCaptionPosition;
  @Output() dialogClosed: EventEmitter<any> = new EventEmitter();

  constructor(
    protected _router: Router,
    public fb: UntypedFormBuilder,
    public dbService: BusinessBrandService,
    protected location: Location,
    appSettings: AppSettingsStorageService,
    public _route: ActivatedRoute,
    protected messageService: MessageService,
    protected alertMessage: AlertMessagesService,
    protected confirmationService: ConfirmationService
  ) {
    super(_router, fb, dbService, location, _route, messageService, alertMessage, confirmationService, appSettings);
  }

  initForm() {
    this._model = "brand";
    this._object = new Brand();
    this._myForm = this.fb.group(new Brand());
  }

  initValidation() {
    this._validation = this.dbService.getValidationRules();
  }

  closeDialog() {
    this.dialogClosed.emit();
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
