/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { DecimalPipe } from "@angular/common";
import { Component, Inject, Input, LOCALE_ID } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { GenericFormService } from "src/app/forms/generic-form/generic-form.component";
import { DBService } from "src/app/shared/services/db.service";
import { FormDataHelpers } from "src/app/utility/FormDataHelpers";
import { NestedFormComponent } from "../../../../forms/nested-form/nested-form.component";
import { CommonValidators } from "../../../../shared/validators/CommonValidators";
import { SaleDocFormat } from "./sale-doc-format";
import { ModelFormGroup } from "src/app/utility/ModelFormGroup";
import { BusinessFormatsSettings } from "../../business-settings/business-formats-settings/BusinessFormatsSettings";

@Component({
  selector: "taku-fields-format",
  templateUrl: "./fields-format.component.html",
  styleUrls: ["./fields-format.component.scss"],
})
export class FieldsFormatComponent extends NestedFormComponent<ModelFormGroup<BusinessFormatsSettings>> {
  @Input() docType: string;
  @Input() prefixFieldName = "prefix";
  @Input() suffixFieldName = "suffix";
  @Input() noOfDigitsFieldName = "noOfDigits";
  @Input() startingNumberFieldName = "startingNumber";
  @Input() prefixFieldCaption = "Doc";
  @Input() colTypeTitle = "Document Type";
  decimalPipe: DecimalPipe;

  static init(fb: UntypedFormBuilder) {
    const tmpForm = fb.group(new SaleDocFormat());
    return tmpForm;
  }

  static setArray(fb: UntypedFormBuilder, saleDocFormats: SaleDocFormat[]) {
    const tmpArray = fb.array([]);
    saleDocFormats.forEach((saleFormat) => {
      tmpArray.push(fb.group(saleFormat));
    });

    return tmpArray;
  }

  constructor(
    dbService: DBService,
    fb: UntypedFormBuilder,
    @Inject(LOCALE_ID) localeID: string,
    private genericFormService: GenericFormService
  ) {
    super(dbService, fb);
    this.decimalPipe = new DecimalPipe(localeID);

    this.subsList.push(
      this.genericFormService.formChanged$.subscribe((object) => {
        this.formatFieldStartingNo(this.noOfDigitsCtrl.value);
      })
    );
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.formatFieldStartingNo(this.noOfDigitsCtrl.value);
    this.subsList.push(
      this.noOfDigitsCtrl.valueChanges.subscribe((noDigits) => {
        this.startingNumberCtrl.setValidators(FormDataHelpers.BuildFixedDigitsValidators(noDigits));
        this.formatFieldStartingNo(noDigits);
      })
    );
  }

  private formatFieldStartingNo(noDigits: string) {
    let formattedVal = this.decimalPipe.transform(this.startingNumberCtrl.value, `${noDigits}.0-0`);
    if (formattedVal) {
      formattedVal = formattedVal.replace(/[^\d]/g, "");
      this.startingNumberCtrl.setValue(formattedVal);
    }
  }

  initValidation(): void {
    if (this.docType === "Voucher") {
      this._validation = {
        [this.noOfDigitsFieldName]: [Validators.required, Validators.min(12), CommonValidators.integer()],
      };
      return;
    }
    this._validation = {
      [this.noOfDigitsFieldName]: [
        Validators.required,
        Validators.min(3),
        Validators.max(10),
        CommonValidators.integer(),
      ],
      [this.startingNumberFieldName]: FormDataHelpers.BuildFixedDigitsValidators(this.noOfDigitsCtrl.value),
    };
  }

  // buildStartingNoValidators(noDigits){
  //   return [Validators.required, Validators.min(1), Validators.max(Math.pow(10, noDigits)-1), CommonValidators.integer()];
  // }

  get prefixCtrl() {
    return this._myForm.get(this.prefixFieldName);
  }

  get suffixCtrl() {
    return this._myForm.get(this.suffixFieldName);
  }

  get noOfDigitsCtrl() {
    return this._myForm.get(this.noOfDigitsFieldName);
  }

  get startingNumberCtrl() {
    return this._myForm.get(this.startingNumberFieldName);
  }

  get captionForStartingDocNo(): string {
    return this.docType.replace("Sales", "") + " Starting #";
  }

  onBlurStartingNoField() {
    const noDigits = this.noOfDigitsCtrl.value;
    this.formatFieldStartingNo(noDigits);
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
