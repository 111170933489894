<header>
  <h3>Personal Addresses</h3>
  <em class="defaultAddress-note">* Default Address</em>
</header>

<p-table
  id="main-table"
  responsiveLayout="stack"
  breakpoint="640px"
  [value]="_formArray['controls']"
  styleClass="p-datatable-striped"
>
  <ng-template pTemplate="header">
    <tr>
      <th>Location Type</th>
      <th>Address Type</th>
      <th>Line 1</th>
      <th>Line 2</th>
      <th>City</th>
      <th>Region</th>
      <th>Postal Code</th>
      <th>Country</th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-formGroup>
    <tr>
      <taku-person-address [formGroup]="formGroup" [displayMode]="_displayMode"></taku-person-address>
    </tr>
  </ng-template>
</p-table>
