/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { AccountCreditCard } from "../account-credit-card";
import { Account, AccountType } from "../account/account";
import { ContactAccountBase } from "../account/contact-account-base";
import { Person } from "../person/person";
import { GenericPhone } from "../phone-shared/GenericPhone";

export class PersonalAccount implements ContactAccountBase {
  id: number;
  accountId: number;
  account: Account;
  person: Person;
  consent: boolean;
  consentDateTime: Date;

  constructor() {
    this.id = 0;
    this.account = new Account(AccountType.personal);
    this.person = new Person();
    this.consent = false;
    this.consentDateTime = null;
  }

  get accountName(): string {
    return this.person.fullName;
  }

  get accountEmails(): string[] {
    return this.person.emailAddresses;
  }

  get accountPhones(): string[] {
    return this.person.personPhones.map((phone) => GenericPhone.FormatNumber(phone));
  }

  get accountInitials(): string[] {
    return [this.person.firstName, this.person.lastName].map((word) => word.charAt(0).toUpperCase());
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
