/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit, Output, EventEmitter, OnChanges, OnDestroy } from "@angular/core";
import { Input } from "@angular/core";
import { MenuItem } from "primeng/api";
import { Observable, of, Subscription } from "rxjs";

export type StepChangedEvent = {
  oldIndex: number;
  newIndex: number;
};

@Component({
  selector: "taku-steps",
  templateUrl: "./taku-steps.component.html",
  styleUrls: ["./taku-steps.component.scss"],
})
export class TakuStepsComponent implements OnInit, OnDestroy {
  static readonly DEFAULT_PREV_STEP_LABEL = "Back";
  static readonly DEFAULT_NEXT_STEP_LABEL = "Next";

  subsList: Subscription[] = [];

  @Input() maxSteps: number;

  @Input() backBtnEnabled = true;
  @Input() nextBtnEnabled = true;
  @Input() model: MenuItem[];
  @Input() lastStepLabel: string;
  @Input() lastStepEnabled = true;
  @Input() lastStepVisible = true;
  @Input() validateStepFn: (currStep: number, newStep: number) => Observable<boolean> = () => of(true);
  @Output() onStepChanged: EventEmitter<StepChangedEvent> = new EventEmitter();
  @Output() onStepsCompleted: EventEmitter<any> = new EventEmitter();
  // Set up two-way binding for activeIndex input
  @Output() activeIndexChange: EventEmitter<number> = new EventEmitter<number>();
  @Input() set activeIndex(newIndex) {
    const oldIndex = this._activeIndex;
    this._activeIndex = newIndex;
    this.activeIndexChange.emit(newIndex);

    if (oldIndex !== newIndex)
      setTimeout(() => {
        // Use timeout in order to force change detection and update the selected tab
        this.onStepChanged.emit({ oldIndex, newIndex });
      }, 0);

    // updated UI
    this.updateNavigationLabels(this._activeIndex);
  }

  get activeIndex() {
    return this._activeIndex;
  }

  private _activeIndex: number;
  // template control
  hasPrevStep = false;
  prevStepLabel: string = TakuStepsComponent.DEFAULT_PREV_STEP_LABEL;
  nextStepLabel: string;

  constructor() {}

  ngOnInit() {
    this.subsList.push(
      this.onStepsCompleted.subscribe(() => {
        this.lastStepEnabled = false;
      })
    );

    this.updateNavigationLabels(this.activeIndex);
  }

  updateNavigationLabels(index: number) {
    if (index === 0) {
      this.hasPrevStep = false;
    } else {
      this.hasPrevStep = true;
    }

    // if this is the last step
    if (index === this.model.length - 1) {
      this.nextStepLabel = this.lastStepLabel;
    } else {
      this.nextStepLabel = TakuStepsComponent.DEFAULT_NEXT_STEP_LABEL;
    }
  }

  goPrevStep() {
    const prevStepIndex = Math.max(this.activeIndex - 1, 0);
    this.subsList.push(
      this.validateStepFn(this.activeIndex, prevStepIndex).subscribe((success) => {
        if (!success) return;

        // Only change step if validation was OK
        this.activeIndex = prevStepIndex;
      })
    );
  }

  get nextStepEnabled(): boolean {
    if (!this.nextBtnEnabled) return false;

    // if this is the last step
    if (this.activeIndex === this.model.length - 1) {
      return this.lastStepEnabled;
    }

    return true;
  }

  get prevStepEnabled(): boolean {
    return this.backBtnEnabled;
  }

  goNextStep() {
    if (!this.nextStepEnabled) return false;

    const lastIndex = this.model.length - 1;
    const nextStepIndex = Math.min(this.activeIndex + 1, lastIndex);
    this.subsList.push(
      this.validateStepFn(this.activeIndex, nextStepIndex).subscribe((success) => {
        if (!success) return;

        // If last step and press next
        if (this.activeIndex === lastIndex) this.onStepsCompleted.emit();

        // Only step out if validation was OK
        this.activeIndex = nextStepIndex;
      })
    );
  }

  get isLastStep() {
    return this.activeIndex === this.model.length - 1;
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
