<div id="saleTransactions-wrapper">
  <div class="sales-line-header" [ngClass]="{ 'has-scrollbars': hasScrollbars }">
    <div class="sku">Product</div>
    <div class="qty">Qty</div>
    <div class="line-discount">{{ _isMobile ? "Disc." : "Discount" }}</div>
    <div class="sale-price-col">Price</div>
    <div class="total-price">Total</div>
  </div>
  <div class="sale-lines" #saleLines>
    <div *ngFor="let commitmentLine of commitments.commitmentLines" class="sale-line">
      <div class="line-desc">{{ commitmentLine.inventory?.description1 }}</div>
      <!-- <div class="categories"></div> -->
      <div class="line-body">
        <div class="sku">SKU: {{ commitmentLine.inventory?.sku }}</div>
        <div class="qty">{{ commitmentLine.qty | formatNegativeNumber }}</div>
        <div class="line-discount">
          <ng-template [ngIf]="commitmentLine.discountAmount > 0">
            {{
              (commitmentLine.unitPrice * +commitmentLine.discountAmount) / 100
                | currency : commitments.currencyIsoCode : "symbol-narrow" : "1.2"
            }}
            <span><br />({{ commitmentLine.discountAmount | number : "1.2" }}%)</span>
          </ng-template>
        </div>
        <div class="sale-price-col">
          <div class="sale-price"
            >{{
              (commitmentLine.unitPrice * (100 - commitmentLine.discountAmount)) / 100
                | currency : commitments.currencyIsoCode : "symbol-narrow" : "1.2"
            }}
          </div>
          <div *ngIf="commitmentLine.discountAmount > 0" class="original-price">
            {{ commitmentLine.unitPrice | currency : commitments.currencyIsoCode : "symbol-narrow" : "1.2" }}</div
          >
        </div>
        <div class="total-price">
          <span class="total-amount">{{
            ((commitmentLine.unitPrice * (100 - commitmentLine.discountAmount)) / 100) * commitmentLine.qty
              | currency : commitments.currencyIsoCode : "symbol-narrow" : "1.2"
              | formatNegativeNumber
          }}</span>
          <!-- <span class="tax-acronyms">{{ getLineTaxAcronyms(commitmentLine) }}</span> -->
        </div>
      </div>
      <div class="saleTransactions-saleLine-details">
        <span><i class="material-icons">bookmark_border</i> {{ inventoryVariantsStr(commitmentLine.inventory) }}</span>
      </div>
      <div *ngIf="commitments.recurringOrderSetting" class="saleTransactions-saleLine-details">
        <span>
          <i class="material-icons">history</i> {{ commitments.recurringOrderSetting.recurringOrderName }}
          <span class="pipe-sep">|</span> <i class="material-icons">repeat</i> {{ commitments.repeatCycle }}
          <span class="pipe-sep collapsePoint">|</span> <i class="material-icons">local_shipping</i>
          {{ commitments.deliveryMethod }}
          <ng-container *ngIf="commitments.docNo">
            <span class="pipe-sep">|</span>
            <i class="material-icons docNumberIcon">event_repeat</i> Commitment # {{ commitments.docNo }}
          </ng-container>
        </span>
      </div>
      <div *ngIf="commitmentLine.note" class="line-notes">Note: {{ commitmentLine.note }}</div>
    </div>
  </div>

  <div class="summary-wrapper">
    <div *ngIf="commitments.refSaleDoc.doc.comment" class="invoice-notes">
      <h3>Invoice Notes</h3>
      <p>
        {{ commitments.refSaleDoc.doc.comment }}
      </p>
    </div>
    <div class="summary">
      <table cellspacing="0">
        <tr>
          <td class="label-col">Sub-total:</td>
          <td class="amount-col">
            {{
              commitments.subTotal
                | currency : commitments.currencyIsoCode : "symbol-narrow" : "1.2"
                | formatNegativeNumber
            }}
          </td>
        </tr>

        <tr>
          <td class="label-col">- Discount:</td>
          <td class="amount-col">
            {{
              -1 * commitments.discountAmountMoney
                | currency : commitments.currencyIsoCode : "symbol-narrow" : "1.2"
                | formatNegativeNumber
            }}
          </td>
        </tr>

        <tr>
          <td class="label-col">+ Shipping:</td>
          <td class="amount-col">
            {{
              commitments.shipperChargeAmount
                | currency : commitments.currencyIsoCode : "symbol-narrow" : "1.2"
                | formatNegativeNumber
            }}
          </td>
        </tr>

        <tr class="total-line">
          <td class="label-col">TOTAL:</td>
          <td class="amount-col">
            {{
              commitments.grandTotal
                | currency : commitments.currencyIsoCode : "symbol-narrow" : "1.2"
                | formatNegativeNumber
            }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>

<p-dialog
  [(visible)]="_activeDialog"
  [contentStyle]="{ maxHeight: '100%' }"
  header="Confirmation"
  styleClass="fixedsize-modal"
  [blockScroll]="true"
  [modal]="true"
>
  <p>
    {{ _dialogContent }}
  </p>
  <ng-template pTemplate="footer">
    <p-button
      icon="pi pi-check"
      (click)="_activeDialog = false"
      label="Ok"
      styleClass="p-button-text"
    ></p-button> </ng-template
></p-dialog>
