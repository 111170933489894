<div #container *ngIf="useMobileDesign" class="self-checkout-mobile-container">
  <taku-sale-doc-self-checkout-wrapper-mobile
    #invoiceDocComponent
    [dvWidth]="container.offsetWidth"
    [dvHeight]="container.offsetHeight"
  >
  </taku-sale-doc-self-checkout-wrapper-mobile>
</div>

<div *ngIf="useTabletDesign" class="self-checkout-tablet-container">
  <taku-sale-doc-self-checkout-wrapper-desktop #invoiceDocComponent> </taku-sale-doc-self-checkout-wrapper-desktop>
</div>

<div *ngIf="useDesktopDesign" class="self-checkout-desktop-container">
  <taku-sale-doc-self-checkout-wrapper-desktop #invoiceDocComponent> </taku-sale-doc-self-checkout-wrapper-desktop>
</div>
