<taku-form-header
  toolbarTitle="Orders for Fulfillment"
  displayMode="inline"
  [showActionBtns]="false"
  [showBackBtn]="false"
>
</taku-form-header>

<!-- <div class="grid"> -->
<div id="sale-channel-search-box" class="col-12">
  <div class="grid fields-line">
    <div class="col-12 sm:col-6 lg:col-3 p-fluid">
      <taku-taku-calendar-range
        [formGroup]="searchForm"
        fieldCaption="Outgoing Date Range"
        fieldNameRangeType="dateRangeType"
        fieldNameDateFrom="dateFrom"
        fieldNameDateTo="dateTo"
        [maxDateValue]="maxDateValue"
      ></taku-taku-calendar-range>
    </div>
    <div class="col-12 sm:col-6 lg:col-3 p-fluid">
      <taku-dropdown
        [formGroup]="searchForm"
        [options]="enum_saleDocSource"
        fieldCaption="Source"
        fieldName="customerSource"
      ></taku-dropdown>
    </div>
    <div class="col-12 sm:col-6 lg:col-3 p-fluid">
      <taku-dropdown
        [formGroup]="searchForm"
        [options]="enum_payment_statuses"
        fieldName="paymentStatus"
        fieldCaption="Payment Status"
      ></taku-dropdown>
    </div>
    <div class="col-12 sm:col-6 lg:col-3 p-fluid">
      <taku-multiselect
        [formGroup]="searchForm"
        [options]="enum_fulfillment_statuses"
        fieldName="fulfillmentStatus"
        fieldCaption="Fulfillment Status"
        [dataKey]="null"
      ></taku-multiselect>
    </div>
    <div class="col-12 sm:col-6 lg:col-3 p-fluid">
      <taku-dropdown
        [formGroup]="searchForm"
        [options]="lookup_stores$ | async"
        fieldCaption="Fulfillment Store"
        fieldName="storeId"
      ></taku-dropdown>
    </div>
    <div class="col-12 sm:col-6 lg:col-3 p-fluid">
      <taku-dropdown
        [formGroup]="searchForm"
        [options]="enum_delivery_methods"
        fieldName="deliveryMethod"
        fieldCaption="Delivery Method"
      ></taku-dropdown>
    </div>
    <div class="col-12 sm:col-6 lg:col-3 p-fluid">
      <taku-multiselect
        [formGroup]="searchForm"
        [options]="enum_delivery_statuses"
        fieldName="deliveryStatus"
        fieldCaption="Delivery Status"
        [dataKey]="null"
      ></taku-multiselect>
    </div>
    <div class="col-12 sm:col-6 lg:col-3 p-fluid">
      <button
        pButton
        pRipple
        type="button"
        label="Refresh"
        class="p-button-raised p-button-secondary mt-2"
        (click)="clearSearch()"
      ></button>
    </div>
  </div>

  <div class="order-completion-menu-container">
    <div class="order-completion-menu">
      <p-tabMenu [model]="tabMenuItems" [activeItem]="tabMenuItems[defaultActiveTabIdx]"></p-tabMenu>
    </div>
    <button
      pButton
      pRipple
      icon="pi pi-info-circle"
      class="order-completion-menu-info-tooltip p-button-text p-button-rounded p-button-icon-only"
      (click)="tabMenuInfoPanel.toggle($event)"
    >
      <p-overlayPanel #tabMenuInfoPanel [showCloseIcon]="false" appendTo="body">
        <div class="tooltip-styling">
          <h5>What is an "Open Order”?</h5>
          <p
            >An order is considered Open if it has not been fully Paid, Completed for fulfillment, and Picked Up or
            Delivered.</p
          >
          <h5>What is a "Closed Order”?</h5>
          <p
            >An order is considered closed once nothing more needs to be done by the merchant. In TAKU, this refers to
            when Payment Status, Fulfillment Status and Delivery Status have all been marked as fully Paid, Completed,
            and Picked Up or Delivered at the same time at some point.</p
          >
        </div>
      </p-overlayPanel>
    </button>
  </div>

  <div id="data-progressbar">
    <p-progressBar mode="indeterminate" value="0" [style.visibility]="_isFetchingData ? 'visible' : 'hidden'">
    </p-progressBar>
  </div>

  <div class="col-12 formlistWrapper">
    <taku-form-list
      #formList
      *ngIf="formFilter"
      [_filter]="formFilter"
      [_model]="'saleDoc'"
      _dialogStyleClass="fullscreen-mobilemodal"
      [_dialogShowHeader]="false"
      [_hideSavingCtrls]="true"
      [_showCtrlsInEditMode]="false"
      [_hideAddLineHeaderBtn]="true"
      [_hideOptionsHeaderBtn]="false"
      [_hideGlobalFilter]="true"
      [_hideAddHeaderBtn]="true"
      [_hideDeleteHeaderBtn]="true"
      _title="Orders for Fulfillment"
      [_rowSelectionType]="RowSelectionType.MULTIPLE"
      _detailMode="n"
      _scrollHeight="calc(100vh - 33rem)"
      [_hideClone]="true"
      _viewColIcon="_"
      _viewColIconName="remove_red_eye"
      [_cols]="upcomingOrderColumns"
      [_dialogDismissable]="true"
      [_saveFormState]="false"
      [defaultSortField]="'deliveryDate'"
      [defaultSortOrder]="1"
    >
      <!-- <div takuFormListHeaderCustomBtns>
          <p-button icon="ui-icon-print"  label="Packing List" (click)="onPrintPackingList(ReceiptPrintingSize.LETTER_SIZE)" [disabled]="formList._selectedObjects === null || formList._selectedObjects?.length === 0"></p-button>
      </div> -->
    </taku-form-list>
  </div>
</div>

<div [hidden]="true">
  <ng-template #printPreviewHost></ng-template>
</div>
