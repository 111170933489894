/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit } from "@angular/core";
import { StoreSocialProfileType, StoreSocialProfile } from "./store-social-profile";
import { NestedFormComponent } from "../../../forms/nested-form/nested-form.component";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";

@Component({
  selector: "taku-store-social-profile",
  templateUrl: "./store-social-profile.component.html",
  styleUrls: ["./store-social-profile.component.scss"],
})
export class StoreSocialProfileComponent extends NestedFormComponent {
  // Define enum variables
  // enum_commecrial_phone_type = this.dbService.enumSelectOptions(CommercialAccountPhoneType, 'Type');
  enum_store_social_profile_type = this.dbService.enumSelectOptions(StoreSocialProfileType);

  static init(fb: UntypedFormBuilder): UntypedFormGroup {
    const storeSocialProfile_Group = fb.group(new StoreSocialProfile());
    return storeSocialProfile_Group;
  }

  static setArray(fb: UntypedFormBuilder, storePSocialProfiles: StoreSocialProfile[]) {
    const formArray = fb.array([]);
    storePSocialProfiles.map((storeSocialProfile) => {
      const temp = fb.group(storeSocialProfile);
      formArray.push(temp);
    });
    return formArray;
  }

  initValidation() {
    this._validation = {};
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
