<div class="main-container">
  <taku-form-header
    toolbarTitle="Return Item Search"
    displayMode="inline"
    [showActionBtns]="false"
    [showBackBtn]="false"
  ></taku-form-header>

  <!-- <header>
      <taku-form-header toolbarTitle="RETURNS" displayMode="inline" [showActionBtns]="false" [showBackBtn]="false"></taku-form-header>
    </header> -->

  <main class="card">
    <div class="tabpanel-container grid">
      <div class="col-12 search-box">
        <div class="grid">
          <div class="col-12 xl:col-6 field-docNo">
            <taku-input
              (keyup)="onKeyPressedDocNo($event)"
              [formGroup]="docsSearchForm"
              fieldName="docNo"
              fieldCaption="Doc #"
              [leftIconClass]="'material-icons md-18'"
              [leftIconName]="'receipt'"
              [rightIconClass]="'pi pi-search'"
            ></taku-input>
          </div>

          <div class="col-12 xl:col-6 date-fields">
            <div class="grid">
              <div class="col-6 field-datefrom">
                <taku-calendar-date
                  [disabled]="docsSearchForm.get('docNo').value"
                  [formGroup]="docsSearchForm"
                  fieldName="dateFrom"
                  fieldCaption="Date From:"
                  appendTo="body"
                ></taku-calendar-date>
              </div>

              <div class="col-6 field-dateto">
                <taku-calendar-date
                  [disabled]="docsSearchForm.get('docNo').value"
                  [formGroup]="docsSearchForm"
                  fieldName="dateTo"
                  fieldCaption="Date To:"
                  appendTo="body"
                ></taku-calendar-date>
              </div>
            </div>
          </div>

          <!-- ********** search box for Items **************** -->
          <div
            class="col-12 xl:col-6 inventory-search-wrapper"
            [ngClass]="{ disabled: docsSearchForm.get('docNo').value }"
          >
            <div class="p-inputgroup inputgroup-outlined">
              <span class="p-inputgroup-addon"><i class="material-icons">local_offer</i></span>
              <taku-search-inventory
                id="inventory_search"
                #searchInventoryComp
                [disabled]="docsSearchForm.get('docNo').value"
                [includeCreateNewBtns]="false"
                [autoClearOnSelection]="false"
                placeholder="Search for Items"
                (onSelectedItemCleared)="clearInventoryField()"
                (itemSelected)="onInventorySearch($event)"
              >
              </taku-search-inventory>
            </div>
          </div>

          <!-- **********search box for Customer**************** -->
          <div
            class="col-12 xl:col-6 customer-search-wrapper"
            [ngClass]="{ disabled: docsSearchForm.get('docNo').value }"
          >
            <div class="p-inputgroup inputgroup-outlined">
              <span class="p-inputgroup-addon"><i class="material-icons">group</i></span>
              <taku-search-accounts
                id="account_search"
                #searchAccountsComp
                [disabled]="docsSearchForm.get('docNo').value"
                [includeCreateNewBtns]="false"
                [autoClearOnSelection]="false"
                placeholder="Account"
                (onSelectedItemCleared)="clearAccountField()"
                (itemSelected)="onAccountSearch($event)"
              >
              </taku-search-accounts>
            </div>
          </div>

          <div class="col-12 search-btn-line">
            <div class="col-5 left-side">
              <div class="grid">
                <div class="col-12 p-fluid noReceipt-wrapper">
                  <button
                    *ngIf="allowNoInvoiceReturns"
                    pButton
                    label="No Receipt"
                    class="p-button-secondary"
                    (click)="onNoReceiptPressed()"
                  ></button>
                </div>
                <div class="col-12">
                  <div *ngIf="storePolicies?.returnPolicy as returnPolicy" class="return-policy-line"
                    ><span class="label">Return policy:</span> <em [innerHTML]="returnPolicy"></em
                  ></div>
                </div>
              </div>
            </div>
            <div class="col-7 search-box-btns text-right">
              <button
                id="clear_btn"
                pButton
                label="Clear"
                class="btn-clear-all p-button-secondary"
                (click)="clearSearchFields()"
                ><span class="hidden-icons p-inputgroup-addon"><i class="icon pi pi-times"></i></span
              ></button>
              <button
                id="search_btn"
                pButton
                label="Search"
                [loading]="_isLoading"
                class="btn-search"
                (click)="searchMultipleDocuments()"
                ><span class="hidden-icons p-inputgroup-addon"><i class="icon pi pi-search"></i></span
              ></button>
            </div>
          </div>
        </div>
      </div>

      <!-- <div *ngIf="!saleDoc">
            <div class="no-lines-note">No results found.</div>
        </div> -->

      <p-table
        *ngIf="saleDoc"
        class="col-12 doc-summary"
        responsiveLayout="stack"
        breakpoint="640px"
        [value]="[saleDoc]"
        styleClass="p-datatable-striped"
      >
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th class="doc-col">Doc#</th>
            <th class="account-col">Account</th>
            <th class="date-col">Date</th>
            <th class="total-col">Total</th>
            <th class="payment-col">Payment Type</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-saleDoc>
          <tr>
            <td><span class="p-column-title">Doc#:</span> {{ saleDoc.doc.docNo }}</td>
            <td><span class="p-column-title">Account:</span> {{ saleDoc.accountName }}</td>
            <td><span class="p-column-title">Date:</span> {{ saleDoc.doc.docDate | dateFormat }}</td>
            <td
              ><span class="p-column-title">Total:</span>
              {{ saleDoc.grandTotal | currency : "CAD" : "symbol-narrow" : "1.2" }}</td
            >
            <td>
              <span class="p-column-title">Payment Type:</span>
              <span class="flex align-items-center">
                <span>{{ tendersPrettyPrint }}</span>
                <taku-tender-details-overlay [saleDocTenders]="saleDoc.saleDocTenders"></taku-tender-details-overlay>
              </span>
            </td>
          </tr>
        </ng-template>
      </p-table>

      <div id="doc-transactions" class="col-12 expandable-content" #docTransactions>
        <ng-template [ngIf]="saleDoc && saleDoc.saleDocLines.length > 0">
          <header>
            <h3 class="col-5 sm:col-4">Transaction Details</h3>

            <div class="col-6 col-offset-1 sm:col-8 sm:col-offset-0 inventory-search-invoice">
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon"><i class="material-icons">local_offer</i></span>
                <input
                  type="text"
                  pInputText
                  placeholder="Items in Invoice"
                  [(ngModel)]="tmpInputCriteria"
                  (keyup)="onTransactionFilterKeyup($event)"
                  style="border: none"
                />
                <i
                  *ngIf="tmpInputCriteria"
                  class="material-icons clear-field"
                  (click)="resetInvoiceFilter()"
                  style="padding-top: 7px"
                  >close</i
                >
                <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
              </div>
            </div>
          </header>

          <p-table
            class="transactions-table"
            responsiveLayout="stack"
            breakpoint="640px"
            [scrollable]="false"
            [value]="filteredSaleLines"
            styleClass="p-datatable-striped"
          >
            <ng-template pTemplate="header" let-columns>
              <tr class="sales-line-header">
                <th class="sku-col">Product Title</th>
                <th class="qty-col">Quantity</th>
                <th class="sale-price-col">Price</th>
                <th class="total-price-col">Total</th>
                <th class="actions-col">Action</th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-saleLine>
              <tr class="line-desc">
                <td colspan="5">
                  <span class="p-column-title">Product Title:</span>{{ saleLine.docLine?.inventory.description1 }}
                </td>
              </tr>
              <!-- <div class="categories"></div> -->
              <tr class="line-body">
                <td class="sku-col">
                  <span class="p-column-title">SKU:</span> {{ _isMobile ? "" : "SKU: " }}
                  {{ saleLine.docLine?.inventory.sku }}
                  <b *ngIf="saleLine.voucher?.saleDocTender">(Used)</b>
                </td>
                <td class="qty-col"> <span class="p-column-title">Quantity:</span> {{ saleLine.qtyNotReferred }} </td>
                <td class="sale-price-col">
                  <span class="p-column-title">Price:</span>
                  <span>
                    <span class="sale-price">{{
                      saleLine.salePrice | currency : "CAD" : "symbol-narrow" : "1.2"
                    }}</span>
                    <span *ngIf="saleLine.salePrice != saleLine.unitPrice" class="original-price">{{
                      saleLine.unitPrice | currency : "CAD" : "symbol-narrow" : "1.2"
                    }}</span>
                  </span>
                </td>
                <td class="total-price-col">
                  <span class="p-column-title">Total:</span
                  >{{ saleLine.salePrice * saleLine.qty | currency : "CAD" : "symbol-narrow" : "1.2" }}
                </td>
                <td class="actions-col">
                  <span class="p-column-title"></span>
                  <button
                    id="return_btn"
                    class="p-button-secondary"
                    label="Return"
                    (click)="onReturnSingleItem(saleLine)"
                    [disabled]="
                      saleLine.recurringOrderSettingId ||
                      saleLine.qtyNotReferred <= 0 ||
                      saleLine.voucher?.saleDocTender
                    "
                    pButton
                  ></button>
                </td>
              </tr>

              <tr *ngIf="saleLine.docLine?.note" class="line-notes">
                <td colspan="5">Note: {{ saleLine.docLine?.note }}</td>
              </tr>
            </ng-template>
          </p-table>
        </ng-template>

        <div *ngIf="saleDoc && !saleDoc.saleDocLines.length" class="no-lines-note"
          >Note: This document doesn't contain returnable transactions.</div
        >
      </div>

      <div class="col-12 transaction-btns">
        <button
          label="Show All"
          (click)="resetInvoiceFilter()"
          pButton
          *ngIf="saleDoc && saleDoc.saleDocLines.length !== filteredSaleLines.length"
          class="p-button-secondary btn-show-all"
        ></button>
        <button
          label="Return All"
          (click)="onReturnAll()"
          pButton
          [disabled]="isReturnAllButtonDisabled"
          *ngIf="saleDoc && saleDoc.saleDocLines.length !== 0"
        ></button>
      </div>

      <!-- <div class="manager-options">
          <button class="p-button-secondary" [disabled]="true" pButton label="Manager Options"></button>
        </div> -->
    </div>
  </main>

  <!-- <div id="multiple-search-results" class="expandable-content col-12">

    </div> -->

  <!-- <footer>
    </footer> -->
</div>
