/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { APP_BRAND } from "src/app/utility/white-label";

@Component({
  selector: "taku-sale-channel-landing",
  templateUrl: "./sale-channel-landing.component.html",
  styleUrls: ["./sale-channel-landing.component.scss"],
})
export class SaleChannelLandingComponent implements OnInit {
  branding = APP_BRAND;
  constructor(private router: Router, protected _route: ActivatedRoute) {}

  ngOnInit(): void {}

  onGetStartedPressed() {
    this._route.params.subscribe((params) => {
      if (params["storeId"]) {
        const _storeId = params.storeId;
        const queryParams = {};
        this._route.queryParams.subscribe((_queryParams) => {
          queryParams["title"] = _queryParams["title"];
          queryParams["force"] = true;

          this.router.navigate(["/integrations/my-apps/sale-channel/settings", _storeId], {
            queryParams: queryParams,
          });
        });
      }
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
