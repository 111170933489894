/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

export class UserProfile {
  id?: number;
  userName: string;
  firstName: string;
  lastName: string;
  role: string;
  imageUrl?: URL;
  email: string;
  uuid: string;

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
