/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { StoreSettingReturnReason } from "../return-reason/StoreSettingReturnReason";
import { Store } from "../store/store";

export class ReturnSetting {
  id: number;
  canReturnWithoutInvoice: boolean;
  canReturnOnSaleScreen: boolean;
  storeSettingReturnReasons: StoreSettingReturnReason[];
  storeId: number;
  store: Store;

  constructor(store?: Store) {
    this.id = 0;
    this.canReturnWithoutInvoice = true;
    this.canReturnOnSaleScreen = true;
    this.storeSettingReturnReasons = [];
    this.storeId = store ? store.id : 0;
    this.store = store || null;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
