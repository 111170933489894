/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

/* [Nazy] Entry point to app bundle and this file executes the actual bootstrap method that launches the app
 * in the webpack configuration file this file is the entry file to our app  --> IT SEEMS WE DONT HAVE WEBPACK
 * any file that added to this file will be added to the bundle */

import { enableProdMode, LOCALE_ID } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
/* [Nazy] it converts our code to display in the browser at run-time
 * we also have the option of using the ahead of time compiler, this produces the smaller project that might run better on mobile devices
 * 1) Ahead-of-time(AOT) vs 2) just-in-time(JIT)
 * Learn more in: https://angular.io/guide/aot-compiler */

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { AppConstants } from "./app/shared/app-constants";

if (environment.production) {
  enableProdMode();
  if (window) {
    window.console.log = () => {
      return false;
    };
  }
}

/* [Nazy] this line will bootstrap our app with root module
 * main.ts => app.module.ts => app.component.ts */
platformBrowserDynamic().bootstrapModule(AppModule, {
  providers: [{ provide: LOCALE_ID, useValue: AppConstants.DEFAULT_LOCALE_ID }],
});

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
