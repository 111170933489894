import { Component, Input } from "@angular/core";
import { SaleDocTender } from "../sale-doc-tender/sale-doc-tender";

@Component({
  selector: "taku-pay-type-details",
  templateUrl: "./pay-type-details.component.html",
  styleUrls: ["./pay-type-details.component.scss"],
})
export class PayTypeDetailsComponent {
  readonly decimalFormat = "1.2-2";

  @Input() saleDocTenders: SaleDocTender[];
}
