/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import type { ConfirmationService } from "primeng/api";
import { Observable, of } from "rxjs";
import { environment } from "src/environments/environment";

export class WebHelpers {
  static readonly SMALLER_MOBILE_BREAKPOINT = 375;
  static readonly MOBILE_BREAKPOINT = 640;
  static readonly TABLET_BREAKPOINT = 992;

  /** If you are trying to launch a new confirmationDialog very soon after closing a different one,
   *  the 2nd one may not be created correctly unless the first one is removed from the DOM first.
   *  This observable will emit once that is true. */
  static waitForConfirmationDialogRemoval(confirmationService: ConfirmationService): Observable<boolean> {
    const existingConfirmation = document.querySelector(".p-dialog-mask:has(.p-confirm-dialog)");
    if (!existingConfirmation) {
      return of(true);
    }
    confirmationService.close();
    return new Observable<boolean>((observer) => {
      const mut = new MutationObserver((entries) => {
        entries[0].removedNodes.forEach((el) => {
          if (el === existingConfirmation) {
            observer.next(true);
            observer.complete();
          }
        });
      });
      mut.observe(document.body, { childList: true });
      observer.add(() => mut.disconnect());
    });
  }

  static isSmallerMobileScreen(): boolean {
    return window.innerWidth <= this.SMALLER_MOBILE_BREAKPOINT;
  }

  static isMobileScreen(): boolean {
    return window.innerWidth <= this.MOBILE_BREAKPOINT;
  }

  static isPortraitTabletScreen(): boolean {
    return window.innerWidth < this.TABLET_BREAKPOINT && !this.isMobileScreen();
  }

  static isDesktopScreen(): boolean {
    return window.innerWidth >= this.TABLET_BREAKPOINT;
  }

  static reattachBodyClassesForDialog(el: HTMLElement): void {
    if (el.closest(".p-overlaypanel, .p-dialog")) {
      document.body.classList.add("p-overflow-hidden");
    }
  }

  static buildImageUrl(imagePath: string): string | null {
    if (!imagePath) return null;

    return environment.apiUrl.replace(/\/api\/?/, "/") + imagePath;
  }

  static isTouchDevice(): boolean {
    return (
      !!(
        typeof window !== "undefined" &&
        ("ontouchstart" in window ||
          (window["DocumentTouch"] && typeof document !== "undefined" && document instanceof window["DocumentTouch"]))
      ) || !!(typeof navigator !== "undefined" && navigator.maxTouchPoints)
    );
  }

  static supportsScrollMethod(): boolean {
    const el = document.createElement("div");
    return !!el.scroll;
  }

  static CancelKeydownBubbling(element: HTMLInputElement, keyCode: number, keyName: string): void {
    return element.addEventListener("keydown", function (e) {
      const isTabPressed = e.key === keyName || e.keyCode === keyCode;

      if (!isTabPressed) {
        return;
      }

      e.stopImmediatePropagation();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
