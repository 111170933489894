<taku-form-header
  [formGroup]="_myForm"
  (backClicked)="goBack()"
  (saveClicked)="onSave()"
  (revertClicked)="onRevert()"
  [toolbarTitle]="pageTitle"
  iconStyleClass="material-icons"
  iconName="dock"
  [showSecondaryBtns]="false"
>
</taku-form-header>

<div [formGroup]="_myForm" class="grid pt-2">
  <p-card class="col-12">
    <div class="col-12">
      <h2>Opening Screen Options</h2>
    </div>

    <div class="col-12 customize-color-theme-instruction-container">
      <span class="pr-2 customize-color-instruction">Click the colored square below to customize.</span>
      <a href="javascript://" class="pi pi-info-circle" (click)="colorThemeInfoPanel.toggle($event)">
        <p-overlayPanel #colorThemeInfoPanel [showCloseIcon]="false" appendTo="body">
          <div class="tooltip-styling">
            <h5>NOTE:</h5>
            <ul>
              <li>Category buttons are shared between the Salesscreen and Kiosk.</li>
              <li>Any changes here to category button colors will also be visible in the Salesscreen Gallery.</li>
            </ul>
          </div>
        </p-overlayPanel>
      </a>
    </div>

    <p-fieldset class="color-theme-fieldset col-12" legend="Color Theme" [toggleable]="false" [collapsed]="false">
      <!-- <p>Click the colored square below to customize the kiosk opening screen.</p> -->
      <div class="col-12 md:col-8 lg:col-6 colorPickers-container">
        <div class="colorPickers-row">
          <p-colorPicker formControlName="colorTheme"></p-colorPicker>
          <span class="colorPicker-label">Main Screen Button Background</span>
        </div>
        <div class="colorPickers-row">
          <p-colorPicker formControlName="mainScreenBtnFontColor"></p-colorPicker>
          <span class="colorPicker-label">Main Screen Button Font</span>
        </div>
        <div class="colorPickers-row">
          <p-colorPicker formControlName="categoryBtnBgColor"></p-colorPicker>
          <span class="colorPicker-label">Category Button Background</span>
        </div>
        <div class="colorPickers-row">
          <p-colorPicker formControlName="categoryBtnFontColor"></p-colorPicker>
          <span class="colorPicker-label">Category Button Font</span>
        </div>
      </div>

      <div class="col-12 md:col-8 lg:col-6 apply-default-colors-container">
        <button pButton icon="pi pi-refresh" (click)="applyDefaultColors()"></button>
        <span>Apply default colours</span>
      </div>
    </p-fieldset>

    <p-fieldset class="col-12" legend="Opening Screen Image" [toggleable]="false" [collapsed]="false">
      <div class="grid">
        <div class="col-6">
          <p-fileUpload
            #fileUploader
            name="image"
            auto="true"
            accept="image/*"
            customUpload="true"
            (uploadHandler)="myUploader($event)"
          ></p-fileUpload>
        </div>
        <div class="col-6" style="margin-top: -2em">
          <div *ngIf="_myForm.value.urlOpeningScreenImageFileName && !imageUrlCtrl.dirty">
            <i class="material-icons clear-field" (click)="clearImage()">close</i>
            <div class="col-12 main-thumbnail-container">
              <img [attr.src]="fileNameImageUrl" />
            </div>
          </div>
        </div>
      </div>
    </p-fieldset>

    <p-fieldset class="col-12" legend="Activate Kiosk Mode" [toggleable]="false" [collapsed]="false">
      <p class="activate-kiosk-instructions">
        <strong>NOTE:</strong> Once you are in kiosk mode, this device will be locked in that mode until the password is
        entered. When you're in kiosk mode, click <i class="material-icons">menu</i> and enter the password to exit. If
        the password is forgotten, a user with administrator access will be required to exit kisok mode on this device.
      </p>
      <p-password formControlName="password" [toggleMask]="true"></p-password>
    </p-fieldset>

    <p-fieldset class="col-12" legend="Checkout Settings" [toggleable]="false" [collapsed]="false">
      <taku-toggle
        [formGroup]="_myForm"
        fieldName="allowPayAtCounter"
        fieldCaption='Activate "Pay at Counter"'
        enabledStateLabel="ON"
        disabledStateLabel="OFF"
      ></taku-toggle>
    </p-fieldset>

    <p-fieldset
      class="col-12"
      legend="Set Up Add-Ons"
      [toggleable]="false"
      [collapsed]="false"
      class="set-up-add-ons-fieldset"
    >
      <p
        >Sell Fixed products during check-out when this device is in kiosk mode. Add optional fees(e.g. bag fees) or
        upsell add-ons by adding inventory items below.</p
      >
      <div class="grid">
        <div class="col-12">
          <div class="col-12 lg:col-6 flex">
            <div class="p-inputgroup inputgroup-outlined field-searchInventory">
              <span class="p-inputgroup-addon"><i class="material-icons">local_offer</i></span>
              <taku-search-inventory
                id="inventory_search"
                #inventorySearchBox
                [autoClearOnSelection]="true"
                placeholder="Search for Items"
                (itemSelected)="onSearchResInventory($event)"
                [showAnimatePlaceholder]="false"
              >
              </taku-search-inventory>
            </div>
            <!-- <button class="remove-items-btn" pButton (click)="removeInventoryItem(selectionInventories)">Remove Selected item</button> -->
          </div>
        </div>
        <div class="col-12">
          <div class="col-12 lg:col-6">
            <p-orderList
              [value]="inventories"
              filterBy="name"
              filterBy="inventory.description1"
              header="Selected Add-Ons"
            >
              <!-- <p-orderList  [value]="inventories" filterBy="name" filterBy="inventory.description1"
            header="Selected Add-Ons" [(selection)]="selectionInventories"> -->
              <ng-template let-inventory pTemplate="item">
                <taku-gallery-list-card [inventory]="inventory">
                  <span SKULink>
                    <a
                      class="event-emitter"
                      *ngIf="isInventoryDetailsVisible"
                      (click)="onClickedViewDetails(inventory, $event)"
                      ><u>{{ inventory.sku }}</u></a
                    >
                    <a *ngIf="!isInventoryDetailsVisible">{{ inventory.sku }}</a>
                  </span>
                  <i
                    removeItem
                    class="material-icons-outlined remove-button event-emitter"
                    (click)="removeInventoryItem(inventory, $event)"
                  >
                    remove_circle_outline
                  </i>
                </taku-gallery-list-card>
                <!-- {{inventory.description1}} -->
              </ng-template>
            </p-orderList>
          </div>
        </div>
      </div>
    </p-fieldset>
  </p-card>
</div>
