/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
export namespace ContentAPI_Account {
  export class Account {
    id: number;
    name: string;
    websiteUrl: string;
    adultContent: boolean;
    businessInformation: AccountBusinessInformation;
    users: AccountUser[];
    googleMyBusinessLink: LinkedGMBAccount;

    constructor(adminEmail: string) {
      this.id = null;
      this.name = null;
      this.websiteUrl = null;
      this.adultContent = false;

      this.businessInformation = {
        address: new AccountAddress(),
        phoneNumber: null,
        customerService: new AccountCustomerService(),
      };

      this.users = adminEmail ? [{ emailAddress: adminEmail, admin: true }] : null;
      this.googleMyBusinessLink = null;
    }
  }

  export type LinkedGMBAccount = {
    gmbEmail: string;
    status: LinkedGMBAccount_Status;
  };

  export enum LinkedGMBAccount_Status {
    ACTIVE = "active",
    INACTIVE = "inactive",
    PENDING = "pending",
  }

  export type AccountUser = {
    emailAddress: string;
    admin: boolean;
  };

  export type AccountBusinessInformation = {
    address: AccountAddress;
    phoneNumber: string;
    customerService: AccountCustomerService;
  };

  export class AccountCustomerService {
    url: string;
    email: string;
    phoneNumber: string;

    constructor() {
      this.url = null;
      this.email = null;
      this.phoneNumber = null;
    }
  }

  export class AccountAddress {
    streetAddress: string;
    locality: string;
    region: string;
    postalCode: string;
    country: string;

    constructor() {
      this.streetAddress = null;
      this.locality = null;
      this.region = null;
      this.postalCode = null;
      this.country = null;
    }
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
