<div [formGroup]="_myForm">
  <div class="grid center">
    <div class="grid col">
      <div class="col-12 xl:col-4 lg:col-6 drop-container">
        <taku-dropdown
          [formGroup]="_myForm"
          [options]="enum_commecrial_email_type"
          [fieldName]="'emailType'"
          [fieldCaption]="'Email Type'"
        >
        </taku-dropdown>
      </div>
      <div class="col-12 xl:col-8">
        <taku-input [formGroup]="_myForm" [fieldName]="'email'" [fieldCaption]="'Email'"></taku-input>
      </div>
    </div>
    <div class="col-fixed">
      <taku-action-button [action]="ACTION_TYPE.COPY" (clickAction)="copyEmailToClipboard()"></taku-action-button>
    </div>
  </div>
</div>
