import { Component, Input, OnInit } from "@angular/core";
import { Category } from "src/app/core/inventory/category/category";

@Component({
  selector: "taku-category-grid-card",
  templateUrl: "./category-grid-card.component.html",
  styleUrls: ["./category-grid-card.component.scss"],
})
export class CategoryGridCardComponent implements OnInit {
  @Input() category: Category;
  @Input() width: number;
  @Input() _isMobile = false;
  @Input() height: number;

  @Input() categoryBgColor: string;
  @Input() categoryFontColor: string;

  constructor() {}

  ngOnInit(): void {}
}
