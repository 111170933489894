/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, LOCALE_ID, NgZone } from "@angular/core";
import { UntypedFormArray, ValidatorFn, Validators } from "@angular/forms";
import * as _ from "lodash";
import { SelectItem } from "primeng/api";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { ArrayDatatypeOptions, Col, DataType, FilterType } from "../../../form-list/form-list/form-list";
import { FormListModel } from "../../../form-list/form-list/form-list-model.interface";
import { AppSettingsStorageService } from "../../../shared/app-settings-storage.service";
import { CurrencySymbolPipe } from "../../../shared/pipes/currency-symbol.pipe";
import { AuthService } from "../../../shared/services/auth.service";
import { CurrencyFormatterService } from "../../../shared/services/currency-formatter.service";
import { DBService, ExtendedSelectItem } from "../../../shared/services/db.service";
import { CommonValidators } from "../../../shared/validators/CommonValidators";
import { GoogleContentAPIService } from "../../settings/integration-settings/google-shopping/GoogleContentAPIService";
import { Zone } from "../../settings/zone-settings/Zone";
import { InventoryBarcodeService } from "../inventory-barcode/InventoryBarcode.service";
import { InventoryBarcodesArrayComponent } from "../inventory-barcodes-array/inventory-barcodes-array.component";
import {
  Cost_Basis,
  GoogleShoppingAgeGroup,
  GoogleShoppingCondition,
  GoogleShoppingGender,
  GoogleShoppingSize,
  GoogleShoppingSizeType,
  Inventory,
  InventoryStock,
  Non_physical_stock_type,
  Stock_type,
  GoogleShoppingTitleTemplate,
  Inventory_Variant_Type,
  Product_Type,
} from "./inventory";
import { InventoryStockLevelsArrayComponent } from "../inventory-stocklevels-array/inventory-stocklevels-array.component";
import { InventoryStockService } from "../inventory-stock/InventoryStock.service";
import { BackendGMCService } from "../../settings/integration-settings/google-shopping/BackendGMCService";
import { Stock } from "../../settings/business-settings/stock/stock";
import { SaleDocType } from "../../document/doc/doc";
import { PackSize } from "../inventory-supplier-product-detail/inventory-supplier-product-detail";

export type InventoryStats = {
  docType: SaleDocType;
  currencyIsoCode: string;
  TotalSales: number;
  TotalQtySales: number;
  InvoiceCount: number;
  LastTransaction: Date;
  avgQtySale: number;
  LastSalePrice: number;
};

@Injectable({
  providedIn: "root",
})
export class InventoryService extends DBService implements FormListModel {
  protected lookup_uOfMeasure: any[];
  protected lookup_categories: any[];
  protected lookup_defaultReportCategories: any[];
  protected lookup_tax_inventory_category: any[];
  protected currencySymbolPipe: CurrencySymbolPipe;

  // getInventoryDetails: EventEmitter<Inventory> = new EventEmitter();

  // Google Shooping lookups and enums
  enum_googleshoppigCondition = this.enumSelectOptions(GoogleShoppingCondition);
  enum_googleshoppingAgeGroup: SelectItem[] = [
    { label: "", value: null },
    { label: "Newborn (Up to 3 months old)", value: GoogleShoppingAgeGroup.NewBorn },
    { label: "Infant (3-12 months old)", value: GoogleShoppingAgeGroup.Infant },
    { label: "Toddler (1-5 years old)", value: GoogleShoppingAgeGroup.Toddler },
    { label: "Kids (5-13 years old)", value: GoogleShoppingAgeGroup.Kids },
    { label: "Adult (Typically teens or older)", value: GoogleShoppingAgeGroup.Adult },
  ];
  enum_googleshoppingGender = this.enumSelectOptions(GoogleShoppingGender);
  enum_googleshoppingSizeType = this.enumSelectOptions(GoogleShoppingSizeType);
  enum_googleshoppingSizeSystem = this.enumSelectOptions(GoogleShoppingSize);
  // TODO: For now just show Basic and Apparel as Title Templates, but this should be undo later
  enum_googleshoppingTitleTemplate = this.enumSelectOptions(GoogleShoppingTitleTemplate).filter((item) =>
    [GoogleShoppingTitleTemplate.Basic, GoogleShoppingTitleTemplate.Apparel].includes(item.value)
  );
  // END of Google Shopping

  lookup_brands: any;
  // Define enum variables
  enumCurrencies = this.currencyService.getCurrencyCodeList();
  _model = "inventory";

  totalQtyOH(currentStock: InventoryStock, allStocks: InventoryStock[]) {
    return _.sumBy(allStocks, "qtyOH");
  }

  override getRows(
    _model: string,
    _filter?: string,
    _offset?: number,
    _limit?: number,
    _sortField?: string,
    _sortOrder?: number,
    extraParams = {},
    _orFilter?
  ): Observable<any> {
    if (_model !== "inventory") {
      // Only inventory entity with long complex queries needs to use POST body
      return super.getRows(_model, _filter, _offset, _limit, _sortField, _sortOrder, extraParams, _orFilter);
    }
    _filter = _filter ? JSON.parse(_filter) : null;
    const params = this._buildRequestParams(_filter, _orFilter, _offset, _limit, _sortField, _sortOrder);
    // Add extra params if not present in regular params
    _.defaults(extraParams, {
      includes: "inventorySupplier.account.commercialAccount",
    });

    _.defaults(params, extraParams);

    // For Inventory, filters should go into the body and use POST method
    return this._postRequest<any[]>(this.webUrl + "model-list/" + _model, {}, params, { filter: "filter" }).pipe(
      tap((response: any) => {
        if (response.rows && response.rows.length) response.rows.forEach((object) => this.afterFetch(object, _model));
      })
    );
  }

  afterFetch(inventory: Inventory, model: string) {
    switch (model) {
      case this._model:
        if (inventory.standardPriceCurrencyIsoCode)
          inventory.standardPrice = this.currencyService.formatMonetaryAmount({
            currencyIsoCode: inventory.standardPriceCurrencyIsoCode,
            amount: inventory.standardPrice,
          });
        if (inventory.msrpPriceCurrencyIsoCode)
          inventory.msrpPrice = this.currencyService.formatMonetaryAmount({
            currencyIsoCode: inventory.msrpPriceCurrencyIsoCode,
            amount: inventory.msrpPrice,
          });

        // format default invetory zone fields
        let defaultZone;
        if ((defaultZone = (<any>inventory).defaultInventoryZone)) {
          if (defaultZone.standardCostCurrencyIsoCode)
            defaultZone.standardCost = this.currencyService.formatMonetaryAmount({
              currencyIsoCode: defaultZone.standardCostCurrencyIsoCode,
              amount: defaultZone.standardCost,
            });

          if (defaultZone.landedCostCurrencyIsoCode)
            defaultZone.landedCost = this.currencyService.formatMonetaryAmount({
              currencyIsoCode: defaultZone.landedCostCurrencyIsoCode,
              amount: defaultZone.landedCost,
            });

          if (defaultZone.customDutyValueCurrencyIsoCode)
            defaultZone.customDutyValue = this.currencyService.formatMonetaryAmount({
              currencyIsoCode: defaultZone.customDutyValueCurrencyIsoCode,
              amount: defaultZone.customDutyValue,
            });
        }

        // Format inventory zones monetary fields
        inventory.inventoryZones.forEach((inventoryZone) => {
          if (inventoryZone.standardCostCurrencyIsoCode)
            inventoryZone.standardCost = this.currencyService.formatMonetaryAmount({
              currencyIsoCode: inventoryZone.standardCostCurrencyIsoCode,
              amount: inventoryZone.standardCost,
            });

          if (inventoryZone.landedCostCurrencyIsoCode)
            inventoryZone.landedCost = this.currencyService.formatMonetaryAmount({
              currencyIsoCode: inventoryZone.landedCostCurrencyIsoCode,
              amount: inventoryZone.landedCost,
            });

          if (inventoryZone.customDutyValueCurrencyIsoCode)
            inventoryZone.customDutyValue = this.currencyService.formatMonetaryAmount({
              currencyIsoCode: inventoryZone.customDutyValueCurrencyIsoCode,
              amount: inventoryZone.customDutyValue,
            });
        });

        //We only want to display the inventory stocks for the current zone of the business.
        const zoneId = this.appSettings.getZoneId(); //Get the current zone of the business
        //Filter the stocks to get only the stock that have the current zoneId.
        // if (this.appSettingsStorageService.getStore().id) {
        //   inventory.inventoryStocks = inventory.inventoryStocks.filter(stock => stock.stock.zoneId === zoneId);
        // }

        break;
    }
  }

  get_enum_product_type(): SelectItem[] {
    return this.enumSelectOptions(Product_Type);
  }
  get_enum_stock_type(): SelectItem[] {
    return this.enumSelectOptions(Stock_type);
  }
  get_enum_inventory_variant_type(): SelectItem[] {
    return this.enumSelectOptions(Inventory_Variant_Type);
  }
  get_enum_nonphysical_stock_type(): SelectItem[] {
    return this.enumSelectOptions(Non_physical_stock_type);
  }
  get_enum_cost_basis(): SelectItem[] {
    return this.enumSelectOptions(Cost_Basis).map((item) => {
      if ([Cost_Basis.FIFO, Cost_Basis.LIFO, Cost_Basis.average].includes(item.value)) {
        item.disabled = true;
        item.icon = "pi pi-lock";
      }

      return item;
    });
  }

  get_lookup_brands(): Observable<SelectItem[]> {
    return this.lookupSelectOptions("brand", "name", { lookupFilter: "", enableFieldName: "isEnabled" });
  }

  get_lookup_uOfMeasure(): Observable<SelectItem[]> {
    return this.lookupSelectOptions("uOfMeasure", "name", { enableFieldName: "isEnabled" });
  }

  get_lookup_categories(): Observable<SelectItem[]> {
    return this.lookupMultiSelectOptions("category", "categoryName", { enableFieldName: "isEnabled" }).pipe(
      map((data: SelectItem[]) => {
        this.lookup_categories = data;
        return data;
      })
    );
  }

  get_lookup_defaultReportCategories(): Observable<SelectItem[]> {
    return this.lookupSelectOptions("category", "categoryName", { lookupFilter: "", enableFieldName: "isEnabled" });
  }

  get_lookup_tax_inventory_category(): Observable<SelectItem[]> {
    return this.lookupSelectOptions("taxInventoryCategory", "category", { enableFieldName: "isEnabled" }).pipe(
      map((data: SelectItem[]) => {
        this.lookup_tax_inventory_category = data;
        return data;
      })
    );
  }

  lookup_shelf(): Observable<ExtendedSelectItem[]> {
    return this.lookupSelectOptions("shelf", "name");
  }

  lookup_aisle(): Observable<ExtendedSelectItem[]> {
    return this.lookupSelectOptions("aisle", "name");
  }

  getActiveZoneStocks(): Observable<Stock[]> {
    return this.stockService.getActiveZoneStocks();
  }

  getLookup_activeZoneStocks(): Observable<SelectItem[]> {
    return this.getActiveZoneStocks().pipe(
      map((stocks: Stock[]) =>
        [{ label: "", value: null }].concat(
          stocks.map((stock) => {
            return {
              label: stock.stockName,
              value: stock.id,
            };
          })
        )
      )
    );
  }

  getLookup_zones(): Observable<SelectItem[]> {
    return this.getZones().pipe(
      map((zones: Zone[]) =>
        [{ label: "", value: null }].concat(
          zones.map((zone) => {
            return {
              label: zone.zoneName,
              value: zone.id,
            };
          })
        )
      )
    );
  }

  getZones(): Observable<Zone[]> {
    return this.getRows("zone", null, 0, 1000).pipe(
      map((data: any) => {
        return (<Zone[]>data.rows).sort((s1, s2) => s1.id - s2.id);
      })
    );
  }

  getStatistics(inventoryId): Observable<InventoryStats[]> {
    const endpoint = this.webUrl + "inventoryStatics/" + inventoryId;
    return this._getRequest<InventoryStats[]>(endpoint);
  }

  populateStocksFormArray(formArray: UntypedFormArray, stockIsVisibleCtrl: string) {
    this.stockService.populateFormArray(formArray, stockIsVisibleCtrl);
  }

  constructor(
    protected currencyService: CurrencyFormatterService,
    protected http: HttpClient,
    protected googleContentAPIService: GoogleContentAPIService,
    @Inject(LOCALE_ID) protected defaultLocale,
    protected authService: AuthService,
    protected appSettings: AppSettingsStorageService,
    protected zone: NgZone,
    public backendGMCService: BackendGMCService,
    public barcodeService: InventoryBarcodeService,
    public stockService: InventoryStockService,
    protected appSettingsStorageService: AppSettingsStorageService
  ) {
    super(http, authService);

    this.currencySymbolPipe = new CurrencySymbolPipe(this.defaultLocale);
  }

  getValidationRules(): { [key: string]: ValidatorFn[] | any } {
    return {
      // sku: [Validators.required],
      description1: [Validators.required],
      standardPrice: [Validators.required, CommonValidators.digits()],
      msrpPrice: [CommonValidators.digits()],
      standardMinPrice: [Validators.required, CommonValidators.digits()],
      standardMaxPrice: [Validators.required, CommonValidators.digits()],
      allowDiscount: [CommonValidators.boolean_value()],
      maxDiscountPercentage: [Validators.required, CommonValidators.digits()],
      maxDiscountAmount: [Validators.required, CommonValidators.digits()],
      isSalePriceEditable: [CommonValidators.boolean_value()],
      isReturnPriceEditable: [CommonValidators.boolean_value()],
      length: [CommonValidators.digits()],
      width: [CommonValidators.digits()],
      height: [CommonValidators.digits()],
      weight: [CommonValidators.digits()],
      CBM: [CommonValidators.digits()],
      CUFT: [CommonValidators.digits()],
      inventoryStocks: this.stockService.getValidationRules(),
      inventoryZones: {
        landedCost: [CommonValidators.digits()],
        standardCost: [CommonValidators.digits()],
        customDutyValue: [CommonValidators.digits()],
      },
      uOfMeasureId: [CommonValidators.lookup_singleSelect()],
      stockType: [CommonValidators.enum_singleSelect(this.get_enum_stock_type().map((item) => item.value))],
      standardPriceCurrencyIsoCode: [CommonValidators.enum_singleSelect(this.enumCurrencies.map((item) => item.value))],
      msrpPriceCurrencyIsoCode: [CommonValidators.enum_singleSelect(this.enumCurrencies.map((item) => item.value))],
      isSellable: [CommonValidators.boolean_value()],
      isBuyable: [CommonValidators.boolean_value()],
      isTrackQTY: [CommonValidators.boolean_value()],
      isBatch: [CommonValidators.boolean_value()],
      isSerial: [CommonValidators.boolean_value()],
      isKits: [CommonValidators.boolean_value()],
      categories: [CommonValidators.lookup_multiSelect()],
      brandId: [CommonValidators.lookup_singleSelect()],
      defaultReportCategoryId: [CommonValidators.lookup_singleSelect()],
      taxInventoryCategoryId: [CommonValidators.lookup_singleSelect()],
      lengthUOfMeasureId: [CommonValidators.lookup_singleSelect()],
      massUOfMeasureId: [CommonValidators.lookup_singleSelect()],
      longDescription: [Validators.maxLength(9600)],
      policy: [Validators.maxLength(2400)],
      inventoryGoogleShopping: {
        productLink: [CommonValidators.websiteURL()],
        imageLink: [CommonValidators.websiteURL()],
        adult: [CommonValidators.boolean_value()],
        ageGroup: [CommonValidators.enum_singleSelect(this.enum_googleshoppingAgeGroup.map((item) => item.value))],
        gender: [CommonValidators.enum_singleSelect(this.enum_googleshoppingGender.map((item) => item.value))],
        isBuldle: [CommonValidators.boolean_value()],
        condition: [CommonValidators.enum_singleSelect(this.enum_googleshoppigCondition.map((item) => item.value))],
        sizeSystem: [CommonValidators.enum_singleSelect(this.enum_googleshoppingSizeSystem.map((item) => item.value))],
        sizeType: [CommonValidators.enum_singleSelect(this.enum_googleshoppingSizeType.map((item) => item.value))],
      },
      inventoryVariantSettings: {
        inventoryOptionsSettingId: [Validators.required, CommonValidators.lookup_singleSelect()],
        inventoryOptionsSettingValues: [Validators.required, CommonValidators.lookup_multiSelect()],
      },
    };
  }

  standardCostCurrency(rowData): string {
    return this._currencyPreffix(rowData.defaultInventoryZone?.standardCostCurrencyIsoCode);
  }

  inventoryVariantType(rowData): string {
    if (rowData.parentInventoryId) {
      return `<i class="material-icons w-2rem" >photo_size_select_small</i>`;
    } else if (rowData.isVariantParent) {
      return `<i class="material-icons w-2rem" >bookmarks</i>`;
    } else {
      return `<i class="material-icons w-2rem" >bookmark_border</i>`;
    }
  }

  standardPriceCurrency(rowData): string {
    return this._currencyPreffix(rowData.standardPriceCurrencyIsoCode);
  }

  msrpPriceCurrency(rowData): string {
    return this._currencyPreffix(rowData.msrpPriceCurrencyIsoCode);
  }

  landedCostCurrency(rowData): string {
    return this._currencyPreffix(rowData.defaultInventoryZone.landedCostCurrencyIsoCode);
  }

  customDutyCurrency(rowData): string {
    return this._currencyPreffix(rowData.defaultInventoryZone.customDutyValueCurrencyIsoCode);
  }

  supplierCostCurrencyIsoCode(rowData): string {
    const detail = rowData.inventorySuppliers[0].inventorySupplierProductDetails.find((row) => {
      return row.packSize === PackSize.SKU;
    });
    return detail ? detail.supplierCostCurrencyIsoCode : undefined;
  }

  private _currencyPreffix(currencyIsoCode) {
    if (!currencyIsoCode) return "";

    return this.currencySymbolPipe.transform(currencyIsoCode, "code") + " ";
  }

  minStock(currentStock: InventoryStock, allStocks: InventoryStock[]): number | string {
    const stockId = this.appSettings.getStore()?.stockId;
    if (stockId) {
      const stock = allStocks.find((row) => row.stockId === stockId);
      if (stock) {
        return stock.minStock;
      }
    }

    return "View";
  }

  maxStock(currentStock: InventoryStock, allStocks: InventoryStock[]): number | string {
    const stockId = this.appSettings.getStore()?.stockId;
    if (stockId) {
      const stock = allStocks.find((row) => row.stockId === stockId);
      if (stock) {
        return stock.maxStock;
      }
    }

    return "View";
  }

  // getSKUCaption(rowData: Inventory) {
  //   return rowData ? rowData.sku : 'No SKU';
  // }

  getFormListColumns(isShowFullSuppilerList = false, stockId = this.appSettings.getStore()?.stockId): Col[] {
    const activeVisible: boolean = this.authService.hasVisiblePermit("Inventory_Menu_Inventory_List_Active_Inactive");
    const activeEnabled: boolean = this.authService.hasEnablePermit("Inventory_Menu_Inventory_List_Active_Inactive");

    const priceVisible: boolean = this.authService.hasVisiblePermit("Inventory_Menu_Inventory_List_Sell_Price");
    const priceEnabled: boolean = this.authService.hasEnablePermit("Inventory_Menu_Inventory_List_Sell_Price");

    const currencyVisible: boolean = this.authService.hasVisiblePermit("Inventory_Menu_Inventory_List_Sell_Price");
    const currencyEnabled: boolean = this.authService.hasEnablePermit("Inventory_Menu_Inventory_List_Sell_Price");

    const priceOptionsVisible: boolean = this.authService.hasVisiblePermit(
      "Inventory_Menu_Inventory_List_Price_Options"
    );
    const priceOptionsEnabled: boolean = this.authService.hasEnablePermit(
      "Inventory_Menu_Inventory_List_Price_Options"
    );

    const altPriceVisible: boolean = this.authService.hasVisiblePermit("Inventory_Menu_Inventory_List_Alt_Price");
    const altPriceEnabled: boolean = this.authService.hasEnablePermit("Inventory_Menu_Inventory_List_Alt_Price");

    const altCurrencyVisible: boolean = this.authService.hasVisiblePermit("Inventory_Menu_Inventory_List_Alt_Price");
    const altCurrencyEnabled: boolean = this.authService.hasEnablePermit("Inventory_Menu_Inventory_List_Alt_Price");

    const barcodesVisible: boolean = this.authService.hasVisiblePermit("Inventory_Menu_Inventory_List_Barcodes");
    const barcodesEnabled: boolean = this.authService.hasEnablePermit("Inventory_Menu_Inventory_List_Barcodes");

    const typeVisible: boolean = this.authService.hasVisiblePermit("Inventory_Menu_Inventory_List_Type");
    const typeEnabled: boolean = this.authService.hasEnablePermit("Inventory_Menu_Inventory_List_Type");

    const categoryVisible: boolean = this.authService.hasVisiblePermit("Inventory_Menu_Inventory_List_Classification");
    const categoryEnabled: boolean = this.authService.hasEnablePermit("Inventory_Menu_Inventory_List_Classification");

    const brandVisible: boolean = this.authService.hasVisiblePermit("Inventory_Menu_Inventory_List_Classification");
    const brandEnabled: boolean = this.authService.hasEnablePermit("Inventory_Menu_Inventory_List_Classification");

    const defaultReportCategoryVisible: boolean = this.authService.hasVisiblePermit(
      "Inventory_Menu_Inventory_List_Classification"
    );
    const defaultReportCategoryEnabled: boolean = this.authService.hasEnablePermit(
      "Inventory_Menu_Inventory_List_Classification"
    );

    const taxCategoryVisible: boolean = this.authService.hasVisiblePermit(
      "Inventory_Menu_Inventory_List_Classification"
    );
    const taxCategoryEnabled: boolean = this.authService.hasEnablePermit(
      "Inventory_Menu_Inventory_List_Classification"
    );

    const defaultAvailQtyVisible: boolean = this.authService.hasVisiblePermit(
      "Inventory_Menu_Inventory_List_Stock_Tab_Stock_Level"
    );
    const defaultAavilQtyEnabled: boolean = this.authService.hasEnablePermit(
      "Inventory_Menu_Inventory_List_Stock_Tab_Stock_Level"
    );

    const totalAvailQtyVisible: boolean = this.authService.hasVisiblePermit(
      "Inventory_Menu_Inventory_List_Stock_Tab_Stock_Level"
    );
    const totalAvailQtyEnabled: boolean = this.authService.hasEnablePermit(
      "Inventory_Menu_Inventory_List_Stock_Tab_Stock_Level"
    );

    const altProductTitleVisible: boolean = this.authService.hasVisiblePermit(
      "Inventory_Menu_Inventory_List_Details_Tab"
    );
    const altProductTitleEnabled: boolean = this.authService.hasEnablePermit(
      "Inventory_Menu_Inventory_List_Details_Tab"
    );

    const defaultMinQtyVisible: boolean = this.authService.hasVisiblePermit(
      "Inventory_Menu_Inventory_List_Stock_Tab_Stock_Level"
    );
    const defaultMinQtyEnabled: boolean = this.authService.hasEnablePermit(
      "Inventory_Menu_Inventory_List_Stock_Tab_Stock_Level"
    );

    const defaultOhQtyVisible: boolean = this.authService.hasVisiblePermit(
      "Inventory_Menu_Inventory_List_Stock_Tab_Stock_Level"
    );
    const defaultOhQtyEnabled: boolean = this.authService.hasEnablePermit(
      "Inventory_Menu_Inventory_List_Stock_Tab_Stock_Level"
    );

    const minStockQtyVisible: boolean = this.authService.hasVisiblePermit(
      "Inventory_Menu_Inventory_List_Stock_Tab_Stock_Level"
    );
    const minStockQtyEnabled: boolean = this.authService.hasEnablePermit(
      "Inventory_Menu_Inventory_List_Stock_Tab_Stock_Level"
    );

    const maxStockQtyVisible: boolean = this.authService.hasVisiblePermit(
      "Inventory_Menu_Inventory_List_Stock_Tab_Stock_Level"
    );
    const maxStockQtyEnabled: boolean = this.authService.hasEnablePermit(
      "Inventory_Menu_Inventory_List_Stock_Tab_Stock_Level"
    );

    const totalOhQtyVisible: boolean = this.authService.hasVisiblePermit(
      "Inventory_Menu_Inventory_List_Stock_Tab_Stock_Level"
    );
    const totalOhQtyEnabled: boolean = this.authService.hasEnablePermit(
      "Inventory_Menu_Inventory_List_Stock_Tab_Stock_Level"
    );

    const defaultStandardCostVisible: boolean = this.authService.hasVisiblePermit(
      "Inventory_Menu_Inventory_List_Stock_Tab_Costing"
    );
    const defaultStandardCostEnabled: boolean = this.authService.hasEnablePermit(
      "Inventory_Menu_Inventory_List_Stock_Tab_Costing"
    );

    const supplierCostVisible: boolean = this.authService.hasVisiblePermit(
      "Inventory_Menu_Inventory_List_Stock_Tab_Costing"
    );
    const supplierCostEnabled: boolean = this.authService.hasEnablePermit(
      "Inventory_Menu_Inventory_List_Stock_Tab_Costing"
    );

    const costBasisVisible: boolean = this.authService.hasVisiblePermit(
      "Inventory_Menu_Inventory_List_Stock_Tab_Costing"
    );
    const costbasisEnabled: boolean = this.authService.hasEnablePermit(
      "Inventory_Menu_Inventory_List_Stock_Tab_Costing"
    );

    const isBarcodesVisible = !isShowFullSuppilerList;
    const isSupplierVisible = !isShowFullSuppilerList;

    return [
      ...(activeVisible
        ? [
            {
              field: "isEnabled",
              header: "Active",
              importingOptions: { header: "Is Enabled" },
              visible: true,
              readonly: !activeEnabled,
              frozen: true,
              dataType: DataType.checkbox,
              dataOptions: Col.boolean_filter_enum,
              filterType: FilterType.enum,
              colWidth: 110,
            },
          ]
        : []), // Have to be changed to toggle
      // { field: 'isSellable', header: 'Sellable', importingOptions: { header: "Is Sellable" }, visible: true, readonly: false, frozen: false, dataType: DataType.toggle, dataOptions: Col.boolean_filter_enum, filterType: FilterType.enum, colWidth: 110 }, // Have to be changed to toggle
      {
        field: "parentInventoryId",
        header: "Product Type",
        visible: true,
        readonly: true,
        frozen: true,
        dataType: DataType.input,
        dataTypeOptions: { prefix: this.inventoryVariantType.bind(this) },
        dataOptions: [{ label: "", value: "removeAll" }, ...this.get_enum_product_type()],
        filterType: FilterType.enum,
        filterField: "parentInventoryId",
        colWidth: 135,
        isNotSortable: true,
      },
      {
        field: "sku",
        header: "SKU",
        visible: true,
        readonly: false,
        frozen: true,
        dataType: DataType.input,
        dataOptions: [],
        // dataTypeOptions: [new ExecuteEventDatatypeOptions({
        //   displayMode: ExecuteEvent_DisplayMode.LINK,
        //   label: this.getSKUCaption,
        //   event: this.getInventoryDetails,
        //   enabledOnEditMode: false,
        //   styleClass: '',
        // })],
        filterType: FilterType.contains,
        colWidth: 175,
      },
      {
        field: "inventorySuppliers[0].account.accountName",
        filterField: "inventorySuppliers.account.commercialAccount.name",
        header: "Supplier",
        visible: isSupplierVisible,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 130,
        isNotSortable: true,
      },
      {
        field: "supplierCode",
        header: "Supplier Code",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 140,
        filterField: "inventorySuppliers.inventorySupplierProductDetails.supplierCode",
        isNotSortable: true,
      },
      {
        field: "inventoryVariantType",
        header: "Product Type",
        visible: false,
        readonly: true,
        frozen: false,
        dataType: DataType.enum,
        dataOptions: this.get_enum_inventory_variant_type(),
        filterType: FilterType.none,
        colWidth: 150,
        isNotSortable: true,
      },
      {
        field: "description1",
        header: "Product Title",
        importingOptions: { header: "Description" },
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 175,
      },
      {
        field: "inventoryVariantsStr",
        header: "Variant",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        dataTypeOptions: DataType.input,
        filterType: FilterType.contains,
        colWidth: 140,
        isNotSortable: true,
        filterField: "inventoryVariants.inventoryOptionsSettingValue.optionValue",
      },
      {
        field: "uOfMeasureId",
        fieldValue: "uOfMeasure.name",
        header: "U of M",
        importingOptions: { header: "Unit of Measure" },
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.lookup,
        dataOptions: this.get_lookup_uOfMeasure(),
        filterType: FilterType.lookup,
        colWidth: 130,
        sortFields: ["uOfMeasure.name"],
      },
      ...(barcodesVisible
        ? [
            {
              field: "inventoryBarcodes",
              header: "Barcodes",
              visible: isBarcodesVisible,
              readonly: true,
              frozen: false,
              dataType: DataType.array,
              dataOptions: [],
              dataTypeOptions: new ArrayDatatypeOptions({
                component: InventoryBarcodesArrayComponent,
                displayField: "barcode",
                showSummary: true,
              }),
              filterType: FilterType.contains,
              filterField: "inventoryBarcodes.barcode",
              sortFields: ["inventoryBarcodes.barcode"],
              colWidth: 250,
              isNotSortable: true,
            },
          ]
        : []),
      ...(currencyVisible
        ? [
            {
              field: "standardPriceCurrencyIsoCode",
              header: "Price (Currency)",
              importingOptions: { header: "SELL PRICE Currency", colWidth: 145 },
              visible: false,
              readonly: !currencyEnabled,
              frozen: false,
              dataType: DataType.enum,
              dataOptions: this.enumCurrencies,
              filterType: FilterType.enum,
              colWidth: 135,
            },
          ]
        : []),
      supplierCostVisible && {
        field: "supplierCost",
        header: "Supplier Cost",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        dataTypeOptions: { prefix: this.supplierCostCurrencyIsoCode.bind(this) },
        filterType: FilterType.none,
        colWidth: 160,
      },
      ...(priceVisible
        ? [
            {
              field: "standardPrice",
              header: "Price",
              importingOptions: { header: "SELL PRICE Amount", colWidth: 145 },
              visible: true,
              readonly: !priceEnabled,
              frozen: false,
              dataType: DataType.number,
              dataTypeOptions: { prefix: this.standardPriceCurrency.bind(this) },
              dataOptions: [],
              filterType: FilterType.contains,
              colWidth: 135,
            },
          ]
        : []),
      ...(altCurrencyVisible
        ? [
            {
              field: "msrpPriceCurrencyIsoCode",
              header: "Alt Price (Currency)",
              importingOptions: { header: "Alt PRICE Currency", colWidth: 145 },
              visible: false,
              readonly: !altCurrencyEnabled,
              frozen: false,
              dataType: DataType.enum,
              dataOptions: this.enumCurrencies,
              filterType: FilterType.enum,
              colWidth: 135,
            },
          ]
        : []),
      ...(altPriceVisible
        ? [
            {
              field: "msrpPrice",
              header: "Alt Price",
              importingOptions: { header: "Alt PRICE Amount", colWidth: 145 },
              visible: true,
              readonly: !altPriceEnabled,
              frozen: false,
              dataType: DataType.number,
              dataTypeOptions: { prefix: this.msrpPriceCurrency.bind(this) },
              dataOptions: [],
              filterType: FilterType.contains,
              colWidth: 135,
            },
          ]
        : []),

      ...(priceOptionsVisible
        ? [
            {
              field: "isSalePriceEditable",
              header: "Edit Sale Price",
              importingOptions: { header: "Edit Sale Price", colWidth: 145 },
              visible: false,
              readonly: !priceOptionsEnabled,
              frozen: false,
              dataType: DataType.checkbox,
              dataOptions: [],
              filterType: FilterType.enum,
              colWidth: 135,
            },
          ]
        : []),
      // ...priceOptionsVisible ? [{ field: 'standardMinPrice', header: 'Min Price', importingOptions: { header: 'Min PRICE Amount', colWidth: 145 }, visible: false, readonly: !priceOptionsEnabled, frozen: false, dataType: DataType.number, dataTypeOptions: { prefix: this.standardPriceCurrency.bind(this) }, dataOptions: [], filterType: FilterType.contains, colWidth: 135 }] : [],
      // ...priceOptionsVisible ? [{ field: 'standardMaxPrice', header: 'Max Price', importingOptions: { header: 'Max PRICE Amount', colWidth: 145 }, visible: false, readonly: !priceOptionsEnabled, frozen: false, dataType: DataType.number, dataTypeOptions: { prefix: this.standardPriceCurrency.bind(this) }, dataOptions: [], filterType: FilterType.contains, colWidth: 135 }] : [],
      ...(priceOptionsVisible
        ? [
            {
              field: "isReturnPriceEditable",
              header: "Edit Return Price",
              importingOptions: { header: "Edit Return Price", colWidth: 145 },
              visible: false,
              readonly: !priceOptionsEnabled,
              frozen: false,
              dataType: DataType.checkbox,
              dataOptions: [],
              filterType: FilterType.enum,
              colWidth: 135,
            },
          ]
        : []),
      ...(priceOptionsVisible
        ? [
            {
              field: "allowDiscount",
              header: "Limit Line Discount",
              importingOptions: { header: "Allow Discount", colWidth: 145 },
              visible: false,
              readonly: !priceOptionsEnabled,
              frozen: false,
              dataType: DataType.checkbox,
              dataOptions: [],
              filterType: FilterType.enum,
              colWidth: 135,
            },
          ]
        : []),

      ...(defaultAvailQtyVisible && stockId
        ? [
            {
              field: "defaultQtyAvail",
              header: "Default Avail Qty",
              headerHTML: "Default<br>Avail Qty",
              visible: true,
              readonly: true,
              frozen: false,
              dataType: DataType.number,
              dataOptions: [],
              filterType: FilterType.none,
              colWidth: 145,
              isNotSortable: true,
            },
          ]
        : []),

      // { field: 'msrpPriceCurrencyIsoCode', header: 'Alt Price (Currency)', importingOptions: { header: 'Alt PRICE Currency', colWidth: 145 }, visible: false, readonly: false, frozen: false, dataType: DataType.enum, dataOptions: this.enum_currencies, filterType: FilterType.enum, colWidth: 135 },
      // { field: 'msrpPrice', header: 'Alt Price', importingOptions: { header: 'Alt PRICE Amount', colWidth: 145 }, visible: false, readonly: false, frozen: false, dataType: DataType.number, dataTypeOptions: { prefix: this.msrpPriceCurrency.bind(this) }, dataOptions: [], filterType: FilterType.contains, colWidth: 135 },

      ...(defaultOhQtyVisible && stockId
        ? [
            {
              field: "defaultQtyOH",
              header: "Default OH Qty",
              headerHTML: "Default<br>OH Qty",
              visible: true,
              readonly: true,
              frozen: false,
              dataType: DataType.number,
              dataOptions: [],
              filterType: FilterType.none,
              colWidth: 145,
              isNotSortable: true,
            },
          ]
        : []),
      minStockQtyVisible && {
        field: "inventoryStocksMin",
        header: "Min Stock Qty",
        headerHTML: "Min<br>Stock Qty",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.array,
        dataTypeOptions: new ArrayDatatypeOptions({
          component: InventoryStockLevelsArrayComponent,
          displayField: this.minStock.bind(this),
          showSummary: false,
        }),
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 145,
        sortFields: ["inventoryStocksMin.minStock"],
        isNotSortable: true,
      },
      maxStockQtyVisible && {
        field: "inventoryStocksMax",
        header: "Max Stock Qty",
        headerHTML: "Max<br>Stock Qty",
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.array,
        dataTypeOptions: new ArrayDatatypeOptions({
          component: InventoryStockLevelsArrayComponent,
          displayField: this.maxStock.bind(this),
          showSummary: false,
        }),
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 145,
        sortFields: ["inventoryStocksMax.maxStock"],
        isNotSortable: true,
      },
      ...(totalOhQtyVisible
        ? [
            {
              field: "inventoryStocks",
              header: "Total OH Qty",
              headerHTML: "Total<br>OH Qty",
              visible: true,
              readonly: true,
              frozen: false,
              dataType: DataType.array,
              dataTypeOptions: new ArrayDatatypeOptions({
                component: InventoryStockLevelsArrayComponent,
                displayField: this.totalQtyOH.bind(this),
                showSummary: false,
              }),
              dataOptions: [],
              filterType: FilterType.none,
              colWidth: 145,
              sortFields: ["inventoryStocks.qtyOH"],
              isNotSortable: true,
            },
          ]
        : []),
      ...(typeVisible
        ? [
            {
              field: "stockType",
              header: "Stock Type",
              importingOptions: { header: "STOCK TYPE Type" },
              visible: false,
              readonly: !typeEnabled,
              frozen: false,
              dataType: DataType.enum,
              dataOptions: this.get_enum_stock_type(),
              filterType: FilterType.enum,
            },
          ]
        : []),
      ...(categoryVisible
        ? [
            {
              field: "categories",
              fieldValue: "categoriesList",
              header: "Category",
              importingOptions: { header: "Inventory Categories" },
              visible: true,
              readonly: !categoryEnabled,
              frozen: false,
              dataType: DataType.multiselect,
              dataOptions: this.get_lookup_categories(),
              filterType: FilterType.multiselect,
              isNotSortable: true,
            },
          ]
        : []),
      ...(brandVisible
        ? [
            {
              field: "brandId",
              fieldValue: "brand.name",
              header: "Brand",
              importingOptions: { header: "Brand" },
              visible: false,
              readonly: !brandEnabled,
              frozen: false,
              dataType: DataType.lookup,
              dataOptions: this.get_lookup_brands(),
              filterType: FilterType.lookup,
              sortFields: ["brand.name"],
            },
          ]
        : []),
      ...(taxCategoryVisible
        ? [
            {
              field: "taxInventoryCategoryId",
              fieldValue: "taxInventoryCategory.category",
              header: "Tax Category",
              importingOptions: { header: "Tax Category" },
              visible: false,
              readonly: !taxCategoryEnabled,
              frozen: false,
              dataType: DataType.lookup,
              dataOptions: this.get_lookup_tax_inventory_category(),
              filterType: FilterType.lookup,
              sortFields: ["taxInventoryCategory.category"],
            },
          ]
        : []),
      ...(defaultReportCategoryVisible
        ? [
            {
              field: "defaultReportCategoryId",
              fieldValue: "defaultReportCategory.categoryName",
              header: "Default Report Category",
              importingOptions: { header: "Default Report Category" },
              visible: false,
              readonly: !defaultReportCategoryEnabled,
              frozen: false,
              dataType: DataType.lookup,
              dataOptions: this.get_lookup_defaultReportCategories(),
              filterType: FilterType.lookup,
              sortFields: ["defaultReportCategory.categoryName"],
            },
          ]
        : []),
      ...(altProductTitleVisible
        ? [
            {
              field: "description2",
              header: "Alt Product Title",
              importingOptions: { header: "Alternative Description" },
              visible: false,
              readonly: !altProductTitleEnabled,
              frozen: false,
              dataType: DataType.input,
              dataOptions: [],
              filterType: FilterType.contains,
              colWidth: 250,
            },
          ]
        : []),
      {
        field: "lengthUOfMeasureId",
        fieldValue: "lengthUOfMeasure.name",
        header: "DIM (UofM)",
        importingOptions: { header: "DIMENSIONS Unit of Measure", colWidth: 165 },
        visible: false,
        readonly: false,
        frozen: false,
        dataType: DataType.lookup,
        dataOptions: this.get_lookup_uOfMeasure(),
        filterType: FilterType.lookup,
        colWidth: 130,
        sortFields: ["lengthUOfMeasure.name"],
      },
      {
        field: "length",
        header: "Length",
        importingOptions: { header: "DIMENSIONS Length", colWidth: 150 },
        visible: false,
        readonly: false,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 140,
      },
      {
        field: "width",
        header: "Width",
        importingOptions: { header: "DIMENSIONS Width", colWidth: 150 },
        visible: false,
        readonly: false,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 140,
      },
      {
        field: "height",
        header: "Height",
        importingOptions: { header: "DIMENSIONS Height", colWidth: 150 },
        visible: false,
        readonly: false,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 140,
      },
      {
        field: "massUOfMeasureId",
        fieldValue: "massUOfMeasure.name",
        header: "Weight (UofM)",
        importingOptions: { header: "WEIGHT Unit of Measure", colWidth: 150 },
        visible: false,
        readonly: false,
        frozen: false,
        dataType: DataType.lookup,
        dataOptions: this.get_lookup_uOfMeasure(),
        filterType: FilterType.lookup,
        colWidth: 130,
        sortFields: ["massUOfMeasure.name"],
      },
      {
        field: "weight",
        header: "Weight",
        importingOptions: { header: "WEIGHT Weight" },
        visible: false,
        readonly: false,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 140,
      },
      {
        field: "CBM",
        header: "Volume (CBM)",
        importingOptions: { header: "VOLUME CBM" },
        visible: false,
        readonly: false,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 140,
      },
      {
        field: "CUFT",
        header: "Volume (CUFT)",
        importingOptions: { header: "VOLUME CUFT" },
        visible: false,
        readonly: false,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 140,
      },

      ...(defaultMinQtyVisible
        ? [
            {
              field: "defaultInventoryStock.minStock",
              header: "Default Min Stock Qty",
              headerHTML: "Default<br>Min Stock Qty",
              visible: false,
              readonly: true,
              frozen: false,
              dataType: DataType.number,
              dataOptions: [],
              filterType: FilterType.none,
              colWidth: 145,
              isNotSortable: true,
            },
          ]
        : []),
      ...(defaultStandardCostVisible
        ? [
            {
              field: "defaultInventoryZone.standardCost",
              header: "Default Standard Cost",
              headerHTML: "Default<br>Standard Cost",
              visible: false,
              readonly: true,
              frozen: false,
              dataType: DataType.number,
              dataTypeOptions: { prefix: this.standardCostCurrency.bind(this) },
              dataOptions: [],
              filterType: FilterType.none,
              colWidth: 155,
              isNotSortable: true,
            },
          ]
        : []),
      {
        field: "defaultInventoryZone.landedCost",
        header: "Default Landed Cost",
        headerHTML: "Default<br>Landed Cost",
        selectorIcon: "pi pi-lock",
        disabled: true,
        visible: false,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataTypeOptions: { prefix: this.landedCostCurrency.bind(this) },
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 150,
        isNotSortable: true,
      },
      {
        field: "defaultInventoryZone.customDutyValue",
        header: "Default Custom Duty",
        headerHTML: "Default<br>Custom Duty",
        selectorIcon: "pi pi-lock",
        disabled: true,
        visible: false,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataTypeOptions: { prefix: this.customDutyCurrency.bind(this) },
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 150,
        isNotSortable: true,
      },
      ...(costBasisVisible
        ? [
            {
              field: "defaultInventoryZone.costBasis",
              header: "Default Cost Basis",
              headerHTML: "Default<br>Cost Basis",
              visible: false,
              readonly: true,
              frozen: false,
              dataType: DataType.enum,
              dataOptions: this.get_enum_cost_basis(),
              filterType: FilterType.none,
              colWidth: 150,
              isNotSortable: true,
            },
          ]
        : []),
      {
        field: "inventoryImagesCount",
        header: "Images Count",
        visible: false,
        readonly: true,
        frozen: false,
        dataType: DataType.number,
        dataOptions: [],
        filterType: FilterType.none,
        colWidth: 140,
        isNotSortable: true,
      },
      {
        field: "tags",
        header: `Tags`,
        visible: true,
        readonly: true,
        frozen: false,
        dataType: DataType.chips,
        dataOptions: [],
        filterType: FilterType.contains,
      },
      {
        field: "note",
        header: "Inventory Notes",
        importingOptions: { header: "Inventory Notes" },
        visible: false,
        readonly: false,
        frozen: false,
        dataType: DataType.input,
        dataOptions: [],
        filterType: FilterType.contains,
        colWidth: 175,
      },
      {
        field: "includedInGallery",
        header: "Show in Gallery",
        importingOptions: { header: "Included in Gallery" },
        visible: true,
        readonly: false,
        frozen: false,
        dataType: DataType.checkbox,
        dataOptions: Col.boolean_filter_enum,
        filterType: FilterType.enum,
        colWidth: 110,
      },
    ];
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
