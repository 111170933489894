/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

export class Category {
  id: number;
  categoryName: string;
  isEnabled: boolean;
  imageFileName: string;
  urlImageFileName: string;
  urlImageFileName40: string;
  urlImageFileName150: string;
  urlImageFileName475: string;
  includedInGallery: boolean;
  inventoryGalleryCount: number;

  constructor() {
    this.id = 0;
    this.categoryName = null;
    this.isEnabled = true;
    this.imageFileName = "";
    this.urlImageFileName = "";
    this.urlImageFileName40 = "";
    this.urlImageFileName150 = "";
    this.urlImageFileName475 = "";
    this.includedInGallery = false;
    this.inventoryGalleryCount = 0;
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
