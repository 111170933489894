/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
import { SaleDocType } from "src/app/core/document/doc/doc";

export class SaleTaxReport {
  id: number;
  docId: number;
  docType: SaleDocType;
  docClosingDate: string;
  totalAmountCalculated: number;

  taxAccountCategory: string;
  accountName: string;
  docNo: string;
  taxID: string;
  saleDocId: number;

  currencyIsoCode: string;
  subTotal: number | string;
  totalTaxAmount: number | string;
  shipperChargeAmount: number | string;
  discountAmount: number | string;

  constructor() {
    this.id = 0;
    this.docId = null;
    this.docType = null;
    this.docClosingDate = null;
    this.totalAmountCalculated = null;

    this.accountName = null;
    this.taxAccountCategory = null;
    this.docNo = null;
    this.taxID = null;
    this.currencyIsoCode = null;
    this.subTotal = null;
    this.totalTaxAmount = null;
    this.shipperChargeAmount = null;
    this.discountAmount = null;
    this.saleDocId = null;
  }

  get totalAmount() {
    return (
      parseFloat((this.subTotal || "0") + "") -
      parseFloat((this.discountAmount || "0") + "") +
      parseFloat((this.totalTaxAmount || "0") + "") +
      parseFloat((this.shipperChargeAmount || "0") + "")
    );
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
