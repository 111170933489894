<taku-form-header
  (backClicked)="goBack()"
  toolbarTitle="GOOGLE SHOPPING INTEGRATION"
  iconStyleClass="takuicon-google-integration"
  [showActionBtns]="false"
  displayMode="inline"
  [showBackBtn]="true"
></taku-form-header>

<p-panel>
  <ng-template pTemplate="header">
    <h1
      >My Google Stores <ng-template [ngIf]="googleAccount$ | async" let-account>({{ account.email }})</ng-template></h1
    >
  </ng-template>

  <div class="col-12">
    <taku-form-list
      _model="googleShoppingStore"
      _title="Google Merchant Stores"
      _detailMode="n"
      [_cols]="googleShoppingStoresCols"
      [_defaultRowValues]="defaultRowValues"
      [_hideAddLineHeaderBtn]="true"
      [_rowSelectionType]="RowSelectionType.NONE"
    ></taku-form-list>
  </div>
</p-panel>
