/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "trim",
  standalone: true,
})
export class TrimPipe implements PipeTransform {
  transform(value: string, globalTrim = false): any {
    if (!value) {
      return "";
    }

    if (globalTrim) return value.replace(/\s*/g, "");
    else return value.trim();
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
