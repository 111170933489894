/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component, EventEmitter, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable, merge, throwError } from "rxjs";
import { FormListColumnsService } from "src/app/form-list/form-list-columns.service";
import { FormListMainComponent } from "src/app/form-list/form-list-main/form-list-main.component";
import { DBService } from "src/app/shared/services/db.service";
import { TakuPayService } from "./../taku-pay.service";
import { Location } from "@angular/common";
import { TakuPayAccountDetail } from "../taku-pay-account-details/taku-pay-account-details";
import { catchError, take, tap } from "rxjs/operators";
import { ConfirmationService, MessageService } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { TakuPaymentAccountStatus } from "../taku-pay";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";

@Component({
  selector: "taku-taku-pay-form-list",
  templateUrl: "./taku-pay-form-list.component.html",
  styleUrls: ["./taku-pay-form-list.component.scss"],
})
export class TakuPayFormListComponent extends FormListMainComponent implements OnInit {
  confirmDialogKey = "takuPaymentAccount-deleteRows";
  isSuperAdmin = false;
  _detailViewRoute = "integrations/my-apps/takuPaymentAccount/";
  isSynced = false;

  constructor(
    protected location: Location,
    protected _route: ActivatedRoute,
    public dbService: DBService,
    protected formListColumnsService: FormListColumnsService,
    public dialogService: DialogService,
    protected takuPayService: TakuPayService,
    public confirmationService: ConfirmationService,
    public appSettings: AppSettingsStorageService,
    public alertMessagesService: AlertMessagesService,
    protected messageService: MessageService
  ) {
    super(location, _route, formListColumnsService);
  }

  ngOnInit() {
    this.subsList.push(
      this.takuPayService.syncTAKUPayAccounts().subscribe((result) => {
        if (result != undefined && !result["success"]) {
          this.messageService.add({
            summary: "Taku Pay Accounts Sync",
            severity: result["success"] ? "success" : "error",
            detail: result["message"],
          });
          this.location.back();
        } else {
          this.isSynced = true;
          super.ngOnInit();
        }
      })
    );
  }

  goBack(): void {
    this.location.back();
  }

  checkIfCanDeleteDialog = (rowsToDelete: TakuPayAccountDetail[]): Observable<any> => {
    return new Observable((observer) => {
      const notDeletableAccountIds: number[] = [];
      this.subsList.push(
        merge(
          ...rowsToDelete.map((account) => {
            if (!this.isSuperAdmin) {
              if (account.status !== TakuPaymentAccountStatus.Pending) {
                notDeletableAccountIds.push(account.id);
              }
            }

            const filter = {
              takuPaymentAccountId: { value: account.id, matchMode: "equals" },
            };
            return this.dbService.getRows("takuPaymentGateway", JSON.stringify(filter), 0, 1).pipe(take(1));
          })
        ).subscribe({
          next: (response) => {
            if (response.rows && response.rows.length) {
              notDeletableAccountIds.push(response.rows[0].id);
            }
          },
          complete: () => {
            rowsToDelete.forEach((row) => {
              if (row.externalAccountId && this.isSuperAdmin) {
                this.takuPayService
                  .removeTakuPayAccount(row.id)
                  .pipe(
                    catchError((errorResponse) => {
                      notDeletableAccountIds.push(row.id);
                      this.messageService.add({
                        severity: "error",
                        summary: "Service Message",
                        detail: errorResponse.error.message,
                      });
                      return throwError(errorResponse);
                    }),
                    tap((result) => {
                      if (result) {
                        this.messageService.add({
                          severity: "success",
                          summary: "Service Message",
                          detail: result.message,
                        });
                      }
                    })
                  )
                  .subscribe({
                    next: () => {},
                    error: () => {
                      notDeletableAccountIds.push(row.id);
                    },
                    complete: () => {
                      if (!notDeletableAccountIds.length) {
                        observer.next(rowsToDelete);
                        observer.complete();
                        return;
                      }
                      // Exclude from the selected rows, those which cannot be deleted
                      rowsToDelete = rowsToDelete.filter((account) => !notDeletableAccountIds.includes(account.id));
                      observer.next(rowsToDelete);
                      observer.complete();

                      this.confirmationService.confirm({
                        key: this.confirmDialogKey,
                        header: "Some records couldn't be deleted",
                        message:
                          "In order to delete a Taku Payment Account, you first need to delete all their configured locations.",
                        acceptLabel: "OK",
                        rejectVisible: false,
                      });
                    },
                  });
              } else if (!this.isSuperAdmin) {
                if (!notDeletableAccountIds.length) {
                  observer.next(rowsToDelete);
                  observer.complete();
                  return;
                }

                // Exclude from the selected rows, those which cannot be deleted
                rowsToDelete = rowsToDelete.filter((account) => !notDeletableAccountIds.includes(account.id));
                observer.next(rowsToDelete);
                observer.complete();

                // this.messageService.add(
                //   this.alertMessagesService.getMessage('taku-payment-account-permission-failure')
                // );

                this.confirmationService.confirm({
                  key: this.confirmDialogKey,
                  header: "Some records couldn't be deleted",
                  message: "Please login with super admin to delete TAKU Accounts.",
                  acceptLabel: "OK",
                  rejectVisible: false,
                });
              }
            });
          },
        })
      );
    });
  };
}
/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
