import { Branding } from "./types";

export const TAKU_BRAND: Branding = Object.freeze({
  mainLogo: "/assets/layout/images/logo.svg",
  mainLogoDark: "/assets/layout/images/logo.png",
  textLogoCss: "url('/assets/layout/images/logo-text.svg')",
  colors: {
    contrast: "inherit",
  },
  generic: {
    versionFooter: "TAKU Canada Inc",
    companyName: "TAKU",
    loadingLogo: "/assets/layout/images/logo-loading.png",
    supportEmail: "help@takulabs.io",
  },
  loginComponent: {
    accountAddress: "Your TAKU account name",
    url: "takuretail.app/",
    background: "https://www.takuretail.app/images/Storefront_Login_Page.png",
    backgroundColor: "#BA944E",
    heading: "Keep your retail business in season",
    headingColor: "#000",
    subHeading: "TAKU's resources help you stay on top of seasonal trends",
    getStarted: {
      buttonLabel: "Visit our blog",
      buttonUrl: "https://www.takulabs.io/blog",
    },
    showSignupLink: true,
  },
  businessSetupComponent: {
    businessName: "Welcome to TAKU - Set-Up your Business",
  },
  appMenuComponent: {
    eCommerce: "TAKU eCommerce",
  },
  salesChannelComponent: {
    aboutLink: "https://takulabs.io/online-orders",
  },
});
