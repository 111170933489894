/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Component } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { GenericFormComponent } from "src/app/forms/generic-form/generic-form.component";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";

import { Location } from "@angular/common";
import { PriceLevelService } from "./price-level-service";
import { Observable, throwError } from "rxjs";
import { ExtendedSelectItem } from "src/app/shared/services/db.service";
import { PriceLevel } from "./price-level";
import { catchError } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { APP_BRAND } from "src/app/utility/white-label";

@Component({
  selector: "taku-price-level",
  templateUrl: "./price-level.component.html",
  styleUrls: ["./price-level.component.scss"],
})
export class PriceLevelComponent extends GenericFormComponent<PriceLevel> {
  _model = "priceLevel";

  lookup_zones$: Observable<ExtendedSelectItem[]>;
  lookup_acctGroups$: Observable<ExtendedSelectItem[]>;
  lookup_stores$: Observable<ExtendedSelectItem[]>;

  branding = APP_BRAND;

  constructor(
    protected _router: Router,
    public fb: UntypedFormBuilder,
    public dbService: PriceLevelService,
    protected location: Location,
    appSettings: AppSettingsStorageService,
    public _route: ActivatedRoute,
    protected messageService: MessageService,
    protected alertMessage: AlertMessagesService,
    protected confirmationService: ConfirmationService
  ) {
    super(_router, fb, dbService, location, _route, messageService, alertMessage, confirmationService, appSettings);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  override onSubmit(silentMode?: boolean): Observable<any> {
    return super.onSubmit(silentMode).pipe(
      catchError((err: HttpErrorResponse) => {
        if (
          err.error.customMessage === "Validation error" &&
          err.error.errors?.find((e) => e.path === "priority_unique_per_zone")
        ) {
          this._myForm.controls.priority.setErrors({ generic: "Priority must be unique per zone" });
          this._myForm.controls.priority.markAsDirty();
        }
        return throwError(err);
      })
    );
  }

  initForm(): void {
    if (!this._id || !this._object) {
      this._object = new PriceLevel(this.appSettingsService.getZoneId());
    }
    this._myForm = this.fb.group(this._object);
    this._myForm.controls.priority.markAsDirty();
    this._myForm.controls.priority.updateValueAndValidity();
  }

  protected initLookups(): void {
    this.lookup_zones$ = this.dbService.lookup_zones();
    this.lookup_acctGroups$ = this.dbService.lookup_accountGroups();
    this.lookup_stores$ = this.dbService.lookup_stores();
  }

  initValidation(): void {
    this._validation = this.dbService.getValidationRules();
  }

  closeModal(): void {
    this.dialogClosed.emit();
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
