<div class="topWrapper">
  <div id="data-progressbar">
    <p-progressBar
      mode="indeterminate"
      value="0"
      [style.visibility]="_isUploading ? 'visible' : 'hidden'"
    ></p-progressBar>
  </div>
  <div class="grid col-12">
    <div class="col-12 lg:col-6 md:col-12">
      <p-panel>
        <ng-template pTemplate="header">
          <h5 class="mb-0"
            >Images
            <a href="javascript://" class="info-circle" (click)="imageInfoPanel.toggle($event)">
              <i class="material-icons info-circle-icon">info</i>
              <p-overlayPanel #imageInfoPanel [showCloseIcon]="false" appendTo="body">
                <div class="tooltip-styling">
                  <h5>Image Upload Tips</h5>
                  <ul>
                    <li>Maximum upload size of 2MB per file</li>
                    <li>PNG, JPG, GIF image formats only</li>
                  </ul>
                </div>
              </p-overlayPanel>
            </a>
          </h5>
          <button
            pButton
            pRipple
            icon="pi pi-plus"
            [label]="_isMobile ? 'URL' : 'Add from URL'"
            (click)="addFromURL()"
            class="p-button-raised mr-2"
          ></button>
          <div class="upload-btn-container">
            <p-fileUpload
              #fileUploader
              [multiple]="true"
              mode="basic"
              name="images[]"
              auto="true"
              accept="image/*"
              [chooseLabel]="_isMobile ? 'Upload' : 'Upload New'"
              customUpload="true"
              (uploadHandler)="myUploader($event)"
            >
            </p-fileUpload>
          </div>
        </ng-template>

        <div class="thumbnails-wrapper clearfix grid">
          <a
            *ngFor="let pictureForm of imagesFormArray['controls']; let i = index"
            [ngClass]="{ activeThumbnail: pictureForm === _activePicture }"
            class="col-4 gallery-picture"
            (click)="onPictureSelected(i)"
            href="javascript://"
          >
            <img [src]="buildImageSrc(pictureForm)" />
            <i *ngIf="pictureForm.get('pinToPictureGallery').value" class="material-icons pin-icon">bookmark</i>
          </a>
        </div>
        <!-- {{imagesFormArray.value|json}} -->
      </p-panel>
    </div>

    <div class="col-12 lg:col-6 md:col-12">
      <p-panel>
        <ng-template pTemplate="header">
          <h5 class="mb-0">Image Preview</h5>
          <button
            class="p-1"
            pButton
            pRipple
            icon="_"
            class="p-button-secondary p-button-rounded p-button-icon-only"
            (click)="onRemoveActivePicture()"
          >
            <i class="m-0 material-icons">delete</i>
          </button>
        </ng-template>

        <div *ngIf="_activePicture" class="previewImage-wrapper clearfix">
          <taku-inventory-image [formGroup]="_activePicture"></taku-inventory-image>
        </div>

        <div *ngIf="!_activePicture" class="nopreview-wrapper clearfix">
          <i>Select an image to preview here</i>
        </div>
      </p-panel>
    </div>
  </div>
</div>

<!--
<div class="col-12">
  {{imagesFormArray.value|json}}
</div> -->
