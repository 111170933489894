/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { Cashout } from "../../cashout/cashout/cashout";
import { CashoutDeposits } from "../../cashout/cashout-closetill/cashout-closetill.component";
import { Observable } from "rxjs";
import { CashoutSummaryDoc } from "../../cashout/db-cashout.service";
import { Input, ElementRef, Directive } from "@angular/core";
import { Store } from "../../settings/store-settings/store/store";
import { StoreSettingCashout } from "../../settings/store-settings/cashout-settings/CashoutSettings";

@Directive()
export abstract class CashoutSlipBase {
  @Input() cashout: Cashout;
  @Input() deposits: CashoutDeposits[];
  @Input() currencyIsoCode: string;
  @Input() cashoutSettings: StoreSettingCashout = new StoreSettingCashout();

  @Input() openingExpectedCash: number;
  @Input() openingActualAmount: number;

  onDataReady$: Observable<any>;
  docsSummaries: CashoutSummaryDoc[];
  store: Store;

  constructor(protected elementRef: ElementRef) {}

  get totalSystemAmount(): number {
    const initialVal = 0;
    if (!this.deposits) return initialVal;
    return this.deposits.reduce((acc, deposit) => {
      return acc + parseFloat(deposit.systemTotal);
    }, initialVal);
  }

  get totalActualAmount(): number {
    const initialVal = 0;
    if (!this.deposits) return initialVal;

    return this.deposits.reduce((acc, deposit) => {
      return acc + parseFloat(deposit.actualAmount + "");
    }, initialVal);
  }

  get totalPennyRounding(): number {
    const initialVal = 0;
    if (!this.docsSummaries) return initialVal;

    return this.docsSummaries.reduce((acc, doc) => {
      return acc + parseFloat(doc.sum_cashRounding);
    }, initialVal);
  }

  get totalQtyCashoutReport(): number {
    const initialVal = 0;
    if (!this.deposits) return initialVal;
    return this.deposits.reduce((acc, deposit) => {
      return acc + Number(deposit?.count);
    }, initialVal);
  }

  get showSystemValues(): boolean {
    return this.cashoutSettings.showSystemValue;
  }

  get showSignatureLine(): boolean {
    return this.cashoutSettings.printsignitureLine;
  }

  get showSalesReportSummary(): boolean {
    return this.cashoutSettings.showSalesReportSummary;
  }

  get showSalesCategories(): boolean {
    return this.cashoutSettings.showSalesCategories;
  }

  sendToPrinter() {}

  getHTMLContent() {
    return this.elementRef.nativeElement;
  }

  // Should be implemented in child classes (Cashout Slip Tape and Letter)
  abstract getStylesheets(): string[];

  subdivisionDisplay(subDivisionIsoCode: string): string {
    return subDivisionIsoCode ? subDivisionIsoCode.split("-").pop() : "";
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
