import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { DBService } from "src/app/shared/services/db.service";

@Component({
  selector: "taku-inventory-variant-setting-line",
  templateUrl: "./inventory-variant-setting-line.component.html",
  styleUrls: ["./inventory-variant-setting-line.component.scss"],
})
export class InventoryVariantSettingLineComponent implements OnInit {
  @Input() inventoryVariantSettingLineForm: UntypedFormGroup;
  lookup_inventoryVariantSetting$: Observable<any>;
  lookup_inventoryVariantSettingValue$: Observable<any>;
  inventoryOptionsSettingValuesChips: string[] = [];
  constructor(protected dbService: DBService) {}

  ngOnInit(): void {
    this.lookup_inventoryVariantSetting$ = this.dbService.lookupSelectOptions("inventoryOptionsSetting", "optionName");
    this.inventoryVariantSettingLineForm
      .get("inventoryOptionsSettingId")
      .valueChanges.subscribe((newInventoryOptionsSettingId) => {
        this.lookup_inventoryVariantSetting$.subscribe((inventoryOptionsSettings) => {
          this.inventoryVariantSettingLineForm
            .get("inventoryOptionsSetting")
            .setValue(inventoryOptionsSettings.find((row) => row.object?.id == newInventoryOptionsSettingId).object);
        });
        this.inventoryVariantSettingLineForm.get("inventoryOptionsSettingValues").setValue([]);
        this.inventoryOptionsSettingValuesChips = [];
        this.lookup_inventoryVariantSettingValue$ = this.dbService.lookupMultiSelectOptions(
          "inventoryOptionsSettingValue",
          "optionValue",
          {
            lookupFilter: JSON.stringify({
              inventoryOptionsSettingId: { value: newInventoryOptionsSettingId, matchMode: "equals" },
            }),
          }
        );
      });
    if (this.inventoryVariantSettingLineForm.get("inventoryOptionsSettingId").value) {
      this.lookup_inventoryVariantSettingValue$ = this.dbService.lookupMultiSelectOptions(
        "inventoryOptionsSettingValue",
        "optionValue",
        {
          lookupFilter: JSON.stringify({
            inventoryOptionsSettingId: {
              value: this.inventoryVariantSettingLineForm.get("inventoryOptionsSettingId").value,
              matchMode: "equals",
            },
          }),
        }
      );
    }
    this.inventoryVariantSettingLineForm.get("inventoryOptionsSettingValues").valueChanges.subscribe((values) => {
      this.inventoryOptionsSettingValuesChips = this.inventoryVariantSettingLineForm
        .get("inventoryOptionsSettingValues")
        .value.map((row) => row.optionValue);
    });

    this.inventoryOptionsSettingValuesChips = this.inventoryVariantSettingLineForm
      .get("inventoryOptionsSettingValues")
      .value?.map((row) => row.optionValue);
  }
}
