import { EventEmitter, Input, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { SettingCardSection } from "../../settings-cards/setting-cards.component";
import { DBService } from "src/app/shared/services/db.service";
import { TakuPayService } from "./taku-pay.service";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { Subscription } from "rxjs";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";
import { MessageService } from "primeng/api";

@Component({
  selector: "taku-pay",
  templateUrl: "./taku-pay.component.html",
  styleUrls: ["./taku-pay.component.scss"],
})
export class TakuPayComponent implements OnInit, OnDestroy {
  @Input() isLanding = false;

  pageIconStyleClass = "ui-icon-apps";
  pageTitle = "TAKU Pay";
  cardsInfo: SettingCardSection[] = [];
  subsList: Subscription[] = [];
  isLoading = false;

  constructor(
    private router: Router,
    private dbService: DBService,
    private appSettingsService: AppSettingsStorageService
  ) {}

  ngOnInit(): void {
    this.initializeCards();
    this.isLoading = true;

    this.subsList.push(
      this.dbService.getRows("takuPaymentAccount").subscribe((result) => {
        if (result.count === 0) {
          this.isLanding = true;
        }
        this.isLoading = false;
      })
    );
  }

  private initializeCards() {
    const storeId = this.appSettingsService.getStoreId();
    const zoneId = this.appSettingsService.getZoneId();
    const defaultStockId = storeId ? this.appSettingsService.getStore().stockId : null;

    this.cardsInfo = [
      {
        label: "",
        cards: [
          {
            // routePath: ['/integrations/my-apps/takupay/'+this.storeId],
            routePath: ["/integrations/my-apps/takupay/model-list/w/takuPaymentAccount"],
            iconStyleClass: "pi pi-credit-card",
            cardTitle: "Account Details",
            cardDesc: ["Account Details"],
            isEnabled: true,
          },
          {
            // routePath: ['/integrations/my-apps/takupay/'+this.storeId],
            routePath: ["/integrations/my-apps/taku-payment-gateways/list/takuPaymentGateway/" + storeId],
            routeQueryParams: {
              title: "takuPaymentGateway",
              queryExtraParams: JSON.stringify({ stockId: defaultStockId, zoneId: zoneId }),
            },
            iconStyleClass: "pi pi-map-marker",
            cardTitle: "Location Setup",
            cardDesc: ["Location Setup"],
            isEnabled: true,
          },
        ],
      },
    ];
  }

  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}
