import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, FormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { Subscription } from "rxjs";
import { StoreService } from "src/app/core/settings/store-settings/store/store.service";
import { AppSettingsStorageService } from "src/app/shared/app-settings-storage.service";
import { SearchResultItem } from "src/app/taku-ui/taku-search-accounts/SearchResultItem";
import { FormDataHelpers } from "src/app/utility/FormDataHelpers";
import { CommercialAccount } from "src/app/core/contact-accounts/commercial-account/commercial-account";
import { Inventory } from "src/app/core/inventory/inventory/inventory";
import { AccountRelationship, AccountType } from "src/app/core/contact-accounts/account/account";
import { TakuSearchAccountsComponent } from "src/app/taku-ui/taku-search-accounts/taku-search-accounts.component";
import { AccountComponent } from "src/app/core/contact-accounts/account/account.component";
import { CommercialAccountComponent } from "src/app/core/contact-accounts/commercial-account/commercial-account.component";
import { ConfirmationService, MessageService } from "primeng/api";
import { environment } from "src/environments/environment";
import { DBService } from "src/app/shared/services/db.service";
import { AlertMessagesService } from "src/app/shared/services/alert-messages.service";

enum FullSizeDialogName {
  COMMERCIAL_ACCOUNT = "commercialAccount",
}

@Component({
  selector: "taku-purchase-document-add-dialog",
  templateUrl: "./purchase-document-add-dialog.component.html",
  styleUrls: ["./purchase-document-add-dialog.component.scss"],
})
export class PurchaseDocumentAddDialogComponent implements OnInit, OnDestroy {
  private webUrl = environment.apiUrl;
  subsList: Subscription[] = [];
  _newPurchaseOrderForm: UntypedFormGroup;
  lookup_stocks: any[];
  FullSizeDialogName = FullSizeDialogName;
  AccountType = AccountType;
  AccountRelationship = AccountRelationship;
  _activeFullDialog: FullSizeDialogName;
  _activeFullDialogExtra: Inventory | CommercialAccount = null;
  @ViewChild("accountsSearchBox", { static: true }) accountSearchComponent: TakuSearchAccountsComponent;

  constructor(
    private dialogRef: DynamicDialogRef,
    private fb: FormBuilder,
    private storeService: StoreService,
    private appSettings: AppSettingsStorageService,
    private messageService: MessageService,
    protected alertMessage: AlertMessagesService,
    private confirmationService: ConfirmationService,
    private dbservice: DBService
  ) {}

  ngOnInit(): void {
    this._newPurchaseOrderForm = this.fb.group({
      stockId: this.fb.control(null, Validators.required),
      commercialAccountId: this.fb.control(null, Validators.required),
      currencyIsoCode: this.fb.control(null),
      currencyConversionRate: this.fb.control(1),
    });
    this.subsList.push(
      this.storeService
        .lookup_stocks(this.appSettings.getZoneId(), null)
        .subscribe((data) => (this.lookup_stocks = data))
    );
  }

  get commercialAccountIdCtrl(): AbstractControl {
    return this._newPurchaseOrderForm.get("commercialAccountId");
  }

  clearAccountField(): void {
    this.commercialAccountIdCtrl.reset();
  }

  onNewCommercialAccountCreated(commercialAccount: CommercialAccount): void {
    if (
      [AccountRelationship.supplier, AccountRelationship.both].includes(commercialAccount.account.accountRelationship)
    ) {
      this.accountSearchComponent.textSearch = commercialAccount.name;
      this.commercialAccountIdCtrl.setValue(commercialAccount.id);
      this._newPurchaseOrderForm.get("currencyIsoCode").setValue(commercialAccount.supplierCurrencyIsoCode);
      this.commercialAccountIdCtrl.markAsDirty();
    } else {
      this.confirmationService.confirm({
        header: "Account Not Selectable",
        message:
          "Account was created, but cannot be used for Purchase Order unless changed to an Account Type 'Supplier', or 'Both'. Would you like to change Account Type to 'Supplier'?",
        acceptLabel: "Yes",
        rejectLabel: "No",
        rejectVisible: true,
        acceptVisible: true,
        rejectButtonStyleClass: "p-button-link",
        accept: () => {
          commercialAccount.account.accountRelationship = AccountRelationship.supplier;
          this.dbservice
            ._putRequest<CommercialAccount>(
              this.webUrl + "model/commercialAccount/" + commercialAccount.id,
              commercialAccount
            )
            .subscribe({
              next: () => {
                this.accountSearchComponent.textSearch = commercialAccount.name;
                this.commercialAccountIdCtrl.setValue(commercialAccount.id);
                this._newPurchaseOrderForm.get("currencyIsoCode").setValue(commercialAccount.supplierCurrencyIsoCode);
                this.commercialAccountIdCtrl.markAsDirty();

                this.messageService.add({
                  summary: "Success",
                  severity: "success",
                  detail: `Commercial Account was successfully updated to 'Supplier' Account Type`,
                });
              },
              error: (_errorResponse) => {
                this.messageService.add(this.alertMessage.getErrorMessage(_errorResponse));
              },
              complete: () => {},
            });
        },
        reject: () => {
          this.accountSearchComponent.textSearch = "";
          this.commercialAccountIdCtrl.setValue(null);
          this._newPurchaseOrderForm.get("currencyIsoCode").setValue(null);
          this.commercialAccountIdCtrl.markAsPristine();
        },
      });
    }
    this.closeFullSizeDialog();
  }

  onSearchResAccount(accountResult: SearchResultItem): void {
    const commercialAccountId = accountResult.data.id;
    this.commercialAccountIdCtrl.setValue(commercialAccountId);
    this._newPurchaseOrderForm.get("currencyIsoCode").setValue(accountResult.data.supplierCurrencyIsoCode);
    this.commercialAccountIdCtrl.markAsDirty();
  }

  get _searchVendorPlaceholder(): string {
    return FormDataHelpers.InputFieldCaption(`Supplier`, this._newPurchaseOrderForm.get("commercialAccountId"));
  }

  openNewModelInDialog(defaultData: any): void {
    let dialogName: FullSizeDialogName;

    if (defaultData instanceof CommercialAccount) {
      dialogName = FullSizeDialogName.COMMERCIAL_ACCOUNT;
      defaultData.account.accountRelationship = AccountRelationship.supplier;
    }

    this.openFullSizeDialog(dialogName, defaultData);
  }

  onClosePressed(): void {
    this.dialogRef.close();
  }

  openFullSizeDialog(dialog: FullSizeDialogName, extra: CommercialAccount | Inventory = null): void {
    this._activeFullDialog = dialog;
    this._activeFullDialogExtra = extra;
  }

  closeFullSizeDialog(): void {
    this._activeFullDialog = null;
    this._activeFullDialogExtra = null;
  }

  onCreatePressed(): void {
    this.dialogRef.close(this._newPurchaseOrderForm.value);
  }

  ngOnDestroy(): void {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}
