/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */

import { formatDate } from "@angular/common";
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
  SimpleChanges,
  OnChanges,
  HostBinding,
  OnDestroy,
  inject,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from "lodash";
import { ConfirmationService, MenuItem } from "primeng/api";
import { Col, RowSelectionType } from "../../../../form-list/form-list/form-list";
import { FormListComponent } from "../../../../form-list/form-list/form-list.component";
import { PrintingFactoryService, PrintingMgr } from "../../../../shared/services/printing-service.service";
import { SearchResultItem } from "../../../../taku-ui/taku-search-accounts/SearchResultItem";
import { DocState, SaleDocType } from "../../doc/doc";
import { SaleDoc } from "../sale-doc/sale-doc";
import { TakuSearchAccountsComponent } from "../../../../taku-ui/taku-search-accounts/taku-search-accounts.component";
import { AppSettingsStorageService } from "../../../../shared/app-settings-storage.service";
import { DBService } from "../../../../shared/services/db.service";
import * as moment from "moment";
import { ReceiptFullPreviewComponent } from "../../../previews/receipt/receipt-full-preview/receipt-full-preview.component";
import { ParkedSalesFactoryService } from "./parked-sales-factory.service";
import { Subscription } from "rxjs";
import { FilterRows } from "src/app/utility/FormDataHelpers";
import { UnfinalizedSalesService } from "../../unfinalized-sales.service";

@Component({
  selector: "taku-parked-sales",
  templateUrl: "./parked-sales.component.html",
  styleUrls: ["./parked-sales.component.scss"],
})
export class ParkedSalesComponent implements OnInit, OnChanges, OnDestroy {
  subsList: Subscription[] = [];
  @HostBinding("class.full-size")
  @Input()
  fullSizeMode = true;
  @Input() shortenFormHeaderHeight = false;

  @Output() onDocReopened = new EventEmitter<SaleDoc>();
  _docReopened = new EventEmitter<SaleDoc>();

  _isDataReady = false;
  @Input() preloadData = true;
  @Input() _scrollHeight = "calc(100vh - 26rem)";

  @ViewChild("formList", { static: true }) formListComponent: FormListComponent;
  @ViewChild("printPreviewHost", { read: ViewContainerRef, static: true }) printPreviewHost: ViewContainerRef;
  @ViewChild("searchAccountsComp", { static: true }) _searchAccountsComp: TakuSearchAccountsComponent;

  private static readonly DATE_FORMAT = "yyyy-MM-dd";
  formGroup: UntypedFormGroup;
  formFilter: {};
  parkedDocColumns: Col[] = [];
  printingMgr: PrintingMgr;
  showFormList: boolean;
  RowSelectionType = RowSelectionType;

  // _activeFullDialog: DialogName;
  // _activeFullDialogExtra: any;
  actionBtns: MenuItem[] = [
    {
      label: "EXPORT",
      disabled: true,
      command: (e) => {
        this.onExportPressed(e);
      },
    },
  ];
  _parkedDocsFilter: FilterRows<SaleDoc> = {
    "doc.state": {
      value: [DocState.draft, DocState.suspended, DocState.voided],
      matchMode: "in",
    },
    // To ensure we don't pull in Voided docs that were previously finalized, we get only docs without a docNo
    "doc.docNo": {
      value: "",
      matchMode: "equals",
    },
    "doc.docType": {
      value: [
        SaleDocType.sales_invoice,
        SaleDocType.online_sales_order,
        SaleDocType.recurring_order,
        SaleDocType.pre_order,
      ],
      matchMode: "in",
    },
    "store.id": {
      value: this.appSettingService.getStoreId(),
      matchMode: "equals",
    },
  };
  extraQueryParams = {
    includes: "saleDocTender",
  };

  private unfinalizedSalesService = inject(UnfinalizedSalesService);
  constructor(
    private parkedDocServiceFactory: ParkedSalesFactoryService,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(LOCALE_ID) private defaultLocale: string,
    private printingFactory: PrintingFactoryService,
    private dbService: DBService,
    private appSettingService: AppSettingsStorageService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit() {
    this.parkedDocColumns = this.parkedDocServiceFactory
      .build(this._docReopened, this.appSettingService)
      .getFormListColumns();
    this.formFilter = this._parkedDocsFilter;

    const date = new Date();
    this.formGroup = this.fb.group({
      docNo: null,
      accountId: 0,
      dateFrom: formatDate(date, ParkedSalesComponent.DATE_FORMAT, this.defaultLocale),
      dateTo: formatDate(date, ParkedSalesComponent.DATE_FORMAT, this.defaultLocale),
    });
    this.subsList.push(
      this.route.params.subscribe((params) => {
        const storeId = parseInt(params["storeId"]) || this.appSettingService.getStoreId();
        this.printingMgr = this.printingFactory.build(storeId);
      })
    );

    this.subsList.push(
      this._docReopened.subscribe((saleDoc: SaleDoc) => {
        this.subsList.push(
          this.dbService.getRow("saleDoc", saleDoc.id).subscribe((_saleDoc: SaleDoc) => {
            if (_saleDoc.stationId !== this.appSettingService.getStationId() && _saleDoc.saleDocTenders.length > 0) {
              this.confirmationService.confirm({
                header: "Warning",
                icon: "pi pi-exclamation-triangle",
                message:
                  "Payments were applied to this unfinished transaction at a different sales station. To avoid cashout discrepancies, this transaction may only be finished or voided in the original sales station.",
                acceptLabel: "Close",
                rejectVisible: false,
              });
            } else {
              this.onContinueSales(_saleDoc);
            }
          })
        );
      })
    );
    if (this.fullSizeMode) {
      this.subsList.push(this.unfinalizedSalesService.promptForUnfinalizedSales().subscribe());
    }

    this.showFormList = !this.fullSizeMode;
    if (this.preloadData) {
      this.searchDocuments();
    }
  }

  disableDeleteRowButton(saleDoc: SaleDoc): boolean {
    return !!saleDoc.saleDocTenders?.length;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.showFormList = !this.fullSizeMode;
  }

  onKeyPressedDocNo(e) {
    if (e.keyCode === 13) {
      // If 'Enter' was pressed made the search automatically
      this.searchDocuments();
    }
  }

  printSelected() {}

  clearSelectedAccount() {
    this.formGroup.get("accountId").setValue(null);
  }

  onExportPressed(e: any): any {}

  clearSearch() {
    this.formGroup.reset();
    // this.formFilter = this._parkedDocsFilter;
    this._searchAccountsComp.clearSearchInputOnly();
  }

  searchDocuments() {
    this.showFormList = true;
    const searchQuery = this.formGroup.value;
    const queryObject = _.cloneDeep(this._parkedDocsFilter);

    if (searchQuery.docNo) {
      // Ignore dates and account if docNo was set in search
      queryObject["doc.docNo"] = { value: searchQuery.docNo, matchMode: "equals" };
    } else {
      if (searchQuery.accountId) {
        queryObject["accountId"] = { value: searchQuery.accountId, matchMode: "equals" };
      }
      if (searchQuery.dateFrom || searchQuery.dateTo) {
        if (!searchQuery.dateFrom) {
          searchQuery.dateFrom = "1800-01-01";
        }

        if (!searchQuery.dateTo) {
          const newDateTo = moment().add(100, "years").toDate();
          searchQuery.dateTo = formatDate(newDateTo, ParkedSalesComponent.DATE_FORMAT, this.defaultLocale);
        }

        queryObject["doc.docDate"] = { value: [searchQuery.dateFrom, searchQuery.dateTo], matchMode: "between" };
      }
    }

    this.formFilter = queryObject;
    this._isDataReady = true;
  }

  onSearchResAccount(account: SearchResultItem) {
    this.formGroup.patchValue({
      accountId: account.ID,
    });
  }

  refreshData() {
    if (this.formListComponent) this.formListComponent.reloadData();
  }

  onPrintPressed() {
    this.subsList.push(
      this.printingMgr.isReady.subscribe({
        next: () => {
          const selectedDoc: SaleDoc = this.formListComponent._selectedObjects;
          if (!selectedDoc) return;

          this.printingMgr.printSaleDocsInBatch([selectedDoc], this.printPreviewHost, ReceiptFullPreviewComponent);
        },
        error: (error) => {
          alert("Error: Can't print because Receipt Builder Settings are not available");
        },
      })
    );
  }

  // closeFullSizeDialog() {
  //   this._activeFullDialog = null;
  // }

  onContinueSales(saleDoc: SaleDoc): void {
    if (this.fullSizeMode) {
      void this.router.navigate(["/sell/saleDoc", saleDoc.id], {
        state: { skipUnfinalizedSalesCheck: true, unParked: true },
      });
    } else {
      this.onDocReopened.emit(saleDoc);
    }

    //this.closeFullSizeDialog();
  }

  // openFullSizeDialog(dialog: DialogName, extra = null): any {
  //   this._activeFullDialog = dialog;
  //   this._activeFullDialogExtra = extra;
  // }
  ngOnDestroy() {
    this.subsList.map((sub) => {
      sub.unsubscribe();
    });
  }
}

/* © 2018-2022 TakuLabs Ltd. All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential */
