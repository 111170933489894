<taku-form-header
  [displayMode]="_isDialog ? 'inline' : null"
  [formGroup]="_myForm"
  (backClicked)="goBack()"
  (saveClicked)="onSave()"
  (revertClicked)="onRevert()"
  (newClicked)="onNew()"
  (deleteClicked)="onDelete()"
  (cloneClicked)="onClone()"
  [toolbarTitle]="_toolbarTitle"
  [insideDialog]="_isDialog"
  [isSaving]="_isSaving"
>
</taku-form-header>

<div class="col-12 pl-0 pr-0">
  <form [formGroup]="_myForm">
    <div class="grid personal-account-container taku-expanded-gridcols-container">
      <div class="col-12 lg:col-3 md:col-5 account-details-container">
        <taku-account
          [accountAccessRights]="personalAccountAccessRights"
          [formGroup]="_myForm.controls['account']"
        ></taku-account>
      </div>

      <div
        class="col-12 lg:col-6 md:col-7 person-details-container"
        *ngIf="personalAccountAccessRights.isGeneralTabVisible || personalAccountAccessRights.isAddressTabVisible"
      >
        <taku-person
          [formGroup]="_myForm.controls['person']"
          [formCtrlAccount]="_myForm.get('account')"
          [enableConsent]="true"
          [isConsentApproved]="consentCtrl.value"
          [consentDateTime]="consentDateTimeCtrl.value"
          (consentApproved)="consentApproved($event)"
          [accountAccessRights]="personalAccountAccessRights"
        >
        </taku-person>
      </div>

      <div class="col-12 lg:col-3 note-column" *ngIf="personalAccountAccessRights.isNoteVisible">
        <p-panel>
          <ng-template pTemplate="header">
            <h2>Note</h2>
          </ng-template>

          <div class="col-12">
            <taku-input-textarea
              [formGroup]="_myForm.controls['account']"
              fieldName="note"
              fieldCaption="Internal Notes"
              [rows]="10"
              [autoResize]="true"
              [disabled]="!personalAccountAccessRights.isNoteEnabled"
            ></taku-input-textarea>
          </div>

          <hr class="fields-divider" />

          <div class="col-12">
            <div class="col-12">
              <h5>Tags</h5>
            </div>

            <div class="grid-nogutter col-12">
              <taku-chips
                [formGroup]="_myForm.controls['account']"
                fieldName="tags"
                fieldPlaceholder="Press Enter to Save Tags"
              ></taku-chips>
            </div>
          </div>
        </p-panel>
      </div>

      <!-- <div class="col-12 associated-personal-account-container">
        <div class="card card-w-title">
          <h1>Associated Personal Account</h1>
          <div class="grid">
            <div class="col-12">
              <div class="ui-rtl" dir="rtl">
                <a href="#">(+) Associate existing Personal Accounts</a>
                <a href="#">(+) Add new Personal Account</a>
              </div>
            </div>
            <div class="col-12">
              TO BE IMPLEMENTED.
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </form>
</div>

<!-- <div *ngIf="debug">
  <p>_myForm value: {{ _myForm.value | json }}</p>

    <p>_object values: {{ _object | json }}</p>
</div> -->
